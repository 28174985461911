import React, { PureComponent, } from 'react';
import { withLocalize, } from "react-localize-redux";
import moment from 'moment';
import Loader from "../../../tima/components/Loader";
import Preloader from "../../../../components/LoadingHOC/Preloader";
import PermissionService from '../../../../services/PermissionService';
import {
	MagicTable,
	MagicInput,
	MagicButton,
	MagicDate,
	MagicSelect,
} from "../../../../components/Magic";
import { MagicConfirm, } from "../../../tima/components/Magic/MagicConfirm";
import '../../styles/result_list.scss';
import { enumService, } from "../../../../services/EnumDataService";
import { listAccountService, } from "../../../../services/ListAccountDataService";

const initialClickButton = {
	chargeDemo: false,
	chargeLive: false,
	checkDemo: false,
	checkLive: false,
};

const initialCreateSymbol = {
	symbol: '',
	pair: 0,
	formula: 0,
};

class ListCorporateAction extends PureComponent {

   static format = 'YYYY-MM-DD';

   constructor (props) {
   	super(props);
   	this.state = {
   		currencyData: {
   			data: [],
   			hash: null,
   		},
   		idsInError: [],
   		idsInQuery: [],
   		idsInSuccess: [],
   		resultData: [],
   		clickButton: initialClickButton,
   		currentDate: moment(),
   		serverSelect: 'ALL',
   		currentCourse: '',
   		isLoadingResult: false,
   		isEmptyResultData: false,
   		isDisabled: false,
   		isLoadingData: true,
   		formData: [],
   		deleteSymbolId: 0,
   		createSymbol: initialCreateSymbol,
   		chargeSimpleSymbol: {
   			login: 0,
   		},
   	};
   };

   get locale () {
   	return this.props?.activeLanguage?.code;
   }

   get isDisableButton () {
   	return !this.state.formData.some(item => item?.punkts?.length);
   }

   get corporateActionConfig () {
   	const { translate, } = this.props;

   	return [
   		{
   			path: [ 'corporateaction', 'corporate_action', 'id', ],
   			key: 'id',
   		},
   		{
   			path: [ 'corporateaction', 'corporate_action', 'symbol', ],
   			key: 'symbol',
   			render: this.renderSymbol,
   			title: "list_accounts_table_corporate_action_symbol",
   		},
   		{
   			path: [ 'corporateaction', 'corporate_action', 'pair', ],
   			key: 'pair',
   			render: this.renderPair,
   			title: "list_accounts_table_corporate_action_currency",
   		},
   		{
   			path: [ 'corporateaction', 'corporate_action', 'formula', ],
   			key: 'formula',
   			render: this.renderFormula,
   			title: "list_accounts_table_corporate_action_formula",
   		},
   		{
   			accessChecked: true,
            // path: ['corporateaction', 'corporate_action', 'id'],
   			key: 'course',
   			render: this.renderCourse,
   			title: "list_accounts_table_corporate_action_course",
   		},
   		{
   			accessChecked: true,
            // path: ['corporateaction', 'corporate_action', 'id'],
   			key: 'points',
   			render: this.renderPoints,
   			title: "list_accounts_table_corporate_action_points",
   		},
   		{
   			accessChecked: true,
            // path: ['corporateaction', 'corporate_action', 'id'],
   			key: 'date',
   			render: this.renderDate,
   			title: "list_accounts_table_corporate_action_date",
   		},
   		{
            // accessChecked: true,
   			path: [ 'corporateaction', 'corporate_action', 'id', ],
   			key: 'action',
   			render: this.renderBtns,
   			title: "list_accounts_table_corporate_action_act",
   		},
   		{
   			accessChecked: true,
   			path: [ 'isCreated', ],
   			key: 'isCreated',
   		},
   		{
   			accessChecked: true,
   			key: 'results',
   			render: this.renderResultList,
   		},
   	].map(({ title: t, ...data }) => ({ ...data, ...t && { title: translate(t), }, }));
   }

   save = async state => new Promise(next => this.setState(state, next));

   enumsChange = async (enums) => {
   	if (enums.hash===this.state?.enums?.hash) {
   		return `${ this.constructor.name }.enumsChange: false`;
   	}
   	await this.save({ enums, });

   	return `${ this.constructor.name }.enumsChange: true`;
   };

   currencyDataChange = async (currencyData) => {
   	if (currencyData.hash===this.state?.currencyData.hash) {
   		return `${ this.constructor.name }.currencyDataChange: false`;
   	}
   	await this.save({ currencyData, });

   	return `${ this.constructor.name }.currencyDataChange: true`;
   };

   async componentDidMount () {
   	const enums = await enumService.enums;

   	await this.save({ enums, });
   	enumService.subscribe('enums', this.enumsChange, this);
   	listAccountService.subscribe('currencyData', this.currencyDataChange, this);
   	await this.getCurrencyData();
   }

   componentWillUnmount () {
   	enumService.unsubscribe('enums', this.enumsChange);
   	listAccountService.unsubscribe('currencyData', this.currencyDataChange, this);
   }

   toDate = value => moment(value).format(ListCorporateAction.format);

   calculatedDate = date => moment(date).startOf('day').add(-1, 'day').format(ListCorporateAction.format);

   resetClickButtons = async () => await this.save({ clickButton: initialClickButton, });

   clearResultData = async () => {
   	const data = {
   		...this.state.idsInError.length && { idsInError: [], },
   		...this.state.idsInQuery.length && { idsInQuery: [], },
   		...this.state.idsInSuccess.length && { idsInSuccess: [], },
   		...this.state.resultData.length && { resultData: [], },
   	};

   	Object.keys(data).length && await this.save(data);
   };

   // filterIdsInQueries = (id) => {
   //    if (this.state.idsInSuccess?.length) {
   //       this.setState(state => ({idsInSuccess: state.idsInSuccess?.filter(stateID => stateID !== id)}));
   //    }
   //    if (this.state.idsInError?.length) {
   //       this.setState(state => ({idsInError: state.idsInError?.filter(stateID => stateID !== id)}));
   //    }
   // };

   clickButton = (key, getResultData) => async () => {
   	try {
   		await this.save({
   			isDisabled: true,
   			clickButton: {
   				initialClickButton,
   				[key]: true,
   			},
   		});
   		await this.clearResultData();
   		await this.getResultData(...getResultData);
   	} catch (error) {
   		error?.showErrorNotification();
   	} finally {
   		await this.save({ isDisabled: false, });
   	}
   };

   getCurrencyData = async () => {
   	try {
   		await this.save({ isDisabled: true, isLoadingData: false, });
   		const getAllCourses = async () => {
   			const allCourses = {};
   			const date = this.calculatedDate(this.state?.currentDate);
   			const { data, } = await listAccountService.currencyCourses(date);

   			for (const key in data) {
   				allCourses[key] = String(data?.[key]);
   			};

   			return allCourses;
   		};
   		const getData = async () => {
   			const currencyData = await listAccountService.currencyData();
   			const data = currencyData?.data?.sort((a, b) => a?.corporateaction?.corporate_action?.id - b?.corporateaction?.corporate_action?.id);

   			return { hash: currencyData.hash, data, };
   		};
   		const allCourses = await getAllCourses();
   		const currencyData = await getData();

   		await this.save({
   			allCourses,
   			currencyData,
   			idsInError: [],
   			idsInQuery: [],
   			idsInSuccess: [],
   			resultData: [],
   		});
   	} catch (error) {
   		error?.showErrorNotification();
   	} finally {
   		await this.save({ isDisabled: false, isLoadingData: true, });
   	}
   };

   getResultData = async (demo, accrue) => {
   	await this.clearResultData();
   	const server = this.state?.serverSelect;
   	const idsInQuery = [];
   	const pr = this.state?.formData
   		.map((item, index) => item?.punkts?.length ? [ item, index, ] : false)
   		.filter(item => item)
   		.map(async ([ item, ]) => {
   			idsInQuery.push(item?.id);
   			const data = {
   				symbol: item?.symbol,
   				pair: item?.pair,
   				formula: item?.formula,
   				kurs: item?.kurs,
   				punkts: item?.punkts,
   				date: moment?.(item?.date)?.format?.('YYYY-MM-DD'),
   			};

   			try {
   				await this.save({ isLoadingResult: true, });
   				const { response, } = await listAccountService.checkDL(demo, accrue, server, [ data, ]);

   				await this.save(state => ({
   					idsInQuery: state.idsInQuery.filter(id => id !== item?.id),
   					idsInSuccess: [ ...state.idsInSuccess, item?.id, ],
   					resultData: [ ...state.resultData, ...response, ],
   					isLoadingResult: false,
   				}));
   				await this.save(state => ({
   					isEmptyResultData: !state.resultData?.length,
   				}));
   			} catch (error) {
   				error?.showErrorNotification?.();
   				await this.save(state => ({
   					idsInQuery: state.idsInQuery.filter(id => id !== item.id),
   					idsInError: [ ...state.idsInError, item?.id, ],
   					isEmptyResultData: !state.resultData?.length,
   					isLoadingResult: false,
   				}));
   			}
   		});

   	await this.save(state => ({ idsInQuery: [ ...state.idsInQuery, idsInQuery, ], }));
   	await Promise.all(pr);
   };

   getCourseByDate = async (pair, date) => {
   	try {
   		await this.save({ isDisabled: true, });
   		const { data, } = await listAccountService.currencyCourse(pair, this.calculatedDate(date));

   		return String(data);
   	} catch (error) {
   		error.showErrorNotification();
   	} finally {
   		await this.save({ isDisabled: false, });
   	}
   };

   addSymbolItem = async ({ symbol, pair, formula, }) => {
   	try {
   		await listAccountService.addSymbol({ symbol, pair, formula, });
   		await this.save({ createSymbol: initialCreateSymbol, });
   		await this.getCurrencyData();
   	} catch (error) {
   		error.showErrorNotification();
   	}
   };

   updateSymbol = async (id, symbol, pair, formula) => {
   	try {
   		await this.save({ isDisabled: true, });
   		const { data, } = await listAccountService.updateSymbol(id, { symbol, pair, formula, });
   		const updatedSymbolIndex = this.state.currencyData.data?.findIndex?.(el => el?.corporateaction?.corporate_action?.id === data?.id);
   		const listData = [ ...this.state.currencyData.data, ];

   		listData[updatedSymbolIndex].corporateaction.corporate_action = {
   			formula: data?.formula,
   			id: data?.id,
   			pair: data?.pair,
   			symbol: data?.symbol,
   		};
   		const ids = this.state.formData?.findIndex(item => item?.id === id);
   		const formData = this.state.formData;

   		delete formData[ids].isEdited;
   		await this.save({ listData, formData, });
   	} catch (error) {
   		error?.showErrorNotification();
   	} finally {
   		await this.save({ isDisabled: false, });
   	}
   };

   deleteSymbolItem = async (id) => {
   	try {
   		await this.save({
   			isDisabled: true,
   			deleteSymbolId: 0,
   		});
   		await listAccountService.deleteSymbolById(id);
   		await this.getCurrencyData();
   		await this.resetClickButtons();
   		await this.clearResultData();
   	} catch (error) {
   		error?.showErrorNotification();
   	} finally {
   		await this.save({ isDisabled: false, });
   	}
   };

   chargeSimpleSymbol = async (data, demo, accrue, server= this.state?.serverSelect) => {
   	try {
         // const server = this.state?.serverSelect;
   		const login = data?.LOGIN;

   		await this.save({ chargeSimpleSymbol: { login, }, });
   		const {
   			symbol, pair, kurs, date, formula, punkts,
   		} = data;
   		const options = [ {
   			symbol,
   			pair,
   			kurs,
   			date,
   			formula,
   			punkts,
   		}, ];
   		const { response, } = await listAccountService.chargeSimpleSymbol(demo, accrue, login, options, server);
   		const loginCalculatedSymbol = response?.[0]?.LOGIN;
   		const indexCalculateSymbol = this.state.resultData?.findIndex?.(el => el?.LOGIN === loginCalculatedSymbol);
   		const resultData = [ ...this.state.resultData, ];

   		resultData[indexCalculateSymbol] = response?.[0];
   		await this.save({
   			resultData,
   		});
   	} catch (error) {
   		error?.showErrorNotification();
   	} finally {
   		await this.save({
   			chargeSimpleSymbol: { login: 0, },
   		});
   	}
   };

   renderServerSelect = () => {
   	const conf = PermissionService.calc(this.corporateActionConfig);

   	if (conf?.id.access('index')) {
   		const serverCorporateAction = this.state?.enums?.data?.corporateaction?.ServersEnum ?? {};
   		const options = {
   			onChange: async (value) => {
   				await this.clearResultData();
   				await this.resetClickButtons();
   				await this.save({ serverSelect: value, });
   			},
   			value: this.state?.serverSelect,
   			values: Object.keys(serverCorporateAction),
   			valueToLabel: value => serverCorporateAction?.[value],
   			disabled: this.state.isLoadingResult || !this.state.isLoadingData,
   			...this.state.isLoadingResult || !this.state.isLoadingData && { className: 'disabled', },
   		};

   		return (<div className="toolbar__item "><MagicSelect { ...options } /></div>);
   	}

   	return "";
   };

   renderCommonDate = () => {
   	const conf = PermissionService.calc(this.corporateActionConfig);

   	if (conf?.id.access('index')) {
   		const options = {
   			locale: 'uk',
   			max: moment(),
   			min: moment().add(-90, 'days'),
   			onChange: async (value) => {
   				await this.save({ currentDate: this.toDate(value), });
   				await this.getCurrencyData();
   			},
   			value: this.state.currentDate,
   			disabled: this.state.isLoadingResult || !this.state.isLoadingData,
   		};

   		return (<div className="toolbar__item "><MagicDate { ...options } /></div>);
   	}

   	return "";
   };

   renderChargeDemo = ({ clickButton, isLoadingResult, }) => {
   	const conf = PermissionService.calc(this.corporateActionConfig);

   	if (conf?.id.access('index', 'update')) {
   		const options = {
   			disabled: clickButton?.chargeDemo || isLoadingResult,
   			onClick: this.clickButton('chargeDemo', [ 1, 1, ]),
   			className: [ `magic-button__item magic-button__item_${ this.isDisableButton ? "not-active" : "demo" }`, ],
   			children: this.props.translate("list_accounts_table_corporate_action_btn-demo"),
   		};

   		return (<div className="toolbar__item "><MagicButton { ...options } /></div>);
   	}

   	return "";
   };

   renderChargeLive = ({ clickButton, isLoadingResult, }) => {
   	const conf = PermissionService.calc(this.corporateActionConfig);

   	if (conf?.id.access('index', 'update')) {
   		const options = {
   			disabled: clickButton?.chargeLive || isLoadingResult,
   			onClick: this.clickButton('chargeLive', [ 0, 1, ]),
   			className: [ `magic-button__item magic-button__item_${ this.isDisableButton ? "not-active" : "check-demo" }`, ],
   			children: this.props.translate("list_accounts_table_corporate_action_btn-check-demo"),
   		};

   		return (<div className="toolbar__item "><MagicButton { ...options } /></div>);
   	}

   	return "";
   };

   renderCheckDemo = ({ clickButton, isLoadingResult, }) => {
   	const conf = PermissionService.calc(this.corporateActionConfig);

   	if (conf?.id.access('index', 'update')) {
   		const options = {
   			disabled: clickButton?.checkDemo || isLoadingResult,
   			onClick: this.clickButton('checkDemo', [ 1, 0, ]),
   			className: [ `magic-button__item magic-button__item_${ this.isDisableButton ? "not-active" : "live" }`, ],
   			children: this.props.translate("list_accounts_table_corporate_action_btn-live"),
   		};

   		return (<div className="toolbar__item "><MagicButton { ...options } /></div>);
   	}

   	return "";
   };

   renderCheckLive = ({ clickButton, isLoadingResult, }) => {
   	const conf = PermissionService.calc(this.corporateActionConfig);

   	if (conf?.id.access('index', 'update')) {
   		const options = {
   			disabled: clickButton?.checkLive || isLoadingResult,
   			onClick: this.clickButton('checkLive', [ 0, 0, ]),
   			className: [ `magic-button__item magic-button__item_${ this.isDisableButton ? "not-active" : "check-live" }`, ],
   			children: this.props.translate("list_accounts_table_corporate_action_btn-check-live"),
   		};

   		return (<div className="toolbar__item "><MagicButton { ...options } /></div>);
   	}

   	return "";
   };

   onChange = (onChange, id) => async (value) => {
   	const index = this.state.formData?.findIndex?.(item => item?.id === id);
   	const item = this.state.formData?.[index];
   	const fd = this.state.formData?.filter?.((_, i) => i !== index);
   	const el = await onChange(item, value);
   	const formData = [ ...fd, el, ];

   	await this.save({ formData, });
   }

   onChangeCreate = field => async (value) => {
   	const createSymbol = state => ({
   		createSymbol: {
   			...state?.createSymbol,
   			[field]: value,
   			...[ "formula", ]?.includes?.(field) && [ "1", 1, ]?.includes?.(value) && { pair: "", },
   		},
   	});

   	await this.save(createSymbol);
   };

   renderText = (item, text) => {
   	if (item?.access?.('index')) {
   		return <span>{text ?? "-"}</span>;
   	}

   	return "-";
   }

   renderSymbol = (symbol, { item, items, }) => {
   	const { isLoadingResult: disabled, } = this.state;
   	const regExp = /^[a-zA-Z0-9\#\&\,\.\_]{0,16}$/g;
   	const translate = {
   		ph: this.props.translate('list_accounts_table_corporate_action_symbol'),
   	};
   	const onValid = (value) => {
   		if (value !== '' && !regExp.test(value)) {
   			throw new Error('xxx');
   		}
   	};

   	if (items?.isCreated?.valueOf && item?.access('index', 'store')) {
   		const { symbol = "", } = this.state.createSymbol;
   		const options = {
   			disabled,
   			placeholder: translate?.ph ?? "",
   			value: symbol ?? "",
   			onChange: this.onChangeCreate('symbol'),
   			onValid: value => onValid(value),
   		};

   		return <MagicInput { ...options } />;
   	} else if (item?.access('update', 'index')) {
   		const id = items?.id?.valueOf;
   		const currentEl = this.state.formData?.find(el => el?.id === id);
   		const symbol = currentEl?.symbol ?? items?.symbol?.valueOf;
   		const formula = currentEl?.formula ?? items?.formula?.valueOf;
   		const pair = currentEl?.pair ?? items?.pair?.valueOf;
   		const kurs = currentEl?.kurs ?? this.state.allCourses?.[pair];
   		const options = {
   			disabled,
   			placeholder: translate?.ph ?? "",
   			value: symbol,
   			onChange: this.onChange(async (item, value) => {
   				await this.clearResultData();
   				await this.resetClickButtons();

   				return {
   					...item && item,
   					...{
   						id, pair, formula, kurs, isEdited: true, symbol: value,
   					},
   				};
   			}, id),
   			onValid: value => onValid(value),
   		};

   		return <MagicInput { ...options } />;
   	}

   	return this.renderText(item, symbol);
   };

   renderPair = (pair, { item, items, }) => {
   	const { isLoadingResult: disabled, currentDate, createSymbol, } = this.state;
   	const currencies = this.state?.enums?.data?.corporateaction?.CurrencyPairsEnum ?? {};
   	const translate = {
   		ph: this.props.translate('list_accounts_table_corporate_action_pair'),
   	};

   	if (items?.isCreated?.valueOf && item?.access('index', 'store')) {
   		const options = {
   			disabled: disabled || [ "1", 1, ].includes(createSymbol?.formula),
   			placeholder: translate?.ph ?? "",
   			className: [ disabled || [ "1", 1, ].includes(createSymbol?.formula) ? 'disabled' : '', ],
   			value: ![ "1", 1, ].includes(createSymbol?.formula) ? createSymbol?.pair : "",
   			onChange: this.onChangeCreate('pair'),
   			ItemProps: value => value === 0 ? { disabled: true, } : {},
   			values: [ 0, "", ...Object.values(currencies), ],
   			valueToLabel: (value) => {
   				switch (value) {
   					case 0: return translate?.ph ?? "";
   					case "": return "-";
   					default: return currencies[value];
   				};
   			},
   		};

   		return <MagicSelect { ...options } />;
   	} else if (item?.access('update', 'index')) {
   		const id = items?.id?.valueOf;
   		const currentEl = this.state.formData?.find(el => el?.id === id);
   		const symbol = currentEl?.symbol ?? items?.symbol?.valueOf;
   		const formula = currentEl?.formula ?? items?.formula?.valueOf;
   		const pair = currentEl?.pair ?? items?.pair?.valueOf;
   		const kurs = this.state?.allCourses?.[pair];
   		const date = moment(this.state?.formData?.find(el => el?.id === id)?.date ?? currentDate);
   		const options = {
   			disabled: disabled || [ "1", 1, ].includes(formula),
   			className: [ formula === "1" ? 'disabled' : '', ],
   			value: [ null, ].includes(pair) || [ "1", 1, ].includes(formula) ? 0 : pair,
   			onChange: async (value) => {
   				await this.clearResultData();
   				await this.resetClickButtons();
               // если есть валюта и формула !== "1" получаем новый курс по дате и валюте
   				const courseByDate = ![ null, 0, ].includes(value) && ![ "1", 1, ].includes(formula)
   					? await this.getCourseByDate(value, this.toDate(date))
   					: "";
   				const index = this.state.formData?.findIndex?.(item => item?.id === id);
   				const item = this.state.formData?.[index];
   				const fd = this.state.formData?.filter((_, i) => i !== index);
   				const el = {
   					...item && item,
   					...{
   						id, symbol, formula, kurs: courseByDate ?? kurs, isEdited: true, pair: value,
   					},
   				};
   				const formData = [ ...fd, el, ];

   				await this.save({ formData, resultData: [], });
   			},
   			values: [ 0, ...Object.keys(currencies), ],
   			valueToLabel: value => value === 0 ? '-' : currencies?.[value],
   		};

   		return <MagicSelect { ...options } />;
   	}

   	return this.renderText(item, pair);
   };

   renderFormula = (formula, { item, items, }) => {
   	const { isLoadingResult: disabled, currentDate, createSymbol, } = this.state;
   	const allFormulas = this.state?.enums?.data?.corporateaction?.FormulaEnum ?? {};
   	const translate = {
   		ph: this.props.translate('list_accounts_table_corporate_choose_formula'),
   	};

   	if (items?.isCreated?.valueOf && item?.access('index', 'store')) {
   		const options = {
   			disabled,
   			value: createSymbol?.formula,
   			ItemProps: value => value === 0 ? { disabled: true, } : {},
   			onChange: this.onChangeCreate('formula'),
   			values: [ 0, ...Object.keys(allFormulas), ],
   			valueToLabel: value => value === 0 ? translate?.ph : allFormulas[value],
   		};

   		return (<MagicSelect { ...options } />);
   	} else if (item?.access('update', 'index')) {
   		const id = items?.id?.valueOf;
   		const currentEl = this.state.formData?.find(el => el?.id === id);
   		const symbol = currentEl?.symbol ?? items?.symbol?.valueOf;
   		const f = currentEl?.formula ?? formula;
   		let pair = currentEl?.pair ?? items?.pair?.valueOf;
   		const kurs = currentEl?.kurs ?? this.state.allCourses[pair];
   		const date = moment(currentEl?.date ?? currentDate);
   		const options = {
   			disabled,
   			value: f,
   			onChange: async (value) => {
   				await this.clearResultData();
   				await this.resetClickButtons();
   				const index = this.state.formData?.findIndex?.(item => item?.id === id);
   				const item = this.state.formData?.[index];
   				const fd = this.state.formData?.filter((_, i) => i !== index);
               // если есть валюта и формула !== ["1", 1] получаем новый курс по дате и валюте
   				const courseByDate = ![ "1", 1, ].includes(value) && pair
   					? await this.getCourseByDate(pair, this.toDate(date))
   					: "";

               // если формула === ["1", 1] то очищаем поле ВАЛЮТА
   				pair = ![ "1", 1, ].includes(value) ? pair : null;
   				const el = {
   					...item && item,
   					...{
   					id, symbol, pair, kurs: courseByDate ?? kurs, isEdited: true, formula: value,
   					},
   				};
   				const formData = [ ...fd, el, ];

   				await this.save({ formData, resultData: [], });
   			},
   			values: Object.keys(allFormulas),
   			valueToLabel: value => allFormulas[value],
   			...disabled && { className: 'disabled', },
   		};

   		return <MagicSelect { ...options } />;
   	}

   	return this.renderText(item, formula);
   };

   renderPoints = (_, { item, items, }) => {
   	const { isLoadingResult: disabled, allCourses, currentDate, } = this.state;
   	const translate = {
   		ph: this.props.translate('list_accounts_table_corporate_action_points'),
   	};

   	if (items?.isCreated?.valueOf) {
   		return '';
   	} else if (item?.access('update', 'index')) {
   		const id = items?.id?.valueOf;
   		const currentEl = this.state.formData?.find(el => el?.id === id);
   		const symbol = currentEl?.symbol ?? items?.symbol?.valueOf;
   		const formula = currentEl?.formula ?? items?.formula?.valueOf;
   		const pair = currentEl?.pair ?? items?.pair?.valueOf;
   		const kurs = currentEl?.kurs ?? allCourses?.[pair] ?? "";
   		const punkts = currentEl?.punkts ?? "";
   		const date = moment(currentEl?.date ?? currentDate);
   		const options = {
   			disabled,
   			value: punkts,
   			placeholder: translate?.ph,
   			onChange: this.onChange(async (item, value) => {
   				await this.clearResultData();
   				await this.resetClickButtons();

   				return {
   					...item && item,
   					...{
   					id, symbol, formula, pair, kurs, date, punkts: value,
   					},
   				};
   			}, id),
   			onValid: (value) => {
   				const regExp = /^[\d]+(?:([.])[\d]{0,4})?$/g;

   				if (value !== '' && !regExp.test(value)) {
   					throw new Error('xxx');
   				}
   			},
   		};

   		return (<MagicInput { ...options } />);
   	}

   	return this.renderText(item, punkts);
   };

   renderCourse = (_, { item, items, }) => {
   	if (items?.isCreated?.valueOf) {
   		return '';
   	} else if (item?.access('update', 'index')) {
   		const { isLoadingResult: disabled, allCourses, } = this.state;
   		const translate = {
   			ph: this.props.translate('list_accounts_table_corporate_action_course'),
   		};
   		const id = items?.id?.valueOf;
   		const currentEl = this.state.formData?.find(el => el?.id === id);
   		const symbol = currentEl?.symbol ?? items?.symbol?.valueOf;
   		const formula = currentEl?.formula ?? items?.formula?.valueOf;
   		const pair = currentEl?.pair ?? items?.pair?.valueOf;
   		const kurs = currentEl?.kurs ?? allCourses?.[pair];
   		const options = {
   			disabled,
   			placeholder: translate?.ph ?? "",
   			value: kurs,
   			onChange: this.onChange(async (item, value) => {
   				await this.clearResultData();
   				await this.resetClickButtons();

   				return {
   					...item && item,
   					...{
   					id, symbol, formula, pair, kurs: value,
   					},
   				};
   			}, id),
   			onValid: (value) => {
   				const regExp = /^[0-9]+\.?[0-9]{0,8}$/g;

   				if (value !== '' && !regExp.test(value)) {
   					throw new Error('xxx');
   				}
   			},
   		};

   		return <MagicInput { ...options } />;
   	}

   	return this.renderText(item, kurs);
   };

   renderDate = (_, { item, items, }) => {
   	if (items?.isCreated?.valueOf) {
   		return '';
   	} else if (item?.access('update', 'index')) {
   		const { isLoadingResult: disabled, currentDate, allCourses, } = this.state;
   		const id = items?.id?.valueOf;
   		const currentEl = this.state.formData?.find(el => el?.id === id);
   		const symbol = currentEl?.symbol ?? items?.symbol?.valueOf;
   		const formula = currentEl?.formula ?? items?.formula?.valueOf;
   		const pair = currentEl?.pair ?? items?.pair?.valueOf;
   		const kurs = currentEl?.kurs ?? allCourses[pair] ?? "";
   		const options = {
   			locale: this.locale,
   			max: moment().toDate(),
   			min: moment().add(-90, 'days').toDate(),
   			disabled,
   			value: moment(currentEl?.date ?? currentDate),
   			onChange: async (value) => {
   				await this.clearResultData();
   				await this.resetClickButtons();
               // если есть валюта и формула !== "1" получаем новый курс по дате и валюте
   				let courseByDate;

   				if (pair) {
   					courseByDate = await this.getCourseByDate(pair, this.toDate(value));
   				}
   				const index = this.state.formData?.findIndex?.(item => item?.id === id);
   				const item = this.state.formData?.[index];
   				const fd = this.state.formData?.filter((_, i) => i !== index);
   				const el = {
   					...item && item,
   					...{
   					id, symbol, formula, pair, kurs: courseByDate ?? kurs, date: value,
   					},
   				};
   				const formData = [ ...fd, el, ];

   				await this.save({ formData, resultData: [], });
   			},
   		};

   		return <MagicDate { ...options } />;
   	}

   	return this.renderText(item, currentDate);
   };

   renderBtns = (_, { item, items, }) => {
   	const { isDisabled: disabled, formData, createSymbol, } = this.state;

   	if (items?.isCreated?.valueOf && item?.access('index', 'store')) {
   		const isDisabled = ({ symbol, pair, formula, }) => {
   			const case_1 = [ false, "x", ].includes(formula) && (!symbol || !pair);
   			const case_2 = [ false, ].includes(formula) && (symbol || !pair);
   			const case_3 = [ "1", 1, ].includes(formula) && !symbol;
   			const case_4 = [ "1/x", "x", ].includes(formula) && (!symbol || !pair);

   			return case_1 || case_2 || case_3 || case_4;
   		};
   		const options = {
   			className: "magic-button__item magic-button__item_add-corporate-action",
   			disabled: isDisabled(createSymbol),
   			onClick: async () => await this.addSymbolItem(createSymbol),
   			children: this.props.translate('list_accounts_table_corporate_action_btn-add'),
   		};

   		return <MagicButton { ...options } />;
   	}
   	const id = items?.id?.valueOf;
   	const translate = {
   		btnSave: this.props.translate('list_accounts_table_corporate_action_btn-save'),
   		btnDelete: this.props.translate('list_accounts_table_corporate_action_btn-delete'),
   		confirmAccept: this.props.translate('list_accounts_table_corporate_action_accept'),
   		confirmReject: this.props.translate('list_accounts_table_corporate_action_reject'),
   		confirmTitle: this.props.translate('list_accounts_table_corporate_action_delete'),
   	};
   	const renderBtnSave = () => {
   		const isNotDisable = () => formData?.find(item => item?.id === id)?.hasOwnProperty('isEdited');
   		const options = {
   			onClick: async () => {
   				const currentRow = formData?.find(el => el?.id === id);
   				const { symbol, pair, formula, } = currentRow;

   				await this.updateSymbol(id, symbol, pair, formula);
   			},
   			className: "magic-button__item magic-button__item_save-corporate-action",
   			disabled: disabled || !isNotDisable(),
   			children: translate?.btnSave ?? "",
   		};

   		return (<MagicButton { ...options } />);
   	};

   	const renderBtnDelete = () => {
   		const options = {
   			onClick: () => this.setState({ deleteSymbolId: id, }),
   			className: "magic-button__item magic-button__item_delete-corporate-action",
   			disabled,
   			children: translate?.btnDelete ?? "",
   		};

   		return (
	<div style={ { position: 'relative', } }>
	<MagicConfirm
	title={ translate?.confirmTitle ?? "" }
	accept={ translate?.confirmAccept ?? "" }
	reject={ translate?.confirmReject ?? "" }
	onReject={ () => this.setState({ deleteSymbolId: 0, }) }
	onAccept={ async () => {
   						this.deleteSymbolItem(id);
   					} }
	isVisible={ this.state.deleteSymbolId === id }
   				/>
	<MagicButton { ...options } />
   			</div>
   		);
   	};

   	if (item?.access?.('index', 'store', 'destroy')) {
   		return (
	<div className="buttons">
	{renderBtnSave()}
	{renderBtnDelete()}
   			</div>
   		);
   	}

   	return "-";
   }

   renderCorporateAction = () => {
   	const data = [ ...this.state.currencyData.data, { isCreated: true, }, ];
   	const options = {
   		config: this.corporateActionConfig,
   		data,
   		head: [ "symbol", "pair", "formula", "course", "points", "date", "action", ],
   		RowProps: ({ items, }) => {
   			return {
   				"data-success": this.state.idsInSuccess?.includes?.(items?.id?.valueOf),
   				"data-error": this.state.idsInError?.includes?.(items?.id?.valueOf),
   			};
   		},
   	};

   	return (<MagicTable { ...options } />);
   };

   renderToolbar = () => {
   	return (
	<div className="toolbar__wrapper">
	{this.renderServerSelect()}
	{this.renderCommonDate()}
	<div className="toolbar__buttons">
	{this.renderChargeDemo(this.state)}
	{this.renderChargeLive(this.state)}
	{this.renderCheckDemo(this.state)}
	{this.renderCheckLive(this.state)}
   			</div>
   		</div>
   	);
   };

   renderContentBlock = () => {
   	return (
	<div className="content-block corporate-action">
	<div className="column-table">{this.renderCorporateAction()}</div>
	<div className="column-results">
	<h2 className="column-results__title">
	{this.props.translate("list_accounts_table_corporate_action_results")}
   				</h2>
	{this.renderResultList()}
   			</div>
   		</div>
   	);
   };

   renderResultList = () => {
   	const conf = PermissionService.calc(this.corporateActionConfig);

   	if (conf?.results?.access('index')) {
   		const {
   			isLoadingResult,
   			resultData,
   			isEmptyResultData,
   			clickButton,
   			chargeSimpleSymbol,
   		} = this.state;
   		const translate = {
   			accrued: this.props.translate("list_accounts_table_corporate_action_charged"),
   			charge: this.props.translate("list_accounts_table_corporate_action_btn-charge"),
   			empty: this.props?.translate('list_accounts_table_corporate_action_empty'),
   			notFound: this.props?.translate('list_accounts_table_corporate_action_not_found'),
   			symbol: this.props?.translate("list_accounts_table_corporate_action_symbol"),
   			score: this.props?.translate("list_accounts_table_corporate_action_score"),
   			records: this.props?.translate("list_accounts_table_corporate_action_records"),
   			amount: this.props?.translate("list_accounts_table_corporate_action_amount"),
   			total: this.props?.translate("list_accounts_table_corporate_action_total"),
   			description: this.props?.translate("list_accounts_table_corporate_action_description"),
   			server: this.props?.translate("list_accounts_table_corporate_action_server"),
   		};
   		const renderResultButton = (data) => {
   			const chargeButton = (demo, accrue) => {
   				const options = {
   					className: "magic-button__item magic-button__item_charge-demo",
   					onClick: () => this.chargeSimpleSymbol(data, demo, accrue, data?.server),
   					children: translate?.charge ?? "",
   				};

   				return (<MagicButton { ...options } />);
   			};

   			if (data?.accrueResult) {
   				return (<div style={ { fontWeight: 600, } }>{translate?.accrued}</div>);
   			}
   			if (clickButton?.checkDemo) {
   				return chargeButton(1, 1);
   			} else if (clickButton?.checkLive) {
   				return chargeButton(0, 1);
   			}
   		};

   		const items = data => [
   			[ "symbol", "symbol", ],
   			[ "score", "LOGIN", ],
   			[ "server", "server", ],
   			[ "records", "trades_count", ],
   			[ "amount", "calculation_formula", ],
   			[ "total", "totalSumm", ],
   			[ "description", "decryption", ],
   		].map((el, key) => (
	<React.Fragment key={ key }>
	<dt className="result-item__title">{ translate?.[el[0]] ?? "" }</dt>
	<dd className="result-item__description">{ data?.[el[1]] ?? "" }</dd>
   			</React.Fragment>),
   		);

   		return (
	<Loader
	loaded={ !isLoadingResult }
	loading={ (<Preloader scale={ 0.8 } />) }
   			>
	{isEmptyResultData
   					? (<span style={ { padding: '10px 0', } }>{translate?.empty ?? translate?.notFound}</span>)
   					: resultData?.map?.((data, index) => {
   						return (
   							<Loader
   								loaded={ chargeSimpleSymbol?.login !== data?.LOGIN }
   								loading={ (<Preloader scale={ 0.8 } />) }
   								key={ index }
	>
   								<div className="result__item">
   									<dl className="result__list">
   										{items(data)}
	</dl>
   									{renderResultButton(data)}
	</div>
	</Loader>
   						);
   					})
   				}
   			</Loader>);
   	}

   	return "-";
   }

   render () {
   	const { isLoadingData, } = this.state;

   	return (
	<div className="content-block">
	<div className="top-block_corporate-action">
	<h1 className="page-title">{this.props.translate("list_accounts_corporate_action")}</h1>
   			</div>
	{this.renderToolbar()}
	<Loader loaded={ isLoadingData } loading={ (<Preloader scale={ 1 } />) }>
	{this.renderContentBlock()}
   			</Loader>
   		</div>
   	);
   };
}

export default withLocalize(ListCorporateAction);