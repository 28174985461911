import React                from "react";
import snsWebSdk            from '@sumsub/websdk';
import {withLocalize}       from "react-localize-redux";
import LangService          from "../../../../../services/LangService";
import {getSumsubConfig, setCategory}    from "../../../services/AccountRequestService";
import PropTypes from "prop-types";

class SumsubVerification extends React.Component {
    static propTypes = {
        account_id: PropTypes.number.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            config: {},
            loadData: false,
        };
    }

    componentDidMount = async () => {
        const { account_id } = this.props;

        try {
            const response = await getSumsubConfig(account_id);

            await this.setState({config: response}, () => this.iframeFunc());
        }catch (e) {
            console.log(e);
        }

        await this.setState({loadData: true});
    };

    iframeFunc = () => {
        const {config } = this.state;
        if (!Object.keys(config).length > 0) return false;
        const { url, flowName, accessToken } = config;

        this.launchWebSdk(url, flowName, accessToken);
    };

    launchWebSdk = (apiUrl, flowName, accessToken) => {
        const { config } = this.state;

        let snsWebSdkInstance = snsWebSdk.init(
                accessToken,
                (newAccessToken) => Promise.resolve(newAccessToken)
            )
            .withConf({
                lang: LangService.getActiveLanguage().short_name,
                ...config,
            })
            .on('idCheck.onError', (error) => {
                console.error('WebSDK onError', error);
            })
            .onMessage((type, payload) => {
                if (type === "idCheck.stepCompleted") {
                    const { account_id } = this.props;

                    setCategory({"account_id": account_id, "category_id": 3}).then((response) => {
                        console.log("Success update category");
                    }).catch((error) => {

                    });
                    //todo something if doc verification is success
                }
            });

        snsWebSdkInstance = snsWebSdkInstance.build();

        snsWebSdkInstance.launch('#sumsub-websdk-container')
    };

    render(){
        return (<div id="sumsub-websdk-container"></div>);
    }
}

export default withLocalize(SumsubVerification);
