/* eslint-disable max-len , sort-keys*/
import React, { Component, } from "react";
import { withRouter, } from "react-router-dom";
import { withLocalize, } from "react-localize-redux";
import {
	MagicTable,
	MagicButton,
	MagicFilterPanels,
	Mentor,
} from "../../../../../../components/Magic";
import MagicTooltip from "../../../../../tima/components/Magic/MagicTooltip/MagicTooltip";
import {
	asyncGetSymbolsGroups,
	deleteSymbolsGroups,
} from "../../../../../../services/PartnersRequestService";
import PagePanel from "../../../../../tima/components/Pages";
import Loader from "../../../../../tima/components/Loader";
import Preloader from "../../../../../../components/LoadingHOC/Preloader";
import { MagicConfirm, } from "../../../../../tima/components/Magic";
import CreateUpdateSymbolGroup from "../Popup/CreateUpdateSymbolGroup";

class SymbolsGroupsMagicTable extends Component {
	constructor (props) {
		super(props);

		this.state = {
			data: [],
			filterLastChange: Date.now(),
			isEmptyData: false,
			loaded: false,
			pages: {
				filter: 0,
				take: 50,
				total: 0,
				totalPages: 0,
				variance: 2,
			},
			removeSymbolGroupId: null,
		};

		const mf = new Mentor({
			owner: this,
			serviceId: 161,
			translate: this.props.translate,
		});

		Object.defineProperty(this, "mf", { get: () => mf, });
	}

	async componentDidMount () {
		this.mf.subscribe(
			{
				changed: this.mfChanged,
				cleaned: this.mfDone,
				cleaning: this.mfDoing,
				loaded: this.mfDone,
				loading: this.mfDoing,
				saved: this.mfDone,
				saving: this.mfDoing,
			},
			this,
		);

		await this.mf.init({ doEmit: true, });
	}

	componentWillUnmount () {
		this.mf.unsubscribe([ this.mfChanged, this.mfDoing, this.mfDone, ], this);
	}

	save = state => new Promise(next => this.setState(state, next));

	onChange = (name, value) => this.save({ [name]: value, });

	params = () => this.props.match.params;

	query = () => new URLSearchParams(this.props.location.search);

	mfChanged = async () => {
		await this.save({ filterLastChange: Date.now(), });
	};

	mfDoing = async () => {
		await this.save({ loaded: false, });
	};

	mfDone = async () => {
		await this.mfChanged();
		await this.onDataLoad();
	};

	enumsChange = async (enums) => {
		if (enums.hash === this.state?.enums?.hash) {
			return `${ this.constructor.name }.enumsChange: false`;
		}
		await this.save({ enums, });

		return `${ this.constructor.name }.enumsChange: true`;
	};

	get symbolsGroupsConfig () {
		const { translate, } = this.props;
		const prefix = "symbols_";

		return [
			{
				path: [ "partners", "symbols_groups", "id", ],
				key: "symbolsGroupsId",
				render: this.renderInitialValue,
				title: `${ prefix }id`,
				orderId: "o:id",
			},
			{
				path: [ "partners", "symbols_groups", "name", ],
				key: "symbolsGroupsName",
				render: this.renderInitialValue,
				title: `${ prefix }name`,
				orderId: "o:name",
			},
			{
				path: [ "partners", "symbols_groups", "amount", ],
				key: "symbolsGroupsAmount",
				render: this.renderInitialValue,
				title: `${ prefix }groups_amount`,
				orderId: "o:amount",
			},
			{
				path: [ "core", "dictionary_trade_servers", "server_name", ],
				key: "symbolsGroupsServer",
				render: this.renderInitialValue,
				title: `${ prefix }server`,
				orderId: `o:server`,
			},
			{
				path: [ "partners", "symbols_groups", "created_at", ],
				key: "symbolsGroupsCreatedAt",
				render: this.renderCreateUpdate,
				title: `${ prefix }created_at`,
				orderId: "o:created",
			},
			{
				path: [ "partners", "symbols_groups", "updated_at", ],
				key: "symbolsGroupsUpdatedAt",
				render: this.renderCreateUpdate,
				title: `${ prefix }updated_at`,
				orderId: "o:updated",
			},
			{
				path: [ "partners", "symbols_groups", "id", ],
				key: "symbolsGroupsActions",
				render: this.renderActions,
				title: `${ prefix }actions`,
				customAccessCheck: ({ item, }) => {
					return (
						item.access("show") &&
						(item.access("update") || item.access("destroy"))
					);
				},
			},
		].map(({
			orderId: o, title: t, xtitle: x, ...item
		}) => ({
			...item,
			...o && {
				order: async () => {
					await this.mf?.orderedToNext(o);
				},
				orderTo: this.mf?.orderedTo(o),
			},
			...t && { title: translate(t), },
			...x && { title: x, },
		}));
	}

	removeSymbolGroup = async (
			symbolGroupId = this.state.removeSymbolGroupId,
	) => {
		if (!symbolGroupId) {
			console.warn("symbolGroup id not found");

			return;
		}

		try {
			await deleteSymbolsGroups(symbolGroupId);
			await this.onDataLoad();
		} catch (error) {
			error?.showErrorNotification?.();
		}
	};

	renderSymbolsCount = (value, { item, }) => {
		if (item?.access?.("index")) {
			return value;
		}
	};

	renderInitialValue = (value, { item, }) => {
		if (item?.access?.("index") && value === 0) {
			return value;
		}

		if (item?.access?.("index")) {
			const LENGTH_SHORT = 20;

			return (
				<MagicTooltip
					classNameTooltip={ `tooltip tooltip--large-width` }
					content={ value ?? "-" }
					lengthCut={ LENGTH_SHORT }
				/>
			);
		}

		return "-";
	};

	renderCreateUpdate = (value, { item, }) => {
		if (item?.access?.("index")) {
			const LENGTH_SHORT = 20;

			return (
				<MagicTooltip
					classNameTooltip={ `tooltip tooltip--large-width` }
					content={ value ?? "YYYY.MM.DD HH:MM:SS" }
					lengthCut={ LENGTH_SHORT }
				/>
			);
		}

		return "YYYY.MM.DD HH:MM:SS";
	};

	renderChannelStatus = (status, { item, }) => {
		if (item.access("index")) {
			return this.props?.enums?.partners?.SymbolStatusEnum?.[+status] ?? "-";
		}

		return "-";
	};

	// eslint-disable-next-line no-unused-vars
	renderActions = (value, { item, items, data, }) => {
		const { translate, popup, } = this.props;
		const { removeSymbolGroupId, } = this.state;

		const renderRemoveButton = () => {
			if (item.access("destroy")) {
				const options = {
					className:
						"magic-button__item magic-button__item_partners-delete-symbol margin--top-10",
					children: translate("symbols_actions_delete"),
					onClick: () => this.onChange("removeSymbolGroupId", value),
				};

				return (
					<div className="magic-confirm-wrapper">
						<MagicConfirm
							accept={ translate("symbols_remove_accept") }
							closeByClickOutside={ true }
							isVisible={ Number(removeSymbolGroupId) === Number(value) }
							reject={ translate("symbols_remove_reject") }
							title={ translate(`magic_confirm_title`) }
							onAccept={ () => this.removeSymbolGroup(removeSymbolGroupId) }
							onReject={ () => this.onChange("removeSymbolGroupId", null) }
						/>
						<MagicButton { ...options } />
					</div>
				);
			}

			return "";
		};

		const renderEditButton = () => {
			const {
				core: { dictionary_trade_servers, },
				partners: { symbols_groups, },
			} = data;

			const formData = {
				trading_server_id: dictionary_trade_servers.id,
				name: symbols_groups.name,
				id: symbols_groups.id,
				amount: symbols_groups.amount ?? 0,
			};

			const options = {
				className: "magic-button__item magic-button__item_partners-edit-symbol",
				children: translate("symbols_actions_edit"),
				onClick: () => popup.togglePopup(formData),
			};

			return (
				<div className="magic-confirm-wrapper">
					<MagicButton { ...options } />
				</div>
			);
		};

		return (
			<>
				{item.access("update") && renderEditButton()}
				{item.access("destroy") && renderRemoveButton()}
			</>
		);
	};

	renderTable = () => {
		const {
			loaded, pages, data, isEmptyData,
		} = this.state;
		const { translate, } = this.props;

		const options = {
			config: this.symbolsGroupsConfig,
			data,
			head: [
				"symbolsGroupsId",
				"symbolsGroupsName",
				"symbolsGroupsAmount",
				"symbolsGroupsServer",
				"symbolsGroupsCreatedAt",
				"symbolsGroupsUpdatedAt",
				"symbolsGroupsActions",
			],
			RowProps: ({ items, }) => {
				return {
					"data-amount-null": [ 0, ].includes(items?.symbolsGroupsAmount?.valueOf),
				};
			},
		};

		return (
			<>
				<MagicFilterPanels mf={ this.mf } show={ true } translate={ translate } />
				<div
					className={ `table-wrapper partners__table ${
						isEmptyData ? "partners__table--empty" : ""
					}` }
				>
					<Loader loaded={ loaded } loading={ <Preloader scale={ 1 } /> }>
						<MagicTable { ...options } />
						{isEmptyData && (
							<div className="table__empty-text">
								{translate("symbols_empty")}
							</div>
						)}

						<PagePanel
							doText={ this.onPageText }
							doTo={ pageId => `?page=${ pageId }` }
							filter={ pages.filter }
							page={ this.pageId() }
							take={ pages.take }
							total={ pages.total }
							variance={ pages.variance }
							onClick={ this.onPageClick }
						/>
					</Loader>
				</div>
			</>
		);
	};

	render () {
		const { enums, popup, } = this.props;

		return (
			<>
				{popup.isOpen && (
					<CreateUpdateSymbolGroup
						enums={ enums }
						formData={ popup.data }
						onClose={ () => popup.togglePopup() }
						onDataLoad={ this.onDataLoad }
					/>
				)}
				{this.renderTable()}
			</>
		);
	}

	pageId = () => {
		const page = this.query().get("page");

		return Number(page) || 0;
	};

	pageIdAsGET = (pageId) => {
		// eslint-disable-next-line no-param-reassign
		pageId = pageId === undefined ? this.pageId() : pageId;
		const { filterId, } = this.mf;

		return {
			filterId,
			take: this.state.pages.take,
			skip: pageId * this.state.pages.take,
		};
	};

	onDataLoad = async (pageId) => {
		await this.save({ loaded: false, isEmptyData: false, });
		const pageOptions = this.pageIdAsGET(pageId);
		const loaded = (data, meta) => ({ pages, }) => ({
			data,
			pages: { ...pages, ...meta, },
			...!data.length && { isEmptyData: true, },
		});

		try {
			const response = await asyncGetSymbolsGroups(pageOptions);
			const {
				data = [],
				meta: { filter = 0, total = 0, totalPages = 0, },
			} = response;

			await this.save(loaded(data, { filter, total, totalPages, }));
		} catch (error) {
			await this.save(loaded([], { filter: 0, total: 0, totalPages: 0, }));
			error?.showErrorNotification?.();
		}

		await this.save(state => ({
			loaded: true,
			...state.removeSymbolGroupId && { removeSymbolGroupId: null, },
		}));
	};

	// eslint-disable-next-line no-unused-vars
	onPageClick = ({ event, pageId, pageIs, }) => {
		this.onDataLoad(pageId);
	};

	onPageText = (pageId, pageIs) => {
		const { translate, } = this.props;
		const {
			current, first, prev, next, last,
		} = pageIs;
		const {
			skipped, taken, filter, total,
		} = pageIs;

		if (skipped || taken) {
			const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : "?";
			const text = skipped ? translate("partners_pages_items") : "";

			return skipped ? `${ text }: ${ id }` : id;
		} else if (filter || total) {
			const id = Number.isInteger(pageId) ? `${ pageId }` : "?";
			const text = translate(
				filter ? "partners_pages_filtered" : "partners_pages_total",
			);

			return `${ text }: ${ id }`;
		} else if (first || prev || next || last) {
			return "";
		} else if (current) {
			return `${ pageId + 1 }`;
		}

		return `${ pageId + 1 }`;
	};
}

export default withRouter(withLocalize(SymbolsGroupsMagicTable));