import { valid, } from './StorageItemOrder.valid';

/**
 * Structure for creation of StorageItemOrder
 *
 * @typedef {Object} StorageItemOrderData - Setting for StorageItemOrder
 * @property {Object} $ - { id: String, type: String("order"), }
 * @property {Number(0, 1)} [enabled=1] - Apply status
 * @property {String} f - Order by field
 * @property {String("order_by")} [o="order_by"] - Const "order_by"
 * @property {String("asc", "desc")} [v="asc"] - Order direction
 * @property {String("n", "y")} [j="n"] - Order in join [from Config!]
 * @property {Number(0, 1)} [order] - Order by expression [from Options!]
 * @property {Object} [...item={}]
 */

/**
 * @class
 */
export class StorageItemOrder {

  /**
   * Create StorageItemOrder instance
   *
   * @method
   * @arg {StorageItemOrderData} data - StorageItemOrder settings
   * @arg {object} storage - Storage instance
   * @arg {Object} $$ -
   * @memberof StorageItemOrder
   */
  constructor (
      data,
      storage,
      $$ = {
        changed: null,
        inited: null,
      },
  ) {
    valid(data, storage, $$);

    Object.defineProperties(this, {
      config: { enumerable: true, get: () => storage.config, },
      data: { enumerable: true, get: () => data, },
      mentor: { enumerable: true, get: () => storage.mentor, },
      options: { enumerable: true, get: () => storage.options, },
      storage: { enumerable: true, get: () => storage, },
      toObject: { get: () => () => data, },
    });

    const {
      $, enabled, f, o, v,
      ..._
    } = data;

    Object.freeze($);

    Object.defineProperties(this, {
      $: { enumerable: true, get: () => ({ ...$, }), },
      $$changed: { enumerable: true, get: () => $$.changed, },
      $$inited: { enumerable: true, get: () => $$.inited, },
      _: { enumerable: true, get: () => _, },
      enabled: { enumerable: true, get: () => enabled ?? 1, },
      f: { enumerable: true, get: () => f, },
      o: { enumerable: true, get: () => o ?? 'order_by', },
      v: { enumerable: true, get: () => v ?? 'asc', },
    });

    Object.freeze(this);

    // check field in storage.fields
    // eslint-disable-next-line no-shadow
    const isField = _ => this.options.fields.includes(_);

    if (Array.isArray(f) && !f.every(isField)) {
      throw new Error(`Expect every StorageItemOrder.f ${ f } in Storage.fields`);
    } else if (!Array.isArray(f) && !isField(f)) {
      throw new Error(`Expect StorageItemOrder.f ${ f } in Storage.fields`);
    }
  }

  /**
   * Create new StorageItemOrder instance with chaged state
   *
   * @method
   * @arg {Object} setter={} - changed properties
   * @arg {String[]} unsetter=[] - removed properties
   * @arg {Object} $$ -
   * @returns {StorageItemOrder} Clone
   * @memberof StorageItemOrder
   */
  $$ (setter = {}, unsetter = [], $$ = {}) {
    const clone = this.toClone();
    const mix = Object.entries({ ...clone, ...setter, })
      .filter(([ key, ]) => !unsetter.includes(key))
      .map(([ key, value, ]) => ({ [key]: value, }));
    const data = Object.assign({}, ...mix);

    return new StorageItemOrder(data, this.storage, {
      changed: this.$$changed,
      inited: this.$$inited,
      ...$$,
    });
  }

  /**
   * Shugar access to order config by key
   *
   * @arg {String} key - Key in Config
   * @returns {*} Config value
   * @memberof StorageItemOrder
   */
  cfg (key) {
    return this.config.order(this.$id)?.[key];
  }

  /**
   * Shugar access to order options by field and key
   *
   * @arg {String} key - Key in Field Options
   * @arg {String} field - Field in Options
   * @returns {*} Options value
   * @memberof StorageItemOrder
   */
  opt (key) {
    return field => this.options.field(field)?.[key];
  }

  /**
   * Return changable part of StorageItemOrder for cloning by $$
   *
   * @method
   * @returns {JSON} Plain clone
   * @memberof StorageItemOrder
   */
  toClone () {
    const id = this.$id;
    const type = this.$type;

    return {
      ...this._,
      $: { id, type, },
      enabled: this.enabled,
      f: this.f,
      o: this.o,
      v: this.v,
    };
  }

  /**
   * Exporting StorageItemOrder to be stored to DB
   *
   * @method
   * @returns {JSON} Export clone
   * @memberof StorageItemOrder
   */
  toJSON () {
    const id = this.$id;
    const type = this.$type;

    return {
      $: { id, type, },
      enabled: this.enabled,
      f: this.f,
      o: this.o,
      v: this.v,
      j: this.j,
      ...[ 0, 1, ].includes(this.order) && { order: this.order, },
    };
  }

  /**
   * Cast StorageItemOrder to String
   *
   * @returns {String} Cast to String
   * @memberof StorageItemOrder
   */
  toString () {
    return `StorageItemOrder ${ JSON.stringify({
      ...this.toJSON(),
      empty: this.empty,
      filled: this.filled,
      object: this.toObject(),
      type: this.type,
    }, null, '\t') }`;
  }

  /**
   * Get order id to be stored to DB
   *
   * @readonly
   * @memberof StorageItemOrder
   */
  get $id () {
    return this.$.id;
  }

  /**
   * Get order const type "order" to be stored to DB
   *
   * @readonly
   * @memberof StorageItemOrder
   */
  get $type () {
    return this.$.type;
  }

  /**
   * Get this.toClone() as property for debug
   *
   * @readonly
   * @memberof StorageItemOrder
   */
  get clone () {
    return this.toClone();
  }

  /**
   * Checks if it should not be stored to DB
   *
   * @readonly
   * @memberof StorageItemOrder
   */
  get empty () {
    return !this.enabled;
  }

  /**
   * Get field name to be stored to DB
   *
   * @readonly
   * @memberof StorageItemOrder
   */
  get field () {
    return this.f;
  }

  /**
   * Checks if it should be stored to DB
   *
   * @readonly
   * @memberof StorageItemOrder
   */
  get filled () {
    return !!this.enabled;
  }

  /**
   * Get j flag for queries on BE
   *
   * @readonly
   * @memberof StorageItemOrder
   */
  get j () {
    return this.cfg('j') ?? 'n';
  }

  /**
   * Get this.toJSON() as property for debug
   *
   * @readonly
   * @memberof StorageItemOrder
   */
  get json () {
    return this.toJSON();
  }

  /**
   * Get this.toObject() as property for debug
   *
   * @readonly
   * @memberof StorageItemOrder
   */
  get object () {
    return this.toObject();
  }

  /**
   * Get const "order_by" to be stored to DB
   *
   * @readonly
   * @memberof StorageItemOrder
   */
  get operator () {
    return this.o;
  }

  /**
   * Get order flag for queries on BE
   *
   * @readonly
   * @memberof StorageItemOrder
   */
  get order () {
    return this.cfg('order') ?? this.opt('order')(this.f);
  }

  /**
   * Get field type in DB
   *
   * @readonly
   * @memberof StorageItemOrder
   */
  get type () {
    return this.cfg('type') ?? this.opt('type')(this.f);
  }

  /**
   * Get direction to be stored to DB
   *
   * @readonly
   * @memberof StorageItemOrder
   */
  get value () {
    return this.v;
  }

}
