import React from 'react';
import { withLocalize } from 'react-localize-redux';

class DistributionInputs extends React.Component {
    render () {
        const { translate } = this.props;

        return (
            <form className = 'distribution-settings' onSubmit = { () => this.props.handleSubmit() }>
                <div className = 'item'>
                    <span>{translate('briefcase_size')} :</span>
                    <input
                        pattern = '\d*'
                        type = 'number'
                        value = { this.props?.data?.briefcase }
                        onChange = { this.props.handleChange('briefcase') }
                    />
                </div>
                <div className = 'item'>
                    <span>{translate('conversion_perc')} :</span>
                    <input
                        type = 'number'
                        value = { this.props?.data?.retention_percent }
                        onChange = { this.props.handleChange('retention_percent') }
                    />
                </div>
                <div className = 'item'>
                    <span>{translate('processing_perc')} :</span>
                    <input
                        type = 'number'
                        value = { this.props?.data?.processing_percent }
                        onChange = { this.props.handleChange('processing_percent') }
                    />
                </div>
                <div className = 'item'>
                    <button className = 'magic-button magic-button_panels-apply'>
                        {translate('list_accounts_block-edit_btn_save')}
                    </button>
                </div>
            </form>
        );
    }
}

export default withLocalize(DistributionInputs);
