/* eslint-disable max-len */
import React, { Component, } from "react";
import { withRouter, } from "react-router-dom";
import { withLocalize, } from "react-localize-redux";

import { HorizontalMenu, } from "../../../components";
import PartnersSystemHeader from "../../ components/PartnersSystemHeader";
import PartnersListMagicTable from "./Table/PartnersListMagicTable";
import { PARTNERS_LIST_EXPORT_FIELDS, } from "./constants/PARTNERS_LIST_EXPORT_FIELDS";

import {
	MagicFilterPanels,
	Mentor,
	showExportToExcelError,
} from "../../../../../components/Magic";

import PagePanel from "../../../../tima/components/Pages";
import { getPartnersParters, } from "../../../../../services/PartnersRequestService";
import PermissionService from "app/services/PermissionService";
import { securedRequest, } from "../../../../../services/RequestService/index";

import * as configBreadcrumbs from './Config/BreadcrumbsConfig';
class PartnersList extends Component {
	constructor (props) {
		super(props);

		this.state = {
			data: [],
			filterLastChange: Date.now(),
			isEmptyData: false,
			loaded: false,
			meta: {},
			pages: {
				filter: 0,
				take: 50,
				total: 0,
				variance: 2,
			},

		};

		const mf = new Mentor({
			owner: this,
			serviceId: 191,
			translate: this.props.translate,
		});

		Object.defineProperty(this, "mf", { get: () => mf, });
	}

	async componentDidMount () {
		this.mf.subscribe(
			{
				changed: this.mfChanged,
				cleaned: this.mfDone,
				cleaning: this.mfDoing,
				loaded: this.mfDone,
				loading: this.mfDoing,
				saved: this.mfDone,
				saving: this.mfDoing,
			},
			this,
		);

		await this.mf.init({ doEmit: true, });
	}

	save = state => new Promise(next => this.setState(state, next));

	onChange = (name, value) => this.save({ [name]: value, });

	params = () => this.props.match.params;

	mfChanged = async () => {
		await this.save({ filterLastChange: Date.now(), });
	};

	mfDoing = async () => {
		await this.save({ loaded: false, });
	};

	mfDone = async () => {
		await this.mfChanged();
		await this.onDataLoad();
	};

	enumsChange = async (enums) => {
		if (enums.hash === this.state?.enums?.hash) {
			return `${ this.constructor.name }.enumsChange: false`;
		}
		await this.save({ enums, });

		return `${ this.constructor.name }.enumsChange: true`;
	};
	componentWillUnmount = () => {
		this.mf.unsubscribe([ this.mfChanged, this.mfDoing, this.mfDone, ], this);
	};

	query = () => new URLSearchParams(this.props.location.search);

	getPermissions = (action) => {
		const pm = PermissionService.calc([ {
			path: [ 'partners', 'partners', ],
			key: 'user',
		}, ]);

		return pm.user.access(action);
	}

	exportToExcel = activeTab => (event) => {
		if (activeTab) {
			const { filterId: filter_id, } = this.mf;

			const globalUrl = `/api/partners/partners`;
			const exportUrl = `/export-to-excel`;
			let url = "";
			let file_name = "";

			switch (activeTab) {
				case "partners": {
					url = `${ globalUrl }${ exportUrl }`;
					file_name = `Partners`;
					break;
				}
				default: {
					break;
				}
			}
			const options = {
				fields: PARTNERS_LIST_EXPORT_FIELDS[activeTab],
				file_name,
			};

			if (filter_id) {
				options.filter_id = filter_id;
			}
			securedRequest
				.post(url, options)
				.then((res) => {
					// eslint-disable-next-line no-undef
					fileDownload(res.data, res.headers.filename);
				})
				.catch((error) => {
					showExportToExcelError(error);
				});
		}
	};

	renderActions = () => {
		const excel = this.getPermissions('exportToExcel');

		if (!excel) {
			return null;
		}

		const { translate, } = this.props;

		return (
			<div className=" top-button-area ">
				{excel ? (
					<div
						className="button bordered"
						onClick={ this.exportToExcel("partners") }
					>
						&nbsp;
						{translate(`managerPage_export_to_excel`)}
					</div>
				) : null}
			</div>
		);
	};
	pageId = () => {
		const page = this.query().get("page");

		return Number(page) || 0;
	};

	pageIdAsGET = (pageId) => {
		const pages = this.state.pages;

		// eslint-disable-next-line no-param-reassign
		pageId = pageId === undefined ? this.pageId() : pageId;
		const { filterId, } = this.mf;
		const result = {
			filterId,
			skip: pageId * pages.take,
			take: pages.take,
		};

		return result;
	};

	onDataLoad = async (pageId) => {
		await this.save({ isEmptyData: false, loaded: false, });

		const pageOptions = this.pageIdAsGET(pageId);
		const loaded = (data, meta) => ({ pages, }) => ({
			data,
			pages: { ...pages, ...meta, },
			...!data.length && { isEmptyData: true, },
		});

		try {
			const response = await getPartnersParters(pageOptions);
			const {
				data = [],
				meta: { filter = 0, total = 0, },
			} = response;

			await this.save(loaded(data, { filter, total, }));
		} catch (error) {
			await this.save(loaded([], { filter: 0, total: 0, }));
			showExportToExcelError(error);
		}
		await this.save({ loaded: true, });
	};

    // eslint-disable-next-line no-unused-vars
    onPageClick = async ({ event, pageId, pageIs, }) => {
    	await this.onDataLoad(pageId);
    };
	onPageText = (pageId, pageIs) => {
		const { translate, } = this.props;
		const {
			current,
			first,
			prev,
			next,
			last,
		} = pageIs;
		const {
			skipped,
			taken,
			filter,
			total,
		} = pageIs;

		if (skipped || taken) {
			const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : "?";
			const text = skipped ? translate("partners_pages_items") : "";

			return skipped ? `${ text }: ${ id }` : id;
		} else if (filter || total) {
			const id = Number.isInteger(pageId) ? `${ pageId }` : "?";
			const text = translate(filter ? "partners_pages_filtered" : "partners_pages_total");

			return `${ text }: ${ id }`;
		} else if (first || prev || next || last) {
			return "";
		} else if (current) {
			return `${ pageId + 1 }`;
		}

		return `${ pageId + 1 }`;

	};

    render () {
    	const {
    		loaded, data, pages, enums,
    	} = this.state;
    	const { translate, } = this.props;

    	const permissionToShow = this.getPermissions('index');
    	const breadcrumbsItems = configBreadcrumbs.breadcrumbs4PartnersList;

    	const head = [
    		"partnerId",
    		"partnerName",
    		"partnerManager",
    		"partnerAccount",
    		"partnerCountry",
    		"partnerStatus",
    		"partnerCode",
    		"partnerRenumeration",
    		"partnerReferrals",
    		"partnerRegistered",
    		"partnerConfirmated",
    	];

    	return (
	<div className="partners-partners">
	<PartnersSystemHeader
	translate={ translate }
	breadcrumbsItems={ breadcrumbsItems }
	actions={ this.renderActions() }
	title={ "menu_partners_system_partners_list" }
    			/>
	<HorizontalMenu />
	{permissionToShow && <>
	<MagicFilterPanels
	mf={ this.mf }
	show={ true }
	translate={ translate }
    				/>
	<PartnersListMagicTable
	data={ data }
	onDataLoad={ this.onDataLoad }
	mf={ this.mf }
	head={ head }
	enums={ enums?.data }
	loaded={ loaded }
    				/>
	<PagePanel
	doText={ this.onPageText }
	filter={ pages.filter }
	page={ this.pageId() }
	take={ pages.take }
	total={ pages.total }
	variance={ pages.variance }
	onClick={ this.onPageClick }
	doTo={ pageId => `?page=${ pageId }` }
    				/>
    			</>}

    		</div>
    	);
    }

}

export default withRouter(withLocalize(PartnersList));