import React, { Component } from 'react';
import { withLocalize, Translate } from "react-localize-redux";
import {CREATE_TEMPLATE_MANAGEMENT,CREATE_TEMPLATE_TRANSLATION,EDIT_TEMPLATE_TRANSLATION} from "../constants";

class ModalBTNTranslation extends Component {
    constructor (props) {
        super(props);

        this.state = {
            are_u_sure: false,
        };
    }

    areSure = () => {
        this.setState((state)=>{
            return { are_u_sure: !state.are_u_sure }
        })
    }

    render () {

        return (
            <Translate>
                {({ translate }) => {
                    return (
                        <div className = 'modal_management-BTN'>

                            {this.props.templateTranslation === EDIT_TEMPLATE_TRANSLATION ?
                                <React.Fragment>
                                    <button className={`button templateManagement_table_btn_edit ${this.props.sendRequest ? 'disabled' : ''}`} onClick = {() => {{
                                        this.props.updateTemplateTranslation(translate);
                                    }}
                                    }>{translate(`template_management_btn_update`)}</button>
                                    <button className={`button templateManagement_table_btn_delete ${this.props.sendRequest ? 'disabled' : ''}`} onClick={()=>{this.areSure()}} >
                                        {translate(`template_management_btn_delete`)}
                                    </button>
                                </React.Fragment>
                                :
                                this.props.templateTranslation === CREATE_TEMPLATE_TRANSLATION ?
                                    <React.Fragment>
                                        <button className={`button templateManagement_table_btn_edit ${this.props.sendRequest ? 'disabled' : ''}`} onClick = {(e) => {
                                            this.props.createTemplateTranslation(translate);
                                            this.props.clearTemplateTranslation(this.props.popUpTemplateManagement.modal_name);
                                        }
                                        }>{translate(`template_management_btn_save`)}</button>
                                        <button className={`button templateManagement_table_btn_cancel ${this.props.sendRequest ? 'disabled' : ''}`} onClick={()=>{{
                                            this.props.toggleTemplateCollaps();
                                            this.props.clearTemplateTranslation(this.props.popUpTemplateManagement.modal_name, true);
                                        }}} >
                                            {translate(`template_management_btn_cancel`)}
                                        </button>
                                    </React.Fragment>
                                    :
                                    ""
                            }

                            {this.state.are_u_sure ?
                                <div className="modal_delete">
                                    <h4>{translate(`template_management_are_u_sure`)}</h4>
                                    <p>{translate(`template_management_you_want_to_delete`)} {translate(`template_management_translation`)}  {CREATE_TEMPLATE_MANAGEMENT[this.props.popUpTemplateManagement.modal_key].label} {translate(`template_management_template_translation`)}</p>
                                    <div className="modal_delete-btn">
                                        <a className = 'btn_yes' onClick={()=>{ {
                                            this.props.deleteTemplateTranslation(translate);
                                            this.areSure();
                                        } }}>{translate(`template_management_yes`)}</a>
                                        <a className = 'btn_no' onClick={()=>{this.areSure()}}>{translate(`template_management_no`)}</a>
                                    </div>
                                </div>
                                : ''
                            }

                        </div>
                    );
                }}
            </Translate>
        );
    }
}
export default withLocalize(ModalBTNTranslation);
