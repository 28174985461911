export const AffiliateProgramsStatus = Object.freeze({
	ACTIVE: 2,
	CalculationsStopped: 3,
	CLOSED: 5,
	NEW: 1,
	PaymentsStopped: 4,
});

export const AllowSeeCreateRebate = Object.freeze({
	EVERYONE: 1,
	REBATE_SERVICE: 2,
	NONE: 3,
});