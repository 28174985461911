import React from 'react';
// import { connect } from 'react-redux';
import { withRouter, } from "react-router-dom";
import { withLocalize, } from 'react-localize-redux';

import { Alert, } from "reactstrap";
import { doLogin, } from "../../../admin_panel/users/reducers/user/actions";
import {managerStatusOnline} from "../../../conversations/services/ConversationsRequestService";
import NotificationService from "../../../../services/NotificationService";

class LoginForm extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			token: '',
            // user:        {},
			remember: false,
			credentials: {
				email: '',
				password: '',
			},
			alert: false,
		};
	}

    save = async state => new Promise(next => this.setState(state, next));

    handleChangeInput = async (event) => {
    	event.preventDefault();
    	const { name, value, } = event?.target ?? {};

    	await this.save(({ credentials, }) => ({
    		credentials: { ...credentials, [name]: value, },
    		alert: false,
    	}));
    };

    handleRemember = async (event) => {
    	const {
    		type, name, checked, value,
    	} = event?.target;

    	await this.save({ [name]: type === 'checkbox' ? checked : value, });
    };

    handleSubmit = async (event) => {
    	event.preventDefault();
    	if (!this.state?.credentials?.email?.trim?.() || !this.state?.credentials?.password?.trim?.()) {
    		return this.save({ alert: true, });
    	}
    	const response = await doLogin(this.state.credentials);

    	if (response) {
    		this.props?.checkAuthorization();
			try {
				await managerStatusOnline();
			}
			catch (error) {
				NotificationService.error({ title: 'error', message: error, remove: false, });

			}
    		window.history.pushState(null, null, this.props?.location?.pathname);
    	} else {
    		return this.save({ alert: true, });
    	}
        // this.props.history.push(this.props?.location?.pathname ?? "/dashboard");
    };

    getAllert = () => {
    	return (<Alert color="warning"> {this.props.translate(`wrong_credential`)}</Alert>);
    };

    renderEmail = (alert) => {
    	const options = {
    		className: 'input-login form-control',
    		name: 'email',
    		onChange: e => this.handleChangeInput(e),
    		placeholder: 'example@mail.com',
    		type: 'email',
    		value: this.state.login,
    	};

    	return (
	<div className="form-group">
	{ alert }
	<div className="login-form-icon">
	<i className="la la-user" />
    			</div>
	<label>{ this.props.translate(`email`)}*</label>
	<input { ...options } />
    		</div>
    	);
    };

    renderPassword = () => {
    	const options = {
    		className: 'input-login form-control',
    		name: 'password',
    		onChange: e => this.handleChangeInput(e),
    		placeholder: '********',
    		type: 'password',
    		value: this.state.password,
    	};

    	return (
	<div className="form-group">
	<div className="login-form-icon">
	<i className="la la-key" />
    			</div>
	<label> {this.props.translate(`password`)}*</label>
	<input { ...options } />
    		</div>
    	);
    };

    renderRememberCheckbox = () => {
    	const options = {
    		name: 'remember',
    		onChange: e => this.handleRemember(e),
    		type: 'checkbox',
    		checked: this.state.remember,
    	};

    	return (
	<div className="form-group">
	<div className="d-flex align-items-center justify-content-between">
	<label className="custom-checkbox">
	<input { ...options } />
	<span>{this.props.translate(`remember_me`)}</span>
    				</label>
	{/*<a onClick = { this.props.toggleFrogotPassword }>{translate(`forgot_password`)}?</a>*/}
    			</div>
    		</div>
    	);
    };

    renderSubmitButton = () => {
    	const options = {
    		className: 'button big green',
    		type: 'submit',
    		value: this.props.translate(`login`),
    	};

    	return (
	<div className="form-group submit-group">
	<div className="d-flex align-items-center justify-content-center">
	<input { ...options } />
    			</div>
    		</div>
    	);
    };

    render () {
    	let alert = '';

    	this.state.alert ? alert = this.getAllert() : null;

    	return (
	<form className="login-form" onSubmit={ this.handleSubmit }>
	{this.renderEmail(alert)}
	{this.renderPassword()}
	{this.renderRememberCheckbox()}
	{this.renderSubmitButton()}
    		</form>
    	);
    }
}

/*const mapStateToProps = (store) => {
    return {
        // user: store.user,
        page: store.page,
    };
};*/

// export default withRouter(withLocalize(connect(mapStateToProps, null)(LoginForm)));
export default withRouter(withLocalize(LoginForm));