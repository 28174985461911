import openSocket from 'socket.io-client';

const RiskNodeServiceConnect = (() => {
    const configMt4 = {
        'production': {
            host: "https://mt5.wsockets.com",
            portReal: "10005", // 10003 for DEVELOPMENT (TEST)
            portDemo: "10009", // 10003 for DEVELOPMENT (TEST)
        },
        'development': {
            host: "https://mt5.wsockets.com",
            portReal: "8002",
            portDemo: "8002",
        },
    };

    const configMt5 = {
        'production': {
            host: "https://mt5.wsockets.com",
            port: "1234",
        },
        'development': {
            host: "https://mt5.wsockets.com",
            port: "8002",
        },
    };

    let io = null;

    return {
        init (isReal, isPlatformMT4 = true) {
            if (io === null) {
                const config = isPlatformMT4 ? configMt4 : configMt5;
                const connect = isPlatformMT4 ?
                    `${config[process.env.NODE_ENV].host}:${this.getPort(isReal, config)}`
                    : `${config[process.env.NODE_ENV].host}:${config[process.env.NODE_ENV].port}`;

                io = openSocket(connect, {
                    reconnectionAttempts: 1,
                    autoConnect: true,
                    xdomain: true,
                    secure: true,
                    rejectUnauthorized: false,
                    transports: ["websocket"],
                });
            }
        },
        getPort(isReal, config) {
            return isReal ? config[process.env.NODE_ENV].portReal : config[process.env.NODE_ENV].portDemo;
        },
        logout () {
            for (const k in this.remove()) {
                this.remove()[k]();
            }
        },
        on () {
            return {
                message: (callback) => {
                    io.on("message", callback);
                },
            };
        },
        emit () {
            return {
                newID: (risk_num) => {
                    io.emit("newID", risk_num);
                },
            };
        },
        remove () {
            return {
                message: () => {
                    io?.removeListener('message');
                    io?.disconnect();
                    io = null;
                },
            };
        },
    };
})();

export default RiskNodeServiceConnect;
