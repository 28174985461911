const addPrefixZero = (number) => number < 10 ? `0${number}`: number;

const getFormatedDate = (date) => {
	const convertedDate = new Date(date);

	const dateInFormat = `${ [
		convertedDate.getFullYear(),
		addPrefixZero(convertedDate.getMonth() + 1),
		addPrefixZero(convertedDate.getDate()),
	].join("-") } ${ [
		addPrefixZero(convertedDate.getHours()),
		addPrefixZero(convertedDate.getMinutes()),
		addPrefixZero(convertedDate.getSeconds()),
	].join(":") }`;

	return dateInFormat;
};

export default getFormatedDate