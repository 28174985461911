export class Observable {
    constructor(settings) {
        const {
            events: Events = [],
            extendable = false,
        } = settings;

        const events = Object.assign(
            {},
            ...Events.map(event => {
                return { [event]: [], };
            })
        );

        const not = (listener, owner) => ([l, o]) => {
            return ![l].includes(listener) || ![o, undefined].includes(owner);
        };

        Object.defineProperties(this, {
            events: {
                enumerable: true,
                get: () => Object.entries(events)
                    .map(([
                        event,
                        listers
                    ]) => [
                        event,
                        listers.length
                    ]),
            },
            emit: {
                get: () => async (event, ...data) => {
                    try {
                        const emitters = events[event]?.map?.(([listener, owner]) => () => {
                            return new Promise(async (resolve, reject) => {
                                try {
                                    const done = await listener(...data);
                                    resolve({ done, owner, });
                                } catch (error) {
                                    reject({ error, owner, });
                                }    
                            });
                        });
                        const result = await Promise.all(emitters.map(_ => _()));
                        return result;    
                    } catch (error) {
                        console.error({ error, event, data, });
                    }
                },
            },
            off: {
                get: () => (event, listener, owner) => {
                    if (events[event] instanceof Array) {
                        const eventsFiltered = events[event].filter(not(listener, owner));
                        events[event] = eventsFiltered;
                    } else if (!extendable) {
                        throw new Error(`Observable.off not expect event: ${ event }`);
                    }
                },
            },
            on: {
                get: () => (event, listener, owner) => {
                    if (!Array.isArray(events[event]) && extendable) {
                        events[event] = [];
                        this.on(event, listener, owner);
                    } else if (
                        Array.isArray(events[event])
                        &&
                        listener instanceof Function
                        &&
                        events[event].every(not(listener, owner))
                    ) {
                        events[event].push([listener, owner]);
                    } else {
                        throw new Error(`Observable.on not expect event: ${ event }`);
                    }
                },
            },
        });

        Object.defineProperties(this, {
            subscribe: {
                get: () => (events, owner) => {
                    if (Array.isArray(events)) {
                        events.forEach(e => this.subscribe(e, owner));
                    } else if (events instanceof Function) {
                        this.events.forEach(e => this.on(e, events, owner));
                    } else if (events instanceof Object) {
                        for (const [e, L] of Object.entries(events)) {
                            const listeners = Array.isArray(L) ? L : [L];
                            listeners.forEach(l => this.on(e, l, owner));
                        }
                    }
                },
            },
            unsubscribe: {
                get: () => (events, owner) => {
                    if (Array.isArray(events)) {
                        for (const event of events) {
                            this.unsubscribe(event, owner);
                        }
                    } else if (events instanceof Function) {
                        const listener = events;
                        for (const [event, amount] of this.events) {
                            this.off(event, listener, owner);
                        }
                    } else if (events instanceof Object) {
                        for (const [event, L] of Object.entries(events)) {
                            const listeners = Array.isArray(L) ? L : [L];
                            for (const listener of listeners) {
                                this.off(event, listener, owner);
                            }
                        }
                    }
                },
            },
        });

        const checkList = {
            0: () => Events instanceof Array,
            1: () => Events.length || extendable,
            2: () => Events.every(e => ['string'].includes(typeof e)),
            3: () => Events.every(e => /^[\w]+$/.test(e)),
        };
        for (const [index, check] of Object.entries(checkList)) {
            try {
                if (check()) continue;
                const Class = this.constructor.name;
                throw new Error(`${ Class } events expect Strings[]`);
            } catch (error) {
                throw new Error(`${ error } [check: ${ index }]`);
            }
        }
    }
};
