import React                        from 'react';
import * as PropTypes               from 'prop-types';
import { operators as allOperators, } from '../../services/Operators.js';
import { MagicSelect, }             from '../../../MagicSelect';

export class MagicFilterOperator extends React.Component {
	static defaultProps = {
		disabled: false,
		showIcon: true,
		showIcons: true,
		showText: false,
		showTexts: true,
	};

	static propTypes = {
		disabled: PropTypes.bool.isRequired,
		filter: PropTypes.object.isRequired,
		showIcon: PropTypes.bool.isRequired,
		showIcons: PropTypes.bool.isRequired,
		showText: PropTypes.bool.isRequired,
		showTexts: PropTypes.bool.isRequired,
	};

	get filter() {
		return this.props.filter;
	}

	get mentor() {
		return this.filter.mentor;
	}

	valueToLabel = (operator, icon, text) => {
		const Operator = this.mentor.Operator(operator);
		return (<React.Fragment>
			{ icon ? (<span className={ `magic-operator-icon_${ Operator.css }` } />) : null }
			{ text ? this.mentor.translate(`magic_filter_${ Operator.css }`) : null }
		</React.Fragment>);
	};

	onChange = async (operator) => {
		if (this.filter.o === operator) return;
		const a = this.filter.Operator.amountOfValues;
		const aa = allOperators.find(operator).amountOfValues;
		const V = {
			0: null,
			1: null,
			2: [null, null],
			Infinity: [],
		};
		return this.mentor.filterChange({
			id: this.filter.$id,
			setter: {
				o: operator,
				...a!==aa && { v: V[aa] },
			},
			$$changed: true,
		});
	};
	
	render () {
		const { disabled, } = this.props;
		const { showIcon: I, showText: T, } = this.props;
		const { showIcons: II, showTexts: TT, } = this.props;
		const options = {
			className: [
				'magic-filter-operator',
				this.filter.enabled ? '' : 'magic-filter-operator_disabled',
				'magic-select__filter-operator',
			],
			disabled,
			label: (operator) => this.valueToLabel(operator, I, T),
			value: this.filter.operator,
			values: this.filter.operators,
			valueToLabel: (operator) => this.valueToLabel(operator, II, TT),
			onChange: this.onChange,
		};
		return ( <MagicSelect { ...options } /> );
	}
};
