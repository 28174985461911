import React from 'react';
import PermissionService, { MODULE_KEYS as pm, } from '../../../../services/PermissionService';

import PartnersList from './PartnersList/PartnersList';
import PartnersRequests from './PartnersRequests/PartnersRequests';
import { partnersCardConfig, } from './PartnerCard/routes';

export const partnersConfig = [
	...partnersCardConfig,
	{
		permission: pm.PARTNERS_REQUESTS,
		action: PermissionService.indexRoute(),
		component: <PartnersRequests />,
		path: [
			'/partners/requests/partnership-applications',
		],
	},
	{
		permission: pm.PARTNERS_PARTNERS_LIST,
		action: PermissionService.indexRoute(),
		component: <PartnersList />,
		path: [
			'/partners/requests/partners-list',
		],
	},
];