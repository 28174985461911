import React, { Component } from 'react';
import {getAvatar} from "../../../services/TaskHelperService";

export default class AddResponsibleRow extends Component {
    constructor (props) {
        super(props);
        this.state = {
            images: {},
        };
    }

    componentDidMount () {
        getAvatar(this, this.props.responsible['id'], this.props.usersAllAvatar);
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        getAvatar(this, nextProps.responsible['id'], nextProps.usersAllAvatar);
    }

    render () {

        return (
            <React.Fragment>
                <div className = 'responsible-flex'>
                    <div className = 'responsible-avatar'>
                        <img alt = 'user' src = { this.state.images.imageId } />
                    </div>
                    <div className = 'responsible-text'>
                        <div className = 'semibold'>{`${this.props.responsible['name']} ${this.props.responsible['surname']}`}</div>
                    </div>
                    <div
                        className = 'responsible-action' onClick = { () => {
                        this.props.setToStateParticipants(this.props.responsible);
                        } }>
                        <i className = 'la la-plus add' />
                    </div>
                </div>
            </React.Fragment>

        );
    }
}
