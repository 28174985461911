/* eslint-disable sort-keys */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable template-curly-spacing */
/* eslint-disable no-useless-escape */
import React, { Component, } from "react";
import { withLocalize, } from "react-localize-redux";
import { Input, } from "../../../../../components";
import {
	isMatch,
	setErrorClass,
	setErrorsFromServer,
} from "../../../../../utils/helpers";
import { MagicSwitch, } from "../../../../../../tima/components/Magic/MagicSwitch";
import {
	MagicConfirm,
	MagicTooltip,
} from "../../../../../../tima/components/Magic";

class CreateCountryGroup extends Component {
	constructor (props) {
		super(props);

		this.state = {
			confirmClose: false,
			countryGroup: {
				group: [],
				is_forbidden: 0,
			},
			country_search: "",
			errors: [],
		};
	}

	save = state => new Promise(next => this.setState(state, next));

	onInputChange = async (event) => {
		const { name, value, } = event.target;

		await this.save({ [name]: value, });
	};

	handleOnChange = async (event, fieldName) => {
		const { name, value, } = event.target;

		try {
			switch (fieldName) {
				case "name": {
					await this.props.setCountryGroup(
						{
							...this.props.countryGroup,
							name: value,
						},
						name,
					);
					break;
				}
				case "rate": {
					await this.props.setCountryGroup(
						{
							...this.props.countryGroup,
							rate: value.replace(/[^0-9\.]/g, ""),
						},
						name,
					);
					break;
				}
				default: {
					break;
				}
			}
		} catch (e) {
			await setErrorsFromServer(e);
		}
	};

	renderCountriesList = (countries = []) => {
		const { uniqueFormDataCountries, index: groupIndex, } = this.props;
		const uniqueCountriesUsed = uniqueFormDataCountries(
			(item, index) => index !== groupIndex,
		);

		return countries?.map?.((item, index1) => {
			return this.renderCountry(item, index1, uniqueCountriesUsed);
		});
	};

	renderCountry = (country, index1, uniqueCountriesUsed) => {
		const {
			countryGroup, fieldsIsNotEdit, index, showPopUp,
		} = this.props;
		const countryId = country?.id;
		const tooltipOptions = {
			content: country.name ?? "-",
			lengthCut: 20,
		};

		const tooltip = <MagicTooltip {...tooltipOptions} />;
		const forbiddenCountry = country.is_work === 2;
		const selectedCountryInForbiddenGroup =
			countryGroup.is_forbidden && countryGroup?.group.includes(countryId) && forbiddenCountry;
		const selectedCountry = uniqueCountriesUsed.includes(countryId);
		const selectedNotForbiddenCountry =
			uniqueCountriesUsed.includes(countryId) && !forbiddenCountry;

		return (
			<div className="payout-rate-list__switches" key={countryId}>
				<MagicSwitch
					className={`magic-switch magic-switch--inline payout-rate-list__country 
            ${forbiddenCountry ? "forbidden-switch" : ""} 
            ${selectedCountryInForbiddenGroup ? "forbidden-switch" : ""}
            ${selectedNotForbiddenCountry ? "selected-country" : ""}
         
            `}
					classNameIcon={`magic-switch__icon forbidden-switch
    
            ${selectedCountryInForbiddenGroup ? "bg-red" : ""}
            ${selectedCountry ? "bg-bold-gray " : ""} 
		 
         
   
     
           
            `}
					index={+countryGroup?.group.includes(countryId)}
					isDisabled={country?.isDisabled || fieldsIsNotEdit || showPopUp}
					key={countryId}
					reverse={false}
					text={tooltip}
					updateStateFromProps={true}
					values={[ 0, 1, ]}
					onChange={async (options) => {
						const newCountryGroup = {
							...countryGroup,
							group: options.value
								? [ ...countryGroup?.group, countryId, ]
								: countryGroup?.group?.filter(_ => _ !== countryId),
						};

						await this.props.setCountryGroup(newCountryGroup);
						await this.props.selectedCountryId(countryId, `group_${index}`);
					}}
				/>
			</div>
		);
	};

	renderCountries () {
		const { translate, countries, formData, } = this.props;
		const noDataText = translate("symbols_empty");
		const filteredCountries = countries
			.filter((country) => {
				const value = this.state.country_search;

				return !value || isMatch(country.name, value.toLowerCase());
			})
			.sort((a, b) => a.name.localeCompare(b.name));
		const matchedCountries = filteredCountries.filter((country) => {
			const value = this.state.country_search;
			const matchedCountry = isMatch(country.name, value.toLowerCase());

			return !value || matchedCountry;
		});

		return (
			<>
				{!matchedCountries.length ? (
					<div className="table__empty-text no-data-payout-rate-list">
						{noDataText}
					</div>
				) : (
					<div
						className={`payout-rate-list__groups custom-horizontal-scroll mb-20`}
					>
						{this.renderCountriesList(matchedCountries, formData)}
					</div>
				)}
			</>
		);
	}

	renderConfirm = ({
		onAccept, onReject, isVisible, translate,
	}) => {
		const options = {
			accept: translate?.accept,
			isVisible,
			onAccept,
			onReject,
			reject: translate?.reject,
		};

		return <MagicConfirm title={this.props.translate(`magic_confirm_title`)} {...options} />;
	};

	render () {
		const { country_search, } = this.state;

		const {
			index,
			translate,
			errors,
			countryGroup,
			condition,
			removeCountryGroup,
			fieldsIsNotEdit,
			showPopUp,
		} = this.props;
		const notSelectedCondition = Object.values(condition).includes(true);
		const disabledName =
			countryGroup.name.length > 20 && fieldsIsNotEdit
				? `${countryGroup.name.slice(0, 20)}...`
				: countryGroup.name;
		const inputNameLabel = countryGroup.is_forbidden
			? translate("partners_payout_rate-list_forbidden-label")
			: `${translate("partners_payout_rate-list_name-label")} ${index}`;

		return (
			notSelectedCondition && <div key={`group_${index}`}>
				<div className="payout-rate-list__forbidden-group mt-20 ">
					<div className="forbidden-switch-block">
						{countryGroup.is_forbidden ? (
							<>
								<Input
									disabled={countryGroup?.is_forbidden}
									key={`name`}
									placeholder={translate("symbols_name_placeholder")}
									value={`${countryGroup.name}`}
									wrapperClassName={`payout-rate-list__name ${
										!countryGroup?.is_forbidden
											? setErrorClass(errors, `name_${index}`)
											: ""
									}`}
									onChange={event =>
										this.handleOnChange(event, `name_${index}`)
									}
									onValid={(value) => {
										const regExp = /^[a-zA-Z0-9а-яА-ЯіІїЇ\s\.\,|/\-\!\@\#\$\&\%\^\\*\(\)]{0,100}$/g; // not ready

										if (value !== "" && !regExp.test(value)) {
											throw new Error("xxx");
										}
									}}
									isRequired={true}
									name="name"
								/>
								<div className="forbidden-switch-block_icon">
									<span className="forbidden-switch-block_tooltip">
										{inputNameLabel}
									</span>
									<MagicSwitch
										isDisabled={countryGroup?.is_forbidden}
										text={""}
										onChange={event => this.onInputChange(event)}
									/>
								</div>
							</>
						) : (
							<div className="group_name_tooltip">
								<Input
									disabled={
										countryGroup?.is_forbidden || fieldsIsNotEdit || showPopUp
									}
									isRequired={true}
									key={`name_${index}`}
									label={inputNameLabel}
									name={`name_${index}`}
									placeholder={translate("symbols_name_placeholder")}
									value={`${disabledName}`}
									wrapperClassName={`payout-rate-list__name ${
										!countryGroup?.is_forbidden
											? setErrorClass(errors, `name_${index}`)
											: ""
									}`}
									onChange={event => this.handleOnChange(event, "name")}
									onValid={(value) => {
										const regExp = /^[a-zA-Z0-9а-яА-ЯіІїЇ\s\.\,|/\-\!\@\#\$\&\%\^\\*\(\)]{0,100}$/g; // not ready

										if (value !== "" && !regExp.test(value)) {
											throw new Error("xxx");
										}
									}}
								/>
								{fieldsIsNotEdit ? (
									<span className="group_name-switch-block_tooltip">
										{countryGroup.name}
									</span>
								) : null}
							</div>
						)}
					</div>
					{condition.CPL ? (
						<Input
							disabled={
								countryGroup?.is_forbidden || fieldsIsNotEdit || showPopUp
							}
							isRequired={true}
							key={`rate_${index}`}
							label={translate("partners_payout_rate-list_payout_rate_block_cpl")}
							name={`rate_${index}`}
							placeholder={translate("partners_payout_rate-list_payout-rate")}
							value={`${countryGroup.rate}`}
							wrapperClassName={`payout-rate-list__search_name ${setErrorClass(
								errors,
								`rate_${index}`,
							)}`}
							onChange={event => this.handleOnChange(event, "rate")}
							onValid={(value) => {
								if (value === "") {
									return;
								}

								const re = /^-?[0-9]+(?:.[0-9]{0,2})?$/;

								if (!re.test(value)) {
									throw new Error("xxx");
								}
								if ((/^0[0-9]/).test(value)) {
									throw new Error("xxx");
								}
								if (+value > "1000") {
									throw new Error("x1xx");
								}
							}}
						/>
					) : null}
					<Input
						label=""
						name="country_search"
						placeholder={translate("partners_payout_rate_list_search_name")}
						type="text"
						value={`${country_search}`}
						wrapperClassName="search payout-rate-list__search_name mt-20"
						onChange={event => this.onInputChange(event)}
					/>
					{countryGroup?.is_forbidden || fieldsIsNotEdit || showPopUp ? null : (
						<button
							className="payout-rate-list__create-btn_btns-btn payout-rate-list__create-btn_btns-btn-remove "
							onClick={() => this.setState({ confirmClose: true, })}
						>
							{translate("partners_payout_rate-list_remove_allowed_countries")}
						</button>
					)}
					{
						<span style={{ position: "relative", }}>
							{" "}
							{this.renderConfirm({
								onAccept: () =>
									removeCountryGroup() && this.save({ confirmClose: false, }),
								onReject: () => this.save({ confirmClose: false, }),
								isVisible: this.state?.confirmClose === true,
								translate: {
									accept: this.props.translate("support_confirm_accept"),
									reject: this.props.translate("support_confirm_reject"),
								},
							})}
						</span>
					}
				</div>
				{notSelectedCondition && this.renderCountries()}
				<div className={!countryGroup ? "payout-rate-list__underline" : ""} />
			</div>

		);
	}
}

export default withLocalize(CreateCountryGroup);