import React, { Component } from 'react';

export default class PermissionsPageAnchor extends Component {
    constructor (props) {
        super(props);
        this.state = {
            data: this.props.data,
        };
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        this.setState({
            data: nextProps.data,
        });
    }

    render () {

        return (
            <React.Fragment>
                <ul className = 'anchor'>
                    {Object.keys(this.state.data).map((item, index) => {
                        return (
                            <li key = { index }>
                                <a href = 'javascript:void(0)' id = { index+1 }>{index+1}</a>
                            </li>);
                    })}

                </ul>
            </React.Fragment>

        );
    }

}
