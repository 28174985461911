import {
	isEmptySelectValue,
	isEmptyString,
} from "../../../../../../utils/helpers";

import {
	tradingServers,
} from "../../consts/consts";

async function validateOnSubmit (state, save, condition) {
	const errors = Object.keys(state.formData).reduce((total, field) => {
		if (field === "name") {
			if (
				state.formData[field].length < 3 ||
				state.formData[field].length > 100
			) {
				total = [ ...total, field, ];
			}
		} else if (field === "description") {
			if (state.formData[field].length > 1000) {
				total = [ ...total, field, ];
			}
		} else if (
			field === "name" ||
			field === "level" ||
			field === "type" ||
			field === "period" ||
			field === "latency"
		) {
			if (
				isEmptySelectValue(state.formData[field]) ||
				isEmptyString(state.formData[field])
			) {
				total = [ ...total, field, ];
			}
		}
		if (condition.CPA) {
			if (
				field === "referrer_country_check" ||
				field === "first_deposit_fixing_hours" ||
				field === "first_deposit_confirmation_period" ||
				field === "payout_rate_list_id"
			) {
				if (
					isEmptySelectValue(state.formData[field]) ||
					isEmptyString(state.formData[field])
				) {
					total = [ ...total, field, ];
				}
			}
		}
		if (condition.REVSHARE) {
			if (
				field === "grace_period" ||
				field === "payout_rate_for_grace_period" ||
				field === "min_req_fullfillment_period"
			) {
				if (
					isEmptySelectValue(state.formData[field]) ||
					isEmptyString(state.formData[field])
				) {
					total = [ ...total, field, ];
				}
			}
		}

		return total;
	}, []);

	await save({ errors, });

	return errors.length === 0;
}

////CPA VALIDATIONS

async function validateFtdCpaSingleBlock (state, save, condition, symbolGroupListOptions
) {
	if (!condition.CPA) {
		return true;
	}
	const { first_deposit_conditions, } = { ...state.formData, }; //...this.state.formData
	const errorsOnSubmit = [];

	const indexesOfConditionsWithoutGroup = first_deposit_conditions
		.map((condition, index) => !condition[0].symbol_group_id ? index : "")
		.filter(index => index !== "");

	const indexesOfEmptyVolumes = first_deposit_conditions
		.map(
			(condition, index) =>
				condition.find(group => !+group.volume)
					? condition.map(
						(group, subIndex) =>
							!+group.volume ? `${ index }_${ subIndex }` : "",
					  )
					: "",
		)
		.flat()
		.filter(index => index !== "");

	const indexesOfConditionWithEmptyDeals = first_deposit_conditions.map((condition, index) => {
		const { symbol_group_id, deals, } = condition[0];
		const groupIsMt5 = symbolGroupListOptions.find(option => option.value === symbol_group_id)?.server_name.includes(tradingServers.MT5);

		return groupIsMt5 && !deals ? index : '';
	}).filter(index => index !== "");

	if (indexesOfConditionsWithoutGroup.length > 0) {
		indexesOfConditionsWithoutGroup.forEach(index =>
			errorsOnSubmit.push(`symbol_group_${ index }`),
		);
	}

	if (indexesOfEmptyVolumes.length > 0) {
		indexesOfEmptyVolumes.forEach(index =>
			errorsOnSubmit.push(`volume_${ index }`),
		);
	}

	if (indexesOfConditionWithEmptyDeals.length > 0) {
		indexesOfConditionWithEmptyDeals.forEach(index =>
			errorsOnSubmit.push(`deals_${ index }`),
		);
	}

	if (errorsOnSubmit.length > 0) {
		await save({
			//this.
			errors: [ ...state.errors, ...errorsOnSubmit, ],
		});

		return false;
	}

	return true;
}

async function validatePayoutRangeRateCpa (state, save, condition) {
	if (!condition.CPA) {
		return true;
	}

	const { payment_coef_cpa, } = { ...state.formData, };
	const lastIndex = payment_coef_cpa.length - 1;
	const errorsOnSubmit = [];

	if (!payment_coef_cpa[lastIndex].deposits_from) {
		errorsOnSubmit.push(`deposits_from${ lastIndex }`);
	}
	if (+payment_coef_cpa[lastIndex].deposits_from > 100_000) {
		errorsOnSubmit.push(`deposits_from${ lastIndex }`);
	}

	if (
		!payment_coef_cpa[lastIndex].coef ||
		payment_coef_cpa[lastIndex].coef > 1000
	) {
		errorsOnSubmit.push(`coef${ lastIndex }`);
	}

	if (
		+payment_coef_cpa[lastIndex].deposits_from <=
		+payment_coef_cpa[lastIndex - 1]?.deposits_from
	) {
		errorsOnSubmit.push(`deposits_from${ lastIndex }`);
	}

	if (
		+payment_coef_cpa[lastIndex].coef <= +payment_coef_cpa[lastIndex - 1]?.coef
	) {
		errorsOnSubmit.push(`coef${ lastIndex }`);
	}

	if (errorsOnSubmit.length > 0) {
		await save({
			//this.
			errors: [ ...state.errors, ...errorsOnSubmit, ],
		});

		return false;
	}

	return true;
}
async function validatePayoutRangeRateCpaOnAdd (state, save, condition) {
	if (!condition.CPA) {
		return true;
	}

	const { payment_coef_cpa, } = { ...state.formData, };
	const lastIndex = payment_coef_cpa.length - 1;
	const errorsOnSubmit = [];

	if (!payment_coef_cpa[lastIndex].deposits_from) {
		errorsOnSubmit.push(`deposits_from${ lastIndex }`);
	}
	if (+payment_coef_cpa[lastIndex].deposits_from > 99999.99) {
		errorsOnSubmit.push(`deposits_from${ lastIndex }`);
	}

	if (
		!payment_coef_cpa[lastIndex].coef ||
		payment_coef_cpa[lastIndex].coef > 999.99
	) {
		errorsOnSubmit.push(`coef${ lastIndex }`);
	}

	if (
		+payment_coef_cpa[lastIndex].deposits_from <=
		+payment_coef_cpa[lastIndex - 1]?.deposits_from
	) {
		errorsOnSubmit.push(`deposits_from${ lastIndex }`);
	}

	if (
		+payment_coef_cpa[lastIndex].coef <= +payment_coef_cpa[lastIndex - 1]?.coef
	) {
		errorsOnSubmit.push(`coef${ lastIndex }`);
	}

	if (errorsOnSubmit.length > 0) {
		await save({
			//this.
			errors: [ ...state.errors, ...errorsOnSubmit, ],
		});

		return false;
	}

	return true;
}

////// REVSHARE VALIDATIONS
async function validateRenumerationRatesRevshare (state, save, condition, accountOptions) {
	if (!condition.REVSHARE) {
		return true;
	}
	const { payout_rates_revshare, } = { ...state.formData, };

	const lastIndex = payout_rates_revshare.length - 1;
	const groupListIsMT5 = accountOptions.find(option => option.value === payout_rates_revshare[lastIndex].groups_list_id)?.server_name?.includes(tradingServers.MT5);

	const errorsOnSubmit = [];

	if (!payout_rates_revshare[lastIndex].symbols_list_id) {
		errorsOnSubmit.push(`symbols_list_id_${ lastIndex }`);
	}
	if (!payout_rates_revshare[lastIndex].groups_list_id) {
		errorsOnSubmit.push(`groups_list_id_${ lastIndex }`);
	}
	if (
		!payout_rates_revshare[lastIndex].reward_by_point_lot &&
		!payout_rates_revshare[lastIndex].reward_by_volume_lot &&
		!payout_rates_revshare[lastIndex].reward_percent_of_commission
	) {
		errorsOnSubmit.push(`remuneration_rates_${ lastIndex }`);
	}
	if (payout_rates_revshare[lastIndex].reward_by_point_lot === "0") {
		errorsOnSubmit.push(`reward_by_point_lot_${ lastIndex }`);
	}
	if (payout_rates_revshare[lastIndex].reward_by_volume_lot === "0") {
		errorsOnSubmit.push(`reward_by_volume_lot_${ lastIndex }`);
	}
	if (payout_rates_revshare[lastIndex].reward_percent_of_commission === "0") {
		errorsOnSubmit.push(`reward_percent_of_commission_${ lastIndex }`);
	}
	if (!payout_rates_revshare[lastIndex].deals && groupListIsMT5) {
		errorsOnSubmit.push(`deals_${ lastIndex }`);
	}

	if (errorsOnSubmit.length > 0) {
		await save({
			errors: [ ...state.errors, ...errorsOnSubmit, ],
		});

		return false;
	}

	return true;
}

async function validatePayoutPercentageRevshare (state, save, condition) {
	if (!condition.REVSHARE) {
		return true;
	}

	const { payment_coef_revshare, } = { ...state.formData, };
	const lastIndex = payment_coef_revshare.length - 1;
	const errorsOnSubmit = [];
	const sumOfInputs =
		+payment_coef_revshare[lastIndex].registrations +
		+payment_coef_revshare[lastIndex].verifications +
		+payment_coef_revshare[lastIndex].active_clients +
		+payment_coef_revshare[lastIndex].new_active_clients;
	const sumOfInputsPrev =
		+payment_coef_revshare[lastIndex - 1]?.registrations +
		+payment_coef_revshare[lastIndex - 1]?.verifications +
		+payment_coef_revshare[lastIndex - 1]?.active_clients +
		+payment_coef_revshare[lastIndex - 1]?.new_active_clients;

	if (
		!payment_coef_revshare[lastIndex].registrations ||
		+payment_coef_revshare[lastIndex].registrations >= 100000 ||
		+payment_coef_revshare[lastIndex].registrations <
			+payment_coef_revshare[lastIndex - 1]?.registrations
	) {
		errorsOnSubmit.push(`registrations_${ lastIndex }`);
	}
	if (
		!payment_coef_revshare[lastIndex].verifications ||
		+payment_coef_revshare[lastIndex].verifications >= 100000 ||
		+payment_coef_revshare[lastIndex].verifications <
			+payment_coef_revshare[lastIndex - 1]?.verifications
	) {
		errorsOnSubmit.push(`verifications_${ lastIndex }`);
	}
	if (
		!payment_coef_revshare[lastIndex].active_clients ||
		+payment_coef_revshare[lastIndex].active_clients >= 100000 ||
		+payment_coef_revshare[lastIndex].active_clients <
			+payment_coef_revshare[lastIndex - 1]?.active_clients
	) {
		errorsOnSubmit.push(`active_clients_${ lastIndex }`);
	}
	if (
		!payment_coef_revshare[lastIndex].new_active_clients ||
		+payment_coef_revshare[lastIndex].new_active_clients >= 100000 ||
		+payment_coef_revshare[lastIndex].new_active_clients <
			+payment_coef_revshare[lastIndex - 1]?.new_active_clients
	) {
		errorsOnSubmit.push(`new_active_clients_${ lastIndex }`);
	}
	if (
		!payment_coef_revshare[lastIndex].coef ||
		+payment_coef_revshare[lastIndex].coef > 1000 ||
		+payment_coef_revshare[lastIndex].coef <=
			+payment_coef_revshare[lastIndex - 1]?.coef
	) {
		errorsOnSubmit.push(`coef_${ lastIndex }`);
	}
	if (sumOfInputs <= sumOfInputsPrev) {
		errorsOnSubmit.push(`sum_error_${ lastIndex }`);
	}

	if (errorsOnSubmit.length > 0) {
		await save({
			errors: [ ...state.errors, ...errorsOnSubmit, ],
		});

		return false;
	}

	return true;
}
async function validatePayoutRangeBlockInOut (state, save, condition) {
	if (!condition.IN_OUT) {
		return true;
	}
	const { payout_rate_for_the_estimated_amount, min_inout_reminder, } = {
		...state.formData,
	};
	const errorsOnSubmit = [];

	if (!payout_rate_for_the_estimated_amount) {
		errorsOnSubmit.push("payout_rate_for_the_estimated_amount");
	}

	if (!+min_inout_reminder) {
		errorsOnSubmit.push("min_inout_reminder");
	}

	if (errorsOnSubmit.length > 0) {
		await save({
			//this.
			errors: [ ...state.errors, ...errorsOnSubmit, ],
		});

		return false;
	}

	return true;
}

async function validatePayoutRangeListInOut (state, save, condition) {
	if (!condition.IN_OUT) {
		return true;
	}

	const { payment_coef_inout, } = { ...state.formData, };
	const lastIndex = payment_coef_inout.length - 1;
	const errorsOnSubmit = [];

	if (!+payment_coef_inout[lastIndex].sum_inout_from) {
		errorsOnSubmit.push(`sum_inout_from${ lastIndex }`);
	}

	if (
		!payment_coef_inout[lastIndex].coef &&
		payment_coef_inout[lastIndex].coef !== 0
	) {
		errorsOnSubmit.push(`coef${ lastIndex }`);
	}

	if (
		+payment_coef_inout[lastIndex].sum_inout_from <=
		+payment_coef_inout[lastIndex - 1]?.sum_inout_from
	) {
		errorsOnSubmit.push(`sum_inout_from${ lastIndex }`);
	}

	if (
		+payment_coef_inout[lastIndex].coef <=
		+payment_coef_inout[lastIndex - 1]?.coef
	) {
		errorsOnSubmit.push(`coef${ lastIndex }`);
	}

	if (errorsOnSubmit.length > 0) {
		await save({
			//this.
			errors: [ ...state.errors, ...errorsOnSubmit, ],
		});

		return false;
	}

	return true;
}

async function validateRefferalCountryBlockCpl (state, save, condition) {
	if (!condition.CPL) {
		return true;
	}
	const {
		referrer_country_check,
		condition_lead_appears,
		payout_rate_list_id,
	} = { ...state.formData, };
	const errorsOnSubmit = [];

	if (!referrer_country_check) {
		errorsOnSubmit.push("referrer_country_check");
	}

	if (!condition_lead_appears) {
		errorsOnSubmit.push("condition_lead_appears");
	}

	if (!payout_rate_list_id) {
		errorsOnSubmit.push("payout_rate_list_id");
	}

	if (errorsOnSubmit.length > 0) {
		await save({
			//this.
			errors: [ ...state.errors, ...errorsOnSubmit, ],
		});

		return false;
	}

	return true;
}

async function validatePayoutRangeListCpl (state, save, condition) {
	if (!condition.CPL) {
		return true;
	}

	const { payment_coef_cpl, } = { ...state.formData, };
	const lastIndex = payment_coef_cpl.length - 1;
	const errorsOnSubmit = [];

	if (!+payment_coef_cpl[lastIndex].leads_from) {
		errorsOnSubmit.push(`leads_from_${ lastIndex }`);
	}

	if (
		!payment_coef_cpl[lastIndex].coef &&
		payment_coef_cpl[lastIndex].coef !== 0
	) {
		errorsOnSubmit.push(`coef_${ lastIndex }`);
	}

	if (
		+payment_coef_cpl[lastIndex].leads_from <=
		+payment_coef_cpl[lastIndex - 1]?.leads_from
	) {
		errorsOnSubmit.push(`leads_from_${ lastIndex }`);
	}

	if (
		+payment_coef_cpl[lastIndex].coef <= +payment_coef_cpl[lastIndex - 1]?.coef
	) {
		errorsOnSubmit.push(`coef_${ lastIndex }`);
	}

	if (errorsOnSubmit.length > 0) {
		await save({
			//this.
			errors: [ ...state.errors, ...errorsOnSubmit, ],
		});

		return false;
	}

	return true;
}

async function validatePayoutRatesByLevel (state, save, level) {
	if (!level.MULTI_LEVEL) {
		return true;
	}

	const { multi_rates_list_rate_by_level: multiRates, } = state.formData;
	const lastIndex = multiRates.length - 1;
	const errorsOnSubmit = [];

	if (
		!multiRates[lastIndex].level_from ||
		+multiRates[lastIndex].level_from < 2 ||
		+multiRates[lastIndex].level_from <= +multiRates[lastIndex - 1]?.level_to ||
		+multiRates[lastIndex].level_from <= +multiRates[lastIndex - 1]?.level_from
	) {
		errorsOnSubmit.push(`level_from_${ lastIndex }`);
	}

	if (
		!multiRates[lastIndex].level_to ||
		+multiRates[lastIndex].level_from < 2 ||
		+multiRates[lastIndex].level_to < +multiRates[lastIndex]?.level_from ||
		+multiRates[lastIndex].level_to <= +multiRates[lastIndex - 1]?.level_to
	) {
		errorsOnSubmit.push(`level_to_${ lastIndex }`);
	}

	if (
		isEmptyString(multiRates[lastIndex].rate) ||
		+multiRates[lastIndex].rate >= +multiRates[lastIndex - 1]?.rate
	) {
		errorsOnSubmit.push(`rate_${ lastIndex }`);
	}

	if (errorsOnSubmit.length > 0) {
		await save({
			//this.
			errors: [ ...state.errors, ...errorsOnSubmit, ],
		});

		return false;
	}

	return true;
}

export {
	validateOnSubmit,
	validatePayoutPercentageRevshare,
	validatePayoutRangeRateCpaOnAdd,
	validateRenumerationRatesRevshare,
	validateFtdCpaSingleBlock,
	validatePayoutRangeRateCpa,
	validatePayoutRangeListInOut,
	validatePayoutRangeBlockInOut,
	validateRefferalCountryBlockCpl,
	validatePayoutRangeListCpl,
	validatePayoutRatesByLevel,
};