import * as actions from './actionTypes';

const initialState = {
    client: {},
    output: {
        'take': 50,
        'skip': 0,
    },
    sort: {
        "f": 'core-account-created_at',
        "o": 'order_by',
        "v": 'desc',
    },
    enums:   {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.SET_CLIENT:
            return {
                ...state,
                ...state.clients,
                'client': action.data,
                'error': false
            };
        case actions.DELETE_CLIENT:
            return {
                ...state,
                ...state.clients,
                'client': action.data,
            };
        case actions.SET_ERROR:
            return {
                ...state,
                'error': true,
            };
        case actions.RESET_ERROR:
            return {
                ...state,
                'error': action.data,
            };
        case actions.GET_ENUMS:
            return {
                ...state,
                ...state.clients,
                'enums': {
                    ...state.enums,
                    ...action.data,
                },
            };
        case actions.GET_OPTIONS:
            return {
                ...state,
                ...state.clients,
                'options': {
                    ...state.options,
                    ...action.data,
                },
            };
        case actions.GET_TRADING:
            return {
                ...state,
                'tradingAccount': {
                    ...action.data.data,
                },
            };
        case actions.GET_SINGLE_TRADING:
            return {
                ...state,
                'singleTradingAccount': {
                    ...action.data.data,
                },
            };
        case actions.GET_TRANSACTIONS:
            return {
                ...state,
                'transactionsAccount': {
                    ...action.data.data,

                },
            };
        case actions.GET_INTERACTION:
            return {
                ...state,
                'accountInteraction': {
                    ...action.data,
                },
            };
        case actions.GET_APPEALS:
            return {
                ...state,
                'accountAppeals': {
                    ...action.data.data,
                },
            };
        case actions.UPDATE_APPEALS: 
            const updatedAppeals = {};

            for (const key in state.accountAppeals) {
                if (state.accountAppeals[key]['tasks-tasks-id'] === action.data['tasks-tasks-id']) {
                    updatedAppeals[key] = { ...state.accountAppeals[key], ...action.data }
                } else {
                    updatedAppeals[key] = { ...state.accountAppeals[key] }
                }
            }

            return {
                ...state,
                'accountAppeals': updatedAppeals,
            }
        case actions.GET_HISTORY:
            return {
                ...state,
                'accountHistory': {
                    ...action.data,
                },
            };
        case actions.GET_TAILS:
            return {
                ...state,
                'accountTails': {
                    ...action.data.data,
                },
            };
        case actions.GET_FILTER_ID :
            return {
                ...state,
                'filterId': action.data,
            };
        case actions.CLEAR_ALL_CLIENTS :
            return {
                ...state,
                ...state.clients,
                'data': action.data,
            };
        case actions.GET_NEW_CATEGORY :
            return {
                ...state,
                'newCategory': action.data,
            };
        case actions.SET_FILTER_CATEGORY:
            return {
                ...state,
                ...state.clients,
                'filterCategoryId': action.data
            };
        case actions.CLEAR_FILTER_CATEGORY:
            return {
                ...state,
                ...state.clients,
                'filterCategoryId': {}
            };
        case actions.FILTER_HASH:
            return {
                ...state,
                ...state.clients,
                'filterHash': action.data,
            };
        default:
            return state;
    }
}
