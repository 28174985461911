import {
	itemsBuild, itemsDrain, itemsResort, CFG_ITEM_TYPE,
} from "../Config";
// import {} from '../Mentor/withDropDown';

export default {
	id: 176,
	...[
		// filters
		...[
			{
				f: "core.account.id",
				id: "f:accountId",
			},
			{
				f: [ "core.account.name", "core.account.surname", ],
				id: "f:partnerName",
			},
			{
				f: "partners.accruals.partner_account",
				id: "f:partnerProgram",
			},
			{
				f: "partners.accruals.amount",
				id: "f:paAmount",
			},
			{
				f: "partners.prev_accruals.partner_id",
				id: "f:accountNumberId",
			},
			{
				f: "partners.prev_accruals.condition_id",
				id: "f:conditionId",
			},
			{
				f: "partners.new_partners_conditions.level",
				id: "f:conditionLevel",
			},
			{
				f: "partners.new_partners_conditions.type",
				id: "f:conditionType",
			},
			{
				f: "partners.accruals.status",
				id: "f:paStatus",
			},
			{
				f: "partners.accruals.created_at",
				id: "f:paCreated",
			},
			{
				f: "partners.accruals.updated_at",
				id: "f:paUdated",
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: "filter", ..._, })),

		// orders
		...[
			{
				f: "core.account.id",
				id: "o:accountId",
			},
			{
				f: "partners.prev_accruals.partner_id",
				id: "o:paAccountNumberId",
			},
			{
				f: "partners.prev_accruals.partner_id",
				id: "o:paConditionId",
			},
			{
				f: "partners.new_partners_conditions.level",
				id: "o:paConditionLevel",
			},
			{
				f: "partners.new_partners_conditions.type",
				id: "o:paConditionType",
			},
			{
				f: "core.account.surname",
				id: "o:aSurname",
			},
			{
				f: "partners.accruals.partner_account",
				id: "o:paAccount",
			},
			{
				f: "partners.accruals.amount",
				id: "o:paAmount",
			},
			{
				f: "partners.accruals.acc_ccy",
				id: "o:paCurrency",
			},
			{
				f: "partners.accruals.status",
				id: "o:paStatus",
			},
			{
				f: "partners.accruals.created_at",
				id: "o:paCreated",
			},
			{
				f: "partners.accruals.updated_at",
				id: "o:paUdated",
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: "order", ..._, })),

		// panels
		...[
			{
				ii: "f:accountId",
				t: "partners_create_referral_partner_id",
			},
			{
				ii: "f:partnerName",
				t: "partners_list_partner_name",
			},
			{
				ii: "f:partnerProgram",
				t: "partners_accruals_table_partner_acccount",
			},
			{
				ii: "f:paAmount",
				t: "partners_accruals_table_amount",
				// off: true,
			},
			{
				ii: "f:accountNumberId",
				t: "partners_accruals_table_partner_program_id",
			},
			{
				ii: "f:conditionId",
				t: "partners_accruals_table_partner_condition_id",
			},
			{
				ii: "f:conditionLevel",
				t: "partners_accruals_table_partner_condition_level",
			},
			{
				ii: "f:conditionType",
				t: "partners_accruals_table_partner_condition_type",
			},
			{
				ii: "f:paStatus",
				t: "partners_accruals_table_status",
			},
			{
				ii: "f:paCreated",
				t: "partners_accruals_table_created_at",
			},
			{
				ii: "f:paUdated",
				t: "partners_accruals_table_updated_at",
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: "panel", ..._, })),
	]
		.map(itemsBuild)
		.reduce(itemsDrain, [])
		.reduce(itemsResort, {}),

	path: "partners/accruals",
	widget: false,
};