import React, { Component }                     from 'react';
import { withRouter }                           from 'react-router-dom';
import { withLocalize }                         from 'react-localize-redux';
import { Breadcrumbs }                          from '../../tima/components/Breadcrumbs';
import * as configBreadcrumbs                   from './SupportLinkBreadcrumbsConfig';
import {
  MagicFilterPanels,
  Mentor,
} from '../../../components/Magic';
import ListSupport                              from './ListSupport';
import OneSupportRequest                        from './OneSupportRequest/OneSupportRequest';
import '../styles/support.scss';
import { enumService, } from "../../../services/EnumDataService";
import { profileService, } from "../../../services/ProfileDataService";

const TAB_ID = {
  support: 'support',
  supports: 'supports',
};
Object.freeze(TAB_ID);

const breadcrumbs = (translate, activeTab, {supportId}) => {
  const config = configBreadcrumbs;

  switch ( activeTab ) {
    case TAB_ID.support:
      return config.breadcrumbs4Support(translate, supportId);
    default:
    case TAB_ID.supports:
      return config.breadcrumbs4Supports(translate);
  }
};

class Support extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: {
        isTechnicalSupport: false,
        isROP: false,
        name: '',
        surname: '',
        profileID: 0,
      },
      ticketTitle: '',
    };

    const mf = new Mentor({
      owner: this,
      serviceId: 216,
      translate: this.props.translate,
    });

    Object.defineProperty(this, 'mf', { get: () => mf, });
  };

  enumsChange = async (enums) => {
    if (enums.hash===this.state.enum?.hash) return `${this.constructor.name}.enumsChange: false`;
    await this.save({ enums, });
    return `${this.constructor.name}.enumsChange: true`;
  };

  profileChange = async (profile) => {
    if (profile.hash===this.state?.profile?.hash) return `${this.constructor.name}.profileChange: false`;
    await this.save(({ profile: p, }) => ({
      profile: { ...p, ...this.checkProfile(profile), },
    }));
    return `${this.constructor.name}.profileChange: true`;
  };

  async componentDidMount() {
    enumService.subscribe('enums', this.enumsChange, this);
    profileService.subscribe('profile', this.profileChange, this);
    await Promise.all([
      enumService.enums,
      profileService.profile
    ]);
  }

  componentWillUnmount() {
    enumService.unsubscribe('enums', this.enumsChange, this);
    profileService.unsubscribe('profile', this.profileChange, this);
  }

  save = async (state) => new Promise(resolve => this.setState(state, resolve));

  isEmpty = val => val === null || !(Object.keys(val) || val).length;

  checkProfile(profile) {
    const { tree_info: profileDepartments, } = profile.data;
    const departments = profileDepartments?.map?.(d => d?.department_alias) ?? [];
    const isTechnicalSupport = departments.includes('technical_support');
    const isROP = departments.includes('administrator_sales');
    const { id: profileID, name, surname, } = profile.data;
    return {
      hash: profile.hash,
      isTechnicalSupport, isROP,
      profileID, name, surname,
    };
  }

  getTitleForOneTicket = title => this.setState({ticketTitle: title});

  params = () => this.props.match.params;

  query = () => new URLSearchParams(this.props.location.search);

  pagination = () => {
  };

  render() {
    const renderContent = (translate, active, params, query) => {
      switch ( active ) {
        case TAB_ID.support:
          return (
            <React.Fragment>
              <div className="content-block">
                <div className="top-page-block">
                  <div>
                    <h1 className={ 'page-title' }>{ this.state.ticketTitle }</h1>
                    <Breadcrumbs items={ breadcrumbsItems }/>
                  </div>
                </div>
              </div>
              <OneSupportRequest
                enums={this.state?.enums?.data}
                getTitleForOneTicket={ this.getTitleForOneTicket }
                profile={ this.state.profile }
              />
            </React.Fragment>
          );
        default:
        case TAB_ID.supports:
          return (
            <React.Fragment>
              <div className="content-block">
                <div className="top-page-block">
                  <div>
                    <h1 className={ 'page-title' }>{ translate('support_list_title') }</h1>
                    <Breadcrumbs items={ breadcrumbsItems }/>
                  </div>
                </div>
              </div>

              <MagicFilterPanels
                mf={ this.mf }
                show={ true }
                translate={ translate }
              />

              <ListSupport
                query={this.query()}
                enums={this.state?.enums?.data}
                mf={ this.mf }
                profile={ this.state.profile }
              />
            </React.Fragment>
          );
      }
    };

    const {activeTab, translate} = this.props;
    const breadcrumbsItems = breadcrumbs(translate, activeTab, this.params());
    const showMagicFilterPanels = [TAB_ID.supports].includes(activeTab);

    return (
      <div className={ `support` }>
        {/* <MagicFilterPanels service={ this.service } show={ showMagicFilterPanels } /> */ }
        { renderContent(translate, activeTab, this.params(), this.query()) }
      </div>
    );
  };
}

export default withRouter(withLocalize(Support));
