import { operators as allOperators, } from '../Operators';
import { Storage, } from '../Storage';

/**
 * Validate StorageItemFilter constructor arguments
 *
 * @function
 * @arg {Object} data - Object with settings
 * @arg {Storage} storage - Storage instance
 * @arg {Object} $$ - Object with argument to bind StorageItemFilter's
 * @returns {undefined} throw Error if any validation failed
 */
export const valid = (data, storage, $$) => {

  const msg = text => new Error(`Expect StorageItemFilter${ text }`);

  if (!(data instanceof Object)) {
    throw msg(`(data, ..., ...) [${ data }] as Object`);
  }

  const {
    $,
    enabled, f, o, v,
    j, 'drop-down': dropDown, having, sub_where,
    fields,
    ..._
  } = data;

  if (!($ instanceof Object)) {
    throw msg(`.$ [${ $ }] as Object`);
  } else if (![ undefined, 0, 1, ].includes($.enabled)) {
    throw msg(`.$.enabled [${ $.enabled }] in {0, 1}`);
  } else if (
    ![ undefined, ].includes($.group)
    &&
    ![ 'string', ].includes(typeof $.group)
  ) {
    throw msg(`.$.group [${ $.group }] as String`);
  } else if (![ 'string', ].includes(typeof $.id)) {
    throw msg(`.$.id [${ $.id }] as String`);
  } else if (![ 'filter', ].includes($.type)) {
    throw msg(`.$.type [${ $.type }] in {"filter"}`);
  }

  if (![ 0, 1, ].includes(enabled)) {
    throw msg(`.enabled [${ enabled }] in {0, 1}`);
  }

  const re = /^(?:[_\w]+[.]){1,2}[_\w]+$/;
  // eslint-disable-next-line no-shadow
  const field = f => re.test(f);

  if ([ 'string', ].includes(typeof f)) {
    if (!field(f)) {
      throw msg(`.f [${ f }] like "schema.table.field"`);
    }
  } else if (Array.isArray(f)) {
    if (!f.length || !f.every(field)) {
      throw msg(`.f [${ f }] like ["schema.table.field"]`);
    }
  } else {
    throw msg(`.f [${ f }] as String`);
  }

  if (![ 'string', ].includes(typeof o)) {
    throw msg(`.o [${ o }] as String`);
  } else if (![ ...allOperators.aliases, ].includes(o)) {
    throw msg(`.o [${ o }] in allOperators.aliases`);
  }

  // eslint-disable-next-line no-shadow
  const value = v => v === null || [ 'number', 'string', ].includes(typeof v);

  if (Array.isArray(v)) {
    if (!v.every(value)) {
      throw msg(`.v as [Value]`);
    }
  } else if (!value(v)) {
    throw msg(`.v [${ v }] as Value`);
  }

  if (![ 'n', 'y', ].includes(j)) {
    throw msg(`.j [${ j }] in {"n", "y"}`);
  }

  if (dropDown === false) {
    // dropDown disabled
  } else if ([ 'string', ].includes(typeof dropDown)) {
    try {
      // eslint-disable-next-line no-new, no-restricted-globals
      new URL(dropDown, location.href);
    } catch (error) {
      throw msg(`.dropDown ${ dropDown } as URL`);
    }
  } else if (![ undefined, ].includes(dropDown)) {
    throw msg(`.dropDown [${ dropDown }] as String`);
  }

  if (![ undefined, 0, 1, ].includes(having)) {
    throw msg(`.having [${ having }] in {0, 1}`);
  }

  if (![ undefined, 0, 1, ].includes(sub_where)) {
    throw msg(`.sub_where [${ sub_where }] in {0, 1}`);
  }

  if (fields instanceof Object) {
    try {
      valid(fields, storage, { ...$$, });
    } catch (error) {
      throw msg(`.fields do ${ error }`);
    }
  } else if (![ undefined, ].includes(fields)) {
    throw msg(`.fields as Object`);
  }

  if (!(_ instanceof Object)) {
    throw msg(`._ expect as Object`);
  // } else if (Object.keys(_).length) {
  //   throw msg(`._ expect is {}`);
  }

  if (!(storage instanceof Object)) {
    throw msg(`(..., storage, ...) [${ storage }] as Object`);
  } else if (!(storage instanceof Storage)) {
    throw msg(`(..., storage, ...) [${ storage }] as Storage`);
  }

  if (!($$ instanceof Object)) {
    throw msg(`(..., ..., $$) as Object`);
  } else if ($$.changed && ![ false, true, ].includes($$.changed)) {
    throw msg(`(..., ..., $$.changed) [${ $$.changed }] as {Boolean | null}`);
  } else if ($$.child && !($$.child instanceof Function)) {
    throw msg(`(..., ..., $$.child) [${ $$.child }] as {Function | null}`);
  } else if ($$.inited && ![ false, true, ].includes($$.inited)) {
    throw msg(`(..., ..., $$.inited) [${ $$.inited }] as {Boolean | null}`);
  } else if ($$.fields && !($$.fields instanceof Object)) {
    throw msg(`(..., ..., $$.fields) [${ $$.fields }] as {Object | null}`);
  } else if ($$.parentId && ![ 'string', ].includes(typeof $$.parentId)) {
    throw msg(`(..., ..., $$.parentId) [${ $$.parentId }] as {String | null}`);
  }

};