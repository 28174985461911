export const promoChildren = [
	{
		path: [ "partners", "promo", ],
		to: "/partners/promo/banners",
	},
	{
		to: '/partners/promo/target-page',
		path: [ 'partners', 'target_pages', ],
	},
	{
		to: '/partners/promo/logos',
		path: [ 'partners', 'logos', ],
	},
	{
		to: '/partners/promo/lending',
		path: [ 'partners', 'lending', ],
	},
];