import { map } from 'lodash';
import { connect } from "react-redux";
import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { withRouter } from 'react-router-dom';
import { withLocalize } from "react-localize-redux";

import { Table } from "reactstrap";
import { Translate } from 'react-localize-redux';
import { Scrollbars } from "react-custom-scrollbars";
import HistoryChangesComponentRow from "./HistoryChangesComponentRow";
import Preloader from '../../components/LoadingHOC/Preloader';

class HistoryChangesComponentTable extends Component {
    render () {

        if (!this.props.data) {
            return <Preloader scale = { 1 } />;
        }

        return (
            <Translate>
                {({ translate }) => {
                    return (
                        <Scrollbars style = { { height: 390 } } >
                            <Table className = 'table-stripped table-ubordered'>
                                <thead>
                                    <tr>
                                        <th>{ translate('historyChanges__Date_and_Time') }</th>
                                        <th>{ translate('historyChanges__Author') }</th>
                                        <th>{ translate('historyChanges__Field') }</th>
                                        <th>{ translate('historyChanges__Before_Change') }</th>
                                        <th>{ translate('historyChanges__After_Change') }</th>
                                        <th>{ translate('historyChanges__Message') }</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {map(this.props.data, (item, key) => {
                                        return (<HistoryChangesComponentRow item = { item } key = { key } />);
                                    })}
                                </tbody>
                            </Table>
                        </Scrollbars>
                    );
                }}
            </Translate>
        );
    }
}

export default withLocalize(withRouter(connect(
    null,
    (dispatch) => bindActionCreators({}, dispatch)
)(HistoryChangesComponentTable)));
