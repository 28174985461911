import React from 'react';

export default class ButtonArea extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
        };
    }

    render () {
        return (
            <div className = 'button-area-block'>
                <div className = 'gradient-button task-create-save' onClick = { this.props.sendData }>{this.props.translate(`tasks_createform_save`)}</div>
                <div className = 'gradient-border-button'>
                    <span onClick = { this.props.closeTask }>{this.props.translate(`tasks_createform_cancel`)}</span>
                </div>
            </div>
        );
    }
}
