import React, { Component, } from "react";
import { withRouter, } from "react-router-dom";
import { withLocalize, } from "react-localize-redux";

import { PAYMENTS_EXPORT_FIELDS, } from "./consts/PAYMENTS_EXPORT_FIELDS";

import PermissionService from "../../../../services/PermissionService";
import { securedRequest, } from "../../../../services/RequestService";
import { showExportToExcelError, } from "../../../../components/Magic";
import { Mentor, } from "../../../../components/Magic";

import { HorizontalMenu, } from "../../components";
import PartnersSystemHeader from "../ components/PartnersSystemHeader";

import AffiliatePayoutsTable from "./table/AffiliatePayoutsTable";
import * as configBreadcrumbs from "./config/BreadcrumbsConfig"
class AffiliatePayouts extends Component {
	constructor (props) {
		super(props);

		this.state = {
			filter_id: 0,
			showPopup: false,
		};
	}

	get isUsedInPartnerCard(){
		return this.props.accountId //has this prop only inside partnerCard component
	}

	save = async state => new Promise(next => this.setState(state, next));

	handleFilterIdChange = filter_id => this.save({ filter_id, });

	exportToExcel = activeTab => (event) => {
		if (activeTab) {
			const { filterId: filter_id, } = this.state;

			const globalUrl = `/api/partners/accruals`;
			const exportUrl = `/export-to-excel`;
			let url = "";
			let file_name = "";

			switch (activeTab) {
				case "payouts": {
					url = `${ globalUrl }${ exportUrl }`;
					file_name = `partner-payouts`;
					break;
				}
				default: {
					break;
				}
			}
			const options = {
				fields: PAYMENTS_EXPORT_FIELDS[activeTab],
				file_name,
			};

			if (filter_id) {
				options.filter_id = filter_id;
			}
			
			securedRequest
				.post(url, options)
				.then((res) => {
					fileDownload(res.data, res.headers.filename);
				})
				.catch((error) => {
					showExportToExcelError(error);
				});
		}
	};

	renderAffiliatePayoutsActions = (translate) => {
		const permissionsAccruals = PermissionService.calc([
			{
				path: [ "partners", "accruals", ],
				key: "user",
			},
		]);

		const excel = permissionsAccruals.user.access("exportToExcel");
		const createAccrual = permissionsAccruals.user.access('store');

		return (
			<div className=" top-button-area ">
				{excel && (
					<div
						className="button bordered "
						onClick={ this.exportToExcel("payouts") }
					>
						{translate(`managerPage_export_to_excel`)}
					</div>
				)}
				{createAccrual && <div
					className="button button--gradient-pink ml-3"
					onClick={ () => this.save({ showPopup: true, }) }
				>
					{translate("partners_create_accrual_btn")}
				</div>}
			</div>
		);
	};

	render () {
		const { translate, accountId, } = this.props;
		const breadcrumbsItems = configBreadcrumbs.breadcrumbs4AffiliatePayouts;

		return (
			<div className="affiliate-payouts">
				{
					this.isUsedInPartnerCard ?
						<div className={ 'd-flex justify-content-end mb-5' }>
							{this.renderAffiliatePayoutsActions(translate)}
						</div>
						:
						<>
							<PartnersSystemHeader
								actions={this.renderAffiliatePayoutsActions(translate)}
								breadcrumbsItems={breadcrumbsItems}
								translate={translate}
								title={'partners_accruals_header'}
							/>
							<HorizontalMenu />
						</>
				}
				<AffiliatePayoutsTable
					popupClose={ () => this.save({ showPopup: false, }) }
					showPopup={ this.state.showPopup }
					handleFilterIdChange={this.handleFilterIdChange}
					accountId={accountId}
				/>
			</div>
		);
	}
}

export default withRouter(withLocalize(AffiliatePayouts));