import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import ModalDepartment from "./ModalDepartment";
import DeleteTreeItem from "./DeleteTreeItem";
import plus from '../../../../../images/plus-blue.svg';
import edit from '../../../../../images/edit-small.svg';
import defaultAvatar from "../../../../../images/profile_default.jpg";
import { Modal } from "reactstrap";
import { withLocalize } from "react-localize-redux";
import PermissionService from "../../../../../services/PermissionService";
import Preloader from "../../../../../components/LoadingHOC/Preloader";
import Loader from "../../../../tima/components/Loader";
import { hierarchyService } from "../../../../../services/HierarchyDataService";
import { userService } from "../../../../../services/UserDataService";

class Tree extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            loaded: false,
            tree: {
                data: [],
                hash: null,
            },
            modalTask:   false,
            fadeIn: false,
            modal: false,
            departmentEdit : undefined,
            users: {
                data: [],
                hash: null,
            },
            avatars: {
                hash: null,
                data: {},
            },
            departments: {
                hash: null,
                departmentsOpt: [],
                departmentsId: {},
            },
        };
    }

    get configTree () {
        return [
            {
                path:   ['core', 'hierarchy_tree', 'name'],
                key:    'permissionRenderTree',
                render: this.renderTree,
            }
        ];
    }

    save = async (state) => new Promise(next => this.setState(state, next));

    treeChanges = async (tree) => {
        if (tree.hash===this.state?.tree?.hash) return `${this.constructor.name}.treeChanges: false`;
        await this.save({ tree });
        return `${this.constructor.name}.treeChanges: true`;
    };

    usersChanges = async (users) => {
        if (users.hash===this.state?.users?.hash) return `${this.constructor.name}.usersChanges: false`;
        await this.save({ users });
        return `${this.constructor.name}.usersChanges: true`;
    };

    departmentsChanges = async (newDepartments) => {
        if (newDepartments.hash===this.state?.departments?.hash) return `${this.constructor.name}.departmentsChanges: false`;
        const departmentsOpt = [];
        const departmentsId = {};
        newDepartments.departments?.data?.forEach((item) => {
            departmentsOpt.push({ label: item.core.departments.name, value: item.core.departments.id });
            departmentsId[item.core.departments.id] = item.core.departments.name;
        });
        await this.save({
            departments: {
                ...this.state.departments,
                departmentsOpt,
                departmentsId,
            },
        });
        return `${this.constructor.name}.departmentsChanges: true`;
    };

    avatarsChange = async (avatars) => {
        if (avatars.hash===this.state?.avatars?.hash) return `${this.constructor.name}.avatarsChange: false`;
        await this.save({ avatars });
        return `${this.constructor.name}.avatarsChange: true`;
    };

    async componentDidMount () {
        hierarchyService.subscribe('tree', this.treeChanges, this);
        hierarchyService.subscribe('crmUsers', this.usersChanges, this);
        hierarchyService.subscribe('departments', this.departmentsChanges, this);
        userService.subscribe('getAllAvatar', this.avatarsChange, this);
        await this.loadData();
    }

    async componentWillUnmount () {
        hierarchyService.unsubscribe('tree', this.treeChanges, this);
        hierarchyService.unsubscribe('crmUsers', this.usersChanges, this);
        hierarchyService.unsubscribe('departments', this.departmentsChanges, this);
        userService.unsubscribe('getAllAvatar', this.avatarsChange, this);
    }

    loadData = async () => {
        await this.save({ loaded: false, });
        try {
            const departmentsOpt = [];
            const departmentsId = {};
            const newTreeResponse = await hierarchyService.tree();
            const newUsersResponse = await hierarchyService.crmUsers();
            const getAvatarResponse = await userService.getAllAvatar();
            const getDepartmentsResponse = await hierarchyService.departments();

            getDepartmentsResponse.departments?.data?.forEach((item) => {
                departmentsOpt.push({ label: item.core.departments.name, value: item.core.departments.id });
                departmentsId[item.core.departments.id] = item.core.departments.name;
            });

            await this.save({
                tree:    newTreeResponse,
                users:   newUsersResponse,
                avatars: getAvatarResponse,
                departmentsOpt,
                departmentsId,
            });
        } catch (error) {
            error?.showErrorNotification?.();
        }
        await this.save({ loaded: true, });
    };

    getUpdatedTree = async () => {
        await this.save({ loaded: false, });
        try {
            const newTreeResponse = await hierarchyService.tree();
            const newUsersResponse = await hierarchyService.crmUsers();

            await this.save({
                tree: newTreeResponse,
                users: newUsersResponse,
            });
        } catch (error) {
            error?.showErrorNotification?.();
        }
        await this.save({ loaded: true, });
    };

    deleteDepartment = async (id) => {
        await this.save({ loaded: false, });
        try {
            await hierarchyService.deleteTeam(id);
            this.getUpdatedTree();
        } catch (error) {
            error?.showErrorNotification?.();
        }
        await this.save({ loaded: true, });
    };

    getRenderTeamName = (item, parentId, access) => {
        return (
            <div className = 'team-name'>
                <span className={`team-name-block ${item.merge.department !== null ? item.merge.department.alias : undefined}`}>{ item.name }</span>
                {
                    access ? <div className="actions">
                        <img onClick = { () => {this.modalTask(item, parentId)} } src={edit} alt="edit"/>
                        {!item.merge.users && !item.merge.user && item.children.length === 0 ?
                            <DeleteTreeItem
                                deleteDepartment = { this.deleteDepartment }
                                id = { item.id }
                            />
                            : null}
                    </div> : null
                }
            </div>
        );
    };

    getRenderUser = (item) => item?.merge?.user ? <div className="leader-name">{item.merge.user.name} {item.merge.user.surname}</div> : null;

    getRenderDepartment = (item) => item?.merge?.department ? <div className={ `leader-department ${item.merge.user == null ? 'title' : ''}` }>{item.merge.department.name}</div> : null;

    getRenderImage = (item) => {
        return item?.merge?.department && item?.merge?.user ? <div className="tree-leader-avatar">
            <img src={ this.state.avatars.data[item.merge.user.id] ? `data:"image/jpeg";base64,${this.state.avatars.data[item.merge.user.id]}` : defaultAvatar } alt="avatar"/></div>
            : null;
    };

    getRenderUsers = (users) => {
        return users?.map?.((user, key) => {
            return (
                <div className='team-member' key={ key }>
                    <div className='team-member-avatar'>
                        <img src={ this.state.avatars.data[user.id] ? `data:"image/jpeg";base64,${this.state.avatars.data[user.id]}` : defaultAvatar } alt="avatar"/>
                    </div>
                    <div>{user.name} {user.surname}</div>
                </div>
            );
        });
    };

    getRenderThree = (data, parentId = null, access = true) => {
        const children = [];
        data?.map?.((item, key) => {
            children.push(
                <li key={key}>
                    <div className='tree-block'>
                        {this.getRenderTeamName(item, parentId, access)}
                        <div className='team-leader'>
                            {this.getRenderImage(item)}
                            <div>
                                {this.getRenderUser(item)}
                                {this.getRenderDepartment(item)}
                            </div>
                        </div>
                        <Scrollbars style={{height: 160, margin: '5px 0'}}>
                            {this.getRenderUsers(item.merge.users)}
                        </Scrollbars>
                        {
                            access ? <div className="add-team" onClick={() => {
                                this.modalTask(undefined, item.id)
                            }}>
                                <img src={plus} alt="plus"/> {this.props.translate(`hierarchy_add_subteam`)}
                            </div> : null
                        }
                    </div>
                    {item.children.length > 0 ? this.getRenderThree(item.children, item.id, access) : null}
                </li>
            );
        });

        return <ul>{children}</ul>;
    };

    modalTask = async (department = undefined, parentId = undefined) => {
        await this.save({
            modal:          !this.state.modal,
            departmentEdit: department,
            parentId,
        });
    };

    renderTree = () => {
        const permissionData = PermissionService.calc(this.configTree);
        if (permissionData.permissionRenderTree.access('index', 'show', 'update', 'store')) {
            return (
                <div className='tree'>
                    { this.state?.tree.data?.length ? this.getRenderThree(this.state.tree.data) : this.renderParentDepartment() }
                </div>
            );
        } else if (permissionData.permissionRenderTree.access('show')) {
            return (
                <div className='tree'>
                    { this.state?.tree.data?.length ? this.getRenderThree(this.state.tree.data, null, false) : null }
                </div>
            );
        } else {
            return null;
        }
    };

    renderParentDepartment = () => {
        return (
            <div className="add-team new-team" onClick={() => this.modalTask()}>
                <img src={plus} alt="plus"/>
                <p>{this.props.translate(`hierarchy_add_subteam`)}</p>
            </div>
        );
    };

    render () {
        const { loaded = false } = this.state;

        return (
            <Loader
                loaded={loaded}
                loading={(<Preloader scale={1}/>)}
            >
                <div>
                    <Modal isOpen={this.state.modal} className='tree-modal'>
                        <ModalDepartment
                            appeals={false}
                            close={this.modalTask}
                            taskId={this.state.modal}
                            departmentEdit={this.state.departmentEdit}
                            users={this.state.users.data}
                            parentId={this.state.parentId}
                            departmentsOpt={this.state.departmentsOpt}
                            departmentsId={this.state.departmentsId}
                            getUpdatedTree={this.getUpdatedTree}
                        />
                    </Modal>
                    { this.renderTree() }
                </div>
            </Loader>
        );
    }
}
export default withLocalize(Tree);
