import React, { Component, } from 'react';
import { Link, withRouter, } from "react-router-dom";
import { withLocalize, } from "react-localize-redux";
import { getAvailableWidgetsInfo, } from "../../../dashboard/services/DashboardRequestService";
import * as configBreadcrumbs from "./Config/BreadcrumbsConfig";
import Breadcrumbs from "../../../tima/components/Breadcrumbs";
import { profileService, } from "../../../../services/ProfileDataService";
import UserRequestWidget from "./userRequestWidget";

class ActivityReport extends Component {

	constructor (props) {
		super(props);

		this.state = {
			advancedFilters: {},
			availableWidgets: null,
			categoryId: 1,
			isHeadOfDepartment: -1,
			managersDropdown: [],
			reset: false,
		};

	}
	save = async state => new Promise(next => this.setState(state, next));

	profileChange = async (profile) => {
		if (profile.hash===this.state?.profile?.hash) {
			return `${ this.constructor.name }.profileChange: false`;
		}
		await this.save({ profile, });

		return `${ this.constructor.name }.profileChange: true`;
	};

	componentDidMount = async () => {
		profileService.subscribe('profile', this.profileChange, this);
		await profileService.profile;
		await this.getAvailableWidgets();
	};
	getAvailableWidgets = async () => {
		try {
			const response = await getAvailableWidgetsInfo();

			const availableWidgets = {};

			response?.data?.forEach((item) => {
				availableWidgets[item.core.widget.name] = true;
			});

			await this.save({ availableWidgets, });
		} catch (error) {
			console.error(error);
		}
	}

	componentWillUnmount = () => {
		profileService.unsubscribe('profile', this.profileChange, this);
	};
	render () {
		const { availableWidgets, permission_template_id, userId, } = this.state;
		const { translate, } = this.props;
		const breadcrumbsItems = configBreadcrumbs.breadcrumbsForActivityReport(translate);

		return (
			<>
				<h1 className="activity_report_title">{translate('menu_admin_activity_report')}</h1>
				<div className="breadcrumbs_activity_report">
					<Link className="activity_report_breadcrumb_item" to="/settings">{this.props.translate(`menu_admin_panel`)} > </Link>
					<Link className="activity_report_breadcrumb_item" to="/settings/activity_report">{this.props.translate(`menu_admin_activity_report`)}</Link>
				</div>
				<div>
					{availableWidgets?.['user-request'] ? (
						<UserRequestWidget
							permissionTemplateId={ permission_template_id }
							userId={ userId }
						/>
					) : ''}
				</div>
			</>
		);
	}
}

export default withRouter(withLocalize(ActivityReport));