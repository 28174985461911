export default (
		translate,
		order = [],
) => [
	{
		id: `dashboard`,
		link: `/dashboard`,
		text: translate('dashboard'),
	},
	{
		id: `general`,
		link: `/list-accounts`,
		text: translate('list_accounts'),
	},
	{
		id: `balance-operations`,
		link: `/list-accounts/balance-operations`,
		text: translate('balance_operations'),
	},
].filter(
	({ id, }) => order.includes(id),
).sort(
	({ id: a, }, { id: b, }) => order.indexOf(a) - order.indexOf(b),
)