import React from 'react';
import { withLocalize } from "react-localize-redux";
import DatePicker from 'react-date-picker';
import moment from "moment";
import PropTypes from "prop-types";
import { map } from "lodash";
import TaskBlock from "./TaskBlock";

class CalendarDayMagic extends React.Component {
    static propTypes = {
        changeSelectedDateWithRequest: PropTypes.func.isRequired,
        prevDay:                       PropTypes.func.isRequired,
        nextDay:                       PropTypes.func.isRequired,
    };

    constructor (props) {
        super(props);

        this.state = {
            tasks:        props.tasks,
            selectedDate: new Date(props.selectedDate), // native Date format for DatePicker
            locale:       props.activeLanguage?.code,
        };
    }

    componentDidMount () {

    }

    save = async (state) => new Promise((next) => this.setState(state, next));

    handleChangeData = async (date) => {
        await this.save({ selectedDate: date });
        this.props.changeSelectedDateWithRequest(date);
    };

    renderCalendarHours = (tasksAll) => {
        return (
            <div className='hour-rows-wrapper'>
                {map(tasksAll, (tasks, indexHour) => this.renderCalendarHour(tasks, indexHour))}
            </div>
        );

    };

    renderCalendarHour = (tasks, indexHour) => {
        const { avatar } = this.props;
        const dateFormat = "HH:00";
        const startDate = moment().startOf('date');
        const currentHour = moment().hour();

        const isActiveClass = moment(startDate).add(indexHour, 'hours').hour() === currentHour ? 'active' : '';

        return (
            <div className='hour-row' key={indexHour}>
                <div className='hour-row__hour-value-wrapper'>
                    <div className='hour-row__hour-value'>
                        {moment(startDate).add(indexHour, 'hours').format(dateFormat)}
                    </div>
                </div>
                <div
                    className={`hour-row__hour-task calendar-day-task-wrapper ${isActiveClass}`}>
                    {
                        (tasks instanceof Array) && tasks.map((item, index) => {
                            return (
                                <TaskBlock
                                    avatar={avatar}
                                    i={index}
                                    item={item}
                                    key={`task-block-${indexHour}-${index}`}
                                />
                            );
                        })
                    }
                </div>
            </div>
        );
    };

    render () {
        const { selectedDate, locale, tasks } = this.state;
        const selectedDay = moment(selectedDate).get('date');
        const selectedDayName = moment(selectedDate).locale(locale).format('dddd');
        const selectedMonth = moment(selectedDate).locale(locale).format('MMMM');
        const selectedYear = moment(selectedDate).get('year');

        return (
            <div className='table-wrapper'>
                <div className='position-relative'>
                    <div className='hour-wrapper'>
                        <div className='calendar-day-header'>
                            <div className='day-header-wrapper'>
                                <div className='icon' onClick={this.props.prevDay}>
                                    <i className='la la-angle-left'/>
                                </div>

                                <p className='picked-date-params'>
                                    <span className='chosen-date'>{selectedDay}</span>
                                    <span className='chosen-date-params'>
                                        {`${selectedDayName}, ${selectedMonth} ${selectedYear}`}
                                    </span>
                                </p>

                                <div className='icon' onClick={this.props.nextDay}>
                                    <i className='la la-angle-right'/>
                                </div>
                            </div>

                            <DatePicker
                                value={selectedDate}
                                onChange={this.handleChangeData}
                                format='dd.MM.y'
                                locale={locale}
                                calendarClassName={'react-datepicker__month-container'}
                                className={'react-datepicker calendar_custom_header white'}
                                clearIcon={null}
                                calendarIcon={null}
                            />
                        </div>
                        {this.renderCalendarHours(tasks)}
                    </div>
                </div>
            </div>
        );
    }
}

export default withLocalize(CalendarDayMagic);
