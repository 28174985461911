import {
  itemsBuild,
  itemsDrain,
  itemsResort,
  CFG_ITEM_TYPE,
} from '../Config';

export default {
  id: 161,
  ...[

    // filters
    ...[ {
      f: 'partners.symbols_groups.id',
      id: 'f:psgId',
    }, {
      f: 'partners.symbols_groups.name',
      id: 'f:psgName',
    }, {
      f: 'core.dictionary_trade_servers.type',
      id: 'f:psgServer',
    }, {
      f: 'partners.symbols_groups.created_at',
      id: 'f:psgCreated',
    }, {
      f: 'partners.symbols_groups.updated_at',
      id: 'f:psgUpdated',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

    // orders
    ...[ {
      f: 'partners.symbols_groups.id',
      id: 'o:id',
    }, {
      f: 'partners.symbols_groups.name',
      id: 'o:name',
    }, {
      f: 'partners.symbols_groups.amount',
      id: 'o:amount',
    }, {
      f: 'core.dictionary_trade_servers.server_name',
      id: 'o:server',
    }, {
      f: 'partners.symbols_groups.created_at',
      id: 'o:created',
    }, {
      f: 'partners.symbols_groups.updated_at',
      id: 'o:updated',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

    // panels
    ...[ {
      ii: 'f:psgId',
      t: 'symbols_id',
    }, {
      ii: 'f:psgName',
      t: 'symbols_name',
    }, {
      ii: 'f:psgServer',
      t: 'symbols_server',
    }, {
      ii: 'f:psgCreated',
      t: 'symbols_created_at',
    }, {
      ii: 'f:psgUpdated',
      t: 'symbols_updated_at',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

  ]
  .map(itemsBuild)
  .reduce(itemsDrain, [])
  .reduce(itemsResort, {}),

  path: 'partners/symbols-groups',
  widget: false,
};
