import React, { Component } from 'react';
import { withLocalize } from "react-localize-redux";
import { withRouter } from 'react-router-dom';
import ManagerNotificationRow from "./ManagerNotificationRow";
import { enumService } from "../../../../../services/EnumDataService";
import {profileService} from "../../../../../services/ProfileDataService";
import '../../styles/_my-profile-notifications-list.scss';
import { Link } from "react-router-dom";
import Preloader from "../../../../../components/LoadingHOC/Preloader";
import { userService } from "../../../../../services/UserDataService";

const imgUrl = "/style-images/";
class ManagerNotification extends Component {

    constructor(props)
    {
        super(props);

        this.state = {
            loaded: false,
            enums: null,
            profile: null,
            data: null,
        };
    }

    save = async state => new Promise(next => this.setState(state, next));

    enumsChange = async(enums) => {
        if (enums.hash===this.state?.enums?.hash) {
            return;
        }
        await this.save({ enums, });
    };

    profileChange = async(profile) => {
        if (profile.hash===this.state?.profile?.hash) {
            return `${this.constructor.name}.profileChange: false`;
        }

        await this.save({ profile });
        return `${this.constructor.name}.profileChange: true`;
    };

    componentWillUnmount()
    {
        enumService.unsubscribe('enums', this.enumsChange);
        profileService.unsubscribe('profile', this.profileChange);
    }

    async componentDidMount()
    {
        const enums = await enumService.enums;
        const profile = await profileService.profile;
        await this.save({ enums, profile });

        const { data } = await userService.userEventSettings();
        this.setState({ data });

        await this.save({ loaded: true });
    }

    renderBreadCrumbs = () => {
        const { translate } = this.props;

        return (
            <div className='breadcrumbs'><Link to='/settings/profile'>{translate(`managerPage_profile`)}</Link> >{translate('events_notifications')}</div>
        );
    };

    render() {
        const { translate } = this.props;

        if (!this.state.loaded) {
            return <Preloader scale = { this.props.scale } />;
        }

        return (
            <div className = 'my-profile-notification-wrapper'>
                <div className='top-page-block'>
                    <div className='title-wrapper'>
                        <h1 className='page-title'>{translate('events_notifications')}</h1>
                        { this.renderBreadCrumbs() }
                    </div>
                </div>
                <div className="notification-top-button">
                    <Link className="magic-button button" to='/settings/profile'>{translate('managerPage_account')}</Link>
                    <Link className="magic-button button active" to='/settings/profile/notifications'>{translate('events_notifications')}</Link>
                </div>
                <div className="block-notification-shadowed">
                    <div className='bottom-content-block'>
                        <div className={'notifications-tooltips d-flex align-items-end justify-content-end'}>
                            <div className={'notifications-tooltips__item notifications-tooltips__item_push'}>
                                <p className={'notifications-tooltips__title'}>{translate('events_push_notifications')}</p>
                                <ul className={'notifications-tooltips__body'}>
                                    <li className={'mb-2 d-flex align-items-center'}><span className={'icon icon_push icon_active mr-2'}></span>{translate('events_notifications_push_and_notifications')}</li>
                                    <li className={'mb-2 d-flex align-items-center'}><span className={'icon icon_push icon_active-and-show mr-2'}></span>{translate('events_notifications_notifications_only')}</li>
                                    <li className={'d-flex align-items-center'}><span className={'icon icon_push icon_disable mr-2'}></span>{translate('events_notifications_disabled')}</li>
                                </ul>
                            </div>
                            <div className={'notifications-tooltips__item tooltips__item_email'}>
                                <p className={'notifications-tooltips__title'}>{translate('events_email_notifications')}</p>
                                <ul className={'notifications-tooltips__body'}>
                                    <li className={'mb-2 d-flex align-items-center'}><span className={'icon icon_email icon_active mr-2'}></span>{translate('events_notifications_enabled')}</li>
                                    <li className={'d-flex align-items-center'}><span className={'icon icon_email icon_disable mr-2'}></span>{translate('events_notifications_disabled')}</li>
                                </ul>
                            </div>
                        </div>
                        {
                            this.state.data?.map?.((item, index) => {
                                return (
                                    <div key={index} className = 'my-profile-notification-wrap'>
                                        <div className = 'my-profile-notification-wrap__title'>
                                            <img
                                                className={'my-profile-notification-wrap__img'}
                                                src={`${imgUrl}notification-type-${item.type}.svg`}
                                                alt="event"
                                            />
                                            <p>{this.state.enums?.data?.core?.EventTypeEnum[item.type]}</p>
                                        </div>

                                        {item?.data?.map?.((value) => {
                                            return (
                                                <ManagerNotificationRow
                                                    key={value.event_id}
                                                    item={value}
                                                    profile={this.state.profile.data}
                                                />
                                            )
                                        })}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default withLocalize(withRouter(ManagerNotification));
