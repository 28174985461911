import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { MagicTable } from "../../../../components/Magic";
import { Link } from 'react-router-dom';

class DistributionTable extends React.Component {
    get tableConfig () {
        const { translate } = this.props;

        return [
            {
                accessChecked: true,
                key:           'userName',
                path:          ['user', 'name'],
            },
            {
                accessChecked: true,
                key:           'userSurname',
                path:          ['user', 'surname'],
            },
            {
                accessChecked: true,
                key:           'isConfirm',
                path:          ['confirm'],
                render:        this.renderIsConfirm,
                title:         'is_avaliable',
            },
            {
                accessChecked: true,
                key:           'userId',
                path:          ['user', 'id'],
                render:        this.renderInline,
                title:         'support_list_id',
                order:         true,
            },
            {
                accessChecked: true,
                key:           'fullName',
                render:        this.renderName,
                title:         'managerPage_fullname',
                order:         true,
            },
            {
                accessChecked: true,
                key:           'count',
                path:          ['briefcase_count'],
                render:        this.renderInline,
                title:         'briefcase',
                order:         true,
            },
            {
                accessChecked: true,
                key:           'rate',
                path:          ['conversion_rate'],
                render:        this.renderInline,
                title:         'conversion',
                order:         true,
            },
            {
                accessChecked: true,
                key:           'processing',
                path:          ['portfolio_processing'],
                render:        this.renderInline,
                title:         'processing_table',
                order:         true,
            },
            {
                accessChecked: true,
                key:           'throughput',
                path:          ['user', 'throughput'],
                render:        this.renderInline,
                title:         'delegation_filter_throughput',
                order:         true,
            },
            {
                accessChecked: true,
                key:           'adaptation',
                path:          ['user', 'adaptation'],
                render:        this.renderAdaptation,
                title:         'managerPage_adaptation',
                order:         true,
            },
            {
                accessChecked: true,
                key:           'status',
                path:          ['user', 'status'],
                render:        this.renderStatus,
                title:         'contest_list-members_status',
                order:         true,
            },
            {
                accessChecked: true,
                key:           'dailyLoad',
                path:          ['user', 'count_active_task'],
                render:        this.renderInline,
                title:         'daily_load',
                order:         true,
            }
        ].map(({ title: t, ...item }) => (
            {
                ...item,
                ...t && { title: item.key === 'rate' || item.key === 'processing' ? `${translate(t)}, %` : translate(t) },
            }
        ));
    }

    renderIsConfirm = (item) => {
        const { translate } = this.props;

        return item ? (
            <span style = { { color: '#0b0' } }>{translate('template_management_yes')}</span>
        ) : (
            <span style = { { color: '#b00' } }>{translate('template_management_no')}</span>
        );
    }

    renderName = (item, { items }) => {
        return <Link to = { `/settings/user/${items.userId.valueOf}` }>{items.userName.valueOf} {items.userSurname.valueOf}</Link>;
    };

    renderInline = (item) => {
        return `${item ?? '-'}`;
    }

    renderAdaptation = (item) => {
        const { translate } = this.props;

        return item === 2 ? translate('template_management_no') : translate('template_management_yes');
    }

    renderStatus = (item) => {
        const { translate } = this.props;

        return item === 1 ? translate('client_active') : translate('client_inactive');
    }

    render () {
        const config = {
            data:   this.props.data,
            config: this.tableConfig,
            head:   [
                'isConfirm',
                'userId',
                'fullName',
                'count',
                'rate',
                'processing',
                'throughput',
                'dailyLoad',
                'adaptation',
                'status'
            ],
        };

        return (
            <MagicTable { ...config } />
        );
    }
}

export default withLocalize(DistributionTable);
