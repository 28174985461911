import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import { marketingService } from '../../../services/MarketingDataService';
import { Mentor, MagicFilterPanels, showExportToExcelError, MagicButton } from '../../../components/Magic';
import Loader from '../../tima/components/Loader';
import Preloader from '../../../components/LoadingHOC/Preloader';
import PagePanel from '../../tima/components/Pages';
import MarketingStatisticsClicksMagicTable from './Table/MarketingStatisticsClicksMagicTable';
import { securedRequest } from '../../../services/RequestService';
import PermissionService from '../../../services/PermissionService';
import { GET_ACCOUNT_UTM_LINK } from '../../../apiRoutes';
import { CLIENTS_LIST_UTM_EXPORT_FIELDS } from '../constants/constants';

class MarketingStatisticsClicks extends Component {
  constructor (props) {
    super(props);

    this.state = {
      accountUtmLink: {
        data: [],
        hash: null,
      },
      loaded: false,
      pages: {
        filter: 0,
        total: 0,
        take: 50,
        variance: 2,
      },
      filterLastChange: Date.now(),
    };

    const mf = new Mentor({
      owner: this,
      serviceId: 701,
      translate: this.props.translate,
    });

    Object.defineProperty(this, 'mf', { get: () => mf, });
    this.props.mf(mf);
  };

  get accountUtmLink () {
    return [{
      key: 'accountUtmLink',
      path: ['statistics', 'account_utm_link',],
    },];
  };

  save = async state => new Promise(next => this.setState(state, next));

  mfChanged = async () => {
    await this.save({ filterLastChange: Date.now(), });
  };

  mfDoing = async () => {
    await this.save({ loaded: false, });
  };

  mfDone = async () => {
    await this.onDataLoad();
  };

  utmLinkChanges = async ({
    data,
    hash,
    options
  }) => {
    if (hash === this.state?.accountUtmLink?.hash) {
      return `${ this.constructor.name }.accountUtmLinkChanges: false`;
    } else if (options.filterId !== this.state?.accountUtmLink?.options?.filterId) {
      return `${ this.constructor.name }.accountUtmLinkChanges: false`;
    } else if (options.take !== this.state?.accountUtmLink?.options?.take) {
      return `${ this.constructor.name }.accountUtmLinkChanges: false`;
    } else if (options.skip !== this.state?.accountUtmLink?.options?.skip) {
      return `${ this.constructor.name }.accountUtmLinkChanges: false`;
    }

    await this.save({
      accountUtmLink: {
        data: data.data,
        hash,
        options,
      },
      pages: {
        ...this.state.pages,
        filter: data?.meta?.filter,
        total: data?.meta?.total,
      },
    });
    return `${ this.constructor.name }.accountUtmLinkChanges: true`;
  };

  async componentDidMount () {
    marketingService.subscribe('accountUtmLink', this.utmLinkChanges, this);
    this.mf.subscribe({
      changed: this.mfChanged,
      cleaned: this.mfDone,
      cleaning: this.mfDoing,
      loaded: this.mfDone,
      loading: this.mfDoing,
      saved: this.mfDone,
      saving: this.mfDoing,
    }, this);
    await this.mf.init({ doEmit: true, });
  };

  componentWillUnmount () {
    marketingService.unsubscribe('accountUtmLink', this.utmLinkChanges, this);
    this.mf.unsubscribe([
      this.mfChanged,
      this.mfDoing,
      this.mfDone,
    ], this);
  };

  exportFile = () => {
    const { filterId: filter_id, } = this.mf;
    const globalUrl = `${ GET_ACCOUNT_UTM_LINK }`;
    const exportUrl = `export-to-excel`;
    const url = `${ globalUrl }/${ exportUrl }`;
    const options = {
      file_name: `export_clients_utm`,
      fields: CLIENTS_LIST_UTM_EXPORT_FIELDS['export_clients_utm'],
    };

    if (filter_id) {
      options['filter_id'] = filter_id;
    }

    securedRequest.post(url, options)
      .then(() => {
        //  console.log('ok');
      })
      .catch((error) => {
        showExportToExcelError(error);
      });
  };

  renderButtonExport = () => {
    const conf = PermissionService.calc(this.accountUtmLink);

    if (conf?.accountUtmLink.access('exportToExcel')) {
      const options = {
        className: 'magic-button__item magic-button__item_export',
        children: this.props.translate('contests_toollbar_export'),
        onClick: () => this.exportFile(),
      };

      return (<MagicButton { ...options } />);
    }
    return '';
  };

  render = () => {
    const { translate, } = this.props;
    const data = this.state?.accountUtmLink?.data;

    return (
      <div>
        <div className="account-utm-link-btn-export">
          {this.renderButtonExport()}
        </div>
        <MagicFilterPanels
          mf={ this.mf }
          show={ true }
          translate={ translate }
        />
        <div className="content-block">
          <div className="table-wrapper">
            <div className="position-relative">
              <Loader
                loaded={ this.state.loaded }
                loading={ (<Preloader scale={ this.props.scale }/>) }
              >
                <MarketingStatisticsClicksMagicTable
                  data={ data }
                  enums={ this.props?.enums }
                  mf={ this.mf }
                  onDataLoad={ () => this.onDataLoad() }
                />
                <PagePanel
                  filter={ this.state.pages.filter }
                  take={ this.state.pages.take }
                  total={ this.state.pages.total }
                  variance={ this.state.pages.variance }
                  page={ this.pageId() }
                  onClick={ this.onPageClick }
                  doText={ this.onPageText }
                  doTo={ (pageId) => `?page=${ pageId }` }
                />
              </Loader>
            </div>
          </div>
        </div>
      </div>
    );
  };

  pageId = () => {
    const { query } = this.props;
    const page = query.get('page');
    return Number(page) || 0;
  };

  pageIdAsGET = (pageId) => {
    pageId = pageId === undefined ? this.pageId() : pageId;
    const { filterId, } = this.mf;
    return {
      filterId,
      take: this.state.pages.take,
      skip: pageId * this.state.pages.take,
    };
  };

  onDataLoad = async (pageId) => {
    const pageOptions = this.pageIdAsGET(pageId);
    await this.save({
      loaded: false,
      accountUtmLink: {
        ...this.state.accountUtmLink,
        options: pageOptions,
      },
    });
    try {
      await marketingService.accountUtmLink(pageOptions);
    } catch (error) {
      console.log('error: ', error);
      await this.save({
        utmSource: {
          data: [],
          hash: null,
          options: {},
        },
        pages: {
          ...this.state.pages,
          filter: 0,
          total: 0,
        }
      });
    }
    await this.save({ loaded: true, });
  };

  onPageClick = ({
    event,
    pageId,
    pageIs,
  }) => {
    this.onDataLoad(pageId);
  };
}

export default withRouter(withLocalize(MarketingStatisticsClicks));
