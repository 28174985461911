const GLOBAL_PATH_API = '/api';
const GLOBAL_PATH_CORE = `${GLOBAL_PATH_API}/core`;

/*trading-accounts*/
export const GET_TRADING_ACCOUNTS = `${GLOBAL_PATH_CORE}/trading-accounts`;
export const GET_TRADING_ACCOUNTS_4_ACCOUNT = `${GLOBAL_PATH_CORE}/trading-accounts/get-trading-accounts`;
export const GET_TRADING_ACCOUNTS_CLOSED_ORDERS = `${GET_TRADING_ACCOUNTS}/get-closed-orders-mt4`;
export const GET_TRADING_ACCOUNTS_OPEN_ORDERS = `${GET_TRADING_ACCOUNTS}/get-open-orders-mt4`;
export const GET_TRADING_ACCOUNTS_LOGS = `${GET_TRADING_ACCOUNTS}/get-journal-mt4`;
export const GET_TRADING_ACCOUNTS_MT = `${GLOBAL_PATH_CORE}/mt4-user`;
export const GET_TRADING_ACCOUNTS_MT_BALANCE = `${GET_TRADING_ACCOUNTS_MT}/balance`;

export const PUT_TRADING_ACCOUNT_ACC_LEVEL = `${GET_TRADING_ACCOUNTS}/update-acc-level`;
export const PUT_TRADING_ACCOUNT_LEVERAGE = `${GET_TRADING_ACCOUNTS}/update-leverage`;
export const PUT_TRADING_ACCOUNT_MT_GROUP = `${GET_TRADING_ACCOUNTS}/set-mt-group`;
export const PUT_TRADING_ACCOUNT_MT5_GROUP_TYPE = `${GET_TRADING_ACCOUNTS}/toggle-net-group`;
export const PUT_TRADING_ACCOUNT_ENABLE_CHANGE_PASSWORD = `${GET_TRADING_ACCOUNTS}/set-enable-change-password`;
export const PUT_TRADING_ACCOUNT_SEND_REPORTS = `${GET_TRADING_ACCOUNTS}/set-send-reports`;
export const PUT_TRADING_ACCOUNT_ENABLE_ARCHIVE = `${GET_TRADING_ACCOUNTS}/archive`;
export const PUT_TRADING_ACCOUNT_RESET_PASSWORD = `${GET_TRADING_ACCOUNTS}/reset-password`;

/*trading-accounts MT5*/
export const GET_TRADING_ACCOUNTS_CLOSED_ORDERS_MT5 = `${GET_TRADING_ACCOUNTS}/get-closed-orders`;
export const GET_TRADING_ACCOUNTS_OPEN_ORDERS_MT5 = `${GET_TRADING_ACCOUNTS}/get-open-orders`;
export const GET_TRADING_ACCOUNTS_OPEN_POSITIONS_MT5 = `${GET_TRADING_ACCOUNTS}/get-open-positions`;
export const GET_TRADING_ACCOUNTS_CLOSED_POSITIONS_MT5 = `${GET_TRADING_ACCOUNTS}/get-closed-positions`;
export const GET_TRADING_ACCOUNTS_CLOSED_DEALS_MT5 = `${GET_TRADING_ACCOUNTS}/get-closed-deals`;

/*system-accounts*/
export const GET_SYSTEM_ACCOUNTS = `${GLOBAL_PATH_CORE}/system-accounts`;
export const GET_SYSTEM_ACCOUNTS_4_ACCOUNT = `${GLOBAL_PATH_CORE}/system-accounts/get-system-accounts`;
export const GET_SYSTEM_ACCOUNTS_BALANCE_HISTORY = `${GET_SYSTEM_ACCOUNTS}/get-balance-transaction-history`;

/* risk manager MT4 */
export const RISK_LIVE = `${GLOBAL_PATH_CORE}/risks-live`;
export const RISK_UNLOCK = `${RISK_LIVE}/unlock-risk`;
export const RISK_UPDATE = `${RISK_LIVE}/update-risk`;

export const GET_RISKS = `${RISK_LIVE}/get-by-trading_account`;
export const GET_CATEGORIES = `${GLOBAL_PATH_CORE}/risk-tool-categories/by-risk`;
export const GET_CATEGORIE_ID = `${GLOBAL_PATH_CORE}/risk-tools/category`;
export const GET_RISK_TOOLS_ALL = `${GLOBAL_PATH_CORE}/risk-tools/category`;

/* risk manager MT5 */
export const RISK_LIVE_MT5 = `${GLOBAL_PATH_CORE}/risk-live-mt5`;
export const GET_RISKS_MT5 = `${RISK_LIVE_MT5}/get-by-trading_account`;
export const RISK_UNLOCK_MT5 = `${RISK_LIVE_MT5}/unlock-risk`;
export const RISK_UPDATE_MT5 = `${RISK_LIVE_MT5}/update-risk`;

/*corporate action*/
export const CORPORATE_ACTION = `${GLOBAL_PATH_API}/corporateaction/corporate_action`;
export const CORPORATE_ACTION_CALCULATE = `${CORPORATE_ACTION}/calculate`;
export const CORPORATE_ACTION_COURSE = `${CORPORATE_ACTION}`;
export const CORPORATE_ACTION_ALL_COURSES = `${CORPORATE_ACTION}`;

//BALANCE OPERATIONS
export const BALANCE_OPERATIONS_ALL = `${GLOBAL_PATH_CORE}/system-accounts-transaction-history`;
