import React from "react";
import { withLocalize, Translate } from "react-localize-redux";

class LeadPopUpAction extends React.Component {
    constructor (props) {
        super(props);

    }

    render () {
        return (
            <Translate>
                {({ translate }) => {
                    return (
                        <div className = 'lead-modal'>
                            <div className = 'close-button' onClick = { this.props.toogle }>
                                <i className = 'la la-close' />
                            </div>
                            <h1 className = 'aligncenter'>{translate('client_delete_are_u_sure')}</h1>
                            <span>{translate('client_delete_marked_4_delete')}</span>
                            <div className = 'button-area'>
                                <div className = 'button bright-blue' onClick = { this.props.delete }>{translate('client_yes')}</div>
                                <div className = 'button white shadowed' onClick = { this.props.toogle }>{translate('client_no')}</div>
                            </div>
                        </div>
                    );
                }}
            </Translate>
        );
    }
}

export default withLocalize(LeadPopUpAction);
