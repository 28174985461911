import React, { Component } from 'react';
import PropTypes from "prop-types";

export default class RiskManagerInstrumentItem extends Component {
    constructor (props) {
        super(props);
        this.myInput = React.createRef();
    }

    componentDidMount () {
        this.props.widthHintBlock(this.myInput.current.offsetWidth);
    }

    render () {
        const { item: { name, id, disabled_symbols = 0, amount = 0 }, toolsListByCategoryId } = this.props;
        const availableSymbols = amount - disabled_symbols;

        return (
            <div className='block-wrapper'>
                <div className='block hint-position' ref={this.myInput}>
                    <div className='block-header'>
                        <span className='block-name'>{`${name} (${availableSymbols}/${amount})`}</span>
                        <span className='block-info btn-show' onClick={(e) => {
                            this.props.hintClose(true, name);
                            this.props.positionHint(e);
                            this.props.transferToolsListByCategoryId(toolsListByCategoryId);
                        }}>{this.props.translate(`list_accounts_risk_manager_btn_show`)}</span>
                    </div>
                </div>
            </div>
        );
    }
}

RiskManagerInstrumentItem.propTypes = {
    transferToolsListByCategoryId: PropTypes.func.isRequired,
};
