const linkById = (link, id) => `${link}/${id}`;
const textById = (translate, key, id) => `${translate(key)} ${id}`;

const SupportLinkConfig = (translate, order, { active, supportId } = {}) =>
  [
    {
      id: 'support',
      link: linkById('/support', supportId),
      text: textById(translate, 'support_link_request', supportId),
    },
    {
      id: 'supports',
      link: '/support',
      text: translate('support_link_title'),
    },
  ]
    .map(_ => {
      const { id } = _;
      const index = [...order].indexOf(id);
      if (index < 0) {
        return false;
      } else if (!active) {
        return { item: { ..._ }, index };
      } else {
        return { item: { ..._, active: id == active }, index };
      }
    })
    .filter(_ => _)
    .sort((a, b) => a.index - b.index)
    .map(({ item }) => item)
    .map(({ id, link, text, active }) => ({ link, text, key: id, active }));

export default SupportLinkConfig;
