import React, { Component, } from "react";
import { withLocalize, } from "react-localize-redux";
import { Dropdown, } from "../";
import { partnersService, } from "../../../../services/PartnersDataService";
import PermissionService from "../../../../services/PermissionService";
import configHorizontalMenu from "./config";
import permissions from "./permissions";
import "./styles.scss";

export class HorizontalMenu extends Component {

  state = {
  	currency: false,
  };

  async componentDidMount () {
  	partnersService.subscribe("getCurrency", this.getCurrency, this);
  	await partnersService.getPartnersCurrency();
  }

  componentWillUnmount () {
  	partnersService.unsubscribe("getCurrency", this.getCurrency, this);
  }

  getCurrency = (currency) => {
  	currency && this.setState({ currency, });
  };

  render = () => {
  	const { translate, } = this.props;
  	const { currency, } = this.state;

  	const pm = PermissionService.calc(permissions);
  	const menuConfig = configHorizontalMenu(translate, pm);

  	if (!menuConfig.length || !currency) {
  		return "";
  	}

  	return (
	<div className="horizontal-menu">
	{ menuConfig.map(({ title, config, }, i) => (
	<Dropdown
	config={ config }key={ i }
	title={ title }
  				/>
  			)) }
  		</div>
  	);
  };
}

export default withLocalize(HorizontalMenu);