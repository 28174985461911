const linkById = (link, id) => `${link}/${id}`;
const textById = (translate, key, id) => `${id}`;

const PaymentsLinkConfig = (translate, order, {
    active,
    orderId,
} = {}) => [
    {
        id: 'order',
        link: linkById('/payments/order', orderId),
        text: textById(translate, 'payment_title', orderId),
    },
    {
        id: 'orders',
        link: '/payments/orders',
        text: translate('payment_title'),
    },
]
    .map((_) => {
        const { id } = _;
        const index = [...order].indexOf(id);
        if (index < 0) {
            return false;
        } else if (!active) {
            return { item: { ..._ }, index };
        } else {
            return { item: { ..._, active: id == active }, index };
        }
    })
    .filter(_ => _)
    .sort((a, b) => a.index - b.index)
    .map(({ item }) => item)
    .map(({ id, link, text, active }) => ({ link, text, key: id, active }));

export default PaymentsLinkConfig;
