import React                                             from 'react';
import * as PropTypes                                    from 'prop-types';
import { MuiPickersUtilsProvider, KeyboardDatePicker, }  from '@material-ui/pickers';
import MomentUtils                                       from '@date-io/moment';
import Moment                                            from 'moment';
import 'moment/locale/ru';
import 'moment/locale/uk';
// https://material-ui-pickers.dev/api/KeyboardDatePicker


export class MagicDate extends React.PureComponent {
	static defaultProps = {
		InputProps: {},
		PickerProps: {},
		ProviderProps: {},
		className: [],
		disabled: false,
		emptyLabel: '?',
		format: 'DD-MM-YYYY',
		locale: 'ru',
		max: null,
		min: null,
		showRelease: false,
		value: null,
	};

	static classNamePropType = PropTypes.string;
	static classNamePropTypes = PropTypes.oneOfType( [
		MagicDate.classNamePropType,
		PropTypes.arrayOf( MagicDate.classNamePropType ),
	] );
	static disabledPropTypes = PropTypes.bool;
	static emptyLabelPropTypes = PropTypes.string;
	static formatPropTypes = PropTypes.string;
	static localePropTypes = PropTypes.oneOf( [ 'en', 'ru', 'uk', ] );
	static showReleasePropTypes = PropTypes.bool;
	static valuePropTypes = PropTypes.oneOfType( [
		PropTypes.instanceOf( Moment ),
		PropTypes.instanceOf( Date ),
		PropTypes.number,
	] );
	static propTypes = {
		InputProps: PropTypes.object.isRequired,
		PickerProps: PropTypes.object.isRequired,
		ProviderProps: PropTypes.object.isRequired,
		className: MagicDate.classNamePropTypes.isRequired,
		disabled: MagicDate.disabledPropTypes.isRequired,
		emptyLabel: MagicDate.emptyLabelPropTypes.isRequired,
		format: MagicDate.formatPropTypes.isRequired,
		locale: MagicDate.localePropTypes.isRequired,
		max: MagicDate.valuePropTypes,
		min: MagicDate.valuePropTypes,
		showRelease: MagicDate.showReleasePropTypes.isRequired,
		value: MagicDate.valuePropTypes,
		onChange: PropTypes.func.isRequired,
	};

	async onChange ( date ) {
		const { onChange, } = this.props;
		onChange && await onChange( date && date.isValid() ? date.toDate() : null );
	}

	renderKeyboardDatePicker () {
		const { InputProps, PickerProps, } = this.props;
		const { disabled, emptyLabel, format, locale, value, } = this.props;
		const { max, min} = this.props;
		const poptions = {
			autoOk: true,
			disableToolbar: true,
			emptyLabel,
			invalidDateMessage: null,
			variant: 'inline',
			...PickerProps,
			InputProps: {
				disableUnderline: true,
				readOnly: true,
				...InputProps,
			},
			disabled,
			format,
			...max && Moment( max ).isValid() ? { maxDate: Moment( max ) } : {},
			...min && Moment( min ).isValid() ? { minDate: Moment( min ) } : {},
			value: value && Moment(value).isValid() ? Moment(value).local(locale) : null,
			onChange: this.onChange.bind( this ),
		};
		return (<KeyboardDatePicker { ...poptions } />);
	}

	renderMuiPickersUtilsProvider () {
		const { ProviderProps, locale, } = this.props;
		const ppoptions = {
			utils: MomentUtils,
			...ProviderProps,
			children: this.renderKeyboardDatePicker(),
			locale,
		};
		return (<MuiPickersUtilsProvider { ...ppoptions } />);
	}

	renderRelease () {
		const { showRelease, value, } = this.props;
		const valid = value && Moment( value ).isValid();
		if ( !valid || !showRelease ) {
			return '';
		}
		const options = {
			className: 'magic-date__release',
			onClick: async () => await this.onChange( null ),
		};
		return (<span { ...options } />);
	}

	render () {
		const { className, } = this.props;
		const classNames = className instanceof Array ? className : [ className ];
		const provider = this.renderMuiPickersUtilsProvider();
		const release = this.renderRelease();
		const options = {
			className: [ 'magic-date', ...classNames ].join(' '),
		};
		return (<div { ...options } >{ provider }{ release }</div>);
	}

};
