const LinkConfig = (translate, order, permissions) =>
	[
		{
			id: "cron",
			link: "/settings/cron",
			text: translate("list_of_crons"),
		},
		{
			id: "settings",
			link: "/settings",
			text: translate("menu_admin_panel"),
		},
	]
		.filter(({ id, }) => order.includes(id))
		.sort(({ id: a, }, { id: b, }) => order.indexOf(a) - order.indexOf(b))
		.map(({ id, ...data }) => ({ ...data, key: id, }))
		.filter((item) => {
			if (permissions) {
				return permissions?.[item?.key]?.access("index") ?? false;
			}
			return true;
		});

export default LinkConfig;