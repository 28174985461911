import React, { Component, Fragment } from 'react';
import PropTypes                      from 'prop-types';
import MagicSwitch                    from './MagicSwitch';


class MagicRadioSwitch extends Component {
	constructor ( props ) {
		super( props );
		this.state = {
			index: props.index,
			scrollPosY: props.scrollPosY,
		};
	}

    componentDidMount () {
        this.setScrollPos(0, this.state.scrollPosY);
    }

    UNSAFE_componentWillReceiveProps = ( nextProps, ) => {
        if (this.props.updateStateFromProps) {
            this.setState( { index: nextProps.index } );
        }
    };

	setScrollPos = (x, y) => {
		this.ref.scrollTo(x, y);
	};

	amount = ( ) => this.props.values.length;
	classIcon = ( ) => this.props.classNameIcon;
	classItem = ( ) => this.props.classNameItem;
	className = ( ) => this.props.className;
	classText = ( ) => this.props.classNameText;
	direction = ( ) => this.props.direction;
	index = ( ) => this.state.index;
	reverse = ( ) => this.props.reverse;
	texts = ( i ) => i>=0 ? this.props.texts[ i ] : this.props.texts;
	values = ( i ) => i>=0 ? this.props.values[ i ] : this.props.values;
	isDisabled = ( ) => this.props.isDisabled;

	onChange = ( itemIndex ) => ( { value: itemValue } ) => {
		if ( !this.props.roundRobing && itemValue===false ) {
			throw new Error( 'MagicSwitch.onChange break' );
		}
		const indexNone = this.props.indexNone ? 0 : 1;
        const step = /reverse/i.test( this.direction() ) ? this.amount() - 1 : 1;
        const amount = this.amount() + indexNone;
        const index = itemValue ? itemIndex : ( ( ( itemIndex - indexNone ) + step ) % amount + indexNone );
		const state = { ...this.state, index, };
		this.setState( state, ( ) => {
			if ( this.props.onChange instanceof Function ) {
				const values = this.values().slice();
				const value = this.values( index );
				const { scrollPosY } = this.state;

				this.props.onChange( { value, index, values, scrollPosY, } );
			}
		} );
	};

    onScroll = (event) => {
		this.setState( {...this.state, scrollPosY: this.ref.scrollTop} );
	};

	renderItem = ( itemIndex ) => (
		<span
            key={ `radio-${ itemIndex }-${ this.index() }` }
            data-radio-index={ itemIndex }
		>
			<MagicSwitch
				className={ this.classItem() }
				classNameIcon={ this.classIcon() }
				classNameText={ this.classText() }
				reverse={ this.reverse() }
				text={ this.texts( itemIndex ) }
				values={ [ false, true ] }
				index={ this.index() === itemIndex ? 1 : 0 }
				onChange={ this.onChange( itemIndex ) }
				isDisabled={ this.isDisabled() }
			/>
		</span>
	);

	renderItems = () => ( this.values().map( ( _, itemIndex ) => this.renderItem( itemIndex ) ) );

	render = ( ) => (
		<div className={ this.className() } data-direction={ this.direction() }
             onScroll={ this.onScroll }
             ref={ (node) => this.ref = node }
		>
			{ this.renderItems() }
		</div>
	);
};


MagicRadioSwitch.propTypes = {
	className: PropTypes.string.isRequired,
	classNameItem: PropTypes.string.isRequired,
	classNameIcon: PropTypes.string.isRequired,
	classNameText: PropTypes.string.isRequired,
	direction: PropTypes.string.isRequired,
	indexNone: PropTypes.bool.isRequired,
	index: ( props, key, component ) => {
		const value = props[ key ];
		// value=-1 is radio unchecked
		if ( !Number.isInteger( value ) || value<(props.indexNone ? -1 : 0) || value>=props.values.length ) {
			return new Error( `Error ${ component }: props.${ key } = '${ value }'` );
		}
	},
	reverse: PropTypes.bool.isRequired,
    roundRobing: PropTypes.bool.isRequired,
	texts: PropTypes.arrayOf(
		PropTypes.oneOfType( [
			PropTypes.bool.isRequired,
			PropTypes.node.isRequired,
			PropTypes.number.isRequired,
			PropTypes.string.isRequired,
		] ).isRequired
	).isRequired,
	values: PropTypes.oneOfType( [
		PropTypes.arrayOf( PropTypes.bool ).isRequired,
		PropTypes.arrayOf( PropTypes.number ).isRequired,
		PropTypes.arrayOf( PropTypes.string ).isRequired,
	] ).isRequired,
	onChange: PropTypes.func.isRequired,
    updateStateFromProps: PropTypes.bool,
};


MagicRadioSwitch.defaultProps = {
	className: `magic-radio-switch`,
	classNameItem: `magic-switch`,
	classNameIcon: `magic-switch__icon`,
	classNameText: `magic-switch__text`,
	direction: 'column',
	indexNone: false, // enable/disable non-selectable state of MagicSwitch
	index: 0,
	reverse: false,
	roundRobing: false,
    scrollPosY: 0, // used in tall pop-ups to recover scroll position
	texts: [ `My MagicRadioSwitch false`, `My MagicRadioSwitch true` ],
	values: [ false, true ],
    updateStateFromProps: false,
};


export default MagicRadioSwitch;
export {
	MagicRadioSwitch,
};
