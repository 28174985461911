export const SUMMARY_EXPORT_FIELDS = {
	summary_of_fin_transactions: [
		"payment-daily_aggregated_fin_transactions-operation_date",
		"payment-daily_aggregated_fin_transactions-order_usd_deposit",
		"payment-daily_aggregated_fin_transactions-bal_op_usd_deposit",
		"payment-daily_aggregated_fin_transactions-order_eur_deposit",
		"payment-daily_aggregated_fin_transactions-bal_op_eur_deposit",
		"payment-daily_aggregated_fin_transactions-order_usd_withdrawal",
		"payment-daily_aggregated_fin_transactions-bal_op_usd_withdrawal",
		"payment-daily_aggregated_fin_transactions-order_eur_withdrawal",
		"payment-daily_aggregated_fin_transactions-bal_op_eur_withdrawal",
		"payment-daily_aggregated_fin_transactions-order_usd_chargeback",
		"payment-daily_aggregated_fin_transactions-bal_op_usd_chargeback",
		"payment-daily_aggregated_fin_transactions-order_eur_chargeback",
		"payment-daily_aggregated_fin_transactions-bal_op_eur_chargeback",
		"payment-daily_aggregated_fin_transactions-unful_order_usd_withd",
		"payment-daily_aggregated_fin_transactions-unful_order_eur_withd",
	],
};