import { securedRequest, } from "../../../services/RequestService";
import {
	get, put, post, MagicError,
} from "../../../components/Magic";

import {
	GET_ACCOUNTS,
	GET_FILTER_HASH,
	GET_TRADING_ACCOUNT,
	GET_TRANSACTIONS,
	SET_USER_FILTER,
	GET_ACCOUNT_DOCUMENTS,
	SET_EMAILS,
	SET_SMS,
	ACTION_SEND_EMAILS_BY_ACCOUNT,
	ACTION_SEND_SMS_BY_ACCOUNT,
	GET_SUMSUB_CONFIG, SET_ACCOUNT_STATUS_ACTIVE, GET_STATEMENT, GET_NOVATALKS_MESSAGE_LIST,
} from '../../../apiRoutes'

import {
	POST_ACCOUNT_TYPE,
	POST_ACCOUNT_STATUS,
	GET_ACCOUNT,
	GET_ALL_ACCOUNT_DOCUMENTS,
	SET_ACCOUNT,
	PUT_ACCOUNT,
	POST_ACCOUNT_RESEND_EMAIL,
	POST_ACCOUNT_CATEGORIES,
    // GET_COUNTRIES_DROP_DOWN,
	GET_COUNTRIES_SELECT_MODEL,
	GET_CITYES_SELECT_MODEL,
	GET_LANGUAGE_DROP_DOWN,
	GET_TICKETS_BY_CLIENT_ID,
	POST_MESSAGE_TEXT,
	GET_DESCRIPTIONS,
	POST_CREATE_DESCRIPTION,
	PUT_DESCRIPTION, PUT_MOBILE_DISCOUNT,
} from "../apiRoutes";

import {
	GET_TIMA_STRATEGIES,
	GET_TIMA_INVESTMENTS,
	GET_TIMA_AGENTS,
} from '../../../apiRoutes';

import { cachingService, } from '../../../services/CachingService';

export const getSumsubConfig = async account_id =>
	await get(`${ GET_SUMSUB_CONFIG }?account_id=${ account_id }`, {});

export const getClientsAsync = async options =>
	await get(`${ GET_ACCOUNTS }`, options);

// TODO: check to remove
export const getFilterHash = (id) => {
	return securedRequest.get(`${ GET_FILTER_HASH }/${ id }`).then(
		response => Promise.resolve(response),
		error => Promise.reject(error.response.data),
	);
};

// TODO: check to remove
export const getClientsByFilterHash = (id, output) => {
	return securedRequest.get(`${ GET_ACCOUNTS }/filter/${ id }?${ output }`).then(
		response => Promise.resolve(response),
		error => Promise.reject(error.response.data),
	);
};

export const getCountries = () => {
	return securedRequest.get(`${ GET_COUNTRIES_DROP_DOWN }`)
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error.response.data),
		);
};

export const getClients = (output) => {
	return securedRequest.get(`${ GET_ACCOUNTS }?${ output }`)
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error.response.data),
		);
};

// TODO: check to remove
export const postFilterData = (params = 'data=[]') => {
	return securedRequest.post(`${ GET_ACCOUNTS }/filter`, params)
		.then(
			response => Promise.resolve(response),
			error => Promise.reject(error.response.data),
		);
};

// TODO: check to remove
export const setFilterHash = (filters) => {
	return securedRequest.post(`${ SET_USER_FILTER }`, { "filter_hash_id": filters.data.id, "type": "1", }).then(
		response => Promise.resolve(response),
		error => Promise.reject(error.response.data),
	);
};

export const getAccount = (id) => {
	return securedRequest.get(`${ GET_ACCOUNT }/${ id }`)
		.then(
			response => Promise.resolve(response.data),
			error => Promise.reject(error.response.data),
		);
};

export const getAllAccountDocuments = (id) => {
	return securedRequest.get(`${ GET_ALL_ACCOUNT_DOCUMENTS }/${ id }`)
		.then(
			response => Promise.resolve(response.data),
			error => Promise.reject(error.response.data),
		);
};

export const uploadDocuments = async (data, account_id) => {
    //TODO: переделать пост как get параметр где 3-й аргумент для formData
	const response = await post(GET_ACCOUNT_DOCUMENTS, data);

	await cachingService.clean({ match: ({ isOne, key, }) => isOne && key.indexOf(`GET:${ SET_ACCOUNT }/${ account_id }`)>0, });

	return response;
};

export const verifyDocument = (id, obj) => {
	return securedRequest.put(`${ SET_ACCOUNT }/${ id }`, obj)
		.then(
			response => Promise.resolve(response.data),
			error => Promise.reject(error.response.data),
		);
};

export const sendAccountManager = (id, obj) => {
	return securedRequest.put(`${ PUT_ACCOUNT }/${ id }`, obj)
		.then(
			response => Promise.resolve(response.data),
			error => Promise.reject(error.response.data),
		);
};

export const updateEmailAccount = async (id, obj) =>
	await put(`${ PUT_ACCOUNT }/${ id }?emails_not_delete=1`, obj);

export const updateAccountProperty = async (id, obj) =>
	await put(`${ PUT_ACCOUNT }/${ id }`, obj);
export const changePhoneAccount = async (id, obj) =>
	await put(`${ PUT_ACCOUNT }/${ id }`, obj);

export const updatePhoneAccount = async (id, obj) =>
	await put(`${ PUT_ACCOUNT }/${ id }?phones_not_delete=1`, obj);

export const updateTypesSet = (obj) => {
	return securedRequest.post(POST_ACCOUNT_TYPE, obj)
		.then(
			response => Promise.resolve(response.data),
			error => Promise.reject(error.response.data),
		);
};

export const updateStatusSet = (obj) => {
	return securedRequest.post(POST_ACCOUNT_STATUS, obj)
		.then(
			response => Promise.resolve(response.data),
			error => Promise.reject(error.response.data),
		);
};

export const setCategory = (obj) => {
	return securedRequest.post(POST_ACCOUNT_CATEGORIES, obj)
		.then(
			response => Promise.resolve(response.data),
			error => Promise.reject(error.response.data),
		);
};

export const changeWarm = (id, obj) => {
	return securedRequest.put(`${ GET_ACCOUNT }/${ id }`, obj)
		.then(
			(response) => {
				return Promise.resolve(response.data);
			},
			error => Promise.reject(error.response.data),
		);
};

export const getCountriesAll = () => {
	return securedRequest.get(GET_COUNTRIES_SELECT_MODEL)
		.then(
			response => Promise.resolve(response.data),
			error => Promise.reject(error.response.data),
		);
};

export const getCityAll = (country_id) => {
	return securedRequest.get(`${ GET_CITYES_SELECT_MODEL }?country_id=${ country_id }`)
		.then(
			response => Promise.resolve(response.data),
			error => Promise.reject(error.response.data),
		);
};

export const updateClientComment = (id, data) => {
	return securedRequest.put(`${ PUT_ACCOUNT }/${ id }`, data)
		.then(
			response => Promise.resolve(response.data),
			error => Promise.reject(error.response.data),
		);
};

export const getTradingAccount = (id = 0) => {
	return securedRequest.get(`${ GET_TRADING_ACCOUNT }/${ id }`)
		.then(
			response => Promise.resolve(response.data),
			error => Promise.reject(error.response.data),
		);
};

// accounts descriptions

export const getAccountDescriptions = async (id, take, skip) => {
	return await get(`${ GET_DESCRIPTIONS }/${ id }?take=${ take }&skip=${ skip }`)
};
export const createAccountDescription = async data => await post(`${ POST_CREATE_DESCRIPTION }`, data);
export const updateAccountDescription = async (id, data) => await put(`${ PUT_DESCRIPTION }/${ id }`, data);
export const updateAccountMobileDiscount = async (data, id) => await put(`${ PUT_MOBILE_DISCOUNT }/${ id }`, data);

export const changeWarmType = async id => await post(`${ PUT_ACCOUNT }/${ id }/cold-to-warm`);
export const resendRegistrationEmail = async data => await post(`${ POST_ACCOUNT_RESEND_EMAIL }`, data);
export const getTransactions = async (id, options) => await get(`${ GET_TRANSACTIONS }/${ id }`, options);
export const getTicketsByClientId = async (id, options) => await get(`${ GET_TICKETS_BY_CLIENT_ID }/${ id }`, options);
export const sendMessageText = async data => await post(`${ POST_MESSAGE_TEXT }`, data);
export const getFilterIdTimaStrategies = async params => await post(`${ GET_TIMA_STRATEGIES }/filter`, params);
export const getTimaStrategies = async (id, params) => await get(`${ GET_TIMA_STRATEGIES }/filter/${ id }`, params);
export const getFilterIdTimaInvestments = async params => await post(`${ GET_TIMA_INVESTMENTS }/filter`, params);
export const getTimaInvestments = async (id, params) => await get(`${ GET_TIMA_INVESTMENTS }/filter/${ id }`, params);
export const getFilterIdTimaAgents = async params => await post(`${ GET_TIMA_AGENTS }/filter`, params);
export const getTimaAgents = async (id, params) => await get(`${ GET_TIMA_AGENTS }/filter/${ id }`, params);

export const getMailTemplates = async () => {
	const url = SET_EMAILS;

	return cachingService.session(url);
};

export const getMailTemplatesByLanguageId = async (languageId) => {
	const url = `${ SET_EMAILS }?language_id=${ languageId }`;

	return await get(url);
};

export const getSmsTemplates = async () => {
	const payload = 'data=[{"f":"core.sms_templates.active","enabled":1, "j":"n","o":"=","v":"1"},{"connect": "pgsql"}]';
	const { id, } = await cachingService.session(`${ SET_SMS }/filter`, { payload, method: 'POST', });
	const { data, } = await cachingService.session(`${ SET_SMS }/filter/${ id }`);

	return data;
};

export const getSmsTemplatesByLanguageId = async (languageId) => {
	return await get(`${ SET_SMS }?language_id=${ languageId }`);
};

export const sendEmail = async data => await post(ACTION_SEND_EMAILS_BY_ACCOUNT, data);
export const sendSms = async data => await post(ACTION_SEND_SMS_BY_ACCOUNT, data);
export const getStatement = async (data, id) => await post(`${GET_STATEMENT}/system-accounts/${id}/generateAccountStatementReport`, data);

export const getFilterUsers = async () => await get('/api/core/account/change-manager/drop-down');
export const getBalanceUsers = async accountId => await get(`/api/core/account/get-balance/${ accountId }`);