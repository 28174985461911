//enums TradingAccountRiskManagerStateEnum
export const RISKMANAGERENUM_OFF = '0';
export const RISKMANAGERENUM_ON = '1';

//enums Mt4UserArchiveEnum
export const ENABLE_ARCHIVE_FALSE = '0';
export const ENABLE_ARCHIVE_TRUE = '1';

//link route
export const LIST_ACCOUNTS_SYSTEMS = 'list-accounts/systems';
export const LIST_ACCOUNTS_TRADINGS = 'list-accounts/tradings';
export const LIST_ACCOUNTS_CORPORATE_ACTION = 'list-accounts/corporate-action';
export const LIST_ACCOUNTS_BALANCE_OPERATIONS = 'list-accounts/balance-operations';
export const LIST_CLIENTS_ID = 'clients';

//tab for table accounts
export const TABID_0 = '0';
export const TABID_1 = '1';
export const TABID_2 = '2';
export const TABID_3 = '3';
export const TABID_4 = '4';

export const TAB_ID = {
    tradingAccountGeneralInfo: 'tradingAccountGeneralInfo',
    tradingAccountOpenOrdersMT4: 'tradingAccountOpenOrdersMT4',
    tradingAccountClosedOrdersMT4: 'tradingAccountClosedOrdersMT4',
    tradingAccountRiskManager: 'tradingAccountRiskManager',
    tradingAccountLogsMT4: 'tradingAccountLogsMT4',
};

//risk type
export const VALUE = 0;
export const PERCENT = 1;
export const TIMA = 2;
export const DELETED = -1;

//risk type symbol
export const USD = 1;
export const EUR = 2;

// field names full path
export const LIST_ACCOUNTS_EXPORT_FIELDS = {
    systemAccounts: [
        'core-system_accounts-id',
        'core-account-name',
        'core-account-surname',
        'core-system_accounts-created_at',
        'core-system_accounts-updated_at',
        'core-system_accounts-type',
        'core-system_accounts-acc_ccy',
        'core-system_accounts-value',
        'core-system_accounts-status',
    ],
    systemAccountHistory: [
        "core-system_accounts_transaction_history-id",
        "core-system_accounts_transaction_history-amount",
        "core-system_accounts_transaction_history-created_at",
        "core-system_accounts_transaction_history-comment"
    ],
    tradingAccounts: [
        'core-trading_accounts-mt_login',
        'core-account-name',
        'core-account-surname',
        'core-trading_accounts-created_at',
        'core-trading_accounts-updated_at',
        'core-trading_accounts-type',
        'core-trading_accounts-acc_level',
        'core-trading_accounts-acc_ccy',
        'mt-user-mt_balance',
        'mt-user-mt_equity',
        'core-trading_accounts-status',
        'core-trading_accounts-leverage',
        'core-trading_accounts-risk_management',
        'core-trading_accounts-mt_group',
        'core-trading_accounts-platform',
    ],
    tradingAccountOpenOrdersMT4: [
        'trades-TICKET',
        'trades-LOGIN',
        'trades-OPEN_TIME',
        'trades-CMD',
        'trades-VOLUME',
        'trades-SYMBOL',
        'trades-OPEN_PRICE',
        'trades-SL',
        'trades-TP',
        'trades-COMMISSION',
        'trades-SWAPS',
        'trades-PROFIT',
        'trades-COMMENT',
    ],
    tradingAccountClosedOrdersMT4: [
        'trades-TICKET',
        'trades-LOGIN',
        'trades-OPEN_TIME',
        'trades-CMD',
        'trades-VOLUME',
        'trades-SYMBOL',
        'trades-OPEN_PRICE',
        'trades-SL',
        'trades-TP',
        'trades-CLOSE_TIME',
        'trades-CLOSE_PRICE',
        'trades-COMMISSION',
        'trades-SWAPS',
        'trades-PROFIT',
        'trades-COMMENT',
    ],
    tradingAccountLogsMT4: [
        'time',
        'ip',
        'message',
    ],
};
