import React from 'react';
import { Table } from 'reactstrap';
import { withRouter } from "react-router-dom";
import { withLocalize, Translate } from 'react-localize-redux';
import CheckUndefinedPermission from "../../../../components/Common/CheckUndefinedPermission";
import {Scrollbars} from "react-custom-scrollbars";
import check from "../../../../images/icon.svg";
import oval from "../../../../images/oval.svg";

class TradingAccountInformation extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            account:     {},
            enum:   this.props.enums ?? {},
        };
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        this.setState({
            account: nextProps.account
        });
    }

    render () {

        return (
            <CheckUndefinedPermission>
                <Translate>
                    {({ translate }) => {
                        return (
                            <div className = 'block-shadowed'>
                                <span className = 'table-title account-title'>{translate(`trading_information`)}</span>

                                <div className = 'react-bs-table table-unbordered-container'>

                                    <Scrollbars
                                        style = { { height: 400 } }
                                        renderView={props => <div {...props} className="view"/>}
                                        renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                                    >
                                        <Table>
                                            <tbody>

                                                <tr>
                                                    <td>{translate(`trading_created`)}</td>
                                                    <td>{this.state.account['core-trading_accounts-created_at']}</td>
                                                </tr>
                                                <tr>
                                                    <td>{translate(`trading_type`)}</td>
                                                    <td>{this.state.enum['TradingAccountTypeEnum'][this.state.account['core-trading_accounts-type']]}</td>
                                                </tr>
                                                <tr>
                                                    <td>{translate(`trading_amount`)}</td>
                                                    <td>{this.state.account['core-trading_accounts-last_equity']}</td>
                                                </tr>
                                                <tr>
                                                    <td>{translate(`trading_shoulder`)}</td>
                                                    <td>1:{this.state.account['core-trading_accounts-leverage']}</td>
                                                </tr>
                                                <tr>
                                                    <td>{translate(`trading_strategy`)}</td>
                                                    <td>{this.state.account['core-trading_accounts-mt_group']}</td>
                                                </tr>
                                                <tr>
                                                    <td>{translate(`trading_risk_manager`)}</td>
                                                    <td>{this.state.account['core-trading_accounts-risk_management'] ? <img src={check} alt='true' /> : <img src={oval} alt='false' /> }</td>
                                                </tr>
                                                <tr>
                                                    <td>{translate(`trading_currency`)}</td>
                                                    <td>{this.state.account['core-trading_accounts-acc_ccy']}</td>
                                                </tr>
                                                <tr>
                                                    <td>{translate(`trading_status`)}</td>
                                                    <td>{this.state.enum['TradingAccountStatusEnum'][this.state.account['core-trading_accounts-status']]}</td>
                                                </tr>
                                                <tr>
                                                    <td>{translate(`trading_platform`)}</td>
                                                    <td>{this.state.account['core-trading_accounts-platform']}</td>
                                                </tr>

                                            </tbody>
                                        </Table>
                                    </Scrollbars>
                                </div>
                            </div>
                        );
                    }}
                </Translate>
            </CheckUndefinedPermission>
        );
    }
}

//TODO Redax Delete enums
/*const mapStateToProps = (store) => {
    return {
        enums: store.clients.enums,
    }
}*/

export default withLocalize(withRouter(TradingAccountInformation));
