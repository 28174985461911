import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import Preloader from "../../../components/LoadingHOC/Preloader";
import Loader from "./Loader";
import PermissionService from "../../../services/PermissionService";
import { Link } from "react-router-dom";
import { MagicConfirm } from "./Magic/MagicConfirm";
import { MagicRadioSwitch } from "./Magic/MagicSwitch";
import NotificationService from "../../../services/NotificationService";
import { MagicTextarea } from "./Magic/MagicTextarea";
import { timaService } from "../../../services/TimaDataService";

class OneTimaAgent extends Component {
    constructor (props) {
        super(props);

        let id = null;

        if (this.props.params && this.props.params.agentId) {
            id = +this.props.params.agentId;
        }

        this.state = {
            loaded:                false,
            agent: {
                data: [],
                hash: null,
            },
            id,
            magicEditValuePrev:    {},
            magicEditValueCurrent: {},
            magicEditSelectionPos: {},
            confirm:               false,
            editMode:              false,
        };
    }

    save = async (state) => new Promise(next => this.setState(state, next));

    agentChanges = async (agent) => {
        if (agent.hash===this.state?.agent?.hash) return `${this.constructor.name}.agentChanges: false`;
        if (agent.agentId !== this.state.id) return `${this.constructor.name}.agentChanges: false`;
        const { data, hash } = agent;
        await this.save({
            agent: { data, hash },
        });

        return `${this.constructor.name}.agentChanges: true`;
    };

    componentDidMount = async () => {
        timaService.subscribe('agent', this.agentChanges, this);
        try {
            const { data, hash } = await timaService.agent(this.state.id);
            const agent = { data, hash };
            await this.save({ loaded: true, agent, })
        } catch (error) {
            error?.showErrorNotification?.();
        }
    };

    componentWillUnmount = () => {
        timaService.unsubscribe('agent', this.agentChanges, this);
    };

    get OneTimaAgentConfig () {
        const { translate, } = this.props;
        const prefix = 'tima_agents_table_';

        return [
            {
                path:   ['tima', 'tima_agents', 'name'],
                key:    'timaAgentName',
                render: this.renderBlockCont(),
                title:  `${prefix}tima_agent`,
            },
            {
                path: ['tima', 'tima_manager', 'id'],
                key:  'timaManagerId',
            },
            {
                path:   ['tima', 'tima_manager', 'name'],
                key:    'timaManagerName',
                render: this.renderBlockContLink({ path: '/tima/strategy' }),
                title:  `${prefix}tima_account`,
            },
            {
                path:   ['tima', 'tima_agents', 'status'],
                key:    'timaAgentStatus',
                render: this.renderBlockCont({ fieldName: 'status' }),
                title:  `${prefix}status`,
            },
            {
                path:   ['tima', 'tima_agents', 'agent_reward'],
                key:    'timaAgentReward',
                render: this.renderBlockCont({ fieldName: 'agent_reward' }),
                title:  `${prefix}agent_reward`,
            },
            {
                path: ['core', 'account', 'id'],
                key:  'clientId',
            },
            {
                path: ['core', 'account', 'name'],
                key:  'clientName',
            },
            {
                path: ['core', 'account', 'surname'],
                key:  'clientSurname',
            },
            {
                accessChecked: true,
                key:           'client',
                render:        this.renderFullNameCont({ fieldName: 'client', path: '/clients' }),
                title:         `${prefix}client`,
            },
            {
                path: ['core', 'user', 'id'],
                key:  'userId',
            },
            {
                path: ['core', 'user', 'name'],
                key:  'userName',
            },
            {
                path: ['core', 'user', 'surname'],
                key:  'userSurname',
            },
            {
                accessChecked: true,
                key:           'responsible',
                render:        this.renderFullNameCont({ fieldName: 'user', path: '/settings/user' }),
                title:         `${prefix}responsible`,
            },
            {
                path:   ['tima', 'tima_agents', 'updated_at'],
                key:    'updatedAt',
                render: this.renderBlockCont(),
                title:  `${prefix}date_updated`,
            },
            {
                path:   ['tima', 'tima_agents', 'created_at'],
                key:    'createdAt',
                render: this.renderBlockCont(),
                title:  `${prefix}date_created`,
            },
        ].map(({ title: t, xtitle: x, ...item }) => ({ ...item, ...x && { title: x }, ...t && { title: translate(t) }, }));
    }

    renderBlockCont = ({ fieldName = '', isEnum = false, enumPath = null, enumName = null } = {}) => (value, { item, items }) => {
        const title = item?.options?.title;
        const key = item?.key;
        const defaultValue = '-';

        let targetValue;

        switch (true) {
            case item?.access?.('show'): {
                if (isEnum) {
                    const enums = this.props?.enums?.[enumPath]?.[enumName];
                    targetValue = enums?.[value] ?? defaultValue;
                } else {
                    targetValue = value ?? defaultValue;
                }
                break;
            }
            default: {
                targetValue = defaultValue;
                break;
            }
        }

        return this.renderBlockContFragment({ fieldName, key, title, targetValue, value, item, items });
    };

    renderBlockContLink = ({ path = null } = {}) => (value, { item, items }) => {
        const id = items?.timaManagerId;
        const title = item?.options?.title;
        const key = item?.key;
        const defaultValue = '-';

        let targetValue;
        let blockUrl;

        switch (true) {
            case id?.access?.('show') && item?.access?.('show'): {
                targetValue = value ?? defaultValue;
                blockUrl = !isNaN(parseInt(`${id?.valueOf}`)) ? `${path}/${ id?.valueOf }` : null;
                break;
            }
            case item?.access?.('show'): {
                targetValue = value ?? defaultValue;
                blockUrl = null;
                break;
            }
            default: {
                targetValue = defaultValue;
                blockUrl = null;
                break;
            }
        }

        return this.renderBlockContFragment({ key, title, targetValue, blockUrl });
    };

    renderFullNameCont = ({ fieldName = null, path = null } = {}) => (value, { item, items }) => {
        const id = items?.[`${fieldName}Id`];
        const name = items?.[`${fieldName}Name`];
        const surname = items?.[`${fieldName}Surname`];

        const title = item?.options?.title;
        const key = item?.key;
        const defaultValue = '-';

        let targetValue;
        let blockUrl;

        switch (true) {
            case id?.access('show') && name?.access?.('show') && surname?.access?.('show'): {
                targetValue = `${ surname?.valueOf } ${ name?.valueOf }`;
                blockUrl = `${path}/${ id?.valueOf }`;
                break;
            }
            case name?.access?.('show') && surname?.access?.('show'): {
                targetValue = `${ surname?.valueOf } ${ name?.valueOf }`;
                blockUrl = null;
                break;
            }
            default: {
                targetValue = defaultValue;
                blockUrl = null;
                break;
            }
        }

        return this.renderBlockContFragment({ key, title, targetValue, fieldName, blockUrl });
    };

    renderBlockContFragment = ({ key, title, targetValue, fieldName, blockUrl, value, item, items }) => {
        const targetOption = fieldName === 'client' ? '_self' : '_blank';

        return (
            <div className="block-cont" key={`block-cont-${key}`}>
                <div className="block">
                    <div className="block-header full-cont">
                        <div className="full-cont__item">
                            {blockUrl ?
                                (
                                    <Link to={`${blockUrl}`} className='link-btn-cont' target={targetOption}>
                                        <span className='block__name link-btn'>
                                            {title}
                                        </span>
                                    </Link>
                                ) :
                                (
                                    <span className="block__name">{title}</span>
                                )
                            }

                        </div>
                        <div className="full-cont__item">
                            {/*<span className="block__info">{targetValue}</span>*/}
                            {this.renderBlockInfoFragment({ fieldName, targetValue, value, item, items })}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderBlockInfoFragment = ({ fieldName, targetValue, value, item, items }) => {
        const { translate } = this.props;

        switch (fieldName) {
            case 'status': {
                const valueFromState = this.state?.magicEditValuePrev?.[fieldName] ?? this.values(fieldName).indexOf(value);
                const isVisible = this.confirmCheck(fieldName);
                const isEditMode = this.editModeCheck(fieldName);

                if (item?.access?.('show', 'update')) {
                    return (
                        <div className='magic-edit-mode'>
                            <span className='block__info edit-btn'
                                  onClick={this.onEnterEditMode(fieldName)}
                            >
                                {this.texts(fieldName)[valueFromState]}
                            </span>

                            {isEditMode ?
                                (
                                    <div
                                        className="magic-confirm-wrapper magic-confirm-wrapper--large-width magic-confirm-wrapper--position-right">
                                        <MagicConfirm
                                            onAccept={this.confirmAcceptHandler(fieldName, valueFromState)}
                                            onReject={this.confirmRejectHandler(fieldName, valueFromState)}
                                            title={translate(`magic_confirm_title`)}
                                            accept={translate(`magic_confirm_yes`)}
                                            reject={translate(`magic_confirm_no`)}
                                            isVisible={!!isVisible}
                                        />

                                        <MagicRadioSwitch
                                            className={`magic-radio-switch /*magic-radio-switch--max-height-200 scroll*/`}
                                            classNameText={`magic-switch__text magic-switch__text--padding-left`}
                                            direction={`column`}
                                            index={isVisible ? this.values(fieldName).indexOf(+isVisible[1]) : valueFromState}
                                            indexNone={true}
                                            // index={-1}
                                            reverse={false}
                                            texts={this.texts(fieldName)}
                                            values={this.values(fieldName)}
                                            onChange={this.onChange(fieldName)}
                                        />

                                        <i className="gl-icon close-btn"
                                           onClick={() => this.onClickCloseConfirmBtn(fieldName)}
                                        />
                                    </div>
                                ) : ''}
                        </div>
                    );
                } else if (item?.access?.('show')) {
                    return (
                        <div>
                            <span className='block__info'>
                                {this.texts(fieldName)[valueFromState]}
                            </span>
                        </div>
                    );
                }
                return '-';
            }
            case 'agent_reward': {
                const valueFromStatePrev = this.state?.magicEditValuePrev?.[fieldName] ?? value;
                const valueFromStateCurrent = this.state?.magicEditValueCurrent?.[fieldName] ?? value;
                const isVisible = this.confirmCheck(fieldName);
                const isEditMode = this.editModeCheck(fieldName);

                if (item?.access?.('show', 'update')) {
                    return (
                        <div className='magic-edit-mode'>
                            <span className='block__info edit-btn'
                                  onClick={this.onEnterEditMode(fieldName)}
                            >
                                {valueFromStatePrev}
                            </span>

                            {isEditMode ?
                                (
                                    <div
                                        className="magic-confirm-wrapper magic-confirm-wrapper--large-width magic-confirm-wrapper--textarea">
                                        <i className="icon-bg icon-bg--success save-btn"
                                           onClick={
                                               async (event) => {
                                                   await this.setConfirm([fieldName, valueFromStateCurrent]);
                                               }
                                           }
                                        />

                                        <MagicConfirm
                                            onAccept={this.confirmAcceptHandler(fieldName, valueFromStatePrev)}
                                            onReject={this.confirmRejectHandler(fieldName, valueFromStatePrev)}
                                            title={translate(`magic_confirm_title`)}
                                            accept={translate(`magic_confirm_yes`)}
                                            reject={translate(`magic_confirm_no`)}
                                            isVisible={!!isVisible}
                                        />

                                        <MagicTextarea
                                            className={'magic-textarea magic-textarea--auto-height scroll'}
                                            value={valueFromStateCurrent.toString()}
                                            onChange={this.onChangeTextarea(fieldName)}
                                            selectionStart={this.state?.magicEditSelectionPos?.[fieldName]?.[0] ?? value?.toString()?.length ?? 0}
                                            selectionEnd={this.state?.magicEditSelectionPos?.[fieldName]?.[1] ?? value?.toString()?.length ?? 0}
                                        />

                                        <i className="gl-icon close-btn"
                                           onClick={() => this.onClickCloseConfirmBtn(fieldName, valueFromStatePrev)}
                                        />
                                    </div>
                                ) : ''}
                        </div>
                    );
                } else if (item?.access?.('show')) {
                    return (
                        <div>
                            <span className='block__info'>
                                {valueFromStateCurrent}
                            </span>
                        </div>
                    );
                }
                return '-';
            }
            default: {
                return (
                    <span className="block__info">{targetValue}</span>
                );
            }
        }
    };

    renderTitle (title) {
        return (
            <div className='title-cont'>
                <h3 className='title'>{title}</h3>
            </div>
        );
    }

    renderInformationCont (fieldsArray, castData) {
        const { translate, params, query } = this.props;

        return (
            <div className='information-cont'>
                {
                    fieldsArray.map((item, i) => {
                        return this.renderInformationContItem(item, i, castData);
                    })
                }
            </div>
        );
    }

    renderInformationContItem (fieldsPart, i, castData) {
        return (
            <div className='information-cont__item' key={`information-cont__item-${i}`}>
                <div className='blocks-wrapper'>
                    {
                        fieldsPart.map((item, i) => {
                            return (
                                <React.Fragment key={`key-${i}`}>
                                    {castData?.[item] ?? false}
                                </React.Fragment>
                            );
                        })
                    }
                </div>
            </div>
        );
    }

    texts = (fieldName) => {
        const enumAsObjectToValues = (_) => [...Object.entries(_).map(([key, value]) => value)];

        switch (fieldName) {
            case 'status':
                return enumAsObjectToValues(this.props?.enums?.tima?.TimaAgentStatusEnum);
            default:
                throw new Error(`Error index( values: ${ fieldName } }`);
        }
    };

    values = (fieldName) => {
        const enumAsObjectToKeys = (_) => [...Object.entries(_).map(([key]) => key)];

        switch (fieldName) {
            case 'status':
                return enumAsObjectToKeys(this.props?.enums?.tima?.TimaAgentStatusEnum).map((key) => +key);
            default:
                throw new Error(`Error index( values: ${ fieldName } }`);
        }
    };

    isTextareaFieldCheck = (fieldName) => {
        const textareaArray = [
            'agent_reward',
        ];

        return textareaArray.indexOf(fieldName) !== -1;
    };

    returnToPrevStateTextarea = async (fieldName, valueFromStatePrev) => {
        const magicEditValuePrev = this.state?.magicEditValuePrev?.[fieldName] ?? valueFromStatePrev;
        const valuePrevLength = magicEditValuePrev?.toString()?.length ?? 0;
        const state = {
            magicEditValueCurrent: {
                ...this.state.magicEditValueCurrent,
                [fieldName]: magicEditValuePrev,
            },

            magicEditSelectionPos: {
                ...this.state.magicEditSelectionPos,
                [fieldName]: [valuePrevLength, valuePrevLength],
            },
        };
        await this.save({ state });
    };

    onClickCloseConfirmBtn = async (fieldName, valueFromStatePrev) => {
        this.setEditMode(false);
        this.setConfirm(false);

        if (this.isTextareaFieldCheck(fieldName)) {
            this.returnToPrevStateTextarea(fieldName, valueFromStatePrev);
        }
    };

    onChange = (fieldName) => async ({ value, scrollPosY }) => {
        await this.setConfirm([fieldName, value, scrollPosY]);
    };

    onChangeTextarea = (fieldName) => async ({ value, selectionStart, selectionEnd }) => {
        await this.save({ magicEditValueCurrent: {
                ...this.state.magicEditValueCurrent,
                [fieldName]: value,
            },
            magicEditSelectionPos: {
                ...this.state.magicEditSelectionPos,
                [fieldName]: [selectionStart, selectionEnd],
            }, });
    };

    onEnterEditMode = (fieldName) => async () => {
        await this.setEditMode([fieldName]);
    };

    confirmAcceptHandler = (fieldName, valuePrev) => async (event) => {
        const confirm = this.confirmCheck(fieldName);
        if (!confirm) {
            await this.setConfirm(false);
            return;
        }
        const value = confirm[1];
        const timaAgentId = this.state?.agent.data?.tima?.tima_agents?.id;

        try {
            const fieldMap = fieldName === "tima_account" ? 'name' : fieldName;
            const data = { [fieldMap]: value };
            const { response } = await timaService.updateAgent(timaAgentId, data);
            await this.save({ agent: { data: { ...this.state.agent.data, tima: { tima_agents: response }}}});

            if (!response) {
                throw new Error(`Can*t update the fieldName: '${fieldName}', please try again later!`);
            }

            let newValue;

            switch (fieldName) {
                case 'agent_reward': {
                    newValue = value;
                    break;
                }

                case 'status': {
                    newValue = this.values(fieldName).indexOf(value);
                    break;
                }

                default: {
                    newValue = +value;
                    break;
                }
            }

            const state = {
                magicEditValuePrev: {
                    ...this.state.magicEditValuePrev,
                    [fieldName]: newValue,
                },
            };

            await this.save({ state });

        } catch (error) {
            NotificationService.error({
                title:   "error",
                message: error.message,
                remove:  false,
            });

            if (this.isTextareaFieldCheck(fieldName)) {
                await this.returnToPrevStateTextarea(fieldName, this.state?.magicEditValuePrev?.[fieldName] ?? valuePrev);
            }
        }
        await this.setConfirm(false);
        await this.setEditMode(false);
    };

    confirmRejectHandler = (fieldName, valuePrev) => async (event) => {
        await this.setConfirm(false);
        await this.setEditMode(false);
    };

    confirmCheck = (fieldName) => {
        const { confirm } = this.state;
        return confirm instanceof Array && confirm[0] === fieldName ? confirm : false;
    };

    editModeCheck = (fieldName) => {
        const { editMode } = this.state;
        return editMode instanceof Array && editMode[0] === fieldName ? editMode : false;
    };

    setConfirm = async (confirm) => {
        await this.save((state) => ({ ...state, confirm }));
    };

    setEditMode = async (editMode) => {
        await this.save((state) => ({ ...state, editMode }));
    };

    render () {
        const { fieldsForChecking, translate, enums, scale } = this.props;
        const { agent: { data }, loaded } = this.state;
        const castData = PermissionService.cast(data, this.OneTimaAgentConfig);

        return (
            <Loader
                loaded={loaded && !!enums}
                loading={(<Preloader scale={scale}/>)}
                translate={translate}
            >
                <div className='glalex-styles tima'>
                    {this.renderTitle(data?.tima?.tima_agents?.name)}

                    {this.renderInformationCont(fieldsForChecking, castData)}
                </div>
            </Loader>
        );
    }
}

OneTimaAgent.defaultProps = {
    fieldsForChecking: [
        [
            'timaAgentName',
            'timaManagerName',
            'timaAgentStatus',
            'timaAgentReward',
        ],
        [
            'client',
            'responsible',
            'updatedAt',
            'createdAt',
        ],
    ],

    // editableFields:         [
    //     'status',
    //     'agent_reward',
    // ],
    // editableTextareaFields: [
    //     'agent_reward',
    // ],
};

export default withLocalize(OneTimaAgent);
