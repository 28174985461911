import React, { Component } from 'react';
import { Row, Col, Table } from 'reactstrap';
import Select from 'react-select';
import {Translate} from "react-localize-redux";
import outgoing from "../../../../../images/outgoing.svg";
import person from "../../../../../images/person.svg";
import check from "../../../../../images/icon.svg";
import {Scrollbars} from "react-custom-scrollbars";
import background from "../../../../../images/background-interaction.svg";
const customStyles = {
    control: () => ({
        width: 100,
    }),
    singleValue: (base, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { opacity, transition };
    },
};

const status = [
    { value: 'success', label: 'Success' },
    { value: 'wait', label: 'Wait' },
    { value: 'fail', label: 'Fail' }
];

export default class InteratorActiveCall extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 'Fiction-writing also has modes: action, exposition,  and transition writing description, dialogue, summary, and transition writing also has modes',
            rows: 8,
            minRows: 10,
             select: {value: this.props.currentUser['interaction_result_type'], label: this.props.currentUser['interaction_result_type']}
        };
    }

    componentDidMount () {
        if (this.multilineTextarea) {
            this.multilineTextarea.style.height = 'auto';
        }
    }

    handleSelect = (selectedOption) => {
        this.setState({ select: selectedOption });
    }

    handleChange = (event) => {
        const textareaLineHeight = 18;
        const { minRows } = this.state;

        const previousRows = event.target.rows;
        event.target.rows = minRows; // reset number of rows in textarea

        const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

        if (currentRows === previousRows) {
            event.target.rows = currentRows;
        }
        this.setState({
            value: event.target.value,
            rows: currentRows
        });
    };

    render () {

        return (

            <Translate>
                {({ translate }) => {
                    return (
                        <div className = 'activeInteraction'>
                            <div className = 'activeInteraction__top'>
                                <Row>
                                    <Col xs = '6'>
                                        <div className = 'activeInteraction__flex flexStart'>
                                            <Table className = 'table-interaction'>
                                                <tbody>
                                                <tr>
                                                    <td className = 'bold'>{translate(`client_type`)}:</td>
                                                    <td>
                                                        <div className = 'activeInteraction__flex_info flexStart'>
                                                            <img
                                                                alt = 'call' src = {outgoing}
                                                            /> Outgoing call <span
                                                            className = 'interaction-amount'>2:35</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className = 'bold'>{translate(`client_manager`)}:</td>
                                                    <td>
                                                        <img
                                                            alt = 'person' src = { person }
                                                        /> Ambros Johnson</td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                    <Col xs = '6'>
                                        <div className = 'activeInteraction__flex flexEnd'>
                                            <Table className = 'table-interaction'>
                                                <tbody>
                                                <tr>
                                                    <td className = 'bold'>{translate(`client_created`)}:</td>
                                                    <td>
                                                        <div className = 'activeInteraction__flex_info'>
                                                            <img
                                                                alt = 'check' src = {check}
                                                            /> 8/09/2019, 15:00 pm
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className = 'activeInteraction__info'>
                                <div className='activeInteraction__info_top'>
                                        <div className = 'activeInteraction__flex_info flexStart'>
                                            <span className='bold'>{translate(`client_result`)}</span>
                                        </div>
                                        <div className = 'activeInteraction__flex_info flexEnd'>
                                            <span className='grey'>{translate(`client_status`)}</span>
                                <Select
                                    className = 'form-select interaction-select'
                                    classNamePrefix = 'select'
                                    options = { status }
                                    styles = { customStyles }
                                    onChange = { this.handleSelect }
                                    value = { this.state.select }
                                />
                                        </div>
                                </div>
                                <Scrollbars
                                    style = { { height: 410 } }
                                    renderView={props => <div {...props}/>}
                                    renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                                    renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} />}
                                >
                                    <Col>
                                        <textarea
                                            value={this.state.value}
                                            placeholder={'Enter your text here...'}
                                            className={'textarea'}
                                            ref={ref => this.multilineTextarea = ref}
                                            onChange={this.handleChange}
                                            rows={this.state.rows ? this.state.rows : 100}
                                            style={{
                                                resize: "none",
                                                fontSize: '12px',
                                                lineHeight: '18px'
                                            }}
                                    />
                                    </Col>
                                </Scrollbars>
                            <Col>
                                <div className = 'activeInteraction__flex_info flexEnd'>
                                    <div
                                        className = 'button turquoise unshadow'
                                    >{translate(`client_save`)}</div>
                                    <div>
                                    </div>
                                </div>
                            </Col>
                            </div>
                            <img className='interaction-bg' src={background} alt="background"/>
                        </div>
                    )}}
            </Translate>

        );
    }
}
