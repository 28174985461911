import React from 'react';
import './style/preloader.css';

export default class Preloader extends React.Component {
    constructor(props){
        super(props);
    }

    render() {
        const { className = '', scale = 1, style, } = this.props;

        return (
            <div className={`loaderUniversal ${className}`} style={style}>
                <div className="slack" style={{ transform: 'scale(' + scale +')' }}>
                    <span className="slack__green"></span>
                    <span className="slack__blue"></span>
                    <span className="slack__orange"></span>
                    <span className="slack__pink"></span>
                </div>
            </div>
        )
    }
}
