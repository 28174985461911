import React, { Component } from 'react';
import '../../../styles/popup.scss';

import PropTypes from "prop-types";

import { MagicConfirm } from "../../../../tima/components/Magic/MagicConfirm";
import { listAccountService } from "../../../../../services/ListAccountDataService";
import { currency, } from "../../../../../enums/currency";
import { MagicSelect, } from "../../../../../components/Magic/components/MagicSelect";
import { paymentService, } from "../../../../../services/PaymentDataService";
import { enumService, } from "../../../../../services/EnumDataService";
import moment from "moment";
import { CHECK, PROCESS, } from "../../../../../enums/get_mt_rate";
import Preloader from "../../../../../components/LoadingHOC/Preloader";
import NotificationService from "../../../../../services/NotificationService";

// const REG_EXP = {
//     general:     /^[а-яА-ЯёЁa-zA-ZІіЇїЄєҐґ0+-9*"'«»;:,.`№\s\-]{1,}$/,
//     floatNumber: /^[0-9]*[.]?[0-9]{1,2}$/,
// };

class PopupConvertion extends Component {
    constructor (props) {
        super(props);

        this.state = {
			errors: {
				isValid: {
					amount:  true,
					commission: true,
					course: true,
				},
			},
			system_accounts: {},
			gotBalanceAccounts: false,
			system_account_id: null,
			acc_ccy: null,
			value: false,
			// create btn state
			isAvailableCreateBalanceAccount: false,
			// confirm popups
			isCreateAccountConfirmVisible: false,
			isConvertConfirmVisible: false,
			// course
			timer: null,
			lock_timer: false,
			currencyPair: false,
			isEnableUpdateCourseBtn: false,
			key: null,
			course: null,
			// result
			isEnableGetResultBtn: false,
			amountToExchange: '',
			commission: '',
			convertResult: '',
			flag: false,
			loadedInnerRequest: true,
        };
    }

    save = async (state) => new Promise(next => this.setState(state, next));

	enumsChange = async (enums) => {
		if (enums.hash===this.state?.enums?.hash) {
			return `${ this.constructor.name }.enumsChange: false`;
		}
		await this.save({ enums, });

		return `${ this.constructor.name }.enumsChange: true`;
	};

    async componentDidMount() {
    	this.getBalanceAccounts();
		// listAccountService.subscribe('systemAccounts4Account', this.getBalanceAccounts, this);

		enumService.subscribe('enums', this.enumsChange, this);
		await enumService.enums;

		document.addEventListener("visibilitychange", this.handleVisibility);
	}

	UNSAFE_componentWillMount() {
		enumService.unsubscribe('enums', this.enumsChange, this);
		document.removeEventListener("visibilitychange", this.handleVisibility);
	}

	componentWillUnmount() {
    	clearInterval(this.courseTimer);
	}

	handleVisibility = () => {
    	// console.log(111, document.visibilityState);
    	// console.log(222, document.hidden);
		if (!document.hidden) {
			this.handleInterval();
		}
	}

	get timerDiff () {
    	return !this.state.timer ? null : moment().diff(moment(this.state.timer), 'seconds');
	}

	getBalanceAccounts = async () => {
		const { accountId, } = this.props;
		const response = await listAccountService.systemAccounts4AccountPure(accountId);
		const { data, } = response;
		const { value, } = this.state;
		const { STATUS_ARCHIVED, TYPE_BALANCE, } = enumService.systemAccountEnum;

		const active_balance_accounts = [];

		if (data) {
			data.forEach((item, i) => {
				const system_account = item?.core?.system_accounts;

				if (system_account) {
					if (system_account.type === TYPE_BALANCE &&
						system_account.status !== STATUS_ARCHIVED &&
						system_account.id !== this.props.id) {
						active_balance_accounts.push(system_account)
					}
				}
			})

			this.save({ system_accounts: active_balance_accounts, gotBalanceAccounts: true, })

			if (value.length) {
				const [acc_ccy, system_account_id] = value;

				if (system_account_id === undefined) {
					const found = active_balance_accounts?.find?.((data => data.acc_ccy === +acc_ccy));

					if (found) {
						this.save({ value: [acc_ccy, found.id]})
					}
				}
			}
		}
	}

	renderBalanceAccountSelect = () => {
    	const valueDefault = false;
    	const systemAccounts = this.state.system_accounts;

		const currencies = this.state.enums?.data?.core?.AccountCurrencyEnum ?? {};
		const values = [
			valueDefault,
			...Object.entries(currencies)
				.filter(([currencyId]) => this.props.systemAccountAccCcy !== +currencyId)
				.map(([currencyId]) => [
					currencyId,
					systemAccounts?.find?.(sa => sa.acc_ccy === +currencyId)?.id,
				]),
		];
    	const options = {
			className: [this.state?.errors?.system_account_id ? 'error' : '', 'convert-select'],
			onChange: async (value) => {
				this.save({ value, });

				if (value === false) {
					this.save({ currencyPair: false, isEnableUpdateCourseBtn: false, isEnableGetResultBtn: false, });
				}

				this.save({ isAvailableCreateBalanceAccount: false, });

				if (value.length) {
					await this.save({ currencyPair: this.getCurrencyPair(this.props.systemAccountAccCcy, value?.[0]), });

					// get exchange course after get second currency
					await this.save({ lock_timer: true, });
					await this.getCurrentCourse();

					const [_, systemAccountId] = value;
					if (systemAccountId === undefined) {
						this.save({ isAvailableCreateBalanceAccount: true, });
					}
				}
			},
			value: this.state?.value ? this.state?.value : valueDefault,
			values,
			valueToLabel: (_) => {
				if (_ === valueDefault) {
					return (
						<div className="popup-convertation__magic-select-items_default">
							{ this.props.translate('list_accounts_system_account_convert_choose_account') }
						</div>
					);
				}
				try {
					const [ currencyId, systemAccountId, ] = _;
					return (
						<div className="popup-convertation__magic-select-items">
							<div className="popup-convertation__currency">
								{ currencies[currencyId] ?? '?' }
							</div>
							<div className="popup-convertation__balance-account">{ systemAccountId }</div>
						</div>
					);
				} catch (error) {
					return null;
				}
			},
			label: (_) => {
				if (_ === valueDefault) {
					return (
						<div className="popup-convertation__magic-select">
							{ this.props.translate('list_accounts_system_account_convert_choose_account') }
						</div>
					);
				}
				try {
					const [ currencyId, systemAccountId, ] = _;
					return (
						<div className="popup-convertation__magic-select">
							<div className="popup-convertation__currency">
								{ currencies[currencyId] ?? '?' }
							</div>
							<div className="popup-convertation__balance-account">{ systemAccountId }</div>
						</div>
					);
				} catch (error) {
					return null;
				}
			},
			disabled: !this.state.gotBalanceAccounts,
		}

    	return (
    		<MagicSelect { ...options } />
		);
	}

	handleOnClickCreateBalanceAccount = async () => {
		this.save({ isCreateAccountConfirmVisible: true, })
	}

	handleCreateBalanceAccount = async (currency_str) => {
		try {
			return await paymentService.createSystemAccount({
				account_id: this.props.accountId,
				acc_ccy: currency_str,
				type: enumService.systemAccountEnum.TYPE_BALANCE,
			});
		} catch (error) {
			error?.showErrorNotification?.();
		}
	}

	handleConfirmCreateBalanceAccount = async event => {
    	this.save({ isCreateAccountConfirmVisible: true, })
		const { value, } = this.state;
    	const [acc_ccy, _,] = value;

    	const res = this.handleCreateBalanceAccount(acc_ccy);

		this.handleRejectCreateBalanceAccount();

    	if (res !== undefined) {
			await this.getBalanceAccounts();

			await this.getCurrentCourse();
		}
	}

	handleRejectCreateBalanceAccount = async () => {
    	this.save({ isCreateAccountConfirmVisible: false, isAvailableCreateBalanceAccount: false})
	}

	handleOnClickConvertion = async () => {
    	this.save({ isConvertConfirmVisible: true, });
	};

	handleConfirmConvertion = async event => {
		this.save({ isConvertConfirmVisible: false, });

		await this.getConvertionRequest(PROCESS);

		this.props.popUpClose();

		// update balance history observer
		await listAccountService.systemBalanceHistory(this.props.id, {take: 7, skip: 0});
		await this.props.refreshChildData();
	}

	handleRejectConvertion = async () => {
		this.save({ isConvertConfirmVisible: false, });
	}

	handleInterval = async () => {
		const diff = this.timerDiff;

		if (!this.state.timer) {
			console.log('no timer');
		} else if (diff >= 30 * 60 && this.state.value && !this.state.lock_timer) {
			this.save({ lock_timer: true, });

			await this.getCurrentCourse();
		} else {
			this.save({})
		}
		// console.log("nextTime ", nextTime);

		// todo if achive 0
		// todo take mt course again
	};

	courseTimer = setInterval(this.handleInterval, 1000);

    get timerToString () {
		const timer = 30 * 60 - this.timerDiff;

		let minutes = Math.floor(timer / 60);
		let seconds = timer % 60;

		minutes = minutes > 9 ? minutes : minutes > 0 ? '0' + minutes : '00';
		seconds = seconds > 9 ? seconds : seconds > 0 ? '0' + seconds : '00';

    	return this.timerDiff ? `${minutes}:${seconds}` : '00:00';
	}

	// checkCurrencyState = () => {
    // 	if (
    // 		this.state?.value &&
	// 		this.state?.value !== false &&
	// 		this.state?.value[1] !== undefined &&
	// 		this.state?.currencyPair === false &&
	// 		this.props.systemAccountAccCcy
	// 	) {
    // 		this.save({
	// 			currencyPair: this.getCurrencyPair(this.props.systemAccountAccCcy, this.state?.value?.[0]),
	// 			isEnableUpdateCourseBtn: true,
	// 		});
	// 	}
	// }

	// checkGetResultBtn = () => {
    // 	this.save({ isEnableGetResultBtn: true, });
	// }

	getCurrencyPair = (currencyFrom, currencyTo) => {
		console.log(4, +currencyFrom, +currencyTo);
		return [currency[+currencyFrom], currency[+currencyTo]];
	}

	getCurrentCourse = async () => {
		const { currencyPair, } = this.state;

		await this.save({ loadedInnerRequest: false, });

		try {
    		const response = await listAccountService.currencyCourseRaw(
    			currencyPair[0], currencyPair[1], moment(new Date()).format('YYYY-MM-DD'));

			if (response) {
				const key = Object.keys(response)[0];
				const course = Object.values(response)[0];

				this.save({ key, course, timer: new Date(), });
			}
		} catch (error) {
			this.save({ loadedInnerRequest: true, });
			console.error(error);
		}

		await this.save({ loadedInnerRequest: true, });
		await this.save({ lock_timer: false, });
	}

	handleCurrentCourse = async (e) => {
		const value = e.target.value;
		const { errors, } = this.state;

		errors['isValid']['course'] = true;
		this.save({ errors, });

		new RegExp('^(?:0|[1-9][\\d]*?)?(?:[\.][\\d]*)?$').test(value) && this.save({ course: value, });
	}

	handleAmountToExchangeInput = async (e) => {
    	e.preventDefault();

    	const value = e.target.value;
		const { errors, } = this.state;

		if (isNaN(value)) {
			errors['isValid']['amount'] = false;
			await this.save({ errors, })
		} else {
			errors['isValid']['amount'] = true;
			await this.save({ errors, })
		}

		if (new RegExp('^(?:0|[1-9][\\d]*?)?(?:[\.][\\d]{0,2})?$').test(value)) {
			await this.save({ amountToExchange: value, });
		}
	}

	validateAmountToExchangeInput = async (e) => {
    	const value = e.target.value;
		const { errors, } = this.state;

		if (+value >= 0.01 && +value <= 9999999.99) {
			errors['isValid']['amount'] = true;
			this.save({ errors, })
		} else {
			errors['isValid']['amount'] = false;
			this.save({ errors, })
			this.pushMessage("error", this.props.translate('list_accounts_system_account_convert_write_off') + ': Must be between 0.01 and 9999999.99', true);
		}
	}

	pushMessage = (type, value, is_remove = false) => {
		NotificationService.error({
			title:   type,
			message: value,
			remove:  is_remove,
		});
	}

	handleCommissionInput = async (e) => {
		e.preventDefault();

		const value = e.target.value;
		const { errors, } = this.state;

		if (isNaN(value)) {
			errors['isValid']['commission'] = false;
			await this.save({ errors: errors, })
		} else {
			errors['isValid']['commission'] = true;
			await this.save({ errors: errors, })
		}

		if (new RegExp('^(?:0|[1-9][\\d]*?)?(?:[\.][\\d]{0,2})?$').test(value)) {
			await this.save({ commission: value, });
		}
	}

	validateCommissionInput = async (e) => {
		const value = e.target.value;
		const { errors, } = this.state;

		if (+value >= 0.00 && +value <= 100) {
			errors['isValid']['commission'] = true;
			this.save({ errors: errors, })
		} else {
			errors['isValid']['commission'] = false;
			this.save({ errors: errors, })
			this.pushMessage("error", this.props.translate('list_accounts_system_account_convert_commissiion') + ': Must be between 0.00 and 100', true);
		}
	}

	getConvertionRequest = async (mode) => {
		this.save({ loadedInnerRequest: false, });

		try {
			const res = await paymentService.convertBetweenSystemAccounts(
				this.props.id,
				{
					system_account: this.state?.value?.[1],
					amount: this.state?.amountToExchange,
					commission: this.state?.commission,
					rate: this.state?.course,
					mode: mode,
				});

			const convertResult = res?.counted_amount ?? null;

			this.save({ convertResult, });
		} catch (error) {
			error?.showErrorNotification?.();

			this.errorsHandler(error);
		}

		this.save({ loadedInnerRequest: true, });
	}

	handleGetResultBtn = async () => {
		await this.getConvertionRequest(CHECK);
	}

	get isEnableUpdateCourseBtn () {
		if (this.state?.value && this.state?.value !== false) {
			return true;//	this.state?.value &&
					// this.state?.value !== false &&
					// this.state?.value[1] !== undefined &&
					// this.state?.currencyPair === false &&
					// this.props.systemAccountAccCcy;
		}
	}

	get _isEnableGetResultBtn() {
		const { amountToExchange, commission, course, isEnableGetResultBtn, errors, value } = this.state;
		const hasErrors = errors['isValid']['amount'] && errors['isValid']['commission'];

		return amountToExchange && commission && course && !isEnableGetResultBtn && hasErrors && value[1];
	}

	get _isConvertConfirmEnabled() {
    	const { convertResult, errors, } = this.state;
		const hasErrors = errors['isValid']['amount'] && errors['isValid']['commission'];

    	return convertResult && this._isEnableGetResultBtn && hasErrors;
	}

    render () {
    	const { translate, id, systemAccountValue, systemAccountAccCcy, } = this.props;
    	const currency_str = currency[systemAccountAccCcy] ? currency[systemAccountAccCcy] : 'USD';
    	const {
    		isCreateAccountConfirmVisible,
			isConvertConfirmVisible,
			// isEnableUpdateCourseBtn,
			key,
			course,
			// isEnableGetResultBtn,
			convertResult,
			errors,
			loadedInnerRequest,
		} = this.state;
		const errorClassForAmount = errors['isValid']['amount'] ? '' : 'error';
		const errorClassForCommission = errors['isValid']['commission'] ? '' : 'error';
		const errorClassForCourse = errors['isValid']['course'] ? '' : 'error';

    	// if () {
    	// 	this.checkGetResultBtn();
		// }

        return (
            <div className='pop-up pop-up--active'>

				{!loadedInnerRequest ?
					(
						<Preloader className='loaderUniversal--fixed-pos' scale={1}/>
					) : false
				}

				<div className="convert-modal pop-up-wrapper scroll">

					<div className="convert-header pop-up-header">
						<h3 className="left pop-up__name">
							{`${translate('list_accounts_system_account_convert_title')}`}
						</h3>
						<i className='gl-icon close-btn close-btn--big' onClick={this.props.popUpClose}/>
					</div>

					<div className="content">

						<div className="convert-account-title row">
							<span>{translate('list_accounts_system_account_convert_current_account')}: {id}, {translate('list_accounts_system_account_convert_equity')} {systemAccountValue ?? Number(0).toFixed(2) } {currency_str}</span>
						</div>

						<div className="row">
							<div className="convert-title">{translate('list_accounts_system_account_convert_balance_account')}*</div>
							<div className="convert-block">
								<div className="convert-input-vasa">
									{/*<input type="text" placeholder="Выберите балансовый счёт"/>*/}
									{ this.renderBalanceAccountSelect() }
								</div>
								<div className="magic-confirm-wrapper">
									<MagicConfirm
										onAccept={this.handleConfirmCreateBalanceAccount}
										onReject={this.handleRejectCreateBalanceAccount}
										title={this.props.translate(`magic_confirm_title`)}
										accept={this.props.translate(`magic_confirm_yes`)}
										reject={this.props.translate(`magic_confirm_no`)}
										isVisible={!!isCreateAccountConfirmVisible}
									/>
									<button
										className={`convert-btn gl-btn gl-btn--blue-dark ${
											this.state.isAvailableCreateBalanceAccount 
												? '' : 'gl-btn--disabled'}`}
										onClick={this.handleOnClickCreateBalanceAccount}
									>
										{translate('list_accounts_system_account_convert_create_btn')}
									</button>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="convert-title">{translate('list_accounts_system_account_convert_write_off')}*</div>
							<div className="convert-block">
								<div className={`convert-input ${errorClassForAmount}`}>
									<input
										type="text"
										placeholder={translate('list_accounts_system_account_convert_enter_the_sum')}
										onChange={this.handleAmountToExchangeInput}
										onBlur={this.validateAmountToExchangeInput}
										value={this.state.amountToExchange}
										autoComplete='off'
									/>
								</div>
							</div>
						</div>

						<div className="row">
							<div className="convert-title">{translate('list_accounts_system_account_convert_commissiion')} %</div>
							<div className="convert-block">
								<div className={`convert-input convert-input--short ${errorClassForCommission}`}>
									<input
										type="text"
										placeholder={translate('list_accounts_system_account_convert_commission_percentage')}
										onChange={this.handleCommissionInput}
										onBlur={this.validateCommissionInput}
										value={this.state.commission}
										autoComplete='off'
									/>
								</div>
								<span className="convert-span">
									%
								</span>
							</div>
						</div>

						<div className="row">
							<div className="convert-title">
								{translate('list_accounts_system_account_convert_conversion_rate')}*
								<div className="convert-timer push">
									{this.timerToString}
								</div>
							</div>
							<div className="convert-block">
								<div className={`convert-input ${errorClassForCourse}`}>
									<input
										type="text"
										placeholder={translate('list_accounts_system_account_convert_enter_conversion_rate')}
										value={course}
										onChange={this.handleCurrentCourse}
									/>
									<span>{key}</span>
								</div>
								<button
									className={`convert-btn gl-btn gl-btn--blue-dark 
										${this.isEnableUpdateCourseBtn ? '' : 'gl-btn--disabled'}`}
									onClick={this.getCurrentCourse}
								>
									{translate('list_accounts_system_account_convert_update_btn')}
								</button>
							</div>
						</div>

						<div className="row">
							<div className="convert-title">{translate('list_accounts_system_account_convert_enter_conversion_result')}:</div>
							<div className="convert-block">
								<div className="convert-input">
									{/*<span className="input__text">{convertResult}</span>*/}
									<input
										type="text"
										placeholder="0.00"
										value={convertResult}
										disabled={true}
										autoComplete='off'
									/>
								</div>
								<button
									className={`convert-btn gl-btn gl-btn--blue-dark 
									${this._isEnableGetResultBtn ? '': 'gl-btn--disabled'}`}
									onClick={this.handleGetResultBtn}
								>
									{translate('list_accounts_system_account_convert_get_course_btn')}
								</button>
							</div>
						</div>

					</div>

					<div className="btns-cont">

						<button
							className="gl-btn gl-btn--blue-border"
							onClick={this.props.popUpClose}
						>
							{translate('partners_cancel_btn')}
						</button>

						<div className="magic-confirm-wrapper">
							<MagicConfirm
								onAccept={this.handleConfirmConvertion}
								onReject={this.handleRejectConvertion}
								title={this.props.translate(`magic_confirm_title`)}
								accept={this.props.translate(`magic_confirm_yes`)}
								reject={this.props.translate(`magic_confirm_no`)}
								isVisible={!!isConvertConfirmVisible}
							/>

							<button
								className={`gl-btn gl-btn--turquoise
								${this._isConvertConfirmEnabled ? '' : 'gl-btn--disabled'}`}
								onClick={this.handleOnClickConvertion}
							>
								{translate('list_accounts_system_account_convert_confirm_btn')}
							</button>
						</div>

					</div>
				</div>
            </div>
        );
    }

	errorsHandler(error) {
		const { validation_errors, } = error;
		const { errors, } = this.state;

		const mapToFields = {
			amount: 'amount',
			commission: 'commission',
			rate: 'course',
		}

		if (typeof validation_errors === "object") {
			for (const [key, value] of Object.entries(validation_errors)) {
				let field = mapToFields[key];

				if (field) {
					errors['isValid'][field] = false;
				}
			}
		}
	}
}

export default (PopupConvertion);

PopupConvertion.propTypes = {
    popUpClose:       PropTypes.func.isRequired,
    refreshChildData: PropTypes.func.isRequired,
};
