export const adminKpiLang = {
  "adminKpi_sales_planning": ["Sales planning", "Планирование продаж", "Планування продажів"],
  "adminKpi_year":["Year","Год","Рік"],
  "adminKpi_table":["Table", "Таблица", "Таблиця"],
  "adminKpi_apply":["Apply", "Применить", "Застосувати"],
  "adminKpi_filters": ["Filters", "Фильтры", "Фільтри"],
  "adminKpi_full_name": ["Full Name", "ФИО", "ПІБ"],
  "adminKpi_department": ["Department", "Департамент", "Департамент"],
  "adminKpi_position":["Position","Должность","Посада"],
  "adminKpi_calls":["Calls","Звонки","Дзвінки"],
  "adminKpi_dialogues":["Dialogues","Диалоги","Діалоги"],
  "adminKpi_check":["Average check","Средний чек","Середній чек"],
  "adminKpi_history":["History", "История", "Історія"],
  "adminKpi_edit":["Edit", "Редактировать", "Редагувати"],
  "adminKpi_save":["Save", "Сохранить", "Зберегти"],
}