import { simpleRequest} from "../../../services/RequestService";
import axios from 'axios';

import {GET_USER_FORGOT_PASSWORD, GET_USER_RESET_PASSWORD} from "../apiRoutes";

export const resetPassword = (obj) => {
    return simpleRequest.post(GET_USER_FORGOT_PASSWORD, obj)
        .then(
            (response) => Promise.resolve(response.data),
            (error) => Promise.reject(error.response.data)
        );
}

export const resetForm = (obj) => {
    return axios.post(GET_USER_RESET_PASSWORD, obj)
        .then(
            (response) => Promise.resolve(response.data),
            (error) => Promise.reject(error.response.data)
        );
}
