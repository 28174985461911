import React from 'react';
import { withLocalize } from "react-localize-redux";
import moment from "moment";
import { map } from "lodash";
import TaskBlock from "./TaskBlock";
import PropTypes from "prop-types";

class CalendarWeekMagic extends React.Component {
    static propTypes = {
        prevWeek: PropTypes.func.isRequired,
        nextWeek: PropTypes.func.isRequired,
    };

    constructor (props) {
        super(props);

        this.state = {
            tasks:        props.tasks,
            selectedWeek: props.selectedWeek,
            locale:       props.activeLanguage?.code,
        };
    }

    componentDidMount () {

    }

    save = async (state) => new Promise((next) => this.setState(state, next));

    render () {
        const { locale, tasks, selectedWeek } = this.state;
        const currentDate = moment().locale(locale);

        const renderCalendarHeaderControls = () => {
            const selectedDateStart = moment(selectedWeek).startOf('week');
            const selectedDayStart = selectedDateStart.get('date');
            const selectedDayNameStart = selectedDateStart.locale(locale).format('dddd');
            const selectedMonthStart = selectedDateStart.locale(locale).format('MMMM');
            const selectedYearStart = selectedDateStart.get('year');

            const selectedDateEnd = moment(selectedWeek).endOf('week');
            const selectedDayEnd = selectedDateEnd.get('date');
            const selectedDayNameEnd = selectedDateEnd.locale(locale).format('dddd');
            const selectedMonthEnd = selectedDateEnd.locale(locale).format('MMMM');
            const selectedYearEnd = selectedDateEnd.get('year');

            return (
                <div className='calendar calendar-week'>
                    <div className='calendar-week-header calendar-day-header header row flex-middle'>
                        <div className='col col-start'>
                            <div className='icon' onClick={this.props.prevWeek}>
                                <i className='la la-angle-left'/>
                            </div>
                        </div>
                        <div className='col col-center'>
                        <span>
                            {selectedDayStart} {`${selectedDayNameStart}, ${selectedMonthStart} ${selectedYearStart}`}
                            &nbsp;&nbsp;&mdash;&nbsp;&nbsp;
                            {selectedDayEnd} {`${selectedDayNameEnd}, ${selectedMonthEnd} ${selectedYearEnd}`}
                        </span>
                        </div>
                        <div className='col col-end'>
                            <div className='icon' onClick={this.props.nextWeek}>
                                <i className='la la-angle-right'/>
                            </div>
                        </div>
                    </div>
                </div>
            );
        };

        const renderCalendarHeader = () => {
            const daysInWeek = [];

            for (let dayIndex = 1; dayIndex <= 7; dayIndex++) {
                const targetDate = moment(selectedWeek).locale(locale).day(dayIndex);
                const targetWeekDayName = targetDate.format('ddd');
                const targetWeekDay = targetDate.format('D');
                const isSameDay = moment(currentDate).isSame(targetDate, 'day');
                const isActiveDayClass = isSameDay ? 'active' : '';

                daysInWeek.push(
                    <div className={`hour-row-day ${isActiveDayClass}`}
                         key={`header-${dayIndex}`}
                    >
                        <div>
                            {targetWeekDayName}
                        </div>
                        <div>
                            {targetWeekDay}
                        </div>
                    </div>
                );
            }

            return daysInWeek;
        };

        const renderCalendarHours = (tasksAll) => {
            return map(tasksAll, (tasksPerHour, indexHour) => renderCalendarHour(tasksPerHour, indexHour));
        };

        const renderCalendarHour = (tasksPerHour, indexHour) => {
            const dateFormat = "HH:00";
            const startDate = moment(selectedWeek).startOf('date');
            const currentHour = moment().hour();

            const isActiveHourClass = moment(startDate).add(indexHour, 'hours').hour() === currentHour ? 'active' : '';

            return (
                <div className='hour-row' key={indexHour}>
                    <div className='hour-row__hour-value-wrapper'>
                        <div className='hour-row__hour-value'>
                            {moment(startDate).add(indexHour, 'hours').format(dateFormat)}
                        </div>
                    </div>
                    <div
                        className={`hour-row__hour-task calendar-day-task-wrapper hour-row--week ${isActiveHourClass}`}>
                        {
                            map(tasksPerHour, (tasksPerDay, indexDay) => {
                                indexDay = +indexDay + 1;
                                const targetDate = moment(selectedWeek).locale(locale).day(indexDay);
                                const isSameDay = moment(currentDate).isSame(targetDate, 'day');
                                const isActiveDayClass = isSameDay ? 'active' : '';

                                return (
                                    <div className={`hour-row-day day-title ${isActiveDayClass}`}
                                         key={`day-${indexHour}-${indexDay}`}
                                    >
                                        {renderCalendarDay(tasksPerDay, indexHour, indexDay)}
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            );
        };

        const renderCalendarDay = (tasksPerDay, indexHour, indexDay) => {
            const { avatar } = this.props;

            return (tasksPerDay instanceof Array) && tasksPerDay.map((item, index) => {
                return (
                    <TaskBlock
                        avatar={avatar}
                        i={index}
                        item={item}
                        key={`task-block-${indexHour}-${indexDay}-${index}`}
                    />
                );
            });
        };

        return (
            <div className='table-wrapper'>
                <div className='position-relative'>
                    {renderCalendarHeaderControls()}

                    <div className='hour-wrapper hour-wrapper--week'>
                        <div className='hour-task-row'>
                            <div className='hour-rows-wrapper'>
                                <div className='hour-row hour-row--th'>
                                    <div className='hour-row__hour-value-wrapper'/>
                                    <div className='hour-row__hour-task calendar-day-task-wrapper hour-row--week'>
                                        {renderCalendarHeader()}
                                    </div>
                                </div>
                                {renderCalendarHours(tasks)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withLocalize(CalendarWeekMagic);
