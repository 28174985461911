const permissions = [
	// Payments
	{
		path: [ "payment", "orders", ],
		key: "menuPaymentsList",
	},
	//Payments Summary
	{
		path: [ 'payment', 'daily_aggregated_fin_transactions', ],
		key: "menuPaymentsSummary",
	},
];

export default permissions;