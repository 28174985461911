import React from 'react';
import PropTypes from 'prop-types';
import { Mentor, } from '../../services/Mentor';
import { MagicFilterFast, } from '../MagicFilterFast';

export class MagicFilterPanel extends React.Component {
  static propTypes = {
    mf: PropTypes.instanceOf(Mentor).isRequired,
    panel: PropTypes.shape({
      id: PropTypes.string,
      items: PropTypes.oneOfType([
        PropTypes.object.isRequired,
        PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
      ]).isRequired,
      translate: PropTypes.string.isRequired,
    }).isRequired,
    translate: PropTypes.func.isRequired,
  }

  renderItem = (filter) => (
    <MagicFilterFast
      key={ filter.$id }
      mf={ this.props.mf }
      filter={ filter }
    />
  );

  onEnabled = async (event) => {
    event.preventDefault();
    const { mf, panel, } = this.props;
    const { enabled, items, } = panel;

    if (panel.onEnabled instanceof Function) {
      await panel.onEnabled({ enabled: +!enabled, mentor: mf, panel, });
    } else if (Array.isArray(items)) {
      // eslint-disable-next-line no-unused-vars
      for (const item of items) {
        await mf.filterChange({
          id: item.$id,
          setter: { enabled: +!enabled, },
          $$changed: true,
        });
      }
    } else {
      await mf.filterChange({
        id: items.$id,
        setter: { enabled: +!enabled, },
        $$changed: true,
      });
    }
  };

  async componentDidMount () {
    const { mf, panel, } = this.props;
    const { enabled, } = panel;

    if (panel.onEnabled instanceof Function) {
      await panel.onEnabled({ enabled, mentor: mf, panel, $$changed: false, });
    }
  }

  render () {
    const { mf, panel, } = this.props;
    const items = () => panel.render instanceof Function
      ? panel.render({ mentor: mf, panel, })
      : Array.isArray(panel.items)
        ? panel.items.map(this.renderItem)
        : panel.items
          ? this.renderItem(panel.items)
          : null;

    return (
      <div className="magic-filter-panel" data-enabled={ panel.enabled } >
        <span
          className="magic-filter-panel__field"
          data-fe-enabled={ panel.$enabled }
          data-empty={ panel.empty }
          data-enabled={ panel.enabled }
          data-filled={ panel.filled }
          data-filling={ panel.filling }
          onClick={ this.onEnabled }
        >
          { panel.label }
        </span>
        { panel.enabled ? items() : null }
      </div>
    );
  }
};