import React, { Component, } from 'react';
import { withLocalize, } from 'react-localize-redux';
import { Breadcrumbs, } from '../../../../tima/components/Breadcrumbs';
import { ToolbarPanel, } from '../../../../tima/components/Toolbar';
import { withRouter, } from 'react-router-dom';
import * as configBreadcrumbs from './PaymentsLinkBreadcrumbsConfig';
import * as configToolbar from './PaymentsLinkToolbarConfig';
import OnePaymentOrderTable from './OnePaymentOrderTable';
import ListPayments from './ListPayments';
import CreateRequestPopup from './PaymentRequestPopup';
import {
	MagicFilterPanels,
	MagicButton,
	Mentor,
	showExportToExcelError,
} from '../../../../../components/Magic';
import { GET_PAYMENT_ORDERS, } from '../../../apiRoutes';
import { PAYMENTS_EXPORT_FIELDS, } from '../consts/PAYMENT_EXPORT_FIELDS';
import { securedRequest, } from '../../../../../services/RequestService';
import PermissionService from '../../../../../services/PermissionService';
import '../../../styles/payments.scss';
import { enumService, } from '../../../../../services/EnumDataService';
import {
	getAllDepartmentsByUserId,
} from "../../../../dashboard/services/DashboardRequestService";
import { profileService, } from "../../../../../services/ProfileDataService";

const TAB_ID = {
	order: 'order',
	orders: 'orders',
};

Object.freeze(TAB_ID);

const breadcrumbs = (translate, activeTab, { orderId, }) => {
	const config = configBreadcrumbs;

	switch (activeTab) {
		case TAB_ID.order:
			return config.breadcrumbs4Order(translate, orderId);
		default:
		case TAB_ID.orders:
			return config.breadcrumbs4Orders(translate);
	}
};

const toolbar = (translate, activeTab, { orderId, }) => {
	const config = configToolbar;

	switch (activeTab) {
		case TAB_ID.strategy:
			return config.toolbarByOrder(translate, 'order', orderId);
		default:
		case TAB_ID.strategies:
			return config.toolbarInit(translate, 'orders');
	}
};

class Payments extends Component {
	constructor (props) {
		super(props);
		this.state = {
			requestPopupIsOpen: false,
			confirm: false,
			editMode: false,
			userId: null,
			departmentsList: [],
			departmentsListNames: [],
		};

		const mf = new Mentor({
			owner: this,
			serviceId: 192,
			translate: this.props.translate,
		});

		Object.defineProperty(this, 'mf', { get: () => mf, });
	}

  setConfirm = (confirm) => {
  	this.setState(state => ({ ...state, confirm, }));
  };

  setEditMode = (editMode) => {
  	this.setState(state => ({ ...state, editMode, }));
  };

  get currency () {
  	return this.state?.enums?.data?.core?.AccountCurrencyEnum ?? {};
  }

  save = async state => new Promise(next => this.setState(state, next));

  enumsChange = async (enums) => {
  	if (enums.hash === this.state?.enums?.hash) {
  		return `${ this.constructor.name }.enumsChange: false`;
  	}
  	await this.save({ enums, });

  	return `${ this.constructor.name }.enumsChange: true`;
  };

  handlerPopup = () => this.setState(state => ({ requestPopupIsOpen: !state.requestPopupIsOpen, }));

  get params () {
  	return this.props?.match?.params;
  }

  get query () {
  	return new URLSearchParams(this.props?.location?.search);
  }

  pagination = () => {};

  get paymentConfig () {
  	return [
  		{
  			path: [ 'payment', 'orders', ],
  			key: 'paymentsExport',
  		},
  		{
  			path: [ 'payment', 'orders', 'id', ],
  			key: 'paymentsCreate',
  		},
  	];
  }
  profileChange = async (profile) => {
  	if (profile.hash===this.state?.profile?.hash) {
  		return `${ this.constructor.name }.profileChange: false`;
  	}
  	await this.save({ profile, userId: profile.data.id, });

  	return `${ this.constructor.name }.profileChange: true`;
  };

  async componentDidMount () {
  	enumService.subscribe('enums', this.enumsChange, this);
  	profileService.subscribe('profile', this.profileChange, this);
  	await profileService.profile;
  	await enumService.enums;
  	try {
  		const responseDep = await getAllDepartmentsByUserId(this.state?.userId);
  		const departmentsList = responseDep?.data;
  		const departmentsListNames = departmentsList?.map(item => item?.alias);


  		await this.save({ departmentsList, departmentsListNames, });
  	} catch (error) {
  		console.log(error);
  	}
  }

  componentWillUnmount () {
  	enumService.unsubscribe('enums', this.enumsChange, this);
  }

  exportFile = () => {
  	const { filterId: filter_id, } = this.mf;
  	const globalUrl = `${ GET_PAYMENT_ORDERS }`;
  	const exportUrl = `export-to-excel`;
  	const url = `${ globalUrl }/${ exportUrl }`;
  	const options = {
  		file_name: `Payments_list`,
  		fields: PAYMENTS_EXPORT_FIELDS.payments,
  	};

  	if (filter_id) {
  		options.filter_id = filter_id;
  	}

  	securedRequest.post(url, options)
  		.then(() => {
      //  console.log('ok');
  		})
  		.catch((error) => {
  			showExportToExcelError(error);
  		});
  };

  renderButtonExport = () => {
  	const conf = PermissionService.calc(this.paymentConfig);

  	if (conf?.paymentsExport.access('exportToExcel')) {
  		const options = {
  			className: "magic-button__item magic-button__item_export",
  			children: this.props.translate("contests_toollbar_export"),
  			onClick: () => this.exportFile(),
  		};

  		return (<MagicButton { ...options } />);
  	}

  	return "";
  };

  renderButtonCreate = () => {
  	const conf = PermissionService.calc(this.paymentConfig);

  	if (conf?.paymentsCreate.access('store')) {
  		const options = {
  			className: "magic-button__item magic-button__item_create-request-payments",
  			children: this.props.translate("payment_button_create_request"),
  			onClick: () => this.setState({ requestPopupIsOpen: !this.state.requestPopupIsOpen, }),
  		};

  		return (<MagicButton { ...options } />);
  	}

  	return "";
  };

  renderHeader = (hideBtnExport = false, hideFiltersPanel = false) => {
  	const { activeTab, translate, } = this.props;
  	const breadcrumbsItems = breadcrumbs(translate, activeTab, this.params);

  	return (
	<div className="content-block">
	<div className="top-page-block">
	<div>
	<h1 className={ 'page-title' }>{translate(`payment_title`)}</h1>
	<Breadcrumbs items={ breadcrumbsItems } />
  				</div>
	{this.state.requestPopupIsOpen && (
	<CreateRequestPopup
	translate={ this.props.translate }
	handlerPopup={ this.handlerPopup }
	currency={ this.currency }
	enums={ this.state?.enums?.data }
  					/>
  				)}
	{ this.renderButtonCreate() }
	{ hideBtnExport ? this.renderButtonExport() : null }
  			</div>
	{
  				hideBtnExport
  					? <MagicFilterPanels
		mf={ this.mf }
		show={ true }
		translate={ translate }
  					/>
  					: null
  			}
  		</div>
  	);
  };

  renderToolbar = items => (
	<div className="content-block">
	<div className="toolbar">
	<ToolbarPanel
	items={ items }
	class4List={ `toolbar-panel__list toolbar-panel__list_flat` }
  			/>
  		</div>
  	</div>
  );

  renderContentOrder = () => {
  	return (
	<OnePaymentOrderTable
	translate={ this.props.translate }
	params={ this.params }
	editMode={ this.state.editMode }
	confirm={ this.state.confirm }
	setEditMode={ this.setEditMode }
	setConfirm={ this.setConfirm }
	enums={ this.state?.enums?.data }
  		/>
  	);
  };

  renderContentOrders = () => {
  	return (
	<ListPayments
	departmentsListNames={ this.state?.departmentsListNames }
	handlerPopup={ this.handlerPopup }
	enums={ this.state?.enums?.data }
	mf={ this.mf }
	query={ this.query }
	params={ this.params }
  		/>
  	);
  };

  render = () => {
  	const { activeTab, translate, } = this.props;

  	this.renderButtonExport();
  	const renderContent = () => {
  		switch (activeTab) {
  			case TAB_ID.order:
  				return (
	<React.Fragment>
	{this.renderHeader()}
	{this.renderContentOrder()}
  					</React.Fragment>);
  			default:
  			case TAB_ID.orders:
  				return (
	<React.Fragment>
	{this.renderHeader(true, true)}
	{this.renderContentOrders()}
  					</React.Fragment>);
  		};
  	};

  	return (<div className={ `payments` }>{renderContent()}</div>);
  };
}

export default withRouter(withLocalize(Payments));