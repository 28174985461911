import {
  itemsBuild,
  itemsDrain,
  itemsResort,
  CFG_ITEM_TYPE,
} from '../Config';
// import {} from '../Mentor/withDropDown';

export default {
  id: 136,
  ...[

    // filters
    ...[ {
      f: 'tima.tima_condition.name',
      id: 'tcName',
    }, {
      f: [ 'core.account.surname', 'core.account.name', ],
      // 'drop-down': false,
      id: 'f:aTimaResponsible',
      o: 'like',
    }, {
      f: 'tima.tima_manager.name',
      id: 'f:tmName',
      o: 'like',
    }, {
      f: 'tima.tima_condition.version',
      id: 'f:tcVersion',
    }, {
      f: 'tima.tima_condition.created_at',
      id: 'f:tcCreated',
    }, {
      f: 'tima.tima_condition.updated_at',
      id: 'f:tcUpdated',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

    // orders
    ...[ {
      f: '',
      id: 'o:',
      off: true, // TODO:
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

    // panels
    ...[ {
      ii: 'tcName',
      t: 'magic_filter_translate_tima_condition_name',
    }, {
      ii: 'f:aTimaResponsible',
      t: 'magic_filter_translate_tima_condition_account_id',
    }, {
      ii: 'f:tmName',
      t: 'magic_filter_translate_tima_condition_manager_name',
    }, {
      ii: 'f:tcVersion',
      t: 'magic_filter_translate_tima_condition_version',
    }, {
      ii: 'f:tcCreated',
      t: 'magic_filter_translate_tima_condition_created_at',
    }, {
      ii: 'f:tcUpdated',
      t: 'magic_filter_translate_tima_condition_updated_at',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

  ]
  .map(itemsBuild)
  .reduce(itemsDrain, [])
  .reduce(itemsResort, {}),

  path: 'tima/tima-condition',
  widget: false,
};