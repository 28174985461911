import React, {Component} from 'react';
import {Col, Row} from 'reactstrap';
import ContactInformation from "./LeadTabContactInfo";
import LeadTabTail from "./LeadTabTail";
import DocumentsTable from "./DocumentsTable";
import CommentsCustomerData from "./Comments";
import PermissionService from '../../../../../services/PermissionService';
import PropTypes from "prop-types";
import MobileDiscounBlock from "./MobileDiscounBlock";
// import CommentsCustomerData from './CommentsCustomerData';

export default class LeadCustomerData extends Component {
    static propTypes = {
        refreshClientData: PropTypes.func.isRequired,
    };

    constructor (props) {
        super(props);
    }

    get pmConfig () {
        return [
            {
                path: ['core', 'account_descriptions'],
                key:  'getByAccountId',
            },
            {
                path: [ 'core', 'account', 'is_mobile' ],
                key: 'isMobile',
            },
        ];
    }

    renderCommentsCustomerData = () => {
        const conf = PermissionService.calc(this.pmConfig);

        if (conf?.getByAccountId.access('getByAccountId')) {
            return (
                <Col sm='4'>
                    <CommentsCustomerData
                        client={this.props.client}
                        id={this.props?.clientId}
                        getClient = { this.props.getClient }
                        refreshClientData={this.props?.refreshClientData}
                    />
                </Col>
            )
        }
        return "";
    };

    render () {
        const conf = PermissionService.calc(this.pmConfig);
        const showIsMobile = conf?.isMobile.access('update');
        return (
            <Row>
                <Col sm = '4'>
                    <ContactInformation
                        user = { this.props.client }
                        enums = {this.props?.enums?.AccountGenderEnum }
                        licensesEnums = {this.props?.enums?.LicenseAliasEnum }
                    />
                </Col>
                {this.renderCommentsCustomerData()}
                <Col sm='4'>
                    <DocumentsTable
                        accountId={this.props.clientId}
                        client={this.props.client}
                        enums={this.props.enums}
                        getClient={this.props.getClient}
                        refreshClientData={this.props?.refreshClientData}
                    />
                    { showIsMobile && <MobileDiscounBlock
                        client={this.props.client}
                        id={this.props?.clientId}

                    /> }
                    <LeadTabTail id={this.props.clientId}/>
                </Col>
            </Row>
        );
    }
}
