import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import { ToolbarPanel, } from '../../tima/components/Toolbar';
import * as configToolbar from './MarketingLinkToolbarConfig';
import * as configBreadcrumbs from './MarketingBreadcrumbsConfig';
import MarketingChannels from './MarketingChannels';
import MarketingSubChannels from './MarketingSubChannels';
import MarketingProducts from './MarketingProducts';
import MarketingUtmLinksGeneration from './MarketingUtmLinksGeneration';
import { Breadcrumbs } from '../../tima/components/Breadcrumbs';
import { enumService } from '../../../services/EnumDataService';
import '../styles/marketing.scss';
import PermissionService, { MODULE_KEYS as pm } from '../../../services/PermissionService';
import MarketingStatisticsClicks from './MarketingStatisticsClicks';

const TAB_ID = {
  utm_source: 'utm_source',
  utm_medium: 'utm_medium',
  utm_campaign: 'utm_campaign',
  utm_link: 'utm_link',
  account_utm_link: 'account_utm_link',
};
Object.freeze(TAB_ID);

const breadcrumbs = configBreadcrumbs.breadcrumbsInit;

// const toolbar = configToolbar.toolbarInit;

const toolbar = (
  translate,
  activeTab,
  permissions) => {
  const config = configToolbar;

  switch ( activeTab ) {
    case TAB_ID.account_utm_link:
      return config.toolbarInit( translate, 'account_utm_link', permissions );
    case TAB_ID.utm_link:
      return config.toolbarInit( translate, 'utm_link', permissions );
    case TAB_ID.utm_medium:
      return config.toolbarInit( translate, 'utm_medium', permissions );
    case TAB_ID.utm_campaign:
      return config.toolbarInit( translate, 'utm_campaign', permissions );
    default:
      case TAB_ID.utm_source:
        return config.toolbarInit( translate, 'utm_source', permissions );
  }
};

class Marketing extends Component {
  constructor (props) {
    super(props);

    let mf = null;
    Object.defineProperty(this, 'mf', {
      get: () => mf ?? {},
      set: _ => { mf = _; },
    });
  };

  get marketingConfig () {
    return [
      {
        path: ['statistics', 'utm_link'],
        key: TAB_ID.utm_link,
      },
      {
        path: ['statistics', 'utm_source'],
        key: TAB_ID.utm_source,
      },
      {
        path: ['statistics', 'utm_medium'],
        key: TAB_ID.utm_medium,
      },
      {
        path: ['statistics', 'utm_campaign'],
        key: TAB_ID.utm_campaign,
      },
      {
        path: ['statistics', 'account_utm_link'],
        key: TAB_ID.account_utm_link,
      },
    ]
  };

  params = ( ) => this.props.match.params;

  query = ( ) => new URLSearchParams( this.props.location.search );

  enumsChange = async (enums) => {
    if (enums.hash===this.state?.enums?.hash) return `${ this.constructor.name }.enumsChanges: false`;
    await this.save({ enums, });
    return `${this.constructor.name}.enumsChange: true`;
  };

  save = async (state) => new Promise(resolve => this.setState(state, resolve));

  async componentDidMount() {
    enumService.subscribe('enums', this.enumsChange);
    await enumService.enums;
  };

  componentWillUnmount() {
    enumService.unsubscribe('enums', this.enumsChange);
  };

  render = ( ) => {
    const enums = this.state?.enums?.data;

    const renderToolbar = ( items ) => (
      <div className = 'content-block'>
        <div className='toolbar'>
          <ToolbarPanel
            items={ items }
            class4List={ `toolbar-panel__list toolbar-panel__list_flat` }
          />
        </div>
      </div>
    );

    const renderContent = ( translate, active, params, query ) => {
      switch ( active ) {
        case TAB_ID.utm_medium:
          return (
            <MarketingSubChannels
              translate={ translate }
              params={ params }
              query={ query }
              mf={ _ => this.mf = _ }
              enums={enums}
            />
          );
        case TAB_ID.utm_campaign:
          return (
           <MarketingProducts
             translate={ translate }
             params={ params }
             query={ query }
             mf={ _ => this.mf = _ }
             enums={enums}
           />
          );
        case TAB_ID.utm_link:
            return (
              <MarketingUtmLinksGeneration
                translate={ translate }
                params={ params }
                query={ query }
                mf={ _ => this.mf = _ }
                enums={ enums }
              />
            )
        case TAB_ID.account_utm_link:
          return (
            <MarketingStatisticsClicks
              translate={ translate }
              params={ params }
              query={ query }
              mf={ _ => this.mf = _ }
              enums={ enums }
            />
          );
        default:
        case TAB_ID.utm_source:
          return (
            <MarketingChannels
              translate={ translate }
              params={ params }
              query={ query }
              mf={ _ => this.mf = _ }
              enums={enums}
            />
          );
      }
    };

    const { activeTab, translate } = this.props;
    const permissions = PermissionService.calc(this.marketingConfig);
    const breadcrumbsItems = breadcrumbs( translate, activeTab, this.params() );
    const toolbarItems = toolbar( translate, activeTab, permissions );

    return (
      <div className= { `tima marketing` }>
        <div className = 'content-block'>
          <div className = 'top-page-block'>
            <div>
              <h1 className={ 'page-title' }>
                { translate( `marketing_strategies_h1` ) }
              </h1>
              <Breadcrumbs items={ breadcrumbsItems } />
            </div>
          </div>
        </div>
        { toolbarItems.length ? renderToolbar( toolbarItems ) : '' }
        { renderContent( translate, activeTab, this.params(), this.query() ) }
      </div>
    )
  };
}

export default withRouter(withLocalize(Marketing));
