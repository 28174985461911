import {
  itemsBuild,
  itemsDrain,
  itemsResort,
  CFG_ITEM_TYPE,
} from '../Config';
// import {} from '../Mentor/withDropDown';

const fields = [
  'deals.Deal',
  'deals.Order',
  'deals.Symbol',
  'deals.Time',
  'deals.Action',
];

const f_all = {
  'f+': fields,
  'o=': [ '=', '!=', ],
};

const o_all = {
  'f+': fields,
};

const f_dAction = {
  enums: {},
}

export default {
  id: 92,
  ...[

    // filters
    ...[ {
      f: 'deals.Deal',
      id: 'f:dDeal',
      ...f_all,
    }, {
      f: 'deals.Order',
      id: 'f:dOrder',
      ...f_all,
    }, {
      f: 'deals.Symbol',
      id: 'f:dSymbol',
      ...f_all,
    }, {
      f: 'deals.Time',
      id: 'f:dTime',
      ...f_all,
    }, {
      f: 'deals.Action',
      id: 'f:dAction',
      off: true, // TODO:
      ...f_all,
      ...f_dAction,
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

    // orders
    ...[ {
      f: '',
      id: 'o:',
      off: true, // TODO:
      ...o_all,
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

    // panels
    ...[ {
      ii: 'f:dDeal',
      t: 'magic_filter_translate_list_accounts_tradings_mt5_closed_deals_deal',
    }, {
      ii: 'f:dOrder',
      t: 'magic_filter_translate_list_accounts_tradings_mt5_closed_deals_order',
    }, {
      ii: 'f:dSymbol',
      t: 'magic_filter_translate_list_accounts_tradings_mt5_closed_deals_symbol',
    }, {
      ii: 'f:dTime',
      t: 'magic_filter_translate_list_accounts_tradings_mt5_closed_deals_time',
    }, {
      ii: 'f:dAction',
      off: true, // TODO:
      t: 'magic_filter_translate_list_accounts_tradings_mt5_closed_deals_action',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

  ]
  .map(itemsBuild)
  .reduce(itemsDrain, [])
  .reduce(itemsResort, {}),

  path: 'core/trading-accounts/get-closed-deals/1',
  widget: false,
};