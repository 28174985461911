export const paymentsLang = {
  //PaymentsTable
  "payment_title": ["Financial transactions", "Финансовые транзакции", "Фінансові транзакції"],
  "payment_button_create_request": ["Сreate a request", "Создать заявку", "Створити заявку"],
  "payment_button_export": ["Export", "Экспорт", "Експорт"],

  "payment_table_create_at": ["Date of creation", "Дата создания", "Дата створення"],
  "payment_table_enrollment_date": ["Date of crediting funds to the company's account", "Дата зачисления средств на счет компании", "Дата зарахування коштів на рахунок компанії",],
  "payment_table_order_id": ["Request number", "Номер заявки", "Номер заявки"],
  "payment_table_system_account_id": ["Balance account number", "Номер балансового счета", "Номер балансового рахунку"],
  // "payment_table_currency": ["Account", "Валюта", "Валюта"],
  "payment_table_client": ["Client", "Клиент", "Клієнт"],
  "payment_table_responsible": ["Manager", "Менеджер", "Менеджер"],
  "payment_table_amount": ["Amount", "Сумма", "Сума"],
  "payment_table_amount_paid": ["Amount to be credited", "Сумма к зачислению", "Сума нарахування"],
  "payment_table_payment_method": ["Payment method", "Способ оплаты", "Спосіб оплати"],
  "payment_table_cwb": ["Card/wallet/bank account number/E-mail", "Номер карты/кошелька/банковского счета/E-mail", "Номер картки/гаманця/банківського рахунку/E-mail"],
  "payment_table_card_holder": ["Cardholder name", "Имя владельца карты", "Ім'я власника картки"],
  "payment_table_description": ["Description", "Назначение платежа", "Призначення платежу",],
  "payment_table_payment_system": ["Payment system", "Платежная система", "Платіжна система"],
  "payment_table_bank_credentials": ["Bank Credentials", "Банковские реквизиты", "Банківські реквізити"],
  "payment_table_date_paid": ["Date of last transaction processing", "Дата последней обработки операции", "Дата останньої обробки операції"],
  "payment_table_type_request": ["Request type", "Тип заявки", "Тип заявки"],
  "payment_table_status": ["Payment status", "Статус платежа", "Статус платежу"],
  "payment_table_id": ["Payment ID", "ID платежа", "ID платежу"],
  "payment_table_resolution": ["Resolution", "Решение", "Рішення"],
  "payment_table_btn_withdrawal": ["Withdrawal", "Вывод", "Виплата"],
  "payment_table_btn_replenishment": ["Replenishment", "Пополнение", "Поповнення"],
  "payment_table_platform": ["Payment platform", "Платежная платформа", "Платіжна платформа"],
  "payment_system": ["Payment system", "Платежная система", "Платіжна система"],
  "payment_table_manually_closing": ["Closure", "Закрытие", "Закриття"],
  "payment_table_flexi_error": ["Connection error", "Ошибка связи", "Помилка з'єднання"],
  "payment_table_error": ["Error", "Ошибка", "Помилка"],
  "payment_table_gateway": ["Gateway", "Шлюз", "Шлюз"],

  //OnePaymentTable
  "payment_table_currency": ["Currency", "Валюта", "Валюта"],
  "payment_table_fee": ["Fee", "Комиссия", "Комісія"],
  "payment_table_invoice": ["Invoice", "Инвойс", "Інвойс"],
  "payment_table_client_agreement": ["Client Agreement", "Клиентское Соглашение", "Клієнтська угода"],
  "payment_table_reason_withdrawal": ["Withdrawal reason", "Причина вывода", "Причина виведення"],
  "payment_table_rejection_reason": ["Rejection reason", "Причина отмены", "Причина скасування"],
  "payment_table_send_request": ["Request sent", "Запрос отправлен", "Запит відправлено"],
  "payment_table_generate_invoice": ["Create", "Создать", "Створити"],
  "payment_table_invalidate_invoice": ["Invalidate invoice", "Инвалидировать инвойс", "Інвалідувати інвойс"],
  "payment_table_generate_client_agreement": ["Create CA", "Создать КС", "Створити КУ"],
  "payment_table_default_bank_usd": ["Bank USD", "Bank USD", "Bank USD"],
  "payment_table_default_bank_eur": ["Bank EUR", "Bank EUR", "Bank EUR"],
  "payment_table_min_amount_error": ["Minimum amount {minvalue} EUR/USD", "Минимальная сумма {minvalue} EUR/USD", "Мінімальна сума {minvalue} EUR/USD", ],

  //CONFIRM
  "one_payment_table_confirm_title": ["Save?", "Сохранить?", "Зберегти?"],
  "one_payment_table_confirm_accept": ["Yes", "Да", "Так"],
  "one_payment_table_confirm_reject": ["No", "Нет", "Ні"],

  //CreateRequestPopup
  "payment_popup_title": ["Create request", "Создать заявку", "Створити заявку"],
  "payment_popup_block_info_client": ["Client information", "Информация клиента", "Інформація клієнта"],
  "payment_popup_block_info_payment": ["Payment information", "Информация платежа", "Інформація платежу"],
  "payment_popup_block_summ": ["Sum and commission", "Сумма и комиссия", "Сума та комісія"],
  "payment_popup_button_create": ["Create", "Создать", "Створити"],
  "payment_popup_button_cancel": ["Cancel", "Отмена", "Відмінити"],
  "payment_popup_created_at": ["Date of creation", "Дата создания", "Дата створення"],
  "payment_popup_type_request": ["Request type*", "Тип заявки*", "Тип заявки*"],
  "payment_popup_type_select_type": ["Select type", "Выберите тип", "Виберіть тип"],
  "payment_popup_client_id": ["Client ID", "ID клиента", "ID клієнта"],
  "payment_popup_system_account_id": ["Balance account number", "Номер балансового счета", "Номер балансового рахунку"],
  "payment_popup_check": ["Check", "Проверить", "Перевірити"],
  "payment_popup_find_success": ["Found successcully!", "Успешно найден!", "Успішно знайдений!"],
  "payment_popup_find_not_found": ["Not found", "Не найден", "Не знайдений"],
  "payment_popup_client_id_ph": ["Enter client ID", "Введите ID клиента", "Введіть ID клієнта"],
  "payment_popup_system_account_ph": ["Enter account number", "Введите номер счета", "Введіть номер рахунку"],
  "payment_popup_currency": ["Account currency*", "Валюта счета*", "Валюта рахунку*"],
  "payment_popup_currency_not_selected": [" Not selected", "Не выбрана", "Не обрана"],
  "payment_popup_currency_select_currency": ["Choose account currency*", "Выберите валюту счета*", "Виберіть валюту рахунку*"],
  "payment_popup_payment_method": ["Payment method*", "Способ оплаты*", "Спосіб оплати*"],
  "payment_popup_select_payment_method": ["Choose payment method", "Выберите способ оплаты", "Оберіть спосіб оплати"],
  "payment_popup_client_name_latin": ["Client name in latin*", " Имя клиента латиницей* ", "Ім'я клієнта латиницею*"],
  "payment_popup_client_surname_latin": ["Client surname in latin*", " Фамилия клиента латиницей* ", "Прізвище клієнта латиницею*"],
  "payment_popup_client_company_latin": ["Company name*", "Название компании* ", "Назва компанії*"],
  "payment_popup_client_enter_company_latin": ["Enter company name*", "Введите название компании* ", "Введіть назву компанії*"],
  "payment_popup_client_enter_name_latin": ["Enter name", "Введите имя", "Введіть ім'я"],
  "payment_popup_client_enter_surname_latin": ["Enter surname", "Введите фамилию", "Введіть прізвище"],
  "payment_popup_payment_system": ["Payment system*", "Платежная система*", "Платіжна система*"],
  "payment_popup_select_payment_system": ["Choose a payment system", "Выберите платежную систему", "Оберіть платіжну систему"],
  "payment_popup_amount": ["Sum*", "Сумма*", "Сума*"],
  "payment_popup_enter_amount": ["Enter amount", "Введите сумму", "Введіть сумму"],
  "payment_popup_fee": ["Commission", "Коммисия", "Комісія"],
  "payment_popup_enter_fee": ["Enter fee", "Введите коммисию", "Введіть комісію"],
  "payment_popup_deposit_sum": ["Withdrawal sum", "Cумма к выплате", " Cума на виплату "],
  "payment_popup_withdrawal_sum": ["Deposit sum", "Cумма к пополнению", "Сума на поповнення"],
  "payment_popup_wallet": ["Wallet*", "Кошелёк*", "Гаманець*"],
  "payment_popup_select_wallet": ["Choose a wallet", "Выберите кошелёк", "Оберіть гаманець"],
  "payment_popup_empty_wallet": ["No verified wallets.", "Нет верифицированных кошельков.", "Немає верифікованих гаманців."],
  "payment_popup_wallet_new": ["Add new", "Добавить новый", "Додати новий"],
  "payment_popup_wallet_new_title": ["Wallet number*", "Номер кошелька*", "Номер гаманця*"],
  "payment_popup_wallet_new_ph": ["Enter wallet number", "Введите номер кошелька", "Введіть номер гаманця"],
  "payment_popup_crypto_number": ["Cryptocurrency wallet number*", "Номер кошелька, криптоадрес*", "Номер гаманця, криптоадреса*"],
  "payment_popup_enter_crypto_number": ["Enter a cryptocurrency address", "Введите криптоадрес", " Введіть криптоадресу"],
  "payment_popup_name_client_bank": ["Client bank*", "Банк клиента*", "Банк клієнта* "],
  "payment_popup_enter_name_client_bank": ["Enter client bank", "Введите банк клиента", "Введіть банк клієнта"],
  "payment_popup_account_number": ["Account number (or IBAN)*", "Номер счета (или IBAN)*", "Номер рахунку (або IBAN)*"],
  "payment_popup_enter_account_number": ["Enter bank account number", "Введите номер счета в банке", "Введіть  номер рахунку в банку"],
  "payment_popup_client_country": ["Recipient country*", "Страна получателя*", "Країна отримувача*"],
  "payment_popup_client_bank_country": ["Recipient bank country*", "Страна банка получателя*", "Країна банка отримувача*"],
  "payment_popup_enter_client_bank_country": ["Enter recipient bank country", "Введите страну банка получателя*", "Введіть країну банка отримувача"],
  "payment_popup_client_bank_swift": ["Recipient bank SWIFT/BIC*", "SWIFT/BIC банка получателя*", "SWIFT/BIC банку отримувача*"],
  "payment_popup_enter_client_bank_swift": ["Enter recipient bank SWIFT/BIC*", "Введите SWIFT/BIC банка получателя*", "Введіть SWIFT/BIC банку отримувача*"],
  "payment_popup_client_code": ["Recipient code", "Код получателя", "Код отримувача"],

  "payment_popup_enter_client_address": ["Enter recipient address", "Введите адрес получателя", "Введіть адресу отримувача*"],
  "payment_popup_client_address": ["Recipient address", "Адрес получателя", "Адреса отримувача"],

  "payment_popup_enter_client_code": ["Enter recipient code", "Введите код получателя", "Введіть код отримувача"],
  "payment_popup_bank_swift": ["SWIFT/BIC*", "SWIFT/BIC*", "SWIFT/BIC*"],
  "payment_popup_bank_iban": ["BANK IBAN*", "BANK IBAN*", "BANK IBAN*"],
  "payment_popup_bank_country": ["Bank country*", "Страна банка*", "Країна банку*"],
  "payment_popup_bank_address": ["Bank address*", "Адрес банка*", "Адреса банку*"],
  "payment_popup_crypto_adress": ["Cryptoaddress", "Криптоадрес", "Криптоадреса",],
  "payment_popup_destination_tag": ["Destination tag", "Метка назначения", "Мітка призначення",],

  "payment_popup_destination_crypto_address_error": ["The crypto address must contain 24 to 100 characters", "Криптоадрес должен содержать от 24 до 100 символов", "Криптоадреса має містити від 24 до 100 символів"],
  "payment_popup_iban_min_length_error": ["The total length of IBAN must be at least 5 characters long", "Общая длина IBAN должна составлять не менее 5 символов", "Загальна довжина IBAN повинна бути не менше 5 символів"],
  "payment_popup_amount_error_100": ["Minimum amount 100 EUR/USD", "Минимальная сумма 100 EUR/USD", "Мінімальна сума 100 EUR/USD", ],
  "payment_popup_amount_error_10": ["Minimum amount 10 EUR/USD", "Минимальная сумма 10 EUR/USD", "Мінімальна сума 10 EUR/USD", ],
  "payment_popup_amount_error_1": ["Minimum amount 1 EUR/USD", "Минимальная сумма 1 EUR/USD", "Мінімальна сума 1 EUR/USD", ],
  "payment_popup_empty_field_error": ["The field can't be empty", "Поле не может быть пустым", "Поле не може бути порожнім", ],


  // "payment_popup_account_number": ["Account number", "Номер счета", "Номер рахунку"],
  // "payment_popup_account_type": ["Account type", "Тип счета", "Тип рахунку"],
  "payment_popup_client": ["Client", "Клиент", "Клієнт"],


  // PagePanel
  "payment_pages_items": ["Items", "Записи", "Записи"],
  "payment_pages_filtered": ["Items after filtration", "Записей после фильтрации", "Записів після фільтрації"],
  "payment_pages_total": ["Items total", "Записей в целом", "Записів в цілому"],

  // Summary table
  "summary_table" : ["Summary table of financial transactions as of date", "Сводная таблица финансовых транзакций на дату", "Зведена таблиця фінансових транзакцій на дату", ],
  "summary_table_title" : ["Summary of financial transactions ", "Сводка финансовых транзакций", "Зведення фінансових транзакцій", ],
  "summary_date": ["Date", "Дата", "Дата", ],
  "summary_sum_deposit_of_usd": ["Sum deposit USD", "Сумма пополнений USD", "Сума поповнень USD", ],
  "summary_balance_deposit_of_usd": ["Balance deposit USD", "Баланс пополнений USD", "Баланс поповнень USD", ],
  "summary_sum_deposit_of_eur": ["Sum deposit EUR", "Сумма пополнений EUR", "Сума поповнень EUR", ],
  "summary_balance_deposit_of_eur": ["Balance deposit EUR", "Баланс пополнений EUR", "Баланс поповнень EUR", ],
  "summary_sum_withdrawal_of_usd": ["Sum withdrawal USD", "Сумма снятий USD", "Сума знять USD",  ],
  "summary_balance_withdrawal_of_usd": ["Balance withdrawal USD", "Баланс снятий USD", "Баланс знять USD",  ],
  "summary_sum_withdrawal_of_eur": ["Sum withdrawal EUR", "Сумма снятий EUR", "Сума знять EUR",  ],
  "summary_balance_withdrawal_of_eur": ["Balance withdrawal EUR", "Баланс снятий EUR", "Баланс знять EUR",  ],
  "summary_sum_chargeback_of_usd": ["Sum chargeback USD", "Сумма возвратов USD", "Сума повернень USD",],
  "summary_balance_chargeback_of_usd": ["Balance chargeback USD", "Баланс возвратов USD", "Баланс повернень USD",  ],
  "summary_sum_chargeback_of_eur": ["Sum chargeback EUR", "Сумма возвратов EUR", "Сума повернень EUR",],
  "summary_balance_chargeback_of_eur": ["Balance chargeback EUR", "Баланс возвратов EUR", "Баланс повернень EUR",  ],
  "summary_sum_unfulfilled_operations_of_usd":["Sum unfulfilled operation USD","Сумма непроведенных операций USD", "Сума непроведених операцій USD", ],
  "summary_sum_unfulfilled_operations_of_eur":["Sum unfulfilled operation EUR","Сумма непроведенных операций EUR", "Сума непроведених операцій EUR", ],
};

