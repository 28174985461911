import React, { Component, } from "react";
import { withRouter, } from "react-router-dom";
import { Redirect, } from "react-router-dom";
import { withLocalize, } from "react-localize-redux";

import PermissionService from "../../../../services/PermissionService";

import { HorizontalMenu, } from "../../components";
import PartnersSystemHeader from "../ components/PartnersSystemHeader";

import * as configBreadcrumbs from "./config/BreadcrumbsConfig";
import affiliateProgramsManagmentChildren from "./config/redirectConfig";
class AffiliateProgramsManagment extends Component {
	constructor (props) {
		super(props);

		this.state = {
			serverCurrency: "",
		};
	}

	save = async state => new Promise(next => this.setState(state, next));

	getPermissions = (path, action) => {
		const pm = PermissionService.calc([
			{
				path,
				key: "user",
			},
		]);

		return pm.user.access(action);
	};

	findFirstAccessableLink = (parent) => {
		const accessableChildren = parent.find(children =>
			this.getPermissions(children.path, "index"),
		);

		return accessableChildren?.to;
	};

	render () {
		const { translate, } = this.props;
		const breadcrumbsItems =
			configBreadcrumbs.breadCrumbs4AffiliateProgramsManagment;
		const redirectTo = this.findFirstAccessableLink(
			affiliateProgramsManagmentChildren,
		);

		return (
			<div className="affiliate-payouts">
				<PartnersSystemHeader
					breadcrumbsItems={ breadcrumbsItems }
					title={ "partners_system_affiliate_programs_management" }
					translate={ translate }
				/>
				<Redirect to={ redirectTo } />
				<HorizontalMenu />
			</div>
		);
	}
}

export default withRouter(withLocalize(AffiliateProgramsManagment));