import { securedRequest, } from '../../../../services/RequestService';
import NotificationService from '../../../../services/NotificationService';

export class MagicError extends Error {
    constructor ( data ) {
        super();

        const errorObjectToString = (errorObj) => {
            const { response } = errorObj;
            const error = response?.data ?? errorObj.message;

            let errorMsg = '';

            errorMsg += typeof error === 'string' ? `${error} ` : '';
            errorMsg += (error instanceof Object && typeof error.error === 'string') ? `${error.error} ` : '';

            if (error instanceof Object && error.errors instanceof Object) {
                for (const key in error.errors) {
                    const targetNode = error.errors[key];

                    if (typeof targetNode === 'string') {
                        errorMsg += `${targetNode} `;
                    } else if (targetNode instanceof Array) {
                        targetNode?.forEach((errorText) => {
                            errorMsg += `${errorText} `;
                        });
                    }
                }
            }

            return errorMsg ? errorMsg : 'Invalid Error!';
        };
        const value = errorObjectToString( data );

        Object.defineProperty(this, 'message', {
            configurable: false,
            enumerable: true,
            value,
            writable: false,
        } );

        Object.defineProperty(this, 'response', {
            configurable: false,
            enumerable: true,
            value: data.response,
            writable: false,
        } );

        Object.defineProperties(this, {
        	validation_errors: {
        		get: () => {
					if (data?.response?.data?.errors) {
						return {...data?.response?.data?.errors};
					}

					return data?.message;
				},
			},
		})

        this.showErrorNotification = () => {
            NotificationService.error({
                title:   "error",
                message: value,
                remove:  false,
            });
        };
    }
}

export const get = async (url, options) => {
    try {
        url = url instanceof URL ? url : new URL(url, location.href);
        if (options?.filterId) {
            url = new URL(url?.href?.replace(url?.pathname, `${ url?.pathname }/filter/${ options?.filterId }`))
        }
        if (options) {
            for (let key of Object.keys(options).filter(item => !['filterId'].includes(item))) {
                url.searchParams.set(key, options?.[key]);
            }
        }

        const { data } = await securedRequest.get(url?.href);

        return data;
    } catch (error) {
        throw new MagicError(error);
    }
};

export const post = async (url, obj, form) => {
    try {
        let param = obj;

        if (form instanceof FormData) {
            for (const [key, value] of Object.entries(obj)) {
                form.append(key, value);
            }
            param = form;
        }
        const response = await securedRequest.post(url, param);
        const { data } = response;

        return data;
    } catch (error) {
        throw new MagicError(error);
    }
};

export const put = async (url, obj) => {
    try {
        const response = await securedRequest.put(url, obj);
        const { data } = response;

        return data;
    } catch (error) {
        throw new MagicError(error);
    }
};

export const patch = async (url, obj) => {
    try {
        const response = await securedRequest.patch(url, obj);
        const { data } = response;

        return data;
    } catch (error) {
        throw new MagicError(error);
    }
};

export const del = async (url, obj) => {
    try {
        return await securedRequest.delete(url, obj);
    } catch (error) {
        throw new MagicError(error);
    }
};
