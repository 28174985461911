import React from 'react';
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { menu } from "./configVerticalMenu";
import { withLocalize } from "react-localize-redux";
import { Scrollbars } from "react-custom-scrollbars";
import ModalWrapper from "../../../../components/Common/ModalWrapper";
import PermissionService from "../../../../services/PermissionService";

//images
import logoCrm from '../../../../images/logo.svg';

//components
import TopNav from './TopNav';
import VerticalMenu from './VerticalMenu';
import AlertList from "../Alert/AlertList";
import KPIWidget from "../KPIWidget/KPIWidget";
import TopKPIWidget from './TopKPIWidget';
import Preloader from "../../../../components/LoadingHOC/Preloader";

//services
import { getNewCategoryAccount } from "../../../clients/services/AccountService";
import { getAllDepartmentsByUserId } from "../../../dashboard/services/DashboardRequestService";
import SearchTopBar from '../../../search/components/SearchTopBar';
import { enumService } from "../../../../services/EnumDataService";
import { profileService } from "../../../../services/ProfileDataService";

class Main extends React.Component {
    state = {
        menuToggle: true,
        searchForm: false,
        tooltipOpen: false,
        user: {},
        visible: true,
        alerts: false,
        error: this.props.error,
        clientContent: [],
        openKPI: false,
        isKpiFlag: false,
    };

    save = async (state) => new Promise(next => this.setState(state, next));

    enumsChange = async (enums) => {
        if (enums.hash === this.state?.enums?.hash) return `${this.constructor.name}.enumsChange: false`;
        await this.save({ enums, });
        return `${this.constructor.name}.enumsChange: true`;
    };

    profileChange = async (profile) => {
        if (profile.hash === this.state?.profile?.hash) return `${this.constructor.name}.profileChange: false`;
        await this.save({ profile, });
        return `${this.constructor.name}.profileChange: true`;
    };

    async componentDidMount() {
        const { data, } =  await profileService.profile;


        enumService.subscribe('enums', this.enumsChange, this);
        profileService.subscribe('profile', this.profileChange, this);
        await Promise.all([
            enumService.enums,
            profileService.profile
        ]);
        this.state?.profile?.data?.id && await this.props.getNewCategoryAccount({ userId: this.state?.profile?.data?.id });
        // this.props.getCountNotWork();
        await this._getAllDepartmentsByUserId();
        await this.save({ managerProfile: data, });
    }

    _getAllDepartmentsByUserId = async () => {
        try {
            await this.save({ getData: false });
            const response = await getAllDepartmentsByUserId(this.state?.profile?.data?.id);
            const whiteDepartmentList = ['administration', 'sales_department', 'conversion', 'retention'];
            let isKpiFlag = false;

            if (response.data && response.data.length) {
                response.data.forEach((item) => {
                    if (whiteDepartmentList.includes(item.alias)) {
                        isKpiFlag = true;
                    }
                });
            }
            await this.save({ isKpiFlag, getData: true });
        } catch (error) {
            console.log('error', error);
            await this.save({ getData: true });
        }
    };

    openKPIfunction = async () => this.save({ openKPI: !this.state.openKPI });

    toggle = async () => this.save({ tooltipOpen: !this.state.tooltipOpen });

    searchFormToogle = async () => this.save({ searchForm: !this.state.searchForm });

    getClassForMenu = () => this.state.menuToggle ? 'nav-collapsed' : '';

    getClassForSearch = () => this.state.searchForm ? 'open' : '';

    componentWillUnmount() {
        enumService.unsubscribe('enums', this.enumsChange, this);
        profileService.unsubscribe('profile', this.profileChange, this);
        document.removeEventListener('click', this.handleClickOutside, false);
    }

    UNSAFE_componentWillMount() {
        document.addEventListener('click', this.handleClickOutside, false);
    }

    handleClickOutside = (e) => {
        const kpiWrapper = document.querySelector('.kpi-wrapper');
        if (!e?.path?.includes(kpiWrapper)) {
            const topbarKpiBlock = document.querySelector('.topbar-kpi-block.details');
            if (!e?.path?.includes(topbarKpiBlock)) {
                this.setState({ openKPI: false });
            }
        }
    }

    render() {
        const { translate } = this.props;
        const enums = this.state.enums?.data?.core?.AccountCategoryEnum;
        const classToogle = `body-wrapper ${this.getClassForMenu()}`;
        const classSearch = `navbar-search-form ${this.getClassForSearch()}`;

        function accessChecked() {
            return this.accessChecked;
        }

        function accessSimple(action) {
            return PermissionService.calc([{ ...this, key: 'menuItem' }])?.menuItem?.access?.(action);
        }

        function accessComplex(action) {
            return this.content.some(item => item?.access(action)) || accessSimple.call(this, action);
        }

        const menuItemAccess = (menuItem) => {
            if (![null, undefined].includes(menuItem?.accessChecked)) {
                return { ...menuItem, access: accessChecked };
            } else if (!menuItem.path.length) {
                return { ...menuItem, access: () => false };
            } else if (!menuItem?.content?.length) {
                return { ...menuItem, access: accessSimple };
            } else {
                return {
                    ...menuItem,
                    content: menuItem?.content?.map(menuItemAccess),
                    access: accessComplex,
                };
            }
        };

        let clientContent = this.props?.newCategory?.map?.(item => ({
            label: enums?.[item?.category_id ?? 0],
            to: `/clients/category/${item?.category_id ?? 0}`,
            id: item?.category_id ?? 0,
            count: item?.count ?? 0,
            path: ['core', 'account'],
            key: `menuClients_${item?.category_id ?? 0}`,
        })) ?? [];

        const calcMenu = menu.map((menuItem) => {
            if (menuItem.label === "menu_clients") {
                return menuItemAccess({ ...menuItem, content: clientContent });
            } else {
                return menuItemAccess(menuItem);
            }
        });
        if (!this.state?.getData) {
            return <Preloader scale={1} />;
        }
        return (
            <div className={classToogle}>
                <div className='topbar'>
                    <div className='topbar-left'>
                        <div className='topbar-logo'><Link to='/dashboard'><img alt='logo' src={logoCrm} /></Link></div>
                    </div>
                    <div className="topbar-search"><SearchTopBar /></div>
                    <div className="topbar-kpi">
                        {this.state.isKpiFlag ?
                            (<TopKPIWidget
                                booleanOpenKPI={this.state.openKPI}
                                openKPI={this.openKPIfunction}
                            />) : ''
                        }
                    </div>
                    <div className='topbar-nav'>
                        <form action='' className={classSearch} role='search'>
                            <div className='form-group'>
                                <input
                                    className='form-control navbar-search' placeholder={translate(`topNav_search`)}
                                    type='text'
                                />
                                <i className='la la-close search-close' onClick={this.searchFormToogle} />
                            </div>
                            <button className='d-none' type='submit'>{translate(`topNav_submit`)}</button>
                        </form>

                        <div className='topbar-nav-right'>
                            <TopNav logout={this.props.logout} enums={this.state.enums}/>
                        </div>

                    </div>
                </div>

                <nav className='left-navigation'>
                    <div className='left-navigation-title'>{translate(`topNav_navigation`)}</div>
                    <Scrollbars style={{ 'height': window.innerHeight - 100 }}>
                        {
                            this.state?.profile?.data?.permission_data ? <VerticalMenu
                                managerProfile={this.state?.managerProfile}
                                content={calcMenu}
                                get_count={this.props.get_count}
                            /> : null
                        }
                    </Scrollbars>
                </nav>

                <div className='content-wrapper scroll'>
                    <div className='alerts-area'>
                        {
                            this.state.alerts ? <AlertList jsonData={alerts} /> : ''
                        }

                    </div>
                    {
                        <ModalWrapper
                            open={this.state.openKPI}
                            openFunction={this.openKPIfunction}
                        >
                            <KPIWidget
                                open={this.state.openKPI}
                            />
                        </ModalWrapper>
                    }
                    {this.props.children}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        newCategory: store.clients.newCategory,
        get_count: store.tasks.setCountNotWork,
    };
};

export default withRouter(withLocalize(connect(
    mapStateToProps,
    (dispatch) =>
        bindActionCreators({
            getNewCategoryAccount,
        }, dispatch)
)(Main)));

