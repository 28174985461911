export const calendarLang = {
    "calendar_choose_date":                ["Choose Date", "Выберите дату", "Виберіть Дату"],
    "calendar_calendar":                   ["Calendar", "Календарь", "Календар"],
    "calendar_create_task":                ["Create a task", "Создать задачу", "Створити завдання"],
    "calendar_google_sync":                ["Sync Google Calendar", "Синхронизировать Календарь Google", "Синхронізувати Календар Google"],
    "calendar_day":                        ["Day", "День", "День"],
    "calendar_week":                       ["Week", "Неделя", "Тиждень"],
    "calendar_month":                      ["Month", "Месяц", "Місяць"],
    "calendar_task":                       ["Task", "Задача", "Завдання"],
    "calendar_deadline":                   ["Deadline", "Дедлайн", "Дедлайн"],
    "calendar_important":                  ["Priority", "Приоритет", "Пріоритет"],
    "calendar_status":                     ["Status", "Статус", "Статус"],
    "calendar_responsible":                ["Responsible", "Ответственный", "Відповідальний"],
    "calendar_client":                     ["Client", "Клиент", "Клієнт"],
    "calendar_description":                ["Description", "Описание", "Опис"],
    "calendar_documents":                  ["Attached files", "Прикрепленные файлы", "Прикріплені файли"],
    "calendar_open_task":                  ["Open Task", "Открыть задачу", "Відкрити завдання"],
    "calendar_more_tasks_expand":          ["Expand", "Развернуть", "Розгорнути"],
    "calendar_more_tasks_collapse":        ["Collapse", "Свернуть", "Згорнути"],
    "calendar_congratulate_btn":           ["Congratulate", "Поздравить", "Привітати"],
    "calendar_delegated_task_delegate":    ["Delegated to user", "Делегирована пользователю", "Делегована користувачу"],
    "calendar_delegated_task_my_delegate": ["Delegated from user", "Делегирована с пользователя", "Делегована з користувача"],
    "calendar_filter_urgency": ["Priority", "Приоритет", "Пріоритет"],
    "calendar_filter_states": ["Status", "Статус", "Статус"],
};
