import React                 from 'react';
import * as PropTypes        from 'prop-types';
// https://github.com/preactjs/preact/issues/540


export class MagicInput extends React.PureComponent {
	static defaultProps = {
		InputProps: {},
		className: [],
		disabled: false,
		focusOnInit: false,
		placeholder: '',
		sizeable: true,
		value: '',
	};

	static classNamePropType = PropTypes.string;
	static classNamePropTypes = PropTypes.oneOfType( [
		MagicInput.classNamePropType,
		PropTypes.arrayOf( MagicInput.classNamePropType ),
	] );
	static disabledPropTypes = PropTypes.oneOfType( [
		PropTypes.bool,
		PropTypes.number
	] );
	static placeholderPropTypes = PropTypes.string;
	static valuePropTypes = PropTypes.string;
	static propTypes = {
		InputProps: PropTypes.object.isRequired,
		className: MagicInput.classNamePropTypes.isRequired,
		disabled: MagicInput.disabledPropTypes.isRequired,
		focusOnInit: PropTypes.bool.isRequired,
		placeholder: MagicInput.placeholderPropTypes.isRequired,
		sizeable: PropTypes.bool,
		value: MagicInput.valuePropTypes.isRequired,
		onChange: PropTypes.func.isRequired,
		onClick: PropTypes.func,
		onValid: PropTypes.func,
	};

	static focused = null;
	state = {
		// focus: false,
		selectionEnd: 0,
		selectionStart: 0,
	};

	async save ( state ) {
		const executor = resolve => this.setState( state, resolve );
		return new Promise( executor );
	}

	async onChange ( event ) {
		MagicInput.focused = this;
		const { onChange, } = this.props;
		event.preventDefault();
		const { target = {}, } = event;
		const { selectionStart, selectionEnd, value, } = target;
		const valid = await this.onValid( value );
		if ( valid ) {
			// await this.save( { focus: true, selectionStart, selectionEnd, } );
			await this.save( { selectionStart, selectionEnd, } );
			onChange && await onChange( value );
		} else {
			const { value, } = this.props;
			onChange && await onChange( value );
		}
	}

	async onClick ( event ) {
		MagicInput.focused = this;
		const { value, onClick, } = this.props;
		event.preventDefault();
		const { target = {}, } = event;
		const { selectionStart, selectionEnd, } = target;
		// await this.save( { focus: true, selectionStart, selectionEnd, } );
		await this.save( { selectionStart, selectionEnd, } );
		onClick && await onClick( value, event );
	}

	async onValid ( value ) {
		const { onValid, } = this.props;
		try {
			onValid && await onValid( value );
			return true;
		} catch ( error ) {}
		return false;
	}

	componentDidMount () {
		if ( this.props.focusOnInit ) {
			MagicInput.focused = this;
			// this.setState({ focus: this.props.focusOnInit });
		}
	}

	componentDidUpdate () {
		const ref = this.refs.InputRef;
		if ( ref ) {
			ref.selectionEnd = this.state.selectionEnd;
			ref.selectionStart = this.state.selectionStart;
		}
	}

	renderInput () {
		const { InputProps, } = this.props;
		const { disabled, placeholder, sizeable, value, } = this.props;
		// const { focus = false, } = this.state;
		const poptions = {
			// autoFocus: MagicInput.focused === this ? true : focus,
			autoFocus: MagicInput.focused === this,
			type: 'text',
			...InputProps,
			disabled,
			placeholder,
			ref: 'InputRef',
			...sizeable && { style: { width: `${ value.length + 1 }ch`, } },
			value,
			onChange: this.onChange.bind( this ),
			onClick: this.onClick.bind( this ),
		};
		return (<input { ...poptions } />);
	}

	render () {
		const { className, } = this.props;
		const classNames = className instanceof Array ? className : [ className ];
		const input = this.renderInput();
		const options = {
			className: [ 'magic-input', ...classNames ].join(' '),
		};
		return (<div { ...options } >{ input }</div>);
	}

}
