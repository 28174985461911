import {
  itemsBuild,
  itemsDrain,
  itemsResort,
  CFG_ITEM_TYPE,
} from '../Config';
import { onEnabled, render, } from '../Mentor/withDropDown';

const f_tStatesId = {
  'o=': [ '=', '!=', ],
};

const f_tUrgencyId = {
  'o=': [ '=', '!=', ],
};

const f_tUserIdTo = {
  'drop-down': '/api/core/user/drop-down',
  'o=': [ '=', '!=', ],
};

const f_tUserId = {
  'o=': [ '=', '!=', ],
};

const p_tStatesId = {
  onEnabled: onEnabled('states'),
  render: render('states'),
};

const p_tUrgencyId = {
  onEnabled: onEnabled('urgencies'),
  render: render('urgencies'),
};

const p_tUserIdTo = {
  onEnabled: onEnabled('delegationToUsers'),
  render: render(
    'delegationToUsers',
    _ => _ ? `${ _?.name } ${ _?.surname }` : '-',
  ),
};

const p_tUserId = {
  onEnabled: onEnabled('delegationUsers'),
  render: render(
    'delegationUsers',
    _ => _ ? `${ _?.name } ${ _?.surname }` : '-',
  ),
};

export default {
  id: 40,
  ...[

    // filters
    ...[ {
      f: 'tasks.tasks.finish_date',
      id: 'f:tAtFinish',
    }, {
      f: 'tasks.task_states.id',
      id: 'f:tStatesId',
      ...f_tStatesId,
    }, {
      f: 'tasks.task_urgency.id',
      id: 'f:tUrgencyId',
      ...f_tUrgencyId,
    }, {
      enabled: 1,
      f: 'tasks.task_users.role_id',
      fields: 'f:tUserIdTo',
      id: 'f:tRoleId',
      o: '=',
      v: '3',
    }, {
      f: [ 'tasks.task_users.user_id', ],
      id: 'f:tUserIdTo',
      ...f_tUserIdTo,
    }, {
      f: 'tasks.task_delegation.user_id',
      id: 'f:tUserId',
      ...f_tUserId,
    }, {
      f: [ 'core.account.surname', 'core.account.name', ],
      id: 'f:aFullName',
      o: 'like',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

    // orders
    ...[ {
      f: 'tasks.tasks.finish_date',
      id: 'o:tAtFinish',
      off: true,
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

    // panels
    ...[ {
      ii: 'f:tAtFinish',
      t: 'magic_filter_translate_delegation_finish_date',
    }, {
      ii: 'f:tStatesId',
      t: 'magic_filter_translate_delegation_status',
      ...p_tStatesId,
    }, {
      ii: 'f:tUrgencyId',
      t: 'magic_filter_translate_delegation_urgency',
      ...p_tUrgencyId,
    }, {
      ii: 'f:tUserIdTo',
      t: 'magic_filter_translate_delegation_manager',
      ...p_tUserIdTo,
    }, {
      ii: 'f:tUserId',
      t: 'magic_filter_translate_delegation_manager_delegated',
      ...p_tUserId,
    }, {
      ii: 'f:aFullName',
      t: 'magic_filter_translate_list_accounts_systems_account',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

  ]
  .map(itemsBuild)
  .reduce(itemsDrain, [])
  .reduce(itemsResort, {}),

  path: 'tasks/tasks/with-delegation',
  widget: false,
};