import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import { Link } from "react-router-dom";
import { asyncGet } from "../../../../services/PartnersRequestService";
import { GET_GROUPS_LIST, GET_GROUPS_LIST_BY_TRADING_ACC_ID, } from "../../../partners/apiRoutes";
import Preloader from "../../../../components/LoadingHOC/Preloader";
import Loader from "../../../tima/components/Loader";
import PermissionService from "../../../../services/PermissionService";
import { MagicConfirm } from "../../../tima/components/Magic/MagicConfirm";
import { MagicRadioSwitch, MagicSwitch } from "../../../tima/components/Magic/MagicSwitch";
import NotificationService from "../../../../services/NotificationService";
import {listAccountService} from "../../../../services/ListAccountDataService";

class OneTradingAccountGeneralInfo extends Component {
    constructor (props) {
        super(props);

        this.state = {
            loaded:                      false,
            groupsListAll:               [],
            groupsListByTradingServerId: [],
            magicEditValuePrev:          {},
            magicEditValueCurrent:       {},
            magicEditSelectionPos:       {},
            confirm:                     false,
            editMode:                    false,

            isGenerateNewPasswordDisabled: false,
            loadedInnerRequest:            true,
        };
    }

    componentDidMount () {
        this.onDataLoad();
    }

    setLoadedInnerRequest (loadedInnerRequest) {
        this.setState(() => ({ loadedInnerRequest, }));
    }

    get oneTradingAccountGeneralInfoConfig () {
        const { translate, } = this.props;
        const prefix = 'list_accounts_table_';
        const prefixMt = 'list_accounts_basic_information_mt_';

        return [
            {
                path: ['core', 'trading_accounts'],
                key:  'coreTradingAccounts',
            },
            {
                path:   ['core', 'trading_accounts', 'mt_login'],
                key:    'mtLogin',
                render: this.renderBlockCont(),
                title:  `${prefix}mt_login`,
            },
            {
                path: ['core', 'account', 'id'],
                key:  'clientId',
            },
            {
                path: ['core', 'account', 'name'],
                key:  'clientName',
            },
            {
                path: ['core', 'account', 'surname'],
                key:  'clientSurname',
            },
            {
                accessChecked: true,
                key:           'client',
                render:        this.renderFullNameCont({ fieldName: 'client', path: '/clients' }),
                title:         `${prefix}client`,
            },
            {
                path:   ['core', 'trading_accounts', 'created_at'],
                key:    'createdAt',
                render: this.renderBlockCont(),
                title:  `${prefix}created_at`,
            },
            {
                path:   ['core', 'trading_accounts', 'updated_at'],
                key:    'updatedAt',
                render: this.renderBlockCont(),
                title:  `${prefix}updated_at`,
            },

            {
                path:   ['core', 'trading_accounts', 'type'],
                key:    'type',
                render: this.renderBlockCont({ isEnum: true, enumPath: 'core', enumName: 'TradingAccountTypeEnum' }),
                title:  `${prefix}type`,
            },
            {
                path:   ['core', 'trading_accounts', 'acc_level'],
                key:    'accLevel',
                render: this.renderBlockCont({ fieldName: 'acc_level' }),
                title:  `${prefix}acc_level`,
            },
            {
                accessChecked: true,
                path:          ['core', 'trading_accounts', 'mt5_group_type'],
                key:           'mt5GroupType',
                render:        this.renderBlockCont({ fieldName: 'mt5_group_type' }),
                title:         `${prefixMt}mt5_group_type`,
            },
            {
                path:   ['core', 'trading_accounts', 'acc_ccy'],
                key:    'accCcy',
                render: this.renderBlockCont({ isEnum: true, enumPath: 'core', enumName: 'AccountCurrencyEnum' }),
                title:  `${prefix}acc_ccy`,
            },
            {
                path: ['tima', 'tima_manager', 'id'],
                key:  'tima_manager_id',
            },
            {
                path:   ['tima', 'tima_manager', 'name'],
                key:    'timaManagerName',
                render: this.renderBlockContLink({ fieldName: 'tima_manager', path: '/tima/strategy' }),
                title:  `${prefix}tima_manager_name`,
            },
            {
                path:   ['core', 'trading_accounts', 'status'],
                key:    'status',
                render: this.renderBlockCont({ isEnum: true, enumPath: 'core', enumName: 'TradingAccountStatusEnum' }),
                title:  `${prefix}status`,
            },
            {
                path:   ['core', 'trading_accounts', 'platform'],
                key:    'platform',
                render: this.renderBlockCont({
                    isEnum:   true,
                    enumPath: 'core',
                    enumName: 'AccountBalancesPlatformEnum'
                }),
                title:  `${prefix}platform`,
            },
            {
                path:   ['core', 'trading_accounts', 'mt_group'],
                key:    'mtGroup',
                render: this.renderBlockCont({ fieldName: 'mt_group' }),
                title:  `${prefix}mt_group`,
            },
            {
                path:   ['core', 'trading_accounts', 'leverage'],
                key:    'leverage',
                render: this.renderBlockCont({ fieldName: 'leverage' }),
                title:  `${prefix}leverage`,
            },
            {
                accessChecked: true,
                path:          ['mt', 'user', 'enable_change_pass'],
                key:           'enableChangePass',
                render:        this.renderBlockCont({ fieldName: 'enable_change_pass' }),
                title:         `${prefix}enable_change_pass`,
            },
            {
                accessChecked: true,
                path:          ['mt', 'user', 'send_reports'],
                key:           'sendReports',
                render:        this.renderBlockCont({ fieldName: 'send_reports' }),
                title:         `${prefix}send_reports`,
            },
            {
                accessChecked: true,
                key:           'generateNewPassword',
                render:        this.renderBlockCont({ fieldName: 'generate_new_password' }),
                title:         `${prefix}generate_new_password`,
            },
            {
                path:   ['core', 'trading_accounts', 'demo_credentials'],
                key:    'passwordMain',
                render: this.renderBlockCont({ fieldName: 'password_main' }),
                title:  `${prefix}password_main`,
            },
            {
                path:   ['core', 'trading_accounts', 'demo_credentials'],
                key:    'passwordInvestor',
                render: this.renderBlockCont({ fieldName: 'password_investor' }),
                title:  `${prefix}password_investor`,
            },
            {
                accessChecked: true,
                path:          ['mt', 'user', 'enable_readonly'],
                key:           'enableReadonly',
                render:        this.renderBlockCont({ fieldName: 'enable_readonly' }),
                title:         `${prefix}enable`,
            },

            {
                accessChecked: true,
                path:          ['mt', 'user', 'balance'],
                key:           'balance',
                render:        this.renderBlockCont({ fieldName: 'balance', digits: 2 }),
                title:         `${prefixMt}balance`,
            },
            {
                accessChecked: true,
                path:          ['mt', 'user', 'credit'],
                key:           'credit',
                render:        this.renderBlockCont({ fieldName: 'credit', digits: 2 }),
                title:         `${prefixMt}credit`,
            },
            {
                accessChecked: true,
                path:          ['mt', 'user', 'margin_free'],
                key:           'marginFree',
                render:        this.renderBlockCont({ fieldName: 'margin_free', digits: 2 }),
                title:         `${prefixMt}margin_free`,
            },
            {
                accessChecked: true,
                path:          ['mt', 'user', 'equity'],
                key:           'equity',
                render:        this.renderBlockCont({ fieldName: 'equity', digits: 2 }),
                title:         `${prefixMt}equity`,
            },
            {
                accessChecked: true,
                path:          ['mt', 'user', 'margin_level'],
                key:           'marginLevel',
                render:        this.renderBlockCont({ digits: 2 }),
                title:         `${prefixMt}margin_level`,
            },
            {
                accessChecked: true,
                path:          ['mt', 'user', 'margin'],
                key:           'margin',
                render:        this.renderBlockCont({ fieldName: 'margin', digits: 2 }),
                title:         `${prefixMt}margin`,
            },
            {
                accessChecked: true,
                path:          ['mt', 'user', 'profit'],
                key:           'profit',
                render:        this.renderBlockCont({ fieldName: 'profit', digits: 2 }),
                title:         `${prefixMt}profit`,
            },

        ].map(({ title: t, xtitle: x, ...item }) => ({ ...item, ...x && { title: x }, ...t && { title: translate(t) }, }));
    }

    onDataLoad = () => {
        const setLoaded = (loaded) => this.setState(() => ({ loaded, }));

        setLoaded(false);

        (async () => {
            try {
                const groupsListData = await this._getGroupsList(this.props?.account_id);
                this.setSortedGroupsListByTradingServerId();
            } catch (error) {

            }
            setLoaded(true);
        })();
    };

    _getGroupsList = async (trading_account_id) => {
        try {
            const response = await asyncGet(`${GET_GROUPS_LIST_BY_TRADING_ACC_ID}/${trading_account_id}`);
            const data = response;

            this.setState((state) => (
                {
                    ...state,
                    groupsListAll: data,
                }));

            return data;
        } catch (error) {
            error?.showErrorNotification?.();

            return false;
        }
    };

    getGroupsListByTradingServerId = (groupsList, tradingServerId) => {
        if (groupsList instanceof Array && +tradingServerId >= 0) {
            return groupsList.filter((item) => {
                return item['trading_server_id'] === +tradingServerId;
            }).map((item) => {
                return item['name'];
            });
        } else {
            return [];
        }
    };

    setSortedGroupsListByTradingServerId = () => {
        const { groupsListAll } = this.state;
        const { data = {} } = this.props;
        const { trading_server_id } = data?.core?.trading_accounts;
        const groupsListByTradingServerId = (this.getGroupsListByTradingServerId(groupsListAll, trading_server_id)).sort((a, b) => {
            return a.localeCompare(b);
        });

        this.setState((state) => ({ ...state, groupsListByTradingServerId }));
    };

    renderBlockCont = ({ fieldName = '', digits = null, isEnum = false, enumPath = null, enumName = null } = {}) => (value, { item, items }) => {
        const title = item?.options?.title;
        const key = item?.key;
        const defaultValue = '-';

        let targetValue;

        switch (true) {
            case item?.access?.('show'): {
                if (isEnum) {
                    const enums = this.props?.enums?.[enumPath]?.[enumName];
                    targetValue = enums?.[value] ?? defaultValue;
                } else if (digits > 0) {
                    targetValue = (+value)?.toFixed?.(digits) ?? defaultValue;
                } else {
                    targetValue = value ?? defaultValue;
                }
                break;
            }
            default: {
                targetValue = defaultValue;
                break;
            }
        }

        return this.renderBlockContFragment({ fieldName, key, title, targetValue, value, item, items });
    };

    renderBlockContLink = ({ fieldName = '', path = null } = {}) => (value, { item, items }) => {
        const id = items?.[`${fieldName}_id`];
        const title = item?.options?.title;
        const key = item?.key;
        const defaultValue = '-';

        let targetValue;
        let blockUrl;

        switch (true) {
            case id?.access?.('show') && item?.access?.('show'): {
                const valueIdFromState = this?.state?.magicEditValuePrev?.[fieldName];
                const isValueIdFromState = !isNaN(parseInt(valueIdFromState));

                targetValue = value ?? defaultValue;

                if (isValueIdFromState) {
                    blockUrl = `${path}/${ this.values(fieldName)[valueIdFromState] }` ?? null;
                } else {
                    blockUrl = !isNaN(parseInt(`${id?.valueOf}`)) ? `${path}/${ id?.valueOf }` : null;
                }
                break;
            }
            case item?.access?.('show'): {
                targetValue = value ?? defaultValue;
                blockUrl = null;
                break;
            }
            default: {
                targetValue = defaultValue;
                blockUrl = null;
                break;
            }
        }

        return this.renderBlockContFragment({ key, title, targetValue, fieldName, blockUrl, value, item, items });
    };

    renderFullNameCont = ({ fieldName = null, path = null } = {}) => (value, { item, items }) => {
        const id = items?.[`${fieldName}Id`];
        const name = items?.[`${fieldName}Name`];
        const surname = items?.[`${fieldName}Surname`];

        const title = item?.options?.title;
        const key = item?.key;
        const defaultValue = '-';

        let targetValue;
        let blockUrl;

        switch (true) {
            case id?.access('show') && name?.access?.('show') && surname?.access?.('show'): {
                targetValue = `${ surname.valueOf ?? '' } ${ name.valueOf ?? '' }`;
                blockUrl = `${path}/${ id?.valueOf }`;
                break;
            }
            case name?.access?.('show') && surname?.access?.('show'): {
                targetValue = `${ surname.valueOf ?? '' } ${ name.valueOf ?? '' }`;
                blockUrl = null;
                break;
            }
            default: {
                targetValue = defaultValue;
                blockUrl = null;
                break;
            }
        }

        return this.renderBlockContFragment({ key, title, targetValue, fieldName, blockUrl });
    };

    renderBlockContFragment = ({ key, title, targetValue, fieldName, blockUrl, value, item, items }) => {
        const targetOption = fieldName === 'client' ? '_self' : '_blank';

        let titleWithFormatter = '';

        switch (fieldName) {
            case 'balance':
            case 'credit':
            case 'margin_free':
            case 'equity':
            case 'margin':
            case 'profit': {
                const currencyVal = items?.accCcy?.valueOf;
                const currency = this.getEnumObjByFieldName('acc_ccy')[currencyVal];

                titleWithFormatter = `${title}, ${currency}`;
            }
            default: {
                break;
            }
        }

        return (
            <div className="block-cont" key={`block-cont-${key}`}>
                <div className={`block`}>
                    <div className="block-header full-cont">
                        <div className="full-cont__item">
                            {blockUrl ?
                                (
                                    <Link to={`${blockUrl}`} className='link-btn-cont' target={targetOption}>
                                        <span className='block__name link-btn'>
                                            {titleWithFormatter ? titleWithFormatter : title}
                                        </span>
                                    </Link>
                                ) :
                                (
                                    <span
                                        className="block__name">{titleWithFormatter ? titleWithFormatter : title}</span>
                                )
                            }

                        </div>
                        <div className="full-cont__item">
                            {this.renderBlockInfoFragment({ fieldName, targetValue, value, item, items })}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderBlockInfoFragment = ({ fieldName, targetValue, value, item, items }) => {
        const { translate, enums } = this.props;

        switch (fieldName) {
            case 'generate_new_password': {
                const isVisible = this.confirmCheck(fieldName);
                const valueFromState = 1; // HARD CODE
                const { isGenerateNewPasswordDisabled } = this.state;
                const btnClassName = isGenerateNewPasswordDisabled ? 'button--disabled' : '';

                const conf = PermissionService.calc(this.oneTradingAccountGeneralInfoConfig);
                const resetPasswordPermission = conf?.coreTradingAccounts?.access('resetPassword') ?? false;

                return resetPasswordPermission ?
                    (
                        <div className="magic-confirm-wrapper">
                            <MagicConfirm
                                onAccept={this.generateNewPassword}
                                onReject={this.confirmRejectHandler(fieldName, valueFromState)}
                                title={translate(`magic_confirm_title`)}
                                accept={translate(`magic_confirm_yes`)}
                                reject={translate(`magic_confirm_no`)}
                                isVisible={!!isVisible}
                            />

                            <span className='block__info'>
                                <div className={`button turquoise ${btnClassName}`}
                                     onClick={this.onClickGenerateNewPasswordBtn(fieldName, valueFromState)}
                                >
                                    {translate(`list_accounts_table_send_password_btn`)}
                                </div>
                            </span>
                        </div>
                    ) : ('-');
            }

            case 'acc_level':
            case 'mt_group':
            case 'leverage': {
                const valueFromState = this.state?.magicEditValuePrev?.[fieldName] ?? this.values(fieldName).indexOf(value);
                const isVisible = this.confirmCheck(fieldName);
                const isEditMode = this.editModeCheck(fieldName);

                const tradingAccountLevel = this.getFieldValueFromProps('acc_level');

                if (fieldName === 'mt_group' && [6, 7].indexOf(+tradingAccountLevel) !== -1) {
                    // "Robox mini", "Robox simple": from TradingAccountLevelEnum
                    if (item?.access?.('show')) {
                        return (
                            <span className='block__info'>{this.texts(fieldName)[valueFromState]}</span>
                        );
                    }
                    return '-';
                }

                if (item?.access?.('show', 'update')) {

                    // custom case for acc_level miniK
                    if (fieldName === 'acc_level' && [15].indexOf(+tradingAccountLevel) === -1) {
                        if (item?.access?.('show', 'update')) {
                            const {enums = {}} = this.props;
                            const {core = {}} = enums;

                            const hasLevelMiniK = core?.TradingAccountLevelEnum[15];
                            if (hasLevelMiniK) {
                                delete core?.TradingAccountLevelEnum[15];
                            }
                        }
                    }

                    return (
                        <div className='magic-edit-mode'>
                            <span className='block__info edit-btn'
                                  onClick={this.onEnterEditMode(fieldName)}
                            >
                                {this.texts(fieldName)[valueFromState]}
                            </span>

                            {isEditMode ?
                                (
                                    <div
                                        className="magic-confirm-wrapper magic-confirm-wrapper--large-width magic-confirm-wrapper--position-right">
                                        <MagicConfirm
                                            onAccept={this.confirmAcceptHandler(fieldName, valueFromState)}
                                            onReject={this.confirmRejectHandler(fieldName, valueFromState)}
                                            title={translate(`magic_confirm_title`)}
                                            accept={translate(`magic_confirm_yes`)}
                                            reject={translate(`magic_confirm_no`)}
                                            isVisible={!!isVisible}
                                        />

                                        <MagicRadioSwitch
                                            className={`magic-radio-switch magic-radio-switch--max-height-200 scroll`}
                                            classNameText={`magic-switch__text magic-switch__text--padding-left`}
                                            direction={`column`}
                                            index={isVisible ? this.values(fieldName).indexOf(+isVisible[1]) : valueFromState}
                                            indexNone={true}
                                            // index={-1}
                                            reverse={false}
                                            texts={this.texts(fieldName)}
                                            values={this.values(fieldName)}
                                            onChange={this.onChange(fieldName)}
                                        />

                                        <i className="gl-icon close-btn"
                                           onClick={this.onClickCloseConfirmBtn(fieldName)}
                                        />
                                    </div>
                                ) : ''}
                        </div>
                    );
                } else if (item?.access?.('show')) {
                    return (
                        <span className='block__info'>{this.texts(fieldName)[valueFromState]}</span>
                    );
                }
                return '-';
            }
            case 'mt5_group_type': {
                const isVisible = this.confirmCheck(fieldName);
                const isEditMode = this.editModeCheck(fieldName);
                const valueFromState = this.state?.magicEditValuePrev?.[fieldName] ?? this.values(fieldName).indexOf(value);
                const magicConfirmText = translate('list_accounts_basic_information_mt_mt5_group_type_magic_confirm_text');
                const magicConfirmClassName = 'magic-confirm--large-width';

                let routeMap = {
                    'mt5_group_type': 'toggleNetGroup',
                };
                const routePermission = items?.coreTradingAccounts.access(routeMap[fieldName]) ?? false;

                return routePermission ? (
                    <div className='magic-edit-mode'>
                        <span className='block__info edit-btn'
                              onClick={this.onEnterEditMode(fieldName)}
                        >
                            {this.texts(fieldName)[valueFromState]}
                        </span>

                        {isEditMode ?
                            (
                                <div
                                    className="magic-confirm-wrapper magic-confirm-wrapper--large-width magic-confirm-wrapper--position-right">
                                    <MagicConfirm
                                        className={`magic-confirm ${magicConfirmClassName}`}
                                        onAccept={this.confirmAcceptHandler(fieldName, valueFromState)}
                                        onReject={this.confirmRejectHandler(fieldName, valueFromState)}
                                        title={translate(`magic_confirm_title`)}
                                        text={magicConfirmText}
                                        accept={translate(`magic_confirm_yes`)}
                                        reject={translate(`magic_confirm_no`)}
                                        isVisible={!!isVisible}
                                    />

                                    <MagicRadioSwitch
                                        className={`magic-radio-switch magic-radio-switch--max-height-200 scroll`}
                                        classNameText={`magic-switch__text magic-switch__text--padding-left`}
                                        direction={`column`}
                                        index={isVisible ? this.values(fieldName).indexOf(+isVisible[1]) : valueFromState}
                                        indexNone={true}
                                        // index={-1}
                                        reverse={false}
                                        texts={this.texts(fieldName)}
                                        values={this.values(fieldName)}
                                        onChange={this.onChange(fieldName)}
                                    />

                                    <i className="gl-icon close-btn"
                                       onClick={this.onClickCloseConfirmBtn(fieldName)}
                                    />
                                </div>
                            ) : ''}
                    </div>
                ) : (<span className='block__info'>{this.texts(fieldName)[valueFromState]}</span>);
            }
            case 'enable_change_pass':
            case 'send_reports':
            case 'enable_readonly': {
                const isVisible = this.confirmCheck(fieldName);
                const valueFromState = this.state?.magicEditValuePrev?.[fieldName] ?? this.values(fieldName).indexOf(value);

                let routeMap = {
                    'enable_change_pass': 'setEnableChangePassword',
                    'send_reports':       'setSendReports',
                    'enable_readonly':    'archive'
                };

                const conf = PermissionService.calc(this.oneTradingAccountGeneralInfoConfig);
                const routePermission = conf?.coreTradingAccounts?.access(routeMap[fieldName]) ?? false;

                return routePermission ? (
                    <div className="magic-confirm-wrapper">
                        <MagicConfirm
                            onAccept={this.confirmAcceptHandler(fieldName, valueFromState)}
                            onReject={this.confirmRejectHandler(fieldName, valueFromState)}
                            title={translate(`magic_confirm_title`)}
                            accept={translate(`magic_confirm_yes`)}
                            reject={translate(`magic_confirm_no`)}
                            isVisible={!!isVisible}
                        />

                        <MagicSwitch
                            classNameText={`magic-switch__text block__info edit-btn`}
                            index={isVisible ? this.values(fieldName).indexOf(+isVisible[1]) : valueFromState}
                            reverse={true}
                            values={this.values(fieldName)}
                            texts={this.texts(fieldName)}
                            onChange={this.onChange(fieldName)}
                            updateStateFromProps={true}
                        />
                    </div>
                ) : (<span className='block__info'>{this.texts(fieldName)[valueFromState]}</span>);
            }
            case 'password_main':
            case 'password_investor': {
                const defaultFieldValue = '-';

                try {
                    const demoCredentialsObj = JSON.parse(targetValue);
                    const fieldMap = fieldName === 'password_main' ? 'password' : 'passwordInvestor';
                    const passwordValue = demoCredentialsObj[fieldMap];

                    return (
                        <span className="block__info">{passwordValue}</span>
                    );
                } catch (error) {
                    return (
                        <span className="block__info">{defaultFieldValue}</span>
                    );
                }
            }
            default: {
                return (
                    <span className="block__info">{targetValue}</span>
                );
            }
        }
    };

    renderTitle (title, i, translate) {
        switch (i) {
            case 1: {
                return (
                    <div className='title-cont title-cont--with-btn information-wrapper'>
                        <h3 className='title'>{title}</h3>

                        <div className='update_info_btn'>
                                <span className='update_info_btn_refresh'
                                      onClick={this.onRefreshBtnClick}
                                >
                                {translate('list_accounts_risk_refresh')}
                            </span>
                        </div>
                    </div>
                );
            }
            default: {
                return (
                    <div className='title-cont'>
                        <h3 className='title'>{title}</h3>
                    </div>
                );
            }
        }
    }

    renderInformationCont (fieldsArray, castData) {
        const { translate, params, query } = this.props;

        return (
            <div className='information-cont'>
                {
                    fieldsArray.map((item, i) => {
                        return this.renderInformationContItem(item, i, castData);
                    })
                }
            </div>
        );
    }

    renderInformationContItem (fieldsPart, i, castData) {
        const { translate } = this.props;
        const titlesArr = ['list_accounts_basic_information_account', 'list_accounts_basic_information_state_account'];
        const tradingAccountType = this.getFieldValueFromProps('type');

        /*const TradingAccountTypeEnum = {
            "2": "Tima_manager",
            "5": "Trading",
            "6": "Demo",
            "7": "Demo_contest",
            "8": "Demo_risk",
            "10": "Demo_m",
        };*/

        let fieldsByTradingAccountType;

        switch (+tradingAccountType) {
            case 2: {
                // 'Tima_manager'
                fieldsByTradingAccountType = fieldsPart.filter((item) => {
                    return ['passwordMain', 'passwordInvestor'].indexOf(item) === -1;
                });
                break;
            }
            case 5: {
                // 'Trading'
                fieldsByTradingAccountType = fieldsPart.filter((item) => {
                    return ['passwordMain', 'passwordInvestor', 'timaManagerName'].indexOf(item) === -1;
                });
                break;
            }
            case 6:
            case 7:
            case 8:
            case 10: {
                // 'Demo', 'Demo_contest', 'Demo_risk', 'Demo_m',
                fieldsByTradingAccountType = fieldsPart.filter((item) => {
                    return ['timaManagerName'].indexOf(item) === -1;
                });
                break;
            }
            default: {
                fieldsByTradingAccountType = fieldsPart;
                break;
            }
        }

        return (
            <div className={`information-cont__item`} key={`information-cont__item-${i}`}>
                {this.renderTitle(translate(titlesArr[i]), i, translate)}

                <div className='blocks-wrapper'>
                    {
                        fieldsByTradingAccountType.map((item, i) => {
                            return (
                                <React.Fragment key={`key-${i}`}>
                                    {castData?.[item] ?? false}
                                </React.Fragment>
                            );
                        })
                    }
                </div>
            </div>
        );
    }

    getFieldValueFromProps = (fieldName) => {
        const { data = {} } = this.props;
        const defaultFieldValue = '';

        let fieldValue;

        try {
            switch (fieldName) {
                case 'type':
                case 'acc_level': {
                    fieldValue = data?.core?.trading_accounts[fieldName];
                    break;
                }

                default: {
                    fieldValue = defaultFieldValue;
                    break;
                }
            }
        } catch (error) {
            fieldValue = defaultFieldValue;
        }

        return fieldValue;
    };

    getEnumObjByFieldName = (fieldName) => {
        const { enums = {} } = this.props;
        const { core = {} } = enums;
        let enumObj = {};

        try {
            switch (fieldName) {
                case 'type': {
                    enumObj = core?.TradingAccountTypeEnum;
                    break;
                }
                case 'leverage': {
                    enumObj = core?.TradingAccountLeverageEnum;
                    break;
                }
                case 'status': {
                    enumObj = core?.TradingAccountStatusEnum;
                    break;
                }
                case 'acc_ccy': {
                    enumObj = core?.AccountCurrencyEnum;
                    break;
                }
                case 'acc_level': {
                    enumObj = this.props.isDemoTradingAccountType ? core?.TradingAccountDemoLevelEnum : core?.TradingAccountLevelEnum;
                    break;
                }
                case 'platform': {
                    enumObj = core?.AccountBalancesPlatformEnum;
                    break;
                }
                case 'enable_change_pass': {
                    enumObj = core?.Mt4UserEnableChangePassEnum;
                    break;
                }
                case 'send_reports': {
                    enumObj = core?.Mt4UserEnableSendReportsEnum;
                    break;
                }
                case 'enable_readonly': {
                    enumObj = core?.Mt4UserArchiveEnum;
                    break;
                }
                case 'mt5_group_type': {
                    enumObj = core?.Mt5GroupTypeEnum;
                    break;
                }

                default: {
                    break;
                }
            }
        } catch (error) {
            throw new Error(`Error getEnumObjByFieldName { fieldName: ${ fieldName } }`);
        }

        return enumObj;
    };

    texts = (fieldName) => {
        const enumAsObjectToValues = (_) => [...Object.entries(_).map(([key, value]) => value)];

        try {
            switch (fieldName) {
                case 'mt_group': {
                    const { groupsListByTradingServerId = [] } = this.state;

                    return groupsListByTradingServerId;
                }
                default: {
                    return enumAsObjectToValues(this.getEnumObjByFieldName(fieldName));
                }
            }
        } catch (error) {
            throw new Error(`Error texts { fieldName: ${ fieldName } }`);
        }
    };

    values = (fieldName) => {
        const enumAsObjectToKeys = (_, isNumber) => [...Object.entries(_).map(([key]) => isNumber ? +key : key)];

        try {
            switch (fieldName) {
                case 'mt_group': {
                    const { groupsListByTradingServerId = [] } = this.state;

                    return groupsListByTradingServerId;
                }
                case 'enable_change_pass':
                case 'send_reports':
                case 'enable_readonly': {
                    return enumAsObjectToKeys(this.getEnumObjByFieldName(fieldName), true);
                }

                default: {
                    return enumAsObjectToKeys(this.getEnumObjByFieldName(fieldName));
                }
            }
        } catch (error) {
            throw new Error(`Error values { fieldName: ${ fieldName } }`);
        }
    };

    onClickCloseConfirmBtn = (fieldName, valueFromStatePrev) => (event) => {
        this.setEditMode(false);
        this.setConfirm(false);
    };

    onChange = (fieldName, notification, notificationValuePrev, isDisabled) => ({ value, scrollPosY }) => {
        const isEditMode = this.editModeCheck(fieldName);

        if (isDisabled) {
            this.setConfirm(false);
            return;
        }

        if (!isEditMode) {
            this.setEditMode(false);
        }

        this.setConfirm([fieldName, value]);
    };

    onEnterEditMode = (fieldName) => () => {
        this.setEditMode([fieldName]);
        this.setConfirm(false);
    };

    generateNewPassword = () => {
        const { isGenerateNewPasswordDisabled } = this.state;
        const setBtnDisabled = (isGenerateNewPasswordDisabled) => {
            this.setState(() => ({ isGenerateNewPasswordDisabled, }));
        };

        if (isGenerateNewPasswordDisabled) {
            return;
        }

        setBtnDisabled(true);
        this.setConfirm(false);
        this.setLoadedInnerRequest(false);

        (async () => {
            try {
                const tradingAccountType = this.getFieldValueFromProps('type');
                const isDemoTradingAccountType = [6, 7, 8, 10].indexOf(+tradingAccountType) !== -1;
                const { account_id: accountId, } = this.props;
                const data = { reset_password: 1 };
                const response = await listAccountService.tradingAccountResetPassword(accountId, data);

                if (response && isDemoTradingAccountType) {
                    this.props.refreshTradingAccountData();
                }
            } catch (error) {
                error?.showErrorNotification?.();
            }

            this.setLoadedInnerRequest(true);
            setBtnDisabled(false);
        })();
    };

    onClickGenerateNewPasswordBtn = (fieldName, value) => (event) => {
        this.setConfirm([fieldName, value]);
    };

    onRefreshBtnClick = (event) => {
        this.props.refreshTradingAccountData();
    };

    confirmAcceptHandler = (fieldName, valuePrev) => async (event) => {
        const confirm = this.confirmCheck(fieldName);
        if (!confirm) {
            this.setConfirm(false);
            return;
        }
        const value = confirm[1];
        const { account_id: accountId, } = this.props;

        this.setLoadedInnerRequest(false);
        try {
            let putFunc = null;
            let data = { [fieldName]: value };

            switch (fieldName) {
                case 'acc_level': {
                    putFunc = listAccountService.tradingAccountAccLevel;
                    break;
                }
                case 'leverage': {
                    putFunc = listAccountService.tradingAccountLeverage;
                    break;
                }
                case 'mt_group': {
                    putFunc = listAccountService.tradingAccountMtGroup;
                    break;
                }
                case 'enable_change_pass': {
                    putFunc = listAccountService.tradingAccountEnableChangePassword;
                    data = { enable_change_password: value };
                    break;
                }
                case 'send_reports': {
                    putFunc = listAccountService.tradingAccountSendReports;
                    break;
                }
                case 'enable_readonly': {
                    putFunc = listAccountService.tradingAccountEnableArchive;
                    break;
                }
                case 'mt5_group_type': {
                    putFunc = listAccountService.tradingAccountMt5GroupType;
                    break;
                }
                default: {
                    throw new Error(`Can*t update the fieldName: '${fieldName}', no necessary put request!`);
                }
            }

            const response = await putFunc(accountId, data);

            if (!response) {
                throw new Error(`Can*t update the fieldName: '${fieldName}', please try again later!`);
            } else if (['acc_level', 'mt_group', 'mt5_group_type'].includes(fieldName)) {
                this.props.refreshTradingAccountData(); // refresh data
            }

            let newValue;

            switch (fieldName) {
                case 'acc_level':
                case 'leverage':
                case 'mt_group':
                case 'mt5_group_type': {
                    newValue = this.values(fieldName).indexOf(value);
                    break;
                }

                default: {
                    newValue = +value;
                    break;
                }
            }

            const state = {
                magicEditValuePrev: {
                    ...this.state.magicEditValuePrev,
                    [fieldName]: newValue,
                },
            };
            this.setState(state);

        } catch (error) {
            NotificationService.error({
                title:   "error",
                message: error.message,
                remove:  false,
            });
        }

        this.setLoadedInnerRequest(true);
        this.setConfirm(false);
        this.setEditMode(false);
    };

    confirmRejectHandler = (fieldName, valuePrev) => (event) => {
        this.setConfirm(false);
        this.setEditMode(false);
    };

    confirmCheck = (fieldName) => {
        const { confirm } = this.state;
        return confirm instanceof Array && confirm[0] === fieldName ? confirm : false;
    };

    editModeCheck = (fieldName) => {
        const { editMode } = this.state;
        return editMode instanceof Array && editMode[0] === fieldName ? editMode : false;
    };

    setConfirm = (confirm) => {
        this.setState((state) => ({ ...state, confirm }));
    };

    setEditMode = (editMode) => {
        this.setState((state) => ({ ...state, editMode }));
    };

    render () {
        const { loadedInnerRequest } = this.state;
        const { translate, fieldsForChecking, data = {}, platform } = this.props;
        const isPlatformMT5 = +platform === 2; // AccountBalancePlatformEnum
        const onlyMT5Fields = ['mt5GroupType',];
        const castData = PermissionService.cast(data, this.oneTradingAccountGeneralInfoConfig);

        const fieldsForCheckingByPlatform = fieldsForChecking?.map(
            (itemsPart) => itemsPart?.filter(
                (fieldName) => isPlatformMT5 ? true : !onlyMT5Fields.includes(fieldName)
            )
        );
        console.log(fieldsForCheckingByPlatform)

        return (
            <Loader
                loaded={this.state.loaded}
                loading={(<Preloader scale={1}/>)}
            >
                <div className='glalex-styles list-accounts'>

                    {!loadedInnerRequest ?
                        (
                            <Preloader className='loaderUniversal--fixed-pos' scale={1}/>
                        ) : false
                    }

                    {this.renderInformationCont(fieldsForCheckingByPlatform, castData)}
                </div>
            </Loader>
        );
    }
}

OneTradingAccountGeneralInfo.defaultProps = {
    fieldsForChecking: [
        [
            'mtLogin',
            'client',
            'createdAt',
            'updatedAt',
            'type',
            'accLevel',
            'mt5GroupType',
            'accCcy',
            'timaManagerName',
            'status',
            'platform',
            'mtGroup',
            'leverage',
            'enableChangePass',
            'sendReports',
            'generateNewPassword',
            'passwordMain',
            'passwordInvestor',
            'enableReadonly',
        ],
        [
            'balance',
            'credit',
            'equity',
            'margin',
            'marginFree',
            'marginLevel',
            'profit',
        ],
    ],

    /*editableFields:         [
        'acc_level',
        'mt_group',
        'leverage',
        'enable_change_pass',
        'send_reports',
        'enable_readonly',
    ],
    editableTextareaFields: [],*/
};

export default withLocalize(OneTradingAccountGeneralInfo);
