import React, { Component, Fragment, } from 'react';

import TargetPageNameItemForm from "./TargetPageNameItemForm";

class TargetPageNameList extends Component {
	get remainingLanguageOptions () {
		const { languageOptions, nameFormList, } = this.props;

		if(!nameFormList){
			return languageOptions
		}

		//filter those that have been already used
		return languageOptions.filter(languageOption => !nameFormList.find(formItem => formItem.language_id.value === languageOption.value));
	}

	get disableAddButton () {
		const noOptionsLeft = !this.remainingLanguageOptions.length || this.props.nameFormList.length === this.props.languageOptions.length;
		const lastItemIsNotSaved = this.props.nameFormList.at(-1) && !this.props.nameFormList.at(-1).id;

		return noOptionsLeft || this.props.isViewMode || lastItemIsNotSaved;
	}
	renderList = () => {
		const {
			translate,
			nameFormList,
			isViewMode,
			deleteNameFormDataItem,
			languageOptions,
			createTargetPageName,
			updateTargetPageName,
		} = this.props;

		return nameFormList.map((nameForm, index) => {
			return (
				<TargetPageNameItemForm
					createTargetPageName={ createTargetPageName }
					deleteNameFormDataItem={ () => deleteNameFormDataItem(index) }
					formData={ nameForm }
					isViewMode={ isViewMode }
					key={ index }
					languageOptions={ languageOptions }
					remainingLanguageOption={ this.remainingLanguageOptions }
					translate={ translate }
					updateTargetPageName={ (id, formData) => updateTargetPageName(id, formData, index) }
				/>);
		}).reverse();
	}
	render () {
		const { translate, addName, } = this.props;

		return (
			<>
				<div
					className={ `promo-popup__button promo-popup__button_add mb-3 ${ this.disableAddButton ? 'disabled' : '' }` }
					onClick={ addName }
				>
					{translate('partner_target_page_add_name_in_pa')}
				</div>
				<div className={ 'promo-popup__block' }>
					{this.renderList()}
				</div>
			</>
		);
	}
}

export default TargetPageNameList;