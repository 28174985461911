import React from 'react';
import moment from 'moment';

/**
 * @param {function} onChange callback to handle select action (REQUIERED)
 * @param {string} dateFrom current selected date (REQUIERED)
 */
class QuarterPicker extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            showDropdown: false,
        };
    }

    componentDidMount () {
        document.addEventListener('mousedown', this.handleClickOutside);
        this.setInitial();
    }

    componentWillUnmount () {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

        // кароче шоб по массивчику кварталов пробежаться и подогнать дату под начало квартала =)
        setInitial = () => {
            const year = this.props.dateFrom.slice(5);

            this.props.quarters.forEach((item) => {
                if (item.dateFrom <= year && item.dateTo >= year) {
                    this.props.onChange({ target: { dataset: { datefrom: item.dateFrom, dateto: item.dateTo }}});
                }
            });
        }

    save = async (state) => new Promise((next) => this.setState(state, next));

    handleClickOutside = async (e) => {
        if(!e.path){
            return //prevents errors in console
        }
        if (this.wrapperRef && !e.path.includes(this.wrapperRef)) {
            await this.save({ showDropdown: false });
        }
    }

    async toogleDropdown () {
        await this.save({ showDropdown: !this.state.showDropdown });
    }

    renderQuarterValue = () => {
        const value = this.props.dateFrom.slice(5); // to extract mm-dd from date (ex. '2012-12-12' => '12-12')
        let currentQuater = 'I';

        this.props.quarters.forEach((item) => {
            if (item.dateFrom <= value && item.dateTo >= value) {
                currentQuater = item.title;
            }
        });

        return currentQuater;
    }

    handleSelect = (e) => {
        this.props.onChange(e);
        this.toogleDropdown();
    }

    render () {
        const isCurrentYear = moment(this.props.dateFrom).format('YYYY') === moment().format('YYYY');
        const yesterdayDate = moment().add(-1, 'days').format('MM-DD');

        return (
            <div className = 'quarter-filter' ref = { (node) => this.wrapperRef = node } onClick = { () => this.toogleDropdown() }>
                <div className = 'value-wrapper'>
                    <span>{this.renderQuarterValue()}</span>
                </div>
                <div className = { `dropdown ${!this.state.showDropdown ? 'hidden' : ''}` }>
                    {this.props.quarters.map((item, i) => (
                        <div
                            className = { `item ${isCurrentYear ? yesterdayDate < item.dateFrom ? 'disabled' : '' : ''}` }
                            data-datefrom = { item.dateFrom }
                            data-dateto = { item.dateTo }
                            key = { i }
                            onClick = { this.handleSelect }
                        >
                            { item.title }
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default QuarterPicker;
