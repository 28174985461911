import React, { Component } from 'react';
import propTypes from 'prop-types';

export class SupportCard extends Component {
  static defaultProps = {
    title: 'title',
    children: 'content',
    className: '',
  };
  static propTypes = {
    title: propTypes.string,
    className: propTypes.string,
  };
  render() {
    const { title, children, className } = this.props;
    return (
      <div className={`card__wrapper ${className ?? ''}`}>
        <div className="card__header">
          <h2 className="header_title">{title}</h2>
        </div>
        <div className="card__content">{children}</div>
      </div>
    );
  }
};