import React, { Component } from 'react';
import { Table } from 'reactstrap';
import LeadTailsRow from './LeadTailsRow';
import { get_random_guid } from "../../../../../helpers/OtherHelper";
import { map } from 'lodash';
import PermissionService, { MODULE_KEYS as pm } from "../../../../../services/PermissionService";
import CheckUndefinedPermission from "../../../../../components/Common/CheckUndefinedPermission";

export default class LeadHistoryTable extends Component {
    state = {
        accountTails: this.props.jsonData
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        this.setState({
            accountTails: { ...nextProps.jsonData },
        });
    }

    render () {

        return (
            <Table className = 'table-ubordered'>
                <thead>
                    <tr>
                        <th>{this.props.translate(`client_transition`)}</th>
                        <th>{this.props.translate(`client_date`)}</th>
                    </tr>
                </thead>
                <CheckUndefinedPermission>
                    {PermissionService.actionForRoute(pm.CORE_ACCOUNT_TAILS, 'getTailsByAccount') ?
                        <tbody>
                            {map(this.state.accountTails,(item) => {
                                return (<LeadTailsRow tail = { item } key = { get_random_guid() } />);
                            })}
                        </tbody>
                        : ""
                    }
                </CheckUndefinedPermission>
            </Table>
        );
    }
}
