import React from 'react';
import { withLocalize } from "react-localize-redux";
import TaskBlock from "./TaskBlock";
import { Collapse } from 'reactstrap';

class OneDayTasksBlock extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            locale:   props.activeLanguage?.code,
            collapse: false,
        };
    }

    componentDidMount () {

    }

    save = async (state) => new Promise((next) => this.setState(state, next));

    toggle = async () => {
        await this.save({ collapse: !this.state.collapse, });
    };

    render () {
        const { tasks = [], targetDay, translate, avatar } = this.props;
        const tasksLength = tasks?.length;
        const MAX_CNT = 4;

        if (tasksLength > MAX_CNT) {
            const { collapse } = this.state;
            const firstData = tasks.slice(0, MAX_CNT - 1);
            const extraData = tasks.slice(MAX_CNT - 1);
            const moreTasksText = translate(collapse ? 'calendar_more_tasks_collapse' : 'calendar_more_tasks_expand');

            return (
                <React.Fragment>
                    {
                        firstData.map((item, index) => {
                            return (
                                <TaskBlock
                                    avatar={avatar}
                                    i={index}
                                    item={item}
                                    key={`task-block-${targetDay}-${index}`}
                                />
                            );
                        })
                    }

                    <div className={`event-block more-tasks ${collapse ? 'more-tasks--collapse' : 'more-tasks--expand'}`}
                         onClick={this.toggle}
                    >
                        {moreTasksText} ({tasksLength - MAX_CNT + 1})
                    </div>

                    <Collapse className='fullwidth-column' isOpen={collapse}>
                        {
                            extraData.map((item, index) => {
                                return (
                                    <TaskBlock
                                        avatar={avatar}
                                        i={index}
                                        item={item}
                                        key={`task-block-${targetDay}-${index}`}
                                    />
                                );
                            })
                        }
                    </Collapse>
                </React.Fragment>
            );
        } else {
            return tasks instanceof Array && tasks.map((item, index) => {
                return (
                    <TaskBlock
                        avatar={avatar}
                        i={index}
                        item={item}
                        key={`task-block-${targetDay}-${index}`}
                    />
                );
            });
        }
    }

}

export default withLocalize(OneDayTasksBlock);
