import {
  itemsBuild,
  itemsDrain,
  itemsResort,
  CFG_ITEM_TYPE,
} from '../Config';
import { onEnabled, render, } from '../Mentor/withDropDown';

const f_city = {
  'drop-down': '/api/core/city/with-account/drop-down?take=all',
  'o=': [ '=', '!=', ],
};

const f_country = {
  'drop-down': '/api/core/country/drop-down?take=all',
  'o=': [ '=', '!=', ],
};

const f_timeZone = {
  'drop-down': '/api/core/time-zone/drop-down?take=all&add_fields[]=offset',
  'o=': [ '=', '!=', ],
};

const p_city = {
  onEnabled: onEnabled('cities'),
  render: render('cities'),
};

const p_country = {
  onEnabled: onEnabled('countries'),
  render: render('countries'),
};

const p_timeZone = {
  onEnabled: onEnabled('timeZones'),
  render: render(
    'timeZones',
    _ => _ ? `${ _.name } (${ _.offset })` : '-',
  ),
};

export default {
  id: 160,
  ...[

    // filters
    ...[ {
      f: 'partners.requests.status',
      id: 'f:rStatus',
    }, {
      f: 'partners.requests.resolution',
      id: 'f:rResolution',
    }, {
      f: 'core.country.id',
      id: 'f:countryId',
      ...f_country,
    }, {
      f: 'core.city.id',
      id: 'f:cityId',
      ...f_city,
    }, {
      f: 'core.account.time_zone_id',
      id: 'f:timeZoneId',
      ...f_timeZone,
    }, {
      f: 'core.partner_account.id',
      id: 'f:paId',
      off: true, // TODO:
    }, {
      f: 'core.balance_account.id',
      id: 'f:baId',
      off: true, // TODO:
    }, {
      f: 'core.system_accounts.id',
      id: 'f:saId',
    }, {
      f: 'core.system_accounts.type',
      id: 'f:saType',
    }, {
      f: [ 'core.account.surname', 'core.account.name', ],
      id: 'f:aFullname',
      o: 'like',
    }, {
      f: 'partners.requests.created_at',
      id: 'f:rCreated',
    }, {
      f: 'partners.requests.updated_at',
      id: 'f:rUpdated',
    }, {
      f: [ 'core.user.surname', 'core.user.name', ],
      id: 'f:uFullname',
      o: 'like',
    }, {
      f: 'partners.partner.name',
      id: 'f:pName',
      o: 'like',
      off: true, // TODO:
    }, {
      f: 'partners.requests.count_referrals',
      off: true, // TODO:
    }, {
      f: 'core.system_accounts.id',
      off: true, // TODO:
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

    // orders
    ...[ {
      f: 'core.account.surname',
      id: 'o:aSurname',
    }, {
      f: 'partners.requests.status',
      id: 'o:rStatus',
    }, {
      f: 'partners.requests.resolution',
      id: 'o:rResolution',
    }, {
      f: 'partners.requests.created_at',
      id: 'o:rCreated',
    }, {
      f: 'partners.requests.updated_at',
      id: 'o:rUpdated',
    }, {
      f: 'core.user.surname',
      id: 'o:uSurname',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

    // panels
    ...[ {
      ii: 'f:rStatus',
      t: 'magic_filter_translate_partners_requests_status',
    }, {
      ii: 'f:rResolution',
      t: 'magic_filter_translate_partners_requests_resolution',
    }, {
      ii: 'f:countryId',
      off: true, // TODO:
      t: 'magic_filter_translate_partners_requests_country',
      ...p_country,
    }, {
      ii: 'f:cityId',
      t: 'magic_filter_translate_partners_requests_city',
      ...p_city,
    }, {
      ii: 'f:timeZoneId',
      t: 'magic_filter_translate_partners_requests_time_zone',
      ...p_timeZone,
    }, {
      ii: 'f:paId',
      off: true, // TODO:
      t: 'magic_filter_translate_partners_requests_partner_account_id',
    }, {
      ii: 'f:baId',
      off: true, // TODO:
      t: 'magic_filter_translate_partners_requests_balance_account',
    }, {
      ii: 'f:saId',
      t: 'magic_filter_translate_partners_requests_system_accounts',
    }, {
      ii: 'f:saType',
      t: 'magic_filter_translate_partners_requests_system_accounts_type',
    }, {
      ii: 'f:aFullname',
      t: 'magic_filter_translate_partners_requests_account',
    }, {
      ii: 'f:rCreated',
      t: 'magic_filter_translate_partners_requests_created_at',
    }, {
      ii: 'f:rUpdated',
      t: 'magic_filter_translate_partners_requests_updated_at',
    }, {
      ii: 'f:uFullname',
      t: 'magic_filter_translate_partners_requests_user',
    }, {
      ii: 'f:pName',
      off: true, // TODO:
      t: 'magic_filter_translate_partners_referrals_partner',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

  ]
  .map(itemsBuild)
  .reduce(itemsDrain, [])
  .reduce(itemsResort, {}),

  path: 'partners/requests',
  widget: false,
};