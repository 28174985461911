import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { withLocalize } from "react-localize-redux";
import Preloader from "../../../components/LoadingHOC/Preloader";
import Loader from "../../tima/components/Loader";
import CalendarFilter from "./CalendarFilter";
import Select from 'react-select';
import SideBarTaskList from "../../common/components/SideBarTaskList";
import moment from "moment";
import CalendarDayMagic from "./CalendarDayMagic";
import CalendarWeekMagic from "./CalendarWeekMagic";
import CalendarMonthMagic from "./CalendarMonthMagic";
import { calendarService, } from "../../../services/CalendarDataService";
import { userService, } from "../../../services/UserDataService";
import { profileService, } from "../../../services/ProfileDataService";
import {
    getTaskStatesDropdown,
    getTaskUrgencyDropdown,
} from "../../tasks/services/TaskRequestService";

const TAB_ID = {
    day:   'day',
    week:  'week',
    month: 'month',
};

Object.freeze(TAB_ID);

const CURRENT_DATE = new Date();

class CalendarMagic extends Component {
    constructor (props) {
        super(props);

        const { activeTab, translate } = props;
        const selectedOption = { value: activeTab, label: translate(`calendar_${activeTab}`) };
        const selectedDate = CURRENT_DATE;
        const selectedWeek = CURRENT_DATE;
        const selectedMonth = CURRENT_DATE;

        this.state = {
            activeTab,
            selectedOption,
            selectedDate,
            selectedMonth,
            filterUsers: null,
            selectStates: null,
            selectedUrgency: null,
            showCreate:  null,
            loaded:      false,
            avatar:      {
                hash: null,
                data: {},
            },
            calendar:    {
                hash: null,
                data: {}
            },
            userCalendarFilter: {
                data: [],
                hash: null,
            },
            profile: {
                data: [],
                hash: null,
            },
            urgencyFilter: [],
            statesFilter: [],
        };
    }

    get calendarConfig () {
        return [
            {
                path: ['tasks', 'tasks'],
                key:  'calendarTasks',
            },
        ];
    }

    getRequestParamsByTab = (tab) => {
        const { selectedDate, selectedWeek, selectedMonth } = this.state;
        const dateFormat = "YYYY-MM-DD";

        switch (tab) {
            case TAB_ID.month: {
                return {
                    from: moment(selectedMonth).startOf('month').format(dateFormat),
                    to:   moment(selectedMonth).endOf('month').format(dateFormat),
                    user_id: this.state.filterUsers,
                    urgency_id: this.state.selectedUrgency,
                    state_id: this.state.selectStates,
                };
            }
            case TAB_ID.week: {
                return {
                    user_id: this.state.filterUsers,
                    urgency_id: this.state.selectedUrgency,
                    state_id: this.state.selectStates,
                    from: moment(selectedWeek).startOf('week').format(dateFormat),
                    to:   moment(selectedWeek).endOf('week').format(dateFormat),
                };
            }
            case TAB_ID.day:
            default: {
                return {
                    from: moment(selectedDate).format(dateFormat),
                    to:   moment(selectedDate).format(dateFormat),
                    user_id: this.state.filterUsers,
                    urgency_id: this.state.selectedUrgency,
                    state_id: this.state.selectStates,
                };
            }
        }
    };

    params = () => this.props.match.params;

    query = () => new URLSearchParams(this.props.location.search);

    avatarsChange = async (avatar) => {
        if (avatar.hash===this.state?.avatar?.hash) return `${this.constructor.name}.avatarsChange: false`;
        await this.save({ avatar });
        return `${this.constructor.name}.avatarsChange: true`;
    };

    profileChange = async (profile) => {
        if (profile.hash===this.state?.profile?.hash) return `${this.constructor.name}.profileChange: false`;
        await this.save({ profile, });
        return `${this.constructor.name}.profileChange: true`;
    };

    componentDidMount = async () => {
        profileService.subscribe('profile', this.profileChange, this);
        calendarService.subscribe('tasks', this.calendarTasksChanges, this);
        calendarService.subscribe('filter', this.userCalendarFilterChange, this);
        userService.subscribe('getAllAvatar', this.avatarsChange, this);
        await profileService.profile;
        await this.save({ filterUsers: [this.state.profile.data.id] });
        await this.handleGetCalendar();
        const response = await Promise.all([
            calendarService.filter(),
            getTaskUrgencyDropdown(),
            getTaskStatesDropdown(),
        ]);
        await this.save({
            urgencyFilter: response[1],
            statesFilter: response[2],
        });

        const avatar = await userService.getAllAvatar();
        await this.save({ avatar });
    };

    componentWillUnmount = () => {
        profileService.unsubscribe('profile', this.profileChange, this);
        calendarService.unsubscribe('tasks', this.calendarTasksChanges, this);
        calendarService.unsubscribe('filter', this.userCalendarFilterChange, this);
        userService.unsubscribe('getAllAvatar', this.avatarsChange, this);
    };

    save = async (state) => new Promise((next) => this.setState(state, next));

    toggle = async (tab) => {
        if (this.state.activeTab !== tab) {
            await this.save({ activeTab: tab, });
        }
    };

    handleFilterChange = async (users, selectStates, selectedUrgency) => {
        await this.save({
            filterUsers: users,
            selectStates,
            selectedUrgency,
        });
        await this.handleGetCalendar();
    }

    changeSelectedDateWithRequest = async (selectedDate) => {
        await this.save({ selectedDate });
        await this.handleGetCalendar();
    };

    changeSelectedDayWithRequest = (mode) => async () => {
        let { selectedDate } = this.state;

        if (mode === 'prev') {
            selectedDate = moment(selectedDate).subtract(1, 'day');
        } else if (mode === 'next') {
            selectedDate = moment(selectedDate).add(1, 'day');
        }

        await this.save({ selectedDate });
        await this.handleGetCalendar();
    };

    changeSelectedWeekWithRequest = (mode) => async () => {
        let { selectedWeek } = this.state;

        if (mode === 'prev') {
            selectedWeek = moment(selectedWeek).subtract(7, 'day');
        } else if (mode === 'next') {
            selectedWeek = moment(selectedWeek).add(7, 'day');
        }

        await this.save({ selectedWeek });
        await this.handleGetCalendar();
    };

    changeSelectedMonthWithRequest = (mode) => async () => {
        let { selectedMonth } = this.state;

        if (mode === 'prev') {
            selectedMonth = moment(selectedMonth).subtract(1, 'months');
        } else if (mode === 'next') {
            selectedMonth = moment(selectedMonth).add(1, 'months');
        }

        await this.save({ selectedMonth });
        await this.handleGetCalendar();
    };

    onActiveTabChange = async (selectedOption) => {
        const selectedDate = new Date();
        const selectedMonth = new Date();
        const tab = selectedOption.value;

        if (this.state.activeTab !== tab) {
            // TODO: check the transition logic

            // this.props.history.push({
            //     pathname: `/calendar/${tab}`,
            //     state:    { filterUsers },
            // });

            await this.save({ activeTab: tab, selectedOption, selectedDate, selectedMonth, loaded: false });
            await this.handleGetCalendar();
        }
    };

    bucketSortBySelectedMode = (data, mode) => {
        switch (mode) {
            case TAB_ID.month: {
                const { selectedMonth } = this.state;
                const sortedData = {};
                const daysInMonth = moment(selectedMonth)?.daysInMonth();

                for (let i = 1; i <= daysInMonth; i++) {
                    sortedData[i] = [];
                }

                data.forEach((item) => {
                    const dayInMonth = moment(item?.['finish_date'])?.date();

                    !isNaN(dayInMonth) && sortedData?.[dayInMonth]?.push(item);
                });

                return sortedData;
            }
            case TAB_ID.week: {
                const sortedData = {};

                for (let i = 0; i < 24; i++) {
                    const sortedDataRow = {};

                    for (let j = 0; j < 7; j++) {
                        sortedDataRow[j] = [];
                    }

                    sortedData[i] = sortedDataRow;
                }

                data.forEach((item) => {
                    // TODO: CHECK THE WEEK DAY FORMAT!!!
                    let dayInWeek = moment(item?.['finish_date'])?.day();
                    const hour = moment(item?.['finish_date'])?.hour();

                    if (!isNaN(dayInWeek) && !isNaN(hour)) {
                        dayInWeek = +dayInWeek !== 0 ? dayInWeek - 1 : 6;
                        sortedData[hour][dayInWeek].push(item);
                    }
                });

                return sortedData;
            }
            case TAB_ID.day: {
                const sortedData = {};

                for (let i = 0; i < 24; i++) {
                    sortedData[i] = [];
                }

                data.forEach((item) => {
                    const hour = moment(item?.['finish_date'])?.hour();

                    !isNaN(hour) && sortedData[hour].push(item);
                });

                return sortedData;
            }
            default: {
                console.log(`No such mode: (${mode}) in bucketSortBySelectedMode !`);

                return data;
            }
        }
    };

    calendarTasksChanges = async (calendar) => {
        if (calendar.hash===this.state?.calendar?.hash) return `${this.constructor.name}.calendarTasksChanges: false`;
        await this.save({ calendar, });
        return `${this.constructor.name}.calendarTasksChanges: true`;
    };

    userCalendarFilterChange = async (userCalendarFilter) => {
        if (userCalendarFilter.hash===this.state?.userCalendarFilter?.hash) return `${this.constructor.name}.userCalendarFilterChange: false`;
        await this.save({ userCalendarFilter });
        return `${this.constructor.name}.userCalendarFilterChange: true`;
    };

    handleGetCalendar = async () => {
        const { activeTab } = this.state;
        const params = {
            ...this.getRequestParamsByTab(activeTab),
            date_type: 'finish_date', // TODO: delete HARD CODE of selected mode !!!
        };

        await this.save({ loaded: false, });

        try {
            const { data } = await calendarService.tasks(params);
            const { tasks = {}, clients = {} } = data;
            const { birth_day = [] } = clients;

            (birth_day instanceof Array) && birth_day?.forEach((birthDayItem) => {
                const birth_date = new Date(`${birthDayItem?.birth_date} 10:00:00`);
                const currentYear = (new Date()).getFullYear();
                const finish_date = moment(birth_date.setFullYear(currentYear)).format('YYYY-MM-DD HH:mm:ss');

                birthDayItem['finish_date'] = finish_date;
                birthDayItem['urgency'] = { alias: 'birthday' };
            });

            const tasksBucket = this.bucketSortBySelectedMode([...tasks, ...birth_day], activeTab);

            await this.save({ loaded: true, tasks: tasksBucket });
        } catch (error) {
            error?.showErrorNotification?.();
            await this.save({ loaded: true, tasks: {} });
        }
    };

    render = () => {
        const { translate } = this.props;
        const { loaded, activeTab, selectedOption, selectedDate, selectedWeek, selectedMonth, tasks, avatar } = this.state;
        const options = [
            { value: TAB_ID.month, label: translate(`calendar_month`) },
            { value: TAB_ID.week, label: translate(`calendar_week`) },
            { value: TAB_ID.day, label: translate(`calendar_day`) }
        ];
        const customStyles = {
            control:     () => ({}),
            singleValue: (base, state) => {
                const opacity = state.isDisabled ? 0.5 : 1;
                const transition = 'opacity 300ms';

                return { opacity, transition };
            },
        };

        const renderTabContent = (activeTab) => {
            switch (activeTab) {
                case TAB_ID.month: {
                    return (
                        <CalendarMonthMagic
                            avatar={avatar.data}
                            tasks={tasks}
                            nextMonth={this.changeSelectedMonthWithRequest('next')}
                            prevMonth={this.changeSelectedMonthWithRequest('prev')}
                            selectedMonth={selectedMonth}
                        />
                    );
                }
                case TAB_ID.week: {
                    return (
                        <CalendarWeekMagic
                            avatar={avatar.data}
                            selectedWeek={selectedWeek}
                            nextWeek={this.changeSelectedWeekWithRequest('next')}
                            prevWeek={this.changeSelectedWeekWithRequest('prev')}
                            tasks={tasks}
                        />
                    );
                }
                case TAB_ID.day: {
                    return (
                        <CalendarDayMagic
                            avatar={avatar.data}
                            selectedDate={selectedDate}
                            changeSelectedDateWithRequest={this.changeSelectedDateWithRequest}
                            nextDay={this.changeSelectedDayWithRequest('next')}
                            prevDay={this.changeSelectedDayWithRequest('prev')}
                            tasks={tasks}
                        />
                    );
                }
                default: {
                    return null;
                }
            }
        };

        return (
            <div>
                <div className='content-block'>
                    <div className='top-page-block'>
                        <h1 className='page-title'>{translate(`calendar_calendar`)}</h1>
                        <div className='top-button-area'>
                            {/* HIDE CREATE_TASK_BTN IN CURRENT VERSION OF CRM */}
                            {/*{PermissionService.actionForRoute(pm.TASKS_TASKS, PermissionService.storeRoute()) ?
                                                    <div onClick={this.setStateModalCreateTask} className='button turquoise'>{translate(`calendar_create_task`)}</div>
                                                : ""
                                            }*/}
                            <div className='form-select turquoise'>
                                <Select
                                    classNamePrefix='select'
                                    value={selectedOption}
                                    isSearchable={false}
                                    onChange={this.onActiveTabChange}
                                    options={options}
                                    styles={customStyles}
                                />
                            </div>
                            {/* HIDE calendar_google_sync IN CURRENT VERSION OF CRM */}
                            {/*<div className = 'button red bordered small'>{translate(`calendar_google_sync`)}</div>*/}
                        </div>
                    </div>
                    {this.state.userCalendarFilter?.data.length > 0 ? (
                        <CalendarFilter
                            data = { this.state.userCalendarFilter?.data }
                            handleFilterChange = { this.handleFilterChange }
                            multipleSelection = { false }
                            initSelectedUser = { this.state.profile.data?.id }
                            urgencyFilter = { this.state.urgencyFilter }
                            statesFilter = { this.state.statesFilter }
                            translate = { translate }
                        />
                    ) : null}
                </div>

                {/*{!isNull(this.state.showCreate) ?
                        <NewTaskCreateForm
                            quick = {false}
                            close = {this.setStateModalCreateTask}
                        />
                        : ""
                    }*/}

                <div className='calendar-wrapper'>
                    <Loader
                        loaded={loaded}
                        loading={(<Preloader scale={1}/>)}
                    >
                        <div className='tab-content'>
                            {renderTabContent(activeTab)}
                        </div>
                    </Loader>

                    <SideBarTaskList/>
                </div>
            </div>
        );
    };
}

export default withRouter(withLocalize(CalendarMagic));
