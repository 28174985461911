/* eslint-disable no-sparse-arrays */
export const setComplexErrorsFromServer = (errors, save) => {
	return save((state) => {
		return {
			errors: [
				...state.errors,
				...Object.keys(errors?.response?.data?.errors).reduce(
					(acummulator, next) => {
						if (next?.includes("symbols_list_id")) {
							acummulator.push(`symbols_list_id_${ next.match(/[0-9]+/) }`); //.match first int
						}
						if (next?.includes("groups_list_id")) {
							acummulator.push(`groups_list_id_${ next.match(/[0-9]+/) }`); //.match first int
						}
						if (next?.includes("deals")) {
							acummulator.push(`deals_${ next.match(/[0-9]+/) }`); //.match first int
						}

						return acummulator;
					},
					[],
				),,
			],
		};
	});
};

