const LinkConfig = (translate, order, options = {}, permissions) => [
  {
    id: 'lendings',
    link: '/partners/promo/lending',
    text: translate('partners_lendings'),
  },
  {
    id: 'promo',
    link: '/partners/promo',
    text: translate('partners_manage_promo'),
  },
  {
    id: 'partners',
    link: '/partners',
    text: translate('partners_h1'),
  },
  {
    id: 'dashboard',
    link: '/dashboard',
    text: translate('menu_dashboard'),
  },
]
.filter(({ id }) => order.includes(id))
.sort(({ id: a, }, { id: b, }) => order.indexOf(a) - order.indexOf(b))
.map( ({ id, ...data }) => ({ ...data, key: id, }))
.filter((item) => {
  if (permissions) {
    return permissions?.[item?.key]?.access('index') ?? false;
  }

  return true;
});

export default LinkConfig;
