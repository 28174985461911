/* eslint-disable max-len */
import React, { Component, } from "react";
import { withRouter, } from "react-router-dom";
import { withLocalize, } from "react-localize-redux";

import Loader from "../../../tima/components/Loader";
import Preloader from "../../../../components/LoadingHOC/Preloader";
import {
	MagicFilterPanels,
	MagicTable,
	Mentor,
} from "../../../../components/Magic";
import PagePanel from "../../../tima/components/Pages/PagePanel";

import { getCrons, } from "../../../../services/PartnersRequestService";
import { MagicTooltip, } from "../../../tima/components/Magic";

class ListOfCronsTable extends Component {
	constructor (props) {
		super(props);
		this.state = {
			confirm: false,
			data: [],
			filterLastChange: Date.now(),
			loaded: false,
			magicEditValuePrev: {},
			pages: {
				filter: 0,
				take: 50,
				total: 0,
				variance: 2,
			},
		};

		const mf = new Mentor({
			owner: this,
			serviceId: 3,
			translate: this.props.translate,
		});

		Object.defineProperty(this, "mf", { get: () => mf, });
	}

	get translate () {
		return this.props.translate;
	}

	get listOfCronsConfig () {
		const { translate, } = this.props;
		const prefix = "cron_";

		const result = [
			{
				key: "cronId",
				orderId: "o:cronId",
				path: [ "log", "cron", "id", ],
				render: this.renderInlineValue,
				title: `${ prefix }id`,
			},
			{
				key: "cronProject",
				orderId: "o:cronProject",
				path: [ "log", "cron", "project", ],
				render: this.renderInlineValue,
				title: `${ prefix }project`,
			},
			{
				key: "cronDescription",
				orderId: "o:cronDescription",
				path: [ "log", "cron", "description", ],
				render: this.renderInlineValue,
				title: `${ prefix }description`,
			},
			{
				key: "cronName",
				orderId: "o:cronName",
				path: [ "log", "cron", "name", ],
				render: this.renderInlineValue,
				title: `${ prefix }name`,
			},
			{
				key: "startAt",
				orderId: "o:startAt",
				path: [ "log", "cron", "start_at", ],
				render: this.renderDate,
				title: `${ prefix }start_at`,
			},
			{
				key: "endAt",
				orderId: "o:endAt",
				path: [ "log", "cron", "end_at", ],
				render: this.renderDate,
				title: `${ prefix }end_at`,
			},
			{
				key: "cronError",
				order: false,
				path: [ "log", "cron", "error", ],
				render: this.renderCronError,
				title: `${ prefix }error`,
			},
		].map(({
			orderId: o, title: t, xtitle: x, ...item
		}) => ({
			...item,
			...o && {
				order: async () => {
					await this.mf.orderedToNext(o);
					// await this.onDataLoad(); // TODO: remove cause reaction should be in subscribe
				},
				orderTo: this.mf.orderedTo(o),
			},
			...t && { title: translate(t), },
			...x && { title: x, },
		}));

		return result;
	}

	renderInlineValue = (value, { item, }) => {
		if (item?.access?.("index")) {
			return value ?? "-";
		}

		return "-";
	};

	renderCronError = (value, { item, items, data, }) => {
		if (item?.access?.('index')) {
			return (
				<MagicTooltip
					classNameTooltip="tooltip tooltip_medium"
					content={ value ?? '-' }
					lengthCut={ 50 }
				/>
			);
		}

		return "-";
	};

	renderDate = (value, { item, }) => {
		//needed to render date and time in separate lines
		if (item.access("index")) {
			if (!value) {
				return "-";
			}

			return value.split(" ").map(index => (
				<span className="row justify-content-center" key={ index }>
					{index}
				</span>
			));
		}

		return "-";
	};

	// eslint-disable-next-line react/sort-comp, require-await
	save = async state => new Promise(next => this.setState(state, next));

	mfChanged = async () => {
		await this.save({ filterLastChange: Date.now(), });
	};

	mfDoing = async () => {
		await this.save({ loaded: false, });
	};

	mfDone = async () => {
		await this.mfChanged();
		await this.onDataLoad();
	};

	query = () => new URLSearchParams(this.props?.location?.search);

	async componentDidMount () {
		this.mf.subscribe(
			{
				changed: this.mfChanged,
				// changing: ,
				cleaned: this.mfDone,
				cleaning: this.mfDoing,
				loaded: this.mfDone,
				loading: this.mfDoing,
				saved: this.mfDone,
				saving: this.mfDoing,
			},
			this,
		);

		await this.mf.init({ doEmit: true, });
	}

	componentWillUnmount () {
		this.mf.unsubscribe([ this.mfChanged, this.mfDoing, this.mfDone, ], this);
	}

	render = () => {
		const { translate, } = this.props;
		const { data, pages, } = this.state;

		const dataTotalExceedsPage = pages.take < pages.total;
		const noData = !data.length;

		const options = {
			config: this.listOfCronsConfig,
			data,
			head: [
				"cronId",
				"cronName",
				"cronProject",
				"cronDescription",
				"startAt",
				"endAt",
				"cronError",
			],
		};

		return (
			<>
				<MagicFilterPanels mf={ this.mf } show={ true } translate={ translate } />
				<div className="content-block">
					<div className="table-wrapper">
						<div className="position-relative">
							<Loader
								loaded={ this.state.loaded }
								loading={ <Preloader scale={ this.props.scale } /> }
								translate={ translate }
							>
								{noData ? (
									<h4 className="text-center">{translate("symbols_empty")}</h4>
								) : (
									<MagicTable { ...options } />
								)}

								{dataTotalExceedsPage && (
									<PagePanel
										doText={ this.onPageText }
										doTo={ pageId => `?page=${ pageId }` }
										filter={ this.state.pages.filter }
										page={ this.pageId() }
										take={ this.state.pages.take }
										total={ this.state.pages.total }
										variance={ this.state.pages.variance }
										onClick={ this.onPageClick }
									/>
								)}
							</Loader>
						</div>
					</div>
				</div>
			</>
		);
	};
	pageId = () => {
		const page = this.query().get("page");

		return Number(page) || 0;
	};

	pageIdAsGET = (pageId) => {
		// eslint-disable-next-line no-param-reassign
		pageId = pageId === undefined ? this.pageId() : pageId;
		const { filterId, } = this.mf;
		const result = {
			...filterId && { filterId, },
			skip: pageId * this.state.pages.take,
			take: this.state.pages.take,
		};

		return result;
	};

	onDataLoad = async (pageId) => {
		await this.save({ loaded: false, magicEditValuePrev: {}, });
		const pageOptions = this.pageIdAsGET(pageId);
		const loaded = (data, meta) => ({ pages, }) => ({
			data,
			pages: { ...pages, ...meta, },
		});

		try {
			const response = await getCrons(pageOptions);
			const {
				data = [],
				meta: { filter = 0, total = 0, },
			} = response;

			await this.save(loaded(data, { filter, total, }));
		} catch (error) {
			await this.save(loaded([], { filter: 0, total: 0, }));
			error?.showErrorNotification?.();
		}
		await this.save({ loaded: true, });
	};

	onPageClick = ({ pageId, }) => {
		// event.preventDefault();
		this.onDataLoad(pageId);
	};

	onPageText = (pageId, pageIs) => {
		const { translate, } = this.props;
		const {
			current, first, prev, next, last,
		} = pageIs;
		const {
			skipped, taken, filter, total,
		} = pageIs;

		if (skipped || taken) {
			const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : "?";
			const text = skipped ? translate("partners_pages_items") : "";

			return skipped ? `${ text }: ${ id }` : id;
		} else if (filter || total) {
			const id = Number.isInteger(pageId) ? `${ pageId }` : "?";
			const text = translate(
				filter ? "partners_pages_filtered" : "partners_pages_total",
			);

			return `${ text }: ${ id }`;
		} else if (first || prev || next || last) {
			return "";
		} else if (current) {
			return `${ pageId + 1 }`;
		}

		return `${ pageId + 1 }`;
	};
}
export default withRouter(withLocalize(ListOfCronsTable));