import React, { Component, } from "react";
import { withLocalize, } from "react-localize-redux";

class CreateGroupButtonsBlock extends Component {
	// eslint-disable-next-line no-useless-constructor
	constructor (props) {
		super(props);
	}

	render () {
		const {
			condition,
			showPopUp,
			errors,
			createNewAllowedGroup,
			updateCountriesCount,
			formData,
			fieldsIsNotEdit,
			setErrorClassGroup,
			translate,
		} = this.props;

		const isSelectedConditionType = condition.CPA || condition.CPL;
		const groups = formData?.country_groups?.map(_ => _.group);
		const isDisabledBtn = groups.length > 9 || fieldsIsNotEdit;

		return (
			<>
				{!isSelectedConditionType || showPopUp ? null : (
					<div className="mt-20 mb-20">
						<div className="payout-rate-list__payout_table-block">
							<button
								className={ `payout-rate-list__create-btn_btns-btn mr-35  ${ setErrorClassGroup(
									errors,
									"add_btn",
								) } ${
									isDisabledBtn
										? "payout-rate-list__create-btn_btns-btn-join-disabled"
										: "payout-rate-list__create-btn_btns-btn-join-group"
								}` }
								disabled={ isDisabledBtn }
								onClick={ createNewAllowedGroup }
							>
								{translate(`partners_payout_rate-list_add_allowed_countries`)}
							</button>
							<button
								className="payout-rate-list__create-btn_btns-btn payout-rate-list__create-btn_btns-btn-refresh"
								onClick={ updateCountriesCount }
							>
								{translate(`partners_payout_rate-list-refresh-country-list`)}
							</button>
						</div>
						<div className="payout-rate-list__underline mt-20" />
					</div>
				)}
			</>
		);
	}
}

export default withLocalize(CreateGroupButtonsBlock);