import React, { Component } from 'react';
import { withLocalize } from "react-localize-redux";
import { map, isUndefined } from "lodash";
import RiskManagerInstrumentItem from "./RiskManagerInstrumentItem";
import Hint from "./Hint";

class RiskManagerInstruments extends Component {
    constructor (props) {
        super(props);

        this.state = {
            currentToolsListByCategoryId: [],
            isHintActive:                 false,
            nameHint:                     '',
            width_hint:                   '',
            left:                         0,
            top:                          0,
        };
    }

    hintClose = (boop_type, name) => {
        this.setState(() => ({
            isHintActive: boop_type,
            nameHint:     name,
        }));
    };

    positionHint = (e) => {
        let box = document.getElementById('tools_blocks').getBoundingClientRect();
        this.setState({
            left: `${(e.clientX - e.target.offsetLeft) + 40}px`,
            top:  `${(e.clientY - e.target.offsetTop - box.top) - 350}px`,
        });
    };

    widthHintBlock = (width) => {
        this.setState(() => ({ width_hint: width }));
    };

    _getToolsListByCategoryId = (toolsList, categoryId) => {
        if (toolsList instanceof Array && +categoryId >= 0) {
            const toolsListFiltered = [];

            toolsList.forEach((item, i) => {
                if (item['risk_tools']['category_id'] === categoryId) {
                    const itemObj = item['risk_tools'];

                    itemObj['isSelected'] = item['risk_tools']['disabled']; // duplicate field value
                    toolsListFiltered.push(itemObj);
                }
            });

            return toolsListFiltered.sort((a, b) => {
                return (a.name).localeCompare(b.name);
            });
        } else {
            return [];
        }
    };

    getRisksCategories = (translate) => {
        const {
            riskToolsAll,
            riskToolsCategoriesAll,
        } = this.props;

        if (!isUndefined(riskToolsCategoriesAll)) {
            return map(riskToolsCategoriesAll, (item, key) => {
                const { risk_tool_categories = {} } = item;
                const { id } = risk_tool_categories;
                const toolsListByCategoryId = this._getToolsListByCategoryId(riskToolsAll, id);

                return (
                    <RiskManagerInstrumentItem
                        item={risk_tool_categories}
                        toolsListByCategoryId={toolsListByCategoryId}
                        transferToolsListByCategoryId={(currentToolsListByCategoryId) => {
                            this.setState({ currentToolsListByCategoryId });
                        }}
                        key={key}
                        translate={translate}
                        hintClose={this.hintClose}
                        widthHintBlock={this.widthHintBlock}
                        positionHint={this.positionHint}
                    />
                );
            });
        } else {
            return <h5 className='inactive_parameter'
                       style={{ 'padding': '0px 10px' }}>{translate(`list_accounts_risk_inactive_parameter`)}</h5>;
        }

    };

    render () {
        const { translate } = this.props;
        const { currentToolsListByCategoryId } = this.state;

        return (
            <React.Fragment>
                <div className='title-cont'>
                    <p className='title'>{translate(`list_accounts_risk_manager_trading_instruments`)}</p>
                </div>

                <div className='blocks-cont trading-tools-blocks' id='tools_blocks'>

                    {
                        this.getRisksCategories(translate)
                    }

                    {
                        this.state.isHintActive
                            ?
                            <Hint
                                hintClose={this.hintClose}
                                toolsListByCategoryId={currentToolsListByCategoryId}
                                width_hint={this.state.width_hint}
                                top={this.state.top}
                                left={this.state.left}
                                nameHint={this.state.nameHint}
                            />
                            : false
                    }

                </div>
            </React.Fragment>
        );
    }
}

export default withLocalize(RiskManagerInstruments);
