import React, { Component } from 'react';
import {
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle
} from 'reactstrap';
import classnames from 'classnames';
import { withRouter } from 'react-router-dom';
import { withLocalize } from "react-localize-redux";
import '../styles/template_management.scss';
import Modal from "./Modal";
import SideBarTaskList from "../../../common/components/SideBarTaskList";
import { TABID_0, TABID_1, CREATE_TEMPLATE, EDIT_TEMPLATE, SMS_TEMPLATE, E_MAIL_TEMPLATE, SMS_KEY, E_MAIL_KEY, CREATE_TEMPLATE_MANAGEMENT, NOT_ACTIVE } from './constants';
import HistoryChangesComponent from "../../../../components/HistoryChanges";
import DataStorage from "../../../../helpers/DataStorage";
import {
    SMS_HISTORY_CHANGES,
    SMS_HISTORY_CHANGES_TRANSLATION,
    MAIL_HISTORY_CHANGES,
    MAIL_HISTORY_CHANGES_TRANSLATION,
    SET_EMAILS,
    SET_SMS,
} from "../../../../apiRoutes";
import NotificationService from "../../../../services/NotificationService";
import PermissionService from "../../../../services/PermissionService";
import { Mentor, MagicFilterPanels } from "../../../../components/Magic";
import TemplateMagicTable from "./Modal/TemplateMagicTable";
import PagePanel from "../../../tima/components/Pages";
import { enumService } from "../../../../services/EnumDataService";
import { cachingService } from "../../../../services/CachingService";
import { templateService } from "../../../../services/TemplateDataService";
class TemplateManagement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dropdownOpen: false,
            smsTemplates: {
                hash: null,
                data: [],
            },
            mailTemplates: {
                hash: null,
                data: [],
            },
            templateData: {
                name: "",
                description: "",
                active: NOT_ACTIVE,
            },
            smsTemplatesTranslation: {
                hash: null,
                data: [],
            },
            mailTemplatesTranslation: {
                hash: null,
                data: [],
            },
            activeTab: TABID_0,
            modal_template_boolType: false,
            showHistoryChangesTemplate: false,
            showHistoryChangesTemplateTranslation: false,
            popUpTemplateManagement: {
                modal_key: null,
                modal_name: '',
                clickTypeTemplate: '',
                template_id: null,
            },
            lang_locale: '',
            language: {
                hash: null,
                data: [],
            },
            loaded: true,
            errors: {},
            pages: {
                filter: 0,
                total: 0,
                take: 50,
                variance: 2,
            },
            getCurrentTemplate: false,
            getTemplateTranslationStatus: false,
            sendRequest: false,
        };

        const mf = new Mentor({
            owner: this,
            serviceId: 1,
            translate: this.props.translate,
        });
        Object.defineProperty(this, 'mf', { get: () => mf, });

        const mfSMS = new Mentor({
            owner: this,
            serviceId: 2,
            translate: this.props.translate,
        });

        Object.defineProperty(this, 'mfSMS', { get: () => mfSMS, });
    }

    // eslint-disable-next-line react/sort-comp, require-await
    save = async state => new Promise(next => this.setState(state, next));

    get currentTemplate() {
        return (async () => {
            try {
                let validData = {};
                if (this.state.popUpTemplateManagement?.modal_name === SMS_TEMPLATE) {
                    const { template } = await templateService.smsTemplate(this.state.popUpTemplateManagement?.template_id);
                    validData = await this.checkValidData(template?.data?.core?.sms_templates ?? {});
                    return validData;
                } else {
                    const { template } = await templateService.mailTemplate(this.state.popUpTemplateManagement?.template_id);
                    validData = await this.checkValidData(template?.data?.core?.mail_templates ?? {});
                    return validData;
                }
            } catch (error) {
                return error;
            }
        })();
    }

    // eslint-disable-next-line require-await
    mfChanged = async () => {
        await this.save({ filterLastChange: Date.now(), });
    };

    mfDoing = async () => {
        await this.save({ loaded: false, });
    };

    mfDone = async () => {
        await this.mfChanged();
        await this.onDataLoad({ activeTab: this.state.activeTab });
    };

    async componentDidMount() {
        const enums = await enumService.enums;
        await this.save({ enums, });
        await this.onDataLoad({ activeTab: this.state.activeTab });
        // await this._mailTemplatesVariables();

        let localeLang = DataStorage.getData('locale');

        localeLang = localeLang ?? 'en';
        await this.save({ lang_locale: localeLang });
        await this.getLanguage();
        enumService.subscribe('enums', this.enumsChange, this);
        templateService.subscribe('smsTemplates', this.smsTemplatesChange, this);
        templateService.subscribe('smsTemplateTranslation', this.smsTemplatesTranslationChange, this);
        templateService.subscribe('mailTemplates', this.mailTemplatesChange, this);
        templateService.subscribe('mailTemplateTranslation', this.mailTemplatesTranslationChange, this);
        templateService.subscribe('language', this.languageChange, this);

        this.mf.subscribe({
            changed: this.mfChanged,
            // changing: ,
            cleaned: this.mfDone,
            cleaning: this.mfDoing,
            loaded: this.mfDone,
            loading: this.mfDoing,
            saved: this.mfDone,
            saving: this.mfDoing,
        }, this);
        await this.mf.init({ doEmit: true, });

        this.mfSMS.subscribe({
            changed: this.mfChanged,
            // changing: ,
            cleaned: this.mfDone,
            cleaning: this.mfDoing,
            loaded: this.mfDone,
            loading: this.mfDoing,
            saved: this.mfDone,
            saving: this.mfDoing,
        }, this);
        await this.mfSMS.init({ doEmit: true, });

        // await this.mf.emit('change', {}); //not sure what it does but triggers error in console

        await this.onDataLoad(); // TODO: moved to mfDone
    }

    componentWillUnmount = async () => {
        this.mf.unsubscribe([
            this.mfChanged,
            this.mfDoing,
            this.mfDone,
        ], this);

        this.mfSMS.unsubscribe([
            this.mfChanged,
            this.mfDoing,
            this.mfDone,
        ], this);
        enumService.unsubscribe('enums', this.enumsChange, this);
        templateService.unsubscribe('smsTemplates', this.smsTemplatesChange, this);
        templateService.unsubscribe('smsTemplateTranslation', this.smsTemplatesTranslationChange, this);
        templateService.unsubscribe('mailTemplates', this.mailTemplatesChange, this);
        templateService.unsubscribe('mailTemplateTranslation', this.mailTemplatesTranslationChange, this);
        templateService.unsubscribe('language', this.languageChange, this);
    };
    get params() { return this.props.match.params; }

    get query() { return new URLSearchParams(this.props.location.search); }

    enumsChange = async (enums) => {
        if (enums.hash === this.state?.enums?.hash) return `${this.constructor.name}.enumsChange: false`;
        await this.save({ enums, });
        return `${this.constructor.name}.enumsChange: true`;
    };

    smsTemplatesChange = async (currentTemplates) => {
        if (currentTemplates.hash === this.state?.smsTemplates?.hash) return `${this.constructor.name}.smsTemplatesChange: false`;
        const { meta: { filter = 0, total = 0, } } = currentTemplates.templates;
        await this.save({
            smsTemplates: {
                hash: currentTemplates.hash,
                data: currentTemplates.templates.data,
            },
            pages: { ...this.state.pages, ...{ filter, total } },
        });
        return `${this.constructor.name}.smsTemplatesChange: true`;
    };

    smsTemplatesTranslationChange = async (translations) => {
        if (translations.hash === this.state?.smsTemplatesTranslation?.hash) return `${this.constructor.name}.smsTemplatesTranslationChange: false`;
        await this.save({
            smsTemplatesTranslation: {
                hash: translations.hash,
                data: translations.data.data,
            },
        });
        return `${this.constructor.name}.smsTemplatesTranslationChange: true`;
    };

    mailTemplatesChange = async (currentTemplates) => {
        if (currentTemplates.hash === this.state?.mailTemplates?.hash) return `${this.constructor.name}.mailTemplatesChange: false`;
        const { meta: { filter = 0, total = 0, } } = currentTemplates.templates;
        await this.save({
            mailTemplates: {
                hash: currentTemplates.hash,
                data: currentTemplates.templates.data,
            },
            pages: { ...this.state.pages, ...{ filter, total } },
        });
        return `${this.constructor.name}.mailTemplatesChange: true`;
    };

    mailTemplatesTranslationChange = async (translations) => {
        if (translations.hash === this.state?.mailTemplatesTranslation?.hash) return `${this.constructor.name}.mailTemplatesTranslationChange: false`;
        await this.save({
            mailTemplatesTranslation: {
                hash: translations.hash,
                data: translations.data.data,
            },
        });
        return `${this.constructor.name}.mailTemplatesTranslationChange: true`;
    };

    languageChange = async (language) => {
        if (language.hash === this.state?.language?.hash) return `${this.constructor.name}.languageChange: false`;
        const name = DataStorage.getData('locale');
        const newLanguage = language?.data?.data?.map?.(item => ({ value: item.core.language.id, label: item.core.language[`name_${name}`] })) ?? [];
        await this.save({
            language: {
                hash: language.hash,
                data: newLanguage,
            },
        });
        return `${this.constructor.name}.languageChange: true`;
    };

    pageId = () => {
        const query = this.query;
        const page = query.get('page');
        return Number(page) || 0;
    };

    pageIdAsGET = (pageId) => {
        pageId = pageId === undefined ? this.pageId() : pageId;
        const { filterId, } = this.mf ?? {};
        const result = {
            filterId,
            take: this.state.pages.take,
            skip: pageId * this.state.pages.take,
        };
        return result;
    };

    pageIdAsGETSMS = (pageId) => {
        pageId = pageId === undefined ? this.pageId() : pageId;
        const { filterId, } = this.mfSMS ?? {};
        const result = {
            filterId,
            take: this.state.pages.take,
            skip: pageId * this.state.pages.take,
        };
        return result;
    };

    onDataLoad = async ({ pageId, activeTab } = {}) => {
        await this.save({ loaded: false });
        const pageOptions = this.pageIdAsGET(pageId);
        const pageOptionsSMS = this.pageIdAsGETSMS(pageId);
        const loaded = (name, selectedTemplates, meta) => ({ pages, }) => ({
            [name]: {
                hash: selectedTemplates.hash,
                data: selectedTemplates.templates.data,
            },
            pages: { ...pages, ...meta, },
        });
        try {
            if (activeTab === TABID_0) {
                const smsTemplates = await templateService.smsTemplates(pageOptionsSMS);
                const { meta: { filter = 0, total = 0, } } = smsTemplates.templates;
                await this.save(loaded("smsTemplates", smsTemplates, { filter, total, }));
            } else {
                const mailTemplates = await templateService.mailTemplates(pageOptions);
                const { meta: { filter = 0, total = 0, } } = mailTemplates.templates;
                await this.save(loaded("mailTemplates", mailTemplates, { filter, total, }));
            }
        } catch (error) {
            await this.save(loaded([], { filter: 0, total: 0, }));
        }
        await this.save({ loaded: true });
    };

    onPageClick = async ({ event, pageId, pageIs, }) => {
        await this.onDataLoad({ pageId, activeTab: this.state.activeTab });
    };

    onPageText = (pageId, pageIs) => {
        const { translate } = this.props;
        const { current, first, prev, next, last, } = pageIs;
        const { skipped, taken, filter, total, } = pageIs;
        if (skipped || taken) {
            const id = Number.isInteger(pageId) ? `${pageId + 1}` : '?';
            const text = skipped ? translate('partners_pages_items') : '';
            return skipped ? `${text}: ${id}` : id;
        } else if (filter || total) {
            const id = Number.isInteger(pageId) ? `${pageId}` : '?';
            const text = translate(filter ? 'partners_pages_filtered' : 'partners_pages_total');
            return `${text}: ${id}`;
        } else if (first || prev || next || last) {
            return '';
        } else if (current) {
            return `${pageId + 1}`;
        } else {
            return `${pageId + 1}`;
        }
    };

    get templatesConfig() {
        return [
            {
                path: ['core', 'sms_templates'],
                key: 'smsTemplates',
            },
            {
                path: ['core', 'mail_templates'],
                key: 'mailTemplates',
            },
        ];
    }

    getLanguage = async () => {
        try {
            const response = await templateService.language();
            const name = DataStorage.getData('locale');
            const newLanguage = response?.data?.data?.map?.(item => ({ value: item.core.language.id, label: item.core.language[`name_${name}`] })) ?? [];
            await this.save({
                language: {
                    hash: response.hash,
                    data: newLanguage,
                },
            });
        } catch (error) {
            NotificationService.errors(error);
        }
    };

    getCurrentTemplate = async () => {
        try {
            await this.save({ getCurrentTemplate: false });
            const templateData = await this.currentTemplate;
            await this.save({
                templateData,
                getCurrentTemplate: true,
            });
        } catch (error) {
            await this.save({ getCurrentTemplate: true });
            this.errorFromServer(error.response.data.errors);
        }
    };

    checkValidData = object => Object.fromEntries(Object.entries(object).map((items) => items.map((item, i) => item !== null ? item : items[i] = "")));

    togglePopup = async () => {
        await this.save((state) => {
            return { modal_template_boolType: !state.modal_template_boolType };
        });
    };

    reloadTemplatesTranslation = async (template_id, modal_name) => {
        try {
            if (modal_name === SMS_TEMPLATE) {
                const response = await templateService.smsTemplateTranslation(template_id);
                await this.save({
                    smsTemplatesTranslation: {
                        hash: response.hash,
                        data: response.data.data,
                    },
                });
            } else if (modal_name === E_MAIL_TEMPLATE) {
                const response = await templateService.mailTemplateTranslation(template_id);
                await this.save({
                    mailTemplatesTranslation: {
                        hash: response.hash,
                        data: response.data.data,
                    },
                });
            }
        } catch (error) {
            this.errorFromServer(error.response.data.errors);
        }
    };

    handleDropdownClick = async () => this.save({ dropdownOpen: !this.state.dropdownOpen });

    togglePopUpTemplateManagement = async (keyForTemplate, clickTypeTemplate, template_id) => {
        try {
            const key = keyForTemplate ?? null;
            await this.save((state) => {
                return {
                    popUpTemplateManagement: {
                        modal_key: key,
                        modal_name: key === SMS_KEY ? SMS_TEMPLATE : key === E_MAIL_KEY ? E_MAIL_TEMPLATE : '',
                        clickTypeTemplate: clickTypeTemplate,
                        template_id: template_id ?? null,
                    },
                    templateData: { ...state.templateData, name: '', description: '', active: NOT_ACTIVE },
                    getCurrentTemplate: true,
                };
            });

            if (key === SMS_KEY && clickTypeTemplate === EDIT_TEMPLATE) {
                const response = await templateService.smsTemplateTranslation(template_id);
                await this.save({
                    smsTemplatesTranslation: {
                        hash: response.hash,
                        data: response.data.data,
                    },
                });
            } else if (key === E_MAIL_KEY && clickTypeTemplate === EDIT_TEMPLATE) {
                const response = await templateService.mailTemplateTranslation(template_id);
                await this.save({
                    mailTemplatesTranslation: {
                        hash: response.hash,
                        data: response.data.data,
                    },
                });
            }
            await this.save({ getTemplateTranslationStatus: true });
        } catch (error) {
            await this.save({ getTemplateTranslationStatus: true });
            this.errorFromServer(error.response.data.errors);
        }
    };

    validateCreateFields = async () => {
        await this.save({ errors: {} });
        Object.keys(this.state.templateData).forEach((item) => {
            if (item === 'name' || item === 'description') {
                this.save({
                    templateData: {
                        ...this.state.templateData,
                        [item]: this.state.templateData?.[item]?.trim(),
                    },
                });
            }
        });
        const validationError = [];
        const createTemplates = Object.fromEntries(
            ['name', 'description'].filter((field) => this.state.templateData?.[field] === null || this.state.templateData?.[field] === '' || this.state.templateData?.[field]?.length === 0)
                .map((field) => [field, true]),
        );
        if (Object.keys(createTemplates).length) {
            await this.save({ errors: createTemplates });
        }
        Object.keys(this.state.errors).forEach(item => {
            if (this.state.errors[item] === true) {
                validationError.push(item);
            }
        });

        if (validationError.length) {
            return false;
        } else {
            await this.save({ errors: {} });
            return true;
        }
    };

    errorFromServer = object => {
        let serverErrorKeys = Object.keys(object) ?? [];

        serverErrorKeys.forEach(async (item) => {
            await this.save({ errors: { ...this.state.errors, [item]: true } });

            NotificationService.error({
                title: 'error',
                message: object[item],
                remove: false,
            });
        });
    };

    clearErrorForClick = async (name) => {
        await this.save({ errors: { ...this.state.errors, [name]: false } });
    };

    sendCreateTemplate = async (key, clickTypeTemplate, modal_name) => {
        try {
            await this.save({ sendRequest: true });
            if (await this.validateCreateFields()) {
                const modalKey = key === SMS_KEY ? SMS_TEMPLATE : key === E_MAIL_KEY ? E_MAIL_TEMPLATE : '';
                if (modal_name === SMS_TEMPLATE) {
                    const { data: { id } } = await templateService.createSmsTemplates(this.state.templateData);
                    await this.clearDataInState(key, clickTypeTemplate, modalKey, id);

                    //date returns null so caching service dosn't work
                    const data = await cachingService.getStoredItem(`${SET_SMS}/filter`, { method: 'POST', });

                    await cachingService.clean({ match: ({ isSession, key }) => isSession && key.indexOf(`POST:${SET_SMS}/filter`) > 0, });
                    await cachingService.clean({ match: ({ isSession, key }) => isSession && key.indexOf(`GET:${SET_SMS}/filter/${data.id}`) > 0, });
                } else if (modal_name === E_MAIL_TEMPLATE) {
                    const { data: { id } } = await templateService.createMailTemplates(this.state.templateData);
                    await this.clearDataInState(key, clickTypeTemplate, modalKey, id);
                    await cachingService.clean({ match: ({ isSession, key }) => isSession && key.indexOf(`GET:${SET_EMAILS}`) > 0, });
                }
                this.closePopUpModule();
                await this.save({ sendRequest: false });
                await this.onDataLoad({ activeTab: this.state.activeTab });
                NotificationService.success({
                    title: 'success',
                    message: this.props.translate(`template_management_create_template`),
                    remove: true,
                    view: true,
                });
            } else {
                await this.save({ sendRequest: false });
                return null;
            }
        } catch (error) {
            await this.save({ sendRequest: false });
            this.errorFromServer(error.response.data.errors);
        }
    };

    sendUpdateTemplate = async (modal_name) => {
        try {
            await this.save({ sendRequest: true });
            if (await this.validateCreateFields()) {
                if (modal_name === SMS_TEMPLATE) {
                    await templateService.putSmsTemplates(this.state.popUpTemplateManagement.template_id, this.state.templateData);

                    //date returns null so caching service dosn't work
                    const data = await cachingService.getStoredItem(`${SET_SMS}/filter`, { method: 'POST', });

                    await cachingService.clean({ match: ({ isSession, key }) => isSession && key.indexOf(`POST:${SET_SMS}/filter`) > 0, });
                    await cachingService.clean({ match: ({ isSession, key }) => isSession && key.indexOf(`GET:${SET_SMS}/filter/${data.id}`) > 0, });
                } else if (modal_name === E_MAIL_TEMPLATE) {
                    await templateService.putMailTemplates(this.state.popUpTemplateManagement.template_id, this.state.templateData);
                    await cachingService.clean({ match: ({ isSession, key }) => isSession && key.indexOf(`GET:${SET_EMAILS}`) > 0, });
                }
                await this.onDataLoad({ activeTab: this.state.activeTab });
                await this.save({ sendRequest: false });
                NotificationService.success({
                    title: 'success',
                    message: this.props.translate(`template_management_update_template`),
                    remove: true,
                    view: true,
                });
            } else {
                await this.save({ sendRequest: false });
                return null;
            }
        } catch (error) {
            await this.save({ sendRequest: false });
            this.errorFromServer(error.response.data.errors);
        }
    };

    deleteTemplate = async (id, tabId) => {
        try {
            await this.save({ sendRequest: true });
            if (+tabId === SMS_KEY) {
                await templateService.deleteSmsTemplates(id);

                //date returns null so caching service dosn't work
                const data = await cachingService.getStoredItem(`${SET_SMS}/filter`, { method: 'POST', });

                await cachingService.clean({ match: ({ isSession, key }) => isSession && key.indexOf(`POST:${SET_SMS}/filter`) > 0, });
                await cachingService.clean({ match: ({ isSession, key }) => isSession && key.indexOf(`GET:${SET_SMS}/filter/${data.id}`) > 0, });
            } else if (+tabId === E_MAIL_KEY) {
                await templateService.deleteMailTemplates(id);
                await cachingService.clean({ match: ({ isSession, key }) => isSession && key.indexOf(`GET:${SET_EMAILS}`) > 0, });
            }
            await this.onDataLoad({ activeTab: this.state.activeTab });
            await this.save({ sendRequest: false });
            NotificationService.success({
                title: 'success',
                message: this.props.translate(`template_management_delete_template`),
                remove: true,
                view: true,
            });
        } catch (error) {
            await this.save({ sendRequest: false });
            NotificationService.errors(error);
        }
    };

    closePopUpModule = async () => this.save({
        modal_template_boolType: !this.state.modal_template_boolType,
        templateData: { name: '', description: '', active: NOT_ACTIVE },
        errors: {},
        mailTemplatesTranslation: { hash: null, data: [] },
    });

    clearDataInState = async (key, clickTypeTemplate, modalKey, id) => {
        await this.save((state) => {
            return {
                mailTemplatesTranslation: { hash: null, data: [] },
                smsTemplatesTranslation: { hash: null, data: [] },
                popUpTemplateManagement: {
                    modal_key: key,
                    modal_name: modalKey,
                    clickTypeTemplate,
                    template_id: id ?? null,
                },
                modal_template_boolType: !state.modal_template_boolType,
                templateData: { name: '', description: '', active: NOT_ACTIVE },
            };
        });
    };

    toggle = async (tab) => {
        if (this.state.activeTab !== tab) {
            await this.save({ activeTab: tab, loaded: false });
            await this.onDataLoad({ activeTab: tab });
        } else {
            return null;
        }
    };

    showHistoryChangesTemplate = async () => this.save({ showHistoryChangesTemplate: !this.state.showHistoryChangesTemplate });

    showHistoryChangesTemplateTranslation = async (id) => this.save({
        showHistoryChangesTemplateTranslation: !this.state.showHistoryChangesTemplateTranslation,
        popUpTemplateManagement: { ...this.state.popUpTemplateManagement, template_translation_id: id },
    });

    setToStateTemplateName = async (value, name) => {
        await this.save({
            templateData:
            {
                ...this.state.templateData,
                [name]: value,
            },
        });
    };

    setToStateActiveCheckbox = async (activeStatus) => {
        await this.save({
            templateData: { ...this.state.templateData, active: activeStatus },
        });
    };

    render() {
        const { translate } = this.props;
        const { activeTab } = this.state;
        const conf = PermissionService.calc(this.templatesConfig);
        const pathMap = { [SMS_KEY]: 'smsTemplates', [E_MAIL_KEY]: 'mailTemplates' };

        const renderDropdownItem = ({ item, key }) => {
            const permission = conf?.[pathMap[key]];

            if (permission?.access('index', 'store')) {
                return (
                    <DropdownItem
                        key={key}
                        onClick={async () => {
                            this.toggle(item.key.toString());
                            await this.togglePopUpTemplateManagement(item.key, CREATE_TEMPLATE, null);
                            await this.togglePopup();
                        }}

                    >
                        {item.label}
                    </DropdownItem>
                );
            } else if (permission?.access('index')) {
                return (
                    <DropdownItem
                        className={'button--disabled'}
                        key={key}
                        disabled
                    >
                        {item.label}
                    </DropdownItem>
                );
            } else {
                return null;
            }
        };

        return (
            <div className='templateManagement'>

                {this.state.modal_template_boolType ?
                    <Modal
                        popUpTemplateManagement={this.state.popUpTemplateManagement}
                        showHistoryChangesTemplate={this.showHistoryChangesTemplate}
                        showHistoryChangesTemplateTranslation={this.showHistoryChangesTemplateTranslation}
                        modalTemplatesTranslation={this.state.popUpTemplateManagement.modal_name === SMS_TEMPLATE ? this.state.smsTemplatesTranslation.data : this.state.popUpTemplateManagement.modal_name === E_MAIL_TEMPLATE ? this.state.mailTemplatesTranslation.data : null}
                        setToStateTemplateName={this.setToStateTemplateName}
                        lang_locale={this.state.lang_locale}
                        language={this.state.language.data}
                        setToStateActiveCheckbox={this.setToStateActiveCheckbox}
                        templatesCreate={this.state.templateData}
                        sendCreateTemplate={this.sendCreateTemplate}
                        sendUpdateTemplate={this.sendUpdateTemplate}
                        deleteTemplate={this.deleteTemplate}
                        closePopUpModule={this.closePopUpModule}
                        reloadTemplatesTranslation={this.reloadTemplatesTranslation}
                        togglePopUpTemplateManagement={this.togglePopUpTemplateManagement}
                        togglePopup={this.togglePopup}
                        clearErrorForClick={this.clearErrorForClick}
                        errors={this.state.errors}
                        getCurrentTemplate={this.getCurrentTemplate}
                        enums={this.state?.enums?.data?.core?.MailTemplateActiveEnum}
                        getCurrentTemplateStatus={this.state.getCurrentTemplate}
                        getTemplateTranslationStatus={this.state.getTemplateTranslationStatus}
                        sendRequest={this.state.sendRequest}

                    />
                    : ''}

                {this.state.showHistoryChangesTemplate
                    ? <HistoryChangesComponent
                        closeFunction={this.showHistoryChangesTemplate}
                        url={this.state.popUpTemplateManagement.modal_name === SMS_TEMPLATE ? `${SMS_HISTORY_CHANGES}/${this.state.popUpTemplateManagement.template_id}` : this.state.popUpTemplateManagement.modal_name === E_MAIL_TEMPLATE ? `${MAIL_HISTORY_CHANGES}/${this.state.popUpTemplateManagement.template_id}` : ''}
                    />
                    : null}

                {this.state.showHistoryChangesTemplateTranslation
                    ? <HistoryChangesComponent
                        closeFunction={this.showHistoryChangesTemplateTranslation}
                        url={this.state.popUpTemplateManagement.modal_name === SMS_TEMPLATE ? `${SMS_HISTORY_CHANGES_TRANSLATION}/${this.state.popUpTemplateManagement.template_translation_id}` : this.state.popUpTemplateManagement.modal_name === E_MAIL_TEMPLATE ? `${MAIL_HISTORY_CHANGES_TRANSLATION}/${this.state.popUpTemplateManagement.template_translation_id}` : ''}
                    />
                    : null}

                <div className='content-block'>
                    <div className='top-page-block'>
                        <h1 className='page-title'>{translate(`template_management`)}</h1>
                        <Dropdown
                            className={`select-template_management`}
                            isOpen={this.state.dropdownOpen}
                            size='sm'
                            toggle={this.handleDropdownClick}
                        >
                            <DropdownToggle caret>
                                <span className={`select-template_management_icon`}>{translate(`template_management_create`)}</span>
                            </DropdownToggle>
                            <DropdownMenu right>
                                {CREATE_TEMPLATE_MANAGEMENT?.map?.((item, key) => {
                                    return renderDropdownItem({ item, key });
                                })}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>

                <div className='table-top'>
                    <div className='templateManagement_tabs'>
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                    className={`${classnames({ active: activeTab === TABID_0 })} tab_sms`}
                                    onClick={async () => await this.toggle(TABID_0)}
                                >
                                    {translate(`template_management_tab_sms`)}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={`${classnames({ active: activeTab === TABID_1 })} tab_email`}
                                    onClick={async () => await this.toggle(TABID_1)}
                                >
                                    {translate(`template_management_tab_email`)}
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                </div>

                <div className='content-block'>
                    <div className='table-wrapper'>
                        <div className='position-relative'>
                            <TabContent activeTab={activeTab}>
                                {activeTab === TABID_0 ?
                                    <TabPane tabId={TABID_0}>
                                        <MagicFilterPanels
                                            mf={this.mfSMS}
                                            show={true}
                                            translate={translate}
                                        />
                                        <TemplateMagicTable
                                            templateName={TABID_0}
                                            data={this.state.smsTemplates.data}
                                            mf={this.mfSMS}
                                            togglePopUpTemplateManagement={this.togglePopUpTemplateManagement}
                                            togglePopup={this.togglePopup}
                                            deleteTemplate={this.deleteTemplate}
                                            loaded={this.state.loaded}
                                            enums={this.state?.enums?.data?.core?.SmsTemplateActiveEnum}
                                        />
                                        <PagePanel
                                            doText={this.onPageText}
                                            doTo={(pageId) => `?page=${pageId}`}
                                            filter={this.state.pages.filter}
                                            page={this.pageId()}
                                            take={this.state.pages.take}
                                            total={this.state.pages.total}
                                            variance={this.state.pages.variance}
                                            onClick={this.onPageClick}
                                        />
                                    </TabPane>
                                    :
                                    <TabPane tabId={TABID_1}>
                                        <MagicFilterPanels
                                            mf={this.mf}
                                            show={true}
                                            translate={translate}
                                        />
                                        <TemplateMagicTable
                                            templateName={TABID_1}
                                            data={this.state.mailTemplates.data}
                                            mf={this.mf}
                                            togglePopUpTemplateManagement={this.togglePopUpTemplateManagement}
                                            togglePopup={this.togglePopup}
                                            deleteTemplate={this.deleteTemplate}
                                            loaded={this.state.loaded}
                                            enums={this.state?.enums?.data?.core?.MailTemplateActiveEnum}
                                        />
                                        <PagePanel
                                            doText={this.onPageText}
                                            doTo={(pageId) => `?page=${pageId}`}
                                            filter={this.state.pages.filter}
                                            page={this.pageId()}
                                            take={this.state.pages.take}
                                            total={this.state.pages.total}
                                            variance={this.state.pages.variance}
                                            onClick={this.onPageClick}
                                        />
                                    </TabPane>
                                }
                            </TabContent>
                        </div>
                        <SideBarTaskList />
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withLocalize(TemplateManagement));
