import { isEmptyString, } from "../../../../../../utils/helpers";

export const validateEmptyFields = async (
		state,
		save,
		users,
		programsAvailableOptions,
) => {
	const errors = [];
	const {
		approved_program_id,
		ban_forever,
		prohibit_application_for,
		reason,
		status,
		user_id,
	} = state.formData;
	const availableUsers = users;
	const availablePrograms = programsAvailableOptions;

	if (
		status === 1 &&
		ban_forever === 0 &&
		isEmptyString(prohibit_application_for)
	) {
		errors.push("prohibit_application_for", "ban_forever");
	}

	if (!user_id || !availableUsers.some(option => +option.value === +user_id)) {
		errors.push("user_id");
	}
	if (
		!approved_program_id ||
		!availablePrograms.some(option => +option.value === +approved_program_id)
	) {
		errors.push("approved_program_id");
	}

	if (
		status === 1 &&
		(isEmptyString(reason) ||
			reason.trim().length < 3 ||
			reason.trim().length > 1000)
	) {
		errors.push("reason");
	}

	if (status === 0) {
		errors.push("status");
	}

	await save({ errors, });

	return errors.length === 0;
};