/* eslint-disable no-useless-constructor ,react/jsx-curly-spacing ,react/jsx-sort-props ,template-curly-spacing ,sort-keys ,no-return-await */
import React, { Component, } from "react";
import { withLocalize, } from "react-localize-redux";

import { MagicDate, } from "../../../../../../../components/Magic";
import { MagicSwitch, } from "../../../../../../tima/components/Magic/MagicSwitch";
import { MagicTextarea, } from "../../../../../../tima/components/Magic";

import { setErrorClass, } from "../../../../../utils/helpers";

class RejectForm extends Component {
	constructor (props) {
		super(props);
	}

	render () {
		const {
			formData,
			errors,
			isEditMode,
			currentLanguage: locale,
			translate,
			save,
		} = this.props;
		const { prohibit_application_for, ban_forever, reason, } = formData;

		const bannedTo = prohibit_application_for ?? ''; //if back return null field should be empty
		const isBanedForever = +ban_forever;
		const isBanedTill = !!prohibit_application_for;
		const isDisabled = isEditMode ? "" : "disabled";

		return (
			<>
				<div className="view-edit-request__row">
					<div className="input__wrapper">
						<label>
							{translate("partners_request_prohibit_application_for")}
						</label>
						<MagicDate
							disabled={!!(!isEditMode || isBanedForever)}
							emptyLabel={translate(
								"partners_requests_partner_prohibit_application_for_placeholder",
							)}
							format={"YYYY-MM-DD"}
							locale={locale}
							min={new Date(new Date().toISOString().split("T")[0])} //PropTypes yells if not date suplied
							name={"prohibit_application_for"}
							value={new Date(bannedTo)}
							className={`view-edit-request__date view-edit-request__date_active ${setErrorClass(
								errors,
								"prohibit_application_for",
							)} ${isDisabled}`}
							// formats value to required format
							onChange={async value =>
								await save({
									formData: {
										...formData,
										prohibit_application_for: `${
											new Date(value).toISOString().split("T")[0]
										} ${new Date().toTimeString().split(" ")[0]}`,
									},
									errors: errors.filter(
										item =>
											item !== "prohibit_application_for" &&
											item !== "ban_forever",
									),
								})
							}
						/>
					</div>
				</div>
				<div className="view-edit-request__row">
					<div className="input__wrapper">
						<MagicSwitch
							className={`view-edit-request__switch ${
								!isEditMode || isBanedTill ? "disabled" : ""
							} ${setErrorClass(errors, "ban_forever")} ${isDisabled}`}
							index={+ban_forever}
							isDisabled={!isEditMode || isBanedTill}
							name={"ban_forever"}
							text={translate("partners_request_ban_forever")}
							updateStateFromProps={true}
							values={[ 0, 1, ]}
							onChange={async value =>
								await save({
									formData: { ...formData, ban_forever: value.value, },
									errors: errors.filter(
										item =>
											item !== "prohibit_application_for" &&
											item !== "ban_forever",
									),
								})
							}
						/>
					</div>
				</div>
				<div className="view-edit-request__row">
					<div className="input__wrapper">
						<label>{translate("partners_requests_partner_reason")}</label>
						<MagicTextarea
							className={`view-edit-request__textarea ${setErrorClass(
								errors,
								"reason",
							)} ${isDisabled}`}
							isDisabled={!isEditMode}
							name={"reason"}
							text={translate("partners_request_ban_forever")}
							value={reason}
							onChange={async value =>
								await save({
									formData: { ...formData, reason: value.value, },
									errors: errors.filter(item => item !== "reason"),
								})
							}
						/>
					</div>
				</div>
			</>
		);
	}
}

export default withLocalize(RejectForm);