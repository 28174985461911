import { DataService } from "./DataService";
import { hash as hashData, } from './CachingService';
import { get, put } from "../components/Magic/helpers/MagicRequest";
import {
    GET_DISTRIBUTION_SETTING_DATA,
    GET_DISTRIBUTION_USERS,
    GLOBAL_PATH_DISTRIBUTION
} from "../apiRoutes";

class DistributionDataService extends DataService {
    constructor () {
        super([
            'distributionUsers',
            'distributionSetting',
            'updateData'
        ]);
        Object.freeze(this);
    }

    async distributionUsers () {
        const url =`${GET_DISTRIBUTION_USERS}`;
        const data = await get(url);
        const hash = await hashData(data);
        const distributionUsers = { data, hash };
        this.emit('distributionUsers', distributionUsers);

        return distributionUsers;
    }

    async distributionSetting () {
        const url =`${GET_DISTRIBUTION_SETTING_DATA}`;
        const response = await get(url);
        response.data = JSON.parse(response.data);
        const hash = await hashData(response);
        const distributionSetting = { settings: response, hash };
        this.emit('distributionSetting', distributionSetting);

        return distributionSetting;
    }

    async updateData ({ id, data }) {
        const url =`${GLOBAL_PATH_DISTRIBUTION}/${id}`;
        const response = await put(url, { data });
        this.emit('updateData', response);

        return response;
    }

}

export const distributionService = new DistributionDataService();
