import React from 'react';
import Main from './modules/common/components/Frame/Main';
import { withRouter } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import AuthService from './services/AuthService';
import LangService from './services/LangService';
import AuthPage from './modules/common/components/AuthPage/AuthPage';
import PermissionService from "./services/PermissionService";
import NotificationComponent from "./modules/common/components/Notification";
import Preloader from "./components/LoadingHOC/Preloader";
import { profileService } from "./services/ProfileDataService";

//styles
import './style/lib_css/line-awesome.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './style/app.scss';

class App extends React.Component {
    state = {
        isShowContent: false,
        changeProfile: false,
    };

    save = async (state) => new Promise(next => this.setState(state, next));

    profileChange = async (profile) => {
        if (profile.hash===this.state?.profile?.hash) return `${this.constructor.name}.profileChange: false`;
        await this.save({ profile, });
        return `${this.constructor.name}.profileChange: true`;
    };

    async componentDidMount () {
        profileService.subscribe('profile', this.profileChange, this);
        LangService.setActiveLanguage();
        PermissionService.setHashToStore();
        await this.checkAuthorization();
    }

    componentWillUnmount () {
        profileService.unsubscribe('profile', this.profileChange, this);
    }

    checkAuthorization = async () => {
        if (AuthService.getSessionToken()) {
            this.setShowContent();
            await profileService.profile;
            await AuthService.refreshSessionDataFromServer(this.state?.profile?.data ?? {});
        } else {
            this.unsetShowContent();
            window.history.pushState({ urlPath: '/' }, "", '/');
        }
    };

    renderLoginForm = () => {
        return <div><NotificationComponent /><AuthPage checkAuthorization={ this.checkAuthorization } /></div>;
    };

    renderComponent = () => {
        if (!this.state?.profile?.data?.id) {
            return <Preloader scale={1} />;
        }

        return (<React.Fragment>
            <NotificationComponent />
            <Main
                logout = { this.unsetShowContent }>
                {this.props.children}
            </Main>
        </React.Fragment>
        );
    };

    setShowContent = async () => this.save({ isShowContent: true });

    unsetShowContent = async () => this.save({ isShowContent: false });

    render () {
        return this.state.isShowContent ? this.renderComponent() : this.renderLoginForm();
    }
}

export default withLocalize(withRouter(App));
