import {shape, string, number, array, oneOfType, } from 'prop-types';

const LEAD_PROP_TYPES =  shape({
    core: shape({
        account: shape({
            addresses: string,
            birth_date: string,
            city_id: number,
            country_id: number,
            created_at: string,
            description: oneOfType([ number, string, ]),
            email: string,
            enn: string,
            gender: oneOfType([ number, string ]),
            id: number,
            is_brand_lawyer: oneOfType([ number, string ]),
            is_email_verify: oneOfType([ number, string ]),
            is_have_demo: oneOfType([ number, string ]),
            is_phone_verify: oneOfType([ number, string ]),
            is_verify_document: oneOfType([ number, string ]),
            language_id: number,
            library_card: string,
            lk_old_id: string,
            lk_old_user_id: string,
            main_account_id: number,
            manager_id: number,
            name: string,
            partner_id: number,
            password: string,
            patronymic: string,
            phone: string,
            processed: string,
            register_type: oneOfType([ number, string ]),
            remember_token: number,
            status: oneOfType([ number, string ]),
            surname: string,
            system_type: string,
            time_zone_id: number,
            token: number,
            trader_diary: oneOfType([ number, string ]),
            updated_at: string,
            warm_type: oneOfType([ number, string ]),
        }),
        account_categories: shape({
            account_id: number,
            active: string,
            category_id: oneOfType([ number, string ]),
            created_at: string,
            id: number,
            is_new: string,
            is_warming: string,
            updated_at: string,
            user_id: number,
        }),
        account_emails: shape({
            array: array,
        }),
        account_phones: shape({
            array: array,
        }),
        account_status: shape({
            account_id: number,
            active: string,
            created_at: string,
            id: number,
            status_id: string,
            updated_at: string,
            user_id: number,
        }),
        account_tails: array,
        account_types: shape({
            account_id: number,
            active: string,
            created_at: string,
            id: number,
            type_id: oneOfType( [ number, string, ] ),
            updated_at: string,
            user_id: number,
        }),
        city: shape({
            country_id: number,
            id: number,
            name_en: string,
            name_ru: string,
            name_uk: string,
        }),
        country: shape({
            code: string,
            id: number,
            name_en: string,
            name_ru: string,
            name_uk: string,
        }),
        language: shape({
            code: string,
            id: number,
            name_en: string,
            name_ru: string,
            name_uk: string,
        }),
        time_zone: shape({
            code: string,
            id: number,
            info: array,
            name: string,
        }),
        user: shape({
            adaptation: string,
            birth_date: string,
            created_at: string,
            email: string,
            id: number,
            name: string,
            phone: string,
            preference_lang_id: number,
            remember_token: string,
            role_id: number,
            status: string,
            surname: string,
            throughput: number,
            updated_at: string,
        }),

    }),
});

export {LEAD_PROP_TYPES};

