import React from "react";

import {isUndefined, map} from "lodash";
import { securedRequest } from "../../../services/RequestService";
import {getTaskStates, getTaskTypes, getTaskUrgency} from "./TaskRequestService";
import {GET_AVATAR} from '../apiRoutes';

export const getAvatar = ($this, id, avatars) => {
    const mimetype = "image/jpeg";

    if (!isUndefined(avatars[id])) {
        $this.setState({
            images: { ...$this.state.images, 'imageId': `data:${mimetype};base64,${avatars[id]}` },
        });
    }
};

export const getAvatarComment = ($this, id) => {
    const url = `${GET_AVATAR}/${id}`;

    let newUrl = null;

    securedRequest.get(url, {
        responseType: 'arraybuffer',
    }).then((response) => {
        newUrl = _imageEncode(response.data);
        $this.setState({
            images: { ...$this.state.images, 'imageId': newUrl },
        });
    });
};

export const _imageEncode = (arrayBuffer) => {
    const u8 = new Uint8Array(arrayBuffer);
    const b64encoded = btoa([].reduce.call(new Uint8Array(arrayBuffer), (p, c) => {
        return p+String.fromCharCode(c);
    }, ''));
    const mimetype="image/jpeg";

    return `data:${mimetype};base64,${b64encoded}`;
};

export const getImportant = ($this) => {
    return (translate) => {
        getTaskUrgency().then((response) => {

            const newObjs = [];

            map(response.data, (value) => {
                newObjs.push({ value: value.tasks.task_urgency.id, label: value.tasks.task_urgency[`name_${$this.lang}`] });
            });
            $this.setState({
                urgency_: newObjs,
            }, () => {
                $this.setState({
                    createTaskSelect: $this.props.createTaskSelect,
                });
            });
        });
    };
};

export const getStatus = ($this) => {
    return (translate) => {
        getTaskStates().then((response) => {

            const newObjs = [];

            map(response.data, (value) => {
                newObjs.push({ value: value.tasks.task_states.id, label: value.tasks.task_states[`name_${$this.lang}`] });
            });
            $this.setState({
                state_: newObjs,
            }, () => {
                $this.setState({
                    createTaskSelect: $this.props.createTaskSelect,
                });
            });
        });
    };
};

export const getType = ($this) => {
    return (translate) => {
        getTaskTypes().then((response) => {

            const newObjs = [];

            map(response.data, (value) => {
                newObjs.push({ value: value.tasks.task_types.id, label: value.tasks.task_types[`name_${$this.lang}`] });
            });
            $this.setState({
                type_: newObjs,
            }, () => {
                $this.setState({
                    createTaskSelect: $this.props.createTaskSelect,
                });
            });
        });
    };
};

export const getIcon = (attachment_icon) => {
    const tableHeads = [];

    switch (attachment_icon) {

        case 'jpg': {
            tableHeads.push(<i className = 'attachment_icon_task fi flaticon-jpg-file-format-variant' />);
            break;
        }
        case 'jpeg': {
            tableHeads.push(<i className = 'attachment_icon_task fi flaticon-jpg-file-format-variant' />);
            break;
        }
        case 'png': {
            tableHeads.push(<i className = 'attachment_icon_task fi flaticon-png-file-format-symbol' />);
            break;
        }
        case 'gif': {
            tableHeads.push(<i className = 'attachment_icon_task fi flaticon-gif-file-format-symbol' />);
            break;
        }
        case 'pdf': {
            tableHeads.push(<i className = 'attachment_icon_task fi flaticon-pdf-file-format-symbol' />);
            break;
        }
        case 'doc': {
            tableHeads.push(<i className = 'attachment_icon_task fi flaticon-doc-file-format' />);
            break;
        }
        case 'docx': {
            tableHeads.push(<i className = 'attachment_icon_task fi flaticon-docx-file-format-symbol' />);
            break;
        }
        case 'xls': {
            tableHeads.push(<i className = 'attachment_icon_task fi flaticon-xls-file-format-symbol' />);
            break;
        }
        case 'xlsx': {
            tableHeads.push(<i className = 'attachment_icon_task fi flaticon-xlsx-file-format-extension' />);
            break;
        }
        case 'txt': {
            tableHeads.push(<i className = 'attachment_icon_task fi flaticon-txt-file-symbol' />);
            break;
        }
        case 'zip': {
            tableHeads.push(<i className = 'attachment_icon_task fi flaticon-zip-file-format' />);
            break;
        }
        case '7z': {
            tableHeads.push(<i className = 'attachment_icon_task fi flaticon-7z-file-format-variant' />);
            break;
        }
        case 'rar': {
            tableHeads.push(<i className = 'attachment_icon_task fi flaticon-rar-file-format' />);
            break;
        }
        default: {
            break;
        }
    }

    return tableHeads;
};

export const viewAttachment = ($this) => {
    const attachment_icon = $this.props.attachment['tasks-task_documents-extension'];
    const tableHeads = getIcon(attachment_icon);

    return tableHeads;
};
