import React, { Component, } from "react";
import { Link, withRouter, } from "react-router-dom";
import { withLocalize, } from "react-localize-redux";

import {
	MagicTable, Mentor, MagicFilterPanels, MagicButton,
} from "../../../../components/Magic";
import PagePanel from "../../../tima/components/Pages";
import Preloader from "../../../../components/LoadingHOC/Preloader";

import { enumService, } from "../../../../services/EnumDataService";
import { showExportToExcelError, } from "../../../../components/Magic";
import { getClientsWithBonuses } from "../../../../services/BonusesRequestService";

class ClientsWithBonusesListTable extends Component {
	constructor (props) {
		super(props);

		this.state = {
			data: [],
			languages: null,
			filterLastChange: Date.now(),
			isEmptyData: false,
			loaded: false,
			meta: {},
			pages: {
				filter: 0,
				take: 50,
				total: 0,
				variance: 2,
			},
		};

		const mf = new Mentor({
			owner: this,
			serviceId: 165,
			translate: this.props.translate,
		});

		Object.defineProperty(this, "mf", { get: () => mf, });
	}

	async componentDidMount () {
		enumService.subscribe('enums', this.enumsChange, this);
		await enumService.enums;

		this.mf.subscribe(
			{
				changed: this.mfChanged,
				cleaned: this.mfDone,
				cleaning: this.mfDoing,
				loaded: this.mfDone,
				loading: this.mfDoing,
				saved: this.mfDone,
				saving: this.mfDoing,
			},
			this,
		);

		await this.mf.init({ doEmit: true, });
	}

		componentWillUnmount () {
			enumService.unsubscribe('enums', this.enumsChange, this);
		}
    save = state => new Promise(next => this.setState(state, next));

    onChange = (name, value) => this.save({ [name]: value, });

    params = () => this.props.match.params;

    mfChanged = async () => {
    	const { handleFilterIdChange, } = this.props;
    	const { filterId, } = this.mf;

    	await handleFilterIdChange(filterId);
    	await this.save({ filterLastChange: Date.now(), });
    };

    mfDoing = async () => {
    	await this.save({ loaded: false, });
    };

    mfDone = async () => {
    	await this.mfChanged();
    	await this.onDataLoad();
    };

    enumsChange = async (enums) => {
    	if (enums.hash === this.state?.enums?.hash) {
    		return `${ this.constructor.name }.enumsChange: false`;
    	}
    	await this.save({ enums, });

    	return `${ this.constructor.name }.enumsChange: true`;
    };
    query = () => new URLSearchParams(this.props.location.search);

    onDataLoad = async (pageId) => {
    	await this.save({ isEmptyData: false, loaded: false, });

    	const pageOptions = this.pageIdAsGET(pageId);
    	const loaded = (data, meta) => ({ pages, }) => ({
    		data,
    		pages: { ...pages, ...meta, },
    		...!data.length && { isEmptyData: true, },
    	});

    	try {
    		const response = await getClientsWithBonuses(pageOptions);
    		const {
    			data = [],
    			meta: { filter = 0, total = 0, },
    		} = response;

    		await this.save(loaded(data, { filter, total, }));
    	} catch (error) {
    		await this.save(loaded([], { filter: 0, total: 0, }));
    		showExportToExcelError(error);
    	}
    	await this.save({ loaded: true, });
    };
    get config () {
    	const { translate, } = this.props;
    	const prefix = "bonuses_";

    	return [
    		{
    			key: "bonuses_bonus_id",
    			orderId: "o:bonusId",
    			path: [ "core", "bonus_program_to_account", "id", ],
    			render: this.renderInlineValue,
    			title: `${ prefix }bonus_id`,
    		},
				    		{
    			key: "bonuses_client",
    			orderId: "o:client",
    			path: [ "core", "bonus_program_to_account", "account_id", ],
    			render: this.renderInlineValue,
    			title: `${ prefix }client`,
    		},
				    		{
    			key: "bonuses_mt_login",
    			orderId: "o:mtLogin",
    			path: [ "core", "trading_accounts", "mt_login", ],
    			render: this.renderInlineValue,
    			title: `${ prefix }mt_login`,
    		},
				    		{
    			key: "bonuses_platform",
    			orderId: "o:platform",
    			path: [ "core", "trading_accounts", "platform", ],
    			render: this.renderInlineValue,
    			title: `${ prefix }platform`,
    		},
				    		{
    			key: "bonuses_amount_of_funds_at_bonus_credited",
    			orderId: "o:amountOfFundsAtBonusCredited",
    			path: [ "core", "bonus_program_to_account", "sum_funds_owner", ],
    			render: this.renderInlineValue,
    			title: `${ prefix }amount_of_funds_at_bonus_credited`,
    		},
				    		{
    			key: "bonuses_amount_of_funds_at_bonus_calculated",
    			orderId: "o:amountOfFundsAtBonusCalculated",
    			path: [ "core", "bonus_program_to_account", "sum_funds_calc", ],
    			render: this.renderInlineValue,
    			title: `${ prefix }amount_of_funds_at_bonus_calculated`,
    		},
				    		{
    			key: "bonuses_program_currency",
    			orderId: "o:program_currency",
    			path: [ "core", "bonus_program_to_account", "currency", ], //?? TODO!
    			render: this.renderInlineValue,
    			title: `${ prefix }program_currency`,
    		},
				    		{
    			key: "bonuses_trading_account_currency",
    			orderId: "o:tradingAccountCurrency",
    			path: [ "core", "promo", "id", ], //?? TODO!
    			render: this.renderInlineValue,
    			title: `${ prefix }trading_account_currency`,
    		},
				    		{
    			key: "bonuses_points_to_unlock_bonus",
    			orderId: "o:pointsToUnlockBonus",
    			path: [ "core", "bonus_program_to_account", "condition_count_bal", ],
    			render: this.renderInlineValue,
    			title: `${ prefix }points_to_unlock_bonus`,
    		},
				    		{
    			key: "bonuses_current_number_of_points",
    			orderId: "o:current_number_of_points",
    			path: [ "core", "bonus_program_to_account", "current_count_bal", ],
    			render: this.renderInlineValue,
    			title: `${ prefix }current_number_of_points`,
    		},
				    		{
    			key: "bonuses_bonus_status",
    			orderId: "o:bonus_status",
    			path: [ "core", "bonus_program_to_account", "status", ],
    			render: this.renderInlineValue,
    			title: `${ prefix }bonus_status`,
    		},
				    		{
    			key: "bonuses_deadline_to_earn_points",
    			orderId: "o:deadlineToEarnPoints",
    			path: [ "core", "bonus_program_to_account", "date_deadline_end", ],
    			render: this.renderInlineValue,
    			title: `${ prefix }deadline_to_earn_points`,
    		},
				    		{
    			key: "bonuses_bonus_credit_date",
    			orderId: "o:bonusCreditDate",
    			path: [ "core", "bonus_program_to_account", "date_enrollment", ], //? does the field correct?
    			render: this.renderInlineValue,
    			title: `${ prefix }bonus_credit_date`,
    		},
				    		{
    			key: "bonuses_bonus_payment_date",
    			orderId: "o:bonusPaymentDate",
    			path: [ "core", "bonus_program_to_account", "date_expired", ],  //? does the field correct?
    			render: this.renderInlineValue,
    			title: `${ prefix }bonus_payment_date`,
    		},

    		// {
    		// 	customAccessCheck: ({ item, }) => {
    		// 		return (
    		// 			item.access("show") &&
        //                 (item.access("update") || item.access("destroy"))
    		// 		);
    		// 	},
    		// 	key: "bannerActions",
    		// 	order: false,
    		// 	path: [ "core", "promo", "id", ],
    		// 	render: this.renderActions,
    		// 	title: `${ prefix }action`,
    		// },
    	].map(({
    		orderId: o, title: t, xtitle: x, ...item
    	}) => ({
    		...item,
    		...o && {
    			order: async () => {
    				await this.mf.orderedToNext(o);
    			},
    			orderTo: this.mf.orderedTo(o),
    		},
    		...t && { title: translate(t), },
    		...x && { title: x, },
    	}));
    }

    renderInlineValue = (value, { item, items, }) => {
    	if (item.access("index")) {
    		return value ?? "-";
    	}

    	return "-";
    };

    renderName= (value, { item, items, }) => {
    	if (item.access("index")) {
    		return (
	          <div
              className={ 'cursor-pointer' }
              onClick={ async () => {
    					const { toggleBannerPopup, } = this.props;
    					const id = items.bannerId.valueOf;
    					const popupData = await this.getBannerFormData(id);

    					popupData.source = `${ popupData.source }?${ this.createRandomQueryString() }`;
    					popupData.isViewMode = true;

    					await toggleBannerPopup(true, popupData);
    				} }
    			  >
	          {value.trim() || "-"}
    			</div>
    		);
    	}

    	return "-";
    };

    renderDate = (value, { item, items, }) => {
    	if (item.access("index")) {
            //date and time on separate lines
    		return value.split(' ').map((date, index) => <div key={ `date-${ items.bannerId.valueOf }-${ index }` }>{date}</div>);
    	}

    	return "-";
    };

    renderStatus = (value, { item, }) => {
    	if (item.access("index")) {
    		const { enums, } = this.state;

    		return enums.data.partners.PartnerPromoStatusEnum[value];
    	}

    	return "-";
    };

  //   renderActions = (value, { item, items, }) => {
  //   	const { translate, toggleBannerPopup, } = this.props;
	//
  //   	const renderViewButton = () => {
  //   		const options = {
  //   			children: translate("partners_requests_partner_view"),
  //   			className:
  //                   "magic-button__item magic-button__item_partners-edit-banner margin--top-10",
  //   			key: `view-${ items.bannerId.valueOf }`,
  //   			onClick: async () => {
  //   				const popupData = await this.getBannerFormData(value);
	//
  //   				popupData.isViewMode = true;
  //   				popupData.source = `${ popupData.source }?${ this.createRandomQueryString() }`;
	//
  //   				await toggleBannerPopup(true, popupData);
  //   			},
  //   		};
	//
  //   		return (
	// <div className="mb-2" >
	// <MagicButton { ...options } />
  //   			</div>
  //   		);
  //   	};

  //   	const renderEditButton = () => {
  //   		const options = {
  //   			children: translate("symbols_actions_edit"),
  //   			className: "magic-button__item magic-button__item_partners-view-banner",
  //   			key: `edit-${ items.bannerId.valueOf }`,
  //   			onClick: async () => {
  //   				const popupData = await this.getBannerFormData(value);
	//
  //   				popupData.source = `${ popupData.source }?${ this.createRandomQueryString() }`;
	//
  //   				await toggleBannerPopup(true, popupData);
  //   			},
  //   		};
	//
  //   		return (
	// <div>
	// <MagicButton { ...options } />
  //   			</div>
  //   		);
  //   	};
	//
  //   	return (
	// <>
	// {item.access("destroy") && renderViewButton()}
	// {item.access("update") && renderEditButton()}
  //   		</>
  //   	);
  //   };

    renderClientWithBonusesListTable () {
    	const { translate, } = this.props;
    	const { loaded, data, } = this.state;

    	const head = [
    		"bonuses_bonus_id",
				"bonuses_client",
				"bonuses_mt_login",
				"bonuses_platform",
				"bonuses_amount_of_funds_at_bonus_credited",
				"bonuses_amount_of_funds_at_bonus_calculated",
				"bonuses_program_currency",
				"bonuses_trading_account_currency",
				"bonuses_points_to_unlock_bonus",
				"bonuses_current_number_of_points",
				"bonuses_bonus_status",
				"bonuses_deadline_to_earn_points",
				"bonuses_bonus_credit_date",
				"bonuses_bonus_payment_date",
    	];

    	if (!loaded) {
    		return <Preloader />;
    	}
    	if (data.length === 0) {
    		return <h4 className="mx-auto text-align-center pt-5">{translate("symbols_empty")}</h4>;
    	}

    	const options = {
    		config: this.config,
    		data,
    		head,
    	};

    	return (
				<div className={"partners__table"}>
					<MagicTable { ...options } />
    		</div>
    	);
    }

    render () {
    	const { pages, enums, } = this.state;
    	const {
    		translate, getPermissions,
    	} = this.props;

    	const showPagePannel = pages.total > pages.take;

    	const permissionToShow = getPermissions('index');

    	return (
            //eslint-disable-next-line
				<>
					{ permissionToShow &&
					<div>
							<MagicFilterPanels
								mf={ this.mf }
								show={ true }
								translate={ translate }
							/>
							{this.renderClientWithBonusesListTable()}
							{ showPagePannel && <PagePanel
									doTo={ pageId => `?page=${ pageId }` }
									filter={ pages.filter }
									page={ this.pageId() }
									take={ pages.take }
									total={ pages.total }
									variance={ pages.variance }
									onClick={ this.onPageClick }
							/>
							}
						</div>
					}
    			</>
    	);
    }

    pageId = () => {
    	const page = this.query().get("page");

    	return Number(page) || 0;
    };

    pageIdAsGET = (pageId) => {
    	const pages = this.state.pages;

        // eslint-disable-next-line no-param-reassign
    	pageId = pageId === undefined ? this.pageId() : pageId;
    	const { filterId, } = this.mf;
    	const result = {
    		filterId,
    		skip: pageId * pages.take,
    		take: pages.take,
    	};

    	return result;
    };
    // eslint-disable-next-line no-unused-vars
    onPageClick = async ({ event, pageId, pageIs, }) => {
    	await this.onDataLoad(pageId);
    };
}

export default withRouter(withLocalize(ClientsWithBonusesListTable));