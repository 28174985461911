export const bonusesLang = {
	"menu_bonuses": [ "Bonuses", "Бонусы", "Бонуси", ],
	"menu_bonuses_client_list": [ "Bonuses", "Бонусы", "Бонуси", ],
	"bonuses_bonus_id": [ "bonus id", "id бонуса", "id бонуса", ],
	"bonuses_client": [ "Client", "Клиент", "Клієнт", ],
	"bonuses_mt_login": [ "МТ Login", "МТ Login", "МТ Login", ],
	"bonuses_platform": [ "Platform", "Платформа", "Платформа", ],
	"bonuses_amount_of_funds_at_bonus_credited": [ "The amount of own funds at the time the bonus is credited", "Сумма собственных средств на момент зачисления бонуса", "Сума власних коштів на момент зарахування бонусу", ],
	"bonuses_amount_of_funds_at_bonus_calculated": [ "The amount of funds for which the bonus was calculated", "Сумма средств, на которые рассчитали бонус", "Сума коштів, на які розрахували бонус", ],
	"bonuses_program_currency": [ "Bonus amount in bonus program currency", "Сумма бонуса в валюте бонусной программы", "Сума бонусу у валюті бонусної програми", ],
	"bonuses_trading_account_currency": [ "Bonus amount in trading account currency", "Сумма бонуса в валюте торгового счета", "Сума бонусу у валюті торгового рахунку", ],
	"bonuses_points_to_unlock_bonus": [ "Number of points to unlock the bonus", "Количество баллов для разблокировки бонуса", "Количество баллов для разблокировки бонуса", ],
	"bonuses_current_number_of_points": [ "Current number of points", "Количество баллов на текущий момент", "Кількість балів на даний момент", ],
	"bonuses_bonus_status": [ "Bonus status", "Статус бонуса", "Статус бонусу", ],
	"bonuses_deadline_to_earn_points": [ "Deadline to earn points", "Дедлайн, чтобы накопить баллы", "Дедлайн, щоб накопичити бали", ],
	"bonuses_bonus_credit_date": [ "Bonus credit date", "Дата зачисления бонуса", "Дата зарахування бонусу", ],
	"bonuses_bonus_payment_date": [ "Bonus payment date", "Дата выплаты бонуса", "Дата виплати бонусу", ],
	"bonuses_date_of_bonus_cancel": [ "Date when the bonus was canceled", "Дата когда бонус анулировался", "Дата коли бонус анулювався", ],
};