import React from 'react';

import PermissionService, { MODULE_KEYS as pm } from "../../services/PermissionService";

import Tasks from "./components/Tasks";

export const tasksConfig = [
    { permission: pm.TASKS_TASKS, action: PermissionService.indexRoute(), component: <Tasks />, path: '/tasks' },
    { permission: pm.TASKS_TASKS, action: PermissionService.showRoute(), component: <Tasks />, path: '/task/:id' }
];
