import React from 'react';
import { withLocalize } from 'react-localize-redux';

class ManagerDropdown extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            isListDisplayed: false,
        };
    }

    componentDidMount () {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount () {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (e) => {
        if(!e.path) return //prevents errors in console
        const isInside = e.path.includes(document.querySelector('.dropdown-checkbox'));

        if (!isInside && this.state.isListDisplayed) {
            this.toogleDropdown();
        }
    }

    toogleDropdown () {
        this.setState({ isListDisplayed: !this.state.isListDisplayed });
    }

    handleSelect (item) {
        this.props.handleSelect(item);
        this.toogleDropdown();
    }

    renderList () {
        return (
            <div className = 'list scroll'>
                {this.props.data.map((item) => {
                    return (
                        <div className = "item" key = { item.id } onClick = {() => this.handleSelect(item) }> 
                            <div className = 'department-name' style={{cursor: 'pointer'}}>
                                <p>{item.name ?? '-'} {item.surname ?? '-'}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    render () {
        const { translate, selected } = this.props;
        const { isListDisplayed } = this.state;

        return (
            <div className = 'calendar-filters'>
                <div className = 'magic-filter-fixed dropdown-checkbox'>
                    <div
                        className = 'selected'
                        style = { selected ? {} : { opacity: 0.4, fontStyle: 'italic' } }
                        onClick = { () => this.toogleDropdown() }>
                        {selected ? `${selected.name} ${selected.surname}` : translate('magic_filter_translate_payment_manager')}
                    </div>
                    {isListDisplayed ? this.renderList() : null}
                </div>
            </div>
        );
    }
}

export default withLocalize(ManagerDropdown);
