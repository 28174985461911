import React from "react";
import { withLocalize } from 'react-localize-redux';
import Preloader from '../../../components/LoadingHOC';
import DistributionTable from './components/distributionUsersTable';
import DistributionInputs from './components/distributionSettingsInputs';
import { distributionService } from '../../../services/DistributionDataService';

class DistributionSettings extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            usersData: {
                data: [],
                hash: null,
            },
            settingsData: {
                settings: null,
                hash:     null,
            },
            pending:      false,
            pendingUsers: false,
        };
    }

    usersDataChanges = async (usersData) => {
        if (usersData.hash===this.state?.usersData?.hash) return `${this.constructor.name}.usersDataChanges: false`;
        await this.save({ usersData });
        return `${this.constructor.name}.usersDataChanges: true`;
    };

    settingsDataChanges = async (settingsData) => {
        if (settingsData.hash===this.state?.settingsData?.hash) return `${this.constructor.name}.settingsDataChanges: false`;
        await this.save({ settingsData });
        return `${this.constructor.name}.settingsDataChanges: true`;
    };

    async componentDidMount () {
        distributionService.subscribe('distributionUsers', this.usersDataChanges, this);
        distributionService.subscribe('distributionSetting', this.settingsDataChanges, this);
        await this.getUsersData();
        await this.getSettingsData();
    }

    async componentWillUnmount () {
        distributionService.unsubscribe('distributionUsers', this.usersDataChanges, this);
        distributionService.unsubscribe('distributionSetting', this.settingsDataChanges, this);
    }

    save = async (state) => new Promise((next) => this.setState(state, next));

    async getUsersData () {
        try {
            await this.save({ pendingUsers: true });
            const usersData = await distributionService.distributionUsers();

            await this.save({ usersData, pendingUsers: false });
        } catch (error) {
            error?.showErrorNotification?.();
        }
    }

    async getSettingsData () {
        try {
            await this.save({ pending: true });
            const settingsData = await distributionService.distributionSetting();
            await this.save({ settingsData, pending: false });
        } catch (error) {
            await this.save({
                settingsData: {
                    settings: null,
                    hash:     null
                },
                pending: false });
            error?.showErrorNotification?.();
        }
    }

    handleSettingsChange = (name) => async (e) => {
        await this.save({ settingsData: {
            ...this.state.settingsData,
            settings: {
                ...this.state.settingsData.settings,
                data: {
                    ...this.state.settingsData.settings.data,
                    [name]: e.target.value,
                },
            },
        }});
    };

    handleUpdate = async () => {
        try {
            if (!this.state.pending) {
                await this.save({ pending: true });
                await distributionService.updateData(this.state.settingsData.settings);
                await this.save({ pending: false });

                await this.getUsersData();
            }
        } catch (error) {
            error?.showErrorNotification?.();
            await this.save({ pending: false });
        }
    };

    renderTable = () => {
        if (this.state.pendingUsers) {
            return <Preloader scale = { 1 } />;
        }

        return this.state.usersData.data?.length > 0 && <DistributionTable data = { this.state.usersData.data } />;
    }

    render () {
        const { translate } = this.props;

        return (
            <div>
                <div className = 'content-block'>
                    <div className = 'top-page-block'>
                        <h1 className = 'page-title'>{translate(`distribution_settings`)}</h1>
                    </div>
                </div>
                <div className = 'content-block'>
                    {this.state.pending && <Preloader scale = { 1 } />}
                    {this.state?.settingsData.settings && !this.state.pending && (
                        <DistributionInputs
                            data = { this.state.settingsData.settings.data }
                            handleChange = { this.handleSettingsChange }
                            handleSubmit = { this.handleUpdate }
                        />
                    )}
                </div>
                <div className = 'content-block'>
                    <div className = 'table-wrapper'>
                        {this.renderTable()}
                    </div>
                </div>
            </div>
        );
    }
}

export default withLocalize(DistributionSettings);
