import React from 'react';
import { withRouter, Link, } from 'react-router-dom';
import Moment from 'moment';
import PagePanel from '../../../../tima/components/Pages';
import Preloader from '../../../../../components/LoadingHOC/Preloader';
import Loader from '../../../../tima/components/Loader';
import { getAccountTotalBalance, } from '../../../services/AccountService';
import { getAccountTotalEquity, } from '../../../services/AccountService';
import { MagicTable, } from '../../../../../components/Magic';
import { listAccountService, } from "../../../../../services/ListAccountDataService";
import LeadStatementPopUp from '../LeadStatementPopUp';

class LeadDeposits extends React.Component {
    state = {
    	loaded: false,
    	affiliate_reward: {},
    	balance: {},
    	client_result: {},
    	credit: {},
    	isOpenedStatement: false,
    	equity: {},
    	promotions_and_adjustments: {},
    	data: [],
    	pages: {
    		filter: 0,
    		total: 0,
    		take: 50,
    		variance: 2,
    	},
    };

    get accountId () {
    	return +this.props?.match?.params?.id;
    }

    get accountTab () {
    	return this.props?.match?.params?.accountTab || "systems";
    }

    get enums () {
    	return this.props.dataAfterLoading;
    }

    get query () {
    	return new URLSearchParams(this?.props?.location?.search);
    }

    get systemsConfig () {
    	const { translate, } = this.props;

    	return [
    		{ // ID счета
    			path: [ 'core', 'system_accounts', 'id', ],
    			key: 'systemId',
    			render: this.renderId(this.renderSystemLink),
    			translate: 'list_accounts_table_system_id',
    		},
    		{ // Дата создания
    			path: [ 'core', 'system_accounts', 'created_at', ],
    			key: 'systemCreated',
    			render: this.renderDate(),
    			translate: 'list_accounts_table_system_created_at',
    		},
    		{ // Дата изменения
    			path: [ 'core', 'system_accounts', 'updated_at', ],
    			key: 'systemUpdated',
    			render: this.renderDate(),
    			translate: 'list_accounts_table_system_updated',
    		},
    		{ // Тип счета
    			path: [ 'core', 'system_accounts', 'type', ],
    			key: 'systemType',
    			render: this.renderEnum('core', 'SystemAccountTypeEnum'),
    			translate: 'list_accounts_table_system_type',
    		},
    		{ // Валюта
    			path: [ 'core', 'system_accounts', 'acc_ccy', ],
    			key: 'systemCurrency',
    			render: this.renderEnum('core', 'AccountCurrencyEnum'),
    			translate: 'list_accounts_table_system_acc_ccy',
    		},
    		{ // Баланс
    			path: [ 'core', 'system_accounts', 'value', ],
    			key: 'systemBalance',
    			render: this.renderText(),
    			translate: 'list_accounts_table_system_mt_balance',
    		},
    		{ // Статус
    			path: [ 'core', 'system_accounts', 'status', ],
    			key: 'systemStatus',
    			render: this.renderEnum('core', 'SystemAccountStatusEnum'),
    			translate: 'list_accounts_table_system_status',
    		},
    		{ // Выписка
    			path: [ 'core', 'system_accounts', ],
    			key: 'systemStatement',
    			render: this.renderStatement(),
    			translate: 'list_accounts_table_system_statement',
    		},
    	].map(({ translate: t, ...data }) => ({ ...data, ...t && { title: translate(t), }, }));
    }

    get tradingsConfig () {
    	const { translate, } = this.props;

    	return [
            // {
            //     path: ['core', 'trading_accounts', 'id'],
            //     key: 'tradingId',
            //     render: this.renderId(this.renderSystemLink),
            //     translate: 'list_accounts_table_system_id',
            // },
    		{ // Номер MT счета
    			path: [ 'core', 'trading_accounts', 'mt_login', ],
    			key: 'mtId',
    			render: this.renderId(this.renderTradingLink),
    			translate: 'list_accounts_table_mt_login',
    		},
    		{
    			path: [ 'core', 'trading_accounts', 'id', ],
    			key: 'tradingAccountId',
    		},
    		{ // Дата создания
    			path: [ 'core', 'trading_accounts', 'created_at', ],
    			key: 'tradingCreated',
    			render: this.renderDate(),
    			translate: 'list_accounts_table_created_at',
    		},
    		{ // Дата последнего изменения
    			path: [ 'core', 'trading_accounts', 'updated_at', ],
    			key: 'tradingUpdated',
    			render: this.renderDate(),
    			translate: 'list_accounts_table_updated_at',
    		},
    		{ // Тип счета
    			path: [ 'core', 'trading_accounts', 'type', ],
    			key: 'tradingType',
    			render: this.renderEnum('core', 'TradingAccountTypeEnum'),
    			translate: 'list_accounts_table_type',
    		},
    		{ // Тип торгового счета
    			path: [ 'core', 'trading_accounts', 'acc_level', ],
    			key: 'tradingLevel',
    			render: this.renderEnum('core', 'TradingAccountLevelEnum'),
    			translate: 'list_accounts_table_acc_level',
    		},
    		{ // Валюта
    			path: [ 'core', 'trading_accounts', 'acc_ccy', ],
    			key: 'tradingCurrency',
    			render: this.renderEnum('core', 'AccountCurrencyEnum'),
    			translate: 'list_accounts_table_acc_ccy',
    		},
    		{ // Баланс
    			accessChecked: true,
    			path: [ 'mt', 'user', 'mt_balance', ],
    			key: 'tradingBalance',
    			render: this.renderText(),
    			translate: 'list_accounts_table_mt_balance',
    		},
    		{ // Средства
    			accessChecked: true,
    			path: [ 'mt', 'user', 'mt_equity', ],
    			key: 'tradingEquity',
    			render: this.renderText(),
    			translate: 'list_accounts_table_mt_equity',
    		},
    		{ // Статус
    			path: [ 'core', 'trading_accounts', 'status', ],
    			key: 'tradingStatus',
    			render: this.renderEnum('core', 'TradingAccountStatusEnum'),
    			translate: 'list_accounts_table_status',
    		},
    		{ // Плечо
    			path: [ 'core', 'trading_accounts', 'leverage', ],
    			key: 'tradingLeverage',
    			render: this.renderEnum('core', 'TradingAccountLeverageEnum'),
    			translate: 'list_accounts_table_leverage',
    		},
    		{ // Риск менеджер
    			path: [ 'core', 'trading_accounts', 'risk_management', ],
    			key: 'tradingRiskManager',
    			render: this.renderEnum('core', 'TradingAccountRiskManagerStateEnum'),
    			translate: 'list_accounts_table_risk_management',
    		},
    		{ // MT группа
    			path: [ 'core', 'trading_accounts', 'mt_group', ],
    			key: 'tradingMtGroup',
    			render: this.renderText(),
    			translate: 'list_accounts_table_mt_group',
    		},
    		{ // Сервер
    			path: [ 'core', 'trading_accounts', 'platform', ],
    			key: 'tradingPlatform',
    			render: this.renderEnum('core', 'AccountBalancesPlatformEnum'),
    			translate: 'list_accounts_table_platform',
    		},
    	].map(({ translate: t, ...data }) => ({ ...data, ...t && { title: translate(t), }, }));
    }

    save = async state => new Promise(next => this.setState(state, next));

    renderSystemLink = (id, text) => (<Link to={ `/list-accounts/systems/${ +id }` }>{ text }</Link>);

    renderTradingLink = (id, text) => (<Link to={ `/list-accounts/tradings/${ +id }` }>{ text }</Link>);

    renderId = link => (value, { item, items, }) => {
    	const tradingAccountId = items?.tradingAccountId
    		? items?.tradingAccountId.valueOf
    		: value;

    	if (link && item?.access('show', 'index')) {
    		return link(+tradingAccountId, value);
    	} else if (item?.access('index')) {
    		return +value;
    	}

    	return '-';
    };

    renderDate = () => (value, { item, }) => {
    	if (item.access('index')) {
    		const formatDB = 'YYYY-MM-DD HH:mm:ss';
    		const formatOut = 'DD/MM/YYYY HH:mm:ss';
    		const date = Moment(value, formatDB);

    		return date.isValid() ? date.format(formatOut) : "";
    	}

    	return '-';
    };

    renderEnum = (schema, enums) => (value, { item, }) => {
    	if (item.access('index')) {
    		return this.enums[schema][enums][value] ?? "";
    	}

    	return '-';
    };

    renderText = () => (value, { item, }) => {
    	if (item.access('index')) {
    		return value ?? "";
    	}

    	return '-';
    };
	handleOnChange = (items) => {
		const { isOpenedStatement, } = this.state;
		const accountId = items?.systemStatement;
		const id = items?.systemId.valueOf;

		window.history.pushState(+id, `/clients/${ +accountId?.valueOf.account_id }/accounts/`);

		this.save({ isOpenedStatement: !isOpenedStatement, });
	}
	renderStatement = () => (value, { item, items, }) => {
		const { isOpenedStatement, } = this.state;
		const id = items?.systemId.valueOf;
		const { translate, } = this.props;

		return value.type !== 1 ? "" :
			<>
			<button
					className="lead_deposits_statement_btn"
					onClick={ () => this.handleOnChange(items) }
				>
					{translate('list_accounts_table_system_statement')}
				</button>

			{isOpenedStatement &&
					<LeadStatementPopUp
					setClosedPopUp={ this.handleOnChange }
					systemId={ id }
				/>
				}
		</>;
	}
	;
    renderSystems = () => {
    	const options = {
    		config: this.systemsConfig,
    		data: this.state.data,
    		head: [
    			'systemId',
    			'systemCreated',
    			'systemUpdated',
    			'systemType',
    			'systemCurrency',
    			'systemBalance',
    			'systemStatus',
    			'systemStatement',
    		],
    	};

    	return (<MagicTable { ...options } />);
    };

    renderTradings = () => {
    	const options = {
    		config: this.tradingsConfig,
    		data: this.state.data,
    		head: [
    			'mtId',
    			'tradingCreated',
    			'tradingUpdated',
    			'tradingType',
    			'tradingLevel',
    			'tradingCurrency',
    			'tradingBalance',
    			'tradingEquity',
    			'tradingStatus',
    			'tradingLeverage',
    			'tradingRiskManager',
    			'tradingMtGroup',
    			'tradingPlatform',
    		],
    	};

    	return (<MagicTable { ...options } />);
    };

    componentDidMount = async () => {
    	await this.onDataLoad();
    };

    renderTotal = (items, title) => {
    	const unit = key => this.enums?.core?.AccountCurrencyEnum?.[key];
    	const fixed = value => Number(value)?.toFixed?.(2);
    	const result = items?.reduce((result, [ key, value, ]) => (
	<React.Fragment>
	{ result }
	<span
	className="total__item"
	data-item={ true }
	data-value={ value }
	data-unit={ key }
    			>
	<span
	className="total__value"
	children={ fixed(value) }
	data-value={ true }
    				/>
	<span
	className="total__unit"
	children={ unit(key) || key }
	data-unit={ true }
    				/>
    			</span>
    		</React.Fragment>
    	), (
	<span
	className="total__title"
	children={ title }
	data-items={ true }
	data-length={ items?.length }
    		/>
    	));

    	return [ result, items?.length, ];
    };

    render = () => {
    	const { translate, } = this.props;
    	const bar = {
    		parent: {
    			className: [ 'toolbar_tab_account', ],
    		},
    		title: {
    			className: [ 'title_information_additional', ],
    			text: translate(`list_accounts`),
    		},
    		systems: {
    			className: [ 'nav-link', ],
    			text: translate('list_accounts_system_accounts'),
    			to: `/clients/${ this.accountId }/accounts/systems`,
    		},
    		tradings: {
    			className: [ 'nav-link', ],
    			text: translate('list_accounts_trading_accounts'),
    			to: `/clients/${ this.accountId }/accounts/tradings`,
    		},
    	};
    	let content = (<span />);

    	switch (this.accountTab) {
    		case 'systems':
    		default: {
    			bar?.systems?.className?.push('active');
    			content = this.renderSystems();
    			break;
    		}
    		case 'tradings': {
    			bar?.tradings?.className?.push('active');
    			content = this.renderTradings();
    			break;
    		}
    	}
    	const [ balance, blength, ] = this.renderTotal(
    		Object.entries(this.state?.balance),
    		translate('client_deposits_balance'),
    	);
    	const [ credit, crlength, ] = this.renderTotal(
    		Object.entries(this.state?.credit),
    		translate('client_deposits_credit'),
    	);
    	const [ equity, elength, ] = this.renderTotal(
    		Object.entries(this.state?.equity),
    		translate('client_deposits_equity'),
    	);
    	const [ affiliateReward, alength, ] = this.renderTotal(
    		Object.entries(this.state?.affiliate_reward),
    		translate('client_deposits_affiliate_reward'),
    	);
    	const [ clientResult, clength, ] = this.renderTotal(
    		Object.entries(this.state?.client_result),
    		translate('client_deposits_client_result'),
    	);
    	const [ promotionsAdjustments, plength, ] = this.renderTotal(
    		Object.entries(this.state?.promotions_and_adjustments),
    		translate('client_deposits_promotions_and_adjustments'),
    	);

    	return (
	<React.Fragment>
	<div className="deposits-tab-inner">
	<div className="deposits-block-wrapper">
	<div className="total">
	<span className="total__scope" data-length={ blength }>{ balance }</span>
	<span className="total__scope" data-length={ crlength }>{ credit }</span>
	<span className="total__scope" data-length={ elength }>{ equity }</span>
	<span className="total__scope" data-length={ alength }>{ affiliateReward }</span>
	<span className="total__scope" data-length={ clength }>{ clientResult }</span>
	<span className="total__scope" data-length={ plength }>{ promotionsAdjustments }</span>
    					</div>
	<div className={ bar.parent.className.join(' ') }>
	<span className={ bar.title.className.join(' ') }>
	{ bar.title.text }
    						</span>
	<Link className={ bar.systems.className.join(' ') } to={ bar.systems.to } >{ bar.systems.text }</Link>
	<Link className={ bar.tradings.className.join(' ') } to={ bar.tradings.to } >{ bar.tradings.text }</Link>
    					</div>
	<Loader
	loaded={ this.state.loaded }
	loading={ (<Preloader scale={ this.props.scale } />) }
    					>
	{ content }
	<PagePanel
	filter={ this.state.pages.filter }
	take={ this.state.pages.take }
	total={ this.state.pages.total }
	variance={ this.state.pages.variance }
	page={ this.pageId() }
	onClick={ this.onPageClick }
	doText={ this.onPageText }
	doTo={ pageId => `?page=${ pageId }` }
    						/>
    					</Loader>
    				</div>
    			</div>
    		</React.Fragment>
    	);
    };

    pageId = () => +this?.query?.get('page') || 0;

    pageIdAsGET = (pageId) => {
    	const take = this?.state?.pages?.take ?? 1;

    	pageId = pageId ?? this.pageId();

    	return { take, skip: pageId * take, };
    };

    onDataLoad = async (pageId) => {
    	const loaded = (data, meta, balance, credit, equity, affiliate_reward, client_result, promotions_and_adjustments) => ({ pages, }) => ({
    		affiliate_reward,
    		client_result,
    		data,
    		balance,
			credit,
    		equity,
    		promotions_and_adjustments,
    		pages: { ...pages, ...meta, },
    	});

    	await this.save({ loaded: false, });
    	try {
    		const accountId = this.accountId;
    		const pageOptions = this.pageIdAsGET(pageId);
    		let load;

    		switch (this.accountTab) {
    			default:
    			case 'systems': {
    				load = listAccountService.systemAccounts4Account.bind(listAccountService);
    				break;
    			}
    			case 'tradings': {
    				load = listAccountService.tradingAccounts4Account.bind(listAccountService);
    				break;
    			}
    		}
    		const response = await Promise.all([
    			load(accountId, pageOptions),
    			getAccountTotalBalance(accountId).catch(error => console.log(error)),
    			getAccountTotalEquity(accountId).catch(error => console.log(error)),
    		]);
    		const { data = [], meta: { filter = 0, total = 0, }, } = response?.[0] ?? {};
    		const balance = response?.[1] ?? {};
    		const credit = response?.[2] ?? {};
    		const affiliate_reward =  response?.[1].affiliate_reward ?? {};
    		const client_result = response?.[1].client_result ?? {};
    		const promotions_and_adjustments = response?.[1].promotions_and_adjustments ?? {};
    		const equity = response?.[2] ?? {};

    		await this.save(loaded(data, { filter, total, }, balance.all, credit, equity, affiliate_reward, client_result, promotions_and_adjustments));
    	} catch (error) {
    		await this.save(loaded([], { filter: 0, total: 0, }, {}, {}));
    	}
    	await this.save({ loaded: true, });
    };

    onPageClick = ({ event, pageId, pageIs, }) => {
        // event.preventDefault();
    	this.onDataLoad(pageId);
    };

    onPageText = (pageId, pageIs) => {
    	const { translate, } = this.props;
    	const {
    		current, first, prev, next, last,
    	} = pageIs;
    	const {
    		skipped, taken, filter, total,
    	} = pageIs;

    	if (skipped || taken) {
    		const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : '?';
    		const text = skipped ? translate('tima_agents_pages_items') : '';

    		return skipped ? `${ text }: ${ id }` : id;
    	} else if (filter || total) {
    		const id = Number.isInteger(pageId) ? `${ pageId }` : '?';
    		const text = translate(filter ? 'tima_agents_pages_filtered' : 'tima_agents_pages_total');

    		return `${ text }: ${ id }`;
    	} else if (first || prev || next || last) {
    		return '';
    	} else if (current) {
    		return `${ pageId + 1 }`;
    	}

    	return `${ pageId + 1 }`;

    };
}
export default withRouter(LeadDeposits);