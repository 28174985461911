import React from 'react';
import * as PT from 'prop-types';
import { Table, } from 'reactstrap';
import PermissionService from '../../../../services/PermissionService';

export class MagicTable extends React.Component {
    static propTypes = {
    	config: PT.arrayOf(PT.shape({
    		actionChecked: PT.bool,
    		path: PT.arrayOf(PT.string),
    		render: PT.func,
    		title: PT.node,
    	})).isRequired,
    	data: PT.array.isRequired,
    	head: PT.arrayOf(PT.string.isRequired).isRequired,
    	CellProps: PT.func,
    	RowProps: PT.func,
    };

    static defaultProps = {
    	data: [],
    	head: [],
    };

    get config () {
    	return this.props?.config;
    }

    get head () {
    	const items = PermissionService.calc(this.config);
    	const head = this.props?.head
    		?.map(key => items?.[key])
    		?.filter((item) => {
    			if (item?.options?.customAccessCheck instanceof Function) {
    				return item?.options?.customAccessCheck({ item, items, });
    			}

    			return item?.access('index');

    		});

    	return head;
    }

    get data () {
    	const data = PermissionService.cast(this.props?.data, this.config, true);

    	return data;
    }

    renderCell = data => (head, index) => {
    	let content;

    	try {
    		let CellProps = this.props?.CellProps;

    		CellProps = CellProps?.({
    			head,
    			index,
    			...data && { item: data?.[head?.key], },
    			...data && { items: data, },
    		});
    		if (data) {
    			content = data?.[head?.key]?.render?.() ?? `? cell ${ head?.key } ?`;
    			const options = {
    				className: `magic-table__item`,
    			};

    			content = (<span { ...options }>{ content }</span>);
    		} else {
    			let onClick; let orderTo;

    			content = head?.title ?? `? title ${ head?.key } ?`;
    			if (head?.title && head?.order instanceof Function) {
    				onClick = { onClick: head?.order, };
    				orderTo = { 'data-order': head?.orderTo?.toLowerCase?.() ?? '', };
    			}
    			const options = {
    				className: `magic-table__cell_title`,
    				...orderTo && !data && orderTo,
    				...onClick && !data && onClick,
    			};

    			content = (<span { ...options }>{ content }</span>);
    		}
    		const options = {
    			...CellProps && CellProps,
    			className: `magic-table__cell`,
    			key: index,
    			'data-cell': !!data,
    			...head?.key && { 'data-key': head?.key, },
    			...head?.title && { 'data-title': head?.title, },
    		};

    		content = (<td { ...options }>{ content }</td>);
    	} catch (error) {
    		content = (<td>{ String(error) }</td>);
    	}

    	return content;
    };

    renderRow = head => (renderCell, index, data) => {
    	const { RowProps, } = this.props;

    	try {
    		const options = {
    			...RowProps && RowProps?.({ index, items: data, head, }),
    			className: `magic-table__row`,
    			key: index,
    			'data-row': index !== undefined,
    			'data-index': index !== undefined ? index + 1 : undefined,
    		};
    		const items = head?.map?.(renderCell);

    		return (<tr { ...options }>{ items }</tr>);
    	} catch (error) {
    		return (<tr><td>{ String(error) }</td></tr>);
    	}
    };

    render = () => {
    	try {
    		const td = this.renderCell;
    		const th = td?.(false);
    		const tr = this.renderRow(this.head);
    		const head = tr?.(th, undefined, undefined);
    		const data = this.data?.map?.((data, index) => tr?.(td?.(data), index, data));

    		return (
    			<Table className={ `magic-table` }>
    				<thead className={ `magic-table__block magic-table__block_title` }>
    					{ head }
    				</thead>
    				<tbody className={ `magic-table__block` } >{ data }</tbody>
    			</Table>
    		);
    	} catch (error) {
    		console.error(error);

    		return (<strong style={ { color: 'red', } }>MagicTable</strong>);
    	}
    }
}