import { valid, } from './Options.valid';

/**
 * Posible field types
 *
 * @readonly
 */
export const TYPE = {
  date: 'date',
  datetime: 'datetime',
  enum: 'enum',
  id: 'id',
  number: 'number',
  string: 'string',
};

/**
 * Convert BE field type to FE field type
 *
 * @arg {String} key - BE field type
 * @returns {String} FE field type
 */
const typeToType = key => ({
  boolean: TYPE.number,
  date: TYPE.date,
  datetime: TYPE.datetime,
  double: TYPE.number,
  enum: TYPE.enum,
  float: TYPE.number,
  id: TYPE.id,
  int: TYPE.number,
  integer: TYPE.number,
  number: TYPE.number,
  json: TYPE.string,
  string: TYPE.string,
  text: TYPE.string,
})[key];

/**
 * Structure for creation of Options
 *
 * @typedef {Object} FilterOptionsData - Settings for Options
 * @property {Object} data - {
 *   "schema.table.field": {
 *     operators: String[],
 *     translate: String,
 *     type: String,
 *     [drop-down: String,]
 *     [enum: Array<String>,]
 *     [enums: Object,]
 *     [having: Number(0, 1),]
 *     [order: Number(0, 1),]
 *     [sub_where: Number(0, 1),]
 *   },
 *   return: Object,
 * }
 * @property {Object} return - { connect: 'pgsql', }
 */

/**
 * @class
 */
export class Options {

  /**
   * Creates as instance of Options
   *
   * @arg {Object} settings - { data: Object, return: Object, }
   * @memberof Options
   */
  constructor (settings) {
    valid(settings, typeToType);

    const { data, return: be, } = settings;

    Object.defineProperties(this, {
      be: { enumerable: true, get: () => be, },
      field: {
        get: () => (field) => {
          if (!data.hasOwnProperty(field)) {
            throw new Error(`Options field: ${ field } unknown`);
          }

          const {
            operators,
            translate,
            type,
            'drop-down': dd,
            enum: e,
            enums,
            having,
            order,
            sub_where,
          } = data[field];

          return {
            operators: [ ...operators, ],
            translate,
            type: typeToType(type),
            ...dd && { 'drop-down': dd, },
            ...e && { enum: [ ...e, ], },
            ...enums && { enums: { ...enums, }, },
            ...having && { having, },
            ...order && { order, },
            ...sub_where && { sub_where, },
          };
        },
      },
      fields: { enumerable: true, get: () => Object.keys(data), },
      settings: { enumerable: true, get: () => settings, },
      toObject: { get: () => () => settings, },
    });

    Object.freeze(this);
  }

  /**
   *
   * @arg {String} field - fiel name
   * @returns {String} URL
   * @memberof Options
   */
  dropDown (field) {
    return this.field(field)['drop-down'];
  }

  /**
   *
   * @arg {String} field - field name
   * @returns {String[]} in all enums path
   * @memberof Options
   */
  enum (field) {
    return this.field(field).enum;
  }

  /**
   *
   * @arg {String} field - field name
   * @returns {Object} Enums Object
   * @memberof Options
   */
  enums (field) {
    return this.field(field).enums;
  }

  /**
   *
   * @arg {String} field - field name
   * @returns {Number} flag Number(0, 1)
   * @memberof Options
   */
  having (field) {
    return this.field(field).having;
  }

  /**
   *
   * @arg {String} field - field name
   * @returns {String[]} Operators list
   * @memberof Options
   */
  operators (field) {
    return this.field(field).operators;
  }

  /**
   *
   * @arg {String} field - field name
   * @returns {Number} fleg Number(0, 1)
   * @memberof Options
   */
  order (field) {
    return this.field(field).order;
  }

  /**
   *
   * @arg {String} field - field name
   * @returns {Number} flag Number(0, 1)
   * @memberof Options
   */
  sub_where (field) {
    return this.field(field).sub_where;
  }

  /**
   *
   * @arg {String} field - field name
   * @returns {Object} Object with flags Number(0, 1)
   * @memberof Options
   */
  tail (field) {
    const data = this.field(field);
    const { having, order, sub_where, } = data;

    return {
      ...![ undefined, ].includes(having) && { having, },
      ...![ undefined, ].includes(order) && { order, },
      ...![ undefined, ].includes(sub_where) && { sub_where, },
    };
  }

  /**
   *
   * @returns {JSON} JSON
   * @memberof Options
   */
  toJSON () {
    const fields = this.fields;

    return {
      fields,
      items: fields.map(field => this.field(field)),
    };
  }

  /**
   *
   * @arg {String} field - field name
   * @returns {String} type
   * @memberof Options
   */
  translate (field) {
    return this.field(field).translate;
  }

  /**
   *
   * @arg {String} field - field name
   * @returns {String} type
   * @memberof Options
   */
  type (field) {
    return this.field(field).type;
  }

  /**
   *
   * @readonly
   * @memberof Options
   */
  get json () {
    return this.toJSON();
  }

  /**
   *
   * @readonly
   * @memberof Options
   */
  get object () {
    return this.toObject();
  }

}