import { cachingService, hash as hashData, } from './CachingService';
import { DataService } from './DataService';

import { GET_PROFILE } from "../apiRoutes";

class ProfileDataService extends DataService {
    constructor () {
        const events = {
            profile: async () => {
                const url = GET_PROFILE;
                const data = await cachingService.session(url);
                const hash = await hashData(data);
                const profile = { data, hash, };
                await this.emit('profile', profile);
                return profile;
            },
        };
        super(Object.keys(events));
        Object.defineProperties(this, {
            profile: { get: events.profile, },
        });
        Object.freeze(this);
    }
}

export const profileService = new ProfileDataService();
