import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import DatePicker from 'react-date-picker';

import InteratorListUser from './InteratorListUser';
import InteratorFinishedCall from './InteratorFinishedCall';
import InteratorDialogue from './InteratorDialogue';
import InteratorActiveCall from './InteratorActiveCall';
import InteratorLetter from './InteratorLetter';
import PermissionService, { MODULE_KEYS as pm } from "../../../../../services/PermissionService";
import { get_random_guid } from "../../../../../helpers/OtherHelper";
import Select from 'react-select';
import addImg from '../../../../../images/yellow-add.svg';

import {Translate, withLocalize} from "react-localize-redux";
import connect from "react-redux/es/connect/connect";

const interaction = [
    {
        'id':                      1,
        'type':                    'dialogue',
        'interaction_created':     '8/09/2019, 15:00 pm',
        'interaction_type':        'Dialogue',
        'interaction_type_icon':   'dialogue',
        'interaction_amount':      '16',
        'interaction_manager':     'Manager Victor',
        'interaction_result_type': 'fail',
        'interaction_result':      'Fiction-writing also has modes: action, exposition,  and transition writing description, dialogue, summary, and transition writing also has modes… ',
    },
    {
        'id':                      2,
        'type':                    'active-call',
        'interaction_created':     '8/09/2019, 15:00 pm',
        'interaction_type':        'Outgoing call',
        'interaction_type_icon':   'callstat_outgoing',
        'interaction_amount':      '2:35',
        'interaction_manager':     'Manager Victor',
        'interaction_result_type': 'wait',
        'interaction_result':      'Fiction-writing also has modes: action, exposition,  and transition writing description, dialogue, summary, and transition writing also has modes… ',
    },
    {
        'id':                      3,
        'type':                    'finished-call',
        'interaction_created':     '8/09/2019, 15:00 pm',
        'interaction_type':        'Incoming call',
        'interaction_type_icon':   'callstat_incoming',
        'interaction_amount':      '2:35',
        'interaction_manager':     'Manager Victor',
        'interaction_result_type': 'fail',
        'interaction_result':      'Fiction-writing also has modes: action, exposition,  and transition writing description, dialogue, summary, and transition writing also has modes… ',
    },
    {
        'id':                      4,
        'type':                    'finished-call',
        'interaction_created':     '8/09/2019, 15:00 pm',
        'interaction_type':        'Missed call',
        'interaction_type_icon':   'callstat_missed',
        'interaction_amount':      '',
        'interaction_manager':     'Manager Victor',
        'interaction_result_type': 'success',
        'interaction_result':      'Fiction-writing also has modes: action, exposition,  and transition writing description, dialogue, summary, and transition writing also has modes… ',
    },
    {
        'id':                      5,
        'type':                    'letter',
        'interaction_created':     '8/09/2019, 15:00 pm',
        'interaction_type':        'Letter',
        'interaction_type_icon':   'letter',
        'interaction_amount':      '16',
        'interaction_manager':     'Manager Victor',
        'interaction_result_type': 'success',
        'interaction_result':      'Fiction-writing also has modes: action, exposition,  and transition writing description, dialogue, summary, and transition writing also has modes… ',
    },
    {
        'id':                      6,
        'type':                    'active-call',
        'interaction_created':     '8/09/2019, 15:00 pm',
        'interaction_type':        'Outgoing call',
        'interaction_type_icon':   'callstat_outgoing',
        'interaction_amount':      '2:35',
        'interaction_manager':     'Manager Victor',
        'interaction_result_type': 'wait',
        'interaction_result':      'Fiction-writing also has modes: action, exposition,  and transition writing description, dialogue, summary, and transition writing also has modes… ',
    },
    {
        'id':                      7,
        'type':                    'finished-call',
        'interaction_created':     '8/09/2019, 15:00 pm',
        'interaction_type':        'Incoming call',
        'interaction_type_icon':   'callstat_incoming',
        'interaction_amount':      '2:35',
        'interaction_manager':     'Manager Victor',
        'interaction_result_type': 'success',
        'interaction_result':      'Fiction-writing also has modes: action, exposition,  and transition writing description, dialogue, summary, and transition writing also has modes… ',
    },
    {
        'id':                      8,
        'type':                    'active-call',
        'interaction_created':     '8/09/2019, 15:00 pm',
        'interaction_type':        'Missed call',
        'interaction_type_icon':   'callstat_missed',
        'interaction_amount':      '',
        'interaction_manager':     'Manager Victor',
        'interaction_result_type': 'fail',
        'interaction_result':      'Fiction-writing also has modes: action, exposition,  and transition writing description, dialogue, summary, and transition writing also has modes… ',
    },
    {
        'id':                      9,
        'type':                    'dialogue',
        'interaction_created':     '8/09/2019, 15:00 pm',
        'interaction_type':        'Dialogue',
        'interaction_type_icon':   'dialogue',
        'interaction_amount':      '16',
        'interaction_manager':     'Manager Victor',
        'interaction_result_type': 'fail',
        'interaction_result':      'Fiction-writing also has modes: action, exposition,  and transition writing description, dialogue, summary, and transition writing also has modes… ',
    },
    {
        'id':                      10,
        'type':                    'active-call',
        'interaction_created':     '8/09/2019, 15:00 pm',
        'interaction_type':        'Outgoing call',
        'interaction_type_icon':   'callstat_outgoing',
        'interaction_amount':      '2:35',
        'interaction_manager':     'Manager Victor',
        'interaction_result_type': 'wait',
        'interaction_result':      'Fiction-writing also has modes: action, exposition,  and transition writing description, dialogue, summary, and transition writing also has modes… ',
    },
    {
        'id':                      11,
        'type':                    'finished-call',
        'interaction_created':     '8/09/2019, 15:00 pm',
        'interaction_type':        'Incoming call',
        'interaction_type_icon':   'callstat_incoming',
        'interaction_amount':      '2:35',
        'interaction_manager':     'Manager Victor',
        'interaction_result_type': 'fail',
        'interaction_result':      'Fiction-writing also has modes: action, exposition,  and transition writing description, dialogue, summary, and transition writing also has modes… ',
    },
    {
        'id':                      12,
        'type':                    'finished-call',
        'interaction_created':     '8/09/2019, 15:00 pm',
        'interaction_type':        'Missed call',
        'interaction_type_icon':   'callstat_missed',
        'interaction_amount':      '',
        'interaction_manager':     'Manager Victor',
        'interaction_result_type': 'success',
        'interaction_result':      'Fiction-writing also has modes: action, exposition,  and transition writing description, dialogue, summary, and transition writing also has modes… ',
    },
    {
        'id':                      13,
        'type':                    'dialogue',
        'interaction_created':     '8/09/2019, 15:00 pm',
        'interaction_type':        'Dialogue',
        'interaction_type_icon':   'dialogue',
        'interaction_amount':      '16',
        'interaction_manager':     'Manager Victor',
        'interaction_result_type': 'fail',
        'interaction_result':      'Fiction-writing also has modes: action, exposition,  and transition writing description, dialogue, summary, and transition writing also has modes… ',
    },
    {
        'id':                      14,
        'type':                    'active-call',
        'interaction_created':     '8/09/2019, 15:00 pm',
        'interaction_type':        'Outgoing call',
        'interaction_type_icon':   'callstat_outgoing',
        'interaction_amount':      '2:35',
        'interaction_manager':     'Manager Victor',
        'interaction_result_type': 'wait',
        'interaction_result':      'Fiction-writing also has modes: action, exposition,  and transition writing description, dialogue, summary, and transition writing also has modes… ',
    },
    {
        'id':                      15,
        'type':                    'finished-call',
        'interaction_created':     '8/09/2019, 15:00 pm',
        'interaction_type':        'Incoming call',
        'interaction_type_icon':   'callstat_incoming',
        'interaction_amount':      '2:35',
        'interaction_manager':     'Manager Victor',
        'interaction_result_type': 'success',
        'interaction_result':      'Fiction-writing also has modes: action, exposition,  and transition writing description, dialogue, summary, and transition writing also has modes… ',
    },
    {
        'id':                      16,
        'type':                    'active-call',
        'interaction_created':     '8/09/2019, 15:00 pm',
        'interaction_type':        'Missed call',
        'interaction_type_icon':   'callstat_missed',
        'interaction_amount':      '',
        'interaction_manager':     'Manager Victor',
        'interaction_result_type': 'success',
        'interaction_result':      'Fiction-writing also has modes: action, exposition,  and transition writing description, dialogue, summary, and transition writing also has modes… ',
    }
];

const customStyles = {
    control: () => ({
    }),
    singleValue: (base, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { opacity, transition };
    },
};

const type = [
    { value: '1', label: 'Outgoing call' },
    { value: '2', label: 'Incoming call' },
    { value: '3', label: 'Missed call' },
    { value: '4', label: 'Dialogue' },
    { value: '5', label: 'Email' }
];

class InteratorList extends Component {
    constructor (props) {
        super(props);
        this.state = {
            dataListUsers:     this.props.items,
            dataListUsersView: this.props.items,
            currentUser:       interaction[0] || {},
            type:              interaction[0].type || undefined,
            currentUserId:     interaction[0].id || undefined,
            order:             [],
            dateFrom: new Date(),
            dateTo: new Date(),
        };
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        this.setState({
            dataListUsersView: { ...nextProps.items },
        });
    }

    setActiveUser = (id, type) => {
        this.setState({
            currentUser: interaction[id] || interaction[0] || {},
            currentUserId: id || 0 || {},
            type,
        });
    }

    renderInteractionDetails = () => {
        switch (this.state.type) {
            case 'finished-call': {
                return <InteratorFinishedCall currentUser = { this.state.currentUser } />;
                break;
            }
            case 'active-call': {
                return <InteratorActiveCall currentUser = { this.state.currentUser } />;
                break;
            }
            case 'letter': {
                return <InteratorLetter currentUser = { this.state.currentUser } />;
                break;
            }
            case 'dialogue': {
                return <InteratorDialogue currentUser = { this.state.currentUser } />;
                break;
            }
            default: {
                break;
            }
        }
    }

    onChangeFrom = dateFrom => this.setState({ dateFrom })
    onChangeTo = dateTo => this.setState({ dateTo })

    render () {
        return (
            <Translate key = { get_random_guid() }>
                {({ translate }) => {
                    return (
                        <div className = 'interaction-list'>
                            {PermissionService.actionForRoute(pm.CORE_ACCOUNT, 'getInteraction') ?
                                <React.Fragment>
                                    <Row>
                                        <Col sm = '7'>
                                            <div className = 'table-top'>
                                                <div className = 'table-filters'>
                                                    <div className = 'table-filters-wrapper'>
                                                        <div
                                                            className = 'table-filters-head'>{translate(`transactions_filters`)}</div>
                                                        <div className = 'input-block interaction-date'>
                                                            <DatePicker
                                                                value={this.state.dateFrom}
                                                                onChange={this.onChangeFrom}
                                                                format="dd.MM.y"
                                                                calendarClassName = {'react-datepicker__month-container'}
                                                                className = {'react-datepicker'}
                                                                clearIcon = { null }
                                                                calendarIcon = { null }
                                                                maxDate={new Date(this.state.dateTo)}
                                                            />
                                                        </div>
                                                        <div className = 'input-block interaction-date'>
                                                            <DatePicker
                                                                value={this.state.dateTo}
                                                                onChange={this.onChangeTo}
                                                                format="dd.MM.y"
                                                                calendarClassName = {'react-datepicker__month-container'}
                                                                className = {'react-datepicker'}
                                                                clearIcon = { null }
                                                                calendarIcon = { null }
                                                                minDate={new Date(this.state.dateFrom) }
                                                            />
                                                        </div>
                                                        <div className='input-block'>
                                                            <div className='table-filter-select'>
                                                                <Select
                                                                    className='form-select page-select'
                                                                    classNamePrefix = 'select'
                                                                    options = { type }
                                                                    styles = { customStyles }
                                                                    isSearchable={false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm = '5'>
                                            <div className = 'table-filters alignright'>
                                                <div className = 'table-filters-wrapper'>
                                                    <div
                                                        className = 'button blue wd-100'
                                                        >{translate(`client_apply`)}</div>
                                                    <div
                                                        className = 'button light-blue bordered wd-100'
                                                        >{translate(`client_reset`)}</div>
                                                    <div
                                                        className = 'button yellow bordered wd-100'
                                                    ><img src={addImg} alt="add"/>{translate(`client_add`)}</div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm = '8'>
                                            <InteratorListUser
                                                jsonData = { interaction }
                                                searchValue = { this.state.searchValue }
                                                setActiveUser = { this.setActiveUser }
                                                currentUserId = { this.state.currentUserId }
                                            />
                                        </Col>
                                        <Col sm = '4'>
                                            {this.renderInteractionDetails()}
                                        </Col>
                                    </Row>
                                </React.Fragment>
                                : ""
                            }
                        </div>
                    );
                }}
            </Translate>
        );
    }
}


export default withLocalize(connect(
)(InteratorList));