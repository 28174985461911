import React, { Component } from 'react';

import DayScheduleSelect from './DayScheduleSelect';
import { withLocalize } from "react-localize-redux";
import { map } from 'lodash';
import * as moment from 'moment';
import DataStorage from "../../../../../helpers/DataStorage";

class ManagerScheduleTable extends Component {
    state = {
        weekSchedule: this.props.weekSchedule,
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        if (this.props.weekSchedule !== nextProps.weekSchedule) {
            this.setState({
                weekSchedule: nextProps.weekSchedule
            });
        }
    }

    render () {
        const weekSchedule = JSON.parse(this.props.weekSchedule);
        // TODO: в будущем к удалению - настройка локализации должна быть в одном месте
        let localeLang = DataStorage.getData('locale');
        localeLang = localeLang ? localeLang : 'en';
        moment.locale(localeLang);

        return (
            <React.Fragment>
                <table className="schedule-table">
                    <tbody>
                    {
                        map(weekSchedule, (item, key) => {
                            const dayOfWeek = +key.substring(key.length - 1);
                            // такой вызов потому что key === day_n, который приходит с бека
                            return <tr key={ key }>
                                <td className="text-capitalize">
                                    {moment.weekdays(dayOfWeek)}
                                </td>
                                <td>
                                    <span className = 'small-day-number'>{key.substring(key.length - 1)}</span>
                                </td>
                                <td>
                                    <DayScheduleSelect disabled={ this.props?.disabled ?? false } scheduleChange = {this.props.scheduleChange} weekSchedule = {weekSchedule} day = {key} type = { 'from' } work = {item['is_work']} value = {item.from} translate = {this.props.translate} />
                                </td>
                                <td>
                                    <DayScheduleSelect disabled={ this.props?.disabled ?? false } scheduleChange = {this.props.scheduleChange} weekSchedule = {weekSchedule} day = {key} type = { 'to' } work = {item['is_work']} value = {item.to} translate = {this.props.translate} />
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
            </React.Fragment>
        );
    }
}
export default withLocalize(ManagerScheduleTable);
