import { combineReducers } from 'redux';
import user from './modules/admin_panel/users/reducers/user/reducer';
import dashboard from './modules/dashboard/reducer';
import users from './modules/admin_panel/users/reducer';
import clients from './modules/clients/reducer';
import transaction from './modules/transactions/reducer';
import tasks from './modules/tasks/reducer';
import sideBar from './modules/common/reducers/side_bar/reducer';
import errors from './services/RequestService/reducer';
import kpi from './modules/common/reducers/kpi/reducer';
import enums from './modules/common/reducers/enums/reducer';
import { localizeReducer as localize } from 'react-localize-redux';

export default combineReducers({
    user,
    localize,
    dashboard,
    clients,
    tasks,
    sideBar,
    errors,
    users,
    transaction,
    kpi,
    enums,
});
