export const templatePermission = {
    "template_permission": ["Template Permission", "Управление правами доступа", "Керування правами доступу"],
    "template_permission_create": ["Create", "Создать", "Створити"],
    "template_permission_template_name": ["Template name", "Имя шаблона", "Назва шаблону"],
    "template_permission_action": ["Action", "Действие", "Дія"],
    "template_permission_btn_edit": ["Edit", "Редактировать", "Редагувати"],
    "template_permission_btn_save": ["Save", "Сохранить", "Зберегти"],
    "template_permission_btn_delete": ["Delete", "Удалить", "Видалити"],
    "template_permission_number_of_users": ["Number of users", "Количество пользователей", "Кількість користувачів"],
    "template_permission_user_search": ["User search", "Поиск пользователя", "Пошук користувачів"],
    "template_permission_user_list": ["User list", "Список пользователей", "Список користувачів"],
    "template_remove_template_message": ["This template is used by CRM users! Add another permission template for these users.", "Данный шаблон используется пользователями CRM!  Добавьте другой шаблон прав доступа для  данных пользователей.", "Даний шаблон використовується користувачами CRM! Додайте інший шаблон прав доступу для даних користувачів."],
    "template_remove_template_message_two": ["The template will be deleted from the database.", "Шаблон будет удален из базы", "Шаблон буде видалений з бази."],
}