import { operators as allOperators, } from '../Operators';

/**
 * Validate Options constructor arguments
 *
 * @function
 * @arg {Object} settings - Object with settings
 * @returns {undefined} throw Error if any validation failed
 */
export const valid = (settings) => {
  const msg = text => new Error(`Expect Config${ text }`);

  if (!(settings instanceof Object)) {
    throw msg(`(settings) as Object`);
  }

  // eslint-disable-next-line object-curly-newline
  const { filters, id, orders, panels, path, translate, widget, } = settings;

  const are = is => _ => _.every(is);
  const re = /^(?:[_\w]+[.]){1,2}[_\w]+$/;
  const isArray = _ => Array.isArray(_);
  const noArray = _ => !isArray(_);
  const isBoolean = _ => [ 'boolean', ].includes(typeof _);
  const isDefined = _ => _ !== undefined;
  const isFlag = _ => [ 0, 1, ].includes(_);
  const isFunction = _ => _ instanceof Function;
  const isInteger = _ => Number.isInteger(_);
  const isObject = _ => _ instanceof Object;
  const isString = _ => [ 'string', ].includes(typeof _);
  const isField = _ => re.test(_);
  const isFilter = (text, it=0) => (filter) => {
    if (it>2) {
      throw msg(`${ text } depth over 2`);
    } else if (!isObject(filter)) {
      throw msg(`${ text } as Object`);
    } else if (!isObject(filter.$)) {
      throw msg(`${ text }.$ as Object`);
    } else if (!isString(filter.$.id)) {
      throw msg(`${ text }.$.id as String`);
    } else if (!isString(filter.$.type)) {
      throw msg(`${ text }.$.type as String`);
    } else if (!isFlag(filter.enabled)) {
      throw msg(`${ text }.enabled in {0, 1}`);
    } else if (isArray(filter.f) && !filter.f.every(isString)) {
      throw msg(`${ text }.f as String[]`);
    } else if (isArray(filter.f) && !filter.f.every(isField)) {
      throw msg(`${ text }.f as Field[]`);
    } else if (!isArray(filter.f) && !isString(filter.f)) {
      throw msg(`${ text }.f as String`);
    } else if (!isArray(filter.f) && !isField(filter.f)) {
      throw msg(`${ text }.f as Field`);
    } else if (!isString(filter.o)) {
      throw msg(`${ text }.o as String`);
    } else if (!allOperators.aliases.includes(filter.o)) {
      throw msg(`${ text }.o in allOperators.aliases`);
    } else if (!isDefined(filter.v)) {
      throw msg(`${ text }.v are Defined`);
    } else if (!isDefined(filter.fields)) {
      // no fields to check
    } else if (isArray(filter.fields)) {
      if (!filter.fields.every(are(isObject))) {
        throw msg(`${ text }.fields as Objects[]`);
      } else {
        filter.fields.every(isFilter(`${ text }.fields`, it + 1));
      }
    } else if (!isObject(filter.fields)) {
      throw msg(`${ text }.fields as Object`);
    } else {
      isFilter(`${ text }.fields`, it + 1)(filter.fields);
    }
  };

  if (!isArray(filters)) {
    throw msg(`.filters as Array`);
  } else {
    filters.every(isFilter(`.filters[]`, 0));
  }

  if (!isInteger(id)) {
    throw msg(`.id as Integer`);
  } else if (!(id > 0)) {
    throw msg(`.id > 0`);
  }

  if (!isArray(orders)) {
    throw msg(`.orders as Array`);
  } else if (!orders.every(isObject)) {
    throw msg(`.orders[] as Object`);
  } else if (!orders.map(_ => _.$).every(isObject)) {
    throw msg(`.orders[].$ as Object`);
  } else if (!orders.map(_ => _.$.id).every(isString)) {
    throw msg(`.orders[].$.id as String`);
  } else if (!orders.map(_ => _.$.type).every(isString)) {
    throw msg(`.orders[].$.type as String`);
  } else if (!orders.map(_ => _.enabled).every(isFlag)) {
    throw msg(`.orders[].enabled in {0, 1}`);
  } else if (!orders.map(_ => _.f).every(isString)) {
    throw msg(`.orders[].f as String`);
  } else if (!orders.map(_ => _.f).every(isField)) {
    throw msg(`.orders[].f as Field`);
  } else if (!orders.map(_ => _.o).every(isString)) {
    throw msg(`.orders[].o as String`);
  } else if (!orders.map(_ => _.o).every(_ => _ === 'order_by')) {
    throw msg(`.orders[].o in {"order_by"}`);
  } else if (!orders.map(_ => _.v).every(isString)) {
    throw msg(`.orders[].v as String`);
  } else if (!orders.map(_ => _.v).every(_ => [ 'asc', 'desc', ].includes(_))) {
    throw msg(`.orders[].v in {"asc", "desc"}`);
  }

  if (!isArray(panels)) {
    throw msg(`.panels as Array`);
  } else if (!panels.every(isObject)) {
    throw msg(`.panels[] as Object`);
  } else if (!panels.map(_ => _.id).every(isString)) {
    throw msg(`.panels[].id as String`);
  } else if (!panels.map(_ => _.items).filter(isArray).every(are(isString))) {
    throw msg(`.panels[].items as String[]`);
  } else if (!panels.map(_ => _.items).filter(noArray).every(isString)) {
    throw msg(`.panels[].items as String`);
  } else if (!panels.map(_ => _.render).filter(isDefined).every(isFunction)) {
    throw msg(`.panels[].render as {Function | undefined}`);
  } else if (!panels.map(_ => _.translate).filter(isDefined).every(isString)) {
    throw msg(`.panels[].translate as String`);
  } else if (!panels.map(_ => _.xlabel).filter(isDefined).every(isString)) {
    throw msg(`.panels[].xlabel as String`);
  } else if (
    !panels.filter(_ => !isDefined(_.xlabel))
      .every(_ => isDefined(_.translate))
  ) {
    throw msg(`.panels[] .xlabel or .translate defined`);
  }

  if (!isString(path)) {
    throw msg(`.path as String`);
  }

  if (!isFunction(translate)) {
    throw msg(`.translate as Function`);
  }

  if (!isBoolean(widget)) {
    throw msg(`.widget as Boolean`);
  }

};