import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withLocalize } from "react-localize-redux";
import Preloader from "../../../../components/LoadingHOC/Preloader";
import PagePanel from "../../../tima/components/Pages/PagePanel";
import Loader from "../../../tima/components/Loader";
import {
  MagicFilterPanels,
  MagicTable,
  Mentor,
} from "../../../../components/Magic";
import { listAccountService } from "../../../../services/ListAccountDataService";

class ClosedOrdersMT5 extends Component {
  constructor (props) {
    super(props);

    this.state = {
      closedOrders: {
          data: [],
          hash: null,
      },
      filterLastChange: Date.now(),
      loaded: false,
      pages: {
        filter: 0,
        take: 50,
        total: 0,
        variance: 2,
      },
    };

    const mf = new Mentor({
      owner: this,
      serviceId: 96,
      translate: this.props.translate,
    });

    Object.defineProperty(this, 'mf', { get: () => mf, });
    this.props.mf(mf);
  }

  // eslint-disable-next-line react/sort-comp, require-await
  save = async state => new Promise(next => this.setState(state, next));

  // eslint-disable-next-line require-await
  mfChanged = async () => {
    await this.save({ filterLastChange: Date.now(), });
  };

  mfDoing = async () => {
    await this.save({ loaded: false, });
  };

  mfDone = async () => {
    await this.mfChanged();
    await this.onDataLoad();
  };

  query = () => new URLSearchParams(this.props?.location?.search);

  closedOrdersChanges = async (closedOrders) => {
      if (closedOrders.hash===this.state?.closedOrders?.hash) return `${this.constructor.name}.closedOrdersChanges: false`;
      const { data, meta, hash } = closedOrders;
      await this.save({
          closedOrders: { data, hash },
          pages: {
              ...this.state.pages,
              filter: meta?.filter,
              total: meta?.total,
          }
      });
      return `${this.constructor.name}.closedOrdersChanges: true`;
  };

  componentDidMount = async () => {
    this.mf.subscribe({
      changed: this.mfChanged,
      // changing: ,
      cleaned: this.mfDone,
      cleaning: this.mfDoing,
      loaded: this.mfDone,
      loading: this.mfDoing,
      saved: this.mfDone,
      saving: this.mfDoing,
    }, this);
    await this.mf.init({ doEmit: true, });
    listAccountService.subscribe('tradingAccountsClosedOrdersMT5', this.closedOrdersChanges, this);
    // await this.mf.emit('change', {});
    // await this.onDataLoad(); // TODO: moved to mfDone
  };

  componentWillUnmount = () => {
    this.mf.unsubscribe([
      this.mfChanged,
      this.mfDoing,
      this.mfDone,
    ], this);
    listAccountService.unsubscribe('tradingAccountsClosedOrdersMT5', this.closedOrdersChanges, this);
  };

  get closedOrdersMT5Config () {
    const { translate, } = this.props;

    return [ {
      accessChecked: true,
      key: 'TimeSetup',
      path: [ 'orders_history', 'TimeSetup', ],
      render: this.renderInlineValue,
      title: `list_accounts_table_ordersMT5_TimeSetup`,
    }, {
      accessChecked: true,
      key: 'Order',
      path: [ 'orders_history', 'Order', ],
      render: this.renderInlineValue,
      title: `list_accounts_table_ordersMT5_Order`,
    }, {
      accessChecked: true,
      key: 'Symbol',
      path: [ 'orders_history', 'Symbol', ],
      render: this.renderInlineValue,
      title: `list_accounts_table_ordersMT5_Symbol`,
    }, {
      accessChecked: true,
      key: 'Type',
      path: [ 'orders_history', 'Type', ],
      render: this.renderEnumField('core', 'Mt5OrdersHistoryTypesEnum'),
      title: `list_accounts_table_ordersMT5_Type`,
    }, {
      accessChecked: true,
      key: 'Volume1',
      path: [ 'orders_history', 'Volume1', ],
    }, {
      accessChecked: true,
      key: 'Volume2',
      path: [ 'orders_history', 'Volume2', ],
    }, {
      accessChecked: true,
      key: 'volume',
      render: this.renderNumberFixedFormat({ fieldName: 'volume' }),
      title: `list_accounts_table_ordersMT5_volume`,
    }, {
      accessChecked: true,
      key: 'PriceOrder',
      path: [ 'orders_history', 'PriceOrder', ],
      render: this.renderNumberFixedFormat({ fieldName: 'PriceOrder' }),
      title: `list_accounts_table_ordersMT5_PriceOrder`,
    }, {
      accessChecked: true,
      key: 'PriceSL',
      path: [ 'orders_history', 'PriceSL', ],
      render: this.renderNumberFixedFormat({ fieldName: 'PriceSL' }),
      title: `list_accounts_table_ordersMT5_PriceSL`,
    }, {
      accessChecked: true,
      key: 'PriceTP',
      path: [ 'orders_history', 'PriceTP', ],
      render: this.renderNumberFixedFormat({ fieldName: 'PriceTP' }),
      title: `list_accounts_table_ordersMT5_PriceTP`,
    }, {
      accessChecked: true,
      key: 'TimeDone',
      path: [ 'orders_history', 'TimeDone', ],
      render: this.renderInlineValue,
      title: `list_accounts_table_ordersMT5_TimeDone`,
    }, {
      accessChecked: true,
      key: 'State',
      path: [ 'orders_history', 'State', ],
      render: this.renderEnumField('core', 'Mt5OrdersStateEnum'),
      title: `list_accounts_table_ordersMT5_State`,
    }, {
      accessChecked: true,
      key: 'Digits',
      path: [ 'orders_history', 'Digits', ],
    }, {
      accessChecked: true,
      key: 'Comment',
      path: [ 'orders_history', 'Comment', ],
      title: `list_accounts_table_ordersMT5_Comment`,
      render: this.renderInlineValue,
    }, ].map(({
      title: t,
      xtitle: x,
      ...item
    }) => ({
      ...item,
      ...x && { title: x },
      ...t && { title: translate(t) },
    }));
  }

  renderInlineValue = (value, { item, items }) => {
    const defaultValue = '-';

    if (item?.access?.('index')) {
        return value || defaultValue;
    }
    return defaultValue;
  };

  renderNumberFixedFormat = ({ fieldName }) => (value, { item, items }) => {
    const defaultDigits = 2;
    const defaultValue = '-';

    try {
      if (item?.access?.('index')) {
        switch (fieldName) {
          case 'volume': {
            const Digits = 2; // HARD CODE for this fields in MT4/MT5
            const Volume1 = items?.['Volume1']?.valueOf;
            const Volume2 = items?.['Volume2']?.valueOf;

            return `${(+Volume1).toFixed(+Digits)} / ${(+Volume2).toFixed(+Digits)}`;
          }
          case 'PriceOrder':
          case 'PriceSL':
          case 'PriceTP':
          case 'PriceCurrent': {
            const Digits = items?.['Digits']?.valueOf ?? defaultDigits;

            return (+value).toFixed(Digits);
          }
          default: {
            return (+value).toFixed(defaultDigits);
          }
        }
      }

      return defaultValue;
    } catch (error) {
      throw new Error(`Error renderNumberFixedFormat( fieldName: ${ fieldName } )`);
    }
  };

  renderEnumField = (path, enumName) => (value, { item }) => {
    const defaultValue = '-';

    if (item?.access?.('index')) {
      const enums = this.props?.enums?.[path]?.[enumName];
      return enums?.[value] ?? defaultValue;
    }
    return defaultValue;
  };

  render = () => {
    const { closedOrders: { data }, } = this.state;
    const options = {
      config: this.closedOrdersMT5Config,
      data,
      head:   [
        'TimeSetup',
        'Order',
        'Symbol',
        'Type',
        'volume',
        'PriceOrder',
        'PriceSL',
        'PriceTP',
        'TimeDone',
        'State',
        'Comment',
      ],
    };

    return (
      <>
        <MagicFilterPanels
          mf={ this.mf }
          show={ true }
          translate={ this.props.translate }
        />

        <div className='orders-page closed-orders-page'>
          <Loader
            loaded={this.state.loaded}
            loading={(<Preloader scale={this.props.scale}/>)}
            translate={this.props.translate}
          >
            <MagicTable {...options} />

            <PagePanel
              filter={this.state.pages.filter}
              take={this.state.pages.take}
              total={this.state.pages.total}
              variance={this.state.pages.variance}
              page={this.pageId()}
              onClick={this.onPageClick}
              doText={this.onPageText}
              doTo={(pageId) => `?page=${ pageId }`}
            />
          </Loader>
        </div>
      </>
    );
  };

  pageId = () => {
    const defaultPageId = 0;

    try {
      const page = this.query().get('page');

      return Number(page) || defaultPageId;
    } catch (e) {
      return defaultPageId;
    }
  };

  pageIdAsGET = (pageId) => {
    pageId = pageId === undefined ? this.pageId() : pageId;
    const { filterId, } = this.mf;
    const result = {
      filterId,
      take: this.state.pages.take,
      skip: pageId * this.state.pages.take,
    };
    return result;
  };

  onDataLoad = async (pageId) => {
    const loaded = (data, meta, hash) => ({ pages, }) => ({
      closedOrders: { data, hash },
      pages: { ...pages, ...meta, },
    });
    await this.save({ loaded: false, });
    try {
      const pageOptions = this.pageIdAsGET(pageId);
      const { id, } = this.props;
      const { data, meta, hash } = await listAccountService.tradingAccountsClosedOrdersMT5(id, pageOptions);
      const { filter, total } = meta;
      await this.save(loaded(data, { filter, total, }, hash));  // no filter data on this page => filter: total - это была временная заплатка
    } catch (error) {
      await this.save(loaded([], { filter: 0, total: 0, }));
      error?.showErrorNotification?.();
    }
    await this.save({ loaded: true, });
  };

  onPageClick = ({ event, pageId, pageIs, }) => {
    // event.preventDefault();
    this.onDataLoad(pageId);
  };

  onPageText = (pageId, pageIs) => {
    const { translate } = this.props;
    const { current, first, prev, next, last, } = pageIs;
    const { skipped, taken, filter, total, } = pageIs;
    if (skipped || taken) {
      const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : '?';
      const text = skipped ? translate('partners_pages_items') : '';
      return skipped ? `${ text }: ${ id }` : id;
    } else if (filter || total) {
      const id = Number.isInteger(pageId) ? `${ pageId }` : '?';
      const text = translate(filter ? 'partners_pages_filtered' : 'partners_pages_total');
      return `${ text }: ${ id }`;
    } else if (first || prev || next || last) {
      return '';
    } else if (current) {
      return `${ pageId + 1}`;
    } else {
      return `${ pageId + 1}`;
    }
  };
}

export default withRouter(withLocalize(ClosedOrdersMT5));
