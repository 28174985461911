const GLOBAL_PATH_API = '/api';
const GLOBAL_PATH_CORE = `${ GLOBAL_PATH_API }/core`;
const GLOBAL_PATH_AUTH = `${ GLOBAL_PATH_CORE }/auth`;
const GLOBAL_PATH_USER_EVENTS = `${ GLOBAL_PATH_CORE }/user-events`;

export const GET_ALL_ENUMS = `${ GLOBAL_PATH_CORE }/get-all-enums`;
export const GET_USER_FORGOT_PASSWORD = `${ GLOBAL_PATH_AUTH }/user/forgot-password`;
export const GET_USER_RESET_PASSWORD = `${ GLOBAL_PATH_AUTH }/user/reset-password`;
export const GET_LANGUAGES = `${ GLOBAL_PATH_CORE }/language`;
export const GET_LANGUAGES_DROP_DOWN = `${ GLOBAL_PATH_CORE }/language/drop-down`;

export const GET_USER_COUNT_ACTIVE_EVENTS = `${ GLOBAL_PATH_USER_EVENTS }/get-count-active-events`;
export const GET_USER_EVENTS_BY_USER_PROFILE = `${ GLOBAL_PATH_USER_EVENTS }/get-by-user-profile`;
export const GET_USER_EVENT_SETTINGS = `${ GLOBAL_PATH_CORE }/user-event-settings/profile`;
export const EVENTS_MARK_ALL_VIEWED = `${ GLOBAL_PATH_CORE }/user-events/mark-all-viewed`;