import React, { Component, } from "react";
import { withRouter, Link, } from "react-router-dom";
import { withLocalize, } from "react-localize-redux";
import PropTypes from "prop-types";

import { MagicTooltip, } from "../../../../../tima/components/Magic/MagicTooltip";
import { MagicTable, } from "../../../../../../components/Magic";

import Preloader from "../../../../../../components/LoadingHOC/Preloader";
import { TotalRenumeration, CreatePartnersReferral, } from "../Popups";

const status = Object.freeze({
	"1": "active",
	"2": "archive",
	"3": "detached",
});

class ReferralsListMagicTable extends Component {
	constructor (props) {
		super(props);

		this.state = {
			showPopUp: false,
			showTotalRenumeration: false,
		};
	}

	static propTypes = {
		head: PropTypes.array.isRequired,
		mf: PropTypes.object.isRequired,
		onDataLoad: PropTypes.func.isRequired,
		// eslint-disable-next-line sort-keys
		data: PropTypes.array,
		enums: PropTypes.object,
	};

	static defaultProps = {
		data: [],
		enums: {},
		head: [],
	};

	get enums () {
		return this.props?.enums ?? {};
	}

	get mf () {
		return this.props.mf;
	}
	get params () {
		return this.props?.match?.params;
	}

	get query () {
		return new URLSearchParams(this.props?.location?.search);
	}

	get config () {
		const { translate, } = this.props;
		const prefix = "partners_referral_";

		return [
			{
				key: "core",
				path: [ "core", ],
			},
			{
				key: "partners",
				path: [ "partners", ],
			},
			{
				key: "accountName",
				path: [ "core", "account", "name", ],
			},
			{
				key: "accountSurname",
				path: [ "core", "account", "surname", ],
			},
			{
				key: "partnerName",
				path: [ "core", "partner", "name", ],
			},
			{
				key: "partnerSurname",
				path: [ "core", "partner", "surname", ],
			},
			{
				key: "partnerId",
				path: [ "partners", "partners_setup", "id", ],
			},
			{
				key: "referralId",
				orderId: "o:referralId",
				path: [ "core", "account", "id", ],
				render: this.renderInlineValue,
				title: `${ prefix }id`,
			},
			{
				key: "referralName",
				orderId: "o:referralName",
				path: [ "core", "account", "id", ],
				render: this.renderReferralName,
				title: `${ prefix }referral`,
			},
			{
				key: "referralPartnerLevelOne",
				orderId: "o:referralPartnerLevelOne",
				path: [ "core", "user", "id", ],
				render: this.renderPartnerLevelOne,
				title: `${ prefix }level_one_partner`,
			},
			{
				key: "referralStatus",
				orderId: "o:referralStatus",
				path: [ "partners", "referral_register", "status", ],
				render: this.renderReferralsStatus,
				title: `${ prefix }status`,
			},
			{
				key: "referralPartner",
				orderId: "o:referralPartner",
				path: [ "partners", "referral_register", "is_partner", ],
				render: this.renderIsPartner,
				title: `${ prefix }is_partner`,
			},
			{
				key: "referralCountry",
				orderId: "o:referralCountry",
				path: [ "core", "country", "name", ],
				render: this.renderInlineValue,
				title: `${ prefix }country`,
			},
			{
				key: "referralRegister",
				orderId: "o:referralRegister",
				path: [ "partners", "referral_register", "attachment_date", ],
				render: this.renderDate,
				title: `${ prefix }attachment_date`,
			},
			{
				key: "referralRemunerations",
				orderId: "o:referralRemunerations",
				path: [ "partners", "referral_register", ],
				render: this.renderReferralRenumerations,
				title: `${ prefix }total_remunerations`,
			},
		].map(({
			orderId: o, title: t, xtitle: x, ...item
		}) => ({
			...item,
			...o && {
				order: async () => {
					await this.mf.orderedToNext(o);
				},
				orderTo: this.mf.orderedTo(o),
			},
			...t && { title: translate(t), },
			...x && { title: x, },
		}));
	}

	// eslint-disable-next-line require-await
	save = async state => new Promise(next => this.setState(state, next));

	renderInlineValue = (value, { item, }) => {
		if (item.access("index")) {
			return value ?? 0;
		}

		return "-";
	};

	renderReferralRenumerations = (value, { item, items, }) => {
		const { popup, } = this.props;

		const data = [
			{
				core: items?.core.valueOf,
				name: "JohnDou",
				partners: items?.partners.valueOf,
			},
		];

		if (item.access("index")) {
			return (
				(
					<button
						className="total-renumeration__toggler"
						onClick={ () => popup.togglePopup(data) }
					>
						{value.all_sum}
					</button>
				) ?? "-"
			);
		}

		return "-";
	};

	renderIsPartner = (value, { item, }) => {
		const { translate, } = this.props;

		if (item.access("index")) {
			return value === 2
				? translate("symbols_remove_reject")
				: translate("symbols_remove_accept");
		}

		return "-";
	};

	renderDate = (value, { item, }) => {
		//needed to render date and time in separate lines
		if (item.access("index")) {
			if (!value) {
				return "-";
			}

			return value.split(" ").map(index => (
				<span className="table__date" key={ index }>
					{index}
				</span>
			));
		}

		return "-";
	};

	renderReferralName (value, { item, items, }) {
		const name = items?.accountName.valueOf ?? "";
		const surname = items?.accountSurname.valueOf ?? "";
		const fullName = name || surname ? `${ name } ${ surname }` : "-";

		if (
			item?.access("index") &&
			items?.accountName?.access?.("index") &&
			items?.accountSurname?.access?.("index")
		) {
			return value ? (
				<Link to={ `/clients/${ value }` }>
					<MagicTooltip
						classNameTooltip={ `tooltip tooltip--large-width` }
						content={ fullName }
						lengthCut={ 20 }
					/>
				</Link>
			) :
				"-"
			;
		} else if (
			items?.userName?.access?.("index") &&
			items?.userSurname?.access?.("index")
		) {
			return fullName;
		}
	}

	renderPartnerLevelOne = (value, { items, }) => {
		const name = items?.partnerName.valueOf ?? "";
		const surname = items?.partnerSurname.valueOf ?? "";
		const fullName = name || surname ? `${ name } ${ surname }` : "-";
		const partnerId = items.partnerId.valueOf ?? "";

		// if (item?.access('index') && items.partnerName?.access?.('index') && items?.partnerSurname?.access?.('index')) { !!! TODO: w8 for normal permissions
		return partnerId ? (
			<Link to={ `/partners/requests/partners-list/${ partnerId }` }>
				{" "}
				<MagicTooltip
					classNameTooltip={ `tooltip tooltip--large-width` }
					content={ fullName }
					lengthCut={ 20 }
				/>
			</Link>
		) :
			"-"
		;
		// } else if (items?.partnerName?.access?.('index') && items?.partnerSurname?.access?.('index')) {
		// return fullName.trim() ? fullName : '-';
		// }
	};

	renderReferralsStatus = (value, { item, }) => {
		const { translate, } = this.props;

		if(item.access("index")) {
			const statusByCode = Object.values(status).filter(
				(_status, index) => +index + 1 === +value,
			);
			const prefix = "partners_refferals_status_";

			if (!statusByCode) {
				return "-";
			}

			return translate(prefix + statusByCode);
		}

		return "-";
	};

	renderTable () {
		const {
			loaded, data, head, translate,
		} = this.props;

		if (!loaded) {
			return <Preloader />;
		}
		if (data.length === 0) {
			return <p className="mx-auto no-data">{translate("symbols_empty")}</p>;
		}

		const options = {
			config: this.config,
			data,
			head,
		};

		return (
			<div className="partners-partners__table">
				<div className="partners__table">
					<MagicTable { ...options } />
				</div>
			</div>
		);
	}
	render () {
		const {
			popup, enums, createPopup, onDataLoad, activeLanguage, translate,
			accountId,
		} = this.props;
		const { showTotalRenumeration, } = this.state;

		const options = {
		  refreshChildData: onDataLoad,
		  popUpClose: createPopup.toggleCreatePopup,
		  activeLanguage,
		  translate,
		  enums,
			accountId,
		};


		return (
			<>
				{popup.isOpen && (
					<TotalRenumeration
						allStatuses={ status }
						enums={ enums }
						popupData={ popup.data }
						showPopUp={ showTotalRenumeration }
						onClose={ async () => {
							await this.save({ showTotalRenumeration: false, });
							await popup.togglePopup();
						} }
						onDataLoad={ onDataLoad }
					/>
				)}

				{createPopup.isOpenCreatePopup && <CreatePartnersReferral {...options}/>}
				{this.renderTable()}
			</>
		);
	}
}

export default withRouter(withLocalize(ReferralsListMagicTable));