import { getSingleNewPartnerConditionList, } from "../../../services/PartnersRequestService";

import sortArrayOfObjectsByKey from "../domains/AffiliateProgramsManagement/PayoutsPartnersRemuneration/Popup/helpers/sortArrayOfObjectsByKey";

const groupAndCollectItemBySameIdAndDate = (accumulator, item) => {
	const { symbol_group_id, } = item;
	const groupKey = [ symbol_group_id, ];

	(accumulator[groupKey] ??= []).push(item);

	return accumulator;
};

const sortFTDByAscendingPayoutsRatesRanges = (a, b) => a.payout_rates_ranges_id - b.payout_rates_ranges_id;
const getEditDataForNewPartnersConditionPopup = async (conditionParameters) => {
	const formData = await getSingleNewPartnerConditionList(
		conditionParameters,
	);
	const dataForEdit = formData?.data?.partners;
	const payment_coef_revshare = sortArrayOfObjectsByKey(dataForEdit?.payment_coef_revshare ?? [], 'id').map((payment) => {
		const {
			active_clients,
			coef,
			new_active_clients,
			verifications,
			registrations,
		} = payment;

		return {
			active_clients, coef, new_active_clients, verifications, registrations,
		};
	});

	const payout_rates_revshare = sortArrayOfObjectsByKey(dataForEdit?.payout_rates_revshare ?? [], 'id').map((payment) => {
		const {
			groups_list_id,
			reward_by_point_lot,
			reward_by_volume_lot,
			reward_percent_of_commission,
			symbols_list_id,
			deals,
		} = payment;

		return {
			groups_list_id, reward_by_point_lot, reward_by_volume_lot, reward_percent_of_commission, symbols_list_id, deals,
		};
	});

	const payment_coef_inout = dataForEdit?.payment_coef_inout?.map((coef_inout) => {
		const {
			sum_inout_from,
			coef,
		} = coef_inout;

		return { sum_inout_from, coef, };
	});

	const editFormData = {
		...dataForEdit?.new_partners_conditions,
            //REVSHARE
		...dataForEdit?.extra_revshare_conditions,
		payment_coef_revshare,
		payout_rates_revshare,
		conditionParameters,

        //CPL
		referrer_country_check:
            dataForEdit?.extra_cp_conditions?.referrer_country_check,
		payout_rate_list_id:
            dataForEdit?.extra_cp_conditions?.payout_rate_list_id,
		condition_lead_appears:
            dataForEdit?.extra_cpl_conditions?.condition_lead_appears,
		payment_coef_cpl: sortArrayOfObjectsByKey(dataForEdit?.payment_coef_cpl, "id"),

        //CPA
		...dataForEdit?.new_partners_conditions.type === 1 && {
			first_deposit_confirmation_period: dataForEdit?.extra_cpa_conditions?.first_deposit_confirmation_period,
			first_deposit_fixing_hours: dataForEdit?.extra_cpa_conditions?.first_deposit_fixing_hours,
			//
			first_deposit_conditions: Object.values(dataForEdit?.first_deposit_conditions.reduce(groupAndCollectItemBySameIdAndDate, []).map(condition => condition.sort(sortFTDByAscendingPayoutsRatesRanges)).reverse()) || [],
			payment_coef_cpa: dataForEdit?.payment_coef_cpa?.sort((a, b) => a.id - b.id).map((item) => {
				return { deposits_from: item.deposits_from, coef: item.coef, };
			}),
		},

        //IN_OUT
		payout_rate_for_the_estimated_amount:
            dataForEdit?.extra_inout_conditions?.payout_rate_for_the_estimated_a,
		min_inout_reminder:
            dataForEdit?.extra_inout_conditions?.min_inout_reminder,
		payment_coef_inout: sortArrayOfObjectsByKey(payment_coef_inout, 'id'),
	};

	return editFormData;
};

export default getEditDataForNewPartnersConditionPopup;