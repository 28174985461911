import React from 'react';
import AddAccessToArray from "./AddAccessToArray";
import EditAccessToArray from "./EditAccessToArray";
import { userService } from "../../../../../services/UserDataService";

export default class AccessCheckboxPopup extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            animation_name: '',
            depth:          '',
            fade:           '',
            name:           'Search',
            left:           0,
            top:            0,
            popup:          0,
            dataField:      [],
            removeField:    [],
            addField:       [],
            listField:      [],
        };

        this.plus = React.createRef();
    }

    getDataField = async () => {
        try {
            const value = `${this.props.module}-${this.props.stateName}-${this.props.show}`;
            const { data } = await userService.getPermissionsSelect(value);
            this.setState({
                dataField: data,
            });
        } catch (error) {
            console.log(error);
        }
    };

    closePopUp = () => {
        this.setState({ animation_name: '' });
        this.setState({ depth: '' });
        this.setState({ fade: '' });
        this.setState({ popup: 0 });
    };

    openPopUp = async (e) => {
        const blockWidth = 250;
        const distanceFromBorderToTriangle = 45;
        const box = this.plus.current.getBoundingClientRect();
        this.setState({
            fade: 'fade-in',
            depth: 'below',
            popup: 1,
            animation_name: 'animate-in',
            left:           `${box.x - (blockWidth - distanceFromBorderToTriangle)}px`,
            top:            `${e.clientY - (e.clientY - e.target.offsetTop) + 30 - e.target.parentElement.parentElement.parentElement.scrollTop}px`,
            width:          "auto",
            heightPoupEdit: '365px',
            heightPopupAdd: '295px',
        });

        await this.getDataField();
    };

    renderBack = () => {
        if (this.state.popup) {
            return (
                <div onClick = { this.closePopUp } style = { { width: '100%', height: '100%', position: 'fixed', top: 0, left: 0, zIndex: 9 } } />
            );
        }
    };

    render () {
        if (!this.props.isCheckedPopup) {
            return false;
        }
        const classNamePopup = this.props.isEditPopup ? 'edit' : 'opener';
        const heightPopup = this.props.isEditPopup ? this.state.heightPoupEdit : this.state.heightPopupAdd;

        return (//.checkbox-Popup #pop-up:before - 246px setState (-99px)
            <div className = 'checkbox-popup'>
                {this.renderBack()}
                <span className = { classNamePopup } id = { this.state.depth } ref={this.plus} onClick = { this.openPopUp }/>
                {
                    this.state.dataField.length > 0 ? <section
                        className = { this.state.animation_name } id = 'pop-up'
                        style = { { left: this.state.left, top: this.state.top, width: this.state.width, height: heightPopup } }>
                        <div className = { this.state.fade } id = 'innerPopUp'>

                            <AddAccessToArray
                                addField = { this.props.addField }
                                closePopUp = { this.closePopUp }
                                dataField = { this.state.dataField }
                                getUserField = { this.props.getUserField }
                                isCheckedPopup = { this.props.isCheckedPopup }
                                item = { this.props.item }
                                module = { this.props.module }
                                show = { this.props.show }
                                stateName = { this.props.stateName }
                            />

                            <EditAccessToArray
                                dataField = { this.state.dataField }
                                getUserField = { this.props.getUserField }
                                isEditPopup = { this.props.isEditPopup }
                                item = { this.props.item }
                                module = { this.props.module }
                                removeField = { this.props.removeField }
                                returnListField = { this.returnListField }
                                show = { this.props.show }
                                stateName = { this.props.stateName }
                            />

                        </div>
                    </section> : null
                }
            </div>
        );
    }
}
