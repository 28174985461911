import React, {lazy} from 'react';

import ResetForm from "./components/AuthPage/ResetForm";
import ErrorPage from "./components/ErrorPage";

const AccessDeniedPage = lazy(() => import('./components/AccessDeniedPage'));
const ResetPassword = lazy(() => import('./components/AuthPage/ResetPassword'));

export const commonConfig = [
    { permission: null, component: <ResetPassword />, path: '/reset' },
    { permission: null, component: <AccessDeniedPage />, path: '/access' },
    { permission: null, component: <AccessDeniedPage />, path: '/reports' },
    { permission: null, component: <ResetForm />, path: '/user-reset-password/:token' },

    { component: <ErrorPage />, path: '/404' }
];
