import React from 'react';

class DiscussionBlockCommentInput extends React.Component {

    handleClickContainer = (e) => {
        e.preventDefault();

        if (e.target.children.length > 0) {
            e.target.children[e.target.children.length - 1].focus();
        }
    }

    handleInputKeyPress = (e, key) => {
        !this.props.autoFocus && this.props.setAutoFocusTrue();

        const moveLeft = () => {
            while (e.target = e.target.previousElementSibling) {
                if (e.target.tagName.toLowerCase() === "input") {
                    e.target.focus();
                    e.target.setSelectionRange(e.target.value.length, e.target.value.length);
                    break;
                }
            }
        };

        const moveRight = () => {
            while (e.target = e.target.nextElementSibling) {
                if (e.target.tagName.toLowerCase() === "input") {
                    e.target.focus();
                    e.target.setSelectionRange(0, 0);
                    break;
                }
            }
        };

        // right arrow
        if (e.keyCode === 39 && e.target.value.length === e.target.selectionStart) {
            moveRight();
        }

        // left arrow
        if (e.keyCode === 37 && e.target.selectionStart === 0) {
            moveLeft();
        }

        // backspace
        if (e.keyCode === 8 && e.target.selectionStart === 0) {
            const prev = e.target.previousElementSibling;

            if (prev && prev.tagName.toLowerCase() === "span") {
                moveLeft();
                this.props.handleInputChange({ target: { value: '' }}, key - 1);
            }
        }

        // delete
        if (e.keyCode === 46 && e.target.value.length === e.target.selectionStart) {
            const next = e.target.nextElementSibling;

            if (next && next.tagName.toLowerCase() === "span") {
                this.props.handleInputChange({ target: { value: '' }}, key + 1);
            }
        }
    }

    renderFormated = () => {
        const comment = this.props?.comment;
        const rx = /(#USER_ID:\d+#)/;

        if (!rx.test(comment)) {
            return (
                <input
                    autoComplete = 'off'
                    autoFocus = { this.props.autoFocus }
                    name = 'description'
                    style = { { width: "100%" } }
                    type = 'text'
                    value = { comment }
                    onChange = { this.props.setComment }
                />
            );
        }

        const words = comment.split(rx);

        return (
            <>
                {words.map((item, key) => {
                    if (rx.test(item)) {
                        const id = item.split(/\#USER_ID:([0-9]+)\#/)[1];
                        const user = this.props.findUserById(id);

                        return (
                            <span className = 'user' data-user-id = { id } key = { key }>
                               {user?.name ?? ''} {user?.surname ?? ''}
                            </span>
                        );
                    }

                    return (
                        <input
                            autoComplete = 'off'
                            autoFocus = { key === words.length - 1 }
                            key = { key }
                            style = { { width: item ? `${item.length + 3}ch` : '1ch' } }
                            type = 'text'
                            value = { words[key] }
                            onChange = { (e) => this.props.handleInputChange(e, key) }
                            onKeyDown = { (e) => this.handleInputKeyPress(e, key) }
                        />
                    );
                })}
            </>
        );
    }

    render () {
        return (
            <div className = 'custom-input__wrapper'>
                <div className = 'custom-input__container' onClick = { (e) => this.handleClickContainer(e) }>
                    {this.renderFormated()}
                </div>
            </div>
        );
    }
}

export default DiscussionBlockCommentInput;
