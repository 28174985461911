import React, { Component, cloneElement } from 'react';
import Preloader from './Preloader';
import { isEmpty, isUndefined, isNull } from "lodash";
export default class LoadingHOC extends Component {
    constructor(props){
        super(props);

        this.state = {
            isLoading: false,
            data: null
        }
    }

    componentDidMount(){
        //transfer to func without Redux
        if(this.props.callFunc){
            this.props.callFunc()
                .then(repository => {
                    this.setState({ isLoading: true, data: repository.data.data });
                });
        }
    }

    //transfer to Props from Redux
    static getDerivedStateFromProps(nextProps) {
        if(isUndefined(nextProps.callReduxProps) || isNull(nextProps.callReduxProps) || isEmpty(nextProps.callReduxProps) || !nextProps.callReduxProps){
            return false;
        }
        return { isLoading: true, data: nextProps.callReduxProps};
    }

    render(){
        if (this.state.isLoading) {
            return cloneElement(this.props.children, { dataAfterLoading: this.state.data });
        }

        return (<Preloader scale = { this.props.scale } />);
    }
}