import React, { Component, } from "react";
import PropTypes from "prop-types";
import "./styles.scss";

class Textarea extends Component {
	constructor (props) {
		super(props);

		const { value, } = props;

		this.textareaRef = React.createRef();
		this.cursor = 0;

		this.state = {
			value,
		};
	}
	componentDidUpdate () {
		this._setCursorPositions();
	}

	UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
		if (nextProps.value !== this.state.value) {
			this.setState({ value: nextProps.value, });
		}
	};

	_setCursorPositions = () => {
		//reset the cursor position for input
		this.textareaRef.current.selectionStart = this.cursor;
		this.textareaRef.current.selectionEnd = this.cursor;
	};

	className = () => this.props.className;
	value = () => {
		return this.state.value || "";
	};

	async save (state) {
		const executor = resolve => this.setState(state, resolve);

		return new Promise(executor);
	}

	async onValid (value) {
		const { onValid, } = this.props;

		try {
			onValid && await onValid(value);

			return true;
		} catch (error) {}

		return false;
	}

	async onChange (event) {
		const { onChange, name, onValid, } = this.props;

		event.preventDefault();
		const { target, } = event;
		const { selectionStart, value, } = target;

		const valid = await this.onValid(value);

		if (valid) {
			this.cursor = selectionStart;
			onChange && await onChange({ target: { value, name, }, });
		} else {
			//when onValid fails - cursor jumps to the end as textarea default behavior, code below prevents cursor from moving
			this.cursor = selectionStart - 1;
			onChange && await onChange({ target: { value: this.props.value, name, }, });
		}
	}

	render = () => {
		const {
			label,
			isDisabled,
			maxLength,
			placeholder,
			textareaWrapperClassName,
		} = this.props;

		return (
			<>
				{label && <label className="description-label">{label}</label>}
				<div
					className={ `magic-input input__field magic-textarea-wrapper ${ textareaWrapperClassName }` }
				>
					<textarea
						className={ this.className() }
						disabled={ isDisabled }
						maxLength={ maxLength }
						placeholder={ placeholder ?? "" }
						ref={ this.textareaRef }
						value={ this.value() }
						onChange={ e => this.onChange(e) }
					/>
				</div>
			</>
		);
	};
}

Textarea.propTypes = {
	className: PropTypes.string.isRequired,
	isDisabled: PropTypes.bool,
	notToFocus: PropTypes.bool,
	textareaWrapperClassName: PropTypes.string,
	value: PropTypes.string,
};

Textarea.defaultProps = {
	className: `magic-textarea`,
	value: `Type text in my magic-textarea!`,
	isDisabled: false,
	textareaWrapperClassName: "",
	notToFocus: true,
};

export default Textarea;
export { Textarea, };