import React from "react";
import dateFns from "date-fns";
import { connect } from "react-redux";
import DataStorage from "../../../../helpers/DataStorage";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";

class RenderMonth extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            currentMonth: this.props.currentMonth ? this.props.currentMonth : new Date(),
            selectedDate: this.props.selectedDate ? this.props.selectedDate : new Date(),
        };
    }

    UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
        this.setState({
            currentMonth: nextProps.currentMonth,
            selectedDate: nextProps.selectedDate,
        });
    }

    render () {
        const { currentMonth, selectedDate } = this.state;

        const monthStart = dateFns.startOfMonth(currentMonth);
        const monthEnd = dateFns.endOfMonth(monthStart, { weekStartsOn: 1 });

        const dateFormat = "D";
        const weekFormat = "dd";

        const days = [];

        let day = monthStart;

        let formattedDate = "";

        let formattedWeek = "";

        const style = {
            width:     `${100 / monthEnd.getDate()}%`,
            flexBasis: `${100 / monthEnd.getDate()}%`,
        };

        let localeLang = DataStorage.getData('locale');

        localeLang = localeLang !== null && localeLang !== undefined ? localeLang : 'en';
        localeLang = localeLang === 'uk' ? "ru" : localeLang;

        const dateFnsLocale = require(`date-fns/locale/${localeLang}`);

        while (day <= monthEnd) {
            formattedDate = dateFns.format(day, dateFormat);
            formattedWeek = dateFns.format(day, weekFormat, { locale: dateFnsLocale });
            days.push(
                <div
                    className = { `line-day ${
                        dateFns.isSaturday(day) || dateFns.isSunday(day)
                            ? "weekend"
                            : dateFns.isSameDay(day, selectedDate) ? "current" : ""
                        }` }
                    key = { day } style = { style }>
                    <div className = 'line-day-inner'>
                        <span className = 'number'>{formattedDate}</span>
                        <span className = 'week'>{formattedWeek}</span>
                    </div>
                </div>
            );
            day = dateFns.addDays(day, 1);
        }
                return (
                    <React.Fragment>
                        {days}
                    </React.Fragment>
                );
    }
}

export default withLocalize(withRouter(connect(
)(RenderMonth)));
