import React from "react";

class DelateTaskPopup extends React.Component {
    constructor (props) {
        super(props);

    }

    render () {
        return (
            <div className = 'lead-modal'>
                <div
                    className = 'close-button' onClick = { () => {
                        this.props.toogle("");
                    } }>
                    <i className = 'la la-close' />
                </div>
                <h1 className = 'aligncenter'>{ this.props.translate(`tasks_delete_are_u_sure`) }</h1>
                <span>{ this.props.translate(`tasks_delete_marked_4_${this.props.type}`) }</span>
                <div className = 'button-area'>
                    <div className = 'button bright-blue' onClick = { this.props.delete }>{ this.props.translate(`tasks_delete_yes`) }</div>
                    <div
                        className = 'button white shadowed' onClick = { () => {
                            this.props.toogle("");
                        } }>{ this.props.translate(`tasks_delete_no`) }</div>
                </div>
            </div>
        );
    }
}

export default DelateTaskPopup;
