import { PropTypes, } from 'prop-types';
import React, { Component } from 'react';
import leadNotifications from "../../../../../images/lead-notifications.png";
import ManagerNotificationRowPush from "./ManagerNotificationRowPush";
import ManagerNotificationRowEmail from "./ManagerNotificationRowEmail";

class ManagerNotificationRow extends Component {

    static propTypes = {
        item: PropTypes.object.isRequired,
        profile: PropTypes.object.isRequired,
    };

    constructor(props)
    {
        super(props);

        this.state = {
            profile: props.profile,
            item: props.item,
        };
    }

    save = async state => new Promise(next => this.setState(state, next));

    updateItem = async (data) => {
        await this.save(({ item }) => ({
            item: {
                ...item,
                id: data.data.id,
                push: data.data.push,
                email: data.data.email
            }
        }));
    }

    render()
    {
        return (
            <div className = 'my-profile-notification-wrap__notification'>
                <div className = 'my-profile-notification-wrap__field'>
                    <img alt = 'lead' src = { leadNotifications } />
                    <p>{this.props.item.name}</p>
                </div>
                <div className = 'my-profile-notification-wrap__buttons'>
                    <ManagerNotificationRowPush
                        profile={this.state.profile}
                        item={this.state.item}
                        updateItem={this.updateItem}
                    />
                    <ManagerNotificationRowEmail
                        profile={this.state.profile}
                        item={this.state.item}
                        updateItem={this.updateItem}
                    />
                </div>
            </div>
        )
    }
}

export default ManagerNotificationRow;
