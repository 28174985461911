import {
	itemsBuild,
	itemsDrain,
	itemsResort,
	CFG_ITEM_TYPE,
} from '../Config';

const f_type = {
	'drop-down': false,
	'o=': [ '=', '!=', ],
};

const f_status = {
	'drop-down': false,
	'o=': [ '=', '!=', ],
};

export default {
	id: 190,
	...[

				// filters
		...[ {
			f: 'partners.new_partners_conditions.id',
			id: 'f:id',
		}, {
			f: 'partners.new_partners_conditions.name',
			id: 'f:name',
		},
		{
			f: 'partners.new_partners_conditions.level',
			id: 'f:level',
		}, {
			f: 'partners.new_partners_conditions.type',
			id: 'f:type',
			...f_type,
		}, {
			f: 'partners.new_partners_conditions.period',
			id: 'f:period',
		}, {
			f: 'partners.new_partners_conditions.latency',
			id: 'f:latency',
		}, {
			f: 'partners.extra_cp_conditions.referrer_country_check',
			id: 'f:referrer_country_check',
		}, {
			f: 'partners.new_partners_conditions.status',
			id: 'f:status',
			...f_status,
		}, {
			f: 'partners.new_partners_conditions.created_at',
			id: 'f:created',
		}, {
			f: 'partners.new_partners_conditions.updated_at',
			id: 'f:updated',
		}, ]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

				// orders
		...[ {
			f: 'partners.new_partners_conditions.id',
			id: 'o:id',
		}, {
			f: 'partners.new_partners_conditions.name',
			id: 'o:name',
		}, {
			f: 'partners.new_partners_conditions.level',
			id: 'o:level',
		}, {
			f: 'partners.new_partners_conditions.type',
			id: 'o:type',
		}, {
			f: 'partners.new_partners_conditions.period',
			id: 'o:period',
		}, {
			f: 'partners.new_partners_conditions.latency',
			id: 'o:latency',
		}, {
			f: 'partners.extra_cp_conditions.referrer_country_check',
			id: 'o:referrer_country_check',
		}, {
			f: 'partners.new_partners_conditions.status',
			id: 'o:status',
		}, {
			f: 'partners.new_partners_conditions.created_at',
			id: 'o:created',
		}, {
			f: 'partners.new_partners_conditions.updated_at',
			id: 'o:updated',
		}, ]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

				// panels
		...[ {
			ii: 'f:id',
			t: 'new_partners_condition_id',
		}, {
			ii: 'f:name',
			t: 'new_partners_condition_name',
		}, {
			ii: 'f:level',
			t: 'new_partners_condition_level',
		}, {
			ii: 'f:type',
			t: 'new_partners_condition_type',
		}, {
			ii: 'f:period',
			t: 'new_partners_condition_calculation_period',
		}, {
			ii: 'f:latency',
			t: 'new_partners_condition_payout_delay_filter',
		}, {
			ii: 'f:referrer_country_check',
			t: 'new_partners_condition_country_determination',
		}, {
			ii: 'f:status',
			t: 'new_partners_condition_status',
		}, {
			ii: 'f:created',
			t: 'new_partners_condition_created_at_filter',
		}, {
			ii: 'f:updated',
			t: 'new_partners_condition_updated_at_filter',
		}, ]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

	]
		.map(itemsBuild)
		.reduce(itemsDrain, [])
		.reduce(itemsResort, {}),

	path: 'partners/new-partners-conditions',
	widget: false,
};