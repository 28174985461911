import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import { Mentor } from '../../../components/Magic';
import { marketingService } from '../../../services/MarketingDataService';
import Loader from '../../tima/components/Loader';
import Preloader from '../../../components/LoadingHOC/Preloader';
import MarketingSubChannelsMagicTable from './Table/MarketingSubChannelsMagicTable';
import PagePanel from '../../tima/components/Pages';
import { withRouter } from 'react-router-dom';
import ModalUtmCreateAndUpdate from './Modal/ModalUtmCreateAndUpdate';
import ModalUtmBindSubchannelToChannel from './Modal/ModalUtmBindSubchannelToChannel';
import NotificationService from '../../../services/NotificationService';
import PermissionService from '../../../services/PermissionService';

class MarketingSubChannels extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isAddModal: false,
      isAddModalShown: false,
      isBindModalShown: false,
      isSameSubChannels: false,
      loaded: false,
      utmMedium: {
        data: [],
        hash: null
      },
      pages: {
        filter:   0,
        total:    0,
        take:     20,
        variance: 2,
      },
      errors: [],
      sameSubChannels: [],
    };

    const mf = new Mentor({
      owner: this,
      serviceId: 999,
      translate: this.props.translate,
    });

    Object.defineProperty(this, 'mf', { get: () => mf, });
    this.props.mf(mf);
  };

  get utmMediumConfig () {
    return [ {
        key: 'utmMedium',
        path: [ 'statistics', 'utm_medium', ],
    }, ];
  };


  save = async state => new Promise(next => this.setState(state, next));

  mfDoing = async () => {
    await this.save({ loaded: false, });
  };

  mfDone = async () => {
    await this.onDataLoad();
  };

  subChannelsChanges = async ({data, hash, options}) => {
    if (hash===this.state?.utmMedium?.hash) {
      return `${ this.constructor.name }.subChannelsChanges: false`;
    } else if (options.filterId!==this.state?.utmMedium?.options?.filterId) {
      return `${this.constructor.name}.subChannelsChanges: false`;
    } else if (options.take!==this.state?.utmMedium?.options?.take) {
      return `${this.constructor.name}.subChannelsChanges: false`;
    } else if (options.skip!==this.state?.utmMedium?.options?.skip) {
      return `${this.constructor.name}.subChannelsChanges: false`;
    }

    await this.save({
      utmMedium: {
        data: data.data,
        hash,
        options,
      },
      pages: {
        ...this.state.pages,
        filter: data?.meta?.filter,
        total: data?.meta?.total,
      },
    });
    return `${this.constructor.name}.subChannelsChanges: true`;
  };

  componentDidMount = async () => {
    marketingService.subscribe('utmMedium', this.subChannelsChanges, this);
    this.mf.subscribe({ loaded: this.mfDone, }, this);
    await this.mf.init({ doEmit: true, });
    await this.getLastNumber();
  };

  componentWillUnmount () {
    marketingService.unsubscribe('utmMedium', this.subChannelsChanges, this);
    this.mf.unsubscribe({
      loaded: this.mfDone,
    }, this);
  };

  getLastNumber = async () => {
    await marketingService.getLastUtmMediumNumber().then((data) => {
      this.save({  lastNumberPlusOne: data.data + 1, })
    });
  };

  utmAddMedium = async ({ description, ...data }) => {
    try {
      const dataNew = {
        ...data,
        ...!description
          ? { SET_NULL__description: "1", }
          : { description, },
      };
      await marketingService.utmAddMedium(this.state.utmMedium.data.id, dataNew);
      await this.onDataLoad();
      await this.save({ isAddModalShown: false });
    } catch (error) {
      await this.setErrorsFromServer(error);
      console.log(error)
    }
  };

  addButtons = () => {
    const conf = PermissionService.calc(this.utmMediumConfig);
    const addBtnPermission = conf?.utmMedium?.access('store') ?? false;

     if (addBtnPermission) {
         return (
          <div className="marketing-buttons">
            <div className="marketing-buttons_btns">
                    <button
                      onClick={() => this.save({
                        isBindModalShown: true,
                      })}
                      className="marketing-buttons_btns-btn marketing-buttons_btns-btn-join">
                      {this.props.translate(`marketing_add_channel_link`)}
                    </button>
                  </div>
            <div className="marketing-buttons_btns">
              <button
                className="marketing-buttons_btns-btn marketing-buttons_btns-btn-add"
                onClick={async () => {
                  await this.getLastNumber();
                  await this.save({ isAddModalShown: true, isAddModal: true, isSameSubChannels: true })
                  }}>
                {this.props.translate(`marketing_add_channel`)}
              </button>
          </div>
        </div>
         )
     }
  };

  searchSameSubChannels = (text) => {
    marketingService.searchUtmMediumCharAt(text).then(async (data) => {
      await this.save({
        sameSubChannels: data.data
      })
    })
  };

  setErrorsFromServer = async (error) => {
    NotificationService.errors({...error?.response?.data, time: 25000});
    let errors = Object?.keys?.(error?.response?.data?.errors)?.map(e => e?.split('.')?.[0]) ?? [];
    await this.save({ errors });
  };

  clearErrorClass = async (name) => {
    await this.save?.(state => ({errors: [...state?.errors?.filter(e => e !== name)],}));
  };

  render = () => {
    let data = this.state.utmMedium.data;

    return (
      <div className='content-block'>
        <div className='table-wrapper'>
          <div className='position-relative'>
            {this.state.isAddModalShown === true ? (
              <ModalUtmCreateAndUpdate
                data={this.state.data}
                enums={this.props.enums}
                errors={this.state.errors}
                viewOnly={[]}
                searchSameSubChannels={this.searchSameSubChannels}
                sameSubChannels={this.state.sameSubChannels}
                isSameSubChannels={this.state.isSameSubChannels}
                clearError={this.clearErrorClass}
                titleKey={{
                  form: `marketing_add_new_subchannel`,
                  name: `statistics_utm_medium_name`,
                  number: `statistics_utm_medium_number`,
                  description: `statistics_utm_medium_description`,
                  status: `statistics_utm_medium_status`,
                }}
                lastNumberPlusOne={this.state.lastNumberPlusOne}
                getLastNumber={this.getLastNumber}
                isAddModal={this.state.isAddModal}
                onClose={() => this.save({ isAddModalShown: false })}
                onDataLoad={() => this.onDataLoad()}
                onSave={this.utmAddMedium}
              />
            ) : null}
            {this.state.isBindModalShown === true ? (
              <ModalUtmBindSubchannelToChannel
                  enums={this.props.enums}
                  errors={this.state.errors}
                  clearError={this.clearErrorClass}
                  onClose={() => {
                    this.save({ isBindModalShown: false })
                    this.onDataLoad()
                  }}
              />
            ): null}
            <Loader
              loaded={this.state.loaded}
              loading={(<Preloader scale={this.props.scale}/>)}
            >
                {this.addButtons()}
              <MarketingSubChannelsMagicTable
                data={data}
                enums={this.props?.enums}
                onDataLoad={() => this.onDataLoad()}
              />
              <PagePanel
                filter={this.state.pages.filter}
                take={this.state.pages.take}
                total={this.state.pages.total}
                variance={this.state.pages.variance}
                page={this.pageId()}
                onClick={this.onPageClick}
                doText={this.onPageText}
                doTo={(pageId) => `?page=${ pageId }`}
              />
            </Loader>
          </div>
        </div>
      </div>
    )
  };

  pageId = () => {
    const { query } = this.props;
    const page = query.get('page');
    return Number(page) || 0;
  };

  pageIdAsGET = (pageId) => {
    pageId = pageId === undefined ? this.pageId() : pageId;
    const { filterId ,} = this.mf;
    return {
      filterId,
      take: this.state.pages.take,
      skip: pageId * this.state.pages.take,
    };
  };

   onDataLoad = async (pageId) => {
    const pageOptions = this.pageIdAsGET(pageId);
    await this.save({
      loaded: false,
      utmMedium: { ...this.state.utmMedium, options: pageOptions, },
    });
    try {
      await marketingService.utmMedium(pageOptions);
    } catch (error) {
      console.log("error: ", error);
      await this.save({ utmMedium: {
          data: [],
          hash: null,
          options: {},
        },
        pages: { ...this.state.pages, filter: 0, total: 0, }});
    }
    await this.save({ loaded: true, });
  };

  onPageClick = ({ pageId }) => {
    this.onDataLoad(pageId);
  };
}

export default withRouter(withLocalize(MarketingSubChannels));
