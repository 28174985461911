import { DataService } from './DataService';
import { hash as hashData } from './CachingService';
import { get, put, post } from '../components/Magic';
import {
  GET_MARKETING_CHANNEL_CHAR_AT,
  GET_MARKETING_CHANNELS,
  GET_MARKETING_PRODUCT_CHAR_AT,
  GET_MARKETING_PRODUCTS,
  GET_MARKETING_SUBCHANNELS,
  GET_MARKETING_SUBCHANNEL_WITH_SOURCE_CHAR_AT,
  GET_MARKETING_SUBCHANNEL_WITHOUT_SOURCE_CHAR_AT,
  GET_MARKETING_CHANNELS_LAST_NUMBER,
  GET_MARKETING_SUBCHANNELS_LAST_NUMBER,
  GET_MARKETING_PRODUCTS_LAST_NUMBER,
  POST_MARKETING_SUBCHANNEL_TO_CHANNEL,
  PUT_MARKETING_CHANNELS,
  POST_MARKETING_CHANNELS,
  PUT_MARKETING_SUBCHANNELS,
  POST_MARKETING_SUBCHANNELS,
  POST_MARKETING_UTM_LINK,
  PUT_MARKETING_PRODUCTS,
  POST_MARKETING_PRODUCTS, 
  GET_MARKETING_SUBCHANNEL_CHAR_AT,
  GET_ACCOUNT_UTM_LINK,
} from '../apiRoutes'

class MarketingDataService extends DataService {
  constructor () {
    super([
      'utmSource',
      'utmMedium',
      'utmCampaign',
      'updateUtmCampaign',
      'updateUtmSource',
      'addUtmSource',
      'updateUtmMedium',
      'utmAddMedium',
      'utmBindMediumToSource',
      'searchUtmSourceCharAt',
      'searchUtmMediumWithUtmSourceCharAt',
      'utmAddProduct',
      'searchUtmCampaignCharAt',
      'utmGenerateUtmLink',
      'searchUtmMediumWithOutUtmSourceCharAt',
      'getLastUtmSourceNumber',
      'getLastUtmMediumNumber',
      'getLastUtmCampaignNumber',
      'getUtmSources',
      'getUtmMediumsWithOutSource',
      'getUtmMediumsWithSource',
      'getUtmCampaigns',
      'searchUtmMediumCharAt',
      'accountUtmLink',
    ]);
    Object.freeze(this);
  };

  async utmSource ({ filterId, take, skip }) {
    const url = `${ GET_MARKETING_CHANNELS }`;
    const data = await get(url, { filterId, take, skip });
    const hash = await hashData(data);
    const channels = { data, hash, options: { filterId, take, skip, }, };
    await this.emit('utmSource', channels);

    return channels;
  };

  async updateUtmSource (channelId, formData) {
    const url = `${ PUT_MARKETING_CHANNELS }/${channelId}`;
    const data = await put(url, formData);
    const hash = await hashData(data);
    const updateChannels = { data, hash, channelId, formData, };
    await this.emit('updateUtmSource', updateChannels);

    return updateChannels;
  };

  async addUtmSource (channelId, formData) {
    const url = `${ POST_MARKETING_CHANNELS }`;
    const data = await post(url, formData);
    const hash = await hashData(data);
    const addChannel = { data, hash, channelId, formData, };
    await this.emit('addUtmSource', addChannel);

    return addChannel;
  };

  async utmMedium ({ filterId, take, skip }) {
    const url = `${ GET_MARKETING_SUBCHANNELS }`;
    const data = await get(url, { filterId, take, skip });
    const hash = await hashData(data);
    const subchannels = { data, hash, options: { filterId, take, skip, }, };
    await this.emit('utmMedium', subchannels);

    return subchannels;
  };

  async updateUtmMedium (channelId, formData) {
    const url = `${ PUT_MARKETING_SUBCHANNELS }/${channelId}`;
    const data = await put(url, formData);
    const hash = await hashData(data);
    const updateSubChannels = { data, hash, channelId, formData, };
    await this.emit('updateUtmMedium', updateSubChannels);

    return updateSubChannels;
  };

  async utmAddMedium (subChannelId, formData) {
    const url = `${ POST_MARKETING_SUBCHANNELS }`;
    const data = await post(url, formData);
    const hash = await hashData(data);
    const addSubchannel = { data, hash, subChannelId, formData, };
    await this.emit('utmAddMedium', addSubchannel);

    return addSubchannel;
  };

  async utmBindMediumToSource ({ utm_medium_id, utm_source_id }) {
    const url = `${ POST_MARKETING_SUBCHANNEL_TO_CHANNEL }`;
    const data = await post(url, { utm_medium_id, utm_source_id });
    const hash = await hashData(data);
    const utmBindMediumToSource = { data, hash, utm_medium_id, utm_source_id, };
    await this.emit('utmBindMediumToSource', utmBindMediumToSource);

    return utmBindMediumToSource;
  };

  async utmGenerateUtmLink (formData) {
    const url = `${ POST_MARKETING_UTM_LINK }`;
    const data = await post(url, formData);
    const hash = await hashData(data);
    const generateUtmSourceMediumCampaign = { data, hash, formData, };
    await this.emit('utmGenerateUtmLink', generateUtmSourceMediumCampaign);

    return generateUtmSourceMediumCampaign;
  };

  async getUtmSources() {
    const url = `${ GET_MARKETING_CHANNEL_CHAR_AT }`;
    const data = await get(url);
    const hash = await hashData(data);
    const getUtmSources = { data, hash, };
    await this.emit('getUtmSources', getUtmSources);

    return getUtmSources;
  };

  async getUtmMediumsWithOutSource({ utm_source_id }) {
    const url = new URL(GET_MARKETING_SUBCHANNEL_WITHOUT_SOURCE_CHAR_AT, location.href);
    url.searchParams.set('utm_source_id', utm_source_id);
    const data = await get(url);
    const hash = await hashData(data);
    const getUtmMediumsWithOutSource = { data, hash, utm_source_id };
    await this.emit('getUtmMediumsWithOutSource', getUtmMediumsWithOutSource);

    return getUtmMediumsWithOutSource;
  };

  async getUtmMediumsWithSource({ utm_source_id }) {
    const url = new URL(GET_MARKETING_SUBCHANNEL_WITH_SOURCE_CHAR_AT, location.href);
    url.searchParams.set('utm_source_id', utm_source_id);
    const data = await get(url);
    const hash = await hashData(data);
    const getUtmMediumsWithSource = { data, hash, utm_source_id };
    await this.emit('getUtmMediumsWithSource', getUtmMediumsWithSource);

    return getUtmMediumsWithSource;
  };


  async getUtmCampaigns() {
    const url = `${ GET_MARKETING_PRODUCT_CHAR_AT }`;
    const data = await get(url);
    const hash = await hashData(data);
    const getUtmCampaigns = { data, hash, };
    await this.emit('getUtmCampaigns', getUtmCampaigns);

    return getUtmCampaigns;
  };

  async searchUtmSourceCharAt(letter) {
    const url = new URL(GET_MARKETING_CHANNEL_CHAR_AT, location.href);
    url.searchParams.set('like', letter);
    const data = await get(url);
    const hash = await hashData(data);
    const searchUtmSourceCharAt = { data, hash, letter, };
    await this.emit('searchUtmSourceCharAt', searchUtmSourceCharAt);

    return searchUtmSourceCharAt;
  };

  async searchUtmMediumCharAt(letter) {
    const url = new URL(GET_MARKETING_SUBCHANNEL_CHAR_AT, location.href);
    url.searchParams.set('like', letter);
    const data = await get(url);
    const hash = await hashData(data);
    const searchUtmMediumCharAt = { data, hash, letter, };
    await this.emit('searchUtmMediumCharAt', searchUtmMediumCharAt);

    return searchUtmMediumCharAt;
  };

  async searchUtmMediumWithUtmSourceCharAt({ utm_source_id, letter }) {
    const url = new URL(GET_MARKETING_SUBCHANNEL_WITH_SOURCE_CHAR_AT, location.href);
    url.searchParams.set('utm_source_id', utm_source_id);
    url.searchParams.set('like', letter);
    const data = await get(url, { utm_source_id });
    const hash = await hashData(data);
    const searchUtmMediumWithUtmSourceCharAt = { data, hash, utm_source_id, letter, };
    await this.emit('searchUtmMediumCharAt', searchUtmMediumWithUtmSourceCharAt);

    return searchUtmMediumWithUtmSourceCharAt;
  };

  async searchUtmMediumWithOutUtmSourceCharAt({ utm_source_id, letter }) {
    const url = new URL(GET_MARKETING_SUBCHANNEL_WITHOUT_SOURCE_CHAR_AT, location.href);
    url.searchParams.set('utm_source_id', utm_source_id);
    url.searchParams.set('like', letter);
    const data = await get(url, { utm_source_id });
    const hash = await hashData(data);
    const searchUtmMediumWithOutUtmSourceCharAt = { data, hash, utm_source_id, letter, };
    await this.emit('searchUtmMediumWithOutUtmSourceCharAt', searchUtmMediumWithOutUtmSourceCharAt);

    return searchUtmMediumWithOutUtmSourceCharAt;
  };

  async searchUtmCampaignCharAt(letter) {
    const url = new URL(GET_MARKETING_PRODUCT_CHAR_AT, location.href);
    url.searchParams.set('like', letter);
    const data = await get(url);
    const hash = await hashData(data);
    const searchSubChannelCharAt = { data, hash, letter, };
    await this.emit('searchUtmCampaignCharAt', searchSubChannelCharAt);

    return searchSubChannelCharAt;
  };

  async utmCampaign ({ filterId, take, skip }) {
    const url = `${ GET_MARKETING_PRODUCTS }`;
    const data = await get(url, { filterId, take, skip });
    const hash = await hashData(data);
    const products = { data, hash, options: { filterId, take, skip, }, };
    await this.emit('utmCampaign', products);

    return products;
  };

  async utmAddProduct (subChannelId, formData) {
    const url = `${ POST_MARKETING_PRODUCTS }`;
    const data = await post(url, formData);
    const hash = await hashData(data);
    const addProduct = { data, hash, subChannelId, formData, };
    await this.emit('utmAddProduct', addProduct);

    return addProduct;
  };

  async updateUtmCampaign (channelId, formData) {
    const url = `${ PUT_MARKETING_PRODUCTS }/${channelId}`;
    const data = await put(url, formData);
    const hash = await hashData(data);
    const updateProducts = { data, hash, channelId, formData, };
    await this.emit('updateUtmCampaign', updateProducts);

    return updateProducts;
  };

  async getLastUtmSourceNumber () {
    const url = `${ GET_MARKETING_CHANNELS_LAST_NUMBER }`;
    const data = await get(url);
    const hash = await hashData(data);
    const getLastUtmSourceNumber = { data, hash, };
    await this.emit('getLastUtmSourceNumber', getLastUtmSourceNumber);

    return getLastUtmSourceNumber;
  };

  async getLastUtmMediumNumber () {
    const url = `${ GET_MARKETING_SUBCHANNELS_LAST_NUMBER }`;
    const data = await get(url);
    const hash = await hashData(data);
    const getLastUtmMediumNumber = { data, hash, };
    await this.emit('getLastUtmMediumNumber', getLastUtmMediumNumber);

    return getLastUtmMediumNumber;
  };

  async getLastUtmCampaignNumber () {
    const url = `${ GET_MARKETING_PRODUCTS_LAST_NUMBER }`;
    const data = await get(url);
    const hash = await hashData(data);
    const getLastUtmCampaignNumber = { data, hash, };
    await this.emit('getLastUtmCampaignNumber', getLastUtmCampaignNumber);

    return getLastUtmCampaignNumber;
  };

  async accountUtmLink ({ filterId, take, skip }) {
    const url = `${ GET_ACCOUNT_UTM_LINK }`;
    const data = await get(url, { filterId, take, skip });
    const hash = await hashData(data);
    const accountUtmLink = { data, hash, options: { filterId, take, skip, }, };
    await this.emit('accountUtmLink', accountUtmLink);

    return accountUtmLink;
  };
}

export const marketingService = new MarketingDataService();
