export const NewPartnersConditionsListStatus = Object.freeze({
	NEW: 1,
	ACTIVE: 2,
	CLOSED: 3,
});
export const newPartnersConditionLevel = Object.freeze({
	SINGLE_LEVEL: 1,
	MULTI_LEVEL: 2,
});

export const newPartnersListConditions = Object.freeze({
	CPA: 1,
	CPL: 2,
	IN_OUT: 3,
	REVSHARE: 4,
});
export const newPartnersPeriodList = Object.freeze({
	DAY: 1,
	WEEK: 2,
	MONTH: 3,
	QUARTER: 4,
	YEAR: 5,
});
export const newPartnersCountryDetermination = Object.freeze({
	BY_PHONE_NUMBER: 1,
	BY_CITIZENSHIP: 2,
});

export const newPartnersLeadAppearConditionList = Object.freeze({
	BY_PHONE_NUMBER: 1,
	BY_EMAIL_AND_PHONE_NUMBER: 2,
	VERIFIED_CONFIRMED_PHONE_NUMBER: 3,
	VERIFIED_CONFIRMED_MAIL: 4,
	VERIFIED_CONFIRMED_MAIL_AND_PHONE_NUMBER: 5,
});

export const tradingServers = Object.freeze({
	MT4: 'МТ-4',
	MT5: 'МТ-5',
});

export const formDataCpaSingleLevel = {
	payoutRangesSumFrom: [],
	referrer_country_check: {},
	first_deposit_fixing_hours: "",
	first_deposit_confirmation_period: "",
	payout_rate_list_id: {},
	payment_coef_cpa: [
		{
			deposits_from: "1",
			coef: "0",
		},
	],
	first_deposit_conditions: [
		[
			{
				symbol_group_id: "",
				payout_rates_ranges_id: "",
				volume: "",
			},
		],
	],
};
export const formDataRevshareSingleLevel = {
	payment_coef_revshare: [
		{
			registrations: "0",
			verifications: "0",
			active_clients: "0",
			new_active_clients: "0",
			coef: "0",
		},
	],
	grace_period: "0",
	payout_rate_for_grace_period: "100",
	min_req_fullfillment_period: "1",
	payout_rates_revshare: [
		{
			groups_list_id: "",
			reward_by_point_lot: "",
			symbols_list_id: "",
			reward_by_volume_lot: "",
			reward_percent_of_commission: "",
			deals: "",
		},
	],
};

export const formDataInOutSingleLevel = {
	payout_rate_for_the_estimated_amount: 0.01,
	min_inout_reminder: 0.01,
	payment_coef_inout: [
		{
			sum_inout_from: 0.01,
			coef: 0,
		},
	],
};

export const formDataCPLSingleLevel = {
	referrer_country_check: '',
	payout_rate_list_id: '',
	condition_lead_appears: '',
	payment_coef_cpl: [
		{
			leads_from: 1,
			coef: 0,
		},
	],
};

export const formDataMultiLevel = {
	consider_ref_linked_to_locked: 1,
	multi_rates_list_rate_by_level: [
		{
			level_from: 2,
			level_to: "",
			rate: "0",
		},
	],
};