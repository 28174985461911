/* eslint-disable no-unused-vars ,max-len, sort-keys */

import React, { PureComponent, } from "react";
import { withLocalize, } from "react-localize-redux";
import { withRouter, } from "react-router-dom";
import Moment from "moment";
import {
	asyncGetSymbols,
	asyncDelSymbols,
} from "../../../../../../services/PartnersRequestService";
import {
	MagicFilterPanels,
	Mentor,
	MagicTable,
	MagicButton,
} from "../../../../../../components/Magic";
import Preloader from "../../../../../../components/LoadingHOC/Preloader";
import Loader from "../../../../../tima/components/Loader";
import PagePanel from "../../../../../tima/components/Pages";
import { MagicTooltip, } from "../../../../../tima/components/Magic/MagicTooltip";
import { MagicConfirm, } from "../../../../../tima/components/Magic/MagicConfirm";
import CreateUpdateSymbol from "../Popup/CreateUpdateSymbol";

const symbolStatus = Object.freeze({
	ACTIVE: 2,
	DISABLE: 0,
	NEW: 1,
});

class SymbolsMagicTable extends PureComponent {
	constructor (props) {
		super(props);

		this.state = {
			data: [],
			filterLastChange: Date.now(),
			isEmptyData: false,
			loaded: false,
			pages: {
				filter: 0,
				take: 50,
				total: 0,
				variance: 2,
			},
			removeSymbolId: null,
		};

		const mf = new Mentor({
			owner: this,
			serviceId: 177,
			translate: this.props.translate,
		});

		Object.defineProperty(this, "mf", { get: () => mf, });
	}

	async componentDidMount () {
		this.mf.subscribe(
			{
				changed: this.mfChanged,
				cleaned: this.mfDone,
				cleaning: this.mfDoing,
				loaded: this.mfDone,
				loading: this.mfDoing,
				saved: this.mfDone,
				saving: this.mfDoing,
			},
			this,
		);

		await this.mf.init({ doEmit: true, });
	}

	componentWillUnmount () {
		this.mf.unsubscribe([ this.mfChanged, this.mfDoing, this.mfDone, ], this);
	}

	save = state => new Promise(next => this.setState(state, next));

	onChange = (name, value) => this.save({ [name]: value, });

	params = () => this.props.match.params;

	query = () => new URLSearchParams(this.props.location.search);

	mfChanged = async () => {
		await this.save({ filterLastChange: Date.now(), });
	};

	mfDoing = async () => {
		await this.save({ loaded: false, });
	};

	mfDone = async () => {
		await this.mfChanged();
		await this.onDataLoad();
	};

	enumsChange = async (enums) => {
		if (enums.hash === this.state?.enums?.hash) {
			return `${ this.constructor.name }.enumsChange: false`;
		}
		await this.save({ enums, });

		return `${ this.constructor.name }.enumsChange: true`;
	};

	get symbolsTableConfig () {
		const { translate, } = this.props;
		const prefix = 'symbols_';

		return [
			{
				path: [ "partners", "symbols", "id", ],
				key: "symbolsId",
				render: this.renderInitialValue,
				title: `${ prefix }id`,
				orderId: "o:id",
			},
			{
				path: [ "partners", "symbols", "name", ],
				key: "symbolsName",
				render: this.renderInitialValue,
				title: `${ prefix }name`,
				orderId: "o:name",
			},
			{
				path: [ "partners", "symbols_groups", "name", ],
				key: "symbolsGroupId",
				render: this.renderInitialValue,
				title: `${ prefix }group`,
				orderId: "o:group",
			},
			{
				path: [ "partners", "symbols", "status", ],
				key: "symbolsStatus",
				render: this.renderChannelStatus,
				title: `${ prefix }status`,
				orderId: "o:status",
			},
			{
				path: [ "core", "dictionary_trade_servers", "server_name", ],
				key: "symbolsServer",
				render: this.renderInitialValue,
				title: `${ prefix }server`,
			},
			{
				path: [ "partners", "symbols", "created_at", ],
				key: "symbolsCreatedAt",
				render: this.renderInitialValue,
				title: `${ prefix }created_at`,
				orderId: "o:created",
			},
			{
				path: [ "partners", "symbols", "updated_at", ],
				key: "symbolsUpdatedAt",
				render: this.renderDate,
				title: `${ prefix }updated_at`,
				orderId: "o:updated",
			},
			{
				path: [ "partners", "symbols", "id", ],
				key: "symbolsActions",
				render: this.renderActions,
				title: `${ prefix }actions`,
				customAccessCheck: ({ item, }) => {
					return (
						item.access("show") &&
						(item.access("update") || item.access("destroy"))
					);
				},
			},
		].map(({
			orderId: o, title: t, xtitle: x, ...item
		}) => ({
			...item,
			...o && {
				order: async () => {
					await this.mf?.orderedToNext(o);
				},
				orderTo: this.mf?.orderedTo(o),
			},
			...t && { title: translate(t), },
			...x && { title: x, },
		}));
	}

	removeSymbol = async (symbolId = this.state.removeSymbolId) => {
		if (!symbolId) {
			console.warn("symbol id not found");

			return;
		}

		try {
			await asyncDelSymbols(symbolId);
			await this.onDataLoad();
		} catch (error) {
			error?.showErrorNotification?.();
		}
	};

	renderInitialValue = (value, { item, }) => {
		if (item?.access?.("index")) {
			return (
				<MagicTooltip
					classNameTooltip="tooltip tooltip--large-width"
					content={ value ?? "-" }
					lengthCut={ 20 }
				/>
			);
		}

		return "-";
	};

	renderDate = (date, { item, }) => {
		if (item?.access?.("index")) {
			const formatDB = "YYYY-MM-DD HH:mm:ss";
			const formatOut = "YYYY-MM-DD HH:mm:ss";

			// eslint-disable-next-line new-cap
			const newDate = Moment(date, formatDB);

			return newDate.isValid() ? newDate.format(formatOut) : "-";
		}

		return "-";
	};

	renderChannelStatus = (status, { item, }) => {
		if (item.access("index")) {
			return (
				this.props.enums.data?.partners?.SymbolStatusEnum?.[+status] ?? "-"
			);
		}

		return "-";
	};

	// eslint-disable-next-line no-unused-vars
	renderActions = (value, { item, items, data, }) => {
		const { translate, popup, } = this.props;
		const { removeSymbolId, } = this.state;

		const renderRemoveButton = () => {
			const options = {
				className:
					"magic-button__item magic-button__item_partners-delete-symbol margin--top-10",
				children: translate("symbols_actions_delete"),
				onClick: () => this.onChange("removeSymbolId", value),
			};

			return (
				<div className="magic-confirm-wrapper">
					<MagicConfirm
						accept={ translate("symbols_remove_accept") }
						closeByClickOutside={ true }
						isVisible={ Number(removeSymbolId) === Number(value) }
						reject={ translate("symbols_remove_reject") }
						title={ translate(`magic_confirm_title`) }
						onAccept={ () => this.removeSymbol(removeSymbolId) }
						onReject={ () => this.onChange("removeSymbolId", null) }
					/>
					<MagicButton { ...options } />
				</div>
			);
		};

		const renderEditButton = () => {
			const {
				core: { dictionary_trade_servers, },
				partners: { symbols, },
			} = data;

			const formData = {
				trading_server_id: dictionary_trade_servers.id,
				name: symbols.name,
				status: symbols.status,
				group_id: symbols.group_id,
				id: symbols.id,
			};

			const options = {
				className: "magic-button__item magic-button__item_partners-edit-symbol",
				children: translate("symbols_actions_edit"),
				onClick: () => popup.togglePopup(formData),
			};

			return (
				<div className="magic-confirm-wrapper">
					<MagicButton { ...options } />
				</div>
			);
		};

		return (
			<>
				{item.access("update") && renderEditButton()}
				{item.access("destroy") && renderRemoveButton()}
			</>
		);
	};

	renderTable = () => {
		const {
			loaded, pages, data, isEmptyData,
		} = this.state;
		const { translate, } = this.props;

		const options = {
			config: this.symbolsTableConfig,
			data,
			head: [
				"symbolsId",
				"symbolsName",
				"symbolsGroupId",
				"symbolsStatus",
				"symbolsServer",
				"symbolsCreatedAt",
				"symbolsUpdatedAt",
				"symbolsActions",
			],
			RowProps: ({ items, }) => {
				return {
					"data-disabled": [ symbolStatus.DISABLE, ].includes(
						items?.symbolsStatus?.valueOf,
					),
				};
			},
		};

		return (
			<>
				<MagicFilterPanels mf={ this.mf } show={ true } translate={ translate } />
				<div
					className={ `table-wrapper partners__table ${
						isEmptyData ? "partners__table--empty" : ""
					}` }
				>
					<Loader loaded={ loaded } loading={ <Preloader scale={ 1 } /> }>
						<MagicTable { ...options } />
						{isEmptyData && (
							<div className="table__empty-text">
								{translate("symbols_empty")}
							</div>
						)}
						<PagePanel
							doText={ this.onPageText }
							doTo={ pageId => `?page=${ pageId }` }
							filter={ pages.filter }
							page={ this.pageId() }
							take={ pages.take }
							total={ pages.total }
							variance={ pages.variance }
							onClick={ this.onPageClick }
						/>
					</Loader>
				</div>
			</>
		);
	};

	render () {
		const { enums, popup, } = this.props;

		return (
			<>
				{popup.isOpen && (
					<CreateUpdateSymbol
						enums={ enums }
						formData={ popup.data }
						onClose={ () => popup.togglePopup() }
						onDataLoad={ this.onDataLoad }
					/>
				)}
				{this.renderTable()}
			</>
		);
	}

	pageId = () => {
		const page = this.query().get("page");

		return Number(page) || 0;
	};

	pageIdAsGET = (pageId) => {
		// eslint-disable-next-line no-param-reassign
		pageId = pageId === undefined ? this.pageId() : pageId;
		const { filterId, } = this.mf;

		return {
			filterId,
			take: this.state.pages.take,
			skip: pageId * this.state.pages.take,
		};
	};

	onDataLoad = async (pageId) => {
		await this.save({ loaded: false, isEmptyData: false, });

		const pageOptions = this.pageIdAsGET(pageId);
		const loaded = (data, meta) => ({ pages, }) => ({
			data,
			pages: { ...pages, ...meta, },
			...!data.length && { isEmptyData: true, },
		});

		try {
			const response = await asyncGetSymbols(pageOptions);
			const {
				data = [],
				meta: { filter = 0, total = 0, },
			} = response;

			await this.save(loaded(data, { filter, total, }));
		} catch (error) {
			await this.save(loaded([], { filter: 0, total: 0, }));
			error?.showErrorNotification?.();
		}
		await this.save({ loaded: true, });
	};

	onPageClick = ({ event, pageId, pageIs, }) => {
		this.onDataLoad(pageId);
	};

	onPageText = (pageId, pageIs) => {
		const { translate, } = this.props;
		const {
			current, first, prev, next, last,
		} = pageIs;
		const {
			skipped, taken, filter, total,
		} = pageIs;

		if (skipped || taken) {
			const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : "?";
			const text = skipped ? translate("partners_pages_items") : "";

			return skipped ? `${ text }: ${ id }` : id;
		} else if (filter || total) {
			const id = Number.isInteger(pageId) ? `${ pageId }` : "?";
			const text = translate(
				filter ? "partners_pages_filtered" : "partners_pages_total",
			);

			return `${ text }: ${ id }`;
		} else if (first || prev || next || last) {
			return "";
		} else if (current) {
			return `${ pageId + 1 }`;
		}

		return `${ pageId + 1 }`;
	};
}

export default withRouter(withLocalize(SymbolsMagicTable));