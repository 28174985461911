import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";

import Preloader from "../../../components/LoadingHOC/Preloader";
import Loader from "./Loader";
import NotificationService from "../../../services/NotificationService";
import PermissionService from "../../../services/PermissionService";
import { Link } from "react-router-dom";
import { MagicConfirm } from "./Magic/MagicConfirm";
import { MagicTextarea } from "./Magic/MagicTextarea";
import ListTimaInvestmentHistory from "./ListTimaInvestmentHistory";
import { MagicRadioSwitch, MagicSwitch } from "./Magic/MagicSwitch";
import PopupEditInvestmentCondition from "./PopupEditInvestmentCondition";
import { timaService } from "../../../services/TimaDataService";

class OneTimaInvestment extends Component {
    constructor (props) {
        super(props);

        let id = null;

        if (this.props.params && this.props.params.investmentId) {
            id = +this.props.params.investmentId;
        }

        this.state = {
            loaded:                false,
            investment: {
                data: [],
                hash: null,
            },
            conditionsList: {
                data: [],
                hash: null,
            },
            agentsList: {
                data: [],
                hash: null,
            },
            id,
            magicEditValuePrev:    {},
            magicEditValueCurrent: {},
            magicEditSelectionPos: {},
            confirm:               false,
            editMode:              false,

            isPopupActive: {
                editInvestmentCondition: false,
            },
        };
    }

    save = async (state) => new Promise(next => this.setState(state, next));

    investmentChanges = async (investment) => {
        if (investment.hash===this.state?.investment?.hash) return `${this.constructor.name}.investmentChanges: false`;
        if (investment.investmentId !== this.state?.id) return `${this.constructor.name}.investmentChanges: false`;
        const { data, hash } = investment;
        await this.save({ investment: { data, hash }});

        return `${this.constructor.name}.investmentChanges: true`;
    };

    conditionsListChanges = async (conditionsList) => {
        if (conditionsList.hash===this.state?.conditionsList?.hash) return `${this.constructor.name}.conditionsListChanges: false`;
        await this.save({ conditionsList });

        return `${this.constructor.name}.conditionsListChanges: true`;
    };

    agentsListChanges = async (agentsList) => {
        if (agentsList.hash===this.state?.agentsList?.hash) return `${this.constructor.name}.agentsListChanges: false`;
        await this.save({ agentsList });

        return `${this.constructor.name}.agentsListChanges: true`;
    };

    componentDidMount = async () => {
        timaService.subscribe('investment', this.investmentChanges, this);
        timaService.subscribe('conditionsList', this.conditionsListChanges, this);
        timaService.subscribe('agentsList', this.agentsListChanges, this);
        await this.onDataLoad();
    };

    componentWillUnmount = () => {
        timaService.unsubscribe('investment', this.investmentChanges, this);
        timaService.unsubscribe('conditionsList', this.conditionsListChanges, this);
        timaService.unsubscribe('agentsList', this.agentsListChanges, this);
    };

    popupClose = (activeTab) => () => {
        this.setState((state) => ({ isPopupActive: { ...state.isPopupActive, [activeTab]: false } }));
    };

    popupOpen = (activeTab) => () => {
        this.setState((state) => ({ isPopupActive: { ...state.isPopupActive, [activeTab]: true } }));
    };

    get OneTimaInvestmentConfig () {
        const { translate, } = this.props;
        const prefix = 'tima_investments_table_';

        return [
            {
                path:   ['tima', 'tima_investment', 'name'],
                key:    'timaInvestmentName',
                render: this.renderBlockCont(),
                title:  `${prefix}investment_number`,
            },
            {
                path: ['core', 'account', 'id'],
                key:  'clientId',
            },
            {
                path: ['core', 'account', 'name'],
                key:  'clientName',
            },
            {
                path: ['core', 'account', 'surname'],
                key:  'clientSurname',
            },
            {
                accessChecked: true,
                key:           'client',
                render:        this.renderFullNameCont({ fieldName: 'client', path: '/clients' }),
                title:         `${prefix}client`,
            },
            {
                path:   ['tima', 'tima_investment', 'amount'],
                key:    'timaInvestmentAmount',
                render: this.renderBlockCont(),
                title:  `${prefix}amount`,
            },
            {
                path:   ['core', 'trading_accounts', 'acc_ccy'],
                key:    'currency',
                render: this.renderBlockCont({ isEnum: true, enumPath: 'core', enumName: 'AccountCurrencyEnum' }),
                title:  `${prefix}currency`,
            },
            {
                accessChecked: true,
                path:          ['core', 'trading_accounts', 'current_equity'],
                key:           'currentEquity',
                render:        this.renderBlockCont(),
                title:         `${prefix}equity`,
            },
            {
                accessChecked: true,
                path:          ['core', 'trading_accounts', 'net_profit'],
                key:           'netProfit',
                render:        this.renderBlockCont(),
                title:         `${prefix}net_profit`,
            },
            {
                accessChecked: true,
                path:          ['core', 'trading_accounts', 'current_profitability'],
                key:           'currentProfitability',
                render:        this.renderBlockCont(),
                title:         `${prefix}current_yield_investments`,
            },
            {
                path:   ['tima', 'tima_investment', 'share'],
                key:    'timaInvestmentShare',
                render: this.renderBlockCont(),
                title:  `${prefix}share`,
            },
            {
                path:   ['tima', 'tima_investment', 'status'],
                key:    'timaInvestmentStatus',
                render: this.renderBlockCont({ fieldName: 'status' }),
                title:  `${prefix}status`,
            },
            {
                path:   ['tima', 'tima_investment', 'risk'],
                key:    'timaInvestmentRisk',
                render: this.renderBlockCont({ fieldName: 'risk' }),
                title:  `${prefix}risk`,
            },
            {
                path:   ['tima', 'tima_investment', 'reinvest'],
                key:    'timaInvestmentReinvest',
                render: this.renderBlockCont({ fieldName: 'reinvest' }),
                title:  `${prefix}reinvest`,
            },
            {
                path:   ['tima', 'tima_condition', 'version'],
                key:    'timaConditionVersion',
                render: this.renderBlockCont(),
                title:  `${prefix}version`,
            },
            {
                path: ['tima', 'tima_condition', 'id'],
                key:  'tima_condition_id',
            },
            {
                path:   ['tima', 'tima_condition', 'name'],
                key:    'timaConditionName',
                render: this.renderBlockContLink({ fieldName: 'tima_condition', path: '/tima/condition' }),
                title:  `${prefix}tima_condition`,
            },
            {
                path:   ['tima', 'tima_investment', 'created_at'],
                key:    'timaInvestmentCreatedAt',
                render: this.renderBlockCont(),
                title:  `${prefix}date_creation`,
            },
            {
                path:   ['tima', 'tima_investment', 'date_closed'],
                key:    'timaInvestmentDateClosed',
                render: this.renderBlockCont(),
                title:  `${prefix}date_closing`,
            },
            {
                path: ['tima', 'tima_manager', 'id'],
                key:  'tima_manager_id',
            },
            {
                path:   ['tima', 'tima_manager', 'name'],
                key:    'timaManagerName',
                render: this.renderBlockContLink({ fieldName: 'tima_manager', path: '/tima/strategy' }),
                title:  `${prefix}tima_account`,
            },
            {
                path: ['tima', 'tima_agents', 'id'],
                key:  'tima_agent_id',
            },
            {
                path:   ['tima', 'tima_agents', 'name'],
                key:    'timaAgentName',
                render: this.renderBlockContLink({ fieldName: 'tima_agent', path: '/tima/agent' }),
                title:  `${prefix}tima_agent`,
            },
        ].map(({ title: t, xtitle: x, ...item }) => ({ ...item, ...x && { title: x }, ...t && { title: translate(t) }, }));
    }

    renderBlockCont = ({ fieldName = '', isEnum = false, enumPath = null, enumName = null } = {}) => (value, { item, items }) => {
        const title = item?.options?.title;
        const key = item?.key;
        const defaultValue = '-';

        let targetValue;

        switch (true) {
            case item?.access?.('show'): {
                if (isEnum) {
                    const enums = this.props?.enums?.[enumPath]?.[enumName];
                    targetValue = enums?.[value] ?? defaultValue;
                } else {
                    targetValue = value ?? defaultValue;
                }
                break;
            }
            default: {
                targetValue = defaultValue;
                break;
            }
        }

        return this.renderBlockContFragment({ fieldName, key, title, targetValue, value, item, items });
    };

    renderBlockContLink = ({ fieldName = '', path = null } = {}) => (value, { item, items }) => {
        const id = items?.[`${fieldName}_id`];
        const title = item?.options?.title;
        const key = item?.key;
        const defaultValue = '-';

        let targetValue;
        let blockUrl;

        switch (true) {
            case id?.access?.('show') && item?.access?.('show'): {
                const valueIdFromState = this?.state?.magicEditValuePrev?.[fieldName];
                const isValueIdFromState = !isNaN(parseInt(valueIdFromState));

                targetValue = value ?? defaultValue;

                if (isValueIdFromState) {
                    blockUrl = `${path}/${ this.values(fieldName)[valueIdFromState] }` ?? null;
                } else {
                    blockUrl = !isNaN(parseInt(`${id?.valueOf}`)) ? `${path}/${ id?.valueOf }` : null;
                }
                break;
            }
            case item?.access?.('show'): {
                targetValue = value ?? defaultValue;
                blockUrl = null;
                break;
            }
            default: {
                targetValue = defaultValue;
                blockUrl = null;
                break;
            }
        }

        return this.renderBlockContFragment({ key, title, targetValue, fieldName, blockUrl, value, item, items });
    };

    renderFullNameCont = ({ fieldName = null, path = null } = {}) => (value, { item, items }) => {
        const id = items?.[`${fieldName}Id`];
        const name = items?.[`${fieldName}Name`];
        const surname = items?.[`${fieldName}Surname`];

        const title = item?.options?.title;
        const key = item?.key;
        const defaultValue = '-';

        let targetValue;
        let blockUrl;

        switch (true) {
            case id?.access('show') && name?.access?.('show') && surname?.access?.('show'): {
                targetValue = `${ surname?.valueOf } ${ name?.valueOf }`;
                blockUrl = `${path}/${ id?.valueOf }`;
                break;
            }
            case name?.access?.('show') && surname?.access?.('show'): {
                targetValue = `${ surname?.valueOf } ${ name?.valueOf }`;
                blockUrl = null;
                break;
            }
            default: {
                targetValue = defaultValue;
                blockUrl = null;
                break;
            }
        }

        return this.renderBlockContFragment({ key, title, targetValue, fieldName, blockUrl });
    };

    renderBlockContFragment = ({ key, title, targetValue, fieldName, blockUrl, value, item, items }) => {
        const targetOption = fieldName === 'client' ? '_self' : '_blank';

        return (
            <div className="block-cont" key={`block-cont-${key}`}>
                <div className="block">
                    <div className="block-header full-cont">
                        <div className="full-cont__item">
                            {blockUrl ?
                                (
                                    <Link to={`${blockUrl}`} className='link-btn-cont' target={targetOption}>
                                        <span className='block__name link-btn'>
                                            {title}
                                        </span>
                                    </Link>
                                ) :
                                (
                                    <span className="block__name">{title}</span>
                                )
                            }

                        </div>
                        <div className="full-cont__item">
                            {/*<span className="block__info">{targetValue}</span>*/}
                            {this.renderBlockInfoFragment({ fieldName, targetValue, value, item, items })}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderBlockInfoFragment = ({ fieldName, targetValue, value, item, items }) => {
        const { translate, enums } = this.props;

        switch (fieldName) {
            case 'status':
            case 'tima_agent': {
                const timaAgentId = items?.tima_agent_id?.valueOf ?? null;
                const calcValue = fieldName === 'tima_agent' ? timaAgentId : value;
                const valueFromState = this.state?.magicEditValuePrev?.[fieldName] ?? this.values(fieldName).indexOf(calcValue);
                const isVisible = this.confirmCheck(fieldName);
                const isEditMode = this.editModeCheck(fieldName);

                if (item?.access?.('show', 'update')) {
                    return (
                        <div className='magic-edit-mode'>
                            <span className='block__info edit-btn'
                                  onClick={this.onEnterEditMode(fieldName)}
                            >
                                {this.texts(fieldName)[valueFromState]}
                            </span>

                            {isEditMode ?
                                (
                                    <div
                                        className="magic-confirm-wrapper magic-confirm-wrapper--large-width magic-confirm-wrapper--position-right">
                                        <MagicConfirm
                                            onAccept={this.confirmAcceptHandler(fieldName, valueFromState)}
                                            onReject={this.confirmRejectHandler(fieldName, valueFromState)}
                                            title={translate(`magic_confirm_title`)}
                                            accept={translate(`magic_confirm_yes`)}
                                            reject={translate(`magic_confirm_no`)}
                                            isVisible={!!isVisible}
                                        />

                                        <MagicRadioSwitch
                                            className={`magic-radio-switch /*magic-radio-switch--max-height-200 scroll*/`}
                                            classNameText={`magic-switch__text magic-switch__text--padding-left`}
                                            direction={`column`}
                                            index={isVisible ? this.values(fieldName).indexOf(+isVisible[1]) : valueFromState}
                                            indexNone={true}
                                            // index={-1}
                                            reverse={false}
                                            texts={this.texts(fieldName)}
                                            values={this.values(fieldName)}
                                            onChange={this.onChange(fieldName)}
                                        />

                                        <i className="gl-icon close-btn"
                                           onClick={this.onClickCloseConfirmBtn(fieldName)}
                                        />
                                    </div>
                                ) : ''}
                        </div>
                    );
                } else if (item?.access?.('show')) {
                    return (
                        <span className='block__info'>{this.texts(fieldName)[valueFromState]}</span>
                    );
                }
                return '-';
            }
            case 'reinvest': {
                const isVisible = this.confirmCheck(fieldName);
                const valueFromState = this.state?.magicEditValuePrev?.[fieldName] ?? this.values(fieldName).indexOf(value);

                if (item?.access?.('show', 'update')) {
                    return (
                        <div className="magic-confirm-wrapper">
                            <MagicConfirm
                                onAccept={this.confirmAcceptHandler(fieldName, valueFromState)}
                                onReject={this.confirmRejectHandler(fieldName, valueFromState)}
                                title={translate(`magic_confirm_title`)}
                                accept={translate(`magic_confirm_yes`)}
                                reject={translate(`magic_confirm_no`)}
                                isVisible={!!isVisible}
                            />

                            <MagicSwitch
                                classNameText={`magic-switch__text block__info edit-btn`}
                                index={isVisible ? this.values(fieldName).indexOf(+isVisible[1]) : valueFromState}
                                reverse={true}
                                values={this.values(fieldName)}
                                texts={this.texts(fieldName)}
                                onChange={this.onChange(fieldName)}
                                updateStateFromProps={true}
                            />
                        </div>
                    );
                } else if (item?.access?.('show')) {
                    return (
                        <span className='block__info'>{this.texts(fieldName)[valueFromState]}</span>
                    );
                }
                return '-';
            }
            case 'risk': {
                const valueFromStatePrev = this.state?.magicEditValuePrev?.[fieldName] ?? value;
                const valueFromStateCurrent = this.state?.magicEditValueCurrent?.[fieldName] ?? value;
                const isVisible = this.confirmCheck(fieldName);
                const isEditMode = this.editModeCheck(fieldName);

                if (item?.access?.('show', 'update')) {
                    return (
                        <div className='magic-edit-mode'>
                            <span className='block__info edit-btn'
                                  onClick={this.onEnterEditMode(fieldName)}
                            >
                                {valueFromStatePrev}
                            </span>

                            {isEditMode ?
                                (
                                    <div
                                        className="magic-confirm-wrapper magic-confirm-wrapper--large-width magic-confirm-wrapper--textarea">
                                        <i className="icon-bg icon-bg--success save-btn"
                                           onClick={
                                               (event) => {
                                                   this.setConfirm([fieldName, valueFromStateCurrent]);
                                               }
                                           }
                                        />

                                        <MagicConfirm
                                            onAccept={this.confirmAcceptHandler(fieldName, valueFromStatePrev)}
                                            onReject={this.confirmRejectHandler(fieldName, valueFromStatePrev)}
                                            title={translate(`magic_confirm_title`)}
                                            accept={translate(`magic_confirm_yes`)}
                                            reject={translate(`magic_confirm_no`)}
                                            isVisible={!!isVisible}
                                        />

                                        <MagicTextarea
                                            className={'magic-textarea magic-textarea--auto-height scroll'}
                                            value={valueFromStateCurrent.toString()}
                                            onChange={this.onChangeTextarea(fieldName)}
                                            selectionStart={this.state?.magicEditSelectionPos?.[fieldName]?.[0] ?? value?.toString()?.length ?? 0}
                                            selectionEnd={this.state?.magicEditSelectionPos?.[fieldName]?.[1] ?? value?.toString()?.length ?? 0}
                                        />

                                        <i className="gl-icon close-btn"
                                           onClick={this.onClickCloseConfirmBtn(fieldName, valueFromStatePrev)}
                                        />
                                    </div>
                                ) : ''}
                        </div>
                    );
                } else if (item?.access?.('show')) {
                    return (
                        <span className='block__info'>{valueFromStateCurrent}</span>
                    );
                }
                return '-';
            }
            case 'tima_condition': {
                const activeTab = 'editInvestmentCondition';

                if (item?.access?.('show', 'update')) {
                    return (
                        <div className='partners'>
                            <span className='block__info edit-btn'
                                  onClick={this.popupOpen('editInvestmentCondition')}
                            >
                                {/*{this.getFieldValue(fieldName, props)}*/}
                                {this.state?.investment.data?.tima?.tima_condition?.name}
                            </span>

                            {this.state.isPopupActive[activeTab] ?
                                (<PopupEditInvestmentCondition
                                    refreshChildData={this.onDataLoad}
                                    popUpClose={this.popupClose(activeTab)}
                                    activeLanguage={this.props.activeLanguage}
                                    translate={translate}
                                    accountId={this.state?.investment.data?.core?.account?.id}
                                    timaInvestmentId={this.state?.investment.data?.tima?.tima_investment?.id}
                                    timaManagerId={this.state?.investment.data?.tima?.tima_manager?.id}
                                    timaConditionId={this.state?.investment.data?.tima?.tima_condition?.id}
                                    enums={enums}
                                />) :
                                (false)}
                        </div>
                    );
                } else if (item?.access?.('show')) {
                    return (
                        <span className='block__info'>{value}</span>
                    );
                }
                return '-';
            }
            default: {
                return (
                    <span className="block__info">{targetValue}</span>
                );
            }
        }
    };

    renderTitle (title) {
        return (
            <div className='title-cont'>
                <h3 className='title'>{title}</h3>
            </div>
        );
    }

    renderInformationCont (fieldsArray, castData) {
        const { translate, params, query, enums } = this.props;

        return (
            <div className='information-cont information-cont--tima-one-investment'>
                {
                    fieldsArray.map((item, i) => {
                        return this.renderInformationContItem(item, i, castData);
                    })
                }

                <div className='information-cont__item' key={`information-cont__item-2`}>
                    {/* INCLUDE ListTimaInvestmentHistory COMPONENT*/}
                    <ListTimaInvestmentHistory
                        translate={translate}
                        params={params}
                        query={query}
                        enums={enums}
                    />
                </div>
            </div>
        );
    }

    renderInformationContItem (fieldsPart, i, castData) {
        return (
            <div className='information-cont__item' key={`information-cont__item-${i}`}>
                {this.renderTitle(this.state?.investment.data?.tima?.tima_investment.name)}

                <div className='blocks-wrapper'>
                    {
                        fieldsPart.map((item, i) => {
                            return (
                                <React.Fragment key={`key-${i}`}>
                                    {castData?.[item] ?? false}
                                </React.Fragment>
                            );
                        })
                    }
                </div>
            </div>
        );
    }

    getEnumObjByFieldName = (fieldName) => {
        const { enums = {} } = this.props;
        const { tima = {} } = enums;
        let enumObj = {};

        try {
            switch (fieldName) {
                case 'status': {
                    enumObj = tima?.TimaInvestmentStatusEnum;
                    break;
                }
                case 'reinvest': {
                    enumObj = tima?.TimaInvestmentReinvestStatusEnum;
                    break;
                }

                default: {
                    break;
                }
            }
        } catch (error) {
            throw new Error(`Error getEnumObjByFieldName { fieldName: ${ fieldName } }`);
        }

        return enumObj;
    };

    texts = (fieldName) => {
        const enumAsObjectToValues = (_) => [...Object.entries(_).map(([key, value]) => value)];
        const agentsListAsObjectToName = [...Object.entries(this.state?.agentsList.data ?? []).map(([key, value]) => `${value.name}`)];

        try {
            switch (fieldName) {
                case 'tima_agent': {
                    return agentsListAsObjectToName;
                }
                default: {
                    return enumAsObjectToValues(this.getEnumObjByFieldName(fieldName));
                }
            }
        } catch (error) {
            throw new Error(`Error texts { fieldName: ${ fieldName } }`);
        }
    };

    values = (fieldName) => {
        const enumAsObjectToKeys = (_, isNumber) => [...Object.entries(_).map(([key]) => isNumber ? +key : key)];
        const agentsListAsObjectToId = [...Object.entries(this.state?.agentsList.data ?? []).map(([key, value]) => value.id)];

        try {
            switch (fieldName) {
                case 'tima_agent': {
                    return agentsListAsObjectToId;
                }
                case 'status':
                case 'reinvest': {
                    return enumAsObjectToKeys(this.getEnumObjByFieldName(fieldName), true);
                }
                default: {
                    return enumAsObjectToKeys(this.getEnumObjByFieldName(fieldName));
                }
            }
        } catch (error) {
            throw new Error(`Error values { fieldName: ${ fieldName } }`);
        }
    };

    isTextareaFieldCheck = (fieldName) => {
        const textareaArray = [
            'risk',
        ];

        return textareaArray.indexOf(fieldName) !== -1;
    };

    returnToPrevStateTextarea = (fieldName, valueFromStatePrev) => {
        const magicEditValuePrev = this.state?.magicEditValuePrev?.[fieldName] ?? valueFromStatePrev;
        const valuePrevLength = magicEditValuePrev?.toString()?.length ?? 0;
        const state = {
            magicEditValueCurrent: {
                ...this.state.magicEditValueCurrent,
                [fieldName]: magicEditValuePrev,
            },

            magicEditSelectionPos: {
                ...this.state.magicEditSelectionPos,
                [fieldName]: [valuePrevLength, valuePrevLength],
            },
        };
        this.setState(state);
    };

    onClickCloseConfirmBtn = (fieldName, valueFromStatePrev) => (event) => {
        this.setEditMode(false);
        this.setConfirm(false);

        if (this.isTextareaFieldCheck(fieldName)) {
            this.returnToPrevStateTextarea(fieldName, valueFromStatePrev);
        }
    };

    onChange = (fieldName) => ({ value, scrollPosY }) => {
        const isEditMode = this.editModeCheck(fieldName);

        if (!isEditMode) {
            this.setEditMode(false);
        }

        this.setConfirm([fieldName, value]);
    };

    onChangeTextarea = (fieldName) => ({ value, selectionStart, selectionEnd }) => {
        const state = {
            magicEditValueCurrent: {
                ...this.state.magicEditValueCurrent,
                [fieldName]: value,
            },
            magicEditSelectionPos: {
                ...this.state.magicEditSelectionPos,
                [fieldName]: [selectionStart, selectionEnd],
            },
        };
        this.setState(state);
    };

    onEnterEditMode = (fieldName) => () => {
        this.setEditMode([fieldName]);
        this.setConfirm(false);
    };

    confirmAcceptHandler = (fieldName, valuePrev) => async (event) => {
        const confirm = this.confirmCheck(fieldName);
        if (!confirm) {
            this.setConfirm(false);
            return;
        }
        const value = confirm[1];
        const timaInvestmentId = this.state?.investment.data?.tima?.tima_investment?.id;
        const accountId = this.state?.investment.data?.core?.account?.id;

        let fieldMap;

        try {
            switch (fieldName) {
                case 'tima_agent':
                    fieldMap = 'tima_agent_id';
                    break;
                default:
                    fieldMap = fieldName;
                    break;
            }

            const data = { account_id: accountId, [fieldMap]: value };
            const { response } = await timaService.updateInvestment(timaInvestmentId, data);

            if (!response) {
                throw new Error(`Can*t update the fieldName: '${fieldName}', please try again later!`);
            }

            let newValue;

            switch (fieldName) {
                case 'risk': {
                    newValue = value;
                    break;
                }
                case 'status':
                case 'tima_agent': {
                    newValue = this.values(fieldName).indexOf(value);
                    break;
                }

                default: {
                    newValue = +value;
                    break;
                }
            }

            const state = {
                magicEditValuePrev: {
                    ...this.state.magicEditValuePrev,
                    [fieldName]: newValue,
                },
            };
            this.setState(state);

        } catch (error) {
            NotificationService.error({
                title:   "error",
                message: error.message,
                remove:  false,
            });

            if (this.isTextareaFieldCheck(fieldName)) {
                this.returnToPrevStateTextarea(fieldName, this.state?.magicEditValuePrev?.[fieldName] ?? valuePrev);
            }
        }
        this.setConfirm(false);
        this.setEditMode(false);
    };

    confirmRejectHandler = (fieldName, valuePrev) => (event) => {
        this.setConfirm(false);
        this.setEditMode(false);
    };

    confirmCheck = (fieldName) => {
        const { confirm } = this.state;
        return confirm instanceof Array && confirm[0] === fieldName ? confirm : false;
    };

    editModeCheck = (fieldName) => {
        const { editMode } = this.state;
        return editMode instanceof Array && editMode[0] === fieldName ? editMode : false;
    };

    setConfirm = (confirm) => {
        this.setState((state) => ({ ...state, confirm }));
    };

    setEditMode = (editMode) => {
        this.setState((state) => ({ ...state, editMode }));
    };

    onDataLoad = async () => {
        await this.save({ loaded: false, });
        const loaded = ({ investment, conditionsList, agentsList }) => (state) => ({
            ...state,
            investment,
            conditionsList,
            agentsList,
        });
        const loadTimaInvestment = async (id) => await timaService.investment(id);
        const loadTimaConditionsList = async () => await timaService.conditionsList();
        const loadTimaAgentsList = async () => await timaService.agentsList();
        try {
            const results = await Promise.all([
                loadTimaInvestment(this.state.id),
                loadTimaConditionsList(),
                loadTimaAgentsList()
            ]);

            if (results[0].error || results[1].error || results[2].error) {
                const errorMsg1 = results[0].error ? results[0].error : '';
                const errorMsg2 = results[1].error ? results[1].error : '';
                const errorMsg3 = results[2].error ? results[2].error : '';

                throw new Error(`${errorMsg1} ${errorMsg2} ${errorMsg3}`);
            }

            const response = results[0];
            const { data, hash } = response;
            const conditionsList = results[1];
            const agentsList = results[2];

            await this.save(loaded({ investment: { data, hash }, conditionsList, agentsList, }));
        } catch (error) {
            let errorMsg = `Error while processing request in OneTimaInvestment: `;

            errorMsg += error.error ? error.error : error;
            NotificationService.error({
                title:   "error",
                message: errorMsg,
                remove:  false,
            });
            const emptyObject = { data: [], hash: null };

            await this.save(loaded({ investment: emptyObject, conditionsList: emptyObject, agentsList: emptyObject, }));
        }
        await this.save({ loaded: true, });
    };

    render () {
        const { fieldsForChecking, translate, enums, scale } = this.props;
        const { investment: { data }, loaded } = this.state;
        const castData = PermissionService.cast(data, this.OneTimaInvestmentConfig);

        return (
            <Loader
                loaded={loaded && !!enums}
                loading={(<Preloader scale={scale}/>)}
                translate={translate}
            >
                <div className='glalex-styles tima'>
                    {this.renderInformationCont(fieldsForChecking, castData)}
                </div>
            </Loader>
        );
    }
}

OneTimaInvestment.defaultProps = {
    fieldsForChecking: [
        [
            'timaInvestmentName',
            'client',
            'timaInvestmentAmount',
            'currency',
            'currentEquity',
            'netProfit',
            'currentProfitability',
            'timaInvestmentShare',
            'timaInvestmentStatus',
            'timaInvestmentRisk',
            'timaInvestmentReinvest',
            'timaConditionVersion',
            'timaConditionName',
            'timaInvestmentCreatedAt',
            'timaInvestmentDateClosed',
            'timaManagerName',
            'timaAgentName',
        ],
    ],

    // editableFields:         [
    //     'status',
    //     'risk',
    //     'reinvest',
    //     'tima_agent',
    // ],
    // editableTextareaFields: [
    //     'risk',
    // ],
};

export default withLocalize(OneTimaInvestment);
