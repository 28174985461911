const GLOBAL_PATH_API = '/api';
const GLOBAL_PATH_CONTESTS = `${GLOBAL_PATH_API}/contests`;

export const GET_CONTESTS = `${GLOBAL_PATH_CONTESTS}/contests`;
export const GET_CONTEST_LIST_MEMBERS = `${GLOBAL_PATH_CONTESTS}/contests-members/by-contest-id`;
export const DISQUALIFY_MEMBER = `${GLOBAL_PATH_CONTESTS}/contests-members`;
export const GET_CONTEST_TRANSLATION = `${GLOBAL_PATH_CONTESTS}/contests-translations/by-contest-id`;
export const GET_TRANSLATIONS = `${GLOBAL_PATH_CONTESTS}/contests-translations`;
export const GET_ALL_NATIONALITY = `${GLOBAL_PATH_API}/core/nationality/drop-down?add_fields[]=alpha_2&sort=name,asc`;
export const GET_ALL_LANGUAGES = `${GLOBAL_PATH_API}/core/language/drop-down?add_fields[]=code&sort=name,asc`;
export const DELETE_CONTEST = `${GLOBAL_PATH_CONTESTS}/contests`;
export const DELETE_TRANSLATION = `${GLOBAL_PATH_CONTESTS}/contests-translations`;
export const ADD_TRANSLATION = `${GLOBAL_PATH_CONTESTS}/contests-translations`;
