import React, { Component, } from "react";
import { withRouter, Redirect, } from "react-router-dom";
import { withLocalize, } from "react-localize-redux";

import { enumService, } from "../../../../../services/EnumDataService";
import { userService, } from "../../../../../services/UserDataService";
import { clientService, } from "../../../../../services/ClientDataService";
import { profileService, } from "../../../../../services/ProfileDataService";
import { getPartnerData, } from "../../../../../services/PartnersRequestService";
import NotificationService from "../../../../../services/NotificationService";
import {
	getAccountAddresses,
	getClientAsync,
} from "../../../../clients/services/AccountService";
import { GET_ACCOUNT_HISTORY, } from "../../../../../apiRoutes";

import Loader from "../../../../tima/components/Loader";
import Preloader from "../../../../../components/Common/Preloader";
import { PartnerCardHeader, PartnerCardTabs, } from "./components/index";
import HistoryChangesComponent from "../../../../../components/HistoryChanges";
class PartnerCard extends Component {
	constructor (props) {
		super(props);
		this.state = {
			account: {},
			activeTab: this.props.activeTab,
			dataFetchingFailed: false, //if trying to access non-existed entity or error occurs while fetching, toggle to redirect
			emailsAsSpam: false,
			// elDom: null,
			// elHeight: {},
			loaded: false,
			partner: {},
			partnerId: this.props.match.params.id,
			profile: {},
			showHistoryChanges: false,
			usersAllAvatar: [],

		};
	}

	get translate () {
		return this.props.translate;
	}

	get isBackOfficeOrTechSupport () {
		// eslint-disable-next-line max-len
		return [ 5, 15, ].includes(this.state.profile?.data?.permission_template_id);
	}

	async componentDidMount () {
		try {
			await this.onDataLoad();

			profileService.subscribe("profile", this.profileChange, this);
			userService.subscribe("getAllAvatar", this.avatarsChange, this);
			userService.subscribe("getUser", this.userChange, this);

			enumService.subscribe("enums", this.enumsChange, this);
			await enumService.enums;

			await profileService.profile;
			const profileId = this.state?.profile?.data?.id;

			await userService.getUser(profileId);

			const usersAllAvatar = await userService.getAllAvatar();

			this.isBackOfficeOrTechSupport
				? await this.checkAccountInSpamList()
				: null;

			// window.addEventListener("resize", this.winResizeRefBlock);

			await this.save({
				usersAllAvatar,
			});
		} catch (error) {
			console.log(error);
			error?.showErrorNotification?.();
		} finally {
			await this.save({ loaded: true, });
		}
	}

	componentWillUnmount () {
		userService.unsubscribe("getAllAvatar", this.avatarsChange, this);
		userService.unsubscribe("getUser", this.userChange, this);
		enumService.unsubscribe("enums", this.enumsChange, this);
		profileService.unsubscribe("profile", this.profileChange, this);

		window.removeEventListener("resize", this.winResizeRefBlock);
	}

	save = state => new Promise(next => this.setState(state, next));

	onDataLoad = async () => {
		const setData = (partnerData, accountData) =>
			this.save({
				account: accountData.data.core,
				partner: partnerData.data,
			});

		await (async () => {
			try {
				const partnerData = await getPartnerData(this.state.partnerId);
				const partnerAccountId = partnerData.data.core.account.id;
				const accountData = await getClientAsync(partnerAccountId);

				await setData(partnerData, accountData);
			} catch (error) {
				console.log(error);
				await this.save({ dataFetchingFailed: true, });
			} finally {
				this.getAccountAdresses();
			}
		})();
	};

	refreshClientData = async () => {
		try {
			await this.save({ loaded: false, });
			await this.onDataLoad();
		} catch (error) {
			error.showErrorNotification();
		} finally {
			await this.save({ loaded: true, });
		}
	};

	enumsChange = async (enums) => {
		if (enums.hash === this.state?.enums?.hash) {
			return `${ this.constructor.name }.enumsChange: false`;
		}

		await this.save({ enums, });

		return `${ this.constructor.name }.enumsChange: true`;
	};

	getAccountAdresses = async () => {
		const cacheAddresses = this.state.account.account_addresses;
		const response = await getAccountAddresses(this.state.partnerId);

		await response &&
			response.data.map(item =>
				cacheAddresses.push(item.core.account_addresses),
			);

		await this.save({
			account: {
				...this.state.account,
				account_addresses: cacheAddresses,
			},
		});
	};

	profileChange = async (profile) => {
		if (profile.hash === this.state?.profile?.hash) {
			return `${ this.constructor.name }.profileChange: false`;
		}
		await this.save({ profile, });

		return `${ this.constructor.name }.profileChange: true`;
	};

	avatarsChange = async (usersAllAvatar) => {
		if (usersAllAvatar.hash === this.state?.usersAllAvatar?.hash) {
			return `${ this.constructor.name }.avatarsChange: false`;
		}
		await this.save({ usersAllAvatar, });

		return `${ this.constructor.name }.avatarsChange: true`;
	};

	userChange = async (user) => {
		if (user.hash === this.state?.user?.hash) {
			return `${ this.constructor.name }.userChange: false`;
		}
		if (user.id && user.id !== this.state?.profile?.data?.id) {
			return `${ this.constructor.name }.userChange: false`;
		}
		await this.save({ user, });

		return `${ this.constructor.name }.userChange: true`;
	};

	checkAccountInSpamList = async () => {
		try {
			const emailsAsSpam = await clientService.checkAccInSpamList({
				account_id: +this.state?.partnerId,
			});

			await this.save({ emailsAsSpam, });
		} catch (e) {
			console.error(e);
			await this.save({ emailsAsSpam: false, });
		}
	};

	deleteAccountFromSpam = async () => {
		try {
			await clientService.delFromSpam({ account_id: +this.state?.userId, });
			await this.save({ emailsAsSpam: false, });
		} catch (error) {
			NotificationService.error({
				message: this.props.translate("spam_error"),
				remove: false,
				title: "error",
			});
			await this.save({ emailsAsSpam: false, });
		}
	};

	showDeleteFromSpamBlock = () => {
		const emailsAsSpam = this.state.emailsAsSpam;

		if (
			this.isBackOfficeOrTechSupport &&
			emailsAsSpam.mail_validate &&
			emailsAsSpam.is_suppression_list
		) {
			return (
				<div className="spam-block">
					<span>{this.props.translate("spam_message")}</span>
					<span
						className="spam-block_delete"
						onClick={ () => this.deleteAccountFromSpam() }
					>
						{this.props.translate("spam_del_btn")}
					</span>
				</div>
			);
		} else if (
			this.isBackOfficeOrTechSupport &&
			!emailsAsSpam.mail_validate &&
			!emailsAsSpam.is_suppression_list
		) {
			return (
				<div className="spam-block">
					<span>{this.props.translate("spam_message")}</span>
					<span className="spam-block_cannot-delete">
						{this.props.translate("cannot_spam_del_btn")}
					</span>
				</div>
			);
		}
	};

	showHistoryChanges = () => {
		this.setState({
			showHistoryChanges: true,
		});
	};

	closeHistoryChangesComponent = () => {
		this.setState({
			showHistoryChanges: false,
		});
	};

	render () {
		const {
			activeTab,
			account,
			loaded,
			user,
			usersAllAvatar,
			enums,
			partnerId,
			emailsAsSpam,
			profile,
			partner,
			dataFetchingFailed,
		} = this.state;

		const translate = this.translate;

		return (
			<>
				{ dataFetchingFailed && <Redirect to={ '/404' } /> }

				<Loader loaded={ loaded } loading={ <Preloader scale={ 1 } /> }>
					<div className="partner-card">
						{this.state.emailsAsSpam ? this.showDeleteFromSpamBlock() : null}

						{this.state.showHistoryChanges ? (
							<HistoryChangesComponent
								closeFunction={ this.closeHistoryChangesComponent }
								url={ `${ GET_ACCOUNT_HISTORY }/${ this.state.partnerId }` }
							/>
						) : null}

						<PartnerCardHeader
							account={ account }
							activeTab={ activeTab }
							emailsAsSpam={ emailsAsSpam }
							enums={ enums?.data }
							partner={ partner }
							partnerId={ partnerId }
							profile={ profile }
							refreshClientData={ this.refreshClientData }
							showHistoryChanges={ this.showHistoryChanges }
							toggleLoaded={ () => this.save({ loaded: !this.state.loaded, }) }
							translate={ translate }
							user={ user }
							usersAllAvatar={ usersAllAvatar }
						/>
						<PartnerCardTabs
							account={ account }
							activeTab={ activeTab }
							enums={ enums }
							isBackOfficeOrTechSupport={ this.isBackOfficeOrTechSupport }
							partner={ partner }
							partnerId={ partnerId }
							refreshClientData={ this.refreshClientData }
							translate={ translate }
						/>
					</div>
				</Loader>
			</>
		);
	}
}

export default withRouter(withLocalize(PartnerCard));