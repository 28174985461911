export const PARTNERS_LIST_EXPORT_FIELDS = {
	partners: [
		"core-account-id",
		"core-account-name",
		"core-account-surname",
		"core-user-name",
		"core-user-surname",
		"core-system_accounts-id",
		"core-country-id",
		"core-country-name",
		"partners-partners_setup-status",
		"partners-referral_code->array->partners-referral_code-ref_short",
		"partners-daily_statistic-total_commission",
		"partners-referral_register-referral_count",
		"partners-partners_setup-created_at",
	],
};