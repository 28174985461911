import {
  itemsBuild,
  itemsDrain,
  itemsResort,
  CFG_ITEM_TYPE,
} from '../Config';
// import {} from '../Mentor/withDropDown';

export default {
  id: 132,
  ...[

    // filters
    ...[ {
      f: 'tima.tima_investment.id',
      id: 'f:tiId',
    }, {
      f: 'tima.tima_investment.name',
      id: 'f:tiName',
      o: 'like',
    }, {
      f: [ 'core.account.surname', 'core.account.name', ],
      id: 'f:aFullname',
      o: 'like',
    }, {
      f: 'tima.tima_manager.name',
      id: 'f:tmName',
      o: 'like',
    }, {
      f: 'core.trading_accounts.acc_ccy',
      id: 'f:taCurrency',
    }, {
      f: 'tima.tima_investment.amount',
      id: 'f:tiAmount',
    }, {
      f: 'tima.tima_investment.status',
      id: 'f:tiStatus',
    }, {
      f: 'tima.tima_investment.created_at',
      id: 'f:tiCreated',
    }, {
      f: 'tima.tima_investment.date_closed',
      id: 'tiAtClosed',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

    // orders
    ...[ {
      f: '',
      id: 'o:',
      off: true, // TODO:
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

    // panels
    ...[ {
      ii: 'f:tiId',
      t: 'magic_filter_translate_tima_investment_id',
    }, {
      ii: 'f:tiName',
      t: 'magic_filter_translate_tima_investment_name',
    }, {
      ii: 'f:aFullname',
      t: 'magic_filter_translate_tima_investment_account',
    }, {
      ii: 'f:tmName',
      t: 'magic_filter_translate_tima_investment_manager_name',
    }, {
      ii: 'f:taCurrency',
      t: 'magic_filter_translate_tima_investment_currency',
    }, {
      ii: 'f:tiAmount',
      t: 'magic_filter_translate_tima_investment_amount',
    }, {
      ii: 'f:tiStatus',
      t: 'magic_filter_translate_tima_investment_status',
    }, {
      ii: 'f:tiCreated',
      t: 'magic_filter_translate_tima_investment_created_at',
    }, {
      ii: 'tiAtClosed',
      t: 'magic_filter_translate_tima_investment_closed_at',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

  ]
  .map(itemsBuild)
  .reduce(itemsDrain, [])
  .reduce(itemsResort, {}),

  path: 'tima/tima-investment',
  widget: false,
};