export const valuesToInt = (arr, ...keys) => {
	return arr.reduce((accumulator, next) => {
		keys.forEach((key) => {
			next = {
				...next,
				[key]: +next[key],
			};
		});

		return [ ...accumulator, next, ];
	}, []);
};