/* eslint-disable max-len , react/jsx-indent-props , react/jsx-indent , indent , no-mixed-spaces-and-tabs , no-shadow ,*/

import React, { Component, } from "react";
import { withRouter, NavLink, } from "react-router-dom";
import { withLocalize, } from "react-localize-redux";
import { setErrorsFromServer, } from "../utils/helpers";
import { Select, } from "../components";
import { MagicTooltip, } from "../../tima/components/Magic/MagicTooltip";
import { MagicConfirm, } from '../../tima/components/Magic/MagicConfirm';
import { menu, } from './config/configPartnersSystemMenu';
import PartnersSystemHeader from './ components/PartnersSystemHeader';
import Loader from '../../tima/components/Loader';
import Preloader from '../../../components/LoadingHOC/Preloader';
import PermissionService from '../../../services/PermissionService';

import * as configBreadcrumbs from './config/BreadcrumbsConfig';
import {
 getPartnersAvailableCurrencies, getPartnersPrograms, getPartnersCurrency, postPartnersCurrency, getPartnersProgramsSetting,
} from '../../../services/PartnersRequestService';
class PartnersSystem extends Component {
	constructor (props) {
		super(props);

		this.state = {
			chosenCurrency: {},
			confirmIsVisible: false,
			currencies: "",
			isLoaded: true,
			partnersPrograms: [],
			serverCurrency: "",
		};
	}

	save = state => new Promise(next => this.setState(state, next));

	get translate () {
		return this.props.translate;
	}

	get isDisabled () {
		const programsSettingConfig = this.programsSettingConfig;
		const permissionToStore = this.getPermissions(programsSettingConfig, 'store');

		return this.state.partnersPrograms.length && this.state.serverCurrency || !permissionToStore;
	}

	get noCurrency () {
		return !this.state.serverCurrency ? "not-clickable" : "";
	}

	get programsSettingConfig () {
		return [ 'partners', 'programs_setting', ];
	}

	handleOnChange = async (event) => {
		const { value, } = event.target;

		await this.save({
			chosenCurrency: value.value,
			confirmIsVisible: true,
		});
	};

	getData = () => {
		const currency_id = this.state.chosenCurrency;
		const currentTime = `${ new Date().toISOString().split("T")[0] } ${
			new Date().toTimeString().split(" ")[0]
		}`;

		return {
			created_at: currentTime,
			currency_id,
			updated_at: currentTime,
		};
	};

	confirmChosenCurrency = async () => {
		try {
			const data = this.getData();

			await this.save({ isLoaded: false, });
			const newServerCurrency = await postPartnersCurrency(data);

			if (await newServerCurrency) {
				await this.save((state) => {
					return {
						chosenCurrency: {},
						serverCurrency: state.chosenCurrency,
					};
				});
			}
		} catch (error) {
			await setErrorsFromServer(error);
		} finally {
			await this.save({
				confirmIsVisible: false,
				isLoaded: true,
			});
		}
	};

	rejectChosenCurrency = async () => {
		await this.save(() => {
			return {
				chosenCurrency: {},
				confirmIsVisible: false,
			};
		});
	};

	componentDidMount = async () => {
		await this.save({ isLoaded: false, });
		try {
			//allSettled becomes handy when user don't have a permission to some route, so we can get at least
			//some data setted
			const [ serverCurrency, partnersPrograms, currencies, ] = await Promise.allSettled([
				getPartnersCurrency(),
				getPartnersPrograms(),
				getPartnersAvailableCurrencies(),
			]);

			await this.save({
				currencies: Object.values(currencies.value ?? {}),
				isLoaded: true,
				partnersPrograms: partnersPrograms?.value.data ?? [],
				serverCurrency: serverCurrency.value,
			});
		} catch (error) {
			console.log(error);
		}
	};

	renderCurrencySelect () {
		const { currencies, serverCurrency, } = this.state;
		const currency = currencies
			? currencies?.find(currency => +currency.id === +serverCurrency)
			: "";

		const isDisabled = this.isDisabled;
		const translate = this.translate;
		const noCurrency = this.noCurrency;

		const options = currencies
			? currencies.map((currency) => {
					return {
						label: currency.code,
						value: currency.id,
					};
			  })
			: [ { label: "", value: "", }, ];
		const infoBlockContent = noCurrency
			? translate("partners_system_please_choose_currency")
			: !isDisabled
				? translate("partners_system_no_programms")
				: "";

		const programsSettingConfig = this.programsSettingConfig;
		const permissionToShow = this.getPermissions(programsSettingConfig, 'show');

		if (!permissionToShow) {
			return null;
		}

		const selectValue = options?.find(option => option.value === serverCurrency) ?? {};

		return (
			<section className="partners-system__affiliate-programs-currency">
				<div>
					<div className="flex-row">
						<h2 className="partners-system__title--secondary">
							{translate("partners_system_affiliate_program_currency")}
						</h2>
						{noCurrency ?
							""
						 : (
							<MagicTooltip
								content={ translate("partners_system_chosen_currency").replace(
									"{currency}",
									currency?.code,
								) }
							/>
						)}
					</div>
					<Select
						disabled={ isDisabled }
						hideLabel={ true }
						isRequired={ true }
						key={ `symbol_group` }
						name={ `symbol_group` }
						options={ options?.filter(option => option.value !== serverCurrency) }
						placeholder={ translate("partners_not_select") }
						value={ selectValue }
						wrapperClassName={ `partners-system__select` }
						onChange={ e => this.handleOnChange(e) }
					/>
				</div>

				{isDisabled ?
					""
				 : (
					<p className="partners-system__description">{infoBlockContent}</p>
				)}
			</section>
		);
	}

	getPermissions = (path, action) => {
		const pm = PermissionService.calc([ {
			path,
			key: 'user',
		}, ]);

        return pm.user.access(action);
	}

	findFirstAccessableLink = (element) => {
		const accessableChildren = element.children.find(children => this.getPermissions(children.path, 'index'));

 		return accessableChildren?.to
	}

	renderMenu (translate) {
		return menu.map((element) => {
			const link = this.findFirstAccessableLink(element);

			if (!link) {
				 return '';
			} //no link meens that user dosn't have permissions for any of submenus

			return (
				<li className="menu__element">
					<NavLink className="menu__card" to={ link }>
						<p className="menu__title">{translate(element.label)}</p>
					</NavLink>
				</li>
			);
		});
	}

	render () {
		const { confirmIsVisible, isLoaded, } = this.state;
		const translate = this.translate;
		const noCurrency = this.noCurrency;
		const breadcrumbsItems = configBreadcrumbs.breadcrumbs4AffiliateSystem;

		return (
			<Loader
				className={ "partners-loader" }
				loaded={ isLoaded }
				loading={ <Preloader scale={ 1 } /> }
				translate={ translate }
			>
			<div className="partners-system">
				<PartnersSystemHeader
					breadcrumbsItems={ breadcrumbsItems }
					translate={ translate }
				/>
				{this.renderCurrencySelect()}

				<nav className={ noCurrency }>
					<ul className="menu">
						{this.renderMenu(translate)}
					</ul>
				</nav>

				<Loader
					className={ "partners-loader" }
					loaded={ isLoaded }
					loading={ <Preloader scale={ 1 } /> }
					translate={ translate }
				>
					<MagicConfirm
						accept={ translate("symbols_remove_accept") }
						className={ "magic-confirm confirm-currency" }
						isVisible={ confirmIsVisible }
						reject={ translate("symbols_remove_reject") }
						title={ translate("client_delete_are_u_sure") }
						onAccept={ this.confirmChosenCurrency }
						onReject={ this.rejectChosenCurrency }
					/>
				</Loader>
			</div>
			</Loader>
		);
	}
}

export default withRouter(withLocalize(PartnersSystem));