
import React from 'react';
import PermissionService, { MODULE_KEYS as pm, } from '../../../../services/PermissionService';

import Banners from "./Banners";
import TargetPage from "./TargetPage";
import Logos from "./Logos";
import Lendings from "./Lendings";
export const promoConfig = [
	{
		permission: pm.PARTNERS_PROMO,
		action: PermissionService.indexRoute(),
		component: <Banners />,
		path: [
			'/partners/promo/banners',
		],
	},
	{
		permission: pm.PARTNERS_TARGET_PAGE,
		action: PermissionService.indexRoute(),
		component: <TargetPage />,
		path: [
			'/partners/promo/target-page',
		],
	},
	{
		permission: pm.PARTNERS_LOGOS,
		action: PermissionService.indexRoute(),
		component: <Logos />,
		path: [
			'/partners/promo/logos',
		],
	},
	{
		permission: pm.PARTNERS_LENDINGS,
		action: PermissionService.indexRoute(),
		component: <Lendings />,
		path: [
			'/partners/promo/lending',
		],
	},
];