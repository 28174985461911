const GLOBAL_PATH_API = '/api';
const GLOBAL_PATH_CORE = `${GLOBAL_PATH_API}/core`;
const GLOBAL_PATH_PARTNERS = `${GLOBAL_PATH_API}/partners`;

/*partners*/
export const GET_PARTNERS_REQUESTS = `${GLOBAL_PATH_PARTNERS}/requests`;
export const GET_PARTNERS_REFERRALS = `${GLOBAL_PATH_PARTNERS}/referrals`;
export const GET_PARTNERS_REFERRALS_BY_PARTNER = `${GET_PARTNERS_REFERRALS}/by-partner_id`;
export const GET_PARTNERS_STATISTIC = `${GLOBAL_PATH_PARTNERS}/statistic`;
export const GET_PARTNERS_ACCRUALS = `${GLOBAL_PATH_PARTNERS}/accruals`;
export const GET_PARTNERS_LIST = `${GET_PARTNERS_ACCRUALS}/get-all-partners?take=all`;

/* partners admin panel */
export const GET_PARTNERS_GROUPS = `${GLOBAL_PATH_PARTNERS}/groups-lists`;
export const GET_PARTNERS_GROUPS_ALL = `${GET_PARTNERS_GROUPS}?take=all`; // get all
export const GET_PARTNERS_SYMBOLS = `${GLOBAL_PATH_PARTNERS}/symbols-lists`;
export const GET_PARTNERS_SYMBOLS_ALL = `${GET_PARTNERS_SYMBOLS}?take=all`; // get all
export const GET_PARTNERS_CONDITIONS = `${GLOBAL_PATH_PARTNERS}/conditions`;

export const CHECK_ACCOUNT_REFERRAL = `${GLOBAL_PATH_CORE}/account/check-account-referral`;
export const CHECK_ACCOUNT_PARTNER = `${GLOBAL_PATH_CORE}/account/check-account-partner`;
export const CHECK_SYSTEM_ACCOUNT_REFERRAL = `${GLOBAL_PATH_CORE}/system-accounts/check-referral`;
export const CHECK_SYSTEM_ACCOUNT_PARTNER = `${GLOBAL_PATH_CORE}/system-accounts/check-partner`;

export const CREATE_PARTNERS_REFERRAL = `${GET_PARTNERS_REFERRALS}/create`;

/* lists */
export const GET_USERS_LIST = `${GLOBAL_PATH_API}/core/user/drop-down`;
export const GET_TRADE_SERVER_LIST = `${GLOBAL_PATH_API}/core/dictionary-trade-servers`;
export const GET_GROUPS_LIST = `${GLOBAL_PATH_API}/core/mt4-groups/getAllGroups`; // get all groups
export const GET_GROUPS_LIST_BY_TRADING_ACC_ID = `${GLOBAL_PATH_API}/core/mt4-groups/getAllGroupsByTradingAccount`; // get all groups by trading account (currency and [demo|live])
export const GET_SYMBOLS_LIST = `${GLOBAL_PATH_PARTNERS}/symbols?take=all`; // get all symbols
export const GET_SYMBOLS_GROUPS_LIST = `${GLOBAL_PATH_PARTNERS}/symbols-groups`;

