import React, { Component } from 'react';
import { withLocalize } from "react-localize-redux";
import { Collapse, CardBody, Card, Input, Label } from 'reactstrap';
import ModalBTNTranslation from "./ModalBTNTranslation";
import { E_MAIL_KEY, E_MAIL_TEMPLATE, EDIT_TEMPLATE, EDIT_TEMPLATE_TRANSLATION, SMS_TEMPLATE } from '../constants';
import SliderMailTemplate from './SliderMailTemplate';
import NotificationService from "../../../../../services/NotificationService";
import { MagicInput, MagicSelect, MagicTemplate } from '../../../../../components/Magic';
import FileService from "../../../../../components/FileService/FileService";
import Preloader from "../../../../../components/LoadingHOC/Preloader";
import { templateService } from "../../../../../services/TemplateDataService";

class ExistTemplate extends Component {
    constructor (props) {
        super(props);

        this.state = {
            addTemplateCollaps:    this.props.closeLanguageTemplate,
            selectLangChange:      null,
            typeTemplateTranslate: null,
            updateSMSTemplateTranslate: {
                id:          this.props?.modalTemplatesTranslation?.sms_template_translation?.id ?? null,
                language_id: this.props?.modalTemplatesTranslation?.sms_template_translation?.language_id ?? null,
                text:        this.props?.modalTemplatesTranslation?.sms_template_translation?.text ?? '',
            },
            updateMailTemplateTranslate: {
                id:          this.props?.modalTemplatesTranslation?.mail_template_translation?.id ?? null,
                language_id: this.props?.modalTemplatesTranslation?.mail_template_translation?.language_id ?? null,
                text:        this.props?.modalTemplatesTranslation?.mail_template_translation?.text ?? '',
                subject:     this.props?.modalTemplatesTranslation?.mail_template_translation?.subject ?? '',
            },
            formTemplateStatus: false,
            templateDocs:       this.props?.modalTemplatesTranslation?.mail_template_t_documents ?? [],
            documentsChange:    '',
            errors: {},
            sendRequest: false,
            counterOnlyText: 0,
            maxLengthCounter: 3500,
        };

        this.editor = React.createRef();
        const fileServiceInstance = new FileService();

        Object.defineProperties(this, {
            fileServiceInstance: { get: () => fileServiceInstance },
        });
    }

    save = async (state) => new Promise(next => this.setState(state, next));

    documentsChange = async () => this.save({ documentsChange: Date.now() });

    async componentDidMount () {
        await this.sortValueSelect(null);
        await this.fileServiceInstance.subscribe(this.documentsChange);
        await this.fileServiceInstance.updateDocument(this.state.templateDocs);
        const counterOnlyText = await this.textCounter();
        await this.save({ counterOnlyText });
    }

    async UNSAFE_componentWillReceiveProps (nextProps) {
        await this.sortValueSelect(nextProps);
    }

    async componentWillUnmount () {
        await this.fileServiceInstance.unsubscribe(this.documentsChange);
    }

    toggleTemplateCollaps = async (typeTemplateTranslate) => {
        await this.save(state => ({
            addTemplateCollaps: !state.addTemplateCollaps,
            typeTemplateTranslate,

        }));
    };

    sortValueSelect = async (props) => {
        let langId;
        if (props) {
            langId = this.props.popUpTemplateManagement.modal_name === SMS_TEMPLATE ? props.modalTemplatesTranslation['sms_template_translation'].language_id : this.props.popUpTemplateManagement.modal_name === E_MAIL_TEMPLATE ? props.modalTemplatesTranslation['mail_template_translation'].language_id : null;
            await this.save({
                updateSMSTemplateTranslate: {
                    id: props?.modalTemplatesTranslation?.sms_template_translation?.id ?? null,
                    text: props?.modalTemplatesTranslation?.sms_template_translation?.text ?? '',
                    language_id: props?.modalTemplatesTranslation?.sms_template_translation?.language_id ?? null,
                },
                updateMailTemplateTranslate: {
                    id: props?.modalTemplatesTranslation?.mail_template_translation?.id ?? null,
                    subject: props?.modalTemplatesTranslation?.mail_template_translation?.subject ?? '',
                    text: props?.modalTemplatesTranslation?.mail_template_translation?.text ?? '',
                    language_id: props?.modalTemplatesTranslation?.mail_template_translation?.language_id ?? null,
                },
                templateDocs: props?.modalTemplatesTranslation?.mail_template_t_documents ?? [],
            });
            await this.fileServiceInstance.updateDocument(this.state.templateDocs);
            this.props?.language?.forEach?.(async (item) => {
                if (item.value === langId) {
                    await this.save({ selectLangChange: item.value });
                }
            });
        } else {
            langId = this.props.popUpTemplateManagement.modal_name === SMS_TEMPLATE ? this.props.modalTemplatesTranslation['sms_template_translation'].language_id : this.props.popUpTemplateManagement.modal_name === E_MAIL_TEMPLATE ? this.props.modalTemplatesTranslation['mail_template_translation'].language_id : null;
            this.props?.language?.forEach?.(async (item) => {
                if (item.value === langId) {
                    await this.save({ selectLangChange: item.value });
                }
            });
        }
    };

    setToState = async (name, value) => {
        name === 'language_id' ? await this.save({ selectLangChange: value }) : null;
        if (this.props.popUpTemplateManagement.modal_name === SMS_TEMPLATE) {
            await this.save((state) => {
                return {
                    updateSMSTemplateTranslate: { ...state.updateSMSTemplateTranslate, [name]: value },
                };
            });
        } else if (this.props.popUpTemplateManagement.modal_name === E_MAIL_TEMPLATE) {
            await this.save((state) => {
                return {
                    updateMailTemplateTranslate: { ...state.updateMailTemplateTranslate, [name]: value },
                };
            });
        }
    };

    updateTemplateTranslation = async (translate) => {
        if (await this.validationFields()) {
            await this.save({ sendRequest: true });
            try {
                if (this.props.popUpTemplateManagement.modal_name === SMS_TEMPLATE) {
                    await templateService.updateSmsTemplatesTranslation(this.state.updateSMSTemplateTranslate.id, this.state.updateSMSTemplateTranslate);
                    await this.props.reloadTemplatesTranslation(this.props.popUpTemplateManagement.template_id, SMS_TEMPLATE);
                } else if (this.props.popUpTemplateManagement.modal_name === E_MAIL_TEMPLATE) {
                    await templateService.updateMailTemplatesTranslation(this.state.updateMailTemplateTranslate.id, this.state.updateMailTemplateTranslate);
                    await this.checkFilesForSend();
                    await this.props.togglePopUpTemplateManagement(E_MAIL_KEY, "EDIT_TEMPLATE", this.props.popUpTemplateManagement?.template_id);
                }
                await this.props._getTempl();
                await this.toggleTemplateCollaps(EDIT_TEMPLATE_TRANSLATION);
                await this.fileServiceInstance.updateDocument(this.state.templateDocs);
                NotificationService.success({
                    title:   'success',
                    message: translate(`template_management_update_template_translation`),
                    remove:  true,
                    view:    true,
                });
                await this.save({ sendRequest: false });
            } catch (error) {
                await this.save({ sendRequest: false });
                this.errorFromServer(error.response.data.errors);
            }
        } else {
            return null;
        }
    };

    checkFilesForSend = async () => {
        const getDocuments = this.fileServiceInstance.uploadingFiles,
            getStatusNewFiles = this.fileServiceInstance.newFiles,
            maxCountOfDocs = 6;
        if (getStatusNewFiles && getDocuments.length < maxCountOfDocs) {
            for (let i = 0; i < getDocuments.length; i++) {
                const arrayElem = getDocuments[i];
                !arrayElem.id ? await this._uploadDocumentMailTempl(arrayElem.file) : null;
            }
        } else {
            return (
                NotificationService.error({
                    title:   'error',
                    message: this.props.translate('template_management_error_file_max_length'),
                    remove:  true,
                    view:    true,
                })
            );
        }
    };

    _uploadDocumentMailTempl = async (file) => {
        try {
            const formData = new FormData();

            formData.set('file', file);
            formData.set('template_translate_id', this.props?.modalTemplatesTranslation?.mail_template_translation?.id);

            await templateService.uploadDoc(formData);
            NotificationService.success({
                title:   'success',
                message: this.props.translate(`template_management_upload_doc_translation`),
                remove:  true,
                view:    true,
            });
        } catch (error) {
            error.response.data.errors.file?.forEach?.(item => {
                return (
                    NotificationService.error({
                        title:   'error',
                        message: item,
                        remove:  true,
                        view:    true,
                    })
                );
            });
        }
    };

    deleteTemplateTranslation = async (translate) => {
        try {
            await this.save({ sendRequest: true });
            if (this.props.popUpTemplateManagement.modal_name === SMS_TEMPLATE) {
                await templateService.deleteSmsTemplatesTranslation(this.state.updateSMSTemplateTranslate.id);
                await this.props.reloadTemplatesTranslation(this.props.popUpTemplateManagement.template_id, SMS_TEMPLATE);
            } else if (this.props.popUpTemplateManagement.modal_name === E_MAIL_TEMPLATE) {
                await templateService.deleteMailTemplatesTranslation(this.state.updateMailTemplateTranslate.id);
                await this.props.reloadTemplatesTranslation(this.props.popUpTemplateManagement.template_id, E_MAIL_TEMPLATE);
            }
            await this.props._getTempl();
            await this.fileServiceInstance.updateDocument(this.props?.modalTemplatesTranslation?.mail_template_t_documents ?? []);
            NotificationService.success({
                title:   'success',
                message: this.props.translate(`template_management_delete_template_translation`),
                remove:  true,
                view:    true,
            });
            this.toggleTemplateCollaps(EDIT_TEMPLATE_TRANSLATION);
            await this.save({ sendRequest: false });
        } catch (error) {
            await this.save({ sendRequest: false });
            NotificationService.errors(error.response.data.errors);
        }
    };

    deleteDocument = async (itemIndex) => {
        try {
            const documentForDelete = await this.fileServiceInstance.filterDocumentArray(itemIndex);
            documentForDelete[0].id ? await templateService.deleteDoc(documentForDelete[0].id) : null;
            await this.fileServiceInstance.changeDocuments();
            NotificationService.success({
                title:   'success',
                message: this.props.translate(`template_management_delete_doc_translation`),
                remove:  true,
                view:    true,
            });
        } catch (error) {
            error.response.data.errors.file?.forEach?.(item => {
                return (
                    NotificationService.error({
                        title:   'error',
                        message: item,
                        remove:  true,
                        view:    true,
                    })
                );
            });
        }
    };

    inputFileHandler = async (event) => {
        const options = {
            size: 10485760, //size in kilobytes
        };
        const uploadFiles = await this.fileServiceInstance.getFileFromInput(options)(event);
        !uploadFiles ? NotificationService.error({
            title:   'error',
            message: this.props.translate('template_management_error_size'),
            remove:  true,
            view:    true,
        }) : null;
    };

    setTemplateToState = async (value) => {
        const counterOnlyText = this.textCounter();
        if (counterOnlyText > this.state.maxLengthCounter) {
            return (
                NotificationService.error({
                    title:   'error',
                    message: this.props.translate('template_management_error_max_length'),
                    remove:  false,
                })
            );
        } else {
            await this.save({
                updateMailTemplateTranslate: {
                    ...this.state.updateMailTemplateTranslate,
                    text: value,
                },
                counterOnlyText,
            });
            this.clearErrorForClick('text');
        }
    };

    textCounter = () => this.editor?.current?.querySelector('.ql-editor')?.textContent?.length;

    templateTextBlock = () => {
        if (this.props.popUpTemplateManagement.modal_name === SMS_TEMPLATE) {
            const options = {
                className:   `${this.state.errors.text ? 'error' : ''}`,
                rows:        9,
                name:        'text',
                placeholder: this.props.translate('template_management_enter_body_sms'),
                value:      this.state.updateSMSTemplateTranslate.text ?? '',
                onChange:    async (event) => {
                    this.clearErrorForClick('text');
                    this.setToState(event.target.name, event.target.value);
                },
            };

            return (
                <textarea { ...options } />
            );
        } else if (this.props.popUpTemplateManagement.modal_name === E_MAIL_TEMPLATE) {
            const options = {
                className:    `${this.state.errors.text ? 'error' : ''}`,
                placeholder:  this.props.translate('template_management_enter_body'),
                value:        this.state.updateMailTemplateTranslate.text ?? '',
                handleChange: this.setTemplateToState,
            };

            return (
                <div ref={ this.editor }><MagicTemplate { ...options } /></div>
            );
        }

    };

    renderLanguageSelect = () => {
        const valueDefault = false;
        const values = this.props.dataLanguage ?? [];
        const options = {
            className: [this.state?.errors?.language_id ? 'error' : ''],
            onChange: async (value) => {
                this.clearErrorForClick('language_id');
                await this.setToState('language_id', value);
            },
            value:        this.state.selectLangChange ?? '',
            values:       values?.map?.((data) => data.value) ?? [],
            valueToLabel: (id) => {
                if (id === valueDefault) {
                    return this.props.translate('template_management_choose_language');
                }
                if (values?.length) {
                    const find = values?.find((data) => id === data.value );

                    return find ? find.label : '??';
                }
            },
        };

        return (
            <MagicSelect { ...options } />
        );
    };

    renderInputSubject = () => {
        const inputName = {
            className: [`${this.state.errors.subject ? 'error' : ''} modal_management-textarea-input`],
            sizeable: false,
            onChange: async (value) => {
                this.clearErrorForClick('subject');
                await this.setToState('subject', value);
            },
            placeholder: this.props.translate('template_management_enter_subject'),
            value:       this.state.updateMailTemplateTranslate.subject ?? '',
            InputProps:  {
                autoComplete: 'new-password',
            },
        };

        return (
            <MagicInput { ...inputName } />
        );
    };

    renderFileInput = () => {
        const { popUpTemplateManagement } = this.props;
        const maxLengthOfDocs = 5;

        if (popUpTemplateManagement.modal_name === E_MAIL_TEMPLATE && popUpTemplateManagement.clickTypeTemplate === EDIT_TEMPLATE && this.fileServiceInstance?.uploadingFiles.length < maxLengthOfDocs) {
            return (
                <Label className='button templateManagement_table_btn_addFile'>
                    <Input
                        name='file'
                        type='file'
                        onChange={ async (event) => {this.inputFileHandler(event)}}
                        onClick={ (event) => { event.target.value = ''}}
                    />
                    {this.props.translate(`template_management_add_template_add-file`)}
                </Label>
            );
        } else { return null; }
    };

    errorFromServer = object => {
        const serverErrorKeys = Object.keys(object);

        serverErrorKeys.forEach(async (item) => {
            await this.save({
                errors: {
                    ...this.state.errors,
                    [item]: true,
                    id:     false,
                },
            });

            NotificationService.error({
                title:   'error',
                message: object[item],
                remove:  false,
            });
        });
    };

    validationFields = async () => {
        await this.save({ errors: {}});
        const validationError = [];
        if (this.props.popUpTemplateManagement.modal_name === SMS_TEMPLATE) {
            const updateTemplates = Object.fromEntries(
                ['id', 'language_id', 'text'].filter((field) => this.state.updateSMSTemplateTranslate[field] === null
                    || this.state.updateSMSTemplateTranslate[field] === ''
                    || this.state.updateSMSTemplateTranslate[field].length === 0)
                    .map((field) => [field, true]),
            );
            if (Object.keys(updateTemplates).length) {
                await this.save({ errors: updateTemplates });
            }
            Object.keys(this.state.errors).forEach(item => {
                if (this.state.errors[item] === true) {
                    validationError.push(item);
                }
            });
        } else if (this.props.popUpTemplateManagement.modal_name === E_MAIL_TEMPLATE) {
            const updateTemplates = Object.fromEntries(
                ['id', 'language_id', 'subject', 'text'].filter((field) => this.state.updateMailTemplateTranslate[field] === null
                    || this.state.updateMailTemplateTranslate[field] === ''
                    || this.state.updateMailTemplateTranslate[field].length === 0)
                    .map((field) => [field, true]),
            );
            if (Object.keys(updateTemplates).length) {
                await this.save({ errors: updateTemplates });
            }
            Object.keys(this.state.errors).forEach(item => {
                if (this.state.errors[item] === true) {
                    validationError.push(item);
                }
            });
        }
        if (validationError.length) {
            validationError.length = 0;
            return false;
        } else {
            await this.save({ errors: {}});
            validationError.length = 0;
            return true;
        }
    };

    clearErrorForClick = async (name) => {
        await this.save({ errors: { ...this.state.errors, [name]: false }});
    };

    render () {
        // if (!this.props?.modalTemplatesTranslation ) return <Preloader scale={ 0.5 } />;

        return (
            <div className="modal_management-add_item">
                {!this.state.addTemplateCollaps
                    ? <div className="modal_management-add_btn_1" onClick={ () => this.toggleTemplateCollaps(EDIT_TEMPLATE_TRANSLATION) }>
                        {this.props.modalTemplatesTranslation.language[`name_${this.props.lang_locale}`]}
                    </div>
                    : ''}

                <Collapse isOpen={ this.state.addTemplateCollaps }>
                    <Card>
                        <CardBody>
                            <div className = 'form-group'>
                                <div className = 'form-select'>
                                    { this.renderLanguageSelect() }
                                </div>
                            </div>
                            {this.props.popUpTemplateManagement.modal_name === E_MAIL_TEMPLATE ?
                                <div className = 'form-group'>
                                    { this.renderInputSubject() }
                                </div>
                                : ''}

                            <div className = 'form-group'>
                                {
                                    this.props.popUpTemplateManagement.modal_name === E_MAIL_TEMPLATE ?
                                        <div className="template-counter">
                                            <p className="counter">{this.state.counterOnlyText} / {this.state.maxLengthCounter}</p>
                                        </div>
                                        : null
                                }
                                {
                                    this.templateTextBlock()
                                }
                            </div>

                            <div className='modal_management-history'>
                                <span onClick={ () => {
                                    if (this.props.popUpTemplateManagement.modal_name === SMS_TEMPLATE) {
                                        this.props.showHistoryChangesTemplateTranslation(Object.keys(this.state.updateSMSTemplateTranslate).length > 0 ? this.state.updateSMSTemplateTranslate.id : '');
                                    } else if (this.props.popUpTemplateManagement.modal_name === E_MAIL_TEMPLATE) {
                                        this.props.showHistoryChangesTemplateTranslation(Object.keys(this.state.updateMailTemplateTranslate).length > 0 ? this.state.updateMailTemplateTranslate.id : '');
                                    }
                                } }>{this.props.translate(`template_management_history_changes`)}</span>

                                { this.renderFileInput() }

                                <ModalBTNTranslation
                                    popUpTemplateManagement={this.props?.popUpTemplateManagement}
                                    closePopUpModule={this.props?.closePopUpModule}
                                    templateTranslation={this.state.typeTemplateTranslate}
                                    updateTemplateTranslation={this.updateTemplateTranslation}
                                    deleteTemplateTranslation={this.deleteTemplateTranslation}
                                    sendRequest={this.state.sendRequest}
                                />
                            </div>

                            {this.fileServiceInstance?.uploadingFiles?.length > 0
                                && this.props.popUpTemplateManagement.modal_name === E_MAIL_TEMPLATE
                                && this.props.popUpTemplateManagement.clickTypeTemplate === EDIT_TEMPLATE
                                ? <SliderMailTemplate
                                    mail_template_t_documents = { this.fileServiceInstance.uploadingFiles }
                                    updateTemplateTranslation = { this.updateTemplateTranslation }
                                    deleteDocument={ this.deleteDocument }
                                />
                                : ''}
                            <div className="modal_management-add_btn_2" onClick={ async  () => { this.toggleTemplateCollaps(EDIT_TEMPLATE_TRANSLATION)} } />
                        </CardBody>
                    </Card>
                </Collapse>
            </div>
        );
    }
}

export default withLocalize(ExistTemplate);
