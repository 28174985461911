import React, { Component } from 'react';
import { withLocalize, Translate } from "react-localize-redux";
import {EDIT_TEMPLATE, CREATE_TEMPLATE_MANAGEMENT} from '../constants';

class ModalBTN extends Component {
    constructor (props) {
        super(props);

        this.state = {
            are_u_sure: false,
        };
    }

    areSure = () => {
        this.setState((state)=>{
            return { are_u_sure: !state.are_u_sure }
        })
    }

    render () {

        return (
            <Translate>
                {({ translate }) => {
                    return (
                        <div className = 'modal_management-BTN'>

                            {this.props.popUpTemplateManagement.clickTypeTemplate === EDIT_TEMPLATE ?
                                <React.Fragment>
                                    <button className={ `button templateManagement_table_btn_edit ${this.props.sendRequest ? 'disabled' : ''}` }
                                            onClick = { () => {this.props.sendUpdateTemplate(this.props.popUpTemplateManagement.modal_name, translate)} }
                                            disabled = {this.props.sendRequest}>
                                        {translate(`template_management_btn_update`)}
                                    </button>
                                    <button className={ `button templateManagement_table_btn_delete ${this.props.sendRequest ? 'disabled' : ''}` }
                                            onClick={()=>{ this.areSure()} }
                                            disabled = { this.props.sendRequest } >
                                        {translate(`template_management_btn_delete`)}
                                    </button>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <button className='button templateManagement_table_btn_edit' onClick = {() => {
                                        this.props.sendCreateTemplate(this.props.popUpTemplateManagement.modal_key, EDIT_TEMPLATE, this.props.popUpTemplateManagement.modal_name, translate);
                                    }
                                    }>{translate(`template_management_btn_save`)}</button>
                                    <button className='button templateManagement_table_btn_cancel' onClick={()=>{this.props.closePopUpModule()}} >
                                        {translate(`template_management_btn_cancel`)}
                                    </button>
                                </React.Fragment>
                            }

                            {this.state.are_u_sure ?
                                <div className="modal_delete">
                                    <h4>{translate(`template_management_are_u_sure`)}</h4>
                                    <p>{translate(`template_management_you_want_to_delete`)} {CREATE_TEMPLATE_MANAGEMENT[this.props.popUpTemplateManagement.modal_key].label} {translate(`template_management_template`)}</p>
                                    <div className="modal_delete-btn">
                                        <a className = 'btn_yes' onClick={()=>{
                                            this.props.deleteTemplate(this.props.popUpTemplateManagement.template_id, this.props.popUpTemplateManagement.modal_key);
                                            this.props.closePopUpModule();
                                        }}>{translate(`template_management_yes`)}</a>
                                        <a className = 'btn_no' onClick={()=>{this.areSure()}}>{translate(`template_management_no`)}</a>
                                    </div>
                                </div>
                                : ''
                            }

                        </div>
                    );
                }}
            </Translate>
        );
    }
}
export default withLocalize(ModalBTN);
