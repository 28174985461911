import React, { Component } from 'react';
import PropTypes from "prop-types";
import {
		updateAccountMobileDiscount
} from "../../../services/AccountRequestService";
import { withRouter } from "react-router-dom";
import { withLocalize } from "react-localize-redux";

class MobileDiscounBlock extends React.Component {
		static Switchs = {
				1: true,
				2: false
		};
		static fromSwitch = (key, defaultValue) => Object.entries(MobileDiscounBlock.Switchs)
				.filter(([ k, v ]) => k === key)
				.map(([ k, v ]) => v)
				.shift() || defaultValue;

		static propTypes = {
				id: PropTypes.string,
				translate: PropTypes.func,
				refreshClientData: PropTypes.func.isRequired,
		};
		constructor (props) {
				super(props);
				this.state = {
						is_mobile: this.props.client.account.is_mobile,
						userId: 0,
						isDisabledSaveBtn: true,


				}
		}

		save = async (state) => new Promise(next => this.setState(state, next));
		get isMobile () {
				return MobileDiscounBlock.fromSwitch(String(this.state.is_mobile), false);
		}

		set isMobile (checked) {
				this.setSwitchValue('is_mobile', 'is_mobile', checked);
		}

		setSwitchValue = (stateKey, dataKey, checked) => {
				const data = checked === true ? '1' : checked === false ? '2' : '2';
				this.setState({
						[stateKey]: data,
						isDisabledSaveBtn: false,
				}, );
		};
		onConfirmClick = async (e) => {
				e.preventDefault();
				const { is_mobile, } = this.state;

				try {
						await updateAccountMobileDiscount({ is_mobile: is_mobile }, this.props.id,);
				} catch (error) {
						await this.save({ error: true, });
						error?.showErrorNotification?.();
				}
				await this.save({ isDisabledSaveBtn: true })

		};
		render () {

				const { translate, } = this.props;
				const { isDisabledSaveBtn } = this.state;
				return (
						<div className='block-shadowed' style={{ display: 'flex', flexDirection: 'column' , alignItems: 'baseline' }}>
								<div className='block-main_input-checkbox'  style={{ position: 'static',   display: 'inline-block', }} >
										<label className="checkbox">

												<input
														className="checkbox"
														type="checkbox"
														name="is_mobile"
														checked={ this.isMobile }
														onClick={ (event) => this.isMobile = event.target.checked }
												/>
												<span/>
										</label>


								</div>
								<span>{ `${ translate('client_mobile_app_discount') } ` }</span>
								<button className={isDisabledSaveBtn ? "gl-btn gl-btn--disabled" : "gl-btn gl-btn--blue"}
												disabled={ isDisabledSaveBtn }
												onClick={ this.onConfirmClick }
								>				{ translate('partners_save_btn') }

								</button>
						</div>
				)
		}

}

export default  withRouter(withLocalize(MobileDiscounBlock)) ;