import {
  itemsBuild,
  itemsDrain,
  itemsResort,
  CFG_ITEM_TYPE,
} from '../Config';
// import { onEnabled, render, } from '../Mentor/withDropDown';

const marketing = {
  id: 999,
  ...[

    // filters
    ...[ {
      f: 'core.account.id',
      id: 'f:aId',
    }, {
  	  f: 'schema.table.field',
      id: 'f:?',
      off: true,
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

    // orders
    ...[ {
      f: 'core.account.id',
      id: 'o:aId',
    }, {
      f: 'schema.table.field',
      id: 'o:?',
      off: true,
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

    // panels
    ...[ {
      ii: 'f:?',
      t: 'translateKey',
      off: true,
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

  ]
  .map(itemsBuild)
  .reduce(itemsDrain, [])
  .reduce(itemsResort, {}),

  path: 'core/account',
  widget: false,
};

export default [
  marketing,
];