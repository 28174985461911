export const validateCreatePaymentRequest = async (formData) => {
	const errorsMessages = [];

	//we don't want many of these to popup - just a single one
	const addEmptyValueError = () => !errorsMessages.find(item => item.message === 'payment_popup_empty_field_error') && errorsMessages.push({ title: "error", message: 'payment_popup_empty_field_error', remove: false, });

	const errors = Object.entries(formData).reduce((accumulator, entry) => {
		const [ key, value, ] = entry;

		const keyIsException = key === 'destination_tag';

		if (keyIsException) {
			return accumulator;
		}

		switch (key) {
			case 'amount': {
				const is_withdrawal = +formData.type === 2;
				const is_crypto = +formData.payment_method === 6;
				const isSystemDepositOrWithdrawal = [ 4, 5, ].includes(+formData.payment_method);
				const minValue = isSystemDepositOrWithdrawal ? 1 : is_crypto && is_withdrawal ? 100 : 10;
				const maxValue = isSystemDepositOrWithdrawal ? 9_999_999.99 : is_crypto ? 1_000_000 : 14500;

				if (+value < minValue) {
					const valueIsEmptyOrZero = !value;
					const minAmountErrorMessage = isSystemDepositOrWithdrawal ? 'payment_popup_amount_error_1' : is_crypto && is_withdrawal ? 'payment_popup_amount_error_100' : 'payment_popup_amount_error_10';

					valueIsEmptyOrZero ? addEmptyValueError() : errorsMessages.push({ title: "error", message: minAmountErrorMessage, remove: false, });

					return [ ...accumulator, key, ];
				}
				if (+value > maxValue) {
					return [ ...accumulator, key, ];
				}
				break;
			}
			case 'payment_card_name': {
				const isCryptoTransfer = +formData.payment_method === 6;
				const isBankTransfer = +formData.payment_method === 3;
				const ibanRegexp = /^[A-Za-z]{0,2}\d{3,34}$/;
				const ibanIsInvalid = !ibanRegexp.test(value);

				if (isBankTransfer && ibanIsInvalid) {
					value ? errorsMessages.push({ title: "error", message: 'payment_popup_iban_min_length_error', remove: false, }) : addEmptyValueError();

					return [ ...accumulator, key, ];
				}

				if (isCryptoTransfer && value.length < 24 || value.length > 100) {
					value ? errorsMessages.push({ title: "error", message: 'payment_popup_destination_crypto_address_error', remove: false, }) : addEmptyValueError();

					return [ ...accumulator, key, ];
				}
				break;
			}
			case 'destination_tag': {
				if (value.length > 100) {
					return [ ...accumulator, key, ];
				}
				break;
			}
			case 'payment_system': {
				if ([ 4, 5, ].includes(formData.payment_method)) { //exception: system deposit and withdrawal doesn't require payment_system, so we skip
					return [ ...accumulator, ];
				}
				break;
			}
			case 'payment_method': {
				if (!value) {
					return [ ...accumulator, key, ];
				}

				//some fields are not added before onChange event, so we should check either they even exist
				if (value === 6 && !formData.payment_card_name) {
					return [ ...accumulator, 'payment_card_name', ];
				}
				break;
			}
			default: {
				if (!value) {
					addEmptyValueError();

					return [ ...accumulator, key, ];
				}
			}
		}

		return accumulator;
	}, []);

	return [ errors, errorsMessages, ];
};

export const onValidAmount = (value, type, method) => {
	const deposit = type === 1;
	const withdrawal = type === 2;
	const card = method === 'CARD';
	const bank = method === 'BANK_TRANSFER';
	const crypto = method === 'CRYPTO_CURRENCY';
	const wallet = method === 'WALLET';

	let max;
	let min;

	switch (true) {
		case crypto: {
			max = 1_000_000;
			min = 1;
			break;
		}
		case wallet || bank : {
			max = 14_500;
			min = 1;
			break;
		}
		case deposit && card: {
			max = 9_999_999.99;
			min = 1;
			break;
		}
		case withdrawal && card: {
			max = 15_000;
			min = 1;
			break;
		}
		default: {
			max = 9_999_999.99;
			min = 1;
			break;
		}
	}

	const regExp = /^[0-9]*\,?\.?[0-9]{0,2}?$/;

	//allow empty input
	if (value !== "" && !regExp.test(value)) {
		throw new Error('error');
	}

	//by given task can accept '.' or ',', but only if there is no one of them already
	if (value.split('').filter(char => char === '.' || char === ',').length > 1) {
		throw new Error('error');
	}

	if (value > max || value !== "" && value < min) {
		throw new Error('error');
	}

	if ((/^0[0-9]/).test(value)) {
		throw new Error("xxx");
	}
};

export const onValidFee = (value, type, method) => {
	const deposit = type === 1;
	const withdrawal = type === 2;
	const card = method === 'CARD';
	const bank = method === 'BANK_TRANSFER';
	const crypto = method === 'CRYPTO_CURRENCY' || +method === 6;
	const wallet = method === 'WALLET';

	let max;

	switch (true) {
		case crypto: {
			max = 99_999.99;
			break;
		}
		case wallet || bank: {
			max = 999.99;
			break;
		}
		case deposit && card: {
			max = 999_999.99;
			break;
		}
		case withdrawal && card: {
			max = 999.99;
			break;
		}
		default: {
			max = 999_999.99;
			break;
		}
	}

	const regExp = /^[0-9]*\,?\.?[0-9]{0,2}?$/;

	//allow empty input
	if (value !== "" && !regExp.test(value)) {
		throw new Error('error');
	}

	//by given task can accept '.' or ',', but only if there is no one of them already
	if (value.split('').filter(char => char === '.' || char === ',').length > 1) {
		throw new Error('error');
	}

	if (value > max) {
		throw new Error('error');
	}

	if ((/^0[0-9]/).test(value)) {
		throw new Error("xxx");
	}
};

export const getMinAmountForOrder = (type, method) => {
	const is_deposit = type === 1;
	const is_card = method === 'CARD';
	const is_bank = method === 'BANK_TRANSFER';
	const is_crypto = method === 'CRYPTO_CURRENCY';

	switch (true) {
		case is_card: {
			return is_deposit ? 1 : 500;
		}
		case is_crypto: {
			return is_deposit ? 10 : 100;
		}
		case is_bank: {
			return 10;
		} default: {
			return 1;
		}
	}
};