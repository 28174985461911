import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withLocalize } from "react-localize-redux";

import { LIST_ACCOUNTS_EXPORT_FIELDS } from "../../consts/constants";
import { securedRequest } from "../../../../services/RequestService";
import Preloader from "../../../../components/LoadingHOC/Preloader";
import PagePanel from "../../../tima/components/Pages/PagePanel";
import Loader from "../../../tima/components/Loader";
import {
  MagicFilterPanels,
  Mentor,
  showExportToExcelError,
} from "../../../../components/Magic";
import ListTradingAccountsMagicTable from './ListTradingAccountsMagicTable';
import '../../styles/list_accounts.scss';
import PermissionService from "../../../../services/PermissionService";
import { listAccountService } from "../../../../services/ListAccountDataService";

class ListTradingAccounts extends Component {
  constructor (props) {
    super(props);
    this.state = {
      loaded:           false,
      tradingAccounts: {
          data: [],
          hash: null,
      },
      pages:            {
        filter:   0,
        total:    0,
        take:     50,
        variance: 2,
      },
      filterLastChange: Date.now(),
    };

    const mf = new Mentor({
      owner: this,
      serviceId: 72,
      translate: this.props.translate,
    });

    Object.defineProperty(this, 'mf', { get: () => mf, });
  }

  // eslint-disable-next-line react/sort-comp, require-await
  save = async state => new Promise(next => this.setState(state, next));

  // eslint-disable-next-line require-await
  mfChanged = async () => {
    await this.save({ filterLastChange: Date.now(), });
  };

  mfDoing = async () => {
    await this.save({ loaded: false, });
  };

  mfDone = async () => {
    await this.mfChanged();
    await this.onDataLoad();
  };

  tradingAccounts = async (tradingAccounts) => {
      if (tradingAccounts.hash===this.state?.tradingAccounts?.hash) return `${this.constructor.name}.tradingAccounts: false`;
      const { data, meta, hash } = tradingAccounts;
      await this.save({
          tradingAccounts: { data, hash },
          pages: {
              ...this.state.pages,
              filter: meta?.filter,
              total: meta?.total,
          }
      });
      return `${this.constructor.name}.tradingAccounts: true`;
  };

  componentDidMount = async () => {
    this.mf.subscribe({
      changed: this.mfChanged,
      // changing: ,
      cleaned: this.mfDone,
      cleaning: this.mfDoing,
      loaded: this.mfDone,
      loading: this.mfDoing,
      saved: this.mfDone,
      saving: this.mfDoing,
    }, this);
    await this.mf.init({ doEmit: true, });
    listAccountService.subscribe('tradingAccounts', this.tradingAccounts, this);
    // await this.mf.emit('change', {});
    // await this.onDataLoad(); // TODO: moved to mfDone
  };

  componentWillUnmount = () => {
    this.mf.unsubscribe([
      this.mfChanged,
      this.mfDoing,
      this.mfDone,
    ], this);
    listAccountService.unsubscribe('tradingAccounts', this.tradingAccounts, this);
  };

  get tradingAccountConfig () {
    return [
      {
        path: ['core', 'trading_accounts'],
        key:  'tradingAccounts',
      },
    ];
  }

  exportToExcel = (activeTab) => (event) => {
    if (activeTab) {
      const { filterId: filter_id } = this.mf;
      const globalUrl = `/api/core`;
      const exportUrl = `export-to-excel`;

      let url = '',
        file_name = '';

      switch (activeTab) {
        case 'tradingAccounts': {
          url = `${globalUrl}/trading-accounts/${exportUrl}`;
          file_name = `Trading_Accounts`;
          break;
        }
        default: {
          break;
        }
      }

      const options = {
        file_name,
        fields: LIST_ACCOUNTS_EXPORT_FIELDS[activeTab],
      };

      if (filter_id) {
        options['filter_id'] = filter_id;
      }

      securedRequest.post(url, options)
        .then((res) => {
          // fileDownload(res.data, res.headers.filename);
        })
        .catch((error) => {
          showExportToExcelError(error);
        });
    }
  };

  renderExportToExcelBtn = (activeTab) => {
    const { translate } = this.props;
    const conf = PermissionService.calc(this.tradingAccountConfig);
    const exportToExcelPermission = conf?.tradingAccounts?.access('exportToExcel') ?? false;

    return exportToExcelPermission ?
      (
        <div className="button button--turquoise"
           onClick={this.exportToExcel(activeTab)}
        >
          {translate('export_excel_btn')}
        </div>
      ) : null;
  };

  render () {
    const { translate } = this.props;

    return (
      <div className='list_accounts'>

        <div className='content-block'>
          <div className='top-page-block'>
            <h1 className='page-title'>{translate(`list_accounts_trading_accounts`)}</h1>
            {this.renderExportToExcelBtn('tradingAccounts')}
          </div>
        </div>

        <MagicFilterPanels
          mf={ this.mf }
          show={ true }
          translate={ translate }
        />

        <div className='content-block'>
          <div className='table-wrapper'>
            <div className='position-relative'>

              <Loader
                loaded={this.state.loaded}
                loading={(<Preloader scale={this.props.scale}/>)}
                translate={translate}
              >
                <ListTradingAccountsMagicTable data={this.state?.tradingAccounts.data}/>
                <PagePanel
                  filter={this.state.pages.filter}
                  take={this.state.pages.take}
                  total={this.state.pages.total}
                  variance={this.state.pages.variance}
                  page={this.pageId()}
                  onClick={this.onPageClick}
                  doText={this.onPageText}
                  doTo={(pageId) => `?page=${ pageId }`}
                />
              </Loader>

            </div>
          </div>
        </div>
      </div>
    );
  }

  query = () => new URLSearchParams(this.props.location.search);

  pageId = () => {
    const defaultPageId = 0;

    try {
      const page = this.query().get('page');

      return Number(page) || defaultPageId;
    } catch (e) {
      return defaultPageId;
    }
  };

  pageIdAsGET = (pageId) => {
    pageId = pageId === undefined ? this.pageId() : pageId;
    const { filterId, } = this.mf;
    const result = {
      filterId,
      take: this.state.pages.take,
      skip: pageId * this.state.pages.take,
    };
    return result;
  };

  onDataLoad = async (pageId) => {
    await this.save({ loaded: false, });
    const pageOptions = this.pageIdAsGET(pageId);
    const loaded = (hash, data, meta) => ({ pages, }) => ({
        tradingAccounts: { data, hash },
        pages: { ...pages, ...meta, },
    });
    try {
      const response = await listAccountService.tradingAccounts(pageOptions);
      const { data, meta: { filter = 0, total = 0, }, hash } = response;
      await this.save(loaded(hash, data, { filter, total, }));
    } catch (error) {
      await this.save(loaded(null, [], { filter: 0, total: 0, }));
      error?.showErrorNotification?.();
    }
    await this.save({ loaded: true, });
  };

  onPageClick = ({ event, pageId, pageIs, }) => {
    // event.preventDefault();
    this.onDataLoad(pageId);
  };

  onPageText = (pageId, pageIs) => {
    const { translate } = this.props;
    const { current, first, prev, next, last, } = pageIs;
    const { skipped, taken, filter, total, } = pageIs;
    if (skipped || taken) {
      const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : '?';
      const text = skipped ? translate('partners_pages_items') : '';
      return skipped ? `${ text }: ${ id }` : id;
    } else if (filter || total) {
      const id = Number.isInteger(pageId) ? `${ pageId }` : '?';
      const text = translate(filter ? 'partners_pages_filtered' : 'partners_pages_total');
      return `${ text }: ${ id }`;
    } else if (first || prev || next || last) {
      return '';
    } else if (current) {
      return `${ pageId + 1}`;
    } else {
      return `${ pageId + 1}`;
    }
  };
}

export default withRouter(withLocalize(ListTradingAccounts));
