import { DataService } from './DataService';
import { hash as hashData } from './CachingService';
import { get } from '../components/Magic';
import { GET_PARTNERS_CURRENCY, } from '../apiRoutes'

class PartnersDataService extends DataService {
  constructor () {
    super([
      'getCurrency',
    ]);

    Object.freeze(this);
  };

  async getPartnersCurrency () {
    const data = await get(GET_PARTNERS_CURRENCY);
    const hash = await hashData(data);
    const partnersCurrency = { data, hash, };
    await this.emit('getCurrency', data);

    return partnersCurrency;
  };
}

export const partnersService = new PartnersDataService();
