import React, { Component, } from 'react';
import { withLocalize, } from 'react-localize-redux';
import { MagicRadioSwitch, } from '../../../../../../tima/components/Magic/MagicSwitch';

class Radio extends Component {

	constructor (props) {
		super(props);
	}

	get optionsName () {
		const { options, } = this.props;

		return options.map(option => option.name);
	}

	get optionsValue () {
		const { options, } = this.props;

		return options.map(option => option.value);
	}

	get currentValue () {
		const { options, value, } = this.props;

		return options.findIndex(option => option.value === value);
	}

	render () {
		const {
			handleOnChange, wrapperClassName, label, className, classNameText, direction, isDisabled,
		} = this.props;

		const texts = this.optionsName;
		const values = this.optionsValue;
		const currenValue = this.currentValue;

		return (
			<div className={ wrapperClassName }>
				<label className="select__label">{label}</label>
				<MagicRadioSwitch
					className={ className }
					classNameText={ classNameText }
					direction={ direction }
					index={ currenValue }
					indexNone={ true }
					isDisabled={ isDisabled }
					reverse={ false }
					texts={ texts }
					updateStateFromProps={ true }
					values={ values }
					onChange={ handleOnChange }
				/>
			</div>
		);
	}
}

export default withLocalize(Radio);