export const filtersLang = {
    'filter_equal': ["Equal", "Равнo", "Однаково"],
    'filter_less': ["Less", "Меньше", "Менше"],
    'filter_larger': ["Larger", "Больше", "Більше"],
    'filter_less_equal': ["Less equal", "Меньше равно", "Менше однаково"],
    'filter_larger_equal': ["Larger equal", "Больше равно", "Більше однаково"],
    'filter_not_equal': ["Not equal", "Не равно", "Не однаково"],
    'filter_in_in': ["In", "Содержит", "Містить"],
    'filter_not_in': ["Not", "Не содержит", "Не містить"],
    'filter_like': ["Like", "Подобно", "Подібно"],
    'filter_not_like': ["Not like", "Не подобно", "Не подібно"],
    'filter_between': ["Between", "Между", "Між"],
    'filter_advanced': ["Advanced Filters", "Расширенные фильтры", "Розширені фільтри"],
    'filter_apply': ["Apply", "Применить", "Застосувати"],
    'filter_select': ["Select...", "Выбрать...", "Вибрати..."],
    'filter_type': ["Type...", "Ввести...", "Ввести..."],
    'filter_name': ["Name", "Наименование", "Назва"],
    'filter_operator': ["Operator", "Параметр", "Параметр"],
    'filter_value': ["Value", "Значение", "Значення"],
    'filter_add_filter': ["Add advanced filter", "Добавить фильтр", "Додати фільтр"],
    'filter_from': ["From", "С", "З"],
    'filter_to': ["To", "До", "По"],
    'filter_choose_day': ["Choose day", "Выберите дату", "Оберіть дату"],
};
