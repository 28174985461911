import React, { Component } from 'react';
import NotificationService from "../../../../../../services/NotificationService";
import PropTypes from "prop-types";
import { isEmpty, isUndefined } from "lodash";
import Preloader from "../../../../../../components/LoadingHOC/Preloader";
import BlockTradingToolsNew from "./BlockTradingToolsNew";
import {listAccountService} from "../../../../../../services/ListAccountDataService";

const REG_EXP = {
    general:          /^[а-яА-ЯёЁa-zA-ZІіЇїЄєҐґ0-9*"'«»;:,.`№\s\-]{1,}$/,
    number:           /^[0-9]{1,}$/,
    floatNumber:      /^[0-9]*[.]?[0-9]{1,2}$/,
    floatNumberMagic: "(^\[0-9]{0,})+([.]([0-9]{0,2})?)?$",
};

const MAX_CNT_DAILY_RISKS = 10;
const MAX_VAL_PERCENT = 100;
const MIN_VAL_DPR = 0.01;

class EditPopUp extends Component {
    constructor (props) {
        super(props);

        const { riskData = {}, risksServerData = {}, isDemoTradingAccountType } = props;
        const { risk_params = {}, id, user_id, invoice_sending, risk_type } = risksServerData;

        this.state = {
            id,
            user_id,
            risk_type,
            ...this.getInitialState(),
            updateTradingTools: [],
            loadedInnerRequest: true,
        };
    }

    componentDidMount () {
        this.tryResetTradeLosingFrom();
    }

    isPlatformMT5 = () => this.props?.platform?.toString() === '2'; // AccountBalancesPlatformEnum

    isMt5GroupTypeNET = () => this.props?.mt5GroupType?.toString() === 'NET'; // Mt5GroupTypeEnum

    isMt5GroupTypeHEDGE = () => this.props?.mt5GroupType?.toString() === 'HEDGE'; // Mt5GroupTypeEnum

    getInitialState = () => {
        const { riskData = {}, risksServerData = {}, isDemoTradingAccountType } = this.props;
        const { risk_params = {}, id, user_id, invoice_sending, risk_type } = risksServerData;
        const { day_profit_risk = [] } = risk_params;
        const isMt5GroupTypeNET = this.isMt5GroupTypeNET();
        const isPlatformMT5 = this.isPlatformMT5();

        const {
            day_risk = '',
            week_risk = '',
            month_risk = '',
            day_row_risk = '',
            week_row_risk = '',
            locking_risk = '',
            order_risk = '',
            trade_losing_from = '',
        } = risk_params;

        const requiredForTima = risk_type === 2;

        const fieldsForCheckingAll = [
            'order_risk',
            'day_risk',
            'week_risk',
            'month_risk',
            'day_row_risk',
            'week_row_risk',
            'trade_losing_from',
            'locking_risk',
            'invoice_sending'
        ];

        const onlyMT4Fields = ['locking_risk',];
        // если по МТ5 счету в поле "Система учета позиций" указана опция "Net", тогда блока с полем "Риск на позицию" не должно быть
        isPlatformMT5 && isMt5GroupTypeNET && onlyMT4Fields.push('order_risk');

        /*const RiskTypeEnum = {
            "0": "Валютный",
            "1": "Процентный",
            "2": "TIMA",
            "-1": "Удален"
        };*/

        const fieldsForChecking = fieldsForCheckingAll.filter((fieldName) => {
            switch (risk_type) {
                case 1: {
                    return ['trade_losing_from'].indexOf(fieldName) === -1;
                }
                case 2: {
                    return ['order_risk', 'trade_losing_from', 'invoice_sending'].indexOf(fieldName) === -1;
                }
                default: {
                    return true;
                }
            }
        }).filter((fieldName) => isPlatformMT5 ? !onlyMT4Fields.includes(fieldName) : true);

        const updateFields = {};
        const updateBlockOnRisk = {};
        const isValid = {};
        const isRequired = {};
        const defaultValue = '';

        fieldsForChecking.forEach((fieldName) => {
            switch (fieldName) {
                case 'invoice_sending': {
                    updateBlockOnRisk[fieldName] = Boolean(risksServerData[fieldName] || defaultValue);
                    break;
                }
                case 'locking_risk': {
                    updateFields[fieldName] = (risk_params[fieldName] || defaultValue) !== 'false';
                    break;
                }
                default: {
                    updateFields[fieldName] = risk_params[fieldName] || defaultValue;
                    break;
                }
            }

            isValid[fieldName] = true;
            isRequired[fieldName] = requiredForTima;
        });

        return {
            formData:   {
                fieldsForChecking,
                updateFields:      { ...updateFields },
                updateBlockOnRisk: { ...updateBlockOnRisk },
                isValid:           { ...isValid },
                isRequired:        { ...isRequired },
            },
            dailyRisks: day_profit_risk.map((item) => ({ ...item, isValid: { profit: true, percent: true } })),
        };
    };

    setLoadedInnerRequest (loadedInnerRequest) {
        this.setState(() => ({ loadedInnerRequest, }));
    }

    setStateCheckedUpdate = (event) => {
        const ev = event.target;

        this.setState((state) => {
            if (ev.name === 'invoice_sending') {
                return {
                    formData: {
                        ...this.state.formData,
                        updateBlockOnRisk: {
                            ...this.state.formData.updateBlockOnRisk,
                            [ev.name]: !state.formData.updateBlockOnRisk[ev.name],
                        },
                    },

                };
            }
            if (ev.name === 'locking_risk') {
                return {
                    formData: {
                        ...this.state.formData,
                        updateFields: {
                            ...this.state.formData.updateFields,
                            [ev.name]: !state.formData.updateFields[ev.name],
                        },
                    },

                };
            }
        });
    };

    updateTradingTools = (updateState) => {
        this.setState(() => {
            return { updateTradingTools: updateState };
        });
    };

    sendRiskManagerParametrs = async () => {
        const removeEmptyFields = (obj) => {
            Object.keys(obj).forEach(key => {
                if (obj[key] && typeof obj[key] === 'object') {
                    removeEmptyFields(obj[key]);
                } else if (obj[key] == null || obj[key] === '') {
                    delete obj[key];
                }
            });
        };

        const strTrimFirstZeroFormatObj = (obj) => {
            const formattedObj = {};

            for (let key in obj) {
                const val = obj[key];
                formattedObj[key] = typeof val === 'string' && val.length > 1 && val[0] === '0' && val[1] !== '.' ? val.slice(1) : val;
            }

            return formattedObj;
        };

        const strTrimFirstZeroFormatArrObjs = (arrObjs) => {
            const formattedArrObjs = [];

            arrObjs.forEach((obj) => {
                formattedArrObjs.push(strTrimFirstZeroFormatObj(obj));
            });

            return formattedArrObjs;
        };

        const fullValidatorStatus = this.fullValidator();

        if (fullValidatorStatus) {
            this.setLoadedInnerRequest(false);

            try {
                const { id, user_id, formData = {}, dailyRisks = [], updateTradingTools = [] } = this.state;
                const { updateFields = {}, updateBlockOnRisk = {} } = formData;
                const { locking_risk } = updateFields;
                const { invoice_sending } = updateBlockOnRisk;
                const disabledTradingTools = updateTradingTools.filter((item) => {
                    return item.isSelected;
                }).map((item) => {
                    return item.name;
                });
                const dailyRisksFiltered = dailyRisks.map((item) => {
                    const { percent, profit } = item;

                    return { percent, profit };
                });
                const { refreshDataFunc, editPopUpClose } = this.props;

                const riskParams = {
                    ...strTrimFirstZeroFormatObj(updateFields),
                };

                if (!this.isPlatformMT5()) {
                    riskParams['locking_risk'] = locking_risk?.toString();
                }
                if (dailyRisksFiltered.length > 0) {
                    riskParams['day_profit_risk'] = strTrimFirstZeroFormatArrObjs(dailyRisksFiltered);
                }
                if (disabledTradingTools.length > 0) {
                    riskParams['disabled_symbols'] = disabledTradingTools;
                }
                removeEmptyFields(riskParams);

                const data = {
                    mt_login:        user_id,
                    invoice_sending: !isNaN(+invoice_sending) ? +invoice_sending : null,
                    risk_params:     JSON.stringify(riskParams),
                };
                removeEmptyFields(data);

                const { response } = this.isPlatformMT5() ? await listAccountService.updateRiskManagerMT5(id, data) : await listAccountService.updateRiskManager(id, data); // RiskLiveId

                if (response.error) {
                    throw new Error(`${response.error}`);
                }

                editPopUpClose();
                refreshDataFunc();
            } catch (error) {
                const errors = error?.response?.data?.errors;
                const newState = this.state;

                for (let key in errors) {
                    const fieldOptionsArr = key.split('.');

                    if (fieldOptionsArr.length === 2) {
                        const [fuf, fieldName] = fieldOptionsArr;

                        newState.formData['isValid'][fieldName] = false;
                    } else if (fieldOptionsArr.length === 4) {
                        const [fuf, listName, rowIndex, fieldName] = fieldOptionsArr;
                        const listNameMap = listName === 'day_profit_risk' ? 'dailyRisks' : listName;

                        newState[listNameMap][rowIndex]['isValid'][fieldName] = false;
                    }
                }

                this.setState(newState);

                error?.showErrorNotification?.();
            }

            this.setLoadedInnerRequest(true);
        }
    };

    helperValidator = (fieldObj) => {
        const { fieldName, currentValue } = fieldObj;
        const { regExp, } = this.getRegExpByFieldName(fieldName);

        if (currentValue === '') {
            return true;
        }

        if (
            (currentValue[0] === ".") ||
            (parseFloat(currentValue[0]) === 0 && parseFloat(currentValue[1]) === 0) ||
            (regExp === REG_EXP.floatNumber && !(new RegExp(REG_EXP.floatNumberMagic)).test(currentValue)) ||
            (regExp === REG_EXP.number && !(new RegExp(REG_EXP.number)).test(currentValue))
        ) {
            return false;
        }

        return true;
    };

    onInputChange = (fieldName, rowIndex, propertyName) => (event) => {
        const { value } = event.target;
        const { formData = {} } = this.state;
        const { updateFields = {}, isValid = {} } = formData;
        const currentValue = value;

        if (this.helperValidator({ fieldName, currentValue })) {
            let newState = {};

            if (propertyName) {
                const tmpList = this.state[propertyName];
                const currentRow = tmpList[rowIndex];

                currentRow[fieldName] = currentValue;
                currentRow['isValid'][fieldName] = true; // remove error border during input
                newState = { [propertyName]: tmpList };
            } else {
                newState = {
                    formData:
                        {
                            ...formData,
                            updateFields: {
                                ...updateFields,
                                [fieldName]: currentValue,
                            },
                            isValid:      {
                                ...isValid,
                                [fieldName]: true, // remove error border during input
                            },

                        },
                };
            }

            this.setState(newState, () => {
                this.tryResetTradeLosingFrom();
            });
        }
    };

    getRegExpByFieldName = (fieldName) => {
        const MIN_FLOAT = 0.01;
        const MAX_PERCENT = 100;
        const MIN_INT = 1;
        const MAX_NUMBER = Number.MAX_SAFE_INTEGER;

        const { risk_type } = this.state;

        let regExp = '';
        let maxLength = 50;
        let minValue = null;
        let maxValue = null;

        switch (fieldName) {
            case 'order_risk':
            case 'day_risk':
            case 'week_risk':
            case 'month_risk': {
                regExp = REG_EXP.floatNumber;
                minValue = MIN_FLOAT;
                if (risk_type === 0) {
                    // for Currency
                    maxValue = MAX_NUMBER;
                } else if (risk_type === 1) {
                    // for Percent
                    maxValue = MAX_PERCENT;
                } else if (risk_type === 2) {
                    // for TIMA riskType
                    maxValue = MAX_PERCENT;
                }

                break;
            }
            case 'trade_losing_from': {
                regExp = REG_EXP.floatNumber;
                minValue = MIN_FLOAT;
                if (risk_type === 0 || risk_type === 1) {
                    // for Currency
                    maxValue = MAX_NUMBER;
                }

                break;
            }
            case 'day_row_risk':
            case 'week_row_risk': {
                regExp = REG_EXP.number;
                minValue = MIN_INT;
                maxValue = MAX_NUMBER;
                break;
            }
            case 'profit': {
                regExp = REG_EXP.floatNumber;
                minValue = MIN_FLOAT;
                maxValue = MAX_NUMBER;
                break;
            }
            case 'percent': {
                regExp = REG_EXP.floatNumber;
                minValue = MIN_FLOAT;
                maxValue = MAX_PERCENT;
                break;
            }
            default: {
                regExp = REG_EXP.general;
                break;
            }
        }

        return { regExp, maxLength, minValue, maxValue };
    };

    isValidField = (fieldObj) => {
        const { name, value, rowIndex, listName } = fieldObj;
        const chechBoxFieldsArr = ['invoice_sending', 'locking_risk'];
        const { risk_type, formData = {} } = this.state;
        const { updateFields = {}, isRequired = {} } = formData;

        let isValid = false;

        if (chechBoxFieldsArr.indexOf(name) !== -1) {
            // isValid = typeof value === 'boolean';
            isValid = true;
        } else {
            try {
                const { regExp, minValue, maxValue } = this.getRegExpByFieldName(name);
                const isEmpty = value === '';

                switch (name) {
                    case 'profit': {
                        const minValueProfit = rowIndex > 0 ? +this.state[listName][rowIndex - 1]['profit'] + MIN_VAL_DPR : minValue;

                        isValid = regExp.test(value) && +value >= minValueProfit && +value <= maxValue;
                        break;
                    }
                    case 'percent': {
                        isValid = regExp.test(value) && +value >= minValue && +value <= maxValue;
                        break;
                    }
                    default: {
                        isValid = isEmpty && !isRequired[name] || (regExp.test(value) && +value >= minValue && +value <= maxValue);
                        break;
                    }
                }

            } catch (error) {
                console.log('isValidField(): No such field: ', name);
            }
        }

        return isValid;
    };

    validateFormDataFields = () => {
        const { formData = {} } = this.state;
        const listName = 'updateFields';
        const tmpList = formData[listName];
        const { isValid: isValidObj } = formData;

        let isValidStatus = true;

        for (let fieldName in isValidObj) {
            const validatorStatus = this.isValidField({ name: fieldName, value: tmpList[fieldName] });

            isValidObj[fieldName] = validatorStatus;
            if (!validatorStatus) {
                isValidStatus = false;
            }
        }

        if (isValidStatus) {
            // turn off all errors
            for (let fieldName in isValidObj) {
                isValidObj[fieldName] = true;
            }
        }

        const newState = {
            formData: {
                ...formData,
                [listName]: tmpList,
            },
        };

        this.setState(newState);

        return isValidStatus;
    };

    validateDailyRisks = () => {
        const listName = 'dailyRisks';
        const tmpList = this.state[listName];
        const isValidStatusArr = [];

        tmpList.forEach((item, rowIndex) => {
            const { isValid: isValidObj } = item;
            let isValidStatus = true;

            for (let fieldName in isValidObj) {
                const validatorStatus = this.isValidField({
                    name:  fieldName,
                    value: item[fieldName],
                    listName,
                    rowIndex
                });

                isValidObj[fieldName] = validatorStatus;
            }

            isValidStatus = isValidObj['profit'] && isValidObj['percent'];

            if (isValidStatus) {
                // turn off all errors
                for (let fieldName in isValidObj) {
                    isValidObj[fieldName] = true;
                }
            }
            isValidStatusArr.push(isValidStatus);
        });

        const isValidStatusResult = isValidStatusArr.every((statusItem) => {
            return statusItem;
        });

        const newState = {
            [listName]: tmpList,
        };

        this.setState(newState);

        return isValidStatusResult;
    };

    fullValidator = () => {
        const { risk_type, dailyRisks = [] } = this.state;
        const { translate } = this.props;
        const requiredForTima = risk_type === 2;

        const validateFormDataFieldsStatus = this.validateFormDataFields();
        const validateDailyRisksStatus = requiredForTima ? this.validateDailyRisks() && dailyRisks.length > 0 : this.validateDailyRisks();

        if (requiredForTima && !dailyRisks.length) {
            NotificationService.error({
                title:   "error",
                message: translate('list_accounts_risk_manager_daily_profit_risk_required_text'),
                remove:  false,
            });
        }

        return validateFormDataFieldsStatus && validateDailyRisksStatus;
    };

    validateTradeLosingFrom = () => {
        const { formData = {} } = this.state;
        const { updateFields = {} } = formData;
        const {
            trade_losing_from,
            day_row_risk,
            week_row_risk,
        } = updateFields;

        return !(isEmpty(day_row_risk) && isEmpty(week_row_risk));
    };

    tryResetTradeLosingFrom = () => {
        const { formData = {} } = this.state;
        const { updateFields = {} } = formData;

        if ('trade_losing_from' in updateFields &&
            'day_row_risk' in updateFields &&
            'week_row_risk' in updateFields &&
            !this.validateTradeLosingFrom()
        ) {
            this.setState({
                formData: {
                    ...formData,
                    updateFields: {
                        ...updateFields,
                        ['trade_losing_from']: '',
                    },
                },
            });
        }
    };

    renderBlock = (fieldName) => {
        const { translate, enums = {}, riskData = {}, risksServerData = {}, symbol, platform, tradingAccountType, isDemoTradingAccountType } = this.props;
        const { risk_type, user_id } = risksServerData;

        const { formData = {} } = this.state;
        const { updateFields = {}, updateBlockOnRisk = {} } = formData;
        const {
            order_risk,
            day_risk,
            week_risk,
            month_risk,
            trade_losing_from,
            day_row_risk,
            week_row_risk,
            locking_risk,
        } = updateFields;
        const { invoice_sending } = updateBlockOnRisk;
        const isPlatformMT5 = this.isPlatformMT5();
        const isRiskTypeCurrency = risk_type?.toString() === '0';
        const isRiskTypePercent = risk_type?.toString() === '1';

        /*const TradingAccountTypeEnum = {
            "2": "Tima_manager",
            "5": "Trading",
            "6": "Demo",
            "7": "Demo_contest",
            "8": "Demo_risk",
            "10": "Demo_m",
        };*/

        /*const RiskTypeEnum = {
            "0": "Валютный",
            "1": "Процентный",
            "2": "TIMA",
            "-1": "Удален"
        };*/

        const prefix = 'list_accounts_risk_manager';
        const prefix_edit = 'list_accounts_risk_edit';

        const translateMap = {
            'risk_type':         `${prefix}_type`,
            'order_risk':        isPlatformMT5 ? `${prefix}_position_risk_mt5` : `${prefix}_order_risk`,
            'trade_losing_from': isPlatformMT5 ? `${prefix}_trade_losing_from_mt5` : `${prefix}_trade_losing_from`,
            'day_row_risk':      isPlatformMT5 ? `${prefix}_max_losing_orders_per_day_mt5` : `${prefix}_max_losing_orders_per_day`,
            'week_row_risk':     isPlatformMT5 ? `${prefix}_max_losing_orders_per_week_mt5` : `${prefix}_max_losing_orders_per_week`,
            'day_risk':          `${prefix}_daily_risk`,
            'week_risk':         `${prefix}_weekly_risk`,
            'month_risk':        `${prefix}_monthly_risk`,
        };
        const translateDescriptionMap = {
            'order_risk':        isPlatformMT5 ?
                                     (isRiskTypeCurrency ? `${prefix_edit}_position_risk_currency_mt5_description` : `${prefix_edit}_position_risk_percent_mt5_description`) :
                                     (isRiskTypeCurrency ? `${prefix_edit}_order_risk_currency_description` : `${prefix_edit}_order_risk_percent_description`),
            'day_risk':          isPlatformMT5 ? `${prefix_edit}_risk_day_mt5_description` : `${prefix_edit}_risk_day_description`,
            'week_risk':         isPlatformMT5 ? `${prefix_edit}_risk_week_mt5_description` : `${prefix_edit}_risk_week_description`,
            'month_risk':        isPlatformMT5 ? `${prefix_edit}_risk_month_mt5_description` : `${prefix_edit}_risk_month_description`,
            'trade_losing_from': isPlatformMT5 ? `${prefix_edit}_trade_losing_from_mt5_description` : `${prefix_edit}_trade_losing_from_description`,
            'day_row_risk':      isPlatformMT5 ? `${prefix_edit}_day_row_risk_mt5_description` : `${prefix_edit}_day_row_risk_description`,
            'week_row_risk':     isPlatformMT5 ? `${prefix_edit}_week_row_risk_mt5_description` : `${prefix_edit}_week_row_risk_description`,
            'locking_risk':      `${prefix_edit}_lock_description`,
        };

        const errorClass = this.state.formData['isValid'][fieldName] ? '' : 'error';
        const keyForBlock = `block-${fieldName}`;

        const MIN_VAL = 0.01;
        const inputLabelForPercent = `${translate('list_accounts_risk_from')} ${MIN_VAL} ${symbol} ${translate('list_accounts_risk_to')} ${MAX_VAL_PERCENT} ${symbol}`;
        const inputLabelDefault = `${translate('list_accounts_risk_from')} ${MIN_VAL} ${symbol}`;

        const inputLabelForDayWeekMonthRisks = [1, 2].indexOf(risk_type) !== -1 ? inputLabelForPercent : inputLabelDefault;
        const inputLabelForOrderRisk = [1].indexOf(risk_type) !== -1 ? inputLabelForPercent : inputLabelDefault;

        switch (fieldName) {
            case 'header' : {
                return (
                    <div className='pop-up-header' key={keyForBlock}>
                        <h3 className='pop-up__name'>
                            {translate('list_accounts_risk_edit_title')} {user_id} {enums.core.AccountBalancesPlatformEnum[platform]}
                        </h3>
                        <i className='gl-icon close-btn close-btn--big'
                           onClick={() => this.props.editPopUpClose()}/>
                    </div>
                );
            }
            case 'risk_type': {
                return (
                    <div className="block" key={keyForBlock}>
                        <div className="block-header">
                            <p className="block__name">
                                {translate(translateMap[fieldName])}
                            </p>
                            <p className="block__info">
                                {enums.core.RiskTypeEnum[risk_type]}
                            </p>
                        </div>
                    </div>
                );
            }
            case 'order_risk': {
                return [0, 1].indexOf(risk_type) !== -1 ?
                    (
                        <div className="block" key={keyForBlock}>
                            <div className="block-header">
                                <p className="block__name">
                                    {translate(translateMap?.[fieldName])}
                                </p>
                                <div className='input'>
                                <span className='input__text'>
                                    {inputLabelForOrderRisk}
                                </span>
                                    <div className='input-wrapper'>
                                        <input className={`input__field ${errorClass}`}
                                               autoComplete='off'
                                               name={fieldName}
                                               type='text'
                                               value={order_risk ? order_risk : ''}
                                               onChange={this.onInputChange(fieldName)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="block-content">
                                <p className="block-info">
                                    {translate(translateDescriptionMap?.[fieldName])}
                                </p>
                            </div>
                        </div>

                    ) : false;
            }
            case 'day_risk': {
                return (
                    <div className="block" key={keyForBlock}>
                        <div className="block-header">
                            <p className="block__name">
                                {translate(translateMap?.[fieldName])}
                            </p>
                            <div className='input'>
                                <span className='input__text'>
                                    {inputLabelForDayWeekMonthRisks}
                                </span>
                                <div className='input-wrapper'>
                                    <input className={`input__field ${errorClass}`}
                                           autoComplete='off'
                                           name={fieldName}
                                           type='text'
                                           value={day_risk ? day_risk : ''}
                                           onChange={this.onInputChange(fieldName)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="block-content">
                            <p className="block-info">
                                {translate(translateDescriptionMap?.[fieldName])}
                            </p>
                        </div>
                    </div>
                );
            }
            case 'week_risk': {
                return (
                    <div className="block" key={keyForBlock}>
                        <div className="block-header">
                            <p className="block__name">
                                {translate(translateMap?.[fieldName])}
                            </p>
                            <div className='input'>
                                <span className='input__text'>
                                    {inputLabelForDayWeekMonthRisks}
                                </span>
                                <div className='input-wrapper'>
                                    <input className={`input__field ${errorClass}`}
                                           autoComplete='off'
                                           name={fieldName}
                                           type='text'
                                           value={week_risk ? week_risk : ''}
                                           onChange={this.onInputChange(fieldName)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="block-content">
                            <p className="block-info">
                                {translate(translateDescriptionMap?.[fieldName])}
                            </p>
                        </div>
                    </div>
                );
            }
            case 'month_risk': {
                return (
                    <div className="block" key={keyForBlock}>
                        <div className="block-header">
                            <p className="block__name">
                                {translate(translateMap?.[fieldName])}
                            </p>
                            <div className='input'>
                                <span className='input__text'>
                                    {inputLabelForDayWeekMonthRisks}
                                </span>
                                <div className='input-wrapper'>
                                    <input className={`input__field ${errorClass}`}
                                           autoComplete='off'
                                           name={fieldName}
                                           type='text'
                                           value={month_risk ? month_risk : ''}
                                           onChange={this.onInputChange(fieldName)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="block-content">
                            <p className="block-info">
                                {translate(translateDescriptionMap?.[fieldName])}
                            </p>
                        </div>
                    </div>
                );
            }
            case 'trade_losing_from': {
                return [0, 1].indexOf(risk_type) !== -1 ?
                    (
                        <div className="block" key={keyForBlock}>
                            <div className="block-header">
                                <p className="block__name">
                                    {translate(translateMap?.[fieldName])}
                                </p>
                                <div className='input'>
                                <span className='input__text'>
                                    {translate('list_accounts_risk_from')} {MIN_VAL} {symbol}
                                </span>
                                    <div className='input-wrapper'>
                                        <input className={`input__field ${errorClass}`}
                                               autoComplete='off'
                                               name={fieldName}
                                               type='text'
                                               value={trade_losing_from ? trade_losing_from : ''}
                                               onChange={this.onInputChange(fieldName)}
                                               disabled={!this.validateTradeLosingFrom()}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="block-content">
                                <p className="block-info">
                                    {translate(translateDescriptionMap?.[fieldName])}
                                </p>
                            </div>
                        </div>
                    ) : false;

            }
            case 'day_row_risk': {
                return (
                    <div className="block" key={keyForBlock}>
                        <div className="block-header">
                            <p className="block__name">
                                {translate(translateMap?.[fieldName])}
                            </p>
                            <div className='input'>
                                <div className='input-wrapper'>
                                    <input className={`input__field ${errorClass}`}
                                           autoComplete='off'
                                           name={fieldName}
                                           type='text'
                                           value={day_row_risk ? day_row_risk : ''}
                                           onChange={this.onInputChange(fieldName)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="block-content">
                            <p className="block-info">
                                {translate(translateDescriptionMap?.[fieldName])}
                            </p>
                        </div>
                    </div>
                );
            }
            case 'week_row_risk': {
                return (
                    <div className="block" key={keyForBlock}>
                        <div className="block-header">
                            <p className="block__name">
                                {translate(translateMap?.[fieldName])}
                            </p>
                            <div className='input'>
                                <div className='input-wrapper'>
                                    <input className={`input__field ${errorClass}`}
                                           autoComplete='off'
                                           name={fieldName}
                                           type='text'
                                           value={week_row_risk ? week_row_risk : ''}
                                           onChange={this.onInputChange(fieldName)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="block-content">
                            <p className="block-info">
                                {translate(translateDescriptionMap?.[fieldName])}
                            </p>
                        </div>
                    </div>
                );
            }
            case 'locking_risk': {
                return (
                    <div className="block" key={keyForBlock}>
                        <div className="block-header justify-content-start">
                            <div className='block-header_input-checkbox' style={{
                                'marginRight': '10px',
                                position:      'relative',
                                width:         '20px',
                                height:        '20px',
                                top:           '-2px',
                                right:         0
                            }}>
                                <label className='checkbox'>
                                    <input
                                        checked={locking_risk}
                                        type='checkbox'
                                        name={fieldName}
                                        onChange={(event) => {
                                            this.setStateCheckedUpdate(event, fieldName);
                                        }}
                                    />
                                    <span/>
                                </label>
                            </div>
                            <span>{translate('list_accounts_risk_manager_location')}</span>
                        </div>

                        <div className="block-content">
                            <p className="block-info">
                                {translate(translateDescriptionMap?.[fieldName])}
                            </p>
                        </div>
                    </div>
                );
            }
            case 'invoice_sending': {
                return [2].indexOf(risk_type) === -1 ? (
                    <div className="block" key={keyForBlock}>
                        <div className="block-header justify-content-start">
                            <div className='block-header_input-checkbox' style={{
                                'marginRight': '10px',
                                position:      'relative',
                                width:         '20px',
                                height:        '20px',
                                top:           '-2px',
                                right:         0
                            }}>
                                <label className='checkbox'>
                                    <input
                                        type='checkbox'
                                        name={fieldName}
                                        checked={invoice_sending}
                                        onChange={(event) => {
                                            this.setStateCheckedUpdate(event, fieldName);
                                        }}
                                    />
                                    <span/>
                                </label>
                            </div>
                            <span>
                                {translate('list_accounts_risk_manager_prohibit_sending_invoices')}
                            </span>
                        </div>
                    </div>
                ) : false;
            }
            default: {
                return false;
            }
        }
    };

    onAddDailyRiskBtnClick = (event) => {
        event.preventDefault();

        const { dailyRisks = [] } = this.state;
        const validateDailyRisksStatus = this.validateDailyRisks();

        if (dailyRisks.length < MAX_CNT_DAILY_RISKS && validateDailyRisksStatus) {
            // TODO !!!
            dailyRisks.push(
                {
                    percent: '',
                    profit:  '',
                    isValid: {
                        percent: true,
                        profit:  true,
                    },
                });
            this.setState({ dailyRisks });
            // this.addItem({ newItemValue: '' });
        }
    };

    onRemoveBtnClick = (rowIndex) => (event) => {
        const { dailyRisks = [] } = this.state;

        dailyRisks.splice(rowIndex, 1);// delete item
        this.setState({ dailyRisks });
    };

    outputProfitTitle = (items, item, rowIndex) => {
        if (rowIndex !== 0) {
            const refVal = items[rowIndex - 1].profit;
            const refValFormat = !isNaN(parseFloat(refVal)) ? parseFloat(refVal) : 0;

            return (refValFormat + MIN_VAL_DPR).toFixed(2);
        } else {
            return MIN_VAL_DPR;
        }
    };

    renderBtnCont = (items, translate) => {
        return items.length < MAX_CNT_DAILY_RISKS ? (
            <div className="btn-cont">
                <button className='gl-btn gl-btn--green gl-btn--small'
                        onClick={this.onAddDailyRiskBtnClick}
                >
                    {translate('list_accounts_risk_edit_add_btn')}
                </button>
            </div>
        ) : false;
    };

    renderDailyProfitRisks = (items, translate, symbol) => {

        return (
            <div className='daily-profit-risks'>
                <div className="block block--no-border">
                    <div className="block-header full-cont">
                        <div className='full-cont__item'>
                            <p className='block__name'>{translate('list_accounts_risk_manager_profitability')} {symbol}</p>
                        </div>
                        <div className='full-cont__item'>
                            <p className='block__name'>{translate('list_accounts_risk_manager_risk')} %</p>
                        </div>
                    </div>
                </div>

                <div className="blocks">
                    {
                        items.map((item, rowIndex) => {
                            return this.renderDailyProfitRiskItem({ items, item, rowIndex, translate, symbol });
                        })
                    }
                </div>

                {this.renderBtnCont(items, translate)}
            </div>
        );

    };

    renderDailyProfitRiskItem = (options) => {
        const { items, item, rowIndex, translate, symbol } = options;
        const propertyName = 'dailyRisks';
        const keyForBlock = `block-dpr-${rowIndex}`;
        const errorClassForProfit = this.state[propertyName][rowIndex]['isValid']['profit'] ? '' : 'error';
        const errorClassForPercent = this.state[propertyName][rowIndex]['isValid']['percent'] ? '' : 'error';

        return (
            <div className="block" key={keyForBlock}>
                <div className="full-cont">
                    <div className='full-cont__item'>
                        <div className='input input--profit'>
                            <span
                                className='input__text'>{translate('list_accounts_risk_from')} {this.outputProfitTitle(items, item, rowIndex)} {symbol}</span>
                            <div className='input-wrapper'>
                                <input
                                    autoComplete='off'
                                    className={`input__field ${errorClassForProfit}`}
                                    name='profit'
                                    type='text'
                                    value={item ? item.profit : ''}
                                    onChange={this.onInputChange('profit', rowIndex, propertyName)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='full-cont__item'>
                        <div className='input'>
                            <span className='input__text'>
                                {translate('list_accounts_risk_from')} {MIN_VAL_DPR}% {translate('list_accounts_risk_to')} {MAX_VAL_PERCENT}%
                            </span>
                            <div className='input-wrapper'>
                                <input
                                    autoComplete='off'
                                    className={`input__field ${errorClassForPercent}`}
                                    name='percent'
                                    type='text'
                                    value={item ? item.percent : ''}
                                    onChange={this.onInputChange('percent', rowIndex, propertyName)}
                                />
                            </div>
                            <i className='gl-icon remove-btn' onClick={this.onRemoveBtnClick(rowIndex)}></i>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render () {
        const { translate, symbol, riskData = {}, risksServerData = {}, riskToolsAll = [], riskToolsCategoriesAll = [] } = this.props;
        const { loadedInnerRequest, formData = {}, dailyRisks = [] } = this.state;
        const { fieldsForChecking } = formData;

        return (
            <div className='glalex-styles pop-up pop-up--edit pop-up--active'>

                {!loadedInnerRequest ?
                    (
                        <Preloader className='loaderUniversal--fixed-pos' scale={1}/>
                    ) : false
                }

                <div className='pop-up-wrapper scroll'>
                    {this.renderBlock('header')}
                    <div className='full-cont full-cont--edit'>
                        <div className='full-cont__item'>
                            <div className='title-cont'>
                                <p className='title'>{translate('list_accounts_risk_manager_options')}</p>
                            </div>

                            <div className='blocks-wrapper'>
                                {this.renderBlock('risk_type')}

                                <div className='edit-risk-params scroll'>
                                    {
                                        fieldsForChecking.map((fieldName) => {
                                            return this.renderBlock(fieldName);
                                        })
                                    }

                                    <div className="block">
                                        <div className="block-header block-header--additional">
                                            <p className="block__name">{translate('list_accounts_risk_manager_daily_profit_risk')}</p>
                                            <p className="block__name--additional">{translate('list_accounts_risk_edit_max_num_parametrs')} 10</p>
                                        </div>

                                        <div className="block-content">
                                            <p className="block-info">
                                                {translate(this.isPlatformMT5() ? 'list_accounts_risk_edit_daily_profit_mt5_description' : 'list_accounts_risk_edit_daily_profit_description')}
                                            </p>
                                        </div>
                                    </div>

                                    {this.renderDailyProfitRisks(dailyRisks, translate, symbol)}
                                </div>
                            </div>
                        </div>

                        <div className='full-cont__item'>
                            <div className='title-cont'>
                                <p className='title'>{translate('list_accounts_risk_edit_block_trading_tools')}</p>
                            </div>

                            <BlockTradingToolsNew
                                riskToolsAll={riskToolsAll}
                                riskToolsCategoriesAll={riskToolsCategoriesAll}
                                translate={translate}
                                updateTradingTools={this.updateTradingTools}
                            />

                        </div>
                    </div>
                    <div className='btns-cont'>
                        <button className='gl-btn gl-btn--blue'
                                onClick={() => this.sendRiskManagerParametrs()}>{translate('list_accounts_risk_edit_btn_save')}</button>
                        <button className='gl-btn gl-btn--blue-border'
                                onClick={() => this.props.editPopUpClose()}>{translate('list_accounts_risk_edit_btn_cancel')}</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default (EditPopUp);

EditPopUp.propTypes = {
    editPopUpClose:  PropTypes.func.isRequired,
    refreshDataFunc: PropTypes.func.isRequired,
};
