import React from 'react';
import DatePicker from "react-date-picker";

class BirthDatepicker extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            date: new Date(),
        };
    }

    onChange = async (date) => {
        await this.props.changeBirth(date);
        this.setState({ date });
    };

    render () {

        return (
            <DatePicker
                value={this.state.date}
                onChange={this.onChange}
                format = "y-MM-dd"
                calendarClassName = {'react-datepicker__month-container'}
                className = {'react-datepicker'}
                clearIcon = { null }
                calendarIcon = { null }
                disabled={ this.props?.disabled ?? false }
            />
        );
    }
}

export default BirthDatepicker;
