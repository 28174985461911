import React, { PureComponent, } from 'react';
import { withRouter, } from 'react-router-dom';
import { withLocalize, } from 'react-localize-redux';
import Preloader from '../../../../../components/LoadingHOC/Preloader';
import Loader from '../../../../tima/components/Loader';

class PopUpSourceOfFunds extends PureComponent {
  constructor (props) {
    super(props);
  };

  save = async (state) => new Promise(next => this.setState(state, next));

  renderResultText = () => {
    const {
      translate,
      verificationError,
      successfullyConfirmedDocument,
      successfullyRejectedDocument,
      clickedConfirmFundsRequest,
      clickedCancelFundsRequest,
      loadedPopupSourceOfFundsData
    } = this.props;

    return (
      <Loader
        loaded={ loadedPopupSourceOfFundsData }
        loading={ (
          <Preloader className="loaderUniversal--fixed-pos" scale={ 1 }/>) }
      >
        { clickedConfirmFundsRequest ? (
          <p className="input__text">{ successfullyConfirmedDocument ? translate('unable_to_confirm_the_request_5th') : verificationError }</p>
        ) : null }
        { clickedCancelFundsRequest ? (
          <p className='input__text'>{ successfullyRejectedDocument ? translate('unable_to_cancel_the_request_5th') : verificationError }</p>
        ) : null }
      </Loader>
    );
  };

  render () {
    const { title, isOpen, onDataLoad, } = this.props;

    return (
        <div className="glalex-styles partners icon-styles">
          <div className="pop-up pop-up--verification pop-up--active">
            <div className="pop-up-wrapper pop-up-source-of-funds">
              <div className="pop-up-header">
                <h3 className="pop-up__name">
                  { title }
                </h3>
                <i
                  className="gl-icon close-btn close-btn--big pop-up-source-of-funds__close-icon"
                  onClick={ isOpen && onDataLoad  }/>
              </div>
              <div className="pop-up-content">
                { this.renderResultText() }
              </div>
            </div>
          </div>
        </div>
      );
  }
}

export default withLocalize(withRouter(PopUpSourceOfFunds));
