let $this = null;

let $prev = null;

export const toggle = {
    init: (_this) => {
        if ($this !== _this) {
            $prev = $this;
        }
        $this = _this;
    },
    collapse: () => {
        $this.setState((state) => ({ collapse: !state.collapse }));
    },
    popup: (removeType, doc_id = null) => {
        $this.setState((prevState) => {
            return {
                fadeInPop:        !prevState.fadeInPop,
                removeType,
                removeDocumentId: doc_id,
            };
        });
    },
    modalTask: (roleUser) => {
        $this.setState({
            taskModal: !$this.state.taskModal,
            fadeIn:    !$this.state.fadeIn,
            roleUser,
        });
    },
    actionMenu: () => {
        $this.setState({
            actionMenu: !$this.state.actionMenu,
        });
    },
    historyChanges: () => {
        $this.setState({
            showHistoryChanges: !$this.state.showHistoryChanges,
        });
    },
    scrollComment: () => {
        $this.scrollChildrenComment.current.scrollIntoView({ behavior: 'smooth' });
    },
    dropdown: () => {
        $this.setState({
            dropdownOpen: !$this.state.dropdownOpen,
        });
    },
    checkList: () => {
        $this.setState({
            addCheckList: !$this.state.addCheckList,
        });
    },
    closeTask: () => {
        document.body.style.overflow = 'auto';

        $this.props.close(null);
    },
    terminate: () => {
        if ($prev) {
            $this = $prev;
        }
    },
};
