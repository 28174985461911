import React from "react";
import { withLocalize } from "react-localize-redux";
import background from '../../images/background-team-modal.svg';
import NotificationService from "../../../../../services/NotificationService";
import { MagicInput } from "../../../../../components/Magic/components/MagicInput";
import { MagicSelect } from "../../../../../components/Magic/components/MagicSelect";
import { MagicMultiSelect } from "../../../../../components/Magic/components/MagicMultiSelect";
import { hierarchyService } from "../../../../../services/HierarchyDataService";

class ModalDepartment extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            dataForm:      {},
            selectOptions: [],
            errors:        {},
            subordination: {
                hash: null,
                data: [],
            },
        };
    }

    subordinationChange = async (subordination) => {
        if (subordination.hash===this.state?.subordination?.hash) return `${this.constructor.name}.subordinationChange: false`;
        await this.save({ subordination });
        return `${this.constructor.name}.subordinationChange: true`;
    };

    async componentDidMount () {
        hierarchyService.subscribe('subordination', this.subordinationChange, this);
        await this.loadData();
    }

    async componentWillUnmount () {
        hierarchyService.unsubscribe('subordination', this.subordinationChange, this);
    }

    loadData = async () => {
        const subordination = await hierarchyService.subordination();
        const newOptions = this.props?.users?.map(item => ({
            id: item?.['core-user-id'],
            name: item?.['core-user-name'],
            surname: item?.['core-user-surname'],
        }))
        .concat(this.props?.departmentEdit?.merge?.users ?? [])
        .concat(this.props?.departmentEdit?.merge?.user ? [this.props?.departmentEdit?.merge?.user] : [])
        .map((item) => ({
            label: `${item.name} ${item.surname}`,
            value: item.id,
        }))
        .filter((item, index, list) => {
            return list.findIndex(it => it?.value === item?.value) === index;
        });
        await this.save({
            selectOptions: newOptions,
            subordination,
        });

        if (this.props.departmentEdit) {
            const staff = [];
            this.props.departmentEdit?.merge?.users?.forEach(item => {
                staff.push(item.id);
            });
            await this.save({ dataForm: { ...this.state.dataForm, users: staff }});
        }
    };

    save = async (state) => new Promise(next => this.setState(state, next));

    setValueFromForm = name => async (value) => {
        await this.save({
            dataForm: { ...this.state.dataForm, [name]: value },
        });
    };

    clearErrorForClick = async (name) => {
        await this.save({ errors: { ...this.state.errors, [name]: false } });
    };

    sendData = async () => {
        try {
            const obj = Object.assign({
                ...this.props.parentId && { parent_id: this.props.parentId },
            }, ...Object.entries(this.state?.dataForm).map(([key, item]) => ({
                ...['users'].includes(key) && !item?.length ? { [`SET_NULL__${ key }`]: 1 } : { [key]: item },
                ...['user_id'].includes(key) && item === false ? { [`SET_NULL__${ key }`]: 1 } : { [key]: item },
            })));
            if (obj.user_id === false) {
                delete obj.user_id;
            } else if (obj.name === '') {
                await this.save({
                    ...this.state.errors,
                    errors: {
                        name: true,
                    },
                });

                return false;
            }

            const data = this.props.departmentEdit ? await hierarchyService.editTeam(this.props?.departmentEdit?.id, obj)
                : await hierarchyService.addTeam(obj);

            if (data?.error) {
                NotificationService.error({
                    title:   "error",
                    message: data.error,
                    remove:  false,
                })
            } else {
                this.props.close();
                this.props.getUpdatedTree();
            }
        } catch (error) {
            this.errorFromServer(error.response.data.errors ? error.response.data.errors : { users: [error] });
            const staff = [];
            this.props.departmentEdit?.merge?.users?.forEach(item => {
                staff.push(item.id);
            });
            await this.save({ dataForm: { ...this.state.dataForm, users: staff }});
        }
    };

    errorFromServer = object => {
        let serverErrorKeys = Object.keys(object);

        serverErrorKeys.forEach(async (item) => {
            await this.save({ errors: { ...this.state.errors, [item]: true }});

            NotificationService.error({
                title:   'error',
                message: object[item],
                remove:  false,
            });
        });
    };

    renderDepartmentName = () => {
        const options = {
            className: [this.state?.errors?.name ? 'error' : ''],
            value:     this.state?.dataForm?.name ?? this.props.departmentEdit?.name ?? '',
            onChange:  this.setValueFromForm('name'),
            onValid:   value => {
                if ((value !== '' && value.length > 255)) {
                    throw new Error('error');
                }
            },
            onClick:   () => this.clearErrorForClick('name'),
        };

        return (
            <div className="modal-team-row">
                <label>{this.props.translate(`hierarchy_team`)}</label>
                <MagicInput { ...options } />
            </div>
        );
    };

    renderDepartamentId = () => {
        const options = {
            className:    [this.state?.errors?.department_id ? 'error' : ''],
            onChange:     async (value) => {
                await this.save({ dataForm: { ...this.state.dataForm, department_id: value } });
                this.clearErrorForClick('department_id');
            },
            value:        this.state.dataForm.department_id ?? this.props?.departmentEdit?.merge?.department?.id ?? '',
            values:       this.props.departmentsOpt?.map?.((data) => data.value) ?? [],
            valueToLabel: (id) => {
                const find = this.props.departmentsOpt.find((data) => id === data.value);

                return id ? find.label : this.props.translate(`hierarchy_select`);

            },
        };

        return (
            <div className="modal-team-row">
                <label>{this.props.translate(`hierarchy_department`)}</label>
                <MagicSelect { ...options } />
            </div>
        );
    };

    renderTeamLead = () => {
        const options = {
            className:    [this.state?.errors?.user_id ? 'error' : ''],
            onChange:     async (value) => {
                await this.save(({ dataForm: { user_id, ...dataForm }} = {}) => ({ dataForm: { ...dataForm, ...value ? { user_id: value } : {user_id: false}}}));
                this.clearErrorForClick('user_id');
            },
            value:        this.state.dataForm.user_id ?? this.props?.departmentEdit?.merge?.user?.id ?? '',
            valueDefault: false,
            values:       this.state.selectOptions?.map?.((data) => data.value) ?? [],
            valueToLabel: (id) => {
                if (this.state.selectOptions.length) {
                    const find = this.state.selectOptions?.find((data) => id === data.value);

                    return id ? find.label : this.props.translate(`hierarchy_select`);
                } else {
                    return this.props.translate(`hierarchy_select`);
                }
            },
        };

        return (
            <div className="modal-team-row">
                <label>{this.props.translate(`hierarchy_lead`)}</label>
                <MagicSelect { ...options } />
            </div>
        );
    };

    renderDepartmentStaff = () => {
        const valueDefault = false;
        const selected = this.state.dataForm.users ?? [];
        const options = {
            className:      [this.state?.errors?.users ? 'error' : ''],
            selectedValues: selected,
            valueDefault,
            values:         this.state.selectOptions?.map((data) => data.value).filter(item => !selected.includes(item)) ?? [],

            valueToLabel: (id) => {
                if (id === valueDefault) {
                    return this.props.translate(`hierarchy_add`);
                }
                const find = this.state?.selectOptions?.find((data) => id === data.value);
                return find ? find.label : '';
            },

            ItemProps: (value) => value === valueDefault ? { disabled: true } : {},

            onSelect:   async (index) => {
                await this.save((state) => ({
                    dataForm: { ...state.dataForm, users: [...state.dataForm?.users ?? [], index] },
                }));
            },
            onUnSelect: async (index) => {
                await this.save((state) => ({
                    dataForm: { ...state.dataForm, users: [...state.dataForm?.users].filter((item) => item !== index) },
                }));
            },
            onClick: () => this.clearErrorForClick('users'),
        };

        return (
            <div className="modal-team-row">
                <label>{this.props.translate(`hierarchy_staff`)}</label>
                <MagicMultiSelect { ...options } />
            </div>
        );
    };

    renderSubordination = () => {
        const options = {
            className:    [this.state?.errors?.parent_id ? 'error' : ''],
            onChange: async (value) => {
                await this.save({ dataForm: { ...this.state.dataForm, parent_id: value } });
                this.clearErrorForClick('parent_id');
            },
            value:        this.state?.dataForm?.parent_id ?? this.props.parentId ?? '',
            values:       this.state.subordination.data?.map?.((data) => data.id) ?? [],
            valueToLabel: (id) => {
                if (this.state?.subordination.data) {
                    const find = this.state.subordination.data?.find((data) => id === data.id);

                    return id ? find?.name : this.props.translate(`hierarchy_subordination`);
                } else {
                    return this.props.translate(`hierarchy_subordination`);
                }

            },
        };

        return (
            <div className="modal-team-row">
                <label>{this.props.translate(`hierarchy_subordination`)}</label>
                <MagicSelect { ...options } />
            </div>
        );
    };

    render () {
        return (
            <div className='modal-team'>
                <div className="modal-team-form">
                    <h4 className="title">{this.props.departmentEdit ? this.props.translate(`hierarchy_edit_team`) : this.props.translate(`hierarchy_create_team`)}</h4>
                    <div className="close" onClick={ () => this.props.close() }><i className="la la-close"></i></div>
                    {this.renderDepartmentName()}
                    {this.renderDepartamentId()}
                    {this.renderTeamLead()}
                    {this.renderDepartmentStaff()}
                    {
                        this.props.parentId ? this.renderSubordination() : null
                    }
                    <div className="button-block">
                        <div className="gradient-button" onClick={this.sendData}>
                            <span>{this.props.departmentEdit ? this.props.translate(`hierarchy_edit`) : this.props.translate(`hierarchy_create`)}</span>
                        </div>
                        <div className="gradient-border-button" onClick={ () => this.props.close() }>
                            <span>{this.props.translate(`hierarchy_cancel`)}</span>
                        </div>
                    </div>
                </div>
                <img className="background" src={background} alt="background"/>
            </div>
        );
    }
}

export default withLocalize(ModalDepartment);
