
import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import connect from "react-redux/es/connect/connect";
import { withLocalize } from "react-localize-redux";

import Select from './Select';
import Accordion from './Accordion';
import { getTenTasks } from "../../../tasks/services/TasksService";

class SideBarTaskList extends Component {
    constructor (props) {
        super(props);

        this.state = {
            modalTask: null
        }
    }

    // componentDidMount () {
    //     console.log("componentDidMount SideBarTaskList");
    //     this.props.getOneHundredTasks( 'today' );
    //
    //     EmitterService.addListener(UPDATE_SIDEBAR_CLIENT, () => setTimeout(() => {
    //         this.props.getOneHundredTasks('today');
    //     }, 150));
    // }
    //
    // componentWillUnmount () {
    //     console.log("componentWillUnmount SideBarTaskList");
    //     EmitterService.removeListener(UPDATE_SIDEBAR_CLIENT, () => {});
    // }

    setStateModalTask = ( modalTask ) => this.setState({ modalTask, });

    /*renderNewTaskPage = ( translate ) => {
        if ( isNull(this.state.modalTask) ) {
            console.log(this.state.modalTask);
            return '';
        }
        console.log(this.state.modalTask);
        const options = {
            appeals: false,
            close: this.setStateModalTask,
            taskId: this.state.modalTask,
            translate,
        };
        return (<NewTaskPage { ...options } />);
    };*/

    renderList = ( translate ) => {
        /*if (this.props.sideBar.task) {
            return '';
        }*/
        const select = this.renderListSelect( translate );
        const accordion = this.renderListAccordion( translate );
        return (<div className = 'sideBarTaskList'>{ select }{ accordion }</div>);
    };

    renderListSelect = ( translate ) => {
        const options = {
            getTenTasks: this.props.getTenTasks,
            translate,
        };
        const title = (<h4>{ translate(`sideBar_task_list`) }</h4>);
        return (<div className={ 'sideBarTaskList_top' }>{ title }<Select { ...options } /></div>);
    };

    renderListAccordion = ( translate ) => {
        const options = {
            setStateModalTask: this.setStateModalTask,
            panels: this.props.taskList,
            translate,
        };
        return (<Accordion { ...options } />);
    };

    render = () => {
        const openSideBar = localStorage.getItem('isOpenSideBarTaskList');
        if (openSideBar !== "true") {
            return null;
        }
        return (
            <React.Fragment>
                {/*{ this.renderNewTaskPage( this.props?.translate ) }*/}
                { this.renderList( this.props?.translate ) }
            </React.Fragment>
        );
    };
}

const mapStateToProps = (store) => {
    return {
        sideBar:  store.sideBar,
        taskList: store.tasks.setOneHundredTasks.data,
    };
};

export default withLocalize(connect(
    mapStateToProps,
    (dispatch) => bindActionCreators({ getTenTasks }, dispatch)
)(SideBarTaskList));
