import React, { Component, } from 'react';
import { withLocalize, } from 'react-localize-redux';
import Loader from '../../tima/components/Loader';
import Preloader from '../../../components/LoadingHOC/Preloader';
import PagePanel from '../../tima/components/Pages';
import PropTypes from 'prop-types';
import ListSupportMagicTable from './ListSupportMagicTable';
import { supportService, } from "../../../services/SupportDataService";

class ListSupport extends Component {

  static propTypes = {
    enums: PropTypes.shape({
      tickets: PropTypes.object,
    }),
    profile: PropTypes.object,
    translate: PropTypes.func,
  };

  static defaultProps = {
    enums: {},
    profile: {},
    translate: () => {},
  };

  constructor (props) {
    super(props);

    this.state = {
      data: [],
      filterLastChange: Date.now(),
      loaded: true,
      pages: {
        filter: 0,
        take: 50,
        total: 0,
        variance: 2,
      },
      profile: this.props?.profile,
      responsibles: [],
    };

    Object.defineProperty(this, 'mf', { get: () => this.props.mf, });
  }

  // eslint-disable-next-line react/sort-comp, require-await
  save = async state => new Promise(next => this.setState(state, next));

  mfChanged = async () => {
    await this.save({ filterLastChange: Date.now(), });
  }

  mfDoing = async () => {
    await this.save({ loaded: false, });
  };

  mfDone = async () => {
    await this.mfChanged();
    await this.onDataLoad();
  };

  async componentDidMount () {
    this.mf.subscribe({
      changed: this.mfChanged,
      // changing: ,
      cleaned: this.mfDone,
      cleaning: this.mfDoing,
      loaded: this.mfDone,
      loading: this.mfDoing,
      saved: this.mfDone,
      saving: this.mfDoing,
    }, this);
    await this.mf.init({ doEmit: true, });
    // await this.mf.emit('change', {});
    // await this.onDataLoad(); // TODO: moved to mfDone
  };

  componentWillUnmount () {
    this.mf.unsubscribe([
      this.mfChanged,
      this.mfDoing,
      this.mfDone,
    ], this);
  };

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.state?.profile !== nextProps?.profile) {
      this.setState({ profile: nextProps?.profile, });
    };
  };

  get params () {
    return this.props?.match?.params;
  }

  get query () {
    return new URLSearchParams(this.props?.location?.search);
  }

  pagination = () => {};

  pageId = () => {
    const { query, } = this.props;
    const page = query.get('page');

    return Number(page) || 0;
  };

  pageIdAsGET = (pageId) => {
    // eslint-disable-next-line no-param-reassign
    pageId = pageId === undefined ? this.pageId() : pageId;

    const { filterId, } = this.mf;
    const result = {
      filterId,
      skip: pageId * this.state.pages.take,
      take: this.state.pages.take,
    };

    return result;
  };

  onDataLoad = async (pageId) => {
    await this.save({ loaded: false, });
    const pageOptions = this.pageIdAsGET(pageId);
    const loaded = (data, meta, responsibles) => ({ pages, }) => ({
      data,
      pages: { ...pages, ...meta, },
      responsibles,
    });

    try {
      // eslint-disable-next-line prefer-const
      let [ { data: response, hash, }, responsibles = [], ] = await Promise.all([
        supportService.getTickets(pageOptions),
        supportService.getResponsibles(),
      ]);

      responsibles = [ { id: null, name: "", surname: "", }, ...responsibles, ];

      const { data=[], meta: { filter=0, total=0, }, } = response;

      await this.save(loaded(data, { filter, total, }, responsibles));
    } catch (error) {
      console.error({ error, });
      error?.showErrorNotification?.();
      await this.save(loaded([], { filter: 0, total: 0, }, []));
    }
    await this.save({ loaded: true, });
  };

  // eslint-disable-next-line no-unused-vars
  onPageClick = ({ event, pageId, pageIs, }) => {
    this.onDataLoad(pageId);
  };

  onPageText = (pageId, pageIs) => {
    const { translate, } = this.props;
    // eslint-disable-next-line object-curly-newline
    const { current, first, prev, next, last, } = pageIs;
    // eslint-disable-next-line object-curly-newline
    const { skipped, taken, filter, total, } = pageIs;

    if (skipped || taken) {
      const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : '?';
      const text = skipped ? translate('support_pages_items') : '';

      return skipped ? `${ text }: ${ id }` : id;
    } else if (filter || total) {
      const id = Number.isInteger(pageId) ? `${ pageId }` : '?';
      const text = translate(filter ? 'support_pages_filtered' : 'support_pages_total');

      return `${ text }: ${ id }`;
    } else if (first || prev || next || last) {
      return '';
    } else if (current) {
      return `${ pageId + 1 }`;
    }

    return `${ pageId + 1 }`;
  };

  render () {
    // eslint-disable-next-line object-curly-newline
    const { loaded, pages, data, responsibles, } = this.state;
    const { profile, } = this.props;

    return (
      <Loader loaded={ loaded } loading={ <Preloader scale={ 1 } /> }>
        <div className="table">
          <div className="filters" />
          <ListSupportMagicTable
            data={ data }
            mf={ this.mf }
            profile={ profile }
            refreshData={ this.onDataLoad }
            responsibles={ responsibles }
          />
          <PagePanel
            doText={ this.onPageText }
            doTo={ pageId => `?page=${ pageId }` }
            filter={ pages.filter }
            page={ this.pageId() }
            showSingle={ false }
            take={ pages.take }
            total={ pages.total }
            variance={ pages.variance }
            onClick={ this.onPageClick }
          />
        </div>
      </Loader>
    );
  }
}

export default withLocalize(ListSupport);