import React, { Component, } from 'react';

import TargetPageLanguageItemForm from "./TargetPageLanguageItemForm";

class TargetPageLanguageList extends Component {

	get remainingLanguageOptions () {
		const { languageOptions, languageFormList, } = this.props;

		if (!languageFormList) {
			return languageOptions;
		}
		//filter those that have been already used
		return languageOptions.filter(languageOption => !languageFormList.find(formItem => formItem.language_id.value === languageOption.value));
	}

	get disableAddButton () {
		const noOptionsLeft = !this.remainingLanguageOptions.length || this.props.languageFormList.length === this.props.languageOptions.length;
		const lastItemIsNotSaved = this.props.languageFormList.at(-1) && !this.props.languageFormList.at(-1).id;

		return noOptionsLeft || this.props.isViewMode || lastItemIsNotSaved;
	}
	renderList = () => {
		const {
			translate,
			languageFormList,
			languageStatusesOptions,
			isViewMode,
			languageOptions,
			deleteLanguageFormDataItem,
			createTargetPageLanguage,
			updateTargetPageLanguage,
		} = this.props;

		return languageFormList.map((languageForm, index) => {
			return (
					<TargetPageLanguageItemForm
						createTargetPageLanguage={ createTargetPageLanguage }
						deleteLanguageFormDataItem={ () => deleteLanguageFormDataItem(index) }
						formData={ languageForm }
						isViewMode={ isViewMode }
						key={ index }
						languageOptions={ languageOptions }
						languageStatusesOptions={ languageStatusesOptions }
						remainingLanguageOption={ this.remainingLanguageOptions }
						translate={ translate }
						updateTargetPageLanguage={ (id, formData) => updateTargetPageLanguage(id, formData, index) }
					/>
			);
		}).reverse();
	}
	render () {
		const { translate, addLanguage, } = this.props;

		return (
			<>
				<div
					className={ `promo-popup__button promo-popup__button_add mb-3 ${ this.disableAddButton ? 'disabled' : '' }` }
					onClick={ addLanguage }
				>
					{translate('partner_target_page_link_add_language')}
				</div>
				<div className={ 'promo-popup__block' }>
					{this.renderList()}
				</div>
			</>
		);
	}
}

export default TargetPageLanguageList;