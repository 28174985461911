export const timaLang = {
    "tima__?": [ "tima__?", "tima__?", "tima__?" ],

    "tima_breadcrumbs_agent": [ "TIMA agent", "TIMA агент", "TIMA агент", ],
    "tima_breadcrumbs_agents": [ "TIMA agents", "TIMA агенты", "TIMA агенти", ],
    "tima_breadcrumbs_condition": [ "TIMA condition", "TIMA условие", "TIMA умова", ],
    "tima_breadcrumbs_conditions": [ "TIMA conditions", "TIMA условия", "TIMA умови", ],
    "tima_breadcrumbs_investment": [ "TIMA investment", "TIMA инвестиция", "TIMA інвестиція", ],
    "tima_breadcrumbs_investments": [ "TIMA investments", "TIMA инвестиции", "TIMA інвестиції", ],
    "tima_breadcrumbs_strategy": [ "TIMA account", "TIMA счет", "TIMA рахунок", ],
    "tima_breadcrumbs_strategies": [ "TIMA accounts", "TIMA счета", "TIMA рахунки", ],

    "tima_link_agent": [ "TIMA agent", "TIMA агент", "TIMA агент", ],
    "tima_link_agents": [ "TIMA agents", "TIMA агенты", "TIMA агенти", ],
    "tima_link_agents_by_strategy": [ "TIMA agents of account", "TIMA агенты по счету", "TIMA агенти за рахунком", ],

    "tima_link_condition": [ "TIMA condition", "TIMA условие", "TIMA умова", ],
    "tima_link_conditions": [ "TIMA conditions", "TIMA условия", "TIMA умови", ],
    "tima_link_conditions_by_agent": [ "TIMA conditions by agent", "TIMA условия по агенту", "TIMA умови за агентом", ],
    "tima_link_conditions_by_strategy": [ "TIMA conditions by account", "TIMA условия по счетом", "TIMA умови за рахунком", ],

    "tima_link_investment": [ "TIMA investment", "TIMA инвестиция", "TIMA інвестиція", ],
    "tima_link_investments": [ "TIMA investments", "TIMA инвестиции", "TIMA інвестиції", ],
    "tima_link_investments_by_agent": [ "TIMA investments by agent", "TIMA инвестиции по агенту", "TIMA інвестиції за агентом", ],
    "tima_link_investments_by_condition": [ "TIMA investments by condition", "TIMA инвестиции по условию ", "TIMA інвестиції за умовою", ],
    "tima_link_investments_by_strategy": [ "TIMA investments by strategy", "TIMA инвестиции по счету ", "TIMA інвестиції за рахунком", ],

    "tima_link_strategy": [ "TIMA account", "TIMA счет", "TIMA рахунок", ],
    "tima_link_strategies": [ "TIMA accounts", "TIMA счета", "TIMA рахунки", ],

    "tima_strategies_h1": [ "TIMA", "TIMA", "TIMA", ],
    "tima_strategies_table_tima_account": ["TIMA account", "TIMA счет", "TIMA рахунок"],
    "tima_strategies_table_account_number": ["Account number", "Номер счета", "Номер рахунку"],
    "tima_strategies_table_client": ["Client", "Клиент", "Клієнт"],
    "tima_strategies_table_equity": ["Equity", "Средства", "Кошти"],
    "tima_strategies_table_balance": ["Balance", "Баланс", "Баланс"],
    "tima_strategies_table_currency": ["Currency", "Валюта", "Валюта"],
    "tima_strategies_table_share_of_capital": ["Share of capital, %", "Доля капитала, %", "Частка капіталу, %"],
    "tima_strategies_table_manager_balance": ["Balance of manager", "Баланс управляющего", "Баланс керуючого"],
    "tima_strategies_table_manager_equity": ["Equity of manager", "Средства управляющего", "Засоби керуючого"],
    "tima_strategies_table_status": ["Status", "Статус", "Статус"],
    "tima_strategies_table_trade_period": ["Trade period", "Торговый период", "Торговий період"],
    "tima_strategies_table_deal_time": ["Deal time, h", "Время исполнения заявок, ч", "Час виконання заявок, г"],
    "tima_strategies_table_deal_step": ["Deal step", "Порог приема заявок", "Поріг прийому заявок"],
    "tima_strategies_table_agent_reward": ["Agent Reward, %", "Вознаграждение агента, %", "Винагорода агента, %"],
    "tima_strategies_table_min_invest_risk": ["Min Invest Risk, %", "Минимальный риск на инвестицию, %", "Мінімальний ризик на інвестицію, %"],
    "tima_strategies_table_responsible": ["Responsible", "Ответственный", "Відповідальний"],

    "tima_strategies_table_manager_capital": ["Capital of manager", "Капитал управляющего", "Капітал керуючого"],
    "tima_strategies_table_server": ["Server", "Сервер", "Сервер"],
    "tima_strategies_table_manager_rating": ["Display in the ranking of managers", "Отображение в рейтинге управляющих", "Відображення в рейтингу керуючих"],
    "tima_strategies_table_contact_email": ["Contact e-mail", "Контактный e-mail", "Контактний e-mail"],
    "tima_strategies_table_contact_phone": ["Contact phone number", "Контактный телефон", "Контактний телефон"],
    "tima_strategies_table_closed_investment_accept": ["Close investment apply", "Запретить прием инвестиций", "Заборонити прийом інвестицій"],
    "tima_strategies_table_deny_investment": ["Close investment apply", "Запретить прием инвестиций", "Заборонити прийом інвестицій"],
    "tima_strategies_table_inv_manual_confirm": ["Invest approvement", "Подтверждение инвестиции", "Підтвердження інвестиції"],
    "tima_strategies_table_is_withdraw": ["Reinvestment", "Реинвестированиe", "Реінвестування"],
    "tima_strategies_table_date_updated": ["Date updated", "Дата изменения", "Дата зміни"],
    "tima_strategies_table_date_created": ["Date created", "Дата создания", "Дата створення"],
    "tima_strategies_table_deals_info": ["Closed order history", "История закрытых сделок", "Історія закритих угод"],
    "tima_strategies_table_is_contest": ["Winner of contest", "Победитель конкурса", "Переможець конкурсу"],
    "tima_strategies_table_notifications": ["Notifications", "Уведомления", "Повідомлення"],

    "tima_strategies_table_new_inv_notification": ["application for new investment", "заявка на новую инвестицию", "заявка на нову інвестицію"],
    "tima_strategies_table_closing_inv_notification": ["application for investment closing", "заявка на закрытие инвестиции", "заявка на закриття інвестиції"],
    "tima_strategies_table_withdraw_notification": ["application for profit withdrawal", "заявка на вывод прибыли", "заявка на виведення прибутку"],
    "tima_strategies_table_from_agent_notification": ["application from agent", "заявка от агента", "заявка від агента"],
    "tima_strategies_table_disable_from_agent_notification": ["cancellation of applications from an agent", "отмена заявок от агента", "скасування заявок від агента"],
    "tima_strategies_table_close_inv_by_risk_notification": ["investment risk closure", "закрытие инвестиции по риску на инвестицию", "закриття інвестиції за ризиком на інвестицію"],

    "tima_investments_table_investment_id": ["ID", "ID", "ID"],
    "tima_investments_table_investment_number": ["Investment №", "Инвестиция №", "Інвестиція №"],
    "tima_investments_table_client": ["Client", "Клиент", "Клієнт"],
    "tima_investments_table_amount": ["Summ", "Сумма", "Сума"],
    "tima_investments_table_currency": ["Currency", "Валюта", "Валюта"],
    "tima_investments_table_tima_account": ["TIMA account", "TIMA счет", "TIMA рахунок"],
    "tima_investments_table_status": ["Status", "Статус", "Статус"],
    "tima_investments_table_risk": ["Risk %", "Риск %", "Ризик %"],
    "tima_investments_table_reinvest": ["Reinvestment", "Реинвестирование", "Реінвестування"],
    "tima_investments_table_share": ["Share, %", "Доля, %", "Частка, %"],
    "tima_investments_table_equity": ["Equity", "Средства", "Кошти"],
    "tima_investments_table_current_yield_investments": ["Current invest profitability, %", "Текущая доходность инвестиции, %", "Поточна прибутковість інвестиції, %"],
    "tima_investments_table_net_profit": ["Net profit", "Чистая прибыль", "Чистий прибуток"],
    "tima_investments_table_tima_agent": ["TIMA agent", "TIMA агент", "TIMA агент"],
    "tima_investments_table_date_creation": ["Date of creation", "Дата создания", "Дата створення"],
    "tima_investments_table_date_closing": ["Closing date", "Дата закрытия", "Дата закриття"],
    "tima_investments_table_responsible": ["Responsible", "Ответственный", "Відповідальний"],

    "tima_investments_table_tima_condition": ["Condition", "Условие", "Умова"],
    "tima_investments_table_version": ["Version", "Версия", "Версія"],

    "tima_edit_investment_condition_title": ["Editing an investment condition for an investment", "Редактирование условия инвестирования для инвестиции", "Редагування умови інвестування для інвестиції"],

    "tima_investment_history_table_title": ["Investment history", "История инвестиции", "Історія інвестиції"],
    "tima_investment_history_table_date_created": ["Date created", "Дата создания", "Дата створення"],
    "tima_investment_history_table_type": ["Type", "Тип", "Тип"],
    "tima_investment_history_table_amount": ["Amount", "Сумма", "Сума"],
    "tima_investment_history_table_profitability_account": ["Profitability of account, %", "Доходность  по тима счету, %", "Прибутковість по TIMA рахунку, %"],
    "tima_investment_history_table_profitability_investment": ["Profitability of investment, %", "Доходность по тима инвестиции, %", "Прибутковість по TIMA інвестиції, %"],

    "tima_conditions_table_version": ["Version", "Версия", "Версія"],
    "tima_conditions_table_tima_condition": ["Condition", "Условие", "Умова"],
    "tima_conditions_table_invest_min": ["Min investment", "Мин. инвестиция", "Мін. інвестиція"],
    "tima_conditions_table_invest_max": ["Max investment", "Макс. инвестиция", "Макс. інвестиція"],
    "tima_conditions_table_reward": ["Reward of manager", "Вознаграждение управляющего, %", "Винагорода керуючого, %"],
    "tima_conditions_table_min_invest_period": ["Min period of investment", "Мин. период инвестирования", "Мін. період інвестування"],
    "tima_conditions_table_penalty": ["Penalty", "Штраф, %", "Штраф, %"],
    "tima_conditions_table_tima_account": ["TIMA account", "TIMA счет", "TIMA рахунок"],
    "tima_conditions_table_date_creation": ["Date created", "Дата создания", "Дата створення"],
    "tima_conditions_table_date_closing": ["Date closed", "Дата закрытия", "Дата закриття"],

    "tima_conditions_table_select_row": ["Select", "Выбрать", "Обрати"],

    "tima_conditions_table_status": ["Status", "Статус", "Статус"],
    "tima_conditions_table_responsible": ["Responsible", "Ответственный", "Відповідальний"],
    "tima_agents_table_tima_agent": ["TIMA agent #", "TIMA Агент №", "TIMA Агент №"],
    "tima_agents_table_client": ["Сlient", "Клиент", "Клієнт"],
    "tima_agents_table_tima_account": ["TIMA account", "TIMA счет", "TIMA рахунок"],
    "tima_agents_table_status": ["Status", "Статус", "Статус"],
    "tima_agents_table_agent_reward": ["Reward, %", "Вознаграждение, %", "Винагорода, %"],
    "tima_agents_table_responsible": ["Responsible", "Ответственный", "Відповідальний"],

    "tima_agents_table_date_updated": ["Date updated", "Дата изменения", "Дата зміни"],
    "tima_agents_table_date_created": ["Date created", "Дата создания", "Дата створення"],

    "tima_pages_items": ["Items", "Записи", "Записи"],
    "tima_pages_filtered": ["Items after filtration", "Записей после фильтрации", "Записів після фільтрації"],
    "tima_pages_total": ["Items total", "Записей в целом", "Записів в цілому"],
    "tima_strategies_pages_items": ["Accounts", "Счета", "Рахунки"],
    "tima_strategies_pages_filtered": ["Accounts after filtration", "Счетов после фильтрации", "Рахунків після фільтрації"],
    "tima_strategies_pages_total": ["Accounts total", "Счетов в целом", "Рахунків в цілому"],
    "tima_investments_pages_items": ["Investments", "Инвестиции", "Інвестиції"],
    "tima_investments_pages_filtered": ["Investments after filtration", "Инвестиций после фильтрации", "Інвестицій після фільтрації"],
    "tima_investments_pages_total": ["Investments total", "Инвестиций в целом", "Інвестицій в цілому"],
    "tima_conditions_pages_items": ["Conditions", "Условия", "Умови"],
    "tima_conditions_pages_filtered": ["Conditions after filtration", "Условий после фильтрации", "Умов після фільтрації"],
    "tima_conditions_pages_total": ["Conditions total", "Условий в целом", "Умов в цілому"],
    "tima_agents_pages_items": ["Agents", "Агенты", "Агенти"],
    "tima_agents_pages_filtered": ["Agents after filtration", "Агентов после фильтрации", "Агентів після фільтрації"],
    "tima_agents_pages_total": ["Agents total", "Агентов в целом", "Агентів в цілому"],


};
