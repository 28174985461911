import React from 'react';
import { Pagination as Pag, PaginationItem, PaginationLink } from 'reactstrap';

export default class Pagination extends React.Component {
    render () {
        return (
            <Pag aria-label = 'Page navigation bottom'>
                <PaginationItem>
                    <PaginationLink>
                        {this.props.translate(`transactions_first`)}
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink previous/>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink >
                        1
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink next/>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink>
                        {this.props.translate(`transactions_last`)}
                    </PaginationLink>
                </PaginationItem>
            </Pag>
        );
    }
}
