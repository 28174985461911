const partnersChildren = [
	{
		path: [ "partners", "partners", ],
		to: "/partners/requests/partners-list",
	},
	{
		path: [ "partners", "requests", ],
		to: "/partners/requests/partnership-application",
	},
];

export default partnersChildren;