export const templateManagement = {
    "template_management": ["Template Management", "Управление шаблонами", "Керування шаблонами"],
    "template_management_template": ["template", "шаблон", "шаблон"],
    "template_management_template_translation": ["template", "шаблона", "шаблону"],
    "template_management_translation": ["translation", "перевод", "переклад"],
    "template_management_edit": ["Edit", "Редактировать", "Редагувати"],
    "template_management_create": ["Create", "Создать", "Створити"],
    "template_management_created_at": ["Created", "Создано", "Створено"],
    "template_management_template_name": ["Template name", "Имя Шаблона", "Ім'я шаблону"],
    "template_management_template_name_placeholder": ["Введите имя шаблона", "Введите имя шаблона", "Введіть ім'я шаблону"],
    "template_management_description_placeholder": ["Enter template description", "Введите описание шаблона", "Введіть опис шаблону"],
    "template_management_description": ["Description", "Описание", "Опис"],
    "template_management_active": ["Active", "Активный", "Активний"],
    "template_management_not_active": ["Not active", "Не активный", "Не активний"],
    "template_management_status": ["Status", "Статус", "Статус"],
    "template_management_language": ["Language", "Язык", "Мова",],
    "template_management_action": ["Action", "Действие", "Дія"],
    "template_management_search": ["Search", "Поиск", "Пошук"],
    "template_management_apply": ["Apply", "Применить", "Застосувати"],
    "template_management_filters": ["Filters", "Фильтры", "Фільтри"],
    "template_management_reset": ["Reset", "Сброс", "Скасувати"],
    "template_management_tab_email": ["E-Mail", "E-Mail", "E-Mail"],
    "template_management_tab_messenger": ["Messenger", "Телефон", "Телефон"],
    "template_management_tab_sms": ["SMS", "CMC", "CMC"],
    "template_management_btn_arhive": ["Arhive", "Aрхив", "Aрхів"],
    "template_management_btn_active": ["Active", "Активний", "Активний"],
    "template_management_btn_edit": ["Edit", "Редактировать", "Редагувати"],
    "template_management_btn_update": ["Update", "Обновить", "Оновити"],
    "template_management_btn_save": ["Save", "Сохранить", "Зберегти"],
    "template_management_btn_delete": ["Delete", "Удалить", "Видалити"],
    "template_management_btn_cancel": ["Cancel", "Отменить", "Скасувати"],
    "template_management_history_changes": ["History of Сhanges", "История изменений", "Історія змін"],
    "template_management_are_u_sure": ["Are you sure?", "Вы уверены?", "Ви впевнені?"],
    "template_management_are_u_sure_confirm": ["Do you want to save the field?", "Вы хотите сохранить поле?", "Ви хочете зберегти поле?"],
    "template_management_no": ["No", "Нет", "Нi"],
    "template_management_yes": ["Yes", "Да", "Так"],
    "template_management_you_want_to_delete": ["You want to delete", "Вы хотите удалить", "Ви хочете видалити"],
    "template_management_input_name": ["Template name", "Имя шаблона", "Ім'я шаблону"],
    "template_management_add_template": ["Add template translation", "Добавить перевод шаблона", "Додати переклад шаблону"],
    "template_management_add_template_added": ["Add", "Добавлено", "Додано"],
    "template_management_add_template_add-file": ["Add file", "Добавить файл", "Додати файл"],
    "template_management_create_template": ["Template created", "Шаблон создан", "Шаблон створено"],
    "template_management_update_template": ["Template updated", "Шаблон обновлен", "Шаблон оновлено"],
    "template_management_delete_template": ["Template deleted", "Шаблон удален", "Шаблон видалено"],
    "template_management_create_template_translation": ["Template translation created", "Шаблон перевода создан", "Шаблон перекладу створено"],
    "template_management_update_template_translation": ["Template translation updated", "Шаблон перевода обновлен", "Шаблон перекладу оновлено"],
    "template_management_delete_template_translation": ["Template translation deleted", "Шаблон перевода удален", "Шаблон перекладу видалено"],
    "template_management_upload_doc_translation": ["Document uploaded", "Документ загружен", "Документ завантажений"],
    "template_management_delete_doc_translation": ["Document deleted", "Документ удален", "Документ видалений"],
    "template_management_download_doc_translation": ["Document downloaded", "Документ загружен", "Документ завантажено"],
    "template_management_choose_language": ["Select a language", "Выберите язык", "Оберіть мову"],
    "template_management_enter_subject": ["Enter Email subject", "Введите тему письма", "Введіть тему листа"],
    "template_management_enter_body": ["Enter the email body", "Введите тело письма", "Введіть тіло листа"],
    "template_management_enter_body_sms": ["Enter the message text", "Введите текст сообщения", "Введіть текст повідомлення"],
    "template_management_error_size": ["Maximum file size 10000KB", "Максимальный размер файла 10000KB", " Максимальний розмір файлу 10000KB"],
    "template_management_error_max_length": ["Maximum Email body length is 3500 symbols", "Максимальный размер тела письма 3500 символов", "Максимальний розмір тіла листа 3500 символів"],
    "template_management_error_file_max_length": ["The maximum number of files is 5", "Максимальное количество файлов 5шт.", "Максимальна кількість файлів 5шт."],
}
