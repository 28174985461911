import React, { Component } from 'react';
import PropTypes from "prop-types";

export class MagicPopUp extends Component {
    constructor (props) {
        super(props);

        this.state = {};
    }

    componentDidMount () {

    }

    popUpClose = () => {
        const { closeFunc, callBackFunc } = this.props;

        closeFunc();
        callBackFunc && typeof callBackFunc === 'function' && callBackFunc();
    };

    render () {
        const { mode, message } = this.props;
        const prefix = 'magic-Popup';

        let magicClassName = mode ? `${prefix}--${mode}` : '';

        return (
            <div className={`glalex-styles magic-popup magic-popup--active ${magicClassName}`}>
                <div className='magic-popup-wrapper'>
                    <div className='magic-popup-header'>
                        <h3 className='magic-popup__name'>
                            &nbsp;
                        </h3>
                        <i className='gl-icon close-btn close-btn--big' onClick={this.popUpClose}/>
                    </div>

                    <div className='magic-popup-content'>
                        <div className='magic-popup--message'>
                            {message}
                        </div>
                    </div>

                    <div className='btns-cont'>
                        <button className='gl-btn gl-btn--blue-border'
                                onClick={this.popUpClose}
                        >
                            OK
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

MagicPopUp.propTypes = {
    message:      PropTypes.string.isRequired,
    mode:         PropTypes.string.isRequired,
    closeFunc:    PropTypes.func.isRequired,
    callBackFunc: PropTypes.func,
};
