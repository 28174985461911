
const SummaryLinkConfig = (translate, order, {
	active,
	orderId,
} = {}) => [
	{
		id: 'payments',
		link: '/payments',
		text: translate('payment_title'),
	},
	{
		id: 'summary',
		link: '/payments/summary_date',
		text: translate('summary_table_title'),
	},
]
	.map((_) => {
		const { id, } = _;
		const index = [ ...order, ].indexOf(id);

		if (index < 0) {
			return false;
		} else if (!active) {
			return { item: { ..._, }, index, };
		}

		return { item: { ..._, active: id == active, }, index, };

	})
	.filter(_ => _)
	.sort((a, b) => a.index - b.index)
	.map(({ item, }) => item)
	.map(({
		id, link, text, active,
	}) => ({
		link, text, key: id, active,
	}));

export default SummaryLinkConfig;