import React from 'react';
import { isUndefined } from "lodash";

import LeadAppealsUser from './LeadAppealsUser';
import PermissionService, { MODULE_KEYS as pm } from "../../../../../services/PermissionService";
import CheckUndefinedPermission from "../../../../../components/Common/CheckUndefinedPermission";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import { bindActionCreators } from "redux";
import {
    downloadDocument,
} from "../../../../tasks/services/TasksService";

import NewTaskPage from "../../../../tasks/components/NewTaskPage/index";

class LeadAppeals extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dataListUsers: this.props.items,
            dataListUsersView: this.props.items,
            currentTask: this.props.items[0] || {},
            showList: false,
            order: [],
        };
    }


    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        let id = nextProps.currentId;

        if (!isUndefined(this.props.match.params.taskId) && nextProps.currentId !== this.props.match.params.taskId) {
            id = parseInt(this.props.match.params.taskId);
        }

        for (const key in nextProps.items) {
            if (nextProps.items[key]['tasks.tasks.id'] === id) {
                this.setState({
                    currentTask: nextProps.items[key],
                });
            }
        }

        this.setState({
            dataListUsers:     nextProps.items,
            dataListUsersView: nextProps.items,
        });
    }

    updateAppealsAccount = (id) => {
        this.props.updateAppealsAccount(id);
    };

    getDocumentTaskID = (task_id) => {
        this.props.getDocumentTaskID(task_id);
    };

    setActiveUser = (id) => {
        this.props.updateClientId(id);
        const page = this.props.pageId();
        this.props.history.push(`/clients/${this.props.match.params.id}/tasks/task/${id}?page=${page}`);
        //todo get lists

        this.setState({
            showList: false,
            currentTask: id || this.state?.dataListUsersView?.[0]?.tasks?.tasks?.id || {},
        });
    };

    render() {
        return (
            <div className='appealsList'>
                <div className='table-wrapper'>
                    <CheckUndefinedPermission>
                        {PermissionService.actionForRoute(pm.TASKS_TASKS, 'getAccountTasks') ?
                            <div className='appealsList_module'>
                                <div className="appeals_user">
                                    <LeadAppealsUser
                                        {...this.props}
                                        setActiveUser={this.setActiveUser}
                                        currentId={ this.props.match?.params?.taskId ?? this.state.currentTask?.tasks?.tasks?.id }

                                    />
                                </div>
                                {
                                    (this.props.match?.params?.taskId || this.state.currentTask?.tasks?.tasks?.id) && <div id='activeUser' className='appealsList_task'>
                                        <NewTaskPage
                                            appeals={true}
                                            taskId={this.props.match?.params?.taskId ?? this.state.currentTask?.tasks?.tasks?.id}
                                            close={this.setStateModalTask}
                                        />

                                    </div>
                                }
                            </div>
                            : ""
                        }
                    </CheckUndefinedPermission>
                </div>
            </div>
        );
    }
}

export default withLocalize(withRouter(connect(
    (dispatch) =>
        bindActionCreators({
            downloadDocument,
        }, dispatch)
)(LeadAppeals)));
