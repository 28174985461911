import React from "react";
// import BreadcrumbText from './BreadcrumbText';
// import BreadcrumbLink from './BreadcrumbLink';
// import BreadcrumbItem from './BreadcrumbItem';
import BreadcrumbList from './BreadcrumbList';
import './styles.scss';

export default function Breadcrumbs ( props ) {
    return (
        <div className='breadcrumbs'>
            <BreadcrumbList { ...props } />
        </div>
    );
}
