import TimaLinkConfig from "./TimaLinkConfig";

export const toolbarInit = ( translate, active, permissions ) =>
    TimaLinkConfig(
        translate,
        [
            'strategies',
            'investments',
            'conditions',
            'agents',
        ],
        {
            active,
        },
        permissions
    );

export const toolbarByAgent = ( translate, active, agentId, permissions ) =>
    TimaLinkConfig(
        translate,
        [
            'agent',
            'investmentsByAgent',
        ],
        {
            active,
            agentId,
        },
        permissions
    );

export const toolbarByCondition = ( translate, active, conditionId, permissions ) =>
    TimaLinkConfig(
        translate,
        [
            'condition',
            'investmentsByCondition',
        ],
        {
            active,
            conditionId,
        },
        permissions
    );

export const toolbarByStrategy = ( translate, active, strategyId, permissions ) =>
    TimaLinkConfig(
        translate,
        [
            'strategy',
            'investmentsByStrategy',
            'conditionsByStrategy',
            'agentsByStrategy',
        ],
        {
            active,
            strategyId,
        },
        permissions
    );
