import React from 'react';
import { sortBy, map, isEmpty } from "lodash";

import { withRouter } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import { bindActionCreators } from "redux";
import { deleteComment, getCommentsTaskID } from "../../services/TasksService";
import { createComment, updateComment } from "../../services/TaskRequestService";
import DiscussionBlockComment from './DiscussionBlockComment';
import DiscussionBlockCommentForm from './DiscussionBlockCommentForm';
import NotificationService from '../../../../services/NotificationService';
import { withLocalize } from 'react-localize-redux';
import { getTaskResponsibles } from '../../services/TaskRequestService';

class DiscussionBlock extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            taskId:      this.props.task.tasks.tasks.id,
            commentTask: {
                name:        "name",
                user_id:     window.localStorage.getItem('userId'),
                task_id:     this.props.task.tasks.tasks.id,
                description: "",
                parent_id:   null,
                files:       [],
            },
            commentId: '',
            reply:     false,
            managers:  [],
            autoFocus: false,
        };

        this.scrollParentComment = [];
    }

    componentDidMount () {
        this.props.getCommentsTaskID(this.state.taskId);
        this.getManagers();
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        if (nextProps.task.tasks.tasks.id !== this.state.taskId) {
            this.setState({
                taskId:      nextProps.task.tasks.tasks.id,
                commentTask: { ...this.state.commentTask,
                    task_id: nextProps.task.tasks.tasks.id,
                },
            }, () => {
                this.props.getCommentsTaskID(nextProps.task.tasks.tasks.id);
            });
        }
    }

    save = async (state) => new Promise((next) => this.setState(state, next));

    getManagers = async () => {
        const data = await getTaskResponsibles();

        await this.save({ managers: data });
    }

    setAutoFocusTrue = async () => await this.save({ autoFocus: true, });

    sendComment = async () => {
        const { files, ...comment } = this.state.commentTask;

        if (comment.description.length > 0 || files.length > 0) {
            const commentTask = new FormData();

            if (files.length > 0) {
                files.map((item) => commentTask.append('files[]', item));
            }

            try {
                await createComment(comment, commentTask);
                await this.props.getCommentsTaskID(this.state.taskId);
                await this.save({
                    commentTask: { ...comment,
                        parent_id:   null,
                        files:       [],
                        description: "",
                    },
                });
            } catch (err) {
                if (err.errors) {
                    for (const key in err.errors) {
                        NotificationService.error({
                            title:   'error',
                            message: err.errors[key][0],
                        });
                    }
                }
            }
        } else {
            NotificationService.error({
                title:   'error',
                message: this.props.translate('task_error_empty_comment'),
            });
        }
    };

    sendCommentUpdate = (value) => {
        updateComment(this.state.commentTask, value).then(() => {
            this.props.getCommentsTaskID(this.state.taskId);
        }).then(() => {
            this.setState({ commentTask: { ...this.state.commentTask, 'description': '' }});
        });
    };

    setToStateComment = (event) => {
        this.setState({
            commentTask: { ...this.state.commentTask,
                [event.target.name]: event.target.value,
            },
        });
    };

    attachFileToComment = async (event) => {
        const file = event.target.files[0];

        await this.save({
            commentTask: { ...this.state.commentTask, files: [...this.state.commentTask.files, file]},
        });
    }

    handleDeleteAttachment = async (i) => {
        const files = [...this.state.commentTask.files];

        files.splice(i, 1);
        await this.save({
            commentTask: { ...this.state.commentTask, files },
        });
    }

    removeReply = () => {
        this.setState({
            reply:       false,
            commentTask: { ...this.state.commentTask,
                'parent_id': null,
            },
        });
    };

    setParentId = (id) => {
        this.setState({
            reply:       true,
            commentTask: { ...this.state.commentTask,
                'parent_id': id,
            },
        });
    };

    sendUpdateComment = (value) => {
        this.sendCommentUpdate(value);
    };

    deleteCommentAction = (value) => {
        this.setState({ commentId: value });
        this.props.remove(this.props.type, value);
    };

    _sortComments = () => {
        return sortBy(this.props.comments.data, (item) => {
            return item?.tasks?.task_comments?.created_at;
        });
    };

    moveToParentComment = (id) => {
        this.scrollParentComment[id].current.scrollIntoView({ behavior: 'smooth' });
    };

    findUserById = (id) => {
        if (!id || this.state?.managers.length <= 0) return null;

        return this.state?.managers?.find((item) => item.id == id);
    }

    _getComments = () => {
        const sort_new_parent_id = this._sortParentId();

        return [map(sort_new_parent_id, (item, key) => {
            if (!isEmpty(item)) {
                this.scrollParentComment[item?.tasks?.task_comments?.id] = React.createRef();

                return (<div ref = { this.scrollParentComment[item?.tasks?.task_comments?.id] }>
                    <DiscussionBlockComment
                        comment = { item }
                        commentTask = { this.state.commentTask }
                        deleteCommentAction = { this.deleteCommentAction }
                        findUserById = { this.findUserById }
                        id = { item?.tasks?.task_comments?.id }
                        key = { key }
                        lastComment = { key }
                        moveToParentComment = { this.moveToParentComment }
                        scrollComment = { this.props.scrollComment }
                        sendComment = { this.sendComment }
                        sendUpdateComment = { this.sendUpdateComment }
                        setParentId = { this.setParentId }
                        setToStateComment = { this.setToStateComment }
                        sort_new_parent_id = { sort_new_parent_id }
                        translate = { this.props.translate }
                        userId = { +window.localStorage.getItem('userId') }
                        setAutoFocusTrue = { this.setAutoFocusTrue }
                    />
                </div>
                );
            }

            return "";

        }), sort_new_parent_id];
    };

    _sortParentId = () => {
        const sort_comments_data = this._sortComments();

        const childArr = [];

        map(sort_comments_data, (parent) => {
            childArr[parent?.tasks?.task_comments?.id] = parent;
        });

        return childArr;
    };

    render () {
        const { translate } = this.props;
        const comments = this._getComments();

        return (
            <div className = 'flex-wrapper lead-block discussion-block'>
                <h6 className = 'discussion-block-title'>{ this.props.translate(`tasks_discussion`) }</h6>
                <div className = 'discussion-list'>{comments[0]}</div>
                <DiscussionBlockCommentForm
                    assistants = { this.props.assistants ?? [] }
                    allComent = { comments[1] }
                    attachFileToComment = { this.attachFileToComment }
                    commentTask = { this.state.commentTask }
                    findUserById = { this.findUserById }
                    handleDeleteAttachment = { this.handleDeleteAttachment }
                    removeReply = { this.removeReply }
                    reply = { this.state.reply }
                    sendComment = { this.sendComment }
                    setParentId = { this.setParentId }
                    setToStateComment = { this.setToStateComment }
                    translate = { translate }
                    autoFocus = { this.state.autoFocus }
                    setAutoFocusTrue = { this.setAutoFocusTrue }
                />
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        comments: store.tasks.setCommentsTaskID,
    };
};

DiscussionBlock.defaultProps = {};

export default withRouter(connect(
    mapStateToProps,
    (dispatch) =>
        bindActionCreators({
            getCommentsTaskID,
            deleteComment,
        }, dispatch)
)(withLocalize(DiscussionBlock)));
