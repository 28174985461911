import * as actions from './actionTypes';

export const setFilterId = (data) => ({
    type: actions.GET_FILTER_ID,
    data,
});

export const setAllClients = (data) => ({
    type: actions.SET_CLIENTS,
    data,
});

export const clearAllClients = () => ({
    type: actions.CLEAR_ALL_CLIENTS,
    data: {},
});

export const setClient = (data) => ({
    type: actions.SET_CLIENT,
    data,
});

export const resetError = () => ({
    type: actions.RESET_ERROR,
    data: false,
});

export const setError = (data) => ({
    type: actions.SET_ERROR,
    data,
});

export const delClient = () => ({
    type: actions.DELETE_CLIENT,
    data: {},
});

export const getEnums = (data) => ({
    type: actions.GET_ENUMS,
    data,
});

export const tradingAccount = (data) => ({
    type: actions.GET_TRADING,
    data,
});

export const singleTradingAccount = (data) => ({
    type: actions.GET_SINGLE_TRADING,
    data,
});

export const transactionsAccount = (data) => ({
    type: actions.GET_TRANSACTIONS,
    data,
});

export const getInteraction = (data) => ({
    type: actions.GET_INTERACTION,
    data,
});

export const getAppeals = (data) => ({
    type: actions.GET_APPEALS,
    data,
});

export const updateAppeals = (data) => ({
    type: actions.UPDATE_APPEALS,
    data,
});

export const getHistory = (data) => ({
    type: actions.GET_HISTORY,
    data,
});

export const getTails = (data) => ({
    type: actions.GET_TAILS,
    data,
});

export const filterOptions = (data) => ({
    type: actions.GET_OPTIONS,
    data,
});

export const getNewCategory = (data) => ({
    type: actions.GET_NEW_CATEGORY,
    data,
});

export const setFilterCategory = (data) => ({
    type: actions.SET_FILTER_CATEGORY,
    data,
});

export const clearFilterCategory = () => ({
    type: actions.CLEAR_FILTER_CATEGORY,
    data: {}
});

export const filterHash = (data) => ({
    type: actions.FILTER_HASH,
    data,
});