import React, {Component} from "react";
import {withLocalize} from "react-localize-redux";
import Preloader from "../../../components/LoadingHOC/Preloader";
import Loader from "./Loader";
import PermissionService from "../../../services/PermissionService";
import {Link} from "react-router-dom";
import {MagicConfirm} from "./Magic/MagicConfirm";
import {MagicRadioSwitch, MagicSwitch} from "./Magic/MagicSwitch";
import {MagicTextarea} from "./Magic/MagicTextarea";
import NotificationService from "../../../services/NotificationService";
import {timaService} from "../../../services/TimaDataService";

class OneTimaStrategy extends Component {
    constructor (props) {
        super(props);

        let id = null;

        if (this.props.params && this.props.params.strategyId) {
            id = +this.props.params.strategyId;
        }

        this.state = {
            loaded:                false,
            strategy:          {
                data: [],
                hash: null,
            },
            id,
            magicEditValuePrev:    {},
            magicEditValueCurrent: {},
            magicEditSelectionPos: {},
            confirm:               false,
            editMode:              false,
        };
    }

    save = async (state) => new Promise(next => this.setState(state, next));

    strategyChanges = async (strategy) => {
        if (strategy.hash===this.state?.strategy?.hash) return `${this.constructor.name}.strategyChanges: false`;
        if (strategy.strategyId===this.state.id) return `${this.constructor.name}.strategyChanges: false`;

        await this.save({ strategy });

        return `${this.constructor.name}.strategyChanges: true`;
    };

    componentDidMount = async () => {
        timaService.subscribe('strategy', this.strategyChanges, this);
        try {
            const { data, hash } = await timaService.strategy(this.state.id);
            await this.save((state) => ({ ...state, loaded: true, strategy: { data, hash }, }));
        } catch (error){
            console.log(error);
        }
    };

    componentWillUnmount = () => {
        timaService.unsubscribe('strategy', this.strategyChanges, this);
    };

    get OneTimaStrategyConfig () {
        const { translate, } = this.props;
        const prefix = 'tima_strategies_table_';

        return [
            {
                path:   ['tima', 'tima_manager', 'name'],
                key:    'timaManagerName',
                render: this.renderBlockCont({ fieldName: 'tima_manager' }),
                title:  `${prefix}tima_account`,
            },
            {
                path:   ['tima', 'tima_manager', 'status'],
                key:    'timaManagerStatus',
                render: this.renderBlockCont({ fieldName: 'status' }),
                title:  `${prefix}status`,
            },
            {
                path:   ['core', 'trading_accounts', 'acc_ccy'],
                key:    'currency',
                render: this.renderBlockCont({ isEnum: true, enumPath: 'core', enumName: 'AccountCurrencyEnum' }),
                title:  `${prefix}currency`,
            },
            {
                path:   ['tima', 'tima_manager', 'manager_capital'],
                key:    'timaManagerCapital',
                render: this.renderBlockCont(),
                title:  `${prefix}manager_capital`,
            },
            {
                path: ['core', 'trading_accounts', 'id'],
                key:  'trading_account_id',
            },
            {
                path:   ['core', 'trading_accounts', 'mt_login'],
                key:    'accountNumber',
                render: this.renderBlockContLink({ fieldName: 'trading_account', path: '/list-accounts/tradings' }),
                title:  `${prefix}account_number`,
            },
            {
                path:   ['core', 'trading_accounts', 'platform'],
                key:    'platform',
                render: this.renderBlockCont({
                    isEnum:   true,
                    enumPath: 'core',
                    enumName: 'AccountBalancesPlatformEnum'
                }),
                title:  `${prefix}server`,
            },
            {
                path: ['core', 'account', 'id'],
                key:  'clientId',
            },
            {
                path: ['core', 'account', 'name'],
                key:  'clientName',
            },
            {
                path: ['core', 'account', 'surname'],
                key:  'clientSurname',
            },
            {
                accessChecked: true,
                key:           'client',
                render:        this.renderFullNameCont({ fieldName: 'client', path: '/clients' }),
                title:         `${prefix}client`,
            },
            {
                path:   ['tima', 'tima_manager', 'share'],
                key:    'timaManagerShare',
                render: this.renderBlockCont(),
                title:  `${prefix}share_of_capital`,
            },
            {
                path:   ['tima', 'tima_manager', 'manager_rating'],
                key:    'timaManagerRating',
                render: this.renderBlockCont({ fieldName: 'manager_rating' }),
                title:  `${prefix}manager_rating`,
            },
            {
                path:   ['tima', 'tima_manager', 'contact_email'],
                key:    'timaManagerContactEmail',
                render: this.renderBlockCont({ fieldName: 'contact_email' }),
                title:  `${prefix}contact_email`,
            },
            {
                path:   ['tima', 'tima_manager', 'contact_phone'],
                key:    'timaManagerContactPhone',
                render: this.renderBlockCont({ fieldName: 'contact_phone' }),
                title:  `${prefix}contact_phone`,
            },
            {
                path: ['core', 'user', 'id'],
                key:  'userId',
            },
            {
                path: ['core', 'user', 'name'],
                key:  'userName',
            },
            {
                path: ['core', 'user', 'surname'],
                key:  'userSurname',
            },
            {
                accessChecked: true,
                key:           'responsible',
                render:        this.renderFullNameCont({ fieldName: 'user', path: '/settings/user' }),
                title:         `${prefix}responsible`,
            },
            {
                path:   ['tima', 'tima_manager', 'trade_period'],
                key:    'timaManagerTradePeriod',
                render: this.renderBlockCont({ fieldName: 'trade_period' }),
                title:  `${prefix}trade_period`,
            },
            {
                path:   ['tima', 'tima_manager', 'deal_time'],
                key:    'timaManagerDealTime',
                render: this.renderBlockCont({ fieldName: 'deal_time' }),
                title:  `${prefix}deal_time`,
            },
            {
                path:   ['tima', 'tima_manager', 'deal_step'],
                key:    'timaManagerDealStep',
                render: this.renderBlockCont({ fieldName: 'deal_step' }),
                title:  `${prefix}deal_step`,
            },
            {
                path:   ['tima', 'tima_manager', 'closed_investment_accept'],
                key:    'timaManagerClosedInvestmentAccept',
                render: this.renderBlockCont({ fieldName: 'closed_investment_accept' }),
                title:  `${prefix}closed_investment_accept`,
            },
            {
                path:   ['tima', 'tima_manager', 'deny_investment'],
                key:    'timaManagerDenyInvestment',
                render: this.renderBlockCont({ fieldName: 'deny_investment' }),
                title:  `${prefix}deny_investment`,
            },
            {
                path:   ['tima', 'tima_manager', 'inv_manual_confirm'],
                key:    'timaManagerInvManualConfirm',
                render: this.renderBlockCont({ fieldName: 'inv_manual_confirm' }),
                title:  `${prefix}inv_manual_confirm`,
            },
            {
                path:   ['tima', 'tima_manager', 'is_withdraw'],
                key:    'timaManagerIsWithdraw',
                render: this.renderBlockCont({ fieldName: 'is_withdraw' }),
                title:  `${prefix}is_withdraw`,
            },
            {
                path:   ['tima', 'tima_manager', 'updated_at'],
                key:    'timaManagerUpdatedAt',
                render: this.renderBlockCont(),
                title:  `${prefix}date_updated`,
            },
            {
                path:   ['tima', 'tima_manager', 'created_at'],
                key:    'timaManagerCreatedAt',
                render: this.renderBlockCont(),
                title:  `${prefix}date_created`,
            },
            {
                path:   ['tima', 'tima_manager', 'agent_reward'],
                key:    'timaManagerAgentReward',
                render: this.renderBlockCont({ fieldName: 'agent_reward' }),
                title:  `${prefix}agent_reward`,
            },
            {
                path:   ['tima', 'tima_manager', 'min_invest_risk'],
                key:    'timaManagerMinInvestmentRisk',
                render: this.renderBlockCont({ fieldName: 'min_invest_risk' }),
                title:  `${prefix}min_invest_risk`,
            },
            {
                path:   ['tima', 'tima_manager', 'deals_info'],
                key:    'timaManagerDealsInfo',
                render: this.renderBlockCont({ fieldName: 'deals_info' }),
                title:  `${prefix}deals_info`,
            },
            {
                path:   ['tima', 'tima_manager', 'is_contest'],
                key:    'timaManagerIsContest',
                render: this.renderBlockCont({
                    isEnum:   true,
                    enumPath: 'tima',
                    enumName: 'TimaManagerIsContestTypeEnum'
                }),
                title:  `${prefix}is_contest`,
            },
            {
                path:   ['tima', 'tima_manager', 'description_ru'],
                key:    'timaManagerDescriptionRu',
                render: this.renderBlockContDescription({ fieldName: 'description_ru' }),
                xtitle: 'Описание',
            },
            {
                path:   ['tima', 'tima_manager', 'description'],
                key:    'timaManagerDescription',
                render: this.renderBlockContDescription({ fieldName: 'description' }),
                xtitle: 'Description',
            },
            {
                path:   ['tima', 'tima_manager', 'new_inv_notification'],
                key:    'timaManagerNewInvNotification',
                render: this.renderBlockContNotification({ fieldName: 'new_inv_notification' }),
                title:  `${prefix}new_inv_notification`,
            },
            {
                path:   ['tima', 'tima_manager', 'closing_inv_notification'],
                key:    'timaManagerClosingInvNotification',
                render: this.renderBlockContNotification({ fieldName: 'closing_inv_notification' }),
                title:  `${prefix}closing_inv_notification`,
            },
            {
                path:   ['tima', 'tima_manager', 'withdraw_notification'],
                key:    'timaManagerWithdrawNotification',
                render: this.renderBlockContNotification({ fieldName: 'withdraw_notification' }),
                title:  `${prefix}withdraw_notification`,
            },
            {
                path:   ['tima', 'tima_manager', 'from_agent_notification'],
                key:    'timaManagerFromAgentNotification',
                render: this.renderBlockContNotification({ fieldName: 'from_agent_notification' }),
                title:  `${prefix}from_agent_notification`,
            },
            {
                path:   ['tima', 'tima_manager', 'disable_from_agent_notification'],
                key:    'timaManagerDisableFromAgentNotification',
                render: this.renderBlockContNotification({ fieldName: 'disable_from_agent_notification' }),
                title:  `${prefix}disable_from_agent_notification`,
            },
            {
                path:   ['tima', 'tima_manager', 'close_inv_by_risk_notification'],
                key:    'timaManagerCloseInvByRiskNotification',
                render: this.renderBlockContNotification({ fieldName: 'close_inv_by_risk_notification' }),
                title:  `${prefix}close_inv_by_risk_notification`,
            },
        ].map(({ title: t, xtitle: x, ...item }) => ({ ...item, ...x && { title: x }, ...t && { title: translate(t) }, }));
    }

    renderBlockCont = ({ fieldName = '', isEnum = false, enumPath = null, enumName = null } = {}) => (value, { item, items }) => {
        const title = item?.options?.title;
        const key = item?.key;
        const defaultValue = '-';

        let targetValue;

        switch (true) {
            case item?.access?.('show'): {
                if (isEnum) {
                    const enums = this.props?.enums?.[enumPath]?.[enumName];
                    targetValue = enums?.[value] ?? defaultValue;
                } else {
                    targetValue = value ?? defaultValue;
                }
                break;
            }
            default: {
                targetValue = defaultValue;
                break;
            }
        }

        return this.renderBlockContFragment({ fieldName, key, title, targetValue, value, item, items });
    };

    renderBlockContLink = ({ fieldName = '', path = null } = {}) => (value, { item, items }) => {
        const id = items?.[`${fieldName}_id`];
        const title = item?.options?.title;
        const key = item?.key;
        const defaultValue = '-';

        let targetValue;
        let blockUrl;

        switch (true) {
            case id?.access?.('show') && item?.access?.('show'): {
                const valueIdFromState = this?.state?.magicEditValuePrev?.[fieldName];
                const isValueIdFromState = !isNaN(parseInt(valueIdFromState));

                targetValue = value ?? defaultValue;

                if (isValueIdFromState) {
                    blockUrl = `${path}/${ this.values(fieldName)[valueIdFromState] }` ?? null;
                } else {
                    blockUrl = !isNaN(parseInt(`${id?.valueOf}`)) ? `${path}/${ id?.valueOf }` : null;
                }
                break;
            }
            case item?.access?.('show'): {
                targetValue = value ?? defaultValue;
                blockUrl = null;
                break;
            }
            default: {
                targetValue = defaultValue;
                blockUrl = null;
                break;
            }
        }

        return this.renderBlockContFragment({ key, title, targetValue, fieldName, blockUrl, value, item, items });
    };

    renderFullNameCont = ({ fieldName = null, path = null } = {}) => (value, { item, items }) => {
        const id = items?.[`${fieldName}Id`];
        const name = items?.[`${fieldName}Name`];
        const surname = items?.[`${fieldName}Surname`];

        const title = item?.options?.title;
        const key = item?.key;
        const defaultValue = '-';

        let targetValue;
        let blockUrl;

        switch (true) {
            case id?.access('show') && name?.access?.('show') && surname?.access?.('show'): {
                targetValue = `${ surname?.valueOf } ${ name?.valueOf }`;
                blockUrl = `${path}/${ id?.valueOf }`;
                break;
            }
            case name?.access?.('show') && surname?.access?.('show'): {
                targetValue = `${ surname?.valueOf } ${ name?.valueOf }`;
                blockUrl = null;
                break;
            }
            default: {
                targetValue = defaultValue;
                blockUrl = null;
                break;
            }
        }

        return this.renderBlockContFragment({ key, title, targetValue, fieldName, blockUrl });
    };

    renderBlockContFragment = ({ key, title, targetValue, fieldName, blockUrl, value, item, items }) => {
        const targetOption = fieldName === 'client' ? '_self' : '_blank';

        let classToAdd = '';

        switch (fieldName) {
            case "inv_manual_confirm": {
                classToAdd = ' block--fixed-height-100';
                break;
            }
            default: {
                break;
            }
        }

        return (
            <div className="block-cont" key={`block-cont-${key}`}>
                <div className={`block${classToAdd}`}>
                    <div className="block-header full-cont">
                        <div className="full-cont__item">
                            {blockUrl ?
                                (
                                    <Link to={`${blockUrl}`} className='link-btn-cont' target={targetOption}>
                                        <span className='block__name link-btn'>
                                            {title}
                                        </span>
                                    </Link>
                                ) :
                                (
                                    <span className="block__name">{title}</span>
                                )
                            }

                        </div>
                        <div className="full-cont__item">
                            {/*<span className="block__info">{targetValue}</span>*/}
                            {this.renderBlockInfoFragment({ fieldName, targetValue, value, item, items })}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderBlockInfoFragment = ({ fieldName, targetValue, value, item, items }) => {
        const { translate } = this.props;

        switch (fieldName) {
            case 'tima_manager':
            case 'contact_email':
            case 'contact_phone':
            case 'agent_reward': {
                const valueFromStatePrev = this.state?.magicEditValuePrev?.[fieldName] ?? value;
                const valueFromStateCurrent = this.state?.magicEditValueCurrent?.[fieldName] ?? value;
                const isVisible = this.confirmCheck(fieldName);
                const isEditMode = this.editModeCheck(fieldName);

                if (item?.access?.('show', 'update')) {
                    return (
                        <div className='magic-edit-mode'>
                            <span className='block__info edit-btn'
                                  onClick={this.onEnterEditMode(fieldName)}
                            >
                                {valueFromStatePrev}
                            </span>

                            {isEditMode ?
                                (
                                    <div
                                        className="magic-confirm-wrapper magic-confirm-wrapper--large-width magic-confirm-wrapper--textarea">
                                        <i className="icon-bg icon-bg--success save-btn"
                                           onClick={
                                               (event) => {
                                                   this.setConfirm([fieldName, valueFromStateCurrent]);
                                               }
                                           }
                                        />

                                        <MagicConfirm
                                            onAccept={this.confirmAcceptHandler(fieldName, valueFromStatePrev)}
                                            onReject={this.confirmRejectHandler(fieldName, valueFromStatePrev)}
                                            title={translate(`magic_confirm_title`)}
                                            accept={translate(`magic_confirm_yes`)}
                                            reject={translate(`magic_confirm_no`)}
                                            isVisible={!!isVisible}
                                        />

                                        <MagicTextarea
                                            className={'magic-textarea magic-textarea--auto-height scroll'}
                                            value={valueFromStateCurrent.toString()}
                                            onChange={this.onChangeTextarea(fieldName)}
                                            selectionStart={this.state?.magicEditSelectionPos?.[fieldName]?.[0] ?? value?.toString()?.length ?? 0}
                                            selectionEnd={this.state?.magicEditSelectionPos?.[fieldName]?.[1] ?? value?.toString()?.length ?? 0}
                                        />

                                        <i className="gl-icon close-btn"
                                           onClick={this.onClickCloseConfirmBtn(fieldName, valueFromStatePrev)}
                                        />
                                    </div>
                                ) : ''}
                        </div>
                    );
                } else if (item?.access?.('show')) {
                    return (
                        <span className='block__info'>{valueFromStateCurrent}</span>
                    );
                }
                return '-';
            }
            case 'min_invest_risk': {
                const valueFromStatePrev = this.state?.magicEditValuePrev?.[fieldName] ?? value;
                const valueFromStateCurrent = this.state?.magicEditValueCurrent?.[fieldName] ?? value;
                const isVisible = this.confirmCheck(fieldName);
                const isEditMode = this.editModeCheck(fieldName);

                if (item?.access?.('show', 'update')) {
                    return (
                        <div className='magic-edit-mode'>
                            <span className='block__info edit-btn'
                                  onClick={this.onEnterEditMode(fieldName)}
                            >
                                {valueFromStatePrev}
                            </span>

                            {isEditMode ?
                                (
                                    <div
                                        className="magic-confirm-wrapper magic-confirm-wrapper--large-width magic-confirm-wrapper--textarea">
                                        <i className="icon-bg icon-bg--success save-btn"
                                           onClick={
                                               (event) => {
                                                   this.setConfirm([fieldName, valueFromStateCurrent]);
                                               }
                                           }
                                        />

                                        <MagicConfirm
                                            onAccept={this.confirmAcceptHandler(fieldName, valueFromStatePrev)}
                                            onReject={this.confirmRejectHandler(fieldName, valueFromStatePrev)}
                                            title={translate(`magic_confirm_title`)}
                                            accept={translate(`magic_confirm_yes`)}
                                            reject={translate(`magic_confirm_no`)}
                                            isVisible={!!isVisible}
                                        />

                                        <MagicTextarea
                                            className={'magic-textarea magic-textarea--auto-height scroll'}
                                            value={valueFromStateCurrent.toString()}
                                            onChange={this.onChangeTextarea(fieldName)}
                                            selectionStart={this.state?.magicEditSelectionPos?.[fieldName]?.[0] ?? value?.toString()?.length ?? 0}
                                            selectionEnd={this.state?.magicEditSelectionPos?.[fieldName]?.[1] ?? value?.toString()?.length ?? 0}
                                        />

                                        <i className="gl-icon close-btn"
                                           onClick={this.onClickCloseConfirmBtn(fieldName, valueFromStatePrev)}
                                        />
                                    </div>
                                ) : ''}
                        </div>
                    );
                } else if (item?.access?.('show')) {
                    return (
                        <span className='block__info'>{valueFromStateCurrent}</span>
                    );
                }
                return '-';
            }
            case 'status':
            case 'manager_rating':
            case 'trade_period':
            case 'deal_time':
            case 'deal_step':
            case 'deals_info': {
                const valueFromState = this.state?.magicEditValuePrev?.[fieldName] ?? this.values(fieldName).indexOf(value);
                const isVisible = this.confirmCheck(fieldName);
                const isEditMode = this.editModeCheck(fieldName);

                if (item?.access?.('show', 'update')) {
                    return (
                        <div className='magic-edit-mode'>
                            <span className='block__info edit-btn'
                                  onClick={this.onEnterEditMode(fieldName)}
                            >
                                {this.texts(fieldName)[valueFromState]}
                            </span>

                            {isEditMode ?
                                (
                                    <div
                                        className="magic-confirm-wrapper magic-confirm-wrapper--large-width magic-confirm-wrapper--position-right">
                                        <MagicConfirm
                                            onAccept={this.confirmAcceptHandler(fieldName, valueFromState)}
                                            onReject={this.confirmRejectHandler(fieldName, valueFromState)}
                                            title={translate(`magic_confirm_title`)}
                                            accept={translate(`magic_confirm_yes`)}
                                            reject={translate(`magic_confirm_no`)}
                                            isVisible={!!isVisible}
                                        />

                                        <MagicRadioSwitch
                                            className={`magic-radio-switch magic-radio-switch--max-height-200 scroll`}
                                            classNameText={`magic-switch__text magic-switch__text--padding-left`}
                                            direction={`column`}
                                            index={isVisible ? this.values(fieldName).indexOf(+isVisible[1]) : valueFromState}
                                            indexNone={true}
                                            // index={-1}
                                            reverse={false}
                                            texts={this.texts(fieldName)}
                                            values={this.values(fieldName)}
                                            onChange={this.onChange(fieldName)}
                                        />

                                        <i className="gl-icon close-btn"
                                           onClick={this.onClickCloseConfirmBtn(fieldName)}
                                        />
                                    </div>
                                ) : ''}
                        </div>
                    );
                } else if (item?.access?.('show')) {
                    return (
                        <span className='block__info'>{this.texts(fieldName)[valueFromState]}</span>
                    );
                }
                return '-';
            }
            case 'closed_investment_accept':
            case 'deny_investment':
            case 'is_withdraw': {
                const isVisible = this.confirmCheck(fieldName);
                const valueFromState = this.state?.magicEditValuePrev?.[fieldName] ?? this.values(fieldName).indexOf(value);

                if (item?.access?.('show', 'update')) {
                    return (
                        <div className="magic-confirm-wrapper">
                            <MagicConfirm
                                onAccept={this.confirmAcceptHandler(fieldName, valueFromState)}
                                onReject={this.confirmRejectHandler(fieldName, valueFromState)}
                                title={translate(`magic_confirm_title`)}
                                accept={translate(`magic_confirm_yes`)}
                                reject={translate(`magic_confirm_no`)}
                                isVisible={!!isVisible}
                            />

                            <MagicSwitch
                                classNameText={`magic-switch__text block__info edit-btn`}
                                index={isVisible ? this.values(fieldName).indexOf(+isVisible[1]) : valueFromState}
                                reverse={true}
                                values={this.values(fieldName)}
                                texts={this.texts(fieldName)}
                                onChange={this.onChange(fieldName)}
                                updateStateFromProps={true}
                            />
                        </div>
                    );
                } else if (item?.access?.('show')) {
                    return (
                        <span className='block__info'>{this.texts(fieldName)[valueFromState]}</span>
                    );
                }
                return '-';
            }
            case 'inv_manual_confirm': {
                const isVisible = this.confirmCheck(fieldName);
                const valueFromState = this.state?.magicEditValuePrev?.[fieldName] ?? value;

                if (item?.access?.('show', 'update')) {
                    return (
                        <div className="magic-confirm-wrapper">
                            <MagicConfirm
                                onAccept={this.confirmAcceptHandler(fieldName, valueFromState)}
                                onReject={this.confirmRejectHandler(fieldName, valueFromState)}
                                title={translate(`magic_confirm_title`)}
                                accept={translate(`magic_confirm_yes`)}
                                reject={translate(`magic_confirm_no`)}
                                isVisible={!!isVisible}
                            />

                            <MagicRadioSwitch
                                classNameText={`magic-switch__text block__info edit-btn`}
                                direction={`column`}
                                indexNone={false}
                                index={isVisible ? +isVisible[1] : valueFromState}
                                reverse={true}
                                texts={this.texts(fieldName)}
                                values={this.values(fieldName)}
                                onChange={this.onChange(fieldName)}
                                updateStateFromProps={true}
                            />
                        </div>
                    );
                } else if (item?.access?.('show')) {
                    return (
                        <span className='block__info'>{this.texts(fieldName)[valueFromState]}</span>
                    );
                }
                return '-';
            }
            default: {
                return (
                    <span className="block__info">{targetValue}</span>
                );
            }
        }
    };

    renderTitle (title) {
        return (
            <div className='title-cont'>
                <h3 className='title'>{title}</h3>
            </div>
        );
    }

    renderBlockContDescription = ({ fieldName = '' }) => (value, { item, items }) => {
        const title = item?.options?.title;

        if (item?.access?.('show', 'update')) {
            return (
                <div className='block-cont'>
                    <div className='block block--fixed-height-171'>
                        <div className='block-header'>
                            <p className='block__name edit-btn edit-btn--icon-right'
                               onClick={this.onEnterEditMode(fieldName)}
                            >
                                {title}
                            </p>
                        </div>

                        <div className='block-content'>
                            {this.renderMagicTextarea({ fieldName, value, item, items })}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className='block-cont'>
                    <div className='block block--fixed-height-171'>
                        <div className='block-header'>
                            <p className='block__name'>
                                {title}
                            </p>
                        </div>

                        <div className='block-content'>
                            {this.renderMagicTextarea({ fieldName, value, item, items })}
                        </div>
                    </div>
                </div>
            );
        }
    };

    renderMagicTextarea ({ fieldName, value, item, items }) {
        const { translate, } = this.props;
        const defaultValue = '-';

        switch (fieldName) {
            case 'description_ru':
            case 'description': {
                const valueFromStatePrev = this.state?.magicEditValuePrev?.[fieldName] ?? value;
                const valueFromStateCurrent = this.state?.magicEditValueCurrent?.[fieldName] ?? value;
                const isVisible = this.confirmCheck(fieldName);
                const isEditMode = this.editModeCheck(fieldName);

                if (item?.access?.('show', 'update')) {
                    return (
                        <div className='magic-edit-mode'>
                            <p className='block__info block__info--fixed-height-90 scroll'
                               onClick={this.onEnterEditMode(fieldName)}
                            >
                                {valueFromStatePrev}
                            </p>

                            {isEditMode ?
                                (
                                    <div
                                        className="magic-confirm-wrapper magic-confirm-wrapper--large-width magic-confirm-wrapper--textarea">
                                        <i className="icon-bg icon-bg--success save-btn"
                                           onClick={
                                               (event) => {
                                                   this.setConfirm([fieldName, valueFromStateCurrent]);
                                               }
                                           }
                                        />

                                        <MagicConfirm
                                            onAccept={this.confirmAcceptHandler(fieldName, valueFromStateCurrent)}
                                            onReject={this.confirmRejectHandler(fieldName, valueFromStateCurrent)}
                                            title={translate(`magic_confirm_title`)}
                                            accept={translate(`magic_confirm_yes`)}
                                            reject={translate(`magic_confirm_no`)}
                                            isVisible={!!isVisible}
                                        />

                                        <MagicTextarea
                                            className={'magic-textarea scroll'}
                                            value={valueFromStateCurrent}
                                            onChange={this.onChangeTextarea(fieldName)}
                                            selectionStart={this.state?.magicEditSelectionPos?.[fieldName]?.[0] ?? value?.toString()?.length ?? 0}
                                            selectionEnd={this.state?.magicEditSelectionPos?.[fieldName]?.[1] ?? value?.toString()?.length ?? 0}
                                        />

                                        <i className="gl-icon close-btn"
                                           onClick={this.onClickCloseConfirmBtn(fieldName)}
                                        />
                                    </div>
                                ) : ''}
                        </div>
                    );
                } else if (item?.access?.('show')) {
                    return (
                        <div className='magic-edit-mode'>
                            <p className='block__info block__info--fixed-height-90 scroll'>
                                {valueFromStateCurrent}
                            </p>
                        </div>
                    );
                }
                return defaultValue;
            }
            default: {
                return defaultValue;
            }

        }
    }

    renderInformationCont (fieldsArray, castData) {
        const { translate, params, query } = this.props;

        return (
            <div className='information-cont'>
                {
                    fieldsArray.map((item, i) => {
                        return this.renderInformationContItem(item, i, castData);
                    })
                }

                <div className='information-cont__item information-cont__item--description' key={`information-cont-3`}>
                    <div className='blocks-wrapper'>
                        {this.renderNotificationsBlocksCont(castData)}

                        {castData?.['timaManagerDescriptionRu'] ?? false}
                        {castData?.['timaManagerDescription'] ?? false}
                    </div>
                </div>
            </div>
        );
    }

    renderNotificationsBlocksCont = (castData) => {
        const { translate, notificationFields } = this.props;

        return (
            <React.Fragment>
                <div className='block-cont'>
                    <div className='block'>
                        <div className='block-header'>
                            <p className='block__name'>
                                {translate(`tima_strategies_table_notifications`)}
                            </p>
                        </div>
                    </div>
                </div>

                <div className='block-cont'>
                    <div className='block block--fixed-height-246'>
                        <div className='block-content' style={{ paddingTop: '0' }}>
                            {
                                notificationFields.map((item, i) => {
                                    return (
                                        <React.Fragment key={`key-${i}`}>
                                            {castData?.[item] ?? false}
                                        </React.Fragment>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    renderBlockContNotification = ({ fieldName = '' }) => (value, { item, items }) => {
        const title = item?.options?.title;

        return (
            <div className='block-content__item' key={`block-content__item-${fieldName}`}>
                <p className='block__name'>
                    {title}
                </p>

                <div className='checkbox-conts'>
                    {this.renderNotificationsBlockCheckboxes({ fieldName, value, item, items })}
                </div>
            </div>
        );
    };

    renderNotificationsBlockCheckboxes ({ fieldName, value, item, items }) {
        const { translate, } = this.props;
        const defaultValue = '-';

        switch (fieldName) {
            case 'new_inv_notification':
            case 'closing_inv_notification':
            case 'withdraw_notification':
            case 'from_agent_notification':
            case 'disable_from_agent_notification':
            case 'close_inv_by_risk_notification': {
                const isVisible = this.confirmCheck(fieldName);
                const valueFromState = this.state.magicEditValuePrev[fieldName] ?? this.toEmailSms(value);

                if (item?.access?.('show', 'update')) {
                    return (
                        <div className="magic-confirm-wrapper">
                            <MagicConfirm
                                onAccept={this.confirmAcceptHandler(fieldName, valueFromState?.value)}
                                onReject={this.confirmRejectHandler(fieldName, valueFromState?.value)}
                                title={translate(`magic_confirm_title`)}
                                accept={translate(`magic_confirm_yes`)}
                                reject={translate(`magic_confirm_no`)}
                                isVisible={!!isVisible}
                            />

                            <MagicSwitch
                                className={`magic-switch magic-switch--inline edit-btn`}
                                classNameText={`magic-switch__text block__info`}
                                index={isVisible ? +isVisible[1].email : valueFromState.email}
                                key={`magic-switch-${fieldName}-1`}
                                reverse={true}
                                values={[0, 1]}
                                text='email'
                                onChange={this.onChange(fieldName, 'email', valueFromState)}
                                updateStateFromProps={true}
                            />
                            {/*REMOVE SMS NOTIFICATIONS IN CURRENT VERSION OF CRM*/}
                            {/*<MagicSwitch
                                className={`magic-switch magic-switch--inline`}
                                classNameText={`magic-switch__text block__info`}
                                index={isVisible ? +isVisible[1].sms : valueFromState.sms}
                                key={`magic-switch-${fieldName}-2`}
                                reverse={true}
                                values={[0, 1]}
                                text='sms'
                                onChange={this.onChange(fieldName, 'sms', valueFromState)}
                                updateStateFromProps={true}
                            />*/}
                        </div>
                    );
                } else if (item?.access?.('show')) {
                    return (
                        <div className="magic-confirm-wrapper">
                            <MagicSwitch
                                className={`magic-switch magic-switch--inline`}
                                classNameText={`magic-switch__text block__info`}
                                index={valueFromState.email}
                                key={`magic-switch-${fieldName}-1`}
                                reverse={true}
                                values={[0, 1]}
                                text='email'
                                onChange={this.onChange(fieldName, 'email', valueFromState, true)}
                                updateStateFromProps={true}
                            />
                            {/*REMOVE SMS NOTIFICATIONS IN CURRENT VERSION OF CRM*/}
                            {/*<MagicSwitch
                                className={`magic-switch magic-switch--inline`}
                                classNameText={`magic-switch__text block__info`}
                                index={valueFromState.sms}
                                key={`magic-switch-${fieldName}-2`}
                                reverse={true}
                                values={[0, 1]}
                                text='sms'
                                onChange={this.onChange(fieldName, 'sms', valueFromState, true)}
                                updateStateFromProps={true}
                            />*/}
                        </div>
                    );
                }
                return defaultValue;
            }
            default:
                throw new Error(`Error renderNotificationsBlockCheckboxes( fieldName: ${fieldName} }`);
        }
    }

    renderInformationContItem (fieldsPart, i, castData) {
        let classToAdd = '';

        switch (i) {
            case 0: {
                classToAdd = 'information-cont__item--ratio-55to45';
                break;
            }
            case 1: {
                classToAdd = 'information-cont__item--ratio-40to60';
                break;
            }
            default: {
                break;
            }
        }

        return (
            <div className={`information-cont__item ${classToAdd}`} key={`information-cont__item-${i}`}>
                <div className='blocks-wrapper'>
                    {
                        fieldsPart.map((item, i) => {
                            return (
                                <React.Fragment key={`key-${i}`}>
                                    {castData?.[item] ?? false}
                                </React.Fragment>
                            );
                        })
                    }
                </div>
            </div>
        );
    }

    toEmailSms = (value) => {
        switch (value) {
            case 3:
                return { email: 1, sms: 1, value };
            case 2:
                return { email: 0, sms: 1, value };
            case 1:
                return { email: 1, sms: 0, value };
            default:
            case 0:
                return { email: 0, sms: 0, value };
        }
    };

    emailSmsToValue = (obj) => {
        return 2 * obj.sms + obj.email;
    };

    getEnumObjByFieldName = (fieldName) => {
        const { enums = {}, customEnums = {} } = this.props;
        const { tima = {} } = enums;
        let enumObj = {};

        try {
            switch (fieldName) {
                case 'status': {
                    enumObj = tima?.TimaManagerStatusEnum;
                    break;
                }
                case 'manager_rating': {
                    enumObj = tima?.TimaManagerRatingVisibilityEnum;
                    break;
                }
                case 'trade_period': {
                    enumObj = tima?.TimaManagerTradePeriodEnum;
                    break;
                }
                case 'deal_time': {
                    enumObj = customEnums.TimaManagerDealTimeEnum;
                    break;
                }
                case 'deal_step': {
                    enumObj = tima?.TimaManagerDealStepEnum;
                    break;
                }
                case 'deals_info': {
                    enumObj = tima?.TimaManagerDealsInfoVisibilityEnum;
                    break;
                }

                case 'closed_investment_accept': {
                    enumObj = tima?.TimaManagerClosedInvestmentAcceptanceEnum;
                    break;
                }
                case 'deny_investment': {
                    enumObj = tima?.TimaManagerClosedInvestmentAcceptanceEnum;
                    // enumObj = tima?.TimaManagerDenyInvestmentEnum;
                    break;
                }
                case 'inv_manual_confirm': {
                    enumObj = tima?.TimaManagerInvestmentConfirmTypeEnum;
                    break;
                }
                case 'is_withdraw': {
                    enumObj = tima?.TimaManagerIsWithdrawEnum;
                    break;
                }
                case 'is_contest': {
                    enumObj = tima?.TimaManagerIsContestTypeEnum;
                    break;
                }

                case 'new_inv_notification': {
                    enumObj = tima?.TimaManagerNewInvestmentNotificationEnum;
                    break;
                }
                case 'closing_inv_notification': {
                    enumObj = tima?.TimaManagerClosingInvestmentNotificationEnum;
                    break;
                }
                case 'withdraw_notification': {
                    enumObj = tima?.TimaManagerWithdrawNotificationEnum;
                    break;
                }
                case 'from_agent_notification': {
                    enumObj = tima?.TimaManagerFromAgentNotificationEnum;
                    break;
                }
                case 'disable_from_agent_notification': {
                    enumObj = tima?.TimaManagerDisableFromAgentNotificationEnum;
                    break;
                }
                case 'close_inv_by_risk_notification': {
                    enumObj = tima?.TimaManagerCloseInvestmentByRiskNotificationEnum;
                    break;
                }

                default: {
                    break;
                }
            }
        } catch (error) {
            throw new Error(`Error getEnumObjByFieldName { fieldName: ${ fieldName } }`);
        }

        return enumObj;
    };

    texts = (fieldName) => {
        const enumAsObjectToValues = (_) => [...Object.entries(_).map(([key, value]) => value)];

        try {
            switch (fieldName) {
                case 'is_withdraw': {
                    return enumAsObjectToValues(this.getEnumObjByFieldName(fieldName)); // invert value - fix BD bug - 0 --> active, 1 - inactive
                }
                default: {
                    return enumAsObjectToValues(this.getEnumObjByFieldName(fieldName));
                }
            }
        } catch (error) {
            throw new Error(`Error texts { fieldName: ${ fieldName } }`);
        }
    };

    values = (fieldName) => {
        const enumAsObjectToKeys = (_, isNumber) => [...Object.entries(_).map(([key]) => isNumber ? +key : key)];

        try {
            switch (fieldName) {
                case 'status':
                case 'manager_rating':
                case 'trade_period':
                case 'deal_time':
                case 'deal_step':
                case 'deals_info':
                case 'deny_investment':
                case 'closed_investment_accept':
                case 'is_withdraw': {
                    return enumAsObjectToKeys(this.getEnumObjByFieldName(fieldName), true);
                }
                default: {
                    return enumAsObjectToKeys(this.getEnumObjByFieldName(fieldName));
                }
            }
        } catch (error) {
            throw new Error(`Error values { fieldName: ${ fieldName } }`);
        }
    };

    isTextareaFieldCheck = (fieldName) => {
        const textareaArray = [
            'description',
            'description_ru',
            'tima_manager',
            'contact_email',
            'contact_phone',
            'agent_reward',
            'min_invest_risk',
        ];

        return textareaArray.indexOf(fieldName) !== -1;
    };

    returnToPrevStateTextarea = async (fieldName, valueFromStatePrev) => {
        const magicEditValuePrev = this.state?.magicEditValuePrev?.[fieldName] ?? valueFromStatePrev;
        const valuePrevLength = magicEditValuePrev?.toString()?.length ?? 0;
        const state = {
            magicEditValueCurrent: {
                ...this.state.magicEditValueCurrent,
                [fieldName]: magicEditValuePrev,
            },

            magicEditSelectionPos: {
                ...this.state.magicEditSelectionPos,
                [fieldName]: [valuePrevLength, valuePrevLength],
            },
        };
        await this.save(state);
    };

    onClickCloseConfirmBtn = (fieldName, valueFromStatePrev) => async (event) => {
        await this.setEditMode(false);
        await this.setConfirm(false);

        if (this.isTextareaFieldCheck(fieldName)) {
            await this.returnToPrevStateTextarea(fieldName, valueFromStatePrev);
        }
    };

    onChange = (fieldName, notification, notificationValuePrev, isDisabled) => async ({ value, scrollPosY }) => {
        const isEditMode = this.editModeCheck(fieldName);

        if (isDisabled) {
            await this.setConfirm(false);
            return;
        }

        if (!isEditMode) {
            await this.setEditMode(false);
        }

        const getNotificationValue = (fieldName, notification, valuePrev) => {
            let notificationValue = {};

            switch (notification) {
                case 'email':
                    notificationValue = { ...valuePrev, email: value, };
                    notificationValue.value = this.emailSmsToValue({
                        sms:   notificationValue.sms,
                        email: notificationValue.email,
                    });
                    break;
                case 'sms':
                    notificationValue = { ...valuePrev, sms: value, };
                    notificationValue.value = this.emailSmsToValue({
                        sms:   notificationValue.sms,
                        email: notificationValue.email,
                    });
                    break;
                default:
                    notificationValue = { ...valuePrev };
                    break;
            }

            return notificationValue;
        };

        if (notificationValuePrev instanceof Object) {
            const isVisible = this.confirmCheck(fieldName);
            let notificationValue = {};

            if (isVisible) {
                notificationValue = getNotificationValue(fieldName, notification, isVisible[1]);
            } else {
                notificationValue = getNotificationValue(fieldName, notification, notificationValuePrev);
            }

            await this.setConfirm([fieldName, notificationValue]);

        } else {
            await this.setConfirm([fieldName, value]);
        }
    };

    onChangeTextarea = (fieldName) => async ({ value, selectionStart, selectionEnd }) => {
        const state = {
            magicEditValueCurrent: {
                ...this.state.magicEditValueCurrent,
                [fieldName]: value,
            },
            magicEditSelectionPos: {
                ...this.state.magicEditSelectionPos,
                [fieldName]: [selectionStart, selectionEnd],
            },
        };
        await this.save(state);
    };

    onEnterEditMode = (fieldName) => async () => {
        await this.setEditMode([fieldName]);
        await this.setConfirm(false);
    };

    confirmAcceptHandler = (fieldName, valuePrev) => async (event) => {
        const confirm = this.confirmCheck(fieldName);
        if (!confirm) {
            await this.setConfirm(false);
            return;
        }

        const value = confirm[1] instanceof Object ? confirm[1].value : confirm[1];
        const timaManagerId = this.state?.strategy.data?.tima?.tima_manager?.id;
        const accountId = this.state?.strategy.data?.core?.account?.id;

        try {
            const fieldMap = fieldName === "tima_manager" ? 'name' : fieldName;
            const data = { account_id: accountId, [fieldMap]: value };
            const { response } = await timaService.updateStrategy(timaManagerId, data);
            if (!response) {
                throw new Error(`Can*t update the fieldName: '${fieldName}', please try again later!`);
            }

            let newValue;

            switch (fieldName) {
                case 'description':
                case 'description_ru':
                case 'tima_manager':
                case 'contact_email':
                case 'contact_phone':
                case 'agent_reward': {
                    newValue = value;
                    break;
                }

                case 'new_inv_notification':
                case 'closing_inv_notification':
                case 'withdraw_notification':
                case 'from_agent_notification':
                case 'disable_from_agent_notification':
                case 'close_inv_by_risk_notification': {
                    newValue = this.toEmailSms(+value);
                    break;
                }

                case 'status':
                case 'manager_rating':
                case 'trade_period':
                case 'deal_time':
                case 'deal_step':
                case 'deals_info': {
                    newValue = this.values(fieldName).indexOf(value);
                    break;
                }

                default: {
                    newValue = +value;
                    break;
                }
            }

            const state = {
                magicEditValuePrev: {
                    ...this.state.magicEditValuePrev,
                    [fieldName]: newValue,
                },
            };
            await this.save(state);

        } catch (error) {
            NotificationService.error({
                title:   "error",
                message: error.message,
                remove:  false,
            });

            if (this.isTextareaFieldCheck(fieldName)) {
                await this.returnToPrevStateTextarea(fieldName, this.state?.magicEditValuePrev?.[fieldName] ?? valuePrev);
            }
        }
        await this.setConfirm(false);
        await this.setEditMode(false);
    };

    confirmRejectHandler = (fieldName, valuePrev) => async (event) => {
        await this.setConfirm(false);
        await this.setEditMode(false);
    };

    confirmCheck = (fieldName) => {
        const { confirm } = this.state;
        return confirm instanceof Array && confirm[0] === fieldName ? confirm : false;
    };

    editModeCheck = (fieldName) => {
        const { editMode } = this.state;
        return editMode instanceof Array && editMode[0] === fieldName ? editMode : false;
    };

    setConfirm = async (confirm) => {
        await this.save((state) => ({ ...state, confirm }));
    };

    setEditMode = async (editMode) => {
        await this.save((state) => ({ ...state, editMode }));
    };

    render () {
        const { fieldsForChecking, translate, enums, scale } = this.props;
        const { strategy: { data }, loaded } = this.state;
        const castData = PermissionService.cast(data, this.OneTimaStrategyConfig);

        return (
            <Loader
                loaded={loaded && !!enums}
                loading={(<Preloader scale={scale}/>)}
                translate={translate}
            >
                <div className='glalex-styles tima'>
                    {this.renderTitle(this.state?.magicEditValuePrev?.['tima_manager'] ?? data?.tima?.tima_manager?.name)}

                    {this.renderInformationCont(fieldsForChecking, castData)}
                </div>
            </Loader>
        );
    }
}

// start of custom enum set
const TimaManagerDealTimeEnum = {};

for (let key = 0; key < 24; key++) {
    TimaManagerDealTimeEnum[key] = `${key}`;
}
// end of custom enum set

OneTimaStrategy.defaultProps = {
    fieldsForChecking:  [
        [
            'timaManagerName',
            'timaManagerStatus',
            'currency',
            'timaManagerCapital',
            'accountNumber',
            'platform',
            'client',
            'timaManagerShare',
            'timaManagerRating',
            'timaManagerContactEmail',
            'timaManagerContactPhone',
            'timaManagerMinInvestmentRisk',
            // 'responsible',
        ],
        [
            'timaManagerTradePeriod',
            'timaManagerDealTime',
            'timaManagerDealStep',
            // 'timaManagerClosedInvestmentAccept',
            'timaManagerDenyInvestment',
            'timaManagerInvManualConfirm',
            'timaManagerIsWithdraw',
            'timaManagerUpdatedAt',
            'timaManagerCreatedAt',
            'timaManagerAgentReward',
            'timaManagerDealsInfo',
            'timaManagerIsContest',
        ],
    ],
    notificationFields: [
        'timaManagerNewInvNotification',
        'timaManagerClosingInvNotification',
        'timaManagerWithdrawNotification',
        'timaManagerFromAgentNotification',
        'timaManagerDisableFromAgentNotification',
        'timaManagerCloseInvByRiskNotification',
    ],
    customEnums:        {
        TimaManagerDealTimeEnum,
    },

    /*editableFields: [
        'closed_investment_accept',
        'inv_manual_confirm',
        'is_withdraw',

        'status',
        'manager_rating',
        'trade_period',
        'deal_time',
        'deal_step',
        'deals_info',

        'tima_account',
        'contact_email',
        'contact_phone',
        'agent_reward',

        'description',
        'description_ru',

        'new_inv_notification',
        'closing_inv_notification',
        'withdraw_notification',
        'from_agent_notification',
        'disable_from_agent_notification',
        'close_inv_by_risk_notification',
    ],
    editableTextareaFields: [
        'tima_account',
        'contact_email',
        'contact_phone',
        'agent_reward',
        'description',
        'description_ru'
    ],*/
};

export default withLocalize(OneTimaStrategy);
