import React, { Component, } from 'react';
import PropTypes from "prop-types";
import Preloader from "../../../../../components/LoadingHOC/Preloader";
class FileInput extends Component {
	constructor (props) {
		super(props);

		this.state = {
			imgSrc: null,
			fileData: null,
			loaded: true,
		};
	}

    static propTypes = {
    	accept: PropTypes.string,
    	buttonText: PropTypes.string,
    	className: PropTypes.string,
    	disabled: PropTypes.bool,
    	file: PropTypes.object,
    	imgData: PropTypes.object,
    	name: PropTypes.string,
    	onChange: PropTypes.func,
    	onImgClick: PropTypes.func,
    };

    get fileIsImg () {
    	const { accept, } = this.props;

    	return accept.includes('image');
    }
    get showUploadedImg () {
    	const { imgSrc, } = this.state;

    	return imgSrc && (this.fileIsImg || this.showImgFromURL);
    }

    get inputIsDisabled () {
    	const { loaded, } = this.state;
    	const { disabled, } = this.props;

    	return !loaded || disabled;
    }

    get setFileFromProps () {
    	return this.props.file;
    }
    get showImgFromURL () {
    	return this.props.imgData.source;
    }

    async componentDidMount () {
    	const { file, imgData, } = this.props;

    	if (this.setFileFromProps) {
    		this.updateFile(file);
    	}
    	if (this.showImgFromURL) {
    		const { source, updated_at, } = imgData;
    		const name = source?.split('/cdn.fx-people.com/').at(-1).split('?').at(0);

    		this.save({ fileData: { name, date: updated_at, }, imgSrc: source, });
    	}
    }

	save = async state => new Promise(next => this.setState(state, next));

	toggleLoaded = loaded => this.save({ loaded, });

	clearCurrentFile = () => this.save({ imgSrc: null, fileData: null, })

	getFileTypeFormmated = (fileType) => {
		try {
			const fileExtention = fileType?.split('/')[1];

			return fileExtention.includes('+') ? fileExtention.split('+')[0] : fileExtention;
		} catch (error) {
			return '';
		}
	}

	getImgFromUrlExtension = () => {
		return this.state.fileData.name.split('.').at(-1);
	}

	getImgSrc = async (file) => {
		const reader = new FileReader();

		reader.onload = async (e) => {
			await this.save({ imgSrc: e.target.result, });
			await this.toggleLoaded(true);
		};

		return reader.readAsDataURL(file);
	}

	getImgData = async (file) => {
		return Promise.all([
			await this.getImgSrc(file),
			await this.save({ fileData: file, }),
		]);
	}
	updateFile = async (file) => {
		try {
			await this.toggleLoaded(false);
			switch (true) {
				case this.fileIsImg: {
					await this.getImgData(file);
					break;
				} default: {
					await this.save({ fileData: file, });
					await this.toggleLoaded(true);
				}
			}
		} catch (error) {
			await this.toggleLoaded(true);
			console.log(error);
			error.showErrorNotification?.();
		}
	}
	onFileUpload = async (input) => {
		await this.clearCurrentFile();

		if (!input.files || !input.files[0]) {
			throw new Error('Upload file error');
		}

		await this.updateFile(input.files[0]);
	}
	onChange = async (e) => {
		this.props.onChange(e);
		this.onFileUpload(e.target);
	}
	formatDate (date) {
		//Format a date as YYYY-MM-DD hh:mm:ss
		function padTo2Digits (num) {
			//Adds a leading zero if the month and day values only contain a single digit (are less than 10).
			return num.toString().padStart(2, '0');
		}

		return (
			`${ [
				date.getFullYear(),
				padTo2Digits(date.getMonth() + 1),
				padTo2Digits(date.getDate()),
			].join('-')
			} ${
				[
					padTo2Digits(date.getHours()),
					padTo2Digits(date.getMinutes()),
					padTo2Digits(date.getSeconds()),
				].join(':') }`
		);
	}

	renderImgPreview = () => {
		const { imgSrc, fileData, } = this.state;
		const { onImgClick, } = this.props;
		const fileType = this.showImgFromURL ? this.getImgFromUrlExtension() : this.getFileTypeFormmated(fileData?.type);
		const date = this.showImgFromURL && fileData.date ? fileData.date : this.formatDate(new Date());

		return (
			<div className={ 'file-input__preview' }>
				<div className={ `file-input__img ${ onImgClick && 'file-input__img_zoomable' }` } style={ { backgroundImage: `url(${ imgSrc })`, } } onClick={ () => onImgClick(imgSrc) } />
				<div className={ 'file-input__info-wrapper' }>
					<div className={ `file-input__icon` }>
						<p className={ 'file-input__file-type' }>{fileType}</p>
					</div>
					<div className={ 'file-input__information' }>
						<p className={ 'file-input__file-name' }>{fileData.name}</p>
						<p className={ 'file-input__uploaded-at' }>{date}</p>
					</div>
				</div>
			</div>
		);
	}
	render () {
    	const {
			className, buttonText, accept, name,
		} = this.props;
		const { loaded, } = this.state;
		const { inputIsDisabled, onChange, } = this;

		return (
			<div className={ `file-input ${ className } ${ inputIsDisabled && 'disabled' }` }>
				<label className={ 'file-input__label' }>
					<input accept={ accept } className={ 'file-input__input' } disabled={ inputIsDisabled } name={ name } type={ "file" } onChange={ e => onChange(e) } />
					<span className={ 'file-input__button-text' }>{buttonText}</span>
				</label>
				{
					!loaded &&
						<Preloader />
				}

				{this.showUploadedImg && this.renderImgPreview()}
			</div>
		);
	}

	static defaultProps = {
		className: '',
		buttonText: 'Upload File',
		accept: 'image/*',
		file: null,
		imgData: null,
		disabled: false,
	}
}

export default FileInput;