import React from 'react';
import { Link, } from 'react-router-dom';
import { MagicButton, } from '../../../../components/Magic';
import PropTypes from 'prop-types';
export class PopupControlButtons extends React.Component {

   static propTypes = {
   	translate: PropTypes.func.isRequired,
   	onAccept: PropTypes.func.isRequired,
   	onCancel: PropTypes.func.isRequired,
   	contestId: PropTypes.number,
   	disabled: PropTypes.bool,
   	isEditable: PropTypes.bool,
   };

   static defaultProps = {
   	contestId: 0,
   	disabled: false,
   	isEditable: false,
   };

   render () {
   	const { contestId, } = this.props;
   	const optionsAccept = {
   		className: "magic-button__item magic-button__item_contest_popup-save",
   		value: "",
   		disabled: this.props.disabled,
   		onClick: this.props.onAccept,
   		children: this.props.translate("contest_popup_btn_save"),
   	};
   	const optionsCancel = {
   		to: `/contests${ contestId ? `/contest/${ contestId }` : `` }`,
   		className: "magic-button__item magic-button__item_contest_popup-cancel",
   		value: "",
   		onClick: this.props.onCancel,
   		children: this.props.translate("contest_popup_btn_cancel"),
   	};

   	return (
	<div className="popup__buttons">
	<MagicButton { ...optionsAccept } />
	<Link { ...optionsCancel } />
   		</div>
   	);
   }
};