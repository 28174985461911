import React, {Component, createRef} from 'react';
import { withLocalize, } from 'react-localize-redux';
import {
	editCountryPayoutRateList, getPartnersCurrency,
} from "../../../../../../../../services/PartnersRequestService";
import { Input, Select } from "../../../../../../components";
import {
	findOptionByValue,
	setErrorClass
} from "../../../../../../utils/helpers";
import { onValidPercantages } from '../../helpers/validation-helpers/onValidHelpers'
import Loader from "../../../../../../../tima/components/Loader";
import Preloader from "../../../../../../../../components/LoadingHOC/Preloader";
import { tradingServers } from "../../consts/consts";
class FtdCpaSingleBlock extends Component {
	constructor(props) {
		super(props);
		this.myP = createRef();
		this.ref = createRef();
		this.state = {
			formData: null,
			loaded: true,
			symbolGroupOptions: [],
			payoutRateSumFrom: [],
			confirmedConditions: [],
			payoutRateListId: '',
			serverCurrency: null,
			elementRefs: [],
		}
	}
	save = async (state) => new Promise(next => this.setState(state, next));

	get currencyIsDollar () {
		return +this.state.serverCurrency === 253;
	}

	get dealsOptions(){
		return Object.entries(this.props.enums.data.partners.PayoutRatesRevshareDealsEnum).map((entry) => {
			const [key, value] = entry;

			return {label: value, value: key}
		})
	}

	componentDidMount = async () => {
		const { symbolOptions, payoutRateSumFrom, confirmedConditions, payoutRateListId } = this.props;
		const serverCurrency = await getPartnersCurrency();

		await this.save(state => {
			return {
				symbolGroupOptions: symbolOptions,
				payoutRateSumFrom: payoutRateSumFrom,
				confirmedConditions: confirmedConditions,
				payoutRateListId: payoutRateListId,
				serverCurrency,
			}
		})
		if(+payoutRateListId && +payoutRateListId !== 0){
			this.setPayoutRatesRanges(payoutRateListId)
			await this.save({loaded: false})
		}

	}

	checkIsDisabled = (index) => {
		const {firstDepositCondInDataBase, statusIsActive, statusIsClosed, isEditMode, isViewMode}= this.props;
		const { formData } = this.state;
		if(!isEditMode) return false
		return (index + 1 <= firstDepositCondInDataBase.length && statusIsActive) || statusIsClosed  || isViewMode || !formData
	}

	 componentDidUpdate = async (prevProps, prevState) => {
		const {  payoutRateListId, confirmedConditions } = this.props;
		const tState = payoutRateListId;
		const pState = prevState.payoutRateListId;
		const thisConditions = confirmedConditions;
		const prevConditions = prevState.confirmedConditions;
		if (thisConditions !== prevConditions) {
			await this.save(() => {
				return {
					confirmedConditions
				}
			});
			await this.adjustSymbolGroupOptions();
		}
		if (tState !== pState) {
			try {
				const { data } = await editCountryPayoutRateList(payoutRateListId);
				await this.save(state => {
					return {
						payoutRateListId: payoutRateListId,
						formData: {
							...state.formData,
							payoutRangesSumFrom: data,
						},
						payoutRatesRanges: data.partners?.payout_rates_ranges.filter(group => Object.values(group).every(value => value)),
					};
				});
			} catch (e) {
				console.log(e)
			} finally {
			await this.save({loaded: true})
			}

		   }

	}
	updateItemRefs = () => {
		this.setState({
			elementRefs: this.props.confirmedConditions?.map(() => createRef()),
		});
	};
	removeItem = (currentIndex) => {

		if (currentIndex > 3) {

			const previousIndex = currentIndex - 1;
			const parentElement = this.state.elementRefs[previousIndex].current.scrollIntoView({
				behavior: "smooth",
				block: "end",
			});
		} else {
			this.ref.current?.scrollIntoView({
				behavior: "smooth",
				block: "end",
			})
		}
	};

	async setPayoutRatesRanges(id){
		const { data } = await editCountryPayoutRateList(id);
		const payoutRatesRanges = data.partners.payout_rates_ranges.filter(group => Object.values(group).every(value => value));
		await this.save(state => {
			return {
				formData: {
					...state.formData,
					payoutRangesSumFrom: data,
				},
				loaded: true,
				payoutRatesRanges,
				payoutRateListId: id,
			};
		});
	}

	adjustSymbolGroupOptions = async () => {
		const symbolGroupOptions = this.props.symbolOptions;
		const pickedOptions = this.state.confirmedConditions?.map(condition => condition[0].symbol_group_id);
		const newSymbolGroupOptions = symbolGroupOptions.filter(option => !pickedOptions.includes(option.value));
		await this.save(state => {
			return {
				symbolGroupOptions: newSymbolGroupOptions,
			};
		});
	}

	handleOnChange = (index, subIndex) => async (event) => {
		const { setGroupField, setGroupRateIdVolume, clearErrorOnChange } = this.props;
		const { value } = event.target;
		const selectValue = value?.value;
		const fieldName = event.target.name.includes('symbol_group') ? 'symbol_group' : event.target.name.includes('volume') ? 'volume' : 'deals';
		await clearErrorOnChange(event, index);

		try {
			switch (fieldName) {
				case 'symbol_group': {
					await setGroupField({ symbol_group_id: selectValue }, index);
					break;
				}
				case 'volume': {
					await setGroupRateIdVolume(`${value}`, index, subIndex)
					break;
				}
				case 'deals': {
					await setGroupField({ deals: selectValue }, index);
					break;
				}
				default: {
					break;
				}
			}
		} catch (e) {
			throw new Error(e);
		}
	};

	renderFtdInputs = (condition, index) => {
		const { translate, errors,} = this.props;

		const checkIsDisabled = this.checkIsDisabled;
		const isDisabled = (index) => checkIsDisabled(index) ;
		return condition.map((group, subIndex) => {
			return (
				<div key={subIndex}>
					<Input
					name={`volume_${index}_${subIndex}`}
					disabled={isDisabled(index)}
					wrapperClassName={`${setErrorClass(errors, `volume_${index}_${subIndex}`)} payout-rate-list__payout_table-input first-deposit-conditions__sum-from first-deposit-conditions__input`}
					placeholder={translate("new_partners_condition_enter_volume_placeholder")}
					value={`${group.volume}`}
					onChange={this.handleOnChange(index, subIndex)}
					onValid={(value) => onValidPercantages(value, 100_000_00)}
					isRequired
					key={subIndex}
					/>
				</div>
			)
		})
	}

	renderTitlesForSumFrom = () => {
		const currencySymbol = this.currencyIsDollar ? "$" : "€";
		return this.state?.payoutRatesRanges?.sort((a, b,) => a.sum_from - b.sum_from).map(
			rate => {
				return (
					<div className='payout-rate-list__payout_table-title-bg first-deposit-conditions__sum-from' key={rate.sum_from}>
						<p className='payout-rate-list__payout_table-title'>
							{`${currencySymbol}${rate?.sum_from}*`}
						</p>
					</div>)
			}
		)
	}

	renderFtdSumFrom = () => {
		const { symbolGroupOptions, confirmedConditions, payoutRatesRanges, payoutRateListId } = this.state;
		const { translate, errors, removeCondition, symbolOptions, formData, } = this.props;

		const checkIsDisabled = this.checkIsDisabled;
		const isDisabled = (index) => checkIsDisabled(index) ;
		const rateListIsNotSelected = !formData.payout_rate_list_id && formData.payout_rate_list_id !== 0;

		return (
			<div className='payout-rate-list__payout_table-block custom-horizontal-scroll pb-175'>
				<div className='payout-rate-list__payout_table-forbidden mr-35'>
					<div className='first-deposit-conditions__row'>
						<div className='payout-rate-list__payout_table-title-bg'>
							<p className='payout-rate-list__payout_table-title'>
								{translate("symbols_group")}
							</p>
						</div>
						{
							this.renderTitlesForSumFrom()
						}
						{ payoutRatesRanges &&
							<div className='payout-rate-list__payout_table-title-bg first-deposit-conditions__deals'>
								<p className='payout-rate-list__payout_table-title'>
									{ translate("new_partners_condition_deals") }
								</p>
							</div>
						}
						<span ref={this.ref} />
					</div>
					{confirmedConditions.map((condition, index) => {
						const symbolGroupIsMT5 = symbolOptions.find(option => option.value === condition[0].symbol_group_id)?.server_name?.includes(tradingServers.MT5);

						return (
							<div key={index} className='first-deposit-conditions__row'>
								{confirmedConditions.length > 1 && !checkIsDisabled(index)
									? <button
										className="payout-rate-list__create-btn_btns-btn payout-rate-list__create-btn_btns-btn-remove mt-20 rm-range-item"
										onClick={() => removeCondition(index) && this.removeItem(index)}
									/>
									:  null
								}
								<Select
									key={`symbol_group_${index}`}
									label=''
									name={`symbol_group_${index}`}
									wrapperClassName={`payout-rate-list__payout_table-ftd-input first-deposit-conditions__select`}
									className={`${setErrorClass(errors, `symbol_group_${index}`)} first-deposit-conditions__select `}
									options={symbolGroupOptions}
									onChange={this.handleOnChange(index)}
									disabled={rateListIsNotSelected || isDisabled(index)}
									placeholder={translate("partners_create_symbol_not_group_select")}
									value={symbolOptions.find(option => option.value === +condition[0].symbol_group_id) ?? {}}
									isRequired
									hideLabel={true}
								/>
								{condition.length === payoutRatesRanges?.length ? this.renderFtdInputs(condition, index) : null}
								{
									payoutRatesRanges && <Select
										wrapperClassName={`first-deposit-conditions__select first-deposit-conditions__select_deals`}
										className={`${ setErrorClass(errors, `deals_${ index }`,) }` }
										hideLabel={true}
										isRequired={true}
										key={`deals_${index}`}
										label=""
										name={`deals_${index}`}
										options={this.dealsOptions}
										value={findOptionByValue(this.dealsOptions, condition[0].deals,)}
										onChange={this.handleOnChange(index)}
										placeholder={translate("client_dropdown_placeholder",)}
										disabled={isDisabled(index) || !symbolGroupIsMT5}
									/>
								}
								{condition.length !== payoutRatesRanges?.length  ? <p className='country-list__hint'>{translate('new_partners_condition_select_countrylist')}</p> : null}
							</div>
						)
					})
					}
					<span style={{marginTop: '15rem'}} ref={ this.myP } />
				</div>
			</div >
		)


	}

	render() {
		const { translate, createNewCondition, status, isViewMode } = this.props;
		const { payoutRatesRanges, symbolGroupOptions } = this.state;
		const disabled = !payoutRatesRanges ? 'disabled' : status.closed ? 'disabled' : symbolGroupOptions.length === 0 ? 'disabled' : isViewMode ? 'disabled' : '';
		return (
			<>
				<div className='payout-rate-list__payout_table-forbidden-title'>
					<p className={'payout-rate-list__payout_block-title'}>
						{translate('new_partners_condition_ftd_confirmation')}
					</p>
					<button
						onClick={() => createNewCondition() && this.myP.current?.scrollIntoView({
							behavior: "smooth",
							block: "end",
						}) && updateItemRefs()}
						className={`${disabled} payout-rate-list__create-btn_btns-btn payout-rate-list__create-btn_btns-btn-join`}
						disabled={disabled}
					>
						{translate(`new_partners_condition_ftd_confirmation_add_group`)}
					</button>
				</div>
				<div className='payout-rate-list__payout_table custom-vertical-scroll'>
					{this.renderFtdSumFrom()}
				</div>
			</>
		)
	}
}

export default withLocalize(FtdCpaSingleBlock);