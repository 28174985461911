import openSocket from 'socket.io-client';
import { profileService, } from "./ProfileDataService";

let store = null;

const NodeService = (() => {
	const config = {
		'development': {
			// |
			// v - was in old events branch, but socket has no connection;
			// host: "http://172.10.1.21", //http://172.10.1.21 TODO VASA
			// host: process.env.SOCKET_PORT ?? "http://crm.go2m.exchange",
			host: process.env.SOCKET_PORT ?? "http://crm.go2m.exchange",//http://localhost
			port: "8000",
		},
		'production': {
			host: "https://backoffice.gerchikco.com",
			port: "8000",
		},
	};

	let io = null;

	return {
		init (globalStore) {
			store = globalStore;

			if (io === null) {
				io = openSocket(`${ config[process.env.NODE_ENV].host }:${ config[process.env.NODE_ENV].port }`, {
					reconnecting: true,
					autoConnect: true,
					xdomain: true,
					secure: true,
					rejectUnauthorized: false,
					transports: [ "websocket", ],
				});
			}
		},
		authEvent (uid) {
			io.emit("authentication", {
				method: "event",
				data: {
					uid,
				},
			});
		},
		async logoutEvent () {
			for (const k in this.remove()) {
				this.remove()[k]();
			}
			const profile = await profileService.profile;
			io.emit("logout", {
				method: "event",
				data: {
					uid: profile.data.id,
					connect: io.id,
				},
			});
		},
		on () {
			return {
				info: (callback) => {
					io.on("info", callback);
				},
				getEvent: (callback) => {
					io.on("get_event", callback);
				},
				getCall: (callback) => {
					io.on("get_call", callback);
				},
				getExcel: (callback) => {
					io.on("export_to_excel_event", callback);
				},
				getNovaTalks: (callback) => {
					io.on("novatalks_event", callback);
				},
				getMessage: (callback) => {
					io.on("message", callback);
				},
				getEventEvent: (callback) => {
					io.on("event", callback);
				},
				reconnect: () => {
					io.on("reconnect", async () => {
						const profile = await profileService.profile;

						this.authEvent(profile.data.id);
					});
				},
			};
		},
		emit () {
			return {};
		},
		remove () {
			return {
				info: () => {
					io.removeListener('info');
				},
				getEvent: () => {
					io.removeListener('get_event');
				},
				getCall: () => {
					io.removeListener('get_call');
				},
				getExcel: () => {
					io.removeListener('export_to_excel_event');
				},
				getNovaTalks: () => {
					io.removeListener('novatalks_event');
				},
				getMessage: () => {
					io.removeListener('message');
				},
				getEventEvent: () => {
					io.removeListener('event');
				},
				reconnect: () => {
					io.removeListener('reconnect');
				},
			};
		},
	};
})();

export default NodeService;