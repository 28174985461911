import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import { MagicTable } from '../../../../components/Magic';
import ModalUtmCreateAndUpdate from '../Modal/ModalUtmCreateAndUpdate';
import { marketingService } from '../../../../services/MarketingDataService';
import NotificationService from '../../../../services/NotificationService';
import { MagicTooltip } from '../../../tima/components/Magic/MagicTooltip/MagicTooltip';

class MarketingSubChannelsMagicTable extends Component {

  constructor (props) {
    super(props);

    this.state = {
      isModalShown: false,
      data: {
        id: null,
        name: '-',
        number: '-',
        description: '-',
        status: '-',
      }
    }
  };

  static propTypes = {
    data: PropTypes.array,
    enums: PropTypes.object,
  };

  static defaultProps = {
    data: [],
    enums: {},
  };

  save = async state => new Promise(next => this.setState(state, next));

  get subChannelsTableConfig () {
    const { translate } = this.props;
    const prefix = 'statistics_utm_medium_';

    return [
      {
        path: [ 'statistics', 'utm_medium', 'name' ],
        key: 'utmMedium',
        render: this.renderInitialValue,
        title: `${prefix}name`,
      },
      {
        path: [ 'statistics', 'utm_medium', 'number' ],
        key: 'utmMediumNumber',
        render: this.renderInitialValue,
        title: `${prefix}number`,
      },
      {
        path: [ 'statistics', 'utm_source', 'name' ],
        key: 'utmSourceMediumName',
        render: this.renderBindChannelName,
        title: `${prefix}utm_source_name`,
      },
      {
        path: [ 'statistics', 'utm_medium', 'description' ],
        key: 'utmMediumDescription',
        render: this.renderDescription,
        title: `${prefix}description`,
      },
      {
        path: [ 'statistics', 'utm_medium', 'status' ],
        key: 'utmMediumStatus',
        render: this.renderChannelStatus,
        title: `${prefix}status`,
      },
      {
        path: [ 'statistics', 'utm_medium', 'id' ],
        key: 'utmMediumId',
        render: this.renderSubChannelId,
        title: `statistics_utm_source_edit`,
      },
    ].map(({ title: t, xtitle: x, ...item }) =>
      ({ ...item, ...x && { title: x },
        ...t && { title: translate(t) },
      }));
  };

  renderSubChannelId = (value, { item, items }) => {
    const { translate } = this.props;
    if (item?.access?.('update',) && value) {
      const url = `/marketing/advertising_channels/subchannels/${items.utmMediumId.valueOf}`
      return (
        <Link to={`${url}`}>
          <button
            onClick={(e) => {
              e.preventDefault()
              this.save({
                isModalShown: !this.state.isModalShown,
                data: {
                  name: items.utmMedium.valueOf,
                  number: items.utmMediumNumber.valueOf,
                  description: items.utmMediumDescription.valueOf,
                  status: items.utmMediumStatus.valueOf,
                  id: items.utmMediumId.valueOf,
                }
              })
            }}
            className='marketing-buttons_table-btn'
          >
            {translate(`marketing_edit_channel`)}
          </button>
        </Link>
      );
    }
    return '-';
  };

  renderDescription = (value, { item }) => {
    if (item?.access?.('index') && item.access?.('show')) {
      const LENGTH_SHORT = 30;
      return (
        <MagicTooltip
          classNameTooltip={`tooltip tooltip--large-width`}
          content={ value ?? '-' }
          lengthCut={ LENGTH_SHORT }
        />
      );
    }
    return '-';
  };

  renderBindChannelName = (value, { item }) => {
    if (item?.access?.('index') && item.access?.('show')) {
      const LENGTH_SHORT = 30;
      return (
        <MagicTooltip
          classNameTooltip={`tooltip tooltip--large-width`}
          content={ value !== undefined ? value.join(", ") : '-' }
          lengthCut={ LENGTH_SHORT }
        />
      );
    }
    return '-';
  };

  renderInitialValue = (value, { item }) => {
    if (item?.access?.('index')) {
      const LENGTH_SHORT = 30;
      return (
        <MagicTooltip
          classNameTooltip={`tooltip tooltip--large-width`}
          content={ value ?? '-' }
          lengthCut={ LENGTH_SHORT }
        />
      );
    }
    return '-';
  };

  renderChannelStatus = (status, { item, }) => {
    if (item.access('index') && item?.access?.('show')) {
      const curObj = status === 1 ? 'active activeStatus' : status === 2 ? 'inactive' : '' ;

      return <div className={`status-active ${curObj}`}>
        {this.props?.enums?.statistics?.UtmMediumStatusEnum?.[+status] ?? "-"}
      </div>
    }
    return '-';
  };

  renderLink = (path, text, className = "") => (<Link to={path} className={className}>{text}</Link>);

  updateUtmMedium = async ({ description, ...data }) => {
    try {
      const dataNew = {
        ...data,
        ...description === ''
          ? { SET_NULL__description: "1", }
          : description
            ? { description, }
            : {},
      };
      await marketingService.updateUtmMedium(this.state.data.id, dataNew);
      await this.props.onDataLoad();
      await this.save({ isModalShown: false });
    } catch (error) {
      await this.setErrorsFromServer(error)
      console.log(error)
    }
  };

  setErrorsFromServer = async (error) => {
    NotificationService.errors({...error?.response?.data, time: 25000});
    let errors = Object?.keys?.(error?.response?.data?.errors)?.map(e => e?.split('.')?.[0]) ?? [];
    await this.save({errors});
  };

  render () {
    if (!this.props?.data?.length) {
      return null;
    }

    const options = {
      config: this.subChannelsTableConfig,
      data: this.props?.data,
      head:   [
        'utmMedium',
        'utmMediumId',
        'utmMediumNumber',
        'utmMediumDescription',
        'utmMediumStatus',
        'utmSourceMediumName',
      ],
    };

    return (
      <>
        <MagicTable {...options} />
        {this.state.isModalShown === true ? (
          <ModalUtmCreateAndUpdate
            data={this.state.data}
            enums={this.props.enums}
            viewOnly={['name', 'number']}
            titleKey={{
              form: `marketing_edit_current_channel`,
              name: `statistics_utm_medium_name`,
              number: `statistics_utm_medium_number`,
              description: `statistics_utm_medium_description`,
              status: `statistics_utm_medium_status`,
            }}
            onSave={this.updateUtmMedium}
            onClose={() => this.save({ isModalShown: false })}
          />
        ) : null}
      </>
    );
  };
}

export default withRouter(withLocalize(MarketingSubChannelsMagicTable));
