import React, { Component }                        from 'react';
import { withLocalize }                            from 'react-localize-redux';
import { withRouter, }                             from 'react-router-dom';
import Loader                                      from '../../../../tima/components/Loader';
import Preloader                                   from '../../../../../components/LoadingHOC/Preloader';
import PagePanel                                   from '../../../../tima/components/Pages';
import {getTicketsByClientId}                      from '../../../services/AccountRequestService'
import PropTypes                                   from 'prop-types';
import ListSupportMagicTable                       from '../../../../support/components/ListSupportMagicTable';
import { profileService } from "../../../../../services/ProfileDataService";
import { supportService }     from "../../../../../services/SupportDataService";
import { Mentor } from "../../../../../components/Magic";

class LeadSupport extends Component {

   static defaultProps = {
      translate: () => {},
   };

   static propTypes = {
      translate: PropTypes.func,
   };

   constructor (props) {
      super(props);

      this.state = {
         profile: {},
         loaded: true,
         data: [],
         filterLastChange: Date.now(),
         responsibles: [],
         pages: {
            filter: 0,
            total: 0,
            take: 50,
            variance: 2,
         },
      };

      const mf = new Mentor({
         owner: this,
         serviceId: 217,
         translate: this.props.translate,
      });

      Object.defineProperty(this, 'mf', { get: () => mf, });
   }

   save = async (state) => new Promise(next => this.setState(state, next));

   profileChange = async (profile) => {
      if (profile.hash===this.state?.profile?.hash) return `${this.constructor.name}.profileChange: false`;
      await this.save(({ profile: p, }) => ({
         profile: { ...p, ...this.checkProfile(profile), },
      }));
      return `${this.constructor.name}.profileChange: true`;
   };

   mfChanged = async () => {
      await this.save({ filterLastChange: Date.now(), });
   }

   mfDoing = async () => {
      await this.save({ loaded: false, });
   };

   mfDone = async () => {
      await this.mfChanged();
      await this.onDataLoad();
   };

   async componentDidMount() {
      profileService.subscribe('profile', this.profileChange, this);
      await profileService.profile;
      this.mf.subscribe({
         changed: this.mfChanged,
         // changing: ,
         cleaned: this.mfDone,
         cleaning: this.mfDoing,
         loaded: this.mfDone,
         loading: this.mfDoing,
         saved: this.mfDone,
         saving: this.mfDoing,
      }, this);
      await this.mf.init({ doEmit: true, });
      // await this.mf.emit('change', {});
      // await this.onDataLoad(); // TODO: moved to mfDone
   }

   componentWillUnmount () {
      profileService.unsubscribe('profile', this.profileChange, this);
      this.mf.unsubscribe([
         this.mfChanged,
         this.mfDoing,
         this.mfDone,
      ], this);
   }

   get params () { return this.props?.match?.params }

   get query() { return new URLSearchParams(this.props?.location?.search) }

   isEmpty = val => val === null || !(Object.keys(val) || val).length;

   checkProfile(profile) {
      const { tree_info: profileDepartments, } = profile.data;
      const departments = profileDepartments?.map?.(d => d?.department_alias) ?? [];
      const isTechnicalSupport = departments.includes('technical_support');
      const isROP = departments.includes('administrator_sales');
      const { id: profileID, name, surname, } = profile.data;
      return {
         hash: profile.hash,
         isTechnicalSupport, isROP,
         profileID, name, surname,
      };
   }

   pagination = () => {};

   pageId = () => {
      const page = this.query.get('page');
      return Number(page) || 0;
   };

   pageIdAsGET = (pageId) => {
      pageId = pageId === undefined ? this.pageId() : pageId;
      const result = {
         take: this.state.pages.take,
         skip: pageId * this.state.pages.take,
      };
      return result;
   };

   onDataLoad = async (pageId) => {
      await this.save({loaded: false,});
      const pageOptions = this.pageIdAsGET(pageId);
      const loaded = (data, meta, responsibles) => ({pages,}) => ({
         data,
         pages: {...pages, ...meta,},
         responsibles,
      });
      try {
         let [response, responsibles = [],] = await Promise.all([
            getTicketsByClientId(this.props?.accountId, pageOptions),
            supportService.getResponsibles(),
            // this.checkProfile(),
         ]);
         responsibles = [{id: null, name: "", surname: ""}, ...responsibles];
         let {data = [], meta: {filter = 0, total = 0,},} = response;
         await this.save(loaded(data, {filter, total,}, responsibles,));
      } catch (error) {
         error?.showErrorNotification?.();
         await this.save(loaded([], {filter: 0, total: 0,}, []));
      }
      await this.save({loaded: true,});
   };

   onPageClick = ({event, pageId, pageIs}) => {
      this.onDataLoad(pageId);
   };

   onPageText = (pageId, pageIs) => {
      const {translate} = this.props;
      const {current, first, prev, next, last} = pageIs;
      const {skipped, taken, filter, total} = pageIs;
      if (skipped || taken) {
         const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : '?';
         const text = skipped ? translate('support_pages_items') : '';
         return skipped ? `${ text }: ${ id }` : id;
      } else if (filter || total) {
         const id = Number.isInteger(pageId) ? `${ pageId }` : '?';
         const text = translate(filter ? 'support_pages_filtered' : 'support_pages_total');
         return `${ text }: ${ id }`;
      } else if (first || prev || next || last) {
         return '';
      } else if (current) {
         return `${ pageId + 1 }`;
      } else {
         return `${ pageId + 1 }`;
      }
   };

   render() {
      const {loaded, pages, data, profile, responsibles} = this.state;
      return (
         <Loader loaded={ loaded } loading={ <Preloader scale={ 1 }/> }>
            <div className="support">
               <div className="table">
                  <ListSupportMagicTable
                     data={data}
                     profile={profile?.data}
                     refreshData={this.onDataLoad}
                     responsibles={responsibles}
                     mf={ this.mf }
                  />
                  <PagePanel
                     showSingle={ false }
                     filter={ pages.filter }
                     take={ pages.take }
                     total={ pages.total }
                     variance={ pages.variance }
                     page={ this.pageId() }
                     onClick={ this.onPageClick }
                     doText={ this.onPageText }
                     doTo={ pageId => `?page=${ pageId }` }
                  />
               </div>
            </div>
         </Loader>
      );
   }
}

export default withRouter(withLocalize(LeadSupport));
