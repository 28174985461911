/* eslint-disable no-useless-escape , no-shadow , no-param-reassign , max-len */
import React, { PureComponent, } from "react";
import { withLocalize, } from "react-localize-redux";
import { Popup, Input, Select, } from "../../../../components";
import {
	createPartnersGroup,
	updatePartnersGroup,
	asyncGet,
} from "../../../../../../services/PartnersRequestService";
import {
	GET_TRADE_SERVER_LIST_TAKE_ALL,
	GET_MT_4_GROUPS_LIST,
} from "../../../../../../apiRoutes";
import {
	findOptionByValue,
	setErrorsFromServer,
	setErrorClass,
	isMatch,
	isEmptySelectValue,
	isEmptyString,
} from "../../../../utils/helpers";
import { MagicSwitch, } from "../../../../../tima/components/Magic/MagicSwitch";
import PropTypes from "prop-types";
import NotificationService from "../../../../../../services/NotificationService";

const tableFields = Object.freeze({
	name: "NAME",
	owner: "OWNER",
	select: "SELECT",
});

const groupStatus = Object.freeze({
	ACTIVE: 2,
	CLOSED: 3,
	NEW: 1,
});

class CreateUpdateAccountsGroupList extends PureComponent {
	static defaultProps = {
		fieldsTable: [ tableFields.select, tableFields.name, tableFields.owner, ],
		formData: {},
		isEditMode: false,
		onClose: () => {},
	};

	static propTypes = {
		formData: PropTypes.shape({
			list: PropTypes.string,
			name: PropTypes.string,
			status: PropTypes.number,
			trading_server_id: PropTypes.number,
		}),
		onClose: PropTypes.func,
	};

	constructor (props) {
		super(props);

		this.state = {
			allGroupList: [],
			errors: [],
			formData: {
				name: "",
				status: {},
				trading_server_id: {},
			},
			groupList: [],
			isAllSelected: false,
			loadData: true,
			platforms: this.getPlatforms() ?? {},
			search: "",
			selectedGroupId: [],
			serversList: [],
			statusList: this.getStatusList() ?? [],
		};
	}

	get isEditMode () {
		return Object.keys(this.props.formData).length > 0;
	}

	get translate () {
		return this.props.translate;
	}

	get groupStatusInDB () {
		const { status, } = this.props.formData;
		const { NEW, ACTIVE, CLOSED, } = groupStatus;

		return {
			active: status === ACTIVE,
			closed: status === CLOSED,
			new: status === NEW,
		};
	}

	get fieldsIsNotEdit () {
		return this.isEditMode && !this.groupStatusInDB.new;
	}

	get groupStatusIsNotEdit () {
		return !this.isEditMode || this.isEditMode && this.groupStatusInDB.closed;
	}

	get groupStatusIsClosed () {
		return this.isEditMode && this.groupStatusInDB.closed;
	}

	get enums () {
		return this.props.enums.data ?? {};
	}

	get getVisibleGroups () {
		return this.state.groupList.filter(({ isVisible, }) => isVisible);
	}

	get getSelectedGroups () {
		return this.state.groupList.filter(({ id, }) => this.state.selectedGroupId.includes(id)) ?? [];
	}

	componentDidMount = async () => {
		await this.toggleLoader();
		const serversList = await this.getServersList();
		const allGroupList = await this.getAllGroupsList();

		await this.save(state => ({
			allGroupList,
			formData: { ...state.formData, status: state.statusList[0], },
			serversList,
		}));

		this.isEditMode && await this.setSavedData();
		await this.toggleLoader();
	};

	save = async (state) => {
		await new Promise(next => this.setState(state, next));
	};

	toggleLoader = () =>
		this.save(state => ({
			loadData: !state.loadData,
		}));

	setSavedData = async () => {
		const {
			name, trading_server_id, status, list,
		} = this.props.formData;

		const selectedGroupsIdx = Object.keys(JSON.parse(list)).map(item =>
			Number(item),
		);

		await this.save(state => ({
			formData: {
				...state.formData,
				name,
				status: findOptionByValue(state.statusList, status),
				trading_server_id: findOptionByValue(
					state.serversList,
					trading_server_id,
				),
			},
			...status === groupStatus.ACTIVE && {
				statusList: state.statusList.filter(
					({ value, }) => value !== groupStatus.NEW,
				),
			},
		}));

		await this.getGroupListByPlatform(selectedGroupsIdx);
	};

	getSendData = () => {
		const { name, trading_server_id, status, } = this.state.formData;

		const list = JSON.stringify(
			this.getSelectedGroups.reduce((total, { label, id, }) => {
				total[id] = label;

				return total;
			}, {}),
		);

		return {
			list,
			name: name.trim(),
			status: status.value,
			trading_server_id: trading_server_id.value,
		};
	};

	getServersList = async () => {
		try {
			const response = await asyncGet(GET_TRADE_SERVER_LIST_TAKE_ALL);

			return response
				.filter(({ server_name, }) => !server_name.includes("Demo"))
				.reduce(
					(total, { id, server_name, type, }) => [
						...total,
						{ label: server_name, type, value: +id, },
					],
					[],
				);
		} catch (error) {
			error?.showErrorNotification?.();

			return false;
		}
	};

	getStatusList = () => {
		return Object.entries(this.enums.partners.MtGroupListStatusEnum ?? {}).map(
			([ key, value, ]) => ({ label: value, value: +key, }),
		);
	};

	getPlatforms = () => {
		return Object.entries(
			this.enums.core.AccountBalancesPlatformEnum ?? {},
		).reduce((total, [ key, value, ]) => {
			total[value] = +key;

			return total;
		}, {});
	};

	getAllGroupsList = async () => {
		try {
			return await asyncGet(GET_MT_4_GROUPS_LIST);
		} catch (error) {
			error?.showErrorNotification?.();

			return false;
		}
	};

	getGroupListByPlatform = async (selectedIds = []) => {
		const {
			formData: { trading_server_id, },
			allGroupList,
		} = this.state;

		const groupList = allGroupList
			.filter(({ platform, }) => +platform === +trading_server_id.type)
			.map((item) => {
				const isSelected = selectedIds.includes(item.id);
				const isDisabled = !this.groupStatusInDB.new && isSelected;

				return {
					...item,
					isSelected,
					isVisible: true,
					label: item.name,
					...isDisabled && { isDisabled: true, },
				};
			});

		await this.save(state => ({
			errors: state.errors.filter(i => i !== "list"),
			groupList,
			groupListForSearch: groupList,
			selectedGroupId: [ ...selectedIds, ],
		}));
	};

	handleOnChange = async (event) => {
		const { name, value, } = event.target;

		await this.save(state => ({
			...state,
			errors: state.errors.filter(item => item !== name),
			formData: {
				...state.formData,
				[name]: value,
			},
		}));
	};

	handleSwitch = async (event, rowIndex, groupId) => {
		const groupList = [ ...this.state.groupList, ];
		const selectedGroupId = [];

		groupList[rowIndex].isSelected = !groupList[rowIndex].isSelected;
		if (event.value === 1) {
			selectedGroupId.push(groupId);
		} else {
			await this.save({
				selectedGroupId: this.state.selectedGroupId.filter(item => item !== groupId),
			});
		}

		await this.save(state => ({
			errors: state.errors.filter(i => i !== "list"),
			groupList,
			selectedGroupId: [ ...this.state.selectedGroupId, selectedGroupId, ].flat(),
		}));
	};

	toggleSelectAllGroups = async (e) => {
		const { isAllSelected, } = this.state;

		const groupList = [ ...this.state.groupList, ].map((group) => {
			return group.isDisabled === true
				? { ...group, isSelected: true, }
				: { ...group, isSelected: !isAllSelected, };
		});

		if (e.value === 1) {
			await this.save({
				selectedGroupId: this.state.groupList.map(item => item.id),
			});
		} else {
			await this.save({
				selectedGroupId: [],
			});
		}

		await this.save(state => ({
			errors: state.errors.filter(i => i !== "list"),
			groupList,
			isAllSelected: !state.isAllSelected,
		}));
	};

	handleSearchGroup = async (event) => {
		const { name, value, } = event.target;

		const groupList = JSON.parse(JSON.stringify([ ...this.state.groupList, ])).map(
			item => ({
				...item,
				isSelected: item.isSelected ? isMatch(item.name, value) : false,
				isVisible: isMatch(item.name, value),
			}),
		);

		await this.save(state => ({
			...state,
			groupList,
			isAllSelected:
				groupList.filter(({ isVisible, isSelected, }) => isVisible && isSelected)
					.length === groupList.length,
			[name]: value,
		}));
	};

	validateEmptyFields = async () => {
		await this.save(state => ({
			errors: Object.keys(state.formData).reduce((total, field) => {
				if (
					isEmptySelectValue(state.formData[field]) ||
					isEmptyString(state.formData[field])
				) {
					total = [ ...total, field, ];
				}

				if (!this.getSelectedGroups.length && !total.includes("list")) {
					total = [ ...total, "list", ];
				}

				return total;
			}, []),
		}));

		return this.state.errors.length === 0;
	};

	saveData = async () => {
		const isValidate = await this.validateEmptyFields();

		if (!isValidate) {
			return;
		}
		this.isEditMode
			? await this.updateGroupList()
			: await this.createGroupList();
	};

	createGroupList = async () => {
		const { onClose, onDataLoad, } = this.props;

		try {
			await this.save({ errors: [], loadData: false, });
			await createPartnersGroup(this.getSendData());
			await Promise.all([ onClose(), onDataLoad(), ]);
		} catch (error) {
			await setErrorsFromServer(error, this.save);
		} finally {
			await this.save({ loadData: true, });
		}
	};

	updateGroupList = async () => {
		const { onClose, onDataLoad, formData, } = this.props;

		try {
			await this.save({ errors: [], loadData: false, });

			await updatePartnersGroup(formData.id, this.getSendData());
			await Promise.all([ onClose(), onDataLoad(), ]);
		} catch (error) {
			const jsonSymbolListErrors = error?.response?.data?.errors?.list;
			const symbolListErrors = jsonSymbolListErrors?.map((item) => {

				const textForReplace = 'real\\G&Co\\';
				const errorMsg = JSON.parse(item).message.replace(textForReplace, ' ');

				NotificationService.error({
					title: "error",
					message: errorMsg,
					remove: false,
				});

			},

			);

			NotificationService.error({
				title: "error",
				message: error.response.data.error,
				remove: false,
			});
			await this.save({ symbolListErrors, });
		} finally {
			await this.save({ loadData: true, });
		}
	};

	renderTitle = () => {
		if (this.isEditMode) {
			return this.translate("partners_edit_account_group_list_title");
		}

		return this.translate("partners_create_account_group_list_title");
	};

	renderTable = () => {
		const { fieldsTable, } = this.props;
		const {
			groupList, isAllSelected, loadData, search, errors,
		} = this.state;

		const renderTableHead = (fields = fieldsTable) => {
			const columnHead = ({ field, }) => {
				switch (field) {
					case tableFields.select:
						return (
							<div>
								<MagicSwitch
									className="magic-switch magic-switch--inline"
									index={ isAllSelected ? 1 : 0 }
									isDisabled={ !groupList.length }
									text=""
									values={ [ 0, 1, ] }
									onChange={ this.toggleSelectAllGroups }
								/>
								<span>
									{`
										${ this.translate("partners_create_account_group_list_selected") }
										(${ this.state.selectedGroupId.length }/${ groupList.length })
									`}
								</span>
							</div>
						);
					case tableFields.name:
						return (
							<Input
								disabled={ !loadData }
								name="search"
								placeholder={ this.translate(
									"partners_create_account_group_list_search_group",
								) }
								value={ search }
								wrapperClassName="search"
								onChange={ this.handleSearchGroup }
							/>
						);
					case tableFields.owner:
						return this.translate("partners_create_account_group_list_owner");
					default:
						return "";
				}
			};

			return (
				<thead>
					<tr>
						{fields.map?.((field, i) => (
							<th key={ i }>{columnHead({ field, })}</th>
						))}
					</tr>
				</thead>
			);
		};

		const renderTablebody = (data) => {
			const columnBody = ({ field, data, }) => {
				switch (field) {
					case tableFields.select:
						return (
							<MagicSwitch
								className={ `magic-switch magic-switch--inline ${
									data.isDisabled ? "magic-switch__disabled" : ""
								} ` }
								index={ this.state.selectedGroupId.includes(data.id) ? 1 : 0 }
								isDisabled={ data.isDisabled }
								text=""
								updateStateFromProps={ true }
								values={ [ 0, 1, ] }
								onChange={ e => this.handleSwitch(e, data.rowIndex, data.id) }
							/>
						);
					case tableFields.name:
						return <span className="bg-grey">{data.name}</span>;
					case tableFields.owner:
						return data.owner;
					default:
						return "-";
				}
			};

			const renderColumn = (data, fields = fieldsTable) => {
				return fields.map((field, index) => (
					<td key={ index }>{columnBody({ data, field, })}</td>
				));
			};

			return (
				<tbody>
					{data.map?.((item, index) => (
						<tr key={ index }>{renderColumn({ ...item, rowIndex: index, })}</tr>
					))}
				</tbody>
			);
		};

		return (
			<div
				className={ `table__wrapper scroll ${ setErrorClass(errors, "list") } ` }
			>
				{groupList.length ? (
					<table className="table">
						{renderTableHead()}
						{renderTablebody(this.getVisibleGroups)}
					</table>
				) : (
					<div className="table--empty">
						{this.translate("partners_create_account_group_list_table_empty")}
					</div>
				)}
			</div>
		);
	};

	render = () => {
		const {
			loadData, formData, serversList, statusList, errors,
		} = this.state;

		const { onClose, } = this.props;

		return (
			<Popup
				loadData={ loadData }
				size="md"
				title={ this.renderTitle() }
				onClick={ this.saveData }
				onClose={ onClose }
			>
				<div className="popup__subtitle">
					{this.translate("partners_create_account_group_list_params")}
				</div>
				<div className="popup__body--row">
					<Input
						disabled={ !loadData || this.fieldsIsNotEdit }
						isRequired={ true }
						label={ this.translate("partners_create_account_group_list_name") }
						name="name"
						placeholder={ this.translate(
							"partners_create_account_group_list_name_placeholder",
						) }
						value={ formData.name }
						wrapperClassName={ setErrorClass(errors, "name") }
						onChange={ this.handleOnChange }
						onValid={ (value) => {
							const regExp = /^[a-zA-Z0-9\s\.\,\/\\\-\_\&\#]{0,100}$/g;

							if (value[0] === " " || !regExp.test(value)) {
								throw new Error("xxx");
							}
						} }
					/>
					<Select
						className={ setErrorClass(errors, "trading_server_id") }
						disabled={ !loadData || this.fieldsIsNotEdit }
						isRequired={ true }
						label={ this.translate("partners_create_account_group_list_server") }
						name="trading_server_id"
						options={ serversList }
						placeholder={ this.translate(
							"partners_create_account_select_server",
						) }
						value={ formData.trading_server_id ?? {} }
						onChange={ async (event) => {
							await this.handleOnChange(event);
							await this.getGroupListByPlatform();
						} }
					/>
					<Select
						className={ setErrorClass(errors, "status") }
						disabled={ !loadData || this.groupStatusIsNotEdit }
						isRequired={ true }
						label={ this.translate("partners_create_account_group_list_status") }
						name="status"
						options={ statusList }
						value={ formData.status }
						onChange={ this.handleOnChange }
					/>
				</div>
				<div className="popup__subtitle popup__subtitle--mt">
					{this.translate("partners_create_account_group_list_table_groups")}
				</div>
				<label className="popup__label">{`${ this.translate(
					"partners_groups-list",
				) }*`}</label>
				{this.renderTable()}
			</Popup>
		);
	};
}

export default withLocalize(CreateUpdateAccountsGroupList);