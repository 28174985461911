import {
  itemsBuild,
  itemsDrain,
  itemsResort,
  CFG_ITEM_TYPE,
} from '../Config';
import { onEnabled, render, } from '../Mentor/withDropDown';

const f_tUserId = {
  'drop-down': '/api/tickets/tickets/user/drop-down?take=all',
  'o=': [ '=', '!=', ],
};

const p_tUserId = {
  onEnabled: onEnabled('tUserIds'),
  render: render(
    'tUserIds',
    _ => _ ? `${ _?.name } ${ _?.surname }` : '-',
  ),
};

const support = {
  id: 216,
  ...[

    // filters
    ...[ {
      f: 'tickets.tickets.id',
      id: 'f:tId',
    }, {
      f: 'tickets.tickets.created_at',
      id: 'f:tCreated',
    }, {
      f: 'tickets.tickets.updated_at',
      id: 'f:tUpdated',
    }, {
      f: 'tickets.tickets.status',
      id: 'f:tStatus',
    }, {
      f: 'tickets.tickets.sub_status',
      id: 'f:tSubStatus',
    }, {
      f: 'tickets.tickets.satisfaction',
      id: 'f:tSatisfaction',
    }, {
      // f: [ 'core.user.surname', 'core.user.name', ],
      f: 'tickets.tickets.user_id',
      id: 'f:tUserId',
      ...f_tUserId,
    }, {
      f: 'core.account.manager',
      id: 'f:aManager',
    }, {
			f: 'core.dictionary_licenses.alias',
			id: 'f:tLicenseAlias',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

    // orders
    ...[ {
      f: 'tickets.tickets.id',
      id: 'o:tId',
    }, {
      f: 'tickets.tickets.subject',
      id: 'o:tSubject',
    }, {
      f: 'core.account.surname',
      id: 'o:aSurname',
    }, {
      f: 'tickets.tickets.created_at',
      id: 'o:tCreatedAt',
    }, {
      f: 'tickets.tickets.status',
      id: 'o:tStatus',
    }, {
      f: 'tickets.tickets.updated_at',
      id: 'o:tUpdatedAt',
    }, {
      f: 'core.user.surname',
      id: 'o:uSurname',
    }, {
      f: '',
      id: 'o:',
      off: true, // TODO:
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

    // panels
    ...[ {
      ii: 'f:tId',
      t: 'magic_filter_translate_support_ticket_id',
    }, {
      ii: 'f:tCreated',
      t: 'magic_filter_translate_support_ticket_created_at',
    }, {
      ii: 'f:tUpdated',
      t: 'magic_filter_translate_support_ticket_updated_at',
    }, {
      ii: 'f:tStatus',
      t: 'magic_filter_translate_support_ticket_status',
    }, {
      ii: 'f:tSubStatus',
      t: 'magic_filter_translate_support_ticket_sub_status',
    }, {
      ii: 'f:tSatisfaction',
      t: 'magic_filter_translate_support_ticket_satisfaction',
    }, {
      ii: 'f:tUserId',
      t: 'magic_filter_translate_support_ticket_user',
      ...p_tUserId,
    }, {
      ii: 'f:aManager',
      t: 'magic_filter_translate_support_ticket_client_manager',
    }, {
			ii: 'f:tLicenseAlias',
			t: 'magic_filter_translate_task_license_alias',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

  ]
  .map(itemsBuild)
  .reduce(itemsDrain, [])
  .reduce(itemsResort, {}),

  path: 'tickets/tickets',
  widget: false,
};

export default [
  support,
  {
    ...support,
    filters: [],
    id: 217,
    panels: [],
  },
];
