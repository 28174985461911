import React from 'react';

import PermissionService, { MODULE_KEYS as pm } from "../../services/PermissionService";

// const Support = lazy( () => import( './components/Support' ) );
import Support from './components/Support';


export const supportConfig = [
	{
		permission: pm.SUPPORT_TICKETS,
		action: PermissionService.showRoute(),
		component: <Support activeTab='support' />,
		path: '/support/:supportId',
	},
	{
		permission: pm.SUPPORT_TICKETS,
		action: PermissionService.indexRoute(),
		component: <Support activeTab='supports' />,
		path: [
			// '/support/filter/:filterId',
			'/support',
		],
	},

];
