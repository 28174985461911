import React, { Component, } from "react";
import { withLocalize, } from "react-localize-redux";
import { Select, } from "../../../../../../components";
import { setErrorClass, findOptionByValue, } from "../../../../../../utils/helpers";
import Radio from "../Radio";
class ReferralCountryBlockCpl extends Component {
	constructor (props) {
		super(props);

		this.state = {
			countryCheckListOptions: [],
			leadAppearConditionOptions: [],
		};
	}

	get leadAppearByReferrerCountryCheckOptions () {
		//options changing depending on reffer country check
		const countryCheck = this.props.countryCheck;
		const leadAppearConditionOptions = this.state.leadAppearConditionOptions;

		const byCitizenshipOptions = leadAppearConditionOptions.filter(
			condition => condition.value !== 1 && condition.value !== 2,
		);
		const byPhoneOptions = leadAppearConditionOptions.filter(
			condition => condition.value !== 4,
		);

		return countryCheck.BY_PHONE ? byPhoneOptions : byCitizenshipOptions;
	}

	save = async state => new Promise(next => this.setState(state, next));

	componentDidMount = async () => {
		const { countryCheckListOptions, leadAppearConditionOptions, } = this.props;

		await this.save((state) => {
			return {
				countryCheckListOptions,
				leadAppearConditionOptions,
			};
		});
	};
	componentDidUpdate = async (prevProps, prevState) => {
		const { setNewValue, formData, } = this.props;
		const countryCheck = formData.referrer_country_check;
		const prevCountryCheck = prevProps.formData.referrer_country_check;

		if (prevCountryCheck !== countryCheck) {
			await setNewValue('condition_lead_appears', '');
		}

	}

	handleOnChange = fieldName => async (event) => {
		const { value, } = event.target;
		const { setNewValue, clearErrorOnChange, } = this.props;

		try {
			clearErrorOnChange(event);
			switch (fieldName) {
				case "referrer_country_check": {
					await setNewValue(fieldName, value.value);
					break;
				}
				case "condition_lead_appears": {
					await setNewValue(fieldName, value.value);
					break;
				}
				case "payout_rate_list_id": {
					await setNewValue(fieldName, value.value);
					break;
				}
				default: {
					break;
				}
			}
		} catch (e) {
			throw new Error(e);
		}
	};

	renderReferralCountryBlockItems = () => {
		const {
			translate,
			errors,
			payoutRateList,
			conditionType,
			formData,
			fieldsIsNotEdit,
			statusIsClosed,
			statusIsActive,
			isViewMode,
			level,
			setNewValue,
		} = this.props;

		const { countryCheckListOptions, } = this.state;
		const considerReferralsLinkedToLocked =
			formData.consider_ref_linked_to_locked;
		const leadAppearByReferrerCountryCheckOptions = this
			.leadAppearByReferrerCountryCheckOptions;
		const filteredPayoutRateList = payoutRateList
			.filter(
				({ partners, }) =>
					partners.payout_rate_lists.type === conditionType &&
					partners.payout_rate_lists.status === 2,
			)
			.map(({ partners, }) => {
				return {
					label: partners.payout_rate_lists.name,
					value: partners.payout_rate_lists.id,
				};
			});

		//if refferal country check changes resets value of select if no such option provided
		const leadAppearByReferrerCountryCheckValue =
			leadAppearByReferrerCountryCheckOptions?.filter(
				option => option.value === formData?.condition_lead_appears,
			)[0]?.value ?? null;

		const refferCountryCheckSelected = formData.referrer_country_check;
		const disabled =
			fieldsIsNotEdit || statusIsActive || statusIsClosed || isViewMode;

		return (
			<div className="new-partners-conditions__popup mb-20">
				{level.MULTI_LEVEL && (
					<div className="w-30 mr-35">
						<Radio
							className={ `magic-radio-switch magic-radio-switch--accept-reject-btns magic-radio-switch--payment-confirmation` }
							classNameText={ "`magic-switch__text`" }
							direction={ "row" }
							handleOnChange={ value =>
								setNewValue("consider_ref_linked_to_locked", value.value)
							}
							isDisabled={ disabled }
							label={ translate(
								"new_partners_confition_allow_blocked_referrals",
							) }
							options={ [
								{ name: translate("symbols_remove_accept"), value: 1, },
								{ name: translate("symbols_remove_reject"), value: 2, },
							] }
							value={ considerReferralsLinkedToLocked }
							wrapperClassName={ "select__wrapper" }
						/>
					</div>
				)}
				<div className="w-30 mr-35">
					<Select
						className={ `${ setErrorClass(errors, "referrer_country_check") }` }
						disabled={ disabled }
						isRequired={ true }
						label={ translate("new_partners_condition_country_determination") }
						name="referrer_country_check"
						options={ countryCheckListOptions }
						placeholder={ translate(
							"new_partners_condition_country_check_placeholder",
						) }
						value={ findOptionByValue(
							countryCheckListOptions,
							formData.referrer_country_check,
						) }
						onChange={ this.handleOnChange("referrer_country_check") }
					/>
				</div>
				<div className="w-30 mr-35">
					<Select
						className={ setErrorClass(errors, "condition_lead_appears") }
						disabled={ disabled || !refferCountryCheckSelected }
						isRequired={ true }
						label={ translate("new_partners_condition_lead_determination") }
						name="condition_lead_appears"
						options={ leadAppearByReferrerCountryCheckOptions }
						placeholder={ translate(
							"new_partners_condition_lead_determination__placeholder",
						) }
						value={ findOptionByValue(
							leadAppearByReferrerCountryCheckOptions,
							leadAppearByReferrerCountryCheckValue,
						) }
						onChange={ this.handleOnChange("condition_lead_appears") }
					/>
				</div>
				<div className="w-30 mr-35 country-list">
					<Select
						className={ setErrorClass(errors, "payout_rate_list_id") }
						disabled={ disabled }
						isRequired={ true }
						label={ translate("new_partners_condition_country_list") }
						name="payout_rate_list_id"
						options={ filteredPayoutRateList }
						placeholder={ translate("new_partners_condition_select_countrylist") }
						value={ findOptionByValue(
							filteredPayoutRateList,
							formData.payout_rate_list_id,
						) }
						onChange={ this.handleOnChange("payout_rate_list_id") }
					/>
				</div>
			</div>
		);
	};
	render () {
		const { formData, } = this.props;

		return <>{formData && this.renderReferralCountryBlockItems()}</>;
	}
}

export default withLocalize(ReferralCountryBlockCpl);