import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withLocalize } from "react-localize-redux";
import Preloader from "../../../../components/LoadingHOC/Preloader";
import PagePanel from "../../../tima/components/Pages/PagePanel";
import Loader from "../../../tima/components/Loader";
import {
    MagicFilterPanels,
    MagicTable,
    Mentor,
} from "../../../../components/Magic";
import { listAccountService } from "../../../../services/ListAccountDataService";

class OpenPositionsMT5 extends Component {
  constructor (props) {
    super(props);

    this.state = {
      loaded:           false,
      openPositions: {
          data: [],
          hash: null
      },
      pages:            {
        filter:   0,
        total:    0,
        take:     50,
        variance: 2,
      },
      filterLastChange: Date.now(),
    };

    const mf = new Mentor({
      owner: this,
      serviceId: 101,
      translate: this.props.translate,
    });

    Object.defineProperty(this, 'mf', { get: () => mf, });
    this.props.mf(mf);
  }

  // eslint-disable-next-line react/sort-comp, require-await
  save = async state => new Promise(next => this.setState(state, next));

  // eslint-disable-next-line require-await
  mfChanged = async () => {
    await this.save({ filterLastChange: Date.now(), });
  };

  mfDoing = async () => {
    await this.save({ loaded: false, });
  };

  mfDone = async () => {
    await this.mfChanged();
    await this.onDataLoad();
  };

  query = () => new URLSearchParams(this.props?.location?.search);

  openPositionChanges = async (openPositions) => {
      if (openPositions.hash===this.state?.openPositions?.hash) return `${this.constructor.name}.openPositionChanges: false`;
      const { data, meta, hash } = openPositions;
      await this.save({
          openPositions: { data, hash },
          pages: {
              ...this.state.pages,
              filter: meta?.filter,
              total: meta?.total,
          }
      });
      return `${this.constructor.name}.openPositionChanges: true`;
  };

  componentDidMount = async () => {
    this.mf.subscribe({
      changed: this.mfChanged,
      // changing: ,
      cleaned: this.mfDone,
      cleaning: this.mfDoing,
      loaded: this.mfDone,
      loading: this.mfDoing,
      saved: this.mfDone,
      saving: this.mfDoing,
    }, this);
    await this.mf.init({ doEmit: true, });
    listAccountService.subscribe('tradingAccountsOpenPositionsMT5', this.openPositionChanges, this);
    // await this.mf.emit('change', {});
    // await this.onDataLoad(); // TODO: moved to mfDone
  };

  componentWillUnmount = () => {
    this.mf.unsubscribe([
      this.mfChanged,
      this.mfDoing,
      this.mfDone,
    ], this);
    listAccountService.unsubscribe('tradingAccountsOpenPositionsMT5', this.openPositionChanges, this);
  };

  get openPositionsMT5Config () {
    const { translate, } = this.props;
    const prefix = 'list_accounts_table_positionsMT5_';

    return [
      {
        accessChecked: true,
        path:          ['positions', 'Symbol'],
        key:           'Symbol',
        render:        this.renderInlineValue,
        title:         `${prefix}Symbol`,
      },
      {
        accessChecked: true,
        path:          ['positions', 'Position'],
        key:           'Position',
        render:        this.renderInlineValue,
        title:         `${prefix}Position`,
      },
      {
        accessChecked: true,
        path:          ['positions', 'TimeCreate'],
        key:           'TimeCreate',
        render:        this.renderInlineValue,
        title:         `${prefix}TimeCreate`,
      },
      {
        accessChecked: true,
        path:          ['positions', 'Action'],
        key:           'Action',
        render:        this.renderEnumField('core', 'Mt5PositionTypesEnum'),
        title:         `${prefix}Action`,
      },
      {
        accessChecked: true,
        path:          ['positions', 'Volume'],
        key:           'Volume',
        render:        this.renderNumberFixedFormat({ fieldName: 'Volume' }),
        title:         `${prefix}Volume`,
      },
      {
        accessChecked: true,
        path:          ['positions', 'PriceOpen'],
        key:           'PriceOpen',
        render:        this.renderNumberFixedFormat({ fieldName: 'PriceOpen' }),
        title:         `${prefix}PriceOpen`,
      },
      {
        accessChecked: true,
        path:          ['positions', 'PriceSL'],
        key:           'PriceSL',
        render:        this.renderNumberFixedFormat({ fieldName: 'PriceSL' }),
        title:         `${prefix}PriceSL`,
      },
      {
        accessChecked: true,
        path:          ['positions', 'PriceTP'],
        key:           'PriceTP',
        render:        this.renderNumberFixedFormat({ fieldName: 'PriceTP' }),
        title:         `${prefix}PriceTP`,
      },
      {
        accessChecked: true,
        path:          ['positions', 'PriceCurrent'],
        key:           'PriceCurrent',
        render:        this.renderNumberFixedFormat({ fieldName: 'PriceCurrent' }),
        title:         `${prefix}PriceCurrent`,
      },
      {
        accessChecked: true,
        path:          ['positions', 'Storage'],
        key:           'Storage',
        render:        this.renderNumberFixedFormat({ fieldName: 'Storage' }),
        title:         `${prefix}Storage`,
      },
      {
        accessChecked: true,
        path:          ['positions', 'Profit'],
        key:           'Profit',
        render:        this.renderNumberFixedFormat({ fieldName: 'Profit' }),
        title:         `${prefix}Profit`,
      },
      {
        accessChecked: true,
        path:          ['positions', 'Comment'],
        key:           'Comment',
        render:        this.renderInlineValue,
        title:         `${prefix}Comment`,
      },
      {
        accessChecked: true,
        path:          ['positions', 'Digits'],
        key:           'Digits',
      },

    ].map(({ title: t, xtitle: x, ...item }) => ({ ...item, ...x && { title: x }, ...t && { title: translate(t) }, }));
  }

  renderInlineValue = (value, { item, items }) => {
    const defaultValue = '-';

    if (item?.access?.('index')) {
      return value ?? defaultValue;
    }
    return defaultValue;
  };

  renderNumberFixedFormat = ({ fieldName }) => (value, { item, items }) => {
    const defaultDigits = 2;
    const defaultValue = '-';

    try {
      switch (fieldName) {
        case 'PriceOpen':
        case 'PriceSL':
        case 'PriceTP':
        case 'PriceCurrent': {
          if (item?.access?.('index')) {
            const DELTA = 3; // HARD CODE delta for this fields in MT5
            const Digits = items?.['Digits']?.valueOf ?? defaultDigits;
            const DigitsMore = Digits + DELTA;

            let fixedValue = (+value).toFixed(+DigitsMore);
            let decreaseCnt = DELTA;

            // to cut insignificant zeros after Digits position
            while (decreaseCnt) {
              decreaseCnt--;

              if (fixedValue?.[fixedValue?.length - 1] === '0') {
                fixedValue = fixedValue.slice(0, -1);
              } else {
                break;
              }
            }

            return fixedValue;
          }
          return defaultValue;
        }
        case 'Storage':
        case 'Profit':
        case 'Volume': {
          const digits = 2; // HARD CODE for this fields in MT4/MT5

          if (item?.access?.('index')) {
            return (+value).toFixed(digits);
          }
          return defaultValue;
        }
        default: {
          if (item?.access?.('index')) {
            return (+value).toFixed(defaultDigits);
          }
          return defaultValue;
        }
      }
    } catch (error) {
      throw new Error(`Error renderNumberFixedFormat( fieldName: ${ fieldName } )`);
    }
  };

  renderEnumField = (path, enumName) => (value, { item }) => {
    const defaultValue = '-';

    if (item?.access?.('index')) {
      const enums = this.props?.enums?.[path]?.[enumName];
      return enums?.[value] ?? defaultValue;
    }
    return defaultValue;
  };

  render = () => {
    const { openPositions: { data }, } = this.state;
    const options = {
      config: this.openPositionsMT5Config,
      data,
      head:   [
        'Symbol',
        'Position',
        'TimeCreate',
        'Action',
        'Volume',
        'PriceOpen',
        'PriceSL',
        'PriceTP',
        'PriceCurrent',
        'Storage',
        'Profit',
        'Comment',
      ],
    };

    return (
      <>
        <MagicFilterPanels
          mf={ this.mf }
          show={ true }
          translate={ this.props.translate }
        />

        <div className='orders-page closed-orders-page'>
          <Loader
            loaded={this.state.loaded}
            loading={(<Preloader scale={this.props.scale}/>)}
            translate={this.props.translate}
          >
            <MagicTable {...options} />

            <PagePanel
              filter={this.state.pages.filter}
              take={this.state.pages.take}
              total={this.state.pages.total}
              variance={this.state.pages.variance}
              page={this.pageId()}
              onClick={this.onPageClick}
              doText={this.onPageText}
              doTo={(pageId) => `?page=${ pageId }`}
            />
          </Loader>
        </div>
      </>
    );
  };

  pageId = () => {
    const defaultPageId = 0;

    try {
      const page = this.query().get('page');

      return Number(page) || defaultPageId;
    } catch (e) {
      return defaultPageId;
    }
  };

  pageIdAsGET = (pageId) => {
    pageId = pageId === undefined ? this.pageId() : pageId;
    const { filterId, } = this.mf;
    const result = {
      filterId,
      take: this.state.pages.take,
      skip: pageId * this.state.pages.take,
    };
    return result;
  };

  onDataLoad = async (pageId) => {
    const loaded = (data, meta, hash) => ({ pages, }) => ({
        openPositions: { data, hash },
        pages: { ...pages, ...meta, },
    });
    await this.save({ loaded: false, });
    try {
      const pageOptions = this.pageIdAsGET(pageId);
      const { id, } = this.props;
      const { data, meta, hash } = await listAccountService.tradingAccountsOpenPositionsMT5(id, pageOptions);
      const { filter, total } = meta;
      await this.save(loaded(data, { filter, total, }, hash));  // no filter data on this page => filter: total - это была временная заплатка
    } catch (error) {
      await this.save(loaded([], { filter: 0, total: 0, }, null));
      error?.showErrorNotification?.();
    }
    await this.save({ loaded: true, });
  };

  onPageClick = ({ event, pageId, pageIs, }) => {
    // event.preventDefault();
    this.onDataLoad(pageId);
  };

  onPageText = (pageId, pageIs) => {
    const { translate } = this.props;
    const { current, first, prev, next, last, } = pageIs;
    const { skipped, taken, filter, total, } = pageIs;
    if (skipped || taken) {
      const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : '?';
      const text = skipped ? translate('partners_pages_items') : '';
      return skipped ? `${ text }: ${ id }` : id;
    } else if (filter || total) {
      const id = Number.isInteger(pageId) ? `${ pageId }` : '?';
      const text = translate(filter ? 'partners_pages_filtered' : 'partners_pages_total');
      return `${ text }: ${ id }`;
    } else if (first || prev || next || last) {
      return '';
    } else if (current) {
      return `${ pageId + 1}`;
    } else {
      return `${ pageId + 1}`;
    }
  };
}

export default withRouter(withLocalize(OpenPositionsMT5));
