export const REFERRALS_LIST_EXPORT_FIELDS = {
	referrals: [
		"core-account-id",
		"core-account-name",
		"core-account-surname",
		"core-country-name",
		"core-partner-name",
		"core-partner-surname",
		"partners-referral_register-status",
		"partners-referral_register-is_partner",
		"partners-referral_register-attachment_date",
		"partners-referral_register-all_sum",
	],
};