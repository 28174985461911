import React                                       from 'react';
import { withLocalize }                            from 'react-localize-redux';
import { withRouter, }                             from 'react-router-dom';
import Loader                                      from '../../../../tima/components/Loader';
import Preloader                                   from '../../../../../components/LoadingHOC/Preloader';
import PagePanel                                   from '../../../../tima/components/Pages';
import { Mentor } from "../../../../../components/Magic";
import ListLettersMagicTable from "./ListLettersMagicTable";
import { clientService, } from "../../../../../services/ClientDataService";
import LettersPopup from "./LettersPopup";
import { enumService, } from "../../../../../services/EnumDataService";

class LeadLetters extends React.Component {
   constructor (props) {
      super(props);

      this.state = {
         enums: {
            data: {},
            hash: null,
         },
         filterLastChange: Date.now(),
         loaded: true,
         letters: {
            data: [],
            hash: null,
         },
         letterId: null,
         pages: {
            filter: 0,
            total: 0,
            take: 20,
            variance: 2,
         },
         showPopup: false,
      };

      const mf = new Mentor({
         owner: this,
         serviceId: 49,
         translate: this.props.translate,
      });

      Object.defineProperty(this, 'mf', { get: () => mf, });
   }

   save = async (state) => new Promise(next => this.setState(state, next));

   mailsChange = async (mails) => {
      if (mails.hash===this.state?.letters?.hash) return `${this.constructor.name}.mailsChange: false`;
      const { data, hash, meta: { filter, total } } = mails;
      await this.save({
         letters: { data, hash, },
         pages: {
            ...this.state.pages,
            filter,
            total,
         }
      });
      return `${this.constructor.name}.mailsChange: true`;
   };

   enumsChange = async (enums) => {
      if (enums.hash===this.state?.enums?.hash) {
         return `${ this.constructor.name }.enumsChange: false`;
      }
      await this.save({ enums, });

      return `${ this.constructor.name }.enumsChange: true`;
   };

   mfChanged = async () => {
      await this.save({ filterLastChange: Date.now(), });
   };

   mfDoing = async () => {
      await this.save({ loaded: false, });
   };

   mfDone = async () => {
      await this.mfChanged();
      await this.onDataLoad();
   };

   async componentDidMount() {
      clientService.subscribe('mails', this.mailsChange, this);
      enumService.subscribe('enums', this.enumsChange, this);
      await enumService.enums;
      this.mf.subscribe({
         changed: this.mfChanged,
         // changing: ,
         cleaned: this.mfDone,
         cleaning: this.mfDoing,
         loaded: this.mfDone,
         loading: this.mfDoing,
         saved: this.mfDone,
         saving: this.mfDoing,
      }, this);
      await this.mf.init({ doEmit: true, });
      // await this.mf.emit('change', {});
      // await this.onDataLoad(); // TODO: moved to mfDone
   }

   componentWillUnmount () {
      clientService.unsubscribe('mails', this.mailsChange, this);
      enumService.unsubscribe('enums', this.enumsChange, this);
      this.mf.unsubscribe([
         this.mfChanged,
         this.mfDoing,
         this.mfDone,
      ], this);
   }

   get params () { return this.props?.match?.params }

   get query() { return new URLSearchParams(this.props?.location?.search) }

   isEmpty = val => val === null || !(Object.keys(val) || val).length;

   pageId = () => {
      const page = this.query.get('page');
      return Number(page) || 0;
   };

   pageIdAsGET = (pageId) => {
      pageId = pageId === undefined ? this.pageId() : pageId;
      const { filterId, } = this.mf;
      const result = {
         filterId,
         take: this.state.pages.take,
         skip: pageId * this.state.pages.take,
      };
      return result;
   };

   onDataLoad = async (pageId) => {
      await this.save({loaded: false,});
      const pageOptions = this.pageIdAsGET(pageId);
      const loaded = (data, meta, hash, ) => ({pages,}) => ({
         letters: { data, hash, },
         pages: {...pages, ...meta,},
      });
      try {
         await clientService.mails(this.props.accountId, pageOptions);
      } catch (error) {
         error?.showErrorNotification?.();
         await this.save(loaded([], { filter: 0, total: 0, }, null));
      }
      await this.save({ loaded: true, });
   };

   onPageClick = ({event, pageId, pageIs}) => {
      this.onDataLoad(pageId);
   };

   onPageText = (pageId, pageIs) => {
      const {translate} = this.props;
      const {current, first, prev, next, last} = pageIs;
      const {skipped, taken, filter, total} = pageIs;
      if (skipped || taken) {
         const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : '?';
         const text = skipped ? translate('support_pages_items') : '';
         return skipped ? `${ text }: ${ id }` : id;
      } else if (filter || total) {
         const id = Number.isInteger(pageId) ? `${ pageId }` : '?';
         const text = translate(filter ? 'support_pages_filtered' : 'support_pages_total');
         return `${ text }: ${ id }`;
      } else if (first || prev || next || last) {
         return '';
      } else if (current) {
         return `${ pageId + 1 }`;
      } else {
         return `${ pageId + 1 }`;
      }
   };

   togglePopup = async (letterId = null) => {
      await this.save({
         showPopup: !this.state.showPopup,
         letterId,
      });
   };

   render() {
      const { loaded, pages, letters: { data }, showPopup, letterId, } = this.state;
      return (
         <Loader loaded={ loaded } loading={ <Preloader scale={ 1 }/> }>
            <div className="table-letters">
               {
                  showPopup && <LettersPopup
                      lettersModal = { showPopup }
                      hidePopup = { this.togglePopup }
                      letterId = { letterId }
                      emailStatusEnum = { this.state.enums?.data?.log?.LogEmailStatusEnum }
                      emailSystemEnum = { this.state.enums?.data?.log?.LogEmailSystemEnum }
                  />
               }
               <ListLettersMagicTable
                   data={data}
                   refreshData={ this.onDataLoad }
                   mf={ this.mf }
                   togglePopup={ this.togglePopup }
               />
               <PagePanel
                   showSingle={ false }
                   filter={ pages.filter }
                   take={ pages.take }
                   total={ pages.total }
                   variance={ pages.variance }
                   page={ this.pageId() }
                   onClick={ this.onPageClick }
                   doText={ this.onPageText }
                   doTo={ pageId => `?page=${ pageId }` }
               />
            </div>
         </Loader>
      );
   }
}

export default withRouter(withLocalize(LeadLetters));
