export default (
    translate,
    order = [],
    {
        active = 0,
        agentId = 0,
        conditionId = 0,
        investmentId = 0,
        strategyId = 0,
    } = {},
    permissions,
) => [
    {
        id: `agent`,
        link: `/tima/agent/${ agentId }`,
        text: `${ translate( 'tima_link_agent' ) } #${ agentId }`,
    },
    {
        id: `agents`,
        link: `/tima/agents`,
        text: translate( 'tima_link_agents' ),
    },
    {
        id: `agentsByStrategy`,
        link: `/tima/agents/strategy/${ strategyId }`,
        text: `${ translate( 'tima_link_agents_by_strategy' ) } #${ strategyId }`,
    },
    {
        id: `condition`,
        link: `/tima/condition/${ conditionId }`,
        text: `${ translate( 'tima_link_condition' ) } #${ conditionId }`,
    },
    {
        id: `conditions`,
        link: `/tima/conditions`,
        text: translate( 'tima_link_conditions' ),
    },
    {
        id: `conditionsByAgent`,
        link: `/tima/conditions/agent/${ agentId }`,
        text: `${ translate( 'tima_link_conditions_by_agent' ) } #${ agentId }`,
    },
    {
        id: `conditionsByStrategy`,
        link: `/tima/conditions/strategy/${ strategyId }`,
        text: `${ translate( 'tima_link_conditions_by_strategy' ) } #${ strategyId }`,
    },
    {
        id: `investment`,
        link: `/tima/investment/${ investmentId }`,
        text: `${ translate( 'tima_link_investment' ) } #${ investmentId }`,
    },
    {
        id: `investments`,
        link: `/tima/investments`,
        text: translate( 'tima_link_investments' ),
    },
    {
        id: `investmentsByAgent`,
        link: `/tima/investments/agent/${ agentId }`,
        text: `${ translate( 'tima_link_investments_by_agent' ) } #${ agentId }`,
    },
    {
        id: `investmentsByCondition`,
        link: `/tima/investments/condition/${ conditionId }`,
        text: `${ translate( 'tima_link_investments_by_condition' ) } #${ conditionId }`,
    },
    {
        id: `investmentsByStrategy`,
        link: `/tima/investments/strategy/${ strategyId }`,
        text: `${ translate( 'tima_link_investments_by_strategy' ) } #${ strategyId }`,
    },
    {
        id: `strategy`,
        link: `/tima/strategy/${ strategyId }`,
        text: `${ translate( 'tima_link_strategy' ) } #${ strategyId }`,
    },
    {
        id: `strategies`,
        link: `/tima/strategies`,
        text: translate( 'tima_link_strategies' ),
    },
].filter(
    ( { id } ) => order.includes( id )
).sort(
    ( { id: a, }, { id: b, } ) => order.indexOf( a ) - order.indexOf( b )
).map(
    ( { id, ...data } ) => ( {
        ...data,
        key: id,
        ...(id === active) && { active: true },
    } )
).filter(
    (item) => {
        if (permissions) {
            switch (item?.key) {
                case 'agentsByStrategy': {
                    return permissions?.['agents']?.access('getAgentsByManager') ?? false;
                }
                case 'conditionsByStrategy': {
                    return permissions?.['conditions']?.access('getConditionsByManager') ?? false;
                }
                case 'investmentsByAgent': {
                    return permissions?.['investments']?.access('getInvestmentsByAgent') ?? false;
                }
                case 'investmentsByCondition': {
                    return permissions?.['investments']?.access('getInvestmentsByCondition') ?? false;
                }
                case 'investmentsByStrategy': {
                    return permissions?.['investments']?.access('getInvestmentsByManager') ?? false;
                }
                default: {
                    return permissions?.[item?.key]?.access('index') ?? false;
                }
            }
        }
        return true;
    }
);
