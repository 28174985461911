import React, { Component } from 'react';
import NotificationService from "../../../../services/NotificationService";
import { withLocalize, Translate } from "react-localize-redux";


import { userService } from "../../../../services/UserDataService";

import '../../../../style/notification.css';
const imgUrl = "/style-images/";

class NotificationComponent extends Component {
    constructor () {
        super();

        this.state = {
            note: [],
        };

        this.configTimeOut = {
            removeTime: 4000,
        };

        this.id = 0;

        this.addNote = this.addNote.bind(this);
        this.setNote = this.setNote.bind(this);
        this.removeNote = this.removeNote.bind(this);
    }

    UNSAFE_componentWillMount () {
        NotificationService.addListener("addNotification", this.addNote());
    }

    addNote () {
        const $this = this;

        return function (notification) {
            const note = $this.state.note;

            const newNote = {
                id:          $this.id,
                type:        notification.type,
                title:       notification.title,
                message:     notification.message,
                view:        notification.view !== undefined ? notification.view : false,
                viewHTML:    notification.viewHTML !== undefined ? notification.viewHTML : '',
                viewClick:   notification.viewClick !== undefined ? notification.viewClick : () => {},
                removeTime:  notification.time !== undefined ? notification.time : $this.configTimeOut.removeTime,
                removeCheck: notification.remove !== undefined ? notification.remove : true,
                active:      false,
                rm:          false,
                removeClick: false,
                payload: notification.payload !== undefined ? notification.payload : false,
            };

            note.unshift(newNote);

            $this.setState({
                note,
            });

            $this.id++;
        };
    }

    removeNoteByClick = (id) => {
        const note = [];

        this.state.note.map((item, key) => {
            note[key] = item;

            if (note[key].id === id) {
                note[key].rm = true;
                note[key].removeClick = true;
            }
        });

        this.setState({
            note,
        });
    }

    removeNote (id) {
        const note = [];

        this.state.note.map((item, key) => {
            note[key] = item;

            if (note[key].id === id) {
                note[key].rm = true;
            }
        });

        this.setState({
            note,
        });
    }

    removeAllNote () {
        const note = [];

        this.state.note.map((item, key) => {
            note[key] = item;
            note[key].rm = true;
        });

        this.setState({
            note,
        });
    }

    setNote () {
        if (this.state.note.length > 0) {
            const noteActive = [];

            let noteRemove = [];

            const rmId = [];

            let getChangesActive = false;

            let getChangesRemove = false;

            this.state.note.map((item, key) => {
                noteActive[key] = item;

                if (!noteActive[key].active) {
                    noteActive[key].active = true;
                    getChangesActive = true;

                    rmId.push({
                        id:          noteActive[key].id,
                        removeTime:  noteActive[key].removeTime,
                        removeCheck: noteActive[key].removeCheck,
                    });
                }
            });

            if (getChangesActive) {
                for (let i = 0; i < rmId.length; i++) {
                    if (rmId[i].removeCheck) {
                        setTimeout(() => {
                            this.removeNote(rmId[i].id);
                        }, rmId[i].removeTime);
                    }
                }

                this.setState({
                    note: noteActive,
                });
            }

            setTimeout(() => {
                noteRemove = [];

                this.state.note.map((item, key) => {
                    if (!item.rm) {
                        noteRemove[key] = item;
                    } else {
                        getChangesRemove = true;
                    }
                });

                if (getChangesRemove) {
                    this.setState({
                        note: noteRemove,
                    });
                }

            }, 400);
        }
    }
    componentDidUpdate () {
        setTimeout(() => {
            this.setNote();
        }, 0);
    }
    render () {
        return (
            <Translate>
                {({translate}) => {
                    return (
                        <div style={{zIndex: 1005}}>
                            <div className = 'notification-container'>

                                {/*<button onClick={ () => { this.removeAllNote(); }}>Clear All</button>*/}

                                {this.state.note.map((item, key) => {
                                    const classRemove = item.rm ? "notification-enter-leave" : "notification-enter-active";

                                    const classNotification = item.active ? `notification notification-error ${classRemove}` : "notification notification-error";

                                    const span = item.active ? "span span-active" : "span";

                                    const styleSpan = item.removeClick ? { height: '0px' } : {};

                                    const stylenotification = item.removeClick ? { height: '0px', margin: '0px' } : {};

                                    const classClose = item.view ? "notification-close" : "notification-close-withoutView";

                                    if (item.type === 'event') {
                                        return (
                                            <span className = { span } key = { key } style = { styleSpan }>
                                                <div className = { `${classNotification} notification-type-event` } role = { item.type } style = { stylenotification }>
                                                    <div className = 'notification-icon'>
                                                        <img src={imgUrl + 'notification-type-' + item.payload.data.params.type + '.svg'} alt={item.type} />
                                                    </div>

                                                    <div className = 'notification-title'>{item.title}</div>

                                                    <div className = 'notification-message'>
                                                        {item.message}
                                                    </div>
                                                    <div className = 'notification-button'>
                                                        <div className = {classClose} onClick = { () => { this.removeNoteByClick(item.id)} }>Close</div>
                                                        { item.view ? <div onClick = { () => { this.removeNoteByClick(item.id)}} className = 'notification-view'>{item.viewHTML}</div> : "" }
                                                    </div>
                                                </div>
                                            </span>
                                        );
                                    } else {
                                        return (
                                            <span className = { span } key = { key } style = { styleSpan }>
                                                <div className = { classNotification } role = { item.type } style = { stylenotification }>
                                                    <div className = 'notification-icon'>
                                                        <img src={imgUrl + item.type + '.svg'} alt={item.type} />
                                                    </div>

                                                    <div className = 'notification-title'>{item.title ? translate(`notification_${item.title}`) : ''}</div>

                                                    <div className = 'notification-message'>
                                                        <p dangerouslySetInnerHTML={{__html: item.message}} />
                                                    </div>
                                                    <div className = 'notification-button'>
                                                        <div className = {classClose} onClick = { () => {this.removeNoteByClick(item.id)} }>Close</div>
                                                        {item.view
                                                            ?
                                                            <div className = 'notification-view' onClick={ () => {
                                                                item.viewClick();
                                                                this.removeNoteByClick(item.id);
                                                            } }>View</div>
                                                            : ""
                                                        }
                                                    </div>
                                                </div>
                                            </span>
                                        );
                                    }
                                })}
                            </div>
                        </div>
                    );
                }}
            </Translate>
        )
    }
}

export default withLocalize(NotificationComponent);
