// import { parse, differenceInMilliseconds } from "date-fns";
import moment from 'moment';

const DateTimeHelper = {

    /**
     * This function return in second time life token
     *
     * @param tokenExp
     * @returns {number}
     */
	getTokenTimeLife (tokenExp) {
		return moment.unix(tokenExp).diff(moment.now(), 'milliseconds', true);
        // const unixDate = parse(new Date()).getTime()/1000;
        // const now = parseInt(unixDate);

        // return differenceInMilliseconds(tokenExp, now);
	},

	getFormattedDate: (date, format) => {
		const formatDB = 'YYYY-MM-DD HH:mm:ss';

		const momentDate = moment(date, formatDB);

		return momentDate.isValid() && momentDate.format(format ?? formatDB);
	},
};

export default DateTimeHelper;