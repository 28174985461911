import {
  itemsBuild,
  itemsDrain,
  itemsResort,
  CFG_ITEM_TYPE,
} from '../Config';
// import {} from '../Mentor/withDropDown';

const f_taBalance = {
  'f+': [ 'core.trading_accounts.balance', ],
};

const f_taEquity = {
  'f+': [ 'core.trading_accounts.equity', ],
};

export default {
  id: 128,
  ...[

    // filters
    ...[ {
      f: 'tima.tima_manager.status',
      id: 'f:tmStatus',
      off: true, // TODO:
    }, {
      f: 'tima.tima_manager.name',
      id: 'f:tmName',
      o: 'like',
    }, {
      f: 'core.trading_accounts.acc_ccy',
      id: 'f:taCurrency',
      off: true, // TODO:
    }, {
      f: 'core.trading_accounts.balance',
      id: 'f:taBalance',
      off: true, // TODO:
      ...f_taBalance,
    }, {
      f: 'core.trading_accounts.mt_login',
      id: 'f:taMtLogin',
    }, {
      f: [ 'core.account.surname', 'core.account.name', ],
      id: 'f:aFullname',
      o: 'like',
    }, {
      f: 'core.trading_accounts.equity',
      id: 'f:taEquity',
      off: true, // TODO:
      ...f_taEquity,
    }, {
      f: 'tima.tima_manager.share',
      id: 'f:tmShare',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

    // orders
    ...[ {
      auto: 1,
      enabled: 1,
      f: 'tima.tima_manager.id',
      id: 'o:tmId',
      v: 'desc',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

    // panels
    ...[ {
      ii: 'f:tmStatus',
      off: true, // TODO:
      t: 'magic_filter_translate_tima_manager_status',
    }, {
      ii: 'f:tmName',
      t: 'magic_filter_translate_tima_manager_name',
    }, {
      ii: 'f:taCurrency',
      off: true, // TODO:
      t: 'magic_filter_translate_tima_manager_currency',
    }, {
      ii: 'f:taBalance',
      off: true, // TODO:
      t: 'magic_filter_translate_tima_manager_balance',
    }, {
      ii: 'f:taMtLogin',
      t: 'magic_filter_translate_tima_manager_mt_login',
    }, {
      ii: 'f:aFullname',
      t: 'magic_filter_translate_tima_manager_acc_name_surname',
    }, {
      ii: 'f:taEquity',
      off: true, // TODO:
      t: 'magic_filter_translate_tima_manager_equity',
    }, {
      ii: 'f:tmShare',
      t: 'magic_filter_translate_tima_manager_share',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

  ]
  .map(itemsBuild)
  .reduce(itemsDrain, [])
  .reduce(itemsResort, {}),

  path: 'tima/tima-manager',
  widget: false,
};