import {
  itemsBuild,
  itemsDrain,
  itemsResort,
  CFG_ITEM_TYPE,
} from '../Config';
// import {} from '../Mentor/withDropDown';

const fields = [
  'orders_history.Order',
  'orders_history.TimeSetup',
  'orders_history.TimeDone',
  'orders_history.Symbol',
];

const f_all = {
  'f+': fields,
  'o=': [ '=', '!=', ],
};

const o_all = {
  'f+': fields,
};

export default {
  id: 96,
  ...[

    // filters
    ...[ {
      f: 'orders_history.Order',
      id: 'f:ohOrder',
      ...f_all,
    }, {
      f: 'orders_history.TimeSetup',
      id: 'f:ohTimeSetup',
      ...f_all,
    }, {
      f: 'orders_history.TimeDone',
      id: 'f:ohTimeDone',
      ...f_all,
    }, {
      f: 'orders_history.Symbol',
      id: 'f:ohSymbol',
      ...f_all,
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

    // orders
    ...[ {
      f: '',
      id: 'o:',
      off: true, // TODO:
      ...o_all,
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

    // panels
    ...[ {
      ii: 'f:ohOrder',
      t: 'magic_filter_translate_list_accounts_tradings_mt5_closed_orders_order',
    }, {
      ii: 'f:ohTimeSetup',
      t: 'magic_filter_translate_list_accounts_tradings_mt5_closed_orders_setup_time',
    }, {
      ii: 'f:ohTimeDone',
      t: 'magic_filter_translate_list_accounts_tradings_mt5_closed_orders_done_time',
    }, {
      ii: 'f:ohSymbol',
      t: 'magic_filter_translate_list_accounts_tradings_mt5_closed_orders_symbol',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

  ]
  .map(itemsBuild)
  .reduce(itemsDrain, [])
  .reduce(itemsResort, {}),

  path: 'core/trading-accounts/get-closed-orders/1',
  widget: false,
};