import {
  itemsBuild,
  itemsDrain,
  itemsResort,
  CFG_ITEM_TYPE,
} from '../Config';
// import {} from '../Mentor/withDropDown';

export default {
  id: 140,
  ...[

    // filters
    ...[ {
      f: 'tima.tima_agents.name',
      id: 'f:taName',
      o: 'like',
    }, {
      f: [ 'core.account.surname', 'core.account.name', ],
      id: 'f:aFullname',
      o: 'like',
    }, {
      f: 'tima.tima_manager.name',
      id: 'f:tmName',
      o: 'like',
    }, {
      f: [ 'core.user.surname', 'core.user.name', ],
      id: 'f:uResponsible',
      o: 'like',
    }, {
      f: 'tima.tima_agents.status',
      id: 'f:taStatus',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

    // orders
    ...[ {
      f: '',
      id: 'o:',
      off: true, // TODO:
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

    // panels
    ...[ {
      ii: 'f:taName',
      t: 'magic_filter_translate_tima_agent_name',
    }, {
      ii: 'f:aFullname',
      t: 'magic_filter_translate_tima_agent_account',
    }, {
      ii: 'f:tmName',
      t: 'magic_filter_translate_tima_agent_manager_name',
    }, {
      ii: 'f:uResponsible',
      t: 'magic_filter_translate_tima_agent_user',
    }, {
      ii: 'f:taStatus',
      t: 'magic_filter_translate_tima_agent_status',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

  ]
  .map(itemsBuild)
  .reduce(itemsDrain, [])
  .reduce(itemsResort, {}),

  path: 'tima/tima-agents',
  widget: false,
};