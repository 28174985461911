import React from 'react';
import * as PropTypes from 'prop-types';
import { MagicSelect, } from '../../../../../MagicSelect';

export class MagicFilterInputEnum extends React.Component {
	static propTypes = {
		disabled: PropTypes.bool.isRequired,
		filter: PropTypes.object.isRequired,
	};

	get filter() {
		return this.props.filter;
	}

	get mentor() {
		return this.filter.mentor;
	}

	valueToLabel = (value) => this.filter.enums[value] ?? '-';

	ItemProps = (value) => ({});

	onChange = async (value) => this.mentor
		.filterChange({
			id: this.filter.$id,
			setter: { v: value===false ? null : value, },
			$$changed: true,
		});

	render = () => (<MagicSelect
		className={[
			'magic-filter-input-enum',
			'magic-select__filter-input-enum',
		]}
		disabled={ this.props.disabled }
		value={ this.filter.value }
		valueDefault={ false }
		values={ Object.keys(this.filter.enums) }
		label={ this.valueToLabel }
		valueToLabel={ this.valueToLabel }
		ItemProps={ this.ItemProps }
		onChange={ this.onChange }
	/>);
};
