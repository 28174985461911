import {
  itemsBuild,
  itemsDrain,
  itemsResort,
  CFG_ITEM_TYPE,
} from '../Config';
import { onEnabled, render, } from '../Mentor/withDropDown';

const f_uId = {
  'drop-down': '/api/core/user/drop-down?take=all',
  'o=': [ '=', '!=', ],
};

const p_uId = {
  onEnabled: onEnabled('users'),
  render: render(
    'users',
    _ => _ ? `${ _?.name } ${ _?.surname }` : '-',
  ),
};

export default {
  id: 48,
  ...[

    // filters
    ...[ {
      f: 'core.user.id',
      id: 'f:uId',
      ...f_uId,
    }, {
      f: 'core.user.created_at',
      id: 'f:uCreated',
    }, {
      f: [ 'core.user.surname', 'core.user.name', ],
      id: 'f:uFullname',
      o: 'like',
    }, {
      f: 'core.user.role_id',
      id: 'f:uDepartment',
      off: true, // TODO:
    }, {
      f: 'core.user.role_id',
      id: 'f:uRoleId',
      off: true, // TODO:
    }, {
      f: 'core.user.email',
      id: 'f:uEmail',
    }, {
      f: 'core.user.phone',
      id: 'f:uPhone',
    }, {
      f: 'core.user.status',
      id: 'f:uStatus',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

    // orders
    ...[ {
      f: 'core.user.id',
      id: 'o:uId',
    }, {
      f: 'core.user.created_at',
      id: 'o:uCreated',
    }, {
      f: 'core.user.surname',
      id: 'o:uSurname',
    }, {
      f: 'core.user.email',
      id: 'o:uEmail',
    }, {
      f: 'core.user.phone',
      id: 'o:uPhone',
    }, {
      f: 'core.user.status',
      id: 'o:uStatus',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

    // panels
    ...[ {
      ii: 'f:uId',
      off: true, // TODO:
      t: 'magic_filter_translate_user_fullname',
      ...p_uId,
    }, {
      ii: 'f:uFullname',
      t: 'magic_filter_translate_user_fullname',
    }, {
      ii: 'f:uDepartment',
      off: true, // TODO:
      t: 'magic_filter_translate_user_department',
    }, {
      ii: 'f:uRoleId',
      off: true, // TODO:
      t: 'magic_filter_translate_user_role',
    }, {
      ii: 'f:uEmail',
      t: 'magic_filter_translate_user_email',
    }, {
      ii: 'f:uStatus',
      t: 'magic_filter_translate_user_status',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

  ]
	.map(itemsBuild)
	.reduce(itemsDrain, [])
	.reduce(itemsResort, {}),

  path: 'core/user',
  widget: false,
};