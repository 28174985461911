import React, { Component } from "react";
import Loader            from "../../tima/components/Loader";
import Preloader         from "../../../components/LoadingHOC/Preloader";
import PagePanel         from "../../tima/components/Pages";
import propTypes         from 'prop-types'
import PermissionService from "../../../services/PermissionService";
import { Link }          from "react-router-dom";
import { MagicTooltip }  from "../../tima/components/Magic/MagicTooltip";
import Moment            from "moment";
import { MagicTable }    from "../../../components/Magic/components/MagicTable";
import { contestService } from "../../../services/ContestDataService"

export class ContestsTable extends Component {

  static defaultProps = {
    enums: {},
    params: {},
  };

  static propTypes = {
    enums: propTypes.object,
    lang: propTypes.string,
    mf: propTypes.object.isRequired,
    translate: propTypes.func.isRequired,
  };

  constructor ( props ) {
    super( props );
    this.state = {
       contests: {
          hash: null,
          data: [],
       },
      filterLastChange: Date.now(),
      loaded: true,
      pages: {
        filter: 0,
        take: 10,
        total: 0,
        variance: 2,
      },
    };

    Object.defineProperty(this, 'mf', { get: () => this.props.mf, });
  }

  // eslint-disable-next-line react/sort-comp, require-await
  save = async state => new Promise(next => this.setState(state, next));

  // eslint-disable-next-line require-await
  mfChanged = async () => {
    await this.save({ filterLastChange: Date.now(), });
  };

  mfDoing = async () => {
    await this.save({ loaded: false, });
  };

  mfDone = async () => {
    await this.mfChanged();
    await this.onDataLoad();
  };

   contestsChanges = async (contests) => {
      if (contests.hash===this.state?.contests?.hash) return `${this.constructor.name}.contestsChanges: false`;
      await this.save({
         contests,
         pages: {
            ...this.state.pages,
            filter: contests?.meta?.filter,
            total: contests?.meta?.total,
         }
      });
      return `${this.constructor.name}.contestsChanges: true`;
   };

  async componentDidMount () {
     contestService.subscribe('contests', this.contestsChanges, this);
    this.mf.subscribe({
      changed: this.mfChanged,
      // changing: ,
      cleaned: this.mfDone,
      cleaning: this.mfDoing,
      loaded: this.mfDone,
      loading: this.mfDoing,
      saved: this.mfDone,
      saving: this.mfDoing,
    }, this);
    await this.mf.init({ doEmit: true, });
    // await this.mf.emit('change', {});
    // await this.onDataLoad(); // TODO: moved to mfDone
  }

  componentWillUnmount () {
    this.mf.unsubscribe([
      this.mfChanged,
      this.mfDoing,
      this.mfDone,
    ], this);
     contestService.unsubscribe('contests', this.contestsChanges, this);
  }

  pageId = () => {
    const {query} = this.props;
    const page = query.get( 'page' );
    return Number( page ) || 0;
  };

  pageIdAsGET = (pageId) => {
    pageId = pageId === undefined ? this.pageId() : pageId;
    const { filterId, } = this.mf;
    const result = {
      filterId,
      take: this.state.pages.take,
      skip: pageId * this.state.pages.take,
    };
    return result;
  };

  async onDataLoad (pageId) {
    await this.save({ loaded: false, });
    const pageOptions = this.pageIdAsGET(pageId);
    const loaded = (data, meta, hash) => ({ pages, }) => ({
      contests: {data, hash, },
      pages: { ...pages, ...meta, },
    });
    try {
      const response = await contestService.contests(pageOptions);
      const { data, meta: { filter = 0, total = 0, }, hash} = response;
      await this.save(loaded(data, { filter, total, }, hash));
    } catch (error) {
      await this.save(loaded([], { filter: 0, total: 0, }, null));
    }
    await this.save({ loaded: true, });
  }

  onPageClick = ( {event, pageId, pageIs} ) => {
    this.onDataLoad( pageId );
  };

  onPageText = ( pageId, pageIs ) => {
    const {translate} = this.props;
    const {current, first, prev, next, last} = pageIs;
    const {skipped, taken, filter, total} = pageIs;
    if ( skipped || taken ) {
      const id = Number.isInteger( pageId ) ? `${pageId + 1}` : '?';
      const text = skipped ? translate( 'contests_pages_items' ) : '';
      return skipped ? `${text}: ${id}` : id;
    } else if ( filter || total ) {
      const id = Number.isInteger( pageId ) ? `${pageId}` : '?';
      const text = translate( filter ? 'contests_pages_filtered' : 'contests_pages_total' );
      return `${text}: ${id}`;
    } else if ( first || prev || next || last ) {
      return '';
    } else if ( current ) {
      return `${pageId + 1}`;
    } else {
      return `${pageId + 1}`;
    }
  };

  get contestsConfig() {
    const { translate } = this.props;
    return [ {
      path: ['contests', 'contests', 'id'],
      key: 'contestId',
      render: this.renderContestId,
      title: 'contests_list_id',
    }, {
      path: ['contests', 'contests_translations', 'name'],
      key: 'contestName',
      render: this.renderContestName,
      title: 'contests_list_title',
    }, {
      path: ['contests', 'contests', 'start_reg_date'],
      key: 'contestRegStart',
      render: this.renderContestDate,
      title: 'contests_list_start_reg_date',
    }, {
      path: ['contests', 'contests', 'finish_reg_date'],
      key: 'contestRegFinish',
      render: this.renderContestDate,
      title: 'contests_list_finish_reg_date',
    }, {
      path: ['contests', 'contests', 'date_start'],
      key: 'contestStart',
      render: this.renderContestDate,
      title: 'contests_list_date_start',
    }, {
      path: ['contests', 'contests', 'date_end'],
      key: 'contestEnd',
      render: this.renderContestDate,
      title: 'contests_list_date_end',
    }, {
      path: ['contests', 'contests', 'prize_fund'],
      key: 'contestPrizeFund',
      render: this.renderContestPrizeFund(this.props.lang),
      title: 'contests_list_prize_fund',
    }, {
      path: ['contests', 'contests', 'prize_count'],
      key: 'contestPrizeCount',
      render: this.renderContestPrizeCount,
      title: 'contests_list_prize_count',
    }, {
      path: ['contests', 'contests_to_participants_categories', 'account_category'],
      key: 'contestCategoriesMembers',
      render: this.renderContestCategoriesMembers,
      title: 'contests_list_participants_categories',
    }, {
      path: ['contests', 'contests', 'status'],
      key: 'contestStatus',
      render: this.renderContestStatus,
      title: 'contests_list_status',
    }, ].map(({title: t, ...data}) => ({...data, ...t && {title: translate(t)},}));
  }

  setClassToItem = value => {
    const categoryClassess = {
      "1": "bg-violet-blue",
      "2": "bg-shadow-blue",
      "3": "bg-grass-green",
      "4": "bg-deep-blue",
      "5": "bg-pink",
      "6": "bg-deep-yellow",
      "7": "bg-red-orange",
    };
    return categoryClassess[value];
  };

  renderContestLink = (id, text) => (<Link to={`/contests/contest/${+id}`}>{text ?? "-"}</Link>);

  renderContestId = (id, { item, }) => {
    if (item?.access('show', 'index')) {
      return this.renderContestLink(+id, id);
    } else if (item?.access('index')) {
      return +id ?? "-";
    }
    return '-';
  };

  renderContestName = (name, { item, items, }) => {
    const options = {
      content: name ?? "-",
      lengthCut: 50,
    };
    const text = (<MagicTooltip {...options} />);
    if (items?.contestId?.access('show') && item?.access('index')) {
      return this.renderContestLink(+items?.contestId?.valueOf, text);
    } else if (item?.access('index')) {
      return text;
    }
    return '-';
  };

  renderContestDate = (date, { item, }) => {
    if (item.access('index')) {
      const formatDB = 'YYYY-MM-DD HH:mm:ss';
      const formatOut = 'YYYY-MM-DD';
      date = Moment(date, formatDB);
      return date.isValid() ? date.format(formatOut) : "-";
    }
    return '-';
  };

  renderContestPrizeFund = (lang) => (prize, { item, }) => {
    if (item.access('index')) {
      prize = Number(prize)?.toFixed?.();
      return lang === "en" ? `${prize}$` : `$${prize}`;
    }
    return '-';
  };

  renderContestPrizeCount = (count, { item, }) => {
    if (item.access('index')) {
      return (<span className={`${this.setClassToItem(1)} color_items`}>{count ?? "-"}</span>);
    }
    return '-';
  };

  renderContestCategoriesMembers = (categories, { item, items, }) => {
    if (item.access('index')) {
      const enums = this.props?.enums?.core?.AccountCategoryEnum;
      const categoryToLabel = category => {
        return enums?.[+category] ?? "-";
      };
      return categories?.map?.((category, index) => (
        <span
          key={`${index}:${category}`}
          className={`color_items ${this.setClassToItem(category)}`}
        >
          {categoryToLabel(category)}
        </span>
      ));
    }
    return '-';
  };

  renderContestStatus = (status, { item, }) => {
    if (item.access('index')) {
      return this.props?.enums?.contests?.ContestsStatusEnum?.[+status] ?? "-";
    }
    return '-';
  };

  renderContests = () => {
    const options = {
        config: this.contestsConfig,
        data: this.state?.contests?.data,
        head: [
          "contestId",
          "contestName",
          "contestStart",
          "contestEnd",
          "contestRegStart",
          "contestRegFinish",
          "contestPrizeFund",
          "contestPrizeCount",
          "contestCategoriesMembers",
          "contestStatus",
        ],
        // CellProps: ({ index, head, item, items }) => {
        //    console.log( index, head, item, items )
        // },
        RowProps: ({ items, }) => {
          return {
            "data-disabled": ["5", 5].includes(items?.contestStatus?.valueOf),
          }
        },
    };
    return (<MagicTable {...options} />);
  };

   render () {
      const {loaded, pages} = this.state;

    return (
      <div className='contests__table table-wrapper'>
        <Loader loaded={loaded} loading={( <Preloader scale={1}/> )}>
            <div className='table bordered-rounded'>{this.renderContests()}</div>
            <PagePanel
              showSingle={false}
              filter={pages.filter}
              take={pages.take}
              total={pages.total}
              variance={pages.variance}
              page={this.pageId()}
              onClick={this.onPageClick}
              doText={this.onPageText}
              doTo={( pageId ) => `?page=${pageId}`}
            />
        </Loader>
      </div>
    );
  }
};
