const permissions = [
	//System Accounts
	{
		path: [ "core", "system_accounts", ],
		key: "list_accounts_system",
	},
	//Trading Accounts
	{
		path: [ 'core', 'trading_accounts', ],
		key: "list_accounts_trading",
	},
	//Corporate Actions
	{
		path: [ 'corporateaction', 'corporate_action', ],
		key: "list_accounts_corporate_action",
	},
	//Balance Operations
	{
		path: [ 'core', 'system_accounts_transaction_history', ],
		key: "balance_operations",
	},
];

export default permissions;