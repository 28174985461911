import LinkConfig from "./LinkConfig";

export const breadcrumbs4CalculationPayoutDashboard = (translate) => {
	// eslint-disable-next-line new-cap
	return LinkConfig(
		translate,
		[
			"dashboard",
			"partners",
			"calculations-dashboard",
			"payout-dashboard",
		],
		{},
	);
};