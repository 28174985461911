import React, { Component } from 'react';
import { withLocalize } from "react-localize-redux";
import PropTypes from "prop-types";
import Preloader from "../../../../../components/LoadingHOC/Preloader";
import PermissionService from "../../../../../services/PermissionService";
import Loader from "../../../../tima/components/Loader";
import { MagicConfirm } from "../../../../tima/components/Magic/MagicConfirm";
import { postAccountSumsubResetVerify } from "../../../services/AccountService";

class PopUpVerificationReset extends Component {
    static propTypes = {
        popUpReVerificationOpen: PropTypes.func.isRequired,
        popUpClose:              PropTypes.func.isRequired,
        refreshClientData:       PropTypes.func.isRequired,
    };

    constructor (props) {
        super(props);

        this.state = {
            loaded:                false,
            loadedInnerRequest:    true,
            refreshClientDataFlag: false,
            isMagicConfirmVisible: {
                reset: false,
            },
            isResetSuccess:        false,
        };

        this.calcData = PermissionService.calc(this.PopUpVerificationConfigReset);
    }

    get PopUpVerificationConfigReset () {
        return [
            {
                path: ['core', 'account'],
                key:  'coreAccount',
            },
        ];
    }

    save = async (state) => new Promise(next => this.setState(state, next));

    componentDidMount = async () => {
        await this.save({ loaded: false, });
        await this.onDataLoad();
        await this.save({ loaded: true, });
    };

    componentWillUnmount = () => {

    };

    popUpCloseFunc = () => {
        const { refreshClientDataFlag = false } = this.state;
        const { popUpClose, refreshClientData } = this.props;

        refreshClientDataFlag && refreshClientData();
        popUpClose();
    };

    onDataLoad = async () => {
        try {

        } catch (error) {

        }
    };

    onNextStepBtnClick = () => {
        const { popUpReVerificationOpen } = this.props;

        // TODO: check the logic!
        this.popUpCloseFunc();
        popUpReVerificationOpen({ eventName: 'onNextStepBtnClick' });
    };

    onConfirmClick = (action) => async (event) => {
        const { accountId } = this.props;

        this.closeAllMagicConfirm();
        await this.save({ loadedInnerRequest: false, });

        try {
            let postFunc = null;

            switch (action) {
                case 'reset': {
                    postFunc = postAccountSumsubResetVerify;
                    break;
                }
                default: {
                    throw new Error(`no necessary post request for action: '${action}' !`);
                }
            }

            const response = await postFunc(accountId);

            if (response?.error) {
                throw new Error(response.error);
            }

            await this.save({ isResetSuccess: true, });
        } catch (error) {
            await this.save({ isResetSuccess: false, });
            error?.showErrorNotification?.();
        }

        await this.save({ loadedInnerRequest: true, });
    };

    closeAllMagicConfirm = async () => {
        const { isMagicConfirmVisible } = this.state;

        for (const key in isMagicConfirmVisible) {
            isMagicConfirmVisible[key] = false;
        }

        await this.save({ isMagicConfirmVisible });
    };

    onActionBtnClick = (actionName) => async (event) => {
        const { isMagicConfirmVisible } = this.state;

        for (const key in isMagicConfirmVisible) {
            isMagicConfirmVisible[key] = key === actionName;
        }

        await this.save({ isMagicConfirmVisible });
    };

    confirmRejectHandler = (actionName) => (event) => {
        this.closeAllMagicConfirm();
    };

    renderPopUpHeader = () => {
        const { translate, } = this.props;

        return (
            <div className='pop-up-header cont cont--with-border-bottom'>

                <h3 className='pop-up__name'>
                    {`${translate('client_verification_reset_title')}`}
                </h3>

                <i className='gl-icon close-btn close-btn--big' onClick={this.popUpCloseFunc}/>
            </div>
        );
    };

    renderInfoText = () => {
        const { translate, } = this.props;
        const { isResetSuccess } = this.state;
        const infoTextMap = ['warning', 'success'];

        return (
            <div className='cont'>
                <p className='cont__text cont__text--align-center'
                   dangerouslySetInnerHTML={{ __html: translate(`client_verification_reset_text_${infoTextMap?.[+isResetSuccess]}`) }}
                />
            </div>
        );
    };

    renderBtns = () => {
        const { translate } = this.props;
        const { isMagicConfirmVisible, isResetSuccess } = this.state;
        const permission = this.calcData?.coreAccount?.access('sumSubResetAccount');
        const btnClassName = permission ? '' : 'gl-btn--disabled';
        const { reset: isResetConfirmVisible, } = isMagicConfirmVisible;
        const actionNameReset = 'reset';

        return (
            <div className='btns-cont'>
                {isResetSuccess ? (
                    <button className={`gl-btn gl-btn--blue next-step-btn ${btnClassName}`}
                            onClick={this.onNextStepBtnClick}
                    >
                        {translate('client_verification_reset_next_step_btn')}
                    </button>
                ) : (
                    <div className="magic-confirm-wrapper">
                        <MagicConfirm
                            className={'magic-confirm magic-confirm--right'}
                            onAccept={this.onConfirmClick(actionNameReset)}
                            onReject={this.confirmRejectHandler(actionNameReset)}
                            title={translate(`magic_confirm_title`)}
                            accept={translate(`magic_confirm_yes`)}
                            reject={translate(`magic_confirm_no`)}
                            isVisible={!!isResetConfirmVisible}
                        />

                        <button className={`gl-btn gl-btn--blue verification-reset-btn ${btnClassName}`}
                                onClick={this.onActionBtnClick(actionNameReset)}
                        >
                            {translate('client_verification_reset_btn')}
                        </button>
                    </div>
                )}
            </div>
        );
    };

    render () {
        const { loaded, loadedInnerRequest, } = this.state;

        return (
            <Loader
                loaded={loaded}
                loading={(<Preloader className='loaderUniversal--fixed-pos' scale={1}/>)}
            >
                <div className='glalex-styles partners icon-styles'>
                    <div className='pop-up pop-up--verification-reset pop-up--active'>

                        {!loadedInnerRequest ?
                            (
                                <Preloader className='loaderUniversal--fixed-pos' scale={1}/>
                            ) : null
                        }

                        <div className='pop-up-wrapper scroll'>
                            {this.renderPopUpHeader()}

                            <div className='pop-up-content'>
                                {this.renderInfoText()}

                                {this.renderBtns()}
                            </div>
                        </div>
                    </div>
                </div>
            </Loader>
        );
    }
}

export default withLocalize(PopUpVerificationReset);
