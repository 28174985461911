import React from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './styles.scss';


const PageLink = ( props ) => {
    const { class4link, } = props;
    const { disabled, onClick, doText, doTo, options, } = props;
    const { id, is, } = options;
    const click = disabled ? {
        onClick: ( event ) => event.preventDefault(),
    } : onClick ? {
        onClick: ( event ) => onClick( { event, pageId: id, pageIs: is } ),
    } : { };
    const to = { to: doTo ? doTo( id, is ) : `#${ id }` };
    const text = ( id, is ) => {
        const { current, first, prev, next, last, } = is;
        if ( doText ) {
            return doText( id, is );
        } else if ( first || prev || next || last ) {
            return '';
        } else if ( current ) {
            return `${ id + 1 }`;
        } else {
            return `${ id + 1 }`;
        }
    };
    return (
      <Link
        { ...{
            className: class4link,
            ...click,
            ...to,
        } }
      >
          { text( id, is ) }
      </Link>
    );
};


const propTypesPageClick = PropTypes.func;
const propTypesPageId = ( value, valueEmpty = false ) => ( props, key, componentName ) => {
    if ( valueEmpty && props[ key ]===undefined ) return;
    if ( Number.isInteger( props[ key ] ) && props[ key ]>=value ) return;
    return new Error( `Invalid prop [${ key }: ${ props[ key ] }] supplied to ${ componentName }. Validation failed.` );
};
const propTypesPageIs = PropTypes.shape( {
    current: PropTypes.bool,
    first: PropTypes.bool,
    prev: PropTypes.bool,
    next: PropTypes.bool,
    last: PropTypes.bool,
} );
const propTypesPageOptions = PropTypes.exact( {
    id: propTypesPageId( 0, false ),
    is: propTypesPageIs,
} );
const propTypesPageText = PropTypes.func;
const propTypesPageTo = PropTypes.func;


PageLink.propTypes = {
    class4link: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: propTypesPageClick,
    doText: propTypesPageText,
    doTo: propTypesPageTo,
    options: propTypesPageOptions,
};


PageLink.defaultProps = {
    class4link: `page__link`,
    disabled: false,
};


export default PageLink;
export {
    propTypesPageClick,
    propTypesPageId,
    propTypesPageIs,
    propTypesPageOptions,
    propTypesPageText,
    propTypesPageTo,
};
