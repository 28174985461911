import LinkConfig from "./LinkConfig";

export const breadcrumbs4ReferralsSwitchingDashboard = (translate) => {
	// eslint-disable-next-line new-cap
	return LinkConfig(
		translate,
		[
			"dashboard",
			"partners",
			"affiliate-programs-management",
			"calculation-dashboard",
			"referrals-switch",
		],
		{},
	);
};