export const clientLang = {
	"client_processed": [ "Processing", "Обработка", "Опрацювання", ],
	"client_client_status": [ "Client status", "Статус клиента", "Статус клієнта",],
	"client_corporate_position": [ "account administrator", "администратор аккаунта", "адміністратор акаунту", ],
	"client_warm_type": [ "Warmth type", "Признак теплоты", "Ознака теплоти", ],
	"client_clients": [ "Clients", "Клиенты", "Клієнти", ],
	"client_processed_popup": [ "Processed", "Обработанный", "Опрацьований", ],
	"client_awaiting_popup": [ "Awaiting", "Ожидает", "Очікує", ],
	"client_overdue_popup": [ "Overdue", "Просроченный", "Прострочений", ],
	"client_count": [ "Clients count", "Кол-во клиентов", "Кількість клієнтів", ],
	"client_create_new": [ "Create new", "Создать новый", "Створити новий", ],
	"client_export_to_excel": [ "Export to Excel", "Экспорт в Excel", "Експорт до Excel", ],
	"client_export_to_excel_type_file_error": [ "The type of file must be: xlsx", "Тип файла должен быть: xlsx", "Тип файлу повинен бути: xlsx", ],
	"client_filters": [ "Filters", "Фильтры", "Фільтри", ],
	"client_id": [ "ID", "ID", "ID", ], // "ИД", "IД"
	"client_description_last_updated_at": [ "Last date of comments", "Дата последнего комментария", "Дата останнього коментаря", ],
	"partner_id": [ "PartnerID", "ПартнерID", "ПартнерID", ], // "ИД", "IД"
	"client_name": [ "Name", "Имя", "Ім'я", ],
	"client_first_name": [ "First Name", "Имя", "Ім'я", ],
	"client_last_name": [ "Last Name", "Фамилия", "Прізвище", ],
	"client_full_name": [ "Full Name", "ФИО", "ПІБ", ],
	"client_email": [ "E-mail", "Эл. почта", "Ел. пошта", ],
	"client_phone": [ "Phone", "Телефон", "Телефон", ],
	"client_messenger": [ "Messenger", "Телефон", "Телефон", ],
	"client_apply": [ "Apply", "Применить", "Застосувати", ],
	"client_more_filters": [ "More Filters", "Дополнительные фильтры", "Додаткові фільтри", ],
	"client_reset": [ "Reset", "Сброс", "Скасувати", ],
	"client_selected": [ "Selected", "Выбранный", "Вибрано", ],
	"client_created_at": [ "Date", "Дата", "Дата", ],
	"client_category_date_changed": [ "Category change date", "Дата изменения категории", "Дата зміни категорії", ],
	"warm_type_last_change": [ "Warmth type change date", "Дата изменения теплоты", "Дата зміни теплоти", ],
	"client_status": [ "Status", "Статус", "Cтатус", ],
	"client_country": [ "Country", "Страна", "Країна", ],
	"client_assigned_to": [ "Assigned to", "Назначен", "Призначений", ],
	"client_assigned_to_responsible": [ "Responsible", "Ответственный", "Відповідальний", ],
	"client_type": [ "Category", "Категория", "Категорія", ],
	"author_of_last_description" : ["Author of last comment", "Автор последнего комментария", "Автор останнього коментаря",],
	"last_description": [ "Last comment", "Последний комментарий", "Останній коментар", ],
	"client_document_side": [ "Side", "Сторона", "Бік", ],
	"client_system_type": [ "System type", "Тип клиента", "Тип клієнта", ],
	"client_first": [ "First", "Начало", "Початок", ],
	"client_last": [ "Last", "Последняя", "Остання", ],
	"client_shown": [ "Show", "Отображено", "Відображено", ],
	"client_from": [ "from", "из", "з", ],
	"client_lead": [ "Lead", "Лид", "Лід", ],
	"client_client": [ "Client", "Клиент", "Клієнт", ],
	"client_action": [ "Action", "Действия", "Дії", ],
	"client_change": [ "Change", "Изменен", "Змінений", ],
	"client_theme": [ "Theme", "Тема", "Тема", ],
	"client_condition": [ "Condition", "Состояние", "Стан", ],
	"client_active": [ "Active", "Активный", "Активний", ],
	"client_blocked": [ "Blocked", "Заблокирован", "Заблокований", ],
	"client_inactive": [ "Inactive", "Неактивный", "Неактивний", ],
	"client_action_type": [ "Action type", "Тип действия", "Тип дії", ],
	"client_create_task": [ "Create task", "Создать задачу", "Створити завдання", ],
	"client_create_quick_task": [ "Quick task", "Быстрая задача", "Швидка задача", ],
	"client_snap_as_duplicate": [ "Mark as duplicate", "Пометить как дубликат", "Позначити як дублікат", ],
	"client_send_message": [ "Send message", "Отправить сообщение", "Відправити повідомлення", ],
	"client_add": [ "Add", "Добавить", "Додати", ],
	"client_add_phone": [ "Add phone number", "Добавить номер телефона", "Додати номер телефону", ],
	"client_change_phone": [ "Change phone number", "Изменить номер телефона", "Змінити номер телефону", ],
	"client_verify_phone": [ "Verify phone number", "Верифицировать номер телефона", "Верифікувати номер телефону", ],
	"client_verify_phone_content": [ "Do you want to verify the phone number", "Вы хотите подтвердить номер телефона", "Ви хочете підтвердити номер телефону", ],
	"client_add_email": [ "Add E-Mail", "Добавить E-Mail", "Додати Ел.пошту", ],
	"client_verify_email": [ "Verify E-mail", "Верифицировать E-mail", "Верифікувати Ел.пошту", ],
	"client_verify_email_content": [ "Do you want to verify email", "Вы хотите подтвердить email", "Ви хочете підтвердити email", ],
	"clients_change_email": [ "Change E-mail", "Изменить E-mail", "Змінити Ел.пошту", ],
	"client_add_resend-reg-email": [ "Resend registration mail", "Переслать регистрационное письмо", "Переслати реєстраційний лист", ],
	"client_delete": [ "Delete", "Удалить", "Видалити", ],
	"client_history_of_changes": [ "History Сhanges", "История изменений", "Історія змін", ],
	"client_edit": [ "Edit", "Редактировать", "Редагувати", ],
	"client_change_warmth": [ "Change warm type", "Изменить теплоту", "Змінити теплоту", ],
	"client_birth_date": [ "Birth Date", "Дата рождения", "День народження", ],
	"client_category": [ "Сategory", "Категория", "Категорія", ],
	"client_personal_manager": [ "Personal Manager", "Персональный менеджер", "Персональний менеджер", ],
	"client_sign_of_warmth": [ "Sign of warmth", "Признак теплоты", "Ознака теплоти", ],
	"client_status_document": [ "Status", "Статус", "Статус", ],
	"client_customer_label": [ "Customer label", "Метка клиента", "Мітка клієнта", ],
	"client_customer_type": [ "Interests", "Интересы", "Інтереси", ],
	"client_customer_broker": [ "Brokers", "С каким брокером сотрудничает", "З яким брокером співпрацює", ],
	"client_customer_lifestyle": [ "Lifestyle", "Стиль жизни", "Стиль життя", ],
	"client_customer_nationality": [ "Сitizenship", "Гражданство", "Громадянство", ],
	"client_customer_religion": [ "Religion", "Религия", "Релігія", ],
	"client_customer_messenger": [ "Messengers", "Мессенджер", "Месенджер", ],
	"client_customer_social_network": [ "Social Networks", "Социальные сети", "Соціальні мережі", ],
	"client_type_client": [ "Сlient type", "Тип клиента", "Тип клієнта", ],
	"client_sub_status": [ "Result of last contact", "Результат последнего контакта", "Результат останнього контакту", ],
	"client_accounts": [ "Accounts", "Счета", "Рахунки", ],
	"client_customer_data": [ "Client data", "Данные клиента", "Дані клієнта", ],
	"client_interaction": [ "Interaction", "Взаимодействие", "Взаємодія", ],
	"client_payments_and_withdrawals": [ "Deposits and withdrawals", "Платежи и снятия средств", "Поповнення та виведення коштів", ],
	"client_appeals": [ "Tasks", "Задачи", "Завдання", ],
	"client_activity": [ "Activity", "Активность", "Активність", ],
	"client_letters": [ "Letters", "Письма", "Листи", ],
	"client_support": [ "Support", "Поддержка", "Підтримка", ],
	"client_tima": [ "TIMA", "TIMA", "TIMA", ],
	"client_contact_information": [ "Contact information", "Контакты", "Контактна інформація", ],
	"client_manager_nickname": [ "Manager nickname", "Псевдоним менеджера", "Псевдонім менеджера", ],
	"client_manager_email": [ "Manager e-mail", "E-mail менеджера", "E-mail менеджера", ],
	"client_date_of_creation": [ "Date created", "Дата создания", "Дата створення", ],
	"client_date_of_change": [ "Date last modified", "Дата последнего изменения", "Дата останньої зміни", ],
	"client_library_card": [ "Library card", "Библиотечная карта", "Бібліотечна карта", ],
	"client_gender": [ "Gender", "Пол", "Стать", ],
	"client_license": [ "License", "Лицензия", "Ліцензія", ],
	"client_time_zone": [ "Time zone", "Часовой пояс", "Часовий пояс", ],
	"client_tin": [ "Tin", "ИНН", "ІПН", ],
	"client_address": [ "Address", "Адрес", "Адреса", ],
	"client_archive": [ "Archive", "Архивировать", "Архівувати", ],
	"client_unzip": [ "Unarchive", "Разархивировать", "Розархівувати", ],
	"client_warming": [ "Warming", "Утепление", "Утеплення", ],
	"client_have_demo": [ "Have Demo", "Демо", "Демо", ],
	"client_brand_lawyer": [ "Brand Lawyer", "Юрист бренда", "Бренд-юрист", ],
	"client_brand_loyal": [ "Loyal", "Лояльный", "Лояльний", ],
	"client_brand_vip": [ "VIP", "VIP", "VIP", ],
	"client_no": [ "No", "Нет", "Нi", ],
	"client_yes": [ "Yes", "Да", "Так", ],
	"client_result": [ "Result", "Результат", "Результат", ],
	"client_tail_table": [ "UTM marks", "Хвосты", "Хвіст", ],
	"client_tail_customer_path": [ "Customer path", "Путь клиента", "Шлях клієнта", ],
	"client_transition": [ "Transition", "Переход", "Перехід", ],
	"client_date": [ "Date", "Дата", "Дата", ],
	"client_documents": [ "Documents", "Документы", "Документи", ],
	"client_file_name": [ "File name", "Имя файла", "Ім'я файлу", ],
	"client_verification_status": [ "Verification status", "", "", ],
	"client_upload_data": [ "Upload data", "Загрузить данные", "Завантажити дані", ],
	"client_under_call": [ "Missed call", "Недозвон", "Не додзвонились", ],
	"client_training": [ "Training", "Обучение", "Навчання", ],
	"client_under_years_old": [ "Under 18 years old", "До 18 лет", "До 18 років", ],
	"client_thinks": [ "Thinks", "Думает", "Думає", ],
	"client_renouncement": [ "Renouncement", "Отказ", "Відмова", ],
	"client_replanishment": [ "Replanishment", "Пополнение", "Поповнення", ],
	"client_double": [ "Double", "Дубль", "Дубль", ],
	"client_work_with_other_companies": [ "Deal with another company", "Работает с другими компаниями", "Працює з іншими компаніями", ],
	"client_novice_trader": [ "Junior trader", "Трейдер новичок", "Трейдер новачок", ],
	"client_trader_experienced": [ "Senior trader", "Трейдер опытный", "Досвідчений трейдер", ],
	// "client_add_resend-reg-email": [ "Resend registration mail", "Переслать регистрационное письмо", "Переслати реєстраційний лист", ],
	"client_trader_managing": [ "Manager trader", "Трейдер управляющий", "Керуючий трейдер", ],
	"client_investor_newbie": [ "Junior investor", "Инвестор новичок", "Інвестор новачок", ],
	"client_investor_experienced": [ "Experienced investor", "Инвестор опытный", "Інвестор досвідчений", ],
	"client_warm": [ "Warm", "Тёплый", "Теплий", ],
	"client_cold": [ "Cold", "Холодный", "Холодний", ],
	"client_hot": [ "Hot", "Горячий", "Гарячий", ],
	"client_man": [ "Man", "Мужчина", "Чоловік", ],
	"client_woman": [ "Woman", "Женщина", "Жінка", ],
	"client_view": [ "View", "Посмотреть", "Переглянути", ],
	"client_created": [ "Created", "Создан", "Створено", ],
	"client_responsible": [ "Responsible", "Ответственный", "Відповідальний", ],
	"client_message": [ "Message", "Сообщение", "Повідомлення", ],
	"client_missed_call": [ "Missed call", "Пропущенный звонок", "Пропущений виклик", ],
	"client_incoming_call": [ "Incoming call", "Входящий звонок", "Вхідний виклик", ],
	"client_sort_by": [ "Sort by", "Сортировать по", "Сортувати за", ],
	"client_filter": [ "Filter", "Фильтр", "Фільтр", ],
	"client_deposit": [ "Deposit", "Пополнения", "Поповнення", ],
	"client_withdraw": [ "Withdraw", "Выводы", "Виводи", ],
	"client_all": [ "All", "Все", "Всi", ],
	"client_manager": [ "Manager", "Менеджер", "Менеджер", ],
	"client_account_number_sender": [ "Account Number Sender", "Номер счета отправителя", "Номер відправника рахунку", ],
	"client_deadline": [ "Deadline", "Крайний срок", "Кінцевий термін", ],
	"client_description": [ "Description", "Описание", "Опис", ],
	"client_reference": [ "Reference", "Ссылка", "Довідка", ],
	"client_assingnes_to": [ "Assigned to", "Назначено", "Присвоєно", ],
	"client_message_send": [ "Send message", "Отправка сообщения", "Відправка повідомлення", ],
	"client_subject": [ "Subject", "Тема", "Тема", ],
	"client_message_choose": [ "Choose", "Выбрать", "Обрати", ],
	"client_message_choose_type": [ "Field Type is required", "Поле Тип обязательно для заполнения", "Поле Тип обов'язкове для заповнення", ],
	"client_message_choose_source": [ "Field Source is required", "Поле Источник обязательно для заполнения", "Поле Джерело обов'язкове для заповнення", ],
	"client_message_choose_template_id": [ "Field Template is required", "Поле Шаблон обязательно для заполнения", "Поле Шаблон обов'язкове для заповнення", ],
	"client_message_type": [ "Type", "Тип", "Тип", ],
	"client_message_text": [ "Text", "Текст", "Текст", ],
	"client_message_enter_text": [ "Enter text", "Введите текст", "Введіть текст", ],
	"client_message_enter_type": [ "Type", "Тип", "Тип", ],
	"client_message_type_text": [ "Text", "Текст", "Текст", ],
	"client_message_type_template": [ "Template", "Шаблон", "Шаблон", ],
	"client_message_source": [ "Source", "Источник", "Джерело", ],
	"client_message_subject": [ "Subject", "Тема", "Тема", ],
	"client_message_subject_ph": [ "Enter Email subject", "Введите тему письма", "Введіть тему листа", ],
	"client_change_phone_ph": [ "Enter the number", "Введите номер", "Введіть номер", ],
	"client_email_input_ph": [ "Enter Email", "Введите email", "Введіть email", ],
	"client_message_add_files": [ "Choose file", "Выбрать файл", "Обрати файл", ],
	"client_message_cancel": [ "Cancel", "Закрыть", "Закрити", ],
	"client_message_send_success": [ "Message were successfully send", "Сообщение успешно отправлено", "Повідомлення успішно відправлено", ],
	"client_ip_address": [ "IP Address", "IP-адрес", "IP-адреса", ],
	"client_category_lead_zero": [ "Lead 0", "Лид 0", "Лiд 0", ],
	"client_category_lead": [ "Lead", "Лид", "Лiд", ],
	"client_category_lead_one": [ "Lead 1", "Лид 1", "Лiд 1", ],
	"client_category_client_zero": [ "Client 0", "Клиент 0", "Клієнт 0", ],
	"client_category_client_one": [ "Client 1", "Клиент 1", "Клієнт 1", ],
	"client_category_client_two": [ "Client 2", "Клиент 2", "Клієнт 2", ],
	"client_category_client_three": [ "Client 3", "Клиент 3", "Клієнт 3", ],
	"client_city": [ "City", "Город", "Мiсто", ],
	"client_enn": [ "TIN", "ИНН", "IПН", ],
	"client_language": [ "Language", "Язык", "Мова", ],
	"client_back": [ "Back", "Назад", "Назад", ],
	"client_next": [ "Next", "Вперед", "Уперед", ],
	"client_save": [ "Save", "Сохранить", "Зберегти", ],
	"client_general_info": [ "General Information", "Общая информация", "Загальна iнформацiя", ],
	"client_contact_info": [ "Contact information", "Контактная информация", "Контактна iнформацiя", ],
	"client_personal_info": [ "Personal Information", "Персональная информация", "Особиста iнформацiя", ],
	"client_add_more_emails": [ "add more e-mails", "добавить ещё электронную почту", "додати ще електронну пошту", ],
	"client_add_more_phones": [ "add more phones", "добавить ещё телефон", "додати ще телефон", ],
	"client_verified": [ "Verified", "Верифицирован", "Підтверджено", ],
	"client_not_verified": [ "Not verified", "Не верифицирован", "Не підтверджено", ],
	"client_image": [ "Document", "Документ", "Документ", ],
	"client_trader_diary": [ "Trader diary", "Дневник трейдера", "Трейдерський щоденник", ],
	"client_delete_are_u_sure": [ "Are you sure?", "Вы уверены?", "Ви впевнені?", ],
	"client_delete_marked_4_delete": [
		"The Card is Marked For Deletion,\ndoes Not Delete From The Database",
		"Эта карточка помеченна к удалению,\nно не будет удалена из базы",
		"Ця картка помічена до видаленя,\nале не буде видалена з бази",
	],
	"client_sms_send_success": [
		"Sms-messages were successfully sent",
		"СМС сообщения успешно отправлены",
		"СМС повідомлення успішно відправлені",
	],
	"client_emails_send_success": [
		"Emails were successfully sent",
		"Сообщения успешно отправлено",
		"Повідомлення успішно відправлено",
	],
	"client_emails_send_error": [
		"Could not send emails",
		"Ошибка при отправке сообщения",
		"Помилка при відправленні повідомлення",
	],
	"client_register_type": [ "Register_type", "Тип аккаунта", "Тип аккаунта", ],
	"client_sms_send_error": [
		"Could not send sms-messages",
		"Ошибка при отправке сообщения",
		"Помилка при відправленні повідомлення",
	],
	"client_filters_error": [
		"Please, choose filters",
		"Пожалуйста, выберите фильтры",
		"Будь ласка, оберіть фільтри",
	],
	"client_select_template": [ "Select template", "Выберите шаблон", "Виберіть шаблон", ],
	"client_deposits_affiliate_reward": [ "Affiliate reward", "Партнерское вознаграждение", "Партнерська винагорода", ],
	"client_deposits_promotions_and_adjustments": [ "Promotions and adjustments", "Акции и корректировки", "Акції і коригування", ],
	"client_deposits_client_result": [ "Client result", "Результат клиента", "Результат клієнта", ],
	"client_deposits_balance": [ "Balance", "Баланс", "Баланс", ],
	"client_deposits_credit": [ "Credit", "Кредит", "Кредит", ],
	"client_deposits_equity": [ "Equity", "Средства", "Кошти", ],
	"account_filter_id": [ "ID", "ИД", "IД", ],
	"account_filter_name": [ "First Name", "Имя", "Ім'я", ],
	"account_filter_surname": [ "Last Name", "Фамилия", "Прізвище", ],
	"account_filter_email": [ "E-mail", "Эл. почта", "Ел. пошта", ],
	"account_filter_status": [ "Status", "Статус", "Cтатус", ],
	"account_filter_phone": [ "Phone", "Телефон", "Телефон", ],
	"account_filter_system_type": [ "System type", "Тип клиента", "Тип клієнта", ],
	"account_filter_user_id": [ "Personal Manager ID", "Персональный менеджер ИД", "Персональний менеджер IД", ],
	"account_filter_manager": [ "Personal Manager", "Персональный менеджер", "Персональний менеджер", ],
	"account_filter_library_card": [ "Library Сard", "Библиотечная карта", "Бібліотечна карта", ],
	"account_filter_birth_date": [ "Birth date", "Дата рождения", "День народження", ],
	"account_filter_created_at": [ "Created at", "Дата создания", "Дата створення", ],
	"account_filter_country": [ "Country", "Страна", "Країна", ],
	"account_filter_user": [ "Manager", "Менеджер", "Менеджер", ],
	"account_filter_gender": [ "Sex", "Пол", "Стать", ],
	"account_filter_language": [ "Language", "Язык", "Мова", ],
	"account_filter_enn": [ "TIN", "ИНН", "ІНН", ],
	"account_filter_updated_at": [ "Updated at", "Дата обновления", "Дата обновлення", ],
	"account_filter_warm_type": [ "Warming", "Признак теплоты", "Ознака теплоти", ],
	"account_filter_category_id": [ "Сategory", "Категория", "Категорія", ],
	"account_comments_customer_title": [ "Comments", "Комментарии", "Коментарi", ],
	"account_comments_customer_button_edit": [ "Edit", "Редактировать", "Редагувати", ],
	"account_comments_customer_button_add": [ "Add", "Добавить", "Додати", ],
	"account_comments_customer_button_cancel": [ "Cancel", "Отменить", "Відмінити", ],
	"account_comments_customer_button_save": [ "Save", "Сохранить", "Зберегти", ],
	"account_comments_customer_create_ph": [ "Enter your comment", "Введите ваш комментарий", "Введіть ваш коментар", ],
	"account_comments_customer_read-more": [ "Read more", "Читать далее", "Читата далі", ],
	"account_comments_customer_cancel": [ "Cancel", "Отменить", "Відмінити", ],
	"account_comments_customer_delete": [ "Delete", "Удалить", "Видалити", ],
	"account_comments_customer_deleted": [ "Deleted", "Удален", "Видалений", ],
	"account_comments_customer_restore": [ "Restore", "Восстановить", "Відновити", ],
	"client_appeals_title": [ "Title", "Наименование", "Назва", ],
	"client_more_details": [ "More details", "Узнать больше", "Дізнатись більше", ],
	"client_account_list": [ "List of accounts", "Список аккаунтов", "Список акаунтів", ],
	"trading_created": [ "Created", "Создано", "Створено", ],
	"trading_type": [ "Type", "Тип", "Тип", ],
	"trading_amount": [ "Amount", "Сумма", "Сума", ],
	"trading_shoulder": [ "Shoulder", "Плечо", "Плече", ],
	"trading_strategy": [ "Strategy", "Стратегия", "Стратегія", ],
	"trading_risk_manager": [ "Sign risk manager", "Признак риск-менеджера", "Ознака ризик-менеджеру", ],
	"trading_currency": [ "Currency", "Валюта", "Валюта", ],
	"trading_status": [ "Status", "Статус", "Статус", ],
	"trading_platform": [ "Platform", "Платформа", "Платформа", ],
	"trading_information": [ "Information", "Информация", "Інформація", ],
	"trading_history": [ "Payment history", "История платежей", "Історія платежів", ],
	"trading_time": [ "Date and time", "День и время", "Дата і час", ],
	"trading_system": [ "Payment system", "Платежная система", "Платіжна система", ],
	"trading_client": [ "Client", "Клиент", "Клієнт", ],
	"trading_card": [ "Payment card", "Номер счета", "Номер рахунку", ],
	"trading_total": [ "Total", "Всего", "Усього", ],
	"activity_created_at": [ "Created", "Создан", "Створений", ],
	"activity_catalog_name": [ "Type", "Тип", "Тип", ],
	"activity_ip": [ "IP", "IP", "IP", ],
	"activity_link_to": [ "Web site", "Веб-сайт", "Веб-сайт", ],
	"activity_referral": [ "Source", "Источник", "Джерело", ],
	"activity_screen_resolution": [ "Technology", "Технология", "Технологія", ],
	"user_profile_weekend": [ "Weekend", "Выходной", "Вихідний", ],
	"user_profile_verification_img": [ "The picture should be no more than 1Mb, 1024x1024px, with the extension jpeg, jpg, png", "Картинка должна быть не более 1Мб, 1024х1024px, с расширением jpeg, jpg, png", "Картинка повинна бути не більше 1 Мб, 1024х1024px, з розширенням jpeg, jpg, png", ],
	"client_dropdown_placeholder": [ "Select", "Выбрать", "Обрати", ],
	"client_new_in_briefcase": [ "New in briefcase", "Новый в портфеле", "Новий в портфелі", ],
	  "client_group_assign": [ "Select All", "Выбрать всех", "Вибрати всiх", ],
	"account_success_archived": [ "The client is archived", "Клиент архивирован", "Кліент архівований", ],
	"account_success_rearchived": [ "The client is re-archived", "Клиент разархивирован", "Кліент разархівований", ],
	"distribute": [ "Distribute", "Распределить", "Розподілити", ],
	"remove_distribution": [ "Remove distribution", "Убрать распределение", "Прибрати розподіл", ],
	"distribution_success": [ "Users are successfuly distributed", "Пользователи успешно распределены", "Користувачі успішно розподілені", ],
	"group_assignment": [ "Group assignment", "Групповое распределение", "Груповий розподіл", ],
	"group_assignment_all": [ "Selected for assignment", "Выбрано для распределения", "Обрано для розподілу", ],
	"group_assignment_success": [ "Assigned successfully", "Распределено успешно", "Розподілено успішно", ],
	"group_assignment_failed": [ "Failed to assign clients", "Не удалось распределить клиентов", "Не вдалось розподілити клієнтів", ],
	"call_queue_management": [ "Create call queue", "Создать очередь обзвона", "Створити чергу обзвона", ],

	"tax_residency": [ "Tax residency data", "Данные налогового резидентства", "Дані податкового резидентства", ],
	"is_avaliable": [ "Available", "Доступный", "Доступний", ],
	"is_mobile": [ "Mobile App", "Мобильное приложение", "Мобільний додаток", ],

	"client_given": [ "Given", "Предоставлены", "Надані", ],
	"client_unspecified": [ "Unspecified", "Отсутствуют", "Відсутні", ],

	"client_verification_menu_personal_data": [ "Personal data", "Персональные данные", "Персональні дані", ],
	"client_verification_menu_client_address": [ "Addresses", "Адреса клиента", "Адреси клієнта", ],
	"client_verification_menu_client_status": [ "Statuses", "Статусы", "Статуси", ],
	"client_verification_menu_tax_residency": [ "Tax residency", "Налоговое резидентство", "Податкове резидентство", ],
	"client_verification_menu_client_documents": [ "Client documents", "Документы клиента", "Документи клієнта", ],

	"client_verification_view_btn": [ "View", "Просмотреть", "Переглянути", ],
	"client_verification_btn": [ "Verify", "Верифицировать", "Верифікувати", ],
	"client_verification_title": [ "Client data", "Данные клиента", "Дані клієнта", ],
	"client_verification_title_account_data": [ "Personal data", "Персональные данные", "Персональні дані", ],
	"client_verification_title_account_status": [ "Statuses", "Статусы", "Статуси", ],
	"client_verification_title_account_address_residence": [ "Residence address", "Адрес проживания", "Адреса проживання", ],
	"client_verification_title_account_address_registration": [ "Registration address", "Адрес регистрации", "Адреса реєстрації", ],
	"client_verification_title_account_address_correspondence": [ "Correspondence address", "Корреспондентский адрес", "Кореспондентська адреса", ],
	"client_verification_title_account_address_additional": [ "Additional address", "Дополнительный адрес", "Додаткова адреса", ],
	"client_verification_title_account_data_tax_residency": [ "Tax residency data", "Данные налогового резидентства", "Дані податкового резидентства", ],
	"client_verification_title_account_data_tax_residency_label": [ "Tax residency data change confirmation is required", "Требуется подтверждение изменения данных налогового резидентства", "Необхідне підтвердження зміни даних податкового резидентства", ],
	"client_verification_title_account_documents": [ "Document data", "Данные документа", "Дані документа", ],

	"client_verification_label_verify_status": [ "Verification status of an individual client", "Статус верификации индивидуального аккаунта клиента", "Статус верифікації індивідуального акаунту клієнта", ],
	"client_verification_label_account_status": [ "Account status of an individual account", "Статус учетной записи индивидуального аккаунта", "Статус облікового запису індивідуального акаунту", ],
	"client_verification_label_status_update_actions": [ "Client verification in Sum&Substance was canceled. The status needs to be updated!", "Верификация клиента в Sum&Substance отменена. Необходимо актуализировать статус!", "Верифікацію клієнта в Sum&Substance скасовано. Необхідно актуалізувати статус!", ],
	"clients_verification_account_status_action_select_option_placeholder": [ "Verification cancellation", "Отмена верификации", "Відміна верифікації", ],
	"clients_verification_account_status_action_request_confirm": [ "Confirm request", "Подтвердить запрос", "Підтвердити запит", ],
	"clients_verification_account_status_action_request_cancel": [ "Cancel request", "Отменить запрос", "Скасувати запит", ],
	"clients_verification_account_status_cancel_request_success_text": [ "Request of Sum&Substance service to cancel client verification has been canceled", "Запрос сервиса Sum&Substance на отмену верификации клиента отменен", "Запит сервісу Sum&Substance на відміну верифікації акаунту скасовано", ],

	"client_verification_label_account_update_request_actions": [ "Documents verification required on client's data change request!", "Требуется проверка документов по запросу клиента на изменение данных!", "Необхідна перевірка документів по запиту клієнта на зміну даних!", ],
	"client_verification_account_confirm_request_success_text": [ "Client's requests to change personal data and address data are accepted", "Запросы клиента на изменение персональных данных и данных адреса подтверждены", "Запити клієнта на зміну персональних даних та даних адреси підтверджено", ],
	"client_verification_account_cancel_request_success_text": [ "Client's requests to change personal data and address data are canceled", "Запросы клиента на изменение персональных данных и данных адреса отменены", "Запити клієнта на зміну персональних даних та даних адреси скасовано", ],

	"clients_update_account_data_btn": [ "Update client data", "Обновить данные клиента", "Оновити дані клієнта", ],
	"clients_update_address_data_btn": [ "Update address", "Обновить адрес", "Оновити адресу", ],
	"clients_create_address_data_btn": [ "Create address", "Создать адрес", "Створити адресу", ],
	"clients_clear_address_data_btn": [ "Erase address", "Стереть адрес", "Стерти адресу", ],
	"clients_update_document_data_btn": [ "Update document data", "Обновить данные документа", "Оновити дані документа", ],
	"clients_confirm_request_btn": [ "Request client confirmation", "Запросить подтверждение клиентом", "Запитати підтвердження клієнтом", ],
	"clients_wait_confirmation_text": [ "Change confirmation by client is expected", "Ожидается подтверждение изменений клиентом", "Очікується підтвердження змін клієнтом", ],

	"clients_update_phone_btn": [ "Update phone number", "Обновить номер телефона", "Оновити номер телефону", ],
	"clients_update_email_btn": [ "Update e-mail address", "Обновить e-mail адрес", "Оновити e-mail адресу", ],
	"clients_update_phone_success_text": [ "Phone number updated", "Номер телефона обновлен", "Номер телефону оновлено", ],
	"clients_update_email_success_text": [ "E-mail address updated", "E-mail адрес обновлен", "E-mail адресу оновлено", ],
	"clients_cancel_phone_success_text": [ "Phone number change cancelled", "Смена номера телефона отменена", "Зміну номеру телефона скасовано", ],
	"clients_cancel_email_success_text": [ "E-mail address change cancelled", "Смена e-mail адреса отменена", "Зміну e-mail адреси скасовано", ],
	"clients_cancel_request_btn": [ "Cancel request", "Отменить запрос", "Скасувати запит", ],
	"clients_document_parsed_data_btn": [ "Get document data", "Получить данные документа", "Отримати дані документа", ],
	"clients_document_parsed_data_hide_btn": [ "Hide document data", "Скрыть данные документа", "Приховати дані документа", ],
	"clients_status_details_btn": [ "Status details", "Детали статуса", "Деталі статусу", ],
	"clients_status_details_hide_btn": [ "Hide status details", "Скрыть детали статуса", "Скрити деталі статусу", ],
	"clients_verification_select_option_placeholder": [ "Select", "Выбрать", "Обрати", ],
	"clients_verification_select_no_options_text": [ "No options", "Опции отсутствуют", "Опції відсутні", ],
	"clients_verification_copy_address_select_option_placeholder": [ "Copy address", "Скопировать адрес", "Скопіювати адресу", ],
	"clients_verification_residency_action_select_option_placeholder": [ "Select action", "Выбрать действие", "Обрати дію", ],

	"clients_verification_residency_action_delete": [ "Delete", "Удалить", "Видалити", ],
	"clients_verification_residency_action_edit": [ "Edit", "Редактировать", "Редагувати", ],
	"clients_verification_residency_action_restore": [ "Restore", "Восстановить", "Відновити", ],
	"clients_verification_residency_action_undo": [ "Undo", "Отменить", "Скасувати", ],
	"clients_verification_residency_action_update": [ "Update", "Обновить", "Оновити", ],

	"clients_verification_residency_text_edit": [ "Tax residency data will be changed. Confirmation by client is needed!", "Данные налогового резидентства будут изменены. Необходимо подтверждение клиентом!", "Дані податкового резидентства будуть змінені. Потрібне підтвердження клієнтом!", ],
	"clients_verification_residency_text_delete": [ "Tax residency data will be deleted. Confirmation by client is needed!", "Данные налогового резидентства будут удалены. Необходимо подтверждение клиентом!", "Дані податкового резидентства будуть видалені. Потрібне підтвердження клієнтом!", ],

	"client_verification_label_data_surname": [ "Last name", "Фамилия", "Прізвище", ],
	"client_verification_label_data_surname_latin": [ "Last name in Latin", "Фамилия латиницей", "Прізвище латиницею", ],
	"client_verification_label_data_name": [ "First name", "Имя", "Ім'я", ],
	"client_verification_label_data_name_latin": [ "First name in Latin", "Имя латиницей", " Ім'я латиницею", ],
	"client_verification_label_data_patronymic": [ "Patronymic", "Отчество", "Ім'я по батькові", ],
	"client_verification_label_data_gender": [ "Gender", "Пол", "Стать", ],
	"client_verification_label_data_birth_date": [ "Birth date", "Дата рождения", "Дата народження", ],
	"client_verification_label_data_nationality_id": [ "Citizenship", "Гражданство", "Громадянство", ],
	"client_verification_label_data_person_identity_number": [ "Identity verification document number", "Номер документа подтверждающего личность", "Номер документа, який підтверджує особу", ],
	"client_verification_label_data_issue_date": [ "Document issue date", "Дата выдачи документа", "Дата видачі документа", ],
	"client_verification_label_data_issue_country_id": [ "Country of document issue", "Страна выдачи документа", "Країна видачі документа", ],

	"client_verification_label_address_country_id": [ "Country", "Cтрана", "Країна", ],
	"client_verification_label_address_state": [ "Region, state, county", "Регион, область, провинция", "Регіон, область, провінція", ],
	"client_verification_label_address_state_latin": [ "Region, state, county in Latin", "Регион, область, провинция латиницей", "Регіон, область, провінція латиницею", ],
	"client_verification_label_address_location": [ "Location", "Населенный пункт", "Населений пункт", ],
	"client_verification_label_address_location_latin": [ "Location in Latin", "Населенный пункт латиницей", "Населений пункт латиницею", ],
	"client_verification_label_address_address": [ "Address", "Адрес", "Адреса", ],
	"client_verification_label_address_address_latin": [ "Address in Latin", "Адрес латиницей", "Адреса латиницею", ],
	"client_verification_label_address_zip_code": [ "Zip code", "Индекс", "Індекс", ],

	"client_verification_label_residency_country_id": [ "Tax residency country", "Страна налогового резидентства", "Країна податкового резидентства", ],
	"client_verification_label_residency_tin": [ "TIN", "ИНН", "ІПН", ],
	"client_verification_label_residency_tin_absence_reason_id": [ "TIN absence reason", "Причина отсутствия ИНН", "Причина відсутності ІПН", ],
	"client_verification_label_residency_status": [ "statusTest", "statusTest", "statusTest", ],
	"client_verification_label_residency_tin_absence_reason_text": [ "Personal reason", "Своя причина", "Своя причина", ],
	"client_verification_label_residency_tin_absence_reason_text_placeholder": [ "If you have selected a reason B, indicate why you cannot get a TIN", "Если Вы выбрали причину B, укажите, почему Вы не можете получить TIN ", "Якщо Ви вибрали причину B, вкажіть, чому Ви не можете отримати TIN", ],

	"client_verification_label_document_type": [ "Document type", "Тип документа", "Тип документу", ],
	"client_verification_label_document_sub_type": [ "Document side", "Сторона документа", "Бік документу", ],
	"client_verification_label_document_status": [ "Document status", "Статус документа", "Статус документу", ],
	"client_verification_label_document_system_answer": [ "Status details", "Детали статуса", "Деталі статусу", ],
	"client_verification_label_document_comment_id": [ "Comment", "Комментарий", "Коментар", ],
	"client_verification_label_document_custom_option": [ "Your option", "Свой вариант", "Свій варіант", ],
    // "client_verification_label_document_description": ["", "", ""], //field without label due to the technical task
	"client_verification_label_document_created_at": [ "Date and time document uploaded", "Дата и время загрузки документа", "Дата та час завантаження документу", ],
	"client_verification_label_document_updated_at": [ "Date and time document last verified", "Дата и время последней проверки документа", "Дата та час останньої перевірки документу", ],

	"client_verification_label_update_actions": [ "The document is related to the client's request for data change", "Документ связан с запросом клиента на изменение данных", "Документ пов'язаний із запитом клієнта на зміну даних", ],
	"client_verification_label_residency_update_actions": [ "Client needs to update this data", "Клиенту необхидимо изменить данные", "Клієнту необхідно змінити дані", ],

	"clients_verification_country_info_prohibited": [ "The country is currently prohibited for client registration and verification on Company website and requires agreement if indicated as a tax residency country", "Страна в данное время запрещена для регистрации и верификации клиента на сайте Компании и требует согласования с юристом, если указана в качестве страны налогового резидентства", "Країна в даний час заборонена для реєстрації та верифікації клієнта на сайті Компанії і вимагає узгодження з юристом, якщо вказана в якості країни податкового резидентства", ],
	"clients_verification_country_info_allowed_verification": [ "The country is currently allowed for client registration on Company website but requires lawyer agreement for verification and if indicated as a tax residency country", "Страна в данное время разрешена для регистрации клиента на сайте Компании, но требует согласования с юристом для верификации и если указана в качестве страны налогового резидентства", "Країна в даний час дозволена для реєстрації клієнта на сайті Компанії, але вимагає узгодження з юристом для верифікації і якщо вказана в якості країни податкового резидентства", ],
	"clients_verification_country_info_prohibited_verification": [ "The country is currently prohibited for client registration on Company website and requires lawyer agreement for verification, and if indicated as a tax residency country", "Страна в данное время запрещена для регистрации клиента на сайте Компании и требует согласования с юристом для верификации, и если указана в качестве страны налогового резидентства", "Країна в даний час заборонена для реєстрації клієнта на сайті Компанії і вимагає узгодження з юристом для верифікації, і якщо вказана в якості країни податкового резидентства", ],

	"client_verification_tooltip_lawyer_agreed": [ "Client verification agreed with a lawyer", "Верификация клиента согласована с юристом", "Верифікація клієнта погоджена з юристом", ],

	"client_verification_reset_title": [ "Verification status resetting at Sum&Substance", "Сброс статуса верификации в Sum&Substance", "Скидання статусу верификації в Sum&Substance", ],
	"client_verification_reset_text_warning": [ "The client status in the Sum&Substance service will be reset. Client status in CRM and PO will remain <span class='cont__text cont__text--bold'>Re-verification</span>. The status of all previously uploaded documents in Sum&Substance will be changed to <span class='cont__text cont__text--bold cont__text--red'>DELETED</span>. The status of documents received from Sum&Substance in CRM & PO will remain unchanged.", "Статус клиента в сервисе Sum&Substance будет сброшен. Статус клиента в СРМ и ЛК останется <span class='cont__text cont__text--bold'>Повторная Верификация</span>. Статус всех ранее загруженных документов в Sum&Substance будет изменен на <span class='cont__text cont__text--bold cont__text--red'>DELETED</span>. Статус документов полученных из Sum&Substance в СРМ И ЛК останется прежним.", "Статус клієнта в сервісі Sum&Substance буде скинутий. Статус клієнта в СРМ та ЛК залишиться <span class='cont__text cont__text--bold'>Повторна Верифікація</span>. Статус всіх раніше завантажених документів в Sum&Substance буде змінено на <span class='cont__text cont__text--bold cont__text--red'>DELETED</span>. Статус документів отриманих з Sum&Substance в СРМ та ЛК залишиться без змін.", ],
	"client_verification_reset_text_success": [ "The client status updated successfully <br>at Sum&Substance", "Статус клиента успешно изменен <br>в Sum&Substance", "Статус клієнта успішно змінений <br>в Sum&Substance", ],
	"client_verification_reset_text_error": [ "Request was unsuccessful. Try again", "Запрос был неуспешным. Попробуйте еще раз", "Запит не був успішним. Спробуйте ще раз", ],
	"client_verification_reset_btn": [ "Reset verification status", "Сбросить статус верификации", "Скинути статус верифікації", ],
	"client_verification_reset_next_step_btn": [ "Next step", "Следующий шаг", "Наступний крок", ],

	"client_re_verification_title": [ "Uploading documents to Sum&Substance", "Загрузка документов в Sum&Substance", "Завантаження документів в Sum&Substance", ],
	"client_re_verification_title_uploaded_files": [ "Documents in CRM", "Документы в CRM", "Документи в CRM", ],
	"client_re_verification_download_btn": [ "Download", "Скачать", "Завантажити", ],

	"client_re_verification_label_document_type": [ "Type", "Тип", "Тип", ],
	"client_re_verification_label_document_sub_type": [ "Side", "Сторона", "Бік", ],
	"client_re_verification_label_document_status": [ "Status", "Статус", "Статус", ],

	"client_no_access_1": [ 'You do not have access to this client\'s data', 'Вы не имеете прав на просмотр данных по этому клиенту.', 'Ви не маєте прав на перегляд даних по цьому клієнтові', ],
	"client_no_access_2": [ 'The client is assigned to another manager', 'Клиент закреплен за другим менеджером.', 'Клієнт закріплений за іншим менеджером', ],
  	"account_answer_call_state_title": [ "Autocall", "Автообзвон", "Автообзвон", ],
	"account_answer_call_state_status": [ "Not answered", "Не отвечает", "Не відповідає", ],
	"client_mobile_app_discount": [ "Used a discount from a mobile application", "Воспользовался скидкой из мобильного приложения", "Скористався знижкою з мобільного додатку", ],

	"account_tag": [ "Tags", "Тег", "Тег", ],
	"letter_id": [ "ID", "ID", "ID", ],
	"letter_delivered_date": [ "Email date", "Дата письма", "Дата листа", ],
	"letter_sender": [ "From", "От кого", "Від кого", ],
	"letter_recipient": [ "To", "Адрес получателя", "Адреса отримувача", ],
	"letter_status": [ "Status", "Статус", "Статус", ],
	"letter_updated_at": [ "Status change date", "Дата смены статуса", "Дата зміни статуса", ],
	"letter_system": [ "Provider", "Провайдер", "Провайдер", ],
	"letter_message_id": [ "Message_id", "Message_id", "Message_id", ],
	"letter_subject": [ "Subject", "Тема", "Тема", ],
	"letter_details": [ "Details", "Детали", "Деталі", ],
	"letter_details_title": [ "Mail details", "Детали письма", "Деталі листа", ],
	"letter_details_info": [ "Detailed information", "Детальная информация", "Детальна інформація", ],
	"letter_history_info": [ "Status history", "История статусов", "Історія статусів", ],
	"letter_update": [ "Refresh", "Обновить", "Оновити", ],
	"spam_message": [ "Client marked emails as spam.", "Клиент пометил рассылку как спам.", "Клієнт позначив розсилку як спам.", ],
	"spam_del_btn": [ "Delete from spam", "Удалить из спама", "Видалити зі спаму", ],
	"spam_error": [ "User not found in suppression list", "Пользователь не найден в списке спама", "Користувача не знайдено у списку спаму", ],
	"cannot_spam_del_btn": [ "Removing from suppression list unvailable. Email is invalid.", "Невозможно удалить из спам листа. Невалидный адрес почты.", "Неможливо видалити з спам листу. Невалiдний адрес пошти.", ],
	"upload_document_request_title": [ "Request", "Заявка", "Заявка", ],
	"source_of_origin_funds_status": [ "Source of funds status", "Статус источника происхождения средств", "Статус джерела походження коштів", ],
	"documents_verification_source_of_funds": [ "Documents verification required on client's source of funds confirmation request!", "Требуется проверка документов по запросу клиента на подтверждение источника происхождения средств!", "Необхідна перевірка документів по запиту клієнта на підтвердження джерела походження коштів!", ],
	"unable_to_confirm_the_request_1st": [ "Unable to confirm the request. Current status of the source of funds is “Confirmed", "Невозможно подтвердить запрос. Текущий статус источника происхождения средств “Подтвержден“.", "Неможливо підтвердити запит. Поточний статус джерела походження коштів “Підтверджений”", ],
	"unable_to_confirm_the_request_2nd": [ "Unable to confirm the request. There is no request for confirmation of the source of funds", "Невозможно подтвердить запрос. Отсутствует заявка на подтверждение источника подтверждения средств.", "Неможливо підтвердити запит. Відсутня заявка на підтвердження джерела походження коштів", ],
	"unable_to_confirm_the_request_3rd": [ "Unable to confirm the request. There is not a single document, related to this very request for confirmation of the source of funds. Previously, a task was created for the client manager to contact the client in order to supply you, as a financial manager, with the documents confirming the source of funds.", "Невозможно подтвердить запрос. Нет ни одного документа, связанного именно с этим запросом на подтверждение источника происхождения средств. На менеджера клиента ранее была создана задача о необходимости связаться с клиентом для предоставления вам, как финансовому менеджеру, документов, подтверждающих источник происхождения средств.", "Неможливо підтвердити запит. Немає жодного документа, пов'язаного саме з цим запитом на підтвердження джерела походження коштів. На менеджера клієнта раніше було створено завдання про необхідність зв'язатися з клієнтом для надання вам, як фінансовому менеджеру, документів, що підтверджують джерело походження коштів.", ],
	"unable_to_confirm_the_request_4th": [ "Unable to confirm the request. There is not a single confirmed document, related to the request for confirmation of the source of funds.", "Невозможно подтвердить запрос. Нет ни одного подтвержденного документа, связанного с запросом на подтверждение источника происхождения средств.", "Неможливо підтвердити запит. Немає жодного підтвердженого документа, пов'язаного із запитом на підтвердження джерела походження коштів.", ],
	"unable_to_confirm_the_request_5th": [ "Source of funds has been successfully confirmed", "Источник происхождения средств успешно подтвержден.", "Джерело походження коштів успішно підтверджено.", ],
	"clients_request_to_confirm_the_source_of_funds_is_accepted": [ "Client's request to confirm the source of funds is accepted.", "Запрос клиента на подтверждение источника происхождения средств подтвержден.", "Запит клієнта на підтвердження джерела походження коштів підтверджено.", ],
	"unable_to_cancel_the_request_1st": [ "Unable to cancel the request. Current status of the source of funds is “Confirmed“", "Невозможно отменить запрос. Текущий статус источника происхождения средств “Подтвержден“", "Неможливо відмінити запит. Поточний статус джерела походження коштів “Підтверджений”", ],
	"unable_to_cancel_the_request_2nd": [ "Unable to cancel the request. There is no request for confirmation of the source of funds.", "Невозможно отменить запрос. Отсутствует заявка на подтверждение источника подтверждения средств.", "Неможливо відмінити запит. Відсутня заявка на підтвердження джерела походження коштів.", ],
	"unable_to_cancel_the_request_3rd": [ "Unable to cancel the request. There is not a single document, related to this very request for confirmation of the source of funds. Previously, a task was created for the client manager to contact the client in order to supply you, as a financial manager, with the documents confirming the source of funds.", "Невозможно отменить запрос. Нет ни одного документа, связанного именно с этим запросом на подтверждение источника происхождения средств. На менеджера клиента ранее была создана задача о необходимости связаться с клиентом для предоставления вам, как финансовому менеджеру, документов, подтверждающих источник происхождения средств.", "Неможливо відмінити запит. Немає жодного документа, пов'язаного саме з цим запитом на підтвердження джерела походження коштів. На менеджера клієнта раніше було створено завдання про необхідність зв'язатися з клієнтом для надання вам, як фінансовому менеджеру, документів, що підтверджують джерело походження коштів.", ],
	"unable_to_cancel_the_request_4th": [ "Unable to cancel the request. There is not a single rejected document related to the request for confirmation of the source of funds.", "Невозможно отменить запрос. Нет ни одного отклоненного документа, связанного с запросом на подтверждение источника происхождения средств./Неможливо відмінити запит.", "Немає жодного відхиленного документа, пов'язаного із запитом на підтвердження джерела походження коштів.", ],
	"unable_to_cancel_the_request_5th": [ "Request to confirm the source of funds has been successfully canceled", "Запрос на подтверждение источника происхождения средств успешно отклонен.", "Запит на підтвердження джерела походження коштів успішно відхилено.", ],
	"clients_request_to_confirm_the_source_of_funds_is_rejected": [ "Client's request to confirm the source of funds is canceled.", "Запрос клиента на подтверждение источника происхождения средств отменен.", "Запит клієнта на підтвердження джерела походження коштів скасовано.", ],
};