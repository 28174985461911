export const hierarchy = {
    "hierarchy_add_subteam": ["Create department", "Создать отдел", "Cтворити відділ"],
    "hierarchy_create": ["Create", "Создать", "Створити"],
    "hierarchy_cancel": ["Cancel", "Отменить", "Скасувати"],
    "hierarchy_edit": ["Save", "Сохранить", "Зберегти"],
    "hierarchy_create_team": ["Create department", "Создать отдел", "Створити відділ"],
    "hierarchy_edit_team": ["Edit department", "Редактировать отдел", "Редагувати відділ"],
    "hierarchy_team": ["Name *", "Наименование *", "Найменування *"],
    "hierarchy_department": ["Department *", "Департамент *", "Департамент *"],
    "hierarchy_lead": ["Supervisor", "Руководитель", "Keрівник"],
    "hierarchy_staff": ["Employee", "Сотрудник", "Cпівробітник"],
    "hierarchy_add": ["Add", "Добавить", "Додати"],
    "hierarchy_select":    ["Select", "Выбрать", "Виберіть"],
    "hierarchy_sure_title": ["Are you sure?", "Вы уверены?", "Ви впевнені?"],
    "hierarchy_sure_text":    ["You want to delete this department", "Вы хотите удалить этот отдел", "Ви хочете видалити цей відділ"],
    "hierarchy_yes": ["Yes", "Да", "Так"],
    "hierarchy_no":    ["No", "Нет", "Ні"],
    "hierarchy_subordination":    ["Subordination", "Подчинение", "Підпорядкування"],
    "hierarchy_error":    ["Enter value", "Введите значение", "Введіть значення"],
}
