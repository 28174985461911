import React, { Component, } from 'react';
import { withLocalize, } from 'react-localize-redux';
import { withRouter, } from 'react-router-dom';
import Moment from 'moment';
import {
	deleteNewPartnerConditionList,
	editNewAffiliateProgramsList,
	getNewAffiliateProgramsList,
} from '../../../../../../services/PartnersRequestService';
import {
	MagicFilterPanels,
	Mentor,
	MagicTable,
	MagicButton,
} from '../../../../../../components/Magic';
import Preloader from '../../../../../../components/LoadingHOC/Preloader';
import Loader from '../../../../../tima/components/Loader';
import PagePanel from '../../../../../tima/components/Pages';
import {
	MagicTooltip,
} from '../../../../../tima/components/Magic/MagicTooltip';

import CreateUpdateAffiliatePrograms
from "../Popup/CreateUpdateAffiliatePrograms";

const conditionStatus = Object.freeze({
	CLOSED: 5,
	NEW: 1,
	ACTIVE: 2,
});

class AffiliateProgramsTable extends Component {
	constructor (props) {
		super(props);

		this.state = {
			loaded: false,
			data: [],
			pages: {
				filter: 0,
				total: 0,
				take: 50,
				variance: 2,
			},
			isViewMode: false,
			filterLastChange: Date.now(),
			newAffiliateProgramsId: null,
			isEmptyData: false,
		};

		const mf = new Mentor({
			owner: this,
			serviceId: 162,
			translate: this.props.translate,
		});

		Object.defineProperty(this, 'mf', { get: () => mf, });
	}

	async componentDidMount () {
		this.mf.subscribe({
			changed: this.mfChanged,
			cleaned: this.mfDone,
			cleaning: this.mfDoing,
			loaded: this.mfDone,
			loading: this.mfDoing,
			saved: this.mfDone,
			saving: this.mfDoing,
		}, this);

		await this.mf.init({ doEmit: true, });
	};

	componentWillUnmount () {
		this.mf.unsubscribe([
			this.mfChanged,
			this.mfDoing,
			this.mfDone,
		], this);
	};

		save = async state => new Promise(next => this.setState(state, next));

		onChange = async (name, value) => await this.save({ [name]: value, });

		params = () => this.props.match.params;

		query = () => new URLSearchParams(this.props.location.search);

		mfChanged = async () => {
			await this.save({ filterLastChange: Date.now(), });
		};

		mfDoing = async () => {
			await this.save({ loaded: false, });
		};

		mfDone = async () => {
			await this.mfChanged();
			await this.onDataLoad();
		};

		enumsChange = async (enums) => {
			if (enums.hash === this.state?.enums?.hash) {
				return `${ this.constructor.name }.enumsChange: false`;
			}
			await this.save({ enums, });

			return `${ this.constructor.name }.enumsChange: true`;
		};

		get affiliateProgramsListTableConfig () {
			const { translate, } = this.props;
			const prefix = 'affiliate_programs_';

			return [
				{
					path: [ 'partners', 'programs', 'id', ],
					key: 'affiliateProgramsId',
					render: this.renderInitialValue,
					title: `${ prefix }id`,
					orderId: 'o:id',
				},
				{
					path: [ 'partners', 'programs', 'name', ],
					key: 'affiliateProgramsName',
					render: this.renderAffiliateProgramsListName,
					title: `${ prefix }name`,
					orderId: 'o:name',
				},
				{
					path: [ 'partners', 'programs', 'client_name', ],
					key: 'affiliateProgramsNameClients',
					render: this.renderAffiliateProgramsListName,
					title: `${ prefix }name_for_clients`,
					orderId: 'o:clients',
				},
				// {
				// 	path: [ 'partners', 'programs', 'show_referral_data', ],
				// 	key: 'affiliateProgramsReferral',
				// 	render: this.renderReferralData,
				// 	title: `${ prefix }show_referrals`,
				// 	orderId: 'o:referrals',
				// },
				// {
				// 	path: [ 'partners', 'programs', 'allow_see_create_rebate', ],
				// 	key: 'affiliateProgramsRebate',
				// 	render: this.renderRebateData,
				// 	title: `${ prefix }show_rebate_projects`,
				// 	orderId: 'o:rebate',
				// },
				{
					path: [ 'partners', 'programs', 'show_amount_referrals_funds', ],
					key: 'affiliateProgramsAmount',
					render: this.renderAmountReferrals,
					title: `${ prefix }show_amount_of_referral`,
					orderId: 'o:amount',
				},
				{
					path: [ 'partners', 'programs', 'cookie_lifetime_days', ],
					key: 'affiliateProgramsCookie',
					render: this.renderInitialValue,
					title: `${ prefix }cookie_lifetime`,
					orderId: 'o:cookie',
				},
				{
					path: [ 'partners', 'programs', 'status', ],
					key: 'affiliateProgramsStatus',
					render: this.renderPayoutStatus,
					title: `${ prefix }partner_status`,
					orderId: 'o:status',
				},
				{
					path: [ 'partners', 'new_partners_conditions', ],
					key: 'affiliateProgramsSingleLevel',
					render: this.SingleLevel,
					title: `${ prefix }single_level_condition`,
				},
				{
					path: [ 'partners', 'new_partners_conditions', ],
					key: 'affiliateProgramsMultiLevel',
					render: this.MultiLevel,
					title: `${ prefix }multi_level_condition`,
				},
				{
					path: [ 'partners', 'programs', 'public_program', ],
					key: 'affiliateProgramsPublicProgram',
					render: this.renderPublicProgram,
					title: `${ prefix }public_program`,
					orderId: 'o:publicProgram',
				},
				{
					path: [ 'partners', 'programs', 'description', ],
					key: 'affiliateProgramsDescription',
					render: this.renderInitialValue,
					title: `${ prefix }description`,
				},
				{
					path: [ 'partners', 'programs', 'created_at', ],
					key: 'affiliateProgramsCreatedAt',
					render: this.renderInitialValue,
					title: `${ prefix }created_at`,
					orderId: 'o:created',
				},
				{
					path: [ 'partners', 'programs', 'updated_at', ],
					key: 'affiliateProgramsUpdatedAt',
					render: this.renderDate,
					title: `${ prefix }updated_at`,
					orderId: 'o:updated',
				},
				{
					path: [ 'partners', 'programs', 'id', ],
					key: 'affiliateProgramsActions',
					render: this.renderActions,
					title: `${ prefix }actions`,
					customAccessCheck: ({ item, }) => {
						return item.access('show') && (item.access('update') || item.access('destroy'));
					},
				},
			].map(({
				orderId: o,
				title: t,
				xtitle: x,
				...item
			}) => ({
				...item,
				...o && {
					order: async () => {
						await this.mf?.orderedToNext(o);
					},
					orderTo: this.mf?.orderedTo(o),
				},
				...t && { title: translate(t), },
				...x && { title: x, },
			}));
		};

		removePayoutList = async (newAffiliateProgramsId = this.state.newAffiliateProgramsId) => {
			if (!newAffiliateProgramsId) {
				console.warn('payout rate list id not found');

				return;
			}

			try {
				await deleteNewPartnerConditionList(newAffiliateProgramsId);
				await this.onDataLoad();
			} catch (error) {
				error?.showErrorNotification?.();
			}
		};

		renderAffiliateProgramsListName = (name, { item, items, data, }) => {
			const {
				partners: { programs, },
			} = data;

			const newAffiliateProgramsId = programs.id;

			if (item?.access?.('index')) {
				return (
					<div
						className="cursor-pointer"
						onClick={ async () => {
					 		await this.editNewAffiliatePrograms(newAffiliateProgramsId);

							await this.save({
								isViewMode: true,
							});
						} }
					>
						<MagicTooltip
							classNameTooltip="tooltip tooltip--large-width"
							content={ name ?? '-' }
							lengthCut={ 20 }
						/>
					</div>
				);
			}

			return "-";
		};

		renderInitialValue = (value, { item, }) => {
			if (item?.access?.('index')) {
				return (
					<MagicTooltip
						classNameTooltip="tooltip tooltip--large-width"
						content={ value ?? '-' }
						lengthCut={ 20 }
					/>
				);
			}

			return "-";
		};
		SingleLevel = (value, { item, }) => {
			const filteredSingleLevel = value.filter(items => items.level !== "2").map(_ => this.props.enums.data?.partners?.TypeEnum?.[+_.type]).join(' , ');

			if (item.access('index')) {
				return filteredSingleLevel ?? '-';
			}

			return "-";

		};
		MultiLevel = (value, { item, }) => {
			const filteredMultiLevel = value.filter(items => items.level !== "1").map(_ => this.props.enums.data?.partners?.TypeEnum?.[+_.type]).join(' , ');

			if (item.access('index')) {
				return filteredMultiLevel ?? '-';
			}

			return "-";

		};

		renderDate = (date, { item, }) => {
			if (item?.access?.('index')) {
				const formatDB = 'YYYY-MM-DD HH:mm:ss';
				const formatOut = 'YYYY-MM-DD HH:mm:ss';

				date = Moment(date, formatDB);

				return date.isValid() ? date.format(formatOut) : "-";
			}

			return "-";
		};

		renderReferralData = (type, { item, }) => {
			if (item.access('index')) {
				return this.props.enums.data?.partners?.ShowReferralDataEnum?.[+type] ?? '-';
			}

			return "-";
		};
		renderRebateData = (type, { item, }) => {
			if (item.access('index')) {
				return this.props.enums.data?.partners?.AllowSeeCreateAndRebateEnum?.[+type] ?? '-';
			}

			return "-";
		};
		renderAmountReferrals = (type, { item, }) => {
			if (item.access('index')) {
				return this.props.enums.data?.partners?.ShowAmountReferralsFundsEnum?.[+type] ?? '-';
			}

			return "-";
		};
		renderPublicProgram = (type, { item, }) => {
			if (item.access('index')) {
				return this.props.enums.data?.partners?.PublicProgramEnum?.[+type] ?? '-';
			}

			return "-";
		};

		renderPayoutStatus = (status, { item, }) => {
			if (item.access('index')) {
				return this.props.enums.data?.partners?.StatusEnum?.[+status] ?? '-';
			}

			return "-";
		};
	filteredOptions = (array, type, level) => {

		return array
			.filter(item => item.type === type && item.level === level)
			.map((item) => {
				const { name, id, } = item;

				return {
					label: name,
					value: id,
				};
			}).reduce((acc, cur, i) => {
				acc = cur;

				return acc;
			}, {});
	}
		editNewAffiliatePrograms = async (id) => {
			const { popup, } = this.props;
			const data = await editNewAffiliateProgramsList(id);
			const dataForEdit = data?.data?.partners;

			const editFormData = {
				...dataForEdit?.programs,
				CPASingle: this.filteredOptions(dataForEdit?.new_partners_conditions, 1, 1),
				CPAMulti: this.filteredOptions(dataForEdit?.new_partners_conditions, 1, 2),
				CPLSingle: this.filteredOptions(dataForEdit?.new_partners_conditions, 2, 1),
				CPLMulti: this.filteredOptions(dataForEdit?.new_partners_conditions, 2, 2),
				InOutSingle: this.filteredOptions(dataForEdit?.new_partners_conditions, 3, 1),
				InOutMulti: this.filteredOptions(dataForEdit?.new_partners_conditions, 3, 2),
				RevshareSingle: this.filteredOptions(dataForEdit?.new_partners_conditions, 4, 1),
				RevshareMulti: this.filteredOptions(dataForEdit?.new_partners_conditions, 4, 2),
			};

			await popup.togglePopup(editFormData);
		}

		renderActions = (value, {
			item,
			items,
			data,
		}) => {
			const {
				translate,
				popup,
			} = this.props;

			const { newAffiliateProgramsId, } = this.state;
			const renderEditButton = () => {
				const {
					partners: { programs, },
				} = data;

				const newAffiliateProgramsId = programs.id;

				const options = {
					className: 'magic-button__item magic-button__item_partners-edit-symbol',
					children: translate('symbols_actions_edit'),
					onClick: async () => await this.editNewAffiliatePrograms(newAffiliateProgramsId),

				};

				return (
					<div className="magic-confirm-wrapper">
						<MagicButton { ...options } />
					</div>
				);
			};

			return (
				<>
					{ item.access('update') && renderEditButton() }
				</>
			);
		};

		renderTable = () => {
			const {
				loaded,
				pages,
				data,
				isEmptyData,
			} = this.state;
			const { translate, } = this.props;

			const options = {
				config: this.affiliateProgramsListTableConfig,
				data,
				head: [
					'affiliateProgramsId',
					'affiliateProgramsName',
					'affiliateProgramsNameClients',
					'affiliateProgramsReferral',
					'affiliateProgramsRebate',
					'affiliateProgramsAmount',
					'affiliateProgramsCookie',
					'affiliateProgramsStatus',
					'affiliateProgramsSingleLevel',
					'affiliateProgramsMultiLevel',
					'affiliateProgramsPublicProgram',
					'affiliateProgramsDescription',
					'affiliateProgramsCreatedAt',
					'affiliateProgramsUpdatedAt',
					'affiliateProgramsActions',

				],
				RowProps: ({ items, }) => {
					return {
						"data-disabled": [ conditionStatus.CLOSED, ].includes(items?.affiliateProgramsStatus?.valueOf),
					};
				},
			};

			return (
				<>
					<MagicFilterPanels
						mf={ this.mf }
						show={ true }
						translate={ translate }
					/>
					<div
						className={ `table-wrapper partners__table ${ isEmptyData ? 'partners__table--empty' : '' }` }
					>
						<Loader
							loaded={ loaded }
							loading={ (<Preloader scale={ 1 } />) }
						>
							<MagicTable { ...options } />
							{ isEmptyData && <div
								className="table__empty-text"
							                 >{ translate("symbols_empty") }</div> }
							<PagePanel
								doText={ this.onPageText }
								doTo={ pageId => `?page=${ pageId }` }
								filter={ pages.filter }
								page={ this.pageId() }
								take={ pages.take }
								total={ pages.total }
								variance={ pages.variance }
								onClick={ this.onPageClick }
							/>
						</Loader>
					</div>
				</>
			);
		};

		render () {
			const {
				enums,
				popup,
			} = this.props;
			const { isViewMode, } = this.state;

			return (
				<>
					{ popup.isOpen && (
						<CreateUpdateAffiliatePrograms
							enums={ enums }
							formData={ popup.data }
							isViewMode={ isViewMode }
							onClose={ async () => {
								await this.save({ isViewMode: false, });
								await popup.togglePopup();

							} }
							onDataLoad={ this.onDataLoad }
						/>
					) }
					{ this.renderTable() }
				</>
			);
		}

		pageId = () => {
			const page = this.query().get('page');

			return Number(page) || 0;
		};

		pageIdAsGET = (pageId) => {
			pageId = pageId === undefined ? this.pageId() : pageId;
			const { filterId, } = this.mf;

			return {
				filterId,
				take: this.state.pages.take,
				skip: pageId * this.state.pages.take,
			};
		};

		onDataLoad = async (pageId) => {
			await this.save({
				loaded: false,
				isEmptyData: false,
			});

			const pageOptions = this.pageIdAsGET(pageId);
			const loaded = (data, meta) => ({ pages, }) => ({
				data,
				pages: { ...pages, ...meta, },
				...!data.length && { isEmptyData: true, },
			});

			try {
				const response = await getNewAffiliateProgramsList(pageOptions);
				const {
					data = [],
					meta: {
						filter = 0,
						total = 0,
					},
				} = response;

				await this.save(loaded(data, {
					filter,
					total,
				}));
			} catch (error) {
				await this.save(loaded([], {
					filter: 0,
					total: 0,
				}));
				error?.showErrorNotification?.();
			}
			await this.save({ loaded: true, });
		};

		onPageClick = ({
			event,
			pageId,
			pageIs,
		}) => {
			this.onDataLoad(pageId);
		};

	onPageText = (pageId, pageIs) => {
		const { translate, } = this.props;
		const {
			current,
			first,
			prev,
			next,
			last,
		} = pageIs;
		const {
			skipped,
			taken,
			filter,
			total,
		} = pageIs;

		if (skipped || taken) {
			const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : "?";
			const text = skipped ? translate("partners_pages_items") : "";

			return skipped ? `${ text }: ${ id }` : id;
		} else if (filter || total) {
			const id = Number.isInteger(pageId) ? `${ pageId }` : "?";
			const text = translate(filter ? "partners_pages_filtered" : "partners_pages_total");

			return `${ text }: ${ id }`;
		} else if (first || prev || next || last) {
			return "";
		} else if (current) {
			return `${ pageId + 1 }`;
		}

		return `${ pageId + 1 }`;

	};
}

export default withRouter(withLocalize(AffiliateProgramsTable));