import { get, post, } from "../../../components/Magic";
import {
	GET_NOVATALKS_END_CONVERSATION,
	GET_NOVATALKS_MANAGER_STATUS,
	GET_NOVATALKS_MESSAGE_LIST,
	GET_NOVATALKS_QUICK_MESSAGE_LIST, GET_NOVATALKS_QUICK_MESSAGE_LIST_BY_LANG, GET_NOVATALKS_UPDATE_EMAIL,
} from "../../../apiRoutes";

export const getAllMessages = async ( take, skip) =>
	await get(`${ GET_NOVATALKS_MESSAGE_LIST }?take=${ take }&skip=${ skip }`);

export const getQuickAnswers = async options =>
	await get(`${ GET_NOVATALKS_QUICK_MESSAGE_LIST }`, options);
export const getQuickAnswersByLang = async contact_id =>
	await get(`${ GET_NOVATALKS_QUICK_MESSAGE_LIST_BY_LANG }/${ contact_id }`);

export const getMessagesById = async conversationId =>
	await get(`${ GET_NOVATALKS_MESSAGE_LIST }/by-conversation/${ conversationId }`);
export const getMessagesByAccounttId = async accountId =>
	await get(`${ GET_NOVATALKS_MESSAGE_LIST }/by-account/${ accountId }`);
export const sendMessage = async data =>
	await post(`${ GET_NOVATALKS_MESSAGE_LIST }`, data);
export const getOnlineTechManagers = async () =>
	await get(`${ GET_NOVATALKS_MANAGER_STATUS }/get-online-technical-managers`);

export const switchResponsibleManager = async id =>
	await post(`${ GET_NOVATALKS_END_CONVERSATION }/${ id }/set-responsible-manager`);
export const switchToTechSupport = async (dialog_id, user_id) =>
	await post(`${ GET_NOVATALKS_END_CONVERSATION }/${ dialog_id }/set-technical-manager/${ user_id }`);
export const markReviewedMessage = async id =>
	await post(`${ GET_NOVATALKS_MESSAGE_LIST }/${ id }/mark-reviewed`);

export const managerStatusOnline = async () =>
	await post(`${ GET_NOVATALKS_MANAGER_STATUS }/set-online`);
export const managerStatusOffline = async () =>
	await post(`${ GET_NOVATALKS_MANAGER_STATUS }/set-offline`);

export const endConversation = async conversationId =>
	await post(`${ GET_NOVATALKS_END_CONVERSATION }/${ conversationId }/end-dialog`);

export const updateClientEmail = async contactId =>
	await post(`${ GET_NOVATALKS_UPDATE_EMAIL }/${ contactId }/email-request`);
export const updateClientLanguage = async (id, language) =>
	await post(`${ GET_NOVATALKS_UPDATE_EMAIL }/${ id }/set-language/${ language }`);