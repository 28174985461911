/* eslint-disable sort-keys, no-shadow  */
import React, { memo, } from "react";
import PropTypes from "prop-types";
import { MagicInput, } from "../../../../components/Magic";
import "./styles.scss";

const Input = ({
	name = "",
	value = "",
	onChange = () => {},
	label = "",
	placeholder = "placeholder",
	onValid = () => {},
	wrapperClassName = "",
	disabled = false,
	isRequired = false,
}) => {
	return (
		<div className={ `input__wrapper ${ wrapperClassName }` }>
			{!!label.length && (
				<label className="">{isRequired ? `${ label }*` : label}</label>
			)}
			<MagicInput
				className="input__field"
				disabled={ disabled }
				name={ name }
				placeholder={ placeholder }
				sizeable={ false }
				value={ value }
				onChange={ value => onChange({ target: { name, value, }, }) }
				onValid={ value => onValid(value) }
			/>
		</div>
	);
};

Input.propTypes = {
	disabled: PropTypes.oneOfType([ PropTypes.bool, PropTypes.number, ]),
	isRequired: PropTypes.bool,
	label: PropTypes.string,
	name: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.string,
	wrapperClassName: PropTypes.string,
	onChange: PropTypes.func,
	onValid: PropTypes.func,
};

export default memo(Input);