import React, { Component, } from 'react';
import { Collapse, } from "reactstrap";

import { Input, Select, } from "../../../../../components";
import { setErrorClass, findOptionByValue, } from "../../../../../utils/helpers";
import { MagicButton, } from "../../../../../../../components/Magic";
import { debounce, } from "lodash";
import { validateCreatePromoFields, } from "../../../helpers/validation-helpers";

class TargetPageNameItemForm extends Component {
    constructor () {
        super();

        this.state = {
            isOpen: false,
            loaded: true,
            formData: {
                language_id: {},
                name: '',
            },
            errors: [],
        };
    }

    async componentDidMount () {
        await this.setStateFromProps();
    }

    get formIsUpdated () {
        //easy way to compare 2 objects
        const currentFormData = JSON.stringify(this.state.formData);
        const previousFormData = JSON.stringify(this.props.formData);

        return currentFormData !== previousFormData;
    }
    get itemExistInDb () {
        return !!this.state.formData?.id; //check id because it's created in DB
    }
    save = async state => new Promise(next => this.setState(state, next));

    toggleLoaded = loadData => this.save({ loadData, });

    toggleDropdown = () => this.save({ isOpen: !this.state.isOpen, });

    handleOnChange = async (event) => {
        const { name, value, } = event.target;

        await this.save(state => ({
            ...state,
            errors: state.errors.filter(item => item !== name),
            formData: {
                ...state.formData,
                [name]: value,
            },
        }));
    };

    setStateFromProps = async () => {
        const { formData, } = this.props;

        await this.save({ formData, });
    }

    getSendData = (formData, additionalEntries = {}) => {
        const sendData = Object.entries(formData).reduce((accumulator, entry) => {
            const [ key, value, ] = entry;

            if (key === 'status' || key === 'language_id') { //select values
                return { ...accumulator, [key]: value.value, };
            }

            if (key === 'id') {
                //don't need to send id
                return { ...accumulator, };
            }

            return { ...accumulator, [key]: value, };
        }, {});

        return { ...sendData, ...additionalEntries, };
    };

    saveData = async () => {
        try {
            const { updateTargetPageName, createTargetPageName, } = this.props;
            const {
                formData,
            } = this.state;
            const { id, } = formData;
            const fieldsAreValid = await validateCreatePromoFields(formData, this.save);

            if (!fieldsAreValid) {
                return false;
            }

            await this.toggleLoaded(false);
            const formSendData = this.getSendData(formData);
            const request = this.itemExistInDb ? () => updateTargetPageName(id, formSendData) : () => createTargetPageName(formSendData);
            const response = await request();

            response.language_id = findOptionByValue(this.props.languageOptions, response.language_id);
            await this.save({ formData: response, });
            await this.toggleLoaded(true);
        } catch (error) {
            await this.toggleLoaded(true);
            console.log(error);
            error.showErrorNotification?.();
        }
    }
    renderItem () {
        const {
            translate,
            isViewMode,
            deleteNameFormDataItem,
            remainingLanguageOption,
        } = this.props;

        const { language_id, name, } = this.state.formData;
        const { errors, loaded, } = this.state;

        const showButtons = !isViewMode && (this.itemExistInDb && this.formIsUpdated) || !this.itemExistInDb;

        return (
            <div className="promo-popup__group promo-popup__group_grey py-3">
                {!isViewMode && <div
                        className="promo-popup__button promo-popup__button_delete"
                        onClick={ deleteNameFormDataItem }
                />
                }
                <div className="promo-popup__row">
                    <Select
                        className={ setErrorClass(errors, `language_id`) }
                        disabled={ isViewMode }
                        isRequired={ true }
                        label={ translate("partner_target_page_language_in_pa") }
                        name="language_id"
                        options={ remainingLanguageOption }
                        placeholder={ translate("partners_target_page_language_placeholder") }
                        value={ language_id }
                        onChange={ e => this.handleOnChange(e) }
                    />
                </div>
                <div className="promo-popup__row">
                    <Input
                        disabled={ isViewMode }
                        isRequired={ true }
                        label={ translate(`partner_target_page_name_in_pa`) }
                        name={ 'name' }
                        placeholder={ translate(`partners_create_symbol_name_placeholder`) }
                        value={ name }
                        wrapperClassName={ setErrorClass(errors, `name`) }
                        onChange={ e => this.handleOnChange(e) }
                        onValid={ (value) => {
                            if (value.length > 60) {
                                throw new Error('XXX');
                            }
                        } }
                    />
                </div>
                { showButtons && <div className={ 'pt-4 mb-3 d-flex' }>
                    <MagicButton
                        children={ translate(
                            `${ this.itemExistInDb ? "template_management_btn_update" : "partners_save_btn" }`,
                        ) }
                        className="magic-button__item magic-button__item_promo-popup-save ml-auto"
                        disabled={ !loaded }
                        onClick={ debounce(this.saveData, 500) }
                    />
                    <MagicButton
                        children={ translate("partners_cancel_btn") }
                        className="magic-button__item magic-button__item_promo-popup-cancel"
                        disabled={ !loaded }
                        onClick={ this.setStateFromProps }
                    />
                </div>}
            </div>
        );
    }

    renderItemInDropdown () {
        const { isOpen, formData: { language_id, }, } = this.state;
        const languageName = language_id.label;

        return (
            <>
                <div className={ 'collapse-toggle' } onClick={ this.toggleDropdown }>{
                    <p className={ `collapse-toggle__label ${ isOpen && 'collapse-toggle__label_active' }` }>
                        {languageName}
                    </p>
                }</div>
                <Collapse isOpen={ isOpen }>
                    {this.renderItem()}
                </Collapse>
            </>
        );
    }
    render () {
       return (
           <>{this.itemExistInDb ? this.renderItemInDropdown() : this.renderItem()}</>
       );
    }
}

export default TargetPageNameItemForm;