import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withLocalize } from "react-localize-redux";
import Preloader from "../../../../components/LoadingHOC/Preloader";
import PagePanel from "../../../tima/components/Pages/PagePanel";
import Loader from "../../../tima/components/Loader";
import {
  MagicFilterPanels,
  MagicTable,
  Mentor,
} from "../../../../components/Magic";
import { listAccountService } from "../../../../services/ListAccountDataService";

class OpenOrders extends Component {
  constructor (props) {
    super(props);

    this.state = {
      openOrders: {
          data: [],
          hash: null,
      },
      filterLastChange: Date.now(),
      loaded: false,
      pages:            {
        filter: 0,
        take: 50,
        total: 0,
        variance: 2,
      },
    };

    const mf = new Mentor({
      owner: this,
      serviceId: 81,
      translate: this.props.translate,
    });

    Object.defineProperty(this, 'mf', { get: () => mf, });
    this.props.mf(mf);
  }

  // eslint-disable-next-line react/sort-comp, require-await
  save = async state => new Promise(next => this.setState(state, next));

  // eslint-disable-next-line require-await
  mfChanged = async () => {
    await this.save({ filterLastChange: Date.now(), });
  };

  mfDoing = async () => {
    await this.save({ loaded: false, });
  };

  mfDone = async () => {
    await this.mfChanged();
    await this.onDataLoad();
  };

  query = () => new URLSearchParams(this.props?.location?.search);

  openOrdersChanges = async (openOrders) => {
      if (openOrders.hash === this.state?.openOrders?.hash) return `${this.constructor.name}.openOrdersChanges: false`;
      const { data, meta, hash } = openOrders;
      await this.save({
          openOrders: { data, hash },
          pages: {
              ...this.state.pages,
              filter: meta?.filter,
              total: meta?.total,
          }
      });
      return `${this.constructor.name}.openOrdersChanges: true`;
  };

  componentDidMount = async () => {
    this.mf.subscribe({
      changed: this.mfChanged,
      // changing: ,
      cleaned: this.mfDone,
      cleaning: this.mfDoing,
      loaded: this.mfDone,
      loading: this.mfDoing,
      saved: this.mfDone,
      saving: this.mfDoing,
    }, this);
    await this.mf.init({ doEmit: true, });
    listAccountService.subscribe('tradingAccountsOpenOrders', this.openOrdersChanges, this);
    // await this.mf.emit('change', {});
    // await this.onDataLoad(); // TODO: moved to mfDone
  };

  componentWillUnmount = () => {
    this.mf.unsubscribe([
      this.mfChanged,
      this.mfDoing,
      this.mfDone,
    ], this);
    listAccountService.unsubscribe('tradingAccountsOpenOrders', this.openOrdersChanges, this);
  };

  get openOrdersConfig () {
    const { translate, } = this.props;

    return [ {
      accessChecked: true,
      key: 'ticket',
      path: [ 'TRADES', 'TICKET', ],
      render: this.renderInlineValue,
      title: `list_accounts_table_orders_order`,
    }, {
      accessChecked: true,
      key: 'login',
      path: [ 'TRADES', 'LOGIN', ],
      render: this.renderInlineValue,
      title: `list_accounts_table_orders_login`,
    }, {
      accessChecked: true,
      key: 'open_time',
      path: [ 'TRADES', 'OPEN_TIME', ],
      render: this.renderInlineValue,
      title: `list_accounts_table_orders_open_time`,
    }, {
      accessChecked: true,
      key: 'cmd',
      path: [ 'TRADES', 'CMD', ],
      render: this.renderEnumField('core', 'Mt4TradesCmdTypeEnum'),
      title: `list_accounts_table_orders_cmd`,
    }, {
      accessChecked: true,
      key: 'volume',
      path: [ 'TRADES', 'VOLUME', ],
      render: this.renderNumberFixedFormat({ fieldName: 'volume' }),
      title: `list_accounts_table_orders_volume`,
    }, {
      accessChecked: true,
      key: 'symbol',
      path: [ 'TRADES', 'SYMBOL', ],
      render: this.renderInlineValue,
      title: `list_accounts_table_orders_symbol`,
    }, {
      accessChecked: true,
      key: 'open_price',
      path: [ 'TRADES', 'OPEN_PRICE', ],
      render: this.renderNumberFixedFormat({ fieldName: 'open_price' }),
      title: `list_accounts_table_orders_open_price`,
    }, {
      accessChecked: true,
      key: 'sl',
      path: [ 'TRADES', 'SL', ],
      render: this.renderNumberFixedFormat({ fieldName: 'sl' }),
      title: `list_accounts_table_orders_sl`,
    }, {
      accessChecked: true,
      key: 'tp',
      path: [ 'TRADES', 'TP', ],
      render: this.renderNumberFixedFormat({ fieldName: 'tp' }),
      title: `list_accounts_table_orders_tp`,
    }, {
      accessChecked: true,
      key: 'commission',
      path: [ 'TRADES', 'COMMISSION', ],
      render: this.renderNumberFixedFormat({ fieldName: 'commission' }),
      title: `list_accounts_table_orders_commission`,
    }, {
      accessChecked: true,
      key: 'swaps',
      path: [ 'TRADES', 'SWAPS', ],
      render: this.renderNumberFixedFormat({ fieldName: 'swaps' }),
      title: `list_accounts_table_orders_storage`,
    }, {
      accessChecked: true,
      key: 'profit',
      path: [ 'TRADES', 'PROFIT', ],
      render: this.renderNumberFixedFormat({ fieldName: 'profit' }),
      title: `list_accounts_table_orders_profit`,
    }, {
      accessChecked: true,
      key: 'comment',
      path: [ 'TRADES', 'COMMENT', ],
      render: this.renderInlineValue,
      title: `list_accounts_table_orders_comment`,
    }, {
      accessChecked: true,
      path: [ 'TRADES', 'DIGITS', ],
      key: 'digits',
    }, ].map(({
      title: t,
      xtitle: x,
      ...item
    }) => ({
      ...item,
      ...x && { title: x },
      ...t && { title: translate(t) },
    }));
  }

  renderInlineValue = (value, { item, items }) => {
    const defaultValue = '-';

    if (item?.access?.('index')) {
      return value ?? defaultValue;
    }
    return defaultValue;
  };

  renderNumberFixedFormat = ({ fieldName }) => (value, { item, items }) => {
    const defaultDigits = 2;
    const defaultValue = '-';
    let digits;

    switch (fieldName) {
      case 'open_price':
      case 'sl':
      case 'tp': {
        digits = items?.['digits']?.valueOf ?? defaultDigits;
        break;
      }
      case 'commission':
      case 'swaps':
      case 'profit':
      case 'volume': {
        digits = 2; // HARD CODE for this fields in MT4/MT5
        break;
      }
      default: {
        digits = defaultDigits;
        break;
      }
    }

    if (item?.access?.('index')) {
      return (+value).toFixed(digits);
    }
    return defaultValue;
  };

  renderEnumField = (path, enumName) => (value, { item }) => {
    const defaultValue = '-';

    if (item?.access?.('index')) {
      const enums = this.props?.enums?.[path]?.[enumName];
      return enums?.[value] ?? defaultValue;
    }
    return defaultValue;
  };

  render = () => {
    const { openOrders: { data }, } = this.state;
    const options = {
      config: this.openOrdersConfig,
      data,
      head:   [
        'ticket',
        'login',
        'open_time',
        'cmd',
        'volume',
        'symbol',
        'open_price',
        'sl',
        'tp',
        'commission',
        'swaps',
        'profit',
        'comment',
      ],
    };

    return (
      <>
        <MagicFilterPanels
          mf={ this.mf }
          show={ true }
          translate={ this.props.translate }
        />

        <div className='orders-page open-orders-page'>
          <Loader
            loaded={this.state.loaded}
            loading={(<Preloader scale={this.props.scale}/>)}
            translate={this.props.translate}
          >
            <MagicTable {...options} />

            <PagePanel
              filter={this.state.pages.filter}
              take={this.state.pages.take}
              total={this.state.pages.total}
              variance={this.state.pages.variance}
              page={this.pageId()}
              onClick={this.onPageClick}
              doText={this.onPageText}
              doTo={(pageId) => `?page=${ pageId }`}
            />
          </Loader>
        </div>
      </>
    );
  };

  pageId = () => {
    const defaultPageId = 0;

    try {
      const page = this.query().get('page');

      return Number(page) || defaultPageId;
    } catch (e) {
      return defaultPageId;
    }
  };

  pageIdAsGET = (pageId) => {
    pageId = pageId === undefined ? this.pageId() : pageId;
    const { filterId, } = this.mf;
    const result = {
      filterId,
      take: this.state.pages.take,
      skip: pageId * this.state.pages.take,
    };
    return result;
  };

  onDataLoad = async (pageId) => {
    const loaded = (data, meta, hash) => ({ pages, }) => ({
        openOrders: { data, hash },
        pages: { ...pages, ...meta, },
    });
    await this.save({ loaded: false, });
    try {
      const pageOptions = this.pageIdAsGET(pageId);
      const { id, } = this.props;
      const { data, meta, hash } = await listAccountService.tradingAccountsOpenOrders(id, pageOptions);
      const { filter = 0, total = 0 } = meta;
      await this.save(loaded(data, { filter, total, }, hash));  // no filter data on this page => filter: total - это была временная заплатка
    } catch (error) {
      await this.save(loaded([], { filter: 0, total: 0, }));
      error?.showErrorNotification?.();
    }
    await this.save({ loaded: true, });
  };

  onPageClick = ({ event, pageId, pageIs, }) => {
    // event.preventDefault();
    this.onDataLoad(pageId);
  };

  onPageText = (pageId, pageIs) => {
    const { translate } = this.props;
    const { current, first, prev, next, last, } = pageIs;
    const { skipped, taken, filter, total, } = pageIs;
    if (skipped || taken) {
      const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : '?';
      const text = skipped ? translate('partners_pages_items') : '';
      return skipped ? `${ text }: ${ id }` : id;
    } else if (filter || total) {
      const id = Number.isInteger(pageId) ? `${ pageId }` : '?';
      const text = translate(filter ? 'partners_pages_filtered' : 'partners_pages_total');
      return `${ text }: ${ id }`;
    } else if (first || prev || next || last) {
      return '';
    } else if (current) {
      return `${ pageId + 1}`;
    } else {
      return `${ pageId + 1}`;
    }
  };
}

export default withRouter(withLocalize(OpenOrders));
