import React, { Component, } from "react";

import { BonusesHeader, } from "../components/BonusesHeader/BonusesHeader";
import ClientsWithBonusesListTable from "./table/ClientsWithBonusesListTable";
import { showExportToExcelError, } from "../../../components/Magic";

import { breadcrumbs4Bonuses, } from "../config/BreadcrumbsConfig";
import { BONUSES_EXPORT_DATA, } from "./consts/BONUSES_EXPORT_DATA";

import { ClIENTS_WITH_BONUSES, } from "../../../apiRoutes";
import { securedRequest, } from "../../../services/RequestService";
import PermissionService from "../../../services/PermissionService";

export class ClientsWithBonusesList extends Component {
	constructor () {
		super();

		this.state = {
			filter_id: null,
		};
	}
	save = async state => new Promise(next => this.setState(state, next));
	handleFilterIdChange = filter_id => this.save({ filter_id, });
	getPermissions = (action) => {
		const pm = PermissionService.calc([ {
			path: [ 'core', 'bonus_program_to_account', ],
			key: 'user',
		}, ]);

		return pm.user.access(action);
	}

	exportToExcelBonuses = activeTab => () => {
		if (activeTab) {
			const { filter_id, } = this.state;
			const payload = BONUSES_EXPORT_DATA;
			const url = `${ ClIENTS_WITH_BONUSES }/export-to-excel`;

			if (filter_id) {
				payload.filter_id = filter_id;
			}

			securedRequest
				.post(url, payload)
				.then((res) => {
					fileDownload(res.data, res.headers.filename);
				})
				.catch((error) => {
					showExportToExcelError(error);
				});
		}
	};

	renderActions = (translate) => {
		const excel = this.getPermissions("exportToExcel");

		return (
			<div className=" top-button-area ">
				{excel && (
					<div
						className="button bordered "
						onClick={ this.exportToExcelBonuses() }
					>
						{translate(`managerPage_export_to_excel`)}
					</div>
				)}
			</div>
		);
	};

	render () {
		const {
			translate,
		} = this.props;

		return (
			<div className="partner-promo">
				<BonusesHeader
					actions={ this.renderActions(translate) }
					breadcrumbsItems={ breadcrumbs4Bonuses }
					title={ "menu_bonuses_client_list" }
					translate={ translate }
				/>
				<ClientsWithBonusesListTable
					getPermissions={ this.getPermissions }
					handleFilterIdChange={ this.handleFilterIdChange }
					translate={ translate }
				/>
			</div>
		);
	}
}