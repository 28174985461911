import {
  itemsBuild,
  itemsDrain,
  itemsResort,
  CFG_ITEM_TYPE,
} from '../Config';
// import {} from '../Mentor/withDropDown';

const fields = [
  'positions.Position_ID',
  'deals.openTime',
  'deals.closeTime',
  'positions.Symbol',
];

const f_all = {
  'f+': fields,
  'o=': [ '=', '!=', ],
};

const o_all = {
  'f+': fields,
};

export default {
  id: 100,
  ...[

    // filters
    ...[ {
      f: 'positions.Position_ID',
      id: 'f:pPosition_ID',
      ...f_all,
    }, {
      f: 'deals.openTime',
      id: 'f:dOpenTime',
      ...f_all,
    }, {
      f: 'deals.closeTime',
      id: 'f:dCloseTime',
      ...f_all,
    }, {
      f: 'positions.Symbol',
      id: 'f:pSymbol',
      ...f_all,
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

    // orders
    ...[ {
      f: '',
      id: 'o:',
      off: true, // TODO:
      ...o_all,
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

    // panels
    ...[ {
      ii: 'f:pPosition_ID',
      t: 'magic_filter_translate_list_accounts_tradings_mt5_closed_positions_position',
    }, {
      ii: 'f:dOpenTime',
      t: 'magic_filter_translate_list_accounts_tradings_mt5_closed_positions_open_time',
    }, {
      ii: 'f:dCloseTime',
      t: 'magic_filter_translate_list_accounts_tradings_mt5_closed_positions_close_time',

    }, {
      ii: 'f:pSymbol',
      t: 'magic_filter_translate_list_accounts_tradings_mt5_closed_positions_symbol',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

  ]
  .map(itemsBuild)
  .reduce(itemsDrain, [])
  .reduce(itemsResort, {}),

  path: 'core/trading-accounts/get-closed-positions/1',
  widget: false,
};