import { securedRequest } from '../../../services/RequestService/index';
import {
    GET_TASK_USERS,
    SET_TASK,
    DELETE_ALL_CHECKLIST,
    DELETE_CHECKLIST,
    UPLOAD_PARTICIPANTS_TASK,
    SET_CHECKLIST,
    GET_TASK_URGENCY,
    GET_TASK_STATES,
    GET_TASK_TYPES,
    GET_DOCUMENT,
    UPLOAD_DOCUMENT_TASK,
    GET_CHECKLIST_ALL,
    GET_COMMENT_TASK,
    GET_COMMENT,
    GET_TASKS,
    GET_SELECT_MODELS,
    GET_TASKS_TYPES,
    // GET_ACCOUNT_LABELS,
    // GET_RESULT_LAST_CONTACT,
    // GET_TYPE_CLIENT_DROP_DOWN,
    GET_RESPONSIBLES,
    GET_TASK_RESPONSIBLES,
    GET_COMMENTS_DOCUMENTS,
    GET_ASSISTANTS,
    GET_TASK_URGENCY_DROPDOWN,
    GET_TASK_STATES_DROPDOWN,
} from "../apiRoutes";
import {GET_FILTER_HASH, SET_USER_FILTER, GET_ACCOUNT} from "../../../apiRoutes";

import { get, post } from "../../../components/Magic";
import { cachingService, } from '../../../services/CachingService';
export { getAccountLabels as getAccountLabelsRequest } from '../../clients/services/AccountService.js';
export { getAccountStatus as getResultLastContact } from '../../clients/services/AccountService.js';
export { getAccountTypes as getTypeClient } from '../../clients/services/AccountService.js';

export const getTasksAsync = async (options) => {
    const url = GET_TASKS;
    return get(url, options);
};

export const updateCustomerSign = async (data, id) => {
    const url = `${GET_ACCOUNT}/${id}`;
    return securedRequest.put(url, data)
        .then(
            (response) => Promise.resolve(response.data),
            (error) => Promise.reject(error.response.data)
        );
};

// TODO: check if needed
export const getFilterHash = async (id) => {
    const url = `${GET_FILTER_HASH}/${id}`;
    return securedRequest.get(url).then(
        (response) => Promise.resolve(response),
        (error) => Promise.reject(error.response.data)
    );
};

// TODO: check if needed
export const getTasksByFilterHash = async (id, output) => {
    const url = `${GET_TASKS}/filter/${id}?${output}`;
    return securedRequest.get(url)
        .then(
            (response) => Promise.resolve(response),
            (error) => Promise.reject(error.response.data)
        );
};

export const postFilterData = async (params = 'data=[]') => {
    const url = `${GET_TASKS}/filter`;
    return securedRequest.post(url, params)
        .then(
            (response) => Promise.resolve(response),
            (error) => Promise.reject(error.response.data)
        );
};

export const getTasks = async (output) => {
    const url = `${GET_TASKS}?${output}`;
    return cachingService.one(url);
};

export const setFilterHash = async (filters) => {
    const url = `${SET_USER_FILTER}`;
    return securedRequest.post(url, {"filter_hash_id":filters.data.id,"type":"2"} ).then(
        (response) => Promise.resolve(response),
        (error) => Promise.reject(error.response.data)
    );
};

export const updateTaskClient = async (data, id) => {
    const url = `${SET_TASK}/${id}`;
    return securedRequest.put(url, data)
        .then(
            (response) => Promise.resolve(response.data),
            (error) => Promise.reject(error.response.data)
        );
};

export const getTaskUsers = async (id, role) => {
    const url = `${GET_TASK_USERS}/${id}/${role}`;
    return cachingService.one(url);
};

export const deleteAllChecklist = async (id) => {
    return securedRequest.delete(`${DELETE_ALL_CHECKLIST}/${id}`)
        .then(
            (response) => Promise.resolve(response.data),
            (error) => Promise.reject(error.response.data)
        );
};

export const deleteChecklist = async (id) => {
    return securedRequest.delete(`${DELETE_CHECKLIST}/${id}`)
        .then(
            (response) => Promise.resolve(response.data),
            (error) => Promise.reject(error.response.data)
        );
};

export const uploadParticipants = async ({ role_id, task_id, user_id }) => {
    return securedRequest.post(UPLOAD_PARTICIPANTS_TASK, { role_id, task_id, user_id })
        .then(
            async (response) => {
                await cachingService.clean({ match: ({ isOne, key }) => isOne && key.indexOf(`GET:${ GET_TASKS }/${ task_id }`)>0, });
                await cachingService.clean({ match: ({ isOne, key }) => isOne && key.indexOf(`GET:${ GET_TASK_USERS }/${ task_id }/${ role_id }`)>0, });
                return Promise.resolve(response.data);
            },
            (error) => Promise.reject(error.response.data)
        );
};

export const deleteParticipants = async (id, task_id, role_id) => {
    return securedRequest.delete(`${UPLOAD_PARTICIPANTS_TASK}/${id}`)
        .then(
            async (response) => {
                await cachingService.clean({ match: ({ isOne, key }) => isOne && key.indexOf(`GET:${ GET_TASKS }/${ task_id }`)>0, });
                await cachingService.clean({ match: ({ isOne, key }) => isOne && key.indexOf(`GET:${ GET_TASK_USERS }/${ task_id }/${role_id}`)>0, });
                return Promise.resolve(response.data);
            },
            (error) => Promise.reject(error.response.data)
        );
};

export const createCheckList = async (data) => {
    return securedRequest.post(SET_CHECKLIST, data)
        .then(
            (response) => Promise.resolve(response.data),
            (error) => Promise.reject(error.response.data)
        );
};

export const updateCheckList = async (data, id) => {
    return securedRequest.put(`${SET_CHECKLIST}/${id}`, data)
        .then(
            (response) => Promise.resolve(response.data),
            (error) => Promise.reject(error.response.data)
        );
};

export const getTaskUrgency = async () => {
    const url = GET_TASK_URGENCY;
    return cachingService.dictionary(url);
};

export const getTaskStates = async () => {
    const url = GET_TASK_STATES;
    return cachingService.dictionary(url);
};

export const getTaskTypes = async () => {
    const url = GET_TASK_TYPES;
    return cachingService.one(url);
};

export const getDocumentTaskID = async (id = 0) => {
    const url = `${GET_DOCUMENT}/${id}`;
    return cachingService.one(url);
};

export const uploadDocument = async (data, taskId) => {
    return securedRequest.post(UPLOAD_DOCUMENT_TASK, data)
        .then(
            async (response) => {
                await cachingService.clean({ match: ({ isOne, key }) => isOne && key.indexOf(`GET:${ GET_DOCUMENT }/${ taskId }`)>0, });
                return Promise.resolve(response.data);
            },
            (error) => Promise.reject(error.response.data)
        );
};

export const deleteDocument = async (id) => {
    return securedRequest.delete(`${UPLOAD_DOCUMENT_TASK}/${id}`)
        .then(
            (response) => Promise.resolve(response.data),
            (error) => Promise.reject(error.response.data)
        );
};

export const getCheckListTaskID = async (id = 0) => {
    const url = `${GET_CHECKLIST_ALL}/${id}`;
    return cachingService.one(url);
};

export const createComment = async ({ name, user_id, task_id, description, parent_id }, form) => {
    return post(GET_COMMENT_TASK, { name, user_id, task_id, description, ...parent_id && { parent_id } }, form)
        .then(
            async (response) => {
                await cachingService.clean({ match: ({ isOne, key }) => isOne && key.indexOf(`GET:${ GET_COMMENT }/${ task_id }`)>0, });
                return Promise.resolve(response.data);
            },
            (error) => Promise.reject(error.response.data)
        );
};

export const updateComment = async ({ description, name, parent_id, task_id, user_id }, id) => {
    return securedRequest.put(`${GET_COMMENT_TASK}/${id}`, { description, name, parent_id, task_id, user_id })
        .then(
            async (response) => {
                await cachingService.clean({ match: ({ isOne, key }) => isOne && key.indexOf(`GET:${ GET_COMMENT }/${ task_id }`)>0, });
                return Promise.resolve(response.data);
            },
            (error) => Promise.reject(error.response.data)
        );
};

export const updateTask = async (data, id) => {
    return securedRequest.put(`${SET_TASK}/${id}`, data)
        .then(
            async (response) => {
                await cachingService.clean({ match: ({ isOne, key }) => isOne && key.indexOf(`GET:${ SET_TASK }/${ id }`)>0, });
                return Promise.resolve(response.data);
            },
            (error) => Promise.reject(error.response.data)
        );
};
export const getAllManagerSelectModels = async () => {
    const url = GET_SELECT_MODELS;
    return cachingService.one(url);
};
export const getTasksTypesRequest = async () => {
    const url = GET_TASKS_TYPES;
    return cachingService.one(url);
};
export const getTasksStatusRequest = getTaskStates;

export const getCommentDocument = async (file) => {
    const response = await securedRequest.get(`${GET_COMMENTS_DOCUMENTS}/${file}`, { responseType: 'blob' });

    return response;
};

export const getAssistants = async () => {
    try {
        const { data } = await securedRequest.get(GET_ASSISTANTS);

        return data;
    } catch (err) {
        console.error(err);
    }
};

export const getResponsibles = async () => {
    try {
        const url = GET_RESPONSIBLES;
        return cachingService.one(url);
    } catch (error) {
        console.log(error)
    }
};

export const getTaskResponsibles = async () => {
    try {
        const url = GET_TASK_RESPONSIBLES;
        return cachingService.one(url);
    } catch (error) {
        console.log(error)
    }
};

export const getTaskUrgencyDropdown = async () => {
    try {
        const url = GET_TASK_URGENCY_DROPDOWN;
        return cachingService.one(url);
    } catch (error) {
        console.log(error)
    }
};

export const getTaskStatesDropdown = async () => {
    try {
        const url = GET_TASK_STATES_DROPDOWN;
        return cachingService.one(url);
    } catch (error) {
        console.log(error)
    }
};
