import React from "react";
import ToolbarPanelText from './ToolbarPanelText';
import ToolbarPanelLink from './ToolbarPanelLink';
import './styles.scss';

export default function ToolbarPanelItem ( props ) {
    const { link, class4Item, } = props;
    return (
        <li
            className={ class4Item || `toolbar-panel__item` }
        >
            { link ? <ToolbarPanelLink { ...props } /> : <ToolbarPanelText { ...props } /> }
        </li>
    );
}
