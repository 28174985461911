import React from 'react';
import { withLocalize } from "react-localize-redux";
import NotificationService from "../../../services/NotificationService";

import { sendEmails, sendSms, sendEmailsByAccountId } from "../../dashboard/actions";
import { MagicSelect } from '../../../components/Magic';

class MessagePopup extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            selectedType:     "1",
            selectedTemplate: this.props?.templates[1][0]?.value,
            subject:          '',
            subjectD:         '',
            errorSubject:     false,
            errorTemplate:    false,
            writeType:        this.props.writeType,
            templates:        this.props.templates,
            userId:           this.props.userId,
        };
    }

    componentDidMount () {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount () {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    save = async (state) => new Promise((next) => this.setState(state, next));

    handleClickOutside = (e) => {
        const isInside = e.path.includes(document.querySelector('.popup_DropdownItem')) ||
                         e.path.includes(document.querySelector('.MuiPopover-root'));

        if (!isInside) {
            this.props.close();
        }
    }

    renderInput () {
        switch (this.state.selectedType) {
            case '1': {
                return (
                    <div className='write-action-block'>
                        <span>{this.props.translate(`dashboard_subject`)}</span>
                        <div className='input'>
                            <input
                                className={`input-subject ${this.state.errorSubject ? 'error' : ''}`}
                                disabled={this.state.subjectD}
                                name='input-subject'
                                placeholder={this.props.translate('template_management_enter_subject')}
                                type='text'
                                value={this.state.subject}
                                onChange={this.onChangeSubject}
                            />
                        </div>
                    </div>
                );
            }
            case '2': {
                return (
                    <div className='write-action-block'>
                        <span>{this.props.translate(`dashboard_subject`)}</span>
                        <div className='input'>
                            <input
                                className={`input-subject ${this.state.errorSubject ? 'error' : ''}`}
                                name='input-subject'
                                placeholder={this.props.translate('template_management_enter_subject')}
                                type='text'
                                value={this.state.subject ?? ''}
                                onChange={this.onChangeSubject}
                            />
                        </div>
                    </div>
                );
            }
            case '3': {
                return false
            }
            default: {
                return false
            }
        }
    }

    onChangeSubject = async (event) => {
        const value = event.target.value;

        await this.save({
            subject:      value,
            errorSubject: false,
        });
    }

    writePrivateAction = async () => {
        const users = [this.state.userId];

        switch (this.state.selectedType) {
            case '1': {
                if (!this.state.subject) {
                    await this.save(
                        {
                            errorSubject: true,
                        }
                    );
                }
                if (!this.state.selectedTemplate) {
                    await this.save(
                        {
                            errorTemplate: true,
                        }
                    );
                }

                if (this.state.subject && this.state.selectedTemplate) {
                    const data = {
                        subject:     this.state.subject,
                        account_ids: users,
                        template_id: this.state.selectedTemplate,
                        system_id:   this.state.selectedType,
                    };

                    try {
                        await sendEmailsByAccountId(data);

                        NotificationService.success(
                            { title: "success", message: this.props.translate('client_emails_send_success'), time: 9000 }
                        );

                        return this.props.close();
                    } catch (error) {
                        error?.showErrorNotification?.();

                        return console.error(error);
                    }
                }

                return false;
            }
            case '2': {
                if (!this.state.subject) {
                    await this.save(
                        {
                            errorSubject: true,
                        }
                    );
                }
                if (!this.state.selectedTemplate) {
                    await this.save(
                        {
                            errorTemplate: true,
                        }
                    );
                }

                if (this.state.subject && this.state.selectedTemplate) {
                    const data = {
                        subject:     this.state.subject,
                        account_ids: users,
                        template_id: this.state.selectedTemplate,
                        system_id:   this.state.selectedType,
                    };

                    try {
                        await sendEmailsByAccountId(data);
                        NotificationService.success(
                            { title: "success", message: this.props.translate('client_emails_send_success'), time: 9000 }
                        );

                        return this.props.close();
                    } catch (error) {
                        error?.showErrorNotification?.();

                        return console.error(error);
                    }
                }

                return false;
            }
            default: {
                return false;
            }
        }
    };

    writeAction = async () => {
        switch (this.state.selectedType) {
            case '1': {
                if (!this.state.subject) {
                    await this.save(
                        {
                            errorSubject: true,
                        }
                    );
                }
                if (!this.state.selectedTemplate) {
                    await this.save(
                        {
                            errorTemplate: true,
                        }
                    );
                }

                if (this.state.subject && this.state.selectedTemplate) {
                    const data = {
                        subject:     this.state.subject,
                        filter_id:   this.props?.filterId,
                        template_id: this.state.selectedTemplate,
                        system_id:   this.state.selectedType,
                    };

                    try {
                        await sendEmails(data);
                        NotificationService.success(
                            { title: "success", message: this.props.translate('client_emails_send_success'), time: 9000 }
                        );

                        return this.props.close();
                    } catch (error) {
                        error?.showErrorNotification?.();

                        return console.error(error);
                    }
                }

                return false;
            }
            case '2': {
                if (!this.state.subject) {
                    await this.save(
                        {
                            errorSubject: true,
                        }
                    );
                }
                if (!this.state.selectedTemplate) {
                    await this.save(
                        {
                            errorTemplate: true,
                        }
                    );
                }

                if (this.state.subject && this.state.selectedTemplate) {
                    const data = {
                        subject:     this.state.subject,
                        filter_id:   this.props?.filterId,
                        template_id: this.state.selectedTemplate,
                        system_id:   this.state.selectedType,
                    };

                    try {
                        await sendEmails(data);
                        NotificationService.success(
                            { title: "success", message: this.props.translate('client_emails_send_success'), time: 9000 }
                        );

                        return this.props.close();
                    } catch (error) {
                        error?.showErrorNotification?.();

                        return console.error(error);
                    }
                }

                return false;
            }
            case '3': {
                if (this.state.selectedTemplate) {
                    const data = {
                        filter_id:   this.props?.filterId,
                        template_id: this.state.selectedTemplate,
                    };

                    try {
                        await sendSms(data);
                        NotificationService.success(
                            { title: "success", message: this.props.translate('client_sms_send_success'), time: 9000 }
                        );

                        return this.props.close();
                    } catch (error) {
                        error?.showErrorNotification?.();

                        return console.error(error);
                    }
                }

                return false;
            }
            default: {
                return false;
            }
        }
    }

    handleSend = () => this.props.sendAction ? this.props.sendAction() : this.writeAction();

    renderSelectType = () => {
        const options = {
            className: "filter-select-emailPopup ",
            value:     this.state?.selectedType,
            values:    this.state?.writeType.map((item) => item.value) ?? [],
            onChange:  async (value) => {
                await this.save({ selectedType: value, selectedTemplate: this.state?.templates[value][0]?.value });
            },
            valueToLabel: (value) => {
                const found = this.state?.writeType.find((item) => item.value === value);

                return found.label ?? '';
            },
        };

        return (<MagicSelect { ...options } />);
    }

    renderSelectTemplate = () => {
        const valueDefault = this.state?.templates.length > 0 ? this.state.selectedTemplate : false;
        const options = {
            className: `filter-select-emailPopup ${this.state.errorTemplate ? 'error' : ''}`,
            value:     this.state.selectedTemplate ?? valueDefault,
            valueDefault,
            values:    this.state?.templates[this.state?.selectedType].map((item) => item.value) ?? [],
            ItemProps: (value) => value === valueDefault ? { disabled: true } : {},
            onChange:  async (value) => {
                await this.save({ selectedTemplate: value });
            },
            valueToLabel: (value) => {
                if (value === valueDefault) {
                    return this.props.translate(`tasks_list_select`);
                }
                const found = this.state?.templates[this.state?.selectedType].find((item) => item.value === value);

                return found?.label ?? '';
            },
        };

        return (<MagicSelect { ...options } />);
    }

    render = () => {
        return (
            <div className ="popup_DropdownItem_modal message">
                <div className="modal-popup-title">
                    <i className="modal_message" />
                    {this.props.translate("client_send_message")}
                </div>
                <div className="modal-popup-body">
                    <div className="write-action-block">
                        <span>{this.props.translate(`dashboard_event_type`)}</span>
                        <div className="input">
                            {this.renderSelectType()}
                        </div>
                    </div>
                    <div className="write-action-block">
                        <span>{this.props.translate(`dashboard_template`)}</span>
                        <div className="input">
                            {this.renderSelectTemplate()}
                        </div>
                    </div>
                    {this.renderInput()}
                </div>
                <div className="modal-popup-btn">
                    <span
                        className="modal-popup-btn-cancel"
                        onClick={() => this.props.close()}
                    >
                        {this.props.translate('client_message_cancel')}
                    </span>
                    <span
                        className="modal-popup-btn-add"
                        disabled={this.state.buttonDisable}
                        onClick={() => this.state.userId ? this.writePrivateAction() : this.writeAction() }
                    >
                        {this.props.translate("kpi_send")}
                    </span>
                </div>
            </div>
        );
    };
}

export default withLocalize(MessagePopup);
