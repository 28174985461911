import React, { Component, } from "react";
import { withLocalize, } from "react-localize-redux";
import { withRouter, } from "react-router-dom";
import { MagicButton, } from "../../../../../components/Magic";
import { enumService, } from "../../../../../services/EnumDataService";
import PermissionService from "../../../../../services/PermissionService";
import * as configBreadcrumbs from "./Config/BreadcrumbsConfig";
import Breadcrumbs from "../../../../tima/components/Breadcrumbs";
import SymbolsListsMagicTable from "./Table/SymbolsListsMagicTable";
import { HorizontalMenu, } from "../../../components";

class SymbolsLists extends Component {
	constructor (props) {
		super(props);

		this.state = {
			enums: {},
			isOpenCreatePopup: false,
			popupData: {},
		};
	}

	async componentDidMount () {
		enumService.subscribe("enums", this.enumsChange, this);
		await enumService.enums;
	}

	componentWillUnmount () {
		enumService.unsubscribe("enums", this.enumsChange, this);
	}

	save = state => new Promise(next => this.setState(state, next));

	toggleCreateUpdatePopup = async (popupData = {}) => {
		await this.save(state => ({
			isOpenCreatePopup: !state.isOpenCreatePopup,
			popupData,
		}));
	};

	enumsChange = async (enums) => {
		if (enums.hash === this.state?.enums?.hash) {
			return `${this.constructor.name}.enumsChange: false`;
		}
		await this.save({ enums, });
		return `${this.constructor.name}.enumsChange: true`;
	};

	getPermissions = (action) => {
		const pm = PermissionService.calc([{
			path: ['partners', 'symbols_lists'],
			key: 'user',
		}]);

		return pm.user.access(action);
	}

	renderButtonCreate = () => {
		const permissionToCreate = this.getPermissions('store')

		if (permissionToCreate) {
			const options = {
				className: "magic-button__item magic-button__item_partners-create-symbol",
				children: this.props.translate('partners_symbols_lists_create'),
				onClick: async () => await this.toggleCreateUpdatePopup(),
			};

			return (
				<MagicButton {...options} />
			)
		}

		return "";
	};

	render () {
		const { enums, isOpenCreatePopup, popupData, } = this.state;
		const { translate, } = this.props;
		const permissionToShow = this.getPermissions('show')
		const breadcrumbsItems = configBreadcrumbs.breadcrumbs4SymbolsList(translate);

		return (
			<>
				<div className="content-block">
					<div className="top-page-block">
						<div>
							<h1 className="page-title">{translate('partners_symbols_lists_title')}</h1>
							<Breadcrumbs items={breadcrumbsItems} />
						</div>
						{this.renderButtonCreate()}
					</div>
					<HorizontalMenu />
					{permissionToShow && <SymbolsListsMagicTable
						enums={enums}
						popup={{
							data: popupData,
							isOpen: isOpenCreatePopup,
							togglePopup: this.toggleCreateUpdatePopup,
						}}
					/>}
				</div>
			</>
		);
	}
}

export default withLocalize(withRouter(SymbolsLists));