import { DataService } from "./DataService";
import { get, post, put, del } from "../components/Magic/helpers/MagicRequest";
import {
    CORPORATE_ACTION,CORPORATE_ACTION_ALL_COURSES,
    CORPORATE_ACTION_CALCULATE,
    CORPORATE_ACTION_COURSE,
    GET_CATEGORIE_ID,
    GET_CATEGORIES,
    GET_RISK_TOOLS_ALL,
    GET_RISKS,
    GET_RISKS_MT5,
    GET_SYSTEM_ACCOUNTS,
    GET_SYSTEM_ACCOUNTS_4_ACCOUNT,
    GET_SYSTEM_ACCOUNTS_BALANCE_HISTORY,
    GET_TRADING_ACCOUNTS,
    GET_TRADING_ACCOUNTS_4_ACCOUNT,
    GET_TRADING_ACCOUNTS_CLOSED_DEALS_MT5,
    GET_TRADING_ACCOUNTS_CLOSED_ORDERS,
    GET_TRADING_ACCOUNTS_CLOSED_ORDERS_MT5,
    GET_TRADING_ACCOUNTS_CLOSED_POSITIONS_MT5,
    GET_TRADING_ACCOUNTS_LOGS,
    GET_TRADING_ACCOUNTS_OPEN_ORDERS,
    GET_TRADING_ACCOUNTS_OPEN_ORDERS_MT5,
    GET_TRADING_ACCOUNTS_OPEN_POSITIONS_MT5,
    PUT_TRADING_ACCOUNT_ACC_LEVEL,
    PUT_TRADING_ACCOUNT_ENABLE_ARCHIVE,
    PUT_TRADING_ACCOUNT_ENABLE_CHANGE_PASSWORD,
    PUT_TRADING_ACCOUNT_LEVERAGE,
    PUT_TRADING_ACCOUNT_MT5_GROUP_TYPE,
    PUT_TRADING_ACCOUNT_MT_GROUP,
    PUT_TRADING_ACCOUNT_RESET_PASSWORD,
    PUT_TRADING_ACCOUNT_SEND_REPORTS,
    RISK_LIVE,
    RISK_LIVE_MT5,
    RISK_UNLOCK,
    RISK_UNLOCK_MT5,
    RISK_UPDATE,
    RISK_UPDATE_MT5,
    BALANCE_OPERATIONS_ALL,
} from "../modules/list_accounts/apiRoutes";
import {hash as hashData} from "./CachingService";

class ListAccountDataService extends DataService {
    constructor(){
        super([
            'tradingAccounts',
            'tradingAccounts4Account',
            'tradingAccountPage',
            'tradingAccountAccLevel',
            'tradingAccountLeverage',
            'tradingAccountMtGroup',
            'tradingAccountMt5GroupType',
            'tradingAccountEnableChangePassword',
            'tradingAccountSendReports',
            'tradingAccountEnableArchive',
            'tradingAccountResetPassword',
            'tradingAccountsClosedOrders',
            'tradingAccountsOpenOrders',
            'tradingAccountsLogs',
            'tradingAccountsClosedOrdersMT5',
            'tradingAccountsOpenOrdersMT5',
            'tradingAccountsOpenPositionsMT5',
            'tradingAccountsClosedPositionsMT5',
            'tradingAccountsClosedDealsMT5',
            'systemAccounts',
            'systemAccounts4AccountPure',
            'systemAccounts4Account',
            'systemAccountPage',
            'systemBalanceHistory',
            'systemAccountDeposit',
            'systemAccountWithdraw',
            'risksByTradingAccountId',
            'risksCategories',
            'risksCategoriesId',
            'riskToolsAllByLoginMT',
            'riskManager',
            'unlockRiskManager',
            'updateRiskManager',
            'deleteRiskManager',
            'risksByTradingAccountIdMT5',
            'riskManagerMT5',
            'unlockRiskManagerMT5',
            'updateRiskManagerMT5',
            'deleteRiskManagerMT5',
            'currencyData',
            'checkDL',
            'chargeSimpleSymbol',
            'currencyCourse',
            'currencyCourseRaw',
            'currencyCourses',
            'deleteSymbolById',
            'updateSymbol',
            'addSymbol'
        ]);
        Object.freeze(this);
    }
    //

  async tradingAccounts ({ filterId, skip, take, }) {
    const url = `${ GET_TRADING_ACCOUNTS }`;
    const { data, meta, } = await get(url, { filterId, skip, take, });
    const hash = await hashData(data);
    const tradingAccounts = {
      data,
      hash,
      meta,
      options: { filterId, skip, take, },
    };

    await this.emit('tradingAccounts', tradingAccounts);

    return tradingAccounts;
  }

    async tradingAccounts4Account (accountId, { take, skip }) {
        const url = `${GET_TRADING_ACCOUNTS_4_ACCOUNT}/${ accountId }`;
        const { data, meta } = await get(url, { take, skip });
        const hash = await hashData(data);
        const tradingAccounts4Account = { data, meta, hash };
        await this.emit('tradingAccounts4Account', tradingAccounts4Account);

        return tradingAccounts4Account;
    }

    async tradingAccountPage (tradingAccountId) {
        const url = `${GET_TRADING_ACCOUNTS}/${ tradingAccountId }`;
        const { data } = await get(url);
        const hash = await hashData(data);
        const tradingAccountPage = { data, hash, tradingAccountId };
        await this.emit('tradingAccountPage', tradingAccountPage);

        return tradingAccountPage;
    }

    /* update accounts */

    async tradingAccountAccLevel (accountId, obj) {
        const url = `${ PUT_TRADING_ACCOUNT_ACC_LEVEL }/${accountId}`;
        const data = await put(url, obj);

        return data;
    }

    async tradingAccountLeverage (accountId, obj) {
        const url = `${ PUT_TRADING_ACCOUNT_LEVERAGE }/${accountId}`;
        const data = await put(url, obj);

        return data;
    }

    async tradingAccountMtGroup (accountId, obj) {
        const url = `${ PUT_TRADING_ACCOUNT_MT_GROUP }/${accountId}`;
        const data = await put(url, obj);

        return data;
    }

    async tradingAccountMt5GroupType (accountId, obj) {
        const url = `${ PUT_TRADING_ACCOUNT_MT5_GROUP_TYPE }/${accountId}`;
        const data = await put(url, obj);

        return data;
    }

    async tradingAccountEnableChangePassword (accountId, obj) {
        const url = `${ PUT_TRADING_ACCOUNT_ENABLE_CHANGE_PASSWORD }/${accountId}`;
        const data = await put(url, obj);

        return data;
    }

    async tradingAccountSendReports (accountId, obj) {
        const url = `${ PUT_TRADING_ACCOUNT_SEND_REPORTS }/${accountId}`;
        const data = await put(url, obj);

        return data;
    }

    async tradingAccountEnableArchive (accountId, obj) {
        const url = `${ PUT_TRADING_ACCOUNT_ENABLE_ARCHIVE }/${accountId}`;
        const data = await put(url, obj);

        return data;
    }

    async tradingAccountResetPassword (accountId, obj) {
        const url = `${ PUT_TRADING_ACCOUNT_RESET_PASSWORD }/${accountId}`;
        const data = await put(url, obj);

        return data;
    }

    /* ******************* MT4 ***************** */

    async tradingAccountsClosedOrders (id, { filterId, take, skip }) {
        const url = `${ GET_TRADING_ACCOUNTS_CLOSED_ORDERS }/${id}`;
        const { data, meta } = await get(url, { filterId, take, skip });
        const hash = await hashData(data);
        const tradingAccountsClosedOrders = { data, meta, hash, id, options: { filterId, take, skip }};
        await this.emit('tradingAccountsClosedOrders', tradingAccountsClosedOrders);

        return tradingAccountsClosedOrders;
    }

    async tradingAccountsOpenOrders (id, { filterId, take, skip }) {
        const url = `${ GET_TRADING_ACCOUNTS_OPEN_ORDERS }/${id}`;
        const { data, meta } = await get(url, { filterId, take, skip });
        const hash = await hashData(data);
        const tradingAccountsOpenOrders = { data, meta, hash, id, options: { filterId, take, skip }};
        await this.emit('tradingAccountsOpenOrders', tradingAccountsOpenOrders);

        return tradingAccountsOpenOrders;
    }

    async tradingAccountsLogs (id, mtParams) {
        const url = `${ GET_TRADING_ACCOUNTS_LOGS }/${id}`;
        const { journal, success } = await post(url, mtParams);
        const hash = await hashData(journal);
        const tradingAccountsLogs = { journal, success, hash, options: mtParams };
        await this.emit('tradingAccountsLogs', tradingAccountsLogs);

        return tradingAccountsLogs;
    }

    /* ******************* MT5 ***************** */

    async tradingAccountsClosedOrdersMT5 (id, { filterId, take, skip }) {
        const url = `${ GET_TRADING_ACCOUNTS_CLOSED_ORDERS_MT5 }/${id}`;
        const { data, meta } = await get(url, { filterId, take, skip });
        const hash = await hashData(data);
        const tradingAccountsClosedOrdersMT5 = { data, meta, hash, id, options: { filterId, take, skip }};
        await this.emit('tradingAccountsClosedOrdersMT5', tradingAccountsClosedOrdersMT5);

        return tradingAccountsClosedOrdersMT5;
    }

    async tradingAccountsOpenOrdersMT5 (id, { filterId, take, skip }) {
        const url = `${ GET_TRADING_ACCOUNTS_OPEN_ORDERS_MT5 }/${id}`;
        const { data, meta } = await get(url, { filterId, take, skip });
        const hash = await hashData(data);
        const tradingAccountsOpenOrdersMT5 = { data, meta, hash, id, options: { filterId, take, skip }};
        await this.emit('tradingAccountsOpenOrdersMT5', tradingAccountsOpenOrdersMT5);

        return tradingAccountsOpenOrdersMT5;
    }

    async tradingAccountsOpenPositionsMT5 (id, { filterId, take, skip }) {
        const url = `${ GET_TRADING_ACCOUNTS_OPEN_POSITIONS_MT5 }/${id}`;
        const { data, meta } = await get(url, { filterId, take, skip });
        const hash = await hashData(data);
        const tradingAccountsOpenPositionsMT5 = { data, meta, hash, id, options: { filterId, take, skip }};
        await this.emit('tradingAccountsOpenPositionsMT5', tradingAccountsOpenPositionsMT5);

        return tradingAccountsOpenPositionsMT5;
    }

    async tradingAccountsClosedPositionsMT5 (id, { filterId, take, skip }) {
        const url = `${ GET_TRADING_ACCOUNTS_CLOSED_POSITIONS_MT5 }/${id}`;
        const { data, meta } = await get(url, { filterId, take, skip });
        const hash = await hashData(data);
        const tradingAccountsClosedPositionsMT5 = { data, meta, hash, id, options: { filterId, take, skip }};
        await this.emit('tradingAccountsClosedPositionsMT5', tradingAccountsClosedPositionsMT5);

        return tradingAccountsClosedPositionsMT5;
    }

    async tradingAccountsClosedDealsMT5 (id, { filterId, take, skip }) {
        const url = `${ GET_TRADING_ACCOUNTS_CLOSED_DEALS_MT5 }/${id}`;
        const { data, meta } = await get(url, { filterId, take, skip });
        const hash = await hashData(data);
        const tradingAccountsClosedDealsMT5 = { data, meta, hash, id, options: { filterId, take, skip }};
        await this.emit('tradingAccountsClosedDealsMT5', tradingAccountsClosedDealsMT5);

        return tradingAccountsClosedDealsMT5;
    }

    /* ******************* System accounts ***************** */

  async systemAccounts ({ filterId, skip, take, }) {
    const url = `${ GET_SYSTEM_ACCOUNTS }`;
    const { data, meta, } = await get(url, { filterId, skip, take, });
    const hash = await hashData(data);
    const systemAccounts = {
      data,
      hash,
      meta,
      options: { filterId, skip, take, },
    };

    await this.emit('systemAccounts', systemAccounts);

    return systemAccounts;
  }

    async systemAccounts4AccountPure (accountId) {
        const url = `${ GET_SYSTEM_ACCOUNTS_4_ACCOUNT }/${ accountId }`;
        const { data, meta } = await get(url);
        const hash = await hashData(data);
        const systemAccounts4Account = { data, meta, hash, accountId, };
        await this.emit('systemAccounts4Account', systemAccounts4Account);

        return systemAccounts4Account;
    }

    async systemAccounts4Account (accountId, { filterId, take, skip }) {
        const url = `${ GET_SYSTEM_ACCOUNTS_4_ACCOUNT }/${ accountId }`;
        const { data, meta } = await get(url, { filterId, take, skip });
        const hash = await hashData(data);
        const systemAccounts4Account = { data, meta, hash, accountId, options: { filterId, take, skip }};
        await this.emit('systemAccounts4Account', systemAccounts4Account);

        return systemAccounts4Account;
    }

    async systemAccountPage (id) {
        const url = `${GET_SYSTEM_ACCOUNTS}/${id}`;
        const { data } = await get(url);
        const hash = await hashData(data);
        const systemAccountPage = { data, hash, id, };
        await this.emit('systemAccountPage', systemAccountPage);

        return systemAccountPage;
    }

    async systemBalanceHistory (accountId, { filterId, take, skip }) {
        const url = `${GET_SYSTEM_ACCOUNTS_BALANCE_HISTORY}/${accountId}`;
        const { data, meta } = await get(url, { filterId, take, skip });
        const hash = await hashData(data);
        const systemBalanceHistory = { data, meta, hash, accountId, options: { filterId, take, skip }};
        await this.emit('systemBalanceHistory', systemBalanceHistory);

        return systemBalanceHistory;
    }

    async systemAccountDeposit (id, obj) {
        const url = `${ GET_SYSTEM_ACCOUNTS }/${id}/deposit`;
        const data = await put(url, obj);
        const systemAccountDeposit = { data };
        await this.emit('systemAccountDeposit', systemAccountDeposit);

        return systemAccountDeposit;
    }

    async systemAccountWithdraw (id, obj) {
        const url = `${ GET_SYSTEM_ACCOUNTS }/${id}/withdraw`;
        const data = await put(url, obj);
        const systemAccountWithdraw = { data };
        await this.emit('systemAccountWithdraw', systemAccountWithdraw);

        return systemAccountWithdraw;
    }

    /* ******************* Risk Manager MT4 ***************** */

    async risksByTradingAccountId (id) {
        const url = `${ GET_RISKS }/${id}`;
        const { data } = await get(url);
        const hash = await hashData(data);
        const risksByTradingAccountId = { data, hash, id, };
        await this.emit('risksByTradingAccountId', risksByTradingAccountId);

        return risksByTradingAccountId;
    }

    async risksCategories (trading_account_id) {
        const url = `${ GET_CATEGORIES }/${trading_account_id}`;
        const { data, } = await get(url);
        const hash = await hashData(data);
        const risksCategories = { data, hash, trading_account_id, };
        await this.emit('risksCategories', risksCategories);

        return risksCategories;
    }

    async risksCategoriesId (id, trading_account_id) {
        const url = `${GET_CATEGORIE_ID}/${id}/${trading_account_id}?take=all`;
        const data = await get(url);
        const hash = await hashData(data);
        const risksCategoriesId = { data, hash, id, trading_account_id, };
        await this.emit('risksCategoriesId', risksCategoriesId);

        return risksCategoriesId;
    }

    async riskToolsAllByLoginMT (trading_account_id) {
        const url = `${GET_RISK_TOOLS_ALL}/${trading_account_id}?take=all`;
        const { data } = await get(url);
        const hash = await hashData(data);
        const riskToolsAllByLoginMT = { data, hash, trading_account_id, };
        await this.emit('riskToolsAllByLoginMT', riskToolsAllByLoginMT);

        return riskToolsAllByLoginMT;
    }

    async riskManager (id) {
        const url = `${RISK_UPDATE}/${id}`;
        const data = await get(url);
        const hash = await hashData(data);
        const riskManager = { data, hash, id, };
        await this.emit('riskManager', riskManager);

        return riskManager;
    }

    async unlockRiskManager (id) {
        const url = `${RISK_UNLOCK}/${id}`;
        const data = await get(url);
        const unlockRiskManager = { data, id, };
        await this.emit('unlockRiskManager', unlockRiskManager);

        return unlockRiskManager;
    }

    async updateRiskManager (id, obj) {
        const url = `${RISK_LIVE}/${id}`;
        const response = await put(url, obj);
        const updateRiskManager = { response };
        await this.emit('updateRiskManager', updateRiskManager);

        return updateRiskManager;
    }

    async deleteRiskManager (id) {
        const url = `${RISK_LIVE}/${id}`;
        const data = await del(url);
        const deleteRiskManager = { data };
        await this.emit('deleteRiskManager', deleteRiskManager);

        return deleteRiskManager;
    }

    /* ******************* Risk Manager MT5 ***************** */

    async risksByTradingAccountIdMT5 (id) {
        const url = `${GET_RISKS_MT5}/${id}`;
        const { data } = await get(url);
        const hash = await hashData(data);
        const risksByTradingAccountIdMT5 = { data, hash, id, };
        await this.emit('risksByTradingAccountIdMT5', risksByTradingAccountIdMT5);

        return risksByTradingAccountIdMT5;
    }

    async riskManagerMT5 (id) {
        const url = `${RISK_UPDATE_MT5}/${id}`;
        const data = await get(url);
        const hash = await hashData(data);
        const riskManagerMT5 = { data, hash, id, };
        await this.emit('riskManagerMT5', riskManagerMT5);

        return riskManagerMT5;
    }

    async unlockRiskManagerMT5 (id) {
        const url = `${RISK_UNLOCK_MT5}/${id}`;
        const data = await get(url);
        const unlockRiskManagerMT5 = { data };
        await this.emit('unlockRiskManagerMT5', unlockRiskManagerMT5);

        return unlockRiskManagerMT5;
    }

    async updateRiskManagerMT5 (id, obj) {
        const url = `${RISK_LIVE_MT5}/${id}`;
        const response = await put(url, obj);
        const updateRiskManagerMT5 = { response };
        await this.emit('updateRiskManagerMT5', updateRiskManagerMT5);

        return updateRiskManagerMT5;
    }

    async deleteRiskManagerMT5 (id) {
        const url = `${RISK_LIVE_MT5}/${id}`;
        const data = await del(url);
        const deleteRiskManagerMT5 = { data };
        await this.emit('deleteRiskManagerMT5', deleteRiskManagerMT5);

        return deleteRiskManagerMT5;
    }

    /* ******************* Corporate Action ***************** */

    async currencyData () {
        const url = `${CORPORATE_ACTION}`;
        const { data } = await get(url);
        const hash = await hashData(data);
        const currencyData = { data, hash, };
        await this.emit('currencyData', currencyData);

        return currencyData;
    }

    async checkDL (demo, accrue, server, data) {
        const url = `${CORPORATE_ACTION_CALCULATE}`;
        const response = await post(url, { demo, accrue, server, data });
        const checkDL = { response };
        await this.emit('checkDL', checkDL);

        return checkDL;
    }

    async chargeSimpleSymbol (demo, accrue, login, data, server) {
        const url = `${CORPORATE_ACTION_CALCULATE}`;
        const response = await post(url, {demo, accrue, login, data, server,});
        const chargeSimpleSymbol = { response };
        await this.emit('chargeSimpleSymbol', chargeSimpleSymbol);

        return chargeSimpleSymbol;
    }

    async currencyCourse (pair, date) {
        const url = `${CORPORATE_ACTION_COURSE}/getRate/${pair}/${date}`;
        const data = await get(url);
        const currencyCourse = { data };
        await this.emit('currencyCourse', currencyCourse);

        return currencyCourse;
    }

    async currencyCourseRaw (pairFrom, pairTo, date) {
        const url = `${CORPORATE_ACTION_COURSE}/getRateRaw/${pairFrom}/${pairTo}/${date}`;
        const data = await get(url);
        await this.emit('currencyCourseRaw', data);

        return data;
    }

    async currencyCourses (date) {
        const url = `${CORPORATE_ACTION_ALL_COURSES}/getAllRates/${date}`;
        const data = await get(url);
        const currencyCourses = { data };
        await this.emit('currencyCourses', currencyCourses);

        return currencyCourses;
    }

    async deleteSymbolById (id) {
        const url = `${CORPORATE_ACTION}/${id}`;
        const { data } = await del(url);

        return data;
    }

    async updateSymbol (id, { symbol, pair, formula, }) {
        const url = `${CORPORATE_ACTION}/${id}`;
        const data = await put(url, { symbol, pair, formula, });
        const updateSymbol = { data };
        await this.emit('updateSymbol', updateSymbol);

        return updateSymbol;
    }

    async addSymbol ({ symbol, pair, formula, }) {
        const url = `${CORPORATE_ACTION}`;
        const data = await post(url, { symbol, pair, formula, });
        const addSymbol = { data };
        await this.emit('addSymbol', addSymbol);

        return addSymbol;
    }

    async getBalanceOperations (options) {
        const url = BALANCE_OPERATIONS_ALL;
        const data = await get(url, options);

        return data;
    }
}
export const listAccountService = new ListAccountDataService();
