
export const menu = [
	{
		label: 'partners_system_categories_affiliate_programs',
		children: [
			{
				to: '/partners/affiliate-programs-management/affiliate-programs',
				path: [ 'partners', 'programs', ],
			},
			{
				to: '/partners/affiliate-programs-management/conditions-for-accruals',
				path: [ 'partners', 'new_partners_conditions', ],
			},
			{
				to: '/partners/affiliate-programs-management/payout-rate-lists',
				path: [ 'partners', 'payout_rate_lists', ],
			},
			{
				to: '/partners/affiliate-programs-management/symbols',
				path: [ 'partners', 'symbols', ],
			},
			{
				to: '/partners/affiliate-programs-management/symbols-groups',
				path: [ 'partners', 'symbols_groups', ],
			},
			{
				to: '/partners/affiliate-programs-management/symbols-lists',
				path: [ 'partners', 'symbols_lists', ],
			},
			{
				to: '/partners/affiliate-programs-management/account-groups-lists',
				path: [ 'partners', 'groups_lists', ],
			},
		],
	},
	{
		label: 'partners_system_categories_partners',
		children: [
			{
				path: [ 'partners', 'partners', ],
				to: '/partners/requests/partners-list',
			},
			{
				path: [ 'partners', 'requests', ],
				to: '/partners/requests/partnership-application',
			},
		],
	},
	{
		label: 'menu_partners_referrer',
		children: [
			{
				to: '/partners/referrels/referrals-list',
				path: [ 'partners', 'referrals', ],
			},
		],
	},
	{
		label: 'menu_partners_system_affiliate_payouts',
		children: [
			{
				to: '/partners/partner-payouts',
				path: [ 'partners', 'accruals', ],
			},
		],
	},
	{
		label: 'partners_manage_promo',
		children: [
			{
				to: '/partners/promo/banners',
				path: [ 'partners', 'promo', ],
			},
			{
				to: '/partners/promo/target-page',
				path: [ 'partners', 'target_pages', ],
			},
			{
				to: '/partners/promo/logos',
				path: [ 'partners', 'logos', ],
			},
			{
				to: '/partners/promo/lending',
				path: [ 'partners', 'lending', ],
			},
		],
	},
	{
		label: 'menu_partners_system_dashboard_calculation',
		children: [
			{
				to: '/partners/dashboards/calculations-dashboard',
				path: [ 'partners', 'condition_calculation_state', ],
			},
			{
				to: '/partners/dashboards/payouts-dashboard',
				path: [ 'partners', 'condition_payout_state', ],
			},
			{
				to: '/partners/dashboards/partners-waiting-switch',
				path: [ 'partners', 'awaiting_change_program', ],
			},
			{
				to: '/partners/dashboards/referrals-waiting-switch',
				path: [ 'partners', 'awaiting_add_referral_register', ],
			},
		],
	},
];