import React, { Component, } from 'react';
import { withLocalize, } from 'react-localize-redux';
import { Input, } from '../../../../../../components';
import {
  setErrorClass,
} from '../../../../../../utils/helpers';

import { onValidPercantages } from '../../helpers/validation-helpers/onValidHelpers';
class PayoutRateListInOutSingleLevel extends Component {
  constructor(props) {
    super(props);
  }

  get disabled() {
    const { payment_coef_inout, formData, isViewMode, statusIsClosed} = this.props;
    const disabled = payment_coef_inout.length >= 10 || formData.payment_coef_inout[formData.payment_coef_inout.length - 1].sum_inout_from >= 100_000 || formData.payment_coef_inout[formData.payment_coef_inout.length - 1].coef >= 1000 || isViewMode || statusIsClosed;
    return disabled ? 'disabled' : ''
  }

  handleOnChange = (index, fieldName) => async (event) => {
    const { setNewSumFromValue, clearErrorOnChange } = this.props;
    const { value } = event.target;
    clearErrorOnChange(event);

    try {
      switch (fieldName) {
        case 'sum_inout_from': {
          await setNewSumFromValue(fieldName, `${value}`, index);
          break;
        }
        case 'coef': {
          await setNewSumFromValue(fieldName, `${value}`, index);
          break;
        }

        default: {
          break;
        }
      }
    } catch (e) {
      throw new Error(e);
    };
  };

  createNewAllowedGroup = () => {
    const { createNewPayoutRangesItem, } = this.props;

    return createNewPayoutRangesItem("1");
  };

  checkIsDisabled = (index) => {
		const {paymentCoefsInDataBase, statusIsActive, statusIsClosed, isEditMode, isViewMode}= this.props;
		if(!isEditMode) return false
		return (index + 1 <= paymentCoefsInDataBase.length && statusIsActive) || statusIsClosed  || isViewMode//in active mode can delete and edit only coefs that are not in DB
	}

  renderPayoutRangeList = () => {
    const { formData, } = this.props;
    const {
      translate,
      errors,
      payment_coef_inout,
      removePayoutRangeItem,
    } = this.props;

    const checkIsDisabled = this.checkIsDisabled;
    const isDisabled = (index) => checkIsDisabled(index) || payment_coef_inout.length - 1 !== index;


    return (
      <div className='payout-rate-list__payout_table-block custom-horizontal-scroll pb-20'>
        <div className='payout-rate-list__payout_table-forbidden mr-35'>
          <div className='payout-rate-list__payout_table-range-items'>
            <div className='payout-rate-list__payout_table-title-bg p0'>
              <p className='payout-rate-list__payout_table-title'>
                {translate("new_partners_condition_sum_in_out")}
              </p>
            </div>
            <div className='payout-rate-list__payout_table-title-bg p0'>
              <p className='payout-rate-list__payout_table-title'>
                {translate("new_partners_condition_payout_percentage_input")}
              </p>
            </div>
          </div>
          {payment_coef_inout?.map((sum, index) => {
            return (
              <div key={index} className='payout-rate-list__payout_table-range-items'>
                {payment_coef_inout?.length > 1 && !checkIsDisabled(index)
                  ? <button
                        className="payout-rate-list__create-btn_btns-btn payout-rate-list__create-btn_btns-btn-remove mt-20 rm-range-item"
                        onClick={() => removePayoutRangeItem(index)} />
                  : null
                }
                <Input
                  name={`sum_inout_from${index}`}
                  wrapperClassName={`payout-rate-list__payout_table-input ${setErrorClass(errors, `sum_inout_from${index}`)}`}
                  placeholder={translate("new_partners_condition_sum_in_out_placeholder")}
                  value={`${sum.sum_inout_from}`}
                  onChange={this.handleOnChange(index, 'sum_inout_from')}
                  onValid={(value) => onValidPercantages(value, 100_000)}
                  disabled={isDisabled(index)}
                  isRequired
                />
                <Input
                  name={`coef${index}`}
                  wrapperClassName={`payout-rate-list__payout_table-input ${setErrorClass(errors, `coef${index}`)}`}
                  placeholder={translate("new_partners_condition_grace_period_payment_placeholder")}
                  value={`${sum.coef}`}
                  onChange={this.handleOnChange(index, 'coef')}
                  disabled={isDisabled(index)}
                  onValid={(value) => onValidPercantages(value, 1000)}
                  isRequired
                />
              </div>
            )
          })}
        </div>
      </div>

    );
  };

  render() {
    const { translate } = this.props;
    const disabled = this.disabled;
    const disabledClassName =  disabled ? `disabled` : '';

    return (
      <>
        <div className='payout-rate-list__payout_table-forbidden-title'>
          <p className={'payout-rate-list__payout_block-title'}>
            {translate('new_partners_condition_payout_percentage')}
          </p>
          <button
            onClick={this.createNewAllowedGroup}
            className={`${disabledClassName} payout-rate-list__create-btn_btns-btn payout-rate-list__create-btn_btns-btn-join`}
            disabled={disabled}
          >
            {translate(`new_partners_condition_add-range`)}
          </button>
        </div>
        <div className='payout-rate-list__payout_table custom-vertical-scroll'>
          {this.renderPayoutRangeList()}
        </div>
      </>
    )
  }
}

export default withLocalize(PayoutRateListInOutSingleLevel);
