import React, { Component } from 'react';
import {getAvatar} from "../../../services/TaskHelperService";

export default class ResponsibleRow extends Component {
    constructor (props) {
        super(props);
        this.state = {
            images: {},
        };
    }

    componentDidMount () {
        getAvatar(this, this.props.getSpectator?.['id'], this.props.usersAllAvatar);
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        getAvatar(this, nextProps.selectPartipiants?.['id'], nextProps.usersAllAvatar);
    }

    render () {
        const getSpectator = this.props.getSpectator;

        return (
            <div className = 'responsible-flex'>
                <div className = 'responsible-avatar'>
                    <img alt = 'user' src = { this.state.images.imageId } />
                </div>
                <div className = 'responsible-text'>
                    <div className = 'semibold'>{getSpectator?.['name'] ?? '' } {getSpectator?.['surname'] ?? ''}</div>
                </div>
                {this.props.roleUser === this.props.ROLE_4 || this.props.roleUser === this.props.ROLE_5 ?
                    <div
                        className = 'responsible-action' onClick = { () => {
                            this.props.removeParticipants(getSpectator?.['id'] ?? '');
                        }}>
                        <i className = 'la la-close' />
                    </div>
                    : ""
                }

            </div>
        );
    }
}
