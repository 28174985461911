import {
	itemsBuild, itemsDrain, itemsResort, CFG_ITEM_TYPE,
} from "../Config";
import { onEnabled, render, } from "../Mentor/withDropDown";

export default {
	id: 163,
	...[
		// filters
		...[
			{
				f: "partners.rebate_projects.id",
				id: "f:rebateId",
			},
			{
				f: [ "core.account.name", "core.account.surname", ],
				id: "f:rebatePartner",
			},
			{
				f: "partners.rebate_projects.name",
				id: "f:rebateProject",
			},
			{
				f: "partners.rebate_projects.rate",
				id: "f:rebateRate",
			},
			{
				f: "partners.rebate_projects.condition",
				id: "f:rebateCondition",
			},
			{
				f: "partners.rebate_projects.status",
				id: "f:rebateStatus",
			},
			{
				f: "partners.rebate_projects.created_at",
				id: "f:rebateApplicationDate",
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: "filter", ..._, })),

		// orders
		...[
			{
				f: "partners.rebate_projects.id",
				id: "o:rebateId",
			},
			{
				f: "partners.rebate_projects.partner_id",
				id: "o:rebatePartner",
			},
			{
				f: "partners.rebate_projects.name",
				id: "o:rebateProject",
			},
			{
				f: "partners.rebate_projects.rate",
				id: "o:rebateRate",
			},
			{
				f: "partners.rebate_projects.condition",
				id: "o:rebateCondition",
			},
			{
				f: "partners.rebate_projects.status",
				id: "o:rebateStatus",
			},
			{
				f: "partners.rebate_projects.created_at",
				id: "o:rebateApplicationDate",
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: "order", ..._, })),

		// panels
		...[
			{
				t: "partners_rebate_id",
				ii: "f:rebateId",
			},
			{
				t: "partners_rebate_partner",
				ii: "f:rebatePartner",
			},
			{
				t: "partners_rebate_project",
				ii: "f:rebateProject",
			},
			{
				t: "partners_rebate_rate",
				ii: "f:rebateRate",
			},
			{
				t: "partners_rebate_condition",
				ii: "f:rebateCondition",
			},
			{
				t: "partners_rebate_status",
				ii: "f:rebateStatus",
			},
			{
				t: "partners_rebate_application_date",
				ii: "f:rebateApplicationDate",
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: "panel", ..._, })),
	]
		.map(itemsBuild)
		.reduce(itemsDrain, [])
		.reduce(itemsResort, {}),

	path: "partners/rebate-projects",
	widget: false,
};