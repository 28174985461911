export const sideBarTaskListLang = {
  "sideBar_task_list": ["Task List", "Список задач","Список завдань"],
  "sideBar_today": ["Today", "Сегодня", "Сьогодні"],
  "sideBar_tomorrow": ["Tomorrow", "Завтра", "Завтра"],
  "sideBar_three_days": ["3 Days", "За 3 дня", "За 3 дні"],
  "sideBar_week": ["Week", "За неделю", "За Тиждень"],
  "sideBar_client": ["Client", "Клиент","Клієнт"],
  "sideBar_deadline": ["Deadline", "Дедлайн", "Дедлайн"],
  "sideBar_expired": ["Expired", "Просрочена",  "Прострочена"],
  "sideBar_important": ["Priority", "Приоритет", "Пріоритет"],
  "sideBar_status": ["Status", "Статус", "Статус"],
  "sideBar_deligated": ["Task delegated", "Задача делигирована", "Задача делігована"],
  "sideBar_open_task": ["Open Task", "Открыть задачу", "Відкрити завдання"]
}