/* eslint-disable sort-keys,react/no-children-prop */
import React, { Component, createRef, } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { debounce, } from "lodash";
import Preloader from "../../../../components/LoadingHOC/Preloader";
import Loader from "../../../tima/components/Loader";
import { MagicButton, } from "../../../../components/Magic";
import { withLocalize, } from "react-localize-redux";
import bg from "../../../../images/background-create-partners-accrual.svg";
import "./styles.scss";

class Popup extends Component {
	static defaultProps = {
		loadData: true,
		showPopUp: false,
		onClick: () => {},
		onClose: () => {},
		translate: () => {},
		showCloseButton: true,
		showTitle: true,
		title: "",
		size: "sm",
		createBtnText: "",
		cancelBtnText: "",
		isImage: true,
		customClassName: "",
		wrapperClassname: "",
	};

	static propTypes = {
		onClose: PropTypes.func.isRequired,
		cancelBtnText: PropTypes.string,
		createBtnText: PropTypes.string,
		customClassName: PropTypes.string,
		isImage: PropTypes.bool,
		loadData: PropTypes.bool,
		showCloseButton: PropTypes.bool,
		showPopUp: PropTypes.bool,
		showTitle: PropTypes.bool,
		size: PropTypes.string,
		title: PropTypes.string,
		wrapperClassname: PropTypes.string,
		onClick: PropTypes.func,
	};

	constructor (props) {
		super(props);

		this.state = {
			container: document.createElement("div"),
		};

		this.containerRef = createRef(null);
	}

	get getContainer () {
		return this.state.container;
	}

	get isLoaded () {
		return this.props.loadData;
	}

	componentDidMount = () => {
		document.body.appendChild(this.getContainer);
		this.containerRef.current && this.containerRef.current.focus();
	};

	componentWillUnmount = () => {
		document.body.removeChild(this.getContainer);
	};

	renderTitle = () => {
		const { showTitle, title, } = this.props;

		if (!this.isLoaded) {
			return "";
		}

		return showTitle && <div className="popup__title">{title}</div>;
	};

	renderCloseBtn = () => {
		const { showCloseButton, onClose, } = this.props;

		if (!this.isLoaded) {
			return "";
		}

		return (
			showCloseButton && (
				<button className="popup__close-button" onClick={ onClose } />
			)
		);
	};

	renderContent = () => {
		const {
			children,
			onClose,
			onClick,
			size,
			translate,
			createBtnText,
			cancelBtnText,
			isImage,
			customClassName,
			showPopUp,
			wrapperClassname,
		} = this.props;

		return (
			<div
				className={ `partners__popup ${ wrapperClassname }` }
				ref={ this.containerRef }
				tabIndex="0"
			>
				<div className={ `popup__wrapper scroll ${ size }` }>
					{this.renderCloseBtn()}
					<div className="popup__header">{this.renderTitle()}</div>
					<div className={ `popup__body ${ customClassName }` }>
						<Loader
							loaded={ this.isLoaded }
							loading={
								<Preloader className="loaderUniversal--abs-pos" scale={ 1 } />
							}
						>
							{children}
							{showPopUp ? null : (
								<div className={ `popup__buttons` }>
									<MagicButton
										children={ translate(
											`${ createBtnText ? createBtnText : "partners_save_btn" }`,
										) }
										className="magic-button__item magic-button_partners_popup-save"
										disabled={ !this.isLoaded }
										onClick={ debounce(onClick, 500) }
									/>
									<MagicButton
										children={ translate(
											`${
												cancelBtnText ? cancelBtnText : "partners_cancel_btn"
											}`,
										) }
										className="magic-button__item magic-button_partners_popup-cancel"
										disabled={ !this.isLoaded }
										onClick={ onClose }
									/>
								</div>
							)}

							{isImage ? (
								<img alt="bg" className="popup__bg-image" src={ bg } />
							) : null}
						</Loader>
					</div>
				</div>
			</div>
		);
	};

	render = () => ReactDOM.createPortal(this.renderContent(), this.getContainer);
}

export default withLocalize(Popup);