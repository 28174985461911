import React from "react";

import MonthLine from "./MonthLine";
import { get_random_guid } from "../../../../helpers/OtherHelper";

class KPIWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fade: true,
        };
    }
    UNSAFE_componentWillReceiveProps() {
        const pathHref = location.pathname.split('/');

        if (pathHref.includes('task') ||
            // pathHref.includes('clients') && pathHref.length > 2 ||
            pathHref.includes('taskCreate') ||
            pathHref.includes('clientCreate') ||
            pathHref.includes('clientEdit')) {
            this.setState(() => {
                this.setState({ fade: false });
            });
        } else {
            this.setState({ fade: true });
        }
    }

    render() {
        return this.state.fade
            ?
            <div className='kpi-widget-wrapper'>
                <div className='month-widget'>
                    <MonthLine
                        key={get_random_guid()}
                        open={this.props.open}
                    />
                </div>
                {/*<div className='leader-widget'>
                    <Leaders leaders={leaders} />
                </div>*/}
            </div>
            : '';
    }
}
export default KPIWidget;
