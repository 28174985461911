import React, { Component } from 'react';
import PropTypes            from 'prop-types';


class MagicSwitch extends Component {
	constructor ( props ) {
		super( props );
		this.state = {
			index: props.index,
		};
	}

    UNSAFE_componentWillReceiveProps = ( nextProps, ) => {
		if (this.props.updateStateFromProps) {
            this.setState( { index: nextProps.index } );
        }
	};

	amount = ( ) => this.props.values.length;
	classIcon = ( ) => this.props.classNameIcon;
	className = ( ) => this.props.className;
	classText = ( ) => this.props.classNameText;
	index = ( ) => this.state.index;
    isDisabled = ( ) => this.props.isDisabled;
	reverse = ( ) => this.props.reverse;
	text = ( ) => this.props.text;
	texts = ( i ) => !this.props.texts.length ? this.text() : i>=0 ? this.props.texts[ i ] : this.props.texts;
	values = ( i ) => i>=0 ? this.props.values[ i ] : this.props.values;

	onChange = ( event ) => {
		if ( !this.isDisabled() ) {
            try {
                event.preventDefault();
                const index = ( this.index() + 1 ) % this.amount();
                const state = { ...this.state, index, };
                if ( this.props.onChange instanceof Function ) {
                    const values = this.values().slice();
                    const value = this.values( index );
                    this.props.onChange( { event, value, index, values, } );
                }
                this.setState( state );
            } catch ( error ) { }
		}
	};

	renderIcon = ( className, index, amount ) => (
		<span className={ className } data-index={ index } data-amount={ amount } >
			{ `${ index }` }
		</span>
	);

	renderText = ( className, text ) => (
		<span className={ className } >{ text }</span>
	);

	render = ( ) => (
		<span
			className={ this.className() }
			onClick={ this.onChange }
			data-reverse={ this.reverse() }
		>
			{ this.renderIcon( this.classIcon(), this.index(), this.amount() ) }
			{ this.renderText( this.classText(), this.texts( this.index() ) ) }
		</span>
	);
};


const propTypeText = PropTypes.oneOfType( [
    PropTypes.bool.isRequired,
    PropTypes.node.isRequired,
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
] );

MagicSwitch.propTypes = {
	className: PropTypes.string.isRequired,
	classNameIcon: PropTypes.string.isRequired,
	classNameText: PropTypes.string.isRequired,
	index: ( props, key, component ) => {
		const value = props[ key ];
		if ( !Number.isInteger( value ) || value<0 || value>=props.values.length ) {
			return new Error( `Error ${ component }: props.${ key } = '${ value }'` );
		}
	},
	reverse: PropTypes.bool.isRequired,
	text: propTypeText.isRequired,
	texts: PropTypes.arrayOf( propTypeText.isRequired ),
	values: PropTypes.arrayOf(
		PropTypes.oneOfType( [
			PropTypes.bool.isRequired,
			PropTypes.number.isRequired,
			PropTypes.string.isRequired,
		] ).isRequired
	).isRequired,
	onChange: PropTypes.func.isRequired,
	updateStateFromProps: PropTypes.bool,
};


MagicSwitch.defaultProps = {
	className: `magic-switch`,
	classNameIcon: `magic-switch__icon`,
	classNameText: `magic-switch__text`,
	index: 0,
	isDisabled: false,
	reverse: false,
	text: `My MagicSwitch`,
	texts: [ ],
	values: [ true, false, ],
    updateStateFromProps: false,
};


export default MagicSwitch;
export {
	MagicSwitch,
};
