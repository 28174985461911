import React from "react";
import { Modal, ModalBody } from 'reactstrap';

class ModalSuccess extends React.Component {
    constructor (props) {
        super(props);
    }

    render () {
        const { modal, title } = this.props;

        return (
            <React.Fragment>
                <Modal className = { 'custom-modal wide color-bg' } isOpen = { modal } toggle = { () => {this.props.toogle()} }>
                    <div className = 'close-button' onClick = { () => {this.props.toogle()} }>
                        <i className = 'la la-close' />
                    </div>
                    <ModalBody>
                        <div className = 'h1 aligncenter'>{ title }</div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ModalSuccess;
