import Config from "./ListAccountsLinkConfig";

export const breadcrumbs4General = (
    translate,
    active,
    tradingAccountId
) => Config(
    translate,
    [ 'general', ],
    false,
    { tradingAccountId, }
);

export const breadcrumbs4OpenedOrdersMT4 = (
    translate,
    active,
    tradingAccountId
) => Config(
    translate,
    [
        'general',
        `opened-orders-mt4`,
    ],
    id => ({
        ...(id === active) && { active: true },
    }),
    { tradingAccountId, }
);

export const breadcrumbs4ClosedOrdersMT4 = (
    translate,
    active,
    tradingAccountId
) => Config(
    translate,
    [
        'general',
        `closed-orders-mt4`,
    ],
    id => ({
        ...(id === active) && { active: true },
    }),
    { tradingAccountId, }
);

export const breadcrumbs4RiskManagerMT4 = (
    translate,
    active,
    tradingAccountId
) => Config(
    translate,
    [
        'general',
        `risk-manager-mt4`,
    ],
    id => ({
        ...(id === active) && { active: true },
    }),
    { tradingAccountId, }
);

export const breadcrumbs4LogsMT4 = (
    translate,
    active,
    tradingAccountId
) => Config(
    translate,
    [
        'general',
        `logs-mt4`,
    ],
    id => ({
        ...(id === active) && { active: true },
    }),
    { tradingAccountId, }
);

// *********************** MT5 ********************** //

export const breadcrumbs4OpenedOrdersMT5 = (
    translate,
    active,
    tradingAccountId
) => Config(
    translate,
    [
        'general',
        `opened-orders-mt5`,
    ],
    id => ({
        ...(id === active) && { active: true },
    }),
    { tradingAccountId, }
);

export const breadcrumbs4ClosedOrdersMT5 = (
    translate,
    active,
    tradingAccountId
) => Config(
    translate,
    [
        'general',
        `closed-orders-mt5`,
    ],
    id => ({
        ...(id === active) && { active: true },
    }),
    { tradingAccountId, }
);

export const breadcrumbs4OpenedPositionsMT5 = (
    translate,
    active,
    tradingAccountId
) => Config(
    translate,
    [
        'general',
        `opened-positions-mt5`,
    ],
    id => ({
        ...(id === active) && { active: true },
    }),
    { tradingAccountId, }
);

export const breadcrumbs4ClosedPositionsMT5 = (
    translate,
    active,
    tradingAccountId
) => Config(
    translate,
    [
        'general',
        `closed-positions-mt5`,
    ],
    id => ({
        ...(id === active) && { active: true },
    }),
    { tradingAccountId, }
);

export const breadcrumbs4ClosedDealsMT5 = (
    translate,
    active,
    tradingAccountId
) => Config(
    translate,
    [
        'general',
        `closed-deals-mt5`,
    ],
    id => ({
        ...(id === active) && { active: true },
    }),
    { tradingAccountId, }
);

export const breadcrumbs4RiskManagerMT5 = (
    translate,
    active,
    tradingAccountId
) => Config(
    translate,
    [
        'general',
        `risk-manager-mt5`,
    ],
    id => ({
        ...(id === active) && { active: true },
    }),
    { tradingAccountId, }
);
