import React from "react";
import PermissionService, {
	MODULE_KEYS as pm,
} from "../../../../services/PermissionService";

import RefferalsList from "./RefferalsList/RefferalsList";

export const referralsConfig = [
	{
		action: PermissionService.indexRoute(),
		component: <RefferalsList />,
		path: "/partners/referrels/referrals-list/:id",
		permission: pm.PARTNERS_REFERRALS,
	},	{
		action: PermissionService.indexRoute(),
		component: <RefferalsList />,
		path: "/partners/referrels/referrals-list/",
		permission: pm.PARTNERS_REFERRALS,
	},
];