import { cachingService, hash as hashData, } from './CachingService';
import { DataService } from './DataService';

import { GET_ALL_ENUMS } from '../modules/common/apiRoutes';
import { setAllEnums } from '../modules/common/reducers/enums/actions';

class EnumDataService extends DataService {
    constructor() {
        const events = {
            enums: async () => {
                const url = GET_ALL_ENUMS;
                const data = await cachingService.dictionary(url);
                const hash = await hashData(data);
                const enums = { data, hash, };
                await this.emit('enums', enums);
                return enums;
            },
        };
        super(Object.keys(events));
        Object.defineProperties(this, {
            enums: { get: events.enums, },
        });
        Object.freeze(this);
    }

    get systemAccountEnum () {
		return {
			STATUS_NEW: 1,
			STATUS_ACTIVE: 2,
			STATUS_DORMANT: 3,
			STATUS_ARCHIVED: 4,

			TYPE_BALANCE: 1,
			TYPE_TIMA_INVESTOR: 3,
			TYPE_TIMA_AGENT: 4,
			TYPE_IB_PARTNER: 9,
		}
	};
};

export const enumService = new EnumDataService();

export const getAllEnums = () => async (dispatch) => {
    try {
        const enums = await enumService.enums;
        dispatch(setAllEnums(enums.data));
        return enums.data;
    } catch (error) {
        throw error;
    }
};
