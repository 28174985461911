import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, } from 'react-router-dom';
import { withLocalize, } from "react-localize-redux";

import PagePanel from '../../tima/components/Pages/PagePanel';
import Preloader from '../../../components/LoadingHOC/Preloader';
import ListSystemAccountsMagicTable from '../../list_accounts/domains/SystemAccounts/ListSystemAccountsMagicTable';

import { Mentor, } from '../../../components/Magic';
import { listAccountService, } from "../../../services/ListAccountDataService";

class SearchSystemAccountsId extends React.Component {
  static propTypes = {
    count: PropTypes.func.isRequired,
  };

  constructor (props) {
    super(props);
    this.state = {
      filterLastChange: Date.now(),
      loaded: true,
      pages: {
        filter: 0,
        take: 5,
        total: 0,
        variance: 2,
      },
      systemAccounts: {
        data: [],
        hash: null,
        options: {},
      },
    };

    const mf = new Mentor({
      owner: this,
      serviceId: 249,
      translate: this.props.translate,
    });

    Object.defineProperty(this, 'mf', { get: () => mf, });
  }

  // eslint-disable-next-line react/sort-comp, require-await
  save = async state => new Promise(next => this.setState(state, next));

  // eslint-disable-next-line require-await
  mfChanged = async () => {
    await this.save({ filterLastChange: Date.now(), });
  };

  mfDoing = async () => {
    await this.save({ loaded: false, });
  };

  mfDone = async () => {
    await this.mfChanged();
    await this.onDataLoad();
  };

  systemAccounts = async ({
    data,
    hash,
    meta,
    options,
  }) => {
    const { filter = 0, total = 0, } = meta;
    const { systemAccounts, } = this.state;

    if (hash===systemAccounts?.hash) {
      return `${ this.constructor.name }.systemAccounts: false`;
    } else if (options?.filterId!==systemAccounts?.options?.filterId) {
      return `${ this.constructor.name }.systemAccounts: false`;
    } else if (options?.skip!==systemAccounts?.options?.skip) {
      return `${ this.constructor.name }.systemAccounts: false`;
    } else if (options?.take!==systemAccounts?.options?.take) {
      return `${ this.constructor.name }.systemAccounts: false`;
    }

    await this.save(({ pages, }) => ({
      pages: { ...pages, filter, total, },
      systemAccounts: { data, hash, options, },
    }));

    return `${ this.constructor.name }.systemAccounts: true`;
  };

  async componentDidMount () {
    this.mf.subscribe({
      changed: this.mfChanged,
      // changing: ,
      cleaned: this.mfDone,
      cleaning: this.mfDoing,
      loaded: this.mfDone,
      loading: this.mfDoing,
      saved: this.mfDone,
      saving: this.mfDoing,
    }, this);
    await this.mf.init({ doEmit: true, });
    listAccountService.subscribe('systemAccounts', this.systemAccounts, this);
    // await this.mf.emit('change', {});
    // await this.onDataLoad(); // TODO: moved to mfDone

    const enabled = 1;
    const v = +this.query.get('q') || 0;

    await this.mf.filterChange({
      $$changed: true,
      id: 'f:saId',
      setter: { enabled, v, },
    });
    await this.mf.apply({});
  }

  componentWillUnmount = () => {
    this.mf.unsubscribe([
      this.mfChanged,
      this.mfDoing,
      this.mfDone,
    ], this);
    listAccountService.unsubscribe('systemAccounts', this.systemAccounts, this);
  };

  get location () {
    // eslint-disable-next-line no-restricted-globals
    return new URL(location.href);
  }

  get query () {
    return new URLSearchParams(this.location.searchParams);
  }

  render () {
    if (!this.state.loaded) {
      return (<Preloader />);
    } else if (!this.state?.systemAccounts.data?.length) {
      return null;
    }

    const { translate, } = this.props;
    const pageOptions = {
      filter: this.state?.pages?.filter,
      take: this.state?.pages?.take,
      total: this.state?.pages?.total,
      variance: this.state?.pages?.variance,
      page: this?.pageId?.(),
      onClick: this?.onPageClick,
      doText: this?.onPageText,
      doTo: (pageId) => {
        const search = this.location;

        search.searchParams.set('pageD', pageId);

        return `${ search?.pathname }${ search.search }`;
      },
      doStatus: () => '',
    };

    return (
      <React.Fragment>
        <h3>{ translate(`list_accounts_system_accounts`) }</h3>
        <ListSystemAccountsMagicTable
          data={ this.state.systemAccounts.data }
        />
        <PagePanel { ...pageOptions } />
      </React.Fragment>
    );
  }

  pageId = () => {
    const pageId = this.query?.get?.('pageD') ?? 0;

    return +pageId || 0;
  };

  pageIdAsGET = (pageId) => {
    pageId = pageId === undefined ? this.pageId() : pageId;

    const { filterId, } = this.mf;
    const result = {
      filterId,
      skip: pageId * this.state.pages.take,
      take: this.state.pages.take,
    };

    return result;
  };

  onDataLoad = async (pageId) => {
    const pageOptions = this.pageIdAsGET(pageId);

    await this.save({ loaded: false, });
    await this.save(({ systemAccounts: _, }) => ({
      systemAccounts: { ..._, options: pageOptions, },
    }));
    try {
      await listAccountService.systemAccounts(pageOptions);
    } catch (e) {
      await this.save(({ pages, }) => ({
        pages: { ...pages, filter: 0, total: 0, },
        systemAccounts: { data: [], hash: null, options: {}, },
      }));
    }
    await this.save({ loaded: true, });
    await this.props.count(this.state.systemAccounts.data.length);
  };

  onPageClick = ({ event, pageId, pageIs, }) => {
    this.onDataLoad(pageId);
  };

  onPageText = (pageId, pageIs) => {
    const { translate, } = this.props;
    // eslint-disable-next-line object-curly-newline
    const { current, first, prev, next, last, } = pageIs;
    // eslint-disable-next-line object-curly-newline
    const { skipped, taken, filter, total, } = pageIs;

    if (skipped || taken) {
      const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : '?';
      const text = skipped ? translate('partners_pages_items') : '';

      return skipped ? `${ text }: ${ id }` : id;
    } else if (filter || total) {
      const id = Number.isInteger(pageId) ? `${ pageId }` : '?';
      const text = translate(filter ? 'partners_pages_filtered' : 'partners_pages_total');

      return `${ text }: ${ id }`;
    } else if (first || prev || next || last) {
      return '';
    } else if (current) {
      return `${ pageId + 1 }`;
    }

    return `${ pageId + 1 }`;
  };
}

export default withRouter(withLocalize(SearchSystemAccountsId));