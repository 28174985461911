/* eslint-disable no-param-reassign , sort-keys , max-len , no-unused-vars , new-cap ,*/

import React, { PureComponent, } from "react";
import { withLocalize, } from "react-localize-redux";
import { withRouter, } from "react-router-dom";
import {
	MagicButton,
	MagicFilterPanels,
	MagicTable,
	Mentor,
} from "../../../../../../components/Magic";
import {
	deletePartnersGroup,
	getAccountGroupsList,
} from "../../../../../../services/PartnersRequestService";
import Loader from "../../../../../tima/components/Loader";
import { MagicConfirm, } from "../../../../../tima/components/Magic/MagicConfirm";
import PagePanel from "../../../../../tima/components/Pages";
import Moment from "moment";
import Preloader from "../../../../../../components/LoadingHOC/Preloader";
import CreateUpdateAccountsGroupList from "../Popup/CreateUpdateAccountsGroupList";
import { MagicTooltip, } from "../../../../../tima/components/Magic";
import { Input, } from "../../../../components";

const accountGroupsListStatus = Object.freeze({
	ACTIVE: 2,
	CLOSED: 3,
	DISABLE: 0,
	NEW: 1,
});

class AccountGroupsListsMagicTable extends PureComponent {
	constructor (props) {
		super(props);

		this.state = {
			data: [],
			filterLastChange: Date.now(),
			group_list_name_search: "",
			isEmptyData: false,
			loaded: false,
			pages: {
				filter: 0,
				take: 50,
				total: 0,
				variance: 2,
			},
			removeAccountGroupListId: null,
		};

		const mf = new Mentor({
			owner: this,
			serviceId: 172,
			translate: this.props.translate,
		});

		Object.defineProperty(this, "mf", { get: () => mf, });
	}

	async componentDidMount () {
		this.mf.subscribe(
			{
				changed: this.mfChanged,
				cleaned: this.mfDone,
				cleaning: this.mfDoing,
				loaded: this.mfDone,
				loading: this.mfDoing,
				saved: this.mfDone,
				saving: this.mfDoing,
			},
			this,
		);

		await this.mf.init({ doEmit: true, });
	}

	componentWillUnmount () {
		this.mf.unsubscribe([ this.mfChanged, this.mfDoing, this.mfDone, ], this);
	}

	params = () => this.props.match.params;

	query = () => new URLSearchParams(this.props.location.search);

	save = state => new Promise(next => this.setState(state, next));

	mfChanged = async () => {
		await this.save({ filterLastChange: Date.now(), });
	};

	mfDoing = async () => {
		await this.save({ loaded: false, });
	};

	mfDone = async () => {
		await this.mfChanged();
		await this.onDataLoad();
	};

	onChange = (name, value) => this.save({ [name]: value, });

	get accountGroupsListsConfig () {
		const { translate, } = this.props;
		const prefix = "partners_symbols_lists_";

		return [
			{
				path: [ "partners", "groups_lists", "id", ],
				key: "groupListId",
				render: this.renderInlineValue,
				title: `${ prefix }id`,
				orderId: "o:id",
			},
			{
				path: [ "partners", "groups_lists", "name", ],
				key: "groupListName",
				render: this.renderInlineValue,
				title: `${ prefix }name`,
				orderId: "o:name",
			},
			{
				path: [ "partners", "groups_lists", "list", ],
				key: "groupList",
				render: this.renderAccountGroupsLists,
				xtitle: this.renderAccountGroupsListsHead(),
			},
			{
				path: [ "partners", "groups_lists", "amount", ],
				key: "groupAmount",
				render: this.renderInlineValue,
				title: `${ prefix }groups_amount`,
				orderId: "o:amountGroup",
			},
			{
				accessChecked: true,
				path: [ "core", "dictionary_trade_servers", "server_name", ],
				key: "groupServerName",
				render: this.renderInlineValue,
				title: `${ prefix }server_name`,
				orderId: "o:serverType",
			},
			{
				path: [ "partners", "groups_lists", "status", ],
				key: "groupStatus",
				render: this.renderStatusValue,
				title: `${ prefix }status`,
				orderId: "o:statusList",
			},
			{
				path: [ "partners", "groups_lists", "created_at", ],
				key: "groupCreatedAt",
				render: this.renderDate,
				title: `${ prefix }created_at`,
				orderId: "o:createdAt",
			},
			{
				path: [ "partners", "groups_lists", "updated_at", ],
				key: "groupUpdatedAt",
				render: this.renderDate,
				title: `${ prefix }updated_at`,
				orderId: "o:updatedAt",
			},
			{
				path: [ "partners", "groups_lists", "id", ],
				key: "groupActions",
				render: this.renderActions,
				title: `${ prefix }actions`,
				customAccessCheck: ({ item, }) => {
					return (
						item.access("show") &&
						(item.access("update") || item.access("destroy"))
					);
				},
			},
		].map(({
			orderId: o, title: t, xtitle: x, ...item
		}) => ({
			...item,
			...o && {
				order: async () => {
					await this.mf.orderedToNext(o);
				},
				orderTo: this.mf.orderedTo(o),
			},
			...t && { title: translate(t), },
			...x && { title: x, },
		}));
	}

	onInputChange = async (event) => {
		const { name, value, } = event.target;

		const data = [ ...this.state.data, ];
		const valueLowerCase = value.toLowerCase();

		data.forEach((item) => {
			const list =
				Object.values(JSON.parse(item.partners.groups_lists.list)).join("") ??
				"";

			item.isVisible = list.toLowerCase().indexOf(valueLowerCase) !== -1;
		});

		await this.save({ data, [name]: value, });
	};

	renderAccountGroupsListsHead = () => {
		const { translate, } = this.props;
		const { group_list_name_search, } = this.state;

		return (
			<Input
				label=""
				name="group_list_name_search"
				placeholder={ translate("partners_groups-list") }
				type="text"
				value={ group_list_name_search }
				wrapperClassName="search"
				onChange={ this.onInputChange }
			/>
		);
	};

	renderInlineValue = (value, { item, }) => {
		const options = {
			content: value ?? "-",
			lengthCut: 20,
		};

		if (item?.access?.("index")) {
			return <MagicTooltip { ...options } />;
		}

		return "-";
	};

	renderStatusValue = (value, { item, }) => {
		const statusEnum = this.props.enums?.data?.partners?.MtGroupListStatusEnum;

		if (item?.access?.("index")) {
			return statusEnum[value] ?? "-";
		}

		return "-";
	};

	renderDate = (date, { item, }) => {
		if (item?.access?.("index")) {
			const formatDB = "YYYY-MM-DD HH:mm:ss";
			const formatOut = "YYYY-MM-DD HH:mm:ss";

			const newDate = Moment(date, formatDB);

			return newDate.isValid() ? newDate.format(formatOut) : "-";
		}

		return "-";
	};

	removeAccountGroupList = async (
			symbolListId = this.state.removeAccountGroupListId,
	) => {
		if (!symbolListId) {
			console.warn("symbol list id not found");

			return;
		}

		try {
			await deletePartnersGroup(symbolListId);
			await this.onDataLoad();
		} catch (error) {
			error?.showErrorNotification?.();
		} finally {
			await this.onChange("removeAccountGroupListId", null);
		}
	};

	renderActions = (value, { item, items, data, }) => {
		const { translate, popup, } = this.props;
		const { removeAccountGroupListId, } = this.state;

		const renderRemoveButton = () => {
			if (item.access("destroy")) {
				const options = {
					className:
						"magic-button__item magic-button__item_partners-delete-symbol margin--top-10",
					children: translate("symbols_actions_delete"),
					onClick: () => this.onChange("removeAccountGroupListId", value),
				};

				return (
					<div className="magic-confirm-wrapper">
						<MagicConfirm
							title={ translate(`magic_confirm_title`) }
							accept={ translate("symbols_remove_accept") }
							closeByClickOutside={ true }
							isVisible={ Number(removeAccountGroupListId) === Number(value) }
							reject={ translate("symbols_remove_reject") }
							onAccept={ () =>
								this.removeAccountGroupList(removeAccountGroupListId)
							}
							onReject={ () => this.onChange("removeAccountGroupListId", null) }
						/>
						<MagicButton { ...options } />
					</div>
				);
			}
		};

		const renderEditButton = () => {
			const options = {
				className: "magic-button__item magic-button__item_partners-edit-symbol",
				children: translate("symbols_actions_edit"),
				onClick: () => popup.togglePopup(data?.partners?.groups_lists),
			};

			return (
				<div className="magic-confirm-wrapper">
					<MagicButton { ...options } />
				</div>
			);
		};

		return (
			<>
				{item.access("update") && renderEditButton()}
				{item.access("destroy") && renderRemoveButton()}
			</>
		);
	};

	renderList = (listObj) => {
		return Object.entries(listObj).map(([ key, value, ]) => {
			return (
				<span className="groups-bg" key={ key }>
					{value}
				</span>
			);
		});
	};

	renderAccountGroupsLists = (value, { item, }) => {
		if (item?.access?.("index")) {
			const listObj = JSON.parse(value);

			const listMarkUp = this.renderList(listObj);

			return (
				<div className="partners-table-list symbols-list">{listMarkUp}</div>
			);
		}

		return "-";
	};

	renderTable = () => {
		const {
			data, isEmptyData, pages, loaded,
		} = this.state;
		const { translate, } = this.props;

		const options = {
			config: this.accountGroupsListsConfig,
			data: data.filter(({ isVisible, }) => isVisible ?? true),
			head: [
				"groupListId",
				"groupListName",
				"groupList",
				"groupAmount",
				"groupServerName",
				"groupName",
				"groupStatus",
				"groupCreatedAt",
				"groupUpdatedAt",
				"groupActions",
			],
			RowProps: ({ items, }) => {
				return {
					"data-disabled": [ accountGroupsListStatus.CLOSED, ].includes(
						items?.groupStatus?.valueOf,
					),
				};
			},
		};

		return (
			<>
				<MagicFilterPanels mf={ this.mf } show={ true } translate={ translate } />
				<div className="content-block">
					<div
						className={ `table-wrapper partners__table ${
							isEmptyData ? "partners__table--empty" : ""
						}` }
					>
						<Loader
							loaded={ loaded }
							loading={ <Preloader scale={ 1 } /> }
							translate={ translate }
						>
							<MagicTable { ...options } />
							{isEmptyData && (
								<div className="table__empty-text">
									{translate("symbols_empty")}
								</div>
							)}

							<PagePanel
								doText={ this.onPageText }
								doTo={ pageId => `?page=${ pageId }` }
								filter={ pages.filter }
								page={ this.pageId() }
								take={ pages.take }
								total={ pages.total }
								variance={ pages.variance }
								onClick={ this.onPageClick }
							/>
						</Loader>
					</div>
				</div>
			</>
		);
	};

	render () {
		const { popup, enums, } = this.props;

		return (
			<>
				{popup.isOpen && (
					<CreateUpdateAccountsGroupList
						enums={ enums }
						formData={ popup.data }
						onClose={ () => popup.togglePopup() }
						onDataLoad={ this.onDataLoad }
					/>
				)}

				{this.renderTable()}
			</>
		);
	}

	pageId = () => {
		const page = this.query().get("page");

		return Number(page) || 0;
	};

	pageIdAsGET = (pageId) => {
		pageId = pageId === undefined ? this.pageId() : pageId;
		const { filterId, } = this.mf;

		return {
			filterId,
			take: this.state.pages.take,
			skip: pageId * this.state.pages.take,
		};
	};

	onDataLoad = async (pageId) => {
		await this.save({ loaded: false, isEmptyData: false, });

		const pageOptions = this.pageIdAsGET(pageId);
		const loaded = (data, meta) => ({ pages, }) => ({
			data,
			pages: { ...pages, ...meta, },
			...!data.length && { isEmptyData: true, },
		});

		try {
			const response = await getAccountGroupsList(pageOptions);
			const {
				data = [],
				meta: { filter = 0, total = 0, totalPages = 0, },
			} = response;

			await this.save(loaded(data, { filter, total, totalPages, }));
		} catch (error) {
			await this.save(loaded([], { filter: 0, total: 0, totalPages: 0, }));
			error?.showErrorNotification?.();
		}
		await this.save(state => ({
			loaded: true,
			...state.removeAccountGroupListId && { removeAccountGroupListId: null, },
		}));
	};

	onPageClick = ({ event, pageId, pageIs, }) => {
		this.onDataLoad(pageId);
	};

	onPageText = (pageId, pageIs) => {
		const { translate, } = this.props;
		const {
			current, first, prev, next, last,
		} = pageIs;
		const {
			skipped, taken, filter, total,
		} = pageIs;

		if (skipped || taken) {
			const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : "?";
			const text = skipped ? translate("partners_pages_items") : "";

			return skipped ? `${ text }: ${ id }` : id;
		} else if (filter || total) {
			const id = Number.isInteger(pageId) ? `${ pageId }` : "?";
			const text = translate(
				filter ? "partners_pages_filtered" : "partners_pages_total",
			);

			return `${ text }: ${ id }`;
		} else if (first || prev || next || last) {
			return "";
		} else if (current) {
			return `${ pageId + 1 }`;
		}

		return `${ pageId + 1 }`;
	};
}

export default withRouter(withLocalize(AccountGroupsListsMagicTable));