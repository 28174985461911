import React, { Component } from 'react';
import { withLocalize } from "react-localize-redux";
import { Collapse, CardBody, Card } from 'reactstrap';
import ModalBTNTranslation from "./ModalBTNTranslation";
import {CREATE_TEMPLATE_TRANSLATION, SMS_TEMPLATE, E_MAIL_TEMPLATE} from '../constants';
import NotificationService from "../../../../../services/NotificationService";
import { MagicSelect } from "../../../../../components/Magic/components/MagicSelect";
import { MagicTemplate } from "../../../../../components/Magic/components/MagicTemplate";
import { templateService } from "../../../../../services/TemplateDataService";

class EmptyTemplate extends Component {
    constructor (props) {
        super(props);

        this.state = {
            selectLangChange: null,
            typeTemplateTranslate: null,
            createSMSTemplateTranslate:{
                template_id: this.props.popUpTemplateManagement.template_id ?? null,
                language_id: null,
                text: '',
            },
            createMailTemplateTranslate:{
                template_id: this.props.popUpTemplateManagement.template_id ?? null,
                language_id: null,
                text: '',
                subject: '',
            },
            errors: {
                language_id: false,
                text: false,
                subject: false,
            },
            sendRequest: false,
            counterOnlyText: 0,
            maxLengthCounter: 3500,
        };
        this.editor = React.createRef();
    }

    async componentWillUnmount () {
        await this.clearStateAfterUpload();
    }

    save = async (state) => new Promise(next => this.setState(state, next));

    clearTemplateTranslation = async (modal_name, cancel) => {
        if (modal_name === SMS_TEMPLATE) {
            if (cancel) {
                await this.save((state) => ({
                    createSMSTemplateTranslate: {
                        ...state.createSMSTemplateTranslate, language_id: null, text: '',
                    },
                    selectLangChange: null,
                }));
            } else {
                await this.save((state) => ({
                    createSMSTemplateTranslate: {
                        ...state.createSMSTemplateTranslate,
                    },
                }));
            }
        } else if (modal_name === E_MAIL_TEMPLATE) {
            if (cancel) {
                await this.save((state) => ({
                    createMailTemplateTranslate: {
                        ...state.createMailTemplateTranslate, language_id: null, text: '', subject: '',
                    },
                    selectLangChange: null,
                }));
            } else {
                await this.save((state) => ({
                    createMailTemplateTranslate: {
                        ...state.createMailTemplateTranslate,
                    },
                }));
            }
        }
    };

    setToState = async (name, value) => {
        name === 'language_id' ? await this.save({ selectLangChange: value, sendRequest: false }) : null;

        if (this.props.popUpTemplateManagement.modal_name === SMS_TEMPLATE) {
            await this.save((state) => {
                return {
                    createSMSTemplateTranslate: { ...state.createSMSTemplateTranslate, [name]: value },
                    sendRequest: false,
                };
            });
        } else if (this.props.popUpTemplateManagement.modal_name === E_MAIL_TEMPLATE) {
            await this.save((state) => {
                return {
                    createMailTemplateTranslate: { ...state.createMailTemplateTranslate, [name]: value },
                    sendRequest: false,
                };
            });
        }
    };
    //Говнокод - потому что нужно сделать быстро!!
    setTemplateToState = async (value) => {
        if (this.props.popUpTemplateManagement.modal_name === SMS_TEMPLATE) {
            await this.save({
                createSMSTemplateTranslate: {
                    ...this.state.createSMSTemplateTranslate,
                    text: value,
                },
                sendRequest: false,
            });
        } else if (this.props.popUpTemplateManagement.modal_name === E_MAIL_TEMPLATE) {
            const counterOnlyText = this.textCounter();
            if (counterOnlyText > this.state.maxLengthCounter) {
                return (
                    NotificationService.error({
                        title:   'error',
                        message: this.props.translate('template_management_error_max_length'),
                        remove:  false,
                    })
                );
            } else {
                await this.save({
                    createMailTemplateTranslate: {
                        ...this.state.createMailTemplateTranslate,
                        text: value,
                    },
                    sendRequest: false,
                    counterOnlyText,
                });
            }
        }
        this.clearErrorForClick('text');
    };

    textCounter = () => this.editor?.current?.querySelector('.ql-editor')?.textContent?.length;

    createTemplateTranslation = async (translate) => {
        if (await this.validationFields()) {
            await this.save({ sendRequest: true });
            try {
                if (this.props.popUpTemplateManagement.modal_name === SMS_TEMPLATE) {
                    await templateService.createSmsTemplatesTranslation(this.state.createSMSTemplateTranslate);
                    await this.props.reloadTemplatesTranslation(this.props.popUpTemplateManagement.template_id, SMS_TEMPLATE);
                }
                if (this.props.popUpTemplateManagement.modal_name === E_MAIL_TEMPLATE) {
                    await templateService.createMailTemplatesTranslation(this.state.createMailTemplateTranslate);
                    await this.props.reloadTemplatesTranslation(this.props.popUpTemplateManagement.template_id, E_MAIL_TEMPLATE);
                }
                await this.clearStateAfterUpload();
                await this.props.toggleTemplateCollaps(CREATE_TEMPLATE_TRANSLATION);
                await this.props._getTempl();
                NotificationService.success({
                    title:   'success',
                    message: translate(`template_management_create_template_translation`),
                    remove:  true,
                    view:    true,
                });
            } catch (error) {
                await this.save({ sendRequest: false });
                this.errorFromServer(error.response.data.errors);
            }
            await this.save({ sendRequest: false });
        } else {
            await this.save({ sendRequest: true });

            return null;
        }
    };

    renderLanguageSelect = () => {
        const valueDefault = true;
        const values = this.props.dataLanguage ?? [];
        const options = {
            className: [this.state?.errors?.language_id ? 'error' : ''],
            onChange: async (value) => {
                this.clearErrorForClick('language_id');
                await this.setToState('language_id', value);
            },
            value:        this.state.selectLangChange ?? '',
            values:       values?.map?.((data) => data.value) ?? [],
            valueToLabel: (id) => {
                if (id === valueDefault) {
                    return this.props.translate('template_management_choose_language');
                }
                if (values?.length) {
                    const find = values?.find((data) => id === data.value );

                    return find ? find.label : this.props.translate('template_management_choose_language');
                }
            },
        };

        return (
            <MagicSelect { ...options } />
        );
    };

    renderTextArea = () => {
        const textareaOptions = {
            className:   `${this.state.errors.text ? 'error' : ''}`,
            rows:        9,
            name:        'text',
            placeholder: this.props.translate('template_management_enter_body_sms'),
            value:       this.state.createSMSTemplateTranslate.text ?? '',
            onChange:    async (event) => {
                this.clearErrorForClick('text');
                await this.setToState(event.target.name, event.target.value);
            },
        };
        const optionsQuill = {
            placeholder:  this.props.translate('template_management_enter_body'),
            value:        this.state.createMailTemplateTranslate.text ?? '',
            handleChange: this.setTemplateToState,
            className:    `${this.state.errors.text ? 'error' : ''}`,
        };

        return (
            <div className = 'form-group'>
                {
                    this.props.popUpTemplateManagement.modal_name !== SMS_TEMPLATE ?
                        <div className="template-counter">
                            <p className="counter">{this.state.counterOnlyText} / {this.state.maxLengthCounter}</p>
                        </div>
                        : null
                }
                {
                    this.props.popUpTemplateManagement.modal_name === SMS_TEMPLATE
                        ? <textarea { ...textareaOptions } />
                        :  <div ref={ this.editor }><MagicTemplate { ...optionsQuill } /></div>
                }
            </div>
        );
    };

    clearStateAfterUpload = async () => {
        await this.save({
            createSMSTemplateTranslate: {
                template_id: this.props.popUpTemplateManagement.template_id ?? null,
                language_id: null,
                text:        '',
            },
            createMailTemplateTranslate: {
                template_id: this.props.popUpTemplateManagement.template_id ?? null,
                language_id: null,
                text:        '',
                subject:     '',
            },
            selectLangChange: null,
        });
    };

    /*Error Notification*/
    errorFromServer = object => {
        let serverErrorKeys = Object.keys(object);

        serverErrorKeys.forEach(async (item) => {
            await this.save({ errors: {
                ...this.state.errors,
                [item]:      true,
                template_id: false,
            }});

            NotificationService.error({
                title:   'error',
                message: object[item],
                remove:  false,
            });
        });
    };

    validationFields = async () => {
        await this.save({ errors: {}});
        const validationError = [];
        if (this.props.popUpTemplateManagement.modal_name === SMS_TEMPLATE) {
            const createTemplates = Object.fromEntries(
                ['template_id', 'language_id', 'text'].filter((field) => this.state.createSMSTemplateTranslate[field] === null || this.state.createSMSTemplateTranslate[field] === '' || this.state.createSMSTemplateTranslate[field].length === 0)
                    .map((field) => [field, true]),
            );
            if (Object.keys(createTemplates).length) {
                await this.save({ errors: createTemplates });
            }
            Object.keys(this.state.errors).forEach(item => {
                if (this.state.errors[item] === true) {
                    validationError.push(item);
                }
            });
        } else if (this.props.popUpTemplateManagement.modal_name === E_MAIL_TEMPLATE) {
            const createTemplates = Object.fromEntries(
                ['template_id', 'language_id', 'subject', 'text'].filter((field) => this.state.createMailTemplateTranslate[field] === null || this.state.createMailTemplateTranslate[field] === '' || this.state.createMailTemplateTranslate[field].length === 0)
                    .map((field) => [field, true]),
            );
            if (Object.keys(createTemplates).length) {
                await this.save({ errors: createTemplates });
            }
            Object.keys(this.state.errors).forEach(item => {
                if (this.state.errors[item] === true) {
                    validationError.push(item);
                }
            });
        }
        if (validationError.length) {
            validationError.length = 0;
            return false;
        } else {
            await this.save({ errors: {}});
            validationError.length = 0;
            return true;
        }
    };

    clearErrorForClick = async (name) => {
        await this.save({ errors: { ...this.state.errors, [name]: false }});
    };

    render () {
        return (
            <div className="modal_management-add_item">
                <Collapse isOpen={this.props.addTemplateCollaps}>
                    <Card>
                        <CardBody>
                            <div className = 'form-group'>
                                <div className = { `form-select` }>
                                    { this.renderLanguageSelect() }
                                </div>
                            </div>

                            {this.props.popUpTemplateManagement.modal_name === E_MAIL_TEMPLATE ?
                                <div className = 'form-group'>
                                    <input
                                        autoComplete = 'off'
                                        name = 'subject'
                                        className={ `${this.state.errors.subject ? 'error' : ''} card-transcription-input` }
                                        placeholder={ this.props.translate('template_management_enter_subject') }
                                        value={this.state.createMailTemplateTranslate.subject ?? ''}
                                        onChange = {async (event) => {
                                            this.clearErrorForClick('subject');
                                            await this.setToState(event.target.name, event.target.value);
                                        }}
                                    />
                                </div>
                                : ''}
                            {this.renderTextArea()}
                            <ModalBTNTranslation
                                popUpTemplateManagement = {this.props.popUpTemplateManagement}
                                toggleTemplateCollaps = {this.props.toggleTemplateCollaps}
                                clearTemplateTranslation = {this.clearTemplateTranslation}
                                templateTranslation = {this.props.typeTemplateTranslate}
                                createTemplateTranslation = {this.createTemplateTranslation}
                                sendRequest={this.state.sendRequest}
                            />

                            <div className="modal_management-add_btn_2" onClick={ async () => this.props.toggleTemplateCollaps(CREATE_TEMPLATE_TRANSLATION) } />
                        </CardBody>
                    </Card>
                </Collapse>
            </div>
        );
    }
}

export default withLocalize(EmptyTemplate);
