export const CLIENTS_LIST_UTM_EXPORT_FIELDS = {
  export_clients_utm: [
    'statistics-account_utm_link-account_id',
    'core-account-name',
    'core-account-surname',
    'core-account_categories-category_id',
    'core-account_categories-created_at',
    'core-country-name',
    'statistics-utm_source-name',
    'statistics-utm_source-number',
    'statistics-utm_medium-name',
    'statistics-utm_medium-number',
    'statistics-utm_campaign-name',
    'statistics-utm_campaign-number',
    'core-account-created_at',
    'core-dictionary_licenses-alias',
  ],
};
