import React from "react";

export const autoCall = {
    "auto_call": ["Autocall", "Автообзвон", "Автозізвон"],
    "auto_call_tittle": ["Settings for autocall clients", "Настройки для автообзвона клиентов", "Налаштування для автозізвону клієнтів"],
    "auto_call_first_select": ["Pause after auto-dial", "Пауза после автообзвона", "Пауза після автозізвону"],
    "auto_call_second_select": ["Days to “No answer”", "Дней до перехода в “не отвечает”", "Дні до переходу в “не відповідає”"],
    "auto_call_second_select_subtitle": ["from 1 to 30 days including", "от 1 до 30 дней включая", "від 1 до 30 днів включно"],
    "auto_call_third_select": ["Unsuccessful dials in a row", "Неуспешные звонки подряд", "Неуспішні дзвінки поспіль"],
    "auto_call_fours_select": ["Dial tries quantity", "Кол-во попыток набора", "Кількість спроб набору"],
    "auto_call_fifth_select": ["Time to answer", "Время ответа менеджера", "Час на відповідь менеджера"],
    "auto_call_six_select": ["Auto-dialer interval", "Интервал между автозвонками", "Інтервал між автодзвінками"],
    "auto_call_text_days": ["days", "дней", "днів"],
    "auto_call_text_sec": ["sec.", "сек.", "сек."],
    "auto_call_text_days_second": ["day", "день", "день"],
    "auto_call_default_value": ["Choose", "Выберите", "Оберіть"],
};