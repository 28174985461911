import React from 'react';
import errorImg from '../../../images/404.svg';
import { Redirect } from 'react-router-dom';

export default class ErrorPage extends React.Component {
    state = {
        redirect: false,
    };

    goBack = () => {
        this.setState( { redirect: true, } );
        // this.props.history.goBack();
    };

    render = () => {
        if ( this.state.redirect ) {
            return (<Redirect to={ '/' } />);
        }
        return (
            <div className='error-page-wrapper'>
                <div className='error-page-inner'>
                    <div className='error-page-content'>
                        <div className='error-page'>
                            <div className='title h3'>Sorry, We’re For Maintenance</div>
                            <div className='subtitle h4'>We’ll be back shortly</div>
                            <div className='d-flex align-items-center justify-content-center'>
                                <div className='button big red unshadow' onClick={ this.goBack }>Go Back</div>
                            </div>
                            <div className='big-error'>
                                <img alt='404' className='big-error-img' src={ errorImg } />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};
