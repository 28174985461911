import React from "react";
import { isUndefined } from 'lodash';
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { withLocalize, Translate } from "react-localize-redux";

import dateFns from "date-fns";
// import { getKPIData } from "../../services/KPIService";

class TopKPIWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentDay: new Date(),
            currentMonth: new Date(),
            selectedDate: new Date(),
            // dataUserId: this.props.userId,
            dataMonth: new Date().getMonth() + 1,
            dataYear: new Date().getFullYear(),
            firstPossibleYear: new Date().getFullYear(),
            kpiData: this.props.kpiData,
            kpiFade: true,
            // openKPIbutton: false,
        };
    }

    UNSAFE_componentWillReceiveProps() {
        const routeHref = location.pathname.split('/');

        if (routeHref.includes('task') ||
            routeHref.includes('lead') ||
            routeHref.includes('taskCreate') ||
            routeHref.includes('clientCreate') ||
            routeHref.includes('clientEdit')
            // ||(routeHref.includes('clients') && routeHref.length > 2)

        ) {
            this.setState(() => {
                this.setState({ kpiFade: false });
            });
        } else {
            this.setState({ kpiFade: true });
        }
    }

    componentDidMount() {
        // this.props.getKPIData({ "userId": this.state.dataUserId, "year": this.state.dataYear, "month": this.state.dataMonth, });
    }

    changeDataUserId = (event) => {
        this.setState({
            dataUserId: event.target.dataset.item,
        });
    };

    topBarKPIBlock = (kpi, monthEnd, currentDay, translate) => {
        !isUndefined(kpi) && kpi ?
            kpi.map((item, key) => {

                const progress = Math.round(item.result * 100 / item.aim);
                const should = Math.round(item.aim / monthEnd.getDate() * currentDay.getDate());

                return (
                    <div className=' topbar-kpi-block' key={key} >
                        <div className=' topbar-kpi-title'>{translate(`${item.title}`)} {should}</div>
                        <div className=' topbar-kpi-info'>
                            <div className='kpi-circle' />
                            {item.result}/{progress}%
                        </div>
                    </div>
                );
            })
            : () => null
            ;
    };

    // openKPIblock = () => {
    //     // this.setState({ openKPIbutton: !this.state.openKPIbutton });
    //     this.props.openKPI();
    // };

    render() {
        const kpi = this.state.kpiData;
        const { currentMonth, currentDay } = this.state;
        const monthStart = dateFns.startOfMonth(currentMonth);
        const monthEnd = dateFns.endOfMonth(monthStart, { weekStartsOn: 1 });

        return (
            <Translate>
                {({ translate }) => {

                    return (
                        <React.Fragment>
                            {this.state.kpiFade ?
                                <div className='topbar-kpi-block left-align'>
                                    <div className='topbar-kpi-title grey'>KPI</div>
                                    <div className='topbar-kpi-info'>{dateFns.format(new Date(), 'DD.MM.YYYY')}</div>
                                </div>
                                : null}
                            {this.state.kpiFade ?
                                this.topBarKPIBlock(kpi, monthEnd, currentDay, translate)
                                : null}
                            {this.state.kpiFade ?
                                <div className='topbar-kpi-block details' onClick={this.props?.openKPI}>
                                    <div className='topbar-kpi-info grey details'>{translate(`dashboard_details`)} <span className='green-circle'>
                                        <i className={this.props?.booleanOpenKPI ? 'la la-angle-up' : 'la la-angle-down close'} />
                                    </span></div>
                                </div>
                                : null
                            }
                        </React.Fragment>
                    );
                }}
            </Translate>
        );
    }
}

/*const mapStateToProps = (store) => {
    return {
        // userId: store.user.session.user_id,
        kpiData: store.kpi.kpiData,
    };
};

export default withLocalize(withRouter(connect(
    mapStateToProps,
    (dispatch) =>
        bindActionCreators({
            getKPIData,
        }, dispatch)
)(TopKPIWidget)));*/
export default withLocalize(withRouter(TopKPIWidget));
