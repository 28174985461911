/* eslint-disable no-shadow */
/* eslint-disable sort-keys */
import React from "react";
import _ from "lodash";
import { Translate, } from "react-localize-redux";
import { Table, } from "reactstrap";
import Select from "react-select";
import { Link, } from "react-router-dom";

import CheckUndefinedPermission from "../../../../../../../../../components/Common/CheckUndefinedPermission";
import PermissionService from "../../../../../../../../../services/PermissionService";
import { getCountryPayoutRateList, editPartnerData, } from "../../../../../../../../../services/PartnersRequestService";
import CreateUpdatePayoutRateList from "../../../../../../AffiliateProgramsManagement/CountryListWithPayoutRates/Popup/CreateUpdatePayoutRateList";

import SwitchPartnerProgramm from "../../../../popups/SwitchPartnerProgramm";
import PayoutPercentages from "../../../../popups/PayoutPercentages";
import { MagicButton, } from "../../../../../../../../../components/Magic";
import { MagicConfirm, } from "../../../../../../../../tima/components/Magic";

import { findOptionByValue, } from "../../../../../../../utils/helpers";
import prepareDataForEditPayoutRateList from "../helpers/helpers";

class PartnerProgramm extends React.Component {
	constructor (props) {
		super(props);

		this.state = {
			changeProgrammPopupIsOpen: false,

			createUpdatePayoutRateListPopup: {
				isOpen: false,
				togglePopup: this.toggleCreateUpdatePayoutRateListPopup,
				data: {},
			},

			payoutPercentagesPopup: {
				isOpen: false,
				togglePopup: this.togglePayoutPercentagesPopup,
				data: {},
			},

			selectedCondition: null,

			rebate_status: 0,
			autopay_status: 2,

			rebateStatusConfirm: {
				isOpen: false,
				confirmValue: null,
			},

			autopayStatusConfirm: {
				isOpen: false,
				confirmValue: null,
			},
		};
	}

	save = state => new Promise(next => this.setState(state, next));

	toggleRebateStatusConfirm = (isOpen, confirmValue) => {
		this.save({
			rebateStatusConfirm: {
				isOpen,
				confirmValue,
			},
		});
	}

	toggleAutopayStatusConfirm = (isOpen, confirmValue) => {
		this.save({
			autopayStatusConfirm: {
				isOpen,
				confirmValue,
			},
		});
	}

	getPermissions = (action, field) => {
		const path = field ? [ 'partners', 'partners_setup', field, ] : [ 'partners', 'partners_setup', ];

		const pm = PermissionService.calc([ {
			path,
			key: 'user',
		}, ]);

		return pm.user.access(action);
	}
	componentDidMount = async () => {
		const firstCondition = this.props.partner.partners.new_conditions_data[0];
		const rebate_status =
			this.props.partner.partners.partners_setup.rebate_status;
		const autopay_status =
			this.props.partner.partners.partners_setup.autopay_status;

		await this.save({
			selectedCondition: firstCondition,
			rebate_status,
			autopay_status,
		});
	};

	changePartnerDataProperty = async (entry) => {
		try {
			const { partner, refreshClientData, } = this.props;
			const { user_id, id, } = partner.partners.partners_setup;

			const sendData = {
				user_id,
				...entry,
			};

			await editPartnerData(id, sendData);
			await refreshClientData();
		} catch (error) {
			error?.showErrorNotification?.();
		}
	}
	get conditionsOptions () {
		const { partner, } = this.props;

		return partner.partners.new_conditions_data.map((condition) => {
			return {
				value: condition.condition.id,
				label: condition.condition.name,
			};
		});
	}

	get tableItems () {
		const { selectedCondition, rebate_status, autopay_status, } = this.state;

		const accountId = this.props.partner.core.account.id;
		const conditionType = selectedCondition?.condition.type;
		const programmStatus = this.props.partner.partners.program.status;
		const conditionLevel = selectedCondition?.condition.level;
		const conditionPeriod = selectedCondition?.condition.period;

		const conditionNeitherCplOrCpa =
			+conditionType !== 1 && +conditionType !== 2;

		const conditionNotCpa = +conditionType !== 1;

		return [
			{
				key: `partner_programm_name`,
				pmField: `name`,
				pmIndex: { schema: "partners", table: "programs", },
				value: () => this.renderProgrammName(),
			},
			{
				key: `partners_condition`,
				pmField: `name`,
				pmIndex: { schema: "partners", table: "conditions", },
				value: () => this.renderConditionSelect(),
			},
			{
				key: `partners_programm_condition_type`,
				pmField: `name`,
				pmIndex: { schema: "partners", table: "conditions", },
				value: () => this.renderEnumField(conditionType, "TypeEnum"),
			},
			{
				key: `partners_programm_status`,
				pmField: `status`,
				pmIndex: { schema: "partners", table: "programs", },
				value: () => this.renderEnumField(programmStatus, "StatusEnum"),
			},
			{
				key: `partners_condition_latency`,
				pmField: `name`,
				pmIndex: { schema: "partners", table: "conditions", },
				value: selectedCondition?.condition.latency,
			},
			{
				key: `partners_condition_period`,
				pmField: `name`,
				pmIndex: { schema: "partners", table: "conditions", },
				value: () => this.renderEnumField(conditionPeriod, "PeriodEnum"),
			},
			{
				key: `partners_condition_level`,
				pmField: `name`,
				pmIndex: { schema: "partners", table: "conditions", },
				value: () => this.renderEnumField(conditionLevel, "LevelEnum"),
			},
			{
				key: `partners_country_list_payout_rates`,
				pmField: `id`,
				pmIndex: { schema: "partners", table: "payout_rate_lists", },
				value: () => this.renderPayoutRatesByCountry(),
				hide: conditionNeitherCplOrCpa,
			},
			{
				key: `partners_ftd_fixation_period`,
				pmField: `first_deposit_fixing_hours`,
				pmIndex: { schema: "partners", table: "extra_cpa_conditions", },
				value:
					selectedCondition?.extra_cpa_conditions?.first_deposit_fixing_hours,
				hide: conditionNotCpa,
			},
			{
				key: `partners_payouts_percentage`,
				pmField: `id`,
				pmIndex: { schema: "partners", table: "payout_rate_lists", },
				value: () => this.renderPayoutPercentages(),
			},
			{
				key: `partner_programm_change`,
				pmAction: "changeProgram",
				pmIndex: {
					schema: "partners",
					table: "partners_setup",
				},
				value: () => this.renderSwitchPartnerProgramm(),
			},
			{
				key: `partners_system_categories_rebate`,
				pmField: "rebate_status",
				pmIndex: {
					schema: "partners",
					table: "partners_setup",
				},
				value: this.renderRebateStatus,
			},
			{
				key: `partners_autopayment`,
				pmField: "autopay_status",
				pmIndex: {
					schema: "partners",
					table: "partners_setup",
				},
				value: this.renderAutopayStatus,
			},
			// {
			// 	key: `partners_system_categories_statistics`,
			// 	pmField: "id",
			// 	pmIndex: {
			// 		schema: "partners",
			// 		table: "statistic",
			// 	},
			// 	value: (
			// 		<MagicButton className={ 'partner-card__view-button partner-card__view-button_link' } onClick={ () => null }>
			// 			<Link to={ `/partners/statistics/${ accountId }` }>
			// 				{this.props.translate("partner_view")}
			// 			</Link>
			// 		</MagicButton>
			// 	),
			// },
		]
			.filter(
				data =>
					data.pmAction
						? PermissionService.action(data.pmIndex, data.pmAction)
						: PermissionService.index(data.pmIndex).can(data.pmField),
			)
			.filter(data => !data.hide)
			.map(data => ({
				...data,
				value: data.value instanceof Function ? data.value() : data.value,
			}));
	}

	renderProgrammName = () => {
		const programmName = _.at(
			this.props,
			"partner.partners.program.name",
		).shift();

		return <span className={ 'text-justify' }>{programmName}</span>;
	};

	renderPayoutRatesByCountry = () => {
		const id = this.state.selectedCondition.extra_cp_conditions.payout_rate_list_id;

		return (
			<MagicButton
				className={ "partner-card__view-button" }
				onClick={ () => this.editCountryPayoutRateList(id) }
			>
				{this.props.translate("partner_view")}
			</MagicButton>
		);
	};

	renderPayoutPercentages = () => {
		return (
			<MagicButton
				className="partner-card__view-button"
				onClick={ () => this.state.payoutPercentagesPopup.togglePopup() }
			>
				{this.props.translate("partner_view")}
			</MagicButton>
		);
	};

	renderEnumField = (value, enumPath) => {
		if (!value || !enumPath) {
			return "";
		}

		return this.props.enums.data.partners[enumPath][value];
	};

	renderConditionSelect = () => {
		const conditionsOptions = this.conditionsOptions;
		const selectedCondition = this.state.selectedCondition;
		const new_conditions_data = this.props.partner.partners.new_conditions_data;

		return this.renderSelect({
			onChange: (selected) => {
				this.save({
					selectedCondition: new_conditions_data.find(
						condition => +condition.condition.id === +selected.value,
					),
				});
			},
			options: conditionsOptions,
			value: findOptionByValue(
				conditionsOptions,
				selectedCondition?.condition.id,
			),
		});
	};

	renderSwitchPartnerProgramm = () => {
		const translate = this.props.translate;
		const permissionToChangeProgramm = this.getPermissions('changeProgram');

		return this.renderButton({
			disabled: !permissionToChangeProgramm,
			children: translate("partner_programm_change_button"),
			onClick: this.toggleChangeProgrammPopup,
		});
	};

	renderRebateStatus = () => {
		const { rebate_status, rebateStatusConfirm, } = this.state;
		const { translate, } = this.props;

		return (
			<>
				<MagicConfirm
					accept={ translate("symbols_remove_accept") }
					className={ "magic-confirm" }
					isVisible={ rebateStatusConfirm.isOpen }
					reject={ translate("symbols_remove_reject") }
					title={ translate("client_delete_are_u_sure") }
					onAccept={ () => {
						this.changePartnerDataProperty({ rebate_status: rebateStatusConfirm.confirmValue, });
						this.toggleRebateStatusConfirm(false, null);
					} }
					onReject={ () => this.toggleRebateStatusConfirm(false, null) }
				/>

				{
					this.renderSwitch({
						disabled: !this.getPermissions('update', 'rebate_status'),
						checked: rebate_status === 1,
						onChange: event => this.toggleRebateStatusConfirm(true, event.target.checked ? 1 : 0),
					})
				}
			</>
		);
	}

	renderAutopayStatus = () => {
		const { autopayStatusConfirm, autopay_status, } = this.state;
		const { translate, } = this.props;

		return (
			<>
				<MagicConfirm
					accept={ translate("symbols_remove_accept") }
					className={ "magic-confirm" }
					isVisible={ autopayStatusConfirm.isOpen }
					reject={ translate("symbols_remove_reject") }
					title={ translate("client_delete_are_u_sure") }
					onAccept={ async () => {
						await this.changePartnerDataProperty({ autopay_status: autopayStatusConfirm.confirmValue, });
						await this.toggleAutopayStatusConfirm(false, null);
					} }
					onReject={ () => this.toggleAutopayStatusConfirm(false, null) }
				/>

				{
					this.renderSwitch({
						disabled: !this.getPermissions('update', 'autopay_status'),
						checked: autopay_status === 1,
						onChange: event => this.toggleAutopayStatusConfirm(true, event.target.checked ? 1 : 2),
					})
				}
			</>
		);
	}

	renderButton = (options) => {
		const translate = this.props.translate;

		return (
			<div className="button-wrapper">
				<MagicButton
					// eslint-disable-next-line react/no-children-prop
					children={ translate("partners_save_btn") }
					className="magic-button__item magic-button_partners_popup-save switch-programm-button"
					{ ...options }
				/>
			</div>
		);
	};

	renderSelect = options => (
		<div className="optipn_multiselect select-right">
			<Select
				className="filter-select multiselect partner-card__select"
				classNamePrefix="select"
				{ ...{
					isClearable: false,
					isMulti: false,
					...options,
				} }
			/>
		</div>
	);

	renderSwitch = options => (
		<div className="block-header_input-checkbox switch-custom-styles">
			<label className="checkbox">
				<input { ...{ type: "checkbox", name: "is_loyal", ...options, } } />
				<span />
			</label>
		</div>
	);

	toggleChangeProgrammPopup = () =>
		this.save({
			changeProgrammPopupIsOpen: !this.state.changeProgrammPopupIsOpen,
		});

	togglePayoutPercentagesPopup = () =>
		this.save({
			payoutPercentagesPopup: {
				...this.state.payoutPercentagesPopup,
				data: this.state.selectedCondition,
				isOpen: !this.state.payoutPercentagesPopup.isOpen,
			},
		});

	toggleCreateUpdatePayoutRateListPopup = newData =>
		this.save({
			createUpdatePayoutRateListPopup: {
				...this.state.createUpdatePayoutRateListPopup,
				...newData && { data: newData, },
				isOpen: !this.state.createUpdatePayoutRateListPopup.isOpen,
			},
		});

	editCountryPayoutRateList = async (id) => {
		if (!id) {
			return console.warn("payout rate list id not found");
		}
		try {
			const { createUpdatePayoutRateListPopup, } = this.state;
			const rawData = await getCountryPayoutRateList(id);
			const dataForEdit = prepareDataForEditPayoutRateList(
				rawData?.data?.partners,
			);

			return createUpdatePayoutRateListPopup.togglePopup(dataForEdit);
		} catch (error) {
			error?.showErrorNotification?.();
		}
	};

	render = () => {
		const {
			changeProgrammPopupIsOpen,
			createUpdatePayoutRateListPopup,
			payoutPercentagesPopup,
		} = this.state;
		const { enums, partner, refreshClientData, } = this.props;
		const tableItems = this.tableItems;
		const translate = this.props.translate;

		return (
			<CheckUndefinedPermission>
				{changeProgrammPopupIsOpen && (
					<SwitchPartnerProgramm
						partner={ partner }
						refreshClientData={ refreshClientData }
						translate={ translate }
						onClose={ this.toggleChangeProgrammPopup }
					/>
				)}

				{payoutPercentagesPopup.isOpen && (
					<PayoutPercentages
						data={ payoutPercentagesPopup.data }
						enums={ enums }
						translate={ translate }
						onClose={ async () => {
							await payoutPercentagesPopup.togglePopup();
						} }
					/>
				)}

				{createUpdatePayoutRateListPopup.isOpen && (
					<CreateUpdatePayoutRateList
						enums={ enums }
						formData={ createUpdatePayoutRateListPopup.data }
						showPopUp={ true }
						onClose={ async () => {
							await createUpdatePayoutRateListPopup.togglePopup();
						} }
						onDataLoad={ () => null }
					/>
				)}

				<Translate>
					{({ translate, }) => (
						<div className="block-shadowed partner-card__partner-programm">
							<span className="table-title">
								{translate(`partner_programm`)}
							</span>
							<div className="react-bs-table table-unbordered-container">
								<Table>
									<tbody>
										{tableItems.map((data, index) => (
											<tr key={ index }>
												<td className={ 'td td_label' }>{translate(data.key)}</td>
												<td className="td td_multiselect">{data.value}</td>
											</tr>
										))}
									</tbody>
								</Table>
							</div>
						</div>
					)}
				</Translate>
			</CheckUndefinedPermission>
		);
	};
}

export default PartnerProgramm;