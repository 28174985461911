import React, { Component, } from "react";
import { withLocalize, } from "react-localize-redux";
import { Input, } from "../../../../../../components";
import { setErrorClass, } from "../../../../../../utils/helpers";
import { onValidPercantages, } from "../../helpers/validation-helpers/onValidHelpers";
import Radio from "../Radio";
class PayoutRateBlockInOut extends Component {
	constructor (props) {
		super(props);
	}

	get disabled () {
		const {
			fieldIsNotEdit,
			isViewMode,
			statusIsClosed,
			statusIsActive,
		} = this.props;

		return fieldIsNotEdit || isViewMode || statusIsClosed || statusIsActive;
	}

	handleOnChange = fieldName => async (event) => {
		const { value, } = event.target;
		const { setInputsValues, clearErrorOnChange, } = this.props;

		try {
			clearErrorOnChange(event);
			switch (fieldName) {
				case "min_inout_reminder": {
					await setInputsValues(fieldName, `${ value }`);
					break;
				}
				case "payout_rate_for_the_estimated_amount": {
					await setInputsValues(fieldName, `${ value }`);
					break;
				}
				default: {
					break;
				}
			}
		} catch (e) {
			throw new Error(e);
		}
	};

	renderPayoutRateBlockInOut = () => {
		const {
			translate,
			errors,
			formData,
			level,
			setInputsValues,
		} = this.props;

		const considerReferralsLinkedToLocked =
			formData.consider_ref_linked_to_locked;
		const disabled = this.disabled;
		return (
			<div className="new-partners-conditions__popup mb-20">
				{level.MULTI_LEVEL && (
					<div className="w-30 mr-35">
						<Radio
							className={ `magic-radio-switch magic-radio-switch--accept-reject-btns magic-radio-switch--payment-confirmation` }
							classNameText={ "`magic-switch__text`" }
							direction={ "row" }
							handleOnChange={ value =>
								setInputsValues(
									"consider_ref_linked_to_locked",
									value.value,
								)
							}
							isDisabled={ disabled }
							label={ translate(
								"new_partners_confition_allow_blocked_referrals",
							) }
							options={ [
								{ name: translate("symbols_remove_accept"), value: 1, },
								{ name: translate("symbols_remove_reject"), value: 2, },
							] }
							value={ considerReferralsLinkedToLocked }
							wrapperClassName={ "select__wrapper" }
						/>
					</div>
				)}
				<div className="w-30 mr-35">
					<Input
						disabled={ disabled }
						isRequired={ true }
						label={ translate("new_partners_condition_min_inout_reminder") }
						name="min_inout_reminder"
						placeholder={ translate(
							"new_partners_condition_min_inout_reminder_placeholder",
						) }
						value={ formData.min_inout_reminder }
						wrapperClassName={ setErrorClass(errors, "min_inout_reminder") }
						onChange={ this.handleOnChange("min_inout_reminder") }
						onValid={ value => onValidPercantages(value, 100) }
					/>
				</div>
				{level.SINGLE_LEVEL && (
					<div className="w-30 mr-35">
						<Input
							disabled={ disabled }
							isRequired={ true }
							label={ translate(
								"new_partners_condition_payout_rate_for_the_estimated_amount",
							) }
							name="payout_rate_for_the_estimated_amount"
							placeholder={ translate(
								"new_partners_condition_payout_rate_for_the_estimated_amount_placeholder",
							) }
							value={ formData.payout_rate_for_the_estimated_amount }
							wrapperClassName={ setErrorClass(
								errors,
								"payout_rate_for_the_estimated_amount",
							) }
							onChange={ this.handleOnChange(
								"payout_rate_for_the_estimated_amount",
							) }
							onValid={ value => onValidPercantages(value, 100) }
						/>
					</div>
				)}
			</div>
		);
	};
	render () {
		const { formData, } = this.props;

		return <>{formData && this.renderPayoutRateBlockInOut()}</>;
	}
}

export default withLocalize(PayoutRateBlockInOut);