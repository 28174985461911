import React from "react";

import { Translate } from "react-localize-redux";
import { isUndefined } from "lodash";
import {getAvatar} from "../../services/TaskHelperService";

class InitiatorBlock extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            images: {},
            name:   '',
            role:   this.props.ROLE_1,
        };
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        getAvatar(this, nextProps.getInitiator?.['id'], nextProps.usersAllAvatar);
        if (!isUndefined(nextProps?.get_enums_tasks?.TaskRoleEnum) && nextProps?.get_enums_tasks?.TaskRoleEnum?.[this.props.ROLE_2] !== this.state.name) {
            this.setState({
                name: nextProps?.get_enums_tasks?.TaskRoleEnum?.[this.props.ROLE_2]
            });
        }
    }

    render () {
        const task_users = this.props.getCreator;

        return (

            <Translate>
                {({ translate }) => {
                    return (
                        <React.Fragment>
                            <div className = 'lead-block'>
                                <div className = 'user-image'>
                                    {
                                        this.state.images.imageId
                                            ? <img alt = { translate(`tasks_filter_creator`) } src = { this.state.images.imageId } />
                                            : ''
                                    }
                                </div>
                                <div className = 'user-info'>
                                    <div>{ translate(`tasks_filter_creator`) }</div>
                                    <div className = 'user-name active'>
                                        { task_users.name || task_users.surname ?
                                            `${task_users.name ?? ''} ${task_users.surname ?? ''}`
                                            : null
                                        }
                                    </div>
                                    {/* <div className = 'user-action'>
                                        <span
                                            onClick = { () => {
                                                this.props.toggleModal(this.state.role);
                                                this.props.getAllManager();
                                            } }><img alt = 'person' src = { person } style={{width: '10px'}}/>
                                            { task_users ? translate(`tasks_change_initiator`) : translate('tasks_initiator_add')}
                                        </span>
                                    </div> */}
                                </div>
                            </div>
                        </React.Fragment>
                    );
                }}
            </Translate>
        );
    }
}

export default InitiatorBlock;