import React, { Component } from 'react';
import { withLocalize } from "react-localize-redux";
import '../styles/list_accounts.scss';
import '../styles/corporate_action.scss';
import { Link } from "react-router-dom";
import { LIST_ACCOUNTS_SYSTEMS, LIST_ACCOUNTS_TRADINGS, LIST_ACCOUNTS_CORPORATE_ACTION, LIST_ACCOUNTS_BALANCE_OPERATIONS, } from '../consts/constants';
import PermissionService from '../../../services/PermissionService'

class ListAccounts extends Component {
    get config () {
        return [
            {
                path: ['core', 'system_accounts'],
                key:  'systemAccounts',
            },
            {
                path: ['core', 'trading_accounts'],
                key:  'tradingAccounts',
            },            {
                path: ['core', 'system_accounts_transaction_history'],
                key:  'transactionHistory',
            },
            {
                path: ['corporateaction', 'corporate_action'],
                key:  'corporateAction',
            }
        ];
    }

    render () {
        const { translate } = this.props;
        const pm = PermissionService.calc(this.config);

        return (
            <div className='list_accounts'>

                <div className='content-block'>
                    <div className='top-page-block'>
                        <h1 className='page-title'>{translate(`list_accounts`)}</h1>
                    </div>
                </div>

                <div className='content-block'>
                    <div className='table-wrapper'>
                        <div className='position-relative'>

                            {pm?.systemAccounts?.access('index') ? (
                                <Link to={LIST_ACCOUNTS_SYSTEMS}>
                                    <div className="list_accounts-system_link">
                                        <h3>{translate(`list_accounts_system_accounts`)}</h3>
                                    </div>
                                </Link>
                            ) : null}

                            {pm?.tradingAccounts?.access('index') ? (
                                <Link to={LIST_ACCOUNTS_TRADINGS}>
                                    <div className="list_accounts-trading_link">
                                        <h3>{translate(`list_accounts_trading_accounts`)}</h3>
                                    </div>
                                </Link>
                            ) : null}

                            {pm?.corporateAction?.access('index') ? (
                                <Link to={LIST_ACCOUNTS_CORPORATE_ACTION}>
                                    <div className="list_accounts-corporate-action_link">
                                        <h3>{translate(`list_accounts_corporate_action`)}</h3>
                                    </div>
                                </Link>
                            ) : null}

                            {pm?.transactionHistory?.access('index') ? (
                                <Link to={LIST_ACCOUNTS_BALANCE_OPERATIONS}>
                                    <div className="list_accounts_balance-operations_link">
                                        <h3>{translate(`balance_operations`)}</h3>
                                    </div>
                                </Link>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withLocalize(ListAccounts);
