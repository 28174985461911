export const topNavLang = {
	"topNav_position": [ "Personal Manager", "Персональный менеджер", "Персональний менеджер", ],
	"topNav_my_account": [ "My profile", "Мой профиль", "Мій профіль", ],
	"topNav_faq": [ "FAQs", "Вопросы и ответы", "Поширені запитання", ],
	"topNav_logout": [ "Log out", "Выйти", "Вийти", ],
  // "topNav_search":["Search and press enter...","Поиск и нажмите ввод...","Знайдіть і натисніть enter..."],
	"topNav_search": [ "Search", "Поиск", "Пошук", ],
	"topNav_search_button": [ "Search", "Искать", "Шукати", ],
	"topNav_navigation": [ "Navigation", "Навигация", "Навігація", ],
	"topNav_submit": [ "Submit", "Отправить", "Надіслати", ],

	"new_notifications": [ "New notifications", "Новые уведомления", "Нові повідомлення ", ],
	"no_notifications": [ "No notifications", "Нет уведомлений", "Немає повідомленнь", ],
	"seen_notifications": [ "Seen notifications", "Просмотренные уведомления", "Переглянуті повідомлення", ],
	"see_all_notifications": [ "See all notifications", "Просмотреть все уведомление", "Переглянути всі повідомлення", ],
};