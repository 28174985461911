import React from 'react';
import {withLocalize, Translate} from "react-localize-redux";

const TEST_OPTIONS = [
    { id: 'today', text: 'sideBar_today' },
    { id: 'tomorrow', text: 'sideBar_tomorrow' },
    { id: 'three_days', text: 'sideBar_three_days' },
    { id: 'week', text: 'sideBar_week' }
];

class Select extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            value: TEST_OPTIONS[0],
        };
    }

    render () {
        return (
            <SelectComponent
                options = { TEST_OPTIONS }
                value = { this.state.value }
                onChange = {
                    (value) => {
                        this.setState({ value });
                        this.props.getTenTasks(value.id);
                    }
                }

            />
        );
    }
}

class SelectComponent extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            isOpen: false,
        };

        this.handleDropdownClick = this.handleDropdownClick.bind(this);
        this.renderBack = this.renderBack.bind(this);
    }

    handleDropdownClick () {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    renderBack() {
        if (this.state.isOpen) {
            return(
                <div onClick={this.handleDropdownClick} style={{width: '100%', height: '100%', position:'fixed', top:0, left: 0, zIndex:9}}></div>
            )
        }
    }

    render () {
        let cls = 'select';
        let cls_per = ' ';

        if (this.state.isOpen) {
            cls += ' _active';
            cls_per += 'select_per';
        }

        return (
            <Translate>
                {({ translate }) => {
                    return (
                        <div className = { `select_component ${cls_per}` }>
                            {this.renderBack()}
                            <div
                                className = { cls }
                                onClick = { this.handleDropdownClick }>
                                <div className = 'select-selected'>{translate(`${this.props.value.text}`)}</div>

                                {this.state.isOpen && <div className = 'select-dropdown'>

                                    {this.props.options.map((option, index) => {

                                        return (<SelectOption
                                            key = { index }
                                            text = {translate(`${option.text}`)}
                                            onClick = { (e) => {
                                                this.props.onChange(option);
                                                this.setState({ isOpen: false });
                                                e.stopPropagation();
                                            } }
                                        />);
                                    })}

                                </div>}
                            </div>
                        </div>
                    );
                }}
            </Translate>
        );
    }
}

class SelectOption extends React.Component {
    constructor (props) {
        super(props);

        this.state = {};
    }

    render () {
        return (
            <div
                className = 'select-option'
                onClick = { this.props.onClick }>
                {this.props.text}
            </div>
        );
    }
}

export default withLocalize(Select);
