import React from "react";
import Moment from 'moment';
import Select from 'react-select';
import { customStyles } from "../TableUsers/customStyles";

class DayScheduleSelect extends React.Component {
    state = {
        work: this.props.work,
        weekSchedule: this.props.weekSchedule,
        value: this.props.value
    };

    onChange = (event) => {
        let weekSchedule;

        if (event.type) {
            weekSchedule = {...this.state.weekSchedule, [this.props.day] : { 'is_work': 2}};
        } else {
            weekSchedule = {...this.state.weekSchedule, [this.props.day] : {...this.state.weekSchedule[this.props.day], [this.props.type] : event.value, 'is_work': 1}};
        }

        this.props.scheduleChange(JSON.stringify(weekSchedule))
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        if (this.props !== nextProps) {
            this.setState({
                work: nextProps.work,
                weekSchedule: nextProps.weekSchedule,
                value: nextProps.value,
            });
        }
    }

    createTimesValue = () => [ ...Array(24*12)
        .fill(Moment().startOf('day'))
        .map((T, i) => Moment(T).add((i+1) * 5, 'minute').format('HH:mm'))
        .map(t => ({ label: t, value: t, })),
        { value: '2', label: this.props?.translate?.('user_profile_weekend'), type: 'is_work' },
    ];

    render () {
        const selectValue = this.state.work === 1 ? { value: this.state.value, label: this.state.value } : { value: 2, label: this.props.translate('user_profile_weekend') };
        const selectClass = this.state.work === 1 ? 'form-select day-schedule' : 'form-select day-schedule weekend';

        return (
            <div className = { selectClass }>
                <Select
                    classNamePrefix = 'select'
                    defaultValue = { selectValue }
                    options = { this.createTimesValue() }
                    styles = { customStyles }
                    onChange = { this.onChange }
                    isDisabled={ this.props?.disabled }
                />
            </div>
        );
    }
}

export default DayScheduleSelect;
