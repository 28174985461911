import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withLocalize } from "react-localize-redux";
import Preloader from "../../../../components/LoadingHOC/Preloader";
import PagePanel from "../../../tima/components/Pages/PagePanel";
import Loader from "../../../tima/components/Loader";
import {
  MagicFilterPanels,
  MagicTable,
  Mentor,
} from "../../../../components/Magic";
import { listAccountService } from "../../../../services/ListAccountDataService";

class OpenOrdersMT5 extends Component {
  constructor (props) {
    super(props);

    this.state = {
      loaded:           false,
      openOrders: {
          data: [],
          hash: null,
      },
      pages:            {
        filter:   0,
        total:    0,
        take:     50,
        variance: 2,
      },
      filterLastChange: Date.now(),
    };

    const mf = new Mentor({
      owner: this,
      serviceId: 97,
      translate: this.props.translate,
    });

    Object.defineProperty(this, 'mf', { get: () => mf, });
    this.props.mf(mf);
  }

  // eslint-disable-next-line react/sort-comp, require-await
  save = async state => new Promise(next => this.setState(state, next));

  // eslint-disable-next-line require-await
  mfChanged = async () => {
    await this.save({ filterLastChange: Date.now(), });
  };

  mfDoing = async () => {
    await this.save({ loaded: false, });
  };

  mfDone = async () => {
    await this.mfChanged();
    await this.onDataLoad();
  };

  query = () => new URLSearchParams(this.props?.location?.search);

  openOrdersChanges = async (openOrders) => {
      if (openOrders.hash===this.state?.openOrders?.hash) return `${this.constructor.name}.openOrdersChanges: false`;
      const { data, meta, hash } = openOrders;
      await this.save({
          openOrders: { data, hash },
          pages: {
              ...this.state.pages,
              filter: meta?.filter,
              total: meta?.total,
          }
      });
      return `${this.constructor.name}.openOrdersChanges: true`;
  };

  componentDidMount = async () => {
    this.mf.subscribe({
      changed: this.mfChanged,
      // changing: ,
      cleaned: this.mfDone,
      cleaning: this.mfDoing,
      loaded: this.mfDone,
      loading: this.mfDoing,
      saved: this.mfDone,
      saving: this.mfDoing,
    }, this);
    await this.mf.init({ doEmit: true, });
    listAccountService.subscribe('tradingAccountsOpenOrdersMT5', this.openOrdersChanges, this);
    // await this.mf.emit('change', {});
    // await this.onDataLoad(); // TODO: moved to mfDone
  };

  componentWillUnmount = () => {
    this.mf.unsubscribe([
      this.mfChanged,
      this.mfDoing,
      this.mfDone,
    ], this);
    listAccountService.unsubscribe('tradingAccountsOpenOrdersMT5', this.openOrdersChanges, this);
  };

  get openOrdersMT5Config () {
    const { translate, } = this.props;
    const prefix = 'list_accounts_table_ordersMT5_';

    return [
      {
        accessChecked: true,
        path:          ['orders', 'TimeSetup'],
        key:           'TimeSetup',
        render:        this.renderInlineValue,
        title:         `${prefix}TimeSetup`,
      },
      {
        accessChecked: true,
        path:          ['orders', 'Order'],
        key:           'Order',
        render:        this.renderInlineValue,
        title:         `${prefix}Order`,
      },
      {
        accessChecked: true,
        path:          ['orders', 'Symbol'],
        key:           'Symbol',
        render:        this.renderInlineValue,
        title:         `${prefix}Symbol`,
      },
      {
        accessChecked: true,
        path:          ['orders', 'Type'],
        key:           'Type',
        render:        this.renderEnumField('core', 'Mt5OrdersTypesEnum'),
        title:         `${prefix}Type`,
      },
      {
        accessChecked: true,
        path:          ['orders', 'Volume1'],
        key:           'Volume1',
      },
      {
        accessChecked: true,
        path:          ['orders', 'Volume2'],
        key:           'Volume2',
      },
      {
        accessChecked: true,
        key:           'volume',
        render:        this.renderNumberFixedFormat({ fieldName: 'volume' }),
        title:         `${prefix}volume`,
      },
      {
        accessChecked: true,
        path:          ['orders', 'PriceOrder'],
        key:           'PriceOrder',
        render:        this.renderNumberFixedFormat({ fieldName: 'PriceOrder' }),
        title:         `${prefix}PriceOrder`,
      },
      {
        accessChecked: true,
        path:          ['orders', 'PriceSL'],
        key:           'PriceSL',
        render:        this.renderNumberFixedFormat({ fieldName: 'PriceSL' }),
        title:         `${prefix}PriceSL`,
      },
      {
        accessChecked: true,
        path:          ['orders', 'PriceTP'],
        key:           'PriceTP',
        render:        this.renderNumberFixedFormat({ fieldName: 'PriceTP' }),
        title:         `${prefix}PriceTP`,
      },
      {
        accessChecked: true,
        path:          ['orders', 'PriceCurrent'],
        key:           'PriceCurrent',
        render:        this.renderNumberFixedFormat({ fieldName: 'PriceCurrent' }),
        title:         `${prefix}PriceCurrent`,
      },
      {
        accessChecked: true,
        path:          ['orders', 'State'],
        key:           'State',
        render:        this.renderEnumField('core', 'Mt5OrdersStateEnum'),
        title:         `${prefix}State`,
      },
      {
        accessChecked: true,
        path:          ['orders', 'Comment'],
        key:           'Comment',
        render:        this.renderInlineValue,
        title:         `${prefix}Comment`,
      },

      {
        accessChecked: true,
        path:          ['orders', 'Digits'],
        key:           'Digits',
      },

    ].map(({ title: t, xtitle: x, ...item }) => ({ ...item, ...x && { title: x }, ...t && { title: translate(t) }, }));
  }

  renderInlineValue = (value, { item, items }) => {
    const defaultValue = '-';

    if (item?.access?.('index')) {
      return value ?? defaultValue;
    }
    return defaultValue;
  };

  renderNumberFixedFormat = ({ fieldName }) => (value, { item, items }) => {
    const defaultDigits = 2;
    const defaultValue = '-';

    try {
      if (item?.access?.('index')) {
        switch (fieldName) {
          case 'volume': {
            const Digits = 2; // HARD CODE for this fields in MT4/MT5
            const Volume1 = items?.['Volume1']?.valueOf;
            const Volume2 = items?.['Volume2']?.valueOf;

            return `${(+Volume1).toFixed(+Digits)} / ${(+Volume2).toFixed(+Digits)}`;
          }
          case 'PriceOrder':
          case 'PriceSL':
          case 'PriceTP':
          case 'PriceCurrent': {
            const Digits = items?.['Digits']?.valueOf ?? defaultDigits;

            return (+value).toFixed(Digits);
          }
          default: {
            return (+value).toFixed(defaultDigits);
          }
        }
      }

      return defaultValue;
    } catch (error) {
      throw new Error(`Error renderNumberFixedFormat( fieldName: ${ fieldName } )`);
    }
  };

  renderEnumField = (path, enumName) => (value, { item }) => {
    const defaultValue = '-';

    if (item?.access?.('index')) {
      const enums = this.props?.enums?.[path]?.[enumName];
      return enums?.[value] ?? defaultValue;
    }
    return defaultValue;
  };

  render = () => {
    const { openOrders: { data }, } = this.state;
    const options = {
      config: this.openOrdersMT5Config,
      data,
      head:   [
        'TimeSetup',
        'Order',
        'Symbol',
        'Type',
        'volume',
        'PriceOrder',
        'PriceSL',
        'PriceTP',
        'PriceCurrent',
        'State',
        'Comment',
      ],
    };

    return (
      <>
        <MagicFilterPanels
          mf={ this.mf }
          show={ true }
          translate={ this.props.translate }
        />

        <div className='orders-page closed-orders-page'>
          <Loader
            loaded={this.state.loaded}
            loading={(<Preloader scale={this.props.scale}/>)}
            translate={this.props.translate}
          >
            <MagicTable {...options} />

            <PagePanel
              filter={this.state.pages.filter}
              take={this.state.pages.take}
              total={this.state.pages.total}
              variance={this.state.pages.variance}
              page={this.pageId()}
              onClick={this.onPageClick}
              doText={this.onPageText}
              doTo={(pageId) => `?page=${ pageId }`}
            />
          </Loader>
        </div>
      </>
    );
  };

  pageId = () => {
    const defaultPageId = 0;

    try {
      const page = this.query().get('page');

      return Number(page) || defaultPageId;
    } catch (e) {
      return defaultPageId;
    }
  };

  pageIdAsGET = (pageId) => {
    pageId = pageId === undefined ? this.pageId() : pageId;
    const { filterId, } = this.mf;
    const result = {
      filterId,
      take: this.state.pages.take,
      skip: pageId * this.state.pages.take,
    };
    return result;
  };

  onDataLoad = async (pageId) => {
    const loaded = (data, meta, hash) => ({ pages, }) => ({
        openOrders:{ data, hash },
        pages: { ...pages, ...meta, },
    });
    await this.save({ loaded: false, });
    try {
      const pageOptions = this.pageIdAsGET(pageId);
      const { id, } = this.props;
      const { data, meta, hash } = await listAccountService.tradingAccountsOpenOrdersMT5(id, pageOptions);
      const { filter, total } = meta;
      await this.save(loaded(data, { filter, total, }, hash));  // no filter data on this page => filter: total - это была временная заплатка
    } catch (error) {
      await this.save(loaded([], { filter: 0, total: 0, }, null));
      error?.showErrorNotification?.();
    }
    await this.save({ loaded: true, });
  };

  onPageClick = ({ event, pageId, pageIs, }) => {
    // event.preventDefault();
    this.onDataLoad(pageId);
  };

  onPageText = (pageId, pageIs) => {
    const { translate } = this.props;
    const { current, first, prev, next, last, } = pageIs;
    const { skipped, taken, filter, total, } = pageIs;
    if (skipped || taken) {
      const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : '?';
      const text = skipped ? translate('partners_pages_items') : '';
      return skipped ? `${ text }: ${ id }` : id;
    } else if (filter || total) {
      const id = Number.isInteger(pageId) ? `${ pageId }` : '?';
      const text = translate(filter ? 'partners_pages_filtered' : 'partners_pages_total');
      return `${ text }: ${ id }`;
    } else if (first || prev || next || last) {
      return '';
    } else if (current) {
      return `${ pageId + 1}`;
    } else {
      return `${ pageId + 1}`;
    }
  };
}

export default withRouter(withLocalize(OpenOrdersMT5));
