export const PARTNERS_REQUESTS_EXPORT_FIELDS = {
	requests: [
		"core-account-id",
		"core-account-name",
		"core-account-surname",
		"core-user-name",
		"partners-requests-created_at",
		"partners-programs-name",
		"partners-requests-status",
		"partners-requests-approved_date",
		"partners-requests-reason",
	],
};