import React                           from 'react';
import { withLocalize }                from 'react-localize-redux';
import { MagicButton }                 from '../../../../../../components/Magic';
import { updateAccountDescription, }   from "../../../../services/AccountRequestService";
import PropTypes                       from 'prop-types';

class Comment extends React.Component {
   static defaultProps = {
      items: {},
      deleteComments: [],
      refreshData: () => {},
      translate: () => {},
      userId: 0,
      maxMessageLength: 0,
   };

   static propTypes = {
      items: PropTypes.object,
      deleteComments: PropTypes.array,
      refreshData: PropTypes.func,
      translate: PropTypes.func,
      userId: PropTypes.number,
      maxMessageLength: PropTypes.number,
   };

   constructor(props) {
      super(props);
      this.state = {
         isEdit: false,
         isDisable: false,
         editText: "",
         maxMessageLength: this.props?.maxMessageLength,
         error: false,
      }
   }

   get commentId() { return this.props?.items?.renderItem ?? {}; };

   get commentText() { return this.props?.items?.commentText ?? {}; };

   get commentCreatedAt() { return this.props?.items?.commentCreatedAt ?? {}; };

   get commentStatus() { return this.props?.items?.commentStatus ?? {}; };

   get commentAuthorId() { return this.props?.items?.commentAuthorId };

   get isDelete() { return this.props?.deleteComments?.includes?.(this.commentId?.valueOf) };

   get isAuthor() { return this.commentAuthorId?.valueOf === this.props?.userId };

   updateDescription = async (data) => {
        try {
            await this.save({ isDisable: true, error: false, });
            await updateAccountDescription(this.commentId?.valueOf, data);
            await this.props.refreshData();
            await this.cancelEditMode();
        } catch (error) {
            await this.save({ error: true, });
            error?.showErrorNotification?.();
        } finally {
            await this.save({ isDisable: false, });
        }
   };

   save = async (state) => new Promise(next => this.setState(state, next));

   setEditMode = async () => await this.save({ isEdit: true, editText: this.commentText?.valueOf, });

   cancelEditMode = async () => await this.save({ isEdit: false, editText: "", error: false, });

   renderBlockText = () => {
      const renderBlockShowMore = () => {
         if(this.commentText?.access('index')) {
            const options = {
               className: "comment__read-more",
               value: "",
               onClick: () => this.props.filterDeleteComments(this.commentId?.valueOf),
               children: this.props?.translate('account_comments_customer_read-more'),
               disabled: this.state.isDisable,
            }
            return (
               <React.Fragment>
                  <span className="comment__deleted">{ this.props?.translate('account_comments_customer_deleted') }</span>
                  {/* <MagicButton {...options} /> */}
               </React.Fragment>)
         }
         return "";
      };

      const renderEditField = () => {
         if(this.commentText?.access('update') && this.isAuthor) {
            const options = {
               className: 'scroll',
               value: this.state.editText ?? "",
               onChange: async (e) => await this.save({ editText: e.target.value, }),
               placeholder: this.props?.translate('account_comments_customer_create_ph'),
               disabled: this.state.isDisable,
            };

            return (
               <React.Fragment>
                  <div className="comment__counter">{ `${ this.state.editText?.trim()?.length }/${ this.state?.maxMessageLength }` }</div>
                  <textarea {...options} />
               </React.Fragment>
            );
         }
         return "";
      };

      const renderText = () => {
         if(this.commentText?.access('index')) {
            return (
               <div
                  className="comment__description"
                  dangerouslySetInnerHTML={{ __html: this.commentText?.valueOf?.replace?.(/\r?\n/g,'<br/>') }}
               />);
         }
         return "";
      };

      const renderBlockDescription = () => {
         if(this.commentText?.access('index')) {
            const { isEdit, } = this.state;

            return ( isEdit ?  renderEditField() : renderText());
         }
         return "";
      };

      return this.isDelete ? renderBlockShowMore() : renderBlockDescription();
   };

   renderBlockSignature = () => {
      if(this.commentAuthorId?.access('index')) {
         const { items } = this.props;
         const authorFullName = `${ items?.commentAuthorName?.valueOf ?? "" } ${ items?.commentAuthorSurname?.valueOf ?? "" }`
         return (
             <div className="comment__signature">
                 <div className="signature__date">{ this.commentCreatedAt?.valueOf ?? ""}</div>
                 <div className="signature__author">{ authorFullName }</div>
             </div>
         )
      }
      return ""
   };

   renderBtns = () => {
      const { isDisable, isEdit, editText: description, } = this.state;

      if (this.props?.items?.renderItem?.access('update') && this.isAuthor) {
         const cancel = () => {
            const options = {
               className: "magic-button__item magic-button__item_comment-cancel",
               value: "",
               onClick: async () => await this.cancelEditMode(),
               children: this.props?.translate('account_comments_customer_cancel'),
               disabled: isDisable,
           }

           return (isEdit && <MagicButton { ...options } />);
         };

         const edit = () => {
            const options = {
               className: "magic-button__item magic-button__item_comment-edit",
               value: "",
               onClick: isEdit
                  ? async () => await this.updateDescription({ description })
                  : async () => await this.setEditMode(),
               children: this.props?.translate(`account_comments_customer_button_${ isEdit ? 'save' : 'edit' }`),
               disabled: isDisable,
            };

            return (<MagicButton { ...options } /> );
         };

         const remove = () => {
            const options = {
               className: "magic-button__item magic-button__item_comment-remove",
               value: "",
               onClick: () => this.updateDescription({ status: 2 }),
               children: this.props?.translate('account_comments_customer_delete'),
               disabled: isDisable,
           }

           return (<MagicButton { ...options } /> );
         };

         const restore = () => {
            const options = {
               value: "",
               className: "magic-button__item magic-button__item_comment-restore",
               onClick: () => this.updateDescription({ status: 1 }),
               children: this.props?.translate('account_comments_customer_restore'),
               disabled: isDisable,
            };

         return (<MagicButton { ...options } /> );
         };

         return (
            <div className="comment__buttons">
               { (this.isDelete || ["2", 2].includes(this.commentStatus?.valueOf))
                  ? restore()
                  : ( <React.Fragment>
                        { cancel() }
                        { edit() }
                        { remove() }
                     </React.Fragment>)
               }
            </div>
         )
      }

   };

   renderComment = () => {
      const classes = () => {
         let cls = [];
         const deletedComment = this.isDelete || ["2", 2].includes(this.commentStatus?.valueOf);
         const error = this.state.error;
         return (deletedComment || error) ? cls = [...cls, 'error'] : cls;
      }
      return (
         <div className={`comment__wrapper ${ classes() }`}>
            { this.renderBlockText() }
            { this.renderBlockSignature() }
            {/* { this.renderBtns() } */}
         </div>
      )
   };

   render = () => this.renderComment();
}

export default withLocalize(Comment);