import React from "react";
import { Link } from "react-router-dom";
import ToolbarPanelText from './ToolbarPanelText';
import './styles.scss';

export default function ToolbarPanelLink ( props ) {
    const { link, active, no, class4Link, class4LinkActive, } = props;
    const classNames = [
        class4LinkActive || `toolbar-panel__link_active`,
        class4Link || `toolbar-panel__link`,
    ];
    return (
        <Link
            className={ classNames.slice( +!active ).concat( no ? [`toolbar-panel__link_no`] : [] ).join(' ') }
            to={ link }
        >
            <ToolbarPanelText { ...props } />
        </Link>
    );
}
