import * as Loc from 'react-localize-redux';

import DataStorage from '../helpers/DataStorage';
import {allLang} from "../lang";
import { renderToStaticMarkup } from "react-dom/server";
import React from "react";
import { enumService } from "./EnumDataService";
import AuthService from "./AuthService";

export const LANG_UA = 'uk';
export const LANG_EN = 'en';
export const LANG_RU = 'ru';

export const LANG_PROP_CODE = 'code';
export const LANG_PROP_SHORT_NAME = 'short_name';
export const LANG_PROP_FULL_NAME = 'full_name';

const LANG_DATA = {
    [LANG_PROP_SHORT_NAME]: {
        [LANG_UA]: 'uk',
        [LANG_EN]: 'en',
        [LANG_RU]: 'ru',
    },
    [LANG_PROP_FULL_NAME]: {
        [LANG_UA]: 'Українська',
        [LANG_EN]: 'English',
        [LANG_RU]: 'Русский',
    },
};

const LangService = (() => {

    const languages = [LANG_EN, LANG_UA, LANG_RU];
    let _store = null;
    const setLocaleLanguageToStorage = (code) => DataStorage.setData('locale', code);
    const getLocaleLanguageFromStorage = () => DataStorage.getData('locale');

    return {
        init (store) {
            _store = store;

            _store.dispatch(Loc.initialize({
                languages: [
                    { name: <div><span className="flag-icon flag-icon-squared flag-icon-gb"></span> English</div>, code: 'en' },
                    { name: <div><span className="flag-icon flag-icon-squared flag-icon-ru"></span> Русский</div>, code: 'ru' },
                    { name: <div><span className="flag-icon flag-icon-squared flag-icon-ua"></span> Українська</div>, code: 'uk' }
                ],
                translation: {},
                options:     { renderToStaticMarkup },
            }));

            this.addTranslation( allLang );

            if (getLocaleLanguageFromStorage()) {
                this.setActiveLanguage();
            }
        },

        get languages () {
            return languages;
        },

        async setActiveLanguage (code) {
            if (!code) {
                const storageLanguage = getLocaleLanguageFromStorage();
                await setLocaleLanguageToStorage(storageLanguage ?? 'en');
                _store.dispatch(Loc.setActiveLanguage(storageLanguage ?? 'en'));
            } else {
                await setLocaleLanguageToStorage(code);
                _store.dispatch(Loc.setActiveLanguage(code));
            }
            AuthService.getSessionToken() ? await enumService.enums : null;
        },

        getActiveLanguage (field) {
            const code = languages.indexOf(getLocaleLanguageFromStorage()) >=0 &&
                getLocaleLanguageFromStorage()

                || this.getNavigatorLanguage()
                || LANG_EN;

            if (field === LANG_PROP_CODE) {
                return code;
            }

            if (field === undefined) {
                return {
                    [LANG_PROP_CODE]:       code,
                    [LANG_PROP_SHORT_NAME]: LANG_DATA.short_name[code],
                    [LANG_PROP_FULL_NAME]:  LANG_DATA.full_name[code],
                };
            }

            if (LANG_DATA[field] === undefined) {
                return null;
            }

            return LANG_DATA[field][code];
        },

        getNavigatorLanguage () {
            const userLang = (navigator.languages &&
                    navigator.languages.length &&
                    navigator.languages[0]

                || navigator.userLanguage
                || navigator.language
                || '').toLowerCase().substr(0, 2);

            return languages.indexOf(userLang) >=0 && userLang || '';
        },

        addTranslation (localeData) {
            _store.dispatch(Loc.addTranslation(localeData));
        },

        get translate () {
            return Loc.getTranslate(_store.getState().locale);
        },

    };
})();

export default Loc.withLocalize(LangService);
