// field names full path
export const REBATE_EXPORT_FIELDS = {
	rebate: [
		"core-account-id",
		"core-account-name",
		"core-account-surname",
		"partners-rebate_projects-id",
		"partners-rebate_projects-name",
		"partners-rebate_projects-condition",
		"partners-rebate_projects-status",
		"partners-rebate_projects-rate",
		"partners-rebate_projects-created_at",
		"partners-rebate_projects-updated_at",
	],
};