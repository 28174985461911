import React, {lazy} from 'react';

import PermissionService, { MODULE_KEYS as pm } from "../../../services/PermissionService";

import UserParentComponent from "./components/ManagerPage/UserParentComponent";

import ManagerNotification from "./components/ManagerPage/ManagerNotification";

const Users = lazy(() => import('./components/Users'));

export const usersConfig = [
    { permission: pm.CORE_USER, action: PermissionService.storeRoute(), component: <UserParentComponent />, path: '/settings/userCreate' },
    { permission: pm.CORE_USER, action: PermissionService.showRoute(), component: <UserParentComponent />, path: '/settings/user/:id' },

    { permission: null, component: <ManagerNotification />, path: '/settings/profile/notifications' },
    { permission: null, component: <UserParentComponent />, path: '/settings/profile' },
    { permission: pm.CORE_USER, component: <Users />, path: '/settings' },
    // { permission: pm.CORE_USER, action: PermissionService.indexRoute(), component: <Users />, path: '/settings/users' },
];
