/* eslint-disable react/no-multi-comp, sort-keys */

import React, { memo, } from "react";
import PropTypes from "prop-types";
import { withLocalize, } from "react-localize-redux";
import ReactSelect, { components, } from "react-select";
import "./styles.scss";

const Select = ({
	name,
	value,
	hideLabel = false,
	placeholder = "",
	onChange = () => { },
	onChangeCallback = false,
	label = "label",
	options = [],
	isSearchable = false,
	className = "",
	disabled = false,
	translate = () => {},
	isRequired = false,
	wrapperClassName = "",
}) => {
	const NoOptionsMessage = (props) => {
		return (
			<components.NoOptionsMessage { ...props }>
				<span>{translate("partners_select_no_options")}</span>
			</components.NoOptionsMessage>
		);
	};

	const customStyle = {
		container: base => ({
			...base,
			borderRadius: "25px",
		}),
		control: (base, state) => ({
			...base,
			backgroundColor: state.isDisabled ? "#e6e6e6" : "#ffffff",
			borderRadius: "25px",
			border: state.isFocused
				? "2px solid rgba(0, 0, 0, 0.2)"
				: "2px solid rgba(0, 0, 0, 0)",
			boxShadow: "0 6px 25px 0 #ebebeb",
			color: "#00000",
			fontSize: "12px",
			fontWeight: "500",
			lineHeight: "1.43",
			padding: "5px",
			zIndex: "1",
			"&:hover": {
				border: state.isFocused
					? "2px solid rgba(0, 0, 0, 0.2)"
					: "2px solid rgba(0, 0, 0, 0)",
			},
		}),
		menu: provided => ({
			...provided,
			zIndex: "2",
			overflow: "hidden",
		}),

		menuList: provided => ({
			...provided,
			maxHeight: "160px",
		}),

		singleValue: (provided, state) => ({
			...provided,
			color: state.isDisabled ? "#a2a2a2" : "#000000",
		}),

		dropdownIndicator: (provided, state) => ({
			...provided,
			transform: state.selectProps.menuIsOpen
				? "rotate(0deg)"
				: "rotate(-90deg)",
		}),
	};

	return (
		<div className={ `select__wrapper ${ wrapperClassName }` }>
			<label className={ hideLabel ? 'hidden' : `select__label` }>{isRequired ? `${ label }*` : label}</label>
			<ReactSelect
				className={ `select__field ${ className }` }
				components={ { IndicatorSeparator: () => null, NoOptionsMessage, } }
				isDisabled={ disabled }
				isSearchable={ isSearchable }
				options={ options }
				placeholder={ placeholder }
				styles={ customStyle }
				value={ Object.keys(value).length ?
					value :
					{ label: placeholder || translate("partners_not_select"), value: null, } }
				onChange={ (event) => {
					if (event.value !== value.value) {
						onChange({ target: { name, value: event, }, });
						onChangeCallback &&
						onChangeCallback({ target: { name, value: event, }, });
					}
				} }
			/>
		</div>
	);
};

Select.propTypes = {
	onChange: PropTypes.func.isRequired,
	defaultLabel: PropTypes.string,
	disabled: PropTypes.oneOfType([ PropTypes.bool, PropTypes.number, ]),
	isRequired: PropTypes.bool,
	isSearchable: PropTypes.bool,
	label: PropTypes.string,
	name: PropTypes.string,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			type: PropTypes.number,
			value: PropTypes.number.isRequired,
		}),
	),
	placeholder: PropTypes.string,
	value: PropTypes.object,
	onChangeCallback: PropTypes.oneOfType([ PropTypes.bool, PropTypes.func, ]),
};

export default withLocalize(memo(Select));