import React, { Component, } from "react";
import PropTypes from "prop-types";
import { withLocalize, } from "react-localize-redux";

import TargetPageLanguageList from "./components/TargetPageLanguageList";
import TargetPageNameList from "./components/TargetPageNameList";
import { Input, Popup, Select, } from "../../../../components";

import {
	updateTargetPage,
	createTargetPage,
	updateTargetPageLanguage,
	createTargetPageLanguage,
	updateTargetPageName,
	createTargetPageName,
	getSystemLanguages,
	deleteTargetPageLanguage,
	deleteTargetPageName,
} from "../../../../../../services/PartnersRequestService";

import { validateCreatePromoFields, } from "../../helpers/validation-helpers";
import { findOptionByValue, setErrorClass, } from "../../../../utils/helpers";
import { TARGET_PAGE_STATUSES, TARGET_PAGE_LANGUAGE_STATUSES, } from "../consts/TARGET_PAGE_STATUSES";

class CreateUpdateTargetPage extends Component {
	static propTypes = {
		onClose: PropTypes.func.isRequired,
		// eslint-disable-next-line sort-keys
		enums: PropTypes.object,
		popupData: PropTypes.object,
		onDataLoad: PropTypes.func,
	};

	static defaultProps = {
		popupData: {},
	};

	constructor (props) {
		super(props);

		this.state = {
			languageOptions: [],

			errors: [],
			languageFormErrors: [],
			nameFormErrors: [],

			previousFormData: { //prevents requests with no change
				name: '',
				link: '',
				status: {},
			},

			formData: {
				name: '',
				link: '',
				status: {},
			},

			languageFormList: [
				// {
				// language_id: {},
				// link: '',
				// status: {},
				// },
			],

			nameFormList: [
				// {
				// name: '',
				// language_id: {},
				// },
			],
		};
	}

	get targetPageExistInDB () {
		return !!this.state.formData.id; //check id because it's created in DB
	}
	get isEditMode () {
		return !!Object.keys(this.props.popupData).length;
	}

	get isViewMode () {
		return this.props.popupData?.isViewMode;
	}

	get statusesOptions () {
		const { enums, } = this.props;

		return Object.values(enums.data.partners.TargetPageStatusEnum).map((status, index) => {
			return {
				label: status,
				value: index,
			};
		});
	}

	get languageStatusesOptions () {
		const { enums, } = this.props;

		return Object.values(enums.data.partners.TargetPageLanguageStatusEnum).map((status, index) => {
			return {
				label: status,
				value: index,
			};
		});
	}

	get formIsUpdated () {
		const currentFormData = JSON.stringify(this.state.formData);
		const previousFormData = JSON.stringify(this.state.previousFormData);

		return currentFormData !== previousFormData;
	}
	async componentDidMount () {
		try {
			this.toggleLoaded(false);

			await this.getInitialData();

			if (this.isEditMode) {
				await this.getUpdateData();
			}
			this.toggleLoaded(true);
		} catch (error) {
			this.toggleLoaded(true);
			console.log(error);
			error.showErrorNotification?.();
		}
	}

	save = async state => new Promise(next => this.setState(state, next));

	toggleLoaded = loadData => this.save({ loadData, });

	getInitialData = async () => {
		const { formData, } = this.state;

		const languages = await getSystemLanguages();
		const languageOptions = languages.data.map((language) => {
			const { core, } = language;

			return {
				label: core.language.name_en,
				value: core.language.id,
			};
		});

		const status = findOptionByValue(this.statusesOptions, TARGET_PAGE_STATUSES.AVAILABLE);

		await this.save({
			languageOptions,
			formData: { ...formData, status, },
			previousFormData: { ...formData, status, },
		});
	}

	resetCurrentFormData = async () => {
		const { previousFormData, } = this.state;

		await this.save({ formData: previousFormData, });
	}

	getEmptyLanguageFormItem = () => {
		const languageStatus = findOptionByValue(this.languageStatusesOptions, TARGET_PAGE_LANGUAGE_STATUSES.AVAILABLE);

		return {
			language_id: {},
			link: '',
			status: languageStatus,
		};
	}
	getEmptyNameFormItem = () => {
		return {
			language_id: {},
			name: '',
		};
	}
	getUpdateData = async () => {
		const {
			formData, languageFormList, nameFormList,
		} = this.props.popupData;
		const {
			name, status, link, id,
		} = formData;

		const { languageOptions, } = this.state;
		const statusOptions = this.statusesOptions;
		const languageStatusesOptions = this.languageStatusesOptions;

		const languageFormListWithSelectValues = languageFormList?.map((formDataItem) => {
			const {
				language_id, link, status, id,
			} = formDataItem;

			return {
				language_id: findOptionByValue(languageOptions, language_id),
				link,
				status: findOptionByValue(languageStatusesOptions, status),
				id,
			};
		});

		const nameFormListWithSelectValues = nameFormList?.map((formDataItem) => {
			const {
				language_id, name, id,
			} = formDataItem;

			return {
				language_id: findOptionByValue(languageOptions, language_id),
				name,
				id,
			};
		});

		const formDataFromProps =  { name, link, status: findOptionByValue(statusOptions, status), id,};

		await this.save({
			previousFormData: formDataFromProps,
			formData: formDataFromProps,

			...languageFormListWithSelectValues?.length && { languageFormList: languageFormListWithSelectValues, },
			...nameFormListWithSelectValues?.length && { nameFormList: nameFormListWithSelectValues, },
		});
	}

	getSendData = (formData, additionalEntries = {}) => {
		const sendData = Object.entries(formData).reduce((accumulator, entry) => {
			const [ key, value, ] = entry;

			if (key === 'status' || key === 'language_id') { //select values
				return { ...accumulator, [key]: value.value, };
			}

			if (key === 'id') {
				//don't need to send id
				return { ...accumulator, };
			}

			return { ...accumulator, [key]: value, };
		}, {});

		return { ...sendData, ...additionalEntries, };
	};

	saveData = async () => {
		try {
			const {
				formData,
			} = this.state;
			const { id, } = formData;
			const fieldsAreValid = await validateCreatePromoFields(formData, this.save);

			if (!fieldsAreValid || !this.formIsUpdated) {
				return false;
			}

			await this.toggleLoaded(false);
			const formSendData = this.getSendData(formData);
			const response = this.isEditMode ? await updateTargetPage(id, formSendData) : await createTargetPage(formSendData);

			const initialNameFormList = [ this.getEmptyNameFormItem(), ];
			const initialLanguageFormList = [ this.getEmptyLanguageFormItem(), ];

			//preset select values
			response.status = findOptionByValue(this.statusesOptions, response.status);
			this.save({
				previousFormData: response,
				formData: response,

				...!this.isEditMode && { nameFormList: initialNameFormList, languageFormList: initialLanguageFormList, },
			});

			await this.toggleLoaded(true);
		} catch (error) {
			await this.toggleLoaded(true);
			console.log(error);
			error.showErrorNotification?.();
		}
	}

	handleOnChange = async (event) => {
		const { name, value, } = event.target;

		await this.save(state => ({
			...state,
			errors: state.errors.filter(item => item !== name),
			formData: {
				...state.formData,
				[name]: value,
			},
		}));
	};
	renderTitle = () => {
		const { translate, } = this.props;

		switch (true) {
			case this.isViewMode: {
				return translate("partner_target_page");
			}
			case this.isEditMode: {
				return translate("partner_target_page_update");
			}
			default: {
				return translate("partner_target_page_create");
			}
		}

	};

	renderTargetPageForm = () => {
		const { translate, } = this.props;
		const {
			formData, errors,
		} = this.state;

		const {
			link, status, name,
		} = formData;

		return (
			<div className="promo-popup__block promo-popup__group">
				<div className="promo-popup__row">
					<Input
						disabled={ this.isViewMode }
						label={ translate(`partner_target_page_name_required`) }
						name={ 'name' }
						placeholder={ translate(`partners_create_symbol_name_placeholder`) }
						value={ name }
						wrapperClassName={ setErrorClass(errors, 'name') }
						onChange={ e => this.handleOnChange(e) }
						onValid={ (value) => {
							if (value.length > 60) {
								throw new Error('XXX');
							}
						} }
					/>
				</div>
				<div className="promo-popup__row">
					<Input
						disabled={ this.isViewMode }
						isRequired={ true }
						label={ translate(`partner_target_page_link`) }
						name={ 'link' }
						placeholder={ translate(`partners_target_page_link_placeholder`) }
						value={ link }
						wrapperClassName={ setErrorClass(errors, 'link') }
						onChange={ e => this.handleOnChange(e) }
						// onValid={ (value) => {
						// 	const regExp = /^[a-zA-Zа-яА-ЯіІїЇєЄґҐёЁ0-9._?=&/:\- ]{1,200}$/;
						//
						// 	if (!regExp.test(value) && value !== '') {
						// 		throw new Error('XXX');
						// 	}
						// } }
					/>
				</div>
				<div className="promo-popup__row">
					<Select
						className={ setErrorClass(errors, "status") }
						disabled={ this.isViewMode }
						isRequired={ true }
						label={ translate("partners_banners_status") }
						name="status"
						options={ this.statusesOptions }
						placeholder={ translate("partners_banners_status") }
						value={ status }
						onChange={ e => this.handleOnChange(e) }
					/>
				</div>
			</div>
		);
	}
	addTargetPageLanguage = async () => {
		const { languageFormList, } = this.state;
		const languageFormListItem = this.getEmptyLanguageFormItem();

		await this.save({
			languageFormList: [
				...languageFormList,
				languageFormListItem,
			],
		});
	}

	addTargetPageName = async () => {
		const { nameFormList, } = this.state;
		const nameFormListItem = this.getEmptyNameFormItem();

		await this.save({
			nameFormList: [
				...nameFormList,
				nameFormListItem,
			],
		});
	}

	createTargetPageName = async (formData) => {
		const response = await createTargetPageName({ ...formData, target_page_id: this.state.formData.id, });
		const newNameFormList = [ ...this.state.nameFormList, ];

		newNameFormList.pop(); //deletes blank item
		newNameFormList.push(response); //because we have individual states inside each item, we want to update parent state with new data from item;
		await this.save({ nameFormList: newNameFormList, });

		return response;
	}

	updateTargetPageName = async (id, formData, index) => {
		const response = await updateTargetPageName(id, { ...formData, target_page_id: this.state.formData.id, });
		const newNameFormList = [ ...this.state.nameFormList, ];

		newNameFormList[index] = response; //because we have individual states inside each item, we want to update parent state with new data from item;

		await this.save({ nameFormList: newNameFormList, });

		return response;
	}

	createTargetPageLanguage = async (formData) => {
		const response = await createTargetPageLanguage({ ...formData, target_page_id: this.state.formData.id, });
		const newLanguageFormList = [ ...this.state.languageFormList, ];

		newLanguageFormList.pop(); //deletes blank item
		newLanguageFormList.push(response); //because we have individual states inside each item, we want to update parent state with new data from item;
		await this.save({ languageFormList: newLanguageFormList, });

		return response;
	}

	updateTargetPageLanguage = async (id, formData, index) => {
		const response = await updateTargetPageLanguage(id, { ...formData, target_page_id: this.state.formData.id, });
		const newLanguageFormList = [ ...this.state.languageFormList, ];

		newLanguageFormList[index] = response; //because we have individual states inside each item, we want to update parent state with new data from item;

		await this.save({ languageFormList: newLanguageFormList, });

		return response;
	}
	deleteNameFormDataItem = async (index) => {
		try {
			const { nameFormList, } = this.state;
			const id = nameFormList[index].id;
			const itemExistInDb = !!id; //id setted only in db, e.g. when item is created

			if (itemExistInDb) {
				const response = await deleteTargetPageName(id);
			}

			await this.save({ nameFormList: nameFormList.filter((item, itemIndex) => index !== itemIndex), });
		} catch (error) {
			console.log(error);
			error.showErrorNotification?.();
		}
	}

	deleteLanguageFormDataItem = async (index) => {
		try {
			const { languageFormList, } = this.state;
			const id = languageFormList[index].id;
			const itemExistInDb = !!id;

			if (itemExistInDb) {
				const response = await deleteTargetPageLanguage(id);
			}

			await this.save({ languageFormList: languageFormList.filter((item, itemIndex) => index !== itemIndex), });
		} catch (error) {
			console.log(error);
			error.showErrorNotification?.();
		}
	}
	renderLanguageForm = () => {
		const {
			languageOptions, languageFormList,
		} = this.state;
		const {
			languageFormList: languagesInDB,
		} = this.props.popupData;

		return (
			<TargetPageLanguageList
				addLanguage={ this.addTargetPageLanguage }
				createTargetPageLanguage={ this.createTargetPageLanguage }
				deleteLanguageFormDataItem={ this.deleteLanguageFormDataItem }
				isEditMode={ this.isEditMode }
				isViewMode={ this.isViewMode }
				languageFormList={ languageFormList }
				languageOptions={ languageOptions }
				languagesInDB={ languagesInDB }
				languageStatusesOptions={ this.languageStatusesOptions }
				translate={ this.props.translate }
				updateTargetPageLanguage={ this.updateTargetPageLanguage }
			/>
		);
	}
	renderNameOnDifferentLanguageForm = () => {
		const { languageOptions, nameFormList, } = this.state;
		const {
			nameFormList: namesInDB,
		} = this.props.popupData;

		return (
			<TargetPageNameList
				addName={ this.addTargetPageName }
				createTargetPageName={ this.createTargetPageName }
				deleteNameFormDataItem={ this.deleteNameFormDataItem }
				isEditMode={ this.isEditMode }
				isViewMode={ this.isViewMode }
				languageOptions={ languageOptions }
				nameFormList={ nameFormList }
				namesInDB={ namesInDB }
				translate={ this.props.translate }
				updateTargetPageName={ this.updateTargetPageName }
			/>
		);
	}

	render () {
		const { onClose, } = this.props;
		const { loadData, } = this.state;
		const isEditMode = this.isEditMode || this.targetPageExistInDB;
		const showPopup = this.isViewMode || (!this.formIsUpdated && this.targetPageExistInDB);

		return (
			//eslint-disable-next-line
			<Popup
				cancelBtnText={ "partners_payout_rate_list_popup_cancel" }
				createBtnText={ isEditMode ? "template_management_btn_update" : "template_management_create" }
				customClassName={ `promo-popup promo-popup_target-page ${ isEditMode && "promo-popup_edit" }` }
				isImage={ false }
				loadData={ loadData }
				showPopUp={ showPopup }
				size={ "popup-banner-size" }
				title={ this.renderTitle() }
				onClick={ this.saveData }
				onClose={ onClose }
			>
				{this.renderTargetPageForm()}

				{
					this.targetPageExistInDB && <>
						{this.renderLanguageForm()}
						{this.renderNameOnDifferentLanguageForm()}
					</>
				}

			</Popup>
		);
	}
}

export default withLocalize(CreateUpdateTargetPage);