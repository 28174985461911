import React, { Component, } from "react";
import { withLocalize, } from "react-localize-redux";
import { Input, Select, } from "../../../../../../components";
import { setErrorClass, findOptionByValue, } from "../../../../../../utils/helpers";
import { onValidDigits, } from "../../helpers/validation-helpers/onValidHelpers";
import Radio from "../Radio";

class ReferralCountryBlockCpa extends Component {
	constructor (props) {
		super(props);

		this.state = {
			countryCheckListOptions: [],
			formData: {
				referrer_country_check: {},
				first_deposit_fixing_hours: 0,
				first_deposit_confirmation_period: 0,
				payout_rate_list_id: '',
			},
		};
	}

	save = async state => new Promise(next => this.setState(state, next));

	componentDidMount = async () => {
		const { countryCheckListOptions, } = this.props;

		await this.save((state) => {
			return {
				countryCheckListOptions,
			};
		});
	};

	handleOnChange = fieldName => async (event) => {
		const { value, } = event.target;
		const { setNewValue, clearErrorOnChange, } = this.props;

		try {
			clearErrorOnChange(event);
			switch (fieldName) {
				case "referrer_country_check": {
					await setNewValue(fieldName, value.value);
					break;
				}
				case "first_deposit_fixing_hours": {
					await setNewValue(fieldName, value);
					break;
				}
				case "first_deposit_confirmation_period": {
					await setNewValue(fieldName, value);
					break;
				}
				case "payout_rate_list_id": {
					await setNewValue(fieldName, value.value);
					break;
				}
				case "consider_ref_linked_to_locked": {
					await setNewValue(fieldName, value);
					break;
				}
				default: {
					break;
				}
			}
		} catch (e) {
			throw new Error(e);
		}
	};

	renderReferralCountryBlockItems = () => {
		const {
			translate,
			errors,
			payoutRateList,
			conditionType,
			formData,
			fieldsIsNotEdit,
			level,
			isViewMode,
		} = this.props;

		const { countryCheckListOptions, } = this.state;
		const filteredPayoutRateList = payoutRateList
			.filter(
				({ partners, }) =>
					partners.payout_rate_lists.type === conditionType &&
					partners.payout_rate_lists.status === 2,
			)
			.map(({ partners, }) => {
				return {
					label: partners.payout_rate_lists.name,
					value: partners.payout_rate_lists.id,
				};
			});
		const disabled = fieldsIsNotEdit || isViewMode;
		const considerReferralsLinkedToLocked =
			formData.consider_ref_linked_to_locked;

		return (
			<div className="new-partners-conditions__popup mb-20">
				{level.MULTI_LEVEL && (
					<div className="w-30 mr-35">
						<Radio // useless, maybee keep previous variant?
							className={ `magic-radio-switch magic-radio-switch--accept-reject-btns magic-radio-switch--payment-confirmation` }
							classNameText={ "`magic-switch__text`" }
							direction={ "row" }
							handleOnChange={ this.handleOnChange(
								"consider_ref_linked_to_locked",
							) }
							isDisabled={ disabled }
							label={ translate(
								"new_partners_confition_allow_blocked_referrals",
							) }
							options={ [
								{ name: translate("symbols_remove_accept"), value: 1, },
								{ name: translate("symbols_remove_reject"), value: 0, },
							] }
							value={ considerReferralsLinkedToLocked }
							wrapperClassName={ "select__wrapper" }
						/>
					</div>
				)}
				<div className="w-30 mr-35">
					<Select
						disabled={disabled}
						isRequired={true}
						name="referrer_country_check"
						options={ countryCheckListOptions }
						placeholder={ translate(
							"new_partners_condition_country_check_placeholder",
				)}
						value={ findOptionByValue(
							countryCheckListOptions,
							formData.referrer_country_check,
						)  }
						onChange={ this.handleOnChange("referrer_country_check") }
						className={ `${ setErrorClass(errors, "referrer_country_check") }` }
						// disabled={!loadData || this.NewPartnersConditionsListStatus.new}
						label={ translate("new_partners_condition_country_determination") }
					/>
				</div>
				<div className="w-30 mr-35">
					<Input
						disabled={disabled}
						isRequired={true}
						placeholder={translate(
							"new_partners_condition_fixation_period_placeholder",
						)}
						value={formData.first_deposit_fixing_hours}
						onChange={this.handleOnChange("first_deposit_fixing_hours") }
						name="first_deposit_fixing_hours"
						// disabled={!loadData || this.statusIsNotEdit}
						wrapperClassName={setErrorClass(
							errors,
							"first_deposit_fixing_hours",
				)}
						onValid={value => onValidDigits(value, 1000, 1)}
						// options={levelList}
						label={translate("new_partners_condition_fixation_period") }
					/>
				</div>
				<div className="w-30 mr-35">
					<Input
						disabled={ disabled }
						isRequired={ true }
						label={ translate("new_partners_condition_confirmation_period") }
						name="first_deposit_confirmation_period"
						placeholder={ translate(
							"new_partners_condition_confirmation_period_placeholder",
						) }
						value={ formData.first_deposit_confirmation_period }
						wrapperClassName={ setErrorClass(
							errors,
							"first_deposit_confirmation_period",
						) }
						onChange={ this.handleOnChange("first_deposit_confirmation_period") }
						onValid={ value => onValidDigits(value, 100, 1) }
					/>
				</div>
				<div className="w-30 mr-35 country-list">
					<Select
						className={ setErrorClass(errors, "payout_rate_list_id") }
						disabled={ disabled }
						isRequired={ true }
						label={ translate("new_partners_condition_country_list") }
						name="payout_rate_list_id"
						options={ filteredPayoutRateList }
						placeholder={ translate("new_partners_condition_select_countrylist") }
						value={ findOptionByValue(
							filteredPayoutRateList,
							formData.payout_rate_list_id,
						) }
						onChange={ this.handleOnChange("payout_rate_list_id") }
					/>
				</div>
			</div>
		);
	};
	render () {
		const { formData, } = this.props;

		return <>{formData && this.renderReferralCountryBlockItems()}</>;
	}
}

export default withLocalize(ReferralCountryBlockCpa);