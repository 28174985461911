import React from 'react';
import PropTypes from 'prop-types';
import {withRouter,} from 'react-router-dom';
import {withLocalize,} from "react-localize-redux";

import PagePanel from '../../tima/components/Pages/PagePanel';
import Preloader from '../../../components/LoadingHOC/Preloader';
import ClientsMagicTable from '../../clients/components/ClientsMagicTable';

import {Mentor,} from '../../../components/Magic';
import {getClientsAsync,} from '../../clients/services/AccountRequestService';

class SearchAccount extends React.Component {
  static propTypes = {
    count: PropTypes.func.isRequired,
  };

  constructor (props) {
    super(props);
    this.state = {
      data: [],
      filterLastChange: Date.now(),
      loaded: true,
      pages: {
        filter: 0,
        take: 5,
        total: 0,
        variance: 2,
      },
    };

    const mf = new Mentor({
      owner: this,
      serviceId: 240,
      translate: this.props.translate,
    });

    Object.defineProperty(this, 'mf', { get: () => mf, });
  }

  // eslint-disable-next-line react/sort-comp, require-await
  save = async state => new Promise(next => this.setState(state, next));

  // eslint-disable-next-line require-await
  mfChanged = async () => {
    await this.save({ filterLastChange: Date.now(), });
  };

  mfDoing = async () => {
    await this.save({ loaded: false, });
  };

  mfDone = async () => {
    await this.mfChanged();
    await this.onDataLoad();
  };

  async componentDidMount () {
    this.mf.subscribe({
      changed: this.mfChanged,
      // changing: ,
      cleaned: this.mfDone,
      cleaning: this.mfDoing,
      loaded: this.mfDone,
      loading: this.mfDoing,
      saved: this.mfDone,
      saving: this.mfDoing,
    }, this);
    await this.mf.init({ doEmit: true, });
    // await this.mf.emit('change', {});
    // await this.onDataLoad(); // TODO: moved to mfDone

    const enabled = 1;
    const v = this.query.get('q') ?? '';

    await this.mf.filterChange({
      $$changed: true,
      id: 'f:aFullname',
      setter: { enabled, v, },
    });
    await this.mf.apply({});
  }

  componentWillUnmount = () => {
    this.mf.unsubscribe([
      this.mfChanged,
      this.mfDoing,
      this.mfDone,
    ], this);
  };

  get location () {
    // eslint-disable-next-line no-restricted-globals
    return new URL(location.href);
  }

  get query () {
    return new URLSearchParams(this.location.searchParams);
  }

  render () {
    if (!this.state.loaded) {
      return (<Preloader />);
    } else if (!this.state?.data?.length) {
      return null;
    }

    const { translate, } = this.props;
    const pageOptions = {
      filter: this.state?.pages?.filter,
      take: this.state?.pages?.take,
      total: this.state?.pages?.total,
      variance: this.state?.pages?.variance,
      page: this?.pageId?.(),
      onClick: this?.onPageClick,
      doText: this?.onPageText,
      doTo: (pageId) => {
        const search = this.location;

        search.searchParams.set('pageA', pageId);

        return `${ search?.pathname }${ search.search }`;
      },
      doStatus: () => '',
    };

    return (
      <React.Fragment>
        <h3>{ translate(`client_clients`) }</h3>
        <ClientsMagicTable
          data={ this.state.data }
          emailPopup={ () => null }
          order={ async (orderId) => {
            await this.mf.orderedToNext(orderId);
            // await this.onDataLoad(); // TODO: remove cause reaction should be in subscribe
          } }
          orderTo={ (orderId) => {
            return this.mf.orderedTo(orderId);
          } }
          selectedUsers={ [] }
          selectUser={ () => {} }
          showCheckbox={ false }
          selectUserPermission = { () => { return false } }
        />
        <PagePanel { ...pageOptions } />
      </React.Fragment>
    );
  }

  pageId = () => {
    const pageId = this.query.get('pageA') ?? 0;

    return +pageId || 0;
  };

  pageIdAsGET = (pageId) => {
    pageId = pageId === undefined ? this.pageId() : pageId;

    const { filterId, } = this.mf;
    const result = {
      filterId,
      skip: pageId * this.state.pages.take,
      take: this.state.pages.take,
    };

    return result;
  };

  onDataLoad = async (pageId) => {
    await this.save({ loaded: false, });

    const pageOptions = this.pageIdAsGET(pageId);
    const loaded = (data, meta) => ({ pages, }) => ({
      data,
      pages: { ...pages, ...meta, },
    });

    try {
      const response =  await getClientsAsync(pageOptions);
      const { data, meta: { filter = 0, total = 0, }, } = response;

      await this.save(loaded(data, { filter, total, }));
    } catch (e) {
      await this.save(loaded([], { filter: 0, total: 0, }));
    }
    await this.save({ loaded: true, });
    await this.props.count(this.state.data.length);
  };

  onPageClick = ({ event, pageId, pageIs, }) => {
    this.onDataLoad(pageId);
  };

  onPageText = (pageId, pageIs) => {
    const { translate, } = this.props;
    // eslint-disable-next-line object-curly-newline
    const { current, first, prev, next, last, } = pageIs;
    // eslint-disable-next-line object-curly-newline
    const { skipped, taken, filter, total, } = pageIs;

    if (skipped || taken) {
      const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : '?';
      const text = skipped ? translate('partners_pages_items') : '';

      return skipped ? `${ text }: ${ id }` : id;
    } else if (filter || total) {
      const id = Number.isInteger(pageId) ? `${ pageId }` : '?';
      const text = translate(filter ? 'partners_pages_filtered' : 'partners_pages_total');

      return `${ text }: ${ id }`;
    } else if (first || prev || next || last) {
      return '';
    } else if (current) {
      return `${ pageId + 1 }`;
    }

    return `${ pageId + 1 }`;

  };
}

export default withRouter(withLocalize(SearchAccount));