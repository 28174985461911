import React, { Component, } from 'react';
import { withLocalize, } from "react-localize-redux";
import {
	setErrorClass,
} from "../../../../../utils/helpers";
import { Select, } from "../../../../../components";
import { isEmpty, } from "lodash";
import Textarea from "../../../../ components/Textarea/Textarea";

class ConditionInputsBlock extends Component {

	constructor (props) {
		super(props);

		this.state = {
			partnersList: [],

		};
	}

		save = async state => new Promise(next => this.setState(state, next));
		componentDidMount = async () => {
			const { partnersList, } = this.props;

			await this.save((state) => {
				return {
					partnersList,
				};
			});

		};
		handleOnChange = (index, fieldName) => async (event) => {
			const { setFieldSingleLeveData, } = this.props;
			const { value, } = event.target;

			try {
				await setFieldSingleLeveData(fieldName, value, index, value.value);
			} catch (e) {
				throw new Error(e);
			}
		};
		removeInputsData = (fieldName, value, id) => async () => {
			const { setConditionsId, } = this.props;

			try {
				await setConditionsId(fieldName, value, id);
			} catch (e) {
				throw new Error(e);
			}
		};
	setErrorClassGroup = (data = [], name = "") => {
		return data.includes(name) ? "errorGroup" : "";
	};

	filteredOptions = (type, level) => {
		const { partnersList, } = this.state;

		return partnersList
			.filter(({ partners, }) => partners.new_partners_conditions.type === type && partners.new_partners_conditions.level === level && partners.new_partners_conditions.status === 2)
			.map((item) => {
				const { name, id, } = item.partners.new_partners_conditions;

				return {
					label: name,
					value: id,
				};
			});
	}

		renderInputsBlock = () => {
			const {
				translate,
				errors,
				formData,
				disabledinputs,
				isViewMode,
				statusInDb,
			} = this.props;
			const disabled = disabledinputs || isViewMode;
			const className = `${ this.setErrorClassGroup(errors, `conditionIds`) } affiliate_programs_inputs__block`;

			return (
				<>
					<div className="df">
						<p className="payout-rate-list__payout_table-title mr14">
							{ translate("affiliate_programs_single_level") }
						</p>
						<p className="payout-rate-list__payout_table-title">
							{ translate("affiliate_programs_multi_level") }
						</p>
					</div>
					<div className={ `df` }>
						<div className={ className } >
							<Select
								className={ setErrorClass(errors, 'CPASingle') }
								disabled={ disabled }
								label={ 'CPA' }
								name="CPASingle"
								options={ this.filteredOptions(1, 1) }
								placeholder={ translate('affiliate_programs_input_placeholder') }
								value={ formData.CPASingle.value === undefined ? {} : formData.CPASingle }
								onChange={ this.handleOnChange(0, "CPASingle") }
							/>
							{isEmpty(formData.CPASingle) || disabled || formData.CPASingle.value === undefined ?
								'': <button
									className="affiliate_programs_remove__btn mt-20 rm-range-item"
									onClick={ this.removeInputsData('CPASingle', {}, formData.CPASingle.value) }
								/> }
							<Select
								className={ setErrorClass(errors, 'CPLSingle') }
								disabled={ disabled }
								label={ 'CPL' }
								name="CPLSingle"
								options={ this.filteredOptions(2, 1) }
								placeholder={ translate('affiliate_programs_input_placeholder') }
								value={ formData.CPLSingle.value === undefined ? {} : formData.CPLSingle }
								onChange={ this.handleOnChange(1, "CPLSingle") }
							/>
							{isEmpty(formData.CPLSingle) || disabled || formData.CPLSingle.value === undefined ?
								'': <button
									className="affiliate_programs_remove__btn  mt-20 rm-range-item"
									onClick={ this.removeInputsData('CPLSingle', {}) }
								/> }
							<Select
								className={ setErrorClass(errors, 'InOutSingle') }
								disabled={ disabled }
								label={ 'In/Out' }
								name="InOutSingle"
								options={ this.filteredOptions(3, 1) }
								placeholder={ translate('affiliate_programs_input_placeholder') }
								value={ formData.InOutSingle.value === undefined ? {} : formData.InOutSingle }
								onChange={ this.handleOnChange(2, "InOutSingle") }
							/>
							{isEmpty(formData.InOutSingle) || disabled || formData.InOutSingle.value === undefined ?
								'': <button
									className="affiliate_programs_remove__btn mt-20 rm-range-item"
									onClick={ this.removeInputsData('InOutSingle', {}) }
								/> }
							<Select
								className={ setErrorClass(errors, 'RevshareSingle') }
								disabled={ disabled }
								label={ 'Revshare' }
								name="RevshareSingle"
								options={ this.filteredOptions(4, 1) }
								placeholder={ translate('affiliate_programs_input_placeholder') }
								value={ formData.RevshareSingle.value === undefined ? {} : formData.RevshareSingle }
								onChange={ this.handleOnChange(3, "RevshareSingle") }
							/>
							{isEmpty(formData.RevshareSingle) || disabled || formData.RevshareSingle.value === undefined ?
								'': <button
									className="affiliate_programs_remove__btn mt-20 rm-range-item"
									onClick={ this.removeInputsData('RevshareSingle', {}) }
								/> }

						</div>
						<div className={ className }>

							<Select
								className={ setErrorClass(errors, 'CPAMulti') }
								disabled={ disabled }
								label={ 'CPA' }
								name="CPAMulti"
								options={ this.filteredOptions(1, 2) }
								placeholder={ translate('affiliate_programs_input_placeholder') }
								value={ formData.CPAMulti.value === undefined ? {} : formData.CPAMulti }
								onChange={ this.handleOnChange(4, "CPAMulti") }
							/>
							{isEmpty(formData.CPAMulti) || disabled || formData.CPAMulti.value === undefined ?
								'': <button
									className="affiliate_programs_remove__btn mt-20 rm-range-item"
									onClick={ this.removeInputsData('CPAMulti', {}) }
								/> }
							<Select
								className={ setErrorClass(errors, 'CPLMulti') }
								disabled={ disabled }
								label={ 'CPL' }
								name="CPLMulti"
								options={ this.filteredOptions(2, 2) }
								placeholder={ translate('affiliate_programs_input_placeholder') }
								value={ formData.CPLMulti.value === undefined ? {} : formData.CPLMulti }
								onChange={ this.handleOnChange(5, "CPLMulti") }
							/>
							{isEmpty(formData.CPLMulti) || disabled || formData.CPLMulti.value === undefined ?
								'': <button
									className="affiliate_programs_remove__btn mt-20 rm-range-item"
									onClick={ this.removeInputsData('CPLMulti', {}) }
								/> }
							<Select
								className={ setErrorClass(errors, 'InOutMulti') }
								disabled={ disabled }
								label={ 'In/Out' }
								name="InOutMulti"
								options={ this.filteredOptions(3, 2) }
								placeholder={ translate('affiliate_programs_input_placeholder') }
								value={ formData.InOutMulti.value === undefined ? {} : formData.InOutMulti }
								onChange={ this.handleOnChange(6, "InOutMulti") }
							/>
							{isEmpty(formData.InOutMulti) || disabled || formData.InOutMulti.value === undefined ?
								'': <button
									className="affiliate_programs_remove__btn mt-20 rm-range-item"
									onClick={ this.removeInputsData('InOutMulti', {}) }
								/> }
							<Select
								className={ setErrorClass(errors, 'RevshareMulti') }
								disabled={ disabled }
								label={ 'Revshare' }
								name="RevshareMulti"
								options={ this.filteredOptions(4, 2) }
								placeholder={ translate('affiliate_programs_input_placeholder') }
								value={ formData.RevshareMulti.value === undefined ? {} : formData.RevshareMulti }
								onChange={ this.handleOnChange(7, "RevshareMulti") }
							/>
							{isEmpty(formData.RevshareMulti) || disabled || formData.RevshareMulti.value === undefined ?
								'': <button
									className="affiliate_programs_remove__btn mt-20 rm-range-item"
									onClick={ this.removeInputsData('RevshareMulti', {}) }
								/> }
						</div>
						<div className={`w-50 mr-35 field affiliate_programs_description ${setErrorClass(errors, 'description')}`}>
							<Textarea
								className={ `${ isViewMode || statusInDb.closed ? "disabled" : "" } h-100 description` }
								isDisabled={ isViewMode || statusInDb.closed }
								isRequired={ false }
								label={ translate("new_partners_condition_description") }
								name="description"
								placeholder={ translate(
									"new_partners_condition_description_placeholder",
								) }
								value={ formData.description }
								onChange={ this.handleOnChange(8, 'description') }
								onValid={ (value) => {
									const regExp = /^[A-Za-zА-Яа-яёЁЇїІіЄєҐґ0-9\s\.\,\-\!\@\#\$\&\%\^\\*\(\)]{0,1000}$/g;

									if (value !== "" && !regExp.test(value)) {
										throw new Error("xxx");
									}
									if (value.length > 1000) {
										throw new Error("xxx");
									}
								} }
							/></div>
					</div>
				</>
			);
		};

		render () {
			const { translate, } = this.props;

			return (
				<>
					<div className="affiliate_programs_inputs_block">
						<p className={ 'payout-rate-list__payout_block-title mb-30' }>
							{ translate('new_partners_condition_rates') }
						</p>
						{ this.renderInputsBlock() }
					</div>
				</>
			);
		}
}

export default withLocalize(ConditionInputsBlock);