import React, { Component, } from "react";
import { withRouter, } from "react-router-dom";
import { withLocalize, } from "react-localize-redux";
class Conversations extends Component {
	get translate () {
		return this.props.translate;
	}
	handleOnChange = async (id) => {
		const { setConversationId } = this.props;
		setConversationId(id);
	}

	renderChannels = (messagesData,) => {

		return messagesData?.map((item, index) => {
			const {
				name,
				surname
			} = item.core.account;
			const { contact_source } = item.novatalks.contacts;
			const { message_time, viewed } = item.novatalks.messages;
			const fullName = `${name} ${surname}`;
			const lastTextMessage = item.novatalks.messages.content;
			const conversationId = item.novatalks.messages.conversation_id;
			const className = viewed === 2 ? `scroll-channels__channel channel bgNotViewed` :  ` scroll-channels__channel channel`

			return (
				<li onClick={() =>this.handleOnChange(conversationId)} className={className} key={ index }>
					<div
						className={ `channel__image channel__image_${ contact_source.toLowerCase() }  ` }
					/>
					<div className="channel__content">
						<div className="channel__row">
							<h3 className="channel__name">{fullName}</h3>
							<p className="channel__messenger">{contact_source}</p>
							<span className={'channel__messenger'}>(id:{conversationId})</span>
							<p className="channel__date">{message_time}</p>
						</div>
						<p className="channel__message">{lastTextMessage.length > 0 ? lastTextMessage : 'Audio Message' } </p>
					</div>
				</li>
			);
		});
	};

	render () {
		const { translate, renderChannels, } = this;
		const { messagesData, conversationData, onScroll,} = this.props;


		return (
			<>
			<div  className="conversations__section">
				<h4 className="conversations__title">
					{translate(`conversation_channel`)}
				</h4>

				<ul onScroll={(event) => onScroll(event)} className="conversations__scroll-channels scroll-channels scroll">
					{renderChannels(messagesData, conversationData)}
				</ul>
			</div>
			</>
		);
	}
}

export default withRouter(withLocalize(Conversations));