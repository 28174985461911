import React, { Component, } from 'react';
import { withLocalize } from 'react-localize-redux'

class HeaderInfoBlock extends Component {

	render () {
		const { conversationData, translate } = this.props;
		const firsElement = conversationData.length - 1;

		return conversationData.slice(firsElement)?.map((clientInfo, index) => {
			const {
				email, name, surname, phone, id
			} = clientInfo.core.account;
			const { language, } = clientInfo?.novatalks?.contacts;
			const { name: managerName, surname: managerSurname, } = clientInfo.core.user_responsible;
			const langTranslate = language === 'ru' ? 'Русский' : language === 'uk' ? 'Украинский' : language === 'en' ? 'Английский' : '';
			const fullName = `${ name ?? '' } ${ surname ?? ''  }`;
			const managerFullName = `${ managerName  ?? '' } ${ managerSurname ?? '' }`;


			return (
				<div className="conversation_header__block" key={ index }>
					<p><a className="conversation__client__name" target="_blank" href={`/clients/${id}`}>{fullName}</a></p>
					<ul className="conversation_header__list">
						<li className="conversation_header__list__items" >
							<p className="conversation__client__info">Телефон</p>
							<p className="conversation__client__info__data" >{phone}</p>
						</li>
						<li className="conversation_header__list__items">
							<p className="conversation__client__info">Email</p>
							<p className="conversation__client__info__data">{email}</p>
						</li>
						<li className="conversation_header__list__items">
							<p className="conversation__client__info">{translate('conversation_client_language')}</p>
							<p className="conversation__client__info__data">   <span className={'select-account-category-label label-3'}>{langTranslate}</span></p>
						</li>
						<li className="conversation_header__list__items">
							<p className="conversation__client__info">{translate('conversation_channel_client_responsible')}</p>
							<p className="conversation__client__info__data"><span className={'select-account-category-label label-3'}>{managerFullName }</span></p>
						</li>
					</ul>
				</div>
			);

		});

	}
}

export default withLocalize(HeaderInfoBlock);