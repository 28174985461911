import React from 'react';
import { Link } from "react-router-dom";

function NoAccess (props) {
    const path = '/';

    return (
        <div className='no-access'>
            <div className='container'>
                <h1>{props.translate('client_no_access_1')}</h1>
                {/*<p>{props.translate('client_no_access_2')}</p>*/}
                <div className='d-flex align-items-center justify-content-center'>
                    <Link to={path} className='button big red unshadow go-back-btn'>Go Back</Link>
                </div>
            </div>
        </div>
    );
}

export default NoAccess;
