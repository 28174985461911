import _, { map, has, isPlainObject, isArray } from "lodash";
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import { imageEncode } from "../../helpers/UserHelper";
import { getLanguages } from "../../../../common/services/LocalisationService";
import defaultAvatar from "../../../../../images/profile_default.jpg";
import NotificationService from "../../../../../services/NotificationService";
import * as moment from "moment";
import { MagicMultiSelect } from "../../../../../components/Magic/components/MagicMultiSelect";
import { MagicSelect } from "../../../../../components/Magic/components/MagicSelect";
import { Col, Form, FormGroup, Input, Label, Row, } from "reactstrap";
import lock from "../../../../../images/lock.svg";
import { MagicInput } from "../../../../../components/Magic/components/MagicInput";
import BirthDatepicker from "./BirthDatepicker";
import ManagerScheduleTable from "./ManagerScheduleTable";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import LangSelect from "../../../../common/components/Frame/LangSelect";
import background from "../../../../../images/profile-background.svg";
import PermissionService from "../../../../../services/PermissionService";
import Preloader from "../../../../../components/LoadingHOC/Preloader";
import PagePanel from "../../../../tima/components/Pages";
import { MagicButton } from "../../../../../components/Magic/components/MagicButton";
import { enumService } from "../../../../../services/EnumDataService";
import { profileService } from "../../../../../services/ProfileDataService";
import { userService } from "../../../../../services/UserDataService";

let formData = new FormData();
const defaultCalendar = '{"day_1": {"to": "22:00", "from": "04:00", "is_work": 1}, "day_2": {"to": "22:00", "from": "04:00", "is_work": 1}, "day_3": {"to": "22:00", "from": "04:00", "is_work": 1}, "day_4": {"to": "22:00", "from": "04:00", "is_work": 1}, "day_5": {"to": "22:00", "from": "04:00", "is_work": 1}, "day_6": {"is_work": 2}, "day_7": {"is_work": 2}}';

class ManagerCreateForm extends Component {
    static Switchs = { 1: true, 2: false };
    static fromSwitch = (key, defaultValue) => Object.entries(ManagerCreateForm.Switchs)
        .filter(([k, v]) => k === key)
        .map(([k, v]) => v)
        .shift() || defaultValue;
    static defaultProps = {
        user: {},
    };

    constructor (props) {
        super(props);
        const userId = this.props.match.path === "/settings/profile" ? this.state?.profile?.id : this.props.match.params.id ? this.props.match.params.id : null;
        // const user = this.props.user?.core?.user;
        // const user = _.get(this.props, 'user.core', undefined);

        this.state = {
            dataForm: {
                email:                  '',
                name:                   '',
                surname:                '',
                preference_lang_id:     133,
                phone:                  '',
                birth_date:             '',
                calendar:               defaultCalendar,
                user_not_work:          [],
                throughput:             '',
                permission_template_id: '',
                adaptation:             1, // possible value for checkbox: 1 === true, 2 === false
                languages:              [169],
                role_id:                2, //fake data
                password:               '',
                password_confirmation:  '',
                status:                 '',
                internal_phone:         '',
            },
            departments:       [],
            languagesData:     [],
            formPermission:    false,
            errors:            {},
            allError:          false,
            currentSelect:     {},
            file:              null,
            imagePreviewUrl:   '',
            userId,
            language:          [],
            languageId:        {},
            selectedLanguages: [169],
            date:              [new Date(), new Date()],
            avatar:            undefined,
            userHash:          null,
            pages: {
                pageId:   0,
                take:     5,
                variance: 2,
            },
            newChanges: false,
            permission_template: {
                hash: null,
                data: [],
            },
            breadcrumb: {
                name: '',
                surname: '',
            },
        };
    }

    get isAdaptation () {
        return ManagerCreateForm.fromSwitch(this.state.dataForm.adaptation, this.state.dataForm.adaptation === 1);
    }

    get configUser () {
        return [
            {
                path:   ['core', 'user', 'name'],
                key:    'userName',
                render: this.renderFirstName,
            },
            {
                path:   ['core', 'user', 'surname'],
                key:    'userLastName',
                render: this.renderLastName,
            },
            {
                path:   ['core', 'user', 'name'],
                key:    'userAvatar',
                render: this.renderAvatar,
            },
            {
                path:   ['core', 'user', 'birth_date'],
                key:    'userBirthDate',
                render: this.renderBirthDate,
            },
            {
                path:   ['core', 'user', 'throughput'],
                key:    'userThroughput',
                render: this.renderOpportunity,
            },
            {
                path:   ['core', 'user', 'adaptation'],
                key:    'userAdaptation',
                render: this.renderAdaptation,
            },
            {
                path:   ['core', 'user', 'status'],
                key:    'userStatus',
                render: this.renderSelectStatus,
            },
            {
                path:   ['core', 'user', 'email'],
                key:    'userEmail',
                render: this.renderEmail,
            },
            {
                path:   ['core', 'user', 'phone'],
                key:    'userPhone',
                render: this.renderPhone,
            },
            {
                path:   ['core', 'user', 'password'],
                key:    'userPassword',
                render: this.renderPassword,
            },
            {
                path:   ['core', 'user', 'password'],
                key:    'userPasswordConf',
                render: this.renderPasswordConf,
            },
            {
                path:   ['core', 'user', 'permission_template_id'],
                key:    'userPermissionTemplateId',
                render: this.renderAccessRights,
            },
            {
                path:   ['core', 'user', 'calendar'],
                key:    'userSchedule',
                render: this.renderSchedule,
            },
            {
                path:   ['core', 'user_not_work', 'id'],
                key:    'userVacantion',
                render: this.renderVacation,
            },
            {
                path:   ['core', 'user', 'name'],
                key:    'userLanguages',
                render: this.renderLanguagesSelect,
            },
            {
                path:   ['core', 'user', 'id'],
                key:    'userButton',
                render: this.renderButton,
            },
            {
                path:   ['core', 'user', 'name'],
                key:    'userDepartments',
                render: this.renderDepatments,
            },
            {
                path:   ['core', 'user', 'internal_phone'],
                key:    'userInternalPhone',
                render: this.renderInternalPhone,
            }
        ];
    }

    set isAdaptation (checked) {
        this.setSwitchValue('adaptation', 'adaptation', checked);
    }

    formatDate (date) {
        return moment(date).format('YYYY-MM-DD HH:mm:ss');
    }

    save = async (state) => new Promise(next => this.setState(state, next));

    getUserData = async (id) => {
        const { data, hash } = await userService.getUser(id);
        await this.saveUserData({ data, hash });
    };

    saveUserData = async ({ data, hash }) => {
        await this.save({
            dataForm: {
                email:                  data.user.email ?? '',
                name:                   data.user.name ?? '',
                surname:                data.user.surname ?? '',
                preference_lang_id:     data.user.preference_lang_id ?? 133,
                phone:                  data.user.phone ?? '',
                internal_phone:         data.user.internal_phone ?? '',
                birth_date:             data.user.birth_date ?? null,
                calendar:               data.user.calendar ?? '',
                user_not_work:          data['user_not_work'] ?? [],
                throughput:             data.user.throughput ?? '',
                permission_template_id: data.user.permission_template_id ?? '',
                adaptation:             data.user.adaptation ?? 1,
                status:                 data.user.status ?? '1',
                languages:              [],
            },
            departments:   data.departments,
            userId:        data.user.id,
            languagesData: data['user_languages'] ?? [],
            userHash:      hash,
            breadcrumb: {
                name: data.user.name ?? '',
                surname: data.user.surname ?? '',
            },
        });
        this.changeDateVacationFromServer(this.state.dataForm.user_not_work);
        this.changeLanguageFromServer(this.state.languagesData);
    };

    changeDateVacationFromServer = (array) => {
        array.forEach( item => {
            item.from = this.formatDate(item.from);
            item.to = this.formatDate(item.to);
            item.usage = true;
        });
    };

    changeLanguageFromServer = async (array) => {
        const languagesSelect = [];
        array.forEach((item) => {
            languagesSelect.push(item.language_id);
        });
        await this.save({
            selectedLanguages: languagesSelect,
            dataForm:          { ...this.state.dataForm, languages: languagesSelect },
        });
    };

    userChange = async (user) => {
        if (user.hash===this.state?.userHash) return `${this.constructor.name}.userChange: false`;
        const { data, hash } = user;
        await this.saveUserData({ data, hash });
        return `${this.constructor.name}.userChange: true`;
    };

    changeState = async () => this.save({ newChanges: true });

    enumsChange = async (enums) => {
        if (enums.hash===this.state?.enums?.hash) return `${this.constructor.name}.enumsChange: false`;
        await this.save({ enums, });
        return `${this.constructor.name}.enumsChange: true`;
    };

    profileChange = async (profile) => {
        if (profile.hash===this.state?.profile?.hash) return `${this.constructor.name}.profileChange: false`;
        await this.save({ profile, });
        return `${this.constructor.name}.profileChange: true`;
    };

    async componentDidMount () {
        enumService.subscribe('enums', this.enumsChange, this);
        profileService.subscribe('profile', this.profileChange, this);
        userService.subscribe('getUsersPermissionTemplates', this.permissionTemplatesChange, this);
        userService.subscribe('getUser', this.userChange, this);
        await Promise.all([
            enumService.enums,
            profileService.profile
        ]);
        formData = new FormData();
        this.setToStateTemplate();
        const languageId = {};

        const responseLang = await getLanguages();
        const newObjs = [];

        map(responseLang, (value) => {
            newObjs.push({ id: value.id, name: value.name });
            languageId[value.id] = value.name;
        });

        await this.save({ language: newObjs, languageId });

        if (this.props.match.path === "/settings/profile") {
            try {
                await this.getUserData('profile');
                const { data } = await userService.getUserAvatar(this.state?.profile?.data?.id);
                const newUrl = await imageEncode(data);
                await this.save({ avatar: newUrl });
            } catch (error) {}
        } else if (this.props.match.path === "/settings/user/:id") {
            try {
                await this.getUserData(+this.state.userId);
                const { data } = await userService.getUserAvatar(+this.state.userId);
                const newUrl = await imageEncode(data);
                await this.save({ avatar: newUrl });
            } catch (error) {}
        }
        await this.save({ loaded: true });
    }

    async componentWillUnmount () {
        enumService.unsubscribe('enums', this.enumsChange, this);
        profileService.unsubscribe('profile', this.profileChange, this);
        userService.unsubscribe('getUsersPermissionTemplates', this.permissionTemplatesChange, this);
        userService.unsubscribe('getUser', this.userChange, this);
        await this.save({ dataForm: {} }); // TODO: ???
    }

    permissionTemplatesChange = async (permission_template) => {
        if (permission_template.hash===this.state?.permission_template?.hash) return `${this.constructor.name}.permissionTemplatesChange: false`;
        await this.save({ permission_template });
        return `${this.constructor.name}.permissionTemplatesChange: true`;
    };

    setPreferenceLangId = async (lang) => {
        switch (lang) {
            case 'ru':
                await this.save({ dataForm: { ...this.state.dataForm, preference_lang_id: 133 }});
                await this.changeState();
                break;
            case 'uk':
                this.save({ dataForm: { ...this.state.dataForm, preference_lang_id: 169 }});
                await this.changeState();
                break;
            case 'en':
                this.save({ dataForm: { ...this.state.dataForm, preference_lang_id: 40 }});
                await this.changeState();
                break;
            default:
                return null;
        }
    };

    setToStateTemplate = async () => {
        const permission_template = await userService.getUsersPermissionTemplates();
        await this.save({ permission_template });
    };

    toggle = async (tab) => {
        if (this.state.activeTab !== tab) {
            await this.save({ activeTab: tab });
        }
    };

    togglePermission = async () => {
        await this.save({ formPermission: !this.state.formPermission });
    };

    verificationLoadImg = () => {
        NotificationService.error({
            title:   'error',
            message: this.props.translate("user_profile_verification_img"),
        });
    };

    handleImageChange = (e) => {
        e.preventDefault();

        const reader = new FileReader();

        const file = e.target.files[0];

        formData.append("avatar", file);

        reader.onload = (e) => {
            const scope = this;

            const img = document.createElement('img');

            if (!(file.type === "image/jpeg" || file.type === "image/png")) {
                this.verificationLoadImg(this.props.translate);

                return false;
            }

            img.onload = async function () {
                if ((this.width > 1024 || this.height > 1024) && file.size > 1000000) {
                    scope.verificationLoadImg(this.props.translate);

                    return false;
                }
                await scope.save({
                    file:   { ...scope.state.dataForm, avatar: formData },
                    avatar: reader.result,
                });
                await scope.changeState();
            };
            img.src = e.target.result;

        };

        reader.readAsDataURL(file);
    };

    checkValue = (item) => {
        if (isPlainObject(item) || isArray(item)) {
            return JSON.stringify(item);
        }

        return item;
    };

    checkEmptyField = async () => {
        if (!this.state.dataForm?.birth_date && this.state.dataForm?.internal_phone === '') {
            await this.save({
                dataForm: {
                    ...this.state.dataForm,
                    SET_NULL__birth_date: 1,
                    SET_NULL__internal_phone: 1,
                },
            });
            if (formData.has('internal_phone')) {
                formData.delete('internal_phone');
            }
            delete this.state.dataForm?.birth_date;
            delete this.state.dataForm?.internal_phone;
        } else if (!this.state.dataForm?.birth_date) {
            await this.save({
                dataForm: {
                    ...this.state.dataForm,
                    SET_NULL__birth_date: 1,
                },
            });
            delete this.state.dataForm?.birth_date;
        } else if (this.state.dataForm?.internal_phone === '') {
            await this.save({
                dataForm: {
                    ...this.state.dataForm,
                    SET_NULL__internal_phone: 1,
                },
            });
            if (formData.has('internal_phone')) {
                formData.delete('internal_phone');
            }
            delete this.state.dataForm?.internal_phone;
        }
    };

    sendData = async (e) => {
        e.preventDefault();
        if (await this.validationFields()) {
            await this.checkEmptyField();
            map(this.state.dataForm, (item, key) => {
                item ? formData.set(key, this.checkValue(item)) : false;
            });

            if (this.state.userId !== null) {
                formData.set('_method', 'PUT');
                try {
                    if (this.props.match.path === "/settings/profile") {
                        await userService.updateUser(formData, "profile");
                        await this.getUserData("profile");
                    } else {
                        await userService.updateUser(formData, this.state.userId);
                    }
                    formData = new FormData();
                    NotificationService.success({
                        title:   'success',
                        message: this.props.translate(`managerPage_update_user`),
                        remove:  true,
                    });
                    await this.save({
                        newChanges: false,
                        breadcrumb: {
                            name: this.state.dataForm.name,
                            surname: this.state.dataForm.surname,
                        }
                    });
                } catch (error) {
                    this.errorFromServer(error.response.data.errors ? error.response.data.errors : { status: [error] });
                }
            } else {
                try {
                    const { data: { id }} = await userService.createUser(formData);
                    formData = new FormData();
                    this.props.history.push(`/settings/user/${id}`);
                } catch (error) {
                    this.errorFromServer(error.response.data.errors ? error.response.data.errors : { status: [error] });
                }
            }
        }
    };

    errorFromServer = object => {
        let serverErrorKeys = Object.keys(object);

        serverErrorKeys.forEach(async (item) => {
            await this.save({ errors: { ...this.state.errors, [item]: true }});

            NotificationService.error({
                title:   'error',
                message: object[item],
                remove:  false,
            });
        });
    };

    handleChange = async (selectedOption) => {
        await this.save({ selectedOption, formPermission: !this.state.formPermission });
    };

    setToState = async (name, value) => {
        await this.save({ dataForm: { ...this.state.dataForm, [name]: value }});
        await this.changeState();
    };

    changeBirth = async (date) => {
        const birthDate = moment(date).format('YYYY-MM-DD');
        await this.save({ dataForm: { ...this.state.dataForm, 'birth_date': birthDate }});
        await this.changeState();
    };

    goBack = (e) => {
        e.preventDefault();
        if ( this.props?.history?.length > 2) {
            this.props.history?.goBack();
        } else {
            this.props.history?.push('/');
        }
    };

    returnErrorTextClass = (className) => {
        if (has(this.state.errorForm, className)) {
            return true;
        }

        return false;
    };

    returnErrorMsg = (className) => {
        if (has(this.state.errorForm, className)) {
            return this.state.errorForm[className].message[0];
        }

        return "";
    };

    scheduleChange = async (schedule) => {
        await this.save({ dataForm: { ...this.state.dataForm, calendar: schedule }});
        await this.changeState();
    };

    vacationChange = async (vacation) => {
        const vac = this.state.dataForm.user_not_work.slice();

        vac.push({ from: this.formatDate(vacation[0]), to: this.formatDate(vacation[1]), usage: true });
        await this.save({ dataForm: { ...this.state.dataForm, 'user_not_work': vac }, date: [new Date(), new Date()]});
        await this.changeState();
    };

    vacationDelete = async (id) => {
        const vac = this.state.dataForm.user_not_work.slice();

        vac.splice(id, 1);
        await this.save({ dataForm: { ...this.state.dataForm, 'user_not_work': vac }});
        await this.changeState();
    };

    clearErrorForClick = async (name) => {
        await this.save({ errors: { ...this.state.errors, [name]: false }});
    };

    sendCurrentLangToSelect = () => {
        if (this.state?.dataForm?.preference_lang_id) {
            switch (this.state?.dataForm?.preference_lang_id) {
                case 133:
                    return "ru";
                case 169:
                    return "uk";
                case 40:
                    return "en";
                default:
                    return 'ru';
            }
        }
    };

    checkPassword = async (name, value) => {
        if (name === 'password') {
            await this.save({ dataForm: { ...this.state.dataForm, password: value }});
        }
        if (name === 'password_confirmation') {
            await this.save({ dataForm: { ...this.state.dataForm, password_confirmation: value }});
        }
        await this.changeState();
    };

    renderLanguagesSelect = (name, {item, items}) => {
        const values = this.state?.language;
        const selected = this.state?.selectedLanguages;
        const errors = this.state?.errors;
        const valueDefault = false;
        const options = {
            SelectProps:    {
                className: [
                    'magic-multi-select__select',
                    selected.length ? 'magic-multi-select__select_not-empty' : '',
                    errors.languages ? 'error' : ''],
            },
            disabled:       false,
            selectedValues: selected,
            valueDefault,
            values:         values.map((data) => data.id).filter(item => !selected.includes(item)) ?? [],

            valueToLabel: (id) => {
                if (id === valueDefault) {
                    return this.props.translate('managerPage_languages');
                }
                const find = values.find((data) => id === data.id);

                return find ? find.name : '?';
            },

            ItemProps: (value) => value === valueDefault ? { disabled: true } : {},

            onSelect: async (index) => {
                this.clearErrorForClick('languages');
                await this.save((state) => ({
                    selectedLanguages: [...state.selectedLanguages, index],
                    languagesData:     [...state.selectedLanguages, index],
                    dataForm:          { ...this.state.dataForm, languages: [...state.selectedLanguages, index] },
                }));
                await this.changeState();
            },
            onUnSelect: async (index) => {
                this.clearErrorForClick('languages');
                await this.save((state) => ({
                    selectedLanguages: [...state.selectedLanguages].filter((item) => item !== index),
                    dataForm:          { ...this.state.dataForm, languages: [...state.selectedLanguages].filter((item) => item !== index) },
                }));
                await this.changeState();
            },
            onClick: (value) => console.log(value),
        };
        if (this.props.match.path === "/settings/profile") {
            if (item.access('updateProfile')) {
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_languages`)}</Label>
                        <MagicMultiSelect { ...options } />
                    </FormGroup>
                );
            } else if (item.access('profile')) {
                const disabledOptions = { ...options, disabled: true };
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_languages`)}</Label>
                        <MagicMultiSelect { ...disabledOptions } />
                    </FormGroup>
                );
            } else {
                return null;
            }
        } else {
            if (item.access('update', 'show')) {
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_languages`)}</Label>
                        <MagicMultiSelect { ...options } />
                    </FormGroup>
                );
            } else if (item.access('show')) {
                const disabledOptions = { ...options, disabled: true };
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_languages`)}</Label>
                        <MagicMultiSelect { ...disabledOptions } />
                    </FormGroup>
                );
            } else {
                return null;
            }
        }
    };

    renderAccessRights = (name, { item, items }) => {
        const valueDefault = false;
        const values = this.state?.permission_template.data ?? [];
        const options = {
            className: [this.state?.errors?.permission_template_id ? 'error' : ''],
            onChange: async (value) => {
                await this.save({ dataForm: { ...this.state.dataForm, permission_template_id: value }});
                await this.changeState();
                this.clearErrorForClick('permission_template_id');
            },
            value:        this.state.dataForm.permission_template_id,
            values:       values?.map?.((data) => data.id) ?? [],
            valueToLabel: (id) => {
                if (id === valueDefault) {
                    return this.props.translate(`managerPage_template_permission`);
                }
                if (values?.length) {
                    const find = values?.find((data) => id === data.id);

                    return find ? find.name : '';
                }
            },
        };

        if (this.props.match.path === "/settings/profile") {
            if (item.access('updateProfile')) {
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_template_permission`)}</Label>
                        <MagicSelect { ...options } />
                    </FormGroup>
                );
            } else if (item.access('profile')) {
                const disabledOption = { ...options, disabled: true };
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_template_permission`)}</Label>
                        <MagicSelect { ...disabledOption } />
                    </FormGroup>
                );
            } else {
                return null;
            }
        } else {
            if (item.access('update', 'show')) {
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_template_permission`)}</Label>
                        <MagicSelect { ...options } />
                    </FormGroup>
                );
            } else if (item.access('show')) {
                const disabledOption = { ...options, disabled: true };
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_template_permission`)}</Label>
                        <MagicSelect { ...disabledOption } />
                    </FormGroup>
                );
            } else {
                return null;
            }
        }
    };

    validationFields = async () => {
        try {
            const stateErrors = [];
            if (this.props.match.path === "/settings/userCreate") {
                const userCreateErrorsFields = Object.fromEntries(
                    [
                        'name',
                        'surname',
                        'throughput',
                        'email',
                        'phone',
                        'password',
                        'permission_template_id',
                        'languages',
                        'status',
                    ].filter((field) => this.state.dataForm[field] === null || this.state.dataForm[field] === '' || this.state.dataForm[field].length === 0)
                        .map((field) => [field, true]),
                );
                if (Object.keys(userCreateErrorsFields).length) {
                    await this.save({ errors: userCreateErrorsFields });
                    throw this.props.translate(`managerPage_error`);
                }
            }
            const errorsFields = Object.fromEntries(
                [
                    'name',
                    'surname',
                    'throughput',
                    'email',
                    'phone',
                    'languages',
                    'status',
                ].filter((field) => this.state.dataForm[field] === null || this.state.dataForm[field] === '' || this.state.dataForm[field].length === 0)
                    .map((field) => [field, true]),
            );
            if (Object.keys(errorsFields).length) {
                await this.save({ errors: errorsFields });
                throw this.props.translate(`managerPage_error`);
            }

            if (!this.validatePass()) {
                await this.save((state) => ({
                    errors: { ...state.errors, password: true },
                }));
                throw this.props.translate(`managerPage_pass_error`);
            } else if (this.state.dataForm.password !== this.state.dataForm.password_confirmation) {
                await this.save((state) => ({
                    errors: { ...state.errors, password: true },
                }));
                throw this.props.translate(`managerPage_confirm_pass_error`);
            }

            Object.keys(this.state.errors).forEach(item => {
                if (this.state.errors[item] === true) {
                    stateErrors.push(item);
                }
            });

            if (stateErrors.length) {
                return false;
            } else {
                await this.save({ errors: {}});
                return true;
            }
        } catch (error) {
            NotificationService.error({
                title:   'error',
                message: error,
                remove:  false,
            });
        }
    };

    validatePass = () => {
        return (/^[a-zA-Z0-9]{8,30}$/).test(this.state.dataForm.password);
    };

    setSwitchValue = async (stateKey, dataKey, checked) => {
        const data = checked === true ? 1 : checked === false ? 2 : 2;
        await this.save({ dataForm: { ...this.state.dataForm, adaptation: data }});
        await this.changeState();
    };

    renderAdaptation = (name, { item, items }) => {
        const options = {
            checked: this.isAdaptation,
            // onClick: (event) => this.isAdaptation = event.target.checked,
            onChange: (event) => this.isAdaptation = event.target.checked,
        };
        if (this.props.match.path === "/settings/profile") {
            if (item.access('updateProfile')) {
                return (
                    this.renderSwitch({ ...options })
                );
            } else if (item.access('profile')) {
                const disabledOptions = { ...options, disabled: true };
                return (
                    this.renderSwitch({ ...disabledOptions })
                );
            } else {
                return null;
            }
        } else {
            if (item.access('update', 'show')) {
                return (
                    this.renderSwitch({ ...options })
                );
            } else if (item.access('show')) {
                const disabledOptions = { ...options, disabled: true };
                return (
                    this.renderSwitch({ ...disabledOptions })
                );
            } else {
                return null;
            }
        }
    };

    renderSwitch = (options) => (
        <FormGroup>
            <Label>{this.props.translate(`managerPage_adaptation`)}</Label>
            <div className='block-header_input-checkbox' style={{
                position:  'relative',
                width:     '20px',
                height:    '20px',
                textAlign: 'center',
                top:       0,
                right:     0,
                display:   'inline-block',
            }}>
                <label className='checkbox'>
                    <input {...{ type: 'checkbox', name: 'adaptation', ...options, }} />
                    <span style={{ 'marginRight': '24px' }}/>
                </label>
            </div>
        </FormGroup>
    );

    renderFirstName = (name, { item, items }) => {
        const options = {
            className: [this.state?.errors?.name ? 'error' : ''],
            value: this.state?.dataForm?.name ?? '',
            onChange: value => this.setToState('name', value),
            onValid: value => {
                if ((value !== '' && value.length > 255)) {
                    throw new Error('error');
                }
            },
            onClick: () => this.clearErrorForClick('name'),
        };
        if (this.props.match.path === "/settings/profile") {
            if (item.access('updateProfile')) {
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_first_name`)}</Label>
                        <MagicInput { ...options } />
                    </FormGroup>
                );
            } else if (item.access('profile')) {
                const disabledOptions = { ...options, disabled: true };
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_first_name`)}</Label>
                        <MagicInput { ...disabledOptions } />
                    </FormGroup>
                );
            } else {
                return null;
            }
        } else {
            if (item.access('update')) {
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_first_name`)}</Label>
                        <MagicInput { ...options } />
                    </FormGroup>
                );
            } else if (item.access('show')) {
                const disabledOptions = { ...options, disabled: true };
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_first_name`)}</Label>
                        <MagicInput { ...disabledOptions } />
                    </FormGroup>
                );
            } else {
                return null;
            }
        }
    };

    renderLastName = (name, { item, items }) => {
        const options = {
            className: [this.state?.errors?.surname ? 'error' : ''],
            value: this.state?.dataForm?.surname ?? '',
            onChange: value => this.setToState('surname', value),
            onValid: value => {
                if ((value !== '' && value.length > 255)) {
                    throw new Error('error');
                }
            },
            onClick: () => this.clearErrorForClick('surname'),
        };
        if (this.props.match.path === "/settings/profile") {
            if (item.access('updateProfile')) {
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_last_name`)}</Label>
                        <MagicInput { ...options } />
                    </FormGroup>
                );
            } else if (item.access('profile')) {
                const disabledOptions = { ...options, disabled: true };
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_last_name`)}</Label>
                        <MagicInput { ...disabledOptions } />
                    </FormGroup>
                );
            } else {
                return null;
            }
        } else {
            if (item.access('update')) {
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_last_name`)}</Label>
                        <MagicInput { ...options } />
                    </FormGroup>
                );
            } else if (item.access('show')) {
                const disabledOptions = { ...options, disabled: true };
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_last_name`)}</Label>
                        <MagicInput { ...disabledOptions } />
                    </FormGroup>
                );
            } else {
                return null;
            }
        }
    };

    renderAvatar = (name, { item, items }) => {
        let { imagePreviewUrl } = this.state;
        imagePreviewUrl = imagePreviewUrl !== '' ? imagePreviewUrl : defaultAvatar;
        if (this.props.match.path === "/settings/profile") {
            if (item.access('updateProfile')) {
                return (
                    <FormGroup className='upload-image'>
                        <Label>{this.props.translate(`managerPage_image_profile`)}</Label>
                        <Label className='upload-image'>
                            <Input type='file' onChange={(e) => this.handleImageChange(e, this.props.translate)}/>
                            <div className='upload-image-wrapper'>
                                <img alt='avatar' src={this.state?.avatar ? this.state.avatar : imagePreviewUrl}/>
                            </div>
                            <div className='button-upload-wrapper'>
                                <div className='button-upload-image'>{this.props.translate(`managerPage_choose_image`)}</div>
                                <span className='button-upload-hint'>{this.props.translate(`managerPage_photo_up`)}</span>
                            </div>
                        </Label>
                        {this.returnErrorTextClass('avatar') ?
                            <div className='has-error'>
                                <div className='error-message'>{this.returnErrorMsg('avatar')}</div>
                            </div>
                            : ''
                        }
                    </FormGroup>
                );
            } else if (item.access('profile')) {
                return (
                    <FormGroup className='upload-image'>
                        <Label>{this.props.translate(`managerPage_image_profile`)}</Label>
                        <Label className='upload-image'>
                            <div className='upload-image-wrapper'>
                                <img alt='avatar' src={this.state?.avatar ? this.state.avatar : imagePreviewUrl}/>
                            </div>
                        </Label>
                    </FormGroup>
                );
            } else {
                return null;
            }
        } else {
            if (item.access('update')) {
                return (
                    <FormGroup className='upload-image'>
                        <Label>{this.props.translate(`managerPage_image_profile`)}</Label>
                        <Label className='upload-image'>
                            <Input type='file' onChange={(e) => this.handleImageChange(e, this.props.translate)}/>
                            <div className='upload-image-wrapper'>
                                <img alt='avatar' src={this.state?.avatar ? this.state.avatar : imagePreviewUrl}/>
                            </div>
                            <div className='button-upload-wrapper'>
                                <div className='button-upload-image'>{this.props.translate(`managerPage_choose_image`)}</div>
                                <span className='button-upload-hint'>{this.props.translate(`managerPage_photo_up`)}</span>
                            </div>
                        </Label>
                        {this.returnErrorTextClass('avatar') ?
                            <div className='has-error'>
                                <div className='error-message'>{this.returnErrorMsg('avatar')}</div>
                            </div>
                            : ''
                        }
                    </FormGroup>
                );
            } else if (item.access('show')) {
                return (
                    <FormGroup className='upload-image'>
                        <Label>{this.props.translate(`managerPage_image_profile`)}</Label>
                        <Label className='upload-image'>
                            <div className='upload-image-wrapper'>
                                <img alt='avatar' src={this.state?.avatar ? this.state.avatar : imagePreviewUrl}/>
                            </div>
                        </Label>
                    </FormGroup>
                );
            } else {
                return null;
            }
        }
    };

    renderBirthDate = (name, {item, items}) => {
        if (this.props.match.path === "/settings/profile") {
            if (item.access('updateProfile')) {
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_birth_date`)}</Label>
                        <BirthDatepicker changeBirth={this.changeBirth} birth={this.state?.dataForm?.birth_date ?? ''} />
                    </FormGroup>
                );
            } else if (item.access('profile')) {
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_birth_date`)}</Label>
                        <BirthDatepicker changeBirth={this.changeBirth} birth={this.state?.dataForm?.birth_date ?? ''} disabled={ true } />
                    </FormGroup>
                );
            } else {
                return null;
            }
        } else {
            if (item.access('update')) {
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_birth_date`)}</Label>
                        <BirthDatepicker changeBirth={this.changeBirth} birth={this.state?.dataForm?.birth_date ?? ''} />
                    </FormGroup>
                );
            } else if (item.access('show')) {
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_birth_date`)}</Label>
                        <BirthDatepicker changeBirth={this.changeBirth} birth={this.state?.dataForm?.birth_date ?? ''} disabled={ true } />
                    </FormGroup>
                );
            } else {
                return null;
            }
        }
    };

    renderOpportunity = (name, {item, items}) => {
        const options = {
            className: [this.state?.errors?.throughput ? 'error' : ''],
            value:     String(this.state?.dataForm?.throughput ?? ''),
            onChange: value => this.setToState('throughput', value),
            onValid: value => {
                if ((value !== '' && !(Number(value) > 0)) || value > 999) {
                    throw new Error('error');
                }
            },
            onClick: () => this.clearErrorForClick('throughput'),
        };
        if (this.props.match.path === "/settings/profile") {
            if (item.access('updateProfile')) {
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_capacity_of_opportunity`)}</Label>
                        <MagicInput { ...options } />
                    </FormGroup>
                );
            } else if (item.access('profile')) {
                const disabledOptions = { ...options, disabled: true };
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_capacity_of_opportunity`)}</Label>
                        <MagicInput { ...disabledOptions } />
                    </FormGroup>
                );
            } else {
                return null;
            }
        } else {
            if (item.access('update')) {
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_capacity_of_opportunity`)}</Label>
                        <MagicInput { ...options } />
                    </FormGroup>
                );
            } else if (item.access('show')) {
                const disabledOptions = { ...options, disabled: true };
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_capacity_of_opportunity`)}</Label>
                        <MagicInput { ...disabledOptions } />
                    </FormGroup>
                );
            } else {
                return null;
            }
        }
    };

    renderEmail = (name, {item, items}) => {
        const options = {
            className: [this.state?.errors?.email ? 'error' : ''],
            value: this.state?.dataForm?.email ?? '',
            onChange: value => this.setToState('email', value),
            onValid: value => {
                if ((value !== '' && value.indexOf('/@/') !== -1)) {
                    throw new Error('error');
                }
            },
            onClick: () => this.clearErrorForClick('email'),
        };
        if (this.props.match.path === "/settings/profile") {
            if (item.access('updateProfile')) {
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_email`)}</Label>
                        <MagicInput { ...options } />
                    </FormGroup>
                );
            } else if (item.access('profile')) {
                const disabledOptions = { ...options, disabled: true };
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_email`)}</Label>
                        <MagicInput { ...disabledOptions } />
                    </FormGroup>
                );
            } else {
                return null;
            }
        } else {
            if (item.access('update')) {
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_email`)}</Label>
                        <MagicInput { ...options } />
                    </FormGroup>
                );
            } else if (item.access('show')) {
                const disabledOptions = { ...options, disabled: true };
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_email`)}</Label>
                        <MagicInput { ...disabledOptions } />
                    </FormGroup>
                );
            } else {
                return null;
            }
        }
    };

    renderPhone = (name, {item, items}) => {
        const options = {
            className: [this.state?.errors?.phone ? 'error' : ''],
            value: this.state?.dataForm?.phone ?? '',
            onChange: value => this.setToState('phone', value),
            onValid: value => {
                if (value !== '' && !(Number(value) > 0)) {
                    throw new Error('error');
                }
            },
            onClick: () => this.clearErrorForClick('phone'),
        };
        if (this.props.match.path === "/settings/profile") {
            if (item.access('updateProfile')) {
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_phone`)}</Label>
                        <MagicInput { ...options } />
                    </FormGroup>
                );
            } else if (item.access('profile')) {
                const disabledOptions = { ...options, disabled: true };
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_phone`)}</Label>
                        <MagicInput { ...disabledOptions } />
                    </FormGroup>
                );
            } else {
                return null;
            }
        } else {
            if (item.access('update')) {
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_phone`)}</Label>
                        <MagicInput { ...options } />
                    </FormGroup>
                );
            } else if (item.access('show')) {
                const disabledOptions = { ...options, disabled: true };
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_phone`)}</Label>
                        <MagicInput { ...disabledOptions } />
                    </FormGroup>
                );
            } else {
                return null;
            }
        }
    };

    renderPassword = (name, {item, items}) => {
        const passwordType = { type: 'password', autoComplete: 'new-password' };
        if (this.props.match.path === "/settings/profile") {
            if (item.access('updateProfile')) {
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_password`)}</Label>
                        <MagicInput
                            className={[this.state?.errors?.password ? 'error' : '']}
                            value={ this.state?.dataForm?.password ?? '' }
                            onChange={value => this.checkPassword('password', value)}
                            onClick={() => this.clearErrorForClick('password')}
                            InputProps={passwordType}
                        />
                    </FormGroup>
                );
            } else {
                return null;
            }
        } else {
            if (item.access('update')) {
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_password`)}</Label>
                        <MagicInput
                            className={[this.state?.errors?.password ? 'error' : '']}
                            value={ this.state?.dataForm?.password ?? '' }
                            onChange={value => this.checkPassword('password', value)}
                            onClick={() => this.clearErrorForClick('password')}
                            InputProps={passwordType}
                        />
                    </FormGroup>
                );
            } else {
                return null;
            }
        }
    };

    renderPasswordConf = (name, {item, items}) => {
        const passwordType = { type: 'password', autoComplete: 'new-password' };
        if (this.props.match.path === "/settings/profile") {
            if (item.access('updateProfile')) {
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_password_conf`)}</Label>
                        <MagicInput
                            className={[this?.state?.errors?.password ? 'error' : '']}
                            onChange={value => this.checkPassword('password_confirmation', value)}
                            value={ this.state?.dataForm?.password_confirmation ?? '' }
                            onClick={() => this.clearErrorForClick('password_confirmation')}
                            InputProps={passwordType}
                        />
                    </FormGroup>
                );
            } else {
                return null;
            }
        } else {
            if (item.access('update')) {
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_password_conf`)}</Label>
                        <MagicInput
                            className={[this?.state?.errors?.password ? 'error' : '']}
                            onChange={value => this.checkPassword('password_confirmation', value)}
                            value={ this.state?.dataForm?.password_confirmation ?? '' }
                            onClick={() => this.clearErrorForClick('password_confirmation')}
                            InputProps={passwordType}
                        />
                    </FormGroup>
                );
            } else {
                return null;
            }
        }
    };

    renderSchedule = (name, {item, items}) => {
        const options = {
            scheduleChange: this.scheduleChange,
            weekSchedule:   this.state?.dataForm?.calendar,
            translate:      this.props.translate,
        };
        if (this.props.match.path === "/settings/profile") {
            if (item.access('updateProfile')) {
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_schedule`)}</Label>
                        <ManagerScheduleTable { ...options } />
                    </FormGroup>
                );
            } else if (item.access('profile')) {
                const disabledOptions = { ...options, disabled: true };
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_schedule`)}</Label>
                        <ManagerScheduleTable { ...disabledOptions } />
                    </FormGroup>
                );
            } else {
                return null;
            }
        } else {
            if (item.access('update')) {
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_schedule`)}</Label>
                        <ManagerScheduleTable { ...options } />
                    </FormGroup>
                );
            } else if (item.access('show')) {
                const disabledOptions = { ...options, disabled: true };
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_schedule`)}</Label>
                        <ManagerScheduleTable { ...disabledOptions } />
                    </FormGroup>
                );
            } else {
                return null;
            }
        }
    };

    renderVacation = (name, {item, items}) => {
        const options = {
            clearIcon:         null,
            calendarIcon:      null,
            selectRange:       true,
            onChange:          this.vacationChange,
            value:             this.state.date,
            showLeadingZeros:  true,
            name:              'range',
            className:         ['vacation-picker'],
            calendarClassName: ['calendar-class-name'],
        };
        if (item.access('updateProfile') || item.access('store')) {
            return (
                <React.Fragment>
                    <FormGroup>
                        <div className="datepicker-block">
                            <div className="datepicker-label">{this.props.translate(`managerPage_vacation`)}</div>
                            <DateRangePicker { ...options } />
                        </div>
                        { this.renderVacationList() }
                    </FormGroup>
                    { this.renderVacationPagination() }
                </React.Fragment>
            );
        } else if (item.access('profile') || item.access('show')) {
            const disabledOptions = { ...options, disabled: true };
            return (
                <React.Fragment>
                    <FormGroup>
                        <div className="datepicker-block">
                            <div className="datepicker-label">{this.props.translate(`managerPage_vacation`)}</div>
                            <DateRangePicker { ...disabledOptions } />
                        </div>
                        { this.renderVacationList() }
                    </FormGroup>
                    { this.renderVacationPagination() }
                </React.Fragment>
            );
        } else {
            return null;
        }
    };

    renderVacationList = () => {
        return (
            <ul className='vacation-list'>
                {
                    map(this.state?.dataForm?.user_not_work?.slice?.(this.pageId() * this.state.pages.take, (this.pageId() + 1) * this.state.pages.take), (vacation, key) => {
                        return (
                            <li key={ key }>
                                <span>Vacation {vacation.from} - {vacation.to}</span>
                                <span onClick={ () => this.vacationDelete(key) }><i className = 'la la-times' /></span>
                            </li>
                        );
                    })
                }
            </ul>
        );
    };

    renderVacationPagination = () => {
        return (this.state?.dataForm?.user_not_work?.length > 5) ? <PagePanel
            filter={ this.state?.dataForm?.user_not_work?.length }
            take={ this.state?.pages?.take }
            total={ this.state?.dataForm?.user_not_work?.length }
            variance={ this.state?.pages?.variance }
            page={ this.pageId() }
            onClick={ this.onPageClick }
            doStatus={ () => '' }
        /> : null;
    };

    pageId = () => {
        return +this.state?.pages?.pageId || 0;
    };

    onPageClick = async ({ event, pageId }) => {
        event.preventDefault();
        const setPageId = async (pageId) => {
            await this.save((state) => ({ pages: { ...state.pages, pageId }}));
        };
        await setPageId(pageId ?? this.state?.pages?.pageId);
    };

    renderDepatments = (name, {item, items}) => {
        let departments = '';
        try {
            if (this.props.match.path === "/settings/profile") {
                departments = this.state.profile?.data?.tree_info ?? [];
                departments = departments.map(item => item.department_name).join('; ');
            } else {
                departments = this.state.departments ?? [];
                departments = departments.map(item => item.name).join('; ');
            }
        } catch (error) {
            console.log(error);
        }
        if (this.props.match.path === "/settings/profile") {
            if (item.access('profile')) {
                return (
                    (departments) ? <FormGroup>
                        <Label>{this.props.translate(`managerPage_department`)}</Label>
                        {
                            <MagicInput
                                value = { departments }
                                onChange = {value => console.log(value)}
                                disabled={true}
                            />
                        }
                    </FormGroup> : null
                );
            } else {
                return null;
            }
        } else {
            if (item.access('show')) {
                return (
                    (departments) ? <FormGroup>
                        <Label>{this.props.translate(`managerPage_department`)}</Label>
                        {
                            <MagicInput
                                value = { departments }
                                onChange = {value => console.log(value)}
                                disabled={true}
                            />
                        }
                    </FormGroup> : null
                );
            } else {
                return null;
            }
        }
    };

    renderSelectStatus = (name, {item, items}) => {
        const values = this.state.enums?.data?.core?.UserStatusEnum ? Object.values(this.state.enums?.data?.core?.UserStatusEnum) : [];
        const options = {
            className: [this.state?.errors?.status ? 'error' : ''],
            onChange: async (value) => {
                const currentValue = values.indexOf(value) + 1;
                await this.save({ dataForm: { ...this.state.dataForm, status: currentValue }});
                await this.clearErrorForClick('status');
                await this.changeState();
            },
            onClick: () => this.clearErrorForClick('status'),
            value:        values[this.state?.dataForm?.status -1] ?? '',
            values:       values.map((data) => data) ?? [],
            valueToLabel: (name) => {
                if (values) {
                    const find = name;

                    return find ? find : '';
                }
            },
        };
        if (this.props.match.path === "/settings/profile") {
            if (item.access('updateProfile')) {
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_status`)}</Label>
                        <MagicSelect { ...options } />
                    </FormGroup>
                );
            } else if (item.access('profile')) {
                const disableOptions = { ...options, disabled: true };

                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_status`)}</Label>
                        <MagicSelect { ...disableOptions } />
                    </FormGroup>
                );
            } else {
                return null;
            }
        } else {
            if (item.access('update')) {
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_status`)}</Label>
                        <MagicSelect { ...options } />
                    </FormGroup>
                );
            } else if (item.access('show')) {
                const disableOptions = { ...options, disabled: true };

                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_status`)}</Label>
                        <MagicSelect { ...disableOptions } />
                    </FormGroup>
                );
            } else {
                return null;
            }
        }
    };

    renderButton = (name, {item, items}) => {
        const optionsBtnSave = {
            className: ['button turquoise', this.state?.newChanges ? '' : 'disabled'],
            onClick:   (e) => this.sendData(e),
            disabled:  !this.state?.newChanges,
        };
        const optionsBtnCancel = {
            className: ['button bordered turquoise'],
            onClick:   (e) => this.goBack(e),
        };
        if (this.props.match.path === "/settings/profile") {
            if (item.access('updateProfile')) {
                return (
                    <div className='button-area justify-end'>
                        <MagicButton { ...optionsBtnSave }>{this.state.userId ? this.props.translate(`managerPage_save`) : this.props.translate(`managerPage_create`)}</MagicButton>
                        <MagicButton { ...optionsBtnCancel }>{this.props.translate(`managerPage_cancel`)}</MagicButton>
                    </div>
                );
            } else {
                return (
                    <div className='button-area justify-end'>
                        <MagicButton { ...optionsBtnCancel }>{this.props.translate(`managerPage_cancel`)}</MagicButton>
                    </div>
                );
            }
        } else {
            if (item.access('update')) {
                return (
                    <div className='button-area justify-end'>
                        <MagicButton { ...optionsBtnSave }>{this.state.userId ? this.props.translate(`managerPage_save`) : this.props.translate(`managerPage_create`)}</MagicButton>
                        <MagicButton { ...optionsBtnCancel }>{this.props.translate(`managerPage_cancel`)}</MagicButton>
                    </div>
                );
            } else if (item.access('show')) {
                return (
                    <div className='button-area justify-end'>
                        <MagicButton { ...optionsBtnSave }>{this.state.userId ? this.props.translate(`managerPage_save`) : this.props.translate(`managerPage_create`)}</MagicButton>
                        <MagicButton { ...optionsBtnCancel }>{this.props.translate(`managerPage_cancel`)}</MagicButton>
                    </div>
                );
            } else {
                return (
                    <div className='button-area justify-end'>
                        <MagicButton { ...optionsBtnCancel }>{this.props.translate(`managerPage_cancel`)}</MagicButton>
                    </div>
                );
            }
        }
    };

    renderInternalPhone = (name, {item, items}) => {
        const options = {
            className: [this.state?.errors?.internal_phone ? 'error' : ''],
            value: this.state?.dataForm?.internal_phone ?? '',
            onChange: value => this.setToState('internal_phone', value),
            onValid: value => {
                if (value !== '' && !(Number(value) > 0)) {
                    throw new Error('error');
                }
            },
            onClick: () => this.clearErrorForClick('internal_phone'),
        };
        if (this.props.match.path === "/settings/profile") {
            if (item.access('updateProfile')) {
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_internal_phone`)}</Label>
                        <MagicInput { ...options } />
                    </FormGroup>
                );
            } else if (item.access('profile')) {
                const disabledOptions = { ...options, disabled: true };
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_internal_phone`)}</Label>
                        <MagicInput { ...disabledOptions } />
                    </FormGroup>
                );
            } else {
                return null;
            }
        } else {
            if (item.access('update')) {
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_internal_phone`)}</Label>
                        <MagicInput { ...options } />
                    </FormGroup>
                );
            } else if (item.access('show')) {
                const disabledOptions = { ...options, disabled: true };
                return (
                    <FormGroup>
                        <Label>{this.props.translate(`managerPage_internal_phone`)}</Label>
                        <MagicInput { ...disabledOptions } />
                    </FormGroup>
                );
            } else {
                return null;
            }
        }
    };

    renderBreadCrumbs = () => {
        return (
            this.props.match.path === "/settings/profile" ? <div className='breadcrumbs'><Link to='/settings/profile'>{this.props.translate(`managerPage_profile`)} </Link> > {`${this.state.breadcrumb.name ?? ''} ${this.state.breadcrumb.surname ?? ''}`}</div>
                : this.props.match.path !== "/settings/userCreate" ? <div className = 'breadcrumbs'><Link to = '/settings'>{this.props.translate(`managerPage_admin_panel`)} </Link> > <Link to = '/settings'>{this.props.translate(`managerPage_user_list`)} </Link> >&nbsp;
                    {`${this.state.breadcrumb.name ?? ''} ${this.state.breadcrumb.surname ?? ''}`}
                </div> : <div className = 'breadcrumbs'><Link to = '/settings'>{this.props.translate(`managerPage_admin_panel`)} </Link> > {this.props.translate(`managerPage_new_user`)}</div>
        );
    };

    renderNotificationButton = () => {
        const { translate } = this.props;

        return (
            this.props.match.path === "/settings/profile"
                ? <div className="notification-top-button">
                    <Link className="magic-button button active" to='/settings/profile'>{translate('managerPage_account')}</Link>
                    <Link className="magic-button button" to='/settings/profile/notifications'>{translate('events_notifications')}</Link>
                </div>
                : <div></div>
        );
    };


    render () {
        if (!this.state.loaded && this.props.match.path !== "/settings/userCreate") {
            return <Preloader scale = { this.props.scale } />;
        }
        const permissionData = PermissionService.cast(this.state.dataForm, this.configUser);

        return (
            <React.Fragment>
                <div className='my-profile-notification-wrapper'>
                    {/*{this.state.formPermission ? <IndexTasks closePermission={this.togglePermission} userId={this.state.userId} userPermissionData={this.state.userId}/> : null}*/}
                    <div className='top-page-block'>
                        <div className='title-wrapper'>
                            <h1 className='page-title'>{this.props.translate(`managerPage_profile`)}</h1>
                            { this.renderBreadCrumbs() }
                        </div>
                    </div>
                    {this.renderNotificationButton()}
                    <div className='bottom-content-block'>
                        <Form className='manager-form'>
                            <div className='manager-form-header'>
                                <img src={lock} alt="lock"/>
                                {this.props.translate(`managerPage_settings`)}
                            </div>
                            <Row>
                                <Col md='4' xs='12'>
                                    {permissionData.userName}
                                    {permissionData.userLastName}
                                    {permissionData.userAvatar}
                                    {permissionData.userBirthDate}
                                    {permissionData.userThroughput}
                                    {permissionData.userAdaptation}
                                    {permissionData.userStatus}
                                </Col>
                                <Col md='4' xs='12'>
                                    {permissionData.userEmail}
                                    {permissionData.userPhone}
                                    {permissionData.userPassword}
                                    {permissionData.userPasswordConf}
                                    {permissionData.userPermissionTemplateId}
                                    {permissionData.userInternalPhone}
                                    {permissionData.userDepartments}
                                </Col>
                                <Col md='4' xs='12'>
                                    {permissionData.userSchedule}
                                    {permissionData.userVacantion}
                                    {permissionData.userLanguages}
                                </Col>
                            </Row>
                            { this.props.match.path === "/settings/profile" ? <div className='manager-form-header'><img src={lock} alt="lock"/>{this.props.translate(`managerPage_interface`)}</div> : null }
                            <Row>
                                <Col md='6' xs='12'>
                                    <FormGroup>
                                        <Label>{this.props.translate(`managerPage_interface`)}</Label>
                                        <div className='select-right'>
                                            <LangSelect
                                                className='filter-select'
                                                classNamePrefix='select'
                                                changeState={ this.state.newChanges }
                                                changeLang={this.changeState}
                                                saveState={ this.save }
                                                savePreferenceLang = { this.setPreferenceLangId }
                                                sendCurrentLangToSelect = { this.sendCurrentLangToSelect() }
                                                profileId = { this.state?.profile?.data?.id === +this.state.userId }
                                                checkProfilePage={ this.props.match.path === "/settings/profile" }
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md='6' xs='12'>
                                    {permissionData.userButton}
                                </Col>
                            </Row>
                            <img alt='background' className='form-background' src={background}/>
                        </Form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withLocalize(withRouter(ManagerCreateForm));
