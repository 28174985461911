import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import { marketingService } from '../../../services/MarketingDataService';
import { MagicButton, MagicInput } from '../../../components/Magic';
import NotificationService from '../../../services/NotificationService';
import PermissionService from '../../../services/PermissionService';

class MarketingUtmLinksGeneration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        protocols: {
          name: 'http://',
          id: 'http://',
        },
      },
      searchItems: {
        channels: [],
        subChannels: [],
        products: [],
        protocols: [
           {
            name: 'http://',
            id: 'http://',
          }, {
            name: 'https://',
            id: 'https://',
          },
        ],
        isLoadingSubChannels: false,
      },
      isCopyBtn: true,
      errors: [],
    }
  };

  get utmLinkConfig () {
    return [ {
        key: 'utmLink',
        path: [ 'statistics', 'utm_link', ],
    }, ];
  };

  save = async state => new Promise(next => this.setState(state, next));

  async componentDidMount () {
    await this.loadData({ key: "channels" });
    await this.loadData({ key: "products" });
  };

  loadData = async ({ key }) => {
      const data =
        key === 'channels'
        ? await marketingService.getUtmSources()
        : key === 'products' ? await marketingService.getUtmCampaigns()
        : null;

    await this.save({
      searchItems: {
        ...this.state.searchItems,
        [key]: data.data,
      }
    })
  };

  onSelectChannelChange = async (value) => {
    if (!value) {
      await this.save({
        formData: {
          ...this.state.formData,
          channels: null,
          subChannels: null,
        }
      });
    } else {
      await this.save({
        searchItems: {
          ...this.state.searchItems,
          isLoadingSubChannels: true,
        },
      });
      const data = await marketingService.getUtmMediumsWithSource({ utm_source_id: value.id });
      await this.save({
        formData: {
          ...this.state.formData,
          channels: value,
        },
        searchItems: {
          ...this.state.searchItems,
          isLoadingSubChannels: false,
          subChannels: data.data,
        },
      });
    }
  };

  onSelectChange = key => async (value) => {
    await this.save({
      formData: {
        ...this.state.formData,
        [key]: value,
      },
    });
    this.state.formData?.channels === null ? await this.save({
      formData: {
        ...this.state.formData,
        subChannels: null,
      }
    }) : null
  };

  onChangeValue = async (website) => {
    await this.clearErrorClass('url');
    await this.save({
      formData: {
        ...this.state.formData,
        website,
      },
    })
  };

  generateUtmLink = async () => {
    try {
      const formData = {
        utm_source_id: this.state.formData.channels.id,
        ...this.state.formData?.subChannels?.id && { utm_medium_id: this.state.formData.subChannels.id, },
        utm_campaign_id: this.state.formData.products.id,
        url: `${this.state.formData.protocols.id}${this.state.formData.website}`
      }

      await marketingService.utmGenerateUtmLink(formData)
      .then(data => {
        this.save({
        formData: {
          ...this.state.formData,
          link: data.data,
        }
      })})
    } catch (e) {
      await this.setErrorsFromServer(e)
      console.error(e)
    }
  };

  optionsToolTip = (option) => {
    if (option.name.length >= 100) {
      return (
        <div className="bind-channel-option-content">
          {option.name.slice(0, 100)}...
          <div className="bind-channel-option-more-content bind-channel-option-more-content_generation-utm">
            {option.name}
          </div>
        </div>
      )
    } else {
      return option.name
    }
  };

  renderSelect = ({
    className,
    item,
    key,
    placeholder,
    options,
    isSearchable = true,
    isLoading = false,
    isDisabled = false,
    onChange,
    value,
  }) => {
    return (
      <div { ...{ ...className && { className }, } }>
        <Select
          maxMenuHeight={150}
          isLoading={isLoading}
          isSearchable={isSearchable ?? true}
          value={this.state.formData?.[key] ?? value}
          options={options ?? item[key] ?? []}
          isClearable={true}
          isDisabled={isDisabled}
          onChange={onChange}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => this.optionsToolTip(option)}
          placeholder={placeholder}
        />
      </div>
    )
  };

  generateBtn = () => {
    const conf = PermissionService.calc(this.utmLinkConfig);
    const generateBtnPermission = conf?.utmLink?.access('store') ?? false;

    if (generateBtnPermission) {
      return (
        <button
          disabled={!this.state.formData?.link}
          className="generate-links__copy-btn"
          onClick={() => navigator.clipboard.writeText(this.state.formData?.link)}
        />
      )
    }
  };

  setErrorsFromServer = async (error) => {
    NotificationService.errors({...error?.response?.data, time: 25000});
    let errors = Object?.keys?.(error?.response?.data?.errors)?.map(e => e?.split('.')?.[0]) ?? [];
    await this.save({ errors });
  };

  clearErrorClass = async (name) => {
    await this.save?.(state => ({errors: [...state?.errors?.filter(e => e !== name)],}));
  };

  renderSearch = () => {
    const item = this.state.searchItems;

    return (
      <div className="generate-links__items">
        <div className="generate-links__field_items">
          <div className="generate-links__field_item">
            <div className="generate-links__sitepanel">
              {this.renderSelect({
                className: `edit-channel_popup__field_item utm-link_searchable-items `,
                item,
                key: 'protocols',
                isSearchable: false,
                isLoading: false,
                onChange: this.onSelectChange('protocols'),
                value: this.state.formData.protocols,
              })}
              <div className="generate-links__website">
                <MagicInput
                  className={[this.state?.errors?.includes('url')
                    ? 'error-value' :
                    '']}
                  sizeable={false}
                  onChange={(value) => this.onChangeValue(value)}
                  value={this.state.formData.website ?? ''}
                  placeholder={this.props.translate(`marketing_write_website`)}
                />
              </div>
            </div>
            <p className="edit-channel_popup__field_items-text">
              {this.props.translate(`marketing_bind_channel`)}*
            </p>
            {this.renderSelect({
              className: 'utm-link_searchable-items',
              item,
              isClearable: true,
              key: 'channels',
              onChange: this.onSelectChannelChange,
              placeholder: this.props.translate(`marketing_generate_links_search-channel`),
            })}
          </div>
        </div>
        <div className="generate-links__field_items">
          <div className="edit-channel_popup__field_item">
            <p className="edit-channel_popup__field_items-text">
              {this.props.translate(`marketing_bind_subchannel`)}
            </p>
            {this.renderSelect({
              className: 'utm-link_searchable-items',
              item,
              isDisabled: !this.state.formData?.channels,
              isClearable: true,
              isLoading: this.state.searchItems.isLoadingSubChannels,
              value: this.state.formData?.subChannels,
              key: 'subChannels',
              onChange: this.onSelectChange('subChannels'),
              placeholder: this.props.translate(`marketing_generate_links_search-subchannel`),
            })}
          </div>
        </div>
        <div className="generate-links__field_items">
          <div className="edit-channel_popup__field_item">
            <p className="edit-channel_popup__field_items-text">
              {this.props.translate(`marketing_bind_product`)}*
            </p>
            {this.renderSelect({
              className: 'utm-link_searchable-items',
              item,
              key: 'products',
              onChange: this.onSelectChange('products'),
              placeholder: this.props.translate(`marketing_generate_links_search-product`),
            })}
          </div>
        </div>
      </div>
    );
  };

  render = () => {
    const { translate } = this.props;

    const checkUpdateState = this.state.formData.products
      && this.state.formData.channels
      && this.state.formData.website
      && this.state.formData.protocols;

    const generateBtnOptions = {
      className: [checkUpdateState ? 'magic-button_panels-apply' : 'disabled-apply-btn'],
      onClick: async () => await this.generateUtmLink(),
      disabled: !checkUpdateState,
    };

    return (
      <div className="generate-links">
        <p className={ 'edit-channel_popup__title' }>
          { translate( `marketing_generate_links_h1` ) }
        </p>
        <div className="generate-links__container">
          {this.renderSearch()}
          <div className="generate-links__button">
            <div>
              <MagicButton { ...generateBtnOptions }>
                {translate(`marketing_generate_utm_link_btn`)}
              </MagicButton>
            </div>
            <div className="generate-links__input">
              <p className="edit-channel_popup__field_items-text">
                { translate(`marketing_generate_links_input_result`) }
              </p>
              <div className="generate-links__input-block">
                {this.generateBtn()}
                <input
                  type="text"
                  className="generate-links__input-copy"
                  disabled={true}
                  value={this.state?.formData?.link ?? ''}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  };
}

export default withRouter(withLocalize(MarketingUtmLinksGeneration));
