import React, { Component }                  from "react";
import { withLocalize, }                     from "react-localize-redux";
import { withRouter, Link, }                 from 'react-router-dom';
import {
   getFilterIdTimaStrategies,
   getFilterIdTimaInvestments,
   getTimaInvestments,
   getFilterIdTimaAgents,
   getTimaAgents,
   getTimaStrategies,
} from "../../../services/AccountRequestService";
import Preloader                             from "../../../../../components/LoadingHOC/Preloader";
import PagePanel                             from '../../../../tima/components/Pages';
import Loader                                from "../../../../tima/components/Loader";
import ListTimaStrategiesMagicTable          from '../../../../tima/components/ListTimaStrategiesMagicTable';
import ListTimaInvestmentsMagicTable         from '../../../../tima/components/ListTimaInvestmentsMagicTable';
import ListTimaAgentsMagicTable              from '../../../../tima/components/ListTimaAgentsMagicTable';
import '../../../../tima/styles/tima.scss';

class LeadTima extends Component {
    constructor (props) {
         super(props);

         this.state = {
               loaded: false,
               data: [],
               pages: {
                  filter:   0,
                  total:    0,
                  take:     50,
                  variance: 2,
               },
         };
    }

   get accountId() {
      return +this.props?.match?.params?.id;
   };

   get accountTab() {
      return this.props?.match?.params?.accountTab || "strategies";
   };

   get params () {
      return this.props?.match?.params
   };

   get query() {
      return new URLSearchParams(this.props?.location?.search)
   };

   save = async (state) => new Promise(next => this.setState(state, next));

   componentDidMount = async () => {
      await this.onDataLoad();
   };

   getFilterId = async (accountTab) => {
      try {
         // Формируем data для создания filter id
         const data = await this.getFilterData(accountTab);
         let filterId;
         let response;
         // Получаем filter id
         switch (accountTab) {
            case "strategies":
            default:
               response = await getFilterIdTimaStrategies({data});
               return filterId = response?.id;
            case "investments":
               response = await getFilterIdTimaInvestments({data});
               return filterId = response?.id;
            case "agents":
               response = await getFilterIdTimaAgents({data});
               return filterId = response?.id;
         }
      }  catch (error) {
         error?.showErrorNotification?.();
      }
   }

   getFilterData = (table = "strategies") => {
      // Формируем data для получения filter id
      const value = this.props?.accountId;
      const params = {
         strategies: {
            path: "tima.tima_manager.account_id",
            value,
         },
         investments: {
            path: "tima.tima_investment.account_id",
            value,
         },
         agents: {
            path: "tima.tima_agents.account_id",
            value,
         },
      };

      return `[{"f":"${params?.[table]?.path}","o":"=","enabled":1,"j":"n","v":"${params?.[table]?.value}"},{"connect":"pgsql"}]`;
   };

   getTimaStrategies = () => async (params) => {
      try {
         const id = await this.getFilterId(this.accountTab);

         return getTimaStrategies(id, params);
      } catch (error) {
         error?.showErrorNotification?.();
      }
   };

   getTimaInvestments = () => async (params) => {
      try {
         const id = await this.getFilterId(this.accountTab);

         return getTimaInvestments(id, params);
      } catch (error) {
         error?.showErrorNotification?.();
      }
   };

   getTimaAgents = () => async (params) => {
      try {
         const id = await this.getFilterId(this.accountTab);

         return getTimaAgents(id, params);
      } catch (error) {
         error?.showErrorNotification?.();
      }
   };

   pagination = () => {};

   pageId = () => {
      const page = this.query.get('page');
      return Number(page) || 0;
   };

   pageIdAsGET = (pageId = undefined) => {
      pageId = pageId === undefined ? this.pageId() : pageId;
      const result = {
         take: this.state.pages.take,
         skip: pageId * this.state.pages.take,
      };
      return result;
   };

   onDataLoad = async (pageId) => {
      await this.save({ loaded: false, });
      const pageOptions = this.pageIdAsGET(pageId);
      const loaded = (data, meta) => ({ pages, }) => ({
         data,
         pages: { ...pages, ...meta, },
      });
      try {
         let load;
         switch(this.accountTab) {
            default:
            case 'strategies': {
                load = this.getTimaStrategies();
                break;
            }
            case 'investments': {
               load = this.getTimaInvestments();
               break;
            }
            case 'agents': {
               load = this.getTimaAgents();
               break;
            }
        }
         const response = await load(pageOptions);
         const { data = [], meta: { filter = 0, total = 0, }, } = response;
         await this.save(loaded(data, { filter, total, }));
      } catch (error) {
         await this.save(loaded([], { filter: 0, total: 0, }));
         error?.showErrorNotification?.();
      }
      await this.save({ loaded: true, });
   };

   onPageClick = ({ event, pageId, pageIs, }) => {
      // event.preventDefault();
      this.onDataLoad(pageId);
   };

   onPageText = (pageId, pageIs) => {
      const { translate } = this.props;
      const { current, first, prev, next, last, } = pageIs;
      const { skipped, taken, filter, total, } = pageIs;
      const textTranslate = {
         strategies:  ['tima_strategies_pages_filtered', 'tima_strategies_pages_total'],
         investments: ['tima_investments_pages_filtered', 'tima_investments_pages_total'],
         agents:      ['tima_agents_pages_filtered', 'tima_agents_pages_total'],
      };
      if (skipped || taken) {
         const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : '?';
         const text = skipped ? translate('tima_strategies_pages_items') : '';
         return skipped ? `${ text }: ${ id }` : id;
      } else if (filter || total) {
         const id = Number.isInteger(pageId) ? `${ pageId }` : '?';
         const text = translate(filter ? textTranslate?.[this.accountTab]?.[0] : textTranslate?.[this.accountTab]?.[1]);
         return `${ text }: ${ id }`;
      } else if (first || prev || next || last) {
         return '';
      } else if (current) {
         return `${ pageId + 1}`;
      } else {
         return `${ pageId + 1}`;
      }
   };

   render() {
      const {translate} = this.props;
      const {data, pages, loaded} = this.state;
      const bar = {
         parent: {
            className: ['toolbar_tab_account'],
         },
         title: {
            className: ['title_information_additional'],
            text: "TIMA",
         },
         strategies: {
            className: ['nav-link'],
            text: translate('tima_link_strategies'),
            to: `/clients/${ this.accountId }/tima/strategies`,
         },
         investments: {
            className: ['nav-link'],
            text: translate('tima_link_investments'),
            to: `/clients/${ this.accountId }/tima/investments`,
         },
         agents: {
            className: ['nav-link'],
            text: translate('tima_link_agents'),
            to: `/clients/${ this.accountId }/tima/agents`,
        },
      };

      let content;
      switch(this.accountTab) {
         case 'strategies':
         default: {
            bar?.strategies?.className?.push('active');
            //ТИМА СЧЕТА
            content = <ListTimaStrategiesMagicTable data={data} enums={this.props.enums} />;
            break;
         }
         case 'investments': {
            // ТИМА ИНВЕСТИЦИИ
            bar?.investments?.className?.push('active');
            content = <ListTimaInvestmentsMagicTable data={data} enums={this.props.enums} />;
            break;
         }
         case 'agents': {
            // ТИМА АГЕНТЫ
            bar?.agents?.className?.push('active');
            content = <ListTimaAgentsMagicTable data={data} enums={this.props.enums} />;
            break;
         }
      }

      return (
         <div className='content-block'>
            <div className='table-wrapper'>
               <div className='position-relative'>
                  <div className={bar.parent.className.join(' ')}>
                     <span className={bar.title.className.join(' ')}>{bar.title.text}</span>
                     { ["strategies", "investments", "agents"].map(item => (
                        <Link className={bar[item].className.join(' ')} to={bar[item].to}>{bar[item].text}</Link>
                     ))}
                  </div>
                  <Loader
                     loaded={loaded}
                     loading={(<Preloader scale={this.props.scale}/>)}
                     translate={translate}
                  >
                     {content}
                     <PagePanel
                        filter={pages.filter}
                        take={pages.take}
                        // total={pages.total} // сейчас total равняется количеству офильтрованных (filter), так как запрос на получение данных с filter id
                        total={pages.filter}
                        variance={pages.variance}
                        page={this.pageId()}
                        onClick={this.onPageClick}
                        doText={this.onPageText}
                        doTo={(pageId) => `?page=${ pageId }`}
                     />
                  </Loader>
               </div>
            </div>
         </div>
      );
   };
};

export default withRouter(withLocalize(LeadTima));
