/* eslint-disable sort-keys */
import React from "react";
import _ from "lodash";
import { Link, } from "react-router-dom";
import { Translate, } from "react-localize-redux";
import { Table, } from "reactstrap";
import Select from "react-select";

import ConfirmPartnerStatus from "../../../../popups/ConfirmPartnerStatus";

import CheckUndefinedPermission from "../../../../../../../../../components/Common/CheckUndefinedPermission";
import PermissionService from "../../../../../../../../../services/PermissionService";
import { getAllUsersByDepartment, editPartnerData, } from "../../../../../../../../../services/PartnersRequestService";

import { findOptionByValue, } from "../../../../../../../utils/helpers";
import { PartnerRoleStatuses, } from "../../../../consts/consts";
class PartnerInformation extends React.Component {
	constructor (props) {
		super(props);

		this.state = {
			partnerSetupStatus: null,
			partnerAffiliateManager: null,

			confirmPopup: {
				isOpen: false,
				status: null,
				is_payout: false,
				loaded: true,
			},

			allUsers: "",
		};
	}

	save = state => new Promise(next => this.setState(state, next));

	getPermissions = (action, field) => {
		const path = field ? [ 'partners', 'partners_setup', field, ] : [ 'partners', 'partners_setup', ];

		const pm = PermissionService.calc([ {
			path,
			key: 'user',
		}, ]);

		return pm.user.access(action);
	}

	componentDidMount = async () => {
		const { partner, } = this.props;
		const allUsers = await getAllUsersByDepartment("affiliate");

		await this.save({
			allUsers,
			partnerSetupStatus: partner.partners.partners_setup?.status,
			partnerAffiliateManager: partner.partners.partner_user?.id,
		});
	};

	get statusAllOptions () {
		const partnerSetupStatus = this.props?.enums?.partners.PartnersSetupStatusEnum;

		return Object.entries(partnerSetupStatus).map((entry) => {
			const [ value, label, ] = entry;

			return {
				value: +value,
				label,
			};
		});
	}

	get statusSelectOptions () {
		const { partnerSetupStatus: currentStatus, } = this.state;
		const statusAllOptions = this.statusAllOptions;

		const { ACTIVE, FROZEN, BLOCKED, } = PartnerRoleStatuses;

		switch (currentStatus) {
			case ACTIVE: {
				return statusAllOptions.filter(option => option.value === FROZEN || option.value === BLOCKED);
			}
			case FROZEN: {
				return statusAllOptions.filter(option => option.value === ACTIVE || option.value === BLOCKED);
			}
			default: {
				return [];
			}
		}
	}

	get usersOptions () {
		const { allUsers, } = this.state;

		if (!allUsers) {
			return [];
		}

		return allUsers.map((user) => {
			return {
				label: (
					<Link to={ `/settings/user/${ user.id }` }>{`${ user.name } ${
						user.surname
					}`}</Link>
				),
				value: user.id,
			};
		});
	}
	get tableItems () {
		return [
			{
				key: `partners_create_referral_partner_id`,
				pmField: `id`,
				pmIndex: { schema: "partners", table: "partners_setup", },
				value: "partner.partners.partners_setup.account_id",
			},
			{
				key: `partner_full_name`,
				value: () =>
					this.renderFullNameWithLink(
						"partner.core.account.id",
						"partner.core.account.name",
						"partner.core.account.surname",
						"/clients/",
					),
				pmField: `name`,
				pmIndex: { schema: "core", table: "account", },
			},
			{
				key: `partner_role_status`,
				value: () => this.renderPartnerStatus(),
				pmField: `status`,
				pmIndex: { schema: "partners", table: "partners_setup", },
			},
			{
				key: `partner_manager`,
				value: () =>
					this.renderFullNameWithLink(
						"partner.core.account.user.id",
						"partner.core.account.user.name",
						"partner.core.account.user.surname",
						"/settings/user/",
					),
				pmField: `user_id`,
				pmIndex: { schema: "core", table: "account", },
			},
			{
				key: `partners_list_partner_manager`,
				value: () => this.renderPartnerAffiliateManager(),
				pmField: `user_id`,
				pmIndex: { schema: "partners", table: "partners_setup", },
			},
			{
				key: `partners_list_partner_confirmation_date`,
				value: "partner.partners.partner_request.updated_at",
				pmField: `updated_at`,
				pmIndex: { schema: "partners", table: "requests", },
			},
			{
				key: `partner_referral_code`,
				value: () => this.renderPartnerReferralCode(),
				pmField: `ref_short`,
				pmIndex: { schema: "partners", table: "referral_code", },
			},
		]
			.filter(data => PermissionService.index(data.pmIndex).can(data.pmField))
			.map(data => ({
				...data,
				value:
					data.value instanceof Function
						? data.value()
						: _.at(this.props, data.value).shift(),
			}));
	}

	changePartnerDataProperty = async (obj) => {
		try {
			const { partner, refreshClientData, } = this.props;
			const { id, } = partner.partners.partners_setup;

			await editPartnerData(id, obj);
			await refreshClientData();
		} catch (error) {
			error?.showErrorNotification?.();
		}
	}

	toggleConfirm = status => this.save({
		confirmPopup: {
			...this.state.confirmPopup,
			isOpen: !this.state.confirmPopup.isOpen,
			...status && { status, },
		},
	});

	toggleConfirmLoader = () => this.save({
		confirmPopup: {
			...this.state.confirmPopup,
			loaded: !this.state.confirmPopup.loaded,
		},
	});

	handleOnCheckbox = (event) => {
		const { confirmPopup, } = this.state;

		this.save({
			confirmPopup: {
				...confirmPopup,
				is_payout: event.target.checked,
			},
		});
	}

	onStatusConfirm = async () => {
		try {
			await this.toggleConfirmLoader();

			const { status: newStatus, is_payout, } = this.state.confirmPopup;
			const { refreshClientData, } = this.props;
			const { ACTIVE, } = PartnerRoleStatuses;
			const isPayoutIncluded = newStatus === ACTIVE;

			await this.changePartnerDataProperty({ status: newStatus, ...isPayoutIncluded && { is_payout: is_payout ? 1 : 2, }, });

			await this.toggleConfirmLoader();
			await this.toggleConfirm();
			await refreshClientData();
		} catch (error) {
			await this.toggleConfirmLoader();
			error.showErrorNotification?.();
		}

	}
	handlePartnerAffiliateManagerChange = async (selected) => {
		try {
			const user_id =  selected.value;

			await this.changePartnerDataProperty({ user_id, });
			await this.save({ partnerAffiliateManager: user_id, });
		} catch (error) {
			error.showErrorNotification?.();
		}
	}
	renderFullNameWithLink (idPath, namePath, surnamePath, link) {
		const id = _.at(this.props, idPath).shift();
		const name = _.at(this.props, namePath).shift() ?? '';
		const surname = _.at(this.props, surnamePath).shift() ?? '';
		const fullname = `${ name } ${ surname }`;

		return id ? <Link to={ `${ link }${ id }` }>{fullname}</Link> : fullname.trim() ? fullname : '-';
	}

	renderPartnerStatus () {
		const permissionToChangeStatus = this.getPermissions('update', 'status');

		const { ACTIVE, FROZEN, } = PartnerRoleStatuses;
		const status = this.state.partnerSetupStatus;
		const isDisabled = status !== ACTIVE && status !== FROZEN || !permissionToChangeStatus;

		const statusAllOptions = this.statusAllOptions;
		const statusSelectOptions = this.statusSelectOptions;

		return this.renderSelect({
			isDisabled,
			onChange: selected => this.toggleConfirm(selected.value),
			options: statusSelectOptions,
			value: findOptionByValue(statusAllOptions, status),
		});
	}

	renderPartnerAffiliateManager () {
		const permissionToChangeManager = this.getPermissions('update', 'user_id');
		const isDisabled = !permissionToChangeManager;

		const usersOptions = this.usersOptions;
		const partnerAffiliateManager = this.state.partnerAffiliateManager;


		return this.renderSelect({
			isDisabled,
			onChange: selected => this.handlePartnerAffiliateManagerChange(selected),
			options: usersOptions,
			value: findOptionByValue(usersOptions, partnerAffiliateManager),
		});
	}

	renderPartnerReferralCode () {
		return this.props.partner.partners.referral_codes
			.map(code => code.ref_short)
			.join(", ");
	}

	renderSelect = options => (
		<div className="optipn_multiselect select-right">
			<Select
				className="filter-select multiselect partner-card__select"
				classNamePrefix="select"
				{ ...{
					isClearable: false,
					isMulti: false,
					...options,
				} }
			/>
		</div>
	);

	render = () => {
		const { confirmPopup, partnerSetupStatus, } = this.state;
		const { translate, } = this.props;

		const tableItems = this.tableItems;

		return (
			<CheckUndefinedPermission>
				{confirmPopup.isOpen &&
					<ConfirmPartnerStatus
						currentStatus={ partnerSetupStatus }
						handleOnCheckbox={ this.handleOnCheckbox }
						loaded={ confirmPopup.loaded }
						newStatus={ confirmPopup.status }
						translate={ translate }
						onCancel={ this.toggleConfirm }
						onConfirm={ this.onStatusConfirm }
					/>}

				<Translate>
					{({ translate, }) => (
						<div className="block-shadowed partner-card__partner-information">
							<span className="table-title">
								{translate(`partner_main_data`)}
							</span>
							<div className="react-bs-table table-unbordered-container">
								<Table>
									<tbody>
										{tableItems.map((data, index) => (
											<tr key={ index }>
												<td className={ 'td' }>{translate(data.key)}</td>
												<td className="td td_multiselect">{data.value}</td>
											</tr>
										))}
									</tbody>
								</Table>
							</div>
						</div>
					)}
				</Translate>
			</CheckUndefinedPermission>
		);
	};
}

export default PartnerInformation;