import { DataService, } from "./DataService";
import { hash as hashData, } from "./CachingService";
import { get, } from "../components/Magic";
import { GET_CALENDAR, GET_DEPARTMENTS_AND_USERS, GET_SINGLE_TASK, } from "../apiRoutes";
import { GET_TASK_USERS, } from "../modules/tasks/apiRoutes";

class CalendarDataService extends DataService {
    constructor () {
        super([
            'tasks',
            'task',
            'filter',
            'responsible',
        ]);
        Object.freeze(this);
    }

    async tasks ({ date_type, from, to, user_id, urgency_id, state_id, }) {
        const url = new URL(GET_CALENDAR, location.href);
        const options = Object.fromEntries(Object.entries({ date_type, from, to, user_id, urgency_id, state_id, }).filter(([key, value]) => value));
        const data = await get(url, options);
        const hash = await hashData(data);
        const calendar = { data, hash, };
        this.emit('tasks', calendar);

        return calendar;
    }

    async task (taskId) {
        const url = GET_SINGLE_TASK;
        const data = await get(`${url}/${taskId}`);
        const hash = await hashData(data);
        const task = { data, hash };
        this.emit('task', task);

        return task;
    }

    async filter () {
        const url = GET_DEPARTMENTS_AND_USERS;
        const data = await get(url);
        const hash = await hashData(data);
        const filter = { data, hash };
        this.emit('filter', filter);

        return filter;
    }

    async responsible (taskId) {
        const url = GET_TASK_USERS;
        const data = await get(`${url}/${taskId}/3`); // task_role === 3 - is for responsible user
        const hash = await hashData(data);
        const responsible = { data, hash };
        this.emit('responsible', responsible);

        return responsible;
    }
}

export const calendarService = new CalendarDataService();
