import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {MagicButton, MagicSelect} from '../../../../components/Magic';
import {MagicTooltip} from "../../../tima/components/Magic";
import Loader from "../../../tima/components/Loader";
import Preloader from "../../../../components/LoadingHOC/Preloader";
import PermissionService from "../../../../services/PermissionService";
import Moment from "moment";
import iconDownload from '../../../../images/icon-file.svg';
import call_icon from '../../../../images/call.svg';
import propTypes from 'prop-types';
import {callService} from "../../../../services/CallDataService";
import {supportService} from "../../../../services/SupportDataService";

export class TicketInfo extends Component {

   static defaultProps = {
      translate: () => { },
      downloadAndSaveFile: () => { },
      profile: {},
      chat_messages: [],
      enums: {},
   };

   static propTypes = {
      translate: propTypes.func,
      downloadAndSaveFile: propTypes.func,
      profile: propTypes.object,
      enums: propTypes.object,
   };

   state = {
      data: {},
      responsibles: [],
      loaded: true,
   }
   componentDidMount = async() => {
      await this.getResponsibles();
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      if (this.state.data !== nextProps.data) {
         this.setState({data: nextProps.data,});
      }
   };

   save = async (state) => new Promise(next => this.setState(state, next));

   getResponsibles = async () => {
      try {
         let responsibles = await supportService.getResponsibles();
         let notResponsible = {
            id: 0,
            name: `${this.props.translate('support_card_not_responsible')}`,
            surname: ''
         };
         await this.save({ responsibles: [notResponsible, ...responsibles], })
      }
      catch (error) {
         error?.showErrorNotification?.();
      }
   };

   closeTicketById = async (id) => {
      try {
         const CLOSED_STATUS = 5;
         await supportService.closeTicketById(id, {status: CLOSED_STATUS,});
         await this.props?.getTicketById(this.props?.id);
      } catch (error) {
         error?.showErrorNotification?.();
      }
   };

   changeResponsible = async (id, options) => {
      try {
         await supportService.changeResponsible(id, options);
         await this.props?.getTicketById?.(this.props?.id);
      } catch (error) {
        error?.showErrorNotification?.();
      }
   };

   sendCall = async (id) => {
      try {
         await callService.sendCall({ account_id: id });
      } catch (error) {
         error?.showErrorNotification?.();
      }
   };

   get ticketInfoConfig() {
      return [
         {
            path: ['core', 'account', 'id',],
            key: 'infoAccountId',
         },
         {
            path: ['core', 'account', 'surname',],
            key: 'infoAccountSurname',
         },
         {
            path: ['core', 'account', 'is_phone_verify'],
            key: 'isPhoneVerified',
         },
         {
            path: ['core', 'user', 'name',],
            key: 'infoResponsibleName',
         },
         {
            path: ['core', 'account', 'register_type',],
            key: 'infoAccountRegisterType',
         },
         {
            path: ['core', 'user', 'surname',],
            key: 'infoResponsibleSurname',
         },
         {
            path: ['core', 'user', 'id',],
            key: 'infoResponsibleId',
         },
         {
            path: ['tickets', 'tickets', 'id',],
            key: 'infoId',
         },
         {
            path: ['tickets', 'tickets', 'status',],
            key: 'infoStatus',
         },
         {
            path: ['tickets', 'tickets', 'subject',],
            key: 'infoSubject',
            render: this.renderInfoText(20),
         },
         {
            path: ['tickets', 'ticket_messages', 'text',],
            key: 'infoDescription',
            render: this.renderInfoText(1000),
         },
         {
            path: ['tickets', 'ticket_messages', 'text',],
            key: 'infoFiles',
            render: this.renderInfoFiles,
         },
         {
            path: ['core', 'account', 'name',],
            key: 'infoClient',
            render: this.renderInfoClient,
         },
         {
            path: ['core', 'account', 'id',],
            key: 'infoCall',
            render: this.renderInfoCall,
         },
         {
            path: ['tickets', 'tickets', 'status',],
            key: 'infoStateAppeal',
            render: this.renderInfoStateAppeal,
         },
         {
            path: ['core', 'account', 'status',],
            key: 'infoClientStatus',
            render: this.renderInfoClientStatus,
         },
         {
            path: ['tickets', 'tickets', 'created_at',],
            key: 'infoCreatedAt',
            render: this.renderInfoDate,
         },
         {
            path: ['tickets', 'tickets', 'status',],
            key: 'infoClose',
            render: this.renderInfoClose,
         },
         {
            path: ['core', 'user', 'id',],
            key: 'infoResponsible',
            render: this.renderInfoResponsible,
         }
      ];
   }

   renderInfoLink = (path, text,) => (<Link to={ path }>{ text }</Link>);

   renderInfoText = maxLength => (text, {item}) => {
      if(item?.access('show')) {
         const options = {content: text, lengthCut: maxLength,}
         return <MagicTooltip {...options}>{text}</MagicTooltip>
      }
      return "-";
   };

   renderInfoDate = (date, {item,}) => {
      if (item.access('show')) {
         const formatDB = 'YYYY-MM-DD HH:mm:ss';
         const formatOut = 'YYYY-MM-DD HH:mm:ss';
         date = Moment(date, formatDB);
         return date.isValid() ? date.format(formatOut) : "-";
      }
      return '-';
   };

   renderInfoFiles = (text, {item,}) => {
      if (item?.access('show')) {
         const {downloadAndSaveFile} = this.props;
         const message = this.props?.chat_messages?.[0]?.tickets ?? [];
         if (message?.ticket_message_documents !== null) {
            return message?.ticket_message_documents?.map?.((d, index) => {
               const options = {
                  content: `${d?.file_name}.${d?.extension}`,
                  lengthCut: 20,
               };
               const fileName = <MagicTooltip {...options} />;
               return (
                  <figure key={index} className='file' onClick={() => downloadAndSaveFile(d?.alias_name, d?.file_name)}>
                     <img className='file__icon' src={iconDownload} alt={d?.file_name}/>
                     <figcaption className='file__desc'>
                        <a className='attached__files file__link'>{fileName}</a>
                     </figcaption>
                  </figure>
               )
            })
         }
      }
      return "";
   };

   renderInfoClient = (name, {item, items}) => {
      let surname = items?.infoAccountSurname?.valueOf;
      surname = surname ? surname : '';
      const accountId = items?.infoAccountId?.valueOf;
      const registerType = items?.infoAccountRegisterType?.valueOf;
      const clientName = +registerType !== 2 ? `${name} ${surname}` : `${name}`;
      if(item?.access('show') && items?.infoAccountId?.access('show')) {
         return (this.renderInfoLink( `/clients/${accountId}`, clientName));
      } else if (item?.access('show')) {
         return clientName ?? "-";
      }
      return "-";
   };

   renderInfoCall = (id, {item, items}) => {
      if (item?.access('show')) {
         return (
            <div className='call' onClick={ () => this.sendCall?.(id) }>
               <img src={ call_icon } alt="phone client" data-is-verified={items?.isPhoneVerified.valueOf}/>
               {this.props.translate('support_card_call')}
            </div>
         )
      }
      return '';
   };

   renderInfoStateAppeal = (status, {item,}) => {
      if (item?.access('show')) {
         const allStatesAppeal = this.props?.enums?.tickets?.TicketStatusEnum ?? {};
         return (allStatesAppeal?.[+status] ?? "-")
      }
      return '';
   };

   renderInfoClientStatus = (status, {item,}) => {
      if (item?.access('show')) {
         const allStatuses = this.props?.enums?.core?.AccountStatusEnum ?? {};
         return (allStatuses?.[+status] ?? "-")
      }
      return '';
   };

   renderInfoClose = (status, {item, items}) => {
      const {profile,} = this.props;
      const responsibleId = items?.infoResponsibleId?.valueOf;
      const infoId = items?.infoId?.valueOf;
      const disabled = [null, 1].includes(responsibleId);
      if (item?.access('show', 'update') && profile?.isROP && +status !== 5) {
         const options = {
            className: [`magic-button__item_ticket-close ${disabled ? 'disabled' : ''}`],
            onClick: () => this.closeTicketById(infoId),
            children: this.props.translate('support_list_close'),
            disabled,
         };
        return (<MagicButton { ...options } />)
      } else if (item?.access('show') && +status === 5 && (profile?.isROP || profile?.isTechnicalSupport)) {
         return <span>{ this.props.translate('support_list_closed') }</span>
      }
      return "";
   };

   renderInfoResponsible = (responsibleId, {item, items}) => {
      const profile = this.props?.profile;
      const responsibles = this.state?.responsibles;
      const ticketStatus = items?.infoStatus?.valueOf;
      const ticketId = items?.infoId?.valueOf;
      const responsibleName = items?.infoResponsibleName?.valueOf;
      const responsibleSurname = items?.infoResponsibleSurname?.valueOf;

      if (item?.access('show', 'update') && responsibles?.length && profile?.isROP) {
         const options = {
            value: responsibleId ? responsibleId : 0,
            onChange: value => {
               let data = value === 0 ? {SET_NULL__user_id: 1,} : {user_id: value,};
               responsibleId !== value ? this.changeResponsible(ticketId, data) : false;
            },
            values: responsibles?.map?.(responsible => responsible?.id),
            valueToLabel: value => {
               // if ([null].includes(value)) {
               //    return this.props.translate('support_list_not_responsible');
               // }
               const responsible = responsibles?.find(item => item?.id === value)
               return `${ responsible?.name ?? ""} ${ responsible?.surname ?? ""}`;
            }
         };
         return <MagicSelect { ...options } />
      } 
      else if ((item?.access('show')) || (item?.access('show') && profile?.isTechnicalSupport)) {
         return (
            <div className="content bg-grey">{
               [null].includes(responsibleId)
                  ? <span>{this.props.translate('support_list_not_responsible')}</span>
                  : `${responsibleName ?? ""} ${responsibleSurname ?? ""}`}
            </div>
         )
      }
      return <div className="content bg-grey">-</div>;
   }

   renderTicketInfo = () => {
      const {translate} = this.props;
      const data = PermissionService.cast(this.state?.data, this.ticketInfoConfig, false);
      return (
         <div className="content__wrapper" >
            {data?.infoClose}
            <div className="content__field">
               <div className="description">{translate('support_card_subject')}</div>
               <div className="content bg-grey">{data?.infoSubject}</div>
            </div>
            <div className="content__field">
               <div className="description">{translate('support_card_description')}</div>
               <div className="content bg-grey">{data?.infoDescription}</div>
            </div>
            <div className="content__field attached__files">
               <div className="content">{data?.infoFiles}</div>
            </div>
            <div className="columns__wrapper">
               <div className="column column_left">
                  <div className="content__field">
                     <div className="description">{translate('support_card_client')}</div>
                     <div className="content bg-grey">{data?.infoClient}</div>
                  </div>
                  <div className="content__field">
                     <div className="description">{translate('support_card_phone')}</div>
                     <div className="content">{data?.infoCall}</div>
                  </div>
                  <div className="content__field">
                     <div className="description">{translate('support_card_state')}</div>
                     <div className="content bg-grey">{data?.infoStateAppeal}</div>
                  </div>
               </div>
               <div className="column column_right">
                  <div className="content__field">
                     <div className="description">{translate('support_card_client_status')}</div>
                     <div className="content bg-grey">{data?.infoClientStatus}</div>
                  </div>
                  <div className="content__field">
                     <div className="description">{translate('support_card_created_at')}</div>
                     <div className="content bg-grey">{data?.infoCreatedAt}</div>
                  </div>
                  <div className="content__field">
                     <div className="description">{translate('support_card_responsible')}</div>
                     {data?.infoResponsible}
                  </div>
               </div>
            </div>
         </div >
      );
   };

   render() {
     const loaded = Object.keys?.(this.props?.data)?.length > 0;
     return (
        <Loader loaded={loaded} loading={<Preloader scale={1}/>}>
            {this.renderTicketInfo()}
        </Loader>
     )
   }
}
