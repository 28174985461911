import React from 'react';
import { map } from "lodash";

import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import NotificationService from "../../../../../services/NotificationService";
import { updateStatusSet } from '../../../services/AccountRequestService'

export default class SelectSubStatus extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            value:   this.props.dataAfterLoading.AccountSubStatusEnum[parseInt(this.props.subStatusId)],
            account: this.props.account_id,
            isOpen:  false,
        };
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        if (nextProps.value !== this.props.value) {
            this.setState({
                value:   this.props.dataAfterLoading.AccountSubStatusEnum[nextProps.value],
                account: nextProps.account_id,
            });
        }
    }

    updateStatus = (value) => {
        const id = value.target.dataset.id;
        const index = value.target.dataset.index;
        const obj = {
            account_id: parseInt(this.state.account),
            status_id:    parseInt(id),
        };

        updateStatusSet(obj)
            .then(() => {
                this.setState({
                    value: this.props.dataAfterLoading.AccountSubStatusEnum[parseInt(index)],
                });
            })
            .catch((error) => {
                NotificationService.errors(error.response.data);
            });
    }

    handleDropdownClick = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    render () {
        return (
            <Dropdown
                className = { `select-account-warm_type-select select-account-warm_type-select-default` }
                isOpen = { this.state.isOpen }
                size = 'sm'
                toggle = { this.handleDropdownClick }
            >
                <DropdownToggle caret>
                    <span>{ this.state.value }</span>
                </DropdownToggle>
                <DropdownMenu right>
                    {map(this.props.dataAfterLoading.AccountSubStatusEnum, (item, key) => {
                        return (
                            <DropdownItem data-id = { key } data-index = { key } data-value = { item } key = { key } onClick = { this.updateStatus }>
                                { item }
                            </DropdownItem>
                        );
                    })}
                </DropdownMenu>
            </Dropdown>
        );
    }
}