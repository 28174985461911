import React                        from 'react';
import * as PropTypes               from 'prop-types';
import { MagicFilterInput, }        from '../../components/MagicFilterInput';
import { MagicFilterOperator, }     from '../../components/MagicFilterOperator';

export class MagicFilterFast extends React.Component {
	static propTypes = {
		filter: PropTypes.object.isRequired,
	};

	get filter() {
		return this.props.filter;
	}

	get amountOfValues() {
		return this.filter.Operator.amountOfValues;
	}

	render = () => (<div className="magic-filter-fixed" >
		<MagicFilterOperator filter={ this.filter } />
		{ this.amountOfValues ? (<MagicFilterInput filter={ this.filter } />) : null }
	</div>);
};
