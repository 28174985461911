import React from 'react';
import '../LoadingHOC/style/preloader.css';

class Preloader extends React.Component {
    render () {
        return (
            <div className="loaderUniversal customWithOutHOC">
                <div className="slack">
                    <span className="slack__green"></span>
                    <span className="slack__blue"></span>
                    <span className="slack__orange"></span>
                    <span className="slack__pink"></span>
                </div>
            </div>
        );
    }
}

export default Preloader;