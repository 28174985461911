import React, { Component, } from 'react';
import { withLocalize, } from 'react-localize-redux';
import { Input, } from '../../../../../../components';
import {
  filterValues,
  setErrorClass,
  setErrorsFromServer,
} from '../../../../../../utils/helpers';
import { onValidDigits, onValidPercantages } from '../../helpers/validation-helpers/onValidHelpers'

class PayoutRateCpaSingleLevel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formData: {
        payoutRanges: [],
      },
      sumFor: [],
    }
  }

  save = async (state) => new Promise(next => this.setState(state, next));

  async componentDidUpdate(prevProps, prevState) {
    const tState = this.props.conditionType;
    const pState = prevProps.conditionType;
    if (tState !== pState) {
      await this.save(state => {
        return {
          formData: {
            ...state.formData,
            payoutRanges: [],
          }

        }
      });

    }
  }
  checkIsDisabled = (index) => {

    const {paymentCoefCpaInDb, statusIsActive, statusIsClosed, isEditMode, isViewMode}= this.props;
    if(!isEditMode) return false
    return (index + 1 <= paymentCoefCpaInDb.length && statusIsActive) || statusIsClosed  || isViewMode //in active mode can delete and edit only coefs that are not in DB
  }


  handleOnChange = (index) => async (event) => {
    const { setNewSumFromValue, setNewRateValue, clearErrorOnChange } = this.props;
    const { name, value } = event.target;
    const fieldName = name.includes('deposits_from') ? 'deposits_from' : name.includes('coef') ? 'coef' : '';
    clearErrorOnChange(event);



    try {
      switch (fieldName) {
        case 'deposits_from': {
          await setNewSumFromValue(fieldName, value, index);
          break;
        }
        case 'coef': {
          await setNewSumFromValue(fieldName, value, index);
          break;
        }

        default: {
          break;
        }
      }
    } catch (e) {
      throw new Error(e);
    };
  };


  createNewAllowedGroup = () => {
    const { createNewPayoutRangesItem, } = this.props;
    return createNewPayoutRangesItem("1");
  };

  renderPayoutRangeList = () => {
    const {
      translate,
      errors,
      confirmedCountFtd,
      removePayoutRangeItem,
    } = this.props;
    const checkIsDisabled = this.checkIsDisabled;
    const isDisabled = (index) => checkIsDisabled(index) || confirmedCountFtd.length - 1 !== index;

    return (
      <div className='payout-rate-list__payout_table-block custom-horizontal-scroll pb-20'>
        <div className='payout-rate-list__payout_table-forbidden mr-35'>
          <div className='payout-rate-list__payout_table-range-items'>
            <div className='payout-rate-list__payout_table-title-bg p0'>
              <p className='payout-rate-list__payout_table-title'>
                {translate("new_partners_condition_fixation_period_count")}
              </p>
            </div>
            <div className='payout-rate-list__payout_table-title-bg p0'>
              <p className='payout-rate-list__payout_table-title'>
                {translate("new_partners_condition_payout_percentage_input")}
              </p>
            </div>
          </div>
          {confirmedCountFtd?.map((sum, index) => {
            return (
              <div key={index} className='payout-rate-list__payout_table-range-items'>
                {confirmedCountFtd?.length > 1 && !checkIsDisabled(index)
                  ? <button
                        className="payout-rate-list__create-btn_btns-btn payout-rate-list__create-btn_btns-btn-remove mt-20 rm-range-item"
                        onClick={() => removePayoutRangeItem(index)} />
                  : null
                }
                <Input
                  name={`deposits_from${index}`}
                  wrapperClassName={`payout-rate-list__payout_table-input ${setErrorClass(errors, `deposits_from${index}`)}`}
                  placeholder={translate("new_partners_condition_enter_amount_placeholder")}
                  value={`${sum.deposits_from}`}
                  onChange={this.handleOnChange(index)}
                  onValid={value => onValidDigits(value, 100_000, 1)}
                  disabled={isDisabled(index)}
                  isRequired
                />
                <Input
                  name={`coef${index}`}
                  wrapperClassName={`payout-rate-list__payout_table-input ${setErrorClass(errors, `coef${index}`)}`}
                  placeholder={translate("new_partners_condition_grace_period_payment_placeholder")}
                  value={`${sum.coef}`}
                  onChange={this.handleOnChange(index)}
                  disabled={isDisabled(index)}
                  onValid={value => onValidPercantages(value, 1000)}
                  isRequired
                />

              </div>
            )
          })}
        </div>
      </div>

    );
  };


  render() {
    const { translate, confirmedCountFtd, formData, status, isViewMode } = this.props;
    const disabled = confirmedCountFtd.length >= 10 ? 'disabled' : status.closed ? 'disabled' : formData.payment_coef_cpa[formData.payment_coef_cpa.length - 1].deposits_from >= 100_000 ? 'disabled' : isViewMode ? 'disabled' : '';

    return (
      <>
        <div className='payout-rate-list__payout_table-forbidden-title'>
          <p className={'payout-rate-list__payout_block-title'}>
            {translate('new_partners_condition_payout_percentage')}
          </p>
          <button
            onClick={() => disabled ? null : this.createNewAllowedGroup()}
            className={`${disabled} payout-rate-list__create-btn_btns-btn payout-rate-list__create-btn_btns-btn-join`}
            disabled={disabled}
          >
            {translate(`new_partners_condition_add-range`)}
          </button>
        </div>
        <div className='payout-rate-list__payout_table custom-vertical-scroll'>
          {this.renderPayoutRangeList()}
        </div>
      </>
    )
  }
}

export default withLocalize(PayoutRateCpaSingleLevel);
