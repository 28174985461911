export default (
    translate,
    order = [],
    props = false, // id => ({ ...props })
    {
        tradingAccountId = 0,
    } = {},
    permissions,
    isDemoTradingAccountType,
) => [
    {
        id: `general`,
        link: `/list-accounts/tradings/${ tradingAccountId }`,
        text: translate( 'trading_account_link_general' ),
        pm: () => permissions?.tradingAccounts?.access('show') ?? true,
    },
    {
        id: `opened-orders-mt4`,
        link: `/list-accounts/tradings/${ tradingAccountId }/opened-orders-mt4`,
        text: translate( 'trading_account_link_opened-orders-mt4' ),
        pm: () => permissions?.tradingAccounts?.access('getOpenOrdersMt4') ?? true,
    },
    {
        id: `closed-orders-mt4`,
        link: `/list-accounts/tradings/${ tradingAccountId }/closed-orders-mt4`,
        text: translate( 'trading_account_link_closed-orders-mt4' ),
        pm: () => permissions?.tradingAccounts?.access('getClosedOrdersMt4') ?? true,
    },
    {
        id: `risk-manager-mt4`,
        link: `/list-accounts/tradings/${ tradingAccountId }/risk-manager-mt4`,
        text: translate( 'trading_account_link_risk-manager-mt4' ),
        pm: () => {
            const risksPath = isDemoTradingAccountType ? 'risksDemo' : 'risksLive';

            return permissions?.[risksPath]?.access('getByTradingAccountId') ?? true;
        },
    },
    {
        id: `logs-mt4`,
        link: `/list-accounts/tradings/${ tradingAccountId }/logs-mt4`,
        text: translate( 'trading_account_link_logs-mt4' ),
        pm: () => permissions?.tradingAccounts?.access('getJournalEventsMt4') ?? true,
    },
    {
        id: `opened-orders-mt5`,
        link: `/list-accounts/tradings/${ tradingAccountId }/opened-orders-mt5`,
        text: translate( 'trading_account_link_opened-orders-mt4' ),
        pm: () => permissions?.tradingAccounts?.access('getOpenOrders') ?? true,
    },
    {
        id: `closed-orders-mt5`,
        link: `/list-accounts/tradings/${ tradingAccountId }/closed-orders-mt5`,
        text: translate( 'trading_account_link_closed-orders-mt4' ),
        pm: () => permissions?.tradingAccounts?.access('getClosedOrders') ?? true,
    },
    {
        id: `opened-positions-mt5`,
        link: `/list-accounts/tradings/${ tradingAccountId }/opened-positions-mt5`,
        text: translate( 'trading_account_link_opened-positions-mt5' ),
        pm: () => permissions?.tradingAccounts?.access('getOpenPositions') ?? true,
    },
    {
        id: `closed-positions-mt5`,
        link: `/list-accounts/tradings/${ tradingAccountId }/closed-positions-mt5`,
        text: translate( 'trading_account_link_closed-positions-mt5' ),
        pm: () => permissions?.tradingAccounts?.access('getClosedPositions') ?? true,
    },
    {
        id: `closed-deals-mt5`,
        link: `/list-accounts/tradings/${ tradingAccountId }/closed-deals-mt5`,
        text: translate( 'trading_account_link_closed-deals-mt5' ),
        pm: () => permissions?.tradingAccounts?.access('getClosedDeals') ?? true,
    },
    {
        id: `risk-manager-mt5`,
        link: `/list-accounts/tradings/${ tradingAccountId }/risk-manager-mt5`,
        text: translate( 'trading_account_link_risk-manager-mt4' ),
        pm: () => permissions?.riskLiveMt5?.access('getByTradingAccountId') ?? true,
    },
].filter(
    ( { id } ) => order.includes( id )
).sort(
    ( { id: a, }, { id: b, } ) => order.indexOf( a ) - order.indexOf( b )
).map(
    ( { id, ...data } ) => ( { ...data, key: id, ...props && props(id), } )
).filter(
    (item) => item?.pm() ?? true
);
