import React from 'react';

import ListAccounts        from "./domains/ListAccounts";
import ListTradingAccounts from "./domains/TradingAccounts/ListTradingAccounts";
import ListSystemAccounts  from "./domains/SystemAccounts/ListSystemAccounts";
import ListCorporateAction from './domains/CorporateActions/ListCorporateAction';
import BalanceOperations from "./domains/BalanceOperations/BalanceOperations";

import TradingAccount      from "./components/Account/TradingAccount";
import SystemAccount       from "./components/Account/SystemAccount";
import {MODULE_KEYS as pm} from "../../services/PermissionService";

export const list_accountsConfig = [
    {permission: null, component: <ListCorporateAction/>, path: '/list-accounts/corporate-action'},

    // {permission: null, component: <TradingAccount/>, path: '/list-accounts/tradings/:id'},
    {
        permission: null,
        component: <TradingAccount activeTab='logs-mt4'/>,
        path: '/list-accounts/tradings/:tradingAccountId/logs-mt4',
    },
    {
        permission: null,
        component: <TradingAccount activeTab='risk-manager-mt4'/>,
        path: '/list-accounts/tradings/:tradingAccountId/risk-manager-mt4',
    },
    {
        permission: null,
        component: <TradingAccount activeTab='closed-orders-mt4'/>,
        path: '/list-accounts/tradings/:tradingAccountId/closed-orders-mt4',
    },
    {
        permission: null,
        component: <TradingAccount activeTab='opened-orders-mt4'/>,
        path: '/list-accounts/tradings/:tradingAccountId/opened-orders-mt4',
    },
    {
        permission: null,
        component: <TradingAccount activeTab='closed-orders-mt5'/>,
        path: '/list-accounts/tradings/:tradingAccountId/closed-orders-mt5',
    },
    {
        permission: null,
        component: <TradingAccount activeTab='opened-orders-mt5'/>,
        path: '/list-accounts/tradings/:tradingAccountId/opened-orders-mt5',
    },
    {
        permission: null,
        component: <TradingAccount activeTab='opened-positions-mt5'/>,
        path: '/list-accounts/tradings/:tradingAccountId/opened-positions-mt5',
    },
    {
        permission: null,
        component: <TradingAccount activeTab='closed-positions-mt5'/>,
        path: '/list-accounts/tradings/:tradingAccountId/closed-positions-mt5',
    },
    {
        permission: null,
        component: <TradingAccount activeTab='closed-deals-mt5'/>,
        path: '/list-accounts/tradings/:tradingAccountId/closed-deals-mt5',
    },
    {
        permission: null,
        component: <TradingAccount activeTab='risk-manager-mt5'/>,
        path: '/list-accounts/tradings/:tradingAccountId/risk-manager-mt5',
    },
    {
        permission: null,
        component: <TradingAccount activeTab='general'/>,
        path: '/list-accounts/tradings/:tradingAccountId',
    },

    {permission: null, component: <ListTradingAccounts/>, path: '/list-accounts/tradings'},

    {permission: null, component: <SystemAccount/>, path: '/list-accounts/systems/:id'},
    {permission: null, component: <ListSystemAccounts/>, path: '/list-accounts/systems'},
    {permission: pm.CORE_BALANCE_OPERATIONS, component: <BalanceOperations/>, path: '/list-accounts/balance-operations'},

    {permission: null, component: <ListAccounts/>, path: '/list-accounts'},
];
