import { isEmpty, isString, isObject, } from 'lodash';
import NotificationService from '../../../services/NotificationService';

/**
 *
 * @param options { Array } -> [ { label: string, value: number }, ]
 * @param value { Number } -> 1
 * @returns { Object } -> {label, value}
 */
export const findOptionByValue = (options, value) => {
	return options.find(item => +item.value === +value) ?? {};
};

/**
 *
 * @param error { Object }
 * @param callback { Function }
 * @param remove { Boolean }
 * @returns { Promise<void> }
 */
export const setErrorsFromServer = async (error = {}, callback, remove = false, ) => {

	NotificationService.errors({
		...error?.response?.data,
		remove,
		errors: Object
			.values(error?.response?.data?.errors)
			.flat()
			.reduce((total, error, index) => {
				try {
					const { message } = JSON.parse(error);

					total[index] = [ message ];
				} catch (e) {
					total[index] = [ error ];
				}

				return total;
			}, {})
	});

	const errors = Object?.keys?.(error?.response?.data?.errors)?.map(e => e?.split('.')?.[0]) ?? [];

	return callback && await callback({ errors, });
};

/**
 *
 * @param data { Array }
 * @param name { String }
 * @returns { String }
 */
export const setErrorClass = (data = [], name = "") => {
	return data.includes(name) ? "error" : "";
};

/**
 *
 * @param value { String } -> word
 * @returns { String } -> WORD
 */
export const toLowerCase = (value) => {
	if (typeof value !== 'string') return "";
	return value.toLowerCase();
}

/**
 *
 * @param v1 { String } -> word
 * @param v2 { String } -> rd
 * @returns { Boolean } -> true
 */
export const isMatch = (v1, v2) => {
	return toLowerCase(v1).indexOf(toLowerCase(v2)) !== -1;
};

/**
 *
 * @param value { String } -> "some-text"
 * @returns { Boolean } -> true
 */
 export const isEmptyString = (value) => {
	return isString(value) && isEmpty(value)
};

/**
 *
 * @param option { Object } -> { label: "label", value: 1, }
 * @returns { Boolean } -> true
 */
 export const isEmptySelectValue = (option) => {
	return isObject(option) && (isEmpty(option) || option.value === null);
};

/**
 *
 * @param values { Array } // [ { value: 1, label: "label 1" }, { value: 2, label: "label 2" } ]
 * @param filteredValues { Array } // [ 2,]
 * @returns { Array } // [ { value: 3, label: "label2" } ]
 */
 export const filterValues = (values, filteredValues) => {
	if (!Array.isArray(values)) return [];
	
	return values.filter(({ value, }) => !filteredValues.includes(value));
};