import React from "react";
import person from "../../../../images/person.svg";
import { getAvatar } from "../../services/TaskHelperService";
import { withLocalize } from "react-localize-redux";

class ResponsibleBlock extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            images: {},
            role:   '3',
        };
    }

    componentDidMount () {
        getAvatar(this, this.props.dataTask.responsible_id, this.props.usersAllAvatar);
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        getAvatar(this, nextProps.dataTask.responsible_id, nextProps.usersAllAvatar);
    }

    renderEmptyResponsible = (roleName) => {
        const { translate } = this.props;

        return (
            <div className = 'lead-block'>
                <div className = {`user-info ${this.props.error('responsible_id')}`}>
                    <div>{roleName}</div>
                    <div className = 'user-action'>
                        <span
                            onClick = { () => {
                                this.props.toggleModal(3);
                                this.props.getAllManager();
                            } }><img alt = 'person' src = { person } style={{width: '10px'}} />
                            {translate('tasks_responsible_add')}
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    render () {
        const allManager = this.props?.allManager;
        const responsibleID = this.props?.dataTask?.responsible_id ?? 0;
        const roleName = this.props?.get_enums_tasks?.TaskRoleEnum?.[this.state.role] ?? '';
        const foundResponsible = allManager[0] && allManager?.find((item) => item.id === responsibleID);
        const { translate } = this.props;

        // check if list of managers is not empty && is there a manager with same ID as responsible (from Client page)
        return allManager[0] && foundResponsible ? (
            <div className = 'lead-block' key = { responsibleID }>
                <div className = 'user-image'>
                    <img alt = 'user' src = { this.state.images.imageId } />
                </div>
                <div className = 'user-info'>
                    <div>{roleName}</div>
                    <div className = 'user-name active'>{`${foundResponsible.name} ${foundResponsible.surname}`} <span className = 'circle' /></div>
                    { [1, 2, 4, 15].includes(+this.props.userRoleId) ? (
                        <div className = 'user-action'>
                            <span
                                onClick = { () => {
                                    this.props.toggleModal(3);
                                    this.props.getAllManager();
                                } }><img alt = 'person' src = { person } style = { { width: '10px' } } />
                                {translate('tasks_change_responsible')}
                            </span>
                        </div>
                    ) : ''}
                </div>
            </div>
        ) : this.renderEmptyResponsible(roleName);
    }
}

export default withLocalize(ResponsibleBlock);
