import {
	isEmptySelectValue,
	isEmptyString,
} from "../../../../../../utils/helpers";

export const validateEmptyFields = async (state, save) => {
	const newErrors = [];
	const { formData, } = state;
	const {
		name, country_groups, status, type,
	} = formData;

	if (isEmptyString(name) || name.length < 3 || name.length > 100) {
		newErrors.push("name");
	}

	if (country_groups.length < 2) {
		newErrors.push("add_btn");
	}

	if (isEmptySelectValue(status)) {
		newErrors.push("status");
	}
	if (isEmptySelectValue(type)) {
		newErrors.push("type");
	}

	const countryGroupsErrors = country_groups.reduce(
		(errors, group, index = 0) => {
			console.log(!index)
			if (group.group.length === 0 && index) {
				errors.push(`group_${ index }`);
			}

			const prevGroup = state.formData.country_groups[index - 1];

			if (prevGroup && prevGroup.name === group.name) {
				errors.push(`name_${ index }`);
			}

			if (group.name.length < 3 || group.name.length > 100) {
				errors.push(`name_${ index }`);
			}

			return [ ...errors, ];
		},
		[],
	);

	newErrors.push(...countryGroupsErrors);

	await save(() => {
		return { errors: [ ...state.errors, ...newErrors, ], };
	});

	return !newErrors.length;
};