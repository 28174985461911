import React, { Component, } from 'react';
import { Select, } from "../../../../../components";
import { setErrorClass, } from "../../../../../utils/helpers";
import FileInput from "../../../components/FileInput";
import { LENDINGS_FILE_EXTENSION_ACCEPT, } from "../../consts/LENDINGS_FILE_EXTENSION_ACCEPT";

class LendingLanguageForm extends Component {

	get remainingLanguageOptions () {
		const { languageOptions, languageFormData, } = this.props;

		//filter those that have been already used
		return languageOptions.filter(languageOption => !languageFormData.find(formItem => formItem.language_id.value === languageOption.value));
	}

	get disableAddButton () {
		return !this.remainingLanguageOptions.length || this.props.languageFormData.length === this.props.languageOptions.length || this.props.isViewMode;
	}
	renderForm = () => {
		const {
			translate, errors,
			handleOnChange,
			languageFormData,
			languageStatusesOptions,
			isViewMode,
			deleteLastLanguage,
			toggleImgPopup,
			isEditMode,
			languagesInDB,
		} = this.props;

		return languageFormData.map((languageForm, index) => {

			const {
				language_id, status, file, updated_at, source,
			} = languageForm;

			const newItemDosntExistInDB = !isEditMode || index + 1 > languagesInDB.length;
			const showDeleteButton = index === languageFormData.length - 1 && newItemDosntExistInDB && !isViewMode;

			return (
				<div className="promo-popup__group" key={ index }>
					{
						showDeleteButton && <div
							className="promo-popup__button promo-popup__button_delete"
							onClick={ () => deleteLastLanguage(index) } //index provided to filter out associated errors
						/>
					}
					<div className="promo-popup__row">
						<Select
							className={ setErrorClass(errors, `language_id_${ index }`) }
							disabled={ isViewMode }
							isRequired={ true }
							label={ translate("partner_target_page_language") }
							name="language_id"
							options={ this.remainingLanguageOptions }
							placeholder={ translate("partners_target_page_language_placeholder") }
							value={ language_id }
							onChange={ e => handleOnChange(e, index) }
						/>
					</div>
					<div className={ 'promo-popup__row' }>
						<div className={ `input__wrapper` }>
							<label className="">{translate(`partners_lendings_preview`)}</label>
							<FileInput
								accept={ LENDINGS_FILE_EXTENSION_ACCEPT }
								buttonText={ file || isEditMode ? translate('partners_change_file') : translate('partners_download') }
								className={ setErrorClass(errors, `file_${ index }`) }
								disabled={ isViewMode }
								file={ file }
								imgData={ { source, updated_at, } }
								name={ 'file' }
								onChange={ e => handleOnChange(e, index) }
								onImgClick={imgSource => toggleImgPopup(true, imgSource)}
							/>
						</div>
					</div>
					<div className="promo-popup__row">
						<Select
							className={ setErrorClass(errors, `status_${ index }`) }
							disabled={ isViewMode }
							isRequired={ true }
							label={ translate("partners_banners_status") }
							name="status"
							options={ languageStatusesOptions }
							placeholder={ translate("partners_banners_status") }
							value={ status }
							onChange={ e => handleOnChange(e, index) }
						/>
					</div>
				</div>
			);
		},
		);
	}
	render () {
		const { translate, addLanguage, } = this.props;

		return (
			<>
				<div className={ 'promo-popup__block promo-popup__block_grey' }>
					{this.renderForm()}
				</div>
				<div
					className={ `promo-popup__button promo-popup__button_add ${ this.disableAddButton ? 'disabled' : '' }` }
					onClick={ addLanguage }
				>
					{translate('partner_target_page_add_another_language')}
				</div>
			</>
		);
	}
}

export default LendingLanguageForm;