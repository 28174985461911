// eslint-disable-next-line no-unused-vars
const LinkConfig = (
		partner,
		activeTab,
		translate,
		order,
		options = {},
		permissions,
) =>
	[
		{
			id: "partner-card",
			link: `/partners/requests/partners-list/${
				partner.partners.partners_setup.id
			}/${ activeTab }`,
			text: translate(`partner_${ activeTab.replace("-", "_").toLowerCase() }`),
		},
		{
			id: "partner-name",
			link: `/partners/requests/partners-list/${
				partner.partners.partners_setup.id
			}`,
			text: `${ partner.core.account.name } ${ partner.core.account.surname }`,
		},
		{
			id: "partners-list",
			link: "/partners/requests/partners-list",
			text: translate("partners_system_partners_list"),
		},
		{
			id: "partners",
			link: "/partners",
			text: translate("partners_h1"),
		},
		{
			id: "dashboard",
			link: "/dashboard",
			text: translate("menu_dashboard"),
		},
	]
		.filter(({ id, }) => order.includes(id))
		.sort(({ id: a, }, { id: b, }) => order.indexOf(a) - order.indexOf(b))
		.map(({ id, ...data }) => ({ ...data, key: id, }))
		.filter((item) => {
			if (permissions) {
				return permissions?.[item?.key]?.access("index") ?? false;
			}

			return true;
		});

export default LinkConfig;