import React from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';

import { Collapse } from 'reactstrap';
import { taskService, } from '../../../../services/TaskDataService';

const statusType = {
  '1': 'bg-violet-blue',
  '2': 'bg-shadow-blue',
  '3': 'bg-grass-green',
  '4': 'bg-deep-blue',
  '5': 'bg-pink',
  '6': 'bg-deep-yellow',
  '7': 'bg-red-orange',
  '8': 'bg-coral',
  '9': 'bg-blood-red',
  '10': 'bg-turquoise',
};

class VerticalMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHaveSubContent: false,
      get_count: this.props.get_count,
      collapse: false,
      ...this.getInitialTaskCountsState(),
    };
  }

  save = async (state) => new Promise(next => this.setState(state, next));

  getInitialTaskCountsState = () => ({
    countTaskMain: {
      taskCount: null,
      taskCountDelegation: null,
      hash: null,
    }
  });

  renderVerificationMenu = () => {

    const items = this.props.content.filter((item) => {
      const access = item.access('index');
      return access;
    });
    return this.renderMenu(items);
  };

  renderMenu = (mainItemsMenu) => {
    const {
      countTaskMain: {
        taskCount,
        taskCountDelegation,
      }
    } = this.state;
    const currentPathname = `/${this.props.location.pathname.split('/').slice(1, 2).join('/')}`;

    const items = mainItemsMenu.map((menu, key) => {
      const isLikePath = currentPathname?.indexOf(menu?.to) !== -1;

      return (
        <MenuItem
          depth={1}
          setFilterCategory={this.setFilterCategory}
          key={key}
          item={menu}
          gerCountType={this.ger_count_type}
          taskCount={taskCount}
          taskCountDelegation={taskCountDelegation}
          isOpen={currentPathname === menu.to}
          isLikePath={isLikePath}
          managerProfile = { this.props.managerProfile }
          location={this.props.location}
          translate={this.props.translate}
        />
      );
    });

    return items;
  };

  ger_count_type = () => {
    return typeof this.state.get_count === 'number' ? this.state.get_count : '';
  };

  getTaskCount = async () => {
    try {
      const response = await getCountTask();

      await this.save({
        taskCount: response?.count,
        taskCountDelegation: response?.count_delegation,
      });
    } catch (error) {
      await this.save({ ...this.getInitialTaskCountsState() });
    }
  };

  countTaskChanges = async (countTask) => {
    if (countTask.hash === this.state?.countTaskMain?.hash) {
      return `${this.constructor.name}.countTaskChanges: false`;
    }
    const {
      data: {
        count,
        count_delegation,
      },
      hash
    } = countTask;
    await this.save({
      countTaskMain: {
        hash,
        taskCount: count,
        taskCountDelegation: count_delegation,
      },
    });

    return `${this.constructor.name}.countTaskChanges: true`;
  };

  async componentDidMount() {
    try {
      taskService.subscribe('countTask', this.countTaskChanges, this);
      await taskService.countTask();
    } catch (error) {
      console.error(error);
    }
  }
    get isBackOfficeAndSale (){
        return this.props.managerProfile?.tree_info?.some(department => department.department_alias === 'back_office' || department.department_alias === 'sales' )
    }

  componentWillUnmount() {
    taskService.unsubscribe('countTask', this.countTaskChanges, this);
  }

  render() {
    return (
      <div className="vertical-menu">
        {
          this.renderVerificationMenu()
        }
      </div>
    );
  }
}

const MenuItem = (props) => {
  const { translate } = props;

  function submenu(item) {
    const isAutoCall = item?.key === 'autoCall';
    const access = item?.access?.(isAutoCall ? 'show' : 'index');

    return access ? renderSubMenu(item) : null;
  }

  const renderSubMenu = (item) => {
    const currentPathname = `/${props.location.pathname.split('/').slice(1, props.depth + 1 + 1).join('/')}`;

    const isLikePathSub = currentPathname?.indexOf(item?.to) !== -1;

    return item?.content?.length ? (
      <MenuItem
        depth={props.depth + 1}
        setFilterCategory={props.setFilterCategory}
        item={item}
        gerCountType={props.ger_count_type}
        taskCount={props.taskCount}
        taskCountDelegation={props.taskCountDelegation}
        isOpen={currentPathname === item.to}
        isLikePath={isLikePathSub}
        location={props.location}
        translate={props.translate}
      />
    ) : (
      <div>
        <NavLink
          activeClassName="current"
          className={`menu-item submenu ${isLikePathSub ? 'current' : ''}`}
          exact
          to={item?.to}>
          <div className="menu-label"><i className={`icon la la-${item?.icon}`} />
            <span>{translate(`${item?.label}`)}</span></div>
          {item?.notification ?
            <span className="menu-notification">{props.gerCountType()}</span> : ''}
        </NavLink>
      </div>
    );
  };

  const contentArray = props.item.clientItem ?
    props.item?.content?.map((item, key) => {
      return (
        <div key={key}>
          {renderTypeClient(item)}
        </div>
      );
    }) : props.item?.content?.map((item, key) => {
      return (
        <div key={key}>
          {submenu(item)}
        </div>
      );
    }).filter(item => item);

  const renderTaskCount = (cnt, { className = '' }) => {
    return cnt ?
      <span className={`menu-notification ${className}`}>
        {cnt}
      </span> : null;
  };

  function renderTypeClient(item) {
    const className = `table-status ${statusType[item.id]}`;

    return (
      <NavLink
        exact
        className={`menu-item submenu ${statusType[item.id]}`}
        to={item.to}
      >
        <div className="menu-label">
          {item.icon ? <i className={`icon la la-${item.icon}`} /> : null}
          <span>{item.label}</span>
        </div>
        <span className={`${className}`} style={{ padding: '4px 11px 4px' }}>
          {(item.count > 99) ? '99+' : item.count}
        </span>
      </NavLink>
    );
  }

  return (
    <div>
      <NavLink
        activeClassName="current"
        className={`menu-item ${props.isOpen ? 'open' : ''} ${props.isLikePath ? 'current' : ''}`}
        exact to={props.item.to}
      >
        <div className="menu-label">
          {props.item.icon === 'conversations' ? (<div className={props.isOpen ? 'conversation_icon_active' : 'conversation_icon'}/>) :  <i className = { `menu-icon menu-icon-${props.item.icon}` } />}
          <span>{translate(props.item.label)}</span>
        </div>
        {!!props.item?.content?.length ? <i className="state-icon la la-angle-right" /> : null}
        {props.item.notification ?
          (
            <React.Fragment>
              {renderTaskCount(props.taskCount, {})}
              {renderTaskCount(props.taskCountDelegation, { className: 'menu-notification--tasks-delegated' })}
            </React.Fragment>
          ) : null
        }
      </NavLink>
      {!!props.item?.content?.length &&
        <Collapse isOpen={props.isOpen}>
          {contentArray}
        </Collapse>}
    </div>
  );
};

export default withLocalize(withRouter(VerticalMenu));
