import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import MarketingChannelsMagicTable from './Table/MarketingChannelsMagicTable';
import { marketingService } from '../../../services/MarketingDataService';
import { Mentor } from '../../../components/Magic';
import Loader from '../../tima/components/Loader';
import Preloader from '../../../components/LoadingHOC/Preloader';
import PagePanel from '../../tima/components/Pages';
import ModalUtmCreateAndUpdate from './Modal/ModalUtmCreateAndUpdate';
import NotificationService from '../../../services/NotificationService';
import PermissionService from '../../../services/PermissionService';

class MarketingChannels extends Component {
    constructor(props) {
        super(props);

        const { params } = props;

        this.state = {
            isAddModal: false,
            isModalShown: false,
            isSameChannels: false,
            loaded: false,
            utmSource: {
                data: [],
                hash: null
            },
            pages: {
                filter:   0,
                total:    0,
                take:     20,
                variance: 2,
            },
            errors: [],
            sameChannels: [],
        };

        const mf = new Mentor({
            owner: this,
            serviceId: 999,
            translate: this.props.translate,
        });

        Object.defineProperty(this, 'mf', { get: () => mf, });
        this.props.mf(mf);
    };

    get utmSourceConfig () {
        return [ {
            key: 'utmSource',
            path: [ 'statistics', 'utm_source', ],
        }, ];
    };

    save = async state => new Promise(next => this.setState(state, next));

    mfDoing = async () => {
        await this.save({ loaded: false, });
    };

    mfDone = async () => {
        await this.onDataLoad();
    };

    channelsChanges = async ({data, hash, options}) => {
        if (hash===this.state?.utmSource?.hash) {
            return `${ this.constructor.name }.utmSourceChanges: false`;
        } else if (options.filterId!==this.state?.utmSource?.options?.filterId) {
            return `${this.constructor.name}.utmSourceChanges: false`;
        } else if (options.take!==this.state?.utmSource?.options?.take) {
            return `${this.constructor.name}.utmSourceChanges: false`;
        } else if (options.skip!==this.state?.utmSource?.options?.skip) {
            return `${this.constructor.name}.utmSourceChanges: false`;
        }

        await this.save({
            utmSource: {
                data: data.data,
                hash,
                options,
            },
            pages: {
                ...this.state.pages,
                filter: data?.meta?.filter,
                total: data?.meta?.total,
            },
        });
        return `${this.constructor.name}.channelsChanges: true`;
    };

    componentDidMount = async () => {
        marketingService.subscribe('utmSource', this.channelsChanges, this);
        this.mf.subscribe({ loaded: this.mfDone, }, this);
        await this.mf.init({ doEmit: true, });
        await this.getLastNumber();
    };

    componentWillUnmount () {
        marketingService.unsubscribe('utmSource', this.channelsChanges, this);
        this.mf.unsubscribe({
            loaded: this.mfDone,
        }, this);
    };

    getLastNumber = async () => {
        await marketingService.getLastUtmSourceNumber().then((data) => {
            this.save({ lastNumberPlusOne: data.data + 1, })
        });
    };

    addUtmSource = async ({ description, ...data }) => {
        try {
            const dataNew = {
                ...data,
                ...!description
                  ? { SET_NULL__description: "1", }
                  : { description, },
            };
            await marketingService.addUtmSource(this.state.utmSource.data.id, dataNew);
            await this.onDataLoad();
            await this.save({ isModalShown: false });
        } catch (error) {
            await this.setErrorsFromServer(error);
            console.log(error)
        }
    };

    addButton = () => {
        const conf = PermissionService.calc(this.utmSourceConfig);
        const addBtnPermission = conf?.utmSource?.access('store') ?? false;

         if (addBtnPermission) {
             return (
               <div className="marketing-buttons">
                   <div className="marketing-buttons_btns">
                       <button
                         className="marketing-buttons_btns-btn marketing-buttons_btns-btn-add"
                         onClick={async () => {
                             await this.getLastNumber();
                             await this.save({ isAddModal: true, isModalShown: true, isSameChannels: true, })
                         } }
                       >
                           {this.props.translate(`marketing_add_channel`)}
                       </button>
                   </div>
               </div>
             )
         }
     };

    searchSameChannels = (text) => {
        marketingService.searchUtmSourceCharAt(text).then(async (data) => {
            await this.save({
                sameChannels: data.data,
            })
        })
    };

    setErrorsFromServer = async (error) => {
        NotificationService.errors({...error?.response?.data, time: 25000});
        let errors = Object?.keys?.(error?.response?.data?.errors)?.map(e => e?.split('.')?.[0]) ?? [];
        await this.save({ errors });
    };

    clearErrorClass = async (name) => {
        await this.save?.(state => ({errors: [...state?.errors?.filter(e => e !== name)],}));
    };

    render = () => {
        const { translate, } = this.props;
        let data = this.state.utmSource.data;

        return (
          <div className='content-block'>
              {this.state.isModalShown === true ? (
               <ModalUtmCreateAndUpdate
                 data={this.state.data}
                 enums={this.props.enums}
                 errors={this.state.errors}
                 viewOnly={[]}
                 searchSameChannels={this.searchSameChannels}
                 sameChannels={this.state.sameChannels}
                 isSameChannels={this.state.isSameChannels}
                 clearError={this.clearErrorClass}
                 titleKey={{
                     form: `marketing_add_new_channel`,
                     name: `statistics_utm_medium_name`,
                     number: `statistics_utm_medium_number`,
                     description: `statistics_utm_medium_description`,
                     status: `statistics_utm_medium_status`,
                 }}
                 lastNumberPlusOne={this.state.lastNumberPlusOne}
                 getLastNumber={this.getLastNumber}
                 isAddModal={this.state.isAddModal}
                 onClose={() => this.save({ isModalShown: false })}
                 onDataLoad={this.onDataLoad}
                 onSave={this.addUtmSource}
               />
              ) : null}
              <div className='table-wrapper'>
                  <div className='position-relative'>
                      <Loader
                        loaded={this.state.loaded}
                        loading={(<Preloader scale={this.props.scale}/>)}
                      >
                          {this.addButton()}
                          <MarketingChannelsMagicTable
                            data={data}
                            enums={this.props?.enums}
                            onDataLoad={() => this.onDataLoad()}
                         />
                          <PagePanel
                            filter={this.state.pages.filter}
                            take={this.state.pages.take}
                            total={this.state.pages.total}
                            variance={this.state.pages.variance}
                            page={this.pageId()}
                            onClick={this.onPageClick}
                            doText={this.onPageText}
                            doTo={(pageId) => `?page=${ pageId }`}
                          />
                      </Loader>

                  </div>
              </div>
          </div>
        )
    };

    pageId = () => {
        const { query } = this.props;
        const page = query.get('page');
        return Number(page) || 0;
    };

    pageIdAsGET = (pageId) => {
        pageId = pageId === undefined ? this.pageId() : pageId;
        const { filterId ,} = this.mf;
        return {
            filterId,
            take: this.state.pages.take,
            skip: pageId * this.state.pages.take,
        };
    };

    onDataLoad = async (pageId) => {
        const pageOptions = this.pageIdAsGET(pageId);
        await this.save({
            loaded: false,
            utmSource: { ...this.state.utmSource, options: pageOptions, },
        });
        try {
            await marketingService.utmSource(pageOptions);
        } catch (error) {
            console.log("error: ", error);
            await this.save({ utmSource: {
                    data: [],
                    hash: null,
                    options: {},
                },
                pages: { ...this.state.pages, filter: 0, total: 0, }});
        }
        await this.save({ loaded: true, });
    };

    onPageClick = ({ event, pageId, pageIs, }) => {
        this.onDataLoad(pageId);
    };

}

export default withRouter(withLocalize(MarketingChannels));
