import * as actions from './actionTypes';

const initialState = {
    data:   {},
    output: {
        'take':       50,
        'skip':       0,
    },
    enums: {},
    total: 0
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.SET_TRANSACTIONS:
            return {
                ...state,
                'data':  action.data.data,
                'total': action.data.meta.total,
                'filterCount': action.data.meta.filter,
            };
        case actions.SET_TRANSACTION:
            return {
                ...state,
                'transaction': action.data,
            };
        case actions.GET_ENUMS:
           return {
               ...state,
               'enums': {
                   ...state.enums,
                   ...action.data,
               },
           };
        default:
            return state;
    }
}
