const sortArrayOfObjectsByKey = (arrayOfObjects, key, ascending = true) => {
	if (!arrayOfObjects || !arrayOfObjects.length) {
		return arrayOfObjects;
	}

	return arrayOfObjects.sort(
		(prev, next) => ascending ? prev[key] - next[key] : next[key] - prev[key],
	);
};

export default sortArrayOfObjectsByKey;