import React from 'react';

import PermissionService, { MODULE_KEYS as pm } from "../../services/PermissionService";

// const Tima = lazy( () => import( './components/Tima' ) );
import Tima from './components/Tima';


export const timaConfig = [
	{
		permission: pm.TIMA_TIMA_AGENTS,
		action: PermissionService.showRoute(),
		component: <Tima activeTab='agent'/>,
		path: '/tima/agent/:agentId',
	},
	{
		permission: pm.TIMA_TIMA_AGENTS,
		action: PermissionService.indexRoute(),
		component: <Tima activeTab='agents' />,
		path: [
			'/tima/agents/strategy/:strategyId/filter/:filterId',
			'/tima/agents/strategy/:strategyId',
			'/tima/agents/filter/:filterId',
			'/tima/agents',
		],
	},

	{
		permission: pm.TIMA_TIMA_CONDITIONS,
		action: PermissionService.showRoute(),
		component: <Tima activeTab='condition' />,
		path: '/tima/condition/:conditionId',
	},
	{
		permission: pm.TIMA_TIMA_CONDITIONS,
		action: PermissionService.indexRoute(),
		component: <Tima activeTab='conditions' />,
		path: [
			'/tima/conditions/agent/:agentId/filter/:filterId',
			'/tima/conditions/agent/:agentId',
			'/tima/conditions/strategy/:strategyId/filter/:filterId',
			'/tima/conditions/strategy/:strategyId',
			'/tima/conditions/filter/:filterId',
			'/tima/conditions',
		],
	},

	{
		permission: pm.TIMA_TIMA_INVESTMENTS,
		action: PermissionService.showRoute(),
		component: <Tima activeTab='investment' />,
		path: '/tima/investment/:investmentId',
	},
	{
		permission: pm.TIMA_TIMA_INVESTMENTS,
		action: PermissionService.indexRoute(),
		component: <Tima activeTab='investments' />,
		path: [
			'/tima/investments/agent/:agentId/filter/:filterId',
			'/tima/investments/agent/:agentId',
			'/tima/investments/condition/:conditionId/filter/:filterId',
			'/tima/investments/condition/:conditionId',
			'/tima/investments/strategy/:strategyId/filter/:filterId',
			'/tima/investments/strategy/:strategyId',
			'/tima/investments/filter/:filterId',
			'/tima/investments',
		],
	},

	{
		permission: pm.TIMA_TIMA_STRATEGIES,
		action: PermissionService.showRoute(),
		component: <Tima activeTab='strategy' />,
		path: '/tima/strategy/:strategyId',
	},
	{
		permission: pm.TIMA_TIMA_STRATEGIES,
		action: PermissionService.indexRoute(),
		component: <Tima activeTab='strategies' />,
		path: [
			'/tima/strategies/filter/:filterId',
			'/tima/strategies',
			'/tima/filter/:filterId',
			'/tima',
		],
	},

];
