import React from 'react';
import { withRouter, Link, } from 'react-router-dom';
import { withLocalize, } from "react-localize-redux";

class SearchTopBar extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      query: '',
    };
  }

  componentDidMount = async () => {
    await this.save({ query: this?.query?.get?.('q') ?? '', });
  };

  save = async state => new Promise((next) => {
    this.setState(state, next);
  });

  get location () {
    return new URL(window?.location?.href);
  }

  get query () {
    return this.location.searchParams;
  }

  get search () {
    const search = this.location;

    search.pathname = '/search';
    search.searchParams.set('q', this?.state?.query ?? '');
    search.searchParams.delete('pageA');
    search.searchParams.delete('pageB');
    search.searchParams.delete('pageC');
    search.searchParams.delete('pageD');

    return search;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { pathname, search, } = this.search;

    this.props.history.push(`${ pathname }${ search }`);
  }

  render = () => {
    const { translate, } =this.props;
    const search = this.search;

    return (
      <form
        className="topbar-wrapper"
        onSubmit={ this.handleSubmit }
      >
        <span className="topbar-search__input">
          <input
            onChange={ async (event) => {
              await this.save({ query: event?.target?.value, });
            } }
            placeholder={ translate('topNav_search') }
            type="text"
            value={ this?.state?.query ?? '' }
          />
        </span>
        <Link
          className="topbar-search__button"
          to={ `${ search?.pathname }${ search.search }` }
        />
      </form>
    );
  };
}

export default withRouter(withLocalize(SearchTopBar));