import {
	itemsBuild,
	itemsDrain,
	itemsResort,
	CFG_ITEM_TYPE,
} from '../Config';

const f_status = {
	'drop-down': '/api/partners/programs/drop-down?take=all',

};

export default {
	id: 162,
	...[

				// filters
		...[ {
			f: 'partners.programs.id',
			id: 'f:id',
		}, {
			f: 'partners.programs.name',
			id: 'f:name',
		},
		{
			f: 'partners.programs.client_name',
			id: 'f:clients',
		}, {
			f: 'partners.programs.show_referral_data',
			id: 'f:referrals',
		}, {
			f: 'partners.programs.allow_see_create_rebate',
			id: 'f:rebate',
		}, {
			f: 'partners.programs.show_amount_referrals_funds',
			id: 'f:amount',
		}, {
			f: 'partners.programs.cookie_lifetime_days',
			id: 'f:cookie',
		}, {
			f: 'partners.programs.status',
			id: 'f:status',
			...f_status,
		},
		{
			f: 'partners.new_partners_conditions.level',
			id: 'f:level',
		}, {
			f: 'partners.new_partners_conditions.type',
			id: 'f:type',
		}, {
			f: 'partners.programs.public_program',
			id: 'f:publicProgram',
		},
		{
			f: 'partners.programs.created_at',
			id: 'f:created',
		}, {
			f: 'partners.programs.updated_at',
			id: 'f:updated',
		}, ]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

				// orders
		...[ {
			f: 'partners.programs.id',
			id: 'o:id',
		}, {
			f: 'partners.programs.name',
			id: 'o:name',
		}, {
			f: 'partners.programs.client_name',
			id: 'o:clients',
		},
		// 		{
		// 	f: 'partners.programs.show_referral_data',
		// 	id: 'o:referrals',
		// }, {
		// 	f: 'partners.programs.allow_see_create_rebate',
		// 	id: 'o:rebate',
		// },
		{
			f: 'partners.programs.show_amount_referrals_funds',
			id: 'o:amount',
		}, {
			f: 'partners.programs.cookie_lifetime_days',
			id: 'o:cookie',
		}, {
			f: 'partners.programs.status',
			id: 'o:status',
		}, {
			f: 'partners.programs.public_program',
			id: 'o:publicProgram',
		},
		{
			f: 'partners.programs.created_at',
			id: 'o:created',
		}, {
			f: 'partners.programs.updated_at',
			id: 'o:updated',
		}, ]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

				// panels
		...[ {
			ii: 'f:id',
			t: 'new_partners_condition_id',
		}, {
			ii: 'f:name',
			t: 'new_partners_condition_name',
		}, {
			ii: 'f:clients',
			t: 'affiliate_programs_name_for_clients',
		},
		// 		{
		// 	ii: 'f:referrals',
		// 	t: 'affiliate_programs_show_referrals_filter',
		// }, {
		// 	ii: 'f:rebate',
		// 	t: 'affiliate_programs_show_rebate_projects_filter',
		// },
		{
			ii: 'f:amount',
			t: 'affiliate_programs_show_amount_of_referral_filter',
		}, {
			ii: 'f:cookie',
			t: 'affiliate_programs_cookie_lifetime',
		}, {
			ii: 'f:status',
			t: 'new_partners_condition_status',
		},
		{
			ii: 'f:type',
			t: 'affiliate_programs_condition_type',
		}, {
			ii: 'f:level',
			t: 'affiliate_programs_condition_level',
		},

		{
			ii: 'f:publicProgram',
			t: 'affiliate_programs_public_program',
		},
		{
			ii: 'f:created',
			t: 'new_partners_condition_created_at_filter',
		}, {
			ii: 'f:updated',
			t: 'new_partners_condition_updated_at_filter',
		}, ]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

	]
		.map(itemsBuild)
		.reduce(itemsDrain, [])
		.reduce(itemsResort, {}),

	path: 'partners/programs',
	widget: false,
};