export const transactionsLang = {
  "transactions_filters": ["Filters", "Фильтры", "Фільтри"],
  "transactions_find_placeholder": ["Search by card number/accounts, Full Name", "Поиск по номеру карты/счёта, ФИО клиента", "Пошук за номером карти/рахунку, ПІБ клієнта"],
  "transactions_period_from": ["Period from", "Период от", "Період від"],
  "transactions_period_to": ["Period to", "Период до", "Період до"],
  "transactions_deposit": ["Deposit", "Пополнения", "Поповнення"],
  "transactions_withdraw": ["Withdraw", "Выводы", "Виводи"],
  "transactions_apply": ["Apply", "Применить", "Застосувати"],
  "transactions_reset": ["Reset", "Сброс", "Скасувати"],
  "transactions_transactions": ["Transactions", "Операции","Операції"],
  "transactions_export_to_excel": ["Export to Excel", "Экспорт в Excel","Експорт до Excel"],
  "transactions_export_to_pdf": ["Export to PDF", "Экспорт в PDF","Експорт до PDF"],
  "transactions_request_number": ["№ request", "№ запроса","№ запиту"],
  "transactions_created": ["Created", "Созданный","Створено"],
  "transactions_account": ["Account", "Счет", "Рахунок"],
  "transactions_account_number": ["Account number", "Номер счета","Номер рахунку"],
  "transactions_card_holder": ["Card holder", "Владелец карты","Власника картки"],
  "transactions_operation_type": ["Type Operation", "Тип операции","Тип операції"],
  "transactions_client": ["Client", "Клиент","Клієнт"],
  "transactions_type_information": ["Type information", "Тип информации","Тип інформації"],
  "transactions_payment_system": ["Payment system", "Система оплаты","Платіжної системи"],
  "transactions_operation_status": ["Operation status", "Рабочее состояние","Стан операції"],
  "transactions_first": ["First", "Начало","Початок"],
  "transactions_last": ["Last", "Последняя","Остання"],
}