import React, { Component, } from "react";
import { withRouter, Redirect, } from "react-router-dom";
import { withLocalize, } from "react-localize-redux";

import PermissionService from "../../../../services/PermissionService";

import { HorizontalMenu, } from "../../components";
import PartnersSystemHeader from "../ components/PartnersSystemHeader";

import * as configBreadcrumbs from './config/BreadcrumbsConfig';
import partnersChildren from "./config/redirectConfig";

class Partners extends Component {
	constructor (props) {
		super(props);
	}

	save = async state => new Promise(next => this.setState(state, next));

	getPermissions = (path, action) => {
		const pm = PermissionService.calc([
			{
				path,
				key: "user",
			},
		]);

		return pm.user.access(action);
	};

	findFirstAccessableLink = (parent) => {
		const accessableChildren = parent.find(children =>
			this.getPermissions(children.path, "index"),
		);

		return accessableChildren?.to;
	};

	render () {
		const { translate, } = this.props;
		const breadcrumbsItems = configBreadcrumbs.breadcrumbs4Partners;
		const redirectTo = this.findFirstAccessableLink(partnersChildren);

		return (
			<div className="partners-partners">
				<PartnersSystemHeader
					breadcrumbsItems={ breadcrumbsItems }
					title={ "partners_system_categories_partners" }
					translate={ translate }
				/>
				<Redirect to={ redirectTo } />
				<HorizontalMenu />
			</div>
		);
	}
}

export default withRouter(withLocalize(Partners));