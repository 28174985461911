import React from 'react';
import * as PropTypes from 'prop-types';
import { MagicFilterInputDate, } from './components/MagicFilterInputDate';
import { MagicFilterInputEnum, } from './components/MagicFilterInputEnum';
import { MagicFilterInputNumber, } from './components/MagicFilterInputNumber';
import { MagicFilterInputText, } from './components/MagicFilterInputText';
import { TYPE, } from '../../services/Options';

export class MagicFilterInput extends React.Component {
	static defaultProps = {
		disabled: false,
		emptyLabel: '-',
		format: 'DD-MM-YYYY',
		locale: 'en',
		placeholder: '-',
	};

	static propTypes = {
		disabled: PropTypes.bool.isRequired,
		emptyLabel: PropTypes.string,
		filter: PropTypes.object.isRequired,
		format: PropTypes.string,
		locale: PropTypes.string,
		placeholder: PropTypes.string,
	};

	get filter() {
		return this.props.filter;
	}

	renderDate = () => (<MagicFilterInputDate { ...{
		className: [
			'magic-filter-input',
			this.filter.enabled ? '' : 'magic-filter-input_disabled',
			'magic-date__filter-input-date',
		],
		...this.props,
	} } />);

	renderEnum = ( data = {} ) => (<MagicFilterInputEnum { ...{
		className: [
			'magic-filter-input',
			this.filter.enabled ? '' : 'magic-filter-input_disabled',
			'magic-select__filter-input-enum',
		],
		...this.props,
		...data, // filter overload for dropDown fields
	} } />);

	renderNumber = () => (<MagicFilterInputNumber { ...{
		className: [
			'magic-filter-input',
			this.filter.enabled ? '' : 'magic-filter-input_disabled',
			'magic-input__filter-input-number',
		],
		...this.props,
		placeholder: '0',
	} } />);

	renderText = () =>(<MagicFilterInputText { ...{
		className: [
			'magic-filter-input',
			this.filter.enabled ? '' : 'magic-filter-input_disabled',
			'magic-input__filter-input-text',
		],
		...this.props,
		placeholder: '-',
	} } />);

	render () {
		const { type } = this.filter;
		let result = this.filter.value;
		// console.log({filter: this.filter});
		if ([ TYPE.date ].includes(type)) {
			result = this.renderDate();
		} else if ([ TYPE.datetime ].includes(type)) {
			result = this.renderDate();
		} else if ([ TYPE.enum ].includes(type)) {
			result = this.renderEnum();
		} else if ([ TYPE.number, TYPE.id, ].includes(type)) {

			// 	if ( filter.dropDown ) {
			// 		const enums = filter.storage.dropDownGet( filter.field )
			// 			.reduce( (drop, item) => { 
			// 				const { id, name, surname } = item;
			// 				// TODO: dropDown concat at frontend
			// 				let value = name;
			// 				if (surname) {
			// 					value = `${ value } ${ surname }`;
			// 				}
			// 				return {
			// 					...drop,
			// 					[ id ]: value,
			// 				};
			// 			}, {});
			// 		const enumToLabel = ( value ) => enums[ value ] || '-';
			// 		const data = {
			// 			enum: filter.valueOf || false,
			// 			enums,
			// 			enumToLabel,
			// 		};
			// 		data.__proto__ = filter;
			// 		return this.renderEnum( { filter: data, } );
			// 	}
			// 	return this.renderNumber();

			result = this.renderNumber();
		} else if ([ TYPE.string ].includes(type)) {
			result = this.renderText();
		} else {
			result = (<b style={{ color: 'red'}}>?</b>);
		}
		return result;
	}
};
