import React from "react";

import letter from "../../../../images/letter.svg";
import call from "../../../../images/call.svg";
import defaultAvatar from "../../../../images/profile_default.jpg";
import { map } from "lodash";
import {Translate} from "react-localize-redux";

class ClientBlock extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            images:     {},
            role:       '2',
            clientName: 'Client',
            fieldName:  'account_id',
        };
    }

    render () {
        const getAccountModel = this.props.getAccountModel;

        return (
            <Translate>
                {({ translate }) => {
                    return (
                        this.props.dataTask.account_id ?
                            map(getAccountModel, (item, key) => {

                                if (item['core-account-id'] === this.props.dataTask.account_id) {
                                    return (
                                        <React.Fragment>
                                            <div className = 'user-image'>
                                                <img alt = 'user' src = { defaultAvatar } />
                                            </div>
                                            <div className = 'user-info'>
                                                <div>{this.state.clientName}</div>
                                                <div className = 'user-name active'>{`${item['core-account-name']}`} <span className = 'circle' /></div>
                                                <div className = 'user-action'>
                                                    <span><img alt = 'call' src = { call } style={{width: '10px'}}/> { translate(`tasks_createform_types_call`) }</span>
                                                    <span><img alt = 'letter' src = { letter } style={{width: '10px'}}/> { translate(`tasks_client_write`) }</span>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    );
                                }
                            })

                            :
                            ""

                    );
                }}
            </Translate>
        );
    }
}

export default ClientBlock;
