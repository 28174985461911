import React, {Component} from 'react';
import {Redirect, withRouter} from 'react-router-dom'
import {debounce} from 'lodash';
import moment from 'moment';
import {MagicButton, MagicInput, MagicSelect} from '../../../../../components/Magic';
import {MagicRadioSwitch} from '../../../../tima/components/Magic/MagicSwitch/MagicRadioSwitch';
import PermissionService from '../../../../../services/PermissionService';
import NotificationService from '../../../../../services/NotificationService';
import {getAccountCountries} from '../../../../clients/services/AccountService';
import {paymentService} from '../../../../../services/PaymentDataService';
import { PAYMENT_SYSTEMS }       from "../consts/PAYMENT_SYSTEMS";
import { ACCOUNT_CURRENCY }      from "../consts/ACCOUNT_CURRENCY";
import { PAYMENT_TYPES }         from "../consts/PAYMENT_TYPES";
import { validateCreatePaymentRequest, onValidFee } from "../helpers/validation-helpers";
import background                from "../../../../../images/background-create-partners-accrual.svg"

class PaymentRequestPopup extends Component {
   state = {
      isDisable: false,
      formData: {
         account_id: "",
         created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
         type: "",
         trading_account_id: "",
         payment_method: "",
         payment_system: "",
         amount:"",
      },
      search: {
         index: 0,
         data: "",
         resultInfo: null,
      },
      data: {},
      paymentMethods: {
         data: [],
         hash: null,
      },
      paymentSystems: {
         data: [],
         hash: null,
      },
      banksInfo: {
         data: [],
         hash: null,
      },
      wallets: {
         data: [],
         hash: null,
      },
      dropDown: {
         // wallets: [],
         // banksInfo: [],
         // paymentMethods: [],
         // paymentSystems: [],
         accountCountries: [],
      },
      errors: [],
      redirectToPayments: false,
   };

   paymentMethodChanges = async (paymentMethods) => {
      if (paymentMethods.hash === this.state?.paymentMethods?.hash) return `${this.constructor.name}.paymentMethodChanges: false`;
      await this.save({ paymentMethods });
      return `${this.constructor.name}.paymentMethodChanges: true`;
   };

   paymentSystemsChanges = async (paymentSystems) => {
      if (paymentSystems.hash === this.state?.paymentSystems?.hash) return `${this.constructor.name}.paymentSystemsChanges: false`;
      await this.save({ paymentSystems });
      return `${this.constructor.name}.paymentSystemsChanges: true`;
   };

   banksChange = async (banksInfo) => {
      if (banksInfo.hash === this.state?.banksInfo?.hash) return `${this.constructor.name}.banksChange: false`;
      await this.save({ banksInfo });
      return `${this.constructor.name}.banksChange: true`;
   };

   walletsChange = async (wallets) => {
      if (wallets.hash === this.state?.wallets?.hash) return `${this.constructor.name}.walletsChange: false`;
      await this.save({ wallets });
      return `${this.constructor.name}.walletsChange: true`;
   };

   componentDidMount = async () => {
      paymentService.subscribe('paymentMethods', this.paymentMethodChanges, this);
      paymentService.subscribe('paymentSystems', this.paymentSystemsChanges, this);
      paymentService.subscribe('bankByCurrency', this.banksChange, this);
      paymentService.subscribe('wallets', this.walletsChange, this);
      await this._getAccountCountries();
   };

   componentWillUnmount = () => {
      paymentService.unsubscribe('paymentMethods', this.paymentMethodChanges, this);
      paymentService.unsubscribe('paymentSystems', this.paymentSystemsChanges, this);
      paymentService.unsubscribe('bankByCurrency', this.banksChange, this);
      paymentService.unsubscribe('wallets', this.walletsChange, this);
   };

   save = async (state) => new Promise(next => this.setState(state, next));

   clearErrorClass = async(name) => {
      if (this.state.errors?.includes(name)) {
         await this.save(state => ({errors: [...state.errors.filter(e => e !== name)],}))
      }
   };

   setOlderAccount = async (systemAccounts=[]) => {
      let trading_account_id, acc_ccy;
      if (systemAccounts?.length > 1) {
         const min = moment.min(systemAccounts?.map?.(d => moment(d?.created_at))).format('YYYY-MM-DD HH:mm:ss');
         const olderSystemAccount = systemAccounts?.find?.(e => e.created_at === min);
         acc_ccy = +olderSystemAccount?.acc_ccy;
         trading_account_id = +olderSystemAccount?.id;
         await this.save(state => ({
            formData: { ...state.formData, acc_ccy, trading_account_id },
         }));
      }
   };

   setToFormData = async (currency = "", system_accounts = []) => {
      let trading_account_id, acc_ccy;
      if (!["", null].includes(currency)) {
         const find = system_accounts?.find?.(e => e?.acc_ccy === currency);
         trading_account_id = find?.id ?? "";
         acc_ccy = find?.acc_ccy ?? "";
      } else {
         trading_account_id = system_accounts?.[0]?.id ?? "";
         acc_ccy = system_accounts?.[0]?.acc_ccy ?? "";
      }
      await this.save(state => ({
         formData: { ...state.formData, acc_ccy, trading_account_id, }
      }));
   };

    _getAccountCountries = async () => {
        try {
            const response = await getAccountCountries();

            response && await this.save(state => ({ dropDown: { ...state.dropDown, accountCountries: response, }}));
        } catch (error) {
            await this.save(state => ({ dropDown: { ...state.dropDown, accountCountries: [], }}));
            error?.showErrorNotification?.();
        }
    };

   _getClientId = async (id, addFormData = true) => {
      let resultInfo = false;
      try {
         await this.save({ isDisable: true, errors: [], });
         const { data } = await paymentService.clientId(id);
         await this.setToFormData(this.state.formData?.acc_ccy, data?.data?.core?.system_accounts);
         if (addFormData) {
            await this.setOlderAccount(data?.data?.core?.system_accounts);
         }
         resultInfo = true;
         await this.save(state => ({
            data: data?.data,
            formData: { ...state.formData, account_id: +id, },
         }));
      } catch (error) {
         resultInfo = false;
      } finally {
         await this.save(state => ({ search: { ...state.search, resultInfo }, isDisable: false }));
      }
   };

   _getSystemAccount = async (systemAccountId) => {
      let acc_ccy = null, trading_account_id = null;
      let resultInfo = false;
      try {
         await this.save({ isDisable: true, errors: [], });
         const { data } = await paymentService.systemAccount(systemAccountId);
         let { account: { id, }, system_accounts } = data?.data?.core;
         if (Object.keys(system_accounts).length) {
            resultInfo = true;
            acc_ccy = +system_accounts?.acc_ccy;
            trading_account_id = +system_accounts?.id;
            await this.save(state => ({
               data: data?.data,
               formData: { ...state.formData, acc_ccy, account_id: id, trading_account_id },
            }));
         }
      } catch (error) {
         resultInfo = false;
         // error.showErrorNotification();
      } finally {
         await this.save(state => ({ search: { ...state.search, resultInfo }, isDisable: false }));
      }
   };

   _getPaymentMethods = async (type) => {
      try {
         const paymentMethods = await paymentService.paymentMethods(type);
         await this.save({
            paymentMethods: {
               hash: paymentMethods.hash,
               data: paymentMethods.data
            }
         });
      } catch (error) {
         error.showErrorNotification();
      }
   };

   _getPaymentSystems = async (type, method, acc_ccy) => {
      try {
         const path = `${PAYMENT_TYPES[+type]}/${PAYMENT_SYSTEMS[+method]}/${ACCOUNT_CURRENCY[+acc_ccy]}`;
         const methodHasDifferentRoute = PAYMENT_SYSTEMS[+method];
         const paymentSystems = methodHasDifferentRoute? await paymentService.paymentSystemsByTypeMethodCurrency(path) : await paymentService.paymentSystems(method);
         await this.save({ paymentSystems });
      } catch (error) {
         error.showErrorNotification();
      }
   };

   _getFeePayer = async (currency, payment_system, type) => {
      try {
         if (currency, payment_system) {
            const { data } = await paymentService.feePayer(currency, payment_system, type);
            await this.save(state => ({ formData: { ...state.formData,fee_payer: data, } }));
         }
      } catch (error) {
         error.showErrorNotification();
      }
   };

   _updateClientCountryName = async () => {
      try {
         const account = this.state?.data?.core?.account ?? {};
         const accountAddresses = this.state?.data?.core?.account_addresses ?? {};
         const accountRegisterType = account?.register_type?.toString();

         let targetAddressObj = {};

         // AccountRegisterTypeEnum: 1 - Individual, 2 - Business, 3 - Test
         switch (accountRegisterType) {
           case '1':
           case '3': {
               // INDIVIDUAL_FACTUAL_ADDRESS = 1,  INDIVIDUAL_REGISTRATION_ADDRESS = 3
               const individualFactualAddress = accountAddresses?.filter((item) => item?.type?.toString() === '1') ?? [];
               const individualRegistrationAddress = accountAddresses?.filter((item) => item?.type?.toString() === '3') ?? [];

               targetAddressObj = individualRegistrationAddress.length ? individualRegistrationAddress[0] : (individualFactualAddress.length ? individualFactualAddress[0] : {});
               break;
           }
           case '2': {
               // CORPORATE_FACTUAL_ADDRESS = 5,  CORPORATE_REGISTRATION_ADDRESS = 7
               targetAddressObj = accountAddresses?.filter((item) => item?.type?.toString() === '7')?.[0] ?? {};
               const corporateRegistrationAddress = accountAddresses?.filter((item) => item?.type?.toString() === '7') ?? [];
               const corporateFactualAddress = accountAddresses?.filter((item) => item?.type?.toString() === '5') ?? [];

               targetAddressObj = corporateRegistrationAddress.length ? corporateRegistrationAddress[0] : (corporateFactualAddress.length ? corporateFactualAddress[0] : {});
               break;
           }
           default: {
               break;
           }
         }

         const country_id = targetAddressObj?.country_id;
         const selectedCountry = this._getSelectedCountryById(country_id);
         const clientCountryName = selectedCountry?.name ?? '';

         await this.save({ clientCountryName });
      } catch (error) {
         error?.showErrorNotification();
      }
   };

   _getSelectedCountryById = (countryId) => {
     let options = this?.state?.dropDown?.accountCountries ?? [];

     return options?.filter((item) => item?.id?.toString() === countryId?.toString())?.[0] ?? null;
   };

   _calcAmountTotal = async (amount) => {
      try {
         const {acc_ccy, payment_system, type} = this.state?.formData;
         if (acc_ccy && payment_system && type) {
            const { data } = await paymentService.calcTotalAmount(amount, acc_ccy, payment_system, type);
            const fee = (parseFloat(data) - parseFloat(amount)).toFixed(2);
            const amount_paid = (parseFloat(amount) - fee).toFixed(2);
            await this.save(state => ( { formData: {...state.formData, fee: String(fee) ?? "", amount_paid, } }));
         }
      } catch (error) {
         error.showErrorNotification();
      }
   };

   _getBankByCurrency = async (id) => {
      try {
         if (id) {
            const banksInfo = await paymentService.bankByCurrency(id);
            await this.save({ banksInfo });
         }
      } catch (error) {
         error.showErrorNotification();
      }
   };

   _getWalletsByAccountId = async (id, paymentSystemId) => {
      try {
         const wallets = await paymentService.wallets(id, paymentSystemId);
         await this.save({ wallets });
      } catch (error) {
         error.showErrorNotification();
      }
   };

   _prepareFormData = (formData) => {
      return Object.fromEntries(
          Object.entries(formData).map(entry => {
             let [key, value] = entry;

             switch (key){
                case("name"):{
                   // если ЮР.ЛИЦО (register_type=2) то меняем при отправке name на company_name (name берем из core=>account=>name)
                   key = "company_name";
                   break
                }
                case("fee"):{
                   value = value > 0 ? `-${value}` : value;
                   break
                }

                //clear redundant fields as we now using specific routes for different methods and types
                case("payment_method"):{
                   return []
                }
                case("type"):{
                  return []
                }
             }

             return [key, value];
          })
      );
   }
   _createNewRequest = async () => {
      try {
         const { formData } = this.state;
         await this.save({ errors: [], isDisable: true, });
         const requestPayload = this._prepareFormData(formData);
         await paymentService.createRequest(requestPayload, formData.payment_method, formData.type);
         await this.save({ redirectToPayments: true, });
      } catch (error) {
         console.log(error)
         if ('error' in error?.response?.data) {
            NotificationService.error({ title: "error", message: error?.response?.data['error'], remove: false, });
         } else {
            await this.save({ errors: Object.keys(error.response?.data?.errors) });
            NotificationService.errors({ ...error?.response?.data, remove: false, });
         }
      } finally {
         await this.save({ isDisable: false, });
      }
   };

   // _createSystemWithdraw = async (trading_account_id, amount) => {
   //    try {
   //       await this.save({ errors: [], isDisable: true, });
   //       await paymentService.systemWithdraw(trading_account_id, { amount, });
   //       await this.save({ redirectToPayments: true, });
   //    } catch (error) {
   //       await this.save({ errors: Object.keys(error.response?.data?.errors) });
   //       NotificationService.errors({ ...error?.response?.data, remove: false, });
   //    } finally {
   //       await this.save({ isDisable: false, });
   //    }
   // };
   //
   // _createSystemDeposit = async (trading_account_id, amount) => {
   //    try {
   //       await this.save({ errors: [], isDisable: true, });
   //       await paymentService.systemDeposit(trading_account_id, { amount, });
   //       await this.save({ redirectToPayments: true, });
   //    } catch (error) {
   //       await this.save({ errors: Object.keys(error.response?.data?.errors), });
   //       NotificationService.errors({ ...error?.response?.data, remove: false, });
   //    } finally {
   //       await this.save({ isDisable: false, });
   //    }
   // };

   _createSystemAccount = async () => {
      try {
         const { account_id, acc_ccy, type, } = this.state?.formData;
         const newSystemAccount = await paymentService.createSystemAccount({
            account_id,
            acc_ccy: String(acc_ccy),
            type,
         });
         await this._getClientId(newSystemAccount?.data?.account_id, false);
      } catch (error) {
         error?.showErrorNotification?.();
      }
   };

   // handlerSystemWithdraw = async () => { //TODO: validation before request??
   //    const { trading_account_id } = this.state.formData;
   //    if (trading_account_id) {
   //       const { amount, } = this.state.formData;
   //       await this._createSystemWithdraw(trading_account_id, amount);
   //    }
   // };
   //
   // handlerSystemDeposit = async () => {
   //    const { trading_account_id, amount, } = this.state.formData;
   //    if (trading_account_id) {
   //       await this._createSystemDeposit(trading_account_id, amount);
   //    } else {
   //       await this._createSystemAccount();
   //       await this._createSystemDeposit(this.state?.formData?.trading_account_id, amount);
   //    }
   // };

   clearSearchData = () => this.setState(state => ({
      formData: {
         account_id: "",
         type: "",
         trading_account_id: "",
         payment_method: "",
         payment_system: "",
         amount: "",
         created_at: state.formData.created_at,
      },
      search: { ...state.search, resultInfo: null, },
      data: {},
   }));

   get paymentConfig() {
      return [
         {
            path: ['core', 'system_accounts', 'id'],
            key: 'requestSystemAccountId',
         },
         {
            path: ['core', 'account', 'id'],
            key: 'requestAccountId',
         },
         {
            path: ['core', 'account', 'name_latin'],
            key: 'requestClientSurnameLatin',
         },
         {
            accessChecked: true,
            key: 'requestSwitch',
            render: this.renderRequestSwitch,
         },
         {
            accessChecked: true,
            key: 'requestSearch',
            render: this.renderRequestSearch,
         },
         {
            path: ['payment', 'orders', 'created_at'],
            key: 'requestDate',
            render: this.renderRequestDate,
         },
         {
            path: ['core', 'account', 'id'],
            key: 'requestClientInfo',
            render: this.renderRequestClientInfo,
         },
         {
            path: ['payment', 'orders', 'type'],
            key: 'requestType',
            render: this.renderRequestType,
         },
         {
            path: ['core', 'system_accounts', 'acc_ccy',],
            key: 'requestCurrency',
            render: this.renderRequestCurrency,
         },
         {
            path: ['payment', 'system_types', 'alias'],
            key: 'requestPaymentMethod',
            render: this.renderRequestPaymentMethod,
         },
         {
            path: ['core', 'account', 'name_latin'],
            key: 'requestClientNameLatin',
            render: this.renderRequestClientLatin,
         },
         {
            path: ['payment', 'systems', 'name'],
            key: 'requestRequestPaymentSystem',
            render: this.renderRequestPaymentSystem,
         },
         {
            path: ['payment', 'systems', 'name'],
            key: 'requestWalletNumber',
            render: this.renderRequestWalletNumber,
         },
         {
            path: ['payment', 'systems', 'name'],
            key: 'requestNewWalletNumber',
            render: this.renderRequestNewWalletNumber,
         },
         {
            path: ['payment', 'systems', 'name'],
            key: 'requestCryptoNumber',
            render: this.renderRequestCryptoNumber,
         },
         {
            path: ['payment', 'systems', 'name'],
            key: 'requestNameClientBank',
            render: this.renderRequestNameClientBank,
         },
         {
            path: ['payment', 'systems', 'name'],
            key: 'requestAccountNumber',
            render: this.renderRequestAccountNumber,
         },
         {
            path: ['payment', 'systems', 'name'],
            key: 'requestClientBankCountry',
            render: this.renderRequestClientBankCountry,
         },
         {
            path: ['core', 'account_addresses', 'country_id'],
            key: 'requestClientCountry',
            render: this.renderRequestClientCountry,
         },
         {
            path: ['payment', 'systems', 'name'],
            key: 'requestClientBankSwift',
            render: this.renderRequestClientBankSwift,
         },
         {
            path: ['payment', 'systems', 'name'],
            key: 'requestClientAddress',
            render: this.renderRequestClientAddress,
         },
         {
            path: ['payment', 'systems', 'name'],
            key: 'requestClientCode',
            render: this.renderRequestClientCode,
         },
         {
            path: ['payment', 'systems', 'name'],
            key: 'requestBankSwift',
            render: this.renderRequestBankInfo('swift'),
         },
         {
            path: ['payment', 'systems', 'name'],
            key: 'requestBankIban',
            render: this.renderRequestBankInfo('iban'),
         },
         {
            path: ['payment', 'systems', 'name'],
            key: 'requestBankCountry',
            render: this.renderRequestBankInfo('country'),
         },
         {
            path: ['payment', 'systems', 'name'],
            key: 'requestBankAddress',
            render: this.renderRequestBankInfo('address'),
         },
         {
            path: ['payment', 'orders', 'amount'],
            key: 'requestRequestAmount',
            render: this.renderRequestAmount,
         },
         {
            path: ['payment', 'orders', 'fee'],
            key: 'requestRequestFee',
            render: this.renderRequestFee,
         },
         {
            path: ['payment', 'orders', 'amount_paid'],
            key: 'requestRequestAmountPaid',
            render: this.renderRequestAmountPaid,
         },
         {
            path: ['payment', 'orders', 'id'],
            key: 'requestBtnCreate',
            render: this.renderRequestBtnCreate,
         },
         {
            accessChecked: true,
            key: 'requestBtnCancel',
            render: this.renderRequestBtnCancel,
         },
      ]
   };

   renderPopupField = ({ title, content, error }) => {
      return (
         <div className={ 'popup__field' }>
            <div className="popup__field popup__field_title">{ title ?? "" }</div>
            <div className={ `popup__field popup__field_content ${ error ? 'error' : '' }` }>{ content }</div>
         </div>
      );
   };

   renderRequestSwitch = (_, { item, }) => {
      if (item?.access('store', 'show')) {
         const {index} = this.state.search;
         const options = {
            direction: "row",
            index,
            texts: ["payment_popup_client_id", "payment_popup_system_account_id",].map(t => this.props.translate(t)),
            values: [0, 1],
            onChange: value => {
               this.clearSearchData();
               this.setState(state => ({ search: { ...state.search, index: value.index, } }));
            },
         };
         return (<div className={ "popup__field " }><MagicRadioSwitch { ...options } /></div>);
      }
      return "-";
   };

   renderRequestSearch = (_, { item, }) => {
      if (item?.access('show')) {
         const { search, isDisable, errors} = this.state;
         const translate = {
            ph: this.props.translate(`payment_popup_${ search?.index === 0 ? "client_id" : "system_account" }_ph`),
            btn: this.props.translate("payment_popup_check"),
            success: this.props.translate("payment_popup_find_success"),
            notFound: this.props.translate("payment_popup_find_not_found"),
         };
         const optionsInput = {
            value: search?.data,
            placeholder: translate?.ph,
            className: errors.includes('account_id') ? 'error' : '',
            onChange: value => {
               this.clearSearchData();
               this.setState(state => ({ search: { ...state.search, data: value.replace(/^0/, ""), } }));
            },
            onValid: value => {
               const regExp = /^[1-9]?[0-9]*$/g;
               if (!regExp.test(value)) throw new Error('error')
            },
         };
         const optionsBtn = {
            disabled: !search?.data?.length || search?.resultInfo || isDisable,
            onClick: debounce(() => search?.index === 0
               ? this._getClientId(search?.data)
               : this._getSystemAccount(search?.data), 300),
            children: translate?.btn,
         };
         const resultMessage = param => param === true
            ? translate?.success
            : param === false ? translate?.notFound : "";
         return (
            <React.Fragment>
               <div className={ "popup__field" }>
                  <MagicInput { ...optionsInput } />
                  <MagicButton { ...optionsBtn } />
               </div>
               <div className={ "popup__field_info" }>{ resultMessage(search?.resultInfo) }</div>
            </React.Fragment>)
      }
      return "-";
   };

   renderRequestClientInfo = (clientId, { item, }) => {
      if (item?.access('show')) {
         const { index: searchType } = this.state.search;
         const { account: { register_type, name, surname } = {}, system_accounts = {} } = this.state.data?.core ?? {};
         const translate = {
            titleClient: this.props.translate("payment_popup_client"),
            titleClientId: this.props.translate("payment_popup_client_id"),
            titleSystemAccount: this.props.translate("payment_popup_system_account_id"),
         };
         const clientName = (regType, name, surname) => +regType === 2 ? name : `${ name } ${ surname }`;
         switch ( searchType ) {
            // ID клиента
            case 0:
               // условие 1:
               // 1) поиск по ID клиента
               // 2) клиент верифицирован
               // 3) клиент имеет 1 балансовый счет
               const case_1 = system_accounts?.length === 1;
               // условие 2:
               // 1) поиск по ID клиента
               // 2) клиент верифицирован
               // 3) клиент имеет 2 балансовых счета
               const case_2 = system_accounts?.length > 1;
               // условие 3:
               // 1) поиск по ID клиента
               // 2) клиент верифицирован
               // 3) клиент НЕ имеет балансовых счетов
               const case_3 = system_accounts === null;
               if (clientId && case_1) {
                  return (
                     <React.Fragment>
                        { this.renderPopupField({
                           title: translate?.titleClient,
                           content: clientName(register_type, name, surname),
                        }) }
                        { system_accounts[0]?.acc_ccy === this.state.formData?.acc_ccy && this.renderPopupField({
                           title: translate?.titleSystemAccount,
                           content: system_accounts[0]?.id,
                        }) }
                     </React.Fragment>
                  );
               } else if (clientId && case_2) {
                  const content = system_accounts?.find(e => e?.acc_ccy === this.state.formData?.acc_ccy)?.id;
                  return (
                     <React.Fragment>
                        { this.renderPopupField({
                           title: translate?.titleClient,
                           content: clientName(register_type, name, surname),
                        }) }
                        { this.renderPopupField({ title: translate?.titleSystemAccount, content }) }
                     </React.Fragment>
                  );
               } else if (clientId && case_3) {
                  return (this.renderPopupField({
                     title: translate?.titleClient,
                     content: clientName(register_type, name, surname),
                  }));
               }
               break;
            // Балансовый счет
            case 1:
               // условие 4:
               // 1) поиск по балансовому счету
               // 2) клиент верифицирован
               // 3) клиент имеет балансовый счет
               const case_4 = system_accounts !== null && Object.keys(system_accounts).length;
               if (clientId && case_4) {
                  return (
                     <React.Fragment>
                        { this.renderPopupField({
                           title: translate?.titleClient,
                           content: clientName(register_type, name, surname),
                        }) }
                        { this.renderPopupField({ title: translate?.titleClientId, content: clientId, }) }
                     </React.Fragment>
                  );
               }
               break;
            default: return "";
         }
      }
   };

   renderRequestCurrency = (acc_ccy, { item, items }) => {
      if (item?.access('show')) {
         const { formData: { acc_ccy, type, payment_method, payment_system }, search: { index: searchType }, } = this.state;
         const allCurrencies = this.props.enums?.core?.AccountCurrencyEnum ?? {};
         const systemAccounts = this.state.data?.core?.system_accounts;
         const translate = {
            title: this.props.translate('payment_popup_currency'),
            selectLabel: this.props.translate("payment_popup_currency_select_currency"),
            notSelected: this.props.translate("payment_popup_currency_not_selected"),
         };
         const error = this.state?.errors?.includes("acc_ccy");
         const clientId = items?.requestAccountId?.valueOf;
         switch (searchType) {
            // поиск по ID клиента
            case 0:
               // есть ID клиента, есть 1 или 2 балансовых счета и не выбран тип (или ПОПОЛНЕНИЕ)
               const case_1 = clientId && systemAccounts !== null && (!type || +type === 1);
               // есть ID клиента, нету БАЛАНСОВЫХ СЧЕТОВ и не выбран тип (или ПОПОЛНЕНИЕ)
               const case_2 = clientId && systemAccounts === null && (!type || +type === 1);
               // есть ID клиента, тип ВЫВОД, 2 Балансовых счета
               const case_3 = clientId && +type === 2 && systemAccounts?.length === 2;
               // есть ID клиента, тип ВЫВОД, 1 Балансовый счет
               const case_4 = clientId && +type === 2 && systemAccounts?.length === 1;
               if (case_4) {
                  return this.renderPopupField({ title: translate?.title, content: allCurrencies[acc_ccy], error });
               } else if (case_1 || case_2 || case_3) {
                  const options = {
                     values: ["", ...Object.keys(allCurrencies)],
                     value: acc_ccy,
                     valueToLabel: value => value === "" ? translate?.selectLabel : allCurrencies[value],
                     ItemProps: value => value === "" ? { disabled: true, } : {},
                     onChange: currency => {
                        this.clearErrorClass('acc_ccy');
                        this.setState(state => ({
                           formData: {
                              ...state.formData,
                              acc_ccy: +currency,
                              created_at: state.formData.created_at,
                              trading_account_id: systemAccounts?.find(e => e?.acc_ccy === +currency)?.id ?? "",
                              payment_system: "",
                              payment_method: "",
                              amount: "",
                              fee: "0",
                           },
                           newWalletNumber: false,
                        }));
                        //если выбрана валюта, платежная система и тип ПОПОЛНЕНИЕ или Метод Крипта
                        if (currency && payment_system && type === 1 ) this._getFeePayer(currency, payment_system, type);
                        //если способ оплаты БАНКОВСКИЙ ПЕРЕВОД
                        if (payment_method === 3) this._getBankByCurrency(currency);
                     }
                  };
                  return this.renderPopupField({ title: translate?.title, content: <MagicSelect { ...options } />, error });
               }
               break;
            // поиск по номеру БАЛАНСОВОГО СЧЕТА
            case 1:
               // есть ID клиента, БАЛАНСОВЫЙ СЧЕТ, асть тип (или нету)
               if (clientId && systemAccounts !== null && (!type || [1, 2].includes(+type))) {
                  return this.renderPopupField({ title: translate?.title, content: allCurrencies[acc_ccy], error });
               }
               break;
            default:
               return this.renderPopupField({ title: translate?.title, content: translate?.notSelected, error });
         }
      }
      return "";
   };

   renderRequestDate = (_, { item, }) => {
      const created_at = this.state?.formData.created_at ?? "-";
      const translate = {
         title: this.props.translate('payment_popup_created_at'),
      };
      if (item?.access('store')) {
         return this.renderPopupField({ title: translate?.title, content: created_at });
      }
      return "-";
   };

   renderRequestType = (_, { item, }) => {
      if (item?.access('show')) {
         const { search: { index }, isDisable, } = this.state;
         const systemAccounts = this.state.data?.core?.system_accounts ?? [];
         const allTypes = this.props?.enums?.payment?.OrderTypeStatusEnum ?? {};
         const translate = {
            title: this.props.translate('payment_popup_type_request'),
            ph: this.props.translate('payment_popup_type_select_type'),
         };
         const error = this.state?.errors?.includes("type");
         const clientIsNotSelected = !this.state.formData.account_id;
         const onChange = async value => {
            this.clearErrorClass('type');
            if (+value === 2 && index === 0) {
               systemAccounts?.length < 2
                  ?  this.setState(state => ({
                     formData: {
                        ...state.formData,
                        acc_ccy: systemAccounts[0]?.acc_ccy ?? "",
                        trading_account_id: systemAccounts[0]?.id ?? "",
                     }
                  }))
                  : false
            }
            this.setState(state => ({
               formData: {
                  acc_ccy: state.formData.acc_ccy,
                  account_id: state.formData.account_id,
                  trading_account_id: state.formData.trading_account_id,
                  created_at: state.formData.created_at,
                  type: +value,
                  payment_system: "",
                  payment_method: "",
                  fee: "0",
                  amount: "",
               },
               newWalletNumber: false,
            }));
            await this._getPaymentMethods(value);
         };
         const options = {
            placeholder: translate?.ph,
            values: ["", ...Object.keys(allTypes)],
            value: this.state.formData?.type,
            disabled: isDisable || clientIsNotSelected,
            onChange,
            ItemProps: value => value === "" ? { disabled: true, } : {},
            valueToLabel: value => value === "" ? translate?.ph : allTypes[value],
         };
         return this.renderPopupField({ title: translate?.title, content: <MagicSelect { ...options } />, error });
      }
      return "";
   };

   renderRequestPaymentMethod = (_, { item, items }) => {
      if (item?.access('show')) {
         const clientId = items?.requestAccountId?.valueOf;
         const DEPOSIT = [2, 6, 3, 4]; // ["WALLET", "CRYPTO_CURRENCY", "BANK_TRANSFER", "SYSTEM_DEPOSIT"]
         const WITHDRAWAL = [2, 6, 3, 5]; // ["WALLET", "BANK_TRANSFER", "SYSTEM_WITHDRAWAL"]
         const {
            formData: { type, acc_ccy, payment_method }, // TODO acc_ccy нужен?
            paymentMethods: { data },
            isDisable
         } = this.state;
         let { name_latin, surname_latin, name, } = this.state.data?.core?.account ?? {};
         const translate = {
            title: this.props.translate("payment_popup_payment_method"),
            selectLabel: this.props.translate("payment_popup_select_payment_method"),
         };
         const error = this.state?.errors?.includes("payment_method");
         const REGISTER_TYPE = +this.state.data?.core?.account?.register_type;
         const values = data
            .filter(item => (type === 1 ? DEPOSIT : WITHDRAWAL).includes(item?.id))
            .map(v => v?.id);
         const options = {
            values: ["", ...values],
            value: payment_method,
            disabled: isDisable,
            ItemProps: value => value === "" ? { disabled: true, } : {},
            valueToLabel: value => value === "" ? translate?.selectLabel : data.find(el => el?.id === value)?.alias,
            onChange: v => {
               this.clearErrorClass('payment_method');
               this.setState(state => ({
                  formData: {
                     acc_ccy: state.formData?.acc_ccy,
                     account_id: state.formData?.account_id,
                     trading_account_id: state.formData?.trading_account_id,
                     created_at: state.formData?.created_at,
                     type: state.formData?.type,
                     payment_system: "",
                     payment_method: v,
                     amount: "",
                     fee: "0",
                  },
                  newWalletNumber: false,
               }));
               let regExp = /[a-zA-Z\-\s]/g;
               name_latin = regExp.test(name_latin) ? name_latin : "";
               surname_latin = regExp.test(surname_latin) ? surname_latin : "";
               const clientName = REGISTER_TYPE === 2 ? { name } : { name_latin, surname_latin };
               v === 3
                  ? this.setState(state => ({ formData: { ...state.formData, ...clientName } }))
                  : false;
               // acc_ccy && (v !== payment_method) ? this._getPaymentSystems(v) : false;
               (v !== payment_method && clientId) ? this._getPaymentSystems(type, v, acc_ccy) : false;
               // type === 2 &&
               v === 3 ? this._updateClientCountryName() : false;
            },
         };

         if (type) {
            return this.renderPopupField({ title: translate?.title, content: <MagicSelect { ...options }/>, error });
         }
      }
      return "";
   };

   renderLatinField = ({ field, translate, onValid = () => {} }) => {
      const options = {
         value: this.state.formData?.[field] ?? "",
         placeholder: this.props?.translate(`payment_popup_client_enter_${ translate?.ph }`),
         onChange: value => {
            this.clearErrorClass(field);
            this.setState(state => ({
               formData: { ...state.formData, [field]: value, } }))},
         onValid,
      };
      return this.renderPopupField({
         title: this.props.translate(`payment_popup_client_${ translate?.title }`),
         content: <MagicInput { ...options } />,
         error: this.state?.errors?.includes(field)
      });
   };

   renderRequestClientLatin = (_, { item, items }) => { // TODO items нужны?
      if (item?.access('show')) {
         const payment_method = this.state.formData?.payment_method === 3; //"BANK_TRANSFER"
         const account = this.state.data?.core?.account ?? {};
         const REGISTER_TYPE = +account?.register_type;
         const case_1 = payment_method && account?.id;
         if (case_1) {
            switch (REGISTER_TYPE) {
               case 1:
               case 3://для тестового акаунта
                  return (
                     <React.Fragment>
                        { this.renderLatinField({
                           field:'name_latin',
                           translate: { title: 'name_latin', ph: 'name_latin',},
                           onValid: value => {
                              const regExp = /^[a-zA-Z\‐‑‒–—\-@#$%^&"'_\-\'\‒\`\’\s]*$/g;
                              if (!regExp.test(value)) throw new Error('error');
                           },
                        }) }
                        { this.renderLatinField({
                           field:'surname_latin',
                           translate: { title: 'surname_latin', ph: 'surname_latin',},
                           onValid: value => {
                              const regExp = /^[a-zA-Z\‐‑‒–—\-@#$%^&"'_\-\'\‒\`\’\s]*$/g;
                              if (!regExp.test(value)) throw new Error('error');
                           },
                        }) }
                     </React.Fragment>
                  )
               case 2:
                  // для юр.лица берем name из core=>account
                  return (
                     this.renderLatinField({
                        field:'name',
                        translate: { title: 'company_latin', ph: 'company_latin', },
                        onValid: value => {
                           const regExp = /^[a-zA-Z\‐‑‒–—\-!@#$%^&*():;"'_.,\-\'\‒\`\’\s]*$/g;
                           if (!regExp.test(value)) throw new Error('error');
                        },
                     })
                  );
               default:
                  return null;
            }
         }
      }
      return null;
   };

   renderRequestPaymentSystem = (_, { item, items }) => {
      const clientId = items?.requestAccountId?.valueOf;
      if (item?.access('store')) {
         const {
            formData: { acc_ccy, payment_system, payment_method, type },
            paymentSystems: { data }
         } = this.state;
         const translate = {
            title: this.props.translate("payment_popup_payment_system"),
            selectLabel: this.props.translate("payment_popup_select_payment_system"),
         };
         const error = this.state?.errors?.includes("payment_system");
         let values;
         switch (acc_ccy) {
            //если валюта USD
            case 1: values = ["", ...data?.filter(e => e?.code === "USD")?.map(item => item?.id)];
               break;
            //если валюта EUR
            case 2: values = ["", ...data?.filter(e => e?.code === "EUR")?.map(item => item?.id)];
               break;
            //в остальных случаях показываем все
            default: values = ["", ...new Set([...data.map(item => item?.id)])];
               break;
         }
         const options = {
            value: payment_system,
            values,
            ItemProps: value => value === "" ? { disabled: true, } : {},
            valueToLabel: value => {
               if (value === "") {
                  return translate?.selectLabel;
               }
               return data.find(el => el?.id === value)?.name;
            },
            onChange: id => {
               this.clearErrorClass("payment_system");
               this.setState(state => ({
                  formData: {
                     ...(state.formData?.name_latin && { name_latin: state.formData?.name_latin }),
                     ...(state.formData?.surname_latin && { surname_latin: state.formData?.surname_latin }),
                     ...(state.formData?.bank_country && { bank_country: state.formData?.bank_country }),
                     ...(state.formData?.name && { name: state.formData?.name }),
                     acc_ccy: state.formData?.acc_ccy,
                     account_id: state.formData?.account_id,
                     trading_account_id: state.formData?.trading_account_id,
                     payment_method: state.formData?.payment_method,
                     created_at: state.formData.created_at,
                     type: state.formData?.type,
                     payment_system: id,
                     amount: "",
                     fee: "0",
                  },
                  newWalletNumber: false,
               }));
               // если выбрана валюта, платежная система и тип ПОПОЛНЕНИЕ или Метод Крипта
               if (acc_ccy && id && type === 1 ) this._getFeePayer(acc_ccy, id, type);
               // если Банковский перевод и мы изменили значение то загружаем банки
               if (payment_method === 3) this._getBankByCurrency(acc_ccy);
               // если Кошелек и мы изменили значение то загружаем кошельки
               if (payment_method === 2 && clientId) this._getWalletsByAccountId(clientId, id);
            },
         };
         const condition = [4, 5].includes(+payment_method) || !payment_method;
         return !condition && this.renderPopupField({ title: translate?.title, content: <MagicSelect { ...options }/>, error });
      }
      return "-";
   };

   renderRequestWalletNumber = (_, { item, }) => {
      if (item?.access('store')) {
         const {
            formData: { payment_method, payment_system, payment_card_id, type },
            wallets: { data = [] },
         } = this.state;
         const translate = {
            title: this.props.translate("payment_popup_wallet"),
            selectLabel: this.props.translate("payment_popup_select_wallet"),
            empty: this.props.translate("payment_popup_empty_wallet"),
            add: this.props.translate("payment_popup_wallet_new"),
         };
         const values = type === 1
            ? ["label", ...data?.filter?.(i => i?.name !== "-")?.map?.(e => e?.id), ""]
            : ["label", ...data?.filter?.(i => (+i?.status === 2 && i?.name !== "-"))?.map?.(e => e?.id)];
         const error = this.state?.errors?.includes("payment_card_id") || this.state?.errors?.includes("payment_card_name");
         const options = {
            values,
            value: payment_card_id || "label",
            ItemProps: value => value === "label" ? { disabled: true, } : {},
            valueToLabel: value => {
               switch (value) {
                  case "label": return translate?.selectLabel;
                  case "": return translate?.add;
                  default : return data?.find(e => e?.id === value)?.name;
               }
            },
            onChange: value => {
               this.clearErrorClass("payment_card_id");
               switch (type) {
                  case 1:
                     this.setState(state => ({
                        newWalletNumber: value === "",
                        formData: { ...state.formData, payment_card_id: value, payment_card_name: '' },
                     }));
                     break;
                  case 2:
                     this.setState(state => ({
                        newWalletNumber: false,
                        formData: { ...state.formData, payment_card_id: value, payment_card_name: '' },
                     }));
                     break;
               }
            },
         };
         if (payment_method === 2 && payment_system) {
            const renderEmptyField = values => {
               // загрузились кошельки и сохранились в стейт
               const case_1 = this.state?.wallets.data.length < 1;
               // ПОПОЛНЕНИЕ и значений больше чем 3
               const case_2 = (type === 1 && values?.length === 2);  //["label", ""]
               // ВЫВОД и значений больше чем 2
               const case_3 = (type === 2 && values?.length === 1); //["label"]

               return (case_1 && (case_2 || case_3)) && <div className="empty">{ translate?.empty }</div>;
            }
            return (
               <React.Fragment>
                  { this.renderPopupField({ title: translate?.title, content: <MagicSelect { ...options }/>, error }) }
                  { renderEmptyField(values) }
               </React.Fragment>
            )
         }
      }
      return "";
   };

   renderRequestNewWalletNumber = (_, { item, }) => {
      if (item?.access('store')) {
         const translate = {
            title: this.props.translate("payment_popup_wallet_new_title"),
            ph: this.props.translate("payment_popup_wallet_new_ph"),
         };
         const { formData: { payment_card_name }, newWalletNumber } = this.state;
         const options = {
            value: payment_card_name ?? "",
            onChange: value => {
               this.clearErrorClass("payment_card_name");
               this.setState(state => ({ formData: { ...state.formData, payment_card_name: value, } }));
            },
            onValid: value => {
               const regExp1 = /^[a-z0-9.-@]*$/gi;
               const regExp2 = /@.*?@/;
               if (!regExp1.test(value) || regExp2.test(value)) throw new Error('error');
            },
            placeholder: translate?.ph,
         }
         if (newWalletNumber) {
            return this.renderPopupField({ title: translate?.title, content: <MagicInput { ...options }/>, });
         }
      }
      return "";
   };

   renderRequestCryptoNumber = (_, { item, }) => {
      if (item?.access('store')) {
         const { payment_card_name, destination_tag, payment_system, payment_method, type } = this.state.formData;
         const translate = {
            title: this.props.translate("payment_popup_crypto_adress"),
            placeholder: this.props.translate("payment_popup_enter_crypto_number"),
         };
         const error = this.state?.errors?.includes("payment_card_name");
         const isWithdrawal = +type === 2;
         const options = {
            value: payment_card_name,
            placeholder: translate?.placeholder,
            onChange: value => {
               this.clearErrorClass("payment_card_name");
               this.setState(state => ({
                  formData: { ...state.formData, payment_card_name: value },
               }))},
            onValid: value => {
               const regExp = /^[a-zA-Z0-9:]{0,100}$/g;
               if (!regExp.test(value)) throw new Error('error');
            },
         }
         //если выбрана платежная система Криптовалюта и Платежная система
         if (payment_system && payment_method === 6) { //[6 => CRYPTO_CURRENCY]
            const optionsDestTag = {
               value: destination_tag,
               placeholder: 'Destination tag',
               onChange: value => this.setState(state => ({
                  formData: { ...state.formData, destination_tag: value },
               })),
               onValid: value => {
                  const regExp = /^[0-9]{0,100}$/g;
                  if (!regExp.test(value)) throw new Error('error');
               }
            };
            return (
               <React.Fragment>
                  { this.renderPopupField({ title: translate?.title, content: <MagicInput { ...options } />, error }) }
                  {/*если выбрана платежная система Криптовалюта и это Вывод*/ }
                  {isWithdrawal && this.renderPopupField({
                     title: this.props.translate('payment_popup_destination_tag'), content: <MagicInput { ...optionsDestTag } />
                  })}
               </React.Fragment>);
         }
      }
      return "";
   };

   renderRequestNameClientBank = (_, { item }) => {
      if (item?.access('store')) {
         const translate = {
            title: this.props.translate("payment_popup_name_client_bank"),
            ph: this.props.translate("payment_popup_enter_name_client_bank"),
         };
         const error = this.state?.errors?.includes("bank_name");
         const { formData: { bank_name, type, payment_method } } = this.state;
         const options = {
            value: bank_name,
            placeholder: translate?.ph,
            onChange: value => {
               this.clearErrorClass("bank_name");
               this.setState(state => ({
                  formData: { ...state.formData, bank_name: value, },
               }))},
            onValid: value => {
               const regExp = /^[a-zA-Z0-9\,\.\s\-\'\&]{0,100}$/g;
               if (!regExp.test(value)) throw new Error('error');
            }
         };
         // если ВЫВОД и БАНКОВСКИЙ ПЕРЕВОД
         if (type === 2 && payment_method === 3) {
            return this.renderPopupField({ title: translate?.title, content: <MagicInput { ...options } />, error });
         }
      }
      return "";
   };

   renderRequestAccountNumber = (_, { item }) => {
      if (item?.access('store')) {
         const translate = {
            title: this.props.translate("payment_popup_account_number"),
            ph: this.props.translate("payment_popup_enter_account_number"),
         };
         const error = this.state?.errors?.includes("payment_card_name");
         const { formData: { payment_card_name, type, payment_method } } = this.state;
         const options = {
            value: payment_card_name,
            placeholder: translate?.ph,
            onChange: value => {
               this.clearErrorClass("payment_card_name");
               this.setState(state => ({
                  formData: { ...state.formData, payment_card_name: value.toUpperCase(), },
               }))},
            onValid: value => {
               const latinLettersRegexp = /^[a-zA-Z]$/g;
               const regExp = /^[a-zA-Z0-9]{0,34}$/g;
               if (!regExp.test(value)) throw new Error('error');
               //only first two letters can be latin symb
               if( latinLettersRegexp.test(value.at(-1)) && value.length > 2) throw new Error('error');
            }
         };
         //если ВЫВОД и БАНКОВСКИЙ ПЕРЕВОД
         if (type === 2 && payment_method === 3) {
            return this.renderPopupField({ title: translate?.title, content: <MagicInput { ...options } />, error });
         }
      }
      return "";
   };

   renderRequestClientCountry = (_, { item, items }) => { //TODO нужны items?
      if (item?.access('show')) {
         const {
            clientCountryName,
            formData: { type, payment_method },
         } = this.state;
         const translate = {
            title: this.props.translate("payment_popup_client_country"),
         };
         // если ВЫВОД и БАНКОВСКИЙ ПЕРЕВОД
         if (["2", 2].includes(type) && ["3", 3].includes(payment_method)) {
            return this.renderPopupField({ title: translate?.title, content: clientCountryName, });
         }
      }
      return "";
   };

   renderRequestClientBankCountry = (_, { item }) => {
      if (item?.access('store')) {
         const { formData: { bank_country, type, payment_method } } = this.state;
         const translate = {
            title: this.props.translate("payment_popup_client_bank_country"),
            ph: this.props.translate("payment_popup_enter_client_bank_country"),
         };
         const error = this.state?.errors?.includes("bank_country");
         const options = {
            value: bank_country,
            placeholder: translate?.ph,
            onChange: value => {
               this.clearErrorClass("bank_country");
               this.setState(state => ({ formData: { ...state.formData, bank_country: value, } }));
            },
            onValid: value => {
               const regExp = /^[a-z\,\.\-\'\s]{0,100}$/gi;
               if (!regExp.test(value)) throw new Error('error');
            }
         };
         // если ВЫВОД и БАНКОВСКИЙ ПЕРЕВОД
         if (type === 2 && payment_method === 3) {
            return this.renderPopupField({ title: translate?.title, content: <MagicInput { ...options } />, error });
         }
      }
      return "";
   };

   renderRequestClientBankSwift = (_, { item }) => {
      if (item?.access('store')) {
         const { formData: { recipient_swift_bic, type, payment_method } } = this.state;
         const translate = {
            title: this.props.translate("payment_popup_client_bank_swift"),
            ph: this.props.translate("payment_popup_enter_client_bank_swift"),
         };
         const error = this.state?.errors?.includes("recipient_swift_bic");
         const options = {
            value: recipient_swift_bic ?? "",
            placeholder: translate?.ph,
            onChange: value => {
               this.clearErrorClass("recipient_swift_bic");
               this.setState(state => ({ formData: { ...state.formData, recipient_swift_bic: value?.toUpperCase(), } }));
            },
            onValid: value => {
               const regExp = /^[a-z0-9]{0,11}$/gi;
               if(!regExp.test(value)) throw new Error('error');
            }
         };
         // если ВЫВОД и БАНКОВСКИЙ ПЕРЕВОД
         if (type === 2 && payment_method === 3) {
            return this.renderPopupField({ title: translate?.title, content: <MagicInput { ...options } />, error });
         }
      }
      return "";
   };

   renderRequestClientAddress = (_, { item }) => {
      if (item?.access('store')) {
         const { formData: { recipient_address, type, payment_method } } = this.state;
         const translate = {
            title: this.props.translate("payment_popup_client_address"),
            ph: this.props.translate("payment_popup_enter_client_address"),
         };
         const error = this.state?.errors?.includes("recipient_address");
         const options = {
            value: recipient_address ?? "",
            placeholder: translate?.ph,
            onChange: value => {
               this.clearErrorClass("recipient_address");
               this.setState(state => ({ formData: { ...state.formData, recipient_address: value, } }));
            },
            onValid: value => {
               const regExp = /^[a-z0-9\,\.\/\s]{0,100}$/gi;
               if (!regExp.test(value)) throw new Error('error');
            }
         };
         // если ВЫВОД и БАНКОВСКИЙ ПЕРЕВОД
         if (type === 2 && payment_method === 3) {
            return this.renderPopupField({ title: translate?.title, content: <MagicInput { ...options } />, error });
         }
      }
      return "";
   };

   renderRequestClientCode = (_, { item }) => {
      if (item?.access('store')) {
         const translate = {
            title: this.props.translate("payment_popup_client_code"),
            ph: this.props.translate("payment_popup_enter_client_code"),
         };
         const error = this.state?.errors?.includes("client_code");
         const { formData: { client_code, type, payment_method } } = this.state;
         const options = {
            value: client_code,
            placeholder: translate?.ph,
            onChange: value => {
               this.clearErrorClass("client_code");
               this.setState(state => ({
                  formData: { ...state.formData, client_code: value, },
               }))},
            onValid: value => {
               const regExp = /^[a-zA-Z0-9]{0,100}$/g;
               if (!regExp.test(value)) throw new Error('error');
            },
         };
         // если ВЫВОД и БАНКОВСКИЙ ПЕРЕВОД
         if (type === 2 && payment_method === 3) {
            return this.renderPopupField({ title: translate?.title, content: <MagicInput { ...options } />, error });
         }
      }
      return "";
   };

   renderRequestBankInfo = field => (_, { item, }) => {
      const { banksInfo: { data }, formData: { payment_system, payment_method } } = this.state;
      if (item?.access('show')) {
         const title = this.props.translate(`payment_popup_bank_${field}`);
         const content = data?.find(el => el?.system_id === payment_system)?.[field];
         //если выбрана Платежная система и это Банковский перевод
         if (payment_system && payment_method === 3) {
            return this.renderPopupField({ title, content });
         }
      }
      return "";
   };

   renderRequestAmount = (_, { item, }) => {
      if (item?.access('store')) {
         const translate = {
            title: this.props.translate("payment_popup_amount"),
            ph: this.props.translate("payment_popup_enter_amount"),
         };
         const error = this.state?.errors?.includes("amount");
         const { formData: { amount, type, fee_payer, acc_ccy, payment_system, payment_method, fee } } = this.state;
         const options = {
            value: amount,
            placeholder: translate?.ph,
            disabled: (!payment_system && ![4, 5].includes(payment_method)) || !acc_ccy,
            onChange: value => {
               //by task can take both symbs, but in calculations ',' - returns NaN
               const sanitizedValue = value.replace(',', '.')
               this.clearErrorClass("amount");
               this.setState(state => ({
                  formData: { ...state.formData, amount: sanitizedValue, amount_paid: (parseFloat(sanitizedValue?.length ? sanitizedValue : "0") - parseFloat(fee || 0)).toFixed(2) },
               }));
            },
            onValid: value => {
               const regExp = /^[0-9]*\,?\.?[0-9]{0,2}?$/;
               const is_crypto = +payment_method === 6;
               const is_system = [ 4, 5, ].includes(+payment_method);
               const maxValue = is_system ? 9_999_999.99 : is_crypto ? 1_000_000 : 14500;

               if (value !== "" && !regExp.test(value)) throw new Error('error');

               if (value > maxValue) throw new Error('error');


               if ((/^0[0-9]/).test(value)) {
                  throw new Error("xxx");
               }

               if (value.length > 3 && +value < 0.01) {
                  throw new Error("xxx");
               }

               // if (value[0] === '0' && value[1] === '0') throw new Error('error');

               //by given task can accept '.' or ',', but only if there is no one of them already
               if(value.split('').filter(char => char === '.' || char === ',').length > 1) throw new Error('error');

               const case_1 = ![4, 5].includes(payment_method);   // не СИСТЕМНЫЙ ВЫВОД или СИСТЕМНОЕ ПОПОЛНЕНИЕ или КРИПТА
               const case_2 = +type === 1;                        // ПОПОЛНЕНИЕ
               const case_3 = fee_payer === "COMPANY";            // плательщик КОМПАНИЯ
               if (value && case_1 && case_2 && case_3) this._calcAmountTotal(value);
            },
         }
         return this.renderPopupField({ title: translate?.title, content: <MagicInput { ...options } />, error });
      }
      return "-";
   };

   renderRequestFee = (_, { item, }) => {
      if (item?.access('store')) {
         const { formData: { fee, type, fee_payer, acc_ccy, amount, payment_system, payment_method } } = this.state; //TODO acc_ccy, payment_system?
         const isCryptoTransaction = +payment_method === 6;
         const translate = {
            title: this.props.translate("payment_popup_fee"),
            ph: this.props.translate("payment_popup_enter_fee"),
         };
         const error = this.state?.errors?.includes("fee");
         const options = {
            value: fee,
            placeholder: translate?.ph,
            // disabled: !payment_system || !acc_ccy,
            disabled: !isCryptoTransaction,
            onChange: value => {
               this.clearErrorClass("fee");
               this.setState(state => ({
                  formData: { ...state.formData, fee: value, amount_paid: (Number(amount) - Number(value || 0)).toFixed(2)}
               }))},
            onValid: value => onValidFee(value, type, payment_method),
         };
         const notSystemTransaction = ![4, 5,].includes(payment_method);
         const typeIsSelected = type !== "";
         const typeIsNotWithdrawal = +type !== 2;
         const companyPaysFee = fee_payer === "COMPANY";

         if ( companyPaysFee && typeIsSelected && notSystemTransaction && typeIsNotWithdrawal) {
            return this.renderPopupField({ title: translate?.title, content: <MagicInput { ...options } />, error });
         }
      }
      return "";
   };

   renderRequestAmountPaid = (_, { item }) => {
      if (item?.access('store')) {
         const { formData: { type, amount, fee, fee_payer, amount_paid, payment_method } } = this.state; //TODO amount, fee?
         const translate = {
            title: this.props.translate(`payment_popup_${ type === 1 ? 'withdrawal_sum' : 'deposit_sum' }`),
         };
         const error = this.state?.errors?.includes("amount_paid");

         const notSystemTransaction = ![4, 5,].includes(payment_method);
         const typeIsSelected = type !== "";
         const typeIsNotWithdrawal = +type !== 2;
         const companyPaysFee = fee_payer === "COMPANY";

         if ( companyPaysFee && typeIsSelected && notSystemTransaction && typeIsNotWithdrawal) {
            return this.renderPopupField({ title: translate?.title, content: amount_paid, error });
         }
      }
      return "";
   };

   renderRequestBtnCreate = (_, { item }) => {
      if (item?.access('store')) {
         const { formData, formData: { payment_method, }, data, isDisable } = this.state; //TODO data?
         const options = {
            onClick: async () => {
               const [errors, errorsMessages] = await validateCreatePaymentRequest(formData, this.props.translate);
               const formDataIsInvalid = errors.length;
               if(formDataIsInvalid){
                  await this.save({errors});
                  return errorsMessages.map(item => {
                     NotificationService.error({...item, message: this.props.translate(item.message)})
                  })
               }

               await this._createNewRequest()
            },
            className: "magic-button__item magic-button__item_popup-create-payments",
            children: this.props.translate('payment_popup_button_create'),
            disabled: isDisable ,
         };
         return (<MagicButton { ...options } />);
      }
   };

   renderRequestBtnCancel = (_, { item }) => {
      if (item?.access('store')) {
         const { handlerPopup, } = this.props;
         const { isDisable } = this.state;
         const options = {
            onClick: () => handlerPopup(),
            className: "magic-button__item magic-button__item_popup-cancel-payments",
            children: this.props.translate('payment_popup_button_cancel'),
            disabled: isDisable,
         };
         return (<MagicButton { ...options } />);
      }
   };

   renderPopup = () => {
      const data = PermissionService.cast(this.state.data, this.paymentConfig);
      return (
         <div className="popup__layout">
            <div className="popup__wrapper scroll">
               <div className="popup__title">
                  { this.props.translate('payment_popup_title') }
                  <i className="popup__close-btn" onClick={ () => this.props.handlerPopup() }> </i>
               </div>
               <div className="popup__content">
                  <div className="content__block">
                     <h2 className="block__title">{ this.props.translate("payment_popup_block_info_client") }</h2>
                     { data?.requestSwitch }
                     { data?.requestSearch }
                     { data?.requestClientInfo }
                     { data?.requestCurrency }
                  </div>
                  <div className="content__block">
                     <h2 className="block__title">{ this.props.translate("payment_popup_block_info_payment") }</h2>
                     { data?.requestDate }
                     { data?.requestType }
                     { data?.requestPaymentMethod }
                     { data?.requestClientNameLatin }
                     { data?.requestRequestPaymentSystem }
                     { data?.requestWalletNumber }
                     { data?.requestNewWalletNumber }
                     { data?.requestCryptoNumber }
                     { data?.requestNameClientBank }
                     { data?.requestAccountNumber }
                     { data?.requestClientCountry }
                     { data?.requestClientBankCountry }
                     { data?.requestClientBankSwift }
                     { data?.requestClientAddress }
                     { data?.requestClientCode }
                     { data?.requestBankSwift }
                     { data?.requestBankIban }
                     { data?.requestBankCountry }
                     { data?.requestBankAddress }
                  </div>
                  <div className="content__block">
                     <h2 className="block__title">{ this.props.translate("payment_popup_block_summ") }</h2>
                     { data?.requestRequestAmount }
                     { data?.requestRequestFee }
                     { data?.requestRequestAmountPaid }
                  </div>
                  <div className="popup__buttons">
                     { data?.requestBtnCreate }
                     { data?.requestBtnCancel }
                  </div>
                  <img src={background} alt="background"/>
               </div>
            </div>
         </div>
      );
   };

   render() {
      return (
         <React.Fragment>
            { this.state.redirectToPayments && <Redirect to={ `/payments` }/> }
            { this.renderPopup() }
         </React.Fragment>)
   }
}

export default withRouter(PaymentRequestPopup);
