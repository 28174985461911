import * as actions from './actionTypes';

const initialState = {
    layout: [
        { w: 10, h: 18, x: 0, y: 0, i: '0', minW: 8, minH: 14, moved: false, 'static': true },
        { w: 4, h: 5, x: 0, y: 11, i: '1', moved: false, 'static': false },
        { w: 3, h: 5, x: 4, y: 11, i: '2', moved: false, 'static': false },
        { w: 3, h: 11, x: 4, y: 0, i: '3', moved: false, 'static': false },
        { w: 4, h: 11, x: 0, y: 0, i: '4', moved: false, 'static': false }
    ],
    briefcase: {}
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.SET_LAYOUTS:
            return {
                ...state,
                layout: action.data,
            };
        case actions.SET_BRIEFCASE:
            return {
                ...state,
                briefcase: action.data,
            };
        case actions.SET_USERS_CATEGORY:
            return {
                ...state,
                usersCategory: action.data.data,
            };
        case actions.SET_USERS_WARMING:
            return {
                ...state,
                usersCategory: action.data.data,
            };
        case actions.SET_DASHBOARD_ACCOUNT:
            return {
                ...state,
                userAccount: action.data,
            };
        case actions.SET_MAIL_TEMPLATES:
            return {
                ...state,
                mailTemplates: action.data,
            };
        case actions.SET_SMS_TEMPLATES:
            return {
                ...state,
                smsTemplates: action.data.data,
            };
        case actions.GET_OPTIONS:
            return {
                ...state,
                options: action.data.options['filter_options'],
            };
        default:
            return state;
    }
}
