import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link, } from 'react-router-dom';
import { withLocalize, } from "react-localize-redux";

import { MagicTable, } from "../../../../components/Magic";
import { MagicTooltip, } from '../../../tima/components/Magic/MagicTooltip';
import { RISKMANAGERENUM_OFF, } from "../../consts/constants";
import { enumService, } from "../../../../services/EnumDataService";

class ListTradingAccounts extends React.Component {
    static propTypes = {
    	data: PropTypes.array,
    };

    static defaultProps = {
    	data: [],
    };

    state = {
    	enums: {},
    };

    get enums () {
    	return this.state?.enums?.data ?? {};
    }

    get params () {
    	return this.props?.match?.params;
    }

    get query () {
    	return new URLSearchParams(this.props?.location?.search);
    }

    get tradingAccountsConfig () {
    	const { translate, } = this.props;
    	const prefix = 'list_accounts_table_';

    	return [
    		{
    			path: [ 'core', 'trading_accounts', 'mt_login', ],
    			key: 'mtLogin',
    			render: this.renderMtLogin,
    			title: `${ prefix }mt_login`,
    		},
    		{
    			path: [ 'core', 'account', 'id', ],
    			key: 'clientId',
    		},
    		{
    			path: [ 'core', 'account', 'name', ],
    			key: 'clientName',
    		},
    		{
    			path: [ 'core', 'account', 'surname', ],
    			key: 'clientSurname',
    		},
    		{
    			path: [ 'core', 'trading_accounts', 'id', ],
    			key: 'tradingAccountId',
    		},
    		{
    			accessChecked: true,
    			key: 'clientFullname',
    			render: this.renderClientFullname,
    			title: `${ prefix }client`,
    		},
    		{
    			path: [ 'core', 'trading_accounts', 'created_at', ],
    			key: 'createdAt',
    			render: this.renderInlineValue,
    			title: `${ prefix }created_at`,
    		},
    		{
    			path: [ 'core', 'trading_accounts', 'updated_at', ],
    			key: 'updatedAt',
    			render: this.renderInlineValue,
    			title: `${ prefix }updated_at`,
    		},
    		{
    			path: [ 'core', 'trading_accounts', 'type', ],
    			key: 'type',
    			render: this.renderEnumField('TradingAccountTypeEnum'),
    			title: `${ prefix }type`,
    		},
    		{
    			path: [ 'core', 'trading_accounts', 'acc_level', ],
    			key: 'accLevel',
    			render: this.renderEnumField('TradingAccountLevelEnum'),
    			title: `${ prefix }acc_level`,
    		},
    		{
    			path: [ 'core', 'trading_accounts', 'acc_ccy', ],
    			key: 'currency',
    			render: this.renderEnumField('AccountCurrencyEnum'),
    			title: `${ prefix }acc_ccy`,
    		},
    		{
    			path: [ 'mt', 'user', 'mt_balance', ],
    			accessChecked: true,
    			key: 'mtBalance',
    			render: this.renderMtField,
    			title: `${ prefix }mt_balance`,
    		},
    		{
    			path: [ 'mt', 'user', 'mt_credit', ],
    			accessChecked: true,
    			key: 'mtCredit',
    			render: this.renderMtField,
    			title: `${ prefix }mt_credit`,
    		},
    		{
    			path: [ 'mt', 'user', 'mt_equity', ],
    			accessChecked: true,
    			key: 'mtEquity',
    			render: this.renderMtField,
    			title: `${ prefix }mt_equity`,
    		},
    		{
    			path: [ 'core', 'trading_accounts', 'status', ],
    			key: 'status',
    			render: this.renderEnumField('TradingAccountStatusEnum'),
    			title: `${ prefix }status`,
    		},
    		{
    			path: [ 'core', 'trading_accounts', 'leverage', ],
    			key: 'leverage',
    			render: this.renderEnumField('TradingAccountLeverageEnum'),
    			title: `${ prefix }leverage`,
    		},
    		{
    			path: [ 'core', 'trading_accounts', 'risk_management', ],
    			key: 'riskManagement',
    			render: this.renderRiskManagement,
    			title: `${ prefix }risk_management`,
    		},
    		{
    			path: [ 'core', 'trading_accounts', 'mt_group', ],
    			key: 'mtGroup',
    			render: this.renderInlineValue,
    			title: `${ prefix }mt_group`,
    		},
    		{
    			path: [ 'core', 'trading_accounts', 'platform', ],
    			key: 'platform',
    			render: this.renderEnumField('AccountBalancesPlatformEnum'),
    			title: `${ prefix }platform`,
    		}, {
    			accessChecked: true,
    			path: [ 'core', 'trading_accounts', 'bonus_exist', ],
    			key: 'bonusExist',
    			render: this.renderBonusValue,
    			title: `${ prefix }mt_bonus_exist`,
    		},

    	].map(({ title: t, xtitle: x, ...item }) => ({ ...item, ...x && { title: x, }, ...t && { title: translate(t), }, }));
    }

    save = async state => new Promise(next => this.setState(state, next));

    enumsChange = async (enums) => {
    	if (enums.hash===this.state?.enums?.hash) {
    		return `${ this.constructor.name }.enumsChange: false`;
    	}
    	await this.save({ enums, });

    	return `${ this.constructor.name }.enumsChange: true`;
    };

    async componentDidMount () {
    	enumService.subscribe('enums', this.enumsChange, this);
    	await enumService.enums;
    }

    componentWillUnmount () {
    	enumService.unsubscribe('enums', this.enumsChange, this);
    }

    renderLink = (path, text, className = "") => (<Link className={ className } to={ path }>{text}</Link>);

    renderInlineValue = (value, { item, items, }) => {
    	if (item?.access?.('index')) {
    		return value ?? '-';
    	}

    	return '-';
    };
	renderBonusValue = (value, { item, items, }) => {
		if (item?.access?.('index')) {
			const bonusValue = value ? 'Yes' : 'No';

			return bonusValue;
		}

		return '-';
	};

    renderMtField = (value, { item, items, }) => {
    	const mtLogin = items?.mtLogin;

    	if (mtLogin?.access('index')) {
    		return value ?? '-';
    	}

    	return '-';
    };

    renderMtLogin = (mt_login, { item, items, }) => {
    	const tradingAccountId = items?.tradingAccountId;

    	if (item?.access?.('index', 'show')) {
    		return this.renderLink(`/list-accounts/tradings/${ tradingAccountId.valueOf }`, mt_login);
    	} else if (item?.access?.('index')) {
    		return mt_login;
    	}

    	return '-';
    };

    renderClientFullname = (value, { items, }) => {
    	const id = items?.clientId;
    	const name = items?.clientName;
    	const surname = items?.clientSurname;

    	if (name?.access?.('index') && surname?.access?.('index')) {
    		const LENGTH_SHORT = 20;
    		let fullname = `${ surname?.valueOf || '' } ${ name?.valueOf || '' }`;

    		fullname = (
    			<MagicTooltip
    				classNameTooltip={ `tooltip tooltip--large-width` }
    				content={ fullname }
    				lengthCut={ LENGTH_SHORT }
	/>
    		);
    		if (id?.access?.('show')) {
    			return this.renderLink(`/clients/${ id.valueOf }`, fullname);
    		}

    		return fullname;

    	}

    	return '-';
    };

    renderEnumField = enumName => (value, { item, }) => {
    	if (item?.access?.('index')) {
    		const enums = this?.enums?.core?.[enumName];

    		return enums?.[value] ?? '-';
    	}

    	return '-';
    };

    renderRiskManagement = (value, { item, }) => {
    	if (item?.access?.('index')) {
    		const enums = this?.enums?.core?.TradingAccountRiskManagerStateEnum;

    		return value === RISKMANAGERENUM_OFF ? enums?.[value] ?? '-' : (<div className="riskManagerStatus" />);
    	}

    	return '-';
    };

    render () {
    	if (!this.props?.data?.length) {
    		return null;
    	}

    	const options = {
    		config: this.tradingAccountsConfig,
    		data: this.props?.data,
    		head: [
    			'mtLogin',
    			'clientFullname',
    			'createdAt',
    			'updatedAt',
    			'type',
    			'accLevel',
    			'currency',
    			'mtBalance',
    			'mtCredit',
    			'mtEquity',
    			'status',
    			'leverage',
    			'riskManagement',
    			'mtGroup',
    			'platform',
    			'bonusExist',
    		],
    	};

    	return (<MagicTable { ...options } />);
    }
}

/*const mapStateToProps = (store) => {
    return {
        enums: store.enums.enums,
    };
};*/

export default withRouter(withLocalize(ListTradingAccounts));