import { GET_ACCOUNT_DOCUMENTS } from 'app/apiRoutes';

import React, { Component } from 'react';
import { securedRequest } from "../../../../../services/RequestService/index";
import errorImg from '../../../../../../app/images/error.svg';
import PropTypes from "prop-types";

export default class LeadDocumentRow extends Component {
    static propTypes = {
        popUpVerificationOpen: PropTypes.func.isRequired,
    };

    constructor (props) {
        super(props);

        this.state = {
            dropdownOpen: false,
            showPopup:    false,
        };
    }

    save = async (state) => new Promise(next => this.setState(state, next));

    togglePopup = () => {
        this.setState({
            showPopup: !this.state.showPopup,
        });
    };

    // downloadFile = (alias_name, file_name) => {
    //     downloadDocument(alias_name, file_name);
    // };

    toggleVerifyStatusSelect = () => {
        this.setState((state) => ({
            dropdownOpen: !state.dropdownOpen,
        }));
    };

    updateVerifyStatus = (event) => {
        securedRequest
            .put(`${GET_ACCOUNT_DOCUMENTS}/${this.props.document.core.account_document.id}`, {
                'status': event.currentTarget.dataset.key,
            })
            .then(() => {
            });
    };

    render () {
        const { props } = this;
        const { enums, index, popUpVerificationOpen } = props;
        const { type, sub_type, created_at, status } = props?.document?.core?.account_document;
        const typeText = enums.AccountDocumentTypeEnum ? enums.AccountDocumentTypeEnum[type] : '';
        const subTypeText = enums.AccountDocumentSubTypeEnum ? enums.AccountDocumentSubTypeEnum[sub_type] : '';
        const statusText = enums.AccountDocumentStatusEnum ? enums.AccountDocumentStatusEnum[status] : '';
        const isScrollToDocumentsBlock = true;

        if (this.props?.document?.src) {
            return (
                <tr className='lead-document-row'>
                    <td>
                        {/*{this.state.showPopup ?
                            <div className='document-viewer'>
                                <span className='close_view_doc' onClick={this.togglePopup}/>
                                <Slider
                                    document={props.document}
                                    documents={props.documents}
                                    downloadFile={this.downloadFile}
                                    client={props.client}
                                    accountId={props.accountId}
                                    getAccountDocuments={props.getAccountDocuments}
                                    hideUploadDocument={props.hideUploadDocument}
                                    togglePopup={this.togglePopup}
                                    statusEnum={enums.AccountDocumentStatusEnum}
                                    typeEnum={enums.AccountDocumentSubTypeEnum}
                                    index={props.index}
                                />
                            </div>
                            : null
                        }
                        <div>
                            {props.document.core.account_document.mime_type === 'application/pdf' ?
                                props.document.src !== 'error' ?
                                <Link to={`/api/core/account-document/get/${props.document.core.account_document.alias_name}`} target="_blank">
                                    <i className='attachment_icon_task fi flaticon-pdf-file-format-symbol'/>
                                </Link> : <img alt='' src={ errorImg } style={{ height: 30, width: 'auto' }}/>
                                :
                                <img alt='' src={ props.document.src !== 'error' ? props.document.src : errorImg } style={props.document.src !== 'error' ? { height: 50, width: 'auto' } : { height: 30, width: 'auto' } } onClick={this.togglePopup}/>
                            }
                        </div>*/}

                        <div>
                            {props?.document?.core?.account_document?.mime_type === 'application/pdf' ?
                                <i className='attachment_icon_task fi flaticon-pdf-file-format-symbol' onClick={popUpVerificationOpen({ activeDocumentIndex: index, isScrollToDocumentsBlock })}/>
                                :
                                <img alt='image'
                                     src={ props?.document?.src !== 'error' ? props?.document?.src : errorImg }
                                     style={props?.document?.src !== 'error' ? { height: 50, width: 'auto' } : { height: 30, width: 'auto' } }
                                     onClick={popUpVerificationOpen({ activeDocumentIndex: index, isScrollToDocumentsBlock })}
                                />
                            }
                        </div>

                    </td>
                    <td>{typeText}</td>
                    <td>{subTypeText}</td>
                    <td>{created_at}</td>
                    <td>{statusText}</td>
                </tr>
            );
        } else {
            return '';
        }
    }
}
