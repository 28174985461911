import React, { Component, Fragment } from "react";
import { withLocalize, } from "react-localize-redux";
import connect from "react-redux/es/connect/connect";
import Preloader from "../../../../components/LoadingHOC/Preloader";
import Loader from "../../../tima/components/Loader";
import PagePanel from "../../../tima/components/Pages/PagePanel";
import { MagicTable } from "../../../../components/Magic/components/MagicTable";
import { listAccountService } from "../../../../services/ListAccountDataService";

class SystemAccountBalanceHistory extends Component {
    constructor (props) {
        super(props);

        const { params, } = props;
        const { id:accountId, } = params || {};

        this.state = {
            loaded: false,
            balanceHistory: {
                data: [],
                hash: null,
            },
            pages: {
                pageId: 0,
                filter: 0,
                total: 0,
                take: 7,
                variance: 2,
            },
            accountId,
        };
    }

    save = async (state) => new Promise(next => this.setState(state, next));

    balanceHistoryChange = async (balanceHistory) => {
        if (balanceHistory.hash===this.state?.balanceHistory?.hash) return `${this.constructor.name}.balanceHistoryChange: false`;
        const { data, meta, hash } = balanceHistory;
        await this.save({
            balanceHistory: { data, hash },
            pages: {
                ...this.state.pages,
                filter: meta?.filter,
                total: meta?.total,
            },
        });
        return `${this.constructor.name}.balanceHistoryChange: true`;
    };

    componentDidMount () {
        listAccountService.subscribe('systemBalanceHistory', this.balanceHistoryChange, this);
        this.onDataLoad();
    }

    componentWillUnmount () {
        listAccountService.unsubscribe('systemBalanceHistory', this.balanceHistoryChange, this);
    }

    get systemAccountBalanceHistoryConfig () {
        const { translate, } = this.props;
        const prefix = 'list_accounts_system_balance_history_';

        return [
            {
                path:   ['core', 'system_accounts_transaction_history', 'id'],
                key:    'id',
                render: this.renderInlineValue,
                title:  `${prefix}id`,
            },
            {
                path:   ['core', 'system_accounts_transaction_history', 'amount'],
                key:    'amount',
                render: this.renderInlineValue,
                title:  `${prefix}amount`,
            },
            {
                path:   ['core', 'system_accounts_transaction_history', 'created_at'],
                key:    'created_at',
                render: this.renderInlineValue,
                title:  `${prefix}created_at`,
            },
            {
                path:   ['core', 'system_accounts_transaction_history', 'comment'],
                key:    'comment',
                render: this.renderInlineValue,
                title:  `${prefix}comment`,
            },

        ].map(({ title: t, xtitle: x, ...item }) => ({ ...item, ...x && { title: x }, ...t && { title: translate(t) }, }));
    }

    renderInlineValue = (value, { item, items }) => {
        if (item?.access?.('index')) {
            return value ?? '-';
        }
        return '-';
    };

    render = () => {
        const { translate, enums, params, query } = this.props;
        const { loaded, balanceHistory: { data }, pages } = this.state;
        const { filter, take, total, variance } = pages;

        const options = {
            config: this.systemAccountBalanceHistoryConfig,
            data,
            head:   [
                'id',
                'amount',
                'created_at',
                'comment',
            ],
        };

        return (
            <Fragment>
                <div className = 'title-cont'>
                    <h3 className = 'title'>
                        {translate(`list_accounts_system_balance_history_title`)}
                    </h3>
                </div>

                <div className = 'table-wrapper'>
                    <div className = 'position-relative'>

                        <Loader
                            loaded={ loaded }
                            loading={ (<Preloader scale={1} />) }
                            translate={ translate }
                        >
                            <MagicTable {...options} />

                            <PagePanel
                                filter={ filter }
                                take={ take }
                                total={ total }
                                variance={ variance }
                                page={ this.pageId() }
                                onClick={ this.onPageClick }
                                doText={ this.onPageText }
                                doTo={ ( pageId ) => `#page-${ pageId + 1 }` }
                            />
                        </Loader>

                    </div>
                </div>
            </Fragment>
        );
    };

    pageId = ( ) => {
        return Number( this.state.pages.pageId ) || 0;
    };

    pageIdAsGET = ( pageId ) => {
        pageId = pageId === undefined ? this.pageId() : pageId;

        return {
            take: this.state.pages.take,
            skip: pageId * this.state.pages.take,
        };
    };

    onDataLoad = ( pageId ) => {
        const setLoaded = ( loaded ) => this.setState( ( ) => ( { loaded, } ) );
        const setData = ( balanceHistory ) => this.setState( ( ) => ( { balanceHistory, } ) );
        const setPageId = ( pageId ) => this.setState( ( state ) => ( { pages: { ...state.pages, pageId, }, } ) );
        const setPages = ( { filter, total } ) => this.setState( ( state ) => ( { pages: { ...state.pages, filter, total, }, } ) );
        const load0 = async ( accountId, pageOptions ) => await listAccountService.systemBalanceHistory( accountId, pageOptions );

        setLoaded( false );
        setPageId( pageId || this.state.pageId );

        ( async () => {
            const pageOptions = this.pageIdAsGET( pageId );
            try {
                const { accountId, } = this.state;
                const { data, meta, hash} = await load0( accountId, pageOptions );
                const balanceHistory = { data, hash };
                setData( balanceHistory );
                setPages( meta );
            } catch ( error ) {
                setData( [ ] );
                setPages( { filter: 0, total: 0, } );

                error?.showErrorNotification?.();
            }
            setLoaded( true );
        } )( );
    };

    onPageClick = ( { event, pageId, pageIs, } ) => {
        event.preventDefault();
        this.onDataLoad( pageId );
    };

    onPageText = ( pageId, pageIs ) => {
        const { translate } = this.props;
        const { current, first, prev, next, last, } = pageIs;
        const { skipped, taken, filter, total, } = pageIs;
        if ( skipped || taken ) {
            const id = Number.isInteger( pageId ) ? `${ pageId + 1 }` : '?';
            const text = skipped ? translate( 'tima_pages_items' ) : '';
            return skipped ? `${ text }: ${ id }` : id;
        } else if ( filter || total ) {
            const id = Number.isInteger( pageId ) ? `${ pageId }` : '?';
            const text = translate( filter ? 'tima_pages_filtered' : 'tima_pages_total' );
            return `${ text }: ${ id }`;
        } else if ( first || prev || next || last ) {
            return '';
        } else if ( current ) {
            return `${ pageId + 1}`;
        } else {
            return `${ pageId + 1}`;
        }
    };
};

const mapStateToProps = (store) => ( { enums: store.enums.enums, } );

export default withLocalize( connect( mapStateToProps, null )( SystemAccountBalanceHistory ) );
