export default (
  translate,
  order = [],
  {
    active,
  } = {},
  permissions,
) => [
  {
    id: `utm_source`,
    link: `/marketing/advertising_channels/channels`,
    text: `${ translate('marketing_link_channels') }`
  },
  {
    id: `utm_medium`,
    link: `/marketing/advertising_channels/subchannels`,
    text: `${ translate('marketing_link_subchannels') }`
  },
  {
    id: `utm_campaign`,
    link: `/marketing/advertising_channels/products`,
    text: `${ translate('marketing_link_products') }`
  },
  {
    id: `utm_link`,
    link: `/marketing/advertising_channels/utm-links-generation`,
    text: `${ translate('marketing_link_utm_links_generation') }`,
    path: ['statistics', 'utm_link']
  },
  {
    id: `account_utm_link`,
    link: `/marketing/advertising_channels/account_utm_link`,
    text: `${ translate('marketing_link_account_utm_link') }`,
    path: ['statistics', 'account_utm_link']
  },
].filter(
  ({ id }) => order.includes(id)
).sort(
  ({ id: a, }, { id: b, }) => order.indexOf(a) - order.indexOf(b)
).map(
  ({
    id,
    ...data
  }) => ({
    ...data,
    key: id,
    ...(id === active) && { active: true },
  })
).filter(
  (item) => {
    if (permissions) {
      switch (item?.key) {
        case 'utm_link': {
          return permissions?.['utm_link']?.access('store') ?? false;
        }
        default: {
          return permissions?.[item?.key]?.access('index') ?? false;
        }
      }
    }
    return true;
  }
);
