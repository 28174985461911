import { itemsBuild, itemsDrain, itemsResort, CFG_ITEM_TYPE, } from '../Config';
// import {} from '../Mentor/withDropDown';

export default {
	id: 121,
	...[

    // filters
		...[
			{
				id: 'f:transactionId',
				f: "core.system_accounts_transaction_history.id",
			},
			{
				id: 'f:transactionClient',
				f: "core.account.name",
			},
			{
				id: 'f:transactionSystemAccount',
				f: "core.system_accounts_transaction_history.system_account_id",
			},
			{
				id: 'f:transactionComment',
				f: "core.system_accounts_transaction_history.comment",
			},
			{
				id: 'f:transactionCreated_at',
				f: "core.system_accounts_transaction_history.created_at",
			},
			{
				id: 'f:transactionUpdated_at',
				f: "core.system_accounts_transaction_history.updated_at",
			},
		 ]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

    // orders
		...[
			{
				id: 'o:transactionId',
				f: "core.system_accounts_transaction_history.id",
			},
			{
				id: 'o:transactionClient',
				f: "core.account.name",
			},
			{
				id: 'o:transactionSystemAccount',
				f: "core.system_accounts_transaction_history.system_account_id",
			},
			{
				id: 'o:transactionAmount',
				f: "core.system_accounts_transaction_history.amount",
			},
			{
				id: 'o:transactionComment',
				f: "core.system_accounts_transaction_history.comment",
			},
			{
				id: 'o:transactionCreated_at',
				f: "core.system_accounts_transaction_history.created_at",
			},
			{
				id: 'o:transactionUpdated_at',
				f: "core.system_accounts_transaction_history.updated_at",
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

    // panels
		...[
			{
				ii: 'f:transactionId',
				t: "balance_operations_id",
			},
			{
				ii: 'f:transactionClient',
				t: "balance_operations_client",
			},
			{
				ii: 'f:transactionSystemAccount',
				t: "balance_operations_system_account",
			},
			{
				ii: 'f:transactionComment',
				t: "balance_operations_comment",
			},
			{
				ii: 'f:transactionCreated_at',
				t: "balance_operations_created_at",
			},
			{
				ii: 'f:transactionUpdated_at',
				t: "balance_operations_updated_at",
			},
		 ]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

	].map(itemsBuild).reduce(itemsDrain, []).reduce(itemsResort, {}),
	path: 'core/system-accounts-transaction-history',
	widget: false,
};