export const SET_CLIENTS = 'SET_CLIENTS';
export const SET_CLIENT = 'SET_CLIENT';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const SET_FILTER = 'SET_FILTER';
export const RESET_FILTER = 'RESET_FILTER';
export const GET_ENUMS = 'GET_ENUMS';
export const GET_OPTIONS = 'GET_OPTIONS';
export const GET_TRADING = 'GET_TRADING';
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const GET_INTERACTION = 'GET_INTERACTION';
export const GET_APPEALS = 'GET_APPEALS';
export const GET_HISTORY = 'GET_HISTORY';
export const GET_TAILS = 'GET_TAILS';
export const CLEAR_ALL_CLIENTS = 'CLEAR_ALL_CLIENTS';
export const GET_FILTER_ID = 'GET_FILTER_ID';
export const SET_ERROR = 'SET_ERROR';
export const RESET_ERROR = 'RESET_ERROR';
export const GET_NEW_CATEGORY = "GET_NEW_CATEGORY";
export const SET_FILTER_CATEGORY = "SET_FILTER_CATEGORY";
export const CLEAR_FILTER_CATEGORY = "CLEAR_FILTER_CATEGORY";
export const GET_SINGLE_TRADING = "GET_SINGLE_TRADING";
export const FILTER_HASH = "FILTER_HASH";
export const UPDATE_APPEALS = "UPDATE_APPEALS";
