import React, { Component, } from "react";
import { withRouter, } from "react-router-dom";
import { withLocalize, } from "react-localize-redux";

import { LIST_ACCOUNTS_EXPORT_FIELDS, } from "../../consts/constants";
import { securedRequest, } from "../../../../services/RequestService";
import Preloader from "../../../../components/LoadingHOC/Preloader";
import PagePanel from "../../../tima/components/Pages/PagePanel";
import Loader from "../../../tima/components/Loader";
import {
	MagicFilterPanels,
	Mentor,
	showExportToExcelError,
} from "../../../../components/Magic";
import ListSystemAccountsMagicTable from "./ListSystemAccountsMagicTable";
import "../../styles/list_accounts.scss";
import PermissionService from "../../../../services/PermissionService";
import { listAccountService, } from "../../../../services/ListAccountDataService";
import { enumService, } from "../../../../services/EnumDataService";

class ListSystemAccounts extends Component {
	constructor (props) {
		super(props);
		this.state = {
			loaded: false,
			systemAccounts: {
				data: [],
				hash: null,
			},
			pages: {
				filter: 0,
				total: 0,
				take: 50,
				variance: 2,
			},
			filterLastChange: Date.now(),
		};

		const mf = new Mentor({
			owner: this,
			serviceId: 64,
			translate: this.props.translate,
		});

		Object.defineProperty(this, "mf", { get: () => mf, });
	}

	// eslint-disable-next-line react/sort-comp, require-await
	save = async state => new Promise(next => this.setState(state, next));

	// eslint-disable-next-line require-await
	mfChanged = async () => {
		await this.save({ filterLastChange: Date.now(), });
	};

	mfDoing = async () => {
		await this.save({ loaded: false, });
	};

	mfDone = async () => {
		await this.mfChanged();
		await this.onDataLoad();
	};

	enumsChange = async (enums) => {
		if (enums.hash === this.state?.enums?.hash) {
			return `${ this.constructor.name }.enumsChange: false`;
		}
		await this.save({ enums, });

		return `${ this.constructor.name }.enumsChange: true`;
	};

	systemAccountsChanges = async (systemAccounts) => {
		if (systemAccounts.hash === this.state?.systemAccounts?.hash) {
			return `${ this.constructor.name }.systemAccountsChanges: false`;
		}
		const { data, meta, hash, } = systemAccounts;

		await this.save({
			systemAccounts: { data, hash, },
			pages: {
				...this.state.pages,
				filter: meta?.filter,
				total: meta?.total,
			},
		});

		return `${ this.constructor.name }.systemAccountsChanges: true`;
	};

	async componentDidMount () {
		this.mf.subscribe(
			{
				changed: this.mfChanged,
				// changing: ,
				cleaned: this.mfDone,
				cleaning: this.mfDoing,
				loaded: this.mfDone,
				loading: this.mfDoing,
				saved: this.mfDone,
				saving: this.mfDoing,
			},
			this,
		);
		await this.mf.init({ doEmit: true, });
		const enums = await enumService.enums;

		await this.save({ enums, });
		enumService.subscribe("enums", this.enumsChange, this);
		listAccountService.subscribe(
			"systemAccounts",
			this.systemAccountsChanges,
			this,
		);
		// await this.mf.emit('change', {});
		// await this.onDataLoad(); // TODO: moved to mfDone
	}

	componentWillUnmount = () => {
		this.mf.unsubscribe([ this.mfChanged, this.mfDoing, this.mfDone, ], this);
		enumService.unsubscribe("enums", this.enumsChange);
		listAccountService.unsubscribe(
			"systemAccounts",
			this.systemAccountsChanges,
			this,
		);
	};

	get systemAccountConfig () {
		return [
			{
				key: "systemAccounts",
				path: [ "core", "system_accounts", ],
			},
		];
	}

	exportToExcel = activeTab => (event) => {
		if (activeTab) {
			const { filterId: filter_id, } = this.mf;
			const globalUrl = `/api/core`;
			const exportUrl = `export-to-excel`;

			let url = "";
			let file_name = "";

			switch (activeTab) {
				case "systemAccounts": {
					url = `${ globalUrl }/system-accounts/${ exportUrl }`;
					file_name = `System_Accounts`;
					break;
				}
				default: {
					break;
				}
			}

			const options = {
				file_name,
				fields: LIST_ACCOUNTS_EXPORT_FIELDS[activeTab],
			};

			if (filter_id) {
				options.filter_id = filter_id;
			}

			securedRequest
				.post(url, options)
				.then((res) => {
					// fileDownload(res.data, res.headers.filename);
				})
				.catch((error) => {
					showExportToExcelError(error);
				});
		}
	};

	renderExportToExcelBtn = (activeTab) => {
		const { translate, } = this.props;
		const conf = PermissionService.calc(this.systemAccountConfig);
		const exportToExcelPermission =
			conf?.systemAccounts?.access("exportToExcel") ?? false;

		return exportToExcelPermission ? (
			<div
				className="button button--turquoise"
				onClick={ this.exportToExcel(activeTab) }
			>
				{translate("export_excel_btn")}
			</div>
		) : null;
	};

	render () {
		const { translate, } = this.props;
		const { enums, } = this.state;
		return (
			<div className="list_accounts">
				<div className="content-block">
					<div className="top-page-block">
						<h1 className="page-title">
							{translate(`list_accounts_system_accounts`)}
						</h1>
						{this.renderExportToExcelBtn("systemAccounts")}
					</div>
				</div>

				<MagicFilterPanels mf={ this.mf } show={ true } translate={ translate } />

				<div className="content-block">
					<div className="table-wrapper">
						<div className="position-relative">
							<Loader
								loaded={this.state.loaded && !!enums }
								loading={ <Preloader scale={ this.props.scale } /> }
								translate={ translate }
							>
								<ListSystemAccountsMagicTable
									data={ this.state?.systemAccounts.data }
									enums={ enums }
								/>
								<PagePanel
									doText={ this.onPageText }
									doTo={ pageId => `?page=${ pageId }` }
									filter={ this.state.pages.filter }
									page={ this.pageId() }
									take={ this.state.pages.take }
									total={ this.state.pages.total }
									variance={ this.state.pages.variance }
									onClick={ this.onPageClick }
								/>
							</Loader>
						</div>
					</div>
				</div>
			</div>
		);
	}

	query = () => new URLSearchParams(this.props.location.search);

	pageId = () => {
		const defaultPageId = 0;

		try {
			const page = this.query().get("page");

			return Number(page) || defaultPageId;
		} catch (e) {
			return defaultPageId;
		}
	};

	pageIdAsGET = (pageId) => {
		pageId = pageId === undefined ? this.pageId() : pageId;
		const { filterId, } = this.mf;
		const result = {
			filterId,
			take: this.state.pages.take,
			skip: pageId * this.state.pages.take,
		};

		return result;
	};

	onDataLoad = async (pageId) => {
		await this.save({ loaded: false, });
		const pageOptions = this.pageIdAsGET(pageId);
		const loaded = (data, meta, hash) => ({ pages, }) => ({
			systemAccounts: { data, hash, },
			pages: { ...pages, ...meta, },
		});

		try {
			const { data, meta, hash, } = await listAccountService.systemAccounts(
				pageOptions,
			);
			const { filter, total, } = meta;

			await this.save(loaded(data, { filter, total, }, hash));
		} catch (error) {
			await this.save(loaded([], { filter: 0, total: 0, }, null));
			error?.showErrorNotification?.();
		}
		await this.save({ loaded: true, });
	};

	onPageClick = ({ event, pageId, pageIs, }) => {
		// event.preventDefault();
		this.onDataLoad(pageId);
	};

	onPageText = (pageId, pageIs) => {
		const { translate, } = this.props;
		const {
			current, first, prev, next, last,
		} = pageIs;
		const {
			skipped, taken, filter, total,
		} = pageIs;

		if (skipped || taken) {
			const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : "?";
			const text = skipped ? translate("partners_pages_items") : "";

			return skipped ? `${ text }: ${ id }` : id;
		} else if (filter || total) {
			const id = Number.isInteger(pageId) ? `${ pageId }` : "?";
			const text = translate(
				filter ? "partners_pages_filtered" : "partners_pages_total",
			);

			return `${ text }: ${ id }`;
		} else if (first || prev || next || last) {
			return "";
		} else if (current) {
			return `${ pageId + 1 }`;
		}

		return `${ pageId + 1 }`;
	};
}

export default withRouter(withLocalize(ListSystemAccounts));