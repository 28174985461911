// field names full path
export const TASKS_EXPORT_FIELDS = {
    tasks: [
        'core-account-name',
        'core-account-surname',
        'tasks-tasks-description',
        'core-account_categories-created_at',
        'core-account_types->array->core-account_types-type_id',
        'core-account_labels->array->core-account_labels-label_id',
        'calls-calls-created_at',
        'core-account_status->array->core-account_status-status_id',
        'core-account_categories-category_id',
        'core-account-is_vip',
        'core-account-is_loyal',
        'core-account-warm_type',
        'core-account-warm_type_last_change',
        'core-user-name',
        'core-user-surname',
        'tasks-task_urgency-name'
    ],
};
