import React from 'react';
import connect from "react-redux/es/connect/connect";
import LeadTailsTable from "./LeadTailsTable";
import { setTails } from '../../../services/AccountService';
import { withRouter } from "react-router-dom";
import { withLocalize, Translate } from 'react-localize-redux';
import { bindActionCreators } from "redux";
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import ComponentLoader from "../../../../../components/HistoryChanges/HistoryChangesComponentTable";

class TailTable extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            accountTails : { ...this.props.accountTails },
        };
    }

    componentDidMount () {
        this.props.setTails(this.props.id);
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        this.setState({
            accountTails: { ...nextProps.accountTails },
        });
    }

    render () {

        if (!this.state.accountTails) {
            return <div className = 'block-shadowed'><ComponentLoader /></div>;
        }

        return (
            <Translate>
                {({ translate }) => {
                    return (
                        <div className = 'block-shadowed'>
                            <span className = 'table-title'>{translate(`client_tail_customer_path`)}</span>
                            <div className = 'react-bs-table table-unbordered-container'>
                                <LeadTailsTable translate = { translate } jsonData = { this.state.accountTails } />
                            </div>
                        </div>
                    );
                }}
            </Translate>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        accountTails: store.clients.accountTails,
    };
};

export default withLocalize(withRouter(connect(
    mapStateToProps,
    (dispatch) =>
        bindActionCreators({
            setTails,
        }, dispatch)
)(TailTable)));
