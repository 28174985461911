import React from 'react';
import PermissionService, { MODULE_KEYS as pm, } from '../../services/PermissionService';

import ClientsWithBonusesList from "./ClientsWithBonusesList";
export const bonusesConfig = [
	{
		permission: pm.BONUS_PROGRAM_TO_ACCOUNT,
		action: PermissionService.indexRoute(),
		component: <ClientsWithBonusesList />,
		path: [
			'/bonuses',
		],
	},
];