import React from "react";
import PropTypes from 'prop-types';
import './styles.scss';
import PageItem, { propTypesPageClick, propTypesPageId, propTypesPageText, propTypesPageTo, } from './PageItem'


const PageList = ( props ) => {
    const { class4list, } = props;
    const { disabled, options, } = props;
    const { current, first, prev, next, last, list, } = options;
    const is = ( value, key ) => ( id ) => value === id ? { [ key ]: true, } : { };
    const item = ( id, is, keyNeeded = false ) => (
        <PageItem
            { ...{
                ...props,
                ...( keyNeeded ? { key: `page-${ id }` } : {} ),
                disabled,
                options: {
                    is: is( id ),
                    id,
                },
            } }
        />
    );
    return (
        <ul className={ class4list } >
            { item( first, is( first, 'first' ), false ) }
            { first < prev ? item( prev, is( prev, 'prev' ), false ) : '' }
            { list.map( ( id ) => item( id, is( current, 'current' ), true ) ) }
            { next < last ? item( next, is( next, 'next' ), false ) : '' }
            { item( last, is( last, 'last' ), true ) }
        </ul>
    );
};


const propTypesListOptions = PropTypes.exact( {
    current: propTypesPageId( 0, false ),
    first: propTypesPageId( 0, false ),
    prev: propTypesPageId( 0, false ),
    list: PropTypes.arrayOf( propTypesPageId( 0, false ) ).isRequired,
    next: propTypesPageId( 0, false ),
    last: propTypesPageId( 0, false ),
} );


PageList.propTypes = {
    class4list: PropTypes.string.isRequired,
    options: propTypesListOptions,
    disabled: PropTypes.bool,
};


PageList.defaultProps = {
    class4list: `page__list`,
    disabled: false,
};


export default PageList;
export {
    propTypesListOptions,
    propTypesPageClick,
    propTypesPageId,
    propTypesPageText,
    propTypesPageTo,
};
