import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { withLocalize, } from "react-localize-redux";
import { Breadcrumbs, } from './Breadcrumbs';
import { ToolbarPanel, } from './Toolbar';
import '../styles/tima.scss';
import * as configBreadcrumbs from './TimaLinkBreadcrumbsConfig';
import * as configToolbar from './TimaLinkToolbarConfig';
import ListTimaStrategies from './ListTimaStrategies';
import OneTimaStrategy from './OneTimaStrategy';
import ListTimaInvestments from './ListTimaInvestments';
import OneTimaInvestment from './OneTimaInvestment';
import ListTimaConditions from './ListTimaConditions';
import OneTimaCondition from './OneTimaCondition';
import ListTimaAgents from './ListTimaAgents';
import OneTimaAgent from './OneTimaAgent';
import { securedRequest } from "../../../services/RequestService";
import { TIMA_EXPORT_FIELDS } from "./constants";
import { showExportToExcelError, } from "../../../components/Magic";
import PermissionService from "../../../services/PermissionService";
import { enumService } from "../../../services/EnumDataService";


const TAB_ID = {
    agent: 'agent',
    agents: 'agents',
    condition: 'condition',
    conditions: 'conditions',
    investment: 'investment',
    investments: 'investments',
    strategy: 'strategy',
    strategies: 'strategies',
};
Object.freeze(TAB_ID);


const breadcrumbs = (
    translate,
    activeTab,
    {
        agentId,
        conditionId,
        investmentId,
        strategyId,
    } ) => {
        const config = configBreadcrumbs;

        switch ( activeTab ) {
            case TAB_ID.agent:
                return config.breadcrumbs4Agent( translate, agentId );
            case TAB_ID.agents:
                return config.breadcrumbs4Agents( translate, { strategyId } );
            case TAB_ID.condition:
                return config.breadcrumbs4Condition( translate, conditionId );
            case TAB_ID.conditions:
                return config.breadcrumbs4Conditions( translate, { agentId, strategyId } );
            case TAB_ID.investment:
                return config.breadcrumbs4Investment( translate, investmentId );
            case TAB_ID.investments:
                return config.breadcrumbs4Investments( translate, { agentId, conditionId, strategyId } );
            case TAB_ID.strategy:
                return config.breadcrumbs4Strategy( translate, strategyId );
            default:
            case TAB_ID.strategies:
                return config.breadcrumbs4Strategies( translate );
        }
    };


const toolbar = (
    translate,
    activeTab,
    {
        agentId,
        conditionId,
        investmentId,
        strategyId,
    },
    permissions) => {
        const config = configToolbar;
        switch ( activeTab ) {
            case TAB_ID.agent:
                return config.toolbarByAgent( translate, 'agent', agentId, permissions );
            case TAB_ID.agents:
                if ( strategyId ) {
                    return config.toolbarByStrategy( translate, 'agentsByStrategy', strategyId, permissions );
                } else {
                    return config.toolbarInit( translate, 'agents', permissions );
                }
            case TAB_ID.condition:
                return config.toolbarByCondition( translate, 'condition', conditionId, permissions );
            case TAB_ID.conditions:
                if ( agentId ) {
                    return config.toolbarByAgent( translate, 'conditionsByAgent', agentId, permissions );
                } else if ( strategyId ) {
                    return config.toolbarByStrategy( translate, 'conditionsByStrategy', strategyId, permissions );
                } else {
                    return config.toolbarInit( translate, 'conditions', permissions );
                }
            case TAB_ID.investment:
                return [ ];
            case TAB_ID.investments:
                if ( agentId ) {
                    return config.toolbarByAgent( translate, 'investmentsByAgent', agentId, permissions );
                } else if ( conditionId ) {
                    return config.toolbarByCondition( translate, 'investmentsByCondition', conditionId, permissions );
                } else if ( strategyId ) {
                    return config.toolbarByStrategy( translate, 'investmentsByStrategy', strategyId, permissions );
                } else {
                    return config.toolbarInit( translate, 'investments', permissions );
                }
            case TAB_ID.strategy:
                return config.toolbarByStrategy( translate, 'strategy', strategyId, permissions );
            default:
            case TAB_ID.strategies:
                return config.toolbarInit( translate, 'strategies', permissions );
        }
    };


class Tima extends Component {
    constructor (props) {
        super(props);

        let mf = null;
        Object.defineProperty(this, 'mf', {
            get: () => mf ?? {},
            set: _ => { mf = _; },
        });
    }

    get timaConfig () {
        return [
            {
                path: ['tima', 'tima_manager'],
                key:  TAB_ID.strategies,
            },
            {
                path: ['tima', 'tima_investment'],
                key:  TAB_ID.investments,
            },
            {
                path: ['tima', 'tima_investment'], // SUCH PATH for system_accounts_transaction_history
                key:  TAB_ID.investment,
            },
            {
                path: ['tima', 'tima_condition'],
                key:  TAB_ID.conditions,
            },
            {
                path: ['tima', 'tima_agents'],
                key:  TAB_ID.agents,
            },

        ];
    }

    params = ( ) => this.props.match.params;

    query = ( ) => new URLSearchParams( this.props.location.search );

    pagination = () => {};

    enumsChange = async (enums) => {
        if (enums.hash===this.state?.enums?.hash) return;
        await this.save({ enums, });
    };

    save = async (state) => new Promise(resolve => this.setState(state, resolve));

    async componentDidMount() {
        const enums = enumService.enums;
        await this.save({ enums, });
        enumService.subscribe('enums', this.enumsChange);
    }

    componentDidUpdate($props, $state, $snapshot) {}

    componentWillUnmount() {
        enumService.unsubscribe('enums', this.enumsChange);
    }

    exportToExcel = (activeTab) => (event) => {
        if (activeTab) {
            const { agentId, conditionId, investmentId, strategyId, } = this.params();
            const { filterId: filter_id, } = this.mf;
            const globalUrl = `/api/tima`;
            const exportUrl = `export-to-excel`;
            const activeTabFormat = activeTab === TAB_ID.investment ? 'investmentHistory' : activeTab;

            let url = '',
                file_name = '';

            switch (activeTab) {
                case TAB_ID.agents: {
                    if (strategyId) {
                        url = `${globalUrl}/tima-agents/get-strategy-agents/${strategyId}/${exportUrl}`;
                        file_name = `TIMA_Agents_by_account_${strategyId}`;
                    } else {
                        url = `${globalUrl}/tima-agents/${exportUrl}`;
                        file_name = `TIMA_Agents`;
                    }

                    break;
                }
                case TAB_ID.conditions: {
                    if (strategyId) {
                        url = `${globalUrl}/tima-condition/get-strategy-conditions/${strategyId}/${exportUrl}`;
                        file_name = `TIMA_Conditions_by_account_${strategyId}`;
                    } else {
                        url = `${globalUrl}/tima-condition/${exportUrl}`;
                        file_name = `TIMA_Conditions`;
                    }

                    break;
                }
                case TAB_ID.investments: {
                    if (strategyId) {
                        url = `${globalUrl}/tima-investment/get-strategy-investments/${strategyId}/${exportUrl}`;
                        file_name = `TIMA_Investments_by_account_${strategyId}`;
                    } else if (conditionId) {
                        url = `${globalUrl}/tima-investment/get-condition-investments/${conditionId}/${exportUrl}`;
                        file_name = `TIMA_Investments_by_condition_${conditionId}`;
                    } else if (agentId) {
                        url = `${globalUrl}/tima-investment/get-agent-investments/${agentId}/${exportUrl}`;
                        file_name = `TIMA_Investments_by_agent_${agentId}`;
                    } else {
                        url = `${globalUrl}/tima-investment/${exportUrl}`;
                        file_name = 'TIMA_Investments';
                    }

                    break;
                }
                case TAB_ID.investment: {
                    if (investmentId) {
                        url = `${globalUrl}/tima-investment/get-transactions/${investmentId}/${exportUrl}`;
                        file_name = `TIMA_Transactions_by_investment_${investmentId}`;
                    }
                    break;
                }
                case TAB_ID.strategies: {
                    url = `${globalUrl}/tima-manager/${exportUrl}`;
                    file_name = `TIMA_Accounts`;
                    break;
                }
                default: {
                    break;
                }
            }

            const options = {
                file_name,
                fields: TIMA_EXPORT_FIELDS[activeTabFormat],
            };

            if (filter_id) {
                options['filter_id'] = filter_id;
            }

            securedRequest.post(url, options)
                .then((res) => {
                    // fileDownload(res.data, res.headers.filename);
                })
                .catch((error) => {
                    showExportToExcelError(error);
                });
        }
    };

    renderExportToExcelBtn = (activeTab) => {
        const { translate } = this.props;
        const conf = PermissionService.calc(this.timaConfig);
        const exportToExcelPermission = conf?.[activeTab]?.access('exportToExcel') ?? false;

        return (
            exportToExcelPermission ?
                (
                    <div className="button button--turquoise"
                         onClick={this.exportToExcel(activeTab)}
                    >
                        {translate('export_excel_btn')}
                    </div>
                ) : false
        );
    };

    render = ( ) => {
        const enums = this.state?.enums?.data;
        const renderToolbar = ( items ) => (
            <div className = 'content-block'>
                <div className='toolbar'>
                    <ToolbarPanel
                        title={ '' }
                        items={ items }
                        class4List={ `toolbar-panel__list toolbar-panel__list_flat` }
                    />
                </div>
            </div>
        );
        const renderContent = ( translate, active, params, query ) => {
                switch ( active ) {
                    case TAB_ID.agent:
                        return (
                            <OneTimaAgent
                                translate={ translate }
                                params={ params }
                                enums={enums}
                            />
                        );
                    case TAB_ID.agents:
                        return (
                            <ListTimaAgents
                                translate={ translate }
                                params={ params }
                                query={ query }
                                mf={ _ => this.mf = _ }
                                enums={enums}
                            />
                        );
                    case TAB_ID.condition:
                        return (
                            <OneTimaCondition
                                translate={ translate }
                                params={ params }
                                enums={enums}
                            />
                        );
                    case TAB_ID.conditions:
                        return (
                            <ListTimaConditions
                                translate={ translate }
                                params={ params }
                                query={ query }
                                mf={ _ => this.mf = _ }
                                enums={enums}
                            />
                        );
                    case TAB_ID.investment:
                        return (
                            <OneTimaInvestment
                                translate={ translate }
                                params={ params }
                                query={ query }
                                enums={enums}
                            />
                        );
                    case TAB_ID.investments:
                        return (
                            <ListTimaInvestments
                                translate={ translate }
                                params={ params }
                                query={ query }
                                mf={ _ => this.mf = _ }
                                enums={enums}
                            />
                        );
                    case TAB_ID.strategy:
                        return (
                            <OneTimaStrategy
                                translate={ translate }
                                params={ params }
                                enums={enums}
                            />
                        );
                    default:
                    case TAB_ID.strategies:
                        return (
                            <ListTimaStrategies
                                translate={ translate }
                                params={ params }
                                query={ query }
                                mf={ _ => this.mf = _ }
                                enums={enums}
                            />
                        );
                }
            };

        const renderSideBar = (activeTab, translate) => {
            switch (activeTab) {
                case TAB_ID.strategies:
                case TAB_ID.investments:
                case TAB_ID.investment:
                case TAB_ID.conditions:
                case TAB_ID.agents:
                    return (
                        <React.Fragment>
                            {this.renderExportToExcelBtn(activeTab)}
                        </React.Fragment>
                    );
                default:
                    return (new Date()).toUTCString();
            }
        };

        const { activeTab, translate, } = this.props;
        const permissions = PermissionService.calc(this.timaConfig);
        const breadcrumbsItems = breadcrumbs( translate, activeTab, this.params() );
        const toolbarItems = toolbar( translate, activeTab, this.params(), permissions );

        return (
            <div className= { `tima` }>
                <div className = 'content-block'>
                    <div className = 'top-page-block'>
                        <div>
                            <h1 className={ 'page-title' }>
                                { translate( `tima_strategies_h1` ) }
                            </h1>
                            <Breadcrumbs items={ breadcrumbsItems } />
                        </div>
                        { renderSideBar(activeTab, translate) }
                    </div>
                </div>
                { toolbarItems.length ? renderToolbar( toolbarItems ) : '' }
                { renderContent( translate, activeTab, this.params(), this.query() ) }
            </div>
        );
    }
}

export default withRouter( withLocalize( ( Tima ) ) );
