import {
	itemsBuild, itemsDrain, itemsResort, CFG_ITEM_TYPE,
} from '../Config';

export default {
	id: 193,
	...[

    // filters
		...[
			{
				f: 'payment.daily_aggregated_fin_transactions.operation_date',
				id: 'f:date',
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

    // orders
		...[
			{
				id: 'o:date',
				f: 'payment.daily_aggregated_fin_transactions.operation_date',
			},
			{
				id: 'o:sumDepositUSD',
				f: 'payment.daily_aggregated_fin_transactions.order_usd_deposit',
			},
			{
				id: 'o:balanceDepositUSD',
				f: 'payment.daily_aggregated_fin_transactions.bal_op_usd_deposit',
			},
			{
				id: 'o:sumDepositEUR',
				f: 'payment.daily_aggregated_fin_transactions.order_eur_deposit',
			},
			{
				id: 'o:balanceDepositEUR',
				f: 'payment.daily_aggregated_fin_transactions.bal_op_eur_deposit',
			},
			{
				id: 'o:sumWithdrawalUSD',
				f: 'payment.daily_aggregated_fin_transactions.order_usd_withdrawal',
			},
			{
				id: 'o:balanceWithdrawalUSD',
				f: 'payment.daily_aggregated_fin_transactions.bal_op_usd_withdrawal',
			},
			{
				id: 'o:sumWithdrawalEUR',
				f: 'payment.daily_aggregated_fin_transactions.order_eur_withdrawal',
			},
			{
				id: 'o:balanceWithdrawalEUR',
				f: 'payment.daily_aggregated_fin_transactions.bal_op_eur_withdrawal',
			},
			{
				id: 'o:sumChargebackUSD',
				f: 'payment.daily_aggregated_fin_transactions.order_usd_chargeback',
			},
			{
				id: 'o:balanceChargebackUSD',
				f: 'payment.daily_aggregated_fin_transactions.bal_op_usd_chargeback',
			},
			{
				id: 'o:sumChargebackEUR',
				f: 'payment.daily_aggregated_fin_transactions.order_eur_chargeback',
			},
			{
				id: 'o:balanceChargebackEUR',
				f: 'payment.daily_aggregated_fin_transactions.bal_op_eur_chargeback',
			},
			{
				id: 'o:sumUnfulfilledOperationsUSD',
				f: 'payment.daily_aggregated_fin_transactions.unful_order_usd_withd',
			},
			{
				id: 'o:sumUnfulfilledOperationsEUR',
				f: 'payment.daily_aggregated_fin_transactions.unful_order_eur_withd',
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

    // panels
		...[
			{
				t: 'summary_date',
				ii: 'f:date',
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

	].map(itemsBuild).reduce(itemsDrain, []).reduce(itemsResort, {}),
	path: 'payment/daily_aggregated_fin_transactions',
	widget: false,
};