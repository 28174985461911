const LinkConfig = (translate, order, options = {}, permissions) => [
	{
		id: 'new-partners-conditions',
		link: '/partners/affiliate-programs-management/new-partners-conditions',
		text: translate('new_partners_condition_rates'),
	},
	{
		id: 'affiliate-programs-management',
		link: '/partners/affiliate-programs-management',
		text: translate('partners_system_affiliate_programs_management'),
	},
	{
		id: 'partners',
		link: '/partners',
		text: translate('partners_h1'),
	},
	{
		id: 'dashboard',
		link: '/dashboard',
		text: translate('menu_dashboard'),
	},
]
	.filter(({ id, }) => order.includes(id))
	.sort(({ id: a, }, { id: b, }) => order.indexOf(a) - order.indexOf(b))
	.map(({ id, ...data }) => ({ ...data, key: id, }))
	.filter((item) => {
		if (permissions) {
			return permissions?.[item?.key]?.access('index') ?? false;
		}

		return true;
	});

export default LinkConfig;