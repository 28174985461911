import React, { Component, } from "react";
import { Input, Select, Popup, } from "../../../../components";
import {
	asyncGet,
	createSymbol,
	editSymbol,
} from "../../../../../../services/PartnersRequestService";
import { withLocalize, } from "react-localize-redux";
import {
	GET_TRADE_SERVER_LIST_TAKE_ALL,
	GET_SYMBOLS_GROUPS_LIST,
} from "../../../../../../apiRoutes";
import {
	findOptionByValue,
	setErrorsFromServer,
	setErrorClass,
	isEmptySelectValue,
	isEmptyString,
	filterValues,
} from "../../../../utils/helpers";
import PropTypes from "prop-types";

const symbolStatus = Object.freeze({
	ACTIVE: 2,
	DISABLE: 0,
	NEW: 1,
});

class CreateUpdateSymbol extends Component {
	static propTypes = {
		onClose: PropTypes.func.isRequired,
		// eslint-disable-next-line sort-keys
		enums: PropTypes.object,
		formData: PropTypes.object,
		onDataLoad: PropTypes.func,
	};

	constructor (props) {
		super(props);

		this.state = {
			errors: [],
			formData: {
				group_id: {},
				name: "",
				status: {},
				trading_server_id: {},
			},
			loadData: true,
			serversList: [],
			statusList: [],
			symbolsGroups: [],
		};
	}

	get translate () {
		return this.props.translate;
	}

	get isEditMode () {
		return Object.keys(this.props.formData).length;
	}

	get symbolStatusInDB () {
		const { status, } = this.props.formData;

		return {
			active: status === symbolStatus.ACTIVE,
			disable: status === symbolStatus.DISABLE,
			new: status === symbolStatus.NEW,
		};
	}

	get fieldsIsNotEdit () {
		return this.isEditMode && !this.symbolStatusInDB.new;
	}

	get statusIsNotEdit () {
		return (
			this.isEditMode && this.symbolStatusInDB.disable || !this.isEditMode
		);
	}

	save = state => new Promise(next => this.setState(state, next));

	toggleLoader = () => this.save(state => ({ loadData: !state.loadData, }));

	componentDidMount = async () => {
		try {
			await this.toggleLoader();
			const serversList = await this.getServersList();

			await this.save((state) => {
				const statusList = this.getStatusList();

				return {
					formData: { ...state.formData, status: statusList[1], },
					serversList,
					statusList,
				};
			});

			if (this.isEditMode) {
				return await this.setSavedData();
			}
		} catch (error) {
			error?.showErrorNotification?.();
		} finally {
			await this.toggleLoader();
		}
	};

	setSavedData = async () => {
		const {
			trading_server_id, name, status, group_id,
		} = this.props.formData;

		await this.getSymbolsGroups(trading_server_id, false);

		await this.save(state => ({
			formData: {
				...state.formData,
				group_id: findOptionByValue(state.symbolsGroups, group_id),
				name,
				status: findOptionByValue(state.statusList, status),
				trading_server_id: findOptionByValue(
					state.serversList,
					trading_server_id,
				),
			},
		}));
	};

	getSendData = () => {
		const {
			name, trading_server_id, status, group_id,
		} = this.state.formData;

		return {
			group_id: group_id.value,
			name: name.trim(),
			status: status.value.toString(),
			trading_server_id: trading_server_id.value,
		};
	};

	validateEmptyFields = async () => {
		await this.save(state => ({
			errors: Object.keys(state.formData).reduce((total, field) => {
				if (
					isEmptySelectValue(state.formData[field]) ||
					isEmptyString(state.formData[field])
				) {
					// eslint-disable-next-line no-param-reassign
					total = [ ...total, field, ];
				}

				return total;
			}, []),
		}));

		return this.state.errors.length === 0;
	};

	saveData = async () => {
		const isValidate = await this.validateEmptyFields();

		if (!isValidate) {
			return;
		}

		this.isEditMode ? await this.updateSymbol() : await this.createSymbol();
	};

	createSymbol = async () => {
		const { onDataLoad, onClose, } = this.props;

		try {
			await this.save({ loadData: false, });
			await createSymbol(this.getSendData());

			onDataLoad && onDataLoad();
			onClose();
		} catch (error) {
			setErrorsFromServer(error, this.save);
		} finally {
			await this.save({ loadData: true, });
		}
	};

	updateSymbol = async () => {
		const { onDataLoad, onClose, formData, } = this.props;

		try {
			await this.save({ loadData: false, });
			await editSymbol(formData.id, this.getSendData());

			onDataLoad && onDataLoad();
			onClose();
		} catch (error) {
			setErrorsFromServer(error, this.save);
		} finally {
			await this.save({ loadData: true, });
		}
	};

	getServersList = async () => {
		try {
			const response = await asyncGet(GET_TRADE_SERVER_LIST_TAKE_ALL);

			return response
				.filter(({ server_name, }) => !server_name.includes("Demo"))
				.reduce((total, { id, server_name, type, }) => {
					total.push({
						label: server_name,
						trading_server_id: type,
						value: +id,
					});

					return total;
				}, []);
		} catch (error) {
			error?.showErrorNotification?.();

			return false;
		}
	};

	getSymbolsGroups = async (tradingServerId, withLoader = true) => {
		if (!tradingServerId) {
			return [];
		}

		try {
			withLoader && await this.toggleLoader();
			const params = `drop-down?take=all&trading_server_id=${ tradingServerId }`;
			const response = await asyncGet(`${ GET_SYMBOLS_GROUPS_LIST }/${ params }`);

			await this.save(state => ({
				errors: state.errors.filter(item => item !== "group_id"),
				formData: {
					...state.formData,
					group_id: {},
				},
				symbolsGroups: response.sort((a, b) => a.name.localeCompare(b.name)).map(({ id, name, }) => ({
					label: name,
					value: id,
				})),
			}));
		} catch (error) {
			error?.showErrorNotification?.();

			return false;
		} finally {
			withLoader && await this.toggleLoader();
		}
	};

	getStatusList = () => {
		return this.props?.enums?.data?.partners?.SymbolStatusEnum?.map?.(
			(item, index) => ({
				label: item,
				value: index,
			}),
		);
	};

	handleOnChange = async (event) => {
		const { name, value, } = event.target;

		await this.save(state => ({
			...state,
			errors: state.errors.filter(item => item !== name),
			formData: {
				...state.formData,
				[name]: value,
			},
		}));
	};

	renderTitle = () => {
		if (this.isEditMode) {
			return this.translate("symbols_edit_symbol");
		}

		return this.translate("symbols_create_symbol");
	};

	render = () => {
		const { onClose, } = this.props;

		const {
			formData,
			loadData,
			serversList,
			symbolsGroups,
			statusList,
			errors,
		} = this.state;

		return (
			<Popup
				loadData={ loadData }
				title={ this.renderTitle() }
				onClick={ this.saveData }
				onClose={ onClose }
			>
				<Input
					disabled={ !loadData || this.fieldsIsNotEdit }
					isRequired={ true }
					label={ this.translate("symbols_name") }
					name="name"
					placeholder={ this.translate("symbols_name_placeholder") }
					value={ formData.name }
					wrapperClassName={ setErrorClass(errors, "name") }
					onChange={ this.handleOnChange }
					onValid={ (value) => {
						// eslint-disable-next-line no-useless-escape
						const regExp = /^[a-zA-Z0-9\.\_]{0,31}$/g;

						if (value !== "" && !regExp.test(value)) {
							throw new Error("xxx");
						}
					} }
				/>
				<Select
					className={ setErrorClass(errors, "status") }
					defaultLabel="symbols_create_status_placeholder"
					disabled={ !loadData || this.statusIsNotEdit }
					isRequired={ true }
					label={ this.translate("symbols_status") }
					name="status"
					options={
						this.symbolStatusInDB.active
							? filterValues(statusList, [ symbolStatus.NEW, ])
							: statusList
					}
					value={ formData.status }
					onChange={ this.handleOnChange }
				/>
				<Select
					className={ setErrorClass(errors, "trading_server_id") }
					disabled={ !loadData || this.fieldsIsNotEdit }
					isRequired={ true }
					label={ this.translate("partners_server_name_label") }
					name="trading_server_id"
					options={ serversList }
					placeholder={ this.translate("partners_create_account_select_server") }
					value={ formData.trading_server_id }
					onChange={ this.handleOnChange }
					onChangeCallback={ async (event) => {
						const { value: tradingServerId, } = event.target.value;

						await this.getSymbolsGroups(tradingServerId);
					} }
				/>
				<Select
					className={ setErrorClass(errors, "group_id") }
					disabled={ !loadData || this.fieldsIsNotEdit }
					isRequired={ true }
					label={ this.translate("symbols_group") }
					name="group_id"
					options={ symbolsGroups }
					placeholder={ this.translate(
						"partners_create_symbol_not_group_select",
					) }
					value={ formData.group_id }
					onChange={ this.handleOnChange }
				/>
			</Popup>
		);
	};
}

export default withLocalize(CreateUpdateSymbol);