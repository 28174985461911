import React from "react";
import { Label, Input } from 'reactstrap';

export default class DocumentsUpload extends React.Component {
    render () {
        return (
            <div>
                <div className = 'attachments-top'>
                    <div className = 'attachments-delete-action'>
                        <div>
                            <Label className = 'upload-file'>
                                <span className = { `button-file ${this.props.canUpload ? '' : 'disabled'}` }>
                                    <Input
                                        disabled = { !this.props.canUpload }
                                        name = ''
                                        type = 'file'
                                        onChange = { this.props.handleselectedFile }
                                    />
                                    {this.props.translate(`task_choose_file`)}
                                </span>
                            </Label>
                            <span className = 'additional-info'>{this.props.translate(`task_file_up`)}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
