import React, { Component } from 'react';
import { withLocalize }     from 'react-localize-redux';
import { withRouter }       from 'react-router-dom';
import { SupportCard }      from './SupportCard';
import { TicketInfo }       from './TicketInfo';
import { TicketChat }       from './TicketChat';
import { securedRequest }   from '../../../../services/RequestService/index';
import { DOWNLOAD_FILE }    from '../../apiRoutes';
import propTypes            from 'prop-types';
import Moment               from "moment";
import { supportService }     from "../../../../services/SupportDataService";

class OneSupportRequest extends Component {

   static defaultProps = {
      id: 0,
      getTitleForOneTicket: () => {},
   };

   static propTypes = {
      enums: propTypes.shape({
         tickets: propTypes.object,
      }),
      match: propTypes.object,
      translate: propTypes.func,
      getTitleForOneTicket: propTypes.func,
   };

   constructor(props) {
      super(props);
      this.state = {
         profile: this.props.profile,
         id: +this.props.match.params.supportId,
         chat_messages: [],
         editId: 0,
         data: {},
      };
   };

   cutText = (text, cutLength) => text = (text?.length > 0 && text?.length > cutLength)
      ? `${text.substr(0, cutLength)}...`
      : text;

   save = async (state) => new Promise(next => this.setState(state, next));

   UNSAFE_componentWillReceiveProps(nextProps) {
      if (this.state?.profile !== nextProps?.profile) {
         this.setState({
            profile: nextProps?.profile,
         });
      }
   };

   componentDidMount = async() => {
      await this.getMessagesByTicketId(this.state.id);
      await this.getTicketById(this.state.id)
   }

   getMessagesByTicketId = async (id) => {
      try {
         const response = await supportService.getMessagesByTicketId(id);
         const {data} = response;
         const chat_messages = data.sort((a, b) => {
            const A = Moment(a?.tickets?.ticket_messages?.created_at);
            const B = Moment(b?.tickets?.ticket_messages?.created_at);
            return A.diff(B);
         });
         await this.save({chat_messages,});
      } catch (error) {
         error.showErrorNotification();
      }
   };

   getTicketById = async (id) => {
      try {
         const {data} = await supportService.getTicketById(id);
         await this.save({data,});
         if(["2", 2].includes(+data?.tickets?.tickets?.status)) {
            const editId = this.state?.chat_messages?.slice?.(-1)?.map?.(e => e?.tickets?.ticket_messages?.id);
            await this.save({editId: Number(...editId)});
         }
      }
      catch (error) {
         error.showErrorNotification();
      }
   };

   saveFileToDisk(item, fileName) {
      if (window.navigator.msSaveBlob) {
         window.navigator.msSaveOrOpenBlob(item, fileName);
      } else {
         let link = document.createElement('a');
         link.setAttribute("download", fileName);
         link.href = URL.createObjectURL(item)
         let evt = document.createEvent("MouseEvents");
         evt.initEvent("click", false, true);
         link.dispatchEvent(evt);
      }
   };

   downloadAndSaveFile = (alias, fileName) => {
      securedRequest.get(`${ DOWNLOAD_FILE }/${ alias }`, {responseType: 'blob'})
         .then(({data}) => this.saveFileToDisk(data, fileName));
   };

   render() {
      const {id,} = this.state;
      const {translate} = this.props;
      return (
            <div className='cards'>
               <SupportCard
                  title={ `ID ${ id } ` }
                  className='one-support__card'>
                  <TicketInfo
                     id={id}
                     enums={ this.props?.enums }
                     downloadAndSaveFile={ this.downloadAndSaveFile }
                     profile={ this.props?.profile }
                     chat_messages={this.state?.chat_messages}
                     data={this.state?.data}
                     translate={ this.props?.translate }
                     getTicketById={this.getTicketById}
                  />
               </SupportCard>
               <SupportCard
                  title={ translate('support_card_chat') }
                  className='chat__wrapper'>
                  <TicketChat
                     profile={ this.props.profile }
                     id={+id}
                     downloadAndSaveFile={ this.downloadAndSaveFile }
                     cutText={this.cutText}
                     chat_messages={this.state.chat_messages}
                     data={this.state.data}
                     getMessagesByTicketId={this.getMessagesByTicketId}
                     getTicketById={this.getTicketById}
                     editId={this.state?.editId}
                     translate={ this.props?.translate }
                  />
               </SupportCard>
            </div>
      )
   };
}

export default withRouter(withLocalize(OneSupportRequest));
