/* eslint-disable padding-line-between-statements */
import React, { Component, } from "react";
import { withLocalize, } from "react-localize-redux";
import { Input, Popup, Select, } from "../../../../components";
import { Textarea, } from "../../../../components/Textarea/Textarea";
import {
	filterValues,
	findOptionByValue,
	setErrorClass,
	setErrorsFromServer,
} from "../../../../utils/helpers";
import { setComplexErrorsFromServer, } from "./helpers/setComplexErrorsFromServer";
import { valuesToInt, } from "./helpers/valuesToInt";
import {
	NewPartnersConditionsListStatus,
	newPartnersConditionLevel,
	newPartnersListConditions,
	newPartnersPeriodList,
	newPartnersCountryDetermination,
	newPartnersLeadAppearConditionList,
	formDataRevshareSingleLevel,
	formDataCpaSingleLevel,
} from "./consts/consts";
import PropTypes from "prop-types";
import Loader from "../../../../../tima/components/Loader";
import Preloader from "../../../../../../components/LoadingHOC/Preloader";
import ReferralCountryBlockCpa from "./components/CPA/RefferalCountryBlockCpa";
import PayoutRateCpaSingleLevel from "./components/CPA/PayoutRateCpaSingleLevel";
import FtdCpaSingleBlock from "./components/CPA/FtdCpaSingleBlock";
import PayoutRateBlockRevshare from "./components/Revshare/PayoutRateBlockRevshare";
import PayoutRateBlockInOut from "./components/inOut/PayoutRateBlockInOut";
import PayoutRateListInOutSingleLevel from "./components/inOut/PayoutRateListInOutSingleLevel";

import {
	asyncGetSymbolsGroups,
	createNewPartnersConditionInOutSingle,
	createNewPartnersConditionCpaSingle,
	editCountryPayoutRateList,
	getPayoutRateLists,
	getAccountGroupsList,
	getSymbolsList,
	createNewPartnersConditionRevShare,
	createNewPartnersConditionСplSingle,
	createNewPartnersConditionCpaMultiple,
	createNewPartnersConditionCplMultiple,
	createNewPartnersConditionInOutMultiple,
	createNewPartnersConditionReMultiple,
	getPartnersCurrency,
	getPartnersAvailableCurrencies,
	editNewPartnerConditionList,
} from "../../../../../../services/PartnersRequestService";

import {
	validateOnSubmit,
	validatePayoutPercentageRevshare,
	validateFtdCpaSingleBlock,
	validatePayoutRangeRateCpa,
	validatePayoutRangeListInOut,
	validatePayoutRangeBlockInOut,
	validateRefferalCountryBlockCpl,
	validatePayoutRangeListCpl,
	validateRenumerationRatesRevshare,
	validatePayoutRangeRateCpaOnAdd,
	validatePayoutRatesByLevel,
} from "./helpers/validation-helpers/validateCreateUpdateNewPartnershipConditions";
import {
	formDataCPASingleLevel,
	formDataInOutSingleLevel,
	formDataCPLSingleLevel,
	formDataMultiLevel,
	commonFormData,
} from "./consts/consts";
import RegistrationClientsRevshareBlock from "./components/Revshare/RegistrationClientsRevshareBlock";
import RenumerationRatesRevshare from "./components/Revshare/RenumerationRatesRevshare";
import CountSubpartnersBlockedReferrals from "./components/Revshare/CountSubpartnersBlockedReferrals";

import RefferalCountryBlockCpl from "./components/CPL/RefferalCountryBlockCpl";
import PayoutRateListCplSingleLevel from "./components/CPL/PayoutRateListCplSingleLevel";

import PayoutRatesByLevel from "./components/PayoutRatesByLevel";
class CreateUpdateNewPartnersConditions extends Component {
	static propTypes = {
		onClose: PropTypes.func.isRequired,
		enums: PropTypes.object,
		formData: PropTypes.object,
		onDataLoad: PropTypes.func,
	};

	constructor (props) {
		super(props);

		this.state = {
			errors: [],
			loadData: false,
			isChanged: false,
			statusList: [],
			serverCurrency: "",
			currencies: "",
			levelList: [],
			periodList: [],
			countryCheckList: [],
			selectedSymbolGroupList: [],
			symbolGroupList: [],
			symbolList: [],
			accountGroupList: [],
			countries: [],
			payoutRateList: [],
			formData: this.emptyFormData,
		};
	}

	save = async state => new Promise(next => this.setState(state, next));

	toggleLoader = async () =>
		await this.save(state => ({ loadData: !state.loadData, }));

	componentDidMount = async () => {
		try {
			const payoutRateList = await getPayoutRateLists();
			const symbolGroupList = await asyncGetSymbolsGroups();
			const accountGroupList = await getAccountGroupsList();
			const symbolList = await getSymbolsList();
			const serverCurrency = await getPartnersCurrency();
			const currencies = await getPartnersAvailableCurrencies();
			const { data, } = payoutRateList;

			await this.save({
				isEmptyData: false,
				countriesLoaded: false,
			});

			const statusList = this.statusList;
			const levelList = this.levelList;
			const periodList = this.periodList;
			const countryCheckList = this.countryCheckList;
			const leadAppearConditionList = this.leadAppearConditionList;
			const conditionTypes = this.conditionTypes;
			const propsFormData = { ...this.props.formData, };
			propsFormData?.first_deposit_conditions?.sort((a,b) => a[0].id - b[0].id); // change direction of created fields
			propsFormData.description = propsFormData.description ?? ""; //back return null if no description was provided

			await this.save((state) => {
				return {
					formData: {
						...state.formData,
						status: propsFormData.status ?? 1,
					},
					serverCurrency,
					currencies: Object.values(currencies),
					payoutRateList: data,
					statusList,
					conditionTypes,
					levelList,
					periodList,
					countryCheckList,
					leadAppearConditionList,
					symbolGroupList: symbolGroupList.data,
					symbolList: symbolList.data,
					accountGroupList: accountGroupList.data,
				};
			});

			if (this.isEditMode) {
				await this.save((state) => {
					return {
						formData: {
							...state.formData,
							...propsFormData,
						},
					};
				});
			}

			if (this.isEditMode && this.props.formData.type === 1) {
				const { payout_rate_list_id, } = this.props.formData;

				const { data, } = await editCountryPayoutRateList(payout_rate_list_id);

				await this.save((state) => {
					return {
						formData: {
							...state.formData,
							payoutRangesSumFrom: data,
							...propsFormData,
						},
					};
				});
			}
		} catch (error) {
			error?.showErrorNotification?.();
		} finally {
			await this.save({ countriesLoaded: true, });
			await this.toggleLoader();
		}
	};

	async componentDidUpdate (prevProps, prevState) {
		const tState = this.state.formData.payout_rate_list_id;
		const pState = prevState.formData.payout_rate_list_id;

		const {
			payout_rate_list_id,
			type,
			level,
			name,
			status,
			period,
		} = this.state.formData;
		const { isChanged, } = this.state;
		const tStateType = type;
		const pStateType = prevState.formData.type;
		const tStateLevel = level;
		const pStateLevel = prevState.formData.level;

		const emptyForm = this.emptyFormData;

		if (
			!this.isEditMode &&
			(tStateType !== pStateType || pStateLevel !== tStateLevel)
		) {
			await this.save({
				formData: {
					...emptyForm,
					name,
					type: tStateType,
					level: tStateLevel,
					status,
				},
				errors: [],
			});
		}
		if (isChanged) {
			await this.save((state) => {
				return {
					formData: {
						...state.formData,
						latency: '',
					},
					isChanged: false,
				};
			});
		}

		if (tState !== pState && payout_rate_list_id && !this.isEditMode) {
			const { data, } = await editCountryPayoutRateList(payout_rate_list_id);

			await this.save((state) => {
				return {
					formData: {
						...state.formData,
						payoutRangesSumFrom: data,
					},
				};
			});
		}

		//after choosing country list, sum_from-input amount changes, so it's neccessary to overwrite previous
		const countryGroupToRange = this.state.formData.payoutRangesSumFrom
			?.partners?.country_group_to_range;
		const prevCountryGroupToRange =
			prevState.formData.payoutRangesSumFrom?.partners?.country_group_to_range;

		if (
			countryGroupToRange &&
			countryGroupToRange !== prevCountryGroupToRange
		) {
			await this.save((state) => {
				return {
					formData: {
						...state.formData,
						first_deposit_conditions: [],
					},
				};
			});
			if (!this.state.formData.first_deposit_conditions.length) {
				await this.createNewFirstDepositCondition();
			}
		}
	}

	get translate () {
		return this.props.translate;
	}

	get isEditMode () {
		return Object.keys(this.props.formData).length;
	}

	get NewPartnersConditionsListStatusInDB () {
		const { status, } = this.props.formData;

		return {
			new: status === NewPartnersConditionsListStatus.NEW,
			active: status === NewPartnersConditionsListStatus.ACTIVE,
			closed: status === NewPartnersConditionsListStatus.CLOSED,
		};
	}

	get condition () {
		const { type, } = this.state.formData;

		return {
			CPA: type === newPartnersListConditions.CPA,
			CPL: type === newPartnersListConditions.CPL,
			IN_OUT: type === newPartnersListConditions.IN_OUT,
			REVSHARE: type === newPartnersListConditions.REVSHARE,
		};
	}

	get countryCheck () {
		const { referrer_country_check, } = this.state.formData;

		return {
			BY_PHONE:
				referrer_country_check ===
				newPartnersCountryDetermination.BY_PHONE_NUMBER,
			BY_CITIZENSHIP:
				referrer_country_check ===
				newPartnersCountryDetermination.BY_CITIZENSHIP,
		};
	}

	get leadCheck () {
		// made same as above because not sure if it's gonna be needed or not
		const { condition_lead_appears, } = this.state.formData;

		return {
			BY_PHONE_NUMBER:
				condition_lead_appears.value ===
				newPartnersLeadAppearConditionList.BY_PHONE_NUMBER,
			BY_EMAIL_AND_PHONE_NUMBER:
				condition_lead_appears.value ===
				newPartnersLeadAppearConditionList.BY_EMAIL_AND_PHONE_NUMBER,
			VERIFIED_CONFIRMED_PHONE_NUMBER:
				condition_lead_appears.value ===
				newPartnersLeadAppearConditionList.VERIFIED_CONFIRMED_PHONE_NUMBER,
			VERIFIED_CONFIRMED_MAIL:
				condition_lead_appears.value ===
				newPartnersLeadAppearConditionList.VERIFIED_CONFIRMED_MAIL,
			VERIFIED_CONFIRMED_MAIL_AND_PHONE_NUMBER:
				condition_lead_appears.value ===
				newPartnersLeadAppearConditionList.VERIFIED_CONFIRMED_MAIL_AND_PHONE_NUMBER,
		};
	}

	get period () {
		const { period, } = this.state.formData;

		return {
			DAY: period === newPartnersPeriodList.DAY,
			WEEK: period === newPartnersPeriodList.WEEK,
			MONTH: period === newPartnersPeriodList.MONTH,
			QUARTER: period === newPartnersPeriodList.QUARTER,
			YEAR: period === newPartnersPeriodList.YEAR,
		};
	}

	get level () {
		const { level, } = this.state.formData;

		return {
			SINGLE_LEVEL: level === newPartnersConditionLevel.SINGLE_LEVEL,
			MULTI_LEVEL: level === newPartnersConditionLevel.MULTI_LEVEL,
		};
	}

	get fieldsIsNotEdit () {
		return this.isEditMode && !this.NewPartnersConditionsListStatusInDB.new;
	}

	get isViewMode () {
		return this.props.isViewMode;
	}

	get statusIsNotEdit () {
		return (
			this.isEditMode && this.NewPartnersConditionsListStatusInDB.closed ||
			!this.isEditMode
		);
	}

	get statusList () {
		return Object.entries(
			this.props?.enums?.data?.partners?.NewConditionStatusEnum ?? {},
		).map(([ key, value, ]) => ({
			label: value,
			value: +key,
		}));
	}

	get countryCheckList () {
		return Object.entries(
			this.props?.enums?.data?.partners?.CountryCheckTypeEnum ?? {},
		).map(([ key, value, ]) => ({
			label: value,
			value: +key,
		}));
	}

	get leadAppearConditionList () {
		return Object.entries(
			this.props?.enums?.data?.partners?.LeadAppearConditionEnum ?? {},
		).map(([ key, value, ]) => ({
			label: value,
			value: +key,
		}));
	}

	get conditionTypes () {
		return Object.entries(this.props.enums.data?.partners?.TypeEnum ?? {}).map(
			([ key, value, ]) => ({
				label: value,
				value: +key,
			}),
		);
	}

	get levelList () {
		return Object.entries(this.props.enums.data?.partners?.LevelEnum ?? {}).map(
			([ key, value, ]) => ({
				label: value,
				value: +key,
			}),
		);
	}

	get periodList () {
		return Object.entries(
			this.props.enums.data?.partners?.PeriodEnum ?? {},
		).map(([ key, value, ]) => ({
			label: value,
			value: +key,
		}));
	}

	get accountGroupListsOptions() {
		const {
			accountGroupList,
		} = this.state;
		const statusInDb = this.NewPartnersConditionsListStatusInDB;


		return accountGroupList?.filter(items => statusInDb.closed ? items.partners.groups_lists.status === 2 || items.partners.groups_lists.status === 3 : items.partners.groups_lists.status === 2)
			.map((items) => {
				const { name, id, } = items.partners.groups_lists;
				const { server_name, } = items.core.dictionary_trade_servers;

				return {
					label: name,
					server_name,
					value: id,
				};
			});
	}

	get symbolGroupListOptions(){
		const { symbolGroupList, } = this.state;

        return symbolGroupList
            .sort((a, b) => a.partners.symbols_groups.name.localeCompare(b.partners.symbols_groups.name))
            ?.filter(item => item.partners.symbols_groups.amount !== 0)
            .map((items) => {
                const { name, id, } = items.partners.symbols_groups;
                const { server_name, } = items.core.dictionary_trade_servers;

                return {
                    label: name,
                    value: id,
                    server_name,
                };
            });
	}

	get createNewPartnersCondition () {
		//temporary solution to change routes on POST
		if (this.level.MULTI_LEVEL) {
			return this.condition.CPA
				? createNewPartnersConditionCpaMultiple
				: this.condition.CPL
					? createNewPartnersConditionCplMultiple
					: this.condition.IN_OUT
						? createNewPartnersConditionInOutMultiple
						: this.condition.REVSHARE
							? createNewPartnersConditionReMultiple
							: "";
		}

		return this.condition.CPA
			? createNewPartnersConditionCpaSingle
			: this.condition.IN_OUT
				? createNewPartnersConditionInOutSingle
				: this.condition.REVSHARE
					? createNewPartnersConditionRevShare
					: this.condition.CPL
						? createNewPartnersConditionСplSingle
						: "";
	}

	get emptyFormData () {
		return {
			name: "",
			status: {},
			type: {},
			level: {},
			delay: [],
			latency: "",
			period: "",
			description: "",
			// CPA state
			...newPartnersListConditions.CPA && formDataCpaSingleLevel,
			//Revshare state
			...newPartnersListConditions.REVSHARE && formDataRevshareSingleLevel,

			...newPartnersListConditions.IN_OUT && formDataInOutSingleLevel, //formdata for InOut

			...newPartnersListConditions.CPL && formDataCPLSingleLevel, //formdata for CPL

			...newPartnersConditionLevel.MULTI_LEVEL && formDataMultiLevel, //formdata for MultyLevel
		};
	}

	get statusIsActive () {
		return this.isEditMode && this.NewPartnersConditionsListStatusInDB.active;
	}

	get statusIsClosed () {
		return this.isEditMode && this.NewPartnersConditionsListStatusInDB.closed;
	}

	getSendData = () => {
		const {
			name,
			type,
			status,
			level,
			latency,
			period,
			description,
			referrer_country_check,
			first_deposit_confirmation_period,
			payment_coef_cpa,
			first_deposit_conditions,
			payout_rate_for_the_estimated_amount,
			min_inout_reminder,
			payment_coef_inout,
			first_deposit_fixing_hours,
			payout_rate_list_id,
			payment_coef_revshare,
			grace_period,
			payout_rate_for_grace_period,
			min_req_fullfillment_period,
			payout_rates_revshare,
			condition_lead_appears,
			payment_coef_cpl,
			consider_ref_linked_to_locked,
			multi_rates_list_rate_by_level,
		} = this.state.formData;

		const ftdCpaWithNoEmptyDealsFields = first_deposit_conditions?.map(conditions => {
			return conditions?.map((item) => {
				const { symbol_group_id, payout_rates_ranges_id, volume, deals, } = item;
				return {
					symbol_group_id,
					payout_rates_ranges_id,
					volume,
					...deals && { deals, }, //Deals will be empty if symbol_group_id trading server is MT4, deals field fills only for MT5
				}
			})
		})

		const payoutRatesRevshare = payout_rates_revshare?.map((item) => {
			return {
				...item.deals && { deals: item.deals, },
				groups_list_id: item.groups_list_id,
				reward_by_point_lot: item.reward_by_point_lot,
				symbols_list_id: item.symbols_list_id,
				reward_by_volume_lot: item.reward_by_volume_lot,
				reward_percent_of_commission: item.reward_percent_of_commission,
			};
		});

		const paymenCoefCpl = payment_coef_cpl?.map((items) => {
			const { leads_from, coef, } = items;

			return {
				leads_from,
				coef,
			};
		});

		return {
			name,
			type,
			status,
			level,
			latency: +latency,
			period,
			description,
			...this.condition.CPA && {
				referrer_country_check,
				payout_rate_list_id,
				first_deposit_fixing_hours: +first_deposit_fixing_hours,
				first_deposit_confirmation_period: +first_deposit_confirmation_period,
				payment_coef_cpa: valuesToInt(payment_coef_cpa, "deposits_from"),
				first_deposit_conditions: ftdCpaWithNoEmptyDealsFields,
			},
			...this.condition.IN_OUT && {
				payout_rate_for_the_estimated_amount,
				min_inout_reminder,
				payment_coef_inout,
			},
			...this.condition.REVSHARE && {
				payment_coef_revshare: valuesToInt(
					payment_coef_revshare,
					"active_clients",
					"new_active_clients",
					"registrations",
					"verifications",
				),
				grace_period: +grace_period,
				payout_rate_for_grace_period: +payout_rate_for_grace_period,
				min_req_fullfillment_period: +min_req_fullfillment_period,
				payout_rates_revshare: payoutRatesRevshare,
			},
			...this.condition.CPL && {
				referrer_country_check,
				payout_rate_list_id,
				condition_lead_appears,
				payment_coef_cpl: paymenCoefCpl,
			},
			...this.level.MULTI_LEVEL && {
				consider_ref_linked_to_locked,
				multi_rates_list_rate_by_level: valuesToInt(
					multi_rates_list_rate_by_level,
					"level_from",
					"level_to",
				),
			},
		};
	};

	saveData = async () => {
		const fieldsAreValid = await validateOnSubmit(
			this.state,
			this.save,
			this.condition,
		);
		const ftdCpaSingleBlockIsValid = await validateFtdCpaSingleBlock(
			this.state,
			this.save,
			this.condition,
			this.symbolGroupListOptions,
		);
		const payoutPercentageRevshareIsValid = await validatePayoutPercentageRevshare(
			this.state,
			this.save,
			this.condition,
		);
		const payoutRangeRateCpaIsValid = await validatePayoutRangeRateCpa(
			this.state,
			this.save,
			this.condition,
		);
		const payoutRangeBlockInOutIsValid = await validatePayoutRangeBlockInOut(
			this.state,
			this.save,
			this.condition,
		);
		const payoutRangeListInOutIsValid = await validatePayoutRangeListInOut(
			this.state,
			this.save,
			this.condition,
		);
		const refferalCountryBlockCplIsValid = await validateRefferalCountryBlockCpl(
			this.state,
			this.save,
			this.condition,
		);
		const payoutRangeRateCplIsValid = await validatePayoutRangeListCpl(
			this.state,
			this.save,
			this.condition,
		);
		const renumerationRatesRevshareIsValid = await validateRenumerationRatesRevshare(
			this.state,
			this.save,
			this.condition,
			this.accountGroupListsOptions,
		);
		const payoutRatesByLevelisValid = await validatePayoutRatesByLevel(
			this.state,
			this.save,
			this.level,
		);

		if (
			!payoutRatesByLevelisValid ||
			!payoutRangeRateCplIsValid ||
			!refferalCountryBlockCplIsValid ||
			!fieldsAreValid ||
			!ftdCpaSingleBlockIsValid ||
			!renumerationRatesRevshareIsValid ||
			!payoutPercentageRevshareIsValid ||
			!payoutRangeRateCpaIsValid ||
			!payoutRangeBlockInOutIsValid ||
			!payoutRangeListInOutIsValid
		) {
			return console.log("Dosn`t matches validation rules");
		}
		this.isEditMode
			? await this.editNewPartnersConditionList()
			: await this.createNewPartnersConditionList();
	};

	editNewPartnersConditionList = async () => {
		const { onClose, onDataLoad, } = this.props;
		const { conditionParameters, } = this.state.formData;

		try {
			await this.save({
				errors: [],
				loadData: false,
			});
			await editNewPartnerConditionList(
				conditionParameters,
				this.getSendData(),
			);
			await Promise.all([ onClose(), onDataLoad(), ]);
		} catch (error) {
			await setErrorsFromServer(error, this.save);
			await setComplexErrorsFromServer(error, this.save);
		} finally {
			await this.save({ loadData: true, });
		}
	};

	createNewPartnersConditionList = async () => {
		const { onClose, onDataLoad, } = this.props;

		try {
			await this.save({
				errors: [],
				loadData: false,
			});
			await this.createNewPartnersCondition(this.getSendData());
			await Promise.all([ onClose(), onDataLoad(), ]);
		} catch (error) {
			console.log(error.response?.data?.errors);
			// await error?.showErrorNotification?.();
			await setErrorsFromServer(error, this.save);
			await setComplexErrorsFromServer(error, this.save);
		} finally {
			await this.save({ loadData: true, });
		}
	};

	createNewFirstDepositCondition = async () => {
		const firstDepositConditions = this.state.formData.first_deposit_conditions;

		if (firstDepositConditions.length > 0) {
			const ftdCpaSingleBlockIsValid = await validateFtdCpaSingleBlock(
				this.state,
				this.save,
				this.condition,
				this.symbolGroupListOptions,
			);

			if (!ftdCpaSingleBlockIsValid) {
				return console.log("Dosn`t matches validation rules");
			}
		}

		if (
			this.isEditMode &&
			!this.state.formData.first_deposit_conditions.length
		) {
			return await this.save((state) => {
				return {
					formData: {
						...state.formData,
						first_deposit_conditions: [
							...this.props.formData?.first_deposit_conditions,
						],
					},
				};
			});
		}

		const countryGroupToRange = await this.state.formData.payoutRangesSumFrom?.partners?.country_group_to_range?.filter(
			group => Object.values(group).every(value => value),
		); //clears empty groups

		const unique = [
			...new Set(countryGroupToRange.map(item => item.payout_rates_range_id)),
		]; //get unique id's cause countyGroupToRange might duplicate some

		const newConditions = unique?.sort((a, b) => a - b).map((id) => {
			return {
				symbol_group_id: "",
				payout_rates_ranges_id: id,
				volume: 0.01, //default value
				deals: '',
			};
		});

		await this.save((state) => {
			return {
				formData: {
					...state.formData,
					first_deposit_conditions: [
						...state.formData.first_deposit_conditions,
						newConditions,
					],
				},
			};
		});
	};

	handleOnChange = async (event) => {
		const { name, value, } = event.target;

		//latency has to be 0 when period is a day
		if (name === "period" && value.value === 1) {
			this.handleOnChange({
				target: {
					name: "latency",
					value: "0",
				},
			});
		}
		if (name === "period" && value.value !== 1) {
			await this.save({
				isChanged: true,
			});
		}

		await this.save(state => ({
			...state,
			errors: state.errors.filter(item => item !== name),
			formData: {
				...state.formData,
				[name]: value.value ?? value,
			},
		}));
	};


	clearErrorOnChange = async (e, index = 0) => {
		const name = e?.target?.name;
		await this.save(state => ({
			...state,
			errors: state.errors.filter((errorItem) => {
				const isNotItem = errorItem !== name;
				//case for CPA FTD
				const isNotDealsOnSymbolGroupChange = !(errorItem === `deals_${ index }` && name === `symbol_group_${ index }`);

				return isNotItem && isNotDealsOnSymbolGroupChange;
			}),
		}));
	};

	onInputChange = async (event) => {
		const { name, value, } = event.target;

		await this.save({ [name]: value, });
	};

	renderCreateButton = () => {
		if (this.isEditMode) {
			return "partners_edit_popup_save";
		}

		return "partners_create_btn";
	};

	renderTitle = () =>
		this.isViewMode
			? this.translate("view_new_partnership_condition")
			: this.isEditMode
				? this.translate("update_new_partnership_condition")
				: this.translate("create_update_new_partnership_condition");

	renderPopupHeader = () => {
		const {
			formData,
			statusList,
			conditionTypes,
			levelList,
			periodList,
			errors,
		} = this.state;

		const period = formData.period;
		const periodIsADay = period === 1;

		const disabled = this.fieldsIsNotEdit || this.isViewMode;

		const disableIfNotNew = this.isEditMode;

		const statusFieldIsDisabled =
			this.statusIsClosed || this.statusIsNotEdit || this.isViewMode;

		const disabledName = `${ formData.name.slice(0, 20) }...`;
		const formDataLength = formData.name.length > 20;
		const statusOptions = this.NewPartnersConditionsListStatusInDB.active
			? filterValues(statusList, [ NewPartnersConditionsListStatus.NEW, ])
			: statusList;

		return (
			<div className="new-partners-conditions__popup mb-20">
				<div className="w-30 mr-35 field">
					<Input
						className={ "field__input" }
						disabled={ disabled }
						isRequired={ true }
						label={ this.translate("new_partners_condition_name") }
						name="name"
						placeholder={ this.translate("symbols_name_placeholder") }
						value={ formDataLength && disabled ? disabledName : formData.name }
						wrapperClassName={ `${ setErrorClass(errors, "name") } field__input` }
						onChange={ this.handleOnChange }
						onValid={ (value) => {
							const regExp = new RegExp("^[A-Za-z0-9 s!@#$%^&*()-.,\\\\]*$");
							if (!value.match(regExp)) {
								throw new Error("xxx");
							}
							if (value.length > 100) {
								throw new Error("xxx");
							}
						} }
					/>
					{disabled && formDataLength ? (
						<p className="field__tooltip">{formData.name}</p>
					) :
						""
					}
				</div>
				<div className="w-30 mr-35">
					<Select
						className={ setErrorClass(errors, "level") }
						disabled={ disableIfNotNew }
						isRequired={ true }
						label={ this.translate("new_partners_condition_level") }
						name="level"
						options={ levelList }
						placeholder={ this.translate(
							"new_partners_condition_level_placeholder",
						) }
						value={ findOptionByValue(levelList, formData.level) }
						onChange={ this.handleOnChange }
					/>
				</div>
				<div className="w-30 mr-35">
					<Select
						className={ setErrorClass(errors, "type") }
						disabled={ disableIfNotNew }
						isRequired={ true }
						label={ this.translate("new_partners_condition_type_label") }
						name="type"
						options={ conditionTypes ?? [] }
						placeholder={ this.translate(
							"new_partners_condition_type_placeholder",
						) }
						value={ findOptionByValue(conditionTypes ?? [], formData.type) }
						onChange={ this.handleOnChange }
					/>
				</div>
				<div className="w-30 mr-35">
					<Select
						className={ setErrorClass(errors, "status") }
						disabled={ statusFieldIsDisabled }
						isRequired={ true }
						label={ this.translate("symbols_status") }
						name="status"
						options={ statusOptions }
						placeholder={ this.translate("symbols_create_status_placeholder") }
						value={ findOptionByValue(statusOptions, formData.status) }
						onChange={ this.handleOnChange }
					/>
				</div>
				<div className="w-30 mr-35">
					<Select
						disabled={disabled}
						label={this.translate("new_partners_condition_calculation_period")}
						name="period"
						options={periodList}
						placeholder={this.translate(
							"new_partners_condition_calculation_period_placeholder",
						)}
						value={findOptionByValue(periodList, formData.period)}
						onChange={this.handleOnChange}
						className={setErrorClass(errors, "period")}
						// disabled={!loadData || this.fieldsIsNotEdit}
						isRequired={true}
					/>
				</div>
				<div className="w-30">
					<Input
						disabled={ periodIsADay || disabled || !period }
						isRequired={ true }
						label={ this.translate("new_partners_condition_delay_days") }
						name="latency"
						placeholder={ this.translate(
							"new_partners_condition_payout_delay_placeholder",
						) }
						value={ periodIsADay ? `0` : `${ formData.latency }` }
						wrapperClassName={ setErrorClass(errors, "latency") }
						onChange={ this.handleOnChange }
						onValid={ (value) => {
							const regExp = /^[0-9]{0,364}$/g;

							if (value !== "" && !regExp.test(value)) {
								throw new Error("xxx");
							}
							if (value === " ") {
								throw new Error("xxx");
							}
							if (`${ value }`.length > 1 && `${ value }`[0] == 0) {
								throw new Error("xxx");
							}
							if (this.period.DAY && value > 0) {
								throw new Error("xxx");
							}
							if (this.period.WEEK && (value < 0 || value > 6)) {
								throw new Error("xxx");
							}
							if (this.period.MONTH && (value < 0 || value > 27)) {
								throw new Error("xxx");
							}
							if (this.period.QUARTER && (value < 0 || value > 88)) {
								throw new Error("xxx");
							}
							if (this.period.YEAR && (value < 0 || value > 364)) {
								throw new Error("xxx");
							}
						} }
					/>
				</div>
			</div>
		);
	};
	renderDescriptionInput = () => {
		const { formData, } = this.state;

		const disabled = this.statusIsClosed || this.isViewMode;
		const descriptionLength = formData?.description?.length;

		const disabledDescription = `${ formData?.description?.slice(0, 200) }...`;

		return (
			<div className="w-100 mr-35 field">
				<Textarea
					className={ `${ disabled ? "disabledTextArea" : "" } description` }
					isDisabled={ disabled }
					label={ this.translate("new_partners_condition_description") }
					name="description"
					notToFocus={ true }
					placeholder={ this.translate(
						"new_partners_condition_description_placeholder",
					) }
					textareaWrapperClassName={ 'field__input' }
					value={ disabled && descriptionLength > 200 ? disabledDescription : formData.description }
					onChange={ this.handleOnChange }
					onValid={ (value) => {
						const regExp = new RegExp(
							"^[A-Za-zА-Яа-яёЁЇїІіЄєҐґ0-9 s.,!@#$%^&*()-]*$",
						);

						if (value !== "" && !regExp.test(value)) {
							throw new Error("xxx");
						}
						if (value.length > 1000) {
							throw new Error("xxx");
						}
					} }
				/>
				{disabled && descriptionLength > 200 ? (
					<p className="field__tooltip">{formData.description}</p>
				) :
					""
				}
			</div>
		);
	};
	renderReferralCountryBlockCpa = () => {
		const {
			errors, countryCheckList, payoutRateList, formData,
		} = this.state;

		return (
			<>
				<Loader
					loaded={ this.state.countriesLoaded }
					loading={ <Preloader scale={ this.props.scale } /> }
					translate={ this.props.translate }
				>
					<ReferralCountryBlockCpa
						clearErrorOnChange={ this.clearErrorOnChange }
						conditionType={ formData.type }
						countryCheckListOptions={ countryCheckList }
						errors={ errors }
						fieldsIsNotEdit={ this.fieldsIsNotEdit }
						formData={ formData }
						isEditMode={ this.isEditMode }
						isViewMode={ this.isViewMode }
						level={ this.level }
						payoutRateList={ payoutRateList }
						setNewValue={ async (fieldName, value) => {
							await this.save(state => ({
								...state,
								formData: {
									...state.formData,
									[fieldName]: value,
								},
							}));
						} }
					/>
				</Loader>
			</>
		);
	};

	renderPayoutRatesCpaSingleLevel = () => {
		const { formData, } = this.state;

		const paymentCoefsInDataBase = this.props.formData.payment_coef_cpa;

		return (
			<>
				<Loader loaded={ this.state.countriesLoaded }>
					<PayoutRateCpaSingleLevel
						clearErrorOnChange={ this.clearErrorOnChange }
						confirmedCountFtd={ formData?.payment_coef_cpa }
						createNewPayoutRangesItem={ async () => {
							const payoutRangeRateCpaIsValid = await validatePayoutRangeRateCpaOnAdd(
								this.state,
								this.save,
								this.condition,
							);

							if (!payoutRangeRateCpaIsValid) {
								return;
							}

							const payment_coef_cpa = this.state.formData?.payment_coef_cpa;
							const lastCoef = payment_coef_cpa[payment_coef_cpa.length - 1];

							await this.save({
								formData: {
									...formData,
									payment_coef_cpa: [
										...formData.payment_coef_cpa,
										{
											deposits_from: +lastCoef.deposits_from + 1,
											coef: parseFloat(+lastCoef.coef + 0.01).toFixed(2),
										},
									],
								},
							});
						} }
						errors={ this.state.errors }
						formData={ this.state.formData }
						isEditMode={ this.isEditMode }
						isViewMode={ this.isViewMode }
						paymentCoefCpaInDb={ paymentCoefsInDataBase }
						removePayoutRangeItem={ async (index) => {
							await this.save((state) => {
								return {
									formData: {
										...state.formData,
										payment_coef_cpa: state.formData.payment_coef_cpa.filter(
											(_, sumIndex) => sumIndex !== index,
										),
									},
									errors: state.errors.filter(
										error =>
											error !== `deposits_from${ index }` &&
											error !== `coef${ index }`,
									),
								};
							});
						} }
						setNewSumFromValue={ async (name, value, index) => {
							const data = [ ...formData.payment_coef_cpa, ];

							data[index] = {
								...data[index],
								[name]: value,
							};
							await this.save({
								formData: {
									...formData,
									payment_coef_cpa: data,
								},
							});
						} }
						status={ this.NewPartnersConditionsListStatusInDB }
						statusIsActive={ this.statusIsActive }
						statusIsClosed={ this.statusIsClosed }
					/>
				</Loader>
			</>
		);
	};

	renderFtdConfirmationBlock = () => {
		const { errors, formData, } = this.state;
		const { enums } = this.props;
		const firstDepositCondInDataBase = this.props.formData
			.first_deposit_conditions;

		return (
			<>
				<FtdCpaSingleBlock
					enums={enums}
					clearErrorOnChange={ this.clearErrorOnChange }
					confirmedConditions={ formData?.first_deposit_conditions } // rename?
					createNewCondition={ this.createNewFirstDepositCondition }
					errors={ errors }
					firstDepositCondInDataBase={ firstDepositCondInDataBase }
					formData={ formData }
					isEditMode={ this.isEditMode }
					isViewMode={ this.isViewMode }
					payoutRateListId={ formData.payout_rate_list_id }
					payoutRateSumFrom={ formData?.payoutRangesSumFrom }
					removeCondition={ async (index) => {
						await this.save((state) => {
							return {
								formData: {
									...state.formData,
									first_deposit_conditions: state.formData.first_deposit_conditions.filter(
										(_, sumIndex) => {
											return index !== sumIndex;
										},
									),
								},
								errors: state.errors.filter(
									error => !error.includes(`volume_${ index }`) && !error.includes(`deals_${ index }`),
								),
							};
						});
					} }
					setGroupField={ async (fieldEntry, index) => {
						await this.save({
							formData: {
								...formData,
								first_deposit_conditions: formData.first_deposit_conditions.map(
									(group, sumIndex) => {
										if (sumIndex === index) {
											group.map((condition) => {
												const [ key, value, ] = Object.entries(fieldEntry).shift();
												condition[key] = value;

												return key === 'symbol_group_id' ? condition.deals = '' : null;
											});
										}
										return group;
									},
								),
							},
						});
					} }
					setGroupRateIdVolume={ async (value, index, subIndex) => {
						await this.save({
							formData: {
								...formData,
								first_deposit_conditions: formData.first_deposit_conditions.map(
									(group, sumIndex) => {
										if (sumIndex === index) {
											group.map(
												(condition, id) =>
													id === subIndex
														? condition.volume = value
														: condition,
											);
										}

										return group;
									},
								),
							},
						});
					} }
					status={ this.NewPartnersConditionsListStatusInDB }
					statusIsActive={ this.statusIsActive }
					statusIsClosed={ this.statusIsClosed }
					symbolOptions={ this.symbolGroupListOptions }
				/>
			</>
		);
	};

	renderVerificationInputsRevshareBlock = () => {
		const { formData, } = this.state;
		const paymentCoefsInDataBase = this.props.formData.payment_coef_revshare;

		return (
			<RegistrationClientsRevshareBlock
				clearErrorOnChange={ this.clearErrorOnChange }
				createNewPayoutRangesItem={ async () => {
					const PayoutPercentageRevshareIsValid = await validatePayoutPercentageRevshare(
						this.state,
						this.save,
						this.condition,
					);

					if (!PayoutPercentageRevshareIsValid) {
						return;
					}

					const payment_coef_revshare = this.state.formData
						?.payment_coef_revshare;
					const lastCoef =
						payment_coef_revshare[payment_coef_revshare.length - 1];

					await this.save({
						formData: {
							...formData,
							payment_coef_revshare: [
								...formData.payment_coef_revshare,
								{
									registrations: parseFloat(+lastCoef.registrations + 1),
									verifications: parseFloat(+lastCoef.verifications + 1),
									active_clients: parseFloat(+lastCoef.active_clients + 1),
									new_active_clients: parseFloat(
										+lastCoef.new_active_clients + 1,
									),
									coef: parseFloat(+lastCoef.coef + 0.01).toFixed(2),
								},
							],
						},
					});
				} }
				errors={ this.state.errors }
				fieldsIsNotEdit={ this.fieldsIsNotEdit }
				formData={ this.state.formData }
				isEditMode={ this.isEditMode }
				isViewMode={ this.isViewMode }
				paymentCoefsInDataBase={ paymentCoefsInDataBase }
				payoutRateRevshare={ formData?.payment_coef_revshare }
				removePayoutRangeItem={ async (index) => {
					await this.save((state) => {
						return {
							formData: {
								...state.formData,
								payment_coef_revshare: state.formData.payment_coef_revshare.filter(
									(_, sumIndex) => sumIndex !== index,
								),
							},
							errors: state.errors.filter(
								error =>
									error !== `deposits_from${ index }` && error !== `coef${ index }`,
							),
						};
					});
				} }
				setNewSumFromValue={ async (
						name,
						value,
						index,
						sumOfInputsErrorName,
				) => {
					const data = [ ...formData.payment_coef_revshare, ];

					data[index] = {
						...data[index],
						[name]: value,
					};
					await this.save({
						errors: this.state.errors.filter(
							item =>
								item !== `${ name }_${ index }` && item !== sumOfInputsErrorName,
						),
						formData: {
							...formData,
							payment_coef_revshare: data,
						},
					});
				} }
				status={ this.NewPartnersConditionsListStatusInDB }
				statusIsActive={ this.statusIsActive }
				statusIsClosed={ this.statusIsClosed }
			/>
		);
	};
	renderPayoutRateBlockRevshare = () => {
		const { errors, formData, } = this.state;

		return (
			<PayoutRateBlockRevshare
				clearErrorOnChange={ this.clearErrorOnChange }
				errors={ errors }
				fieldsIsNotEdit={ this.fieldsIsNotEdit }
				formData={ formData }
				isViewMode={ this.isViewMode }
				setInputsValues={ async (fieldName, value) => {
					await this.save(state => ({
						...state,
						formData: {
							...state.formData,
							[fieldName]: value,
						},
					}));
				} }
			/>
		);
	};

	renderPayoutRateBlockInOut = () => {
		const { errors, formData, } = this.state;

		return (
			<PayoutRateBlockInOut
				clearErrorOnChange={ this.clearErrorOnChange }
				errors={ errors }
				formData={ formData }
				isViewMode={ this.isViewMode }
				level={ this.level }
				setInputsValues={ async (fieldName, value) => {
					await this.save(state => ({
						...state,
						formData: {
							...state.formData,
							[fieldName]: value,
						},
					}));
				} }
				statusIsActive={ this.statusIsActive }
				statusIsClosed={ this.statusIsClosed }
			/>
		);
	};

	renderCountSubpartnersBlockedReferrals = () => {
		const { formData, } = this.state;
		const paymentCoefsInDataBase = this.props.formData.payment_coef_inout;

		return (
			<CountSubpartnersBlockedReferrals
				formData={ formData }
				setNewValue={ async (fieldName, value) => {
					await this.save(state => ({
						...state,
						formData: {
							...state.formData,
							[fieldName]: value,
						},
					}));
				} }
				statusIsActive={ this.statusIsActive }
				statusIsClosed={ this.statusIsClosed }
			/>
		);
	};

	renderPayoutRateListInOutSingleLevel = () => {
		const { formData, } = this.state;
		const paymentCoefsInDataBase = this.props.formData.payment_coef_inout?.sort((a, b) => a?.sum_inout_from - b?.sum_inout_from);

		return (
			<>
				<Loader loaded={ this.state.countriesLoaded }>
					<PayoutRateListInOutSingleLevel
						clearErrorOnChange={ this.clearErrorOnChange }
						createNewPayoutRangesItem={ async (value, forbiddenValue) => {
							const payoutRangeRateInOutisValid = await validatePayoutRangeListInOut(
								this.state,
								this.save,
								this.condition,
							);

							if (!payoutRangeRateInOutisValid) {
								return;
							}

							const payment_coef_inout = this.state.formData
								?.payment_coef_inout;
							const lastCoef =
								payment_coef_inout[payment_coef_inout.length - 1];

							await this.save({
								formData: {
									...formData,
									payment_coef_inout: [
										...formData.payment_coef_inout,
										{
											sum_inout_from: parseFloat(
												+lastCoef.sum_inout_from + 0.01,
											).toFixed(2),
											coef: parseFloat(+lastCoef.coef + 0.01).toFixed(2),
										},
									],
								},
							});
						} }
						errors={ this.state.errors }
						fieldsIsNotEdit={ this.fieldsIsNotEdit }
						formData={ this.state.formData }
						isEditMode={ this.isEditMode }
						isViewMode={ this.isViewMode }
						payment_coef_inout={ formData?.payment_coef_inout }
						paymentCoefsInDataBase={ paymentCoefsInDataBase }
						removePayoutRangeItem={ async (index) => {
							await this.save((state) => {
								return {
									formData: {
										...state.formData,
										payment_coef_inout: state.formData.payment_coef_inout.filter(
											(_, sumIndex) => sumIndex !== index,
										),
									},
									errors: state.errors.filter(
										error =>
											error !== `sum_inout_from${ index }` &&
											error !== `coef${ index }`,
									),
								};
							});
						} }
						setNewSumFromValue={ async (name, value, index) => {
							const data = [ ...formData.payment_coef_inout, ];

							data[index] = {
								...data[index],
								[name]: value,
							};
							await this.save({
								formData: {
									...formData,
									payment_coef_inout: data,
								},
							});
						} }
						status={ this.NewPartnersConditionsListStatusInDB }
						statusIsActive={ this.statusIsActive }
						statusIsClosed={ this.statusIsClosed }
					/>
				</Loader>
			</>
		);
	};

	renderRenumerationRatesRevshare = () => {
		const {
			accountGroupList,
			errors,
			formData,
			symbolList,
			serverCurrency,
			currencies,
		} = this.state;
		const { enums } = this.props;
		const ratesInDataBase = this.props.formData.payout_rates_revshare;
		const statusInDb = this.NewPartnersConditionsListStatusInDB;

		const symbolListOptions = symbolList
			?.filter(items => statusInDb.closed ? items.partners.symbols_lists.status === 2 || items.partners.symbols_lists.status === 0 : items.partners.symbols_lists.status === 2)
			.map((items) => {
				const { name, id, server_name, } = items.partners.symbols_lists;

				return {
					label: name,
					value: id,
					server_name,
				};
			});
		const currency = currencies
			? currencies?.find(currency => +currency.id === +serverCurrency)
			: "";

		return (
			<RenumerationRatesRevshare
				enums={enums}
				accountGroupList={ accountGroupList }
				accountGroupListsOptions={ this.accountGroupListsOptions }
				clearErrorOnChange={ this.clearErrorOnChange }
				createNewCondition={ async () => {
					const renumerationRatesRevshareIsValid = await validateRenumerationRatesRevshare(
						this.state,
						this.save,
						this.condition,
						this.accountGroupListsOptions,
					);

					if (!renumerationRatesRevshareIsValid) {
						return;
					}

					await this.save({
						formData: {
							...formData,
							payout_rates_revshare: [
								...formData.payout_rates_revshare,
								{
									groups_list_id: "",
									symbols_list_id: "",
									reward_by_point_lot: "",
									reward_by_volume_lot: "",
									reward_percent_of_commission: "",
									deals: "",
								},
							],
						},
					});
				} }
				errors={ errors }
				formData={ formData }
				isEditMode={ this.isEditMode }
				isViewMode={ this.isViewMode }
				ratesInDataBase={ ratesInDataBase }
				ratesRevshare={ formData.payout_rates_revshare ?? [] }
				removeCondition={ async (index) => {
					await this.save((state) => {
						return {
							formData: {
								...state.formData,
								payout_rates_revshare: state.formData.payout_rates_revshare.filter(
									(_, sumIndex) => {
										return index !== sumIndex;
									},
								),
							},
							errors: state.errors.filter(
								error => !error.includes(`volume_${ index }`),
							),
						};
					});
				} }
				serverCurrency={ currency }
				setFieldData={ async (name, value, index, groupError) => {
					const data = [ ...formData.payout_rates_revshare, ];
					if (name === 'groups_list_id') {
						data[index] = {
							...data[index],
							[name]: value,
							'deals': "",
							'symbols_list_id': "",
						};
					} else {
						data[index] = {
							...data[index],
							[name]: value,
						};
					}
					await this.save({
						errors: this.state.errors.filter((errorItem) => {
							const isNotCurrentItem = errorItem !== `${ name }_${ index }`;
							const isNotGroupError = errorItem !== groupError;
							const isNotDealsOnGroupListChange = !(errorItem === `deals_${ index }` && name === 'groups_list_id');

							return isNotCurrentItem && isNotGroupError && isNotDealsOnGroupListChange;
						}),
						formData: {
							...formData,
							payout_rates_revshare: data,
						},
					});
				} }
				status={ this.NewPartnersCnditionsListStatusInDB }
				statusIsActive={ this.statusIsActive }
				statusIsClosed={ this.statusIsClosed }
				symbolOptions={ symbolListOptions }
			/>
		);
	};

	renderRefferalCountryBlockCpl = () => {
		const {
			errors,
			countryCheckList,
			payoutRateList,
			leadAppearConditionList,
			formData,
		} = this.state;

		const fieldsIsNotEdit = this.fieldsIsNotEdit;
		const isEditMode = this.isEditMode;
		const isViewMode = this.isViewMode;
		const statusIsClosed = this.statusIsClosed;
		const statusIsActive = this.statusIsActive;
		const countryCheck = this.countryCheck;

		return (
			<>
				<Loader
					loaded={ this.state.countriesLoaded }
					loading={ <Preloader scale={ this.props.scale } /> }
					translate={ this.props.translate }
				>
					<RefferalCountryBlockCpl
						clearErrorOnChange={ this.clearErrorOnChange }
						conditionType={ formData.type }
						countryCheck={ countryCheck }
						countryCheckListOptions={ countryCheckList }
						errors={ errors }
						fieldsIsNotEdit={ fieldsIsNotEdit }
						formData={ formData }
						isEditMode={ isEditMode }
						isViewMode={ isViewMode }
						leadAppearConditionOptions={ leadAppearConditionList }
						level={ this.level }
						payoutRateList={ payoutRateList }
						setNewValue={ async (fieldName, value) => {
							await this.save(state => ({
								...state,
								formData: {
									...state.formData,
									[fieldName]: value,
								},
							}));
						} }
						statusIsActive={ statusIsActive }
						statusIsClosed={ statusIsClosed }
					/>
				</Loader>
			</>
		);
	};

	renderPayoutRateListCplSingleLevel = () => {
		const { formData, } = this.state;
		const paymentCoefsInDataBase = this.props.formData.payment_coef_cpl;

		const fieldsIsNotEdit = this.fieldsIsNotEdit;
		const isEditMode = this.isEditMode;
		const isViewMode = this.isViewMode;
		const statusIsClosed = this.statusIsClosed;
		const statusIsActive = this.statusIsActive;

		return (
			<>
				<Loader loaded={ this.state.countriesLoaded }>
					<PayoutRateListCplSingleLevel
						clearErrorOnChange={ this.clearErrorOnChange }
						createNewPayoutRangesItem={ async (value, forbiddenValue) => {
							const payoutRangeRateCplIsValid = await validatePayoutRangeListCpl(
								this.state,
								this.save,
								this.condition,
							);

							if (!payoutRangeRateCplIsValid) {
								return;
							}

							const payment_coef_cpl = this.state.formData?.payment_coef_cpl;
							const lastCoef = payment_coef_cpl[payment_coef_cpl.length - 1];

							await this.save({
								formData: {
									...formData,
									payment_coef_cpl: [
										...formData.payment_coef_cpl,
										{
											leads_from: +lastCoef.leads_from + 1,
											coef: parseFloat(+lastCoef.coef + 0.01).toFixed(2),
										},
									],
								},
							});
						} }
						errors={ this.state.errors }
						fieldsIsNotEdit={ fieldsIsNotEdit }
						formData={ this.state.formData }
						isEditMode={ isEditMode }
						isViewMode={ isViewMode }
						payment_coef_cpl={ formData?.payment_coef_cpl }
						paymentCoefsInDataBase={ paymentCoefsInDataBase }
						removePayoutRangeItem={ async (index) => {
							await this.save((state) => {
								return {
									formData: {
										...state.formData,
										payment_coef_cpl: state.formData.payment_coef_cpl.filter(
											(_, sumIndex) => sumIndex !== index,
										),
									},
									errors: state.errors.filter(
										error =>
											error !== `leads_from_${ index }` &&
											error !== `coef${ index }`,
									),
								};
							});
						} }
						setNewSumFromValue={ async (name, value, index) => {
							const data = [ ...formData.payment_coef_cpl, ];

							data[index] = {
								...data[index],
								[name]: value,
							};
							await this.save({
								formData: {
									...formData,
									payment_coef_cpl: data,
								},
							});
						} }

						status={ this.NewPartnersConditionsListStatusInDB }
						statusIsActive={ statusIsActive }
						statusIsClosed={ statusIsClosed }
					/>
				</Loader>
			</>
		);
	};

	renderPayoutRatesByLevel () {

		/*get rid off when prod?*/
		const { formData, errors, } = this.state;

		return (
			<PayoutRatesByLevel
				clearErrorOnChange={ this.clearErrorOnChange }
				createNewPayoutRatesByLevelItem={ async (value, forbiddenValue) => {
					const payoutRangeRateByLevel = await validatePayoutRatesByLevel(
						this.state,
						this.save,
						this.level,
					);

					if (!payoutRangeRateByLevel) {
						return;
					}

					const multi_rates_list_rate_by_level = this.state.formData
						?.multi_rates_list_rate_by_level;
					const lastRate =
						multi_rates_list_rate_by_level[
							multi_rates_list_rate_by_level.length - 1
						];

					await this.save({
						formData: {
							...formData,
							multi_rates_list_rate_by_level: [
								...formData.multi_rates_list_rate_by_level,
								{
									level_from: +lastRate.level_to + 1,
									level_to: +lastRate.level_to + 1,
									rate: `${ parseFloat(+lastRate.rate - 0.01).toFixed(2) }`,
								},
							],
						},
					});
				} }
				errors={ errors }
				fieldsIsNotEdit={ this.fieldsIsNotEdit }
				formData={ formData }
				removeRate={ async (index) => {
					await this.save((state) => {
						return {
							formData: {
								...state.formData,
								multi_rates_list_rate_by_level: state.formData.multi_rates_list_rate_by_level.filter(
									(_, rateIndex) => rateIndex !== index,
								),
							},
							errors: state.errors.filter(
								error =>
									error !== `level_from_${ index }` &&
									error !== `level_to_${ index }` &&
									error !== `rate_${ index }`,
							),
						};
					});
				} }
				setNewRateValue={ async (name, value, index) => {
					const data = [ ...formData.multi_rates_list_rate_by_level, ];

					data[index] = {
						...data[index],
						[name]: value,
					};
					await this.save({
						formData: {
							...formData,
							multi_rates_list_rate_by_level: data,
						},
					});
				} }
			/>
		);
	}

	render () {
		const { onClose, } = this.props;
		const { loadData, } = this.state;
		const viewClass =
			this.isViewMode || this.NewPartnersConditionsListStatusInDB.closed
				? "popup_view-mode"
				: "";

		return (
			<Popup
				cancelBtnText={ "partners_payout_rate_list_popup_cancel" }
				createBtnText={ this.renderCreateButton() }
				customClassName={ `${ viewClass } max-height-popup` }
				isImage={ false }
				loadData={ loadData }
				showTitle={ true }
				size={ "lg" }
				title={ this.renderTitle() }
				onClick={ this.saveData }
				onClose={ onClose }
			>
				{this.renderPopupHeader()}
				{this.condition.CPA && this.renderReferralCountryBlockCpa()}
				{this.condition.CPA && this.renderFtdConfirmationBlock()}
				{this.condition.CPL && this.renderRefferalCountryBlockCpl()}
				{this.condition.IN_OUT && this.renderPayoutRateBlockInOut()}
				{this.condition.REVSHARE &&
					this.level.MULTI_LEVEL &&
					this.renderCountSubpartnersBlockedReferrals()}
				{this.condition.REVSHARE && this.renderRenumerationRatesRevshare()}
				{this.level.MULTI_LEVEL && this.renderPayoutRatesByLevel()}
				{this.condition.REVSHARE && this.renderPayoutRateBlockRevshare()}
				{this.condition.CPA && this.renderPayoutRatesCpaSingleLevel()}
				{this.condition.REVSHARE &&
					this.renderVerificationInputsRevshareBlock()}
				{this.condition.IN_OUT && this.renderPayoutRateListInOutSingleLevel()}
				{this.condition.CPL && this.renderPayoutRateListCplSingleLevel()}
				{this.renderDescriptionInput()}
			</Popup>
		);
	}
}

export default withLocalize(CreateUpdateNewPartnersConditions);