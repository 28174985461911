import React                   from 'react';
import { withRouter, } from "react-router-dom";
import { withLocalize, } from 'react-localize-redux';
import { Fade, }               from 'reactstrap';
import { clientService, } from "../../../../../services/ClientDataService";
import {MagicTooltip} from "../../../../tima/components/Magic/MagicTooltip";
import Preloader from "../../../../../components/Common/Preloader";

class LettersPopup extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            loaded: false,
            data: {},
        }
    }

    save = async (state) => new Promise(next => this.setState(state, next));

    async componentDidMount () {
        await this.onDataLoad();
    }

    onDataLoad = async () => {
        try {
            await this.save({ loaded: false });
            const { letterId } = this.props;
            const data = letterId ? await clientService.mail(letterId) : {};
            await this.save({ data, loaded: true });
        } catch (e) {
            await this.save({ data: {}, loaded: true });

        }
    };

    renderClose = () => {
        const onClick = () => {
            this.props.hidePopup();
        };
        const options = { className: 'close-button', onClick, };
        return (<div {...options}><i className={'la la-close'} /></div>);
    };

    renderTitle = () => {
        return (
            <div className="title">{this.props.translate('letter_details_title')}: ID - { this.props.letterId ?? '' }</div>
        );
    };

    renderDetailBlock = () => {
        const defaultArray = ['subject', 'sender', 'recipient', 'system', 'message_id'];
        return (
            <div className="grey-block">
                <div className="grey-block_title">{this.props.translate('letter_details_info')}</div>
                {
                    defaultArray.map(item => {
                        return this.renderItem(item);
                    })
                }
            </div>
        );
    };

    renderItem = (item) => {
        const renderTooltip = (value) => {
            const LENGTH_SHORT = 20;
            let fullName = value ?? '-';
            return (
                <MagicTooltip
                    classNameTooltip={`tooltip tooltip--large-width`}
                    content={ `${fullName}` }
                    lengthCut={ LENGTH_SHORT }
                />
            );
        };
        switch (item) {
            case 'delivered_date':
            case 'updated_at':
                return (
                    <div className="item">
                        <div className="item_title">{this.props.translate(`letter_${item}`)}</div>
                        <div className="item_text">{this.state.data?.[item] ?? '-'}</div>
                    </div>);
            // case 'status':
            //     return (
            //         <div className="item">
            //             <div className="item_title">{this.props.translate(`letter_${item}`)}</div>
            //             <div className="item_text">{this.props?.emailStatusEnum?.[this.state.data?.[item]]}</div>
            //         </div>);
            case 'system':
                return (
                    <div className="item">
                        <div className="item_title">{this.props.translate(`letter_${item}`)}</div>
                        <div className="item_text">{this.props?.emailSystemEnum?.[this.state.data?.[item]]}</div>
                    </div>);
            default:
                return (
                    <div className="item">
                        <div className="item_title">{this.props.translate(`letter_${item}`)}</div>
                        <div className="item_text">{renderTooltip(this.state.data?.[item])}</div>
                    </div>);
        }
    };

    renderHistoryBlock = () => {
        return (
            <div className="grey-block">
                <div className="flex-block">
                    <div className="grey-block_title">{this.props.translate('letter_history_info')}</div>
                    <span className="update" onClick={async () => await this.onDataLoad() }>{this.props.translate('letter_update')}</span>
                </div>
                <div className="item">
                    <div className="item_title bold">{this.props.translate(`letter_updated_at`)}</div>
                    <div className="item_text bold">{this.props.translate(`letter_status`)}</div>
                </div>
                {
                    Object.values(this.props.emailStatusEnum)?.map?.(item => {
                        return (
                            <div className="item">
                                <div className="item_title">{this.state.data?.[`${item}_date`] ?? '-'}</div>
                                <div className="item_text">{item}</div>
                            </div>
                        )
                    })
                }
            </div>
        );
    };

    render () {
        return (
            <Fade
                className={ 'client-popup letters-modal fade-block' }
                in={ this.props.lettersModal }
            >
                <div className="letters-block scroll">
                    {
                        !this.state.loaded ? <Preloader scale={ 1 }/>
                            : <div>
                                { this.renderClose() }
                                { this.renderTitle() }
                                <div className="details-block">
                                    {this.renderDetailBlock()}
                                    {this.renderHistoryBlock()}
                                </div>
                            </div>
                    }
                </div>
            </Fade>
        );
    }
}

export default withRouter(withLocalize(LettersPopup));
