import React from 'react';
import { withLocalize } from 'react-localize-redux';
import {MagicSelect} from "../../../components/Magic/components/MagicSelect";

/**
 * @props
 *
 * @data {Object} contains users (requiered)
 * @handleFilterChange {Function} that receives selected users after submit (requiered)
 * @initSelectedUser {String} userId if filter needs to initialy select a user (optional)
 * @singleDepartmentSelect {Boolean} if filter needs to check users only from one department (optional)
 */
class CalendarFilter extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            isListDisplayed: false,
            // JSON is used to prevent users and departments in props from mutation
            data: JSON.parse(JSON.stringify(props.data)),
            selectedUrgency: "",
            selectedStates: "",
            changeFilters: false,
        };
    }

    UNSAFE_componentWillMount () {
        this.props.initSelectedUser && this.initSelect();
    }

    componentDidMount () {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount () {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (e) => {
        if(!e.path) return //prevents errors in console
        const isInside = e.path.includes(document.querySelector('.list'));

        if (!isInside && this.state.isListDisplayed) {
            this.toogleDropdown();
        }
    }

    toogleDropdown () {
        this.setState({ isListDisplayed: !this.state.isListDisplayed });
    }

    initSelect () {
        this.state.data.forEach((item) => {
            item.users.forEach((user) => {
                if (user.id === this.props.initSelectedUser) {
                    this.handleCheck(item.department, user)
                }
            })
        })
    }

    /**
     * @arg {Object} department department
     * @arg {Object} user pass if particular user was clicked
     * @return {void}
     */
    handleCheck (department, user) {
        const { data } = this.state;
        const index = data.findIndex((el) => el.department.id === department.id);

        if (this.props.singleDepartmentSelect) {
            data.forEach((item) => {
                if (item.department.id !== department.id) {
                    item.department.checked = false;
                    item.users.forEach((user) => user.checked = false);
                }
            });
        }

        // data[index] - particular department
        if (!user) {
            data[index] = {
                ...data[index],
                checked: !data[index].checked,
                users:   data[index].users.map((oldUser) => ({ ...oldUser, checked: !data[index].checked })),
            };
        } else {
            if (!this.props.multipleSelection) {
                this.removeSelectedFromAll();
            }

            const userIndex = data[index].users.findIndex((oldUser) => oldUser.id === user.id);

            data[index].users[userIndex] = {
                ...user,
                checked: !data[index].users[userIndex].checked,
            };

            // check status for department if every user is checked
            data[index] = {
                ...data[index],
                checked: data[index].users.every((el) => el.checked === true),
            };
        }

        this.setState({ data, changeFilters: true, });
    }

    handleSubmit () {
        const users = this.selectedId ?? null;
        const selectStates = this.state.selectedStates ?? null;
        const selectedUrgency = this.state.selectedUrgency ?? null;

        this.props.handleFilterChange(users, selectStates, selectedUrgency);
    }

    renderList () {
        return (
            <div className = 'list scroll'>
                {this.state.data.map((item) => {
                    return (
                        <div className = 'item' key = { item.department.id }>
                            <div className = 'department' >
                                {this.props.multipleSelection ? (
                                    <i
                                        className = { `menu-icon menu-icon-check-circle ${item.checked ? 'active' : ''}` }
                                        onClick = { () => this.handleCheck(item.department) }
                                    />
                                ) : ''}
                                <p className = 'department-name'>{item.department.name}</p>
                            </div>
                            {item.users?.map((user, i) => {
                                return (
                                    <div
                                        className = 'user'
                                        key = { item.department.id + i }>

                                        <i
                                            className = { `menu-icon menu-icon-check-circle ${user.checked ? 'active' : ''}` }
                                            onClick = { () => this.handleCheck(item.department, user) }
                                        />
                                        <p>{user.name ?? '-'} {user.surname ?? '-'}</p>

                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        );
    }

    get selectedNames () {
        const { data } = this.state;
        const selectedUsers = [];

        data.forEach((department) => {
            const checkedUsers = [];

            department.users.forEach((user) => {
                if (user.checked) {
                    checkedUsers.push(`${user.name} ${user.surname}`);
                }
            });
            selectedUsers.push(...checkedUsers);
        });

        return selectedUsers.length > 0 ? selectedUsers.join(', ') : null;
    }

    removeSelectedFromAll = () => {
        const { data } = this.state;

        data.forEach((department) => {
            department.users.forEach((user) => {
                if (user.checked) {
                    user.checked = false;
                }
            });
        });
    }

    get selectedId () {
        const { data } = this.state;
        const selected = [];

        data.forEach((department) => {
            department.users.forEach((user) => {
                if (user.checked) {
                    return selected.push(user.id);
                }
            });
        });

        return selected.length > 0 ? selected : null;
    }

    resetFilter () {
        // JSON is used to prevent users and departments in props from mutation
        this.initSelect();
        this.setState({
            // data: JSON.parse(JSON.stringify(this.props.data)),
            selectedUrgency: "",
            selectedStates: "",
            changeFilters: false,
        });
        this.props.handleFilterChange([this.props.initSelectedUser], null, null);
    }

    renderUrgency () {
        const urgency = this.props.urgencyFilter ?? [];
        const options = {
            value:    this.state.selectedUrgency,
            onChange: value => {
                this.setState({ selectedUrgency: value, changeFilters: true, })
            },
            values: urgency?.map?.(urgency => urgency?.id),
            valueToLabel: (id) => {
                const find = urgency.find((data) => id === data.id);

                return find?.name ?? this.props.translate('calendar_filter_urgency');

            },
        };
        return <MagicSelect { ...options } />;
    };

    renderStates () {
        const states = this.props.statesFilter ?? [];
        const options = {
            value:    this.state.selectedStates,
            onChange: value => {
                this.setState({ selectedStates: value, changeFilters: true, })
            },
            values: states?.map?.(states => states?.id),
            valueToLabel: (id) => {
                const find = states.find((data) => id === data.id);

                return find?.name ?? this.props.translate('calendar_filter_states');

            },
        };
        return <MagicSelect { ...options } />;
    }

    render () {
        const { isListDisplayed } = this.state;
        const { translate } = this.props;
        const selected = this.selectedNames;

        return (
            <div className = 'calendar-filters'>
                {this.renderUrgency()}
                {this.renderStates()}
                <div className = 'magic-filter-fixed dropdown-checkbox'>
                    <div
                        className = 'selected'
                        style = { selected ? {} : { opacity: 0.4, fontStyle: 'italic' } }
                        onClick = { () => this.toogleDropdown() }>
                        {selected ?? translate('magic_filter_translate_payment_manager')}
                        <svg className="MuiSvgIcon-root MuiSelect-icon" focusable="false" viewBox="0 0 24 24"
                             aria-hidden="true">
                            <path d="M7 10l5 5 5-5z"></path>
                        </svg>
                    </div>
                    {isListDisplayed ? this.renderList() : null}
                </div>
                <button
                    className = 'magic-button magic-button_panels-apply'
                    style = {!this.state.changeFilters ? {filter: 'grayscale(1)', pointerEvents: 'none'} : {}}
                    onClick = { () => this.handleSubmit() }
                >
                    {translate('filter_apply')}
                </button>
                {this.state.changeFilters ?
                    <button className = 'magic-button magic-button_panels-reset' onClick = { () => this.resetFilter() }>
                        {translate('dashboard_reset')}
                    </button>
                    : null
                }
            </div>
        );
    }
}

export default withLocalize(CalendarFilter);
