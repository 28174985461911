import React, {Component}                 from 'react';
import PropTypes                          from 'prop-types';
import { withRouter, Link }               from 'react-router-dom';
import { withLocalize }                   from "react-localize-redux";
import { connect }                        from "react-redux";
import { MagicTable }                     from "../../../components/Magic";


class ListTimaAgentsMagicTable extends Component {
    static propTypes = {
        data: PropTypes.array,
        enums: PropTypes.object,
    };

    static defaultProps = {
        data: [],
        enums: {},
    };


    get listTimaAgentsConfig () {
      const { translate, } = this.props;
      const prefix = 'tima_agents_table_';

      return [
          {
              path:   ['tima', 'tima_agents', 'name'],
              key:    'timaAgent',
              render: this.renderTimaLinkField('timaAgent', '/tima/agent'),
              title:  `${prefix}tima_agent`,
          },
          {
              path: ['tima', 'tima_agents', 'id'],
              key:  'timaAgentId',
          },
          {
              path: ['core', 'account', 'id'],
              key:  'clientId',
          },
          {
              path: ['core', 'account', 'name'],
              key:  'clientName',
          },
          {
              path: ['core', 'account', 'surname'],
              key:  'clientSurname',
          },
          {
              accessChecked: true,
              key:           'clientFullname',
              render:        this.renderFullNameField('client', '/clients'),
              title:         `${prefix}client`,
          },
          {
              path:   ['tima', 'tima_manager', 'name'],
              key:    'timaAccount',
              render: this.renderTimaLinkField('timaAccount', '/tima/strategy'),
              title:  `${prefix}tima_account`,
          },
          {
              path: ['tima', 'tima_manager', 'id'],
              key:  'timaAccountId',
          },
          {
              path:   ['tima', 'tima_agents', 'status'],
              key:    'status',
              render: this.renderEnumField('TimaAgentStatusEnum'),
              title:  `${prefix}status`,
          },
          {
              path:   ['tima', 'tima_agents', 'agent_reward'],
              key:    'agentReward',
              render: this.renderInlineValue,
              title:  `${prefix}agent_reward`,
          },
          {
              path: ['core', 'user', 'id'],
              key:  'responsibleId',
          },
          {
              path: ['core', 'user', 'name'],
              key:  'responsibleName',
          },
          {
              path: ['core', 'user', 'surname'],
              key:  'responsibleSurname',
          },
          {
              accessChecked: true,
              key:           'responsibleFullName',
              render:        this.renderFullNameField('responsible', '/settings/user'),
              title:         `${prefix}responsible`,
          },

      ].map(({ title: t, xtitle: x, ...item }) => ({ ...item, ...x && { title: x }, ...t && { title: translate(t) }, }));
  }

  renderLink = (path, text, className = "") => (<Link to={path} className={className}>{text}</Link>);

  renderInlineValue = (value, { item, items }) => {
      if (item?.access?.('index')) {
          return value ?? '-';
      }
      return '-';
  };

  renderTimaLinkField = (fieldName, path) => (value, { item, items }) => {
      const id = items?.[`${fieldName}Id`];
      if (item?.access?.('index', 'show')) {
          return this.renderLink(`${path}/${ id.valueOf }`, value);
      } else if (item?.access?.('index')) {
          return value;
      }
      return '-';
  };

  renderFullNameField = (fieldName, path) => (value, { items }) => {
      const id = items?.[`${fieldName}Id`];
      const name = items?.[`${fieldName}Name`];
      const surname = items?.[`${fieldName}Surname`];
      if (id?.access('show') && name?.access?.('index') && surname?.access?.('index')) {
          return this.renderLink(`${path}/${ id.valueOf }`, `${name.valueOf} ${surname.valueOf}`);
      } else if (name?.access?.('index') && surname?.access?.('index')) {
          return `${ surname.valueOf } ${ name.valueOf }`;
      }
      return '-';
  };

  renderEnumField = (enumName) => (value, { item }) => {
      if (item?.access?.('index')) {
          const enums = this.props?.enums?.tima?.[enumName];
          return enums?.[value] ?? '-';
      }
      return '-';
  };

   render () {
      if (!this.props?.data?.length) {
         return null;
      }

      const options = {
         config: this.listTimaAgentsConfig,
         data: this.props?.data,
         head:   [
             'timaAgent',
             'clientFullname',
             'timaAccount',
             'status',
             'agentReward',
             'responsibleFullName',
         ],
     };
      return (<MagicTable {...options} />);
   }
}

export default withRouter(withLocalize(ListTimaAgentsMagicTable));
