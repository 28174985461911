import LinkConfig from "./LinkConfig";

export const breadCrumbs4AffiliateProgramsManagment = (translate) => {
	// eslint-disable-next-line new-cap
	return LinkConfig(
		translate,
		[
			"dashboard",
			"partners",
			"affiliate-programs-management",
			"symbols-groups",
		],
		{},
	);
};