import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, } from 'react-router-dom';
import { withLocalize, } from "react-localize-redux";
import { MagicTable, } from '../../../../../components/Magic';
import { enumService, } from "../../../../../services/EnumDataService";
import { MagicTooltip } from "../../../../tima/components/Magic/MagicTooltip";

class ListLettersMagicTable extends React.Component {
    static propTypes = {
        data: PropTypes.array,
    };

    static defaultProps = {
        data: [],
        enums: {
            data: {},
            hash: null,
        }
    };

    constructor (props) {
        super(props);

        this.state = {
            confirmClose: null,
        };

        Object.defineProperty(this, 'mf', { get: () => this.props.mf, });
    }

    get params () {
        return this.props?.match?.params;
    }

    get query () {
        return new URLSearchParams(this.props?.location?.search);
    }

    get lettersConfig () {
        const { translate, } = this.props;
        const result = [ {
            key: 'letterId',
            path: [ 'log', 'mails', 'id', ],
            render: this.renderText,
            title: 'letter_id',
        }, {
            key: 'letterDetail',
            path: [ 'log', 'mails', ],
            render: this.renderDetails,
            xtitle: ' ',
        }, {
            key: 'letterSendData',
            orderId: 'o:mDate',
            path: [ 'log', 'mails', 'created_at', ],
            render: this.renderText,
            title: 'letter_delivered_date',
        }, {
            key: 'letterSender',
            path: [ 'log', 'mails', 'sender', ],
            render: this.renderText,
            title: 'letter_sender',
        }, {
            key: 'letterRecipientAddress',
            path: [ 'log', 'mails', 'recipient', ],
            render: this.renderText,
            title: 'letter_recipient',
        }, {
            key: 'letterStatus',
            orderId: 'o:mStatus',
            path: [ 'log', 'mails', 'status', ],
            render: this.renderStatus,
            title: 'letter_status',
        }, {
            key: 'letterChangeDate',
            path: [ 'log', 'mails', 'updated_at', ],
            render: this.renderText,
            title: 'letter_updated_at',
        }, {
            key: 'letterProvider',
            path: [ 'log', 'mails', 'system', ],
            render: this.renderProvider,
            title: 'letter_system',
        }, {
            key: 'letterSubject',
            orderId: 'o:mSubject',
            path: [ 'log', 'mails', 'subject', ],
            render: this.renderText,
            title: 'letter_subject',
        }, {
            key: 'letterMessageId',
            path: [ 'log', 'mails', 'message_id', ],
            render: this.renderText,
            title: 'letter_message_id',
        }, ].map(({
            orderId: o,
            title: t,
            xtitle: x,
            ...item
        }) => ({
            ...item,
            ...o && {
                order: async () => {
                    await this.mf.orderedToNext(o);
                },
                orderTo: this.mf.orderedTo(o),
            },
            ...t && { title: translate(t), },
            ...x && { title: x, },
        }));

        return result;
    }

    enumsChange = async (enums) => {
        if (enums.hash===this.state?.enums?.hash) {
            return `${ this.constructor.name }.enumsChange: false`;
        }
        await this.save({ enums, });

        return `${ this.constructor.name }.enumsChange: true`;
    };

    async componentDidMount () {
        enumService.subscribe('enums', this.enumsChange, this);
        await enumService.enums;
    }

    componentWillUnmount () {
        enumService.unsubscribe('enums', this.enumsChange, this);
    }

    save = state => new Promise(next => this.setState(state, next));

    renderText = (value, { item, items, }) => {
        if (item.access('index', 'show')) {
            const LENGTH_SHORT = 20;
            let fullName = value ?? '-';
            fullName = (
                <MagicTooltip
                    classNameTooltip={`tooltip tooltip--large-width`}
                    content={ `${fullName}` }
                    lengthCut={ LENGTH_SHORT }
                />
            );
            return fullName ?? '-';
        }

        return '-';
    };

    renderStatus = (status, { item, }) => {
        const emailStatusEnum = this.state.enums?.data?.log?.LogEmailStatusEnum;
        if (item.access('index', 'show')) {
            return emailStatusEnum[status] ?? '-';
        }
        return '-'
    };

    renderProvider = (value, { item, }) => {
        const emailSystemEnum = this.state.enums?.data?.log?.LogEmailSystemEnum;
        if (item.access('index', 'show')) {
            return emailSystemEnum[value] ?? '-';
        }
        return '-'
    };

    renderDetails = (value, { items, }) => {
        const options = {
            className: 'letter-details',
            onClick: async () => {
                await this.props?.togglePopup(items.letterId.valueOf);
            }
        };
        return (
            <span { ...options } >{this.props.translate('letter_details')}</span>
        );
    };

    render () {
        if (!this.props?.data?.length) {
            return null;
        }

        const options = {
            config: this.lettersConfig,
            data: this.props?.data,
            head: [
                "letterId",
                "letterSendData",
                "letterSubject",
                "letterSender",
                "letterRecipientAddress",
                "letterStatus",
                "letterChangeDate",
                "letterProvider",
                "letterMessageId",
                "letterDetail",
            ],
        };

        return (<MagicTable { ...options } />);
    }
}

export default withRouter(withLocalize(ListLettersMagicTable));
