import React, { Component, } from "react";
import { withRouter, } from "react-router-dom";
import { withLocalize, } from "react-localize-redux";
import { Breadcrumbs, } from "../../tima/components/Breadcrumbs";
import * as configBreadcrumbs from "./ContestsLinkBreadcrumbsConfig";
import { MagicButton, } from "../../../components/Magic/components/MagicButton";
import ContestCard from "./ContestCard";
import { ContestsTable, } from "./ContestsTable";
import { CreateContestPopup, } from "./CreateContestPopup/CreateContestPopup";
import { GET_CONTESTS, } from "../apiRoutes";
import { securedRequest, } from "../../../services/RequestService";
import { CONTESTS_EXPORT_FIELDS, } from "./constants";
import {
  MagicFilterPanels,
  Mentor,
  showExportToExcelError,
} from "../../../components/Magic";
import PermissionService from "../../../services/PermissionService";
import { enumService, } from "../../../services/EnumDataService";
import '../styles/contests.scss';

const TAB_ID = {
  contest: 'contest',
  contests: 'contests',
};

Object.freeze(TAB_ID);

const breadcrumbs = (translate, activeTab, { contestId, }) => {
  const config = configBreadcrumbs;

  switch (activeTab) {
    case TAB_ID.contest:
      return config.breadcrumbs4Contest(translate, contestId);
    default:
    case TAB_ID.contests:
      return config.breadcrumbs4Contests(translate);
  }
};

class Contests extends Component {
  constructor (props) {
    super(props);
    this.state = {
      isOpenCreatePopup: false,
      nameContest: "",
    };

    const mf = new Mentor({
      owner: this,
      serviceId: 200,
      translate: this.props.translate,
    });

    Object.defineProperty(this, 'mf', { get: () => mf, });
  }

  async componentDidMount () {
    enumService.subscribe('enums', this.enumsChange, this);
    await enumService.enums;
  }

  componentWillUnmount () {
    enumService.unsubscribe('enums', this.enumsChange, this);
  }

  enumsChange = async (enums) => {
    if (enums.hash === this.state?.enums?.hash) {
      return `${ this.constructor.name }.enumsChange: false`;
    }
    await this.save({ enums, });

    return `${ this.constructor.name }.enumsChange: true`;
  };

  save = async state => new Promise(next => this.setState(state, next));

  params = () => this.props.match.params;

  query = () => new URLSearchParams(this.props.location.search);

  pagination = () => {};

  getNameContest = name => this.setState({ nameContest: name, });

  get contestConfig () {
    return [ {
      key: 'contestsExport',
      path: [ 'contests', 'contests', ],
    }, {
      key: 'contestsCreate',
      path: [ 'contests', 'contests', 'id', ],
    }, ];
  }

  exportFile = () => {
    const { filterId: filter_id, } = this.mf;
    const globalUrl = `${ GET_CONTESTS }`;
    const exportUrl = `export-to-excel`;
    const url = `${ globalUrl }/${ exportUrl }`;
    const options = {
      fields: CONTESTS_EXPORT_FIELDS.contests,
      file_name: `Contests_list`,
    };

    if (filter_id) {
      options.filter_id = filter_id;
    }
    securedRequest.post(url, options)
      .then(() => {
        // console.log('ok');
      })
      .catch((error) => {
        showExportToExcelError(error);
      });
  };

  isOpenCreatePopup = () => {
    this.setState({ isOpenCreatePopup: false, });
  }

  renderButtonExport = () => {
    const conf = PermissionService.calc(this.contestConfig);

    if (conf?.contestsExport.access('exportToExcel')) {
      const options = {
        children: this.props.translate("contests_toollbar_export"),
        className: "magic-button__item magic-button__item_export",
        onClick: () => this.exportFile(),
      };

      return (<MagicButton { ...options } />);
    }

    return "";
  };

  renderButtonCreate = () => {
    const conf = PermissionService.calc(this.contestConfig);

    if (conf?.contestsCreate.access('store')) {
      const options = {
        children: this.props.translate("contests_toollbar_create"),
        className: "magic-button__item magic-button__item_create-request-contests",
        onClick: () => this.setState({ isOpenCreatePopup: true, }),
      };

      return (<MagicButton { ...options } />);
    }

    return "";
  };

  render () {
    const enums = this.state?.enums?.data;
    const renderContent = (translate, active, params, query) => {
      switch (active) {
        case TAB_ID.contest:
          return (
            <React.Fragment>
              <div className="top-page-block contests__header">
                <div className="contests__header_title">
                  <h1 className="page-title"> { this.state?.nameContest } </h1>
                  <Breadcrumbs items={ breadcrumbsItems } />
                </div>
              </div>
              <ContestCard
                enums={ enums }
                getNameContest={ this.getNameContest }
                lang={ this.props?.activeLanguage?.code }
                params={ params }
                query={ query }
                translate={ this.props?.translate }
              />
            </React.Fragment>
          );
        default:
        case TAB_ID.contests:
          return (
            <React.Fragment>
              <div className="top-page-block contests__header">
                <div className="contests__header_title">
                  <h1 className="page-title">
                    { translate(`contests_h1`) }
                  </h1>
                  <Breadcrumbs items={ breadcrumbsItems } />
                </div>
                <div className="contests-toolbar">
                  { this.renderButtonExport() }
                  { this.renderButtonCreate() }
                </div>
              </div>

              <MagicFilterPanels
                mf={ this.mf }
                show={ true }
                translate={ translate }
              />

              <ContestsTable
                enums={ enums }
                lang={ this.props?.activeLanguage?.code }
                mf={ this.mf }
                params={ params }
                query={ query }
                translate={ this.props?.translate }
              />
              { this.state.isOpenCreatePopup && (
                <CreateContestPopup
                  enums={ enums }
                  isOpenCreatePopup={ this.isOpenCreatePopup }
                  translate={ this.props?.translate }
                />
              ) }
            </React.Fragment>
          );
      }
    };

    const { activeTab, translate, } = this.props;
    const breadcrumbsItems = breadcrumbs(translate, activeTab, this.params());

    return (
      <div className="contests">
        { renderContent(translate, activeTab, this.params(), this.query()) }
      </div>
    );
  }
}

export default withRouter(withLocalize(Contests));