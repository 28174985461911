import React, {Component}                 from 'react';
import PropTypes                          from 'prop-types';
import { withRouter, Link }               from 'react-router-dom';
import { withLocalize }                   from "react-localize-redux";
import { connect }                        from "react-redux";
import { MagicTable }                     from "../../../components/Magic";


class ListTimaInvestmentsMagicTable extends Component {
    static propTypes = {
        data: PropTypes.array,
        enums: PropTypes.object,
    };

    static defaultProps = {
        data: [],
        enums: {},
    };

   get listTimaInvestmentsConfig () {
      const { translate, } = this.props;
      const prefix = 'tima_investments_table_';

      return [
          {
              path:   ['tima', 'tima_investment', 'name'],
              key:    'timaInvestment',
              render: this.renderTimaLinkField('timaInvestment', '/tima/investment'),
              title:  `${prefix}investment_number`,
          },
          {
              path: ['tima', 'tima_investment', 'id'],
              key:  'timaInvestmentId',
              render: this.renderTimaLinkField('timaInvestment', '/tima/investment'),
              title:  `${prefix}investment_id`,
          },
          {
              path: ['core', 'account', 'id'],
              key:  'clientId',
          },
          {
              path: ['core', 'account', 'name'],
              key:  'clientName',
          },
          {
              path: ['core', 'account', 'surname'],
              key:  'clientSurname',
          },
          {
              accessChecked: true,
              key:           'clientFullname',
              render:        this.renderFullNameField('client', '/clients'),
              title:         `${prefix}client`,
          },
          {
              path:   ['tima', 'tima_investment', 'amount'],
              key:    'amount',
              render: this.renderInlineValue,
              title:  `${prefix}amount`,
          },
          {
              path:   ['core', 'trading_accounts', 'acc_ccy'],
              key:    'currency',
              render: this.renderEnumField('core', 'AccountCurrencyEnum'),
              title:  `${prefix}currency`,
          },
          {
              path:   ['tima', 'tima_manager', 'name'],
              key:    'timaAccount',
              render: this.renderTimaLinkField('timaAccount', '/tima/strategy'),
              title:  `${prefix}tima_account`,
          },
          {
              path: ['tima', 'tima_manager', 'id'],
              key:  'timaAccountId',
          },
          {
              path:   ['tima', 'tima_investment', 'status'],
              key:    'status',
              render: this.renderEnumField('tima', 'TimaInvestmentStatusEnum'),
              title:  `${prefix}status`,
          },
          {
              path:   ['tima', 'tima_investment', 'risk'],
              key:    'risk',
              render: this.renderInlineValue,
              title:  `${prefix}risk`,
          },
          {
              path:   ['tima', 'tima_investment', 'reinvest'],
              key:    'reinvest',
              render: this.renderEnumField('tima', 'TimaInvestmentReinvestStatusEnum'),
              title:  `${prefix}reinvest`,
          },

          {
              path:   ['tima', 'tima_investment', 'share'],
              key:    'share',
              render: this.renderNumberFixedFormat(2),
              title:  `${prefix}share`,
          },
          {
              accessChecked: true,
              path:          ['core', 'trading_accounts', 'current_equity'],
              key:           'equity',
              render:        this.renderInlineValue,
              title:         `${prefix}equity`,
          },
          {
              accessChecked: true,
              path:          ['core', 'trading_accounts', 'current_profitability'],
              key:           'currentProfitability',
              render:        this.renderInlineValue,
              title:         `${prefix}current_yield_investments`,
          },
          {
              accessChecked: true,
              path:          ['core', 'trading_accounts', 'net_profit'],
              key:           'netProfit',
              render:        this.renderInlineValue,
              title:         `${prefix}net_profit`,
          },
          {
              path:   ['tima', 'tima_agents', 'name'],
              key:    'timaAgent',
              render: this.renderTimaLinkField('timaAgent', '/tima/agent'),
              title:  `${prefix}tima_agent`,
          },
          {
              path: ['tima', 'tima_agents', 'id'],
              key:  'timaAgentId',
          },
          {
              path:   ['tima', 'tima_investment', 'created_at'],
              key:    'createdAt',
              render: this.renderInlineValue,
              title:  `${prefix}date_creation`,
          },
          {
              path:   ['tima', 'tima_investment', 'date_closed'],
              key:    'dateClosed',
              render: this.renderInlineValue,
              title:  `${prefix}date_closing`,
          },
          {
              path: ['core', 'user', 'id'],
              key:  'responsibleId',
          },
          {
              path: ['core', 'user', 'name'],
              key:  'responsibleName',
          },
          {
              path: ['core', 'user', 'surname'],
              key:  'responsibleSurname',
          },
          {
              accessChecked: true,
              key:           'responsibleFullName',
              render:        this.renderFullNameField('responsible', '/settings/user'),
              title:         `${prefix}responsible`,
          },

      ].map(({ title: t, xtitle: x, ...item }) => ({ ...item, ...x && { title: x }, ...t && { title: translate(t) }, }));
  }


   renderLink = (path, text, className = "") => (<Link to={path} className={className}>{text}</Link>);

   renderInlineValue = (value, { item, items }) => {
      if (item?.access?.('index')) {
          return value ?? '-';
      }
      return '-';
   };

   renderNumberFixedFormat = (digits) => (value, { item, items }) => {
      if (item?.access?.('index')) {
          // return !isNaN(+value) ? (+value)?.toFixed?.(digits) ?? '-' : '-';
          return (+value).toFixed(digits) ?? '-';
      }
      return '-';
  };

   renderFullNameField = (fieldName, path) => (value, { items }) => {
      const id = items?.[`${fieldName}Id`];
      const name = items?.[`${fieldName}Name`];
      const surname = items?.[`${fieldName}Surname`];
      if (id?.access('show') && name?.access?.('index') && surname?.access?.('index')) {
          return this.renderLink(`${path}/${ id.valueOf }`, `${name.valueOf} ${surname.valueOf}`);
      } else if (name?.access?.('index') && surname?.access?.('index')) {
          return `${ surname.valueOf } ${ name.valueOf }`;
      }
      return '-';
   };

   renderTimaLinkField = (fieldName, path) => (value, { item, items }) => {
      const id = items?.[`${fieldName}Id`];
      if (item?.access?.('index', 'show')) {
          return this.renderLink(`${path}/${ id.valueOf }`, value);
      } else if (item?.access?.('index')) {
          return value;
      }
      return '-';
   };

   renderEnumField = (path, enumName) => (value, { item }) => {
      if (item?.access?.('index')) {
          const enums = this.props?.enums?.[path]?.[enumName];
          return enums?.[value] ?? '-';
      }
      return '-';
   };

   render () {
      if (!this.props?.data?.length) {
         return null;
      }

      const options = {
         config: this.listTimaInvestmentsConfig,
         data: this.props?.data,
         head:   [
             'timaInvestmentId',
             'timaInvestment',
             'clientFullname',
             'amount',
             'currency',
             'timaAccount',
             'status',
             'risk',
             'reinvest',
             'share',
             'equity',
             'currentProfitability',
             'netProfit',
             'timaAgent',
             'createdAt',
             'dateClosed',
             'responsibleFullName',
         ],
     };
      return (<MagicTable {...options} />);
   }
}

export default withRouter(withLocalize(ListTimaInvestmentsMagicTable));
