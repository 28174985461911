import {
    itemsBuild,
    itemsDrain,
    itemsResort,
    CFG_ITEM_TYPE,
} from '../Config';

import { onEnabled, render, } from '../Mentor/withDropDown';

const f_uId = {
    'drop-down': '/api/core/user/drop-down?take=all',
    'o=': ['=', '!=',],
};

const p_uId = {
    onEnabled: onEnabled('users'),
    render: render(
        'users',
        _ => _ ? `${_?.name} ${_?.surname}` : '-',
    ),
};


export default {
    id: 189,
    ...[
        // filters
        ...[
            {
                f: 'core.account.id',
                id: 'f:partnerId',
            },
            {
                f: 'core.user.id',
                id: 'f:uId',
                ...f_uId,
            },
            {
                f: 'partners.programs.name',
                id: 'f:partnerProgram',
            },
            {
                f: 'partners.requests.status',
                id: 'f:status',
            },
            {
                f: ['core.account.surname', 'core.account.name',],
                id: 'f:partnerClient',
            },
            {
                f: 'partners.requests.updated_at',
                id: 'f:partnerApplicationDate',
            },
            {
                f: 'partners.requests.approved_date',
                id: 'f:partnerConfirmated',
            },
        ]
            .filter(_ => _ && !_.off)
            .map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

        // orders
        ...[
            {
                f: 'core.account.id',
                id: 'o:partnerId',
            },
            {
                f: 'core.account.id',
                id: 'o:id',
            },
            {
                f: 'core.account.name',
                id: 'o:partnerClient',
            },
            {
                f: 'partners.programs.name',
                id: 'o:partnerProgram',
            },
            {
                f: 'partners.requests.status',
                id: 'o:status',
            },
            {
                f: 'partners.requests.reason',
                id: 'o:partnerReason',
            },
            {
                f: 'partners.requests.updated_at',
                id: 'o:partnerApplicationDate',
            },
            {
                f: 'partners.requests.approved_date',
                id: 'o:partnerConfirmated',
            },
        ]
            .filter(_ => _ && !_.off)
            .map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

        // panels
        ...[
            {
                ii: 'f:partnerId',
                t: 'partners_requests_partner_id',
            },
            {
                ii: 'f:partnerClient',
                t: 'partners_requests_partner_client',
            },
            {
                ii: 'f:uId',
                t: 'partners_list_partner_user',
                ...p_uId,
            },
            {
                ii: 'f:partnerProgram',
                t: 'partners_requests_partner_affiliate_program',
            },
            {
                ii: 'f:status',
                t: 'partners_requests_partner_status',
            },
            {
                ii: 'f:partnerApplicationDate',
                t: 'partners_requests_partner_application_date',
            },
            {
                ii: 'f:partnerConfirmated',
                t: 'partners_list_partner_confirmation_date',
            },
        ]
            .filter(_ => _ && !_.off)
            .map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

    ]
        .map(itemsBuild)
        .reduce(itemsDrain, [])
        .reduce(itemsResort, {}),

    path: 'partners/requests',
    widget: false,
};
