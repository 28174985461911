import React from "react";
import BreadcrumbText from './BreadcrumbText';
import BreadcrumbLink from './BreadcrumbLink';
import './styles.scss';

export default function BreadcrumbItem ( props ) {
    const { link, class4Item, } = props;
    return (
        <li
            className={ class4Item || `breadcrumb__item` }
        >
            { link ? <BreadcrumbLink { ...props } /> : <BreadcrumbText { ...props } /> }
        </li>
    );
}
