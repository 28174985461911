export const contestsLang = {
  //CONTESTS LIST
  "contests_h1": ["Contests", "Конкурсы", "Конкурси"],
  "contests_bradcrumbs": ["Contests", "Конкурсы", "Конкурси"],
  "contests_link_contest": ["Contest", "Конкурс", "Конкурс"],
  "contests_toollbar_export": ["Export", "Экcпорт", "Експорт"],
  "contests_toollbar_create": ["Create new", "Создать конкурс", "Створити конкурс"],
  "contests_list_id": ["ID", "ID", "ID"],
  "contests_list_title": ["Title", "Название", "Назва"],
  "contests_list_start_reg_date": ["Start Registration", "Cтарт регистрации", "Старт реєстрації"],
  "contests_list_finish_reg_date": ["End Registration", "Конец регистрации", "Кінець реєстрації"],
  "contests_list_date_start": ["Start contest", "Старт конкурса", "Початок конкурса"],
  "contests_list_date_end": ["End contest", "Конец конкурса", "Кінець конкурса"],
  "contests_list_prize_fund": ["Prize pool", "Призовой фонд", "Призовий фонд"],
  "contests_list_prize_count": ["Prizes", "Призовых мест", "Призових місць"],
  "contests_list_participants_categories": ["Category of participants", "Категория участников", "Категорія учасників"],
  "contests_list_status": ["Contest Status", "Статус конкурса", "Статус конкурса"],

  //ONE CONTEST CARD
  "contest_card_title": ["Сonditions of the contest", "Условия конкурса", "Умови конкурс"],
  "contest_card_btn_edit": ["Edit", "Редактировать", "Редагувати"],
  "contest_card_btn_delete": ["Delete", "Удалить", "Видалити"],
  "contest_card_start_reg_date": ["Start registration", "Старт регистрации", "Старт реєстрації"],
  "contest_card_finish_reg_date": ["Finish registration", "Конец регистрации", "Кінець реєстрації"],
  "contest_card_date_start": ["Start contest", "Старт конкурса", "Початок конкурса"],
  "contest_card_date_end": ["Finish contest", "Конец конкурса", "Кінець конкурса"],
  "contest_card_prize_fund": ["Prize pool", "Призовой фонд", "Призовой фонд"],
  "contest_card_prize_count": ["Prizes", "Призовых мест", "Призових місць"],
  "contest_card_citizenship": ["Citizenship", "Гражданство", "Громадянство"],
  "contest_card_category_participants": ["Category of participants", "Категория участников", "Категорія участників"],
  "contest_card_status": ["Status of the contest", "Статус конкурса", "Статус конкурса"],
  "contest_card_condition_max_loss": ["Maximum Drawdown Condition", "Условие по максимальной просадке", "Умова по максимальній просадці"],
  "contest_card_condition_closed_orders": ["Condition for closed sell / buy orders", "Условие по закрытым ордерам sell/buy", "Умова за закритими ордерами sell / buy"],
  "contest_card_created_at": ["Created date", "Дата создания", "Дата створення"],
  "contest_card_updated_at": ["Edit date", "Дата изменения", "Дата зміни"],
  "contest_card_language": ["Language of the contest", "Язык конкурса", "Мова конкурса"],
  "contest_card_default-language": ["Default language", "Язык по умолчанию", "Мова за замовчуванням"],
  "contest_card_name": ["Title of the contest", "Название конкурса", "Назва конкурса"],
  "contest_card_description": ["Description", "Описание", "Опис"],
  "contest_card_condition": ["Condition", "Условия конкурса", "Умова конкурса"],
  "contest_card_sendpulse_book": ["SendPulse book", "SendPulse book", "SendPulse book"],
  "contest_card_sendpulse_decline_book": ["SendPulse decline book", "SendPulse decline book", "SendPulse decline book"],

  //DELETE CONTEST
  "contest_card_delete_сonfirm": ["Сonfirm", "Подтвердить", "Підтвердити"],
  "contest_card_delete_cancel": ["Cancel", "Отменить", "Відмінити"],
  "contest_card_delete_attention": [
    "Warning! Removing will affect all contestants, do not do this unless absolutely necessary",
    "Предупреждение! Удаление затронет всех участников конкурса, не делайте этого без крайней необходимости",
    "Попередження! Видалення торкнеться всіх учасників конкурсу, не робіть цього без крайньої необхідності"
  ],

  //ONE CONTEST LIST MEMBERS
  "contest_list-members_btn_export": ["Export to Excel", "Экспорт в Excel", "Експорт в Excel"],
  "contest_list-members_title": ["Competitors", "Участники конкурса", "Участники конкурса"],
  "contest_list-members_place": ["Place", "Место", "Місце"],
  "contest_list-members_id": ["ID", "ID", "ID"],
  "contest_list-members_client": ["Client", "Клиент", "Клієнт"],
  "contest_list-members_nickname": ["Nickname", "Никнейм", "Нікнейм"],
  "contest_list-members_status": ["Status", "Статус", "Статус"],
  "contest_list-members_mt_login": ["Contest demo-account", "Конкурсный demo-счет", "Конкурсний demo-рахунок"],
  "contest_list-members_balance": ["Contest demo-account balance", "Баланс конкурсного demo-счета", "Баланс конкурсного demo-рахунку"],
  "contest_list-members_equity": ["Contest demo-account equity", "Средства конкурсного demo-счета", "Кошти конкурсного demo-рахунку"],
  "contest_list-members_profitability": ["Current invest profitability", "Текущая доходность", "Поточна прибутковість"],
  "contest_list-members_loss": ["Current drawdown", "Текущая просадка", "Поточна просадка"],
  "contest_list-members_max_loss": ["Maximum drawdown", "Максимальная просадка", "Максимальна просадка"],
  "contest_list-members_created_at": ["Date of registration", "Дата регистрации", "Дата реєстрації"],
  "contest_list-members_rating" : ["Contest account", "Конкурсный счет", "Конкурсний рахунок"],
  "contest_list-members_disqualify": ["Disqualify", "Дисквалифицировать", "Дискваліфікувати"],
  "contest_list-members_disqualified": ["Disqualified", "Дисквалифицирован", "Дискваліфікований"],
  "contest_list-members_not-disqualify": ["Not disqualified", "Не дисквалифицирован", "Не дискваліфікований"],

  //CREATE CONTEST POPUP
  "contest_popup_title": ["Create contest", "Создание конкурса", "Створення конкурсу"],
  "contest_popup_title-edit": ["Edit contest", "Редактирование конкурса", "Редагування конкурсу"],
  "contest_popup_start_reg_date": ["Start registration*", "Старт регистрации*", "Старт реєстрації*"],
  "contest_popup_finish_reg_date": ["Finish registration*", "Конец регистрации*", "Кінець реєстрації*"],
  "contest_popup_date_start": ["Start contest*", "Старт конкурса*", "Початок конкурсу*"],
  "contest_popup_date_end": ["Finish contest*", "Конец конкурса*", "Кінець конкурсу*"],
  "contest_popup_amount_prize": ["Prize pool*", "Призовой фонд*", "Призовий фонд*"],
  "contest_popup_prize": ["Prize places*", "Призовых мест*", "Призових місць*"],
  "contest_popup_create_date": ["Created date*", "Дата создания*", "Дата створення*"],
  "contest_popup_edit_date": ["Edit date*", "Дата изменения*", "Дата зміни*"],
  "contest_popup_sendpulse_book": ["SendPulse book*", "SendPulse book*", "SendPulse book*"],
  "contest_popup_sendpulse_decline_book": ["SendPulse decline book*", "SendPulse decline book*", "SendPulse decline book*"],
  "contest_popup_drawdown_condition": ["Maximum Drawdown Condition,%*", "Условие по максимальной просадке,%*", "Умова по максимальній просадці,%*"],
  "contest_popup_closed_condition": ["Condition for closed sell / buy orders*", "Условие по закрытым ордерам sell/buy*", "Умова за закритими ордерами sell / buy*"],
  "contest_popup_status": ["Status of the contest*", "Статус конкурса*", "Статус конкурсу*"],
  "contest_popup_category": ["Category of participants*", "Категория участников*", "Категорія учасників*"],
  "contest_popup_select-category": ["Select category*", "Выберите категорию*", "Виберіть категорію*"],
  "contest_popup_select-all": ["Select all", "Выбрать все", "Виберати все"],
  "contest_popup_select-citizenship": ["Select citizenship*", "Выберите гражданство*", "Виберіть громадянство*"],
  "contest_popup_select-language": ["Select language*", "Выберите язык*", "Виберіть мову*"],
  "contest_popup_citizenship": ["Citizenship*", "Гражданство*", "Громадянство*"],
  "contest_popup_language": ["Language contest*", "Язык конкурса*", "Мова конкурсу*"],
  "contest_popup_title-contest": ["Name contest*", "Название конкурса*", "Назва конкурсу*"],
  "contest_popup_description-contest": ["Description contest*", "Описание конкурса*", "Опис конкурсу*"],
  "contest_popup_condition": ["Conditions*", "Условия*", "Умови*"],
  "contest_popup_description-condition": ["Download conditions", "Загрузить условия", "Завантажити умови"],
  "contest_popup_btn_save": ["Save", "Сохранить", "Зберегти"],
  "contest_popup_btn_cancel": ["Cancel", "Отменить", "Відмінити"],
  "contest_card_places_from": ["Prize places from", "Призовых мест от", "Призових місць від"],
  "contest_card_places_to": ["Prize places to", "Призовых мест до", "Призових місць до"],
  "contest_card_prize": ["Amount", "Сумма", "Сума"],
  "contest_card_places_from_req": ["Prize places from*", "Призовых мест от*", "Призових місць від*"],
  "contest_card_places_to_req": ["Prize places to*", "Призовых мест до*", "Призових місць до*"],
  "contest_card_prize_req": ["Amount*", "Сумма*", "Сума*"],
  "contest_card_info_block": ["The amount is indicated for one prize place", "Сумма указывается за  одно призовове место", "Сума вказується за одне призове місце"],
  "contest_popup_prize_places": ["group of prizes", "группа призовых мест", "група призових місць"],

  //Errors messages
  "contests_error_name_length": ["The name must contain a maximum of 150 characters", "Название должно содержать максимум 150 символов", "Назва повинно містити максимум 150 символів"],
  "contests_error_desc_length": ["The description must contain a maximum of 300 characters", "Описание должно содержать максимум 300 символов", "Опис повинно містити максимум 300 символів"],
  "contests_error_sendpulse_book_length": ["The field SendPulse book must contain of 8 characters", "Поле  SendPulse book должно содержать 8 символов", "Поле  SendPulse book повинно містити 8 символів"],
  "contests_error_sendpulse_decline_book_length": ["The field SendPulse decline book must contain of 8 characters", "Поле  SendPulse decline book должно содержать 8 символов", "Поле  SendPulse decline book повинно містити 8 символів"],


  "contests_error_condition_closed_orders": ['Fill in the field "Condition for closed sell / buy orders"', 'Заполните поле "Условие по закрытым ордерам sell/buy"', 'Заповніть поле "Умова за закритими ордерами sell / buy"'],
  "contests_error_condition_file_name": ["Download file", "Загрузите файл", "Завантажте файл"],
  "contests_error_condition_max_loss": ['Fill in the field "Maximum Drawdown Condition"', 'Заполните поле "Условие по максимальной просадке', 'Заповніть поле "Умова по максимальній просадці"'],
  "contests_error_date_end": ["Choose a date end contest", "Выберите дату окончания конкурса", "Виберіть дату закінчення конкурса"],
  "contests_error_date_start": ["Choose a date start contest", "Выберите дату начала конкурса", "Виберіть дату початку конкурса"],
  "contests_error_description": ['Fill in the field "Description contest"', 'Заполните поле "Описание конкурса"', 'Заповніть поле "Опис конкурса"'],
  "contests_error_finish_reg_date": ["Choose a date finish registration", "Выберите дату окончания регистрации", "Виберіть дату закінчення реєстрації"],
  "contests_error_start_reg_date": ["Choose a date start registration", "Выберите дату начала регистрации", "Виберіть дату початку реєстрації"],
  "contests_error_name": ['Fill in the field "Name contest"', 'Заполните поле "Название конкурса"', 'Заповніть поле "Назва конкурса"'],
  "contests_error_prize_count": ['Fill in the field "Prizes"', 'Заполните поле "Призовых мест"', 'Заповніть поле "Призових місць"'],
  "contests_error_prize_fund": ['Fill in the field "Amount of the prize pool"', 'Заполните поле "Сумма призового фонда"', 'Заповніть поле "Сума призового фонда"'],
  "contests_error_selectedCategories": ["Choose a Category of participants", "Выберите Категория участников", "Виберіть Категорія учасників"],
  "contests_error_selectedNationalities": ["Choose a Citizenship", "Выберите Гражданство", "Виберіть Громадянство"],
  "contests_error_selectedLanguages": ["Choose a Language", "Выберите Язык", "Виберіть Мову"],
  "contests_error_sendpulse_book": ['Fill in the field "SendPulse book"', 'Заполните поле "SendPulse book"', 'Заповніть поле "SendPulse book"'],
  "contests_error_sendpulse_decline_book": ['Fill in the field "SendPulse decline book"', 'Заполните поле "SendPulse decline book"', 'Заповніть поле "SendPulse decline book"'],

  // CONFIRM DELETE CONTEST TRANSLATION
  "contests_translation_delete": ["Are you sure?", "Вы уверены?", "Ви впевнені?"],
  "contests_translation_accept": ["Yes", "Да", "Так"],
  "contests_translation_reject": ["No", "Нет", "Ні"],

  // CONFIRM DISQUALIFY MEMBER
  "contests_disqualify_title": ["Disqualify member?", "Дисквалифицировать участника?", "Дискваліфікувати учасника?"],

  //  PagePanel
  "contests_pages_items": ["Items", "Записи", "Записи"],
  "contests_pages_filtered": ["Items after filtration", "Записей после фильтрации", "Записів після фільтрації"],
  "contests_pages_total": ["Items total", "Записей в целом", "Записів в цілому"],

};
