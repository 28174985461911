import React, { Component, } from 'react';
import { withLocalize, } from 'react-localize-redux';
import PeriodFilter from '../../../../dashboard/components/PeriodFilter/PeriodFilter';
import moment from 'moment';
import { securedRequest, } from '../../../../../services/RequestService';
import { GET_STATEMENT, } from '../../../../../apiRoutes';
import { withRouter, } from 'react-router';

class LeadStatementPopUp extends Component {
	constructor (props) {
		super(props);

		this.state = {
			data: [],
			dateFrom: moment().add(-1, 'days').startOf('month').format('YYYY-MM-DD'),
			dateTo: moment().add(-1, 'days').format('YYYY-MM-DD'),
		};
	}
	save = async state => new Promise(next => this.setState(state, next));
	handlePeriodChange = async ({ dateFrom, dateTo, }) => {

		if (this.state.dateFrom !== dateFrom || this.state.dateTo !== dateTo) {
			await this.save({
				...dateFrom && { dateFrom, },
				...dateTo && { dateTo, },
			});
		}
	}

	handleSendStatement = async () => {
		const { dateFrom, dateTo, }= this.state;
		const systemId = window.history.state; // сетим айдишник счета не через байнд а просто через историю браузера
		const options = {
			from: dateFrom,
			to: dateTo,
		};

		securedRequest.post(`${ GET_STATEMENT }/system-accounts/${ systemId }/generateAccountStatementReport`, options, { responseType: 'blob', })
			.then((response) => {
				// Create a Blob from the response data
				const blob = new Blob([ response.data, ]);

				// Create a link element
				const link = document.createElement('a');

				// Set the href attribute to a Blob URL
				link.href = window.URL.createObjectURL(blob);

				// Extract the filename from the content-disposition header
				const contentDisposition = response.headers['content-disposition'];
				const filename = contentDisposition.split('filename=')[1].slice(0, -1) || 'downloaded_file';

				// Set the download attribute with the filename
				link.download = filename;

				// Append the link to the document
				document.body.appendChild(link);

				// Trigger a click on the link to start the download
				link.click();

				// Remove the link from the document
				document.body.removeChild(link);
			})
			.catch((error) => {
				// Handle errors here
				console.error('Error fetching data:', error);
			});
	}

	renderSelectBlock = () => {
		const { dateFrom, dateTo, } = this.state;
		const { translate, } = this.props;

		return (
			<>
				<PeriodFilter
					avaliableFilterTypes={ [ 'allTime', 'halfYear', 'exactDate', 'year', ] }
					dateFrom={ dateFrom }
					dateTo={ dateTo }
					onChange={ this.handlePeriodChange }
				/>
				<button className="gl-btn gl-btn--blue verification-btn lead_statement_btn" onClick={ () => this.handleSendStatement() }>{translate('list_accounts_generate_statement_btn')}</button>

			</>
		);
	}

	render () {
		const { translate, setClosedPopUp, } = this.props;

		return (
			<div className="lead_statement_wrapper">
				<div className="lead_statement_wrapper__popup" >
					<div className="lead_statement_wrapper__block">
						<h1 className="lead_statement_wrapper__title">{translate('list_accounts_table_system_statement_title')}</h1>
						<div className="edit-channel_popup__close " onClick={ () => setClosedPopUp() } />
					</div>
					{this.renderSelectBlock()}
				</div>
			</div>
		);
	}
}

export default withLocalize(withRouter(LeadStatementPopUp));