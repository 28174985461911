import {
	itemsBuild,
	itemsDrain,
	itemsResort,
	CFG_ITEM_TYPE,
} from '../Config';
import { onEnabled, render, } from "../Mentor/withDropDown";

const f_initiator = {
	'drop-down': '/api/partners/condition-payout-state-reason/get-all-initiators',
	// 'o=': [ '=', '!=', ],
};
const p_uId = {
	onEnabled: onEnabled('users'),
	render: render(
		'users',
		_ => _ ? `${ _?.name } ${ _?.surname }` : '-',
	),
};

const f_status = {
	'drop-down': false,
	'o=': [ '=', '!=', ],
};

export default {
	id: 183,
	...[

        // filters
		...[ {
			f: 'partners.condition_payout_state_reason.id',
			id: 'f:id',
		}, {
			f: 'partners.condition_payout_state.condition_type',
			id: 'f:type',
		},
		{
			f: 'partners.condition_payout_state.condition_level',
			id: 'f:level',
		},
		{
			f: 'partners.condition_payout_state_reason.reason',
			id: 'f:reason',
		},
		{
			f: 'partners.day_processing.day',
			id: 'f:day',
		},
		{
			f: 'partners.condition_payout_state_reason.initiator_id',
			id: 'f:initiator',
			...f_initiator,
		}, {
			f: 'partners.condition_payout_state.error',
			id: 'f:error',
		}, {
			f: 'partners.condition_payout_state.status',
			id: 'f:status',
			...f_status,
		}, {
			f: 'partners.condition_payout_state.created_at',
			id: 'f:created',
		}, {
			f: 'partners.condition_payout_state.updated_at',
			id: 'f:updated',
		}, ]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

        // orders
		...[ {
			f: 'partners.condition_payout_state_reason.id',
			id: 'o:id',
		}, {
			f: 'partners.condition_payout_state.condition_type',
			id: 'o:type',
		},
		{
			f: 'partners.condition_payout_state.condition_level',
			id: 'o:level',
		},
		{
			f: 'partners.condition_payout_state_reason.reason',
			id: 'o:reason',
		},
		{
			f: 'partners.day_processing.day',
			id: 'o:day',
		},
		{
			f: 'partners.condition_payout_state_reason.initiator_id',
			id: 'o:initiator',
		}, {
			f: 'partners.condition_payout_state.error',
			id: 'o:error',
		}, {
			f: 'partners.condition_payout_state.status',
			id: 'o:status',
		}, {
			f: 'partners.condition_payout_state.created_at',
			id: 'o:created',
		}, {
			f: 'partners.condition_payout_state.updated_at',
			id: 'o:updated',
		}, ]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

        // panels
		...[ {
			ii: 'f:id',
			t: 'partner_dashboard_table_id',
		}, {
			ii: 'f:type',
			t: 'partner_dashboard_table_condition_type',
		}, {
			ii: 'f:level',
			t: 'partner_dashboard_table_condition_level',
		},
		{
			ii: 'f:reason',
			t: 'partner_dashboard_table_reason',
		}, {
			ii: 'f:day',
			t: 'partner_dashboard_table_day',
		},
		{
			ii: 'f:initiator',
			t: 'partner_dashboard_table_initiator',
			...p_uId,
		}, {
			ii: 'f:error',
			t: 'partner_dashboard_table_comment',
		}, {
			ii: 'f:status',
			t: 'partner_dashboard_table_status',
		}, {
			ii: 'f:created',
			t: 'partners_payout_created_at',
		}, {
			ii: 'f:updated',
			t: 'partners_payout_updated_at',
		}, ]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

	]
		.map(itemsBuild)
		.reduce(itemsDrain, [])
		.reduce(itemsResort, {}),

	path: 'partners/condition-payout-state',
	widget: false,
};