export const BANNERS_EXPORT_FIELDS = {
	banners: [
		"partners-promo-id",
		"partners-promo-name",
		"partners-promo-size",
		"core-language-name",
		"partners-target_pages-name",
		"partners-promo-status",
		"partners-promo-created_at",
	],
};