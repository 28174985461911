import React from "react";
import Select from 'react-select';
import { withLocalize, } from 'react-localize-redux';
import LangService, { LANG_PROP_CODE, } from '../../../../services/LangService';
import DataStorage from "../../../../helpers/DataStorage";
import { withRouter, } from 'react-router-dom';
import { MagicConfirm, } from "../../../tima/components/Magic/MagicConfirm";
import { putProfileLanguage, } from "../../../clients/services/AccountService";

const customStyles = {
	control: () => ({
	}),
	singleValue: (base, state) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = 'opacity 300ms';

		return { opacity, transition, };
	},
};

const languages = {
	en: <div><span className="flag-icon flag-icon-squared flag-icon-gb" /> English</div>,
	ru: <div><span className="flag-icon flag-icon-squared flag-icon-ru" /> Русский</div>,
	uk: <div><span className="flag-icon flag-icon-squared flag-icon-ua" /> Українська</div>,
};

class LangSelect extends React.Component {

	constructor (props) {
		super(props);

		const lang = DataStorage.getData('locale');
		const value = lang ?? 'en';
		const label = lang ? languages[lang] : "Select lang";

		this.state = {
			defaultLang: { label, value, },
			currentValue: '',
			selectLang: '',
			newRender: false,
			setLang: false,
		};
	}

    save = async state => new Promise(next => this.setState(state, next));

    async componentDidMount () {
    	if (!this.props?.checkProfilePage && this.props?.checkProfilePage !== undefined) {
    		this.setLanguage();
    	} else {
    		const lang = DataStorage.getData('locale') ?? 'en';

    		await this.save({
    			defaultLang: {
    				label: languages[lang],
    				value: lang,
    			},
    			selectLang: {
    				label: languages[lang],
    				value: lang,
    			},
    		});
    		LangService.setActiveLanguage(lang);
    	}
    }
    setLangInDB = async (lang) => {
		const token = DataStorage.getData('token');
		if (!this.state.setLang || token === null) {
			return;
		}
    	switch (lang) {
    		case 'ru':
    			await putProfileLanguage({ preference_lang_id: 133, });
    			break;
    		case 'uk':
    			await putProfileLanguage({ preference_lang_id: 169, });
    			break;
    		case 'en':
    			await putProfileLanguage({ preference_lang_id: 40, });
    			break;
    		default:
    			return null;
    	}
    }

    setLang = async (param) => {
		// this.props?.changeLang();
    	await this.save({
    		selectLang: {
    			label: languages[param.value],
    			value: param.value,
    		},
			setLang: true,
    	});
    	if (this.props?.profileId === undefined) {
    		await LangService.setActiveLanguage(param.value);
    	} else if (this.props?.checkProfilePage) {
    		await this.userSetLang();
    	} else if (!this.props?.checkProfilePage) {
    		this.props?.savePreferenceLang(param.value);
    	}
    };

    userSetLang = async () => {
    	if (!this.props?.changeState) {
    		await LangService.setActiveLanguage(this.state?.selectLang.value);

    		return;
    	}
    	await this.save({ showModal: true, });
    	await this.renderConfirm();
    	if (this.state.accept === undefined) {
    		return null;
    	} else if (this.state.accept) {
    		await LangService.setActiveLanguage(this.state?.selectLang.value);
    		await this.save({ showModal: false, accept: undefined, });
    		this.props.saveState({ newChanges: false, });
    	} else {
    		const oldLang = {};

    		this.props?.languages?.filter(item => item.active === true).forEach((item) => {
    			oldLang.label = languages[item.code];
    			oldLang.value = item.code;
    		});
    		await this.save({ showModal: false, accept: undefined, selectLang: oldLang, });
    	}
    };

    setLanguage = async () => {
    	await this.save({
    		defaultLang: {
    			label: languages[this.props?.sendCurrentLangToSelect],
    			value: this.props?.sendCurrentLangToSelect,
    		},
    		selectLang: {
    			label: languages[this.props?.sendCurrentLangToSelect],
    			value: this.props?.sendCurrentLangToSelect,
    		},
    	});
    };

    renderConfirm = () => {
    	const optionsMagicConfirm = {
    		title: this.props.translate('managerPage_confirm'),
    		accept: this.props.translate('managerPage_confirm_accept'),
    		reject: this.props.translate('managerPage_confirm_reject'),
    		onReject: async () => {
    			await this.save({ showModal: false, accept: false, });
    			this.userSetLang();
    		},
    		onAccept: async () => {
    			await this.save({ showModal: false, accept: true, });
    			this.userSetLang();
    		},
    		isVisible: this.state.showModal,
    	};

    	return (
	<MagicConfirm { ...optionsMagicConfirm } />
    	);
    };

    render () {
    	const lang = this.props.languages.map((obj) => {
    		return { label: obj.name, value: obj.code, };
    	});

    	return (
	<React.Fragment>
	{
    				this.state.showModal && this.props?.changeState ? this.renderConfirm() : null
    			}
	<Select
	className={ this.props?.className }
	classNamePrefix={ this.props?.classNamePrefix ? this.props?.classNamePrefix : 'login-select' }
	defaultValue={ this.state?.defaultLang }
	value={ this.state?.selectLang }
	onChange={ this.setLang }
	options={ lang }
	styles={ customStyles }
    			/>
    		</React.Fragment>
    	);
    }
}

export default withLocalize(withRouter(LangSelect));