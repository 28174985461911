import {
    GET_ACCOUNT_TOTAL_BALANCE,
    GET_ACCOUNT_TOTAL_EQUITY,
    POST_ACCOUNT_CANCEL_EMAIL,
    POST_ACCOUNT_CANCEL_PHONE,
    POST_ACCOUNT_CHANGE_EMAIL,
    POST_ACCOUNT_CHANGE_PHONE,
    POST_ACCOUNT_CHANGE_HISTORY,
    POSTFIX_CHANGE_TRD_CONFIRM,
    POSTFIX_CHANGE_TRD_REJECT,
    POST_ACCOUNT_INDIVIDUAL_VERIFICATION_CONFIRM,
    POST_ACCOUNT_INDIVIDUAL_VERIFICATION_CANCEL,
    POST_ACCOUNT_PERSONAL_DATA_CANCEL,
    POST_ACCOUNT_PERSONAL_DATA_CONFIRM,
} from '../apiRoutes';

import {
    GET_ACCOUNT,
    SET_ACCOUNT,
    GET_APPEALS,
    GET_INTERACTION,
    GET_ACCOUNT_ADDRESSES,
    GET_ACCOUNT_ADDRESS_TYPES_ENUM,
    PUT_ACCOUNT_ADDRESS,
    GET_ACCOUNT_TAX_RESIDENCIES,
    GET_ACCOUNT_TAX_RESIDENCY_DATA_CHANGES,
    GET_ACCOUNT_CHANGE_HISTORY_LAST_REQUEST,
    GET_ACCOUNT_DOCUMENTS,
    GET_ACCOUNT_DOCUMENT_TYPE_RATIO_TO_SUB_TYPE,
    GET_ACCOUNT_DOCUMENT_PARSED_DATA,
    GET_ACCOUNT_TAILS,
    GET_FILTER_OPTIONS,
    GET_NEW_CATEGORY,
    GET_DICTIONARY_ACCOUNT_TYPE,
    SET_DICTIONARY_ACCOUNT_TYPE,
    GET_DICTIONARY_ACCOUNT_BROKERS,
    GET_DICTIONARY_ACCOUNT_LABELS,
    GET_DICTIONARY_ACCOUNT_LIFESTYLES,
    GET_DICTIONARY_ACCOUNT_MESSENGERS,
    GET_DICTIONARY_ACCOUNT_NATIONALITIES,
    GET_DICTIONARY_ACCOUNT_COUNTRIES,
    GET_DICTIONARY_ACCOUNT_CITIES,
    GET_DICTIONARY_ACCOUNT_DOCUMENT_COMMENTS,
    GET_DICTIONARY_ACCOUNT_RELIGIONS,
    GET_DICTIONARY_ACCOUNT_SOCIAL_NETWORKS,
    GET_DICTIONARY_ACCOUNT_STATUS,
    GET_DICTIONARY_ACCOUNT_TYPES,
    GET_DICTIONARY_ACCOUNT_TIME_ZONES,
    GET_DICTIONARY_ACCOUNT_TIN_ABSENCE_REASONS,
    POST_ACCOUNT_VERIFY,
    POST_ACCOUNT_SUMSUB_RESET_VERIFY,
    GET_ACCOUNT_INDIVIDUAL_VERIFIER_STATUS, GET_USER,
    SET_ACCOUNT_STATUS_ACTIVE,
    SET_ACCOUNT_STATUS_ARCHIVE,
    ACCOUNT_TAG, SET_PROFILE_LANGUAGE,
} from '../../../apiRoutes';

import {
    getTails,
    setClient,
    delClient,
    updateAppeals,
    filterOptions,
    getInteraction,
    resetError,
    getNewCategory,
    setFilterCategory,
} from "../actions";

import { securedRequest } from 'app/services/RequestService/index';
import { get, post, put, del, MagicError } from "../../../components/Magic";
import { cachingService } from '../../../services/CachingService';
import { getAllEnums, } from '../../../services/EnumDataService';

export const getAccountTotalBalance = async (accountId) => {
    const url = `${GET_ACCOUNT_TOTAL_BALANCE}/${accountId}`;
    return cachingService.one(url);
};

export const getAccountTotalEquity = async (accountId) => {
    const url = `${GET_ACCOUNT_TOTAL_EQUITY}/${accountId}`;
    return cachingService.one(url);
};

export const getDictionaryAccountType = async () => getAccountTypes();

export const setDictionaryAccountType = (data) => {
    return securedRequest.post(`${SET_DICTIONARY_ACCOUNT_TYPE}`, data)
        .then(
            (response) => Promise.resolve(response.data),
            (error) => Promise.reject(error.response.data)
        );
};

export const setAccountStatusActive = async (id) => {
    const response = post(`${ SET_ACCOUNT_STATUS_ACTIVE }/${id}`, {});
    await cachingService.clean({ match: ({ isOne, key }) => isOne && key.indexOf(`GET:${ SET_ACCOUNT }/${ id }`)>0, });
    return response;
};

export const setAccountStatusArchive = async (id) => {
    const response = post(`${ SET_ACCOUNT_STATUS_ARCHIVE }/${id}`, {});
    await cachingService.clean({ match: ({ isOne, key }) => isOne && key.indexOf(`GET:${ SET_ACCOUNT }/${ id }`)>0, });
    return response;
};

export const setFilterCategoryAccount = (data) => (dispatch) => {
    dispatch(setFilterCategory(data));
};

export const getNewCategoryAccount = (data) => (dispatch) => {
    return securedRequest.post(`${GET_NEW_CATEGORY}`, data)
        .then((response) => {
            dispatch(getNewCategory(response.data));
        })
        .catch((error) => {
        });
};

export const createAccount = (data) => {
    return securedRequest.post(SET_ACCOUNT, data)
        .then(
            (response) => Promise.resolve(response.data),
            (error) => Promise.reject(error.response.data)
        );
};

export const updateAccount = async (data, accountId) => {
    try {
        const response = await securedRequest.put(`${SET_ACCOUNT}/${accountId}`, data);
        return response.data;
    } catch (error) {
        throw new MagicError(error);
    }
};

export const putAccount = async (id, obj) =>
    await put(`${ SET_ACCOUNT }/${id}`, obj);

export const putAccountDocument = async (id, obj) =>
    await put(`${ GET_ACCOUNT_DOCUMENTS }/${id}`, obj);

export const putAccountAddress = async (addressId, obj) =>
    await put(`${ PUT_ACCOUNT_ADDRESS }/${addressId}`, obj);

export const postAccountAddress = async (params, obj) =>
    await post(`${ PUT_ACCOUNT_ADDRESS }`, { ...params, ...obj });

export const deleteAccountAddress = async (addressId) =>
    await del(`${ PUT_ACCOUNT_ADDRESS }/${addressId}`, {});

export const postAccountChangeEmailRequest = async (id, obj = {}) =>
    await post(`${ POST_ACCOUNT_CHANGE_EMAIL }/${id}`, obj);

export const postAccountCancelEmailRequest = async (id, obj = {}) =>
    await post(`${ POST_ACCOUNT_CANCEL_EMAIL }/${id}`, obj);

export const postAccountChangePhoneRequest = async (id, obj = {}) =>
    await post(`${ POST_ACCOUNT_CHANGE_PHONE }/${id}`, obj);

export const postAccountCancelPhoneRequest = async (id, obj = {}) =>
    await post(`${ POST_ACCOUNT_CANCEL_PHONE }/${id}`, obj);

export const postAccountPersonalDataCancelRequest = async (id, obj = {}) =>
    await post(`${ POST_ACCOUNT_PERSONAL_DATA_CANCEL }/${id}`, obj);

export const postAccountPersonalDataConfirmRequest = async (id, obj = {}) =>
    await post(`${ POST_ACCOUNT_PERSONAL_DATA_CONFIRM }/${id}`, obj);

export const postAccountIndividualVerificationConfirmRequest = async (id, obj = {}) =>
    await post(`${ POST_ACCOUNT_INDIVIDUAL_VERIFICATION_CONFIRM }/${id}`, obj);

export const postAccountIndividualVerificationCancelRequest = async (id, obj = {}) =>
    await post(`${ POST_ACCOUNT_INDIVIDUAL_VERIFICATION_CANCEL }/${id}`, obj);

export const postAccountChangeHistoryRejectRequestTRD = async (accountChangeHistoryId, obj = {}) =>
    await post(`${ POST_ACCOUNT_CHANGE_HISTORY }/${accountChangeHistoryId}/${POSTFIX_CHANGE_TRD_REJECT}`, obj);

export const postAccountChangeHistoryConfirmRequestTRD = async (accountChangeHistoryId, obj = {}) =>
    await post(`${ POST_ACCOUNT_CHANGE_HISTORY }/${accountChangeHistoryId}/${POSTFIX_CHANGE_TRD_CONFIRM}`, obj);

export const clearClient = () => (dispatch) => {
    dispatch(delClient());
};

export const getAccountBrokers = async () => {
    // Интересы
    const url = GET_DICTIONARY_ACCOUNT_BROKERS;
    return cachingService.dictionary(url);
};

export const getAccountLabels = async () => {
    // Метка клиента
    const url = GET_DICTIONARY_ACCOUNT_LABELS;
    return cachingService.dictionary(url);
};

export const getAccountLifestyles = async () => {
    const url = GET_DICTIONARY_ACCOUNT_LIFESTYLES;
    return cachingService.dictionary(url);
};

export const getAccountMessengers = async () => {
    const url = GET_DICTIONARY_ACCOUNT_MESSENGERS;
    return cachingService.dictionary(url);
};

export const getAccountNationalities = async () => {
    const url = GET_DICTIONARY_ACCOUNT_NATIONALITIES;
    return cachingService.dictionary(url);
};

export const getAccountCountries = async () => {
    const url = `${GET_DICTIONARY_ACCOUNT_COUNTRIES}?add_fields[]=is_work&add_fields[]=alpha_2&sort=name,asc`;
    return cachingService.dictionary(url);
};

export const getAccountDocumentComments = async () => {
    const url = GET_DICTIONARY_ACCOUNT_DOCUMENT_COMMENTS;
    return cachingService.dictionary(url);
};

//TODO: add the request for corporate account document options
export const getAccountDocumentTypeRatioToSubType = async () => {
    const url = GET_ACCOUNT_DOCUMENT_TYPE_RATIO_TO_SUB_TYPE;
    return cachingService.dictionary(url);
};

export const getAccountTimeZones = async () => {
    const url = GET_DICTIONARY_ACCOUNT_TIME_ZONES;
    return cachingService.dictionary(url);
};

export const getAccountReligions = async () => {
    const url = GET_DICTIONARY_ACCOUNT_RELIGIONS;
    return cachingService.dictionary(url);
};

export const getAccountSocialNetworks = async () => {
    const url = GET_DICTIONARY_ACCOUNT_SOCIAL_NETWORKS;
    return cachingService.dictionary(url);
};

export const getAccountStatus = async () => {
    // Результат последнего контакта
    const url = GET_DICTIONARY_ACCOUNT_STATUS;
    return cachingService.dictionary(url);
};

export const getAccountTypes = async () => {
    // Интересы
    const url = GET_DICTIONARY_ACCOUNT_TYPES;
    return cachingService.dictionary(url);
};

export const getAccountCitiesByCountryId = async (id) =>
    await get(`${GET_DICTIONARY_ACCOUNT_CITIES}?country_id=${id}`);

export const getAccountAddresses = async (id) =>
    await get(`${GET_ACCOUNT_ADDRESSES}/${id}`);

export const getAccountAddressTypesEnum = async (id) =>
    await get(`${GET_ACCOUNT_ADDRESS_TYPES_ENUM}/${id}`);

export const getAccountTaxResidencies = async (id) =>
    await get(`${GET_ACCOUNT_TAX_RESIDENCIES}/${id}`);

export const postAccountTaxResidencyDataChange = async (obj) =>
    await post(`${GET_ACCOUNT_TAX_RESIDENCY_DATA_CHANGES}`, obj);

export const putAccountTaxResidencyDataChange = async (id, obj) =>
    await put(`${GET_ACCOUNT_TAX_RESIDENCY_DATA_CHANGES}/${id}`, obj);
export const putProfileLanguage = async ( obj) =>
    await put(`${SET_PROFILE_LANGUAGE}/`, obj);

export const deleteAccountTaxResidencyDataChange = async (id) =>
    await del(`${GET_ACCOUNT_TAX_RESIDENCY_DATA_CHANGES}/${id}`);

export const getAccountChangeHistoryLastRequestByType = async (id, type) =>
    await get(`${GET_ACCOUNT_CHANGE_HISTORY_LAST_REQUEST}/${id}/with-type/${type}`);

export const getAccountTinAbsenceReasons = async () => {
    const url = GET_DICTIONARY_ACCOUNT_TIN_ABSENCE_REASONS;
    return cachingService.dictionary(url);
};

export const getAccountIsROP = async (userId) =>
    await get(`${GET_USER}/${userId}/is-rop`);

export const getAccountDocumentParsedData = async (documentId) =>
    await get(`${GET_ACCOUNT_DOCUMENT_PARSED_DATA}/${documentId}`);

export const getAccountIndividualVerifierStatus = async (accountId) =>
    await get(`${GET_ACCOUNT_INDIVIDUAL_VERIFIER_STATUS}/${accountId}`);

export const postAccountVerify = async (id) =>
    await post(`${POST_ACCOUNT_VERIFY}/${id}`, {});

export const postAccountSumsubResetVerify = async (account_id) =>
    await post(`${POST_ACCOUNT_SUMSUB_RESET_VERIFY}`, { account_id });

export const updateAccountTypes = async (accountId, data) => updateAccount(data, accountId);
// {
//     // TODO: sane as updateAccount, but args reversed
//     try {
//         const response = await securedRequest.put( `${ SET_ACCOUNT }/${ accountId }`, data );
//         return response.data;
//     } catch ( error ) {
//         throw new Error( error.response.data );
//     }
// };

export const updateCustomOption = async (accountId, data) => updateAccount(data, accountId);
// {
//     // TODO: same as updateAccount, but args reversed
//     try {
//         const response = await securedRequest.put( `${ SET_ACCOUNT }/${ accountId }`, data );
//         return response.data;
//     } catch ( error ) {
//         throw new Error( error.response.data );
//     }
// };

export const updateSubstatusOption = async (accountId, data) => {
    // TODO: sane as updateCustomOption
    try {
        const response = await securedRequest.put(`${SET_ACCOUNT}/${accountId}`, data);
        return response.data;
    } catch (error) {
        throw new Error(error.response.data);
    }
};

export const updateIsBrandLawyer = (id, data) => {
    // TODO: sane as updateCustomOption
    return securedRequest.put(`${SET_ACCOUNT}/${id}`, data)
        .then(
            (response) => Promise.resolve(response.data),
            (error) => Promise.reject(error.response.data)
        );
};

export const getClientAsync = async (id) => {
    const url = `${GET_ACCOUNT}/${id}`;
    return cachingService.one(url);
}

export const getClient = (id = 0) => async (dispatch) => {
    try {
        const response = await getClientAsync(id);
        dispatch(setClient(response));
    } catch (error) {
        // dispatch(setError(error));
        dispatch(setClient({ data: false }));
    }
};

export const setResetError = () => (dispatch) => {
    dispatch(resetError());
};

export const getClientInteraction = (id) => (dispatch) => {
    return securedRequest.get(`${GET_INTERACTION}/${id}`)
        .then((response) => {
            dispatch(getInteraction(response.data));
        })
        .catch((error) => {
        });
};

export const deleteClient = (id = 0) => (dispatch) => {
    return securedRequest.delete(`${GET_ACCOUNT}/${id}`)
        .then((response) => {
            dispatch(delClient());
        })
        .catch((error) => {
        });
};

export const getFilterOptions = () => (dispatch) => {
    // TODO: remove function as old
    return securedRequest.get(`${GET_FILTER_OPTIONS}`)
        .then((response) => {
            dispatch(filterOptions(response.data));
        })
        .catch((error) => {
        });
};

export const getAppealsAccount = async (id = 0, pageOptions) => {
    try {
        let url = '';
        if (pageOptions) {
            url = new URL(`${GET_APPEALS}/${id}`, window.location.href);
            Object.entries(pageOptions).forEach(([key, value]) => url.searchParams.set(key, value));
        } else {
            url = `${GET_APPEALS}/${id}`;
        }
        const { data, meta, } = await cachingService.one(url);

        return { data, meta };
    } catch (error) {}
};

export const updateAppealsAccount = (data) => (dispatch) => {
    return dispatch(updateAppeals(data));
};

export const setTails = (id = 0) => async (dispatch) => {
    try {
        const url = `${GET_ACCOUNT_TAILS}/${id}`;
        const response = await cachingService.one(url);
        dispatch(getTails(response));
    } catch (error) {}
};

export const deleteAccountTag = async (tagId) =>
	await del(`${ ACCOUNT_TAG }/${tagId}`, {});

//TODO Redax Delete local enums
export const setEnums = getAllEnums;
