import React, { Component } from 'react';
import { withMediaProps } from 'react-media-player';
import volumeBar from '../../../../../images/volume-line.svg';

class MuteUnmute extends Component {
    state = {
        genVolume: this.props.media.volume
    }

    shouldComponentUpdate ({ media }) {
        return (
            this.props.media.volume !== media.volume ||
            this.props.media.isMuted !== media.isMuted
        );
    }

    _handleMouseUp = ({ target: { value }}) => {
            this.props.media.setVolume(Number(value));
    }

    _handleChange = ({ target: { value }}) => {
        this.props.media.setVolume(Number(value));
    }

    render() {
        const { media: { volume }, className, style } = this.props;
        return (
            <div className="volume-bar">
                <div  style = { {
                    position:        'relative',
                    height: '18px',
                    width: '28px'
                }
                }>
                <div
                    style = { {
                        position:        'absolute',
                        width:           `100%`,
                        height:         '100%',
                    }
                    }
                >
                    <img src={volumeBar} alt="volume"
                    style = { {
                    height:         '100%',
                    width:          'auto',
                    maxWidth:       '28px',
                    opacity:        '.2',
                    ...style,
                } } />
                </div>
                <div
                    style = { {
                        position:        'absolute',
                        overflow:        'hidden',
                        height:         '100%',
                        width:           `${volume * 100 / this.state.genVolume}%`,
                    }
                    }
                >
                    <img src={volumeBar} alt="volume"
                         style = { {
                             height:         '100%',
                             width:          'auto',
                             maxWidth:       '30px',
                             ...style,
                         } } />
                </div>
                <input
                    className = { className }
                    max = { this.state.genVolume }
                    onChange = { this._handleChange }
                    onMouseDown = { this._handleMouseDown }
                    onMouseUp = { this._handleMouseUp }
                    step = { this.state.genVolume / 10 }
                    style = { {
                        opacity:        '0',
                        position:       'absolute',
                        height:         '100%',
                        width:          '100%',
                        ...style,
                    } }
                    type = 'range'
                    value = { volume }
                />
                </div>
            </div>
        )
    }
}

export default withMediaProps(MuteUnmute)