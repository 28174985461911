import {
	itemsBuild, itemsDrain, itemsResort, CFG_ITEM_TYPE,
} from "../Config";

export default {
	id: 3,
	...[
		// filters
		...[
			{
				f: "log.cron.id",
				id: "f:cronId",
			},
			{
				f: "log.cron.project",
				id: "f:cronProject",
			},
			{
				f: "log.cron.name",
				id: "f:cronName",
			},
			{
				f: "log.cron.start_at",
				id: "f:startAt",
			},
			{
				f: "log.cron.end_at",
				id: "f:endAt",
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: "filter", ..._, })),

		// orders
		...[
			{
				f: "log.cron.id",
				id: "o:cronId",
			},
			{
				f: "log.cron.project",
				id: "o:cronProject",
			},
			{
				f: "log.cron.name",
				id: "o:cronName",
			},
			{
				f: "log.cron.description",
				id: "o:cronDescription",
			},
			{
				f: "log.cron.start_at",
				id: "o:startAt",
			},
			{
				f: "log.cron.end_at",
				id: "o:endAt",
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: "order", ..._, })),

		// panels
		...[
			{
				ii: "f:cronId",
				t: "cron_id",
			},
			{
				ii: "f:cronProject",
				t: "cron_project",
			},
			{
				ii: "f:cronName",
				t: "cron_name",
			},
			{
				ii: "f:startAt",
				t: "cron_start_at",
			},
			{
				ii: "f:endAt",
				t: "cron_end_at",
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: "panel", ..._, })),
	]
		.map(itemsBuild)
		.reduce(itemsDrain, [])
		.reduce(itemsResort, {}),

	path: "log/cron",
	widget: false,
};