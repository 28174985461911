import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import { marketingService } from '../../../../services/MarketingDataService';
import Select from 'react-select';
import NotificationService from '../../../../services/NotificationService';
import Preloader from '../../../../components/LoadingHOC/Preloader';
import Loader from '../../../tima/components/Loader';

class ModalUtmBindSubchannelToChannel extends Component {
  constructor (props) {
    super(props);

    this.state = {
      isBindModalShown: false,
      formData: [],
      searchItems: [],
      searchItemsSequence: 0,
      errors: [],
      loaded: true,
    };
  };

  async componentDidMount () {
    await this.searchItemInit();
  };

  searchItemInit = async () => {
    await this.save({ loaded: false });
    const channels = await marketingService.getUtmSources();

    await this.save(({
      formData,
      searchItems,
      searchItemsSequence: index,
    }) => {
      return {
        loaded: true,
        formData: [
          ...formData, { index, },
        ],
        channels: channels.data ?? [],
        searchItems: [
          ...searchItems,
          {
            index,
            subChannel: [],
            isLoadingChannel: false,
            isLoadingSubChannel: false,
          },
        ],
        searchItemsSequence: index + 1,
      };
    });
  };

  save = async state => new Promise(next => this.setState(state, next));

  bindSubChannelToChannel = async () => {
    let formData = this.state.formData.map(item => {
      return {
        isDisabled: item.isDisabled,
        index: item.index,
        utm_source_id: item.channel?.id,
        utm_medium_id: item.subChannel?.id,
      };
    });

    await this.save({ loaded: false })
    try {
      let hasErrors = false;
      let saved = await Promise.all(formData.map(async ({ index, isDisabled, ...formData }) => {
        if (isDisabled) return ;
        try {
          await marketingService.utmBindMediumToSource(formData);
          return index;
        } catch (error) {
          hasErrors = true;
          await this.setErrorsFromServer(error);
          console.log(error)
        }
      }));
      saved = saved.filter(Number.isInteger);
      await this.save(({ formData, }) => {
        return {
          formData: formData.map(item => {
            return saved.includes(item.index) ? { ...item, isDisabled: true, } : item;
          }),
        };
      });
      !hasErrors && this.props.onClose && await this.props.onClose();
      await this.save({ loaded: true })
    } catch (error) {
      console.log(error)
    }
  };

  onSelectInputChange = (index, key) => (text) => {
    this.clearErrorClass('utm_source_id') && this.clearErrorClass('utm_medium_id');

    const isLoading = {
      channel: 'isLoadingChannel',
      subChannel: 'isLoadingSubChannel',
    }[key];

    const loading = async () => {
      await this.save({ [isLoading]: true, });
      try {
        await this.save( { [isLoading]: false, })
      } catch (error) {
        await this.setErrorsFromServer(error);
        console.log(error)
      }
    };

    const load = async () => {
      await loading();
    };
    text && load(text);
  };

  onSelectChange = (index, key) => async (option) => {
    await this.save(({ formData, }) => {
      return {
        formData: formData.map(item => (item.index === index) ? { ...item, [key]: option, } : item),
      };
    });

    const formData = this.state.formData?.find(_ => _.index === index);

    if (formData.channel === null) {
      await this.save(({ formData }) => {
        return {
          formData: formData.map(item => (item.index === index) ? { ...item, subChannel: option, } : item),
        }
      })
    }

    const result = await marketingService.getUtmMediumsWithOutSource({ utm_source_id: formData?.channel?.id });

    await this.save(({ searchItems, }) => {
      return {
        searchItems: searchItems.map(item => (item.index === index) ? { ...item, subChannel: result.data, } : item)
      }
    })
  };

  optionsToolTip = (option) => {
    if (option.name.length >= 20) {
      return (
        <div className="bind-channel-option-content">
          {option.name.slice(0, 15)}...
          <div className="bind-channel-option-more-content">
            {option.name}
          </div>
        </div>
      )
    } else {
      return option.name
    }
  };

   renderSelect = (item, key, items, index) => {
     const formData = this.state.formData?.find(_ => _.index === item.index);
     const isSubChannel = key === 'subChannel';
     const isDisabled = (isSubChannel && !formData?.channel) || formData?.isDisabled;
     const isLoading = {
       channel: item.isLoadingChannel,
       subChannel: item.isLoadingSubChannel,
     }[key];

     const selectedSubChannels = this.state.formData
       .filter(item => item.index !== index && item?.channel?.id === formData?.channel?.id)
       .map(item => item.subChannel?.id)
       .filter(_ => _);

     return (
      <div>
        <Select
          className={
            this.state?.errors?.includes({ channel: 'utm_source_id', subChannel: 'utm_medium_id', }[key]) && !isDisabled
            ? 'error-value' :
            ''}
          maxMenuHeight={ 150 }
          isClearable={ true }
          isDisabled={ isDisabled }
          isLoading={ isLoading }
          isSearchable={ true }
          value={ formData?.[key] }
          getOptionLabel={ (option) => this.optionsToolTip(option) }
          getOptionValue={ (option) => option.id }
          options={ (items ?? item[key]).filter(item => !selectedSubChannels.includes(item?.id)) }
          onChange={ this.onSelectChange(item.index, key) }
          onInputChange={ this.onSelectInputChange(item.index, key, items) }
          placeholder={ { channel: this.props.translate(`marketing_generate_links_search-channel`), subChannel: this.props.translate(`marketing_generate_links_search-subchannel`), }[key] }
        />
      </div>
    )
  };

   removeField = (index) => async () => {
     await this.save(({ searchItems, formData, }) => {
       return {
         searchItems: searchItems.filter(item => item.index !== index),
         formData: formData.filter(item => item.index !== index),
       };
     });
   };

  renderSearch = (item, key) => {
    const formData = this.state.formData?.find(_ => _.index === item.index);
    const isDisabled = formData?.isDisabled;

    return (
      <div key={ item.index } className="search-items">
        <div className="edit-channel_popup__field_items">
          <div className="edit-channel_popup__field_item">
            <p className="edit-channel_popup__field_items-text">
              { this.props.translate(`marketing_bind_channel`) }*
            </p>
            { this.renderSelect(item, 'channel', this.state.channels) }
          </div>
        </div>
        <div className="edit-channel_popup__field_items">
          <div className="edit-channel_popup__field_item">
            <p className="edit-channel_popup__field_items-text">
              { this.props.translate(`marketing_bind_subchannel`) }*
            </p>
            { this.renderSelect(item, 'subChannel') }
          </div>
        </div>
        <div className="generate-links__remove-btn-block">
          {this.state.searchItems.length > 1 && !isDisabled ? (
            <button
              className="generate-links__remove-btn"
              onClick={this.removeField(item.index)}
            >
              X
            </button>
          ) : null}
        </div>
      </div>
    )
  };

  setErrorsFromServer = async (error) => {
    try {
      NotificationService.errors({ ...error?.response?.data, time: 3000 });
      let errors = Object.keys(error?.response?.data?.errors).map(e => e?.split('.')?.[0]) ?? [];
      await this.save({ errors });
    } catch (e) {}
  };

  clearErrorClass = async (name) => {
    await this.save?.(state => ({ errors: [...state?.errors?.filter(e => e !== name)], }));
  };

  render () {
    const { translate } = this.props;
    const disabledSaveBtn = this.state.formData.some(_ => !_.channel || !_.subChannel);

    return (
      <>
        <div className="edit-channel">
          <div className="edit-channel_popup custom-inputs scroll">
            <Loader
              loaded={this.state.loaded}
              loading={(<Preloader scale={this.props.scale}/>)}
              translate={this.props.translate}
            >
            <div className="edit-channel_popup-container">
              <div className="edit-channel_popup__header">
                <div className="edit-channel_popup__title">
                  { translate(`marketing_bind_subchannel_to_channel`) }
                </div>
              </div>
              <div className="edit-channel_popup__content">
                <div className="edit-channel_popup__field">
                  { this.state.searchItems.map(this.renderSearch) }
                  <div className="bind-modal_btn-add">
                    <button
                      disabled={disabledSaveBtn}
                      className={disabledSaveBtn ? 
                      "edit-channel_popup__buttons-save-cancel edit-channel_popup__buttons-save-cancel-add-disabled" : 
                      "edit-channel_popup__buttons-save-cancel edit-channel_popup__buttons-save-cancel-add"}
                      onClick={ () => this.searchItemInit() }
                    >
                      <p className="bind-modal__btn-text">{ translate(`marketing_bind_more`) }</p>
                    </button>
                  </div>
                  <div className="edit-channel_popup__buttons">
                    <button
                      disabled={disabledSaveBtn}
                      className={disabledSaveBtn ? 
                      "edit-channel_popup__buttons-save-cancel edit-channel_popup__buttons-save-cancel-save-disabled" : 
                      "edit-channel_popup__buttons-save-cancel edit-channel_popup__buttons-save-cancel-save"}
                      onClick={this.bindSubChannelToChannel}
                    >
                      <p className="bind-modal__btn-text">{ translate(`marketing_save_modal_data`) }</p>
                    </button>
                    <button
                      className="edit-channel_popup__buttons-save-cancel edit-channel_popup__buttons-save-cancel-cancel bind-channel-cancel-btn"
                      onClick={() => this.props.onClose()}
                    >
                      <p className="bind-modal__btn-text">{ translate(`marketing_cancel_modal_data`) }</p>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            </Loader>
          </div>
        </div>
      </>
    )
  };
}

export default withRouter(withLocalize(ModalUtmBindSubchannelToChannel));
