import React, { Component } from "react";
import deleteButton from "../../../../../images/remove.svg";
import { withLocalize } from "react-localize-redux";

class AttachmentDocument extends Component {

    attachment = () => {
        const mimes = {
            jpg:  'flaticon-jpg-file-format-variant',
            JPG:  'flaticon-jpg-file-format-variant',
            jpeg: 'flaticon-jpg-file-format-variant',
            png:  'flaticon-png-file-format-symbol',
            gif:  'flaticon-gif-file-format-symbol',
            pdf:  'flaticon-pdf-file-format-symbol',
            doc:  'flaticon-doc-file-format',
            docx: 'flaticon-docx-file-format-symbol',
            xls:  'flaticon-xls-file-format-symbol',
            xlsx: 'flaticon-xlsx-file-format-extension',
            txt:  'flaticon-txt-file-symbol',
            zip:  'flaticon-zip-file-format',
            '7z': 'flaticon-7z-file-format-variant',
            rar:  'flaticon-rar-file-format',
        };
        const attachment_icon = this.props.attachment.extension ?? this.props?.attachment?.file?.name?.split('.')[1];

        return (<i key={attachment_icon} className = { `attachment-img_icon fi ${mimes?.[attachment_icon] ?? 'flaticon-txt-file-symbol'}` } />);
    };

    downloadFile = async (translate) => {
        this.props?.attachment?.alias_name
            ? await this.props.downlDocMailTempl(this.props.attachment.alias_name, this.props.attachment.file_name, translate)
            : null;
    };

    move = () => {
        this.props?.allDocuments?.length % 2 !== 0 ? this.props.moveForward() : null;
    };

    render () {
        return (
            <div className = 'carousel-item-block'>
                <div className = 'carousel-item-text'>
                    <div className = 'attachment-img' onClick = { () => this.downloadFile(this.props.translate) }>
                        { this.attachment() }
                    </div>
                    <h5>{this.props?.attachment?.file_name ?? this.props?.attachment?.file?.name }</h5>
                    {this.props.attachment.created_at
                        ? <p>{this.props.translate(`template_management_add_template_added`)} {this.props.attachment.created_at}</p>
                        : null
                    }
                    <img
                        onClick = { () => {
                            this.props.deleteDocument(this.props.index);
                            this.move();
                        } } className = 'delete-attachment' src = { deleteButton } alt = 'delete'
                    />
                </div>
            </div>
        );
    }
}
export default withLocalize(AttachmentDocument);
