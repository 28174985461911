export const marketing = {
  "menu_marketing": ["Marketing", "Маркетинг", "Маркетинг"],
  "statistics_utm_source_edit": ["Edit", "Редактировать", "Редагувати"],
  // h1
  "marketing_strategies_h1": ["Advertising channels", "Рекламные каналы", "Рекламні канали"],
  // VerticalMenu
  "menu_marketing_advertising_channels": ["Advertising channels", "Рекламные каналы", "Рекламні канали"],
  // Tabs
  "marketing_link_channels": ["Channels", "Каналы", "Канали"],
  "marketing_link_subchannels": ["Subchannels", "Подканалы", "Пiдканали"],
  "marketing_link_products": ["Products", "Продукты", "Продукти"],
  "marketing_link_utm_links_generation": ["UTM links generation", "Генерация UTM ссылок", "Генерацiя UTM посилань"],
  "marketing_link_account_utm_link": ["Clients list (UTM links)", "Список клиентов (UTM метки)", "Список клієнтів (UTM мітки)"],
  // Channels
  "statistics_utm_source_name": ["Name", "Название", "Назва"],
  "statistics_utm_source_number": ["Number", "Номер", "Номер"],
  "statistics_utm_source_description": ["Description", "Описание", "Опис"],
  "statistics_utm_source_status": ["Status", "Статус", "Статус"],
  // Subchannels
  "statistics_utm_medium_name": ["Name", "Название", "Назва"],
  "statistics_utm_medium_number": ["Number", "Номер", "Номер"],
  "statistics_utm_medium_description": ["Description", "Описание", "Опис"],
  "statistics_utm_medium_status": ["Status", "Статус", "Статус"],
  "statistics_utm_medium_utm_source_name": ["Channel", "Канал", "Канал"],
  // Products
  "statistics_utm_campaign_name": ["Name", "Название", "Назва"],
  "statistics_utm_campaign_number": ["Number", "Номер", "Номер"],
  "statistics_utm_campaign_description": ["Description", "Описание", "Опис"],
  "statistics_utm_campaign_status": ["Status", "Статус", "Статус"],
  // buttons
  "marketing_add_channel_link": ["Add Link", "Привязать", "Зв'язати"],
  "marketing_add_channel": ["Add", "Добавить", "Додати"],
  "marketing_edit_channel": ["Edit", "Редактировать", "Редагувати"],
  // modal
  "marketing_active_channel_status": ["Active", "Активный", "Активний"],
  "marketing_archive_channel_status": ["Archive", "Архивный", "Архивний"],
  "marketing_edit_current_channel": ["Edit channel", "Редактирование канала", "Редагування каналу"],
  "marketing_edit_current_subchannel": ["Edit subchannel", "Редактирование подканала", "Редагування пiдканалу"],
  "marketing_add_new_channel": ["Add channel", "Добавление канала", "Додати канал"],
  "marketing_add_new_subchannel": ["Add subchannel", "Добавление подканала", "Додати пiдканал"],
  "marketing_add_new_product": ["Add product", "Добавление продукта", "Додати продукт"],
  "marketing_add_edit_product": ["Edit product", "Редактирование продукта", "Редагування продукт"],
  "marketing_edit_description": ["Description", "Описание", "Опис"],
  "marketing_save_modal_data": ["Save", "Сохранить", "Зберегти"],
  "marketing_cancel_modal_data": ["Cancel", "Отменить", "Скасувати"],
  "marketing_bind_subchannel_to_channel": ["Bind subchannel to channel", "Привязать подканал к каналу", "Прив'язати пiдканал до каналу"],
  "marketing_bind_channel": ["Channel", "Канал", "Канал"],
  "marketing_bind_subchannel": ["Subchannel", "Подканал", "Пiдканал"],
  "marketing_bind_product": ["Product", "Продукт", "Продукт"],
  "marketing_write_website": ["Write website", "Введите веб-сайт", "Введіть веб-сайт"],
  "marketing_generate_utm_link_btn": ["Generate", "Сгенерировать", "Згенерувати"],
  "marketing_bind_more": ["Bind more", "Привязать ещё", "Прив'язати ще"],
  // generate utm links
  "marketing_generate_links_h1": ["Generating UTM links", "Генерация UTM ссылок", "Генерацiя UTM посилань"],
  "marketing_generate_links_input_result": ["Result", "Результат", "Результат"],
  "marketing_generate_links_search-channel": ["Search channel", "Поиск канала", "Пошук каналу"],
  "marketing_generate_links_search-subchannel": ["Search subchannel", "Поиск подканала", "Пошук пiдканалу"],
  "marketing_generate_links_search-product": ["Search products", "Поиск продукта", "Пошук продукта"],
  // statitstics clicks
  "core_account_id": ["ID", "ID", "ID"],
  "core_account_name": ["Full Name", "ФИО", "ПІБ"],
  "core_account_country_id": ["Country", "Страна", "Країна"],
  "core_account_license": ["License", "Лицензия", "Ліцензія"],
  "core_account_category_id": ["Category", "Категория", "Категорія"],
  "statistics_account_utm_link_utm_link_id": ["Channel", "Канал", "Канал"],
  "statistics_account_utm_link_account_id": ["ID", "ID", "ID"],
  "statistics_utm_source_id": ["Utm_source", "Utm_source", "Utm_source"],
  "statistics_utm_medium_id": ["Utm_medium", "Utm_medium", "Utm_medium"],
  "statistics_utm_campaign_id": ["Utm_campaign", "Utm_campaign", "Utm_campaign"],
  "statistics_channel": ["Channel", "Канал", "Канал"],
  "statistics_subchannel": ["Subchannel", "Подканал", "Підканал"],
  "statistics_product": ["Product", "Продукт", "Продукт"],
  "statistics_account_utm_link_created_at": ["Date of creation", "Дата создания", "Дата створення"],
};
