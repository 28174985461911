import React                               from 'react';
import * as PropTypes                      from 'prop-types';
import { FormControl, MenuItem, Select, }  from '@material-ui/core';
// https://material-ui.com/ru/api/select/
// https://material-ui.com/ru/components/selects/


export class MagicSelect extends React.PureComponent {
	static defaultProps = {
		ControlProps: {},
		ItemsProps: {},
		SelectProps: {},
		className: [],
		disabled: false,
	};

	static classNamePropType = PropTypes.string;
	static classNamePropTypes = PropTypes.oneOfType( [
		MagicSelect.classNamePropType,
		PropTypes.arrayOf( MagicSelect.classNamePropType ),
	] );
	static disabledPropTypes = PropTypes.bool;
	static valuePropType = [
		PropTypes.bool,
		PropTypes.number,
		PropTypes.string,
	];
	static valuePropTypes = PropTypes.oneOfType( MagicSelect.valuePropType );
	static valuesPropType = [
		PropTypes.number,
		PropTypes.string,
	];
	static valuesItemPropTypes = PropTypes.oneOfType( MagicSelect.valuesPropType );
	static valuesPropTypes = PropTypes.arrayOf( MagicSelect.valuesItemPropTypes );
	static propTypes = {
		ControlProps: PropTypes.object.isRequired,
		ItemsProps: PropTypes.oneOfType([
			PropTypes.func,
			PropTypes.object,
		]).isRequired,
		SelectProps: PropTypes.object.isRequired,
		className: MagicSelect.classNamePropTypes.isRequired,
		disabled: MagicSelect.disabledPropTypes.isRequired,
		value: MagicSelect.valuePropTypes.isRequired,
		valueDefault: MagicSelect.valuePropTypes,
		values: MagicSelect.valuesPropTypes.isRequired,
		label: PropTypes.func,
		valueToLabel: PropTypes.func,
		ItemProps: PropTypes.func,
		onChange: PropTypes.func.isRequired,
	};

	async onChange ( event ) {
		event.preventDefault();
		const { onChange, } = this.props;
		onChange && await onChange( event.target.value );
	}

	label ( value ) {
		const { label, valueDefault, } = this.props;
		if ( label instanceof Function ) {
			return label( [ null, undefined ].includes( value ) ? valueDefault : value );
		}
		return this.valueToLabel( [ null, undefined ].includes( value ) ? valueDefault : value );
	}

	valueToLabel ( value, valueIndex ) {
		const { valueToLabel, } = this.props;
		if ( valueToLabel instanceof Function ) {
			return valueToLabel( value, valueIndex );
		}
		return value;
	}

	renderSelectItem ( value, valueIndex ) {
		const { ItemsProps, ItemProps, } = this.props;
		const label = this.valueToLabel( value, valueIndex );
		const options = { ...ItemsProps, };
		if ( ItemProps instanceof Function ) {
			Object.assign( options, ItemProps( value, valueIndex ) || {} );
		} else if ( ItemProps instanceof Object ) {
			Object.assign( options, ItemProps );
		}
		const key = `${ value }`;
		Object.assign( options, { children: label, key, value, } );
		return (<MenuItem { ...options } />);
	}

	renderSelect () {
		const { SelectProps, } = this.props;
		const { disabled, value, valueDefault: vd = [], values, } = this.props;
		const valueDefault = vd instanceof Array ? vd : [ vd ];
		const options = {
			autoWidth: true,
			displayEmpty: true,
			disableUnderline: true,
			native: false,
			...SelectProps,
			children: [ ...valueDefault, ...values ].map( this.renderSelectItem.bind( this ) ),
			disabled,
			renderValue: this.label.bind( this ),
			value,
			onChange: this.onChange.bind( this ),
		};
		return (<Select { ...options } />);
	}

	renderFormControl () {
		const { ControlProps, } = this.props;
		const options = {
			...ControlProps,
			children: this.renderSelect(),
		};
		return (<FormControl { ...options } />);
	}

	render () {
		const { className, } = this.props;
		const classNames = className instanceof Array ? className : [ className ];
		const options = {
			children: this.renderFormControl(),
			className: [ 'magic-select', ...classNames ].join(' '),
		};
		return (<div { ...options } />);
	}

};
