import React from 'react';
import { withLocalize } from 'react-localize-redux';

/**
 * @param {function} onChange callback used to recive { from, to } date values (REQUIERED)
 * @param {Array} avaliableFilterTypes valid types are: ['currentMonth', 'exactMonth', 'quarter', 'year', 'exactDate']
 */
class UserFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showDropdown: false,
        };
    }

    componentDidUpdate() {

    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    save = async (state) => new Promise((next) => this.setState(state, next));

    handleClickOutside = async (e) => {
        if(!e.path){
            return //prevents errors in console
        }
        
        if (this.wrapperRef && !e.path.includes(this.wrapperRef)) {
            await this.save({ showDropdown: false });
        }
    }

    async toogleDropdown() {
        if (!this.state.showDropdown) {
            document.addEventListener('mousedown', this.handleClickOutside);
        } else {
            document.removeEventListener('mousedown', this.handleClickOutside);
        }

        await this.save({ showDropdown: !this.state.showDropdown });
    }

    async hadleFilterChange(user) {
        this.props.onChange(user.id);

        await this.save({ selectedUser: user.name, showDropdown: false });
    }

    render() {
        const { translate, avaliableFilterTypes, selectedUser } = this.props;
        const selectedUserEntry = avaliableFilterTypes?.filter(user => user?.id === selectedUser)[0]?.name;

        return (
            <>
                <div className='dashboard-actions'>
                    <div className={`period-filter ${!this.state.showDropdown ? 'unactive' : ''}`} ref={(node) => this.wrapperRef = node}>
                        <div className='field' onClick={() => this.toogleDropdown()}>
                            <span className='placeholder'>{selectedUser ? selectedUserEntry : translate('filter-select-user')}</span>
                            <i className={`arrow ${this.state.showDropdown ? 'arrow_opened' : ''}`}>&#8249;</i>
                        </div>
                        <div className='dropdown'>
                            {this.props?.avaliableFilterTypes?.map((user, i) => {
                                return (
                                    <div className='item' key={i} onClick={() => this.hadleFilterChange(user)}>
                                        {user.name}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default withLocalize(UserFilter);
