import React from "react";
import { map } from "lodash";
import { Collapse } from 'reactstrap';

class DepartmentUsers extends React.Component {
    state = {
        users: this.props.item,
        collapse: false
    }

    UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
        this.setState({
            users: nextProps.item
        });
    }

    toggleCollapse = () => {
        this.setState({
            collapse: !this.state.collapse
        });
    }

    render () {
        return (
            <div className='department-block'>
                <div className="department-title" onClick={this.toggleCollapse}>{this.state.users[0]['dep_name']}</div>
                <Collapse isOpen={this.state.collapse}>
                    {map(this.state.users, (item, key) => {
                       return <div className="department-user" key = {key} data-item={item['user_id']} data-name={item['name']} data-surname={item['surname']} onClick={ this.props.changeDataUserId }>{item.name} {item.surname}</div>
                    })}

                </Collapse>
            </div>
        );
    }
}

export default DepartmentUsers;
