import React, { Component, } from "react";
import PropTypes from "prop-types";
import { withLocalize, } from "react-localize-redux";

import LendingLanguageForm from "./components/LendingLanguageForm";
import { Input, Popup, Select, } from "../../../../components";
import FileInput from "../../components/FileInput";

import {
	createLending,
	updateLending,
	createLendingLanguage,
	updateLendingLanguage,
	deleteLendingLanguage,
	getSystemLanguages,
	getTargetPagesDropdown,
} from "../../../../../../services/PartnersRequestService";

import { validateCreatePromoFields, validateArrayOfFormDatas, } from "../../helpers/validation-helpers";
import { findOptionByValue, setErrorClass, } from "../../../../utils/helpers";

import { LENDINGS_STATUSES, LENDINGS_LANGUAGE_STATUSES, } from "../consts/LENDINGS_STATUSES";
import { LENDINGS_FILE_EXTENSION_ACCEPT, } from "../consts/LENDINGS_FILE_EXTENSION_ACCEPT";
import ImagePopup from "../../components/ImagePopup";

class CreateUpdateLending extends Component {
    static propTypes = {
    	onClose: PropTypes.func.isRequired,
        // eslint-disable-next-line sort-keys
    	enums: PropTypes.object,
    	popupData: PropTypes.object,
    	onDataLoad: PropTypes.func,
    };

	static defaultProps = {
		popupData: {},
	};

	constructor (props) {
    	super(props);

    	this.state = {
    		languageOptions: [],
			targetPageOptions: [],

    		errors: [],
			languageFormErrors: [],

    		formData: {
    			name: '',
    			status: {},
				target_page_id: {},
				file: null,

				//Values on update
				id: null,
				source: null,
				updated_at: null,
    		},

			languageFormData: [
				// {
					// language_id: {},
					// file: null,
					// status: {},
				// },
			],

			imgPopup: {
				isOpen: false,
				imgSrc: null,
			},
    	};
	}

	get isEditMode () {
    	return !!Object.keys(this.props.popupData).length;
	}

	get isViewMode () {
    	return this.props.popupData?.isViewMode;
	}

	get statusesOptions () {
    	const { enums, } = this.props;

    	return Object.values(enums.data.partners.LendingStatusEnum).map((status, index) => {
    		return {
    			label: status,
    			value: index,
    		};
    	});
	}

	get languageStatusesOptions () {
		const { enums, } = this.props;

		return Object.values(enums.data.partners.LendingLanguageStatusEnum).map((status, index) => {
			return {
				label: status,
				value: index,
			};
		});
	}
	async componentDidMount () {
    	try {
    		this.toggleLoaded(false);

    		await this.getInitialData();

    		if (this.isEditMode) {
    			await this.getUpdateData();
    		}
    		this.toggleLoaded(true);
    	} catch (error) {
    		this.toggleLoaded(true);
    		console.log(error);
    		error.showErrorNotification?.();
    	}
	}
    save = async state => new Promise(next => this.setState(state, next));

	toggleLoaded = loadData => this.save({ loadData, });

	toggleImgPopup = (isOpen, imgSrc) => {
		return this.save({ imgPopup: { imgSrc, isOpen, }, });
	}

	getInitialData = async () => {
		const { formData, } = this.state;

		const languages = await getSystemLanguages();
		const languageOptions = languages.data.map((language) => {
			const { core, } = language;

			return {
				label: core.language.name_en,
				value: core.language.id,
			};
		});

		const targetPages = await getTargetPagesDropdown();
		const targetPageOptions = targetPages.map((item) => {
			return {
				value: item.id,
				label: item.name,
			};
		});

		const status = findOptionByValue(this.statusesOptions, LENDINGS_STATUSES.AVAILABLE);

		await this.save({
			languageOptions,
			targetPageOptions,
			formData: { ...formData, status, },
		});
	}

	getEmptyLanguageFormItem = () => {
		const languageStatus = findOptionByValue(this.languageStatusesOptions, LENDINGS_LANGUAGE_STATUSES.AVAILABLE);

		return {
			language_id: {},
			file: null,
			status: languageStatus,
		};
	}
	getUpdateData = async () => {
		const {
			formData, languageFormData,
		} = this.props.popupData;
		const {
			status,
			target_page_id,
		} = formData;

		const { languageOptions, targetPageOptions, } = this.state;
		const statusOptions = this.statusesOptions;
		const languageStatusesOptions = this.languageStatusesOptions;

		const languageFormDataWithSelectValues = languageFormData.map((formDataItem) => {
			const {
				language_id, status,
			} = formDataItem;

			return {
				...formDataItem,
				language_id: findOptionByValue(languageOptions, language_id),
				status: findOptionByValue(languageStatusesOptions, status),
			};
		});

		await this.save({
			formData: {
				...formData,
				target_page_id: findOptionByValue(targetPageOptions, target_page_id),
				status: findOptionByValue(statusOptions, status),
			},

			languageFormData: languageFormDataWithSelectValues,
		});
	}

	getSendData = (rawFormData, additionalEntries = {}) => {
		const formData = new FormData();

		Object.entries({ ...rawFormData, ...additionalEntries, }).map((entry) => {
			const [ key, value, ] = entry;

			const keyIsRedundant = key === 'source' || key === 'updated_at' || key === 'created_at'; //no need to send them, but we receive them as the response
			const itemExistInDB = key === 'id' && value;

			if (key === 'status' || key === 'target_page_id' || key === 'language_id') {
				return formData.set(key, value.value);
			}

			if (itemExistInDB) {
				formData.set("_method", "PUT");
			}

			if (keyIsRedundant) {
				return null;
			}

			return formData.set(key, value);
		});

		return formData;
	};

	sendSerialLanguages = async (lending_id) => {
		const {
			languageFormData,
		} = this.state;

		await languageFormData.reduce(async (previousPromise, formItem) => {
			//need to send requests one after another, otherwise if send all at once backend may write them on the same id - which returns error
			await previousPromise;

			const sendData = this.getSendData(formItem, { lending_id, });

			return await formItem.id ? updateLendingLanguage(formItem.id, sendData) : createLendingLanguage(sendData);
		}, Promise.resolve());
	}

	saveData = async () => {
		try {
			const { onClose, onDataLoad, } = this.props;
			const {
				formData, languageFormData,
			} = this.state;
			const id = formData.id;

			const fieldsAreValid = await validateCreatePromoFields(formData, this.save);
			const languageFieldsAraValid = await validateArrayOfFormDatas(languageFormData, languageFormErrors => this.save({ languageFormErrors, }));

			if (!fieldsAreValid || !languageFieldsAraValid) {
				return false;
			}

			await this.toggleLoaded(false);
			const formSendData = this.getSendData(formData);
			const response = this.isEditMode ? await updateLending(id, formSendData) : await createLending(formSendData);

			await this.sendSerialLanguages(response.id);

			await this.toggleLoaded(true);
			await onClose();
			await onDataLoad();
		} catch (error) {
			await this.toggleLoaded(true);
			console.log(error);
			error.showErrorNotification?.();
		}
	}

    handleOnChange = async (event) => {
    	const { name, value, } = event.target;

    	await this.save(state => ({
    		...state,
    		errors: state.errors.filter(item => item !== name),
    		formData: {
    			...state.formData,
    			[name]: value,
    		},
    	}));
    };
	handleOnFileChange = async (e) => {
		const { formData, errors, } = this.state;

		await this.save({
			errors: errors.filter(error => error !== 'file'),
			formData: {
				...formData,
				file: e.target.files[0],
			},
		});
	}

	handleOnLanguageFormChange = async (event, index) => {
		const { languageFormData, languageFormErrors, } = this.state;
		const { name, value, } = event.target;

		const languageFormWithChange = [ ...languageFormData, ];

		if (name === 'file') {
			languageFormWithChange[index] = {
				...languageFormWithChange[index],
				file: event.target.files[0],
			};
		} else {
			languageFormWithChange[index] = {
				...languageFormWithChange[index],
				[name]: value,
			};
		}

		await this.save(state => ({
			...state,
			languageFormData: languageFormWithChange,

			//filter errors
			 languageFormErrors: languageFormErrors.filter(error => error !== `${ name }_${ index }`),
		}));
	};

    renderTitle = () => {
    	const { translate, } = this.props;

    	switch (true) {
    		case this.isViewMode: {
    			return translate("partners_lending");
    		}
    		case this.isEditMode: {
    			return translate("partners_lendings_edit");
    		}
    		default: {
    			return translate("partners_lendings_create");
    		}
    	}

    };

	renderForm = () => {
		const { translate, } = this.props;
		const {
			formData, errors, targetPageOptions,
		} = this.state;

		const {
			name, status, file, target_page_id, source, updated_at,
		} = formData;

		return (
			<div className="promo-popup__block">
				<div className="promo-popup__row promo-popup__row_first">
					<Input
						disabled={ this.isViewMode }
						label={ translate(`partners_create_banner_name`) }
						name={ 'name' }
						placeholder={ translate(`partners_create_symbol_name_placeholder`) }
						value={ name }
						wrapperClassName={ setErrorClass(errors, 'name') }
						onChange={ e => this.handleOnChange(e) }
						onValid={ (value) => {
							if (value > 30) {
								throw new Error('XXX');
							}
						} }
					/>
				</div>
				<div className="promo-popup__row">
					<Select
						className={ setErrorClass(errors, "status") }
						disabled={ this.isViewMode }
						isRequired={ true }
						label={ translate("partners_banners_status") }
						name="status"
						options={ this.statusesOptions }
						placeholder={ translate("partners_banners_status") }
						value={ status }
						onChange={ e => this.handleOnChange(e) }
					/>
				</div>
				<div className={ 'promo-popup__row' }>
					<div className={ `input__wrapper` }>
						<label className="">{translate(`partners_lendings_miniature`)}</label>
						<FileInput
							accept={ LENDINGS_FILE_EXTENSION_ACCEPT }
							buttonText={ file || this.isEditMode ? translate('partners_change_file') : translate('partners_download') }
							className={ setErrorClass(errors, 'file') }
							disabled={ this.isViewMode }
							file={ file }
							imgData={ { source, updated_at, } }
							name={ 'file' }
							onChange={ e => this.handleOnFileChange(e) }
							onImgClick={ imgSource => this.toggleImgPopup(true, imgSource) }
						/>
					</div>
				</div>
				<div className="promo-popup__row">
					<Select
						className={ setErrorClass(errors, "target_page_id") }
						disabled={ this.isViewMode }
						isRequired={ true }
						label={ translate("partners_banners_target_page") }
						name="target_page_id"
						options={ targetPageOptions }
						placeholder={ translate("partner_banners_target_page_placeholder") }
						value={ target_page_id }
						onChange={ e => this.handleOnChange(e) }
					/>
				</div>
			</div>
		);
	}

	deleteLastInArray = async (formName) => {
		const newArray = [ ...this.state[formName], ]; //clone

		newArray.pop(); //remove last

		await this.save({ [formName]: newArray, });
	}

	filterErrorsWithIndex = async (index, formErrors) => {
		const errorsClone = [ ...this.state[formErrors], ];

		//error goes by: fieldName_index (link_1) - split to get index and compare
		return this.save({ [formErrors]: errorsClone.filter(error => error.split('_').at(-1) !== `${ index }`), });
	}
	addTargetPageLanguage = async () => {
		const { languageFormData, } = this.state;
		const languageFormDataItem = this.getEmptyLanguageFormItem();

		await this.save({
			languageFormData: [
				...languageFormData,
				languageFormDataItem,
			],
		});
	}

	deleteLastLanguageFormDataItem = async (index) => {
		try {
			const { languageFormData, } = this.state;

			// const itemExistInDb = languageFormData.at(-1).id;
			//
			// if (itemExistInDb && this.isEditMode) {
			// 	const nameId = languageFormData.at(-1).id;
			// 	const response = await deleteLendingLanguage(nameId);
			// }

			await this.deleteLastInArray('languageFormData');
			await this.filterErrorsWithIndex(index, 'languageFormErrors');
		} catch (error) {
			console.log(error);
			error.showErrorNotification?.();
		}
	}
	renderLanguageForm = () => {
		const { popupData, translate, } = this.props;
		const { languageFormData: languagesInDB, } = popupData;
		const {
			languageOptions, languageFormData, languageFormErrors,
		} = this.state;

		return (
			<LendingLanguageForm
				addLanguage={ this.addTargetPageLanguage }
				deleteLastLanguage={ this.deleteLastLanguageFormDataItem }
				errors={ languageFormErrors }
				handleOnChange={ this.handleOnLanguageFormChange }
				isEditMode={ this.isEditMode }
				isViewMode={ this.isViewMode }
				languageFormData={ languageFormData }
				languageOptions={ languageOptions }
				languagesInDB={ languagesInDB }
				languageStatusesOptions={ this.languageStatusesOptions }
				toggleImgPopup={ this.toggleImgPopup }
				translate={ translate }
			/>
		);
	}

	render () {
    	const { onClose, } = this.props;
    	const { loadData, imgPopup, } = this.state;
    	const isEditMode = this.isEditMode;

    	return (
			//eslint-disable-next-line
			<Popup
				cancelBtnText={ "partners_payout_rate_list_popup_cancel" }
				createBtnText={ isEditMode ? null : "partners_payout_rate_list_popup_save" }
				customClassName={ `promo-popup promo-popup_target-page ${ isEditMode && "promo-popup_edit" }` }
				isImage={ false }
				loadData={ loadData }
				showPopUp={ this.isViewMode }
				size={ "popup-banner-size" }
				title={ this.renderTitle() }
				onClick={ this.saveData }
				onClose={ onClose }
			>
				{ imgPopup.isOpen &&
					<ImagePopup
						imgSrc={ imgPopup.imgSrc }
						onClose={ () => this.toggleImgPopup(false, null) }
					/>
				}
				{this.renderForm()}
				{this.renderLanguageForm()}
			</Popup>
    	);
	}
}

export default withLocalize(CreateUpdateLending);