export const SET_USERS = 'SET_USERS';
export const SET_USER = 'SET_USER';
export const DELETE_USER = 'DELETE_USER';
export const SET_FILTER = 'SET_FILTER';
export const RESET_FILTER = 'RESET_FILTER';
export const GET_ENUMS = 'GET_ENUMS';
export const GET_AVATAR = 'GET_AVATAR';
export const SET_ERROR = 'SET_ERROR';
export const RESET_ERROR = 'RESET_ERROR';
export const SET_SALES_PLANNING = 'SET_SALES_PLANNING';
export const SET_DEPARTMENTS = 'SET_DEPARTMENTS';
