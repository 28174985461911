import React, { Component } from 'react';
import { withLocalize, Translate } from "react-localize-redux";
import '../styles/template_permission.scss';
import TablePermission from "../../template_permission/components/Table/TablePermission";
import SideBarTaskList from "../../../common/components/SideBarTaskList";
import IndexTasks from "../../users/components/Permissions";
import DeleteTemplatePopup from "./DeleteTemplatePopup";
import NotificationService from "../../../../services/NotificationService";
import { userService } from "../../../../services/UserDataService";
import Preloader from "../../../../components/LoadingHOC/Preloader";

class TemplatePermission extends Component {
    constructor (props) {
        super(props);

        this.state = {
            permission_data:     [],
            permissionTemplates: {
                hash: null,
                data: [],
                meta: {},
            },
            formPermission:      false,
            removeTemplate:      false,
            removeIdTemplate:    0,
            countUser:           0,
            isLoading:           true,
        };

    }

    async componentDidMount () {
        userService.subscribe('getPermissionTemplates', this.permissionTemplatesChanges, this);
        await this.loadPermissionTemplates();
    }

    componentWillUnmount = () => {
        userService.unsubscribe('getPermissionTemplates', this.permissionTemplatesChanges, this);
    };

    permissionTemplatesChanges = async (permissionTemplates) => {
        if (permissionTemplates.hash===this.state?.permissionTemplates?.hash) return `${this.constructor.name}.permissionTemplatesChanges: false`;
        const { hash, data: { meta, data }} = permissionTemplates;
        await this.save({ permissionTemplates: { hash, meta, data }});
        return `${this.constructor.name}.permissionTemplatesChanges: true`;
    };

    save = async (state) => new Promise(next => this.setState(state, next));

    loadPermissionTemplates = async () => {
        await this.save({ isLoading: true });
        const { hash, data: { meta, data }} = await userService.getPermissionTemplates();
        await this.save({
            permissionTemplates: { hash, meta, data },
            isLoading:           false,
        });
    };

    togglePermission = (data = []) => {
        this.setState({
            formPermission:  !this.state.formPermission,
            permission_data: data,
        });
    };

    toggleRemoveTemplate = (id = 0, count_user = 0) => {
        this.setState({
            removeTemplate:   !this.state.removeTemplate,
            removeIdTemplate: id,
            countUser:        count_user,
        });
    };

    removeTemplatePermissions = async () => {
        await userService.removeTemplatePm(this.state.removeIdTemplate);
        await this.toggleRemoveTemplate();
        await this.loadPermissionTemplates();
        NotificationService.success({
            title:   "success",
            message: "Template success delete",
        });
    };

    render () {
        return (
            <Translate>
                {({ translate }) => {
                    return (
                        <div className = 'templatePermission'>
                            {this.state.removeTemplate ?
                                <DeleteTemplatePopup
                                    countUser = { this.state.countUser }
                                    delete = { this.removeTemplatePermissions }
                                    toogle = { this.toggleRemoveTemplate }
                                    translate = { translate }
                                />
                                :""
                            }
                            { this.state.formPermission ? <IndexTasks closePermission = { this.togglePermission } loadPermissionTemplates = { this.loadPermissionTemplates } translate = { translate } userPermissionData = { this.state.permission_data } /> : null }

                            <div className = 'content-block'>
                                <div className = 'top-page-block'>
                                    <h1 className = 'page-title'>{translate(`template_permission`)}</h1>
                                    <span
                                        onClick = { () => {
                                            this.togglePermission();
                                        } } className = { `template_permission_btn` }>{translate(`template_permission_create`)}</span>
                                </div>
                            </div>

                            <div className = 'content-block'>
                                <div className = 'table-wrapper'>
                                    <div className = 'position-relative'>
                                        {
                                            this.state.isLoading ?
                                                <Preloader scale = { this.props.scale } />
                                                :
                                                <TablePermission
                                                    dataAfterLoading = { this.state.permissionTemplates }
                                                    loadPermissionTemplates = { this.loadPermissionTemplates }
                                                    togglePermission = { this.togglePermission }
                                                    toggleRemoveTemplate = { this.toggleRemoveTemplate }
                                                />
                                        }
                                    </div>
                                    <SideBarTaskList />
                                </div>
                            </div>

                        </div>
                    );
                }}
            </Translate>
        );
    }
}

export default withLocalize(TemplatePermission);
