import React from "react";

import fire from "../../../../images/fire.svg";
import status from "../../../../images/status.svg";
import StatusSelect from "./StatusSelect";
import ImportanceSelect from "./ImportanceSelect";
//import TypeBlockSelect from "./TypeBlockSelect";
import {getImportant, getStatus, } from "../../services/TaskHelperService";
import hourglass from "../../../../images/hourglass.svg";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import DataStorage from "../../../../helpers/DataStorage";
import moment from 'moment';

class StatusBlock extends React.Component {
    constructor (props) {
        super(props);

        this.lang = DataStorage.getData('locale');

        this.state = {
            type_:            [],
            state_:           [],
            urgency_:         [],
            createTaskSelect: this.props.createTaskSelect,

        };
    }

    render () {
        return (
            <div className = 'flex-wrapper lead-block taskSmallFixed'>

                <div className = 'user-info select-block'>
                    <div className = 'grey-title'>
                        <div className = 'icon-wrapper'>
                            <img alt = 'hourglass' src = { hourglass } />
                        </div>
                        {this.props.translate(`tasks_createform_deadline`)}</div>
                    <DatePicker
                        className = {`statusBlock-picker-${this.props.error('finish_date')}`}
                        selected={ new Date(this.props.selected) }
                        // selected = { this.props.selected }
                        onChange = { this.props.handleChangeData }
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode = 'select'
                        showTimeSelect
                        timeFormat = 'HH:mm'
                        timeIntervals = { 15 }
                        dateFormat = 'yyyy-MM-dd'
                        timeCaption = 'time'
                        minDate = { moment().add(-1, 'week').toDate() }
                    />
                </div>

                <div className='user-info select-block'>
                    <div className='grey-title'>
                        <div className='icon-wrapper'>
                            <img alt='status' src={fire}/>
                        </div>
                        {this.props.translate(`tasks_createform_important`)}</div>
                    <ImportanceSelect
                        error={this.props.error}
                        translate={this.props.translate}
                        data={this.state.urgency_}
                        defaultValue={this.props.createTaskSelect.urgency_id}
                        getData={getImportant(this)}
                        name='urgency_id'
                        setToStateSelect={this.props.setToStateSelect}
                    />
                </div>

                {/*<div className = 'user-info select-block'>
                    <div className = 'grey-title'>
                        <div className = 'icon-wrapper'>
                            <img alt = 'type' src = { type } />
                        </div>
                        {this.props.translate(`tasks_createform_type`)}</div>
                    <TypeBlockSelect
                        error = {this.props.error}
                        translate = {this.props.translate}
                        data = { this.state.type_ }
                        defaultValue = { this.props.createTaskSelect.type_id }
                        getData = { getType(this) }
                        name = 'type_id'
                        setToStateSelect = { this.props.setToStateSelect }
                    />
                </div>*/}

                {!this.props.quick ?
                    <div className = 'user-info select-block'>
                        <div className = 'grey-title'>
                            <div className = 'icon-wrapper'>
                                <img alt = 'status' src = { status } />
                            </div>
                            {this.props.translate(`tasks_createform_status`)}</div>
                        <StatusSelect
                            error = {this.props.error}
                            translate = {this.props.translate}
                            data = { this.state.state_ }
                            defaultValue = { this.props.createTaskSelect.state_id }
                            getData = { getStatus(this) }
                            name = 'state_id'
                            setToStateSelect = { this.props.setToStateSelect }
                        />
                    </div>
                    : ""
                }
            </div>
        );
    }
}

export default StatusBlock;
