import React                   from 'react';
import PropTypes               from 'prop-types';
import { connect }             from 'react-redux';
import { bindActionCreators }  from 'redux';
import { Translate }           from 'react-localize-redux';
import { isNull, }             from 'lodash';

import { Fade, }               from 'reactstrap';
import { Scrollbars, }         from 'react-custom-scrollbars';
import ResponsibleRow          from './ResponsibleRow';
import { getAllTaskUsers, }    from '../../../../tasks/services/TasksService';
import defaultAvatar           from '../../../../../images/profile_default.jpg';


class UserModalPopUp extends React.Component {
    static propTypes = {
        // translate: PropTypes.func,
        userModal: PropTypes.func,
        // userFadePopUp: PropTypes.func,
    };

    constructor (props) {
        super(props);

        this.state = {
            filter: '',
            user: this.props?.client?.user,
        };
    }

    get users() {
        return this.props.getAllUsers?.map(item => item?.core?.user) ?? [];
    }

    componentDidMount () {
        this.props.getAllTaskUsers();
    }

    UNSAFE_componentWillReceiveProps (props) {
        props?.client?.user && this.setState({ user: props?.client?.user, });
    }

    searchUpdate = (event) => this.setState({ filter: event?.target?.value ?? '', });

    filterReset = () => this.setState({filter: '', user: this.props?.client?.user,});

    onAdd = (id) => () => {
        this.setState({ user: this.users.find(user => user.id === id) ?? this.props?.client?.user, })
    };

    renderClose = () => {
        const onClick = () => {
            this.props.userModal();
            this.filterReset();
        };
        const options = { className: 'close-button', onClick, };
        return (<div {...options}><i className={'la la-close'} /></div>);
    };

    renderSave = (translate) => {
        const onClick = () => {
            this.props.userModal();
            this.filterReset();
            const id = this.state?.user?.id ?? this.props?.client?.user?.id;
            id && this.props.sendManager(id);
        };
        const title = translate(`tasks_createform_save`);
        return (<div className={'gradient-button'}><span onClick={onClick}>{title}</span></div>);
    };

    renderResponsible = (user, {index=undefined, showAdd=false,}={}) => {
        const {id, name, surname} = user || {};
        const avatars = this.props.usersAllAvatar;
        const mimetype = 'image/jpeg';
        const avatar = id in avatars ? `data:${mimetype};base64,${avatars[id]}` : defaultAvatar;
        const onAdd = this.onAdd(id);
        const options = {avatar, name, surname, ...index && {index,}, ...showAdd && {onAdd,},};
        return (<ResponsibleRow key={index} {...options} />);
    };

    renderResponsibles = (translate) => {
        const count = !isNull(this.props.client['core-user-id']) ? '1' : '0';
        const title = `${translate(`tasks_participants`)} (${count})`;
        const responsible = this.state?.user ?? this.props?.client?.user;
        return (
            <React.Fragment>
                <div className={'responsible-title'}>{title}</div>
                <div>
                    <Scrollbars style = { { height: 350 } }>
                        {this.renderResponsible(responsible)}
                    </Scrollbars>
                </div>
            </React.Fragment>
        );
    };

    renderResponsiblesAdd = (translate) => {
        let users = this.users;
        if (this.state?.filter) {
            const filter = this.state.filter.toLowerCase();
            users = users.filter(user => `${user.name} ${user.surname}`.toLowerCase().includes(filter));
        }
        users = users.filter(user => user.id !== this.state.user.id)
            .map((user, index) => this.renderResponsible(user, {index: index, showAdd: true,}));
        return (
            <React.Fragment>
                <div className = 'responsible-title'>
                    <input
                        onChange={ this.searchUpdate }
                        placeholder={ translate('task_search') }
                        type={ 'text' }
                        value={ this.state.filter }
                    />
                </div>
                <div>
                    <Scrollbars style = { { height: 350 } }>
                        {users}
                    </Scrollbars>
                </div>
            </React.Fragment>
        );
    };

    render () {
        return (
            <Translate>
                {({ translate }) => {
                    return (
                        <Fade
                            className={ 'client-popup spectors-modal fade-block' }
                            in={ this.props.userFadePopUp }
                        >
                            <div className={ 'spectors-block scroll'}>
                                { this.renderClose() }
                                <h4 className={ 'aligncenter' }>
                                    { translate(`tasks_change_the`) } { translate(`tasks_filter_responsible_a`) }
                                </h4>
                                <div className={ 'flex-wrapper' }>
                                    <div className={'participants'}>
                                        {/* {this.renderResponsibles(translate)} */}
                                    </div>
                                    <div className={'add-participants'}>
                                        { this.renderResponsiblesAdd(translate) }
                                    </div>
                                </div>
                                <div className = 'modal-buttons'>
                                    { this.renderSave(translate) }
                                </div>
                            </div>
                        </Fade>
                    );
                }}
            </Translate>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        getAllUsers: store.tasks.setAllTaskUsers.data,
    };
};

export default connect(
    mapStateToProps,
    (dispatch) =>
        bindActionCreators({
            getAllTaskUsers,
        }, dispatch)
)(UserModalPopUp);
