import React, { Component } from 'react';
import { withLocalize } from "react-localize-redux";
import { Link } from 'react-router-dom';
import moment from "moment";

import sandClock from '../../images/sandclock.svg';
import taskImportance from '../../images/task-importance.svg';
import status from '../../images/status.svg';
import PropTypes from "prop-types";
import call from "../../../../images/call.svg";
import profile_default from "../../../../images/profile_default.jpg";
import file_icon from "../../../../images/icon-file.svg";
import { callService } from "../../../../services/CallDataService";

class ModalWindowForTask extends Component {
    static propTypes = {
        closePopup: PropTypes.func.isRequired,
    };

    constructor (props) {
        super(props);

        this.state = {
            data: props.data,
            lang: props.activeLanguage?.code,
        };
    }

    componentDidMount () {

    }

    render () {
        const { translate, leftOrRight, topOrBottom } = this.props;
        const { data } = this.state;
        const { tasks: tasksObj = {}, core = {}, responsible = {} } = data;
        const { account = {} } = core;
        const { id: accountId, name: accountName, surname: accountSurname } = account;
        const { id: responsibleId, name: responsibleName, surname: responsibleSurname } = responsible;
        const { task_states, task_urgency, task_users, tasks, task_documents = [] } = tasksObj;
        const { id: taskId, finish_date: finishDate } = tasks;

        const dayFormatForDeadline = "DD.MM.YYYY HH:mm:ss";
        const classFinishDate = Date.parse(finishDate) < new Date() ? 'expired-date' : '';
        const deadline = moment(finishDate).format(dayFormatForDeadline);

        // TODO: get task_urgency?.[`name_${this.state.lang}`] and task_states?.[`name_${this.state.lang}`]
        // as one field by localize from back-end

        const renderPhoneCall = () => {
            const optionsCall = {
                className: `phone-call`,
                style:     { cursor: 'pointer', marginLeft: '7px' },
                onClick:   async () => callService.sendCall({ account_id: accountId }),
            };
            const img = (<img alt='call' src={call}/>);

            return (<span {...optionsCall}>{img}</span>);
        };

        const renderUserAvatar = (userId) => {
            let src = this?.props?.avatar?.[userId];
            src = src ? `data:image/jpeg;base64,${ src }` : profile_default;

            return (<img style={{ borderRadius: '12px', width: '24px' }} src={src}/>);
        };

        const renderAttachments = () => {
            if (!(task_documents instanceof Array && task_documents?.length > 0)) {
                return null;
            }

            const documentsCount = task_documents.length;

            return (
                <div className='task-modal__attachments'>
                    <p className='task-modal__attachments-title'>{translate(`calendar_documents`)} ({documentsCount})</p>

                    <div className='task-modal__attachment'>
                        {task_documents.map((attachment, index) => renderAttachment(attachment, index))}
                    </div>
                </div>
            );
        };

        const renderAttachment = (attachment, index) => {
            const { file_name, created_at, } = attachment;

            return (
                <div className='task-modal__attachment-half'
                     key={`attachment-${index}`}
                >
                    <img alt='file_icon' src={file_icon}/>

                    <p>
                        <span>{file_name}</span>
                        <span className='task-modal__creation-date'>{created_at}</span>
                    </p>
                </div>
            );
        };

        return (
            <div className={`task-modal-window ${leftOrRight} ${topOrBottom}`}>
                <div
                    className='task-modal__close'
                    onClick={() => {
                        this.props.closePopup();
                    }}
                >
                    <i className='la la-close'/>
                </div>
                <div className='task-modal__header'>
                    <p>{translate(`calendar_task`)} {taskId}</p>
                </div>
                <div className='task-modal__statuses-block'>
                    {/*REMOVED THIS BLOCK IN CURRENT VERSION OF CRM*/}
                    {/*<div className='task-modal__icons'>
                        <Link to={`/task/${taskId}`}>
                            <img alt='change' src={changeIcon}/>
                        </Link>
                        <img alt='delete' className='task-modal__delete-icon' src={deleteIcon}/>
                    </div>*/}
                    <div className='task-modal__status-block'>
                        <img alt='clock' src={sandClock}/>
                        <p className='task-modal__status-name'>{translate(`calendar_deadline`)}</p>
                        <p className={`task-modal__status-value ${classFinishDate}`}>{deadline}</p>
                    </div>
                    <div className='task-modal__status-block'>
                        <img alt='importance' src={taskImportance}/>
                        <p className='task-modal__status-name'>{translate(`calendar_important`)}</p>
                        <p className={`task-modal__status-value urgency urgency--${task_urgency?.['alias']}`}>
                            {task_urgency?.[`name_${this.state.lang}`]}
                        </p>
                    </div>
                    <div className='task-modal__status-block'>
                        <img alt='status' src={status}/>
                        <p className='task-modal__status-name'>{translate(`calendar_status`)}</p>
                        <p className={`task-modal__status-value states states--${task_states?.['alias']}`}>
                            {task_states?.[`name_${this.state.lang}`]}
                        </p>
                    </div>
                    <div className='task-modal__status-block'>
                        {renderUserAvatar(responsibleId)}
                        <p className='task-modal__status-name'>{translate(`calendar_responsible`)}</p>
                        <p className={`task-modal__status-value`}>{responsibleName} {responsibleSurname}</p>
                    </div>
                    <div className='task-modal__status-block'>
                        {/* NO CLIENT AVATARS NOW */}
                        {renderUserAvatar(null)}
                        <p className='task-modal__status-name'>{translate(`calendar_client`)}</p>
                        <p className={`task-modal__status-value align-items--center`}>{accountName} {accountSurname} {renderPhoneCall()}</p>
                    </div>
                </div>
                <div className='task-modal__description'>
                    <p className='task-modal__description-title'>{translate(`calendar_description`)}</p>
                    <p>{tasks?.['description']}</p>
                </div>

                {renderAttachments()}

                <div className='task-modal__open-task'>
                    {tasks?.['account_id'] ?
                        <Link to={`/clients/${tasks?.['account_id']}/tasks/task/${taskId}`} target='_blank'>
                            <p>{translate(`calendar_open_task`)}</p>
                        </Link>
                        :
                        <Link to={`/task/${taskId}`} target='_self'>
                            <p>{translate(`calendar_open_task`)}</p>
                        </Link>
                    }
                </div>
            </div>
        );
    }
}

export default withLocalize(ModalWindowForTask);
