/* eslint-disable sort-keys */
const configHorizontalMenu = (translate, permissions = null) =>
	[
		// Payments
		{
			title: translate("menu_payments"),
			config: [
				{
					title: translate("menu_payments"),
					key: "menuPaymentsList",
					path: "/payments",
				},
			],
		},
	  //Payments Summary
		{
			title: translate('summary_table_title'),
			config: [
				{
					title: translate('summary_table_title'),
					key: 'menuPaymentsSummary', //*
					path: '/payments/summary_date',
				},
			],
		},
	]
		.map(item => ({
			...item,
			config: item.config.filter(({ key, }) => {
				if (permissions) {
					return permissions?.[key]?.access("index") ?? false;
				}

				return true;
			}),
		}))
		.filter(({ config, }) => config.length);

export default configHorizontalMenu;