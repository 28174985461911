import {
  itemsBuild,
  itemsDrain,
  itemsResort,
  CFG_ITEM_TYPE,
} from '../Config';
import { onEnabled, render, } from '../Mentor/withDropDown';
// import {} from '../Mentor/withDropDown';

const f_oId = {
  'drop-down': false,
};

const f_oTradingId = {
  'drop-down': false,
};

const f_pSystem = {
  'drop-down': '/api/payment/systems/drop-down?take=all',
  'o=': [ '=', '!=', ],
};

const f_mClosing = {
  'o=': [ '=', '!=', ],
};

const p_pSystem = {
  onEnabled: onEnabled('paymentSystems'),
  render: render('paymentSystems'),
};

export default {
  id: 192,
  ...[

    // filters
    ...[ {
      f: [ 'core.account.surname', 'core.account.name', ],
      id: 'f:aFullname',
      o: 'like',
    }, {
      // Номер счета IB Partner
      off: true, // TODO:
    }, {
      f: 'payment.orders.id',
      id: 'f:oId',
      ...f_oId,
    }, {
      f: 'payment.orders.trading_account_id',
      id: 'f:oTradingId',
      ...f_oTradingId,
    }, {
      f: 'payment.orders.status',
      id: 'f:oStatus',
    }, {
      f: 'payment.orders.resolution',
      id: 'f:oResolution',
    }, {
      f: 'payment.orders.created_at',
      id: 'f:oCreated',
    }, {
      f: 'payment.orders.date_paid',
      id: 'f:oDatePaid',
    }, {
      f: 'payment.orders.updated_at',
      id: 'f:oUpdated',
    }, {
      f: [ 'core.user.surname', 'core.user.name', ],
      id: 'f:uFullname',
      o: 'like',
    }, {
      f: 'core.system_accounts.acc_ccy',
      id: 'f:saCurrency',
    }, {
      f: 'payment.orders.type',
      id: 'f:oType',
    }, {
      f: 'payment.systems_by_card.name',
      id: 'f:pPlatform',
    }, {
      f: 'payment.orders.payment_system',
      id: 'f:pSystems',
      ...f_pSystem,
    }, {
      f: 'payment.orders.manually_closing',
      id: 'f:mClosing',
      ...f_mClosing,
    },
    {
      f: 'payment.system_gateway.name',
      id: 'f:mGateway',
    },
    ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

    // orders
    ...[ {
      f: '',
      id: 'o:',
      off: true, // TODO:
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

    // panels
    ...[ {
      ii: 'f:aFullname',
      t: 'magic_filter_translate_payment_account',
    }, {
      // Номер счета IB Partner
      off: true, // TODO:
    }, {
      ii: 'f:oId',
      t: 'magic_filter_translate_payment_order_id',
    }, {
      ii: 'f:oTradingId',
      t: 'magic_filter_translate_payment_trading_account_id',
    }, {
      ii: 'f:oStatus',
      t: 'magic_filter_translate_payment_status',
    }, {
      ii: 'f:oResolution',
      t: 'magic_filter_translate_payment_resolution',
    }, {
      ii: 'f:oCreated',
      t: 'magic_filter_translate_payment_created_at',
    }, {
      ii: 'f:oDatePaid',
      t: 'magic_filter_translate_payment_date_paid',
    }, {
      ii: 'f:oUpdated',
      t: 'magic_filter_translate_payment_updated_at',
    }, {
      ii: 'f:uFullname',
      t: 'magic_filter_translate_payment_manager',
    }, {
      ii: 'f:saCurrency',
      t: 'magic_filter_translate_payment_currency',
    }, {
      ii: 'f:oType',
      t: 'magic_filter_translate_payment_type',
    }, {
      ii: 'f:pPlatform',
      t: 'payment_table_platform',
    }, {
      ii: 'f:pSystems',
      t: 'payment_system',
      ...p_pSystem,
    }, {
      ii: 'f:mClosing',
      t: 'magic_filter_translate_payment_manually_closing',
    },
      {
        t: 'payment_table_gateway',
        ii: 'f:mGateway',
      },
    ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

  ]
  .map(itemsBuild)
  .reduce(itemsDrain, [])
  .reduce(itemsResort, {}),

  path: 'payment/orders',
  widget: false,
};
