import * as actions from './actionTypes';

const initialState = {
    data:   {},
    task:   {},
    output: {
        'take': 50,
        'skip': 0,
    },
    filter: {
        'tasks-tasks-account_id':   '',
        'tasks-tasks-description': '',
        'tasks-tasks-finish_date': '',
    },
    sort: {
        "f": 'tasks-tasks-finish_date',
        "o": 'order_by',
        "v": 'asc',
    },
    options:               {},
    enums:                 {},
    setOneHundredTasks:    {},
    setCommentsTaskID:     {},
    setDocumentTaskID:     {},
    setCheckListTaskID:    {},
    setCountNotWork:       {},
    setTaskUsers:          {},
    setAllTaskUsers:       {},
    setParticipants:       {},
    setSelectAccountModel: {},
    setDeleteTask:         {},
    taskHistory:           {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.SET_TASKS:
            return {
                ...state,
                ...state.tasks,
                'data':        action.data.data,
                'options':     action.data.options.filter_options,
                'total':       action.data.meta.total,
                'filterCount': action.data.meta.filter,
            };
        case actions.SET_TASK:
            return {
                ...state,
                ...state.tasks,
                'task':  action.data,
                'error': false,
            };
        case actions.SET_ERROR:
            return {
                ...state,
                'error': true,
            };
        case actions.RESET_ERROR:
            return {
                ...state,
                'error': action.data,
            };
        case actions.SET_FILTER:
            return {
                ...state,
                ...state.tasks,
                'filter': {
                    ...state.filter,
                    ...action.data,
                },
            };
        case actions.GET_ENUMS_TASKS:
            return {
                ...state,
                ...state.tasks,
                'enums': {
                    ...state.enums,
                    ...action.data,
                },
            };
        case actions.SET_ONE_HUNDRED_TASKS:
            return {
                ...state,
                ...state.tasks,
                'setOneHundredTasks': action.data,
            };
        case actions.SET_COMMENTS_TASK_ID:
            return {
                ...state,
                ...state.tasks,
                'setCommentsTaskID': action.data,
            };
        case actions.DELETE_COMMENT:
            return {
                ...state,
                ...state.tasks,
                'setCommentsTaskID': action.data,
            };
        case actions.SET_COUNT_NOT_WORK:
            return {
                ...state,
                ...state.tasks,
                'setCountNotWork': action.data,
            };
        case actions.SET_TASK_USERS:
            return {
                ...state,
                ...state.tasks,
                'setTaskUsers': action.data,
            };
        case actions.SET_ALL_TASKS_USERS:
            return {
                ...state,
                ...state.tasks,
                'setAllTaskUsers': action.data,
            };
        case actions.SET_PARTICIPANTS:
            return {
                ...state,
                ...state.tasks,
                'setParticipants': action.data,
            };
        case actions.DELETE_PARTICIPANTS:
            return {
                ...state,
                ...state.tasks,
                'setParticipants': action.data,
            };
        case actions.SET_SELECT_ACCOUNT_MODEL:
            return {
                ...state,
                ...state.tasks,
                'setSelectAccountModel': action.data,
            };
        case actions.DELETE_TASK:
            return {
                ...state,
                ...state.tasks,
                'setDeleteTask': action.data,
            };
      case actions.GET_HISTOR:
            return {
                ...state,
              ...state.tasks,
                'taskHistory': action.data,
            };
      case actions.CLEAR_TASK:
            return {
                ...state,
                ...state.tasks,
                'task':  {},
                'error': false,
            };
        case actions.FILTER_HASH:
            return {
                ...state,
                ...state.tasks,
                'filterHash': action.data,
            };
        case actions.GET_TYPES_TASKS:
            return {
                ...state,
                ...state.tasks,
                'tasksTypes': action.data,
            };
        case actions.GET_STATUS_TASKS:
            return {
                ...state,
                ...state.tasks,
                'tasksStatus': action.data,
            };
        default:
            return state;
    }
}
