import { editNewAffiliateProgramsList, } from "../../../services/PartnersRequestService";

const getEditDataForAffiliateProgramsPopup = async (id) => {
	const data = await editNewAffiliateProgramsList(id);
	const dataForEdit = data?.data?.partners;

	const editFormData = {
		// single_level_condition_ids: [ ...dataForEdit?.new_partners_conditions, ].filter(item => item.level === 1),
		// multi_level_condition_ids: [ ...dataForEdit?.new_partners_conditions, ].filter(item => item.level === 2),
		...dataForEdit?.programs,
		CPAMulti: {
			label: dataForEdit?.new_partners_conditions[1]?.name,
			value: dataForEdit?.new_partners_conditions[1]?.id,
		},
		CPASingle: {
			label: dataForEdit?.new_partners_conditions[0]?.name,
			value: dataForEdit?.new_partners_conditions[0]?.id,
		},
		CPLMulti: {
			label: dataForEdit?.new_partners_conditions[3]?.name,
			value: dataForEdit?.new_partners_conditions[3]?.id,
		},
		CPLSingle: {
			label: dataForEdit?.new_partners_conditions[2]?.name,
			value: dataForEdit?.new_partners_conditions[2]?.id,
		},
		InOutMulti: {
			label: dataForEdit?.new_partners_conditions[5]?.name,
			value: dataForEdit?.new_partners_conditions[5]?.id,
		},
		InOutSingle: {
			label: dataForEdit?.new_partners_conditions[4]?.name,
			value: dataForEdit?.new_partners_conditions[4]?.id,
		},
		RevshareMulti: {
			label: dataForEdit?.new_partners_conditions[7]?.name,
			value: dataForEdit?.new_partners_conditions[7]?.id,
		},
		RevshareSingle: {
			label: dataForEdit?.new_partners_conditions[6]?.name,
			value: dataForEdit?.new_partners_conditions[6]?.id,
		},
	};

	return editFormData;
};

export default getEditDataForAffiliateProgramsPopup;