import * as actions from './actionTypes';
import { simpleRequest } from '../../../../../services/RequestService/index';
import AuthService from "../../../../../services/AuthService";
import PermissionService from "../../../../../services/PermissionService";
import { post } from "../../../../../components/Magic/helpers/MagicRequest";

export const setSessionData = (data) => ({
    type: actions.SET_SESSION_DATA,
    data,
});

export const unsetSessionData = () => ({
    type: actions.UNSET_SESSION_DATA,
});

export const getProfile = (data) => ({
    type: actions.GET_PROFILE,
    data,
});

export const setHash = (data) => ({
    type: actions.SET_HASH,
    data,
});

export const doLogin = async (credentials) => {
    try {
        const logIn = await simpleRequest.post('/api/core/auth/user/login', {
            email:    credentials.email,
            password: credentials.password,
        });
        const data = {
            token: logIn?.data?.authorization?.token,
            permission_hash: logIn?.data?.user_hash,
        };
        await AuthService.initSession(data);
        await PermissionService.setHashToStore();

        return true;
    } catch (error) {
        return false;
    }
};

export const doLogout = async (id) => {
    try {
        await post('/api/core/auth/user/logout');
        const storageItems = ['token', 'permission_hash', 'userId'];
        await AuthService.destroySession(id, storageItems);
        await PermissionService.logout();
    } catch (e) {
        console.log('Error: ', e);
    }
};
