import { DataService } from "./DataService";
import { hash as hashData, } from './CachingService';
import { get, post, put } from "../components/Magic/helpers/MagicRequest";
import {
    DISABLED_DELEGATION,
    GET_DELEGATION,
    GET_DELEGATION_CHANGE_USERS,
    UPDATE_DELEGATION
} from "../modules/admin_panel/delegation/apiRoutes";

class DelegationDataService extends DataService {
    constructor () {
        super([
            'delegation',
            'delegationsUsers',
            'updateDelegation',
            'disabledDelegation'
        ]);
        Object.freeze(this);
    }

    async delegation ({ filterId, take, skip }) {
        const url = GET_DELEGATION;
        const data = await get(url, { filterId, take, skip });
        const hash = await hashData(data);
        const delegation = { data, hash, options: { filterId, take, skip }, };
        await this.emit('delegation', delegation);

        return delegation;
    }

    async updateDelegation({ task_ids, user_id }) {
        const url = UPDATE_DELEGATION;
        const data = await post(url, { task_ids, user_id });
        const updateDelegation = { data, options: { task_ids, user_id }, };
        await this.emit('updateDelegation', updateDelegation);

        return updateDelegation;
    }

    async disabledDelegation({ task_ids }) {
        const url = DISABLED_DELEGATION;
        const data = await put(url, { task_ids });
        const disabledDelegation = { data, options: { task_ids, } };
        await this.emit('disabledDelegation', disabledDelegation);

        return disabledDelegation;
    }

    async delegationsUsers (taskId) {
        const url = `${ GET_DELEGATION_CHANGE_USERS }/${taskId}`;
        const data = await get(url);
        const hash = await hashData(data);
        const delegationsUsers = { data, hash, options: { taskId, } };
        await this.emit('delegationsUsers', delegationsUsers);

        return delegationsUsers;
    }
}

export const delegationService = new DelegationDataService();
