import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import { MagicTable } from '../../../../components/Magic';
import { MagicTooltip } from '../../../tima/components/Magic/MagicTooltip';

class MarketingStatisticsClicksMagicTable extends Component {
  constructor (props) {
    super(props);

    this.state = {
      loaded: false,
    };

    this.LENGTH_SHORT = 30;
  }

  save = async state => new Promise(next => this.setState(state, next));

  get accountUtmLinkTableConfig () {
    const { translate } = this.props;

    const prefixStatistics = 'statistics_';
    const prefixAccountUtmLink = 'statistics_account_utm_link_';
    const prefixCore = 'core_account_';

    return [
      {
        path: ['core', 'account', 'name'],
        key: 'userName',
      },
      {
        path: ['core', 'account', 'surname'],
        key: 'userSurname',
      },
      {
        path: ['statistics', 'account_utm_link', 'account_id'],
        key: 'accountId',
        render: this.renderInitialValue,
        title: `${ prefixAccountUtmLink }account_id`,
        orderId: 'o:accUtmLinkId',
      },
      {
        accessChecked: true,
        key: 'accountFullName',
        render: this.renderUserFullname,
        title: `${ prefixCore }name`,
        orderId: `o:coreAccName`,
      },
      {
        path: ['core', 'account_categories', 'category_id'],
        key: 'accountCategory',
        render: this.renderAccountCategory,
        title: `${ prefixCore }category_id`,
        orderId: `o:coreAccCategory`,
      },
      {
        path: ['core', 'account_categories', 'created_at',],
        key: 'accountCategoryChanged',
        render: this.renderInitialValue,
        title: 'client_category_date_changed',
        orderId: `o:coreAccCategoryCreatedAt`,
      },
      {
        path: ['core', 'country', 'name'],
        key: 'accountCountryName',
        render: this.renderInitialValue,
        title: `${ prefixCore }country_id`,
      },
      {
        path: ['statistics', 'utm_source', 'name'],
        key: 'utmLinkId',
        render: this.renderInitialValue,
        title: `${ prefixStatistics }channel`,
      },
      {
        path: ['statistics', 'utm_source', 'number'],
        key: 'utmSourceId',
        render: this.renderInitialValue,
        title: `${ prefixStatistics }utm_source_id`,
      },
      {
        path: ['statistics', 'utm_medium', 'name'],
        key: 'utmMediumName',
        render: this.renderInitialValue,
        title: `${ prefixStatistics }subchannel`,
      },
      {
        path: ['statistics', 'utm_medium', 'number'],
        key: 'utmMediumId',
        render: this.renderInitialValue,
        title: `${ prefixStatistics }utm_medium_id`,
      },
      {
        path: ['statistics', 'utm_campaign', 'name'],
        key: 'utmCampaignName',
        render: this.renderInitialValue,
        title: `${ prefixStatistics }product`,
      },
      {
        path: ['statistics', 'utm_campaign', 'number'],
        key: 'utmCampaignId',
        render: this.renderInitialValue,
        title: `${ prefixStatistics }utm_campaign_id`,
      },
      {
        path: ['core', 'account', 'created_at'],
        key: 'accountCreatedAt',
        render: this.renderInitialValue,
        title: `${ prefixAccountUtmLink }created_at`,
        orderId: `o:coreAccCreatedAt`,
      },
      {
        path: ['core', 'dictionary_licenses', 'alias'],
        key: 'accountLicense',
        render: this.renderInitialValue,
        title: `${ prefixCore }license`,
      }
    ].map(({
      orderId: o,
      title: t,
      xtitle: x,
      ...item
    }) => ({
      ...item,
      ...o && {
        order: async () => {
          await this.props.mf?.orderedToNext(o);
        },
        orderTo: this.props.mf?.orderedTo(o),
      },
      ...t && { title: translate(t), },
      ...x && { title: x },
    }));
  };

  renderLink = (text, to) => to ? <Link to={to} target="_blank">{text}</Link> : text;

  renderInitialValue = (value, { item }) => {
    if (item?.access?.('index')) {
      return (
        <MagicTooltip
          classNameTooltip={ `tooltip tooltip--large-width` }
          content={ value ?? '-' }
          lengthCut={ this.LENGTH_SHORT }
        />
      );
    }

    return '-';
  };

  renderUserFullname = (value, { items, }) => {
    const id = items?.accountId;
    const name = items?.userName;
    const surname = items?.userSurname;

    if (name?.access?.('index') && surname?.access?.('index')) {
      const LENGTH_SHORT = 20;
      let fullname = `${ surname?.valueOf || '' } ${ name?.valueOf || '' }`;
      fullname = (
        <MagicTooltip
          classNameTooltip={`tooltip tooltip--large-width`}
          content={ fullname }
          lengthCut={ LENGTH_SHORT }
        />
      );
      if (id?.access?.('show')) {
        const options = {
          style: {
            display:         '-webkit-box',
            WebkitLineClamp: '1',
            WebkitBoxOrient: 'vertical',
          },
          to: `/clients/${ +id?.valueOf }`,
        };
        return (<Link { ...options }>{ fullname }</Link>);
      } else {
        return fullname;
      }
    }
    return '-';
  };

  renderAccountCategory = (value, {
    item,
    items,
  }) => {
    if (item.access('index')) {
      const enums = this.props.enums?.core?.AccountCategoryEnum;
      const statusType = {
        1: 'bg-violet-blue',
        2: 'bg-shadow-blue',
        3: 'bg-grass-green',
        4: 'bg-deep-blue',
        5: 'bg-pink',
        6: 'bg-deep-yellow',
        7: 'bg-red-orange',
        8: 'bg-coral',
        9: 'bg-blood-red',
        10: 'bg-turquoise',
      };
      const categoryId = +value;
      const options = {
        className: `table-status ${ statusType?.[categoryId] }`,
      };
      const category = enums?.[+value];

      return (<div { ...options }>{ category }</div>);
    }

    return '-';
  };

  render () {
    const { data } = this.props;

    const options = {
      config: this.accountUtmLinkTableConfig,
      data: data,
      head: [
        'accountId',
        'accountFullName',
        'accountCategory',
        'accountCategoryChanged',
        'accountCountryName',
        'utmLinkId',
        'utmSourceId',
        'utmMediumName',
        'utmMediumId',
        'utmCampaignName',
        'utmCampaignId',
        'accountCreatedAt',
        'accountLicense',
      ]
    };

    return <MagicTable { ...options } />;
  }
}

export default withRouter(withLocalize(MarketingStatisticsClicksMagicTable));
