import React, { Component, } from 'react';
import { withRouter, } from "react-router-dom";
import { withLocalize, } from "react-localize-redux";

import * as configBreadcrumbs from './config/BreadcrumbsConfig';

import PartnersSystemHeader from "../../ components/PartnersSystemHeader";
import { HorizontalMenu, } from "../../../components";
import BannersTable from "./table/BannersTable";
import PermissionService from "../../../../../services/PermissionService";
import { showExportToExcelError, } from "../../../../../components/Magic";
import { BANNERS_EXPORT_FIELDS, } from "./consts/BANNERS_EXPORT_FIELDS";
import { securedRequest, } from "../../../../../services/RequestService";

class Banners extends Component {

	constructor (props) {
		super(props);

		this.state = {
			filter_id: 0,
			createUpdatePopup: {
				isOpen: false,
				data: null,
			},
			targetPagePopup: {
				isOpen: false,
				data: null,
			},
		};
	}

	save = async state => new Promise(next => this.setState(state, next));

	toggleBannerPopup = (isOpen, data) => {
		return this.save({ createUpdatePopup: { data, isOpen, }, });
	}

	toggleTargetPagePopup = (isOpen, data) => {
		return this.save({ targetPagePopup: { data, isOpen, }, });
	}
	get translate () {
		return this.props.translate;
	}
	getPermissions = (action) => {
		const pm = PermissionService.calc([ {
			path: [ 'partners', 'promo', ],
			key: 'user',
		}, ]);

		return pm.user.access(action);
	}
	exportToExcel = activeTab => (event) => {
		if (activeTab) {
			const { filter_id, } = this.state;

			const globalUrl = `/api/partners/promo`;
			const exportUrl = `/export-to-excel`;
			let url = "";
			let file_name = "";

			switch (activeTab) {
				case "banners": {
					url = `${ globalUrl }${ exportUrl }`;
					file_name = `Banners`;
					break;
				}
				default: {
					break;
				}
			}
			const options = {
				fields: BANNERS_EXPORT_FIELDS[activeTab],
				file_name,
			};

			if (filter_id) {
				options.filter_id = filter_id;
			}

			securedRequest
				.post(url, options)
				.then((res) => {
					fileDownload(res.data, res.headers.filename);
				})
				.catch((error) => {
					showExportToExcelError(error);
				});
		}
	};

	renderActions = (translate) => {
		const excel = this.getPermissions("exportToExcel");
		const createBanner = this.getPermissions('store');

		return (
			<div className=" top-button-area ">
				{excel && (
					<div
						className="button bordered "
						onClick={ this.exportToExcel("banners") }
					>
						{translate(`managerPage_export_to_excel`)}
					</div>
				)}
				{createBanner && <div
					className="button button--gradient-pink ml-3"
					onClick={ () => this.toggleBannerPopup(true) }
				                 >
					{translate("partners_banners_create")}
				</div>}
			</div>
		);
	};

	handleFilterIdChange = filter_id => this.save({ filter_id, });

	render () {
		const {
			translate, getPermissions, toggleBannerPopup, toggleTargetPagePopup,
		} = this;
		const { createUpdatePopup, targetPagePopup, } = this.state;
		const breadcrumbsItems = configBreadcrumbs.breadcrumbs4Banners;

		return (
			<div className="partner-promo">
				<PartnersSystemHeader
					actions={ this.renderActions(translate) }
					breadcrumbsItems={ breadcrumbsItems }
					title={ "partners_banners" }
					translate={ translate }
				/>
				<HorizontalMenu />
				<BannersTable
					createUpdatePopup={ createUpdatePopup }
					getPermissions={ getPermissions }
					handleFilterIdChange={ this.handleFilterIdChange }
					targetPagePopup={ targetPagePopup }
					toggleBannerPopup={ toggleBannerPopup }
					toggleTargetPagePopup={ toggleTargetPagePopup }
					translate={ translate }
				/>
			</div>
		);
	}
}

export default withLocalize(withRouter(Banners));