import React from 'react';
import * as PropTypes from 'prop-types';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Delta = Quill.import('delta');

export class MagicTemplate extends React.PureComponent {
    static defaultProps = {
        className:        [],
        placeholder:      '',
        value:            '',
        isDefaultToolbar: true,
        hiddenModules:    {},
        toolbar:          [],
    };

    static classNamePropType = PropTypes.string;
    static classNamePropTypes = PropTypes.oneOfType([
        MagicTemplate.classNamePropType,
        PropTypes.arrayOf(MagicTemplate.classNamePropType)
    ]);
    static placeholderPropTypes = PropTypes.string;
    static valuePropTypes = PropTypes.string;
    static hiddenModulesPropTypes = PropTypes.objectOf(PropTypes.bool);
    static propTypes = {
        className:     MagicTemplate.classNamePropTypes.isRequired,
        placeholder:   MagicTemplate.placeholderPropTypes.isRequired,
        value:         MagicTemplate.valuePropTypes.isRequired,
        handleChange:  PropTypes.func.isRequired,
        hiddenModules: MagicTemplate.hiddenModulesPropTypes,
        toolbar:       PropTypes.array, // link for possible values for toolbar https://quilljs.com/docs/modules/toolbar/
        onClick:       PropTypes.func,
        onValid:       PropTypes.func,
    };
    static reactQuillFormats = [
        "header",
        "bold",
        "italic",
        "underline",
        "align",
        "list",
        "indent",
        "link",
        "img",
        "video",
        "code",
        "script",
        "background",
        "color",
        "font",
        "size",
        "strike",
        "blockquote",
        "direction",
        "code-block",
        "formula"
    ];

    constructor (props) {
        super(props);
        this.state = {
            content: this.props.value,
            toolbar: {},
            isLoad:  false,
        };
    }

    async componentDidMount () {
        const toolbar = await this.quillToolbar();
        await this.save({
            toolbar,
            isLoad: true,
        });
    }

    async UNSAFE_componentWillReceiveProps (nextProps) {
        await this.save({ content: nextProps.value });
    }

    quillToolbar = () => {
        const { isDefaultToolbar, hiddenModules, toolbar } = this.props;
        const defaultToolbar = [
            [{ 'header': [1, 2, 3, false] }],
            ['bold', 'italic', 'underline'],
            [{ 'align': [] }],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
            ['link'],
            ['clean']
        ];
        const defaultHiddenModules = {
            img:          false,
            video:        false,
            code:         false,
            script:       false,
            background:   false,
            color:        false,
            font:         false,
            size:         false,
            strike:       false,
            blockquote:   false,
            direction:    false,
            formula:      false,
            'code-block': false,
        };

        return (
            {
                toolbar:   isDefaultToolbar ? defaultToolbar : toolbar,
                clipboard: {
                    matchers: [
                        [Node.ELEMENT_NODE, function(node, delta) {
                            return delta.compose(new Delta().retain(delta.length(), // this code check all formats and disable what you added in attributes with value false
                                isDefaultToolbar ? defaultHiddenModules : hiddenModules
                            ));
                        }]
                    ],
                },
            }
        );
    };

    save = async (state) => new Promise(next => this.setState(state, next));

    onChange = async (text) => {
        await this.save({ content: text });
        await this.handleSend();
    };

    handleSend = async () => {
        const { handleChange } = this.props;
        await handleChange(this.state.content);
    };

    render () {
        if (!this.state.isLoad) {
            return null;
        }
        const { className, placeholder } = this.props;
        const classNames = className instanceof Array ? className : [className];
        const options = {
            theme:     'snow',
            className: ['magic-quill', ...classNames].join(' '),
            formats:   MagicTemplate.reactQuillFormats,
            modules:   this.state.toolbar,
            placeholder,
            value:     this.state.content,
            onChange:  this.onChange,
        };

        return <ReactQuill { ...options } />;
    }
}
