const LinkConfig = (translate, order, permissions) =>
	[
		{
			id: "admin",
			link: "/settings",
			text: 'dsdsd',
		},
		{
			id: "activity-report",
			link: "/activity-report",
			text: translate("menu_admin_activity_report"),
		},
	]
		.filter(({ id, }) => order.includes(id))
		.sort(({ id: a, }, { id: b, }) => order.indexOf(a) - order.indexOf(b))
		.map(({ id, ...data }) => ({ ...data, key: id, }))
		.filter((item) => {
			if (permissions) {
				return permissions?.[item?.key]?.access("index") ?? false;
			}

			return true;
		});

export default LinkConfig;