import React from 'react';
import { Table } from 'reactstrap';
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import { withLocalize, Translate } from 'react-localize-redux';
import CheckUndefinedPermission from "../../../../components/Common/CheckUndefinedPermission";
import {Scrollbars} from "react-custom-scrollbars";
import deposit from "../../../../images/deposit.svg";

class TradingPaymentHistory extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            account:     {},
        };
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        this.setState({
            account: nextProps.account
        });
    }

    render () {

        return (
            <CheckUndefinedPermission>
                <Translate>
                    {({ translate }) => {
                        return (
                            <div className = 'block-shadowed'>
                                <span className = 'table-title account-title'>{translate(`trading_history`)}</span>
                                <div className = 'react-bs-table table-unbordered-container'>
                                    <Scrollbars
                                        style = { { height: 400 } }
                                        renderView={props => <div {...props} className="view"/>}
                                        renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                                    >
                                        <Table className="table react-bs-table">
                                            <thead className="react-bs-container-header">
                                                <tr>
                                                    <th>{translate(`trading_time`)}</th>
                                                    <th>{translate(`trading_type`)}</th>
                                                    <th>{translate(`trading_status`)}</th>
                                                    <th>{translate(`trading_system`)}</th>
                                                    <th>{translate(`trading_amount`)}</th>
                                                    <th>{translate(`trading_client`)}</th>
                                                    <th>{translate(`trading_card`)}</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr>
                                                    <td>2018/08/13, 13:15 pm</td>
                                                    <td><div className="type-cell"><img src={ deposit } alt="type"/> Withdrawal</div></td>
                                                    <td>Active</td>
                                                    <td>WebMoney</td>
                                                    <td>$ 1000</td>
                                                    <td>Ambros Johnson</td>
                                                    <td>333444555</td>
                                                </tr>

                                                <tr>
                                                    <td>2018/08/13, 13:15 pm</td>
                                                    <td><div className="type-cell"><img src={ deposit } alt="type"/> Withdrawal</div></td>
                                                    <td>Active</td>
                                                    <td>WebMoney</td>
                                                    <td>$ 1000</td>
                                                    <td>Ambros Johnson</td>
                                                    <td>333444555</td>
                                                </tr>

                                                <tr>
                                                    <td>2018/08/13, 13:15 pm</td>
                                                    <td><div className="type-cell"><img src={ deposit } alt="type"/> Withdrawal</div></td>
                                                    <td>Active</td>
                                                    <td>WebMoney</td>
                                                    <td>$ 1000</td>
                                                    <td>Ambros Johnson</td>
                                                    <td>333444555</td>
                                                </tr>

                                                <tr>
                                                    <td>2018/08/13, 13:15 pm</td>
                                                    <td><div className="type-cell"><img src={ deposit } alt="type"/> Withdrawal</div></td>
                                                    <td>Active</td>
                                                    <td>WebMoney</td>
                                                    <td>$ 1000</td>
                                                    <td>Ambros Johnson</td>
                                                    <td>333444555</td>
                                                </tr>

                                                <tr>
                                                    <td>2018/08/13, 13:15 pm</td>
                                                    <td><div className="type-cell"><img src={ deposit } alt="type"/> Withdrawal</div></td>
                                                    <td>Active</td>
                                                    <td>WebMoney</td>
                                                    <td>$ 1000</td>
                                                    <td>Ambros Johnson</td>
                                                    <td>333444555</td>
                                                </tr>

                                                <tr>
                                                    <td>2018/08/13, 13:15 pm</td>
                                                    <td><div className="type-cell"><img src={ deposit } alt="type"/> Withdrawal</div></td>
                                                    <td>Active</td>
                                                    <td>WebMoney</td>
                                                    <td>$ 1000</td>
                                                    <td>Ambros Johnson</td>
                                                    <td>333444555</td>
                                                </tr>

                                                <tr>
                                                    <td>2018/08/13, 13:15 pm</td>
                                                    <td><div className="type-cell"><img src={ deposit } alt="type"/> Withdrawal</div></td>
                                                    <td>Active</td>
                                                    <td>WebMoney</td>
                                                    <td>$ 1000</td>
                                                    <td>Ambros Johnson</td>
                                                    <td>333444555</td>
                                                </tr>

                                                <tr>
                                                    <td>2018/08/13, 13:15 pm</td>
                                                    <td><div className="type-cell"><img src={ deposit } alt="type"/> Withdrawal</div></td>
                                                    <td>Active</td>
                                                    <td>WebMoney</td>
                                                    <td>$ 1000</td>
                                                    <td>Ambros Johnson</td>
                                                    <td>333444555</td>
                                                </tr>

                                                <tr>
                                                    <td>2018/08/13, 13:15 pm</td>
                                                    <td><div className="type-cell"><img src={ deposit } alt="type"/> Withdrawal</div></td>
                                                    <td>Active</td>
                                                    <td>WebMoney</td>
                                                    <td>$ 1000</td>
                                                    <td>Ambros Johnson</td>
                                                    <td>333444555</td>
                                                </tr>

                                                <tr>
                                                    <td>2018/08/13, 13:15 pm</td>
                                                    <td><div className="type-cell"><img src={ deposit } alt="type"/> Withdrawal</div></td>
                                                    <td>Active</td>
                                                    <td>WebMoney</td>
                                                    <td>$ 1000</td>
                                                    <td>Ambros Johnson</td>
                                                    <td>333444555</td>
                                                </tr>

                                                <tr>
                                                    <td>2018/08/13, 13:15 pm</td>
                                                    <td><div className="type-cell"><img src={ deposit } alt="type"/> Withdrawal</div></td>
                                                    <td>Active</td>
                                                    <td>WebMoney</td>
                                                    <td>$ 1000</td>
                                                    <td>Ambros Johnson</td>
                                                    <td>333444555</td>
                                                </tr>

                                                <tr>
                                                    <td>2018/08/13, 13:15 pm</td>
                                                    <td><div className="type-cell"><img src={ deposit } alt="type"/> Withdrawal</div></td>
                                                    <td>Active</td>
                                                    <td>WebMoney</td>
                                                    <td>$ 1000</td>
                                                    <td>Ambros Johnson</td>
                                                    <td>333444555</td>
                                                </tr>

                                                <tr>
                                                    <td>2018/08/13, 13:15 pm</td>
                                                    <td><div className="type-cell"><img src={ deposit } alt="type"/> Withdrawal</div></td>
                                                    <td>Active</td>
                                                    <td>WebMoney</td>
                                                    <td>$ 1000</td>
                                                    <td>Ambros Johnson</td>
                                                    <td>333444555</td>
                                                </tr>

                                                <tr>
                                                    <td>2018/08/13, 13:15 pm</td>
                                                    <td><div className="type-cell"><img src={ deposit } alt="type"/> Withdrawal</div></td>
                                                    <td>Active</td>
                                                    <td>WebMoney</td>
                                                    <td>$ 1000</td>
                                                    <td>Ambros Johnson</td>
                                                    <td>333444555</td>
                                                </tr>

                                            </tbody>
                                        </Table>
                                    </Scrollbars>
                                </div>
                            </div>
                        );
                    }}
                </Translate>
            </CheckUndefinedPermission>
        );
    }
}

export default withLocalize(withRouter(connect(
)(TradingPaymentHistory)));