import React, { Component } from 'react';
import Loader               from "../../../tima/components/Loader";
import Preloader            from "../../../../components/LoadingHOC/Preloader";
import propTypes            from 'prop-types';
import moment from 'moment';
import NotificationService  from "../../../../services/NotificationService";
import PermissionService    from "../../../../services/PermissionService";
import { MagicButton }      from "../../../../components/Magic/components/MagicButton";
import editIcon             from "../../../../images/edit.svg";
import iconDownload         from "../../../../images/icon-file.svg";
import iconUpload           from "../../../../images/file.svg";
import { supportService }   from "../../../../services/SupportDataService";

export class TicketChat extends Component {

   static propTypes = {
      chat_messages: propTypes.array,
      cutText: propTypes.func,
      data: propTypes.object,
      downloadAndSaveFile: propTypes.func,
      getMessagesByTicketId: propTypes.func,
      getTicketById: propTypes.func,
      profile: propTypes.object,
      transalte: propTypes.func,
   }

   constructor(props) {
      super(props);
      this.editTextarea = React.createRef();
      this.lastEl = React.createRef();
      this.state = {
         ticket_id: +props?.id,
         profileId: 0,
         data: {},
         chat_messages: [],
         message: "",
         messageLength: "",
         uploadFiles: [],
         newFiles: false,
         textareaParams: {
            rows: 2,
            lineHeight: 15,
         },
         editId: 0,
         isEdited: false,
         isDisabled: false,
       }
   }

   UNSAFE_componentWillReceiveProps(nextProps) {
      if (this.state?.chat_messages !== nextProps?.chat_messages) {
         this.setState({chat_messages: nextProps?.chat_messages,});
      };
      if (this.state?.data !== nextProps?.data) {
         this.setState({data: nextProps?.data,});
      };
      if (this.state?.editId !== nextProps?.editId) {
         this.setState({editId: nextProps?.editId,});
      };
      if (this.state?.profileId !== +nextProps?.profile?.profileID) {
         this.setState({profileId: +nextProps?.profile?.profileID})
      };
   };

   save = async (state) => new Promise(resolve => this.setState(state, resolve));

   handleChange = event => {
      let e = event?.target;
      this.setState({
         message: e?.value?.trimStart?.(),
         messageLength: e?.value?.length,
      });
      const previousRows = e?.rows;
      const textareaLineHeight = 15;
      const maxRows = 8;
      event.target.rows = 2;
      const currentRows = (e?.scrollHeight / textareaLineHeight);
      if (currentRows === previousRows) {
         event.target.rows = currentRows;
      }
      if (currentRows >= maxRows) {
         event.target.rows = maxRows;
      }
      this.setState(state => ({
         textareaParams: {
            ...state.textareaParams,
            rows: currentRows < maxRows ? currentRows : maxRows,
         }
      }));
   }

   inputFilesHandler = event => {
      const {uploadFiles: oldFiles, isEdited} = this.state;
      const files = event?.target?.files?.[0];
      if (files?.size <= 10485760) {
         const uploadFiles = [...[...oldFiles], files];
         this.setState({uploadFiles})
      }
      isEdited ? this.setState({newFiles: true}) : false;
   }

   deleteFileMessage = async (fileID) => {
      const {getMessagesByTicketId} = this.props;
      const {ticket_id} = this.state;
      try {
         await this.save({isDisabled: true,});
         await supportService.deleteFileMessage(fileID);
         await getMessagesByTicketId(ticket_id);
         await this.save({message: "", uploadFiles: [], isEdited: false});
      } catch (error) {
        error?.showErrorNotification?.();
      } finally {
         await this.save({isDisabled: false,});
      }
   };

   createMessage = async () => {
      try {
         const formData = new FormData();
         const {data, ticket_id, message, uploadFiles,} = this.state;
         const {getMessagesByTicketId, getTicketById} = this.props;
         const user_id = data?.core?.user?.id;
         [
            ['ticket_id', ticket_id],
            ['from', 1],
            ['user_id', user_id]
         ]?.forEach(data => formData.append(data[0], data[1]));
         if (message?.length) {
            formData.append('text', message);
         }
         uploadFiles?.forEach(file => formData.append('files[]', file));
         await this.save({isDisabled: true,})
         const response = await supportService.createMessageByTicketId(formData);
         if (response?.ticket_id === ticket_id) {
            await getMessagesByTicketId(ticket_id);
            await this.save({ message: '', uploadFiles: [], isEdited: false,});
            await getTicketById(ticket_id);
         };
      } catch (error) {
        error?.showErrorNotification?.();
      } finally {
         await this.save({isDisabled: false,})
      }
   };

   checkEdit = () => {
      const lastMessage = [...this.state?.chat_messages]
         .find(elem => +elem?.tickets?.ticket_messages?.id === this.state?.editId);
      const {tickets: {ticket_messages: {text}, ticket_message_documents}} = lastMessage;
      const uploadFiles = [];
      if (ticket_message_documents !== null) {
         ticket_message_documents?.map?.(i => uploadFiles?.push?.({name: i?.file_name, ...i}));
      }
      this.setState({
         message: text,
         messageLength: text?.length,
         uploadFiles,
         isEdited: true,
      });
      this.editTextarea.current?.focus?.();
   };

   editMessage = async () => {
      const {getMessagesByTicketId} = this.props;
      const {isEdited, editId, message} = this.state;
      try {
         if (isEdited && editId !== 0) {
            let formData = new FormData();
            message?.length
               ? formData.append('text', message)
               : formData.append('SET_NULL__text', 1)
            formData.append('_method', 'PATCH');
            if (this.state.newFiles) {
               this.state?.uploadFiles
                     .filter(el => !(el?.hasOwnProperty?.('id')))
                     .forEach(f => formData.append('files[]', f));
            }
            await this.save({isDisabled: true,});
            await supportService.changeMessageById(this.state.editId, formData);
            await getMessagesByTicketId(this.state.ticket_id);
            await this.save({message: '', uploadFiles: [], isEdited: false, newFiles: false,});
         }
      } catch (error) {
         error?.showErrorNotification?.();
      } finally {
         await this.save({isDisabled: false,})
      }
   };

   get ticketChatConfig() {
      return [
         {
            path: ['core', 'account', 'name',],
            key: 'chatClientName',
         },
         {
            path: ['core', 'account', 'surname',],
            key: 'chatClientSurname',
         },
         {
            path: ['core', 'user', 'name',],
            key: 'chatManagerName',
         },
         {
            path: ['core', 'user', 'surname',],
            key: 'chatManagerSurname',
         },
         {
            path: ['core', 'user', 'id',],
            key: 'chatManagerId',
         },
         {
            path: ['tickets', 'ticket_messages', 'from',],
            key: 'chatAuthor',
         },
         {
            path: ['tickets', 'ticket_message_documents', 'alias_name'],
            key: 'chatFileAlias',
         },
         {
            path: ['tickets', 'ticket_message_documents', 'file_name',],
            key: 'chatFileName',
         },
         {
            path: ['tickets', 'ticket_messages', 'created_at',],
            key: 'chatMessageTime',
         },
         {
            path: ['tickets', 'ticket_messages', 'id',],
            key: 'chatMessageId',
         },
         {
            path: ['tickets', 'ticket_messages', 'text',],
            key: 'chatMessageText',
            render: this.renderChatMessage,
         },
         {
            path: ['tickets', 'ticket_messages', 'ticket_id',],
            key: 'chatTextarea',
            render: this.renderChatTextarea,
         },
      ]
   };

   renderChatMessage = (message, {item, items}) => {
     if(item?.access('show')) {
        const {profile, downloadAndSaveFile, cutText,} = this.props;
        const {editId} = this.state;
        const registerType = +this.state?.data?.core?.account?.register_type;
        const ticketStatus = +this.state.data?.tickets?.tickets?.status;
        const messageId = items?.chatMessageId?.valueOf;
        const capitalize = text =>`${text?.charAt?.(0)?.toUpperCase?.()}${text?.slice?.(1)}`;
        const clientName = registerType === 2
         ? `${capitalize(items?.chatClientName?.valueOf)}`
         : `${capitalize(items?.chatClientName?.valueOf)} ${capitalize(items?.chatClientSurname?.valueOf)}`;
        const managerName = `${items?.chatManagerName?.valueOf || ""} ${items?.chatManagerSurname?.valueOf || ""}`;
        const indexFrom = +items?.chatAuthor?.valueOf;
        const fileName = items?.chatFileName?.valueOf;
        const fileAlias = items?.chatFileAlias?.valueOf;
        const time = moment(items?.chatMessageTime?.valueOf).format('YYYY.MM.DD HH:mm:ss');
        const messageAuthor = () => {
           let authorName, authorSign;
           const editBtn = (item?.access('update') && editId === messageId
                              && this.props?.profile?.profileID === this.state?.data?.tickets?.tickets?.user_id
                              && ticketStatus === 2 && !profile?.isROP
                              && profile?.isTechnicalSupport)
              ? <img className='message_edit' src={editIcon} onClick={this.checkEdit}/>
              : false;
           switch (indexFrom) {
              case 1:
                 authorName = managerName;
                 authorSign = this.props.translate("support_card_manager_sign");
                 break;
              case 2:
                 authorName = clientName;
                 authorSign = this.props.translate("support_list_client");
                 break;
           };
           return (
              <div className="message_author">
                 {editBtn} { authorName || "" }
                 <span className='message_author-sign'>({ authorSign })</span>
              </div>
           )
        };
        return (
           <div className={ `body__item` }>
              <div className={ `item__content ${ indexFrom === 2 ? 'reverse' : '' }` }>
                 <div className="content__time">{ time }</div>
                 <div className="content__message">
                    {messageAuthor()}
                    <div className="message_text">{ message }</div>
                 </div>
              </div>
              <div className="item__files">
                 { fileAlias?.map((alias, i) => (
                    <figure key={i} className='file' onClick={ () => downloadAndSaveFile(alias, fileName[i]) }>
                       <img className='file__icon' src={ iconDownload } alt={ fileName[i] }/>
                       <figcaption className='file__desc'>
                          <a className='attached__files file__link'>{ cutText(fileName[i], 20) }</a>
                       </figcaption>
                    </figure>
                 ))}
              </div>
           </div>)
     }
     return "";
   };

   renderChatTextarea = (textarea, {item, items}) => {
      if(textarea === undefined ? item?.access('store') : item?.access('update')){
         // const managerId = items?.chatManagerId?.valueOf;
         const {message, uploadFiles, textareaParams, isEdited, editId, isDisabled, profileId} = this.state;
         const {cutText} = this.props;
         const ticketStatus = +this.state.data?.tickets?.tickets?.status;
         const userId = +this.state.data?.core?.user?.id;
         const options = {
            rows: textareaParams?.rows,
            style: {'lineHeight': `${textareaParams?.lineHeight}px`},
            ref: this.editTextarea,
            placeholder: this.props.translate('support_card_textarea_ph'),
            value: message,
            onChange: this.handleChange,
            className: "message__text scroll",
         }
         // тикет НЕ закрыт
         const case_1 = ticketStatus !== 5;
         // еслм менеджер ответственный
         const case_2 = profileId === userId;
         const addedFiles = () => {
            const File = ({file, index, isDisabled}) => {
               return (
                  <figure key={index} className={`file ${isDisabled ? 'disabled' : ''}`}>
                     <img className='file__icon' src={iconDownload} alt={file?.name} />
                     <span
                        className={`file__remove ${isDisabled ? 'disabled' : ''}`}
                        onClick={() => {
                           this.setState({uploadFiles: [...this.state?.uploadFiles]?.filter?.((_, i) => i !== index)});
                           (isEdited && editId !== 0) ? this.deleteFileMessage(file?.id) : false;
                        }}
                     > </span>
                     <figcaption className='file__desc'>
                        <a className='attached__files file__link'>{cutText(file?.name, 20)}</a>
                     </figcaption>
                  </figure>
               )
            }
            return (
               <React.Fragment>
                  <div className='upload__files'>
                     {uploadFiles?.map?.((file, index) => (
                        <File
                           file={file}
                           index={index}
                           isDisabled={this.state?.isDisabled}
                        />))}
                  </div>
                  <label >
                     {uploadFiles?.length < 5 &&
                     (<figure>
                        <img
                           className={`btn_upload ${isDisabled ? 'disabled' : ''}`}
                           src={iconUpload}
                           alt="add file"/>
                     </figure>)}
                     <input type="file"
                        disabled={isDisabled}
                        onChange={e => !isDisabled ? this.inputFilesHandler(e) : false}
                        className='visibility-hidden' />
                  </label>
               </ React.Fragment>
            )
         }
         const controlButtons = () => {
            const {messageLength} = this.state;
            const optionsCancel = {
               disabled: !message?.length && !uploadFiles?.length || isDisabled,
               className: ['magic-button__item_card_ticket-cancel cancel'],
               onClick: () => this.setState({
                  message: '',
                  uploadFiles: [],
                  isEdited: false,
                  newFiles: false
               }),
               children: this.props.translate('support_card_cancel'),
            };
            const optionsSend = {
               className: ['magic-button__item_card_ticket-send send'],
               onClick: () => {
                  if (message?.length > messageLength) {
                     NotificationService.error({
                        title: 'error',
                        message: this.props.translate('support_card_error_length')
                     });
                  } else {
                     isEdited ? this.editMessage() : this.createMessage();
                  }
               },
               disabled: !message?.length && !uploadFiles?.length || isDisabled,
               children: isEdited ? this.props.translate('support_card_message_edit') : this.props.translate('support_card_send'),
            };
            return (
               <React.Fragment>
                  <MagicButton {...optionsCancel} />
                  <MagicButton {...optionsSend} />
               </React.Fragment>
            )
         };
         if(case_1 && case_2) {
            return (
                  <div className="chat__type-message">
                     <div className="spiner"></div>
                     <textarea {...options} />
                     <div className="buttons">
                        {addedFiles()}
                        {controlButtons()}
                     </div>
                  </div>
            )
         }
      }
      return "";
   };

   render() {
      const loaded = Object.keys?.(this.props?.data)?.length > 0;
      const data = PermissionService.cast(this.state?.chat_messages.slice?.(1,this.state?.chat_messages?.length), this.ticketChatConfig, true);
      let textarea;
      const editChat = data?.find?.(({chatMessageId}) => chatMessageId?.valueOf === this.state?.editId);
      if(editChat) {
         textarea = editChat?.chatTextarea?.render?.();
      } else {
         const data = PermissionService.cast({}, this.ticketChatConfig, true);
         textarea = data?.chatTextarea?.render?.();
      }
      this.lastEl?.current?.scrollIntoView?.();
      return (
         <Loader loaded={loaded} loading={<Preloader scale={1}/>}>
            <div className="chat__wrapper">
               <div className="chat__body scroll">
                  { data?.map?.((el,i) => <React.Fragment key={i}>{el?.chatMessageText?.render()}</React.Fragment>)}
                  <div ref={this.lastEl}></div>
               </div>
               {textarea}
            </div>
         </Loader>
      )
   }
};
