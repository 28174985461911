import React, { Component, } from "react";
import { withRouter, Link, } from "react-router-dom";
import PropTypes from "prop-types";
import "./styles.scss";

class Dropdown extends Component {
    static defaultProps = {
        config: [],
        title: "",
    };

    static propTypes = {
        config: PropTypes.array.isRequired,
        title: PropTypes.string.isRequired,
    };

    constructor (props) {
        super(props);

        this.state = {
            activeItem: null,
        };
    }

    get config () {
        return this.props.config;
    }

    componentDidMount = () => {
        this.setActiveItem();
    };

    setActiveItem = () => {
        const { location, } = this.props;

        const url = location.pathname;
        const findActiveItem = this.config.find(({ path, }) => path === url);

        if (findActiveItem) {
            this.setState({ activeItem: findActiveItem, });
        }
    };

    renderTitle = () => {
        const { title, } = this.props;
        const { activeItem, } = this.state;

        return (
            <summary className="dropdown__title">
                <div className="title">
                    <div
                        className={ `dropdown__icon ${
                            activeItem ? "dropdown__icon--active" : ""
                        }` }
                    />
                    {title}
                    <span className="dropdown__arrow" />
                </div>
                {activeItem && <div className="subtitle">{activeItem.title}</div>}
            </summary>
        );
    };

    renderList = () => {
        const { activeItem, } = this.state;

        return (
            <ul className="dropdown__list">
                {this.config
                    .filter(({ key = null, }) => key !== activeItem?.key)
                    .map(({ title, path, key, }) => (
                        <li key={ key }>
                            <Link to={ path }>{title}</Link>
                        </li>
                    ))}
            </ul>
        );
    };

    render = () => {
        return (
            <details className="dropdown__wrapper">
                {this.renderTitle()}
                {this.renderList()}
            </details>
        );
    };
}

export default withRouter(Dropdown);