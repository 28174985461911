import { DateTime, } from 'luxon';
import { Config, } from '../Config';
import { valid, } from './Mentor.valid';
import { Observable, } from '../Observable';
import { operators as allOperators, } from '../Operators.js';
import { Options, TYPE, } from '../Options';
import { Service, } from '../Service';
import { Storage, } from '../Storage';
// import { StorageItemFilter, } from '../StorageItemFilter';
// import { StorageItemOrder, } from '../StorageItemOrder';
import SERVICES from '../cfg/new-index';
import { isArray, } from "lodash";

/**
 * @class
 */
export class Mentor extends Observable {

  /**
   * Creates as instance of Mentor
   *
   * @arg {Object} settings - {
   *   owner: Component,
   *   translate: Function,
   *   serviceId: Number,
   * }
   * @memberof Mentor
   */
	constructor (settings) {
		valid(settings);

		super({
			events: [
				'changing', 'changed',
				'cleaning', 'cleaned',
				'loading', 'loaded',
				'saving', 'saved',
			],
		});

		const { owner, serviceId, translate, } = settings;
		const SERVICE = SERVICES.find(_ => _.id === serviceId);

		if (!SERVICE) {
			throw new Error(`Mentor serviceId not found`);
		}

		const config = new Config({ ...SERVICE, translate, });
		const service = new Service(this);
		const storage = new Storage(this);

		Object.defineProperties(this, {
			config: { enumerable: true, get: () => config, },
			owner: { enumerable: true, get: () => owner, },
			service: { enumerable: true, get: () => service, },
			serviceId: { enumerable: true, get: () => serviceId, },
			storage: { enumerable: true, get: () => storage, },
			translate: { get: () => translate, },
		});

		let filterId = 0;
		let options = null;
		let userFilterId = 0;

		Object.defineProperties(this, {
			filterId: {
				enumerable: true,
				get: () => filterId,
				set: (_) => {
					filterId = _;
				},
			},
			changed: {
				enumerable: true,
				get: () => this.filters.some(_ => _.$$changed) || this.orders.some(_ => _.$$changed),
			},
			options: {
				enumerable: true,
				get: () => options,
				set: (_) => {
					options = _;
				},
			},
			userFilterId: {
				enumerable: true,
				get: () => userFilterId,
				set: (_) => {
					userFilterId = _;
				},
			},
		});
	}

  /**
   *
   * @returns {undefined}
   * @memberof Mentor
   */
	async actionAutoApply () {
		const isAuto = _ => _.auto && _.enabled && !_.$$parentId;
		const autoItems = this.storage.items.some(isAuto);

		if (this.filterId) {
      // auto apply needed only if no filters applied
		} else if (autoItems) {
			await this.apply({ doEmit: false, force: true, });
		}

		return this.changed;
	}

  /**
   *
   * @returns {undefined}
   * @memberof Mentor
   */
  // eslint-disable-next-line require-await, no-empty-pattern
	async actionLoadFromConfig () {
    // restore filters and orders state from config
		const filters = this.config.filters({ flat: false, single: false, });
		const orders = this.config.orders;
		const $$ = { changed: false, inited: true, };

		this.storage.unitems(() => false);

    // eslint-disable-next-line no-unused-vars
		for (const f of Array.isArray(filters) ? filters : []) {
			try {
				this.storage.filter = { ...f, $$, }; // new StorageItemFilter(f, this.storage, $$);
			} catch (error) {
				console.error(error);
			}
		}

    // eslint-disable-next-line no-unused-vars
		for (const o of Array.isArray(orders) ? orders : []) {
			try {
				this.storage.order = { ...o, $$, }; // new StorageItemOrder(o, this.storage, $$);
			} catch (error) {
				console.error(error);
			}
		}

		return this.changed;
	}

  /**
   *
   * @returns {undefined}
   * @memberof Mentor
   */
	async actionLoadFromDB () {
    // load current filters, filterId and userFilterId
		const {
			data,
			filterId,
			userFilterId,
		} = await this.service.loadUserFilter();
		const $$ = { changed: false, inited: true, };

		this.filterId = filterId;
		this.userFilterId = userFilterId;

    // eslint-disable-next-line no-unused-vars
		for (const d of Array.isArray(data) ? data : []) {
			try {
				if (d?.$?.type === 'filter') {
					this.storage.filter = { ...d, $$, }; // new StorageItemFilter(d, this.storage, $$);
				} else if (d?.$?.type === 'order') {
					this.storage.order = { ...d, $$, }; // new StorageItemOrder(d, this.storage, $$);
				}
        // else {
        //   console.log('mf.reset -> loadUserFilter -> data[] ->', { d, });
        // }
			} catch (error) {
				console.error(error);
			}
		}

		return this.changed;
	}

  /**
   *
   * @arg {Boolean} [doEmit=true] -
   * @returns {undefined}
   * @memberof Mentor
   */
	async apply ({ doEmit = true, force = false, }) {
		if (!this.inited()) {
			return undefined;
		} else if (!force && !this.changed) {
			return undefined;
		}

		const emit = {
			apply: true,
			filterId: this.filterId,
			userFilterId: this.userFilterId,
		};

		if (doEmit) {
			await this.emit('saving', emit);
		}

    // save filters
		const { filterId, data, } = await this.service.saveFilter();
		const $$ = { changed: false, inited: true, };

		for (const f of this.storage.filters) {
			if (f.$$changed) {
				this.storage.filter = { ...f.toJSON(), $$, };
			}
		}

		for (const o of this.storage.orders) {
			if (o.$$changed) {
				this.storage.order = { ...o.toJSON(), $$, };
			}
		}

		this.filterId = filterId;
    // eslint-disable-next-line no-unused-vars
		for (const d of Array.isArray(data) ? data : []) {
			try {
				if (d?.$?.type === 'filter') {
					this.storage.filter = { ...d, $$, }; // new StorageItemFilter(d, this.storage, $$);
				} else if (d?.$?.type === 'order') {
					this.storage.order = { ...d, $$, }; // new StorageItemOrder(d, this.storage, $$);
				}
        // else {
        //   console.log('mf.apply -> saveFilter -> data[] ->', { d, });
        // }
			} catch (error) {
				console.error(error);
			}
		}

    // bind filter to user
		const { userFilterId, } = await this.service.saveUserFilter();

		this.userFilterId = userFilterId;

		if (doEmit) {
			await this.emit('saved', emit);
		}
	}

  /**
   *
   * @arg {Boolean} [doEmit=true] -
   * @returns {undefined}
   * @memberof Mentor
   */
	async clean ({ doEmit = true, force = true, }) {
		if (!this.inited()) {
			return undefined;
		} else if (!force && !this.userFilterId) {
			return undefined;
		}

		const emit = {
			clean: true,
			filterId: this.filterId,
			userFilterId: this.userFilterId,
		};

		if (doEmit) {
			await this.emit('cleaning', emit);
		}

    // unbing service filter to user
		await this.service.unsaveUserFilter();
		this.filterId = 0;
		this.userFilterId = 0;

		await this.actionLoadFromConfig();
		await this.actionAutoApply();

		if (doEmit) {
			await this.emit('cleaned', emit);
		}
	}

  /**
   *
   * @arg {String} id - filterId
   * @returns {StorageItemFilter} StorageItemFilter
   * @memberof Mentor
   */
	filter (id) {
		return this.inited() ? this.storage.filter({ id, }) : null;
	}

  /**
   *
   * @arg {String} id - filterId
   * @arg {Object} setter - new values
   * @arg {String[]} unsetter - keys to remove
   * @arg {Boolean} [doEmit=true] - flag
   * @returns {undefined} - async filterChange
   * @memberof Mentor
   */
	async filterChange ({
		id,
		setter = {},
		unsetter = [],
		doEmit = true,
		$$changed = false,
		$$inited = false,
	}) {

		const filter = this.filter(id);

		if (!filter) {
			return;
		}
		const emit = {
			filter: true,
			id,
			setter,
			unsetter,
		};

		if (doEmit) {
			await this.emit('changing', emit);
		}

    // TODO: type cast in setter ???
		let { v, } = setter;

		const cast = (_) => {
			const format = {
				[TYPE.date]: 'yyyy-MM-dd',
				[TYPE.datetime]: 'yyyy-MM-dd HH:mm:ss',
			}[filter.type];

			if ([ null, undefined, ].includes(v)) {

				return v;
			} else if (_ instanceof Date && format) {

				return DateTime.fromJSDate(_).toFormat(format);
			} else if ([ 'number', 'string', ].includes(typeof _)) {

				return _;
			}

			return null;
		};
		const $$ = { changed: $$changed, inited: $$inited, };

		v = Array.isArray(v) ? v.map(cast) : cast(v);

		this.storage.filter = filter.$$({
			...setter,
			...v !== undefined && { v, },
		}, unsetter, $$);

		if (doEmit) {
			await this.emit('changed', emit);
		}
	}

  /**
   *
   * @arg {Boolean} [doEmit=true] - flag
   * @returns {Boolean} inited
   */
	async init ({ doEmit = true, }) {
		const emit = {
			init: true,
			serviceId: this.serviceId,
		};

		if (doEmit) {
			await this.emit('loading', emit);
		}

		try {
			const filterOptions = await this.service.loadFilterOptions();

			this.options = new Options(filterOptions);
		} catch (error) {
			console.log({ error, });
			this.options = null;
			this.filterId = this.userFilterId = 0;
		}
		await this.reset({ doEmit: false, });

		if (doEmit) {
			await this.emit('loaded', emit);
		}

		return this.inited();
	}

  /**
   *
   * @returns {Boolean} options inited
   */
	inited () {
		return !!this.options;
	}

  /**
   *
   * @returns {Object[]} exporting items array
   */
	itemsToExport () {
		const filters = this.storage.filters.map((_) => {
			let json = _.toJSON();

			const c0 = !_.$$child;
			const c1 = _.$$child && !Array.isArray(_.$$child);
			const c2 = _.$$child && Array.isArray(_.$$child);
      // eslint-disable-next-line no-shadow
			const off = _ => _.empty && !_.enabled;
      // eslint-disable-next-line no-shadow, no-extra-parens
			const half = _ => (_.empty && _.enabled) || (_.filling && !_.filled);

			if (_.$$parentId) {
        // child are exporting with there parents
				json = undefined;
			} else if (
        // empty, diabled and child free are not exporting
        // eslint-disable-next-line no-extra-parens
				(off(_) && c0)
        ||
        // empty, diabled and with empty and diabled child are not exporting
        // eslint-disable-next-line no-extra-parens
        (off(_) && c1 && off(_.$$child))
        ||
        // epmty, diabled and with empty and diabled childs are not exporting
        // eslint-disable-next-line no-extra-parens
        (off(_) && c2 && _.$$child.every(off))
			) {
				json = undefined;
			} else if (
				half(_)
        ||
        // eslint-disable-next-line no-extra-parens
        (c1 && off(_))
        ||
        // eslint-disable-next-line no-extra-parens
        (c2 && off(_))
        ||
        // eslint-disable-next-line no-extra-parens
        (c1 && off(_.$$child))
        ||
        // eslint-disable-next-line no-extra-parens
        (c1 && half(_.$$child))
        ||
        // eslint-disable-next-line no-extra-parens
        (c2 && _.$$child.every(off))
        ||
        // eslint-disable-next-line no-extra-parens
        (c2 && _.$$child.every(half))
			) {
				json = {
					...json,
					$: { ...json.$, enabled: 1, },
					enabled: 0,
				};
			}

			return json;
		}).filter(_ => _);
		const orders = this.storage.orders.map((_) => {
			let json = _.toJSON();

			if (!_.enabled) {
        // disabled are not exporting
				json = undefined;
			}

			return json;
		}).filter(_ => _);

		return { filters, orders, };
	}

  /**
   *
   * @arg {String} operator -
   * @returns {Operator} Operator
   * @memberof Mentor
   */
	Operator (operator) {
		return allOperators.find(operator);
	}

  /**
   *
   * @arg {String} id - orderId
   * @returns {StorageItemOrder} StorageItemOrder
   * @memberof Mentor
   */
	order (id) {
		return this.inited() ? this.storage.order({ id, }) : null;
	}

  /**
   * Get order direction by orderId
   *
   * @arg {String} id -
   * @returns {'asc' | 'desc' | null} order direction
   * @memberof Mentor
   */
	orderedTo (id) {
    // before: service.orderTo(field)
		const order = this.order(id);

		return order && order.enabled ? order.value : null;
	}

  /**
   * Set next order direction by orderId
   *
   * @arg {String} id - orderId
   * @arg {Boolean} [doEmit=true] - flag do emit changing/changed/saving/saved
   * @returns {undefined} async set next order direction
   * @memberof Mentor
   */
	async orderedToNext (id, doEmit = true) {
		const order = this.order(id);
		const $$ = { changed: true, inited: false, };

		if (!order) {
			return;
		}
    // eslint-disable-next-line no-unused-vars
		const ordersEnabled = this.orders.filter(_ => _.enabled && _.$id !== id);

		for (const o of ordersEnabled) {
			this.storage.order = o.$$({ enabled: 0, }, [], { ...$$, });
		}
		const emit = { id, orderedToNext: true, };

		const setter = {};

		if (!order.enabled) {
			Object.assign(setter, { enabled: 1, v: 'asc', });
		} else if (order.enabled && order.v === 'asc') {
			Object.assign(setter, { enabled: 1, v: 'desc', });
		} else if (order.enabled && order.v === 'desc') {
			Object.assign(setter, { enabled: 0, v: 'asc', });
		} else {
			return undefined;
		}

		if (doEmit) {
			await this.emit('changing', { ...emit, ...setter, });
		}

		this.storage.order = order.$$({ ...setter, }, [], { ...$$, });

		if (doEmit) {
			await this.emit('changed', { ...emit, ...setter, });
		}

		if (doEmit) {
			await this.emit('saving', emit);
		}

		await this.apply({ doEmit: false, });

		if (doEmit) {
			await this.emit('saved', emit);
		}
	}

  /**
   * Get panel by panelId and map items to StorageItem and get enabled status
   *
   * @arg {String} id - panelId
   * @returns {PanelItem} panel item by id with enabled and items as filters
   * @memberof Mentor
   */
	panel (id) {
		if (!this.inited()) {
			return null;
		}

		const panel = this.config.panel(id);

		if (!panel) {
			return null;
		}

    // eslint-disable-next-line no-shadow
		const cast = (_, cast) => Array.isArray(_) ? _.map(cast) : cast(_);
    // eslint-disable-next-line no-shadow
		const every = (_, every) => Array.isArray(_) ? _.every(every) : every(_);
    // const some = (_, some) => Array.isArray(_) ? _.some(some) : some(_);
		const { items, ..._ } = this.config.panel(id);

		if (!items) {
			return null;
		}

    // eslint-disable-next-line no-shadow
		const filters = cast(items, _ => this.filter(_));

		if (!filters) {
			return null;
		}

		return {
			..._,
      // eslint-disable-next-line no-shadow
			$enabled: every(filters, _ => _.$enabled) ? 1 : 0,
      // eslint-disable-next-line no-shadow
			empty: every(filters, _ => _.empty) ? 1 : 0,
      // eslint-disable-next-line no-shadow
			enabled: every(filters, _ => _.enabled) ? 1 : 0,
      // eslint-disable-next-line no-shadow
			filled: every(filters, _ => _.filled) ? 1 : 0,
      // eslint-disable-next-line no-shadow
			filling: every(filters, _ => _.filling) ? 1 : 0,
			items: filters,
			label: panel.label(id),
		};
	}

  /**
   * Reset filters and orders to saved in DB or Config
   *
   * @arg {Boolean} [doEmit=true] - flag do emit loading/loaded
   * @returns {undefined} async reset of filters and orders
   * @memberof Mentor
   */
	async reset ({ doEmit = true, }) {
		if (!this.inited()) {
			return undefined;
		}

		const emit = {
			filterId: this.filterId,
			reset: true,
			userFilterId: this.userFilterId,
		};

		if (doEmit) {
			await this.emit('loading', emit);
		}

		await this.actionLoadFromConfig();
		await this.actionLoadFromDB();
		await this.actionAutoApply();

		if (doEmit) {
			await this.emit('loaded', emit);
		}

		return this.changed;
	}

  /**
   * Get Mentor data as json for debug
   *
   * @returns {JSON} for debug
   * @memberof Mentor
   */
	toJSON () {
		return {
			changed: this.changed,
			filterId: this.filterId,
			inited: this.inited(),
			serviceId: this.serviceId,
			userFilterId: this.userFilterId,
			...this.component && { component: `${ this.component }`, },
			...this.config && { config: this.config.json, },
			...this.options && { options: this.options.json, },
			...this.service && { service: true, },
			...this.storage && { storage: this.storage.json, },
			...this.translate && { translate: typeof this.translate, },
		};
	}

  /**
   *
   * @readonly
   * @memberof Mentor
   */
	get json () {
		return this.toJSON();
	}

  /**
   *
   * @readonly
   * @memberof Mentor
   */
	get filters () {
		return this.inited() ? this.storage.filters : [];
	}

  /**
   *
   * @readonly
   * @memberof Mentor
   */
	get orders () {
		return this.inited() ? this.storage.orders : [];
	}

  /**
   *
   * @readonly
   * @memberof Mentor
   */
	get panels () {
		return this.inited()
			? this.config.panels.map(p => this.panel(p.id)).filter(_ => _)
			: [];
	}
};