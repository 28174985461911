import React from 'react';
import { withRouter, } from 'react-router-dom';
import { getClientAsync, getAccountAddresses } from '../../services/AccountService';
import { withLocalize } from 'react-localize-redux';
import LeadComponent from './LeadComponent';
import Preloader from "../../../../components/LoadingHOC/Preloader";
import Loader from "../../../tima/components/Loader";
import { enumService } from "../../../../services/EnumDataService";
import NoAccess from './NoAccess';

class Lead extends React.Component {
    state = {
        userId: this.props.match.params.id,
        loaded: false,
        data: false,
    };

    save = async (state) => new Promise(next => this.setState(state, next));

    enumsChange = async (enums) => {
        if (enums.hash===this.state?.enums?.hash) return `${this.constructor.name}.enumsChange: false`;
        await this.save({ enums, });
        return `${this.constructor.name}.enumsChange: true`;
    };

    async componentDidMount () {
        enumService.subscribe('enums', this.enumsChange, this);
        await enumService.enums;
        await this.onDataLoad();
    }

    componentWillUnmount () {
        enumService.unsubscribe('enums', this.enumsChange, this);
    }

    // TODO: onDataLoad renew style
    onDataLoad = () => {
        // const setState = async( state ) => new Promise( resolve => this.setState( state, resolve ) );
        const setLoaded = async( loaded ) => await this.save( { loaded, } );
        const setData = async( data ) => await this.save( { data, } );

        setLoaded(false);

        (async () => {
            try {
                const response = await getClientAsync(this.state.userId);
                const { data } = response;

                await setData( data );
            } catch (error) {
                await setData( false );
            }

            const cacheAddresses = this.state.data.core.account_addresses;
            const response = await getAccountAddresses(this.state.userId);
            const { data } = response;

            data.map(item => cacheAddresses.push(item.core.account_addresses));
            await this.save({
                data: {
                    ...this.state.data,
                    core: {
                        ...this.state.data.core,
                        account_addresses: cacheAddresses
                    }
                }
            });
            setLoaded(true);
        })();
    };

    render() {
        const { activeTab } = this.props;
        const { data, loaded } = this.state;

        if ((loaded && data === false) || data.length === 0) {
            return (<NoAccess translate = {this.props.translate} />);
        }

        return (
            <Loader
                loaded={loaded}
                loading={(<Preloader scale={1}/>)}
            >
                <LeadComponent
                    dataAfterLoading={data}
                    refreshClientData={this.onDataLoad}
                    activeTab={activeTab}
                    enums={this.state?.enums?.data}
                />
            </Loader>
        );
    }
}

export default withLocalize(withRouter(Lead));
