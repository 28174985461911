const OPERATORS = [{
    aliases: ['<->',],
    amountOfValues: 2,
    css: 'between',
    name: 'between',
}, {
    aliases: ['not_equal', '!equal',],
    amountOfValues: 1,
    css: 'not-equal',
    name: '!=',
}, {
    aliases: ['equal',],
    amountOfValues: 1,
    css: 'equal',
    name: '=',
}, {
    aliases: ['!@', '!in',],
    amountOfValues: Infinity, // nolimits for amount
    css: 'not-in',
    name: 'not_in',
}, {
    aliases: ['@',],
    amountOfValues: Infinity, // nolimits for amount
    css: 'in',
    name: 'in',
}, {
    aliases: ['less_equal', 'le',],
    amountOfValues: 1,
    css: 'less-or-equal',
    name: '<=',
}, {
    aliases: ['lt',],
    amountOfValues: 1,
    css: 'less',
    name: '<',
}, {
    aliases: ['!#', '!null',],
    amountOfValues: 0, // nolimits for amount
    css: 'not-null',
    name: 'not_null',
}, {
    amountOfValues: 0, // nolimits for amount
    aliases: ['#',],
    css: 'null',
    name: 'null',
}, {
    amountOfValues: 1,
    aliases: ['!~',],
    css: 'not-like',
    name: 'not_like',
}, {
    amountOfValues: 1,
    aliases: ['~',],
    css: 'like',
    name: 'like',
}, {
    aliases: ['more_equal', 'ge', 'greater_equal', 'larger_equal', 'greater-or-equal', 'larger-or-equal',],
    amountOfValues: 1,
    css: 'more-or-equal',
    name: '>=',
}, {
    aliases: ['gt', 'greater', 'larger',],
    amountOfValues: 1,
    css: 'more',
    name: '>',
},].map(item => ({
    ...item,
    aliases: new Set([ ...item.aliases, item.name, item.css]),
    css: item.css,
    name: item.name,
    operator: item.name,
})).map(item => Object.create(item, {
    aliases: { get: () => [ ...item.aliases ], enumerable: true, },
    amountOfValues: { get: () => item.amountOfValues, enumerable: true, },
    css: { get: () => item.css, enumerable: true, },
    is: { get: () => (operator) => item.aliases.has(operator) },
    name: { get: () => item.name, enumerable: true, },
    operator: { get: () => item.name, enumerable: true, },
    toJSON: { get: function() { return { ...this, }; }, },
    toString: { get: () => function () { return this.operator; }, },
    valueOf: { get: function() { return { ...this, }; }, },
}));

export const operators = Object.create({}, {
    aliases: { get: () => [].concat( ...OPERATORS.map(o => o.aliases) ), },
    names: { get: () => OPERATORS.map(o => o.name), },
    operators: { get: () => OPERATORS.map(o => o.operator), },
    is: { get: () => (operator) => OPERATORS.some(o => o.is(operator)), },
    toJSON: { get: () => OPERATORS.map(o => o.toJSON), },
    toString: { get: () => () => OPERATORS.map(o => `${ o }`).join(', '), },
    find: {
        get: () => function (options, empty=false) {
            if (options instanceof Function) {
                return OPERATORS.find(options);
            } else if (options instanceof Array) {
                return options.map(options => this.find(options)).filter(o => empty || o);
            } else if (options) {
                return OPERATORS.find(o => o.is(options));
            }
            return undefined;
        },
    },
});
