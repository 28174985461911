import {
	GET_ACCOUNT_GROUPS_LISTS,
	GET_PARTNERS_REQUESTS,
	GET_PARTNERS_REFERRALS,
	// GET_PARTNERS_REFERRALS_BY_PARTNER,
	// GET_PARTNERS_STATISTIC,
	GET_PARTNERS_ACCRUALS,
	// GET_USERS_LIST,
	GET_PARTNERS_LIST,
	GET_PARTNERS_GROUPS,
	GET_PARTNERS_SYMBOLS,
	// GET_PARTNERS_CONDITIONS,
	CHECK_ACCOUNT_REFERRAL,
	CHECK_ACCOUNT_PARTNER,
	CHECK_SYSTEM_ACCOUNT_REFERRAL,
	CHECK_SYSTEM_ACCOUNT_PARTNER,
	CREATE_PARTNERS_REFERRAL,
	SYMBOLS,
	GET_SYMBOLS_GROUPS,
	// GET_SYMBOLS_GROUPS_LIST,
	// PUT_SYMBOLS,
	GET_CRONS,
	// DEL_PARTNERS_SYMBOLS,
	GET_TARGET_PAGES,
	PARTNERS_SYMBOLS_LISTS,
	GET_PAYOUT_RATE_LISTS_ALL,
	GET_PAYOUT_RATE_LISTS,
	GET_ALL_COUNTRIES,
	GET_ALL_COUNTRIES_IS_WORK,
	GET_PARTNERS_PROGRAMS,
	GET_PARTNERS_AVAILABLE_CURRENCIES,
	GET_PARTNERS_SETTING,
	GET_PARTNERS_CURRENCY,
	GET_PARTNERS_PARTNERS,
	GET_REBATE_PROJECTS,
	GET_PAYOUT_RATE_RANGES,
	GET_PARTNERS_PROGRMAS_SETTING,
	GET_USERS_DROPDOWN,
	GET_ALL_USERS_BY_DEPARTMENT,
	GET_DEPARTMENTS,
	GET_NEW_PARTNERS_CONDITION_LISTS_ALL,
	GET_NEW_PARTNERS_CONDITION_LISTS,
	GET_NEW_PARTNERS_CONDITION_LISTS_CPA_SINGLE,
	GET_NEW_PARTNERS_CONDITION_LISTS_INOUT_SINGLE,
	GET_SYMBOLS_LIST,
	GET_NEW_PARTNERS_CONDITION_LISTS_REVSHARE_SINGLE,
	GET_NEW_PARTNERS_CONDITION_LISTS_CPL_SINGLE,
	GET_NEW_PARTNERS_CONDITION_LISTS_CPA_MULTIPLE,
	GET_NEW_PARTNERS_CONDITION_LISTS_CPL_MULTIPLE,
	GET_NEW_PARTNERS_CONDITION_LISTS_INOUT_MULTIPLE,
	GET_NEW_PARTNERS_CONDITION_LISTS_REVSHARE_MULTIPLE,
	GET_NEW_AFFILIATE_PROGRAMS_LISTS_ALL,
	GET_NEW_AFFILIATE_PROGRAMS_LISTS,
	GET_PROMO,
	GET_TARGET_PAGES_DROPDOWN,
	GET_PARTNER_DATA,
	GET_PARTNERS_REFERRALS_BY_PARTNER,
	GET_PARTNERS_ACCRUALS_BY_PARTNER,
	CHANGE_PARTNER_PROGRAMM,
	GET_TARGET_PAGE_LANGUAGES,
	GET_TARGET_PAGE_NAME,
	MOVE_ALL_PARTNERS_TO_PROGRAMM,
	GET_PROMOTIONL_CAMPAIGNS,
	GET_LOGOS,
	GET_TARGET_PAGE_NAME_DROPDOWN,
	GET_LANGUAGE,
	GET_LENDINGS,
	GET_LENDING_LANGUAGES,
	GET_CHANGE_PROGRAM,
	GET_REFERRAL_REGISTER,
	GET_DASHBOARD_CALCULATION_REASON,
	GET_DASHBOARD_CALCULATION_PAYOUT_REASON,
	GET_CHANGE_REFFERAL,
	GET_DASHBOARD_CALCULATION_PAYOUT_REASON_RESTART,
	GET_DASHBOARD_CALCULATION_REASON_RESTART,
} from '../apiRoutes';

import {
	get, post, put, del, patch,
} from "../components/Magic";

export const getPartnerData = async id =>
	await get(`${ GET_PARTNER_DATA }/${ id }/get-info`);

export const getPartnersRequests = async options =>
	await get(`${ GET_PARTNERS_REQUESTS }`, options);

export const getAllUsersByDepartment = async (alias, options) =>
	await get(`${ GET_ALL_USERS_BY_DEPARTMENT }/${ alias }`, options);

export const getRequest = async id =>
	await get(`${ GET_PARTNERS_REQUESTS }/${ id }`);

export const getUsersDropdown = async options =>
	await get(`${ GET_USERS_DROPDOWN }`, options);

export const editRequest = async (id, obj) =>
	await put(`${ GET_PARTNERS_REQUESTS }/${ id }`, obj);

export const getDepartments = async obj =>
	await get(`${ GET_DEPARTMENTS }`, obj);

export const getCrons = async options =>
	await get(`${ GET_CRONS }`, options);

// export const putPartnersRequests = async (id, obj) =>
//   await put(`${ GET_PARTNERS_REQUESTS }/${ id }`, obj);

export const getPartnersReferrals = async options =>
	await get(`${ GET_PARTNERS_REFERRALS }`, options);

export const getPartnersReferralsByPartner = async (partnerId, options) =>
	await get(`${ GET_PARTNERS_REFERRALS_BY_PARTNER }/${ partnerId }`, options);

// export const getPartnersStatistic = async (options) =>
//   await get(`${ GET_PARTNERS_STATISTIC }`, options);

// export const putPartnersStatistic = async (id, obj) =>
//   await put(`${ GET_PARTNERS_STATISTIC }/${ id }`, obj);

export const getPartnersAccruals = async options =>
	await get(`${ GET_PARTNERS_ACCRUALS }`, options);

export const getPartnersAccrualsByPartner = async (partnerId, options) =>
	await get(`${ GET_PARTNERS_ACCRUALS_BY_PARTNER }/${ partnerId }`, options);
export const getPartnersList = async () => await get(`${ GET_PARTNERS_LIST }`);

export const createPartnersAccrual = async obj =>
	await post(`${ GET_PARTNERS_ACCRUALS }`, obj);

export const putPartnersAccruals = async (id, obj) =>
	await put(`${ GET_PARTNERS_ACCRUALS }/${ id }`, obj);

// export const getUsersList = async () =>
//   await get(`${ GET_USERS_LIST }`);

// /* partners admin panel */
// export const getPartnersGroups = async (options) =>
//   await get(`${ GET_PARTNERS_GROUPS }`, options);

export const createPartnersGroup = async (obj) => {
	return await post(`${ GET_PARTNERS_GROUPS }`, obj);
};

export const updatePartnersGroup = async (id, obj) => {
	return await put(`${ GET_PARTNERS_GROUPS }/${ id }`, obj);
};

export const deletePartnersGroup = async (id) => {
	return await del(`${ GET_PARTNERS_GROUPS }/${ id }`);
};

export const getPartnersSymbols = async (options) => {
	return await get(`${ GET_PARTNERS_SYMBOLS }`, options);
};

// export const getPartnersConditions = async (options) =>
//   await get(`${ GET_PARTNERS_CONDITIONS }`, options);

// export const createPartnersCondition = async (obj) =>
//   await post(`${ GET_PARTNERS_CONDITIONS }`, obj);

export const checkAccountReferral = async id =>
	await get(`${ CHECK_ACCOUNT_REFERRAL }/${ id }`);

export const checkAccountPartner = async id =>
	await get(`${ CHECK_ACCOUNT_PARTNER }/${ id }`);

export const checkSystemAccountReferral = async id =>
	await get(`${ CHECK_SYSTEM_ACCOUNT_REFERRAL }/${ id }`);

export const checkSystemAccountPartner = async id =>
	await get(`${ CHECK_SYSTEM_ACCOUNT_PARTNER }/${ id }`);

export const createPartnersReferral = async obj =>
	await post(`${ CREATE_PARTNERS_REFERRAL }`, obj);

export const createSymbol = async (options) => {
	return await post(`${ SYMBOLS }`, options);
};

export const editSymbol = async (id, obj) => {
	return await put(`${ SYMBOLS }/${ id }`, obj);
};

// export const asyncGetSymbolsGroups = async (options) =>
//   await get(`${ GET_SYMBOLS_GROUPS_LIST }`, options);

export const asyncGetSymbols = async (options) => {
	return await get(`${ SYMBOLS }`, options);
};

export const asyncDelSymbols = async (id) => {
	return await del(`${ SYMBOLS }/${ id }`);
};

export const getSymbolsList = async (options) => {
	return await get(`${ GET_PARTNERS_SYMBOLS }`, options);
};

export const deleteSymbolsList = async (id) => {
	await del(`${ PARTNERS_SYMBOLS_LISTS }/${ id }`);
};

export const createPartnersSymbol = async (obj) => {
	return await post(PARTNERS_SYMBOLS_LISTS, obj);
};

export const putPartnersSymbol = async (id, obj) => {
	return await put(`${ PARTNERS_SYMBOLS_LISTS }/${ id }`, obj);
};

export const asyncGet = async (url) => {
	return await get(url);
};

export const asyncGetSymbolsGroups = async options =>
	await get(`${ GET_SYMBOLS_GROUPS }`, options);
export const getPromotionalCampaignsData = async (id, options) =>
	await get(`${ GET_PROMOTIONL_CAMPAIGNS }/${ id }`, options);

export const deleteSymbolsGroups = async (id) => {
	await del(`${ GET_SYMBOLS_GROUPS }/${ id }`);
};

export const editSymbolGroup = async (id, obj) => {
	return await put(`${ GET_SYMBOLS_GROUPS }/${ id }`, obj);
};

export const createSymbolGroup = async (options) => {
	return await post(`${ GET_SYMBOLS_GROUPS }`, options);
};

export const getAccountGroupsList = async (options) => {
	return await get(`${ GET_ACCOUNT_GROUPS_LISTS }`, options);
};

export const createPayoutRateList = async (obj) => {
	return await post(`${ GET_PAYOUT_RATE_LISTS }`, obj);
};
export const editPayoutRateList = async (id, obj) => {

	return await put(`${ GET_PAYOUT_RATE_LISTS }/${ id }`, obj);
};
export const getPayoutRateLists = async (options) => {
	return await get(`${ GET_PAYOUT_RATE_LISTS_ALL }`, options);
};

export const deleteCountryPayoutRateList = async (id) => {
	return await del(`${ GET_PAYOUT_RATE_LISTS }/${ id }`);
};

export const getAllCountries = async (options) => {
	return await get(`${ GET_ALL_COUNTRIES }`, options);
};

export const getAllCountriesIsWork = async (options) => {
	return await get(`${ GET_ALL_COUNTRIES_IS_WORK }`, options);
};

export const editCountryPayoutRateList = async (id) => {
	return await get(`${ GET_PAYOUT_RATE_LISTS }/${ id }`);
};

export const getCountryPayoutRateList = async (id) => {
	return await get(`${ GET_PAYOUT_RATE_LISTS }/${ id }`);
};

export const getPartnersAvailableCurrencies = async (options) => {
	return await get(`${ GET_PARTNERS_AVAILABLE_CURRENCIES }`, options);
};

export const getPartnersPrograms = async (options) => {
	return await get(`${ GET_PARTNERS_PROGRAMS }`, options);
};

export const getPartnersProgramsSetting = async (options) => {
	return await get(`${ GET_PARTNERS_PROGRMAS_SETTING }`, options);
};

export const getPartnersCurrency = async (options) => {
	return await get(`${ GET_PARTNERS_CURRENCY }`, options);
};

export const putPartnersCurrency = async (options) => {
	return await put(`${ GET_PARTNERS_CURRENCY }`, options);
};
export const postPartnersCurrency = async (obj) => {
	return await post(`${ GET_PARTNERS_PROGRMAS_SETTING }`, obj);
};

export const getPartnersParters = async (options) => {
	return await get(`${ GET_PARTNERS_PARTNERS }`, options);
};
export const editCountryPayoutRanges = async (id) => {
	return await get(`${ GET_PAYOUT_RATE_RANGES }/${ id }`);
};

/// NEW_PARTNERS_CONDITION /////
export const getNewPartnersList = async (options) => {
	return await get(`${ GET_NEW_PARTNERS_CONDITION_LISTS_ALL }`, options);
};
export const getNewAffiliateProgramsList = async (options) => {
	return await get(`${ GET_NEW_AFFILIATE_PROGRAMS_LISTS_ALL }`, options);
};
export const editNewAffiliateProgramsList = async (id) => {
	return await get(`${ GET_NEW_AFFILIATE_PROGRAMS_LISTS }/${ id }`);
};

export const getSingleNewPartnerConditionList = async (params) => {
	return await get(`${ GET_NEW_PARTNERS_CONDITION_LISTS }/${ params }`);
};

export const editNewPartnerConditionList = async (params, obj) => {
	return await patch(`${ GET_NEW_PARTNERS_CONDITION_LISTS }/${ params }`, obj);
};

export const deleteNewPartnerConditionList = async (id) => {
	return await del(`${ GET_NEW_PARTNERS_CONDITION_LISTS }/${ id }`);
};

export const createNewPartnersConditionCpaSingle = async (obj) => {
	return await post(`${ GET_NEW_PARTNERS_CONDITION_LISTS_CPA_SINGLE }`, obj);
};

export const createNewPartnersConditionCpaMultiple = async (obj) => {
	return await post(`${ GET_NEW_PARTNERS_CONDITION_LISTS_CPA_MULTIPLE }`, obj);
};
export const createNewPartnersConditionCplMultiple = async (obj) => {
	return await post(`${ GET_NEW_PARTNERS_CONDITION_LISTS_CPL_MULTIPLE }`, obj);
};
export const createNewPartnersConditionInOutSingle = async (obj) => {
	return await post(`${ GET_NEW_PARTNERS_CONDITION_LISTS_INOUT_SINGLE }`, obj);
};
export const createNewPartnersConditionRevShare = async (obj) => {
	return await post(`${ GET_NEW_PARTNERS_CONDITION_LISTS_REVSHARE_SINGLE }`, obj);
};
export const createNewPartnersConditionInOutMultiple = async (obj) => {
	return await post(`${ GET_NEW_PARTNERS_CONDITION_LISTS_INOUT_MULTIPLE }`, obj);
};
export const createNewPartnersConditionReMultiple = async (obj) => {
	return await post(`${ GET_NEW_PARTNERS_CONDITION_LISTS_REVSHARE_MULTIPLE }`, obj);
};

export const createNewPartnersConditionСplSingle = async (obj) => {
	return await post(`${ GET_NEW_PARTNERS_CONDITION_LISTS_CPL_SINGLE }`, obj);
};

export const createAffiliateProgram = async (obj) => {
	return await post(`${ GET_PARTNERS_PROGRAMS }`, obj);
};
export const editAffiliateProgram = async (id, obj) => {
	return await put(`${ GET_PARTNERS_PROGRAMS }/${ id }`, obj);
};

export const getBanners = async (obj) => {
	return await get(`${ GET_PROMO }`, obj);
};
export const getSingleBanner = async (id) => {
	return await get(`${ GET_PROMO }/${ id }`);
};

export const createBanner = async (obj) => {
	return await post(`${ GET_PROMO }`, obj);
};
export const updateBanner = async (id, obj) => {
	return await post(`${ GET_PROMO }/${ id }`, obj);
};

export const deleteBanner = async (id) => {
	return await del(`${ GET_PROMO }/${ id }`);
};

export const getTargetPagesDropdown = async () => {
	return await get(`${ GET_TARGET_PAGES_DROPDOWN }`);
};

export const editPartnerData = async (id, obj) => {
	return await put(`${ GET_PARTNER_DATA }/${ id }`, obj);
};

export const changePartnerProgramm = async (obj) => {
	return await post(`${ CHANGE_PARTNER_PROGRAMM }`, obj);
};
export const moveAllPartnersToProgramm = async (obj) => {
	return await post(`${ MOVE_ALL_PARTNERS_TO_PROGRAMM }`, obj);
};

export const getTargetPages = async (obj) => {
	return await get(`${ GET_TARGET_PAGES }`, obj);
};
export const getSingleTargetPage = async (id, obj) => {
	return await get(`${ GET_TARGET_PAGES }/${ id }`, obj);
};
export const updateTargetPage = async (id, obj) => {
	return await put(`${ GET_TARGET_PAGES }/${ id }`, obj);
};
export const createTargetPage = async (obj) => {
	return await post(`${ GET_TARGET_PAGES }`, obj);
};
export const updateTargetPageLanguage = async (id, obj) => {
	return await put(`${ GET_TARGET_PAGE_LANGUAGES }/${ id }`, obj);
};
export const createTargetPageLanguage = async (obj) => {
	return await post(`${ GET_TARGET_PAGE_LANGUAGES }`, obj);
};

export const deleteTargetPageLanguage = async (id) => {
	return await del(`${ GET_TARGET_PAGE_LANGUAGES }/${ id }`);
};
export const updateTargetPageName = async (id, obj) => {
	return await put(`${ GET_TARGET_PAGE_NAME }/${ id }`, obj);
};
export const deleteTargetPageName = async (id) => {
	return await del(`${ GET_TARGET_PAGE_NAME }/${ id }`);
};
export const createTargetPageName = async (obj) => {
	return await post(`${ GET_TARGET_PAGE_NAME }`, obj);
};
export const getTargetPageNames = async (id) => {
	return await get(`${ GET_TARGET_PAGE_NAME_DROPDOWN }?target_page_id=${ id }`);
};

export const getLogos = async (obj) => {
	return await get(`${ GET_LOGOS }`, obj);
};
export const getSingleLogo = async (id, obj) => {
	return await get(`${ GET_LOGOS }/${ id }`, obj);
};

export const createLogo = async (obj) => {
	return await post(`${ GET_LOGOS }`, obj);
};
export const updateLogo = async (id, obj) => {
	return await post(`${ GET_LOGOS }/${ id }`, obj);
};
export const getLendings = async (obj) => {
	return await get(`${ GET_LENDINGS }`, obj);
};
export const getSingleLending = async (id, obj) => {
	return await get(`${ GET_LENDINGS }/${ id }`, obj);
};

export const createLending = async (obj) => {
	return await post(`${ GET_LENDINGS }`, obj);
};
export const updateLending = async (id, obj) => {
	return await post(`${ GET_LENDINGS }/${ id }`, obj);
};

export const getLendingLanguages = async (obj) => {
	return await get(`${ GET_LENDING_LANGUAGES }`, obj);
};
export const getSingleLendingLanguage = async (id, obj) => {
	return await get(`${ GET_LENDING_LANGUAGES }/${ id }`, obj);
};

export const createLendingLanguage = async (obj) => {
	return await post(`${ GET_LENDING_LANGUAGES }`, obj);
};
export const updateLendingLanguage = async (id, obj) => {
	return await post(`${ GET_LENDING_LANGUAGES }/${ id }`, obj);
};
export const deleteLendingLanguage = async (id) => {
	return await del(`${ GET_LENDING_LANGUAGES }/${ id }`);
};

export const getSystemLanguages = async (obj) => {
	return await get(`${ GET_LANGUAGE }`, obj);
};


export const rebateProjects = async (obj) => {
	return await get(`${ GET_REBATE_PROJECTS }`, obj);
};

export const getReferralRegister = async (obj) => {
	return await get(`${ GET_REFERRAL_REGISTER }`, obj);
};
export const getDashboardCalculation = async (obj) => {
	return await get(`${ GET_DASHBOARD_CALCULATION_REASON }`, obj);
};
export const getDashboardCalculationPayout = async (obj) => {
	return await get(`${ GET_DASHBOARD_CALCULATION_PAYOUT_REASON }`, obj);
};
export const getPartnersWaitingList = async (obj) => {
	return await get(`${ GET_CHANGE_PROGRAM }`, obj);
};
export const getRefferalsWaitingList = async (obj) => {
	return await get(`${ GET_CHANGE_REFFERAL }`, obj);
};
export const restartConditionCalculation = async (id) => {
	return await post(`${ GET_DASHBOARD_CALCULATION_REASON_RESTART }/${ id }/restart`);
};
export const restartConditionPayoutCalculation = async (id) => {
	return await post(`${ GET_DASHBOARD_CALCULATION_PAYOUT_REASON_RESTART }/${ id }/restart`);
};