import React from "react";

import fire from "../../../../images/fire.svg";
import status from "../../../../images/status.svg";
import StatusSelect from "./StatusSelect";
import ImportanceSelect from "./ImportanceSelect";
//import TypeBlockSelect from "./TypeBlockSelect";
import { getImportant, getStatus, getType } from "../../services/TaskHelperService";
import hourglass from "../../../../images/hourglass.svg";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import DataStorage from "../../../../helpers/DataStorage";
import _ from 'lodash';
import moment from "moment";


class StatusBlock extends React.Component {
    constructor (props) {
        super(props);

        this.lang = DataStorage.getData('locale');
        this.handleChangeType = {
            importance: "urgency_id",
            status:     "state_id",
            types:      "type_id",
        };
        this.state = {
            type_:            [],
            state_:           [],
            urgency_:         [],
            createTaskSelect: this.props.createTaskSelect,
            dataTask:         { ...this.props.dataTask },
        };
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        this.setState({
            dataTask: { ...nextProps.dataTask },
        });
    }

    handleChange = (type) => {
        return (value) => {
            this.props.setToStateSelect(value, type);
        };
    };

    render () {

        return (
            <div className = 'flex-wrapper lead-block taskSmallFixed'>
                <div className = 'user-info select-block'>
                    <div className = 'grey-title'>
                        <div className = 'icon-wrapper'>
                            <img alt = 'hourglass' src = { hourglass } />
                        </div>
                        { this.props.translate(`tasks_createform_deadline`) }
                    </div>
                    <DatePicker
                        className = { `statusBlock-picker-${this.props.error('finish_date')}` }
                        selected = { new Date( _.at( this.props, 'task.tasks.tasks.finish_date' ) || this.props.selected ) }
                        onChange = { this.props.handleChangeData }
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode = 'select'
                        showTimeSelect
                        timeFormat = 'HH:mm'
                        timeIntervals = { 15 }
                        dateFormat = 'yyyy-MM-dd'
                        timeCaption = 'time'
                        minDate = { moment().add(-1, 'week').toDate() }
                    />
                </div>

                <div className = 'user-info select-block'>
                    <div className = 'grey-title'>
                        <div className = 'icon-wrapper'>
                            <img alt = 'status' src = { fire } />
                        </div>
                        { this.props.translate(`tasks_createform_important`) }
                    </div>
                    <ImportanceSelect
                        data = { this.state.urgency_ }
                        dataTask = { this.state.dataTask }
                        error = { this.props.error }
                        getData = { getImportant(this) }
                        handleChangeImportance = { this.handleChange(this.handleChangeType.importance) }
                        task = { this.props.task }
                        translate = { this.props.translate }
                    />
                </div>

                {/*<div className = 'user-info select-block'>
                    <div className = 'grey-title'>
                        <div className = 'icon-wrapper'>
                            <img alt = 'type' src = { type } />
                        </div>{ this.props.translate(`tasks_createform_type`) }</div>
                    <TypeBlockSelect
                        data = { this.state.type_ }
                        dataTask = { this.state.dataTask }
                        error = { this.props.error }
                        getData = { getType(this) }
                        handleChangeTypes = { this.handleChange(this.handleChangeType.types) }
                        task = { this.props.task }
                        translate = { this.props.translate }
                    />
                </div>*/}

                <div className='user-info select-block'>
                    <div className='grey-title'>
                        <div className='icon-wrapper'>
                            <img alt='status' src={ status } />
                        </div>
                        {this.props.translate(`tasks_createform_status`)}
                    </div>
                    <StatusSelect
                        data = { this.state.state_ }
                        dataTask = { this.state.dataTask }
                        error = { this.props.error }
                        getData = { getStatus(this) }
                        handleChangeStatus = { this.handleChange(this.handleChangeType.status) }
                        task = { this.props.task }
                        translate = { this.props.translate }
                        userRoleId = { this.props.userRoleId }

                    />
                </div>

            </div>
        );
    }
}

export default StatusBlock;
