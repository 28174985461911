const affiliateProgramsManagmentChildren = [
	{
		to: '/partners/affiliate-programs-management/affiliate-programs',
		path: [ 'partners', 'programs', ],
	},
	{
		to: '/partners/affiliate-programs-management/conditions-for-accruals',
		path: [ 'partners', 'new_partners_conditions', ],
	},
	{
		to: '/partners/affiliate-programs-management/payout-rate-lists',
		path: [ 'partners', 'payout_rate_lists', ],
	},
	{
		to: '/partners/affiliate-programs-management/symbols',
		path: [ 'partners', 'symbols', ],
	},
	{
		to: '/partners/affiliate-programs-management/symbols-groups',
		path: [ 'partners', 'symbols_groups', ],
	},
	{
		to: '/partners/affiliate-programs-management/symbols-lists',
		path: [ 'partners', 'symbols_lists', ],
	},
	{
		to: '/partners/affiliate-programs-management/account-groups-lists',
		path: [ 'partners', 'groups_lists', ],
	},
];

export default affiliateProgramsManagmentChildren;