import * as actions from './actionTypes';
import { securedRequest } from '../../services/RequestService/index';
import {
    GET_USERS_CATEGORY,
    GET_DASHBOARD_ACCOUNT,
    GET_USERS_WARMING,
    GET_ACCOUNTS,
    ACTION_SEND_EMAILS, GET_USERS_EXCEL, SET_SMS,
    ACTION_SEND_EMAILS_BY_ACCOUNT,
    ACTION_SEND_SMS,
    ACTION_SEND_SMS_BY_ACCOUNT,
    GET_SALES_FUNNEL,
    GET_ALL_MANAGERS,
} from "../../apiRoutes";
import _ from "lodash";
import fileDownload from "js-file-download";
import { post, get } from '../../components/Magic/helpers/MagicRequest';
import { cachingService, } from '../../services/CachingService';
import { getMailTemplates, } from '../clients/services/AccountRequestService.js';

export const setLayoutsData = (data) => ({
    type: actions.SET_LAYOUTS,
    data,
});

export const setLayouts = (data) => (dispatch) => {
    dispatch(setLayoutsData(data));
};

export const getBriefcase = (data) => ({
    type: actions.SET_BRIEFCASE,
    data,
});

export const getUsersCategory = (data) => ({
    type: actions.SET_USERS_CATEGORY,
    data,
});

export const getUsersWarming = (data) => ({
    type: actions.SET_USERS_WARMING,
    data,
});

export const getDashboardAccount = (data) => ({
    type: actions.SET_DASHBOARD_ACCOUNT,
    data,
});

export const getEmailTemplates = (data) => ({
    type: actions.SET_MAIL_TEMPLATES,
    data,
});

export const getSmsTemplates = (data) => ({
    type: actions.SET_SMS_TEMPLATES,
    data,
});
export const filterOptions = (data) => ({
    type: actions.GET_OPTIONS,
    data,
});

export const getAllManagers = async () => {
    try {
        const data = await get(`${GET_ALL_MANAGERS}`);

        return data?.data;
    } catch (error) {}
};

export const setBriefcase = (data) => (dispatch) => {
    const params = new URLSearchParams(data);
    return securedRequest.get(`${GET_SALES_FUNNEL}?${params.toString()}`)
        .then((response) => {
            dispatch(getBriefcase(response.data));
        })
        .catch((error) => {
            console.log('error', error);
        });
};

export const setUsersCategory = (data) => (dispatch) => {
    return securedRequest.post(`${GET_USERS_CATEGORY}/?take=50`, data)
        .then((response) => {
            dispatch(getUsersCategory(response.data));
        })
        .catch((error) => {
            console.log('error', error);
        });
};

export const setMailTemplates = () => async (dispatch) => {
    try {
        const response = await getMailTemplates();
        dispatch(getEmailTemplates(response));
    } catch (error) {
        console.log('error', error);
    }
};

export const setSmsTemplates = (
    payload = 'data=[{"f":"core.sms_templates.active","enabled":1, "j":"n","o":"=","v":"1"},{"connect": "pgsql"}]'
) => async (dispatch) => {
    try {
        const { id } = await cachingService.session(`${SET_SMS}/filter`, { payload, method: 'POST', });
        const response = await cachingService.session(`${SET_SMS}/filter/${ id }`);
        dispatch(getSmsTemplates(response));
    } catch (error) {
        console.log('error', error);
    }
};

export const setUsersCategoryFiltered = (params = 'data=[]', data) => (dispatch) => {
    return securedRequest.post(`${GET_ACCOUNTS}/filter`, params)
        .then((response) => {
            data.filterId = response.data.id;
            securedRequest.post(`${GET_USERS_CATEGORY}?take=50`, data).then((response) => {
                dispatch(getUsersCategory(response.data));
            });
        })
        .catch((error) => {
            console.log('error', error);
        });
};

export const getFilteredData = (params = 'data=[]', data) => (dispatch) => {
    return securedRequest.post(`${GET_ACCOUNTS}/filter`, params)
        .then((response) => {
            data.filterId = response.data.id;
            securedRequest.post(`${GET_USERS_CATEGORY}`, data).then((response) => {
                dispatch(getUsersCategory(response.data));
            });
        })
        .catch((error) => {
            console.log('error', error);
        });
};

export const setUsersWarming = (data) => (dispatch) => {
    return securedRequest.post(`${GET_USERS_WARMING}`, data)
        .then((response) => {
            dispatch(getUsersWarming(response.data));
        })
        .catch((error) => {
            console.log('error', error);
        });
};

export const setDashboardAccount = (id) => (dispatch) => {
    return securedRequest.get(`${GET_DASHBOARD_ACCOUNT}/${id}`)
        .then((response) => {
            dispatch(getDashboardAccount(response.data));
        })
        .catch((error) => {
            console.log('error', error);
        });
};

export const getFilterOptions = (output = 'take=0&skip=0') => (dispatch) => {
    // TODO: remove function as old
    return securedRequest.get(`${GET_ACCOUNTS}?${output}`)
        .then((response) => {
            dispatch(filterOptions(response.data));
        })
        .catch((error) => {
            console.log('error', error);
        });
};

export const sendEmails = async (data) => post(ACTION_SEND_EMAILS, data);

export const sendEmailsByAccountId = (data) => {
    return securedRequest.post(ACTION_SEND_EMAILS_BY_ACCOUNT, data)
        .then(
            (response) => Promise.resolve(response.data),
            (error) => Promise.reject(error.response.data)
        );
};


export const sendSms = (params = 'data=[]', data) => {
    return securedRequest.post(`${GET_ACCOUNTS}/filter`, `data=${params}`)
        .then((response) => {
            data['filter_id'] = response.data.id;
            securedRequest.post(ACTION_SEND_SMS, data)
                .then(
                    (response) => Promise.resolve(response.data),
                    (error) => Promise.reject(error.response.data)
                );
        })
        .catch((error) => {
            console.log('error', error);
        });
};

export const sendSmsByAccountId = (data) => {
    return securedRequest.post(ACTION_SEND_SMS_BY_ACCOUNT, data)
        .then(
            (response) => Promise.resolve(response.data),
            (error) => Promise.reject(error.response.data)
        );
};

export const getUsersExcel = (data) => {
    return securedRequest.post(GET_USERS_EXCEL, data, { responseType: 'blob' })
        .then((res) => {
            // console.log(res);
            fileDownload(res.data, res.headers.filename);
        });
};
