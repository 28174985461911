import React, { Component, } from 'react';
import { withRouter, } from 'react-router-dom';
import { withLocalize, } from "react-localize-redux";
import '../styles/delegation.scss';
import Modal from "./Modal";
import NotificationService from "../../../../services/NotificationService";
import Preloader from "../../../../components/LoadingHOC/Preloader";
import Loader from "../../../tima/components/Loader";
import PagePanel from "../../../tima/components/Pages";
import {
  MagicFilterPanels,
  Mentor,
} from "../../../../components/Magic";
import { MagicTable, } from "../../../../components/Magic";
import { MagicTooltip, } from "../../../tima/components/Magic/MagicTooltip";
import { MagicSwitch, } from "../../../tima/components/Magic/MagicSwitch";
import PermissionService from '../../../../services/PermissionService';
import { delegationService } from "../../../../services/DelegationDataService";

const IS_DELEGATE_TRUE = '1';
const IS_DELEGATE_FALSE = '2';

class Delegation extends Component {

  constructor (props) {
    super(props);

    this.state = {
      delegation: {
        data: [],
        hash: null,
        options: {},
      },
      delegationFormShow: false,
      filterLastChange: Date.now(),
      loaded: false,
      pages: {
        filter: 0,
        take: 50,
        total: 0,
        variance: 2,
      },
      selected: {
        accountId: props?.location?.state?.selected?.accountId ?? 0,
        taskIds: props?.location?.state?.selected?.taskIds ?? [],
        user: props?.location?.state?.selected?.user ?? {},
        userId: props?.location?.state?.selected?.userId ?? 0,
      },
    };

    const mf = new Mentor({
      owner: this,
      serviceId: 40,
      translate: this.props.translate,
    });

    Object.defineProperty(this, 'mf', { get: () => mf, });
  }

  // eslint-disable-next-line react/sort-comp, require-await
  save = async state => new Promise(next => this.setState(state, next));

  // eslint-disable-next-line require-await
  mfChanged = async () => {
    await this.save({ filterLastChange: Date.now(), });
  };

  mfDoing = async () => {
    await this.save({ loaded: false, });
  };

  delegationChanges = async ({ data, hash, options, }) => {
    if (hash===this.state?.delegation?.hash) {
        return `${this.constructor.name}.delegationChanges: false`;
    }
    if (options.filterId!==this.state?.delegation?.options?.filterId) {
        return `${this.constructor.name}.delegationChanges: false`;
    }
    if (options.take!==this.state?.delegation?.options?.take) {
        return `${this.constructor.name}.delegationChanges: false`;
    }
    if (options.skip!==this.state?.delegation?.options?.skip) {
        return `${this.constructor.name}.delegationChanges: false`;
    }
    const delegation_user = data?.data?.[0]?.core?.user ?? {};
    await this.save({
      delegation: {
        data: data.data,
        hash,
        options,
      },
      pages: {
        ...this.state.pages,
        filter: data?.meta?.filter,
        total: data?.meta?.total,
      },
      delegation_user,
      checkAll: IS_DELEGATE_FALSE,
    });
    return `${this.constructor.name}.delegationChanges: true`;
  };

  mfDone = async () => {
    await this.mfChanged();
    await this.onDataLoad();
  };

  async componentDidMount () {
    delegationService.subscribe('delegation', this.delegationChanges, this);
    this.mf.subscribe({
      changed: this.mfChanged,
      // changing: ,
      cleaned: this.mfDone,
      cleaning: this.mfDoing,
      loaded: this.mfDone,
      loading: this.mfDoing,
      saved: this.mfDone,
      saving: this.mfDoing,
    }, this);
    await this.mf.init({ doEmit: true, });
    // await this.mf.emit('change', {});
    // await this.onDataLoad(); // TODO: moved to mfDone
  };

  componentWillUnmount () {
    this.mf.unsubscribe([
      this.mfChanged,
      this.mfDoing,
      this.mfDone,
    ], this);
    delegationService.unsubscribe('delegation', this.delegationChanges, this);
  }

  get params () {
    return this.props.match.params;
  }

  get query () {
    return new URLSearchParams(this.props.location.search);
  }

  get delegationConfig () {
    const { translate, } = this.props;

    return [ {
      key: 'accountId',
      path: [ 'core', 'account', 'id', ],
    }, {
      key: 'accountName',
      path: [ 'core', 'account', 'name', ],
    }, {
      key: 'accountSurname',
      path: [ 'core', 'account', 'surname', ],
    }, {
      key: 'userId',
      path: [ 'core', 'user', 'id', ],
    }, {
      key: 'userName',
      path: [ 'core', 'user', 'name', ],
    }, {
      key: 'userSurname',
      path: [ 'core', 'user', 'surname', ],
    }, {
      key: 'userThroughputCount',
      path: [ 'core', 'user', 'count_tasks_to_date', ],
    }, {
      key: 'userThroughputTotal',
      path: [ 'core', 'user', 'throughput', ],
    }, {
      key: 'userThroughputPercent',
      path: [ 'core', 'user', 'throughput_percent', ],
    }, {
      key: 'taskId',
      path: [ 'tasks', 'tasks', 'id', ],
    }, {
      key: 'userToId',
      path: [ 'tasks', 'task_delegation', 'user_id', ],
    }, {
      key: 'userToThroughputTotal',
      path: [ 'tasks', 'task_delegation_user', 'throughput', ],
    }, {
      key: 'userToThroughputDay',
      path: [ 'tasks', 'task_delegation_user', 'throughput_day', ],
    }, {
      accessChecked: true,
      key: 'taskSelected',
      render: this.renderTaskSelected,
      title: 'delegation_table_check_delegation',
    }, {
      accessChecked: true,
      key: 'userFullname',
      render: this.renderUserFullname,
      title: 'delegation_table_manager',
    }, {
      accessChecked: true,
      key: 'userToFullname',
      path: [ 'tasks', 'task_delegation_user', 'string', ],
      render: this.renderUserToFullname,
      title: 'delegation_table_manager_delegation',
    }, {
      accessChecked: true,
      key: 'userPosition',
      path: [ 'core', 'departments', 'names', ],
      render: this.renderUserPosition,
      title: 'delegation_table_position',
    }, {
      accessChecked: true,
      key: 'userToThroughput',
      render: this.renderUserToThroughput,
      title: 'delegation_table_throughput',
    }, {
      key: 'taskDeadline',
      path: [ 'tasks', 'tasks', 'finish_date', ],
      render: this.renderInlineValue,
      title: 'delegation_table_finish_date',
    }, {
      accessChecked: true,
      key: 'accountFullname',
      render: this.renderAccountFullname,
      title: 'delegation_table_client',
    }, {
      key: 'taskDescription',
      path: [ 'tasks', 'tasks', 'description', ],
      render: this.renderTaskDescription,
      title: 'delegation_table_task_description',
    }, {
      key: 'taskStatus',
      path: [ 'tasks', 'task_states', 'name' ],
      render: this.renderInlineValue,
      title: 'delegation_table_status',
    }, {
      key: 'taskUrgency',
      path: [ 'tasks', 'task_urgency', 'name' ],
      render: this.renderInlineValue,
      title: 'delegation_table_importance',
    }, ].map(({
      orderId: o,
      title: t,
      xtitle: x,
      ...item
    }) => ({
      ...item,
      ...o && {
        order: async () => {
          await this.mf.orderedToNext(o);
          // await this.onDataLoad(); // TODO: remove cause reaction should be in subscribe
        },
        orderTo: this.mf.orderedTo(o),
      },
      ...t && { title: translate(t), },
      ...x && { title: x, },
    }));
  }

  selectTask = async ({
    taskId = [],
    accountId = 0,
    userId = 0,
    user = {},
  }) => {
    const select = ({ taskIds = [], }) => {
      const data = { accountId, user, userId, };

      taskIds = [ ...taskIds ?? [], ...taskId, ];

      return { taskIds, ...taskIds.length === 1 && data, };
    };

    await this.save(({
      selected: s,
    }) => ({
      selected: {
        ...s,
        ...select(s),
      },
    }));
  };

  unselectTask = async ({ taskId = [], }) => {
    const unselect = ({ taskIds = [], }) => {
      const data = { accountId: 0, user: {}, userId: 0, };

      taskIds = taskIds?.filter(id => !taskId.includes(id)) ?? [];

      return { taskIds, ...!taskIds.length && data, };
    };

    await this.save(({
      selected: s,
    }) => ({
      selected: {
        ...s,
        ...unselect(s),
      },
    }));
  };

    renderInlineValue = (value, { item }) => {
        if (item.access('index')) {
            return value ?? '';
        }
        return '-';
    };

    renderTaskSelected = (value, { items }) => {
        const accountId = items.accountId.valueOf;
        const userId = items.userId.valueOf;
        if (![accountId, 0].includes(this.state?.selected?.accountId)) {
            return '';
        }
        if (![userId, 0].includes(this.state?.selected?.userId)) {
            return '';
        }
        const taskId = items.taskId.valueOf;
        const options = {
            index: this.state?.selected?.taskIds?.includes?.(taskId) ? 1 : 0,
            values: [IS_DELEGATE_FALSE, IS_DELEGATE_TRUE],
            className: 'magic-switch--inline',
            texts: ['', ''],
            onChange: async (options) => console.log(options),
        };
        if (options.index) {
            options.onChange = () => this.unselectTask({ taskId: [taskId] });
        } else {
            const user = {
                name: items.userName.valueOf,
                surname: items.userSurname.valueOf,
                count: items.userThroughputCount.valueOf,
                total: items.userThroughputTotal.valueOf,
            };
            options.onChange = () => this.selectTask({ taskId: [taskId], accountId, userId, user });
        }
        return (<MagicSwitch { ...options } />);
    };

    renderUserToFullname = (value, { item }) => {
        if (item.access('index')) {
            const LENGTH_SHORT = 20;
            let fullname = value?.replace?.(/[| |]/g,' ') ?? '';
            fullname = (
                <MagicTooltip
                    classNameTooltip={`tooltip tooltip--large-width`}
                    content={ fullname }
                    lengthCut={ LENGTH_SHORT }
                />
            );
            return fullname;
        }
        return '-';
    };

    renderUserPosition = (value, { item }) => {
        if (item.access('index')) {
            const LENGTH_SHORT = 20;
            let position = '';
            try { position = JSON.parse( value ); } catch (error) {}
            position = position instanceof Array ? position.join(' ') : '';
            position = (
                <MagicTooltip
                    classNameTooltip={`tooltip tooltip--large-width`}
                    content={ position }
                    lengthCut={ LENGTH_SHORT }
                />
            );
            return position;
        }
        return '-';
    };

    renderUserToThroughput = (value, { items }) => {
        const count = items.userToThroughputDay;
        const total = items.userToThroughputTotal;
        return count.valueOf >= 0 && total.valueOf ? `${count.valueOf} / ${total.valueOf}` : '';
    };

    renderUserFullname = (value, { items }) => {
        const name = items.userName;
        const surname = items.userSurname;
        if (name.access('index') && surname.access('index')) {
            const LENGTH_SHORT = 20;
            const fullname = (
                <MagicTooltip
                    classNameTooltip={`tooltip tooltip--large-width`}
                    content={ `${ name?.valueOf } ${ surname?.valueOf }` }
                    lengthCut={ LENGTH_SHORT }
                />
            );
            return fullname;
        }
        return '-';
    };

    renderAccountFullname = (value, { items }) => {
        const name = items.accountName;
        const surname = items.accountSurname;
        if (name.access('index') && surname.access('index')) {
            const LENGTH_SHORT = 20;
            const fullname = (
                <MagicTooltip
                    classNameTooltip={`tooltip tooltip--large-width`}
                    content={ `${ name?.valueOf ?? '' } ${ surname?.valueOf ?? '' }` }
                    lengthCut={ LENGTH_SHORT }
                />
            );
            return fullname;
        }
        return '-';
    };

    renderTaskDescription = (value, { item }) => {
        if (item.access('index')) {
            const LENGTH_SHORT = 20;
            const tooltip = (
                <MagicTooltip
                    classNameTooltip={`tooltip tooltip--large-width`}
                    content={ value ?? '' }
                    lengthCut={ LENGTH_SHORT }
                />
            );
            const options = { className: `task_urgency`, style: { 'textAlign': 'left' }, };
            return (<div { ...options }>{ tooltip }</div>);
        }
        return '-';
    };

    renderDelegationForm = () => {
        if (this.state.delegationFormShow) {
            const { translate, } = this.props;
            return (
                <Modal
                    translate={ translate }
                    onOk={ async (userToId) => this.onDelegation(userToId, this.state?.selected?.taskIds ?? []) }
                    onClose={ async () => this.save({ delegationFormShow: false }) }
                    accountId={ this.state?.selected?.accountId }
                    taskIds={ this.state?.selected?.taskIds }
                    userId={ this.state?.selected?.userId }
                    user={ this.state?.selected?.user }
                />
            );
        }
        return '';
    };

    onDelegation = async (userToId, taskIds) => {
        try {
            await delegationService.updateDelegation({ task_ids: taskIds, user_id: userToId });
            await this.save({ delegationFormShow: false });
            await this.unselectTask({ taskId: taskIds, }); // unselect
            await this.onDataLoad();
        } catch (error) {
            error?.showErrorNotification?.();
        }
    };

    onUnDeledation = async (taskIds) => {
        try {
            await delegationService.disabledDelegation({ task_ids: taskIds });
            await this.unselectTask({ taskId: taskIds, }); // unselect
            await this.onDataLoad();
        } catch (error) {
            error?.showErrorNotification?.();
        }
    };

    renderDelegationActions = () => {
        const { translate, } = this.props;
        const className = list => f => ['button', ...list, ...f].join(' ');
        const className1 = className(['filter_btn-delegation']);
        const className2 = className(['filter_btn-remove_delegation']);
        const title1 = translate(`delegation_filter_btn_delegation`);
        const title2 = translate(`delegation_filter_btn_remove_delegation`);
        const pm = PermissionService.calc([{
            path: [ 'tasks', 'task_delegation' ],
            key: 'taskDelegation',
        }]);
        const pmDelegate = pm.taskDelegation.access('store');
        const pmUnDelegate = pm.taskDelegation.access('disabled');
        if (this.state?.selected?.taskIds?.length) {
            const onClickDelegation = () => this.save({ delegationFormShow: true });
            const onClickUnDelegation = () => this.onUnDeledation(this.state?.selected?.taskIds ?? []);
            return (<React.Fragment>
                { pmDelegate ? <div className={ className1([]) } onClick={ onClickDelegation }>{title1}</div> : null }
                { pmUnDelegate ? <div className={ className2([]) } onClick={ onClickUnDelegation }><span>{title2}</span></div> : null }
            </React.Fragment>);
        } else {
            const onClick = () => NotificationService.error({ title: "error", message: translate(`delegation_error_select_task`), });
            return (<React.Fragment>
                { pmDelegate ? <div className={ className1(['disabled']) } onClick={ onClick }>{title1}</div> : null }
                { pmUnDelegate ? <div className={ className2(['disabled']) } onClick={ onClick }><span>{title2}</span></div> : null }
            </React.Fragment>);
        }
    }

    render () {
      const { translate, } = this.props;
      const options = {
        config: this.delegationConfig,
        data: this.state.delegation.data,
        head: [
          'taskSelected',
          'userFullname',
          'userToFullname',
          'userPosition',
          'userToThroughput',
          'taskDeadline',
          'accountFullname',
          'taskDescription',
          'taskStatus',
          'taskUrgency',
        ],
      };

      return (
        <div className='delegation'>

          { this.renderDelegationForm() }

          <div className='content-block'>
            <div className='top-page-block'>
              <h1 className='page-title'>
                { translate(`delegation`) }
              </h1>
              <div className='table-filters_right'>
                { this.renderDelegationActions() }
              </div>
            </div>
          </div>

          <MagicFilterPanels
            mf={ this.mf }
            show={ true }
            translate={ translate }
          />

          <div className='content-block'>
            <div className='table-wrapper'>
              <div className='position-relative'>
                <Loader
                  loaded={ this.state.loaded }
                  loading={ (<Preloader scale={ 0.8 } />) }
                  translate={ translate }
                >
                  <MagicTable { ...options } />
                  <PagePanel
                    filter={ this.state.pages.filter }
                    take={ this.state.pages.take }
                    total={ this.state.pages.total }
                    variance={ this.state.pages.variance }
                    page={ this.pageId() }
                    onClick={ this.onPageClick }
                    doText={ this.onPageText(translate) }
                    doTo={ (pageId) => {
                      return {
                        search: `?page=${ pageId }`,
                        state: { selected: this.state.selected },
                      };
                    } }
                  />
                </Loader>
              </div>
            </div>
          </div>
        </div>
      );
    }

    pageId = () => {
      const query = this.query;
      const page = query.get('page');

      return Number(page) || 0;
    };

    pageIdAsGET = (pageId) => {
      pageId = pageId === undefined ? this.pageId() : pageId;
      const { filterId, } = this.mf;
      const result = {
        filterId,
        skip: pageId * this.state.pages.take,
        take: this.state.pages.take,
      };

      return result;
    };

    onDataLoad = async ( pageId ) => {
        const pageOptions = this.pageIdAsGET( pageId );
        await this.save({
            loaded: false,
            delegation: {...this.state.delegation, options: pageOptions, },
        });
        try {
            await delegationService.delegation(pageOptions);
        } catch ( error ) {
            await this.save({
                delegation: {
                    data: [],
                    hash: null,
                    options: {}
                },
                pages: { ...this.state.pages, filter: 0, total: 0, },
                delegation_user: {},
                checkAll: IS_DELEGATE_FALSE,
            });
        }
        await this.save({ loaded: true, });
    };

    onPageClick = ( { event, pageId, pageIs, } ) => {
        // event.preventDefault();
        this.onDataLoad( pageId );
    };

    onPageText = ( translate ) => ( pageId, pageIs ) => {
        const { current, first, prev, next, last, } = pageIs;
        const { skipped, taken, filter, total, } = pageIs;
        if ( skipped || taken ) {
            const id = Number.isInteger( pageId ) ? `${ pageId + 1 }` : '?';
            const text = skipped ? translate( 'delegation_pages_items' ) : '';
            return skipped ? `${ text }: ${ id }` : id;
        } else if ( filter || total ) {
            const id = Number.isInteger( pageId ) ? `${ pageId }` : '?';
            const text = translate( filter ? 'delegation_pages_filtered' : 'delegation_pages_total' );
            return `${ text }: ${ id }`;
        } else if ( first || prev || next || last ) {
            return '';
        } else if ( current ) {
            return `${ pageId + 1}`;
        } else {
            return `${ pageId + 1}`;
        }
    };

}

export default withRouter(withLocalize(Delegation));
