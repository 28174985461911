import React from 'react';
import { userService, } from "./UserDataService";
import { USER_EVENTS_TYPE, } from "../modules/common/components/Frame/consts/USER_EVENTS_TYPE";

const EventEmitter = require('events');

class NotificationService extends EventEmitter {
	constructor () {
		super();
		this.eventEmitter = new EventEmitter();

		this.stepAddNotification = 150;
		this.countAddNotification = -this.stepAddNotification;
		this.constListener = {
			add: "addNotification",
		};
		this.constType = {
			error: "error",
			warning: "warning",
			info: "info",
			success: "success",
		};

		let history = null;

		Object.defineProperties(this, {
			history: {
				get: () => history,
				set: value => history = value,
			},
		});
	}

	addListener (eventName, listener) {
		this.eventEmitter.addListener(eventName, listener);
	}

	on (eventName, listener) {
		this.eventEmitter.on(eventName, listener);
	}

	removeEventListener (eventName, listener) {
		this.eventEmitter.removeListener(eventName, listener);
	}

	errors (payload) {
		if (!(payload instanceof Object)) {
			console.log("params need instanceof object");

			return false;
		}

		for (const key in payload.errors) {
			this.error({
				title: "error_validation",
				message: payload.errors[key][0],
				time: payload.time,
				remove: payload.remove,
			});
		}
	}

	error (payload) {
		if (!(payload instanceof Object)) {
			console.log("params need instanceof object");

			return false;
		}
		if (payload.title === undefined || payload.message === undefined) {
			console.log("title and message required");

			return false;
		}

		this.emit(this.constListener.add, {
			type: this.constType.error,
			title: payload.title,
			message: payload.message,
			time: payload.time,
			remove: payload.remove,
			view: payload.view,
			viewClick: payload.viewClick,
		});
	}

	success (payload) {
		if (!(payload instanceof Object)) {
			console.log("params need instanceof object");

			return false;
		}
		if (payload.title === undefined || payload.message === undefined) {
			console.log("title and message required");

			return false;
		}

		this.emit(this.constListener.add, {
			type: this.constType.success,
			title: payload.title,
			message: payload.message,
			time: payload.time,
			remove: payload.remove,
			view: payload.view,
			viewClick: payload.viewClick,
		});
	}

	eventMessageViewClick (userEventId, viewFunc, link) {
		userService.updateUserEvents({ status: 3, }, userEventId)
			.then((response) => {
				if (response?.status === 3) {
					viewFunc();
				}
			})
			.catch((error) => {
				NotificationService.error(error);
			});

		const { history, } = this;

		history?.push?.(link);
	}

	eventMessage (payload) {
		if (!(payload instanceof Object)) {
			console.log("params need instanceof object");

			return false;
		}

		const { message, link, } = this.getNotificationMessageAndLink(payload);

		this.emit(this.constListener.add, {
			type: 'event',
			title: payload.data.params.translate,
			message,
			params: payload.params,
			remove: false,
			view: true,
			viewHTML: <button
				onClick={ () => {
					this.eventMessageViewClick(payload.data.params.userEventId, payload.data.viewFunc, link);
				} }
			          >View</button>,
			payload,
			closeFunc: payload.data.closeFunc,
			viewFunc: payload.data.viewFunc,
		});
	}

	message (payload) {
		if (!(payload instanceof Object)) {
			console.log("params need instanceof object");

			return false;
		}
		if (payload.type === undefined || payload.message === undefined) {
			console.log("type and message required");

			return false;
		}

		this.emit(this.constListener.add, {
			title: payload.title,
			type: payload.type,
			message: payload.message,
			time: payload.time,
			remove: payload.remove,
			view: payload.view,
			viewClick: payload.viewClick,
			other: payload?.other,
		});
	}

	emit (event, payload, error = false) {
		this.countAddNotification += this.stepAddNotification;

		setTimeout(() => {
			this.eventEmitter.emit(event, payload, error);

			if (this.countAddNotification - this.stepAddNotification > 0) {
				this.countAddNotification -= this.stepAddNotification;
			}
		}, this.countAddNotification);
	}

	getEventEmitter () {
		return this.eventEmitter;
	}

	getNotificationMessageAndLink = (payload) => {
		let message = '';
		let link = '';

		const { AccountCurrencyEnum, } = payload.enums.data.core;
		const eventType = payload.data.params.type;
		const eventName = payload.data.params.name;
		const eventParams = payload.data.params.params;
		const accountFullName = `${ eventParams['core.account']?.name } ${ eventParams['core.account']?.surname }`;

		switch (eventType) {
			case USER_EVENTS_TYPE.ACCOUNT: {
				message = `${ accountFullName }`;
				link = `/clients/${ eventParams['core.account'].id }`;
				break;
			} case USER_EVENTS_TYPE.TASK: {
				message = `#${ eventParams['tasks.tasks'].id }`;
				link = `/clients/${ eventParams['core.account'].id }/tasks/task/${ eventParams['tasks.tasks'].id }`;
				break;
			} case USER_EVENTS_TYPE.ORDER: {
				message = `${ accountFullName } ${ eventParams['payment.orders'].amount } ${ AccountCurrencyEnum[eventParams['core.system_accounts'].acc_ccy] }`;
				link = `/payments/order/${ eventParams['payment.orders'].id }`;
				break;
			} case USER_EVENTS_TYPE.NOVATALKS: {
				const set_dialog_event_name = 'NOVATALKS_SET_DIALOG_BY_MANAGER';
				const conversation_id = eventName === set_dialog_event_name ? eventParams['novatalks.dialogs']?.conversation_id : eventParams['novatalks.messages']?.conversation_id;

				message = `${ accountFullName }`;
				link = `/conversations/${ conversation_id }`;
				break;
			}
		}

		return { message, link, };
	}
}

export default new NotificationService();