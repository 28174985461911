import React, { Component } from 'react';
import Accounts from './Accounts';
import classnames from 'classnames';

const ACTIVE_TAB = '1';

export default class GridContainer extends Component {

    constructor (props) {
        super(props);

        this.state = {
            data:      this.props.data,
            activeTab: ACTIVE_TAB,
        };
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        this.setState({
            data: nextProps.data,
        });
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    };

    moduleToggleGrid = (item) => {
        this.props.moduleToggle(item);
    };

    checkItem = (item, index) => {
        const result = this.props.checkItem(item);

        if (this.state.activeTab === index && !result) {
            this.setState({
                activeTab:'1'
            });
        }

        return result;
    };

    render () {
        return (
            <div className = 'grid-container'>
                {Object.keys(this.state.data).map((item, index) => {
                    return (
                        <div
                            className = { `${classnames({ active: this.state.activeTab === index })} grid-container-row` } key = { index }
                            onClick = { () => {
									 this.toggle(index);
								 } } >
                            <div className = 'tasks' onClick={() => {this.moduleToggleGrid(item)}}>
                                <span className = 'tasks-check' />
                                <span className = 'tasks-name'>{item}</span>
                            </div>
                            {this.state.activeTab === index && this.checkItem(item, index)
                                ?
                                    <Accounts
                                        data = { this.state.data }
                                        name = { item }
                                        isFieldChecked = {this.props.isFieldChecked}
                                        isChecked = {this.props.isChecked}
                                        moduleToggle = {this.props.moduleToggle}
                                        stateNameToggle={this.props.stateNameToggle}
                                        methodToggle={this.props.methodToggle}
                                        fieldToggle={this.props.fieldToggle}
                                        isEditPopup={this.props.isEditPopup}
                                        getUserField={this.props.getUserField}
                                        removeField={this.props.removeField}
                                        addField={this.props.addField}
                                    />
                                :
                                null}
                        </div>
                    );
                })}
            </div>

        );

    }

}
