import React from 'react';
import { withRouter } from "react-router-dom";
import { withLocalize, } from "react-localize-redux";

import LeadAppeals from './LeadAppeals';
import { TabContent, TabPane, } from 'reactstrap';
import { getAppealsAccount } from "../../../services/AccountService";
import {Mentor} from "../../../../../components/Magic/components/MagicFilter/services/Mentor";
import {cachingService} from "../../../../../services/CachingService";
import {GET_APPEALS} from "../../../../../apiRoutes";
import Preloader from "../../../../../components/LoadingHOC/Preloader";
import Loader from "../../../../tima/components/Loader";

class LeadAppealsView extends React.Component {
    constructor(props) {
        super(props);

        const mf = new Mentor({
            owner: this,
            serviceId: 33,
            translate: this.props.translate,
        });

        Object.defineProperty(this, 'mf', { get: () => mf, });

        this.state = {
            activeTab: '1',
            currentId: +this.props.match.params.taskId ?? 0,
            data: [],
            loaded: true,
            pages:  {
                filter: 0,
                total: 0,
                take: 50,
                variance: 2,
            },
        };
    }

    save = async (state) => new Promise(next => this.setState(state, next));

    mfChanged = async () => {
        await this.save({ filterLastChange: Date.now(), });
    };

    mfDoing = async () => {
        await this.save({ loaded: false, });
    };

    mfDone = async () => {
        await this.mfChanged();
        await this.onDataLoad();
    };

    async componentDidMount() {
        this.mf.subscribe({
            changed: this.mfChanged,
            // changing: ,
            cleaned: this.mfDone,
            cleaning: this.mfDoing,
            loaded: this.mfDone,
            loading: this.mfDoing,
            saved: this.mfDone,
            saving: this.mfDoing,
        }, this);
        await this.mf.init({ doEmit: true, });
        // await this.mf.emit('change', {});
        // await this.onDataLoad(); // TODO: moved to mfDone
    }

    componentWillUnmount () {
        this.mf.unsubscribe([
            this.mfChanged,
            this.mfDoing,
            this.mfDone,
        ], this);
    }

    // ************************* NEW PAGINATION ****************************
    pageId = () => {
        const defaultPageId = 0;

        try {
            const query = new URLSearchParams(location.search);
            const page = query.get('page');

            return Number(page) || defaultPageId;
        } catch (e) {
            return defaultPageId;
        }
    };

    pageIdAsGET = (pageId) => {
        pageId = pageId === undefined ? this.pageId() : pageId;
        const { filterId, } = this.mf;
        const result = {
            filterId,
            take: this.state.pages.take,
            skip: pageId * this.state.pages.take,
        };
        return result;
    };

    onDataLoad = async ( pageId ) => {
        await this.save({ loaded: false, });
        const pageOptions = this.pageIdAsGET( pageId );
        const loaded = (data, meta) => ({ pages, }) => ({
            data,
            pages: { ...pages, ...meta, },
        });
        try {
            await cachingService.clean({ match: ({ isOne, key }) => isOne && key.indexOf(`GET:${ GET_APPEALS }/${ this.props.clientId }`)>0, });
            const { data, meta: { filter, total } } = await getAppealsAccount(this.props.clientId, pageOptions);
            await this.save(loaded(data, { filter, total, }));
        } catch ( error ) {
            await this.save(loaded([], { filter: 0, total: 0, }));
        }
        await this.save({ loaded: true, });
    };

    onPageClick = async ({ event, pageId, pageIs, }) => {
        // event.preventDefault();
        await this.onDataLoad(pageId);
    };

    onPageText = (pageId, pageIs) => {
        const { translate } = this.props;
        const { current, first, prev, next, last, } = pageIs;
        const { skipped, taken, filter, total, } = pageIs;
        if (skipped || taken) {
            const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : '?';
            const text = skipped ? translate('partners_pages_items') : '';
            return skipped ? `${ text }: ${ id }` : id;
        } else if (filter || total) {
            const id = Number.isInteger(pageId) ? `${ pageId }` : '?';
            const text = translate(filter ? 'partners_pages_filtered' : 'partners_pages_total');
            return `${ text }: ${ id }`;
        } else if (first || prev || next || last) {
            return '';
        } else if (current) {
            return `${ pageId + 1}`;
        } else {
            return `${ pageId + 1}`;
        }
    };

    updateClientId = async (id) => {
        await this.save({
            currentId: id
        });
    };

    updateAppealsAccount = async (id) => {
        await this.save({ currentId: id, });
        await this.onDataLoad();
    };

    toggle = async (tab) => {
        if (this.state.activeTab !== tab) {
            await this.save({ activeTab: tab, });
        }
    };

    render() {
        return (
            <div className='appeals-tab'>
                <div className='appeals-tab-inner'>
                   {/* <span className='span-view'>{this.props.translate(`client_view`)}</span>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={`${classnames({ active: this.state.activeTab === '1' })} list-icon`}
                                onClick={() => {
                                    this.toggle('1');
                                }}>
                                <i className='fa fa-list-ul' />
                            </NavLink>
                        </NavItem>
                    </Nav>*/}
                    <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId='1'>
                            <Loader
                                loaded={this.state.loaded}
                                loading={(<Preloader scale={1}/>)}
                                translate={this.props.translate}
                            >
                                {
                                    this.state.data.length > 0 ?
                                        <LeadAppeals
                                            currentId={this.state.currentId}
                                            items={this.state.data}
                                            pages={this.state.pages}
                                            loaded={this.state.loaded}
                                            clientId={this.props.clientId}
                                            updateClientId={this.updateClientId}
                                            updateAppealsAccount={this.updateAppealsAccount}
                                            onDataLoad={this.onDataLoad}
                                            onPageClick={this.onPageClick}
                                            doText={this.onPageText}
                                            pageId={this.pageId}
                                            mf={ this.mf }

                                        />
                                        : null
                                }
                            </Loader>
                        </TabPane>
                    </TabContent>
                </div>
            </div>
        );
    }
}

export default withLocalize(withRouter(LeadAppealsView));
