import {
  itemsBuild,
  itemsDrain,
  itemsResort,
  CFG_ITEM_TYPE,
} from '../Config';

const f_type = {
  'drop-down': false,
  'o=': [ '=', '!=', ],
};

const f_status = {
  'drop-down': false,
  'o=': [ '=', '!=', ],
};

export default {
  id: 180,
  ...[

    // filters
    ...[ {
      f: 'partners.payout_rate_lists.id',
      id: 'f:id',
    }, {
      f: 'partners.payout_rate_lists.name',
      id: 'f:name',
    }, {
      f: 'partners.payout_rate_lists.type',
      id: 'f:type',
      ...f_type,
    }, {
      f: 'partners.payout_rate_lists.status',
      id: 'f:status',
      ...f_status,
    }, {
      f: 'partners.payout_rate_lists.created_at',
      id: 'f:created',
    }, {
      f: 'partners.payout_rate_lists.updated_at',
      id: 'f:updated',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

    // orders
    ...[ {
      f: 'partners.payout_rate_lists.id',
      id: 'o:id',
    }, {
      f: 'partners.payout_rate_lists.name',
      id: 'o:name',
    },{
      f: 'partners.payout_rate_lists.count_groups',
      id: 'o:countGroups',
    }, {
      f: 'partners.payout_rate_lists.type',
      id: 'o:type',
    }, {
      f: 'partners.payout_rate_lists.status',
      id: 'o:status',
    }, {
      f: 'partners.payout_rate_lists.created_at',
      id: 'o:created',
    }, {
      f: 'partners.payout_rate_lists.updated_at',
      id: 'o:updated',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

    // panels
    ...[ {
      ii: 'f:id',
      t: 'partners_payout_id',
    }, {
      ii: 'f:name',
      t: 'partners_payout_name',
    }, {
      ii: 'f:type',
      t: 'partners_payout_type',
    }, {
      ii: 'f:status',
      t: 'partners_payout_status',
    }, {
      ii: 'f:created',
      t: 'partners_payout_created_at',
    }, {
      ii: 'f:updated',
      t: 'partners_payout_updated_at',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

  ]
  .map(itemsBuild)
  .reduce(itemsDrain, [])
  .reduce(itemsResort, {}),

  path: 'partners/payout-rate-lists',
  widget: false,
};
