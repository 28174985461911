import {
	itemsBuild,
	itemsDrain,
	itemsResort,
	CFG_ITEM_TYPE,
} from '../Config';

export default {
	id: 181,
	...[

    // // filters
	// 	...[
	// 		{
	// 			f: '',
	// 			id: '',
	// 		},
	// 	]
	// 		.filter(_ => _ && !_.off)
	// 		.map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

    // orders
		...[
			{
				f: 'partners.referral_code.company_name',
				id: 'o:promoCampaignCampaign',
			},
			{
				f: 'partners.referral_code.created_at',
				id: 'o:promoCampaignCreatedAt',
			},
			{
				f: 'partners.referral_code.ref_short',
				id: 'o:promoCampaignReferralCode',
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

    // // panels
	// 	...[
	// 		{
	// 			ii: '',
	// 			t: '',
	// 		},
	// 	]
	// 		.filter(_ => _ && !_.off)
	// 		.map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

	]
		.map(itemsBuild)
		.reduce(itemsDrain, [])
		.reduce(itemsResort, {}),

	path: 'partners/referral-code',
	widget: false,
};