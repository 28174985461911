export const tasksLang = {
    "tasks_createform_types_notification": ["Notification", "Уведомление", "Повідомлення"],
    "tasks_createform_types_newsletter": ["Newsletter", "Письмо", "Лист"],
    "tasks_createform_types_task": ["Task", "Задача", "Завдання"],
    "tasks_createform_types_call": ["Call", "Звонок", "Дзвiнок"],
    "tasks_createform_types_technical": ["Technical", "Технический", "Технічні"],
    "tasks_createform_types_support": ["Support", "Служба поддержки", "Підтримка"],
    "tasks_createform_types_sales": ["Sales", "Продажи", "Продажі"],
    "tasks_createform_types_financial": ["Financial", "Финансовый", "Фінансові"],
    "tasks_createform_types_call_introduction": ["Call (Introduction)", "Звонок (Знакомство)", "Дзвінок (Знайомство)"],
    "tasks_createform_types_call_feedback": ["Call (Feedback)", "Звонок (Обратная связь)", "Дзвінок (Зворотній зв'язок)"],
    "tasks_createform_types_call_warming": ["Call (Warming)", "Звонок (Утепление)", "Дзвінок (Утеплення)"],
    "tasks_createform_types_email": ["E-Mail", "E-Mail", "E-Mail"],
    "tasks_createform_types_email_warming": ["E-Mail (Warming)", "E-Mail (Утепление)", "E-Mail (Утеплення)"],
    "tasks_createform_types_appeal_from_personal_cabinet": ["Appeal from Personal cabinet", "Обращение из ЛК", "Звернення з ЛК"],
    "tasks_createform_types_congratulation_with_birthday": ["Congratulation with Birthday", "Поздравление с ДР", "Привітання з ДР"],

    "tasks_createform_state_3": ["Done", "Готовая", "Готова"],
    "tasks_createform_state_2": ["In process", "В процессе", "В процесі"],
    "tasks_createform_state_4": ["Canceled", "Отменена", "Скасована"],
    "tasks_createform_state_1": ["New", "Новая", "Нова"],
    "tasks_createform_urgency_1": ["Not urgent", "Не срочная", "Не терміново"],
    "tasks_createform_urgency_2": ["Normal", "Нормальная", "Нормальна"],
    "tasks_createform_urgency_3": ["Important", "Важная", "Важлива"],
    "tasks_createform_urgency_4": ["Urgent", "Срочная", "Термінова"],
    "tasks_createform_type": ["Type", "Тип", "Тип"],
    "tasks_createform_important": ["Priority", "Приоритет", "Пріоритет"],
    "tasks_createform_status": ["Status", "Статус", "Статус"],
    "tasks_createform_deadline": ["Deadline", "Дедлайн", "Дедлайн"],
    "tasks_createform_save": ["Save", "Сохранить", "Зберегти"],
    "tasks_createform_cancel": ["Cancel", "Отменить", "Скасувати"],
    "tasks_type_the_description": ["Type the description", "Введите описание", "Введіть опис"],
    "tasks_type_the_title": ["Type the title", "Введите название", "Введіть назву"],
    "tasks_history":        ["History", "История", "Історія"],
    "tasks_delete_task":    ["Delete", "Удалить", "Видалити"],
    "tasks_action":         ["Action", "Действия", "Дія"],
    "tasks_task_list":      ["Task list", "Список задач", "Список завдань"],
    "tasks_new_task":       ["New task", "Новая задача", "Нове завдання"],
    "tasks_tasks":          ["Tasks", "Задачи", "Завдання"],
    "tasks_create_task":    ["Create a task", "Создать задачу", "Створити завдання"],
    'tasks_task_id':        ["ID", "ID", "ID"],
    'tasks_type':           ["type", "тип", "тип"],
    'tasks_client':         ["Сlient", "Клиент", "Клієнт"],
    'tasks_task_name':      ["task_name", "название задачи", "ім'я завдання"],
    'tasks_description':    ["Description", "Описание", "Опис"],
    'tasks_attachments':    ["attachments", "вложения", "вкладення"],
    'tasks_status':         ["Status", "Статус", "Статус"],
    'tasks_deadline':       ["Deadline", "Крайний срок", "Термін"],
    'tasks_responsible':    ["Responsible manager", "Ответственный менеджер", "Відповідальний менеджер"],
    'tasks_placeholder_id': ["Id, name, description", "Ид, имя, описание", "Iд, ім'я, опис"],
    "tasks_apply":          ["Apply", "Применить", "Застосувати"],
    "tasks_more_filters":   ["More Filters", "Дополнительные фильтры", "Додаткові фільтри"],
    "tasks_reset":          ["Reset", "Сброс", "Скасувати"],
    "tasks_selected":       ["Selected", "Выбранные", "Вибрано"],
    "tasks_list_select":    ["Select", "Выбрать", "Виберіть"],
    "tasks_filters":        ["filters", "фильтры", "фільтри"],
    "tasks_created_at":     ["created date", "дата создания", "дата створення"],
    "tasks_updated_at":     ["updated date", "дата обновления", "дата оновлення"],
    "tasks_files":          ["NO FILE", "НЕТ ФАЙЛОВ", "НЕМАЄ ФАЙЛІВ"],
    "tasks_category_client":["Customer category", "Категория клиента", "Категорія клієнта"],
    "tasks_phone":          ["Phone", "Телефон", "Телефон"],
    "tasks_customer_sign":  ["Customer sign", "Признак клиента", "Ознака клієнта"],
    "tasks_warm":           ["Warm client", "Теплота клиента", "Теплота клієнта"],
    "tasks_urgency":        ["Urgency", "Важность", "Важливість"],
    "tasks_last_contact":   ["Result last contact", "Результат последнего контакта ", "Результат останнього контакта "],
    "tasks_date_account_categories":  ["Transition date by customer category", "Дата перехода по категории клиента ", "Дата переходу по категорії клієнта"],
    "tasks_is_loyal":       ["Loyal", "Лояльный", "Лояльний"],
    "tasks_is_vip":         ["VIP", "VIP", "VIP"],
    "tasks_account_labels": ["Client label", "Метка клиента", "Мітка клієнта"],
    "tasks_date_warm":      ["Date of transition according to customer warmth", "Дата перехода по теплоте клиента", "Дата перехода по теплоті клієнта"],
    "tasks_type_client":    ["Type client", "Тип клиента", "Тип клієнта"],
    "tasks_date_last_contact": ["Date of last customer contact", "Дата последнего контакта с клиентом", "Дата останнього контакта з клієнтом"],


    'tasks_call':            ["Call", "Звонок", "Дзвінок"],
    'tasks_task':         ["Task", "Задача", "Завдання"],
    'tasks_newsletter':         ["Newsletter", "Рассылка", "Розсилка"],
    'tasks_notification':         ["Notification", "Уведомление", "Сповіщення"],

    "tasks_filter_id":          ["Task id", "Ид задачи", "Iд завдання"],
    "tasks_filter_type":        ["Type", "Тип", "Тип"],
    "tasks_filter_responsible": ["Responsible", "Ответственный", "Відповідальний"],
    "tasks_filter_responsible_a": ["responsible", "ответственного", "відповідального"],
    "tasks_filter_deadline":    ["Deadline", "Дедлайн", "Дедлайн"],
    "tasks_filter_creator":     ["Creator", "Создатель", "Створювач"],
    "tasks_filter_status":      ["Status", "Статус", "Статус"],
    "tasks_filter_name":        ["Title", "Наименование", "Назва"],
    "tasks_filter_description": ["Description", "Описание", "Опис"],

    "tasks_check_list":         ["Check list", "Список задач", "Список завдань"],
    "tasks_create_checklist":   ["Create Check List", "Создать список задач", "Створити список завдань"],
    "tasks_task_ID":            ["task ID", "ID задачи", "ID задачи"],
    "tasks_task_to_todo_list":  ["At the moment there is no to-do list in this task", "В этой задаче нет списка", "В цьому завданні немає списку"],
    "tasks_describe_task":      ["Describe your task", "Описание задачи", "Опис завдання"],
    "tasks_task_remove":        ["Remove", "Удалить", "Видалити"],
    "tasks_task_initiator":     ["Initiator", "Инициатор", "Iніціатор"],
    "tasks_task_initiator_a":     ["initiator", "инициатора", "ініціатора"],
    "tasks_task_assistance":    ["Assistant", "Ассистент", "Асистент"],
    "tasks_task_assistance_a":   ["assistant", "ассистента", "асистента"],
    "tasks_task_manager":       ["Manager", "Менеджер", "Менеджер"],
    "tasks_change_initiator":   ["Change the initiator", "Изменить инициатора", "Змінити ініціатора"],
    "tasks_change_assistance":  ["Change the assistant", "Изменить ассистента", "Змінити асистента"],
    "tasks_change_responsible": ["Change the responsible", "Изменить ответственного", "Змінити відповідального"],
    "tasks_change_the":         ["Change the", "Изменить", "Змінити"],
    "tasks_add_the":            ["Add the", "Добавить", "Добавити"],
    "tasks_participants":         ["Participants in a task", "Участники в задаче", "Участники у задачі"],
    "tasks_spectator":          ["Spectator", "Наблюдатель", "Спостерігач"],
    "tasks_spectator_a":          ["spectator", "наблюдателя", "спостерігача"],
    "tasks_spectator_add":          ["Add spector", "Добавить наблюдателя", "Додати спостерігача"],
    "tasks_initiator_add":     ["Add initiator", "Добавить инициатора", "Додати ініціатора"],
    "tasks_assistance_add":      ["Add assistant", "Добавить ассистента", "Додати асистента"],
    "tasks_responsible_add":    ["Add responsible", "Добавить ответственного", "Добавити відповідального"],
    "tasks_show_all":          ["Show All", "Показать все", "Показати все"],
    "tasks_show_all_short":          ["All", "Все", "Все"],
    "tasks_join":              ["Join", "Присоединится", "Приєднатися"],
    "tasks_disscussion_details": ["Detailed Discussion Of The Project", "Детальное обсуждение проекта", "Детальне обговорення"],
    "tasks_edit":              ["Edit", "Редактировать", "Редагувати"],
    "tasks_attachments_preview": ["Attachments Preview", "Предпросмотр вложений", "Перегляд вкладень"],
    "tasks_client_write":     ["Write", "Написать", "Написати"],
    "tasks_client_404":       ["Client not found", "Клиент не найден", "Клієнт не знайдений"],
    "tasks_date_of_change":   ["Date of change", "Дата изменения", "Дата зміненння"],
    "tasks_discussion":   ["Discussion", "Обсуждение", "Обговорення"],
    "tasks_type_smth":   ["Enter a message text", "Введите текст сообщения", "Введіть текст повідомлення"],
    "task_error_empty_comment": ["Comment can not be empty", "Комментарий не может быть пустым", "Коментар не може бути пустим"],
    "tasks_send":        ["SEND", "Отправить", "Відіслати"],
    "tasks_delete_are_u_sure":        ["Are you sure?", "Вы уверены?", "Ви впевнені?"],
    "tasks_delete_marked_4_delete":        [
        "The Card is Marked For Deletion,\ndoes Not Delete From The Database",
        "Эта карточка помеченна к удалению,\nно не будет удалена из базы",
        "Ця картка помічена до видаленя,\nале не буде видалена з бази"
    ],
    "tasks_delete_marked_4_task":        [
        "The Card is Marked For Deletion,\ndoes Not Delete From The Database",
        "Эта карточка помеченна к удалению,\nно не будет удалена из базы",
        "Ця картка помічена до видаленя,\nале не буде видалена з бази"
    ],
    "tasks_delete_marked_4_client":        [
        "The client will be removed from the task!",
        "Клиент будет удален с задания!",
        "Клієнт буде видалений з завдання!"
    ],
    "tasks_delete_marked_4_document":        [
        "The document will be removed from the task!",
        "Документ будет удален с задания!",
        "Документ буде видалений з завдання!"
    ],
    "tasks_delete_marked_4_checkList":        [
        "The task list will be removed from the task!",
        "Список задач будет удален с задания!",
        "Список завдань буде видалений з завдання!"
    ],
    "tasks_delete_marked_4_comment":        [
        "The comment will be removed from the task!",
        "Комментарий будет удален с задания!",
        "Коментар буде видалений з завдання!"
    ],
    "tasks_delete_yes":        ["Yes", "Да", "Так"],
    "tasks_delete_no":        ["No", "Нет", "Ні"],
    "tasks_history_changes":        ["History of Сhanges", "История изменений", "Історія змін"],
    "tasks_history_date_time":        ["Date and Time", "Дата и Время", "Дата та Час"],
    "tasks_history_value_name":        ["Value name", "Название", "Назва"],
    "tasks_history_param_value_before": ["Parameter Value Before Change", "Значение до изменения", "Значення до змін"],
    "tasks_history_param_value_after":  ["Parameter Value After Change", "Значение после изменения", "Значення після змін"],
    "tasks_success_create_quick" : ["Quick task successfully created", "Быстрая задача успешно создана!", "Швидке завдання успішно створена"],
    "task_add_client" : ["Add client", "Добавить клиента", "Додати клієнта"],
    "task_edit_client" : ["client", "клиента", "клієнта"],
    "task_remove_client" : ["Remove client", "Удалить клиента", "Видалити клієнта"],
    "task_add_spectator" : ["Add spectator", "Добавить наблюдателя", "Додати глядача"],
    "task_search" : ["Search", "Поиск", "Пошук"],
    "task_remove_check_list" : ["Remove checklist", "Удалить чеклист", "Видалити чекліст"],
    "task_checklist": ["Checklist", "Чеклист", "Чекліст"],
    "task_add_check_list" : ["Add checklist", "Добавить чеклист", "Додати чекліст"],
    "task_reply" : ["Reply", "Ответить", "Відповісти"],
    "task_choose_file" : ["Choose File", "Выберите файл", "Виберіть файл"],
    "task_file_up" : ["File up to 10MB", "Файл до 10 МБ", "Файл до 10 Мб"],
    "no_assistants" : ["No assistants", "Нет ассистентов", "Немає асистентів"]

};
