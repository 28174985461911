import React, { Component, } from "react";
import { isNull } from "lodash";

import { emailInputIsValid, phoneInputIsValid } from "../../../../../../clients/components/Leads/helpers/validation-helpers";
import { setAccountStatusActive, setAccountStatusArchive } from "../../../../../../clients/services/AccountService";
import { changeWarmType, sendAccountManager, resendRegistrationEmail, updateAccountProperty, updatePhoneAccount, updateEmailAccount} from "../../../../../../clients/services/AccountRequestService";
import NotificationService from "../../../../../../../services/NotificationService";
import PermissionService from "../../../../../../../services/PermissionService";

import Breadcrumbs from "../../../../../../tima/components/Breadcrumbs";
import ActionsDropdown from "./components/ActionsDropdown";
import InfoBar from "./components/InfoBar";
import UserModalPopUp from "../../../../../../clients/components/Leads/UserModalPopUp";
import NewTaskCreateForm from "../../../../../../tasks/components/NewTaskCreateForm/index";

import * as configBreadcrumbs from "../../confg/BreadcrumbsConfig";

class PartnerCardHeader extends Component {
    constructor(props){
        super(props)

        this.state={
            loadedInnerRequest:   true,
            dropdownOpen: false,
            action_mails: [],
            action_phones: [],
            // ...this.getInitialActionFieldsValues(),
            togglePopupItem:{
                bool: false,
                type: null,
                top:  0,
            },
            errors:[],
            templates:[],
            confirmRegistration: false,
            userFadePopUp:false,
            manager:{
                user_id: null,
            },
            showCreate:null,
        }
    }


	get translate () {
		return this.props.translate;
	}

    get leadConfig() {
        return [
            {
                path: ['core', 'account'],
                key:  'coreAccount',
            },
            {
                path: ['core', 'account'],
                key: 'sendText',
            },
            {
                path: ['core', 'mail'],
                key: 'sendTemplate',
            },
        ];
    };

    get accountPropertyConfig () {
		return [
			{
				key: "changeEmail",
				path: [ "core", "account", "email", ],
			},
			{
				key: "changePhone",
				path: [ "core", "account", "phone", ],
			},
            {        
                path: ['core', 'account', 'is_email_verify'],
                key:  'verifyEmail',
            },
            {
                path: ['core', 'account', 'is_phone_verify'],
                key:  'verifyPhone',
            },
			{
				key: "decryptData",
				path: [ "core", "account", ],
			},
		];
	}

    // componentDidMount(){
    //     document.addEventListener('click', this.handlerClosePopup);
    // }

    // componentWillUnmount () {
    //     document.removeEventListener('click', this.handlerClosePopup);
    // }


	save = state => new Promise(next => this.setState(state, next));
    
    
    // getAccountStatus = () => {
    //     return parseInt(this.props?.account?.account.status);
    // };

    // _isAccountStatusActive = () => this.getAccountStatus()?.toString() === '1'; // AccountStatusEnum

    // _isAccountStatusDeleted = () => this.getAccountStatus()?.toString() === '2'; // AccountStatusEnum

    // _isAccountStatusArchived = () => this.getAccountStatus()?.toString() === '3'; // AccountStatusEnum

    // getInitialActionFieldsValues = () => {
    //     return {
    //         action_mail:  '',
    //         action_phone: '',
    //         subject:      '',
    //         subjectD:     '',
    //     };

    // };

    // onArchiveBtnClick = (accountId) => async () => {
    //     const { translate, } = this.props;
    //     const isAccountStatusActive = this._isAccountStatusActive();
    //     const isAccountStatusArchived = this._isAccountStatusArchived();

    //     let setFunc = null;

    //     switch (true) {
    //         case isAccountStatusActive: {
    //             setFunc = setAccountStatusArchive;
    //             break;
    //         }
    //         case isAccountStatusArchived: {
    //             setFunc = setAccountStatusActive;
    //             break;
    //         }
    //         default: {
    //             throw new Error(`Can*t do the request in the current account_status!`);
    //         }
    //     }

    //     await this.save({ loadedInnerRequest: false, });

    //     try {
    //         const response = await setFunc(accountId,);

    //         if (response?.error) {
    //             throw new Error(response?.error);
    //         }

    //         const requestStatusMsg = isAccountStatusArchived
    //             ? translate('account_success_rearchived')
    //             : translate('account_success_archived');
    //         NotificationService.success({
    //             title:   'success',
    //             message: requestStatusMsg,
    //             remove:  false,
    //         });

    //         await this.props?.refreshClientData();
    //     } catch (error) {
    //         error?.showErrorNotification?.();
    //     }

    //     await this.save({ loadedInnerRequest: true, });
    // };


    // renderArchiveBtn = (accountId) => {
    //     const { translate} = this.props;
    //     const calcData = PermissionService.calc(this.leadConfig);

    //     const isAccountStatusArchived = this._isAccountStatusArchived();
    //     const isAccountStatusDeleted = this._isAccountStatusDeleted();
    //     const permissionActive = calcData?.coreAccount?.access('active');
    //     const permissionArchive = calcData?.coreAccount?.access('archive');

    //     const btnNameText = !isAccountStatusArchived ? translate(`client_archive`) : translate(`client_unzip`);
    //     const renderFlag = !isAccountStatusDeleted && (isAccountStatusArchived ? permissionActive : permissionArchive);

    //     return renderFlag ? (
    //         <>
    //             { this.state?.dropdownOpen && <div className="dropDown__overlay" /> }
    //             <div className={`dropDown__wrapper ${ this.state?.dropdownOpen ? 'open' : 'close'}`}>
    //                 <div className='dropDown__item' onClick={this.onArchiveBtnClick(accountId)}>
    //                     <i className='action_tasks' />
    //                     { btnNameText }
    //                 </div>
    //             </div>
    //         </>
    //     ) : null;
    // };
    
    // setStatePopupItem = async (type, positionTop) => {
    //     await this.save((state) => ({
    //         togglePopupItem: {
    //             ...state.togglePopupItem,
    //             bool: !state.togglePopupItem.bool,
    //             type,
    //             top:  positionTop,
    //         }
    //     }));
    // };

    // closePopupAction = async () => {
    //     await this.save((prevProps) => {
    //         return {
    //             ...this.getInitialActionFieldsValues(),
    //             togglePopupItem: {
    //                 ...prevProps.togglePopupItem,
    //                 type: '',
    //                 top:  '',
    //                 bool: !prevProps.togglePopupItem.bool,
    //             },
    //             messageText: "",
    //             // dropdownOpen: !prevProps.dropdownOpen,
    //         };
    //     });
    // };


    // updateStateAccountSingleProperty = (obj) => { 
    //     if(Object.keys(obj).length > 1) return console.log('Object has more than single property');

    //     return this.save(
    //         { account: {
    //             ...this.state.account, 
    //             ...obj,
    //         }
    //     })
    // }

    // setErrorByProperty = (obj) => {
    //     if(obj.hasOwnProperty('phone')){ 
    //         return this.save({errors: [...this.state.errors, 'change_phone']})
    //     }
    //     if(obj.hasOwnProperty('email')){ 
    //         return this.save({errors: [...this.state.errors, 'change_email']})
    //     }
    // }

    // showConfirmBlock = async () => await this.save({ confirmRegistration: true });

    // setStateModalCreateTask = (id, quickTask) => {
    //     this.setState({
    //         showCreate: id,
    //         quickTask,
    //         togglePopupItem: {
    //             type: '',
    //             top:  '',
    //             bool: false,
    //         },
    //         confirmRegistration: false,
    //     });
    // };

    // changeAccountProperty = async (obj) => {
    //     try {
    //         const response = await updateAccountProperty(this.props?.account?.account.id, obj);
    //         response && await this.updateStateAccountSingleProperty(obj)
    //         response && this.closePopupAction();
    //     } catch (error) {
    //         NotificationService.error({
    //             title:   'error',
    //             message: error.message,
    //             remove:  false,
    //         });
    //         await this.setErrorByProperty(obj);
    //     }
    // }

    // setNewEmail = (event) => {
    //     this.setState({ action_mail: event.target.value });
    // };

    // setNewPhone = (event) => {
    //     this.setState({ action_phone: event.target.value });
    // };

    // updateEmail = async () => {
    //     try {
    //         const response = await updateEmailAccount(this.props?.account?.account.id, { emails: [this.state.action_mail] });

    //         response && this.closePopupAction();
    //     } catch (error) {
    //         NotificationService.error({
    //             title:   'error',
    //             message: error.message,
    //             remove:  false,
    //         });
    //     }
    // };

    // updatePhone = async () => {
    //     try {
    //         const response = await updatePhoneAccount(this.props?.account?.account.id, { phones: [this.state.action_phone] });

    //         response && this.closePopupAction();
    //     } catch (error) {
    //         NotificationService.error({
    //             title:   'error',
    //             message: error.message,
    //             remove:  false,
    //         });
    //     }
    // };

    // handleResendEmail = async () => {
    //     try {
    //         await this.save({
    //             dropdownOpen: false,
    //             confirmRegistration: false
    //         });
    //         await resendRegistrationEmail({ account_id: this.props?.account?.account.id});
    //     } catch (error) {
    //         await this.save({
    //             dropdownOpen: false,
    //             confirmRegistration: false
    //         });
    //         error?.showErrorNotification();
    //     }
    // };

    // handleChangeWarmType = async () => {
    //     try {
    //         await changeWarmType(this.props.account?.account.id);

    //         this.props.toggleLoaded();
    //         await this.props.refreshClientData(); // * 
    //     } catch (error) {
    //         error?.showErrorNotification();
    //     } finally { 
    //         this.props.toggleLoaded();
    //     }
    // }

    // handleChangeEmail = async () => { 
    //     const emailIsValid = await emailInputIsValid(this.save, this.state);
    //     if(!emailIsValid){
    //         return
    //     }
    //     this.changeAccountProperty({email:this.state.action_mail})
    // }

    // handleChangePhone = async () => { 
    //     const phoneIsValid = await phoneInputIsValid(this.save, this.state); 
    //     if(!phoneIsValid) return 
    //     this.changeAccountProperty({ phone: `+${this.state.action_phone}`})
    // }

    // handleVerifyPhone = () => { 
    //     this.changeAccountProperty({ is_phone_verify: 1 })
    // }

    // handleVerifyEmail= () => { 
    //     this.changeAccountProperty({ is_email_verify: 1 })
    // }

    // handlerClosePopup = async (e) => {
    //     const targetClassList = [ ...e?.target?.classList ];
    //     const isOpenDropDownModal = this.state?.togglePopupItem?.bool ?? false;
    //     const isOpenDropDown = this.state?.dropdownOpen ?? false;

    //     if (!isOpenDropDownModal && isOpenDropDown && targetClassList.includes("dropDown__overlay")) {
    //         return await this.toggleDropdown();
    //     } else if (isOpenDropDownModal && targetClassList.includes("dropDown__modal_overlay")) {
    //         return await this.closePopupAction();
    //     }

    // }
    
    dropdownItemNav = async (e) => {
        let element = e.target;
        const positionTop = `${e.clientY - (e.clientY - e.target.offsetTop)}px`;
        await this.save({
            ...this.getInitialActionFieldsValues(),
            togglePopupItem: { ...this.state.togglePopupItem, bool: false, },
        });

        while (!element.getAttribute('data-type')) {
            element = element.parentElement;
            if (!isNull(element.getAttribute('data-type'))) {
                return await this.setStatePopupItem(element.getAttribute('data-type'), positionTop);
            }
        }

        return await this.setStatePopupItem(element.getAttribute('data-type'), positionTop);
    };

    toggleDropdown = async () => {
        await this.save({
            dropdownOpen: !this.state.dropdownOpen,
            ...this.getInitialActionFieldsValues(),
            togglePopupItem: {
                ...this.state.togglePopupItem,
                bool: false,
            },
            confirmRegistration: false,
        });
    };

    // userModal () {
    //     this.setState({
    //         userFadePopUp: !this.state.userFadePopUp,
    //     });
    // }

    // sendManager = async (id) => {
    //     this.setState({
    //         manager: { user_id: id },
    //     }, () => {
    //         sendAccountManager(this.props.partnerId, this.state.manager)
    //             .then(() => {
    //                     this.props.getClient(this.props.partnerId); 
    //                 },
    //                 (error) => {
    //                     NotificationService.error({
    //                         title:   'error',
    //                         message: error?.errors?.user_id ? error.errors.user_id : "Could not update",
    //                         remove:  false,
    //                     });
    //                 }
    //             );
    //     });

    // };

	render() {
        const {dropdownOpen, confirmRegistration, togglePopupItem, action_phone, action_mail, errors, userFadePopUp } = this.state;
		const { account, profile, user, enums, usersAllAvatar, partner, activeTab,} = this.props;
		const translate = this.translate;
		const breadcrumbsItems = configBreadcrumbs.breadcrumbs4PartnersCard;

		return (
            <>
                {/* <UserModalPopUp
                    client={account}
                    sendManager={this.sendManager}
                    userFadePopUp={userFadePopUp}
                    userModal={this.userModal}
                    usersAllAvatar={usersAllAvatar.data}
                /> */}
                {/* {!isNull(this.state.showCreate) ?
                    <NewTaskCreateForm
                        close={this.setStateModalCreateTask}
                        quick={false}
                        userId={this.props.partnerId}
                        responsible={account.user}
                    />
                    : ""
                } */}

                <div className="partners-card__header top-page-block">
                    <div>
                        <h4>{translate('partner_card')}</h4>
                        <Breadcrumbs items={ breadcrumbsItems(partner, activeTab, translate) } />
                    </div>


                 <div className='top-button-area'>
                        {/* <div className='lead-page-dropdown'>
                            <div onClick={ this.toggleDropdown } className='button-icon dropDown__toggle-icon'>
                                <i className='la la-plus-circle' />
                                {this.props?.translate(`client_action`)}
                            </div>
                            <div>
                            { (this.state.togglePopupItem.bool && this.state.dropdownOpen) && <div className="dropDown__modal_overlay" /> }
                                { !this._isAccountStatusArchived()
                                    ? <ActionsDropdown 
                                        translate={translate} 
                                        account={account.account}
                                        accountPropertyConfig={this.accountPropertyConfig}
                                        user={user}
                                        profile={profile}
                                        onArchiveBtnClick={this.onArchiveBtnClick}
                                        confirmRegistration={confirmRegistration}
                                        handleResendEmail = {this.handleResendEmail}
                                        handleRejectResendEmail = {async () => await this.save({ confirmRegistration: false })}
                                        dropdownOpen={dropdownOpen}
                                        dropdownItemNav={this.dropdownItemNav}
                                        togglePopupItem={togglePopupItem}
                                        action_phone={action_phone}
                                        action_mail={action_mail}
                                        setNewPhone={this.setNewPhone}
                                        setNewEmail={this.setNewEmail}
                                        errors={errors}
                                        handleChangeEmail={this.handleChangeEmail}
                                        handleChangePhone={this.handleChangePhone}
                                        handleVerifyPhone={this.handleVerifyPhone}
                                        handleVerifyEmail={this.handleVerifyEmail}
                                        handleChangeWarmType={this.handleChangeWarmType}
                                        updatePhone={this.updatePhone}
                                        updateEmail={this.updateEmail}
                                        setStateModalCreateTask={this.setStateModalCreateTask}
                                        _isAccountStatusArchived={this._isAccountStatusArchived}
                                        closePopupAction={this.closePopupAction}
                                        showConfirmBlock={this.showConfirmBlock}
                                    />
                                    : <>{ this.renderArchiveBtn(accountId) }</>
                                }
                            </div>
                        </div> */}
                        <div className='button-icon' onClick={this.props.showHistoryChanges}> 
                            <i className='la la-clock-o'/>
                            { this.props?.translate(`client_history_of_changes`) }
                        </div>
                    </div>
                </div>
                <InfoBar
                    account={ account }
                    accountPropertyConfig={this.accountPropertyConfig}
                    profile={ profile }
                    translate={ translate }
                    user={ user }
                    enums = { enums }
                    dropdownItemNav={this.dropdownItemNav}
                    // closePopupAction={this.closePopupAction}
                    togglePopupItem={togglePopupItem}
                />
            </>
		);
	}
}

export default PartnerCardHeader;