import { DataService } from "./DataService";
import {del,get,post,put} from "../components/Magic/helpers/MagicRequest";
import { hash as hashData } from "./CachingService";
import { GET_HIERARCHY_TREE, GET_CRM_USERS, GET_DEPARTMENTS, GET_SUBORDINATION } from "../apiRoutes";

class HierarchyDataService extends DataService {
    constructor () {
        super([
            'tree',
            'editTeam',
            'addTeam',
            'deleteTeam',
            'crmUsers',
            'departments',
            'subordination'
        ]);
        Object.freeze(this);
    }

    async tree () {
        const url = `${GET_HIERARCHY_TREE}`;
        const data = await get(url);
        const hash = await hashData(data);
        const tree = { data, hash };
        this.emit('tree', tree);

        return tree;
    }

    async editTeam (id, obj) {
        const url = `${GET_HIERARCHY_TREE}/${id}`;
        const editTeam = await put(url, obj);
        this.emit('editTeam', editTeam);

        return editTeam;
    }

    async addTeam (obj) {
        const url = `${GET_HIERARCHY_TREE}`;
        const addTeam = await post(url, obj);
        this.emit('addTeam', addTeam);

        return addTeam;
    }

    async deleteTeam (id) {
        const url = `${GET_HIERARCHY_TREE}/${id}`;
        const deleteTeam = await del(url);
        this.emit('deleteTeam', deleteTeam);

        return deleteTeam;
    }

    async crmUsers () {
        const url = `${GET_CRM_USERS}`;
        const data = await get(url);
        const hash = await hashData(data);
        const crmUsers = { data, hash };
        this.emit('crmUsers', crmUsers);

        return crmUsers;
    }

    async departments () {
        const url = `${GET_DEPARTMENTS}`;
        const data = await get(url);
        const hash = await hashData(data);
        const departments = { departments: data, hash };
        this.emit('departments', departments);

        return departments;
    }

    async subordination () {
        //TODO: make cash for subordination
        const url = `${GET_SUBORDINATION}`;
        const data = await get(url);
        const hash = await hashData(data);
        const subordination = { data, hash };
        this.emit('subordination', subordination);

        return subordination;
    }
}

export const hierarchyService = new HierarchyDataService();
