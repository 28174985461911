import React from "react";
import Select from 'react-select';

const customStyles = {
    control: () => ({
        width: 100,
    }),
    singleValue: (base, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { opacity, transition };
    },
};

class StatusSelect extends React.Component {
    constructor (props) {
        super(props);
    }

    componentDidMount () {
        if (this.props.getData) {
            this.props.getData(this.props.translate);
        }
    }

    get options () {
        if (![1, 2, 4, 15].includes(+this.props.userRoleId)) {
            const arr = this.props.data;
            const index = arr.findIndex((item) => item.value == 4);

            index !== -1 && arr.splice(index, 1);

            return arr;
        }

        return this.props.data;
    }

    render () {
        const selectedOptionDefault = {
            value: this.props.task.tasks.task_states.id,
            label: this.props.translate(`tasks_createform_state_${this.props.task.tasks.task_states.id}`),
        };

        return (
            <div className = 'table-filter-select tooltip-wrapper'>
                <Select
                    isSearchable = {false}
                    className = {`task-select page-select status-${this.props.error('state_id')}`}
                    classNamePrefix = 'select'
                    placeholder = {this.props.translate(`tasks_list_select`)}
                    options = { this.options }
                    styles = { customStyles }
                    onChange = { this.props.handleChangeStatus }
                    value = { this.props.dataTask.state_id ? ((event) => this.event)() : selectedOptionDefault }
                />
                <div className = 'tooltip tooltip-top'>
                    { this.props.translate(`tasks_createform_state_${this.props.task.tasks.tasks.state_id}`) }
                </div>
            </div>
        );
    }
}

export default StatusSelect;
