import {
    itemsBuild,
    itemsDrain,
    itemsResort,
    CFG_ITEM_TYPE,
} from '../Config';

import { onEnabled, render, } from '../Mentor/withDropDown';

const f_uId = {
    'drop-down': '/api/core/user/drop-down?take=all',
    'o=': ['=', '!=',],
};

const p_uId = {
    onEnabled: onEnabled('users'),
    render: render(
        'users',
        _ => _ ? `${_?.name} ${_?.surname}` : '-',
    ),
};

const f_accountCountry = {
    'drop-down': '/api/core/country/with-account/drop-down?take=all',
    'o=': ['=', '!=', 'null', 'not_null'],
};

const p_accCountryId = {
    onEnabled: onEnabled('accCountries'),
    render: render('accCountries'),
};

const f_status = {
    'drop-down': false,
    'o=': ['=', '!=',],
};

export default {
    id: 191,
    ...[
        // filters
        ...[
            {
                f: ['core.account.surname', 'core.account.name',],
                id: 'f:partnerName',
            },
            {
                f: 'core.system_accounts.id',
                id: 'f:partnerAccount',
            },
            {
                f: 'core.user.id',
                id: 'f:uId',
                ...f_uId,
            },
            {
                f: 'partners.referral_code.ref_short',
                id: 'f:partnerReferralCode',
            },
            {
                f: 'core.country.id',
                id: 'f:accCountryId',
                ...f_accountCountry,
            },
            {
                f: 'partners.partners_setup.status',
                id: 'f:status',
                ...f_status,
            },
            {
                f: 'partners.daily_statistic.total_commission',
                id: 'f:partnerRenumeration',
            },
            {
                f: 'partners.referral_register.referral_count',
                id: 'f:partnerReferrals',
            },
            {
                f: 'partners.partners_setup.created_at',
                id: 'f:partnerRegistered',
            },
            {
                f: 'partners.requests.approved_date',
                id: 'f:partnerConfirmated',
            },
        ]
            .filter(_ => _ && !_.off)
            .map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

        // orders
        ...[{
            f: 'core.account.id',
            id: 'o:id',
        },
        {
            f: 'core.account.name',
            id: 'o:partnerName',
        },
        {
            f: 'core.system_accounts.id',
            id: 'o:partnerAccount',
        },
        {
            f: 'core.user.id',
            id: 'o:uId',
        },
        {
            f: 'partners.referral_code.ref_short',
            id: 'o:partnerReferralCode',
        },
        {
            f: 'core.country.id',
            id: 'o:accCountryId'
        },
        {
            f: 'partners.partners_setup.status',
            id: 'o:status',
        },
        {
            f: 'partners.daily_statistic.total_commission',
            id: 'o:partnerRenumeration',
        },
        {
            f: 'partners.referral_register.referral_count',
            id: 'o:partnerReferrals',
        },
        {
            f: 'partners.partners_setup.created_at',
            id: 'o:partnerRegistered',
        },
        {
            f: 'partners.requests.approved_date',
            id: 'o:partnerConfirmated',
        },
        ]
            .filter(_ => _ && !_.off)
            .map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

        // panels
        ...[
            {
                ii: 'f:partnerName',
                t: 'partners_list_partner_name',
            },
            {
                ii: 'f:partnerAccount',
                t: 'partners_list_partner_account',
            },
            {
                ii: 'f:uId',
                t: 'partners_list_partner_user',
                ...p_uId,
            },
            {
                ii: 'f:partnerReferralCode',
                t: 'partners_list_partner_code',
            },
            {
                ii: 'f:accCountryId',
                t: 'magic_filter_translate_statistics_account_core_country',
                ...p_accCountryId,
            },
            {
                ii: 'f:status',
                t: 'partners_list_partner_status',
            },
            {
                ii: 'f:partnerRenumeration',
                t: 'partners_list_partner_renumeration',
            },
            {
                ii: 'f:partnerReferrals',
                t: 'partners_list_partner_referrals',
            },
            {
                ii: 'f:partnerRegistered',
                t: 'partners_list_partner_registration_date',
            },
        ]
            .filter(_ => _ && !_.off)
            .map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

    ]
        .map(itemsBuild)
        .reduce(itemsDrain, [])
        .reduce(itemsResort, {}),

    path: 'partners/partners',
    widget: false,
};
