import {
	itemsBuild,
	itemsDrain,
	itemsResort,
	CFG_ITEM_TYPE,
} from '../Config';

const f_status = {
  'drop-down': false,
  'o=': [ '=', '!=', ],
};

const f_server = {
  'drop-down': '/api/partners/symbols-lists/drop-down?take=all',
  'o=': [ '=', '!=', ],
};

const f_list = {
  'drop-down': false,
  'o=': [ 'like', 'not_like', ],
};

export default {
	id: 178,
	...[

	// filters
	...[ {
		f: 'partners.symbols_lists.id',
		id: 'f:gId',
	},
	{
		f: 'partners.symbols_lists.name',
		id: 'f:gName',
	},
	{
		f: 'partners.symbols_lists.status',
		id: 'f:gStatus',
		...f_status,
	},
	{
		f: 'partners.symbols_lists.list',
		id: 'f:gList',
		o: 'like',
		...f_list,
	},
	{
		f: 'partners.symbols_lists.created_at',
		id: 'f:gCreated',
	},
	{
		f: 'partners.symbols_lists.updated_at',
		id: 'f:gUpdated',
	},
	{
		f: 'core.dictionary_trade_servers.type',
		id: 'f:gPlatform',
		...f_server,
	}, ]
	.filter(_ => _ && !_.off)
	.map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

	// orders
	...[ {
		f: 'partners.symbols_lists.id',
		id: 'o:gId',
	},
	{
		f: 'partners.symbols_lists.name',
		id: 'o:gName',
	},
	{
		f: 'partners.symbols_lists.amount',
		id: 'o:gAmount',
	},
	{
		f: 'partners.symbols_lists.status',
		id: 'o:gStatus',
	},
	{
		f: 'partners.symbols_lists.created_at',
		id: 'o:gCreatedAt',
	},
	{
		f: 'partners.symbols_lists.updated_at',
		id: 'o:gUpdatedAt',
	},
	{
		f: 'core.dictionary_trade_servers.type',
		id: 'o:gServer',
	}, ]
	.filter(_ => _ && !_.off)
	.map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

	// panels
	...[ {
		ii: 'f:gId',
		t: 'magic_filter_translate_partners_symbols_lists_id',
	},
	{
		ii: 'f:gName',
		t: 'magic_filter_translate_partners_symbols_lists_name',
	},
	{
		ii: 'f:gStatus',
		t: 'magic_filter_translate_partners_symbols_lists_status',
	},
	{
		ii: 'f:gCreated',
		t: 'magic_filter_translate_partners_symbols_lists_created_at',
	},
	{
		ii: 'f:gUpdated',
		t: 'magic_filter_translate_partners_symbols_lists_updated_at',
	},
	{
		ii: 'f:gPlatform',
		t: 'magic_filter_translate_partners_symbols_lists_server',
	},
	{
		ii: 'f:gList',
		t: 'magic_filter_translate_partners_symbols_lists_symbol',
	}, ]
	.filter(_ => _ && !_.off)
	.map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

	]
	.map(itemsBuild)
	.reduce(itemsDrain, [])
	.reduce(itemsResort, {}),

	path: 'partners/symbols-lists',
	widget: false,
};
