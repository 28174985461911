import React, { Component } from 'react';
import {withMediaProps, utils} from 'react-media-player';

const { formatTime } = utils;
class CustomSeekBar extends Component {
    _isPlayingOnMouseDown = false
    _onChangeUsed = false

    shouldComponentUpdate ({ media }) {
        return (
            this.props.media.currentTime !== media.currentTime ||
            this.props.media.duration !== media.duration
        );
    }

    _handleMouseDown = () => {
        this._isPlayingOnMouseDown = this.props.media.isPlaying;
        this.props.media.pause();
    }

    _handleMouseUp = ({ target: { value }}) => {
        // seek on mouseUp as well because of this bug in <= IE11
        // https://github.com/facebook/react/issues/554
        if (!this._onChangeUsed) {
            this.props.media.seekTo(Number(value));
        }

        // only play if media was playing prior to mouseDown
        if (this._isPlayingOnMouseDown) {
            this.props.media.play();
        }
    }

    _handleChange = ({ target: { value }}) => {
        this.props.media.seekTo(Number(value));
        this._onChangeUsed = true;
    }

    render () {
        const { className, style, media } = this.props;
        const { duration, currentTime } = media;

        return (
            <React.Fragment>
                <div className='media-line'
                    style = { {
                        position:        'relative',
                        width:           '100%',
                        height:          '20px',
                        backgroundColor: 'transparent',
                        marginTop: '5px'
                    }
                    }>
                    <div className='before'></div>
                    <div className='after'></div>
                    <div
                        style = { {
                            position:        'absolute',
                            left:            0,
                            top:             '9px',
                            width:           `100%`,
                            height:          '2px',
                            backgroundColor: '#f5f5f5',
                        }
                        }
                    />
                    <div
                        style = { {
                            position:        'absolute',
                            left:            0,
                            top:             '9px',
                            width:           `${currentTime * 100 / duration}%`,
                            height:          '2px',
                            backgroundColor: '#00c1a3',
                        }
                        }
                    />
                        <div
                            style = { {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position:        'absolute',
                                left:            `${currentTime * 100 / duration}%`,
                                top:             0,
                                width:           '40px',
                                height:          '20px',
                                marginLeft: '-20px',
                                backgroundColor: '#00c1a3',
                                borderRadius: '10px',
                                fontSize: '12px',
                                color: '#ffffff'
                            }
                            }>{formatTime(currentTime)}</div>
                    <div
                        style ={{
                            position: 'absolute',
                            left: '-20px',
                            bottom: '-17px',
                            fontSize: '12px',
                            color: '#a2a2a2',
                            marginRight: '-20px',
                        }
                        }
                        >{formatTime(currentTime)}</div>
                    <div
                        style ={{
                            position: 'absolute',
                            right: '-20px',
                            bottom: '-17px',
                            fontSize: '12px',
                            color: '#a2a2a2',
                        }
                        }
                    >{formatTime(duration)}</div>
                    <input
                        className = { className }
                        max = { duration.toFixed(4) }
                        onChange = { this._handleChange }
                        onMouseDown = { this._handleMouseDown }
                        onMouseUp = { this._handleMouseUp }
                        step = 'any'
                        style = { {
                            backgroundSize: `${currentTime * 100 / duration}% 100%`,
                            opacity:        '0',
                            position:       'absolute',
                            height:         '100%',
                            width:          '100%',
                            ...style,
                        } }
                        type = 'range'
                        value = { currentTime }
                    />
                </div>
            </React.Fragment>
        );
    }
}

export default withMediaProps(CustomSeekBar);
