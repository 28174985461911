import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import '../../styles/marketing.scss';
import { MagicInput, MagicSelect } from '../../../../components/Magic';
import { MagicTextarea } from '../../../tima/components/Magic/MagicTextarea/MagicTextarea';
import Loader from '../../../tima/components/Loader';
import Preloader from '../../../../components/LoadingHOC/Preloader';
import { MagicTooltip } from "../../../tima/components/Magic/MagicTooltip/MagicTooltip";

class ModalUtmCreateAndUpdate extends Component {
  static propTypes = {
    data: PropTypes.shape({
      name: PropTypes.node,
      number: PropTypes.node,
      description: PropTypes.node,
      status: PropTypes.node,
    }).isRequired,
    enums: PropTypes.object,
    titleKey: PropTypes.shape({
      form: PropTypes.string,
      name: PropTypes.string,
      number: PropTypes.string,
      description: PropTypes.string,
      status: PropTypes.string,
      save: PropTypes.string,
      cancel: PropTypes.string,
    }),
    translate: PropTypes.func.isRequired,
    viewOnly: PropTypes.arrayOf(PropTypes.string),
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    data: {},
    enums: {},
    titleKey: {},
    viewOnly: [],
  };

  wrapperRef = createRef();

  constructor (props) {
    super(props);
    this.state = {
      showSameItems: true,
      loaded: true,
      formData: {
        number: this.props.data?.number ?? this.props?.lastNumberPlusOne,
        ...props.isAddModal && { status: 1, },
      },
      errors: {},
    };
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      this.save({ showSameItems: false })
    } else {
      this.save({ showSameItems: true })
    }
  };

  save = async state => new Promise(next => this.setState(state, next));

  onChange =  (key, value) => {
    this.save(({ formData: fd, }) => ({ formData: { ...fd, [key]: value, } }));
  };

  onClose = () => {
    this.save({ formData: {}, errors: {}, });
    this.props.onClose();
    this.props.errors?.includes('name') ? this.props.clearError('name') : null;
  };

  onSave = async () => {
    try {
      await this.save({ loaded: false })
      if (this.props?.errors?.includes?.('number')) await this.props.getLastNumber();
      await this.props.onSave(this.state.formData);
      await this.save({ loaded: true })
    } catch (error) {
      console.log(error)
    }
  };

  renderName = () =>  {
    const LENGTH_SHORT = 30;

    return (
      this.props.viewOnly.includes('name')
        ? (
          <div className="disabled-background">
            <MagicTooltip
              classNameTooltip={`tooltip tooltip--large-width custom-tooltip`}
              content={ this.props.data?.name ?? '? Name ?' }
              lengthCut={ LENGTH_SHORT }
            />
          </div>
        )
        : (
        <div ref={this.wrapperRef}>
        <MagicInput { ...{
          className: this.props?.errors?.includes('name')
            ? 'marketing-modal__input-name error-value' :
            'marketing-modal__input-name',
          sizeable: false,
          value: this.props.isAddModal ? this.state?.formData?.name : this.props?.data?.name,
          onChange: value => {
            this.onChange('name', value)
            this.props.isSameChannels ? this.props.searchSameChannels(value) : null
            this.props.isSameSubChannels ? this.props.searchSameSubChannels(value) : null
            this.props.isSameProducts ? this.props.searchSameProducts(value) : null
            this.props.clearError('name');
          },
          onValid: value => {
            if ((value !== '' && value.length > 50)) {
              throw new Error('error');
            }},
        } } />
        </div>)
    );
  };

  ifNumberError = async () => {
    if (this.props.errors?.includes?.('number')) {
      return this.props.getLastNumber();
    }
  };

  renderNumber = () => {
    return (
      <MagicInput { ...{
        className: [`marketing-modal__input-num`],
        sizeable: false,
        disabled: true,
        value: `${this.state.formData?.number}` ?? `${this.props.data?.number}`,
        onChange: () => null, //to prevent errors in console
        onValid: value => {if (!!isNaN(value) || value.length > 3) throw new Error('error')}
      } } />)
  };

  renderDescription = () => {
    return (
      this.props.viewOnly.includes('description')
        ? (this.props.data?.description ?? '? Description ?')
        : (<div className="popup__field">
            <div className="popup__field_item">
              <div className="marketing-field-description" >
                <p className="edit-channel_popup__field_items-text">
                  { this.props.translate(`marketing_edit_description`) }
                </p>
              </div>
              <div className="textarea__wrapper">
                <MagicTextarea { ...{
                  className: `magic-textarea-modal scroll`,
                  notToFocus: true,
                  value: this.state.formData.description ?? this.props.data?.description ?? '',
                  onChange: ({ value }) => this.onChange('description', value),
                  maxLength: 255, } } />
              </div>
            </div>
          </div>
        )
    )
  };

  renderStatus = () => {
    const enums = this.props.enums.statistics.UtmCampaignStatusEnum;
    return this.props.viewOnly.includes('status')
      ? (this.props.data?.status ? enums[this.props.data?.status] : '? Status ?')
      : (<div className="marketing-field-status">
          <MagicSelect { ...{
          value: this.state.formData?.status ?? this.props.data?.status,
          values: Object.keys(enums),
          valueToLabel: (value) => enums[value] ?? enums[this.state?.formData?.status],
          onChange: value => this.onChange('status', value),
        } } />
        </div>);
  };

  renderSameItems = () => {
    const items = this.props.sameChannels ?? this.props.sameSubChannels ?? this.props.sameProducts;
    const LENGTH_SHORT = 23;

    return (
      <div className={ items.length >= 1 ? `marketing-same-channels` : null }>
        {items.map((item, index) => {
          return (
            <p key={index}>
              <MagicTooltip
                classNameTooltip={`tooltip tooltip--large-width tooltip-same-width`}
                content={ item.name ?? '-' }
                lengthCut={ LENGTH_SHORT }
              />
            </p>
          );
        })}
      </div>
    )
  };

  render () {
    return (
      <div className="edit-channel">
        <div className="edit-channel_popup">
          <Loader
            loaded={this.state.loaded}
            loading={(<Preloader scale={this.props.scale}/>)}
          >
          <div className="edit-channel_popup-container">
            <div className="edit-channel_popup__header">
              <div className="edit-channel_popup__title">
                { this.props.translate(this.props.titleKey.form ?? '') }
              </div>
              <div
                onClick={this.onClose}
                className="edit-channel_popup__close" />
            </div>
            <div className="edit-channel_popup__content">
              <div className="edit-channel_popup__field">
                <div className="edit-channel_popup__field_items">
                  <div className="marketing-same-channels_container">
                    <div className="edit-channel_popup__field_item marketing-name-block">
                      <p className="edit-channel_popup__field_items-text">
                        { this.props.translate(this.props.titleKey.name ?? '') }*
                      </p>
                      { this.renderName() }
                    </div>
                    {this.state.showSameItems ? (
                      <div>
                        {this.props.isSameChannels && this.state.formData?.name ? this.renderSameItems() : null}
                        {this.props.isSameSubChannels && this.state.formData?.name ? this.renderSameItems() : null}
                        {this.props.isSameProducts && this.state.formData?.name ? this.renderSameItems() : null}
                      </div>
                    ) : null}
                  </div>
                  <div className="edit-channel_popup__field_items-item marketing-number-block">
                    <p className="edit-channel_popup__field_items-text">
                      { this.props.translate(this.props.titleKey.number ?? '') }
                    </p>
                    { this.renderNumber() }
                  </div>
                </div>
                { this.renderDescription() }
                <div className="edit-channel_popup__description">
                  <p className="edit-channel_popup__field_items-text">
                    { this.props.translate(this.props.titleKey.status ?? '') }*
                  </p>
                  { this.renderStatus() }
                </div>
                <div className="edit-channel_popup__buttons">
                  <button
                    className="edit-channel_popup__buttons-save-cancel edit-channel_popup__buttons-save-cancel-save"
                    onClick={this.onSave}
                  >
                    { this.props.translate(this.props.titleKey.save ?? 'marketing_save_modal_data') }
                  </button>
                  <button
                    className="edit-channel_popup__buttons-save-cancel edit-channel_popup__buttons-save-cancel-cancel"
                    onClick={this.onClose}
                  >
                    { this.props.translate(this.props.titleKey.cancel ?? 'marketing_cancel_modal_data') }
                  </button>
                </div>
              </div>
            </div>
          </div>
          </Loader>
        </div>
      </div>
    )
  };
}

export default withRouter(withLocalize(ModalUtmCreateAndUpdate));
