import React, { Component } from 'react';
import { withLocalize } from "react-localize-redux";
import PropTypes from "prop-types";
import Preloader from "../../../../../components/LoadingHOC/Preloader";
import PermissionService from "../../../../../services/PermissionService";
import Loader from "../../../../tima/components/Loader";
import errorImg from "../../../../../images/error.svg";
import SumsubVerification from "./SumsubVerification";

class PopUpReVerification extends Component {
    static propTypes = {
        popUpClose:        PropTypes.func.isRequired,
        refreshClientData: PropTypes.func.isRequired,
    };

    constructor (props) {
        super(props);

        this.state = {
            loaded:                false,
            loadedInnerRequest:    true,
            refreshClientDataFlag: true, // HARD CODE - due to technical task
        };

        this.calcData = PermissionService.calc(this.PopUpVerificationConfigReset);
    }

    get PopUpVerificationConfigReset () {
        return [
            {
                path: ['core', 'account'],
                key:  'coreAccount',
            },
        ];
    }

    save = async (state) => new Promise(next => this.setState(state, next));

    componentDidMount = async () => {
        await this.save({ loaded: false, });
        await this.onDataLoad();
        await this.save({ loaded: true, });
    };

    componentWillUnmount = () => {

    };

    popUpCloseFunc = () => {
        const { refreshClientDataFlag } = this.state;
        const { popUpClose, refreshClientData } = this.props;

        refreshClientDataFlag && refreshClientData();
        popUpClose();
    };

    onDataLoad = async () => {
        try {

        } catch (error) {

        }
    };

    downloadFile = (filename, src) => {
        const a = document.createElement('a');

        a.href = src;
        a.download = filename;
        a.click();
        a.remove();
    };

    onFileDownloadBtnClick = (item, index) => () => {
        const { src, } = item;
        const { account_document = {} } = item?.core;
        const fileName = `${account_document?.file_name}.${account_document?.extension}`;

        this.downloadFile(fileName, src);
    };

    renderPopUpHeader = () => {
        const { translate, } = this.props;

        return (
            <div className='pop-up-header cont cont--with-border-bottom'>

                <h3 className='pop-up__name'>
                    {`${translate('client_re_verification_title')}`}
                </h3>

                <i className='gl-icon close-btn close-btn--big' onClick={this.popUpCloseFunc}/>
            </div>
        );
    };

    renderSumSubVerification = () => {
        const { accountId } = this.props;

        return (
            <SumsubVerification account_id={+accountId}/>
        );
    };

    _getEnumByFieldName = (fieldName) => {
        const { enums = {} } = this.props;
        let enumObj = {};

        try {
            switch (fieldName) {
                case 'type': {
                    enumObj = enums.AccountDocumentTypeEnum;
                    break;
                }
                case 'sub_type': {
                    enumObj = enums.AccountDocumentSubTypeEnum;
                    break;
                }
                case 'status': {
                    enumObj = enums.AccountDocumentStatusEnum;
                    break;
                }
                default: {
                    throw new Error(`_getEnumValueByFieldName: No such enum for field - ${fieldName}`);
                }
            }
        } catch (error) {
            console.log(error);
        }

        return enumObj;
    };

    renderDocumentsPreviewBlock = () => {
        const { translate, documents: accountDocuments = [] } = this.props;
        const { activeDocumentIndex = 0 } = this.state;
        const activeDocument = accountDocuments?.[activeDocumentIndex] ?? {};
        const { file_name: fileNameActive = '', extension: extensionActive = '', mime_type: mimeTypeActive = '' } = activeDocument?.core?.account_document ?? {};
        const isPdfFile = mimeTypeActive?.toLowerCase() === 'application/pdf';
        const alignPreviewBigClass = activeDocument?.src === 'error' ? 'align-center' : '';
        const classNameScroll = !isPdfFile ? 'scroll' : ''; // TODO: check the styles

        const _renderDocumentPreview = (item) => {
            return item?.core?.account_document?.mime_type === 'application/pdf' ?
                <i className='attachment_icon_task fi flaticon-pdf-file-format-symbol'/>
                :
                <img src={item.src !== 'error' ? item.src : errorImg} alt='image'/>;
        };

        const _renderHoverTextBlock = (fieldName, fieldValue, labelNameToTranslate) => {
            const labelText = translate(labelNameToTranslate) ?? '';
            const data = this._getEnumByFieldName(fieldName)?.[fieldValue] ?? '';

            return (
                <p className={'hover-block__text'}>
                    {`${labelText} - ${data}`}
                </p>
            );
        };

        const _renderDocumentsPreviewSmall = (item, index) => {
            const { alias_name = '', id = '', mime_type = '', type, sub_type, status, } = item?.core?.account_document ?? {};
            const isActiveClass = 'active';
            const alignPreviewSmallClass = mime_type?.toLowerCase() === 'application/pdf' || item?.src === 'error' ? 'align-center' : '';
            const statusToClassNameMap = {
                1: 'yellow',
                2: 'yellow',
                3: 'green',
                4: 'red',
                5: 'grey',
            };
            const statusClassName = statusToClassNameMap?.[status] ?? ''; // AccountDocumentStatusEnum

            return (
                <div
                    className={`documents-preview__small hover-block status-border status-border--${statusClassName} ${isActiveClass} ${alignPreviewSmallClass}`}
                    key={`key-${id}`}
                >
                    {_renderDocumentPreview(item)}

                    <div className={'hover-block__layout'}>
                        {_renderHoverTextBlock('type', type, 'client_re_verification_label_document_type')}
                        {_renderHoverTextBlock('sub_type', sub_type, 'client_re_verification_label_document_sub_type')}
                        {_renderHoverTextBlock('status', status, 'client_re_verification_label_document_status')}

                        {item?.src !== 'error' ? (
                            <button className={'file-download-btn hover-block__text icon icon--download'}
                                    onClick={this.onFileDownloadBtnClick(item, index)}
                            >
                                {translate('client_re_verification_download_btn')}
                            </button>
                        ) : null}
                    </div>
                </div>
            );
        };

        return (
            <div className='documents'>
                <div className='documents-preview'>
                    <div className='documents-preview__left'>
                        <div className={`documents-preview__big ${classNameScroll} ${alignPreviewBigClass}`}>
                            {this.renderSumSubVerification()}
                        </div>
                    </div>

                    <div className='documents-preview__right scroll'>
                        <div className='documents-title-cont'>
                            <p className='documents__title'>{translate('client_re_verification_title_uploaded_files')}</p>
                        </div>

                        {accountDocuments.map((item, index) => _renderDocumentsPreviewSmall(item, index))}
                    </div>
                </div>
            </div>
        );
    };

    render () {
        const { loaded, loadedInnerRequest, } = this.state;

        return (
            <Loader
                loaded={loaded}
                loading={(<Preloader className='loaderUniversal--fixed-pos' scale={1}/>)}
            >
                <div className='glalex-styles partners icon-styles'>
                    <div className='pop-up pop-up--re-verification pop-up--active'>

                        {!loadedInnerRequest ?
                            (
                                <Preloader className='loaderUniversal--fixed-pos' scale={1}/>
                            ) : null
                        }

                        <div className='pop-up-wrapper scroll'>
                            {this.renderPopUpHeader()}

                            <div className='pop-up-content'>
                                {this.renderDocumentsPreviewBlock()}
                            </div>
                        </div>
                    </div>
                </div>
            </Loader>
        );
    }
}

export default withLocalize(PopUpReVerification);
