import React, { Component } from 'react';
import AccessCheckboxPopup from './AccessCheckboxPopup';

class Access extends Component {
    constructor (props) {
        super(props);

        this.checked = false;
        this.state = {
            data:     this.props.data,
            name:     this.props.name,
            mainName: this.props.mainName,
        };
    }

    render () {
        return (
            <div className = 'access'>
                {Object.keys(this.state.data[this.state.name]).map((item, index) => {
                    let isChecked = false;
                    const module = this.state.mainName;
                    const stateName = this.state.name;
                    const method = item;

                    isChecked = this.props.isChecked(isChecked, module, stateName, method);

                    return (
                        <div className = 'access-item' key = { index }>
                            <div className = 'title'>
                                <label className = 'checkbox'>
                                    <input
                                        type = 'checkbox' value = 'checkedall' onClick = { () => {
                                            this.props.methodToggle(module, stateName, method, isChecked);
                                        } } checked = { isChecked }
                                    />
                                    <span />
                                </label>
                                {item}
                            </div>
                            <div className = 'access-scroll'>
                                <ul className = 'access-scroll-wrap' id = 'scroll-style2'>
                                    {Object.keys(this.state.data[this.state.name][item]).map((show, ind) => {
                                        const isFieldChecked = this.props.isFieldChecked(isChecked, item, show, module, stateName);
                                        const isEditPopup = this.props.isEditPopup(isFieldChecked, item, show, module, stateName);

                                        return (
                                            <li key = { ind }>
                                                <label className = 'checkbox'>
                                                    <input
                                                        checked = { isFieldChecked } key = { ind } type = 'checkbox' value = { show } onClick = { () => {
                                                            this.props.fieldToggle(module, stateName, method, show, isFieldChecked);
                                                        } }
                                                    />
                                                    <span />
                                                </label>
                                                {show}
                                                <AccessCheckboxPopup
                                                    addField = { this.props.addField }
                                                    getUserField = { this.props.getUserField }
                                                    isCheckedPopup = { isFieldChecked }
                                                    isEditPopup = { isEditPopup }
                                                    item = { item }
                                                    module = { module }
                                                    removeField = { this.props.removeField }
                                                    show = { show }
                                                    stateName = { stateName }
                                                />
                                            </li>
                                        );
                                    })
                                    }
                                </ul>
                            </div>
                        </div>
                    );
                })
                }
            </div>
        );
    }
}

export default Access;
