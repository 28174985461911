import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link, } from 'react-router-dom';
import { withLocalize, } from "react-localize-redux";
import { MagicButton, MagicSelect, MagicTable, } from '../../../components/Magic';
import { MagicConfirm, MagicTooltip, } from '../../tima/components/Magic';
import Moment from "moment";
import call_icon from "../../../images/call.svg";
import { enumService, } from "../../../services/EnumDataService";
import { callService, } from "../../../services/CallDataService";
import { supportService, } from "../../../services/SupportDataService";

class ListSupportMagicTable extends React.Component {
  static propTypes = {
    data: PropTypes.array,
    profile: PropTypes.object,
    refreshData: PropTypes.func,
    responsibles: PropTypes.array,
    translate: PropTypes.func,
  };

  static defaultProps = {
    data: [],
    profile: {},
    refreshData: () => {},
    responsibles: [],
    translate: () => {},
  };

  constructor (props) {
    super(props);

    this.state = {
      confirmClose: null,
    };

    Object.defineProperty(this, 'mf', { get: () => this.props.mf, });
  }

  get params () {
    return this.props?.match?.params;
  }

  get query () {
    return new URLSearchParams(this.props?.location?.search);
  }

  get supportConfig () {
    const { translate, } = this.props;
    const result = [ {
      key: 'ticketClientId',
      path: [ 'core', 'account', 'id', ],
    }, {
      key: 'ticketClientSurname',
      path: [ 'core', 'account', 'surname', ],
    }, {
      key: 'isPhoneVerified',
      path: [ 'core', 'account', 'is_phone_verify', ],
    }, {
      key: 'ticketManagerName',
      path: [ 'core', 'user', 'name', ],
    }, {
      key: 'ticketManagerSurname',
      path: [ 'core', 'user', 'surname', ],
    }, {
      key: 'ticketId',
      orderId: 'o:tId',
      path: [ 'tickets', 'tickets', 'id', ],
      render: this.renderTicketId,
      title: 'support_list_id',
    }, {
      key: 'ticketSubject',
      orderId: 'o:tSubject',
      path: [ 'tickets', 'tickets', 'subject', ],
      render: this.renderTicketSubject,
      title: 'support_list_subject',
    }, {
      key: 'ticketClient',
      orderId: 'o:aSurname',
      path: [ 'core', 'account', 'name', ],
      render: this.renderTicketClient,
      title: 'support_list_client',
    }, {
      key: 'ticketCall',
      path: [ 'core', 'account', 'id', ],
      render: this.renderTicketCall,
      title: 'support_list_call',
    }, {
      key: 'ticketCreatedAt',
      orderId: 'o:tCreatedAt',
      path: [ 'tickets', 'tickets', 'created_at', ],
      render: this.renderTicketDate,
      title: 'support_list_created_at',
    }, {
      key: 'ticketStateAppeal',
      orderId: 'o:tStatus',
      path: [ 'tickets', 'tickets', 'status', ],
      render: this.renderTicketStateAppeal,
      title: 'support_list_state',
    }, {
      key: 'ticketStatusClient',
      path: [ 'core', 'account', 'status', ],
      render: this.renderTicketStatusClient,
      title: 'support_list_client_status',
    }, {
      key: 'ticketUpdatedAt',
      orderId: 'o:tUpdatedAt',
      path: [ 'tickets', 'tickets', 'updated_at', ],
      render: this.renderTicketDate,
      title: 'support_list_updated_at',
    }, {
      key: 'ticketSubstatus',
      path: [ 'tickets', 'tickets', 'sub_status', ],
      render: this.renderTicketSubstatus,
      title: 'support_list_sub_status',
    }, {
      key: 'ticketSatisfaction',
      path: [ 'tickets', 'tickets', 'satisfaction', ],
      render: this.renderTicketSatisfaction,
      title: 'support_list_satisfaction',
    }, {
      key: 'ticketClose',
      path: [ 'tickets', 'tickets', 'status', ],
      render: this.renderTicketClose,
      title: 'support_list_close',
    }, {
      key: 'ticketResponsible',
      orderId: 'o:uSurname',
      path: [ 'core', 'user', 'id', ],
      render: this.renderTicketResponsible,
      title: 'support_list_responsible',
    }, {
      accessChecked: true,
      key: 'ticketClientManager',
      path: [ 'core', 'account', 'manager', ],
      render: this.renderTicketClientManager,
      title: 'support_list_client_manager',
    }, ].map(({
      orderId: o,
      title: t,
      xtitle: x,
      ...item
    }) => ({
      ...item,
      ...o && {
        order: async () => {
          await this.mf.orderedToNext(o);
          // await this.onDataLoad(); // TODO: remove cause reaction should be in subscribe
        },
        orderTo: this.mf.orderedTo(o),
      },
      ...t && { title: translate(t), },
      ...x && { title: x, },
    }));

    return result;
  }

  enumsChange = async (enums) => {
    if (enums.hash===this.state?.enums?.hash) {
      return `${ this.constructor.name }.enumsChange: false`;
    }
    await this.save({ enums, });

    return `${ this.constructor.name }.enumsChange: true`;
  };

  async componentDidMount () {
    enumService.subscribe('enums', this.enumsChange, this);
    await enumService.enums;
  }

  componentWillUnmount () {
    enumService.unsubscribe('enums', this.enumsChange, this);
  }

  save = state => new Promise(next => this.setState(state, next));

  sendCall = async (id) => {
    try {
      await callService.sendCall({ account_id: id });
    } catch (error) {
      error?.showErrorNotification?.();
    }
  };

  closeTicketById = async (id) => {
    try {
      const CLOSED_STATUS = 5;

      await this.props?.clearConfirmClose?.();

      const response = await supportService.closeTicketById(id, {
        status: CLOSED_STATUS,
      });

      if (response?.id === id && response?.status === 5) {
        await this.props?.refreshData?.();
      }
    } catch (error) {
      await this.save({ confirmClose: ticketId, });
      error?.showErrorNotification?.();
    }
  };

  changeResponsible = async (id, options) => {
    try {
      await supportService.changeResponsible(id, options);
      await this.props?.refreshData?.();
    } catch (error) {
      error?.showErrorNotification?.();
    }
  };

  renderConfirm = ({
    onAccept,
    onReject,
    isVisible,
    translate,
  }) => {
    const options = {
      accept: translate?.accept,
      isVisible,
      onAccept,
      onReject,
      reject: translate?.reject,
      title: translate?.title,
    };

    return (<MagicConfirm { ...options } />);
  };

  renderSupportLink = (path, text, className = "") => (
    <Link className={ className } to={ path } >
      { text }
    </Link>
  );

  renderTicketDate = (date, { item, }) => {
    if (item.access('index')) {
      const formatDB = 'YYYY-MM-DD HH:mm:ss';
      const formatOut = 'YYYY-MM-DD HH:mm:ss';

      date = Moment(date, formatDB);

      return date.isValid() ? date.format(formatOut) : "?";
    }

    return '-';
  };

  renderTicketId = (id, { item, }) => {
    const options = {
      content: String(id),
      lengthCut: 20,
    };
    const text = (<MagicTooltip { ...options } />);

    if (item?.access('index', 'show')) {
      return this.renderSupportLink(`/support/${ id }`, text, "table_item-bold-text");
    } else if (item?.access('index')) {
      return text;
    }

    return '-';
  };

  renderTicketSubject = (subject, { item, items, }) => {
    const ticketId = items?.ticketId?.valueOf;
    const options = {
      content: String(subject),
      lengthCut: 20,
    };
    const text = (<MagicTooltip { ...options } />);

    if (item?.access('index', 'show')) {
      return this.renderSupportLink(`/support/${ ticketId }`, text, "table_item-bold-text");
    } else if (item?.access('index')) {
      return text;
    }

    return '-';
  };

  renderTicketClient = (name, { item, items, }) => {
    const surname = items?.ticketClientSurname?.valueOf;
    const accountId = items?.ticketClientId?.valueOf;
    const options = {
      content: String(`${ name ?? "" } ${ surname ?? "" }`),
      lengthCut: 20,
    };
    const text = (<MagicTooltip { ...options } />);

    if (item?.access('index', 'show')) {
      return this.renderSupportLink(`/clients/${ accountId }`, text);
    } else if (item?.access('index')) {
      return text;
    }

    return '-';
  };

  renderTicketCall = (id, { item, items, }) => {
    if (item?.access('index')) {
      return (
        <img
          alt="phone client"
          className='call'
          data-is-verified={ items?.isPhoneVerified.valueOf }
          src={ call_icon }
          onClick={ () => this.sendCall?.(id) }
        />
      );
    }

    return '-';
  };

  renderTicketStateAppeal = (status, { item, }) => {
    if (item?.access('index')) {
      const stateAppeals = this.state?.enums?.data?.tickets?.TicketStatusEnum ?? {};

      return stateAppeals?.[+status] ?? "-";
    }

    return '-';
  };

  renderTicketStatusClient = (status, { item, }) => {
    if (item?.access('index')) {
      const clientStatuses = this.state?.enums?.data?.core?.AccountStatusEnum ?? {};

      return clientStatuses?.[+status] ?? "-";
    }

    return '-';
  };

  renderTicketSubstatus = (subStatus, {item,}) => {
    if (item?.access('index')) {
      const subStatuses = this.state?.enums?.data?.tickets?.TicketSubStatusEnum ?? {};
      return (subStatuses?.[+subStatus] ?? "-");
    }
    return '-';
  };

  renderTicketSatisfaction = (satisfaction, { item, }) => {
    if (item?.access('index')) {
      const satisfactions = this.state?.enums?.data?.tickets?.TicketSatisfactionEnum ?? {};
      return (satisfactions?.[+satisfaction] ?? "-");
    }
    return '-';
  };

  renderTicketClose = (status, {item, items}) => {
    const profile = this.props?.profile ?? {};
    const translate = {
      close: this.props.translate('support_list_close'),
      closed: this.props.translate('support_list_closed'),
    };
    const responsibleId = items?.ticketResponsible?.valueOf;
    const ticketId = items?.ticketId?.valueOf;
    if (item?.access('update') && profile?.isROP && !["5", 5].includes(status)) {
      const disabled = [null, 1].includes(responsibleId);
      const options = {
        className: [`magic-button__item_ticket-close ${disabled ? 'disabled' : ''}`],
        onClick: async () => await this.save({confirmClose: ticketId}),
        children: translate?.close,
        disabled,
      };
      return (
        <React.Fragment>
          <span style={ {position: 'relative'} }>
            { this.renderConfirm({
              onAccept: () => this.closeTicketById?.(ticketId),
              onReject: async () => await this.save({confirmClose: null}),
              translate: {
                title: this.props.translate('support_confirm_title'),
                accept: this.props.translate('support_confirm_accept'),
                reject: this.props.translate('support_confirm_reject'),
              },
              isVisible: this.state?.confirmClose === ticketId,
            }) }
            <MagicButton { ...options } />
          </span>
        </React.Fragment>
      )
      // если есть право, тикет закрыт и админ отд. продаж или техподдержка
    } else if (item?.access('index') && ["5", 5].includes(status) && (profile?.isROP || profile?.isTechnicalSupport)) {
      return <span>{ translate?.closed }</span>
    }
    return "-";
  };

  renderTicketClientManager = (manager, {item, items}) => {
    if ( items?.ticketClientId?.access('index') ) {
      const options = {
        content: manager ?? "",
        lengthCut: 20,
      };
      return (<MagicTooltip {...options} />);
    }
    return '-';
  };

  renderTicketResponsible = (responsibleId, {item, items}) => {
    const responsibles = this.props?.responsibles;
    const profile = this.props?.profile;
    const translate = {
      notResponsible: this.props.translate('support_list_not_responsible'),
    }
    const ticketStatus = +items?.ticketStateAppeal?.valueOf;
    const ticketId = items?.ticketId?.valueOf;
    const responsibleName = items?.ticketManagerName?.valueOf;
    const responsibleSurname = items?.ticketManagerSurname?.valueOf;
    if (item?.access('index') && ticketStatus === 5) {
      return responsibleId === null
        ? <span>{translate?.notResponsible}</span>
        : `${responsibleName ?? ""} ${responsibleSurname ?? ""}`;
    }
    else if (item?.access('index', 'update') && responsibles?.length && profile?.isROP) {
      const options = {
        value: responsibleId,
        onChange: value => {
          let data = value === null ? {SET_NULL__user_id: 1,} : {user_id: value,};
          responsibleId !== value ? this.changeResponsible?.(ticketId, data) : false;
        },
        values: responsibles?.map?.(responsible => responsible?.id),
        valueToLabel: value => {
          const idsResponsibles = responsibles?.map?.(item => item?.id);
          if (value === null || !idsResponsibles?.includes?.(value)) {
            return translate?.notResponsible;
          }
          const {name, surname} = responsibles?.find?.(item => item?.id === value);
          return `${name ?? ""} ${surname ?? ""}`;
        }
      };
      return <MagicSelect { ...options } />
    }
    else if (item?.access('index')) {
      return [null].includes(responsibleId)
        ? <span>{translate?.notResponsible}</span>
        : `${ responsibleName ?? ""} ${ responsibleSurname ?? ""}`;
    }

    return "-";
  };

  render () {
    if (!this.props?.data?.length) {
      return null;
    }

    const options = {
      config: this.supportConfig,
      data: this.props?.data,
      head: [
        "ticketId",
        "ticketSubject",
        "ticketClient",
        "ticketCall",
        "ticketCreatedAt",
        "ticketStateAppeal",
        "ticketStatusClient",
        "ticketUpdatedAt",
        "ticketSubstatus",
        "ticketSatisfaction",
        "ticketClose",
        "ticketClientManager",
        "ticketResponsible",
      ],
    };

    return (<MagicTable { ...options } />);
  }
}

export default withRouter(withLocalize(ListSupportMagicTable));
