import React, { Component, } from 'react';
import { withLocalize, } from "react-localize-redux";
import { MagicButton, MagicInput, } from "../../../../../components/Magic";
import {
	endConversation,
	sendMessage,
	switchResponsibleManager, switchToTechSupport,
	updateClientEmail, updateClientLanguage,
} from '../../../services/ConversationsRequestService';
import NotificationService from "../../../../../services/NotificationService";
import Preloader from "../../../../../components/LoadingHOC/Preloader";
import Loader from "../../../../tima/components/Loader";

class SendMessageBlock extends Component {
	constructor (props) {
		super(props);
		this.state = {
			isOpenedQuickMessagesBlock: false,
			isOpenedTechManagersBlock: false,
			isOpenedConversationActionsBlock: false,
			isOpenedSetLanguage: false,
			loaded: true,
			messageText: '',
			languages: [
				'Украинский',	 'Русский',
				 'Английский',
			],
		};
	}

	save = async state => new Promise(next => this.setState(state, next));
	get translate () {
		return this.props.translate;
	}

	handleSendQuickMessage = async (action) => {
		switch (action) {
			case 'actions': {
				await this.save({ isOpenedConversationActionsBlock: !this.state.isOpenedConversationActionsBlock, isOpenedQuickMessagesBlock: false, isOpenedTechManagersBlock: false, isOpenedSetLanguage: false  });
				break;
			}
			case 'quick_message': {
				await this.save({ isOpenedQuickMessagesBlock: !this.state.isOpenedQuickMessagesBlock, isOpenedTechManagersBlock: false, isOpenedSetLanguage: false });
				break;
			}
			case 'tech_managers': {
				await this.save({ isOpenedTechManagersBlock: !this.state.isOpenedTechManagersBlock, isOpenedQuickMessagesBlock: false, isOpenedSetLanguage: false });
				break;
			}
			case 'set_language' : {
				await this.save({ isOpenedSetLanguage: !this.state.isOpenedSetLanguage, isOpenedTechManagersBlock: false, isOpenedQuickMessagesBlock: false,  });
				break;

			}
			default: {
				break;
			}
		}

	}
	get isTechSupport () {
		return this.props?.managerProfile.tree_info?.some(department => department.department_alias === 'technical_support');
	}
	get isRetentionConversionSupport () {
		return this.props?.managerProfile.tree_info?.some(department => department.department_alias === 'client_support' || department.department_alias === 'conversion' || department.department_alias === 'retention');
	}

	handleSendMessage = (messageType, id) => async () => {
		const { conversationId, } = this.props;
		const { messageText, } = this.state;

		await this.save({ loaded: false, });

		switch (messageType) {
			case 'end_message' : {
				try {
					await endConversation(id);
					await this.save({ loaded: true, messageText: '', });
				} catch (error) {
					NotificationService.error({ title: 'error', message: error, remove: false, });
					await this.save({ loaded: true, messageText: '', });
				}
				break;
			}
			case 'send_message' : {
				try {
					await sendMessage({ content: messageText, conversation_id: conversationId, });
					await this.save({ loaded: true, messageText: '', });
				} catch (error) {
					NotificationService.error({ title: 'error', message: error, remove: false, });
					await this.save({ loaded: true, messageText: '', });
				}
				break;
			}
			case 'update_email' : {
				try {
					await updateClientEmail(id);
					await this.save({ loaded: true, });
				} catch (error) {
					NotificationService.error({ title: 'error', message: error, remove: false, });
					await this.save({ loaded: true, messageText: '', });
				}
				break;
			}
			case 'switch_responsible' : {
				try {
					await switchResponsibleManager(id);
					await this.save({ loaded: true, });
				} catch (error) {
					NotificationService.error({ title: 'error', message: error, remove: false, });
					await this.save({ loaded: true, messageText: '', });
				}
				break;
			}
			default: {
				break;
			}
		}

	}
	handleOnChangeQuickMessages = async (action, message = '', dialogId, userId) => {
		switch (action) {
			case 'quick_messages' : {
				await this.save({ isOpenedQuickMessagesBlock: !this.state.isOpenedQuickMessagesBlock, messageText: message, });
				break;
			}
			case 'set_tech_support' : {
				await this.save({ isOpenedTechManagersBlock: !this.state.isOpenedTechManagersBlock, });
				try {
					await switchToTechSupport(dialogId, userId);
				} catch (e) {
					console.log(e);
				}
				break;
			}
			default: {
				break;
			}
		}

	}
	handleSetLanguage = async (id, lang) => {
		const switchedLang = lang === 'Русский' ? 'ru' : lang === 'Украинский' ? 'uk' : lang === 'Английский' ? 'en' : '';

		try {
			await updateClientLanguage(id, switchedLang);
			await this.save({ isOpenedSetLanguage: false, loaded: true, });
		} catch (error) {
			NotificationService.error({ title: 'error', message: error, remove: false, });
			await this.save({ isOpenedSetLanguage: false, loaded: true, });
		}

	}
	handleOnChange = async (e) => {
		const { value, } = e.target;

		 await this.save((state) => {
			 return {
				 ...state,
				 messageText: value,
			 };
		 });
	}
	 render () {
		const {
			quickAnswersData, conversationData, contactId, techManagers,
		} = this.props;
	 	const {
			messageText, isOpenedQuickMessagesBlock, isOpenedTechManagersBlock, isOpenedSetLanguage, languages, isOpenedConversationActionsBlock
		} = this.state;
		const className = messageText ? 'activeConversationTab' : 'send_message_btn';
	 	const slicedConversationData = Object.assign({}, conversationData.slice(0, 1));
	 	const isTechSupport = this.isTechSupport;
	 	const isRetentionConversionSupport = this.isRetentionConversionSupport;
	 	const dialogId = slicedConversationData[Object.keys(slicedConversationData)].novatalks?.messages?.dialog_id;
	 	const contactsIdForLang = slicedConversationData[Object.keys(slicedConversationData)].novatalks?.messages?.contact_id;

		return (
			<div className="send_message_wrapper">
				<Loader loaded={ this.state.loaded } loading={ <Preloader scale={ 0.6 } /> }>
					<textarea
						className="send_message_input scroll-chat"
						placeholder={ this.translate('conversation_window_send_btn_placeholder') }
						value={ messageText }
						onChange={ e => this.handleOnChange(e) }
					/>
					<div className="send_btn_block">
						<div className="send_btn_block_wrapper" >

							<MagicButton
								className={ className }
								disabled={ !messageText }
								onClick={ this.handleSendMessage('send_message') }
							>{this.translate('conversation_window_send_btn')}
							</MagicButton>

							<div onClick={() => this.handleSendQuickMessage('actions') } className="conversation_actions_block" >
								<div className="conversation_actions_block_icon " />
								<p>{this.translate('conversation_channel_actions')}</p>
							</div>
								{isOpenedConversationActionsBlock && (
									<div className="conversation_actions_drop_down ">
										<div onClick={() => this.handleSendQuickMessage('quick_message') } className="justify-content-between conversation_list_items">
											<div className="d-flex" >
												<span className="conversation_quick_message_icon" />
												<span >{this.translate('conversation_window_quick_answers')}</span>
											</div>
											<span className="conversation_dropdown_arrow" />
										</div>
										{isTechSupport && (
											<>
												<div onClick={ this.handleSendMessage('update_email', contactId) } className="conversation_list_items">
													<span className="arrow-round" />
													<span>{this.translate('conversation_window_check_email')}</span>
												</div>
												<div onClick={ this.handleSendMessage('switch_responsible', dialogId) } className="conversation_list_items">
													<span className="switch_arrow" />
													<span >{this.translate('conversation_window_switch_to_responsible_btn')}</span>
												</div>
											</>
										)}

										{isRetentionConversionSupport && (
											<div onClick={() => this.handleSendQuickMessage('tech_managers') } className="justify-content-between conversation_list_items">
												<div className="d-flex">
													<span className="switch_arrow" />
													<span >{this.translate('conversation_window_switch_to_tech_support_btn')}</span>
												</div>
												<span className="conversation_dropdown_arrow" />
											</div >
										)}

										{(isRetentionConversionSupport || isTechSupport) && (
											<div onClick={() => this.handleSendQuickMessage('set_language') } className="justify-content-between conversation_list_items ">
												<div className="d-flex">
													<span className="world_icon" />
													<span>{this.translate('conversation_client_set_language')}</span>
												</div>
												<span className="conversation_dropdown_arrow" />
											</div>
										)}

									</div>
								) }


										{isOpenedQuickMessagesBlock && (
											<ul className="conversation_quick_message_wrapper" >
												{
													quickAnswersData?.map((msg, index) => {
														return (
															<li
																className="conversation_quick_message_list_items"
																key={ index }
																onClick={ () => this.handleOnChangeQuickMessages('quick_messages', msg.message) }
															>
																{msg.message}
															</li>

														);
													})
												}

											</ul>

										)

										}
								{ isOpenedTechManagersBlock && (
										<ul className="conversation_quick_message_wrapper" >
											{ techManagers.length ?
												techManagers?.map(({ name, surname, id }, index) => {
													const fullName =`${name} ${surname}`;
													return (
														<li
															className="conversation_quick_message_list_items"
															key={ index }
															onClick={ () => this.handleOnChangeQuickMessages('set_tech_support','', dialogId, id) }
														>
															{fullName}
														</li>

													);
												})
												:
												<li
													className="conversation_quick_message_list_items"
												>
													{this.translate('conversation_window_no_tech_managers')}
												</li>

											}

										</ul>

									)

								}
								{isOpenedSetLanguage && (
										<ul className="conversation_quick_message_wrapper_set-lang" >
											{
												languages?.map((lang, index) => {
													return (
														<li
															className="conversation_quick_message_list_items"
															key={ index }
															onClick={ () => this.handleSetLanguage( contactsIdForLang, lang) }
														>
															{lang}
														</li>

													);
												})
											}

										</ul>

									)
								}



						</div>
						<button className="conversation_end_btn" onClick={ this.handleSendMessage('end_message', dialogId) }>{this.translate('conversation_window_end_conversation')}</button>

					</div>
				</Loader>
			</div>
		);
	 }
}

export default withLocalize(SendMessageBlock);