import { Component, } from 'react';

/**
 * Validate Service constructor arguments
 *
 * @function
 * @arg {Object} settings - {
 *   serviceId: Number,
 *   translate: Function,
 *   owner: Component,
 * }
 * @returns {undefined} throw Error if any validation failed
 */
export const valid = (settings) => {
  const msg = text => new Error(`Expect Mentor${ text }`);

  if (!(settings instanceof Object)) {
    throw msg(`(settings) as Object`);
  }

  const { owner, serviceId, translate, } = settings;

  if (!(owner instanceof Component)) {
    throw msg(`.owner as Component`);
  }

  if (![ 'number', ].includes(typeof serviceId)) {
    throw msg(`.serviceId as Number`);
  }

  if (!(translate instanceof Function)) {
    throw msg(`.translate as Function`);
  }
};