import React from "react";

import PermissionService, {
	MODULE_KEYS as pm,
} from "../../services/PermissionService";

import Conversations from "./Conversations";

export const conversationsConfig = [
	{
		action: PermissionService.indexRoute(),
		component: <Conversations />,
		path: "/conversations",
		permission: pm.TASKS_TASKS,
	},
	{
		action: PermissionService.showRoute(),
		component: <Conversations />,
		path: "/conversations/:id",
		permission: pm.TASKS_TASKS,
	},
];