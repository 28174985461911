import React from 'react';
import * as PropTypes from 'prop-types';
import { MagicInput, } from '../../../../../MagicInput';

export class MagicFilterInputNumber extends React.Component {
	static propTypes = {
		disabled: PropTypes.bool.isRequired,
		filter: PropTypes.object.isRequired,
		placeholder: PropTypes.string.isRequired,
	};

	state = {
		v: null,
		vv: null,
	};

	save = async (state) => new Promise(next => this.setState(state, next));

	get filter() {
		return this.props.filter;
	}

	get mentor() {
		return this.filter.mentor;
	}

	onChange = (index) => async (value) => {
		const equal = value===`${+value}`;
		const match = Number.isFinite(+value);
		if ([0, 1].includes(index)) {
			let $v = this.filter.v;
			if (equal) {
				$v = Array.isArray($v) && $v.length === 2 ? $v : [null, null];
				const $value = $v[index];
				if (value===`${$value}`) {
					return;
				} 
				const v = Object.values({ ...$v, [index]: value, });
				await this.mentor.filterChange({
					id: this.filter.$id,
					setter: { v, },
					$$changed: true,
				});
				await this.save({ v: null, });
			} else if (match) {
				$v = this.state.v ?? $v;
				$v = Array.isArray($v) && $v.length === 2 ? $v : [null, null];
				const $value = $v[index];
				if (value===`${$value}`) {
					return;
				} 
				const v = Object.values({ ...$v, [index]: value, });
				await this.mentor.filterChange({
					id: this.filter.$id,
					setter: { v, },
					$$changed: true,
				});
				await this.save({ v, });
			} else {
				$v = this.state.v ?? $v;
				$v = Array.isArray($v) && $v.length === 2 ? $v : [null, null];
				const $value = $v[index];
				if (value===`${$value}`) {
					return;
				} 
				const v = Object.values({ ...$v, [index]: value, });
				await this.mentor.filterChange({
					id: this.filter.$id,
					setter: { v: [null, null], },
					$$changed: true,
				});
				await this.save({ v, });
			}
		} else {
			const $value = this.filter.v;
			const v = value;
			if (value===`${$value}`) {
				// value not changed
			} else if (equal) {
				await this.mentor.filterChange({
					id: this.filter.$id,
					setter: { v, },
					$$changed: true,
				});
				await this.save({ v: null, });
			} else if (match) {
				await this.mentor.filterChange({
					id: this.filter.$id,
					setter: { v, },
					$$changed: true,
				});
				await this.save({ v, });
			} else {
				await this.mentor.filterChange({
					id: this.filter.$id,
					setter: { v: null, },
					$$changed: true,
				});
				await this.save({ v, });
			}
		}
	};

	onValid = async (value) => {
		if (/^[0-9]*$/.test( value )) {
			return true;
		} else {
			throw new Error( 'Wrong value' );
		}
	}

	renderInput = (value, onChange, key) => (<MagicInput
		className={[
			'magic-filter-input-number',
			'magic-input__filter-input-number',
		]}
		disabled={ this.props.disabled }
		{ ...key!==undefined && { key, } }
		placeholder={ this.props.placeholder }
		value={ value===null ? '' : `${ value }` }
		onChange={ onChange }
		onValid={ this.onValid }
	/>);

	mentorSaving = async () => {
		await this.save({ vv: null, });
		// await this.mentor.filterChange({
		// 	id: this.filter.$id,
		// 	setter: { v: this.filter.v, },
		// 	$$changed: false,
		// });
	};

	async componentDidMount () {
		this.mentor.subscribe({
			saving: this.mentorSaving,
		}, this);
	}

	componentWillUnmount () {
		this.mentor.unsubscribe([
			this.mentorSaving,
		], this);
	}

	render = () => {
		if (this.filter.amountOfValues === Infinity) {
			const onChange = async ({ target: { value }}) => {
				let vv = [ ...value.matchAll(/[\d]+/g) ];
				vv = vv.map(m => m.shift());
				vv = [ ...new Set(vv), ];
				await this.mentor.filterChange({
					id: this.filter.$id,
					setter: { v: vv, },
					$$changed: true,
				});
				await this.save({ vv });
			};
			const s = this.state.vv;
			const v = this.filter.v;
			const value = Array.isArray(s) ? s : Array.isArray(v) ? v : [];
			return (<div style={{ display: 'inline-flex', }} ><textarea onChange={onChange} defaultValue={ value.join(', ') } /></div>);
		} else if (this.filter.amountOfValues === 2) {
			const v = this.state.v ?? this.filter.v;
			let items = Array.isArray(v) ? v : [null, null];
			items = items.map((v, i) => {
				let s = this.state.v;
				s = Array.isArray(s) ? s[i] : null;
				return this.renderInput(s ?? v, this.onChange(i), i);
			});
			return (<React.Fragment>{ items }</React.Fragment>);
		} else if (this.filter.amountOfValues === 1) {
			const s = this.state.v;
			const value = Array.isArray(s) ? null : s;
			return this.renderInput(value ?? this.filter.v, this.onChange(), 0);	
		} else {
			return null;
		}
	};
};
