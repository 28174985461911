import { Component } from "react";
import React from "react";
import {securedRequest} from "../../../../services/RequestService/index";
import {DOWNLOAD_DOCUMENT} from "../../../../apiRoutes";
import {viewAttachment} from "../../services/TaskHelperService";

export default class AttachmentRow extends Component {

    constructor (props) {
        super(props);

        this.state = {
            showPopup: false,
        };
    }
    componentDidMount () {
        this.getFile();
    }

    componentWillUnmount () {
        this.props.fileImgFuncClear(this.props.attachment['tasks-task_documents-id']);
    }

    _fileEncode = (arrayBuffer) => {
        const b64encoded = btoa([].reduce.call(new Uint8Array(arrayBuffer), (p, c) => {
            return p+String.fromCharCode(c);
        }, ''));

        return {
            "mimetype": this.props.attachment['tasks-task_documents-mime_type'],
            "base64":   b64encoded,
        };
    };

    getFile = () => {
        return securedRequest
            .get(`${DOWNLOAD_DOCUMENT}/${this.props.attachment['tasks-task_documents-alias_name']}`, {
                responseType: 'arraybuffer',
            })
            .then((response) => {
                const fileEncode = this._fileEncode(response.data);
                const src = `data:${fileEncode.mimetype};base64,${fileEncode.base64}`;

                let file = <img onClick = { this.setFileSrc } alt = '' data-src = { src } src = { src } width = '100px' />;

                if (fileEncode.mimetype === 'application/pdf') {
                    file = <i
                        onClick = { this.setFileSrc }
                        data-src = { `data:application/pdf;base64,${fileEncode.base64}` }
                        className = 'attachment_icon_task fi flaticon-pdf-file-format-symbol'
                    />;
                }

                this.setState({
                    file,
                });
                this.props.fileImgFunc({
                    id: this.props.attachment['tasks-task_documents-id'],
                    src,
                });
            });
    };

    togglePopup = () => {
        this.setState({
            showPopup: !this.state.showPopup,
        });
    };

    render () {
        return (
            <div className = 'attachment-flex' onClick={ () => this.props.downloadDoc(this.props.attachment['tasks-task_documents-alias_name']) }>
                {/*{this.state.showPopup ?
                    <div className = 'lead-document-row'>
                        <div className = 'document-viewer'>
                            <span className = 'close_view_doc' onClick = { this.togglePopup } />
                            <DocumentSlider
                                document = { this.props.attachment }
                                documents = { this.props.documents }
                                downloadFile = { this.props.downloadDoc }
                                fileImage = { this.props.fileImage }
                                // togglePopup = { this.togglePopup }
                            />
                        </div>
                    </div>
                : null
                }*/}
                <div
                    className = 'attachment-img'>
                    { viewAttachment(this) }
                </div>
                <div className = 'attachment-text'>
                    <div>{this.props.attachment['tasks-task_documents-file_name']}</div>
                    <div className='attachment-text-create'>
                        {this.props.attachment['tasks-task_documents-created_at']}
                    </div>
                </div>
                {/* <img
                    onClick = { () => {
                        this.props.deleteDocumentAction(this.props.type.document, this.props.attachment['tasks-task_documents-id']);
                    } } className = 'delete-attachment' src = { deleteButton } alt = 'delete'
                /> */}
            </div>
        );
    }
}
