import React, { Component } from "react";
import {Link, withRouter} from "react-router-dom";
import { withLocalize, } from "react-localize-redux";

import Preloader from "../../../../../components/LoadingHOC/Preloader";
import PagePanel from "../../../../tima/components/Pages/PagePanel";
import Loader from "../../../../tima/components/Loader";
import {
  MagicFilterPanels,
  Mentor,
  MagicTable
} from '../../../../../components/Magic';
import { MagicConfirm } from "../../../../tima/components/Magic/MagicConfirm";
import { MagicRadioSwitch } from "../../../../tima/components/Magic/MagicSwitch";
import CreatePartnersAccrual from "../popup/CreatePartnersAccural";
import CreateUpdateAffiliatePrograms from "../../AffiliateProgramsManagement/AffiliatePrograms/Popup/CreateUpdateAffiliatePrograms";
import CreateUpdateNewPartnersConditions from "../../AffiliateProgramsManagement/PayoutsPartnersRemuneration/Popup/CreateUpdateNewPartnersConditions";

import NotificationService from "../../../../../services/NotificationService";
import { enumService } from "../../../../../services/EnumDataService";
import { getPartnersAccruals, getPartnersAccrualsByPartner, putPartnersAccruals } from "../../../../../services/PartnersRequestService";

import getEditDataForAffiliateProgramsPopup from "../../../helpers/getEditDataForAffiliateProgramsPopup";
import getEditDataForNewPartnersConditionPopup from "../../../helpers/getEditDataForNewPartnersConditionPopup";
class ListPartnersAccruals extends Component {
  constructor (props) {
    super(props);

    this.state = {
      autoFilterWasApplied: false, //auto filter triggers addition call of onDataLoad method, which triggers loader twice, state check prevents it;
      loaded:             false,
      data:               [],
      pages:              {
        filter:   0,
        total:    0,
        take:     50,
        variance: 2,
      },
      filterLastChange:   Date.now(),
      activeLangIndex:    this.getActiveLangIndex(),
      magicEditValuePrev: {},
      confirm: false,

      programPopupIsOpen: false,
      programPopupData: {},

      conditionPopupIsOpen: false,
      conditionPopupData: {},
    };

    const mf = new Mentor({
      owner: this,
      serviceId: this.autoFilterByAccountId ? 175 : 176,
      translate: this.props.translate,
    });

    Object.defineProperty(this, 'mf', { get: () => mf, });
  }

  get autoFilterByAccountId(){
    return this.props.accountId;
  }

  // eslint-disable-next-line react/sort-comp, require-await
  save = async state => new Promise(next => this.setState(state, next));

  mfChanged = async () => {
    const { handleFilterIdChange, } = this.props;
    const { filterId, } = this.mf;
    await handleFilterIdChange(filterId);
    await this.save({ filterLastChange: Date.now(), });
  };

  mfDoing = async () => {
    await this.save({ loaded: false, });
  };

  mfDone = async () => {
    await this.mfChanged();
    await this.onDataLoad();
  };

  enumsChange = async (enums) => {
    if (enums.hash===this.state?.enums?.hash) return `${this.constructor.name}.enumsChange: false`;
    await this.save({ enums, });
    return `${this.constructor.name}.enumsChange: true`;
  };

  query = () => new URLSearchParams(this.props.location.search);

  async componentDidMount() {
    enumService.subscribe('enums', this.enumsChange, this);
    await enumService.enums;

    this.mf.subscribe({
      changed: this.mfChanged,
      // changing: ,
      cleaned: this.mfDone,
      cleaning: this.mfDoing,
      loaded: this.mfDone,
      loading: this.mfDoing,
      saved: this.mfDone,
      saving: this.mfDoing,
    }, this);
    await this.mf.init({ doEmit: true, });

    if(this.autoFilterByAccountId) {
      await this.useAccountAutoFilter();
      await this.mfDone();
    }
  }

  componentWillUnmount() {
    this.mf.unsubscribe([
      this.mfChanged,
      this.mfDoing,
      this.mfDone,
    ], this);

    enumService.unsubscribe('enums', this.enumsChange, this);
  }

  useAccountAutoFilter = async () => {
    const accountId =  this.props.accountId;

    await this.mf.filterChange({
      id: 'f:accountId',
      setter: { v:accountId, enabled: 1, },
      $$changed: true,
    });

    await this.mf.apply({ doEmit: false, });
    await this.save({autoFilterWasApplied: true,})
  }
  getConditionParameters = (items, id) => {
    const level = items.conditionLevel.valueOf;
    const type = items.conditionType.valueOf;

    return this.getEditRouteParameters(level, type, id);
  };

  getEditRouteParameters = (levelId, typeId, conditionId) => {
		const level = levelId === 2 ? "Multiple" : "Single";

		let type =
			this.state.enums.data?.partners?.TypeEnum?.[+typeId].toLowerCase() ?? "-";

		type = type.includes("/") ? type.split("/").join("") : type; // in/out -> inout

		return `${ type }${ level }/${ conditionId }`;
	};	

  getActiveLangIndex = () => {
    let index = 0;

    switch (this.props.activeLanguage.code) {
      case 'en':
      default: {
        index = 0;
        break;
      }
      case 'ru': {
        index = 1;
        break;
      }
      case 'uk': {
        index = 2;
        break;
      }
    }

    return index;
  };
  get listPartnersAccrualsConfig () {
    const { translate, } = this.props;
    const prefix = 'partners_accruals_table_';

    const result = [ {
      path: ['core', 'account', 'id'],
      key:  'clientId',
      render: this.renderInlineValue,
      title: `partners_create_referral_partner_id`,
      orderId: 'o:accountId',
    }, {
      path: ['core', 'account', 'name'],
      key:  'clientName',
    }, {
      path: ['core', 'account', 'surname'],
      key:  'clientSurname',
    }, {
      accessChecked: true,
      key: 'ibPartner',
      render: this.renderFullNameField('client', '/clients'),
      title: `partners_list_partner_name`,
      orderId: 'o:aSurname',
    }, {
      path: ['partners', 'accruals', 'partner_account'],
      key: 'accountNumber',
      render: this.renderLinkField('accountNumber', '/list-accounts/systems'),
      title: `${prefix}partner_acccount`,
      orderId: 'o:paAccount',
    }, {
      path: ['partners', 'accruals', 'amount'],
      key: 'amount',
      render: this.renderInlineValue,
      title: `${prefix}amount`,
      orderId: 'o:paAmount',
    }, 
    {
      path: ['partners', 'prev_accruals', 'affiliate_program_id'],
      key: 'accountNumberId',
      render: this.renderProgramId,
      title: `${prefix}partner_program_id`,
      orderId: 'o:paAccountNumberId',
    },
    {
      path: ['partners', 'prev_accruals', 'condition_id'],
      key: 'conditionId',
      render: this.renderConditionId,
      title: `${prefix}partner_condition_id`,
      orderId: 'o:paConditionId',
    },
    {
      path: ['partners', 'new_partners_conditions', 'level'],
      key: 'conditionLevel',
      render: this.renderEnumField('partners', 'LevelEnum'),
      title: `${prefix}partner_condition_level`,
      orderId: 'o:paConditionLevel',
    },
    {
      path: ['partners', 'new_partners_conditions', 'type'],
      key: 'conditionType',
      render: this.renderEnumField('partners', 'TypeEnum'),
      title: `${prefix}partner_condition_type`,
      orderId: 'o:paConditionType',
    },
    {
      path: ['partners', 'accruals', 'acc_ccy'],
      key: 'currency',
      render: this.renderEnumField('core', 'AccountCurrencyEnum'),
      title: `${prefix}currency`,
      orderId: 'o:paCurrency',
    }, {
      path: ['partners', 'accruals', 'status'],
      key: 'status',
      render: this.renderStatus,
      title: `${prefix}status`,
      orderId: 'o:paStatus',
    }, {
      path: ['partners', 'accruals', 'created_at'],
      key: 'createdAt',
      render: this.renderInlineValue,
      title: `${prefix}created_at`,
      orderId: 'o:paCreated',
    },
    {
      path: ['partners', 'accruals', 'updated_at'],
      key: 'updatedAt',
      render: this.renderInlineValue,
      title: `${prefix}updated_at`,
      orderId: 'o:paUdated',
    },
    {
      path: ['partners', 'accruals', 'description'], // !!! DUPLICATE STATUS IN PAYMENT !!!
      key: 'paymentDescription',
      render: this.renderInlineValue,
      title: `${prefix}partner_comment`,
    }, 
     {
      path: ['partners', 'accruals', 'status'], // !!! DUPLICATE STATUS IN PAYMENT !!!
      key: 'paymentConfirmation',
      render: this.renderPaymentConfirmation,
      title: `${prefix}payment_confirmation`,
    }, ]
    .map(({
      orderId: o,
      title: t,
      xtitle: x,
      ...item
    }) => ({
      ...item,
      ...o && {
        order: async () => {
          await this.mf.orderedToNext(o);
          // await this.onDataLoad(); // TODO: remove cause reaction should be in subscribe
        },
        orderTo: this.mf.orderedTo(o),
      },
      ...t && { title: translate(t), },
      ...x && { title: x },
    }));
    return result;
  }

  renderLink = (path, text, className = "") => (<Link to={path} className={className}>{text}</Link>);

  renderInlineValue = (value, { item, items }) => {
    if (item?.access?.('index')) {
      return value ?? '-';
    }
    return '-';
  };

  renderEnumField = (path, enumName) => (value, { item }) => {
    if (item?.access?.('index')) {
      const enums = this.state?.enums?.data?.[path]?.[enumName];
      return enums?.[value] ?? '-';
    }
    return '-';
  };

  renderStatus = (value, { item, index: rowIndex }) => {
    if (item?.access?.('index')) {

      const refValue = this.state?.magicEditValuePrev?.[rowIndex]?.['status'] ?? +value;
      const enums = this.state?.enums?.data?.partners?.PartnerAccrualStatusEnum;
      let enumValue = enums[refValue];
      let statusClass;

      switch(refValue){ 
        case(1):{
          statusClass = `accrual-status accrual-status--declined`;
          break
        }
        case(2):{
          statusClass = `accrual-status accrual-status--paid`;
          break
        }
        case(3):{
          statusClass = `accrual-status accrual-status--frozen`;
          break
        }
        default: {
          statusClass = `accrual-status accrual-status--new`;
        }
      }


      return (<div className={statusClass}>{enumValue}</div>);
    }
    return '-';
  };

  renderFullNameField = (fieldName, path) => (value, { items }) => {
    const id = items?.[`${fieldName}Id`];
    const name = items?.[`${fieldName}Name`];
    const surname = items?.[`${fieldName}Surname`];
    // if (id?.access('show') && name?.access?.('index') && surname?.access?.('index')) {
    //   return this.renderLink(`${path}/${ id.valueOf }`, `${name.valueOf} ${surname.valueOf ?? ''}`);
    // } else 
    if (name?.access?.('index') && surname?.access?.('index')) {
      return `${ surname.valueOf ?? '' } ${ name.valueOf }`;
    }
    return '-';
  };

  // OLD FUNCTION
  // renderLinkField = (fieldName, path) => (value, { item, items }) => {
  //     const id = items?.[`${fieldName}Id`];
  //     if (item?.access?.('index', 'show')) {
  //         return this.renderLink(`${path}/${ id.valueOf }`, value);
  //     } else if (item?.access?.('index')) {
  //         return value;
  //     }
  //     return '-';
  // };

  renderLinkField = (fieldName, path) => (value, { item, items }) => {
    if (item?.access?.('index', 'show')) {
      return value ? this.renderLink(`${path}/${ value }`, value) : '-';
    } else if (item?.access?.('index')) {
      return value;
    }
    return '-';
  };

  renderProgramId = (value, { item, items }) => {
    if (item?.access?.('index', 'show')) {
        return value ? (
          <div
            className="cursor-pointer"
            onClick={ () =>
              this.toggleProgramPopup(value)
            }
          >{value} 
          </div>
        ) :
          "-"
        ;
    } else if (item?.access?.('index')) {
      return value;
    }
    return '-';
  };

  renderConditionId = (value, { item, items }) => {
    if (item?.access?.('index', 'show') && value) {
        const conditionParameters = this.getConditionParameters(items, value);
        return value ? (
          <div
            className="cursor-pointer"
            onClick={ () =>
              this.toggleConditionPopup(conditionParameters)
            }
          >{value} 
          </div>
        ) :
          "-"
        ;
    } else if (item?.access?.('index')) {
      return value ?? "-";
    }
  };


  renderPaymentConfirmation = (value, { item, items, index: rowIndex }) => {
    const { translate } = this.props;
    const fieldName = 'payment_confirmation';
    const refValue = this.state?.magicEditValuePrev?.[rowIndex]?.['status'] ?? +value;

    if (item?.access?.('index') && item?.access?.('update') && !(+refValue)) {
      const isVisible = this.confirmCheck(fieldName, rowIndex);
      const magicEditValuePrevByRowIndex = this.state?.magicEditValuePrev?.[rowIndex]?.[fieldName];

      return (
        <div className="magic-confirm-wrapper">
          <MagicConfirm
            onAccept={this.confirmAcceptHandler(fieldName, rowIndex, magicEditValuePrevByRowIndex)}
            onReject={this.confirmRejectHandler(fieldName, rowIndex, magicEditValuePrevByRowIndex)}
            title={translate(`magic_confirm_title`)}
            accept={translate(`magic_confirm_yes`)}
            reject={translate(`magic_confirm_no`)}
            isVisible={!!isVisible}
          />

          <MagicRadioSwitch
            className={`magic-radio-switch magic-radio-switch--accept-reject-btns magic-radio-switch--payment-confirmation`}
            classNameText={`magic-switch__text`}
            direction={`column-reverse`}
            indexNone={true}
            index={-1}
            reverse={false}
            texts={this.props?.paymentConfirmationTexts?.[this.state?.activeLangIndex]}
            values={[1, 2]}
            onChange={this.onChange(fieldName, rowIndex)}
            updateStateFromProps={true}
          />
        </div>
      );
    }
    return '-';
  };

  /*index = (fieldName) => {
    switch (fieldName) {
      case 'status':
      case 'payment_confirmation': // !!! DUPLICATE STATUS IN PAYMENT !!!
        return +this.props.data.partners.accruals.status;
      default:
        throw new Error(`Error index( fieldName: ${ fieldName } }`);
    }
  };*/

  onChange = (fieldName, rowIndex) => ({ value }) => {
    this.setConfirm([rowIndex, fieldName, value]);
  };

  confirmAcceptHandler = (fieldName, rowIndex, valuePrev) => async (event) => {
    const confirm = this.confirmCheck(fieldName, rowIndex);
    if (!confirm) {
      this.setConfirm(false);
      return;
    }
    const value = confirm[2];
    const accrualsId = this.state?.data?.[rowIndex].partners?.accruals?.id;

    fieldName = fieldName === 'payment_confirmation' ? 'status' : fieldName;

    try {
      const data = { [fieldName]: value };
      const response = await putPartnersAccruals(accrualsId, data);
      if (!response) {
        throw new Error(`Can*t update the fieldName: '${fieldName}', please try again later!`);
      }

      const state = {
        magicEditValuePrev: {
          ...this.state.magicEditValuePrev,
          [rowIndex]: {
            ...this.state.magicEditValuePrev[rowIndex],
            [fieldName]: +value,
          },

        },
      };
      this.setState(state);

    } catch (error) {
      NotificationService.error({
        title:   "error",
        message: error.message,
        remove:  false,
      });
    }
    this.setConfirm(false);

  };

  confirmRejectHandler = (fieldName, rowIndex, valuePrev) => (event) => {
    this.setConfirm(false);
  };

  confirmCheck = (fieldName, rowIndex) => {
    const { confirm } = this.state;
    return confirm instanceof Array && confirm[0] === rowIndex && confirm[1] === fieldName ? confirm : false;
  };

  setConfirm = (confirm) => this.setState({ confirm });

  toggleProgramPopup = async (id) => {
		const editFormData = await getEditDataForAffiliateProgramsPopup(id);

		await this.save({
			programPopupData: editFormData,
			programPopupIsOpen: true,
		});
	};

  toggleConditionPopup = async (conditionParameters) => { 
    const editFormData = await getEditDataForNewPartnersConditionPopup(conditionParameters);

		await this.save({
			conditionPopupData: editFormData,
			conditionPopupIsOpen: true,
		});
  }

  render = () => {
    const { translate, showPopup, activeLanguage, popupClose, accountId} = this.props;
    const { data, programPopupIsOpen, programPopupData, conditionPopupIsOpen, conditionPopupData, pages,} = this.state;

    const isEmptyData = data.length === 0
    const moreThanOnePage = pages.total > pages.take;

    const options = {
      config: this.listPartnersAccrualsConfig,
      data,
      head:   [
        'clientId',
        'ibPartner',
        'accountNumber',
        'amount',
        'accountNumberId',
        'conditionId',
        'conditionLevel',
        'conditionType',
        // 'currency',
        'status',
        'createdAt',
        'updatedAt',
        'paymentDescription',
        'paymentConfirmation',
      ],
    };

	const enums = this.state?.enums?.data;
	const popupOptions = {
	  refreshChildData: this.onDataLoad,
	  popUpClose: popupClose,
      accountId,
	  activeLanguage,
	  translate,
	  enums,
	};

    return (
      <>
      	{programPopupIsOpen && (
					<CreateUpdateAffiliatePrograms
						enums={ enums }
						formData={ programPopupData }
						isViewMode={ true }
						onClose={ async () => {
							await this.save({ programPopupIsOpen: false, });
						} }
						onDataLoad={ this.onDataLoad }
					/>
				)}
        {conditionPopupIsOpen && (
					<CreateUpdateNewPartnersConditions
						enums={ this.state?.enums }
						formData={ conditionPopupData}
						isViewMode={ true }
						onClose={ async () => {
							await this.save({ conditionPopupIsOpen: false, });
						} }
						onDataLoad={ this.onDataLoad }
					/>
				)}
        {showPopup && <CreatePartnersAccrual { ...popupOptions } />}
        {
          !this.autoFilterByAccountId &&
            <MagicFilterPanels
                mf={ this.mf }
                show={ true }
                translate={ translate }
            />
        }

        <div className='content-block'>
          <div className='partners-partners__table'>
            <div className='position-relative'>

              <Loader
                loaded={this.state.loaded}
                loading={(<Preloader scale={this.props.scale}/>)}
                translate={translate}
              >

                {isEmptyData ? (
                <div className="table__empty-text">
                  <h4 className={'text-center'}>{translate("symbols_empty")}</h4>
                </div>
              )
                  :
                    <div className={'partners__table'}>
                      <MagicTable {...options} />
                    </div>
                }
                {moreThanOnePage && <PagePanel
                    filter={pages.filter}
                    page={this.pageId()}
                    take={pages.take}
                    total={pages.total}
                    variance={pages.variance}
                    onClick={this.onPageClick}
                    doText={this.onPageText}
                    doTo={(pageId) => `?page=${pageId}`}
                />}
              </Loader>

            </div>
          </div>
        </div>
      </>
    );
  };

    pageId = () => {
        const page = this.query().get('page');
        return Number(page) || 0;
    };

  pageIdAsGET = (pageId) => {
    const pages = this.state.pages;

    // eslint-disable-next-line no-param-reassign
    pageId = pageId === undefined ? this.pageId() : pageId;
    const { filterId, } = this.mf;
    const result = {
      filterId,
      skip: pageId * pages.take,
      take: pages.take,
    };

    return result;
  };

  onDataLoad = async (pageId) => {
    // RESET magicEditValuePrev - to fix bug with shift state for element #0 onCreatePartnersAccrual
    await this.save({ loaded: false, magicEditValuePrev: {}, });
    const pageOptions = this.pageIdAsGET(pageId);
    const loaded = (data, meta) => ({ pages, }) => ({
      data,
      pages: { ...pages, ...meta, },
    });
    try {
      const response = await getPartnersAccruals(pageOptions);
      const { data = [], meta: { filter = 0, total = 0, }, } = response;
      await this.save(loaded(data, { filter, total, }));
    } catch (error) {
      await this.save(loaded([], { filter: 0, total: 0, }));
      error?.showErrorNotification?.();
    }

    if(this.autoFilterByAccountId) { //only when component in partnerCard;
      return this.state.autoFilterWasApplied && await this.save({loaded: true,});//when filter is added automatically, loader triggers twice - we want avoid this behavior
    }

    await this.save({loaded: true,})
  };

  onPageClick = async ({ event, pageId, pageIs, }) => {
    await this.onDataLoad(pageId);
  };

  onPageText = (pageId, pageIs) => {
    const { translate } = this.props;
    const { current, first, prev, next, last, } = pageIs;
    const { skipped, taken, filter, total, } = pageIs;
    if (skipped || taken) {
      const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : '?';
      const text = skipped ? translate('partners_pages_items') : '';
      return skipped ? `${ text }: ${ id }` : id;
    } else if (filter || total) {
      const id = Number.isInteger(pageId) ? `${ pageId }` : '?';
      const text = translate(filter ? 'partners_pages_filtered' : 'partners_pages_total');
      return `${ text }: ${ id }`;
    } else if (first || prev || next || last) {
      return '';
    } else if (current) {
      return `${ pageId + 1}`;
    } else {
      return `${ pageId + 1}`;
    }
  };
}

ListPartnersAccruals.defaultProps = {
  paymentConfirmationTexts: [
    ['rejected', 'confirmed'],
    ['отклонен', 'подтвержден'],
    ['відхилений', 'підтверджений']
  ],
};

export default withRouter(withLocalize(ListPartnersAccruals));
