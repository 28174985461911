import { PropTypes, } from 'prop-types';
import React, { Component } from 'react';
import NotificationService from "../../../../../services/NotificationService";
import { userService } from "../../../../../services/UserDataService";

class ManagerNotificationRowEmail extends Component {

    static propTypes = {
        item: PropTypes.object.isRequired,
        profile: PropTypes.object.isRequired,
        updateItem: PropTypes.func.isRequired,
    };

    setChange = async() => {
        const newValue = this.props.item.email === 1 ? 2 : 1;

        try {
            let data = null;
            if (this.props.item.id === null) {
                data = await userService.createUserEventSettings({
                    user_id: this.props.profile.id,
                    event_id: this.props.item.event_id,
                    email: newValue,
                    push: 2,
                });
            } else {
                data = await userService.updateUserEventSettings({email: newValue}, this.props.item.id);
            }

            if (data) {
                await this.props.updateItem(data);
            }
        } catch (error) {
            NotificationService.errors(error);
        }
    }

    render() {
        const itemStatus = this.props.item.email === 1 ? 'active' : 'disable';

        return (
            <div
                className = { `notification-toggle email icon icon_${itemStatus} ${itemStatus} icon_email ` }
                onClick={async () => await this.setChange()}>
            </div>
        )
    }
}

export default ManagerNotificationRowEmail;