export const GLOBAL_PATH_API = '/api';
export const GET_PAYMENT_ORDERS = `${GLOBAL_PATH_API}/payment/orders`;
export const GET_PAYMENT_SYSTEMS= `${GLOBAL_PATH_API}/payment/systems`
export const GET_PAYMENT_SYSTEMS_BY_TYPE = `${GLOBAL_PATH_API}/payment/systems/get-payment-system-by-type`
export const GET_BANK_ACCOUNT = `${GLOBAL_PATH_API}/payment/bank_account`;
export const GET_CLIENT_ID = `${GLOBAL_PATH_API}/core/account/check`;
export const GET_SYSTEM_ACCOUNT = `${GLOBAL_PATH_API}/core/system-accounts/check`;
export const GET_BANK_BY_CURRENCY = `${GLOBAL_PATH_API}/payment/systems/get-bank-by-currency`;
export const GET_PAYMENT_METHODS = `${GLOBAL_PATH_API}/payment/systems/methods`;
export const GET_CARDS_BY_ACCOUNT_ID = `${GLOBAL_PATH_API}/payment/cards/get-by-account_id`;
export const CALCULATE_TOTAL_AMOUNT = `${GET_PAYMENT_ORDERS}/calculate-total-amount`;
export const EDIT_CWB = `${GLOBAL_PATH_API}/payment/cards`;
export const CREATE_REQUEST = `${GLOBAL_PATH_API}/payment/orders`;
export const SYSTEM_REQUEST = `${GLOBAL_PATH_API}/core/system-accounts`;
export const SYSTEM_ACCOUNTS_CONVERT = `${SYSTEM_REQUEST}/convert`;
export const GET_FEE_PAYER = `${GLOBAL_PATH_API}/payment/orders/get-fee-payer`;

export const GET_SUMMARY_OF_FIN_TRANSACTIONS = `${GLOBAL_PATH_API}/payment/daily_aggregated_fin_transactions`;