import React, { Component } from 'react';
import { withLocalize } from "react-localize-redux";
import { Input } from "../../../../../../components";
import { setErrorClass } from "../../../../../../utils/helpers";
import { onValidDigits, onValidPercantages } from '../../helpers/validation-helpers/onValidHelpers';
class RegistrationClientsRevshareBlock extends Component {
	constructor(props) {
		super(props);

	}

	get disabled(){ 
		const {payoutRateRevshare, statusIsClosed, isViewMode} = this.props;
		
		return  payoutRateRevshare.length >= 10 || +payoutRateRevshare[payoutRateRevshare.length-1].coef === 1000 || statusIsClosed || isViewMode 
	}

	handleOnChange = (fieldName, index, sumOfInputsErrorName) => async (event) => {
		const { setNewSumFromValue, } = this.props;
		const { value } = event.target;

		try {
			switch (fieldName) {
				case 'registrations': {
					await setNewSumFromValue(fieldName, value, index, sumOfInputsErrorName);
					break;
				}
				case 'verifications': {
					await setNewSumFromValue(fieldName, value, index, sumOfInputsErrorName);
					break;
				}
				case 'active_clients': {
					await setNewSumFromValue(fieldName, value, index, sumOfInputsErrorName);
					break;
				}
				case 'new_active_clients': {
					await setNewSumFromValue(fieldName, value, index, sumOfInputsErrorName);
					break;
				}
				case 'coef': {
					await setNewSumFromValue(fieldName, value, index,);
					break;
				}

				default: {
					break;
				}
			}
		} catch (e) {
			throw new Error(e);
		};
	};
		setErrorClassGroup = (data = [], name = "", groupName = "") => {
				return data.includes(name) || data.includes(groupName) ? "error" : "";
		};

	createNewAllowedGroup = () => {
		const { createNewPayoutRangesItem, } = this.props;

		return createNewPayoutRangesItem("1");
	};

	checkIsDisabled = (index) => {
		const {paymentCoefsInDataBase, statusIsActive, statusIsClosed, isEditMode, isViewMode}= this.props;
		if(!isEditMode) return false
		return (index + 1 <= paymentCoefsInDataBase.length && statusIsActive) || statusIsClosed  || isViewMode //in active mode can delete and edit only coefs that are not in DB
	}

	renderBlockInputs = () => {
		const {
			translate,
			errors,
			payoutRateRevshare,
			removePayoutRangeItem,
		} = this.props;

		const checkIsDisabled = this.checkIsDisabled;

		const isDisabled = (index) => checkIsDisabled(index) || payoutRateRevshare.length - 1 !== index;

		return (
			<div className='payout-rate-list__payout_table-blockRevshare custom-horizontal-scroll pb-20'>
				<div className='payout-rate-list__payout_table-forbidden mr-35'>
					<div className='payout-rate-list__payout_table-range-items'>
						<div className='new-partners-payout-rate-list__title p0'>
							<p className='payout-rate-list__payout_table-title'>
								{translate("new_partners_condition_registration_from")}
							</p>
						</div>
						<div className='new-partners-payout-rate-list__title p0'>
							<p className='payout-rate-list__payout_table-title'>
								{translate("new_partners_condition_verification_from")}
							</p>
						</div>
						<div className='new-partners-payout-rate-list__title p0'>
							<p className='payout-rate-list__payout_table-title'>
								{translate("new_partners_condition_active_clients_from")}
							</p>
						</div>
						<div className='new-partners-payout-rate-list__title p0'>
							<p className='payout-rate-list__payout_table-title'>
								{translate("new_partners_condition_new_active_clients_from")}
							</p>
						</div>
						<div className='new-partners-payout-rate-list__title p0'>
							<p className='payout-rate-list__payout_table-title'>
								{translate("new_partners_condition_payout_percentage_input")}
							</p>
						</div>
					</div>
					{payoutRateRevshare?.map((sum, index) => {
						return (
							<div key={index} className='payout-rate-list__payout_table-range-items'>
								{payoutRateRevshare.length > 1 && !checkIsDisabled(index)
									? <button
										className="payout-rate-list__create-btn_btns-btn payout-rate-list__create-btn_btns-btn-remove mt-20 rm-range-item"
										onClick={() => removePayoutRangeItem(index)} />
									: null
								}
								<Input
									name={`registrations_${index}`}
									wrapperClassName={`payout-rate-list__payout_table-input mw ${this.setErrorClassGroup(errors, `sum_error_${index}`, `registrations_${index}`)} `}
									placeholder={translate("new_partners_condition_enter_amount_placeholder")}
									value={`${sum.registrations}`}
									onChange={this.handleOnChange('registrations', index , `sum_error_${index}`)}
									onValid={value => onValidDigits(value, 100_000)}
									disabled={isDisabled(index)}
									isRequired
								/>
								<Input
									name={`verifications_${index}`}
									wrapperClassName={`payout-rate-list__payout_table-input mw ${this.setErrorClassGroup(errors,  `sum_error_${index}`, `verifications_${index}`)}`}
									placeholder={translate("new_partners_condition_enter_amount_placeholder")}
									value={`${sum.verifications}`}
									onChange={this.handleOnChange('verifications', index, `sum_error_${index}`)}
									disabled={isDisabled(index) }
									onValid={value => onValidDigits(value, 100_000)}
									isRequired
								/>
								<Input
									name={`active_clients_${index}`}
									wrapperClassName={`payout-rate-list__payout_table-input mw ${this.setErrorClassGroup(errors, `sum_error_${index}`, `active_clients_${index}`)}`}
									placeholder={translate("new_partners_condition_enter_amount_placeholder")}
									value={`${sum.active_clients}`}
									onChange={this.handleOnChange('active_clients', index, `sum_error_${index}`)}
									disabled={isDisabled(index) }
									onValid={value => onValidDigits(value, 100_000)}
									isRequired
								/>
								<Input
									name={`new_active_clients_${index}`}
									wrapperClassName={`payout-rate-list__payout_table-input mw ${this.setErrorClassGroup(errors, `sum_error_${index}`, `new_active_clients_${index}`)}`}
									placeholder={translate("new_partners_condition_enter_amount_placeholder")}
									value={`${sum.new_active_clients}`}
									onChange={this.handleOnChange('new_active_clients', index, `sum_error_${index}`)}
									disabled={isDisabled(index) }
									onValid={value => onValidDigits(value, 100_000)}
									isRequired
								/>
								<Input
									name={`coef_${index}`}
									wrapperClassName={`payout-rate-list__payout_table-input mw ${setErrorClass(errors, `coef_${index}`)}`}
									placeholder={translate("new_partners_condition_grace_period_payment_placeholder")}
									value={`${sum.coef}`}
									onChange={this.handleOnChange('coef', index)}
									disabled={isDisabled(index) }
									onValid={(value) => onValidPercantages(value, 1000)}
									isRequired
								/>

							</div>
						)
					})}
				</div>
			</div>

		);
	};


	render() {
		const { translate,} = this.props;
		const disabled = this.disabled;
		const disabledClassName =  disabled ? `disabled` : '';
		return (
			<>
				<div className='new-partners-conditions_payout__rate'>
					<button
						onClick={this.createNewAllowedGroup}
						className={`${disabledClassName} payout-rate-list__create-btn_btns-btn payout-rate-list__create-btn_btns-btn-join`}
						disabled={disabled}
					>
						{translate(`new_partners_condition_add-range`)}
					</button>
				</div>
				<div className="new-partners-conditions__rates mb-20">
					{this.renderBlockInputs()}
				</div>
			</>
		);
	}
}

export default withLocalize(RegistrationClientsRevshareBlock);