import {
  itemsBuild,
  itemsDrain,
  itemsResort,
  CFG_ITEM_TYPE,
} from '../Config';
import { onEnabled, render, } from '../Mentor/withDropDown';

const f_group = {
  'drop-down': '/api/partners/symbols-groups/drop-down',
  'o=': [ '=', '!=', ],
};

const f_status = {
  'drop-down': false,
  'o=': [ '=', '!=', ],
};

const p_group = {
  onEnabled: onEnabled('symbolGroups'),
  render: render(
    'symbolGroups',
    // _ => _ ? `${ _?.name } ${ _?.surname }` : '-',
  ),
};

export default {
  id: 177,
  ...[

    // filters
    ...[ {
      f: 'partners.symbols.id',
      id: 'f:id',
    }, {
      f: 'partners.symbols.name',
      id: 'f:name',
    }, {
      // f: [ 'partners.symbols.group_id', 'partners.symbols_groups.name' ],
      f: 'partners.symbols.group_id',
      id: 'f:group',
      ...f_group,
    }, {
      f: 'partners.symbols.status',
      id: 'f:status',
      ...f_status,
    }, {
      f: 'partners.symbols.created_at',
      id: 'f:created',
    }, {
      f: 'partners.symbols.updated_at',
      id: 'f:updated',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

    // orders
    ...[ {
      f: 'partners.symbols.id',
      id: 'o:id',
    }, {
      f: 'partners.symbols.name',
      id: 'o:name',
    }, {
      f: 'partners.symbols_groups.name',
      id: 'o:group',
    }, {
      f: 'partners.symbols.status',
      id: 'o:status',
    }, {
      f: 'partners.symbols.created_at',
      id: 'o:created',
    }, {
      f: 'partners.symbols.updated_at',
      id: 'o:updated',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

    // panels
    ...[ {
      ii: 'f:id',
      t: 'symbols_id',
    }, {
      ii: 'f:name',
      t: 'symbols_name',
    }, {
      ii: 'f:group',
      t: 'symbols_group',
      ...p_group,
    }, {
      ii: 'f:status',
      t: 'symbols_status',
    }, {
      ii: 'f:created',
      t: 'symbols_created_at',
    }, {
      ii: 'f:updated',
      t: 'symbols_updated_at',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

  ]
  .map(itemsBuild)
  .reduce(itemsDrain, [])
  .reduce(itemsResort, {}),

  path: 'partners/symbols',
  widget: false,
};
