import React, { Component } from 'react';
const imgUrl = "../../../images/";

export default class InteratorListData extends Component {
    state = {
        interaction: this.props.user,
        currentUserId: this.props.currentUserId
    }

    clickUser = () => {
	    this.props.setCurrentUser(this.props.user.id, this.props.user.type);
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        this.setState({
            interaction: { ...nextProps.user },
            currentUserId: nextProps.currentUserId
    });
    }

    render () {

        return (
            <tr onClick={this.clickUser} className={`interaction-row ${this.state.currentUserId === this.state.interaction.id ? 'current' : ''}`} >
                <td>
                    {this.state.interaction['interaction_created']}
                </td>
                <td>
                    <div className="interaction-info"><img src={ imgUrl + this.state.interaction[`interaction_type_icon`] + '.svg'} alt="message"/> {this.state.interaction[`interaction_type`]} {this.state.interaction["interaction_amount"] ? <span className='interaction-amount'>{this.state.interaction["interaction_amount"]}</span> : ''}</div>
                </td>
                <td>{this.state.interaction["interaction_manager"]}</td>
                <td>
                    <div className="interaction-info"><span className={`interaction-result ${this.state.interaction['interaction_result_type']}`}></span>{this.state.interaction['interaction_result']}</div>
                </td>
            </tr>
        );
    }
}
