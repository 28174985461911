import React, { Suspense, } from 'react';
import { Route, Switch, Redirect, } from "react-router-dom";
import DataStorage from './helpers/DataStorage';

// //services
import LangService from './services/LangService';
import AuthService from "./services/AuthService";
import Preloader from "./components/Common/Preloader";
import PermissionService from "./services/PermissionService";

import ErrorPage from "./modules/common/components/ErrorPage";
import NodeService from "./services/NodeService";
import RoutePermission from "./components/RoutePermission";

import { tasksConfig, } from "./modules/tasks/routes";
import { clientsConfig, } from "./modules/clients/routes";
import { dashboardConfig, } from "./modules/dashboard/routes";
import { transactionConfig, } from "./modules/transactions/routes";
import { callsConfig, } from "./modules/calls/routes";
import { calendarConfig, } from "./modules/calendar/routes";
import { usersConfig, } from "./modules/admin_panel/users/routes";
import { kpi_settingsConfig, } from "./modules/admin_panel/kpi_settings/routes";
import { activityReportConfig, } from "./modules/admin_panel/activity_report/routes";
import { hierarchyConfig, } from "./modules/admin_panel/hierarchy/routes";
import { distributionConfig, } from "./modules/admin_panel/distribution_settings/routes";
import { commonConfig, } from "./modules/common/routes";
import { template_managementConfig, } from "./modules/admin_panel/template_management/routes";
import { template_permissionConfig, } from "./modules/admin_panel/template_permission/routes";
import { list_accountsConfig, } from "./modules/list_accounts/routes";
import { timaConfig, } from "./modules/tima/routes";
import { delegationConfig, } from "./modules/admin_panel/delegation/routes";
import { paymentsConfig, } from "./modules/payments/routes";
import { contestsConfig, } from './modules/contests/routes';
import { supportConfig, } from './modules/support/routes';
import { searchConfig, } from './modules/search/routes';
import { autoCallConfig, } from "./modules/admin_panel/autocall/routes";
import { marketingConfig, } from './modules/marketing/routes';
import { conversationsConfig, } from './modules/conversations/routes';
import { listOfCronsConfig } from './modules/admin_panel/list_of_crons/routes';
import { partnersSystemConfig } from "./modules/partners_system/routes";
import { bonusesConfig } from "./modules/bonuses/routes";

export default (store) => {
	const redirect = (data={}) => props => (<Redirect { ...{ ...data, ...props, } } />);

	DataStorage.init();
	LangService.init(store);
	AuthService.init(store);
	PermissionService.init(store);
	NodeService.init(store);

	const routePermission = value => props => (<RoutePermission { ...props } data={ value } />);
	const getRoutes = [
		...tasksConfig,
		...clientsConfig,
		...dashboardConfig,
		...transactionConfig,
		...activityReportConfig,
		...callsConfig,
		...calendarConfig,
		...autoCallConfig,
		...marketingConfig,
		...kpi_settingsConfig,
		...hierarchyConfig,
		...distributionConfig,
		...template_managementConfig,
		...template_permissionConfig,
		...listOfCronsConfig,
        ...delegationConfig,
		...usersConfig, // user
		...commonConfig,
		...list_accountsConfig,
		...timaConfig,
		...partnersSystemConfig,
		...paymentsConfig,
		...contestsConfig,
		...supportConfig,
		...searchConfig,
	    ...conversationsConfig,
		...bonusesConfig,
    ]
		.map((value, index) => {
			return (
				<Route
					component={ routePermission(value) }
					key={ index }
					path={ value.path }
				/>
			);
		});

	return (
		<Suspense fallback={ <Preloader /> }>
			<Switch>
				<Route component={ redirect({ to: '/dashboard', }) } exact={ true } path={ '/' } />
				<Route component={ ErrorPage } path={ `/404` } />
				{ getRoutes }
				<Route component={ ErrorPage } />
			</Switch>
		</Suspense>
	);
};