// field names full path
export const CLIENTS_EXPORT_FIELDS = {
    clients: [
        'core-account-id',
        'core-account-surname',
        'core-account-name',
        'core-account-email',
        'core-account-phone',
        'core-account-warm_type_last_change',
        'core-account-warm_type',
        'core-account-new_in_briefcase',
        'core-account_descriptions-created_at',
        'core-account_descriptions-description',
        'core-account_categories-created_at',
        'core-account_categories-category_id',
        'core-country-name',
        'core-city-name',
        'core-user-surname',
        'core-user-name',
        'core-account-is_processed',
        'core-account-tax_residency_self_certification',
        'core-dictionary_account_tag_t->array->core-dictionary_account_tag_t-description',
    ],
}
