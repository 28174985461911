import React from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import del from '../../../../../images/delete-small.svg';
import {Translate} from "react-localize-redux";

export default class DeleteTreeItem extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dropdownOpen: false
        };
    }

    toggle = () => {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    render() {
        return (
            <Translate>
                {({ translate }) => {
                    return (
                        <Dropdown className="tree-modal-wrapper" direction = 'right' tag = 'div' isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                            <DropdownToggle tag = 'div'>
                                <img src={del} alt="delete"/>
                            </DropdownToggle>
                            <DropdownMenu>
                                <div
                                    className="tree-modal-delete"
                                >
                                    <h6>{translate(`hierarchy_sure_title`)}</h6>
                                    <p>{translate(`hierarchy_sure_text`)}</p>
                                    <div className="tree-modal-actions">
                                        <div className="tree-modal-button red"
                                             onClick = { () => {this.props.deleteDepartment(this.props.id), this.toggle()} }
                                        >{translate(`hierarchy_yes`)}</div>
                                        <div className="tree-modal-button" onClick={this.toggle}>{translate(`hierarchy_no`)}</div>
                                    </div>
                                </div>
                            </DropdownMenu>
                        </Dropdown>
                    )}
                }
            </Translate>
        )
    }
}