import LinkConfig from "./LinkConfig";

export const breadcrumbs4PartnersCard = (partner, activeTab, translate) => {
	// eslint-disable-next-line new-cap
	return LinkConfig(partner,
		activeTab,
		translate,
		[ "dashboard", "partners", "partners-list", "partner-name", "partner-card", ],
		{},
	);
};