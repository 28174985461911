/* eslint-disable no-unused-vars, max-len , sort-keys */

import React, { PureComponent, } from "react";
import { withLocalize, } from "react-localize-redux";
import { withRouter, } from "react-router-dom";
import {
	MagicButton,
	MagicFilterPanels,
	MagicTable,
	Mentor,
} from "../../../../../../components/Magic";
import {
	deleteSymbolsList,
	getSymbolsList,
	asyncGet,
} from "../../../../../../services/PartnersRequestService";
import Loader from "../../../../../tima/components/Loader";
import { MagicConfirm, } from "../../../../../tima/components/Magic/MagicConfirm";
import PagePanel from "../../../../../tima/components/Pages";
import Moment from "moment";
import Preloader from "../../../../../../components/LoadingHOC/Preloader";
import { Input, } from "../../../../components";
import CreateUpdateSymbolsList from "../Popup/CreateUpdateSymbolsList";
import { MagicTooltip, } from "../../../../../tima/components/Magic";
import { GET_SYMBOLS_LIST, } from "../../../../../../apiRoutes";

const symbolListStatus = Object.freeze({
	ACTIVE: 2,
	DISABLE: 0,
	NEW: 1,
});

class SymbolsListsMagicTable extends PureComponent {
	constructor (props) {
		super(props);

		this.state = {
			data: [],
			filterLastChange: Date.now(),
			isEmptyData: false,
			loaded: false,
			pages: {
				filter: 0,
				take: 50,
				total: 0,
				variance: 2,
			},
			removeSymbolsListId: null,
			symbol_name_search: "",
			symbols: [],
		};

		const mf = new Mentor({
			owner: this,
			serviceId: 178,
			translate: this.props.translate,
		});

		Object.defineProperty(this, "mf", { get: () => mf, });
	}

	params = () => this.props.match.params;

	query = () => new URLSearchParams(this.props.location.search);

	save = state => new Promise(next => this.setState(state, next));

	mfChanged = async () => {
		await this.save({ filterLastChange: Date.now(), });
	};

	mfDoing = async () => {
		await this.save({ loaded: false, });
	};

	mfDone = async () => {
		await this.mfChanged();
		await this.onDataLoad();
	};

	async componentDidMount () {
		this.mf.subscribe(
			{
				changed: this.mfChanged,
				cleaned: this.mfDone,
				cleaning: this.mfDoing,
				loaded: this.mfDone,
				loading: this.mfDoing,
				saved: this.mfDone,
				saving: this.mfDoing,
			},
			this,
		);

		await this.mf.init({ doEmit: true, });
		await this.getSymbols();
	}

	componentWillUnmount () {
		this.mf.unsubscribe([ this.mfChanged, this.mfDoing, this.mfDone, ], this);
	}

	onChange = (name, value) => this.save({ [name]: value, });

	getInactiveSymbols = (data) => {
		if (!Array.isArray(data)) {
			return [];
		}

		return data.filter(({ status, }) => !status).map(({ name, }) => name);
	};

	getSymbols = async () => {
		try {
			const response = await asyncGet(GET_SYMBOLS_LIST);

			const symbols = response.data.map(({ partners, }) => {
				const { name, status, id, } = partners.symbols;

				return { id, name, status, };
			});

			await this.save({ symbols, });
		} catch (error) {
			error?.showErrorNotification?.();
		}
	};

	get listPartnersSymbolsConfig () {
		const { translate, } = this.props;
		const prefix = "partners_symbols_lists_";

		return [
			{
				path: [ "partners", "symbols_lists", "id", ],
				key: "symbolListId",
				render: this.renderInlineValue,
				title: `${ prefix }id`,
				orderId: "o:gId",
			},
			{
				path: [ "partners", "symbols_lists", "name", ],
				key: "symbolListName",
				render: this.renderSymbolListName,
				title: `${ prefix }name`,
				orderId: "o:gName",
			},
			{
				path: [ "partners", "symbols_lists", "list", ],
				key: "symbolsList",
				render: this.renderSymbols,
				xtitle: this.renderSymbolsHead(),
			},
			{
				path: [ "partners", "symbols_lists", "amount", ],
				key: "symbolsAmount",
				render: this.renderInlineValue,
				title: `${ prefix }amount_symbol`,
				orderId: "o:gAmount",
			},
			{
				accessChecked: true,
				path: [ "partners", "symbols_lists", "server_name", ],
				key: "serverName",
				render: this.renderInlineValue,
				title: `${ prefix }server_name`,
				orderId: "o:gServer",
			},
			{
				path: [ "partners", "symbols_lists", "status", ],
				key: "symbolsStatus",
				render: this.renderStatusValue,
				title: `${ prefix }status`,
				orderId: "o:gStatus",
			},
			{
				path: [ "partners", "symbols_lists", "created_at", ],
				key: "symbolsCreatedAt",
				render: this.renderDate,
				title: `${ prefix }created_at`,
				orderId: "o:gCreatedAt",
			},
			{
				path: [ "partners", "symbols_lists", "updated_at", ],
				key: "symbolsUpdatedAt",
				render: this.renderDate,
				title: `${ prefix }updated_at`,
				orderId: "o:gUpdatedAt",
			},
			{
				path: [ "partners", "symbols_lists", "id", ],
				key: "symbolsActions",
				render: this.renderActions,
				title: `${ prefix }actions`,
				customAccessCheck: ({ item, }) => {
					return (
						item.access("show") &&
						(item.access("update") || item.access("destroy"))
					);
				},
			},
		].map(({
			orderId: o, title: t, xtitle: x, ...item
		}) => ({
			...item,
			...o && {
				order: async () => {
					await this.mf.orderedToNext(o);
				},
				orderTo: this.mf.orderedTo(o),
			},
			...t && { title: translate(t), },
			...x && { title: x, },
		}));
	}

	onInputChange = async (event) => {
		const { name, value, } = event.target;

		const data = [ ...this.state.data, ];
		const valueLowerCase = value.toLowerCase();

		data.forEach((item) => {
			const list =
				Object.values(JSON.parse(item.partners.symbols_lists.list)).join("") ??
				"";

			item.isVisible = list.toLowerCase().indexOf(valueLowerCase) !== -1;
		});

		await this.save({ data, [name]: value, });
	};

	renderSymbolsHead = () => {
		const { translate, } = this.props;
		const { symbol_name_search, } = this.state;

		return (
			<Input
				label=""
				name="symbol_name_search"
				placeholder={ translate("partners_symbols_table_list") }
				type="text"
				value={ symbol_name_search }
				wrapperClassName="search"
				onChange={ this.onInputChange }
			/>
		);
	};

	renderInlineValue = (value, { item, }) => {
		const options = {
			content: value ?? "-",
			lengthCut: 20,
		};

		if (item?.access?.("index")) {
			return <MagicTooltip { ...options } />;
		}

		return "-";
	};

	renderStatusValue = (value, { item, }) => {
		const statusEnum = this.props.enums?.data?.partners?.SymbolListStatusEnum;

		if (item?.access?.("index")) {
			return statusEnum[value] ?? "-";
		}

		return "-";
	};

	renderDate = (date, { item, }) => {
		if (item?.access?.("index")) {
			const formatDB = "YYYY-MM-DD HH:mm:ss";
			const formatOut = "YYYY.MM.DD HH:mm:ss";

			// eslint-disable-next-line new-cap
			const newDate = Moment(date, formatDB);

			return newDate.isValid() ? newDate.format(formatOut) : "-";
		}

		return "-";
	};

	removeSymbolList = async (symbolListId = this.state.removeSymbolsListId) => {
		if (!symbolListId) {
			console.warn("symbol list id not found");

			return;
		}

		try {
			await deleteSymbolsList(symbolListId);
			await this.onDataLoad();
		} catch (error) {
			error?.showErrorNotification?.();
		} finally {
			await this.onChange("removeSymbolsListId", null);
		}
	};

	// eslint-disable-next-line no-unused-vars
	renderActions = (value, { item, items, data, }) => {
		const { translate, popup, } = this.props;
		const { removeSymbolsListId, } = this.state;

		const renderRemoveButton = () => {
			const options = {
				className:
					"magic-button__item magic-button__item_partners-delete-symbol margin--top-10",
				children: translate("symbols_actions_delete"),
				onClick: () => this.onChange("removeSymbolsListId", value),
			};

			return (
				<div className="magic-confirm-wrapper">
					<MagicConfirm
						accept={ translate("symbols_remove_accept") }
						closeByClickOutside={ true }
						isVisible={ Number(removeSymbolsListId) === Number(value) }
						reject={ translate("symbols_remove_reject") }
						title={ translate(`magic_confirm_title`) }
						onAccept={ () => this.removeSymbolList(removeSymbolsListId) }
						onReject={ () => this.onChange("removeSymbolsListId", null) }
					/>
					<MagicButton { ...options } />
				</div>
			);
		};

		const renderEditButton = () => {
			const options = {
				className: "magic-button__item magic-button__item_partners-edit-symbol",
				children: translate("symbols_actions_edit"),
				onClick: () => popup.togglePopup(data?.partners?.symbols_lists),
			};

			return (
				<div className="magic-confirm-wrapper">
					<MagicButton { ...options } />
				</div>
			);
		};

		return (
			<>
				{item.access("update") && renderEditButton()}
				{item.access("destroy") && renderRemoveButton()}
			</>
		);
	};

	renderSymbolListName = (value, { item, }) => {
		const options = {
			content: value ?? "-",
			lengthCut: 20,
		};

		if (item?.access?.("index")) {
			return <MagicTooltip { ...options } />;
		}

		return "-";
	};

	renderList = (
			listObj,
			unactiveItems = this.getInactiveSymbols(this.state.symbols),
	) => {
		return Object.entries(listObj).map(([ key, value, ], index, array) => {
			const isLastElement = index === array.indexOf(...array.slice(-1));
			const isInactiveElement = unactiveItems.includes(value);

			// eslint-disable-next-line no-param-reassign
			value = isLastElement ? value : `${ value }, `;

			return (
				<span className={ isInactiveElement ? "groups-inactive" : "" } key={ key }>
					{value}
				</span>
			);
		});
	};

	renderSymbols = (value, { item, }) => {
		if (item?.access?.("index")) {
			const listObj = JSON.parse(value);

			const listMarkUp = this.renderList(listObj);

			return (
				<div className="partners-table-list symbols-list">{listMarkUp}</div>
			);
		}

		return "-";
	};

	renderTable = () => {
		const {
			data, isEmptyData, pages, loaded,
		} = this.state;
		const { translate, } = this.props;

		const options = {
			config: this.listPartnersSymbolsConfig,
			data: data.filter(({ isVisible, }) => isVisible ?? true),
			head: [
				"symbolListId",
				"symbolListName",
				"symbolsList",
				"symbolsAmount",
				"serverName",
				"symbolsStatus",
				"symbolsCreatedAt",
				"symbolsUpdatedAt",
				"symbolsActions",
			],
			RowProps: ({ items, }) => {
				return {
					"data-disabled": [ symbolListStatus.DISABLE, ].includes(
						items?.symbolsStatus?.valueOf,
					),
				};
			},
		};

		return (
			<>
				<MagicFilterPanels mf={ this.mf } show={ true } translate={ translate } />
				<div className="content-block">
					<div
						className={ `table-wrapper partners__table ${
							isEmptyData ? "partners__table--empty" : ""
						}` }
					>
						<Loader
							loaded={ loaded }
							loading={ <Preloader scale={ 1 } /> }
							translate={ translate }
						>
							<MagicTable { ...options } />
							{isEmptyData && (
								<div className="table__empty-text">
									{translate("symbols_empty")}
								</div>
							)}
							<PagePanel
								doText={ this.onPageText }
								doTo={ pageId => `?page=${ pageId }` }
								filter={ pages.filter }
								page={ this.pageId() }
								take={ pages.take }
								total={ pages.total }
								variance={ pages.variance }
								onClick={ this.onPageClick }
							/>
						</Loader>
					</div>
				</div>
			</>
		);
	};

	render () {
		const { popup, enums, } = this.props;

		return (
			<>
				{popup.isOpen && (
					<CreateUpdateSymbolsList
						enums={ enums.data }
						formData={ popup.data }
						onClose={ () => popup.togglePopup() }
						onDataLoad={ this.onDataLoad }
					/>
				)}

				{this.renderTable()}
			</>
		);
	}

	pageId = () => {
		const page = this.query().get("page");

		return Number(page) || 0;
	};

	pageIdAsGET = (pageId) => {
		// eslint-disable-next-line no-param-reassign
		pageId = pageId === undefined ? this.pageId() : pageId;
		const { filterId, } = this.mf;

		return {
			filterId,
			take: this.state.pages.take,
			skip: pageId * this.state.pages.take,
		};
	};

	onDataLoad = async (pageId) => {
		await this.save({ loaded: false, isEmptyData: false, });
		const pageOptions = this.pageIdAsGET(pageId);
		const loaded = (data, meta) => ({ pages, }) => ({
			data,
			pages: { ...pages, ...meta, },
			...!data.length && { isEmptyData: true, },
		});

		try {
			const response = await getSymbolsList(pageOptions);
			const {
				data = [],
				meta: { filter = 0, total = 0, },
			} = response;

			await this.save(loaded(data, { filter, total, }));
		} catch (error) {
			await this.save(loaded([], { filter: 0, total: 0, }));
			error?.showErrorNotification?.();
		}
		await this.save(state => ({
			loaded: true,
			...state.removeSymbolsListId && { removeSymbolsListId: null, },
		}));
	};

	onPageClick = ({ event, pageId, pageIs, }) => {
		this.onDataLoad(pageId);
	};

	onPageText = (pageId, pageIs) => {
		const { translate, } = this.props;
		const {
			current, first, prev, next, last,
		} = pageIs;
		const {
			skipped, taken, filter, total,
		} = pageIs;

		if (skipped || taken) {
			const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : "?";
			const text = skipped ? translate("partners_pages_items") : "";

			return skipped ? `${ text }: ${ id }` : id;
		} else if (filter || total) {
			const id = Number.isInteger(pageId) ? `${ pageId }` : "?";
			const text = translate(
				filter ? "partners_pages_filtered" : "partners_pages_total",
			);

			return `${ text }: ${ id }`;
		} else if (first || prev || next || last) {
			return "";
		} else if (current) {
			return `${ pageId + 1 }`;
		}

		return `${ pageId + 1 }`;
	};
}

export default withRouter(withLocalize(SymbolsListsMagicTable));