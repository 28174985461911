export const CFG_ITEM_TYPE = 'cfgItemType';

/**
 *
 * @arg {String} id -
 * @arg {Object} item - {
 *   [CFG_ITEM_TYPE]: String('filter')
 *   $: Object
 *   enabled: Number(0, 1)
 *   f: String
 *   j: String('n', 'y')
 *   o: String
 *   v: null | Number | Number[] | String | String[]
 *   order: Number(0, 1) | null
 * }
 * @returns {Object | null} filter item build
 */
export const itemFilter = (id, {
  [CFG_ITEM_TYPE]: type = 'filter',
  $,
  enabled = 0,
  f,
  fields,
  j = 'n',
  o = '=',
  v = null,
  ..._
}) => {
  const f1 = Array.isArray(f) ? [ ...f, ] : f;
  // eslint-disable-next-line object-curly-newline
  const main = { enabled, f: f1, j, o, v, };
  const f2 = fields && { fields, };

  // eslint-disable-next-line object-curly-newline
  return { $: { ...$, id: `${ id }`, type, }, ...main, ...f2, ..._, };
};

/**
 *
 * @arg {String} id -
 * @arg {Object} item - {
 *   [CFG_ITEM_TYPE]: String('order')
 *   $: Object
 *   enabled: Number(0, 1)
 *   f: String
 *   j: String('n', 'y')
 *   o: String('order_by')
 *   v: String('asc', 'desc')
 *   order: Number(0, 1) | null
 * }
 * @returns {Object | null} order item build
 */
export const itemOrder = (id, {
  [CFG_ITEM_TYPE]: type = 'order',
  $,
  enabled = 0,
  f,
  j = 'n',
  o = 'order_by',
  v = 'asc',
  ..._
}) => {
  // eslint-disable-next-line object-curly-newline
  const main = { enabled, f, j, o, v, };

  return { $: { ...$, id: `${ id }`, type, }, ...main, ..._, };
};

/**
 *
 * @arg {String} id -
 * @arg {Object} item - {
 *   [CFG_ITEM_TYPE]: String('panel')
 *   items: String | String[]
 *   render: Function | null
 *   translate: String
 * }
 * @returns {Object | null} panel item build
 */
export const itemPanel = (id, {
  [CFG_ITEM_TYPE]: type = 'panel',
  ii,
  r,
  t,
  x,
  ..._
}) => {
  const items = Array.isArray(ii) ? [ ...ii, ] : ii;
  // eslint-disable-next-line object-curly-newline
  const main = { items, ...r && { render: r, }, translate: t, xlabel: x, };

  // eslint-disable-next-line object-curly-newline
  return { id: `${ id }`, type, ...main, ..._, };
};

/**
 *
 * @arg {Object} item -
 * @arg {Number} index -
 * @returns {Object | null} item build
 */
export const itemsBuild = (item, index) => {
  let result = null;

  switch (item?.[CFG_ITEM_TYPE]) {
    case 'filter':
      result = itemFilter(item?.id ?? index, item) ?? null;
      break;
    case 'order':
      result = itemOrder(item?.id ?? index, item) ?? null;
      break;
    case 'panel':
      result = itemPanel(item?.id ?? index, item) ?? null;
      break;
    default:
      result = null;
  }

  return result;

  // return {
  //   filter: itemFilter,
  //   order: itemOrder,
  //   panel: itemPanel,
  // }[item.type]?.(id ?? index, item) ?? null;
};

/**
 *
 * @example of use
 *   .reduce(itemsDrain, [])
 * @arg {Object[]} pipe -
 * @arg {Object} item -
 * @arg {Number} _index -
 * @arg {Object[]} items -
 * @returns {Object[]} pipe of items with drained children
 */
export const itemsDrain = (pipe, item, _index, items) => {
  // eslint-disable-next-line no-extra-boolean-cast
  if (!item) {
    // remove empty items
  } else if (item?.type === 'panel') {
    pipe.push(item);
  } else if (item?.$?.type === 'order') {
    pipe.push(item);
  } else if (item?.$?.type === 'filter') {
    const parent = items.find(_ => _ && _.$ && _.fields
      && item.$.type === _.$.type
      && (Array.isArray(_.fields)
        ? _.fields.includes(item.$.id)
        : item.$.id === _.fields));
    const children = Array.isArray(item.fields)
      ? items.find(_ => _.$
        && item.$.type === _.$.type
        && item.fields.includes(_.$.id))
      : item.fields
        ? items.find(_ => _.$
          && item.$.type === _.$.type
          && item.fields === _.$.id)
        : null;

    if (parent) {
      // child are in fields
    } else if (children) {
      pipe.push({ ...item, fields: children, });
    } else {
      pipe.push(item);
    }
  }

  return pipe;
};

/**
 *
 * @example of use
 *   .reduce(itemsResort, {})
 * @arg {Object[]} pipe -
 * @arg {Object} item -
 * @arg {Number} index -
 * @returns {Object} pipe of { filters, orders, panels, }
 */
export const itemsResort = (pipe, item, index) => {
  if (index === 0) {
    Object.assign(pipe, {
      filters: [],
      orders: [],
      panels: [],
    });
  }

  if ([ 'filter', ].includes(item?.$?.type)) {
    pipe.filters.push(item);
  } else if ([ 'order', ].includes(item?.$?.type)) {
    pipe.orders.push(item);
  } else if ([ 'panel', ].includes(item?.type)) {
    pipe.panels.push(item);
  }

  return pipe;
};