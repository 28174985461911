import * as actions from "./actionTypes";

const initialState = {
    loginError: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.LOGIN_ERROR:
            return {
                ...state,
                loginError: {
                    ...state.loginError,
                    data: action.data,
                },
            };
        case actions.LOGIN_ERROR_DELETE:
            return {
                ...state,
                loginError: {
                    ...state.loginError,
                    data: action.data,
                },
            };
        default:
            return state;
    }
}
