import React, { PureComponent, } from 'react';
import { Redirect, } from 'react-router-dom';
import Moment from 'moment';
import {
	MagicSwitch,
	MagicTextarea,
	MagicConfirm,
} from './../../../tima/components/Magic';
import {
	MagicInput,
	MagicMultiSelect,
	MagicDate,
} from '../../../../components/Magic';
import { PopupControlButtons, } from './PopupControlButtons';
import { Categories, } from './Categories';
import { Citizenship, } from './Citizenship';
import propTypes from 'prop-types';
import NotificationService from "../../../../services/NotificationService";
import '../../styles/create-contest-popup.scss';
import { contestService, } from "../../../../services/ContestDataService";
import { value, } from "lodash/seq";
import { logger, } from "redux-logger";

export class CreateContestPopup extends PureComponent {
   static format = 'YYYY-MM-DD';

   static defaultProps = {
   	enums: {},
   	isOpenCreatePopup: () => {},
   	isOpenEditPopup: () => {},
   	valuesToEditContest: {},
   	translate: () => {},
   };

   static propTypes = {
   	enums: propTypes.shape({ core: propTypes.object, }),
   	isOpenCreatePopup: propTypes.func,
   	isOpenEditPopup: propTypes.func,
   	translate: propTypes.func,
   	valuesToEditContest: propTypes.object,
   };

   static contestFields = [
   	'form_open_time',
   	'date_start',
   	'date_end',
   	'start_reg_date',
   	'finish_reg_date',
   	'prize_fund',
   	'prize_count',
   	'condition_max_loss',
   	'condition_closed_orders', ];

   static translationFields = [
   	'name',
   	'description',
   	'sendpulse_book',
   	'sendpulse_decline_book', ];

   constructor (props) {
   	super(props);
   	const format = 'YYYY-MM-DD';
   	const parse = date => date ? Moment(date).format(format) : null;
   	const tomorrow = Moment().startOf('day').add(1, 'day');
   	const afterRegistration = Moment(tomorrow).add(1, 'day');
   	const afterActivation = Moment(afterRegistration).add(1, 'day');
   	const afterStart = Moment(afterActivation).add(1, 'day');
   	const contests = props?.data?.contests;
	   console.log(contests)
   	const languages = props?.languages;
   	const translationDefault = languages?.find?.(item => +item?.contests?.contests_translations?.default === 1);

   	this.state = {
   		formData: {
   			contest: {
   				form_open_time: contests?.contests?.form_open_time ?? "",
   				start_reg_date: parse(contests?.contests?.start_reg_date) ?? Moment(tomorrow).format(format),
   				finish_reg_date: parse(contests?.contests?.finish_reg_date) ?? "", //|| afterRegistration.toDate(),
   				date_start: parse(contests?.contests?.date_start) ?? "", //|| afterActivation.toDate(),
   				date_end: parse(contests?.contests?.date_end) ?? "", //|| afterStart.toDate(),
   				prize_count: contests?.contests?.prize_count ?? "",
   				prize_fund: contests?.contests?.prize_fund ?? "",
   				condition_max_loss: contests?.contests?.condition_max_loss ?? "",
   				condition_closed_orders: contests?.contests?.condition_closed_orders ?? "",
   			},
   			winner_prizes: contests?.winners_prizes
				.sort((a,b) => a.places_from - b.places_from)
				.map((items) => {
				   const { places_from, places_to, prize } = items
				   return [{
					   places_from: places_from,
					   places_to: places_to,
					   prize: prize,
				   }]
			}) ?? [],
   			language: {
   				allLanguages: [],
   				showConfirmPopup: false,
   				deleteContestID: 0,
   				selectedLanguages: languages
   					?.sort?.((a, b) => b?.contests?.contests_translations?.default - a?.contests?.contests_translations?.default)
   					?.map?.(item => item?.contests?.contests_translations?.language_id) ?? [],
   				translations: languages?.length ? Object.assign({}, ...languages?.map((item) => {
   					const language_id = item?.contests?.contests_translations?.language_id;

   					return { [language_id]: { ...item?.contests?.contests_translations, }, };
   				})) : {},
   				currentLanguage: translationDefault?.contests?.contests_translations?.language_id ?? null,
   				translationDefault: translationDefault?.contests?.contests_translations?.language_id ?? "0",
   				translationDefaultCurrent: translationDefault?.contests?.contests_translations?.language_id ?? "0",
   				isTranslateEdited: false,
   				nameCharCounter: translationDefault?.contests?.contests_translations?.name?.length ?? 0,
   				descriptionCharCounter: translationDefault?.contests?.contests_translations?.description?.length ?? 0,
   			},
   		},
   		languageHash: null,
   		category: {
   			allCategories: this.props?.enums?.core?.AccountCategoryEnum ?? {},
   			participants_categories: contests?.contests_to_participants_categories?.map?.(item => item?.account_category) ?? [],
   		},
   		nationality: {
   			hash: null,
   			allNationalities: [],
   			selectedNationalities: contests?.contests_nationality.map(item => +item.nationality_id) ?? [],
   		},
   		isContestEdited: false,
   		isDisabledLangSelect: false,
   		isDisabled: false,
   		isOpenedInputBlock: false,
   		redirectEditContest: false,
   		redirectContestCard: false,
   		errors: [],
   	};
   };

   get createPrizePlacesIsDisabled(){
	   const {formData} = this.state;
	   const {winner_prizes} = formData;

	   const winnerPrizesLastTo = winner_prizes.at(-1)?.at(0).places_to;
	   const winnerPrizesLastFrom = winner_prizes.at(-1)?.at(0).places_from;
	   const winnerPrizesPrizeIsEmpty = !winner_prizes.at(-1)?.at(0).prize;
	   const toLesserThenFrom = +winnerPrizesLastFrom > +winnerPrizesLastTo;
	   const winnerPrizesAreNotEmpty = winner_prizes.length;

	   return winnerPrizesAreNotEmpty && (toLesserThenFrom || winnerPrizesPrizeIsEmpty) ;
   }

   save = state => new Promise(next => this.setState(state, next));

   languagesChanges = async (languages) => {
   	if (languages.hash === this.state?.languageHash) {
   		return `${ this.constructor.name }.languagesChanges: false`;
   	}
   	await this.save(state => ({
   		languageHash: languages.hash,
   		formData: {
   			...state.formData,
   			language: {
   				...state?.formData?.language,
   				allLanguages: languages.data,
   			},
   		},
   	}));

   	return `${ this.constructor.name }.languagesChanges: true`;
   };

   nationalitiesChanges = async (nationalities) => {
   	if (nationalities.hash === this.state?.nationality.hash) {
   		return `${ this.constructor.name }.nationalitiesChanges: false`;
   	}
   	await this.save(state => ({
   		nationality: {
   			...state.nationality,
   			hash: nationalities.hash,
   			allNationalities: nationalities.data,
   		},
   	}));

   	return `${ this.constructor.name }.nationalitiesChanges: true`;
   };

   componentDidMount = async () => {
   	contestService.subscribe('languages', this.languagesChanges, this);
   	contestService.subscribe('nationalities', this.nationalitiesChanges, this);
   	await this._getAllNationality();
   	await this._getAllLanguages();
   	await this.save(state => ({
   		formData: { ...state.formData, language: { ...state.formData.language, isTranslateEdited: false, }, },
   		isContestEdited: false,
   	}));
   };

   componentWillUnmount = async () => {
   	contestService.unsubscribe('languages', this.languagesChanges, this);
   	contestService.unsubscribe('nationalities', this.nationalitiesChanges, this);
   };

   get translationIsEditable () {
   	return this.state.formData?.language?.selectedLanguages?.length;
   };

   get currentLanguage () {
   	return this.state.formData?.language?.currentLanguage ?? 0;
   };

   get currentTranslation () {
   	return this.state?.formData?.language?.translations[this.currentLanguage];
   };

   get isDefaultLanguage () {
   	const { currentLanguage, translationDefaultCurrent, } = this.state.formData?.language;
   	const { contestId, } = this.props;

   	if (!contestId) {
   		return 1;
   	};

   	return currentLanguage === translationDefaultCurrent ? 1 : 0;
   };

   get selectedCategories () {
   	return this.state?.category?.participants_categories ?? [];
   }
   get selectedWinnerPrizes () {
   	return this.state?.formData?.winner_prizes ?? [];
   }

   get selectedNationalities () {
   	return this.state?.nationality?.selectedNationalities ?? [];
   }

   _getAllNationality = async () => {
   	try {
   		const response = await contestService.nationalities();

   		await this.save(state => ({
   			nationality: { ...state.nationality, allNationalities: response.data, },
   		}));
   	} catch (error) {
   		error.showErrorNotification();
   	}
   };

   _getAllLanguages = async () => {
   	try {
   		const response = await contestService.languages();

   		await this.save(state => ({
   			formData: {
   				...state.formData,
   				language: {
   					...state?.formData?.language,
   					allLanguages: response.data,
   				},
   			},
   		}));
   	} catch (error) {
   		error.showErrorNotification();
   	}
   };

   _deleteTranslation = async (id) => {
      //удаляем несохраненный язык, очищаем ошибки для переводимых полей
      // const translationID = this.state?.formData?.language?.translations[id]?.id !== 0
      //    ? this.state?.formData?.language?.translations[id]?.id
      //    : null;
   	await this.save(state => ({
   		errors: state?.errors
   			?.filter(item => ![
   				'name',
   				'description',
   				'sendpulse_book',
   				'sendpulse_decline_book',
   				'condition_file_name', ]
   				?.includes(item)),
   	}));
   	const translationID = this.state?.formData?.language?.translations?.[id]?.id || null;

   	try {
   		const removeUnsavedTranslate = async () => {
   			const translations = { ...this.state?.formData?.language.translations, };

   			delete translations?.[id];
   			await this.save(state => ({
   				formData: {
   					...state.formData,
   					language: {
   						...state.formData.language,
   						selectedLanguages: [ ...this.state?.formData?.language?.selectedLanguages, ].filter(language => language !== id),
   						translations,
   						nameCharCounter: 0,
   						descriptionCharCounter: 0,
   					},
   				},
   			}));
   		};
   		const removeSavedTranslate = async (translationID) => {
   			try {
   				await contestService.delTranslationById(translationID);
   				const translations = { ...this.state?.formData?.language.translations, };

   				delete translations[id];
   				await this.save(state => ({
   					formData: {
   						...state.formData,
   						language: {
   							...state.formData.language,
   							translationDefaultCurrent: state.formData.language.translationDefault,
   							deleteContestID: "",
   							translations,
   							selectedLanguages: [ ...this.state?.formData?.language.selectedLanguages, ].filter(language => language !== id),
   						},
   					},
   				}));
   			} catch (error) {
   				NotificationService.errors(error.response.data);
   			}
   		};

   		if (translationID === null) {
            // console.log('Язык НЕ сохранен, удалить');
   			await removeUnsavedTranslate();
   		} else {
            // console.log('Язык СОХРАНЕН, удалить ');
   			await removeSavedTranslate(translationID);
   		};
   		const selectedTranslations = [ ...this.state?.formData?.language.selectedLanguages, ];

   		if (selectedTranslations?.length) {
            //если после удаления конкурса у нас остались выбраные языки, то делаем текущим языком последний из списка выбранных
   			const lastSelectElem = selectedTranslations.pop();

   			await this.save(state => ({
   				formData: {
   					...state.formData,
   					language: {
   						...state.formData.language,
   						currentLanguage: lastSelectElem,
   						nameCharCounter: state?.formData?.language?.translations[lastSelectElem]?.name?.length,
   						descriptionCharCounter: state?.formData?.language?.translations[lastSelectElem]?.description?.length,
   						isTranslateEdited: false,
   					},
   				},
   				isDisabledLangSelect: false,
   			}));
   		}
   	} catch (error) {
   		NotificationService.errors(error.response.data);
   	}
   };

   handleSelectedFile = (event) => {
   	if (event.target) {
   		const { name: condition_file_name, } = event.target.files[0];

   		this.setState(state => ({
   			formData: {
   				...state.formData,
   				language: {
   					...state.formData.language,
   					isTranslateEdited: true,
   					translations: {
   						...state.formData.language.translations,
   						[this.currentLanguage]: { ...this.currentTranslation, condition_file_name, },
   					},
   				},
   			},
   			isDisabledLangSelect: true,
   		}));
   	}
   };

   setErrorClass = name => this.state?.errors?.includes(name) ? "error" : "";

   setErrorsFromServer = async (error) => {
   	NotificationService.errors({ ...error?.response?.data, time: 25000, });
   	const errors = Object?.keys?.(error?.response?.data?.errors)?.map(e => e) ?? [];

   	await this.save({ errors, });
   }

   clearErrorClass = async (name) => {
   	await this.save?.(state => ({ errors: [ ...state?.errors?.filter(e => e !== name), ], }));
   };

   onChangeContest = async (name, value) => {
   	this?.clearErrorClass?.(name);
   	const formData = state => ({
   		formData: {
   			...state?.formData,
   			contest: { ...state?.formData?.contest, [name]: value, },
   		},
   		isContestEdited: true,
   	});

   	await this?.save?.(formData);
   };
	onChangePrizeBlock = async (name, value, index) => {
		await this?.clearErrorClass?.(`winner_prizes.${index}.${name}`);
		const { formData: { winner_prizes, }, } =this.state;
		const formData = state => ({
			isContestEdited: true,
			formData: {
				...state?.formData,
				winner_prizes: winner_prizes.map((items, groupIndex) => {
					if (groupIndex === index) {
						items.map(fields => fields[name] = value);
					}
					return items;
				}),
			},
		});

		await this?.save?.(formData);
	};
	removePrizeList = async (index) => {
		await this.save((state) => {
			return {
				formData: {
					...state.formData,
					winner_prizes: state.formData.winner_prizes.filter(
						(_, sumIndex) => {
							return index !== sumIndex;
						},
					),
				},
			};
		});
	}
	createPrizeInputBlock = async () => {
		const { formData, } = this.state;
		const {winner_prizes} = formData;
		const lastIndex = winner_prizes.length - 1;

		const lastPlacesTo = winner_prizes[lastIndex]?.at(0).places_to; //Array of arrays with single object inside each
		const nextPlacesFrom = lastPlacesTo ? +lastPlacesTo + 1 : 1;

		await this.save({
			formData: {
				...formData,
				winner_prizes: [
					...formData.winner_prizes,
					[ {
						places_from: nextPlacesFrom,
						places_to: '',
						prize: '',
					}, ],

				],
			},

		});
	}

   onChangeStartRegDate = () => async (value) => {
   	const format = CreateContestPopup?.format;
   	const start_reg_date = Moment(value).startOf('day').format(format);
   	const finish_reg_date = Moment(this?.state?.formData?.contest?.finish_reg_date, format).startOf('day');
   	const date_start = Moment(this?.state?.formData?.contest?.date_start, format).startOf('day');
   	const date_end = Moment(this?.state?.formData?.contest?.date_end, format).startOf('day');
   	const formData = state => ({
   		formData: {
   			...state?.formData,
   			contest: {
   				...state?.formData?.contest,
   				start_reg_date,
   				...finish_reg_date.diff(start_reg_date, 'day', true) < 1 && { finish_reg_date: '', },
   				...date_start.diff(start_reg_date, 'day', true) < 2 && { date_start: '', },
   				...date_end.diff(start_reg_date, 'day', true) < 3 && { date_end: '', },
   			},
   		},
   		isContestEdited: true,
   	});

   	await this?.save?.(formData);
   	await this?.clearErrorClass?.("start_reg_date");
   };

   onChangeFinishRegDate = () => async (value) => {
   	const format = CreateContestPopup?.format;
   	const finish_reg_date = Moment(value).startOf('day').format(format);
   	const date_end = Moment(this?.state?.formData?.contest?.date_end, format).startOf('day');
   	const formData = state => ({
   		formData: {
   			...state?.formData,
   			contest: {
   				...state?.formData?.contest,
   				finish_reg_date,
   				...date_end.diff(finish_reg_date, 'day', true) < 1 && { date_end: '', },
   			},
   		},
   		isContestEdited: true,
   	});

   	await this?.save?.(formData);
   	await this?.clearErrorClass?.("finish_reg_date");
   };

   onChangeDateStart = () => async (value) => {
   	const format = CreateContestPopup?.format;
   	const date_start = Moment(value).startOf('day').format(format);
   	const date_end = Moment(this?.state?.formData?.contest?.date_end, format).startOf('day');
   	const formData = state => ({
   		formData: {
   			...state?.formData,
   			contest: {
   				...state?.formData?.contest,
   				date_start,
   				...date_end.diff(date_start, 'day', true) < 1 && { date_end: '', },
   			},
   		},
   		isContestEdited: true,
   	});

   	await this?.save?.(formData);
   	await this?.clearErrorClass?.("date_start");
   };

   onChangeDateEnd = () => async (value) => {
   	const format = CreateContestPopup?.format;
   	const date_end = Moment(value).startOf('day').format(format);
   	const formData = state => ({
   		formData: {
   			...state?.formData,
   			contest: { ...state?.formData?.contest, date_end, },
   		},
   		isContestEdited: true,
   	});

   	await this?.save?.(formData);
   	await this?.clearErrorClass?.("date_end");
   };

   onChangeTranslationCheckbox = async () => {
   	const { contestId, } = this.props;
   	let { currentLanguage, translationDefault, translationDefaultCurrent, } = this.state?.formData?.language;

   	translationDefaultCurrent = contestId && currentLanguage !== translationDefaultCurrent
   		? currentLanguage
   		: translationDefault;
   	const formData = state => ({
   		formData: {
   			...state?.formData,
   			language: { ...state?.formData?.language, isTranslateEdited: true, translationDefaultCurrent, },
   		},
   		isDisabledLangSelect: ![ "0", ]?.includes?.(translationDefault),
   	});

   	await this?.save?.(formData);
   };

   onChangeTranslation = (field, charCounter = false) => async (value) => {
   	this?.clearErrorClass?.(field);
   	const formData = state => ({
   		formData: {
   			...state?.formData,
   			language: {
   				...state?.formData?.language,
   				isTranslateEdited: true,
   				...charCounter && { [`${ field }CharCounter`]: value?.length, },
   				translations: {
   					...state?.formData?.language?.translations,
   					[this?.currentLanguage]: {
   						...state?.formData?.language?.translations?.[this?.currentLanguage],
   						[field]: value,
   					},
   				},
   			},
   		},
   		isDisabledLangSelect: true,
   	});

   	await this.save(formData);
   };

   optionsStartRegDate = (options = {}) => {
   	let {
   		start_reg_date, finish_reg_date, date_start, date_end,
   	} = this.state.formData?.contest;
   	const format = CreateContestPopup.format;
   	const className = this.setErrorClass("start_reg_date");

   	start_reg_date = Moment(start_reg_date, format).startOf('day');
   	finish_reg_date = Moment(finish_reg_date, format).startOf('day');
   	date_start = Moment(date_start, format).startOf('day');
   	date_end = Moment(date_end, format).startOf('day');
   	const tomorrow = Moment().startOf('day').add(1, 'day');
   	const maxLimits = [ finish_reg_date, date_start, date_end, ].filter(l => l.isValid());
   	const max = maxLimits.length ? Moment.min(maxLimits).add(-1, 'day') : null;
   	const min = tomorrow;
   	let value = start_reg_date.isValid() ? start_reg_date : min;

   	if (start_reg_date.isValid() && tomorrow.diff(start_reg_date, 'day', true) > 0) {
         // start_reg_date is valid in the past
   		return {
   			...options, className, disabled: true, value: value.toDate(),
   		};
   	} else if (!max) {
         // start_reg_date is valid has no max limitation
   		const PickerProps = { minDate: min.toDate(), };

   		value = start_reg_date.isValid() ? value.toDate() : null;

   		return {
   			...options, className, PickerProps, value,
   		};
   	} else if (value.diff(max, 'day') > 0) {
         // start_reg_date has incorrect max and/or min limitations
   		return {
   			...options, className, disabled: true, value: null,
   		};
   	}
         // start_reg_date has correct max and min limitations
   	const PickerProps = { maxDate: max.toDate(), minDate: min.toDate(), };

   	value = start_reg_date.isValid() ? value.toDate() : null;

   	return {
   		...options, className, PickerProps, value,
   	};

   };

   optionsFinishRegDate = (options = {}) => {
   	const format = CreateContestPopup.format;
   	let {
   		start_reg_date, finish_reg_date, date_start, date_end,
   	} = this.state.formData?.contest;
   	const className = this.setErrorClass("finish_reg_date");

   	start_reg_date = Moment(start_reg_date, format).startOf('day');
   	finish_reg_date = Moment(finish_reg_date, format).startOf('day');
   	date_start = Moment(date_start, format).startOf('day');
   	date_end = Moment(date_end, format).startOf('day');
   	const tomorrow = Moment().startOf('day').add(1, 'day');
   	const afterRegistration = Moment(tomorrow).add(1, 'day');
   	const maxLimits = [ date_end, ].filter(l => l.isValid());
   	const minLimits = [ start_reg_date, Moment(tomorrow).add(start_reg_date.isValid() ? -1 : 0, 'day'), ].filter(l => l.isValid());
   	const max = maxLimits.length ? Moment.min(maxLimits).add(-1, 'day') : null;
   	const min = Moment.max(minLimits).add(1, 'day');
   	let value = finish_reg_date.isValid() ? finish_reg_date : min;

   	if (finish_reg_date.isValid() && tomorrow.diff(finish_reg_date, 'day', true) > 0) {
         // finish_reg_date is valid in the past
   		return {
   			...options, className, disabled: true, value: value.toDate(),
   		};
   	} else if (min.diff(value) > 0) {
         // finish_reg_date is valid but has invalid min limitation
   		const PickerProps = { minDate: min.toDate(), };

   		return {
   			...options, className, PickerProps, value: null,
   		};
   	} else if (!max) {
         // finish_reg_date is valid has no max limitation
   		const PickerProps = { minDate: min.toDate(), };

   		value = finish_reg_date.isValid() ? value.toDate() : null;

   		return {
   			...options, className, PickerProps, value,
   		};
   	} else if (value.diff(max, 'day') > 0) {
         // finish_reg_date has incorrect max and/or min limitations
   		return {
   			...options, className, disabled: true, value: null,
   		};
   	}
         // finish_reg_date has correct max and min limitations
   	const PickerProps = { maxDate: max.toDate(), minDate: min.toDate(), };

   	value = finish_reg_date.isValid() ? value.toDate() : null;

   	return {
   		...options, className, PickerProps, value,
   	};

   };

   optionsDateStart = (options = {}) => {
   	const format = CreateContestPopup.format;
   	let {
   		start_reg_date, finish_reg_date, date_start, date_end,
   	} = this.state.formData?.contest;
   	const className = this.setErrorClass("date_start");

   	start_reg_date = Moment(start_reg_date, format).startOf('day');
   	finish_reg_date = Moment(finish_reg_date, format).startOf('day');
   	date_start = Moment(date_start, format).startOf('day');
   	date_end = Moment(date_end, format).startOf('day');
   	const tomorrow = Moment().startOf('day').add(1, 'day');
   	const afterRegistration = Moment(tomorrow).add(1, 'day');
   	const afterActivation = Moment(afterRegistration).add(1, 'day');
   	const maxLimits = [ date_end, ].filter(l => l.isValid());
   	const minLimits = [ start_reg_date, Moment(tomorrow).add(start_reg_date.isValid() ? -2 : 0, 'day'), ].filter(l => l.isValid());
   	const max = maxLimits.length ? Moment.min(maxLimits).add(-1, 'day') : null;
   	const min = Moment.max(minLimits).add(2, 'day');
   	let value = date_start.isValid() ? date_start : min;

   	if (date_start.isValid() && tomorrow.diff(date_start, 'day', true) > 0) {
         // date_start is valid in the past
   		return {
   			...options, className, disabled: true, value: value.toDate(),
   		};
   	} else if (min.diff(value) > 0) {
         // date_start is valid but has invalid min limitation
   		const PickerProps = { minDate: min.toDate(), };

   		return {
   			...options, className, PickerProps, value: null,
   		};
   	} else if (!max) {
         // date_start is valid has no max limitation
   		const PickerProps = { minDate: min.toDate(), };

   		value = date_start.isValid() ? value.toDate() : null;

   		return {
   			...options, className, PickerProps, value,
   		};
   	} else if (value.diff(max, 'day') > 0) {
         // date_start has incorrect max and/or min limitations
   		return {
   			...options, className, disabled: true, value: null,
   		};
   	}
         // date_start has correct max and min limitations
   	const PickerProps = { maxDate: max.toDate(), minDate: min.toDate(), };

   	value = date_start.isValid() ? value.toDate() : null;

   	return {
   		...options, className, PickerProps, value,
   	};

   };

   optionsDateEnd = (options = {}) => {
   	const format = CreateContestPopup.format;
   	let {
   		start_reg_date, finish_reg_date, date_start, date_end,
   	} = this.state.formData?.contest;
   	const className = this.setErrorClass("date_end");

   	start_reg_date = Moment(start_reg_date, format).startOf('day');
   	finish_reg_date = Moment(finish_reg_date, format).startOf('day');
   	date_start = Moment(date_start, format).startOf('day');
   	date_end = Moment(date_end, format).startOf('day');
   	const tomorrow = Moment().startOf('day').add(1, 'day');
   	const AfterRegistration = Moment(tomorrow).startOf('day').add(1, 'day');
   	const AfterActivation = Moment(AfterRegistration).startOf('day').add(1, 'day');
   	const AfterStart = Moment(AfterActivation).startOf('day').add(1, 'day');

   	const minLimits = [
   		start_reg_date,
   		finish_reg_date,
   		date_start,
   		Moment(tomorrow).add(start_reg_date.isValid() ? -1 : 2, 'day'),
   		Moment(tomorrow).add(finish_reg_date.isValid() ? -1 : 0, 'day'),
   		Moment(tomorrow).add(date_start.isValid() ? -1 : 0, 'day'),
   	].filter(l => l.isValid());

   	const min = Moment.max(minLimits).add(1, 'day');

   	let value = date_end.isValid() ? date_end : min;

   	if (date_end.isValid() && tomorrow.diff(date_end, 'day', true) > 0) {
         // date_end is valid in the past
   		return {
   			...options, className, disabled: true, value: value.toDate(),
   		};
   	} else if (min.diff(value) > 0) {
         // date_end is valid but has invalid min limitation
   		const PickerProps = { minDate: min.toDate(), };

   		return {
   			...options, className, PickerProps, value: null,
   		};
   	}
         // date_end is valid has no max limitation
   	const PickerProps = { minDate: min.toDate(), };

   	value = date_end.isValid() ? value.toDate() : null;

   	return {
   		...options, className, PickerProps, value,
   	};

   };

   setUnsavedTranslation = async () => {
   	return new Promise((resolve) => {
   		const translationDefault = {
   			id: 0,
   			name: '',
   			description: '',
   			condition_file_name: '',
   			sendpulse_book: '',
   			sendpulse_decline_book: '',
   		};

   		const translations = this.state?.formData?.language?.selectedLanguages
   			.filter(language => !this.state?.formData?.language?.translations[language])
   			.map(language => ({ [language]: { ...translationDefault, }, }));

   		this.setState(
   			state => ({
   				formData: {
   					...state.formData,
   					language: {
   						...state.formData.language,
   						translations: Object.assign({}, state?.formData?.language?.translations, ...translations),
   						nameCharCounter: this.currentTranslation?.name?.length ?? 0,
   						descriptionCharCounter: this.currentTranslation?.description?.length ?? 0,
   					},
   				},
   			}),
   			resolve,
   		);
   	});
   };

   createEditContest = async () => {
   	const { contestId, } = this.props;
   	const isTranslateEdited = this.state?.formData?.language?.isTranslateEdited;
   	const isContestEdited = this.state?.isContestEdited;
   	const contestTranslationsId = this.currentTranslation?.id;

   	if (!contestId) {
         // console.log('Create contest');
   		await this.saveContest();
   	} else {
   		if (!isTranslateEdited && !isContestEdited) {
   			await this.save({ redirectContestCard: true, });
   		}
   		if (isTranslateEdited && !contestTranslationsId && isContestEdited) {
   			try {
   				await this.addTranslation();
   				await this.editContest();
   				if (this.state?.checkEditContestField) {
   					await this.save({ redirectContestCard: true, });
   				}
   			} catch (error) {
   				console.log(error);
   			}
   		} else if (isContestEdited || isTranslateEdited && contestTranslationsId) {
   			try {
   				await this.editContest();
   				this.state?.checkEditContestField
   					? await this.save({ redirectContestCard: true, })
   					: null;
   			} catch (error) {
   				console.log(error);
   			}
   		} else if (isTranslateEdited && !contestTranslationsId) {
   			try {
   				await this.addTranslation();
   				this.state?.checkEditTranslationField
   					? await this.save({ redirectContestCard: true, })
   					: null;
   			} catch (error) {
   				console.log(error);
   			}
   		}
   	}
   };

   saveContest = async () => {
   	try {
   		const language_id = this.state?.formData?.language?.currentLanguage;
   		const translations = this.state?.formData?.language?.translations;
   		const formData = new FormData();

   		this.selectedCategories?.forEach?.(x => formData.append('participants_categories[]', x));
   		this.selectedWinnerPrizes.forEach?.((item, index) => {
			   item.forEach(fields => {
				   Object.entries(fields)?.map(([key, value]) => {
					   formData.append(`winner_prizes[${index}][${key}]`, value )
				   })
			   } )
		} );
   		this.selectedNationalities?.forEach?.(x => formData.append('nationalities[]', x));
   		CreateContestPopup.contestFields.forEach(item => formData.append(item, this.state?.formData?.contest?.[item]));
   		formData.append('language_id', language_id);
   		formData.append('default', this.isDefaultLanguage);
   		if (Object.keys(translations)?.length) {
   			CreateContestPopup?.translationFields.forEach(item => formData.append(item, this.currentTranslation?.[item]));
   		}


   		if (this.UploadFile && this.UploadFile?.files?.length) {
   			formData.append('condition_file_name', this.UploadFile.files[0]);
   		}
   		await this.save({ errors: [], isDisabled: true, });
   		const { data, } = await contestService.saveContest(formData);
   		const { id, } = data;

   		await this.save(state => ({
   			formData: {
   				...state.formData,
   				language: { ...state.formData?.language, translationDefault: state?.formData?.language?.currentLanguage, },
   			},
   			id,
   			isContestEdited: false,
   			redirectEditContest: true,
   		}));
   	} catch (error) {
   		await this.setErrorsFromServer(error);
   	} finally {
   		await this.save({ isDisabled: false, });
   	}
   };

   editContest = async () => {
   	try {
   		const { contestId, } = this.props;
   		const currentLanguage = this.state?.formData?.language?.currentLanguage;
   		const formData = new FormData();

   		CreateContestPopup?.contestFields.forEach(item => formData.append(item, this.state?.formData?.contest[item]));
   		CreateContestPopup?.translationFields.forEach(item => formData.append(item, this.currentTranslation[item]));
   		this.selectedCategories?.length
   			? this.selectedCategories?.forEach?.(x => formData.append('participants_categories[]', x))
   			: formData.append('participants_categories[]', []);
   		this.selectedNationalities?.length
   			? this.selectedNationalities?.forEach?.(x => formData.append('nationalities[]', x))
   			: formData.append('nationalities[]', []);
   		if (this.UploadFile && this.UploadFile?.files?.length) {
   			formData.append('condition_file_name', this.UploadFile?.files?.[0]);
   		}
		   this.selectedWinnerPrizes?.length
			   ? this.selectedWinnerPrizes.forEach?.((item, index) => {
			   item.forEach(fields => {
				   Object.entries(fields)?.map(([key, value]) => {
					   formData.append(`winner_prizes[${index}][${key}]`, value )
				   })
			   } )
		   } )
			   : formData.append('winner_prizes[]', [])
   		formData.append('language_id', currentLanguage);
   		formData.append('default', this.isDefaultLanguage);
   		formData.append('contests_translations_id', this.currentTranslation?.id !== 0 ? this.currentTranslation?.id : "");
   		await this.save({
   			errors: [], isDisabled: true, isDisabledLangSelect: true, checkEditContestField: false,
   		});
   		const { data, } = await contestService.updateContest(+contestId, formData);

   		await this.save(state => ({
   			formData: {
   				...state.formData,
   				contest: {
   					...state.formData?.contest,
   					start_reg_date: data?.start_reg_date ?? "",
   					finish_reg_date: data?.finish_reg_date ?? "",
   					date_start: data?.date_start ?? "",
   					date_end: data?.date_end ?? "",
   					condition_closed_orders: data?.condition_closed_orders ?? "",
   					condition_max_loss: data?.condition_max_loss ?? "",
   				},
   				language: {
   					...state.formData.language,
   					isTranslateEdited: false,
   					translationDefault: state?.formData?.language?.translationDefaultCurrent,
   				},
   			},
   			isDisabledLangSelect: false,
   			isContestEdited: false,
   			checkEditContestField: true,
   		}));
   	} catch (error) {
   		await this.setErrorsFromServer(error);
   	} finally {
   		await this.save({ isDisabled: false, });
   	}
   };

   addTranslation = async () => {
   	try {
   		const { contestId, } = this.props;
   		const currentLanguage = this.state?.formData?.language?.currentLanguage;
   		const translationDefault = this.state.formData?.language?.translationDefault;
   		const formData = new FormData();

   		CreateContestPopup?.translationFields.map(item => formData.append(item, this.currentTranslation[item]));
   		formData.append('language_id', currentLanguage);
   		formData.append('contest_id', +contestId);
   		formData.append('default', this.isDefaultLanguage);
   		if (this.UploadFile && this.UploadFile?.files?.length) {
   			formData.append('condition_file_name', this.UploadFile.files[0]);
   		}
   		await this.save({
   			errors: [], isDisabled: true, isDisabledLangSelect: true, checkEditTranslationField: false,
   		});
   		const { data, } = await contestService.addTranslation(formData);
   		const { language_id: idTranslationDefault, default: indexTranslationDefault, } = data;

   		await this.save(state => ({
   			formData: {
   				...state.formData,
   				language: {
   					...state.formData.language,
   					isTranslateEdited: false,
   					translations: { ...state.formData?.language?.translations, [+idTranslationDefault]: { ...data, }, },
   				},
   			},
   			isContestEdited: false,
   			isDisabledLangSelect: false,
   			checkEditTranslationField: true,
   		}));
   		if (+indexTranslationDefault === 1 && translationDefault !== +idTranslationDefault) {
   			await this.save(state => ({
   				language: {
   					...state.language,
   					translationDefault: +idTranslationDefault,
   					translationDefaultCurrent: +idTranslationDefault,
   				},
   			}));
   		}
   	} catch (error) {
   		await this.setErrorsFromServer(error);
   	} finally {
   		await this.save({ isDisabled: false, });
   	}
   }

   renderCategories = () => {
   	const { 1: a, 2: b, ...allCategories } = this.state.category?.allCategories;
   	const selectedValues = this.state.category?.participants_categories ?? [];
   	const error = this.setErrorClass("participants_categories");
   	const onSelect = async (index) => {
   		if ([ 0, ].includes(index)) {
   			const participants_categories = Object.keys(allCategories)
   				.map(item => +item)
   				.filter(item => item !== index);

   			await this.save(state => ({
   				category: {
   					...state.category,
   					participants_categories,
   				},
   				isContestEdited: true,
   			}));
   		} else {
   			await this.save(state => ({
   				category: {
   					...state?.category,
   					participants_categories: [ ...state?.category?.participants_categories, +index, ],
   				},
   				isContestEdited: true,
   			}));
   		}
   		await this.clearErrorClass("participants_categories");
   	};
   	const onUnSelect =  async (index) => {
   		await this.save(state => ({
   			category: {
   				...state?.category,
   				participants_categories: [ ...state?.category?.participants_categories, ].filter(item => item !== index),
   			},
   			isContestEdited: true,
   		}));
   	};
   	const options = {
   		translate: this.props?.translate,
   		values: allCategories,
   		selectedValues,
   		error,
   		disabled: this.state?.isDisabled,
   		onSelect: async index => await onSelect(index),
   		onUnSelect: async index => await onUnSelect(index),
   	};

   	return (<Categories { ...options } />);
   };

   renderNationalities = () => {
   	const selectedValues = this.state.nationality?.selectedNationalities ?? [];
   	const error = this.setErrorClass("nationalities");
   	const onSelect = async (id) => {
   		if ([ 0, ].includes(id)) {
   			const selectedNationalities = this.state?.nationality?.allNationalities
   				.map(item => +item.id)
   				.filter(item => +item !== id);

   			await this.save(state => ({
   				nationality: { ...state.nationality, selectedNationalities, },
   				isContestEdited: true,
   			}));
   		} else {
   			await this.save(state => ({
   				nationality: {
   					...state.nationality,
   					selectedNationalities: [ ...state.nationality?.selectedNationalities, id, ],
   				},
   				isContestEdited: true,
   			}));
   		}
   		await this.clearErrorClass("nationalities");
   	};
   	const onUnSelect =  async (id) => {
   		await this.save(state => ({
   			nationality: {
   				...state.nationality,
   				selectedNationalities: [ ...state.nationality?.selectedNationalities, ].filter(item => item !== id),
   			},
   			isContestEdited: true,
   		}));
   	};
   	const options = {
   		translate: this.props?.translate,
   		values: this.state?.nationality?.allNationalities,
   		selectedValues,
   		error,
   		disabled: this.state?.isDisabled,
   		onSelect: async index => await onSelect(index),
   		onUnSelect: async index => await onUnSelect(index),
   	};

   	return (
	<Citizenship { ...options } />
   	);
   };

   renderLanguages () {
   	const { isDisabledLangSelect, } = this.state;
   	const values = this.state?.formData?.language?.allLanguages ?? [];
   	const selectedLanguages = this.state?.formData?.language?.selectedLanguages ?? [];
   	const translationDefault = this.state?.formData?.language.translationDefault ?? null;
   	const deleteContestID = this.state?.formData?.language.deleteContestID ?? "";
   	const showConfirmPopup = this.state?.formData?.language.showConfirmPopup ?? false;
   	const valueDefault = false;
   	const options = {
   		SelectProps: {
   			disabled: isDisabledLangSelect,
   			className: [
   				'magic-multi-select__select languages',
   				selectedLanguages.length ? 'magic-multi-select__select_not-empty' : '',
   				this.setErrorClass("language_id"),
   				isDisabledLangSelect ? 'disabled' : '',
   			],
   		},
   		selected: this.currentLanguage,
   		selectedValues: selectedLanguages,
   		valueDefault,
   		values: values.map(data => data?.id).filter(item => !selectedLanguages.includes(item)),
   		ItemProps: value => value === valueDefault ? { disabled: true, } : {},
   		SelectedProps: value => ({ unselectable: value !== translationDefault, }),
   		selectedToLabel: (id) => {
   			const find = id === valueDefault ? false : values.find(data => id === data.id);
   			const className = ({ code, }) => [ 'flag-icon', 'flag-icon-squared', `flag-icon-${ code.toLowerCase() }`, ].join(' ');

   			return !find ? '' :
   				(<div style={ { position: 'relative', } }>
	<span className={ className(find) } />
	<span className="title" title={ find?.name }>{find?.name}</span>
	{deleteContestID === id && (
	<MagicConfirm
	accept={ this.props.translate('contests_translation_accept') }
	reject={ this.props.translate('contests_translation_reject') }
	onReject={ () => this.setState(state => ({
   								formData: {
   									...state.formData,
   									language: { ...state.formData.language, showConfirmPopup: false, },
   								},
   							})) }
	onAccept={ () => this._deleteTranslation(deleteContestID) }
	title={ this.props.translate('contests_translation_delete') }
	isVisible={ showConfirmPopup }
   						/>
   					)}
   				</div>);
   		},
   		valueToLabel: (id) => {
   			if (id === valueDefault) {
   				return this.props.translate('contest_popup_select-language');
   			}
   			const find = values?.find(data => id === data?.id);

   			return find ? find?.name : '?';
   		},
   		onSelect: async (index) => {
   			await this.save(state => ({
   				formData: {
   					...state.formData,
   					language: {
   						...state.formData.language,
   						selectedLanguages: [ ...state?.formData?.language.selectedLanguages, index, ],
   						currentLanguage: index,
   						isTranslateEdited: true,
   					},
   				},
   				isDisabledLangSelect: true,
   			}));
   			await this.setUnsavedTranslation();
   			if (this.state.contestId) {
   				await this.save(state => ({
   					formData: {
   						...state.formData,
   						language: {
   							...state.formData.language,
   							translations: {
   								...state.formData.language.translations,
   								[state.formData.language.currentLanguage]: {
   									...state.formData.language.translations[state.language?.currentLanguage],
   									default: '0',
   								},
   							},
   						},
   					},
   				}));
   			}
   		},
   		onUnSelect: async (index) => {
   			if (this.state?.formData?.language?.translations[index]?.id === 0) {
   				this._deleteTranslation(index);
   				this.onChangeTranslationCheckbox();
   			}
            // else if(this.state.isDisabledLangSelect) {
   			else {
   				this.setState(state => ({
   					formData: {
   						...state.formData,
   						language: {
   							...state.formData.language,
   							deleteContestID: index,
   							showConfirmPopup: true,
   						},
   					},
   				}));
   			}
   		},
   		onClick: (value) => {
   			if (!this.state?.formData?.language?.isTranslateEdited) {
   				this.setState(state => ({
   					formData: {
   						...state.formData,
   						language: {
   							...state.formData.language,
   							currentLanguage: value,
   							nameCharCounter: state.formData.language?.translations[value]?.name?.length ?? 0,
   							descriptionCharCounter: state.formData.language?.translations[value]?.description?.length ?? 0,
   						},
   					},
   				}));
   			}
   		},
   	};

   	return (
	<div className="popup__field">
	<div className="popup__field_item"><MagicMultiSelect { ...options } /></div>
   		</div>
   	);
   };

   renderTranslationsFields = () => {
   	const defaultLanguage = () => {
   		const { currentLanguage, translationDefault, translationDefaultCurrent, } = this.state.formData?.language;
   		const options = {
   			values: [ false, true, ],
   			text: "",
   			index: currentLanguage === translationDefaultCurrent ? 1 : 0,
   			onChange: this.onChangeTranslationCheckbox,
   			updateStateFromProps: true,
   			...currentLanguage === translationDefault && { className: 'not-active', },
   		};

   		return (
	<div className="popup__field">
	<div className={ `popup__field_item ${ !this.props.contestId ? 'popup__field_item-disable' : '' }` }>
	<label>{this.props.translate('contest_card_default-language')}</label>
	<div className={ `popup__field_item-checkbox ` }><MagicSwitch { ...options } /></div>
   				</div>
   			</div>
   		);
   	};
   	const name = () => {
   		const max = 150;
   		const options = {
   			className: [
   				'name_contest',
   				this.setErrorClass("name"),
   				!this.translationIsEditable ? 'disabled' : '',
   			],
   			currentLanguage: this.currentLanguage,
   			value: this.currentTranslation?.name ?? '',
   			onChange: this.onChangeTranslation('name', true),
   			disabled: !this.translationIsEditable,
   		};

   		return (
	<div className="popup__field">
	<div className="popup__field_item">
	<label> {this.props.translate('contest_popup_title-contest')} </label>
	<div className="input__wrapper">
	<MagicInput { ...options } />
	<span className="counter">{`${ this.state.formData.language?.nameCharCounter }/${ max }`}</span>
   					</div>
   				</div>
   			</div>
   		);
   	};
   	const description = () => {
   		const max = 300;
   		const onChange = this.onChangeTranslation('description', true);
   		const options = {
   			className: `magic-textarea scroll ${ this.setErrorClass("description") }`,
   			value: this.currentTranslation?.description ?? '',
   			onChange: ({ value, }) => onChange(value),
   			notToFocus: true,
   			isDisabled: !this.translationIsEditable,
   		};

   		return (
	<div className="popup__field">
	<div className="popup__field_item">
	<label> {this.props.translate('contest_popup_description-contest')} </label>
	<div className="textarea__wrapper">
	<MagicTextarea { ...options } />
	<span className="counter">{`${ this.state.formData.language?.descriptionCharCounter }/${ max }`}</span>
   					</div>
   				</div>
   			</div>
   		);
   	};
   	const file = () => {
   		const { errors, formData: { language, }, } = this.state;
   		const fileName = () => {
   			const cutFileLength = 30;

   			return this.currentTranslation?.condition_file_name
   				? `${ this.currentTranslation?.condition_file_name?.substr?.(0, cutFileLength) }...`
   				: '';
   		};

   		return (
	<div className="popup__field">
	<div className="popup__field_item popup__field_item-upload-file">
	<label>{this.props.translate('contest_popup_condition')}</label>
	<div className="popup__field_item" style={ { margin: 0, } }>
	<div className="upload_btn">
	<label
	className={
   								`${ errors?.includes("condition_file_name") ? "error" : "" }
                            ${ !language?.selectedLanguages?.length ? "disabled" : "" }`
   							}
   							>
	{this.props.translate('contest_popup_description-condition')}
	<input
	accept="application/pdf,application/vnd.ms-excel"
	className="visibility-hidden"
	disabled={ !this.translationIsEditable }
	type="file"
	ref={ node => this.UploadFile = node }
	onChange={ this.handleSelectedFile }
   								/>
   							</label>
   						</div>
	<div
	className={
   							`upload_path 
                        ${ errors?.includes("condition_file_name") ? "error" : "" }
                        ${ !language?.selectedLanguages?.length ? "disabled" : "" }`
   						}
   						>{fileName()}</div>
   					</div>
   				</div>
   			</div>
   		);
   	};
   	const sendpulse = () => {
   		const options = {
   			className: [ !this.translationIsEditable ? 'disabled' : '', this.setErrorClass("sendpulse_book"), ],
   			value: this.currentTranslation ? this.currentTranslation.sendpulse_book : '',
   			onChange: this.onChangeTranslation('sendpulse_book', true),
   			onValid: (value) => {
   				if (!!isNaN(value) || value.length > 8) {
   					throw new Error('error');
   				}
   			},
   			disabled: !this.translationIsEditable,
   		};

   		return (
	<div className="popup__field_item">
	<label> {this.props.translate('contest_popup_sendpulse_book')} </label>
	<MagicInput { ...options } />
   			</div>
   		);
   	};
   	const sendpulseDecline = () => {
   		const options = {
   			className: [ !this.translationIsEditable ? 'disabled' : '', this.setErrorClass("sendpulse_decline_book"), ],
   			value: this.currentTranslation ? this.currentTranslation.sendpulse_decline_book : '',
   			onChange: this.onChangeTranslation('sendpulse_decline_book', true),
   			onValid: (value) => {
   				if (!!isNaN(value) || value.length > 8) {
   					throw new Error('error');
   				}
   			},
   			disabled: !this.translationIsEditable,
   		};

   		return (
	<div className="popup__field_item">
	<label> {this.props.translate('contest_popup_sendpulse_decline_book')} </label>
	<MagicInput { ...options } />
   			</div>);
   	};

   	return (
	<React.Fragment>
	{defaultLanguage()}
	{name()}
	{description()}
	{file()}
	<div className="popup__field">
	{sendpulse()}
	{sendpulseDecline()}
   			</div>
   		</React.Fragment>
   	);
   };

   renderPopup = () => {
   	const {
   		translate, contestId, isOpenEditPopup, isOpenCreatePopup,
   	} = this.props;
   	const {
   		formData: { contest, winner_prizes, }, isDisabled,
   	} = this.state;
   	const popupHeader = () => {
   		return (
	<div className="popup__header">
	<div className="popup__title">{translate(`contest_popup_${ contestId ? 'title-edit' : 'title' }`)}</div>
   			</div>
   		);
   	};
   	const popupContent = () => {
   		return (
	<div className="popup__content">
	<div className="popup__field">
	<div className="popup__field_item">
	<label>{ translate('contest_popup_start_reg_date') }</label>
	<MagicDate
	{ ...this.optionsStartRegDate({
   								locale: this.locale,
   								value: contest?.start_reg_date,
   								emptyLabel: "",
   								disabled: isDisabled,
   								onChange: this.onChangeStartRegDate('start_reg_date'),
   							}) }
   						/>
   					</div>
	<div className="popup__field_item">
	<label>{ translate('contest_popup_finish_reg_date') }</label>
	<MagicDate
	{ ...this.optionsFinishRegDate({
   								locale: this.locale,
   								value: contest?.finish_reg_date,
   								emptyLabel: "",
   								disabled: isDisabled,
   								onChange: this.onChangeFinishRegDate('finish_reg_date'),
   							}) }
   						/>
   					</div>
   				</div>
	<div className="popup__field">
	<div className="popup__field_item">
	<label>{ translate('contest_popup_date_start') }</label>
	<MagicDate
	{ ...this.optionsDateStart({
   								locale: this.locale,
   								value: contest?.date_start,
   								emptyLabel: "",
   								disabled: isDisabled,
   								onChange: this.onChangeDateStart('date_start'),
   							}) }
   						/>
   					</div>
	<div className="popup__field_item">
	<label>{ translate('contest_popup_date_end') }</label>
	<MagicDate
	{ ...this.optionsDateEnd({
   								locale: this.locale,
   								value: contest?.date_end,
   								emptyLabel: "",
   								disabled: isDisabled,
   								onChange: this.onChangeDateEnd('date_end'),
   							}) }
   						/>
   					</div>
   				</div>
	<div className="popup__field">
	<div className="popup__field_item">
	<label> {translate('contest_popup_amount_prize')} </label>
	<MagicInput
	className={ [ 'magic-input--dollar-sign', this.setErrorClass("prize_fund"), ] }
	value={ String(contest?.prize_fund) }
	onChange={ value => this.onChangeContest('prize_fund', value) }
	onValid={ (value) => {
   								const regExp = /^[0-9]{0,1000000}$/g;

   								if (!regExp.test(value) || +value > 1e6) {
   									throw new Error('error');
   								}
   							} }
	disabled={ isDisabled }
   						/>
   					</div>
	<div className="popup__field_item">
	<label> {translate('contest_popup_prize')} </label>
	<MagicInput
	className={ [ this.setErrorClass("prize_count"), ] }
	value={ String(contest?.prize_count) }
	onChange={ value => this.onChangeContest('prize_count', value) }
	onValid={ (value) => {
   								const regExp = /^[0-9]{0,3}$/g;

   								if (!regExp.test(value) || +value > 100) {
   									throw new Error('error');
   								}
   							} }
	disabled={ isDisabled }
   						/>
   					</div>
   				</div>
	{ winner_prizes?.map((item, index) => {
   					return (
						   < div key={index}>
						<label  className="popup__field_item"> {index + 1} {translate('contest_popup_prize_places')} </label>
						{item?.map((_, i) => {
							   return (
								   <div className="popup__field" key={ i }>
									   <div className="contest__prize_label">
										   <label> {translate('contest_card_places_from_req')} </label>
										   <MagicInput
											   className={ [ this.setErrorClass(`winner_prizes.${index}.places_from`), 'disabled'] }
											   disabled={ true } //always disabled by TT
											   value={ String(_?.places_from) }
											   onChange={ value => this.onChangePrizeBlock('places_from', value, index) }
											   onValid={ (value) => {
												   const regExp = /^[0-9]{0,3}$/g;
												   if (!regExp.test(value) || +value > 100) {
													   throw new Error('error');
												   }
											   } }
										   />
									   </div>
									   <div className="contest__prize_label">
										   <label> {translate('contest_card_places_to_req')} </label>
										   <MagicInput
											   className={ [ this.setErrorClass(`winner_prizes.${index}.places_to`), ] }
											   disabled={ isDisabled || index < winner_prizes.length - 1}
											   value={ String(_?.places_to) }
											   onChange={ value => this.onChangePrizeBlock('places_to', value, index) }
											   onValid={ (value) => {
												   const regExp = /^[0-9]{0,3}$/g;

												   if (!regExp.test(value) || +value > 100) {
													   throw new Error('error');
												   }
											   } }
										   />
									   </div>
									   <div className="contest__prize_label">
										   <label> {translate('contest_card_prize_req')} </label>
										   <MagicInput
											   isRequired={true}
											   className={ [ this.setErrorClass(`winner_prizes.${index}.prize`), ] }
											   disabled={ isDisabled || index < winner_prizes.length - 1}
											   value={ String(_?.prize) }
											   onChange={ value => this.onChangePrizeBlock('prize', value, index) }
											   onValid={ (value) => {
												   const regExp = /^[0-9]{0,1000000}$/g;

												   if (!regExp.test(value) ||  +value > 1e6 ) {
													   throw new Error('error');
												   }
											   } }
										   />
									   </div>
									   { index !== 0 && index === winner_prizes.length - 1
										   ? <div
											   className="remove__prize__btn"
											   onClick={() => this.removePrizeList(index)}
										   />
										   : ''
									   }
									   {index === winner_prizes.length - 1 && <>
										   <div className="info__prize__btn"/>
										   <p className='info__prize__msg'>{translate('contest_card_info_block')}</p>
									   </>}
								   </div>

							   );
						   })}
						   </div>
   					);
   				})

   				}
		<button disabled={this.createPrizePlacesIsDisabled} className="popup__field_item add-prize-btn" onClick={ () => this.createPrizeInputBlock() }>+</button>

	<div className="popup__field">
	<div className="popup__field_item select">
	<label>{this.props.translate('contest_popup_citizenship')}</label>
	{this.renderNationalities()}
   					</div>
   				</div>
	<div className="popup__field">
	<div className="popup__field_item select">
	<label>{this.props.translate('contest_popup_category')}</label>
	{this.renderCategories()}
   					</div>
   				</div>
	<div className="popup__field">
	<div className="popup__field_item">
	<label> {translate('contest_popup_drawdown_condition')} </label>
	<MagicInput
	className={ [ this.setErrorClass("condition_max_loss"), ] }
	value={ String(contest?.condition_max_loss) }
	onChange={ value => this.onChangeContest('condition_max_loss', value) }
	onValid={ (value) => {
   								const regExp = /^[0-9]{0,3}$/g;

   								if (!regExp.test(value) || +value > 100) {
   									throw new Error('error');
   								}
   							} }
	disabled={ isDisabled || [ 1, ].includes(+this.props?.data?.contests?.contests?.status) }
   						/>
   					</div>
	<div className="popup__field_item">
	<label> {translate('contest_popup_closed_condition')} </label>
	<MagicInput
	className={ [ this.setErrorClass("condition_closed_orders"), ] }
	value={ String(contest?.condition_closed_orders) }
	onChange={ value => this.onChangeContest('condition_closed_orders', value) }
	onValid={ (value) => {
   								const regExp = /^[0-9]{0,20}$/g;

   								if (!regExp.test(value) || +value < 0) {
   									throw new Error('error');
   								}
   							} }
	disabled={ isDisabled || [ 1, ].includes(+this.props?.data?.contests?.contests?.status) }
   						/>
   					</div>
   				</div>
	{this.renderLanguages()}
	{this.renderTranslationsFields()}
   			</div>
   		);
   	};

   	return (
	<div className="contest__create-popup popup__layout">
	<div className="popup__layout">
	<div className="popup__wrapper scroll">
	{popupHeader()}
	{popupContent()}
	<PopupControlButtons
	contestId={ +contestId }
	translate={ translate }
	onAccept={ this.createEditContest }
	disabled={ isDisabled }
	onCancel={ () => contestId ? isOpenEditPopup() : isOpenCreatePopup() }
   					/>
   				</div>
   			</div>
   		</div>
   	);
   };

   render () {
   	return (
	<React.Fragment>
	{this.state?.redirectEditContest && <Redirect to={ `/contests/contest/${ this.state?.id }/edit` } />}
	{this.state?.redirectContestCard && <Redirect to={ `/contests/contest/${ this.props?.contestId }` } />}
	{this.renderPopup()}
   		</React.Fragment>
   	);
   }
}