import SupportLinkConfig from './SupportLinkConfig';

export const breadcrumbs4Support = (translate, supportId) => {
  return SupportLinkConfig(translate, ['supports', 'support'], {
    supportId,
  });
};

export const breadcrumbs4Supports = translate => {
  return SupportLinkConfig(translate, ['supports'], {});
};
