//PDF file received from backend as base64 string
//for chrome there is limitation for the size of pdf documents loaded by a data-URI
//so we need to convert large ase64 data back to binary, packed in a Blob and then set our iframe's src to a blob-URI pointing to that Blob
//https://stackoverflow.com/questions/58488416/open-base64-encoded-pdf-file-using-javascript-issue-with-file-size-larger-than
function getBlobFromBase64 (base64, type = "application/octet-stream") {
	const binStr = atob(base64);
	const len = binStr.length;
	const arr = new Uint8Array(len);

	for (let i = 0; i < len; i++) {
		arr[i] = binStr.charCodeAt(i);
	}

	return new Blob([ arr, ], { type, });
}
export function getBlobUrlFromBase64 (base64, applicationType) {
	if (!base64 || typeof base64 !== 'string') {
		return '';
	}

	const blob = getBlobFromBase64(base64, applicationType);

	return URL.createObjectURL(blob);
}