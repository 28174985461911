import { Storage, } from '../Storage';

/**
 * Validate StorageItemOrder constructor arguments
 *
 * @function
 * @arg {Object} data - Object with settings
 * @arg {Storage} storage - Storage instance
 * @arg {Object} $$ -
 * @returns {undefined} throw Error if any validation failed
 */
export const valid = (data, storage, $$) => {

  const msg = text => new Error(`Expect StorageItemOrder${ text }`);

  if (!(data instanceof Object)) {
    throw msg(`(data, ...) [${ data }] as Object`);
  }

  const {
    $,
    enabled, f, o, v,
    j, order,
    ..._
  } = data;

  if (!($ instanceof Object)) {
    throw msg(`.$ [${ $ }] as Object`);
  } else if (![ 'string', ].includes(typeof $.id)) {
    throw msg(`.$.id [${ $.id }] as String`);
  } else if (![ 'order', ].includes($.type)) {
    throw msg(`.$.type [${ $.type }] in {"order"}`);
  }

  if (![ 0, 1, ].includes(enabled)) {
    throw msg(`.enabled [${ enabled }] in {0, 1}`);
  }

  const re = /^(?:[_\w]+[.]){1,2}[_\w]+$/;
  // eslint-disable-next-line no-shadow
  const field = f => re.test(f);

  if ([ 'string', ].includes(typeof f)) {
    if (!field(f)) {
      throw msg(`.f [${ f }] like "schema.table.field"`);
    }
  } else {
    throw msg(`.f [${ f }] as String`);
  }

  if (![ 'order_by', ].includes(o)) {
    throw msg(`.o [${ o }] in {"order_by"}`);
  }

  if (![ 'asc', 'desc', ].includes(v)) {
    throw msg(`.v [${ v }] in {"asc", "desc"}`);
  }

  if (![ 'n', 'y', ].includes(j)) {
    throw msg(`.j [${ j }] in {"n", "y"}`);
  }

  if (![ undefined, 0, 1, ].includes(order)) {
    throw msg(`.order [${ order }] in {0, 1}`);
  }

  if (!(_ instanceof Object)) {
    throw msg(`._ [${ _ }] as Object`);
  // } else if (Object.keys(_).length) {
  //   throw msg(`._ is {}`);
  }

  if (!(storage instanceof Object)) {
    throw msg(`(..., storage) [${ storage }] as Object`);
  } else if (!(storage instanceof Storage)) {
    throw msg(`(..., storage) [${ storage }] as Storage`);
  }

  if (!($$ instanceof Object)) {
    throw msg(`(..., ..., $$) as Object`);
  } else if ($$.changed && ![ false, true, ].includes($$.changed)) {
    throw msg(`(..., ..., $$.changed) [${ $$.changed }] as {Boolean | null}`);
  } else if ($$.inited && ![ false, true, ].includes($$.inited)) {
    throw msg(`(..., ..., $$.inited) [${ $$.inited }] as {Boolean | null}`);
  }

};