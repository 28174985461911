import {
  itemsBuild,
  itemsDrain,
  itemsResort,
  CFG_ITEM_TYPE,
} from '../Config';
// import {} from '../Mentor/withDropDown';

const f_Number = {
  'drop-down': false,
  'o=': [ '=', '!=', ],
};

export default {
  id: 204,
  ...[

    // filters
    ...[ {
      f: 'contests.contests_statistic.place',
      id: 'f:cPlace',
      ...f_Number,
    }, {
      f: 'contests.contests_statistic.max_loss',
      id: 'f:cMaxLoss',
      ...f_Number,
    }, {
      f: 'contests.contests_statistic.loss',
      id: 'f:cLoss',
      ...f_Number,
    }, {
      f: [ 'core.account.surname', 'core.account.name', ],
      id: 'f:aFullname',
      o: 'like',
    }, {
      f: 'core.account.contest_nickname',
      id: 'f:aNick',
      o: 'like',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

    // orders
    ...[ {
      f: 'core.account.surname',
      id: 'o:aFullname',
      off: true, // TODO:
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

    // panels
    ...[ {
      ii: 'f:cPlace',
      t: 'magic_filter_translate_contest_member_place',
    }, {
      ii: 'f:cMaxLoss',
      t: 'magic_filter_translate_contest_member_max_loss',
    }, {
      ii: 'f:cLoss',
      t: 'magic_filter_translate_contest_member_loss',
    }, {
      ii: 'f:aFullname',
      t: 'magic_filter_translate_contest_member_account',
    }, {
      ii: 'f:aNick',
      t: 'magic_filter_translate_contest_member_nickname',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

  ]
  .map(itemsBuild)
  .reduce(itemsDrain, [])
  .reduce(itemsResort, {}),

  path: 'contests/contests-members/by-contest-id/1',
  widget: false,
};