import React from "react";
import './styles.scss';

export default function BreadcrumbText ( props ) {
    const { text, class4Text, } = props;
    return (
        <span
            className={ class4Text || `breadcrumb__text` }
        >
            { text || '?' }
        </span>
    );
}
