import { operators as allOperators, } from '../Operators';

/**
 * Validate Options constructor arguments
 *
 * @function
 * @arg {Object} settings - Object with settings
 * @arg {Function} typeToType - convert BE field type to FE field type
 * @returns {undefined} throw Error if any validation failed
 */
export const valid = (settings, typeToType) => {
  const msg = text => new Error(`Expect Options${ text }`);

  if (!(settings instanceof Object)) {
    throw msg(`(settings) as Object`);
  }

  const { data, return: be, } = settings;

  if (!(data instanceof Object)) {
    throw msg(`.data as Object`);
  }

  const re = /^(?:[_\w]+[.]){1,2}[_\w]+$/;
  // eslint-disable-next-line no-shadow
  const field = f => re.test(f);

  if (!Object.keys(data).every(field)) {
    throw msg(`.data key like "schema.table.field"`);
  } else if (!Object.values(data)
    .every(d => d instanceof Object)
  ) {
    throw msg(`.data value as Object`);
  } else if (!Object.values(data)
    .every(d => Array.isArray(d.operators))
  ) {
    throw msg(`.data[].operators as Array`);
  } else if (!Object.values(data)
    .every(d => d.operators.every(_ => [ 'string', ].includes(typeof _)))
  ) {
    throw msg(`.data[].operators as String[]`);
  } else if (!Object.values(data)
    .every(d => d.operators.every(_ => allOperators.aliases.includes(_)))
  ) {
    console.error(
      Object.entries(data)
        .filter(([ f, d, ]) => !d.operators
          .every(_ => allOperators.aliases.includes(_)),
        ),
    );
    // throw msg(`.data[].operators in allOperators.aliases`);
  // } else if (!Object.values(data)
  //   .every(d => [ 'string', ].includes(typeof d.translate))
  // ) {
  //   throw msg(`.data[].translate as String`);
  } else if (!Object.values(data)
    .every(d => [ 'string', ].includes(typeof d.type))
  ) {
    throw msg(`.data[].type as String`);
  } else if (!Object.values(data)
    .every(d => [ 'string', ].includes(typeof typeToType(d.type)))
  ) {
    throw msg(`.data[].type as TYPE`);
  }

  if (!(be instanceof Object)) {
    throw msg(`.return as Object`);
  }
};