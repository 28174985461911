import {hash as hashData,} from './CachingService';
import {DataService} from './DataService';
import {get, post, put, patch} from "../components/Magic/helpers/MagicRequest";
import {
    CALCULATE_TOTAL_AMOUNT,
    CREATE_REQUEST,
    EDIT_CWB,
    GET_BANK_BY_CURRENCY,
    GET_CARDS_BY_ACCOUNT_ID,
    GET_CLIENT_ID,
    GET_FEE_PAYER,
    GET_PAYMENT_METHODS,
    GET_PAYMENT_ORDERS,
    GET_PAYMENT_SYSTEMS_BY_TYPE,
    GET_SYSTEM_ACCOUNT,
    GLOBAL_PATH_API,
    SYSTEM_REQUEST,
    SYSTEM_ACCOUNTS_CONVERT,
    GET_SUMMARY_OF_FIN_TRANSACTIONS, GET_PAYMENT_SYSTEMS,
} from "../modules/payments/apiRoutes";

import { PAYMENT_CREATE_METHODS } from "../modules/payments/domains/ListPayments/consts/PAYMENT_CREATE_METHODS";
import { PAYMENT_TYPES } from "../modules/payments/domains/ListPayments/consts/PAYMENT_TYPES";

class PaymentDataService extends DataService {
    constructor () {
        super([
            'paymentOrders',
            'paymentOrder',
            'clientId',
            'systemAccount',
            'paymentMethods',
            'wallets',
            'bankByCurrency',
            'calcTotalAmount',
            'feePayer',
            'paymentSystems',
            'generateInvoice',
            'invalidateInvoice',
            'createInvoice',
            'editPayment',
            'createRequest',
            'editCWB',
            'createCard',
            'systemWithdraw',
            'systemDeposit',
            'createSystemAccount',
            'inOut',
        ]);
        this.cache = {};
        Object.freeze(this);
    }


    _getFromCache = (cachedObj, cachedObjKey) => {
        const noObjectInCache = !this.cache[cachedObj];

        return noObjectInCache ? false : this.cache[cachedObj][cachedObjKey];
    }

    _setInCache = (cachedObj, cachedObjKey, value) => {
        const noObjectInCache = !this.cache[cachedObj];

        if(noObjectInCache) this.cache[cachedObj] = {};

        this.cache[cachedObj][cachedObjKey] = value;
    }

    async paymentOrders ({ filterId, take, skip }) {
        const url = `${GET_PAYMENT_ORDERS}`;
        const data = await get(url, { filterId, take, skip });
        const hash = await hashData(data);
        const paymentOrders = { data, hash, options: { filterId, take, skip, }, };
        this.emit('paymentOrders', paymentOrders);

        return paymentOrders;
    }

    async paymentOrder (orderId) {
        const url = `${GET_PAYMENT_ORDERS}/${orderId}`;
        const data = await get(url);
        const hash = await hashData(data);
        const paymentOrder = { data, hash, orderId, };
        this.emit('paymentOrder', paymentOrder);

        return paymentOrder;
    }

    async clientId (client_id) {
        const url = `${GET_CLIENT_ID}/${client_id}`;
        const data = await get(url);
        const clientId = { data, };
        this.emit('clientId', clientId);

        return clientId;
    }

    async systemAccount (system_account_id) {
        const url = `${GET_SYSTEM_ACCOUNT}/${system_account_id}`;
        const data = await get(url);
        const systemAccount = { data, };
        this.emit('systemAccount', systemAccount);

        return systemAccount;
    }

    async paymentMethods (type) {
        const responseCached = this._getFromCache('paymentMethods', type);
        if(responseCached) {
            return responseCached
        }

        const url = `${GET_PAYMENT_METHODS}/${PAYMENT_TYPES[type]}`;
        const data = await get(url);
        const hash = await hashData(data);
        const paymentMethods = { data, hash, };
        this.emit('paymentMethods', paymentMethods);


        this._setInCache('paymentMethods', type, paymentMethods);
        return paymentMethods;
    }

    async wallets (clientId, paymentSystemId) {
        //TODO: Comment if confirmed no use
        const url = `${GET_CARDS_BY_ACCOUNT_ID}/${clientId}/${paymentSystemId}`;
        const data = await get(url);
        const hash = await hashData(data);
        const wallets = { data, hash, };
        this.emit('wallets', wallets);

        return wallets;
    }

    async bankByCurrency (currencyId) {
        const responseCached = this._getFromCache('bankByCurrency', currencyId);
        if(responseCached) {
            return responseCached
        }

        const url = `${GET_BANK_BY_CURRENCY}/${currencyId}`;
        const data = await get(url);
        const hash = await hashData(data);
        const bankByCurrency = { data, hash, currencyId, };
        this.emit('bankByCurrency', bankByCurrency);

        this._setInCache('bankByCurrency', currencyId, bankByCurrency);
        return bankByCurrency;
    }

    async calcTotalAmount (amount, currency, payment_system_id, type) {
        const url = `${CALCULATE_TOTAL_AMOUNT}?amount=${amount}&currency=${currency}&payment_system_id=${payment_system_id}&type=${type}`;
        const data = await get(url);
        const calcTotalAmount = { data };
        this.emit('calcTotalAmount', calcTotalAmount);

        return calcTotalAmount;
    }

    async feePayer (currency, payment_system_id, type) {
        const url = `${GET_FEE_PAYER}?currency=${currency}&payment_system_id=${payment_system_id}&type=${type}`;
        const data = await get(url);
        // const hash = await hashData(data);
        const feePayer = { data };
        this.emit('feePayer', feePayer);

        return feePayer;
    }

    async paymentSystems (id) {
        //TODO: delete or comment if confirm no use
        const url = `${GET_PAYMENT_SYSTEMS_BY_TYPE}/${id}`;
        const data = await get(url);
        const hash = await hashData(data);
        const paymentSystems = { data, hash };
        this.emit('paymentSystems', paymentSystems);

        return paymentSystems;
    }

    async paymentSystemsByTypeMethodCurrency (path) {
        const responseCached = this._getFromCache('paymentSystems', path);
        if (responseCached) {
            return responseCached;
        }

        const url = `${GET_PAYMENT_SYSTEMS}/${path}`;
        const data = await get(url);
        const hash = await hashData(data);
        const paymentSystems = {data, hash};
        this.emit('paymentSystems', paymentSystems);

        this._setInCache('paymentSystems', path, paymentSystems);

        return paymentSystems;
    }

    async generateInvoice (orderId) {
        const url = `${GET_PAYMENT_ORDERS}/${orderId}/invoice/create`;
        const data = await get(url);
        const generateInvoice = { data };
        this.emit('generateInvoice', generateInvoice);

        return generateInvoice;
    }

    async invalidateInvoice (orderId) {
        const url = `${GET_PAYMENT_ORDERS}/${orderId}/invoice/invalidate`;
        const data = await get(url);
        const invalidateInvoice = { data, orderId };
        this.emit('invalidateInvoice', invalidateInvoice);

        return invalidateInvoice;
    }

    async editPayment (id, options) { //TODO: delete after all edit request move to editPatch
        const url = `${GET_PAYMENT_ORDERS}/${id}`;
        const data = await put(url, options);
        const hash = await hashData(data);
        const editPayment = { data, hash };
        this.emit('editPayment', editPayment);

        return editPayment;
    }

    async editPaymentPatch (path, options){
        const url = `${GET_PAYMENT_ORDERS}/${path}`;
        const data = await patch(url, options);
        const hash = await hashData(data);
        const editPayment = { data, hash };
        this.emit('editPayment', editPayment);

        return editPayment;
    }

    async createRequest (obj, payment_method, payment_type) {
        const isNewRoute = PAYMENT_CREATE_METHODS[payment_method];
        const newUrl = `${CREATE_REQUEST}/${PAYMENT_TYPES[payment_type]}/${PAYMENT_CREATE_METHODS[payment_method]}`

        const url = isNewRoute ? newUrl : `${CREATE_REQUEST}`;
        const data = await post(url, obj);
        const createRequest = { data };
        this.emit('createRequest', createRequest);

        return createRequest;
    }

    async editCWB (paymenCardId, {status, name, order_id}) {
        const url = `${ EDIT_CWB }/${ paymenCardId }`;
        const data = await put(url, {status, name, order_id});
        const editCWB = { data };
        this.emit('editCWB', editCWB);

        return editCWB;
    }

    async createCard ({ account_id, name, payment_system, status }) {
        const url = `${ EDIT_CWB }`;
        const data = await post(url, { account_id, name, payment_system, status });
        const createCard = { data };
        this.emit('createCard', createCard);

        return createCard;
    }

    async systemWithdraw (systemAccountId, { amount }) {
        const url = `${SYSTEM_REQUEST}/${systemAccountId}/withdraw-orders`;
        const data = await put(url, { amount });
        const systemWithdraw = { data };
        this.emit('systemWithdraw', systemWithdraw);

        return systemWithdraw;
    }

    async systemDeposit (systemAccountId, { amount }) {
        const url = `${SYSTEM_REQUEST}/${systemAccountId}/deposit-orders`;
        const data = await put(url, { amount });
        const systemDeposit = { data };
        this.emit('systemDeposit', systemDeposit);

        return systemDeposit;
    }

    async createSystemAccount ({ account_id, acc_ccy, type }) {
        const url = SYSTEM_REQUEST;
        const data = await post(url, { account_id, acc_ccy, type });
        const createSystemAccount = { data };
        this.emit('createSystemAccount', createSystemAccount);

        return createSystemAccount;
    }

	async convertBetweenSystemAccounts (systemAccountId, data) {
    	const url = `${SYSTEM_ACCOUNTS_CONVERT}/${systemAccountId}`;
    	const convertBetweenSystemAccounts = await post(url, data);
    	this.emit('convertBetweenSystemAccounts', convertBetweenSystemAccounts);

    	return convertBetweenSystemAccounts;
	}

    async inOut (id){
        const responseCached = this._getFromCache('inOut', id);
        if (responseCached) {
            return responseCached;
        }

        const url = `${GET_PAYMENT_ORDERS}/${id}/in-out-statistic`;
        const data = await get(url);
        const hash = await hashData(data);
        const inOut = { data, hash };
        this.emit('inOut', inOut);


        this._setInCache('inOut', id, inOut);
        return inOut;
    }

    async getCardsByAccountAndOrderId  (account_id, payment_system_id) {
        const url = new URL(`${EDIT_CWB}/get-by-account_id/${account_id}/${payment_system_id}`, location.href);
        return await get(url);
    }

    async getCardsByAccountAndOrderId  (account_id, payment_system_id) {
        const url = new URL(`${EDIT_CWB}/get-by-account_id/${account_id}/${payment_system_id}`, location.href);
        return await get(url);
    }
    async getSummaryOfFinTransactions (obj) {
        return await get(GET_SUMMARY_OF_FIN_TRANSACTIONS, obj);
    }
    //TODO: hidden requests
    /*downloadInvoice = async (id) => await get(`${GET_PAYMENT_ORDERS}/${id}/invoice`);
    getInvoice = async (orderId) => await get(`${GET_PAYMENT_ORDERS}/${orderId}/invoice`);
    getAllCountries = async () => await get(`${GLOBAL_PATH_API}/core/country/with-account/drop-down`);*/
}

export const paymentService = new PaymentDataService();
