import React from "react";
import * as PropTypes from "prop-types";
import { MagicSelect } from "../MagicSelect";

export class MagicMultiSelect extends React.PureComponent {
  static defaultProps = {
    SelectProps: {},
    className: [],
    classSelected: [],
    disabled: false,
    valueDefault: false
  };

  static propTypes = {
    SelectProps: PropTypes.object.isRequired,
    className: MagicSelect.classNamePropTypes.isRequired,
    classSelected: MagicSelect.classNamePropTypes.isRequired,
    disabled: MagicSelect.disabledPropTypes.isRequired,
    selected: MagicSelect.valuesItemPropTypes,
    selectedValues: MagicSelect.valuesPropTypes,
    valueDefault: MagicSelect.valuePropTypes.isRequired,
    values: MagicSelect.valuesPropTypes.isRequired,
    label: PropTypes.func,
    selectedToLabel: PropTypes.func,
    valueToLabel: PropTypes.func,
    ItemProps: PropTypes.func,
    SelectedProps: PropTypes.func,
    onClick: PropTypes.func,
    onSelect: PropTypes.func.isRequired,
    onUnSelect: PropTypes.func.isRequired
  };

  async onClick(value) {
    const { disabled, onClick } = this.props;
    !disabled && onClick && (await onClick(value));
  }

  async onSelect(value) {
    const { onSelect } = this.props;
    onSelect && (await onSelect(value));
  }

  async onUnSelect(value) {
    const { disabled, onUnSelect } = this.props;
    !disabled && onUnSelect && (await onUnSelect(value));
  }

  label(value) {
    const { SelectProps, label } = this.props;
    if (label instanceof Function) {
      return label(value);
    } else if (SelectProps.label instanceof Function) {
      return SelectProps.label(value);
    }
    return this.valueToLabel(value);
  }

  selectedToLabel(value) {
    const { selectedToLabel } = this.props;
    if (selectedToLabel instanceof Function) {
      return selectedToLabel(value);
    }
    return this.valueToLabel(value);
  }

  valueToLabel(value) {
    const { SelectProps, valueToLabel } = this.props;
    if (valueToLabel instanceof Function) {
      return valueToLabel(value);
    } else if (SelectProps.valueToLabel instanceof Function) {
      return SelectProps.valueToLabel(value);
    }
    return value;
  }

  renderSelect() {
    const { SelectProps } = this.props;
    const { disabled, valueDefault, values } = this.props;
    const { ItemProps } = this.props;
    const options = {
      ...SelectProps,
      ...(disabled ? { disabled } : {}),
      label: this.label.bind(this),
      value: valueDefault,
      valueDefault,
      values: [...new Set(values)],
      valueToLabel: this.valueToLabel.bind(this),
      ...(ItemProps instanceof Function ? { ItemProps } : {}),
      onChange: this.onSelect.bind(this)
    };
    return <MagicSelect {...options} />;
  }

  renderSelectedItemX(value) {
    const { disabled, selected } = this.props;
    const options = {
      className: [
        "magic-multi-select__item_remove",
        ...(disabled ? ["magic-multi-select__item_remove_disabled"] : []),
        ...(selected === value ? ["magic-multi-select__item_remove_selected"] : [])
      ].join(" "),
      onClick: (event) => {
        event.preventDefault();
        this.onUnSelect(value);
      },
    };
    return <span {...options} />;
  }

  renderSelectedItem(value) {
    const { disabled, selected } = this.props;
    const { SelectedProps } = this.props;
    const label = this.selectedToLabel(value);
    let selectedPropsObject = {};
    if (SelectedProps instanceof Function) {
      selectedPropsObject = SelectedProps(value) || {};
    } else if (SelectedProps instanceof Object) {
      selectedPropsObject = { ...SelectedProps, };
    }
    const {
      unselectable = true,
      className: classSelected,
      ...selectedProps
    } = selectedPropsObject;
    const classNames = classSelected instanceof Array ? classSelected : [ classSelected ];
    const key = value;
    const onClick = async (event) => {
      event.preventDefault();
      await this.onClick(value);
    };
    const options = {
      ...selectedProps,
      className: [
        "magic-multi-select__item",
        ...(disabled ? ["magic-multi-select__item_disabled"] : []),
        ...(selected === value ? ["magic-multi-select__item_selected"] : []),
        ...classNames,
      ].join(" "),
      key,
      value,
      onClick,
    };
    const unselect = unselectable === false ? '' : this.renderSelectedItemX( value );
    return <div {...options} >{ label }{ unselect }</div>;
  }

  renderSelected() {
    const { classSelected, selectedValues: values } = this.props;
		const classNames = classSelected instanceof Array ? classSelected : [ classSelected ];
    const options = {
      children: values.map(this.renderSelectedItem.bind(this)),
      className: ["magic-multi-select__list", ...classNames].join(" ")
    };
    return <div {...options} />;
  }

  render() {
    const { className } = this.props;
    const classNames = className instanceof Array ? className : [className];
    const select = this.renderSelect();
    const selected = this.renderSelected();
    const options = {
      className: ["magic-multi-select", ...classNames].join(" ")
    };
    return (
      <div {...options} key={Math.random()}>
        {select}
        {selected}
      </div>
    );
  }
}
