import React, { Component, } from "react";
import { withLocalize, } from "react-localize-redux";
import { Input, Select, } from "../../../../../components";

class Header extends Component {
	// eslint-disable-next-line no-useless-constructor
	constructor (props) {
		super(props);
	}

	render () {
		const {
			translate,
			fieldsIsNotEdit,
			showPopUp,
			formData,
			statusIsNotEdit,
			handleOnChange,
			payoutRateListStatusInDB,
			loadData,
			isEditMode,
			setErrorClass,
			statusList,
			conditionTypes,
			payoutRateListStatus,
			filterValues,
			errors,
		} = this.props;

		const disabledName =
			formData.name.length > 20 && fieldsIsNotEdit
				? `${ formData.name.slice(0, 20) }...`
				: formData.name;

		return (
			<>
				<div className="payout-rate-list__popup mb-20">
					<div className="w-80 mr-35 name_tooltip">
						<Input
							disabled={ !loadData || fieldsIsNotEdit || showPopUp }
							isRequired={ true }
							label={ translate("symbols_name") }
							name="name"
							placeholder={ translate("symbols_name_placeholder") }
							value={ `${ disabledName }` }
							wrapperClassName={ `${ setErrorClass(errors, "name") }` }
							onChange={ handleOnChange }
							onValid={ (value) => {
								// eslint-disable-next-line no-useless-escape
								const regExp = /^[a-zA-Z0-9а-яА-ЯіІїЇ\s\.\,|/\-\!\@\#\$\&\%\^\\*\(\)]{0,100}$/g;

								if (value !== "" && !regExp.test(value)) {
									throw new Error("xxx");
								}
							} }
						/>
						{fieldsIsNotEdit ? (
							<span className="name-switch-block_tooltip">{formData.name}</span>
						) : null}
					</div>
					<div className="w-40 mr-35">
						<Select
							className={ setErrorClass(errors, "status") }
							defaultLabel="symbols_create_status_placeholder"
							disabled={ !loadData || statusIsNotEdit || showPopUp }
							isRequired={ true }
							label={ translate("symbols_status") }
							name="status"
							options={
								payoutRateListStatusInDB.active
									? filterValues(statusList, [ payoutRateListStatus.NEW, ])
									: statusList
							}
							value={ formData.status }
							onChange={ handleOnChange }
						/>
					</div>
					<div className="w-40 mr-35">
						<Select
							className={ setErrorClass(errors, "type") }
							disabled={ !loadData || isEditMode || showPopUp }
							isRequired={ true }
							label={ translate("partners_payout_type_of_condition") }
							name="type"
							options={ conditionTypes }
							placeholder={ translate("partners_payout_rate_list_choose_type") }
							value={ formData.type }
							onChange={ handleOnChange }
						/>
					</div>
					<div className="w-40">
						<Input
							disabled={ true }
							label={ translate("partners_payout_rate_list_groups_count") }
							name="count_groups"
							value={ `${ formData.country_groups.length }` }
							wrapperClassName={ setErrorClass(errors, "count_groups") }
							onChange={ handleOnChange }
						/>
					</div>
				</div>
				<div className="payout-rate-list__underline mt-20" />
			</>
		);
	}
}

export default withLocalize(Header);