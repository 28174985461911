import * as actions from './actionTypes';

const initialState = {
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.SET_ENUMS:
            return {
                ...state,
                'enums': action.data,
            };
        default:
            return state;
    }
}
