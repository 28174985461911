import {securedRequest} from "../../../services/RequestService";

import {GET_LANGUAGES_DROP_DOWN} from "../apiRoutes";

export const getLanguages = () => {
    return securedRequest.get(GET_LANGUAGES_DROP_DOWN)
        .then(
            (response) => Promise.resolve(response.data),
            (error) => Promise.reject(error.response.data)
        );
}

