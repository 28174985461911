import React, { Component, } from "react";
import { withRouter, Link, } from "react-router-dom";
import { withLocalize, } from "react-localize-redux";
import PropTypes from "prop-types";

import {
	getPartnersParters,
	getPromotionalCampaignsData,
} from "../../../../../../../../../services/PartnersRequestService";

import { MagicTooltip, } from "../../../../../../../../tima/components/Magic/MagicTooltip";
import { MagicTable, Mentor, showExportToExcelError, } from "../../../../../../../../../components/Magic";

import Preloader from "../../../../../../../../../components/LoadingHOC/Preloader";
import PagePanel from "../../../../../../../../tima/components/Pages";
class PromotioalCampaignsTable extends Component {
    static propTypes = {
    	accountId: PropTypes.number.isRequired,
    	enums: PropTypes.object,
    	translate: PropTypes.func,
    };

    static defaultProps = {
    	enums: {},
    };

    constructor (props) {
    	super(props);

    	this.state = {
    		data: [],
    		filterLastChange: Date.now(),
    		isEmptyData: false,
    		loaded: false,
    		meta: {},
    		pages: {
    			filter: 0,
    			take: 50,
    			total: 0,
    			variance: 2,
    		},
    	};

    	const mf = new Mentor({
    		owner: this,
    		serviceId: 181,
    		translate: this.props.translate,
    	});

    	Object.defineProperty(this, "mf", { get: () => mf, });
    }

    get enums () {
    	return this.props?.enums ?? {};
    }

    async componentDidMount () {
    	this.mf.subscribe(
    		{
    			changed: this.mfChanged,
    			cleaned: this.mfDone,
    			cleaning: this.mfDoing,
    			loaded: this.mfDone,
    			loading: this.mfDoing,
    			saved: this.mfDone,
    			saving: this.mfDoing,
    		},
    		this,
    	);

    	await this.mf.init({ doEmit: true, });
    	// try {
    	// 	const { accountId, } = this.props;
    	// 	const response = await getPromotionalCampaignsData(accountId);
		//
    	// 	await this.save({ data: response.data, });
    	// 	await this.toggleLoaded(true);
    	// } catch (error) {
    	// 	await this.toggleLoaded(true);
    	// 	console.log(error);
    	// 	error.showErrorNotification?.();
    	// }
    }

	componentWillUnmount = () => {
		this.mf.unsubscribe([ this.mfChanged, this.mfDoing, this.mfDone, ], this);
	};

	save = state => new Promise(next => this.setState(state, next));
	query = () => new URLSearchParams(this.props.location.search);

	onChange = (name, value) => this.save({ [name]: value, });

	params = () => this.props.match.params;

	mfChanged = async () => {
		await this.save({ filterLastChange: Date.now(), });
	};

	mfDoing = async () => {
		await this.save({ loaded: false, });
	};

	mfDone = async () => {
		await this.mfChanged();
		await this.onDataLoad();
	};

	onDataLoad = async (pageId) => {
		await this.save({ isEmptyData: false, loaded: false, });

		const pageOptions = this.pageIdAsGET(pageId);
		const loaded = (data, meta) => ({ pages, }) => ({
			data,
			pages: { ...pages, ...meta, },
			...!data.length && { isEmptyData: true, },
		});

		try {
			const response = await getPromotionalCampaignsData(this.props.accountId, pageOptions);
			const {
				data = [],
				meta: { filter = 0, total = 0, },
			} = response;

			await this.save(loaded(data, { filter, total, }));
		} catch (error) {
			await this.save(loaded([], { filter: 0, total: 0, }));
			showExportToExcelError(error);
		}
		await this.save({ loaded: true, });
	};

	get config () {
		const { translate, } = this.props;
		const prefix = "promotional_campaign_";

		return [
			{
				key: "promoCampaignCampaign",
				path: [ "partners", "referral_code", "company_name", ],
				orderId: 'o:promoCampaignCampaign',
				render: this.renderInlineValue,
				title: `${ prefix }campaign`,
			},
			{
				key: "promoCampaignCreatedAt",
				path: [ "partners", "referral_code", "created_at", ],
				orderId: 'o:promoCampaignCreatedAt',
				render: this.renderDate,
				title: `${ prefix }created_at`,
			},
			{
				key: "promoCampaignIdentifiers",
				path: [ "partners", "identificators", ],
				order: false,
				render: this.renderIdentificators,
				title: `${ prefix }identifiers`,
			},
			{
				key: "promoCampaignReferralCode",
				path: [ "partners", "referral_code", "ref_short", ],
				orderId: 'o:promoCampaignReferralCode',
				render: this.renderInlineValue,
				title: `${ prefix }ref_code`,
			},
		].map(({
				   orderId: o, title: t, xtitle: x, ...item
			   }) => ({
			...item,
			...o && {
				order: async () => {
					await this.mf.orderedToNext(o);
				},
				orderTo: this.mf.orderedTo(o),
			},
			...t && { title: translate(t), },
			...x && { title: x, },
		}));
	}

    renderInlineValue = (value, { item, }) => {
    	if (item.access("index")) {
    		return value ?? "-";
    	}

    	return "-";
    };

    renderDate = (value, { item, }) => {
        //needed to render date and time in separate lines
    	if (item.access("index")) {
    		if (!value) {
    			return "-";
    		}

    		return value.split(" ").map(index => (
				//eslint-disable-next-line
				<div className="table__date" key={ index }>
					{index}
    			</div>
    		));
    	}

    	return "-";
    };

    renderIdentificators = (value, { item, }) => {
    	if (item.access("index")) {
    		return value === null ? "-" :
				//eslint-disable-next-line
				<MagicTooltip
					classNameTooltip={ `tooltip tooltip--large-width` }
					content={ value.map(identificator => identificator.name).join(", ") }
					lengthCut={ 20 }
				/>;
    	}

    	return "-";
    };
    renderPromotionalCampaignsMagicTable () {
    	const {
    		loaded, data,
    	} = this.state;

    	const { translate, } = this.props;

    	const head = [
    		"promoCampaignCampaign",
    		"promoCampaignCreatedAt",
    		"promoCampaignIdentifiers",
    		"promoCampaignReferralCode",
    	];

    	if (!loaded) {
    		return <Preloader />;
    	}
    	if (data.length === 0) {
    		return <p className="mx-auto">{translate("symbols_empty")}</p>;
    	}

    	const options = {
    		config: this.config,
    		data,
    		head,
    	};

    	return (
			//eslint-disable-next-line
			<>
				<MagicTable { ...options } />
    		</>
    	);
    }
    render () {
    	const { pages, } = this.state;

    	return (
			//eslint-disable-next-line
				<div className="partners-partners__table">
					<div className="partners__table">
	{/*eslint-disable-next-line*/}
						{this.renderPromotionalCampaignsMagicTable()}
    			</div>
					{/*eslint-disable-next-line*/}
					<PagePanel
						filter={ pages.filter }
						page={ this.pageId() }
						take={ pages.take }
						total={ pages.total }
						variance={ pages.variance }
						onClick={ this.onPageClick }
						doTo={ pageId => `?page=${ pageId }` }
					/>
				</div>
    	);
    }

	onPageClick = async ({ event, pageId, pageIs, }) => {
		await this.onDataLoad(pageId);
	};
	pageId = () => {
		const page = this.query().get("page");

		return Number(page) || 0;
	};

	pageIdAsGET = (pageId) => {
		const pages = this.state.pages;

		// eslint-disable-next-line no-param-reassign
		pageId = pageId === undefined ? this.pageId() : pageId;
		const { filterId, } = this.mf;
		const result = {
			filterId,
			skip: pageId * pages.take,
			take: pages.take,
		};

		return result;
	};
}

export default withRouter(withLocalize(PromotioalCampaignsTable));