import {
	itemsBuild, itemsDrain, itemsResort, CFG_ITEM_TYPE,
} from "../Config";
import { onEnabled, render, } from "../Mentor/withDropDown";

const f_lang = {
	"drop-down": "/api/core/language/drop-down",
	"o=": [ "=", "!=", "null", "not_null", ],
};

const p_lang = {
	onEnabled: onEnabled("bannerLanguage"),
	render: render("bannerLanguage"),
};

const f_target_page = {
	"drop-down": "/api/partners/target-pages/drop-down",
	"o=": [ "=", "!=", "null", "not_null", ],
}

const p_target_page = {
	onEnabled: onEnabled("targetPage"),
	render: render("targetPage"),
};
export default {
	id: 165,
	...[
		// filters
		...[
			{
				f: "partners.promo.id",
				id: "f:bannerId",
			},
			{
				f: "partners.promo.name",
				id: "f:bannerName",
			},
			{
				f: "partners.promo.size",
				id: "f:bannerSize",
			},
			{
				f: "core.language.id",
				id: "f:bannerLanguage",
				...f_lang,
			},
			{
				f: "partners.promo.target_page_id",
				id: "f:bannerTargetPage",
				...f_target_page,
			},
			{
				f: "partners.promo.status",
				id: "f:bannerStatus",
			},
			{
				f: "partners.promo.created_at",
				id: "f:bannerApplicationDate",
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: "filter", ..._, })),

		// orders
		...[
			{
				f: "partners.promo.id",
				id: "o:bannerId",
			},
			{
				f: "partners.promo.name",
				id: "o:bannerName",
			},
			{
				f: "partners.promo.size",
				id: "o:bannerSize",
			},
			{
				f: "core.language.id",
				id: "o:bannerLanguage",
			},
			{
				f: "partners.target_pages.name",
				id: "o:bannerTargetPage",
			},
			{
				f: "partners.promo.status",
				id: "o:bannerStatus",
			},
			{
				f: "partners.promo.created_at",
				id: "o:bannerApplicationDate",
			},

		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: "order", ..._, })),

		// panels
		...[
			{
				t: "partners_banners_id",
				ii: "f:bannerId",
			},
			{
				t: "partners_banners_name",
				ii: "f:bannerName",
			},
			{
				t: "partners_banners_size",
				ii: "f:bannerSize",
			},
			{
				t: "partners_banners_language",
				ii: "f:bannerLanguage",
				...p_lang,
			},
			{
				t: "partners_banners_target_page",
				ii: "f:bannerTargetPage",
				...p_target_page,
			},
			{
				t: "partners_banners_status",
				ii: "f:bannerStatus",
			},
			{
				t: "partners_banners_created_at",
				ii: "f:bannerApplicationDate",
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: "panel", ..._, })),
	]
		.map(itemsBuild)
		.reduce(itemsDrain, [])
		.reduce(itemsResort, {}),

	path: "partners/promo",
	widget: false,
};