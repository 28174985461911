/* eslint-disable no-unused-vars , array-callback-return ,  sort-keys, max-len, no-shadow  */
import React, { Component, } from "react";
import { withLocalize, } from "react-localize-redux";
import { withRouter, } from "react-router-dom";
import Moment from "moment";
import {
	getPayoutRateLists,
	deleteCountryPayoutRateList,
	getCountryPayoutRateList,
} from "../../../../../../services/PartnersRequestService";
import {
	MagicFilterPanels,
	Mentor,
	MagicTable,
	MagicButton,
} from "../../../../../../components/Magic";
import Preloader from "../../../../../../components/LoadingHOC/Preloader";
import Loader from "../../../../../tima/components/Loader";
import PagePanel from "../../../../../tima/components/Pages";
import { MagicTooltip, } from "../../../../../tima/components/Magic/MagicTooltip";
import { MagicConfirm, } from "../../../../../tima/components/Magic/MagicConfirm";
import CreateUpdatePayoutRateList from "../Popup/CreateUpdatePayoutRateList";

const payoutStatus = Object.freeze({
	CLOSED: 0,
	NEW: 1,
	// eslint-disable-next-line sort-keys
	ACTIVE: 2,
});

class CountryListWithPayoutRatesMagicTable extends Component {
	constructor (props) {
		super(props);

		this.state = {
			data: [],
			filterLastChange: Date.now(),
			isEditMode: false,
			isEmptyData: false,
			loaded: false,
			pages: {
				filter: 0,
				take: 50,
				total: 0,
				variance: 2,
			},
			payoutListId: null,
			showPopUp: false,
		};

		const mf = new Mentor({
			owner: this,
			serviceId: 180,
			translate: this.props.translate,
		});

		Object.defineProperty(this, "mf", { get: () => mf, });
	}

	async componentDidMount () {
		this.mf.subscribe(
			{
				changed: this.mfChanged,
				cleaned: this.mfDone,
				cleaning: this.mfDoing,
				loaded: this.mfDone,
				loading: this.mfDoing,
				saved: this.mfDone,
				saving: this.mfDoing,
			},
			this,
		);

		await this.mf.init({ doEmit: true, });
	}

	UNSAFE_componentWillUnmount () {
		this.mf.unsubscribe([ this.mfChanged, this.mfDoing, this.mfDone, ], this);
	}

	save = state => new Promise(next => this.setState(state, next));

	onChange = (name, value) => this.save({ [name]: value, });

	params = () => this.props.match.params;

	query = () => new URLSearchParams(this.props.location.search);

	mfChanged = async () => {
		await this.save({ filterLastChange: Date.now(), });
	};

	mfDoing = async () => {
		await this.save({ loaded: false, });
	};

	mfDone = async () => {
		await this.mfChanged();
		await this.onDataLoad();
	};

	enumsChange = async (enums) => {
		if (enums.hash === this.state?.enums?.hash) {
			return `${ this.constructor.name }.enumsChange: false`;
		}
		await this.save({ enums, });

		return `${ this.constructor.name }.enumsChange: true`;
	};

	get payoutRateListTableConfig () {
		const { translate, } = this.props;
		const prefix = "partners_payout_";

		return [
			{
				key: "payoutId",
				orderId: "o:id",
				path: [ "partners", "payout_rate_lists", "id", ],
				render: this.renderInitialValue,
				title: `${ prefix }id`,
			},
			{
				key: "payoutName",
				orderId: "o:name",
				path: [ "partners", "payout_rate_lists", "name", ],
				render: this.renderPayoutRateListName,
				title: `${ prefix }name`,
			},
			{
				key: "payoutCountGroup",
				orderId: "o:countGroups",
				path: [ "partners", "payout_rate_lists", "count_groups", ],
				render: this.renderInitialValue,
				title: `${ prefix }groups_count`,
			},
			{
				key: "payoutType",
				orderId: "o:type",
				path: [ "partners", "payout_rate_lists", "type", ],
				render: this.renderPayoutType,
				title: `${ prefix }type`,
			},
			{
				key: "payoutStatus",
				orderId: "o:status",
				path: [ "partners", "payout_rate_lists", "status", ],
				render: this.renderPayoutStatus,
				title: `${ prefix }status`,
			},
			{
				key: "payoutCreatedAt",
				orderId: "o:created",
				path: [ "partners", "payout_rate_lists", "created_at", ],
				render: this.renderInitialValue,
				title: `${ prefix }created_at`,
			},
			{
				key: "payoutUpdatedAt",
				orderId: "o:updated",
				path: [ "partners", "payout_rate_lists", "updated_at", ],
				render: this.renderDate,
				title: `${ prefix }updated_at`,
			},
			{
				customAccessCheck: ({ item, }) => {
					return (
						item.access("show") &&
						(item.access("update") || item.access("destroy"))
					);
				},
				key: "payoutActions",
				path: [ "partners", "payout_rate_lists", "id", ],
				render: this.renderActions,
				title: `${ prefix }actions`,
			},
		].map(({
			orderId: o, title: t, xtitle: x, ...item
		}) => ({
			...item,
			...o && {
				order: async () => {
					await this.mf?.orderedToNext(o);
				},
				orderTo: this.mf?.orderedTo(o),
			},
			...t && { title: translate(t), },
			...x && { title: x, },
		}));
	}

	removePayoutList = async (payoutRateListId = this.state.payoutRateListId) => {
		if (!payoutRateListId) {
			console.warn("payout rate list id not found");

			return;
		}

		try {
			await deleteCountryPayoutRateList(payoutRateListId);
			await this.onDataLoad();
		} catch (error) {
			error?.showErrorNotification?.();
		}
	};

	editCountryPayoutRateList = async (id) => {
		if (!id) {
			return console.warn("payout rate list id not found");
		}
		try {
			const { popup, } = this.props;
			const rawData = await getCountryPayoutRateList(id);
			const dataForEdit = this.prepareDataForEdit(rawData?.data?.partners);

			return popup.togglePopup(dataForEdit);
		} catch (error) {
			error?.showErrorNotification?.();
		}
	};

	setCountries = (id, array) => {
		const result = array.filter((item) => {
			return item.country_group_id === id;
		});

		return result;
	};

	prepareDataForEdit (rawData) {
		try {
			const {
				name, type, status, id,
			} = rawData.payout_rate_lists;

			const countries = rawData?.country_to_group;

			const payoutRangesSumFrom =
				type === 1 &&
				rawData?.payout_rates_ranges
					.sort((a, b) => a.id - b.id)
					.map(({ sum_from, }) => sum_from);

			const country_groups = [];

			if (type === 2) {
				rawData?.country_group.map((countryGroup) => {
					const filteredCountries = this.setCountries(
						countryGroup.id,
						countries,
					);
					const forbiddenGroup = countryGroup.is_forbidden === "true" ? 1 : 0;

					return (
						!filteredCountries.find(({ id, }) => {
							id === countryGroup.id;
						}) &&
						country_groups.push({
							group: filteredCountries.map(item => item.country_id),
							is_forbidden: forbiddenGroup,
							name: countryGroup.name,
							id: countryGroup.id,
							rate: countryGroup.payout_rate,
						})
					);
				});
			} else if (type === 1) {
				// eslint-disable-next-line max-len
				rawData?.country_group.sort((a, b) => a.id - b.id).map((countryGroup) => {
					const filteredCountries = this.setCountries(
						countryGroup.id,
						countries,
					);

					const payoutRanges = rawData?.country_group_to_range
						.sort((a, b) => a.payout_rates_range_id - b.payout_rates_range_id)
						.filter(item => item.country_group_id === countryGroup.id)
						.map(({ amount, }, index) => amount);
					const forbiddenGroup = countryGroup.is_forbidden === "true" ? 1 : 0;

					return (
						!filteredCountries.find(({ id, }) => {
							id === countryGroup.id;
						}) &&
						country_groups.push({
							group: filteredCountries.map(item => item.country_id),
							is_forbidden: forbiddenGroup,
							name: countryGroup.name,
							id: countryGroup.id,
							payoutRanges,
						})
					);
				});
			}

			return {
				country_groups,
				payoutRangesSumFrom,
				name,
				type,
				status,
				id,
			};
		} catch (e) {
			console.log(e);
		}
	}

	renderPayoutRateListName = (name, { item, data, }) => {
		const {
			partners: { payout_rate_lists, },
		} = data;
		const payoutRateListsId = payout_rate_lists.id;

		// this.editPayoutList()
		if (item?.access?.("index")) {
			return (
				<div
					className="cursor-pointer"
					onClick={ async () => {
						await this.save({
							showPopUp: true,
						});
						await this.editCountryPayoutRateList(payoutRateListsId);
					} }
				>
					<MagicTooltip
						classNameTooltip="tooltip tooltip--large-width "
						content={ name ?? "-" }
						lengthCut={ 20 }
					/>
				</div>
			);
		}

		return "-";
	};

	renderInitialValue = (value, { item, }) => {
		if (item?.access?.("index")) {
			return (
				<MagicTooltip
					classNameTooltip="tooltip tooltip--large-width"
					content={ value ?? "-" }
					lengthCut={ 20 }
				/>
			);
		}

		return "-";
	};

	renderDate = (date, { item, }) => {
		if (item?.access?.("index")) {
			const formatDB = "YYYY-MM-DD HH:mm:ss";
			const formatOut = "YYYY-MM-DD HH:mm:ss";

			// eslint-disable-next-line new-cap
			const formatDate = Moment(date, formatDB);

			return formatDate.isValid() ? formatDate.format(formatOut) : "-";
		}

		return "-";
	};

	renderPayoutType = (type, { item, }) => {
		if (item.access("index")) {
			return (
				this.props.enums.data?.partners?.PayoutRateListTypeEnum?.[+type] ?? "-"
			);
		}

		return "-";
	};

	renderPayoutStatus = (status, { item, }) => {
		if (item.access("index")) {
			return (
				this.props.enums.data?.partners?.PayoutRateListStatusEnum?.[+status] ??
				"-"
			);
		}

		return "-";
	};

	renderActions = (value, { item, data, }) => {
		const { translate, popup, } = this.props;
		const { payoutListId, } = this.state;

		const renderRemoveButton = () => {
			const options = {
				children: translate("symbols_actions_delete"),
				className:
					"magic-button__item magic-button__item_partners-delete-symbol margin--top-10",
				onClick: () => this.onChange("payoutListId", value),
			};

			return (
				<div className="magic-confirm-wrapper">
					<MagicConfirm
						accept={ translate("symbols_remove_accept") }
						closeByClickOutside={ true }
						isVisible={ Number(payoutListId) === Number(value) }
						reject={ translate("symbols_remove_reject") }
						title={ translate(`magic_confirm_title`) }
						onAccept={ () => this.removePayoutList(payoutListId) }
						onReject={ () => this.onChange("payoutListId", null) }
					/>
					<MagicButton { ...options } />
				</div>
			);
		};

		const renderEditButton = () => {
			const {
				partners: { payout_rate_lists, },
			} = data;

			const payoutRateListsId = payout_rate_lists.id;

			const options = {
				children: translate("symbols_actions_edit"),
				className: "magic-button__item magic-button__item_partners-edit-symbol",
				onClick: async () => {
					await this.editCountryPayoutRateList(payoutRateListsId);
				},
			};

			return (
				<div className="magic-confirm-wrapper">
					<MagicButton { ...options } />
				</div>
			);
		};

		return (
			<>
				{item.access("update") && renderEditButton()}
				{item.access("destroy") && renderRemoveButton()}
			</>
		);
	};

	renderTable = () => {
		const {
			loaded, pages, data, isEmptyData,
		} = this.state;
		const { translate, } = this.props;

		const options = {
			RowProps: ({ items, }) => {
				return {
					"data-disabled": [ payoutStatus.CLOSED, ].includes(
						items?.payoutStatus?.valueOf,
					),
				};
			},
			config: this.payoutRateListTableConfig,
			data,
			head: [
				"payoutId",
				"payoutName",
				"payoutCountGroup",
				"payoutType",
				"payoutStatus",
				"payoutCreatedAt",
				"payoutUpdatedAt",
				"payoutActions",
			],
		};

		return (
			<>
				{/*eslint-disable-next-line max-len*/}
				<MagicFilterPanels mf={ this.mf } show={ true } translate={ translate } />
				<div
					className={ `table-wrapper partners__table ${
						isEmptyData ? "partners__table--empty" : ""
					}` }
				>
					<Loader loaded={ loaded } loading={ <Preloader scale={ 1 } /> }>
						<MagicTable { ...options } />
						{isEmptyData && (
							<div className="table__empty-text">
								{translate("symbols_empty")}
							</div>
						)}
						<PagePanel
							doText={ this.onPageText }
							doTo={ pageId => `?page=${ pageId }` }
							filter={ pages.filter }
							page={ this.pageId() }
							take={ pages.take }
							total={ pages.total }
							variance={ pages.variance }
							onClick={ this.onPageClick }
						/>
					</Loader>
				</div>
			</>
		);
	};

	render () {
		const { enums, popup, } = this.props;
		const { showPopUp, } = this.state;

		return (
			<>
				{popup.isOpen && (
					<CreateUpdatePayoutRateList
						enums={ enums }
						formData={ popup.data }
						showPopUp={ showPopUp }
						onClose={ async () => {
							await this.save({ showPopUp: false, });
							await popup.togglePopup();
						} }
						onDataLoad={ this.onDataLoad }
					/>
				)}
				{this.renderTable()}
			</>
		);
	}

	pageId = () => {
		const page = this.query().get("page");

		return Number(page) || 0;
	};

	pageIdAsGET = (pageId) => {
		// eslint-disable-next-line no-param-reassign
		pageId = pageId === undefined ? this.pageId() : pageId;
		const { filterId, } = this.mf;

		return {
			filterId,
			skip: pageId * this.state.pages.take,
			take: this.state.pages.take,
		};
	};

	onDataLoad = async (pageId) => {
		await this.save({
			isEmptyData: false,
			loaded: false,
		});

		const pageOptions = this.pageIdAsGET(pageId);
		const loaded = (data, meta) => ({ pages, }) => ({
			data,
			pages: { ...pages, ...meta, },
			...!data.length && { isEmptyData: true, },
		});

		try {
			const response = await getPayoutRateLists(pageOptions);
			const {
				data = [],
				meta: { filter = 0, total = 0, },
			} = response;

			await this.save(
				loaded(data, {
					filter,
					total,
				}),
			);
		} catch (error) {
			await this.save(
				loaded([], {
					filter: 0,
					total: 0,
				}),
			);
			error?.showErrorNotification?.();
		}
		await this.save({ loaded: true, });
	};

	onPageClick = ({ pageId, }) => {
		this.onDataLoad(pageId);
	};

	onPageText = (pageId, pageIs) => {
		const { translate, } = this.props;
		const {
			current, first, prev, next, last,
		} = pageIs;
		const {
			skipped, taken, filter, total,
		} = pageIs;

		if (skipped || taken) {
			const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : "?";
			const text = skipped ? translate("partners_pages_items") : "";

			return skipped ? `${ text }: ${ id }` : id;
		} else if (filter || total) {
			const id = Number.isInteger(pageId) ? `${ pageId }` : "?";
			const text = translate(
				filter ? "partners_pages_filtered" : "partners_pages_total",
			);

			return `${ text }: ${ id }`;
		} else if (first || prev || next || last) {
			return "";
		} else if (current) {
			return `${ pageId + 1 }`;
		}

		return `${ pageId + 1 }`;
	};
}

export default withRouter(withLocalize(CountryListWithPayoutRatesMagicTable));