import React from "react";
import { isEmpty } from 'lodash';
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import { Fade } from "reactstrap";
import ReactPaginate from 'react-paginate';
import { securedRequest } from "../../services/RequestService";
import NotificationService from "../../services/NotificationService";
import HistoryChangesComponentTable from "./HistoryChangesComponentTable";

import "../../style/_react-paginate.scss";
import "../../style/_history-changes-component.scss";

class HistoryChangesComponent extends React.Component {

    static propTypes = {
        url: PropTypes.string.isRequired,
        closeFunction: PropTypes.func.isRequired,
    };

    constructor (props) {
        super(props);

        this.state = {
            showCount: 5,
            data: false,
            paginateData: false,
        };
    }

    getData = (from, to) => {
        this.setState({
            paginateData: Object.entries(this.state.data).slice(from, to).map(entry => entry[1])
        });
    };

    getPagination = () => {
        let pagination = null;

        if (!isEmpty(this.state.data) && (this.state.data.length > this.state.showCount)) {
            pagination = <ReactPaginate
                previousLabel = { '«' }
                nextLabel = { '»' }
                breakLabel = { '...' }
                breakClassName = { 'break-me' }
                pageCount = { Math.ceil(this.state.data.length / this.state.showCount) }
                marginPagesDisplayed = { 2 }
                pageRangeDisplayed = { this.state.showCount }
                onPageChange = { this.handlePageClick }
                containerClassName = { 'pagination react-paginate' }
                subContainerClassName = { 'pages pagination' }
                activeClassName = { 'active' }
            />;
        }

        return pagination;
    };

    handlePageClick = (data) => {
        let selected = data.selected;
        let page = selected + 1;
        let to = page * this.state.showCount;
        let from = to - this.state.showCount;

        this.getData(from, to);
    };

    getHistoryChanges = () => {
        securedRequest
            .get(this.props.url)
            .then((response) => {
                this.setState({
                    data: response.data,
                }, () => {
                    this.getData(0, this.state.showCount);
                });
            })
            .catch((error) => {
                NotificationService.errors(error.response.data);
            });
    };

    componentDidMount () {
        this.getHistoryChanges();
    }

    render () {
        return (
            <Fade className = 'fade-block history-changes'>
                <div>
                    <div className = 'close-button' onClick = { this.props.closeFunction }>
                        <i className = 'la la-close' />
                    </div>
                    <h1 className = 'page-title aligncenter'>
                        { this.props.translate(`historyChanges__History_of_Changes`) }
                    </h1>
                    <HistoryChangesComponentTable data = { this.state.paginateData } />
                    { this.getPagination() }
                </div>
            </Fade>
        );
    }
}

export default withLocalize(HistoryChangesComponent);
