import React from "react";
import {
    Tooltip
} from 'reactstrap';
export default class SpectatorBlock extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            tooltipOpen: false,
        };

    }

    toggle = () => {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen,
        });
    };

    render () {
        return (
            <React.Fragment>
                {/*<div className = { `item-spectator color-spectator-${this.props.value+1}` } id = { `spectator-${this.props.item['core-user-id']}` }>*/}
                <div className = { `item-spectator color-spectator-${this.props.value+1}` } id = { `spectator-${this.props.item['id']}` }>
                    {/*{this.props.item['core-user-name'][0].toUpperCase()}{this.props.item['core-user-surname'][0].toUpperCase()}*/}
                    {this.props.item['name'][0].toUpperCase()}{this.props.item['surname'][0].toUpperCase()}
                </div>
                <Tooltip
                    arrowClassName = 'tooltipSpectatorArrow'
                    color = 'white'
                    innerClassName = 'tooltipSpectator'
                    isOpen = { this.state.tooltipOpen }
                    placement = 'bottom'
                    // target = { `spectator-${this.props.item['core-user-id']}` }
                    target = { `spectator-${this.props.item['id']}` }
                    toggle = { this.toggle }>
                    {/*{`${this.props.item['core-user-name']} ${this.props.item['core-user-surname']}`}*/}
                    {`${this.props.item['name']} ${this.props.item['surname']}`}
                </Tooltip>
            </React.Fragment>
        );

    }
}
