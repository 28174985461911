import React, { lazy } from 'react';

import PermissionService, { MODULE_KEYS as pm } from "../../services/PermissionService";

// const Payments = lazy( () => import( './components/Payments' ) );
import Payments from './domains/ListPayments/components/Payments';
import SummaryOfFinTransactions from "./domains/SummaryOfFinTransactions";
export const paymentsConfig = [
	{
		permission: pm.PAYMENTS_REQUESTS,
		action: PermissionService.showRoute(),
		component: <Payments activeTab='order' />,
		path: '/payments/order/:orderId',
	},
	{
		permission: pm.PAYMENT_SUMMARY,
		action: PermissionService.showRoute(),
		component: <SummaryOfFinTransactions />,
		path: '/payments/summary_date',
	},
	{
		permission: pm.PAYMENTS_REQUESTS,
		action: PermissionService.indexRoute(),
		component: <Payments activeTab='orders' />,
		path: [
			'/payments/orders/filter/:filterId',
			'/payments/orders',
			'/payments/filter/:filterId',
			'/payments',
		],
	},

];
