import React from 'react';
import accessDeniedImg from '../../../images/503.svg';
import { Redirect } from 'react-router-dom';

export default class AccessDeniedPage extends React.Component {
    state = {
        redirect: false,
    };

    goBack = () => {
        this.setState( { redirect: true, } );
        // this.props.history.goBack();
    };

    render = () => {
        if ( this.state.redirect ) {
            return (<Redirect to={ '/' } />);
        }
        return (
            <div className='error-page-wrapper'>
                <div className='error-page-inner'>
                    <div className='error-page-content'>
                        <div className='error-page'>
                            <div className='title h3'>Access is denied</div>
                            <div className='big-error'>
                                <div className='big-error-button'>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <a className='button big red unshadow' onClick={ this.goBack }>Go Back</a>
                                    </div>
                                </div>
                                <img alt='503' className='big-access-denied-img' src={ accessDeniedImg } />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
};
