const inputFieldIsValid = (fieldName, value) => {
	switch (fieldName) {
		case "amount": {
			if (value < 0.01 && +value !== 0) {
				return false;
			} else if (value > 1_000_000) {
				return false;
			}
			return true;
		}
		default: {
			break;
		}
	}
};

export default inputFieldIsValid;