import React, { Component, } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-date-picker";
import { isNull, } from "lodash";
import moment from "moment";
import Select from "react-select";

import Loader from "../../../../../../tima/components/Loader";
import Preloader from "../../../../../../../components/LoadingHOC/Preloader";
import { MagicRadioSwitch, } from "../../../../../../tima/components/Magic/MagicSwitch";
import {
	checkAccountPartner,
	checkAccountReferral,
	checkSystemAccountPartner,
	checkSystemAccountReferral,
	createPartnersReferral,
} from "../../../../../../../services/PartnersRequestService";
import { MagicError, MagicDate, } from "../../../../../../../components/Magic";

import NotificationService from "../../../../../../../services/NotificationService";

const CURRENT_DATE = new Date();
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const REG_EXP = {
	number: /^[0-9]{1,}$/,
};

Object.freeze(REG_EXP);

const MODE = {
	id: 0,
	accountNumber: 1,
};

Object.freeze(MODE);

class CreatePartnersReferral extends Component {
	static propTypes = {
		popUpClose: PropTypes.func.isRequired,
		refreshChildData: PropTypes.func.isRequired,
	};

	constructor (props) {
		super(props);

		const { translate, } = props;

		this.state = {
			loaded: true,
			loadedInnerRequest: true,

			client: {
				mode: MODE.id,
				searchStr: "",
				isValid: true,
				isFound: null,
				isCheckBtnEnabled: false,
				data: {},
			},

			partner: {
				mode: MODE.id,
				searchStr: "",
				isValid: true,
				isFound: null,
				isCheckBtnEnabled: false,
				data: {},
			},

			selectedDate: {
				isValid: true,
				data: null,
				isOtherDate: false,
				otherDate: CURRENT_DATE,
				options: [
					{
						value: "otherDate",
						label: translate("partners_create_referral_date_custom_option"),
						order: 999,
					},
				],
			},
		};
	}

	get partnerIsPreseted () {
		return this.props.accountId;
	}
	save = async state => new Promise(next => this.setState(state, next));

	componentDidMount = async () => {
		try {
			if (this.partnerIsPreseted) {
				await this.findPartnerFromProps();
			}
		} catch (e) {
			console.log(e);
		}
	};

	findPartnerFromProps = async () => {
		const {partner} = this.state;
		const { accountId, } = this.props;

		await this.save({
			partner: {
				...partner,
				searchStr: accountId,
				isCheckBtnEnabled: true,
			},
		});

		await this.onCheckBtnClick('partner')();
	}

    getNotificationSuccess = () => {
        return NotificationService.success({
            title: 'success',
            message: this.props.translate('partners_referral_added_success'),
            remove: false,
        })
    }
	validateFormDataFields = async () => {
		const { client = {}, partner = {}, selectedDate, } = this.state;
		const { data: selectedDateData, isOtherDate, } = selectedDate;
		const clientId = client?.data?.core?.account?.id;
		const partnerId = partner?.data?.core?.account?.id;

		const isClientValid = client?.isFound && !isNaN(clientId);
		const isPartnerValid = partner?.isFound && !isNaN(partnerId);
		const isValidDate = this.isValidField({
			name: "date",
			value: selectedDateData?.value,
		});
		const isValidOtherDate = this.isValidField({
			name: "date",
			value: selectedDate?.otherDate,
		});
		const isValidTargetDate = isOtherDate ? isValidOtherDate : isValidDate;

		const isValidStatus =
			!!isClientValid && !!isPartnerValid && !!isValidTargetDate;

		await this.save(state => ({
			client: {
				...state.client,
				isValid: isClientValid,
			},
			partner: {
				...state.partner,
				isValid: isPartnerValid,
			},
			selectedDate: {
				...state.selectedDate,
				isValid: isValidTargetDate,
			},
		}));

		return isValidStatus;
	};

	onConfirmClick = async (event) => {
		const validatorStatus = await this.validateFormDataFields();

		if (validatorStatus) {
			const { client = {}, partner = {}, selectedDate, } = this.state;
			const { data: selectedDateData, isOtherDate, otherDate, } = selectedDate;
			const referral_id = client?.data?.core?.account?.id;
			const partner_id = partner?.data?.core?.account?.id;

			const formData = {
				referral_id,
				partner_id,
				date: moment(isOtherDate ? otherDate : selectedDateData.value).format(
					DATE_FORMAT,
				),
			};

			await this.save({ loadedInnerRequest: false, });

			try {
				const response = await createPartnersReferral(formData);

				if (response?.error) {
					throw new MagicError(Error(response?.error)); // custom error from back-end
				}

				this.props.refreshChildData();
				this.props.popUpClose();
				this.getNotificationSuccess()
			} catch (error) {
				error?.showErrorNotification?.();
			}

			await this.save({ loadedInnerRequest: true, });
		}
	};

	getRegExpByFieldName = (fieldName) => {
		let regExp = "";
		const maxLength = 50;

		switch (fieldName) {
			default: {
				regExp = REG_EXP.number;
				break;
			}
		}

		return {
			regExp,
			maxLength,
		};
	};

	isValidField = (fieldObj) => {
		const { name, value, } = fieldObj;

		let isValid = false;

		if (name === "date") {
			isValid = moment(value, DATE_FORMAT).isValid();
		} else {
			try {
				const { regExp, maxLength, } = this.getRegExpByFieldName(name);

				isValid = value.length <= maxLength && regExp.test(value);
			} catch (error) {
				console.log("isValidField(): No such field: ", name);
			}
		}

		return isValid;
	};

	inputValueIsValid = value => +value !== 0 || value === "";

	onInputChange = blockName => async (event) => {
		const { value, } = event.target;
		const { selectedDate, } = this.state;
		const { options, } = selectedDate;

		if (!this.inputValueIsValid(value)) {
			return;
		}

		const newOptions = options.filter(item => item.customLabel !== blockName);
		const isValid = this.isValidField({
			name: "searchStr",
			value,
		});

		if (isValid || value === "") {
			const newState = {
				[blockName]: {
					...this.state[blockName],
					searchStr: value,
					isCheckBtnEnabled: isValid,
					isFound: null,
					isValid: true, // reset error onInput
					data: {},
				},
				selectedDate: {
					...this.state.selectedDate,
					data: null,
					isOtherDate: false,
					options: newOptions,
				},
			};

			await this.save(newState);
		}
	};

	onCheckBtnClick = blockName => async () => {
		const { translate, } = this.props;
		const blockObj = this.state[blockName];
		const { selectedDate, } = this.state;
		const { options, } = selectedDate;
		const { mode, searchStr: id, isCheckBtnEnabled, } = blockObj;

		if (!isCheckBtnEnabled) {
			return;
		}

		if (id !== "" && !isNaN(id)) {
			let checkFunc = null;

			switch (true) {
				case blockName === "client" && !mode: {
					checkFunc = checkAccountReferral;
					break;
				}
				case blockName === "client" && !!mode: {
					checkFunc = checkSystemAccountReferral;
					break;
				}
				case blockName === "partner" && !mode: {
					checkFunc = checkAccountPartner;
					break;
				}
				case blockName === "partner" && !!mode: {
					checkFunc = checkSystemAccountPartner;
					break;
				}
				default: {
					throw new Error(
						`Can*t check the field: '${ blockName }', no necessary check request!`,
					);
				}
			}

			await this.save({ loadedInnerRequest: false, });

			try {
				const response = await checkFunc(id);
				const { data = {}, } = response;

				if (response?.error) {
					throw new Error("Custom Error: Not Found!"); // custom error, due to different response format from the back-end
				}

				if (data?.core?.account) {
					const created_at = data?.core?.account?.created_at;
					const dateLabel = translate(
						`partners_create_referral_date_label_${ blockName }`,
					);
					const orderArr = [ "client", "partner", ];

					const newOptions = options.filter(
						item => item.customLabel !== blockName,
					);

					if (blockName === "client") {
						newOptions.push({
							value: created_at,
							label: `${ created_at } - ${ dateLabel }`,
							customLabel: blockName,
							order: orderArr.indexOf(blockName),
						});
					}

					if (
						blockName === "partner" &&
						data?.core?.account_roles?.[0]?.created_at
					) {
						const partner_created_at =
							data?.core?.account_roles?.[0]?.created_at; // !!! 1 role for 1 partner !!!
						const dateLabelConfirm = translate(
							`partners_create_referral_date_label_confirmation`,
						);

						newOptions.push({
							value: partner_created_at,
							label: `${ partner_created_at } - ${ dateLabelConfirm }`,
							customLabel: blockName,
							order: orderArr.indexOf(blockName),
						});
					}

					newOptions.sort((opt1, opt2) => opt1.order - opt2.order);

					const newState = {
						[blockName]: {
							...this.state[blockName],
							isFound: true,
							isValid: true,
							isCheckBtnEnabled: false,
							data,
						},
						selectedDate: {
							...this.state.selectedDate,
							options: newOptions,
						},
					};

					await this.save(newState);
				} else {
					throw new Error("Custom Error: Not Found!"); // custom error, due to different response format from the back-end
				}
			} catch (error) {
				console.log("Error onCheckBtnClick: ", error);
				error?.showErrorNotification?.();

				const newOptions = options.filter(
					item => item.customLabel !== blockName,
				);

				const newState = {
					[blockName]: {
						...this.state[blockName],
						isFound: false,
						isValid: false,
						isCheckBtnEnabled: true,
						data: {},
					},
					selectedDate: {
						...this.state.selectedDate,
						options: newOptions,
					},
				};

				await this.save(newState);
			}

			await this.save({ loadedInnerRequest: true, });
		}
	};

	onDateChange = async (date) => {
		const newState = {
			selectedDate: {
				...this.state.selectedDate,
				otherDate: date,
				isValid: true,
			},
		};

		await this.save(newState);
	};

	onDateSelectChange = async (date) => {
		const newState = {
			selectedDate: {
				...this.state.selectedDate,
				data: date,
				isValid: true,
				isOtherDate: date.value === "otherDate",
				otherDate: CURRENT_DATE,
			},
		};

		await this.save(newState);
	};

	onSwitchChange = blockName => async ({ value, }) => {
		const blockObj = this.state[blockName];
		const { searchStr, } = blockObj;
		const { selectedDate, } = this.state;
		const { options, } = selectedDate;

		const newOptions = options.filter(item => item.customLabel !== blockName);

		const newState = {
			[blockName]: {
				...this.state[blockName],
				mode: value,
				isFound: null,
				isValid: true, // reset error onInput
				isCheckBtnEnabled: !!searchStr.length,
			},
			selectedDate: {
				...this.state.selectedDate,
				data: null,
				isOtherDate: false,
				options: newOptions,
			},
		};

		await this.save(newState);
	};

	renderFoundDataBlock = (blockName) => {
		const { translate, enums = {}, } = this.props;
		const blockObj = this.state[blockName];
		const {
			mode, searchStr, isFound, isCheckBtnEnabled, data = {},
		} = blockObj;
		const { core = {}, } = data;
		const { account = {}, account_roles = [], account_categories = [], } = core;
		const { name = "", surname = "", created_at = "", } = account;

		const accountCategoriesText =
			account_categories instanceof Array
				? account_categories
					?.map(
						item =>
							enums?.core?.AccountCategoryEnum?.[item.category_id] ?? "-",
					)
					?.join("; ")
				: "";

		const accountRolesText =
			account_roles instanceof Array
				? account_roles
					?.map(item => enums?.core?.AccountRolesEnum?.[item.role] ?? "-")
					?.join("; ")
				: "";

		const statusTextArr = [
			"partners_create_referral_error_text",
			"partners_create_referral_success_text",
		];
		const statusClass = isFound ? "info-text--success" : "info-text--error";

		if (isNull(isFound)) {
			return null;
		}

		return (
			<div className="cont cont--message">
				<div className={ `info-text info-text--title ${ statusClass }` }>
					{translate(statusTextArr[+isFound])}
				</div>

				{isFound ? (
					<React.Fragment>
						<div className="info-text">
							<span className="info-text__name">
								{`${ translate("partners_create_referral_client") }:`}
							</span>
							<span className="info-text__content">{`${ name } ${ surname }`}</span>
						</div>
						<div className="info-text">
							<span className="info-text__name">
								{`${ translate("partners_create_referral_category") }:`}
							</span>
							<span className="info-text__content">{`${ accountCategoriesText }`}</span>
						</div>
						<div className="info-text">
							<span className="info-text__name">
								{`${ translate("partners_create_referral_roles") }:`}
							</span>
							<span className="info-text__content">{`${ accountRolesText }`}</span>
						</div>
						<div className="info-text">
							<span className="info-text__name">
								{`${ translate("partners_create_referral_created_at") }:`}
							</span>
							 <span className="info-text__content">{`${ created_at }`}</span>
						</div>
						{blockName === "partner" && account_roles?.[0]?.created_at ? (
							<div className="info-text">
								<span className="info-text__name">
									{`${ translate(
										"partners_create_referral_date_label_confirmation",
									) }:`}
								</span>
								<span className="info-text__content">{`${
									account_roles?.[0]?.created_at
								}`}</span>
							</div>
						) : null}
					</React.Fragment>
				) : null}
			</div>
		);
	};

	renderSwitchBlock = (blockName) => {
		const { translate, } = this.props;
		const blockObj = this.state[blockName];
		const {
			mode, searchStr, isFound, isCheckBtnEnabled, isValid,
		} = blockObj;
		const btnClassName = isCheckBtnEnabled ? "" : "button--disabled";
		const errorClass = isValid ? "" : "error";

		const radioSwitchLabelClientArr = [
			"partners_create_referral_client_id",
			"partners_create_referral_balance_account_number",
		];
		const radioSwitchLabelPartnerArr = [
			"partners_create_referral_partner_id",
			"partners_create_referral_partner_account_number",
		];
		const radioSwitchLabelArr =
			blockName === "client"
				? radioSwitchLabelClientArr
				: radioSwitchLabelPartnerArr;

		const blockIsDisabled = this.partnerIsPreseted && blockName === 'partner';

		const magicOptions = {
			className: "magic-radio-switch space-between",
			direction: "row",
			index: mode,
			texts: radioSwitchLabelArr.map(t => translate(t)),
			values: [ MODE.id, MODE.accountNumber, ],
			onChange: this.onSwitchChange(blockName),
			isDisabled: blockIsDisabled,
		};

		return (
			<div className="add-referral-block">
				<div className="cont cont--radio-switch">
					<MagicRadioSwitch { ...magicOptions } />
				</div>

				<div className="cont space-between">
					<div className="input" key={ `input-${ blockName }-${ mode }` }>
						<div className="input-wrapper">
							<input
								autoComplete="off"
								className={ `input__field ${ errorClass }` }
								disabled={ blockIsDisabled }
								placeholder={ translate(
									`${ radioSwitchLabelArr[mode] }_placeholder`,
								) }
								type="text"
								value={ searchStr }
								onChange={ this.onInputChange(blockName) }
							/>
						</div>
					</div>

					<button
						className={ `gl-btn gl-btn--turquoise ${ btnClassName }` }
						disabled={ blockIsDisabled }
						onClick={ this.onCheckBtnClick(blockName) }
					>
						{translate("partners_create_referral_check_btn")}
					</button>
				</div>

				{this.renderFoundDataBlock(blockName)}
			</div>
		);
	};

	renderDateBlock = () => {
		const { translate, } = this.props;
		const { selectedDate, client, partner, } = this.state;
		const {
			data: selectedDateData,
			isValid,
			options,
			isOtherDate,
			otherDate,
		} = selectedDate;
		const errorClass = isValid ? "" : "error";
		const isSelectEnabled = client?.isFound && partner?.isFound;

		const partnerBecamePartner = new Date( partner.data.partners?.partners_setup.created_at ?? 0);
		const refferalRegistered = new Date(client.data.core?.account.created_at ?? 0);


		return (
			<div className="cont">
				<div className="input space-between">
					<div className="input-wrapper">
						<Select
							className={`input-select ${errorClass}`}
							classNamePrefix="select"
							isDisabled={!isSelectEnabled}
							isSearchable={false}
							options={options}
							value={selectedDateData}
							placeholder={translate(
								"partners_create_referral_date_placeholder",
							)}
							// styles = { customStyles }
							onChange={this.onDateSelectChange}
						/>
					</div>

					{isOtherDate ? (
						<div className="input-wrapper input-block dataPickerStyleCustom">
							<MagicDate
								disabled={ false }
								emptyLabel={translate(
									"partners_requests_partner_prohibit_application_for_placeholder",
								)}
								format={"YYYY-MM-DD"}
								locale={ this.props?.activeLanguage?.code }
								min={Math.max(partnerBecamePartner, refferalRegistered)}
								max={new Date(new Date().toISOString().split("T")[0])}
								name={"prohibit_application_for"}
								value={ otherDate }
								className={ `react-datepicker input__field ${ errorClass }` }
								// formats value to required format
								onChange={ this.onDateChange }
							/>
						</div>
					) : null}
				</div>
			</div>
		);
	};

	render () {
		const { translate, popUpClose, } = this.props;
		const { loaded, loadedInnerRequest, } = this.state;

		return (
			<Loader
				loaded={ loaded }
				loading={ <Preloader className="loaderUniversal--abs-pos" scale={ 1 } /> }
			>
				<div className="glalex-styles pop-up pop-up--create-partners-referral pop-up--active">
					{!loadedInnerRequest ? (
						<Preloader className="loaderUniversal--fixed-pos" scale={ 1 } />
					) :
						false
					}

					<div className="pop-up-wrapper scroll">
						<div className="pop-up-header">
							<h3 className="pop-up__name">
								{translate("partners_create_referral_title")}
							</h3>
							<i
								className="gl-icon close-btn close-btn--big"
								onClick={ popUpClose }
							/>
						</div>

						<div className="pop-up-content">
							{this.renderSwitchBlock("partner")}

							{this.renderSwitchBlock("client")}

							{this.renderDateBlock()}
						</div>

						<div className="btns-cont">
							<button
								className="gl-btn gl-btn--blue"
								onClick={ this.onConfirmClick }
							>
								{translate("partners_add_btn")}
							</button>
							<button
								className="gl-btn gl-btn--blue-border"
								onClick={ popUpClose }
							>
								{translate("partners_cancel_btn")}
							</button>
						</div>
					</div>
				</div>
			</Loader>
		);
	}
}

export default CreatePartnersReferral;