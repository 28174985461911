import React from "react";

import person from "../../../../images/person.svg";
import initiator from "../../../../images/client-blue.svg";
import { map } from "lodash";
import {getAvatar} from "../../services/TaskHelperService";

class InitiatorBlock extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            images: {},
            role:   '1',
        };
    }

    componentDidMount() {
        getAvatar(this, this.props.dataTask.initiator_id, this.props.usersAllAvatar);
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        getAvatar(this, nextProps.dataTask.initiator_id, nextProps.usersAllAvatar);
    }

    getEnumRole = () => {
        return map(this.props?.get_enums_tasks?.TaskRoleEnum, (roleName, key) => {
            if (key === this.state.role) {
                return (
                    <div className = 'lead-block flex-wrapper taskSmallFixed' key = { key }>
                        <div className = 'user-image'>
                            <img alt = 'user' src = { initiator } />
                        </div>
                        <div className = 'user-info'>
                            <div>{roleName}</div>
                            <div className = 'user-action'>
                                <span
                                    onClick = { () => {
                                        this.props.toggleModal(2);
                                        this.props.getAllManager();
                                    } }><img alt = 'person' src = { person } />{roleName}</span>
                            </div>
                        </div>
                    </div>
                );
            }
        });
    };

    roleName = () => {
        let _name; let _key;

        map(this.props?.get_enums_tasks?.TaskRoleEnum, (name, key) => {
            if (key === this.state.role) {
                _name = name;
                _key = key;
            }
        });

        return [_name, _key];
    };

    render () {
        const getAllUsers = this.props.getAllUsers;

        return (

               this.props.dataTask.initiator_id ?
                   map(getAllUsers, (item, key) => {
                      // if (item.core.user.id === this.props.dataTask.initiator_id) {
                       if (item.id === this.props.dataTask.initiator_id) {
                           return (
                               <div className = 'lead-block flex-wrapper taskSmallFixed' key = { key }>
                                   <div className = 'user-image'>
                                       <img alt = 'user' src = { this.state.images.imageId } />
                                   </div>
                                   <div className = 'user-info'>
                                       <div>{this.roleName()[0]}</div>
                                       <div className = 'user-name active'>{`${item.core.user.name} ${item.core.user.surname}`} <span className = 'circle' /></div>
                                       <div className = 'user-action'>
                                           <span
                                               onClick = { () => {
                                                   this.props.toggleModal(2);
                                                   this.props.getAllManager();
                                               } }><img alt = 'person' src = { person } />{this.roleName()[0]}</span>
                                       </div>
                                   </div>
                               </div>
                           );
                       }
                   })

                   :
                   this.getEnumRole()
        );
    }
}

export default InitiatorBlock;
