import React, { Fragment, Children, cloneElement, } from 'react';
import PropTypes from 'prop-types';

const renderLoaded = ({
	children, loaded, loading, ..._
}) => Children
	.toArray(children)
	.map(child => cloneElement(child, { ..._, ...child.props, }));

const renderLoading = ({ loading, }) => (
	<Fragment>
		{ loading ? loading : <div>Loading...</div> }
	</Fragment>
);

const Loader = (props) => {
	const { loaded, } = props;

	return (
		<Fragment>
			{ loaded ? renderLoaded(props) : renderLoading(props) }
		</Fragment>
	);
};

// https://learn-reactjs.ru/core/typechecking-with-prop-types
Loader.propTypes = {
	children: PropTypes.node.isRequired,
	loaded: PropTypes.bool.isRequired,
	loading: PropTypes.node,
};

export default Loader;