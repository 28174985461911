
const setCountries = (id, array) => {
    const result = array.filter((item) => {
        return item.country_group_id === id;
    });

    return result;
};

export default function prepareDataForEditPayoutRateList (rawData) {
	try {
		const {
			name, type, status, id,
		} = rawData.payout_rate_lists;

		const countries = rawData?.country_to_group;

		const payoutRangesSumFrom =
			type === 1 &&
			rawData?.payout_rates_ranges
				.sort((a, b) => a.id - b.id)
				.map(({ sum_from, }) => sum_from);

		const country_groups = [];

		if (type === 2) {
			rawData?.country_group.map((countryGroup) => {
				const filteredCountries = setCountries(countryGroup.id, countries);
				const forbiddenGroup = countryGroup.is_forbidden === "true" ? 1 : 0;

				return (
					!filteredCountries.find(({ id, }) => {
						id === countryGroup.id;
					}) &&
					country_groups.push({
						group: filteredCountries.map(item => item.country_id),
						is_forbidden: forbiddenGroup,
						name: countryGroup.name,
						id: countryGroup.id,
						rate: countryGroup.payout_rate,
					})
				);
			});
		} else if (type === 1) {
			// eslint-disable-next-line max-len
			rawData?.country_group.sort((a, b) => a.id - b.id).map((countryGroup) => {
				const filteredCountries = setCountries(countryGroup.id, countries);

				const payoutRanges = rawData?.country_group_to_range
					.sort((a, b) => a.payout_rates_range_id - b.payout_rates_range_id)
					.filter(item => item.country_group_id === countryGroup.id)
					.map(({ amount, }, index) => amount);
				const forbiddenGroup = countryGroup.is_forbidden === "true" ? 1 : 0;

				return (
					!filteredCountries.find(({ id, }) => {
						id === countryGroup.id;
					}) &&
					country_groups.push({
						group: filteredCountries.map(item => item.country_id),
						is_forbidden: forbiddenGroup,
						name: countryGroup.name,
						id: countryGroup.id,
						payoutRanges,
					})
				);
			});
		}

		return {
			country_groups,
			payoutRangesSumFrom,
			name,
			type,
			status,
			id,
		};
	} catch (e) {
		console.log(e);
	}
}