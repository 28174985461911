import {
	get, post, put, del,
} from "../../../components/Magic";
import {
	GET_USER_COUNT_ACTIVE_EVENTS,
	EVENTS_MARK_ALL_VIEWED,
	// GET_USER_EVENTS_BY_USER_PROFILE,
	// GET_USER_EVENT_SETTINGS,
} from "../apiRoutes";

export const getUserCountActiveEvents = async () =>
	await get(`${ GET_USER_COUNT_ACTIVE_EVENTS }`);

export const getUserEventsByUserProfile = async () =>
	await get(`${ GET_USER_EVENTS_BY_USER_PROFILE }`);

export const getUserEventSettings = async () =>
	await get(`${ GET_USER_EVENT_SETTINGS }`);

export const markAllEventsViewed = async () =>
	await post(`${ EVENTS_MARK_ALL_VIEWED }`);