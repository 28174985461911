export const SET_TASKS = 'SET_TASKS';
export const SET_TASK = 'SET_TASK';
export const SET_FILTER = 'SET_FILTER';
export const GET_ENUMS_TASKS = 'GET_ENUMS_TASKS';
export const SET_ONE_HUNDRED_TASKS = 'SET_ONE_HUNDRED_TASKS';
export const SET_COMMENTS_TASK_ID = 'SET_COMMENTS_TASK_ID';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const SET_COUNT_NOT_WORK = 'SET_COUNT_NOT_WORK';
export const SET_TASK_USERS = 'SET_TASK_USERS';
export const SET_ALL_TASKS_USERS = 'SET_ALL_TASKS_USERS';
export const SET_PARTICIPANTS = 'SET_PARTICIPANTS';
export const DELETE_PARTICIPANTS = 'DELETE_PARTICIPANTS';
export const SET_SELECT_ACCOUNT_MODEL = 'SET_SELECT_ACCOUNT_MODEL';
export const SET_ERROR = 'SET_ERROR';
export const RESET_ERROR = 'RESET_ERROR';
export const DELETE_TASK = 'DELETE_TASK';
export const GET_HISTOR = 'GET_HISTOR';
export const DELETE_PARTICIPANTS_CLIENT = 'DELETE_PARTICIPANTS_CLIENT';
export const CLEAR_TASK = 'CLEAR_TASK';
export const FILTER_HASH = 'FILTER_HASH';
export const GET_TYPES_TASKS = 'GET_TYPES_TASKS';
export const GET_STATUS_TASKS = 'GET_STATUS_TASKS';

