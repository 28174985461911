import * as actions from './actionTypes';

const initialState = {
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.SET_KPI:
            return {
                ...state,
                'kpiData':        action.data
            };
        case actions.SET_USERS_DEPARTMENT:
            return {
                ...state,
                'usersDepartment':        action.data
            };
        default:
            return state;
    }
}
