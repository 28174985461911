import React from "react";
import { Fade } from "reactstrap";
import { Scrollbars } from "react-custom-scrollbars";
import SpectatorRow from "./SpectatorRow";
import AddResponsibleRow from "./AddResponsibleRow";
import {
    uploadParticipants,
    deleteParticipants
} from "../../../services/TaskRequestService";

class TaskSpectatorModal extends React.Component {
    constructor (props) {
        super(props);

        // this.constFieldData = 'core-user-id';

        this.state = {
            filter:         '',
            data:           this.props.getSpectator,
            getSortManager: this.props.getAllManager,
            roleUser:       this.props.roleUser,
            deletedSpectator: [],
        };
    }

    async UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
        if (this.props.type === "create" && nextProps.getSpectator.length >= 0) {
            await this.save({
                data:      nextProps.getSpectator,
                getSortManager: nextProps.getAllManager,
                roleUser:       parseInt(nextProps.roleUser),
            });
        } else {
            if (nextProps.getSpectator.length > 0 || nextProps.roleUser !== this.state.roleUser) {
                await this.save({
                    data:      nextProps.getSpectator,
                    getSortManager: nextProps.getAllManager,
                    roleUser:       nextProps.roleUser,
                });
            } else {
                await this.save({
                    data:      [],
                    getSortManager: nextProps.getAllManager,
                    // roleUser:  this.props.ROLE_4,
                });
            }
        }
    }

    save = state => new Promise(next => this.setState(state, next));

    comparisonData = (item) => (spectator) => {
        return item.id === spectator.id;
    };

    filterSpectator = (defaultArray, newSpectator) => [...defaultArray, newSpectator].filter((item, index, array) => array.findIndex(this.comparisonData(item)) === index);

    addSpectator = async (object) => {
        if (this.state.roleUser !== this.props.ROLE_3) {
            const newSpectators = await this.filterSpectator(this.state.data, object);
            await this.save({
                data: newSpectators,
            });
        } else {
            await this.save({
                data: [object],
            });
        }
    };

    removeSpectator = async (id) => {
        await this.save((state) => {
            const removeDataId = [];
            const deletedSpectator = [...state.deletedSpectator];
            state.data?.forEach((item) => {
                if (item?.id !== id) {
                    removeDataId.push(item);
                } else {
                    deletedSpectator.push(item);
                }
            });

            return {
                data: removeDataId,
                deletedSpectator,
            };
        });
    };

    revertData = async () => {
        await this.filterReset();
        await this.save({
            data: this.props.getSpectator,
        });
    };

    createUser = async (dataArray) => {
        await Promise.all(dataArray?.map?.(async (data) => {
            try {
                await uploadParticipants({
                    task_id: this.props.taskId,
                    user_id: data.id,
                    role_id: this.state.roleUser,
                });
            } catch (error) {
                console.log(error);
            }
        }));
        await this.getDataTask();
    };

    filterReset = async () => await this.save({ filter: '' });

    saveModalTask = async () => {
        const dataArray = this.state.data?.map?.((item) => item.id);
        let boolean = true;

        this.filterReset();

        if (this.state.roleUser === this.props.ROLE_3) {
            boolean = false;
            await this.props.updateDataClient({task_id: +this.props.taskId, user_id: dataArray[0], role_id: this.props.ROLE_3});
        } else if (this.props.getSpectator.length > 0) {
            const filterNewUsers = this.state.data.filter(item => !item.hasOwnProperty('delete_id'));
            boolean = false;
            const filteredSpectator = this.state.deletedSpectator?.filter(item => item.delete_id) ?? [];
            await Promise.all(filteredSpectator?.map( async (item, key) => {
                try {
                    await deleteParticipants(item.delete_id, this.props.taskId, this.state?.roleUser);
                } catch (error) {
                    error.showErrorNotification();
                }
            }));
            await this.createUser(filterNewUsers);
            await this.save({ deletedSpectator: []});
            await this.getDataTask();
        } else {
            dataArray.length === 0 && boolean ? this.getDataTask() : this.createUser(this.state.data);
        }
    };

    saveModalCreateTask = () => {
        const dataArray = this.state.data?.map?.((item) => item.id);

        this.filterReset();

        switch (this.state.roleUser) {
            case this.props.ROLE_4 :
                this.props.setDataModal(dataArray, 'spectator_ids');
                break;
            case this.props.ROLE_3:
                this.props.setDataModal(dataArray[0], 'responsible_id');
                break;
            case this.props.ROLE_2:
                this.props.setDataModal(dataArray[0], 'initiator_id');
                break;
            case this.props.ROLE_1:
                this.props.setDataModal(dataArray[0], 'account_id');
            case this.props.ROLE_5:
                this.props.setDataModal(dataArray, 'assistance_ids')
                break;
        }
    };

    getDataTask = () => {
        switch (this.state.roleUser) {
            case this.props.ROLE_4 :
                this.props.getTaskParticipantsMethod(this.props.taskId, this.props.ROLE_4);
                break;
            case this.props.ROLE_3:
                this.props.getTaskParticipantsMethod(this.props.taskId, this.props.ROLE_3);
                break;
            case this.props.ROLE_2:
                this.props.getTaskParticipantsMethod(this.props.taskId, this.props.ROLE_2);
                break;
            case this.props.ROLE_5:
                this.props.getTaskParticipantsMethod(this.props.taskId, this.props.ROLE_5);
                break;
            default: break;
        }
    };

    // sortAllClient = (clients) => {
    //     if (!this.state.filter) {
    //         return clients;
    //     }
    //     const array = clients.filter((item) => {
    //         const name = item.name ?? '';
    //         const surname = item.surname ?? '';

    //         return name.toLowerCase().includes(this.state.filter.toLowerCase()) || surname.toLowerCase().includes(this.state.filter.toLowerCase());
    //     });

    //     return array;
    // };

    getName = (translate) => {
        switch (this.state.roleUser) {
            case this.props.ROLE_4 :
                return translate(`tasks_spectator_a`);
            case this.props.ROLE_3:
                return translate(`tasks_filter_responsible_a`);
            case this.props.ROLE_2:
                return translate(`tasks_task_initiator_a`);
            case this.props.ROLE_1:
                return translate(`tasks_filter_responsible_a`);
            // return translate(`task_edit_client`);
            case this.props.ROLE_5:
                return translate(`tasks_task_assistance_a`)
            default: break;
        }
    };

    renderAddResponsibleRow = (key, item) => {
        const { filter } = this.state;
        const isVisible = item?.isVisible ?? true;

        return isVisible && (
            <AddResponsibleRow
                filter = { this.state.filter }
                key = { key }
                responsible = { item }
                roleUser = { this.props.roleUser }
                setToStateParticipants = { this.addSpectator }
                usersAllAvatar = { this.props.usersAllAvatar }
            />
        );
    };

    onInputSearchChange = async (event) => {
        const { value } = event.target;
        const valueLowerCase = value.toLowerCase();
        const newState = { filter: value };

        this.state.getSortManager?.forEach((item, i) => {
            const isStrLike = `${item.name} ${item.surname}`.toLowerCase().indexOf(valueLowerCase) !== -1;

            item['isVisible'] = isStrLike;
        });

        await this.save(newState);
    };

    render () {
        // const getAccountModel = this.props.getAccountModel;
        // const sortAllClient = this.sortAllClient(getAccountModel);
        const spectatorIdsArray = this.state.data?.map?.((item) => item.id);
        const { translate } = this.props;

        return (
            <Fade className='spectors-modal fade-block' in={this.props.fade}>
                <div className='spectors-block'>
                    <div
                        className='close-button' onClick={() => {
                        this.props.toggleModalTask();
                        this.revertData();
                    }}>
                        <i className='la la-close'/>
                    </div>
                    <h4 className='aligncenter'>{translate(`${this.state.data.length ? 'tasks_change_the' : 'tasks_add_the'}`)} {this.getName(translate)}</h4>
                    <div className='flex-wrapper'>
                        <div className='participants'>
                            <div
                                className='responsible-title'>{translate(`tasks_participants`)} ({this.state.data.length})
                            </div>
                            <div>
                                <Scrollbars style={{ height: 300 }}>

                                    {
                                        //   this.state.roleUser === this.props.ROLE_1 ?
                                        //      getAccountModel?.map?.((item, key) => {
                                        //          if (item.id === spectatorIdsArray[0]) {
                                        //              return (<ResponsibleRowClient
                                        //                  key = { key }
                                        //                  name_field = 'client'
                                        //                  removeParticipants = { this.removeSpectator }
                                        //                  removeParticipantsClient = { () => {} }
                                        //                  responsible = { item }
                                        //              />);
                                        //          }
                                        //      })

                                        //      :

                                        this.state.data?.map?.((item, key) => {
                                            return (<SpectatorRow
                                                getSpectator = { item }
                                                key = { key }
                                                removeParticipants = { this.removeSpectator }
                                                ROLE_4 = { this.props.ROLE_4 }
                                                ROLE_5 = { this.props.ROLE_5 }
                                                roleUser = { this.state.roleUser }
                                                selectPartipiants = { item }
                                                usersAllAvatar = { this.props.usersAllAvatar }
                                            />);
                                        })

                                    }
                                </Scrollbars>
                            </div>
                        </div>
                        <div className='add-participants'>
                            <div className='responsible-title'>
                                <div className=''>
                                    <input onChange={this.onInputSearchChange}
                                           placeholder={translate(`task_search`)}
                                           type='text'
                                           value={this.state.filter}
                                    />
                                </div>
                            </div>
                            <div>
                                <Scrollbars style={{ height: 300 }}>
                                    {
                                        //   this.state.roleUser === this.props.ROLE_1 ?
                                        //      getAccountModel?.map?.((item, key) => {
                                        //          return (<AddResponsibleRowClient
                                        //              dataTask = { this.props.dataTask }
                                        //              filter = { this.state.filter }
                                        //              key = { key }
                                        //              name = { item.name }
                                        //              responsible = { item }
                                        //              roleUser = { this.state.roleUser }
                                        //              setToStateParticipants = { this.addSpectator }
                                        //              sortAllClient = { sortAllClient[key] ?? '' }
                                        //          />);
                                        //      })

                                        //      :

                                        this.state.getSortManager?.map?.((item, key) => {
                                            if (!spectatorIdsArray?.includes?.(item.id)) {
                                                return this.renderAddResponsibleRow(key, item);
                                            }
                                        })
                                    }
                                </Scrollbars>
                            </div>
                        </div>
                    </div>
                    <div className='modal-buttons'>
                        <div className='gradient-button'>
                            <span
                                onClick={() => {
                                    if (this.props.type === "create") {
                                        this.saveModalCreateTask();
                                        // console.log('create')
                                    } else {
                                        // console.log('save')
                                        this.saveModalTask();
                                    }
                                    this.props.toggleModalTask(this.state.roleUser);
                                }}>{translate(`tasks_createform_save`)}</span>
                        </div>
                    </div>
                </div>
            </Fade>
        );
    }
}
export default TaskSpectatorModal;
