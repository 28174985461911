import React, { Component } from 'react';
import { map } from 'lodash';
import classnames from 'classnames';

class Hint extends Component {

    render () {
        const {
            toolsListByCategoryId,
            hintClose,
            width_hint,
            top,
            left,
            nameHint,
        } = this.props;

        return (
            <div className='hint hint--active glalex-styles' style={{ width: `${width_hint}px`, left: left, top: top }}>
                <div className='hint__inner-cont'>
                    <div className='hint-header'>
                        <span className='hint__name'>{nameHint}</span>
                        <i className='gl-icon close-btn' onClick={() => {
                            hintClose(false);
                        }}/>
                    </div>

                    <div className='hint-content scroll cont--trading-tools'>
                        <ul className='list-items'>
                            {
                                map(toolsListByCategoryId, (item, key) => {
                                    const { disabled, name } = item;

                                    return (
                                        <li key={key}
                                            className={`${classnames({ 'selected': disabled })}`}>{name}</li>
                                    );
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default (Hint);
