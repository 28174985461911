import React from 'react';
import { USER_PROP_TYPES, OUTPUT_PROP_TYPES } from '../../../components/PropTypes/Tasks_PropTypes';
import { isNull } from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withLocalize } from 'react-localize-redux';

import IndexTasks from "../../admin_panel/users/components/Permissions/index";
import SideBarTaskList from "../../common/components/SideBarTaskList/index";
import PermissionService, { MODULE_KEYS as pm } from "app/services/PermissionService";
import {
  getTasksStatus
} from '../services/TasksService';
import { withRouter } from 'react-router-dom';
import NewTaskCreateForm from "./NewTaskCreateForm/index";
import DataStorage from "../../../helpers/DataStorage";

import { TASKS_EXPORT_FIELDS } from "./constants";
import { securedRequest } from "../../../services/RequestService";
import { getAccountLabelsRequest, getTasksAsync } from "../services/TaskRequestService";
import Preloader from "../../../components/LoadingHOC/Preloader";
import PagePanel from "../../tima/components/Pages/PagePanel";
import Loader from "../../tima/components/Loader";
import {
  MagicFilterPanels,
  Mentor,
  NotificationService,
  showExportToExcelError,
} from "../../../components/Magic";
import TasksMagicTable from './TasksMagicTable';
import { updateCustomerSign, getResultLastContact, getTypeClient, updateTask } from '../services/TaskRequestService';
import { enumService, } from "../../../services/EnumDataService";
import { profileService, } from "../../../services/ProfileDataService";
import { taskService } from "../../../services/TaskDataService";



class Tasks extends React.Component {
  constructor(props) {
    super(props);

    const mf = new Mentor({
      owner: this,
      serviceId: 32,
      translate: this.props.translate,
    });

    Object.defineProperty(this, 'mf', { get: () => mf, });

    const taskId = this.props.match.params.id ? this.props.match.params.id : null;

    this.state = {
      taskId,
      dictionary_account_labels: [], // ???
      showCreate: null, // ???
      params: { // ???
        ...this.props.filter,
      },
      tableSort: false, // ???
      output: { // ???
        ...this.props.output,
      },
      filter: { // ???
        ...this.props.filter,
      },
      sort: { // ???
        ...this.props.sort,
      },
      // userId: this.props.user.profile.id, // ???
      fs: {}, // ???
      clientFilter: {}, // ???
      selectedFilters: {}, // ???
      fieldsForChecking: this.props.fieldsForChecking, // ???
      chosenFilter: [], // ???
      filterCount: this.props.filterCount, // ???
      total: this.props.total, // ???
      statusType: [], // ???
      typeType: [], // ???
      queryString: '', // ???
      activeFilter: false, // ???
      // advancedFilters: {}, // ???
      // additionalActiveFilters: {}, // ???
      formPermission: false, // ???
      statusSelect: { // ???
        value: null,
        id: null,
      },
      typeSelect: { // ???
        value: null,
        id: null,
      },
      selectsOptions: {}, // ???
      lang: undefined, // ???
      typesSelects: undefined, // ???
      statusSelects: undefined, // ???
      selectsValue: { ...this.props.selectsValue }, // ???

      loaded: false,
      data: [],
      accountLabels: [],
      accountLastContacts: [],
      accountTypes: [],
      taskStatuses: [],
      pages:  {
        filter: 0,
        total: 0,
        take: 50,
        variance: 2,
      },
      filterLastChange: Date.now(),
      editStatus: false,
    };
  }

  // eslint-disable-next-line react/sort-comp, require-await
  save = async state => new Promise(next => this.setState(state, next));

  // ************************* NEW FILTERS ****************************
  mfChanged = async () => {
    await this.save({ filterLastChange: Date.now(), });
  }

  mfDoing = async () => {
    await this.save({ loaded: false, });
  };

  mfDone = async () => {
    await this.mfChanged();
    await this.onDataLoad();
  };

  enumsChange = async (enums) => {
    if (enums.hash===this.state?.enums?.hash) {
      return `${ this.constructor.name }.enumsChange: false`;
    }
    await this.save({ enums, });

    return `${ this.constructor.name }.enumsChange: true`;
  };

  profileChange = async (profile) => {
    if (profile.hash===this.state?.profile?.hash) {
      return `${ this.constructor.name }.profileChange: false`;
    }
    await this.save({ profile, });

    return `${ this.constructor.name }.profileChange: true`;
  };

  async componentDidMount () {
    enumService.subscribe('enums', this.enumsChange, this);
    profileService.subscribe('profile', this.profileChange, this);
    await Promise.all([
      enumService.enums,
      profileService.profile,
    ]);
    await Promise.all([
      [ 'accountLabels', getAccountLabelsRequest, ],
      [ 'accountLastContacts', getResultLastContact, ],
      [ 'accountTypes', getTypeClient, ],
    ].map(async ([ key, f, ]) => {
      try {
        const response = await f();

        await this.save({ [key]: response, });
      } catch (error) {
        NotificationService.error({
          message: error,
          remove: false,
          title: 'error',
        });
      }
    }));

    this.mf.subscribe({
      changed: this.mfChanged,
      // changing: ,
      cleaned: this.mfDone,
      cleaning: this.mfDoing,
      loaded: this.mfDone,
      loading: this.mfDoing,
      saved: this.mfDone,
      saving: this.mfDoing,
    }, this);
    await this.mf.init({ doEmit: true, });
    // await this.mf.emit('change', {});
    // await this.onDataLoad(); // TODO: moved to mfDone

    // this.props.getTasksTypes();
    this.props.getTasksStatus();

    const localeLang = DataStorage.getData('locale') ?? 'en';

    await this.save({ lang: localeLang, });
  }

  componentWillUnmount () {
    enumService.unsubscribe('enums', this.enumsChange, this);
    profileService.unsubscribe('profile', this.profileChange, this);

    this.mf.unsubscribe([
      this.mfChanged,
      this.mfDoing,
      this.mfDone,
    ], this);
  }
  // ************************* *********** ****************************

  // ************************* NEW PAGINATION ****************************
  pageId = () => {
    const defaultPageId = 0;

    try {
      const query = new URLSearchParams(location.search);
      const page = query.get('page');

      return Number(page) || defaultPageId;
    } catch (e) {
      return defaultPageId;
    }
  };

  pageIdAsGET = (pageId) => {
    pageId = pageId === undefined ? this.pageId() : pageId;
    const { filterId, } = this.mf;
    const result = {
      filterId,
      take: this.state.pages.take,
      skip: pageId * this.state.pages.take,
    };
    return result;
  };

  onDataLoad = async ( pageId ) => {
    await this.save({ loaded: false, });
    const pageOptions = this.pageIdAsGET( pageId );
    const loaded = (data, meta) => ({ pages, }) => ({
      data,
      pages: { ...pages, ...meta, },
    });
    try {
      const response = await getTasksAsync(pageOptions);
      const { data, meta: { filter = 0, total = 0, }, } = response;
      await this.save(loaded(data, { filter, total, }));
    } catch ( error ) {
      await this.save(loaded([], { filter: 0, total: 0, }));
    }
    await taskService.countTask();
    await this.save({ loaded: true, });
  };

  onPageClick = ({ event, pageId, pageIs, }) => {
    // event.preventDefault();
    this.onDataLoad(pageId);
  };

  onPageText = (pageId, pageIs) => {
    const { translate } = this.props;
    const { current, first, prev, next, last, } = pageIs;
    const { skipped, taken, filter, total, } = pageIs;
    if (skipped || taken) {
      const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : '?';
      const text = skipped ? translate('partners_pages_items') : '';
      return skipped ? `${ text }: ${ id }` : id;
    } else if (filter || total) {
      const id = Number.isInteger(pageId) ? `${ pageId }` : '?';
      const text = translate(filter ? 'partners_pages_filtered' : 'partners_pages_total');
      return `${ text }: ${ id }`;
    } else if (first || prev || next || last) {
      return '';
    } else if (current) {
      return `${ pageId + 1}`;
    } else {
      return `${ pageId + 1}`;
    }
  };

  updateTask = async (data, id) => {
    try {
      await updateTask(data, id);
      await this.onDataLoad();
     } catch (err) {
      console.error(err);
     }
  }

    // *************************************************************************

  exportToExcel = (activeTab) => (event) => {
    if (activeTab) {
      const { filterId: filter_id, } = this.mf;
      const exportUrl = `export-to-excel`;

      let url = '',
        file_name = '';

      switch (activeTab) {
        case 'tasks': {
          url = `api/tasks/tasks/${exportUrl}`;
          file_name = `Tasks`;
          break;
        }
        default: {
          break;
        }
      }

      const options = {
        file_name,
        fields: TASKS_EXPORT_FIELDS[activeTab],
      };

      if (filter_id) {
        options['filter_id'] = filter_id;
      }

      // const taskStatusFieldName = this.props.activeLanguage ? `tasks-task_states-name_${this.props.activeLanguage.code}` : '';
      // if (taskStatusFieldName) {
      //     const POSITION_TO_INSERT = 5;
      //
      //     options.fields.splice(POSITION_TO_INSERT, 0, taskStatusFieldName);
      // }

      securedRequest.post(url, options)
        .then((res) => {
          // fileDownload(res.data, res.headers.filename);
        })
        .catch((error) => {
          showExportToExcelError(error);
        });
    }
  };

  handleClickOutsideStatus = async (e) => {
    if(!e.path) return //prevents errors in console
    if (
      !e.path.includes(document.querySelector('.tasks-status__id'))
      && !e.path.includes(document.querySelector('.MuiPaper-root'))
      && this.state.editStatus
    ) {
      await this.save({ editStatus: false });
      window.removeEventListener('mousedown', this.handleClickOutsideStatus);
    }
  }

  openStatusEdit = async (id) => this.save({ editStatus: id });

  render() {
    const { translate } = this.props;
    const head = [
      'accountFullname',
      'taskDescription',
      'taskStatus',
      'accountCategoryChangeDate',
      // 'taskAccountType',
      // 'taskCreated',
      // 'taskAccountLabels',
      // 'taskUpdated',
      // 'taskName',
      // 'taskAttachments',
      'taskLastContact',
      'taskLastContactDate',
      'accountCategory',
      'taskPhone',
      // 'taskCustomerSign',
      // 'taskWarm',
      'taskWarmDate',
      // 'taskDeadline',
      'taskResponsible',
      // 'taskUrgency',
    ];
    return (
      <div className={this.state.activeFilter ? 'advanced-filters-fix' : ''}>
        {this.state.formPermission ? <IndexTasks closePermission={this.closePermission} /> : null}
        {!isNull(this.state.showCreate) ?
          <NewTaskCreateForm
            quick={false}
            close={this.setStateModalCreateTask}
            profileId={this.state?.profile?.data?.id}
          />
          : ""
        }
        <div className='content-block'>
          <div className='top-page-block'>
            <h1 className='page-title'>{translate(`tasks_tasks`)}</h1>
            <div className=' top-button-area '>
              {PermissionService.actionForRoute(pm.TASKS_TASKS, PermissionService.storeRoute()) ?
                <React.Fragment>
                  <div className="button button--turquoise"
                    onClick={this.exportToExcel('tasks')}
                  >
                    {translate('export_excel_btn')}
                  </div>
                </React.Fragment>
                : ""
              }
            </div>
          </div>
        </div>

        <MagicFilterPanels
          mf={ this.mf }
          show={ true }
          translate={ translate }
        />

        <div className='content-block'>
          <div className='table-wrapper'>
            <div className='position-relative'>

              <Loader
                loaded={this.state.loaded}
                loading={(<Preloader scale={1}/>)}
                translate={translate}
              >
                <TasksMagicTable
                  data={ this.state?.data }
                  accountLabels={ this.state?.accountLabels }
                  accountLastContacts={ this.state?.accountLastContacts }
                  accountTypes={ this.state?.accountTypes }
                  taskStatuses={ this.props?.tasksStatus }
                  onDataLoad={ this.onDataLoad }
                  updateCustomerSign={ updateCustomerSign }
                  mf={ this.mf }
                  userRoleId = { this.state?.profile?.data?.role_id }
                  updateTask = { this.updateTask }
                  handleClickOutsideStatus = { this.handleClickOutsideStatus }
                  openStatusEdit = { this.openStatusEdit }
                  editStatus = { this.state.editStatus }
                  userDepartmentId = { this.state?.profile?.data?.permission_template_id }
                  head={ head }
                  // order={ async (field) => {
                  //   await this.service?.orderSave(field);
                  //   await this.onDataLoad();
                  // } }
                  // orderTo={ (field) => this.service?.orderTo?.(field) }
                  enums={this.state?.enums?.data}
                />

                <PagePanel
                  filter={this.state.pages.filter}
                  take={this.state.pages.take}
                  total={this.state.pages.total}
                  variance={this.state.pages.variance}
                  page={this.pageId()}
                  onClick={this.onPageClick}
                  doText={this.onPageText}
                  doTo={(pageId) => `?page=${ pageId }`}
                />
              </Loader>
            </div>

            <SideBarTaskList />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (store) => {
  return {
    // enums: store.enums.enums,
    // user: store.user,
    output: store.tasks.output,

    sort: store.tasks.sort,
    options: store.tasks.options,
    filterHash: store.tasks.filterHash,
    tasksTypes: store.tasks.tasksTypes,
    tasksStatus: store.tasks.tasksStatus,
  };
};

Tasks.propTypes = {
  user: USER_PROP_TYPES,
  output: OUTPUT_PROP_TYPES,
};

export default withLocalize(withRouter(connect(
  mapStateToProps,
  (dispatch) =>
    bindActionCreators({
      // setEnums,
      // getTasksTypes,
      getTasksStatus
    }, dispatch)
)(Tasks)));
