import React, { Component, } from "react";
import { withLocalize, } from "react-localize-redux";
import { Input, } from "../../../../../../components";
import { setErrorClass, } from "../../../../../../utils/helpers";
import {
	onValidDigits,
	onValidPercantages,
} from "../../helpers/validation-helpers/onValidHelpers";

class PayoutRateBlockRevshare extends Component {
	constructor (props) {
		super(props);
	}

	save = async state => new Promise(next => this.setState(state, next));

	handleOnChange = fieldName => async (event) => {
		const { setInputsValues, clearErrorOnChange, } = this.props;
		const { name, value, } = event.target;

		clearErrorOnChange(event);
		try {
			switch (fieldName) {
				case "grace_period": {
					await setInputsValues(name, value);
					break;
				}
				case "payout_rate_for_grace_period": {
					await setInputsValues(name, value);
					break;
				}
				case "min_req_fullfillment_period": {
					await setInputsValues(name, value);
					break;
				}
				default: {
					break;
				}
			}
		} catch (e) {
			throw new Error(e);
		}
	};

	renderBlockInputs = () => {
		const {
			errors,
			translate,
			formData,
			fieldsIsNotEdit,
			isViewMode,
		} = this.props;
		const gracePeriodValue = formData.grace_period.toString() === "0";
		const disabled = fieldsIsNotEdit || isViewMode;

		return (
			<>
				<div className="w-30 mr-35 field">
					<Input
						className={ "field__input" }
						disabled={ disabled }
						isRequired={ true }
						label={ translate("new_partners_condition_grace_period_days") }
						name="grace_period"
						placeholder={ translate(
							"new_partners_condition_grace_period_days_placeholder",
						) }
						value={ `${ formData.grace_period }` }
						wrapperClassName={ `${ setErrorClass(
							errors,
							"grace_period",
						) } field__input` }
						onChange={ this.handleOnChange("grace_period") }
						onValid={ value => onValidDigits(value, 1000) }
					/>
				</div>
				<div className="w-30 mr-35 field">
					<Input
						className={ "field__input" }
						disabled={ gracePeriodValue || disabled }
						isRequired={ true }
						label={ translate("new_partners_condition_grace_period_payment") }
						name="payout_rate_for_grace_period"
						placeholder={ translate(
							"new_partners_condition_grace_period_payment_placeholder",
						) }
						value={
							gracePeriodValue ? "100" : formData.payout_rate_for_grace_period
						}
						wrapperClassName={ `${ setErrorClass(
							errors,
							"payout_rate_for_grace_period",
						) } field__input` }
						onChange={ this.handleOnChange("payout_rate_for_grace_period") }
						onValid={ value => onValidPercantages(value, 1000) }
					/>
				</div>
				<div className="w-30 mr-35 field">
					<Input
						className={ "field__input" }
						disabled={ disabled }
						isRequired={ true }
						label={ translate("new_partners_condition_fullfillment_period_days") }
						name="min_req_fullfillment_period"
						placeholder={ translate(
							"new_partners_condition_fullfillment_period_days_placeholder",
						) }
						value={ formData.min_req_fullfillment_period }
						wrapperClassName={ ` ${ setErrorClass(
							errors,
							"min_req_fullfillment_period",
						) } field__input` }
						onChange={ this.handleOnChange(
							"min_req_fullfillment_period",
						) }
						onValid={ value => onValidDigits(value, 1000, 1) }
					/>
				</div>
			</>
		);
	};

	render () {
		const { translate, } = this.props;

		return (
			<>
				<div className="payout-rate-list__payout_table-forbidden-title">
					<p className={ "payout-rate-list__payout_block-title" }>
						{translate("new_partners_condition_payout_percentage_revshare")}
					</p>
				</div>
				<div className="new-partners-conditions__popup mb-20">
					{this.renderBlockInputs()}
				</div>
			</>
		);
	}
}

export default withLocalize(PayoutRateBlockRevshare);