import React from "react";
import PermissionService, {
	MODULE_KEYS as pm,
} from "../../../../services/PermissionService";

import Symbols from './Symbols';
import SymbolsLists from './SymbolsLists';
import SymbolsGroups from './SymbolsGroups';
import AccountGroupsLists from './AccountGroupsLists';
import CountryListWithPayoutRates from './CountryListWithPayoutRates';
import PayoutsPartnersRemuneration from './PayoutsPartnersRemuneration';
import AffiliatePrograms from './AffiliatePrograms';
import CalculationDashboard from "./CalculationsDashboard/CalculationDashboard";
import CalculationPayoutDashboard from "./CalculationsPayoutsDashboard/CalculationPayoutDashboard";
import PartnersWaitingSwitch from "./PartnersWaitingSwitch/PartnersWaitingSwitch";
import ReferalsWaitingSwitch from "./RefferalsWaitingSwitch/ReferalsWaitingSwitch";

export const affiliateProgramsManagementConfig = [
	{
		permission: pm.PARTNERS_PROGRAMS,
		action: PermissionService.indexRoute(),
		component: <AffiliatePrograms />,
		path: [
			'/partners/affiliate-programs-management/affiliate-programs',
		],
	},
	{
		permission: pm.PARTNERS_PAYOUTS_RENUMERATIONS,
		action: PermissionService.indexRoute(),
		component: <PayoutsPartnersRemuneration />,
		path: [
			'/partners/affiliate-programs-management/conditions-for-accruals',
		],
	},
	{
		permission: pm.PARTNERS_PAYOUT_RATE_LIST,
		action: PermissionService.indexRoute(),
		component: <CountryListWithPayoutRates />,
		path: [
			'/partners/affiliate-programs-management/payout-rate-lists',
		],
	},
	{
		permission: pm.PARTNERS_SYMBOLS,
		action: PermissionService.indexRoute(),
		component: <Symbols />,
		path: [
			'/partners/affiliate-programs-management/symbols',
		],
	},
	{
		permission: pm.PARTNERS_SYMBOLS_GROUP,
		action: PermissionService.indexRoute(),
		component: <SymbolsGroups />,
		path: [
			'/partners/affiliate-programs-management/symbols-groups',
		],
	},
	{
		permission: pm.PARTNERS_SYMBOLS_LISTS,
		action: PermissionService.indexRoute(),
		component: <SymbolsLists />,
		path: [
			'/partners/affiliate-programs-management/symbols-lists',
		],
	},
	{
		permission: pm.PARTNERS_GROUP_LISTS,
		action: PermissionService.indexRoute(),
		component: <AccountGroupsLists />,
		path: [
			'/partners/affiliate-programs-management/account-groups-lists',
		],
	},
	{
		permission: pm.PARTNERS_DASHBOARD,
		action: PermissionService.indexRoute(),
		component: <CalculationDashboard />,
		path: [
			'/partners/dashboards/calculations-dashboard',
		],
	},
	{
		permission: pm.PARTNERS_REQUESTS,
		action: PermissionService.indexRoute(),
		component: <CalculationPayoutDashboard />,
		path: [
			'/partners/dashboards/payouts-dashboard',
		],
	},
	{
		permission: pm.PARTNERS_REQUESTS,
		action: PermissionService.indexRoute(),
		component: <PartnersWaitingSwitch />,
		path: [
			'/partners/dashboards/partners-waiting-switch',
		],
	},
	{
		permission: pm.PARTNERS_REQUESTS,
		action: PermissionService.indexRoute(),
		component: <ReferalsWaitingSwitch />,
		path: [
			'/partners/dashboards/referrals-waiting-switch',
		],
	},
];