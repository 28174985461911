import React from "react";

export default class SelectTemplatePermissions extends React.Component {
    constructor (props) {
        super(props);
    }

    render () {
        return (
            <SelectComponent
                onChange = { (value) => this.setState({ value }) }
                options = { this.props.templatePermissionData }
                value = { this.props.value }
                updateIndexValueSelect = {this.props.updateIndexValueSelect}
                updateIdTemplatePermissions = {this.props.updateIdTemplatePermissions}
            />
        );
    }
}

class SelectComponent extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    handleDropdownClick = () => {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    };

    updateValueSelect = (index) => {
        this.props.updateIndexValueSelect(index);
    };

    updateValueTemplate = (index) => {
        this.props.updateIdTemplatePermissions(index);
    };

    render () {
        let cls = 'select';

        if (this.state.isOpen) {
            cls += ' _active';
        }

        return (
            <div className = 'select_component'>
                <div
                    className = { cls }
                    onClick = { this.handleDropdownClick }>
                    <div className = 'select-selected' style={{textOverflow: 'ellipsis', overflow: 'hidden'}}>{this.props.value.text}</div>
                </div>
                {this.state.isOpen && <div className = 'select-dropdown' style={{maxHeight:'170px', overflowX:'auto', overflowY: 'overlay'}}>

                    {this.props.options.map((option, index) => {

                        return (
                            <SelectOption
                                key = { index } text = { option.text } onClick = { (e) => {
                                    this.props.onChange(option);
                                    this.setState({ isOpen: false });
                                    this.updateValueSelect(index);
                                    this.updateValueTemplate(option.id);
                                    e.stopPropagation();
                                } }
                            />);
                    })}

                </div>}
            </div>
        );
    }
}

class SelectOption extends React.Component {
    constructor (props) {
        super(props);

        this.state = {};
    }

    render () {
        return (
            <div
                className = 'select-option'
                onClick = { this.props.onClick }>
                {this.props.text}
            </div>
        );
    }
}
