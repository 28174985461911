const ObservableService = (() => {
	let items = {};

	return {
		init () {

		},
		emit (event, data = {}) {
			return items?.[event] ? Promise.all(items[event].map(async e => await e(data))) : false;
		},
		subscribe (event, listener) {
			items = { ...items, [event]: [ ...items?.[event] ? [ ...items[event], ] : [], listener, ], };

			return true;
		},
		unsubscribe (event, listener) {
			if (!items?.[event]) {
				return false;
			}
			const index = items[event].indexOf(listener);

			if (!(index >= 0)) {
				return false;
			}

			return items[event].splice(index, 1);
		},
		getEvents () {
			return items;
		},
		getListeners (event) {
			return items?.[event] ? items[event] : false;
		},
	};
})();

export default ObservableService;