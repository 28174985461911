export const support = {
  //BreadCrumbs
  "support_link_title": ['Support', 'Поддержка', 'Підтримка'],
  "support_link_request": ['Ticket', 'Обращение', 'Звернення'],
  //  PagePanel
  "support_pages_items": ['Items', 'Записи', 'Записи'],
  "support_pages_filtered": ['Items after filtration', 'Записей после фильтрации', 'Записів після фільтрації'],
  "support_pages_total": ['Items total', 'Записей в целом', 'Записів в цілому'],

  //SUPPORT LIST
    "support_list_title":         ['Support', 'Поддержка', 'Підтримка'],
    "support_list_id":            ['ID', 'ID', 'ID'],
    "support_list_subject":       ['Subject', 'Тема', 'Тема'],
    "support_list_client":        ['Client', 'Клиент', 'Клієнт'],
    "support_list_call":          ['Phone', 'Телефон', 'Телефон'],
    "support_list_state":         ['State of ticket', 'Состояние обращения', 'Стан звернення'],
    "support_list_created_at":    ['Date and time created', 'Дата и время создания', 'Дата і час створення'],
    "support_list_client_status": ['Client status', 'Статус клиента', 'Статус клієнта'],
    "support_list_updated_at":    ['Date and time modified', 'Дата и время изменения', 'Дата і час зміни'],
    "support_list_sub_status":    ['Substatus', 'Подстатус', 'Підстатус'],
    "support_list_satisfaction":  ['Degree of satisfaction', 'Степень удовлетворенности', 'Cтупінь задоволеності'],
    "support_list_close":         ['Close ticket', 'Закрыть обращение', 'Закрити звернення'],
    "support_list_closed":        ['Closed', 'Закрыто', 'Закрито'],
    "support_list_responsible":   ['Responsible', 'Ответственный', 'Відповідальний'],
  "support_list_not_responsible": ['Not responsible', 'Нету ответственного', 'Немає відповідального'],
  "support_list_client_manager":  ['Manager', 'Менеджер', 'Менеджер'],
  //SUPPORT CONFIRM
  "support_confirm_title":        ['Close ticket?', 'Закрыть обращение?', 'Закрити звернення?'],
  "support_confirm_accept":       ['Yes', 'Да', 'Так'],
  "support_confirm_reject":       ['No', 'Нет', 'Ні'],

  //ONE SUPPORT SUPPORT
  // CARD INFO
  "support_card_subject": ['Subject', 'Тема', 'Тема'],
  "support_card_description": ['Description', 'Описание', 'Опис'],
  // "support_card_attached__files": ['Attached files', 'Прикрепленные файлы', 'Прикріплені файли'],
  "support_card_client": ['Client', 'Клиент', 'Клієнт'],
  "support_card_client_status": ['Client status', 'Статус клиента', 'Статус клієнта'],
  "support_card_phone": ['Phone', 'Телефон', 'Телефон'],
  "support_card_call": ['Call', 'Позвонить', 'Зателефонувати'],
  "support_card_created_at": ['Date and time created', 'Дата и время создания', 'Дата і час створення'],
  "support_card_state": ['State', 'Состояние', 'Стан'],
  "support_card_responsible": ['Responsible', 'Ответственный', 'Відповідальний'],
  "support_card_close": ['Close ticket', 'Закрыть обращение', 'Закрити звернення'],
  "support_card_closed": ['Closed', 'Закрыто', 'Закрито'],
  "support_card_not_responsible": ['Not responsible', 'Без ответственного', 'Без відповідального'],


  //CARD CHAT
  "support_card_chat": ['Chat', 'Чат', 'Чат'],
  "support_card_textarea_ph": ['Write message...', 'Напишите сообщение...', 'Напишіть повідомлення...'],
  "support_card_send": ['Send', 'Отправить', 'Відправити'],
  "support_card_message_edit": ['Edit', 'Редактировать', 'Редагувати'],
  "support_card_error_length": ['Message can not be longer than 1000 symbols', 'Сообщение не должно содержать больше 1000 символов', 'Повідомлення не може містити більше 1000 символів'],
  "support_card_cancel": ['Cancel', 'Отменить', 'Відмінити'],
  "support_card_manager_sign": ['Manager','Менеджер', 'Менеджер'],
  "support_card_client_sign": ['Client', 'Клиент', 'Клієнт'],
};
