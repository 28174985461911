import React, { lazy } from 'react';
import PermissionService, { MODULE_KEYS as pm } from "../../services/PermissionService";
// const Contests = lazy( () => import( './components/Contests' ) );
import Contests from './components/Contests';



export const contestsConfig = [
	{
		permission: pm.CONTESTS_CONTESTS,
		action: PermissionService.showRoute(),
		component: <Contests activeTab='contest' />,
		path: [
			'/contests/contest/:contestId/:isEdit',
			'/contests/contest/:contestId',
		],
	},
	
	{
		permission: pm.CONTESTS_CONTESTS,
		action: PermissionService.indexRoute(),
		component: <Contests activeTab='contests' />,
		path: [
			'/contests/filter/:filterId',
			'/contests',
		],
	},	
];
