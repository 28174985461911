import {
  itemsBuild,
  itemsDrain,
  itemsResort,
  CFG_ITEM_TYPE,
} from '../Config';
// import {} from '../Mentor/withDropDown';

export default {
  id: 64,
  ...[

    // filters
    ...[ {
      f: 'core.system_accounts.id',
      id: 'f:saId',
    }, {
      f: [ 'core.account.surname', 'core.account.name', ],
      id: 'f:aFullname',
      o: 'like',
    }, {
      f: 'core.system_accounts.created_at',
      id: 'f:saCreated',
    }, {
      f: 'core.system_accounts.updated_at',
      id: 'f:saUpdated',
    }, {
      f: 'core.system_accounts.type',
      id: 'f:saType',
    }, {
      f: 'core.system_accounts.acc_ccy',
      id: 'f:saCurrency',
    }, {
      f: 'core.system_accounts.status',
      id: 'f:saStatus',
    }, {
      f: 'core.dictionary_licenses.alias',
      id: 'f:tLicenseAlias',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

    // orders
    ...[ {
      f: '',
      id: 'o:',
      off: true, // TODO:
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

    // panels
    ...[ {
      ii: 'f:saId',
      t: 'magic_filter_translate_list_accounts_systems_id',
    }, {
      ii: 'f:aFullname',
      t: 'magic_filter_translate_list_accounts_systems_account',
    }, {
      ii: 'f:saCreated',
      t: 'magic_filter_translate_list_accounts_systems_created_at',
    }, {
      ii: 'f:saUpdated',
      t: 'magic_filter_translate_list_accounts_systems_updated_at',
    }, {
      ii: 'f:saType',
      t: 'magic_filter_translate_list_accounts_systems_type',
    }, {
      ii: 'f:saCurrency',
      t: 'magic_filter_translate_list_accounts_systems_currency',
    }, {
      ii: 'f:saStatus',
      t: 'magic_filter_translate_list_accounts_systems_status',
    }, {
      ii: 'f:tLicenseAlias',
      t: 'magic_filter_translate_task_license_alias',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

  ]
  .map(itemsBuild)
  .reduce(itemsDrain, [])
  .reduce(itemsResort, {}),

  path: 'core/system-accounts',
  widget: false,
};