import {
    GET_DEPARTMENTS_BY_USERS,
    GET_FUNNEL,
    GET_SALES_FUNNEL,
    GET_FUNNEL_USERS
} from '../../../apiRoutes'
import { securedRequest } from '../../../services/RequestService'
import { get } from '../../../components/Magic'

export const getAllDepartmentsByUserId = (id) => {
    return securedRequest.get(`${GET_DEPARTMENTS_BY_USERS}/${id}`).then(
        (response) => Promise.resolve(response),
        (error) => Promise.reject(error.response.data)
    );
};
export const getSalesFunnel = async (id, options) => await get(`${GET_SALES_FUNNEL}?user_id=${id}`, options);
export const getWidgetClients = async (options) => {
    const { user_ids } = options;

    delete options.user_ids;

    return await get(`${GET_FUNNEL}${user_ids ?? ''}`, options)
};

export const getFunnelUsers = async () => await get(GET_FUNNEL_USERS);
export const getFunnel = async (options) => {
    const { user_ids } = options;

    user_ids && delete options.user_ids;

    return await get(`${GET_SALES_FUNNEL}${user_ids ?? ''}`, options);
}

// export const getBriefCase = async (params) => await get(`/api/widget/core/sales-funnel-active?${params}`);

export const getConversionDistribution = async () => await get(`/api/widget/core/conversion-distribution-info`);

export const getAvailableWidgetsInfo = async () => await get(`/api/core/widget-user/by-manager`);

export const getRopDepositsData = async (options) => await get('/api/widget/core/rop-deposits', options);
export const getManagerNamesData = async (options) => await get('/api/widget/core/user-request/users', options);
export const getManagerName = async (id) => await get(`/api/core/user/${id}`);
export const getUserActivityData = async (dateFrom, dateTo, selectedUser,) => await get(`/api/widget/core/user-request?date_from=${dateFrom}&date_to=${dateTo}&user_id=${selectedUser}`);
export const getRopDepositsTopAmounts = async (options) => await get(`/api/widget/core/rop-deposits-top-amounts`, options);
export const getRopInOutData = async (options) => await get(`/api/widget/core/rop-in-out`, options);
export const getRopActivations = async (options) => await get('/api/widget/core/rop-activations', options);
export const getBriefcaseData = async (options) => await get(`/api/widget/core/briefcase-percentage-processing`, options);
export const getRetentionRatings = async (options) => await get(`/api/widget/core/retention-rating-in-out`, options)
export const getBriefcasePopupData = async (options) => await get(`/api/widget/core/briefcase-percentage-processing/managers`, options);
export const getCurrentBriefcaseData = async (options) => await get(`/api/widget/core/briefcase-percentage-processing-current-briefcase`, options);
export const getCurrentBriefcaseUsers = async (options) => await get(`/api/widget/core/briefcase-percentage-processing-current-briefcase/users`, options);
export const getConversionRating = async (options) => await get(`/api/widget/core/conversion-rating`, options);
export const getRetentionInOut = async (options) => await get(`/api/widget/core/retention-in-out`, options);
export const getDepositsPopupData = async (options) => await get(`/api/widget/core/rop-deposits/details`, options);
export const getRetentionPercentConversion = async (options) => await get(`/api/widget/core/retention-my-percent-conversion`, options);
export const getRetentionMyPercentConversionCurrentBriefcase = async (options) => await get(`/api/widget/core/retention-my-percent-conversion-current-briefcase`, options);
export const getConversionActivations = async (options) => await get(`/api/widget/core/conversion-activations`, options);
export const getColdUsers = async (options) => await get(`/api/widget/core/briefcase-percentage-processing/coldAccount`, options);
export const getMyStatistics = async (options) => await get(`/api/widget/core/conversion-my-percent-conversion`, options);
export const getSource = async (options) => (await get(`/api/widget/statistics/registration-statistics-for-advertising-channels/source`, options))?.data;
export const getMedium = async (options) => (await get(`/api/widget/statistics/registration-statistics-for-advertising-channels/medium`, options))?.data;
export const getCampaign = async (options) => (await get(`/api/widget/statistics/registration-statistics-for-advertising-channels/campaign`, options))?.data;
export const getMediumWithSourceId = async (utm_source_id, date_from, date_to) => {
    const url = new URL(`/api/widget/statistics/registration-statistics-for-advertising-channels/medium`, location.href);
    url.searchParams.set('utm_source_id', utm_source_id);
    url.searchParams.set('date_from', date_from);
    url.searchParams.set('date_to', date_to);
    return (await get(url))?.data;
};
export const getCampaignWithSourceAndMediumId = async (utm_source_id, utm_medium_id, date_from, date_to) => {
    const url = new URL(`/api/widget/statistics/registration-statistics-for-advertising-channels/campaign`, location.href);
    url.searchParams.set('utm_source_id', utm_source_id);
    url.searchParams.set('utm_medium_id', utm_medium_id);
    url.searchParams.set('date_from', date_from);
    url.searchParams.set('date_to', date_to);
    return (await get(url))?.data;
};
export const getCampaignWithMediumId = async (utm_medium_id, date_from, date_to) => {
    const url = new URL(`/api/widget/statistics/registration-statistics-for-advertising-channels/campaign`, location.href);
    url.searchParams.set('utm_medium_id', utm_medium_id);
    url.searchParams.set('date_from', date_from);
    url.searchParams.set('date_to', date_to);
    return (await get(url))?.data;
};
export const getCampaignWithSourceId = async (utm_source_id, date_from, date_to) => {
    const url = new URL(`/api/widget/statistics/registration-statistics-for-advertising-channels/campaign`, location.href);
    url.searchParams.set('utm_source_id', utm_source_id);
    url.searchParams.set('date_from', date_from);
    url.searchParams.set('date_to', date_to);
    return (await get(url))?.data;
};
export const getSourceDropDown = async () => (await get(`/api/widget/statistics/registration-statistics-for-advertising-channels/sourceDropDown`))?.data;
export const getSourceDropDownWithMediumId = async (utm_medium_id) => {
    const url = new URL(`/api/widget/statistics/registration-statistics-for-advertising-channels/sourceDropDown`, location.href);
    url.searchParams.set('utm_medium_id', utm_medium_id);
    return (await get(url))?.data;
};
export const getMediumDropDown = async () => (await get(`/api/widget/statistics/registration-statistics-for-advertising-channels/mediumDropDown`))?.data;
export const getMediumDropDownWithSourceId = async (utm_source_id) => {
    const url = new URL(`/api/widget/statistics/registration-statistics-for-advertising-channels/mediumDropDown`, location.href);
    url.searchParams.set('utm_source_id', utm_source_id);
    return (await get(url))?.data;
};
