import { PropTypes, } from 'prop-types';
import React, { Component } from 'react';
import NotificationService from "../../../../../services/NotificationService";
import { userService } from "../../../../../services/UserDataService";
import MagicTooltip from "../../../../tima/components/Magic/MagicTooltip/MagicTooltip";
class ManagerNotificationRowPush extends Component {

    static propTypes = {
        item: PropTypes.object.isRequired,
        profile: PropTypes.object.isRequired,
        updateItem: PropTypes.func.isRequired,
    };

    setChange = async() => {
        let newValue = this.props.item.push;

        switch (this.props.item.push)
        {
            case 1: newValue = 2; break;
            case null:
            case 2:
                newValue = 3; break;
            case 3: newValue = 1; break;
        }

        try {
            let data = null;
            if (this.props.item.id === null) {
                data = await userService.createUserEventSettings({
                    user_id: this.props.profile.id,
                    event_id: this.props.item.event_id,
                    push: newValue,
                    email: 2,
                });
            } else {
                data = await userService.updateUserEventSettings({push: newValue}, this.props.item.id);
            }

            if (data) {
                await this.props.updateItem(data);
            }
        } catch (error) {
            NotificationService.errors(error);
        }
    }

    render()
    {
        let status = '';
        switch (this.props.item.push) {
            case 1: status = 'active'; break;
            case 3: status = 'active-and-show'; break;
            default:
                status = 'disable';
                break;
        }

        return (
            <div
                className = { `notification-toggle bell icon icon_push icon_${status} ${status}` }
                onClick={async () => await this.setChange()}>
            </div>
        )
    }
}

export default ManagerNotificationRowPush;