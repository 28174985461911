import React from 'react';
import { withLocalize } from "react-localize-redux";
import moment from "moment";
import PropTypes from "prop-types";
import OneDayTasksBlock from "./TaskBlock/OneDayTasksBlock";

class CalendarMonthMagic extends React.Component {
    static propTypes = {
        prevMonth: PropTypes.func.isRequired,
        nextMonth: PropTypes.func.isRequired,
    };

    constructor (props) {
        super(props);

        this.state = {
            tasks:         props.tasks,
            locale:        props.activeLanguage?.code,
            selectedMonth: props.selectedMonth,
        };
    }

    componentDidMount () {

    }

    save = async (state) => new Promise((next) => this.setState(state, next));

    renderCalendarHeader = () => {
        const { selectedMonth, locale } = this.state;
        const dateFormat = "MMMM YYYY";

        return (
            <div className='header row flex-middle'>
                <div className='col col-start'>
                    <div className='icon' onClick={this.props.prevMonth}>
                        <i className='la la-angle-left'/>
                    </div>
                </div>
                <div className='col col-center'>
                    <span>{moment(selectedMonth).locale(locale).format(dateFormat)}</span>
                </div>
                <div className='col col-end'>
                    <div className='icon' onClick={this.props.nextMonth}>
                        <i className='la la-angle-right'/>
                    </div>
                </div>
            </div>
        );
    };

    renderCalendarDays = () => {
        const { locale } = this.state;
        const daysInWeek = [];

        for (let dayIndex = 1; dayIndex <= 7; dayIndex++) {
            const targetDate = moment().locale(locale).day(dayIndex);
            const targetWeekDayName = targetDate.format('dddd');

            daysInWeek.push(
                <div className={`cell`}
                     key={`header-${dayIndex}`}
                >
                    {targetWeekDayName}
                </div>
            );
        }

        return <div className='days row'>{daysInWeek}</div>;
    };

    renderCalendarTable = () => {
        const { avatar } = this.props;
        const { selectedMonth, locale, tasks = {} } = this.state;
        const currentDate = moment();
        const monthStart = moment(selectedMonth).startOf('month');
        const monthEnd = moment(selectedMonth).endOf('month');
        const weekDayStart = moment(monthStart).startOf('week');
        const weekDayEnd = moment(monthEnd).endOf('week');

        const tableRows = [];

        let targetDay = weekDayStart;

        while (targetDay <= weekDayEnd) {
            const tableRow = [];

            for (let dayIndex = 1; dayIndex <= 7; dayIndex++) {
                const targetDayNumber = moment(targetDay).format('D');
                const isSameMonth = moment(selectedMonth).isSame(targetDay, 'month');
                const isSameDay = moment(currentDate).isSame(targetDay, 'day');
                const classToAdd = !isSameMonth ? 'disabled' : isSameDay ? 'selected' : '';

                tableRow.push(
                    <div className={`cell ${classToAdd}`}
                         key={`tableCell-${targetDay}`}>

                        {isSameMonth ? (
                            <OneDayTasksBlock
                                avatar={avatar}
                                targetDay={targetDay}
                                tasks={tasks?.[targetDayNumber]}
                            />) : null
                        }

                        <span className='number'>{targetDayNumber}</span>
                    </div>
                );

                targetDay = moment(targetDay).add(1, 'days');
            }

            tableRows.push(
                <div className='row' key={`tableRow-${targetDay}`}>
                    {tableRow}
                </div>
            );
        }

        return <div className='calendar-table'>{tableRows}</div>;
    };

    render () {
        return (
            <div className='calendar'>
                {this.renderCalendarHeader()}
                {this.renderCalendarDays()}
                {this.renderCalendarTable()}
            </div>
        );
    }
}

export default withLocalize(CalendarMonthMagic);
