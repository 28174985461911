export const SET_SESSION_DATA = 'SET_SESSION_DATA';
export const UNSET_SESSION_DATA = 'UNSET_SESSION_DATA';

// export const SET_SESSION_TOKEN = 'SET_SESSION_TOKEN';
// export const SET_PERMISSION_HASH = 'SET_PERMISSION_HASH';
// export const SET_PERMISSION_HASH_FROM_LS = 'SET_PERMISSION_HASH_FROM_LS';
// export const UNSET_SESSION_TOKEN = 'UNSET_SESSION_TOKEN';

// export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
// export const LOGIN_VERIFY_FAILED = 'LOGIN_VERIFY_FAILED';
// export const LOGIN_FAILED_NO_ACTIVATION = 'LOGIN_FAILED_NO_ACTIVATION';
// export const LOGIN_FAILED_NO_USER = 'LOGIN_FAILED_NO_USER';
// export const LOGIN_FAILED_BAD_CREDENTIALS = 'LOGIN_FAILED_BAD_CREDENTIALS';
// export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const GET_PROFILE = 'GET_PROFILE';
export const SET_HASH = 'SET_HASH';
// export const LOGOUT_FAILED = 'LOGOUT_FAILED';
