import React from 'react';
import { withLocalize } from 'react-localize-redux';

class DistributionPopUp extends React.Component {
    render () {
        const { translate, response } = this.props;

        return (
            <div className = 'modal_delegation'>
                <div className = 'modal_delegation-body'>
                    <div className = 'modal_delegation-close' onClick={() => this.props.close()}><i className = 'la la-close' /></div>
                    <div className = 'modal_delegation-header'>
                        <h3>{translate(`distribute`)}</h3>
                    </div>
                    <div className = 'modal_delegation-data'>
                        <p>{translate('group_assignment_all')}: {response.all}</p>
                        <p>{translate('group_assignment_success')}: {response.set}</p>
                        <p>{translate('group_assignment_failed')}: {response.not_set}</p>
                        {response.messages.length > 0 && response.messages.map((item, i) => <p key = { i } className="red">{item.name} ({item.count})</p>)}
                    </div>
                    <div className='modal_delegation-footer'>
                        <div className='button filter_btn-remove_delegation' onClick = {() => this.props.close() }>
                            <span>Ok</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withLocalize(DistributionPopUp);
