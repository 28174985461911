import React, { Component, } from "react";
import { withRouter, } from "react-router-dom";
import { withLocalize, } from "react-localize-redux";

import { REBATE_EXPORT_FIELDS, } from "./consts/REBATE_EXPORT_FIELDS";

import PermissionService from "../../../../services/PermissionService";
import { securedRequest, } from "../../../../services/RequestService";
import { showExportToExcelError, } from "../../../../components/Magic";

import { HorizontalMenu, } from "../../components";
import PartnersSystemHeader from "../ components/PartnersSystemHeader";
import RebateTable from "./table/RebateTable";

import * as configBreadcrumbs from "./config/BreadcrumbsConfig";
class Rebate extends Component {
	constructor (props) {
		super(props);

		this.state = {
			filter_id: 0,
		};
	}

	save = async state => new Promise(next => this.setState(state, next));

	handleFilterIdChange = filter_id => this.save({ filter_id, });

	getPermissions = (action) => {
		const pm = PermissionService.calc([ {
			path: [ 'partners', 'rebate_projects', ],
			key: 'user',
		}, ]);

		return pm.user.access(action);
	}

	exportToExcel = activeTab => (event) => {
		if (activeTab) {
			const { filter_id, } = this.state;

			const globalUrl = `/api/partners/rebate-projects`;
			const exportUrl = `/export-to-excel`;
			let url = "";
			let file_name = "";

			switch (activeTab) {
				case "rebate": {
					url = `${ globalUrl }${ exportUrl }`;
					file_name = `Rebate_projects`;
					break;
				}
				default: {
					break;
				}
			}
			const options = {
				fields: REBATE_EXPORT_FIELDS[activeTab],
				file_name,
			};

			if (filter_id) {
				options.filter_id = filter_id;
			}

			securedRequest
				.post(url, options)
				.then((res) => {
					fileDownload(res.data, res.headers.filename);
				})
				.catch((error) => {
					showExportToExcelError(error);
				});
		}
	};

	renderRebateActions = (translate) => {
		const excel = this.getPermissions("exportToExcel");

		return (
			<div className=" top-button-area ">
				{excel && (
					<div
						className="button bordered"
						onClick={ this.exportToExcel("rebate") }
					>
						{translate(`managerPage_export_to_excel`)}
					</div>
				)}
			</div>
		);
	};

	render () {
		const { translate, } = this.props;
		const breadcrumbsItems = configBreadcrumbs.breadcrumbs4Rebate;

		return (
			<div className="affiliate-payouts">
				<PartnersSystemHeader
					actions={ this.renderRebateActions(translate) }
					breadcrumbsItems={ breadcrumbsItems }
					title={ 'partners_rebate_projects' }
					translate={ translate }
				/>
				<HorizontalMenu />
				<RebateTable
					getPermissions={ this.getPermissions }
					handleFilterIdChange={ this.handleFilterIdChange }
					translate={ translate }
				/>
			</div>
		);
	}
}

export default withRouter(withLocalize(Rebate));