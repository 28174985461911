import NotificationService from "../../../../services/NotificationService";
import {MagicError} from "../MagicRequest";

export const showExportToExcelError = (error) => {
    try {
        const magicError = new MagicError(error);
        magicError.showErrorNotification();
    } catch {
        NotificationService.error({
            title: "error",
            message: error.message ? error.message : (error.error ? error.error : 'Invalid error'),
            remove: false,
        });
    }
};
