import { DataService, } from "./DataService";
import {get,post,put} from "../components/Magic/helpers/MagicRequest";
import { GET_CALLS_BY_PHONE, SEND_CALL, GET_USER_CALL_SETTING } from '../modules/calls/apiRoutes';
import {hash as hashData} from "./CachingService";

class CallDataService extends DataService {
    constructor () {
        super([
            'calls',
            'sendCall',
            'userCallSettings',
            'updateUserCallSettings',
        ]);
        Object.freeze(this);
    }
    async calls ({ filterId, take, skip }) {
        const url = GET_CALLS_BY_PHONE;
        const data = await get(url, { filterId, take, skip });
        const calls = { data };
        await this.emit('calls', calls);

        return calls.data;
    }

    async sendCall ({ account_id }) {
        const url = SEND_CALL;
        const response = await post(url, { account_id });
        const serverAnswer = { response };
        await this.emit('sendCall', serverAnswer);

        return serverAnswer;
    }

    async userCallSettings (id = 1) {
        const url = `${GET_USER_CALL_SETTING}/${id}`;
        const { data: { calls: { user_calls_setting }}} = await get(url);
        const hash = await hashData(user_calls_setting);
        const userCallSettings = { user_calls_setting, hash };
        await this.emit('userCallSettings', userCallSettings);

        return userCallSettings;
    }

    async updateUserCallSettings (item, id = 1) {
        const url = `${GET_USER_CALL_SETTING}/${id}`;
        const response = await put(url, item);
        const updateUserCallSettings = { response, item };
        await this.emit('updateUserCallSettings', updateUserCallSettings);

        return updateUserCallSettings;
    }
}

export const callService = new CallDataService();
