import PaymentsLinkConfig from "./PaymentsLinkConfig";

export const breadcrumbs4Order = ( translate, orderId ) => {
    return PaymentsLinkConfig(
        translate,
        [
            'orders',
            'order',
        ],
        {
          orderId,
        }
    );
};

export const breadcrumbs4Orders = ( translate ) => {
    return PaymentsLinkConfig(
        translate,
        [
            'orders',
        ],
        { }
    );
};

