import React, { Component, } from "react";
import { withLocalize, } from "react-localize-redux";
import { Input, } from "../../../../../components";
import { setErrorClass, } from "../../../../../utils/helpers";
import {
	onValidPercantages,
	onValidDigits,
} from "../helpers/validation-helpers/onValidHelpers";

class PayoutRatesByLevel extends Component {
	constructor (props) {
		super(props);
	}

	save = async state => new Promise(next => this.setState(state, next));

	async componentDidUpdate () {}

	handleOnChange = (index, fieldName) => async (event) => {
		const { clearErrorOnChange, setNewRateValue, } = this.props;
		const { value, } = event.target;

		await clearErrorOnChange(event);

		try {
			switch (fieldName) {
				case "level_from": {
					await setNewRateValue("level_from", value, index);
					break;
				}
				case "level_to": {
					await setNewRateValue("level_to", value, index);
					break;
				}
				case "rate": {
					await setNewRateValue("rate", value, index);
					break;
				}
				default: {
					break;
				}
			}
		} catch (e) {
			throw new Error(e);
		}
	};

	renderPayoutRatesByLevelInputs () {
		const {
			translate, formData, errors, removeRate,
		} = this.props;
		const { multi_rates_list_rate_by_level: multiRates, } = formData;

		const isDisabled = index =>
			multiRates.length - 1 === index ? "" : "disabled_input";

		return multiRates.map((item, index) => {
			return (
				<div className="first-deposit-conditions__row">
					{index !== 0 ? (
						<button
							className="payout-rate-list__create-btn_btns-btn payout-rate-list__create-btn_btns-btn-remove mt-20 rm-range-item"
							onClick={ () => removeRate(index) }
						/>
					) : null}
					<Input
						isRequired
						disabled={isDisabled(index)}
						placeholder={translate(
							"new_partners_confition_level_from_including_placeholder",
						)}
						value={item.level_from}
						onChange={this.handleOnChange(index, "level_from")}
						onValid={value => onValidDigits(value, 10, 1)}
						name={`level_from_${index}`}
						// disabled={ !loadData || this.payoutRateListStatusInDB.new }
						wrapperClassName={`${setErrorClass(
							errors,
							`level_from_${index}`,
						)} payout-rate-list__payout_table-input`}
					/>
					<Input
						isRequired
						disabled={isDisabled(index)}
						placeholder={translate(
							"new_partners_confition_level_to_including_placeholder",
						)}
						value={item.level_to}
						onChange={this.handleOnChange(index, "level_to")}
						onValid={value => onValidDigits(value, 10, 1)}
						name={`level_to_${index}`}
						// disabled={ !loadData || this.payoutRateListStatusInDB.new }
						wrapperClassName={`${setErrorClass(
							errors,
							`level_to_${index}`,
						)} payout-rate-list__payout_table-input`}
					/>
					<Input
						isRequired
						disabled={isDisabled(index)}
						placeholder={translate(
							"new_partners_condition_grace_period_payment_placeholder",
						)}
						value={item.rate}
						onChange={this.handleOnChange(index, "rate")}
						onValid={value => onValidPercantages(value, 1000)}
						name={`rate_${index}`}
						// disabled={ !loadData || this.payoutRateListStatusInDB.new }
						wrapperClassName={`${setErrorClass(
							errors,
							`rate_${index}`,
						)} payout-rate-list__payout_table-input`}
					/>
				</div>
			);
		});
	}

	renderPayoutRatesByLevel () {
		const { translate, } = this.props;

		return (
			<div className="payout-rate-list__payout_table-block custom-horizontal-scroll pb-20">
				<div className="payout-rate-list__payout_table-forbidden mr-35">
					<div className="first-deposit-conditions__row">
						<div className="payout-rate-list__payout_table-title-bg  payout-rate-list__payout_table-pd0">
							<p className="payout-rate-list__payout_table-title">
								{translate("new_partners_confition_level_from_including")}
							</p>
						</div>
						<div className="payout-rate-list__payout_table-title-bg payout-rate-list__payout_table-pd0">
							<p className="payout-rate-list__payout_table-title">
								{translate("new_partners_confition_level_to_including")}
							</p>
						</div>
						<div className="payout-rate-list__payout_table-title-bg payout-rate-list__payout_table-pd0">
							<p className="payout-rate-list__payout_table-title">
								{translate("new_partners_confition_rate")}
							</p>
						</div>
					</div>
					{this.renderPayoutRatesByLevelInputs()}
				</div>
			</div>
		);
	}

	render () {
		const {
			translate,
			createNewPayoutRatesByLevelItem,
			formData,
			status,
			fieldsIsNotEdit,
		} = this.props;
		const multiRates = formData.multi_rates_list_rate_by_level;
		const hasMaxLevelTo = multiRates.some(item => item.level_to === "10");
		const hasMinRate = multiRates.some(item => item.rate === "0");
		const disabled = hasMaxLevelTo
			? "disabled"
			: hasMinRate
				? "disabled"
				: fieldsIsNotEdit
					? "disabled"
					: multiRates.length === 9
						? "disabled"
						: "";

		return (
			<>
				<div className="payout-rate-list__payout_table-forbidden-title">
					<p className={ "payout-rate-list__payout_block-title" }>
						{translate("new_partners_confition_rates_by_level")}
					</p>
					<button
						className={ `${ disabled } payout-rate-list__create-btn_btns-btn payout-rate-list__create-btn_btns-btn-join` }
						disabled={ disabled }
						onClick={ () => createNewPayoutRatesByLevelItem() }
					>
						{translate(`new_partners_condition_ftd_confirmation_add_range`)}
					</button>
				</div>
				<div className="payout-rate-list__payout_table custom-vertical-scroll">
					{this.renderPayoutRatesByLevel()}
				</div>
			</>
		);
	}
}

export default withLocalize(PayoutRatesByLevel);