import React from 'react';

import deleteImg from "../../../../images/blue-check.svg";
import { isNull } from 'lodash';
import {getAvatarComment, getIcon} from "../../services/TaskHelperService";
import { getCommentDocument } from '../../services/TaskRequestService';

const lineHeight = 18;

export default class DiscussionBlockComment extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            edit:          false,
            remove:        false,
            commentTask:   { ...this.props.commentTask },
            commentId:     '',
            comment_reply: false,
            images:        {},

        };
    }

    componentDidMount () {
        getAvatarComment(this, this.props.comment?.tasks?.task_comments?.user_id);
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        this.setState({
            commentTask: { ...nextProps.commentTask },
        });
    }

    editText = () => {
        this.setState({
            edit:      !this.state.edit,
            commentId: this.props.comment?.tasks?.task_comments?.id,
        });
    };

    removeText = () => {
        this.setState({
            commentId: this.props.comment?.tasks?.task_comments?.id,
            remove:    !this.state.remove,
        });
    };

    downloadFile = async (fileName) => {
        try {
            const { status, headers, data } = await getCommentDocument(fileName);
            const blob = new Blob([data], { type: headers['content-type'] });

            if (status > 199 && status < 300) {
                if (window.navigator.msSaveBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    const objectUrl = URL.createObjectURL(blob);

                    window.open(objectUrl);
                }
            }
        } catch (err) {
            console.error(err);
        }
    }

    getDescription (description) {
        const rx = /\#USER_ID:([0-9]+)\#/g;

        if (rx.test(description)) {
            description.match(rx).forEach((item) => {
                const id = item.match(/[0-9]+/)[0];
                const user = this.props.findUserById(id);

                description = user ? description.replace(/\#USER_ID:([0-9]+)\#/, `${user?.name ?? ''} ${user?.surname ?? ''}`) : description;
            });
        }

        return description;
    }

    renderAttachments = (item, i) => {
        const icon = getIcon(item.extension);

        return (
            <div key = { i } className="comment-attachment">
                {icon}
                <p onClick = { () => this.downloadFile(item.alias_name) }>
                    {item.file_name}
                </p>
            </div>
        );
    }

    renderReplyMessage = (replyMessage) => this.getDescription(replyMessage);

    render () {
        const comment = this.props.comment;
        return (
            <div className = 'discussion-list-item'>
                <div className = 'discussion-avatar'>
                    <img alt = 'avatar' src = { this.state.images.imageId } />
                </div>
                {/* {this.state.edit ?
                    <div className = 'discussion-info'>
                        <Form>
                            <textarea
                                rows = '5'
                                onChange = { this.props.setToStateComment }
                                name = 'description'
                                required
                                style = { { lineHeight: `${lineHeight}px` } }
                                defaultValue = { comment?.tasks?.task_comments?.description }
                            />
                        </Form>
                        <div className = 'discussion-bottom'>
                            <div className = 'discussion-time'>{comment?.tasks?.task_comments?.created_at}</div>
                            <div className = 'discussion-action'>
                                <span
                                    className = 'active' onClick = { () => {
                                        this.props.sendUpdateComment(this.props.id);
                                        this.editText();
                                    } }>{this.props.translate(`tasks_createform_save`)}</span>
                                {this.props.sort_new_parent_id.length -1 === this.props.lastComment ?
                                    <span
                                        className = { this.state.remove ? `active` : `` }
                                        onClick = { () => {
                                            this.props.deleteCommentAction(this.props.id);
                                            this.removeText();
                                        } }>{this.props.translate(`tasks_task_remove`)}</span>
                                    : null }
                            </div>
                        </div>
                    </div> : */}
                    <div className = 'discussion-info'>
                        <div
                            className = { this.state.remove ? `discussion-text remove core-user-role_id_${comment?.core?.user?.role_id}` : `discussion-text core-user-role_id_${comment?.core?.user?.role_id}` }
                            style = { { lineHeight: `${lineHeight}px` } }>
                            {!isNull(comment?.tasks?.task_comments?.parent_id) ?
                                <div
                                    className = { `discussion-parent-text_${comment?.core?.user?.role_id ?? 1}` } onClick = {
                                        () => {
                                            this.props.moveToParentComment(comment?.tasks?.task_comments?.parent_id);
                                        }
                                    }>
                                    {this.renderReplyMessage(this.props.sort_new_parent_id[comment?.tasks?.task_comments?.parent_id]?.tasks?.task_comments?.description)}
                                    {this.props.sort_new_parent_id[comment?.tasks?.task_comments?.parent_id]?.tasks?.task_comment_documents?.length > 0 ?
                                        this.props.sort_new_parent_id[comment?.tasks?.task_comments?.parent_id]?.tasks?.task_comment_documents.map((item, i) => this.renderAttachments(item, i))
                                    : null}
                                </div>
                                : ""}
                            <div className = 'discussion-children-text'>{this.getDescription(comment?.tasks?.task_comments?.description)}</div>
                            {comment?.tasks?.task_comment_documents?.length > 0
                                ? comment?.tasks?.task_comment_documents?.map((item, i) => this.renderAttachments(item, i))
                                : null}
                            <div className = { this.state.remove ? `delete-wrapper active` : `delete-wrapper` }>
                                <img alt = 'delete-img' onClick = { this.toggleDone } src = { deleteImg } />
                            </div>
                        </div>
                        <div className = 'discussion-bottom'>
                            <div className = 'discussion-time'>{comment?.tasks?.task_comments?.created_at}</div>
                            {this.props.userId === comment?.tasks?.task_comments?.user_id ? null : (
                                <div className = 'discussion-action'>
                                <span
                                    onClick = { async () => {
                                        await this.props.setParentId(this.props.comment?.tasks?.task_comments?.id);
                                        await this.props.setAutoFocusTrue();
                                        document.querySelector('input[name=description]').focus();
                                        // await this.setFocusForInput();
                                    } }>{this.props.translate(`task_reply`)}</span>
                                </div>
                            )}
                                {/* <span onClick = { this.editText }>{this.props.translate(`tasks_edit`)}</span>
                                {this.props.sort_new_parent_id.length -1 === this.props.lastComment ?
                                    <span
                                        className = { this.state.remove ? `active` : `` }
                                        onClick = { () => {
                                            this.removeText();
                                            this.props.deleteCommentAction(this.props.id);
                                        } }>{this.props.translate(`tasks_task_remove`)}</span>
                                    : null} */}

                        </div>
                    </div>
                {/* } */}
            </div>
        );
    }
}
