export const authLang = {
  "login": ["Login", "Войти", "Увійти"],
  "log_in": ["Log in", "Войти", "Увійти"],
  "email": ["Email", "Почта", "Пошта"],
  "password": ["Password", "Пароль", "Пароль"],
  "remember_me": ["Remember me", "Запомнить меня", "Запам'ятати мене"],
  "forgot_password": ["Forgot password", "Забыли пароль", "Забули пароль"],
  "form_title": ["Log in", "Форма входа", "Форма входу"],
  "dashboard": ["Dashboard", "Панель пользователя", "Панель користувача" ],
  "profile": ["Profile", "Профиль", "Профіль" ],
  "logout": ["Logout", "Выйти", "Вийти" ],
  "auth_title": ["Intelligent Solution for Your Business", "Интеллектуальное решение для вашего бизнеса", "Інтелектуальне рішення для Вашого бізнесу" ],
  "wrong_credential": ["Wrong login or password!", "Не верный логин или пароль!", "Невірний логін або пароль!" ],
  "reset": ["Reset", "Сброс", "Скидання"],
  "back_to_form_login": ["Back to Login form", "Вернуться к форме входа", "Повернутися до форми входу"],
  "reset_password_check_email": ["Request for recovery sent, check your mail", "Запрос на восстановление отправлен на почту, проверьте почту", "Запит на відновлення надіслано, перевірте свою пошту" ],
};
