const setErrorClass = (errors, name) => errors?.includes(name) ? "error" : "";

const onValidEmailInput = (value) => {
	const regex = new RegExp("^[a-zA-Z0-9_.@-]*$");

	if (value.split("").filter(symb => symb === "@").length > 1) {
		return false;
	}

	return regex.test(value);
};
const onValidPhoneInput = (value) => {
	const regex = /^\d+$/;

	if (value === '') {
		return true;
	}

	return regex.test(value);
};

const emailInputIsValid = (save, state) => {
	const errors = [];

	if (!state.action_mail.trim()) {
		errors.push("change_email");
	}

	save({ errors: [ ...state.errors, ...errors, ], });

	return errors.length === 0;
};

const phoneInputIsValid = (save, state) => {
	const errors = [];

	if (!state.action_phone.trim()) {
		errors.push("change_phone");
	}

	save({ errors: [ ...state.errors, ...errors, ], });

	return errors.length === 0;
};

export {
	setErrorClass, onValidEmailInput, emailInputIsValid, phoneInputIsValid, onValidPhoneInput,
};