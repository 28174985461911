import React, { Component, } from "react";
import Breadcrumbs from "../../../tima/components/Breadcrumbs";
import PropTypes from "prop-types";

export class BonusesHeader extends Component {
	static propTypes = {
		actions: PropTypes.oneOfType([ PropTypes.object, PropTypes.func, ]),
		breadcrumbsItems: PropTypes.func,
		// eslint-disable-next-line react/sort-prop-types
		title: PropTypes.string.isRequired,
		translate: PropTypes.func.isRequired,
	};

	static defaultProps = {
		title: "partners_h1",
	};

	render () {
		const {
			breadcrumbsItems, translate, title, actions,
		} = this.props;

		const renderActions = actions ? actions : null;

		return (
			<header className="partners-system__header">
				<h1 className="partners-system__title">{translate(title)}</h1>
				<Breadcrumbs items={ breadcrumbsItems(translate) } />
				{renderActions}
			</header>
		);
	}
}