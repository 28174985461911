import React from "react";
// import ToolbarPanelText from './ToolbarPanelText';
// import ToolbarPanelLink from './ToolbarPanelLink';
import ToolbarPanelItem from './ToolbarPanelItem';
import './styles.scss';

export default function ToolbarPanelList ( props ) {
    const { items, class4List, keysMapper, title = '', } = props;
    const item2key = ( _, index ) => {
        const { link, text, key } = _;
        if ( key ) {
            return key;
        } else if ( keysMapper ) {
            return keysMapper( { ..._, index, } );
        } else {
            return `${ link || text || index }`;
        }
    };
    const title_prefix = title && items.length > 0 && (<span className={'title_information_additional'}>{ title }</span>);

    return (
        <ul className={ class4List || `toolbar-panel__list` }>
            { title_prefix }
            {
                items.map( ( item, index ) => (
                    <ToolbarPanelItem
                        { ...item }
                        key={ item2key( item, index ) }
                    />
                ) )
            }
        </ul>
    );
}
