export default partnerId => [
	{
		key: "partner-card",
		permission: null,
		to: `/partners/requests/partners-list/${ partnerId }/partner-card`,
		label: 'partner_main_data',
		class: 'interaction',
	},
	{
		key: "promotional-campaigns",
		permission: null,
		to: `/partners/requests/partners-list/${ partnerId }/promotional-campaigns`,
		label: 'partner_promotional_campaigns',
		class: 'interaction',
	},
	{
		key: "payouts",
		permission: [ 'partners', 'accruals', ],
		to: `/partners/requests/partners-list/${ partnerId }/payouts`,
		label: 'partner_payouts',
		class: 'interaction',
	},
	{
		key: "referrals",
		permission: [ 'partners', 'referrals', ],
		to: `/partners/requests/partners-list/${ partnerId }/referrals`,
		label: 'partners_system_categories_referrers',
		class: 'interaction',
	},
];