//global endpoints
const GLOBAL_PATH_API = '/api';
const GLOBAL_PATH_CORE = `${GLOBAL_PATH_API}/core`;
const GLOBAL_PATH_TASKS = `${GLOBAL_PATH_API}/tasks`;
const GLOBAL_PATH_PAYMENTS = `${GLOBAL_PATH_API}/payment`;
const GLOBAL_PATH_SUMSUB = `${GLOBAL_PATH_CORE}/sumsub`;
export const GLOBAL_PATH_DISTRIBUTION = `${GLOBAL_PATH_CORE}/distribution-setting`;
const GLOBAL_PATH_TIMA = `${GLOBAL_PATH_API}/tima`;
const GLOBAL_PATH_MARKETING = `${GLOBAL_PATH_API}/statistics`;
const GLOBAL_PATH_PARTNERS = `${GLOBAL_PATH_API}/partners`;

//endpoints for core module
export const GET_ACCOUNT = `${GLOBAL_PATH_CORE}/account`;
export const SET_ACCOUNT = `${GLOBAL_PATH_CORE}/account`;
export const GET_ACCOUNTS = `${GLOBAL_PATH_CORE}/account`;
export const GET_ACCOUNT_ADDRESSES = `${GLOBAL_PATH_CORE}/account-addresses/get-account`;
export const GET_ACCOUNT_ADDRESS_TYPES_ENUM = `${GLOBAL_PATH_CORE}/account-addresses/get-type-enums-by-account`;
export const PUT_ACCOUNT_ADDRESS = `${GLOBAL_PATH_CORE}/account-addresses`;
export const GET_ACCOUNT_TAX_RESIDENCIES = `${GLOBAL_PATH_CORE}/account-tax-residency-data/get-by-account-id`;
export const GET_ACCOUNT_TAX_RESIDENCY_DATA_CHANGES = `${GLOBAL_PATH_CORE}/account-tax-residency-data-changes`;
export const GET_ACCOUNT_CHANGE_HISTORY_LAST_REQUEST = `${GLOBAL_PATH_CORE}/account-change-history/last/by-account`;
export const GET_ACCOUNT_DOCUMENTS = `${GLOBAL_PATH_CORE}/account-document`;
export const GET_ACCOUNT_DOCUMENT = `${GLOBAL_PATH_CORE}/account-document/get`;
export const GET_ACCOUNT_DOCUMENT_TYPE_RATIO_TO_SUB_TYPE = `${GET_ACCOUNT_DOCUMENTS}/get-type-ratio-to-sub-type`;
export const GET_ACCOUNT_DOCUMENT_PARSED_DATA = `${GET_ACCOUNT_DOCUMENTS}/get-parsed-data-by-document`;
export const GET_ACCOUNT_HISTORY = `${GLOBAL_PATH_CORE}/account/history-changes`;
export const GET_ACCOUNT_TAILS = `${GLOBAL_PATH_CORE}/account-tails/get-tails-by-account`;
export const GET_ALL_ACCOUNT_ENUMS = `${GLOBAL_PATH_CORE}/get-enums/`; // TODO: remove CRM-1461
export const GET_FILTER_OPTIONS = `${GET_ACCOUNT}/filter/form`;
export const GET_USERS = `${GLOBAL_PATH_CORE}/user`;
export const GET_USERS_DROPDOWN = `${GET_USERS}/drop-down?take=all`;
export const GET_USER = `${GLOBAL_PATH_CORE}/user`;
export const GET_ALLOWED_TO_CHANGE_MANAGERS = `${GLOBAL_PATH_CORE}/account/change-manager/drop-down`;
export const GET_ALL_USER_ENUMS = `${GLOBAL_PATH_CORE}/get-enums/`; // TODO: remove CRM-1461
export const SET_EMAILS = `${GLOBAL_PATH_CORE}/mail-all-templates/get-all`;
export const SET_SMS = `${GLOBAL_PATH_CORE}/sms-templates/get-all`;
export const ACTION_SEND_EMAILS = `${GLOBAL_PATH_CORE}/mail/send-to-accounts-by-filter`;
export const SEND_EMAILS = `${GLOBAL_PATH_API}/widget/core/sales-funnel/sendEmail`;
export const SEND_SMS = `${GLOBAL_PATH_API}/widget/core/sales-funnel/sendSms`;
export const ACTION_SEND_EMAILS_BY_ACCOUNT = `${GLOBAL_PATH_CORE}/mail/send-to-account`;
export const ACTION_SEND_SMS = `${GLOBAL_PATH_CORE}/sms/send-to-accounts-by-filter`;
export const ACTION_SEND_SMS_BY_ACCOUNT = `${GLOBAL_PATH_CORE}/sms/send-to-accounts`;
export const IS_VALID = `${GET_ACCOUNT}/is-valid`;
export const DOWNLOAD_ACCOUNT_DOCUMENT = `${GLOBAL_PATH_CORE}/account-document/get`;
export const POST_ACCOUNT_VERIFY = `${GET_ACCOUNT}/verify-document/verified`;
export const POST_ACCOUNT_SUMSUB_RESET_VERIFY = `${GET_ACCOUNT}/sumsub-reset-account`;
export const GET_ACCOUNT_INDIVIDUAL_VERIFIER_STATUS = `${GET_ACCOUNT}/individual-verifier-status`;
export const SET_ACCOUNT_STATUS_ACTIVE = `${SET_ACCOUNT}/active`;
export const SET_ACCOUNT_STATUS_ARCHIVE = `${SET_ACCOUNT}/archive`;

export const ACCOUNT_TAG = `${GLOBAL_PATH_CORE}/account-tag`;

export const GET_DICTIONARY_ACCOUNT_BROKERS = `${GLOBAL_PATH_CORE}/dictionary-account-brokers/drop-down`;
export const GET_DICTIONARY_ACCOUNT_LABELS = `${GLOBAL_PATH_CORE}/dictionary-account-labels/drop-down`;
export const GET_DICTIONARY_ACCOUNT_LIFESTYLES = `${GLOBAL_PATH_CORE}/dictionary-account-lifestyle/drop-down`;
export const GET_DICTIONARY_ACCOUNT_MESSENGERS = `${GLOBAL_PATH_CORE}/messenger/drop-down`;
export const GET_DICTIONARY_ACCOUNT_NATIONALITIES = `${GLOBAL_PATH_CORE}/nationality/drop-down?sort=name,asc`;
export const GET_DICTIONARY_ACCOUNT_COUNTRIES = `${GLOBAL_PATH_CORE}/country/drop-down`;
export const GET_DICTIONARY_ACCOUNT_CITIES = `${GLOBAL_PATH_CORE}/city/drop-down`;
export const GET_DICTIONARY_ACCOUNT_DOCUMENT_COMMENTS = `${GLOBAL_PATH_CORE}/account-document-comment/drop-down`;
export const GET_DICTIONARY_ACCOUNT_RELIGIONS = `${GLOBAL_PATH_CORE}/dictionary-account-religion/drop-down`;
export const GET_DICTIONARY_ACCOUNT_TIME_ZONES = `${GLOBAL_PATH_CORE}/time-zone/drop-down?add_fields[]=offset`;
export const GET_DICTIONARY_ACCOUNT_SOCIAL_NETWORKS = `${GLOBAL_PATH_CORE}/social-networks/drop-down`;
export const GET_DICTIONARY_ACCOUNT_STATUS = `${GLOBAL_PATH_CORE}/dictionary-account-status/drop-down`;
export const GET_DICTIONARY_ACCOUNT_TYPES = `${GLOBAL_PATH_CORE}/dictionary-account-types/drop-down`;
export const GET_DICTIONARY_ACCOUNT_TIN_ABSENCE_REASONS = `${GET_ACCOUNT}/account-tin-absence-reason/drop-down`;

export const GET_AVATAR = `${GET_USER}/get-avatar/`;
export const GET_TRADING_ACCOUNT = `${GLOBAL_PATH_CORE}/trading-accounts/get-trading-accounts`;
export const GET_SINGLE_ACCOUNT = `${GLOBAL_PATH_CORE}/trading-accounts/getByName`;
export const GET_TRANSACTIONS = `${GLOBAL_PATH_PAYMENTS}/orders/get-account-transactions`;
export const GET_INTERACTION = `${GET_ACCOUNT}/get-interaction`;
export const GET_APPEALS = `${GLOBAL_PATH_TASKS}/tasks/get-account-tasks`;
export const GET_NEW_CATEGORY = `${GLOBAL_PATH_CORE}/account-categories/get-new-accounts-by-categories`;
export const GET_USERS_EXCEL = `${GET_ACCOUNT}/funnel-excel/`;
export const SET_USER_FILTER = `${GLOBAL_PATH_CORE}/user-filter`;
export const GET_FILTER_HASH = `${SET_USER_FILTER}/user-type`;

export const GET_DICTIONARY_ACCOUNT_TYPE = `${GLOBAL_PATH_CORE}/dictionary-account-types/drop-down`;
export const SET_DICTIONARY_ACCOUNT_TYPE = `${GLOBAL_PATH_CORE}/account-types`;
export const GET_ACCOUNT_HISTORY_BY_ACCOUNT = `${GLOBAL_PATH_CORE}/account-change-history/by-account`;
export const PATCH_ACCOUNT_CHANGE_HISTORY = `${GLOBAL_PATH_CORE}/account-change-history`;

//Tasks
export const GET_TASKS = `${GLOBAL_PATH_TASKS}/tasks`;
export const UPLOAD_DOCUMENT_TASK = `${GLOBAL_PATH_TASKS}/task-documents`;
export const DOWNLOAD_DOCUMENT = `${UPLOAD_DOCUMENT_TASK}/get`;
//export const SELECT_ACCOUNT_MODEL = `${GET_ACCOUNT}/select-models`;
export const SELECT_ACCOUNT_MODEL = `${GET_ACCOUNT}/drop-down`;
export const GET_TASK_HISTORY = `${GET_TASKS}/history-changes`;

//Users
export const SET_USER = `${GLOBAL_PATH_CORE}/user`;
export const GET_PROFILE = `${GLOBAL_PATH_CORE}/auth/user/profile`;
export const SET_PROFILE_LANGUAGE = `${GLOBAL_PATH_CORE}/user/profile`;
export const GET_DEPARTMENTS = `${GLOBAL_PATH_CORE}/departments`;
export const GET_PERMISSIONS = `${GLOBAL_PATH_CORE}/permissions`;
export const GET_PERMISSIONS_SELECT = `${GLOBAL_PATH_CORE}/get-permission-select`;
export const TEMPLATE_PERMISSIONS = `${GLOBAL_PATH_CORE}/permission-templates`;
export const SAVE_SALES_PLANNING = `${GLOBAL_PATH_CORE}/user-kpi-plan`;
export const GET_USER_AVATAR = `${GET_USER}/get-avatar`;
export const GET_CRM_USERS = `${GET_USER}/users-not-hierarchy-tree`;
export const GET_USERS_AVATARS = `${GET_USER}/get-all-avatar`;
export const GET_SUBORDINATION = `${GLOBAL_PATH_CORE}/hierarchy-tree/drop-down`;

export const GET_USER_EVENT_SETTINGS = `${GLOBAL_PATH_CORE}/user-event-settings/profile`;
export const USER_EVENT_SETTINGS = `${GLOBAL_PATH_CORE}/user-event-settings`;
export const USER_EVENTS = `${GLOBAL_PATH_CORE}/user-events`;
export const USER_PROFILE_EVENTS = `${GLOBAL_PATH_CORE}/user-events/get-by-user-profile`;

//Calendar
export const GET_CALENDAR = `${GLOBAL_PATH_TASKS}/tasks/get-calendar`;
export const GET_SINGLE_TASK = `${GLOBAL_PATH_TASKS}/tasks`;

// Dashboard
export const GET_BRIEFCASE = `${GLOBAL_PATH_CORE}/account-categories/get-sales-changes`;
export const GET_USERS_CATEGORY = `${GLOBAL_PATH_CORE}/account-categories/get-accounts-by-category`;
export const GET_USERS_WARMING = `${GLOBAL_PATH_CORE}/account-categories/get-accounts-by-warming`;
export const GET_DASHBOARD_ACCOUNT = `${GLOBAL_PATH_CORE}/account/get-balance`;
export const GET_KPI = `${GLOBAL_PATH_CORE}/user-kpi-plan/get-kpi-stats-by-user`;
export const GET_USERS_BY_DEPARTMENT = `${GLOBAL_PATH_CORE}/hierarchy-tree/get-users-by-all-department`;
export const GET_DEPARTMENTS_BY_USERS = `${GLOBAL_PATH_CORE}/hierarchy-tree/get-departments-by-user`;
export const GET_DEPARTMENTS_AND_USERS = `${GLOBAL_PATH_CORE}/hierarchy-tree/get-all-users`;
export const GET_SALES_FUNNEL = `${GLOBAL_PATH_API}/widget/core/conversion-percent-rate`;
export const GET_ALL_MANAGERS = `${GLOBAL_PATH_API}/widget/core/sales-funnel/getManagers`;
export const GET_FUNNEL = `${GLOBAL_PATH_API}/widget/core/sales-funnel-active`;
export const GET_ALL_USERS_BY_DEPARTMENT = `${GLOBAL_PATH_CORE}/hierarchy-tree/get-all-users-by-department`;

// Marketing
export const GET_MARKETING_CHANNELS = `${GLOBAL_PATH_MARKETING}/utm_source`;
export const PUT_MARKETING_CHANNELS = `${GLOBAL_PATH_MARKETING}/utm_source`;
export const POST_MARKETING_CHANNELS = `${GLOBAL_PATH_MARKETING}/utm_source`;
export const GET_MARKETING_SUBCHANNELS = `${GLOBAL_PATH_MARKETING}/utm_medium`;
export const PUT_MARKETING_SUBCHANNELS = `${GLOBAL_PATH_MARKETING}/utm_medium`;
export const POST_MARKETING_SUBCHANNELS = `${GLOBAL_PATH_MARKETING}/utm_medium`;
export const GET_MARKETING_PRODUCTS = `${GLOBAL_PATH_MARKETING}/utm_campaign`;
export const PUT_MARKETING_PRODUCTS = `${GLOBAL_PATH_MARKETING}/utm_campaign`;
export const POST_MARKETING_PRODUCTS = `${GLOBAL_PATH_MARKETING}/utm_campaign`;
export const GET_MARKETING_CHANNELS_LAST_NUMBER = `${GLOBAL_PATH_MARKETING}/utm_source/last-number`;
export const GET_MARKETING_SUBCHANNELS_LAST_NUMBER = `${GLOBAL_PATH_MARKETING}/utm_medium/last-number`;
export const GET_MARKETING_PRODUCTS_LAST_NUMBER = `${GLOBAL_PATH_MARKETING}/utm_campaign/last-number`;
export const POST_MARKETING_SUBCHANNEL_TO_CHANNEL = `${GLOBAL_PATH_MARKETING}/utm_source_utm_medium`;
export const GET_MARKETING_CHANNEL_CHAR_AT = `${GLOBAL_PATH_MARKETING}/utm_source/drop-down`;
export const GET_MARKETING_SUBCHANNEL_CHAR_AT = `${GLOBAL_PATH_MARKETING}/utm_medium/drop-down`;
export const GET_MARKETING_SUBCHANNEL_WITH_SOURCE_CHAR_AT = `${GLOBAL_PATH_MARKETING}/utm_medium/drop-down-with-source`;
export const GET_MARKETING_SUBCHANNEL_WITHOUT_SOURCE_CHAR_AT = `${GLOBAL_PATH_MARKETING}/utm_medium/drop-down-without-source`;
export const GET_MARKETING_PRODUCT_CHAR_AT = `${GLOBAL_PATH_MARKETING}/utm_campaign/drop-down`;
export const POST_MARKETING_UTM_LINK = `${GLOBAL_PATH_MARKETING}/utm_link`;
export const GET_ACCOUNT_UTM_LINK = `${GLOBAL_PATH_MARKETING}/account_utm_link`;

//Admin-kpi
export const GET_SALES_PLANNING = `${GLOBAL_PATH_CORE}/user-kpi-plan/`;

export const GET_USER_KPI_SALES_PLANE = `${GLOBAL_PATH_CORE}/user-kpi-plan`;
export const SET_USER_KPI_SALES_PLANE = `${GLOBAL_PATH_CORE}/user-kpi-plan`;

export const GET_SUMSUB_CONFIG = `${GLOBAL_PATH_SUMSUB}/config`;

//Admin-distributions
export const GET_DISTRIBUTION_USERS = `${GET_USER}/get-distribution-by-department/conversion`;
export const GET_DISTRIBUTION_SETTING_DATA = `${GLOBAL_PATH_DISTRIBUTION}/by-alias/conversion`;

//Admin-tree
export const GET_HIERARCHY_TREE = `${GLOBAL_PATH_CORE}/hierarchy-tree`;

//Admin-templateManagment
/*sms*/
export const GET_SMS_TEMPLATES = `${GLOBAL_PATH_CORE}/sms-templates`;
export const GET_SMS_TEMPLATES_TRANSLATION = `${GLOBAL_PATH_CORE}/sms-template-translation`;
export const GET_SMS_TEMPLATES_TRANSLATION_BY_TEMPLATE = `${GET_SMS_TEMPLATES_TRANSLATION}/by-template`;

/*mail*/
export const GET_MAIL_TEMPLATES = `${GLOBAL_PATH_CORE}/mail-templates`;
export const GET_MAIL_TEMPLATES_TRANSLATION = `${GLOBAL_PATH_CORE}/mail-template-translation`;
export const GET_MAIL_TEMPLATES_TRANSLATION_BY_TEMPLATE = `${GET_MAIL_TEMPLATES_TRANSLATION}/by-template`;
export const GET_MAIL_TEMPLATE_DOCUMENT = `${GLOBAL_PATH_CORE}/mail-template-t-documents`;
export const GET_DOWNLOAD_MAIL_DOCUMENT = `${GLOBAL_PATH_CORE}/mail-template-t-documents/get`;

/*language*/
export const GET_LANGUAGE = `${GLOBAL_PATH_CORE}/system-languages`;
export const GET_CHANGE_PROGRAM = `${GLOBAL_PATH_API}/partners/awaiting-change-program`;
export const GET_CHANGE_REFFERAL = `${GLOBAL_PATH_API}/partners/awaiting-add-referral-register`;
export const GET_REFERRAL_REGISTER = `${GLOBAL_PATH_API}/partners/awaiting-add-referral-register`;
export const GET_DASHBOARD_CALCULATION_REASON = `${GLOBAL_PATH_API}/partners/condition-calculation-state`;
export const GET_DASHBOARD_CALCULATION_PAYOUT_REASON = `${GLOBAL_PATH_API}/partners/condition-payout-state`;
export const GET_DASHBOARD_CALCULATION_REASON_RESTART = `${GLOBAL_PATH_API}/partners/condition-calculation-state-reason`;
export const GET_DASHBOARD_CALCULATION_PAYOUT_REASON_RESTART = `${GLOBAL_PATH_API}/partners/condition-payout-state-reason`;



/*history*/
export const SMS_HISTORY_CHANGES = `${GET_SMS_TEMPLATES}/history-changes`;
export const SMS_HISTORY_CHANGES_TRANSLATION = `${GET_SMS_TEMPLATES_TRANSLATION}/history-changes`;
export const MAIL_HISTORY_CHANGES = `${GET_MAIL_TEMPLATES}/history-changes`;
export const MAIL_HISTORY_CHANGES_TRANSLATION = `${GET_MAIL_TEMPLATES_TRANSLATION}/history-changes`;

/*template_permission*/
export const GET_PERMISSION_TEMPLATES = `${GLOBAL_PATH_CORE}/permission-templates`;
export const GET_USERS_PERMISSION_TEMPLATES = `${GLOBAL_PATH_CORE}/permission-templates/drop-down`;


/*tima*/
export const GET_TIMA_STRATEGIES = `${GLOBAL_PATH_TIMA}/tima-manager`;
export const GET_TIMA_INVESTMENTS = `${GLOBAL_PATH_TIMA}/tima-investment`;
export const GET_TIMA_STRATEGY_INVESTMENTS = `${GET_TIMA_INVESTMENTS}/get-strategy-investments`;
export const GET_TIMA_CONDITION_INVESTMENTS = `${GET_TIMA_INVESTMENTS}/get-condition-investments`;
export const GET_TIMA_AGENT_INVESTMENTS = `${GET_TIMA_INVESTMENTS}/get-agent-investments`;
export const GET_TIMA_INVESTMENT_HISTORY = `${GET_TIMA_INVESTMENTS}/get-transactions`;
export const GET_TIMA_CONDITIONS = `${GLOBAL_PATH_TIMA}/tima-condition`;
export const GET_TIMA_STRATEGY_CONDITIONS = `${GET_TIMA_CONDITIONS}/get-strategy-conditions`;
export const GET_TIMA_AGENTS = `${GLOBAL_PATH_TIMA}/tima-agents`;
export const GET_TIMA_STRATEGY_AGENTS = `${GET_TIMA_AGENTS}/get-strategy-agents`;

/*lists*/
export const GET_TIMA_CONDITIONS_LIST = `${GET_TIMA_CONDITIONS}/drop-down`;
export const GET_TIMA_CONDITIONS_BY_VERSION = `${GET_TIMA_CONDITIONS}/get-conditions`;
export const GET_TIMA_VERSIONS_BY_MANAGER = `${GET_TIMA_CONDITIONS}/get-versions`;
export const GET_TIMA_AGENTS_LIST = `${GET_TIMA_AGENTS}/drop-down`;

/* partners */
export const GET_PARTNERS_PARTNERS = `${GLOBAL_PATH_PARTNERS}/partners`;
export const GET_PARTNERS_REQUESTS = `${GLOBAL_PATH_PARTNERS}/requests`;
export const GET_PARTNERS_REFERRALS = `${GLOBAL_PATH_PARTNERS}/referrals`;
export const GET_PARTNERS_REFERRALS_BY_PARTNER = `${GET_PARTNERS_REFERRALS}/by-partner_id`;
// export const GET_PARTNERS_STATISTIC = `${GLOBAL_PATH_PARTNERS}/statistic`;
export const GET_PARTNERS_ACCRUALS = `${GLOBAL_PATH_PARTNERS}/accruals`;
export const GET_PARTNERS_ACCRUALS_BY_PARTNER  = `${GET_PARTNERS_ACCRUALS}/by-partner_id`;
export const GET_PARTNERS_LIST = `${GET_PARTNERS_ACCRUALS}/get-all-partners?take=all`;
export const GET_PARTNERS_GROUPS = `${GLOBAL_PATH_PARTNERS}/groups-lists`;
export const GET_PARTNERS_GROUPS_ALL = `${GET_PARTNERS_GROUPS}?take=all`;
export const GET_PARTNERS_SYMBOLS = `${GLOBAL_PATH_PARTNERS}/symbols-lists?take=all`;
export const PARTNERS_SYMBOLS_LISTS = `${GLOBAL_PATH_PARTNERS}/symbols-lists`;
export const GET_PARTNERS_SYMBOLS_ALL = `${GET_PARTNERS_SYMBOLS}?take=all`;
export const GET_PARTNERS_CONDITIONS = `${GLOBAL_PATH_PARTNERS}/conditions`;
export const CHECK_ACCOUNT_REFERRAL = `${GLOBAL_PATH_CORE}/account/check-account-referral`;
export const CHECK_ACCOUNT_PARTNER = `${GLOBAL_PATH_CORE}/account/check-account-partner`;
export const CHECK_SYSTEM_ACCOUNT_REFERRAL = `${GLOBAL_PATH_CORE}/system-accounts/check-referral`;
export const CHECK_SYSTEM_ACCOUNT_PARTNER = `${GLOBAL_PATH_CORE}/system-accounts/check-partner`;
export const CREATE_PARTNERS_REFERRAL = `${GET_PARTNERS_REFERRALS}/create`;
// export const GET_USERS_LIST = `${GLOBAL_PATH_API}/core/user/drop-down`;
export const GET_TRADE_SERVER_LIST = `${GLOBAL_PATH_API}/core/dictionary-trade-servers`;
export const GET_TRADE_SERVER_LIST_TAKE_ALL = `${GET_TRADE_SERVER_LIST}/drop-down?add_fields[]=type&add_fields[]=is_demo&take=all`;
export const GET_MT_4_GROUPS_LIST = `${GLOBAL_PATH_API}/core/mt4-groups/getAllGroups`;
// export const GET_GROUPS_LIST = `${GLOBAL_PATH_API}/core/mt4-groups/getAllGroups`;
export const GET_SYMBOLS_LIST = `${GLOBAL_PATH_PARTNERS}/symbols?take=all`;
export const GET_SYMBOLS_GROUPS_LIST = `${GLOBAL_PATH_PARTNERS}/symbols-groups`;
// export const GET_SYMBOLS_LIST = `${GLOBAL_PATH_PARTNERS}/symbols?take=all`;
export const GET_SYMBOLS_GROUPS = `${GLOBAL_PATH_PARTNERS}/symbols-groups`;
export const SYMBOLS = `${GLOBAL_PATH_PARTNERS}/symbols`;
// export const PUT_SYMBOLS = `${GLOBAL_PATH_PARTNERS}/symbols`;
export const GET_ACCOUNT_GROUPS_LISTS = `${GLOBAL_PATH_PARTNERS}/groups-lists`;
export const GET_PARTNERS_CURRENCY = `${GLOBAL_PATH_PARTNERS}/programs-setting/has-currency`;
export const GET_PARTNERS_PROGRMAS_SETTING = `${GLOBAL_PATH_PARTNERS}/programs-setting`;
export const GET_PAYOUT_RATE_LISTS_ALL = `${GLOBAL_PATH_PARTNERS}/payout-rate-lists?take=all`;
export const GET_PAYOUT_RATE_LISTS = `${GLOBAL_PATH_PARTNERS}/payout-rate-lists`;
export const GET_PAYOUT_RATE_RANGES = `${GLOBAL_PATH_PARTNERS}/country-group`;
export const GET_ALL_COUNTRIES = `${GLOBAL_PATH_CORE}/country?take=all`;
export const GET_ALL_COUNTRIES_IS_WORK = `${GLOBAL_PATH_CORE}/country/drop-down?add_fields[]=is_work&take=all`;

export const GET_PARTNERS_PROGRAMS = `${GLOBAL_PATH_PARTNERS}/programs`;
export const GET_PARTNERS_AVAILABLE_CURRENCIES = `${GLOBAL_PATH_PARTNERS}/programs/available-currencies/drop-down`;
export const GET_FUNNEL_USERS = `${GET_SALES_FUNNEL}/users`
export const GET_STATEMENT = `${GLOBAL_PATH_CORE}`
/// NEW_PARTNERS_CONDITION /////
export const GET_NEW_PARTNERS_CONDITION_LISTS_ALL = `${GLOBAL_PATH_PARTNERS}/new-partners-conditions?take=all`;
export const GET_NEW_AFFILIATE_PROGRAMS_LISTS_ALL = `${GLOBAL_PATH_PARTNERS}/programs?take=all`;
export const GET_NEW_AFFILIATE_PROGRAMS_LISTS = `${GLOBAL_PATH_PARTNERS}/programs`;
export const GET_NEW_PARTNERS_CONDITION_LISTS = `${GLOBAL_PATH_PARTNERS}/new-partners-conditions`;
export const GET_NEW_PARTNERS_CONDITION_LISTS_CPA_SINGLE = `${GLOBAL_PATH_PARTNERS}/new-partners-conditions/cpaSingle`;
export const GET_NEW_PARTNERS_CONDITION_LISTS_CPA_MULTIPLE = `${GLOBAL_PATH_PARTNERS}/new-partners-conditions/cpaMultiple`;
export const GET_NEW_PARTNERS_CONDITION_LISTS_CPL_MULTIPLE = `${GLOBAL_PATH_PARTNERS}/new-partners-conditions/cplMultiple`;
export const GET_NEW_PARTNERS_CONDITION_LISTS_INOUT_SINGLE = `${GLOBAL_PATH_PARTNERS}/new-partners-conditions/inoutSingle`;
export const GET_NEW_PARTNERS_CONDITION_LISTS_REVSHARE_SINGLE = `${GLOBAL_PATH_PARTNERS}/new-partners-conditions/revshareSingle`;
export const GET_NEW_PARTNERS_CONDITION_LISTS_INOUT_MULTIPLE = `${GLOBAL_PATH_PARTNERS}/new-partners-conditions/inoutMultiple`;
export const GET_NEW_PARTNERS_CONDITION_LISTS_REVSHARE_MULTIPLE = `${GLOBAL_PATH_PARTNERS}/new-partners-conditions/revshareMultiple`;
export const GET_NEW_PARTNERS_CONDITION_LISTS_CPL_SINGLE = `${GLOBAL_PATH_PARTNERS}/new-partners-conditions/cplSingle`;
export const GET_PARTNER_DATA =`${GLOBAL_PATH_PARTNERS}/partners-setup`;
export const CHANGE_PARTNER_PROGRAMM =`${GET_PARTNER_DATA}/change-program`;
export const GET_PROMOTIONL_CAMPAIGNS =`${GLOBAL_PATH_PARTNERS}/referral-code/get-by-partner`;

export const MOVE_ALL_PARTNERS_TO_PROGRAMM = `${GET_PARTNERS_PROGRAMS}/move-all-partners-to-program`;
// CRONS
export const GET_CRONS = `${GLOBAL_PATH_API}/log/cron`
// Conversation Block
export const GET_NOVATALKS_MESSAGE_LIST = `${GLOBAL_PATH_API}/novatalks/messages`;
export const GET_NOVATALKS_MANAGER_STATUS = `${GLOBAL_PATH_API}/novatalks/agents`;
export const GET_NOVATALKS_END_CONVERSATION = `${GLOBAL_PATH_API}/novatalks/dialogs`;
export const GET_NOVATALKS_UPDATE_EMAIL = `${GLOBAL_PATH_API}/novatalks/contacts`;

export const GET_NOVATALKS_QUICK_MESSAGE_LIST = `${GLOBAL_PATH_API}/novatalks/quick-messages/drop-down`;
export const GET_NOVATALKS_QUICK_MESSAGE_LIST_BY_LANG = `${GLOBAL_PATH_API}/novatalks/quick-messages/by-contact`;


// PROMO

export const GET_PROMO = `${GLOBAL_PATH_PARTNERS}/promo`;

export const GET_TARGET_PAGES = `${GLOBAL_PATH_PARTNERS}/target-pages`;
export const GET_TARGET_PAGE_LANGUAGES = `${GLOBAL_PATH_PARTNERS}/target-page-languages`;
export const GET_TARGET_PAGE_NAME = `${GLOBAL_PATH_PARTNERS}/target-page-name`;
export const GET_TARGET_PAGE_NAME_DROPDOWN = `${GET_TARGET_PAGE_NAME}/drop-down`;

export const GET_LOGOS = `${GLOBAL_PATH_PARTNERS}/logos`;
export const GET_LENDINGS = `${GLOBAL_PATH_PARTNERS}/lending`;
export const GET_LENDING_LANGUAGES = `${GLOBAL_PATH_PARTNERS}/lending-languages`;

export const GET_TARGET_PAGES_DROPDOWN = `${GLOBAL_PATH_PARTNERS}/target-pages/drop-down`;
export const GET_REBATE_PROJECTS = `${GLOBAL_PATH_PARTNERS}/rebate-projects`;

// BONUSES

export const ClIENTS_WITH_BONUSES = `${GLOBAL_PATH_CORE}/bonus-program-to-account`;