import { map, isArray, forEach, reduce, isNull, isUndefined, omit } from 'lodash';

import { securedRequest } from '../../../services/RequestService/index';
import dateFns from "date-fns";
import {
    GET_FILTER_OPTIONS,
    GET_ALLOWED_TO_CHANGE_MANAGERS,
} from '../../../apiRoutes';
import {
    GET_TASKS,
    GET_ALL_TASKS_ENUMS,
    GET_TEN_TASKS,
    GET_COMMENT,
    GET_COMMENT_TASK,
    DOWNLOAD_DOCUMENT,
    SET_SAVE_ALL_TASK,
    GET_COUNT_TASK
} from '../apiRoutes';
import {
    setAllTasks, setTask, getEnumsTasks,
    setOneHundredTasks,
    setCommentsTaskID, delComment, setAllTaskUsers,
    resetError, setError,
    delTask, clearTask, filterHash,tasksStatus
} from "../actions";
import { filterOptions } from "../../clients/actions";
import {
    getTasksByFilterHash,
    setFilterHash,
    getFilterHash,
    getTasks,
    postFilterData,
    getTasksStatusRequest
} from './TaskRequestService';
import { cachingService, } from '../../../services/CachingService';

export const getAllTasks = (params = 'data=[]', output = '') => (dispatch) => {
    return securedRequest.post(`${GET_TASKS}/filter`, params)
        .then((response) => {
            securedRequest.get(`${GET_TASKS}/filter/${response.data.id}?${output}`).then((response) => {
                dispatch(setAllTasks(response.data));
            });
        })
        .catch((error) => {
            console.log('error', error);
        });
};

export const getFilterHashTable = (id, filter, output = '') => (dispatch) => {
    return getFilterHash(id)
        .then((response) => {
            if (response.data[0]) {
                dispatch(filterHash(response.data));
                return getTasksByFilterHash(response.data[0]["core-filter_hash-id"], output)
            } else {
                return getTasks(output)
            }
        });
};

export const getFilteredData = (params = 'data=[]', output = '') => {
    let filter;
    return postFilterData(params)
        .then((response) => {
            filter = response;
            return setFilterHash(filter);
        })
        .then(() => {
            return getTasksByFilterHash(filter.data.id, output)
        })
};

export const getTask = (id = 0) => async (dispatch) => {
    try {
        const url = `${GET_TASKS}/${id}`;
        const response = await cachingService.one(url);
        dispatch(setTask(response));
    } catch (error) {
        dispatch(setTask(undefined));
        dispatch(setError(error));
    }
};

export const clearReduxTask = () => (dispatch) => {
    dispatch(clearTask());
};

export const clearReduxComment = () => (dispatch) => {
    dispatch(delComment());
};

export const setResetError = () => (dispatch) => {
    dispatch(resetError());
};

// export const getTasksTypes = () => (dispatch) => {
//     return getTasksTypesRequest()
//         .then((response) => {
//             dispatch(tasksTypes(response.data));
//         })
//         .catch((error) => {
//             console.log('error', error);
//         });
// };

export const getTasksStatus = () => (dispatch) => {
    return getTasksStatusRequest()
        .then((response) => {
            dispatch(tasksStatus(response.data));
        })
        .catch((error) => {
            console.log('error', error);
        });
};

export const setEnumsTasks = () => (dispatch) => {
    return securedRequest.get(`${GET_ALL_TASKS_ENUMS}`)
        .then((response) => {
            dispatch(getEnumsTasks(response.data));
        })
        .catch((error) => {
            console.log('error', error);
        });
};

export const getTenTasks = (value) => (dispatch) => {
    return securedRequest.get(`${GET_TEN_TASKS}?take=10&filter=${value}`)
        .then((response) => {
            dispatch(setOneHundredTasks(response.data));
        })
        .catch((error) => {
            console.log('error', error);
        });
};

export const getCommentsTaskID = (id = 0) => async (dispatch) => {
    try {
        const url = `${GET_COMMENT}/${id}`;
        const response = await cachingService.one(url);
        dispatch(setCommentsTaskID(response));
    } catch (error) {
        console.log('error', error);
    }
};

export const deleteComment = (id) => (dispatch) => {
    return securedRequest.delete(`${GET_COMMENT_TASK}/${id}`)
        .then(() => {
            dispatch(delComment());
        })
        .catch((error) => {
            console.log('error', error);
        });
};

export const downloadDocument = async (alias) => {
    const response = await securedRequest.get(`${DOWNLOAD_DOCUMENT}/${alias}`, { responseType: 'blob' });
    return response;
};

/*export const getFilteredData = (params = 'data=[]', output = '') => (dispatch) => {
    return securedRequest.post(`${GET_TASKS}/filter`, params)
        .then((response) => {
            securedRequest.get(`${GET_TASKS}/filter/${response.data.id}?${output}`).then((response) => {
                dispatch(setAllTasks(response.data));
            }).then(() => {
                securedRequest.post(`${SET_USER_FILTER}`, { "filter_hash_id": response.data.id, "type": "2" });
            });
        })
        .catch((error) => {
            console.log('error', error);
        });
};*/

export const getDataByFilterId = (id, output = '') => (dispatch) => {
    // TODO: remove function as old & not used
    return securedRequest.get(`${GET_TASKS}/filter/${id}?${output}`).then((response) => {
        dispatch(setAllTasks(response.data));
    })
        .catch((error) => {
            console.log('error', error);
        });
};

export const getFilterOptions = () => (dispatch) => {
    // TODO: remove function as old
    return securedRequest.get(`${GET_FILTER_OPTIONS}`)
        .then((response) => {
            dispatch(filterOptions(response.data));
        })
        .catch((error) => {
            console.log('error', error);
        });
};

export const getCountTask = async () => {
    try {
        const response = await securedRequest.get(GET_COUNT_TASK);
        return response.data;
    } catch (error) {
        console.log("Error: ", error);
    }
};

//GET_COUNT_NOT_WORK
// export const getCountNotWork = () => (dispatch) => {
//     return securedRequest.get(GET_COUNT_NOT_WORK)
//         .then((response) => {
//             dispatch(setCountNotWork(response.data));
//         })
//         .catch((error) => {
//             console.log('error', error);
//         });
// };

//GET ALL TASKS USER
export const getAllTaskUsers = () => (dispatch) => {
    return securedRequest.get(GET_ALLOWED_TO_CHANGE_MANAGERS)
        .then((response) => {
            const dataInOldStructure = response.data.map((item) => {
                return {
                    core:
                        { user:
                            item,
                        },
                };
            });

            dispatch(setAllTaskUsers({ data: dataInOldStructure }));
        })
        .catch((error) => {
            console.log('error', error);
        });
};

//SAVE_ALL_TASK
export const saveAllTask = (data) => {
    return securedRequest.post(SET_SAVE_ALL_TASK, data)
        .then(
            (response) => Promise.resolve(response.data),
            (error) => Promise.reject(error.response.data)
        );
};

//GET SELECT_ACCOUNT_MODEL
export const getSelectAccountModel = () => (dispatch) => {
    console.error('Not user select all accounts!!!!');
    return Promise.resolve([]);
    // return securedRequest.get(SELECT_ACCOUNT_MODEL)
    //     .then((response) => {
    //         dispatch(setSelectAccountModel(response));
    //     })
    //     .catch((error) => {
    //         console.log('error', error);
    //     });
};

//DELETE TASK
export const deleteTask = (id = 0) => (dispatch) => {
    return securedRequest.delete(`${GET_TASKS}/${id}`)
        .then((response) => {
            dispatch(delTask());
        })
        .catch((error) => {
        });
};

//all other methods for task page component

export function onFind () {
    map(this.props.defFilter, (item, key) => {
        document.getElementsByName(item)[0] ? document.getElementsByName(item)[0].parentElement.classList.remove('active') : '';

    });
    const filterDataToObj = this.state.advancedFilters;
    const filterData = [];
    const activeFilters = {};

    let newAdvancedFilters = {};
    let filter = JSON.parse( JSON.stringify(this.props.initialFilter) );

    map(filterDataToObj, (item, key) => {
        switch (this.props.fieldsForCheckingAdvanced[key].type) {
            case 'f': {
                let valueArr = [];

                if (item.v) {
                    if (isArray(item.v) && item.o !== 'between') {
                        valueArr = item.v;
                    } else {
                        valueArr.push(item.v);
                    }

                    newAdvancedFilters = {
                        ...newAdvancedFilters,
                        [key]: { 'o': this.state.advancedFilters[key].o, 'v': valueArr },
                    };

                    if (this.props.defFilter.indexOf(key) < 0) {
                        activeFilters[key] = {
                            'o': this.state.advancedFilters[key].o, 'v': valueArr,
                        };
                    } else {
                        filter[key] = {
                            ...filter[key], 'v': valueArr.join(),
                        };
                    }
                }

                if (valueArr.length) {
                    filterData.push({
                        "f": this.props.fieldsForCheckingAdvanced[key].f,
                        "o": this.state.advancedFilters[key].o,
                        "v": valueArr,
                    });
                }
            }
            case 'fkey': {
                let valueArr = [];

                if (item.v) {
                    if (isArray(item.v) && item.o !== 'between') {
                        valueArr = item.v;
                    } else {
                        valueArr.push(item.v);
                    }

                    newAdvancedFilters = {
                        ...newAdvancedFilters,
                        [key]: { 'o': this.state.advancedFilters[key].o, 'v': item.v },
                    };

                    if (this.props.defFilter.indexOf(key) < 0) {
                        activeFilters[key] = {
                            'o': this.state.advancedFilters[key].o, 'v': valueArr,
                        };
                    } else {
                        filter[key] = {
                            ...filter[key], 'v': valueArr,
                        };
                    }
                }

                forEach(this.props.fieldsForCheckingAdvanced[key].fkey, (fItem, k) => {
                    if (key === 'val') {
                        filterData.push({
                            "fkey":       this.props.fieldsForCheckingAdvanced[key].f,
                            [fItem.type]: fItem.f,
                            "o":          fItem.o,
                            "v":          fItem.v,
                        });
                    } else {
                        filterData.push({
                            "fkey":       this.props.fieldsForCheckingAdvanced[key].f,
                            [fItem.type]: fItem.f,
                            "o":          fItem.o,
                            "v":          valueArr,
                        });
                    }
                });
            }
        }
    });

    filterData.push({
        "f": this.state.sort.f,
        "o": this.state.sort.o,
        "v": this.state.sort.v,
    });

    const filters = JSON.stringify(filterData);

    this.setState({
            selectedFilters: {
                ...newAdvancedFilters
            },
            advancedFilters: {
                ...newAdvancedFilters
            },
            filter: {
                ...filter
            },
            activeFilter: false,
            additionalActiveFilters: {
                ...activeFilters,
        },
    }, () => {
            this.getFilteredData(`data=${filters}`, objectToQueryString(this.state.output)).then((response) => {
                this.setState({
                    data: response.data.data,
                    filterCount: response.data.meta.filter,
                    total: response.data.meta.total
                });
            });

        forEach(newAdvancedFilters, (item, key) => {
            if (this.props.defFilter.indexOf(key) >= 0 && !document.getElementsByName(key)[0].parentElement.classList.contains('active')) {

                document.getElementsByName(key)[0].parentElement.className += ' active';
            }
        });
    }
    );

}

export function onChangeFilterSelectTable (event, name) {
    this.setState({
        filter:
            {
                ...this.state.filter,
                [name]: {
                    ...this.state.filter[name],
                    'v': event.value,
                },
            },
        advancedFilters:
            {
                ...this.state.advancedFilters,
                [name]: {
                    ...this.state.advancedFilters[name],
                    'o': this.state.filter[name].o,
                    'v': event.value,
                },
            },
    });
}

export const objectToQueryString = (obj) => {
    const qs = reduce(obj, (result, value, key) => {

        return !isNull(value) && !isUndefined(value) ? result += `${key}=${value}&` : result;
    }, '').slice(0, -1);

    return qs;
};

export function tableSortToggle () {
    this.setState({
        tableSort: !this.state.tableSort,
    });
}

export function getClassForTableSort () {
    if (this.state.tableSort) {
        return 'sorted';
    }

    return '';
}

export function onChangeFilter (event) {

    const value = event.target.value;
    const name = event.target.name;

    this.setState({
        filter:
                {
                    ...this.state.filter,
                    [name]: {
                        ...this.state.filter[name], 'v': value,
                    },
                },
        advancedFilters:
                {
                    ...this.state.advancedFilters,
                    [name]: {
                        ...this.state.advancedFilters[name],
                        'o': this.state.filter[name].o,
                        'v': value,
                    },
                },
    }
    );
}

export function onChangeTableSort (event) {

    const type = event.currentTarget.dataset.type;

    if (this.props.total > this.props.filterCount) {
        this.setState({
            sort:
                {
                    ...this.state.sort, 'f': type, 'v': this.state.sort.v === 'asc' ? 'desc' : 'asc',
                },
        }, () => {
            this.onFind();
        });
    } else {
        this.setState({
            sort:
                {
                    ...this.state.sort, 'f': type, 'v': this.state.sort.v === 'asc' ? 'desc' : 'asc',
                },
        }, () => {
            this.getFilteredData(`data=${JSON.stringify([{
                'f': this.state.sort.f,
                'o': this.state.sort.o,
                'v': this.state.sort.v,
            }])}`, objectToQueryString(this.state.output)).then((response) => {
                this.setState({
                    data: response.data.data,
                    filterCount: response.data.meta.filter,
                    total: response.data.meta.total
                });
            });
        });
    }
}

export function onChangeFilterAdvanced (event) {

    const value = event.target.value;
    const item = event.target.dataset.item;

    this.setState({
        advancedFilters:
            {
                ...this.state.advancedFilters,
                [item]: {
                    ...this.state.advancedFilters[item],
                    'v': value,
                },
            },
    });
}

export function onChangeFilterAdvancedCalendar (event, i) {

    const value = dateFns.format(event, 'YYYY-MM-DD 00:00:00');
    const item = i;

    this.setState({
        advancedFilters:
            {
                ...this.state.advancedFilters,
                [item]: {
                    ...this.state.advancedFilters[item],
                    'v': value,
                },
            },
    });
}

export function onChangeFilterAdvancedPeriod (event, i, type) {

    const value = dateFns.format(event, 'YYYY-MM-DD 00:00:00');
    const item = i;

    const valueArr = this.state.advancedFilters[item].v ? this.state.advancedFilters[item].v : [value, value];

    valueArr[type] = value;
    valueArr.splice(type, 1, value);

    this.setState({
        advancedFilters:
            {
                ...this.state.advancedFilters,
                [item]: {
                    ...this.state.advancedFilters[item],
                    'v': valueArr,
                },
            },
    });
}

export function onChangeFilterDataType (event, i) {

    const value = event;
    const item = i;

    this.setState({
        advancedFilters:
            {
                ...this.state.advancedFilters,
                [item]: {
                    ...this.state.advancedFilters[item],
                    'v': value,
                },
            },
    });
}

export function removeFilterAdditional (event) {
    const item = event.target.dataset.item;
    const additionalActiveFilters = omit(this.state.additionalActiveFilters, item);
    const advancedFilters = omit(this.state.advancedFilters, item);

    this.setState({
        advancedFilters:
                {
                    ...advancedFilters,
                },
        additionalActiveFilters:
                {
                    ...additionalActiveFilters,
                },
    },
    () => {
        this.onFind();
    });
}

export function removeFilter (event) {
    const name = event.target.parentElement.parentElement.children[0].name;

    this.setState({
        filter:          { ...this.state.filter, [name]: { ...this.state.filter[name], 'v': '' }},
        advancedFilters: { ...this.state.advancedFilters, [name]: { ...this.state.advancedFilters[name], 'v': '' }},
    },
    () => {
        this.onFind();
    });

    event.target.parentElement.parentElement.children[0].value = '';
    event.target.parentElement.parentElement.classList.remove('active');
}

export function addFilter () {
    this.setState({
        advancedFilters:
            {
                ...this.state.advancedFilters,
                [Object.keys(this.state.advancedFilters).length]: {
                    'f': 'tasks-tasks-id', 'o': 'in', 'v': '',
                },
            },
    });
}

export function onChangeFilterSelect (event) {

    const value = event.value;
    const name = event.name;

    this.props.setFilters({
        [name]: [{ 'o': 'like', 'v': value }],
    });

    this.setState({
        filter: [...this.state.filter, {
            [name]: { 0: { 'o': 'like', 'v': value }},
        }],
    });
}

export function onChangeFilterSelectAdvanced (event) {

    const value = event.value;
    const data = event.data;
    const type = event.type;

    const obj = {};

    if (type === 'o') {
        obj.o = value;
    } else {
        obj.f = value;
        obj.o = this.props.options[value][0] ? this.props.options[value][0] : 'like';
        obj.v = '';
    }

    this.setState({
        advancedFilters:
            {
                ...this.state.advancedFilters,
                [data]: {
                    ...this.state.advancedFilters[data],
                    ...obj,
                },
            },
    });
}

export function onChangeFilterSelectMulti (event, id, name) {
    const types = {
        'core-account-status':      'statusType',
        'core-account-system_type': 'typeType',
    };
    const value = event.value;

    if (value.length) {

        this.setState({
            advancedFilters:
                {
                    ...this.state.advancedFilters,
                    [id]: {
                        'f': name, 'o': '=', 'v': value,
                    },
                },
            filter:
                {
                    ...this.state.filter,
                    [name]: {
                        ...this.state.filter[name], 0: { 'o': '=', 'v': value },
                    },
                },
            [types[name]]: event,
        });
    } else {

        this.setState({
            advancedFilters:
                {
                    ...this.state.advancedFilters,
                    [id]: {
                        'f': name, 'o': '=', 'v': '',
                    },
                },
            filter:
                {
                    ...this.state.filter,
                    [name]: {
                        ...this.state.filter[name], 0: { 'o': '=', 'v': '' },
                    },
                },
            [types[name]]: [],
        });
    }
}

export function next () {
    const diff = parseInt(this.state.output.skip) + parseInt(this.state.output.take);

    if (parseInt(this.state.filterCount) > diff) {
        const skip = this.state.output.skip + this.state.output.take;

        this.setState({ output: { ...this.state.output, skip }}, () => {
            this.onFind();
        });
    }
}

export function prev () {
    if (this.state.output.skip >= 1) {
        const skip = this.state.output.skip - this.state.output.take;

        this.setState({ output: { ...this.state.output, skip }}, () => {
            this.onFind();
        });
    }

    return true;
}

export function first () {
    this.setState({ output: { ...this.state.output, 'skip': 0 }}, () => {
        this.onFind();
    });
}

export function last () {
    const div = this.state.filterCount % this.state.output.take;

    let skip = 0;

    if (div === 0) {
        skip = this.state.filterCount - this.state.output.take;
    } else {
        skip = this.state.filterCount - div;
    }

    this.setState({ output: { ...this.state.output, skip }}, () => {
        this.onFind();
    });
}

export function reset () {
    this.setState({
        filter: {
            ...this.props.filter,
        },
        advancedFilters:         {},
        additionalActiveFilters: {},
    },
    () => {
        this.onFind();
    }
    );

    const allFilterInputs = document.querySelectorAll('.input-block .filter');

    allFilterInputs.forEach((item) => {
        item.parentElement.children[0].value = '';
        item.parentElement.classList.remove('active');
    });

}

export function handlePwdKeyUp (e) {
    if (e.key === 'Enter') {
        this.first();
    }
}

export function setTypeValueMulti (value, id) {

    this.setState((prevState) => ({
        typeSelect: {
            ...prevState.typeSelect,
            id,
            value,
        },
    }));
}

export function toggleFilter () {
    this.setState({
        activeFilter: !this.state.activeFilter,
    });
}

export function onChangeFilterFS (event) {

    const value = event.target.value;

    this.setState({
        fs: { fs: ['tasks-tasks-id', 'tasks-tasks-description'], o: 'like', v: value },
    });
}

export function removeFilterAdvancedFS () {

    this.setState({
        fs: { fs: ['tasks-tasks-id', 'tasks-tasks-description'], o: 'like', v: '' },
    });
}

export function onChangeFilterClient (event) {

    const value = event.target.value;
    const userType = event.target.dataset.user;

    if (event.target.dataset.item) {
        this.setState({
            advancedFilters:
                {
                    ...this.state.advancedFilters,
                    [event.target.dataset.item]: {
                        ...this.state.advancedFilters[event.target.dataset.item],
                        fkey:     "tasks-task_users-array",
                        fs:       ['core-user-name', 'core-user-surname'],
                        v:        value,
                        fkeytype: "tasks-task_users-array",
                        ftype:    "tasks-task_users-role_id",
                        otype:    '=',
                        vtype:    userType,
                    },
                },
            clientFilter:
                {
                    ...this.state.clientFilter,
                    [event.target.dataset.item]: {
                        ...this.state.clientFilter[event.target.dataset.item],
                        fkey:     "tasks-task_users-array",
                        fs:       ['core-user-name', 'core-user-surname'],
                        v:        value,
                        fkeytype: "tasks-task_users-array",
                        ftype:    "tasks-task_users-role_id",
                        otype:    '=',
                        vtype:    userType,
                    },
                },
        });
    } else {

        const item = Object.keys(this.state.advancedFilters).length;

        event.target.setAttribute('data-item', item);

        this.setState({
            advancedFilters:
                {
                    ...this.state.advancedFilters,
                    [event.target.dataset.item]: {
                        fkey:     "tasks-task_users-array",
                        fs:       ['core-user-name', 'core-user-surname'],
                        o:        'like',
                        v:        value,
                        fkeytype: "tasks-task_users-array",
                        ftype:    "tasks-task_users-role_id",
                        otype:    '=',
                        vtype:    userType,
                    },
                },
            clientFilter:
                {
                    ...this.state.clientFilter,
                    [event.target.dataset.item]: {
                        ...this.state.clientFilter[event.target.dataset.item],
                        fkey:     "tasks-task_users-array",
                        o:        'like',
                        fs:       ['core-user-name', 'core-user-surname'],
                        v:        value,
                        fkeytype: "tasks-task_users-array",
                        ftype:    "tasks-task_users-role_id",
                        otype:    '=',
                        vtype:    userType,
                    },
                },
        });
    }
}
