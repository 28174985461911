import React, { Component, Fragment } from "react";
import { withLocalize, } from "react-localize-redux";
import Preloader from "../../../components/LoadingHOC/Preloader";
import PagePanel from './Pages';
import Loader from "./Loader";
import { MagicTable } from "../../../components/Magic";
import { timaService } from "../../../services/TimaDataService";

class ListTimaInvestmentHistory extends Component {
    constructor (props) {
        super(props);

        const { params, } = props;
        const { investmentId, } = params || {};

        this.state = {
            loaded: false,
            investmentHistory: {
                data: [],
                hash: null,
            },
            pages:  {
                pageId:   0,
                filter:   0,
                total:    0,
                take:     20,
                variance: 2,
            },
            investmentId,
        };
    }

    save = async (state) => new Promise(next => this.setState(state, next));

    timaInvestmentHistoryChanges = async (investmentHistory) => {
        if (investmentHistory.hash===this.state?.investmentHistory?.hash) {
            return `${this.constructor.name}.timaInvestmentHistoryChanges: false`;
        }
        if (investmentHistory.investmentId !== this.state?.investmentId) {
            return `${this.constructor.name}.timaInvestmentHistoryChanges: false`;
        }
        if (investmentHistory.meta.filter===this.state?.pages?.filter) {
            return `${this.constructor.name}.timaInvestmentHistoryChanges: false`;
        }
        if (investmentHistory.meta.total===this.state?.pages?.total) {
            return `${this.constructor.name}.timaInvestmentHistoryChanges: false`;
        }
        const { data, meta, hash } = investmentHistory;
        await this.save({
            investmentHistory: { data, hash },
            pages: {
                ...this.state.pages,
                filter: meta?.filter,
                total: meta?.total,
            },
        });

        return `${this.constructor.name}.timaInvestmentHistoryChanges: true`;
    };

    componentDidMount = async () => {
        timaService.subscribe('investmentHistory', this.timaInvestmentHistoryChanges, this);
        await this.onDataLoad();
    };

    componentWillUnmount = () => {
        timaService.unsubscribe('investmentHistory', this.timaInvestmentHistoryChanges, this);
    };

    get listTimaInvestmentHistoryConfig () {
        const { translate, } = this.props;
        const prefix = 'tima_investment_history_table_';

        return [
            {
                path:   ['tima', 'investment_transactions', 'created_at'],
                key:    'createdAt',
                render: this.renderInlineValue,
                title:  `${prefix}date_created`,
            },
            {
                path:   ['tima', 'investment_transactions', 'type'],
                key:    'type',
                render: this.renderEnumField('tima', 'TimaInvestmentTrasactionTypeEnum'),
                title:  `${prefix}type`,
            },
            {
                path:   ['tima', 'investment_transactions', 'amount'],
                key:    'amount',
                render: this.renderNumberFixedFormat(2),
                title:  `${prefix}amount`,
            },
            {
                path:   ['tima', 'investment_transactions', 'profitability'],
                key:    'profitability',
                render: this.renderNumberFixedFormat(2),
                title:  `${prefix}profitability_account`,
            },
            {
                accessChecked: true,
                path:          ['tima', 'investment_transactions', 'investment_profitability'],
                key:           'investmentProfitability',
                render:        this.renderNumberFixedFormat(2),
                title:         `${prefix}profitability_investment`,
            },

        ].map(({ title: t, xtitle: x, ...item }) => ({ ...item, ...x && { title: x }, ...t && { title: translate(t) }, }));
    }

    renderInlineValue = (value, { item, items }) => {
        if (item?.access?.('index')) {
            return value ?? '-';
        }
        return '-';
    };

    renderNumberFixedFormat = (digits) => (value, { item, items }) => {
        if (item?.access?.('index')) {
            // return !isNaN(+value) ? (+value)?.toFixed?.(digits) ?? '-' : '-';
            return (+value).toFixed(digits) ?? '-';
        }
        return '-';
    };

    renderEnumField = (path, enumName) => (value, { item }) => {
        if (item?.access?.('index')) {
            const enums = this.props?.enums?.[path]?.[enumName];
            return enums?.[value] ?? '-';
        }
        return '-';
    };

    render = () => {
        const { translate } = this.props;
        const { investmentHistory: { data } } = this.state;

        const options = {
            config: this.listTimaInvestmentHistoryConfig,
            data,
            head:   [
                'createdAt',
                'type',
                'amount',
                'profitability',
                'investmentProfitability',
            ],
        };

        return (
            <Fragment>
                <div className='title-cont'>
                    <h3 className='title'>{translate('tima_investment_history_table_title')}</h3>
                </div>

                <div className='table-wrapper'>
                    <div className='position-relative'>

                        <Loader
                            loaded={this.state.loaded}
                            loading={(<Preloader scale={this.props.scale}/>)}
                            translate={translate}
                        >
                            <MagicTable {...options} />

                            <PagePanel
                                filter={this.state.pages.filter}
                                take={this.state.pages.take}
                                total={this.state.pages.total}
                                variance={this.state.pages.variance}
                                page={this.pageId()}
                                onClick={this.onPageClick}
                                doText={this.onPageText}
                                doTo={(pageId) => `?page=${ pageId }`}
                            />
                        </Loader>

                    </div>
                </div>
            </Fragment>
        );
    };

    pageId = () => {
        const { query } = this.props;
        const page = query.get('page');
        return Number(page) || 0;
    };

    pageIdAsGET = (pageId) => {
        pageId = pageId === undefined ? this.pageId() : pageId;
        return {
            take: this.state.pages.take,
            skip: pageId * this.state.pages.take,
        };
    };

    onDataLoad = async (pageId) => {
        await this.save({ loaded: false, });
        const pageOptions = this.pageIdAsGET(pageId);
        const loaded = (data, meta, hash) => ({ pages, }) => ({
            investmentHistory: { data, hash, },
            pages: { ...pages, ...meta, },
        });
        try {
            const { investmentId, } = this.state;
            const { data, meta, hash, } = await timaService.investmentHistory(investmentId, pageOptions);
            const { filter = 0, total = 0, } = meta;

            await this.save(loaded(data, { filter, total, }, hash));
        } catch (error) {
            await this.save(loaded([], { filter: 0, total: 0, }, null));
            error?.showErrorNotification?.();
        }
        await this.save({ loaded: true, });
    };

    onPageClick = async ({ event, pageId, pageIs, }) => {
        event.preventDefault();
        await this.onDataLoad(pageId);
    };

    onPageText = (pageId, pageIs) => {
        const { translate } = this.props;
        const { current, first, prev, next, last, } = pageIs;
        const { skipped, taken, filter, total, } = pageIs;
        if (skipped || taken) {
            const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : '?';
            const text = skipped ? translate('tima_pages_items') : '';
            return skipped ? `${ text }: ${ id }` : id;
        } else if (filter || total) {
            const id = Number.isInteger(pageId) ? `${ pageId }` : '?';
            const text = translate(filter ? 'tima_pages_filtered' : 'tima_pages_total');
            return `${ text }: ${ id }`;
        } else if (first || prev || next || last) {
            return '';
        } else if (current) {
            return `${ pageId + 1}`;
        } else {
            return `${ pageId + 1}`;
        }
    };
};

export default withLocalize(ListTimaInvestmentHistory);
