//link route
export const LIST_TIMA_STRATEGIES_ID = 'tima/strategy';
export const LIST_TIMA_INVESTMENTS_ID = 'tima/investment';
export const LIST_TIMA_CONDITIONS_ID = 'tima/condition';
export const LIST_TIMA_AGENTS_ID = 'tima/agent';

export const LIST_USERS_ID = 'settings/user';

// field names full path
export const TIMA_EXPORT_FIELDS = {
    strategies: [
        'tima-tima_manager-name',
        'core-trading_accounts-mt_login',
        'core-account-surname',
        'core-account-name',
        'core-trading_accounts-equity',
        'core-trading_accounts-balance',
        'core-trading_accounts-acc_ccy',
        'tima-tima_manager-share',
        'core-trading_accounts-manager_balance',
        'core-trading_accounts-manager_equity',
        'tima-tima_manager-status',
        'tima-tima_manager-trade_period',
        'tima-tima_manager-deal_time',
        'tima-tima_manager-deal_step',
        'tima-tima_manager-agent_reward',
        'tima-tima_manager-min_invest_risk',
        'core-user-surname',
        'core-user-name',
    ],
    investments: [
        'tima-tima_investment-name',
        'core-account-surname',
        'core-account-name',
        'tima-tima_investment-amount',
        'core-trading_accounts-acc_ccy',
        'tima-tima_manager-name',
        'tima-tima_investment-status',
        'tima-tima_investment-risk',
        'tima-tima_investment-reinvest',
        'tima-tima_investment-share',
        'core-trading_accounts-current_equity',
        'core-trading_accounts-current_profitability',
        'core-trading_accounts-net_profit',
        'tima-tima_agents-name',
        'tima-tima_investment-created_at',
        'tima-tima_investment-date_closed',
        'core-user-surname',
        'core-user-name',
    ],
    investmentHistory: [
        'tima-investment_transactions-created_at',
        'tima-investment_transactions-type',
        'tima-investment_transactions-amount',
        'tima-investment_transactions-profitability',
        'tima-investment_transactions-investment_profitability',
    ],
    conditions: [
        'tima-tima_condition-version',
        'tima-tima_condition-name',
        'tima-tima_condition-invest_min',
        'tima-tima_condition-invest_max',
        'tima-tima_condition-reward',
        'tima-tima_condition-min_invest_period',
        'tima-tima_condition-penalty',
        'tima-tima_manager-name',
        'tima-tima_condition-created_at',
        'tima-tima_condition-updated_at',
    ],
    agents: [
        'tima-tima_agents-name',
        'core-account-surname',
        'core-account-name',
        'tima-tima_manager-name',
        'tima-tima_agents-status',
        'tima-tima_agents-agent_reward',
        'core-user-surname',
        'core-user-name',
    ],
};
