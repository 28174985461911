import React, { Component, } from 'react';
import {
	MagicButton, MagicFilterPanels, MagicTable, Mentor,
} from "../../../../../../components/Magic";
import Loader from "../../../../../tima/components/Loader";
import Preloader from "../../../../../../components/LoadingHOC/Preloader";
import PagePanel from "../../../../../tima/components/Pages";
import Moment from "moment/moment";
import { MagicTooltip, } from "../../../../../tima/components/Magic";
import {
	getDashboardCalculationPayout, restartConditionPayoutCalculation,
} from "../../../../../../services/PartnersRequestService";
import { withLocalize, } from "react-localize-redux";
import { withRouter, } from "react-router";
import { Link, } from "react-router-dom";
import NotificationService from "../../../../../../services/NotificationService";

class CalculationPayoutDashboardMagicTable extends Component {
	constructor (props) {
		super(props);

		this.state = {
			calculationStateId: '',
			data: [],
			filterLastChange: Date.now(),
			isEmptyData: false,
			isViewed: true,
			loaded: false,
			pages: {
				filter: 0,
				take: 50,
				total: 0,
				variance: 2,
			},
		};

		const mf = new Mentor({
			owner: this,
			serviceId: 183,
			translate: this.props.translate,
		});

		Object.defineProperty(this, "mf", { get: () => mf, });
	}

	async componentDidMount () {
		this.mf.subscribe(
			{
				changed: this.mfChanged,
				cleaned: this.mfDone,
				cleaning: this.mfDoing,
				loaded: this.mfDone,
				loading: this.mfDoing,
				saved: this.mfDone,
				saving: this.mfDoing,
			},
			this,
		);

		await this.mf.init({ doEmit: true, });
	}

	UNSAFE_componentWillUnmount () {
		this.mf.unsubscribe([ this.mfChanged, this.mfDoing, this.mfDone, ], this);
	}

    save = state => new Promise(next => this.setState(state, next));

    onChange = (name, value) => this.save({ [name]: value, });

    params = () => this.props.match.params;

    query = () => new URLSearchParams(this.props.location.search);

    mfChanged = async () => {
    	await this.save({ filterLastChange: Date.now(), });
    };

    mfDoing = async () => {
    	await this.save({ loaded: false, });
    };

    mfDone = async () => {
    	await this.mfChanged();
    	await this.onDataLoad();
    };

    enumsChange = async (enums) => {
    	if (enums.hash === this.state?.enums?.hash) {
    		return `${ this.constructor.name }.enumsChange: false`;
    	}
    	await this.save({ enums, });

    	return `${ this.constructor.name }.enumsChange: true`;
    };
    get calculationDashboardTableConfig () {
    	const { translate, } = this.props;
    	const prefix = "partner_dashboard_table_";

    	return [
    		{
    			path: [ 'core', 'user', 'name', ],
    			key: 'userName',
    		}, {
    			path: [ 'core', 'user', 'surname', ],
    			key: 'userSurname',
    		},
    		{
    			key: "calculationId",
    			orderId: "o:id",
    			path: [ "partners", "condition_payout_state", "id", ],
    			render: this.renderInitialValueId,
    			title: `${ prefix }id`,
    		},
    		{
    			key: "calculationConditionType",
    			orderId: "o:type",
    			path: [ "partners", "condition_payout_state", "condition_type", ],
    			render: this.renderCalculationType,
    			title: `${ prefix }condition_type`,
    		},
    		{
    			key: "calculationConditionLevel",
    			orderId: "o:level",
    			path: [ "partners", "condition_payout_state", "condition_level", ],
    			render: this.renderCalculationLevel,
    			title: `${ prefix }condition_level`,
    		},
    		{
    			key: "calculationReason",
    			path: [ "partners", "condition_payout_state_reason", "reason", ],
    			render: this.renderCalculationReason,
    			title: `${ prefix }reason`,
    		},{
    			key: "calculationReasonCount",
    			path: [ "partners", "condition_payout_state_reason", "reason", ],
    			render: this.renderCalculationReasonCount,
    			title: `${ prefix }reason_count`,
    		},
    		{
    			key: "calculationDay",
    			orderId: "o:day",
    			path: [ "partners", "day_processing", "day", ],
    			render: this.renderInitialValue,
    			title: `${ prefix }day`,
    		},
    		{
    			key: "calculationInitiator",
    			orderId: "o:initiator",
    			path: [ "partners", "condition_payout_state_reason", "initiator_id", ],
    			render: this.renderUserName,
    			title: `${ prefix }initiator`,
    		},
    		{
    			key: "calculationComment",
    			orderId: "o:error",
    			path: [ "partners", "condition_payout_state", "error", ],
    			render: this.renderInitialValue,
    			title: `${ prefix }comment`,
    		},
    		{
    			key: "calculationStatus",
    			orderId: "o:status",
    			path: [ "partners", "condition_payout_state", "status", ],
    			render: this.renderCalculationStatus,
    			title: `${ prefix }status`,
    		},
    		{
    			key: "calculationCreatedAt",
    			orderId: "o:created",
    			path: [ "partners", "condition_payout_state", "created_at", ],
    			render: this.renderDate,
    			title: `${ prefix }created-at`,
    		},
    		{
    			key: "calculationUpdatedAt",
    			orderId: "o:updated",
    			path: [ "partners", "condition_payout_state", "updated_at", ],
    			render: this.renderDate,
    			title: `${ prefix }updated-at`,
    		},
    		{
    			customAccessCheck: ({ item, }) => {
    				return (
    					item.access("index")
    				);
    			},
    			key: "calculationActions",
    			path: [ "partners", "condition_payout_state_reason", "condition_payout_state_id", ],
    			render: this.renderActions,
    			title: `${ prefix }actions`,
    		},
    	].map(({
    		orderId: o, title: t, xtitle: x, ...item
    	}) => ({
    		...item,
    		...o && {
    			order: async () => {
    				await this.mf?.orderedToNext(o);
    			},
    			orderTo: this.mf?.orderedTo(o),
    		},
    		...t && { title: translate(t), },
    		...x && { title: x, },
    	}));
    }

	renderUserName = (value, { item, items, }) => {
		const partnerName = items?.userName;
		const partnerSurname = items?.userSurname;

		const fullName = `${ partnerName?.valueOf ? partnerName?.valueOf : '' } ${ partnerSurname?.valueOf ? partnerSurname?.valueOf : '' }`;

		if (item.access("index") && fullName.trim()) {
			return <Link to={ `/settings/user/${ +value }` }>{fullName}</Link>;
		}

		return "-";
	};
	renderCalculationReason = (reason, { item, }) => {
		if (item.access("index")) {
			const slicedArray = reason.length > 1 ? reason.slice(-1)[0] : reason; //ForMyltiplyReasons

			return (
				this.props.enums.data?.partners?.ConditionPayoutStateReasonEnum?.[+slicedArray] ?? "-"
			);
		}

		return "-";
	}
	renderCalculationReasonCount = (reason, { item, }) => {
		return	reason.length ? reason.length : "-";

	}
	renderInitialValueId = (value, { item, }) => {
		const valueForId = value.length > 1 ? value[value.length - 1] : value;

		if (item?.access?.("index")) {
			return (
				<MagicTooltip
					classNameTooltip="tooltip tooltip--large-width"
					content={ valueForId ?? "-" }
					lengthCut={ 20 }
				/>
			);
		}

		return "-";
	};
	renderInitialValue = (value, { item, }) => {
		if (item?.access?.("index")) {
			return (
				<MagicTooltip
					classNameTooltip="tooltip tooltip--large-width"
					content={ value ?? "-" }
					lengthCut={ 20 }
				/>
			);
		}

		return "-";
	};
	renderCalculationStatus = (status, { item, }) => {
		if (item.access("index")) {
			return (
				this.props.enums.data?.partners?.ConditionPayoutStateStatusEnum?.[+status] ??
				"-"
			);
		}

		return "-";
	};
	renderDate = (date, { item, }) => {
		if (item?.access?.("index")) {
			const formatDB = "YYYY-MM-DD HH:mm:ss";
			const formatOut = "YYYY-MM-DD HH:mm:ss";

			// eslint-disable-next-line new-cap
			const formatDate = Moment(date, formatDB);

			return formatDate.isValid() ? formatDate.format(formatOut) : "-";
		}

		return "-";
	};
	renderCalculationType = (type, { item, }) => {
		if (item.access("index")) {
			return (
				this.props.enums.data?.partners?.TypeEnum?.[+type] ?? "-"
			);
		}

		return "-";
	};
	renderCalculationLevel = (status, { item, }) => {
		if (item.access("index")) {
			return (
				this.props.enums.data?.partners?.LevelEnum?.[+status] ??
				"-"
			);
		}

		return "-";
	};
	restartCalculation = async (id) => {
		const { translate, } = this.props;

		try {
			await restartConditionPayoutCalculation(id);
			NotificationService.success({
				title: 'success',
				message: translate(`partner_dashboard_table_restart_notification`),
				remove: true,
				view: false,
			});
			this.save({ isViewed: false, });
		} catch (error) {
			error?.showErrorNotification?.();
		}

	}
	renderActions = (value, { item, data, }) => {
		if (data?.partners.condition_payout_state?.status !== 3) {
			return '-';
		}
		const { translate, } = this.props;

		const renderRestartButton = () => {
			const {
				partners: { condition_payout_state_reason, },
			} = data;
			const { isViewed, } = this.state;

			const sortedArray = condition_payout_state_reason.sort((a, b) => b.id - a.id);
			const slicedData = condition_payout_state_reason > 1 ? Object.assign({}, sortedArray[sortedArray.length - 1]): Object.assign({}, condition_payout_state_reason.slice(0, 1));
			const calculationStateId = slicedData[Object.keys(slicedData)].id;

			const options = {
				children: translate("partner_dashboard_table_restart"),
				className: "magic-button__item magic-button__item_partners-edit-symbol",
				onClick: () => this.restartCalculation(calculationStateId),
			};

			return isViewed &&
				<div className="magic-confirm-wrapper">
					<MagicButton { ...options } />
				</div>;

		};

		return (
			<>
				{item.access("index") && renderRestartButton()}
			</>
		);
	}

	renderTable = () => {
		const {
			loaded, pages, data, isEmptyData,
		} = this.state;
		const { translate, } = this.props;

		const options = {
			config: this.calculationDashboardTableConfig,
			data,
			head: [
				"calculationId",
				"calculationConditionType",
				"calculationConditionLevel",
				"calculationReason",
				"calculationReasonCount",
				"calculationDay",
				"calculationInitiator",
				"calculationComment",
				"calculationStatus",
				"calculationCreatedAt",
				"calculationUpdatedAt",
				"calculationActions",
			],
		};

		return (
			<>
				{/*eslint-disable-next-line max-len*/}
				<MagicFilterPanels mf={ this.mf } show={ true } translate={ translate } />
				<div
					className={ `table-wrapper partners__table ${
						isEmptyData ? "partners__table--empty" : ""
					}` }
				>
					<Loader loaded={ loaded } loading={ <Preloader scale={ 1 } /> }>
						<MagicTable { ...options } />
						{isEmptyData && (
							<div className="table__empty-text">
								{translate("symbols_empty")}
							</div>
						)}
						<PagePanel
							doText={ this.onPageText }
							doTo={ pageId => `?page=${ pageId }` }
							filter={ pages.filter }
							page={ this.pageId() }
							take={ pages.take }
							total={ pages.total }
							variance={ pages.variance }
							onClick={ this.onPageClick }
						/>
					</Loader>
				</div>
			</>
		);
	};
	render () {
    	return (
	<>
				{this.renderTable()}
			</>
    	);
	}
	pageId = () => {
		const page = this.query().get("page");

		return Number(page) || 0;
	};

	pageIdAsGET = (pageId) => {
		// eslint-disable-next-line no-param-reassign
		pageId = pageId === undefined ? this.pageId() : pageId;
		const { filterId, } = this.mf;

		return {
			filterId,
			skip: pageId * this.state.pages.take,
			take: this.state.pages.take,
		};
	};

	onDataLoad = async (pageId) => {
		await this.save({
			isEmptyData: false,
			loaded: false,
		});

		const pageOptions = this.pageIdAsGET(pageId);
		const loaded = (data, meta) => ({ pages, }) => ({
			data,
			pages: { ...pages, ...meta, },
			...!data.length && { isEmptyData: true, },
		});

		try {
			const response = await getDashboardCalculationPayout(pageOptions);
			const {
				data = [],
				meta: { filter = 0, total = 0, },
			} = response;

			await this.save(
				loaded(data, {
					filter,
					total,
				}),
			);
		} catch (error) {
			await this.save(
				loaded([], {
					filter: 0,
					total: 0,
				}),
			);
			error?.showErrorNotification?.();
		}
		await this.save({ loaded: true, });
	};

	onPageClick = ({ pageId, }) => {
		this.onDataLoad(pageId);
	};

	onPageText = (pageId, pageIs) => {
		const { translate, } = this.props;
		const {
			current, first, prev, next, last,
		} = pageIs;
		const {
			skipped, taken, filter, total,
		} = pageIs;

		if (skipped || taken) {
			const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : "?";
			const text = skipped ? translate("partners_pages_items") : "";

			return skipped ? `${ text }: ${ id }` : id;
		} else if (filter || total) {
			const id = Number.isInteger(pageId) ? `${ pageId }` : "?";
			const text = translate(
				filter ? "partners_pages_filtered" : "partners_pages_total",
			);

			return `${ text }: ${ id }`;
		} else if (first || prev || next || last) {
			return "";
		} else if (current) {
			return `${ pageId + 1 }`;
		}

		return `${ pageId + 1 }`;
	};
}

export default withRouter(withLocalize(CalculationPayoutDashboardMagicTable));