import React, { Component, } from 'react';
import { withLocalize, } from "react-localize-redux";
import PropTypes from "prop-types";
import Preloader from "../../../../../components/LoadingHOC/Preloader";
import Select from 'react-select';
import DatePicker from "react-date-picker";
import {
    getAccountIsROP,
    getAccountAddresses,
    getAccountAddressTypesEnum,
    getAccountTaxResidencies,
    getAccountChangeHistoryLastRequestByType,
    getAccountTinAbsenceReasons,
    getAccountCountries,
    getAccountDocumentComments,
    getAccountDocumentTypeRatioToSubType,
    getAccountDocumentParsedData,
    getAccountNationalities,
    postAccountCancelEmailRequest,
    postAccountCancelPhoneRequest,
    postAccountChangeEmailRequest,
    postAccountChangePhoneRequest,
    postAccountTaxResidencyDataChange,
    postAccountVerify,
    postAccountChangeHistoryRejectRequestTRD,
    postAccountChangeHistoryConfirmRequestTRD,
    postAccountIndividualVerificationConfirmRequest,
    postAccountIndividualVerificationCancelRequest,
    postAccountPersonalDataCancelRequest,
    postAccountPersonalDataConfirmRequest,
    putAccount,
    putAccountAddress,
    postAccountAddress,
    putAccountDocument,
    putAccountTaxResidencyDataChange,
    deleteAccountAddress,
    deleteAccountTaxResidencyDataChange,
    setAccountStatusActive,
    setAccountStatusArchive,
} from '../../../services/AccountService'
import Loader from "../../../../tima/components/Loader";
import { isEmpty, isNull, isUndefined, } from "lodash";
import moment from "moment";
import { MagicError, } from '../../../../../components/Magic';
import errorImg from "../../../../../images/error.svg";
import PermissionService from "../../../../../services/PermissionService";
import { MagicSwitch, } from "../../../../tima/components/Magic/MagicSwitch";
import NotificationService from "../../../../../services/NotificationService";
import { clientService, } from '../../../../../services/ClientDataService';
import PopUpSourceOfFunds from './PopUpSourceOfFunds';
import { getBlobUrlFromBase64 } from "./helpers/getBlobUrlFromBase64";


const DATE_FORMAT_LONG = 'YYYY-MM-DD HH:mm:ss';
const DATE_FORMAT_SHORT = 'YYYY-MM-DD';
const REG_EXP = {
    general: /^[а-яА-ЯёЁa-zA-ZІіЇїЄєҐґ0-9+*"'«»;:,.`№@\s\-]{1,}$/,

    cyrillic_latin:          /^[а-яА-ЯёЁa-zA-ZІіЇїЄєҐґ\-‐‑‒–—-’`'\s−–—\p{L}\p{Cs}]{1,}$/gu,
    latin:                   /^[a-zA-Z\-’`'−—‐‑‒–—-’\s]{1,}$/,
    document_number:         /^[а-яА-ЯёЁa-zA-ZІіЇїЄєҐґ0-9\s.−]{1,}$/,
    state_location:          /^[а-яА-ЯёЁa-zA-ZІіЇїЄєҐґ0-9,‐‑‒–—’''’'/\-/./\\-’`'\s\p{L}\p{Cs}\p{Cc}]{1,}$/gu,
    state_location_latin:    /^[a-zA-Z0-9,./\-’`'\s]{1,}$/,
    address:                 /^[а-яА-ЯёЁa-zA-ZІіЇїЄєҐґ0-9,./\-#()’`'\s\p{L}\p{Cs}]{1,}$/gu,
    address_latin:           /^[a-zA-Z0-9,./\-#()’`'\s]{1,}$/,
    zip_code:                /^[a-zA-Z0-9\s‐‑‒–—-]{1,}$/gu,
    tin:                     /^[a-zA-Z0-9\s\-−–—]{1,}$/,
    tin_absence_reason_text: /^[a-zA-Z0-9,."-()\s]{1,}$/,
    description:             /^[а-яА-ЯёЁa-zA-ZІіЇїЄєҐґ0-9,./\-#()’`'\s]{1,}$/,
};
Object.freeze(REG_EXP);

const FIELD_TYPE = {
    input:      'input',
    select:     'select',
    textarea:   'textarea',
    datepicker: 'datepicker',
    custom:     'custom',
};
Object.freeze(FIELD_TYPE);

const MENU_ITEMS_CONFIG = (translate) => ({
    personalData:    {
        name:          'personal-data',
        label:         translate(`client_verification_menu_personal_data`),
        stateListName: 'formData',
        order:         1,
    },
    clientAddress:   {
        name:          'client-address',
        label:         translate(`client_verification_menu_client_address`),
        stateListName: 'addressesData',
        order:         2,
    },
    statusData:      {
        name:          'client-status',
        label:         translate(`client_verification_menu_client_status`),
        stateListName: 'statusData',
        order:         3,
    },
    taxResidency:    {
        name:          'tax-residency',
        label:         translate(`client_verification_menu_tax_residency`),
        stateListName: 'taxResidenciesData',
        order:         4,
    },
    clientDocuments: {
        name:          'client-documents',
        label:         translate(`client_verification_menu_client_documents`),
        stateListName: 'documentsData',
        order:         5,
    },
});

class PopUpVerification extends Component {
    static propTypes = {
        popUpClose:        PropTypes.func.isRequired,
        refreshClientData: PropTypes.func.isRequired,
    };

    constructor (props) {
        super(props);

        const { activeDocumentIndex = 0, documents = [], translate } = props;

        this.state = {
            loaded:                            false,
            loadedInnerRequest:                true,
            isStatusDetailsBtnActive:          false,
            refreshClientDataFlag:             false,
            accountAddresses:                  [],
            accountAddressesOptions:           [],
            accountAddressTypesEnum:           [],
            accountNationalitiesOptions:       [],
            accountCountriesOptions:           [],
            accountDocumentCommentsOptions:    [],
            accountDocumentTypeRatioToSubType: [],
            accountTinAbsenceReasons:          [],
            accountChangeHistoryLastRequest:   {},
            accountDocuments:                  documents,
            activeDocumentIndex,
            isUserRop:                         false,
            isVerifyFundsModalOpen:            false,
            documentsArePinned:                false,
            successfullyConfirmedDocument:      false,
            successfullyRejectedDocument:      false,
            clickedConfirmFundsRequest:         false,
            clickedCancelFundsRequest:         false,
            statusSelectIsDisabled:            false,
            verificationError:                  "",
        };

        this.calcData = PermissionService.calc(this.popUpVerificationConfig);
        this.menuItems = MENU_ITEMS_CONFIG(translate);
        this.residencyActionOptions = this.getResidencyActionOptions(translate);
        this.accountStatusActionOptions = this.getAccountStatusActionOptions(translate);
    }

    get popUpVerificationConfig () {
        return [
            {
                path: ['core', 'account'],
                key:  'coreAccount',
            },
            {
                path: ['core', 'account_addresses'],
                key:  'coreAccountAddresses',
            },
            {
                path: ['core', 'account_document'],
                key:  'coreAccountDocument',
            },
            {
                path: ['core', 'account_tax_residency_data'],
                key:  'coreAccountTaxResidencyData',
            },
            {
                path: ['core', 'account_tax_residency_data_changes'],
                key:  'coreAccountTaxResidencyDataChanges',
            },
            {
                path: ['core', 'account_change_history'],
                key:  'coreAccountChangeHistory',
            },
            {
                path: ['core', 'account_change_email'],
                key:  'coreAccountChangeEmail',
            },
            {
                path: ['core', 'account_change_phone'],
                key:  'coreAccountChangePhone',
            },
            {
                path: ['core', 'account', 'status'],
                key:  'coreAccountStatus',
            },
            {
                path: ['core', 'account', 'surname'],
                key:  'surname',
            },
            {
                path: ['core', 'account', 'surname_latin'],
                key:  'surname_latin',
            },
            {
                path: ['core', 'account', 'name'],
                key:  'name',
            },
            {
                path: ['core', 'account', 'name_latin'],
                key:  'name_latin',
            },
            {
                path: ['core', 'account', 'patronymic'],
                key:  'patronymic',
            },
            {
                path: ['core', 'account', 'gender'],
                key:  'gender',
            },
            {
                path: ['core', 'account', 'birth_date'],
                key:  'birth_date',
            },
            {
                path: ['core', 'account', 'nationality_id'],
                key:  'nationality_id',
            },
            {
                path: ['core', 'account', 'person_identity_number'],
                key:  'person_identity_number',
            },
            {
                path: ['core', 'account', 'issue_date'],
                key:  'issue_date',
            },
            {
                path: ['core', 'account', 'issue_country_id'],
                key:  'issue_country_id',
            },

            {
                path: ['core', 'account_addresses', 'country_id'],
                key:  'country_id',
            },
            {
                path: ['core', 'account_addresses', 'lawyer_agreed'],
                key:  'lawyer_agreed',
            },
            {
                path: ['core', 'account_addresses', 'state'],
                key:  'state',
            },
            {
                path: ['core', 'account_addresses', 'state_latin'],
                key:  'state_latin',
            },
            {
                path: ['core', 'account_addresses', 'location'],
                key:  'location',
            },
            {
                path: ['core', 'account_addresses', 'location_latin'],
                key:  'location_latin',
            },
            {
                path: ['core', 'account_addresses', 'address'],
                key:  'address',
            },
            {
                path: ['core', 'account_addresses', 'address_latin'],
                key:  'address_latin',
            },
            {
                path: ['core', 'account_addresses', 'zip_code'],
                key:  'zip_code',
            },

            {
                path: ['core', 'account_document', 'type'],
                key:  'type',
            },
            {
                path: ['core', 'account_document', 'sub_type'],
                key:  'sub_type',
            },
            {
                path: ['core', 'account_document', 'status'],
                key:  'status',
            },
            {
                path: ['core', 'account_document', 'created_at'],
                key:  'created_at',
            },
            {
                path: ['core', 'account_document', 'updated_at'],
                key:  'updated_at',
            },
            {
                path: ['core', 'account_document', 'comment_id'],
                key:  'comment_id',
            },
            {
                path: ['core', 'account_document', 'description'],
                key:  'description',
            },

            {
                path: ['core', 'account_tax_residency_data', 'country_id'],
                key:  'trd_country_id',
            },
            {
                path: ['core', 'account_tax_residency_data', 'tin'],
                key:  'trd_tin',
            },
            {
                path: ['core', 'account_tax_residency_data', 'tin_absence_reason_id'],
                key:  'trd_tin_absence_reason_id',
            },
            {
                path: ['core', 'account_tax_residency_data', 'confirmed_status'],
                key:  'trd_confirmed_status',
            },
            {
                path: ['core', 'account_tax_residency_data', 'temporary_status'],
                key:  'trd_temporary_status',
            },
            {
                path: ['core', 'account_tax_residency_data', 'tin_absence_reason_text'],
                key:  'trd_tin_absence_reason_text',
            },

            {
                path: ['core', 'account_tax_residency_data_changes', 'country_id'],
                key:  'trdc_country_id',
            },
            {
                path: ['core', 'account_tax_residency_data_changes', 'tin'],
                key:  'trdc_tin',
            },
            {
                path: ['core', 'account_tax_residency_data_changes', 'tin_absence_reason_id'],
                key:  'trdc_tin_absence_reason_id',
            },
            {
                path: ['core', 'account_tax_residency_data_changes', 'status'],
                key:  'trdc_status',
            },
            {
                path: ['core', 'account_tax_residency_data_changes', 'temporary_status'],
                key:  'trdc_temporary_status',
            },
            {
                path: ['core', 'account_tax_residency_data_changes', 'tin_absence_reason_text'],
                key:  'trdc_tin_absence_reason_text',
            },
        ];
    }

    save = async (state) => new Promise(next => this.setState(state, next));

    componentDidMount = async () => {
        await this.save({ loaded: false, });

        await this.onDataLoad();
        await this.extendAccountAddresses();
        await this.save({
            formData:                  this.getInitialFormDataState(),
            addressesData:             this.getInitialAddressesDataState(),
            statusData:                this.getInitialStatusDataState(),
            taxResidenciesData:        this.getInitialTaxResidenciesDataState(),
            taxResidenciesChangesData: this.getInitialTaxResidenciesChangesDataState(),
            documentsData:             this.getInitialDocumentsDataState(),
            accountAddressesOptions:   this.getAccountAddressesOptions(),
        });

        await this.save({ loaded: true, });
        this.props?.isScrollToDocumentsBlock && this._smoothScrollToTargetElemByHref('#section-client-documents');
        await this.checkAccountForApprovedDocumentsAndOrder();
        await this.currentlyDisabledStatusOfSelect();
    };

    componentWillUnmount = () => {

    };

    popUpCloseFunc = () => {
        const { refreshClientDataFlag = false } = this.state;
        const { popUpClose, refreshClientData } = this.props;

        refreshClientDataFlag && refreshClientData();
        popUpClose();
    };

    onDataLoad = async () => {
        const accountId = this.props?.accountId;

        try {
            const [
                addressesFlag,
                addressTypesEnumFlag,
                taxResidenciesFlag,
                nationalitiesFlag,
                countriesFlag,
                documentCommentsFlag,
                documentTypesRatioFlag,
                tinAbsenceReasonsFlag,
                changeHistoryLastRequestEmailFlag,
                changeHistoryLastRequestPhoneFlag,
                changeHistoryLastRequestTRDFlag,
            ] = await Promise.all([
                this.loadAccountAddresses(),
                this.loadAccountAddressTypesEnum(),
                this.loadAccountTaxResidencies(),
                this.loadAccountNationalitiesOptions(),
                this.loadAccountCountriesOptions(),
                this.loadAccountDocumentCommentsOptions(),
                this.loadAccountDocumentTypeRatioToSubType(),
                this.loadAccountTinAbsenceReasons(),
                this.loadAccountChangeHistoryLastRequest('change_email'),
                this.loadAccountChangeHistoryLastRequest('change_phone'),
                this.loadAccountChangeHistoryLastRequest('change_tax_residency_data'),
                this.getAccountInfoForSourceApprove(accountId),
            ]);
        } catch (error) {

        }
    };

    getAccountInfoForSourceApprove = async (accountId) => {
        const account = await clientService.accountChangeHistoryForSourceApprove(accountId);

        await this.save({ sourceApproveAccount: account, });
    };

    checkAccountIsROP = async () => {
        try {
            const { userId } = this.props;
            const response = await getAccountIsROP(userId);

            await this.save({ isUserRop: response, });

            return true;
        } catch (error) {
            await this.save({ isUserRop: false, });

            return false;
        }
    };

    loadAccountTinAbsenceReasons = async () => {
        try {
            const response = await getAccountTinAbsenceReasons();
            const optionsArr = response?.map((item) => ({
                ...item,
                label: item?.name, value: item?.id
            })) ?? [];

            await this.save({ accountTinAbsenceReasons: optionsArr });

            return true;
        } catch (error) {
            await this.save({ accountTinAbsenceReasons: [], });
            error?.showErrorNotification?.();

            return false;
        }
    };

    loadAccountAddressTypesEnum = async () => {
        const accountId = this.props?.accountId;

        try {
            const response = await getAccountAddressTypesEnum(accountId);
            // AccountAddressesTypeEnum - all types (for phys/yur account)
            await this.save({ accountAddressTypesEnum: response });

            return true;
        } catch (error) {
            await this.save({ accountAddressTypesEnum: [], });
            error?.showErrorNotification?.();

            return false;
        }
    };

    loadAccountAddresses = async () => {
        const accountId = this.props?.accountId;

        try {
            const response = await getAccountAddresses(accountId);
            const accountAddresses = response?.data?.map((item) => item?.core?.account_addresses)?.sort((a, b) => a?.type - b?.type) ?? [];

            await this.save({ accountAddresses });

            return true;
        } catch (error) {
            await this.save({ accountAddresses: [], });
            error?.showErrorNotification?.();

            return false;
        }
    };

    loadAccountTaxResidencies = async () => {
        const accountId = this.props?.accountId;

        try {
            const response = await getAccountTaxResidencies(accountId);
            const accountTaxResidencies = response?.data?.map((item) => item?.core) ?? [];

            await this.save({ accountTaxResidencies });

            return true;
        } catch (error) {
            await this.save({ accountTaxResidencies: [], });
            // error?.showErrorNotification?.();

            return false;
        }
    };

    loadAccountDocumentTypeRatioToSubType = async () => {
        try {
            const response = await getAccountDocumentTypeRatioToSubType();
            // TODO: check if neccessary - to replace enum options from response data !!!

            await this.save({ accountDocumentTypeRatioToSubType: response, });

            return true;
        } catch (error) {
            await this.save({ accountDocumentTypeRatioToSubType: [], });
            error?.showErrorNotification?.();

            return false;
        }
    };

    loadAccountNationalitiesOptions = async () => {
        try {
            const response = await getAccountNationalities();
            const optionsArr = response?.map((item) => ({ label: item?.name, value: item?.id })) ?? [];

            await this.save({ accountNationalitiesOptions: optionsArr, });

            return true;
        } catch (error) {
            await this.save({ accountNationalitiesOptions: [], });
            error?.showErrorNotification?.();

            return false;
        }
    };

    loadAccountCountriesOptions = async () => {
        try {
            const response = await getAccountCountries();
            // is_work: '1' - not forbidden, '2' - forbidden
            // ?.filter((item) => item?.['is_work']?.toString() === '1')
            const optionsArr = response?.map((item) =>
                ({
                    label:   item?.name,
                    value:   item?.id,
                    is_work: item?.is_work,
                })) ?? [];

            await this.save({ accountCountriesOptions: optionsArr, });

            return true;
        } catch (error) {
            await this.save({ accountCountriesOptions: [], });
            error?.showErrorNotification?.();

            return false;
        }
    };

    loadAccountDocumentCommentsOptions = async () => {
        const { translate } = this.props;

        try {
            const response = await getAccountDocumentComments();

            const optionsArr = response?.map((item) => ({ label: item?.description, value: item?.id })) ?? [];

            optionsArr.push(
                {
                    label: translate('client_verification_label_document_custom_option'),
                    value: 'customComment',
                }
            );

            await this.save({ accountDocumentCommentsOptions: optionsArr, });

            return true;
        } catch (error) {
            await this.save({ accountDocumentCommentsOptions: [], });
            error?.showErrorNotification?.();

            return false;
        }
    };

    loadAccountChangeHistoryLastRequest = async (typeName) => {
        const accountId = this.props?.accountId;
        // AccountChangeHistoryTypeEnum
        const typeNameToTypeMap = {
            'change_personal_data':      1,
            'change_address':            2,
            'change_email':              3,
            'change_phone':              4,
            'change_password':           5,
            'change_tax_residency_data': 6,
        };

        try {
            let type = typeNameToTypeMap?.[typeName] ?? null;
            const response = await getAccountChangeHistoryLastRequestByType(accountId, type);

            await this.save(
                (state) => ({
                    accountChangeHistoryLastRequest: {
                        ...state.accountChangeHistoryLastRequest,
                        [typeName]: response,
                    },
                })
            );

            return true;
        } catch (error) {
            await this.save(
                (state) => ({
                    accountChangeHistoryLastRequest: {
                        ...state.accountChangeHistoryLastRequest,
                        [typeName]: null,
                    },
                })
            );
            // error?.showErrorNotification?.();

            return false;
        }
    };

    reLoadAccountTaxResidencies = async () => {
        await this.save({ loadedInnerRequest: false, });

        const taxResidenciesFlag = await this.loadAccountTaxResidencies();

        taxResidenciesFlag && await this.save({
            taxResidenciesData:        this.getInitialTaxResidenciesDataState(),
            taxResidenciesChangesData: this.getInitialTaxResidenciesChangesDataState(),
        });

        await this.save({ loadedInnerRequest: true, });
    };

    reLoadAccountChangeHistoryLastRequest = async (typeName) => {
        await this.save({ loadedInnerRequest: false, });

        await this.loadAccountChangeHistoryLastRequest(typeName);

        await this.save({ loadedInnerRequest: true, });
    };

    extendAccountAddresses = async () => {
        const { accountAddresses = [], accountAddressTypesEnum = [] } = this.state;
        const addressTypesEnumKeys = Object.keys(accountAddressTypesEnum)?.map((item) => +item);
        const currentAddressesTypes = accountAddresses?.map((item) => item?.type);
        //TODO: check the map for business accounts!
        const addressTypeToOrderMap = {
            1: 1,
            2: 3,
            3: 2,
            4: 4,
        }; // accountAddressTypesEnum

        let updateStateFlag = false;

        addressTypesEnumKeys?.forEach((item) => {
            if (!currentAddressesTypes?.includes(item)) {
                updateStateFlag = true;

                accountAddresses.push(
                    {
                        type:         item,
                        isNewAddress: true, // customFlag
                    }
                );
            }
        });

        const extendedAccountAddresses = accountAddresses?.map((item) => (
            {
                ...item,
                order: addressTypeToOrderMap?.[item?.type] ?? item?.type ?? null,
            }
        ));

        updateStateFlag && await this.save({ accountAddresses: extendedAccountAddresses?.sort((a, b) => a?.order - b?.order) });
    };

    getAccountAddressesOptions = () => {
        const { accountAddresses = [], accountAddressTypesEnum = [] } = this.state;

        const optionsArr = accountAddresses?.map((item, rowIndex) => (
            {
                label: accountAddressTypesEnum?.[item?.type],
                value: item?.type,
                type:  item?.type,
                rowIndex,
            }
        )) ?? [];

        return optionsArr;
    };

    getResidencyActionOptions = (translate) => {
        const labelPrefix = 'clients_verification_residency_action';

        return [
            { label: translate(`${labelPrefix}_delete`), value: 'delete' },
            { label: translate(`${labelPrefix}_edit`), value: 'edit' },
            { label: translate(`${labelPrefix}_restore`), value: 'restore' },
            { label: translate(`${labelPrefix}_undo`), value: 'undo' },
            { label: translate(`${labelPrefix}_update`), value: 'update' },
        ];
    };

    getAccountStatusActionOptions = (translate) => {
        const labelPrefix = 'clients_verification_account_status_action_request';

        return [
            { label: translate(`${labelPrefix}_confirm`), value: 'confirm' },
            { label: translate(`${labelPrefix}_cancel`), value: 'cancel' },
        ];
    };

    _isDocumentStatusRejected = (value) => value?.toString() === '4'; // AccountDocumentStatusEnum

    _isDocumentSystemSumSub = (value) => value?.toString() === '2'; // AccountDocumentSystemEnum: {1: "crm", 2: "sum_sub"}

    _isAddressTypeResidence = (type) => type?.toString() === '1';

    _isTinAbsenceReasonText = (value) => value?.toString() === '1';

    _isAccountChangeHistoryStatusClosed = (type) => ['3', '4', '5'].includes(this.state?.accountChangeHistoryLastRequest?.[type]?.status?.toString()) || isNull(this.state?.accountChangeHistoryLastRequest?.[type]); // AccountChangeHistoryStatusEnum

    _isAccountChangeHistoryStatusInProcess = (type) => this.state?.accountChangeHistoryLastRequest?.[type]?.status?.toString() === '1'; // AccountChangeHistoryStatusEnum

    _isAccountChangeHistoryStatusWaitConfirmation = (type) => this.state?.accountChangeHistoryLastRequest?.[type]?.status?.toString() === '2'; // AccountChangeHistoryStatusEnum

    _isAccountChangeHistoryStatusActive = (type) => this._isAccountChangeHistoryStatusInProcess(type) || this._isAccountChangeHistoryStatusWaitConfirmation(type); // AccountChangeHistoryStatusEnum

    _isAccountStatusActive = () => this?.state?.statusData?.status?.toString() === '1'; // AccountStatusEnum

    _isAccountStatusDeleted = () => this?.state?.statusData?.status?.toString() === '2'; // AccountStatusEnum

    _isAccountStatusArchived = () => this?.state?.statusData?.status?.toString() === '3'; // AccountStatusEnum

    _isAccountIndividualOrTest = (value) => ['1', '3'].includes(value?.toString()); // AccountRegisterTypeEnum

    _isSelectedCountryNeedsAgree = (isWorkVal) => ['3', '4'].includes(isWorkVal?.toString());

    getSubTypeOptionsBySelectedType = (selectedType) => {
        const { accountDocumentTypeRatioToSubType = [] } = this.state;
        const subTypeOptions = this._getOptionsFromEnumByField('sub_type');
        const filteredOptions = accountDocumentTypeRatioToSubType?.filter((item) => item?.name === selectedType)?.[0]?.sub_types?.map((item) => item?.name);
        const filteredFormattedOptions = subTypeOptions?.filter((item) => filteredOptions?.includes(item?.value)) ?? [];

        return filteredFormattedOptions;
    };

    getInitialDocumentsDataState = () => {
        const accountDocuments = this?.state?.accountDocuments;
        const resultObjArr = [];

        accountDocuments?.forEach((itemObj, rowIndex) => {
            resultObjArr.push(this.getInitialDocumentDataState(itemObj, rowIndex));
        });

        return resultObjArr;
    };

    _getInitialTransformObj = () => ({
        rotate: 0,
        scale:  1,
    });

    getInitialDocumentDataState = (itemObj = {}, rowIndex) => {
        const { departmentsListNames, } = this.props;

        const financialDepartment = 'financial'.includes(departmentsListNames);

        const defVal = '';
        const {
            id: documentId = null,
            type = defVal,
            sub_type = defVal,
            status = defVal,
            comment_id = defVal,
            description = defVal,
            created_at = defVal,
            updated_at = defVal,
        } = itemObj?.core?.account_document ?? {};

        const _checkInput = (val) => !isNull(val) && !isUndefined(val) ? val : ''; // Warning: `value` prop on `input` should not be null
        const isDocumentStatusRejected = this._isDocumentStatusRejected(status);
        const calcData = this.calcData;
        const pm = ['show', 'store', 'update'];

        let isStatusDisabled = false;

        if (calcData?.status?.access(...pm)) {
            if (!financialDepartment) {
                isStatusDisabled = (type === 'BANK_ACCOUNT_STATEMENT' || type === 'CONTRACT_OF_SALE');
            }
            if (financialDepartment) {
                if (type === 'BANK_ACCOUNT_STATEMENT' || type === 'CONTRACT_OF_SALE') {
                    isStatusDisabled = false;
                } else {
                    isStatusDisabled = true;
                }
            }
        }

        return {
            documentId,
            transformObj:                  this._getInitialTransformObj(),
            documentParsedData:            {},
            isDocumentParsedDataBtnActive: false,
            type:                          {
                data:         this._getSelectedOptionByValue('type', type),
                options:      this._getOptionsFromEnumByField('type'),
                isValid:      true,
                isDisabled:   !calcData?.type?.access(...pm),
                isRequired:   true,
                isToValidate: true,
                fieldType:    FIELD_TYPE.select,
            },
            sub_type:                      {
                data:         this._getSelectedOptionByValue('sub_type', sub_type),
                options:      this.getSubTypeOptionsBySelectedType(type),
                isValid:      true,
                isDisabled:   !calcData?.sub_type?.access(...pm),
                isRequired:   true,
                isToValidate: true,
                fieldType:    FIELD_TYPE.select,
            },
            status:                        {
                data:         this._getSelectedOptionByValue('status', status),
                options:      this._getOptionsFromEnumByField('status'),
                isValid:      true,
                isDisabled:   isStatusDisabled,
                isRequired:   true,
                isToValidate: true,
                fieldType:    FIELD_TYPE.select,
            },
            comment_id:                    {
                data:         this._getSelectedOptionByValue('comment_id', comment_id ? comment_id : 'customComment'),
                options:      this?.state?.accountDocumentCommentsOptions ?? [],
                isValid:      true,
                isDisabled:   !calcData?.comment_id?.access(...pm),
                isRequired:   isDocumentStatusRejected,
                isActive:     isDocumentStatusRejected,
                isToValidate: true,
                fieldType:    FIELD_TYPE.select,
            },
            description:                   {
                data:         _checkInput(description),
                isValid:      true,
                isDisabled:   !calcData?.description?.access(...pm),
                isRequired:   isNull(comment_id),
                isActive:     isNull(comment_id) && isDocumentStatusRejected,
                isToValidate: true,
                fieldType:    FIELD_TYPE.textarea,
            },
            created_at:                    {
                data:         _checkInput(created_at),
                isValid:      true,
                isDisabled:   true,
                isToValidate: false,
                fieldType:    FIELD_TYPE.input,
            },
            updated_at:                    {
                data:         _checkInput(updated_at),
                isValid:      true,
                isDisabled:   true,
                isToValidate: false,
                fieldType:    FIELD_TYPE.input,
            },
        };
    };

    getInitialAddressesDataState = () => {
        const accountAddresses = this?.state?.accountAddresses;
        const resultObjArr = [];

        accountAddresses?.forEach((itemObj, rowIndex) => {
            resultObjArr.push(this.getInitialAddressDataState(itemObj, rowIndex));
        });

        return resultObjArr;
    };

    getInitialAddressDataState = (itemObj = {}, rowIndex) => {
        const defVal = '';
        const {
            country_id = defVal,
            lawyer_agreed = null,
            state = defVal,
            state_latin = defVal,
            location = defVal,
            location_latin = defVal,
            address = defVal,
            address_latin = defVal,
            zip_code = defVal,
            type = defVal,
        } = itemObj;

        const _checkInput = (val) => !isNull(val) && !isUndefined(val) ? val : ''; // Warning: `value` prop on `input` should not be null
        const calcData = this.calcData;
        const pm = ['show', 'store', 'update'];
        const isAddressResidence = this._isAddressTypeResidence(type);
        // is_work = 2 - is PROHIBETED
        const countryOptionsFiltered = this?.state?.accountCountriesOptions?.filter(
            (item) => ['1', '3', '4'].includes(item?.is_work?.toString())
        ) ?? [];
        const selectedCountryId = this._getSelectedOptionByValue('country_id', country_id);
        const isSelectedCountryNeedsAgree = this._isSelectedCountryNeedsAgree(selectedCountryId?.is_work);

        return {
            country_id:     {
                data:         selectedCountryId,
                options:      countryOptionsFiltered,
                isValid:      true,
                isDisabled:   !calcData?.country_id?.access(...pm),
                isRequired:   isAddressResidence,
                isSearchable: true,
                isToValidate: true,
                fieldType:    FIELD_TYPE.select,
            },
            lawyer_agreed:  {
                data:         lawyer_agreed,
                isValid:      true,
                isDisabled:   !calcData?.lawyer_agreed?.access(...pm),
                isRequired:   false,
                isActive:     isSelectedCountryNeedsAgree,
                isToValidate: false,
                fieldType:    FIELD_TYPE.custom,
            },
            state:          {
                data:         _checkInput(state),
                isValid:      true,
                isDisabled:   !calcData?.state?.access(...pm),
                isRequired:   isAddressResidence,
                isToValidate: true,
                fieldType:    FIELD_TYPE.input,
            },
            state_latin:    {
                data:         _checkInput(state_latin),
                isValid:      true,
                isDisabled:   !calcData?.state_latin?.access(...pm),
                isRequired:   isAddressResidence,
                isToValidate: true,
                fieldType:    FIELD_TYPE.input,
            },
            location:       {
                data:         _checkInput(location),
                isValid:      true,
                isDisabled:   !calcData?.location?.access(...pm),
                isRequired:   isAddressResidence,
                isToValidate: true,
                fieldType:    FIELD_TYPE.input,
            },
            location_latin: {
                data:         _checkInput(location_latin),
                isValid:      true,
                isDisabled:   !calcData?.location_latin?.access(...pm),
                isRequired:   isAddressResidence,
                isToValidate: true,
                fieldType:    FIELD_TYPE.input,
            },
            address:        {
                data:         _checkInput(address),
                isValid:      true,
                isDisabled:   !calcData?.address?.access(...pm),
                isRequired:   isAddressResidence,
                isToValidate: true,
                fieldType:    FIELD_TYPE.input,
            },
            address_latin:  {
                data:         _checkInput(address_latin),
                isValid:      true,
                isDisabled:   !calcData?.address_latin?.access(...pm),
                isRequired:   isAddressResidence,
                isToValidate: true,
                fieldType:    FIELD_TYPE.input,
            },
            zip_code:       {
                data:         _checkInput(zip_code),
                isValid:      true,
                isDisabled:   !calcData?.zip_code?.access(...pm),
                isRequired:   isAddressResidence,
                isToValidate: true,
                fieldType:    FIELD_TYPE.input,
            },
        };
    };

    getInitialTaxResidenciesChangesDataState = () => {
        // AccountTaxResidencyDataChangeStatusEnum: 1 - 'new'
        // AccountTaxResidencyDataChangeActionEnum: 1 - 'edit', 2 - 'delete'
        const taxResidencies = this.state?.accountTaxResidencies?.map(
            (item) => {
                const account_tax_residency_data_changes = item?.account_tax_residency_data_changes?.filter(
                    (item) => item?.status?.toString() === '1' && ['1', '2'].includes(item?.action?.toString())
                ) ?? [];

                return account_tax_residency_data_changes?.length ? account_tax_residency_data_changes[0] : null; // TODO: check the quantity if more than 1
            }
        ) || [];

        const resultObjArr = [];

        taxResidencies?.forEach((itemObj, rowIndex) => {
            resultObjArr.push(!isNull(itemObj) ? this.getInitialTaxResidencyChangeDataState(itemObj, rowIndex) : null);
        });

        return resultObjArr;
    };

    getInitialTaxResidencyChangeDataState = (itemObj = {}, rowIndex) => {
        const defVal = '';
        const {
            id = null,
            action = null,
            country_id = defVal,
            tin = defVal,
            tin_absence_reason_id = defVal,
            status = null,
            tin_absence_reason_text = defVal,
        } = itemObj;

        const _checkInput = (val) => !isNull(val) && !isUndefined(val) ? val : ''; // Warning: `value` prop on `input` should not be null
        const calcData = this.calcData;
        const pm = ['show', 'store', 'update'];

        const selectedTinAbsenceReasonId = this._getSelectedOptionByValue('tin_absence_reason_id', tin_absence_reason_id);
        const isTinAbsenceReasonText = this._isTinAbsenceReasonText(selectedTinAbsenceReasonId?.is_text);

        const targetAccountTaxResidencyData = this.state?.accountTaxResidencies?.[rowIndex]?.account_tax_residency_data;
        const isTempStatusDeleted = targetAccountTaxResidencyData?.temporary_status?.toString() === '3';
        const accountTinAbsenceReasons = this?.state?.accountTinAbsenceReasons ?? [];
        const nullableOption = {
            label: this.props?.translate('clients_verification_select_option_placeholder') ?? '',
            value: null,
        };
        const accountTinAbsenceReasonsOptions = accountTinAbsenceReasons?.length ? [nullableOption, ...accountTinAbsenceReasons] : accountTinAbsenceReasons;

        // is_work = 2 - is PROHIBETED
        const countryOptionsFiltered = this?.state?.accountCountriesOptions?.filter(
            (item) => ['1', '3', '4'].includes(item?.is_work?.toString())
        ) ?? [];

        return {
            id,
            status,
            action,
            country_id:              {
                data:         this._getSelectedOptionByValue('country_id', country_id),
                options:      countryOptionsFiltered,
                isValid:      true,
                isDisabled:   !(calcData?.trdc_country_id?.access(...pm) && !isTempStatusDeleted),
                isRequired:   !isTempStatusDeleted,
                isSearchable: true,
                isToValidate: true,
                fieldType:    FIELD_TYPE.select,
            },
            tin:                     {
                data:         _checkInput(tin),
                isValid:      true,
                isDisabled:   !(calcData?.trdc_tin?.access(...pm) && !isTempStatusDeleted && isNull(selectedTinAbsenceReasonId)),
                isRequired:   !isTempStatusDeleted && isNull(selectedTinAbsenceReasonId),
                isToValidate: true,
                fieldType:    FIELD_TYPE.input,
            },
            tin_absence_reason_id:   {
                data:         selectedTinAbsenceReasonId,
                options:      accountTinAbsenceReasonsOptions,
                isValid:      true,
                isDisabled:   !(calcData?.trdc_tin_absence_reason_id?.access(...pm) && !isTempStatusDeleted),
                isRequired:   !isTempStatusDeleted && !isNull(selectedTinAbsenceReasonId),
                isToValidate: true,
                fieldType:    FIELD_TYPE.select,
            },
            tin_absence_reason_text: {
                data:         _checkInput(tin_absence_reason_text),
                isValid:      true,
                isDisabled:   !(calcData?.trdc_tin_absence_reason_text?.access(...pm) && !isTempStatusDeleted),
                isRequired:   !isTempStatusDeleted,
                isActive:     isTinAbsenceReasonText, // && isNull(selectedTinAbsenceReasonId),
                isToValidate: true,
                fieldType:    FIELD_TYPE.textarea,
            },
        };
    };

    getInitialTaxResidenciesDataState = () => {
        // AccountTaxResidencyDataConfirmedStatusEnum: 1 - 'signed'
        const taxResidencies = this.state?.accountTaxResidencies?.map(
            (item) => item?.account_tax_residency_data
        )?.map(
            (item) => item?.confirmed_status?.toString() === '1' /*&& isNull(item?.temporary_status)*/ ? item : null
        );

        const resultObjArr = [];

        taxResidencies?.forEach((itemObj, rowIndex) => {
            resultObjArr.push(!isNull(itemObj) ? this.getInitialTaxResidencyDataState(itemObj, rowIndex) : null);
        });

        return resultObjArr;
    };

    getInitialTaxResidencyDataState = (itemObj = {}, rowIndex) => {
        const defVal = '';
        const {
            id = null,
            action = null,
            temporary_status = null,
            country_id = defVal,
            tin = defVal,
            tin_absence_reason_id = defVal,
            confirmed_status = null,
            tin_absence_reason_text = defVal,
        } = itemObj;

        const _checkInput = (val) => !isNull(val) && !isUndefined(val) ? val : ''; // Warning: `value` prop on `input` should not be null
        const calcData = this.calcData;
        const pm = ['show', 'store', 'update'];

        const selectedTinAbsenceReasonId = this._getSelectedOptionByValue('tin_absence_reason_id', tin_absence_reason_id);
        const isTinAbsenceReasonText = this._isTinAbsenceReasonText(selectedTinAbsenceReasonId?.is_text);

        return {
            id,
            status:                  confirmed_status,
            action,
            temporary_status,
            country_id:              {
                data:         this._getSelectedOptionByValue('country_id', country_id),
                options:      this?.state?.accountCountriesOptions ?? [],
                isValid:      true,
                isDisabled:   true,
                isSearchable: true,
                isToValidate: true,
                fieldType:    FIELD_TYPE.select,
            },
            tin:                     {
                data:         _checkInput(tin),
                isValid:      true,
                isDisabled:   true,
                isToValidate: true,
                fieldType:    FIELD_TYPE.input,
            },
            tin_absence_reason_id:   {
                data:         selectedTinAbsenceReasonId,
                options:      this?.state?.accountTinAbsenceReasons ?? [],
                isValid:      true,
                isDisabled:   true,
                isToValidate: true,
                fieldType:    FIELD_TYPE.select,
            },
            tin_absence_reason_text: {
                data:         _checkInput(tin_absence_reason_text),
                isValid:      true,
                isDisabled:   true,
                isActive:     isTinAbsenceReasonText,
                isToValidate: true,
                fieldType:    FIELD_TYPE.textarea,
            },
        };
    };

    getInitialStatusDataState = () => {
        const defVal = '';
        const { client = {} } = this.props;
        const { account = {} } = client;
        const {
            status = defVal,
            is_verify_document = defVal,
        } = account;

        const calcData = this.calcData;
        const pm = ['show', 'store', 'update'];

        return {
            status,
            is_verify_document,
        };
    };

    getInitialFormDataState = () => {
        const defVal = '';
        const { client = {} } = this.props;
        const { account = {} } = client;
        const {
            surname = defVal,
            surname_latin = defVal,
            name = defVal,
            name_latin = defVal,
            patronymic = defVal,
            gender = defVal,
            birth_date = defVal,
            nationality_id = defVal,
            person_identity_number = defVal,
            issue_date = defVal,
            issue_country_id = defVal,
        } = account;

        const _checkInput = (val) => !isNull(val) && !isUndefined(val) ? val : ''; // Warning: `value` prop on `input` should not be null
        const calcData = this.calcData;
        const pm = ['show', 'store', 'update'];

        return {
            surname:                {
                data:         _checkInput(surname),
                isValid:      true,
                isDisabled:   !calcData?.surname?.access(...pm),
                isRequired:   true,
                isToValidate: true,
                fieldType:    FIELD_TYPE.input,
            },
            surname_latin:          {
                data:         _checkInput(surname_latin),
                isValid:      true,
                isDisabled:   !calcData?.surname_latin?.access(...pm),
                isRequired:   true,
                isToValidate: true,
                fieldType:    FIELD_TYPE.input,
            },
            name:                   {
                data:         _checkInput(name),
                isValid:      true,
                isDisabled:   !calcData?.name?.access(...pm),
                isRequired:   true,
                isToValidate: true,
                fieldType:    FIELD_TYPE.input,
            },
            name_latin:             {
                data:         _checkInput(name_latin),
                isValid:      true,
                isDisabled:   !calcData?.name_latin?.access(...pm),
                isRequired:   true,
                isToValidate: true,
                fieldType:    FIELD_TYPE.input,
            },
            patronymic:             {
                data:         _checkInput(patronymic),
                isValid:      true,
                isDisabled:   !calcData?.patronymic?.access(...pm),
                isToValidate: true,
                fieldType:    FIELD_TYPE.input,
            },
            gender:                 {
                data:         this._getSelectedOptionByValue('gender', gender),
                options:      this._getOptionsFromEnumByField('gender'),
                isValid:      true,
                isDisabled:   !calcData?.gender?.access(...pm),
                isToValidate: true,
                fieldType:    FIELD_TYPE.select,
            },
            birth_date:             {
                data:         birth_date ? new Date(birth_date) : null, // native Date format for DatePicker
                isValid:      true,
                isDisabled:   !calcData?.birth_date?.access(...pm),
                isRequired:   true,
                isToValidate: true,
                fieldType:    FIELD_TYPE.datepicker,
            },
            nationality_id:         {
                data:         this._getSelectedOptionByValue('nationality_id', nationality_id),
                options:      this?.state?.accountNationalitiesOptions ?? [],
                isValid:      true,
                isDisabled:   !calcData?.nationality_id?.access(...pm),
                isRequired:   true,
                isSearchable: true,
                isToValidate: true,
                fieldType:    FIELD_TYPE.select,
            },
            person_identity_number: {
                data:         _checkInput(person_identity_number),
                isValid:      true,
                isDisabled:   !calcData?.person_identity_number?.access(...pm),
                isToValidate: true,
                fieldType:    FIELD_TYPE.input,
            },
            issue_date:             {
                data:         issue_date ? new Date(issue_date) : null, // native Date format for DatePicker
                isValid:      true,
                isDisabled:   !calcData?.issue_date?.access(...pm),
                isToValidate: true,
                fieldType:    FIELD_TYPE.datepicker,
            },
            issue_country_id:       {
                data:         this._getSelectedOptionByValue('issue_country_id', issue_country_id),
                options:      this?.state?.accountCountriesOptions ?? [],
                isValid:      true,
                isDisabled:   !calcData?.issue_country_id?.access(...pm),
                isSearchable: true,
                isToValidate: true,
                fieldType:    FIELD_TYPE.select,
            },
        };
    };

    values = (fieldName) => {
        const { enums } = this.props;
        const enumAsObjectToKeys = (_) => [...Object.entries(_).map(([key]) => key)];

        switch (fieldName) {
            case 'lawyer_agreed':
                return enumAsObjectToKeys(enums?.AccountAddressesLawyerAgreedEnum).map(_ => 2 - _).reverse();
            default:
                throw new Error(`Error index( values: ${ fieldName } }`);
        }
    };

    _getOptionsFromEnumByField = (fieldName) => {
        const { enums = {} } = this.props;
        let namesArr = [];

        try {
            let enumObj = {};

            switch (fieldName) {
                case 'gender': {
                    enumObj = enums.AccountGenderEnum;
                    break;
                }
                case 'type': {
                    enumObj = enums.AccountDocumentTypeEnum;
                    break;
                }
                case 'sub_type': {
                    enumObj = enums.AccountDocumentSubTypeEnum;
                    break;
                }
                case 'status': {
                    enumObj = enums.AccountDocumentStatusEnum;
                    break;
                }
                default: {
                    throw new Error(`_getOptionsFromEnumByField: No such enum for field - ${fieldName}`);
                }
            }

            namesArr = Object.entries(enumObj)?.map(([key, value]) => ({ label: value, value: key })) ?? [];
        } catch (error) {
            console.log(error);
        }

        return namesArr;
    };

    _getSelectedOptionByValue = (fieldName, fieldValue) => {
        let options = [];

        switch (fieldName) {
            case 'gender':
            case 'type':
            case 'sub_type':
            case 'status': {
                options = this._getOptionsFromEnumByField(fieldName);
                break;
            }
            case 'nationality_id': {
                options = this?.state?.accountNationalitiesOptions ?? [];
                break;
            }
            case 'country_id':
            case 'issue_country_id': {
                options = this?.state?.accountCountriesOptions ?? [];
                break;
            }
            case 'comment_id': {
                options = this?.state?.accountDocumentCommentsOptions ?? [];
                break;
            }
            case 'tin_absence_reason_id': {
                options = this?.state?.accountTinAbsenceReasons ?? [];
                break;
            }

            default: {
                break;
            }
        }

        const selectedOption = options?.filter((item) => item?.value?.toString() === fieldValue?.toString())?.[0] ?? null;

        return selectedOption;
    };

    _getFormDataToSend = (propertyName, rowIndex) => {
        const dataObj = [
            'documentsData',
            'addressesData',
            'taxResidenciesData',
            'taxResidenciesChangesData'
        ].includes(propertyName) ?
            (this.state?.[propertyName]?.[rowIndex] ?? {}) :
            (this.state?.[propertyName] ?? {});
        const resData = {};

        const _isNotEmptyField = (fieldVal) => {
            return fieldVal !== '' && fieldVal !== null && fieldVal !== undefined && fieldVal !== 'Invalid date';
        };

        for (let key in dataObj) {
            const curFieldObj = dataObj?.[key] ?? {};
            const { data, fieldType, isActive = true } = curFieldObj;

            let skipFlag = false;
            let curVal;

            switch (fieldType) {
                case FIELD_TYPE.input:
                case FIELD_TYPE.textarea: {
                    curVal = data;
                    break;
                }
                case FIELD_TYPE.select: {
                    curVal = data?.value;
                    break;
                }
                case FIELD_TYPE.datepicker: {
                    curVal = moment(data).format(DATE_FORMAT_SHORT);
                    break;
                }
                case FIELD_TYPE.custom: {
                    if (key === 'lawyer_agreed') {
                        curVal = 2 - data;
                    } else {
                        skipFlag = true;
                    }

                    break;
                }
                default: {
                    skipFlag = true;
                    break;
                }
            }

            if (isActive && !skipFlag) {
                if (_isNotEmptyField(curVal) && curVal !== 'customComment') {
                    resData[key] = curVal;
                } else if (!_isNotEmptyField(curVal)) {
                    resData[`SET_NULL__${key}`] = 'fuf';
                }
            }
        }

        return resData;
    };

    onUpdateAccountDataClick = async () => {
        const accountId = this.props?.accountId;
        const propertyName = 'formData';
        const { isValidStatus } = await this.validateFormDataFields(propertyName);

        if (isValidStatus) {
            await this.save({ loadedInnerRequest: false, });

            try {
                const formDataToSend = this._getFormDataToSend(propertyName);
                const response = await putAccount(accountId, formDataToSend);

                if (response?.error) {
                    throw new MagicError(Error(response?.error)); // custom error from back-end
                }

                await this.save({ refreshClientDataFlag: true });
            } catch (error) {
                const errors = error?.response?.data?.errors ?? {};
                const newState = this.state;

                for (let fieldName in errors) {
                    if (fieldName in newState?.[propertyName]) {
                        newState[propertyName][fieldName]['isValid'] = false;
                    }
                }

                await this.save(newState);

                error?.showErrorNotification?.();
            }

            await this.save({ loadedInnerRequest: true, });
        }
    };

    onAccountAddressActionBtnClick = (action, item, rowIndex) => async () => {
        const propertyName = 'addressesData';
        const accountId = this.props?.accountId;
        const { accountAddresses = [] } = this.state;
        const { id: addressId, type } = item;
        const { isValidStatus } = await this.validateFormDataFields(propertyName, rowIndex);

        if (isValidStatus) {
            await this.save({ loadedInnerRequest: false, });

            try {
                const addressDataToSend = this._getFormDataToSend(propertyName, rowIndex);
                let response;

                switch (action) {
                    case 'create': {
                        response = await postAccountAddress({ type, account_id: accountId }, addressDataToSend);

                        const newState = this.state;

                        if (response instanceof Object && 'isNewAddress' in newState?.['accountAddresses']?.[rowIndex]) {
                            newState['accountAddresses'][rowIndex] = { ...response, isNewAddress: false }; // update initial state

                            await this.save(newState);
                        }

                        break;
                    }
                    case 'delete': {
                        response = await deleteAccountAddress(addressId);

                        const newState = this.state;

                        if (response) {
                            newState['accountAddresses'][rowIndex] = { type, isNewAddress: true }; // restore initial state
                            newState['addressesData'][rowIndex] = this.getInitialAddressDataState(newState['accountAddresses'][rowIndex], rowIndex);

                            await this.save(newState);
                        }

                        break;
                    }
                    case 'update':
                    default: {
                        response = await putAccountAddress(addressId, addressDataToSend);

                        break;
                    }
                }

                if (!response) {
                    throw new MagicError(Error(`Can*t make '${action}' action!`)); // custom error from back-end
                } else if (response?.error) {
                    throw new MagicError(Error(response?.error)); // custom error from back-end
                }

                await this.save({ refreshClientDataFlag: true });
            } catch (error) {
                const errors = error?.response?.data?.errors ?? {};
                const newState = this.state;

                for (let fieldName in errors) {
                    if (fieldName in newState?.[propertyName]?.[rowIndex]) {
                        newState[propertyName][rowIndex][fieldName]['isValid'] = false;
                    }
                }

                await this.save(newState);

                error?.showErrorNotification?.();
            }

            await this.save({ loadedInnerRequest: true, });
        }
    };

    onUpdateAccountDocumentDataClick = async () => {
        const propertyName = 'documentsData';
        const rowIndex = this.state?.activeDocumentIndex;
        const documentId = this.state?.[propertyName]?.[rowIndex]?.documentId;
        const { isValidStatus } = await this.validateFormDataFields(propertyName, rowIndex);

        if (isValidStatus) {
            await this.save({ loadedInnerRequest: false, });

            try {
                const documentsDataToSend = this._getFormDataToSend(propertyName, rowIndex);
                const response = await putAccountDocument(documentId, documentsDataToSend);

                if (response?.error) {
                    throw new MagicError(Error(response?.error)); // custom error from back-end
                }

                const referencePropertyName = 'accountDocuments';
                const statePropertyName = 'documentsData';

                // update reference object
                response && await this.save((state) => {
                    const referenceList = state?.[referencePropertyName];
                    const referenceCurrentRow = referenceList?.[rowIndex];

                    referenceCurrentRow['core']['account_document'] = response;

                    return { [referencePropertyName]: referenceList, };
                });

                // after update state object
                response && await this.save((state) => {
                    const itemObj = state[referencePropertyName][rowIndex];
                    const documentDataState = this.getInitialDocumentDataState(itemObj, rowIndex);

                    return {
                        [statePropertyName]: {
                            ...state[statePropertyName],
                            [rowIndex]: documentDataState,
                        },
                    };
                });

                await this.save({ refreshClientDataFlag: true });
            } catch (error) {
                const errors = error?.response?.data?.errors ?? {};
                const newState = this.state;

                for (let fieldName in errors) {
                    if (fieldName in newState?.[propertyName]?.[rowIndex]) {
                        newState[propertyName][rowIndex][fieldName]['isValid'] = false;

                        if (fieldName === 'description') {
                            newState[propertyName][rowIndex]['comment_id']['isValid'] = false;
                        }
                    }
                }

                await this.save(newState);

                error?.showErrorNotification?.();
            }

            await this.save({ loadedInnerRequest: true, });
        }
    };

    onResidencyCancelConfirmRequestBtnClick = (mode, id) => async () => {
        let postFunc = null;

        switch (mode) {
            case 'cancel': {
                postFunc = postAccountChangeHistoryRejectRequestTRD;
                break;
            }
            case 'confirm': {
                postFunc = postAccountChangeHistoryConfirmRequestTRD;
                break;
            }
            default: {
                throw new Error(`Can*t do the: '${mode}', no necessary post request!`);
            }
        }

        await this.save({ loadedInnerRequest: false, });

        try {
            const response = await postFunc(id);

            response && await this.reLoadAccountChangeHistoryLastRequest('change_tax_residency_data');
            response && await this.reLoadAccountTaxResidencies();
        } catch (error) {
            error?.showErrorNotification?.();
        }

        await this.save({ loadedInnerRequest: true, });
    };

    onUpdateEmailPhoneBtnClick = (mode, id, rowIndex) => async () => {
        const propertyName = 'documentsData';

        const _saveDocumentDataToState = async (dataName, data) => {
            await this.save((state) => {
                const tmpList = state?.[propertyName];
                const currentRow = tmpList?.[rowIndex];

                currentRow[dataName] = data;

                const newState = { [propertyName]: tmpList };

                return newState;
            });
        };

        let postFunc = null;

        switch (mode) {
            case 'email': {
                postFunc = postAccountChangeEmailRequest;
                break;
            }
            case 'phone': {
                postFunc = postAccountChangePhoneRequest;
                break;
            }
            default: {
                throw new Error(`Can*t change the: '${mode}', no necessary post request!`);
            }
        }

        await this.save({ loadedInnerRequest: false, });

        try {
            const response = await postFunc(id);

            response && await _saveDocumentDataToState(`${mode}ChangeStatus`, 1);
            response && await this.save({ refreshClientDataFlag: true });
        } catch (error) {
            error?.showErrorNotification?.();
        }

        await this.save({ loadedInnerRequest: true, });
    };

    onCancelRequestBtnClick = (mode, id, rowIndex) => async () => {
        const propertyName = 'documentsData';

        const _saveDocumentDataToState = async (dataName, data) => {
            await this.save((state) => {
                const tmpList = state?.[propertyName];
                const currentRow = tmpList?.[rowIndex];

                currentRow[dataName] = data;

                const newState = { [propertyName]: tmpList };

                return newState;
            });
        };

        let postFunc = null;

        switch (mode) {
            case 'email': {
                postFunc = postAccountCancelEmailRequest;
                break;
            }
            case 'phone': {
                postFunc = postAccountCancelPhoneRequest;
                break;
            }
            default: {
                throw new Error(`Can*t cancel the: '${mode}', no necessary post request!`);
            }
        }

        await this.save({ loadedInnerRequest: false, });

        try {
            const response = await postFunc(id);

            response && await _saveDocumentDataToState(`${mode}ChangeStatus`, 0);
            response && await this.save({ refreshClientDataFlag: true });
        } catch (error) {
            error?.showErrorNotification?.();
        }

        await this.save({ loadedInnerRequest: true, });
    };

    onDocumentStatusDetailsBtnClick = async () => {
        await this.save({ isStatusDetailsBtnActive: !this.state.isStatusDetailsBtnActive });
    };

    onDocumentParsedDataBtnClick = () => async () => {
        const propertyName = 'documentsData';

        const { activeDocumentIndex = 0, accountDocuments = [], documentsData = [] } = this.state;
        const { isDocumentParsedDataBtnActive = false, documentId = null, documentParsedData = {} } = documentsData?.[activeDocumentIndex] ?? {};
        const isDocumentParsedDataBtnActiveNew = !isDocumentParsedDataBtnActive;

        const _saveDocumentDataToState = async (dataName, data) => {
            await this.save((state) => {
                const tmpList = state?.[propertyName];
                const currentRow = tmpList?.[activeDocumentIndex];

                currentRow[dataName] = data;

                const newState = { [propertyName]: tmpList };

                return newState;
            });
        };

        if (isDocumentParsedDataBtnActiveNew) {
            await this.save({ loadedInnerRequest: false, });

            try {
                const response = await getAccountDocumentParsedData(documentId);

                await _saveDocumentDataToState('documentParsedData', response);
                await _saveDocumentDataToState('isDocumentParsedDataBtnActive', true);
            } catch (error) {
                await _saveDocumentDataToState('documentParsedData', {});
                await _saveDocumentDataToState('isDocumentParsedDataBtnActive', false);
                error?.showErrorNotification?.();
            }

            await this.save({ loadedInnerRequest: true, });
        } else {
            await _saveDocumentDataToState('isDocumentParsedDataBtnActive', false);
        }
    };

    getRegExpByFieldName = (fieldName, propertyName) => {
        let regExp = '';
        let maxLength = 255;

        //TODO: check the regExp for each field !!!
        switch (fieldName) {
            case 'surname':
            case 'name':
            case 'patronymic': {
                regExp = REG_EXP.cyrillic_latin;
                break;
            }
            case 'surname_latin':
            case 'name_latin': {
                regExp = REG_EXP.latin;
                break;
            }

            case 'person_identity_number': {
                regExp = REG_EXP.document_number;
                break;
            }

            case 'state':
            case 'location': {
                regExp = REG_EXP.state_location;
                break;
            }
            case 'state_latin':
            case 'location_latin': {
                regExp = REG_EXP.state_location_latin;
                break;
            }

            case 'address': {
                regExp = REG_EXP.address;
                break;
            }
            case 'address_latin': {
                regExp = REG_EXP.address_latin;
                break;
            }

            case 'zip_code': {
                regExp = REG_EXP.zip_code;
                break;
            }

            case 'tin': {
                regExp = REG_EXP.tin;
                maxLength = 20;
                break;
            }

            case 'tin_absence_reason_text': {
                regExp = REG_EXP.tin_absence_reason_text;
                break;
            }

            case 'description': {
                regExp = REG_EXP.description;
                maxLength = 100;
                break;
            }
            default: {
                regExp = REG_EXP.general;
                break;
            }
        }

        return { regExp, maxLength };
    };

    isValidField = (fieldObj) => {
        const { propertyName, name, fieldType, value } = fieldObj;

        let isEmpty = false;
        let isValid = false;

        switch (fieldType) {
            case FIELD_TYPE.datepicker: {
                isValid = moment(value, DATE_FORMAT_SHORT).isValid();
                isEmpty = !isValid;
                break;
            }
            case FIELD_TYPE.select: {
                isValid = !!(value && value !== "");
                isEmpty = !isValid;
                break;
            }

            case FIELD_TYPE.input:
            case FIELD_TYPE.textarea: {
                isEmpty = value?.trim() === '' || value === null || value === undefined;

                try {
                    const { regExp, maxLength, } = this.getRegExpByFieldName(name, propertyName);

                    isValid = ((value?.length <= maxLength) && regExp?.test(value));
                } catch (error) {
                    console.log('isValidField(): No such field: ', name);
                }
                break;
            }
            default: {
                break;
            }
        }

        return { isValid, isEmpty };
    };

    _checkRowIndex = (rowIndex) => !isNaN(rowIndex) && rowIndex >= 0;

    _getValidatedFormDataFieldsState = (propertyName, rowIndex, notToChangeState = false) => {
        const noValidateFields = []; // reserved

        let listData = {};
        let isValidStatus = true;
        let isAllFieldsEmpty = true;

        if (this._checkRowIndex(rowIndex)) {
            listData = this.state?.[propertyName]?.[rowIndex] ?? {};
        } else {
            listData = this.state?.[propertyName] ?? {};
        }

        for (let fieldName in listData) {
            if (listData?.[fieldName]?.isToValidate && !noValidateFields.includes(fieldName)) {
                const curDataObj = listData[fieldName];
                const { fieldType, data, isRequired = false, isActive = true } = curDataObj;
                const { isValid, isEmpty } = this.isValidField({
                    name:  fieldName,
                    propertyName,
                    fieldType,
                    value: data,
                });
                const isValidNew = !isActive ? (isRequired ? (!isEmpty && isValid) : (isValid || isEmpty)) : true;

                if (!notToChangeState) {
                    curDataObj.isValid = isValidNew;
                    curDataObj.isEmpty = isEmpty;

                    if ([FIELD_TYPE.input, FIELD_TYPE.textarea].includes(fieldType)) {
                        curDataObj.data = data?.trim() ?? data;
                    }
                }

                if (!isValidNew) {
                    isValidStatus = false;
                }

                if (!isEmpty) {
                    isAllFieldsEmpty = false;
                }
            }
        }

        return { isValidStatus, isAllFieldsEmpty, listData };
    };

    validateFormDataFields = async (propertyName, rowIndex) => {
        const { isValidStatus, isAllFieldsEmpty, listData } = this._getValidatedFormDataFieldsState(propertyName, rowIndex);
        let newState;

        if (this._checkRowIndex(rowIndex)) {
            const tmpList = this.state?.[propertyName];

            tmpList[rowIndex] = listData;
            newState = { [propertyName]: tmpList };
        } else {
            newState = { [propertyName]: listData };
        }

        await this.save(newState);

        return { isValidStatus, isAllFieldsEmpty };
    };

    onInputChange = (fieldName, propertyName, rowIndex) => async (event) => {
        const { value } = event.target;
        const { isValid, isEmpty } = this.isValidField({
            name:      fieldName,
            propertyName,
            fieldType: FIELD_TYPE.input,
            value,
        });

        if (isValid || isEmpty) {
            let newState = {};

            if (this._checkRowIndex(rowIndex)) {
                const tmpList = this.state?.[propertyName];
                const currentRow = tmpList?.[rowIndex];

                currentRow[fieldName].data = value;
                currentRow[fieldName].isValid = true; // reset error onInput
                newState = { [propertyName]: tmpList };
            } else {
                newState = {
                    [propertyName]: {
                        ...this.state?.[propertyName],
                        [fieldName]: {
                            ...this.state?.[propertyName]?.[fieldName],
                            data:    value,
                            isValid: true, // reset error onInput
                        },
                    },
                };
            }

            await this.save(newState);
        }
    };

    onSelectChange = (fieldName, propertyName, rowIndex) => async (option) => {
        const { departmentsListNames, } = this.props;
        const calcData = this.calcData;
        const pm = ['show', 'store', 'update'];

        const financialDepartment = 'financial'.includes(departmentsListNames);

        const _saveOptionToState = async () => {
            await this.save((state) => {
                let newState = {};

                if (this._checkRowIndex(rowIndex)) {
                    const tmpList = state?.[propertyName];
                    const currentRow = tmpList?.[rowIndex];
                    currentRow[fieldName].data = option;
                    currentRow[fieldName].isValid = true; // reset error onInput

                    if (calcData?.status?.access(...pm)) {
                        if (currentRow['status'] === '1' ||  !isEmpty(currentRow['address'])) return  newState = { [propertyName]: tmpList };
                        if (!financialDepartment && (currentRow['type']?.data?.value === 'BANK_ACCOUNT_STATEMENT' || currentRow['type']?.data?.value === 'CONTRACT_OF_SALE')) {
                                currentRow['status'].isDisabled = true;
                                newState = { [propertyName]: tmpList, };
                            } else {
                            currentRow['status'].isDisabled = false;
                            newState = { [propertyName]: tmpList, };
                        }


                        if (financialDepartment && !( currentRow['type']?.data?.value === 'BANK_ACCOUNT_STATEMENT' || currentRow['type']?.data?.value === 'CONTRACT_OF_SALE')) {
                            currentRow['status'].isDisabled = true;

                                newState = { [propertyName]: tmpList, };
                            }
                    }
                    newState = { [propertyName]: tmpList };
                } else {
                    newState = {
                        [propertyName]: {
                            ...state?.[propertyName],
                            [fieldName]: {
                                ...state?.[propertyName]?.[fieldName],
                                data:    option,
                                isValid: true, // reset error onInput
                            },
                        },
                    };
                }

                return newState;
            });
        };

        await _saveOptionToState();

        if (
            propertyName === 'documentsData' &&
            this._checkRowIndex(rowIndex)
        ) {
            switch (fieldName) {
                case 'status': {
                    await this.save((state) => {
                        const tmpList = state?.[propertyName];
                        const currentRow = tmpList?.[rowIndex];
                        const isDocumentStatusRejected = this._isDocumentStatusRejected(option?.value);
                        const isCustomOption = currentRow['comment_id']?.data?.value === 'customComment';

                        // reset to the initial state
                        currentRow['description'].isActive = isDocumentStatusRejected && isCustomOption;
                        currentRow['description'].isRequired = isDocumentStatusRejected && isCustomOption;
                        currentRow['description'].isValid = true;

                        currentRow['comment_id'].isActive = isDocumentStatusRejected;
                        currentRow['comment_id'].isRequired = isDocumentStatusRejected;
                        currentRow['comment_id'].isValid = true;

                        const newState = { [propertyName]: tmpList };

                        return newState;
                    });

                    break;
                }
                case 'comment_id': {
                    await this.save((state) => {
                        const tmpList = state?.[propertyName];
                        const currentRow = tmpList?.[rowIndex];
                        const isDocumentStatusRejected = this._isDocumentStatusRejected(currentRow['status']?.data?.value);
                        const isCustomOption = option?.value === 'customComment';

                        // reset to the initial state
                        currentRow['description'].isActive = isCustomOption;
                        currentRow['description'].isRequired = isDocumentStatusRejected && isCustomOption;
                        currentRow['description'].isValid = true;

                        const newState = { [propertyName]: tmpList };

                        return newState;
                    });

                    break;
                }
                case 'type': {
                    await this.save((state) => {
                        const tmpList = state?.[propertyName];
                        const currentRow = tmpList?.[rowIndex];
                        const typeVal = option?.value;
                        // update related field state
                        currentRow['sub_type'].data = null;
                        currentRow['sub_type'].options = this.getSubTypeOptionsBySelectedType(typeVal);
                        const newState = { [propertyName]: tmpList };

                        return newState;
                    });

                    break;
                }
                default: {
                    break;
                }
            }
        } else if (
            propertyName === 'taxResidenciesChangesData' &&
            this._checkRowIndex(rowIndex) && fieldName === 'tin_absence_reason_id'
        ) {
            await this.save((state) => {
                const tmpList = state?.[propertyName];
                const currentRow = tmpList?.[rowIndex];
                const reasonIdValue = option?.value;
                const isTinAbsenceReasonText = this._isTinAbsenceReasonText(option?.is_text);

                // update related field state
                currentRow['tin'].data = '';

                if (isNull(reasonIdValue)) {
                    currentRow['tin'].isRequired = true;
                    currentRow['tin'].isDisabled = false;

                    currentRow['tin_absence_reason_text'].isRequired = false;
                    currentRow['tin_absence_reason_text'].isActive = false;

                    currentRow['tin_absence_reason_id'].isRequired = false;
                } else {
                    currentRow['tin'].isRequired = false;
                    currentRow['tin'].isDisabled = true;

                    currentRow['tin_absence_reason_id'].isRequired = true;

                    if (isTinAbsenceReasonText) {
                        // option - 'B' - required description
                        currentRow['tin_absence_reason_text'].isRequired = true;
                        currentRow['tin_absence_reason_text'].isActive = true;
                    } else {
                        currentRow['tin_absence_reason_text'].isRequired = false;
                        currentRow['tin_absence_reason_text'].isActive = false;
                    }
                }

                const newState = { [propertyName]: tmpList };

                return newState;
            });
        } else if (propertyName === 'addressesData' &&
            this._checkRowIndex(rowIndex) && fieldName === 'country_id'
        ) {
            await this.save((state) => {
                const tmpList = state?.[propertyName];
                const currentRow = tmpList?.[rowIndex];
                const isSelectedCountryNeedsAgree = this._isSelectedCountryNeedsAgree(option?.is_work);

                // update related field state
                currentRow['lawyer_agreed'].data = null;
                currentRow['lawyer_agreed'].isActive = isSelectedCountryNeedsAgree;

                const newState = { [propertyName]: tmpList };

                return newState;
            });
        }
    };

    onSwitchChange = (fieldName, propertyName, rowIndex, isDisabledSwitch) => async ({ value, scrollPosY }) => {
        if (isDisabledSwitch) {
            return;
        }

        await this.save((state) => {
            let newState = {};

            if (this._checkRowIndex(rowIndex)) {
                const tmpList = state?.[propertyName];
                const currentRow = tmpList?.[rowIndex];

                currentRow[fieldName].data = value;
                currentRow[fieldName].isValid = true; // reset error onInput
                newState = { [propertyName]: tmpList };
            }

            return newState;
        });
    };

    onCopyAddressSelectChange = (propertyName, rowIndexDist) => async (option) => {
        const tmpList = this.state?.[propertyName];
        const rowIndexSrc = option?.rowIndex;

        for (let key in tmpList?.[rowIndexDist]) {
            tmpList[rowIndexDist][key]['data'] = tmpList?.[rowIndexSrc]?.[key]?.['data'];
            tmpList[rowIndexDist][key]['isValid'] = true;
        }

        await this.save({ [propertyName]: tmpList });
    };

    onResidencyActionSelectChange = (rowIndex) => async (option) => {
        await this.save({ loadedInnerRequest: false, });

        const action = option?.value;

        switch (action) {
            case 'delete': {
                try {
                    const propertyName = 'taxResidenciesData';
                    const formDataToSend = this._getFormDataToSend(propertyName, rowIndex);
                    const id = this.state?.[propertyName]?.[rowIndex]?.id;

                    const response = await postAccountTaxResidencyDataChange({
                        ...formDataToSend,
                        account_tax_residency_data_id: id,
                        action:                        '2', // delete
                        status:                        '1', // new
                    });

                    response && await this.reLoadAccountTaxResidencies();
                } catch (error) {
                    error?.showErrorNotification?.();
                }
                break;
            }
            case 'edit': {
                try {
                    const propertyName = 'taxResidenciesData';
                    const formDataToSend = this._getFormDataToSend(propertyName, rowIndex);
                    const id = this.state?.[propertyName]?.[rowIndex]?.id;

                    const response = await postAccountTaxResidencyDataChange({
                        ...formDataToSend,
                        account_tax_residency_data_id: id,
                        action:                        '1', // edit
                        status:                        '1', // new
                    });

                    response && await this.reLoadAccountTaxResidencies();
                } catch (error) {
                    error?.showErrorNotification?.();
                }
                break;
            }
            case 'restore': {
                try {
                    const propertyName = 'taxResidenciesChangesData';
                    const id = this.state?.[propertyName]?.[rowIndex]?.id;

                    const response = await deleteAccountTaxResidencyDataChange(id);

                    response && await this.reLoadAccountTaxResidencies();
                } catch (error) {
                    error?.showErrorNotification?.();
                }
                break;
            }
            case 'undo': {
                try {
                    const propertyName = 'taxResidenciesChangesData';
                    const id = this.state?.[propertyName]?.[rowIndex]?.id;

                    const response = await deleteAccountTaxResidencyDataChange(id);

                    response && await this.reLoadAccountTaxResidencies();
                } catch (error) {
                    error?.showErrorNotification?.();
                }
                break;
            }
            case 'update': {
                const propertyName = 'taxResidenciesChangesData';
                const { isValidStatus, isAllFieldsEmpty } = await this.validateFormDataFields(propertyName, rowIndex);
                if (isValidStatus || !isAllFieldsEmpty) {
                    try {
                        const formDataToSend = this._getFormDataToSend(propertyName, rowIndex);
                        const id = this.state?.[propertyName]?.[rowIndex]?.id;
                        const response = await putAccountTaxResidencyDataChange(id, formDataToSend);

                        response && await this.save({ refreshClientDataFlag: true });
                    } catch (error) {
                        const errors = error?.response?.data?.errors ?? {};
                        const newState = this.state;

                        for (let fieldName in errors) {
                            if (fieldName in newState?.[propertyName]?.[rowIndex]) {
                                newState[propertyName][rowIndex][fieldName]['isValid'] = false;
                            }
                        }

                        await this.save(newState);

                        error?.showErrorNotification?.();
                    }
                }

                break;
            }
            default: {
                break;
            }
        }

        await this.save({ loadedInnerRequest: true, });
    };

    onDateChange = (fieldName, propertyName, rowIndex) => async (date) => {
        let newState = {};

        if (this._checkRowIndex(rowIndex)) {
            const tmpList = this.state?.[propertyName];
            const currentRow = tmpList?.[rowIndex];

            currentRow[fieldName].data = date;
            currentRow[fieldName].isValid = true; // reset error onInput
            newState = { [propertyName]: tmpList };
        } else {
            newState = {
                [propertyName]: {
                    ...this.state?.[propertyName],
                    [fieldName]: {
                        ...this.state?.[propertyName]?.[fieldName],
                        data:    date,
                        isValid: true, // reset error onInput
                    },
                },
            };
        }

        await this.save(newState);
    };

    renderInputBlock = (fieldName, propertyName, rowIndex) => {
        const _getIconClassName = () => {
            let iconClassName = '';

            switch (fieldName) {
                case 'surname':
                case 'surname_latin':
                case 'name':
                case 'name_latin':
                case 'patronymic':
                case 'gender':
                case 'person_identity_number':
                case 'issue_date': {
                    iconClassName = 'person';
                    break;
                }
                case 'birth_date': {
                    iconClassName = 'cake';
                    break;
                }
                case 'nationality_id':
                case 'country_id':
                case 'issue_country_id': {
                    iconClassName = 'earth';
                    break;
                }
                case 'state':
                case 'state_latin':
                case 'zip_code': {
                    iconClassName = 'map';
                    break;
                }
                case 'location':
                case 'location_latin':
                case 'address':
                case 'address_latin': {
                    iconClassName = 'home';
                    break;
                }
                case 'type': {
                    iconClassName = 'figures';
                    break;
                }
                case 'sub_type':
                case 'status': {
                    iconClassName = 'search';
                    break;
                }
                case 'comment_id': {
                    iconClassName = 'check';
                    break;
                }
                default: {
                    break;
                }
            }

            return iconClassName;
        };

        const _getLabelPrefix = () => {
            const propertyNameToLabelPrefixMap = {
                'formData':                  'client_verification_label_data',
                'addressesData':             'client_verification_label_address',
                'taxResidenciesData':        'client_verification_label_residency',
                'taxResidenciesChangesData': 'client_verification_label_residency',
                'documentsData':             'client_verification_label_document',
            };

            return propertyNameToLabelPrefixMap?.[propertyName] ?? '';
        };

        const iconClassName = _getIconClassName();
        const labelPrefix = _getLabelPrefix();

        const { translate } = this.props;
        const { activeDocumentIndex } = this.state;

        let pathObj;

        switch (propertyName) {
            case 'documentsData': {
                rowIndex = activeDocumentIndex;
                pathObj = this.state?.[propertyName]?.[rowIndex]?.[fieldName] || {};

                break;
            }
            case 'addressesData':
            case 'taxResidenciesData':
            case 'taxResidenciesChangesData': {
                pathObj = this.state?.[propertyName]?.[rowIndex]?.[fieldName] || {};

                break;
            }
            case 'formData':
            default: {
                pathObj = this.state?.[propertyName]?.[fieldName] || {};

                break;
            }
        }
        const currentlyDocType = this.state?.['taxResidenciesChangesData']?.[activeDocumentIndex]?.['tin'];
        const currentlyDocTypeTin = this.state?.['taxResidenciesChangesData']?.[activeDocumentIndex]?.['tin_absence_reason_id'];
        const currentlyDocTypeCountryId = this.state?.['taxResidenciesChangesData']?.[activeDocumentIndex]?.['country_id'];
        const currentlyDocTypeTinText = this.state?.['taxResidenciesChangesData']?.[activeDocumentIndex]?.['tin_absence_reason_text'];


        const data = pathObj?.data;
        const options = pathObj?.options;
        const fieldType = pathObj?.fieldType;
        const errorClass = pathObj?.isValid ? '' : 'error';
        const isActive = pathObj?.isActive ?? true;
        const isDisabled = pathObj?.isDisabled ?? false;
        const isRequired = pathObj?.isRequired ?? false;
        const labelText = translate(`${labelPrefix}_${fieldName}`);
        const labelTextFormatted = isRequired ? `${labelText}*` : labelText;
        const { departmentsListNames } = this.props;
        const calcData = this.calcData;
        const pm = ['show', 'store', 'update'];
        const verificationDepartment = 'verification'.includes(departmentsListNames);
        let disableInput = false

        if (calcData?.status?.access(...pm)) {
            if (!verificationDepartment) {
                if (!currentlyDocType) {
                    disableInput = true
                } else {

                    disableInput = true
                    currentlyDocType.isDisabled = true
                    currentlyDocTypeTin.isDisabled =true
                    currentlyDocTypeCountryId.isDisabled =true
                    currentlyDocTypeTinText.isDisabled =true
                }



            }

        }
        const isCountryTooltip = [
            'formData',
            'addressesData',
            'taxResidenciesData',
            'taxResidenciesChangesData'
        ].includes(propertyName);
        const isCountrySwitch = propertyName === 'addressesData' && rowIndex === 0;

        const _renderCountryTooltip = () => {
            const is_work = data?.is_work ?? null;
            const isWorkToTextMap = {
                2: 'clients_verification_country_info_prohibited',
                3: 'clients_verification_country_info_allowed_verification',
                4: 'clients_verification_country_info_prohibited_verification',
            };
            // CountryIsWorkEnum: 1 - 'ALLOWED', 2 - 'PROHIBETED', 3 - 'ALLOWED_VERIFICATION', 4 - 'PROHIBETED_VERIFICATION'
            return ['2', '3', '4'].includes(is_work?.toString()) && (
                <div className={`tooltip-wrapper country-wrapper icon icon--info-red`}>
                    <div className={`tooltip tooltip--large-width`}>
                        {translate(isWorkToTextMap?.[is_work])}
                    </div>
                </div>
            );
        };

        const _renderCountrySwitch = () => {
            const fieldNameSwitch = 'lawyer_agreed';
            const pathObjSwitch = this.state?.[propertyName]?.[rowIndex]?.[fieldNameSwitch] || {};
            const dataSwitch = pathObjSwitch?.data ? 2 - pathObjSwitch?.data : 0;
            const errorClassSwitch = pathObjSwitch?.isValid ? '' : 'error';
            const isActiveSwitch = pathObjSwitch?.isActive ?? true;
            const isDisabledSwitch = pathObjSwitch?.isDisabled ?? false;
            const disabledClass = isDisabledSwitch ? 'disabled-field' : '';


            return isActiveSwitch ? (
                <div className={'tooltip-wrapper tooltip-wrapper--country-agree'}>
                    <MagicSwitch
                        className={`magic-switch magic-switch--country-agree`}
                        classNameText={`magic-switch__text block__info`}
                        classNameIcon={`magic-switch__icon`}
                        index={dataSwitch}
                        isDisabled={isDisabledSwitch }
                        reverse={true}
                        values={this.values(fieldNameSwitch)}
                        text={''}
                        onChange={this.onSwitchChange(fieldNameSwitch, propertyName, rowIndex, isDisabledSwitch)}
                        updateStateFromProps={true}
                    />

                    <div className={'tooltip tooltip--large-width'}>
                        {translate('client_verification_tooltip_lawyer_agreed')}
                    </div>
                </div>
            ) : null;
        };

        const key = `key-${fieldName}-${rowIndex ? rowIndex : 0}`;

        switch (fieldType) {
            case FIELD_TYPE.select: {
                const isSearchable = pathObj?.isSearchable ?? false;
                return isActive ? (
                    <div className='input' key={key}>
                        <span className='input__text'>
                            {labelTextFormatted}
                        </span>

                        <div className={`input-wrapper`}>
                            {isCountryTooltip && _renderCountryTooltip()}

                            <Select
                                className={`input__field input-select ${errorClass} icon icon--${iconClassName}`}
                                classNamePrefix='select'
                                isDisabled={isDisabled }
                                isSearchable={isSearchable }
                                noOptionsMessage={({ inputValue }) => translate('clients_verification_select_no_options_text')}
                                onChange={this.onSelectChange(fieldName, propertyName, rowIndex)}
                                options={options}
                                placeholder={translate('clients_verification_select_option_placeholder')}
                                //styles = { customStyles }
                                value={data}
                            />

                            {isCountrySwitch && _renderCountrySwitch()}
                        </div>
                    </div>
                ) : null;
            }
            case FIELD_TYPE.datepicker: {
                return isActive ? (
                    <div className='input' key={key}>
                        <span className='input__text'>
                            {labelTextFormatted}
                        </span>

                        <div className={`input-wrapper dataPickerStyleCustom icon icon--${iconClassName}`}>
                            <DatePicker
                                value={data}
                                onChange={this.onDateChange(fieldName, propertyName, rowIndex)}
                                format='yyyy.MM.dd'
                                locale={this.props.activeLanguage.code}
                                calendarClassName={'react-datepicker__month-container'}
                                className={`react-datepicker input__field ${errorClass}`}
                                clearIcon={null}
                                calendarIcon={null}
                                disabled={isDisabled}
                            />
                        </div>
                    </div>
                ) : null;
            }
            case FIELD_TYPE.textarea: {
                const disabledClass = isDisabled ? 'disabled-field' : '';
                const placeholder = fieldName === 'tin_absence_reason_text' ?
                    translate('client_verification_label_residency_tin_absence_reason_text_placeholder') : '';

                return isActive ? (
                    <div className='input' key={key}>
                        <span className='input__text'>
                            {fieldName !== 'description' ? labelTextFormatted : ''}
                        </span>

                        <div
                            className={`input__field input-wrapper icon icon--${iconClassName} ${errorClass} ${disabledClass}`}>
                            <textarea className={`magic-textarea scroll`}
                                      autoComplete='off'
                                      type='text'
                                      value={data}
                                      onChange={this.onInputChange(fieldName, propertyName, rowIndex)}
                                      disabled={isDisabled }
                                      placeholder={placeholder}
                            />
                        </div>
                    </div>
                ) : null;
            }
            case FIELD_TYPE.input:
            default: {

                return isActive ? (
                    <div className='input' key={key}>
                        <span className='input__text'>
                            {labelTextFormatted}
                        </span>

                        <div className={`input-wrapper icon icon--${iconClassName}`}>
                            <input className={`input__field ${errorClass}`}
                                   autoComplete='off'
                                   type='text'
                                   value={data}
                                   onChange={this.onInputChange(fieldName, propertyName, rowIndex)}
                                   disabled={isDisabled}
                            />
                        </div>
                    </div>
                ) : null;
            }
        }
    };

    renderChangeEmailPhoneBtns = () => {
        const { translate, } = this.props;
        const { activeDocumentIndex = 0, accountDocuments = [], documentsData = [] } = this.state;
        const activeDocument = accountDocuments?.[activeDocumentIndex] ?? {};
        const account_change_email = activeDocument?.core?.account_change_email ?? {};
        const account_change_phone = activeDocument?.core?.account_change_phone ?? {};
        const { emailChangeStatus = null, phoneChangeStatus = null } = documentsData?.[activeDocumentIndex] ?? {};

        const btnEmailChangeClassName = this.calcData?.coreAccountChangeEmail?.access('change') ? '' : 'gl-btn--disabled';
        const btnEmailCancelClassName = this.calcData?.coreAccountChangeEmail?.access('cancel') ? '' : 'gl-btn--disabled';
        const btnPhoneChangeClassName = this.calcData?.coreAccountChangePhone?.access('change') ? '' : 'gl-btn--disabled';
        const btnPhoneCancelClassName = this.calcData?.coreAccountChangePhone?.access('cancel') ? '' : 'gl-btn--disabled';

        const emailChangeStatusMap = ['clients_cancel_email_success_text', 'clients_update_email_success_text'];
        const phoneChangeStatusMap = ['clients_cancel_phone_success_text', 'clients_update_phone_success_text'];

        const _renderChangeEmailBtns = () => {
            const { id } = account_change_email;
            const isStatusWaitConfirmation = this._isAccountChangeHistoryStatusWaitConfirmation('change_email');

            return !isNull(id) && isStatusWaitConfirmation ? (
                <div className='input request-action-btns'>
                    <span className='input__text icon icon--info-red'>
                        {translate('client_verification_label_update_actions')}
                    </span>

                    {isNull(emailChangeStatus) ?
                        (
                            <div>
                                <button className={`gl-btn gl-btn--blue-dark ${btnEmailChangeClassName}`}
                                        onClick={this.onUpdateEmailPhoneBtnClick('email', id, activeDocumentIndex)}
                                >
                                    {translate('clients_update_email_btn')}
                                </button>
                                <button className={`gl-btn gl-btn--red ${btnEmailCancelClassName}`}
                                        onClick={this.onCancelRequestBtnClick('email', id, activeDocumentIndex)}
                                >
                                    {translate('clients_cancel_request_btn')}
                                </button>
                            </div>
                        ) : (
                            <span className='input__text'>
                                {translate(emailChangeStatusMap[emailChangeStatus])}
                            </span>
                        )
                    }
                </div>
            ) : null;
        };

        const _renderChangePhoneBtns = () => {
            const { id } = account_change_phone;
            const isStatusWaitConfirmation = this._isAccountChangeHistoryStatusWaitConfirmation('change_phone');

            return !isNull(id) && isStatusWaitConfirmation ? (
                <div className='input request-action-btns'>
                    <span className='input__text icon icon--info-red'>
                        {translate('client_verification_label_update_actions')}
                    </span>

                    {isNull(phoneChangeStatus) ?
                        (
                            <div>
                                <button className={`gl-btn gl-btn--blue-dark ${btnPhoneChangeClassName}`}
                                        onClick={this.onUpdateEmailPhoneBtnClick('phone', id, activeDocumentIndex)}
                                >
                                    {translate('clients_update_phone_btn')}
                                </button>
                                <button className={`gl-btn gl-btn--red ${btnPhoneCancelClassName}`}
                                        onClick={this.onCancelRequestBtnClick('phone', id, activeDocumentIndex)}
                                >
                                    {translate('clients_cancel_request_btn')}
                                </button>
                            </div>
                        ) : (
                            <span className='input__text'>
                                {translate(phoneChangeStatusMap[phoneChangeStatus])}
                            </span>
                        )
                    }
                </div>
            ) : null;
        };

        return (
            <React.Fragment>
                {_renderChangeEmailBtns()}
                {_renderChangePhoneBtns()}
            </React.Fragment>
        );
    };

    renderDocumentParsedDataBlock = () => {
        const { translate, } = this.props;
        const { activeDocumentIndex = 0, accountDocuments = [], documentsData = [] } = this.state;
        const { isDocumentParsedDataBtnActive = false, documentParsedData = {} } = documentsData?.[activeDocumentIndex] ?? {};
        const activeDocument = accountDocuments?.[activeDocumentIndex] ?? {};
        const tooltipClassName = isDocumentParsedDataBtnActive ? 'active' : '';
        const accountDocumentSystem = activeDocument?.core?.account_document?.system;
        const isSumSub = this._isDocumentSystemSumSub(accountDocumentSystem);
        const btnClassName = this.calcData?.coreAccountDocument?.access('getParsedDataByDocumentId') ? '' : 'gl-btn--disabled';

        const _renderTooltipRows = (objData) => {
            return Object.entries(objData)?.map(([key, value]) => {
                return value instanceof Object ? _renderTooltipRows(value) : _renderTooltipRow([key, value]);
            });
        };

        const _renderTooltipRow = ([key, value]) => {
            return (
                <div className='tooltip-cont' key={`key-${key}-${value}`}>
                    <span className='tooltip__title'>{`${key}: `}</span>
                    <span className='tooltip__text'>{value}</span>
                </div>
            );
        };

        return isSumSub ? (
            <div className={`document-parse-data-block`}>
                <div className={`tooltip-wrapper tooltip-wrapper--to-bottom  ${tooltipClassName}`}>
                    <button className={`gl-btn gl-btn--blue-dark ${btnClassName}`}
                            onClick={this.onDocumentParsedDataBtnClick()}
                    >
                        {translate(isDocumentParsedDataBtnActive ? 'clients_document_parsed_data_hide_btn' : 'clients_document_parsed_data_btn')}
                    </button>

                    {isDocumentParsedDataBtnActive ? (
                        <div className={`tooltip`}>
                            {_renderTooltipRows(documentParsedData)}
                        </div>
                    ) : null}
                </div>
            </div>
        ) : (<div className={`document-parse-data-block`}></div>); // to avoid mark-up jumpings
    };

    renderDocumentStatusDetailsBlock = () => {
        const { translate, } = this.props;
        const { isStatusDetailsBtnActive = false, activeDocumentIndex = 0, accountDocuments = [], } = this.state;
        const activeDocument = accountDocuments?.[activeDocumentIndex] ?? {};
        const tooltipClassName = isStatusDetailsBtnActive ? 'active' : '';
        const accountDocumentSystem = activeDocument?.core?.account_document?.system;
        const isSumSub = this._isDocumentSystemSumSub(accountDocumentSystem);

        let system_answer = {};

        try {
            system_answer = JSON.parse(activeDocument?.core?.account_document?.system_answer ?? {});
        } catch (error) {

        }

        const { moderationComment = '', clientComment = '', rejectLabels = '' } = system_answer;

        return isSumSub ? (
            <div className={`input document-status-details-block`}>
                <div className={`tooltip-wrapper tooltip-wrapper--to-top ${tooltipClassName}`}>
                    <button className='gl-btn gl-btn--blue-dark'
                            onClick={this.onDocumentStatusDetailsBtnClick}
                    >
                        {translate(isStatusDetailsBtnActive ? 'clients_status_details_hide_btn' : 'clients_status_details_btn')}
                    </button>

                    {isStatusDetailsBtnActive ? (
                        <div className={`tooltip`}>
                            <p className='tooltip__header'>Sum&Substance</p>

                            {moderationComment ? (
                                <React.Fragment>
                                    <p className='tooltip__title'>moderationComment</p>
                                    <p className='tooltip__text'>{moderationComment}</p>
                                </React.Fragment>
                            ) : null}

                            {clientComment ? (
                                <React.Fragment>
                                    <p className='tooltip__title'>clientComment</p>
                                    <p className='tooltip__text'>{clientComment}</p>
                                </React.Fragment>
                            ) : null}

                            {rejectLabels ? (
                                <React.Fragment>
                                    <p className='tooltip__title'>rejectLabels</p>
                                    <p className='tooltip__text'>{rejectLabels}</p>
                                </React.Fragment>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            </div>
        ) : (<div className={`input document-status-details-block`}></div>); // to avoid mark-up jumpings
    };

    transformImageHandler = async (mode) => {
        const { activeDocumentIndex = 0, documentsData = [] } = this.state;
        const transformObj = documentsData?.[activeDocumentIndex]?.transformObj ?? this._getInitialTransformObj();
        const rotateDelta = 90;
        const scaleDelta = 0.1;

        let { rotate = 0, scale = 1 } = transformObj;

        const _checkScale = (scale) => {
            const defaultScale = 1;
            const scaleMin = 0.1;
            const scaleMax = 4;

            if (scale <= scaleMin) {
                return scaleMin;
            } else if (scale >= scaleMax) {
                return scaleMax;
            } else {
                return !isNaN(scale) ? scale : defaultScale;
            }
        };

        const _checkAngle = (angle) => {
            const defaultAngle = 0;

            return !isNaN(angle) ? angle : defaultAngle;
        };

        switch (mode) {
            case 'rotateLeft': {
                rotate = _checkAngle(rotate - rotateDelta);
                break;
            }
            case 'rotateRight': {
                rotate = _checkAngle(rotate + rotateDelta);
                break;
            }
            case 'zoomIn': {
                scale = _checkScale(scale + scaleDelta);
                break;
            }
            case 'zoomOut': {
                scale = _checkScale(scale - scaleDelta);
                break;
            }
            default: {
                break;
            }
        }

        await this.save((state) => {
            const propertyName = 'documentsData';
            const tmpList = state?.[propertyName];
            const currentRow = tmpList?.[activeDocumentIndex];

            currentRow.transformObj = { rotate, scale };

            const newState = { [propertyName]: tmpList };

            return newState;
        });
    };

    onRotateLeftBtnClick = async () => {
        await this.transformImageHandler('rotateLeft');
    };

    onRotateRightBtnClick = async () => {
        await this.transformImageHandler('rotateRight');
    };

    onZoomInBtnClick = async () => {
        await this.transformImageHandler('zoomIn');
    };

    onZoomOutBtnClick = async () => {
        await this.transformImageHandler('zoomOut');
    };

    onActiveDocumentIndexChange = async (mode, index) => {
        const { activeDocumentIndex = 0, documentsData = [], accountDocuments = [] } = this.state;
        const documentsLength = accountDocuments?.length ?? 0;

        let activeDocumentIndexNew;

        const _checkDocumentIndex = (checkIndex) => {
            if (checkIndex < 0) {
                return 0;
            } else if (checkIndex >= documentsLength) {
                return documentsLength - 1 > 0 ? (documentsLength - 1) : 0;
            } else {
                return checkIndex;
            }
        };

        switch (mode) {
            case 'prev': {
                activeDocumentIndexNew = _checkDocumentIndex(activeDocumentIndex - 1);
                break;
            }
            case 'next': {
                activeDocumentIndexNew = _checkDocumentIndex(activeDocumentIndex + 1);
                break;
            }
            case 'target': {
                activeDocumentIndexNew = _checkDocumentIndex(+index);
                break;
            }
            default: {
                activeDocumentIndexNew = activeDocumentIndex;
                break;
            }
        }

        await this.save({ activeDocumentIndex: activeDocumentIndexNew });
    };

    onDocumentPrevBtnClick = async () => {
        await this.onActiveDocumentIndexChange('prev');
        await this.currentlyDisabledStatusOfSelect();
    };

    onDocumentNextBtnClick = async () => {
        await this.onActiveDocumentIndexChange('next');
        await this.currentlyDisabledStatusOfSelect();
    };

    onDocumentsPreviewSmallClick = (item, index) => async () => {
        await this.onActiveDocumentIndexChange('target', index);
        await this.currentlyDisabledStatusOfSelect();
    };

    renderDocumentsPreviewBlock = () => {
        const { translate, } = this.props;
        const { activeDocumentIndex = 0, accountDocuments = [], documentsData = [] } = this.state;
        const activeDocument = accountDocuments?.[activeDocumentIndex] ?? {};
        const transformObj = documentsData?.[activeDocumentIndex]?.transformObj ?? {};
        const { rotate = 0, scale = 1 } = transformObj;
        const { file_name: fileNameActive = '', extension: extensionActive = '', mime_type: mimeTypeActive = '' } = activeDocument?.core?.account_document ?? {};
        const isPdfFile = mimeTypeActive?.toLowerCase() === 'application/pdf';
        const alignPreviewBigClass = activeDocument?.src === 'error' ? 'align-center' : '';
        const classNameScroll = !isPdfFile ? 'scroll-x-y' : ''; // TODO: check the styles
        const prevBtnClassName = activeDocumentIndex <= 0 ? 'gl-btn--disabled' : '';
        const nextBtnClassName = activeDocumentIndex >= accountDocuments.length - 1 ? 'gl-btn--disabled' : '';

        const transformStyles = {
            'transform': `scale(${scale}) rotate(${rotate}deg)`,
        };
        const _renderDocument = (item) => {
            const base64 = item.src.split('base64,')[1];
            const blobUrl = getBlobUrlFromBase64(base64, 'application/pdf');

            return item.src === 'error' ?
                <img src={errorImg} alt='error image' style={transformStyles}/>
                :
                item?.core?.account_document?.mime_type === 'application/pdf' ?
                    <iframe src={blobUrl} style={{ 'position': 'relative' }}
                            frameBorder='0'
                            width='100%'
                            height='100%'
                    />
                    :
                    <img src={item.src} alt='image' style={transformStyles}/>;
        };

        const _renderDocumentPreview = (item) => {
            return item?.core?.account_document?.mime_type === 'application/pdf' ?
                <i className='attachment_icon_task fi flaticon-pdf-file-format-symbol'/>
                :
                <img src={item.src !== 'error' ? item.src : errorImg} alt='image'/>;
        };

        const _renderDocumentsPreviewSmall = (item, index) => {
            const { alias_name = '', id = '', mime_type = '', status } = item?.core?.account_document ?? {};
            const isActiveClass = index === activeDocumentIndex ? 'active' : '';
            const alignPreviewSmallClass = mime_type?.toLowerCase() === 'application/pdf' || item?.src === 'error' ? 'align-center' : '';
            const statusToClassNameMap = {
                1: 'yellow',
                2: 'yellow',
                3: 'green',
                4: 'red',
                5: 'grey',
            };
            const statusClassName = statusToClassNameMap?.[status] ?? ''; // AccountDocumentStatusEnum

            return (
                <div
                    className={`documents-preview__small status-border status-border--${statusClassName} ${isActiveClass} ${alignPreviewSmallClass}`}
                    key={`key-${id}`}
                    onClick={this.onDocumentsPreviewSmallClick(item, index)}>

                    {_renderDocumentPreview(item)}
                </div>
            );
        };

        return (
            <div className='documents'>
                <div className='documents-title-cont'>
                    {this.renderDocumentParsedDataBlock()}

                    <p className='documents__title'>{`${fileNameActive}.${extensionActive}`}</p>
                </div>

                <div className='documents-preview'>
                    <div className='documents-preview__left'>
                        <div className={`documents-preview__big ${classNameScroll} ${alignPreviewBigClass}`}>
                            {_renderDocument(activeDocument)}
                        </div>

                        <div className='documents-preview__controls'>
                            <div className='controls-left'>
                                {!isPdfFile ? (
                                    <React.Fragment>
                                        <i className='icon icon--rotate-left' onClick={this.onRotateLeftBtnClick}></i>

                                        <i className='icon icon--rotate-right' onClick={this.onRotateRightBtnClick}></i>

                                        <i className='icon icon--zoom-in' onClick={this.onZoomInBtnClick}></i>

                                        <i className='icon icon--zoom-out' onClick={this.onZoomOutBtnClick}></i>
                                    </React.Fragment>
                                ) : null}

                            </div>
                            <div className='controls-right'>
                                <i className={`icon icon--arrow-left ${prevBtnClassName}`}
                                   onClick={this.onDocumentPrevBtnClick}
                                ></i>

                                <i className={`icon icon--arrow-right ${nextBtnClassName}`}
                                   onClick={this.onDocumentNextBtnClick}
                                ></i>
                            </div>
                        </div>
                    </div>

                    <div className='documents-preview__right scroll'>
                        {accountDocuments.map((item, index) => _renderDocumentsPreviewSmall(item, index))}
                    </div>
                </div>
            </div>
        );
    };

    renderAccountDataBtns = () => {
        const { translate, departmentsListNames = [] } = this.props;
        const condition = departmentsListNames?.includes('technical_support') || departmentsListNames?.includes('verification');
        const btnClassName = condition ? '' : 'gl-btn--disabled';

        return (
            <div className='btns-cont'>
                <button className={`gl-btn gl-btn--blue ${btnClassName}`}
                        onClick={this.onUpdateAccountDataClick}
                >
                    {translate('clients_update_account_data_btn')}
                </button>
                <button className='gl-btn gl-btn--blue-border'
                        onClick={this.popUpCloseFunc}
                >
                    {translate('partners_cancel_btn')}
                </button>
            </div>
        );
    };

    renderAccountDocumentsBlock = () => {
        const { translate } = this.props;
        const { accountDocuments = [] } = this.state;
        const propertyName = 'documentsData';

        return accountDocuments?.length ?
            (
                <div id={`section-${this.menuItems?.clientDocuments?.name}`}>
                    <div className='cont cont--account-documents-block'>
                        <div className='cont-title'>
                            <h4 className='cont-title__name'>{translate('client_verification_title_account_documents')}</h4>
                        </div>

                        <div className='documents-wrapper'>
                            {this.renderDocumentsPreviewBlock()}

                            <div className="cont__left">
                                {
                                    [
                                        'type',
                                        'sub_type',
                                        'status',
                                    ].map((fieldName) => this.renderInputBlock(fieldName, propertyName))
                                }

                                {this.renderDocumentStatusDetailsBlock()}
                            </div>

                            <div className="cont__right">
                                {
                                    [
                                        'created_at',
                                        'updated_at',
                                        'comment_id',
                                        'description',
                                    ].map((fieldName) => this.renderInputBlock(fieldName, propertyName))
                                }
                            </div>

                            {this.renderChangeEmailPhoneBtns()}
                        </div>
                    </div>

                    {this.renderAccountDocumentDataBtns()}
                </div>
            ) : null;
    };

    renderAccountDocumentDataBtns = () => {
        const { activeDocumentIndex, } = this.state;
        const { translate, departmentsListNames } = this.props;
        const btnClassName = this.calcData?.coreAccountDocument?.access('store', 'update') ? '' : 'gl-btn--disabled';
        const currentlyDocType = this.state?.['documentsData']?.[activeDocumentIndex]?.['type'];
        const data = currentlyDocType?.data;
        const currentlyDocSide = this.state?.['documentsData']?.[activeDocumentIndex]?.['sub_type'];
        const financialDepartment = 'financial'.includes(departmentsListNames);
        let isDisabledBtn = false;


        if (financialDepartment ) {
            if (data?.value === 'BANK_ACCOUNT_STATEMENT' || data?.value === 'CONTRACT_OF_SALE' ) {
                isDisabledBtn = false;
            } else {
                isDisabledBtn = true;
            }
            if  (!currentlyDocSide?.data?.value)  isDisabledBtn = true;
        }

        if (!financialDepartment) {
            if (data?.value === 'BANK_ACCOUNT_STATEMENT' || data?.value === 'CONTRACT_OF_SALE' || !currentlyDocSide?.data?.value ) {
                isDisabledBtn = true;
            } else {
                isDisabledBtn = false;
            }
        }

        const btnDisabledClassName = isDisabledBtn ? 'gl-btn--disabled' : '';

        return (
            <div className='btns-cont'>
                <button className={`gl-btn gl-btn--blue ${btnClassName} ${btnDisabledClassName}`}
                        disabled={isDisabledBtn}
                        onClick={this.onUpdateAccountDocumentDataClick}
                >
                    {translate('clients_update_document_data_btn')}
                </button>
                <button className='gl-btn gl-btn--blue-border'
                        onClick={this.popUpCloseFunc}
                >
                    {translate('partners_cancel_btn')}
                </button>
            </div>
        );
    };

    renderAccountAddressBtns = (item, propertyName, rowIndex) => {
        const { translate } = this.props;
        const { accountAddressesOptions = [] } = this.state;
        const { type, isNewAddress = false } = item;
        const isAddressResidence = this._isAddressTypeResidence(type);
        const permission = this.calcData?.coreAccountAddresses?.access('store', 'update', 'destroy');
        const btnDeleteClassName = permission && !isNewAddress ? '' : 'gl-btn--disabled';
        const btnCreateUpdateText = isNewAddress ?
            translate('clients_create_address_data_btn') :
            translate('clients_update_address_data_btn');
        const createUpdateMode = isNewAddress ? 'create' : 'update';

        const isCopySelectDisabled = !permission;
        const filteredOptions = accountAddressesOptions?.filter((addressItem) => addressItem?.type !== type);
        const selectedOption = null;

        const { isValidStatus, isAllFieldsEmpty } = this._getValidatedFormDataFieldsState(propertyName, rowIndex, true); // not to change state
        const btnCreateUpdateClassName = permission && !isAllFieldsEmpty ? '' : 'gl-btn--disabled';

        return (
            <div className='btns-cont'>
                <button className={`gl-btn gl-btn--blue ${btnCreateUpdateClassName}`}
                        onClick={this.onAccountAddressActionBtnClick(createUpdateMode, item, rowIndex)}
                >
                    {btnCreateUpdateText}
                </button>

                {
                    !isAddressResidence ? (
                        <button className={`gl-btn gl-btn--blue ${btnDeleteClassName}`}
                                onClick={this.onAccountAddressActionBtnClick('delete', item, rowIndex)}
                        >
                            {translate('clients_clear_address_data_btn')}
                        </button>
                    ) : null
                }

                <div className='copy-address-select input'>
                    <Select
                        className={`input-wrapper input__field input-select`}
                        classNamePrefix='select'
                        isDisabled={isCopySelectDisabled}
                        isSearchable={false}
                        onChange={this.onCopyAddressSelectChange(propertyName, rowIndex)}
                        options={filteredOptions}
                        placeholder={translate('clients_verification_copy_address_select_option_placeholder')}
                        value={selectedOption}
                    />
                </div>
            </div>
        );
    };

    renderAccountAddressBlocks = () => {
        const { accountAddresses = [] } = this.state;

        return (
            <div id={`section-${this.menuItems?.clientAddress?.name}`}>
                {accountAddresses?.map((item, rowIndex) => this.renderAccountAddressBlock(item, rowIndex))}
            </div>
        );
    };

    renderAccountAddressBlock = (item, rowIndex) => {
        const propertyName = 'addressesData';
        const { translate, } = this.props;
        const { accountAddressTypesEnum = {} } = this.state;
        const { type } = item;

        return (
            <div className='cont cont--with-border-bottom' key={`address-block-${type}`}>
                <div className='cont-title'>
                    <h4 className='cont-title__name'>{accountAddressTypesEnum?.[type]}</h4>
                </div>

                <div className="cont__left">
                    {
                        [
                            'country_id',
                            'state',
                            'state_latin',
                            'zip_code',
                        ].map((fieldName) => this.renderInputBlock(fieldName, propertyName, rowIndex))
                    }
                </div>

                <div className="cont__right">
                    {
                        [
                            'location',
                            'location_latin',
                            'address',
                            'address_latin',
                        ].map((fieldName) => this.renderInputBlock(fieldName, propertyName, rowIndex))
                    }
                </div>

                {this.renderAccountAddressBtns(item, propertyName, rowIndex)}
            </div>
        );
    };

    onAccountStatusActionSelectChange = () => async (option) => {
        const { accountId, translate } = this.props;
        const actionName = option?.value;

        let postFunc = null;

        switch (actionName) {
            case 'cancel': {
                postFunc = postAccountIndividualVerificationCancelRequest;
                break;
            }
            case 'confirm': {
                postFunc = postAccountIndividualVerificationConfirmRequest;
                break;
            }
            default: {
                throw new Error(`onAccountStatusActionSelectChange: Can*t do the: '${actionName} in ', no necessary post request!`);
            }
        }

        await this.save({ loadedInnerRequest: false, });

        try {
            const response = await postFunc(accountId);
            const isCancel = actionName === 'cancel';

            if (response) {
                await this.save({ refreshClientDataFlag: actionName === 'confirm' });

                isCancel && NotificationService.success({
                    title:   'success',
                    message: translate('clients_verification_account_status_cancel_request_success_text'),
                    remove:  false,
                });

                this.popUpCloseFunc();
            }
        } catch (error) {
            error?.showErrorNotification?.();
        }

        await this.save({ loadedInnerRequest: true, });
    };

    onAccountCancelConfirmRequestBtnClick = (actionName) => async () => {
        const { translate, accountId } = this.props;
        let postFunc = null;

        switch (actionName) {
            case 'confirm': {
                postFunc = postAccountPersonalDataConfirmRequest;
                break;
            }
            case 'cancel': {
                postFunc = postAccountPersonalDataCancelRequest;
                break;
            }
            default: {
                throw new Error(`Can*t change the: '${actionName}', no necessary post request!`);
            }
        }

        await this.save({ loadedInnerRequest: false, });

        try {
            const response = await postFunc(accountId);
            const isCancel = actionName === 'cancel';

            if (response) {
                await this.save({ accountCancelConfirmRequestStatus: actionName });
                await this.save({ refreshClientDataFlag: isCancel });

                isCancel && NotificationService.success({
                    title:   'success',
                    message: translate('client_verification_account_cancel_request_success_text'),
                    remove:  false,
                });

                isCancel && this.popUpCloseFunc();
            }
        } catch (error) {
            error?.showErrorNotification?.();
        }

        await this.save({ loadedInnerRequest: true, });
    };

    renderAccountStatusUpdateBtns = () => {
        const { translate, accountIndividualVerifierStatus } = this.props;
        const accountStatusActionOptions = this.accountStatusActionOptions;
        const { status, check_task, register_type } = accountIndividualVerifierStatus ?? {};

        const pmConfirm = this.calcData?.coreAccount?.access('individualCancellationVerificationConfirmation');
        const pmCancel = this.calcData?.coreAccount?.access('individualCancellationVerificationCancel');
        const selectedOption = null;
        const isStatusActionSelectDisabled = !(pmConfirm && pmCancel);
        const renderFlag = this._isAccountIndividualOrTest(register_type) && status?.toString() === '1' && check_task;

        return renderFlag ? (
            <div className='status-block request-action-btns request-action-btns--account-status'>
                <span className='input__text icon icon--info-red'>
                    {translate('client_verification_label_status_update_actions')}
                </span>

                <div className='account-status-action-select input'>
                    <Select
                        className={`input-wrapper input__field input-select`}
                        classNamePrefix='select'
                        isDisabled={isStatusActionSelectDisabled}
                        isSearchable={false}
                        onChange={this.onAccountStatusActionSelectChange()}
                        options={accountStatusActionOptions}
                        placeholder={translate('clients_verification_account_status_action_select_option_placeholder')}
                        value={selectedOption}
                    />
                </div>
            </div>
        ) : null;
    };

    renderAccountCancelConfirmRequestBtns = () => {
        const { translate, accountIndividualVerifierStatus } = this.props;
        const { accountCancelConfirmRequestStatus = null } = this.state;
        const { status, check_change_request_exists, register_type } = accountIndividualVerifierStatus ?? {};

        const btnCancelRequestClassName = this.calcData?.coreAccount?.access('personalDataCancelRequest') ? '' : 'gl-btn--disabled';
        const btnConfirmRequestClassName = this.calcData?.coreAccount?.access('personalDataConfirmRequest') ? '' : 'gl-btn--disabled';

        const requestStatusNameToTextMap = {
            'confirm': 'client_verification_account_confirm_request_success_text',
            'cancel':  'client_verification_account_cancel_request_success_text',
        };
        const renderFlag = this._isAccountIndividualOrTest(register_type) &&
            status?.toString() === '3' &&
            check_change_request_exists;

        return renderFlag ? (
            <div className='status-block request-action-btns request-action-btns--account-update-request p15'>
                <span className='input__text icon icon--info-red'>
                    {translate('client_verification_label_account_update_request_actions')}
                </span>

                {
                    !accountCancelConfirmRequestStatus ? (
                        <div>
                            <button className={`gl-btn gl-btn--blue-dark ${btnConfirmRequestClassName}`}
                                    onClick={this.onAccountCancelConfirmRequestBtnClick('confirm')}
                            >
                                {translate('clients_verification_account_status_action_request_confirm')}
                            </button>

                            <button className={`gl-btn gl-btn--red ${btnCancelRequestClassName}`}
                                    onClick={this.onAccountCancelConfirmRequestBtnClick('cancel')}
                            >
                                {translate('clients_cancel_request_btn')}
                            </button>
                        </div>
                    ) : (
                        <span className='input__text'>
                            {translate(requestStatusNameToTextMap?.[accountCancelConfirmRequestStatus])}
                        </span>
                    )
                }
            </div>
        ) : null;
    };

    renderVerificationStatus = () => {
        const { is_verify_document, } = this?.state?.statusData ?? {};

        const statusToIconClassNameMap = {
            1: 'status-success',
            2: 'status-cancel',
            3: 'status-warning',
        };
        const iconClassName = statusToIconClassNameMap?.[is_verify_document] ?? '';

        return (
            <span className={`icon icon--${iconClassName}`} />
        );
    };

    onArchiveBtnClick = () => async () => {
        const { translate, accountId } = this.props;
        const isAccountStatusActive = this._isAccountStatusActive();
        const isAccountStatusArchived = this._isAccountStatusArchived();

        let setFunc = null;

        switch (true) {
            case isAccountStatusActive: {
                setFunc = setAccountStatusArchive;
                break;
            }
            case isAccountStatusArchived: {
                setFunc = setAccountStatusActive;
                break;
            }
            default: {
                throw new Error(`Can*t do the request in the current account_status!`);
            }
        }

        await this.save({ loadedInnerRequest: false, });

        try {
            const response = await setFunc(accountId,);

            if (response?.error) {
                throw new Error(response?.error);
            }

            const requestStatusMsg = isAccountStatusArchived
                ? translate('account_success_rearchived')
                : translate('account_success_archived');
            NotificationService.success({
                title:   'success',
                message: requestStatusMsg,
                remove:  false,
            });

            await this.save({ refreshClientDataFlag: true });
            this.popUpCloseFunc();
        } catch (error) {
            error?.showErrorNotification?.();
        }

        await this.save({ loadedInnerRequest: true, });
    };

    renderArchiveBtn = () => {
        const { translate, departmentsListNames = [], } = this.props;
        const departmentsCondition = departmentsListNames?.includes('administration')
          || departmentsListNames?.includes('technical_support')
          || departmentsListNames?.includes('administrator_sales');
        const isAccountStatusArchived = this._isAccountStatusArchived();
        const isAccountStatusDeleted = this._isAccountStatusDeleted();
        const permissionActive = this.calcData?.coreAccount?.access('active');
        const permissionArchive = this.calcData?.coreAccount?.access('archive');

        const btnArchiveClassName = isAccountStatusArchived ?
            (permissionActive ? '' : 'gl-btn--disabled') :
            (permissionArchive ? '' : 'gl-btn--disabled');
        const btnNameText = !isAccountStatusArchived ? translate(`client_archive`) : translate(`client_unzip`);
        const renderFlag = departmentsCondition && !isAccountStatusDeleted;

        return renderFlag ? (
            <button className={`gl-btn gl-btn--blue archive-btn ${btnArchiveClassName}`}
                    onClick={this.onArchiveBtnClick()}
            >
                {btnNameText}
            </button>
        ) : null;
    };

    checkAccountForApprovedDocumentsAndOrder = async () => {
        const { accountDocuments, } = this.state;

        if (!accountDocuments.length) {
            await this.save({ documentsArePinned: false, });
        } else {
            accountDocuments.map(async (accDocument) => {
                const documentsArePinned = [3].includes(accDocument?.core?.account_document?.status);

                if (documentsArePinned) {
                    await this.save({ documentsArePinned, })
                } else {
                    await this.save({ documentsArePinned, })
                }
            });
        }
    };

    onVerifySourceOfFundsBtnClick = (actionName) => async () => {
        const { sourceApproveAccount, } = this.state;

        switch (actionName) {
            case 'confirm': {
                await this.save({
                    isVerifyFundsModalOpen: !this.state.isVerifyFundsModalOpen,
                    clickedConfirmFundsRequest: true,
                    clickedCancelFundsRequest: false,
                    loadedPopupSourceOfFundsData: false,
                });
                const CONFIRMED_STATUS = 3;
                try {
                    await clientService.accountChangeHistory(sourceApproveAccount?.id, { status: CONFIRMED_STATUS, });

                    await this.save({ successfullyConfirmedDocument: true, loadedPopupSourceOfFundsData: true, });
                } catch (e) {
                    const verificationError = e?.response?.data?.errors?.status.join("");
                    await this.save({ verificationError, successfullyConfirmedDocument: false, loadedPopupSourceOfFundsData: true, });
                }
                break;
            }
            case 'cancel': {
                await this.save({
                    isVerifyFundsModalOpen: !this.state.isVerifyFundsModalOpen,
                    clickedCancelFundsRequest: true,
                    clickedConfirmFundsRequest: false,
                    loadedPopupSourceOfFundsData: false,
                });

                const REJECTED_STATUS = 4;
                try {
                    await clientService.accountChangeHistory(sourceApproveAccount?.id, { status: REJECTED_STATUS, });
                    await this.save({ successfullyRejectedDocument: true, loadedPopupSourceOfFundsData: true, });
                } catch (e) {
                    const verificationError = e?.response?.data?.errors?.status.join("");
                    await this.save({ verificationError, successfullyRejectedDocument: false, loadedPopupSourceOfFundsData: true, });
                }
                break;
            }
            default: {
                await this.save({ clickedConfirmFundsRequest: false, clickedCancelFundsRequest: false, });
                throw new Error(`Can't resolve action!`);
            }
        }
    };

    renderVerifySourceOfFundsBtns = () => {
        const {
            sourceApproveAccount,
            successfullyConfirmedDocument,
            successfullyRejectedDocument,
        } = this.state;

        const { translate, client, departmentsListNames, } = this.props;

        const financialDepartment = 'financial'.includes(departmentsListNames);
        const sourceOfFunds = client?.account?.source_of_funds;
        const NOT_CONFIRMED_SOURCE_OF_FUNDS = sourceOfFunds === 2;
        const IN_PROCESS_STATUS = [1].includes(sourceApproveAccount?.status);
        const WAIT_CONFIRMATION = [2].includes(sourceApproveAccount?.status);
        const isRender = NOT_CONFIRMED_SOURCE_OF_FUNDS && (IN_PROCESS_STATUS || WAIT_CONFIRMATION);

        const isDisabledConfirmBtn = NOT_CONFIRMED_SOURCE_OF_FUNDS && (!IN_PROCESS_STATUS || !WAIT_CONFIRMATION);
        const isDisabledCancelBtn = NOT_CONFIRMED_SOURCE_OF_FUNDS && (!IN_PROCESS_STATUS || !WAIT_CONFIRMATION);

        const btnConfirmRequestIsDisabled = isDisabledConfirmBtn ? '' : 'gl-btn--disabled';
        const btnCancelRequestIsDisabled = isDisabledCancelBtn ? '' : 'gl-btn--disabled';

        return isRender ? (
            <div className='status-block request-action-btns request-action-btns--account-update-request source-of-founds-block p15'>
                { financialDepartment && !(successfullyConfirmedDocument || successfullyRejectedDocument) ? (
                  <span className='input__text icon icon--info-red'>
                    { translate('documents_verification_source_of_funds') }
                </span>
                ) : null }
                { successfullyConfirmedDocument
                    ? <p className='input__text'>{ translate('clients_request_to_confirm_the_source_of_funds_is_accepted') }</p>
                        : null
                }
                { successfullyRejectedDocument
                    ? <p className='input__text'>{ translate('clients_request_to_confirm_the_source_of_funds_is_rejected') }</p>
                        : null
                }
                {
                    financialDepartment && !(successfullyConfirmedDocument || successfullyRejectedDocument) ? (
                      <div>
                          <button className={`gl-btn gl-btn--blue-dark ${btnConfirmRequestIsDisabled}` }
                                  onClick={this.onVerifySourceOfFundsBtnClick('confirm')}
                          >
                              { translate('clients_verification_account_status_action_request_confirm') }
                          </button>

                          <button className={`gl-btn gl-btn--red ${btnCancelRequestIsDisabled}` }
                                  onClick={this.onVerifySourceOfFundsBtnClick('cancel') }
                          >
                              { translate('clients_cancel_request_btn') }
                          </button>
                      </div>
                    )  : null
                }
            </div>
        ) : null;
    };

    currentlyDisabledStatusOfSelect = async () => {
        const { activeDocumentIndex, } = this.state;
        const { departmentsListNames, } = this.props;

        const calcData = this.calcData;
        const pm = ['show', 'store', 'update'];
        const financialDepartment = 'financial'.includes(departmentsListNames);
        const currentlyDocType = this.state?.['documentsData']?.[activeDocumentIndex]?.['type'];
        const data = currentlyDocType?.data;

        await this.save((state) => {
            let newState = {};
            const tmpList = state?.['documentsData'];
            const currentRow = tmpList?.[activeDocumentIndex];


            // if (calcData?.status?.access(...pm)) {
            //
            //     if (!financialDepartment && !(data?.value === 'BANK_ACCOUNT_STATEMENT' || data?.value === 'CONTRACT_OF_SALE' )) {
            //             currentRow['status'].isDisabled = false;
            //
            //             newState = { ['documentsData']: tmpList, };
            //         }else {
            //         currentRow['status'].isDisabled = true;
            //
            //         newState = { ['documentsData']: tmpList, };
            //     }
            //     if (financialDepartment && !(data?.value === 'BANK_ACCOUNT_STATEMENT' || data?.value === 'CONTRACT_OF_SALE' )) {
            //         currentRow['status'].isDisabled = true;
            //
            //         newState = { ['documentsData']: tmpList, };
            //     }else {
            //         currentRow['status'].isDisabled = false;
            //
            //         newState = { ['documentsData']: tmpList, };
            //     }
            // }

            return newState;
        });
    };

    renderAccountStatusBlock = () => {
        const { translate, enums = {}, client, } = this.props;
        const propertyName = 'statusData';
        const { is_verify_document, status } = this?.state?.[propertyName] ?? {};

        const sourceOfFunds = client?.account?.source_of_funds;
        const isVerifyDocumentText = enums?.AccountVerifyDocumentEnum?.[is_verify_document] ?? '';
        const statusText = enums?.AccountStatusEnum?.[status] ?? '';
        const sourceOfFundsText = enums?.AccountSourceOfFundsStatusEnum?.[sourceOfFunds];

        return (
            <div id={`section-${this.menuItems?.statusData?.name}`} className='cont cont--with-border-bottom'>
                <div className='cont-title'>
                    <h4 className='cont-title__name'>{translate('client_verification_title_account_status')}</h4>
                </div>

                <div className={'status-blocks'}>
                    <div className={'status-block status-block--verify-status'}>
                        <span className={'input__text'}>{translate('client_verification_label_verify_status')}</span>
                        <span
                            className={'input__text align-center'}>{this.renderVerificationStatus()} {isVerifyDocumentText}</span>
                    </div>
                    { this.renderAccountCancelConfirmRequestBtns() }
                    <div className={'status-block status-block--account-status'}>
                        <span className={'input__text'}>{ translate('client_verification_label_account_status') }</span>
                        {this.renderArchiveBtn()}
                        <span className={'input__text'}>{ statusText }</span>
                    </div>
                    <div className={'status-block status-block--account-status'}>
                        <span className={'input__text'}>{ translate('source_of_origin_funds_status') }</span>
                        <span className={'input__text'}>{ sourceOfFundsText }</span>
                    </div>
                </div>
                    { this.renderVerifySourceOfFundsBtns() }
                    { this.renderAccountStatusUpdateBtns() }
             </div>
        );
    };

    renderPopUpSourceOfFunds = () => {
        const {
            isVerifyFundsModalOpen,
            sourceApproveAccount,
            accountDocuments,
            documentsArePinned,
            clickedConfirmFundsRequest,
            clickedCancelFundsRequest,
            verificationError,
            successfullyConfirmedDocument,
            successfullyRejectedDocument,
            loadedPopupSourceOfFundsData,
        } = this.state;

        const {
            translate,
            client,
            accountId,
            refreshClientData,
        } = this.props;

        return (
            <>
                { isVerifyFundsModalOpen
                    ? <PopUpSourceOfFunds
                        loadedPopupSourceOfFundsData={ loadedPopupSourceOfFundsData }
                        accountId={ accountId }
                        title={ translate('source_of_origin_funds_status') }
                        isOpen={ async () => await this.save({ isVerifyFundsModalOpen: true, }) }
                        sourceApproveAccount={ sourceApproveAccount }
                        sourceOfFunds={ client?.account?.source_of_funds }
                        accountDocuments={ accountDocuments }
                        documentsArePinned={ documentsArePinned }
                        clickedConfirmFundsRequest={ clickedConfirmFundsRequest }
                        clickedCancelFundsRequest={ clickedCancelFundsRequest }
                        verificationError={ verificationError }
                        successfullyConfirmedDocument={ successfullyConfirmedDocument }
                        successfullyRejectedDocument={ successfullyRejectedDocument }
                        onDataLoad={ async () => {
                            try {
                                await this.save({ loaded: false, });
                                await this.save({ isVerifyFundsModalOpen: false, });
                                if (successfullyConfirmedDocument) {
                                    refreshClientData();
                                }
                                await this.save({ loaded: true, });
                                return this._smoothScrollToTargetElemByHref('#section-client-status');
                            } catch (e) {
                                e?.showErrorNotification?.();
                            }
                        } }
                    />
                    : null }
            </>
        )
    };

    renderAccountDataBlock = () => {
        const { translate, } = this.props;
        const propertyName = 'formData';

        return (
            <div id={`section-${this.menuItems?.personalData?.name}`} className='cont cont--with-border-bottom'>
                <div className='cont-title'>
                    <h4 className='cont-title__name'>{translate('client_verification_title_account_data')}</h4>
                </div>

                <div className="cont__left">
                    {
                        [
                            'surname',
                            'surname_latin',
                            'name',
                            'name_latin',
                            'person_identity_number',
                            'issue_country_id',
                        ].map((fieldName) => this.renderInputBlock(fieldName, propertyName))
                    }
                </div>

                <div className="cont__right">
                    {
                        [
                            'patronymic',
                            'gender',
                            'birth_date',
                            'nationality_id',
                            'issue_date'
                        ].map((fieldName) => this.renderInputBlock(fieldName, propertyName))
                    }
                </div>

                {this.renderAccountDataBtns()}
            </div>
        );
    };

    renderAccountDataResidencyBlocks = () => {
        const { taxResidenciesData = [], taxResidenciesChangesData = [] } = this.state;
        const { translate, } = this.props;

        return taxResidenciesData?.length ?
            (
                <div id={`section-${this.menuItems?.taxResidency?.name}`}
                     className='cont cont--with-border-bottom'
                >
                    <div className='cont-title'>
                        <h4 className='cont-title__name'>{translate('client_verification_title_account_data_tax_residency')}</h4>
                    </div>

                    {this.renderAccountTinAbsenceReasons()}

                    {taxResidenciesData?.map((item, rowIndex) => {
                        const blockClassName = item?.temporary_status?.toString() === '3' ? 'error' : ''; // AccountTaxResidencyDataTemporaryStatusEnum - '3' - deleted

                        return (
                            <div className={`residency-blocks-wrapper ${blockClassName}`}
                                 key={`residency-blocks-wrapper-${rowIndex}`}
                            >
                                {!isNull(item) && this.renderAccountDataResidencyBlock(item, 'taxResidenciesData', rowIndex)}
                                {!isNull(taxResidenciesChangesData?.[rowIndex]) && this.renderAccountDataResidencyBlock(taxResidenciesChangesData?.[rowIndex], 'taxResidenciesChangesData', rowIndex)}

                                {/* switch render logic - uncomment if necessary and check! */}
                                {/*{
                                    isNull(taxResidenciesChangesData?.[rowIndex]) ? (
                                        !isNull(item) && this.renderAccountDataResidencyBlock(item, 'taxResidenciesData', rowIndex)
                                    ) : (
                                        this.renderAccountDataResidencyBlock(taxResidenciesChangesData?.[rowIndex], 'taxResidenciesChangesData', rowIndex)
                                    )
                                }*/}
                            </div>
                        );
                    })}

                    {this.renderResidencyCancelConfirmRequestBtns()}
                </div>
            ) : null;
    };

    renderResidencyCancelConfirmRequestBtns = () => {
        const { translate, departmentsListNames } = this.props;

        const typeName = 'change_tax_residency_data';
        const isStatusInProcess = this._isAccountChangeHistoryStatusInProcess(typeName);
        const isStatusWaitConfirmation = this._isAccountChangeHistoryStatusWaitConfirmation(typeName);
        const isStatusActive = this._isAccountChangeHistoryStatusActive(typeName);
        let disableButton = false
        const btnCancelRequestClassName = this.calcData?.coreAccountChangeHistory?.access('changeTaxResidencyDataRejected')   ? '' : 'gl-btn--disabled';
        const btnConfirmRequestClassName = this.calcData?.coreAccountChangeHistory?.access('changeTaxResidencyDataWaitConfirmation') ? '' : 'gl-btn--disabled';
        const id = this?.state?.accountChangeHistoryLastRequest?.[typeName]?.id ?? null;
        const calcData = this.calcData;
        const pm = ['show', 'store', 'update'];
        const verificationDepartment = 'verification'.includes(departmentsListNames);


        if (calcData?.status?.access(...pm)) {
            disableButton = !verificationDepartment;

        }
        const disabledBtnStyle = !disableButton ? ''  : 'gl-btn--disabled' ;
        return isStatusActive ? (
            <div className='residency-blocks input request-action-btns request-action-btns--residency'>
                <span className='input__text icon icon--info-red'>
                    {translate('client_verification_label_residency_update_actions')}
                </span>

                <div>
                    {
                        !isStatusWaitConfirmation ? (
                            <button  className={`gl-btn gl-btn--blue-dark ${btnConfirmRequestClassName || disabledBtnStyle}`}
                                    onClick={this.onResidencyCancelConfirmRequestBtnClick('confirm', id)}
                            >
                                {translate('clients_confirm_request_btn')}
                            </button>
                        ) : (
                            <span className='input__text'>
                                {translate('clients_wait_confirmation_text')}
                            </span>
                        )
                    }

                    <button   className={`gl-btn gl-btn--red ${btnCancelRequestClassName || disabledBtnStyle}`}
                            onClick={this.onResidencyCancelConfirmRequestBtnClick('cancel', id)}
                    >
                        {translate('clients_cancel_request_btn')}
                    </button>
                </div>
            </div>
        ) : null;
    };

    renderAccountTinAbsenceReasons = () => {
        const { accountTinAbsenceReasons = [] } = this.state;

        return accountTinAbsenceReasons.length ? (
            <div className='tin-absence-reasons'>
                {
                    accountTinAbsenceReasons?.map((item, index) => {
                        return (
                            <p className='input__text' key={`tin-absence-reason-${index}`}>
                                {item?.name} - {item?.description}
                            </p>
                        );
                    })
                }
            </div>
        ) : null;
    };

    renderResidencyStatus = (propertyName, rowIndex) => {
        const statusToIconClassNameMap = {
            1: 'status-success',
            2: 'status-warning',
            3: 'status-cancel',
        };
        const actionToIconClassNameMap = {
            1: 'status-warning',
            2: 'status-cancel',
        };

        let iconClassName;

        switch (propertyName) {
            case 'taxResidenciesData': {
                const statusValue = this.state?.[propertyName]?.[rowIndex]?.['status'] || '';

                iconClassName = statusToIconClassNameMap?.[statusValue] ?? '';
                break;
            }
            case 'taxResidenciesChangesData': {
                const statusValue = this.state?.[propertyName]?.[rowIndex]?.['status'] || '';
                const actionValue = this.state?.[propertyName]?.[rowIndex]?.['action'] || '';

                if (statusValue?.toString() === '1') {
                    // status - NEW
                    iconClassName = actionToIconClassNameMap?.[actionValue] ?? '';
                }

                break;
            }
            default: {
                break;
            }
        }

        return (
            <div className={`icon icon--${iconClassName}`}></div>
        );
    };

    renderResidencyAction = (propertyName, rowIndex) => {
        const { translate } = this.props;
        const taxResidenciesData = this.state?.taxResidenciesData;
        const targetTaxResidenciesData = taxResidenciesData?.[rowIndex];
        const targetTaxResidenciesChangesData = this.state?.taxResidenciesChangesData?.[rowIndex];
        const { departmentsListNames } = this.props;
        const calcData = this.calcData;
        const pm = ['show', 'store', 'update'];
        const financialDepartment = 'verification'.includes(departmentsListNames);
        const typeName = 'change_tax_residency_data';
        const isAccountChangeHistoryStatusActive = this._isAccountChangeHistoryStatusActive(typeName);
        const trdTemporaryStatus = targetTaxResidenciesData?.temporary_status;
        const trdcStatus = targetTaxResidenciesChangesData?.status;

        const isEmptyTaxResidenciesChangesData = isNull(targetTaxResidenciesChangesData);
        const isRenderFlag = isEmptyTaxResidenciesChangesData || (propertyName === 'taxResidenciesChangesData' && trdcStatus?.toString() === '1'); // status --- new
        let whiteList = [];
        let disableInput = false
        if (calcData?.status?.access(...pm)) {
            if (!financialDepartment) {
                disableInput = true
            }
        }

        switch (true) {
            case propertyName === 'taxResidenciesData' && isNull(trdTemporaryStatus): {
                const isDeleteOptionActive = taxResidenciesData?.length === 2 &&
                    taxResidenciesData.every(
                        (item) => item?.temporary_status?.toString() !== '3' // temporary_status --- NOT deleted
                    );

                whiteList = isDeleteOptionActive ? ['edit', 'delete'] : ['edit'];
                break;
            }
            case propertyName === 'taxResidenciesChangesData' && trdTemporaryStatus?.toString() === '2': {
                // temporary_status - changed
                whiteList = ['undo', 'update'];
                break;
            }
            case propertyName === 'taxResidenciesChangesData' && trdTemporaryStatus?.toString() === '3': {
                // temporary_status - deleted
                whiteList = ['restore'];
                break;
            }
            default: {
                break;
            }
        }

        const isResidencyActionSelectDisabled = !this.calcData?.coreAccountTaxResidencyDataChanges?.access('store', 'update', 'destroy');
        const filteredOptions = this.residencyActionOptions.filter((item) => whiteList?.includes(item?.value));
        const selectedOption = null;

        return (
            <div className='residency-action-select input'>

                {isAccountChangeHistoryStatusActive && isRenderFlag ? (
                    <Select
                        className={`input-wrapper input__field input-select`}
                        classNamePrefix='select'
                        isDisabled={isResidencyActionSelectDisabled || disableInput}
                        isSearchable={false}
                        onChange={this.onResidencyActionSelectChange(rowIndex)}
                        options={filteredOptions}
                        placeholder={translate('clients_verification_residency_action_select_option_placeholder')}
                        value={selectedOption}
                    />
                ) : null}
            </div>
        );
    };

    renderAccountDataResidencyBlock = (item, propertyName, rowIndex) => {
        const { translate } = this.props;
        const taxResidenciesData = this.state?.taxResidenciesData;
        const targetTaxResidenciesData = taxResidenciesData?.[rowIndex];

        const trdTemporaryStatus = targetTaxResidenciesData?.temporary_status;

        const _renderInfoText = () => {
            const modeToTextMap = {
                2: 'clients_verification_residency_text_edit',
                3: 'clients_verification_residency_text_delete',
            };
            const infoText = translate(modeToTextMap?.[trdTemporaryStatus] ?? '');

            return (
                <div className='residency-blocks residency-blocks--info-text request-action-btns'>
                    <span className='input__text icon icon--info-red'>
                        {infoText}
                    </span>
                </div>
            );
        };

        return (
            <div key={`residency-block-${propertyName}-${rowIndex}`} className={`residency-blocks`}>
                {
                    [
                        'country_id',
                        'tin',
                        'tin_absence_reason_id',
                    ].map((fieldName) => (
                        <div key={`residency-block-${rowIndex}-${fieldName}`} className='residency-block'>
                            {this.renderInputBlock(fieldName, propertyName, rowIndex)}
                        </div>
                    ))
                }

                <div className="residency-block residency-block--status">
                    {this.renderResidencyStatus(propertyName, rowIndex)}
                </div>

                <div className="residency-block residency-block--action">
                    {this.renderResidencyAction(propertyName, rowIndex)}
                </div>

                <div className="residency-block residency-block--full">
                    {this.renderInputBlock('tin_absence_reason_text', propertyName, rowIndex)}
                </div>

                {propertyName === 'taxResidenciesChangesData' ? _renderInfoText() : null}
            </div>
        );
    };

    _smoothScroll = (elem, offsetTop) => {
        elem && elem?.scrollTo({ top: offsetTop, behavior: 'smooth' });
    };

    _smoothScrollToTargetElemByHref = (targetHref) => {
        const HEADER_HEIGHT = document.querySelector('.pop-up-header')?.clientHeight ?? 100;
        const targetElem = document.querySelector(targetHref);
        const targetOffsetTop = targetElem?.offsetTop ?? 0;

        this._smoothScroll(this.popUpWrapper, targetOffsetTop - HEADER_HEIGHT);
    };

    onMenuItemClick = (event) => {
        event.preventDefault();
        const targetHref = event?.currentTarget?.getAttribute('href');

        this._smoothScrollToTargetElemByHref(targetHref);
    };

    renderPopUpMenu = () => {
        const menuItems = Object.values(this.menuItems)?.filter(
            (item) => {
                const stateList = this.state?.[item?.stateListName];

                return stateList && (stateList instanceof Array ? stateList?.length : true);
            }
        ) ?? [];

        const renderMenuItem = (menuItem) => {
            const { name, label } = menuItem;

            return (
                <a className={`pop-up-menu__item tooltip-wrapper icon icon--${name}`}
                   href={`#section-${name}`}
                   onClick={this.onMenuItemClick}
                   key={`menu-item-${name}`}
                >
                    <div className='tooltip'>{label}</div>
                </a>
            );
        };

        return (
            <div className='pop-up-menu'>
                {
                    menuItems?.map((menuItem) => {
                        return renderMenuItem(menuItem);
                    })
                }
            </div>
        );
    };

    renderPopUpHeader = () => {
        const { translate, } = this.props;
        const { departmentsListNames } = this.props;
        const calcData = this.calcData;
        const pm = ['show', 'store', 'update'];
        const verificationDepartment = 'verification'.includes(departmentsListNames);
        const permission = calcData?.coreAccount?.access('verifyDocumentVerified');
        let enableVerificationButton = false


        if (calcData?.status?.access(...pm)) {
            if (verificationDepartment && permission) {
                enableVerificationButton = true
            }
        }

        return (
            <div className='pop-up-header'>

                {this.renderPopUpMenu()}

                <h3 className='pop-up__name'>
                    {`${translate('client_verification_title')}`}
                </h3>
                {enableVerificationButton && this.renderVerificationBtn() }

                <i className='gl-icon close-btn close-btn--big' onClick={this.popUpCloseFunc}/>
            </div>
        );
    };

    onVerificationBtnClick = () => async () => {
        const accountId = this.props?.accountId;

        await this.save({ loadedInnerRequest: false, });

        try {
            const response = await postAccountVerify(accountId);

            if (response?.error) {
                throw new MagicError(Error(response?.error)); // custom error from back-end
            }

            await this.save({ refreshClientDataFlag: true });
            this.popUpCloseFunc();
        } catch (error) {
            //error?.showErrorNotification?.();
            error?.response?.data?.errors?.forEach((errorItem) => {
                NotificationService.error({
                    title:   "error_validation",
                    message: errorItem,
                    remove:  false,
                });
            });
        }

        await this.save({ loadedInnerRequest: true, });
    };

    renderVerificationBtn = () => {
        const { translate, departmentsListNames = [], eventName = '', accountIndividualVerifierStatus } = this.props;
        const { status, service_is_live, register_type } = accountIndividualVerifierStatus ?? {};
        const isVerificator = departmentsListNames?.includes('verification');
        const permission = this.calcData?.coreAccount?.access('verifyDocumentVerified');

        const btnVerificationClassName = permission && isVerificator ? '' : 'gl-btn--disabled';
        const renderFlag = !(eventName?.toString() === 'onDocumentClick') &&
            status?.toString() !== '1' &&
            !service_is_live &&
            this._isAccountIndividualOrTest(register_type);

        return renderFlag ? (
            <button className={`gl-btn gl-btn--blue verification-btn ${btnVerificationClassName}`}
                    onClick={this.onVerificationBtnClick()}
            >
                {translate('client_verification_btn')}
            </button>
        ) : null;
    };

    render () {
        const { loaded, loadedInnerRequest, } = this.state;

        return (
            <Loader
                loaded={loaded}
                loading={(<Preloader className='loaderUniversal--fixed-pos' scale={1}/>)}
            >
                <div className='glalex-styles partners icon-styles'>
                    <div className='pop-up pop-up--verification pop-up--active'>

                        {!loadedInnerRequest ?
                            (
                                <Preloader className='loaderUniversal--fixed-pos' scale={1}/>
                            ) : null
                        }

                        <div className='pop-up-wrapper scroll' ref={(node) => this.popUpWrapper = node}>
                            {this.renderPopUpHeader()}

                            <div className='pop-up-content'>
                                {this.renderAccountDataBlock()}

                                {this.renderAccountAddressBlocks()}

                                {this.renderPopUpSourceOfFunds()}

                                {this.renderAccountStatusBlock()}

                                {this.renderAccountDataResidencyBlocks()}

                                {this.renderAccountDocumentsBlock()}
                            </div>
                        </div>
                    </div>
                </div>
            </Loader>
        );
    }
}

export default withLocalize(PopUpVerification);
