import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, } from 'react-router-dom';
import { Provider, } from 'react-redux';
import { LocalizeProvider, } from 'react-localize-redux';

import './app/services/CachingService';
import './app/services/EnumDataService';
import './app/services/ProfileDataService';
import './app/services/SupportDataService';

import Store from './app/store';
import IndexApp from './app/IndexApp';
import ResetForm from './app/modules/common/components/AuthPage/ResetForm';

import './app/images/favicon/favicon.ico';
import './app/images/favicon/apple-touch-icon-72x72.png';

ReactDOM.render((
	<BrowserRouter>
		<Provider store={ Store }>
			<LocalizeProvider store={ Store } >
				<Switch>
					<Route component={ ResetForm } path={ '/user-reset-password/:token' } />
					<Route component={ IndexApp } path={ '/' } />
				</Switch>
			</LocalizeProvider>
		</Provider>
	</BrowserRouter>
),
document.getElementById('crm-app'),
);