import {
  itemsBuild,
  itemsDrain,
  itemsResort,
  CFG_ITEM_TYPE,
} from '../Config';
import { onEnabled, render, } from '../Mentor/withDropDown';

const o_settings = {
  'o=': ['=', '!='],
}

const o_changed_date = {
  'o=': ['=', '!=', '<', '>', '<=', '>='],
};

const f_channels = {
  'drop-down': '/api/statistics/utm_source/drop-down?take=all',
  'o=': ['=', '!=',],
};

const p_channels = {
  onEnabled: onEnabled('accUtmSource'),
  render: render('accUtmSource'),
};

const f_subchannels = {
  'drop-down': '/api/statistics/utm_medium/drop-down?take=all',
  'o=': ['=', '!=',],
};

const p_subchannels = {
  onEnabled: onEnabled('accUtmMedium'),
  render: render('accUtmMedium'),
}

const f_campaign = {
  'drop-down': '/api/statistics/utm_campaign/drop-down?take=all',
  'o=': ['=', '!=',],
};

const p_campaign = {
  onEnabled: onEnabled('accUtmCampaign'),
  render: render('accUtmCampaign'),
};

const f_country = {
  'drop-down': '/api/statistics/account_utm_link/country-drop-down?take=all',
  'o=': ['=', '!=',],
};

const p_country = {
  onEnabled: onEnabled('coreAccCountry'),
  render: render('coreAccCountry'),
};

const account_utm_link = {
  id: 701,
  ...[

    // filters
    ...[ {
      f: 'statistics.account_utm_link.account_id',
      id: 'f:accUtmLinkId',
      ...o_settings,
    }, {
      f: 'core.account.name',
      id: 'f:coreAccName',
      ...o_settings,
    }, {
      f: 'core.account.surname',
      id: 'f:coreAccSurName',
      ...o_settings,
    }, {
      f: 'core.account_categories.category_id',
      id: 'f:coreAccCategory',
      ...o_settings,
    }, {
      f: 'core.country.id',
      id: 'f:coreAccCountry',
      ...f_country,
    }, {
      f: 'core.account_categories.created_at',
      id: 'f:coreAccCategoryCreatedAt',
      ...o_changed_date,
    }, {
      f: 'statistics.utm_source.id',
      id: 'f:accUtmSource',
      ...f_channels,
    }, {
      f: 'statistics.utm_medium.id',
      id: 'f:accUtmMedium',
      ...f_subchannels,
    }, {
      f: 'statistics.utm_campaign.id',
      id: 'f:accUtmCampaign',
      ...f_campaign,
    }, {
      f: 'core.account.created_at',
      id: 'f:coreAccCreatedAt',
      ...o_changed_date,
    }, {
      f: 'core.dictionary_licenses.alias',
      id: 'f:coreAccLicense',
      ...o_settings,
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

    // orders
    ...[ {
      f: 'statistics.account_utm_link.account_id',
      id: 'o:accUtmLinkId',
    }, {
      f: 'core.account.name',
      id: 'o:coreAccName',
    }, {
      f: 'core.account.surname',
      id: 'o:coreAccSurName',
    }, {
      f: 'core.account_categories.category_id',
      id: 'o:coreAccCategory',
    }, {
      f: 'core.country.id',
      id: 'o:coreAccCountry',
    }, {
      f: 'core.account_categories.created_at',
      id: 'o:coreAccCategoryCreatedAt',
    }, {
      f: 'statistics.utm_source.id',
      id: 'o:accUtmSource',
    }, {
      f: 'statistics.utm_medium.id',
      id: 'o:accUtmMedium',
    }, {
      f: 'statistics.utm_campaign.id',
      id: 'o:accUtmCampaign',
    }, {
      f: 'core.account.created_at',
      id: 'o:coreAccCreatedAt',
    }, {
      f: 'core.dictionary_licenses.alias',
      id: 'o:coreAccLicense',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

    // panels
    ...[ {
      ii: 'f:accUtmLinkId',
      t: 'magic_filter_translate_account_utm_link_account_id',
    }, {
      ii: 'f:coreAccName',
      t: 'magic_filter_translate_statistics_account_core_name',
    }, {
      ii: 'f:coreAccSurName',
      t: 'magic_filter_translate_statistics_account_core_surname',
    }, {
      ii: 'f:coreAccCategory',
      t: 'magic_filter_translate_statistics_account_core_category',
    }, {
      ii: 'f:coreAccCountry',
      t: 'magic_filter_translate_statistics_account_core_country',
      ...p_country,
    }, {
      ii: 'f:coreAccCategoryCreatedAt',
      t: 'magic_filter_translate_account_change_category_date',
    }, {
      ii: 'f:accUtmSource',
      t: 'magic_filter_translate_utm_channel',
      ...p_channels,
    }, {
      ii: 'f:accUtmMedium',
      t: 'magic_filter_translate_utm_subchannel',
      ...p_subchannels,
    }, {
      ii: 'f:accUtmCampaign',
      t: 'magic_filter_translate_utm_campaign',
      ...p_campaign,
    }, {
      ii: 'f:coreAccCreatedAt',
      t: 'magic_filter_translate_statistics_account_core_date_of_creation',
    }, {
      ii: 'f:coreAccLicense',
      t: 'magic_filter_translate_task_license_alias',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

  ]
  .map(itemsBuild)
  .reduce(itemsDrain, [])
  .reduce(itemsResort, {}),

  path: 'statistics/account_utm_link',
  widget: false,
};

export default [
  account_utm_link,
  ...[ 1, 2, 3, 4, 5, 6, 7, ].map(categoryId => ({
    ...account_utm_link,
    id: account_utm_link.id + categoryId,
    ...{
      // filters
      filters: account_utm_link.filters
        .map(_ => ({
          ..._,
          ..._.$.id === 'f:coreAccCategory' && {
            auto: 1,
            enabled: 1,
            v: `${ categoryId }`,
          },
        })),
      // orders
      orders: account_utm_link.orders.filter(_ => _.$.id !== 'o:coreAccCategory'),
      // panels
      panels: account_utm_link.panels.filter(_ => _.items !== 'f:coreAccCategory'),
    },
  })),
];
