import { DataService, } from './DataService';
import {
    get,
    post,
    put,
} from '../components/Magic';
import { hash as hashData } from './CachingService';
import {
    CHECK_ACCOUNT_FROM_SPAM,
    DELETE_ACCOUNT_FROM_SPAM,
    GET_MAIL_BY_ID,
    GET_MAILS_BY_ACCOUNT_ID
} from '../modules/clients/apiRoutes'; // TODO will be refactor
import {
    GET_ACCOUNT_HISTORY_BY_ACCOUNT,
    PATCH_ACCOUNT_CHANGE_HISTORY,
} from '../apiRoutes';

class ClientDataService extends DataService {
    constructor () {
        super([
            'mails',
            'mail',
        ]);
        Object.freeze(this);
    }

    async mails (accountId, { filterId, take, skip }) {
        const url = `${GET_MAILS_BY_ACCOUNT_ID}/${accountId}`;
        const { data, meta } = await get(url, { filterId, take, skip });
        const hash = await hashData(data);
        const mails = { data, meta, hash, options: { filterId, take, skip } };
        await this.emit('mails', mails);

        return mails;
    }

    async mail (letterId) {
        const url = `${GET_MAIL_BY_ID}/${letterId}`;
        const { data: { log: { mails } } } = await get(url);
        await this.emit('mail', mails);

        return mails;
    }

    async delFromSpam ({ account_id }) {
        const url = `${ DELETE_ACCOUNT_FROM_SPAM }`;
        return await post(url, { account_id });
    }

    async checkAccInSpamList ({ account_id }) {
        const url = `${ CHECK_ACCOUNT_FROM_SPAM }`;
        return await post(url, { account_id });
    }

    async accountChangeHistoryForSourceApprove (account_id) {
        const url = `${ GET_ACCOUNT_HISTORY_BY_ACCOUNT }/${account_id}/for-source-approve`;
        return await get(url);
    }

    async accountChangeHistory (account_id, { status, }) {
        const url = `${PATCH_ACCOUNT_CHANGE_HISTORY}/${account_id}/update-source-of-funds`;
        return await put(url, { status });
    }
}

export const clientService = new ClientDataService();
