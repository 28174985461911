import React, { Component } from 'react';
import { MagicSwitch } from "../../../../../tima/components/Magic/MagicSwitch";
import Loader from "../../../../../tima/components/Loader";
import Preloader from "../../../../../../components/LoadingHOC/Preloader";
import PropTypes from "prop-types";

class BlockTradingToolsNew extends Component {
    constructor (props) {
        super(props);

        const { riskToolsAll: toolsAll = [], riskToolsCategoriesAll: toolsCategoriesAll = [] } = props;

        this.state = {
            loaded:                 true,
            toolsAll,
            toolsCategoriesAll,
            toolsCategoriesCurrent: [],
        };
    }

    componentDidMount () {
        const { toolsCategoriesAll = [], toolsAll = [] } = this.state;

        this.setToolsCategoriesCurrent(toolsCategoriesAll, toolsAll);
        this.transferDataToParent();
    }

    transferDataToParent = () => {
        const { toolsAll = [] } = this.state;
        const selectedTools = toolsAll
            .map((item) => item['risk_tools'])
            .filter((item) => item['isSelected']);

        this.props.updateTradingTools(selectedTools);
    };

    _getToolsListByCategoryId = (toolsList, categoryId) => {
        if (toolsList instanceof Array && +categoryId >= 0) {
            const toolsListFiltered = [];

            toolsList.forEach((item, i) => {
                if (item['risk_tools']['category_id'] === categoryId) {
                    const itemObj = item['risk_tools'];

                    itemObj['isSelected'] = item['risk_tools']['disabled']; // duplicate field value
                    toolsListFiltered.push(itemObj);
                }
            });

            return toolsListFiltered;
        } else {
            return [];
        }
    };

    setToolsCategoriesCurrent = (toolsCategoriesAll, toolsAll) => {
        toolsCategoriesAll.forEach((categoryItem) => {

            categoryItem['risk_tools'] = this._getToolsListByCategoryId(toolsAll, categoryItem['risk_tool_categories'].id);
            categoryItem['countToolsSelected'] = 0;
            categoryItem['countToolsAll'] = categoryItem['risk_tools'].length;
            categoryItem['isSelectedAll'] = 0;

            categoryItem['risk_tools'].forEach((toolItem) => {
                if (toolItem['isSelected']) {
                    categoryItem['countToolsSelected'] += 1;
                }
            });

            const isSelectedAll = categoryItem['risk_tools'].every((toolItem) => {
                return toolItem['isSelected'];
            });

            categoryItem['isSelectedAll'] = +isSelectedAll;
        });

        const newState = {
            toolsCategoriesCurrent: toolsCategoriesAll,
        };

        this.setState(newState);
    };

    onSwitchChange = (fieldName, categoryIndex, data) => ({ value }) => {
        if (fieldName === 'select_all_tools') {
            // 'select_all' actions
            const tmpToolsCategoriesCurrent = this.state.toolsCategoriesCurrent;
            tmpToolsCategoriesCurrent[categoryIndex]['isSelectedAll'] = value;

            if (value) {
                tmpToolsCategoriesCurrent[categoryIndex]['countToolsSelected'] = tmpToolsCategoriesCurrent[categoryIndex]['countToolsAll'];
            } else {
                tmpToolsCategoriesCurrent[categoryIndex]['countToolsSelected'] = 0;
            }

            tmpToolsCategoriesCurrent[categoryIndex]['risk_tools'] = tmpToolsCategoriesCurrent[categoryIndex]['risk_tools'].map((item) => {
                item['isSelected'] = value;

                return item;
            });

            const newState = { toolsCategoriesCurrent: tmpToolsCategoriesCurrent };

            this.setState(newState, () => {
                this.transferDataToParent();
            });
        }
    };

    onListTitleClick = (categoryData, index) => () => {
        let tmpToolsCategoriesCurrent = this.state.toolsCategoriesCurrent;

        tmpToolsCategoriesCurrent = tmpToolsCategoriesCurrent.map((item, i) => {
            const tmpObj = item;

            if (i !== index) {
                tmpObj['isVisible'] = false;
            } else {
                tmpObj['isVisible'] = !tmpObj['isVisible'];
            }

            return tmpObj;
        });

        this.setState({ toolsCategoriesCurrent: tmpToolsCategoriesCurrent });
    };

    onToolClick = (toolData, categoryIndex, toolIndex) => () => {
        const tmpToolsCategoriesCurrent = this.state.toolsCategoriesCurrent;
        const newValue = !tmpToolsCategoriesCurrent[categoryIndex]['risk_tools'][toolIndex]['isSelected'];

        tmpToolsCategoriesCurrent[categoryIndex]['risk_tools'][toolIndex]['isSelected'] = newValue;

        if (newValue) {
            tmpToolsCategoriesCurrent[categoryIndex]['countToolsSelected'] += 1;
        } else {
            tmpToolsCategoriesCurrent[categoryIndex]['countToolsSelected'] -= 1;
        }

        const isSelectedAll = tmpToolsCategoriesCurrent[categoryIndex]['risk_tools'].every((item) => {
            return item['isSelected'];
        });

        tmpToolsCategoriesCurrent[categoryIndex]['isSelectedAll'] = +isSelectedAll;

        this.setState({ toolsCategoriesCurrent: tmpToolsCategoriesCurrent }, () => {
            this.transferDataToParent();
        });
    };

    renderListCategories = (categoriesList) => {
        return categoriesList.map((item, categoryIndex) => {
            return this.renderListCategory(item, categoryIndex);
        });
    };

    renderListCategory = (categoryData, categoryIndex) => {
        const { translate, } = this.props;
        const className = categoryData['isVisible'] ? 'list-item active' : 'list-item';
        const {
            isSelectedAll,
            countToolsSelected,
            countToolsAll,
            risk_tool_categories = {},
            risk_tools = [],
        } = categoryData;
        const isOneSymbolSelected = +(countToolsSelected >= 1);

        return (
            <div className={className} key={`list-item-${categoryIndex}`}>
                <div className='list-title list-title--category'
                     onClick={this.onListTitleClick(categoryData, categoryIndex)}
                >
                    <MagicSwitch
                        className={`magic-switch magic-switch--inline`}
                        index={isOneSymbolSelected}
                        key={`magic-switch-title-${categoryIndex}`}
                        reverse={false}
                        values={[0, 1]}
                        text={risk_tool_categories.name}
                        onChange={this.onSwitchChange('not_to_select_all_tools', categoryIndex, categoryData)}
                        updateStateFromProps={true}
                    />

                    <div aria-hidden="true"
                         className="css-1ep9fjw select__indicator select__dropdown-indicator">
                        <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true"
                             focusable="false" className="css-19bqh2r">
                            <path
                                d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                        </svg>
                    </div>
                </div>

                <div className='list-content'>
                    <div className='list-title'>
                        <h4 className='list-title__name'>{translate('list_accounts_risk_available_tools_title')}</h4>
                    </div>

                    <div className='list list--tools'>
                        <MagicSwitch
                            className={`magic-switch magic-switch--inline`}
                            index={isSelectedAll}
                            key={`magic-switch-select-all-symbols-${categoryIndex}`}
                            reverse={false}
                            values={[0, 1]}
                            text={`${translate('list_accounts_risk_select_all_tools')} ${countToolsSelected}/${countToolsAll})`}
                            onChange={this.onSwitchChange('select_all_tools', categoryIndex, categoryData)}
                            updateStateFromProps={true}
                        />

                        <ul className='list-items'>
                            {this.renderListTools(risk_tools, categoryIndex)}
                        </ul>

                    </div>
                </div>
            </div>
        );
    };

    renderListTools = (toolsList, categoryIndex) => {
        return toolsList.map((item, toolIndex) => {
            return this.renderListTool(item, categoryIndex, toolIndex);
        });
    };

    renderListTool = (toolData, categoryIndex, toolIndex) => {
        const className = toolData['isSelected'] ? 'selected' : '';

        return (
            <li className={className}
                key={`symbol-list-item-${categoryIndex}-${toolIndex}`}
                onClick={this.onToolClick(toolData, categoryIndex, toolIndex)}
            >
                {toolData['name']}
            </li>
        );
    };

    render () {
        const { translate } = this.props;

        return (
            <Loader
                loaded={this.state.loaded}
                loading={(<Preloader className='loaderUniversal--abs-pos' scale={1}/>)}
            >
                <div className='cont cont--trading-tools'>
                    <div className='list list--trading-tools table scroll'>

                        {this.renderListCategories(this.state.toolsCategoriesCurrent)}

                    </div>
                </div>
            </Loader>
        );
    }
}

export default (BlockTradingToolsNew);

BlockTradingToolsNew.propTypes = {
    updateTradingTools: PropTypes.func.isRequired,
};
