import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from 'react-router-dom';
import { withLocalize, Translate } from 'react-localize-redux';

import {
    getClientInteraction
} from "../../../services/AccountService";
import InteratorList from './InteratorList';
import CheckUndefinedPermission from "../../../../../components/Common/CheckUndefinedPermission";

class Interaction extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '1',
        };
    }

    componentDidMount() {
        this.props.getClientInteraction(this.props.clientId);
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    render() {
        return (
            <Translate>
                {({ translate }) => {
                    return (
                        <div className='interaction'>
                            <CheckUndefinedPermission>
                                {this.props.accountInteraction ? <InteratorList items={this.props.accountInteraction} /> : ''}
                            </CheckUndefinedPermission>
                        </div>
                    );
                }}
            </Translate>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        accountInteraction: store.clients.accountInteraction,
    };
};

export default withLocalize(withRouter(connect(
    mapStateToProps,
    (dispatch) =>
        bindActionCreators({
            getClientInteraction
        }, dispatch)
)(Interaction)));
