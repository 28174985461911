import React from "react";
import { Scrollbars } from "react-custom-scrollbars";
import defaultAvatar from "../../../../../images/profile_default.jpg";

class ManagerDelegation extends React.Component {

    state = {
        filter: '',
    };

    save = async (state) => new Promise(next => this.setState(state, next));

    avatar = (id) => {
        const data = this.props?.usersAvatars?.[id];
        return data ? `data:image/jpeg;base64,${ data }` : defaultAvatar;
    };    

    renderUser = () => {
        // const { translate, } = this.props;
        const avatar = this.avatar(this.props?.userId); // this.state.images.imageId
        const user = this.props?.user;
        const name = user?.name;
        const surname = user?.surname;
        const count = user?.count;
        const total = user?.total;
        return (
            <div className="modal_delegation_description">
                <img src={ avatar } alt="img"/>
                <div className="description-info">
                    <span>{ name } { surname }</span>
                    {/* <p>{translate(`delegation_filter_throughput`)} --%</p> */}
                    <p>{ count }/{ total }</p>
                </div>
            </div>
        );
    };

    renderUserTo = (userId, user, action) => {
        if (userId) {
            const avatar = this.avatar(userId);
            const name = user?.name;
            const surname = user?.surname;
            const count = user?.count_tasks_to_date;
            const total = user?.throughput;
            let departments = user?.['core-departments-name'];
            try {
                departments = JSON.parse(departments);
                departments = departments?.join(', ');
            } catch (error) {
                departments = '';
            }
            return (
                <div className="participants" key={ userId }>
                    <div className="responsible-flex">
                        <div className="responsible-avatar"><img alt="user" src={ avatar } /></div>
                        <div className="responsible-text">
                            <span>{ name } { surname }</span>
                            <p>{ count }/{ total }</p>
                            { departments ?? '' }
                        </div>
                        { action }
                    </div>
                </div>
            );
        }
        return null;
    };

    renderUserToAdd = (data) => {
        const { id: userId, ...user } = data;
        if (userId) {
            const add = async () => {
                this.props?.onUserTo?.(userId, user);
            };
            const action = (<div className="responsible-action" onClick={ add }><i className = 'la la-plus add' /></div>);
            return this.renderUserTo(userId, user, action);
        }
        return null;
    };

    renderUserToRemove = () => {
        const userId = this.props?.userToId;
        const user = this.props?.userTo;
        if (userId) {
            const remove = () => {
                this.props?.onUserTo?.();
            };
            const action = (<div className="responsible-action" onClick={ remove }><i className = 'la la-close close' /></div>);
            return this.renderUserTo(userId, user, action);
        } else {
            const { translate, } = this.props;
            const text = translate('delegation_modal_assign_namager');
            const content = (<div className = 'responsible-text'><span>{ text }</span> </div>);
            return (<div className="participants"><div className="responsible-flex">{ content }</div></div>);
        }
    };

    renderSearch = () => {
        const { translate, } = this.props;
        const change = async (event) => await this.save({ filter: event.target.value });
        return (
            <input
                onChange={ change }
                placeholder={ translate(`task_search`) }
                type='text'
                // value={ this.state?.filter }
            />
        );
    };

    render = () => {
        const { translate, } = this.props;
        let items = this.props?.usersTo;
        if (this.state?.filter) {
            const filter = this.state?.filter;       
            items = items?.filter?.(({ name, surname }) => `${ name } ${ surname }`.indexOf(filter) >= 0);
        }
        items = items?.map?.(userTo => this.renderUserToAdd?.(userTo));
        return (
            <React.Fragment>
                <div className="modal_delegation_search">
                    { this.renderUser() }
                    { this.renderUserToRemove() }
                </div>
                <div className = 'flex-wrapper'>
                    <div className = 'add-participants'>
                        <div className = 'responsible-title'>{ this.renderSearch() }</div>
                        <div><Scrollbars style = { { height: 300 } }>{ items }</Scrollbars></div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default ManagerDelegation;
