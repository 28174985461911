import React from "react";
import { Link } from "react-router-dom";
import BreadcrumbText from './BreadcrumbText';
import './styles.scss';

export default function BreadcrumbLink ( props ) {
    const { link, class4Link, } = props;
    return (
        <Link
            className={ class4Link || `breadcrumb__link` }
            to={ link }
        >
            <BreadcrumbText { ...props } />
        </Link>
    );
}
