import TimaLinkConfig from "./TimaLinkConfig";

export const breadcrumbs4Strategy = ( translate, strategyId ) => {
    return TimaLinkConfig(
        translate,
        [
            'strategies',
            'strategy',
        ],
        {
            strategyId,
        }
    );
};

export const breadcrumbs4Strategies = ( translate ) => {
    return TimaLinkConfig(
        translate,
        [
            'strategies',
        ],
        { }
    );
};

export const breadcrumbs4Investment = ( translate, investmentId ) => {
    return TimaLinkConfig(
        translate,
        [
            'investments',
            'investment',
        ],
        {
            investmentId,
        }
    );
};

export const breadcrumbs4Investments = ( translate, optionsWithId ) => {
    const { agentId, conditionId, strategyId, } = optionsWithId || { };
    if ( agentId ) {
        return TimaLinkConfig(
            translate,
            [
                'investments',
                'investmentsByAgent',
            ],
            {
                agentId,
            }
        );
    } else if ( conditionId ) {
        return TimaLinkConfig(
            translate,
            [
                'investments',
                'investmentsByCondition',
            ],
            {
                conditionId,
            }
        );
    } else if ( strategyId ) {
        return TimaLinkConfig(
            translate,
            [
                'investments',
                'investmentsByStrategy',
            ],
            {
                strategyId,
            }
        );
    } else {
        return TimaLinkConfig(
            translate,
            [
                'investments',
            ],
            { }
        );
    }
};

export const breadcrumbs4Condition = ( translate, conditionId ) => {
    return TimaLinkConfig(
        translate,
        [
            'conditions',
            'condition',
        ],
        {
            conditionId,
        }
    );
};

export const breadcrumbs4Conditions = ( translate, optionsWithId ) => {
    const { agentId, strategyId, } = optionsWithId || {};
    if ( agentId ) {
        return TimaLinkConfig(
            translate,
            [
                'conditions',
                'conditionsByAgent',
            ],
            {
                agentId,
            }
        );
    } else if ( strategyId ) {
        return TimaLinkConfig(
            translate,
            [
                'conditions',
                'conditionsByStrategy',
            ],
            {
                strategyId,
            }
        );
    } else {
        return TimaLinkConfig(
            translate,
            [
                'conditions',
            ],
            { }
        );
    }
};

export const breadcrumbs4Agent = ( translate, agentId ) => {
    return TimaLinkConfig(
        translate,
        [
            'agents',
            'agent',
        ],
        {
            agentId,
        }
    );
};

export const breadcrumbs4Agents = ( translate, optionsWithId ) => {
    const { strategyId, } = optionsWithId || { };
    if ( strategyId ) {
        return TimaLinkConfig(
            translate,
            [
                'agents',
                'agentsByStrategy',
            ],
            {
                strategyId,
            }
        );
    } else {
        return TimaLinkConfig(
            translate,
            [
                'agents',
            ],
            { }
        );
    }
};
