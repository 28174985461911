import { Observable } from '../components/Magic/components/MagicFilter/services/Observable';

export class DataService {
    constructor (events=[]) {
        const observer = new Observable({ events, });
        Object.defineProperties(this, {
            events: { enumerable: true, get: () => observer.events, },
            emit: {
                get: () => async (event, ...data) => observer.emit(event, ...data),
            },
            subscribe: {
                get: () => (event, listener, owner) => observer.on(event, listener, owner),
            },
            unsubscribe: {
                get: () => (event, listener, owner) => observer.off(event, listener, owner),
            },
        });
    }
}
