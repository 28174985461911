import { isNull } from "lodash";

const CheckIsNull = ({ string }) => {
    if (isNull(string)) {
        return '';
    }

    return string;
};

export default CheckIsNull;
