import { DataService } from "./DataService";
import { hash as hashData } from "./CachingService";
import { get, put } from "../components/Magic/helpers/MagicRequest";
import {
    GET_TIMA_AGENT_INVESTMENTS,GET_TIMA_AGENTS,
    GET_TIMA_AGENTS_LIST,
    GET_TIMA_CONDITION_INVESTMENTS,
    GET_TIMA_CONDITIONS,
    GET_TIMA_CONDITIONS_LIST,
    GET_TIMA_INVESTMENT_HISTORY,
    GET_TIMA_INVESTMENTS,
    GET_TIMA_STRATEGIES,
    GET_TIMA_STRATEGY_AGENTS,
    GET_TIMA_STRATEGY_CONDITIONS,
    GET_TIMA_STRATEGY_INVESTMENTS
} from "../apiRoutes";

class TimaDataService extends DataService {
    constructor () {
        super([
            'strategies',
            'strategy',
            'updateStrategy',
            'strategyInvestments',
            'strategyConditions',
            'strategyAgents',
            'strategyConditionsAll',
            'investments',
            'investment',
            'updateInvestment',
            'investmentHistory',
            'condition',
            'conditions',
            'conditionsList',
            'updateCondition',
            'conditionInvestments',
            'agents',
            'agent',
            'agentsList',
            'updateAgent',
            'agentInvestments',
        ]);
        Object.freeze(this);
    }

    async strategies ({ filterId, take, skip }) {
        const url = GET_TIMA_STRATEGIES;
        const { data, meta } = await get(url, { filterId, take, skip });
        const hash = await hashData(data);
        const strategies = { data, meta, hash, options: { filterId, take, skip }};
        await this.emit('strategies', strategies);

        return strategies;
    }

    async strategy (strategyId) {
        const url = `${ GET_TIMA_STRATEGIES }/${ strategyId }`;
        const { data } = await get(url);
        const hash = await hashData(data);
        const strategy = { data, hash, strategyId };
        await this.emit('strategy', strategy);

        return strategy;
    }

    async updateStrategy (strategyId, obj) {
        const url = `${ GET_TIMA_STRATEGIES }/${ strategyId }`;
        const data = await put(url, obj);
        const updateStrategy = { response: data, strategyId, options: obj, };
        await this.emit('updateStrategy', updateStrategy);

        return updateStrategy;
    }

    async strategyInvestments (strategyId, { filterId, take, skip }) {
        const url = `${ GET_TIMA_STRATEGY_INVESTMENTS }/${ strategyId }`;
        const { data, meta } = await get(url, { filterId, take, skip });
        const hash = await hashData(data);
        const strategyInvestments = { data, meta, hash, strategyId, options: { filterId, take, skip }};
        await this.emit('strategyInvestments', strategyInvestments);

        return strategyInvestments;
    }

    async strategyConditions (strategyId, { filterId, take, skip }) {
        const url = `${ GET_TIMA_STRATEGY_CONDITIONS }/${ strategyId }`;
        const { data, meta } = await get(url, { filterId, take, skip });
        const hash = await hashData(data);
        const strategyConditions = { data, meta, hash, strategyId, options: { filterId, take, skip } };
        await this.emit('strategyConditions', strategyConditions);

        return strategyConditions;
    }

    async strategyAgents (strategyId, { filterId, take, skip }) {
        const url = `${ GET_TIMA_STRATEGY_AGENTS }/${ strategyId }`;
        const { data, meta } = await get(url, { filterId, take, skip });
        const hash = await hashData(data);
        const strategyAgents = { data, meta, hash, strategyId, options: { filterId, take, skip }};
        await this.emit('strategyAgents', strategyAgents);

        return strategyAgents;
    }

    async strategyConditionsAll (managerId) {
        const url = `${ GET_TIMA_STRATEGY_CONDITIONS }/${ managerId }?take=all`;
        const { data } = await get(url);
        const hash = await hashData(data);
        const strategyConditionsAll = { response: data, hash, managerId };
        await this.emit('strategyConditionsAll', strategyConditionsAll);

        return strategyConditionsAll;
    }

    async investments ({ filterId, take, skip }) {
        const url = `${ GET_TIMA_INVESTMENTS }`;
        const { data, meta } = await get(url, { filterId, take, skip });
        const hash = await hashData(data);
        const investments = { data, meta, hash, options: { filterId, take, skip }};
        await this.emit('investments', investments);

        return investments;
    }

    async investment (investmentId) {
        const url = `${ GET_TIMA_INVESTMENTS }/${ investmentId }`;
        const { data } = await get(url);
        const hash = await hashData(data);
        const investment = { data, hash, investmentId, };
        await this.emit('investment', investment);

        return investment;
    }

    async updateInvestment (investmentId, obj) {
        const url = `${ GET_TIMA_INVESTMENTS }/${ investmentId }`;
        const data = await put(url, obj);
        const updateInvestment = { response: data, investmentId, options: obj, };
        await this.emit('updateInvestment', updateInvestment);

        return updateInvestment;
    }

    async investmentHistory (investmentId, { filterId, take, skip }) {
        const url = `${ GET_TIMA_INVESTMENT_HISTORY }/${ investmentId }`;
        const { data, meta } = await get(url, { filterId, take, skip });
        const hash = await hashData(data);
        const investmentHistory = { data, meta, hash, investmentId, options: { filterId, take, skip }};
        await this.emit('investmentHistory', investmentHistory);

        return investmentHistory;
    }

    async conditions ({ filterId, take, skip }) {
        const url = `${ GET_TIMA_CONDITIONS }`;
        const { data, meta } = await get(url, { filterId, take, skip });
        const hash = await hashData(data);
        const conditions = { data, meta, hash, options: { filterId, take, skip } };
        await this.emit('conditions', conditions);

        return conditions;
    }

    async condition (conditionId) {
        const url = `${ GET_TIMA_CONDITIONS }/${ conditionId }`;
        const { data } = await get(url);
        const hash = await hashData(data);
        const condition = { data, hash, conditionId, };
        await this.emit('condition', condition);

        return condition;
    }

    async updateCondition (conditionId, obj) {
        const url = `${ GET_TIMA_CONDITIONS }/${ conditionId }`;
        const data = await put(url, obj);
        const updateCondition = { response: data, conditionId, options: obj, };
        await this.emit('updateCondition', updateCondition);

        return updateCondition;
    }

    async conditionsList () {
        const url = `${ GET_TIMA_CONDITIONS_LIST }`;
        const data = await get(url);
        const hash = await hashData(data);
        const conditionsList = { data, hash, };
        await this.emit('conditionsList', conditionsList);

        return conditionsList;
    }

    async conditionInvestments (conditionId, { filterId, take, skip }) {
        const url = `${ GET_TIMA_CONDITION_INVESTMENTS }/${ conditionId }`;
        const { data, meta } = await get(url, { filterId, take, skip });
        const hash = await hashData(data);
        const conditionInvestments = { data, meta, hash, conditionId, options: { filterId, take, skip }};
        await this.emit('conditionInvestments', conditionInvestments);

        return conditionInvestments;
    }

    async agents ({ filterId, take, skip }) {
        const url = `${ GET_TIMA_AGENTS }`;
        const { data, meta } = await get(url, { filterId, take, skip });
        const hash = await hashData(data);
        const agents = { data, meta, hash, options: { filterId, take, skip }};
        await this.emit('agents', agents);

        return agents;
    }

    async agent (agentId) {
        const url = `${ GET_TIMA_AGENTS }/${ agentId }`;
        const { data } = await get(url);
        const hash = await hashData(data);
        const agent = { data, hash, agentId };
        await this.emit('agent', agent);

        return agent;
    }

    async updateAgent (agentId, obj) {
        const url = `${ GET_TIMA_AGENTS }/${ agentId }`;
        const data = await put(url, obj);
        const updateAgent = { response: data, agentId, options: obj, };
        await this.emit('updateAgent', updateAgent);

        return updateAgent;
    }

    async agentsList () {
        const url = `${ GET_TIMA_AGENTS_LIST }`;
        const data = await get(url);
        const hash = await hashData(data);
        const agentsList = { data, hash };
        await this.emit('agentsList', agentsList);

        return agentsList;
    }

    async agentInvestments (agentId, { filterId, take, skip }) {
        const url = `${ GET_TIMA_AGENT_INVESTMENTS }/${ agentId }`;
        const { data, meta } = await get(url, { filterId, take, skip });
        const hash = await hashData(data);
        const agentInvestments = { data, meta, hash, agentId, options: { filterId, take, skip }};
        await this.emit('agentInvestments', agentInvestments);

        return agentInvestments;
    }
}

export const timaService = new TimaDataService();
