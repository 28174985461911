import React, { Component } from 'react';

import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker';
import Select from 'react-select';

import inputFieldIsValid from './helpers';
import {findOptionByValue} from "../../../utils/helpers";

import { createPartnersAccrual, getPartnersList } from '../../../../../services/PartnersRequestService';
import Loader from '../../../../tima/components/Loader';
import Preloader from '../../../../../components/LoadingHOC/Preloader';

const CURRENT_DATE = new Date();
const REG_EXP = {
  general: /^[а-яА-ЯёЁa-zA-ZІіЇїЄєҐґ0-9*"'«»;:,.`№\s\-]{1,}$/,
  floatNumber: /^[0-9]*[.]?[0-9]{1,2}$/,
};

class CreatePartnersAccrual extends Component {
  constructor (props) {
    super(props);

    this.state = {
      loaded: false,
      loadedInnerRequest: true,
      partnersList: [],
      formData: {
        amount: '',
        created_at: CURRENT_DATE,
        account_id: null,
        type: null,
        isValid: {
          amount: true,
          created_at: true,
          account_id: true,
        },
      },
    };
  }

  get partnerIsPreSelected(){
    return this.props.accountId;
  }

  get typeOptions () {
    return Object.entries(this.props.enums.partners.PartnerAccrualTypeEnum).map(entry => {
      const [value, label] = entry;
      return {value, label}
    })
  }
  save = async (state) => new Promise(next => this.setState(state, next));

  componentDidMount = async () => {
    await this.onDataLoad();
  };

  onDataLoad = async () => {
    await this.save({ loaded: false, });

    try {
      const partnersListData = await this._getPartnersList();

      if(this.partnerIsPreSelected){
        await this._getPreselectedAccountId();
      }
    } catch (error) {
      console.log(error)
    }

    await this.save({ loaded: true, });
  };

  _getPreselectedAccountId = async () => {
    const account_id = findOptionByValue(this.state.partnersList, this.props.accountId);
    await this.save({formData: {...this.state.formData, account_id}});
  }
  _getPartnersList = async () => {
    const convertData = (data) => {
      return data?.map((item) => {
        const id = item?.core?.account?.id;
        const label = `${ id } - ${ item?.core?.account?.name } ${ item?.core?.account?.surname }`;

        return {
          value: id,
          label
        };
      }) ?? [];
    };

    try {
      const response = await getPartnersList();
      const { data } = response;

      await this.save({ partnersList: convertData(data), });

      return data;
    } catch (error) {
      await this.save({ partnersList: [], });
      error?.showErrorNotification?.();

      return false;
    }
  };

  onConfirmClick = async (event) => {
    const twoDigits = (dateField) => {
      if (dateField < 10) {
        return `0${ dateField }`;
      } else {
        return dateField;
      }
    };
    const dateConverter = (date) => {
      let dateStr = -1;

      if (date instanceof Date) {
        dateStr = `${ date.getFullYear() }-${ twoDigits(date.getMonth() + 1) }-${ twoDigits(date.getDate()) } ${ twoDigits(date.getHours()) }:${ twoDigits(date.getMinutes()) }:${ twoDigits(date.getSeconds()) }`;
      }

      return dateStr;
    };

    const validatorStatus = await this.validateFormDataFields();

    if (validatorStatus) {
      const type = this.state.formData.type?.value;
      const typeNotEmpty = !!type;

      const formData = {
        account_id: this.state.formData.account_id ? this.state.formData.account_id.value : null,
        amount: +this.state.formData.amount,
        created_at: dateConverter(this.state.formData.created_at),
        ...typeNotEmpty && ({type}),
      };

      (async () => {
        await this.save({ loadedInnerRequest: false, });

        try {
          const response = await createPartnersAccrual(formData);

          await this.props.refreshChildData();
          await this.props.popUpClose();
        } catch (error) {
          console.log(error)
          const errors = error.response.data.errors;
          const newState = this.state;

          for (let key in errors) {
            const fieldOptionsArr = key.split('.');

            if (fieldOptionsArr.length === 1) {
              const [fieldName] = fieldOptionsArr;

              newState.formData['isValid'][fieldName] = false;
            } else if (fieldOptionsArr.length === 3) {
              const [listName, rowIndex, fieldName] = fieldOptionsArr;

              newState[listName][rowIndex]['isValid'][fieldName] = false;
            }
          }

          await this.save(newState);
          error?.showErrorNotification?.();
        }

        await this.save({ loadedInnerRequest: true, });
      })();
    }
  };

  getRegExpByFieldName = (fieldName) => {
    let regExp = '';
    let maxLength = 50;

    switch (fieldName) {
      case 'amount': {
        regExp = REG_EXP.floatNumber;
        maxLength = 12;
        break;
      }
      default: {
        regExp = REG_EXP.general;
        break;
      }
    }

    return {
      regExp,
      maxLength
    };
  };

  deleteNoValidSymbolsInField = (fieldObj) => {
    const {
      name,
      value
    } = fieldObj;
    const {
      regExp,
      maxLength
    } = this.getRegExpByFieldName(name);

    let resultStr = value;
    let regExpToMatch = String(regExp);

    regExpToMatch = regExpToMatch.slice(2, -2);
    regExpToMatch = new RegExp(regExpToMatch, 'g');

    if (value.length > 0) {
      const flagTest = !regExp.test(value);
      const flagMax = value.length > maxLength;

      if (flagTest || flagMax) {
        if (flagTest) {
          try {
            // console.log('value.match(regExpToMatch): ', value.match(regExpToMatch));
            switch (regExp) {
              case REG_EXP.floatNumber: {
                const dotsCnt = value.match(/[.]/g) ? value.match(/[.]/g).length : 0;

                if (!dotsCnt || dotsCnt > 1 || dotsCnt === 1 && value[value.length - 1] !== '.') {
                  resultStr = value.match(regExpToMatch)[0];
                }
                break;
              }
              default: {
                resultStr = value.match(regExpToMatch).join('');
                break;
              }
            }
          } catch (error) {
            resultStr = '';
          }
        }
        if (flagMax) {
          resultStr = resultStr.slice(0, maxLength);
        }

      }
    }

    return resultStr;
  };

  isValidField = (fieldObj) => {
    const {
      name,
      value
    } = fieldObj;
    const selectFieldsArr = ['account_id', 'created_at', 'type'];

    let isValid = false;

    if(name === 'type') return true //exception

    if (selectFieldsArr.indexOf(name) !== -1) {
      isValid = !!(value && value !== '');
    } else {
      try {
        const { regExp } = this.getRegExpByFieldName(name);

        isValid = regExp.test(value);
      } catch (error) {
        console.log('isValidField(): No such field: ', name);
      }
    }

    return isValid;
  };

  validateFormDataFields = async () => {
    const listName = 'formData';
    const tmpList = this.state[listName];
    const { isValid: isValidObj } = tmpList;
    // const fieldsForCheck = ['name', 'accruals_frequency',];

    let isValidStatus = true;

    for (let fieldName in isValidObj) {
      const validatorStatus = this.isValidField({
        name: fieldName,
        value: tmpList[fieldName]
      });

      isValidObj[fieldName] = validatorStatus;
      
      if (!validatorStatus) {
        isValidStatus = false;
      }
    }

    if (isValidStatus) {
      // turn off all errors
      for (let fieldName in isValidObj) {
        isValidObj[fieldName] = true;
      }
    }

    const newState = {
      [listName]: tmpList,
    };
    await this.save(newState);

    return isValidStatus;
  };

  onDateChange = (fieldName) => async (date) => {

    const newState = {
      formData: {
        ...this.state.formData,
        [fieldName]: date,
        isValid: {
          ...this.state.formData.isValid,
          [fieldName]: true,
        },
      },

    };

    await this.save(newState);
  };

  onInputChange = (fieldName) => async (event) => {
    const { value } = event.target;

    if(!inputFieldIsValid(fieldName, value)) return

    const currentValue = this.deleteNoValidSymbolsInField({
      name: fieldName,
      value
    });
    const newState = {
      formData: {
        ...this.state.formData,
        [fieldName]: currentValue,
        isValid: {
          ...this.state.formData.isValid,
          [fieldName]: true,
        },
      }
    };

    await this.save(newState);
  };

  onSelectChange = (fieldName) => async (option) => {
    const newState = {
      formData: {
        ...this.state.formData,
        [fieldName]: option,
        isValid: {
          ...this.state.formData.isValid,
          [fieldName]: true,
        },
      }
    };

    await this.save(newState);
  };

  setErrorClass = (fieldName) => { 
    return this.state.formData.isValid[fieldName] ? '' : 'error';
  }

  render () {
    const { translate, } = this.props;
    const {
      loaded,
      loadedInnerRequest
    } = this.state;

    const partnerIsPreSelected = this.partnerIsPreSelected

    return (
      <Loader
        loaded={ loaded }
        loading={ (<Preloader className="loaderUniversal--abs-pos" scale={ 1 }/>) }
      >
        <div className="glalex-styles pop-up pop-up--create-partners-accrual pop-up--active">

          { !loadedInnerRequest ?
            (
              <Preloader className="loaderUniversal--fixed-pos" scale={ 1 }/>
            ) : false
          }

          <div className="pop-up-wrapper">
            <div className="pop-up-header">
              <h3 className="pop-up__name">
                { translate('partners_create_accrual_title') }
              </h3>
              <i className="gl-icon close-btn close-btn--big" onClick={ this.props.popUpClose }/>
            </div>

            <div className="pop-up-content">
              <div className="input">
                <span className="input__text">IB Partner</span>
                <div className="input-wrapper">
                  <Select
                    className={ `input-select ${ this.setErrorClass('account_id') }` }
                    classNamePrefix="select"
                    isDisabled={ partnerIsPreSelected }
                    isSearchable={ true }
                    onChange={ this.onSelectChange('account_id') }
                    options={ this.state.partnersList }
                    placeholder={ this.props.translate('partners_create_accrual_partner_placeholder') }
                    //styles = { customStyles }
                    value={ this.state.formData.account_id }
                  />
                </div>
              </div>

              <div className="input">
                <span className="input__text">{ translate('partners_create_accrual_amount_label') }</span>
                <div className="input-wrapper">
                  <input className={ `input__field ${ this.setErrorClass('amount') }` }
                         autoComplete="off"
                         type="text"
                         placeholder={ translate('partners_create_accrual_amount_placeholder') }
                         value={ this.state.formData.amount }
                         onChange={ this.onInputChange('amount') }
                  />
                </div>
              </div>

              <div className="input">
                <span className="input__text">{translate('new_partners_condition_type_label')}</span>
                <div className="input-wrapper">
                  <Select
                    className={ `input-select` }
                    classNamePrefix="select"
                    isDisabled={ false }
                    onChange={ this.onSelectChange('type') }
                    options={ this.typeOptions }
                    placeholder={ this.props.translate('partners_accruals_select_condition_placeholder') }
                    //styles = { customStyles }
                    value={ this.state.formData.type }
                  />
                </div>
              </div>


              <div className="input">
                <span className="input__text">{ translate('partners_create_accrual_date_label') }</span>
                <div className="input-wrapper input-block dataPickerStyleCustom">
                  <DatePicker
                    value={ this.state.formData.created_at }
                    minDate={ CURRENT_DATE }
                    onChange={ this.onDateChange('created_at') }
                    format="yyyy.MM.dd"
                    locale={ this.props.activeLanguage.code }
                    calendarClassName={ 'react-datepicker__month-container' }
                    className={ `react-datepicker input__field ${ this.setErrorClass('created_at') }` }
                    clearIcon={ null }
                    calendarIcon={ null }
                    disabled={ false }
                  />
                </div>
              </div>
            </div>

            <div className="btns-cont">
              <button className="gl-btn gl-btn--blue"
                      onClick={ this.onConfirmClick }
              >
                { translate('partners_create_btn') }
              </button>
              <button className="gl-btn gl-btn--blue-border"
                      onClick={ this.props.popUpClose }
              >
                { translate('partners_cancel_btn') }
              </button>
            </div>
          </div>
        </div>
      </Loader>
    );
  }
}

export default (CreatePartnersAccrual);

CreatePartnersAccrual.propTypes = {
  popUpClose: PropTypes.func.isRequired,
  refreshChildData: PropTypes.func.isRequired,
};
