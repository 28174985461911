import React, { Component, } from "react";
import { withLocalize, } from "react-localize-redux";
import { Link, } from "react-router-dom";
import PropTypes from "prop-types";

import { MagicTooltip, } from "../../../../../../tima/components/Magic/MagicTooltip";
import { Popup, } from "../../../../../components";
import { MagicTable, } from "../../../../../../../components/Magic";
import Loader from "../../../../../../tima/components/Loader";

import { getPartnersCurrency, } from "../../../../../../../services/PartnersRequestService";
class TotalRenumeration extends Component {
	static propTypes = {
		onClose: PropTypes.func.isRequired,
		// eslint-disable-next-line sort-keys
		enums: PropTypes.object,
		formData: PropTypes.object,
		onDataLoad: PropTypes.func,
	};

	constructor (props) {
		super(props);

		this.state = {
			serverCurrency: null,
			loaded: false,
		};
	}

	get currencyIsDollar () {
		return +this.state.serverCurrency === 253;
	}

	save = state => new Promise(next => this.setState(state, next));

	componentDidMount = async () => {
		try {
			const serverCurrency = await getPartnersCurrency();

			await this.save({
				serverCurrency,
			});
		} catch (error) {
			console.log(error);
		} finally {
			this.save({ loaded: true, });
		}
	};

	get config () {
		const { translate, } = this.props;
		const prefix = "partners_referral_";

		return [
			{
				key: "partnerName",
				path: [ "core", "partner", "name", ],
			},
			{
				key: "partnerSurname",
				path: [ "core", "partner", "surname", ],
			},
			{
				key: "partnerSetupId",
				path: [ "partners", "partners_setup", "id", ],
			},
			{
				key: "cpa_sum",
				path: [ "partners", "referral_register", "cpa_sum", ],
			},
			{
				key: "cpl_sum",
				path: [ "partners", "referral_register", "cpl_sum", ],
			},
			{
				key: "in_out_total_in",
				path: [ "partners", "referral_register", "in_out_total_in", ],
			},
			{
				key: "in_out_total_in_out",
				path: [ "partners", "referral_register", "in_out_total_out", ],
			},
			{
				key: "revshare_sum",
				path: [ "partners", "referral_register", "revshare_sum", ],
			},
			{
				key: "all_sum",
				path: [ "partners", "referral_register", "all_sum", ],
			},
			{
				key: "partnerId",
				path: [ "core", "partner", "id", ],
			},
			{
				key: "partnerFName",
				path: [ "core", "account", "id", ],
				render: this.renderPartnerName,
				title: `partners_list_partner_name`,
			},
			// {
			//     key: 'partnerLevel',
			//     path: ['core', 'user', 'id',],
			//     render: this.renderFromKey,
			//     title: `new_partners_condition_level`,
			// },
			{
				key: "cpaSum",
				path: [ "partners", "referral_register", ],
				render: (value, { item, items, }) =>
					this.renderFromKey("cpa_sum", { item, items, }),
				title: `${ prefix }cpa`,
			},
			// {
			//     key: 'cpaSumMultiLevel',
			//     path: ['partners', 'referral_register',],
			//     render: this.renderСpaSumMultilevel,
			//     title: `${prefix}cpa_multilevel`,
			// },
			{
				key: "cplSum",
				path: [ "partners", "referral_register", ],
				render: (value, { item, items, }) =>
					this.renderFromKey("cpl_sum", { item, items, }),
				title: `${ prefix }cpl`,
			},
			// {
			//     key: 'cplSumMultiLevel',
			//     path: ['partners', 'referral_register',],
			//     render: this.renderСplSumMultilevel,
			//     title: `${prefix}cpl_multilevel`,
			// },
			{
				key: "inSum",
				path: [ "partners", "referral_register", ],
				render: (value, { item, items, }) =>
					this.renderFromKey("in_out_total_in", { item, items, }),
				title: `${ prefix }in`,
			},
			{
				key: "outSum",
				path: [ "partners", "referral_register", ],
				render: (value, { item, items, }) =>
					this.renderFromKey("in_out_total_in_out", { item, items, }),
				title: `${ prefix }out`,
			},
			{
				key: "revshareSum",
				path: [ "partners", "referral_register", ],
				render: (value, { item, items, }) =>
					this.renderFromKey("revshare_sum", { item, items, }),
				title: `${ prefix }revshare`,
			},
			// {
			//     key: 'revshareMultiLevel',
			//     path: ['partners', 'referral_register',],
			//     render: this.renderСplSumMultilevel,
			//     title: `${prefix}revhare_multilevel`,
			// },
			{
				key: "totalRemunerations",
				path: [ "partners", "referral_register", ],
				render: (value, { item, items, }) =>
					this.renderFromKey("all_sum", { item, items, }),
				title: `${ prefix }total_remunerations`,
			},
		].map(({ title: t, xtitle: x, ...item }) => ({
			...item,
			...t && { title: translate(t), },
			...x && { title: x, },
		}));
	}

	get head () {
		return [
			"partnerFName",
			"partnerLevel",
			"cpaSum",
			"cpaSumMultiLevel",
			"cplSum",
			"inSum",
			"outSum",
			"revshareSum",
			"totalRemunerations",
		];
	}

	renderPartnerName = (value, { items, }) => {
		const name = items?.partnerName.valueOf ?? "";
		const surname = items?.partnerSurname.valueOf ?? "";
		const fullName = name || surname ? `${ name } ${ surname }` : "-";
		const partnerSetupId = items.partnerSetupId.valueOf ?? "";

		// if (item?.access('index') && items.partnerName?.access?.('index') && items?.partnerSurname?.access?.('index')) { !!! TODO: w8 for normal permissions
		return partnerSetupId ? (
			<Link to={ `/partners/requests/partners-list/${ partnerSetupId }` }>
				{/*//TODO: change link when partner card finished*/}
				<MagicTooltip
					classNameTooltip={ `tooltip tooltip--large-width` }
					content={ fullName }
					lengthCut={ 20 }
				/>
			</Link>
		) :
			"-"
		;
	};

	renderFromKey = (key, { items, }) => {
		//is alternative for renderInlineValue, which dosn't work for these one, because permissions dosn't allow the cell to render;
		const currencySymbol = this.currencyIsDollar ? "$" : "€";

		return `${ currencySymbol } ${ items[key].valueOf }` ?? `${ currencySymbol }0`;
	};

	renderTable () {
		const { popupData: data, } = this.props;
		const config = this.config;
		const head = this.head;

		const options = {
			config,
			data,
			head,
		};

		return (
			<div className="total-renumeration__table-wrapper">
				<div className="partners__table">
					<MagicTable { ...options } />
				</div>
			</div>
		);
	}

	render () {
		const { showPopUp, onClose, translate, } = this.props;

		return (
			<Loader loaded={ this.state.loaded }>
				<Popup
					isImage={ false }
					showPopUp={ showPopUp }
					size={ "lg total-renumeration" } //it's style classes, total-renumeration - needed to adjusted default popup classes
					title={ translate("partners_referral_total_remunerations_by_referral") }
					onClose={ onClose }
				>
					{this.renderTable()}
				</Popup>
			</Loader>
		);
	}
}

export default withLocalize(TotalRenumeration);