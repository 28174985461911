import React from 'react';
import Preloader from '../../../../../components/LoadingHOC/Preloader';
import { withLocalize } from 'react-localize-redux';
import { paymentService } from '../../../../../services/PaymentDataService'

class AmountPopUp extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            inOut: {
                data: [],
                hash: null
            },
        };
    }

    inOutChange = async (inOut) => {
        if (inOut.hash===this.state?.inOut?.hash) return `${this.constructor.name}.inOutChange: false`;
        await this.setState({ inOut, });
        return `${this.constructor.name}.inOutChange: true`;
    };

    async componentDidMount () {
        paymentService.subscribe('inOut', this.inOutChange, this);
        await this.getData();
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount () {
        paymentService.unsubscribe('inOut', this.inOutChange, this);
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (e) => {
        const isInside = e.path.includes(document.querySelector('.amount-popup'));

        if (!isInside) {
            this.props.close();
        }
    }

    getData = async () => {
        try {
            const inOut = await paymentService.inOut(this.props.paymentId);

            this.setState({ inOut });
        } catch (error) {
            console.error(error);
        }
    }

    renderTable = () => {
        const { inOut: { data, }, } = this.state;
        const { translate } = this.props;

        return (
            <table>
                <thead>
                    <tr>
                        <td>{translate('trading_system')}</td>
                        <td>In</td>
                        <td>Out</td>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, i) => {
                        return item?.name === 'total'
                            ? (
                                <tr key={i}>
                                    <td className = 'ttl'>{translate('trading_total')}</td>
                                    <td className = 'ttl-sum'>
                                        <p>{item?.deposit}</p>
                                    </td>
                                    <td className = 'ttl-sum red'>
                                        <p>{item?.withdrawal}</p>
                                    </td>
                                </tr>
                            ) : (
                                <tr key = { i } >
                                    <td>{item?.name}</td>
                                    <td>
                                        <div className="flex-td">
                                            <span>&bull;</span>
                                            <i>{item?.deposit}</i>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flex-td">
                                            <span className = 'red'>&bull;</span>
                                            <i>{item?.withdrawal}</i>
                                        </div>
                                    </td>
                                </tr>
                            );
                    })}
                </tbody>
            </table>
        );
    }

    render () {
        return (
            <div className = 'amount-popup'>
                <i className = 'close' onClick = { () => this.props.close() } />
                {this.state.inOut.data.length > 0 ? this.renderTable() : <Preloader scale = { 1 } />}
            </div>
        );
    }
}

export default withLocalize(AmountPopUp);
