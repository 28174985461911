import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link, } from 'react-router-dom';
import { withLocalize, } from "react-localize-redux";
import { MagicTable, } from '../../../components/Magic';
import { MagicTooltip, } from '../../tima/components/Magic/MagicTooltip';
import letter from "../../../images/letter.svg";
import call from "../../../images/call.svg";
import { enumService, } from "../../../services/EnumDataService";
import { callService, } from "../../../services/CallDataService";
import MessagePopupNew from './MessagePopup/index';

class ClientsMagicTable extends React.Component {
	static propTypes = {
		data: PropTypes.array,
	};

	static defaultProps = {
		data: [],
	};

	state = {
		isEmailModal: false,
	};

	enumsChange = async (enums) => {
		if (enums.hash===this.state.enum?.hash) {
			return `${ this.constructor.name }.enumsChange: false`;
		}

		await this.save({ enums, });

		return `${ this.constructor.name }.enumsChange: true`;
	};

	async componentDidMount () {
		enumService.subscribe('enums', this.enumsChange, this);
		await enumService.enums;
	}

	componentWillUnmount () {
		enumService.unsubscribe('enums', this.enumsChange, this);
	}

	// eslint-disable-next-line require-await
	save = async state => new Promise(next => this.setState(state, next));

	get params () {
		return this.props?.match?.params;
	}

	get query () {
		return new URLSearchParams(this.props?.location?.search);
	}

	get accountConfig () {
		const { translate, } = this.props;
		const enabled = _ => _.filter(_ => _.core?.account?.status === 1);
		const allChecked = (a, b) => !(a?.length < b?.length);

		return [
			{
				key: 'accountName',
				path: [ 'core', 'account', 'name', ],
			},
			{
				key: 'accountSurname',
				path: [ 'core', 'account', 'surname', ],
			},
			{
				key: 'isPhoneVerified',
				path: [ 'core', 'account', 'is_phone_verify', ],
			},
			{
				key: 'isEmailVerified',
				path: [ 'core', 'account', 'is_email_verify', ],
			},
			{
				key: 'userId',
				path: [ 'core', 'user', 'id', ],
			},
			{
				key: 'userName',
				path: [ 'core', 'user', 'name', ],
			},
			{
				key: 'userSurname',
				path: [ 'core', 'user', 'surname', ],
			},
			{
				key: 'userLanguageId',
				path: [ 'core', 'account', 'language_id', ],
			},
			{
				key: 'authorOflastDescriptionName',
				path: [ 'core', 'descriptions', 'last_user_name', ],
			},
			{
				key: 'descriptionsUserId',
				path: [ 'core', 'account_descriptions', 'user_id', ],
			},
			{
				key: 'authorOflastDescriptionSurname',
				path: [ 'core', 'descriptions', 'last_user_surname', ],
			},
			{
				key: 'accountId',
				orderId: 'o:aId',
				path: [ 'core', 'account', 'id', ],
				render: this.renderInlineValue,
				title: 'client_id',
			},
			{
				accessChecked: true,
				key: 'accountDescriptionUpdatedAt',
				orderId: 'o:aAtDesc',
				path: [ 'core', 'account_descriptions', 'created_at', ],
				render: this.renderInlineValue,
				title: 'client_description_last_updated_at',
			},
			{
				accessChecked: true,
				key: 'accountWarmTypeUpdatedAt',
				orderId: 'o:aAtWarmLast',
				path: [ 'core', 'account', 'warm_type_last_change', ],
				render: this.renderInlineValue,
				title: 'warm_type_last_change',
			},
			{
				key: 'accountCreated',
				orderId: 'o:aCreated',
				path: [ 'core', 'account', 'created_at', ],
				render: this.renderInlineValue,
				title: 'client_created_at',
			},
			{
				accessChecked: true,
				key: 'accountFullname',
				orderId: 'o:aSurname',
				render: this.renderAccountFullname,
				title: 'client_full_name',
			},
			{
				key: 'accountEmail',
				path: [ 'core', 'account', 'email', ],
				render: this.renderAccountEmail,
				title: 'client_email',
			},
			{
				key: 'accountPhone',
				path: [ 'core', 'account', 'phone', ],
				render: this.renderAccountPhone,
				title: 'client_phone',
			},
			{
				key: 'accountWarm',
				orderId: 'o:aWarm',
				path: [ 'core', 'account', 'warm_type', ],
				render: this.renderAccountWarm,
				title: 'client_warm_type',
			},
			{
				key: 'accountStatus',
				orderId: 'o:aStatus',
				path: [ 'core', 'account', 'status', ],
				render: this.renderAccountStatus,
				title: 'client_client_status',
			},
			{
				key: 'accountRegisterType',
				orderId: 'o:aRegisterType',
				path: [ 'core', 'account', 'register_type', ],
				render: this.renderAccountRegisterType,
				title: 'client_register_type',
			},
			{
				key: 'accountCategoryChanged',
				orderId: 'o:aAtCategory',
				path: [ 'core', 'account_categories', 'created_at', ],
				render: this.renderInlineValue,
				title: 'client_category_date_changed',
			},
			{
				key: 'accountCategory',
				orderId: 'o:aCategoryId',
				path: [ 'core', 'account_categories', 'category_id', ],
				render: this.renderAccountCategory,
				title: 'client_type',
			},
			{
				key: 'accountCountryName',
				orderId: 'o:accCountryId',
				path: [ 'core', 'country', 'name', ],
				render: this.renderInlineValue,
				title: 'client_country',
			},
			{
				accessChecked: true,
				key: 'userFullname',
				orderId: 'o:uSurname',
				render: this.renderUserFullname,
				title: 'client_assigned_to_responsible',
			},
			{
				accessChecked: true,
				key: 'authorOflastDescription',
				path: [ 'core', 'descriptions', 'last_user_id',],
				orderId: 'o:uAuthorOflastDescription',
				render: this.renderAuthorOflastDescription,
				title: 'author_of_last_description',
			},
			{
				accessChecked: true,
				key: 'lastDescription',
				path: [ 'core', 'account_descriptions', 'description', ],
				render: this.renderLastDescription,
				title: 'last_description',
			},
			{
				accessChecked: true,
				key: 'newInBriefcase',
				path: [ 'core', 'account', 'new_in_briefcase', ],
				render: this.renderBriefcaseStatus,
				title: 'client_new_in_briefcase',
			},
			{
				key: 'taxSertification',
				path: [ 'core', 'account', 'tax_residency_self_certification', ],
				render: this.renderTaxSertification,
				title: 'tax_residency',
			},
			{
				key: 'storeMemberQueue',
				path: [ 'calls', 'user_call_queue_members', ],
			}, {
				key: 'isMobile',
				path: [ 'core', 'account', 'is_mobile', ],
				render: this.renderAcountIsMobile,
				title: 'is_mobile',
			},
			{
				...this.props.selectUserPermission && {
					accessChecked: this.props.selectUserPermission,
				},
				key: 'checkbox',
				path: [ 'core', 'account', ],
				render: this.renderCheckbox,
				// title: 'client_dropdown_placeholder',
				xtitle: (
					<label>
						<div>
							{ translate('client_group_assign') }
						</div>
						<div>
							({ this.props.selectedUsers?.length } / { enabled(this.props.data)?.length })
						</div>
						<div>
							<input
								checked={ allChecked(this.props.selectedUsers, enabled(this.props.data)) }
								key={ allChecked(this.props.selectedUsers, enabled(this.props.data)) }
								type="checkbox"
								onChange={ (event) => {
									event.preventDefault();
									const accounts = enabled(this.props.data);
									const selected = this.props.selectedUsers;
									const ids = accounts.map(_ => _.core?.account?.id);

									this.props.selectUser(allChecked(selected, accounts), ids);
								} }
							/>
						</div>
					</label>
				),
			},
			{
				accessChecked: true,
				key: 'isProcessed',
				path: [ 'core', 'account', 'is_processed', ],
				render: this.renderIsProcessed,
				title: 'client_processed',
			}, {
				key: 'accountTag',
				// orderId: 'o:aCategoryId',
				path: [ 'core', 'dictionary_account_tag_t', 'description', ],
				render: this.renderAccountTag,
				title: 'account_tag',
			},
		].map(({
			orderId: o,
			title: t,
			xtitle: x,
			...item
		}) => ({
			...item,
			...o && {
				order: () => this.props.order(o),
				orderTo: this.props.orderTo(o),
			},
			...t && { title: translate(t), },
			...x && { title: x, },
		}));
	}

  // eslint-disable-next-line no-unused-vars
  renderCheckbox = (value, { item, items, }) => {
    // если есть права на режим распредиления и аккаунт не архивный(id = 3)
  	if (items.accountStatus.valueOf !== 3) {
  		const id = items?.accountId?.valueOf;
  		const isChecked = this.props?.selectedUsers?.includes(id);

  		const options = {
        // eslint-disable-next-line no-unused-vars
  			onChange: (event) => {
  				this.props.selectUser(isChecked, id);
  			},
  		};

  		if (isChecked) {
  			options.checked = true;
  		}

  		return (
	<input type="checkbox" { ...options } />
  		);
  	}

  	return '';
  }

  renderInlineValue = (value, { item, }) => {
  	if (item.access('index')) {
  		return value ?? "-";
  	}

  	return '-';
  };
		renderAcountIsMobile = (value, { item, }) => {
			if (!item.access('index')) {
				return '-';
			} else if (!value) {
				return '';
			}

			const { translate, } = this.props;

			return value === 2 ? translate("client_no") : translate("client_yes");
		};

  renderTaxSertification = (value, { item, }) => {
  	if (item.access('index')) {
  		const { translate, } = this.props;

  		return value === 2
  			? translate('client_unspecified')
  			: translate('client_given');
  	}

  	return '';
  }

  // eslint-disable-next-line no-unused-vars
  renderAccountCategory = (value, { item, items, }) => {
  	if (item.access('index')) {
  		const enums = this.state?.enums?.data?.core?.AccountCategoryEnum;
  		const statusType = {
  			1: 'bg-violet-blue',
  			2: 'bg-shadow-blue',
  			3: 'bg-grass-green',
  			4: 'bg-deep-blue',
  			5: 'bg-pink',
  			6: 'bg-deep-yellow',
  			7: 'bg-red-orange',
  			8: 'bg-coral',
  			9: 'bg-blood-red',
  			10: 'bg-turquoise',
  		};
  		const categoryId = +value;
  		const options = {
  			className: `table-status ${ statusType?.[categoryId] }`,
  		};
  		const category = enums?.[+value];

  		return (<div { ...options }>{ category }</div>);
  	}

  	return '-';
  };

  renderLastDescription = (value) => {
  	return (
	<MagicTooltip
	classNameTooltip={ `tooltip tooltip--large-width` }
	content={ value ?? '' }
	lengthCut={ 20 }
  		/>
  	);
  }
	renderAuthorOflastDescription = (value, {item, items}) => {
			const id = value;
			const name = items?.authorOflastDescriptionName;
			const surname = items?.authorOflastDescriptionSurname;

			const descriptionsUserIdAcess = items.descriptionsUserId.access('show');
			const userNameAccess = items?.userName.access('index');
			const userSurnameAccess = items?.userSurname.access('index');

			if (userNameAccess && userSurnameAccess) {
				const LENGTH_SHORT = 20;
				let fullname = `${ surname?.valueOf || '' } ${ name?.valueOf || '' }`;

				fullname = (
					<MagicTooltip
						classNameTooltip={`tooltip tooltip--large-width`}
						content={ fullname }
						lengthCut={ LENGTH_SHORT }
					/>
				);

				if (descriptionsUserIdAcess) {
					const options = {
						style: {
							/*maxWidth:        '250px',*/
							display:         '-webkit-box',
							WebkitLineClamp: '1',
							WebkitBoxOrient: 'vertical',
							// overflow:        'hidden',
						},
						to: `/settings/user/${ +id }`,
					};
					return (<Link { ...options }>{ fullname }</Link>);
				} else {
					return fullname;
				}
			}

			return '-';
  }

  renderBriefcaseStatus = (value, { item, }) => {
  	if (!item.access('index')) {
  		return '-';
  	} else if (!value) {
  		return '';
  	}

  	const { translate, } = this.props;

  	return value === 2 ? translate("client_no") : translate("client_yes");
  }

  renderAccountEmail = (value, { item, items, }) => {
  	const { emailPopup, showCheckbox, } = this.props;

  	if (!item.access('index')) {
  		return '-';
  	} else if (!value) {
  		return '';
  	}
    // eslint-disable-next-line no-unused-vars
  	const options = {
  		style: {
  			cursor: 'pointer',
  			'pointer-events': showCheckbox ? 'none' : 'default',
  		},
  	};
  	const isActive = showCheckbox ? '2' : items?.isEmailVerified?.valueOf;
  	const img = (<img alt="letter" data-is-verified={ isActive } src={ letter } />);
    // const textEmail = translate('tasks_client_write');

  	const closeModal = async (e) => {
  		if (e) {
  			e.stopPropagation();
  		}
  		await this.save({ isEmailModal: false, });
  	};
  	const showModal = async () => await this.save({ isEmailModal: { id: items.accountId.valueOf, language_id: items?.userLanguageId?.valueOf, }, });

  	return (
	<div style={ { position: 'relative', } } onClick={ async () => await showModal() } >
	<span { ...options }>{ img } { value }</span>
	<div>
	{this.state.isEmailModal.id === items.accountId.valueOf && (
	<MessagePopupNew
	accountId={ String(this.state.isEmailModal.id) }
	languageId={ this.state.isEmailModal.language_id }
	title={ this.props.translate('client_message_send') }
	translate={ this.props.translate }
	onCancel={ async (e) => {
  							await closeModal(e);
  						} }
  					/>
  				)}
  			</div>
  		</div>
  	);
        // const { translate } = this.props;
        // return (<span><img alt='letter' src={ letter }/> {translate(`tasks_client_write`)}</span>);
  };

  renderAccountFullname = (value, { items, }) => {
  	const id = items?.accountId;
  	const name = items?.accountName;
  	const surname = items?.accountSurname;

  	if (name?.access?.('index') && surname?.access?.('index')) {
  		const LENGTH_SHORT = 20;
  		let fullname = `${ surname?.valueOf || '' } ${ name?.valueOf || '' }`;

  		fullname = (
  			<MagicTooltip
  				classNameTooltip={ `tooltip tooltip--large-width` }
  				content={ fullname }
  				lengthCut={ LENGTH_SHORT }
	/>
  		);
  		if (id?.access?.('show')) {
  			const options = {
  				style: {

            /*maxWidth:        '250px',*/
  					display: '-webkit-box',
  					WebkitLineClamp: '1',
  					WebkitBoxOrient: 'vertical',
            // overflow:        'hidden',
  				},
  				to: `/clients/${ +id?.valueOf }`,
  			};

  			return (<Link { ...options }>{ fullname }</Link>);
  		}

  		return fullname;

  	}

  	return '-';
  };

  renderAccountPhone = (value, { item, items, }) => {
  	if (!item.access('index')) {
  		return '-';
  	} else if (!value) {
  		return '';
  	}
  	const { translate, showCheckbox, } = this.props;
  	const isActive = showCheckbox ? '2' : items?.isPhoneVerified?.valueOf;
  	const options = {
  		style: { cursor: 'pointer', 'pointerEvents': showCheckbox ? 'none' : 'default', },
  	};
  	const img = (<img alt="call" data-is-verified={ isActive } src={ call } />);
    // const textPhone = translate('tasks_call')
  	return (
	<span
	{ ...options }
	onClick={ async () => !showCheckbox && callService.sendCall({ account_id: items.accountId.valueOf, }) }
  		>
	{ img } { value }
  		</span>
  	);
    // const { translate } = this.props;
    // return (<span { ...options }><img alt='call' src={ call } /> {translate(`tasks_createform_types_call`)}</span>);
  };

  renderAccountWarm = (value, { item, }) => {
  	if (item.access('index')) {
  		const enums = this.state.enums?.data?.core?.AccountWarmTypeEnum;
  		const options = {
  			className: `status-warm value-${ value }`,
  			style: { display: 'inline-block', },
  		};
  		const warm = enums?.[+value];

  		return (<span { ...options }>{ warm }</span>);
  	}

  	return '-';
  };

  renderAccountStatus = (value, { item }) => {
	  if (item.access('index')) {
		  const enums = this.state.enums?.data?.core?.AccountStatusEnum;
		  const status = enums?.[+value];

		  const options = {
			  className: `client-status-${ value === 2 ? 'delete' : '' }`,
		  };
		  return (<span { ...options }>{ status }</span>);
	  }
	  return '-';
  };
  renderAccountRegisterType = (value, { item }) => {
    if (item.access('index')) {
      const enums = this.state.enums?.data?.core?.AccountRegisterTypeEnum;
//      const options = {
//        className: `status-warm value-${value}`,
//        style: { display: 'inline-block', },
//      };
      const warm = enums?.[+value];
      return (<span>{ warm }</span>);
    }
    return '-';
  };

  renderUserFullname = (value, { items, }) => {
  	const id = items?.userId;
  	const name = items?.userName;
  	const surname = items?.userSurname;

  	if (name?.access?.('index') && surname?.access?.('index')) {
  		const LENGTH_SHORT = 20;
  		let fullname = `${ surname?.valueOf || '' } ${ name?.valueOf || '' }`;

  		fullname = (
  			<MagicTooltip
  				classNameTooltip={ `tooltip tooltip--large-width` }
  				content={ fullname }
  				lengthCut={ LENGTH_SHORT }
	/>
  		);
  		if (id?.access?.('show')) {
  			const options = {
  				style: {
            /*maxWidth:        '250px',*/
  					display: '-webkit-box',
  					WebkitLineClamp: '1',
  					WebkitBoxOrient: 'vertical',
            // overflow:        'hidden',
  				},
  				to: `/settings/user/${ +id?.valueOf }`,
  			};

  			return (<Link { ...options }>{ fullname }</Link>);
  		}

  		return fullname;

  	}

  	return '-';
  };

  renderIsProcessed = (value, { item, }) => {
  	if (item.access('index')) {
  		const enums = this.state.enums?.data?.core?.AccountIsProcessedEnum;
  		const processed = enums?.[+value];

  		return (<span>{ processed }</span>);
  	}

  	return '';
  }

  renderAccountTag = (value, { item, }) => {
  	if (item.access('index')) {
  		return value ? value.join(", ") : '';
  	}

  	return '';
  }

  render = () => {
  	if (!this.props?.data?.length) {
  		return null;
  	}

  	const options = {
  		config: this.accountConfig,
  		data: this.props?.data,
  		head: [
  			'accountId',
  			'checkbox',
        // 'accountCreated',
  			'accountFullname',
        // 'accountCountry',
  			'accountEmail',
  			'accountPhone',
  			'accountWarmTypeUpdatedAt',
  			'accountStatus',
            'accountWarm',
  			'accountDescriptionUpdatedAt',
  			'lastDescription',
            'authorOflastDescription',
  			'userFullname',
  			'newInBriefcase',
  			'isProcessed',
  			'taxSertification',
  			'accountCountryName',
  			'accountTag',
  			'accountCategoryChanged',
  			'accountCategory',
  			'isMobile',
  		'accountRegisterType',],
  		RowProps: ({ index, items, head, }) => index===undefined ? {} : { 'data-archived': items?.accountStatus?.valueOf, },
  	};

  	return (<MagicTable { ...options } />);
  }
}

export default withRouter(withLocalize(ClientsMagicTable));