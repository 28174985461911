const permissions = [
	// Управление партнерскими программами
	{
		path: [ "partners", "programs", ],
		key: "affiliate_programs",
	},

	{
		path: [ "partners", "new_partners_conditions", ],
		key: "new_partners_conditions",
	},
	// Списки стран со ставками выплат
	{
		path: [ "partners", "payout_rate_lists", ],
		key: "payout_rate_lists",
	},
	// Символы
	{
		path: [ "partners", "symbols", ],
		key: "symbols",
	},
	// Список символов
	{
		path: [ "partners", "symbols_lists", ],
		key: "symbols_lists",
	},
	// Группы символов
	{
		path: [ "partners", "symbols_groups", ],
		key: "symbols_groups",
	},
	// Список групп счетов
	{
		path: [ "partners", "groups_lists", ],
		key: "groups_lists",
	},

	//Партнерские выплаты
	{
		path: [ 'partners', 'accruals', ],
		key: 'accruals',
	},

	//Заявки на партнерство
	{
		path: [ 'partners', 'requests', ],
		key: 'requests',
	},

	//Список партнеров
	{
		path: [ 'partners', 'partners', ],
		key: 'partners',
	},

	// Рефералы
	// Список рефералов
	{
		path: [ "partners", "referrals", ],
		key: "referrals",
	},

	// Статистика
	// Статистика по переходам
	{
		path: [ "partners", "statistic", ],
		key: "statistic",
	},

	// toDo
	// Промоматериалы
	// Управление промоматериалами
	{
	  path: [ 'partners', 'promo', ],
	  key: 'banners',
	},
	{
	  path: [ 'partners', 'target_pages', ],
	  key: 'target-page',
	},
	{
	  path: [ 'partners', 'logos', ],
	  key: 'logos',
	},
	{
		key: 'lendings',
		path: [ 'partners', 'lending', ],
	},
	{
		key: 'calculationsDashboard',
		path: [ 'partners', 'condition_calculation_state', ],
	},
	{
		key: 'calculationsPayout',
		path: [ 'partners', 'condition_payout_state', ],
	},
	{
		key: 'awaitingPartners',
		path: [ 'partners', 'awaiting_change_program', ],
	},
	{
		key: 'awaitingReferrals',
		path: [ 'partners', 'awaiting_add_referral_register', ],
	},
	// Статистика по промоматериалам
	// {
	//   path: ['partners', ],
	//   key: '',
	// },

	// toDO
	// Rebate Service
	// Rebate IB Admin
	{
	  path: [ 'partners', 'rebate_projects', ],
	  key: 'rebate',
	},

	// toDO
	// "Менеджеры"
	// cписок менеджеров
	// {
	//   path: ['partners', ],
	//   key: '',
	// },
	// Admin
	// {
	//   path: ['partners', ],
	//   key: '',
	// },
	// Менеджеры (статистика по менеджерам?)
	// {
	//   path: ['partners', ],
	//   key: '',
	// },
];

export default permissions;