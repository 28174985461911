import React from "react";
import PropTypes from "prop-types";
import { withRouter, Link, } from "react-router-dom";
import { withLocalize, } from "react-localize-redux";
import { connect, } from "react-redux";

import { MagicTable, } from "../../../../components/Magic";
import { MagicTooltip, } from "../../../tima/components/Magic/MagicTooltip";

class ListSystemAccountsMagicTable extends React.Component {
	constructor (props) {
		super(props);
	}

	static propTypes = {
		data: PropTypes.array,
		enums: PropTypes.object,
	};

	static defaultProps = {
		data: [],
		enums: {},
	};

	get enums () {
		return this.props?.enums ?? {};
	}

	get params () {
		return this.props?.match?.params;
	}

	get query () {
		return new URLSearchParams(this.props?.location?.search);
	}

	get systemAccountsConfig () {
		const { translate, } = this.props;
		const prefix = "list_accounts_table_system_";

		return [
			{
				path: [ "core", "system_accounts", "id", ],
				key: "systemAccountId",
				render: this.renderSystemAccountId,
				title: `${ prefix }id`,
			},
			{
				path: [ "core", "account", "id", ],
				key: "clientId",
			},
			{
				path: [ "core", "account", "name", ],
				key: "clientName",
			},
			{
				path: [ "core", "account", "surname", ],
				key: "clientSurname",
			},
			{
				path: [ "core", "account", "status", ],
				key: "accountStatus",
			},
			{
				accessChecked: true,
				key: "clientFullname",
				render: this.renderClientFullname,
				title: `${ prefix }client`,
			},
			{
				path: [ "core", "system_accounts", "created_at", ],
				key: "createdAt",
				render: this.renderInlineValue,
				title: `${ prefix }created_at`,
			},
			{
				path: [ "core", "system_accounts", "updated_at", ],
				key: "updatedAt",
				render: this.renderInlineValue,
				title: `${ prefix }updated_at`,
			},
			{
				path: [ "core", "system_accounts", "type", ],
				key: "type",
				render: this.renderEnumField("SystemAccountTypeEnum"),
				title: `${ prefix }type`,
			},
			{
				path: [ "core", "system_accounts", "acc_ccy", ],
				key: "currency",
				render: this.renderEnumField("AccountCurrencyEnum"),
				title: `${ prefix }acc_ccy`,
			},
			{
				path: [ "core", "system_accounts", "value", ],
				key: "value",
				render: this.renderInlineValue,
				title: `${ prefix }value`,
			},
			{
				path: [ "core", "system_accounts", "status", ],
				key: "status",
				render: this.renderEnumField("SystemAccountStatusEnum"),
				title: `${ prefix }status`,
			},
		].map(({ title: t, xtitle: x, ...item }) => ({
			...item,
			...x && { title: x, },
			...t && { title: translate(t), },
		}));
	}

	renderLink = (path, text, className = "") => (
		<Link className={ className } to={ path }>
			{text}
		</Link>
	);

	renderInlineValue = (value, { item, }) => {
		if (item?.access?.("index")) {
			return value ?? "-";
		}

		return "-";
	};

	renderSystemAccountId = (value, { item, }) => {
		if (item?.access?.("index", "show")) {
			return this.renderLink(`/list-accounts/systems/${ value }`, value);
		}

		return this.renderInlineValue(value, { item, });
	};

	renderClientFullname = (value, { items, }) => {
		const id = items?.clientId;
		const name = items?.clientName;
		const surname = items?.clientSurname;

		if (name?.access?.("index") && surname?.access?.("index")) {
			const LENGTH_SHORT = 20;
			let fullname = `${ surname?.valueOf || "" } ${ name?.valueOf || "" }`;

			fullname = (
				<MagicTooltip
					classNameTooltip={ `tooltip tooltip--large-width` }
					content={ fullname }
					lengthCut={ LENGTH_SHORT }
				/>
			);
			if (id?.access?.("show")) {
				return this.renderLink(`/clients/${ id.valueOf }`, fullname);
			}

			return fullname;
		}

		return "-";
	};

	renderEnumField = enumName => (value, { item, }) => {
		if (item?.access?.("index")) {
			const enums = this.enums?.data?.core?.[enumName];

			return enums?.[value] ?? "-";
		}

		return "-";
	};

	render () {
		if (!this.props?.data?.length) {
			return null;
		}

		const options = {
			config: this.systemAccountsConfig,
			data: this.props?.data,
			head: [
				"systemAccountId",
				"clientFullname",
				"createdAt",
				"updatedAt",
				"type",
				"currency",
				"value",
				"status",
			],
			RowProps: ({ index, items, head, }) =>
				index === undefined
					? {}
					: { "data-archived": items?.accountStatus?.valueOf, },
		};

		return <MagicTable { ...options } />;
	}
}

export default connect(
	null,
)(withRouter(withLocalize(ListSystemAccountsMagicTable)));