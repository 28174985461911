import calls from './new-calls';
import coreAccount from './new-core-account';
import contestMembers from './new-contest-members';
import contests from './new-contests';
import delegation from './new-delegation';
import listAccountsSystems from './new-list-accounts-systems';
import listAccountsTradingsMt4OrdersClosed from './new-list-accounts-tradings-mt4-orders-closed';
import listAccountsTradingsMt4OrdersOpened from './new-list-accounts-tradings-mt4-orders-opened';
import listAccountsTradingsMt5DealsClosed from './new-list-accounts-tradings-mt5-deals-closed';
import listAccountsTradingsMt5OrdersClosed from './new-list-accounts-tradings-mt5-orders-closed';
import listAccountsTradingsMt5OrdersOpened from './new-list-accounts-tradings-mt5-orders-opened';
import listAccountsTradingsMt5PositionsClosed from './new-list-accounts-tradings-mt5-positions-closed';
import listAccountsTradingsMt5PositionsOpened from './new-list-accounts-tradings-mt5-positions-opened';
import listAccountsTradings from './new-list-accounts-tradings';
import newPartnerRebateProjects from "./new-partner-rebate-projects";
import partnerAccruals from './new-partner-accruals';
import partnerAccrualsPartnerCard from './new-partner-accruals-partner-card';
import partnerReferrals from './new-partner-referrals';
import partnerReferralsByPartner from './new-partner-referrals-by-partner';
import partnerRequests from './new-partner-requests';
import payment from './new-payment';
import search from './new-search';
import supportTickets from './new-support-tickets';
import tasks from './new-tasks';
import timaAgents from './new-tima-agents';
import timaConditions from './new-tima-conditions';
import timaInvestments from './new-tima-investments';
import timaStaratagies from './new-tima-staratagies';
import usersKpiPlan from './new-users-kpi-plan';
import users from './new-users';
import userLetters from './new-user-letters';
import marketing from './new-statistics-marketing';
import partnerSymbols from './new-partner-symbols';
import partnersSymbolsGroups from './new-partners-symbols-groups';
import accountUtmLink from './new-statistics-account-utm-link';
import templateManagementMails from './template-management-mails';
import templateManagementSMS from './template-management-sms';
import balanceOperations from "./balance-operations";
import summaryOfFinTransactions from './summary-of-fin-transactions';
import partnerSymbolsLists from './new-partner-symbols-lists';
import accountGroupsLists from './new-partner-account-groups-lists';
import partnersPayoutRateLists from './new-partners-payout-rate-lists';
import newPartnersCondition from './new-partners-condition';
import partnerPartnersList from './new-partner-partners-list';
import partnerPartnersRequests from './new-partner-partners-requests';
import newPartnersReferralsList from './new-partner-referrals-list';
import newPartnersReferralsListPartnerCard from './new-partner-referrals-list-partner-card';
import newAffiliatePrograms from './new-affiliate-programs';
import listOfCrons from './list-of-crons';
import newPartnerPromotionalCampaings from "./new-partner-promotional-campaings";
import newPartnerCalculationDashboard from "./new-partner-calculation-dashboard";
import newPartnerCalculationPayoutDashboard from "./new-partner-calculation-payout-dashboard";
import newPartnersAwaitingList from "./new-partners-awaiting-list";
import newReferralsAwaitingList from "./new-referrals-awaiting-list";

import newPartnerBanners from "./new-partner-banners";
import newPartnerTargetPages from "./new-partner-target-pages";
import newPartnerLogos from "./new-partner-logos";
import newPartnerLendings from "./new-partner-lendings";
export default [
	calls,
	...coreAccount,
	contests,
	contestMembers,
	delegation,
	listAccountsSystems,
	listAccountsTradingsMt4OrdersClosed,
	listAccountsTradingsMt4OrdersOpened,
	listAccountsTradingsMt5DealsClosed,
	listAccountsTradingsMt5OrdersClosed,
	listAccountsTradingsMt5OrdersOpened,
	listAccountsTradingsMt5PositionsClosed,
	listAccountsTradingsMt5PositionsOpened,
	listAccountsTradings,
	partnerAccruals,
	partnerAccrualsPartnerCard,partnerReferrals,
	partnerReferralsByPartner,
	partnerRequests,
	payment,
	...search,
	...supportTickets,
	...tasks,
	timaAgents,
	timaConditions,
	timaInvestments,
	timaStaratagies,
	usersKpiPlan,
	users,
	userLetters,
	...marketing,
	...accountUtmLink,
	newPartnerTargetPages,
	templateManagementMails,
	templateManagementSMS,
	balanceOperations,
	summaryOfFinTransactions,
	partnersSymbolsGroups,partnerSymbols,
	newPartnerRebateProjects,
	partnerSymbolsLists,
	accountGroupsLists,
	newAffiliatePrograms,
	partnersPayoutRateLists,
	newPartnersCondition,
	partnerPartnersRequests,
	partnerPartnersList,
	newPartnersReferralsList,newPartnersReferralsListPartnerCard,
	listOfCrons,
	newPartnerPromotionalCampaings,
	newPartnerBanners,
	newPartnerLogos,
	newPartnerLendings,
	newPartnerCalculationDashboard,
	newPartnerCalculationPayoutDashboard,
	newPartnersAwaitingList,
	newReferralsAwaitingList,
];