import React, { PureComponent, } from "react";
import { Redirect, withRouter, Link, } from "react-router-dom";
import Moment from "moment";
import propTypes from 'prop-types';
import { GET_CONTEST_LIST_MEMBERS, GET_TRANSLATIONS, } from "../apiRoutes";
import { CONTESTS_EXPORT_FIELDS, } from "./constants";
import { securedRequest, } from "../../../services/RequestService";
import Preloader from "../../../components/LoadingHOC/Preloader";
import Loader from "../../tima/components/Loader";
import { MagicButton, } from "../../../components/Magic/components/MagicButton";
import { MagicConfirm, } from "../../tima/components/Magic/MagicConfirm";
import { ContestMembers, } from "./ContestMembers";
import { CreateContestPopup, } from "./CreateContestPopup/CreateContestPopup";
import PermissionService from "../../../services/PermissionService";
import {
	MagicFilterPanels,
	Mentor,
	showExportToExcelError,
} from "../../../components/Magic";
import { contestService, } from "../../../services/ContestDataService";

class ContestCard extends PureComponent {

  static defaultProps = {
  	params: {},
  };

  static propTypes = {
  	lang: propTypes.string,
  	params: propTypes.object,
  };

  constructor (props) {
  	super(props);
  	this.state = {
  		currentContest: {
  			hash: null,
  			data: {},
  		},
  		languages: {
  			hash: null,
  			data: [],
  		},
  		showTranslationId: undefined,
  		isOpenEditPopup: this.isEdit,
  		showConfirmDelete: false,
  		redirectDeletedContest: false,
  	};

  	const mf = new Mentor({
  		owner: this,
  		serviceId: 204,
  		translate: this.props.translate,
  	});

  	Object.defineProperty(this, 'mf', { get: () => mf, });
  }

  get isEdit () {
  	return this.props?.match?.params?.isEdit ?? false;
  }

  get contestId () {
  	return +this?.props?.params?.contestId;
  }

   currentContestChanges = async (currentContest) => {
   	if (currentContest.hash===this.state?.currentContest?.hash) {
   		return `${ this.constructor.name }.currentContestChanges: false`;
   	}
   	await this.save({
   		currentContest: {
   			hash: currentContest.hash,
   			data: currentContest.data,
   		},
   	});

   	return `${ this.constructor.name }.currentContestChanges: true`;
   };

   contestTranslationsChanges = async (translation) => {
   	if (translation.hash===this.state?.languages?.hash) {
   		return `${ this.constructor.name }.contestTranslationsChanges: false`;
   	}
   	await this.save({
   		languages: {
   			hash: translation.hash,
   			data: translation.data.data,
   		},
   	});

   	return `${ this.constructor.name }.contestTranslationsChanges: true`;
   };

   async componentDidMount () {
   	contestService.subscribe('contestById', this.currentContestChanges, this);
   	contestService.subscribe('contestTranslationsById', this.contestTranslationsChanges, this);
   	await this._getData();
   };

   componentDidUpdate (_, prevState) {
   	if (prevState?.contestName !== this.state?.contestName) {
   		this.props?.getNameContest?.(this.state?.contestName);
   	}
   }

   componentWillUnmount () {
   	contestService.unsubscribe('contestById', this.currentContestChanges, this);
   	contestService.unsubscribe('contestTranslationsById', this.contestTranslationsChanges, this);
   };

  save = async state => new Promise(next => this.setState(state, next));

  _getData = async () => {
  	const data = Promise.all([
  		await this._getContestByID(),
  		await this._getContestTranslationsById(),
  	]);
  };

   isOpenEditPopup = () => this.setState({ isOpenEditPopup: true, });

   params = () => this.props.match.params;

   exportListMembers = () => {
   	const { params: { contestId: id, }, } = this.props;
   	const { filterId: filter_id, } = this.mf;
   	const globalUrl = `${ GET_CONTEST_LIST_MEMBERS }`;
   	const exportUrl = `export-to-excel`;
   	const url = `${ globalUrl }/${ id }/${ exportUrl }`;
   	const options = {
   		file_name: `${ id }_participants_list_`,
   		fields: CONTESTS_EXPORT_FIELDS.listMembers,
   	};

   	if (filter_id) {
   		options.filter_id = filter_id;
   	}

   	securedRequest.post(url, options)
   		.then((res) => {
            // console.log('ok');
   		})
   		.catch((error) => {
   			showExportToExcelError(error);
   		});
   };

   _getContestTranslationsById = async () => {
   	try {
   		const { contestId, } = this.props?.params;
   		const response = await contestService.contestTranslationsById(contestId);
   		const { data, } = response.data;

   		await this.save({
   			languages: {
   				...this.state.languages,
   				data,
   			},
   		});
   	} catch (error) {
   		error?.showErrorNotification?.();
   	}
   }

   _getContestByID = async () => {
   	try {
   		const { contestId, } = this.props?.params;
   		const response = await contestService.contestById(contestId);
   		const { data, } = response.data;

   		await this.setState({
   			currentContest: {
   				...this.state.currentContest,
   				data,
   			},
   		});
   	} catch (error) {
   		error?.showErrorNotification?.();
   	}
   };

   deleteContestsById = async (contestId) => {
   	try {
   		await contestService.delContestsById(contestId);
   		await this.save({ redirectDeletedContest: true, });
   	} catch (error) {
   		error?.showErrorNotification?.();
   	}
   };

   renderButtonEdit = () => {
   	const { translate, } = this.props;
   	const data = PermissionService.cast(this.state?.currentContest?.data, this.contestConfig, true);

   	if ([ 2, 5, ].includes(+data?.contestStatus?.valueOf) || !data?.contestStatus?.access?.('update')) {
   		return '';
   	}
   	const options = {
   		className: 'magic-button__item magic-button__item_contest_edit',
   		to: `/contests/contest/${ this.contestId }/edit`,
   		children: translate('contest_card_btn_edit'),
   	};

   	return (<Link { ...options } />);
   };

   renderButtonDelete = () => {
   	const { translate, } = this.props;
   	const data = PermissionService.cast(this.state?.currentContest?.data, this.contestConfig, true);

   	if ([ 2, 5, ].includes(+data?.contestStatus?.valueOf) || !data?.contestStatus.access('destroy')) {
   		return '';
   	}

   	const optionsBtn = {
   		className: 'magic-button__item magic-button__item_contest_delete',
   		onClick: () => this.setState({ showConfirmDelete: !this.state.showConfirmDelete, }),
   	};
   	const optionsMagicConfirm = {
   		title: ![ "0", 0, ].includes(data?.contestStatus?.valueOf) ? translate('contest_card_delete_attention') : '',
   		accept: translate('contest_card_delete_сonfirm'),
   		reject: translate('contest_card_delete_cancel'),
   		onReject: () => this.setState({ showConfirmDelete: !this.state.showConfirmDelete, }),
   		onAccept: () => this.deleteContestsById(+data?.contestId?.valueOf),
   		isVisible: this.state.showConfirmDelete,
   	};

   	return (
	<MagicButton { ...optionsBtn }>
	{translate('contest_card_btn_delete')}
	<MagicConfirm { ...optionsMagicConfirm } />
   		</MagicButton>
   	);
   };

   cardHeader = ({ translate, }) => {
   	return (
	<div className="card__header ">
	<h2 className="card__title">
	{translate("contest_card_title")}
   			</h2>
	<div className="card__buttons">
	{ this.renderButtonEdit() }
	{ this.renderButtonDelete() }
   			</div>
   		</div>
   	);
   };

   renderFlag = (codeLang) => {
   	const classFlag = codeLang === "en" ? "gb" : codeLang.toLowerCase();

   	return `flag-icon flag-icon-squared flag-icon-${ classFlag }`;
   };

   get contestConfig () {
   	return [
   		{
   			path: [ 'contests', 'contests', 'id', ],
   			key: 'contestId',
   		},
   		{
   			path: [ 'contests', 'contests', ],
   			key: 'contestMembersExport',
   		},
   		{
   			path: [ 'contests', 'contests', 'start_reg_date', ],
   			key: 'contestRegStart',
   			render: this.renderContestDate('start_reg_date'),
   		},
   		{
   			path: [ 'contests', 'contests', 'finish_reg_date', ],
   			key: 'contestRegFinish',
   			render: this.renderContestDate('finish_reg_date'),
   		},
   		{
   			path: [ 'contests', 'contests', 'date_start', ],
   			key: 'contestStart',
   			render: this.renderContestDate('date_start'),
   		},
   		{
   			path: [ 'contests', 'contests', 'date_end', ],
   			key: 'contestEnd',
   			render: this.renderContestDate('date_end'),
   		},
   		{
   			path: [ 'contests', 'contests', 'prize_fund', ],
   			key: 'contestPrizeFund',
   			render: this.renderContestText('prize_fund'),
   		},
   		{
   			path: [ 'contests', 'contests', 'prize_count', ],
   			key: 'contestPrizeCount',
   			render: this.renderContestText('prize_count'),
   		},
   		{
   			path: [ 'core', 'nationality', 'name', ],
   			key: 'contestNationalityName',
   			render: this.renderContestNationalities('citizenship'),
   		},
   		{
   			path: [ 'contests', 'contests_to_participants_categories', 'account_category', ],
   			key: 'contestParticipantsCategories',
   			render: this.renderParticipantsCategories('category_participants'),
   			allCategories: this.props.enums?.core?.AccountCategoryEnum ?? {},
   		},
   		{
   			path: [ 'core', 'nationality', 'alpha_2', ],
   			key: 'contestNationalityAlpha',
   		},
   		{
   			path: [ 'core', 'nationality', 'id', ],
   			key: 'contestNationalityId',
   		},
   		{
   			path: [ 'contests', 'contests', 'status', ],
   			key: 'contestStatus',
   			render: this.renderContestStatus('status'),
   		},
   		{
   			path: [ 'contests', 'contests', 'condition_max_loss', ],
   			key: 'contestMaxLoss',
   			render: this.renderContestText('condition_max_loss'),
   		},
   		{
   			path: [ 'contests', 'contests', 'condition_closed_orders', ],
   			key: 'contestConditionClosedOrders',
   			render: this.renderContestText('condition_closed_orders'),
   		},
   		{
   			path: [ 'contests', 'contests', 'created_at', ],
   			key: 'contestCreated',
   			render: this.renderContestDate('created_at'),
   		},
   		{
   			path: [ 'contests', 'contests', 'updated_at', ],
   			key: 'contestUpdated',
   			render: this.renderContestDate('updated_at'),
   		},
   	];
   }
   get prizesConfig () {
   	return [
		{
			path: [ 'contests', 'winners_prizes', 'prize', ],
			key: 'constestPrizes',
			render: this.renderContestPrizes('prize'),
		},

   		{
   			path: [ 'contests', 'winners_prizes', 'places_to', ],
   			key: 'constestPrizesTo',
   			render: this.renderContestPrizes('places_to'),
   		},
		{
			path: [ 'contests', 'winners_prizes', 'places_from', ],
			key: 'constestPrizesFrom',
			render: this.renderContestPrizes('places_from'),
		},
   	];
   }

   get languagesConfig () {
   	return [
   		{
   			path: [ 'contests', 'contests_translations', 'default', ],
   			key: 'contestDefault',
   		},
   		{
   			path: [ 'contests', 'contests_translations', 'id', ],
   			key: 'contestTranslationId',
   		},
   		{
   			path: [ 'core', 'language', 'id', ],
   			key: 'contestLanguageId',
   		},
   		{
   			path: [ 'core', 'language', 'code', ],
   			key: 'contestLanguageCode',
   		},
   		{
   			path: [ 'contests', 'contests_translations', 'name', ],
   			key: 'contestName',
   			render: this.renderContestText('name', {
   				classNameWrapper: 'field--full--width',
   			}),
   		},
   		{
   			path: [ 'contests', 'contests_translations', 'description', ],
   			key: 'contestDescription',
   			render: this.renderContestText('description', {
   				classNameWrapper: 'field--full--width',
   			}),
   		},
   		{
   			path: [ 'contests', 'contests_translations', 'condition_file_path', ],
   			key: 'contestConditionFilePath',
   			render: this.renderContestConditionFilePath,
   		},
   		{
   			path: [ 'contests', 'contests_translations', 'condition_file_name', ],
   			key: 'contestConditionFileName',
   		},
   		{
   			path: [ 'core', 'language', 'name', ],
   			key: 'contestLanguageName',
   			render: this.renderContestLanguageName,
   		},
   		{
   			path: [ 'contests', 'contests_translations', 'sendpulse_book', ],
   			key: 'contestLanguageSendPulse',
   			render: this.renderContestText('sendpulse_book'),
   		},
   		{
   			path: [ 'contests', 'contests_translations', 'sendpulse_decline_book', ],
   			key: 'contestLanguageDeclineSendPulse',
   			render: this.renderContestText('sendpulse_decline_book'),
   		},
   	];
   }

   renderContestTitledItem = (key, value, className) => {

   	const { translate, } = this.props;
   	const { classNameValue = 'description', classNameWrapper = 'column__field column__field_item', } = className || {};

   	return (
	<div className={ classNameWrapper }>
	<span className="title">{ translate(`contest_card_${ key }`) }</span>
	<div className={ classNameValue }>{ value ?? "-"}</div>
   		</div>
   	);
   };
	renderWinnerPrizesItems = (key, value, className) => {


		const { classNameValue = 'description', classNameWrapper = 'column__field column__field_item', } = className || {};

		return (
			<div className='d-flex' >
				<div >{ value ?? "-"}</div>
			</div>
		);
	};

   renderContestDate = key => (date, { item, items, }) => {
   	if (item.access('show')) {
   		const formatDB = 'YYYY-MM-DD HH:mm:ss';
   		const formatOut = 'YYYY-MM-DD HH:mm:ss';

   		date = Moment(date, formatDB);

   		return this.renderContestTitledItem(key, date.isValid() ? date.format(formatOut) : "-");
   	}

   	return '-';
   };

   renderContestText = (key, className) => (text, { item, }) => {
   	if (item.access('show')) {
   		return this.renderContestTitledItem(key, text ?? "-", className);
   	}

   	return '-';
   };
	renderContestPrizes = key => (names, { item, items, }) => {
		const { translate, } = this.props;
		if (item.access('show')) {
			const winnerPrizes = names?.map?.((prize, index) => {

				return (
					<>
					<span className="card_contest__prize">{ translate(`contest_card_${ key }`) }</span>
					<div className='card_contest_amount'>{prize} </div>
					</>
				);
			});

			return this.renderWinnerPrizesItems(key, winnerPrizes, );
		}

		return '-';
	};

   renderContestNationalities = key => (names, { item, items, }) => {
   	const { translate, } = this.props;

   	if (item.access('show')) {
   		const nationalities = names?.map?.((name, index) => {
   			const id = items?.contestNationalityId?.valueOf[index];
   			const alpha = items?.contestNationalityAlpha?.valueOf[index];

   			return (
	<div className="nationality__item" key={ id } title={ name }>
	<span className={ this.renderFlag(alpha) }> </span>
	<span>{ alpha?.toUpperCase?.() }</span>
   				</div>
   			);
   		});

   		return this.renderContestTitledItem(key, nationalities, {
   			classNameValue: 'nationality__list',
   			classNameWrapper: 'field--full--width',
   		});
   	};

   	return '-';
   };

   renderParticipantsCategories = key => (categories, { item, items, }) => {
   	if (item.access('show')) {
   		const allCategories = item?.options?.allCategories;
   		const participants = categories?.map?.((category, index) => {
   			return (
	<div className="participants__item" key={ index }>
	<span>{ allCategories?.[+category] }</span>
   				</div>
   			);
   		});

   		return this.renderContestTitledItem(key, participants, {
   			classNameValue: 'participants__list',
   			classNameWrapper: 'field--full--width',
   		});
   	}

   	return '-';
   };

   renderContestStatus = key => (status, { item, }) => {
   	if (item.access('show')) {
   		const contestStatuses = this.props?.enums?.contests?.ContestsStatusEnum ?? {};

   		return this.renderContestTitledItem(key, contestStatuses?.[+status], {
   			classNameValue: 'description value',
   			classNameWrapper: 'field--full--width',
   		});
   	}

   	return '-';
   };

   renderContestConditionFilePath = (path, { item, items, }) => {
   	if (item.access('show')) {
   		const contestTranslationId = items?.contestTranslationId?.valueOf;
   		const file_name = items?.contestConditionFileName?.valueOf;
   		const saveFileToDisk = async (item, fileName) => {
   			if (window.navigator.msSaveBlob) {
   				window.navigator.msSaveOrOpenBlob(item, fileName);
   			} else {
   				const link = document.createElement('a');

   				link.setAttribute("download", fileName);
   				link.href = URL.createObjectURL(item);
   				const evt = document.createEvent("MouseEvents");

   				evt.initEvent("click", false, true);
   				link.dispatchEvent(evt);
   			}
   		};

   		const downloadAgreementFile = async (e) => {
   			try {
   				e.preventDefault();
   				const url = `${ GET_TRANSLATIONS }/${ contestTranslationId }/get-agreement-file/`;
   				const { data, } = await securedRequest.get(url, { responseType: 'blob', });

   				await saveFileToDisk(data, file_name);
   			} catch (error) {
   				error?.showErrorNotification?.();
   			}
   		};

   		const a = (<a onClick={ e => downloadAgreementFile(e) }>{ items?.contestConditionFileName?.valueOf }</a>);

   		return this.renderContestTitledItem('condition', a, {
   			classNameValue: 'description condition',
   			classNameWrapper: 'field--full--width',
   		});
   	}

   	return '-';
   };

   renderContestLanguages = (languages) => {
   	const { translate, } = this.props;
   	const pm = PermissionService.calc(this.languagesConfig);

   	if (pm.contestLanguageId.access('show') && pm.contestLanguageCode.access('show') && pm.contestLanguageName.access('show')) {
   		const className = (item) => {
   			const case_1 = +item?.contestDefault?.valueOf && !this.state.showTranslationId;
   			const case_2 = this.state.showTranslationId === item?.contestLanguageId?.valueOf;

   			return `languages__item ${ case_1 || case_2 ?'languages__item_active' : '' }`;
   		};

   		return (
	<React.Fragment>
	<span className="title">{translate('contest_card_language')}</span>
	<div className="languages__list">
	{ languages.map(item => (
	<div
	className={ className(item) }
	key={ item?.contestLanguageId?.valueOf }
	title={ item?.contestLanguageName?.valueOf }
	onClick={ () => this.setState({ showTranslationId: item?.contestLanguageId?.valueOf, }) }
   						>
	<span className={ this.renderFlag(item?.contestLanguageCode?.valueOf) } />
	<span>{ item?.contestLanguageCode?.valueOf?.toUpperCase?.() }</span>
   						</div>
   					)) }
   				</div>
   			</React.Fragment>
   		);
   	}

   	return '-';
   };

   renderContestTranslations = () => {
   	if (!this.state.languages.data || !this.state.languages?.data.length) {
   		return '';
   	};
   	const pm = PermissionService.calc(this.languagesConfig);
   	const languages = PermissionService.cast(this.state.languages.data, this.languagesConfig, true);
   	let language;

   	if (!this.state.showTranslationId) {
   		language = languages?.find?.(l => +l?.contestDefault?.valueOf);
   	} else {
   		language = languages?.find?.(item => item?.contestLanguageId?.valueOf === this.state?.showTranslationId);
   	}

   	pm.contestName.access('show')
   		? this.setState({ contestName: language?.contestName?.valueOf, })
   		: "";
   	const renderContestName = pm.contestName.access('show')
   		? language?.contestName.render()
   		: "-";
   	const renderContestDescription = pm.contestDescription.access('show')
   		? language?.contestDescription.render()
   		: "-";
   	const renderCntestConditionFilePath = pm.contestConditionFilePath.access('show')
   		? language?.contestConditionFilePath.render()
   		: "-";
   	const renderContestSendPulse = pm.contestLanguageSendPulse.access('show')
   		? language?.contestLanguageSendPulse.render()
   		: "-";
   	const renderContestSendPulseDecline = pm.contestLanguageDeclineSendPulse.access('show')
   		? language?.contestLanguageDeclineSendPulse.render()
   		: "-";

   	if (!language) {
   		return '';
   	};

   	return (
	<React.Fragment>
	{ renderContestName }
	{ renderContestDescription }
	{ renderCntestConditionFilePath }
	<div className="field--full--width">{ this.renderContestLanguages(languages) }</div>
	<div className="columns">
	<div className="column">{ renderContestSendPulse }</div>
	<div className="column">{ renderContestSendPulseDecline }</div>
   			</div>
   		</React.Fragment>
   	);
   };

   renderContestButtonExport = () => {
   	const conf = PermissionService.calc(this.contestConfig);

   	if (conf?.contestMembersExport.access('exportToExcel')) {
   		const options = {
   			onClick: () => this.exportListMembers(),
   			className: "magic-button__item magic-button__item_export",
   			children: this.props.translate("contest_list-members_btn_export"),
   		};

   		return (<MagicButton { ...options } />);
   	}

   	return "";
   };

   cardContent = () => {

   	const data = PermissionService.cast(this.state?.currentContest?.data, this.contestConfig);
   	const winnerPrizesData = PermissionService.cast(this.state?.currentContest?.data, this.prizesConfig);

   	return (
	<div className="card__content">
	<div className="card__content card__content_left">
	<div className="columns">
	<div className="column">
	<div className="column__field">{ data?.contestRegStart }</div>
	<div className="column__field">{ data?.contestStart }</div>
	<div className="column__field icon-dollar">{ data?.contestPrizeFund }</div>
   					</div>
	<div className="column">
	<div className="column__field">{ data?.contestRegFinish }</div>
	<div className="column__field">{ data?.contestEnd }</div>
	<div className="column__field">{ data?.contestPrizeCount }</div>
   					</div>
   				</div>
		<div className="card_contest__wrapper">
		<div>{ winnerPrizesData?.constestPrizesFrom }</div>
		<div>{ winnerPrizesData?.constestPrizesTo}</div>
		<div>{ winnerPrizesData?.constestPrizes }</div>
		</div>
	{ data?.contestNationalityName }
	{ data?.contestParticipantsCategories }
	{ data?.contestStatus }
	<div className="columns">
	<div className="column"><div className="column__field">{ data?.contestMaxLoss }</div></div>
	<div className="column"><div className="column__field">{data?.contestConditionClosedOrders}</div></div>
   				</div>
   			</div>
	<div className="card__content card__content_right">
	<div className="columns">
	<div className="column"><div className="column__field">{ data?.contestCreated }</div></div>
	<div className="column"><div className="column__field">{ data?.contestUpdated }</div></div>
   				</div>
	{ this.renderContestTranslations() }
   			</div>
   		</div>
   	);
   };

   contestList = ({
   	translate, lang, enums, getName, params, query,
   }) => {
   	const optionsBtnExport = {
   		onClick: () => this.exportListMembers(),
   		className: "magic-button__item magic-button__item_export",
   		children: translate("contest_list-members_btn_export"),
   	};
   	const contestStatus = this.state.currentContest?.data?.contests?.contests?.status;

   	return (
	<React.Fragment>
	<div className={ 'list__header' }>
	<h2 className="card__title list__title">{translate("contest_list-members_title")}</h2>
	{ this.renderContestButtonExport() }
   			</div>

	<MagicFilterPanels
	mf={ this.mf }
	show={ true }
	translate={ translate }
   			/>

	<div className="list__content">
	<ContestMembers
	contestStatus={ contestStatus }
	id={ this.props.params.contestId }
	translate={ translate }
	params={ params }
	query={ query }
	enums={ enums }
	mf={ this.mf }
   				/>
   			</div>
   		</React.Fragment>
   	);
   };

   render () {
   	const { translate, } = this.props;
   	const { currentContest: { data, }, languages, } = this.state;

   	return (
	<Loader
	loaded={ Object.keys?.(this.state?.languages.data).length > 0 }
	loading={ (<Preloader scale={ 1 } />) }
   		>
	{this.state.isOpenEditPopup &&
            (<CreateContestPopup
	isOpenEditPopup={ this.isOpenEditPopup }
	translate={ translate }
	valuesToEditContest={ { data, languages: languages.data, } }
	enums={ this.props?.enums }
	data={ data }
	languages={ languages?.data }
	contestId={ this.props?.params?.contestId }
            />
            )}
	<div className="contest__card">
	{this.cardHeader(this.props)}
	{this.cardContent(this.props)}
	{this.state?.redirectDeletedContest && <Redirect to="/contests" />}
   			</div>
	<div className="contest__list">
	{this.contestList(this.props)}
   			</div>
   		</Loader>
   	);
   };
};

export default withRouter(ContestCard);