import React, { Component } from 'react';
import PropTypes            from 'prop-types';

class MagicTooltip extends Component {

    constructor ( props ) {
        super( props );
        this.state = {
            //
        };
    }

    classNameTooltipWrapper = ( ) => this.props.classNameTooltipWrapper;
    classNameTooltip = ( ) => this.props.classNameTooltip;

    render = ( ) => {
        const { lengthCut, content } = this.props;

        if (content) {
            let contentShort = content.length > lengthCut ?
                `${content.slice(0, lengthCut)}...` : content;

            return (
                content !== contentShort ?
                    (<div className = { this.classNameTooltipWrapper() }>
                        <div>
                            {contentShort}
                        </div>

                        <div className = { this.classNameTooltip() }>
                            {content}
                        </div>
                    </div>) : (<div>{content}</div>)
            );
        } else {
            return '';
        }
    }
}

MagicTooltip.propTypes = {
    classNameTooltipWrapper: PropTypes.string.isRequired,
    classNameTooltip: PropTypes.string.isRequired,
    lengthCut: PropTypes.number.isRequired,
    content: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
};

MagicTooltip.defaultProps = {
    classNameTooltipWrapper: `tooltip-wrapper`,
    classNameTooltip: `tooltip`,
    lengthCut: 10,
    content: `My MagicTooltip`,
};

export default MagicTooltip;
export {
    MagicTooltip,
};
