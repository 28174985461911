import axios from 'axios';
import AuthService from '../AuthService';
import PermissionService from '../PermissionService';
import LangService, { LANG_PROP_CODE } from '../LangService';

const requestParams = {
    headers: {
        'Accept': 'application/json',
    },
};

const setAuthHeaders = (config) => {
    config.headers.Authorization = `Bearer ${AuthService.getSessionToken()}`;

    return config;
};

const setLangHeaders = (config) => {
    config.headers['User-Language'] = LangService.getActiveLanguage(LANG_PROP_CODE);

    return config;
};

const checkInternalServerError = (error) => {
    const status = `${error.response.status}`[0];

    if (status === '5') {
        error.response.data = 'errors.5xx_internal_server';
    }
};

const simpleRequest = axios.create(requestParams);
const securedRequest = axios.create(requestParams);

// securedRequest.interceptors.request.use((config) => {
//     console.log('securedRequest#request success: ', config, (new Error()).stack);
//     return config;
// }, (error) => {
//     console.log('securedRequest#request error:', error, (new Error()).stack);
//     Promise.reject(error);
// });
// securedRequest.interceptors.response.use((response) => {
//     console.log('securedRequest#response success: ', response, (new Error()).stack);
//     return response;
// }, (error) => {
//     console.log('securedRequest#response error: ', error, (new Error()).stack);
//     Promise.reject(error);
// });
securedRequest.interceptors.request.use(setAuthHeaders, null);
securedRequest.interceptors.request.use(setLangHeaders, null);
simpleRequest.interceptors.request.use(setLangHeaders, null);

simpleRequest.interceptors.response.use((response) => {
    // Do something with response data

    return response;

}, (error) => {
    checkInternalServerError(error);

    return Promise.reject(error);
});

securedRequest.interceptors.response.use(async (response) => {
    if (response.headers['user-permission-hash']) {
        await PermissionService.hashIsEqual(response.headers['user-permission-hash']);
    }

    if (response.data.error !== 'Token has expired') {
        if (AuthService.isSessionTokenValid()) {
            return response;
        }
        await AuthService.destroySession();
    }

}, (error) => {
    checkInternalServerError(error);

    return Promise.reject(error);
});

export { simpleRequest, securedRequest };
