import { isEmpty, has } from "lodash";

export const checkFieldTable = (field, data) => {
    if (isEmpty(data)) {
        return false;
    }

    return recursiveCheckFieldTable(field.split("-"), data[0]);
};

export const getDataFieldTable = (field, data) => {
    return getDataRecursive(field.split("-"), data);
};

function recursiveCheckFieldTable (field, data) {
    if (!has(data, field[0])) {
        return false;
    }

    if (field.length > 1) {
        const cField = field[0];

        field.shift();

        return recursiveCheckFieldTable(field, data[cField]);
    }

    return true;
}

function getDataRecursive (field, data) {
    if (!has(data, field[0])) {
        return null;
    }

    const cField = field[0];

    if (field.length > 1) {
        field.shift();

        return getDataRecursive(field, data[cField]);
    } else {
        return data[cField];
    }
}
