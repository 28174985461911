const GLOBAL_PATH_API = '/api';
const GLOBAL_PATH_CORE = `${GLOBAL_PATH_API}/core`;

export const GET_ACCOUNT = `${GLOBAL_PATH_CORE}/account`;
export const SET_ACCOUNT = `${GLOBAL_PATH_CORE}/account`;
export const PUT_ACCOUNT = `${GLOBAL_PATH_CORE}/account`;
export const POST_ACCOUNT_RESEND_EMAIL = `${GLOBAL_PATH_CORE}/account/resend-registration`;
export const GET_ACCOUNT_TOTAL_BALANCE = `${GET_ACCOUNT}/get-balance`;
export const GET_ACCOUNT_TOTAL_EQUITY = `${GET_ACCOUNT}/get-agent`;
export const POST_ACCOUNT_TYPE = `${GLOBAL_PATH_CORE}/account-types`;
export const POST_ACCOUNT_STATUS = `${GLOBAL_PATH_CORE}/account-status`;
export const GET_ALL_ACCOUNT_DOCUMENTS = `${GLOBAL_PATH_CORE}/account-document/all`;
export const POST_ACCOUNT_CATEGORIES = `${GLOBAL_PATH_CORE}/account-categories`;
export const GET_COUNTRIES = `${GLOBAL_PATH_CORE}/country`;
export const GET_COUNTRIES_SELECT_MODEL = `${GET_COUNTRIES}/drop-down`;
export const GET_CITYES_SELECT_MODEL = `${GLOBAL_PATH_CORE}/city/drop-down`;
export const GET_LANGUAGE = `${GLOBAL_PATH_CORE}/language`;
export const GET_LANGUAGE_DROP_DOWN = `${GET_LANGUAGE}/drop-down`;
export const GET_TIME_ZONE = `${GLOBAL_PATH_CORE}/time-zone`;
export const GET_TICKETS_BY_CLIENT_ID = `${GLOBAL_PATH_API}/tickets/tickets/account-id`;
export const POST_MESSAGE_TEXT = `${GLOBAL_PATH_CORE}/account/email-send-text`;
export const GET_DESCRIPTIONS = `${GLOBAL_PATH_CORE}/account-descriptions/account-id`;
export const PUT_DESCRIPTION = `${GLOBAL_PATH_CORE}/account-descriptions`;
export const PUT_MOBILE_DISCOUNT = `${GLOBAL_PATH_CORE}/account`;
export const POST_CREATE_DESCRIPTION = `${GLOBAL_PATH_CORE}/account-descriptions`;

export const POST_ACCOUNT_CHANGE_EMAIL = `${GLOBAL_PATH_CORE}/account-change-email/change`;
export const POST_ACCOUNT_CANCEL_EMAIL = `${GLOBAL_PATH_CORE}/account-change-email/cancel`;
export const POST_ACCOUNT_CHANGE_PHONE = `${GLOBAL_PATH_CORE}/account-change-phone/change`;
export const POST_ACCOUNT_CANCEL_PHONE = `${GLOBAL_PATH_CORE}/account-change-phone/cancel`;

export const POST_ACCOUNT_PERSONAL_DATA_CANCEL = `${SET_ACCOUNT}/personal-data/cancel-request`;
export const POST_ACCOUNT_PERSONAL_DATA_CONFIRM = `${SET_ACCOUNT}/personal-data/confirm-request`;

export const POST_ACCOUNT_CHANGE_HISTORY = `${GLOBAL_PATH_CORE}/account-change-history`;
export const PREFIX_CHANGE_TRD = `change-tax-residency-data`;
export const POSTFIX_CHANGE_TRD_REJECT = `${PREFIX_CHANGE_TRD}/rejected`;
export const POSTFIX_CHANGE_TRD_CONFIRM = `${PREFIX_CHANGE_TRD}/wait_confirmation`;

export const POST_ACCOUNT_INDIVIDUAL_VERIFICATION = `${SET_ACCOUNT}/individual-cancellation-verification`;
export const POST_ACCOUNT_INDIVIDUAL_VERIFICATION_CONFIRM = `${POST_ACCOUNT_INDIVIDUAL_VERIFICATION}/confirmation`;
export const POST_ACCOUNT_INDIVIDUAL_VERIFICATION_CANCEL = `${POST_ACCOUNT_INDIVIDUAL_VERIFICATION}/cancel`;
export const GET_MAILS_BY_ACCOUNT_ID = `${GLOBAL_PATH_API}/log/mails/get-by-account-id`;
export const GET_MAIL_BY_ID = `${GLOBAL_PATH_API}/log/mails`;
export const DELETE_ACCOUNT_FROM_SPAM = `${GLOBAL_PATH_CORE}/mail/spark-post/delete-account-from-suppression-list`;
export const CHECK_ACCOUNT_FROM_SPAM = `${GLOBAL_PATH_CORE}/mail/spark-post/is-suppression-list`;
