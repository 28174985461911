import {
  itemsBuild,
  itemsDrain,
  itemsResort,
  CFG_ITEM_TYPE,
} from '../Config';
// import {} from '../Mentor/withDropDown';

export default {
  id: 200,
  ...[

    // filters
    ...[ {
      f: 'contests.contests.id',
      id: 'f:cId',
    }, {
      f: 'contests.contests.date_start',
      id: 'f:cStart',
    }, {
      f: 'contests.contests.date_end',
      id: 'f:cEnd',
    }, {
      f: 'contests.contests.status',
      id: 'f:cStatus',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

    // orders
    ...[ {
      f: 'contests.contests.status',
      id: 'o:cStatus',
      off: true, // TODO:
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

    // panels
    ...[ {
      ii: 'f:cId',
      off: true,
      t: 'magic_filter_translate_contest_id',
    }, {
      ii: 'f:cStart',
      off: true,
      t: 'magic_filter_translate_contest_date_start',
    }, {
      ii: 'f:cEnd',
      off: true,
      t: 'magic_filter_translate_contest_date_end',
    }, {
      ii: 'f:cStatus',
      off: true,
      t: 'magic_filter_translate_contest_status',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

  ]
  .map(itemsBuild)
  .reduce(itemsDrain, [])
  .reduce(itemsResort, {}),

  path: 'contests/contests',
  widget: false,
};