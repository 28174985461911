import React      from 'react';
import PropTypes  from 'prop-types';


export default class ResponsibleRow extends React.Component {
    static defaultProps = {
        avatar: '',
        name: '',
        surname: '',
    };

    static propTypes = {
        avatar: PropTypes.string,
        name: PropTypes.string,
        surname: PropTypes.string,
        index: PropTypes.number,
        onAdd: PropTypes.func,
    };

    renderAvatar = () => {
        const {avatar,} = this.props;
        const options = {className: 'responsible-avatar',};
        const image = (<img {...{alt: 'user', src: avatar,}} />);
        return (<div {...options}>{image}</div>);
    };

    renderSemibold = () => {
        const {name, surname,} = this.props;
        const options1 = {className: 'responsible-text',};
        const options2 = {className: 'semibold',};
        const render = (<div {...options2}>{name} {surname}</div>);
        return (<div {...options1}>{render}</div>);
    };

    renderAdd = () => {
        const {onAdd} = this.props;
        if (onAdd instanceof Function) {
            const options = {
                className: 'responsible-action',
                onClick: onAdd,
            };
            const image = <i {...{className: 'la la-plus add',}} />
            return (<div { ...options } >{image}</div>);    
        }
        return '';
    }

    render = () => {
        const options = {className: 'responsible-flex', key: this.props.index ?? 0,};
        const avatar = this.renderAvatar();
        const semibold = this.renderSemibold();
        const add = this.renderAdd();

        return (<div {...options}>{avatar}{semibold}{add}</div>);
    };

};
