/* eslint-disable no-tabs */
import React, { Component, } from "react";
import { withLocalize, } from "react-localize-redux";
import { withRouter, } from "react-router-dom";
import Moment from "moment";
import sortArrayOfObjectsByKey from "../Popup/helpers/sortArrayOfObjectsByKey";
import getEditDataForNewPartnersConditionPopup from "../../../../helpers/getEditDataForNewPartnersConditionPopup";

import {
	deleteNewPartnerConditionList, editCountryPayoutRateList,
	getNewPartnersList,
	getSingleNewPartnerConditionList,
} from "../../../../../../services/PartnersRequestService";
import {
	MagicFilterPanels,
	Mentor,
	MagicTable,
	MagicButton,
} from "../../../../../../components/Magic";
import Preloader from "../../../../../../components/LoadingHOC/Preloader";
import Loader from "../../../../../tima/components/Loader";
import PagePanel from "../../../../../tima/components/Pages";
import { MagicTooltip, } from "../../../../../tima/components/Magic/MagicTooltip";
import { MagicConfirm, } from "../../../../../tima/components/Magic/MagicConfirm";
import CreateUpdateNewPartnersConditions from "../Popup/CreateUpdateNewPartnersConditions";

const conditionStatus = Object.freeze({
	CLOSED: 0,
	NEW: 1,
	ACTIVE: 2,
});

class PayoutsPartnersRemunerationTable extends Component {
	constructor (props) {
		super(props);

		this.state = {
			loaded: false,
			data: [],
			pages: {
				filter: 0,
				total: 0,
				take: 50,
				variance: 2,
			},
			filterLastChange: Date.now(),
			newPartnerConditionId: null,
			isEmptyData: false,
			isViewMode: false,
		};

		const mf = new Mentor({
			owner: this,
			serviceId: 190,
			translate: this.props.translate,
		});

		Object.defineProperty(this, "mf", { get: () => mf, });
	}

	async componentDidMount () {
		this.mf.subscribe(
			{
				changed: this.mfChanged,
				cleaned: this.mfDone,
				cleaning: this.mfDoing,
				loaded: this.mfDone,
				loading: this.mfDoing,
				saved: this.mfDone,
				saving: this.mfDoing,
			},
			this,
		);

		await this.mf.init({ doEmit: true, });
	}

	componentWillUnmount () {
		this.mf.unsubscribe([
			this.mfChanged,
			this.mfDoing,
			this.mfDone,
		], this);
	}

	save = async state => new Promise(next => this.setState(state, next));

	onChange = async (name, value) => await this.save({ [name]: value, });

	params = () => this.props.match.params;

	query = () => new URLSearchParams(this.props.location.search);

		mfChanged = async () => {
			await this.save({ filterLastChange: Date.now(), });
		};

		mfDoing = async () => {
			await this.save({ loaded: false, });
		};

		mfDone = async () => {
			await this.mfChanged();
			await this.onDataLoad();
		};

		enumsChange = async (enums) => {
			if (enums.hash === this.state?.enums?.hash) {
				return `${ this.constructor.name }.enumsChange: false`;
			}
			await this.save({ enums, });

			return `${ this.constructor.name }.enumsChange: true`;
		};

	getEditRouteParameters = (levelId, typeId, conditionId) => {
		const level = levelId === 2 ? "Multiple" : "Single";

		let type =
			this.props.enums.data?.partners?.TypeEnum?.[+typeId].toLowerCase() ?? "-";

		type = type.includes("/") ? type.split("/").join("") : type; // in/out -> inout

		return `${ type }${ level }/${ conditionId }`;
	};	get payoutRateListTableConfig () {
		const { translate, } = this.props;
		const prefix = "new_partners_condition_";

		return [
			{
				path: [ "partners", "new_partners_conditions", "id", ],
				key: "conditionId",
				render: this.renderInitialValue,
				title: `${ prefix }id`,
				orderId: "o:id",
			},
			{
				path: [ "partners", "new_partners_conditions", "name", ],
				key: "conditionName",
				render: this.renderPayoutRateListName,
				title: `${ prefix }name`,
				orderId: "o:name",
			},
			{
				path: [ "partners", "new_partners_conditions", "level", ],
				key: "conditionLevel",
				render: this.renderConditionLevel,
				title: `${ prefix }level`,
				orderId: "o:level",
			},
			{
				path: [ "partners", "new_partners_conditions", "type", ],
				key: "conditionType",
				render: this.renderPayoutType,
				title: `${ prefix }type`,
				orderId: "o:type",
			},
			{
				path: [ "partners", "new_partners_conditions", "period", ],
				key: "conditionPeriod",
				render: this.renderConditionPeriod,
				title: `${ prefix }calculation_period`,
				orderId: "o:period",
			},
			{
				path: [ "partners", "new_partners_conditions", "latency", ],
				key: "conditionLatency",
				render: this.renderInitialValue,
				title: `${ prefix }delay_days`,
				orderId: "o:latency",
			},
			{
				path: [ "partners", "extra_cp_conditions", "referrer_country_check", ],
				key: "conditionDetermination",
				render: this.renderConditionCountryCheck,
				title: `${ prefix }country_determination`,
				orderId: "o:referrer_country_check",
			},
			{
				path: [ "partners", "new_partners_conditions", "status", ],
				key: "conditionStatus",
				render: this.renderPayoutStatus,
				title: `${ prefix }status`,
				orderId: "o:status",
			},
			{
				path: [ "partners", "new_partners_conditions", "description", ],
				key: "conditionDescription",
				render: this.renderInitialValue,
				title: `${ prefix }description`,
			},
			{
				path: [ "partners", "new_partners_conditions", "created_at", ],
				key: "conditionCreatedAt",
				render: this.renderInitialValue,
				title: `${ prefix }created_at`,
				orderId: "o:created",
			},
			{
				path: [ "partners", "new_partners_conditions", "updated_at", ],
				key: "conditionUpdatedAt",
				render: this.renderDate,
				title: `${ prefix }updated_at`,
				orderId: "o:updated",
			},
			{
				path: [ "partners", "new_partners_conditions", "id", ],
				key: "conditionActions",
				render: this.renderActions,
				title: `${ prefix }actions`,
				customAccessCheck: ({ item, }) => {
					return (
						item.access("show") &&
						(item.access("update") || item.access("destroy"))
					);
				},
			},
		].map(({
			orderId: o, title: t, xtitle: x, ...item
		}) => ({
			...item,
			...o && {
				order: async () => {
					await this.mf?.orderedToNext(o);
				},
				orderTo: this.mf?.orderedTo(o),
			},
			...t && { title: translate(t), },
			...x && { title: x, },
		}));
	}

	removePayoutList = async (
			newPartnerConditionId = this.state.newPartnerConditionId,
	) => {
		if (!newPartnerConditionId) {
			console.warn("payout rate list id not found");

			return;
		}

		try {
			await deleteNewPartnerConditionList(newPartnerConditionId);
			await this.onDataLoad();
		} catch (error) {
			error?.showErrorNotification?.();
		}
	};

	async openViewPopup (conditionParameters) {
		const { popup, } = this.props;
		const editFormData = await getEditDataForNewPartnersConditionPopup(conditionParameters);

		await this.save({ isViewMode: true, });
		await popup.togglePopup(editFormData);
	}

	renderPayoutRateListName = (name, { item, items, data, }) => {
		const {
			partners: { new_partners_conditions, },
		} = data;

		const conditionParameters = this.getConditionParameters(
			items,
			new_partners_conditions.id,
		);

		if (item?.access?.("index")) {
			return (
				<div
					className="payout-rate-list_pointer"
					onClick={ async () => this.openViewPopup(conditionParameters) }
				>
					<MagicTooltip
						classNameTooltip="tooltip tooltip--large-width"
						content={ name ?? "-" }
						lengthCut={ 20 }
					/>
				</div>
			);
		}

		return "-";
	};

		renderInitialValue = (value, { item, }) => {

			if (item?.access?.("index")) {
				return (
					<MagicTooltip
						classNameTooltip="tooltip tooltip--large-width"
						content={ value.toString() ?? "-" }
						lengthCut={ 20 }
					/>
				);
			}

			return "-";
		};

		renderDate = (date, { item, }) => {
			if (item?.access?.("index")) {
				const formatDB = "YYYY-MM-DD HH:mm:ss";
				const formatOut = "YYYY-MM-DD HH:mm:ss";

				date = Moment(date, formatDB);

				return date.isValid() ? date.format(formatOut) : "-";
			}

			return "-";
		};

		renderPayoutType = (type, { item, }) => {
			if (item.access("index")) {
				return this.props.enums.data?.partners?.TypeEnum?.[+type] ?? "-";
			}

			return "-";
		};
		renderConditionCountryCheck = (type, { item, }) => {
			if (item.access("index")) {
				return (
					this.props.enums.data?.partners?.CountryCheckTypeEnum?.[+type] ?? "-"
				);
			}

			return "-";
		};
		renderConditionLevel = (type, { item, }) => {
			if (item.access("index")) {
				return this.props.enums.data?.partners?.LevelEnum?.[+type] ?? "-";
			}

			return "-";
		};
		renderConditionPeriod = (type, { item, }) => {
			if (item.access("index")) {
				return this.props.enums.data?.partners?.PeriodEnum?.[+type] ?? "-";
			}

			return "-";
		};

		renderPayoutStatus = (status, { item, }) => {
			if (item.access("index")) {
				return (
					this.props.enums.data?.partners?.NewConditionStatusEnum?.[+status] ??
			"-"
				);
			}

			return "-";
		};

	getConditionParameters = (items, id) => {
		const level = items.conditionLevel.valueOf;
		const type = items.conditionType.valueOf;

		return this.getEditRouteParameters(level, type, id);
	};

	renderActions = (value, { item, items, data, }) => {
		const { translate, popup, } = this.props;
		const { newPartnerConditionId, } = this.state;

		const renderRemoveButton = () => {
			const options = {
				className: "magic-button__item magic-button__item_partners-delete-symbol margin--top-10",
				children: translate("symbols_actions_delete"),
				onClick: async () => await this.onChange("newPartnerConditionId", value),
			};

			return (
				<div className="magic-confirm-wrapper">
					<MagicConfirm
						accept={ translate("symbols_remove_accept") }
						closeByClickOutside={ true }
						isVisible={ Number(newPartnerConditionId) === Number(value) }
						reject={ translate("symbols_remove_reject") }
						title={ translate(`magic_confirm_title`) }
						onAccept={ async () =>
							await this.removePayoutList(newPartnerConditionId)
						}
						onReject={ async () =>
							await this.onChange("newPartnerConditionId", null)
						}
					/>
					<MagicButton { ...options } />
				</div>
			);
		};

		const renderEditButton = () => {
			const {
				partners: { new_partners_conditions, },
			} = data;

			const conditionParameters = this.getConditionParameters(
				items,
				new_partners_conditions.id,
			);

			const options = {
				className: "magic-button__item magic-button__item_partners-edit-symbol",
				children: translate("symbols_actions_edit"),
				onClick: async () => {
					const editFormData = await getEditDataForNewPartnersConditionPopup(conditionParameters);

					await popup.togglePopup(editFormData);
				 },
			};

			return (
				<div className="magic-confirm-wrapper">
					<MagicButton { ...options } />
				</div>
			);
		};

		return (
			<>
				{ item.access("update") && renderEditButton() }
				{ item.access("destroy") && renderRemoveButton() }
			</>
		);
	};

		renderTable = () => {
			const {
				loaded,
				pages,
				data,
				isEmptyData,
			} = this.state;
			const { translate, } = this.props;

			const options = {
				config: this.payoutRateListTableConfig,
				data,
				head: [
					"conditionId",
					"conditionName",
					"conditionLevel",
					"conditionType",
					"conditionPeriod",
					"conditionLatency",
					"conditionDetermination",
					"conditionStatus",
					"conditionDescription",
					"conditionCreatedAt",
					"conditionUpdatedAt",
					"conditionActions",
				],
				RowProps: ({ items, }) => {
					return {
						"data-disabled": [ conditionStatus.CLOSED, ].includes(
							items?.conditionStatus?.valueOf,
						),
					};
				},
			};

			return (
				<>
					<MagicFilterPanels mf={ this.mf } show={ true } translate={ translate } />
					<div
						className={ `table-wrapper partners__table ${
							isEmptyData ? "partners__table--empty" : ""
						}` }
					>
						<Loader loaded={ loaded } loading={ <Preloader scale={ 1 } /> }>
							<MagicTable { ...options } />
							{isEmptyData && (
								<div className="table__empty-text">
									{translate("symbols_empty")}
								</div>
							)}

							<PagePanel
								doText={ this.onPageText }
								doTo={ pageId => `?page=${ pageId }` }
								filter={ pages.filter }
								page={ this.pageId() }
								take={ pages.take }
								total={ pages.total }
								variance={ pages.variance }
								onClick={ this.onPageClick }
							/>
						</Loader>
					</div>
				</>
			);
		};

		render () {
			const { enums, popup, } = this.props;
			const { isViewMode, } = this.state;

			return (
				<>
					{popup.isOpen && (
						<CreateUpdateNewPartnersConditions
							enums={ enums }
							formData={ popup.data }
							isViewMode={ isViewMode }
							onClose={ async () => {
								await this.save({ isViewMode: false, });
								await popup.togglePopup();
							} }
							onDataLoad={ this.onDataLoad }
						/>
					)}
					{this.renderTable()}
				</>
			);
		}

		pageId = () => {
			const page = this.query().get("page");

			return Number(page) || 0;
		};

		pageIdAsGET = (pageId) => {
			pageId = pageId === undefined ? this.pageId() : pageId;
			const { filterId, } = this.mf;

			return {
				filterId,
				take: this.state.pages.take,
				skip: pageId * this.state.pages.take,
			};
		};

		onDataLoad = async (pageId) => {
			await this.save({
				loaded: false,
				isEmptyData: false,
			});

			const pageOptions = this.pageIdAsGET(pageId);
			const loaded = (data, meta) => ({ pages, }) => ({
				data,
				pages: { ...pages, ...meta, },
				...!data.length && { isEmptyData: true, },
			});

			try {
				const response = await getNewPartnersList(pageOptions);
				const {
					data = [],
					meta: {
						filter = 0,
						total = 0,
					},
				} = response;

				await this.save(loaded(data, {
					filter,
					total,
				}),
				);
			} catch (error) {
				await this.save(loaded([], {
					filter: 0,
					total: 0,
				}),
				);
				error?.showErrorNotification?.();
			}
			await this.save({ loaded: true, });
		};

		onPageClick = ({
			event,
			pageId,
			pageIs,
		}) => {
			this.onDataLoad(pageId);
		};

		onPageText = (pageId, pageIs) => {
			const { translate, } = this.props;
			const {
				current,
				first,
				prev,
				next,
				last,
			} = pageIs;
			const {
				skipped,
				taken,
				filter,
				total,
			} = pageIs;

			if (skipped || taken) {
				const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : "?";
				const text = skipped ? translate("partners_pages_items") : "";

				return skipped ? `${ text }: ${ id }` : id;
			} else if (filter || total) {
				const id = Number.isInteger(pageId) ? `${ pageId }` : "?";
				const text = translate(filter ? "partners_pages_filtered" : "partners_pages_total");

				return `${ text }: ${ id }`;
			} else if (first || prev || next || last) {
				return "";
			} else if (current) {
				return `${ pageId + 1 }`;
			}

			return `${ pageId + 1 }`;

		};
}

export default withRouter(withLocalize(PayoutsPartnersRemunerationTable));