const linkById = (link, id) => `${link}/${id}`;
const textById = (translate, key, id) => `${translate(key)} ${id}`;

const ContestsLinkConfig = (translate, order, {
    active,
    contestId,
} = {}) => [
    {
        id: 'contest',
        link: linkById('/contests/contest', contestId),
        text: textById(translate, 'contests_link_contest', contestId),
    },
    {
        id: 'contests',
        link: '/contests',
        text: translate('contests_bradcrumbs'),
    },
]
    .map((_) => {
        const { id } = _;
        const index = [...order].indexOf(id);
        if (index < 0) {
            return false;
        } else if (!active) {
            return { item: { ..._ }, index };
        } else {
            return { item: { ..._, active: id == active }, index };
        }
    })
    .filter(_ => _)
    .sort((a, b) => a.index - b.index)
    .map(({ item }) => item)
    .map(({ id, link, text, active }) => ({ link, text, key: id, active }));

export default ContestsLinkConfig;
