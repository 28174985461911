import React, { Component, } from "react";
import { withRouter, Link, } from "react-router-dom";
import { withLocalize, } from "react-localize-redux";
import PropTypes from "prop-types";

import { MagicTooltip, } from "../../../../../tima/components/Magic/MagicTooltip";
import { MagicTable, } from "../../../../../../components/Magic";

import Preloader from "../../../../../../components/LoadingHOC/Preloader";

const status = Object.freeze({
	"1": "active",
	"2": "frozen",
	"3": "archive",
	"4": "blocked",
	"5": "deleted",
});

class PartnersListMagicTable extends Component {
	static propTypes = {
		data: PropTypes.array,
		enums: PropTypes.object,
		// eslint-disable-next-line react/sort-prop-types
		head: PropTypes.array.isRequired,
		mf: PropTypes.object.isRequired,
		onDataLoad: PropTypes.func.isRequired,
	};

	static defaultProps = {
		data: [],
		enums: {},
		head: [],
	};

	get enums () {
		return this.props?.enums ?? {};
	}

	get mf () {
		return this.props.mf;
	}
	get params () {
		return this.props?.match?.params;
	}

	get query () {
		return new URLSearchParams(this.props?.location?.search);
	}

	get config () {
		const { translate, } = this.props;
		const prefix = "partners_list_";

		return [
			{
				key: "accountName",
				path: [ "core", "account", "name", ],
			},
			{
				key: "accountSurname",
				path: [ "core", "account", "surname", ],
			},
			{
				key: "accountStatus",
				path: [ "core", "account", "status", ],
			},
			{
				key: "accountCountry",
				path: [ "core", "country", "name", ],
			},
			{
				key: "userName",
				path: [ "core", "user", "name", ],
			},
			{
				key: "userSurname",
				path: [ "core", "user", "surname", ],
			},
			{
				key: "partnerId",
				orderId: "o:id",
				path: [ "core", "account", "id", ],
				render: this.renderInlineValue,
				title: `${ prefix }partner_id`,
			},
			{
				key: "partnerManager",
				orderId: "o:uId",
				path: [ "core", "user", "id", ],
				render: this.renderPartnerManager,
				title: `${ prefix }partner_manager`,
			},
			{
				key: "partnerName",
				orderId: "o:partnerName",
				path: [ "partners", "partners_setup", "id", ],
				render: this.renderPartnerName,
				title: `${ prefix }partner_name`,
			},
			{
				key: "partnerAccount",
				orderId: "o:partnerAccount",
				path: [ "core", "system_accounts", "id", ],
				render: this.renderPartnerAccount,
				title: `${ prefix }partner_account`,
			},
			{
				key: "partnerCountry",
				orderId: "o:accCountryId",
				path: [ "core", "country", "id", ],
				render: this.renderPartnerCountry,
				title: `${ prefix }partner_country`,
			},
			{
				key: "partnerStatus",
				orderId: "o:status",
				path: [ "partners", "partners_setup", "status", ],
				render: this.renderPartnerStatus,
				title: `${ prefix }partner_status`,
			},
			{
				key: "partnerCode",
				orderId: "o:partnerReferralCode",
				path: [ "partners", "referral_code", ],
				render: this.renderPartnerCode,
				title: `${ prefix }partner_code`,
			},
			{
				key: "partnerRenumeration",
				orderId: "o:partnerRenumeration",
				path: [ "partners", "daily_statistic", "total_commission", ],
				render: this.renderInlineValue,
				title: `${ prefix }partner_renumeration`,
			},
			{
				key: "partnerReferrals",
				orderId: "o:partnerReferrals",
				path: [ "partners", "referral_register", ],
				render: this.renderPartnerReferrals, // TODO change to renderInlinValue
				title: `${ prefix }partner_referrals`,
			},
			{
				key: "partnerRegistered",
				orderId: "o:partnerRegistered",
				path: [ "partners", "partners_setup", "created_at", ],
				render: this.renderDate,
				title: `${ prefix }partner_registration_date`,
			},
			{
				key: "partnerConfirmated",
				orderId: "o:partnerConfirmated",
				path: [ "partners", "requests", "approved_date", ],
				render: this.renderDate,
				title: `${ prefix }partner_confirmation_date`,
			},
		].map(({
			orderId: o, title: t, xtitle: x, ...item
		}) => ({
			...item,
			...o && {
				order: async () => {
					await this.mf.orderedToNext(o);
				},
				orderTo: this.mf.orderedTo(o),
			},
			...t && { title: translate(t), },
			...x && { title: x, },
		}));
	}

	renderInlineValue = (value, { item, }) => {
		if (item.access("index")) {
			return value ?? "-";
		}

		return "-";
	};

	renderDate = (value, { item, }) => {
		//needed to render date and time in separate lines
		if (item.access("index")) {
			if (!value) {
				return "-";
			}

			return value.split(" ").map(index => (
				<span className="table__date" key={ index }>
					{index}
				</span>
			));
		}

		return "-";
	};

	renderPartnerStatus = (value, { item, items, }) => {
		const { translate, } = this.props;

		if (item.access("index")) {
			const statusByCode = Object.values(status).filter(
				// eslint-disable-next-line no-param-reassign, no-plusplus, no-shadow
				(status, index) => ++index === +value,
			);
			const prefix = "partners_list_partner_status_";

			if (!statusByCode) {
				return "-";
			}

			const isArchived =
				items?.accountStatus.valueOf === 3 || statusByCode[0] === "archive"; //either role or account is archived

			return isArchived ? (
				<span className="table__archived">
					{translate(prefix + statusByCode)}
				</span>
			) :
				translate(prefix + statusByCode)
			;
		}

		return "-";
	};

	renderPartnerCode = (value, { item, }) => {
		if (item?.access?.('index')) {
			if (value === null) {
				return "-";
			}
			if (value.length === 1) {
				return value.map(code => code.ref_short).slice(0, 1);
			}

			const oneItemCode = value.map(code => code.ref_short).slice(0, 1);

			return (
				<div className="partners_magic_table_code_tooltip">
					{ oneItemCode } ...
					<ul className="partners_magic_table_code_tooltip_all">{value.map((code, i) => <li key={i}>{code.ref_short},</li>)}</ul>
				</div>
			);
		}

		return "-";
	};

	renderPartnerAccount = (value, { item, }) => {
		if (item.access("index")) {
			return value ? (
				<Link to={ `/list-accounts/systems/${ value }` }>{value}</Link>
			) :
				"-"
			;
		}

		return "-";
	};

	renderPartnerReferrals = (value, { item, }) => {
		//* TODO change refferals to renderInlineValue
		if (item.access("index")) {
			return value === null ? "-" : value.referral_count;
		}

		return "-";
	};

	renderPartnerName (value, { item, items, }) {
		const name = items?.accountName.valueOf ?? "";
		const surname = items?.accountSurname.valueOf ?? "";
		const fullName = `${ name } ${ surname }`;

		if (
			item?.access("index") &&
			items?.userName?.access?.("index") &&
			items?.userSurname?.access?.("index")
		) {
			return (
				<Link to={ `/partners/requests/partners-list/${ value }` }>
					<MagicTooltip
						classNameTooltip={ `tooltip tooltip--large-width` }
						content={ fullName }
						lengthCut={ 20 }
					/>
				</Link>
			);
		} else if (
			items?.userName?.access?.("index") &&
			items?.userSurname?.access?.("index")
		) {
			return fullName;
		}
	}

	renderPartnerManager = (value, { item, items, }) => {
		const name = items?.userName.valueOf ?? "";
		const surname = items?.userSurname.valueOf
			? items.userSurname.valueOf
			: "-"; //otherwise field gonna be empty if blank manager data;
		const fullName = `${ name } ${ surname }`;

		if (
			item?.access("index") &&
			items?.userName?.access?.("index") &&
			items?.userSurname?.access?.("index")
		) {
			return (
				<Link to={ `/settings/user/${ value }` }>
					<MagicTooltip
						classNameTooltip={ `tooltip tooltip--large-width` }
						content={ fullName }
						lengthCut={ 20 }
					/>
				</Link>
			);
		} else if (
			items?.userName?.access?.("index") &&
			items?.userSurname?.access?.("index")
		) {
			return fullName.trim() ? fullName : "-";
		}
	};

	renderPartnerCountry (value, { item, items, }) {
		const country = items?.accountCountry.valueOf ?? "";

		if (item?.access("index") && items?.accountCountry?.access?.("index")) {
			return `${ country }`;
		} else if (items?.accountCountry?.access?.("index")) {
			return `${ country }`;
		}
	}

	renderPartnersListMagicTableTable () {
		const {
			loaded, data, head, translate,
		} = this.props;

		if (!loaded) {
			return <Preloader />;
		}
		if (data.length === 0) {
			return <p className="mx-auto">{translate("symbols_empty")}</p>;
		}

		const options = {
			config: this.config,
			data,
			head,
		};

		return (
			<>
				<MagicTable { ...options } />
			</>
		);
	}
	render () {
		return (
			<div className="partners-partners__table">
				<div className="partners__table">
					{this.renderPartnersListMagicTableTable()}
				</div>
			</div>
		);
	}
}

export default withRouter(withLocalize(PartnersListMagicTable));