import React, { Component } from 'react';
import { withLocalize, Translate } from "react-localize-redux";

import { Table } from 'reactstrap';
import { forEach, map, startCase, toLower } from "lodash";
import TablePermissionData from "./TablePermissionData";

class TablePermission extends Component {
    constructor (props) {
        super(props);
    }

    checkField = (field, name) => {
        return (
            <Translate key={field}>
                {({ translate }) => {
                    return (
                        <th key = { field }>
                            <div>{startCase(toLower(translate(`template_permission_${name}`)))}&nbsp;
                                {name !== 'action' ? <i className = 'fa fa-angle-down' /> : ''}
                            </div>
                        </th>);
                }}
            </Translate>
        );
    };

    renderTH = () => {
        const tableHeads = [];

        forEach(this.props.fieldsForChecking, (name, item) => {
            tableHeads.push(this.checkField(item, name));
        });

        return tableHeads;
    };

    renderBodyRow = (translate) => {
        return map(this.props.dataAfterLoading.data, (item, key) => {
            return (<TablePermissionData
                toggleRemoveTemplate = {this.props.toggleRemoveTemplate}
                data = { item }
                key = { key }
                loadPermissionTemplates = { this.props.loadPermissionTemplates }
                togglePermission = { this.props.togglePermission }
                translate = { translate }
            />);
        });
    };

    render () {
        const tableTH = this.renderTH();
        return (
            <Translate>
                {({ translate }) => {
                    return (
                        <div className = 'table bordered-rounded'>
                            <Table>
                                <thead>
                                    <tr>
                                        {tableTH.map((item) => {
                                            return item;
                                        })}
                                    </tr>
                                </thead>
                                <tbody className = 'tbody'>
                                    {this.renderBodyRow(translate)}
                                </tbody>
                            </Table>
                        </div>
                    );
                }}
            </Translate>
        );
    }
}

TablePermission.defaultProps = {
    fieldsForChecking: {
        'name':            'template_name',
        'number_of_users': 'number_of_users',
        'action':          'action',
    },
};

export default withLocalize(TablePermission);
