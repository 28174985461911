import {
	itemsBuild, itemsDrain, itemsResort, CFG_ITEM_TYPE,
} from "../Config";
import { onEnabled, render, } from "../Mentor/withDropDown";

const f_country = {
	"drop-down": "/api/core/country/drop-down?take=all",
	"o=": [ "=", "!=", ],
};

const p_country = {
	onEnabled: onEnabled("countries"),
	render: render("countries"),
};

export default {
	id: 187,
	...[
		// filters
		...[
			{
				f: "core.account.id",
				id: "f:referralId",
			},
			{
				f: "core.account.partner_id",
				id: "f:referralPartnerLevelOne",
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: "filter", ..._, })),

		// orders
		...[
			{
				f: "core.account.id",
				id: "o:referralId",
			},
			{
				f: "core.account.surname",
				id: "o:referralName",
			},
			{
				f: "core.account.partner_id",
				id: "o:referralPartnerLevelOne",
			},
			{
				f: "partners.referral_register.is_partner",
				id: ":referralPartner",
			},
			{
				f: "partners.referral_register.attachment_date",
				id: "o:referralRegister",
			},
			{
				f: "core.account.country_id",
				id: "o:referralCountry",
			},
			{
				f: "partners.referral_register.status",
				id: "o:referralStatus",
			},
			{
				f: "partners.referral_register.all_sum",
				id: "o:referralRemunerations",
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: "order", ..._, })),

		// panels
		...[
			{
				ii: "f:referralId",
				t: "partners_referral_id",
			},
			{
				ii: "f:referralPartnerLevelOne",
				t: "partners_referral_level_one_partner",
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: "panel", ..._, })),
	]
		.map(itemsBuild)
		.reduce(itemsDrain, [])
		.reduce(itemsResort, {}),

	path: "partners/referrals",
	widget: false,
};