export const verticalMenuLang = {
    "menu_dashboard": ["Dashboard", "Панель управления", "Панель управління",],
    "menu_conversations":               [ "Conversations", "Разговоры", "Розмови",],
    "menu_clients": ["Clients", "Клиенты", "Клієнти",],
    "menu_tasks": ["Tasks", "Задачи", "Задачі",],
    "menu_calendar": ["Calendar", "Календарь", "Календар",],
    "menu_reports": ["Reports", "Отчеты", "Звіти",],
    "menu_dialogs": ["Dialogs", "Диалоги", "Діалоги",],
    "menu_admin_panel": ["Admin Panel", "Панель администратора", "Панель адміністратора",],
    "menu_user": ["User", "Пользователь", "Користувач",],
    "menu_kpi_settings": ["KPI Settings", "Настройки KPI", "Налаштування KPI",],
    "distribution_settings": ["Conversion leads distribution settings", "Настройки распределения лидов для Конверсии", "Налаштування розподілу лідів для Конверсії"],
    "menu_landing": ["Landing", "Landing", "Landing",],
    "menu_transactions": ["Transactions", "Операции", "Операції",],
    "menu_calendar_test": ["Calendar Test", "Calendar Test", "Calendar Test",],
    "menu_callstat": ["Calls statistic", "Статистика звонков", "Статистика дзвінків",],
    "menu_tree": ["Tree", "Дерево", "Дерево",],
    "template_management": ["Template Management", "Управление шаблонами", "Керування шаблонами",],
    "template_permission": ["Template Permission", "Управление правами доступа", "Керування правами доступу",],
    "list__accounts": ["List of accounts", "Cписок счетов", "Список рахунків",],
    "list_accounts_system": ["System accounts", "Системные счета", "Системні рахунки",],
    "list_accounts_trading": ["Trading accounts", "Торговые счета", "Торгові рахунки",],
    "list_accounts_corporate_action": ["Corporate Action[en]", "Corporate Action[ru]", "Corporate Action[ua]",],
    // TODO: check tima menu
    "menu_tima": ["TIMA", "TIMA", "TIMA",],
    "menu_tima_strategies": ["Strategies", "Стратегии", "Стратегії",],
    "menu_tima_investments": ["Investments", "Инвестиции", "Інвестиції",],
    "menu_tima_conditions": ["Conditions", "Условия", "Умови",],
    "menu_tima_agents": ["Agents", "Агенты", "Агенти",],
    // TODO: check partners menu
    "menu_partners": ["Affiliate system", "Партнерская система", "Партнерська система",],
    "menu_partners_list": ["Partners", "Партнеры", "Партнери",],
    "menu_partners_referrer": ["Referrers", "Рефералы", "Реферали",],
    "menu_partners_profit": ["Profit statistics", "Статистика начислений", "Статистика нарахувань",],
    "menu_partners_payment": ["Payments requests", "Заявки на выплаты", "Заяви на виплати",],
    "menu_partners_groups_lists": ["MT Group Lists", "Списки MT-Групп", "Списки MT-Груп",],
    "menu_partners_symbols_lists": ["Symbol Lists", "Списки символов", "Списки символів",],
    "menu_partners_conditions": ["Conditions", "Условия", "Умови",],
    "menu_partners_system_affiliate_programs_management": ["Affiliate programs management", "Управление партнерскими программами", "Керування партнерськими програмами"],
    "menu_partners_system_affiliate_payouts": ["Affiliate Payouts", "Партнерские выплаты", "Партнерські виплати"],
    "menu_partners_system_affiliate_programs": ["Affiliate programs", "Партнерские программы", " Партнерські програми",],
    "menu_partners_system_conditions_for_accruals": ["Conditions for accruals and payouts", "Условия начислений и выплат", "Умови нарахувань та виплат"],
    "menu_partners_system_country_list_with_payouts_rates": ["Country lists with payout rates", "Списки стран со ставками выплат", "Списки країн зі ставками виплат"],
    "menu_partners_system_symbols": ["Symbols", "Символы", "Cимволи"],
    "menu_partners_system_symbols_groups": ["Symbols groups", "Группы символов", "Групи символів"],
    "menu_partners_system_symbols_lists": ["Symbol Lists", "Списки символов", "Списки символів"],
    "menu_partners_system_account_groups_list": ["Account groups lists", "Списки групп счетов", "Списки груп рахунків"],
    "menu_partners_system_history_of_all_payments": ["History of all payments", "История всех выплат", "Історія всіх виплат"],
    "menu_partners_system_partners_list": ["Partners list", "Список партнеры", "Список партнерів"],
    "menu_partners_system_partnership_applications": ["Partnership requests", "Заявки на партнерство", "Заявки на партнерство"],
    "menu_partners_system_referrer_list": ["Referrers list", "Список рефералов", "Список рефералів"],
    "menu_partners_system_statistic": ["Statistic", "Статистика", "Статистика",],
    "menu_partners_system_dashboard_calculation": ["Calculations dashboard", "Дашборд расчетов", "Дашборд розрахунків",],
    "menu_partners_system_dashboard_calculation_payout": ["Payouts dashboard", "Дашборд выплат", "Дашборд виплат",],
    "menu_partners_system_waiting_switch": ["Partners waiting to switch", "Партнеры ожидающие переключения", "Партнери очікуючі переключення",],
    "menu_partners_referrals_waiting_switch": ["Referrals waiting to be added", " Рефералы ожидающие добавления", "Реферали очікуючі додавання",],
    "menu_partners_system_statistic_click": ["Click-through statistics", "Статистика по переходам", "Статистика по переходам",],


    // TODO: input and output requests
    "menu_payments": ["Financial transactions", "Финансовые транзакции", "Фінансові транзакції"],
    "menu_payments_list": ["Financial transactions list", "Список финансовых транзакций", "Список фінансових транзакцій"],
    // TODO: input and output requests
    "menu_contests": ["Contests", "Конкурсы", "Конкурси"],
    "menu_support": ["Support", "Поддержка", "Підтримка"],
};
