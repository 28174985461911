import React, { Component, } from 'react';
import { withRouter, } from "react-router-dom";
import { withLocalize, } from "react-localize-redux";
import PagePanel from "../../../../tima/components/Pages";
// import LeadPaymentsTable from './LeadPaymentsTable';
import ListPaymentsMagicTable from '../../../../payments/domains/ListPayments/components/ListPaymentsMagicTable';
import Loader from '../../../../tima/components/Loader';
import Preloader from '../../../../../components/LoadingHOC/Preloader';
import { getTransactions, } from '../../../services/AccountRequestService';

class LeadPayments extends Component {
	constructor (props) {
		super(props);
		this.state = {
			pages: {
				filter: 0,
				total: 0,
				take: 50,
				variance: 2,
			},
			loaded: false,
		};
	}

    componentDidMount = async () => {
    	await this.onDataLoad();
    }

    get params () {
    	return this.props?.match?.params;
    }

    get query () {
    	return new URLSearchParams(this.props?.location?.search);
    }

    save = state => new Promise(next => this.setState(state, next));

    onDataLoad = async (pageId) => {
    	await this.save({ loaded: false, });
    	const pageOptions = this.pageIdAsGET(pageId);
    	const loaded = (data, meta) => ({ pages, }) => ({
    		data,
    		pages: { ...pages, ...meta, },
    	});

    	try {
    		const response = await getTransactions(this.props?.clientId, pageOptions);
    		const { data = [], meta: { filter = 0, total = 0, }, } = response;

    		await this.save(loaded(data, { filter, total, }));
    	} catch (error) {
    		console.log("error: ", error);
    		await this.save(loaded([], { filter: 0, total: 0, }));
    	}
    	await this.save({ loaded: true, });
    };

     pageId = () => {
     	const page = this.query.get('page');

     	return Number(page) || 0;
     };

     pageIdAsGET = (pageId) => {
     	pageId = pageId === undefined ? this.pageId() : pageId;
        // const filterId = this.service.filterId;
     	const result = {
        //    filterId,
     		take: this.state.pages.take,
     		skip: pageId * this.state.pages.take,
     	};

     	return result;
     };

     onPageClick = ({ event, pageId, pageIs, }) => {
     	this.onDataLoad(pageId);
     };

     onPageText = (pageId, pageIs) => {
     	const { translate, } = this.props;
     	const {
     		current, first, prev, next, last,
     	} = pageIs;
     	const {
     		skipped, taken, filter, total,
     	} = pageIs;

     	if (skipped || taken) {
     		const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : '?';
     		const text = skipped ? translate('payment_pages_items') : '';

     		return skipped ? `${ text }: ${ id }` : id;
     	} else if (filter || total) {
     		const id = Number.isInteger(pageId) ? `${ pageId }` : '?';
        //    const text = translate(filter ? 'payment_pages_filtered' : 'payment_pages_total');
     		const text = translate('payment_pages_total');

     		return `${ text }: ${ id }`;
     	} else if (first || prev || next || last) {
     		return '';
     	} else if (current) {
     		return `${ pageId + 1 }`;
     	}

     	return `${ pageId + 1 }`;

     };

     render () {
     	const { pages, loaded, } = this.state;

     	return (
	<div className="payments">
	<div className="payments-table">
	<Loader loaded={ loaded } loading={ <Preloader scale={ 1 } /> }>
	<ListPaymentsMagicTable
	departmentsListNames={ this.props?.departmentsListNames }
	data={ this.state?.data }
	refreshData={ this.onDataLoad }
	enums={ this.props?.enums }
     					/>
	<PagePanel
	showSingle={ false }
	filter={ pages.filter }
	take={ pages.take }
	total={ pages.total }
	variance={ pages.variance }
	page={ this.pageId() }
	onClick={ this.onPageClick }
	doText={ this.onPageText }
	doTo={ pageId => `?page=${ pageId }` }
     					/>
     				</Loader>
     			</div>
     		</div>
     	);
     }
}

export default withLocalize(withRouter(LeadPayments));