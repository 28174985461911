import LinkConfig from "./LinkConfig";

export const breadcrumbsForActivityReport = (translate) => {
	// eslint-disable-next-line new-cap
	return LinkConfig(
		translate,
		[
			"admin",
			"activity-report",
		],
		{},
	);
};