import React, { Component } from 'react';

class RangeSlider extends Component {
    constructor (props) {
        super(props);
        this.state = {
            value: props.initialValue,
        };
    }

    componentDidMount () {
        this.updateBackground();
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        this.updateBackground();
    }

    updateBackground = () => {
        const { initialValue, maxValue, minValue } = this.props;

        const sliderElem = document.getElementById(this.props.sliderId);
        const sliderValueCont = sliderElem.parentElement.querySelector('.slider-value');
        const widthRange = sliderElem.clientWidth;
        const widthPointer = sliderValueCont.clientWidth;
        //const sliderValue =  sliderElem.value;
        const sliderValue = +initialValue;
        const deltaVal = (maxValue - minValue);
        const sliderValueAbsOrigin = sliderValue >= maxValue ? 100 : sliderValue <= minValue ? 0 : (sliderValue - minValue) / deltaVal * 100;

        const relPointerSize = widthPointer / widthRange * 100;
        const sliderValueAbs = sliderValueAbsOrigin > 100 - relPointerSize ? 100 - relPointerSize : sliderValueAbsOrigin;
        const bgColor = 'linear-gradient(90deg, #ff1d33 ' + sliderValueAbs + '% , #ececed ' + sliderValueAbs + '%)';

        sliderElem.style.background = bgColor;
        sliderValueCont.style.left = sliderValueAbs + '%';

        this.setState({ value: sliderValue });
    };

    handleChange = (event) => {
        // disabled
        // this.setState({ value: event.target.value });
        // this.updateBackground();
    };

    render () {
        const { sliderContClass = '', sliderId, minValue, maxValue, step, symbol, isSymbolShown } = this.props;
        const { value } = this.state;
        const sliderCurrentValueText = !symbol ? value : `${value}\u00A0${symbol}`;
        const sliderMinValueText = !(isSymbolShown && symbol) ? minValue : `${minValue}\u00A0${symbol}`;
        const sliderMaxValueText = !(isSymbolShown && symbol) ? maxValue : `${maxValue}\u00A0${symbol}`;

        return (
            <div className='slider-wrapper'>
                <span className='slider-range-value min-value'>{sliderMinValueText}</span>
                <div className={`slider-cont ${sliderContClass}`}>
                    <input
                        className='slider'
                        id={sliderId}
                        type='range'
                        min={minValue}
                        max={maxValue}
                        value={value}
                        onChange={this.handleChange}
                        step={step}/>
                    <span className={`slider-value`}>{sliderCurrentValueText}</span>
                </div>
                <span className='slider-range-value max-value'>{sliderMaxValueText}</span>
            </div>
        );
    }
}

export default (RangeSlider);
