import React from 'react';
import routes from './routes';
import Store from "./store";
import App from './App';

class IndexApp extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            token: this.props.match.params.token,
        };

    }

    render () {
        return (
            <App { ...this.props }>
                {routes(Store)}
            </App>
        );
    }
}

export default IndexApp;
