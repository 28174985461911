import React, { Component, } from "react";
import { Link, withRouter, } from "react-router-dom";
import { withLocalize, } from "react-localize-redux";

import { MagicTable, Mentor, MagicFilterPanels, } from "../../../../../components/Magic";
import PagePanel from "../../../../tima/components/Pages";
import Preloader from "../../../../../components/LoadingHOC/Preloader";

import { showExportToExcelError, } from "../../../../../components/Magic";
import { listAccountService, } from "../../../../../services/ListAccountDataService";
import { enumService, } from "../../../../../services/EnumDataService";

class BalanceOperationsTable extends Component {
	constructor (props) {
		super(props);

		this.state = {
			data: [],
			filterLastChange: Date.now(),
			isEmptyData: false,
			loaded: false,
			meta: {},
			pages: {
				filter: 0,
				take: 50,
				total: 0,
				variance: 2,
			},

		};

		const mf = new Mentor({
			owner: this,
			serviceId: 121,
			translate: this.props.translate,
		});

		Object.defineProperty(this, "mf", { get: () => mf, });
	}

	async componentDidMount () {
		enumService.subscribe('enums', this.enumsChange, this);
		this.mf.subscribe(
			{
				changed: this.mfChanged,
				cleaned: this.mfDone,
				cleaning: this.mfDoing,
				loaded: this.mfDone,
				loading: this.mfDoing,
				saved: this.mfDone,
				saving: this.mfDoing,
			},
			this,
		);

		await this.mf.init({ doEmit: true, });
	}

	componentWillUnmount () {
		enumService.unsubscribe('enums', this.enumsChange, this);
	}
	save = state => new Promise(next => this.setState(state, next));

	onChange = (name, value) => this.save({ [name]: value, });

	params = () => this.props.match.params;

	mfChanged = async () => {
		const { handleFitlerIdChange, } = this.props;
		const { filterId: filter_id, } = this.mf;

		await handleFitlerIdChange(filter_id);
		await this.save({ filterLastChange: Date.now(), });
	};

	mfDoing = async () => {
		await this.save({ loaded: false, });
	};

	mfDone = async () => {
		await this.mfChanged();
		await this.onDataLoad();
	};

	enumsChange = async (enums) => {
		if (enums.hash === this.state?.enums?.hash) {
			return `${ this.constructor.name }.enumsChange: false`;
		}
		await this.save({ enums, });

		return `${ this.constructor.name }.enumsChange: true`;
	};
	componentWillUnmount = () => {
		this.mf.unsubscribe([ this.mfChanged, this.mfDoing, this.mfDone, ], this);
	};

	query = () => new URLSearchParams(this.props.location.search);

	onDataLoad = async (pageId) => {
		await this.save({ isEmptyData: false, loaded: false, });

		const pageOptions = this.pageIdAsGET(pageId);
		const loaded = (data, meta) => ({ pages, }) => ({
			data,
			pages: { ...pages, ...meta, },
			...!data.length && { isEmptyData: true, },
		});

		try {
			const response = await listAccountService.getBalanceOperations(pageOptions);
			const {
				data = [],
				meta: { filter = 0, total = 0, },
			} = response;

			await this.save(loaded(data, { filter, total, }));
		} catch (error) {
			await this.save(loaded([], { filter: 0, total: 0, }));
			showExportToExcelError(error);
		}
		await this.save({ loaded: true, });
	};

	get config () {
		const { translate, } = this.props;
		const prefix = "balance_operations_";

		return [
			{
				key: "clientId",
				path: [ "core", "account", "id", ],
			},
			{
				key: "clientSurname",
				path: [ "core", "account", "surname", ],
			},
			{
				key: "transactionId",
				orderId: "o:transactionId",
				path: [ "core", "system_accounts_transaction_history", "id", ],
				render: this.renderInlineValue,
				title: `${ prefix }id`,
			},
			{
				key: "client",
				orderId: "o:transactionClient",
				path: [ "core", "account", "name", ],
				render: this.renderClientName,
				title: `${ prefix }client`,
			},
			{
				key: "systemAccountId",
				orderId: "o:transactionSystemAccount",
				path: [ "core", "system_accounts_transaction_history", "system_account_id", ],
				render: this.renderSystemAccount,
				title: `${ prefix }system_account`,
			},
			{
				key: "amount",
				orderId: "o:transactionAmount",
				path: [ "core", "system_accounts_transaction_history", "amount", ],
				render: this.renderInlineValue,
				title: `${ prefix }amount`,
			},
			{
				key: "comment",
				orderId: "o:transactionComment",
				path: [ "core", "system_accounts_transaction_history", "comment", ],
				render: this.renderInlineValue,
				title: `${ prefix }comment`,
			},
			{
				key: "created_at",
				orderId: "o:transactionCreated_at",
				path: [ "core", "system_accounts_transaction_history", "created_at", ], //date
				render: this.renderDate,
				title: `${ prefix }created_at`,
			},
			{
				key: "updated_at",
				orderId: "o:transactionUpdated_at",
				path: [ "core", "system_accounts_transaction_history", "updated_at", ], //date
				render: this.renderDate,
				title: `${ prefix }updated_at`,
			},

		].map(({
				   orderId: o, title: t, xtitle: x, ...item
			   }) => ({
			...item,
			...o && {
				order: async () => {
					await this.mf.orderedToNext(o);
				},
				orderTo: this.mf.orderedTo(o),
			},
			...t && { title: translate(t), },
			...x && { title: x, },
		}));
	}

	renderInlineValue = (value, { item, items, }) => {
		if (item.access("index")) {
			return value ?? "-";
		}

		return "-";
	};

	renderDate = (value, { item, }) => {
		if (item.access("index")) {
			//date and time on separate lines
			return value.split(' ').map(index => <div>{index}</div>);
		}

		return "-";
	};

	renderClientName = (value, { item, items, }) => {
		if (item.access("index")) {
			const accountId = items.clientId.valueOf;
			const surname = items.clientSurname?.valueOf;
			const fullname = `${ value ?? '-' } ${ surname ?? '' }`;

			return accountId ? <Link to={ `/clients/${ accountId }` }>{fullname}</Link> : value;
		}

		return "-";
	}

	renderSystemAccount = (value, { item, items, }) => {
		if (item.access("index")) {
			return value ? <Link to={ `/list-accounts/systems/${ value }` }>{value}</Link> : '-';
		}

		return "-";
	}
	renderPartnersListMagicTableTable () {
		const { translate, } = this.props;
		const { loaded, data, } = this.state;

		const head = [
			"transactionId",
			"client",
			"systemAccountId",
			"amount",
			"comment",
			"created_at",
			"updated_at",
		];

		if (!loaded) {
			return <Preloader />;
		}
		if (data.length === 0) {
			return <h4 className="mx-auto text-align-center pt-5">{translate("empty_table_data")}</h4>;
		}

		const options = {
			config: this.config,
			data,
			head,
		};

		return (
			<>
				<MagicTable { ...options } />
			</>
		);
	}

	render () {
		const { pages, } = this.state;
		const { translate, getPermissions, } = this.props;

		const showPagePannel = pages.total > pages.take;

		const permissionToShow = getPermissions('index');

		return (
			<>
				{ permissionToShow &&
					<div>
						<MagicFilterPanels
							mf={ this.mf }
							show={ true }
							translate={ translate }
						/>
						{this.renderPartnersListMagicTableTable()}
						{
							showPagePannel && <PagePanel
								doTo={ pageId => `?page=${ pageId }` }
								filter={ pages.filter }
								page={ this.pageId() }
								take={ pages.take }
								total={ pages.total }
								variance={ pages.variance }
								onClick={ this.onPageClick }
							/>
						}
					</div>
				}
			</>
		);
	}

	pageId = () => {
		const page = this.query().get("page");

		return Number(page) || 0;
	};

	pageIdAsGET = (pageId) => {
		const pages = this.state.pages;

		// eslint-disable-next-line no-param-reassign
		pageId = pageId === undefined ? this.pageId() : pageId;
		const { filterId, } = this.mf;
		const result = {
			filterId,
			skip: pageId * pages.take,
			take: pages.take,
		};

		return result;
	};
	// eslint-disable-next-line no-unused-vars
	onPageClick = async ({ event, pageId, pageIs, }) => {
		await this.onDataLoad(pageId);
	};
}

export default withRouter(withLocalize(BalanceOperationsTable));