import React, { Component, } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, } from "reactstrap";
import { USER_EVENTS_TYPE, } from "../consts/USER_EVENTS_TYPE";
import { USER_EVENTS_STATUSES, } from "../consts/USER_EVENTS_STATUSES";
import { userService, } from "../../../../../services/UserDataService";
import getFormatedDate from "../../../../conversations/helpers";

const imgUrl = "/style-images/";

class DropdownEventCount extends Component {
	constructor() {
		super();

		this.state = {
			markedAsSeenEvents: [],
		}
	}

	save = async state => new Promise(next => this.setState(state, next));

	get newEvents () {
		const eventItems = this.props.userProfileEvents ?? [];

		return eventItems.filter(item => item.core.user_events.status === USER_EVENTS_STATUSES.NEW);
	}

	get seenEvents () {
		const eventItems = this.props.userProfileEvents ?? [];

		return eventItems.filter(item => item.core.user_events.status === USER_EVENTS_STATUSES.SEEN);
	}
	calcCountActiveEvents = () => {
		const { countActiveEvents, } = this.props;

		if (!countActiveEvents) {
			return 0;
		}

		if (+countActiveEvents >= 100) {
			return '99+';
		}
		return countActiveEvents;
	};

	getEventLinkAndTextContent = (eventItem) => {
		const { events, user_events, } = eventItem.core;
		const eventType = events.type;
		const eventName = events.name;
		const params = JSON.parse(user_events.params);

		const { AccountCurrencyEnum, } = this.props.enums.data.core;
		const accountFullName =  `${params['core.account']?.name} ${params['core.account']?.surname}`

		let eventLink;
		let eventTextContent;

		switch (eventType) {
			case (USER_EVENTS_TYPE.ACCOUNT): {
				const accountId = `#${params['core.account']?.id}`;
				eventLink = `/clients/${params['core.account']?.id}`;
				eventTextContent = `${eventItem.core.event_translation.name} ${accountFullName} ${accountId}`
				break
			}
			case (USER_EVENTS_TYPE.TASK): {
				eventLink = `/clients/${params['core.account']?.id}/tasks/task/${params['tasks.tasks']?.id}`;
				eventTextContent = `${eventItem.core.event_translation.name} #${params['tasks.tasks']?.id}`
				break
			}
			case (USER_EVENTS_TYPE.ORDER): {
				const orderData = `${ params['payment.orders'].amount } ${ AccountCurrencyEnum[params['core.system_accounts'].acc_ccy]}`;
				eventLink = `/payments/order/${params['payment.orders']?.id}`;
				eventTextContent = `${eventItem.core.event_translation.name} ${accountFullName} ${orderData}`
				break
			}
			case USER_EVENTS_TYPE.NOVATALKS: {
				const set_dialog_event_name = 'NOVATALKS_SET_DIALOG_BY_MANAGER';
				const conversation_id = eventName === set_dialog_event_name ? params['novatalks.dialogs']?.conversation_id : params['novatalks.messages']?.conversation_id;

				eventLink = `/conversations/${ conversation_id }`;
				eventTextContent = `${ eventItem.core.event_translation.name } ${ accountFullName }`;
				break;
			}
		}

		return { eventLink, eventTextContent }
	}
	followEventLink = async (userEventId, link) => {
		try{
			const { history, onDataLoad, } = this.props;

			await userService.updateUserEvents({ status: USER_EVENTS_STATUSES.CLOSED, }, userEventId);

			await onDataLoad();

			history.push(link);
		} catch (error){
			error.showErrorNotification?.();
			console.log(error);
		}
	}


	markEventSeen = async (userEventId) => {
		try{
			const { markedAsSeenEvents, } = this.state;

			if(markedAsSeenEvents.includes(userEventId)) return  //here we preventing infinite request to the back for the same event

			await userService.updateUserEvents({ status: USER_EVENTS_STATUSES.SEEN, }, userEventId);
			await this.save({ markedAsSeenEvents: [ ...markedAsSeenEvents, userEventId, ], });

		} catch (error){
			error.showErrorNotification?.();
			console.log(error);
		}
	}
	returnEventToNew = async (userEventId) => {
		try{
			const { markedAsSeenEvents, } = this.state;
			const { onDataLoad, } = this.props;

			await userService.updateUserEvents({ status: USER_EVENTS_STATUSES.NEW, }, userEventId);

			await this.save({ markedAsSeenEvents: markedAsSeenEvents.filter(markedEvent => markedEvent !== userEventId), });
			await onDataLoad();
		} catch (error){
			error.showErrorNotification?.();
			console.log(error);
		}
	}
    renderEventItems = (eventItems) => {
		const { translate } = this.props;
    	if (eventItems.length < 1) {
    		return <p className={ 'text-align-center mb-3' }>{translate("no_notifications")}</p>;
    	}

    	return (
			<ul className={'dropdown-menu_events-wrapper custom-scrollbar'}>
				{eventItems.map((item, index) => {
					const userEventId = item.core.user_events.id;
					const { eventLink, eventTextContent } = this.getEventLinkAndTextContent(item);
					const eventIsSeen = item.core.user_events.status === USER_EVENTS_STATUSES.SEEN;

					const handleItemMouseEnter = () => !eventIsSeen && this.markEventSeen(userEventId);
					const handleItemClick = () => this.followEventLink(userEventId, eventLink);
					const handleRetunButtonClick = async (event) => {
						event.stopPropagation();
						await this.returnEventToNew(userEventId);
					}

					return (
						<li onClick={handleItemClick} key={index} onMouseEnter={handleItemMouseEnter}>
							<DropdownItem className="notify-block">
								<div className="notify-icon d-grid justify-content-center align-content-center">
									<img
										className={'notify-icon__img'}
										src={`${imgUrl}notification-type-${item.core.events.type}.svg`}
										alt="event"
									/>

									{eventIsSeen && <div className={'notify-icon__return'} onClick={handleRetunButtonClick}></div>}

								</div>
								<span className="notify-details">
								<p className={'mb-2 pr-3 max-w'}>{eventTextContent}</p>
								 <span
									 className="notify-time">{getFormatedDate(item.core.user_events.created_at)}</span>
							</span>
							</DropdownItem>
						</li>
					);
				})}
		</ul>
    	);
    }
    render () {
    	const { loaded, markAllEventsAsViewed, onDataLoad, translate } = this.props;
    	const calcCountActiveEvents = this.calcCountActiveEvents();
    	const newEventsNotEmpty = this.newEvents.length;

    	return (
			<>
				<DropdownToggle caret={ true } nav={ true } onClick={onDataLoad}>
					<span className="notify-amount-cont">
						<i className="la la-bell notify-icon" />
						{ !loaded ?
						<div className="spinner-border text-danger notify-loader" role="status" />
							:
						<span className="notify-amount">
							{calcCountActiveEvents}
						</span>}
					</span>
				</DropdownToggle>
				<DropdownMenu className={'dropdown-menu_events mr-2'}>
					<div>
						<h6 className={ 'p-2' }>{translate("new_notifications")}</h6>
						{this.renderEventItems(this.newEvents)}
						{
							!!newEventsNotEmpty &&
							<button onClick={markAllEventsAsViewed} className={ 'd-flex bg-none align-items-center justify-content-center gap-0 bg-transparent border-0 my-0 mx-auto py-2' }>
								<i className={ 'la la-check m-0 p-0 mr-2' } />
								<p className={ 'm-0' }>{translate("see_all_notifications")}</p>
							</button>
						}
					</div>
					<div>
						<h6 className={ 'p-2' }>{translate("seen_notifications")}</h6>
						{this.renderEventItems(this.seenEvents)}
    				</div>
    			</DropdownMenu>
    		</>
    	);
    }
}

export default DropdownEventCount;