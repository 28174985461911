export const PAYMENTS_EXPORT_FIELDS = {
    payments: [
        'payment-orders-created_at',
        'payment-orders-date_enrollment',
        'payment-orders-id',
        'payment-orders-trading_account_id',
        'core-system_accounts-acc_ccy',
        'core-account-name',
        'core-account-surname',
        'core-user-name',
        'core-user-surname',
        'payment-orders-amount',
        'payment-orders-amount_paid',
        'payment-system_types-alias',
        'payment-systems-name',
        'payment-cards-name',
        'payment-orders-description',
        'payment-orders-date_paid',
        'payment-orders-type',
        'payment-orders-status',
        'payment-orders-internal_payment_id',
        'payment-orders-resolution',
        'payment-orders-error',
    ],
};
