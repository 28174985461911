const payoutRateListStatus = Object.freeze({
	ACTIVE: 2,
	CLOSED: 3,
	NEW: 1,
});

const payoutRateListConditions = Object.freeze({
	CPA: 1,
	CPL: 2,
});

export { payoutRateListStatus, payoutRateListConditions, };