import {shape, number, string, bool, object, oneOfType} from 'prop-types';

const USER_PROP_TYPES =  shape({
    login: shape({
        errors: object,
        httpStatus: number,
        success: bool,
    }),
    profile: shape({
        adaptation: oneOfType([string, number]),
        avatar: number,
        birth_date: string,
        created_at: string,
        email: string,
        id: number,
        lk_old_id: number,
        name: string,
        phone: string,
        preference_lang_id: number,
        role_id: number,
        status: oneOfType([string, number]),
        surname: string,
        throughput: number,
        token: string,
        updated_at: string,
    }),
    session: shape({
        access_token: string,
        exp: number,
        hash: string,
        iat: number,
        iss: string,
        jti: string,
        nbf: number,
        prv: string,
        sub: number,
        token: string,
        user_id: number,
    }),
});

const MAIL_PROP_TYPES = shape({
    system_id: number,
    template_id: number,
    template_name: string,
});

const SORT_PROP_TYPES = shape({
    f: string,
    o: string,
    v: string,
});

export {USER_PROP_TYPES, MAIL_PROP_TYPES, SORT_PROP_TYPES};