import React, { Component, } from "react";
import { withRouter, } from "react-router-dom";
import { withLocalize, } from "react-localize-redux";

import { Breadcrumbs, } from "../../tima/components/Breadcrumbs";
import ListOfCronsTable from "./components/ListOfCronsTable";

import * as configBreadcrumbs from "./config/BreadcrumbsConfig";
class ListOfCrons extends Component {
	get translate () {
		return this.props.translate;
	}

	render () {
		const translate = this.translate;
		const breadcrumbsItems = configBreadcrumbs.breadcrumbs4ListOfCrons(
			translate,
		);

		return (
			<div className="content-block">
				<div className="top-page-block mb-4">
					<div>
						<h1 className="page-title">{translate("list_of_crons")}</h1>
						<Breadcrumbs items={ breadcrumbsItems } />
					</div>
				</div>

				<ListOfCronsTable translate={ translate } />
			</div>
		);
	}
}

export default withRouter(withLocalize(ListOfCrons));