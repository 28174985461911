import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { withLocalize } from "react-localize-redux";
import moment from 'moment';
import {
  MagicFilterPanels,
  MagicTable,
  Mentor,
} from '../../../components/Magic';
import PagePanel from '../../tima/components/Pages/PagePanel';
import Preloader from '../../../components/LoadingHOC/Preloader';
import { callService } from "../../../services/CallDataService";

import '../../../style/_call-statistics.scss';

class CallStatistics extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      data: [],
      filterLastChange: Date.now(),
      loaded: false,
      pages: {
        filter: 0,
        take: 50,
        total: 0,
        variance: 2,
      },
      userPhone: null,
    };

    const mf = new Mentor({
      owner: this,
      serviceId: 224,
      translate: this.props.translate,
    });

    Object.defineProperty(this, 'mf', { get: () => mf, });
  }

  // eslint-disable-next-line react/sort-comp, require-await
  save = async state => new Promise(next => this.setState(state, next));

  mfChanged = async () => {
    await this.save({ filterLastChange: Date.now(), });
  }

  mfDoing = async () => {
    await this.save({ loaded: false, });
  };

  mfDone = async () => {
    await this.mfChanged();
    await this.onDataLoad();
  };

  async componentDidMount () {
    this.mf.subscribe({
      changed: this.mfChanged,
      // changing: ,
      cleaned: this.mfDone,
      cleaning: this.mfDoing,
      loaded: this.mfDone,
      loading: this.mfDoing,
      saved: this.mfDone,
      saving: this.mfDoing,
    }, this);
    await this.mf.init({ doEmit: true, });
    // await this.mf.emit('change', {});
    // await this.onDataLoad(); // TODO: moved to mfDone
  }

  componentWillUnmount = () => {
    this.mf.unsubscribe([
      this.mfChanged,
      this.mfDoing,
      this.mfDone,
    ], this);
  };

  get query () { return new URLSearchParams(this.props.location.search); }

  get config () {
    const { translate, } = this.props;
    const result = [ {
      key: 'accountId',
      path: [ 'core', 'account', 'id', ],
    }, {
      key: 'accountName',
      path: [ 'core', 'account', 'name', ],
    }, {
      key: 'accountSurname',
      path: [ 'core', 'account', 'surname', ],
    }, {
      key: 'userName',
      path: [ 'core', 'user', 'name', ],
    }, {
      key: 'userSurname',
      path: [ 'core', 'user', 'surname', ],
    }, {
      key: 'callSrc',
      path: [ 'calls', 'calls', 'src', ],
    }, {
      key: 'callIncoming',
      path: [ 'calls', 'calls', 'incoming', ],
    }, {
      key: 'callType',
      orderId: 'o:cIn',
      path: [ 'calls', 'calls', 'id', ],
      render: this.renderCallType,
      title: `callstatistics_type_call`,
    }, {
      key: 'callDate',
      orderId: 'o:cCreated',
      path: [ 'calls', 'calls', 'created_at', ],
      render: this.renderInlineValue,
      title: 'callstatistics_date',
    }, {
      key: 'callClient',
      orderId: 'o:aSurname',
      path: [ 'core', 'account', 'id', ],
      render: this.renderCallClient,
      title: 'callstatistics_client',
    }, {
      key: 'callUser',
      orderId: 'o:uSurname',
      path: [ 'core', 'user', 'id', ],
      render: this.renderCallUser,
      title: 'callstatistics_user',
    }, {
      key: 'callDuration',
      orderId: 'o:cDuration',
      path: [ 'calls', 'calls', 'duration', ],
      render: this.renderCallDuration,
      title: 'callstatistics_duration',
    }, {
      key: 'callResult',
      orderId: 'o:cDisposition',
      path: [ 'calls', 'calls', 'disposition', ],
      render: this.renderDisposition,
      title: 'callstatistics_result',
    }, ]
    .map(({
      orderId: o,
      title: t,
      xtitle: x,
      ...item
    }) => ({
      ...item,
      ...o && {
        order: async () => {
          await this.mf.orderedToNext(o);
          // await this.onDataLoad(); // TODO: remove cause reaction should be in subscribe
        },
        orderTo: this.mf.orderedTo(o),
      },
      ...t && { title: translate(t), },
      ...x && { title: x, },
    }));
    return result;
  }

  renderInlineValue = (value, { item }) => {
    if (item.access('index')) {
      return value ?? '-';
    }
    return '-';
  };

  renderCallType = (value, { item, items }) => {
    const dataTypeCalls = items.callIncoming.valueOf;

    if (item.access('index')) {
      const typeCalls = [0, '0'].includes(dataTypeCalls) ? 'outgoing' : 'incoming';

      return (
        <div className = 'calltype-wrapper'>
          <div className = { `callstat-typecall ${typeCalls}` } />
          <p>{this.props.translate(typeCalls)}</p>
        </div>
      );
    }
  };

  renderCallClient = (value, { item, items }) => {
    const name = items?.accountName;
    const surname = items?.accountSurname;

    if (item?.access('index') && name?.access?.('index') && surname?.access?.('index')) {
      return <Link to = { `/clients/${ value }` }>{ name.valueOf } { surname.valueOf }</Link>;
    } else if (name?.access?.('index') && surname?.access?.('index')) {
      return `${ name.valueOf } ${ surname.valueOf }`;
    }
  };

  renderCallUser = (value, { item, items }) => {
    const name = items?.userName.valueOf ?? '';
    const surname = items?.userSurname.valueOf ?? '';

    if (item?.access('index') && items?.userName?.access?.('index') && items?.userSurname?.access?.('index')) {
      // return <Link to = { `/settings/user/${ value }` }>{ name.valueOf } { surname.valueOf }</Link>;
      return `${ name } ${ surname }`;
    } else if (items?.userName?.access?.('index') && items?.userSurname?.access?.('index')) {
      return `${ name } ${ surname }`;
    }
  };

  renderCallDuration = (value, { item }) => {
    if (item?.access('index')) {
      return moment.utc(value*1000).format('HH:mm:ss');
    }
    return null;
  };

  renderDisposition = (value, { item }) => {
    const currentValue = value?.toLowerCase()?.replace(/ /g, '_');
    if (item.access('index')) {
      return ["answered", "no_answer", "busy", "failed"].includes(currentValue) ? this.props.translate(`call_statistics_${currentValue}`) : '-';
    }
    return '-';
  };

  renderMagicTable () {
    if (!this.state.loaded) {
      return (<Preloader />);
    }

    const options = {
      config: this.config,
      data:   this.state.data,
      head:   [
        'callUser',
        'callType',
        'callClient',
        'callDate',
        'callDuration',
        'callResult'
      ],
    };

    return (
      <>
        <MagicTable { ...options } />
        <PagePanel
          filter = { this.state.pages.filter }
          page = { this.pageId() }
          take = { this.state.pages.take }
          total = { this.state.pages.total }
          variance = { this.state.pages.variance }
          onClick = { this.onPageClick }
          doTo = { (pageId) => `?page=${pageId}` }
        />
      </>
    )

  }

  render () {
    const { translate } = this.props;

    return (
      <div>
        <div className = 'content-block'>
          <div className = 'top-page-block'>
            <h1 className = 'bold'>{translate(`callstatistics`)}</h1>
          </div>
        </div>

        <div className='content-block'>
          <div className='table-wrapper'>
            <div className='position-relative'>

              <MagicFilterPanels
                mf={ this.mf }
                show={ true }
                translate={ translate }
              />

              { this.renderMagicTable() }
              
            </div>
          </div>
        </div>
      </div>
    );
  }

  pageId = () => {
    const query = this.query;
    const page = query.get('page');

    return Number(page) || 0;
  };

  pageIdAsGET = ( pageId ) => {
    pageId = pageId === undefined ? this.pageId() : pageId;
    const { filterId, } = this.mf;
    const result = {
      filterId,
      take: this.state.pages.take,
      skip: pageId * this.state.pages.take,
    };

    return result;
  };

  onDataLoad = async (pageId) => {
    await this.save({ loaded: false });
    const pageOptions = this.pageIdAsGET(pageId);
    const loaded = (data, meta) => ({ pages, }) => ({
      data,
      pages: { ...pages, ...meta, },
    });
    try {
      const response = await callService.calls(pageOptions);
      const { data, meta: { filter = 0, total = 0, }, } = response;
      await this.save(loaded(data, { filter, total, }));
    } catch (e) {
      await this.save(loaded([], { filter: 0, total: 0, }));
    }
    await this.save({ loaded: true, });
  };

  onPageClick = async ({ event, pageId, pageIs, }) => {
    await this.onDataLoad(pageId);
  };
}

export default withRouter(withLocalize(CallStatistics));
