import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { withLocalize } from "react-localize-redux";
import TasksMagicTable from "../../../../tasks/components/TasksMagicTable";
import PagePanel from "../../../../tima/components/Pages";

class LeadAppealsUser extends Component {
    constructor (props) {
        super(props);
    }

    cleanUrl = (pageId) => {
        const url = new URL(window.location.href);
        url.searchParams.set('page', pageId);
        const pathname = url.pathname.replace(/[\/]task[\/][0-9]+/, '');
        const search = url.search;
        return `${ pathname }${ search }`;
        // return `?page=${ pageId }`
    };

    render () {
        const head = [
            'taskId',
            'taskDeadline',
            'taskStatus',
            'taskDescription',
        ];
        return (
            <React.Fragment>
                <div className="tableSort">
                    <TasksMagicTable
                        data={ this.props?.items }
                        head={ head }
                        mf={ this.props.mf }
                        onDataLoad={ this.props.onDataLoad }
                        updateCustomerSign={() => {}}
                        leadComponent={ true }
                        RowProps={ true }
                        setActiveUser={ this.props.setActiveUser }
                        currentId={ +this.props.currentId }
                    />
                </div>
                <PagePanel
                    filter={this.props.pages.filter}
                    take={this.props.pages.take}
                    total={this.props.pages.total}
                    variance={this.props.pages.variance}
                    page={this.props.pageId()}
                    onClick={this.props.onPageClick}
                    doText={this.props.onPageText}
                    doTo={(pageId) => this.cleanUrl(pageId)}
                />
            </React.Fragment>
        )
    }
}

export default withLocalize(withRouter(LeadAppealsUser));
