import React, { Component } from 'react';

import PropTypes from "prop-types";

import NotificationService from "../../../../../services/NotificationService";
import { MagicTextarea } from "../../../../tima/components/Magic/MagicTextarea";
import { MagicConfirm } from "../../../../tima/components/Magic/MagicConfirm";
import Preloader from "../../../../../components/LoadingHOC/Preloader";
import { listAccountService } from "../../../../../services/ListAccountDataService";

const REG_EXP = {
    general:     /^[а-яА-ЯёЁa-zA-ZІіЇїЄєҐґ0+-9*"'«»;:,.`№\s\-]{1,}$/,
    floatNumber: /^[0-9]*[.]?[0-9]{1,2}$/,
};

class PopupDepositWithdrawal extends Component {
    constructor (props) {
        super(props);

        this.state = {
            loaded:                false,
            loadedInnerRequest:    true,
            partnersList:          [],
            formData:              {
                amount:  '',
                comment: '',

                isValid: {
                    amount:  true,
                    comment: true,
                },
            },
            isMagicConfirmVisible: {
                deposit:  false,
                withdraw: false,
            },
        };
    }

    save = async (state) => new Promise(next => this.setState(state, next));

    componentDidMount () {

    }

    onConfirmClick = (action) => (event) => {
        const removeEmptyFields = (obj) => {
            Object.keys(obj).forEach(key => {
                if (obj[key] && typeof obj[key] === 'object') {
                    removeEmptyFields(obj[key]);
                } else if (obj[key] == null || obj[key] === '') {
                    delete obj[key];
                }
            });
        };

        const validatorStatus = this.validateFormDataFields();

        this.closeAllMagicConfirm();

        if (validatorStatus) {
            const { account_id = null } = this.props;
            const { formData } = this.state;
            const { amount, comment } = formData;

            const data = {
                account_id,
                amount: +amount,
                comment,
            };

            removeEmptyFields(data);

            (async () => {
                await this.save({ loadedInnerRequest: false, });

                try {
                    let putFunc = null;

                    switch (action) {
                        case 'deposit': {
                            putFunc = listAccountService.systemAccountDeposit.bind(listAccountService);
                            break;
                        }
                        case 'withdraw': {
                            putFunc = listAccountService.systemAccountWithdraw.bind(listAccountService);
                            break;
                        }
                        default: {
                            throw new Error(`no necessary put request for action: '${action}' !`);
                        }
                    }

                    const response = await putFunc(account_id, data);

                    if (response.error) {
                        throw new Error(response.error);
                    }

                    this.props.refreshChildData();
                    this.props.popUpClose();
                } catch (error) {

                    if (error.response && error.response.data) {
                        const errors = error.response.data.errors;
                        const newState = this.state;

                        for (let key in errors) {
                            const fieldOptionsArr = key.split('.');

                            if (fieldOptionsArr.length === 1) {
                                const [fieldName] = fieldOptionsArr;

                                newState.formData['isValid'][fieldName] = false;
                            } else if (fieldOptionsArr.length === 3) {
                                const [listName, rowIndex, fieldName] = fieldOptionsArr;

                                newState[listName][rowIndex]['isValid'][fieldName] = false;
                            }
                        }

                        this.setState(newState);
                    }

                    NotificationService.error({
                        title:   "error",
                        message: error.message,
                        remove:  false,
                    });
                }

                await this.save({ loadedInnerRequest: true, });
            })();
        }
    };

    closeAllMagicConfirm = async () => {
        const { isMagicConfirmVisible } = this.state;

        for (const key in isMagicConfirmVisible) {
            isMagicConfirmVisible[key] = false;
        }

        await this.save({ isMagicConfirmVisible });
    };

    onActionBtnClick = (actionName) => async (event) => {
        const { isMagicConfirmVisible } = this.state;

        for (const key in isMagicConfirmVisible) {
            isMagicConfirmVisible[key] = key === actionName;
        }

        await this.save({ isMagicConfirmVisible });
    };

    confirmRejectHandler = (actionName) => (event) => {
        this.closeAllMagicConfirm();
    };

    getRegExpByFieldName = (fieldName) => {
        let regExp = '';
        let maxLength = 50;

        switch (fieldName) {
            case 'amount': {
                regExp = REG_EXP.floatNumber;
                maxLength = 12;
                break;
            }
            case 'comment': {
                regExp = REG_EXP.general;
                maxLength = 255;
                break;
            }
            default: {
                regExp = REG_EXP.general;
                break;
            }
        }

        return { regExp, maxLength };
    };

    deleteNoValidSymbolsInField = (fieldObj) => {
        const { name, value } = fieldObj;
        const { regExp, maxLength } = this.getRegExpByFieldName(name);

        let resultStr = value;
        let regExpToMatch = String(regExp);

        regExpToMatch = regExpToMatch.slice(2, -2);
        regExpToMatch = new RegExp(regExpToMatch, "g");

        if (value.length > 0) {
            const flagTest = !regExp.test(value);
            const flagMax = value.length > maxLength;

            if (flagTest || flagMax) {
                if (flagTest) {
                    try {
                        // console.log('value.match(regExpToMatch): ', value.match(regExpToMatch));
                        switch (regExp) {
                            case REG_EXP.floatNumber: {
                                const dotsCnt = value.match(/[.]/g) ? value.match(/[.]/g).length : 0;

                                if (!dotsCnt || dotsCnt > 1 || dotsCnt === 1 && value[value.length - 1] !== '.') {
                                    resultStr = value.match(regExpToMatch)[0];
                                }
                                break;
                            }
                            default: {
                                resultStr = value.match(regExpToMatch).join('');
                                break;
                            }
                        }
                    } catch (error) {
                        resultStr = '';
                    }
                }
                if (flagMax) {
                    resultStr = resultStr.slice(0, maxLength);
                }

            }
        }

        return resultStr;
    };

    isValidField = (fieldObj) => {
        const { name, value } = fieldObj;
        const selectFieldsArr = ['selectFieldName',];

        let isValid = false;

        if (selectFieldsArr.indexOf(name) !== -1) {
            isValid = !!(value && value !== "");
        } else {
            try {
                const { regExp } = this.getRegExpByFieldName(name);

                isValid = regExp.test(value);
            } catch (error) {
                console.log('isValidField(): No such field: ', name);
            }
        }

        return isValid;
    };

    validateFormDataFields = () => {
        const listName = 'formData';
        const tmpList = this.state[listName];
        const { isValid: isValidObj } = tmpList;
        // const fieldsForCheck = ['name', 'accruals_frequency',];

        let isValidStatus = true;

        for (let fieldName in isValidObj) {
            if (fieldName === 'comment') {
                // skip validation for this field
                break;
            }

            const validatorStatus = this.isValidField({ name: fieldName, value: tmpList[fieldName] });

            isValidObj[fieldName] = validatorStatus;
            if (!validatorStatus) {
                isValidStatus = false;
            }
        }

        if (isValidStatus) {
            // turn off all errors
            for (let fieldName in isValidObj) {
                isValidObj[fieldName] = true;
            }
        }

        const newState = {
            [listName]: tmpList,
        };

        this.setState(newState);

        return isValidStatus;
    };

    onInputChange = (fieldName) => (event) => {
        const { value } = event.target;
        const currentValue = this.deleteNoValidSymbolsInField({ name: fieldName, value });
        const newState = { formData: { ...this.state.formData, [fieldName]: currentValue } };

        this.setState(newState);
    };

    onChangeTextarea = (fieldName) => ({ value, selectionStart, selectionEnd }) => {
        const currentValue = this.deleteNoValidSymbolsInField({ name: fieldName, value });
        const state = {
            formData: {
                ...this.state.formData,
                [fieldName]: currentValue,
            },
        };

        this.setState(state);
    };

    render () {
        const { translate, account_id } = this.props;
        const { formData, isMagicConfirmVisible, loadedInnerRequest } = this.state;
        const { deposit: isDepositConfirmVisible, withdraw: isWithdrawConfirmVisible } = isMagicConfirmVisible;
        const errorClassForAmount = formData['isValid']['amount'] ? '' : 'error';
        const errorClassForComment = formData['isValid']['comment'] ? '' : 'error';

        return (
            <div className='glalex-styles pop-up pop-up--deposit-withdrawal pop-up--active'>

                {!loadedInnerRequest ?
                    (
                        <Preloader className='loaderUniversal--fixed-pos' scale={1}/>
                    ) : false
                }

                <div className='pop-up-wrapper'>
                    <div className='pop-up-header'>
                        <h3 className='pop-up__name'>
                            {`${translate('list_accounts_popupDepositWithdrawal_title')} ${account_id}`}
                        </h3>
                        <i className='gl-icon close-btn close-btn--big' onClick={this.props.popUpClose}/>
                    </div>

                    <div className='pop-up-content'>
                        <div className='input'>
                            <span
                                className='input__text'>{translate('list_accounts_popupDepositWithdrawal_amount_label')}</span>
                            <div className='input-wrapper'>
                                <input className={`input__field ${errorClassForAmount}`}
                                       autoComplete='off'
                                       type='text'
                                       placeholder={translate('list_accounts_popupDepositWithdrawal_amount_placeholder')}
                                       value={this.state.formData.amount}
                                       onChange={this.onInputChange('amount')}
                                />
                            </div>
                        </div>

                        <div className='input'>
                            <span className='input__text input__text--flex-start'>
                                {translate('list_accounts_popupDepositWithdrawal_comment_label')}
                            </span>

                            <div className='input-wrapper'>
                                <MagicTextarea
                                    className={`magic-textarea input__field scroll ${errorClassForComment}`}
                                    value={this.state.formData.comment}
                                    onChange={this.onChangeTextarea('comment')}
                                    notToFocus={true}
                                />
                            </div>
                        </div>

                    </div>

                    <div className='btns-cont'>

                        <div className="magic-confirm-wrapper">
                            <MagicConfirm
                                onAccept={this.onConfirmClick('deposit')}
                                onReject={this.confirmRejectHandler('deposit')}
                                title={this.props.translate(`magic_confirm_title`)}
                                accept={this.props.translate(`magic_confirm_yes`)}
                                reject={this.props.translate(`magic_confirm_no`)}
                                isVisible={!!isDepositConfirmVisible}
                            />

                            <button className='gl-btn gl-btn--turquoise'
                                    onClick={this.onActionBtnClick('deposit')}
                            >
                                {translate('list_accounts_popupDepositWithdrawal_deposit_btn')}
                            </button>
                        </div>

                        <div className="magic-confirm-wrapper">
                            <MagicConfirm
                                onAccept={this.onConfirmClick('withdraw')}
                                onReject={this.confirmRejectHandler('withdraw')}
                                title={this.props.translate(`magic_confirm_title`)}
                                accept={this.props.translate(`magic_confirm_yes`)}
                                reject={this.props.translate(`magic_confirm_no`)}
                                isVisible={!!isWithdrawConfirmVisible}
                            />

                            <button className='gl-btn gl-btn--blue'
                                    onClick={this.onActionBtnClick('withdraw')}
                            >
                                {translate('list_accounts_popupDepositWithdrawal_withdraw_btn')}
                            </button>
                        </div>

                        <button className='gl-btn gl-btn--blue-border'
                                onClick={this.props.popUpClose}
                        >
                            {translate('partners_cancel_btn')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default (PopupDepositWithdrawal);

PopupDepositWithdrawal.propTypes = {
    popUpClose:       PropTypes.func.isRequired,
    refreshChildData: PropTypes.func.isRequired,
};
