import React, { Component, } from "react";
import { withRouter, } from 'react-router';
import { withLocalize, } from "react-localize-redux";

import { Breadcrumbs, } from '../../../tima/components/Breadcrumbs';
import { breadcrumbs4Summary, } from "./confg/SummaryBreadcrumbsConfig";
import { SUMMARY_EXPORT_FIELDS, } from "./consts/SUMMARY_EXPORT_FIELDS";
import { showExportToExcelError, } from "../../../../components/Magic";

import { securedRequest, } from "../../../../services/RequestService";
import PermissionService from "../../../../services/PermissionService";

import HorizontalMenu from '../../components/HorizontalMenu';
import SummaryOfFinTransactionsTable from "./components/SummaryOfFinTransactionsTable";

class SummaryOfFinTransactions extends Component {
	constructor(props) {
		super(props);

		this.state = {
			filter_id: 0,
		};

	}

	save = state => new Promise(next => this.setState(state, next));
	getPermissions = (action) => {
		const pm = PermissionService.calc([ {
			path: [ 'payment', 'daily_aggregated_fin_transactions', ],
			key: 'user',
		}, ]);

		return pm.user.access(action);
	}
	exportToExcel = activeTab => (event) => {
		if (activeTab) {
			const { filter_id, } = this.state;

			const globalUrl = `/api/payment/daily_aggregated_fin_transactions`;
			const exportUrl = `/export-to-excel`;
			let url = "";
			let file_name = "";

			switch (activeTab) {
				case "summary_of_fin_transactions": {
					url = `${ globalUrl }${ exportUrl }`;
					file_name = `Summary_of_fin_transactions`;
					break;
				}
				default: {
					break;
				}
			}
			const options = {
				fields: SUMMARY_EXPORT_FIELDS[activeTab],
				file_name,
			};

			if (filter_id) {
				options.filter_id = filter_id;
			}
			securedRequest
				.post(url, options)
				.then((res) => {
					// eslint-disable-next-line no-undef
					// fileDownload(res.data, res.headers.filename);
				})
				.catch((error) => {
					showExportToExcelError(error);
				});
		}
	};

	renderActions = () => {
		const excel = this.getPermissions('exportToExcel');

		if (!excel) {
			return null;
		}

		const { translate, } = this.props;

		return (
			<div className=" top-button-area ">
				{excel ? (
					<div
						className="button bordered"
						onClick={ this.exportToExcel("summary_of_fin_transactions") }
					>
						&nbsp;
						{translate(`managerPage_export_to_excel`)}
					</div>
				) : null}
			</div>
		);
	};

	handleFitlerIdChange = filter_id => this.save({ filter_id, })
	render () {
		const { translate, } = this.props;
		const breadcrumbsItems = breadcrumbs4Summary(translate);

		return (
			<div>
				<div className="content-block">
					<div className="top-page-block">
						<div>
							<h1 className={ 'page-title' }>{translate(`summary_table_title`)}</h1>
							<Breadcrumbs items={ breadcrumbsItems } />
						</div>
						{this.renderActions()}
					</div>
				</div>
				<HorizontalMenu />
				<SummaryOfFinTransactionsTable getPermissions={ this.getPermissions } handleFitlerIdChange={this.handleFitlerIdChange} />
			</div>
		);
	};
};

export default withRouter(withLocalize(SummaryOfFinTransactions));