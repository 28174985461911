import React, {Component}                 from 'react';
import PropTypes                          from 'prop-types';
import { withRouter, Link }               from 'react-router-dom';
import { withLocalize }                   from "react-localize-redux";
import { connect }                        from "react-redux";
import { MagicTable }                     from "../../../components/Magic";


class ListTimaStrategiesMagicTable extends Component {
    static propTypes = {
        data: PropTypes.array,
        enums: PropTypes.object,
    };

    static defaultProps = {
        data: [],
        enums: {},
    };

    get listTimaStrategiesConfig () {
        const { translate } = this.props;
        const prefix = 'tima_strategies_table_';

        return [
            {
                path: ['core', 'trading_accounts', 'id'],
                key:  'accountNumberId',
            },
            {
                path: ['core', 'account', 'id'],
                key:  'clientId',
            },
            {
                path: ['core', 'account', 'name'],
                key:  'clientName',
            },
            {
                path: ['core', 'account', 'surname'],
                key:  'clientSurname',
            },
            {
                path: ['core', 'user', 'id'],
                key:  'responsibleId',
            },
            {
                path: ['core', 'user', 'name'],
                key:  'responsibleName',
            },
            {
                path: ['core', 'user', 'surname'],
                key:  'responsibleSurname',
            },
            {
                path: ['tima', 'tima_manager', 'id'],
                key:  'timaAccountId',
            },
            {
                path:   ['tima', 'tima_manager', 'name'],
                key:    'timaAccount',
                render: this.renderTimaLinkField('timaAccount', '/tima/strategy'),
                title:  `${prefix}tima_account`,
            },
            {
                path:   ['core', 'trading_accounts', 'mt_login'],
                key:    'accountNumber',
                render: this.renderTimaLinkField('accountNumber', '/list-accounts/tradings'),
                title:  `${prefix}account_number`,
            },
            {
                accessChecked: true,
                key:           'clientFullname',
                render:        this.renderFullNameField('client', '/clients'),
                title:         `${prefix}client`,
            },
            {
                accessChecked: true,
                path:          ['core', 'trading_accounts', 'equity'],
                key:           'equity',
                render:        this.renderInlineValue,
                title:         `${prefix}equity`,
            },
            {
                accessChecked: true,
                path:          ['core', 'trading_accounts', 'balance'],
                key:           'balance',
                render:        this.renderInlineValue,
                title:         `${prefix}balance`,
            },
            {
                path:   ['core', 'trading_accounts', 'acc_ccy'],
                key:    'currency',
                render: this.renderEnumField('core', 'AccountCurrencyEnum'),
                title:  `${prefix}currency`,
            },
            {
                path:   ['tima', 'tima_manager', 'share'],
                key:    'share',
                render: this.renderNumberFixedFormat(2),
                title:  `${prefix}share_of_capital`,
            },
            {
                accessChecked: true,
                path:          ['core', 'trading_accounts', 'manager_balance'],
                key:           'managerBalance',
                render:        this.renderNumberFixedFormat(2),
                title:         `${prefix}manager_balance`,
            },
            {
                accessChecked: true,
                path:          ['core', 'trading_accounts', 'manager_equity'],
                key:           'managerEquity',
                render:        this.renderNumberFixedFormat(2),
                title:         `${prefix}manager_equity`,
            },
            {
                path:   ['tima', 'tima_manager', 'status'],
                key:    'status',
                render: this.renderEnumField('tima', 'TimaManagerStatusEnum'),
                title:  `${prefix}status`,
            },
            {
                path:   ['tima', 'tima_manager', 'trade_period'],
                key:    'tradePeriod',
                render: this.renderEnumField('tima', 'TimaManagerTradePeriodEnum'),
                title:  `${prefix}trade_period`,
            },
            {
                path:   ['tima', 'tima_manager', 'deal_time'],
                key:    'dealTime',
                render: this.renderInlineValue,
                title:  `${prefix}deal_time`,
            },
            {
                path:   ['tima', 'tima_manager', 'deal_step'],
                key:    'dealStep',
                render: this.renderEnumField('tima', 'TimaManagerDealStepEnum'),
                title:  `${prefix}deal_step`,
            },
            {
                path:   ['tima', 'tima_manager', 'agent_reward'],
                key:    'agentReward',
                render: this.renderInlineValue,
                title:  `${prefix}agent_reward`,
            },
            {
                path:   ['tima', 'tima_manager', 'min_invest_risk'],
                key:    'minInvestRisk',
                render: this.renderInlineValue,
                title:  `${prefix}min_invest_risk`,
            },
            {
                accessChecked: true,
                key:           'responsibleFullName',
                render:        this.renderFullNameField('responsible', '/settings/user'),
                title:         `${prefix}responsible`,
            }
        ].map(({ title: t, xtitle: x, ...item }) => ({ ...item, ...x && { title: x }, ...t && { title: translate(t) }}));
    }

    renderLink = (path, text, className = "") => (<Link to={path} className={className}>{text}</Link>);

    renderInlineValue = (value, { item, items }) => {
        if (item?.access?.('index')) {
            return value ?? '-';
        }
        return '-';
    };

    renderNumberFixedFormat = (digits) => (value, { item, items }) => {
        if (item?.access?.('index')) {
            // return !isNaN(+value) ? (+value)?.toFixed?.(digits) ?? '-' : '-';
            return (+value).toFixed(digits) ?? '-';
        }
        return '-';
    };

    renderTimaLinkField = (fieldName, path) => (value, { item, items }) => {
        const id = items?.[`${fieldName}Id`];
        if (item?.access?.('index', 'show')) {
            return this.renderLink(`${path}/${ id.valueOf }`, value);
        } else if (item?.access?.('index')) {
            return value;
        }
        return '-';
    };

    renderFullNameField = (fieldName, path) => (value, { items }) => {
        const id = items?.[`${fieldName}Id`];
        const name = items?.[`${fieldName}Name`];
        const surname = items?.[`${fieldName}Surname`];
        const status = items?.status?.valueOf;

        if (id?.access('show') && name?.access?.('index') && surname?.access?.('index')) {
            // status 3 = archived account
            if (fieldName === 'responsible') {
                return status !== 3 && (name.valueOf || surname.valueOf)
                    ? this.renderLink(`${path}/${id.valueOf}`, `${name.valueOf} ${surname.valueOf ?? ''}`)
                    : '-';
            } else if (fieldName === 'client') {
                return status !== 3 ?
                    this.renderLink(`${path}/${id.valueOf}`, `${name.valueOf} ${surname.valueOf ?? ''}`)
                    : `${name.valueOf} ${surname.valueOf ?? ''}`;
            }
        } else if (name?.access?.('index') && surname?.access?.('index')) {
            return `${surname.valueOf ?? '-'} ${name.valueOf ?? ''}`;
        }

        return '-';
    };

    renderEnumField = (path, enumName) => (value, { item }) => {
        if (item?.access?.('index')) {
            const enums = this.props?.enums?.[path]?.[enumName];
            return enums?.[value] ?? '-';
        }
        return '-';
    };

   render () {
      if (!this.props?.data?.length) {
         return null;
      }

      const options = {
         config: this.listTimaStrategiesConfig,
         data: this.props?.data,
         head:   [
            'timaAccount',
            'accountNumber',
            'clientFullname',
            'equity',
            'balance',
            'currency',
            'share',
            'managerBalance',
            'managerEquity',
            'status',
            'tradePeriod',
            'dealTime',
            'dealStep',
            'agentReward',
            'minInvestRisk',
            'responsibleFullName',
         ],
      };

      return (<MagicTable {...options} />);
   }
}

export default withRouter(withLocalize(ListTimaStrategiesMagicTable));
