import { transform, isEmpty } from 'lodash';
import jwtDecode from 'jwt-decode';
import DataStorage from '../helpers/DataStorage';
import DateTimeHelper from "../helpers/DateTimeHelper";
import {
    setSessionData,
    unsetSessionData,
    getProfile
} from '../modules/admin_panel/users/reducers/user/actions';
import PermissionService from "./PermissionService";
import { cachingService } from "./CachingService";
import { profileService } from "./ProfileDataService";

const AuthService = (() => {

    const TOKEN_KEY_NAME = 'token';
    const USER_ID = 'userId';

    const USER_PROPS = ['first_name', 'last_name'];

    let _this = null;

    let store = null;

    const setSessionDataToState = () => {
        const token = getSessionTokenFromStorage();

        if (!_this.isSessionTokenValid(token)) {
            removeSessionDataFromStorage(TOKEN_KEY_NAME);

            return false;
        }

        const sessionData = _this.getSessionData();

        store.dispatch(setSessionData(sessionData));

        return true;
    };

    const setSessionDataToStorage = (name, value) => DataStorage.setData(name, value);

    const setUserIdToStorage = (id) => DataStorage.setData(USER_ID, id);

    const getSessionTokenFromStorage = () => DataStorage.getData(TOKEN_KEY_NAME);

    const removeSessionDataFromStorage = (item) => DataStorage.removeData(item);

    const decodeSessionToken = (token) => {
        token = token || _this.getSessionToken();
        try {
            const data = jwtDecode(token);
            const timeLife = DateTimeHelper.getTokenTimeLife(data.exp);
            if (timeLife <= 0) {
                return false;
            }

            return true;

        } catch (error) {
            return false;
        }
    };

    return {
        init (globalStore) {
            _this = this;
            store = globalStore;

            setSessionDataToState();
        },

        async initSession (data= {}) {
            Object.keys(data).forEach((item) => setSessionDataToStorage(item, data[item]));
            const profile = await profileService.profile;
            await PermissionService.load(profile.data);
            document.cookie = `uid=${profile.data.id};path=/;max-age=31536000`;
            setUserIdToStorage(profile.data.id);
            await store.dispatch(getProfile(profile.data));

            if (!setSessionDataToState()) {
                return false;
            }
        },

        async destroySession (id, storageArray = []) {
            document.cookie = `uid=${id};path=/;max-age=0`;
            await storageArray.forEach((item) => removeSessionDataFromStorage(item));
            await store.dispatch(unsetSessionData());
            await cachingService.cleanSession();
        },

        getSessionData (userData) {
            const decoded = decodeSessionToken();

            const data = transform(decoded, (result, value, key) => {
                if (USER_PROPS.indexOf(key) !== -1) {
                    result.user[key] = value;
                } else {
                    result.session[key] = value;
                }
            }, { user: {}, session: {}});

            if (!isEmpty(userData)) {
                data.user = userData;
                data.session.permissions = userData.permissions;
            }
            data.session.user_id = data.session.sub;
            data.session.token = _this.getSessionToken();

            return data;
        },

        async refreshSessionDataFromServer (profile) {
            try {
                if (profile) {
                    const sessionData = _this.getSessionData(profile);
                    await PermissionService.load(profile);
                    await store.dispatch(getProfile(profile));
                    store.dispatch(setSessionData(sessionData));
                } else {
                    const profile = await profileService.profile;
                    const sessionData = _this.getSessionData(profile.data);
                    await PermissionService.load(profile.data);
                    await store.dispatch(getProfile(profile.data));
                    store.dispatch(setSessionData(sessionData));
                }
            } catch (error) {
                await _this.destroySession();
            }
        },

        getSessionToken () {
            return getSessionTokenFromStorage();
        },

        isSessionTokenValid (token) {
            return decodeSessionToken(token);
        },
    };
})();

export default AuthService;
