import React, { Component, } from "react";
import { withRouter, Link, } from "react-router-dom";
import { withLocalize, } from "react-localize-redux";
import PropTypes from "prop-types";

import { MagicTooltip, } from "../../../../../tima/components/Magic/MagicTooltip";
import { MagicTable, MagicButton, } from "../../../../../../components/Magic";

import Preloader from "../../../../../../components/LoadingHOC/Preloader";
import ViewEditPartnersRequest from "../Popup/ViewEditPartnersRequest";
import CreateUpdateAffiliatePrograms from "../../../AffiliateProgramsManagement/AffiliatePrograms/Popup/CreateUpdateAffiliatePrograms";

import { 
	getRequest,
	getPartnersPrograms,
} from "../../../../../../services/PartnersRequestService";

import getEditDataForAffiliateProgramsPopup from "../../../../helpers/getEditDataForAffiliateProgramsPopup";

const status = Object.freeze({
	"0": "new", //Declined
	"1": "declined", //New
	"2": "accepted", //Accepted
});

class PartnersRequestsMagicTable extends Component {
	constructor (props) {
		super(props);

		this.state = {
			allPrograms: [],
			programPopupData: {},
			programPopupIsOpen: false,
			showPopUp: false,
		};
	}

	async componentDidMount () {
		const allPrograms = await getPartnersPrograms();

		await this.save({ allPrograms: allPrograms.data, });
	}

	static propTypes = {
		data: PropTypes.array,
		enums: PropTypes.object,
		// eslint-disable-next-line react/sort-prop-types
		head: PropTypes.array.isRequired,
		mf: PropTypes.object.isRequired,
		onDataLoad: PropTypes.func.isRequired,
	};

	static defaultProps = {
		data: [],
		enums: {},
		head: [],
	};

	get enums () {
		return this.props?.enums ?? {};
	}

	get mf () {
		return this.props.mf;
	}
	get params () {
		return this.props?.match?.params;
	}

	get query () {
		return new URLSearchParams(this.props?.location?.search);
	}

	get config () {
		const { translate, } = this.props;
		const prefix = 'partners_requests_';
		return [
			{
				key: "accountName",
				path: [ "core", "account", "name", ],
			},
			{
				key: "accountSurname",
				path: [ "core", "account", "surname", ],
			},
			{
				key: "userName",
				path: [ "core", "user", "name", ],
			},
			{
				key: "userSurname",
				path: [ "core", "user", "surname", ],
			},
			{
				key: "requestId",
				path: [ "partners", "requests", "id", ],
			},
			{
				key: "programRealName",
				path: [ "partners", "programs", "name", ],
			},
			{
				key: "approvedProgramId",
				path: [ "partners", "requests", "approved_program_id", ],
			},
			{
				key: "clientProgramId",
				path: [ "partners", "requests", "program_id", ],
			},
			{
				key: "partnerId",
				orderId: "o:partnerId",
				path: [ "core", "account", "id", ],
				render: this.renderInlineValue,
				title: `${ prefix }partner_id`,
			},
			{
				key: "partnerManager",
				orderId: "o:uId",
				path: [ "core", "user", "id", ],
				render: this.renderPartnerManager,
				title: `${ prefix }partner_manager`,
			},
			{
				key: "partnerClient",
				orderId: "o:partnerClient",
				path: [ "core", "account", "id", ],
				render: this.renderClientName,
				title: `${ prefix }partner_client`,
			},
			{
				key: "partnerApplicationDate",
				orderId: "o:partnerApplicationDate",
				path: [ "partners", "requests", "created_at", ],
				render: this.renderDate,
				title: `${ prefix }partner_application_date`,
			},
			{
				key: "partnerProgram",
				orderId: "o:partnerProgram",
				path: [ "partners", "programs", "client_name", ],
				render: this.renderProgramName,
				title: `${ prefix }partner_affiliate_program`,
			},
			{
				key: "partnerStatus",
				orderId: "o:status",
				path: [ "partners", "requests", "status", ],
				render: this.renderPartnerStatus,
				title: `${ prefix }partner_status`,
			},
			{
				key: "partnerConfirmated",
				orderId: "o:partnerConfirmated",
				path: [ "partners", "requests", "approved_date", ],
				render: this.renderDate,
				title: `${ prefix }partner_confirmation_date`,
			},
			{
				key: "partnerReason",
				orderId: "o:partnerReason",
				path: [ "partners", "ban_request", "reason", ],
				render: this.renderReason,
				title: `${ prefix }partner_reason`,
			},
			{
				customAccessCheck: ({ item, }) => {
					return item.access("show") && item.access("update");
				},
				key: "partnerActions",
				path: [ "partners", "requests", "id", ],
				render: this.renderActions,
				title: `${ prefix }partner_action`,
			},
		].map(({
			orderId: o, title: t, xtitle: x, ...item
		}) => ({
			...item,
			...o && {
				order: async () => {
					await this.mf.orderedToNext(o);
				},
				orderTo: this.mf.orderedTo(o),
			},
			...t && { title: translate(t), },
			...x && { title: x, },
		}));
	}

	save = async (state) => new Promise(next => this.setState(state, next));
	renderInlineValue = (value, { item, }) => {
		if (item.access("index")) {
			return value ?? "-";
		}

		return "-";
	};

	renderDate = (value, { item, }) => {
		//needed to render date and time in separate lines
		if (item.access("index")) {
			if (!value) {
				return "-";
			}

			return value.split(" ").map(index => (
				<span className="table__date" key={ index }>
					{index}
				</span>
			));
		}

		return "-";
	};

	renderPartnerCode = (value, { item, }) => {
		if (item.access("index")) {
			return value === null
				? "-"
				: value.map(code => code.ref_short).join(", ");
		}

		return "-";
	};

	renderClientAccount = (value, { item, }) => {
		if (item.access("index")) {
			return value ? (
				<Link to={ `/list-accounts/systems/${ value }` }>{value}</Link>
			) :
				"-"
			;
		}

		return "-";
	};

	renderReason = (value, { item, }) => {
		if (item.access("index")) {
			return value ? (
				<MagicTooltip
					classNameTooltip={ `tooltip tooltip--large-width` }
					content={ value }
					lengthCut={ 20 }
				/>
			) :
				"-"
			;
		}

		return "-";
	};

	renderProgramName = (value, { item, items, }) => {
		//if client has approvedProgramId it's name showed, else requested program name showed
		const { allPrograms, } = this.state;
		const approvedProgramId = items.approvedProgramId.valueOf;
		const clientProgramId = items.clientProgramId.valueOf;
		const approverProgramName = allPrograms.filter(
			program => program.partners.programs.id === approvedProgramId,
		)[0]?.partners.programs.client_name;

		if (item.access("index")) {
			return value ? (
				<div
					className="cursor-pointer"
					onClick={ () =>
						this.toggleProgramPopup(approvedProgramId ?? clientProgramId)
					}
				>
					<MagicTooltip
						classNameTooltip={ `tooltip tooltip--large-width` }
						content={ approverProgramName ?? value }
						lengthCut={ 20 }
					/>
				</div>
			) :
				"-"
			;
		}

		return "-";
	};

	renderClientName (value, { item, items, }) {
		const name = items?.accountName.valueOf ?? "";
		const surname = items?.accountSurname.valueOf ?? "";
		const fullName = `${ name } ${ surname }`;

		if (
			item?.access("index") &&
			items?.accountName?.access?.("index") &&
			items?.accountSurname?.access?.("index")
		) {
			return value ? (
				<Link to={ `/clients/${ value }` }>
					<MagicTooltip
						classNameTooltip={ `tooltip tooltip--large-width` }
						content={ fullName }
						lengthCut={ 20 }
					/>
				</Link>
			) :
				"-"
			;
		} else if (
			items?.userName?.access?.("index") &&
			items?.userSurname?.access?.("index")
		) {
			return fullName;
		}
	}

	renderPartnerManager = (value, { item, items, }) => {
		const name = items?.userName.valueOf ?? "";
		const surname = items?.userSurname.valueOf
			? items.userSurname.valueOf
			: "-"; //otherwise field gonna be empty if blank manager data;
		const fullName = `${ name } ${ surname }`;

		if (
			item?.access("index") &&
			items?.userName?.access?.("index") &&
			items?.userSurname?.access?.("index")
		) {
			return value ? (
				<Link to={ `/settings/user/${ value }` }>
					<MagicTooltip
						classNameTooltip={ `tooltip tooltip--large-width` }
						content={ fullName }
						lengthCut={ 20 }
					/>
				</Link>
			) :
				"-"
			;
		} else if (
			items?.userName?.access?.("index") &&
			items?.userSurname?.access?.("index")
		) {
			return fullName.trim() ? fullName : "-";
		}
	};

	renderPartnerStatus = (value, { item, }) => {
		const { translate, } = this.props;

		if (item.access("index")) {
			const statusByCode = Object.values(status).filter(
				// eslint-disable-next-line no-shadow
				(status, index) => +index === +value,
			);
			const prefix = "partners_request_partner_status_";

			if (!statusByCode) {
				return "-";
			}
			// const isArchived = items?.partnerStatus.valueOf === 3 || statusByCode[0] === 'archive'; //either role or account is archived
			// return isArchived ? <span className='table__archived'>{translate(prefix + statusByCode)}</span> : translate(prefix + statusByCode);

			return translate(prefix + statusByCode);
		}

		return "-";
	};

	renderActions = (value, { item, items, }) => {
		const { translate, popup, } = this.props;
		const { allPrograms, } = this.state;

		const requestId = items?.requestId?.valueOf ?? "";

		const name = items?.accountName.valueOf ?? "";
		const surname = items?.accountSurname.valueOf ?? "";
		const fullName = `${ name } ${ surname }`;

		async function onClick () {
			const data = await getRequest(requestId);
			const dataForEdit = data?.data?.partners?.requests;
			const ban_request = data?.data?.partners?.ban_request;

			dataForEdit.account_fname = fullName;
			dataForEdit.ban_request = ban_request;
			dataForEdit.allPrograms = allPrograms;

			await popup.togglePopup(dataForEdit);
		}

		const renderEditButton = () => {
			const options = {
				children: translate("symbols_actions_edit"),
				className: "magic-button__item magic-button__item_partners-edit-symbol",
				onClick,
			};

			return (
				<div className="magic-confirm-wrapper">
					<MagicButton { ...options } />
				</div>
			);
		};

		const renderViewButton = () => {
			const options = {
				children: translate("partners_requests_partner_view"),
				className:
					"magic-button__item magic-button__item_partners-view-request",
				onClick,
			};

			return (
				<div className="magic-confirm-wrapper">
					<MagicButton { ...options } />
				</div>
			);
		};

		return (
			<>
				{item.access("update") &&
					items?.partnerStatus?.valueOf === 0 &&
					renderEditButton()}
				{item.access("show") &&
					items?.partnerStatus?.valueOf !== 0 &&
					renderViewButton()}
			</>
		);
	};

	renderTable () {
		const {
			loaded, data, head, translate,
		} = this.props;

		if (!loaded) {
			return <Preloader />;
		}
		if (data.length === 0) {
			return <p className="mx-auto no-data">{translate("symbols_empty")}</p>;
		}

		const options = {
			config: this.config,
			data,
			head,
		};

		return (
			<div className="partners-partners__table">
				<div className="partners__table">
					<MagicTable { ...options } />
				</div>
			</div>
		);
	}

	toggleProgramPopup = async (id) => {
		const editFormData = await getEditDataForAffiliateProgramsPopup(id);

		await this.save({
			programPopupData: editFormData,
			programPopupIsOpen: true,
		});
	};

	render () {
		const { popup, enums, onDataLoad, } = this.props;

		const { showPopUp, programPopupData, programPopupIsOpen, } = this.state;

		return (
			<>
				{popup.isOpen && (
					<ViewEditPartnersRequest
						allStatuses={ status }
						enums={ enums }
						popupData={ popup.data }
						showPopUp={ showPopUp }
						onClose={ async () => {
							await this.save({ showPopUp: false, });
							await popup.togglePopup();
						} }
						onDataLoad={ onDataLoad }
					/>
				)}
				{programPopupIsOpen && (
					<CreateUpdateAffiliatePrograms
						enums={ enums }
						formData={ programPopupData }
						isViewMode={ true }
						onClose={ async () => {
							await this.save({ programPopupIsOpen: false, });
						} }
						onDataLoad={ this.onDataLoad }
					/>
				)}
				{this.renderTable()}
			</>
		);
	}
}

export default withRouter(withLocalize(PartnersRequestsMagicTable));