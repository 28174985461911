import React, { Component, } from 'react';
import { withLocalize, } from 'react-localize-redux';
import { withRouter, } from 'react-router-dom';

import { MagicButton, } from '../../../../../components/Magic';
import { HorizontalMenu, } from '../../../components';
import AffiliateProgramsTable from './Table/AffiliateProgramsTable';
import PartnersSystemHeader from "../../ components/PartnersSystemHeader";

import { enumService, } from '../../../../../services/EnumDataService';
import PermissionService from '../../../../../services/PermissionService';

import * as configBreadcrumbs from './Config/BreadcrumbsConfig';

class AffiliatePrograms extends Component {
	constructor (props) {
		super(props);

		this.state = {
			enums: {},
			isOpenCreatePopup: false,
			popupData: {},
		};
	}

	async componentDidMount () {
		enumService.subscribe('enums', this.enumsChange, this);
		await enumService.enums;
	};

	UNSAFE_componentWillUnmount () {
		enumService.unsubscribe('enums', this.enumsChange, this);
	};

	toggleCreateUpdatePopup = async (popupData = {}) => {
		await this.save(state => ({
			isOpenCreatePopup: !state.isOpenCreatePopup,
			popupData,
		}));
	};

	save = async state => new Promise(next => this.setState(state, next));

	enumsChange = async (enums) => {
		if (enums.hash === this.state?.enums?.hash) {
		  return `${ this.constructor.name }.enumsChange: false`;
		}
		await this.save({ enums, });

		return `${ this.constructor.name }.enumsChange: true`;
	};

	get AffiliateProgramsConfig () {
		return [
		  {
				path: [ 'partners', 'programs', 'id', ],
				key: 'programs',
		  },
		];
	}

	renderButtonCreate = () => {
		const conf = PermissionService.calc(this.AffiliateProgramsConfig);

		if (conf?.programs.access('store')) {
		  	const options = {
				className: "magic-button__item magic-button__item_partners-create-symbol",
				children: this.props.translate('symbols_create_symbol_btn'),
						// eslint-disable-next-line no-return-await
				onClick: async () => await this.toggleCreateUpdatePopup(),
			};

			return (
				<div className="top-button-area">
					<MagicButton { ...options } />
				</div>
			);
		}

		return '';
	};

	render () {
		const { enums, isOpenCreatePopup, popupData, } = this.state;
		const { translate, } = this.props;

		const breadcrumbsItems = configBreadcrumbs.breadcrumbs4AccuralsConditins;

		return (
			<div className='pt-3'>
				<PartnersSystemHeader
					breadcrumbsItems={ breadcrumbsItems }
					title={ "menu_partners_system_affiliate_programs" }
					translate={ translate }
					actions = {this.renderButtonCreate() }
				/>
				<HorizontalMenu />
				<div className="content-block df">
					<div className="table-wrapper">
						<div className="position-relative">
							<AffiliateProgramsTable
								enums={ enums }
								popup={ {
									data: popupData,
									isOpen: isOpenCreatePopup,
									togglePopup: this.toggleCreateUpdatePopup,
								} }
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(withLocalize(AffiliatePrograms));