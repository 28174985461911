import React, { Component } from 'react';
import Access from './Access';
import classnames from 'classnames';
import { has } from 'lodash';
import { securedRequest } from "../../../../../services/RequestService";

const ACTIVE_TAB = '1';

export default class GridContainer extends Component {
    constructor (props) {
        super(props);

        this.state = {
            data:      this.props.data,
            activeTab: ACTIVE_TAB,
            name:      this.props.name,
            translate: false,
        };
    }

    toggle = (tab, stateName, item) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });

            this.props.stateNameToggle(stateName, item);
        }
    };

    getTranslateAllTables = () => {
        securedRequest.get('/api/core/get-translate-all-tables')
            .then((response) => {
                this.setState({
                    translate: response.data,
                });
            })
            .catch((error) => {
                console.log('error', error);
            });
    };

    componentDidMount() {
        this.getTranslateAllTables();
    }

    render () {

        if (!this.state.translate) {
            return null;
        }

        return (
            <React.Fragment>
                <div className = 'accounts'>

                    {Object.keys(this.state.data[this.state.name]).map((item, index) => {
                        const tableName =
                            (
                                has(this.state.translate, this.state.name) &&
                                has(this.state.translate[this.state.name], item)
                            )
                            ? this.state.translate[this.state.name][item]
                            : item;

                        const stateName = this.state.name;

                        return (
                            <div className = 'accounts-wrap' key = { index }>
                                <div
                                    className = { `${classnames({ active: this.state.activeTab === index })} accounts-item` }
                                    onClick = { () => {
											 this.toggle(index, stateName, item);
										 } }>
                                    <span className = 'accounts-item-check' />

                                    <div className = 'table-filter-select tooltip-wrapper'>
                                        <span className = 'accounts-item-name'>{ tableName }</span>
                                        <div className = 'tooltip tooltip-bottom'>{ tableName }</div>
                                    </div>

                                </div>
                                {this.state.activeTab === index
                                    ?
                                    <Access
                                            addField = { this.props.addField }
                                            data = { this.state.data[this.state.name] }
                                            fieldToggle = { this.props.fieldToggle }
                                            getUserField = { this.props.getUserField }
                                            isChecked = { this.props.isChecked }
                                            isEditPopup = { this.props.isEditPopup }
                                            isFieldChecked = { this.props.isFieldChecked }
                                            mainName = { this.state.name }
                                            methodToggle = { this.props.methodToggle }
                                            moduleToggle = { this.props.moduleToggle }
                                            name = { item }
                                            removeField = { this.props.removeField }
                                            stateNameToggle = { this.props.stateNameToggle }
                                        />
                                    :
                                    null}
                            </div>
                        );
                    })
                    }

                </div>

            </React.Fragment>

        );
    }

}
