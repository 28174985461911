import React from "react";
import person from "../../../../images/person.svg";
import { Translate } from "react-localize-redux";
import { isUndefined } from "lodash";
import {getAvatar} from "../../services/TaskHelperService";

class ResponsibleBlock extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            images: {},
            name:   '',
            role:   this.props.ROLE_3,
        };
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        getAvatar(this, nextProps.getResponsible['id'], nextProps.usersAllAvatar);
        if (!isUndefined(nextProps?.get_enums_tasks?.TaskRoleEnum) && nextProps?.get_enums_tasks?.TaskRoleEnum?.[this.props.ROLE_3] !== this.state.name) {
            this.setState({
                name: nextProps.get_enums_tasks.TaskRoleEnum[this.props.ROLE_3]
            });
        }
    }

    render () {
        const task_users = this.props.getResponsible;

        return (

            <Translate>
                {({ translate }) => {
                    return (
                        <React.Fragment>
                            <div className = 'lead-block'>
                                <div className = 'user-image'>
                                    {
                                        this.state.images.imageId
                                            ? <img alt = { translate(`tasks_filter_responsible`) } src = { this.state.images.imageId } />
                                            : ''
                                    }
                                </div>
                                <div className = 'user-info'>
                                    <div>{ translate(`tasks_filter_responsible`) }</div>
                                    <div className = 'user-name active'>
                                        { task_users.name || task_users.surname ?
                                            `${task_users.name ?? ''} ${task_users.surname ?? ''}`
                                            : translate(`tasks_task_manager`)
                                        }
                                    </div>
                                    {/* "Администрация", "Администратор отдела продаж", "Аналитика", “Техническая поддержка” */}
                                    { [1, 2, 4, 15].includes(+this.props.userRoleId) ? (
                                        <div className = 'user-action'>
                                            <span
                                                onClick = { () => {
                                                    this.props.toggleModal(this.state.role);
                                                    this.props.getAllManager();
                                                } }><img alt = 'person' src = { person } style={{width: '10px'}} /> { translate(`tasks_change_responsible`) }</span>
                                        </div>
                                    ) : ''}
                                </div>
                            </div>
                        </React.Fragment>
                    );
                }}
            </Translate>
        );
    }
}

export default ResponsibleBlock;
