import React from 'react';
import { Alert } from 'reactstrap';
import { connect } from 'react-redux';
import logoCrm from "../../../../images/logo.svg";
import loginIphone from "../../../../images/i-phone-x.png";
import { withRouter, Link } from "react-router-dom";

import '../../../../style/NewLogin.scss';
import {resetForm} from "../../services/AuthService";

class ResetForm extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            data: {
                password:              '',
                password_confirmation: '',
                token:                 this.props.match.params.token,
            },
            visible: false,
        };

        this.onChangeInput = this.onChangeInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onDismiss = this.onDismiss.bind(this);

    }

    onChangeInput (event) {
        this.setState({
            data: {
                ...this.state.data,
                [event.target.name]: event.target.value,
            },
        });

    }

    onDismiss () {
        this.setState({ visible: false });
        this.props.history.push("/");
    }

    handleSubmit (event) {
        event.preventDefault();

        resetForm(this.state.data).then(() => {
            this.setState({
                visible: true,
            });

        });
    }

    render () {
        return (
            <div className = 'login-wrapper'>
                <div className = 'login-container'>
                    <div className = 'login-img-logo'>
                        <img alt = 'logo' src = { logoCrm } />
                    </div>

                    <h2 className = 'light-grey-text text-center text-upper' />
                    <form className = 'login-form' onSubmit = { this.handleSubmit }>
                        <Alert color = 'success' isOpen = { this.state.visible } toggle = { this.onDismiss }>
                            Password is changed. You can <Link to = '/'>login</Link>!
                        </Alert>
                        <br />
                        <div className = 'form-group'>
                            <div className = 'login-form-icon'>
                                <i className = 'la la-key' />
                            </div>
                            <label> Password</label>
                            <input
                                className = 'input-login form-control'
                                name = 'password'
                                placeholder = '********'
                                type = 'password'
                                onChange = { this.onChangeInput }
                            />
                            <div className = 'error-message'>121132131</div>
                        </div>
                        <div className = 'form-group'>
                            <div className = 'login-form-icon'>
                                <i className = 'la la-key' />
                            </div>
                            <label> Confirm password</label>
                            <input
                                className = 'input-login form-control'
                                name = 'password_confirmation'
                                placeholder = '********'
                                type = 'password'
                                onChange = { this.onChangeInput }
                            />
                            <div className = 'error-message'>121132131</div>
                        </div>

                        <div className = 'form-group submit-group'>
                            <div className = 'd-flex align-items-center justify-content-center'>

                                <input
                                    className = 'button big green' type = 'submit' value = 'Change'
                                />

                            </div>
                        </div>

                    </form>
                </div>
                <div className = 'login-img'>
                    <div className = 'login-img-text'>
                        <h1 className = 'login-img-title'>
                            <span id = 'auth_title' >
                                Intelligent Solution for Your Business
                            </span>
                        </h1>
                    </div>
                    <img alt = 'login' src = { loginIphone } />
                </div>
            </div>

        );
    }
}

export default withRouter(connect()(ResetForm));
