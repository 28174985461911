import React, { Component, } from 'react';
import ReactDOM from 'react-dom';

import { SortableContainer, SortableHandle, SortableElement, } from 'react-sortable-hoc';
import arrayMove from '../../../../../helpers/ArrayHelper';
import connect from "react-redux/es/connect/connect";
import { bindActionCreators, } from "redux";
// import { getCheckListTaskID } from "../../../services/TasksService";
import { updateCheckList, } from "../../../services/TaskRequestService";
import { map, upperFirst, } from "lodash";
import NotificationService from "../../../../../services/NotificationService";

const DragHandle = SortableHandle(({ style, }) => (
	<span style={ { ...style, ...{ cursor: 'move', display: 'inline-block', 'width': '24px', }, } } >{'::::'}</span>),
);

const SortableList = SortableContainer(({ children, }) => (
	<ul className="checklist-wrapper">
		{children}
	</ul>
));

const SortableItem = SortableElement(({
	data, index, key, checkListTask, setToCheck, check_lists_id, sortBtnChecklist, _handleKeyDownInputCheckBox, val, valueF, handleClick,
}) => {

	let isChecked = data['tasks-task_check_lists-check'] === 2;

	return (
		<li
			style={ { 'listStyleType': 'none', 'marginLeft': '-3px', 'zIndex': '1004', } }
		>
			{sortBtnChecklist ?
				<span className="dragHandle">
					<DragHandle />
				</span>
				:
				''
			}
			<label className="taskList-checkbox">
				<input
					checked={ isChecked }
					disabled={ Boolean(sortBtnChecklist) } type="checkbox" value={ isChecked } onClick={ (e) => {
						isChecked = !isChecked;
						setToCheck(isChecked, e, check_lists_id);
					} }
				/>
				<span style={ sortBtnChecklist ? { 'opacity': '0.5', } : { 'opacity': '1', } } />
			</label>
			<label className="taskList-checkbox-textarea">
				{!sortBtnChecklist ?
					<span
						style={ isChecked ?
							{ 'textDecorationLine': 'line-through', 'opacity': '0.5', 'cursor': 'text', }
							: null
						}
					>
						{data['tasks-task_check_lists-name']}
					</span>
					:
					val ?
						<input
							data-check={ data['tasks-task_check_lists-check'] }
							defaultValue={ data['tasks-task_check_lists-name'] }
							name="name"
							required={ true }
							style={ isChecked ? { 'textDecorationLine': 'line-through', 'opacity': '0.5', } : null } onBlur={ () => _handleKeyDownInputCheckBox(event, check_lists_id) }
						/>
						:
						<span
							style={ isChecked ? { 'textDecorationLine': 'line-through', 'opacity': '0.5', 'width': '100%', } : null }
							onClick={ () => valueF() }
						>
							{data['tasks-task_check_lists-name']}
						</span>

				}
			</label>
			{!sortBtnChecklist ?
				<i className="close-button-checkList la la-times-circle-o" onClick={ id => handleClick(data['tasks-task_check_lists-id']) } />
				: null}
		</li>
	);
});

class SortableComponent extends Component {
	constructor (props) {
		super(props);
		this.state = {
			checkList: this.props.checkList,
			dataSort: [],
			val: false,
			showPopup: false,
			left: 0,
			top: 0,
			delID: null,
		};
		this.handleClick = this.handleClick.bind(this);
		this.handleOutsideClick = this.handleOutsideClick.bind(this);
	}

	componentDidMount () {
		this.sortByCheckList(this.state.checkList);
	}

	UNSAFE_componentWillReceiveProps (nextProps) {
		this.setState({
			checkList: nextProps.checkList,
			val: false,
		});
		setTimeout(() => this.sortByCheckList(nextProps.checkList), 0);
	}

    handleClick = (id) => {
    	this.props.removeCheckList(id, this.state.dataSort);
    };

    handleOutsideClick = (e) => {
    	const domNode = ReactDOM.findDOMNode(this);

    	if (domNode.contains(e.target)) {
    		return;
    	}

    	this.handleClick(e);
    };

    onSortEnd = ({ oldIndex, newIndex, }) => {
    	this.setState({
    		dataSort: arrayMove(this.state.dataSort, oldIndex, newIndex),
    	});
    };

    compareByCheckList = (key) => {
    	return function (a, b) {
    		if (a[key] < b[key]) {
    			return -1;
    		}
    		if (a[key] > b[key]) {
    			return 1;
    		}

    		return 0;
    	};
    };

    sortByCheckList = (checkList) => {
    	const arrayCopy = [ ...checkList, ];

    	arrayCopy.sort(this.compareByCheckList('tasks-task_check_lists-sort'));

    	this.setState({ dataSort: arrayCopy, });
    };

    sortUpdate = () => {
    	const promise = new Promise((resolve, reject) => {

    		map(this.state.dataSort, (item, key) => {
    			updateCheckList({ 'sort': key+1, }, item['tasks-task_check_lists-id'])
    				.then(() => {
    					if (this.state.dataSort.length - 1 === key) {
    						this.props.setChecklistTask(this.props.taskId);
    						this.props.sortBtnChecklistFunc();
    					}
    				})
    				.catch((error) => {
    					resolve(false);
    				});
    		});
    	});

    	promise
    		.then(
    			(result) => {
    				if (!result) {
    					NotificationService.error({
    						title: "error",
    						message: "Permission denied",
    					});
    				}
    			},
    			(error) => {

    			},
    		);
    };

    valueF = () => {
    	this.setState({ val: true, });
    };

    render () {
    	return (
	<React.Fragment>
	<div className="modal-task-container__titl">
	<h6 className="modal-task-container__titleHeadList">
	{ this.props.translate(`task_checklist`) } ({this.props.checkList.length})
    				</h6>
	{this.props.checkList.length > 0 ? (
	<div className="modal-task-container__close" style={ { paddingRight: '0px', } }>
	{this.props.sortBtnChecklist ?
	<div
	className="custom-button" onClick={ () => {
    									this.sortUpdate();
    								} }
    							>
	<span>{this.props.translate(`tasks_createform_save`)}</span>
    							</div>
    							:
    							<div
		className="custom-button" onClick={ () => {
    									this.props.sortBtnChecklistFunc();
    								} }
	>
		<span>{upperFirst(this.props.translate(`tasks_edit`))}</span>
	</div>
    						}
    					</div>)
    					: null
    				}
    			</div>

	<SortableList
	onSortEnd={ this.onSortEnd }
	useDragHandle={ true }
    			>
	{this.state.dataSort.map((row, index) => {
    					return (
	<SortableItem
	_handleKeyDownInputCheckBox={ this.props._handleKeyDownInputCheckBox }
	check_lists_id={ row['tasks-task_check_lists-id'] }
	checkListTask={ this.props.checkListTask }
	data={ row }
	handleClick={ this.handleClick }
	index={ index }
	key={ index }
	removeCheckList={ this.props.removeCheckList }
	setToCheck={ this.props.setToCheck }
	sortBtnChecklist={ this.props.sortBtnChecklist }
	val={ this.state.val }
	valueF={ this.valueF }
    						/>
    					);
    				})}
    			</SortableList>
    		</React.Fragment>
    	);
    }
}

export default connect(
	null,
	dispatch =>
		bindActionCreators({
            // getCheckListTaskID,
		}, dispatch),
)(SortableComponent);