import React, { Component, } from "react";
import { withLocalize, } from "react-localize-redux";
import PropTypes from "prop-types";
import { Input, Select, Popup, } from "../../../../components";
import {
	asyncGet,
	createSymbolGroup,
	editSymbolGroup,
} from "../../../../../../services/PartnersRequestService";
import {
	GET_TRADE_SERVER_LIST_TAKE_ALL,
	GET_SYMBOLS_GROUPS_LIST,
} from "../../../../../../apiRoutes";
import {
	findOptionByValue,
	setErrorsFromServer,
	setErrorClass,
	isEmptySelectValue,
	isEmptyString,
} from "../../../../utils/helpers";

class CreateUpdateSymbol extends Component {
	static propTypes = {
		onClose: PropTypes.func.isRequired,
		// eslint-disable-next-line sort-keys
		enums: PropTypes.object,
		formData: PropTypes.object,
		onDataLoad: PropTypes.func,
	};

	constructor (props) {
		super(props);

		this.state = {
			errors: [],
			formData: {
				name: "",
				trading_server_id: {},
			},
			loadData: true,
			serverList: [],
			statusList: [],
			symbolsGroups: [],
		};
	}

	get translate () {
		return this.props.translate;
	}

	get isEditMode () {
		return Object.keys(this.props.formData).length;
	}

	get isEditTradingServerSelect () {
		return (
			this.props.formData.amount !== 0 &&
			this.renderTitle() !== this.translate("symbols_create_symbol_group")
		);
	}

	save = state => new Promise(next => this.setState(state, next));

	componentDidMount = async () => {
		await this.save((state) => {
			return {
				formData: { ...state.formData, },
			};
		});

		await this.onDataLoad();
	};

	onDataLoad = async () => {
		await this.save({ loadData: false, });

		try {
			const [ serversList, symbolsGroups, ] = await Promise.all([
				this.getServersList(),
				this.getSymbolsGroups(),
			]);

			await this.save({ serversList, symbolsGroups, });

			if (this.isEditMode) {
				await this.setSavedData();
			}
		} catch (error) {
			error?.showErrorNotification?.();

			return false;
		} finally {
			await this.save({ loadData: true, });
		}
	};

	getSymbolsGroups = async () => {
		try {
			const response = await asyncGet(`${ GET_SYMBOLS_GROUPS_LIST }/drop-down`);

			return response.map(({ id, name, }) => ({ label: name, value: id, }));
		} catch (error) {
			error?.showErrorNotification?.();

			return false;
		}
	};

	setSavedData = async () => {
		const { trading_server_id, name, } = this.props.formData;

		await this.save(state => ({
			formData: {
				...state.formData,
				name,
				trading_server_id: findOptionByValue(
					state.serversList,
					trading_server_id,
				),
			},
		}));
	};

	getSendData = () => {
		const { name, trading_server_id, } = this.state.formData;

		return { name, trading_server_id: trading_server_id.value, };
	};

	validateEmptyFields = async () => {
		await this.save(state => ({
			errors: Object.keys(state.formData).reduce((total, field) => {
				if (
					isEmptySelectValue(state.formData[field]) ||
					isEmptyString(state.formData[field])
				) {
					// eslint-disable-next-line no-param-reassign
					total = [ ...total, field, ];
				}

				return total;
			}, []),
		}));

		return this.state.errors.length === 0;
	};

	saveData = async () => {
		const isValidate = await this.validateEmptyFields();

		if (!isValidate) {
			return;
		}

		this.isEditMode
			? await this.updateSymbol()
			: await this.createSymbolGroup();
	};

	createSymbolGroup = async () => {
		const { onDataLoad, onClose, } = this.props;

		try {
			await this.save({ loadData: false, });
			await createSymbolGroup(this.getSendData());

			onDataLoad && onDataLoad();
			onClose();
		} catch (error) {
			await setErrorsFromServer(error, this.save);
		} finally {
			await this.save({ loadData: true, });
		}
	};

	updateSymbol = async () => {
		const { onDataLoad, onClose, formData, } = this.props;

		try {
			await this.save({ loadData: false, });
			await editSymbolGroup(formData.id, this.getSendData());

			onDataLoad && onDataLoad();
			onClose();
		} catch (error) {
			await setErrorsFromServer(error, this.save);
		} finally {
			await this.save({ loadData: true, });
		}
	};

	getServersList = async () => {
		try {
			const response = await asyncGet(GET_TRADE_SERVER_LIST_TAKE_ALL);

			return response
				.filter(({ server_name, }) => !server_name.includes("Demo"))
				.reduce((total, { server_name, id, }) => {
					total.push({ label: server_name, value: +id, });

					return total;
				}, []);
		} catch (error) {
			error?.showErrorNotification?.();

			return false;
		}
	};

	handleOnChange = async (event) => {
		const { name, value, } = event.target;

		await this.save(state => ({
			...state,
			errors: state.errors.filter(item => item !== name),
			formData: {
				...state.formData,
				[name]: value,
			},
		}));
	};

	renderTitle = () => {
		if (this.isEditMode) {
			return this.translate("symbols_edit_symbol_group");
		}

		return this.translate("symbols_create_symbol_group");
	};

	render = () => {
		const { onClose, } = this.props;

		const {
			formData, loadData, serversList, errors,
		} = this.state;

		return (
			<Popup
				loadData={ loadData }
				title={ this.renderTitle() }
				onClick={ this.saveData }
				onClose={ onClose }
			>
				<Input
					disabled={ !loadData }
					isRequired={ true }
					label={ this.translate("symbols_name") }
					name="name"
					placeholder={ this.translate(
						"partners_create_symbol_name_placeholder",
					) }
					value={ formData.name }
					wrapperClassName={ setErrorClass(errors, "name") }
					onChange={ this.handleOnChange }
					onValid={ (value) => {
						// eslint-disable-next-line no-useless-escape
						const regExp = /^[a-zA-Z0-9\s\.\,\/\\\-\_\&\#\()\\]{0,100}$/g;

						if (value[0] === " " || !regExp.test(value)) {
							throw new Error("xxx");
						}
					} }
				/>
				<Select
					className={ setErrorClass(errors, "trading_server_id") }
					disabled={ !loadData || this.isEditTradingServerSelect }
					isRequired={ true }
					label={ this.translate("partners_server_name_label") }
					name="trading_server_id"
					options={ serversList }
					placeholder={ this.translate("partners_create_account_select_server") }
					value={ formData.trading_server_id }
					onChange={ this.handleOnChange }
					onChangeCallback={ async (event) => {
						const { value: tradingServerId, } = event.target.value;

						await this.getSymbolsGroups(tradingServerId);
					} }
				/>
			</Popup>
		);
	};
}

export default withLocalize(CreateUpdateSymbol);