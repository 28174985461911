import React from 'react';
import { Media, Player } from 'react-media-player';
import CustomPlayPause from './CustomPlayPause';
import CustomSeekBar from './CustomSeekBar';
import MuteUnmute from './MuteUnmute';

export default class InteratorAudioPlayer extends React.Component {

    render () {

        return (
            <React.Fragment>
                <Media>
                    <div className="media">
                        <div className="media-player">
                            <Player src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/355309/Swing_Jazz_Drum.mp3" />
                        </div>
                        <div className="media-controls">
                            <CustomPlayPause />
                            <CustomSeekBar />
                            <MuteUnmute />
                        </div>
                    </div>
                </Media>
            </React.Fragment>
        );
    }
}
