import React, { Component } from 'react';
import PropTypes from 'prop-types';

class MagicConfirm extends Component {
	static propTypes = {
		title: PropTypes.string,
    className: PropTypes.string.isRequired,
    accept: PropTypes.string.isRequired,
    reject: PropTypes.string.isRequired,
    onAccept: PropTypes.func.isRequired,
    onReject: PropTypes.func.isRequired,
    isVisible: PropTypes.bool,
    text: PropTypes.string,
		closeByClickOutside: PropTypes.bool,
	};

	static defaultProps = {
    className: `magic-confirm`,
    isVisible: false,
		closeByClickOutside: false,
	};

	render = () => {
		const {
			isVisible,
			className,
			title,
			text,
			onAccept,
			accept,
			onReject,
			reject,
			closeByClickOutside,
		} = this.props;

		if (!isVisible) return "";
	
		return (
			<>
				{ closeByClickOutside && <div onClick={ onReject } className="magic-confirm__backdrop"/> }
				
				<div className={ className ? className : "" }>
					
					{ title && <h4 className="magic-confirm__title"> { title } </h4> }
					{ text && <p className="magic-confirm__text">{ text }</p> }
					
					<div className="magic-confirm-btns">
						<span 
							className="magic-confirm__btn magic-confirm__btn--yes"
							onClick={ onAccept }
							children={ accept }
						/>
						<span 
							className="magic-confirm__btn magic-confirm__btn--no"
							onClick={ onReject }
							children={ reject }
						/>
					</div>
				</div>
			</>
		)
	}
}

export default MagicConfirm;
export { MagicConfirm };
