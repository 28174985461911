import React                                from 'react';
import { withRouter, Link }                 from 'react-router-dom';
import Moment                               from 'moment';
import { withLocalize }                     from "react-localize-redux";
import '../../../../../style/_sales-planning.scss';
import Preloader                            from '../../../../../components/Common/Preloader';
import PagePanel                            from "../../../../tima/components/Pages/PagePanel";
import Loader                               from "../../../../tima/components/Loader";
import {
  MagicFilterPanels,
  MagicTable,
  MagicSelect,
  MagicButton,
  Mentor,
}                                           from "../../../../../components/Magic";
import PermissionService                    from '../../../../../services/PermissionService';
import { GET_USER_KPI_SALES_PLANE }         from '../../../../../apiRoutes';
import { SALES_PLANNING_EXPORT_FIELDS }     from '../../constants';
import { securedRequest }                   from "../../../../../services/RequestService";
import { enumService }                      from "../../../../../services/EnumDataService";
import { userService } from "../../../../../services/UserDataService";

class SalesPlanning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      salesPlanes: {
        hash: null,
        data: [],
      },
      formData: [],
      isEditing: false,
      pages: {
        filter: 0,
        total: 0,
        take: 50,
        variance: 2,
      },
      filterLastChange: Date.now(),
    };

    const mf = new Mentor({
      owner: this,
      serviceId: 52,
      translate: this.props.translate,
    });

    Object.defineProperty(this, 'mf', { get: () => mf, });

  }

  // eslint-disable-next-line react/sort-comp, require-await
  save = async state => new Promise(next => this.setState(state, next));

  mfChanged = async () => {
    await this.save({ filterLastChange: Date.now(), });
  };

  mfDoing = async () => {
    await this.save({ loaded: false, });
  };

  mfDone = async () => {
    await this.mfChanged();
    await this.onDataLoad();
  };

  enumsChange = async (enums) => {
    if (enums.hash===this.state?.enums?.hash) return `${this.constructor.name}.enumsChange: false`;
    await this.save({ enums, });
    return `${this.constructor.name}.enumsChange: true`;
  };

  salesPlanesChange = async (salesPlanes) => {
    if (salesPlanes.hash===this.state?.salesPlanes?.hash) return `${this.constructor.name}.salesPlanesChange: false`;
    const { hash, planes: { data, meta }} = salesPlanes;
    await this.save({
      salesPlanes: { data, hash },
      pages: { ...this.state.pages, ...meta },
    });
    return `${this.constructor.name}.salesPlanesChange: true`;
  };

  async componentDidMount() {
    enumService.subscribe('enums', this.enumsChange, this);
    userService.subscribe('getSalesPlanes', this.salesPlanesChange, this);
    await enumService.enums;

    this.mf.subscribe({
      changed: this.mfChanged,
      // changing: ,
      cleaned: this.mfDone,
      cleaning: this.mfDoing,
      loaded: this.mfDone,
      loading: this.mfDoing,
      saved: this.mfDone,
      saving: this.mfDoing,
    }, this);
    await this.mf.init({ doEmit: true, });
    // await this.mf.emit('change', {});
    // await this.onDataLoad(); // TODO: moved to mfDone
  }

  componentWillUnmount() {
    this.mf.unsubscribe([
      this.mfChanged,
      this.mfDoing,
      this.mfDone,
    ], this);

    enumService.unsubscribe('enums', this.enumsChange, this);
    userService.unsubscribe('getSalesPlanes', this.salesPlanesChange, this);
  }

  get params () { return this.props.match.params; }

  get query () { return new URLSearchParams(this.props.location.search); }

  get userKpiPlaneConfig () {
    const { translate, } = this.props;
    return [
      {
        path: [ 'core', 'user', 'name' ],
        key: 'userName',
      },
      {
        path: [ 'core', 'user', 'surname' ],
        key: 'userSurname',
      },
      {
        path: [ 'core', 'user_kpi_plan' ],
        key: 'userKpiPlanExport',
      },
      {
        path: [ 'core', 'user_kpi_plan', 'id' ],
        key: 'userKpiPlanId',
      },
      {
        path: [ 'core', 'user', 'id' ],
        key: 'userId',
        orderId: 'o:uId',
        render: this.renderInlineValue,
        title: 'managerPage_id',
      },
      {
        path: [ 'core', 'user', 'created_at' ],
        key: 'userCreated',
        render: this.renderInlineValue,
        title: 'managerPage_created',
      },
      {
        accessChecked: true,
        key: 'userFullname',
        orderId: 'o:aSurname',
        render: this.renderUserFullname,
        title: 'managerPage_fullname',
      },
      {
        // accessChecked: true,
        path: [ 'core', 'departments', 'name' ],
        key: 'userDepartment',
        render: this.renderDepartments,
        title: 'managerPage_department',
      },
      {
        // accessChecked: true,
        path: [ 'core', 'permission_templates', 'name' ],
        key: 'userPermissionTemplates',
        render: this.renderInlineValue, // this.renderDepartments,
        title: 'managerPage_permission_templates',
      },
      {
        // accessChecked: true,
        path: [ 'core', 'user_kpi_plan', 'kpi_type_id' ],
        key: 'userKpiType',
        orderId: 'o:uKpiPlanTypeId',
        render: this.renderUserKpiTypeId,
        title: 'managerPage_kpi_type_id',
      },
      {
        path: [ "core", "user_kpi_plan", 'year' ],
        key: 'userKpiPlanYear',
        orderId: 'o:uKpiPlanYear',
        render: this.renderUserKpiYear,
        title: 'managerPage_year',
      },
      ...Array(12).fill(0).map((_, index) => ({
        path: [ "core", "user_kpi_plan", `kpi_month_${ index + 1 }` ],
        key: `userKpiPlanMonth-${ index + 1 }`,
        orderId: `o:userKpiPlanMonth-${ index + 1 }`,
        render: this.renderKpiPlanMonth(index + 1),
        xtitle: Moment().month(index).format('MMMM'),
      })),
      {
        path: [ 'core', 'user_kpi_plan', 'active' ],
        key: 'userKpiActive',
        render: this.renderUserKpiActive,
        title: 'managerPage_active',
      },
    ].map(({
      orderId: o,
      title: t,
      xtitle: x,
      ...item
    }) => ({
      ...item,
      ...o && {
        order: async () => {
          await this.mf.orderedToNext(o);
        },
        orderTo: this.mf.orderedTo(o),
      },
      ...x && { title: x },
      ...t && { title: translate(t) },
    }));
  }

  exportFile = () => {
    const { filterId: filter_id, } = this.mf;
    const globalUrl = `${GET_USER_KPI_SALES_PLANE}`;
    const exportUrl = `export-to-excel`;
    const url = `${globalUrl}/${exportUrl}`
    const options = {
      file_name: `Sales_planning`,
      fields: SALES_PLANNING_EXPORT_FIELDS['salesPlanning'],
    };
    if (filter_id) {
      options['filter_id'] = filter_id;
    }
    console.log(GET_USER_KPI_SALES_PLANE)
    securedRequest.post(url, options)
      .then(() => {//  console.log('ok');
      })
      .catch((error) => {
        showExportToExcelError(error);
      });
  };

  renderBtnExport = () => {
    const pm = PermissionService.calc(this.userKpiPlaneConfig);
    if(pm?.userKpiPlanExport.access('exportToExcel')) {
      const options = {
        className: "magic-button__item magic-button__item_export",
        children: this.props.translate("managerPage_export_to_excel"),
        onClick: this.exportFile,
      };
      return (<MagicButton {...options} />)
      }
    return "";
  };

  renderInlineValue = (value, { item }) => {
    if (item.access('index')) {
      return value ?? '-';
    }
    return '-';
  }

  renderUserFullname = (value, { items }) => {
    const id = items?.userId;
    const name = items?.userName;
    const surname = items?.userSurname;
    if (id?.access('show') && name?.access?.('index') && surname?.access?.('index')) {
      return <Link to={ `/settings/user/${ id.valueOf }` }>{ name.valueOf } { surname.valueOf }</Link>;
    } else if (name?.access?.('index') && surname?.access?.('index')) {
      return `${ name.valueOf } ${ surname.valueOf }`;
    }
    return '-';
  }

  renderDepartments = (value, { item }) => {
    try {
      if (value && item?.access('index')) {
        const values = JSON.parse(value)
        return values.join(', ');
      }
    } catch (error) {}
    return '-';
  };

  renderKpiPlanMonth = (index) => (value, { item, items }) => {
    if (this.state.isEditing) {
      if (item.access('index', 'store')) {
        const userId = items?.userId?.valueOf;
        const type = this.state?.formData?.find?.(item => item?.userId===userId)?.kpi_type_id ?? items?.userKpiType?.valueOf; // user_id
        const year = this.state?.formData?.find?.(item => item?.userId===userId)?.year ?? items?.userKpiPlanYear?.valueOf; // user_id
        const onChange = async (event) => {
          let plan = {
            userId, // user_id
            kpi_type_id: type,
            year,
          };
          Object.assign(plan, ...Array(12).fill(0).map((_, i) => i + 1).map(i => ({
            [`kpi_month_${ i }`]: this.state?.formData?.find?.(item => item?.userId===userId)?.[`kpi_month_${ i }`] ?? items?.[`userKpiPlanMonth-${ i }`]?.valueOf, // user_id
          })));
          plan ={
            ...plan,
            [`kpi_month_${ index }`]: event?.target?.value,
          };
          await this.save(({ formData: fd }) => {
            const planIndex = fd?.findIndex?.(item => item?.userId===userId); // user_id
            const planItem = fd?.[planIndex];
            const plans = fd?.filter((_, i) => i !== planIndex);
            plan = { ...planItem && planItem, ...plan, };
            return {
              formData: [ ...plans, plan ],
            };
          });
        };
        return (
          <input
            className='inputAdminKpi'
            defaultValue={ value }
            onChange={ onChange }
            type='text'
          />
        );
      }
      return '-';
    } else {
      return this.renderInlineValue(value, { item });
    }
  };

  renderUserKpiTypeId = (value, { item, items }) => {
    if (item.access('index')) {
      const enums = this.state?.enums?.data?.core?.KpiTypesEnum;
      if (this.state.isEditing && !value) {
        const userId = items?.userId?.valueOf;
        const onChange = async (type) => {
          let plan = {
            userId, // user_id
            kpi_type_id: type,
          };
          await this.save(({ formData: fd }) => {
            const planIndex = fd?.findIndex?.(item => item?.userId===userId); // user_id
            const planItem = fd?.[planIndex];
            const plans = fd?.filter((_, i) => i !== planIndex);
            plan = { ...planItem && planItem, ...plan, };
            return {
              formData: [ ...plans, plan ],
            };
          });
        };
        const options = {
          value: this.state?.formData?.find?.(item => item?.userId===userId)?.kpi_type_id ?? 0, // user_id
          valueToLabel: (value) => value===0 ? '-' : enums?.[value] ?? '?',
          values: [0, ...Object.keys(enums)],
          onChange,
        };
        return (<MagicSelect { ...options } />);
      } else {
        return enums?.[value] ?? '-';
      }
    }
    return '-';
  }

  renderUserKpiYear = (value, { item, items }) => {
    if (item.access('index')) {
      if (this.state.isEditing && !value) {
        const userId = items?.userId?.valueOf;
        const onChange = async (event) => {
          let plan = {
            userId, // user_id
            year: event?.target?.value,
          };
          await this.save(({ formData: fd }) => {
            const planIndex = fd?.findIndex?.(item => item?.userId===userId); // user_id
            const planItem = fd?.[planIndex];
            const plans = fd?.filter((_, i) => i !== planIndex);
            plan = { ...planItem && planItem, ...plan, };
            return {
              formData: [ ...plans, plan ],
            };
          });
        };
        return (
          <input
            className='inputAdminKpi'
            defaultValue={ this.state?.formData?.find?.(item => item?.userId===userId)?.year ?? '' } // user_id
            onChange={ onChange }
            type='text'
          />
        );
      } else {
        return value ?? '-';
      }
    }
    return '-';
  };

  renderUserKpiActive = (value, { item }) => {
    if (item.access('index')) {
      const enums = this.state?.enums?.data?.core?.UserKpiPlanEnum;
      return enums?.[value] ?? '-';
    }
    return '-';
  };

  renderActions = () => {
    const pm = PermissionService.calc([{
      path: [ 'core', 'user_kpi_plan', 'id'],
      key: 'userKpiPlanId',
    }]);
    const store = pm.userKpiPlanId.access('store');
    if (!store) {
      return null;
    }

    const { translate } = this.props;

    const edit = (value) => async () => {
      if (!value) {
        await this.save({ isEditing: false, formData: [], });
      } else {
        await this.save({ isEditing: true, });
      }
    };
    const save = async () => {
      await this.save({ loaded: false, });
      await Promise.all(this.state?.formData?.map(async (plan) => {
        try {
          await userService.setSalesPlanes(plan);
        } catch (error) {
          error.showErrorNotification();
        }
      }));
      await this.save({ isEditing: false, formData: [], });
      await this.onDataLoad();
    };

    return (
      <div className = 'buttons-flex__block buttons-flex--buttons-right'>
        {this.state.isEditing ?
          <div className = 'button red' onClick={ edit(false) }>{translate(`managerPage_cancel`)}</div>
          :
          <div className = 'button green' onClick={ edit(true) }>{translate(`adminKpi_edit`)}</div>
        }
        {this.state.isEditing ?
          <div className = 'button blue' onClick={ save }>{translate(`adminKpi_save`)}</div>
          : ""
        }
      </div>
    );
  };

  render = () => {
    const { translate } = this.props;

    const options = {
      config: this.userKpiPlaneConfig,
      data: this.state.salesPlanes.data,
      head: [
        'userId',
        // 'userCreated',
        'userFullname',
        'userDepartment',
        // 'userPermissionTemplates',
        'userKpiType',
        'userKpiPlanYear',
        ...Array(12).fill(0).map((_, index) => `userKpiPlanMonth-${ index + 1 }`),
        // 'userKpiActive',
      ],
    };

    return (
      <div>
        <div className = 'content-block'>
          <div className = 'top-page-block'>
            <h1 className = 'bold'>{translate(`adminKpi_sales_planning`)}</h1>
            <div className = 'top-button-area'>
              <div className = 'buttons-flex'>
                { this.renderActions() }
                { this.renderBtnExport() }
              </div>
             </div>
          </div>
        </div>

        <MagicFilterPanels
          mf={ this.mf }
          show={ true }
          translate={ translate }
        />

        <div className='content-block'>
          <div className='table-wrapper'>
            <div className='position-relative'>
              <Loader
                loaded={this.state.loaded}
                loading={(<Preloader scale={this.props.scale}/>)}
                translate={translate}
              >
                <MagicTable { ...options } />
                <PagePanel
                  filter={this.state.pages.filter}
                  take={this.state.pages.take}
                  total={this.state.pages.total}
                  variance={this.state.pages.variance}
                  page={this.pageId()}
                  onClick={this.onPageClick}
                  doText={this.onPageText}
                  doTo={(pageId) => `?page=${ pageId }`}
                  doStatus={()=>''}
                />
              </Loader>
            </div>
          </div>
        </div>
      </div>
    );
  };

  pageId = () => {
    const query = this.query;
    const page = query.get( 'page' );
    return Number( page ) || 0;
  };

  pageIdAsGET = ( pageId ) => {
    pageId = pageId === undefined ? this.pageId() : pageId;
    const { filterId, } = this.mf;
    const result = {
      filterId,
      take: this.state.pages.take,
      skip: pageId * this.state.pages.take,
    };
    return result;
  };

  onDataLoad = async ( pageId ) => {
    await this.save({ loaded: false, });
    const pageOptions = this.pageIdAsGET( pageId );
    const loaded = (hash, data, meta) => ({ pages, }) => ({
      salesPlanes: { data, hash },
      pages: { ...pages, ...meta, },
    });
    try {
      const { hash, planes } = await userService.getSalesPlanes(pageOptions);
      const { data, meta: { filter = 0, total = 0, }, } = planes;
      await this.save(loaded(hash, data, { filter, total, }));
    } catch ( error ) {
      await this.save(loaded(null, [], { filter: 0, total: 0, }));
    }
    await this.save({ loaded: true, });
  };

  onPageClick = ({ event, pageId, pageIs, }) => {
    // event.preventDefault();
    this.onDataLoad(pageId);
  };

  onPageText = (pageId, pageIs) => {
    const { translate } = this.props;
    const { current, first, prev, next, last, } = pageIs;
    const { skipped, taken, filter, total, } = pageIs;
    if (skipped || taken) {
      const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : '?';
      const text = skipped ? translate('partners_pages_items') : '';
      return skipped ? `${ text }: ${ id }` : id;
    } else if (filter || total) {
      const id = Number.isInteger(pageId) ? `${ pageId }` : '?';
      const text = translate(filter ? 'partners_pages_filtered' : 'partners_pages_total');
      return `${ text }: ${ id }`;
    } else if (first || prev || next || last) {
      return '';
    } else if (current) {
      return `${ pageId + 1}`;
    } else {
      return `${ pageId + 1}`;
    }
  };
}

export default withRouter(withLocalize(SalesPlanning));
