import React, { Component, createRef, } from 'react';
import { markReviewedMessage, } from "../../../services/ConversationsRequestService";

class RenderMessagesWindow extends Component {
	constructor (props) {
		super(props);
		this.myP = createRef();
	}
	save = async state => new Promise(next => this.setState(state, next));
	componentDidMount () {
		const { conversationData, } = this.props;

		if (conversationData.length) {
			this.myP.current?.scrollIntoView({
				behavior: "smooth",
				block: "end",
			});
		}
	}
	handleUnreadMessages = async (id, viewed) => {
		if (viewed === 2) {
			await markReviewedMessage(id);
			this.props.updateConversation();
		}

	}

	render () {
		const { conversationData, } = this.props;

		return (
			<>
				{conversationData.map((msg, index, arr) => {
					const { name, surname, } = msg.core.account;
					const managerName = msg.core.user;
					const attachmentsData = msg.novatalks.message_attachments;
					const viewedMsg = arr.filter(item => item.novatalks.messages.viewed === 2 && item.novatalks.messages.type === 'incoming');
					const lastUnviewedMessage = viewedMsg[viewedMsg.length -1]?.novatalks?.messages;

					const {
						content, type, message_time, viewed, id,
					} = msg.novatalks.messages;
					const className = viewed === 2 ? 'conversation_client_msg_not_readed' : '';

					if (type === 'incoming') {

						return (<>{lastUnviewedMessage?.content === content && lastUnviewedMessage?.id === id && <div className="conversation_msg_client_unread_msg" ref={ this.myP }>Unreaded Message</div> }
							{!attachmentsData?.length ?
								<div className={ className } key={ index } onMouseEnter={ () => this.handleUnreadMessages(id, viewed) }>
									<div
										className="conversation_msg_client"
									>
										<p className="conversation_msg_client_name">{name} {surname}</p>

										<p>{content}</p>
										<p className="conversation_msg_client_file_time text-align-right">{message_time}</p>
									</div>
								</div>
								:
								''}
							{ attachmentsData
									&& attachmentsData.map(({ file_type, thumb_url, }, i) => {

										if (file_type === 'image') {

											return (
												<div className={ className } key={ i } onMouseEnter={ () => this.handleUnreadMessages(id, viewed) }>
													<div
														className="conversation_msg_client"

													>
														<p className="conversation_msg_client_name">{name} {surname}</p>

														<div className="conversation_msg_client_block">
															<img alt="image" className="conversation_msg_client_img" src={ thumb_url } />
															<a className="conversation_msg_client_img_upload" href={ thumb_url } />
														</div>
														 <p>{content}</p>
														<p className="conversation_msg_client_file_time text-align-right">{message_time.slice(11)}</p>
													</div>
												</div>

											);
										}
										if (file_type === 'file') {
											return (
												<div className={ className } key={ i } onMouseEnter={ () => this.handleUnreadMessages(id, viewed) }>
													<div
														className="conversation_msg_client"
														key={ i }
													>
														<p className="conversation_msg_client_name">{name} {surname}</p>
														<div className="conversation_msg_client_file_block">
															<a className="conversation_msg_client_file" href={ thumb_url } />
															<div>
																<p>Document</p>
																<p className="conversation_msg_client_file_time">{message_time}</p>
															</div>
														</div>
														<p>{content}</p>
													</div>
												</div>
											);
										}
										if (file_type === 'audio') {
											return (
												<div className={ className } key={ i } onMouseEnter={ () => this.handleUnreadMessages(id, viewed) }>
													<div
														className="conversation_msg_client"
														key={ i }
													>
														<p className="conversation_msg_client_name">{name} {surname}</p>
														<div className="conversation_msg_client_file_block">
															<audio controls={ true } src={ thumb_url } />
														</div>
														<p className="conversation_msg_client_file_time text-align-right">{message_time}</p>
													</div>
												</div>
											);
										}
									})}

						</>
						);
					}

					return (
						<div className="conversation_msg_manager" >
							{managerName?.map(({ name, surname, }) => (
								<p>{name} {surname}</p>
							))}
							<p>{content}</p>
							<p className="conversation_msg_client_file_time_manager text-align-right">{message_time}</p>
						</div>
					);

				},

				).reverse()}
				<span ref={ this.myP } />
			</>

		);
	}
}

export default RenderMessagesWindow;