import PaymentsLinkConfig from "./PaymentsLinkConfig";

export const toolbarInit = ( translate, active ) =>
    PaymentsLinkConfig(
        translate,
        [
            'orders',
        ],
        {
            active,
        }
    );

export const toolbarByOrder = ( translate, active, orderId ) =>
    PaymentsLinkConfig(
        translate,
        [
            'orders',
            'order',
        ],
        {
            active,
            orderId,
        }
    );

