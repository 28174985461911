import React, { Component, } from 'react';
import { withLocalize, } from 'react-localize-redux';
import { withRouter, } from 'react-router-dom';
import { enumService, } from '../../../../../services/EnumDataService';

import { HorizontalMenu, } from '../../../components';

import PartnersRequestsMagicTable from './Table/PartnersRequestsMagicTable';
import PartnersSystemHeader from '../../ components/PartnersSystemHeader'
import { PARTNERS_REQUESTS_EXPORT_FIELDS } from './consts/PARTNERS_REQUESTS_EXPORT_FIELDS';

import {
	MagicFilterPanels,
	Mentor,
	showExportToExcelError,
} from '../../../../../components/Magic';
import PagePanel from '../../../../tima/components/Pages/PagePanel';

import { getPartnersRequests } from '../../../../../services/PartnersRequestService';
import PermissionService from "app/services/PermissionService";
import { securedRequest } from '../../../../../services/RequestService/index';

import * as configBreadcrumbs from './Config/BreadcrumbsConfig';
class PartnersRequests extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			enums: {},
			filterLastChange: Date.now(),
			isEmptyData: false,
			isOpenPopup: false,
			loaded: false,
			meta: {},
			pages: {
				filter: 0,
				take: 50,
				total: 0,
				variance: 2,
			},
			popupData: {},
		};

		const mf = new Mentor({
			owner: this,
			serviceId: 189,
			translate: this.props.translate,
		});

		Object.defineProperty(this, 'mf', { get: () => mf, });
	}

	async componentDidMount() {
		this.mf.subscribe({
			changed: this.mfChanged,
			cleaned: this.mfDone,
			cleaning: this.mfDoing,
			loaded: this.mfDone,
			loading: this.mfDoing,
			saved: this.mfDone,
			saving: this.mfDoing,
		}, this);

		await this.mf.init({ doEmit: true, });

		enumService.subscribe('enums', this.enumsChange, this);
		await enumService.enums;
	};

	UNSAFE_componentWillUnmount() {
		enumService.unsubscribe('enums', this.enumsChange, this);
		this.mf.unsubscribe([
			this.mfChanged,
			this.mfDoing,
			this.mfDone,
		], this);
	};

	save = async (state) => new Promise(next => this.setState(state, next));

	onChange = async (name, value) => await this.save({ [name]: value, })

	params = () => this.props.match.params;

	mfChanged = async () => {
		await this.save({ filterLastChange: Date.now(), });
	};

	mfDoing = async () => {
		await this.save({ loaded: false, });
	};

	mfDone = async () => {
		await this.mfChanged();
		await this.onDataLoad();
	};

	enumsChange = async (enums) => {
		if (enums.hash === this.state?.enums?.hash) {
			return `${this.constructor.name}.enumsChange: false`;
		}
		await this.save({ enums, });

		return `${this.constructor.name}.enumsChange: true`;
	};

	query = () => new URLSearchParams(this.props.location.search);

	getPermissions = (action) => {
		const pm = PermissionService.calc([{
			path: ['partners', 'requests'],
			key: 'user',
		}]);
		return pm.user.access(action);
	}

	exportToExcel = activeTab => () => {
		if (activeTab) {
			const { filterId: filter_id, } = this.mf;

			const globalUrl = `/api/partners/requests`;
			const exportUrl = `/export-to-excel`;
			let url = '';
			let file_name = '';

			switch (activeTab) {
				case 'requests': {
					url = `${globalUrl}${exportUrl}`;
					file_name = `requests`;
					break;
				}
				default: {
					break;
				}
			}

			const options = {
				fields: PARTNERS_REQUESTS_EXPORT_FIELDS[activeTab],
				file_name,
			};

			if (filter_id) {
				options['filter_id'] = filter_id;
			}

			securedRequest.post(url, options)
				.then((res) => {
					fileDownload(res.data, res.headers.filename);
				})
				.catch((error) => {
					showExportToExcelError(error);
				});
		}
	};

	pageId = () => {
		const page = this.query().get('page');
		return Number(page) || 0;
	};

	pageIdAsGET = (pageId) => {
		const pages = this.state.pages;

		pageId = pageId === undefined ? this.pageId() : pageId;
		const { filterId, } = this.mf;
		const result = {
			filterId,
			take: pages.take,
			skip: pageId * pages.take,
		};

		return result;
	};

	onDataLoad = async (pageId) => {
		await this.save({ loaded: false, isEmptyData: false, });

		const pageOptions = this.pageIdAsGET(pageId);
		const loaded = (data, meta) => ({ pages, }) => ({
			data,
			pages: { ...pages, ...meta, },
			...(!data.length && { isEmptyData: true, }),
		});

		try {
			const response = await getPartnersRequests(pageOptions);
			const { data = [], meta: { filter = 0, total = 0, }, } = response;

			await this.save(loaded(data, { filter, total, }));
		} catch (error) {
			await this.save(loaded([], { filter: 0, total: 0, }));
			showExportToExcelError(error);
		}
		await this.save({ loaded: true, });
	};


	onPageClick = async ({ event, pageId, pageIs, }) => {
		await this.onDataLoad(pageId);
	};

	onPageText = (pageId, pageIs) => {
		const { translate, } = this.props;
		const {
			current,
			first,
			prev,
			next,
			last,
		} = pageIs;
		const {
			skipped,
			taken,
			filter,
			total,
		} = pageIs;

		if (skipped || taken) {
			const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : "?";
			const text = skipped ? translate("partners_pages_items") : "";

			return skipped ? `${ text }: ${ id }` : id;
		} else if (filter || total) {
			const id = Number.isInteger(pageId) ? `${ pageId }` : "?";
			const text = translate(filter ? "partners_pages_filtered" : "partners_pages_total");

			return `${ text }: ${ id }`;
		} else if (first || prev || next || last) {
			return "";
		} else if (current) {
			return `${ pageId + 1 }`;
		}

		return `${ pageId + 1 }`;

	};

	togglePopup = async (popupData = {}) => {
		await this.save(state => ({
			isOpenPopup: !state.isOpenPopup,
			popupData,
		}));
	};

	enumsChange = async (enums) => {
		if (enums.hash === this.state?.enums?.hash) {
			return `${this.constructor.name}.enumsChange: false`;
		}
		await this.save({ enums, });
		return `${this.constructor.name}.enumsChange: true`;
	};


	renderActions = () => {
		const pm = PermissionService.calc([
			{
				key: "user",
				path: [ "core", "user", ],
			},
		]);

		const excel = this.getPermissions('exportToExcel');
		if (!excel) {
			return null;
		}

		const { translate, } = this.props;

		return (
			<div className=" top-button-area ">
				{excel ? (
					<div
						className="button bordered"
						onClick={ this.exportToExcel("requests") }
					>
						&nbsp;
						{translate(`managerPage_export_to_excel`)}
					</div>
				) : null}
			</div>
		);
	};

	render() {
		const { loaded, data, pages, enums, popupData, isOpenPopup } = this.state;
		const { translate } = this.props;

		const breadcrumbsItems = configBreadcrumbs.breadcrumbs4PartnersRequests;
		const permissionToShow = this.getPermissions('show');

		const head = [
			'partnerId',
			'partnerClient',
			'partnerManager',
			'partnerApplicationDate',
			'partnerProgram',
			'partnerStatus',
			'partnerConfirmated',
			'partnerReason',
			'partnerActions'
		]

		return (
			<div className="partners-partners">
				<PartnersSystemHeader translate={translate} breadcrumbsItems={breadcrumbsItems} actions={this.renderActions()} />

				<HorizontalMenu />

				{permissionToShow && <><MagicFilterPanels mf={ this.mf } show={ true } translate={ translate } />
					<PartnersRequestsMagicTable
					data={ data }
					enums={ enums?.data }
					head={ head }
					loaded={ loaded }
					mf={ this.mf }
					popup={ {
						data: popupData,
						isOpen: isOpenPopup,
						togglePopup: this.togglePopup,
					} }
					translate={ translate }
					onDataLoad={ this.onDataLoad }
				/>
				<PagePanel
					doText={ this.onPageText }
					doTo={ pageId => `?page=${ pageId }` }
					filter={ pages.filter }
					page={ this.pageId() }
					take={ pages.take }
					total={ pages.total }
					variance={ pages.variance }
					onClick={ this.onPageClick }
				/></>
				}
			</div>
		);
	}
}

export default withRouter(withLocalize(PartnersRequests));