import { map } from 'lodash';
import React from 'react';
import { withRouter } from 'react-router-dom';

import Panel from './Panel';

class Accordion extends React.Component {

    state = {
        activeTab: 0,
    };

    activateTab = ( index ) => () => this.setState( ( { activeTab, } ) => ( { activeTab: activeTab === index ? -1 : index, } ) );

    render = () => {
        const panels = this.props.panels;
        const { activeTab } = this.state;

        return (
            <div className = 'sideBarTaskList_accordion' >
                <div className = 'accordion'>
                    {map(panels, (panel, index) => {
                        return (<Panel
                            setStateModalTask={this.props.setStateModalTask}
                            activateTab={ this.activateTab( index ) }
                            activeTab={ activeTab }
                            index={ index }
                            key={ index }
                            panel={ panel }
                            translate={ this.props.translate }
                        />);
                    }
                    )}
                </div>
            </div>
        );
    };

}

export default withRouter(Accordion);
