import { isEmptySelectValue, } from "../../../utils/helpers";

export async function validateCreatePromoFields (formData, save) {
	const errors = Object.entries(formData).reduce((accumulator, entry) => {
		const [ key, value, ] = entry;

		const keyIsName = key === 'name';
		const keyIsLink = key === 'link';
		const valueOfSelectIsEmpty = typeof value === 'object' && key !== 'file' && isEmptySelectValue(value);
		const skipKey = key === 'id' || key==='source' || key === 'updated_at' || key === 'created_at';
		const emptyValue = !value;

		switch (true) {
			case skipKey: {
				return accumulator;
			}
			case keyIsName: {
				const sanitizedValue = value.trim().split('').filter((symbol) => {
					return symbol !== '.' && symbol !== '_';
				});

				const min = 1;
				const max = 60;

				return sanitizedValue.length > max || sanitizedValue.length < min ? [ ...accumulator, 'name', ] : accumulator;
			}
			case keyIsLink: {
				// const regExp = /^[a-zA-Zа-яА-ЯіІїЇєЄґҐёЁ0-9._?=&/:\- ]{1,200}$/;
				const valueIsNotEmpty = value.trim().length;

				return valueIsNotEmpty ? accumulator : [ ...accumulator, 'link', ];
			}
			case valueOfSelectIsEmpty: {
				return [ ...accumulator, key, ];
			}
			case emptyValue: {
				return [ ...accumulator, key, ];
			}
			default: {
				return accumulator;
			}
		}

	}, []);

	await save({ errors, });

	return !errors.length;
}

export async function validateArrayOfFormDatas (arrayOfFormDatas, save) {

	const errors = arrayOfFormDatas.reduce((accumulator, formDataItem, index) => {
		const errorsOfFormDatasItem = [];

		const mockSave = ({ errors, }) => errorsOfFormDatasItem.push(...errors);
		//usually save() sets state, but because we have array of formDatas we want to collect errors from all items and set them later
		//so mock just collect errors here, to utilize them later

		validateCreatePromoFields(formDataItem, mockSave);

		//add index to identify formData error
		const namedErrors = errorsOfFormDatasItem.map(item => `${ item }_${ index }`);

		return [ ...accumulator, ...namedErrors, ];
	}, []);

	await save(errors);

	return !errors.length;
}