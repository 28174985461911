import React, { Component, } from "react";
import PropTypes from "prop-types";
import "./styles.scss";

class Textarea extends Component {
	constructor (props) {
		super(props);

		const { value, selectionStart, selectionEnd, } = props;

		this.state = {
			value,
			selectionStart,
			selectionEnd,
		};
	}

	componentDidMount () {
		const { selectionStart, selectionEnd, } = this.state;

		this.setSelectionPos([ selectionStart, selectionEnd, ]);
	}

		UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
			if (nextProps.value !== this.state.value) {
				this.setState({ value: nextProps.value, });
			}
		};

		className = () => this.props.className;
		value = () => {
			return this.state.value || "";
		};

		async save (state) {
			const executor = resolve => this.setState(state, resolve);

			return new Promise(executor);
		}

		async onValid (value) {
			const { onValid, } = this.props;

			try {
				onValid && await onValid(value);

				return true;
			} catch (error) {}

			return false;
		}

		async onChange (event) {
			const { onChange, name, } = this.props;

			event.preventDefault();
			const { target, } = event;
			const { selectionStart, selectionEnd, value, } = target;

			const valid = await this.onValid(value);

			if (valid) {
						// await this.save( { focus: true, selectionStart, selectionEnd, } );
				await this.save({ selectionStart, selectionEnd, });
				onChange && await onChange({ target: { value, name, }, });
			} else {
				const { value, } = this.props;

				onChange && await onChange({ target: { value, name, }, });
			}
		}

		setSelectionPos = (selectionPos) => {
			if (!this.props.notToFocus) {
				if (selectionPos instanceof Array && selectionPos.length === 2) {
					this.ref.focus();
					[ this.ref.selectionStart, this.ref.selectionEnd, ] = selectionPos;
				} else {
					return false;
				}
			}
		};

		render = () => {
			const {
				label, isDisabled, maxLength, placeholder,
			} = this.props;

			return (
				<>
					{label && <label className="description-label">{label}</label>}
					<div className="magic-input input__field magic-textarea-wrapper">
						<textarea
							className={ this.className() }
							disabled={ isDisabled }
							maxLength={ maxLength }
							placeholder={ placeholder ?? "" }
							ref={ node => this.ref = node }
							value={ this.value() }
							onChange={ this.onChange.bind(this) }
						/>
					</div>
				</>
			);
		};
}

Textarea.propTypes = {
	className: PropTypes.string.isRequired,
	selectionEnd: PropTypes.number.isRequired,
	selectionStart: PropTypes.number.isRequired,
	isDisabled: PropTypes.bool,
	notToFocus: PropTypes.bool,
	value: PropTypes.string,
};

Textarea.defaultProps = {
	className: `magic-textarea`,
	value: `Type text in my magic-textarea!`,
	selectionStart: 0,
	selectionEnd: 0,
	isDisabled: false,
};

export default Textarea;
export { Textarea, };