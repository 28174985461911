import React, { Component, } from "react";
import { withLocalize, } from "react-localize-redux";
import Radio from "../Radio";

class CountSubpartnersBlockedReferrals extends Component {
	constructor (props) {
		super(props);
	}

	render = () => {
		const {
			translate,
			formData,
			fieldIsNotEdit: disabled,
			setNewValue,
		} = this.props;
		const considerReferralsLinkedToLocked =
			formData.consider_ref_linked_to_locked;

		return (
			<div className="new-partners-conditions__popup mb-20">
				<div className="w-30 mr-35">
					<Radio
						className={ `magic-radio-switch magic-radio-switch--accept-reject-btns magic-radio-switch--payment-confirmation` }
						classNameText={ "`magic-switch__text`" }
						direction={ "row" }
						handleOnChange={ value =>
							setNewValue("consider_ref_linked_to_locked", value.value)
						}
						isDisabled={ disabled }
						label={ translate("new_partners_confition_allow_blocked_referrals") }
						options={ [
							{ name: translate("symbols_remove_accept"), value: 1, },
							{ name: translate("symbols_remove_reject"), value: 2, },
						] }
						value={ considerReferralsLinkedToLocked }
						wrapperClassName={ "select__wrapper" }
					/>
				</div>
			</div>
		);
	};
	render () {
		const { formData, } = this.props;

		return <>{formData && this.CountSubpartnersBlockedReferrals()}</>;
	}
}

export default withLocalize(CountSubpartnersBlockedReferrals);