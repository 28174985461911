import React from 'react';
import { MagicFilterOperator, } from '../../components/MagicFilterOperator';
import { MagicSelect, } from '../../../MagicSelect';

export const onEnabled = state => async ({
  enabled = null,
  mentor,
  panel,
  $$changed = true,
}) => {
  const { owner, service, } = mentor;
  const { items, } = panel;
  const url = items?.['drop-down'];
  const E = enabled ?? +!panel.enabled;
  const filterChange = async (id, enabled, setter) => {
    await mentor.filterChange({
      id,
      setter: setter ?? { enabled: enabled ?? E, },
      $$changed,
    });
  };

  await filterChange(items.$id);
  if (items.$$parentId) {
    await filterChange(items.$$parentId);
  } else if (items.$$childId && !Array.isArray(items.$$childId)) {
    await filterChange(items.$$childId);
  } else if (items.$$childId && Array.isArray(items.$$childId)) {
    await Promise.all(items.$$childId.map(id => filterChange(id)));
  }

  if (owner && service && url && E && !owner.state.filtersDropDown?.[state]) {
    // eslint-disable-next-line require-await
    const save = async _ => new Promise(next => owner.setState(_, next));

    await save(({ filtersDropDown, }) => ({
      filtersDropDown: {
        ...filtersDropDown,
        [state]: [],
      },
    }));

    const data = await service.loadDropDown(url);

    await save(({ filtersDropDown, }) => ({
      filtersDropDown: {
        ...filtersDropDown,
        [state]: [ ...data, ],
      },
    }));
  }

  await filterChange(items.$id, null, {});
};

// eslint-disable-next-line react/display-name
export const render = (state, key = _ => _?.name ?? '-') => ({ mentor, panel, }) => {
  const { owner, } = mentor;
  const { items, } = panel;
  const data = owner.state.filtersDropDown?.[state] ?? [];
  const valueToLabel = _ => key(data.find(({ id, }) => id === _));
  const onChange = v => mentor.filterChange({
    id: items.$id,
    setter: { v, },
    $$changed: true,
  });

  const list = !Array.isArray(data) || !data.length ? '...' : (
    <MagicSelect
      className={ [
        'magic-filter-input',
        'magic-filter-input-enum',
        'magic-select__filter-input-enum',
      ] }
      disabled={ !panel.enabled }
      value={ items.value ?? 0 }
      valueDefault={ 0 }
      values={ data.map(({ id, }) => id) }
      valueToLabel={ valueToLabel }
      onChange={ onChange }
    />
  );

  return (<div><MagicFilterOperator filter={ items } />{ list }</div>);
};