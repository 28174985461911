/* eslint-disable radix */
import React, { Component, } from "react";
import { Translate, } from "react-localize-redux";
import { startCase, toLower, } from "lodash";
import moment from "moment";

import userAvatar from "../../../../../../../../images/partners-system/partner-card/partner-card-icon.svg";
import mavrikiy from "../../../../../../../../images/flags/license_country_flags/mavrikiy.svg";
import vanuaty from "../../../../../../../../images/flags/license_country_flags/vanuaty.svg";
import call from "../../../../../../../../images/call.svg";
import letter from "../../../../../../../../images/letter.svg";
import person from "../../../../../../../../images/person.svg";

import { MagicTooltip, } from "../../../../../../../tima/components/Magic";
import MessagePopupNew from "../../../../../../../clients/components/MessagePopup/MessagePopupNew";
import PermissionService, {
	MODULE_KEYS as pm,
} from "../../../../../../../../services/PermissionService";
import { callService, } from "../../../../../../../../services/CallDataService";
import { getAccountCountries, } from "../../../../../../../clients/services/AccountService";

import DataStorage from "../../../../../../../../helpers/DataStorage";
import {
	descriptionHelperCategory,
	descriptionHelperWarmType,
} from "../../../../../../../clients/services/descriptionHelper";

class InfoBar extends Component {
	constructor (props) {
		super(props);

		this.state = {
			accountCountries: [],
		};
	}

	async componentDidMount () {
		try {
			const response = await getAccountCountries();

			response && await this.save({ accountCountries: response, });
		} catch (error) {
			await this.save({ accountCountries: [], });
			error?.showErrorNotification?.();
		}
	}

	get translate () {
		return this.props.translate;
	}

	_getSelectedCountryById = (countryId) => {
		return (
			this.state.accountCountries.filter?.(
				item => item?.id?.toString() === countryId?.toString(),
			)?.[0] ?? null
		);
	};

	save = state => new Promise(next => this.setState(state, next));

	userDepartmentIds = () => {
		return [ this.props?.profile?.permission_template_id, ];
	};

	getAccountPropertyPermissions = (action, key) => {
		const permissions = PermissionService.calc(
			this.props.accountPropertyConfig,
		);

		return permissions[key].access(action);
	};

	getAccountStatus = () => {
		return parseInt(this.props?.account?.status);
	};

	_isAccountStatusArchived = () => this.getAccountStatus()?.toString() === "3"; // AccountStatusEnum

	renderDisabledContent = (condition) => {
		return condition ? (
			<div className="disabled-content" onClick={ this.onDisabledContentClick } />
		) :
			""
		;
	};

	onDisabledContentClick = (event) => {
		event.stopPropagation();
	};

	helperDescription = (id, type) => {
		const locale = DataStorage.getData("locale");

		if (type === "category") {
			return <>{descriptionHelperCategory[id][`name_${ locale }`]}</>;
		}
		if (type === "warmType") {
			return <>{descriptionHelperWarmType[id][`name_${ locale }`]}</>;
		}
	};

	statusFormatter (value) {
		const langCategoryAccount = {
			1: "client_category_lead_zero",
			2: "client_category_lead",
			3: "client_category_lead_one",
			4: "client_category_client_zero",
			5: "client_category_client_one",
			6: "client_category_client_two",
			7: "client_category_client_three",
		};

		const statusType = {
			"1": "bg-violet-blue",
			"2": "bg-shadow-blue",
			"3": "bg-grass-green",
			"4": "bg-deep-blue",
			"5": "bg-pink",
			"6": "bg-deep-yellow",
			"7": "bg-red-orange",
			"8": "bg-coral",
			"9": "bg-blood-red",
			// eslint-disable-next-line sort-keys
			"10": "bg-turquoise",
		};

		const curObj = statusType[value];

		const className = `table-status ${ curObj }`;

		return (
			<Translate>
				{({ translate, }) => {
					return (
						<div className={ className }>
							{translate(`${ langCategoryAccount[parseInt(value)] }`)}
						</div>
					);
				}}
			</Translate>
		);
	}
	renderAvatar = () => {
		return (
			<div className="lead-component__item lead-component__avatar">
				<span className="lead-img">
					<img alt="user" src={ userAvatar } />
				</span>
			</div>
		);
	};

	renderTitle = (translate) => {
		const client = this.props?.account;

		if ([ 2, ].includes(+client?.account?.register_type)) {
			//*
			const corporate =
				PermissionService.show(pm.CORE_ACCOUNT).can("name") &&
				client?.account?.name;
			const account_corporate = client?.account_corporate
				?.filter(a => +a?.is_admin === 1)
				.shift();
			const name =
				PermissionService.show(pm.CORE_ACCOUNT_CORPORATE).can("name") &&
				account_corporate?.name;
			const surname =
				PermissionService.show(pm.CORE_ACCOUNT_CORPORATE).can("surname") &&
				account_corporate?.surname;
			const fullName = [ name, surname, ].filter(x => x).join(" ");
			const license_alias = client?.dictionary_licenses?.alias;
			const license_translations =
				this.props.enums?.core?.CustomLicenseAliasEnum[license_alias] ?? "";
			const imageName = license_alias === "MAURITIUS" ? mavrikiy : vanuaty;

			return (
				<div className="lead-component__item lead-component__title">
					<span className="lead-info-name tooltip-wrapper">
						{corporate || "?"}
					</span>
					<span
						className="lead-info-name tooltip-wrapper"
						style={ { fontSize: "smaller", marginLeft: "12px", } }
					>
						{fullName || "?"}
					</span>
					<span
						className="lead-info-name tooltip-wrapper"
						style={ { fontSize: "smaller", marginLeft: "12px", } }
					>
						{` (${ translate("client_corporate_position") })` || "?"}
					</span>
					<span className="lead-info-title" style={ { marginLeft: "12px", } }>
						<span className="lead-info-title" style={ { marginLeft: "12px", } }>
							<img
								alt={ imageName }
								className="form-background"
								src={ imageName }
								style={ { height: "13px", marginRight: "10px", } } //*
							/>
							{license_translations}
						</span>
					</span>
				</div>
			);
		}
		const countCharName = 60;
		const name =
			PermissionService.show(pm.CORE_ACCOUNT).can("name") &&
			client?.account?.name;
		const surname =
			PermissionService.show(pm.CORE_ACCOUNT).can("surname") &&
			client?.account?.surname;
		const fullName = [ name, surname, ].filter(x => x).join(" ");
		const shortName =
			fullName.length > countCharName
				? `${ fullName.substring(0, countCharName) }...`
				: fullName;
		const tooltip = shortName !== fullName && (
			<div className="tooltip">{fullName}</div>
		);
		const license_alias = client?.dictionary_licenses?.alias;
		const license_translations =
			this.props.enums?.core?.CustomLicenseAliasEnum[license_alias] ?? "";
		const imageName = license_alias === "MAURITIUS" ? mavrikiy : vanuaty;

		return (
			<div className="lead-component__item lead-component__title">
				<div className="lead-info-name tooltip-wrapper">
					{shortName || "?"}
					{tooltip}
				</div>
				<span className="lead-info-title" style={ { marginLeft: "12px", } }>
					<img
						alt={ imageName }
						className="form-background"
						src={ imageName }
						style={ { height: "13px", marginRight: "10px", } }
					/>
					{license_translations}
				</span>
			</div>
		);
	};

	renderInfoMain = () => {
		return (
			<div
				className="lead-general-info lead-component__item lead-component__info"
				data-info="main"
			>
				<div className="lead-personal-info">
					<table className="lead-info-table">
						<tbody>
							{this.renderInfoCountry()}
							{this.renderInfoBirth()}
						</tbody>
					</table>
				</div>
			</div>
		);
	};

	renderInfoCountry = () => {
		const translate = this.translate;
		const show = PermissionService.show(pm.CORE_ACCOUNT).can("country_id");

		if (!show) {
			return "";
		}
		const client = this.props?.account;
		const accountRegisterType = client?.account?.register_type?.toString();
		const accountAddresses = client?.account_addresses;

		let targetAddressObj = {};

		// AccountRegisterTypeEnum: 1 - Individual, 2 - Business, 3 - Test
		switch (accountRegisterType) {
			case "1":
			case "3": {
				// INDIVIDUAL_FACTUAL_ADDRESS = 1
				targetAddressObj =
					accountAddresses?.filter(
						item => item?.type?.toString() === "1",
					)?.[0] ?? {};
				break;
			}
			case "2": {
				// CORPORATE_REGISTRATION_ADDRESS = 7
				targetAddressObj =
					accountAddresses?.filter(
						item => item?.type?.toString() === "7",
					)?.[0] ?? {};
				break;
			}
			default: {
				break;
			}
		}

		const country_id = targetAddressObj?.country_id;
		const selectedCountry = this._getSelectedCountryById(country_id);
		const name = selectedCountry?.name ?? "";
		const alpha_2 = selectedCountry?.alpha_2?.toLowerCase() ?? "";
		const COUNTRY_LENGTH_SHORT = 15;

		return (
			<tr>
				<td className="lead-info-title" style={ { whiteSpace: "nowrap", } }>
					{startCase(toLower(translate(`client_country`)))}
				</td>

				<td className="lead-info-text lead-info-text--country">
					<MagicTooltip
						classNameTooltip={ `tooltip` }
						content={ name }
						lengthCut={ COUNTRY_LENGTH_SHORT }
					/>
				</td>
				<td>
					<span
						className={ `flag-icon flag-icon-squared flag-icon-${ alpha_2 }` }
					/>
				</td>
			</tr>
		);
	};

	renderInfoBirth = () => {
		const translate = this.translate;
		const show = PermissionService.show(pm.CORE_ACCOUNT).can("birth_date");

		if (!show) {
			return "";
		}

		const client = this.props.account;
		const format = "YYYY-MM-DD";
		const dob = moment(client?.account?.birth_date, format); // date-of-birth
		const now = moment();
		const birth = dob.isValid() && dob.format("DD/MM/YYYY");
		const age = dob.isValid() && now.diff(dob, "years");
		const pie =
			dob.isValid() && Math.abs(dob.add(age, "years").diff(now, "d")) <= 3;

		return (
			<tr>
				<td className="lead-info-title" style={ { whiteSpace: "nowrap", } }>
					{translate(`client_birth_date`)}
				</td>
				<td className="lead-info-text" style={ { whiteSpace: "nowrap", } }>
					{birth} {age > 0 ? `(${ age })` : ""}
				</td>
				{pie && (
					<td>
						<i className="la la-birthday-cake" />
					</td>
				)}
			</tr>
		);
	};

	renderInfoPhone = () => {
		const translate = this.translate;
		const show = PermissionService.show(pm.CORE_ACCOUNT).can("phone");

		if (!show) {
			return "";
		}
		const phone = this.props?.account?.account.phone;
		const isVerified = +this.props?.account?.account.is_phone_verify;
		const notDecryptPhone = this.getAccountPropertyPermissions(
			"decryptPhone",
			"decryptData",
		);

		return (
			<>
				{notDecryptPhone && (
					<div
						className="lead-info lead-component__item lead-component__info"
						data-info="phone"
					>
						<div className="lead-info-title">{translate(`client_phone`)}</div>
						{/* <div className="lead-info-text">
					<div className="lead-info-text" id="show-phone">
					<span
					onClick={ () =>
						// eslint-disable-next-line max-len,
						callService.sendCall({
									account_id: this.props?.account?.account.id,
								})
							}
							>
							<img alt="call" data-is-verified={ isVerified } src={ call } />{" "}
							{translate(`tasks_createform_types_call`)}
							</span>
							</div>
						</div> */}
						<p className="lead-info-contacts">{phone}</p>
					</div>
				)}
			</>
		);
	};

	renderInfoEmail = () => {
		const translate = this.translate;
		const show = PermissionService.show(pm.CORE_ACCOUNT).can("email");

		if (!show) {
			return "";
		}

		const isVerified = +this.props?.account?.account.is_email_verify;
		const email = this.props?.account?.account.email ?? "";

		const cutEmail = `${ email.slice(0, 2) }...${
			email.includes("@") ? `@${ email.split("@")[1] }` : ""
		}`; //email can be passed without @, so different cut-styles required
		const notDecryptEmail = this.getAccountPropertyPermissions(
			"decryptEmail",
			"decryptData",
		);

		return (
			<>
				{notDecryptEmail && (
					<div
						className="lead-info lead-component__item lead-component__info"
						data-info="email"
					>
						<div className="lead-info-title">{translate(`client_email`)}</div>
						{/* <div
					className="lead-info-text"
					data-type="clientMessage"
					onClick={ (e) => {
						this.props.dropdownItemNav(e); //*
					} }
				>
				<span>
						<img alt="letter" data-is-verified={ isVerified } src={ letter } />
						{translate(`tasks_client_write`)}
					</span> */}
						{/* </div> */}

						<div className={ `tooltip-wrapper lead-info-contacts` }>
							{" "}
							{/* custom tooltip */}
							<div>{cutEmail}</div>
							<div className={ `tooltip lead-info-contacts` }>{email}</div>
						</div>
						{/*
							<div style={ { top: this.props?.togglePopupItem?.top, } }>
								{this.props?.togglePopupItem?.type === "clientMessage" && (
									<MessagePopupNew
										accountId={ String(this.props.account.account.id) }
										className={ "dropDown__modal_message" }
										languageId={ this.props.account?.account?.language_id }
										title={ this.props.translate("client_message_send") }
										translate={ this.props.translate }
										onCancel={ () => this.props.closePopupAction() } //*
										/>
										)}
									</div>
						*/}
					</div>
				)}
			</>
		);
	};

	renderInfoCategory = () => {
		const translate = this.translate;
		const show = PermissionService.store(pm.CORE_ACCOUNT_CATEGORIES).can(
			"category_id",
		);

		const categoryId = this.props.account?.account_categories?.category_id;
		const categoryName = this.statusFormatter(categoryId);

		if (!show) {
			return (
				<div
					className="lead-info lead-component__item lead-component__info"
					data-info="category"
				>
					<div className="lead-info-title">{translate(`client_category`)}</div>
					<div className="lead-info-text">{categoryName}</div>
				</div>
			);
		}

		return (
			<div
				className="lead-info lead-component__item lead-component__info"
				data-info="category"
			>
				<div className="lead-info-title">{translate(`client_category`)}</div>
				<div className="lead-info-text label-not-hover hintHelper-wrapper">
					<span
						className={ `select-account-category-label label-${ +categoryId }` }
					>
						{this.props.enums?.core?.AccountCategoryEnum[+categoryId]}
					</span>
					<div className="hintHelper hintHelper-bottom">
						{this.helperDescription(parseInt(categoryId), "category")}
					</div>
				</div>
			</div>
		);
	};

	renderInfoWarm = () => {
		const translate = this.translate;
		const show = PermissionService.show(pm.CORE_ACCOUNT).can("warm_type");

		const client = this.props?.account;
		const warmTypeId = client?.account?.warm_type;
		const warmTypeName = this.statusFormatter(warmTypeId);

		if (!show) {
			return (
				<div
					className="lead-info lead-component__item lead-component__info"
					data-info="warm"
				>
					<div className="lead-info-title">
						{translate(`client_sign_of_warmth`)}
					</div>
					<div className="lead-info-text">{warmTypeName}</div>
				</div>
			);
		}
		let { account_warm_rule_translation: warmTexts = null, } = client;

		warmTexts = warmTexts?.text;

		return (
			<div
				className="lead-info lead-component__item lead-component__info"
				data-info="warm"
			>
				<div className="lead-info-title">
					{translate(`client_sign_of_warmth`)}
				</div>
				<div className="lead-info-text label-not-hover hintHelper-wrapper">
					<span
						className={ `select-account-warm_type-label label-${ +warmTypeId }` }
					>
						{this.props.enums?.core?.AccountWarmTypeEnum[+warmTypeId]}
					</span>
					<div className="hintHelper hintHelper-bottom">{warmTexts}</div>
				</div>
			</div>
		);
	};

	renderInfoAssigned = () => {
		const translate = this.translate;
		const client = this.props?.account;
		const departmentIds = this.userDepartmentIds();
		const adminDepartmentIds = departmentIds.filter((
				id, //*
		) => [ 2, 4, 12, 14, ].includes(id));
		const hasAccess = !!adminDepartmentIds.length;
		const options = {
			...hasAccess && { onClick: this.userModal, },
		};

		return (
			<div
				className="lead-info lead-component__item lead-component__info"
				data-info="assigness"
			>
				<div className="lead-info-title">
					{translate(`client_assigned_to_responsible`)}
				</div>
				<div className="lead-info-text" { ...options }>
					{`${ client?.user?.name ?? "" } ${ client?.user?.surname ?? "" }`}
					<img alt="person" src={ person } />
				</div>
			</div>
		);
	};

	renderNewInBriefcase = () => {
		const translate = this.translate;
		const client = this.props?.account;

		return (
			<div
				className="lead-info lead-component__item lead-component__info"
				data-info="briefcase"
			>
				<div className="lead-info-title">
					{translate(`client_new_in_briefcase`)}
				</div>
				<div className="lead-info-text">
					{client?.account?.new_in_briefcase === 2
						? translate("client_no")
						: translate("client_yes")}
				</div>
			</div>
		);
	};

	renderProcessedInfo = () => {
		const translate = this.translate;
		const client = this.props?.account;

		return (
			<div
				className="lead-info lead-component__item lead-component__info"
				data-info="assigness"
			>
				<div className="lead-info-title">{translate(`client_processed`)}</div>
				<div className="lead-info-text">
					{
						this.props.enums?.core?.AccountIsProcessedEnum[
							client?.account?.is_processed
						]
					}
				</div>
			</div>
		);
	};

	renderInfoStatus = () => {
		const translate = this.translate;
		const show = PermissionService.show(pm.CORE_ACCOUNT).can(
			"is_verify_document",
		);

		if (!show) {
			return "";
		}
		const statusDocument = this.props?.account?.account.is_verify_document;

		//const subStatus = this.subStatus(client.account_status.status_id, client.account.id);
		return (
			<div
				className="lead-info lead-component__item lead-component__info"
				data-info="status"
			>
				<div className="lead-info-title">
					{translate(`client_status_document`)}
				</div>
				<div className="lead-info-text label-not-hover">
					<span
						className={ `select-account-status-document label-${ +statusDocument }` }
					>
						{this.props.enums?.core?.AccountVerifyDocumentEnum[+statusDocument]}
					</span>
				</div>
			</div>
		);
	};

	render () {
		return (
			<div
				className="sidebar-wrapper sideBar-leads"
				style={ { flexDirection: "column", } } //*
			>
				<div
					className={ `lead-block-wrapper ${
						this.state.fadeIn ? "overflow " : " "
					}${ this.state.fadeInPop ? "overflow" : "" }` } //*
				>
					<div
						className={ `lead-info-wrapper lead-component__page` }
						style={ { position: "relative", } }
					>
						{this.renderDisabledContent(this._isAccountStatusArchived())}
						{this.renderAvatar()}
						{this.renderTitle()}
						{this.renderInfoMain()}
						{this.renderInfoPhone()}
						{this.renderInfoEmail()}
						{this.renderInfoCategory()}
						{this.renderInfoWarm()}
						{this.renderInfoAssigned()}
						{this.renderInfoStatus()}
						{this.renderNewInBriefcase()}
						{this.renderProcessedInfo()}
						<div className="lead-component__item_last" />
					</div>
				</div>
			</div>
		);
	}
}

export default InfoBar;