import React, { Component, } from "react";
import { withRouter, } from "react-router-dom";
import { withLocalize, } from "react-localize-redux";
import { MagicButton, } from "../../../../../components/Magic";
import SymbolsGroupsMagicTable from "./Table/SymbolsGroupsMagicTable";
import * as configBreadcrumbs from "./Config/BreadcrumbsConfig";
import Breadcrumbs from "../../../../tima/components/Breadcrumbs";
import PermissionService from "../../../../../services/PermissionService";
import { enumService, } from "../../../../../services/EnumDataService";
import { HorizontalMenu, } from "../../../components";

class SymbolsGroups extends Component {
	constructor (props) {
		super(props);

		this.state = {
			enums: {},
			isOpenCreatePopup: false,
			popupData: {},
		};
	}

	async componentDidMount () {
		enumService.subscribe("enums", this.enumsChange, this);
		await enumService.enums;
	}

  componentWillUnmount() {
    enumService.unsubscribe('enums', this.enumsChange, this);
  }


  getPermissions = (action) => {
    const pm = PermissionService.calc([{
      path: ['partners', 'symbols_groups'],
      key: 'user',
    }]);

    return pm.user.access(action);
  }

  save = async (state) => new Promise(next => this.setState(state, next));

  enumsChange = async (enums) => {
    if (enums.hash === this.state?.enums?.hash) {
      return `${this.constructor.name}.enumsChange: false`;
    }
    await this.save({ enums, });

    return `${this.constructor.name}.enumsChange: true`;
  };

	toggleCreateUpdatePopup = async (popupData = {}) => {
		await this.save(state => ({
			isOpenCreatePopup: !state.isOpenCreatePopup,
			popupData,
		}));
	};

  renderButtonCreate = () => {
    const permissionToCreate = this.getPermissions('store')

    if (permissionToCreate) {
      const options = {
        className: 'magic-button__item magic-button__item_partners-create-symbol',
        children: this.props.translate('partners_symbol_group_create'),
        onClick: async () => await this.toggleCreateUpdatePopup(),
      };

      return (
        <MagicButton {...options} />
      );
    }

    return '';
  };

  render() {
    const { enums, isOpenCreatePopup, popupData, } = this.state;
    const { translate, } = this.props;
    const permissionToShow = this.getPermissions('show')
    const breadcrumbsItems = configBreadcrumbs.breadcrumbs4Symbols(translate);

    return (
      <div className="content-block">
        <div className="top-page-block">
          <div>
            <h1 className="page-title">{translate('partners_symbols_groups_table_list')}</h1>
            <Breadcrumbs items={breadcrumbsItems} />
          </div>
          {this.renderButtonCreate()}
        </div>
        <HorizontalMenu />
        {permissionToShow && <SymbolsGroupsMagicTable
          enums={enums}
          popup={{
            data: popupData,
            isOpen: isOpenCreatePopup,
            togglePopup: this.toggleCreateUpdatePopup,
          }}
        />}
      </div>
    );
  }
}

export default withRouter(withLocalize(SymbolsGroups));