import React, { Component } from "react";
import { withLocalize, } from "react-localize-redux";
import '../styles/tima.scss';
import Preloader from "../../../components/LoadingHOC/Preloader";
import PagePanel from './Pages';
import Loader from "./Loader";
import ListTimaStrategiesMagicTable from './ListTimaStrategiesMagicTable';
import { MagicFilterPanels, Mentor, } from '../../../components/Magic';
import { timaService } from '../../../services/TimaDataService'


class ListTimaStrategies extends Component {
  constructor (props) {
    super(props);

        this.state = {
            loaded:     false,
            strategies: {
                data: [],
                hash: null,
            },
            pages: {
                filter:   0,
                total:    0,
                take:     50,
                variance: 2,
            },
            filterLastChange: Date.now(),
        };

    const mf = new Mentor({
      owner: this,
      serviceId: 128,
      translate: this.props.translate,
    });

    Object.defineProperty(this, 'mf', { get: () => mf, });
    this.props.mf(mf);
  }

  // eslint-disable-next-line react/sort-comp, require-await
  save = async state => new Promise(next => this.setState(state, next));

  // eslint-disable-next-line require-await
  mfChanged = async () => {
    await this.save({ filterLastChange: Date.now(), });
  };

  mfDoing = async () => {
    await this.save({ loaded: false, });
  };

  mfDone = async () => {
    await this.mfChanged();
    await this.onDataLoad();
  };

    strategiesChanges = async (strategies) => {
        if (strategies.hash===this.state?.strategies?.hash) {
            return `${this.constructor.name}.strategiesChanges: false`;
        }
        if (strategies.meta.filter===this.state?.pages?.filter) {
            return `${this.constructor.name}.strategiesChanges: false`;
        }
        if (strategies.meta.total===this.state?.pages?.total) {
            return `${this.constructor.name}.strategiesChanges: false`;
        }
        const { data, meta, hash } = strategies;
        await this.save({
            strategies: { data, hash },
            pages: {
                ...this.state.pages,
                filter: meta?.filter,
                total: meta?.total,
            }
        });
        return `${this.constructor.name}.strategiesChanges: true`;
    };

    componentDidMount = async () => {
        this.mf.subscribe({
          changed: this.mfChanged,
          // changing: ,
          cleaned: this.mfDone,
          cleaning: this.mfDoing,
          loaded: this.mfDone,
          loading: this.mfDoing,
          saved: this.mfDone,
          saving: this.mfDoing,
        }, this);
        await this.mf.init({ doEmit: true, });
        timaService.subscribe('strategies', this.strategiesChanges, this);
        // await this.mf.emit('change', {});
        // await this.onDataLoad(); // TODO: moved to mfDone
  };

  componentWillUnmount = () => {
    this.mf.unsubscribe([
      this.mfChanged,
      this.mfDoing,
      this.mfDone,
    ], this);
      timaService.unsubscribe('strategies', this.strategiesChanges, this);
  };

    render = () => {
        const { translate } = this.props;
        const { strategies: { data }} = this.state;

    return (
      <>
        <MagicFilterPanels
          mf={ this.mf }
          show={ true }
          translate={ translate }
        />
        <div className='content-block'>
          <div className='table-wrapper'>
            <div className='position-relative'>

              <Loader
                loaded={this.state.loaded}
                loading={(<Preloader scale={this.props.scale}/>)}
                translate={translate}
              >
                <ListTimaStrategiesMagicTable
                  data={data}
                  enums={this.props?.enums}
                />

                <PagePanel
                  filter={this.state.pages.filter}
                  take={this.state.pages.take}
                  total={this.state.pages.total}
                  variance={this.state.pages.variance}
                  page={this.pageId()}
                  onClick={this.onPageClick}
                  doText={this.onPageText}
                  doTo={(pageId) => `?page=${ pageId }`}
                />
              </Loader>

            </div>
          </div>
        </div>
      </>
    );
  };

  pageId = () => {
    const { query } = this.props;
    const page = query.get('page');
    return Number(page) || 0;
  };

  pageIdAsGET = (pageId = undefined) => {
    pageId = pageId === undefined ? this.pageId() : pageId;
    const { filterId, } = this.mf;
    const result = {
      filterId,
      take: this.state.pages.take,
      skip: pageId * this.state.pages.take,
    };
    return result;
  };

  onDataLoad = async (pageId) => {
    await this.save({ loaded: false, });
    const pageOptions = this.pageIdAsGET(pageId);
    const loaded = (data, meta, hash) => ({ pages, }) => ({
        strategies: { data, hash },
        pages: { ...pages, ...meta, },
    });
    try {
      const { data, meta, hash } = await timaService.strategies(pageOptions);
      const { filter, total } = meta;
      await this.save(loaded(data, { filter, total, }, hash));
    } catch (error) {
      await this.save(loaded([], { filter: 0, total: 0, }));
      error?.showErrorNotification?.();
    }
    await this.save({ loaded: true, });
  };

  onPageClick = async ({ event, pageId, pageIs, }) => {
    // event.preventDefault();
    await this.onDataLoad(pageId);
  };

  onPageText = (pageId, pageIs) => {
    const { translate } = this.props;
    const { current, first, prev, next, last, } = pageIs;
    const { skipped, taken, filter, total, } = pageIs;
    if (skipped || taken) {
      const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : '?';
      const text = skipped ? translate('tima_strategies_pages_items') : '';
      return skipped ? `${ text }: ${ id }` : id;
    } else if (filter || total) {
      const id = Number.isInteger(pageId) ? `${ pageId }` : '?';
      const text = translate(filter ? 'tima_strategies_pages_filtered' : 'tima_strategies_pages_total');
      return `${ text }: ${ id }`;
    } else if (first || prev || next || last) {
      return '';
    } else if (current) {
      return `${ pageId + 1}`;
    } else {
      return `${ pageId + 1}`;
    }
  };
};

export default withLocalize(ListTimaStrategies);
