import React, { Component } from 'react';
import { MagicMultiSelect } from '../../../../components/Magic/components/MagicMultiSelect';
import PropTypes            from 'prop-types';
export class Categories extends Component {
   
   static propTypes = {
      disabled: PropTypes.bool,
      error: PropTypes.string,
      onSelect: PropTypes.func.isRequired,
      onUnSelect: PropTypes.func.isRequired,
      selectedValues: PropTypes.arrayOf(PropTypes.number).isRequired,
      translate: PropTypes.func.isRequired,
      values: PropTypes.objectOf(PropTypes.string).isRequired,
   };

   static defaultProps = {
      disabled: false,
      error: "",
   };

   render() {
      const values = Object
         .keys(this.props?.values ?? {})
         .map(v => +v)
         .filter(v => !this.props?.selectedValues?.includes?.(v));
      const valueDefault = false;
      const valueAll = 0;
      const options = {
         SelectProps: {
            className: [ 
               'magic-multi-select__select participants_category',
               this.props?.selectedValues?.length ? 'magic-multi-select__select_not-empty' : '',
               this.props?.error ? 'error': ''
            ].filter(c => c),},
         disabled: this.props?.disabled,
         selectedValues: this.props?.selectedValues,
         valueDefault,
         values: values?.length ? [valueAll, ...values] : [],
         ItemProps: value => [valueDefault].includes(value) ? {disabled: true,} : {},
         valueToLabel: value => {
            switch (value) {
               case valueDefault:
                  return this.props?.translate('contest_popup_select-category');
               case valueAll:
                  return this.props?.translate('contest_popup_select-all');
            }
            return this.props?.values?.[value] ?? '-';
         },
         onSelect: this.props?.onSelect,
         onUnSelect: this.props?.onUnSelect,
      };
      return (<MagicMultiSelect {...options} />);
   };
};
