/* eslint-disable react/forbid-component-props */
/* eslint-disable radix */
import React, { Component, } from "react";
import {
	Col, Nav, NavItem, Row, TabContent, TabPane,
} from "reactstrap";
import { Link, } from "react-router-dom";
import classnames from "classnames";

import PermissionService from "../../../../../../../services/PermissionService";

import getPartnerCardTabsConfig from '../../confg/TabsConfig';
import MainInformation from "./components/MainInformation/MainInformation";
import PromotionalCampaignsTable from "./components/PromotionalCampaigns/PromotionalCampaignsTable";
import ReferralsList from "../../../../Referrels/RefferalsList/RefferalsList"
import AffiliatePayouts from "../../../../AffiliatePayouts/AffiliatePayouts";
class PartnerCardTabs extends Component {
	get translate () {
		return this.props.translate;
	}

	save = state => new Promise(next => this.setState(state, next));

	getPermissions = (path, action) => {
		const pm = PermissionService.calc([ {
			path,
			key: 'user',
		}, ]);

		return pm.user.access(action);
	}
	renderDisabledContent = (condition) => {
		return condition ? (
			<div className="disabled-content" onClick={ this.onDisabledContentClick } />
		) :
			""
		;
	};

	onDisabledContentClick = (event) => {
		event.stopPropagation();
	};

	getAccountStatus = () => {
		return parseInt(this.props?.account?.account.status);
	};

	_isAccountStatusArchived = () => this.getAccountStatus()?.toString() === "3"; // AccountStatusEnum

	renderTabs = () => {
		const { translate, partner, partnerId, } = this.props;

		const partnerCardTabsConfig = getPartnerCardTabsConfig(partnerId);

		return partnerCardTabsConfig.reduce((accumulator, tab) => {
			const needPermissions = tab.permission;
			const hasPermissions = needPermissions ? this.getPermissions(tab.permission, 'index') : true;

			if (needPermissions && !hasPermissions) {
				return accumulator;
			}

			return [ ...accumulator,
				<NavItem>
					<Link
						className={ `nav-link ${ classnames({
							active: this.props.activeTab === tab.key,
							// disabled: this.state.navItemIsDisabled,
						}) } ${ tab.class }` }
						to={ tab.to }
					>
						{translate(tab.label)}
					</Link>
				</NavItem>,

			];
		}, []);
	}
	render () {
		const translate = this.translate;
		const {
			account,
			refreshClientData,
			partner,
			enums,
			isBackOfficeOrTechSupport,
		} = this.props;

		const accountId = partner.partners.partners_setup.account_id;

		// const { ACTIVE, FROZEN, } = PartnerRoleStatuses;
		// const pratnerRoleStatus = partner.partners.partners_setup?.status;
		// const disableCreateAccrualButton =  pratnerRoleStatus !== ACTIVE && pratnerRoleStatus !== FROZEN;
		// const disableAddRefferalButton =  pratnerRoleStatus !== ACTIVE;

		return (
			<div className="lead-page-tabs">
				<div className="lead-page-tabs-inner">
					<Nav tabs={ true }>
						{this.renderTabs()}
					</Nav>
					<TabContent
						activeTab={ this.props.activeTab }
						style={ { position: "relative", } }
					>
						{this.renderDisabledContent(this._isAccountStatusArchived())}

						{this.props.activeTab === "partner-card" ? (
							<TabPane tabId={ "partner-card" }>
								<MainInformation
									account={ account }
									enums={ enums }
									id={ account.account.id }
									isBackOfficeOrTechSupport={ isBackOfficeOrTechSupport }
									partner={ partner }
									refreshClientData={ refreshClientData }
									translate={ translate }
								/>
							</TabPane>
						) :
							""
						}
						{this.props.activeTab === "promotional-campaigns" ? (
							<TabPane
								className="tab-autoheight"
								tabId={ "promotional-campaigns" }
							>
								<PromotionalCampaignsTable
									enums={ enums }
									translate={ translate }
									accountId={accountId}
								/>
							</TabPane>
						) :
							""
						}
						{this.props.activeTab === "referrals" ? (
							<TabPane
								className="tab-autoheight"
								tabId={ "referrals" }
							>
								<ReferralsList
									accountId={accountId}
								/>
							</TabPane>
						) :
							""
						}
						{this.props.activeTab === "payouts" ? (
							<TabPane
								className="tab-autoheight"
								tabId={ "payouts" }
							>
								<AffiliatePayouts
									accountId={accountId}
								/>
							</TabPane>
						) :
							""
						}
					</TabContent>
				</div>
			</div>
		);
	}
}

export default PartnerCardTabs;