import React from 'react';
import { has, isInteger, isEmpty } from "lodash";

export default class EditAccessToArray extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            name: '',
        };
    }

    onChangeInput = (value) => {
        this.setState({
            name: value,
        });
    };

    onClearInput = () => {
        this.setState({
            name: '',
        });
    };

    returnListField = (listField) => {
        let value = listField.toString();

        if (has(this.props.dataField[this.props.dataField.length - 1], 'key')) {
            this.props.dataField.map((map, index) => {
                if (map.key.toString() === listField.toString()) {
                    value = map.value.toString();
                }
            });
        }

        return value;
    };

    returnKeyField = (keyField) => {
        let value = keyField.toString();

        this.props.dataField.map((map, index) => {
            if (map.value.toString() === value) {
                value = map.key.toString();
            }
        });

        return value;
    };

    returnList = () => {
        const field = this.props.getUserField(this.props.item, this.props.show, this.props.module, this.props.stateName);

        const keyField = [];

        if (has(this.props.dataField[this.props.dataField.length - 1], 'key')) {
            this.props.dataField.map((map, index) => {
                if (field.includes(map.key.toString())) {
                    keyField.push(map.value.toString());
                }
            });

            return (
                keyField.filter((data) =>
                    data.toLowerCase().includes(this.state.name.toLowerCase())).map((searchItem, index) => {
                    return (
                        <li key = { index }>
                            <span className = 'remove' onClick = { () => {this.props.removeField(this.props.item, this.props.show, this.props.module, this.props.stateName, this.returnKeyField(searchItem)) }} />
                            <p>{
                                searchItem
                            }</p>
                        </li>
                    );
                })
            );
        }
        field.forEach((item, i, arr) => {
            if (isInteger(item)) {
                field[i] = field[i].toString();
            }
        });

        return (
            field.filter((data) =>
                data.toLowerCase().includes(this.state.name.toLowerCase())).map((searchItem, index) => {
                return (
                    <li key = { index }>
                        <span
                            className = 'remove' onClick = { () => {
                                this.props.removeField(this.props.item, this.props.show, this.props.module, this.props.stateName, searchItem);
                            } }
                        />
                        <p>{
                            this.returnListField(searchItem)
                        }</p>
                    </li>
                );
            })
        );

    };

    render () {
        if (!this.props.isEditPopup) {
            return true;
        }

        if (isEmpty(this.props.dataField)) {
            return true;
        }

        return (
            <div style = { { float: 'left', width: '250px' } }>
                <div className = 'search form-group'>
                    <form>
                        <input
                            className = 'form-control'
                            onChange = { (e) => this.onChangeInput(e.target.value) }
                            onClick = { (e) => this.onClearInput(e.target.value) } type = 'text'
                            value = { this.state.name }
                        />
                    </form>
                </div>

                <div className = 'checkbox-popup-add'>
                    <ul>
                        {this.returnList()}
                    </ul>
                </div>
            </div>
        );
    }
}
