import React from 'react';
import {withRouter} from 'react-router-dom';
import {withLocalize} from "react-localize-redux";
import {bindActionCreators} from "redux";
import PreloaderIcon from 'react-preloader-icon';
import Oval from 'react-preloader-icon/loaders/Oval';
import {connect} from "react-redux";
import Preloader from 'app/components/Common/Preloader';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import PermissionService from "app/services/PermissionService";
import {isNull, map} from 'lodash';
import SideBarTaskList from "../../common/components/SideBarTaskList/index";
import {arrayOf} from 'prop-types';
import {MAIL_PROP_TYPES, USER_PROP_TYPES} from "../../../components/PropTypes/Clients_PropsTypes";
import {profileService} from "../../../services/ProfileDataService";


import {getFilterOptions, setFilterCategoryAccount} from '../services/AccountService';
import {
    clearFilterCategoryAccount,
    clearReduxAllClients,
    exportToExcel,
    getCallQueue,
    getFilterHashOne,
    getFilterHashTable,
    submitClearCallQueue,
    submitCreateCallQueue,
    submitDistribution,
} from '../services/AccountTableServise';

import {setMailTemplates, setSmsTemplates} from "../../dashboard/actions";

import {getClientsAsync, getFilterUsers} from "../services/AccountRequestService";
import PagePanel from "../../tima/components/Pages/PagePanel";
import Loader from "../../tima/components/Loader";
import {MagicFilterPanels, Mentor, post, showExportToExcelError,} from "../../../components/Magic";
import ClientsMagicTable from './ClientsMagicTable';
import MessagePopup from './MessagePopup';
import NotificationService from "../../../services/NotificationService";
import DistributionPopUp from './DistributionPopUp';
import ManagerDropdown from './ManagerDropdown';

class Clients extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			backdrop: 'static',
			preloader: false,
			preloaderButton: false,
			tableSort: false,
			output: {
			...this.props.output,
			},
			fieldsForChecking: this.props.fieldsForChecking,
			// chosenFilter: [],
			// advancedFilters: {},
			// queryString: '',
			// dropdownOpen: false,
			togglePopupItem: {
			bool: false,
			top: 0,
			type: null,
			},
			mailTemplates: this.props.mailTemplates,
			smsTemplates: this.props.smsTemplates,
			selectedType: {},
			templates: [],
			writeType: [],
			subject: '',
			subjectD: '',
			selectedTemplate: {},
			total: undefined,
			selectedUsers: [],
			distributionModal: false,
			distributionMode: false,
			filterUsers: null,
			selectedUserTo: null,
			// callQueue: {
			callQueueManagementMode: false,
			selectedUsersCallQueue: [],
					userCallQueueCounter: null,
			// },
			responseData: null,
			loaded: false,
			data: [],
			pages: {
			filter: 0,
			take: 50,
			total: 0,
			variance: 2,
			},
			isExportToExcelWIthFileBtnDisabled: false,
		};

		// this.last = last.bind(this);
		// this.next = next.bind(this);
		// this.prev = prev.bind(this);
		// this.first = first.bind(this);
		this.exportToExcel = exportToExcel.bind(this);
		// this.openModal = openModal.bind(this);
		// this.closeFilter = closeFilter.bind(this);
		// this.getFilteredData = getFilteredData.bind(this);

		const categoryId = this.params?.categoryId ?? 0;
		const mf = new Mentor({
			owner: this,
			serviceId: 8 + +categoryId,
			translate: this.props.translate,
		});

		Object.defineProperty(this, 'mf', { get: () => mf, });
	}

	profileChange = async (profile) => {
		if (profile.hash===this.state?.profile?.hash) return `${this.constructor.name}.profileChange: false`;
		await this.save({ profile, });
		return `${this.constructor.name}.profileChange: true`;
	};

	get clientsConfig () {
		return [ {
			key: 'account',
			path: ['core', 'account'],
		}, {
			key: 'user_call_queue_members',
			path: ['calls', 'user_call_queue_members'],
		}, ];
	}

	// eslint-disable-next-line react/sort-comp, require-await
	save = async state => new Promise(next => this.setState(state, next));

	mfChanged = async () => {
		await this.save({ filterLastChange: Date.now(), });
	}

	mfDoing = async () => {
		await this.save({ loaded: false, });
		await this.removeSelectedUsers();
		await this.removeSelectedUsersCallQueue();
	};

	mfDone = async () => {
		await this.mfChanged();
		await this.onDataLoad();
	};

	async componentDidMount () {
		this.props.setMailTemplates();
		this.props.setSmsTemplates();
		profileService.subscribe('profile', this.profileChange, this);
		/*this.props.getFilterHashTable(2, objectToQueryString(this.state.output)).then((response) => {
			this.setState({
				data: response.data.data,
				filterCount: response.data.meta.filter,
				total: response.data.meta.total
			});
		});*/

		this.mf.subscribe({
			changed: this.mfChanged,
			// changing: ,
			cleaned: this.mfDone,
			cleaning: this.mfDoing,
			loaded: this.mfDone,
			loading: this.mfDoing,
			saved: this.mfDone,
			saving: this.mfDoing,
		}, this);
		await this.mf.init({ doEmit: true, });
		// await this.mf.emit('change', {});
		// await this.onDataLoad(); // TODO: moved to mfDone
		await this.getCallQueueCounter();
	};

  componentWillUnmount () {
	this.mf.unsubscribe([
	  this.mfChanged,
	  this.mfDoing,
	  this.mfDone,
	], this);
	this.props.clearReduxAllClients();
	profileService.unsubscribe('profile', this.profileChange, this);
  }

	UNSAFE_componentWillReceiveProps (nextProps, prevProps) {
		this.setState({
			mailTemplates: nextProps?.mailTemplates,
			smsTemplates: nextProps?.smsTemplates,
		});
		const writeType = [
			{value: '1', label: 'dashboard_crm_email'},
			{value: '2', label: 'dashboard_sendpulse'},
			{value: '3', label: 'dashboard_sms'}
		];

		writeType?.map?.((item) => {
			item.label = this.props?.translate?.(`${item?.label}`);
		});

		const templates = {};
		const val = [1, 2, 3];

		map?.(val, (num) => {
			templates[num] = [];
		});

		map(nextProps?.mailTemplates, (item) => {
			templates?.[item?.['system_id']]?.push?.({
				value: item?.['template_id'],
				label: item?.['template_name'],
				subject: item?.['template_subject']
			});
		})
		map(nextProps?.smsTemplates, (item) => {
			templates?.[3]?.push?.({
				value: item?.core?.['sms_templates']?.id,
				label: item?.core?.['sms_templates']?.name,
				subject: item?.core?.['sms_templates']?.text
			});
		});

		[3].includes(+this.state?.selectedType?.value) ?
			this.setState(
				{
					templates: templates,
					writeType: writeType,
					selectedType: writeType?.[0],
					selectedTemplate: templates?.[writeType?.[0]?.value] ? templates?.[writeType?.[0]?.value]?.[0] : '',
					subject: '',
					subjectD: ''
				}
			) : this.setState(
			{
				templates: templates,
				writeType: writeType,
				selectedType: writeType?.[0],
				selectedTemplate: templates?.[writeType?.[0]?.value] ? templates?.[writeType?.[0]?.value]?.[0] : '',
				subject: templates?.[writeType?.[0]?.value] ? templates?.[writeType?.[0]?.value]?.[0] ? templates?.[writeType?.[0]?.value]?.[0]?.['subject'] : '' : '',
				subjectD: templates?.[writeType?.[0]?.value] ? templates?.[writeType?.[0]?.value]?.[0] ? templates?.[writeType?.[0]?.value]?.[0]?.['subject'] : '' : ''
			})

		if (nextProps !== prevProps) {
			return {
				preloader: false,
			};
		}

		return null;
	}

	get params () { return this.props.match.params; }

	get query () { return new URLSearchParams(this.props.location.search); }

	// ************************* NEW PAGINATION ****************************
	pageId = () => {
		const query = this.query;
		const page = query.get( 'page' );
		return Number( page ) || 0;
	};

	pageIdAsGET = ( pageId ) => {
		pageId = pageId === undefined ? this.pageId() : pageId;
		const { filterId, } = this.mf;
		const result = {
			filterId,
			take: this.state.pages.take,
			skip: pageId * this.state.pages.take,
		};
		return result;
	};

	onDataLoad = async ( pageId ) => {
		await this.save({ loaded: false, });
		const pageOptions = this.pageIdAsGET( pageId );
		const loaded = (data, meta) => ({ pages, }) => ({
			data,
			pages: { ...pages, ...meta, },
		});
		try {
			const response = await getClientsAsync(pageOptions);
			const { data, meta: { filter = 0, total = 0, }, } = response;
			await this.save(loaded(data, { filter, total, }));
		} catch ( error ) {
			await this.save(loaded([], { filter: 0, total: 0, }));
		}
		await this.save({ loaded: true, });

		// !!! TODO: DELETE A PEACE OF SHIT !!! HOT FIX: COSTYL SOLUTION BEFORE PRESENTATION !!!
		// this.props.getFilterHashTable(2, objectToQueryString(this.state.output)).then((response) => {
		//     this.setState({
		//         /*data: response.data.data,*/
		//         filterCount: response.data.meta.filter,
		//         total: response.data.meta.total
		//     });
		// });
	};

	onPageClick = ({ event, pageId, pageIs, }) => {
		// event.preventDefault();
		this.onDataLoad(pageId);
		this.removeSelectedUsers();
	};

	onPageText = (pageId, pageIs) => {
		const { translate } = this.props;
		const { current, first, prev, next, last, } = pageIs;
		const { skipped, taken, filter, total, } = pageIs;
		if (skipped || taken) {
			const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : '?';
			const text = skipped ? translate('partners_pages_items') : '';
			return skipped ? `${ text }: ${ id }` : id;
		} else if (filter || total) {
			const id = Number.isInteger(pageId) ? `${ pageId }` : '?';
			const text = translate(filter ? 'partners_pages_filtered' : 'partners_pages_total');
			return `${ text }: ${ id }`;
		} else if (first || prev || next || last) {
			return '';
		} else if (current) {
			return `${ pageId + 1}`;
		} else {
			return `${ pageId + 1}`;
		}
	};
	// *************************************************************************

	toggleDropdown = () => {
		this.setState({
			dropdownOpen: !this.state.dropdownOpen,
			togglePopupItem: {...this.state.togglePopupItem,
				bool: false,
			},
		});
	}

	closePopupAction = async () => {
		await this.save((prevProps) => {
			return {
				togglePopupItem: {
					...prevProps.togglePopupItem,
					bool: !prevProps.togglePopupItem.bool,
				},
			};
		});
		this.toggleDropdown();
	}

	dropdownItemNav = async (e) => {
		e.persist();

		await this.save({
			togglePopupItem: {
				...this.state.togglePopupItem,
				bool: false,
			},
		});

		let element = e.target;
		let positionTop = `${e.clientY - (e.clientY - e.target.offsetTop) - 50}px`;

		while(!element.getAttribute('data-type')){
			element = element.parentElement;
			if(!isNull( element.getAttribute('data-type'))){
				return this.setStatePopupItem(element.getAttribute('data-type'), positionTop)
			}
		}
		return this.setStatePopupItem(element.getAttribute('data-type'), positionTop)
	}

	setStatePopupItem = (type, positionTop) => {
		this.setState((prevProps)=>{
			return {togglePopupItem: {...prevProps.togglePopupItem,
					bool: !prevProps.togglePopupItem.bool,
					type,
					top: positionTop
				}}
		});
	}

	saveDropDown = async () => {
		await this.save({ dropdownOpen: true });
	};

	exportToExcelBtnClick = (event) => {
		const { filterId } = this.mf;
		const permissions = PermissionService.calc(this.clientsConfig);

		const checkAccess = (item) => {
			if (item === 'core-account-email') {
				return permissions?.account?.access('decryptEmail');
			} else if (item === 'core-account-phone') {
				return permissions?.account?.access('decryptPhone');
			}

			return true;
		};

		this.exportToExcel(filterId, checkAccess);
	};

	exportToExcelWithFileBtnClick = async (event) => {
		const { isExportToExcelWIthFileBtnDisabled } = this.state;
		const { translate } = this.props;
		const url = `/api/core/account/export-to-excel-with-file`;

		const _showExportToExcelTypeFileError = () => {
			NotificationService.error({
				title: "error",
				message: translate('client_export_to_excel_type_file_error'),
				remove: false,
			});
		};

		if (isExportToExcelWIthFileBtnDisabled) {
			return;
		}

		await this.save({ isExportToExcelWIthFileBtnDisabled: true });

		if (this.exportToExcelWithFileInput && this.exportToExcelWithFileInput?.files?.length) {
			const selectedFile = this.exportToExcelWithFileInput.files[0];

			if (/\.xlsx$/i.test(selectedFile?.name?.toLowerCase())) {
				const formData = new FormData();

				formData.append('file_name', 'Clients');
				formData.append('file', selectedFile);

				try {
					const response = await post(url, formData);
				} catch (error) {
					showExportToExcelError(error);
				}
			} else {
				_showExportToExcelTypeFileError();
			}
			this.exportToExcelWithFileInput.value = ''; // reset
		} else {
			_showExportToExcelTypeFileError();
		}

		await this.save({ isExportToExcelWIthFileBtnDisabled: false });
	};

	renderExportToExcelBtn = () => {
		const { translate } = this.props;
		const conf = PermissionService.calc(this.clientsConfig);
		const exportToExcelPermission = conf?.account?.access('exportToExcel') ?? false;

		return (
			exportToExcelPermission ?
				(
					<div
						className={`button button${this.state.distributionMode ? '--disabled' : '--turquoise'}`}
						onClick={this.exportToExcelBtnClick}
					>
						{translate('export_excel_btn')}
					</div>
				) : false

		);
	};

	renderExportToExcelWIthFileBtn = () => {
		const { translate } = this.props;
		const { isExportToExcelWIthFileBtnDisabled } = this.state;
		const btnClassName = isExportToExcelWIthFileBtnDisabled ? 'button--disabled' : '';

		const conf = PermissionService.calc(this.clientsConfig);
		const exportToExcelWIthPermission = conf?.account?.access('exportToExcelWithFile') ?? false;

		return (
			exportToExcelWIthPermission ?
				(
					<React.Fragment>
						<div className={`button button${this.state.distributionMode ? '--disabled' : '--turquoise'} ${btnClassName}`} style={ { 'width': '210px' } }
							onClick={ () => { this.exportToExcelWithFileInput?.click() } }
						>
							{translate('export_excel_with_file_btn')}
						</div>

						<input type="file"
							accept=".xlsx"
							onChange={ this.exportToExcelWithFileBtnClick }
							ref={node => (this.exportToExcelWithFileInput = node)}
							style={ { 'display': 'none' } }
						/>
					</React.Fragment>
				) : false

		);
	};

	renderPrivateMessage = (userId, close) => {
		return (
			<MessagePopup
				close = { close }
				templates = { this.state.templates }
				userId = { userId }
				writeType = { this.state.writeType.filter((item) => item.value !== "3") }
			/>
		);
	}

	renderBack () {
		if (this.state.dropdownOpen) {
			return <div onClick={this.toggleDropdown} style={{width: '100%', height: '100%', position:'absolute', top:0, left: 0, zIndex:9}}></div>
		}
	}

	toggleDistributionModal = async () => this.save(s => ({ distributionModal: !s.distributionModal }));

	getFilterUsers = async () => {
		try {
			let { selectedUserTo } = this.state;
			const filterUsers = await getFilterUsers() ?? [];

			selectedUserTo = selectedUserTo && filterUsers.some((user) => user.id === selectedUserTo.id) ? selectedUserTo : null;
			await this.save({ filterUsers, selectedUserTo });
		} catch (error) {
			error?.showErrorNotification?.();
		}
	}

	getCallQueueCounter = async () => {
		const conf = PermissionService.calc(this.clientsConfig);
		const hasPermissions = conf?.user_call_queue_members?.access('getQueue');

		if (hasPermissions) {
			try {
				const data = { user_id: this.state?.profile?.data?.id };
				const { count } = await getCallQueue(data);

				this.save({ userCallQueueCounter: count ?? 0 });
			} catch (error) {
				error?.showErrorNotification?.();
			}
		}
	}

	toggleDistributionMode = async () => {
		const { filterUsers } = this.state;

		if (!filterUsers || !this.state.distributionMode) {
			await this.getFilterUsers();
		}

		return this.save(s => ({ distributionMode: !s.distributionMode }));
	};

	toggleCallQueueManagementMode = () => {
		// const { filterUsers } = this.state;

		// if (!this.state.callQueueManagementMode) {
		//     await this.getFilterUsersForCalls();
		// }

		return this.save(s => ({ callQueueManagementMode: !s.callQueueManagementMode }));
	}

	handleSelectFilterUser = async (item) => await this.save({ selectedUserTo: item });

	removeSelectedUsers = async () => await this.save({ selectedUsers: []});

	removeSelectedUsersCallQueue = async () => await this.save({ selectedUsersCallQueue: []});

	renderDistributionButton = () => {
		const { translate } = this.props;
		const conf = PermissionService.calc(this.clientsConfig);
		const hasPermissions = conf?.account?.access('changeManagers');

		if (hasPermissions) {
			return !this.state.distributionMode ? (
				<div
					className = { `button filter_btn-delegation` }
					onClick = { () => this.toggleDistributionMode() }
				>
					{translate('group_assignment')}
				</div>
			) : (
				<>
					<ManagerDropdown
						data = { this.state.filterUsers }
						handleSelect = { this.handleSelectFilterUser }
						selected = { this.state.selectedUserTo }
					/>
					<div
						className = { `button filter_btn-delegation ${this.state.selectedUsers.length > 0 && this.state.selectedUserTo ? '' : 'disabled'}` }
						onClick = { () => this.handleSubmitDistribution() }
					>
						{translate('tasks_change_responsible')}
					</div>
					<div
						className = { `button filter_btn-remove_delegation` }
						onClick = { () => {
							this.removeSelectedUsers();
							this.toggleDistributionMode();
						} }
					>
						{translate('tasks_createform_cancel')}
					</div>
				</>
			);
		}
	}

	renderCallQueueManagementButton = () => {
		const { translate } = this.props;
		const conf = PermissionService.calc(this.clientsConfig);
		const hasPermissions = conf?.user_call_queue_members?.access('store');

		if (hasPermissions) {
			return !this.state.callQueueManagementMode ? (
				<div
					className = { `button filter_btn-call_queue` }
					onClick = { () => this.toggleCallQueueManagementMode() }
				>
					{translate('call_queue_management')}
				</div>
			) : (
				<>
					<div
						className = { `button filter_btn-call_queue ${this.state.selectedUsersCallQueue.length > 0 ? '' : 'disabled'}` }
						onClick = { () => this.handleSubmitCreateCallQueue() }
					>
						{translate('call_queue_members_add')}
					</div>

										<div
											className={ `button filter_btn-call_queue ${this.state.userCallQueueCounter > 0 ? '' : 'hidden'}` }
											onClick={() => this.handleSubmitClearCallQueue() }
										>
											{translate('call_queue_members_clr')}
										</div>

										<div
						className = { `button filter_btn-remove_call_queue` }
						onClick = { () => {
							this.removeSelectedUsersCallQueue();
							this.toggleCallQueueManagementMode();
						} }
					>
						{translate('tasks_createform_cancel')}
					</div>
				</>
			);
		}
	}

	handleSelectUser = async (isChecked, id) => {
		const { selectedUsers, } = this.state;
		let selectedUsersNew = isChecked
			? selectedUsers.filter(
				Array.isArray(id)
					? _ => !id.includes(_)
					: _ => _ !== id
			)
			: [ ...new Set([
				...selectedUsers,
				...Array.isArray(id) ? id : [ id, ],
			]), ];
		await this.save({ selectedUsers: selectedUsersNew, });

	}

	handleSelectUserCallQueue = async (isChecked, id) => {
		const { selectedUsersCallQueue, } = this.state;
		let selectedUsersCallQueueNew = isChecked
			? selectedUsersCallQueue.filter(
				Array.isArray(id)
					? _ => !id.includes(_)
					: _ => _ !== id
			)
			: [ ...new Set([
				...selectedUsersCallQueue,
				...Array.isArray(id) ? id : [ id, ],
			]), ];
		await this.save({ selectedUsersCallQueue: selectedUsersCallQueueNew, });
	}

	handleSubmitDistribution = async () => {
		const data = { user_id: this.state.selectedUserTo.id, account_ids: this.state.selectedUsers };
		const responseData = await submitDistribution(data);

		if (responseData) {
			await this.save({ responseData });
			await this.toggleDistributionModal();
			await this.removeSelectedUsers();
			await this.getFilterUsers();
			await this.onDataLoad();
		}
	}

	handleSubmitCreateCallQueue = async () => {
		const data = { user_id: this.state?.profile?.data?.id, account_ids: this.state.selectedUsersCallQueue };
		const responseData = await submitCreateCallQueue(data);

		if (responseData) {
			await this.save({ responseData });
			await this.toggleCallQueueManagementMode();
			await this.removeSelectedUsersCallQueue();
		}

		this.getCallQueueCounter();
	}

	handleSubmitClearCallQueue = async () => {
		const data = { user_id: this.state?.profile?.data?.id };
		const response = await submitClearCallQueue(data);

		if (response.status === 200) {
			this.save({ userCallQueueCounter: null });
		}
	}

	render = () => {
		const { translate } = this.props;

		const preload = this.state.preloaderButton
			? <PreloaderIcon
				duration = { 800 }
				loader = { Oval }
				size = { 20 } // min: 1, max: 50
				strokeColor = '#0096ff'
				strokeWidth = { 10 }
			/>
			: '';

		return (
			<React.Fragment>
				{this.renderBack()}
				{this.state.distributionModal ?
					<DistributionPopUp
						close = { () => {
							this.toggleDistributionModal();
							this.onDataLoad();
						} }
						response = { this.state.responseData }
					/>
					: ''}
				<div className = 'content-block'>
					<div className = 'top-page-block' style = {{border: 'none'}} >
						<h1 className = 'page-title'>{translate(`client_clients`)}</h1>
						<div className = ' top-button-area '>
							{this.renderCallQueueManagementButton()}
							{this.renderDistributionButton()}
							{this.renderExportToExcelWIthFileBtn()}

							{this.renderExportToExcelBtn()}
						</div>
					</div>
				</div>

				<MagicFilterPanels
					mf={ this.mf }
					show={ true }
					translate={ translate }
				/>

				<div className = 'content-block'>
					<div className = 'table-wrapper'>
						<div className = 'position-relative'>
							<Loader
								loaded={this.state.loaded}
								loading={(<Preloader scale={this.props.scale}/>)}
								translate={translate}
							>
								<ClientsMagicTable
									data={ this.state?.data }
									emailPopup={ this.renderPrivateMessage }
									order={ async (orderId) => {
										await this.removeSelectedUsers();
										await this.mf.orderedToNext(orderId);
										// await this.onDataLoad(); // TODO: remove cause reaction should be in subscribe
									} }
									orderTo={ (orderId) => {
										return this.mf.orderedTo(orderId);
									} }
									selectedUsers = {
										this.state.callQueueManagementMode
											? this.state.selectedUsersCallQueue
											: this.state.selectedUsers
									}
									selectUser = {
										this.state.callQueueManagementMode
											? this.handleSelectUserCallQueue
											: this.handleSelectUser
									}
									showCheckbox = { this.state.distributionMode || this.state.callQueueManagementMode }
									selectUserPermission={({ access, items, }) => {
										if (this.state.distributionMode) {
											return access('changeManagers');
										} else if (this.state.callQueueManagementMode) {
											return items.storeMemberQueue.access('store');
										}

										return false;
									}}
								/>
								<PagePanel
									doText={this.onPageText}
									doTo={(pageId) => `?page=${ pageId }`}
									filter={this.state.pages.filter}
									page={this.pageId()}
									take={this.state.pages.take}
									total={this.state.pages.total}
									variance={this.state.pages.variance}
									onClick={this.onPageClick}
								/>
							</Loader>

						</div>
						<SideBarTaskList />
					</div>
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (store) => {
	return {
		user:        store.user,
		output:      store.clients.output,
		sort:        store.clients.sort,
		options:     store.clients.options,
		mailTemplates: store.dashboard.mailTemplates,
		smsTemplates: store.dashboard.smsTemplates,
		filterHash:  store.clients.filterHash
	};
};

Clients.propTypes = {
	user: USER_PROP_TYPES,
	mailTemplates:  arrayOf(MAIL_PROP_TYPES),
};

export default withRouter(withLocalize(connect(
	mapStateToProps,
	(dispatch) =>
		bindActionCreators({
			getFilterOptions, // TODO: remove line as old
			clearReduxAllClients,
			clearFilterCategoryAccount,
			setMailTemplates,
			setSmsTemplates,
			getFilterHashTable,
			getFilterHashOne,
			setFilterCategoryAccount
		}, dispatch)
)(Clients)));
