/* eslint-disable sort-keys */
const configHorizontalMenu = (translate, permissions = null) =>
	[
		// Управление партнерскими программами
		{
			title: translate("partners_system_categories_affiliate_programs"),
			config: [
				{
					title: translate("menu_partners_system_affiliate_programs"),
					key: "affiliate_programs",
					path:
						"/partners/affiliate-programs-management/affiliate-programs",
				},
				{
					title: translate("menu_partners_system_conditions_for_accruals"),
					key: "new_partners_conditions",
					path:
						"/partners/affiliate-programs-management/conditions-for-accruals",
				},
				{
					title: translate(
						"menu_partners_system_country_list_with_payouts_rates",
					),
					key: "payout_rate_lists",
					path: "/partners/affiliate-programs-management/payout-rate-lists",
				},
				{
					title: translate("menu_partners_system_symbols"),
					key: "symbols",
					path: "/partners/affiliate-programs-management/symbols",
				},
				{
					title: translate("menu_partners_symbols_lists"),
					key: "symbols_lists",
					path: "/partners/affiliate-programs-management/symbols-lists",
				},
				{
					title: translate("menu_partners_system_symbols_groups"),
					key: "symbols_groups",
					path: "/partners/affiliate-programs-management/symbols-groups",
				},
				{
					title: translate("menu_partners_system_account_groups_list"),
					key: "groups_lists",
					path: "/partners/affiliate-programs-management/account-groups-lists",
				},
			],
		},
		  //Affiliate payouts
		  {
			title: translate('partners_system_categories_affiliate_payouts'),
			config: [
			  {
					title: translate('partners_system_affiliate_payouts_history'),
					key: 'accruals',
					path: '/partners/partner-payouts',
			  },
			],
		  },
		//Partners
		{
			title: translate("partners_system_categories_partners"),
			config: [
				{
					title: translate("partners_system_partners_list"),
					key: "partners",
					path: "/partners/requests/partners-list",
				},
				{
					title: translate("partners_system_partners_requests"),
					key: "requests",
					path: "/partners/requests/partnership-applications",
				},
			],
		},
		// Рефералы
		{
		  title: translate('menu_partners_referrer'),
		  config: [
		    {
		      title: translate('menu_partners_system_referrer_list'),
		      key: 'referrals',
		      path: '/partners/referrels/referrals-list',
		    },
		  ],
		},
		// // Статистика
		// {
		//   title: translate('menu_partners_system_statistic'),
		//   config: [
		//     {
		//       title: translate('partners_system_statistics_conversions'),
		//       key: 'statistic',//*
		//       path: '/partners/statistic/statistic' // todo добавить после statistic название таблицы (пока таблицы нету)
		//     },
		//     {
		//       title: translate('partners_system_statistics_companies'),
		//       key: 'referrals',//*
		//       path: '/partners/statistic/companies'
		//     },
		//     {
		//       title: translate('partners_system_statistics_partners'),
		//       key: 'requests',//*
		//       path: '/partners/statistic/partners'
		//     },
		//     {
		//       title: translate('partners_system_statistics_referals'),
		//       key: 'groups_lists',//*
		//       path: '/partners/statistic/referals'
		//     },
		//     {
		//       title: translate('partners_system_statistics_countries'),
		//       key: 'symbols_groups',//*
		//       path: '/partners/statistic/countries'
		//     },
		//     {
		//       title: translate('partners_system_statistics_accounts'),
		//       key: 'symbols',//*
		//       path: '/partners/statistic/accounts'
		//     },
		//     {
		//       title: translate('partners_system_statistics_days'),
		//       key: 'payout_rate_lists',//*
		//       path: '/partners/statistic/days'
		//     },
		//     {
		//       title: `${translate('partners_system_statistics_days')} - CPA`,
		//       key: 'payout_rate_lists',//*
		//       path: '/partners/statistic/days-cpa'
		//     },
		//     {
		//       title: `${translate('partners_system_statistics_days')} - CPL`,
		//       key: 'payout_rate_lists',//*
		//       path: '/partners/statistic/days-cpl'
		//     },
		//     {
		//       title: `${translate('partners_system_statistics_days')} - In/Out`,
		//       key: 'payout_rate_lists',//*
		//       path: '/partners/statistic/days-inout'
		//     },
		//     {
		//       title: `${translate('partners_system_statistics_days')} - Revshare`,
		//       key: 'payout_rate_lists',//*
		//       path: '/partners/statistic/days-revshare'
		//     },

		//   ],
		// },
		// //Promomaterials
		{
		  title: translate('partners_manage_promo'),
		  config: [
			  {
			    title: translate("partners_banners"),
			    key: 'banners',
			    path: '/partners/promo/banners',
			  },
			  {
			    title: translate("partner_target_page"),
			    key: 'target-page',
			    path: '/partners/promo/target-page',
			  },
			  {
			    title: translate("partner_logos"),
			    key: 'logos',
			    path: '/partners/promo/logos',
			  },
			  {
			    title: translate("partners_lendings"),
			    key: 'lendings',
			    path: '/partners/promo/lending',
			  },
		    // {
		    //   title: translate("partners_system_promomaterials_managment"),
		    //   key: 'requests',//*
		    //   path: '/partners/promo-materials'
		    // },
		    // {
		    //   title: translate("partners_system_promomaterials_statistics"),
		    //   key: 'groups_lists',//*
		    //   path: '/partners/promo-materialsa'
		    // },
		  ],
		},
		// Rebate
		{
		  title: translate('partners_rebate'),
		  config: [
		    {
		      title: translate('partners_rebate_projects'),
		      key: 'rebate',
		      path: '/partners/rebate',
		    },
		  ],
		},
		// Dashboards calculations
		{
			title: translate("menu_partners_system_dashboard_calculation"),
			config: [
				{
					title: translate("menu_partners_system_dashboard_calculation"),
					key: "calculationsDashboard",
					path: "/partners/dashboards/calculations-dashboard",
				},
				{
					title: translate("menu_partners_system_dashboard_calculation_payout"),
					key: "calculationsPayout",
					path: "/partners/dashboards/payouts-dashboard",
				},
				{
					title: translate("menu_partners_system_waiting_switch"),
					key: "awaitingPartners",
					path: "/partners/dashboards/partners-waiting-switch",
				},
				{
					title: translate("menu_partners_referrals_waiting_switch"),
					key: "awaitingReferrals",
					path: "/partners/dashboards/referrals-waiting-switch",
				},
			],
		},
		// // Rebate
		// {
		//   title: translate('partners_system_categories_rebate'),
		//   config: [
		//     {
		//       title: translate('partners_system_rebate_partners'),
		//       key: 'referrals',//*
		//       path: '/partners/rebate'
		//     },
		//   ],
		// },
		// // Managers
		// {
		//   title: translate('partners_system_categories_managers'),
		//   config: [
		//     {
		//       title: translate('partners_system_managers_partners'),
		//       key: 'referrals',//*
		//       path: '/partners/managers'
		//     },
		//   ],
		// },
	]
		.map(item => ({
			...item,
			config: item.config.filter(({ key, }) => {
				if (permissions) {
					return permissions?.[key]?.access("index") ?? false;
				}

				return true;
			}),
		}))
		.filter(({ config, }) => config.length);

export default configHorizontalMenu;