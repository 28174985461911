export const descriptionHelperCategory = {
    1: {
        name_en: "Completed registration. Specified surname, name or company name and e-mail",
        name_ru: "Прошел регистрацию. Указал фамилию, имя либо название компании и e-mail",
        name_uk: "Зареєструвався. Вказав прізвище, ім'я або назва компанії та e-mail",
    },
    2: {
        name_en: "Email confirmation passed",
        name_ru: "Прошел подтверждение электронной почты",
        name_uk: "Пройшов підтвердження електронної пошти",
    },
    3: {
        name_en: "Uploaded at least one document for verification",
        name_ru: "Загрузил минимум один документ на верификацию",
        name_uk: "Завантажив мінімум один документ на верифікацію",
    },
    4: {
        name_en: "Passed full verification",
        name_ru: "Прошел полную верификацию",
        name_uk: "Пройшов повну верифікацію",
    },
    5: {
        name_en: "Made the first deposit after verification",
        name_ru: "Внес первый депозит после верификации",
        name_uk: "Вніс перший депозит після верифікації",
    },
    6: {
        name_en: "Made the first deposit after switching to the CLIENT 1 category",
        name_ru: "Внес первый депозит после перехода в категорию КЛИЕНТ 1",
        name_uk: "Вніс перший депозит після переходу в категорію КЛІЄНТ 1",
    },
    7: {
        name_en: "Made the first deposit after switching to the CLIENT 2 category",
        name_ru: "Внес первый депозит после перехода в категорию КЛИЕНТ 2",
        name_uk: "Вніс перший депозит після переходу в категорію КЛІЄНТ 2",
    },
};

export const descriptionHelperWarmType = {
    1: {
        name_en: "Test 1",
        name_ru: "Тест 1",
        name_uk: "Тест 1",
    },
    2: {
        name_en: "Test 2",
        name_ru: "Тест 2",
        name_uk: "Тест 2",
    },
    3: {
        name_en: "Test 3",
        name_ru: "Тест 3",
        name_uk: "Тест 3",
    },
};
