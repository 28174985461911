import { valid, } from './Service.valid';
import { Observable, } from '../Observable';
import { cachingService, } from '../../../../../../services/CachingService';
import { get, post, del, } from '../../../../helpers/MagicRequest';

/**
 * @class
 */
export class Service extends Observable {

  /**
   * Creates as instance of Service
   *
   * @arg {Mentor} mentor - owner instance
   * @memberof Service
   */
  constructor (mentor) {
    valid(mentor);

    super({
      events: [
        'filterOptionsLoad',
        'userFilterLoad',
        'userFilterSave',
        'userFilterUnsave',
        'filterSave',
        'dropDownLoad',
      ],
    });

    Object.defineProperties(this, {
      config: { enumerable: true, get: () => this.mentor.config, },
      mentor: { enumerable: true, get: () => mentor, },
      options: { enumerable: true, get: () => this.mentor.options, },
    });

    Object.freeze(this);
  }

  /**
   * Load FilterOptions from BE
   *
   * @example response
   *   // {
   *   //   return: { ... },
   *   //   data: {
   *   //     field: {
   *   //       operators,
   *   //       translate,
   *   //       type,
   *   //       drop-down?,
   *   //       enum?,
   *   //       enums?,
   *   //       having?
   *   //       order?
   *   //       sub_where?
   *   //   }
   *   // }
   * @returns {Object} { data: Object, return: Oject, }
   * @memberof Service
   */
  async loadFilterOptions () {
    try {
      const url = this.mentor.config.loadFilterOptions();
      const options = { params: true, };
      const response = await cachingService.session(url, options);

      return response;
    } catch (error) {
      console.log({ error, });
      error?.showErrorNotification?.();
    }

    return { data: {}, return: {}, };
  }

  /**
   * Load filter binded to user
   *
   * @example response
   *   // [ {
   *   //   core-filter_hash-data: "json",
   *   //   core-filter_hash-id: filterId,
   *   //   core-user_filter-type: serviceId,
   *   //   core-filter_hash-hash: hash,
   *   //   core-user_filter-filter_hash_id,
   *   //   core-user_filter-id: userFilterId,
   *   //   core-user_filter-user_id: userId
   *   // } ]
   * @returns {Object} { filterId: Number, userFilterId: Number, data: Array, }
   * @memberof Service
   */
  async loadUserFilter () {
    try {
      const url = this.mentor.config.loadUserFilter();
      const { widget, } = this.mentor.config;
      const response = await get(url);
      const responseData = widget ? response.data : response;
      const [ raw, ] = Array.isArray(responseData) ? responseData : [];
      const {
        'core-filter_hash-data': data = false,
        'core-filter_hash-id': filterId = 0,
        'core-user_filter-id': userFilterId = 0,
      } = raw || {};

      return {
        data: data ?? [], // data ? JSON.parse(data) : [],
        filterId,
        userFilterId,
      };
    } catch (error) {
      console.log({ error, });
      error?.showErrorNotification?.();
    }

    return { data: [], filterId: 0, userFilterId: 0, };
  }

  /**
   * Save filter binding to user
   *
   * @example request payload
   *   // {
   *   //   filter_hash_id: filterId,
   *   //   type: serviceId,
   *   // }
   * @example response
   *   // {
   *   //   filter_hash_id: filterId,
   *   //   id,
   *   //   type: serviceId,
   *   //   user_id,
   *   // }
   * @returns {Object} { filterId: Number, userFilterId: Number, }
   * @memberof Service
   */
  async saveUserFilter () {
    try {
      const url = this.mentor.config.saveUserFilter();
      const { serviceId, filterId, } = this.mentor;
      const payload = {
        filter_hash_id: filterId,
        type: `${ serviceId }`,
      };
      const response = await post(url, payload);
      const { id: userFilterId, } = response;

      return { filterId, userFilterId, };
    } catch (error) {
      console.log({ error, });
      error?.showErrorNotification?.();
    }

    return { filterId: 0, userFilterId: 0, };
  }

  /**
   * Remove filter binding to user
   *
   * @example response
   *   // true
   * @returns {Boolean} remove result
   * @memberof Service
   */
  async unsaveUserFilter () {
    try {
      const { userFilterId, } = this.mentor;
      const url = this.mentor.config.unsaveUserFilter(userFilterId);
      const response = await del(url);
      const { data, } = response;

      return data;
    } catch (error) {
      console.log({ error, });
      error?.showErrorNotification?.();
    }

    return false;
  }

  /**
   * Save filter
   *
   * @example request payload
   *   // {
   *   //   data: [
   *   //     ...filters,
   *   //     ...orders,
   *   //     be,
   *   //   ],
   *   // }
   * @example response
   *   // {
   *   //   data: "json",
   *   //   hash,
   *   //   id: filterId,
   *   // }
   * @returns {Object} { filterId: Number, data: Array, }
   * @memberof Service
   */
  async saveFilter () {
    try {
      const url = this.mentor.config.saveFilter();
      const { widget, } = this.mentor.config;
      const { be, } = this.mentor.options;
      const { filters = [], orders = [], } = this.mentor.itemsToExport();
      const data = [
        ...filters,
        ...orders,
        ...be ? [ be, ] : [],
      ];
      const payload = { data: JSON.stringify(data), };
      const response = await post(url, payload);
      const raw = widget ? response.data : response; // [ raw, ]
      const { id: filterId, data: json, } = raw;

      return {
        data: json,
        filterId,
      };
    } catch (error) {
      console.log({ error, });
      error?.showErrorNotification?.();
    }

    return { data: [], filterId: 0, };
  }

  /**
   * Load drop-down list
   *
   * @arg {String} url - link to download drop-down list
   * @returns {Array} List for drop-down
   * @memberof Service
   */
  async loadDropDown (url) {
    try {
      // eslint-disable-next-line no-restricted-globals
      const urlDD = new URL(url, location.href);
      const response = await get(urlDD);

      return response;
    } catch (error) {
      console.log({ error, });
      error?.showErrorNotification?.();
    }

    return [];
  }

}