import React, { Component } from 'react';
import { TABLE_PERMISSION__PROP_TYPES } from "../../../../../components/PropTypes/Template_Permission_PropsTypes";

import { get_random_guid } from '../../../../../helpers/OtherHelper';
import { getDataFieldTable } from "../../../../../components/Common/CheckFieldTable";

export default class TablePermissionData extends Component {
    constructor (props) {
        super(props);
    }

    checkField = (field, props) => {
        return (<td key = { get_random_guid() }>
            <div>{getDataFieldTable(field, props)}</div>
        </td>);
    };

    checkFieldBtn = (data) => {
        return (<td key = { get_random_guid() }>
            <a
                className = 'button templatePermission_table_btn_edit' onClick = { () => {
                    this.props.togglePermission(data.core.permission_templates);
                } } >{this.props.translate(`template_permission_btn_edit`)}</a>
            <a
                className = 'button templatePermission_table_btn_delete' onClick = { () => {
                    this.props.toggleRemoveTemplate(data.core.permission_templates.id, data.core.permission_templates.count_users);
                } } >{this.props.translate(`template_permission_btn_delete`)}</a>
        </td>);
    };

    renderBody = () => {
        const fields = [];

        this.props.fieldsForChecking.map((item) => {
            switch (item) {
                case 'action': {
                    fields.push(this.checkFieldBtn(this.props.data));
                    break;
                }
                default: {
                    fields.push(this.checkField(item, this.props.data));
                }
            }
        });

        return fields;
    };

    render () {
        const tableBody = this.renderBody();

        return (
            <tr>
                {tableBody.map((item) => {
                    return item;
                })}
            </tr>
        );
    }
}

TablePermissionData.defaultProps = {
    fieldsForChecking: [
        'core-permission_templates-name',
        'core-permission_templates-count_users',
        'action'
    ],
};

TablePermissionData.propTypes = {
    data: TABLE_PERMISSION__PROP_TYPES,
};
