import React from 'react';
import { withLocalize, } from 'react-localize-redux';
import PermissionService from '../../../../../../services/PermissionService';
import { MagicButton, } from '../../../../../../components/Magic';
import { profileService, } from "../../../../../../services/ProfileDataService";
import {
	getAccountDescriptions,
	createAccountDescription,
} from '../../../../services/AccountRequestService';
import Comment from './Comment';
import PropTypes from 'prop-types';
import { withRouter, } from 'react-router-dom';
import {
	deleteAccountTag,
	getClientAsync,
} from "../../../../services/AccountService";
import { NO_ANSWER, } from "../../../../../../enums/dictionary_account_tag";


class CommentsCustomerData extends React.Component {

		static defaultProps = {
			id: "0",
			translate: () => {
			},
		};
		static propTypes = {
			refreshClientData: PropTypes.func.isRequired,
			id: PropTypes.string,
			translate: PropTypes.func,
		};

		constructor (props) {
			super(props);
			this.state = {
				comments: [],
				create: {},
				isCreate: this.isAutoFocus ?? false,
				isDisable: false,
				errors: [],
				userId: 0,
				maxMessageLength: 500,
				addMessageLength: 0,
				hasAccountNotAnsweredTag: false,
				accountNotAnsweredTagDisabled: false,
			};
		}

		save = async state => new Promise(next => this.setState(state, next));

		profileChange = async (profile) => {
			if (profile.hash === this.state?.profile?.hash) {
				return `${ this.constructor.name }.profileChange: false`;
			}
			await this.save({ profile, });

			return `${ this.constructor.name }.profileChange: true`;
		};

		async componentDidMount () {
			profileService.subscribe('profile', this.profileChange, this);
			await profileService.profile;
			await this.getDescriptions(this.props?.id);
			await this.getUserId();
			await this.getAccount();

			if (this.notAnsweredTag) {
				this.notAnsweredTag.addEventListener('click', this.handlerButtonNotAnswered);
			}
		};

		componentWillUnmount () {
			profileService.unsubscribe('profile', this.profileChange, this);
		}

		get isAutoFocus () {
			const query = new URLSearchParams(this.props?.location?.search);

			return query.has('focusComment');
		}

		get pmConfig () {
			return [
				{
					accessChecked: true,
					path: [ 'isAddComment', ],
					key: 'isAddComment',
				},
				{
					path: [ 'core', 'user', 'id', ],
					key: 'commentAuthorId',
				},
				{
					path: [ 'core', 'user', 'name', ],
					key: 'commentAuthorName',
				},
				{
					path: [ 'core', 'user', 'surname', ],
					key: 'commentAuthorSurname',
				},
				{
					path: [ 'core', 'account_descriptions', 'description', ],
					key: 'commentText',
				},
				{
					path: [ 'core', 'account_descriptions', 'status', ],
					key: 'commentStatus',
				},
				{
					path: [ 'core', 'account_descriptions', 'created_at', ],
					key: 'commentCreatedAt',
				},
				{
					path: [ 'core', 'account_descriptions', 'id', ],
					key: 'renderItem',
					render: this.renderItem,
				},
				{
					path: [ 'core', 'account_tag', 'id', ],
					key: 'accountTag',
				},
			];
		}

		get accessDepartmentsForArchiveClient () {
			return [ "administration", "administrator_sales", "technical_support", ];
		}

		setErrors = async ({ errors, }) => await this.save({ errors: Object.keys(errors), });

		clearErrors = async () => await this.save({ errors: [], });

		checkError = field => this.state.errors?.includes(field) ? 'error' : "";

		setCreateMode = async () => await this.save({ isCreate: true, });

		cancelCreateMode = async () => await this.save({
			isCreate: false,
			create: {},
			addMessageLength: 0,
		});

		filterDeleteComments = async (id) => {
			await this.save(state => ({ deleteComments: state.deleteComments.filter(c => c !== id), }));
		};
	fetchData = async (id) => {
		const { comments, } = this.state;

		try {
			const response = await getAccountDescriptions(id, 50, comments.length);
			const newData = await response.data;

			await this.save({
				comments: [ ...comments, ...newData, ],
				loaded: true,
			});
		} catch (e) {
			console.log(e);
		}
	}

		getDescriptions = async (id) => {
			try {
				const response = await getAccountDescriptions(id, 50, 0);
				const {
					data = [],
					errors,
					meta,
				} = response;
				const deleteComments = data
					?.filter(item => [ "2", 2, ].includes(item?.core?.account_descriptions?.status))
					?.map(item => item?.core?.account_descriptions?.id);

				await this.save({
					comments: data,
					errors,
					deleteComments,
					totalData: meta.total,
				});
			} catch (error) {
				this.setErrors(error?.response?.data);
				error?.showErrorNotification?.();
			}
		};
	handleScroll = (event) => {
		const { scrollHeight, scrollTop, clientHeight, } = event.target;
		const { comments, totalData, } = this.state;
		const dataCount = totalData - comments.length;

		if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1 && dataCount > 0) {
			this.fetchData(this.props?.id);
		}

	}
		getUserId = async () => {
			try {
				const {
					id: userId,
					tree_info: userDepartments,
				} = this.state?.profile?.data ?? {};

				await this.save({
					userId,
					userDepartments,
				});
			} catch (error) {
				error?.showErrorNotification?.();
			}
		};

		addDescription = async () => {
			try {
				await this.clearErrors();
				await this.save({ isDisable: true, });
				await createAccountDescription({
					account_id: this.props.id,
					description: this.state.create?.description,
				});
				await this.getDescriptions(this.props?.id);
				await this.cancelCreateMode();
				this.props.refreshClientData();
			} catch (error) {
				await this.setErrors(error?.response?.data);
				error?.showErrorNotification?.();
			} finally {
				await this.save({ isDisable: false, });
			}
		};

		renderItem = (value, {
			item,
			items,
		}) => {
			if (value) {
				return this.renderComment(value, {
					item,
					items,
				});
			}

			return (
				<div className="comments__btn comments__btn_add">
					{ this.renderAddMessage(_, {
						item,
						items,
					}) }
				</div>
			);
		};

		renderAddMessage = (_, {
			item,
			items,
		}) => {
			const translate = {
				add: this.props?.translate('account_comments_customer_button_add'),
				save: this.props?.translate('account_comments_customer_button_save'),
				cancel: this.props?.translate('account_comments_customer_button_cancel'),
				ph: this.props?.translate('account_comments_customer_create_ph'),
			};

			if (item?.access('index', 'store')) {
				const {
					isCreate,
					isDisable,
					create,
				} = this.state;
				const optionsText = {
					className: `comments__create_field ${ this.checkError("description") } scroll`,
					value: create?.description ?? "",
					onChange: async ({ target, }) => await this.save({
						addMessageLength: target?.value?.trim()?.length,
						create: {
							description: target?.value,
						},
					}),
					placeholder: translate?.ph,
					disabled: isDisable,
					autoFocus: this.isAutoFocus,
				};
				const optionsCreate = {
					className: "magic-button__item magic-button__item_comments-create",
					value: "",
					onClick: this.toggleButtonAdd,
					children: isCreate ? translate?.save : translate?.add,
					disabled: isDisable,
				};
				const optionsCancel = {
					className: "magic-button__item magic-button__item_comments-cancel",
					value: "",
					onClick: async () => await this.cancelCreateMode(),
					children: translate?.cancel,
					disabled: isDisable,
				};

				return (
					<div className="comments__create">
						{ isCreate && (
							<React.Fragment>
								<div className="comments__create_counter">
									{ `${ this.state?.addMessageLength }/${ this.state?.maxMessageLength }` }
								</div>
								<textarea { ...optionsText } />
							</React.Fragment>
						) }
						<div className="comments__create_buttons">
							<MagicButton { ...optionsCreate } />
							{ isCreate && <MagicButton { ...optionsCancel } /> }
						</div>
					</div>
				);
			} else if (item?.access('index')) {
				return <span>{ translate?.add }</span>;
			}

			return "";
		};

		toggleButtonAdd = () => this.state.isCreate ? this.addDescription() : this.setCreateMode();

		renderComment = (_, {
			item,
			items,
		}) => {
			return (
				<Comment
					deleteComments={ this.state.deleteComments }
					filterDeleteComments={ this.filterDeleteComments }
					items={ items }
					key={ items?.renderItem?.valueOf }
					maxMessageLength={ this.state.maxMessageLength }
					refreshData={ async () => await this.getDescriptions(this.props?.id) }
					userId={ this.state.userId }
				/>);
		};

		getAccount = async () => {
			const { data, } = await getClientAsync(this.props.id);
			const tags = data?.core?.account_tag;
			const tag = tags?.find(tag => tag.dictionary_account_tag_id === NO_ANSWER);

			tag && this.save({
				hasAccountNotAnsweredTag: true,
				accountTagId: tag.id,
			});
		};

		deleteAccountTag = async () => {

			if (this.state.hasAccountNotAnsweredTag) {
				const res = await deleteAccountTag(this.state.accountTagId);

				res?.status === 202 && this.save({
					accountNotAnsweredTagDisabled: true,
					hasAccountNotAnsweredTag: false,
					accountTagId: null,
				});
			}
		};

		handlerButtonNotAnswered = async (e) => {
			e.preventDefault();

			if (!this.notAnsweredTag) {
				return;
			}

			this.notAnsweredTag.removeEventListener('click', this.handlerButtonNotAnswered);

			await this.deleteAccountTag();

			this.save({ accountNotAnsweredTagDisabled: true, });

			this.notAnsweredTag.addEventListener('click', this.handlerButtonNotAnswered);
		};

		render = () => {
			const data = PermissionService.cast([ { isAddComment: true, }, ...this.state.comments, ], this.pmConfig, true);
			const wrapperClassName = () => {
				let cls = 'comments__wrapper';

				this.state.userDepartments?.map(({ department_alias, }) => {
					if (this.accessDepartmentsForArchiveClient.includes(department_alias)) {
						return cls += ' show-archive';
					}
				});

				return cls;
			};

			const conf = PermissionService.calc(this.pmConfig);
			const autoCallLabel = conf?.accountTag?.access('show') ? (
				<span>
					{ this.props.translate('account_answer_call_state_title') }:&nbsp;
					<span
						className={ this.state.hasAccountNotAnsweredTag ? 'account_answer_call_state_not_answered' : '' }
						ref={ node => this.notAnsweredTag = node }
					>
						{ this.props.translate(`account_answer_call_state_status`) }
					</span>
				</span>
			) : null;

				//account_answer_call_state_hidden
			return (
				<>
					<div className={ wrapperClassName() }>
						<div className="table-title-cont icon-styles">
							<span
								className="comments__title"
							>{ this.props.translate(`account_comments_customer_title`) }</span>
							{ autoCallLabel }
						</div>
						<div className="comments__content scroll" onScroll={ event => this.handleScroll(event) }>
							{ data?.map((data, index) => (<React.Fragment
								key={ index }
							                              >{ data?.renderItem?.render?.() } </React.Fragment>)) }
						</div>
					</div>

				</>
			);
		};
}

export default withRouter(withLocalize(CommentsCustomerData));