export function onValidPercantages (value, max, min = 0.01) {
	if (value === "") {
		return;
	}
	const maxZerosInARow = `${ min }`.length - 1; //if min 0.01 -> we will be stopped on 0.0
	const lengthAfterFloatingPointOfMin = `${ min }`.split('.').pop().length;
	const regexpString = `^-?[0-9]*\\.?[0-9]{0,${ lengthAfterFloatingPointOfMin }}?$`;
	const regexp = new RegExp(regexpString);

	if (!regexp.test(value)) {
		throw new Error("xxx");
	}
	if (value === "." || value === "-") {
		throw new Error("xxx");
	}
	if ((/^0[0-9]/).test(value)) {
		throw new Error("xxx");
	}
	if (value.length > maxZerosInARow && +value < min) {
		throw new Error("x1xx");
	}
	if (+value > max) {
		throw new Error("x1xx");
	}
}

export function onValidDigits (value, max, min = 0) {
	const regExp = /^(0|[0-9]\d*)$/g;

	if (value.length > 1 && +value[0] === 0) {
		throw new Error("x1xx");
	}

	if (value !== "" && !regExp.test(value)) {
		throw new Error("x1xx");
	}

	if (value < min && value !== "") {
		throw new Error("x1xx");
	}

	if (value > max) {
		throw new Error("x1xx");
	}
}