import * as actions from './actionTypes';

const filterReset = {
    'take': 10,
    'skip': 0,
    'skip_count': 1,
    'core-user-id': '',
    'core-user-name': '',
    'core-user-email': '',
    'core-user-phone': '',
    'core-user-nickname': '',
    'order_by': 'core-user-id|asc',
};

const initialState = {
    data: {},
    user: {},
    sales: {},
    filter: {
        'take': 10,
        'skip': 0,
        'skip_count': 1,
        'core-user-id': '',
        'core-user-name': '',
        'core-user-email': '',
        'core-user-phone': '',
        'core-user-nickname': '',
        'order_by': 'core-user-id|asc',
    },
    enums: {},
    avatar: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.SET_USERS:
            return {
                ...state,
                ...state.users,
                'data': action.data.data,
                'total': action.data.meta.total,
            };
        case actions.SET_USER:
            return {
                ...state,
                ...state.users,
                'user': action.data.data,
                'error': false
            };
        case actions.SET_ERROR:
            return {
                ...state,
                'error': true,
            };
        case actions.RESET_ERROR:
            return {
                ...state,
                'error': action.data,
            };
        case actions.DELETE_USER:
            return {
                ...state,
                ...state.users,
                'user': action.data,
            };
        case actions.SET_FILTER:
            return {
                ...state,
                ...state.users,
                'filter': {
                    ...state.filter,
                    ...action.data,
                },
            };
        case actions.RESET_FILTER:
            return {
                ...state,
                ...state.users,
                'filter': {
                    ...filterReset,
                },
            };
        case actions.GET_ENUMS:
            return {
                ...state,
                ...state.users,
                'enums': {
                    ...state.enums,
                    ...action.data,
                },
            };
        case actions.GET_AVATAR:
            return {
                ...state,
                ...state.users,
                'avatar': {
                    ...state.avatar,
                    ...action.data,
                },
            };
        case actions.SET_SALES_PLANNING:
            return {
                ...state,
                ...state.sales,
                'sales': action.data,
                'error': false
            };

        case actions.SET_DEPARTMENTS:
            return {
                ...state,
                ...state.users,
                'departments': {
                    ...action.data
                }
            }
        default:
            return state;
    }
}
