import React from "react";
import ModalWindowForTask from "./ModalWindowForTask";
import Preloader from "../../../../components/LoadingHOC/Preloader";
import { Link } from "react-router-dom";
import call from "../../../../images/call.svg";
import { withLocalize } from "react-localize-redux";
import { callService } from "../../../../services/CallDataService";
import { calendarService } from "../../../../services/CalendarDataService";

class TaskBlock extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            popupVisible: false,
            item:         this.props.item,
            loaded:       true,
            data:         {},
        };
        this.myClass = 'task-modal__close';

    }

    save = async (state) => new Promise(next => this.setState(state, next));

    toggle = () => {
        if (!this.state.popupVisible) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
        } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
        }
        this.setState({
            popupVisible: !this.state.popupVisible,
        });
    };

    handleClick = async () => {
        const taskId = this.props?.item?.id;

        await this.save({ loaded: false, });

        try {
            const [taskData, responsibleData = [],] = await Promise.all([
                calendarService.task(taskId),
                calendarService.responsible(taskId),
            ]);
            const { data } = taskData.data;

            await this.save({ loaded: true, data: { ...data, responsible: responsibleData?.data?.[0] } });
            this.toggle();
        } catch (error) {
            error?.showErrorNotification?.();
            await this.save({ loaded: true, data: {} });
        }
    };

    onBirthdayClick = () => {
        this.toggle();
    };

    closePopup = () => {
        this.setState({
            popupVisible: false,
        });
        document.removeEventListener('click', this.handleOutsideClick, false);
    };

    handleOutsideClick = (e) => {
        // ignore clicks on the component itself
        if (this.node?.contains(e.target)) {
            return false;
        } else {
            this.closePopup();
        }
    };

    render () {
        const { avatar, translate } = this.props;
        const urgency_alias = this.props?.item?.urgency?.alias;
        const delegate_flag = this.props?.item?.delegate_flag;
        const state_alias = this.props?.item?.state?.alias;
        const delegateClassName = (delegate_flag && delegate_flag !== 'my') ? `delegate delegate--${delegate_flag}` : '';
        const { loaded, data } = this.state;
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const modalWidth = 300; // HARD CODE
        const modalHeight = 350; // HARD CODE
        const leftModal = "left--modal";
        const rightModal = "right--modal";
        const topModal = "top--modal";
        const bottomModal = "bottom--modal";

        if (urgency_alias === 'birthday') {
            const surname = this.props?.item?.surname ?? '';
            const name = this.props?.item?.name ?? '';
            const id = this.props?.item?.id ?? null;

            /*const options = {
                id,
                surname,
                name,
                closePopup:  this.closePopup,
                leftOrRight: this.props.j > 5 ? leftModal : rightModal,
                topOrBottom: this.props.i > 16 ? topModal : bottomModal,
            };*/

            const renderPhoneCall = () => {
                const optionsCall = {
                    className: `phone-call`,
                    style:     { cursor: 'pointer', },
                    onClick:   async () => callService.sendCall({ account_id: id }),
                };
                const img = (<img alt='call' src={call}/>);

                return (<span {...optionsCall}>{img}</span>);
            };

            // REMOVED this block in current version of CRM (24/09/2020)
            /*return (
                <div
                    className='department-task__wrap'
                    ref={(node) => {
                        this.node = node;
                    }}>
                        <span className={`department-task ${this.state.openModal ? 'active' : ''}`}
                              onClick={this.onBirthdayClick}>
                            <div className={`calendar-day-task type-${urgency_alias} task-no-wrap`}>
                                <p>{surname} {name}</p>
                            </div>
                        </span>

                    {this.state.popupVisible && (
                        <ModalWindowForBirthDay
                            {...options}
                        />
                    )}
                </div>
            );*/
            return (
                <div className='department-task__wrap'>
                    <span className={`department-task`}>
                        <div className={`calendar-day-task type-${urgency_alias} task-no-wrap`}>
                            <Link to={`/clients/${id}`} target='_blank'>
                                <p>{surname} {name}</p>
                            </Link>
                            {renderPhoneCall()}
                        </div>
                    </span>
                </div>
            );
        } else {
            const boxCoords = this?.node?.getBoundingClientRect() ?? {};
            const { right = 0, bottom = 0, } = boxCoords;
            const leftOrRight = right + modalWidth < windowWidth ? rightModal : leftModal;
            const topOrBottom = bottom + modalHeight < windowHeight ? topModal : bottomModal;

            const options = {
                avatar,
                data,
                closePopup: this.closePopup,
                leftOrRight,
                topOrBottom,
            };

            return (
                <React.Fragment>
                    {!loaded ?
                        (
                            <Preloader className='loaderUniversal--fixed-pos' scale={1}/>
                        ) : false
                    }

                    <div
                        className='department-task__wrap'
                        ref={(node) => {
                            this.node = node;
                        }}>
                            <span className={`department-task ${this.state.openModal ? 'active' : ''} ${delegateClassName ? 'tooltip-wrapper' : ''}` }
                                  onClick={this.handleClick}>
                                <div
                                    className={`calendar-day-task type-${urgency_alias} status-${state_alias} task-no-wrap ${delegateClassName}`}>
                                    <p>{this.props?.item?.description}</p>
                                    { state_alias === 'cancellation' 
                                        ? <span className='line' />
                                        : state_alias === 'done' 
                                        ? <span className='line' />
                                        : null
                                    }
                                </div>

                                { delegateClassName && (<div className = 'tooltip'>{ translate(`calendar_delegated_task_${delegate_flag}`) }</div>) }
                            </span>

                        {this.state.popupVisible && (
                            <ModalWindowForTask
                                {...options}
                            />
                        )}

                    </div>
                </React.Fragment>
            );
        }
    }
}

export default withLocalize(TaskBlock);
