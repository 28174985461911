import React, { Component, } from "react";
import { withRouter,} from "react-router-dom";
import { withLocalize, } from "react-localize-redux";

import { MagicTable, Mentor, MagicFilterPanels, } from "../../../../../components/Magic";
import PagePanel from "../../../../tima/components/Pages";
import Preloader from "../../../../../components/LoadingHOC/Preloader";

import { showExportToExcelError, } from "../../../../../components/Magic";
import { paymentService, } from "../../../../../services/PaymentDataService";
import { enumService, } from "../../../../../services/EnumDataService";

class SummaryOfFinTransactionsTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            filterLastChange: Date.now(),
            isEmptyData: false,
            loaded: false,
            meta: {},
            pages: {
                filter:  0,
                take: 50,
                total: 0,
                variance: 2,
            },

        };

        const mf = new Mentor({
            owner: this,
            serviceId: 193,
            translate: this.props.translate,
        });

        Object.defineProperty(this, "mf", { get: () => mf, });
    }

    async componentDidMount () {
        enumService.subscribe('enums', this.enumsChange, this);
        this.mf.subscribe(
            {
                changed: this.mfChanged,
                cleaned: this.mfDone,
                cleaning: this.mfDoing,
                loaded: this.mfDone,
                loading: this.mfDoing,
                saved: this.mfDone,
                saving: this.mfDoing,
            },
            this,
        );

        await this.mf.init({ doEmit: true, });
    }

    componentWillUnmount () {
        enumService.unsubscribe('enums', this.enumsChange, this);
    }
    save = state => new Promise(next => this.setState(state, next));

    onChange = (name, value) => this.save({ [name]: value, });

    params = () => this.props.match.params;

    mfChanged = async () => {
        const { handleFitlerIdChange, } = this.props;
        const { filterId: filter_id, } = this.mf;

        await handleFitlerIdChange(filter_id);
        await this.save({ filterLastChange: Date.now(), });
    };

    mfDoing = async () => {
        await this.save({ loaded: false, });
    };

    mfDone = async () => {
        await this.mfChanged();
        await this.onDataLoad();
    };

    enumsChange = async (enums) => {
        if (enums.hash === this.state?.enums?.hash) {
            return `${ this.constructor.name }.enumsChange: false`;
        }
        await this.save({ enums, });

        return `${ this.constructor.name }.enumsChange: true`;
    };
    componentWillUnmount = () => {
        this.mf.unsubscribe([ this.mfChanged, this.mfDoing, this.mfDone, ], this);
    };

    query = () => new URLSearchParams(this.props.location.search);

    onDataLoad = async (pageId) => {
        await this.save({ isEmptyData: false, loaded: false, });

        const pageOptions = this.pageIdAsGET(pageId);
        const loaded = (data, meta) => ({ pages, }) => ({
            data,
            pages: { ...pages, ...meta, },
            ...!data.length && { isEmptyData: true, },
        });

        try {
            const response = await paymentService.getSummaryOfFinTransactions(pageOptions);
            const {
                data = [],
                meta: { filter = 0, total = 0, },
            } = response;

            await this.save(loaded(data, { filter, total, }));
        } catch (error) {
            await this.save(loaded([], { filter: 0, total: 0, }));
            showExportToExcelError(error);
        }
        await this.save({ loaded: true, });
    };

    get config () {
        const { translate, } = this.props;
        const prefix = "summary_";

        return [
            {
                key: "transactionDate",
                orderId: "o:date",
                path: [ "payment", "daily_aggregated_fin_transactions", "operation_date", ],
                render: this.renderInlineValue,
                title: `${ prefix }date`,
            },
            {
                key: "deposit-sum-USD",
                orderId: "o:sumDepositUSD",
                path: [ "payment", "daily_aggregated_fin_transactions", "order_usd_deposit", ],
                render: this.renderTransaction,
                title: `${ prefix }sum_deposit_of_usd`,
            },
            {
                key: "deposit-balance-USD",
                orderId: "o:balanceDepositUSD",
                path: [ "payment", "daily_aggregated_fin_transactions", "bal_op_usd_deposit", ],
                render: this.renderTransaction,
                title: `${ prefix }balance_deposit_of_usd`,
            },
            {
                key: "deposit-sum-EUR",
                orderId: "o:sumDepositEUR",
                path: [ "payment", "daily_aggregated_fin_transactions", "order_eur_deposit", ],
                render: this.renderTransaction,
                title: `${ prefix }sum_deposit_of_eur`,
            },
            {
                key: "deposit-balance-EUR",
                orderId: "o:balanceDepositEUR",
                path: [ "payment", "daily_aggregated_fin_transactions", "bal_op_eur_deposit", ],
                render: this.renderTransaction,
                title: `${ prefix }balance_deposit_of_eur`,
            },
            {
                key: "withdrawal-sum-USD",
                orderId: "o:sumWithdrawalUSD",
                path: [ "payment", "daily_aggregated_fin_transactions", "order_usd_withdrawal", ],
                render: this.renderTransaction,
                title: `${ prefix }sum_withdrawal_of_usd`,
            },
            {
                key: "withdrawal-balance-USD",
                orderId: "o:balanceWithdrawalUSD",
                path: [ "payment", "daily_aggregated_fin_transactions", "bal_op_usd_withdrawal", ],
                render: this.renderTransaction,
                title: `${ prefix }balance_withdrawal_of_usd`,
            },
            {
                key: "withdrawal-sum-EUR",
                orderId: "o:sumWithdrawalEUR",
                path: [ "payment", "daily_aggregated_fin_transactions", "order_eur_withdrawal", ],
                render: this.renderTransaction,
                title: `${ prefix }sum_withdrawal_of_eur`,
            },
            {
                key: "withdrawal-balance-EUR",
                orderId: "o:balanceWithdrawalEUR",
                path: [ "payment", "daily_aggregated_fin_transactions", "bal_op_eur_withdrawal", ],
                render: this.renderTransaction,
                title: `${ prefix }balance_withdrawal_of_eur`,
            },
            {
                key: "chargeback-sum-USD",
                orderId: "o:sumChargebackUSD",
                path: [ "payment", "daily_aggregated_fin_transactions", "order_usd_chargeback", ],
                render: this.renderTransaction,
                title: `${ prefix }sum_chargeback_of_usd`,
            },
            {
                key: "chargeback-balance-USD",
                orderId: "o:balanceChargebackUSD",
                path: [ "payment", "daily_aggregated_fin_transactions", "bal_op_usd_chargeback", ],
                render: this.renderTransaction,
                title: `${ prefix }balance_chargeback_of_usd`,
            },
            {
                key: "chargeback-sum-EUR",
                orderId: "o:sumChargebackEUR",
                path: [ "payment", "daily_aggregated_fin_transactions", "order_eur_chargeback", ],
                render: this.renderTransaction,
                title: `${ prefix }sum_chargeback_of_eur`,
            },
            {
                key: "chargeback-balance-EUR",
                orderId: "o:balanceChargebackEUR",
                path: [ "payment", "daily_aggregated_fin_transactions", "bal_op_eur_chargeback", ],
                render: this.renderTransaction,
                title: `${ prefix }balance_chargeback_of_eur`,
            },
            {
                key: "transactionUnfulfilledOperationsUSD",
                orderId: "o:sumUnfulfilledOperationsUSD",
                path: [ "payment", "daily_aggregated_fin_transactions", "unful_order_usd_withd", ],
                render: this.renderInlineValue,
                title: `${ prefix }sum_unfulfilled_operations_of_usd`,
            },            {
                key: "transactionUnfulfilledOperationsEUR",
                orderId: "o:sumUnfulfilledOperationsEUR",
                path: [ "payment", "daily_aggregated_fin_transactions", "unful_order_eur_withd", ],
                render: this.renderInlineValue,
                title: `${ prefix }sum_unfulfilled_operations_of_eur`,
            },
        ].map(({
                   orderId: o, title: t, xtitle: x, ...item
               }) => ({
            ...item,
            ...o && {
                order: async () => {
                    await this.mf.orderedToNext(o);
                },
                orderTo: this.mf.orderedTo(o),
            },
            ...t && { title: translate(t), },
            ...x && { title: x, },
        }));
    }

    renderInlineValue = (value, { item, items}) => {
        if (item.access("index")) {
            return value ?? "-";
        }

        return "-";
    };

    renderTransaction = (value, {item, items}) => {
        if (item.access("index")) {
            const transactionIsCorrect = this.checkTransactionsEquality(item, items);
            const className = !transactionIsCorrect ? 'text-danger font-weight-bold' : '';
            return <span className={ className }>{value}</span> ?? "-";
        }
        return "-";
    }

    checkTransactionsEquality(item, items){
        const [operation, category, currency] = item.key.split('-');

        const correspondingCategory = category === 'sum' ? 'balance' : 'sum';
        const correspondingTransactionKey = [ operation, correspondingCategory, currency, ].join('-');
        const correspondingTransaction = items[correspondingTransactionKey];

        return item.valueOf === correspondingTransaction.valueOf;
    }
    renderPartnersListMagicTableTable () {
        const { translate, } = this.props;
        const { loaded, data,  } = this.state;

        const head = [
            "transactionDate",
            "deposit-sum-USD",
            "deposit-balance-USD",
            "deposit-sum-EUR",
            "deposit-balance-EUR",
            "withdrawal-sum-USD",
            "withdrawal-balance-USD",
            "withdrawal-sum-EUR",
            "withdrawal-balance-EUR",
            "chargeback-sum-USD",
            "chargeback-balance-USD",
            "chargeback-sum-EUR",
            "chargeback-balance-EUR",
            "transactionUnfulfilledOperationsUSD",
            "transactionUnfulfilledOperationsEUR",
        ];

        if (!loaded) {
            return <Preloader />;
        }
        if (data.length === 0) {
            return <p className="mx-auto">{translate("no_data")}</p>;
        }

        const options = {
            config: this.config,
            data,
            head,
        };

        return (
            <>
                <MagicTable { ...options } />
            </>
        );
    }

    render () {
        const { pages, data, } = this.state;
        const { translate, getPermissions, } = this.props;

        const showPagePannel = pages.total > pages.take;

        const permissionToShow = getPermissions('index');

        return (
            <>
                { permissionToShow &&
                    <div>
                        <MagicFilterPanels
                            mf={this.mf}
                            show={true}
                            translate={translate}
                        />
                        {this.renderPartnersListMagicTableTable()}
                        {
                            showPagePannel && <PagePanel
                                filter={pages.filter}
                                page={this.pageId()}
                                take={pages.take}
                                total={pages.total}
                                variance={pages.variance}
                                onClick={this.onPageClick}
                                doTo={pageId => `?page=${pageId}`}
                            />
                        }
                    </div>
                }
            </>
        );
    }

    pageId = () => {
        const page = this.query().get("page");

        return Number(page) || 0;
    };

    pageIdAsGET = (pageId) => {
        const pages = this.state.pages;

        // eslint-disable-next-line no-param-reassign
        pageId = pageId === undefined ? this.pageId() : pageId;
        const { filterId, } = this.mf;
        const result = {
            filterId,
            skip: pageId * pages.take,
            take: pages.take,
        };

        return result;
    };
    // eslint-disable-next-line no-unused-vars
    onPageClick = async ({ event, pageId, pageIs, }) => {
        await this.onDataLoad(pageId);
    };
}

export default withRouter(withLocalize(SummaryOfFinTransactionsTable));