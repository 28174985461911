// field names full path
export const PAYMENTS_EXPORT_FIELDS = {
	payouts: [
		"core-account-id",
		"core-account-surname",
		"core-account-name",
		"partners-accruals-partner_account",
		"partners-accruals-amount",
		"partners-prev_accruals-affiliate_program_id",
		"partners-prev_accruals-condition_id",
		"partners-new_partners_conditions-level",
		"partners-new_partners_conditions-type",
		"partners-accruals-status",
		"partners-accruals-created_at",
		"partners-accruals-updated_at",
		"partners-accruals-description",
	],
};
