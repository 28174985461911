import React from 'react';

import Marketing from './components/Marketing';
import PermissionService, { MODULE_KEYS as pm } from '../../services/PermissionService';

export const marketingConfig = [
  {
    permission: pm.STATISTICS_UTM_LINK,
    action: PermissionService.storeRoute(),
    component: <Marketing activeTab="account_utm_link" />,
    path: [
      '/marketing/advertising_channels/account_utm_link',
    ]
  },
  {
    permission: pm.STATISTICS_UTM_LINK,
    action: PermissionService.storeRoute(),
    component: <Marketing activeTab="utm_link" />,
    path: [
      '/marketing/advertising_channels/utm-links-generation',
    ]
  },
  {
    permission: pm.STATISTICS_UTM_MEDIUM,
    action: PermissionService.showRoute(),
    component: <Marketing activeTab="utm_medium" />,
    path: [
      '/marketing/advertising_channels/subchannels',
    ]
  },
  {
    permission: pm.STATISTICS_UTM_CAMPAIGN,
    action: PermissionService.showRoute(),
    component: <Marketing activeTab="utm_campaign" />,
    path: [
      '/marketing/advertising_channels/products',
    ]
  },
  {
    permission: pm.STATISTICS_UTM_SOURCE,
    action: PermissionService.showRoute(),
    component: <Marketing activeTab="utm_source" />,
    path: [
      '/marketing/advertising_channels/channels',
      '/marketing/advertising_channels/',
      '/marketing/'
    ]
  }
];
