import React from 'react';
import {
	itemsBuild,
	itemsDrain,
	itemsResort,
	CFG_ITEM_TYPE,
} from '../Config';
import { MagicSelect, } from '../../../MagicSelect';
import { MagicFilterOperator, } from '../../components/MagicFilterOperator';

const renderTradingAccountType = ({ mentor, panel, }) => {
	const { owner, translate, } = mentor;
	const { items, } = panel;
	const enums = {
		'demo': translate('magic_filter_translate_list_accounts_tradings_type_demo'),
		'live': translate('magic_filter_translate_list_accounts_tradings_type_live'),
		'unknown': translate('magic_filter_translate_list_accounts_tradings_type_unknown'),
	};
	const values = {
		'demo': [ 6, 7, 8, 10, ].map(String),
		'live': [ 2, 5, ].map(String),
	};
	const value = items.v;
	const isUnKnown = Array.isArray(value)
		&& Object.values(values).every(_ => `${ value }` !== `${ _ }`);
	const valueToEnum = v => !Array.isArray(v)
		? null
		: (
			Object.entries(values)
				.map(([ key, value, ]) => ({ key, value, }))
				.find(({ value, }) => `${ value }` === `${ v }`)?.key
			?? 'unknown'
		);
	const onChange = v => mentor.filterChange({
		id: items.$id,
		setter: { v: values[v] ?? null, },
		$$changed: true,
	});

	const list = (
		<MagicSelect
			className={ [
				'magic-filter-input',
				'magic-filter-input-enum',
				'magic-select__filter-input-enum',
			] }
			disabled={ !panel.enabled }
			value={ valueToEnum(items.v) ?? 0 }
			valueDefault={ 0 }
			values={ Object.keys(enums).filter(key => key !== 'unknown' || isUnKnown) }
			valueToLabel={ _ => enums[_] ?? '-' }
			onChange={ onChange }
			ItemsProps={ ({ value, }) => value === 'unknown' ? { disabled: true, } : {}}
		/>
	);

	return (<div><MagicFilterOperator filter={ items } />{ list }</div>);
};

const p_taType = {
	render: renderTradingAccountType,
};

export default {
	id: 72,
	...[

		// filters
		...[ {
			f: 'core.trading_accounts.mt_login',
			id: 'f:taMtLogin',
		}, {
			f: [ 'core.account.surname', 'core.account.name', ],
			id: 'f:aFullname',
			o: 'like',
		}, {
			f: 'core.trading_accounts.acc_ccy',
			id: 'f:taCurrency',
		}, {
			f: 'core.trading_accounts.bonus_exist',
			id: 'f:taBonusExist',
		}, {
			f: 'core.trading_accounts.leverage',
			id: 'f:taLeverage',
		}, {
			f: 'core.trading_accounts.platform',
			id: 'f:taPlatform',
		}, {
			f: 'core.trading_accounts.mt_group',
			id: 'f:taMtGroup',
		}, {
			f: 'core.trading_accounts.risk_management',
			id: 'f:taRisk',
		}, {
			f: 'core.trading_accounts.type',
			id: 'f:taType',
			'o': 'in',
			'o=': [ 'in', ],
		}, {
			f: '',
			id: 'f:?',
			off: true,
		}, {
			f: 'core.dictionary_licenses.alias',
			id: 'f:tLicenseAlias',
		}, ]
		.filter(_ => _ && !_.off)
		.map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

		// orders
		...[ {
			f: '',
			id: 'o:',
			off: true, // TODO:
		}, ]
		.filter(_ => _ && !_.off)
		.map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

		// panels
		...[ {
			ii: 'f:taMtLogin',
			t: 'magic_filter_translate_list_accounts_tradings_mt_login',
		}, {
			ii: 'f:aFullname',
			t: 'magic_filter_translate_list_accounts_tradings_account',
		}, {
			ii: 'f:taCurrency',
			t: 'magic_filter_translate_list_accounts_tradings_currency',
		},{
			ii: 'f:taBonusExist',
			t: 'magic_filter_translate_list_accounts_tradings_bonus_exist',
		}, {
			ii: 'f:taLeverage',
			t: 'magic_filter_translate_list_accounts_tradings_leverage',
		}, {
			ii: 'f:taPlatform',
			t: 'magic_filter_translate_list_accounts_tradings_platform',
		}, {
			ii: 'f:taMtGroup',
			t: 'magic_filter_translate_list_accounts_tradings_mt_group',
		}, {
			ii: 'f:taRisk',
			t: 'magic_filter_translate_list_accounts_tradings_risk_management',
		}, {
			ii: 'f:taType',
			t: 'magic_filter_translate_list_accounts_tradings_type',
			...p_taType,
		}, {
			ii: 'f:?',
			off: true,
			t: '?',
		}, {
			ii: 'f:tLicenseAlias',
			t: 'magic_filter_translate_task_license_alias',
		}, ]
		.filter(_ => _ && !_.off)
		.map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

	]
	.map(itemsBuild)
	.reduce(itemsDrain, [])
	.reduce(itemsResort, {}),

	path: 'core/trading-accounts',
	widget: false,
};