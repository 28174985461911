/* eslint-disable sort-keys,react/no-children-prop */
import React, { Component, createRef, } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { withLocalize, } from "react-localize-redux";

import fallBackImage from '../../../../../images/partners-system/fallback-img.jpg';

class ImagePopup extends Component {
    static defaultProps = {
    	imgSrc: null,
    	onClose: () => {},
    };

    static propTypes = {
    	imgSrc: PropTypes.string,
    	wrapperClassname: PropTypes.string,
    	onClose: PropTypes.func,
    };

    constructor (props) {
    	super(props);

    	this.state = {
    		container: document.createElement("div"),
    	};

    	this.containerRef = createRef(null);
    }

    get getContainer () {
    	return this.state.container;
    }

    componentDidMount = () => {
    	document.body.appendChild(this.getContainer);
    	this.containerRef.current && this.containerRef.current.focus();
    };

    componentWillUnmount = () => {
    	document.body.removeChild(this.getContainer);
    };

	addDefaultSrc = (event) => {
		return event.target.src = fallBackImage;
	}
    renderCloseBtn = () => {
    	const { onClose, } = this.props;

    	return (
	            <button className="popup__close-button" onClick={ onClose } />
    		);
    };

    renderContent = () => {
    	const {
    		wrapperClassname, imgSrc,
    	} = this.props;

    	return (
			<div className={ `partners__popup ${ wrapperClassname }` } ref={ this.containerRef } tabIndex="0">
				<div className={ `popup__wrapper scroll popup__wrapper_image` }>
					{this.renderCloseBtn()}
					<img alt={ 'promo-banner' } src={ imgSrc } onError={ e => this.addDefaultSrc(e) } />
    			</div>
    		</div>
    	);
    };

    render = () => ReactDOM.createPortal(this.renderContent(), this.getContainer);
}

export default withLocalize(ImagePopup);