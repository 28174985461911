const AsyncFunction = Object.getPrototypeOf( async function(){} ).constructor;

export class MagicObservable {
    constructor() {
        const listeners = [];

        Object.defineProperties(this, {
            emit: {
                get: () => async (...data) => {
                    const L = listeners;
                    return Promise.all(L.map(async (l) => l(...data)));
                },
            },
            subscribe: {
                get: () => (listener) => {
                    const L = listeners, l = listener;
                    if (!(l instanceof AsyncFunction)) {
                        return false;
                    } else if (L.includes(l)) {
                        return false;
                    } else {
                        L.push(l);
                        return true;
                    }
                },
            },
            unsubscribe: {
                get: () => (listener) => {
                    const L = listeners, l = listener;
                    const index = L.indexOf(l);
                    if (index>=0) {
                        L.splice(index, 1);
                        return true;
                    }
                    return false;
                },
            },
        });
        Object.freeze(this);
    }
};
