import React from 'react';
import { MagicConfirm } from '../../../../tima/components/Magic/MagicConfirm';
import ManagerDelegation from './ManagerDelegation';
import { delegationService } from "../../../../../services/DelegationDataService";
import { userService } from "../../../../../services/UserDataService";


export default class Modal extends React.Component {
    state = {
        showConfirm: false,
        userToId: 0,
        userTo: {},
        usersAvatars: {
            hash: null,
            data: {},
        },
        usersTo: {
            data: [],
            hash: null
        },
    };

    delegationUsersChanges = async (users) => {
        if (users.hash===this.state?.usersTo?.hash) {
            return `${this.constructor.name}.delegationUsersChanges: false`;
        }
        if (users.options.taskId!==this.props?.taskIds?.[0] ?? 0) {
            return `${this.constructor.name}.delegationUsersChanges: false`;
        }
        await this.save({
            usersTo: {
                data: users.data,
                hash: users.hash,
            },
        });
        return `${this.constructor.name}.delegationUsersChanges: true`;
    };

    avatarsChange = async (usersAvatars) => {
        if (usersAvatars.hash===this.state?.usersAvatars?.hash) return `${this.constructor.name}.avatarsChange: false`;
        await this.save({ usersAvatars });
        return `${this.constructor.name}.avatarsChange: true`;
    };

    save = async (state) => new Promise(next => this.setState(state, next));

    componentDidMount = async () => {
        delegationService.subscribe('delegationsUsers', this.delegationUsersChanges, this);
        userService.subscribe('getAllAvatar', this.avatarsChange, this);
        await this.onDataLoadAvatars();
        await this.onDataLoadUsersTo();
    };

    componentWillUnmount = () => {
        delegationService.unsubscribe('delegationsUsers', this.delegationUsersChanges, this);
        userService.unsubscribe('getAllAvatar', this.avatarsChange, this);
    };

    onDataLoadAvatars = async () => {
        try {
            const usersAvatars = await userService.getAllAvatar();
            await this.save({ usersAvatars });
        } catch (error) {
            await this.save({ usersAvatars: { hash: null, data: {}}});
            console.log('error', error);
        }
    };

    onDataLoadUsersTo = async () => {
        try {
            const taskIds = this.props?.taskIds;
            await delegationService.delegationsUsers(taskIds?.[0] ?? 0);
        } catch (error) {
            console.log('error', error);
        }
    };

    onOk = async () => this.props?.onOk?.(this.state?.userToId); // TODO: userToId

    onClose = async () => {
        this.state?.showConfirm && await this.onConfirm(false);
        await this.props?.onClose?.();
    };

    onConfirm = async (showConfirm) => this.save({ showConfirm });

    onUserTo = async (userToId = 0, userTo = {}) => await this.save({ userToId, userTo, });

    renderConfirm = () => {
        const { translate, } = this.props;
        const optionsMagicConfirm = {
            title:    translate('delegation_modal_are_shure_descr'),
            accept:   translate('delegation_modal_are_shure_yes'),
            reject:   translate('delegation_modal_are_shure_no'),
            onReject: (event) => {
                event.preventDefault();
                this.onConfirm(false);
            },
            onAccept: this.onOk,
            isVisible: this.state?.showConfirm,
        };
        return (
            <MagicConfirm { ...optionsMagicConfirm } />
        );
    };

    render = () => {
        const { translate, } = this.props;
        return (
            <div className = 'modal_delegation'>
                <div className = 'modal_delegation-body'>
                    <div className = 'modal_delegation-close' onClick={ this.onClose }><i className = 'la la-close' /></div>

                    <div className = 'modal_delegation-header'>
                        <h3>{translate(`delegation_modal_title`)}</h3>
                    </div>

                    <div className = 'modal_delegation-block'>
                        <div className="modal_delegation_manager">
                            <span className="manager_title">{translate(`delegation_modal_title_manager`)}</span>
                            <span className="manager_title_delegation">{translate(`delegation_modal_title_manager_delegation`)}</span>
                        </div>

                        <div className = 'modal_delegation-block-manager'>
                            <ManagerDelegation
                                translate={ translate }
                                accountId={ this.props?.accountId }
                                taskIds={ this.props?.taskIds }
                                userId={ this.props?.userId }
                                user={ this.props?.user }
                                userToId={ this.state?.userToId }
                                userTo={ this.state?.userTo }
                                usersAvatars={ this.state?.usersAvatars.data }
                                usersTo={ this.state?.usersTo.data }
                                onUserTo={ this.onUserTo }
                            />
                        </div>
                    </div>

                    <div className='modal_delegation-footer'>
                        <div style={{ display: 'inline-block', position: 'relative', }}>
                            <div className='button filter_btn-delegation' onClick={ () => this.onConfirm(true) }>
                                { translate(`delegation_modal_btn_save`) }
                            </div>
                            { this.renderConfirm() }
                        </div>
                        <div className='button filter_btn-remove_delegation' onClick={ this.onClose }>
                            <span>{ translate(`delegation_modal_btn_cancel`) }</span>
                        </div>
                    </div>

                </div>
            </div>
        );
    };
};
