import React, { Component } from 'react';
import { MagicMultiSelect } from '../../../../components/Magic/components/MagicMultiSelect';
import PropTypes            from 'prop-types';
export class Citizenship extends Component {

   static propTypes = {
      disabled: PropTypes.bool,
      error: PropTypes.string,
      onSelect: PropTypes.func.isRequired,
      onUnSelect: PropTypes.func.isRequired,
      selectedValues: PropTypes.arrayOf(PropTypes.number),
      translate: PropTypes.func.isRequired,
      values: PropTypes.arrayOf(PropTypes.object).isRequired,
   };

   static defaultProps = {
      disabled: false,
      error: "",
   };

   render() {
      const values = this.props?.values
         .map(e => e?.id)
         .filter(v => !this.props?.selectedValues?.includes?.(v));
      const valueDefault = false;
      const valueAll = 0;
      const options = {
         SelectProps: {
            className: [
               'magic-multi-select__select citizenship',
               this.props?.selectedValues?.length ? 'magic-multi-select__select_not-empty' : '', 
               this.props?.error ? 'error' : ''
            ].filter(c => c),},
         disabled: this.props?.disabled,
         selectedValues: this.props?.selectedValues,
         valueDefault,
         values: values?.length ? [valueAll, ...values] : [],
         ItemProps: value => [valueDefault].includes(value) ? {disabled: true} : {},
         selectedToLabel: value => {
            const find = [valueDefault].includes(value) ? false : this.props?.values.find(el => value === el?.id);
            const className = ({alpha_2}) => ['flag-icon', 'flag-icon-squared', `flag-icon-${ alpha_2?.toLowerCase() }`]?.join(' ');
            return !find 
               ? ''
               : (<div title={ find?.name }>
                     <span className={className(find)}/>
                     <span className="title">{find?.alpha_2}</span>
                  </div>);
         },
         valueToLabel: value => {
            switch (value) {
               case valueDefault:
                  return this.props?.translate('contest_popup_select-citizenship');
               case valueAll:
                  return this.props?.translate('contest_popup_select-all');
            }
            return this.props?.values?.find?.(el => value === el?.id)?.name ?? '-';
         },
         onSelect: this.props?.onSelect,
         onUnSelect: this.props?.onUnSelect,
      };
      return (<MagicMultiSelect {...options} />);
   }
};
