import {
  itemsBuild,
  itemsDrain,
  itemsResort,
  CFG_ITEM_TYPE,
} from '../Config';
// import {} from '../Mentor/withDropDown';

const fields = [
  'TRADES.TICKET',
  'TRADES.OPEN_TIME',
  'TRADES.SYMBOL',
];

const f_all = {
  'f+': fields,
  'o=': [ '=', '!=', ],
};

const o_all = {
  'f+': fields,
};

export default {
  id: 80,
  ...[

    // filters
    ...[ {
      f: 'TRADES.TICKET',
      id: 'f:tTICKET',
      ...f_all,
    }, {
      f: 'TRADES.OPEN_TIME',
      id: 'f:tOPEN_TIME',
      ...f_all,
    }, {
      f: 'TRADES.SYMBOL',
      id: 'f:tSYMBOL',
      ...f_all,
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

    // orders
    ...[ {
      f: '',
      id: 'o:',
      off: true, // TODO:
      ...o_all,
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

    // panels
    ...[ {
      ii: 'f:tTICKET',
      t: 'magic_filter_translate_list_accounts_tradings_closed_orders_ticket',
    }, {
      ii: 'f:tOPEN_TIME',
      t: 'magic_filter_translate_list_accounts_tradings_closed_orders_open_time',
    }, {
      ii: 'f:tSYMBOL',
      t: 'magic_filter_translate_list_accounts_tradings_closed_orders_symbol',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

  ]
  .map(itemsBuild)
  .reduce(itemsDrain, [])
  .reduce(itemsResort, {}),

  path: 'core/trading-accounts/get-closed-orders-mt4/1',
  widget: false,
};