import React from 'react';

class DiscussionBlockMention extends React.Component {
    render () {
        return (
            <div className = "mention-popup">
                {this.props?.assistants.length > 0 ?
                    <div className="scroll">
                        {this.props.assistants.map((item) => (
                            <div
                                className = "mention-popup__user" 
                                key = { item.id }
                                onClick = { () => this.props.handleMentionUser(item.id) }
                            >
                                <p>{item.name ?? ''} {item.surname ?? ''}</p>
                            </div>
                        ))}
                    </div>
                    : (
                        <div className = 'empty' >{this.props.translate('no_assistants')}</div>
                    )}
            </div>
        );
    }
}

export default DiscussionBlockMention;
