import React, { Component, } from "react";
import { AffiliateProgramsStatus, } from "../consts/consts";
import { Popup, } from "../../../../../components";
class ConfirmChangePartnerProgrammStatus extends Component {

	renderTitle () {
		const { status, translate, } = this.props;
		const { PaymentsStopped, ACTIVE } = AffiliateProgramsStatus;

		switch (+status) {
			case PaymentsStopped: {
				return translate('affiliate_program_stop_payments');
			} case ACTIVE: {
				return translate('affiliate_program_resume_payments');
			} default: {
				return translate('affiliate_programs_close_program');
			}
		}
	}

	renderDescription () {
		const { status, translate, } = this.props;
		const {
			PaymentsStopped, ACTIVE, CLOSED,
		} = AffiliateProgramsStatus;

		switch (+status) {
			case PaymentsStopped: {
				return translate('affiliate_programs_stop_program_text');
			} case ACTIVE: {
				return translate('affiliate_program_resume_program_text');
			} case CLOSED: {
				return translate('affiliate_programs_close_text');
			} default: {
				return translate('affiliate_programs_stop_program_text');
			}
		}
	}

	renderConfirmButton () {
		const { status, } = this.props;
		const {
			PaymentsStopped, ACTIVE, CLOSED,
		} = AffiliateProgramsStatus;

		switch (+status) {
			case PaymentsStopped: {
				return 'affiliate_program_stop_program';
			} case ACTIVE: {
				return 'affiliate_program_resume_program';
			} case CLOSED: {
				return 'affiliate_programs_close_program_button';
			} default: {
				return 'affiliate_program_stop_program';
			}
		}
	}

	renderCheckBox = () => {
		const { translate, handleOnCheckbox, is_payout, } = this.props;

		return (
			<div className="block-header_input-checkbox switch-custom-styles">
				<label className="checkbox">
					<input
						checked={ is_payout }
						name="is_payout"
						type="checkbox"
						onClick={ handleOnCheckbox }
					/>
					<span className="checkbox__icon" />
				</label>
				<p className={ "checkbox__label" }> {translate("affiliate_program_with_renumeration")}</p>
			</div>
		);
	};

	render () {
		const {
			onConfirm, onCancel, status,
		} = this.props;
		const statusIsAcive = status === AffiliateProgramsStatus.ACTIVE;

		return (
			<Popup
				cancelBtnText={ "affiliate_program_go_back_to_editing" }
				createBtnText={ this.renderConfirmButton() }
				isImage={ false }
				size={ 'smd' }
				title={ this.renderTitle() }
				wrapperClassname={ `change-partner-programm change-programm-status` }
				onClick={ onConfirm }
				onClose={ onCancel }
			>
				<p className={ 'change-programm-status__description' }>{this.renderDescription()}</p>
				{statusIsAcive && this.renderCheckBox()}
			</Popup>
		);
	}
}

export default ConfirmChangePartnerProgrammStatus;