import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import { MagicTable } from '../../../../components/Magic';
import ModalUtmCreateAndUpdate from '../Modal/ModalUtmCreateAndUpdate';
import { marketingService } from '../../../../services/MarketingDataService';
import { MagicTooltip } from '../../../tima/components/Magic/MagicTooltip/MagicTooltip';

class MarketingProductsMagicTable extends Component {
  constructor (props) {
    super(props);

    this.state = {
      isModalShown: false,
      data: {
        id: null,
        name: '-',
        number: '-',
        description: '-',
        status: '-',
      }
    }
  };

  static propTypes = {
    data: PropTypes.array,
    enums: PropTypes.object,
  };

  static defaultProps = {
    data: [],
    enums: {},
  };

  save = async state => new Promise(next => this.setState(state, next));

  get productsTableConfig () {
    const { translate } = this.props;
    const prefix = 'statistics_utm_campaign_'

    return [
      {
        path: ['statistics', 'utm_campaign', 'name'],
        key: 'utmCampaign',
        render: this.renderInitialValue,
        title: `${ prefix }name`,
      },
      {
        path: [ 'statistics', 'utm_campaign', 'number' ],
        key: 'utmCampaignNumber',
        render: this.renderInitialValue,
        title: `${ prefix }number`,
      },
      {
        path: [ 'statistics', 'utm_campaign', 'description' ],
        key: 'utmCampaignDescription',
        render: this.renderDescription,
        title: `${ prefix }description`,
      },
      {
        path: [ 'statistics', 'utm_campaign', 'status' ],
        key: 'utmCampaignStatus',
        render: this.renderProductStatus,
        title: `${ prefix }status`,
      },
      {
        path: [ 'statistics', 'utm_campaign', 'id' ],
        key: 'utmCampaignId',
        render: this.renderChangeProduct,
        title: `statistics_utm_source_edit`,
      }
    ].map(({ title: t, xtitle: x, ...item }) =>
      ({ ...item, ...x && { title: x },
        ...t && { title: translate(t) },
      }));
  };

  renderChangeProduct = (value, { item, items }) => {
    const { translate } = this.props;
    if (item?.access?.('store') && value) {
      const url = `/marketing/advertising_channels/products/${items.utmCampaignId.valueOf}`
      return (
        <Link to={`${url}`}>
          <button
            onClick={(e) => {
              e.preventDefault()
              this.save({
                isModalShown: !this.state.isModalShown,
                data: {
                  name: items.utmCampaign.valueOf,
                  number: items.utmCampaignNumber.valueOf,
                  description: items.utmCampaignDescription.valueOf,
                  status: items.utmCampaignStatus.valueOf,
                  id: items.utmCampaignId.valueOf,
                }
              })
            }}
            className='marketing-buttons_table-btn'
          >
            {translate(`marketing_edit_channel`)}
          </button>
        </Link>
      );
    }
    return '-';
  };

  renderInitialValue = (value, { item }) => {
    if (item?.access?.('index') && item?.access?.('show')) {
      const LENGTH_SHORT = 30;
      return (
        <MagicTooltip
          classNameTooltip={`tooltip tooltip--large-width`}
          content={ value ?? '-' }
          lengthCut={ LENGTH_SHORT }
        />
      );
    }
    return '-';
  };

  renderDescription = (value, { item }) => {
    if (item?.access?.('index') && item.access?.('show')) {
      const LENGTH_SHORT = 30;
      return (
        <MagicTooltip
          classNameTooltip={`tooltip tooltip--large-width`}
          content={ value ?? '-' }
          lengthCut={ LENGTH_SHORT }
        />
      );
    }
    return '-';
  };

  renderProductStatus = (status, { item, }) => {
    if (item.access('index') && item?.access?.('show')) {
      const curObj = status === 1 ? 'active activeStatus' : status === 2 ? 'inactive' : '' ;

      return <div className={`status-active ${curObj}`}>
        {this.props?.enums?.statistics?.UtmCampaignStatusEnum?.[+status] ?? "-"}
      </div>
    }
    return '-';
  };

  renderLink = (path, text, className = "") => (<Link to={path} className={className}>{text}</Link>);

  updateUtmCampaign = async ({ description, ...data }) => {
    try {
      const dataNew = {
        ...data,
        ...description === ''
          ? { SET_NULL__description: "1", }
          : description
            ? { description, }
            : {},
      };
      await marketingService.updateUtmCampaign(this.state.data.id, dataNew);
      await this.props.onDataLoad();
      await this.save({ isModalShown: false });
    } catch (e) {
      console.log(e)
    }
  };

  render () {
    if (!this.props?.data?.length) {
      return null;
    }

    const options = {
      config: this.productsTableConfig,
      data: this.props?.data,
      head:   [
        'utmCampaign',
        'utmCampaignNumber',
        'utmCampaignDescription',
        'utmCampaignStatus',
        'utmCampaignId',
      ],
    };

    return (
      <>
        <MagicTable {...options} />
        {this.state.isModalShown === true ? (
          <ModalUtmCreateAndUpdate
            data={this.state.data}
            enums={this.props.enums}
            viewOnly={['name', 'number']}
            titleKey={{
              form: `marketing_add_edit_product`,
              name: `statistics_utm_medium_name`,
              number: `statistics_utm_medium_number`,
              description: `statistics_utm_medium_description`,
              status: `statistics_utm_medium_status`,
            }}
            onSave={this.updateUtmCampaign}
            onClose={() => this.save({ isModalShown: false })}
          />
        ) : null}
      </>
    );
  };
}

export default withRouter(withLocalize(MarketingProductsMagicTable));
