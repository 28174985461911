import React from "react";
// import ToolbarPanelText from './ToolbarPanelText';
// import ToolbarPanelLink from './ToolbarPanelLink';
// import ToolbarPanelItem from './ToolbarPanelItem';
import ToolbarPanelList from './ToolbarPanelList';
import './styles.scss';

export default function ToolbarPanel ( props ) {
    const { class4Panel, } = props;

    return (
        <div className={ class4Panel || `toolbar-panel` }>
            <ToolbarPanelList { ...props } />
        </div>
    );
}
