import React from 'react';
import { securedRequest } from '../../../../../services/RequestService/index';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import { withLocalize } from 'react-localize-redux';
import PermissionService from "../../../../../services/PermissionService";
import LeadDocumentRow from "./LeadDocumentRow";
import UploadDocument from "./UploadDocument";
import { Table, Button, Collapse } from "reactstrap";
import connect from "react-redux/es/connect/connect";
import { bindActionCreators } from "redux";
import { getClient, getAccountIndividualVerifierStatus } from "../../../services/AccountService";
import { Scrollbars } from "react-custom-scrollbars";
import NotificationService from "../../../../../services/NotificationService";
import {GET_ACCOUNT_DOCUMENT} from "../../../../../apiRoutes";
import {getAccount, getAllAccountDocuments, verifyDocument, getAccountDocument } from "../../../services/AccountRequestService";
import Preloader from "../../../../../components/LoadingHOC/Preloader";
import LoadingHOC from "../../../../../components/LoadingHOC";
import { getAllDepartmentsByUserId } from "../../../../dashboard/services/DashboardRequestService";
import PopUpVerification from "../PopUpVerification/PopUpVerification";
import PopUpVerificationReset from "../PopUpVerification/PopUpVerificationReset";
import PopUpReVerification from "../PopUpVerification/PopUpReVerification";
import PropTypes from "prop-types";
import { profileService } from "../../../../../services/ProfileDataService";

const EVENT_NAME = {
    onDocumentClick:        'onDocumentClick',
    onVerificationBtnClick: 'onVerificationBtnClick',
    onViewBtnClick:         'onViewBtnClick',
};
Object.freeze(EVENT_NAME);

const POP_UP_NAME = {
    verification:           'verification',
    verificationReset:      'verificationReset',
    reVerification:         'reVerification',

};
Object.freeze(POP_UP_NAME);

class DocumentsTable extends React.Component {
    static propTypes = {
        refreshClientData: PropTypes.func.isRequired,
    };

    constructor (props) {
        super(props);

        this.state = {
            departmentsList:               [],
            departmentsListNames:          [],
            docs:                          [],
            uploaded:                      false,
            account:                       {},
            documents:                     [],
            preloaderButton:               false,
            disabledClass:                 '',
            showUploadDocument:            false,
            isAdminOrVerifOrTechSuppFlag:  false,
            userId:                        null,

            isPopUpActive: {
                [POP_UP_NAME.verification]:      false,
                [POP_UP_NAME.verificationReset]: false,
                [POP_UP_NAME.reVerification]:    false,
            },
            loadedInnerRequest:         false,
            loadedInnerFixedRequest:    true,
            isDocumentsBlockVisible:    false,
            accountIndividualVerifierStatus: null,
            popUpParamsObj: {},
        };

        this.calcData = PermissionService.calc(this.documentsTableConfig);
    }

    get documentsTableConfig () {
        return [
            {
                path: ['core', 'account'],
                key:  'coreAccount',
            },
            {
                path: ['core', 'account_document'],
                key:  'coreAccountDocument',
            },
        ];
    }

    get accountRegisterType () { return this.props?.client?.account?.register_type; }

    get isVerifyDocument () { return this.props?.client?.account?.is_verify_document };

    save = async (state) => new Promise(next => this.setState(state, next));

    profileChange = async (profile) => {
        if (profile.hash===this.state?.profile?.hash) return `${this.constructor.name}.profileChange: false`;
        await this.save({ profile, userId: profile.data.id, });
        return `${this.constructor.name}.profileChange: true`;
    };

    async componentDidMount() {
        profileService.subscribe('profile', this.profileChange, this);
        await profileService.profile;
        await this.getAccountOne();
        await this._getAllDepartmentsByUserId();
        await this.getAccountDocuments();
    }

    componentWillUnmount () {
        profileService.unsubscribe('profile', this.profileChange, this);
    }

    _getAllDepartmentsByUserId = async () => {
        let isAdminOrVerifOrTechSuppFlag = false;

        try {
            const response = await getAllDepartmentsByUserId(this.state?.userId);
            const departmentsList = response?.data;
            const whiteDepartmentList = ['administration', 'technical_support', 'verification', 'financial'];

            if (departmentsList && departmentsList?.length) {
                const departmentsListNames = departmentsList?.map((item) => item?.alias);

                isAdminOrVerifOrTechSuppFlag = departmentsListNames?.some((item) => whiteDepartmentList.includes(item));
                await this.save({ isAdminOrVerifOrTechSuppFlag, departmentsList, departmentsListNames });
            }
        } catch (error) {
            console.log("error", error);
            await this.save({ isAdminOrVerifOrTechSuppFlag, departmentsList: [], departmentsListNames: [] });
        }
    };

    getAccountOne = async () => {
        try {
            const response = await getAccount(this.props?.accountId);
            await this.save({ account: response?.data?.core });
        } catch (error) {
            NotificationService.errors(error?.response?.data?.core);
        }
    };

    loadAccountIndividualVerifierStatus = async () => {
        await this.save({ loadedInnerFixedRequest: false, });

        try {
            const response = await getAccountIndividualVerifierStatus(this.props?.accountId);
            await this.save({ accountIndividualVerifierStatus: response });

            return true;
        } catch (error) {
            await this.save({ accountIndividualVerifierStatus: null });

            return false;
        } finally {
            await this.save({ loadedInnerFixedRequest: true, });
        }
    };

    getAccountDocuments = async () => {
        await this.save({ loadedInnerRequest: false, });

        try {
            await this.save({ uploaded: true });
            const response = await getAllAccountDocuments(this.props?.accountId);
            await this.save({ documents: response?.data });
            // await this.getBase64Document(0);
            await this.getBase64AccountDocuments();
        } catch (error) {
            NotificationService.errors(error?.response?.data);
        }

        await this.save({ loadedInnerRequest: true, });
    };

    getBase64AccountDocuments = async () => {
        const { documents = [] } = this.state;
        const documentsLength = documents.length;

        const promises = documents.map(async (item, index) => await this.getBase64Document(index));
        const results = await Promise.all(promises);

        if (documentsLength === results?.length) {
            results.forEach((src, index) => {
                documents[index]['src'] = src;
            });

            await this.save({ documents });
        }
    };

    hideUploadDocument = async () => {
        await this.save({ showUploadDocument: false });
    };

    _fileEncode (arrayBuffer, document) {
        const b64encoded = btoa([].reduce.call(new Uint8Array(arrayBuffer), (p, c) => {
            return p+String.fromCharCode(c);
        }, ''));

        return {
            "mimetype": document?.core?.account_document?.mime_type,
            "base64":   b64encoded,
        };
    }

    getOneDocument = async (aliasName, document) => {
        try {
            const response = await securedRequest.get(`${GET_ACCOUNT_DOCUMENT}/${aliasName}`, { responseType: 'arraybuffer' });
            const fileEncode = await this._fileEncode(response?.data, document);
            const src = `data:${fileEncode?.mimetype};base64,${fileEncode?.base64}`;

            return src;
        } catch {
            return 'error';
        }
    };

    getBase64Document = async (index) => {
        const document = this.state?.documents[index] ?? '';

        if (!document) {
            this.save({ uploaded: true });
        }
        if (document?.core?.account_document?.alias_name) {
            let docs = this.state?.documents;
            for (const item of docs) {
                let src = await this.getOneDocument(document?.core?.account_document?.alias_name, document);
                // docs[index]['src'] = src;
                // await this.save({ documents: docs });
                // await this.getBase64Document(++index);
                return src;
            }
        } else {
            return false;
        }
    };

    updateVerifyDocument = async (event) => {
        const verify_document = {
            'is_verify_document': event.currentTarget?.dataset?.verified,
        };

        if (this.state.disabledClass != '') {
            return true;
        }
        await this.save({
            preloaderButton: true,
            disabledClass:   'disabled',
        });
        try {
            await verifyDocument(this.props.accountId, verify_document);
            await this.getAccountOne();
            await this.save({
                preloaderButton: false,
                disabledClass:   '',
            });
            await this.props.getClient(this.props.accountId);
        } catch (error) {
            await this.save({
                preloaderButton: false,
                disabledClass:   '',
            });
            console.log("error", error);
            NotificationService.error({
                title:   'error',
                message: error?.errors?.is_verify_document ? error.errors.is_verify_document[0] : "Could not update",
                remove:  false,
            });
        }
    };

    toggleUploadForm = async () => {
        await this.save({ showUploadDocument: !this.state.showUploadDocument });
    };

    hideUploadDocument = async () => {
        await this.save({ showUploadDocument: false });
    };

    /*_fileEncode (arrayBuffer, document) {
        const b64encoded = btoa([].reduce.call(new Uint8Array(arrayBuffer), (p, c) => {
            return p+String.fromCharCode(c);
        }, ''));

        return {
            "mimetype": document['core-account_document-mime_type'],
            "base64":   b64encoded,
        };
    }*/

    popUpDeterminant = (eventName) => ({ activeDocumentIndex, isScrollToDocumentsBlock }) => async () => {
        const { departmentsListNames } = this.state;
        const permission = this.calcData?.coreAccount?.access('individualVerifierStatus');
        const isVerificator = departmentsListNames?.includes('verification');
        const accountRegisterType = this.accountRegisterType?.toString() ?? '';
        const isVerifyDocument = this.isVerifyDocument?.toString() ?? '';

        if (accountRegisterType === '2') {
            //TODO: the logic for business accounts
            this.popUpVerificationOpen(POP_UP_NAME.verification)({ activeDocumentIndex, isScrollToDocumentsBlock, eventName });

            return;
        }

        if (!permission) {
            this.popUpVerificationOpen(POP_UP_NAME.verification)({ activeDocumentIndex, isScrollToDocumentsBlock, eventName });

            return;
        }

        const responseFlag = await this.loadAccountIndividualVerifierStatus();

        if (!responseFlag) {
            return;
        }

        const { accountIndividualVerifierStatus } = this.state;
        const {
            check_external_verification,
            check_external_reset,
            service_is_live,
        } = accountIndividualVerifierStatus ?? {};

        switch (eventName) {
            case EVENT_NAME.onDocumentClick: {
                this.popUpVerificationOpen(POP_UP_NAME.verification)({ activeDocumentIndex, isScrollToDocumentsBlock, eventName });

                break;
            }
            case EVENT_NAME.onViewBtnClick: {
                this.popUpVerificationOpen(POP_UP_NAME.verification)({ activeDocumentIndex, eventName });

                break;
            }
            case EVENT_NAME.onVerificationBtnClick: {
                if (isVerificator) {
                    if (isVerifyDocument === '2') {
                        // NOT VERIFIED
                        service_is_live && !check_external_verification ?
                            this.popUpVerificationOpen(POP_UP_NAME.reVerification)({ eventName })
                            :
                            this.popUpVerificationOpen(POP_UP_NAME.verification)({ activeDocumentIndex, eventName });

                    } else if (isVerifyDocument === '3') {
                        // RE_VERIFIED
                        if (service_is_live && check_external_verification && !check_external_reset) {
                            this.popUpVerificationOpen(POP_UP_NAME.verificationReset)({ eventName });
                        } else if (service_is_live && !check_external_verification) {
                            this.popUpVerificationOpen(POP_UP_NAME.reVerification)({ eventName });
                        } else {
                            this.popUpVerificationOpen(POP_UP_NAME.verification)({ activeDocumentIndex, eventName });
                        }
                    }
                } else {
                    this.popUpVerificationOpen(POP_UP_NAME.verification)({ activeDocumentIndex, eventName });
                }

                break;
            }
            default: {
                break;
            }
        }
    };

    popUpVerificationOpen = (popUpName) => async (popUpParamsObj) => {
        await this.save((state) => ({
            isPopUpActive: {
                ...state.isPopUpActive,
                [popUpName]: true,
            },
            popUpParamsObj,
        }));
    };

    popUpVerificationClose = (popUpName) => async () => {
        await this.save((state) => ({
            isPopUpActive: {
                ...state.isPopUpActive,
                [popUpName]: false,
            },
            popUpParamsObj: {},
        }));
    };

    renderPopUpVerification = () => {
        const {
            accountIndividualVerifierStatus,
            documents,
            departmentsListNames,
            isPopUpActive = {},
            popUpParamsObj = {},
            userId,
        } = this.state;
        const {
            verification: isPopUpVerificationActive,
            verificationReset: isPopUpVerificationResetActive,
            reVerification: isPopUpReVerificationActive,
        } = isPopUpActive;
        const { client, enums, accountId, refreshClientData } = this.props;

        switch (true) {
            case isPopUpVerificationActive: {
                const options = {
                    accountIndividualVerifierStatus,
                    accountId,
                    client,
                    departmentsListNames,
                    documents,
                    enums,
                    ...popUpParamsObj,
                    userId,
                    refreshClientData,
                    popUpClose: this.popUpVerificationClose(POP_UP_NAME.verification),
                };

                return (
                    <PopUpVerification {...options}/>
                );
            }
            case isPopUpVerificationResetActive: {
                const options = {
                    accountIndividualVerifierStatus,
                    accountId,
                    client,
                    departmentsListNames,
                    enums,
                    eventName: popUpParamsObj?.eventName,
                    userId,
                    refreshClientData,
                    popUpReVerificationOpen: this.popUpVerificationOpen(POP_UP_NAME.reVerification),
                    popUpClose: this.popUpVerificationClose(POP_UP_NAME.verificationReset),
                };

                return (
                    <PopUpVerificationReset {...options}/>
                );
            }
            case isPopUpReVerificationActive: {
                const options = {
                    accountIndividualVerifierStatus,
                    accountId,
                    client,
                    departmentsListNames,
                    documents,
                    enums,
                    eventName: popUpParamsObj?.eventName,
                    userId,
                    refreshClientData,
                    popUpClose: this.popUpVerificationClose(POP_UP_NAME.reVerification),
                };

                return (
                    <PopUpReVerification {...options}/>
                );
            }
            default: {
                return null;
            }
        }
    };

    onVerificationViewBtnClick = (eventName) => () => {
        const activeDocumentIndex = 0;

        this.popUpDeterminant(eventName)({ activeDocumentIndex })();
    };

    renderVerificationBtn = () => {
        const { translate } = this.props;
        const { departmentsListNames } = this.state;
        const permission = true; // this.calcData?.coreAccount?.access('store', 'update');
        const btnVerificationClassName = permission ? '' : 'gl-btn--disabled';
        const accountRegisterType = this.accountRegisterType?.toString() ?? '';
        const isVerifyDocument = this.isVerifyDocument?.toString() ?? '';

        const btnTextMap = ['client_verification_view_btn', 'client_verification_btn'];
        const eventNamesMap = [EVENT_NAME.onViewBtnClick, EVENT_NAME.onVerificationBtnClick];

        let condition = null;

        // AccountRegisterTypeEnum: 1 - 'Individual', 2 - 'Business', 3 - 'Test'
        if (isVerifyDocument === '1') {
            condition = 0; // always render ViewBtn for verified account
        } else {
            switch (accountRegisterType) {
                case '1':
                case '3': {
                    condition = departmentsListNames?.includes('technical_support') || departmentsListNames?.includes('verification');
                    break;
                }
                case '2': {
                    condition = departmentsListNames?.includes('technical_support') || departmentsListNames?.includes('legal');
                    break;
                }
                default: {
                    break;
                }
            }
        }

        const btnName = btnTextMap?.[+condition] ?? '';
        const eventName = eventNamesMap?.[+condition] ?? '';

        return (
            <button className={`gl-btn gl-btn--blue verification-btn ${btnVerificationClassName}`}
                    onClick={this.onVerificationViewBtnClick(eventName)}
            >
                {translate(btnName)}
            </button>
        );
    };

    renderVerificationStatus = () => {
        const isVerifyDocument = this.isVerifyDocument;

        const statusToIconClassNameMap = {
            1: 'status-success',
            2: 'status-cancel',
            3: 'status-warning',
        };
        const iconClassName = statusToIconClassNameMap?.[isVerifyDocument] ?? '';

        return (
            <div className={`icon icon--${iconClassName}`}></div>
        );
    };

    onToggleDocumentsBtnClick = async () => {
        await this.save({ isDocumentsBlockVisible: !this.state.isDocumentsBlockVisible });
    };

    render () {
        const { translate } = this.props;
        const {
            preloaderButton,
            documents,
            account,
            loadedInnerRequest,
            loadedInnerFixedRequest,
            isDocumentsBlockVisible,
        } = this.state;
        const preload = preloaderButton ? <i className = 'fa fa-spinner fa-spin ' /> : '';
        const verifiedDocuments = this.state?.account?.account?.is_verify_document;
        const documentsLength = documents?.length;
        const calcData = this.calcData;
        const toggleDocumentsBtnCanClickClass = documentsLength ? 'toggle-documents-btn--can-click' : '';
        const toggleDocumentsBtnClass = isDocumentsBlockVisible ? 'up' : 'down';

        if (!documents || !account?.account || !verifiedDocuments || !loadedInnerRequest) {
            return <div className = 'block-shadowed documents-table'><Preloader scale = { 0.8 } /></div>;
        }

        return (
            <div className='block-shadowed documents-table'>

                {!loadedInnerFixedRequest ?
                    (
                        <Preloader className='loaderUniversal--fixed-pos' scale={1}/>
                    ) : null
                }

                {this.renderPopUpVerification()}

                {this.state.showUploadDocument
                    ?
                    <LoadingHOC callFunc={false} callReduxProps={this.props.enums} scale={0.8}>
                        <UploadDocument
                            registerType={this.accountRegisterType}
                            accountId={this.props.accountId}
                            getAccountDocuments={this.getAccountDocuments}
                            hideUploadDocument={this.hideUploadDocument}
                            getClient={this.props.getClient}
                            refreshClientData={this.props?.refreshClientData}
                        />
                    </LoadingHOC>
                    : null}

                <div className={'table-title-cont icon-styles'}>
                    <span className='table-title'>
                        {this.state.isAdminOrVerifOrTechSuppFlag ?
                            (
                                <Button color='link' onClick={this.toggleUploadForm} size='sm'>
                                    <i aria-hidden='true' className='fa fa-plus-circle color-green'/>
                                </Button>
                            ) : ''
                        }

                        <span className={`toggle-documents-btn ${toggleDocumentsBtnCanClickClass}`}
                              onClick={documentsLength ? this.onToggleDocumentsBtnClick : () => {}}
                        >
                            {translate(`client_documents`)}

                            {documentsLength ? (
                                <React.Fragment>
                                    {` (${documentsLength}) `}
                                    <i className={`la la-chevron-circle-${toggleDocumentsBtnClass}`} />
                                </React.Fragment>
                            ) : ''}
                        </span>

                    </span>

                    {this.renderVerificationBtn()}

                    {/*{this.renderVerificationStatus()}*/}
                </div>

                {calcData?.coreAccountDocument?.access('getAccountDocuments') ? (
                    <div>
                        {documents?.length > 0
                            ?
                            <Collapse isOpen = { isDocumentsBlockVisible }>
                                <Scrollbars
                                    autoHeight
                                    autoHeightMax={871}
                                    renderThumbVertical={(props) => <div {...props} className='thumb-vertical'/>}
                                    renderView={(props) => <div {...props} className='view'/>}>
                                    <Table className='table-stripped table-ubordered'>
                                        <thead>
                                        <tr>
                                            <th>{translate(`client_image`)}</th>
                                            <th>{translate(`client_type`)}</th>
                                            <th>{translate(`client_document_side`)}</th>
                                            <th>{translate(`client_created_at`)}</th>
                                            <th>{translate(`client_status`)}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            documents?.map?.((item, index) => {
                                                return (
                                                    <LeadDocumentRow
                                                        key={index}
                                                        document={item}
                                                        documents={documents}
                                                        client={this.props?.client}
                                                        accountId={this.props?.accountId}
                                                        index={index}
                                                        getAccountDocuments={this.getAccountDocuments}
                                                        hideUploadDocument={this.hideUploadDocument}
                                                        popUpVerificationOpen={this.popUpDeterminant(EVENT_NAME.onDocumentClick)}
                                                        enums={this.props?.enums}
                                                    />
                                                );
                                            })
                                        }
                                        </tbody>
                                    </Table>
                                </Scrollbars>
                            </Collapse>
                            : null}
                    </div>
                ) : null }
            </div>
        );
    }
}

export default withLocalize(connect(
    null,
    (dispatch) =>
        bindActionCreators({
            getClient,
        }, dispatch)
)(DocumentsTable));
