import React, { Component } from 'react';
import { withLocalize } from "react-localize-redux";
import { withRouter } from 'react-router-dom';
import { LIST_ACCOUNTS_EXPORT_FIELDS, /* TAB_ID*/ } from '../../consts/constants';

import { Breadcrumbs, } from '../../../tima/components/Breadcrumbs';
import { ToolbarPanel, } from '../../../tima/components/Toolbar';
import * as configBreadcrumbs from '../../configs/ListAccountsLinkBreadcrumbsConfig';
import * as configToolbar from '../../configs/ListAccountsLinkToolbarConfig';

import { securedRequest } from "../../../../services/RequestService";
import {
  showExportToExcelError,
} from "../../../../components/Magic";
import Preloader from "../../../../components/LoadingHOC/Preloader";
import Loader from "../../../tima/components/Loader";
import PermissionService from "../../../../services/PermissionService";

import OneTradingAccountGeneralInfo from "../ListPages/OneTradingAccountGeneralInfo";
import OpenOrders from '../ListPages/OpenOrders';
import ClosedOrders from "../ListPages/ClosedOrders";
import RiskManager from '../ListPages/RiskManager';
import Logs from '../ListPages/Logs';
import ClosedOrdersMT5 from "../ListPages/ClosedOrdersMT5";
import OpenOrdersMT5 from "../ListPages/OpenOrdersMT5";
import OpenPositionsMT5 from "../ListPages/OpenPositionsMT5";
import ClosedPositionsMT5 from "../ListPages/ClosedPositionsMT5";
import ClosedDealsMT5 from "../ListPages/ClosedDealsMT5";
import { enumService } from "../../../../services/EnumDataService";
import { listAccountService } from "../../../../services/ListAccountDataService";

const TAB_ID = {
  tradingAccountGeneralInfo:     'general',
  tradingAccountOpenOrdersMT4:   'opened-orders-mt4',
  tradingAccountClosedOrdersMT4: 'closed-orders-mt4',
  tradingAccountRiskManager:     'risk-manager-mt4',
  tradingAccountLogsMT4:         'logs-mt4',

  tradingAccountOpenPositionsMT5:   'opened-positions-mt5',
  tradingAccountOpenOrdersMT5:      'opened-orders-mt5',
  tradingAccountClosedOrdersMT5:    'closed-orders-mt5',
  tradingAccountClosedDealsMT5:     'closed-deals-mt5',
  tradingAccountClosedPositionsMT5: 'closed-positions-mt5',
  tradingAccountRiskManagerMT5:     'risk-manager-mt5',
};
Object.freeze(TAB_ID);

const breadcrumbs = (
  translate,
  activeTab,
  {
    tradingAccountId,
  }) => {
  const config = configBreadcrumbs;

  switch (activeTab) {
    case TAB_ID.tradingAccountOpenOrdersMT4:
      return config.breadcrumbs4OpenedOrdersMT4(translate, activeTab, tradingAccountId);
    case TAB_ID.tradingAccountClosedOrdersMT4:
      return config.breadcrumbs4ClosedOrdersMT4(translate, activeTab, tradingAccountId);
    case TAB_ID.tradingAccountRiskManager:
      return config.breadcrumbs4RiskManagerMT4(translate, activeTab, tradingAccountId);
    case TAB_ID.tradingAccountLogsMT4:
      return config.breadcrumbs4LogsMT4(translate, activeTab, tradingAccountId);

    case TAB_ID.tradingAccountOpenPositionsMT5:
      return config.breadcrumbs4OpenedPositionsMT5(translate, activeTab, tradingAccountId);
    case TAB_ID.tradingAccountOpenOrdersMT5:
      return config.breadcrumbs4OpenedOrdersMT5(translate, activeTab, tradingAccountId);
    case TAB_ID.tradingAccountClosedOrdersMT5:
      return config.breadcrumbs4ClosedOrdersMT5(translate, activeTab, tradingAccountId);
    case TAB_ID.tradingAccountClosedDealsMT5:
      return config.breadcrumbs4ClosedDealsMT5(translate, activeTab, tradingAccountId);
    case TAB_ID.tradingAccountClosedPositionsMT5:
      return config.breadcrumbs4ClosedPositionsMT5(translate, activeTab, tradingAccountId);
    case TAB_ID.tradingAccountRiskManagerMT5:
      return config.breadcrumbs4RiskManagerMT5(translate, activeTab, tradingAccountId);

    case TAB_ID.tradingAccountGeneralInfo:
    default:
      return config.breadcrumbs4General(translate, activeTab, tradingAccountId);
  }
};

const toolbar = (
  translate,
  activeTab,
  {
    tradingAccountId,
  },
  platform,
  permissions,
  isDemoTradingAccountType) => {
  const config = configToolbar;

  switch (+platform) {
    // AccountBalancesPlatformEnum
    case 1: {
      return [...config.toolbarGeneral(translate, activeTab, tradingAccountId, permissions), ...config.toolbarMoreMT4(translate, activeTab, tradingAccountId, permissions, isDemoTradingAccountType)];
    }
    case 2: {
      return [...config.toolbarGeneral(translate, activeTab, tradingAccountId, permissions), ...config.toolbarMoreMT5(translate, activeTab, tradingAccountId, permissions, isDemoTradingAccountType)];
    }
    default: {
      return [...config.toolbarGeneral(translate, activeTab, tradingAccountId, permissions)];
    }
  }

};

class TradingAccount extends Component {

  constructor (props) {
    super(props);

    this.state = {
      id: parseInt(this.params().tradingAccountId),
      loaded: false,
      ...this.getInitialState(),
    };

    let mf = null;

    Object.defineProperty(this, 'mf', {
      get: () => mf ?? {},
      set: (_) => {
        mf = _;
      },
    });
  }

  save = async (state) => new Promise(next => this.setState(state, next));

  getInitialState = () => {
    return {
      trading_account_page:     false,
      mt_login:                 '',
      platform:                 '',
      isDemoTradingAccountType: null,
      risk_type:                null,
      hash:                     null,
      riskManagement:           0,
    };
  };

  params = () => this.props.match.params;

  query = () => new URLSearchParams(this.props.location.search);

  pagination = () => {
  };

  enumsChange = async (enums) => {
    if (enums.hash===this.state?.enums?.hash) return `${this.constructor.name}.enumsChange: false`;
    await this.save({ enums, });
    return `${this.constructor.name}.enumsChange: true`;
  };

  tradingAccountDataChange = async (tradingAcc) => {
      if (tradingAcc.hash===this.state?.hash) return `${this.constructor.name}.tradingAccountDataChange: false`;
      const { data = {}, hash } = tradingAcc;
      const { core = {} } = data;
      const { trading_accounts = {}, risks_live = {}, risks_demo = {} } = core;
      const { mt_login = '', platform, type: tradingAccountType, risk_management } = trading_accounts;
      const { risk_type: riskTypeLive = null } = risks_live;
      const { risk_type: riskTypeDemo = null } = risks_demo;
      const isDemoTradingAccountType = [6, 7, 8, 10].includes(+tradingAccountType);
      const riskManagement = +risk_management;
      await this.save({
          trading_account_page: tradingAcc?.data ?? {},
          mt_login,
          platform,
          tradingAccountType,
          isDemoTradingAccountType,
          risk_type: isDemoTradingAccountType ? riskTypeDemo : riskTypeLive,
          riskManagement,
          hash,
      });

      return `${this.constructor.name}.tradingAccountDataChange: true`;
  };

  componentDidMount = async () => {
    enumService.subscribe('enums', this.enumsChange, this);
    listAccountService.subscribe('tradingAccountPage', this.tradingAccountDataChange, this);
    await enumService.enums;
    await this.onDataLoad();
  }

  componentDidUpdate ($props, $state, $snapshot) {}

  componentWillUnmount = () => {
    enumService.unsubscribe('enums', this.enumsChange, this);
    listAccountService.unsubscribe('tradingAccountPage', this.tradingAccountDataChange, this);
  }

  get tradingAccountConfig () {
    return [ {
      key: 'tradingAccounts',
      path: [ 'core', 'trading_accounts', ],
    }, {
      key: 'risksLive',
      path: [ 'core', 'risks_live', ],
    }, {
      key: 'risksDemo',
      path: [ 'core', 'risks_demo', ],
    }, {
      key: 'riskLiveMt5',
      path: [ 'core', 'risk_live_mt5', ],
    }, ];
  }

  isRiskManagerEnabled = () => this.state.riskManagement;

  // TODO: onDataLoad renew style
  onDataLoad = () => {
    const { id } = this.state;
    const setLoaded = (loaded) => this.setState(() => ({ loaded, }));
    const setData = (data) => this.setState(() => (data));
    const load0 = async () => await listAccountService.tradingAccountPage(id);

    setLoaded(false);
    (async () => {
      try {
        const response = await load0();
        const { data = {}, hash } = response;
        const { core = {} } = data;
        const { trading_accounts = {}, risks_live = {}, risks_demo = {} } = core;
        const { mt_login = '', platform, type: tradingAccountType, risk_management } = trading_accounts;
        const { risk_type: riskTypeLive = null } = risks_live;
        const { risk_type: riskTypeDemo = null } = risks_demo;
        const isDemoTradingAccountType = [6, 7, 8, 10].includes(+tradingAccountType);
          const riskManagement = +risk_management;

        setData({
          trading_account_page: response?.data ?? {},
          mt_login,
          platform,
          tradingAccountType,
          isDemoTradingAccountType,
          risk_type: isDemoTradingAccountType ? riskTypeDemo : riskTypeLive,
          riskManagement,
          hash,
        });
        setLoaded(true);
      } catch (error) {
        setData(this.getInitialState());
        error?.showErrorNotification?.();
      }
    })();
  };

  exportToExcel = (activeTab) => (event) => {
    if (activeTab) {
      const { tradingAccountId: id } = this.params();
      const { filterId: filter_id, } = this.mf;
      const globalUrl = `/api/core/trading-accounts`;
      const exportUrl = `export-to-excel`;

      let url = '',
        file_name = '',
        fields = [];

      let filterOptions = '';

      switch (activeTab) {
        case TAB_ID.tradingAccountOpenOrdersMT4: {
          url = `${globalUrl}/get-open-orders-mt4/${id}/${exportUrl}`;
          file_name = `Open_orders_by_trading_account_${id}`;
          fields = LIST_ACCOUNTS_EXPORT_FIELDS['tradingAccountOpenOrdersMT4'];
          break;
        }
        case TAB_ID.tradingAccountClosedOrdersMT4: {
          url = `${globalUrl}/get-closed-orders-mt4/${id}/${exportUrl}`;
          file_name = `Closed_orders_by_trading_account_${id}`;
          fields = LIST_ACCOUNTS_EXPORT_FIELDS['tradingAccountClosedOrdersMT4'];
          break;
        }
        /*case TAB_ID.tradingAccountLogsMT4: {
          url = `${globalUrl}/get-journal-mt4/${id}/${exportUrl}`;
          file_name = `Logs_by_trading_account_${id}`;
          fields = LIST_ACCOUNTS_EXPORT_FIELDS['tradingAccountLogsMT4'];
          // TODO: REMOVE HARD CODE FILTER PARAMS {}
          filterOptions = {
            mode: 4,
            from: '01.08.2019 00:00:00',
            to: '10.08.2019 00:00:00',
            login: 21572,
          };
          break;
        }*/
        default: {
          break;
        }
      }

      const options = {
        file_name,
        fields,
        ...filterOptions,
      };

      if (filter_id) {
        options['filter_id'] = filter_id;
      }

      securedRequest.post(url, options)
        .then((res) => {
          // fileDownload(res.data, res.headers.filename);
        })
        .catch((error) => {
          showExportToExcelError(error);
        });
    }
  };

  renderExportToExcelBtn = (activeTab) => {
    const { translate, } = this.props;
    const conf = PermissionService.calc(this.tradingAccountConfig);
    const exportToExcelPermission = conf?.tradingAccounts?.access('exportToExcel') ?? false;

    return exportToExcelPermission ?
      (
        <div
          className="button button--turquoise"
          onClick={ this.exportToExcel(activeTab) }
        >
          {translate('export_excel_btn')}
        </div>
      ) : null;
  };

  render = () => {
    const renderToolbar = (items) => (
      <div className='content-block'>
        <div className='toolbar'>
          <ToolbarPanel
            title={''}
            items={[items[0]]}
            class4List={`toolbar-panel__list toolbar-panel__list_flat`}
          />

          <ToolbarPanel
            title={translate(`list_accounts_basic_information_additional`)}
            items={items.slice(1)}
            class4List={`toolbar-panel__list toolbar-panel__list_flat`}
          />
        </div>
      </div>
    );
    const renderContent = (translate, active, params, platform, query) => {
      const options = {
        enums: this.state?.enums?.data,
        translate,
        params,
        platform,
        query,
      };

      switch (active) {
        case TAB_ID.tradingAccountClosedOrdersMT4: {
          const { id, } = this.state;
          const props = { ...options, id, mf: _ => this.mf = _, };
          return (<ClosedOrders {...props} />);
        }
        case TAB_ID.tradingAccountOpenOrdersMT4: {
          const { id, } = this.state;
          const props = { ...options, id, mf: _ => this.mf = _, };
          return (<OpenOrders {...props} />);
        }
        case TAB_ID.tradingAccountRiskManager:
        case TAB_ID.tradingAccountRiskManagerMT5: {
          const { id, trading_account_page: data, isDemoTradingAccountType } = this.state;
          const props = { ...options, id, data, isDemoTradingAccountType };
          return this.isRiskManagerEnabled() ? (
            <RiskManager {...props} />) : ('!!! Error: No RiskManager !!!');
        }
        case TAB_ID.tradingAccountLogsMT4: {
          const { id, mt_login, } = this.state;
          const props = { ...options, id, mt_login, };
          return (<Logs {...props} />);
        }
        case TAB_ID.tradingAccountClosedDealsMT5: {
          const { id, } = this.state;
          const props = { ...options, id, mf: _ => this.mf = _, };
          return (<ClosedDealsMT5 {...props} />);
        }
        case TAB_ID.tradingAccountClosedOrdersMT5: {
          const { id, } = this.state;
          const props = { ...options, id, mf: _ => this.mf = _, };
          return (<ClosedOrdersMT5 {...props} />);
        }
        case TAB_ID.tradingAccountOpenOrdersMT5: {
          const { id, } = this.state;
          const props = { ...options, id, mf: _ => this.mf = _, };
          return (<OpenOrdersMT5 {...props} />);
        }
        case TAB_ID.tradingAccountClosedPositionsMT5: {
          const { id, } = this.state;
          const props = { ...options, id, mf: _ => this.mf = _, };
          return (<ClosedPositionsMT5 {...props} />);
        }
        case TAB_ID.tradingAccountOpenPositionsMT5: {
          const { id, } = this.state;
          const props = { ...options, id, mf: _ => this.mf = _, };
          return (<OpenPositionsMT5 {...props} />);
        }
        default:
        case TAB_ID.tradingAccountGeneralInfo: {
          const { id: account_id, mt_login, trading_account_page: data, isDemoTradingAccountType } = this.state;
          const props = {
            ...options, account_id, mt_login, data, isDemoTradingAccountType,
            // trading_account_page: data,
            refreshTradingAccountData: this.onDataLoad, // bind refresh data func to the child
          };
          return (<OneTradingAccountGeneralInfo {...props} />);
        }
      }
    };

    const renderSideBar = (activeTab, translate) => {
      switch (activeTab) {
        case TAB_ID.tradingAccountOpenOrdersMT4:
        case TAB_ID.tradingAccountClosedOrdersMT4:
          return (
            <React.Fragment>
              {this.renderExportToExcelBtn(activeTab)}
            </React.Fragment>
          );
        default:
          return '';
      }
    };

    const { loaded, platform, isDemoTradingAccountType } = this.state;
    const { activeTab, translate, } = this.props;
    const params = this.params();
    const permissions = PermissionService.calc(this.tradingAccountConfig);

    let breadcrumbsItems = breadcrumbs(translate, activeTab, params);
    let toolbarItems = toolbar(translate, activeTab, params, platform, permissions, isDemoTradingAccountType);

    if (!this.isRiskManagerEnabled()) {
      const filterFunc = (item) => {
        // return item.key !== TAB_ID.tradingAccountRiskManager;
        return ![TAB_ID.tradingAccountRiskManager, TAB_ID.tradingAccountRiskManagerMT5].includes(item.key);
      };

      breadcrumbsItems = breadcrumbsItems.filter(filterFunc);
      toolbarItems = toolbarItems.filter(filterFunc);
    }

    return (
      <div className={`trading_accounts`}>
        <div className='content-block'>
          <div className='top-page-block'>
            <div>
              <h1 className='page-title'>
                {translate(`list_accounts_table_mt_login`)} {this.state.mt_login}
              </h1>
              <Breadcrumbs items={breadcrumbsItems}/>
            </div>
            {renderSideBar(activeTab, translate)}
          </div>
        </div>
        {toolbarItems.length ? renderToolbar(toolbarItems) : ''}

        <Loader
          loaded={loaded}
          loading={(<Preloader scale={1}/>)}
          translate={translate}
        >
          {renderContent(translate, activeTab, params, platform, this.query())}
        </Loader>

      </div>
    );
  };
}

export default withLocalize(withRouter(TradingAccount));
