import React, { Component } from 'react';

class ModalWrapper extends Component {
    constructor (props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    static getDerivedStateFromProps (nextProps, prevState) {
        if (prevState.open !== nextProps.open) {
            return {
                open: nextProps.open,
            };
        }

        return null;

    }

    render () {
        return (
            <React.Fragment>
                <div className = { this.state.open ? 'kpi-wrapper open' : 'kpi-wrapper' }>
                    <div style = { { position: 'relative', zIndex: 99 } }>
                        {this.props.children}
                    </div>
                </div>
                {/*{this.state.open ?
                    <div onClick = { this.props.openFunction } style = { { width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, zIndex: 98 } } />
                    : null}*/}
            </React.Fragment>
        );
    }
}

export default ModalWrapper;
