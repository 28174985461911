import * as actions from './actionTypes';

const initialState = {
    login: {
        success:    false,
        errors:     {},
        httpStatus: 201,
    },
    session: {
        token:       '',
        exp:         0,
        permissions: 0,
    },
    profile: {
        name:    '',
        surname: '',
        avatar:  null,
    },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case actions.LOGOUT_SUCCESS:
            return {
                ...state,
                login: {
                    ...state.login,
                    success:    false,
                    httpStatus: 200,
                },
            };
        case actions.GET_PROFILE:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    ...action.data,
                },
            };
        case actions.SET_HASH:
            return {
                ...state,
                session: {
                    ...state.session,
                    hash: action.data,
                },
            };
        case actions.SET_SESSION_DATA:
            return {
                ...state,
                session: {
                    ...state.session,
                    ...action.data.session,
                    access_token: '',
                },
            };
        case actions.UNSET_SESSION_DATA:
            return {
                ...state,
                session: {
                    token:       '',
                    exp:         0,
                    permissions: 0,
                },
                profile: {
                    first_name: '',
                    last_name:  '',
                    avatar:     null,
                },
            };
        default:
            return state;
    }
}
