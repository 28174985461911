import React, { Component, } from 'react';
import { withLocalize, } from 'react-localize-redux';
import { Input, } from '../../../../../components';
import {
		filterValues,
		setErrorClass,
		setErrorsFromServer,
} from '../../../../../utils/helpers';

class PayoutRate extends Component {
		constructor (props) {
				super(props);

				this.state = {
						formData: {
								payoutRanges: [],
						},
						sumFor: [],
				};
		}

		get payoutRangesDosntAscend(){
			const {countryGroups} = this.props;
			return countryGroups.some(group => { 
				const payoutRangesLastIndex = group.payoutRanges.length - 1;
				return !group.is_forbidden && +group.payoutRanges[payoutRangesLastIndex] <= +group.payoutRanges[payoutRangesLastIndex - 1];
			})
		} 

		get payoutRangesHasMaxValue(){
			const {countryGroups} = this.props;
			return countryGroups.some(group => { 
				const payoutRangesLastIndex = group.payoutRanges.length - 1;
				return +group.payoutRanges[payoutRangesLastIndex] === 10000;
			})
		} 

		get groupsDosntAscendAmount(){
			const { payoutRangesSumFrom } = this.props;
			const groups = payoutRangesSumFrom?.map(item => item);

			const groupsLastIndex = groups.length - 1;
			return +groups[groupsLastIndex] <= +groups[groupsLastIndex-1];
		}


		get groupsHasMaxValue(){
			const { payoutRangesSumFrom } = this.props;
			const groups = payoutRangesSumFrom?.map(item => item);

			const groupsLastIndex = groups.length - 1;
			return +groups[groupsLastIndex] === 1000000000;
		}

		get isDisabledBtn(){
			const { payoutRangesSumFrom, fieldsIsNotEdit, showPopUp, countryGroups} = this.props;
			const groupsDosntAscendAmount = this.groupsDosntAscendAmount;
			const payoutRangesDosntAscend = this.payoutRangesDosntAscend;
			const groupsHasMaxValue = this.groupsHasMaxValue;
			const payoutRangesHasMaxValue = this.payoutRangesHasMaxValue;
			const groups = payoutRangesSumFrom?.map(item => item);

			return groups.length > 9 || fieldsIsNotEdit || showPopUp || groupsDosntAscendAmount || payoutRangesDosntAscend || payoutRangesHasMaxValue || groupsHasMaxValue;
		}
		save = async (state) => new Promise(next => this.setState(state, next));

		async componentDidUpdate (prevProps, prevState) {
				const tState = this.props.conditionType;
				const pState = prevProps.conditionType;
				if (tState !== pState) {
						await this.save(state => {
								return {
										formData: {
												...state.formData,
												payoutRanges: [],
										}

								};
						});

				}
		}

		handleOnChange = (fieldName, iRate, iCountry) => async (event) => {
				const { value } = event.target;
				const {
						setNewSumFromValue,
						setNewRateValue,
				} = this.props;
				try {
						switch (fieldName) {

								case 'sum_from': {
										await setNewSumFromValue(value, iRate, fieldName);
										break;
								}
								case 'payout_ranges': {

										await setNewRateValue(value, iRate, iCountry, fieldName);
										break;
								}
								default: {
										break;
								}
						}
				} catch (e) {
						throw new Error(e);
				}
		};

		createNewAllowedGroup = () => {
				const { createNewPayoutRangesItem } = this.props;

				return createNewPayoutRangesItem("0", 0);
		};

		onValid = (value, max) => {

			if (value === "") {
				return;
			}
			const re = /^-?[0-9]*\.?[0-9]{0,2}?$/;
		
			if (!re.test(value)) {
				throw new Error("xxx");
			}
		
			if (value === "." || value === "-") {
				throw new Error("xxx");
			}
		
			if ((/^0[0-9]/).test(value)) {
				throw new Error("xxx");
			}
			if (value.length > 3 && +value < 0.01) {
				throw new Error("x1xx");
			}
			if (+value > max) {
				throw new Error("x1xx");
			}
		};

		renderPayoutRangeList = () => {
				const { formData, } = this.state;
				const {
						key,
						translate,
						countryGroups,
						errors,
						payoutRangesSumFrom,
						removePayoutRangeItem,
						fieldsIsNotEdit,
						showPopUp
				} = this.props;
				const isDisabledInput = payoutRangesSumFrom.length - 1;

				return (
						<div
								className="payout-rate-list__payout_table-block custom-horizontal-scroll pb-20">
								<div className="payout-rate-list__payout_table-forbidden mr-35">
										<div className="payout-rate-list__payout_table-title-bg">
												<p className="payout-rate-list__payout_table-title">
														{ translate("partners_payout_rate-list_sum-from") }
												</p>
										</div>
										{ payoutRangesSumFrom?.map((sum, index) => {

												return (
														<div key={ index }
																 className="payout-rate-list__payout_table-range-items">
																{ index === 0 || fieldsIsNotEdit || showPopUp
																		? null
																		: <button
																				className="payout-rate-list__create-btn_btns-btn payout-rate-list__create-btn_btns-btn-remove mt-20 rm-range-item"
																				onClick={ () => removePayoutRangeItem(index) }/>
																}

																<Input
																		key={ `sum_from_${ index }` }
																		name={ formData?.sum }
																		wrapperClassName={ `payout-rate-list__payout_table-input ${ setErrorClass(errors, `sum_from_${ index }`) }` }
																		placeholder={ translate("partners_payout_rate-list-enter-amount") }
																		value={ `${sum}` }
																		disabled={ index !== isDisabledInput || fieldsIsNotEdit || showPopUp }
																		onChange={ this.handleOnChange('sum_from', index) }
																		onValid={ (value) => this.onValid(value, 1000000000) }
																		isRequired
																/>
														</div>
												);
										}) }
								</div>
								{ countryGroups?.map((item, index) => this.renderPayoutRangeItem(item, index)) }
						</div>
				);
		};

		renderPayoutRangeItem = (country, iCountry) => {
				const { formData, } = this.state;
				const {
						translate,
						errors,
						countryGroups,
						fieldsIsNotEdit,
						showPopUp
				} = this.props;
				const isDisabledInput = country.payoutRanges.length - 1;
				const isDisabled = countryGroups.length - 1;

				const groupNumber = country.is_forbidden ?
						`${ translate("partners_payout_rate-list_forbidden-label") }*` :
						`${ translate("partners_payout_rate-list_name-label") } ${ iCountry }*`;

				return (
						<div key={ iCountry } className="mr-35">
								<div className="payout-rate-list__payout_table-titles-block">
										<div className="payout-rate-list__payout_table-title-bg">
												<p className="payout-rate-list__payout_table-title">
														{ groupNumber }
												</p>
										</div>
								</div>
								{ country?.payoutRanges?.map((rate, iRate) => {
										return (
												<Input
														key={ `payout_ranges_${iCountry}_${ iRate }` }
														name={ `${formData?.rate}` }
														wrapperClassName={ `payout-rate-list__payout_table-input ${ setErrorClass(errors, `payout_ranges_${ iCountry }_${ iRate }`) }` }
														disabled={ country?.is_forbidden || iRate !== isDisabledInput || fieldsIsNotEdit || showPopUp }
														placeholder={ translate("partners_payout_rate-list-rate-placeholder") }
														value={ `${rate}` }
														onChange={ this.handleOnChange('payout_ranges', iRate, iCountry) }
														onValid={ (value) => this.onValid(value, 10000) }
														isRequired
												/>
										);
								}) }
						</div>
				);
		};

		render () {
				const {translate,} = this.props;
				const isDisabledBtn = this.isDisabledBtn

				return ( 
						<>
								<div className="payout-rate-list__payout_table-forbidden-title">
										<p className={ 'payout-rate-list__payout_block-title' }>
												{ translate('partners_payout_rate-list_payout_rate_block') }
										</p>
										<button
												disabled={ isDisabledBtn }
												onClick={ () => this.createNewAllowedGroup() }
												className={ `payout-rate-list__create-btn_btns-btn mr-35 ${ isDisabledBtn ? 'payout-rate-list__create-btn_btns-btn-join-disabled' : 'payout-rate-list__create-btn_btns-btn-join-group' }` }
										>
												{ translate(`partners_payout_rate-list_add-range`) }
										</button>
								</div>
								<div
										className="payout-rate-list__payout_table custom-vertical-scroll">
										{ this.renderPayoutRangeList() }
								</div>
						</>
				);
		}
}

export default withLocalize(PayoutRate);
