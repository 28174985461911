import React, { Component, } from 'react';
import { withLocalize, } from "react-localize-redux";
import moment from "moment/moment";
import {
	getManagerName,
	getManagerNamesData, getUserActivityData,
} from "../../../dashboard/services/DashboardRequestService";
import UserRequestTable from "./table/UserRequestTable";
import UserActivityChats from "./userActivityChats";

class UserRequestWidget extends Component {
	constructor (props) {
		super(props);

		this.state = {
			data: [],
			dateFrom: moment().add(-1, 'days').startOf('month').format('YYYY-MM-DD'),
			dateTo: moment().add(-1, 'days').format('YYYY-MM-DD'),
			filteredUsers: [], //?
			loaded: false,
			name: null,
			selectedUser: '',
			userName: '',
			users: [],
		};
	}

	async componentDidMount () {

		const users = await getManagerNamesData();

		await this.save({ users: users?.data, });
	}
    getUserActivityData = async () => {
    	const { dateFrom, dateTo, selectedUser, } = this.state;

    	try {
    		await this.save({ loaded: false, data: [], });

    		const response = await getUserActivityData(dateFrom, dateTo, selectedUser);
    		const userName = await getManagerName(this.state.selectedUser);

    		await this.save({ data: response.data, loaded: true, userName: userName.data.core.user, });
    	} catch (err) {
    		err?.showErrorNotification?.();
    		await this.save({ loaded: true, });
    	}
    }
	renderUserActivityCharts = () => {
		const {
			data, dateTo, dateFrom, selectedUser, users,
		} = this.state;

		return (
			<UserActivityChats
				data={ data }
				dateFrom={ dateFrom }
				dateTo={ dateTo }
				selectedUser={ selectedUser }
				setDateFromFilter={ async (dateFrom, dateTo) => {
					await this.save({
						...dateFrom && { dateFrom, },
						...dateTo && { dateTo, },
					});
					await this.getUserActivityData();
				} }
				setUser={ async (user) => {
					await this.save({ selectedUser: user, });
					await this.getUserActivityData();
				} }
				users={ users }

			/>
		);
	}
	renderUserRequestTable = () => {
		const { data, selectedUser, userName, } = this.state;

		return (
			<UserRequestTable
				data={ data }
				selectedUser={ selectedUser }
				userName={ userName }
			/>
		);
	}

    save = async state => new Promise(next => this.setState(state, next));
    render () {
    	return (
	<div>
	{this.renderUserActivityCharts()}
	{this.renderUserRequestTable()}
    		</div>
    	);
    }
}

export default withLocalize(UserRequestWidget);