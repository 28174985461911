import React, { Component, } from 'react';
import { withLocalize, } from "react-localize-redux";

class UserRequestTable extends Component {
	render () {

		const { data, translate, userName, } = this.props;
		const { name, surname, } = userName;
		const fullName = `${ name } ${ surname }`;

		return (
			<>
				<div className=" activity_report_block  ">
					<ul className="user_activity_label__block">
						<li>{translate('activity_report_user_date')}</li>
						<li>{translate('activity_report_user_manager_name')}</li>
						<li>{translate('activity_report_user_start_time')}</li>
						<li>{translate('activity_report_user_end_time')}</li>
						<li>{translate('activity_report_user_count')}</li>
					</ul>
				</div>
				{data.map(({
					c: count, d: date, f: firstTime, l: lastTime,
				}, index) => {

					return (
						<ul className="user_activity_label__table" key={ index }>
							<li>{date}</li>
							<li>{fullName}</li>
							<li>{firstTime ?? '-' }</li>
							<li>{lastTime ?? '-'}</li>
							<li>{count}</li>
						</ul>
					);
				})}
			</>

		);
	}
}

export default withLocalize(UserRequestTable);