import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withLocalize } from "react-localize-redux";
import Preloader from "../../../../components/LoadingHOC/Preloader";
import PagePanel from "../../../tima/components/Pages/PagePanel";
import Loader from "../../../tima/components/Loader";
import {
  MagicFilterPanels,
  MagicTable,
  Mentor,
} from "../../../../components/Magic";
import { listAccountService } from "../../../../services/ListAccountDataService";

class ClosedDealsMT5 extends Component {
  constructor (props) {
    super(props);

    this.state = {
      closedDeals: {
          data: [],
          hash: null,
      },
      filterLastChange: Date.now(),
      loaded: false,
      pages: {
        filter: 0,
        take: 50,
        total: 0,
        variance: 2,
      },
    };

    const mf = new Mentor({
      owner: this,
      serviceId: 92,
      translate: this.props.translate,
    });

    Object.defineProperty(this, 'mf', { get: () => mf, });
    this.props.mf(mf);
  }

  // eslint-disable-next-line react/sort-comp, require-await
  save = async state => new Promise(next => this.setState(state, next));

  // eslint-disable-next-line require-await
  mfChanged = async () => {
    await this.save({ filterLastChange: Date.now(), });
  };

  mfDoing = async () => {
    await this.save({ loaded: false, });
  };

  mfDone = async () => {
    await this.mfChanged();
    await this.onDataLoad();
  };

  query = () => new URLSearchParams(this.props?.location?.search);

  closedDealsChanges = async (closedDeals) => {
      if (closedDeals.hash===this.state?.closedDeals?.hash) return `${this.constructor.name}.closedDealsChanges: false`;
      const { data, meta, hash } = closedDeals;
      await this.save({
          closedDeals: { data, hash },
          pages: {
              ...this.state.pages,
              filter: meta?.filter,
              total: meta?.total,
          }
      });
      return `${this.constructor.name}.closedDealsChanges: true`;
  };

  componentDidMount = async () => {
    this.mf.subscribe({
      changed: this.mfChanged,
      // changing: ,
      cleaned: this.mfDone,
      cleaning: this.mfDoing,
      loaded: this.mfDone,
      loading: this.mfDoing,
      saved: this.mfDone,
      saving: this.mfDoing,
    }, this);
    await this.mf.init({ doEmit: true, });
    listAccountService.subscribe('tradingAccountsClosedDealsMT5', this.closedDealsChanges, this);
    // await this.mf.emit('change', {});
    // await this.onDataLoad(); // TODO: moved to mfDone
  };

  componentWillUnmount = () => {
    this.mf.unsubscribe([
      this.mfChanged,
      this.mfDoing,
      this.mfDone,
    ], this);
    listAccountService.unsubscribe('tradingAccountsClosedDealsMT5', this.closedDealsChanges, this);
  };

  get closedDealsMT5Config () {
    const { translate, } = this.props;
    const prefix = 'list_accounts_table_dealsMT5_';

    return [ {
      accessChecked: true,
      key: 'Time',
      path: [ 'deals', 'Time', ],
      render: this.renderInlineValue,
      title: `list_accounts_table_dealsMT5_Time`,
    }, {
      accessChecked: true,
      key: 'Deal',
      path: [ 'deals', 'Deal', ],
      render: this.renderInlineValue,
      title: `list_accounts_table_dealsMT5_Deal`,
    }, {
      accessChecked: true,
      key: 'Order',
      path: [ 'deals', 'Order', ],
      render: this.renderInlineValue,
      title: `list_accounts_table_dealsMT5_Order`,
    }, {
      accessChecked: true,
      key: 'Symbol',
      path: [ 'deals', 'Symbol', ],
      render: this.renderInlineValue,
      title: `list_accounts_table_dealsMT5_Symbol`,
    }, {
      accessChecked: true,
      key: 'Action',
      path: [ 'deals', 'Action', ],
      render: this.renderEnumField('core', 'Mt5DealsActionEnum'),
      title: `list_accounts_table_dealsMT5_Action`,
    }, {
      accessChecked: true,
      key: 'Entry',
      path: [ 'deals', 'Entry', ],
      render: this.renderEnumField('core', 'Mt5DealsEntryEnum'),
      title: `list_accounts_table_dealsMT5_Entry`,
    }, {
      accessChecked: true,
      key: 'Volume',
      path: [ 'deals', 'Volume', ],
      render: this.renderInlineValue,
      title: `list_accounts_table_dealsMT5_Volume`,
    }, {
      accessChecked: true,
      key: 'Price',
      path: [ 'deals', 'Price', ],
      render: this.renderNumberFixedFormat({ fieldName: 'Price' }),
      title: `list_accounts_table_dealsMT5_Price`,
    }, {
      accessChecked: true,
      key: 'PriceSL',
      path: [ 'deals', 'PriceSL', ],
      render: this.renderNumberFixedFormat({ fieldName: 'PriceSL' }),
      title: `list_accounts_table_dealsMT5_PriceSL`,
    }, {
      accessChecked: true,
      key: 'PriceTP',
      path: [ 'deals', 'PriceTP', ],
      render: this.renderNumberFixedFormat({ fieldName: 'PriceTP' }),
      title: `list_accounts_table_dealsMT5_PriceTP`,
    }, {
      accessChecked: true,
      key: 'Commission',
      path: [ 'deals', 'Commission', ],
      render: this.renderNumberFixedFormat({ fieldName: 'Commission' }),
      title: `list_accounts_table_dealsMT5_Commission`,
    }, {
      accessChecked: true,
      key: 'Storage',
      path: [ 'deals', 'Storage', ],
      render: this.renderNumberFixedFormat({ fieldName: 'Storage' }),
      title: `list_accounts_table_dealsMT5_Storage`,
    }, {
      accessChecked: true,
      key: 'Profit',
      path: [ 'deals', 'Profit', ],
      render: this.renderNumberFixedFormat({ fieldName: 'Profit' }),
      title: `list_accounts_table_dealsMT5_Profit`,
    }, {
      accessChecked: true,
      key: 'Digits',
      path: [ 'deals', 'Digits', ],
    }, {
      accessChecked: true,
      key: 'Comment',
      path: [ 'deals', 'Comment', ],
      render: this.renderInlineValue,
      title: `list_accounts_table_dealsMT5_Comment`,
    }, ].map(({
      title: t,
      xtitle: x,
      ...item
    }) => ({
      ...item,
      ...x && { title: x },
      ...t && { title: translate(t) },
    }));
  }

  renderInlineValue = (value, { item, items }) => {
    const defaultValue = '-';

    if (item?.access?.('index')) {
        return value || defaultValue;
    }
    return defaultValue;
  };

  renderNumberFixedFormat = ({ fieldName }) => (value, { item, items }) => {
    const defaultDigits = 2;
    const defaultValue = '-';
    let digits;

    switch (fieldName) {
      case 'Price':
      case 'PriceSL':
      case 'PriceTP': {
        digits = items?.['Digits']?.valueOf ?? defaultDigits;
        break;
      }
      case 'Commission':
      case 'Storage':
      case 'Profit': {
        digits = 2; // HARD CODE for this fields in MT4/MT5
        break;
      }
      default: {
        digits = defaultDigits;
        break;
      }
    }

    if (item?.access?.('index')) {
        return (+value).toFixed(digits);
    }
    return defaultValue;
  };

  renderEnumField = (path, enumName) => (value, { item }) => {
    const defaultValue = '-';

    if (item?.access?.('index')) {
      const enums = this.props?.enums?.[path]?.[enumName];

      return enums?.[value] ?? defaultValue;
    }
    return defaultValue;
  };

  render = () => {
    const { closedDeals: { data }, } = this.state;
    const options = {
      config: this.closedDealsMT5Config,
      data,
      head:   [
        'Time',
        'Deal',
        'Order',
        'Symbol',
        'Action',
        'Entry',
        'Volume',
        'Price',
        'PriceSL',
        'PriceTP',
        'Commission',
        'Storage',
        'Profit',
        'Comment',
      ],
    };

    return (
      <>
        <MagicFilterPanels
          mf={ this.mf }
          show={ true }
          translate={ this.props.translate }
        />

        <div className='orders-page closed-orders-page'>
          <Loader
            loaded={this.state.loaded}
            loading={(<Preloader scale={this.props.scale}/>)}
            translate={this.props.translate}
          >
            <MagicTable {...options} />

            <PagePanel
              filter={this.state.pages.filter}
              take={this.state.pages.take}
              total={this.state.pages.total}
              variance={this.state.pages.variance}
              page={this.pageId()}
              onClick={this.onPageClick}
              doText={this.onPageText}
              doTo={(pageId) => `?page=${ pageId }`}
            />
          </Loader>
        </div>
      </>
    );
  };

  pageId = () => {
    const defaultPageId = 0;

    try {
      const page = this.query().get('page');

      return Number(page) || defaultPageId;
    } catch (e) {
      return defaultPageId;
    }
  };

  pageIdAsGET = (pageId) => {
    pageId = pageId === undefined ? this.pageId() : pageId;
    const { filterId, } = this.mf;
    const result = {
      filterId,
      take: this.state.pages.take,
      skip: pageId * this.state.pages.take,
    };
    return result;
  };

  onDataLoad = async (pageId) => {
    const loaded = (data, meta, hash) => ({ pages, }) => ({
      closedDeals: { data, hash },
      pages: { ...pages, ...meta, },
    });
    await this.save({ loaded: false, });
    try {
      const pageOptions = this.pageIdAsGET(pageId);
      const { id, } = this.props;
      const { data, meta, hash } = await listAccountService.tradingAccountsClosedDealsMT5(id, pageOptions);
      const { filter, total } = meta;
      await this.save(loaded(data, { filter, total, }, hash));  // no filter data on this page => filter: total - это была временная заплатка
    } catch (error) {
      await this.save(loaded([], { filter: 0, total: 0, }, null));
      error?.showErrorNotification?.();
    }
    await this.save({ loaded: true, });
  };

  onPageClick = ({ event, pageId, pageIs, }) => {
    // event.preventDefault();
    this.onDataLoad(pageId);
  };

  onPageText = (pageId, pageIs) => {
    const { translate } = this.props;
    const { current, first, prev, next, last, } = pageIs;
    const { skipped, taken, filter, total, } = pageIs;
    if (skipped || taken) {
      const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : '?';
      const text = skipped ? translate('partners_pages_items') : '';
      return skipped ? `${ text }: ${ id }` : id;
    } else if (filter || total) {
      const id = Number.isInteger(pageId) ? `${ pageId }` : '?';
      const text = translate(filter ? 'partners_pages_filtered' : 'partners_pages_total');
      return `${ text }: ${ id }`;
    } else if (first || prev || next || last) {
      return '';
    } else if (current) {
      return `${ pageId + 1}`;
    } else {
      return `${ pageId + 1}`;
    }
  };
}

export default withRouter(withLocalize(ClosedDealsMT5));
