import moment from 'moment';
import sha1 from 'js-sha1';
import { get, post, put, MagicError, } from '../components/Magic/helpers/MagicRequest';

const expired = (expire) => {
    if ([false, true].includes(expire)) {
        return expire;
    }
    return moment().diff(expire, 'second', true)>0;
    
};

class CachingService {
    constructor({
        garbageCollectorInterval=1000 * 60 * 60 * 24,
        maxAge=60 * 60 * 24,
        prefix='cache',
        renew=false,
    } = {}) {
        this.mutex = new Map();
        const urlToKey = (url, options) => {
            const {
                locale = localStorage.getItem('locale'),
                method = 'GET',
                prefix = this.prefix,
                userId = localStorage.getItem('userId') ?? 'userId',
                params = false,
            } = options;
            const payload = options?.payload instanceof Object ? encodeURIComponent(JSON.stringify(options?.payload)) : false;
            const U = new URL(url, location);
            const key = U.pathname + (params ? U.search : '') + (payload ? `:${ payload }` : '');
            if (prefix && key && locale && userId) {
                return `${ prefix }:${ locale }:${ userId }:${ method }:${ key }`;
            }
            throw new Error(`urlToKey('${ key }', '${ locale }', ${ userId }) in CachingService`);
        };
        const getStoredItem = (url, options) => {
            const key = urlToKey(url, options);
            const json = localStorage.getItem(key);
            try {
                return JSON.parse(json);
            } catch (error) {
                return undefined;
            }
        };
        const setStoredItem = (url, value, options) => {
            let {
                locale, method, prefix, userId, params, payload,
                maxAge = this.maxAge,
                renew = this.renew,
                expire,
                ...cond
            } = options;
            const key = urlToKey(url, {
                ...locale!==undefined && { locale },
                ...method!==undefined && { method },
                ...prefix!==undefined && { prefix },
                ...userId!==undefined && { userId },
                ...params!==undefined && { params },
                ...payload!==undefined && { payload },
            });
            expire = expire ?? moment().add(maxAge, 'second').format();
            localStorage.setItem(
                key,
                JSON.stringify({
                    data: value,
                    ...expire!==undefined && { expire, },
                    ...renew!==undefined && { renew, },
                    ...cond
                })
            );
        };
        const removeStoredItem = (url, options) => {
            const key = urlToKey(url, options);
            localStorage.removeItem(key);
        };
        const storedItem = (url, options, value=undefined) => {
            if (value) {
                return setStoredItem(url, value, options);
            }
            const { data, expire=false, renew=this.renew, } = getStoredItem(url, options);
            if (expired(expire) || !data) {
                removeStoredItem(url, options);
                throw new Error(`CachingService.item('${ url }') not found`);
            }            
            expire!==false && renew && setStoredItem(url, data, options);
            return data;
        };
        Object.defineProperties(this, {
            expire: { get: () => moment().add(maxAge, 'second').format(), },
            expired: { value: (expire) => moment().diff(expire, 'second', true)<0, },
            maxAge: { get: () => maxAge, },
            prefix: { get: () => prefix, },
            renew: { get: () => renew, },
            getStoredItem: { get: () => getStoredItem, },
            setStoredItem: { get: () => setStoredItem, },
            removeStoredItem: { get: () => removeStoredItem, },
            storedItem: { get: () => storedItem, },
        });

        const garbageCollector = async() => {
            await this.garbageCollector();
            setTimeout(garbageCollector, garbageCollectorInterval);
        };
        garbageCollector();
    }

    async item({ url, ...options }) {
        const sleep = async (delay=1) => new Promise(next => setTimeout(next, delay));
        const mutexKey = JSON.stringify({ url, ...options });
        while (this.mutex.has(mutexKey)) {
            await sleep(10);
        }
        this.mutex.set(mutexKey, true);
        let result;
        try {
            result = this.storedItem(url, options);
        } catch (error) {
            try {
                const { method='GET', payload } = options;
                switch (method) {
                    case 'GET': {
                        result = await get(url);
                        break;
                    }
                    case 'POST': {
                        result = await post(url, payload);
                        break;
                    }
                    default: {
                        this.mutex.delete(mutexKey);
                        throw new Error('Method unknown for Caching.item');
                    }
                }

                // result with error are not stored
                if (result?.error) {
                    new MagicError({ response: { data: result?.error, }})
                        .showErrorNotification();
                } else {
                    cachingService.storedItem(url, options, result);
                }
            } catch (error) {
                console.error(error);
            }
        }
        this.mutex.delete(mutexKey);
        return result;
    };

    async dictionary(url, { any=true, ...moreOptions }={}) {
        const duration = moment.duration(1, 'day');
        const options = {
            ...any && { userId: 'any', },
            maxAge: duration.asSeconds(),
            duration: duration.humanize(),
            renew: false,
            isDictionary: true,
            ...moreOptions,
        };
        return this.item({ ...options, url, });
    }

    async list(url, { ...moreOptions }={}) {
        const duration = moment.duration(10, 'seconds');
        const options = {
            maxAge: duration.asSeconds(),
            duration: duration.humanize(),
            renew: false,
            isList: true,
            ...moreOptions,
        };
        return this.item({ ...options, url, });
    }

    async one(url, { ...moreOptions }={}) {
        const duration = moment.duration(15, 'seconds');
        const options = {
            maxAge: duration.asSeconds(),
            duration: duration.humanize(),
            renew: false,
            isOne: true,
            ...moreOptions,
        };
        return this.item({ ...options, url, });
    }

    async session(url, { ...moreOptions }={}) {
        const duration = moment.duration(1, 'hour');
        const options = {
            maxAge: duration.asSeconds(),
            duration: duration.humanize(),
            renew: true,
            isSession: true,
            ...moreOptions,
        };
        return this.item({ ...options, url, });
    }

    async clean({ match, all=false, }) {
        const pattern = new RegExp(`^${ this.prefix }:[\\w]{2}:(?:any|userId|[\\d]+):.+$`);
        Object.keys(localStorage)
            .filter(key => pattern.test(key))
            .forEach(key => {
                try {
                    if (all===true) {
                        throw new Error(`CachingService.clean clean all key: '${ key }'`);
                    }
                    const json = localStorage.getItem(key);
                    const { data, expire, ...jsonData } = JSON.parse(json);
                    if (match({ ...jsonData, data, expire, key })) {
                        throw new Error(`CachingService.clean matched key: '${ key }'`);
                    }
                } catch (error) {
                    console.log(`CachingService.clean grabed key '${ key }'`);
                    localStorage.removeItem(key);
                }
            });
    }

    async cleanDictionary() {
        await this.clean({ match: ({ isDictionary, }) => isDictionary, });
    }

    async cleanSession() {
        await this.clean({ match: ({ isSession, }) => isSession, });
    }

    async garbageCollector() {
        return await this.clean({ match: ({ data, expire}) => expired(expire) || !data, });
    }
}

const cachingService = new CachingService({
    garbageCollectorInterval: 1000*8,
    maxAge: moment.duration(1, 'day').asSeconds(),
    prefix: 'crm',
});

const hash = async (data) => sha1(JSON.stringify(data));

export {
    cachingService,
    hash,
    CachingService,
};
