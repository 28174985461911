import React, { Component } from 'react';
import { withLocalize } from "react-localize-redux";
import { withRouter } from 'react-router-dom';
import { LIST_ACCOUNTS_EXPORT_FIELDS } from "../../consts/constants";
import OneSystemAccountGeneralInfo from "../ListPages/OneSystemAccountGeneralInfo";
import Preloader from "../../../../components/LoadingHOC/Preloader";
import Loader from "../../../tima/components/Loader";
import { post } from '../../../../components/Magic/helpers/MagicRequest';
import { showExportToExcelError } from "../../../../components/Magic/helpers/MagicHelpers";
import PermissionService from "../../../../services/PermissionService";
import { enumService } from "../../../../services/EnumDataService";
import { listAccountService } from "../../../../services/ListAccountDataService";

class SystemAccount extends Component {

    constructor (props) {
        super(props);

        const { id } = this.params();

        this.state = {
            id,
            loaded: false,
            systemAccount: {
                data: [],
                hash: null,
            }
        };
    }

    save = async (state) => new Promise(next => this.setState(state, next));

    enumsChange = async (enums) => {
        if (enums.hash===this.state?.enums?.hash) return `${this.constructor.name}.enumsChange: false`;
        await this.save({ enums, });
        return `${this.constructor.name}.enumsChange: true`;
    };

    systemAccountChange = async (systemAccount) => {
        if (systemAccount.hash===this.state?.systemAccount?.hash) return `${this.constructor.name}.systemAccountChange: false`;
        await this.save({ systemAccount, });
        return `${this.constructor.name}.systemAccountChange: true`;
    };

    async componentDidMount() {
        enumService.subscribe('enums', this.enumsChange, this);
        listAccountService.subscribe('systemAccountPage', this.systemAccountChange, this);
        await enumService.enums;
        await this.onDataLoad();
    }

    componentWillUnmount() {
        enumService.unsubscribe('enums', this.enumsChange, this);
        listAccountService.unsubscribe('systemAccountPage', this.systemAccountChange, this);
    }

    get systemAccountConfig () {
        return [
            {
                path: ['core', 'system_accounts'],
                key:  'systemAccounts',
            },
        ];
    }

    params = () => this.props.match.params;

    query = () => new URLSearchParams(this.props.location.search);

    exportToExcel = (activeTab) => async (event) => {
        if (activeTab) {
            const { id } = this.params();

            let url = '';
            let file_name = '';

            if (activeTab === 'systemAccountHistory' && id) {
                url = `/api/core/system-accounts/get-balance-transaction-history/${id}/export-to-excel`;
                file_name = `History_payments_by_system_account_${id}`;
            }

            const options = {
                file_name,
                fields: LIST_ACCOUNTS_EXPORT_FIELDS[activeTab],
            };

            try {
                await post(url, options);
            } catch (error) {
                showExportToExcelError(error);
            }
        }
    };

    // TODO: onDataLoad renew style
    onDataLoad = () => {
        const { id } = this.state;
        const setLoaded = (loaded) => this.setState(() => ({ loaded, }));
        const setData = (data) => this.setState(() => (data));
        const load0 = async () => await listAccountService.systemAccountPage(id);

        setLoaded(false);
        (async () => {
            try {
                const systemAccount = await load0();

                setData({
                    systemAccount
                });
                setLoaded(true);
            } catch (error) {
                error?.showErrorNotification?.();
            }
        })();
    };

    renderExportToExcelBtn = (activeTab) => {
        const { translate } = this.props;
        const conf = PermissionService.calc(this.systemAccountConfig);
        const exportToExcelPermission = conf?.systemAccounts?.access('exportToExcel') ?? false;

        return exportToExcelPermission ?
            (
                <div className="button button--turquoise"
                     onClick={this.exportToExcel(activeTab)}
                >
                    {translate('export_excel_btn')}
                </div>
            ) : null;
    };

    render () {
        const { id, systemAccount: { data }, loaded, enums } = this.state;
        const { translate } = this.props;

        const params = this.params();
        const query = this.query();

        return (
            <div className='system_accounts'>
                <div className='content-block'>
                    <div className='top-page-block'>
                        <h1 className='page-title'>{translate(`list_accounts_system_accounts_number`)} {id}</h1>
                        {this.renderExportToExcelBtn('systemAccountHistory')}
                    </div>
                </div>

                <Loader
                    loaded={loaded}
                    loading={(<Preloader scale={1}/>)}
                    translate={translate}
                >
                    <OneSystemAccountGeneralInfo
                        account_id={id}
                        data={data}
                        enums={enums?.data}
                        translate={translate}
                        params={params}
                        query={query}
                        refreshSystemAccountData={this.onDataLoad} // bind refresh data func to the child
                    />
                </Loader>

            </div>
        );
    }
}

export default withLocalize(withRouter(SystemAccount));
