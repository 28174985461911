import React from 'react';
import _, {forEach} from "lodash";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {Translate, withLocalize} from 'react-localize-redux';
import moment from 'moment';

import {Table} from 'reactstrap';
import CheckUndefinedPermission from "../../../../../components/Common/CheckUndefinedPermission";
import {
    getAccountBrokers,
    getAccountLabels,
    getAccountLifestyles,
    getAccountMessengers,
    getAccountNationalities,
    getAccountReligions,
    getAccountSocialNetworks,
    getAccountStatus,
    getAccountTimeZones,
    getAccountTypes,
    updateAccount,
} from "../../../services/AccountService";
import PermissionService, {MODULE_KEYS as pm} from "../../../../../services/PermissionService";
import Select from "react-select";
import NotificationService from "../../../../../services/NotificationService";


class ContactInformation extends React.Component {

	static Options = ( invoker ) => ( id, name ) => ( data ) => {
		id = id || 'id';
		name = name || 'name';
		if ( !data || !( id in data ) || !( name in data ) ) {
			console.log( !data , !( id in data ) , !( name in data ) );
			throw new Error( `${ invoker } structure changed, expacted { ${ id }, ${ name } }, got ${ JSON.stringify( data ) }` );
		}
		const { [ id ]: value, [ name ]: label, } = data || {};
		return { value, label, };
	};
	static Switchs = { 1: true, 2: false };
	static fromSwitch = ( key, defaultValue ) => Object.entries( ContactInformation.Switchs )
		.filter( ( [ k, v ] ) => k===key )
		.map( ( [ k, v ] ) => v )
		.shift() || defaultValue;
	static toSwitch = ( value, defaultKey ) => Object.entries( ContactInformation.Switchs )
		.filter( ( [ k, v ] ) => v===value )
		.map( ( [ k, v ] ) => k )
		.shift() || defaultKey;


	setValue = ( stateKey, dataKey ) => ( data ) => {
		this.setState({
			[ stateKey ]: data,
		}, async ( ) => {
			try {
				await updateAccount( { [ dataKey ]: data, }, this.accountId );
			} catch (error) {
				NotificationService.error({ title: 'error', message: error.message, remove: false })
			}
		} );
	};
	setSwitchValue = ( stateKey, dataKey, checked ) => {
		const data = checked===true ? '1' : checked===false ? '2' : '2';
		this.setState({
			[ stateKey ]: data,
		}, async ( ) => {
			try {
				await updateAccount( { [ dataKey ]: data, }, this.accountId );
			} catch (error) {
				NotificationService.error({ title: 'error', message: error.message, remove: false })
			}
		} );
	};
	setSelectValue = ( stateKey, dataKey, selected, saveAsMulti = true ) => {
		this.setState({
			[ stateKey ]: selected,
		}, async ( ) => {
			try {
				let data = [ selected ].map( item => item.value );
				data = saveAsMulti ? data : data.shift();
				if ( data===0 ) {
					dataKey  = `SET_NULL__${ dataKey }`;
					data = 1;
				}
				await updateAccount( { [ dataKey ]: data, }, this.accountId );
			} catch (error) {
				NotificationService.error({ title: 'error', message: error.message, remove: false })
			}
			} );
	};
	setMultiSelectValue = ( stateKey, dataKey, selected ) => {
		this.setState({
			[ stateKey ]: selected,
		}, async ( ) => {
			const data = selected.map( item => item.value );
			try {
				await updateAccount( { [ dataKey ]: data, }, this.accountId )
			} catch (error) {
				NotificationService.error({ title: 'error', message: error.message, remove: false });
			}
		} );
	};

	constructor (props) {
		super(props);
		const {
			dictionary_account_brokers: brokers,
			dictionary_account_labels: labels,
			dictionary_account_lifestyle: lifestyles,
			messenger: messengers,
			nationality: nationalities,
			dictionary_account_religion: religions,
			social_networks,
			dictionary_account_status: status,
			dictionary_account_types: types,
			time_zone: time_zone
		} = this.props.user || {};

		this.state = {
			user: {},
			enums: this.props.enums,
			licensesEnums: this.props.licensesEnums,
			userId: this.props.match.params.id,
			lang : undefined,

			// brokers
			accountBrokersOptions: [],
			accountBrokersSelected: []
				.concat( brokers || [] )
				.map( ContactInformation.Options( 'dictionary-account-brokers' )( 'id' ) ),

			// labels
			accountLabelsOptions: [],
			accountLabelsSelected: []
				.concat( labels || [] )
				.map( ContactInformation.Options( 'dictionary-account-labels' )( 'id' ) ),

			// lifestyles
			accountLifestylesOptions: [],
			accountLifestylesSelected: []
				.concat( lifestyles || [] )
				.map( ContactInformation.Options( 'dictionary-account-lifestyles' )( 'id' ) ),

			// messengers
			accountMessengersOptions: [],
			accountMessengersSelected: []
				.concat( messengers || [] )
				.map( ContactInformation.Options( 'messengers' )( 'id' ) ),

			// nationalities
			accountNationalitiesOptions: [],
			accountNationalitiesSelected: []
				.concat( nationalities || [] )
				.map( ContactInformation.Options( 'account-nationalities' )( 'id' ) ),

			// time zones
			accountTimeZonesOptions: [],
			accountTimeZonesSelected: []
				.concat(time_zone || [])
				.map( ContactInformation.Options( 'time_zone' )( 'id' )),

			// religions
			accountReligionsOptions: [],
			accountReligionsSelected: []
				.concat( religions || [] )
				.map( ContactInformation.Options( 'dictionary-account-religions' )( 'id' ) ),

			// social_networks
			accountSocialNetworksOptions: [],
			accountSocialNetworksSelected: []
				.concat( social_networks || [] )
				.map( ContactInformation.Options( 'social_networks' )( 'id' ) ),

			// status: result of last contact
			accountStatusOptions: [],
			accountStatusSelected: []
				.concat( status || [] )
				.map( ContactInformation.Options( 'dictionary-account-statuses' )( 'id' ) ),

			// types
			accountTypesOptions: [],
			accountTypesSelected: []
				.concat( types || [] )
				.map( ContactInformation.Options( 'dictionary-account-types' )( 'id' ) ),

			// nationality: this.props.user.
			is_loyal: this.props.user.account.is_loyal,
			is_vip: this.props.user.account.is_vip,
		};
	}

	get accountId() { return this.props.user.account.id; }

    get accountRegisterType () {
        return this.props?.user?.account?.register_type;
    }

    get accountAddresses () {
        return this.props?.user?.account_addresses;
    }
	get isLoyal() { return ContactInformation.fromSwitch( String(this.state.is_loyal), false ); }
	get isVIP() { return ContactInformation.fromSwitch( String(this.state.is_vip), false ); }

	get accountBrokersSelected() { return this.state.accountBrokersSelected; }
	get accountLabelsSelected() { return this.state.accountLabelsSelected; }
	get accountLifestylesSelected() { return this.state.accountLifestylesSelected; }
	get accountMessengersSelected() { return this.state.accountMessengersSelected; }
	get accountNationalitiesSelected() { return this.state.accountNationalitiesSelected; }
	get accountReligionsSelected() { return this.state.accountReligionsSelected; }
	get accountTimeZonesSelected() { return this.state.accountTimeZonesSelected; }
	get accountSocialNetworksSelected() { return this.state.accountSocialNetworksSelected; }
	get accountStatusSelected() { return this.state.accountStatusSelected; }
	get accountTypesSelected() { return this.state.accountTypesSelected; }

	get accountBrokersOptions() { return this.state.accountBrokersOptions; }
	get accountLabelsOptions() { return this.state.accountLabelsOptions; }
	get accountLifestylesOptions() { return this.state.accountLifestylesOptions; }
	get accountMessengersOptions() { return this.state.accountMessengersOptions; }
	get accountNationalitiesOptions() { return this.state.accountNationalitiesOptions; }
	get accountReligionsOptions() { return this.state.accountReligionsOptions; }
	get accountTimeZonesOptions() { return this.state.accountTimeZonesOptions; }
	get accountSocialNetworksOptions() { return this.state.accountSocialNetworksOptions; }
	get accountStatusOptions() { return this.state.accountStatusOptions; }
	get accountTypesOptions() { return this.state.accountTypesOptions; }


	set isLoyal( checked ) {
		this.setSwitchValue( 'is_loyal', 'is_loyal', checked );
	}
	set isVIP( checked ) {
		this.setSwitchValue( 'is_vip', 'is_vip', checked );
	}
	set accountBrokersSelected( selected ) {
		this.setMultiSelectValue( 'accountBrokersSelected', 'brokers', selected );
	}
	set accountLabelsSelected( selected ) {
		this.setMultiSelectValue( 'accountLabelsSelected', 'labels', selected );
	}
	set accountLifestylesSelected( selected ) {
		this.setSelectValue( 'accountLifestylesSelected', 'lifestyles', selected );
	}
	set accountMessengersSelected( selected ) {
		this.setMultiSelectValue( 'accountMessengersSelected', 'messengers', selected );
	}
	set accountNationalitiesSelected( selected ) {
		this.setSelectValue( 'accountNationalitiesSelected', 'nationality_id', selected, false );
	}
	set accountReligionsSelected( selected ) {
		this.setSelectValue( 'accountReligionsSelected', 'religion_id', selected, false );
	}
	set accountTimeZonesSelected( selected ) {
		this.setSelectValue( 'accountTimeZonesSelected', 'time_zone_id', selected, false);
	}
	set accountSocialNetworksSelected( selected ) {
		this.setMultiSelectValue( 'accountSocialNetworksSelected', 'social_networks', selected );
	}
	set accountStatusSelected( selected ) {
		this.setMultiSelectValue( 'accountStatusSelected', 'account_status', selected );
	}
	set accountTypesSelected( selected ) {
		this.setMultiSelectValue( 'accountTypesSelected', 'types', selected );
	}


	loadAccountBrokersOptions = async () => {
		try {
			const response = await getAccountBrokers();
			const data = response.map( ContactInformation.Options( 'dictionary-account-brokers' )() );
			this.setState( { accountBrokersOptions: data, } );
		} catch ( error ) {}
	};
	loadAccountLabelsOptions = async () => {
		try {
			const response = await getAccountLabels();
			const data = response.map( ContactInformation.Options( 'dictionary-account-labels' )() );
			this.setState( { accountLabelsOptions: data, } );
		} catch ( error ) {}
	};
	loadAccountLifestylesOptions = async () => {
		try {
			const response = await getAccountLifestyles();
			const data = response.map( ContactInformation.Options( 'dictionary-account-lifestyles' )() );
			this.setState( { accountLifestylesOptions: data, } );
		} catch ( error ) {}
	};
	loadAccountMessengersOptions = async () => {
		try {
			const response = await getAccountMessengers();
			const data = response.map( ContactInformation.Options( 'dictionary-account-messengers' )() );
			this.setState( { accountMessengersOptions: data, } );
		} catch ( error ) {}
	};
	loadAccountNationalitiesOptions = async () => {
		try {
			const response = await getAccountNationalities();
			const data = response.map( ContactInformation.Options( 'dictionary-account-nationalities' )() );
			this.setState( { accountNationalitiesOptions: data, } );
		} catch ( error ) {}
	};
	loadAccountTimeZones = async () => {
		try {
			const response = await getAccountTimeZones();
			const data = response?.map((item) => {
				const label = this.renderTimeZoneLabel(item);

				return { value: item?.id, label }
			});
			const accountTimeZonesSelected = data?.filter((item => this.props?.user?.time_zone?.id === item?.value));

			this.setState( { accountTimeZonesOptions: data, accountTimeZonesSelected } );
		} catch (error) {}
	}
	loadAccountReligionsOptions = async () => {
		try {
			const response = await getAccountReligions();
			let data = response;
			data.unshift( { id: 0, name: '---', } );
			data = data.map( ContactInformation.Options( 'dictionary-account-religion' )() );
			this.setState( { accountReligionsOptions: data, } );
		} catch ( error ) {}
	};
	loadAccountSocialNetworksOptions = async () => {
		try {
			const response = await getAccountSocialNetworks();
			const data = response.map( ContactInformation.Options( 'dictionary-account-social_networks' )() );
			this.setState( { accountSocialNetworksOptions: data, } );
		} catch ( error ) {}
	};
	loadAccountStatusOptions = async () => {
		try {
			const response = await getAccountStatus();
			const data = response.map( ContactInformation.Options( 'dictionary-account-status' )() );
			this.setState( { accountStatusOptions: data, } );
		} catch ( error ) {}
	};
	loadAccountTypesOptions = async () => {
		try {
			const response = await getAccountTypes();
			const data = response.map( ContactInformation.Options( 'dictionary-account-types' )() );
			this.setState( { accountTypesOptions: data, } );
		} catch ( error ) {}
	};


	componentDidMount = async () => {
		forEach( this.props.allLanguages, ( item ) => {
			item.active ? this.setState( { lang: item.code } ) : undefined;
		} );
		await this.loadAccountBrokersOptions();
		await this.loadAccountLabelsOptions();
		await this.loadAccountLifestylesOptions();
		// await this.loadAccountMessengersOptions(); // REMOVED IN CURRENT VERSION OF CRM
		await this.loadAccountNationalitiesOptions();
		await this.loadAccountTimeZones();
		await this.loadAccountReligionsOptions();
		// await this.loadAccountSocialNetworksOptions(); // REMOVED IN CURRENT VERSION OF CRM
		await this.loadAccountStatusOptions();
		await this.loadAccountTypesOptions();
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		this.setState( {
			user: nextProps.user,
		} );
	};


	renderSwitch = ( options ) => (
		<div className='block-header_input-checkbox' style={{ position: 'relative', width: '20px', height: '20px', textAlign: 'center', top: 0, right: 0, display: 'inline-block', }} >
			<label className="checkbox">
				<input { ...{ type: 'checkbox', name: 'is_loyal', ...options, } } />
				<span style = { { 'marginRight': '24px' } }/>
			</label>
		</div>
	);

	renderSelect = ( options ) => (
		<div className='optipn_multiselect select-right'>
			<Select
				className = 'filter-select multiselect'
				classNamePrefix = 'select'
				{ ...{
					isMulti: false,
					isClearable: false,
					...options,
				} }
			/>
		</div>
	);

	renderMultiSelect = ( options ) => this.renderSelect( { isMulti: true, ...options, } );


	renderGender = () => {
		const gender = _.at( this.props, 'user.account.gender' ).shift();
		return gender && this.state.enums ? this.state.enums[ gender ] : '';
	};

	renderBrokers = () => this.renderMultiSelect({
		value: this.accountBrokersSelected,
		options: this.accountBrokersOptions,
		onChange: ( selected ) => this.accountBrokersSelected = selected,
	});

	renderLabels = () => this.renderMultiSelect({
		value: this.accountLabelsSelected,
		options: this.accountLabelsOptions,
		onChange: ( selected ) => this.accountLabelsSelected = selected,
	});

	/*renderLifestyles = () => this.renderSelect({
		value: this.accountLifestylesSelected,
		options: this.accountLifestylesOptions,
		onChange: ( selected ) => this.accountLifestylesSelected = selected,
	});*/

    /*renderNationality = () => this.renderSelect({
        value: this.accountNationalitiesSelected,
        options: this.accountNationalitiesOptions,
        onChange: ( selected ) => this.accountNationalitiesSelected = selected,
    });*/

    renderNationality = () => this.accountNationalitiesSelected?.[0]?.label ?? '';

	renderReligion = () => this.renderSelect({
		value: this.accountReligionsSelected,
		options: this.accountReligionsOptions,
		onChange: ( selected ) => this.accountReligionsSelected = selected,
	});

	renderMessengers = () => this.renderMultiSelect({
		value: this.accountMessengersSelected,
		options: this.accountMessengersOptions,
		onChange: ( selected ) => this.accountMessengersSelected = selected,
	});

	renderSocialNetworks = () => this.renderMultiSelect({
		value: this.accountSocialNetworksSelected,
		options: this.accountSocialNetworksOptions,
		onChange: ( selected ) => this.accountSocialNetworksSelected = selected,
	});

	renderStatus = () => this.renderMultiSelect({
		value: this.accountStatusSelected,
		options: this.accountStatusOptions,
		onChange: ( selected ) => this.accountStatusSelected = selected,
	});

	renderTypes = () => this.renderMultiSelect({
		value: this.accountTypesSelected,
		options: this.accountTypesOptions,
		onChange: ( selected ) => this.accountTypesSelected = selected,
	});
    //TODO: check the path to country/city (from core.account_addresses ?!)
    renderCountry = () => this.props?.user?.country?.[`name`];

    // renderCity = () => this.props?.user?.city?.[`name`];

    renderCity = () => {
        const accountRegisterType = this.accountRegisterType?.toString();
        const accountAddresses = this.accountAddresses;

        let targetAddressObj = {};

        // AccountRegisterTypeEnum: 1 - Individual, 2 - Business, 3 - Test
        switch (accountRegisterType) {
            case '1':
            case '3': {
                // INDIVIDUAL_FACTUAL_ADDRESS = 1
                targetAddressObj = accountAddresses?.filter((item) => item?.type?.toString() === '1')?.[0] ?? {};
                break;
            }
            case '2': {
                // CORPORATE_REGISTRATION_ADDRESS = 7
                targetAddressObj = accountAddresses?.filter((item) => item?.type?.toString() === '7')?.[0] ?? {};
                break;
            }
            default: {
                break;
            }
        }

        return targetAddressObj?.location_latin ?? '';
    };

	renderTimeZone = () => this.renderSelect({
		value: this.accountTimeZonesSelected,
		options: this.accountTimeZonesOptions,
		onChange: ( selected ) => this.accountTimeZonesSelected = selected
	})

	renderLoyal = () => this.renderSwitch({
		checked: this.isLoyal,
		onClick: ( event ) => this.isLoyal = event.target.checked,
	} );

	renderVIP = () => this.renderSwitch({
		checked: this.isVIP,
		onClick: ( event ) => this.isVIP = event.target.checked,
	} );

	renderLink = () => {
		const partnerId = this.props.user?.account?.partner_id;
		return partnerId ? <Link to={`/clients/${partnerId}`}>{partnerId}</Link> : null;
	};

	renderTimeZoneLabel = (time) => {
		const { name, offset } = time;
		const utcOffset = moment().utcOffset(+offset.split(' ')[1]).format('HH:mm:ss');

        return `${name} ${offset} ${utcOffset}`
	}

	renderLicense = () => {
		const license = _.at( this.props, 'user.dictionary_licenses.alias' );
		return license && this.state.licensesEnums ? this.state.licensesEnums[ license ] : '';
	};


	render = () => {
		const items = [
			// {
			// 	key: `client_license`,
			// 	value: this.renderLicense,
			// 	pmField: `id`,
			// 	pmIndex: pm.CORE_ACCOUNT,
			// },
			{
				key: `client_manager_nickname`,
				value: 'user.user.nickname',
				pmField: `nickname`,
				pmIndex: pm.CORE_USER,
			},
			{
				key: `client_manager_email`,
				value: 'user.user.email',
				pmField: `email`,
				pmIndex: pm.CORE_USER,
			},
			{
				key: `client_id`,
				value: 'user.account.id',
				pmField: `id`,
				pmIndex: pm.CORE_ACCOUNT,
			},
			{
				key: `partner_id`,
				value: this.renderLink,
				pmField: `id`,
				pmIndex: pm.CORE_ACCOUNT,
			},
			{
				key: `client_customer_label`,
				value: this.renderLabels,
				pmField: `label_id`,
				pmIndex: pm.CORE_ACCOUNT_LABELS,
			},
			{
				key: `client_customer_type`,
				value: this.renderTypes,
				pmField: `type_id`,
				pmIndex: pm.CORE_ACCOUNT_TYPES,
			},
			{
				key: `client_customer_broker`,
				value: this.renderBrokers,
				pmField: `broker_id`,
				pmIndex: pm.CORE_ACCOUNT_BROKERS,
			},
			{
				key: `client_sub_status`,
				value: this.renderStatus,
				pmField: `status_id`,
				pmIndex: pm.CORE_ACCOUNT_STATUS,
			},
			{
				key: `client_brand_loyal`,
				value: this.renderLoyal,
				pmField: `is_loyal`,
				pmIndex: pm.CORE_ACCOUNT,
			},
			{
				key: `client_brand_vip`,
				value: this.renderVIP,
				pmField: `is_vip`,
				pmIndex: pm.CORE_ACCOUNT,
			},
			/*{
				key: `client_customer_lifestyle`,
				value: this.renderLifestyles,
				pmField: `lifestyle_id`,
				pmIndex: pm.CORE_ACCOUNT_LIFESTYLES,
			},*/
			{
				key: `client_customer_nationality`,
				value: this.renderNationality,
				pmField: `nationality_id`,
				pmIndex: pm.CORE_ACCOUNT,
			},
			{
				key: `client_time_zone`,
				value: this.renderTimeZone,
				pmField: `time_zone_id`,
				pmIndex: pm.CORE_ACCOUNT,
			},
            /*{
                key: `client_country`,
                value: this.renderCountry,
                pmField: `country_id`,
                pmIndex: pm.CORE_ACCOUNT,
            },*/
			{
				key: `client_city`,
				value: this.renderCity,
				pmField: `city_id`,
				pmIndex: pm.CORE_ACCOUNT,
			},
			{
				key: `client_gender`,
				value: this.renderGender,
				pmField: `gender`,
				pmIndex: pm.CORE_ACCOUNT,
			},
			{
				key: `client_customer_religion`,
				value: this.renderReligion,
				pmField: `religion_id`,
				pmIndex: pm.CORE_ACCOUNT,
			},
			// {
			// 	key: `client_customer_messenger`,
			// 	value: this.renderMessengers, // REMOVED IN CURRENT VERSION OF CRM
			// 	pmField: `messenger_id`,
			// 	pmIndex: pm.CORE_ACCOUNT_MESSENGERS,
			// },
			// {
			// 	key: `client_customer_social_network`,
			// 	value: this.renderSocialNetworks, // REMOVED IN CURRENT VERSION OF CRM
			// 	pmField: `social_network_id`,
			// 	pmIndex: pm.CORE_ACCOUNT_SOCIAL_NETWORKS,
			// },
			// {
			// 	key: `client_address`,
			// 	value: 'user.account.addresses',
			// 	pmField: `addresses`,
			// 	pmIndex: pm.CORE_ACCOUNT,
			// },
			// {
			// 	key: `client_tin`,
			// 	value: 'user.account.enn',
			// 	pmField: `enn`,
			// 	pmIndex: pm.CORE_ACCOUNT,
			// },
			{
				key: `client_date_of_creation`,
				value: 'user.account.created_at',
				pmField: `created_at`,
				pmIndex: pm.CORE_ACCOUNT,
			},
			{
				key: `client_date_of_change`,
				value: 'user.account.updated_at',
				pmField: `updated_at`,
				pmIndex: pm.CORE_ACCOUNT,
			},
		].filter( data => PermissionService
			.index( data.pmIndex )
			.can( data.pmField )
		).map( data => ({
			...data,
			value: data.value instanceof Function ? data.value() : _.at( this.props, data.value ).shift()
		}) );

		return (
			<CheckUndefinedPermission>
				<Translate>
					{({ translate }) => (
						<div className = 'block-shadowed'>
							<span className = 'table-title'>{translate(`client_contact_information`)}</span>
							<div className = 'react-bs-table table-unbordered-container'>
								<Table> <tbody> { items.map( ( data, index ) => (
									<tr key={ index }>
										<td>{ translate( data.key ) }</td>
										<td className='td_multiselect'>{ data.value }</td>
									</tr>
								) ) } </tbody> </Table>
							</div>
						</div>
					)}
				</Translate>
			</CheckUndefinedPermission>
		);
	};

}

//TODO Redax Delete enums
const mapStateToProps = (store) => {
    return {
        allLanguages: _.get( store, 'localize.languages', [] ),
    };
};

export default withLocalize(withRouter(connect(mapStateToProps, null)(ContactInformation)));
