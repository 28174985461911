import React from "react";
// import BreadcrumbText from './BreadcrumbText';
// import BreadcrumbLink from './BreadcrumbLink';
import BreadcrumbItem from './BreadcrumbItem';
import './styles.scss';

export default function BreadcrumbList ( props ) {
    const { items, class4List, keysMapper } = props;
    const item2key = ( _, index ) => {
        const { link, text, key } = _;
        if ( key ) {
            return key;
        } else if ( keysMapper ) {
            return keysMapper( { ..._, index, } );
        } else {
            return `${ link || text || index }`;
        }
    };
    return (
        <ul
            className={ class4List || `breadcrumb__list` }
        >
            {
                items.map( ( item, index ) => (
                    <BreadcrumbItem
                        { ...item }
                        key={ item2key( item, index ) }
                    />
                ) )
            }
        </ul>
    );
}
