import React, { Component } from 'react';
import dateFns from "date-fns";

export default class LeadTailsRow extends Component {
    state = {
        tail: this.props.tail
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        this.setState({
            tail: { ...nextProps.tail },
        });
    }

    render () {

        return (
            <tr>
                <td>{this.state.tail["core-tails-tails_name"]}</td>
                <td>{dateFns.format(new Date(this.state.tail["core-account_tails-created_at"]), 'DD/MM/YYYY HH:mm:ss')}</td>
            </tr>
        );
    }
}
