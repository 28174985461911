import React from "react";

import PermissionService, {
	MODULE_KEYS as pm,
} from "../../../../../services/PermissionService";
import PartnerCard from "./PartnerCard";

export const partnersCardConfig = [
	{
		action: PermissionService.showRoute(),
		component: <PartnerCard activeTab="partner-card" />,
		path: [ "/partners/requests/partners-list/:id/partner-card", ],
		permission: pm.PARTNERS_SETUP,
	},
	{
		action: PermissionService.showRoute(),
		component: <PartnerCard activeTab="promotional-campaigns" />,
		path: [ "/partners/requests/partners-list/:id/promotional-campaigns", ],
		permission: pm.CORE_ACCOUNT,
	},
	{
		action: PermissionService.showRoute(),
		component: <PartnerCard activeTab="referrals" />,
		path: [ "/partners/requests/partners-list/:id/referrals", ],
		permission: pm.CORE_ACCOUNT,
	},
	{
		action: PermissionService.showRoute(),
		component: <PartnerCard activeTab="payouts" />,
		path: [ "/partners/requests/partners-list/:id/payouts", ],
		permission: pm.CORE_ACCOUNT,
	},
	{
		action: PermissionService.indexRoute(),
		component: <PartnerCard activeTab="partner-card" />,
		path: [ "/partners/requests/partners-list/:id", ],
		permission: pm.PARTNERS_SETUP,
	},
];