import {
  itemsBuild,
  itemsDrain,
  itemsResort,
  CFG_ITEM_TYPE,
} from '../Config';
import { onEnabled, render, } from '../Mentor/withDropDown';

const f_tStatesId = {
  'drop-down': '/api/tasks/task-states/drop-down?take=all',
  'o=': [ '=', '!=', ],
};

const f_tCreatorUserId = {
  'drop-down': '/api/core/user/drop-down?take=all',
  'o=': [ '=', '!=', ],
};

const f_tResponsibleUserId = {
  'drop-down': '/api/core/user/drop-down?take=all',
  'o=': [ '=', '!=', ],
};


const f_tEventId = {
  'drop-down': '/api/core/event-tables?table_name=tasks.tasks&take=all',
  'o=': [ '=', '!=', ],
};


const p_tEventId = {
  onEnabled: onEnabled('tEventId'),
  render: render('tEventId'),
};

const p_tStatesId = {
  onEnabled: onEnabled('tStatesId'),
  render: render('tStatesId'),
};

const p_tCreatorUserId = {
  onEnabled: onEnabled('tCreatorUsers'),
  render: render(
    'tCreatorUsers',
    _ => _ ? `${ _?.name } ${ _?.surname }` : '-',
  ),
};

const p_tResponsibleUserId = {
  onEnabled: onEnabled('tResponsibleUsers'),
  render: render(
    'tResponsibleUsers',
    _ => _ ? `${ _?.name } ${ _?.surname }` : '-',
  ),
};

const tasks = {
  id: 32,
  ...[

    // filters
    ...[ {
      f: 'tasks.tasks.id',
      id: 'f:tId',
    }, {
      f: [ 'core.account.surname', 'core.account.name', ],
      id: 'f:aFullname',
      o: 'like',
    }, {
      f: 'core.account_categories.category_id',
      id: 'f:aСategoryId',
    }, {
      f: 'core.account_categories.created_at',
      id: 'f:acCreated',
    }, {
      f: 'core.account.warm_type',
      id: 'f:aWarmType',
    }, {
      f: 'tasks.task_states.id',
      id: 'f:tStatesId',
      ...f_tStatesId,
    }, {
      f: 'tasks.tasks.status',
      id: 'f:tStatus',
      off: true, // TODO:
    }, {
      f: 'tasks.tasks.finish_date',
      id: 'f:tAtFinish',
    }, {
      enabled: 1,
      f: 'tasks.task_users.role_id',
      fields: 'f:tCreatorUserId',
      id: 'f:tCreatorRole',
      o: '=',
      v: '1',
    }, {
      f: [ 'core.user.id', ],
      id: 'f:tCreatorUserId',
      ...f_tCreatorUserId,
    }, {
      enabled: 1,
      f: 'tasks.task_users.role_id',
      fields: 'f:tResponsibleUserId',
      id: 'f:tResponsibleRole',
      o: '=',
      v: '3',
    }, {
      f: [ 'core.user.id', ],
      id: 'f:tResponsibleUserId',
      ...f_tResponsibleUserId,
    }, {
      f: 'tasks.tasks.description',
      id: 'f:tDesc',
      o: 'like',
    }, {
      f: 'core.dictionary_licenses.alias',
      id: 'f:tLicenseAlias',
    },
      {
        f: 'tasks.task_events.event_id',
        id: 'f:tEventId',
        ...f_tEventId,
      },
    ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

    // orders
    ...[ {
      f: 'core.account.surname',
      id: 'o:aSurname',
    }, {
      f: 'tasks.tasks.description',
      id: 'o:tDesc',
    }, {
      f: 'core.account_categories.created_at',
      id: 'o:acCreated',
    }, {
      f: 'core.account.warm_type',
      id: 'o:aWarmType',
    }, {
      f: 'core.account.warm_type_last_change',
      id: 'o:aAtWarmChanged',
    }, {
      f: 'tasks.tasks.finish_date',
      id: 'o:tAtFinish',
    }, {
      f: 'core.user.surname',
      id: 'o:uSurname',
    }, {
      f: 'core.account_categories.category_id',
      id: 'o:aСategoryId',
    }, {
      f: 'tasks.task_states.id',
      id: 'o:tStatus',
    }, {
      f: 'tasks.tasks.id',
      id: 'o:tTaskId',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

    // panels
    ...[ {
      ii: 'f:tId',
      t: 'magic_filter_translate_task_id',
    }, {
      ii: 'f:aFullname',
      t: 'magic_filter_translate_task_account',
    }, {
      ii: 'f:aWarmType',
      t: 'magic_filter_translate_task_warm',
    }, {
      ii: 'f:tStatesId',
      t: 'magic_filter_translate_task_status',
      ...p_tStatesId,
    },
      {
        ii: 'f:tEventId',
        t: 'magic_filter_translate_task_event_id',
        ...p_tEventId,
      },
      {
      ii: 'f:tStatus',
      off: true, // TODO:
      xt: '?',
    }, {
      ii: 'f:tAtFinish',
      t: 'magic_filter_translate_task_finish_date',
    }, {
      ii: 'f:tCreatorUserId',
      t: 'magic_filter_translate_task_creator',
      ...p_tCreatorUserId,
    }, {
      ii: 'f:tResponsibleUserId',
      t: 'magic_filter_translate_task_responsible',
      ...p_tResponsibleUserId,
    }, {
      ii: 'f:tDesc',
      t: 'magic_filter_translate_task_description',
    }, {
      ii: 'f:aСategoryId',
      t: 'magic_filter_translate_task_category_id',
    }, {
      ii: 'f:acCreated',
      t: 'magic_filter_translate_task_created_at',
    }, {
      ii: 'f:tLicenseAlias',
      t: 'magic_filter_translate_task_license_alias',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

  ]
  .map(itemsBuild)
  .reduce(itemsDrain, [])
  .reduce(itemsResort, {}),

  path: 'tasks/tasks',
  widget: false,
};

export default [
  tasks,
  {
    ...tasks,
    filters: [],
    id: 33,
    panels: [],
  },
];
