import { DataService } from "./DataService";
import { del, get, post, put } from "../components/Magic/helpers/MagicRequest";
import { hash as hashData } from "./CachingService";
import {
    GET_DOWNLOAD_MAIL_DOCUMENT,
    GET_LANGUAGE,
    GET_MAIL_TEMPLATE_DOCUMENT,
    GET_MAIL_TEMPLATES,
    GET_MAIL_TEMPLATES_TRANSLATION,
    GET_MAIL_TEMPLATES_TRANSLATION_BY_TEMPLATE,
    GET_SMS_TEMPLATES,
    GET_SMS_TEMPLATES_TRANSLATION,
    GET_SMS_TEMPLATES_TRANSLATION_BY_TEMPLATE
} from "../apiRoutes";
import fileDownload from "js-file-download";

class TemplateDataService extends DataService {
    constructor () {
        super([
            'smsTemplates',
            'smsTemplate',
            'smsTemplateTranslation',
            'createSmsTemplates',
            'putSmsTemplates',
            'deleteSmsTemplates',
            'createSmsTemplatesTranslation',
            'updateSmsTemplatesTranslation',
            'deleteSmsTemplatesTranslation',
            'mailTemplates',
            'mailTemplate',
            'mailTemplateTranslation',
            'createMailTemplates',
            'putMailTemplates',
            'deleteMailTemplates',
            'createMailTemplatesTranslation',
            'updateMailTemplatesTranslation',
            'deleteMailTemplatesTranslation',
            'language',
            'downloadDoc',
            'uploadDoc',
            'deleteDoc',
            'templatesVariables'
        ]);
        Object.freeze(this);
    }

    async smsTemplates (options) {
        const url = `${GET_SMS_TEMPLATES}`;
        const templates = await get(url, options);
        const hash = await hashData(templates);
        const smsTemplates = { templates, hash };
        this.emit('smsTemplates', smsTemplates);

        return smsTemplates;
    }

    async smsTemplate (id) {
        const url = `${GET_SMS_TEMPLATES}/${id}`;
        const template = await get(url);
        const smsTemplate = { template };
        this.emit('smsTemplate', smsTemplate);

        return smsTemplate;
    }

    async smsTemplateTranslation (id) {
        const url = `${GET_SMS_TEMPLATES_TRANSLATION_BY_TEMPLATE}/${id}`;
        const data = await get(url);
        const hash = await hashData(data);
        const smsTemplateTranslation = { data, hash };
        this.emit('smsTemplateTranslation', smsTemplateTranslation);

        return smsTemplateTranslation;
    }

    async createSmsTemplates (obj) {
        const url = `${GET_SMS_TEMPLATES}`;
        const data = await post(url, obj);
        const createSmsTemplates = { data };
        this.emit('createSmsTemplates', createSmsTemplates);

        return createSmsTemplates;
    }

    async putSmsTemplates (id, obj) {
        const url = `${GET_SMS_TEMPLATES}/${id}`;
        const data = await put(url, obj);
        const putSmsTemplates = { data };
        this.emit('putSmsTemplates', putSmsTemplates);

        return putSmsTemplates;
    }

    async deleteSmsTemplates (id) {
        const url = `${GET_SMS_TEMPLATES}/${id}`;
        const data = await del(url);
        const deleteSmsTemplates = { data };
        this.emit('deleteSmsTemplates', deleteSmsTemplates);

        return deleteSmsTemplates;
    }

    async createSmsTemplatesTranslation (obj) {
        const url = `${GET_SMS_TEMPLATES_TRANSLATION}`;
        const data = await post(url, obj);
        const createSmsTemplatesTranslation = { data };
        this.emit('createSmsTemplatesTranslation', createSmsTemplatesTranslation);
    }

    async updateSmsTemplatesTranslation (id, obj) {
        const url = `${GET_SMS_TEMPLATES_TRANSLATION}/${id}`;
        const data = await put(url, obj);
        const hash = await hashData(data);
        const updateSmsTemplatesTranslation = { data, hash };
        this.emit('updateSmsTemplatesTranslation', updateSmsTemplatesTranslation);
    }

    async deleteSmsTemplatesTranslation (id) {
        const url = `${GET_SMS_TEMPLATES_TRANSLATION}/${id}`;
        const data = await del(url);
        const deleteSmsTemplatesTranslation = { data };
        this.emit('deleteSmsTemplatesTranslation', deleteSmsTemplatesTranslation);
    }

    async mailTemplates (options) {
        const url = `${GET_MAIL_TEMPLATES}`;
        const templates = await get(url, options);
        const hash = await hashData(templates);
        const mailTemplates = { templates, hash };
        this.emit('mailTemplates', mailTemplates);

        return mailTemplates;
    }

    async mailTemplate (id) {
        const url = `${GET_MAIL_TEMPLATES}/${id}`;
        const template = await get(url);
        const mailTemplate = { template };
        this.emit('mailTemplate', mailTemplate);

        return mailTemplate;
    }

    async mailTemplateTranslation (id) {
        const url = `${GET_MAIL_TEMPLATES_TRANSLATION_BY_TEMPLATE}/${id}`;
        const data = await get(url);
        const hash = await hashData(data);
        const mailTemplateTranslation = { data, hash };
        this.emit('mailTemplateTranslation', mailTemplateTranslation);

        return mailTemplateTranslation;
    }

    async createMailTemplates (obj) {
        const url = `${GET_MAIL_TEMPLATES}`;
        const data = await post(url, obj);
        const createMailTemplates = { data };
        this.emit('createMailTemplates', createMailTemplates);

        return createMailTemplates;
    }

    async putMailTemplates (id, obj) {
        const url = `${GET_MAIL_TEMPLATES}/${id}`;
        const data = await put(url, obj);
        const putMailTemplates = { data };
        this.emit('putMailTemplates', putMailTemplates);
    }

    async deleteMailTemplates (id) {
        const url = `${GET_MAIL_TEMPLATES}/${id}`;
        const data = await del(url);
        const deleteMailTemplates = { data };
        this.emit('deleteMailTemplates', deleteMailTemplates);
    }

    async createMailTemplatesTranslation (obj) {
        const url = `${GET_MAIL_TEMPLATES_TRANSLATION}`;
        const data = await post(url, obj);
        const createMailTemplatesTranslation = { data };
        this.emit('createMailTemplatesTranslation', createMailTemplatesTranslation);
    }

    async updateMailTemplatesTranslation (id, obj) {
        const url = `${GET_MAIL_TEMPLATES_TRANSLATION}/${id}`;
        const data = await put(url, obj);
        const hash = await hashData(data);
        const updateMailTemplatesTranslation = { data, hash };
        this.emit('updateMailTemplatesTranslation', updateMailTemplatesTranslation);
    }

    async deleteMailTemplatesTranslation (id) {
        const url = `${GET_MAIL_TEMPLATES_TRANSLATION}/${id}`;
        const data = await del(url);
        const deleteMailTemplatesTranslation = { data };
        this.emit('deleteMailTemplatesTranslation', deleteMailTemplatesTranslation);
    }

    async language () {
        const url = `${GET_LANGUAGE}`;
        const data = await get(url);
        const hash = await hashData(data);
        const language = { data, hash };
        this.emit('language', language);

        return language;
    }

    async downloadDoc (alias, fileName) {
        const url = `${GET_DOWNLOAD_MAIL_DOCUMENT}/${alias}`;
        const data = await get(url, { responseType: 'blob' });
        const name = `${fileName}.${data?.type.split('/')[1]}`;
        const downloadDoc = { data };
        fileDownload(data, name, data?.type);
        this.emit('downloadDoc', downloadDoc);
    }

    async uploadDoc (formData) {
        const url = `${GET_MAIL_TEMPLATE_DOCUMENT}`;
        const data = await post(url, formData);
        const uploadDoc = { data };
        this.emit('uploadDoc', uploadDoc);
    }

    async deleteDoc (id) {
        const url = `${GET_MAIL_TEMPLATE_DOCUMENT}/${id}`;
        const data = await del(url);
        const deleteDoc = { data };
        this.emit('deleteDoc', deleteDoc);
    }
}

export const templateService = new TemplateDataService();
