export const conversationsLang = {
	"conversation_channel": [ "Channels", "Каналы", "Канали", ],
	"conversation_channel_actions": [ "Actions", "Действия", "Дії", ],
	"conversation_client_language": [ "Chat language", "Язык чата", "Мова чату", ],
	"conversation_client_set_language": [ "Set language", "Установить язык", "Встановити мову", ],
	"conversation_channel_client_responsible": [ "Responsible", "Ответственный", "Відповідальний", ],
	"conversation_window": [ "Conversation window", "Окно разговора", "Вікно розмови", ],
	"conversation_window_check_email": [ "Request email", "Запросить email", "Запросити email", ],
	"conversation_window_end_conversation": [ "End conversation", "Завершить разговор", "Завершити розмову", ],
	"conversation_window_no_tech_managers": [ "No employees", "Нет сотрудников", "Нема співробітників", ],
	"conversation_window_quick_answers": [ "Quick Answers", "Быстрые ответы", "Швидкі відповіді", ],
	"conversation_window_send_btn": [ "Send", "Отправить", "Надіслати", ],
	"conversation_window_send_btn_placeholder": [ "Enter your answer", "Введите ваш ответ", "Введіть вашу відповідь", ],
	"conversation_window_switch_to_responsible_btn": [ "Switch to responsible", "Переключить на отвественного", "Переключити на відповідального", ],
	"conversation_window_switch_to_tech_support_btn": [ "Switch to tech support", "Переключить на тех поддержку", "Переключити на тех підтримку", ],

};