import { orderBy, map } from "lodash";
import React, { Component } from 'react';
import { withLocalize, Translate } from 'react-localize-redux';

import { Table } from 'reactstrap';
import InteratorListData from './InteratorListData';
import { Scrollbars } from "react-custom-scrollbars";

class InteratorListUser extends Component {
    constructor (props) {
        super(props);
        this.state = {
            allAccounts: this.props.jsonData,
            sort: "date",
            sortType: true,
            currentUserId: this.props.currentUserId
        };
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        this.setState({
            allAccounts: { ...nextProps.jsonData },
            currentUserId: nextProps.currentUserId
        });
    }
    sortBy = (event) => {

        let list = this.state.allAccounts;
        const curSort = event.currentTarget.dataset.sort;
        let curSortType = curSort === this.state.sort ? this.state.sortType : false;

        list = orderBy(list, [curSort], curSortType ? 'desc' : 'asc');

        this.setState(
            {
                allAccounts: list,
                sortType: !curSortType,
                sort: curSort
            });
    }

    setCurrentUser = (id, type) => {
        this.props.setActiveUser(id, type);
    }
    render () {
        return (
            <Translate>
                {({ translate }) => {
                    return (

                        <div className = 'tableSort'>
                            <Scrollbars
                                style = { { height: 730 } }
                                renderView={props => <div {...props}/>}
                                renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                                renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} />}
                            >
                                <Table>
                                    <thead>
                                    <tr>
                                        <th className='sortable-header' data-sort="date" onClick={ this.sortBy }><div>{translate(`client_created`)} {this.state.sort === "date" ? <i className = {`fa fa-angle-down ${this.state.sortType}`} /> : ''}</div></th>
                                        <th className='sortable-header' data-sort="call_billsec" onClick={ this.sortBy }><div>{translate(`client_type`)} {this.state.sort === "call_billsec" ? <i className = {`fa fa-angle-down ${this.state.sortType}`} /> : ''}</div></th>
                                        <th className='sortable-header' data-sort="user_name" onClick={ this.sortBy }><div>{translate(`client_responsible`)} {this.state.sort === "user_name" ? <i className = {`fa fa-angle-down ${this.state.sortType}`} /> : ''}</div></th>
                                        <th className='sortable-header' data-sort="user_result" onClick={ this.sortBy }><div>{translate(`client_result`)} {this.state.sort === "user_result" ? <i className = {`fa fa-angle-down ${this.state.sortType}`} /> : ''}</div></th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {map(this.state.allAccounts, (item, key) => {
                                        return (<InteratorListData
                                            translate = {translate}
                                            key = { key }
                                            searchValue = { this.props.searchValue }
                                            setCurrentUser = { this.setCurrentUser }
                                            user = { item }
                                            currentUserId = { this.state.currentUserId }
                                        />);
                                    })
                                    }
                                    </tbody>
                                </Table>
                            </Scrollbars>
                        </div>

                    );
                }}
            </Translate>
        );
    }
}

export default withLocalize(InteratorListUser);
