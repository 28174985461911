import React, { PureComponent, } from 'react';
import { withLocalize, } from 'react-localize-redux';
import { withRouter, } from 'react-router-dom';
import propTypes from 'prop-types';
import Loader from '../../../../tima/components/Loader';
import Preloader from '../../../../../components/LoadingHOC/Preloader';
import PagePanel from '../../../../tima/components/Pages';
import ListPaymentsMagicTable from './ListPaymentsMagicTable';
import { paymentService, } from "../../../../../services/PaymentDataService";

class ListPayments extends PureComponent {

  static propTypes = {
  	mf: propTypes.object.isRequired,
    // eslint-disable-next-line sort-keys
  	enums: propTypes.object,
  	handlerPopup: propTypes.func,
  	titleTableThead: propTypes.array,
  	translate: propTypes.func,
  	departmentsListNames: propTypes.array,
  };

  static defaultProps = {
  	enums: {},
  	handlerPopup: () => {},
  	translate: () => {},
  };

  constructor (props) {
  	super(props);
  	this.state = {
  		data: [],
  		filterLastChange: Date.now(),
  		loaded: false,
  		payments: {
  			data: [],
  			hash: null,
  			options: {},
  		},
  		pages: {
  			filter: 0,
  			take: 50,
  			total: 0,
  			variance: 2,
  		},
  	};

  	Object.defineProperty(this, 'mf', { get: () => this.props.mf, });
  }

  // eslint-disable-next-line react/sort-comp, require-await
  save = async state => new Promise(next => this.setState(state, next));

  mfChanged = async () => {
  	await this.save({ filterLastChange: Date.now(), });
  };

  mfDoing = async () => {
  	await this.save({ loaded: false, });
  };

  mfDone = async () => {
  	await this.mfChanged();
  	await this.onDataLoad();
  };

  paymentsChanges = async ({ data, hash, options, }) => {
  	if (hash===this.state?.payments?.hash) {
  		return `${ this.constructor.name }.paymentsChanges: false`;
  	} else if (options.filterId!==this.state?.payments?.options?.filterId) {
  		return `${ this.constructor.name }.paymentsChanges: false`;
  	} else if (options.take!==this.state?.payments?.options?.take) {
  		return `${ this.constructor.name }.paymentsChanges: false`;
  	} else if (options.skip!==this.state?.payments?.options?.skip) {
  		return `${ this.constructor.name }.paymentsChanges: false`;
  	}
  	await this.save({
  		payments: {
  			data: data.data,
  			hash,
  			options,
  		},
  		pages: {
  			...this.state.pages,
  			filter: data?.meta?.filter,
  			total: data?.meta?.total,
  		},
  	});

  	return `${ this.constructor.name }.paymentsChanges: true`;
  };

  componentDidMount = async () => {
  	paymentService.subscribe('paymentOrders', this.paymentsChanges, this);
  	this.mf.subscribe({
  		changed: this.mfChanged,
      // changing: ,
  		cleaned: this.mfDone,
  		cleaning: this.mfDoing,
  		loaded: this.mfDone,
  		loading: this.mfDoing,
  		saved: this.mfDone,
  		saving: this.mfDoing,
  	}, this);
  	await this.mf.init({ doEmit: true, });
    // await this.mf.emit('change', {});
    // await this.onDataLoad(); // TODO: moved to mfDone
  };

  componentWillUnmount = () => {
  	this.mf.unsubscribe([
  		this.mfChanged,
  		this.mfDoing,
  		this.mfDone,
  	], this);
  	paymentService.unsubscribe('paymentOrders', this.paymentsChanges, this);
  };

  onDataLoad = async (pageId) => {
  	const pageOptions = this.pageIdAsGET(pageId);

  	await this.save({
  		loaded: false,
  		payments: { ...this.state.payments, options: pageOptions, },
  	});
  	try {
  		await paymentService.paymentOrders(pageOptions);
  	} catch (error) {
  		console.log("error: ", error);
  		await this.save({
  			payments: {
  			data: [],
  			hash: null,
  			options: {},
  		},
  		pages: { ...this.state.pages, filter: 0, total: 0, },
  		});
  	}
  	await this.save({ loaded: true, });
  };

  pageId = () => {
  	const { query, } = this.props;
  	const page = query.get('page');

  	return Number(page) || 0;
  };

  pageIdAsGET = (pageId) => {
    // eslint-disable-next-line no-param-reassign
  	pageId = pageId === undefined ? this.pageId() : pageId;

  	const { filterId, } = this.mf;
  	const result = {
  		filterId,
  		skip: pageId * this.state.pages.take,
  		take: this.state.pages.take,
  	};

  	return result;
  };

  // eslint-disable-next-line no-unused-vars
  onPageClick = ({ event, pageId, pageIs, }) => {
  	this.onDataLoad(pageId);
  };

  onPageText = (pageId, pageIs) => {
  	const { translate, } = this.props;
    // eslint-disable-next-line object-curly-newline
  	const { current, first, prev, next, last, } = pageIs;
    // eslint-disable-next-line object-curly-newline
  	const { skipped, taken, filter, total, } = pageIs;

  	if (skipped || taken) {
  		const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : '?';
  		const text = skipped ? translate('payment_pages_items') : '';

  		return skipped ? `${ text }: ${ id }` : id;
  	} else if (filter || total) {
  		const id = Number.isInteger(pageId) ? `${ pageId }` : '?';
  		const text = translate(filter ? 'payment_pages_filtered' : 'payment_pages_total');

  		return `${ text }: ${ id }`;
  	} else if (first || prev || next || last) {
  		return '';
  	} else if (current) {
  		return `${ pageId + 1 }`;
  	}

  	return `${ pageId + 1 }`;
  };

  handleSelectAmount = async (id) => {
  	await this.save({ selectedAmountId: id ?? null, });
  };

  render () {
  	const { loaded, pages, payments, } = this.state;

  	return (
	<Loader loaded={ loaded } loading={ <Preloader scale={ 1 } /> }>
	<div className="table payments-table">
	<ListPaymentsMagicTable
	departmentsListNames={ this.props?.departmentsListNames }
	data={ payments.data }
	enums={ this.props?.enums }
	handleSelectAmount={ this.handleSelectAmount }
	refreshData={ this.onDataLoad }
	selectedAmountId={ this.state.selectedAmountId }
  				/>
	<PagePanel
	doText={ this.onPageText }
	doTo={ pageId => `?page=${ pageId }` }
	filter={ pages.filter }
	page={ this.pageId() }
	showSingle={ false }
	take={ pages.take }
	total={ pages.total }
	variance={ pages.variance }
	onClick={ this.onPageClick }
  				/>
  			</div>
  		</Loader>
  	);
  }
}

export default withRouter(withLocalize(ListPayments));