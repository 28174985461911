import * as actions from './actionTypes';


export const setSideBar = (data) => ({
  type: actions.SET_SIDEBAR,
  data,
});

export const getSideBar = (data) => (dispatch) => {
  dispatch(setSideBar(data));
};
