import {
  itemsBuild,
  itemsDrain,
  itemsResort,
  CFG_ITEM_TYPE,
} from '../Config';
import { onEnabled, render, } from '../Mentor/withDropDown';

const f_aId = {
  // 'drop-down': false,
  'o+': [ 'in', 'not_in', ],
  // type: 'string',
};

const f_accountCountry = {
  'drop-down': '/api/core/country/with-account/drop-down?take=all',
  'o=': [ '=', '!=', 'null', 'not_null' ],
};

const f_city = {
  'drop-down': '/api/core/city/with-account/drop-down?take=all',
  'o=': [ '=', '!=', 'null', 'not_null' ],
};

const f_uId = {
  'drop-down': '/api/core/account/user/drop-down?take=all',
  'o=': [ '=', '!=', ],
};

const f_aDictionaryAccountTagId = {
	'drop-down': '/api/core/dictionary-account-tag/drop-down?take=all',
	'o=': [ '=', 'null', ],
};

const p_cityId = {
  onEnabled: onEnabled('cities'),
  render: render('cities'),
};

const p_accCountryId = {
  onEnabled: onEnabled('accCountries'),
  render: render('accCountries'),
};

const p_uId = {
  onEnabled: onEnabled('users'),
  render: render(
    'users',
    _ => _ ? `${ _?.name } ${ _?.surname }` : '-',
  ),
};

const p_aDictionaryAccountTagId = {
	onEnabled: onEnabled('tagIds'),
	render: render('tagIds'),
};

const accounts = {
  id: 8,
  ...[

    // filters
    ...[ {
      f: 'core.account.id',
      id: 'f:aId',
      ...f_aId,
    }, {
      f: 'core.account.name',
      id: 'f:aName',
      o: 'like',
    }, {
      f: 'core.account.surname',
      id: 'f:aSurname',
      o: 'like',
    }, {
      f: [ 'core.account.surname', 'core.account.name', ],
      id: 'f:aFullname',
      o: 'like',
    }, {
      f: [ 'core.account.email', 'core.account.phone', ],
      id: 'f:aEmailPhone',
      o: '=',
    }, {
      f: 'core.account.email',
      id: 'f:aEmail',
    }, {
      f: 'core.account.phone',
      id: 'f:aPhone',
    }, {
      f: 'core.account_categories.category_id',
      id: 'f:aCategoryId',
    }, {
      f: 'core.country.id',
      id: 'f:accCountryId',
      ...f_accountCountry,
    }, {
      f: 'core.city.id',
      id: 'f:cityId',
      ...f_city,
    }, {
      f: 'core.account.warm_type',
      id: 'f:aWarm',
    }, {
      f: 'core.account.created_at',
      id: 'f:aCreated',
    }, {
      f: 'core.account_categories.created_at',
      id: 'f:aAtCategory',
    }, {
      f: [ 'core.user.surname', 'core.user.name', ],
      id: 'f:uName',
      o: 'like',
    }, {
      f: 'core.user.id',
      id: 'f:uId',
      ...f_uId,
    },
      {
      f: 'core.account_descriptions.user_id',
      id: 'f:uAuthorOflastDescription',
      ...f_uId,
    }, {
      f: 'core.account_descriptions.updated_at',
      id: 'f:aAtDesc',
    }, {
      f: 'core.account.warm_type_last_change',
      id: 'f:aAtWarmLast',
    // }, {
    //   f: 'core.account.tax_residency_self_certification',
    //   id: 'f:aTaxResidency',
    }, {
      f: 'core.account.new_in_briefcase',
      id: 'f:aNew',
    }, {
      f: 'core.account.is_processed',
      id: 'f:aIsProcessed',
    }, {
  	  f: 'core.account_tag.dictionary_account_tag_id',
      id: 'f:aDictionaryAccountTagId',
      ...f_aDictionaryAccountTagId,
    }, {
      f: 'core.dictionary_licenses.alias',
      id: 'f:tLicenseAlias',
    }, {
      f: 'core.account.is_mobile',
      id: 'f:aIsMobile',
    },
    {
      f: 'core.account.register_type',
      id: 'f:aRegisterType',
    }, {
      f: 'core.account.status',
      id: 'f:aStatus',
    },
    ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

    // orders
    ...[ {
      f: 'core.account.id',
      id: 'o:aId',
    }, {
      f: 'core.account.surname',
      id: 'o:aSurname',
    }, {
      f: 'core.account.email',
      id: 'o:aEmail',
    }, {
      f: 'core.account.phone',
      id: 'o:aPhone',
    }, {
      f: 'core.account.warm_type',
      id: 'o:aWarm',
    }, {
      f: 'core.account.created_at',
      id: 'o:aCreated',
    }, {
      f: 'core.account_categories.created_at',
      id: 'o:aAtCategory',
    }, {
      f: 'core.user.surname',
      id: 'o:uSurname',
    },
      {
      f: 'core.account_descriptions.user_id',
      id: 'o:uAuthorOflastDescription',
    },
      {
      f: 'core.account_descriptions.updated_at',
      id: 'o:aAtDesc',
    }, {
      f: 'core.account.warm_type_last_change',
      id: 'o:aAtWarmLast',
    }, {
      f: 'core.account_categories.category_id',
      id: 'o:aCategoryId',
    }, {
      f: 'core.country.id',
      id: 'o:accCountryId'
    }, {
        f: 'core.account.is_mobile',
        id: 'o:aIsMobile'
      },
    {
      f: 'core.account.register_type',
      id: 'o:aRegisterType',
    },
      {
        f: 'core.account.status',
        id: 'o:aStatus',
      },
    ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),
    // panels
    ...[  {
      ii: 'f:aId',
      t: 'magic_filter_translate_account_id',
    }, {
      ii: 'f:aName',
      t: 'magic_filter_translate_account_name',
    }, {
      ii: 'f:aSurname',
      t: 'magic_filter_translate_account_surname',
    }, {
      ii: 'f:aEmail',
      t: 'magic_filter_translate_account_email',
    }, {
      ii: 'f:aPhone',
      t: 'magic_filter_translate_account_phone',
    }, {
      ii: 'f:aCategoryId',
      t: 'magic_filter_translate_account_category',
    }, {
      ii: 'f:accCountryId',
      t: 'magic_filter_translate_statistics_account_core_country',
      ...p_accCountryId,
    }, {
      ii: 'f:cityId',
      t: 'magic_filter_translate_account_city',
      ...p_cityId,
    }, {
      ii: 'f:aWarm',
      t: 'magic_filter_translate_account_warm',
    },
      {
        ii: 'f:aStatus',
        t: 'client_client_status',
      },
      {
      ii: 'f:aCreated',
      t: 'magic_filter_translate_account_created_at',
    }, {
      ii: 'f:aAtCategory',
      t: 'magic_filter_translate_account_change_category_date',
    }, {
      ii: 'f:uId',
      t: 'magic_filter_translate_task_responsible',
      ...p_uId,
    },{
      ii: 'f:uAuthorOflastDescription',
      t: 'author_of_last_description',
      ...p_uId,
    }, {
      ii: 'f:aAtDesc',
      t: 'magic_filter_translate_account_description_last_updated',
    // }, {
    //   ii: 'f:aTaxResidency',
    //   t: 'tax_residency',
    }, {
      ii: 'f:aAtWarmLast',
      t: 'warm_type_last_change',
    }, {
      ii: 'f:aNew',
      t: 'client_new_in_briefcase',
    }, {
      ii: 'f:aIsProcessed',
      t: 'client_processed',
    }, {
  	  ii: 'f:aDictionaryAccountTagId',
      t: 'account_tag',
      ...p_aDictionaryAccountTagId,
    }, {
      ii: 'f:tLicenseAlias',
      t: 'magic_filter_translate_task_license_alias',
    }, {
      ii: 'f:aIsMobile',
      t: 'magic_filter_translate_is_mobile',
    },
    {
      ii: 'f:aRegisterType',
      t: 'magic_filter_translate_account_register_type',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

  ]
  .map(itemsBuild)
  .reduce(itemsDrain, [])
  .reduce(itemsResort, {}),

  path: 'core/account',
  widget: false,
};

export default [
  accounts,
  ...[ 1, 2, 3, 4, 5, 6, 7, ].map(categoryId => ({
    ...accounts,
    id: accounts.id + categoryId,
    ...{

      // filters
      filters: accounts.filters
        .map(_ => ({
          ..._,
          ..._.$.id === 'f:aCategoryId' && {
            auto: 1,
            enabled: 1,
            v: `${ categoryId }`,
          },
        })),

      // orders
      orders: accounts.orders.filter(_ => _.$.id !== 'o:aCategoryId'),

      // panels
      panels: accounts.panels.filter(_ => _.items !== 'f:aCategoryId'),

    },
  })),
];