import React, { Component, } from "react";
import { Col, Row, } from "reactstrap";
import PropTypes from "prop-types";

import CommentsCustomerData from "../../../../../../../../clients/components/Leads/LeadCustomerData/Comments";
import PartnerInformation from "./PartnerInformation";
import PartnerProgramm from "./PartnerProgramm";

import PermissionService from "../../../../../../../../../services/PermissionService";
import { getClient, } from "../../../../../../../../clients/services/AccountService";

export default class MainInformation extends Component {
	static propTypes = {
		refreshClientData: PropTypes.func.isRequired,
	};

	get translate () {
		return this.props.translate;
	}

	get pmConfig () {
		return [
			{
				key: "getByAccountId",
				path: [ "core", "account_descriptions", ],
			},
			{
				key: "isMobile",
				path: [ "core", "account", "is_mobile", ],
			},
		];
	}

	renderCommentsCustomerData = () => {
		const conf = PermissionService.calc(this.pmConfig);

		if (conf?.getByAccountId.access("getByAccountId")) {
			return (
				<Col sm="4">
					<CommentsCustomerData
						client={ this.props.account }
						getClient={ getClient }
						id={ `${ this.props?.account.account.id }` }
						refreshClientData={ this.props?.refreshClientData }
					/>
				</Col>
			);
		}

		return "";
	};

	render () {
		const {
			enums,
			partner,
			isBackOfficeOrTechSupport,
			refreshClientData,
		} = this.props;
		const translate = this.translate;

		return (
			<Row>
				<Col sm="4">
					<PartnerInformation
						enums={ enums.data }
						isBackOfficeOrTechSupport={ isBackOfficeOrTechSupport }
						partner={ partner }
						translate={ translate }
						refreshClientData = {refreshClientData}
					/>
				</Col>
				{this.renderCommentsCustomerData()}
				<Col sm="4">
					<PartnerProgramm
						enums={ enums }
						isBackOfficeOrTechSupport={ isBackOfficeOrTechSupport }
						partner={ partner }
						refreshClientData={ refreshClientData }
						translate={ translate }
					/>
				</Col>
			</Row>
		);
	}
}