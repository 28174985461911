import React, { Component } from 'react';
import RangeSlider from "./RangeSlider";
import { Scrollbars } from "react-custom-scrollbars";
import { withLocalize } from "react-localize-redux";
import { map, isUndefined, isNull } from 'lodash';
import classnames from 'classnames';
import DayRiskItem from './DayRiskItem';
import moment from "moment";

const DATE_FORMAT_SHORT = 'DD.MM.YYYY';

class RiskManagerParametrs extends Component {
    constructor (props) {
        super(props);
    }

    isPlatformMT5 = () => this.props?.platform?.toString() === '2'; // AccountBalancesPlatformEnum

    isMt5GroupTypeNET = () => this.props?.mt5GroupType?.toString() === 'NET'; // Mt5GroupTypeEnum

    isMt5GroupTypeHEDGE = () => this.props?.mt5GroupType?.toString() === 'HEDGE'; // Mt5GroupTypeEnum

    dayProfitRisk = (translate) => {
        const { risksServerData = {}, riskAppData = {}, symbol } = this.props;
        const { risk_params = {}, risk_type } = risksServerData;
        const { day_profit_risk: maxValArray = [] } = risk_params;
        const { response = {} } = riskAppData;
        const { day_profit_risk: currentValArray = [], day_max_profit, day_max_T } = response;

        return map(maxValArray, (item, key) => {
            if (maxValArray.length !== 0) {
                return (
                    <DayRiskItem
                        translate={translate}
                        index={key}
                        key={key}
                        item={item}
                        currentValue={currentValArray[key] ? currentValArray[key] : 0}
                        maxValue={maxValArray[key].percent}
                        day_profit_risk={maxValArray}
                        day_max_profit={day_max_profit}
                        day_max_T={day_max_T}
                        risk_type={risk_type}
                        symbol={symbol}
                    />
                );
            } else {
                return (
                    <h5 className='inactive_parameter'>{translate(`list_accounts_risk_inactive_parameter`)}</h5>
                );
            }
        });
    };

    getValueFromProps = (fieldName, mode) => {
        const { risksServerData = {}, riskAppData = {} } = this.props;
        const { risk_params: maxValObj = {}, risk_type } = risksServerData;
        const { response: curValObj } = riskAppData;
        const defaultFieldValue = null;

        let fieldValue = defaultFieldValue;
        const targetValObj = mode === 'max' ? maxValObj : mode === 'cur' ? curValObj : {};

        try {
            switch (fieldName) {
                case 'risk_type': {
                    fieldValue = !isNaN(+risk_type) ? +risk_type : risk_type;
                    break;
                }
                case 'order_risk':
                case 'trade_losing_from':
                case 'day_row_risk':
                case 'week_row_risk':
                case 'day_risk':
                case 'week_risk':
                case 'month_risk': {
                    fieldValue = !isNaN(+targetValObj[fieldName]) ? +targetValObj[fieldName] : targetValObj[fieldName];
                    break;
                }
                default: {
                    fieldValue = defaultFieldValue;
                    break;
                }
            }
        } catch (error) {
            fieldValue = defaultFieldValue;
        }

        return fieldValue;
    };

    renderBlock = (fieldName, stylesObj) => {
        const { translate, tradingAccountType, risksServerData = {}, symbol } = this.props;
        const { invoice_sending = 0, risk_params = {} } = risksServerData;
        const { locking_risk = 'false' } = risk_params;
        const riskType = this.getValueFromProps('risk_type');
        const isPlatformMT5 = this.isPlatformMT5();
        const isMt5GroupTypeNET = this.isMt5GroupTypeNET();

        /*const TradingAccountTypeEnum = {
            "2": "Tima_manager",
            "5": "Trading",
            "6": "Demo",
            "7": "Demo_contest",
            "8": "Demo_risk",
            "10": "Demo_m",
        };*/

        /*const RiskTypeEnum = {
            "0": "Валютный",
            "1": "Процентный",
            "2": "TIMA",
            "-1": "Удален"
        };*/

        const prefix = 'list_accounts_risk_manager';
        const translateMap = {
            'risk_type':         `${prefix}_type`,
            'order_risk':        isPlatformMT5 ? `${prefix}_position_risk_mt5` : `${prefix}_order_risk`,
            'trade_losing_from': isPlatformMT5 ? `${prefix}_trade_losing_from_mt5` : `${prefix}_trade_losing_from`,
            'day_row_risk':      isPlatformMT5 ? `${prefix}_max_losing_orders_per_day_mt5` : `${prefix}_max_losing_orders_per_day`,
            'week_row_risk':     isPlatformMT5 ? `${prefix}_max_losing_orders_per_week_mt5` : `${prefix}_max_losing_orders_per_week`,
            'day_risk':          `${prefix}_daily_risk`,
            'week_risk':         `${prefix}_weekly_risk`,
            'month_risk':        `${prefix}_monthly_risk`,
        };

        const maxVal = this.getValueFromProps(fieldName, 'max');
        const curVal = this.getValueFromProps(fieldName, 'cur');

        switch (fieldName) {
            case 'risk_type': {
                return (
                    <div className='block' style={stylesObj}>
                        <div className='block-header'>
                            <span className='block-name'>
                                {translate(translateMap[fieldName])}
                            </span>
                            <span
                                className='block-info'>{this.props.enums.core.RiskTypeEnum[curVal]}</span>
                        </div>
                    </div>
                );
            }
            case 'order_risk': {
                // если по МТ5 счету в поле "Система учета позиций" указана опция "Net", тогда блока с полем "Риск на позицию" не должно быть
                if (isPlatformMT5 && isMt5GroupTypeNET) {
                    return null;
                }

                return ['0', '1'].includes(riskType?.toString()) ?
                    (
                        <div className='block' style={stylesObj}>
                            <div className='block-header'>
                                <span className='block-name'>
                                    {translate(translateMap[fieldName])}
                                </span>

                                {!isUndefined(maxVal) ?
                                    (
                                        <span className='block-info'>{`${maxVal}`} {symbol}</span>
                                    ) :
                                    <h5 style={{ 'marginTop': '0' }}
                                        className='inactive_parameter'>{translate(`list_accounts_risk_inactive_parameter`)}</h5>
                                }
                            </div>
                        </div>

                    ) : false;
            }
            case 'trade_losing_from': {
                return riskType === 0 && maxVal > 0 ?
                    (
                        <div className='block' style={stylesObj}>
                            <div className='block-header'>
                                <span className='block-name'>
                                    {translate(translateMap[fieldName])}
                                </span>

                                {!isUndefined(maxVal) ?
                                    (
                                        <span className='block-info'>{`${maxVal}`} {symbol}</span>
                                    ) :
                                    <h5 style={{ 'marginTop': '0' }}
                                        className='inactive_parameter'>{translate(`list_accounts_risk_inactive_parameter`)}</h5>
                                }
                            </div>
                        </div>
                    ) : false;
            }
            case 'day_row_risk':
            case 'week_row_risk': {
                const ABS_ZERO = 0;
                const MIN_VAL = 1;
                const sliderContClass = curVal < MIN_VAL ? 'slider-cont--hidden' : '';

                return (
                    <div className='block' style={stylesObj}>
                        <div className='block-header'>
                            <span className='block-name'>
                                {translate(translateMap[fieldName])}
                            </span>
                        </div>
                        <div className='block-content'>
                            {
                                !isUndefined(maxVal) ?
                                    <RangeSlider
                                        sliderId={`slider-${fieldName}`}
                                        minValue={ABS_ZERO}
                                        maxValue={maxVal}
                                        initialValue={curVal}
                                        step={MIN_VAL}
                                        sliderContClass={sliderContClass}
                                    />
                                    :
                                    <h5 className='inactive_parameter'>{translate(`list_accounts_risk_inactive_parameter`)}</h5>
                            }
                        </div>
                    </div>
                );
            }

            case 'day_risk': {
                const ABS_ZERO = 0;
                const MIN_VAL = 0.01;
                const sliderContClass = curVal >= 0 ? 'slider-cont--hidden' : '';
                const currentDate = moment();

                return (
                    <div className='block' style={stylesObj}>
                        <div className='block-header'>
                            <span className='block-name'>
                                {translate(translateMap[fieldName])}
                            </span>
                            {
                                !isUndefined(maxVal) ?
                                    <span className='block-info'>{currentDate.format(DATE_FORMAT_SHORT)}</span>
                                    :
                                    ''
                            }
                        </div>
                        <div className='block-content'>
                            {
                                !isUndefined(maxVal) ?
                                    <RangeSlider
                                        sliderId={`slider-${fieldName}`}
                                        minValue={ABS_ZERO}
                                        maxValue={maxVal}
                                        initialValue={Math.abs(parseFloat(curVal))}
                                        step={MIN_VAL}
                                        sliderContClass={sliderContClass}
                                        symbol={symbol}
                                    />
                                    :
                                    <h5 className='inactive_parameter'>{translate(`list_accounts_risk_inactive_parameter`)}</h5>
                            }
                        </div>
                    </div>
                );
            }

            case 'week_risk':
            case 'month_risk': {
                const ABS_ZERO = 0;
                const MIN_VAL = 0.01;
                const sliderContClass = curVal >= 0 ? 'slider-cont--hidden' : '';

                const currentDate = moment();
                const currentDayNumber = currentDate.day();
                const lastSaturday = currentDayNumber === 6 ? currentDate : moment().day(-1);
                const nextSaturday = moment().day(6);
                const firstDayInMonth = moment().date(1);
                const firstDayInNextMonth = moment().add(1, 'months').startOf('month');

                const fromDate = fieldName === 'week_risk' ? lastSaturday : firstDayInMonth;
                const toDate = fieldName === 'week_risk' ? nextSaturday : firstDayInNextMonth;

                return (
                    <div className='block' style={stylesObj}>
                        <div className='block-header'>
                            <span className='block-name'>
                                {translate(translateMap[fieldName])}
                            </span>
                            {
                                !isUndefined(maxVal) ?
                                    <span className='block-info'>
                                        {translate('list_accounts_risk_from2')} {fromDate.format(DATE_FORMAT_SHORT)} {translate('list_accounts_risk_to2')} {toDate.format(DATE_FORMAT_SHORT)}
                                    </span>
                                    :
                                    ''
                            }
                        </div>
                        <div className='block-content'>
                            {
                                !isUndefined(maxVal) ?
                                    <RangeSlider
                                        sliderId={`slider-${fieldName}`}
                                        minValue={ABS_ZERO}
                                        maxValue={maxVal}
                                        initialValue={Math.abs(parseFloat(curVal))}
                                        step={MIN_VAL}
                                        sliderContClass={sliderContClass}
                                        symbol={symbol}
                                    />
                                    :
                                    <h5 className='inactive_parameter'>{translate(`list_accounts_risk_inactive_parameter`)}</h5>
                            }
                        </div>
                    </div>
                );
            }
            case 'locking_risk': {
                return !isPlatformMT5 ? (
                    <div className='block'>
                        <div className='block-header'>
                            <span
                                className={`block-name status ${classnames({ active: !isUndefined(locking_risk) && !isNull(locking_risk) && (String(locking_risk) === "true") })}`}>
                                {translate(`list_accounts_risk_manager_location`)}
                            </span>
                        </div>
                    </div>
                ) : null;
            }
            case 'invoice_sending': {
                return !['2'].includes(riskType?.toString()) ? (
                    <div className='block'>
                        <div className='block-header'>
                            <span
                                className={`block-name status ${classnames({ active: !isUndefined(invoice_sending) && !isNull(invoice_sending) && invoice_sending === 1 })}`}>
                                {translate(`list_accounts_risk_manager_prohibit_sending_invoices`)}
                            </span>
                        </div>
                    </div>
                ) : false;

            }

            default: {
                return false;
            }
        }
    };

    render () {
        const { translate, riskAppData = {}, risksServerData = {}, symbol } = this.props;
        const { invoice_sending = 0, risk_params = {} } = risksServerData;
        const { locking_risk = 'false', day_profit_risk } = risk_params;

        if (!riskAppData.response) {
            return (
                <div className='title-cont title-cont--error'>
                    {/* TODO: INSERT VALID ERROR REASON TEXT !!!  */}
                    <p className='title'>
                        {translate('list_accounts_risk_rm_server_unavailable_text')}
                    </p>
                </div>
            );
        }

        return (
            <React.Fragment>
                <div className='information-conts'>
                    <div className='information-cont'>
                        <div className='blocks-cont'>
                            {this.renderBlock('risk_type', {})}
                            {this.renderBlock('order_risk', {})}
                        </div>

                        <div className='blocks-cont'>
                            {this.renderBlock('trade_losing_from', {})}
                            {this.renderBlock('day_row_risk', { padding: '34px 20px' })}
                            {this.renderBlock('week_row_risk', { padding: '34px 20px' })}
                        </div>
                    </div>

                    <div className='information-cont'>
                        <div className='blocks-cont'>
                            {this.renderBlock('day_risk', { padding: '30px 20px' })}
                            {this.renderBlock('week_risk', { padding: '37px 20px' })}
                            {this.renderBlock('month_risk', { padding: '34px 20px' })}
                        </div>
                    </div>

                    <div className='information-cont'>
                        <div className='blocks-cont'>
                            <div className='block'>
                                <div className='block-header'>
                                    <span className='block-name'>
                                        {translate(`list_accounts_risk_manager_daily_profit_risk`)}
                                    </span>
                                </div>
                            </div>

                            <div className='block daily-profit-risk-header-block bg-creamy'>
                                <div className='block-header full-cont'>
                                    <span className='block-name cont'>
                                        {translate(`list_accounts_risk_manager_profitability`)} {symbol}
                                    </span>
                                    <span className='block-name cont'>
                                        {translate(`list_accounts_risk_manager_risk`)} %
                                    </span>
                                </div>
                            </div>


                            <Scrollbars
                                style={{ height: 270 }}>
                                <div className='daily-profit-risk-blocks'>
                                    {
                                        !isUndefined(day_profit_risk) ?
                                            this.dayProfitRisk(translate)
                                            :
                                            <h5 className='inactive_parameter'
                                                style={{ 'padding': '0px 20px' }}>{translate(`list_accounts_risk_inactive_parameter`)}</h5>
                                    }
                                </div>
                            </Scrollbars>

                        </div>
                    </div>
                </div>

                <div className='information-conts'>
                    <div className='information-cont'>
                        <div className='blocks-cont additional-settings-blocks'>
                            {this.renderBlock('locking_risk', {})}
                        </div>
                    </div>

                    <div className='information-cont'>
                        <div className='blocks-cont'>
                            {this.renderBlock('invoice_sending', {})}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default withLocalize(RiskManagerParametrs);
