import React, { Component, } from "react";
import { Popup, } from "../../../../components";
import {Input} from "../../../../components";

export default class PayoutPercentages extends Component {

	get conditionIsCPA (){ 
		return this.props.data.condition.type === 1;
	}

	get conditionIsCPL (){ 
		return this.props.data.condition.type === 2;
	}

	get conditionIsInOut(){ 
		return this.props.data.condition.type === 3;
	}

	get conditionIsRevshare (){ 
		return this.props.data.condition.type === 4;
	}

	renderPayoutRangeList = (type) => {
		const {
		  translate,
		  data,
		} = this.props;
		
		let paymentCoef;
		let conditionKey;
		let columnTitle;

		switch(type){ 
			case('CPA'):{
				paymentCoef = data.payment_coef_cpa;
				conditionKey = 'deposits_from';
				columnTitle = translate("new_partners_condition_fixation_period_count");
				break
			}
			case('CPL'):{
				paymentCoef = data.payment_coef_cpl;
				conditionKey = 'leads_from';
				columnTitle = translate("new_partners_condition_lead_count");
				break
			}
			case('IN_OUT'):{
				paymentCoef = data.payment_coef_inout;
				conditionKey = 'sum_inout_from';
				columnTitle = translate("new_partners_condition_sum_in_out");
				break
			}
		}

		paymentCoef = Array.isArray(paymentCoef) ? paymentCoef : [paymentCoef];

		return (
		  <div className='payout-rate-list__payout_table-block custom-horizontal-scroll pb-20'>
			<div className='payout-rate-list__payout_table-forbidden  '>
			  <div className='payout-rate-list__payout_table-range-items'>
				<div className='payout-rate-list__payout_table-title-bg p0'>
				  <p className='payout-rate-list__payout_table-title'>
					{columnTitle}
				  </p>
				</div>
				<div className='payout-rate-list__payout_table-title-bg p0'>
				  <p className='payout-rate-list__payout_table-title'>
					{translate("new_partners_condition_payout_percentage_input")}
				  </p>
				</div>
			  </div>
			  {paymentCoef?.map((sum, index) => {
				return (
					<div key={index} className='payout-rate-list__payout_table-range-items'>
					<Input
					  name={`deposits_from${index}`}
					  wrapperClassName={`payout-rate-list__payout_table-input`}
					  placeholder={translate("new_partners_condition_enter_amount_placeholder")}
					  value={`${sum[conditionKey]}`}
					  disabled={true}
					/>
					<Input
					  name={`coef${index}`}
					  wrapperClassName={`payout-rate-list__payout_table-input`}
					  placeholder={translate("new_partners_condition_grace_period_payment_placeholder")}
					  value={`${sum.coef}`}
					  disabled={true}
					/>
				  </div>
				)
			  })}
			</div>
		  </div>
	
		);
	  };
	  
	  renderRegistrationClients(){ 
		const {
			translate,
			data,
		  } = this.props;
		  
		  const payoutRateRevshare = Array.isArray(data.payment_coef_revshare) ? data.payment_coef_revshare : [data.payment_coef_revshare] ;

		  return (
			<div className='payout-rate-list__payout_table-block payout-rate-list_registration pb-20 y-scroll'>
				<div className='payout-rate-list__payout_table-forbidden  '>
					<div className='payout-rate-list__payout_table-range-items'>
						<div className='new-partners-payout-rate-list__title p0'>
							<p className='payout-rate-list__payout_table-title'>
								{translate("new_partners_condition_registration_from")}
							</p>
						</div>
						<div className='new-partners-payout-rate-list__title p0'>
							<p className='payout-rate-list__payout_table-title'>
								{translate("new_partners_condition_verification_from")}
							</p>
						</div>
						<div className='new-partners-payout-rate-list__title p0'>
							<p className='payout-rate-list__payout_table-title'>
								{translate("new_partners_condition_active_clients_from")}
							</p>
						</div>
						<div className='new-partners-payout-rate-list__title p0'>
							<p className='payout-rate-list__payout_table-title'>
								{translate("new_partners_condition_new_active_clients_from")}
							</p>
						</div>
						<div className='new-partners-payout-rate-list__title p0'>
							<p className='payout-rate-list__payout_table-title'>
								{translate("new_partners_condition_payout_percentage_input")}
							</p>
						</div>
					</div>
					{payoutRateRevshare?.map((sum, index) => {
						return (
							<div key={index} className='payout-rate-list__payout_table-range-items'>
								<Input
									name={`registrations_${index}`}
									wrapperClassName={`payout-rate-list__payout_table-input`}
									placeholder={translate("new_partners_condition_enter_amount_placeholder")}
									value={`${sum.registrations}`}
									disabled={true}
								/>
								<Input
									name={`verifications_${index}`}
									wrapperClassName={`payout-rate-list__payout_table-input`}
									placeholder={translate("new_partners_condition_enter_amount_placeholder")}
									value={`${sum.verifications}`}
									disabled={true}
								/>
								<Input
									name={`active_clients_${index}`}
									wrapperClassName={`payout-rate-list__payout_table-input`}
									placeholder={translate("new_partners_condition_enter_amount_placeholder")}
									value={`${sum.active_clients}`}
									disabled={true}
								/>
								<Input
									name={`new_active_clients_${index}`}
									wrapperClassName={`payout-rate-list__payout_table-input`}
									placeholder={translate("new_partners_condition_enter_amount_placeholder")}
									value={`${sum.new_active_clients}`}
									disabled={true}
								/>
								<Input
									name={`coef_${index}`}
									wrapperClassName={`payout-rate-list__payout_table-input`}
									placeholder={translate("new_partners_condition_grace_period_payment_placeholder")}
									value={`${sum.coef}`}
									disabled={true}
								/>
							</div>
						)
					})}
				</div>
			</div>
		);
	};

	renderExtraRevshareConditions = () => {
		const {
			translate,
			data,
		} = this.props;
		
		const extra_revshare_conditions = data.extra_revshare_conditions

		return (
			<>
				<div className='payout-rate-list__payout_table-block custom-horizontal-scroll pb-5 mb-3 '>
				<div className='payout-rate-list__payout_table-forbidden  '>
					<div className='payout-rate-list__payout_table-range-items'>
						<div className='new-partners-payout-rate-list__title p0'>
							<p className='payout-rate-list__payout_table-title'>
								{ translate("new_partners_condition_grace_period_days") }
							</p>
						</div>
						<div className='new-partners-payout-rate-list__title p0'>
							<p className='payout-rate-list__payout_table-title'>
								{translate("new_partners_condition_grace_period_payment")}
							</p>
						</div>
						<div className='new-partners-payout-rate-list__title p0'>
							<p className='payout-rate-list__payout_table-title'>
								{ translate("new_partners_condition_fullfillment_period_days")}
							</p>
						</div>
					</div>
					<div  className='payout-rate-list__payout_table-range-items'>
				</div>
				<div className='payout-rate-list__payout_table-range-items'>
					<Input
						className={ "field__input" }
						disabled={ true }
						name="grace_period"
						placeholder={ translate(
							"new_partners_condition_grace_period_days_placeholder",
						) }
						value={ `${extra_revshare_conditions.grace_period}` }
						wrapperClassName={ `payout-rate-list__payout_table-input` }
					/>
					<Input
						className={ "field__input" }
						disabled={true}
						name="payout_rate_for_grace_period"
						placeholder={ translate(
							"new_partners_condition_grace_period_payment_placeholder",
						) }
						value={extra_revshare_conditions.payout_rate_for_grace_period}
						wrapperClassName={ `payout-rate-list__payout_table-input` }
					/>
					<Input
						className={ "field__input" }
						disabled={ true }
						name="min_req_fullfillment_period"
						placeholder={translate(
							"new_partners_condition_fullfillment_period_days_placeholder",
						)}
						value={extra_revshare_conditions.min_req_fullfillment_period}
						wrapperClassName={`payout-rate-list__payout_table-input`}
					/>
					</div>
				</div>
			</div>
		</>)
	}

	renderPayoutRangeListRevshare(){
		return(
		<>
			{this.renderExtraRevshareConditions()}
			{this.renderRegistrationClients()}
		</>
		)
	}
	render () {
		const {translate, onClose} = this.props;

		return (
			<Popup
				wrapperClassname={`affiliate-percantages`}
				isImage={ false }
				size={ "md" }
				title={ translate("partners_payouts_percentage") }
				onClose={ onClose }
				showPopUp = {true}
			>
				{this.conditionIsCPA && this.renderPayoutRangeList('CPA')}
				{this.conditionIsCPL && this.renderPayoutRangeList('CPL')}
				{this.conditionIsInOut && this.renderPayoutRangeList('IN_OUT')}
				{this.conditionIsRevshare && this.renderPayoutRangeListRevshare()}
			</Popup>
		);
	}
}