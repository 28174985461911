import React from "react";
import { withLocalize } from "react-localize-redux";
import { MagicTable } from '../../../../../components/Magic';
import { MagicTooltip } from '../../../../tima/components/Magic/MagicTooltip';
import PropTypes from "prop-types";
import { ACTIVE, NOT_ACTIVE, TABID_0 } from "../constants";
import ModalBTNTable from "./ModalBTNTable";
import Preloader from "../../../../../components/LoadingHOC/Preloader";
import { getLanguages } from '../../../../../modules/common/services/LocalisationService';
class TemplateMagicTable extends React.Component {
    static propTypes = {
        data: PropTypes.array,
    };

    static defaultProps = {
        data: [],
    };

    get mf() {
        return this.props.mf;
    }

    get smsTableConfig() {
        const { translate, } = this.props;
        return [
            {
                path: ['core', 'sms_templates', 'created_at'],
                key: 'templateCreated',
                render: this.renderTemplateDate,
                title: 'template_management_created_at',
                orderId: 'o:templatesCreated',
                order: true,
            },
            {
                path: ['core', 'sms_templates', 'name'],
                key: 'templateName',
                render: this.renderTemplateText,
                title: 'template_management_template_name',
                orderId: 'o:templatesName',
                order: true,
            },
            {
                path: ['core', 'sms_template_translation'],
                key: 'templateLanguage',
                render: this.renderTemplateLanguage,
                title: 'template_management_language',
            },
            {
                path: ['core', 'sms_templates', 'description'],
                key: 'templatesDescription',
                render: this.renderTemplateText,
                title: 'template_management_description',
                orderId: 'o:templatesDescription',
                order: true,
            },
            {
                path: ['core', 'sms_templates', 'active'],
                key: 'templatesStatus',
                render: this.renderTemplateStatus,
                title: 'template_management_status',
                orderId: 'o:templatesStatus',
                order: true,
            },
            {
                path: ['core', 'sms_templates', 'id'],
                key: 'templatesActions',
                render: this.renderTemplateActions,
                title: 'template_management_action',
                order: true,
            },
        ]
            .map(({
                orderId: o,
                title: t,
                xtitle: x,
                ...item
            }) => ({
                ...item,
                ...o && {
                    order: async () => {
                        await this.mf.orderedToNext(o);
                    },
                    orderTo: this.mf.orderedTo(o),
                },
                ...t && { title: translate(t), },
                ...x && { title: x, },
            }));
    }

    get mailTableConfig() {
        const { translate, } = this.props;
        return [
            {
                path: ['core', 'mail_templates', 'created_at'],
                key: 'templateCreated',
                render: this.renderTemplateDate,
                title: 'template_management_created_at',
                orderId: 'o:templatesCreated',
                order: true,
            },
            {
                path: ['core', 'mail_templates', 'name'],
                key: 'templateName',
                render: this.renderTemplateText,
                title: 'template_management_template_name',
                orderId: 'o:templatesName',
                order: true,
            },
            {
                path: ['core', 'mail_template_translation'],
                key: 'templateLanguage',
                render: this.renderTemplateLanguage,
                title: 'template_management_language',
            },
            {
                path: ['core', 'mail_templates', 'description'],
                key: 'templatesDescription',
                render: this.renderTemplateText,
                title: 'template_management_description',
                orderId: 'o:templatesDescription',
                order: true,
            },
            {
                path: ['core', 'mail_templates', 'active'],
                key: 'templatesStatus',
                render: this.renderTemplateStatus,
                title: 'template_management_status',
                orderId: 'o:templatesStatus',
                order: true,
            },
            {
                path: ['core', 'mail_templates', 'id'],
                key: 'templatesActions',
                render: this.renderTemplateActions,
                title: 'template_management_action',
                order: false,
            }
        ]
            .map(({
                orderId: o,
                title: t,
                xtitle: x,
                ...item
            }) => ({
                ...item,
                ...o && {
                    order: async () => {
                        await this.mf.orderedToNext(o);
                    },
                    orderTo: this.mf.orderedTo(o),
                },
                ...t && { title: translate(t), },
                ...x && { title: x, },
            }));
    }


    save = async (state) => new Promise(next => this.setState(state, next));

    componentDidMount = async () => {
        const languages = await getLanguages();

        this.save({
            languages,
        })
    }

    renderTemplateDate = (value, { item }) => {
        if (item.access('index') && item.access('show')) {
            return value ?? "-";
        }

        return '-';
    };

    renderTemplateText = (value, { item }) => {
        if (item.access('index') && item.access('show')) {
            const LENGTH_SHORT = 20;
            return (
                <MagicTooltip
                    classNameTooltip={`tooltip tooltip--large-width`}
                    content={value ?? '-'}
                    lengthCut={LENGTH_SHORT}
                />
            );
        }

        return '-';
    };

    renderTemplateLanguage = (value, { item }) => {
        if (item.access('index') && item.access('show')) {
            if (!value) return '-'
            const allLanguages = value.map(language => {
                const languageValue = Object.values(this.state.languages).find(value => +value.id === +language.language_id);
                return languageValue.name;
            });
            return <span>{allLanguages.join(', ')}</span>
        }

        return '-';
    };

    renderTemplateStatus = (value, { item }) => {
        if (item.access('index') && item.access('show')) {
            const curObj = value === ACTIVE ? 'active' : value === NOT_ACTIVE ? 'inactive' : '';
            const curTitle = value === ACTIVE ? this.props.enums?.[ACTIVE] : value === NOT_ACTIVE ? this.props.enums?.[NOT_ACTIVE] : '';

            return <div className={`status-active ${curObj}`}>{curTitle}</div>;
        }

        return '-';
    };

    renderTemplateActions = (props) => {
        return <ModalBTNTable
            id_template={props}
            togglePopUpTemplateManagement={this.props.togglePopUpTemplateManagement}
            togglePopup={this.props.togglePopup}
            deleteTemplate={this.props.deleteTemplate}
            tabId={this.props.templateName}
        />;
    };

    render() {
        const options = {
            data: this.props?.data,
            head: [
                'templateCreated',
                'templateName',
                'templateLanguage',
                'templatesDescription',
                'templatesStatus',
                'templatesActions'
            ],
        };
        if (!this.props?.loaded) {
            return <Preloader />;
        }

        if (this.props?.templateName === TABID_0) {
            const smsOptions = {
                ...options,
                config: this.smsTableConfig,
            };

            return (<MagicTable {...smsOptions} />);
        } else {
            const mailOptions = {
                ...options,
                config: this.mailTableConfig,
            };

            return (<MagicTable {...mailOptions} />);
        }
    }
}

export default withLocalize(TemplateMagicTable);
