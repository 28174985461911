import React from 'react';
import { withLocalize, Translate, } from 'react-localize-redux';
//images and style
import '../../../../style/NewLogin.scss';
import logoCrm from '../../../../images/logo.svg';
import loginIphone from '../../../../images/i-phone-x.png';

//components
import LoginForm from "./LoginForm";
import LangSelect from "../Frame/LangSelect";
import ResetPassword from "./ResetPassword";

class AuthPage extends React.Component {
	constructor (props) {
		super(props);
		this.state = {
			forgotPassword: false,
		};
		this.renderForm = this.renderForm.bind(this);
		this.toggleForgotPassword = this.toggleForgotPassword.bind(this);
	}

	toggleForgotPassword () {
		this.setState({
			forgotPassword: !this.state.forgotPassword,
		});
	}

	renderForm () {
		if (!this.state.forgotPassword) {
			return (<LoginForm
				checkAuthorization={ this.props?.checkAuthorization }
			/>);
		}

        // return <ResetPassword toggleFrogotPassword = { this.toggleForgotPassword } />;

	}

	renderFormTitle () {
		if (!this.state.forgotPassword) {
			return (
				<Translate id="log_in" >
					Log in
				</Translate>
			);
		}

		return (
			<Translate>
				{({ translate, }) => {
					return translate(`reset`);
				}}
			</Translate>
		);
	}

	render () {
		return (
			<div className="login-wrapper">
				<div className="login-container">
					<div className="login-img-logo">
						<img alt="logo" src={ logoCrm } />
					</div>

					<h2 className="light-grey-text text-center text-upper">{ this.renderFormTitle()}</h2>
					{ this.renderForm() }
				</div>
				<div className="login-img">
					<div className="login-img-text">
						<h1 className="login-img-title">
							<Translate id="auth_title" >
								Intelligent Solution for Your Business
							</Translate>
						</h1>
						<div className="login-select">
							{<LangSelect
								curLang={ this.props.activeLanguage }
							/>}
						</div>
					</div>
					<img alt="login" src={ loginIphone } />
				</div>
			</div>
		);
	}
}

export default withLocalize(AuthPage);