export const CONTESTS_EXPORT_FIELDS = {
  contests: [
    'contests-contests-id',
    'contests-contests-start_reg_date',
    'contests-contests-finish_reg_date',
    'contests-contests-date_start',
    'contests-contests-date_end',
    'contests-contests-prize_fund',
    'contests-contests-prize_count',
    'contests-contests_to_participants_categories->array->contests-contests_to_participants_categories-account_category',
    'contests-contests-status',
  ],
  listMembers: [
    'contests-contests_members-place',
    'core-account-id',
    'core-account-name',
    'core-account-surname',
    'core-account-contest_nickname',
    'contests-contests_members-status',
    'core-trading_accounts-mt_login',
    'contests-contests_statistic-balance',
    'contests-contests_statistic-equity',
    'contests-contests_statistic-profitability',
    'contests-contests_statistic-loss',
    'contests-contests_statistic-max_loss',
    'contests-contests_members-created_at',
  ],
};
