import React, {Component, createRef,} from "react";
import { withLocalize, } from "react-localize-redux";

import { tradingServers } from "../../consts/consts";
import { Input, Select, } from "../../../../../../components";
import { setErrorClass, findOptionByValue } from "../../../../../../utils/helpers";

import { onValidPercantages, } from "../../helpers/validation-helpers/onValidHelpers";
class RenumerationRatesRevshare extends Component {
	constructor (props) {
		super(props);
		this.myP = createRef();
		this.ref = createRef();
		this.state = {
			formData: {},
			accountListOptions: [],
			currentIndex: null,
			symbolListOptions: [],
			ratesRevshare: [],
			elementRefs: [],
		};
	}

	get dealsOptions(){
		return Object.entries(this.props.enums.data.partners.PayoutRatesRevshareDealsEnum).map((entry) => {
			const [key, value] = entry;

			return {label: value, value: key}
		})
	}

	save = async state => new Promise(next => this.setState(state, next));

	componentDidMount = async () => {
		try {
			const { accountGroupListsOptions, symbolOptions, ratesRevshare, } = this.props;
			const filteredSymbOp = symbolOptions?.reduce((acc, item) => {
				acc[item.server_name] ? acc[item.server_name].push(item) : acc[item.server_name] = [item]
				return acc;
			}, {})

			await this.save(() => {
				return {
					accountListOptions: accountGroupListsOptions,
					symbolListOptions: symbolOptions,
					ratesRevshare,
					filteredSymbOp,
				};
			});
		} catch (e) {
			console.log(e)
		}

	};
	componentDidUpdate(prevProps) {
		// Check if the items array has changed
		if (prevProps.ratesRevshare !== this.props.ratesRevshare) {
			this.updateItemRefs();
		}
	}
	updateItemRefs = () => {
		this.setState({
			elementRefs: this.props.ratesRevshare?.map(() => createRef()),
		});
	};
	removeItem = (currentIndex) => {
		if (currentIndex > 3) {

			const previousIndex = currentIndex - 1;
			const parentElement = this.state.elementRefs[previousIndex].current.scrollIntoView({
				behavior: "smooth",
				block: "end",
			});
		} else {
			this.ref.current?.scrollIntoView({
				behavior: "smooth",
				block: "end",
			})
		}
	};

	adjustAccountListOptions = () => {
		const { accountGroupListsOptions, ratesRevshare } = this.props;

		const {filteredSymbOp } = this.state;

		if(!filteredSymbOp) return []

		return accountGroupListsOptions.filter((option) => {
			return ratesRevshare?.filter(item => item.groups_list_id === option.value)?.length < filteredSymbOp[option?.server_name]?.length;
		});

	};

	handleOnChange = (index, fieldName, groupError) => async (event) => {
		const { setFieldData, } = this.props;
		const { value, } = event.target;

		try {
			switch (fieldName) {
				case "groups_list_id": {
					await setFieldData(fieldName, value.value, index);
					break;
				}
				case "symbols_list_id": {
					await setFieldData(fieldName, value.value, index);
					break;
				}
				case "reward_by_point_lot": {
					await setFieldData(fieldName, value, index, groupError);
					break;
				}
				case "reward_by_volume_lot": {
					await setFieldData(fieldName, value, index, groupError);
					break;
				}
				case "reward_percent_of_commission": {
					await setFieldData(fieldName, value, index, groupError);
					break;
				}
				case "deals": {
					await setFieldData(fieldName, value.value, index, groupError);
					break;
				}
				default: {
					break;
				}
			}
		} catch (e) {
			throw new Error(e);
		}
	};
	getAvailableSymboListOptions = (groups_list_id, index) => {
		const { ratesRevshare, } = this.props;
		const { accountGroupListsOptions, symbolOptions, } = this.props;
		const ratesByGroupId = ratesRevshare.filter((item) => item.groups_list_id === groups_list_id);


		const pickedAccountGroupListsOptionServerName = accountGroupListsOptions.find((option) =>  option.value === ratesRevshare[index]?.groups_list_id)?.server_name;

	 	return symbolOptions.filter(option => pickedAccountGroupListsOptionServerName?.includes(option.server_name) && !ratesByGroupId.find(rate => rate.symbols_list_id === option.value ));
	}

	setErrorClassGroup = (data = [], name = "", groupName = "") => {
		return data.includes(name) || data.includes(groupName) ? "error" : "";
	};

	checkIsDisabled = (index) => {
		const {ratesInDataBase, statusIsActive, statusIsClosed, isEditMode, isViewMode}= this.props;
		if(!isEditMode) return false

		return (index + 1 <= ratesInDataBase?.length && statusIsActive) || statusIsClosed || isViewMode //in active mode can delete and edit only rates that are not in DB
	}

	renderBlock = () => {
		const {
			translate,
			errors,
			removeCondition,
			accountGroupListsOptions,
			ratesRevshare,
			symbolOptions,
			serverCurrency,
		} = this.props;
		const {elementRefs} = this.state;

		const checkIsDisabled = this.checkIsDisabled;


		return (
			<div className="payout-rate-list__payout_table-blockRevshare custom-horizontal-scroll pb" >
				<div className="payout-rate-list__payout_table-forbidden mr-35">
					<div className="payout-rate-list__payout_table-range-items">
						<div className="new-partners-payout-rate-list__title p0">
							<p className="payout-rate-list__payout_table-title">
								{translate("new_partners_condition_account_list")}
							</p>
						</div>
						<div className="new-partners-payout-rate-list__title p0">
							<p className="payout-rate-list__payout_table-title">
								{translate("new_partners_condition_symbol_list")}
							</p>
						</div>
						<div className="new-partners-payout-rate-list__title p0">
							<p className="payout-rate-list__payout_table-title text-align-center">
								{translate("new_partners_condition_remuneration_per_lot")}
							</p>
						</div>
						<div className="new-partners-payout-rate-list__title p0">
							<p className="payout-rate-list__payout_table-title">
								{translate(
									"new_partners_condition_remuneration_currency_per_lot",
								).replace("{{currency}}", serverCurrency?.code)}
							</p>
						</div>
						<div className="new-partners-payout-rate-list__title p0">
							<p className="payout-rate-list__payout_table-title text-align-center">
								{translate("new_partners_condition_remuneration_commission")}
							</p>
						</div>
						<div className="new-partners-payout-rate-list__title p0">
							<p className="payout-rate-list__payout_table-title">
								{translate("new_partners_condition_deals")}
							</p>
						</div>
						<span ref={this.ref} />
					</div>

					{ratesRevshare?.map((condition, index) => {
						const groupListIsMT5 = accountGroupListsOptions.find(option => option.value === condition.groups_list_id)?.server_name?.includes(tradingServers.MT5)

						return (
							<div   className="first-deposit-conditions__row" key={ index } ref={elementRefs[index]} >
								{ ratesRevshare?.length > 1 && !checkIsDisabled(index)
									? <button
										className="payout-rate-list__create-btn_btns-btn payout-rate-list__create-btn_btns-btn-remove mt-20 rm-range-item"
										onClick={ () => removeCondition(index) &&  this.removeItem(index)}
									/>
									:null
									}

								<Select
									className={`${setErrorClass(
										errors,
										`groups_list_id_${index}`,
									)}`}
									hideLabel={true}
									isRequired={true}
									key={`account_group_${index}`}
									label=""
									value={findOptionByValue(accountGroupListsOptions, condition?.groups_list_id,)}
									name={`account_group_${index}`}
									options={this.adjustAccountListOptions(condition?.symbols_list_id)}
									onChange={this.handleOnChange(index, "groups_list_id")}
									placeholder={translate(
										"new_partners_condition_symbol_list_placeholder",
									)}
									disabled={checkIsDisabled(index)}
								/>
								<Select
									className={`${setErrorClass(
										errors,
										`symbols_list_id_${index}`,
									)} first-deposit-conditions__select`}
									hideLabel={true}
									isRequired={true}
									key={`symbol_group_list_${index}`}
									label=""
									name={`symbol_group_list_${index}`}
									options={this.getAvailableSymboListOptions(condition?.groups_list_id, index)}
									value={findOptionByValue(symbolOptions,condition?.symbols_list_id,)}
									onChange={this.handleOnChange(index, "symbols_list_id")}
									placeholder={translate(
										"new_partners_condition_symbol_list_placeholder",
									)}
									disabled={checkIsDisabled(index) || !condition?.groups_list_id}
								/>
								<Input
									isRequired={ true }
									key={ `reward_by_point_lot_${ index }` }
									name={ `reward_by_point_lot_${ index }` }
									placeholder={ "" }
									value={ `${condition.reward_by_point_lot ?? ''}` }
									wrapperClassName={  `mw ${ this.setErrorClassGroup(
										errors,
										`reward_by_point_lot_${ index }`,
										`remuneration_rates_${ index }`,
									) }` }
									onChange={ this.handleOnChange(
										index,
										"reward_by_point_lot",
										`remuneration_rates_${ index }`,
									) }
									onValid={ value => onValidPercantages(value, 1000) }
									disabled={checkIsDisabled(index)}
								/>
								<Input
									isRequired={ true }
									name={ `reward_by_volume_lot_${ index }` }
									placeholder={ "" }
									value={ `${condition.reward_by_volume_lot ?? ''}` }
									wrapperClassName={ `mw ${ this.setErrorClassGroup(
										errors,
										`reward_by_volume_lot_${ index }`,
										`remuneration_rates_${ index }`,
									) } ` }
									onChange={ this.handleOnChange(
										index,
										"reward_by_volume_lot",
										`remuneration_rates_${ index }`,
									) }
									onValid={ value => onValidPercantages(value, 1000, 0.0001) }
									disabled={checkIsDisabled(index)}
								/>
								<Input
									isRequired={ true }
									name={ `reward_percent_of_commission_${ index }` }
									placeholder={ "" }
									value={ `${condition.reward_percent_of_commission ?? ''}` }
									wrapperClassName={ `mw ${ this.setErrorClassGroup(
										errors,
										`reward_percent_of_commission_${ index }`,
										`remuneration_rates_${ index }`,
									) } ` }
									onChange={ this.handleOnChange(
										index,
										"reward_percent_of_commission",
										`remuneration_rates_${ index }`,
									) }
									onValid={ value => onValidPercantages(value, 100) }
									disabled={checkIsDisabled(index)}
								/>
								<Select
									className={`mw ${ setErrorClass(
										errors,
										`deals_${ index }`,
									) } ` }
									hideLabel={true}
									isRequired={true}
									key={`deals_${index}`}
									label=""
									name={`deals_${index}`}
									options={this.dealsOptions}
									value={findOptionByValue(this.dealsOptions, condition?.deals,)}
									onChange={this.handleOnChange(index, "deals", )}
									placeholder={translate("client_dropdown_placeholder",)}
									disabled={checkIsDisabled(index) || !groupListIsMT5}
								/>
							</div>
						);
					})}
					<span style={{marginTop: '15rem'}} ref={ this.myP } />
				</div>
			</div>
		);
	};

	render () {
		const {
			translate,
			createNewCondition,
			statusIsClosed,
			isViewMode
		} = this.props;

		const disabled = !this.adjustAccountListOptions().length ||
			 statusIsClosed || isViewMode
		const disabledClassName =  disabled ? `disabled` : '';

		return (
			<>
				<div className="payout-rate-list__payout_table-forbidden-title">
					<p className={ "payout-rate-list__payout_block-title" }>
						{translate("new_partners_condition_remuneration_rates")}
					</p>
					<button
						className={ `${disabledClassName } payout-rate-list__create-btn_btns-btn payout-rate-list__create-btn_btns-btn-join` }
						disabled={ disabled }
						onClick={ () => createNewCondition() && this.myP.current?.scrollIntoView({
							behavior: "smooth",
							block: "end",
						}) && updateItemRefs() }
					>
						{translate(`new_partners_condition_revshare_add_rate`)}
					</button>
				</div>
				<div className="payout-rate-list__payout_table custom-vertical-scroll">
					{this.renderBlock()}
				</div>
			</>
		);
	}
}

export default withLocalize(RenumerationRatesRevshare);