import { globalLang, } from './Global/global.locale';
import { authLang, } from './Auth/auth';
import { verticalMenuLang, } from "./VerticalMenu/VerticalMenu";
import { sideBarTaskListLang, } from './SideBarTaskList/sideBarTaskList';
import { managerPageLang, } from './ManagerPage/managerPage';
import { clientLang, } from './Clients/clients';
import { hierarchy, } from './Hierarchy/hierarchy';
import { topNavLang, } from './TopNav/topNav';
import { kpiLang, } from './Kpi/kpi';
import { adminKpiLang, } from './AdminKpi/adminKpi';
import { transactionsLang, } from './Transactions/transactions';
import { calendarLang, } from './Calendar/calendar';
import { tasksLang, } from './Tasks/tasks';
import { dashboardLang, } from './Dashboard/dashboard';
import { callstatisticsLang, } from './CallStatistics/callstatistics';
import { filtersLang, } from './Filters/filters';
import { notificationLang, } from './Notification/notification';
import { historyChanges, } from './HistoryChanges/historyChanges';
import { templateManagement, } from './TemplateManagement/templateManagement';
import { templatePermission, } from './TemplatePermission/templatePermission';
import { delegation, } from './Delegation/delegation';
import { listAccounts, } from './ListAccounts/listAccounts';
import { timaLang, } from './Tima/tima';
import { partnersLang, } from './Partners/partners';
import { listOfCrons }           from './ListOfCrons/listOfCrons';
import { magicLang, } from './Magic/magic';
import { contestsLang, } from './Contests/contests';
import { paymentsLang, } from './Payments/payments';
import { support, } from './Support/support';
import { searchLang, } from './Search/search';
import { autoCall, } from "./AutoCall/autoCall";
import { marketing, } from './Marketing/marketing';
import { conversationsLang, } from "./Conversations/Conversations";
import { adminActivityReport, } from "./AdminActivityReport/adminActivityReport";
import { bonusesLang } from "./Bonuses/bonuses";

export const allLang = {
	...bonusesLang,
	...globalLang,
	...authLang,
	...verticalMenuLang,
	...sideBarTaskListLang,
	...managerPageLang,
	...clientLang,
	...topNavLang,
	...kpiLang,
	...adminKpiLang,
	...transactionsLang,
	...calendarLang,
	...tasksLang,
	...dashboardLang,
	...callstatisticsLang,
	...filtersLang,
	...notificationLang,
	...historyChanges,
	...templateManagement,
	...templatePermission,
	...hierarchy,
	...listAccounts,
	...timaLang,
	...partnersLang,
	...delegation,
	...magicLang,
	...paymentsLang,
	...globalLang,
	...authLang,
	...verticalMenuLang,
	...sideBarTaskListLang,
	...managerPageLang,
	...clientLang,
	...topNavLang,
	...kpiLang,
	...adminKpiLang,
	...transactionsLang,
	...calendarLang,
	...tasksLang,
	...dashboardLang,
	...callstatisticsLang,
	...filtersLang,
	...notificationLang,
	...historyChanges,
	...templateManagement,
	...templatePermission,
	...hierarchy,
	...listAccounts,
	...timaLang,
	...partnersLang,
    ...listOfCrons,
	...delegation,
	...magicLang,
	...contestsLang,
	...support,
	...searchLang,
	...autoCall,
	...marketing,
    ...conversationsLang,
    ...adminActivityReport,
};