/* eslint-disable no-plusplus, no-param-reassign*/

const getCountryGroupsErrorsCPA = (state) => {
	return state.formData.country_groups.reduce((errors, group, index = 0) => {
		const payoutRanges = group.payoutRanges;
		const lastRangeIndex = payoutRanges.length - 1;

		if (
			payoutRanges[lastRangeIndex].length === 0 ||
			payoutRanges[lastRangeIndex] > 10000
		) {
			errors.push(`payout_ranges_${ index }_${ lastRangeIndex }`);
		}

		const previousRangeInGroup = payoutRanges[lastRangeIndex - 1];

		if (
			+previousRangeInGroup &&
			+payoutRanges[lastRangeIndex] < +previousRangeInGroup
		) {
			errors.push(`payout_ranges_${ index }_${ lastRangeIndex }`);
		}

		index++;

		return [ ...errors, ];
	}, []);
};

const getErrorsPayoutRangesFieldsCPA = (state) => {
	const errors = [];
	const formData = state.formData;
	const { payoutRangesSumFrom, } = formData;
	const lastIndex = payoutRangesSumFrom.length - 1;

	if (
		payoutRangesSumFrom[lastIndex].length === 0 ||
		+payoutRangesSumFrom[lastIndex] === 0 ||
		+payoutRangesSumFrom[lastIndex] > 1000000000
	) {
		errors.push(`sum_from_${ lastIndex }`);
	}

	const previousRange = payoutRangesSumFrom[lastIndex - 1];

	if (previousRange && +payoutRangesSumFrom[lastIndex] < +previousRange) {
		errors.push(`sum_from_${ lastIndex }`);
	}

	return errors;
};

const getCountryGroupsErrorsCPL = (state) => {
	return state.formData.country_groups.reduce((errors, group, index = 0) => {
		if (group.rate.length === 0 || +group.rate > 10000) {
			errors.push(`rate_${ index }`);
		}

		index++;

		return [ ...errors, ];
	}, []);
};

export const validatePayoutRangesFields = async (state, save, condition) => {
	let newErrors = [];

	if (condition.CPA) {
		const cpaPayoutRangeErrors = getErrorsPayoutRangesFieldsCPA(state);
		const cpaCountryGroupsErrors = getCountryGroupsErrorsCPA(state);

		newErrors = [
			...newErrors,
			...cpaPayoutRangeErrors,
			...cpaCountryGroupsErrors,
		];
	} else {
		const cplErrors = getCountryGroupsErrorsCPL(state);

		newErrors = [ ...newErrors, ...cplErrors, ];
	}
	await save({ errors: [ ...state.errors, ...newErrors, ], });

	return !newErrors.length;
};