import React, { Component, } from 'react';
import {
	MagicFilterPanels, MagicTable, Mentor,
} from "../../../../../../components/Magic";
import Loader from "../../../../../tima/components/Loader";
import Preloader from "../../../../../../components/LoadingHOC/Preloader";
import PagePanel from "../../../../../tima/components/Pages";
import Moment from "moment/moment";
import { MagicTooltip, } from "../../../../../tima/components/Magic";
import {
	 getRefferalsWaitingList,
} from "../../../../../../services/PartnersRequestService";
import { withLocalize, } from "react-localize-redux";
import { withRouter, } from "react-router";
import { Link, } from "react-router-dom";

class ReferalsWaitingSwitchTable extends Component {
	constructor (props) {
		super(props);

		this.state = {
			data: [],
			filterLastChange: Date.now(),
			isEmptyData: false,
			loaded: false,
			pages: {
				filter: 0,
				take: 50,
				total: 0,
				variance: 2,
			},
		};

		const mf = new Mentor({
			owner: this,
			serviceId: 185,
			translate: this.props.translate,
		});

		Object.defineProperty(this, "mf", { get: () => mf, });
	}

	async componentDidMount () {
		this.mf.subscribe(
			{
				changed: this.mfChanged,
				cleaned: this.mfDone,
				cleaning: this.mfDoing,
				loaded: this.mfDone,
				loading: this.mfDoing,
				saved: this.mfDone,
				saving: this.mfDoing,
			},
			this,
		);

		await this.mf.init({ doEmit: true, });
	}

	UNSAFE_componentWillUnmount () {
		this.mf.unsubscribe([ this.mfChanged, this.mfDoing, this.mfDone, ], this);
	}

    save = state => new Promise(next => this.setState(state, next));

    onChange = (name, value) => this.save({ [name]: value, });

    params = () => this.props.match.params;

    query = () => new URLSearchParams(this.props.location.search);

    mfChanged = async () => {
    	await this.save({ filterLastChange: Date.now(), });
    };

    mfDoing = async () => {
    	await this.save({ loaded: false, });
    };

    mfDone = async () => {
    	await this.mfChanged();
    	await this.onDataLoad();
    };

    enumsChange = async (enums) => {
    	if (enums.hash === this.state?.enums?.hash) {
    		return `${ this.constructor.name }.enumsChange: false`;
    	}
    	await this.save({ enums, });

    	return `${ this.constructor.name }.enumsChange: true`;
    };
    get awaitingReferralListConfig () {
    	const { translate, } = this.props;
    	const prefix = "partner_dashboard_table_";

    	return [
    		{
    			path: [ 'core', 'account', 'name', ],
    			key: 'referralName',
    		}, {
    			path: [ 'core', 'account', 'surname', ],
    			key: 'referralSurname',
    		},{
    			path: [ 'partners', 'partners_setup', 'id', ],
    			key: 'partnerId',
    		},
    		{
    			path: [ 'core', 'partner', 'name', ],
    			key: 'partnerName',
    		}, {
    			path: [ 'core', 'partner', 'surname', ],
    			key: 'partnerSurname',
    		},
    		{
    			key: "awaitingId",
    			orderId: "o:id",
    			path: [ "partners", "awaiting_add_referral_register", "id", ],
    			render: this.renderInitialValue,
    			title: `${ prefix }id`,
    		},
    		{
    			key: "awaitingPartners",
    			orderId: "o:partners",
    			path: [ "partners", "awaiting_add_referral_register", "data", ],
    			render: this.renderPartnerName,
    			title: `${ prefix }partners`,
    		},
    		{
    			key: "awaitingReferral",
    			orderId: "o:referral",
    			path: [ "partners", "awaiting_add_referral_register", "data", ],
    			render: this.renderReferralName,
    			title: `${ prefix }refferal`,
    		},
    		{
    			key: "awaitingStatus",
    			orderId: "o:status",
    			path: [ "partners", "awaiting_add_referral_register", "status", ],
    			render: this.renderCalculationStatus,
    			title: `${ prefix }status`,
    		},
    		{
    			key: "awaitingCreatedAt",
    			orderId: "o:created",
    			path: [ "partners", "awaiting_add_referral_register", "created_at", ],
    			render: this.renderDate,
    			title: `${ prefix }created-at`,
    		},
    		{
    			key: "awaitingUpdatedAt",
    			orderId: "o:updated",
    			path: [ "partners", "awaiting_add_referral_register", "updated_at", ],
    			render: this.renderDate,
    			title: `${ prefix }updated-at`,
    		},

    	].map(({
    		orderId: o, title: t, xtitle: x, ...item
    	}) => ({
    		...item,
    		...o && {
    			order: async () => {
    				await this.mf?.orderedToNext(o);
    			},
    			orderTo: this.mf?.orderedTo(o),
    		},
    		...t && { title: translate(t), },
    		...x && { title: x, },
    	}));
    }
	renderInitialValue = (value, { item, }) => {

		if (item?.access?.("index")) {
			return (
				<MagicTooltip
					classNameTooltip="tooltip tooltip--large-width"
					content={ value ?? "-" }
					lengthCut={ 20 }
				/>
			);
		}

		return "-";
	};

	renderPartnerName = (value, { items, }) => {
		const name = items?.partnerName.valueOf ?? "";
		const surname = items?.partnerSurname.valueOf ?? "";
		const fullName = name || surname ? `${ name } ${ surname }` : "-";
		const partnerSetupId = items?.partnerId.valueOf ?? "";

		return partnerSetupId ? (
			<Link to={ `/partners/requests/partners-list/${ partnerSetupId }` }>
				<MagicTooltip
					classNameTooltip={ `tooltip tooltip--large-width` }
					content={ fullName }
					lengthCut={ 20 }
				/>
			</Link>
		) :
			"-"
		;
	};
	renderReferralName = (value, { items, }) => {
		const name = items?.referralName.valueOf ?? "";
		const surname = items?.referralSurname.valueOf ?? "";
		const fullName = name || surname ? `${ name } ${ surname }` : "-";
		const partnerSetupId = JSON.parse(value).account_id ?? "";

		return partnerSetupId ? (
			<Link to={ `/clients/${ partnerSetupId }` }>
				<MagicTooltip
					classNameTooltip={ `tooltip tooltip--large-width` }
					content={ fullName }
					lengthCut={ 20 }
				/>
			</Link>
		) :
			"-"
		;
	};
	renderCalculationStatus = (status, { item, }) => {
		if (item.access("index")) {
			return (
				this.props.enums.data?.partners?.AwaitingAddReferralRegisterStatusEnum?.[+status] ??
				"-"
			);
		}

		return "-";
	};
	renderDate = (date, { item, }) => {
		if (item?.access?.("index")) {
			const formatDB = "YYYY-MM-DD HH:mm:ss";
			const formatOut = "YYYY-MM-DD HH:mm:ss";

			// eslint-disable-next-line new-cap
			const formatDate = Moment(date, formatDB);

			return formatDate.isValid() ? formatDate.format(formatOut) : "-";
		}

		return "-";
	};
	renderCalculationLevel = (status, { item, }) => {
		if (item.access("index")) {
			return (
				this.props.enums.data?.partners?.LevelEnum?.[+status] ??
				"-"
			);
		}

		return "-";
	};

	renderTable = () => {
		const {
			loaded, pages, data, isEmptyData,
		} = this.state;
		const { translate, } = this.props;

		const options = {
			config: this.awaitingReferralListConfig,
			data,
			head: [
				"awaitingId",
				"awaitingPartners",
				"awaitingReferral",
				"awaitingStatus",
				"awaitingCreatedAt",
				"awaitingUpdatedAt",
			],
		};

		return (
			<>
				{/*eslint-disable-next-line max-len*/}
				<MagicFilterPanels mf={ this.mf } show={ true } translate={ translate } />
				<div
					className={ `table-wrapper partners__table ${
						isEmptyData ? "partners__table--empty" : ""
					}` }
				>
					<Loader loaded={ loaded } loading={ <Preloader scale={ 1 } /> }>
						<MagicTable { ...options } />
						{isEmptyData && (
							<div className="table__empty-text">
								{translate("symbols_empty")}
							</div>
						)}
						<PagePanel
							doText={ this.onPageText }
							doTo={ pageId => `?page=${ pageId }` }
							filter={ pages.filter }
							page={ this.pageId() }
							take={ pages.take }
							total={ pages.total }
							variance={ pages.variance }
							onClick={ this.onPageClick }
						/>
					</Loader>
				</div>
			</>
		);
	};
	render () {
    	return (
	<>
				{this.renderTable()}
			</>
    	);
	}
	pageId = () => {
		const page = this.query().get("page");

		return Number(page) || 0;
	};

	pageIdAsGET = (pageId) => {
		// eslint-disable-next-line no-param-reassign
		pageId = pageId === undefined ? this.pageId() : pageId;
		const { filterId, } = this.mf;

		return {
			filterId,
			skip: pageId * this.state.pages.take,
			take: this.state.pages.take,
		};
	};

	onDataLoad = async (pageId) => {
		await this.save({
			isEmptyData: false,
			loaded: false,
		});

		const pageOptions = this.pageIdAsGET(pageId);
		const loaded = (data, meta) => ({ pages, }) => ({
			data,
			pages: { ...pages, ...meta, },
			...!data.length && { isEmptyData: true, },
		});

		try {
			const response = await getRefferalsWaitingList(pageOptions);
			const {
				data,
				meta: { filter = 0, total = 0, },
			} = response;

			await this.save(
				loaded(data, {
					filter,
					total,
				}),
			);
		} catch (error) {
			await this.save(
				loaded([], {
					filter: 0,
					total: 0,
				}),
			);
			error?.showErrorNotification?.();
		}
		await this.save({ loaded: true, });
	};

	onPageClick = ({ pageId, }) => {
		this.onDataLoad(pageId);
	};

	onPageText = (pageId, pageIs) => {
		const { translate, } = this.props;
		const {
			current, first, prev, next, last,
		} = pageIs;
		const {
			skipped, taken, filter, total,
		} = pageIs;

		if (skipped || taken) {
			const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : "?";
			const text = skipped ? translate("partners_pages_items") : "";

			return skipped ? `${ text }: ${ id }` : id;
		} else if (filter || total) {
			const id = Number.isInteger(pageId) ? `${ pageId }` : "?";
			const text = translate(
				filter ? "partners_pages_filtered" : "partners_pages_total",
			);

			return `${ text }: ${ id }`;
		} else if (first || prev || next || last) {
			return "";
		} else if (current) {
			return `${ pageId + 1 }`;
		}

		return `${ pageId + 1 }`;
	};
}

export default withRouter(withLocalize(ReferalsWaitingSwitchTable));