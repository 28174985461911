import React, { Component, } from 'react';
import PropTypes from "prop-types";
import { Input, Select, Popup, } from "../../../../components";
import {
	filterValues, findOptionByValue,
	setErrorClass,
	setErrorsFromServer,
} from "../../../../utils/helpers";
import {
	AffiliateProgramsStatus,
	AllowSeeCreateRebate,
} from "./consts/consts";
import Radio from "../../PayoutsPartnersRemuneration/Popup/components/Radio";
import { MagicButton, } from "../../../../../../components/Magic";
import { withLocalize, } from "react-localize-redux";
import ConditionInputsBlock from "./components/ConditionInputsBlock";
import {
	createAffiliateProgram, editAffiliateProgram,
	getNewPartnersList,
	getPartnersAvailableCurrencies,
	getPartnersCurrency,
} from "../../../../../../services/PartnersRequestService";
import {
	validateOnSubmit,
} from "./validation-helper/validateCreateUpdateAffiliatePrograms";
import ConfirmChangePartnerProgrammStatus from "./components/ConfirmChangePartnerProgrammStatus";
import MoveAllPartnersToProgram from "./components/MoveAllPartnersToProgram";

class CreateUpdateAffiliatePrograms extends Component {
		static propTypes = {
			onClose: PropTypes.func.isRequired,
			enums: PropTypes.object,
			formData: PropTypes.object,
			onDataLoad: PropTypes.func,
		};

		constructor (props) {
			super(props);

			this.state = {
				errors: [],
				loadData: false,
				statusList: [],
				partnersList: [],
				currencies: '',
				serverCurrency: '',
 				confirmPopupIsOpen: false,
				moveAllPartnersToProgramIsOpen: false,
				formData: {
					allow_see_create_rebate: {},
					name: '',
					client_name: '',
					status: {},
					cookie_lifetime_days: '1',
					description: '',
					public_program: 1,
					show_amount_referrals_funds: 2,
					CPASingle: {},
					CPLSingle: {},
					InOutSingle: {},
					RevshareSingle: {},
					CPAMulti: {},
					CPLMulti: {},
					InOutMulti: {},
					RevshareMulti: {},
					single_level_condition_ids: [],
					multi_level_condition_ids: [],
					conditionIds: [],
					is_payout: 0,
				},
			};
		}

		componentDidMount = async () => {
			try {
				const serverCurrency = await getPartnersCurrency();
				const currencies = await getPartnersAvailableCurrencies();
				const statusList = this.statusList;
				const partnersList = await getNewPartnersList();
				const propsFormData = this.props.formData;

				await this.save({
					isEmptyData: false,
					countriesLoaded: false,
				});

				await this.save((state) => {
					return {
						formData: {
							...state.formData,
							status: statusList[0],
						},
						currencies: Object.values(currencies),
						partnersList: partnersList.data,
						serverCurrency,
						statusList,
					};
				});
				if (this.isEditMode) {
					await this.save((state) => {
						return {
							formData: {
								...state.formData,
								...propsFormData,
								allow_see_create_rebate: findOptionByValue(this.allowSeeCreateRebateSelectOptions, propsFormData.allow_see_create_rebate),
								status: findOptionByValue(statusList, propsFormData.status),
							},
						};
					});
				}

			} catch (error) {
				error?.showErrorNotification?.();
			} finally {
				await this.save({ countriesLoaded: true, });
				await this.toggleLoader();
			}
		};

		componentDidUpdate (_prevProps, prevState) {
			const prevRebateConditionValue = prevState.formData.RevshareSingle.value;
			const currentRebateConditionValue = this.state.formData.RevshareSingle.value;

			const prevAllowSeeCreateRebateValue = prevState.formData?.allow_see_create_rebate?.value;
			const currentAllowSeeCreateRebateValue = this.state.formData?.allow_see_create_rebate?.value;

			const updateIsNotOnEditDidMount = !(!prevAllowSeeCreateRebateValue && currentAllowSeeCreateRebateValue && this.isEditMode);
			const currentRebateConditionIsSetted = currentRebateConditionValue;
			const rebateConditionWasChanged = prevRebateConditionValue !== currentRebateConditionValue;

			if (updateIsNotOnEditDidMount && currentRebateConditionIsSetted && rebateConditionWasChanged) {
				this.setAllowSeeCreateRebateDefaultValue();
			}
		}

	save = async state => new Promise(next => this.setState(state, next));

		toggleLoader = async () => await this.save(state => ({ loadData: !state.loadData, }));

		get translate () {
			return this.props.translate;
		};

		get isEditMode () {
			return Object.keys(this.props.formData).length;
		};

		get statusList () {
			return Object
				.entries(this.props?.enums?.data?.partners?.StatusEnum ?? {})
				.map(([ key, value, ]) => ({
					label: value,
					value: +key,
				}));
		};

		get allowSeeCreateRebateSelectOptions () {
			return Object
				.entries(this.props?.enums?.data?.partners?.AllowSeeCreateAndRebateEnum ?? {})
				.map(([ key, value, ]) => ({
					label: value,
					value: +key,
				}));
		};

		get statusIsNotEdit () {
			return this.isEditMode && this.AffiliateProgramsStatusInDB.closed || !this.isEditMode;
		};
		get fieldsIsNotEdit () {
			return this.isEditMode && !this.AffiliateProgramsStatusInDB.new;
		};

		get AffiliateProgramsStatusInDB () {
			const status = this.props.formData?.status ?? 1;

			return {
				new: status === AffiliateProgramsStatus.NEW,
				active: status === AffiliateProgramsStatus.ACTIVE,
				closed: status === AffiliateProgramsStatus.CLOSED,
				paymentsstopped: status === AffiliateProgramsStatus.PaymentsStopped,
				calculationsstopped: status === AffiliateProgramsStatus.CalculationsStopped,
			};
		};

		get proceedWithoutConfirm () {
			const status = this.state.formData.status.value;
			const statusDidNotChange = status === this.props.formData.status;
			const statusIsCalculationsStopped = status === AffiliateProgramsStatus.CalculationsStopped || this.AffiliateProgramsStatusInDB.calculationsstopped;

			return !this.isEditMode || this.AffiliateProgramsStatusInDB.new || statusDidNotChange || statusIsCalculationsStopped;
		}

		get showSwitchPartnersButton () {
			return this.isEditMode && !this.AffiliateProgramsStatusInDB.new && !this.AffiliateProgramsStatusInDB.closed;
		}

		setAllowSeeCreateRebateDefaultValue = async () => {
			const allowSeeCreateRebateDefaultValue = this.allowSeeCreateRebateSelectOptions.find(option => +option.value === AllowSeeCreateRebate.NONE);

			await this.save({ formData: { ...this.state.formData, allow_see_create_rebate: allowSeeCreateRebateDefaultValue, }, });
		}
		getSendData = () => {
			const {
				name,
				client_name,
				cookie_lifetime_days,
				description,
				public_program,
				status,
				show_amount_referrals_funds,
				CPASingle,
				CPLSingle,
				InOutSingle,
				RevshareSingle,
				CPAMulti,
				CPLMulti,
				InOutMulti,
				RevshareMulti,
				is_payout,
				allow_see_create_rebate,
			} = this.state.formData;
			const filteredSingleLevel = [ CPASingle.value, CPLSingle.value, InOutSingle.value, RevshareSingle.value, ].filter(item => typeof item === "number");
			const filteredMultiLevel = [ CPAMulti.value, CPLMulti.value, InOutMulti.value, RevshareMulti.value, ].filter(item => typeof item === "number");

			return {
				name,
				client_name,
				cookie_lifetime_days,
				description,
				public_program,
				status: status.value,
				show_amount_referrals_funds,
				single_level_condition_ids: filteredSingleLevel,
				multi_level_condition_ids: filteredMultiLevel,
				is_payout: is_payout ? 1 : 2,

				...RevshareSingle.value && { allow_see_create_rebate: allow_see_create_rebate.value, },
			};
		};

		setConditionIds = () => {
			const { formData, } = this.state;
			const {
				CPASingle,
				CPLSingle,
				InOutSingle,
				RevshareSingle,
				CPAMulti,
				CPLMulti,
				InOutMulti,
				RevshareMulti,
			} = formData;

			const filteredSingleLevel = [ CPASingle.value, CPLSingle.value, InOutSingle.value, RevshareSingle.value, ].filter(item => typeof item === "number");
			const filteredMultiLevel = [ CPAMulti.value, CPLMulti.value, InOutMulti.value, RevshareMulti.value, ].filter(item => typeof item === "number");

			return this.save({
				formData: {
					...formData,
					conditionIds: [ ...filteredSingleLevel, ...filteredMultiLevel, ],
				},
			});
		}
		saveData = async () => {
			await this.setConditionIds();

			const fieldsAreValid = await validateOnSubmit(this.state, this.save);

			if (!fieldsAreValid) {
				return console.log('Dosn`t matches validation rules');
			}

			this.isEditMode ? await this.updateNewAffiliateProgram() : await this.createNewAffiliateProgram();
		};
		updateNewAffiliateProgram = async () => {
			const {
				onClose,
				onDataLoad,
			} = this.props;
			const { id: formDataId, } = this.props.formData;

			try {
				await this.save({
					errors: [],
					loadData: false,
				});
				await editAffiliateProgram(formDataId, this.getSendData());
				await Promise.all([ onClose(), onDataLoad(), ]);
			} catch (error) {
				await error?.showErrorNotification?.();
				await setErrorsFromServer(error, this.save);
			} finally {
				await this.save({ loadData: true, });
			}
		}

		createNewAffiliateProgram = async () => {
			const {
				onClose,
				onDataLoad,
			} = this.props;

			try {
				await this.save({
					errors: [],
					loadData: false,
				});
				await createAffiliateProgram(this.getSendData());
				await Promise.all([ onClose(), onDataLoad(), ]);
			} catch (error) {
				await error?.showErrorNotification?.();
				await setErrorsFromServer(error, this.save);
			} finally {
				await this.save({ loadData: true, });
			}
		};

	// eslint-disable-next-line max-len
		toggleConfirm = () => this.save({ confirmPopupIsOpen: !this.state.confirmPopupIsOpen, })
		toggleMoveAllPartnersToProgram = () => this.save({ moveAllPartnersToProgramIsOpen: !this.state.moveAllPartnersToProgramIsOpen, })

		handleOnChange = async (event) => {
			const {
				name,
				value,
			} = event.target ? event.target : event;

			await this.save(
				state => ({
					...state,
					errors: state.errors.filter(item => item !== name),
					formData: {
						...state.formData,
						[name]: value,
					},

				}),
			);
		};
		handleOnChangeRadioButton = async (fieldName, event) => {
			const { value, } = event;

			await this.save(
				state => ({
					...state,
					formData: {
						...state.formData,
						[fieldName]: value,
					},

				}),
			);
		}
		renderTitle = () => {
			const { isViewMode, } = this.props;

			if (isViewMode) {
				return this.translate('affiliate_programs_view');
			}
			if (this.isEditMode) {
				return this.translate('affiliate_programs_update');
			}

			return this.translate('affiliate_programs_create');
		};

		renderPopupHeader = () => {
			const {
				formData,
				errors,
				serverCurrency,
				currencies,
				statusList,
			} = this.state;
			const { isViewMode, } = this.props;

			const disabled = this.fieldsIsNotEdit || isViewMode;
			const disabledName = `${ formData.name.slice(0, 20) }...`;
			const allowSeeCreateRebateSelectDisabled = !this.AffiliateProgramsStatusInDB.new || isViewMode;
			const showAllowSeeCreateRebateSelect = formData.RevshareSingle.value;

			const options = currencies ? currencies.map((currency) => {
				return {
					label: currency.code,
					value: currency.id,
				};
			}) : [ {
				label: '',
				value: '',
			}, ];

			const statusOptions =
					this.AffiliateProgramsStatusInDB.active || this.AffiliateProgramsStatusInDB.calculationsstopped
						? filterValues(statusList, [ AffiliateProgramsStatus.NEW, ])
						: this.AffiliateProgramsStatusInDB.new
							? filterValues(statusList, [ AffiliateProgramsStatus.CalculationsStopped, ])
		 		: statusList;

			const showSwitchPartnersButton = !!this.showSwitchPartnersButton;

			return (
				<div className="new-partners-conditions__popup mb-20">
					<div className="w-30 mr-35 field">
						<Input
							className={ 'field__input' }
							disabled={ disabled }
							isRequired={ true }
							label={ this.translate('new_partners_condition_name') }
							name="name"
							placeholder={ this.translate('symbols_name_placeholder') }
							value={ disabled ? disabledName : formData.name }
							wrapperClassName={ `${ setErrorClass(errors, 'name') } field__input` }
							onChange={ this.handleOnChange }
							onValid={ (value) => {
								const regExp = /^[a-zA-Z0-9\s\.\,\-\!\@\#\$\&\%\^\\*\(\)]{0,100}$/g;

								if (!value.match(regExp)) {
									throw new Error('xxx');
								}
								if (value.length > 100) {
									throw new Error('xxx');
								}
							} }
						/>
						{disabled ?
							<p className=" field__tooltip field__affiliate_tooltip__name">{formData.name}</p> : ''}
					</div>
					<div className="w-30 mr-35">
						<Input
							className={ 'field__input' }
							disabled={ disabled }
							isRequired={ true }
							label={ this.translate('affiliate_programs_name_for_clients') }
							name="client_name"
							placeholder={ this.translate('affiliate_programs_name_for_clients') }
							value={ disabled ? disabledName : formData.client_name }
							wrapperClassName={ `${ setErrorClass(errors, 'client_name') } field__input` }
							onChange={ this.handleOnChange }
							onValid={ (value) => {
								const regExp = /^[a-zA-Z0-9\s\.\,\-\!\@\#\$\&\%\^\\*\(\)]{0,100}$/g;

								if (!value.match(regExp)) {
									throw new Error('xxx');
								}
								if (value.length > 100) {
									throw new Error('xxx');
								}
							} }
						/>
						{disabled ?
							<p className="field__tooltip field__affiliate_tooltip__clients_name">{formData.client_name}</p> : ''}
					</div>
					<div className="w-30 mr-35">
						<Select
							className={ setErrorClass(errors, 'currencies') }
							disabled={ true }
							isRequired={ true }
							label={ this.translate('partners_statistic_table_currency') }
							name="currencies"
							options={ options?.filter(option => option.value !== serverCurrency) }
							placeholder={ this.translate('new_partners_condition_type_placeholder') }
							value={ options?.filter(option => option.value === serverCurrency) }
							onChange={ this.handleOnChange }
						/>
					</div>
					<div className="w-30 mr-35">
						<Radio
							className={ `${ disabled ? ' new-partners-conditions__disabled magic-radio-switch ' : 'magic-radio-switch magic-radio-switch--accept-reject-btns magic-radio-switch--payment-confirmation' }` }
							classNameText={ '`magic-switch__text`' }
							direction={ 'row' }
							handleOnChange={ e => this.handleOnChangeRadioButton('public_program', e) }
							isDisabled={ disabled }
							label={ this.translate('affiliate_programs_public_program') }
							name={ 'public_program' }
							options={ [ {
								name: this.translate('symbols_remove_accept'),
								value: 1,
							}, {
								name: this.translate('symbols_remove_reject'),
								value: 2,
							}, ] }
							value={ formData.public_program }
							wrapperClassName={ 'select__wrapper' }
						/>
					</div>
					<div className="w-30 mr-35">
						<Radio
							className={ `${ disabled ? ' new-partners-conditions__disabled magic-radio-switch ' : 'magic-radio-switch magic-radio-switch--accept-reject-btns magic-radio-switch--payment-confirmation' }` }
							classNameText={ '`magic-switch__text`' }
							direction={ 'row' }
							handleOnChange={ e => this.handleOnChangeRadioButton('show_amount_referrals_funds', e) }
							isDisabled={ disabled }
							label={ this.translate('affiliate_programs_show_amount_of_referral') }
							name={ 'show_amount_referrals_funds' }

							options={ [ {
								name: this.translate('symbols_remove_accept'),
								value: 1,
							}, {
								name: this.translate('symbols_remove_reject'),
								value: 2,
							}, ] }
							value={ formData.show_amount_referrals_funds }
							wrapperClassName={ 'select__wrapper' }
						/>
					</div>
					<div className="w-30">
						<Input
							disabled={ disabled }
							isRequired={ true }
							label={ this.translate('affiliate_programs_cookie_lifetime') }
							name="cookie_lifetime_days"
							placeholder={ this.translate('affiliate_programs_cookie_lifetime_placeholder') }
							value={ formData.cookie_lifetime_days }
							wrapperClassName={ setErrorClass(errors, 'cookie_lifetime_days') }
							onChange={ this.handleOnChange }
							onValid={ (value) => {
								const regExp = /^(\d){1,13}$/g;

								if (value === '') {
									return;
								}
								if (!value.match(regExp)) {
									throw new Error('xxx');
								}
								if (value < 1 || value > 365) {
									throw new Error('xxx');
								}
							} }
						/>
					</div>
					{
						showAllowSeeCreateRebateSelect &&
							<div className="w-30 mr-35">
								<Select
									className={ setErrorClass(errors, 'allow_see_create_rebate') }
									disabled={ allowSeeCreateRebateSelectDisabled }
									isRequired={ true }
									label={ this.translate('affiliate_programs_allow_see_create_rebate') }
									name="allow_see_create_rebate"
									options={ this.allowSeeCreateRebateSelectOptions }
									placeholder={ this.translate('partners_requests_table_resolution_placeholder') }
									value={ formData.allow_see_create_rebate }
									onChange={ this.handleOnChange }
								/>
							</div>
					}
					<div className="w-30 mr-35">
						<Select
							className={ setErrorClass(errors, 'status') }
							disabled={ this.statusIsNotEdit || isViewMode }
							isRequired={ true }
							label={ this.translate('symbols_status') }
							name="status"
							options={ statusOptions	}
							placeholder={ this.translate('symbols_create_status_placeholder') }
							value={ formData.status }
							onChange={ this.handleOnChange }
						/>
					</div>
					{showSwitchPartnersButton && <div className="w-30 mr-35 d-flex align-items-end">
						<MagicButton
							children={ this.translate(`${ "affiliate_program_switch_all_partners" }`) }
							className="magic-button__item magic-button_partners_popup-save switch-all-partners"
							disabled={ this.statusIsNotEdit || isViewMode }
							onClick={ this.toggleMoveAllPartnersToProgram }
						/>
					</div>}
				</div>
			);
		};

		renderInputsBlock = () => {
			const { formData, partnersList, errors, } = this.state;
			const { isViewMode, } = this.props;

			return (
				<ConditionInputsBlock
					disabledinputs={ this.fieldsIsNotEdit }
					errors={ errors }
					formData={ formData }
					isViewMode={ isViewMode }
					partnersList={ partnersList }
					setConditionsId={ async (name, value, id) => {
						await this.save({
							formData: {
								...formData,
								[name]: value,
								single_level_condition_ids: this.state.formData.single_level_condition_ids.filter(item => item !== id),
							},
						});
					} }
					setFieldSingleLeveData={ async (name, value, index, id) => {

						await this.save({
							errors: this.state.errors.filter(item => item !== name && item !== 'conditionIds'),
							formData: {
								...formData,
								[name]: value,
							},
						});
					} }
					statusInDb={ this.AffiliateProgramsStatusInDB }
				/>
			);
		};

		handleOnConfirm = async () => {
			try {
				await this.saveData();
				await this.toggleConfirm();
			} catch (error) {
				error.showErrorNotification();
			}
		}

		handleOnClick = async () => {
			//save without additional confirm
			if (this.proceedWithoutConfirm) {
				return this.saveData();
			}

			//open confirm
			await this.setConditionIds();
			const fieldsAreValid = await validateOnSubmit(this.state, this.save);

			if (!fieldsAreValid) {
				return console.log('Dosn`t matches validation rules');
			}

			return this.toggleConfirm();
		}
		renderConfirmChangePartnerProgrammStatus () {
			const { confirmPopupIsOpen, formData, } = this.state;
			const { translate, } = this.props;
			const { is_payout, status, } = formData;

			return (
				<>
					{confirmPopupIsOpen &&
						<ConfirmChangePartnerProgrammStatus
							handleOnCheckbox={ () => this.save({ formData: { ...formData, is_payout: !is_payout, }, }) }
							is_payout={ is_payout }
							status={ status.value }
							statusInDB={ this.AffiliateProgramsStatusInDB }
							translate={ translate }
							onCancel={ this.toggleConfirm }
							onConfirm={ this.handleOnConfirm }
						/> }
				</>
			);
		}

		renderMoveAllPartnersToProgramm () {
			const {
				translate,
				onDataLoad,
			} = this.props;

			const { id: currentProgrammId, } = this.props.formData;

			const { moveAllPartnersToProgramIsOpen, } = this.state;

			return (
				<>
					{moveAllPartnersToProgramIsOpen &&
						<MoveAllPartnersToProgram
							currentProgrammId={ currentProgrammId }
							refreshClientData={ onDataLoad }
							toggleLoader={ this.toggleLoader }
							translate={ translate }
							onClose={ this.toggleMoveAllPartnersToProgram }
						/>}
				</>
			);
		}
		render () {
			const {
				onClose,
				isViewMode,
			} = this.props;
			const {
				loadData,
			} = this.state;
			const viewClass = isViewMode ? "popup_view-mode" : "";

			return (
				<Popup
					cancelBtnText={ 'partners_payout_rate_list_popup_cancel' }
					createBtnText={ this.isEditMode? 'partners_save_btn' : 'partners_create_btn' }
					customClassName={ `${ viewClass } max-height-popup position-static` }
					isImage={ false }
					loadData={ loadData }
					showTitle={ true }
					size={ 'lg' }
					title={ this.renderTitle() }
					onClick={ this.handleOnClick }
					onClose={ onClose }
				>
					{ this.renderMoveAllPartnersToProgramm() }
					{ this.renderConfirmChangePartnerProgrammStatus() }
					{ this.renderPopupHeader() }
					{ this.renderInputsBlock() }
					{/*{ this.renderDescriptionInput() }</div>*/}

				</Popup>
			);
		}
}

export default withLocalize(CreateUpdateAffiliatePrograms);