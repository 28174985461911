import React, { Component, } from "react";
import PropTypes from "prop-types";
import { withLocalize, } from "react-localize-redux";

import { Input, Popup, Select, } from "../../../../components";
import FileInput from "../../components/FileInput";

import { getLanguages, } from "../../../../../common/services/LocalisationService";
import { getTargetPagesDropdown, createBanner, updateBanner, } from "../../../../../../services/PartnersRequestService";

import { validateCreatePromoFields, } from "../../helpers/validation-helpers";
import { findOptionByValue, setErrorClass, } from "../../../../utils/helpers";
import { BANNER_FILE_EXTENSION_ACCEPT, } from "../consts/BANNER_FILE_EXTENSION_ACCEPT";
import ImagePopup from "../../components/ImagePopup";
class CreateUpdateBanner extends Component {
    static propTypes = {
    	onClose: PropTypes.func.isRequired,
        // eslint-disable-next-line sort-keys
    	enums: PropTypes.object,
    	formData: PropTypes.object,
    	onDataLoad: PropTypes.func,
    };

	static defaultProps = {
    	formData: {},
	};

	constructor (props) {
    	super(props);

    	this.state = {
    		languageOptions: [],
    		targetPageOptions: [],

    		errors: [],
    		formData: {
    			name: '',
    			file: null,
    			status: {},
    			target_page_id: {},
    			language_id: {},
    		},

			//Update values
    		id: null,
    		source: null,
    		updated_at: null,

			imgPopup: {
				isOpen: false,
				imgSrc: null,
			},
    	};
	}

	get isEditMode () {
    	return !!Object.keys(this.props.formData).length;
	}

	get isViewMode () {
    	return this.props.formData?.isViewMode;
	}

	get statusesOptions () {
    	const { enums, } = this.props;

    	return Object.values(enums.data.partners.PartnerPromoStatusEnum).map((status, index) => {
    		return {
    			label: status,
    			value: index,
    		};
    	});
	}

	async componentDidMount () {
    	try {
    		this.toggleLoaded(false);

    		await this.getInitialData();

    		if (this.isEditMode) {
    			await this.getUpdateData();
    		}

    		this.toggleLoaded(true);
    	} catch (error) {
    		this.toggleLoaded(true);
    		console.log(error);
    		error.showErrorNotification?.();
    	}
	}
    save = async state => new Promise(next => this.setState(state, next));

	toggleLoaded = loadData => this.save({ loadData, });

	toggleImgPopup = (isOpen, imgSrc) => {
		return this.save({ imgPopup: { imgSrc, isOpen, }, });
	}
	getOptionsFromArrayOfObjects = (array, valueKey, labelKey) => {
		return array.map((index) => {
			return {
				value: index[valueKey],
				label: index[labelKey],
			};
		});
	}

	getInitialData = async () => {
		const { formData, } = this.state;
		const languages = await getLanguages();
		const languageOptions = this.getOptionsFromArrayOfObjects(languages, 'id', 'name');

		const targetPages = await getTargetPagesDropdown();
		const targetPageOptions = this.getOptionsFromArrayOfObjects(targetPages, 'id', 'name');

		const status = findOptionByValue(this.statusesOptions, 1);

		await this.save({
			languageOptions,
			targetPageOptions,
			formData: { ...formData, status, },
		});
	}
	getUpdateData = async () => {
		const {
			id, language_id, name, source, status, target_page_id, updated_at,
		} = this.props.formData;
		const { languageOptions, targetPageOptions, } = this.state;
		const statusOptions = this.statusesOptions;

		await this.save({
			formData: {
				language_id: findOptionByValue(languageOptions, language_id),
				name,
				status: findOptionByValue(statusOptions, status),
				target_page_id: findOptionByValue(targetPageOptions, target_page_id),
			},
			id,
			source,
			updated_at,
		});
	}

    getSendData = () => {
    	const { formData: rawFormData, } = this.state;
    	const formData = new FormData();

    	Object.entries(rawFormData).map((entry) => {
    		const [ key, value, ] = entry;

    		if (key === 'name' || key === 'file') {
    			return formData.set(key, value);
    		}

    		return formData.set(key, value.value);
    	});

    	if (this.isEditMode) {
    		formData.set("_method", "PUT");
    	}

    	return formData;
    };
	saveData = async () => {
		try {
			const { onClose, onDataLoad, } = this.props;
			const { formData, id, } = this.state;

			const fieldsAreValid = await validateCreatePromoFields(formData, this.save);

			if (!fieldsAreValid) {
				return false;
			}

			await this.toggleLoaded(false);
			const data = this.getSendData();

			this.isEditMode ? await updateBanner(id, data) : await createBanner(data);

			await this.toggleLoaded(true);
			await onClose();
			await onDataLoad();
		} catch (error) {
			await this.toggleLoaded(true);
			console.log(error);
			error.showErrorNotification?.();
		}
	}

    handleOnChange = async (event) => {
    	const { name, value, } = event.target;

    	await this.save(state => ({
    		...state,
    		errors: state.errors.filter(item => item !== name),
    		formData: {
    			...state.formData,
    			[name]: value,
    		},
    	}));
    };
	handleOnFileChange = async (e) => {
		const { formData, errors, } = this.state;

		await this.save({
			errors: errors.filter(error => error !== 'file'),
			formData: {
				...formData,
				file: e.target.files[0],
			},
		});
	}

    renderTitle = () => {
    	const { translate, } = this.props;

    	switch (true) {
    		case this.isViewMode: {
    			return translate("partners_banners_view");
    		}
    		case this.isEditMode: {
    			return translate("partners_banners_edit");
    		}
    		default: {
    			return translate("partners_banners_create");
    		}
    	}

    };

	renderForm = () => {
		const { translate, } = this.props;
		const {
			formData, errors, languageOptions, targetPageOptions, source, updated_at,
		} = this.state;

		const {
			name, file, language_id, status, target_page_id,
		} = formData;

		return (
			<>
				<div className="promo-popup__row promo-popup__row_first">
					<Input
						disabled={ this.isViewMode }
						label={ translate(`partners_create_banner_name`) }
						name={ 'name' }
						placeholder={ translate(`partners_create_symbol_name_placeholder`) }
						value={ name }
						wrapperClassName={ setErrorClass(errors, 'name') }
						onChange={ e => this.handleOnChange(e) }
						onValid={ (value) => {
							const regExp = /^[a-zA-Zа-яА-Я0-9._ ]{1,30}$/;

							if (!regExp.test(value) && value !== '') {
								throw new Error('XXX');
							}
						} }
					/>
				</div>
				<div className="promo-popup__row">
					<Select
						className={ setErrorClass(errors, "language_id") }
						disabled={ this.isViewMode }
						isRequired={ true }
						isSearchable={ true }
						label={ translate("partners_banners_language") }
						name="language_id"
						options={ languageOptions }
						placeholder={ translate("template_management_choose_language") }
						value={ language_id }
						onChange={ e => this.handleOnChange(e) }
					/>
				</div>
				<div className="promo-popup__row">
					<Select
						className={ setErrorClass(errors, "status") }
						disabled={ this.isViewMode }
						isRequired={ true }
						label={ translate("partners_banners_status") }
						name="status"
						options={ this.statusesOptions }
						placeholder={ translate("partners_banners_status") }
						value={ status }
						onChange={ e => this.handleOnChange(e) }
					/>
				</div>
				<div className="promo-popup__row">
					<Select
						className={ setErrorClass(errors, "target_page_id") }
						disabled={ this.isViewMode }
						isRequired={ true }
						label={ translate("partners_banners_target_page") }
						name="target_page_id"
						options={ targetPageOptions }
						placeholder={ translate("partner_banners_target_page_placeholder") }
						value={ target_page_id }
						onChange={ e => this.handleOnChange(e) }
					/>
				</div>
				<div className={ 'promo-popup__row' }>
					<div className={ `input__wrapper` }>
						<label className="">{translate(`partners_download`)}</label>
						<FileInput
							accept={ BANNER_FILE_EXTENSION_ACCEPT }
							buttonText={ file || this.isEditMode ? translate('partners_change_file') : translate('partners_download') }
							className={ setErrorClass(errors, 'file') }
							disabled={ this.isViewMode }
							file={ file }
							imgData={ { source, updated_at, } }
							name={ 'file' }
							onChange={ e => this.handleOnFileChange(e) }
							onImgClick={ imgSource => this.toggleImgPopup(true, imgSource) }
						/>
					</div>
				</div>
			</>
		);
	}

	render () {
    	const { onClose, } = this.props;
    	const { loadData, imgPopup} = this.state;
    	const isEditMode = this.isEditMode;

    	return (
			<Popup
				cancelBtnText={ "partners_payout_rate_list_popup_cancel" }
				createBtnText={ isEditMode ? null : "partners_payout_rate_list_popup_save" }
				customClassName={ `promo-popup ${ isEditMode && "promo-popup_edit" }` }
				isImage={ false }
				loadData={ loadData }
				showPopUp={ this.isViewMode }
				size={ "popup-banner-size" }
				title={ this.renderTitle() }
				onClick={ this.saveData }
				onClose={ onClose }
			>
				{ imgPopup.isOpen &&
					<ImagePopup
						imgSrc={ imgPopup.imgSrc }
						onClose={ () => this.toggleImgPopup(false, null) }
					/>
				}
				{this.renderForm()}
			</Popup>
    	);
	}
}

export default withLocalize(CreateUpdateBanner);