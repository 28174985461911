import React, { Component } from 'react';
import PropTypes            from 'prop-types';

class MagicTextarea extends Component {

    constructor ( props ) {
        super( props );

        const { value, selectionStart, selectionEnd } = props;

        this.state = {
            value,
            selectionStart,
            selectionEnd,
        };
    }

    componentDidMount () {
        const { selectionStart, selectionEnd } = this.state;

        this.setSelectionPos( [selectionStart, selectionEnd] );
    }

    UNSAFE_componentWillReceiveProps = ( nextProps, nextContext ) => {
        if(nextProps.value !== this.state.value) {
            this.setState({value: nextProps.value});
        }
    };

    className = ( ) => this.props.className;
    value = ( ) => { return this.state.value || ''};

    onChange = (event) => {
        const value = event.target.value;
        const { selectionStart, selectionEnd } = event.target;
        const state = { ...this.state, value, selectionStart, selectionEnd};

        this.setState( state, ( ) => {
            // this.setSelectionPos( [selectionStart, selectionEnd] );
            if ( this.props.onChange instanceof Function ) {
                this.props.onChange( { value, selectionStart, selectionEnd } );
            }
        } );

    };

    setSelectionPos = ( selectionPos ) => {
        if (!this.props.notToFocus) {
            if (selectionPos instanceof Array && selectionPos.length === 2 ) {
                this.ref.focus();
                [ this.ref.selectionStart, this.ref.selectionEnd ] = selectionPos;
            } else {
                return false;
            }
        }
    };

    render = ( ) => {
        return (
            <textarea
                maxLength={this.props.maxLength}
                className={this.className()}
                value={this.value()}
                onChange={this.onChange}
                ref={(node) => this.ref = node}
                disabled={this.props.isDisabled}
                // setSelectionPos = { this.setSelectionPos }
            />
        );
    }
}

MagicTextarea.propTypes = {
    className: PropTypes.string.isRequired,
    value: PropTypes.string,
    selectionStart: PropTypes.number.isRequired,
    selectionEnd: PropTypes.number.isRequired,
    notToFocus: PropTypes.bool,
    isDisabled: PropTypes.bool,
};

MagicTextarea.defaultProps = {
    className: `magic-textarea`,
    value: `Type text in my magic-textarea!`,
    selectionStart: 0,
    selectionEnd: 0,
    isDisabled: false,
};

export default MagicTextarea;
export {
    MagicTextarea,
};
