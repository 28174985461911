import React from 'react';
import ReactDOM from 'react-dom';
import { withRouter, Link, } from 'react-router-dom';
import { withLocalize, } from "react-localize-redux";
import moment from 'moment';
import { callService } from "../../../../services/CallDataService";

class Panel extends React.Component {
    state = {
        height: 0,
    };

    componentDidMount() {
        window.setTimeout(() => {
            const el = ReactDOM.findDOMNode(this);
            const height = el.querySelector('.panel__inner').scrollHeight;

            this.setState({
                height,
            });
        }, 300);
    }

    renderCallback = () => {
        return '';
        const { panel, } = this.props;
        const options = { className: 'panel__callback', };
        const optionsPhone = {
            className: 'panel__callback-phone',
            onClick: async () => callService.sendCall({ account_id: panel?.core?.account?.id }),
            href: 'javascript:void(0)',
        };
        const optionsMail = {
            className: 'panel__callback-mail',
            href: 'javascript:void(0)',
        };
        return (<div { ...options }><a { ...optionsPhone } /><a { ...optionsMail } /></div>);
    };

    render = () => {
        const { activeTab, index, activateTab, panel, translate } = this.props;
        const { height } = this.state;
        const isActive = activeTab === index;
        const innerStyle = {
            height: `${isActive ? height : 0}px`,
        };

        const h4style = !isActive ? {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '20ch',
        } : {
            width: 'unset',
        };

        const accountId = panel?.core?.account?.id;
        const accountName = panel?.core?.account?.name;
        const accountSurname = panel?.core?.account?.surname;
        const taskId = panel?.tasks?.tasks?.id;
        const taskDescription = panel?.tasks?.tasks?.description;
        const taskFinishDate = panel?.tasks?.tasks?.finish_date;
        const taskFinishDateMoment = moment( taskFinishDate, 'YYYY-MM-DD HH:mm:ss' );
        const taskUrgencyAlias = panel?.tasks?.task_urgency?.alias;
        const taskUrgencyName = panel?.tasks?.task_urgency?.name;
        const taskStatesAlias = panel?.tasks?.task_states?.alias;
        const taskStatesName = panel?.tasks?.task_states?.name;
        const taskIsDelegate = false && panel?.tasks?.task_delegation?.is_delegate;
        const classTask = ''; // panel['tasks-task_types-alias']
        const classFinishDate = taskFinishDateMoment.diff( moment() ) < 0 ? 'expired-date' : '';
        const isExpired =  taskFinishDateMoment.diff( moment() ) < 0;

        return (
            <div
                aria-expanded={ isActive }
                className={ 'panel' }
            >
                <div
                    className={ 'panel__label tooltip-wrapper' }
                    onClick={ activateTab }
                    style={{ minHeight: '70px', height: 'unset', }}
                >
                    <h4 style={ h4style }>
                        {/*<span className={classTask} />*/}
                        { taskId }: <strong>{ taskDescription }</strong>
                    </h4>
                    {/* <div className='tooltip'>{ taskDescription }</div> */}
                </div>
                <div
                    aria-hidden={ !isActive }
                    className={ 'panel__inner' }
                    style={ innerStyle }
                >
                    <div className={ 'panel__content' }>
                        <ul>
                            { accountId ? <li><span><i />{ translate( `sideBar_client` ) }</span><Link to={ `/clients/${ accountId }/tasks/task/${ taskId }` } target="_blank">{ accountName } { accountSurname }</Link></li> : '' }
                            <li>
                                <span><i />{ translate( `sideBar_deadline` ) }</span>
                                <span className={ `finish_date ${ classFinishDate}` } >{ taskFinishDate } {isExpired ?  translate( `sideBar_expired` ) : ""}</span>
                            </li>
                            <li><span><i />{ translate( `sideBar_important` ) }</span><i className={ `panel_status_icon ${ taskUrgencyAlias }` } />{ taskUrgencyName }</li>
                            <li><span><i />{ translate( `sideBar_status` ) }</span><i className={ `panel_status_icon ${ taskStatesAlias }` } />{ taskStatesName }</li>
                            { taskIsDelegate ? <li><span style={{ whiteSpace: 'nowrap', }}><i />{ translate( `sideBar_deligated` ) }</span></li> : '' }
                        </ul>
                        { this.renderCallback() }
                    </div>
                    <div
                        onClick={() => {
                            accountId ?
                                this.props.history.push(`/clients/${ accountId }/tasks/task/${ taskId }`)
                                :
                                this.props.setStateModalTask( taskId );
                        }}><button className={ 'panel__open-task' }>{ translate( `sideBar_open_task` ) }</button></div>
                </div>
            </div>
        );
    };
}

export default withLocalize(withRouter(Panel));
