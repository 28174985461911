import React, { Component } from 'react';
import GridContainer from "./GridContainer";
import PermissionsPageAnchor from "./PermissionsPageAnchor";
import SelectTemplatePermissions from "./SelectTemplatePermissions";

import { isEmpty, isArray, has, remove, omit, isUndefined, isNull } from "lodash";
import profile_default from '../../../../../images/profile_default.jpg';
import { Scrollbars } from "react-custom-scrollbars";
import NotificationService from "../../../../../services/NotificationService";
import { userService } from "../../../../../services/UserDataService";

class PermissionsPage extends Component {
    constructor (props) {
        super(props);
        this.state = {
            userListName: "",
            userList: {
                hash: null,
                data: [],
            },
            templateId:             this.props.userPermissionData.id,
            formPermission:         true,
            dataPermissions:        {
                hash: null,
                data: [],
            },
            userPermissionData:     !isEmpty(this.props.userPermissionData.data) ? JSON.parse(this.props.userPermissionData.data) : {},
            notification:           true,
            templatePermissionData: {
                hash: null,
                data: [],
            },
            indexValueSelect:       0,
            idTemplatePermissions:  1,
            user_list_toggle:       false,
            templateName:           !isEmpty(this.props.userPermissionData.data) ? this.props.userPermissionData.name : "",
            errorForm:              [],
            searchUser:             "",
        };
    }

    async componentDidMount () {
        userService.subscribe('getUserRequest', this.usersChanges, this);
        userService.subscribe('getTemplatePm', this.templatesChanges, this);
        userService.subscribe('getPermissions', this.permissionsChanges, this);
        await this.getPermissions();
        await this.getTemplatePermissions();
        await this.getUsers();
    }

    componentWillUnmount = () => {
        userService.unsubscribe('getUserRequest', this.usersChanges, this);
        userService.unsubscribe('getTemplatePm', this.templatesChanges, this);
        userService.unsubscribe('getPermissions', this.permissionsChanges, this);
    };

    usersChanges = async (users) => {
        if (users.hash===this.state?.userList?.hash) return `${this.constructor.name}.usersChanges: false`;
        const { hash, data: { data }} = users;
        await this.save({ userList: { hash, data }});
        return `${this.constructor.name}.usersChanges: true`;
    };

    templatesChanges = async (templates) => {
        if (templates.hash===this.state?.templatePermissionData?.hash) return `${this.constructor.name}.templatesChanges: false`;
        const { hash, data: { data }} = templates;
        const result = [];
        data.map((item, index) => {
            if (index === 0) {
                this.updateIdTemplatePermissions(item.core.permission_templates.id);
            }
            result[index] = {
                id:   item.core.permission_templates.id,
                text: item.core.permission_templates.name,
                data: item.core.permission_templates.data,
            };
        });
        await this.save({ templatePermissionData: { hash, data: result }});
        return `${this.constructor.name}.templatesChanges: true`;
    };

    permissionsChanges = async (permissions) => {
        if (permissions.hash===this.state?.dataPermissions?.hash) return `${this.constructor.name}.permissionsChanges: false`;
        const { hash, data } = permissions;
        await this.save({ dataPermissions: { hash, data }});
        return `${this.constructor.name}.permissionsChanges: true`;
    };

    save = async (state) => new Promise(next => this.setState(state, next));

    getPermissions = async () => {
        const { hash, data } = await userService.getPermissions();
        await this.save({ dataPermissions: { hash, data }});
    };

    changeTemplateName = (event) => {
        this.setState({
            templateName: event.target.value,
        });

        if (!isEmpty(event.target.value)) {
            this.setState({ errorForm:
                    { ...this.state.errorForm, [event.target.name]: { status: false }},
            });
        }
    };

    setErrorData = (name, message, status) => {
        this.setState({ errorForm:
                { ...this.state.errorForm, [name]: { status }},
        });
    };

    returnErrorTextClass = (name) => {
        return this.state.errorForm[name] && this.state.errorForm[name].status ? 'error' : '';
    };

    savePermission = async () => {
        try {
            if (isUndefined(this.state.templateId)) {
                await userService.createTemplatePm({
                    data: JSON.stringify(this.state.userPermissionData),
                    name: this.state.templateName,
                });
                NotificationService.success({
                    title:   "success",
                    message: "success create",
                });
                this.props.loadPermissionTemplates();
                this.props.closePermission();
            } else {
                await userService.updateTemplatePm({
                    data: JSON.stringify(this.state.userPermissionData),
                    name: this.state.templateName,
                }, this.state.templateId);
                NotificationService.success({
                    title:   "success",
                    message: "success",
                });
                await this.props.loadPermissionTemplates();
                await this.props.closePermission();
            }
        } catch (error) {
            NotificationService.errors(error);
            if (isUndefined(this.state.templateId)) {
                const name = Object.keys(error.errors)[0];
                this.setErrorData(name, error.errors[name], true);
            }
        }
    };

    addField = (item, show, module, stateName, addItem) => {
        // const key = Number(addItem.key);
        const key = addItem.key;

        const addData =  has(addItem, 'key') ? key : addItem.value;

        const setData = this.state.userPermissionData[module][stateName][item][show];

        setData.splice(setData.length, 0, addData);

        this.setState({
            userPermissionData: {
                ...this.state.userPermissionData,
                [module]: {
                    ...this.state.userPermissionData[module],
                    [stateName]: {
                        ...this.state.userPermissionData[module][stateName],
                        [item]: {
                            ...this.state.userPermissionData[module][stateName][item],
                            [show]: setData,
                        },
                    },
                },
            },
        });
    };

    removeField = (item, show, module, stateName, removeItem) => {
        this.setState({
            userPermissionData: {
                ...this.state.userPermissionData,
                [module]: {
                    ...this.state.userPermissionData[module],
                    [stateName]: {
                        ...this.state.userPermissionData[module][stateName],
                        [item]: {
                            ...this.state.userPermissionData[module][stateName][item],
                            [show]: remove(this.state.userPermissionData[module][stateName][item][show], (n) => {
                                return n !== removeItem;
                            }),
                        },
                    },
                },
            },
        });
    };

    getUserField = (item, show, module, stateName) => {
        return this.state.userPermissionData[module][stateName][item][show];
    };

    isEditPopup = (isFieldChecked, item, show, module, stateName) => {
        if (!isFieldChecked) {
            return false;
        }

        if (isEmpty(this.state.userPermissionData[module][stateName][item][show])) {
            return false;
        }

        return true;
    };

    isFieldChecked = (isChecked, item, show, module, stateName) => {
        return Boolean(isChecked && has(this.state.userPermissionData[module][stateName][item], show));
    };

    isChecked = (isChecked, module, stateName, method) => {
        const permissions = this.state.userPermissionData?.[module]?.[stateName]?.[method];

        return !!permissions;
    };

    moduleToggle = (item) => {
        if (!has(this.state.userPermissionData, item)) {
            if (isArray(this.state.userPermissionData)) {
                this.setState({
                    userPermissionData: {},
                });
            }

            this.setState({
                userPermissionData: {
                    ...this.state.userPermissionData,
                    [item]: {
                    },
                },
            });
        }
    };

    stateNameToggle = (stateName, item) => {
        if (!has(this.state.userPermissionData[stateName], item)) {
            this.setState({
                userPermissionData: {
                    ...this.state.userPermissionData,
                    [stateName]: {
                        ...this.state.userPermissionData[stateName],
                        [item]: {},
                    },
                },
            });
        }
    };

    commonToggle = (module, stateName, method) => {
        this.setState({
            userPermissionData: {
                ...this.state.userPermissionData,
                [module]: {
                    ...this.state.userPermissionData[module],
                    [stateName]: {
                        ...this.state.userPermissionData[module][stateName],
                        [method]: {
                            ...this.state.dataPermissions.data[module][stateName][method],
                        },
                    },
                },
            },
        });
    };

    methodToggle = (module, stateName, method, isChecked) => {
        if (isChecked) {
            this.setState({
                userPermissionData: {
                    ...this.state.userPermissionData,
                    [module]: {
                        ...this.state.userPermissionData[module],
                        [stateName]: {
                            ...omit(this.state.userPermissionData[module][stateName], method),
                        },
                    },
                },
            });
        } else {
            this.commonToggle(module, stateName, method);
        }
    };

    fieldToggle = (module, stateName, method, show, isChecked) => {
        if (isChecked) {
            this.setState({
                userPermissionData: {
                    ...this.state.userPermissionData,
                    [module]: {
                        ...this.state.userPermissionData[module],
                        [stateName]: {
                            ...this.state.userPermissionData[module][stateName],
                            [method]: {
                                ...omit(this.state.userPermissionData[module][stateName][method], show),
                            },
                        },
                    },
                },
            });
        } else {
            if (!has(this.state.userPermissionData[module][stateName], method)) {
                this.commonToggle(module, stateName, method);
            }

            this.setState({
                userPermissionData: {
                    ...this.state.userPermissionData,
                    [module]: {
                        ...this.state.userPermissionData[module],
                        [stateName]: {
                            ...this.state.userPermissionData[module][stateName],
                            [method]: {
                                ...this.state.userPermissionData[module][stateName][method],
                                [show]: [],
                            },
                        },
                    },
                },
            });
        }
    };

    getUsers = async () => {
        const { hash, data: { data }} = await userService.getUserRequest();
        await this.save({ userList: { hash, data }});
    };

    getTemplatePermissions = async () => {
        const result = [];
        const { hash, data: { data }} = await userService.getTemplatePm();
        data.map((item, index) => {
            if (index === 0) {
                this.updateIdTemplatePermissions(item.core.permission_templates.id);
            }
            result[index] = {
                id:   item.core.permission_templates.id,
                text: item.core.permission_templates.name,
                data: item.core.permission_templates.data,
            };
        });
        await this.save({
            templatePermissionData: { hash, data: result },
            indexValueSelect:       0,
        });
    };

    applyTemplatePermission = (event) => {
        event.preventDefault();
        this.setState({
            userPermissionData: JSON.parse(this.state.templatePermissionData.data[this.state.indexValueSelect].data),
        });
    };

    updateIndexValueSelect = (index) => {
        this.setState({
            indexValueSelect: index,
        });
    };

    updateIdTemplatePermissions = (index) => {
        this.setState({
            idTemplatePermissions: index,
        });
    };

    checkItem = (item) => {
        if (has(this.state.userPermissionData, item)) {
            return true;
        }

        return false;

    };

    setTemplatePm = (id, name) => {
        this.setState({
            userListName: name,
        });
        this.updateIdTemplatePermissions(id);
        this.state.templatePermissionData.data.map((item, index) => {
            if (id === item.id) {
                return this.updateIndexValueSelect(index);
            }
        });
    };

    userListToggle = () => {
        this.setState((state) => {
            return { user_list_toggle: !state.user_list_toggle };
        });
    };

    changeSearchUser = (event) => {
        this.setState({
            searchUser: event.target.value,
        });
    };

    render () {
        if (this.state.formPermission) {
            const sortListUser = (users) => {
                let array = users.filter((item) => {
                    const name = !isNull(item.core.user.name) ? item.core.user.name : '';

                    return name.toLowerCase().includes(this.state.searchUser.toLowerCase());
                });

                if (isEmpty(array)) {
                    array = users.filter((item) => {
                        const surname = !isNull(item.core.user.surname) ? item.core.user.surname : '';

                        return surname.toLowerCase().includes(this.state.searchUser.toLowerCase());
                    });
                }

                return array;
            };

            return (
                <div className = 'formTasks'>
                    <div className = 'formTasks-popup'>
                        <div className = 'formTasks_header'>

                            <div className = 'formTasks_header_fio'>
                                <form>
                                    {!isEmpty(this.state.templatePermissionData.data)
                                        ?
                                        <SelectTemplatePermissions
                                                templatePermissionData = { this.state.templatePermissionData.data }
                                                updateIdTemplatePermissions = { this.updateIdTemplatePermissions }
                                                updateIndexValueSelect = { this.updateIndexValueSelect }
                                                value = { this.state.templatePermissionData.data[this.state.indexValueSelect] }
                                            />
                                        :
                                        null}
                                    <div className = 'formTasks_user_list'>
                                        <div
                                            className = 'formTasks_user_list-body' onClick = { () => {
                                                this.userListToggle();
                                            } }>
                                            <p className = 'formTasks_user_list-title'>
                                    {isEmpty(this.state.userListName) ?
                                                    this.props.translate(`template_permission_user_list`)
                                                    :
                                                    this.state.userListName
                                                }

                                </p>
                                            <span className = 'formTasks_user_list-amount'>{this.state.userList.data?.length}</span>
                                        </div>

                                        {this.state.user_list_toggle ?
                                            <div className = 'formTasks_user_list-modal'>
                                    <div className = 'search'>
                                                    <input
                                                        name = 'searchUser'
                                                        onChange = { this.changeSearchUser }
                                                        placeholder = { this.props.translate(`template_permission_user_search`) }
                                                        type = 'text'
                                                        value = { this.state.searchUser }
                                                    />
                                                </div>
                                    <div className = 'users_section'>
                                                    <Scrollbars style = { { height: 360 } }>
                                                        <ul className = 'users'>
                                                            {sortListUser(this.state.userList.data).map((item, key) => {
                                                                return (
                                                                    <li
                                                                        className = 'user' style = { { cursor: "pointer" } } onClick = { () => {
                                                                            this.setTemplatePm(item.core.user.permission_template_id, `${item.core.user.surname} ${item.core.user.name}`);
                                                                            this.userListToggle();
                                                                        } }>
                                                                        <img alt = '' src = { profile_default } />
                                                                        <p>{item.core.user.surname} {item.core.user.name}</p>
                                                                    </li>
                                                                );
                                                            })}
                                                        </ul>
                                                    </Scrollbars>
                                                </div>
                                    <div
                                                    className = 'btn' onClick = { () => {
                                                        this.userListToggle();
                                                    } }>
                                                    {this.props.translate(`template_management_btn_cancel`)}
                                                </div>
                                </div>
                                            : ''}

                                    </div>
                                    <button onClick = { this.applyTemplatePermission }>{this.props.translate(`template_pm_apply`)}</button>
                                </form>
                                <div className = 'vertical-line' />
                                <input
                                    className = { `template-name ${this.returnErrorTextClass('name')}` }
                                    name = 'name'
                                    placeholder = { this.props.translate(`template_permission_template_name`) }
                                    type = 'text'
                                    onChange = { this.changeTemplateName }
                                    defaultValue = { this.state.templateName }
                                />
                            </div>
                            <div className = 'button-section'>
                                <a
                                    onClick = {
                                        this.savePermission
                                    }
                                    className = 'button custom-salat'>{this.props.translate(`managerPage_save`)}</a>
                                <a
                                    onClick = { () => {
                                        this.props.closePermission(this.state.formPermission);
                                    } } className = 'button bordered custom-border'>{this.props.translate(`managerPage_cancel`)}</a>
                            </div>

                        </div>
                        <div className = 'formTasks_body'>
                            <div className = 'formTasks_body-scroll' id = 'scroll-style'>
                                <PermissionsPageAnchor data = { this.state.dataPermissions.data } />
                                <GridContainer
                                    addField = { this.addField }
                                    checkItem = { this.checkItem }
                                    data = { this.state.dataPermissions.data }
                                    fieldToggle = { this.fieldToggle }
                                    getUserField = { this.getUserField }
                                    isChecked = { this.isChecked }
                                    isEditPopup = { this.isEditPopup }
                                    isFieldChecked = { this.isFieldChecked }
                                    methodToggle = { this.methodToggle }
                                    moduleToggle = { this.moduleToggle }
                                    removeField = { this.removeField }
                                    stateNameToggle = { this.stateNameToggle }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return null;

    }
}

export default PermissionsPage;
