import React from "react";
import {
    Carousel,
    CarouselItem, Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
    Fade
} from 'reactstrap';
import { withRouter } from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import { bindActionCreators } from "redux";
import {
    getTask,
    setEnumsTasks,
    setResetError,
    deleteTask,
    // getSelectAccountModel,
    clearReduxTask,
    downloadDocument,
    deleteComment,
    getCommentsTaskID,
    clearReduxComment
} from "../../services/TasksService";
import {
    updateTaskClient,
    getTaskUsers,
    deleteAllChecklist,
    deleteChecklist,
    updateCheckList,
    createCheckList,
    getDocumentTaskID,
    deleteDocument,
    uploadDocument,
    getCheckListTaskID,
    updateTask,
    uploadParticipants,
} from "../../services/TaskRequestService";
import { map, omit, isNull, has, isEmpty, debounce, upperFirst, isUndefined } from "lodash";
import moment from 'moment';
import InitiatorBlock from "./InitiatorBlock";
import ResponsibleBlock from "./ResponsibleBlock";
import { withLocalize, Translate } from "react-localize-redux";
import { Collapse } from 'reactstrap';
import ClientBlock from "./ClientBlock";
import NotificationService from "../../../../services/NotificationService";
import StatusBlock from './StatusBlock';
import DiscussionBlock from "./DiscussionBlock";
import { get_random_guid } from "../../../../helpers/OtherHelper";
import DocumentsUpload from "./DocumentsUpload";
import AttachmentRow from "./AttachmentRow";
import TaskSpectatorModal from "./TaskModal/TaskSpectatorModal";
import { GET_TASK_HISTORY } from "../../../../apiRoutes";
import DelateTaskPopup from "./DelateTaskPopup";
import HistoryChangesComponent from "../../../../components/HistoryChanges/index";
import SortableComponent from "./SortableComponent/SortableComponent";
import plus from "../../../../images/tasks-plus.svg";
import PermissionService, { MODULE_KEYS as pm } from "../../../../services/PermissionService";
import { toggle } from "../../services/TaskToggleService";
import SpectatorBlock from "./SpectatorBlock";
import clientColor from "../../../../images/clients-gradient.svg";
import clientGrey from "../../../../images/clients-grey.svg";
import deleteImg from "../../../../images/delete.svg";
import taskColor from "../../../../images/tasks-color.svg";
import taskGrey from "../../../../images/tasks-grey.svg";
import time from "../../../../images/time.svg";
import {TASK_PROP_TYPES} from "../../../../components/PropTypes/Task_PropsTypes";
import { getResponsibles, getAssistants} from "../../services/TaskRequestService";
import { updateAppealsAccount } from "../../../clients/services/AccountService";
import { profileService } from "../../../../services/ProfileDataService";
import { enumService } from "../../../../services/EnumDataService";
import { userService } from "../../../../services/UserDataService";

class Task extends React.Component {
    constructor (props) {
        super(props);

        if (!this.props.appeals) {
            document.body.style.overflow = 'hidden';
        }

        this.fileImage = [];
        this.ROLE_1 = 1;
        this.ROLE_2 = 2;
        this.ROLE_3 = 3;
        this.ROLE_4 = 4;
        this.ROLE_5 = 5;

        this.SET_NULL__account_id = { SET_NULL__account_id: 1 };
        this.removeTypeConst = {
            client:    "client",
            task:      "task",
            document:  "document",
            checkList: "checkList",
            comment:   "comment",
        };
        this.state = this.getDefaultState(this.props.taskId);
    }

    enumsChange = async (enums) => {
        if (enums.hash===this.state?.enums?.hash) return `${this.constructor.name}.enumsChange: false`;
        await this.save({ enums, });
        return `${this.constructor.name}.enumsChange: true`;
    };

    profileChange = async (profile) => {
        if (profile.hash===this.state?.profile?.hash) return `${this.constructor.name}.profileChange: false`;
        await this.save({ profile, });
        return `${this.constructor.name}.profileChange: true`;
    };

    avatarsChange = async (usersAllAvatar) => {
        if (usersAllAvatar.hash===this.state?.usersAllAvatar?.hash) return `${this.constructor.name}.avatarsChange: false`;
        await this.save({ usersAllAvatar });
        return `${this.constructor.name}.avatarsChange: true`;
    };

    scrollChildrenComment = React.createRef();

    async componentDidMount() {
        enumService.subscribe('enums', this.enumsChange, this);
        profileService.subscribe('profile', this.profileChange, this);
        userService.subscribe('getAllAvatar', this.avatarsChange, this);
        await Promise.all([
            enumService.enums,
            profileService.profile
        ]);
        toggle.init(this);
        this.getData(this.props.taskId);
    }

    UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
        if (this.props.appeals && this.props.taskId !== nextProps.taskId) {
            this.SET_NULL__account_id = { SET_NULL__account_id: 1 };

            this.setState(this.getDefaultState(nextProps.taskId));
            this.getData(nextProps.taskId);
        }

        if (!isEmpty(nextProps.checkList)) {
            this.setState({
                checkList: nextProps.checkList,
            });
        }

        if (this.props?.task?.tasks?.tasks && !this.state.dataTask.state_id) {
            this.setState({
                dataTask: { ...this.state.dataTask, description: this.props?.task?.tasks?.tasks.description },
            });
        }
    }

    componentWillUnmount() {
        enumService.unsubscribe('enums', this.enumsChange, this);
        profileService.unsubscribe('profile', this.profileChange, this);
        userService.unsubscribe('getAllAvatar', this.avatarsChange, this);
        this.props.clearReduxTask();
        this.props.clearReduxComment();
    }

    getDefaultState = (task_id) => {
        return {
            roleUser:           0,
            taskModal:          false,
            showUploadDocument: false,
            editHead:           false,
            editDescription:    false,
            actionMenu:         false,
            activeIndex:        0,
            collapse:           false,
            taskId:             task_id,
            removeType:         "",
            removeDocumentId:   null,
            checkList:          [],
            documents:          [],
            dataTask:           {
                name:         '',
                description:  '',
                type_id:      '',
                state_id:     '',
                urgency_id:   '',
                finish_date:  new Date(),
                addCheckList: false,
                link:         null,
            },
            dataTaskClient: {
                account_id: '',
            },
            createTaskSelect: {
                type_id:    '',
                state_id:   '',
                urgency_id: '',
            },
            createParticipants: {
                task_id,
                user_id: '',
                role_id: '',
            },
            checkListTask: {
                task_id,
                name:  '',
                check: '1',
            },
            participantsId:     '',
            addCheckList:       false,
            checklistEmpty:     false,
            fadeIn:             false,
            fadeInPop:          false,
            fadeInWraper:       false,
            clientName:         'Client',
            fieldClientName:    'account_id',
            usersAllAvatar:     {
                hash: null,
                data: {},
            },
            errorForm:          {},
            showHistoryChanges: false,
            getAllManager:      {},
            selectPartipiants:  {
                initiator:   {},
                responsible: {},
                spectator:   {},
            },
            getCreator:       {},
            getResponsible:   {},
            getSpectator:     {},
            getAssistance:    [],
            checkReset:       '',
            sortBtnChecklist: false,
        };
    };

    save = async (state) => new Promise(next => this.setState(state, next));

    getData = async (taskId) => {
        this.props.setEnumsTasks();
        this.props.getTask(taskId);
        this.setChecklistTask(taskId);
        // this.props.getSelectAccountModel();
        this.setDocumentTask(taskId); // HIDE DocumentsUpload REQUEST IN CURRENT VERSION OF CRM
        const usersAllAvatar = await userService.getAllAvatar();
        await this.save({ usersAllAvatar });

        this.getTaskParticipants(taskId, this.ROLE_1);
        this.getTaskParticipants(taskId, this.ROLE_3);
        // this.getTaskParticipants(taskId, this.ROLE_4);
        this.getTaskParticipants(taskId, this.ROLE_5);
    };

    setDocumentTask = (taskId) => {
        getDocumentTaskID(taskId).then((response) => {
            this.setState({
                documents: response.data,
            });
        }).catch((error) => {

        });
    };

    setChecklistTask = (taskId) => {
        getCheckListTaskID(taskId).then((response) => {
            this.setState({
                checkList: response.data,
            });
        }).catch((error) => {

        });
    };

    removeParticipantsClient = () => {
        updateTaskClient(this.SET_NULL__account_id, this.state.taskId).then((response) => {
            this.props.getTask(this.state.taskId);
            toggle.popup();
        }).catch((error) => {
            NotificationService.error({
                title:   "error",
                message: "Permission denied",
            });
            toggle.popup("");
        });
    };

    onExiting = () => {
        this.animating = true;
    };

    onExited = () => {
        this.animating = false;
    };

    save = async (state) => new Promise(next => this.setState(state, next));

    next = () => {
        if (this.animating) {
            return;
        }
        const nextIndex = this.state.activeIndex === Math.ceil(this.state.documents.length/3-1) ? 0 : this.state.activeIndex + 1;

        this.setState({ activeIndex: nextIndex });
    };

    previous = () => {
        if (this.animating) {
            return;
        }
        const nextIndex = this.state.activeIndex === 0 ? Math.ceil(this.state.documents.length/3-1) : this.state.activeIndex - 1;

        this.setState({ activeIndex: nextIndex });
    };

    handleChangeData = async (date) => {
        await this.save({ finish_date: new Date( date ) } );

        this.updateData({ finish_date: moment(date).format('YYYY-MM-DD HH:mm:ss') });
    };

    save = async (state) => new Promise(next => this.setState(state, next));

    setToStateSelect = async (event, name) => {
        await this.save({
            createTaskSelect: { ...this.state.createTaskSelect, [name]: event.value },
        });

        this.updateData({ [name]: event.value });
    };

    updateData = (data) => {
        updateTask(data, this.state.taskId).then((response) => {
            this.removeError(Object.keys(data)[0]);
            this.props.getTask(this.state.taskId);

            const updatedData = {};

            for (const key in response) {
                if (response.hasOwnProperty(key)) {
                    updatedData[`tasks-tasks-${key}`] = response[key];
                }
            }
            this.props.updateAppealsAccount(updatedData);
        }).catch((error) => {
            this.removeError(Object.keys(data)[0]);

            NotificationService.errors(error);

            for (let i = 0; i <= Object.keys(error.errors).length - 1; i++) {
                const name = Object.keys(error.errors)[i];

                this.setErrorData(name, error.errors[name], true);
            }
        });
    };

    removeError = (data) => {
        this.setState({ errorForm:
                { ...omit(this.state.errorForm, data) },
        });
    };

    sendDocument = (data) => {
        uploadDocument(data, this.state.taskId).then(() => {
            this.setDocumentTask(this.state.taskId);
        }).catch((error) => {
            this.setState({ errorForm: {}});
            NotificationService.errors(error);

            for (let i = 0; i <= Object.keys(error.errors).length - 1; i++) {
                const name = Object.keys(error.errors)[i];

                this.setErrorData(name, error.errors[name], true);
            }
        });
    };

    handleselectedFile = (event) => {
        const data = new FormData();

        data.append('task_id', this.state.taskId);
        data.append('file', event.target.files[0]);

        this.sendDocument(data);
    };

    downloadDoc = async (file) => {
        try {
            const { status, headers, data } = await downloadDocument(file);
            const blob = new Blob([data], { type: headers['content-type'] });

            if (status > 199 && status < 300) {
                if (window.navigator.msSaveBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, file);
                } else {
                    const objectUrl = URL.createObjectURL(blob);

                    window.open(objectUrl);
                }
            }
        } catch (err) {
            console.error(err);
        }
    };

    getTaskParticipants = async (taskId = null, role_id) => {
        await this.props.getTask(this.state.taskId);
        const response = await getTaskUsers(isNull(taskId) ? this.state.taskId : taskId, role_id);
        let keyParticipants = "";
        let valueParticipants = "";
        let task_users = this.props?.task?.tasks?.task_users?.filter(roleId => (roleId.role_id === 4 || roleId.role_id === 5));

        switch (role_id) {
            case this.ROLE_1:
                keyParticipants = "getCreator";
                valueParticipants = response[0];
                break;
            case this.ROLE_3:
                keyParticipants = "getResponsible";
                valueParticipants = response[0];
                break;
            case this.ROLE_4:
                task_users?.forEach?.(({ user_id, id }) => {
                    const foundElem = response.find(spectatorId => spectatorId.id === user_id);
                    foundElem ? foundElem["delete_id"] = id : null;
                });
                keyParticipants = "getSpectator";
                valueParticipants = response;
                break;
            case this.ROLE_5:
                task_users?.forEach?.(({ user_id, id }) => {
                    const foundElem = response.find(spectatorId => spectatorId.id === user_id);
                    foundElem ? foundElem["delete_id"] = id : null;
                });
                keyParticipants = "getAssistance";
                valueParticipants = response;
                break;
            default:
                break;
        }
        this.setState({ [keyParticipants]: valueParticipants });
    };

    getAllManager = async (role = null) => {
        let response = null;

        try {
            if (role === this.ROLE_5) {
                response = await getAssistants();
            } else {
                response = await getResponsibles();
            }

            await this.save({ getAllManager: response });
        } catch (err) {
            console.error('error');
        }
    };

    // clientBlock = () => {
    //     return has(this.props.task, "core") ?
    //         map(this.props.getAccountModel, (item, key) => {
    //             if (item['core-account-id'] === this.props.task.core.account.id) {
    //                 return (<ClientBlock
    //                     get_task_users = { item }
    //                     key = { key }
    //                     usersAllAvatar = { this.state.usersAllAvatar }
    //                 />);
    //             }
    //         })
    //         :
    //         "";
    // };

    returnErrorTextClass = (className) => {
        return has(this.state.errorForm, className) ? "error" : "";
    };

    getSpectatorData = () => {
        if (this.state.roleUser === this.ROLE_4) {
            return this.state.getSpectator;
        } else if (this.state.roleUser === this.ROLE_2) {
            return this.state.getCreator ? [this.state.getCreator] : [];
        } else if (this.state.roleUser === this.ROLE_3) {
            return this.state.getResponsible ? [this.state.getResponsible] : [];
        } else if (this.state.roleUser === this.ROLE_5) {
            return this.state.getAssistance ? this.state.getAssistance : [];
        }

        return [];
    }

    spectatorModal = () => {
        return (<TaskSpectatorModal
            fade = { this.state.fadeIn }
            // getAccountModel = { this.props.getAccountModel }
            getAllManager = { this.state.getAllManager }
            getSpectator = { this.getSpectatorData() }
            getTaskParticipantsMethod = { this.getTaskParticipants }
            isChange = { this.getSpectatorData()?.length !== 0 }
            ROLE_1 = { this.ROLE_1 }
            ROLE_2 = { this.ROLE_2 }
            ROLE_3 = { this.ROLE_3 }
            ROLE_4 = { this.ROLE_4 }
            ROLE_5 = { this.ROLE_5 }
            roleUser = { this.state.roleUser }
            task = { this.props.task }
            taskId = { this.props.taskId }
            toggleModalTask = { toggle.modalTask }
            translate = { this.props.translate }
            type = 'task'
            updateDataClient = { this.updateDataClient }
            usersAllAvatar = { this.state.usersAllAvatar.data }
        />);
    };

    renderDocuments = (documents) => {
        return (
            <Carousel
                activeIndex = { this.state.activeIndex }
                next = { () => {} }
                previous = { () => {} }>
                {documents}
                {this.state.documents.length > 3 ?
                    <div>
                        <span className = 'document-previos' onClick = { this.previous }>
                            <i className = 'la la-angle-left' />
                        </span>
                        <span className = 'document-next' onClick = { this.next }>
                            <i className = 'la la-angle-right' />
                        </span>
                    </div>
                    : ""
                }
            </Carousel>
        );
    };

    deleteClientAction = () => {
        this.props.deleteTask(this.state.taskId);
        toggle.popup();
        toggle.closeTask();
    };

    //account_id
    updateDataClient = ({user_id, task_id, role_id}) => {
        uploadParticipants({ user_id, task_id, role_id }).then(async (response) => {
            await this.getTaskParticipants(this.state.taskId, role_id);
            // this.props.getTask(this.state.taskId);
        }).catch((error) => {
            this.setState({ errorForm: {}});

            NotificationService.errors(error);

            for (let i = 0; i <= Object.keys(error.errors).length - 1; i++) {
                const name = Object.keys(error.errors)[i];

                this.setErrorData(name, error.errors[name], true);
            }
        });
    };

    setErrorData = (name, message, status) => {
        this.setState({ errorForm:
                { ...this.state.errorForm, [name]: { message, error: status }},
        });
    };

    setToCheck = (event, name, id) => {
        this.setState({
            checkListTask: {
                ...this.state.checkListTask,
                'name':  name.target.parentElement.nextElementSibling.children[0].value,
                'check': event === true ? 2 : 1,
            }}, () => {
            updateCheckList(this.state.checkListTask, id).then(() => {
                this.setChecklistTask(this.state.taskId);
            }).catch((error) => {
                NotificationService.error({
                    title:   "error",
                    message: error?.error,
                });
            });
        });
    };

    sendCheckList = () => {
        const sendData = { ...this.state.checkListTask, ...{ sort: this.state.checkList.length + 1 }};

        createCheckList(sendData).then(() => {
            this.setChecklistTask(this.state.taskId);
            this.setState({
                checkListTask: {
                    ...this.state.checkListTask,
                    name: "",
                },
            });
        }).catch((errors) => {
            NotificationService.errors(errors);
        });
    };

    setToStateCheckList = (event) => {
        this.setState({
            checkListTask: {
                ...this.state.checkListTask,
                [event.target.name]: event.target.value,
            },
            checkReset: event.target.value,
        });
    };

    sortBtnChecklistFunc = () => {
        this.setState({ sortBtnChecklist: !this.state.sortBtnChecklist });
    };

    checkReset = () => {
        this.setState({ checkReset: '' });
    };

    removeAllCheckList = () => {
        this.setState({ checklistEmpty: false });

        deleteAllChecklist(this.state.taskId)
            .then(() => {
                this.setChecklistTask(this.state.taskId);
                toggle.popup();
            }).catch((error) => {
            toggle.popup();
            NotificationService.error({
                title:   "error",
                message: error,
            });
        });
    };

    removeCheckList = (id, dataSort) => {
        deleteChecklist(id)
            .then(() => {
                const arrayList = [];

                let newItem = {};

                dataSort.map((item, key) => {
                    if (item['tasks-task_check_lists-id'] !== id) {
                        newItem = item;
                        newItem['tasks-task_check_lists-sort'] = key + 1;

                        arrayList.push(newItem);
                    }
                });
                this.setState({
                    checkList: arrayList,
                });
            }).catch((error) => {
            NotificationService.error({
                title:   "error",
                message: error.error,
            });
        });
    };

    _handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            this.sendCheckList();
            this.checkReset();
        }
    };
    _handleKeyDownInputCheckBox = (event, id) => {
        const editedChecklist = this.state.checkList.map((item) => {
            if (item['tasks-task_check_lists-id'] === id) {
                item = {
                    ...item,
                    'tasks-task_check_lists-name': event.target.value,
                    'tasks-task_check_lists-check': event.target.dataset.check,
                };
            }

            return item;
        });

        this.setState({
            checkList: editedChecklist,
            checkListTask: {
                ...this.state.checkListTask,
                [event.target.name]: event.target.value,
                check:               event.target.dataset.check,
            }
        }, () => {
            updateCheckList(this.state.checkListTask, id).then(() => {
                this.setChecklistTask(this.state.taskId);
            }).catch((error) => {
                NotificationService.error({
                    title:   "error",
                    message: error,
                });
            });
        });
    };

    checklistCreate = () => {
        this.setState({ checklistEmpty: !this.state.checklistEmpty });
    };

    deleteDocumentAction = () => {
        deleteDocument(this.state.removeDocumentId).then(() => {
            this.setDocumentTask(this.props.taskId);
            toggle.popup();
        }).catch((error) => {
            NotificationService.error({
                title:   "error",
                message: error,
            });
        });
    };

    deleteCommentAction = () => {
        this.props.deleteComment(this.state.removeDocumentId).then(() => {
            this.props.getCommentsTaskID(this.props.taskId);
            toggle.popup();
        });
    };

    renderCheckList = () => {
        return (
            <Translate>
                {({ translate }) => {
                    return (
                        <div className = 'flex-wrapper ckecklist-block tasks' id = 'ckecklist-blockScroll'>

                            <SortableComponent
                                _handleKeyDownInputCheckBox = { this._handleKeyDownInputCheckBox }
                                checkList = { this.state.checkList }
                                checkListTask = { this.state.checkListTask }
                                removeCheckList = { this.removeCheckList }
                                setChecklistTask = { this.setChecklistTask }
                                setToCheck = { this.setToCheck }
                                sortBtnChecklist = { this.state.sortBtnChecklist }
                                sortBtnChecklistFunc = { this.sortBtnChecklistFunc }
                                taskId = { this.state.taskId }
                                translate = { translate }
                            />

                            <div className = 'ckecklist-block-add'>
                                <img alt = 'plus' onClick = { toggle.checkList } src = { plus } />
                                {this.state.addCheckList ?
                                    <label>
                                        <input
                                            autoFocus = 'true'
                                            onChange = { this.setToStateCheckList }
                                            onKeyDown = { this._handleKeyDown }
                                            name = 'name'
                                            required
                                            placeholder = { `${translate(`tasks_describe_task`)}...` }
                                            value = { this.state.checkReset }
                                            autoComplete = 'off'
                                        />
                                        <div className = 'ckecklist-block-add-post'>
                                            <span
                                                className = 'span-add-button-checkList'
                                                onClick = { () => {
                                                    this.sendCheckList();
                                                    this.checkReset();
                                                } }><i className = 'add-button-checkList fa fa-check-circle' /></span>
                                            <span
                                                className = 'span-close-button-checkList'
                                                onClick = { () => {
                                                    toggle.checkList();
                                                    this.checkReset();
                                                } }><i className = 'close-button-checkList la la-times-circle-o' /></span></div>
                                    </label>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    );
                }}
            </Translate>
        );
    };

    handleClick = (e) => {
        if (!this._delayedClickHead) {
            this._delayedClickHead = debounce(this.doClick, 500);
        }
        if (this.clickedOnceHead) {
            this._delayedClickHead.cancel();
            this.clickedOnceHead = false;
            this.setState({
                editHead: true,
                dataTask: { ...this.state.dataTask, name: this.props.task.tasks.tasks.name },
            });
        } else {
            this._delayedClickHead(e);
            this.clickedOnceHead = true;
        }
    };

    doClick = (e) => {
        this.clickedOnceHead = undefined;
    };

    onBlurHead = () => {
        this.updateData({
            name: this.state.dataTask.name,
        });
        this.setState({
            editHead: false,
        });
    };

    changeHead = (event) => {
        this.setState({
            dataTask: { ...this.state.dataTask, name: event.target.value },
        });
    };

    handleClickDescr = (e) => {
        if (!this._delayedClickDescr) {
            this._delayedClickDescr = debounce(this.doClickDescr, 500);
        }
        if (this.clickedOnceDescr) {
            this._delayedClickDescr.cancel();
            this.clickedOnceDescr = false;
            this.setState({ editDescription: true });
        } else {
            this._delayedClickDescr(e);
            this.clickedOnceDescr = true;
        }
    };

    doClickDescr = (e) => {
        this.clickedOnceDescr = undefined;
    };

    onBlurDescr = async () => {
        this.updateData({ description: this.state.dataTask.description });
        this.setState({
            editDescription: false,
        });
    };

    changeDescr = (event) => {
        this.setState({
            dataTask: { ...this.state.dataTask, description: event.target.value },
        });
    };

    fileImgFuncClear = (id) => {
        const newFile = [];

        map(this.fileImage, (item, key) => {
            if (id !== item.id) {
                newFile.push(item);
            }
        });

        this.fileImage = newFile;
    };

    fileImgFunc = (img) => {
        this.fileImage.push(img);
    };

    hideUploadDocument = () => {
        this.setState({
            showUploadDocument: false,
        });
    };

    renderTask = () => {
        const task = this.props.task;

        let renderDocuments = [];

        if (this.state.documents.length > 0) {
            const arrayDocuments = Array.apply(null, { length: Math.ceil(this.state.documents.length / 3) }).map((value, index) => {
                return index * 3;
            });

            renderDocuments = this.renderDocuments(arrayDocuments.map((item, index) => {
                return (
                    <CarouselItem
                        key = { item }
                        onExited = { this.onExited }
                        onExiting = { this.onExiting }>
                        <div className = 'carousel-inner-flex'>
                            {
                                this.state.documents.slice(item, item + 3).map((item2, index2) => {
                                    return (
                                        <AttachmentRow
                                            attachment = { item2 }
                                            deleteDocumentAction = { toggle.popup }
                                            documents = { this.state.documents }
                                            downloadDoc = { this.downloadDoc }
                                            fileImage = { this.fileImage }
                                            fileImgFunc = { this.fileImgFunc }
                                            fileImgFuncClear = { this.fileImgFuncClear }
                                            key = { index2 }
                                            statusEnum = { this.props.statusEnum }
                                            type = { this.removeTypeConst }
                                            typeEnum = { this.props.typeEnum }
                                        />
                                    );
                                })
                            }
                        </div>
                    </CarouselItem>
                );
            }));
        }

        const taskLinks = task.tasks.task_trading_accounts;

        return (
            <Translate>
                {({ translate }) => {
                    return (
                        <div>
                            <div className = { `modal-task-page modal-task-page--special` } style = { this.props.appeals ? { 'zIndex': this.state.fadeInPop ? "999" : "998", 'position': 'relative' } : {} }>
                                <div className="modal-task-container--special">
                                    <div className='left'>
                                        {this.state.showHistoryChanges
                                            ? <HistoryChangesComponent
                                                closeFunction = { toggle.historyChanges }
                                                url = { `${GET_TASK_HISTORY}/${this.state.taskId}` }
                                            />
                                            : ""
                                        }
                                        <div className = 'sidebar-wrapper sideBar-leads' style = { this.props.appeals ? { 'top': '0', 'position': 'fixed' } : {} }>
                                            {this.state.fadeInPop ?
                                                <div className = 'overlay' />
                                                : ""}
                                            <div
                                                className = { `lead-block-wrapper ${this.state.fadeInWraper ? 'overflow ' : ''}${this.state.fadeInPop ? 'overflow' : ''}` }>
                                                <div className = 'lead-info-wrapper' style = { this.props.appeals ? { 'alignItems': 'flex-start' } : {} }>
                                                    <Fade className = 'fade-block' in = { this.state.fadeInPop }>
                                                        <DelateTaskPopup
                                                            delete = {
                                                                (() => {
                                                                    switch (this.state.removeType) {
                                                                        case this.removeTypeConst.task :
                                                                            return this.deleteClientAction;
                                                                        case this.removeTypeConst.client :
                                                                            return this.removeParticipantsClient;
                                                                        case this.removeTypeConst.checkList :
                                                                            return this.removeAllCheckList;
                                                                        case this.removeTypeConst.document :
                                                                            return this.deleteDocumentAction;
                                                                        case this.removeTypeConst.comment :
                                                                            return this.deleteCommentAction;
                                                                    }
                                                                })()
                                                            }
                                                            toogle = { toggle.popup }
                                                            translate = { translate }
                                                            type = { this.state.removeType }
                                                        />
                                                    </Fade>
                                                </div>
                                            </div>
                                        </div>
                                        <div className = 'task-page'>
                                            <div className = { `task-block-wrapper ${!this.state.taskModal ? 'hidden ' : ''}` }>
                                                <div className = 'users-wrapper' style = { { zIndex: 1002 } }>
                                                    {this.spectatorModal()}
                                                </div>
                                            </div>
                                        </div>
                                        {!this.props.appeals ?
                                            <div className = 'overlay' onClick = { toggle.closeTask } />
                                            : ""
                                        }
                                        <div className = 'modal-task-container modal-task-container--changed' style = { this.props.appeals ? { 'marginTop': '0', 'paddingTop': '0' } : {} }>
                                            {/*<div className = 'modal-task-container__titl'>
                                            <h6 className = 'modal-task-container__titleHead' style = { this.state.editHead ? { width: '100%', display: 'flex' } : {} } onClick = { this.handleClick }>
                                                ID {this.state.taskId}
                                                :
                                                {this.state.editHeadeditHead ?
                                                    <input
                                                        autoFocus = 'true'
                                                        defaultValue = { task.tasks.tasks.name }
                                                        onBlur = { this.onBlurHead }
                                                        onChange = { this.changeHead }
                                                        style = { { width: '400px', marginTop: '-1px' } }
                                                        type = 'text'
                                                        value = { this.state.dataTask.name }
                                                    />
                                                    :
                                                    task.tasks.tasks.name
                                                }

                                            </h6>
                                            {!this.props.appeals ?
                                                <div className = 'modal-task-container__close' onClick = { toggle.closeTask }><i
                                                    className = 'la la-close'
                                                /></div>
                                                : ""
                                            }
                                        </div>*/}
                                            <div className = 'modal-task-container__users-wrapper'>
                                                <div className = 'small-column small-column--special'>
                                                    <ResponsibleBlock
                                                        get_enums_tasks = { this.state?.enums?.data?.tasks }
                                                        getAllManager = { this.getAllManager }
                                                        getResponsible = { !isEmpty(this.state.getResponsible) ? this.state.getResponsible : '' }
                                                        ROLE_3 = { this.ROLE_3 }
                                                        toggleModal = { toggle.modalTask }
                                                        usersAllAvatar = { this.state.usersAllAvatar?.data }
                                                        userRoleId = { this.state.profile?.data?.permission_template_id }
                                                    />
                                                    {/* <div className = 'lead-block'>
                                                    {this.clientBlock()}
                                                </div> */}

                                                    <div className = 'lead-block lead-block--special' style = { { minWidth: '114px', } }>
                                                        <Dropdown isOpen = { this.state.dropdownOpen } toggle = { toggle.dropdown }>
                                                            <DropdownToggle tag = { 'div' }>
                                                            <span
                                                                className = 'action-button' onClick = { () => {
                                                                toggle.actionMenu();
                                                            } }>
                                                                <i className = { `fa fa-plus-circle` } />
                                                                <div className = 'text'>
                                                                    {translate(`tasks_action`)}
                                                                </div>
                                                            </span>
                                                            </DropdownToggle>
                                                            <DropdownMenu className = 'action-menu'>
                                                                {/* {isNull(task.core.account.id) ?
                                                                <DropdownItem
                                                                    className = 'first'
                                                                    onClick = { () => {
                                                                        toggle.modalTask(this.ROLE_1);
                                                                    } } tag = { 'div' }>
                                                                    <div className = 'action-img-wrapper'><img alt = 'client' src = { clientColor } /></div>
                                                                    {translate(`task_add_client`)}
                                                                </DropdownItem>
                                                                :
                                                                <DropdownItem
                                                                    className = 'first'
                                                                    onClick = { () => {
                                                                        toggle.popup(this.removeTypeConst.client);
                                                                    } } tag = { 'div' }>
                                                                    <div className = 'action-img-wrapper'><img alt = 'client' src = { clientGrey } /></div>
                                                                    {translate(`task_remove_client`)}
                                                                </DropdownItem>
                                                            } */}
                                                                {!isUndefined(this.state.checkList) && this.state.checkList.length > 0 || this.state.checklistEmpty ?
                                                                    <React.Fragment>
                                                                        {PermissionService.actionForRoute(pm.TASKS_TASK_CHECK_LISTS, "deleteCheckListsByTaskId") ?
                                                                            <DropdownItem
                                                                                className = 'middle'
                                                                                onClick = { () => {
                                                                                    toggle.popup(this.removeTypeConst.checkList);
                                                                                } } tag = { 'div' }>
                                                                                <div className = 'action-img-wrapper'><img alt = 'task' src = { taskGrey } /></div>
                                                                                {translate(`task_remove_check_list`)}
                                                                            </DropdownItem>
                                                                            :
                                                                            ""}
                                                                    </React.Fragment>
                                                                    :
                                                                    <DropdownItem className = 'middle' onClick = { this.checklistCreate } tag = { 'div' }>
                                                                        <div className = 'action-img-wrapper'><img alt = 'task' src = { taskColor } /></div>
                                                                        {translate(`task_add_check_list`)}
                                                                    </DropdownItem>

                                                                }
                                                                <DropdownItem className = 'middle' onClick = { toggle.historyChanges } tag = { 'div' }>
                                                                    <div className = 'action-img-wrapper'><img alt = 'history' src = { time } /></div>
                                                                    {translate(`tasks_history`)}
                                                                </DropdownItem>
                                                                {/* <DropdownItem
                                                                tag = { 'div' } onClick = { () => {
                                                                    toggle.Popup(this.removeTypeConst.task);
                                                                } } className = 'last'>
                                                                <div className = 'action-img-wrapper'><img alt = 'delete' src = { deleteImg } /></div>
                                                                {translate(`tasks_delete_task`)}
                                                            </DropdownItem> */}
                                                            </DropdownMenu>

                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <Collapse isOpen = { this.state.collapse }>
                                                <div className = 'modal-task-container__spoiler'>
                                                    <div className = 'spoiler_content'>
                                                        <div className = 'modal-task-container__users-wrapper'>
                                                            <div className = 'small-column'>
                                                                <InitiatorBlock
                                                                    get_enums_tasks = { this.state?.enums?.data?.tasks }
                                                                    getAllManager = { this.getAllManager }
                                                                    getCreator = { !isEmpty(this.state.getCreator) ? this.state.getCreator : '' }
                                                                    ROLE_1 = { this.ROLE_1 }
                                                                    toggleModal = { toggle.modalTask }
                                                                    usersAllAvatar = { this.state.usersAllAvatar.data }
                                                                />
                                                                <div className = 'spectator-block' >
                                                                    <div className = 'block'>
                                                                        <div>{translate(`tasks_task_assistance`)}</div>
                                                                        {!isEmpty(this.state.getAssistance) ?
                                                                            <div
                                                                                className = 'edit' onClick = {
                                                                                async () => {
                                                                                    await this.getAllManager(this.ROLE_5);
                                                                                    toggle.modalTask(this.ROLE_5);//todo
                                                                                }
                                                                            }>{translate(`tasks_edit`)}<i className = 'fa fa-pencil' /></div>
                                                                            :
                                                                            ""
                                                                        }
                                                                    </div>

                                                                    <div className = 'block-content'>
                                                                        {!isEmpty(this.state.getAssistance) ?
                                                                            this.state.getAssistance?.map((item, value) => {
                                                                                return (
                                                                                    <SpectatorBlock
                                                                                        item = { item }
                                                                                        value = { value }
                                                                                    />
                                                                                );
                                                                            })
                                                                            :
                                                                            <div
                                                                                className = 'spectator-add' onClick = {
                                                                                () => {
                                                                                    this.getAllManager(this.ROLE_5);
                                                                                    toggle.modalTask(this.ROLE_5);//todo
                                                                                }
                                                                            }>
                                                                                <i className = 'fa fa-user' /> {translate(`tasks_assistance_add`)}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {/* <div className = 'spectator-block' >
                                                                    <div className = 'block'>
                                                                        <div>{translate(`tasks_spectator`)}</div>
                                                                        {!isEmpty(this.state.getAssistance) ?
                                                                            <div
                                                                                className = 'edit' onClick = {
                                                                                () => {
                                                                                    this.getAllManager();
                                                                                    toggle.modalTask(this.ROLE_4);//todo
                                                                                }
                                                                            }>{translate(`tasks_edit`).toLowerCase()}<i className = 'fa fa-pencil' /></div>
                                                                            :
                                                                            ""
                                                                        }
                                                                    </div>

                                                                    <div className = 'block-content'>
                                                                        {!isEmpty(this.state.getSpectator) ?
                                                                            this.state.getSpectator.slice(0, 8).map((item, value) => {
                                                                                return (
                                                                                    <SpectatorBlock
                                                                                        item = { item }
                                                                                        value = { value }
                                                                                    />
                                                                                );
                                                                            })
                                                                            :
                                                                            <div
                                                                                className = 'spectator-add' onClick = {
                                                                                () => {
                                                                                    this.getAllManager();
                                                                                    toggle.modalTask(this.ROLE_4);//todo
                                                                                }
                                                                            }>
                                                                                <i className = 'fa fa-user' /> {translate(`tasks_spectator_add`)}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Collapse>
                                            <div className = 'toggle-button-wrapper'>
                                            <span className = 'toggle-button' onClick = { toggle.collapse }>
                                                <i className = { `la la-angle-${(this.state.collapse ? 'up' : 'down')}` } />
                                            </span>
                                            </div>
                                            <div className = 'modal-task-container__info'>{/*appealsList__info*/}
                                                <div className = 'page'>
                                                    <StatusBlock
                                                        createTaskSelect = { this.state.createTaskSelect }
                                                        dataTask = { this.state.dataTask }
                                                        error = { this.returnErrorTextClass }
                                                        handleChangeData = { this.handleChangeData }
                                                        selected = { this.state.finish_date }
                                                        setToStateSelect = { this.setToStateSelect }
                                                        task = { task }
                                                        translate = { translate }
                                                        userRoleId = { this.state.profile?.data?.permission_template_id }
                                                    />
                                                </div>
                                            </div>
                                            <div className = { `modal-edit-description ${(this.state.editDescription ? 'editDescription' : '')}` }>
                                                <div className = 'modal-task-container__titl editDescriptionPadding'>
                                                    <h6 className = 'modal-task-container__titleHeadList'>
                                                        {upperFirst(translate(`tasks_description`))}
                                                    </h6>
                                                    {this.state.editDescription ?
                                                        <div
                                                            className = 'modal-task-container__close'
                                                            style = { { paddingRight: '0px' } }>
                                                            <div
                                                                className = 'custom-button'>
                                                                <span>{translate(`tasks_createform_save`)}</span>
                                                            </div>
                                                        </div>
                                                        : ""
                                                    }
                                                </div>
                                                <div className = 'modal-task-container__info renderDescription' onClick = { this.handleClickDescr }>
                                                    <div className = 'modal-task-container__descr_content'>
                                                        {this.state.editDescription ?
                                                            <textarea
                                                                autoFocus = 'true'
                                                                defaultValue = { this.state.dataTask.description }
                                                                name = 'description'
                                                                onBlur = { this.onBlurDescr }
                                                                onChange = { this.changeDescr }
                                                                value = { this.state.dataTask.description } />
                                                            :
                                                            <p>{this.state.dataTask.description}</p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {(!isEmpty(taskLinks) && taskLinks.length > 0) ?
                                                <div className='modal-task-container__info renderLink'>
                                                    {taskLinks.map((item, key) => (
                                                        <p>
                                                            <a href={"/list-accounts/tradings/" + item.trading_account_id}
                                                               target="_blank"
                                                               style={{color: '#007bff'}}
                                                            >
                                                                {item.link}
                                                            </a>
                                                        </p>
                                                    ))}
                                                </div>
                                                : ""
                                            }
                                            {!isEmpty(this.state.documents) ?
                                                <div className='modal-task-container__info renderDocument'>
                                                    <div className='attachments-list'>
                                                        {renderDocuments}
                                                    </div>
                                                </div>
                                                : ""
                                            }
                                            <div className = 'attachments' ref = { this.scrollChildrenComment }>
                                                <DocumentsUpload
                                                    handleselectedFile = { this.handleselectedFile }
                                                    translate = { translate }
                                                    canUpload = { this.state.documents.length < 5}
                                                />
                                            </div>

                                            {!isUndefined(this.state.checkList) && this.state.checkList.length > 0 ?
                                                <div className = 'modal-task-container__info renderChecklist'>
                                                    <div className = 'checklist-column'>
                                                        {this.renderCheckList()}
                                                    </div>
                                                </div>
                                                :
                                                <React.Fragment>
                                                    {this.state.checklistEmpty ?
                                                        <div className = 'modal-task-container__info renderChecklist'>
                                                            <div className = 'checklist-column'>
                                                                {this.renderCheckList()}
                                                            </div>
                                                        </div>
                                                        :
                                                        ""
                                                    }
                                                </React.Fragment>
                                            }
                                        </div>
                                    </div>
                                    <div className='right'>
                                        <div className = 'modal-task-container__info' style = { { padding: '0px' } }>
                                            <div className = 'page'>
                                                <DiscussionBlock
                                                    key = { get_random_guid }
                                                    remove = { toggle.popup }
                                                    scrollComment = { toggle.scrollComment }
                                                    task = { task }
                                                    translate = { translate }
                                                    type = { this.removeTypeConst.comment }
                                                    assistants = { this.state.getAssistance ?? [] }
                                                    managers = {this.state.getAllManager ?? [] }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }}
            </Translate>
        );
    };

    render () {
        const task = this.props.task;

        if (!isUndefined(task)) {
            return this.renderTask();
        }

        return null;

    }
}

const mapStateToProps = (store) => {
    return {
        task:            store.tasks.task.data,
        // get_enums_tasks: store.tasks.enums,
        taskHistory:     store.tasks.taskHistory,
        // getAccountModel: store.tasks.setSelectAccountModel.data,
        // enums:           store.enums,
        userRoleId:      store.user.profile.role_id,
    };
};

Task.propTypes = {
    task: TASK_PROP_TYPES,
};

export default withRouter(withLocalize(connect(
    mapStateToProps,
    (dispatch) =>
        bindActionCreators({
            getTask,
            updateAppealsAccount,
            setEnumsTasks,
            setResetError,
            deleteTask,
            // getSelectAccountModel,
            clearReduxTask,
            downloadDocument,
            deleteComment,
            getCommentsTaskID,
            clearReduxComment,
            // getAllEnums,
        }, dispatch)
)(Task)));
