import React from "react";
import dateFns from "date-fns";
import UserProgress from './UserProgress';
// import { connect } from "react-redux";
import DataStorage from "../../../../helpers/DataStorage";
import {withLocalize, Translate} from "react-localize-redux";
import {withRouter} from "react-router-dom";
// import {bindActionCreators} from "redux";

// import { getKPIData, getUsersDepartment } from "../../services/KPIService";
import { KPIWidgetFilter } from './KPIWidgetFilter';
import RenderMonth from './RenderMonth';
import { profileService } from "../../../../services/ProfileDataService";

class MonthLine extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            currentMonth: new Date(),
            selectedDate: new Date(),
            // dataUserId: this.props.userId,
            dataMonth: new Date().getMonth() + 1,
            dataYear: new Date().getFullYear(),
            firstPossibleYear: new Date().getFullYear(),
            kpiData: this.props.kpiData,
        };
    }

    save = async (state) => new Promise(next => this.setState(state, next));

    profileChange = async (profile) => {
        if (profile.hash===this.state?.profile?.hash) return `${this.constructor.name}.profileChange: false`;
        await this.save({ profile, });
        return `${this.constructor.name}.profileChange: true`;
    };

    async componentDidMount() {
        profileService.subscribe('profile', this.profileChange, this);
        await profileService.profile;
        /*if (this.props.open) {
            this.props.getKPIData({"userId": this.state.dataUserId, "year": this.state.dataYear, "month": this.state.dataMonth,});
            this.props.getUsersDepartment();
        }*/
    }
    componentWillUnmount () {
        profileService.unsubscribe('profile', this.profileChange, this);
    }

    changeDataYear = (event) => {
        this.setState({
            dataYear: event
        });
    };

    changeDataUserId = (event) => {
        this.setState({
            userName: event.target.dataset.name,
            userSurname: event.target.dataset.surname,
            dataUserId: event.target.dataset.item
        });
    };

    changeDataMonth = (event) => {
        this.setState({
            dataMonth: event.value
        });
    };

    applyKpiFilter = () => {
        this.setState({
            currentMonth: new Date().setMonth(this.state.dataMonth-1)
        });
        // this.props.getKPIData({ "userId": this.state.dataUserId, "year": this.state.dataYear, "month": this.state.dataMonth});
    };

    renderMonth () {
        return (
          <Translate>
              {({translate})=>{
                  const { currentMonth, selectedDate } = this.state;

                  const monthStart = dateFns.startOfMonth(currentMonth);
                  const monthEnd = dateFns.endOfMonth(monthStart, { weekStartsOn: 1 });

                  const dateFormat = "D";
                  const weekFormat = "dd";

                  const days = [];
                  let day = monthStart;
                  let formattedDate = "";
                  let formattedWeek = "";

                  const style = {
                      width:     `${100 / monthEnd.getDate()}%`,
                      flexBasis: `${100 / monthEnd.getDate()}%`,
                  };

                  let localeLang = DataStorage.getData('locale');
                  localeLang = (localeLang !== null && localeLang !== undefined) ? localeLang : 'en';
                  localeLang = (localeLang === 'uk') ? "ru" : localeLang;

                  const dateFnsLocale = require(`date-fns/locale/${localeLang}`);

                  while (day <= monthEnd) {
                      formattedDate = dateFns.format(day, dateFormat);
                      formattedWeek = dateFns.format(day, weekFormat, {locale: dateFnsLocale});
                      days.push(
                          <div
                              className = { `line-day ${
                                  dateFns.isSaturday(day) || dateFns.isSunday(day)
                                      ? "weekend"
                                      : dateFns.isSameDay(day, selectedDate) ? "current" : ""
                                  }` }
                              key = { day } style = { style }>
                              <div className = 'line-day-inner'>
                                  <span className = 'number'>{formattedDate}</span>
                                  <span className = 'week'>{formattedWeek}</span>
                              </div>
                          </div>
                      );
                      day = dateFns.addDays(day, 1);
                  }
                  return (
                      <div className = 'calendar-line'>
                          <div className = 'calendar-line-title'>KPI</div>
                          <RenderMonth
                              currentMonth = { this.state.currentMonth}
                              selectedDate = { this.state.selectedDate}
                          />
                          <KPIWidgetFilter
                            months = { this.props.months}
                            dataYear = { this.state.dataYear }
                            firstPossibleYear = { this.state.firstPossibleYear }
                            dataMonth = { this.state.dataMonth }
                            userName = { this.state.profile?.data?.name }
                            userSurname = { this.state.profile?.data?.surname }
                            // usersDepartment = { this.props.usersDepartment }
                            translate = { translate }
                            dropdownOpen = { this.state.dropdownOpen}
                            changeDataYear = { this.changeDataYear }
                            changeDataUserId = { this.changeDataUserId }
                            changeDataMonth = { this.changeDataMonth }
                            applyKpiFilter = { this.applyKpiFilter }
                          />
                      </div>
                  );
              }}
          </Translate>
        );
    }

    nextMonth = () => {
        this.setState({
            currentMonth: dateFns.addMonths(this.state.currentMonth, 1),
        });
    };

    prevMonth = () => {
        this.setState({
            currentMonth: dateFns.subMonths(this.state.currentMonth, 1),
        });
    };

    render () {

        return (
            <div className = 'calendar-widget'>
                {this.renderMonth()}
                <UserProgress
                    currentDay = { this.state.selectedDate }
                    currentMonth = { this.state.currentMonth }
                    currentMonthNum = { new Date(this.state.currentMonth).getMonth() + 1 }
                    results = { this.state.kpiData }
                />
            </div>
        );
    }
}

/*const mapStateToProps = (store) => {
    return {
        userId: store.user.session.user_id,
        userName: store.user.profile.name,
        userSurname: store.user.profile.surname,
        kpiData: store.kpi.kpiData,
        usersDepartment: store.kpi.usersDepartment,
    };
};*/

MonthLine.defaultProps= {
    months: {
        '1': 'kpi_january',
        '2': 'kpi_february',
        '3': 'kpi_march',
        '4': 'kpi_april',
        '5': 'kpi_may',
        '6': 'kpi_june',
        '7': 'kpi_july',
        '8': 'kpi_august',
        '9': 'kpi_september',
        '10': 'kpi_october',
        '11': 'kpi_november',
        '12': 'kpi_december',
    },
    startYear: 2019
}

/*export default withLocalize(withRouter(connect(
    mapStateToProps,
    (dispatch) =>
        bindActionCreators({
            getKPIData,
            getUsersDepartment
        }, dispatch)
)(MonthLine)));*/
export default withLocalize(withRouter(MonthLine));
