import React, { Component, } from "react";
import { withRouter, } from "react-router-dom";
import { withLocalize, } from "react-localize-redux";
import { HorizontalMenu, } from "../../components";
import PartnersSystemHeader from "../ components/PartnersSystemHeader";
// import { breadcrumbsItems, } from "../config/breadCrumbs4Referrels";

class Statistics extends Component {
	constructor (props) {
		super(props);

		this.state = {
			serverCurrency: "",
		};
	}

	save = async state => new Promise(next => this.setState(state, next));

	render () {
		const { translate, } = this.props;

		return (
			<div className="affiliate-payouts">
				<PartnersSystemHeader
					// breadcrumbsItems={ breadcrumbsItems }
					translate={ translate }
				/>
				<HorizontalMenu />
			</div>
		);
	}
}

export default withRouter(withLocalize(Statistics));