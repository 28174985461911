/* eslint-disable no-useless-constructor */
/* eslint-disable react/no-children-prop */
import React, { Component, } from "react";
import { withLocalize, } from "react-localize-redux";
import { Select, } from "../../../../../components";
import { MagicButton, } from "../../../../../../../components/Magic";
import { debounce, } from "lodash";

class CountrySwitcher extends Component {
	constructor (props) {
		super(props);
	}

	get countriesOptions () {
		const { countries, selectedCountries, } = this.props;

		return countries
			.filter((country) => {
				const { id, name, is_work, } = country;

				if (selectedCountries.includes(id)) {
					return {
						label: name,
						value: id,
						isWork: is_work,
					};
				}
			})
			.map((item) => {
				return {
					label: item.name,
					value: item.id,
					isWork: item.is_work,
				};
			})
			.sort((a, b) => a.label.localeCompare(b.label));
	}
	get filteredOptions () {
	        const {formData, fieldsIsNotEdit, selectedCountry, translate} = this.props;

	        return formData?.country_groups?.map((item, index) => {
	                const {name, group } = item;
	                const altName = `${ translate('partners_payout_rate-list_name-label') + ' ' + index }`;

	                return {
	                        label: name || altName,
	                        value: index,
	                        group: group
	                }

	        }).filter((item, index, arr) => {
	                if (!fieldsIsNotEdit) {
	                        return !item.group.includes(selectedCountry.value) && {
	                                label: item.label,
	                                value: item.value,
	                                group: item.group
	                        };
	                }
	                if ( arr[0].group.includes(selectedCountry.value))  {
	                        return !item.group.includes(selectedCountry.value) &&  {
	                                label: item.label,
	                                value: item.value,
	                                group: item.group
	                        };

	                } else if (item.value < 1) {
	                        return  {
	                                label: item.label,
	                                value: item.value,
	                                group: item.group
	                        };
	                }
	        });
	}

	render () {
		const {
			selectedGroup,
			isSelectedConditionType,
			setErrorClass,
			errors,
			loadData,
			showPopUp,
			translate,
			selectedCountry,
			save,
			handleSwitchCountryGroupSubmit,
		} = this.props 

                const countriesOptions = this.countriesOptions;
		const filteredOptions = this.filteredOptions;
		const isDisabled = selectedGroup.label
			? "magic-button__item magic-button_partners_popup-save  payout-rate-list__payout_table-countries-select switch-group-btn"
			: "magic-button__item magic-button_partners_popup-save  payout-rate-list__payout_table-countries-select switch-group-btn__disabled";
		const customStyles = {
			option: (provided, state) => ({
				...provided,
				borderBottom: "1px dotted pink",
				color: state.isSelected ? "red" : "blue",
				padding: 20,
			}),
		};

		return (
			<>
				{isSelectedConditionType ? (
					<>
						<p className="payout-rate-list__payout_block-title mt-20">
							{translate("partners_payout_rate-list-switching-group")}
						</p>
						<div className="payout-rate-list__payout_table-switching-groups ">
							<Select
								className={ `payout-rate-list__payout_table-countries-select  ${ setErrorClass(
									errors,
									"country",
								) }
                                            ` }
								disabled={ !loadData || showPopUp }
								isRequired={ true }
								label={ translate("partners_referrals_table_country") }
								name="country"
								options={ countriesOptions }
								placeholder={ translate(
									"partners_payout_rate-list-select-country",
								) }
								styles={ customStyles }
								value={ selectedCountry ?? {} }
								onChange={ async (event) => {
									const { value, } = event.target;

									await save({ selectedCountry: value, });
								} }
							/>
							<Select
								className={ `payout-rate-list__payout_table-countries-select ${ setErrorClass(
									errors,
									"group",
								) }` }
								disabled={ !selectedCountry.value }
								isRequired={ true }
								label={ translate("partners_payout_rate-list-choose-in-group") }
								name="group"
								options={ filteredOptions }
								placeholder={ translate(
									"partners_payout_rate-list-choose-in-group",
								) }
								value={ selectedGroup ?? {} }
								onChange={ async (event) => {
									const { value, } = event.target;

									await save({ selectedGroup: value, });
								} }
							/>
							<MagicButton
								children={ translate("partners_payout_rate-list-change-group") }
								className={ isDisabled }
								disabled={ !selectedGroup.label }
								onClick={ debounce(
									() =>
										handleSwitchCountryGroupSubmit(
											selectedCountry,
											selectedGroup.value,
										),
									500,
								) }
							/>
						</div>
						<div className="payout-rate-list__underline mt-20" />
					</>
				) : null}
			</>
		);
	}
}

export default withLocalize(CountrySwitcher);
