import React from 'react';
import _ from 'lodash';
import { withLocalize } from 'react-localize-redux';

import PreloaderIcon from "react-preloader-icon";
import ModalSuccess from "../../../../components/Common/ModalSuccess";
import Oval from "react-preloader-icon/loaders/Oval";
import {resetPassword} from '../../services/AuthService';

import '../../../../style/NewLogin.scss';

class ResetPassword extends React.Component {
    // static modalTitle = 'Request for recovery sent, check your mail';

    constructor (props) {
        super(props);
        const { translate } = props;

        this.state = {
            data: {
                email: '',
            },
            action: false,
            modal: false,
            modalTitle: translate('reset_password_check_email'), //ResetPassword.modalTitle,
        };

        this.modalTitle = translate('reset_password_check_email');
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        this.modalTitle = nextProps.translate('reset_password_check_email');
    };

    toState( state ) {
        return new Promise( resolve => this.setState( state, resolve ) );
    }

    back = () => this.props.toggleFrogotPassword();

    onData = ( event ) => {
        event.preventDefault();
        const { target: { name, value, } } = event;
        this.setState( ( state ) => ( { data: { ...state.data, [ name ]: value, }, } ) );
    };

    onDone = async () => {
        await this.toState( { action: false, modal: false, } );
    };

    onSubmit = async ( event ) => {
        event.preventDefault();
        if ( this.state.action ) return ;
        await this.toState( { action: true, } );
        try {
            await resetPassword( this.state.data );
            await this.toState( {
                modal: true,
                modalTitle: this.modalTitle,
            } );
        } catch ( error ) {
            // NotificationService.errors( error );
            const errors = _.at( error, 'errors.email' ).join('\n') || this.modalTitle;
            await this.toState( {
                modal: true,
                modalTitle: errors,
            } );
        }
        setTimeout( this.onDone, 3000 );
    };

    renderPreloader = () => (
      <PreloaderIcon
        duration = { 800 }
        loader = { Oval }
        size = { 35 } // min: 1, max: 50
        strokeColor = '#0096ff'
        strokeWidth = { 10 }
      />
    );

    renderModal = () => (
      <ModalSuccess
        modal = { this.state.modal }
        title = { this.state.modalTitle }
        toogle = { this.onDone }
      />
    );

    render = () => {
        const { translate } = this.props;

        return (
            <form className = 'login-form' onSubmit = { this.onSubmit }>
                <div className = 'form-group'>
                    <div className = 'login-form-icon'>
                        <i className = 'la la-user' />
                    </div>
                    <label>{translate(`email`)}*</label>
                    <input
                        className = 'input-login form-control'
                        name = 'email'
                        onChange = { this.onData }
                        placeholder = 'example@mail.com'
                        type = 'email'
                    />
                    <div className = 'error-message'>Test test test</div>
                </div>

                <div className = 'form-group'>
                    <div className = 'd-flex align-items-center justify-content-between'>
                        <a onClick = { this.back }>{ translate( `back_to_form_login` ) }</a>
                    </div>
                </div>

                <div className = 'form-group submit-group'>
                    <div className = 'd-flex align-items-center justify-content-center'>
                        <button className = 'button big green' type = 'submit'>
                            { this.state.action ? this.renderPreloader() : '' }
                            &nbsp;
                            { translate(`reset`) }
                        </button>
                    </div>
                </div>

                { this.renderModal() }
            </form>
        );
    };
}

export default withLocalize(ResetPassword);
