//SMS & E_Mail Tabs
export const TABID_0 = '0';
export const TABID_1 = '1';

//STATE: clickTypeTemplate
export const CREATE_TEMPLATE = 'CREATE_TEMPLATE';
export const EDIT_TEMPLATE = 'EDIT_TEMPLATE';

//STATE: clickTypeTemplateTranslation
export const CREATE_TEMPLATE_TRANSLATION = 'CREATE_TEMPLATE_TRANSLATION';
export const EDIT_TEMPLATE_TRANSLATION = 'EDIT_TEMPLATE_TRANSLATION';

//STATE: modal_name
export const SMS_TEMPLATE = 'SMS_TEMPLATE';
export const E_MAIL_TEMPLATE = 'E_MAIL_TEMPLATE';

//STATE: modal_key(data-key)
export const SMS_KEY = 0;
export const E_MAIL_KEY = 1;

//checkout template
export const ACTIVE = 1;
export const NOT_ACTIVE = 2;

//template management
export const CREATE_TEMPLATE_MANAGEMENT = [
    {label: 'SMS', key: 0},
    {label: 'E-Mail', key: 1},
];
