import React from 'react';
import { parseInt, isEqual, isInteger, isNull, has, isEmpty } from "lodash";

export default class AddAccessToArray extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            name: 'Search'
        };
    }

    onChangeInput = (value) => {
        this.setState({
            name: value,
        });
    };

    onClearInput = (value) => {
        this.setState({
            name: '',
        });
    };

    closePopUpAdd = () => {
        this.props.closePopUp();
    };

    addFieldAccessPopup = (item, show, module, stateName, removeItem, flagInt) => {
        if (flagInt) {
            removeItem.value = parseInt(removeItem.value);
        }

        this.props.addField(item, show, module, stateName, removeItem);
    };

    returnList = () => {
        const name = (isEqual('Search', this.state.name)) ? "" : this.state.name;
        const field = this.props.getUserField(this.props.item, this.props.show, this.props.module, this.props.stateName);
        const dataField = this.props.dataField;
        let flagInt = false;



        field.forEach(function(item, i, arr) {

            if (isInteger(item) ) {
                field[i] = field[i].toString();
            }
        });

        dataField.forEach(function(item, i, arr) {
            if (isNull(item.value)) {
                delete dataField[i];
            }else if (isInteger(item.value)) {
                dataField[i].value = dataField[i].value.toString();
                flagInt = true;
            }
        });

        return (
            dataField.filter(data =>
                data.value.toLowerCase().includes(name.toLowerCase())).map((searchItem, index) => {

                if (has(searchItem, 'key') && has(searchItem, 'value')) {
                    if (field.includes(searchItem.key.toString())) {
                        return true;
                    }
                } else {
                    if (field.includes(searchItem.value)) {
                        return true;
                    }
                }

                return (
                    <li key={index}>
                        <span className='add-plus' onClick={() => {
                            this.addFieldAccessPopup(
                                this.props.item,
                                this.props.show,
                                this.props.module,
                                this.props.stateName,
                                searchItem,
                                flagInt
                            )}
                        }/>
                        <p>{searchItem.value}</p>
                    </li>
                );
            })
        );
    };

    render () {
        if (isEmpty(this.props.dataField)) {
                return true;
            }

            return (
                    <div style={{float: 'left', width: '250px'}}>
                        <div className='search form-group'>
                            <form>
                                <span className='search-icon'/>
                                <input className='form-control'
                                       onChange={(e) => this.onChangeInput(e.target.value)}
                                       onClick={(e) => this.onClearInput(e.target.value)} type='text'
                                       value={this.state.name}/>
                            </form>
                        </div>

                        <div className='checkbox-popup-add'>
                            <ul>
                                {this.returnList()}
                            </ul>
                            <button onClick={this.closePopUpAdd}>Save</button>
                        </div>
                    </div>
            );
    }
}
