import React, { Component, } from "react";
import { withLocalize, } from "react-localize-redux";
import { withRouter, } from "react-router-dom";
import { MagicButton, } from "../../../../../components/Magic";
import { enumService, } from "../../../../../services/EnumDataService";
import PermissionService from "../../../../../services/PermissionService";
import * as configBreadcrumbs from "./Config/BreadcrumbsConfig";
import Breadcrumbs from "../../../../tima/components/Breadcrumbs";
import { HorizontalMenu, } from "../../../components";
import CountryListWithPayoutRatesMagicTable from "./Table/CountryListWithPayoutRatesMagicTable";

class CountryListWithPayoutRates extends Component {
	constructor (props) {
		super(props);

		this.state = {
			enums: {},
			isOpenCreatePopup: false,
			popupData: {},
		};
	}

	async componentDidMount () {
		enumService.subscribe("enums", this.enumsChange, this);
		await enumService.enums;
	}

	UNSAFE_componentWillUnmount () {
		enumService.unsubscribe("enums", this.enumsChange, this);
	}

	toggleCreateUpdatePopup = async (popupData = {}) => {
		await this.save(state => ({
			isOpenCreatePopup: !state.isOpenCreatePopup,
			popupData,
		}));
	};

	save = state => new Promise(next => this.setState(state, next));

	enumsChange = async (enums) => {
		if (enums.hash === this.state?.enums?.hash) {
			return `${ this.constructor.name }.enumsChange: false`;
		}
		await this.save({ enums, });

		return `${ this.constructor.name }.enumsChange: true`;
	};

	getPermissions = (action) => {
		const pm = PermissionService.calc([{
			path: ['partners', 'payout_rate_lists'],
			key: 'user',
		}]);

		return pm.user.access(action);
	}

	renderButtonCreate = () => {
		const permissionToCreate = this.getPermissions('store');
		if (permissionToCreate) {
			const options = {
				className: "magic-button__item magic-button__item_partners-create-symbol",
				children: this.props.translate('symbols_create_symbol_btn'),
				onClick: async () => await this.toggleCreateUpdatePopup(),
			};

			return <MagicButton { ...options } />;
		}

		return "";
	};

	render () {
		const { enums, isOpenCreatePopup, popupData, } = this.state;
		const { translate, } = this.props;
		const breadcrumbsItems = configBreadcrumbs.breadcrumbs4PayoutRateList(translate);
		const permissionToShow = this.getPermissions('show');

		return (
			<div className="content-block">
				<div className="top-page-block">
					<div>
						<h1 className="page-title">{translate("partners_payout_rates")}</h1>
						<Breadcrumbs items={ breadcrumbsItems } />
					</div>
					{this.renderButtonCreate()}
				</div>

				<HorizontalMenu />

				{permissionToShow &&
					<CountryListWithPayoutRatesMagicTable
						enums={enums}
						popup={{
							data: popupData,
							isOpen: isOpenCreatePopup,
							togglePopup: this.toggleCreateUpdatePopup,
						}}
					/>}

			</div>
		);
	}
}

export default withRouter(withLocalize(CountryListWithPayoutRates));