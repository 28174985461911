import React, { Component } from 'react';
import { withMediaProps } from 'react-media-player';
import play from '../../../../../images/play.svg';
import pause from '../../../../../images/pause.svg';

class CustomPlayPause extends Component {
    shouldComponentUpdate({ media }) {
        return (this.props.media.isPlaying !== media.isPlaying ||
        this.props.media.currentTime !== media.currentTime ||
        this.props.media.progress !== media.progress ||
        this.props.media.duration !== media.duration)
    }

    _handlePlayPause = () => {
        this.props.media.playPause()
    }

    render() {
        const {style, media } = this.props
        return (
            <React.Fragment>
            <span
                className='media-play-button'
                style={style}
                onClick={this._handlePlayPause}
            >
                {media.isPlaying ? <img src={pause} alt="pause"/> : <img src={play} alt="play"/>}
            </span>
            </React.Fragment>
        )
    }
}

export default withMediaProps(CustomPlayPause);