/* eslint-disable no-unused-vars , array-callback-return ,  sort-keys, no-mixed-spaces-and-tabs, max-len , no-shadow , indent */
import React, { Component, } from "react";
import { withLocalize, } from "react-localize-redux";
import PropTypes from "prop-types";

import Loader from "../../../../../tima/components/Loader";
import Preloader from "../../../../../../components/LoadingHOC/Preloader";
import { Popup, } from "../../../../components";
import {
	Header,
	CountrySwitcher,
	CreateGroupButtonsBlock,
	CreateCountryGroup,
	PayoutRate,
} from "./components/components";

import {
	getAllCountriesIsWork,
	createPayoutRateList,
	editPayoutRateList,
} from '../../../../../../services/PartnersRequestService';
import {
	filterValues,
	findOptionByValue,
	setErrorClass,
	setErrorsFromServer,
} from "../../../../utils/helpers";
import {
	validatePayoutRangesFields,
	validateEmptyFields,
} from "./helpers/helpers";
import {
	payoutRateListStatus,
	payoutRateListConditions,
} from "./consts/consts";

class CreateUpdatePayoutRateList extends Component {
	static propTypes = {
		onClose: PropTypes.func.isRequired,
		enums: PropTypes.object,
		formData: PropTypes.object,
		onDataLoad: PropTypes.func,
	};

	constructor (props) {
		super(props);

		this.state = {
			countries: [],
			countriesLoaded: true,
			errors: [],
			formData: {
				country_groups: [
					{
						group: [],
						is_forbidden: 1,
						name: this.props.translate(
							"partners_payout_rate_list_forbidden_group",
						),
						...payoutRateListConditions.CPL && { rate: "0", },
						payoutRanges: [ "", ],
					},
				],
				name: "",
				payoutRangesSumFrom: [ "", ],
				status: {},
				type: {},
			},
			loadData: true,
			selectedCountry: [],
			selectedCountryId: -1,
			listId: "",
			selectedGroup: [],
			statusList: [],
		};
	}

	uniqueFormDataCountries = (filter = () => true) => {
		const country_groups = this.state.formData?.country_groups;
		const scopeCountries = country_groups
			.filter((item, index) => filter(item, index))
			.map(item => item.group)
			.flat();
		const uniqueCountries = new Set(scopeCountries);

		return Array.from(uniqueCountries);
	};

	save = state => new Promise(next => this.setState(state, next));

	toggleLoader = () => this.save(state => ({ loadData: !state.loadData, }));

	componentDidMount = async () => {
		try {
			await this.save({
				isEmptyData: false,
				countriesLoaded: false,
			});
			await this.toggleLoader();

			const countries = await getAllCountriesIsWork();
			const forbiddenCountry = countries
				?.filter((country) => {
					if (country.is_work === 2) {
						return {
							id: country.id,
						};
					}
				})
				.map(countryId => countryId.id);

			const statusList = this.statusList;
			const conditionTypes = this.conditionTypes;

			await this.save((state) => {
				const defaultSelectedCountries = state.formData.country_groups?.map(
					(item) => {
						return {
							...item,
							group: forbiddenCountry,
						};
					},
				);

				return {
					countries,
					...!countries.length && { isEmptyData: true, },
					formData: {
						...state.formData,
						country_groups: defaultSelectedCountries,
						status: statusList[0],
					},
					statusList,
					conditionTypes,
				};
			});

			if (this.isEditMode) {
				const {
					country_groups,
					payoutRangesSumFrom,
					name,
					type,
					status,
					id: listId,
				} = this.props.formData;

				const formData = {
					country_groups,
					payoutRangesSumFrom,
					name,
					type: findOptionByValue(conditionTypes, type),
					status: findOptionByValue(this.state.statusList, status),
				};

				await this.save({ formData, listId, });
			}
		} catch (error) {
			error?.showErrorNotification?.();
		} finally {
			await this.save({ countriesLoaded: true, });
			await this.toggleLoader();
		}
	};

	async componentDidUpdate (prevProps, prevState) {
		const tState = this.state.formData.type;
		const pState = prevState.formData.type;
		const tSelCountry = this.state.formData.country_groups;
		const pSelCountry = prevState.formData.country_groups;
		const tSelGroup = this.state.selectedCountry;
		const pSelGroup = prevState.selectedCountry;

		if (tSelCountry !== pSelCountry) {
			await this.save({ selectedCountry: {}, });
		}
		if (tSelGroup !== pSelGroup) {
			await this.save({ selectedGroup: {}, });
		}

		const country_groups = this.state.formData.country_groups?.map((item) => {
			return {
				...item,
				payoutRanges: [ "0", ],
				rate: "0",
			};
		});

		if (!this.isEditMode && tState !== pState) {
			await this.save((state) => {
				return {
					formData: {
						...state.formData,
						country_groups,
						payoutRangesSumFrom: [ "0.01", ],
					},
				};
			});
		}
	}

	get translate () {
		return this.props.translate;
	}

	get isEditMode () {
		return Object.keys(this.props.formData).length;
	}

	get payoutRateListStatusInDB () {
		const { status, } = this.props.formData || 1;

		return {
			new: status === payoutRateListStatus.NEW,
			active: status === payoutRateListStatus.ACTIVE,
			closed: status === payoutRateListStatus.CLOSED,
		};
	}

	get condition () {
		const { type, } = this.state.formData;

		return {
			CPA: type.value === payoutRateListConditions.CPA,
			CPL: type.value === payoutRateListConditions.CPL,
		};
	}

	get fieldsIsNotEdit () {
		return this.isEditMode && !this.payoutRateListStatusInDB.new;
	}

	get statusIsNotEdit () {
		return (
			this.isEditMode && this.payoutRateListStatusInDB.closed ||
			!this.isEditMode
		);
	}

	get statusList () {
		return Object.entries(
			this.props?.enums?.data?.partners?.PayoutRateListStatusEnum ?? {},
		).map(([ key, value, ]) => ({
			label: value,
			value: +key,
		}));
	}

	get conditionTypes () {
		return Object.entries(
			this.props.enums.data?.partners?.PayoutRateListTypeEnum ?? {},
		).map(([ key, value, ]) => ({
			label: value,
			value: +key,
		}));
	}

	getSendData = () => {
		const {
			name,
			type,
			status,
			country_groups: countryGroups,
			payoutRangesSumFrom,
		} = this.state.formData;
		const country_groups = countryGroups.map((countryGroup) => {
			return {
				is_forbidden: countryGroup.is_forbidden,
				name: countryGroup.name,
				group: countryGroup.group,
				...type.value === payoutRateListConditions.CPL && {
					rate: countryGroup.rate,
				},
			};
		});
		const payout_ranges =
			type.value === payoutRateListConditions.CPA
				? payoutRangesSumFrom.map((sum, index) => {
						return {
							sum_from: sum,
							country_groups: countryGroups.map((countryGroup) => {
								return {
									name: countryGroup.name,
									rate: countryGroup.payoutRanges[index],
								};
							}),
						};
				  })
				: [];

		return {
			country_groups,
			...type.value === payoutRateListConditions.CPA && { payout_ranges, },
			name,
			type: type.value,
			status: status.value,
		};
	};

	saveData = async () => {
		const fieldsAreValid = await validateEmptyFields(this.state, this.save);
		const payoutRangesFieldsAreValid = await validatePayoutRangesFields(
			this.state,
			this.save,
			this.condition,
		);

		if (!fieldsAreValid || !payoutRangesFieldsAreValid) {
			return;
		}
		this.isEditMode
			? await this.updatePayoutRateList()
			: await this.createPayoutRateList();
	};

	updatePayoutRateList = async () => {
		const { onDataLoad, onClose, } = this.props;
		const formDataId = this.state.listId;

		try {
			await this.save({
				errors: [],
				loadData: false,
			});
			await editPayoutRateList(formDataId, this.getSendData());
			await Promise.all([ onClose(), onDataLoad(), ]);
		} catch (error) {
			await error?.showErrorNotification?.();
		} finally {
			await this.save({ loadData: true, });
		}
	};

	createPayoutRateList = async () => {
		const { onClose, onDataLoad, } = this.props;

		try {
			await this.save({
				errors: [],
				loadData: false,
			});
			await createPayoutRateList(this.getSendData());
			await Promise.all([ onClose(), onDataLoad(), ]);
		} catch (error) {
			// await error?.showErrorNotification?.();
			await setErrorsFromServer(error, this.save);
		} finally {
			await this.save({ loadData: true, });
		}
	};

	handleOnChange = async (event) => {
		const { name, value, } = event.target;

		await this.save(state => ({
			...state,
			errors: state.errors.filter(item => item !== name),
			formData: {
				...state.formData,
				[name]: value,
			},
		}));
	};

	renderTitle = () => {
		const { showPopUp, } = this.props;

		if (showPopUp) {
			return this.translate("partners_payout_rates");
		} else if (this.isEditMode) {
			return this.translate("partners_update_payout_rate_list");
		}

		return this.translate("partners_create_payout_rate_list");
	};

	renderPopupHeader = () => {
		const {
			formData,
			loadData,
			statusList,
			conditionTypes,
			errors,
		} = this.state;

		const { showPopUp, } = this.props;

		return (
			<Header
				conditionTypes={ conditionTypes }
				errors={ errors }
				fieldsIsNotEdit={ this.fieldsIsNotEdit }
				filterValues={ filterValues }
				formData={ formData }
				handleOnChange={ this.handleOnChange }
				isEditMode={ this.isEditMode }
				loadData={ loadData }
				payoutRateListStatus={ payoutRateListStatus }
				payoutRateListStatusInDB={ this.payoutRateListStatusInDB }
				setErrorClass={ setErrorClass }
				showPopUp={ showPopUp }
				statusIsNotEdit={ this.statusIsNotEdit }
				statusList={ statusList }
				translate={ this.translate }
			/>
		);
	};

	renderCountrySwitch = () => {
		const {
			formData,
			loadData,
			countries,
			selectedCountry,
			selectedGroup,
			errors,
		} = this.state;
		const { translate, showPopUp, } = this.props;
		const isSelectedConditionType = this.condition.CPA || this.condition.CPL;
		const selectedCountries = formData.country_groups
			?.map(item => item.group)
			.flat();

		return (
			<CountrySwitcher
				countries={ countries }
				errors={ errors }
				fieldsIsNotEdit={ this.fieldsIsNotEdit }
				formData={ formData }
				handleSwitchCountryGroupSubmit={ this.handleSwitchCountryGroupSubmit }
				isSelectedConditionType={ isSelectedConditionType }
				loadData={ loadData }
				save={ this.save }
				selectedCountries={ selectedCountries }
				selectedCountry={ selectedCountry }
				selectedGroup={ selectedGroup }
				setErrorClass={ setErrorClass }
				showPopUp={ showPopUp }
				translate={ translate }
			/>
		);
	};

	removeCountryGroup = index => async () => {
		await this.save(({ formData, }) => {
			return {
				formData: {
					...formData,
					country_groups: formData.country_groups.filter(
						(_, groupIndex) => groupIndex !== index,
					),
				},
			};
		});
	};
	calculatingPayoutRate = (values, addition) => {
		if (values.length === 0 || this.condition.CPL && values.length === 1) {
			return 0;
		}
		const sumWithInitial = values.reduce(
			(accumulator, currentValue) =>
				parseFloat(currentValue) + parseFloat(addition),
			addition,
		);

		return sumWithInitial.toFixed(2);
	};

	createNewAllowedGroup = async () => {
		const isValidate = await validatePayoutRangesFields(
			this.state,
			this.save,
			this.condition,
		);

		if (!isValidate) {
			return;
		}

		const { formData, } = this.state;
		const cplRates = formData?.country_groups?.map(item => item.rate);

		try {
			const newItemCountryGroup = {
				is_forbidden: 0,
				group: [],
				name: "",
				...this.condition.CPL
					? {
							rate: this.calculatingPayoutRate(cplRates, "0.01"),
							payoutRanges: Array(
								this.state.formData.payoutRangesSumFrom.length,
							).fill(""),
					  }
					: null,
				...this.condition.CPA
					? {
							payoutRanges: Array(
								this.state.formData.payoutRangesSumFrom.length,
							)
								.fill(0).map((_, i, index) => {
									return i === 0 ? 0 : ((i / 100)).toFixed(2);
								}),
					  }
					: null,
			};

			await this.save(({ formData, }) => {
				return {
					formData: {
						...formData,
						country_groups: [ ...formData.country_groups, newItemCountryGroup, ],
					},
				};
			});
		} catch (error) {
			throw new Error(error);
		}
	};

	handleSwitchCountryGroupSubmit = async (country, groupId) => {
		const { formData, } = this.state;
		const countries = await getAllCountriesIsWork();

		if (!countries.includes(country.value)) {
			try {
				await this.save({ countriesLoaded: false, });

				await this.save((state) => {
					return {
						...state,
						countries,
						...!countries.length && { isEmptyData: true, },
					};
				});
			} catch (error) {
				await setErrorsFromServer(error);
			} finally {
				await this.save({ countriesLoaded: true, });
			}
		}

		const country_groups = formData.country_groups.map((item) => {
			return {
				...item,
				group: item.group.filter(id => id !== country.value),
			};
		});

		country_groups.filter((item, index) => {
			if (index === groupId) {
				item.group.push(country.value);
			}
		});

		await this.save(({ formData, }) => {
			return {
				formData: {
					...formData,
					country_groups,
				},
			};
		});
	};

	setErrorClassGroup = (data = [], name = "") => {
		return data.includes(name) ? "errorGroup" : "";
	};

	renderCountryGroups = () => {
		const { formData, countries, errors, } = this.state;
		const { translate, showPopUp, } = this.props;

		return (
			<>
				{formData.country_groups ?
					formData.country_groups.map((countryGroup, index) => {
						const className = `${ this.setErrorClassGroup(
							errors,
							`group_${ index }`,
						) }`;

						return (
							<div className={ className } key={ `group_${ index }` }>
								<Loader
									loaded={ this.state.countriesLoaded }
									loading={ <Preloader scale={ this.props.scale } /> }
									translate={ this.props.translate }
								>
									{index > 9 ? null : (
										<CreateCountryGroup
											condition={ this.condition }
											countries={ countries }
											countryGroup={ countryGroup }
											errors={ errors }
											fieldsIsNotEdit={ this.fieldsIsNotEdit }
											formData={ formData }
											index={ index }
											isEmptyData={ countries }
											key={ index }
											removeCountryGroup={ this.removeCountryGroup(index) }
											selectedCountryId={ async (
												selectedCountryId,
												fieldName,
											) => {
												await this.save({
													errors: this.state.errors.filter(
														item => item !== fieldName,
													),
													selectedCountryId,
												});
											} }
											setCountryGroup={ async (countryGroup, fieldName) => {
												const country_groups = formData.country_groups.map(
													(_, i) => {
														return i === index
															? countryGroup
															: {
																	..._,
																	group: _.group.filter(
																		g => !countryGroup.group.includes(g),
																	),
															  };
													},
												);

												await this.save({
													errors: this.state.errors.filter(
														item => item !== fieldName,
													),
													formData: {
														...formData,
														country_groups,
													},
												});
											} }
											showPopUp={ showPopUp }
											uniqueFormDataCountries={ this.uniqueFormDataCountries }
										/>
									)}
								</Loader>
							</div>
						);
					})
				 : (
					<p className="no-data-payout-rate-list">
						{translate("symbols_empty")}
					</p>
				)}
			</>
		);
	};
	renderCreateButton = () => {
		if (this.isEditMode) {
			return "partners_edit_popup_save";
		}

		return "partners_create_btn";
	};

	updateCountriesCount = async () => {
		try {
			await this.save({ countriesLoaded: false, });

			const countries = await getAllCountriesIsWork();

			await this.save((state) => {
				return {
					...state,
					countries,
					...!countries.length && { isEmptyData: true, },
				};
			});
		} catch (error) {
			await setErrorsFromServer(error);
		} finally {
			await this.save({ countriesLoaded: true, });
		}
	};

	renderCreateGroupsButton = () => {
		const { translate, showPopUp, } = this.props;
		const { formData, errors, } = this.state;

		return (
			<CreateGroupButtonsBlock
				condition={ this.condition }
				createNewAllowedGroup={ this.createNewAllowedGroup }
				errors={ errors }
				fieldsIsNotEdit={ this.fieldsIsNotEdit }
				formData={ formData }
				setErrorClassGroup={ setErrorClass }
				showPopUp={ showPopUp }
				translate={ translate }
				updateCountriesCount={ this.updateCountriesCount }
			/>
		);
	};

	renderPayoutRateSection = () => {
		const { formData, } = this.state;
		const { showPopUp, } = this.props;

		return (
			<>
				<Loader
					loaded={ this.state.countriesLoaded }
					loading={ <Preloader scale={ this.props.scale } /> }
					translate={ this.props.translate }
				>
					<PayoutRate
						conditionType={ formData.type }
						countryGroups={ formData?.country_groups }
						createNewPayoutRangesItem={ async (value, forbiddenValue) => {
							const isValid = await validatePayoutRangesFields(
								this.state,
								this.save,
								this.condition,
							);

							if (!isValid) {
								return;
							}
							await this.save({
								formData: {
									...formData,
									country_groups: formData?.country_groups?.map(
										(countryGroup, index) => {
											if (countryGroup.is_forbidden) {
												return {
													...countryGroup,
													payoutRanges: [
														...countryGroup.payoutRanges,
														forbiddenValue,
													],
												};
											}

											return {
												...countryGroup,
												payoutRanges: [
													...countryGroup.payoutRanges,
													this.calculatingPayoutRate(
														countryGroup.payoutRanges,
														"0.01",
														index,
													),
												],
											};
										},
									),
									payoutRangesSumFrom: [
										...formData.payoutRangesSumFrom,
										this.calculatingPayoutRate(
											formData.payoutRangesSumFrom,
											"0.01",
										),
									],
								},
							});
						} }
						errors={ this.state.errors }
						fieldsIsNotEdit={ this.fieldsIsNotEdit }
						payoutRangesSumFrom={ formData?.payoutRangesSumFrom }
						removePayoutRangeItem={ async (index) => {
							await this.save(({ formData, }) => {
								return {
									formData: {
										...formData,
										country_groups: formData?.country_groups?.map(group => ({
											...group,
											payoutRanges: group.payoutRanges.filter(
												(_, groupIndex) => {
													return groupIndex !== index;
												},
											),
										})),
										payoutRangesSumFrom: formData.payoutRangesSumFrom.filter(
											(_, sumIndex) => sumIndex !== index,
										),
									},
								};
							});
						} }
						setNewRateValue={ async (value, iRate, iCountry, fieldName) => {
							const countryGroups = formData?.country_groups?.map(
								(countryGroup, iGroup) => {
									const newState = countryGroup.payoutRanges;

									if (iGroup === iCountry) {
										newState[iRate] = value;
									}

									return {
										...countryGroup,
										payoutRanges: newState,
									};
								},
							);

							await this.save({
								errors: this.state.errors.filter(
									item => item !== `${ fieldName }_${ iCountry }_${ iRate }`,
								),
								formData: {
									...formData,
									country_groups: countryGroups,
								},
							});
						} }
						setNewSumFromValue={ async (value, index, fieldName) => {
							const newState = formData.payoutRangesSumFrom;

							newState[index] = value;

							await this.save({
								errors: this.state.errors.filter(
									item => item !== `${ fieldName }_${ index }`,
								),
								formData: {
									...formData,
									payoutRangesSumFrom: newState,
								},
							});
						} }
						showPopUp={ showPopUp }
					/>
				</Loader>
			</>
		);
	};

	render () {
		const { onClose, showPopUp, } = this.props;
		const { loadData, formData, } = this.state;

		return (
			<Popup
				cancelBtnText={ "partners_payout_rate_list_popup_cancel" }
				createBtnText={
					showPopUp ? null : this.renderCreateButton()
				}
				customClassName={ "max-height-popup" }
				isImage={ false }
				loadData={ loadData }
				showPopUp={ showPopUp }
				size={ "lg" }
				title={ this.renderTitle() }
				onClick={ this.saveData }
				onClose={ onClose }
			>
				{this.renderPopupHeader()}
				{this.renderCountryGroups()}
				{formData.country_groups.length
					? this.renderCreateGroupsButton()
					: null}
				{this.renderCountrySwitch()}
				{this.condition.CPA && this.renderPayoutRateSection()}
			</Popup>
		);
	}
}

export default withLocalize(CreateUpdatePayoutRateList);