import React from 'react';
import * as PropTypes from 'prop-types';
import { withRouter, Redirect, } from 'react-router-dom';
import { MagicFilterPanel, } from '../MagicFilterPanel';
import { MagicButton, } from '../../../MagicButton';
// import { MagicFilterForm, } from '../MagicFilterForm';

class MagicFilterPanelsComponent extends React.Component {
  static defaultProps = {
    show: true,
    titleActionsApply: `Apply`,
    titleActionsReset: `Reset`,
    titleActionsClean: `Clean`,
  };

  static propTypes = {
    show: PropTypes.oneOfType([
      PropTypes.bool.isRequired,
      PropTypes.shape({
        apply: PropTypes.bool,
        clean: PropTypes.bool,
        reset: PropTypes.bool,
        rich: PropTypes.bool,
      }).isRequired,
    ]).isRequired,
    mf: PropTypes.object,
    translate: PropTypes.func.isRequired,
  };

  state = {
    loaded: false,
    showRich: false,
    filterLastChange: Date.now(),
    redirect: false,
  };

  save = async (state) => new Promise(next => this.setState(state, next));

  filterChanging = async () => {
    await this.save({ filterLastChange: Date.now(), loaded: false, });
  };

  filterChanged = async () => {
    await this.save({ filterLastChange: Date.now(), loaded: true, });
  };

  componentDidMount = async () => {
    this.props.mf.subscribe({
      changed: this.filterChanged,
      // changing: ,
      cleaned: this.filterChanged,
      cleaning: this.filterChanging,
      loaded: this.filterChanged,
      loading: this.filterChanging,
      saved: this.filterChanged,
      saving: this.filterChanging,
    }, this);
    await this.save({ redirect: false, });
  };

  componentWillUnmount = () =>this.props.mf.unsubscribe(this.filterChanged, this);

  renderButton = (className, onClick, text) => (
    <MagicButton className={ className } onClick={ onClick }>
      { text }
    </MagicButton>
  );

  renderApply = () => this.renderButton(
    [ 'magic-button_panels-apply', ],
    async () => await this.props.mf.apply({ doEmit: true, }),
    'Apply',
  );

  renderClean = () => this.renderButton(
    [ 'magic-button_panels-clean', ],
    async () => await this.props.mf.clean({ doEmit: true, }),
    'Clean',
  );

  renderReset = () => this.renderButton(
    [ 'magic-button_panels-reset', ],
    async () => await this.props.mf.reset({ doEmit: true, }),
    'Reset',
  );

  renderRich = () => this.renderButton(
    [ 'magic-button_panels-rich', 'la', 'la-dedent', ],
    async () => await this.save({ showRich: !state.showRich, }),
    'More',
  );

  renderRichForm = () => {
    return null;
    // className: `magic-filter-panels__rich`
    // const show = this.state.showFormFilters;
    // return !show ? '' : `MagicFilterForm`;
    // return (<MagicFilterForm service={ this.service } />);  
  };

  renderPanel = panel => (<MagicFilterPanel
    key={ panel.id }
    mf={ this.props.mf }
    panel={ panel }
    translate={ this.props.translate }
  />);

  renderPanels = () => {
    const { mf, } = this.props;
    const { filters, orders, panels, } = mf;
    const { filterId, changed, } = mf;
    const filtersNotEmpty = filters.some(_ => !_.auto && !_.empty);
    const ordersNotEmpty = orders.some(_ => !_.empty);
    const notEmpty = filtersNotEmpty || ordersNotEmpty;

    return (
      <div className="magic-filter-panel__fast" >
        { panels.map(this.renderPanel) }
        { this.state.loaded && changed ? this.renderApply() : null }
        { this.state.loaded && changed ? this.renderReset() : null }
        { this.state.loaded && filterId && notEmpty ? this.renderClean() : null }
      </div>
    );
  };

  render () {
    if (!this.props.show) {
      return null;
    } else if (this.state.redirect) {
      // eslint-disable-next-line no-restricted-globals
      const url = new URL(this.props.location.pathname, location.href);

      url.searchParams.delete('page');
      const to = url.href.slice(url.origin.length);

      return (<Redirect to={ to } />);
    }

    return (
      <div className={ `magic-filter-panels` } >
        { this.renderPanels() }
        {/* { false && this.renderRich() } */}
        {/* { this.renderRichForm() } */}
      </div>
    );
  }

}

export const MagicFilterPanels = withRouter(MagicFilterPanelsComponent);
