import { DataService } from "./DataService";
import { del, get, post, put } from "../components/Magic/helpers/MagicRequest";
import { cachingService, hash as hashData } from "./CachingService";
import {
    GET_AVATAR,
    GET_USER_KPI_SALES_PLANE,
    GET_USERS,
    SET_USER_KPI_SALES_PLANE,
    GET_PERMISSIONS,
    GET_PERMISSIONS_SELECT,
    GET_USER,
    GET_USER_AVATAR,
    GET_USERS_AVATARS,
    SET_USER,
    TEMPLATE_PERMISSIONS,
    GET_PERMISSION_TEMPLATES,
    GET_USERS_PERMISSION_TEMPLATES,
    GET_USER_EVENT_SETTINGS,
    USER_EVENT_SETTINGS,
    USER_EVENTS,
    USER_PROFILE_EVENTS
} from "../apiRoutes";
import { securedRequest } from "./RequestService";

class UserDataService extends DataService {
    constructor () {
        super([
            'createUser',
            'updateUser',
            'getUserRequest',
            'updateTemplatePm',
            'createTemplatePm',
            'removeTemplatePm',
            'getTemplatePm',
            'getPermissions',
            'getPermissionsSelect',
            'getUserAvatar',
            'getPermissionTemplates',
            'getUsersPermissionTemplates',
            'getUsersList',
            'getAllAvatar',
            'getSalesPlanes',
            'setSalesPlanes',
            'getUser',
            'deleteUser',
            'setAvatar',
            'userEventSettings',
            'userEventSettingsById',
            'createUserEventSettings',
            'updateUserEventSettings',
            'updateUserEvents',
            'userEvents',
        ]);
    }

    async createUser (user) {
        const url = `${SET_USER}`;
        const data = await post(url, user);
        const createUser = { data };
        this.emit('createUser', createUser);

        return createUser;
    }

    async updateUser (user, id) {
        const url = `${SET_USER}/${id}`;
        const data = await post(url, user);
        const updateUser = { data };
        this.emit('updateUser', updateUser);
    }

    async getUserRequest () {
        const url = `${GET_USER}`;
        const data = await get(url);
        const hash = await hashData(data);
        const getUserRequest = { data, hash };
        this.emit('getUserRequest', getUserRequest);

        return getUserRequest;
    }

    async updateTemplatePm ({ data, name }, id) {
        const url = `${TEMPLATE_PERMISSIONS}/${id}`;
        const response = await put(url, { data, name });
        const updateTemplatePm = { response, id, obj: { data, name }};
        this.emit('updateTemplatePm', updateTemplatePm);
    }

    async createTemplatePm (permission) {
        const url = `${TEMPLATE_PERMISSIONS}`;
        const data = await post(url, permission);
        const createTemplatePm = { data };
        this.emit('createTemplatePm', createTemplatePm);
    }

    async removeTemplatePm (id) {
        const url = `${TEMPLATE_PERMISSIONS}/${id}`;
        const data = await del(url);
        const removeTemplatePm = { data, id };
        this.emit('removeTemplatePm', removeTemplatePm);
    }

    async getTemplatePm () {
        //Objects.defineProperties
        const url = `${TEMPLATE_PERMISSIONS}`;
        const data = await get(url);
        const hash = await hashData(data);
        const getTemplatePm = { data, hash };
        this.emit('getTemplatePm', getTemplatePm);

        return getTemplatePm;
    }

    async getPermissions () {
        const url = `${GET_PERMISSIONS}`;
        const data = await get(url);
        const hash = await hashData(data);
        const getPermissions = { data, hash };
        this.emit('getPermissions', getPermissions);

        return getPermissions;
    }

    async getPermissionsSelect (value) {
        const url = `${GET_PERMISSIONS_SELECT}?value=${value}`;
        const data = await get(url);
        const getPermissionsSelect = { data, value };
        this.emit('getPermissionsSelect', getPermissionsSelect);

        return getPermissionsSelect;
    }

    async getUserAvatar (id) {
        //TODO: rewrite method for images!!!!!
        const url = `${GET_USER_AVATAR}/${id}`;
        const { data } = await securedRequest.get(url, {
            responseType: 'arraybuffer',
        });
        const getUserAvatar = { data, id };
        this.emit('getUserAvatar', getUserAvatar);

        return getUserAvatar;
    }

    async getPermissionTemplates (options) {
        const url = `${GET_PERMISSION_TEMPLATES}`;
        const data = await get(url, options);
        const hash = await hashData(data);
        const getPermissionTemplates = { data, hash, options };
        this.emit('getPermissionTemplates', getPermissionTemplates);

        return getPermissionTemplates;
    }

    async getUsersPermissionTemplates (options) {
        //TODO: need to make cash for this route
        const url = `${GET_USERS_PERMISSION_TEMPLATES}`;
        const data = await get(url, options);
        const hash = await hashData(data);
        const getUsersPermissionTemplates = { data, hash, options };
        this.emit('getUsersPermissionTemplates', getUsersPermissionTemplates);

        return getUsersPermissionTemplates;
    }

    async getUsersList (options) {
        const url = `${GET_USERS}`;
        const users = await get(url, options);
        const hash = await hashData(users);
        const getUsersList = { users, hash, options };
        this.emit('getUsersList', getUsersList);

        return getUsersList;
    }

    async getAllAvatar () {
        //Objects.defineProperties
        const url = GET_USERS_AVATARS;
        const data = await cachingService.dictionary(url);
        const hash = await hashData(data);
        const getAllAvatar = { data, hash };
        this.emit('getAllAvatar', getAllAvatar);

        return getAllAvatar;
    }

    async getSalesPlanes (options) {
        const url = `${GET_USER_KPI_SALES_PLANE}`;
        const planes = await get(url, options);
        const hash = await hashData(planes);
        const getSalesPlanes = { planes, hash, options };
        this.emit('getSalesPlanes', getSalesPlanes);

        return getSalesPlanes;
    }

    async setSalesPlanes (options) {
        const url = `${SET_USER_KPI_SALES_PLANE}`;
        const data = await post(url, options);
        const setSalesPlanes = { data };
        this.emit('setSalesPlanes', setSalesPlanes);
    }

    async getUser (id) {
        const url = `${GET_USER}/${id}`;
        const { data: { core }} = await get(url);
        const hash = await hashData(core);
        const getUser = { data: core, hash, id };
        this.emit('getUser', getUser);

        return getUser;
    }

    async deleteUser (id) {
        const url = `${GET_USER}/${id}`;
        const data = await del(url);
        const deleteUser = { data, id };
        this.emit('deleteUser', deleteUser);
    }

    async setAvatar (id) {
        const url = `${GET_AVATAR}/${id}`;
        const data = await get(url);
        const hash = await hashData(data);
        const setAvatar = { data, hash, id };
        this.emit('setAvatar', setAvatar);

        return setAvatar;
    }
    async userEventSettings () {
        const url = `${GET_USER_EVENT_SETTINGS}`;
        const data = await get(url);
        const hash = await hashData(data);
        const userEventSettings = { data, hash, };
        this.emit('userEventSettings', userEventSettings);

        return userEventSettings;
    }

    async userEventSettingsById (id) {
        const url = `${USER_EVENT_SETTINGS}/${id}`;
        const data = await get(url);
        const hash = await hashData(data);
        const userEventSettingsById = { data, hash, id, };
        this.emit('userEventSettingsById', userEventSettingsById);

        return userEventSettingsById;
    }

    async createUserEventSettings ({ user_id, event_id, push, email, }) {
        const url = `${USER_EVENT_SETTINGS}`;
        const data = await post(url, { user_id, event_id, push, email, });
        const createUserEventSettings = { data };
        this.emit('createUserEventSettings', createUserEventSettings);

        return createUserEventSettings;
    }

    async updateUserEventSettings (obj, id) {
        const url = `${USER_EVENT_SETTINGS}/${id}`;
        const data = await put(url, obj);
        const updateUserEventSettings = { data };
        this.emit('updateUserEventSettings', updateUserEventSettings);

        return updateUserEventSettings;
    }

    async updateUserEvents (obj, id) {
        const url = `${USER_EVENTS}/${id}`;
        const data = await put(url, obj);
        const updateUserEvents = { data };
        this.emit('updateUserEvents', updateUserEvents);

        return updateUserEvents;
    }

    async userEvents () {
        const url = `${USER_PROFILE_EVENTS}`;
        const data = await get(url);
        const hash = await hashData(data);
        const userEvents = { data, hash, };
        this.emit('userEvents', userEvents);

        return userEvents;
    }
}

export const userService = new UserDataService();
