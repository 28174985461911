import {
	itemsBuild, itemsDrain, itemsResort, CFG_ITEM_TYPE,
} from "../Config";
import { onEnabled, render, } from "../Mentor/withDropDown";

export default {
	id: 167,
	...[
		// filters
		...[
			{
				f: "partners.logos.id",
				id: "f:logosId",
			},
			{
				f: "partners.logos.name",
				id: "f:logosName",
			},
			{
				f: "partners.logos.size",
				id: "f:logosSize",
			},
			{
				f: "partners.logos.status",
				id: "f:logosStatus",
			},
			{
				f: "partners.logos.created_at",
				id: "f:logosApplicationDate",
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: "filter", ..._, })),

		// orders
		...[
			{
				f: "partners.logos.id",
				id: "o:logosId",
			},
			{
				f: "partners.logos.name",
				id: "o:logosName",
			},
			{
				f: "partners.logos.size",
				id: "o:logosSize",
			},
			{
				f: "partners.logos.status",
				id: "o:logosStatus",
			},
			{
				f: "partners.logos.created_at",
				id: "o:logosApplicationDate",
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: "order", ..._, })),

		// panels
		...[
			{
				t: "partner_logos_id",
				ii: "f:logosId",
				
			},
			{
				t: "partner_logos_name",
				ii: "f:logosName",
			},
			{
				t: "partner_logos_size",
				ii: "f:logosSize",
			},
			{
				t: "partner_logos_status",
				ii: "f:logosStatus",
			},
			{
				t: "partner_logos_application_date",
				ii: "f:logosApplicationDate",
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: "panel", ..._, })),
	]
		.map(itemsBuild)
		.reduce(itemsDrain, [])
		.reduce(itemsResort, {}),

	path: "partners/logos",
	widget: false,
};