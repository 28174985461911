import React, { Component, createRef, } from 'react';
import { withLocalize, } from "react-localize-redux";
import HeaderInfoBlock from "./components/HeaderInfoBlock";
import SendMessageBlock from "./components/SendMessageBlock";
import RenderMessagesWindow from "./components/RenderMessagesWindow";
import { getOnlineTechManagers, getQuickAnswersByLang, } from "../../services/ConversationsRequestService";
import { logger, } from "redux-logger";

class ConversationWindow extends Component {
	constructor () {
		super();
		this.state = {
			contactId: '',
			quickAnswersData: [],
			techManagers: [],
		};
	}

	componentDidMount = () => {
		 this.quickMessagesData();
		 this.getTechManagers();

	}
	save = async state => new Promise(next => this.setState(state, next));
	quickMessagesData = async () => {
		const { conversationData, } = this.props;
		const slicedConversationData = Object.assign({}, conversationData.slice(0, 1));
		const contactId = slicedConversationData[Object.keys(slicedConversationData)].novatalks?.messages?.contact_id;

		try {
			const quickAnswersData = await getQuickAnswersByLang(contactId);

			await this.save({ contactId, quickAnswersData, });
		} catch (e) {
			console.log(e);
		}

	}
	getTechManagers = async () => {
		try {
			const techManagers = await getOnlineTechManagers();

			await this.save({ techManagers, });
		} catch (e) {
			console.log(e);
		}
	}

	get translate () {
		return this.props.translate;
	}
	renderHeaderInfoBlock = () => {
		const { conversationData, } = this.props;

		return (
			<HeaderInfoBlock conversationData={ conversationData } />
		);
	}

	renderMessageWindow = () => {
		const { conversationData, updateConversation, } = this.props;

		return (
			<RenderMessagesWindow conversationData={ conversationData } updateConversation={ updateConversation } />
		);
	}

	renderInputBlock = () => {
		const {
			accountId, conversationId, conversationData, managerProfile,
		} = this.props;
		const { quickAnswersData, contactId, techManagers, } = this.state;

		return (
			<SendMessageBlock
				accountId={ accountId }
				contactId={ contactId }
				conversationData={ conversationData }
				conversationId={ conversationId }
				managerProfile={ managerProfile }
				quickAnswersData={ quickAnswersData }
				techManagers={ techManagers }

			/>
		);
	}
	render () {
		const {
			translate, renderInputBlock, renderHeaderInfoBlock, renderMessageWindow,
		} = this;
		const { accountId, } = this.props;
		const className = accountId ? "conversation_window_wrapper scroll-chat mh60" : "conversation_window_wrapper scroll-chat";

		return (
			<div >
				<h4 className="conversations__title">
					{translate(`conversation_window`)}
				</h4>

				<div className="conversation_window" >
					{ accountId ? '' : renderHeaderInfoBlock()}
					<div className={ className } >{renderMessageWindow()}</div>

					{renderInputBlock()}
				</div>
			</div>

		);
	}
}

export default withLocalize(ConversationWindow);