import React from 'react';
import _, { has } from 'lodash';
import DataStorage from "../helpers/DataStorage";
import { getProfile, setHash } from "../modules/admin_panel/users/reducers/user/actions";
// import { getProfile as uploadProfile } from "../modules/support/services/SupportRequestService";
import { profileService } from "./ProfileDataService";

const PermissionService = (() => {

	let store = null;
	let _this = null;
	let permission = null;
	let permissions = undefined;
	let array = null;
	let profile = undefined;

	const defaultAction = {
		index: "index",
		show: "show",
		store: "store",
		update: "update",
		destroy: "destroy",
	};

	const lang = () => window.localStorage.getItem('locale') || store.getState()?.localize?.options?.defaultLanguage || 'en';
	const getHashFromStorage = () => DataStorage.getData('permission_hash');
	const setHashToStorage = (hash) => DataStorage.setData('permission_hash', hash);
	// const downloadProfile = async () => uploadProfile();

	return {
		init(globalStore) {
			_this = this;
			store = globalStore;
		},

		setHashToStore(hash) {
			const storageHash = getHashFromStorage();
			if (!hash && !storageHash) {
				return;
			}
			store.dispatch(setHash(hash ?? storageHash));
		},

		async hashIsEqual(hash) {
			if (store.getState().user.session.hash) {
				if (store.getState().user.session.hash === hash) {

					return true;
				}
				await _this.refreshSessionDataFromServer(hash);

			}

		},

		async refreshSessionDataFromServer(hash) {
			try {
				const { data, } = await profileService.profile;
				profile = data;
				store.dispatch(getProfile(profile));
				store.dispatch(setHash(hash));
				setHashToStorage(hash);
			} catch (error) {
				console.log(error);
			}
		},

		start(params, action) {
			return this.action(params, action);
		},

		index(params) {
			return this.action(params, defaultAction.index);
		},

		show(params) {
			return this.action(params, defaultAction.show);
		},

		store(params) {
			return this.action(params, defaultAction.store);
		},

		update(params) {
			return this.action(params, defaultAction.update);
		},

		destroy(params) {
			return this.action(params, defaultAction.destroy);
		},

		indexRoute() {
			return defaultAction.index;
		},

		showRoute() {
			return defaultAction.show;
		},

		storeRoute() {
			return defaultAction.store;
		},

		updateRoute() {
			return defaultAction.update;
		},

		destroyRoute() {
			return defaultAction.destroy;
		},

		action(params, action) {
			if (this.canRoute(params)) {
				if (store.getState().user.profile.permission_data) {
					permission = JSON.parse(store.getState().user.profile.permission_data);

					if (has(permission, params.schema) && has(permission[params.schema], params.table)) {
						if (!has(permission[params.schema][params.table], action)) {
							array = Object.keys(0);

							return this;
						}

						array = Object.keys(permission[params.schema][params.table][action]);

						return this;
					}
				}
			}
			throw new Error('Array not exist!');
		},

		actionForRoute(params, action) {
			if (this.canRoute(params)) {
				if (store.getState().user.profile.permission_data) {
					permission = JSON.parse(store.getState().user.profile.permission_data);

					if (has(permission, params.schema) && has(permission[params.schema], params.table) && has(permission[params.schema][params.table], action)) {

						return true;
					}
				}
			}

			return false;
		},

		canRoute(params) {
			if (store.getState().user.profile.permission_data) {
				permission = JSON.parse(store.getState().user.profile.permission_data);

				if (has(permission, params.schema) && has(permission[params.schema], params.table)) {
					return true;
				}
			}

			return false;
		},

		//Methods for permission to fields in table

		can(field) {
			try {
				return array.includes(field);
			} catch (e) {
				console.log(e);
			}

		},

		getRows() {
			return array;
		},

		logout() {
			permissions = undefined;
		},

		load(newProfile) {
			// console.log('permissions', permissions);
			if (newProfile) {
				permissions = JSON.parse(newProfile?.permission_data ?? null);
				profile = newProfile;
			}
		},

		cast(data, config = [], raw = false) {
			if (!permissions) {
				const data = store.getState().user.profile.permission_data;
				permissions = JSON.parse(data ?? null);
			}
			if (data instanceof Array) {
				return castList(data, permissions, config, lang(), raw);
			} else if (data instanceof Object) {
				return castFields(data, permissions, config, lang(), raw);
			} else {
				return undefined;
			}
		},

		calc(config = []) {
			if (!permissions) {
				const data = store.getState().user.profile.permission_data;
				permissions = JSON.parse(data ?? null);
			}
			return accessItems(permissions, config, lang());
		},
	};
})();

export default PermissionService;

// ** Названия основных экшенов **
// index    - выборка всех данных
// show     - выборка одной единицы
// store    - сохранение или создание новой
// update   - редактирование
// destroy  - удаление

export const MODULE_KEYS = {
	'CORE_ACCOUNT': { 'schema': 'core', 'table': 'account' },
	'CORE_ACCOUNT_CORPORATE': { 'schema': 'core', 'table': 'account_corporate' },
	'CORE_ACCOUNT_DOCUMENT': { 'schema': 'core', 'table': 'account_document' },
	'CORE_MAIL_TEMPLATES': { 'schema': 'core', 'table': 'mail_templates' },
	'CORE_TIME_ZONE': { 'schema': 'core', 'table': 'time_zone' },
	'CORE_COUNTRY': { 'schema': 'core', 'table': 'country' },
	'CORE_LANGUAGE': { 'schema': 'core', 'table': 'language' },
	'CORE_USER': { 'schema': 'core', 'table': 'user' },
	'CORE_USER_CALENDAR': { 'schema': 'tasks', 'table': 'tasks' },
	'CORE_HIERARCHY_TREE': { 'schema': 'core', 'table': 'hierarchy_tree' },
	'CORE_ACCOUNT_CATEGORIES': { 'schema': 'core', 'table': 'account_categories' },
	'CORE_ACCOUNT_BROKERS': { 'schema': 'core', 'table': 'account_brokers' },
	'CORE_ACCOUNT_LABELS': { 'schema': 'core', 'table': 'account_labels' },
	'CORE_ACCOUNT_LIFESTYLES': { 'schema': 'core', 'table': 'account_lifestyle' },
	'CORE_ACCOUNT_MESSENGERS': { 'schema': 'core', 'table': 'account_messengers' },
	'CORE_ACCOUNT_SOCIAL_NETWORKS': { 'schema': 'core', 'table': 'account_social_networks' },
	'CORE_ACCOUNT_STATUS': { 'schema': 'core', 'table': 'account_status' },
	'CORE_ACCOUNT_TYPES': { 'schema': 'core', 'table': 'account_types' },
	'CORE_TEMPLATE_PERMISSIONS': { 'schema': 'core', 'table': 'template_permissions' },
	'CORE_USER_KPI_PLAN': { 'schema': 'core', 'table': 'user_kpi_plan' },
	'CORE_TRADING_ACCOUNTS': { 'schema': 'core', 'table': 'trading_accounts' },
	'CORE_TAILS': { 'schema': 'core', 'table': 'tails' },
	'CORE_ACCOUNT_TAILS': { 'schema': 'core', 'table': 'account_tails' },
	'CORE_BALANCE_OPERATIONS':       { 'schema': 'core', 'table': 'system_accounts_transaction_history', },
	'TASKS_TASKS': { 'schema': 'tasks', 'table': 'tasks' },
	'TASKS_TASK_STATES': { 'schema': 'tasks', 'table': 'task_states' },
	'TASKS_TASK_URGENCY': { 'schema': 'tasks', 'table': 'task_urgency' },
	'TASKS_TASK_TYPES': { 'schema': 'tasks', 'table': 'task_types' },
	'TASKS_TASK_USERS': { 'schema': 'tasks', 'table': 'task_users' },
	'TASKS_TASK_DOCUMENTS': { 'schema': 'tasks', 'table': 'task_documents' },
	'TASKS_TASK_COMMENTS': { 'schema': 'tasks', 'table': 'task_comments' },
	'TASKS_TASK_CHECK_LISTS': { 'schema': 'tasks', 'table': 'task_check_lists' },
	'LOG_UPDATE': { 'schema': 'log', 'table': 'update' },
	'PAYMENT_HISTORY': { 'schema': 'payment', 'table': 'orders' },
	'PAYMENT_CARDS': { 'schema': 'payment', 'table': 'cards' },
	'PAYMENT_SYSTEMS': { 'schema': 'payment', 'table': 'systems' },
	'PAYMENT_SUMMARY':               { 'schema': 'payment', 'table': 'daily_aggregated_fin_transactions' },
	'MT_TRADING': { 'schema': 'tima', 'table': 'trading' },
	'CALLS_CALLS': { 'schema': 'calls', 'table': 'calls' },
	// TODO: check tima tables for permissions
	'TIMA_TIMA_AGENTS': { 'schema': 'tima', 'table': 'tima_agents' },
	'TIMA_TIMA_CONDITIONS': { 'schema': 'tima', 'table': 'tima_manager' },
	'TIMA_TIMA_INVESTMENTS': { 'schema': 'tima', 'table': 'tima_investment' },
	'TIMA_TIMA_STRATEGIES': { 'schema': 'tima', 'table': 'tima_manager' },
	// TODO: check partners tables for permissions
	'PARTNERS_CONDITIONS': { 'schema': 'partners', 'table': 'conditions' },
	'PARTNERS_SYMBOLS_LISTS': { 'schema': 'partners', 'table': 'symbols_lists' },
	'PARTNERS_GROUP_LISTS': { 'schema': 'partners', 'table': 'groups_lists' },
	'PARTNERS_PAYMENTS': { 'schema': 'partners', 'table': 'accruals' },
	'PARTNERS_SETUP': { 'schema': 'partners', 'table': 'partners_setup' },
	'PARTNERS_GROUP_SYMBOLS': { 'schema': 'partners', 'table': 'groups_symbols' },
	'PARTNERS_PROFITS': { 'schema': 'partners', 'table': 'statistic' },
	'PARTNERS_REFERRALS': { 'schema': 'partners', 'table': 'referrals' },
	'PARTNERS_PAYOUT_HISTORY': { 'schema': 'partners', 'table': 'payout_history' },
	'PARTNERS_TARGET_PAGE': { 'schema': 'partners', 'table': 'target_pages' },//*
	'PARTNERS_LOGOS': { 'schema': 'partners', 'table': 'logos' },
	'PARTNERS_LENDINGS': { 'schema': 'partners', 'table': 'lending' },
	'PARTNERS_STATISTICS': { 'schema': 'partners', 'table': 'statistics' },//*
	'PARTNERS_PROMO': { 'schema': 'partners', 'table': 'promo' },
	'PARTNERS_REBATE': { 'schema': 'partners', 'table': 'rebate_projects' },
	'PARTNERS_REQUESTS': { 'schema': 'partners', 'table': 'requests' },
	'PARTNERS_DASHBOARD': { 'schema': 'partners', 'table': 'condition_calculation_state' },
	'PARTNERS_PROGRAMS': { 'schema': 'partners', 'table': 'programs' },
	'PARTNERS_PAYOUTS_RENUMERATIONS': { 'schema': 'partners', 'table': 'new_partners_conditions' },
	'PARTNERS_PAYOUT_RATE_LIST': { 'schema': 'partners', 'table': 'payout_rate_lists' },
	'PARTNERS_SYMBOLS': { 'schema': 'partners', 'table': 'symbols' },
	'PARTNERS_SYMBOLS_GROUP': { 'schema': 'partners', 'table': 'symbols_groups' },
	'PARTNERS_PARTNERS_LIST': { 'schema': 'partners', 'table': 'partners' },
	'PAYMENTS_REQUESTS': { 'schema': 'payment', 'table': 'orders' },
	'CONTESTS_CONTESTS': { 'schema': 'contests', 'table': 'contests' },
	'CONTESTS_MEMBERS': { 'schema': 'contests', 'table': 'members' },
	// TODO: check support tables for permissions
	'SUPPORT_TICKETS': { 'schema': 'tickets', 'table': 'tickets' },
	'LOG_MAILS': { 'schema': 'log', 'table': 'mails' },
	'STATISTICS_UTM_SOURCE': { 'schema': 'statistics', 'table': 'utm_source' },
	'STATISTICS_UTM_MEDIUM': { 'schema': 'statistics', 'table': 'utm_medium' },
	'STATISTICS_UTM_CAMPAIGN': { 'schema': 'statistics', 'table': 'utm_campaign' },
	'STATISTICS_UTM_LINK': { 'schema': 'statistics', 'table': 'utm_link' },
	'STATISTICS_ACCOUNT_UTM_LINK': { 'schema': 'statistics', 'table': 'account_utm_link' },
	//BONUSES
	'BONUS_PROGRAM_TO_ACCOUNT': { 'schema': 'core', 'table': 'bonus_program_to_account' },
};

const castKey = (key, ...path) => key || path.join('_').toLowerCase()
	.replace(/_+(.)/g, (_, p) => p.toUpperCase());

const accessCheck = (
	permissions,
	schema = undefined,
	table = undefined,
	action = undefined,
	field = undefined,
	lang = undefined
) => {
	if (schema === undefined) {
		return true;
	}
	if (table === undefined) {
		const path = [schema];
		return _.has(permissions, path);
	}
	if (action === undefined && field === undefined) {
		const path = [schema, table];
		return _.has(permissions, path);
	} else if (action === undefined) {
		const actions = 'index,show,store,update,destroy'.split(',');
		const attr = a => [permissions, schema, table, a, field, lang];
		return actions.every(a => accessCheck(...attr(a)));
	} else if (action === '*') {
		const actions = 'index,show,store,update,destroy'.split(',');
		const attr = a => [permissions, schema, table, a, field, lang];
		return actions.some(a => accessCheck(...attr(a)));
	}
	if (field === undefined) {
		const path = [schema, table, action];
		return _.has(permissions, path);
	}
	if (lang === undefined) {
		const path = [schema, table, action, field];
		return _.has(permissions, path)
	}
	if (['name', 'title', 'description'].includes(field)) {
		const path1 = [schema, table, action, field];
		const path2 = [schema, table, action, `${field}_${lang}`];
		const has1 = _.has(permissions, path1);
		const has2 = _.has(permissions, path2);
		return has1 || has2;
	}
	const path = [schema, table, action, field];
	return _.has(permissions, path);
};

const accessItem = (
	permissions,
	key,
	accessChecked,
	schema,
	table,
	field,
	options,
	lang,
	items,
) => {
	const item = Object.create({ ...options, }, {
		key: { enumerable: true, get: () => castKey(key, item.path), },
		schema: { get: () => schema, },
		table: { get: () => table, },
		field: { get: () => field, },
		options: { enumerable: true, get: () => options, },
		path: {
			enumerable: true,
			get: () => [schema, table, field].filter($ => $),
		},
		access: {
			value: (...actions) => {
				if ([true, false].includes(accessChecked)) {
					return accessChecked;
				}
				const access = action => accessCheck(
					permissions,
					schema,
					table,
					action,
					field,
					lang
				);
				if (accessChecked instanceof Function) {
					return accessChecked({ access, item, items, });
				}
				return actions.length ? actions.every(access) : access();
			},
		},
		accesses: {
			value: (...actions) => {
				const access = action => ({ [action]: item.access(action) });
				return Object.assign({}, ...actions.map(access));
			},
		},
		// index: { enumerable: true, get: () => item.access('index'), },
		// show: { enumerable: true, get: () => item.access('show'), },
		// store: { enumerable: true, get: () => item.access('store'), },
		// update: { enumerable: true, get: () => item.access('update'), },
		// destroy: { enumerable: true, get: () => item.access('destroy'), },
		debug: {
			enumerable: true,
			get: () => {
				const accesses = 'index,show,store,update,destroy';
				return item.accesses(...accesses.split(','));
			},
		},
		toJSON: {
			value: () => ({ ...item, }),
		},
	});
	return item;
};

const castItem = (
	data,
	permissions,
	key,
	accessChecked,
	schema,
	table,
	field,
	options,
	lang,
	items,
) => {
	const { defaults = undefined, } = options;
	const item = accessItem(
		permissions,
		key,
		accessChecked,
		schema,
		table,
		field,
		options,
		lang,
		items,
	);
	Object.defineProperties(item, {
		data: { get: () => data, },
		valueOf: {
			enumerable: true,
			get: () => {
				let T = data;
				if ([T, schema].includes(undefined)) {
					return defaults;
				}
				T = _.get(T, [schema], undefined);
				if ([T, table].includes(undefined)) {
					return T;
				}
				T = _.get(T, [table], undefined);
				if ([T, field].includes(undefined)) {
					return T;
				} else if (T instanceof Array) {
					return T.map(t => _.get(t, [field], defaults));
				} else if (T instanceof Object) {
					return _.get(T, [field], defaults);
				} else {
					return defaults;
				}
			},
		},
	});
	return item;
};

const accessConfig = (config) => config.map(cfg => {
	if (cfg instanceof Array) {
		const [schema, table = undefined, field = undefined] = cfg;
		return {
			key: undefined,
			accessChecked: undefined,
			schema, table, field,
			options: {},
		};
	} else if (cfg instanceof Object) {
		const {
			key = undefined,
			accessChecked = undefined,
			path = [],
			...options
		} = cfg;
		const [schema, table = undefined, field = undefined] = path;
		return { key, accessChecked, schema, table, field, options, };
	}
	return {};
});

const accessItems = (permissions, config, lang) => {
	const items = Object.create(null, {
		toJSON: {
			value: () => {
				const map = key => ({ [key]: items[key].toJSON(), });
				return Object.assign({}, ...Object.keys(items).map(map));
			},
		},
	});
	for (const cfg of accessConfig(config)) {
		const { key, accessChecked, schema, table, field, options, } = cfg;
		if (accessChecked === undefined && !schema) {
			continue;
		}
		const item = accessItem(
			permissions,
			key,
			accessChecked,
			schema,
			table,
			field,
			options,
			lang,
			items
		);
		Object.defineProperties(items, {
			[item.key]: { enumerable: true, get: () => item, },
		});
	}
	return items;
};

const castItems = (data, permissions, config, lang, dataIndex) => {
	const items = Object.create(null, {
		toJSON: {
			value: () => {
				const map = key => ({ [key]: items[key].toJSON(), });
				return Object.assign({}, ...Object.keys(items).map(map));
			},
		},
	});
	for (const cfg of accessConfig(config)) {
		const { key, accessChecked, schema, table, field, options, } = cfg;
		if (accessChecked === undefined && !schema) { // schema?.table?.field
			continue;
		}
		const item = castItem(
			data,
			permissions,
			key,
			accessChecked,
			schema,
			table,
			field,
			options,
			lang,
			items,
		);
		if (item.key in items) continue;
		Object.defineProperties(item, {
			item: { get: () => item, },
			items: { get: () => items, },
			render: {
				value: (render) => {
					if (render instanceof Function) {
						return render(item.valueOf, { item, items, data, index: dataIndex, });
					} else if (item?.options?.render instanceof Function) {
						const render = item.options.render;
						return render(item.valueOf, { item, items, data, index: dataIndex, });
					}
					console.error(`Permission render not specified for ${item.key}`);
					return `? render ${item.key} ?`;
				},
			},
		});
		Object.defineProperties(items, {
			[item.key]: { enumerable: true, get: () => item, },
		});
	}
	return items;
};

const castFields = (data, permissions, config, lang, raw, dataIndex = 0) => {
	const items = castItems(data, permissions, config, lang, dataIndex);
	if (raw) {
		return items;
	} else {
		const result = Object.create(null, {});
		for (const key in items) {
			const item = items[key];
			Object.defineProperties(result, {
				[key]: {
					get: () => (<React.Fragment>{item.render()}</React.Fragment>),
				},
			});
		}
		return result;
	}
};

const castList = (data, permissions, config, lang, raw) => {
	return data.map((data, dataIndex) => castFields(data, permissions, config, lang, raw, dataIndex));
};
