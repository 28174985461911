import React, { Component } from 'react';

import Loader from "../../../tima/components/Loader";
import Preloader from "../../../../components/LoadingHOC/Preloader";
import PropTypes from "prop-types";
import { MagicConfirm } from "../Magic/MagicConfirm";

import NotificationService from "../../../../services/NotificationService";
import { MagicSwitch } from "../Magic/MagicSwitch";
import { get_random_guid } from "../../../../helpers/OtherHelper";
import { forEach } from "lodash";
import { Table } from 'reactstrap';
import { timaService } from "../../../../services/TimaDataService";

class PopupEditInvestmentCondition extends Component {
    constructor (props) {
        super(props);

        this.state = {
            loaded:       false,
            loadedInnerRequest: true,
            conditionsList: {
                data: [],
                hash: null,
            },

            isMagicConfirmVisible: {
                editInvestmentCondition:  false,
            },
            isSelectedCondition: true,
        };
    }

    save = async (state) => new Promise(next => this.setState(state, next));

    timaStrategyConditionsAllChanges = async (conditionsList) => {
        if (conditionsList.hash===this.state?.conditionsList?.hash) {
            return `${this.constructor.name}.timaStrategyConditionsAllChanges: false`;
        }
        if (conditionsList.managerId !== this.props?.timaManagerId) {
            return `${this.constructor.name}.strategyConditionsChanges: false`;
        }
        const { response, hash } = conditionsList;
        const data = response?.map?.((item) => {
            return {
                ...item.tima.tima_condition,
                tima_condition: item.tima.tima_condition.name,
                tima_account: item.tima.tima_manager.name,
                isSelected: item.tima.tima_condition.id === timaConditionId ? 1 : 0,
            };
        });
        await this.save({ conditionsList: { data, hash }});

        return `${this.constructor.name}.timaStrategyConditionsAllChanges: true`;
    };

    componentDidMount = async () => {
        timaService.subscribe('strategyConditionsAll', this.timaStrategyConditionsAllChanges, this);
        this.onDataLoad();
    };

    componentWillUnmount = () => {
        timaService.unsubscribe('strategyConditionsAll', this.timaStrategyConditionsAllChanges, this);
    };

    onDataLoad = () => {
        const setLoaded = (loaded) => this.setState(() => ({ loaded, }));

        setLoaded(false);

        (async () => {
            try {
                const { timaManagerId, timaConditionId } = this.props;
                const { response, hash } = await timaService.strategyConditionsAll(timaManagerId);

                if (response) {
                    const data = response?.map?.((item) => {
                        return {
                            ...item.tima.tima_condition,
                            tima_condition: item.tima.tima_condition.name,
                            tima_account: item.tima.tima_manager.name,
                            isSelected: item.tima.tima_condition.id === timaConditionId ? 1 : 0,
                        };
                    });

                    await this.save({ conditionsList: { data, hash } });
                }
            } catch (error) {
                error?.showErrorNotification?.();
            }
            setLoaded(true);
        })();
    };

    closeAllMagicConfirm = async () => {
        const { isMagicConfirmVisible } = this.state;

        for (const key in isMagicConfirmVisible) {
            isMagicConfirmVisible[key] = false;
        }

        await this.save({ isMagicConfirmVisible });
    };

    confirmRejectHandler = (actionName) => (event) => {
        this.closeAllMagicConfirm();
    };

    getSelectedTimaCondition = () => {
        const selectedTimaCondition = this.state.conditionsList.data.filter((item) => {
            return item.isSelected;
        });
        const isSelectedCondition = selectedTimaCondition.length === 1;

        this.setState({isSelectedCondition});

        return isSelectedCondition ? selectedTimaCondition[0] : false;
    };

    onConfirmClick = (action) => (event) => {
        const setLoaded = (loadedInnerRequest) => this.setState(() => ({ loadedInnerRequest, }));
        const selectedTimaCondition = this.getSelectedTimaCondition();

        this.closeAllMagicConfirm();

        if (selectedTimaCondition) {
            (async () => {
                setLoaded(false);

                try {
                    const { timaInvestmentId, accountId } = this.props;
                    const selectedConditionId = selectedTimaCondition.id;
                    const fieldName = 'tima_conditions_id';
                    const data = { account_id: accountId, [fieldName]: selectedConditionId };
                    const { response } = await timaService.updateInvestment(timaInvestmentId, data);

                    if (!response) {
                        throw new Error(`Can*t update the field: '${fieldName}', please try again later!`);
                    }

                    this.props.refreshChildData();
                    this.props.popUpClose();
                } catch (error) {
                    NotificationService.error({
                        title:   "error",
                        message: error.message,
                        remove:  false,
                    });
                }
                setLoaded(true);
            })();
        }
    };

    onActionBtnClick = (actionName) => async (event) => {
        const { isMagicConfirmVisible } = this.state;

        for (const key in isMagicConfirmVisible) {
            isMagicConfirmVisible[key] = key === actionName;
        }

        await this.save({ isMagicConfirmVisible });
    };

    onSwitchChange = (fieldName, rowIndex, data) => ({ value }) => {
        const data = this.state.conditionsList.data.map((item, index) => {
            item.isSelected = index === rowIndex ? value : 0; // radio button behaviour

            return item;
        });

        this.setState({conditionsList: { ...this.state.hash, data }});
    };

    checkFieldTH = (field, fieldName) => {
        const translateName = this.props.translate(`tima_conditions_table_${fieldName}`);

        switch (fieldName) {
            default: {
                return (
                    <th key={`th-${ fieldName }`}>
                        <div className={'text-align-center'}>
                            {translateName}
                        </div>
                    </th>
                );
            }
        }
    };

    checkFieldTD = (rowIndex, cellIndex, fieldName, data) => {
        if (data) {
            const key = get_random_guid();

            switch (fieldName) {
                case 'select_row': {
                    const { conditionsList: { data } } = this.state;
                    const curTimaConditionsListData = data[rowIndex];
                    const isSelected = (
                        curTimaConditionsListData !== 'undefined' &&
                        curTimaConditionsListData.isSelected !== 'undefined'
                    ) ? curTimaConditionsListData.isSelected : 0;

                    return (
                        <td key={key}>
                            <div>
                                <MagicSwitch
                                    className={`magic-switch magic-switch--inline`}
                                    index={isSelected}
                                    key={`magic-switch-${fieldName}-${rowIndex}`}
                                    reverse={true}
                                    values={[0, 1]}
                                    text=''
                                    onChange={this.onSwitchChange(fieldName, rowIndex, data)}
                                />
                            </div>
                        </td>
                    );
                }
                default: {
                    const fieldValue = data[fieldName];

                    return (
                        <td key={key}>
                            <div>
                                {fieldValue}
                            </div>
                        </td>
                    );
                }
                /*default: {
                    throw new Error(`No such field in table: ${fieldName}`);
                }*/
            }
        } else {
            return;
        }

    };

    renderTH = () => {
        const tableHeads = [];

        forEach(this.props.fieldsTable, (name, i) => {
            tableHeads.push(this.checkFieldTH(i, name));
        });

        return tableHeads;
    };

    renderTableBody = (dataItems) => {
        const tableRows = [];

        if (dataItems.length) {
            forEach(dataItems, (data, rowIndex) => {
                tableRows.push(this.renderTableBodyRow(rowIndex, data));
            });
        }

        return tableRows;
    };

    renderTableBodyRow = (rowIndex, data) => {
        const tableCells = [];

        if (data) {
            forEach(this.props.fieldsTable, (fieldName, cellIndex) => {
                tableCells.push(this.checkFieldTD(rowIndex, cellIndex, fieldName, data));
            });
        }

        return (
            <tr data-row-index={rowIndex}
                key={`row-${ rowIndex }`}>
                {tableCells}
            </tr>
        );
    };

    renderTable = () => {
        const tableTH = this.renderTH();
        const errorClass = !this.state.isSelectedCondition ? 'error': '';

        return (
            <div className={`table bordered-rounded scroll table--edit-investment-condition ${errorClass}`}>
                <Table>
                    <thead>
                    <tr>
                        {tableTH}
                    </tr>
                    </thead>
                    <tbody className='tbody'>
                        {this.renderTableBody(this.state.conditionsList.data)}
                    </tbody>
                </Table>
            </div>
        );
    };

    render () {
        const { translate, } = this.props;
        const { isMagicConfirmVisible, loaded, loadedInnerRequest } = this.state;
        const { editInvestmentCondition } = isMagicConfirmVisible;

        return (
            <Loader
                loaded={loaded}
                loading={(<Preloader className='loaderUniversal--fixed-pos' scale={1}/>)}
            >
                <div className='glalex-styles pop-up pop-up--edit-investment-condition pop-up--active'>

                    {!loadedInnerRequest ?
                        (
                            <Preloader className='loaderUniversal--fixed-pos' scale={1}/>
                        ) : false
                    }

                    <div className='pop-up-wrapper'>
                        <div className='pop-up-header'>
                            <h3 className='pop-up__name'>
                                {translate('tima_edit_investment_condition_title')}
                            </h3>
                            <i className='gl-icon close-btn close-btn--big' onClick={this.props.popUpClose}/>
                        </div>

                        <div className='pop-up-content'>
                            {this.renderTable()}
                        </div>

                        <div className='btns-cont'>
                            <div className="magic-confirm-wrapper">
                                <MagicConfirm
                                    onAccept={this.onConfirmClick('editInvestmentCondition')}
                                    onReject={this.confirmRejectHandler('editInvestmentCondition')}
                                    title={this.props.translate(`magic_confirm_title`)}
                                    accept={this.props.translate(`magic_confirm_yes`)}
                                    reject={this.props.translate(`magic_confirm_no`)}
                                    isVisible={!!editInvestmentCondition}
                                />

                                <button className='gl-btn gl-btn--turquoise'
                                        onClick={this.onActionBtnClick('editInvestmentCondition')}
                                >
                                    {translate('partners_save_btn')}
                                </button>
                            </div>

                            <button className='gl-btn gl-btn--blue-border'
                                    onClick={this.props.popUpClose}
                            >
                                {translate('partners_cancel_btn')}
                            </button>
                        </div>
                    </div>
                </div>
            </Loader>
        );
    }
}

export default (PopupEditInvestmentCondition);

PopupEditInvestmentCondition.propTypes = {
    popUpClose:       PropTypes.func.isRequired,
    refreshChildData: PropTypes.func.isRequired,
};

PopupEditInvestmentCondition.defaultProps = {
    fieldsTable: [
        'select_row',
        'version',
        'tima_condition',
        'invest_min',
        'invest_max',
        'reward',
        'min_invest_period',
        'penalty',
        'tima_account',
    ],
};
