import React, { Component } from 'react';
import RangeSlider from "./RangeSlider";

class DayRiskItem extends Component {

    render () {
        const { translate, risk_type, day_profit_risk,  item = {}, symbol, index, maxValue, currentValue } = this.props;
        const { profit } = item;
        const sliderContClass = +currentValue === 0 ? 'slider-cont--hidden' : '';

        const calculateValue = () => {
            const { day_max_profit, day_max_T } = this.props;
            let calcCurrentValue;

            switch (+risk_type) {
                // RiskTypeEnum
                case 0: {
                    // currency: (1- (day_profit_risk / day_max_profit)) * 100
                    calcCurrentValue = Math.abs(1 - currentValue / day_max_profit) * 100;
                    break;
                }
                case 1:
                case 2: {
                    // percent and TIMA: (1- (day_profit_risk / day_max_T)) * 100
                    calcCurrentValue = Math.abs(1 - currentValue / day_max_T) * 100;
                    break;
                }
                default: {
                    calcCurrentValue = currentValue;
                    break;
                }
            }

            return calcCurrentValue;
        };

        const calcCurVal = calculateValue();
        const calcCurValFormat = calcCurVal ? calcCurVal.toFixed(2) : 0;

        return (
            <div className = 'block full-cont'>
                <div className = 'block-header cont'>
                    <span className = 'block-name'>
                        {profit} {symbol}
                    </span>
                </div>
                <div className = 'block-content cont'>
                    <RangeSlider
                        sliderId = {`day_profit_risk-${index}`}
                        minValue = '0'
                        maxValue = {maxValue}
                        initialValue = {calcCurValFormat} // !!! TODO: insert valid current value !!!
                        step = '1'
                        symbol={`%`}
                        isSymbolShown={true}
                        sliderContClass={sliderContClass}
                    />
                </div>
            </div>
        );
    }
}
export default DayRiskItem;
