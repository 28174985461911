import React from 'react';
import { withLocalize } from 'react-localize-redux';
import DatePicker from 'react-datepicker';
import QuarterPicker from './QuarterPicker';
import moment from 'moment';

/**
 * @param {function} onChange callback used to recive { from, to } date values (REQUIERED)
 * @param {string} dateFrom date from value (REQUIERED)
 * @param {string} dateTo date (REQUIERED)
 * @param {Array} avaliableFilterTypes valid types are: ['currentMonth', 'exactMonth', 'quarter', 'year', 'exactDate']
 */
class PeriodFilter extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            selectedPeriod: 'currentMonth',
            showDropdown:   false,
        };
    }

    componentWillUnmount () {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    get quarters () {
        return [
            {
                dateFrom: '01-01',
                dateTo:   '03-31',
                title:    'I',
            },
            {
                dateFrom: '04-01',
                dateTo:   '06-30',
                title:    'II',
            },
            {
                dateFrom: '07-01',
                dateTo:   '09-30',
                title:    'III',
            },
            {
                dateFrom: '10-01',
                dateTo:   '12-31',
                title:    'IV',
            }
        ];
    }

    save = async (state) => new Promise((next) => this.setState(state, next));

    handleClickOutside = async (e) => {
        if(!e.path){
            return //prevents errors in console
        }
        if (this.wrapperRef && !e.path.includes(this.wrapperRef)) {
            await this.save({ showDropdown: false });
        }
    }

    async toogleDropdown () {
        if (!this.state.showDropdown) {
            document.addEventListener('mousedown', this.handleClickOutside);
        } else {
            document.removeEventListener('mousedown', this.handleClickOutside);
        }

        await this.save({ showDropdown: !this.state.showDropdown });
    }

    async hadleFilterChange (type) {
        if (type === 'year') {
            this.yearHandler(new Date());
        } else if (type === 'exactMonth') {
            this.exactMonthHandler(new Date(this.props.dateFrom));
        } else if (type === 'allTime') {
            const dateFrom = moment('01.01.2015').format('YYYY-MM-DD');
            const dateTo = moment().format('YYYY-MM-DD');
            this.props.onChange({ dateFrom, dateTo });
        }else if (type === 'halfYear') {
            let dateTo = moment();
            const dateFrom = dateTo.clone().subtract(6, 'months').startOf('month').format('YYYY-MM-DD');
            dateTo = dateTo.format('YYYY-MM-DD');
            this.props.onChange({ dateFrom, dateTo });
        }

        await this.save({ selectedPeriod: type, showDropdown: false });
    }
    yearHandler = (e) => {
        let dateTo = moment(e).endOf('year').format('YYYY-MM-DD');
        const dateFrom = moment(e).startOf('year').format('YYYY-MM-DD');

        if (dateTo >= moment().add(-1, 'days').format('YYYY-MM-DD')) {
            dateTo = moment().add(-1, 'days').format('YYYY-MM-DD');
        }

        this.props.onChange({ dateFrom, dateTo });
    };

    exactMonthHandler = (e) => {
        let dateTo = moment(e).endOf('month').format('YYYY-MM-DD');
        const dateFrom = moment(e).startOf('month').format('YYYY-MM-DD');

        if (dateTo >= moment().add(-1, 'days').format('YYYY-MM-DD')) {
            dateTo = moment().add(-1, 'days').format('YYYY-MM-DD');
        }

        this.props.onChange({ dateFrom, dateTo });
    };

    quarterHandler = (e) => {
        if (e instanceof Date) {
            let dateToQuarter = this.props.dateTo.slice(5);
            let dateFromQuarter = this.props.dateFrom.slice(5);

            if (moment(e).startOf('year') !== moment().startOf('year')) {
                this.quarters.forEach((item) => {
                    if (item.dateFrom <= dateToQuarter && item.dateTo >= dateToQuarter) {
                        dateToQuarter = item.dateTo;
                        dateFromQuarter = item.dateFrom;
                    }
                });
            }

            let dateTo = `${moment(e).format('YYYY')}-${dateToQuarter}`;
            const dateFrom = `${moment(e).format('YYYY')}-${dateFromQuarter}`;

            if (dateTo >= moment().add(-1, 'days').format('YYYY-MM-DD')) {
                dateTo = moment().add(-1, 'days').format('YYYY-MM-DD');
            }

            this.props.onChange({ dateFrom, dateTo });
        } else {
            const { datefrom, dateto } = e.target.dataset;
            const dateFrom = `${this.props.dateFrom.slice(0, 5)}${datefrom}`;

            let dateTo = `${this.props.dateFrom.slice(0, 5)}${dateto}`;

            if (dateTo >= moment().add(-1, 'days').format('YYYY-MM-DD')) {
                dateTo = moment().add(-1, 'days').format('YYYY-MM-DD');
            }

            this.props.onChange({ dateFrom, dateTo });
        }
    };

    exactDateHandler = (type, e) => {
        this.props.onChange({ [type]: moment(e).format('YYYY-MM-DD') });
    };

    renderAdditionalFilters () {
        // Текущий месяц
        if (this.state.selectedPeriod === 'currentMonth') {
            const dateFrom = moment().add(-1, 'days').startOf('month').format('YYYY-MM-DD');
            const dateTo = moment().add(-1, 'days').format('YYYY-MM-DD');

            this.props.onChange({ dateFrom, dateTo });

            return '';
        }

        // Определенный месяц
        if (this.state.selectedPeriod === 'exactMonth') {
            return (
                <DatePicker
                    showMonthYearPicker
                    dateFormat = 'MM/yyyy'
                    maxDate = { moment().add(-1, 'days').endOf('month').toDate() }
                    popperPlacement = 'bottom-start'
                    selected = { new Date(this.props.dateFrom) }
                    onChange = { this.exactMonthHandler }
                />
            );
        }

        // Определенный год
        if (this.state.selectedPeriod === 'year') {
            return (
                <DatePicker
                    showYearPicker
                    dateFormat = 'MM/yyyy'
                    maxDate = { moment().add(-1, 'days').endOf('year').toDate() }
                    popperPlacement = 'bottom-start'
                    selected = { new Date(this.props.dateFrom) }
                    onChange = { this.yearHandler }
                />
            );
        }
        // half-year
        // if (this.state.selectedPeriod === 'halfYear') {
        //     return (
        //       <DatePicker
        //         dateFormat = 'yyyy'
        //         // maxDate = { moment().add(-1, 'days').endOf('year').toDate() }
        //         // popperPlacement = 'bottom-start'
        //         // selected = { new Date(this.props.dateFrom) }
        //         onChange = { this.halfYearHandler }
        //       />
        //     );
        // }

        // За определенный квартал
        if (this.state.selectedPeriod === 'quarter') {
            return (
                <>
                    <QuarterPicker
                        dateFrom = { this.props.dateFrom }
                        quarters = { this.quarters }
                        onChange = { this.quarterHandler }
                    />
                    <DatePicker
                        showYearPicker
                        dateFormat = 'yyyy'
                        maxDate = { moment().add(-1, 'days').endOf('year').toDate() }
                        popperPlacement = 'bottom-start'
                        selected = { new Date(this.props.dateFrom) }
                        onChange = { this.quarterHandler }
                    />
                </>
            );
        }

        // С даты по дату
        if (this.state.selectedPeriod === 'exactDate') {
            return (
                <>
                    <DatePicker
                        dateFormat = 'dd/MM/yyyy'
                        maxDate = { new Date(this.props.dateTo) }
                        popperPlacement = 'bottom-start'
                        selected = { new Date(this.props.dateFrom) }
                        onChange = { (e) => this.exactDateHandler('dateFrom', e) }
                    />
                    <DatePicker
                        dateFormat = 'dd/MM/yyyy'
                        maxDate = { moment().add(-1, 'days').toDate() }
                        minDate = { new Date(this.props.dateFrom) }
                        popperPlacement = 'bottom-start'
                        selected = { new Date(this.props.dateTo) }
                        onChange = { (e) => this.exactDateHandler('dateTo', e) }
                    />
                </>
            );
        }
    }

    render () {
        const { translate } = this.props;

        return (
            <>
                <div className = 'dashboard-actions'>
                    <div className = { `period-filter ${!this.state.showDropdown ? 'unactive': ''}` } ref = { (node) => this.wrapperRef = node }>
                        <div className = 'field' onClick = { () => this.toogleDropdown() }>
                            <span className = 'placeholder'>{ translate(`filter-${this.state.selectedPeriod}`) }</span>
                            <i className = { `arrow ${ this.state.showDropdown ? 'arrow_opened' : '' }`}>&#8249;</i>
                        </div>
                        <div className = 'dropdown'>
                            { this.props?.avaliableFilterTypes?.map((type, i) => {
                                return (
                                    <div className = 'item' key = { i } onClick = { () => this.hadleFilterChange(type) }>
                                        { translate(`filter-${type}`) }
                                    </div>
                                );
                            }) }
                        </div>
                    </div>
                </div>
                <div className = 'dashboard-actions period-filter-calendars'>
                    <div className = 'periodpickers'>
                        { this.renderAdditionalFilters() }
                    </div>
                </div>
            </>
        );
    }
}

export default withLocalize(PeriodFilter);
