import {
	itemsBuild, itemsDrain, itemsResort, CFG_ITEM_TYPE,
} from "../Config";
// import {} from '../Mentor/withDropDown';

export default {
	id: 175,
	...[
		// filters
		...[
			{
				f: "core.account.id",
				id: "f:accountId",
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: "filter", ..._, })),

		// orders
		...[
			{
				f: "core.account.id",
				id: "o:accountId",
			},
			{
				f: "partners.prev_accruals.partner_id",
				id: "o:paAccountNumberId",
			},
			{
				f: "partners.prev_accruals.partner_id",
				id: "o:paConditionId",
			},
			{
				f: "partners.new_partners_conditions.level",
				id: "o:paConditionLevel",
			},
			{
				f: "partners.new_partners_conditions.type",
				id: "o:paConditionType",
			},
			{
				f: "core.account.surname",
				id: "o:aSurname",
			},
			{
				f: "partners.accruals.partner_account",
				id: "o:paAccount",
			},
			{
				f: "partners.accruals.amount",
				id: "o:paAmount",
			},
			{
				f: "partners.accruals.acc_ccy",
				id: "o:paCurrency",
			},
			{
				f: "partners.accruals.status",
				id: "o:paStatus",
			},
			{
				f: "partners.accruals.created_at",
				id: "o:paCreated",
			},
			{
				f: "partners.accruals.updated_at",
				id: "o:paUdated",
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: "order", ..._, })),

		// panels
		...[
			{
				ii: "f:accountId",
				t: "partners_create_referral_partner_id",
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: "panel", ..._, })),
	]
		.map(itemsBuild)
		.reduce(itemsDrain, [])
		.reduce(itemsResort, {}),

	path: "partners/accruals",
	widget: false,
};