import React from "react";

import letter from "../../../../images/letter.svg";
import call from "../../../../images/call.svg";
import {Translate} from "react-localize-redux";
import defaultAvatar from "../../../../images/profile_default.jpg";
import CheckIsNull from "../../../../components/Common/CheckIsNull";
import { callService } from "../../../../services/CallDataService";

class ClientBlock extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            images: {},
            clientName: 'Client',
            fieldName:  'account_id',
        };
    }

    render () {
        return (
            <Translate>
                {({ translate }) => {
                    return (
                        <React.Fragment>
                                <div className = 'user-image'>
                                    <img alt = 'user' src = { defaultAvatar } />
                                </div>
                                <div className = 'user-info'>
                                    <div>{ translate(`tasks_client`)}</div>
                                    <div className = 'user-name active'>
                                        <CheckIsNull string={this.props.get_task_users['core-account-name']}/>
                                        {` `}
                                        <CheckIsNull string={this.props.get_task_users['core-account-surname']}/>
                                    </div>
                                    <div className = 'user-action'>
                                        <span onClick = { async () => callService.sendCall({ account_id: this.props.get_task_users['core-account-id'] }) }>
                                            <img alt = 'call' src = { call } style={{width: '10px'}} /> { translate(`tasks_createform_types_call`) }
                                        </span>
                                        <span>
                                            <img alt = 'letter' src = { letter } style={{width: '10px'}} /> { translate(`tasks_client_write`) }
                                        </span>
                                    </div>
                                </div>
                        </React.Fragment>
                    );
                }}
            </Translate>
        );
    }
}

export default ClientBlock;
