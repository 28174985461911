import React, { Component, } from 'react';
import { withLocalize, } from "react-localize-redux";
import { withRouter, } from "react-router";
import { enumService, } from "../../../../../services/EnumDataService";
import PermissionService from "../../../../../services/PermissionService";
import * as configBreadcrumbs from "./Config/BreadcrumbsConfig";
import Breadcrumbs from "../../../../tima/components/Breadcrumbs";
import CalculationPayoutDashboardMagicTable from "./Table/CalculationPayoutDashboardMagicTable";
import {HorizontalMenu} from "../../../components";
class CalculationPayoutDashboard extends Component {
	constructor (props) {
		super(props);

		this.state = {
			enums: {},
		};
	}

	async componentDidMount () {
		enumService.subscribe("enums", this.enumsChange, this);
		await enumService.enums;
	}

	UNSAFE_componentWillUnmount () {
		enumService.unsubscribe("enums", this.enumsChange, this);
	}

	toggleCreateUpdatePopup = async (popupData = {}) => {
		await this.save(state => ({
			isOpenCreatePopup: !state.isOpenCreatePopup,
			popupData,
		}));
	};

	save = state => new Promise(next => this.setState(state, next));

	enumsChange = async (enums) => {
		if (enums.hash === this.state?.enums?.hash) {
			return `${ this.constructor.name }.enumsChange: false`;
		}
		await this.save({ enums, });

		return `${ this.constructor.name }.enumsChange: true`;
	};

	getPermissions = (action) => {
		const pm = PermissionService.calc([ {
			path: [ 'partners', 'condition_calculation_state_reason', ],
			key: 'user',
		}, ]);
		return pm.user.access(action);
	}
	render () {
		const { enums, } = this.state;
		const { translate, } = this.props;
		const breadcrumbsItems = configBreadcrumbs.breadcrumbs4CalculationPayoutDashboard(translate);
		const permissionToShow = this.getPermissions('index');

		return (
			<div className="content-block">
				<div className="top-page-block">
					<div>
						<h1 className="page-title">{translate("menu_partners_system_dashboard_calculation_payout")}</h1>
						<Breadcrumbs items={ breadcrumbsItems } />
					</div>
				</div>
				<HorizontalMenu />
				{permissionToShow &&
					<CalculationPayoutDashboardMagicTable
						enums={ enums }
					/>}

			</div>
		);
	}
}

export default withLocalize(withRouter(CalculationPayoutDashboard));