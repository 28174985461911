import React from "react";
import PropTypes from 'prop-types';
import './styles.scss';
import PageStatus, { propTypesStatus, } from './PageStatus';
import PageList, { propTypesPageClick, propTypesPageId, propTypesPageText, propTypesPageTo } from './PageList';


export default class PagePanel extends React.Component {
    static defaultProps = {
        class4panel: `page__panel`,
        take: 1,
        variance: 2,
        page: 0,
        filter: 0,
        total: 0,
        showSingle: true,
    };

    static propTypes = {
        class4panel: PropTypes.string,
        onClick: propTypesPageClick,
        doPanel: propTypesStatus,
        doStatus: propTypesStatus,
        doTo: propTypesPageTo,
        doText: propTypesPageText,
        take: propTypesPageId( 1, false ),
        variance: propTypesPageId( 0, false ),
        page: propTypesPageId( 0, false ),
        filter: propTypesPageId( 0, false ),
        total: propTypesPageId( 0, false ),
        showSingle: PropTypes.bool,
    };

    render = () => {
        const props = this.props;
        const { class4panel, } = props;
        const { doPanel, } = props;
        const { filter, page, take, total, variance, showSingle, } = props;
        // items-props to valid values
        const itemsAmountFilter = Math.max( 0, filter );
        const itemsAmountTotal = Math.max( 0, total );
        const itemsPerPage = Math.max( 1, take );
        // pages-props to valid values
        const pageId = Math.max( 0, page );
        const pageVariance = Math.max( 0, variance );
        // items options calculating
        const itemsAmount = itemsAmountFilter;
        const itemsSkipped = pageId * itemsPerPage < itemsAmount ? pageId * itemsPerPage : undefined;
        const itemsTaken = itemsSkipped===undefined ? undefined : Math.min( itemsSkipped + itemsPerPage, itemsAmount ) - 1;
        // pages options calculating
        const pageAmount = Math.ceil( itemsAmount / itemsPerPage );
        const pageIdCurrent = pageId;
        const pageIdFirst = 0;
        const pageIdLast = Math.max( pageIdFirst, pageAmount - 1 );
        const pageIdList = Array( 2 * pageVariance + 1 ).fill(0)
          .map( ( _, index ) => index - pageVariance )
          .map( ( offset ) => pageId + offset )
          .filter( ( id ) => pageIdFirst <= id && id <= pageIdLast );
        const pageIdNext = Math.min( pageId + 1, pageIdLast );
        const pageIdPrev = Math.max( pageId - 1, pageIdFirst );
        // gathering calculated options
        const options = {
            status: {
                skipped: itemsSkipped,
                taken: itemsTaken,
                filter: itemsAmountFilter,
                total: itemsAmountTotal,
            },
            list: {
                current: pageIdCurrent,
                first: pageIdFirst,
                last: pageIdLast,
                next: pageIdNext,
                prev: pageIdPrev,
                list: pageIdList,
            }
        };
        if ( doPanel ) {
            return doPanel( { props, options, } );
        } else if (total > take) {
            return (
                <span className={ class4panel } >
                    <PageStatus { ...props } options={ options.status } />
                    <PageList { ...props } options={ options.list } />
                </span>
            );
        } else if ( showSingle ) {
            return (
                <span className={ class4panel } >
                    <PageStatus { ...props } options={ options.status } />
                    <PageList { ...props } options={ options.list } disabled={ true } />
                </span>
            );
        } else {
            return '';
        }
    };
};
