import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import { MagicSelect } from '../../../../../components/Magic';
import { uploadDocuments } from '../../../services/AccountRequestService';
import { clientService, } from '../../../../../services/ClientDataService';
import '../../../../../style/_account_document-upload.scss';

class UploadDocument extends Component {
    constructor (props) {
        super(props);

        this.state = {
            fileValue: '',
            ...this._getInitialOptions('type'),
            ...this._getInitialOptions('subType'),
            availableSubTypeOptions: {},
            requestValue: '',
            errorRequestForApproveAccId: false,
        };
    }

    async componentDidMount() {
        await this.getAccountInfoForSourceApprove();
    }

    save = state => new Promise(next => this.setState(state, next));

    _getInitialOptions = (optionName) => {
        switch (optionName) {
            case 'type': {
                return {
                    typeValue: '',
                    dropdownTypeOpen: false,
                    // dropdownTypeLabel: translate(`client_dropdown_placeholder`),
                };
            }
            case 'subType': {
                return {
                    subTypeValue: '',
                    dropdownSubTypeOpen: false,
                    // dropdownSubTypeLabel: translate(`client_dropdown_placeholder`),
                };
            }
            default: {
                return {};
            }
        }
    };

    filterSubTypeOptions = (docType) => {
        const allOptionsObj = this.props.dataAfterLoading.AccountDocumentSubTypeEnum;
        let targetListKeys = [];
        //TODO: the map or logic restructurization for CORPORATE docTypes
        switch (docType) {
            case 'PASSPORT': {
                targetListKeys = ['FRONT_SIDE', 'BACK_SIDE', 'OTHER'];
                break;
            }
            case 'ID_CARD': {
                targetListKeys = ['FRONT_SIDE', 'BACK_SIDE'];
                break;
            }
            case 'SELFIE': {
                targetListKeys = ['FRONT_SIDE'];
                break;
            }
            case 'INTERNATIONAL_PASSPORT': {
                targetListKeys = ['FRONT_SIDE'];
                break;
            }
            case 'BANK_STATEMENT': {
                targetListKeys = ['FRONT_SIDE', 'OTHER'];
                break;
            }
            case 'UTILITY_BILL': {
                targetListKeys = ['FRONT_SIDE', 'OTHER'];
                break;
            }
            case 'ISLAMIC_ACCOUNT_STATEMENT': {
                targetListKeys = ['FRONT_SIDE', 'OTHER'];
                break;
            }
            case 'CLIENT_AGREEMENT': {
                targetListKeys = ['FRONT_SIDE',];
                break;
            }
            case 'BANK_ACCOUNT_STATEMENT': {
                targetListKeys = ['OTHER'];
                break;
            }
            case 'CONTRACT_OF_SALE': {
                targetListKeys = ['OTHER'];
                break;
            }
            default: {
                break;
            }
        }

        return Object.fromEntries(
          Object.entries(allOptionsObj).filter(([key, value]) => {
              return targetListKeys.includes(key);
          })
        );
    };

    toggleDropdownTypeOpen = () => {
        this.setState((state) => ({
            dropdownTypeOpen: !state.dropdownTypeOpen,
        }));
    };

    toggleDropdownSubTypeOpen = () => {
        this.setState((state) => ({
            dropdownSubTypeOpen: !state.dropdownSubTypeOpen,
        }));
    };

    getAccountInfoForSourceApprove = async () => {
        const account = await clientService.accountChangeHistoryForSourceApprove(this.props?.accountId)
            .catch(async (error) => error && await this.save({ errorRequestForApproveAccId: true, }))
        const accountStatus = account?.status;
        const STATUS_IN_PROCESS = [1].includes(accountStatus);
        const STATUS_WAIT_CONFIRMATION = [2].includes(accountStatus);

        if (STATUS_IN_PROCESS || STATUS_WAIT_CONFIRMATION) await this.save({ approvedAccountId: account.id, });
    };

    uploadDocument = async (event) => {
        const TypeBankAccount = this.state?.typeValue === 'BANK_ACCOUNT_STATEMENT';
        const TypeContractOfSale = this.state?.typeValue === 'CONTRACT_OF_SALE';
        const formData = new FormData();
        formData.append('file', event?.target?.files?.[0]);
        formData.append('account_id', this.props?.accountId);
        formData.append('type', this.state?.typeValue);
        formData.append('sub_type', this.state?.subTypeValue);
        ((TypeBankAccount || TypeContractOfSale)
            ? formData.append('account_change_history_id', this.state?.requestValue)
            : null)
        try {
            await uploadDocuments(formData, this.props?.accountId);
            await this.props.getAccountDocuments();
            await this.props.hideUploadDocument();
            await this.props.refreshClientData();
            // await this.props.getClient(this.props.accountId);
        } catch (error) {
            error.showErrorNotification?.();
        }
    };

    closeForm = () => {
        this.props.hideUploadDocument();
    };

    renderDocumentTypeSelect = () => {
        const TypeBankAccount = this.state?.typeValue === 'BANK_ACCOUNT_STATEMENT';
        const TypeContractOfSale = this.state?.typeValue === 'CONTRACT_OF_SALE';
        const registerType = this.props?.registerType ?? "";
        const allTypesIndividual = this.props?.dataAfterLoading?.AccountDocumentTypeEnum ?? {};
        const allTypesCorporate = this.props?.dataAfterLoading?.AccountCorporateDocumentTypeEnum ?? {};
        const allTypes = registerType?.toString() === '2' ? allTypesCorporate : allTypesIndividual;
        const values = Object.keys(allTypes);
        const valueDefault = '';
        const options = {
            className: 'mw',
            values: [valueDefault, ...values],
            value: this.state?.typeValue,
            ItemProps: value => [valueDefault].includes(value) ? {disabled: true} : {},
            onChange: async (value) => {
                const filterSubTypeOptions = this.filterSubTypeOptions(value);
                await this.save({
                    typeValue: value,
                    availableSubTypeOptions: filterSubTypeOptions,
                    ...this._getInitialOptions('subType'),
                    ...((!TypeBankAccount || !TypeContractOfSale) && { requestValue: '', })
                });
            },
            valueToLabel: value => {
                switch (value) {
                    case valueDefault:
                        return this.props?.translate(`client_dropdown_placeholder`);
                    default:
                        return allTypes?.[value];
                }
            },
        }

        return (
            <div
                className = 'upload-document-type-select'
                size = 'sm'
                isOpen = { this.state?.dropdownTypeOpen }
                toggle = { this.toggleDropdownTypeOpen }
            >
                <MagicSelect {...options} />
            </div>
        )
    };

    renderDocumentSubTypeSelect = () => {
        const allSubTypes = this.state?.availableSubTypeOptions ?? {};
        const valueDefault = '';
        const options = {
            values: [valueDefault, ...Object.keys(allSubTypes)],
            value: this.state?.subTypeValue,
            ItemProps: value => [valueDefault].includes(value) ? { disabled: true, } : {},
            onChange: async (value) => await this.save({ subTypeValue: value, }),
            valueToLabel: value => {
                switch (value) {
                    case valueDefault:
                        return this.props?.translate(`client_dropdown_placeholder`);
                    default:
                        return allSubTypes?.[value];
                }
            },
        };
        return (
            <div
                className = 'upload-document-type-select'
                size = 'sm'
                isOpen = { this.state.dropdownSubTypeOpen }
                toggle = { this.toggleDropdownSubTypeOpen}
            >
                <MagicSelect {...options} />
            </div>
        );
    };

    renderUploadBtn = () => {
        return (
            <div className = 'upload-image'>
                <label className = 'button-upload-wrapper'>
                    <input
                        onChange = {this.uploadDocument}
                        type = 'file'
                        value={this.state?.fileValue}
                    />
                    <div className = 'button-upload-image'>{this.props.translate('client_upload_data')}</div>
                </label>
            </div>
        )
    };

    renderRequestSelect = () => {
        const { approvedAccountId, } = this.state;
        const { translate, } = this.props;

        const options = {
            valueDefault: '',
            values: [[approvedAccountId] ?? []],
            value: this.state?.requestValue,
            onChange: async (value) => await this.save({ requestValue: value, }),
            valueToLabel: value => {
                if (value === '') {
                    return translate(`client_dropdown_placeholder`);
                } else {
                    return approvedAccountId;
                }
            }
        }

        return (
          <div
            className = 'upload-document-type-select'
            size = 'sm'
            isOpen = { this.state.dropdownSubTypeOpen }
            toggle = { this.toggleDropdownSubTypeOpen}
          >
              <div className="account_document-upload-request-title">
                  { this.props.translate('upload_document_request_title') }
              </div>
              <MagicSelect { ...options } />
          </div>
        );
    };

    render () {
        const TypeBankAccount = this.state?.typeValue === 'BANK_ACCOUNT_STATEMENT';
        const TypeContractOfSale = this.state?.typeValue === 'CONTRACT_OF_SALE';

        return (
            <div className='account_document-upload'>
                <button onClick={this.closeForm} className="close">×</button>
                <div>
                    <div>
                        {this.renderDocumentTypeSelect()}
                        {this.renderDocumentSubTypeSelect()}
                        { (TypeBankAccount || TypeContractOfSale)
                            ? this.renderRequestSelect()
                            : null
                        }
                    </div>
                    {this.renderUploadBtn()}

                </div>
            </div>
        );
    }
}

export default withLocalize(withRouter(UploadDocument));
