import React, { Component, } from 'react';
import { withLocalize, } from "react-localize-redux";
import PeriodFilter from "../../../dashboard/components/PeriodFilter/PeriodFilter";
import UserFilter from "../../../dashboard/components/UserFilter/UserFilter";
import { charFour, charThree, chartOne, charTwo } from './consts/consts'

class UserActivityChats extends Component {
	constructor (props) {
		super(props)
	this.state ={
			coefForHeight: null
	}

	}


	save = async state => new Promise(next => this.setState(state, next));

    handlePeriodChange = async ({ dateFrom, dateTo, }) => {
    	const { setDateFromFilter, } = this.props;

    	if (this.props.dateFrom !== dateFrom || this.props.dateTo !== dateTo) {
    		setDateFromFilter(dateFrom, dateTo);
    	}
    }

	// eslint-disable-next-line require-await
    handleUserChange = async (user) => {
		// eslint-disable-next-line no-mixed-spaces-and-tabs
    	const { setUser, } = this.props;

    	setUser(user);
    }
		sortValueOfArray =  (value) => {
			const maxNumber = Math.max(...value.map(obj => obj.c));
switch (true) {
	case maxNumber <= 50: {
		this.save({ coefForHeight: 0.30 })
		return chartOne.map((n,i) => <li key={i}>{n}</li>).reverse()
	}
	case  maxNumber <= 100 : {
		this.save({ coefForHeight: 0.15 })
		return charTwo.map((n,i) => <li key={i}>{n}</li>).reverse()
	}
	case maxNumber <= 1000 : {
		this.save({ coefForHeight: 0.015})
		return charThree.map((n,i) => <li key={i}>{n}</li>).reverse()
	}
	case maxNumber > 1000 : {
		this.save({ coefForHeight: 0.0013})
		return charFour.map((n,i) => <li key={i}>{n}</li>).reverse()
	}
	default:
		return chartOne.map((n,i) => <li key={i}>{n}</li>).reverse()
}
		}
	renderFilters = () => {
		const {
			dateFrom, dateTo, selectedUser, users,
		} = this.props;
const filterUsers = users.filter(users => users.id !== "null");
		return (
			<>
				<PeriodFilter
					avaliableFilterTypes={ [ 'currentMonth', 'exactDate',  'exactMonth', ] }
					dateFrom={ dateFrom }
					dateTo={ dateTo }
					onChange={ this.handlePeriodChange }
				/>
				<UserFilter
					avaliableFilterTypes={ filterUsers }
					selectedUser={ selectedUser }
					onChange={ this.handleUserChange }
				/></>
		);
	}

	renderCharts = () => {
		const { data, } = this.props;
		const { coefForHeight } = this.state;
		// const data = [];
		//
		// for (let i = 1; i <= 20; i++) {
		// 	data.push({ c: i * 22 , d: `2023/11/05` });
		// }

		return (
			<>
				<div>
					<ul className="activity_report_chart_count">
						{this.sortValueOfArray(data)}
					</ul>
				</div>

				<div className="activity_report_charts_block" >
					{data?.sort((a, b) => new Date(a.d) - new Date(b.d)).map(({
						c: count, d: date,
					}, index) => {

						const dateToUtc = date.slice(0, 10)
						const formattedDateDay = dateToUtc.slice(8);
						const formattedDateMonth = dateToUtc.slice(5, 7);
						const dateF = `${ formattedDateDay }/${ formattedDateMonth }`;
						const heightOfBlocks = count * coefForHeight;
						const hoursPerDay = date.slice(-5);


						return (
							<div className="activity_report_date" key={ index } >{ data.length === 24 ? hoursPerDay : dateF}
								<span className="activity_report_date_chart" style={ { "height": `${ heightOfBlocks }rem`, } } ><span className="activity_report_date_chart__numbers">{count}</span></span>
							</div>

						);
					})}

				</div>
			</>
		);
	}

	render () {

		// eslint-disable-next-line no-mixed-spaces-and-tabs
    	return (

	<>
				<div className="user_activity_clients_title" >
					<h3 >{this.props.translate('client_clients')}</h3>
					<div style={ { width: "25%", } }>
			{this.renderFilters()}
					</div>
		</div>
				<div >
			{this.renderCharts()}
		</div>
			</>
			// eslint-disable-next-line no-mixed-spaces-and-tabs
    	);
	}
}

export default withLocalize(UserActivityChats);