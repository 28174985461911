import React, { Component, } from "react";
import { withRouter, } from "react-router-dom";
import { withLocalize, } from "react-localize-redux";

import channelsConfig from "./helpers/messages.json";
import ScrollChannels from "./components/ScrollChannels/ScrollChannels";
import ConversationWindow from "./components/ConversationWindow/ConversationWindow";
import {
	getAllMessages, getMessagesByAccounttId,
	getMessagesById,
} from "./services/ConversationsRequestService";
import ObservableService from "../../services/ObservableService";
import { profileService, } from "../../services/ProfileDataService";
import Preloader from "../../components/LoadingHOC/Preloader";
import Loader from "../tima/components/Loader";

class Conversations extends Component {
	constructor (props) {
		super(props);
		this.state = {
			conversationData: [],
			conversationId: this.extractConversationIdFromURL(props),
			loaded: true,
			messagesData: null,
			page: 1,
			totalData: '',
		};
		ObservableService.subscribe('updateConversations', this.handle);

	}
	 extractConversationIdFromURL = (props) => {
	 	const urlData = props?.location?.pathname.split("/conversations/");

	 	return urlData.length > 1 ? urlData.pop() : '';

	 };

	save = async state => new Promise(next => this.setState(state, next));
	componentDidMount = async () => {

		const { data, } =  await profileService.profile;

		await this.save({ managerProfile: data, });
		await this.handle();
	}
	handleScroll = (event) => {
		const { scrollHeight, scrollTop, clientHeight, } = event.target;
		const { messagesData, totalData, } = this.state;
		const dataCount = totalData - messagesData.length;

		if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1 && dataCount > 0) {
			this.fetchData();
		}

	}
	 fetchData = async () => {
	 	const { messagesData, } = this.state;

	 	try {
	 		const response = await getAllMessages(50, messagesData.length);
	 		const newData = await response.data;

	 		await this.save({
	 			messagesData: [ ...messagesData, ...newData, ],
	 			loaded: true,
	 		});
	 	} catch (e) {
	 		console.log(e);
	 	}
	 }

	handle = async () => {
		const { conversationId, } = this.state;
		const { accountId, } = this.props;

		await this.save({ loaded: false, });
		try {
			if (!accountId) {
				const { data, meta, } = await getAllMessages(50, 0);

				await this.save(
					{ loaded: true, messagesData: data, totalData: meta.total, },
				);
			}
			if (accountId) {
				const dataFromClientsTab = await getMessagesByAccounttId(accountId);

				await this.save(
					{ loaded: true, messagesData: dataFromClientsTab.data, },
				);
			}
			if (conversationId !== '') {
				const conversationData = await getMessagesById(conversationId);

				await this.save(
					{ conversationData: conversationData.data, loaded: true, },
				);
			}

		} catch (e) {
			await this.save({ loaded: true, });
		} finally {
			await this.save({ loaded: true, });
		}

	}
	async componentDidUpdate (prevProps, prevState) {
		const { conversationId, } = this.state;
		const tState = conversationId;
		const pState = prevState.conversationId;

		if (tState !== pState) {
			const conversationData = await getMessagesById(this.state.conversationId);

			await this.save((state) => {
				return {
					...state,
					conversationData: conversationData.data,
				};
			});
		}
	}
	componentWillUnmount () {
		ObservableService.unsubscribe('updateConversations', this.handle);
		window.removeEventListener('scroll', this.handleScroll);
	}

	get translate () {
		return this.props.translate;
	}

	render () {
		const translate = this.translate;
		const {
			messagesData, conversationData, conversationId, managerProfile,
		} = this.state;
		const { accountId, } = this.props;


		return (
			<div className="content-block">
				<div className="top-page-block">
					<div>
						<h1 className={ "page-title" }>{translate(`menu_conversations`)}</h1>
					</div>
				</div>
				<div className="conversations_wrapper" >
					<Loader
						loaded={ this.state.loaded }
						loading={ (<Preloader scale={ this.props.scale } />) }
						translate={ this.props.translate }
					>
						<ScrollChannels
							channelsConfig={ channelsConfig }
							conversationData={ conversationData }
							messagesData={ messagesData }
							setConversationId={ async (id) => {
								await this.save({
									conversationId: id,
								});
							} }
							onScroll={ this.handleScroll }
						/>
					</Loader>
					{!conversationData.length ? '': (
						<ConversationWindow
							accountId={ accountId }
							conversationData={ conversationData }
							conversationId={ conversationId }
							managerProfile={ managerProfile }
							updateConversation={ this.handle }
						/>
					) }

				</div>

			</div>
		);
	}
}

export default withRouter(withLocalize(Conversations));