import React, { Component, } from "react";
import { withRouter, } from "react-router-dom";
import { withLocalize, } from "react-localize-redux";

import {
	MagicTable, Mentor, MagicFilterPanels, MagicButton,
} from "../../../../../../components/Magic";

import { getLogos, getSingleLogo, } from "../../../../../../services/PartnersRequestService";
import { enumService, } from "../../../../../../services/EnumDataService";

import PagePanel from "../../../../../tima/components/Pages";
import Preloader from "../../../../../../components/LoadingHOC/Preloader";
import { showExportToExcelError, } from "../../../../../../components/Magic";
import ImagePopup from "../../components/ImagePopup";
import CreateUpdateLogo from "../popup/CreateUpdateLogo";
class LogosTable extends Component {
	constructor (props) {
		super(props);

		this.state = {
			data: [],
			languages: null,
			filterLastChange: Date.now(),
			isEmptyData: false,
			loaded: false,
			meta: {},
			pages: {
				filter: 0,
				take: 50,
				total: 0,
				variance: 2,
			},
		};

		const mf = new Mentor({
			owner: this,
			serviceId: 167,
			translate: this.props.translate,
		});

		Object.defineProperty(this, "mf", { get: () => mf, });
	}

	async componentDidMount () {
		enumService.subscribe('enums', this.enumsChange, this);
		await enumService.enums;

		this.mf.subscribe(
			{
				changed: this.mfChanged,
				cleaned: this.mfDone,
				cleaning: this.mfDoing,
				loaded: this.mfDone,
				loading: this.mfDoing,
				saved: this.mfDone,
				saving: this.mfDoing,
			},
			this,
		);

		await this.mf.init({ doEmit: true, });
	}

	componentWillUnmount () {
		enumService.unsubscribe('enums', this.enumsChange, this);
	}
    save = state => new Promise(next => this.setState(state, next));

    onChange = (name, value) => this.save({ [name]: value, });

    params = () => this.props.match.params;

    mfChanged = async () => {
    	const { handleFilterIdChange, } = this.props;
    	const { filterId, } = this.mf;

    	await handleFilterIdChange(filterId);
    	await this.save({ filterLastChange: Date.now(), });
    };

    mfDoing = async () => {
    	await this.save({ loaded: false, });
    };

    mfDone = async () => {
    	await this.mfChanged();
    	await this.onDataLoad();
    };

    enumsChange = async (enums) => {
    	if (enums.hash === this.state?.enums?.hash) {
    		return `${ this.constructor.name }.enumsChange: false`;
    	}
    	await this.save({ enums, });

    	return `${ this.constructor.name }.enumsChange: true`;
    };
    query = () => new URLSearchParams(this.props.location.search);

    onDataLoad = async (pageId) => {
    	await this.save({ isEmptyData: false, loaded: false, });

    	const pageOptions = this.pageIdAsGET(pageId);
    	const loaded = (data, meta) => ({ pages, }) => ({
    		data,
    		pages: { ...pages, ...meta, },
    		...!data.length && { isEmptyData: true, },
    	});

    	try {
    		const response = await getLogos(pageOptions);
    		const {
    			data = [],
    			meta: { filter = 0, total = 0, },
    		} = response;

    		await this.save(loaded(data, { filter, total, }));
    	} catch (error) {
    		await this.save(loaded([], { filter: 0, total: 0, }));
    		showExportToExcelError(error);
    	}
    	await this.save({ loaded: true, });
    };
    get config () {
    	const { translate, } = this.props;
    	const prefix = "partner_logos_";

    	return [
    		{
    			key: "logoImageURL",
    			path: [ "partners", "logos", "source", ],
    		},
    		{
    			key: "logosId",
    			orderId: "o:logosId",
    			path: [ "partners", "logos", "id", ],
    			render: this.renderInlineValue,
    			title: `${ prefix }id`,
    		},
    		{
    			key: "logosName",
    			orderId: "o:logosName",
    			path: [ "partners", "logos", "name", ],
    			render: this.renderName,
    			title: `${ prefix }name`,
    		},
    		{
    			key: "logosSize",
    			orderId: "o:logosSize",
    			path: [ "partners", "logos", "size", ],
    			render: this.renderInlineValue,
    			title: `${ prefix }size`,
    		},
    		{
    			key: "logosStatus",
    			orderId: "o:logosStatus",
    			path: [ "partners", "logos", "status", ],
    			render: this.renderStatus,
    			title: `${ prefix }status`,
    		},
    		{
    			key: "logosApplicationDate",
    			orderId: "o:logosApplicationDate",
    			path: [ "partners", "logos", "created_at", ],
    			render: this.renderDate,
    			title: `${ prefix }application_date`,
    		},
    		{
    			customAccessCheck: ({ item, }) => {
    				return (
    					item.access("show") &&
						(item.access("update") || item.access("destroy"))
    				);
    			},
    			key: "logosActions",
    			order: false,
    			path: [ "partners", "logos", "id", ],
    			render: this.renderActions,
    			title: `${ prefix }action`,
    		},
    	].map(({
    		orderId: o, title: t, xtitle: x, ...item
    	}) => ({
    		...item,
    		...o && {
    			order: async () => {
    				await this.mf.orderedToNext(o);
    			},
    			orderTo: this.mf.orderedTo(o),
    		},
    		...t && { title: translate(t), },
    		...x && { title: x, },
    	}));
    }

    renderInlineValue = (value, { item, items, }) => {
    	if (item.access("index")) {
    		return value ?? "-";
    	}

    	return "-";
    };

	renderName= (value, { item, items, }) => {
		if (item.access("index")) {
			return (
				<div
					className={ 'cursor-pointer' }
					onClick={ async () => {
						const { toggleCreateUpdatePopup, } = this.props;
						const id = items.logosId.valueOf;
						const popupData = await this.getLogoFormData(id);

						popupData.source = `${ popupData.source }?${ this.createRandomQueryString() }`;
						popupData.isViewMode = true;

						await toggleCreateUpdatePopup(true, popupData);
					} }
				>
					{value.trim() || "-"}
				</div>
			);
		}

		return "-";
	};
    renderDate = (value, { item, items, }) => {
    	if (item.access("index")) {
            //date and time on separate lines
    		return value.split(' ').map((date, index) => <div key={ `date-${ items.logosId.valueOf }-${ index }` }>{date}</div>);
    	}

    	return "-";
    };

	renderStatus = (value, { item, }) => {
		if (item.access("index")) {
			const { enums, } = this.state;

			return enums.data.partners.LogoStatusEnum[value];
		}

		return "-";
	};

	//makes sure img from link is not cached
	createRandomQueryString = () => (Math.random() + 1).toString(36).substring(7);
	renderActions = (value, { item, items, }) => {
		const { translate, toggleCreateUpdatePopup, } = this.props;

		const renderViewButton = () => {
			const options = {
				children: translate("partners_requests_partner_view"),
				className:
					"magic-button__item magic-button__item_partners-edit-banner margin--top-10",
				key: `view-${ value }`,
				onClick: async () => {
					const popupData = await this.getLogoFormData(value);

					popupData.source = `${ popupData.source }?${ this.createRandomQueryString() }`;
					popupData.isViewMode = true;

					await toggleCreateUpdatePopup(true, popupData);
				},
			};

			return (
				<div className="mb-2" >
					<MagicButton { ...options } />
				</div>
			);
		};

		const renderEditButton = () => {
			const options = {
				children: translate("symbols_actions_edit"),
				className: "magic-button__item magic-button__item_partners-view-banner",
				key: `edit-${ value }`,
				onClick: async () => {
					const popupData = await this.getLogoFormData(value);

					popupData.source = `${ popupData.source }?${ this.createRandomQueryString() }`;
					await toggleCreateUpdatePopup(true, popupData);
				},
			};

			return (
				<div>
					<MagicButton { ...options } />
				</div>
			);
		};

		return (
			<>
				{item.access("destroy") && renderViewButton()}
				{item.access("update") && renderEditButton()}
			</>
		);
	};

	renderPartnersListMagicTableTable () {
    	const { translate, } = this.props;
    	const { loaded, data, } = this.state;

    	const head = [
			'logosId',
			'logosName',
			'logosSize',
			'logosStatus',
			'logosApplicationDate',
			'logosActions',
    	];

    	if (!loaded) {
    		return <Preloader />;
    	}
    	if (data.length === 0) {
    		return <h4 className="mx-auto text-align-center pt-5">{translate("symbols_empty")}</h4>;
    	}

    	const options = {
    		config: this.config,
    		data,
    		head,
    	};

    	return (
			//eslint-disable-next-line
			<>
				<MagicTable { ...options } />
			</>
    	);
	}

	getLogoFormData = async (bannerId) => {
		try {
			const response = await getSingleLogo(bannerId);
			const {
				id, name, source, status, updated_at,
			} = response.data.partners.logos;

			return {
				id, name, source, status, updated_at,
			};

		} catch (error) {
			console.log(error);
			error.showErrorNotification?.();
		}
	}

	render () {
    	const { pages, enums, } = this.state;
    	const {
			translate, getPermissions, createUpdatePopup, toggleCreateUpdatePopup,
		} = this.props;

    	const showPagePannel = pages.total > pages.take;

    	const permissionToShow = getPermissions('index');

    	return (
			//eslint-disable-next-line
			<>
				{ createUpdatePopup.isOpen &&
					<CreateUpdateLogo
						enums={ enums }
						formData={ createUpdatePopup.data }
						translate={ translate }
						onClose={ () => toggleCreateUpdatePopup(false, null) }
						onDataLoad={ this.onDataLoad }
					/>
				}
				{ permissionToShow &&
					<div>
						<MagicFilterPanels
							mf={ this.mf }
							show={ true }
							translate={ translate }
						/>
						{this.renderPartnersListMagicTableTable()}
						{
							showPagePannel && <PagePanel
								doTo={ pageId => `?page=${ pageId }` }
								filter={ pages.filter }
								page={ this.pageId() }
								take={ pages.take }
								total={ pages.total }
								variance={ pages.variance }
								onClick={ this.onPageClick }
							/>
						}
					</div>
				}
			</>
		);
	}

    pageId = () => {
    	const page = this.query().get("page");

    	return Number(page) || 0;
    };

    pageIdAsGET = (pageId) => {
    	const pages = this.state.pages;

        // eslint-disable-next-line no-param-reassign
    	pageId = pageId === undefined ? this.pageId() : pageId;
    	const { filterId, } = this.mf;
    	const result = {
    		filterId,
    		skip: pageId * pages.take,
    		take: pages.take,
    	};

    	return result;
    };
    // eslint-disable-next-line no-unused-vars
    onPageClick = async ({ event, pageId, pageIs, }) => {
    	await this.onDataLoad(pageId);
    };
}

export default withRouter(withLocalize(LogosTable));