import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import { MagicTable } from '../../../../components/Magic';
import { MagicTooltip } from '../../../tima/components/Magic/MagicTooltip';
import ModalUtmCreateAndUpdate from '../Modal/ModalUtmCreateAndUpdate';
import { marketingService } from '../../../../services/MarketingDataService';

class MarketingChannelsMagicTable extends Component {

  constructor (props) {
    super(props);

    this.state = {
      isModalShown: false,
      data: {
        id: null,
        name: '-',
        number: '-',
        description: '-',
        status: '-',
      }
    }
  };

  static propTypes = {
    data: PropTypes.array,
    enums: PropTypes.object,
  };

  static defaultProps = {
    data: [],
    enums: {},
  };

  save = async state => new Promise(next => this.setState(state, next));

  get channelsTableConfig () {
    const { translate } = this.props;
    const prefix = 'statistics_utm_source_';

    return [
      {
        path: [ 'statistics', 'utm_source', 'name' ],
        key: 'utmSource',
        render: this.renderInitialValue,
        title: `${prefix}name`,
      },
      {
        path: [ 'statistics', 'utm_source', 'number' ],
        key: 'utmSourceNumber',
        render: this.renderInitialValue,
        title: `${prefix}number`,
      },
      {
        path: [ 'statistics', 'utm_source', 'description' ],
        key: 'utmSourceDescription',
        render: this.renderDescription,
        title: `${prefix}description`,
      },
      {
        path: [ 'statistics', 'utm_source', 'status' ],
        key: 'utmSourceStatus',
        render: this.renderChannelStatus,
        title: `${prefix}status`,
      },
      {
        path: [ 'statistics', 'utm_source', 'id' ],
        key: 'utmSourceId',
        render: this.renderChannelId,
        title: `${prefix}edit`,
      },
    ].map(({ title: t, xtitle: x, ...item }) =>
      ({ ...item, ...x && { title: x },
        ...t && { title: translate(t) },
      }));
  };

  renderChannelId = (value, { item, items }) => {
    const { translate } = this.props;
    if (item.access?.('update') && value) {
      const url = `/marketing/advertising_channels/channels/${items.utmSourceId.valueOf}`
      return (
        <Link to={`${url}`}>
          <button
            onClick={(e) => {
              e.preventDefault()
              this.save({
                isModalShown: !this.state.isModalShown,
                data: {
                  name: items.utmSource.valueOf,
                  number: items.utmSourceNumber.valueOf,
                  description: items.utmSourceDescription.valueOf,
                  status: items.utmSourceStatus.valueOf,
                  id: items.utmSourceId.valueOf,
                }
              })
            }}
            className='marketing-buttons_table-btn'>
            {translate(`marketing_edit_channel`)}
          </button>
        </Link>
      );
    }
    return '-';
  };

  renderDescription = (value, { item }) => {
    if (item?.access?.('index')) {
      const LENGTH_SHORT = 30;
      return (
        <MagicTooltip
          classNameTooltip={`tooltip tooltip--large-width`}
          content={ value ?? '-' }
          lengthCut={ LENGTH_SHORT }
        />
      );
    }
    return '-';
  };

  renderInitialValue = (value, { item }) => {
    if (item?.access?.('index')) {
      const LENGTH_SHORT = 30;
      return (
        <MagicTooltip
          classNameTooltip={`tooltip tooltip--large-width`}
          content={ value ?? '-' }
          lengthCut={ LENGTH_SHORT }
        />
      );
    }
    return '-';
  };

  renderChannelStatus = (status, { item, }) => {
    if (item.access('index')) {
      const curObj = status === 1 ? 'active activeStatus' : status === 2 ? 'inactive' : '' ;
      return <div className={`status-active ${curObj}`}>
        {this.props?.enums?.statistics?.UtmSourceStatusEnum?.[+status] ?? "-"}
      </div>;
    }
    return '-';
  };

  renderLink = (path, text, className = "") => (<Link to={path} className={className}>{text}</Link>);

  updateUtmSource = async ({ description, ...data }) => {
    try {
      const dataNew = {
        ...data,
        ...description === ''
          ? { SET_NULL__description: "1", }
          : description
            ? { description, }
            : {},
      };
      await marketingService.updateUtmSource(this.state.data.id, dataNew);
      await this.props.onDataLoad()
    } catch (e) {
      console.log(e)
    }
  };

  render () {
    if (!this.props?.data?.length) {
      return null;
    }

    const options = {
      config: this.channelsTableConfig,
      data: this.props?.data,
      head:   [
        'utmSource',
        'utmSourceId',
        'utmSourceNumber',
        'utmSourceStatus',
        'utmSourceDescription',
      ],
    };

    return (
      <>
       <MagicTable {...options} />
       {this.state.isModalShown === true ? (
         <ModalUtmCreateAndUpdate
           data={this.state.data}
           enums={this.props.enums}
           viewOnly={['name', 'number']}
           titleKey={{
             form: `marketing_edit_current_channel`,
             name: `statistics_utm_source_name`,
             number: `statistics_utm_source_number`,
             description: `marketing_edit_description`,
             status: `statistics_utm_source_status`,
           }}
           onSave={this.updateUtmSource}
           onClose={async () => { await this.save({ isModalShown: false }) }}
         />
       ) : null}
      </>
    )
  };
}

export default withRouter(withLocalize(MarketingChannelsMagicTable));
