import {
	itemsBuild, itemsDrain, itemsResort, CFG_ITEM_TYPE,
} from "../Config";
import { onEnabled, render, } from "../Mentor/withDropDown";

const f_lang = {
	"drop-down": "/api/core/mail-templates/languages",
	"o=": [ "=", "!=", "null", "not_null", ],
};

const p_lang = {
	onEnabled: onEnabled("languagesMail"),
	render: render("languagesMail"),
};

export default {
	id: 1,
	...[
		// filters
		...[
			{
				f: "core.mail_templates.active",
				id: "f:templatesStatus",
			},
			{
				f: "core.mail_template_translation.language_id",
				id: "f:templateLanguage",
				...f_lang,
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: "filter", ..._, })),

		// orders
		...[
			{
				f: "core.mail_templates.created_at",
				id: "o:templatesCreated",
			},
			{
				f: "core.mail_templates.name",
				id: "o:templatesName",
			},
			{
				f: "core.mail_templates.description",
				id: "o:templatesDescription",
			},
			{
				f: "core.mail_templates.active",
				id: "o:templatesStatus",
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: "order", ..._, })),

		// panels
		...[
			{
				ii: "f:templatesStatus",
				t: "template_management_status",
			},
			{
				ii: "f:templateLanguage",
				t: "template_management_language",
				...p_lang,
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: "panel", ..._, })),
	]
		.map(itemsBuild)
		.reduce(itemsDrain, [])
		.reduce(itemsResort, {}),
	path: "core/mail-templates",
	widget: false,
};