/* eslint-disable no-useless-constructor ,react/jsx-curly-spacing ,react/jsx-sort-props ,template-curly-spacing ,sort-keys ,no-return-await */
import React, { Component, } from "react";
import { withLocalize, } from "react-localize-redux";
import { Select, } from "../../../../../components";
import { findOptionByValue, setErrorClass, } from "../../../../../utils/helpers";
class Form extends Component {
	constructor (props) {
		super(props);
	}

	render () {
		const {
			state,
			translate,
			users,
			programsAvailableOptions: programs,
			statuses,
			isEditMode,
			renderRejectForm,
			handleOnChange,
		} = this.props;
		const { user_id, approved_program_id, status, } = state.formData;
		const { allPrograms, errors, } = state;
		const isRejected = status === 1;
		const selectedProgramName = allPrograms
			? allPrograms.find(
				program => program.partners.programs.id === approved_program_id,
					// eslint-disable-next-line no-mixed-spaces-and-tabs
			  )?.partners.programs.name
			: "";

		return (
			<div className="view-edit-request__form">
				<div className="view-edit-request__row">
					<Select
						className={setErrorClass(errors, "user_id")}
						disabled={!isEditMode}
						label={translate("partners_request_manager")}
						name={"user_id"}
						options={users}
						placeholder={translate(
							"partners_requests_partner_manager_placeholder",
						)}
						value={findOptionByValue(users, user_id)}
						onChange={handleOnChange}
					/>
				</div>
				<div className="view-edit-request__row">
					<Select
						className={setErrorClass(errors, "approved_program_id")}
						disabled={!isEditMode}
						label={translate("partners_requests_partner_affiliate_program")}
						name={"approved_program_id"}
						options={programs}
						placeholder={translate(
							"partners_requests_partner_program_placeholder",
						)}
						value={findOptionByValue(programs, approved_program_id)}
						onChange={handleOnChange}
					/>
					<p className="view-edit-request__name">{selectedProgramName}</p>
				</div>
				<div className="view-edit-request__row">
					<Select
						className={setErrorClass(errors, "status")}
						disabled={!isEditMode}
						label={translate("partners_requests_partner_status")}
						name={"status"}
						options={statuses}
						value={findOptionByValue(statuses, status)}
						onChange={handleOnChange}
					/>
				</div>
				{isRejected && renderRejectForm()}
			</div>
		);
	}
}

export default withLocalize(Form);