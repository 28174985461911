import SummaryLinkConfig from "./SummaryLinkConfig";
export const breadcrumbs4Summary = (translate) => {
	return SummaryLinkConfig(
		translate,
		[
			'payments',
			'summary',
		],
		{ },
	);
};