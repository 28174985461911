import React, { Component } from 'react';
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import ModalBTN from './ModalBTN';
import ExistTemplate from './ExistTemplate';
import EmptyTemplate from './EmptyTemplate';
import {
    EDIT_TEMPLATE,
    ACTIVE,
    NOT_ACTIVE,
    CREATE_TEMPLATE_MANAGEMENT,
    SMS_TEMPLATE,
    E_MAIL_TEMPLATE,CREATE_TEMPLATE,CREATE_TEMPLATE_TRANSLATION
} from '../constants';
import { MagicInput } from "../../../../../components/Magic/components/MagicInput";
import { enumService } from "../../../../../services/EnumDataService";
import Preloader from "../../../../../components/LoadingHOC/Preloader";
import { Scrollbars } from "react-custom-scrollbars";

class Modal extends Component {
    constructor (props) {
        super(props);
        this.state = {
            template_active: this.props.templatesCreate.active ?? '',
            closeLanguageTemplate: true,
            addTemplateCollaps: false,
            typeTemplateTranslate: '',
        };
    }

    async componentDidMount () {
        enumService.subscribe('enums', this.enumsChange, this);
        await enumService.enums;
        this.props.popUpTemplateManagement.template_id ? await this.props.getCurrentTemplate() : null;
    }

    componentWillUnmount () {
        enumService.unsubscribe('enums', this.enumsChange, this);
    }

    enumsChange = async (enums) => {
        if (enums.hash===this.state.enum?.hash) return `${this.constructor.name}.enumsChange: false`;
        await this.save({ enums, });
        return `${this.constructor.name}.enumsChange: true`;
    };

    save = async (state) => new Promise(next => this.setState(state, next));

    async UNSAFE_componentWillReceiveProps (nextProps) {
        await this.save({
            template_active: nextProps.templatesCreate.active ?? '',
        });
    }

    renderModalBTN = (clickTypeTemplate) => {
        return (
            <ModalBTN
                popUpTemplateManagement = {this.props.popUpTemplateManagement}
                clickTypeTemplate = {clickTypeTemplate}
                sendCreateTemplate = {this.props.sendCreateTemplate}
                sendUpdateTemplate = {this.props.sendUpdateTemplate}
                deleteTemplate = {this.props.deleteTemplate}
                closePopUpModule = {this.props.closePopUpModule}
                sendRequest={this.props.sendRequest}
            />
        );
    };

    toggleTemplateActive = async (status) => {
        status === ACTIVE ? await this.save({ template_active: NOT_ACTIVE }) : await this.save({ template_active: ACTIVE });
        await this.props.setToStateActiveCheckbox(this.state.template_active);
    };

    languageIncludes = (modal_name) => {
        if (Object.keys(this.props.modalTemplatesTranslation).length > 0) {
            const sortLanguage = [];
            const lang__template_translation = [];

            this.props?.modalTemplatesTranslation?.map?.((item) => {
                lang__template_translation.push(modal_name === SMS_TEMPLATE
                    ? item.core['sms_template_translation'].language_id
                    : modal_name === E_MAIL_TEMPLATE
                        ? item.core['mail_template_translation'].language_id : null);
            });
            this.props.language?.map?.((item) => !lang__template_translation.includes(item.value) ? sortLanguage.push(item) : null);

            return sortLanguage;
        }
    };

    emptyTemplate = (dataLanguage) => {
        if (this.state.addTemplateCollaps) {
            return (
                <EmptyTemplate
                    addTemplateCollaps={this.state.addTemplateCollaps}
                    language = {this.props.language}
                    popUpTemplateManagement = {this.props.popUpTemplateManagement}
                    reloadTemplatesTranslation = {this.props.reloadTemplatesTranslation}
                    _getTempl = {this.props.getCurrentTemplate}
                    dataLanguage = {dataLanguage}
                    toggleTemplateCollaps={this.toggleTemplateCollaps}
                    typeTemplateTranslate={this.state.typeTemplateTranslate}
                />
            );
        } else {
            return null;
        }
    };

    renderHistoryButton = () => {
        return (
            <React.Fragment>
                {this.props.popUpTemplateManagement.clickTypeTemplate === EDIT_TEMPLATE
                    ? <div className='modal_management-history'>
                        <span onClick={this.props.showHistoryChangesTemplate}>{this.props.translate(`template_management_history_changes`)}</span>
                        {this.renderModalBTN(this.props.popUpTemplateManagement.clickTypeTemplate)}
                    </div>
                    : this.renderModalBTN(this.props.popUpTemplateManagement.clickTypeTemplate)}
            </React.Fragment>
        );
    };

    renderCheckbox = () => {
        const enums = this.state.enums?.data?.core?.MailTemplateActiveEnum;
        return (
            <div className='modal_management_template-active' onClick = { async () => {this.toggleTemplateActive(this.state.template_active, this.props.popUpTemplateManagement.clickTypeTemplate, this.props.popUpTemplateManagement.modal_name)} }>
                <label className = 'checkbox'>
                    <input
                        checked = { this.state.template_active === ACTIVE ? true : this.state.template_active === NOT_ACTIVE ? false : null }
                        type = 'checkbox'
                        name = 'active'
                        onChange={ () => {} }
                    />
                    <span />
                </label>
                {this.state.template_active === ACTIVE ? enums?.[ACTIVE] : this.state.template_active === NOT_ACTIVE ? enums?.[NOT_ACTIVE] : null}
            </div>
        );
    };

    sendTemplateName = async (value, name) => {
        await this.props.setToStateTemplateName(value, name);
    };

    renderTemplateTextarea = () => {
        const inputName = {
            className: [`${this.props.errors.name ? 'error' : ''} modal_management-textarea-input`],
            sizeable: false,
            onChange: async (value) => {
                this.props?.clearErrorForClick('name');
                this.sendTemplateName(value, 'name');
            },
            placeholder: this.props.translate('template_management_template_name_placeholder'),
            value:       this.props?.templatesCreate.name ?? '',
            InputProps:  {
                autoComplete: 'new-password',
            },
        };
        const inputDescription = {
            className: [`${this.props.errors.description ? 'error' : ''} modal_management-textarea-input`],
            name:      'description',
            onChange: async (event) => {
                this.props?.clearErrorForClick('description');
                this.sendTemplateName(event.target.value, 'description');
            },
            placeholder: this.props.translate('template_management_description_placeholder'),
            value:       this.props?.templatesCreate.description ?? '',
        };
        return (
            <div className = 'modal_management-textarea'>
                <div className = 'modal_management-textarea-title'>
                    {
                        this.props.getCurrentTemplateStatus ?
                            <React.Fragment>
                                <div className = 'title_wrap'>
                                    <div>
                                        {this.props.translate(`template_management_input_name`)}<span>*</span>
                                    </div>
                                    {this.renderCheckbox()}
                                </div>
                                <MagicInput { ...inputName } />
                                <div className = 'title_wrap'>
                                    <div>
                                        {this.props.translate(`template_management_description`)}<span>*</span>
                                    </div>
                                </div>
                                <textarea { ...inputDescription } />
                            </React.Fragment>
                            : <Preloader scale={0.5} />

                    }
                </div>
                {this.renderHistoryButton()}
            </div>
        );
    };

    renderLanguageTemplate = (dataLanguage) => {
        if (!this.props?.getTemplateTranslationStatus) {
            return <Preloader scale={0.5} />;
        } else if (this.props.modalTemplatesTranslation?.length > 0) {
            return this.props.modalTemplatesTranslation?.map?.((item, key) => {
                return (<ExistTemplate
                    key={key}
                    popUpTemplateManagement={this.props.popUpTemplateManagement}
                    modalTemplatesTranslation={item.core}
                    lang_locale={this.props.lang_locale}
                    closePopUpModule={this.props.closePopUpModule}
                    language={this.props.language}
                    reloadTemplatesTranslation={this.props.reloadTemplatesTranslation}
                    _getTempl={this.props.getCurrentTemplate}
                    dataLanguage={dataLanguage}
                    showHistoryChangesTemplateTranslation={this.props.showHistoryChangesTemplateTranslation}
                    togglePopUpTemplateManagement={this.props.togglePopUpTemplateManagement}
                    togglePopup={this.props.togglePopup}
                    errors={this.props.errors}
                    /*closeAllLanguageTemplate={this.closeAllLanguageTemplate}
                    closeLanguageTemplateStatus={this.state.closeLanguageTemplate}*/

                />);
            });
        } else {
            return null;
        }
    };

    renderTemplateLanguageList = () => {
        const dataLanguage = this.props.language;

        return (
            <div className="modal_management-add">
                {!this.state.addTemplateCollaps && this.props.modalTemplatesTranslation?.length < 3 ?
                    <div className="modal_management-add_btn">
                        <button onClick={() => this.toggleTemplateCollaps(CREATE_TEMPLATE_TRANSLATION)}>{this.props.translate(`template_management_add_template`)}</button>
                    </div>
                    :''}
                {!dataLanguage
                    ? this.emptyTemplate(this.props.language)
                    : dataLanguage.length > 0
                        ? this.emptyTemplate(dataLanguage)
                        : null
                }
                {this.renderLanguageTemplate(dataLanguage)}
            </div>
        );
    };

    renderTitle = () => {
        const templateConstant = this.props.popUpTemplateManagement.clickTypeTemplate === CREATE_TEMPLATE
            ? this.props.translate(`template_management_create`)
            : this.props.translate(`template_management_edit`);

        return <h3>{templateConstant} {CREATE_TEMPLATE_MANAGEMENT[this.props.popUpTemplateManagement.modal_key]?.label} {this.props.translate(`template_management_template`)}</h3>;
    };

    toggleTemplateCollaps = async (typeTemplateTranslate) => {
        await this.save((state) => ({
            addTemplateCollaps: !state.addTemplateCollaps,
            typeTemplateTranslate,
        }));
    };

    render () {
        return (
            <div className = 'modal_management'>
                <div className = 'modal_management-body'>
                    <Scrollbars autoHeight autoHeightMax = { window.innerHeight - 300 } style={{width: 'auto'}} >
                        <div className = 'modal_management-close' onClick={()=>{
                            this.props.closePopUpModule();
                        }}>
                            <i className = 'la la-close' />
                        </div>

                        <div className = 'modal_management-header'>
                            {this.renderTitle()}
                        </div>

                        {this.renderTemplateTextarea()}

                        {this.props.popUpTemplateManagement.clickTypeTemplate === EDIT_TEMPLATE
                            ? this.renderTemplateLanguageList() : null}
                    </Scrollbars>
                </div>
            </div>
        );
    }
}

export default withLocalize(withRouter(Modal));
