import { Alert } from "reactstrap";
import React, { Component } from "react";

class AlertBlock extends Component {
    constructor (props) {
        super(props);
        this.state = {
            visible: true,
        };
        this.onDismiss = this.onDismiss.bind(this);
    }

    onDismiss () {
        this.setState({ visible: false });
    }

    UNSAFE_componentWillMount() {
        if (this.state.visible) {
            this.invisible = setTimeout(() => {
                this.setState(() => ({visible: false}))
            }, 2000);
        }
    }

    componentWillUnmount () {
        clearTimeout(this.invisible);
    }

    render() {


        return (
            <Alert className = 'alert-default' isOpen = { this.state.visible }>
                <div className = 'alert-text'>
                    <div className = 'alert-title'>{this.props.alert.title}</div>
                    <div className = 'alert-paragraph'>{this.props.alert.text}</div>
                </div>
                <div className = 'alert-buttons'>
                    <span className = 'alert-button' onClick = { this.onDismiss }>Close</span>
                    { this.props.alert.link ?
                        <span className = 'alert-button'>View</span> : ''

                    }
                </div>
            </Alert>
        );
    }
}

export default AlertBlock;
