import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { Link } from 'react-router-dom';

import propTypes                                from 'prop-types';

import {
   GET_PAYMENT_ORDERS }                         from '../../../apiRoutes';

import {
   MagicButton,
   MagicSelect }                                from "../../../../../components/Magic";
import {
   MagicConfirm, MagicRadioSwitch, MagicSwitch, MagicTextarea,
   MagicTooltip }                               from '../../../../tima/components/Magic';
import Loader                                   from '../../../../tima/components/Loader';
import Preloader                                from '../../../../../components/LoadingHOC/Preloader';
import Input from "../../../../partners_system/components/Input/Input";
import { MagicDate } from "../../../../../components/Magic";

import { securedRequest }                       from '../../../../../services/RequestService';
import PermissionService                        from "../../../../../services/PermissionService";
import NotificationService                      from "../../../../../services/NotificationService";
import { paymentService }                       from "../../../../../services/PaymentDataService";

import { PAYMENT_TYPES }                        from "../consts/PAYMENT_TYPES";
import { PAYMENT_UPDATE_METHODS }               from "../consts/PAYMENT_UPDATE_METHODS";

import { onValidAmount,
         onValidFee, getMinAmountForOrder, }    from "../helpers/validation-helpers";
import DateTimeHelper                           from "../../../../../helpers/DateTimeHelper";

class OnePaymentOrderTable extends React.Component {

   static propTypes = {
      confirm: propTypes.bool,
      editMode: propTypes.bool,
      enums: propTypes.object,
      setConfirm: propTypes.func.isRequired,
      setEditMode: propTypes.func.isRequired,
      translate: propTypes.func.isRequired,
   }

   constructor(props) {
      super(props);
      this.state = {
         paymentOrder: {
            data: [],
            hash: null,
         },
         paymentId: '',
         editField: {
            field: "",
            confirm: "",
            value: "",
         },
         banks: {
            data: {},
            hash: null,
         },
         sendFlexiGate: 0,
         orderId: 0,
         resolution: 0,
         loaded: true,
         disabledInvoiceBtn: false,
         disabledAggreementBtn: false,
         manuallyClosingUpdatedValue: {},
         sendResolutionRequest: false,
         alreadyUsedCwb: [],
         isUsedCwb: null,
      };
   };

   paymentOrderChanges = async (payment) => {
      const { orderId } = this.props?.params;
      if (payment.hash===this.state?.paymentOrder?.hash) {
         return `${this.constructor.name}.paymentOrderChanges: false`;
      } else if (payment.orderId!==orderId) {
         return `${this.constructor.name}.paymentOrderChanges: false`;
      }
      const { data: { data }} = payment;
      await this.save({
         paymentOrder: {
            data: [data],
            hash: payment.hash,
         }
      });

      const accountId = data?.payment?.orders?.account_id;
      const paymentSystem = data?.payment?.orders?.payment_system;
      const Deposit = +data?.payment?.orders?.type === 1;

      if (Deposit) {
         const alreadyUsedCwb = await paymentService.getCardsByAccountAndOrderId(accountId, paymentSystem);
         await this.save({ alreadyUsedCwb: alreadyUsedCwb, });

         this.state?.alreadyUsedCwb.map(async (item) => {
            if (item.id === data?.payment?.orders?.payment_card_id) {
               await this.save({ isUsedCwb: data?.payment?.orders?.payment_card_id, });
            }
         })
      }

      return `${this.constructor.name}.paymentOrderChanges: true`;
   };

   banksChange = async (banks) => {
      if (banks.hash===this.state?.banks?.hash) return `${this.constructor.name}.banksChange: false`;
      if (banks.currencyId!==+this.state.paymentOrder?.data?.[0]?.core?.system_accounts?.acc_ccy) {
         return `${this.constructor.name}.banksChange: false`;
      }
      const data = banks?.data?.reduce?.((bank, item) => {
         return {...bank, [item?.system_id]: item?.name};
      }, {});
      await this.save({
         banks: {
            data: data,
            hash: banks.hash,
         }
      });
      return `${this.constructor.name}.banksChange: true`;
   };

   componentDidMount = async () => {
      paymentService.subscribe('paymentOrder', this.paymentOrderChanges, this);
      paymentService.subscribe('bankByCurrency', this.banksChange, this);
      await this._getData();
      await this._getBankByCurrency();
   };

   componentWillUnmount = () => {
      paymentService.unsubscribe('paymentOrder', this.paymentOrderChanges, this);
      paymentService.unsubscribe('bankByCurrency', this.banksChange, this);
   };


   get urlPathOfTheOrder(){
      const paymentOrderData = this.state.paymentOrder.data[0].payment;
      const { type, id } = paymentOrderData.orders;
      const method = paymentOrderData.system_types.alias ?? 'BY_SYSTEM'; //if empty we count it as system
      if(!PAYMENT_TYPES[type] || !PAYMENT_UPDATE_METHODS[method]) return `${ id }`;

      return `${ PAYMENT_TYPES[type] }/${ PAYMENT_UPDATE_METHODS[method] }/${ id }`;
   }

   // get isSharpay(){
   //    return this.state.paymentOrder.data[0].payment.system_gateway.name === 'SharPay'
   // }
   save = async (state) => new Promise(next => this.setState(state, next))


   _getData = async () => {
      const { orderId } = this.props?.params;
      try {
         await this.save({ loaded: false });
         await paymentService.paymentOrder(orderId);
         await this.save({ sendResolutionRequest: {}, });
         await this.save({ loaded: true, });
      } catch (error) {
         error?.showErrorNotification?.();
         await this.save({ paymentOrder: { data: [], hash: null, }, });
      }
   };

   _getBankByCurrency = async () => {
      try {
         const currencyId = +this.state.paymentOrder?.data?.[0]?.core?.system_accounts?.acc_ccy ?? 0;
         await paymentService.bankByCurrency(currencyId);
      } catch (error) {
        error?.showErrorNotification?.();
         await this.save({ banks: { data: [], hash: null, }, });
      }
   };

   _editPayment = async (id, field, value) => {
      try {
         const options = {
            [field]: value,
            ...this.state?.manuallyClosingUpdatedValue[id] === 1 && {
               manually_closing: 1,
            },
         };

         const path = this.urlPathOfTheOrder;
         const response = await paymentService.editPaymentPatch(path, options);

         this.closeEditField();

         this.state?.manuallyClosingUpdatedValue[id] === 1 && value === 1 ? await this._getData() : null;


         if (field === "resolution" && response?.data[0] && this.state?.manuallyClosingUpdatedValue[id] !== 1) {
            await this.save({ sendFlexiGate: id });
            ("resolution" in options && response?.data[0]) && NotificationService.success({title: "error", message: response?.data[1], remove: false,});
         } else if (!response?.data[0]){
            await this._getData();
            await this._getBankByCurrency();
         }
      } catch (error) {
         this.closeConfirm();
         error?.showErrorNotification?.();
      }
   };

   setResolution = async (orderId, value) => {
      await this.save({orderId, resolution: value,});
   };

   setFlexiGate = async (orderId) => {
      await this.save({sendFlexiGate: orderId,});
   };

   clearResolution = async () => {
      await this.save({orderId: 0, resolution: 0,});
   };

   _editCWB = async (paymenCardId, orderId, status, value) => {
      try {
         await paymentService.editCWB(paymenCardId, {status, name: value, order_id: orderId,});
         this.closeEditField();
         await this._getData();
         await this._getBankByCurrency();
      } catch (error) {
         this.closeConfirm();
         error?.showErrorNotification?.();
      }
   };

   _createInvoice = async (orderId) => {
      try {
         await this.save({ disabledInvoiceBtn: true });
         const { data } = await paymentService.generateInvoice(orderId);
         await this.save(state => ({
            paymentOrder: {
               ...state.paymentOrder,
               data: [{ ...state.paymentOrder?.data?.[0], payment: { ...state.paymentOrder?.data?.[0]?.payment, orders: data},}]
            },
         }))
         await this.save({ disabledInvoiceBtn: false });
      } catch (error) {
        error.showErrorNotification();
      }
   };

   _createCard = async (account_id, name, payment_system, orderId) => {
      try {
         const { data } = await paymentService.createCard({ account_id, name, payment_system, status: 2 });
         const { id } = data;
         await this._editPayment(orderId, "payment_card_id", id);
      } catch (error) {
         error?.showErrorNotification?.();
      }
   };

   onChangeManuallyClosing = async (value, id) => {
      await this.save({
         manuallyClosingUpdatedValue: {
            ...this.state.manuallyClosingUpdatedValue,
            [id]: value,
         },
      });
   };

   onChangeResolution = async (value, id) => {
      await this.save({
         sendResolutionRequest: {
            ...this.state.sendResolutionRequest,
            [id]: value,
         },
      });
   };

   get paymentConfig() {
      const {translate} = this.props;
      return [
         {
            path: ['payment', 'orders', 'source'],
            key: 'paymentOrderSource',
         },
         {
            path: ['payment', 'orders', 'was_already_closed'],
            key: 'paymentWasAlreadyClosed',
         },
         {
            path: ['payment', 'orders', 'type'],
            key: 'paymentOrderType',
         },
         {
            path: ['payment', 'orders', 'is_sended'],
            key: 'paymentIsSended',
         },
         {
            path: ['payment', 'orders', 'status'],
            key: 'paymentStatus',
         },
         {
            path: ['core', 'account', 'name'],
            key: 'paymentClientName',
         },
         {
            path: ['core', 'account', 'surname'],
            key: 'paymentClientSurname',
         },
         {
            path: ['payment', 'orders', 'account_id'],
            key: 'paymentClientId',
         },
         {
            path: ['payment', 'orders', 'payment_card_id'],
            key: 'paymentCardId',
         },
         {
            path: ['payment', 'orders', 'has_invoice'],
            key: 'paymentHasInvoice',
         },
         {
            path: ['payment', 'orders', 'payment_system'],
            key: 'paymentOrdersSystemId',
         },
         {
            path: ['core', 'user', 'surname'],
            key: 'paymentResponsibleSurname',
         },
         {
            path: [ 'payment', 'system_gateway', 'name', ],
            key: 'paymentGateway',
         },
         {
            path: ['payment', 'orders', 'created_at'],
            key: 'paymentCreateAt',
            render: this.renderPaymentDate,
            title: 'payment_table_create_at',
         },
         {
            path: ['payment', 'orders', 'date_enrollment'],
            key: 'paymentDateEnrollment',
            render: this.renderEnrollmentDate,
            title: 'payment_table_enrollment_date',
         },
         {
            path: ['payment', 'orders', 'id'],
            key: 'paymentOrderId',
            render: this.renderPaymentOrderId,
            title: 'payment_table_order_id',
         },
         {
            path: ['core', 'system_accounts', 'id'],
            key: 'paymentSystemId',
            render: this.renderPaymentSystemId,
            title: 'payment_table_system_account_id',
         },
         {
            path: ['core', 'account', 'name'],
            key: 'paymentClientFullname',
            render: this.renderPaymentClient,
            title: 'payment_table_client',
         },
         {
            path: ['core', 'user', 'name'],
            key: 'paymentResponsible',
            render: this.renderPaymentResponsible,
            title: 'payment_table_responsible',
         },
         {
            path: ['core', 'system_accounts', 'acc_ccy'],
            key: 'paymentCurrency',
            render: this.renderPaymentCurrency,
            title: 'payment_table_currency',
         },
         {
            path: ['payment', 'orders', 'amount'],
            key: 'paymentAmount',
            render: this.renderPaymentAmount,
            title: 'payment_table_amount',
         },
         {
            path: ['payment', 'orders', 'fee'],
            key: 'paymentFee',
            render: this.renderPaymentFee,
            title: 'payment_table_fee',
         },
         {
            path: ['payment', 'orders', 'amount_paid'],
            key: 'paymentAmountPaid',
            render: this.renderAmountPaid,
            title: 'payment_table_amount_paid',
         },
         {
            path: ['payment', 'system_types', 'alias'],
            key: 'paymentMethod',
            render: this.renderPaymentText,
            title: 'payment_table_payment_method',
         },
         {
            path: ['payment', 'systems', 'name'],
            key: 'paymentSystem',
            render: this.renderPaymentSystem,
            title: 'payment_table_payment_system',
         },
         {
            path: ['payment', 'orders', 'bank_credentials'],
            key: 'bankCredentials',
            render: this.renderPaymentBankCredentials,
            title: 'payment_table_bank_credentials',
         },
         {
            path: ['payment', 'cards', 'name'],
            key: 'paymentCWB',
            render: this.renderPaymentCWB,
            title: 'payment_table_cwb',
         },
         {
            path: ['payment', 'cards', 'card_holder'],
            key: 'paymentCardHolder',
            render: this.renderPaymentText,
            title: 'payment_table_card_holder',
         },
         {
            path: ['payment', 'orders', 'description'],
            key: 'paymentDescription',
            render: this.renderPaymentDescription,
            title: 'payment_table_description',
         },
         {
            path: ['payment', 'orders', 'date_paid'],
            key: 'paymentDatePaid',
            render: this.renderPaymentDate,
            title: 'payment_table_date_paid',
         },
         {
            path: ['payment', 'orders', 'status'],
            key: 'paymentOrderStatus',
            render: this.renderPaymentStatus,
            title: 'payment_table_status',
         },
         {
            path: ['payment', 'orders', 'manually_closing'],
            key: 'paymentManuallyClosing',
            render: this.renderPaymentManuallyClosing,
            title: 'payment_table_manually_closing',
         },
         {
            path: ['payment', 'orders', 'id'],
            key: 'paymentInvoice',
            render: this.renderPaymentInvoice,
            title: 'payment_table_invoice',
         },
         {
            path: ['payment', 'orders'],
            key: 'paymentOrders',
         },
         {
            path: ['payment', 'orders'],
            key: 'paymentClientAgreementPermission',
         },
         {
            path: ['payment', 'orders', 'id'],
            key: 'paymentClientAgreement',
            render: this.renderClientAgreement,
            title: 'payment_table_client_agreement',
         },
         {
            path: ['payment', 'orders', 'resolution'],
            key: 'paymentResolution',
            render: this.renderPaymentResolution,
            title: 'payment_table_resolution',
         },
         {
            path: ['payment', 'orders', 'withdrawal_reason_message'],
            key: 'paymentWithdrawal',
            render: this.renderPaymentWithdrawal,
            title: 'payment_table_reason_withdrawal',
         },
         {
            path: ['payment', 'orders', 'rejection_reason_message'],
            key: 'paymentRejectionMessage',
            render: this.renderPaymentText,
            title: 'payment_table_rejection_reason',
         },
         {
            path: ['payment', 'systems_by_card', 'name'],
            key: 'paymentSystemPlatformName',
            render: this.renderPaymentText,
            title: 'payment_table_platform'
         }
      ].map(({title: t, ...data}) => ({...data, ...t && {title: translate(t)},}));
   }

   setEditField = (field, value) => this.setState(state => ({editField: {...state.editField, field, value},}));

   setEditConfirm = field => this.setState(state => ({editField: {...state.editField, confirm: field,},}));

   closeConfirm = () => this.setState(state => ({editField: {...state.editField, confirm: "",}}));

   closeEditField = () => this.setState({editField: {field: "", confirm: "", value: "",},});

   renderPaymentLink = (path, text) => (<Link to={path} className="column__field_description">{text}</Link>);

   renderPaymentDate = (date, {item,}) => {
      if ( item.access('show') ) {
         return DateTimeHelper.getFormattedDate(date) ||  '-';
      }
      return '-';
   };

   renderEnrollmentDate = (date, {item, items}) => {
      if(item.access('show', 'update')){
         const { editField } = this.state;
         const case_1 = +items?.paymentStatus?.valueOf === 3;                      // [ 3 => "CLOSED" ]
         const case_2 = +items?.paymentOrderType?.valueOf === 1;                   // [ 1 => "ПОПОЛНЕНИЕ" ]

         //If order is not in status CLOSED
         //Order type is DEPOSIT
         const condition_1 = !case_1 && case_2;

         if(condition_1){
            const orderId = this.props.params.orderId;
            const editFieldDateIsInvalid = ['-', '',].includes(editField?.value);
            const magicDateValue =  editFieldDateIsInvalid ? Date.now() : editField.value;

            const options = {
               onChange: async (date) => await this.save(state => ({ editField: {...state.editField, value: date} })),
               value: magicDateValue,
               className: 'input__wrapper-order',
               max: Date.now(),
            };

            return (
                this.renderEditBlock({
                   field: 'date_enrollment',
                   value: date || new Date(Date.now()).toISOString(),
                   valueToLabel: DateTimeHelper.getFormattedDate(date, "YYYY-MM-DD") || '-',
                   children: <MagicDate {...options}/>,
                   onAccept: async () => {
                      const formattedDate = DateTimeHelper.getFormattedDate(this.state.editField.value, "YYYY-MM-DD");
                      await this._editPayment(orderId, "date_enrollment", formattedDate);
                      },
                   onReject: () => this.closeConfirm(),
                })
            )
         }
      }
      if (item.access('show')) {
         return DateTimeHelper.getFormattedDate(date, "YYYY-MM-DD") || "-";
      }
      return '-';
   };

   renderPaymentText = (text, {item,}) => {
      const options = {
         content: text ?? "-",
         lengthCut: 20,
      };
      if (item.access('show')) {
         return  (<MagicTooltip {...options} />);
      }
      return '-';
   };

   renderAmountPaid = (text, {item, items}) => {
      const value = +items.paymentAmount.valueOf + +items.paymentFee.valueOf; //fee is a negative number
      const options = {
         content: value ?? "-",
         lengthCut: 20,
      };
      if (item.access('show')) {
         return  (<MagicTooltip {...options} />);
      }
      return '-';
   };
   renderPaymentOrderId = (id, {item, items,}) => {
      if ( item?.access('show') ) {
         const orderIndex = items?.paymentOrderType?.valueOf;
         const typesOrder = this.props?.enums?.payment?.OrderTypeStatusEnum ?? {};
         const options = {
            content: typesOrder?.[orderIndex] ?? "-",
            lengthCut: 20,
         };
         const text = (<MagicTooltip {...options} />);
         return (
            <React.Fragment>
               <div className={`order-type ${[2].includes(+orderIndex) ? 'withdrawal' : 'replenishment'}`}>{text}</div>
               {id}
            </React.Fragment>
         )
      }
      return '-';
   };

   renderPaymentSystemId = (id, {item,}) => {
      const options = {
         content: String(id) ?? "-",
         lengthCut: 20,
      };
      if (item?.access('show')) {
         return this.renderPaymentLink(`/list-accounts/systems/${id}`, <MagicTooltip {...options} />);
      }
      return '-';
   };

   renderPaymentClient = (_, {item, items,}) => {
      const {paymentClientName, paymentClientSurname, paymentClientId} = items;
      const name = paymentClientName?.valueOf;
      const surname = paymentClientSurname?.valueOf;
      const clientId = paymentClientId?.valueOf;
      const options = {
         content: `${name ?? ""} ${surname ?? ""}`,
         lengthCut: 20,
      };
      const text = <MagicTooltip {...options} />
      if ( item?.access('show') && items?.paymentClientId?.access('show')) {
         return (this.renderPaymentLink(`/clients/${clientId}/depositsandwithdrawals`, text));
      }
      return this.renderPaymentText(`${name ?? ""} ${surname ?? ""}`, {item,});
   };

   renderPaymentResponsible = (name, {item, items}) => {
      const surname = items?.paymentResponsibleSurname?.valueOf;
      const fullName = `${name ?? ""} ${surname ?? ""}`
      return this.renderPaymentText(fullName, {item,});
   };

   renderPaymentCurrency = (currency, {item,}) => {
      const allCurrencies = this.props.enums?.core?.AccountCurrencyEnum ?? {};
      return this.renderPaymentText(allCurrencies?.[currency], {item,});
   };

   renderEditBlock = ({field, value, valueToLabel = value, children, onAccept, onReject}) => {
      const setEditField = () => this.setEditField(field, value);
      const setEditConfirm = () => this.setEditConfirm(field);
      const closeEditField = () => this.closeEditField();
      const optionsConfirm = {
         onAccept,
         onReject,
         title: this.props.translate('one_payment_table_confirm_title'),
         accept: this.props.translate('one_payment_table_confirm_accept'),
         reject: this.props.translate('one_payment_table_confirm_reject'),
         isVisible: this.state.editField?.confirm === field,
      };
      return (
         <div className='edit__block'>
            <i className='edit__block edit__block_btn' onClick={setEditField}/>
            <span>{valueToLabel ?? "-"}</span>
            {this.state.editField.field === field &&
            (<div className='magic-confirm__wrapper'>
               <div className='control-buttons'>
                  <i className='control-buttons control-buttons_accept' onClick={setEditConfirm}/>
                  <i className='control-buttons control-buttons_close' onClick={closeEditField}/>
               </div>
               <MagicConfirm {...optionsConfirm} />
               {children}
            </div>)
            }
         </div>
      )
   };

   renderPaymentAmount = (amount, {item, items}) => {
      if ( item?.access('show', 'update') ) {
         const { translate } = this.props;
         const orderId = this.props.params.orderId;
         const { manuallyClosingUpdatedValue, } = this.state;

         const case_1 = [2].includes(+items?.paymentStatus?.valueOf);                                 // [ 2 => "IN_PROCESS" ]
         const case_2 = [1].includes(+items?.paymentOrderType?.valueOf);                              // [ 1 => "ПОПОЛНЕНИЕ" ]
         const case_3 = [9].includes(+items?.paymentResolution?.valueOf);                             // [ 9 => "STATUS_NEED_CONFIRM" ]
         const case_4 = ["BANK_TRANSFER", "CRYPTO_CURRENCY"].includes(items?.paymentMethod?.valueOf); // [БАНКОВСКИЙ ПЕРЕВОД ИЛИ КРИПТА]
         const case_5 = ["WALLET"].includes(items?.paymentMethod?.valueOf);                           // [КОШЕЛЕК]
         const case_6 = ["CRM"].includes(items?.paymentOrderSource?.valueOf);                         // [ заявка, созданная в CRM ]
         const case_7 = ["PO"].includes(items?.paymentOrderSource?.valueOf);                          // [ заявка, созданная в ЛК ]
         const case_8 = [47].includes(+items?.paymentOrdersSystemId?.valueOf);                        // PAYEER
         const case_9 = items.paymentGateway.valueOf === "SharPay";                                   // Sharpay
         const case_10 = +items.paymentResolution?.valueOf === 0;                                     // Resolution: WAIT
         const case_11 = +items.paymentStatus?.valueOf === 1;                                         // Status: Open
         // const case_12 = manuallyClosingUpdatedValue[orderId] === 1;                               // Is manually closing
         const case_13 = ["CARD"].includes(items?.paymentMethod?.valueOf);                            // CARD
         // const case_14 = +items.paymentManuallyClosing.valueOf === 1;                              // Manually closing in db
         const case_15 = ["CRYPTO_CURRENCY"].includes(items?.paymentMethod?.valueOf);                 // КРИПТА
         const case_16 = ["BANK_TRANSFER"].includes(items?.paymentMethod?.valueOf);                   // БАНКОВСКИЙ ПЕРЕВОД
         const case_17 = [2].includes(+items?.paymentOrderType?.valueOf);                             // [ 2 => "WITHDRAWAL" ]
         const case_18 = [null, "", "SYSTEM_DEPOSIT"].includes(items?.paymentMethod?.valueOf)         // [CИСТЕМНЫЙ ДЕПОЗИТ] - if empty we suppose it's system deposit

         // условие 1: если заявка:
         // 1) в статусе "IN_PROCESS",
         // 2) тип заявки "ПОПОЛНЕНИЕ",
         // 3) имеет решение "STATUS_NEED_CONFIRM",
         // 4) через "BANK_TRANSFER", "CRYPTO_CURRENCY" ( Банковский перевод / Криптовалюту )
         // 5) Не Sharpay
         const condition_1 = case_1 && case_2 && case_3 && case_4 && !case_9;

         // условие 2: если заявка:
         // 1) в статусе "IN_PROCESS",
         // 2) тип заявки "ПОПОЛНЕНИЕ",
         // 3) имеет решение "STATUS_NEED_CONFIRM",
         // 4) через "WALLET" ( Кошелек )
         // 5) созданная в CRM
         const condition_2 = case_1 && case_2 && case_3 && case_5 && case_6;

         // условие 3: если заявка:
         // 1) в статусе "IN_PROCESS",
         // 2) тип заявки "ПОПОЛНЕНИЕ",
         // 3) имеет решение "STATUS_NEED_CONFIRM",
         // 4) через "WALLET" ( Кошелек )
         // 5) созданная в ЛК
         // 6) PAYEER
         const condition_3 = case_1 && case_2 && case_3 && case_5 && case_7 && case_8;


         // условие 4: если заявка:
         // 1) в статусе "OPEN",
         // 2) имеет решение "WAIT",
         // 3) через "CARD" ( КАРТУ ) или "WALLET" (КОШЕЛЕК),
         // 4) Шлюз SharPay,
         const condition_4 = case_11 && case_10 && (case_13 || case_5) && case_9

         // условие 5: если заявка:
         // 1) в статусе "IN_PROCESS",
         // 2) имеет решение "NEED CONFIRM",
         // 4) через "CRYPTO_CURRENCY" (Криптовалюта),
         // 5) Шлюз SharPay,
         // 6) Тип заявки DEPOSIT (пополнение),
         // 7) Созданая в СRM
         const condition_5 = case_1 && case_3 && case_15 && case_9 && case_2 && case_6;

         // условие 6: если заявка:
         // 1) в статусе "OPEN",
         // 2) имеет решение "WAIT",
         // 3) через "CRYPTO_CURRENCY" (Криптовалюта),
         // 4) Шлюз SharPay,
         // 5) Созданая в ЛК,
         const condition_6 = case_11 && case_10 && case_15 && case_9  && case_7;

         // условие 7: если заявка:
         // 1) в статусе "NEED CONFIRM",
         // 2) тип заявки "ПОПОЛНЕНИЕ",
         // 3) имеет решение "IN_PROCESS",
         // 4) через "BANK_TRANSFER" (Банковский перевод),
         // 5) Шлюз SharPay,
         const condition_7 = case_3 && case_2 && case_1 && case_16 && case_9

         // условие 8: если заявка:
         // 1) в статусе "IN_PROCESS",
         // 2) тип заявки "ПОПОЛНЕНИЕ",
         // 3) имеет решение "STATUS_NEED_CONFIRM",
         // 4) SYSTEM_DEPOSIT (Системный Депозит)
         const condition_8 = case_1 && case_2 && case_3 && case_18;

         if (!case_17 && (condition_1 || condition_2 || condition_3 || condition_4 || condition_5 || condition_6 || condition_7 || condition_8)) {
            const { field, value } = this.state?.editField;
            const orederType = items?.paymentOrderType?.valueOf;
            const orderMethod = items?.paymentMethod?.valueOf;

            const orderId = items?.paymentOrderId?.valueOf;
            const options = {
               onChange: async (e) => await this.save(state => ({ editField: {...state.editField, value: e.target.value.replace(',', '.')} })),
               onValid: (value) => onValidAmount(value, orederType, orderMethod),
               value: this.state.editField?.value,
               placeholder: '',
               wrapperClassName: 'input__wrapper-order'
               // selectionStart: this.state.editField?.value?.length ?? 0,
               // selectionEnd: this.state.editField?.value?.length ?? 0,
            };
            return (
               this.renderEditBlock({
                  field: 'amount',
                  value: amount,
                  children: <Input {...options}/>,

                  onAccept: () => {
                     const minValue = getMinAmountForOrder(orederType, orderMethod)

                     if(value < minValue){
                        return NotificationService.error({ title: "error", message: translate('payment_table_min_amount_error').replace('{minvalue}', minValue), remove: false, });
                     }

                     return this._editPayment(orderId, field, value)
                  },
                  onReject: () => this.closeConfirm(),
               })
            )
         }
      }
      return this.renderPaymentText(amount, {item});
   };

   renderPaymentFee = (fee, {item, items}) => {
      if ( item?.access('show', 'update') ) {
         const { translate } = this.props;
         const orderId = this.props.params.orderId;
         const { manuallyClosingUpdatedValue, } = this.state;

         const case_1 = items.paymentGateway.valueOf === "SharPay";                                  // Sharpay
         const case_2 = +items.paymentResolution?.valueOf === 0;                                     // Resolution: WAIT
         const case_3 = +items.paymentStatus?.valueOf === 1;                                         // Status: Open
         // const case_4 = manuallyClosingUpdatedValue[orderId] === 1;                                  // Is manually closing
         const case_5 = ["CARD"].includes(items?.paymentMethod?.valueOf);                            // CARD
         // const case_6 = +items.paymentManuallyClosing.valueOf === 1;                                 // Manually closing in db
         const case_7 = ["WALLET"].includes(items?.paymentMethod?.valueOf);                          // WALLET
         const case_8 = ["CRYPTO_CURRENCY"].includes(items?.paymentMethod?.valueOf);                 // CRYPTO CURRENCY
         const case_9 = ["CRM"].includes(items?.paymentOrderSource?.valueOf);                        // [ заявка, созданная в CRM ]
         const case_10 = ["PO"].includes(items?.paymentOrderSource?.valueOf);                        // [ заявка, созданная в ЛК ]
         const case_11 = [2].includes(+items?.paymentStatus?.valueOf);                               // Status: In Process
         const case_12 = [9].includes(+items?.paymentResolution?.valueOf);                           // Resolution: Need Confirm
         const case_13 = [1].includes(+items?.paymentOrderType?.valueOf);                            // [ 1 => "ПОПОЛНЕНИЕ" ]
         const case_14 = ["BANK_TRANSFER"].includes(items?.paymentMethod?.valueOf);                  // БАНКОВСКИЙ ПЕРЕВОД
         const case_15 = [2].includes(+items?.paymentOrderType?.valueOf);                            // [ 2 => "WITHDRAWAL" ]

         // условие 1: если заявка:
         // 1) в статусе "OPEN",
         // 2) имеет решение "WAIT",
         // 4) через "CARD" ( КАРТУ ) или "WALLET" (КОШЕЛЕК),
         // 5) Шлюз SharPay,
         const condition_1 =  case_3 && case_2 && (case_5 || case_7)  && case_1

         // условие 2: если заявка:
         // 1) в статусе "IN_PROCESS",
         // 2) имеет решение "NEED CONFIRM",
         // 3) через "CRYPTO_CURRENCY" (Криптовалюта),
         // 4) Шлюз SharPay,
         // 5) Тип заявки DEPOSIT (пополнение),
         // 6) Созданая в СRM
         const condition_2 = case_11 && case_12 && case_8 && case_1 && case_13 && case_9;

         // условие 3: если заявка:
         // 1) в статусе "OPEN",
         // 2) имеет решение "WAIT",
         // 3) через "CRYPTO_CURRENCY" (Криптовалюта),
         // 4) Шлюз SharPay,
         // 5) Созданая в ЛК,
         const condition_3 = case_3 && case_2 && case_8 && case_1 && case_10;


         // условие 7: если заявка:
         // 1) в статусе "NEED CONFIRM",
         // 2) тип заявки "ПОПОЛНЕНИЕ",
         // 3) имеет решение "IN_PROCESS",
         // 4) через "BANK_TRANSFER", "CRYPTO_CURRENCY" ( Банковский перевод / Криптовалюту )
         // 5) Шлюз НЕ SharPay,
         const condition_4 = case_12 && case_13 && case_11 && (case_14 || case_8) && !case_1


         if (!case_15 && (condition_1 || condition_2 || condition_3 || condition_4)) {
            const { field, value } = this.state?.editField;
            const orderId = items?.paymentOrderId?.valueOf;

            const feeEditValue = this.state.editField?.value;
            const alwaysPositiveFee = feeEditValue < 0 ? -feeEditValue : feeEditValue //Math.abs removes floating point so we can't use it

            const options = {
               onChange: async (e) => await this.save(state => ({ editField: {...state.editField, value: e.target.value.replace(',', '.')} })),
               onValid: (value) => onValidFee(value, items?.paymentOrderType?.valueOf, items?.paymentMethod?.valueOf),
               value: alwaysPositiveFee,
               placeholder: '',
               wrapperClassName: 'input__wrapper-order',
            };
            return (
                this.renderEditBlock({
                   field: 'fee',
                   value: fee ?? '0',
                   children: <Input {...options}/>,
                   onAccept: () => {
                      const sanitizedValue = value.trim().split('').filter(char => char !== '.');
                      const negativeValue = -value; //fee value in db exists as negative number

                      if(!sanitizedValue.length){
                         return NotificationService.error({ title: "error", message: translate('payment_popup_empty_field_error'), remove: false, });
                      }

                      return this._editPayment(orderId, field, negativeValue)
                   },
                   onReject: () => this.closeConfirm(),
                })
            )
         }
      }
      return this.renderPaymentText(fee ?? '0', {item});
   };

   renderPaymentSystem = (name, {item, items}) => {
      const allBanks = this.state?.banks?.data ?? {};
      if (item?.access('show', 'update')) {
         const translate = {
            defaultUsd: this.props.translate('payment_table_default_bank_usd'),
            defaultEur: this.props.translate('payment_table_default_bank_eur'),
         };
         const case_1 = [2].includes(+items?.paymentStatus?.valueOf);                         // [ 2 => "IN_PROCESS" ]
         const case_2 = ["BANK_TRANSFER"].includes(items?.paymentMethod?.valueOf);            // БАНКОВСКИЙ ПЕРЕВОД
         const case_3 = ["PO"].includes(items?.paymentOrderSource?.valueOf);                  // [ заявка, созданная в ЛК ]
         const case_4 = [49, 50].includes(+items?.paymentOrdersSystemId?.valueOf);            // [ ДЕФОЛТНЫЙ БАНК ]
         const case_5 = items?.paymentWasAlreadyClosed.valueOf;                               // заявка не закрывалась
         const case_6 = [1, 2, 9].includes(+items?.paymentResolution?.valueOf);               //[ "STATUS_APPROVED", "STATUS_DECLINED", "STATUS_NEED_CONFIRM" ]
         // условие 1: если заявка
         // 1) имеет статус "IN_PROCESS",
         // 2) через "BANK_TRANSFER" ( Банковский перевод )
         // 3) Созданная в ЛК
         // 4) payment_system_id === [49, 50]
         // 5) имеет одно из решений  [ "STATUS_APPROVED", "STATUS_DECLINED", "STATUS_NEED_CONFIRM" ]
         const condition_1 = case_1 && case_2 && case_6; // && case_2 && case_3 && case_4;

         if (condition_1 && Object.keys?.(allBanks)?.length) {
            let {field, value} = this.state?.editField;
            const orderId = items?.paymentOrderId?.valueOf;
            let values = [];
            // ПРОВЕРЯЕМ PAYMENT_SYSTEM
            switch (+items?.paymentOrdersSystemId?.valueOf) {
               // ЕСЛИ 49 ИЛИ 50, ДОБАВЛЯЕМ ДЕФОЛТНЫЙ БАНК В МАССИВ ЗНАЧЕНИЙ
               case 49:
                  values = ["49", ...Object.keys?.(allBanks)];
                  break;
               case 50:
                  values = ["50", ...Object.keys?.(allBanks)];
                  break;
               // ЕСЛИ НЕТ, ТО ОСТАВЛЯЕМ МАССИВ ЗНАЧЕНИЙ БЕЗ ИЗМЕНЕНИЙ
               default:
                  values = Object.keys?.(allBanks) ?? '-';
                  break;
            }
            const valueToLabel = value => {
               switch (+value) {
                  case 49:
                     return translate?.defaultUsd;
                  case 50:
                     return translate?.defaultEur;
                  default:
                     return allBanks?.[value] ?? '-';
               }
            };
            const options = {
               values,
               value,
               ItemProps: value =>  [49, 50].includes(+value) ? {disabled: true,} : {},
               valueToLabel: value => valueToLabel(value),
               onChange: async (value) => await this.save(state => ({editField: {...state.editField, value,}})),
            };
            return (
               this.renderEditBlock({
                  field: 'payment_system',
                  value: +items?.paymentOrdersSystemId?.valueOf,
                  valueToLabel: valueToLabel(items?.paymentOrdersSystemId?.valueOf),
                  children: <MagicSelect {...options}/>,
                  onAccept: () => this._editPayment(orderId, field, value),
                  onReject: () => this.closeConfirm(),
               })
            )
         } else {
            const options = {
               content: name,
               lengthCut: 20,
            };
            return (<MagicTooltip {...options} />);
         }
      }
      return this.renderPaymentText(name, {item});
   };

   renderPaymentBankCredentials = (name, { item, items }) => {
      let credentials = items?.bankCredentials?.valueOf;
      try {
         credentials = JSON.parse(credentials);
         credentials = Object.entries(credentials).filter(([_, v]) => v).map(i => i.join(': ')).join('\n');
         // return this.renderPaymentText(name, {item});
      } catch (e) {
         credentials = '-';
      }
      return (<pre>{ credentials }</pre>);
   };

   renderPaymentCWB = (cwb, { item, items }) => {
      if (item?.access('show', 'update')) {
         const case_1 = [2].includes(+items?.paymentStatus?.valueOf);                                 // [ 2 => "IN_PROCESS" ]
         const case_2 = [1].includes(+items?.paymentOrderType?.valueOf);                              // [ 1 => "ПОПОЛНЕНИЕ" ]
         const case_3 = ["BANK_TRANSFER"].includes(items?.paymentMethod?.valueOf); // [БАНКОВСКИЙ ПЕРЕВОД ИЛИ КРИПТА]
         const case_4 = ["CARD"].includes(items?.paymentMethod.valueOf);                              // [CARD]
         const case_5 = ["PO"].includes(items?.paymentOrderSource?.valueOf);                          // [ заявка, созданная в ЛК ]
         const case_6 = items?.paymentOrderSource?.valueOf === "CRM";                                 // [ заявка, созданная в CRM ]
         const case_7 = ["BANK_TRANSFER"].includes(items?.paymentMethod?.valueOf);
         const case_8 = [null, "-"].includes(item.valueOf);                                           // с бека в поле payments-cards-name пришло
         const case_9 = [1, 2, 5].includes(+items?.paymentResolution?.valueOf);                       // [ "STATUS_APPROVED", "STATUS_DECLINED", "STATUS_CHARGEBACK" ]
         const case_10 = [49, 50].includes(+items?.paymentOrdersSystemId?.valueOf);                   // дефолтный банк
         const case_11 = ["WALLET"].includes(items?.paymentMethod?.valueOf);                          // [ WALLET ]
         const case_12 = [47].includes(+items?.paymentOrdersSystemId?.valueOf);                       // PAYEER
         const case_13 = [9].includes(+items?.paymentResolution?.valueOf);                            // [ "STATUS_NEED_CONFIRM" ]
         const case_14 = [2].includes(+items?.paymentOrderType?.valueOf);                             // [ 1 => "ВЫВОД" ]
         const case_15 = [10].includes(items?.paymentResolution?.valueOf);                            // [ "STATUS_ERROR" ]
         const case_16 = ["CRYPTO_CURRENCY"].includes(items?.paymentMethod?.valueOf);                 // [КРИПТА]

         // условие 1: если заявка
         // 1) имеет статус "IN_PROCESS",
         // 2) статус "ПОПОЛНЕНИЕ",
         // 3) через "BANK_TRANSFER", "CRYPTO_CURRENCY" в CRM ( Банковский перевод / Криптовалюта)
         const condition_1 = case_1 && case_2 && (case_7 || case_16 && case_6)  && !case_10 && !case_9;
         // (Author Alex Snisar: незакрита через банк (статус "In process", а резолюшен НЕЕЕ один из "STATUS_APPROVED, STATUS_DECLINED, STATUS_CHARGEBACK") - редагування номеру картки дозволене

         const condition_2 = case_1 && case_2 && !case_16 && !case_10 && case_9 && case_8;
         // условие 2: если заявка
         // 1) имеет статус "IN_PROCESS",
         // 2) статус "ПОПОЛНЕНИЕ",
         // 3) через "WALLET" ( Кошелек)
         // 4) Созданная в СРМ
         // const condition_2 = case_1 && case_2 && case_6;
         //(Author Alex Snisar: значение null (карты нет) либо значение из базы данных пришло и оно равно прочерку/минусу - тоггда и это поле должно быть доступным для изменения

         // WALLET ПОПОЛНЕНИЕ
         // (Author Alex Snisar: перевідкрита через банк (статус "In process", а резолюшен один из "STATUS_APPROVED, STATUS_DECLINED, STATUS_CHARGEBACK"):
         // - редагування номеру картки дозволене один раз на alphanumeric номер якщо його немає або прочерк
         const condition_3 = case_1 && case_2 && !case_16 && case_12 && (case_13 || case_15) && (case_8 || case_12);

         if (condition_1 || condition_2 || condition_3 && !case_14 && !case_10) {
            const checkIsUsedCwb = async (value) => {
               if (case_2) {
                  this.state.alreadyUsedCwb.map(async (item) => {
                     await this.save({ isUsedCwb: null });
                     if (item.name === value) {
                        await this.save({ isUsedCwb: item.id, });
                     }
                  });
               }
            };

            const options = {
               onChange: async ({ value }) => {
                  if (case_7 || case_11 || case_16) { // если Банковский перевод ИЛИ WALLET ИЛИ КРИПТА - то делаем валидацию
                     checkIsUsedCwb(value);

                     await this.save(state => {
                        const maxLength = case_16 ? 100 : 34;
                        const regexpString = `^[a-zA-Z0-9]{0,${maxLength}}$`;
                        const regExp = new RegExp(regexpString);
                        return {
                           editField: {
                              ...state.editField,
                              value: !regExp.test(value) ? state.editField?.value : value.trim().toUpperCase(),
                           }
                        }
                     })
                  } else {
                     // если нет не делаем валидацию
                     await this.save(state => ({ editField: { ...state.editField, value: value.trim().toUpperCase(), }}));
                  }
               },
               value: this.state.editField?.value,
               selectionStart: this.state.editField?.value?.length ?? 0,
               selectionEnd: this.state.editField?.value?.length ?? 0,
            };

            return (
               this.renderEditBlock({
                  field: 'cwb',
                  value: ["-"].includes(cwb) ? "" : cwb,
                  valueToLabel: cwb,
                  children: <MagicTextarea {...options} />,
                  onAccept: () => {
                     // БАНКОВСКИЙ ПЕРЕВОД, ЛК, payment_card_id === null ИЛИ кошелёк PAYEER + заявка зи ЛК или СРМ, payment_card_id === null
                     ((case_5 || case_6) && case_7 && [null].includes(items?.paymentCardId?.valueOf) && this.state.isUsedCwb === null)
                      || ((case_5 || case_6) && case_11 && case_12 && case_8) && this.state.isUsedCwb === null
                        ?  this._createCard(
                           items?.paymentClientId?.valueOf,
                           this.state?.editField?.value,
                           items?.paymentOrdersSystemId?.valueOf,
                           items?.paymentOrderId?.valueOf)
                           :
                           (this.state.isUsedCwb ?
                              this._editPayment(items?.paymentOrderId?.valueOf, 'payment_card_id', this.state?.isUsedCwb)
                              :
                              this._editCWB(
                                 items?.paymentCardId?.valueOf,
                                 items?.paymentOrderId?.valueOf,
                                 items?.paymentOrderStatus?.valueOf,
                                 this.state?.editField?.value
                              )
                           )
                  },
                  onReject: () => this.closeConfirm(),
               })
            )
         } else {
            const options = {
               content: cwb,
               lengthCut: 20,
            };
            return (<MagicTooltip {...options} />);
         }
      }
      return this.renderPaymentText(cwb, {item});
   };

   renderPaymentDescription = (description, {item, items}) => {
      if (item?.access('show', 'update')) {
         const case_1 = +items?.paymentStatus?.valueOf === 2;                                         // [ 2 => "IN_PROCESS" ]
         const case_2 = +items?.paymentOrderType?.valueOf === 1;                                      // [ 1 => "ПОПОЛНЕНИЕ" ]
         const case_3 = +items?.paymentOrderType?.valueOf === 2;                                      // [ 2 => "ВЫВОД" ]
         const case_4 = ["CRYPTO_CURRENCY"].includes(items?.paymentMethod?.valueOf); // [БАНКОВСКИЙ ПЕРЕВОД ИЛИ КРИПТА]
         const case_5 = ["WALLET"].includes(items?.paymentMethod?.valueOf);                           // [КОШЕЛЕК]
         const case_6 = items?.paymentOrderSource.valueOf === "CRM";                                  // [ заявка, созданная через CRM ]

         // условие 1: если заявка
         // 1) имеет статус "IN_PROCESS",
         // 2) статус "ВЫВОД",
         // 3) Через "CRYPTO_CURRENCY" ( Криптовалюта)
         const condition_1 = case_1 && case_3 && case_4;

         // условие 2: если заявка
         // 1) имеет статус "IN_PROCESS",
         // 2) статус "ПОПОЛНЕНИЕ",
         // 3) Через "CRYPTO_CURRENCY" ( Криптовалюта)
         const condition_2 = case_1 && case_2 && case_4;

         // условие 3: если заявка
         // 1) имеет статус "IN_PROCESS",
         // 2) статус "ПОПОЛНЕНИЕ",
         // 3) через "WALLET" ( Кошелек )
         // 4) созданная через CRM
         const condition_3 = case_1 && case_2 && case_5 && case_6;

         if ( condition_1 || condition_2 || condition_3 ) {
            const onValidCondition = ["CRYPTO_CURRENCY", "BANK_TRANSFER"].includes(items?.paymentMethod?.valueOf);
            const options = {
               onChange: async ({value}) => {
                  // if ( onValidCondition ) {
                     //если Криптовалюта или Банковский перевод, то делаем валидацию

                  //    await this.save(state => {
                  //       const regExp = /^[a-zA-Z0-9]{0,100}$/g;
                  //       return {
                  //          editField: {
                  //             ...state.editField,
                  //             value: !regExp.test(value) ? state.editField?.value : value
                  //          }
                  //       }
                  //    })
                  // } else {

                     //если нет не делаем валидацию
                     await this.save(state => ({editField: {...state.editField, value}}))
                  // }
               },
               value: this.state.editField?.value,
               selectionStart: this.state.editField?.value?.length ?? 0,
               selectionEnd: this.state.editField?.value?.length ?? 0,
            };
            const {field, value} = this.state?.editField;
            const orderId = items?.paymentOrderId?.valueOf;
            return (
               this.renderEditBlock({
                  field: 'description',
                  value: description,
                  children: <MagicTextarea {...options} />,
                  onAccept: () => this._editPayment(orderId, field, value),
                  onReject: () => this.closeConfirm(),
               })
            )
         } else {
            const options = {
               content: description,
               lengthCut: 20,
            };
            return (<MagicTooltip {...options} />);
         }
      }
      return this.renderPaymentText(description, {item});
   };

   renderPaymentStatus = (status, { item, items }) => {
      const allStatuses = this.props.enums?.payment?.TransactionStatusEnum ?? {};
      const indexShowPermissions = item?.access('index', 'show');
      const indexShowUpdateStorePermissions = item?.access('index', 'show', 'update', 'store');

      if (indexShowPermissions || indexShowUpdateStorePermissions) {
         const case_1 = +items?.paymentStatus?.valueOf === 3;                      // [ 3 => "CLOSED" ]
         const case_2 = +items?.paymentOrderType?.valueOf === 1;                   // [ 1 => "ПОПОЛНЕНИЕ" ]
         const case_3 = [1, 2, 5].includes(+items?.paymentResolution?.valueOf);    //[ "STATUS_APPROVED", "STATUS_DECLINED", "STATUS_CHARGEBACK" ]
         const case_4 = items?.paymentWasAlreadyClosed.valueOf;
         const case_5 = +items?.paymentStatus?.valueOf === 2;                      // [ 2 => "IN_PROCESS" ]
         // const case_6 = items?.paymentOrderSource?.valueOf === "PO";            // созданная в ЛК
         const case_7 = items?.paymentOrderSource?.valueOf === "CRM";              // созданная в CRM
         const case_8 = ["CARD"].includes(items?.paymentMethod?.valueOf);
         const case_9 = ["WALLET"].includes(items?.paymentMethod?.valueOf);
         const case_10 = ["BANK_TRANSFER"].includes(items?.paymentMethod?.valueOf);
         const case_11 = items?.paymentHasInvoice?.valueOf;

         // общее условие
         // 1) ПОПОЛНЕНИЕ
         // 2) имеет решение  "STATUS_APPROVED", "STATUS_DECLINED", "STATUS_CHARGEBACK"
         // 3) через "CARD", "WALLET", "BANK_TRANSFER" ( Карта, Кошелек, Банковский перевод ), если карточка и кошелек то CRM
         const condition_1 = case_2 && case_3 && (case_10 || (case_8 && case_7) || (case_9 && case_7));

         // условие 2: если заявка
         // 1) имеет статус "CLOSED",
         const condition_2 = condition_1 && case_1;

         // условие 2: если заявка
         // 1) имеет статус "IN_PROCESS",
         // 5) заявка раньше была закрыта / и есть валидный инвойс
         const condition_3 = condition_1 && case_5 && (case_11 || (case_9 && !case_11));

         if (condition_2 || condition_3) {
            const [CLOSED, IN_PROCESS] = [3, 2];
            const values = case_1 ? [CLOSED, IN_PROCESS] : [IN_PROCESS, CLOSED];
            const options = {
               values,
               valueToLabel: value => allStatuses[value],
               ItemProps: value => value === values[0] ? {disabled: true,} : {},
               onChange: value => this.setState(state => ({editField: {...state.editField, value,}})),
               value: +this.state.editField?.value,
            };
            const {field, value} = this.state?.editField;
            const orderId = items?.paymentOrderId?.valueOf;
            return (
               this.renderEditBlock({
                  field: 'status',
                  value: status,
                  valueToLabel: allStatuses?.[status],
                  children: <MagicSelect {...options}/>,
                  onAccept: () => this._editPayment(orderId, field, value),
                  onReject: () => this.closeConfirm(),
               })
            )
         } else {
            const options = {
               content: allStatuses?.[status],
               lengthCut: 20,
            };
            return (<MagicTooltip {...options} />);
         }
      }
      if ( !items?.paymentWasAlreadyClosed?.valueOf ) {
         return this.renderPaymentText(allStatuses?.[status], {item});
      }
   };

   renderPaymentManuallyClosing = (manually_closing, { item, items }) => {
      const indexShowPermissions = item?.access('index', 'show');
      const indexShowUpdateStorePermissions = item?.access('index', 'show', 'update', 'store');

      if (indexShowPermissions || indexShowUpdateStorePermissions) {
         const orderManuallyClosedEnum = this.props?.enums?.payment?.OrderManuallyClosedEnum ?? {};
         const {
            paymentOrderId,
            paymentMethod,
            paymentStatus,
            paymentResolution,
            paymentOrderSource,
            paymentGateway,
            paymentIsSended
         } = items;

         const manuallyClosingWasSelected = this.state.manuallyClosingUpdatedValue[paymentOrderId.valueOf] === 1;

         const case_1 = ["PO"].includes(paymentOrderSource?.valueOf);          // PO
         const case_2 = ["CARD"].includes(paymentMethod?.valueOf);             // CARD
         const case_3 = [9].includes(paymentResolution?.valueOf);              // Resolution: STATUS_NEED_CONFIRM
         const case_4 = [1].includes(paymentResolution?.valueOf);              // Resolution: APPROVED
         const case_5 = [2].includes(paymentResolution?.valueOf);              // Resolution: DECLINED
         const case_6 = [5].includes(paymentResolution?.valueOf);              // Resolution: CHARGEBACK
         const case_7 = [10].includes(paymentResolution?.valueOf);             // Resolution: ERROR
         const case_8 = +paymentStatus?.valueOf === 3;                         // Status: Closed
         const case_9 = +paymentStatus?.valueOf === 2;                         // Status: In Process

         //SHARPAY CASES
         const case_10 = paymentGateway.valueOf === "SharPay"; //Payment gateway: Sharpay
         const case_11 = +paymentStatus.valueOf === 1; //Status: Open
         const case_12 = paymentMethod?.valueOf === "WALLET"; //Payment method: WALLET
         const case_13 = paymentMethod?.valueOf === "CRYPTO_CURRENCY"; //Payment method: CRYPTO
         const case_14 = paymentResolution?.valueOf === 0; //Resolution: WAIT
         const case_15 = paymentIsSended?.valueOf === false; //Order wasn't send

         // PO,
         // CARD,
         const condition_1 = case_1 && case_2;

         //PO,
         //SHARPAY
         //Payment method: CARD or WALLET or CRYPTO
         //Order wasn't send
         const condition_2 = case_1 && case_10 && (case_2 || case_12 || case_13) && case_15;

         // PO,
         // CARD without SHARPAY or SHARPAY and CARD or WALLET or CRYPTO and Order wasn't send,
         // Status: Closed OR In process,
         // Resolution: Approved OR Declined OR Chargeback,
         const condition_3 = (condition_1 || condition_2) && ((case_8 || case_9) && (case_4 || case_5 || case_6));

         // PO,
         // CARD,
         // Status: In process,
         // Resolution: Need confirm OR Error,
         const condition_4 = condition_1 && case_9 && (case_3 || case_7);

         // Status: Open,
         // Resolution: Wait,
         const condition_5 = case_11 && case_14;

         // Status: In process,
         // Resolution: Need confirm,
         const condition_6 = case_9 && case_3;

         // PO,
         // SHARPAY
         // Payment method: CARD or WALLET or CRYPTO
         // Status: Open,
         // Resolution: Wait,
         // OR
         // Status: In process,
         // Resolution: Need confirm,
         const condition_7 = condition_2 && (condition_5 || condition_6);

         // orderManuallyClosedEnum[1] – Custom closing,
         // orderManuallyClosedEnum[2] – Standard closing,
         const value = manually_closing === 1
             ? orderManuallyClosedEnum[1]
             : manually_closing === 2
                 ? orderManuallyClosedEnum[2]
                 : '-';

         if (condition_3) {
            const options = {
               className: indexShowUpdateStorePermissions ? `max-content-payments-table magic-switch magic-switch--inline` : `max-content-payments-table magic-switch magic-switch--inline disabled-magic-payment-switch`,
               classNameText: `magic-switch__text`,
               direction: `column-reverse`,
               indexNone: false,
               isDisabled: true,
               index: manually_closing === 1 ? 0 : -1,
               reverse: false,
               texts: [value],
               values: [value],
               updateStateFromProps: true,
            }
            return value !== '-' ? <MagicRadioSwitch { ...options } /> : <MagicTooltip content={[value]} lengthCut={20} />;
         } else if (condition_4 || condition_7) {
            const value = this.state?.manuallyClosingUpdatedValue?.[paymentOrderId.valueOf] ?? manually_closing ?? 2;
            const text = { 1: orderManuallyClosedEnum[1], 2: orderManuallyClosedEnum[2], }[value];

            const options = {
               className: indexShowUpdateStorePermissions ? `max-content-payments-table magic-switch magic-switch--inline` : `max-content-payments-table magic-switch magic-switch--inline disabled-magic-payment-switch`,
               reverse: false,
               isDisabled: !indexShowUpdateStorePermissions,
               index: !indexShowUpdateStorePermissions ? -1 : manuallyClosingWasSelected ? 1 : 0,
               values: [2, 1],
               text: indexShowUpdateStorePermissions ? [text] : '-',
               onChange: indexShowUpdateStorePermissions ? ({ value }) => this.onChangeManuallyClosing(value, paymentOrderId.valueOf) : null,
            }
            return <MagicSwitch { ...options } />
         } else {
            return '-';
         }
      }
   };

   renderPaymentInvoice = (id, { item, items }) => {
      const case_1 = [1].includes(+items?.paymentOrderType?.valueOf);                        // [ПОПОЛНЕНИЕ]
      const case_2 = ["BANK_TRANSFER"].includes(items?.paymentMethod?.valueOf);              // [БАНКОВСКИЙ ПЕРЕВОД]
      const case_3 = [49, 50].includes(+items?.paymentOrdersSystemId?.valueOf);              // [БАНКОВСКАЯ СИСТЕМА - Bank USD, Bank EUR]
      const case_4 = case_1 ? true : items?.paymentOrdersSystemId?.valueOf === 36;           // Если вывод, то только банк [JSC Bank VTB]
      const case_5 = +items?.paymentStatus?.valueOf === 2;                                   // STATUS: IN PROCESS
      const case_6 = +items?.paymentResolution.valueOf === 9;                                // RESOLUTION: NEED CONFIRM
      const invalidateInvoicePermission = PermissionService.calc([
         {
            path: ['payment', 'orders'],
            key: 'paymentOrders',
         }
      ]);

      const indexShowPermissions = item?.access('index', 'show');
      const indexShowUpdateStorePermissions = item?.access('index', 'show', 'update', 'store');

      if ((indexShowPermissions || indexShowUpdateStorePermissions) && case_4 && case_2 && !case_3) {
         const hasInvoice = items?.paymentHasInvoice?.valueOf;
         const saveFileToDisk = async (item, fileName = 'invoice') => {
            if ( window.navigator.msSaveBlob ) {
               window.navigator.msSaveOrOpenBlob(item, fileName);
            } else {
               let link = document.createElement('a');
               link.setAttribute("download", fileName);
               link.href = URL.createObjectURL(item)
               let evt = document.createEvent("MouseEvents");
               evt.initEvent("click", false, true);
               link.dispatchEvent(evt);
            }
         };

         const downloadAndSaveFile = async (url) => {
            const response = await securedRequest.get(url,{responseType: 'blob'});
            await saveFileToDisk(response.data, response?.headers?.filename);
         };

         const downloadInvoiceFile = async () => {
            try {
               const url = `${GET_PAYMENT_ORDERS}/${id}/invoice`;
               await downloadAndSaveFile(url);
            } catch (error) {
               console.log(`Invoice do not download: ${error}`);
            }
         };

         const invalidateInvoice = async (id) => {
            if (case_1 && case_2 && !case_3 && case_5 && case_6) {
               try {
                  await this.save({ ...this.state, disabledInvoiceBtn: true, });
                  await paymentService.invalidateInvoice(id).then(async (res) => {
                     await this.save(({ paymentOrder: pO }) => ({
                        paymentOrder: {
                           data: [ { core: { ...pO.data[0].core }, payment: { ...pO.data[0].payment, orders: res.data, } }]
                        }
                     }))
                  });
                  await this.save({ ...this.state, disabledInvoiceBtn: false, });
               } catch (e) {
                  console.log(e)
               }
            }
         };

         let downloadInvoiceOptions = {
            onClick: () => hasInvoice ? downloadInvoiceFile() : this._createInvoice(id),
            disabled: this.state.disabledInvoiceBtn,
            className: this.state.disabledInvoiceBtn ? "invoice__download-disabled" : hasInvoice ? "invoice__download" : "invoice__generate",
            children: hasInvoice ? "" : this.props.translate("payment_table_generate_invoice"),
         };

         let createInvoiceOptions = {
            onClick: () => this._createInvoice(id),
            disabled: this.state.disabledInvoiceBtn,
            className: this.state.disabledInvoiceBtn ? "invoice__generate-disabled" : "invoice__generate",
            children: this.props.translate("payment_table_generate_invoice"),
         };

         let invalidateOptions = {
            onClick: () => invalidateInvoice(id),
            disabled: !case_5 && !case_6,
            className: (case_5 && case_6) ? "invoice__generate" : "invoice__invalidate-disabled",
            children: this.props.translate("payment_table_invalidate_invoice")
         };

         return (
            <>
               { hasInvoice
               ? ( <>
                     {case_1 && case_2 && !case_3 && invalidateInvoicePermission?.paymentOrders?.access('invalidateInvoice') ? <MagicButton { ...invalidateOptions } /> : null}
                     <MagicButton { ...downloadInvoiceOptions } />
                  </> )
               : (indexShowUpdateStorePermissions && <MagicButton { ...createInvoiceOptions } />)
               }
            </>
         )
      }
      return "-"
   };

   renderClientAgreement = (id, {item, items}) => {
       if (items?.paymentClientAgreementPermission?.access('generateClientAgreement')) {
           const saveFileToDisk = async (item, fileName = 'client_agreement.pdf') => {
               if ( window.navigator.msSaveBlob ) {
                   window.navigator.msSaveOrOpenBlob(item, fileName);
               } else {
                   let link = document.createElement('a');
                   link.setAttribute("download", fileName);
                   link.href = URL.createObjectURL(item)
                   let evt = document.createEvent("MouseEvents");
                   evt.initEvent("click", false, true);
                   link.dispatchEvent(evt);
               }
           };
           const downloadAndSaveFile = async (url) => {
               const response = await securedRequest.get(url,{responseType: 'blob'});
               await saveFileToDisk(response.data, JSON.parse(response?.headers?.filename));
           };
           const downloadClientAgreementFile = async () => {
               try {
                   await this.save({ disabledAggreementBtn: true });
                   const url = `${GET_PAYMENT_ORDERS}/${id}/client_agreement`;
                   await downloadAndSaveFile(url);
                   await this.save({ disabledAggreementBtn: false });
               } catch (error) {
                   console.log(`Client agreement don't download: ${error}`);
                   await this.save({ disabledAggreementBtn: false });
               }
           };
           let options = {
               onClick: () => downloadClientAgreementFile(),
               disabled: this.state.disabledAggreementBtn,
               className: this.state.disabledAggreementBtn ? "invoice__generate-disabled" : "invoice__generate",
               children: this.props.translate("payment_table_generate_client_agreement"),
           };
           return <MagicButton {...options} />
       }
       return "-"
   };

   renderPaymentResolution = (resolution, {item, items}) => {
      const allResolutions = this.props?.enums?.payment?.OrderResolutionEnum ?? {};
      const orderId = this.props.params.orderId;
      const { manuallyClosingUpdatedValue, } = this.state;

      if (item?.access('index', 'update')) {
         const {
            paymentOrderId,
            paymentSystem,
            paymentStatus,
            paymentMethod,
            paymentOrderType,
            paymentCWB,
            paymentDescription,
            paymentOrderSource,
            paymentIsSended,
            paymentHasInvoice,
            paymentOrdersSystemId,
            paymentGateway,
         } = items;
         // Общие условия
         // 1) имеет статус "IN_PROCESS"
         // 2) имеет заполненое поле "Платежная система"
         // 3) раньше не закрывалась
         const case_1 = +paymentStatus?.valueOf === 2;
         const case_2 = paymentSystem?.valueOf?.length > 0;
         const case_3 = paymentHasInvoice?.valueOf;
         const condition_1 = case_1 && case_2;
         const case_4 = [1].includes(+paymentOrderType?.valueOf);                                 // [ 1 => "ПОПОЛНЕНИЕ" ]
         const case_5 = [2].includes(+paymentOrderType?.valueOf);                                 // [ 2 => "ВЫВОД" ]
         const case_6 = ["PO"].includes(paymentOrderSource?.valueOf);                             // созданная в ЛК
         const case_7 = ["CRM"].includes(paymentOrderSource?.valueOf);                            // созданная в CRM
         const case_8 = ["BANK_TRANSFER"].includes(paymentMethod?.valueOf);                       // через BANK_TRANSFER
         const case_9 = ["WALLET"].includes(paymentMethod?.valueOf);                              // через WALLET
         const case_10 = ["CRYPTO_CURRENCY"].includes(paymentMethod?.valueOf);                    // через CRYPTO_CURRENCY
         const case_11 = paymentCWB?.valueOf?.length > 0 && paymentCWB?.valueOf !== "-";          // заполнено поле CWB
         const case_12 = paymentDescription?.valueOf?.length > 0;                                 // Заполнено поле Назначение платежа
         const case_13 = ["CARD"].includes(paymentMethod?.valueOf);                               // через CARD
         const case_14 = paymentHasInvoice?.valueOf;                                              // Invoice
         const case_15 = [1, 2, 5].includes(+resolution);                                         // [ "STATUS_APPROVED", "STATUS_DECLINED", "STATUS_CHARGEBACK" ]
         const case_16 = [47].includes(+paymentOrdersSystemId?.valueOf);                          // PAYEER
         const case_17 = [1].includes(+resolution);                                               // [ "STATUS_APPROVED" ]
         const case_18 = ["Payeer"].includes(paymentSystem.valueOf);
         const case_19 = [49, 50].includes(+items?.paymentOrdersSystemId?.valueOf);

         //SHARPAY CASES
         const case_20 = paymentGateway.valueOf === "SharPay";                                    //Sharpay
         const case_21 = +resolution === 0;                                                       //Resolution: WAIT
         const case_22 = +paymentStatus?.valueOf === 1;                                           //Status: Open
         const case_23 = manuallyClosingUpdatedValue[orderId] === 1;                              //Is manually closing
         const case_24 = +items.paymentManuallyClosing.valueOf === 1;                             // Manually closing in db
         const case_25 = +resolution === 9;                                                       //Resolution: Need confirm
         const case_26 = [null, "", "SYSTEM_DEPOSIT"].includes(items?.paymentMethod?.valueOf)     // Через Системный депозит - if empty we suppose it's system operation
         const case_27 = [null, "", "SYSTEM_WITHDRAWAL"].includes(items?.paymentMethod?.valueOf)  // Через Системный вывод - if empty we suppose it's system operation
         const case_28 = paymentIsSended?.valueOf === false;                                      // Payment wasn't sent

         // ВЫВОД "BANK_TRANSFER", "WALLET", "CARD", "CRYPTO_CURRENCY"
         // 1) "ВЫВОД"
         // 2) заполнено поле Номер банк.счета (BANK_TRANSFER),
         //                   Номер кошелька (WALLET), номер карты (CARD),
         //                   номер криптокошелька (CRYPTO_CURRENCY)
         const condition_2 = condition_1 && case_5 && case_11;
         // ПОПОЛНЕНИЕ "WALLET", "CARD"
         // 1) "ПОПОЛНЕНИЕ"
         // 2) через "WALLET"
         // 3) заполнено поле Номер кошелька(WALLET), номер карты (CARD)
         // 4) создана в CRM (WALLET), создана в ЛК (CARD)
         const condition_3 = condition_1 && (case_4 || case_5) && ((case_7 && case_9) || (case_6 && case_13)) && case_11 && !case_17 && !case_15;
         // ПОПОЛНЕНИЕ "BANK_TRANSFER","CRYPTO_CURRENCY"
         // 1) "ПОПОЛНЕНИЕ"
         // 2) через "BANK_TRANSFER" + заполнено поле номер банк. счета и есть инвойс
         // 3) через "CRYPTO_CURRENCY" + назначение платежа
         const BT_valid = case_8 && case_14 && !case_15;
         const CC_valid = case_10 && case_11 && case_12;

         const condition_4 = condition_1 && case_4 && (BT_valid || CC_valid);
         const condition_5 = paymentIsSended?.valueOf;                                       // отправка на FlexiGate
         // const condition_6 = condition_1 && case_4 && case_6 && case_16;
         const condition_7 = condition_1 && case_5 && case_9 && case_16 && (case_6 || case_7) && case_11;

         // 1) IN PROCESS
         // 2) ПОПОЛНЕНИЕ
         // 3) ЛК или ЦРМ
         // 4) WALLET
         // 5) PAYEER
         // 6) Заполнено ли поле CWB
         // 7) Переоткрыта заявка
         const condition_8 = case_1 && case_4 && (case_6 || case_7) && case_9 && case_16 && case_11 && !case_17 && !case_15;

         const condition_9 = case_1 && case_4 && case_9 && !case_15 && case_11;

         const condition_10 = case_1 && case_4 && case_8 && case_14 && !case_15 && !case_19;

         //PO and WALLET or Card, or just just Crypto
         //SHARPAY
         //STATUS: OPEN
         //RESOLUTION: WAIT
         //Manually closing
         //Order wasn't sent
         const condition_11 = ((case_6 && (case_9 || case_13)) || case_10) && case_20 && case_21 && case_22 && (case_23 || case_24) && case_28;

         //PO and WALLET or Card, or just just Crypto
         //SHARPAY
         //RESOLUTION: Need confirm
         //STATUS: In process
         //Order wasn't sent
         const condition_12 = ((case_6 && (case_9 || case_13)) || case_10) && case_20 && case_25 && case_1 && case_28;

         //Withdrawal
         //Sharpay and WALLET or CRYPTO or CARD
         //or
         //BankTransfer or System
         const condition_13 = case_5 && ((case_20 && (case_9 || case_10 || case_13)) || (case_8 || case_27));

         //RESOLUTION: Need confirm
         //STATUS: In process
         //Method (BANK_TRANSFER or (SYSTEM_DEPOSIT or SYSTEM_WITHDRAWAL created in CRM))
         const condition_14 = case_1 && case_25 && (case_8 || (case_7 && case_26 || case_27))


         const values = () => {
            const STATUS_APPROVED = 1;
            const STATUS_DECLINED = 2;
            const STATUS_CHARGEBACK = 5;
            let values = [];
            if (condition_2 || condition_13) {
               values = [resolution, STATUS_APPROVED, STATUS_CHARGEBACK]
            } else if (!case_5 || condition_3 || condition_4) {
               values =  [resolution, STATUS_APPROVED, STATUS_DECLINED];
            }
            return values;
         };

         if (condition_5 || this.state?.sendResolutionRequest?.[paymentOrderId?.valueOf]) {
            const options = {
               content: this.props.translate("payment_table_send_request"),
               lengthCut: 20,
            };
            return (<MagicTooltip { ...options } />);
         } else if (condition_2 || condition_3 || condition_4 || condition_7 || condition_8 || condition_9 || condition_10 || condition_11 || condition_12 || condition_14) {
            let options = {
               values: values(),
               ItemProps: value => value === resolution ? { disabled: true, } : {},
               value: +this.state.editField?.value,
               onChange: value => this.setState(state => ({
                  editField: { ...state.editField, value: +value },
               })),
               valueToLabel: value => allResolutions?.[value === resolution ? resolution : value],
            };
            return (
               this.renderEditBlock({
                  field: 'resolution',
                  value: resolution,
                  valueToLabel: allResolutions?.[resolution],
                  children: <MagicSelect {...options}/>,
                  onAccept: async () => {
                     try {
                        (case_8 || case_9 || case_16 || case_13) && await this.onChangeResolution(true, paymentOrderId.valueOf)

                        const id = paymentOrderId?.valueOf;
                        const options = {
                           resolution: +this.state.editField?.value,
                           ...this.state.manuallyClosingUpdatedValue?.[paymentOrderId?.valueOf] === 1 && (+this.state.editField?.value === 1 || case_20) && {
                              manually_closing: 1,
                           }
                         };

                        const path = this.urlPathOfTheOrder;
                        const response = await paymentService.editPaymentPatch(path, options);

                        response?.data?.[0] === false && await this.onChangeResolution(true, paymentOrderId.valueOf)

                        case_16 && !this.state.sendFlexiGate && await this._getData();
                        case_18 && await this._getData();

                        if (case_6 && (case_4 || case_5) && (case_9 || case_13)) {
                           // если ПОПОЛНЕНИЕ или ВЫВОД в ЛК через Карточку или Кошелек то меняем в состоянии поле sendFlexiGate
                           // (селект меняется на поле "ЗАПРОС ОТПРАВЛЕН")
                           await this.save({ sendFlexiGate: id, });
                           await this.setFlexiGate?.(paymentOrderId?.valueOf);
                           ("resolution" in options && response?.data?.[0] === false) && NotificationService?.error?.({
                              title: "error",
                              message: this.props.translate(`payment_table_flexi_error`),
                              remove: false,
                           });
                           response?.data?.[0] === false && await this.onChangeResolution(false, paymentOrderId.valueOf)
                        } else {
                           await this._getData();
                        }

                        case_8 || case_9 || case_10 || case_15 || case_16 && await this._getData();

                        this.state.manuallyClosingUpdatedValue?.[paymentOrderId?.valueOf] === 1
                        && +this.state.editField?.value === 1
                        ?  await this._getData()
                        : null;

                     } catch (error) {
                        NotificationService?.errors?.({ ...error?.response?.data , remove: false});
                     } finally {
                        await this.clearResolution?.();
                     }
                  },
                  onReject: () => this.closeConfirm(),
               }))
         }
      }
      return this.renderPaymentText(allResolutions?.[resolution], { item, });
   };

   renderPaymentWithdrawal = (message, {item, items}) => {
      if (item?.access('show', 'update')) {
         const case_1 = [2].includes(+items?.paymentStatus?.valueOf);         // [ 2 => "IN_PROCESS" ]
         const case_2 = [2].includes(+items?.paymentOrderType?.valueOf);      // [ 1 => "ВЫВОД" ]
         // Если заявка
         // 1) имеет статус "IN_PROCESS"
         // 2) на "ВЫВОД"
         const condition_1 = case_1 && case_2;
         if (condition_1 && !message) {
            const { field, value } = this.state?.editField;
            const orderId = items?.paymentOrderId?.valueOf;
            const options = {
               onChange: async ({value}) => await this.save(state => ({editField: {...state?.editField, value,}})),
               value: this.state?.editField?.value,
               selectionStart: this.state?.editField?.value?.length ?? 0,
               selectionEnd: this.state?.editField?.value?.length ?? 0,
            };
            return (
               this.renderEditBlock({
                  field: 'withdrawal_reason_message',
                  value: message,
                  children: <MagicTextarea {...options}/>,
                  onAccept: () => this._editPayment(orderId, field, value),
                  onReject: () => this.closeConfirm(),
               })
            )
         }
      }
      return this.renderPaymentText(message, {item});
   };

   render() {
      const {loaded,} = this.state;
      const {translate,} = this.props;
      const data = PermissionService.cast(this.state.paymentOrder?.data?.[0], this.paymentConfig);
      const columnLeft = [
         ["payment_table_create_at", "paymentCreateAt"],
         ["payment_table_enrollment_date", "paymentDateEnrollment"],
         ["payment_table_order_id", "paymentOrderId", "type"],
         ["payment_table_system_account_id", "paymentSystemId"],
         ["payment_table_client", "paymentClientFullname"],
         ["payment_table_responsible", "paymentResponsible"],
         ["payment_table_currency", "paymentCurrency"],
         ["payment_table_amount", "paymentAmount"],
         ["payment_table_fee", "paymentFee"],
         ["payment_table_amount_paid", "paymentAmountPaid"],
         ["payment_table_payment_method", "paymentMethod"],
         ["payment_table_payment_system", "paymentSystem"],
         ["payment_table_bank_credentials", "bankCredentials"]

      ];
      const columnRight = [
         ["payment_table_cwb", "paymentCWB"],
         ["payment_table_platform", "paymentSystemPlatformName"],
         ["payment_table_card_holder", "paymentCardHolder"],
         ["payment_table_description", "paymentDescription"],
         ["payment_table_date_paid", "paymentDatePaid"],
         ["payment_table_status", "paymentOrderStatus"],
         ["payment_table_manually_closing", "paymentManuallyClosing"],
         ["payment_table_invoice", "paymentInvoice"],
         ["payment_table_client_agreement", "paymentClientAgreement"],
         ["payment_table_resolution", "paymentResolution"],
         ["payment_table_reason_withdrawal", "paymentWithdrawal"],
         ["payment_table_rejection_reason", "paymentRejectionMessage"],
      ];
      return (
         <Loader loaded={loaded} loading={<Preloader scale={1}/>}>
            <div className="one-payment">
               <div className="columns__wrapper">
                  <div className="column left">
                     {columnLeft.map((el, key) => (
                        <div className={`column__field ${el[2] ?? ""}`} key={key}>
                           <div className="column__field_label">{translate(`${el?.[0]}`)}</div>
                           <div className="column__field_description">{data?.[el?.[1]]}</div>
                        </div>
                     ))}
                  </div>
                  <div className="column right">
                     {columnRight.map((el, key) => (
                        <div className={`column__field ${el[2] ?? ""}`} key={key}>
                           <div className="column__field_label">{translate(`${el?.[0]}`)}</div>
                           <div className="column__field_description">{data?.[el?.[1]]}</div>
                        </div>
                     ))}
                  </div>
               </div>
            </div>
         </Loader>
      );
   }
}
export default withLocalize(OnePaymentOrderTable);
