/* eslint-disable sort-keys */
const configHorizontalMenu = (translate, permissions = null) =>
	[
		// System Accounts
		{
			title: translate("list_accounts_system"),
			config: [
				{
					title: translate("list_accounts_system"),
					key: "list_accounts_system",
					path: "/list-accounts/systems",
				},
			],
		},
	  //Trading Accounts
		{
			title: translate('list_accounts_trading'),
			config: [
				{
					title: translate('list_accounts_trading'),
					key: 'list_accounts_trading',
					path: '/list-accounts/tradings',
				},
			],
		},
		//Corporate Actions
		{
			title: translate('list_accounts_corporate_action'),
			config: [
				{
					title: translate('list_accounts_corporate_action'),
					key: 'list_accounts_corporate_action',
					path: '/list-accounts/corporate-action',
				},
			],
		},
		//Balance Operations
		{
			title: translate('balance_operations'),
			config: [
				{
					title: translate('balance_operations'),
					key: 'balance_operations',
					path: '/list-accounts/balance-operations',
				},
			],
		},
	]
		.map(item => ({
			...item,
			config: item.config.filter(({ key, }) => {
				if (permissions) {
					return permissions?.[key]?.access("index") ?? false;
				}

				return true;
			}),
		}))
		.filter(({ config, }) => config.length);

export default configHorizontalMenu;