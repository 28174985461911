import React, { Component, } from "react";
import Loader from "../../tima/components/Loader";
import Preloader from "../../../components/LoadingHOC/Preloader";
import PagePanel from "../../tima/components/Pages";
import propTypes from 'prop-types';
import Moment from "moment";
import { MagicTable, } from "../../../components/Magic";
import { MagicConfirm, } from "../../tima/components/Magic/MagicConfirm";
import { contestService } from "../../../services/ContestDataService";

export class ContestMembers extends Component {

  static propTypes = {
    mf: propTypes.object.isRequired,
    // eslint-disable-next-line sort-keys
    enums: propTypes.shape({
      contests: propTypes.object,
    }),
    id: propTypes.string,
    translate: propTypes.func,
  };

  static defaultProps = {
    enums: {},
    id: "",
    translate: () => {},
  };

  constructor (props) {
    super(props);
    this.state = {
      contestId: props.id,
      disqualifyMemberId: 0,
      filterLastChange: Date.now(),
      loaded: false,
      membersList: {
        hash: null,
        data: [],
      },
      pages: {
        filter: 0,
        take: 50,
        total: 0,
        variance: 2,
      },
    };

    Object.defineProperty(this, 'mf', { get: () => this.props.mf, });
  }

  // eslint-disable-next-line react/sort-comp, require-await
  save = async state => new Promise(next => this.setState(state, next));

  mfChanged = async () => {
    await this.save({ filterLastChange: Date.now(), });
  };

  mfDoing = async () => {
    await this.save({ loaded: false, });
  };

   mfDone = async () => {
     await this.mfChanged();
     await this.onDataLoad();
   };

   membersListChanges = async (membersList) => {
     if (membersList.hash===this.state?.membersList?.hash) return `${this.constructor.name}.membersListChanges: false`;
     const { data = [], meta, hash, } = membersList;
     const { filter = 0, total = 0, } = meta;

     await this.save({
       membersList: { data, hash, },
       pages: {
         ...this.state.pages,
         filter,
         total,
       },
     });
     return `${this.constructor.name}.membersListChanges: true`;
   };

   async componentDidMount () {
     this.mf.subscribe({
       changed: this.mfChanged,
      // changing: ,
       cleaned: this.mfDone,
       cleaning: this.mfDoing,
       loaded: this.mfDone,
       loading: this.mfDoing,
       saved: this.mfDone,
       saving: this.mfDoing,
     }, this);
     await this.mf.init({ doEmit: true, });
    // await this.mf.emit('change', {});
    // await this.onDataLoad(); // TODO: moved to mfDone
   };

   componentWillUnmount () {
     contestService.unsubscribe('membersList', this.membersListChanges, this);
     this.mf.unsubscribe([
       this.mfChanged,
       this.mfDoing,
       this.mfDone,
     ], this);
   };

   get membersConfig () {
     const { translate, } = this.props;

     return [ {
       key: 'memberId',
       path: [ 'contests', 'contests_members', 'id', ],
     }, {
       key: 'memberName',
       path: [ 'core', 'account', 'name', ],
     }, {
       key: 'memberSurname',
       path: [ 'core', 'account', 'surname', ],
     }, {
       key: 'clientId',
       path: [ 'core', 'account', 'id', ],
       render: this.renderMemberPlace,
       title: "contest_list-members_id",
     }, {
       key: 'memberPlace',
       path: [ 'contests', 'contests_members', 'place', ],
       render: this.renderMemberPlace,
       title: "contest_list-members_place",
     }, {
       key: 'memberFullName',
       path: [ 'core', 'account', 'name', ],
       render: this.renderMemberFullName,
       title: "contest_list-members_client",
     }, {
       key: 'memberNickname',
       path: [ 'core', 'account', 'contest_nickname', ],
       render: this.renderMemberText,
       title: "contest_list-members_nickname",
     }, {
       key: 'memberStatus',
       path: [ 'contests', 'contests_members', 'status', ],
       render: this.renderMemberStatus,
       title: "contest_list-members_status",
     }, {
       key: 'memberDemoBalance',
       path: [ 'core', 'trading_accounts', 'mt_login', ],
       render: this.renderMemberText,
       title: "contest_list-members_mt_login",
     }, {
       key: 'memberBalance',
       path: [ 'contests', 'contests_statistic', 'balance', ],
       render: this.renderMemberText,
       title: "contest_list-members_balance",
     }, {
       key: 'memberEquity',
       path: [ 'contests', 'contests_statistic', 'equity', ],
       render: this.renderMemberText,
       title: "contest_list-members_equity",
     }, {
       key: 'memberProfitability',
       path: [ 'contests', 'contests_statistic', 'profitability', ],
       render: this.renderMemberText,
       title: "contest_list-members_profitability",
     }, {
       key: 'memberLoss',
       path: [ 'contests', 'contests_statistic', 'loss', ],
       render: this.renderMemberText,
       title: "contest_list-members_loss",
     }, {
       key: 'memberMaxLoss',
       path: [ 'contests', 'contests_statistic', 'max_loss', ],
       render: this.renderMemberText,
       title: "contest_list-members_max_loss",
     }, {
       key: 'memberCreated',
       path: [ 'contests', 'contests_members', 'created_at', ],
       render: this.renderDate,
       title: "contest_list-members_created_at",
     }, {
       key: 'memberDisqualify',
       path: [ 'contests', 'contests_members', 'status', ],
       render: this.renderMemberDisqualify,
       title: "contest_list-members_disqualify",
     }, ].map(({
       title: t,
       ...data
     }) => ({
       ...data,
       ...t && { title: translate(t), },
     }));
   }

  renderDate = (date, { item, }) => {
    if (item.access('index')) {
      const formatDB = 'YYYY-MM-DD HH:mm:ss';
      const formatOut = 'YYYY-MM-DD HH:mm:ss';

      date = Moment(date, formatDB);

      return date.isValid() ? date.format(formatOut) : "-";
    }

    return '-';
  };

  renderMemberText = (text, { item, }) => {
    if (item.access('show')) {
      return text ?? '-';
    }

    return '-';
  };

  renderMemberPlace = (place, { item, }) => {
    if (item.access('show')) {
      return +place !== -1 ? place : "-";
    }

    return '-';
  };

  renderMemberFullName = (_, { item, items, }) => {
    if (item.access('show')) {
      const name = items?.memberName?.valueOf;
      const surname = items?.memberSurname?.valueOf;

      return `${ name ?? "" } ${ surname ?? "" }`;
    }

    return "-";
  };

  renderMemberStatus = (status, { item, }) => {
    const eunms = this.props?.enums?.contests?.ContestMembersStatusEnum;
    const allStatuses = eunms ?? {};

    if (item.access('index')) {
      return allStatuses?.[+status] ?? "";
    }

    return '-';
  };

  // eslint-disable-next-line object-curly-newline
  renderConfirm = ({ onAccept, onReject, isVisible, translate, }) => {
    const options = {
      accept: translate?.accept,
      isVisible,
      onAccept,
      onReject,
      reject: translate?.reject,
      title: translate?.title,
    };

    return (<MagicConfirm { ...options } />);
  };

  renderMemberDisqualify = (status, { item, items, }) => {
    if (item.access('index')) {
      const disqualified = [ 3, ].includes(+status);
      const dropped = [ 2, ].includes(+status);
      const contestDisabled = [ 2, 5, ].includes(+this.props?.contestStatus);
      const translate = disqualified ? 'disqualified' : 'not-disqualify';
      const checkable = !disqualified && !dropped && !contestDisabled && item.access('update');
      const memberId = items?.memberId?.valueOf;
      const options = {
        className: `list__item 
          ${ disqualified ? 'checked' : '' } 
          ${ contestDisabled || dropped ? 'disable' : '' }`,
        ...checkable && {
          onClick: () => this.setState({
            disqualifyMemberId: memberId,
          }),
        },
      };

      return (
        <div style={ { position: 'relative', } }>
          {this.renderConfirm({
            isVisible: this.state?.disqualifyMemberId === memberId,
            onAccept: () => this.onMemberDisqualify(items?.memberId?.valueOf),
            onReject: () => this.setState({ disqualifyMemberId: 0, }),
            translate: {
              accept: this.props.translate("contests_translation_accept"),
              reject: this.props.translate("contests_translation_reject"),
              title: this.props.translate("contests_disqualify_title"),
            },
          })}
          <span { ...options }>
            {this.props.translate(`contest_list-members_${ translate }`)}
          </span>
        </div>
      );
    }

    return '-';
  };

  onMemberDisqualify = async (id) => {
    try {
      await this.save({ disqualifyMemberId: 0, loaded: false, });
      const DISQUALIFY_STATUS = 3;

      await contestService.disqualifyMember(id, { status: DISQUALIFY_STATUS, });
      await this.onDataLoad();
      await this.save({ loaded: true, });
    } catch (error) {
      error?.showErrorNotification?.();
    }
  };

  renderMembers = () => {
    const options = {
      config: this.membersConfig,
      data: this.state.membersList.data,
      head: [
        "memberPlace",
        "clientId",
        "memberFullName",
        "memberNickname",
        "memberStatus",
        "memberDemoBalance",
        "memberBalance",
        "memberEquity",
        "memberProfitability",
        "memberLoss",
        "memberMaxLoss",
        "memberCreated",
        "memberDisqualify",
      ],
    };

    return (<MagicTable { ...options } />);
  };

  pageId = () => {
    const { query, } = this.props;
    const page = query.get('page');

    return Number(page) || 0;
  };

  pageIdAsGET = (pageId) => {
    pageId = pageId === undefined ? this.pageId() : pageId;

    const { filterId, } = this.mf;
    const result = {
      filterId,
      skip: pageId * this.state.pages.take,
      take: this.state.pages.take,
    };

    return result;
  };

  onDataLoad = async (pageId) => {
    const loaded = (data, meta, hash) => ({ pages, }) => ({
      membersList: { data, hash, },
      pages: { ...pages, ...meta, },
    });

    await this.save({ loaded: false, });
    try {
      const { contestId, } = this.state;
      const pageOptions = this.pageIdAsGET(pageId);
      const response = await contestService.membersList(contestId, pageOptions);
      const { data = [], meta: { filter = 0, total = 0, }, hash, } = response ?? {};

      await this.save(loaded(data, { filter, total, }, hash));
    } catch (error) {
      await this.save(loaded([], { filter: 0, total: 0, }, null));
    }
    await this.save({ loaded: true, });
  };

  onPageClick = async ({ event, pageId, pageIs, }) => {
    // event.preventDefault();
    await this.onDataLoad(pageId);
  };

  onPageText = (pageId, pageIs) => {
    const { translate, } = this.props;
    // eslint-disable-next-line object-curly-newline
    const { current, first, prev, next, last, } = pageIs;
    // eslint-disable-next-line object-curly-newline
    const { skipped, taken, filter, total, } = pageIs;

    if (skipped || taken) {
      const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : '?';
      const text = skipped ? translate('contests_pages_items') : '';

      return skipped ? `${ text }: ${ id }` : id;
    } else if (filter || total) {
      const id = Number.isInteger(pageId) ? `${ pageId }` : '?';
      const text = translate(filter ? 'contests_pages_filtered' : 'contests_pages_total');

      return `${ text }: ${ id }`;
    } else if (first || prev || next || last) {
      return '';
    } else if (current) {
      return `${ pageId + 1 }`;
    }

    return `${ pageId + 1 }`;
  };

  render = () => {
    const { loaded, } = this.state;

    return (
      <div className='content-block'>
        <div className='table-wrapper'>
          <div className='position-relative'>
            <Loader loaded={loaded} loading={(<Preloader scale={1}/>)}>
              <div className='table bordered-rounded one-contest__table'>
                { this.renderMembers() }
              </div>
              <PagePanel
                filter={ this.state?.pages?.filter }
                doText={ this.onPageText }
                doTo={ (pageId) => `?page=${pageId}` }
                onClick={ this.onPageClick }
                page={ this.pageId() }
                take={ this.state?.pages?.take }
                total={ this.state?.pages?.total }
                variance={ this.state?.pages?.variance }
              />
            </Loader>
          </div>
        </div>
      </div>
    );
  };

};
