import React, { Component, } from "react";
import { Link, } from "react-router-dom";
import { withLocalize, } from "react-localize-redux";

import { Input, Select, } from "../../../../../components";
import { MagicDate, } from "../../../../../../../components/Magic";

import { findOptionByValue, } from "../../../../../utils/helpers";

class StaticData extends Component {
	get experienceOptions () {
		const { enums, } = this.props;
		const experienceEnum = enums.partners.PartnersRequestExperienceEnum;

		return experienceEnum.map((experienceTitle, index) => {
			return {
				label: experienceTitle,
				value: index,
			};
		});
	}

	render () {
		const {
			account_fname,
			account_id,
			created_at,
			experience,
			brokers,
			count_ref_per_month,
			allPrograms,
			program_id,
			comment,
			web_resources,
		} = this.props.state;

		const {
			translate,
			countries,
			tools,
			programsAllOptions: programs,
		} = this.props;

		const selectedProgramName = allPrograms
			? allPrograms.find(program => program.partners.programs.id === program_id)
				?.partners.programs.name
			: "";

		const experienceOptions = this.experienceOptions;

		return (
			<div className="view-edit-request__static">
				<div className="view-edit-request__row">
					<Input
						disabled={ true }
						label={ translate("partners_request_client_id") }
						value={ `${ account_id }` }
					/>
				</div>
				<div className="view-edit-request__row">
					{" "}
					{/*workaround for input-like link, due to requirments*/}
					<div className=" input__wrapper">
						<label>{translate("partners_requests_partner_client")}</label>
						<div className="view-edit-request__link-wrapper magic-input input__field">
							<Link
								className={ "view-edit-request__link" }
								to={ `/clients/${ account_id }` }
							>
								{account_fname}
							</Link>
						</div>
					</div>
				</div>
				<div className="view-edit-request__row">
					<div className="input__wrapper">
						<label>
							{translate("partners_requests_partner_application_date")}
						</label>
						<MagicDate
							className={ "view-edit-request__date" }
							disabled={ true }
							format={ "YYYY-MM-DD HH:mm:ss" }
							value={ new Date(created_at) }
							onChange={ () => null }
						/>
					</div>
				</div>
				<div className="view-edit-request__row">
					{/* <div className="input__wrapper">
						<label>
							{translate("partners_requests_partner_affiliate_program")}
						</label>
						<div className="view-edit-request__pargraph-wrapper magic-input input__field custom-scrollbar">
							<p className={"view-edit-request__pargraph"}>{client_name}</p>
						</div>
						<p className="view-edit-request__name">{name}</p>
					</div> */}
					<Select
						disabled={ true }
						label={ translate("partners_requests_partner_affiliate_program") }
						name={ "approved_program_id" }
						options={ programs }
						placeholder={ translate(
							"partners_requests_partner_program_placeholder",
						) }
						value={ findOptionByValue(programs, program_id) }
						onChange={ () => null }
					/>
					<p className="view-edit-request__name">{selectedProgramName}</p>
				</div>
				<div className="view-edit-request__row">
					<div className="input__wrapper">
						<label>{translate("partners_request_plan_to_attract")}</label>
						<div className="view-edit-request__pargraph-wrapper magic-input input__field custom-scrollbar">
							<p className={ "view-edit-request__pargraph" }>{comment}</p>
						</div>
					</div>
				</div>
				<div className="view-edit-request__row">
					<Select
						disabled={ true }
						label={ translate("partners_request_experience") }
						name={ "approved_program_id" }
						options={ experienceOptions }
						placeholder={ translate("partners_request_experience") }
						value={ findOptionByValue(experienceOptions, experience) }
						onChange={ () => null }
					/>
				</div>
				<div className="view-edit-request__row">
					<div className="input__wrapper">
						<label>{translate("partners_request_brokers")}</label>
						<div className="view-edit-request__pargraph-wrapper magic-input input__field custom-scrollbar">
							<p className={ "view-edit-request__pargraph" }>{brokers}</p>
						</div>
					</div>
				</div>
				<div className="view-edit-request__row">
					<div className="input__wrapper">
						<label>{translate("partners_request_tools")}</label>
						<div className="view-edit-request__pargraph-wrapper magic-input input__field custom-scrollbar">
							<p className={ "view-edit-request__pargraph" }>{tools}</p>
						</div>
					</div>
				</div>
				<div className="view-edit-request__row">
					<Input
						disabled={ true }
						label={ translate("partners_request_expected_referals") }
						value={ `${ count_ref_per_month }` }
					/>
				</div>
				<div className="view-edit-request__row">
					<div className="input__wrapper">
						<label>{translate("partners_request_regions")}</label>
						<div className="view-edit-request__pargraph-wrapper magic-input input__field custom-scrollbar">
							<p className={ "view-edit-request__pargraph" }>{countries}</p>
						</div>
					</div>
				</div>
				<div className="view-edit-request__row">
					<div className="input__wrapper">
						<label>{translate("partners_request_links")}</label>
						<div className="view-edit-request__pargraph-wrapper magic-input input__field custom-scrollbar">
							<p className={ "view-edit-request__pargraph" }>{web_resources}</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withLocalize(StaticData);