import React, { Component } from 'react';
import { Row, Col, Table } from 'reactstrap';
import letter from "../../../../../images/letter.svg";
import check from "../../../../../images/icon.svg";
import person from "../../../../../images/person.svg";
import {Scrollbars} from "react-custom-scrollbars";
import {Translate} from "react-localize-redux";
import {get_random_guid} from "../../../../../helpers/OtherHelper";
import background from "../../../../../images/background-interaction.svg";

export default class InteratorLetter extends Component {

    render () {
        const currentUser = this.props.currentUser;

        return (
            <Translate key = { get_random_guid() }>
                {({ translate }) => {
                    return (
                        <div className = 'activeInteraction'>
                            <div className = 'activeInteraction__top'>
                                <Row>
                                    <Col xs = '6'>
                                        <div className = 'activeInteraction__flex flexStart'>
                                            <Table className = 'table-interaction'>
                                                <tbody>
                                                <tr>
                                                    <td className = 'bold'>{translate(`client_type`)}:</td>
                                                    <td>
                                                        <div className = 'activeInteraction__flex_info flexStart'>
                                                            <img
                                                                alt = 'call' src = {letter}
                                                            /> Email
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className = 'bold'>{translate(`client_manager`)}:</td>
                                                    <td>
                                                        <img
                                                            alt = 'person' src = { person }
                                                        /> Ambros Johnson</td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                    <Col xs = '6'>
                                        <div className = 'activeInteraction__flex flexEnd'>
                                            <Table className = 'table-interaction'>
                                                <tbody>
                                                <tr>
                                                    <td className = 'bold'>{translate(`client_created`)}:</td>
                                                    <td>
                                                        <div className = 'activeInteraction__flex_info'>
                                                            <img
                                                                alt = 'check' src = {check}
                                                            /> 8/09/2019, 15:00 pm
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className = 'bold'>{translate(`client_theme`)}:</td>
                                                    <td>
                                                        <div className = 'activeInteraction__flex_info'>
                                                            Летняя Жара
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className = 'bold'>{translate(`client_status`)}:</td>
                                                    <td>
                                                        <div className = 'activeInteraction__flex_info bold'>
                                                            Закрыт
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className = 'activeInteraction__info'>
                                <Row className='activeInteraction__info_top'>
                                        <div className = 'activeInteraction__flex_info flexStart'>
                                            <span className='bold'>{translate(`client_result`)}</span>
                                        </div>
                                        <div className = 'activeInteraction__flex_info flexEnd'>
                                            <span className='grey'>{translate(`client_status`)}</span>
                                            <span className={ `interaction-status ${currentUser['interaction_result_type']}`}>Not successful</span>
                                        </div>
                                </Row>
                                <Scrollbars
                                    style = { { height: 410 } }
                                    renderView={props => <div {...props} className="view"/>}
                                    renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
                                    renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} />}
                                >
                                    <Col className = 'activeInteraction__info_desc'>
                                        <p>
                                            Fiction-writing also has modes: action, exposition,  and transition writing description, dialogue, summary, and transition writing also has modes It is a long established fact  that
                                            a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution
                                        </p>
                                        <p>
                                            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution
                                        </p>
                                        <p>
                                            Fiction-writing also has modes: action, exposition,  and transition writing description,
                                            dialogue, summary, and transition writing also has modes It is a long established fact
                                            that a reader will be distracted by the readable content of a page when looking. Fiction-writing also has modes: action, exposition,  and transition writing description, dialogue, summary, and transition writing also has modes It is a long established fact  that
                                            a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution
                                        </p>
                                        <p>
                                            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution.
                                        </p>
                                        <p>
                                            Fiction-writing also has modes: action, exposition,  and transition writing description,
                                            dialogue, summary, and transition writing also has modes It is a long established fact
                                            that a reader will be distracted by the readable content of a page when looking. Fiction-writing also has modes: action, exposition,  and transition writing description, dialogue, summary, and transition writing also has modes It is a long established fact  that
                                            a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution
                                        </p>
                                        <p>
                                            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution.
                                        </p>
                                    </Col>
                                </Scrollbars>
                            </div>
                            <img className='interaction-bg' src={background} alt="background"/>
                        </div>
                    )}}
            </Translate>

        );
    }
}
