import PagePanel from './PagePanel';
import PageStatus from './PageStatus';
import PageList from './PageList';
import PageItem from './PageItem';
import PageLink from './PageLink';

export default PagePanel;
export {
    PagePanel,
    PageStatus,
    PageList,
    PageItem,
    PageLink,
};
