import React, { Component } from 'react';
import AlertBlock from "./AlertBlock";
import { get_random_guid } from "../../../../helpers/OtherHelper";

export default class AlertList extends Component {
    constructor (props) {
        super(props);
    }

    render () {

        const alerts = this.props.jsonData;

        return (
            alerts.map((item) => {
                return (<AlertBlock alert = { item } key = { get_random_guid() } />);
            })
        );
    }
}
