import React from 'react';
import {
	Nav, NavItem, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledDropdown,
} from 'reactstrap';
import Moment from 'moment';
import fileDownload from "js-file-download";
import { profileService, } from "../../../../services/ProfileDataService";
import PermissionService from "../../../../services/PermissionService";
//images
import connect from "react-redux/es/connect/connect";
import { bindActionCreators, } from "redux";
import { doLogout, } from "../../../admin_panel/users/reducers/user/actions";
import { getSideBar, } from "../../reducers/side_bar/actions";
import { withRouter, Link, } from "react-router-dom";
import { withLocalize, Translate, } from "react-localize-redux";
import NodeService from "../../../../services/NodeService";
import NotificationService from "../../../../services/NotificationService";
import { securedRequest, } from "../../../../services/RequestService";
import { getTenTasks, } from "../../../tasks/services/TasksService";
import { DefaultIcon, } from "./DefaultIcon/";
import { userService, } from "../../../../services/UserDataService";
import ObservableService from "../../../../services/ObservableService";
import { managerStatusOffline, managerStatusOnline, } from "../../../conversations/services/ConversationsRequestService";

import DropdownEventCount from "./DropdownEventCount/DropdownEventCount";

import { getUserCountActiveEvents, markAllEventsViewed, } from "../../services/UserEventsService";
class TopNav extends React.Component {
	constructor (props) {
		super(props);

		this.state = {
			dd1: false,
			isOpen: localStorage.getItem('isOpenSideBarTaskList') === 'true',
			step: 0,
			avatars: [],

			loaded: false,
			countActiveEvents: null,
			userProfileEvents: null,
		};
	}

	get userEventsPermissionConfig () {
		return [
			{
				key: 'user',
				path: [ 'core', 'user_events', ],
			},
		];
	}

	get showUserEvents () {
		const userEventsPermission = PermissionService.calc(this.userEventsPermissionConfig);

		return userEventsPermission?.user?.access('index', 'show') ?? false;
	}
	save = async state => new Promise(next => this.setState(state, next));

	toggleLoaded = loaded => this.save({ loaded, });

	profileChange = async (profile) => {
		if (profile.hash===this.state?.profile?.hash) {
			return `${ this.constructor.name }.profileChange: false`;
		}
		await this.save({ profile, });

		return `${ this.constructor.name }.profileChange: true`;
	};

	async componentDidMount () {
		await this.loadUserEvents();
		NotificationService.history = this.props.history;

		profileService.subscribe('profile', this.profileChange, this);
		await profileService.profile;
		await this.props.getTenTasks('today');
		const response = await userService.getAllAvatar();

		this.save({ avatars: response.data, });

		if (this.state.step === 0) {
			await this.save({ step: 1, });
			// console.log("test");
			NodeService.authEvent(this.state?.profile?.data?.id);
			NodeService.on().info((data) => {
				// console.log(data);
			});
			NodeService.on().reconnect();
			NodeService.on().getCall((data) => {
				// console.log(data);
			});

			NodeService.on().getExcel((data) => {
				if (data.alias === 'error') {
					NotificationService.error({
						title: "error",
						message: `${ data.fileName }`,
						remove: false,
					});
				} else {
					securedRequest.post('/api/core/excel-file', {
						alias: data.alias,
						file_name: `${ data.fileName }_${ Moment(new Date()).format('DD-MM-YYYY_HH:mm:ss') }`,
					}, { responseType: 'blob', })
						.then((res) => {
							fileDownload(res.data, res.headers['content-disposition'].replace('attachment; filename=', ''));
						});
				}
			});

			NodeService.on().getNovaTalks((data) => {
				ObservableService.emit('updateConversations', data);
			});

			NodeService.on().getMessage((data) => {
				NotificationService.message({
					type: data.message_type,
					title: data.title,
					message: data.message,
					remove: data.auto_close ? data.auto_close : false,
				});
			});

			NodeService.on().getEvent((data) => {
				NotificationService.success({
					title: "info",
					message: `У вас новая задача №${ data.task_id }`,
					view: true,
					viewClick: () => {
						this.props.history.push(`/task/${ data.task_id }`);
					},
				});
			});

			NodeService.on().getEventEvent(async (data) => {
				if (data?.params?.userEventSettings?.push !== 3) {
					data.closeFunc = () => {
						this.loadUserCountActiveEvents();
					};
					data.viewFunc = () => {
						this.loadUserCountActiveEvents();
					};

					await NotificationService.eventMessage({ data, enums: this.props.enums, });
				}

				await this.loadUserCountActiveEvents();
			});
		}
		await this.onDataLoad();
	}

	async componentWillUnmount () {
		NotificationService.history = null;
		profileService.unsubscribe('profile', this.profileChange, this);
		await NodeService.logoutEvent();
	}

	onDataLoad = async () => {
		await this.toggleLoaded(false);
		await this.loadUserCountActiveEvents();
		await this.loadUserEvents();
		await this.toggleLoaded(true);
	};

	loadUserEvents = async () => {
		try {
			if (!this.showUserEvents) {
				return;
			}

			const response = await userService.userEvents();

			await this.save({ userProfileEvents: response.data.data, });
		} catch (error) {
			error.showErrorNotification?.();
			console.log(error);
			await this.save({ userProfileEvents: null, });
		}
	}
	loadUserCountActiveEvents = async () => {
		try {
			if (!this.showUserEvents) {
				return;
			}

			const response = await getUserCountActiveEvents();

			await this.save({ countActiveEvents: response, });
		} catch (error) {
			error.showErrorNotification?.();
			console.log(error);
			await this.save({ countActiveEvents: null, });
		}
	};

	markAllEventsAsViewed = async () => {
		try {
			const response = await markAllEventsViewed();

			await this.onDataLoad();
		} catch (error) {
			console.log(error);
		}
	}

	dropdownTaskList = async () => {
		await this.save({ isOpen: !this.state.isOpen, });
		localStorage.setItem('isOpenSideBarTaskList', String(this.state.isOpen));
		await this.props.getSideBar(this.state.isOpen);
	};
	handleMangerStatus = async (status) => {
		switch (status) {
			case 'online': {
				try {
					await managerStatusOnline();
				} catch (error) {
					NotificationService.error({ title: 'error', message: error, remove: false, });
				}
				break;
			}

			case 'offline': {
				try {
					await managerStatusOffline();
				} catch (error) {
					NotificationService.error({ title: 'error', message: error, remove: false, });
				}

				break;
			}

			default: {
				break;
			}

		}
	}

	doLogoutFunction = async () => {
		await this.props?.logout();
		await doLogout(this.state.profile?.data?.id);
		this.props.history.push('/');
	};

	renderUserAvatar = () => {
		const userId = this.state.profile?.data?.id;
		let src = this.state.avatars[userId];

		src = src ? `data:image/jpeg;base64,${ src }` : null;

		if (!src) {
			return this.makeDefaultIcon();
		}

		return (
			//eslint-disable-next-line
			<span className="nav-top-img">
				<img alt="user" src={ src } />
			</span>
		);
	};

	makeDefaultIcon = () => (<DefaultIcon { ...this.state.profile?.data } />);

	render () {
		const { loaded, countActiveEvents, userProfileEvents, } = this.state;
		const { history, translate, } = this.props;
		const treeInfo = this.state.profile?.data?.tree_info ?? [];
		const departmentNamesStr = treeInfo.map(item => item?.department_name ?? '').join(', ');

		return (
			<Translate>
				{({ translate, }) => {
					return (
						<Nav className="topbar-user-list">
							<div className="langSelect">
								{/* <LangSelect />*/}
							</div>

							{
								this.showUserEvents &&
								<UncontrolledDropdown className="no-caret" id="top-notification" nav={ true }>
								<DropdownEventCount countActiveEvents={ countActiveEvents } history={ history } loaded={ loaded } markAllEventsAsViewed={ this.markAllEventsAsViewed } toggleLoaded={ this.toggleLoaded } translate={ translate } userProfileEvents={ userProfileEvents } onDataLoad={ this.onDataLoad } enums={this.props.enums} />
							</UncontrolledDropdown>}

							<UncontrolledDropdown className="nav-top-user no-caret narrow" id="top-user" nav={ true }>
								<DropdownToggle nav={ true }>
									<span className="top-user-wrapper">
										{this.renderUserAvatar()}
										<div className="top-account-info">
											<div className="top-account-name">{ `${ this.state.profile?.data?.name ?? "" } ${ this.state.profile?.data?.surname ?? "" }` }</div>
											{/* <div className = 'top-account-position'>{translate(`topNav_position`)}</div> */}
											<div className="top-account-position top-account-departments">{ `${ departmentNamesStr }` }</div>
										</div>
									</span>
								</DropdownToggle>
								<DropdownMenu>
									<Link to="/settings/profile">
										<DropdownItem>
											<i className="la la-user" />
											<span>{translate(`topNav_my_account`)}</span>
										</DropdownItem>
									</Link>
									{/*<Link to = '/profile/2'>
													<DropdownItem>
														<i className = 'la la-cog' />
														<span>
															{translate(`topNav_faq`)}
														</span>
													</DropdownItem>
												</Link>*/}
									<DropdownItem onClick={ () => this.handleMangerStatus('online') }>
										<i className="green-circle" />
										<span>Online</span>
									</DropdownItem>
									<DropdownItem onClick={ () => this.handleMangerStatus('offline') }>
										<i className="red" />
										<span>Offline</span>
									</DropdownItem>
									<DropdownItem onClick={ this.doLogoutFunction }>
										<i className="la la-sign-out" />
										<span>{translate(`topNav_logout`)}</span>
									</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>

							<NavItem className="top-menu-tasks" ><i className="la la-terminal" onClick={ this.dropdownTaskList } /></NavItem>
							{/*{this.state.ddTL ?'1' : '2'}*/}
						</Nav>
					);
				}}
			</Translate>
		);
	}
}

export default withLocalize(withRouter(connect(null,
	dispatch =>
		bindActionCreators({
			getSideBar,
			getTenTasks,
		}, dispatch),
)(TopNav)));