/* eslint-disable sort-keys */
export const magicLang = {
  "magic__?": [ "magic__?", "magic__?", "magic__?", ],

  "magic_confirm_title": [ "Are you sure?", "Вы уверены?", "Ви впевнені?", ],
  "magic_confirm_title_account": [ "Confirm sending", "Подтвердите отправку", "Підтвердить відправлення", ],
  "magic_confirm_yes": [ "Yes", "Да", "Так", ],
  "magic_confirm_no": [ "No", "Нет", "Ні", ],

  "magic_filter_between": [ "between", "между", "між", ],
  "magic_filter_not-equal": [ "not equal", "не равно", "не дорівнює", ],
  "magic_filter_equal": [ "equal", "равно", "дорівнює", ],
  "magic_filter_not-in": [ "not in", "не один из", "не один серед", ],
  "magic_filter_in": [ "in", "один из", "один серед", ],
  "magic_filter_less-or-equal": [ "less or equal", "меньше или равно", "меньш або дорівнює", ],
  "magic_filter_less": [ "less", "меньше", "меньш", ],
  "magic_filter_not-null": [ "not null", "not null", "not null", ],
  "magic_filter_null": [ "null", "null", "null", ],
  "magic_filter_not-like": [ "not like", "не на подобии", "не схоже на", ],
  "magic_filter_like": [ "like", "на подобии", "схоже на", ],
  "magic_filter_more-or-equal": [ "more or equal", "больше или равно", "більше або дорівнює", ],
  "magic_filter_more": [ "more", "больше", "більше", ],
  // TODO: translate other operators ...
  // Fast Filters for clients
  "magic_filter_translate_account_name": [ "First Name", "Имя", "Ім'я", ],
  "magic_filter_translate_account_surname": [ "Last Name", "Фамилия", "Прізвище", ],
  "magic_filter_translate_account_email": [ "E-Mail", "E-Mail", "E-Mail", ],
  "magic_filter_translate_account_phone": [ "Tel. number", "Телефон", "Телефон", ],
  "magic_filter_translate_account_id": [ "ID", "ID", "ID", ],
  "magic_filter_translate_account_category": [ "Category", "Категория", "Категорія", ],
  "magic_filter_translate_account_city": [ "City", "Город", "Місто", ],
  "magic_filter_translate_account_warm": [ "Warmth", "Теплота", "Теплота", ],
  "magic_filter_translate_account_created_at": [ "Created at", "Дата создания", "Дата створення", ],
  "magic_filter_translate_account_change_category_date": [ "Category change date", "Дата изменения категории", "Дата зміни категорії", ],
  "magic_filter_translate_account_description_last_updated": [ "Last date of comments", "Дата последнего коментария", "Дата останнього коментаря", ],
  "magic_filter_translate_account_register_type": ["Register_type", "Тип аккаунта", "Тип аккаунта"],
  // Fast Filters for task
  "magic_filter_translate_task_id": [ "ID", "ID", "ID", ],
  "magic_filter_translate_task_account": [ "Client", "Клиент", "Клієнт", ],
  "magic_filter_translate_task_warm": [ "Warm", "Теплота", "Теплота", ],
  "magic_filter_translate_task_status": [ "Status", "Статус", "Статус", ],
  "magic_filter_translate_task_finish_date": [ "Deadline", "Крайний Срок", "Крайній термін", ],
  "magic_filter_translate_task_responsible": [ "Responsible", "Ответственный", "Відповідальний", ],
  "magic_filter_translate_task_creator": [ "Creator", "Создатель", "Створювач", ],
  "magic_filter_translate_task_description": [ "Name", "Описание", "Опис", ],
  "magic_filter_translate_task_category_id": [ "Category", "Категория", "Категорія", ],
  "magic_filter_translate_task_created_at": [ "Category change date", "Дата изменения категории", "Дата зміни категорії", ],
  "magic_filter_translate_task_event_id": [ "Event", "Событие", "Подія", ],
  "magic_filter_translate_task_license_alias": [ "License", "Лицензия", "Ліцензія", ],
  "magic_filter_translate_is_mobile": [ "Mobile discount", "Мобильная скидка", "Мобільна знижка", ],
  // Fast Filters for tima manager
  "magic_filter_translate_tima_manager_status": [ "Status", "Статус", "Статус", ],
  "magic_filter_translate_tima_manager_name": [ "TIMA account", "TIMA счет", "TIMA рахунок", ],
  "magic_filter_translate_tima_manager_currency": [ "Currency", "Валюта", "Валюта", ],
  "magic_filter_translate_tima_manager_balance": [ "Balance", "Баланс", "Баланс", ],
  "magic_filter_translate_tima_manager_mt_login": [ "Account number", "Номер счета", "Номер рахунку", ],
  "magic_filter_translate_tima_manager_acc_name_surname": [ "Client", "Клиент", "Клієнт", ],
  "magic_filter_translate_tima_manager_equity": [ "Equity", "Средства", "Кошти", ],
  "magic_filter_translate_tima_manager_share": [ "Share", "Доля капитала", "Частка капіталу", ],
  // Fast Filters for tima investment
  "magic_filter_translate_tima_investment_id": [ "ID", "ID", "ID", ],
  "magic_filter_translate_tima_investment_name": [ "Investment", "Инвестиции", "Інвестиції", ],
  "magic_filter_translate_tima_investment_account": [ "Client", "Клиент", "Клієнт", ],
  "magic_filter_translate_tima_investment_manager_name": [ "TIMA account name", "TIMA имя счет", "TIMA назва рахунок", ],
  "magic_filter_translate_tima_investment_currency": [ "Currency", "Валюта", "Валюта", ],
  "magic_filter_translate_tima_investment_amount": [ "Amount", "Сумма", "Сума", ],
  "magic_filter_translate_tima_investment_status": [ "Status", "Статус", "Статус", ],
  "magic_filter_translate_tima_investment_created_at": [ "Created at", "Дата создания", "Дата створення", ],
  "magic_filter_translate_tima_investment_closed_at": [ "Closed at", "Дата закрытия", "Дата закриття", ],
  // Fast Filters for tima condition
  "magic_filter_translate_tima_condition_name": [ "Condition", "Условия", "Умови", ],
  "magic_filter_translate_tima_condition_account_id": [ "TIMA manager", "TIMA ответственный", "TIMA відповідальний", ],
  "magic_filter_translate_tima_condition_manager_name": [ "TIMA account name", "TIMA имя счет", "TIMA назва рахунок", ],
  "magic_filter_translate_tima_condition_version": [ "Version", "Версия", "Версія", ],
  "magic_filter_translate_tima_condition_created_at": [ "Created at", "Дата создания", "Дата створення", ],
  "magic_filter_translate_tima_condition_updated_at": [ "Updated at", "Дата изменения", "Дата зміни", ],
  // Fast Filters for tima agent
  "magic_filter_translate_tima_agent_name": [ "TIMA agent", "TIMA агент", "TIMA агент", ],
  "magic_filter_translate_tima_agent_account": [ "Client", "Клиент", "Клієнт", ],
  "magic_filter_translate_tima_agent_manager_name": [ "TIMA account name", "TIMA имя счет", "TIMA назва рахунок", ],
  "magic_filter_translate_tima_agent_user": [ "Responsible", "Ответственный", "Відповідальний", ],
  "magic_filter_translate_tima_agent_status": [ "Status", "Статус", "Статус", ],
  // Fast Filters for partners requests
  "magic_filter_translate_partners_requests_account": [ "Client", "Клиент", "Клієнт", ],
  // "magic_filter_translate_partners_requests_partner_account_id": [ "IB Partner account", "Счет IB пертнера", "Рахунок IB пертнера", ],
  // "magic_filter_translate_partners_requests_balance_account": [ "Balance account", "Балансовый счет", "Балансовий рахунок", ],
  "magic_filter_translate_partners_requests_system_accounts": [ "Account number", "Номер счета", "Номер рахунку", ],
  "magic_filter_translate_partners_requests_system_accounts_type": [ "Account type", "Тип счета", "Тип рахунку", ],
  "magic_filter_translate_partners_requests_status": [ "Status", "Статус", "Статус", ],
  "magic_filter_translate_partners_requests_resolution": [ "Resolution", "Решение", "Рішення", ],
  "magic_filter_translate_partners_requests_created_at": [ "Created at", "Дата создания", "Дата створення", ],
  "magic_filter_translate_partners_requests_updated_at": [ "Updated at", "Дата изменения", "Дата зміни", ],
  "magic_filter_translate_partners_requests_user": [ "Responsible", "Ответственный", "Відповідальний", ],
  "magic_filter_translate_partners_requests_country": [ "Country", "Страна", "Країна", ],
  "magic_filter_translate_partners_requests_time_zone": [ "Time zone", "Тайм-зона", "Тайм-зона", ],
  "magic_filter_translate_partners_requests_city": [ "City", "Город", "Місто", ],
  // Fast Filters for partners referrals
  "magic_filter_translate_partners_referrals_partner": [ "IB Partner", "IB Партнер", "IB Партнер", ],
  // "magic_filter_translate_partners_referrals_account": [ "Client", "Клиент", "Клієнт", ],
  // "magic_filter_translate_partners_referrals_partner_account_id": [ "IB Partner account", "Счет IB пертнера", "Рахунок IB пертнера", ],
  // "magic_filter_translate_partners_referrals_balance_account": [ "Balance account", "Балансовый счет", "Балансовий рахунок", ],
  // "magic_filter_translate_partners_referrals_partner_status": [ "Status", "Статус", "Статус", ],
  // "magic_filter_translate_partners_referrals_resolution": [ "Resolution", "Решение", "Рішення", ],
  "magic_filter_translate_partners_referrals_created_at": [ "Created at", "Дата создания", "Дата створення", ],
  // "magic_filter_translate_partners_referrals_updated_at": [ "Updated at", "Дата изменения", "Дата зміни", ],
  // Fast Filters for partners referrals 4 partner
  "magic_filter_translate_partners_referrals-4-partner_account": [ "Referral Client", "Реферальный Клиент", "Реферальний Клієнт", ],
  // "magic_filter_translate_partners_referrals-4-partner_system_accounts_id": [ "Referral balance account", "Балансовый счет реферала", "Балансовий рахунок реферала", ],
  "magic_filter_translate_partners_referrals-4-partner_system_accounts": [ "Referral account number", "Номер счета реферала", "Номер рахунку реферала", ],
  "magic_filter_translate_partners_referrals-4-partner_system_accounts_type": [ "Referral account type", "Тип счета реферала", "Тип рахунку реферала", ],
  "magic_filter_translate_partners_referrals-4-partner_created_at": [ "Created at", "Дата создания", "Дата створення", ],
  "magic_filter_translate_partners_referrals-4-partner_category_id": [ "Category", "Категория", "Категорія", ],
  "magic_filter_translate_partners_referrals-4-partner_country": [ "Country", "Страна", "Країна", ],
  "magic_filter_translate_partners_referrals-4-partner_city": [ "City", "Город", "Місто", ],
  "magic_filter_translate_partners_referrals-4-partner_date_paid": [ "Paid at", "Дата пополнения", "Дата поповнення", ],
  // Fast Filters for partners statistic
  "magic_filter_translate_partners_statistic_partner_account_id": [ "IB Partner ID", "IB пертнер ID", "IB пертнер ID", ],
  "magic_filter_translate_partners_statistic_balance_account": [ "Balance account", "Балансовый счет", "Балансовий рахунок", ],
  "magic_filter_translate_partners_statistic_daily_statistic_sum": [ "Income Sum", "Начисление", "Нарахування", ],
  "magic_filter_translate_partners_statistic_accruals_sum": [ "Accruals", "Выплаты", "Виплати", ],
  "magic_filter_translate_partners_statistic_diff_index": [ "Diff", "Разница", "Різниця", ],
  "magic_filter_translate_partners_statistic_autopay_status": [ "Autopay", "Автоначисление", "Автонарахування", ],

  "magic_filter_translate_partners_symbols_lists_id": [ "ID", "ID", "ID", ],
  "magic_filter_translate_partners_symbols_lists_name": [ "Name", "Название", "Назва", ],
  "magic_filter_translate_partners_symbols_lists_created_at": [ "Date created", "Дата создания", "Дата створення", ],
  "magic_filter_translate_partners_symbols_lists_updated_at": [ "Date modified", "Дата изменения", "Дата зміни", ],
  "magic_filter_translate_partners_symbols_lists_status": [ "Status", "Статус", "Статус", ],
  "magic_filter_translate_partners_symbols_lists_server": [ "Server", "Сервер", "Сервер", ],
  "magic_filter_translate_partners_symbols_lists_symbol": [ "Symbol", "Символ", "Символ", ],

  // Fast Filters for payment
  "magic_filter_translate_payment_account": [ "Client", "Клиент", "Клієнт", ],
  "magic_filter_translate_payment_order_id": [ "Order Id", "Номер заявки", "Номер заяви", ],
  "magic_filter_translate_payment_trading_account_id": [ "System account", "Балансовый счет", "Балансовий рахунок", ],
  "magic_filter_translate_payment_status": [ "Status", "Статус", "Статус", ],
  "magic_filter_translate_payment_resolution": [ "Resolution", "Решение", "Рішення", ],
  "magic_filter_translate_payment_created_at": [ "Created at", "Дата создания", "Дата створення", ],
  "magic_filter_translate_payment_date_paid": [ "Date of last transaction processing", "Дата последней обработки операции", "Дата останньої обробки операції", ],
  "magic_filter_translate_payment_updated_at": [ "Updated at", "Дата изменения", "Дата зміни", ],
  "magic_filter_translate_payment_manager": [ "Manager", "Менеджер", "Менеджер", ],
  "magic_filter_translate_payment_currency": [ "Currency", "Валюта", "Валюта", ],
  "magic_filter_translate_payment_type": [ "Request type", "Тип заявки", "Тип заявки", ],
  "magic_filter_translate_payment_manually_closing": [ "Closure", "Закрытие", "Закриття" ],

  // Fast Filters for contest
  "magic_filter_translate_contest_date_start": [ "Start at", "Старт конкурса", "Початок конкурсу", ],
  "magic_filter_translate_contest_date_end": [ "End at", "Конец конкурса", "Кінец конкурсу", ],
  "magic_filter_translate_contest_status": [ "Status", "Статус", "Статус", ],
  // Fast Filters for contest members
  "magic_filter_translate_contest_member_place": [ "Place", "Рейтинг", "Рейтинг", ],
  "magic_filter_translate_contest_member_max_loss": [ "Max loss", "Максимальная просадка", "Максимальне просідання", ],
  "magic_filter_translate_contest_member_loss": [ "Current loss", "Текущая просадка", "Поточне просідання", ],
  "magic_filter_translate_contest_member_account": [ "Client", "Клиент", "Клієнт", ],
  "magic_filter_translate_contest_member_nickname": [ "Nickname", "Никнейм", "Нікнейм", ],
  // Fast Filters for support
  "magic_filter_translate_support_ticket_id": [ "Id", "Номер", "Номер", ],
  "magic_filter_translate_support_ticket_created_at": [ "Created at", "Дата создания", "Дата створення", ],
  "magic_filter_translate_support_ticket_updated_at": [ "Date modified", "Дата изменения", "Дата зміни", ],
  "magic_filter_translate_support_ticket_status": [ "Status", "Статус", "Статус", ],
  "magic_filter_translate_support_ticket_sub_status": [ "Sub-status", "Под-статус", "Під-статус", ],
  "magic_filter_translate_support_ticket_satisfaction": [ "Satisfaction", "Удовлетворенность", "Задоволеність", ],
  "magic_filter_translate_support_ticket_user": [ "Responsible", "Ответственный", "Відповідальний", ],
  "magic_filter_translate_support_ticket_client_manager": [ "Manager", "Менеджер", "Менеджер", ],
  // Fast Filters for list-accounts-tradings
  "magic_filter_translate_list_accounts_tradings_mt_login": [ "MT account", "Номер счета", "Номер рахунку", ],
  "magic_filter_translate_list_accounts_tradings_account": [ "Client", "Клиент", "Клієнт", ],
  "magic_filter_translate_list_accounts_tradings_currency": [ "Currency", "Валюта", "Валюта", ],
  "magic_filter_translate_list_accounts_tradings_bonus_exist": [ "Bonus Exist", "Наличие Бонуса", "Наявність бонусу", ],
  "magic_filter_translate_list_accounts_tradings_leverage": [ "Leverage", "Плечо", "Плече", ],
  "magic_filter_translate_list_accounts_tradings_platform": [ "Server", "Сервер", "Сервер", ],
  "magic_filter_translate_list_accounts_tradings_type": [ "Type", "Тип", "Тип", ],
  "magic_filter_translate_list_accounts_tradings_type_unknown": [ "?", "?", "?", ],
  "magic_filter_translate_list_accounts_tradings_type_demo": [ "Demo", "Демо", "Демо", ],
  "magic_filter_translate_list_accounts_tradings_type_live": [ "Live", "Лайв", "Лайв", ],
  "magic_filter_translate_list_accounts_tradings_risk_management": [ "Risk managment", "Риск менеджмент", "Ризик менеджмент", ],
  "magic_filter_translate_list_accounts_tradings_mt_group": [ "MT Group", "MT группа", "MT група", ],
  // Fast Filters for list-accounts-tradings-mt4-opened-orders
  "magic_filter_translate_list_accounts_tradings_opened_orders_ticket": [ "Order", "Ордер", "Ордер", ],
  "magic_filter_translate_list_accounts_tradings_opened_orders_open_time": [ "Open time", "Время открытия", "Час відкриття", ],
  "magic_filter_translate_list_accounts_tradings_opened_orders_cmd": [ "Type", "Тип", "Тип", ],
  "magic_filter_translate_list_accounts_tradings_opened_orders_symbol": [ "Symbol", "Символ", "Символ", ],
  // Fast Filters for list-accounts-tradings-mt4-orders-closed
  "magic_filter_translate_list_accounts_tradings_closed_orders_ticket": [ "Order", "Ордер", "Ордер", ],
  "magic_filter_translate_list_accounts_tradings_closed_orders_open_time": [ "Open time", "Время открытия", "Час відкриття", ],
  "magic_filter_translate_list_accounts_tradings_closed_orders_symbol": [ "Symbol", "Символ", "Символ", ],
  // Fast Filters for list-accounts-tradings-mt5-deals-closed
  "magic_filter_translate_list_accounts_tradings_mt5_closed_deals_deal": [ "Deal", "Сделка", "Угода", ],
  "magic_filter_translate_list_accounts_tradings_mt5_closed_deals_order": [ "Order", "Ордер", "Ордер", ],
  "magic_filter_translate_list_accounts_tradings_mt5_closed_deals_symbol": [ "Symbol", "Символ", "Символ", ],
  "magic_filter_translate_list_accounts_tradings_mt5_closed_deals_time": [ "Time", "Время", "Час", ],
  "magic_filter_translate_list_accounts_tradings_mt5_closed_deals_action": [ "Order type", "Тип ордера", "Тип ордера", ],
  // Fast Filters for list-accounts-tradings-mt5-orders-closed
  "magic_filter_translate_list_accounts_tradings_mt5_closed_orders_order": [ "Order", "Ордер", "Ордер", ],
  "magic_filter_translate_list_accounts_tradings_mt5_closed_orders_setup_time": [ "TimeSetup", "Время размещения", "Час розміщення", ],
  "magic_filter_translate_list_accounts_tradings_mt5_closed_orders_done_time": [ "TimeDone", "Время исполнения", "Чaс виконання", ],
  "magic_filter_translate_list_accounts_tradings_mt5_closed_orders_symbol": [ "Symbol", "Символ", "Символ", ],
  // Fast Filters for list-accounts-tradings-mt5-orders-opened
  "magic_filter_translate_list_accounts_tradings_mt5_opened_orders_order": [ "Order", "Ордер", "Ордер", ],
  "magic_filter_translate_list_accounts_tradings_mt5_opened_orders_setup_time": [ "Setup time", "Время размещения", "Час розміщення", ],
  "magic_filter_translate_list_accounts_tradings_mt5_opened_orders_symbol": [ "Symbol", "Символ", "Символ", ],
  // Fast Filters for list-accounts-tradings-mt5-positions-closed
  "magic_filter_translate_list_accounts_tradings_mt5_closed_positions_position": [ "PositionID", "Позиция", "Позиція", ],
  "magic_filter_translate_list_accounts_tradings_mt5_closed_positions_open_time": [ "Opening time", "Время открытия", "Час відкриття", ],
  "magic_filter_translate_list_accounts_tradings_mt5_closed_positions_close_time": [ "Closing time", "Время закрытия", "Час закриття", ],
  "magic_filter_translate_list_accounts_tradings_mt5_closed_positions_symbol": [ "Symbol", "Символ", "Символ", ],
  // Fast Filters for list-accounts-tradings-mt5-positions-opened
  "magic_filter_translate_list_accounts_tradings_mt5_opened_positions_position": [ "Position", "Позиция", "Позиція", ],
  "magic_filter_translate_list_accounts_tradings_mt5_opened_positions_create_time": [ "TimeCreate", "Время создания", "Час створення", ],
  "magic_filter_translate_list_accounts_tradings_mt5_opened_positions_symbol": [ "Symbol", "Символ", "Символ", ],
  // Fast Filters for list-accounts-systems
  "magic_filter_translate_list_accounts_systems_id": [ "Account ID", "ID счета", "ID рахунку", ],
  "magic_filter_translate_list_accounts_systems_account": [ "Client", "Клиент", "Клієнт", ],
  "magic_filter_translate_list_accounts_systems_created_at": [ "Creation date", "Дата создания", "Дата створення", ],
  "magic_filter_translate_list_accounts_systems_updated_at": [ "Date of last balance tansaction", "Дата последней балансовой операции", "Дата останньої балансової операції", ],
  "magic_filter_translate_list_accounts_systems_type": [ "Account type", "Тип счета", "Тип рахунку", ],
  "magic_filter_translate_list_accounts_systems_currency": [ "Currency", "Валюта", "Валюта", ],
  "magic_filter_translate_list_accounts_systems_status": [ "Status", "Статус", "Статус", ],
  // Fast Filters for delegation
  "magic_filter_translate_delegation_finish_date": [ "Finish date", "Крайний срок", "Останній строк", ],
  "magic_filter_translate_delegation_status": [ "Status", "Статус", "Статус", ],
  "magic_filter_translate_delegation_urgency": [ "Urgency", "Важность", "Важливість", ],
  "magic_filter_translate_delegation_manager": [ "Responsible manager", "Ответственный менеджер", "Відповідальний менеджер", ],
  "magic_filter_translate_delegation_manager_delegated": [ "Delegated to", "Делегировано", "Делеговано", ],
  // Fast Filters for users
  "magic_filter_translate_user_fullname": [ "User", "Пользователь", "Користувач", ],
  "magic_filter_translate_user_email": [ "E-mail", "E-mail", "E-mail", ],
  "magic_filter_translate_user_role": [ "Role", "Роль", "Роль", ],
  "magic_filter_translate_user_status": [ "Status", "Статус", "Стан", ],
  "magic_filter_translate_user_kpi_plan_year": [ "Year", "Год", "Рік", ],
  "magic_filter_translate_user_kpi_plan_kpi_type_id": [ "Type", "Тип", "Тип", ],
  "magic_filter_translate_user_kpi_plan_active": [ "Active", "Активен", "Активен", ],
  // Widgets Fast Filters for briefcase
  "magic_filter_translate_widget_briefcase_account": [ "Client", "Клиент", "Клієнт", ],
  // Fast Filters for calls
  "magic_filter_translate_calls_calldate": [ "Date", "Дата", "Дата", ],
  "magic_filter_translate_calls_manager": [ "Manager", "Менеджер", "Менеджер", ],
  "magic_filter_translate_calls_duration": [ "Duration", "Продолжительность", "Тривалість", ],
  "magic_filter_translate_calls_disposition": [ "Result", "Результат", "Результат", ],
  "magic_filter_translate_calls_incoming": [ "Call type", "Тип звонка", "Тип дзвінка", ],
  // Statistics Account Utm Link
  "magic_filter_translate_account_utm_link_account_id": [ "ID", "ID", "ID" ],
  "magic_filter_translate_statistics_account_core_name": [ "Name", "Имя", "Ім'я" ],
  "magic_filter_translate_statistics_account_core_surname": [ "Surname", "Фамилия", "Прізвище" ],
  "magic_filter_translate_statistics_account_core_category": [ "Category", "Категория", "Категорія" ],
  "magic_filter_translate_statistics_account_core_country": [ "Country", "Страна", "Країна" ],
  "magic_filter_translate_statistics_account_core_date_of_category_change": [ "Category change date", "Дата изменения категории", "Дата зміни категорії" ],
  "magic_filter_translate_utm_channel": [ "Channel", "Канал", "Канал" ],
  "magic_filter_translate_utm_subchannel": [ "Subchannel", "Подканал", "Підканал" ],
  "magic_filter_translate_utm_campaign": [ "Campaign", "Продукт", "Продукт" ],
  "magic_filter_translate_statistics_account_core_date_of_creation": [ "Date of creation", "Дата создания", "Дата створення" ],
  "magic_filter_translate_statistics_account_core_license": [ "License", "Лицензия", "Ліцензія" ],
};
