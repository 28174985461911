import React from "react";
import PropTypes from 'prop-types';
import './styles.scss';
import { propTypesPageId, propTypesPageText, } from './PageLink';


const PageStatus = ( props ) => {
    const { class4status, class4statusSkipped, class4statusTaken, class4statusFilter, class4statusTotal, } = props;
    const { doStatus, doText, options, } = props;
    if ( doStatus ) {
        return doStatus( { props, options, } );
    } else {
        const { skipped, taken, filter, total, } = options;
        const text = ( id, is ) => {
            const { skipped, taken, filter, total, } = is;
            if ( doText ) {
                return doText( id, is );
            } else if ( skipped || taken ) {
                return Number.isInteger( id ) ? `${ id + 1 }` : '?';
            } else if ( filter || total ) {
                return Number.isInteger( id ) ? `${ id }` : '?';
            } else {
                return '?';
            }
        }
        return ( <span className={ class4status } >
            <span className={ class4statusSkipped } >{ text( skipped, { skipped: true, } ) }</span>
            &nbsp;-&nbsp;
            <span className={ class4statusTaken } >{ text( taken, { taken: true, } ) }</span>
            &nbsp;/&nbsp;
            <span className={ class4statusFilter } >{ text( filter, { filter: true, } ) }</span>
            &nbsp;/&nbsp;
            <span className={ class4statusTotal } >{ text( total, { total: true, } ) }</span>
        </span> );
    }
};


const propTypesStatusOptions = PropTypes.exact( {
    skipped: propTypesPageId( 0, true ),
    taken: propTypesPageId( 0, true ),
    filter: propTypesPageId( 0, false ),
    total: propTypesPageId( 0, false ),
} );
const propTypesStatus = PropTypes.func;


PageStatus.propTypes = {
    class4status: PropTypes.string,
    class4statusSkipped: PropTypes.string,
    class4statusTaken: PropTypes.string,
    class4statusFilter: PropTypes.string,
    class4statusTotal: PropTypes.string,
    doStatus: propTypesStatus,
    doText: propTypesPageText,
    options: propTypesStatusOptions,
};


PageStatus.defaultProps = {
    class4status: `page__status`,
    class4statusSkipped: `page__status_skipped`,
    class4statusTaken: `page__status_taken`,
    class4statusFilter: `page__status_filter`,
    class4statusTotal: `page__status_total`,
};


export default PageStatus;
export {
    propTypesStatus,
    propTypesStatusOptions,
};
