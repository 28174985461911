import React, { Component, } from "react";

import { MagicConfirm, } from "../../../../../../tima/components/Magic";
import { Popup, Select, } from "../../../../../components";

import {
	getPartnersPrograms,
	moveAllPartnersToProgramm,
} from "../../../../../../../services/PartnersRequestService";

import { findOptionByValue, setErrorClass, } from "../../../../../utils/helpers";

export default class MoveAllPartnersToProgram extends Component {
	constructor (props) {
		super(props);

		this.state = {
			allPrograms: [],
			errors: [],
			runCalculations: 2,
			selectedProgramm: null,
			showConfirm: true,
		};
	}

	get programsAvailableOptions () {
		const { allPrograms, } = this.state;
		const { currentProgrammId, } = this.props;

		if (!allPrograms) {
			return [];
		}

		return allPrograms
			.filter(
				program =>
					+program.partners.programs.status !== 1 &&
                    +program.partners.programs.status !== 5 &&
                    +program.partners.programs.id !== +currentProgrammId,
			)
			.map((program) => {
				return {
					label: program.partners.programs.name,
					value: program.partners.programs.id,
				};
			}); //not NEW or CLOSED
	}

    save = state => new Promise(next => this.setState(state, next));

    componentDidMount = async () => {
    	try {
    		const response = await getPartnersPrograms();
    		const allPrograms = response.data;

    		await this.save({ allPrograms, });
    	} catch (error) {
    		console.log(error);
    		error.showErrorNotification();
    	} finally {
    		await this.save({ loaded: true, });
    	}
    };

    getSendData = () => {
    	const from_program_id = this.props.currentProgrammId;
    	const to_program_id = this.state.selectedProgramm;
    	const is_payout = this.state.runCalculations;

    	return { is_payout, from_program_id, to_program_id, };
    };

    handleOnChange = (event) => {
    	const { name, value, } = event.target;
    	const { errors, } = this.state;

        //clear error onChange
    	this.save({ errors: errors.filter(error => error !== name), });

    	switch (name) {
    		case "program_id": {
    			this.save({ selectedProgramm: value.value, });
    			break;
    		}
    		case "run_calculations": {
    			this.save({ runCalculations: event.target.checked ? 1 : 2, });
    			break;
    		}
    		default: {
    			return null;
    		}
    	}
    };

    renderSelectPartnerProgramm = () => {
    	const programs = this.programsAvailableOptions;
    	const { selectedProgramm, errors, } = this.state;
    	const translate = this.props.translate;

    	return (
	        <div className="view-edit-request__row">
		<Select
	className={ setErrorClass(errors, "program_id") }
	hideLabel={ true }
	name={ "program_id" }
	options={ programs }
	placeholder={ translate("partners_requests_partner_program_placeholder") }
	value={ findOptionByValue(programs, selectedProgramm) }
					// eslint-disable-next-line max-len
	onChange={ this.handleOnChange }
    			/>
    		</div>
    	);
    };

    renderRunCalculationsSwitch = () => {
    	const translate = this.props.translate;

    	return (
	<div className="block-header_input-checkbox switch-custom-styles">
	<label className="checkbox">
	<input
	name="run_calculations"
	type="checkbox"
	onClick={ this.handleOnChange }
    				/>
	<span className="checkbox__icon" />
    			</label>
	<p> {translate("partner_run_calculations")}</p>
    		</div>
    	);
    };
    toggleConfirm = () => this.save({ showConfirm: !this.state.showConfirm, });
	toggleLoader = () => this.props.toggleLoader();
    onConfirmAccept = () => {
    	return this.toggleConfirm();
    };
    onPopupAccept = async () => {
    	try {
    		const { refreshClientData, onClose, } = this.props;
    		const { selectedProgramm, errors, } = this.state;

    		if (!selectedProgramm) {
    			return this.save({ errors: [ ...errors, "program_id", ], });
    		}

    		await this.toggleLoader();
    		const sendData = this.getSendData();

    		await moveAllPartnersToProgramm(sendData);

    		await refreshClientData();
    		await this.toggleLoader();
    		await onClose();
    	} catch (error) {
    		await this.toggleLoader();
    		error?.showErrorNotification?.();
    	}
    };

    render () {
    	const { onClose, translate, } = this.props;
    	const { showConfirm, } = this.state;
    	const { onConfirmAccept, onPopupAccept, } = this;
    	const popupDynamicSize = showConfirm ? 'md' : 'smd';

    	return (
	<>
	<Popup
	cancelBtnText={ "partner_confirm_cancel" }
	createBtnText={ "partner_confirm_proceed" }
	isImage={ false }
	size={ popupDynamicSize } //popup body is overflow hidden, need more space for confirm
	title={ translate("affiliate_program_move_all_partners_to_program") }
	wrapperClassname={ `change-partner-programm` }
	onClick={ onPopupAccept }
	onClose={ onClose }
    			>
	{this.renderSelectPartnerProgramm()}
	{this.renderRunCalculationsSwitch()}
	<MagicConfirm
	accept={ translate("partner_confirm_proceed") }
	isVisible={ showConfirm }
	reject={ translate("partner_confirm_cancel") }
	text={ translate("partner_confirm_change_programm") }
	onAccept={ onConfirmAccept }
	onReject={ onClose }
	title={ translate("affiliate_program_move_all_partners_to_program") }
	className={ 'magic-confirm magic-confirm_popup-style' }
    				/>
    			</Popup>
    		</>
    	);
    }
}