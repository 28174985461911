/* eslint-disable no-mixed-spaces-and-tabs , react/jsx-indent , indent , max-len , no-case-declarations ,*/
import React, { Component, } from "react";
import PropTypes from "prop-types";
import { withLocalize, } from "react-localize-redux";
import { Input, Popup, Select, } from "../../../../components";
import { MagicSwitch, } from "../../../../../tima/components/Magic/MagicSwitch";
import {
	findOptionByValue,
	isEmptySelectValue,
	isEmptyString,
	setErrorClass,
	setErrorsFromServer,
	toLowerCase,
} from "../../../../utils/helpers";
import {
	GET_SYMBOLS_GROUPS_LIST,
	GET_SYMBOLS_LIST,
	GET_TRADE_SERVER_LIST_TAKE_ALL,
} from "../../../../../../apiRoutes";
import {
	asyncGet,
	createPartnersSymbol,
	putPartnersSymbol,
} from "../../../../../../services/PartnersRequestService.js";
import NotificationService from "../../../../../../services/NotificationService";

const symbolListStatus = Object.freeze({
	ACTIVE: 2,
	DISABLE: 0,
	NEW: 1,
});

class CreateUpdateSymbolsList extends Component {
	static defaultProps = {
		formData: {},
		onClose: () => {},
		onDataLoad: () => {},
	};

	static propTypes = {
		formData: PropTypes.object,
		// eslint-disable-next-line react/sort-prop-types
		onClose: PropTypes.func.isRequired,
		onDataLoad: PropTypes.func.isRequired,
	};

	constructor (props) {
		super(props);

		this.state = {
			errors: [],
			formData: {
				name: "",
				status: {},
				trading_server_id: {},
			},
			loadData: true,
			serversList: [],
			statusList: [],
			symbolsAll: [],
			symbolsGroupsAll: [],
			symbolsGroupsCurrent: [],
		};
	}

	get isEditMode () {
		return Object.keys(this.props.formData).length;
	}

	get symbolListStatusInDB () {
		const { status, } = this.props.formData;
		const { NEW, ACTIVE, DISABLE, } = symbolListStatus;

		return {
			active: status === ACTIVE,
			disable: status === DISABLE,
			new: status === NEW,
		};
	}

	get fieldsIsNotEdit () {
		return this.isEditMode && !this.symbolListStatusInDB.new;
	}

	get statusIsNotEdit () {
		return (
			this.isEditMode && this.symbolListStatusInDB.disable || !this.isEditMode
		);
	}

	get translate () {
		return this.props.translate;
	}

	save = state => new Promise(next => this.setState(state, next));

	toggleLoader = () => this.save(state => ({ loadData: !state.loadData, }));

	componentDidMount = async () => {
		try {
			await this.toggleLoader();
			await this.save((state) => {
				const statusList = this.getSymbolGroupStatus();

				return {
					formData: {
						...state.formData,
						status: statusList[1] ?? {},
					},
					statusList,
				};
			});

			await this.onDataLoad();
		} catch (error) {
			error?.showErrorNotification();
		} finally {
			await this.toggleLoader();
		}
	};

	getSymbolGroupStatus = () => {
		const { enums, } = this.props;

		return Object.entries(enums.partners.SymbolListStatusEnum).map(
			([ key, value, ]) => ({
				label: value,
				value: +key,
			}),
		);
	};

	getServersList = async () => {
		try {
			const response = await asyncGet(GET_TRADE_SERVER_LIST_TAKE_ALL);

			return response
				.filter(({ server_name, }) => !server_name.includes("Demo"))
				.reduce((total, { server_name, id, }) => {
					total.push({
						label: server_name,
						value: +id,
					});

					return total;
				}, []);
		} catch (error) {
			error?.showErrorNotification?.();

			return false;
		}
	};

	onDataLoad = async () => {
		try {
			const [ serversList, ] = await Promise.all([
				this.getServersList(),
				this.getGroupsList(),
				this.getSymbolsList(),
			]);

			await this.save({ serversList, });

			if (this.isEditMode) {
				await this.setSavedData();
			}
		} catch (error) {
			error?.showErrorNotification?.();

			return false;
		}
	};

	setSavedData = async () => {
		const {
 name, list, trading_server_id, status,
} = this.props.formData;

		await this.save(state => ({
			formData: {
				...state.formData,
				name,
				status: findOptionByValue(state.statusList, status),
			},
			...status === symbolListStatus.ACTIVE && {
				statusList: state.statusList.filter(
					({ value, }) => value !== symbolListStatus.NEW,
				),
			},
		}));

		await this.setServerSelectOption(trading_server_id, JSON.parse(list));
	};

	getGroupsList = async () => {
		try {
			const response = await asyncGet(GET_SYMBOLS_GROUPS_LIST);

			await this.save(state => ({
				...state,
				symbolsGroupsAll: response.data.map(
					item => item.partners.symbols_groups,
				),
			}));
		} catch (error) {
			error?.showErrorNotification?.();

			return false;
		}
	};

	getSymbolsList = async () => {
		try {
			const response = await asyncGet(GET_SYMBOLS_LIST);

			await this.save({ symbolsAll: response.data, });
		} catch (error) {
			error?.showErrorNotification?.();

			return false;
		}
	};

	getGroupsListByServerId = (groupsList, serverId) => {
		if (!Array.isArray(groupsList) || +serverId <= 0) {
			return [];
		}

		return Array.from(groupsList)
			.reduce((total, item) => {
				if (Number(item.trading_server_id) === Number(serverId)) {
					const symbolsItems = this.getSymbolsListByGroupId(item.id);
					let symbolsSelected = null;

					try {
						symbolsSelected = JSON.parse(this.props?.formData?.list);
					} catch (e) {
						symbolsSelected = {};
					}

					// eslint-disable-next-line no-shadow
					const symbolsArr = symbolsItems.filter((item) => {
						return (
							[ symbolListStatus.ACTIVE, ].includes(item.status) ||
							symbolsSelected[item.id]
						);
					});

					// eslint-disable-next-line no-param-reassign
					total = [
						...total,
						{
							...item,
							countSymbolsAll: symbolsArr.length,
							countSymbolsSelected: 0,
							isSelectedAll: 0,
							isVisible: false,
							symbol_name_search: "",
							symbols: symbolsArr,
						},
					];
				}

				return total;
			}, [])
			.filter(item => item.symbols.length);
	};

	getSymbolsListByGroupId = (groupId) => {
		if (+groupId <= 0) {
			return [];
		}

		const symbols = Array.from(this.state.symbolsAll).reduce((total, item) => {
			if (+item?.partners?.symbols?.group_id === +groupId) {
				// eslint-disable-next-line no-param-reassign
				total = [
					...total,
					{
						...item?.partners?.symbols,
						group_name: item.partners.symbols_groups.name,
						isSelected: 0,
						isVisible: true,
					},
				];
			}

			return total;
		}, []);

		if (!this.isEditMode) {
			const SYMBOL_ACTIVE_STATUS = 2;

			return symbols.filter(item => +item.status === SYMBOL_ACTIVE_STATUS);
		}

		return symbols;
	};

	setServerSelectOption = async (tradingServerId, list = {}) => {
		const { serversList, } = this.state;
		const symbolsGroupsAll = [ ...this.state.symbolsGroupsAll, ];

		const symbolsGroupsCurrent =
			this.getGroupsListByServerId(symbolsGroupsAll, tradingServerId) ?? [];

		if (Object.keys(list).length) {
			// eslint-disable-next-line array-callback-return
			Object.entries(list).map(([ symbolId, symbolName, ]) => {
				symbolsGroupsCurrent.forEach((groupItem) => {
					groupItem.symbols.forEach((symbolItem) => {
						if (symbolItem.id === +symbolId && symbolItem.name === symbolName) {
							symbolItem.isSelected = 1;
							groupItem.countSymbolsSelected += 1;
							symbolItem.isDisabled =
								!this.symbolListStatusInDB.new && symbolItem.isSelected;
						}
					});
					groupItem.isSelectedAll = groupItem.symbols.every(
						({ isSelected, }) => isSelected,
					);
				});
			});
		}
		await this.save(state => ({
			errors: state.errors.filter(i => i !== "list"),
			formData: {
				...state.formData,
				trading_server_id: findOptionByValue(serversList, tradingServerId),
			},
			symbolsGroupsCurrent,
		}));
	};

	getSendData = () => {
		const {
			symbolsGroupsCurrent,
			formData: { name, trading_server_id, status, },
		} = this.state;

		const selectedSymbolList = {};

		symbolsGroupsCurrent.forEach((groupItem) => {
			groupItem.symbols
				.filter(({ isSelected, }) => isSelected)
				.forEach((symbolItem) => {
					selectedSymbolList[symbolItem.id] = symbolItem.name;
				});
		});

		return {
			list: JSON.stringify(selectedSymbolList),
			name,
			status: status.value,
			trading_server_id: trading_server_id.value,
		};
	};

	validateEmptyFields = async () => {
		await this.save((state) => {
			const emptySymbolsSelected = state.symbolsGroupsCurrent.every(
				({ countSymbolsSelected, }) => !countSymbolsSelected,
			);

			const errors = Object.keys(state.formData).reduce((total, field) => {
				if (
					isEmptySelectValue(state.formData[field]) ||
					isEmptyString(state.formData[field])
				) {
					// eslint-disable-next-line no-param-reassign
					total = [ ...total, field, ];
				}

				return total;
			}, []);

			return { errors: emptySymbolsSelected ? [ ...errors, "list", ] : errors, };
		});

		return this.state.errors.length === 0;
	};

	saveData = async () => {
		const isValidate = await this.validateEmptyFields();

		if (!isValidate) {
			return;
		}

		this.isEditMode
			? await this.updateSymbolList()
			: await this.createSymbolList();
	};

	createSymbolList = async () => {
		const { onDataLoad, onClose, } = this.props;

		try {
			await this.save({ loadData: false, });
			await createPartnersSymbol(this.getSendData());

			onDataLoad && onDataLoad();
			onClose();
		} catch (error) {
			await setErrorsFromServer(error, this.save);

			const jsonSymbolListErrors = error?.response?.data?.errors?.list;
			const symbolListErrors = jsonSymbolListErrors.map((item) => {
				try {
					const symbolItem = JSON.parse(item);

					return symbolItem;
				} catch (e) {
					return item;
				}
			});

			await this.save({ symbolListErrors, });
		} finally {
			await this.save({ loadData: true, });
		}
	};

	updateSymbolList = async () => {
		const { onDataLoad, onClose, formData, } = this.props;

		try {
			await this.save({ loadData: false, });
			await putPartnersSymbol(formData.id, this.getSendData());

			onDataLoad && onDataLoad();
			onClose();
		} catch (error) {
			NotificationService.error({
				title: "error",
				message: error.response.data.error,
				remove: false,
			});
			await setErrorsFromServer(error, this.save);

			const jsonSymbolListErrors = error?.response?.data?.errors?.list;
			const symbolListErrors = jsonSymbolListErrors.map((item) => {
				try {
					const symbolItem = JSON.parse(item);

					return symbolItem;
				} catch (e) {
					return item;
				}
			});

			await this.save({ symbolListErrors, });

		} finally {
			await this.save({ loadData: true, });
		}
	};

	onInputSearchChange = groupIndex => async (event) => {
		const { name, value, } = event.target;

		const symbolsGroupsCurrent = [ ...this.state.symbolsGroupsCurrent, ];
		const currentGroup = symbolsGroupsCurrent[groupIndex];

		currentGroup[name] = value;

		currentGroup.symbols.forEach((item) => {
			item.isVisible = toLowerCase(item.name).includes(toLowerCase(value));
		});

		await this.save({ symbolsGroupsCurrent, });
	};

	onSwitchChange = (fieldName, groupIndex) => async ({ value, }) => {
		switch (fieldName) {
			case "select_all_symbols":
				const symbolsGroupsCurrent = [ ...this.state.symbolsGroupsCurrent, ];
				const currentGroup = symbolsGroupsCurrent[groupIndex];

				const isDisabledSymbols = currentGroup.symbols
					.map(item => item)
					.filter(({ isDisabled, }) => isDisabled).length;

				currentGroup.isSelectedAll = value;
				currentGroup.countSymbolsSelected = value
					? currentGroup.countSymbolsAll
					: isDisabledSymbols;
				currentGroup.symbols.forEach((item) => {
					if (!item.isDisabled) {
						item.isSelected = value;
					}
				});

				await this.save(state => ({
					errors: state.errors.filter(item => item !== "list"),
					symbolsGroupsCurrent,
				}));
				break;

			default:
				break;
		}
	};

	onListTitleClick = index => async () => {
		const symbolsGroupsCurrent = [ ...this.state.symbolsGroupsCurrent, ].reduce(
			(total, item, i) => {
				return [
					...total,
					{
						...item,
						isVisible: i !== index ? false : !item.isVisible,
					},
				];
			},
			[],
		);

		await this.save({ symbolsGroupsCurrent, });
	};

	onSymbolClick = (groupIndex, symbolIndex) => async () => {
		const symbolsGroupsCurrent = [ ...this.state.symbolsGroupsCurrent, ];
		const currentGroup = symbolsGroupsCurrent[groupIndex];
		const newValue = !currentGroup.symbols[symbolIndex].isSelected;

		currentGroup.symbols[symbolIndex].isSelected = newValue;
		currentGroup.countSymbolsSelected = newValue
			? currentGroup.countSymbolsSelected += 1
			: currentGroup.countSymbolsSelected -= 1;

		currentGroup.isSelectedAll = +currentGroup.symbols.every(
			({ isSelected, }) => isSelected,
		);

		await this.save(state => ({
			errors: state.errors.filter(item => item !== "list"),
			symbolsGroupsCurrent,
		}));
	};

	handleOnChange = async (event) => {
		const { name, value, } = event.target;

		await this.save(state => ({
			...state,
			errors: state.errors.filter(item => item !== name),
			formData: {
				...state.formData,
				[name]: value,
			},
		}));
	};

	renderListGroups = (groupsList) => {
		return groupsList.sort((a, b) => a.name.localeCompare(b.name)).map((item, groupIndex) =>
			this.renderListGroup(item, groupIndex),
		);
	};

	renderListGroup = (groupData, groupIndex) => {
		const className = groupData.isVisible ? "list-item active" : "list-item";
		const { symbol_name_search, isSelectedAll, countSymbolsAll, } = groupData;

		const isSelectedSymbols = groupData.symbols
			.map(item => item)
			.filter(({ isSelected, }) => isSelected).length;

		return (
			<div className={ className } key={ `list-item-${ groupIndex }` }>
				<div
					className="list-title list-title--group"
					onClick={ this.onListTitleClick(groupIndex) }
				>
					<MagicSwitch
						className={ `magic-switch magic-switch--inline` }
						index={ +(isSelectedSymbols >= 1) }
						key={ `magic-switch-title-${ groupIndex }` }
						reverse={ false }
						text={ groupData.name }
						updateStateFromProps={ true }
						values={ [ 0, 1, ] }
						onChange={ this.onSwitchChange(
							"not_to_select_all_symbols",
							groupIndex,
						) }
					/>
					<div
						className={ `popup__arrow ${ groupData.isVisible ? "down" : "" }` }
					/>
				</div>

				<div className="list-content">
					<Input
						label=""
						name="symbol_name_search"
						placeholder={ this.translate(
							"partners_create_symbol_search_symbol_placeholder",
						) }
						value={ symbol_name_search }
						wrapperClassName="search"
						onChange={ this.onInputSearchChange(groupIndex) }
					/>

					<div className="list list--symbols">
						<MagicSwitch
							className={ `magic-switch magic-switch--inline` }
							index={ +(isSelectedAll >= 1) }
							key={ `magic-switch-select-all-symbols-${ groupIndex }` }
							reverse={ false }
							text={ `${ this.translate(
								"partners_create_symbol_select_all_symbols",
							) } ${ isSelectedSymbols }/${ countSymbolsAll })` }
							updateStateFromProps={ true }
							values={ [ 0, 1, ] }
							onChange={ this.onSwitchChange("select_all_symbols", groupIndex) }
						/>

						<ul className="list-items">
							{this.renderListSymbols(groupData.symbols, groupIndex)}
						</ul>
					</div>
				</div>
			</div>
		);
	};

	renderListSymbols = (symbolsList = [], groupIndex) => {
		// eslint-disable-next-line array-callback-return
		return symbolsList?.sort((a, b) => a.name.localeCompare(b.name)).map?.((item, symbolIndex) => {
			if (![ symbolListStatus.NEW, ].includes(item.status)) {
				return this.renderListSymbol(item, groupIndex, symbolIndex);
			}
		});
	};

	renderListSymbol = (symbolData, groupIndex, symbolIndex) => {
		let classNameError = "";
		const classNameSelected = symbolData.isSelected ? "selected" : "";

		const activeSymbol = [ symbolListStatus.ACTIVE, ].includes(symbolData?.status);
		const inactiveSymbol = [ symbolListStatus.DISABLE, ].includes(
			symbolData?.status,
		);
		const disabledSymbol = symbolData.isDisabled;
		const newSymbolList = this.symbolListStatusInDB.new;

		const activeNewSymbol = activeSymbol && newSymbolList;
		const inactiveNewSymbol = inactiveSymbol && newSymbolList;
		const activeSelectedNotNewSymbol =
			activeSymbol && disabledSymbol && !newSymbolList;
		const inactiveSymbolListNew = inactiveSymbol && newSymbolList;

		const symbolNameErrorItems = this.state?.symbolListErrors?.map(
			item => item.symbol_name,
		);

		if (Array.isArray(symbolNameErrorItems)) {
			// eslint-disable-next-line array-callback-return
			symbolNameErrorItems.map((symbol_name) => {
				if (symbol_name === symbolData.name && symbolData.isSelected) {
					classNameError = "error";
				}
			});
		}

		return (
			symbolData.isVisible && (
				<li
					{ ...{
						className: classNameError || classNameSelected,
						"data-active-selected-not-new-symbol": activeSelectedNotNewSymbol,
						"data-activenew": activeNewSymbol,
						"data-disabled": !!disabledSymbol,
						"data-inactive-symbol": inactiveSymbol,
						"data-inactivenew": inactiveNewSymbol,
						"data-inactivesymbollistnew": inactiveSymbolListNew,
					} }
					// eslint-disable-next-line react/no-children-prop
					children={ symbolData.name }
					key={ `symbol-list-item-${ groupIndex }-${ symbolIndex }` }
					onClick={ this.onSymbolClick(groupIndex, symbolIndex) }
				/>
			)
		);
	};

	popupTitle = () => {
		if (this.isEditMode) {
			return this.translate("partners_edit_symbol_title");
		}

		return this.translate("partners_symbols_lists_create_symbols_list");
	};

	render = () => {
		const { onClose, } = this.props;
		const {
			loadData,
			formData,
			statusList,
			errors,
			serversList,
			symbolsGroupsCurrent,
		} = this.state;

		return (
			<Popup
				loadData={ loadData }
				title={ this.popupTitle() }
				onClick={ this.saveData }
				onClose={ onClose }
			>
				<div className="popup__subtitle">
					{this.translate("partners_create_account_group_list_params")}
				</div>
				<Input
					disabled={ !loadData || this.fieldsIsNotEdit }
					isRequired={ true }
					label={ this.translate("partners_create_symbol_name_label") }
					name="name"
					placeholder={ this.translate(
						"partners_symbols_lists_create_name_placeholder",
					) }
					value={ formData.name }
					wrapperClassName={ setErrorClass(errors, "name") }
					onChange={ this.handleOnChange }
					onValid={ (value) => {
						// eslint-disable-next-line no-useless-escape
						const regExp = /^[a-zA-Z0-9\s\.\,\/\\\-\_\&\#\%]{0,100}$/g;

						if (value[0] === " " || !regExp.test(value)) {
							throw new Error("xxx");
						}
					} }
				/>

				<Select
					className={ setErrorClass(errors, "status") }
					disabled={ !loadData || this.statusIsNotEdit }
					isRequired={ true }
					label={ `${ this.translate("partners_create_symbol_status") }` }
					name="status"
					options={ statusList }
					value={ formData.status }
					onChange={ this.handleOnChange }
				/>

				<Select
					className={ setErrorClass(errors, "trading_server_id") }
					disabled={ !loadData || this.fieldsIsNotEdit }
					isRequired={ true }
					label={ this.translate("partners_server_name_label") }
					name="trading_server_id"
					options={ serversList }
					placeholder={ this.translate("partners_create_account_select_server") }
					value={ formData.trading_server_id }
					onChange={ this.handleOnChange }
					onChangeCallback={ async (event) => {
						const tradingServerId = event.target.value.value;

						await this.setServerSelectOption(tradingServerId);
					} }
				/>
				<div className="popup__subtitle popup__subtitle--mt">
					{this.translate("partners_create_symbol_table_groups")}
				</div>
				<label className="popup__label">{`${ this.translate(
					"partners_symbols_lists_list",
				) }*`}</label>
				<div
					className={ `popup__create-symbol-list ${ setErrorClass(
						errors,
						"list",
					) }` }
				>
					<div className="list list--groups table">
						{symbolsGroupsCurrent.length ?
							this.renderListGroups(symbolsGroupsCurrent)
						 : (
							<div className="groups--empty">
								{this.translate(
									"partners_symbols_lists_create_empty_group_list",
								)}
							</div>
						)}
					</div>
				</div>
			</Popup>
		);
	};
}

export default withLocalize(CreateUpdateSymbolsList);