const GLOBAL_PATH_API = '/api';
const GLOBAL_PATH_TASKS = `${GLOBAL_PATH_API}/tasks`;
const GLOBAL_PATH_CORE = `${GLOBAL_PATH_API}/core`;
const GLOBAL_PATH_TASK_USERS = `${GLOBAL_PATH_TASKS}/task-users`;

export const GET_TASKS = `${GLOBAL_PATH_TASKS}/tasks`;
export const SET_TASK = `${GLOBAL_PATH_TASKS}/tasks`;
export const GET_ALL_TASKS_ENUMS = `${GLOBAL_PATH_TASKS}/get-enums/`; // TODO: remove CRM-1461
export const GET_COMMENT = `${GLOBAL_PATH_TASKS}/task-comments/all`;
export const GET_COMMENT_TASK = `${GLOBAL_PATH_TASKS}/task-comments`;
export const GET_COMMENTS_DOCUMENTS = `${GLOBAL_PATH_TASKS}/task-comment-documents/get`;
export const UPLOAD_DOCUMENT_TASK = `${GLOBAL_PATH_TASKS}/task-documents`;
export const GET_DOCUMENT = `${UPLOAD_DOCUMENT_TASK}/all`;
export const DOWNLOAD_DOCUMENT = `${UPLOAD_DOCUMENT_TASK}/get`;
export const GET_TEN_TASKS = `${GET_TASKS}/get-one-hundred-active-tasks`;
export const SET_CHECKLIST = `${GLOBAL_PATH_TASKS}/task-check-lists`;
export const DELETE_CHECKLIST = `${GLOBAL_PATH_TASKS}/task-check-lists`;
export const DELETE_ALL_CHECKLIST = `${GLOBAL_PATH_TASKS}/task-check-lists/task_id`;
export const GET_CHECKLIST_ALL = `${SET_CHECKLIST}/all`;
export const GET_TASK_USERS = `${GLOBAL_PATH_TASK_USERS}/get-task-users`;
export const GET_COUNT_NOT_WORK = `${GET_TASKS}/get-count-not-work`;
export const UPLOAD_PARTICIPANTS_TASK = `${GLOBAL_PATH_TASKS}/task-users`;
export const SET_SAVE_ALL_TASK = `${GET_TASKS}/save-all-task`;
export const GET_TASK_HISTORY = `${GET_TASKS}/orders-changes`;
export const GET_TASK_URGENCY = `${GLOBAL_PATH_TASKS}/task-urgency`;
export const GET_TASK_URGENCY_DROPDOWN = `${GLOBAL_PATH_TASKS}/task-urgency/drop-down`;
export const GET_TASK_STATES = `${GLOBAL_PATH_TASKS}/task-states`;
export const GET_TASK_STATES_DROPDOWN = `${GLOBAL_PATH_TASKS}/task-states/drop-down`;
export const GET_TASK_TYPES = `${GLOBAL_PATH_TASKS}/task-types`;
export const GET_AVATAR = `${GLOBAL_PATH_CORE}/user/get-avatar`;
export const GET_SELECT_MODELS = `${GLOBAL_PATH_CORE}/user/select-models`;
export const GET_TASKS_TYPES = `${GLOBAL_PATH_TASKS}/task-types`;
export const GET_TASKS_STATUS = GET_TASK_STATES;
// export const GET_ACCOUNT_LABELS = `${GLOBAL_PATH_CORE}/dictionary-account-labels/drop-down`;
// export const GET_RESULT_LAST_CONTACT = `${GLOBAL_PATH_CORE}/dictionary-account-status/drop-down`;
// export const GET_TYPE_CLIENT_DROP_DOWN = `${GLOBAL_PATH_CORE}/dictionary-account-types/drop-down`;
export const GET_RESPONSIBLES = `${GLOBAL_PATH_TASKS}/tasks/change-manager/drop-down`;
export const GET_ASSISTANTS = `${GLOBAL_PATH_TASKS}/tasks/assistance/drop-down`;
export const GET_TASK_RESPONSIBLES = `${GLOBAL_PATH_CORE}/user/drop-down?take=all`;
export const GET_COUNT_TASK = `${GET_TASKS}/get-count-task`;
