import React from 'react';
import * as PropTypes from 'prop-types';
import { MagicInput, } from '../../../../../MagicInput';

export class MagicFilterInputText extends React.Component {
	static propTypes = {
		disabled: PropTypes.bool.isRequired,
		filter: PropTypes.object.isRequired,
		placeholder: PropTypes.string.isRequired,
	};

	get filter() {
		return this.props.filter;
	}

	get mentor() {
		return this.filter.mentor;
	}

	onChange = async (value) => this.mentor
		.filterChange({
			id: this.filter.$id,
			setter: { v: value.length ? value : null, },
			$$changed: true,
		});


	onValid = async (value) => {};

	render = () => (<MagicInput
		className={[
			'magic-filter-input-text',
			'magic-input__filter-input-text',
		]}
		disabled={ this.props.disabled }
		placeholder={ this.props.placeholder }
		value={ this.filter.value ?? '' }
		onChange={ this.onChange }
		onValid={ this.onValid }
	/>);
};
