const LinkConfig = (translate, order, options = {}, permissions) => [
	{
		id: 'bonuses',
		link: '/bonuses',
		text: translate('menu_bonuses_client_list'),
	},
	{
		id: 'dashboard',
		link: '/dashboard',
		text: translate('menu_dashboard'),
	},
]
	.filter(({ id, }) => order.includes(id))
	.sort(({ id: a, }, { id: b, }) => order.indexOf(a) - order.indexOf(b))
	.map(({ id, ...data }) => ({ ...data, key: id, }))
	.filter((item) => {
		if (permissions) {
			return permissions?.[item?.key]?.access('index') ?? false;
		}

		return true;
	});

export default LinkConfig;