/* eslint-disable template-curly-spacing */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable require-await */
/* eslint-disable no-param-reassign */
/* eslint-disable sort-keys */
/* eslint-disable no-return-await */
import React, { Component, } from "react";
import { withLocalize, } from "react-localize-redux";

import { Input, Popup, } from "../../../../components";
import { StaticData, RejectForm, Form, } from "./components/components";

import {
	getAllCountries,
	getAllUsersByDepartment,
	editRequest,
} from "../../../../../../services/PartnersRequestService";

import { validateEmptyFields, } from "./helpers/helpers";
import PropTypes from "prop-types";
import Loader from "../../../../../tima/components/Loader";
import Preloader from "../../../../../../components/LoadingHOC/Preloader";
class ViewEditPartnersRequest extends Component {
	static propTypes = {
		onClose: PropTypes.func.isRequired,
		// eslint-disable-next-line sort-keys
		enums: PropTypes.object,
		formData: PropTypes.object,
		onDataLoad: PropTypes.func,
	};

	constructor (props) {
		super(props);

		this.state = {
			account_fname: "",
			account_id: "",
			allCountries: "",
			allPrograms: "",
			allUsers: "",
			brokers: "",
			count_ref_per_month: "",
			countries: "",
			created_at: "",
			errors: [],
			experience: "",
			formData: {
				ban_forever: 0,
				prohibit_application_for: "",
				reason: "",
				status: "",
				user_id: "",
			},
			id: "",
			tools: "",
			updated_at: "",
			comment: "",
			web_resources: "",
		};
	}

	get currentLanguage () {
		const { languages, } = this.props;

		return languages[0].active ? "en" : languages[1].active ? "ru" : "uk";
	}

	get isEditMode () {
		const { status, } = this.props.popupData;

		return status === 0;
	}

	get countries () {
		const { allCountries, countries, } = this.state;

		if (!allCountries || !countries) {
			return "";
		}

		const key = `name_${this.currentLanguage}`;

		return countries
			?.map((country) => {
				return allCountries.find(index => index.core.country.id === country)
					?.core.country[key];
			})
			.join(", ");
	}

	get users () {
		const { allUsers, } = this.state;

		if (!allUsers) {
			return [];
		}

		return allUsers.map((user) => {
			return {
				label: `${user.name} ${user.surname}`,
				value: user.id,
			};
		});
	}

	get programsAllOptions () {
		const { allPrograms, } = this.state;

		if (!allPrograms) {
			return [];
		}

		return allPrograms.map((program) => {
			return {
				label: program.partners.programs.client_name,
				value: program.partners.programs.id,
			};
		});
	}

	get programsAvailableOptions () {
		const { allPrograms, } = this.state;

		if (!allPrograms) {
			return [];
		}

		return allPrograms
			.filter(
				program =>
					+program.partners.programs.status !== 1 &&
					+program.partners.programs.status !== 5,
			)
			.map((program) => {
				return {
					label: program.partners.programs.client_name,
					value: program.partners.programs.id,
				};
			}); //not NEW or CLOSED
	}

	get statuses () {
		const { allStatuses, translate, } = this.props;
		const prefix = "partners_request_partner_status_";

		return Object.values(allStatuses).map((status, index) => {
			return {
				label: translate(`${prefix}${status}`),
				value: index,
			};
		});
	}

	get tools () {
		const { enums, } = this.props;
		const { tools, } = this.state;

		if (!tools) {
			return "";
		}

		return Object.values(enums.partners.PartnersRequestToolsEnum)
			.filter((element, index) => tools.some(tool => tool === index))
			.join(", ");
	}

	async componentDidMount () {
		try {
			const allCountries = await getAllCountries();
			const allUsers = await getAllUsersByDepartment("affiliate");

			const {
				id,
				account_id,
				account_fname,
				created_at,
				experience,
				brokers,
				tools,
				count_ref_per_month,
				countries,
				user_id,
				status,
				updated_at,
				ban_request,
				approved_program_id,
				program_id,
				allPrograms,
				comment,
				web_resources,
			} = this.props.popupData;

			const {
				reason,
				forever: ban_forever,
				to: prohibit_application_for,
			} = ban_request;

			const convetredCountries = await JSON.parse(countries); //back returns JSON
			const convetredTools = await JSON.parse(tools); //back returns JSON

			await this.save({
				account_fname,
				account_id,
				allCountries: allCountries.data,
				allPrograms,
				allUsers,
				brokers,
				count_ref_per_month,
				countries: convetredCountries,
				created_at,
				program_id,
				experience,
				formData: {
					ban_forever,
					approved_program_id: approved_program_id ?? "",
					prohibit_application_for,
					reason,
					status,
					user_id: user_id ?? "",
				},
				id,
				loadData: true,
				tools: convetredTools,
				updated_at,
				comment,
				web_resources,
			});
		} catch (e) {
			console.log(e);
		}
	}

	async componentDidUpdate (prevProps, prevState) {
		const { formData, } = this.state;
		const prevStatus = prevState.formData.status;
		const currentStatus = formData.status;

		if (prevStatus !== "" && prevStatus !== currentStatus) {
			await this.save({
				formData: {
					...formData,
					prohibit_application_for: "",
					ban_forever: 0,
					reason: "",
				},
			});
		}
	}
	save = async state => new Promise(next => this.setState(state, next));

	saveData = async () => {
		const isValidate = await validateEmptyFields(
			this.state,
			this.save,
			this.users,
			this.programsAvailableOptions,
		);

		if (!isValidate) {
			return;
		}

		this.isEditMode ? await this.updateRequest() : "";
	};

	getSendData = () => {
		const {
			user_id,
			approved_program_id,
			status,
			reason,
			prohibit_application_for,
			ban_forever,
		} = this.state.formData;

		return {
			user_id,
			approved_program_id,
			status,
			ban_request_reason: reason,
			...status !== 1 && {
				//not Rejected
				ban_request_forever: ban_forever,
				ban_request_to: prohibit_application_for,
			},
			...ban_forever
				? { ban_request_forever: ban_forever, }
				: { ban_request_to: prohibit_application_for, }, //Rejected and baned forever
		};
	};

	updateRequest = async () => {
		const { onDataLoad, onClose, } = this.props;
		const { id, } = this.state;

		try {
			await this.save({
				errors: [],
				loadData: false,
			});
			await editRequest(id, this.getSendData());
			await Promise.all([ onClose(), onDataLoad(), ]);
		} catch (error) {
			await error?.showErrorNotification?.();
		} finally {
			await this.save({ loadData: true, });
		}
	};

	handleOnChange = async (event) => {
		const { name, value, } = event.target;

		await this.save(state => ({
			...state,
			errors: state.errors.filter(item => item !== name),
			formData: {
				...state.formData,
				[name]: value.value,
			},
		}));
	};

	renderTitle = () => {
		const { translate, } = this.props;

		if (this.isEditMode) {
			return translate("partners_request_edit");
		}

		return translate("partners_request");
	};

	renderStaticData = () => {
		return (
			<StaticData
				countries={this.countries}
				enums={this.props.enums}
				programsAllOptions={this.programsAllOptions}
				state={this.state}
				tools={this.tools}
				translate={this.props.translate}
			/>
		);
	};

	renderRejectForm = () => {
		return (
			<RejectForm
				currentLanguage={this.currentLanguage}
				errors={this.state.errors}
				formData={this.state.formData}
				isEditMode={this.isEditMode}
				save={this.save}
				translate={this.props.translate}
			/>
		);
	};

	renderForm = () => {
		return (
			<Form
				handleOnChange={this.handleOnChange}
				isEditMode={this.isEditMode}
				programsAvailableOptions={this.programsAvailableOptions}
				renderRejectForm={this.renderRejectForm}
				state={this.state}
				statuses={this.statuses}
				translate={this.props.translate}
				users={this.users}
			/>
		);
	};

	renderTimeStamp () {
		const { updated_at, } = this.state;
		const { translate, } = this.props;

		return (
			<>
				<div className="view-edit-request__row">
					<Input
						disabled={true}
						label={translate("partners_request_processing_date")}
						value={updated_at}
						wrapperClassName={"view-edit-request__timestamp"}
					/>
				</div>
			</>
		);
	}

	render () {
		const { onClose, showPopUp, translate, } = this.props;
		const { loadData, } = this.state;
		const isEditMode = this.isEditMode;

		return (
			<Loader
				loaded={loadData}
				loading={(<Preloader scale={this.props.scale} />)}
				translate={translate}
			>
				<Popup
					cancelBtnText={"partners_payout_rate_list_popup_cancel"}
					createBtnText={
						isEditMode ? null : "partners_payout_rate_list_popup_save"
					}
					customClassName={`max-height-popup view-edit-request ${isEditMode &&
					"view-edit-request_edit"}`}
					isImage={false}
					loadData={loadData}
					showPopUp={showPopUp}
					size={"sm"}
					title={this.renderTitle()}
					onClick={this.saveData}
					onClose={onClose}
				>
					{this.renderStaticData()}
					{this.renderForm()}
					{!isEditMode && this.renderTimeStamp()}
				</Popup>
			</Loader>
		);
	}
}

export default withLocalize(ViewEditPartnersRequest);