import React, { Component, } from "react";
import { withLocalize, } from "react-localize-redux";
import { withRouter, } from "react-router-dom";
import { enumService, } from "../../../../../services/EnumDataService";

import { HorizontalMenu, } from "../../../components";

import ReferralsListMagicTable from "./Table/ReferralsListMagicTable";
import PartnersSystemHeader from "../../ components/PartnersSystemHeader";
import { REFERRALS_LIST_EXPORT_FIELDS, } from "./consts/REFERRALS_LIST_EXPORT_FIELDS";

import {
	MagicFilterPanels,
	Mentor,
	showExportToExcelError,
} from "../../../../../components/Magic";
import PagePanel from "../../../../tima/components/Pages/PagePanel";

import { getPartnersReferrals, } from "../../../../../services/PartnersRequestService";
import PermissionService from "app/services/PermissionService";
import { securedRequest, } from "../../../../../services/RequestService/index";

import * as configBreadcrumbs from "./Config/BreadcrumbsConfig";

class ReferralsList extends Component {
	constructor (props) {
		super(props);

		this.state = {
			autoFilterWasApplied: false, //auto filter triggers addition call of onDataLoad method, which triggers loader twice, state check prevents it;
			data: [],
			enums: {},
			filterLastChange: Date.now(),
			isEmptyData: false,
			isOpenCreatePopup: false,
			isOpenPopup: false,
			loaded: false,
			meta: {},
			pages: {
				filter: 0,
				take: 50,
				total: 0,
				variance: 2,
			},
			popupData: {},
		};

		const mf = new Mentor({
			owner: this,
			serviceId: this.tableByAccountId ? 187 : 188,
			translate: this.props.translate,
		});

		Object.defineProperty(this, "mf", { get: () => mf, });
	}

	get tableByAccountId () {
		return this.props.accountId;
	}

	// get getReferrals () {
	// 	//switcher between partnerCard referrals-list and general referrals-list
	// 	const accountId = this.props?.accountId;
	//
	// 	return accountId ? pageOptions => getPartnersReferralsByPartner(accountId, pageOptions) : getPartnersReferrals;
	// }

	async componentDidMount () {
		this.mf.subscribe(
			{
				changed: this.mfChanged,
				cleaned: this.mfDone,
				cleaning: this.mfDoing,
				loaded: this.mfDone,
				loading: this.mfDoing,
				saved: this.mfDone,
				saving: this.mfDoing,
			},
			this,
		);

		await this.mf.init({ doEmit: true, });

		if (this.tableByAccountId) {
			await this.usePartnerAutoFilter();
			await this.mfDone();
		}

		enumService.subscribe("enums", this.enumsChange, this);
		await enumService.enums;
	}

	usePartnerAutoFilter = async () => {
		const partnerId =  this.props.accountId;

		await this.mf.filterChange({
			id: 'f:referralPartnerLevelOne',
			setter: { v: partnerId, enabled: 1, },
			$$changed: true,
		});

		await this.mf.apply({ doEmit: false, });
		await this.save({ autoFilterWasApplied: true, });
	}
	UNSAFE_componentWillUnmount () {
		enumService.unsubscribe("enums", this.enumsChange, this);
		this.mf.unsubscribe([ this.mfChanged, this.mfDoing, this.mfDone, ], this);
	}

	// eslint-disable-next-line require-await
	save = async state => new Promise(next => this.setState(state, next));

	// eslint-disable-next-line no-return-await
	onChange = async (name, value) => await this.save({ [name]: value, });

	params = () => this.props.match.params;

	mfChanged = async () => {
		await this.save({ filterLastChange: Date.now(), });
	};

	mfDoing = async () => {
		await this.save({ loaded: false, });
	};

	mfDone = async () => {
		await this.mfChanged();
		await this.onDataLoad();
	};

	enumsChange = async (enums) => {
		if (enums.hash === this.state?.enums?.hash) {
			return `${ this.constructor.name }.enumsChange: false`;
		}
		await this.save({ enums, });

		return `${ this.constructor.name }.enumsChange: true`;
	};

	query = () => new URLSearchParams(this.props.location.search);

	permission = (action) => {
		const pm = PermissionService.calc([
			{
				key: "user",
				path: [ "partners", "referrals", ],
			},
		]);

		return pm.user.access(action);
	};

	exportToExcel = activeTab => () => {
		if (activeTab) {
			const { filterId: filter_id, } = this.mf;

			const globalUrl = `/api/partners/referrals`;
			const exportUrl = `/export-to-excel`;
			let url = "";
			let file_name = "";

			switch (activeTab) {
				case "referrals": {
					url = `${ globalUrl }${ exportUrl }`;
					file_name = `referrals`;
					break;
				}
				default: {
					break;
				}
			}

			const options = {
				fields: REFERRALS_LIST_EXPORT_FIELDS[activeTab],
				file_name,
			};

			if (filter_id) {
				options.filter_id = filter_id;
			}

			securedRequest.post(url, options).catch((error) => {
				showExportToExcelError(error);
			});
		}
	};

	pageId = () => {
		const page = this.query().get("page");

		return Number(page) || 0;
	};

	pageIdAsGET = (pageId) => {
		const pages = this.state.pages;

		// eslint-disable-next-line no-param-reassign
		pageId = pageId === undefined ? this.pageId() : pageId;
		const { filterId, } = this.mf;
		const result = {
			filterId,
			skip: pageId * pages.take,
			take: pages.take,
		};

		return result;
	};

	onDataLoad = async (pageId) => {
		await this.save({ isEmptyData: false, loaded: false, });

		const pageOptions = this.pageIdAsGET(pageId);
		const loaded = (data, meta) => ({ pages, }) => ({
			data,
			pages: { ...pages, ...meta, },
			...!data.length && { isEmptyData: true, },
		});

		try {
			const response = await getPartnersReferrals(pageOptions);
			const {
				data = [],
				meta: { filter = 0, total = 0, },
			} = response;

			await this.save(loaded(data, { filter, total, }));
		} catch (error) {
			await this.save(loaded([], { filter: 0, total: 0, }));
			showExportToExcelError(error);
		}

		if(this.tableByAccountId){ //only inside PartnerCard components
			//when filter is added automatically, loader triggers twice - we want avoid this behavior
			return this.state.autoFilterWasApplied && await this.save({ loaded: true, });
		}

		 await this.save({ loaded: true, });
	};

	onPageClick = async ({ pageId, }) => {
		await this.onDataLoad(pageId);
	};
	onPageText = (pageId, pageIs) => {
		const { translate, } = this.props;
		const {
			current,
			first,
			prev,
			next,
			last,
		} = pageIs;
		const {
			skipped,
			taken,
			filter,
			total,
		} = pageIs;

		if (skipped || taken) {
			const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : "?";
			const text = skipped ? translate("partners_pages_items") : "";

			return skipped ? `${ text }: ${ id }` : id;
		} else if (filter || total) {
			const id = Number.isInteger(pageId) ? `${ pageId }` : "?";
			const text = translate(filter ? "partners_pages_filtered" : "partners_pages_total");

			return `${ text }: ${ id }`;
		} else if (first || prev || next || last) {
			return "";
		} else if (current) {
			return `${ pageId + 1 }`;
		}

		return `${ pageId + 1 }`;

	};

	togglePopup = async (popupData = {}) => {
		await this.save(state => ({
			isOpenPopup: !state.isOpenPopup,
			popupData,
		}));
	};

	enumsChange = async (enums) => {
		if (enums.hash === this.state?.enums?.hash) {
			return `${ this.constructor.name }.enumsChange: false`;
		}
		await this.save({ enums, });

		return `${ this.constructor.name }.enumsChange: true`;
	};

	toggleCreatePopup = async () => {
		await this.save(state => ({
			isOpenCreatePopup: !state.isOpenCreatePopup,
		}));
	};

	renderActions = () => {
		const pm = PermissionService.calc([
			{
				key: "referrals",
				path: [ "partners", "referrals", ],
			},
		]);

		const excel = pm.referrals.access("exportToExcel");
		const permissionToCreateReferrals = pm.referrals.access("create");

		const { translate, } = this.props;

		return (
			<div className=" top-button-area ">
				{excel && (
					<div
						className="button bordered"
						onClick={ this.exportToExcel("referrals") }
					>
						&nbsp;
						{translate(`managerPage_export_to_excel`)}
					</div>
				)}
				{permissionToCreateReferrals && (
					<div
						className="button button--gradient-pink ml-3"
						onClick={ this.toggleCreatePopup }
					>
						{translate("partners_create_referral_btn")}
					</div>
				)}
			</div>
		);
	};

	render () {
		const {
			loaded,
			data,
			pages,
			enums,
			popupData,
			isOpenPopup,
			isOpenCreatePopup,
		} = this.state;
		const {
			translate,
			accountId,
		} = this.props;

		const breadcrumbsItems = configBreadcrumbs.breadcrumbs4PartnersRequests;
		const permissionToShow = this.permission("show");
		const moreThenOnePage = pages.total > pages.take;
		const head = [
			"referralId",
			"referralName",
			"referralPartnerLevelOne",
			"referralStatus",
			"referralPartner",
			"referralCountry",
			"referralRegister",
			"referralRemunerations",
		];

		return (
			<div className="partners-partners">
				{ this.tableByAccountId ?
					<div className={ 'd-flex justify-content-end mb-5' }>
						{this.renderActions()}
					</div>
					:
					<>
						<PartnersSystemHeader
							actions={ this.renderActions() }
							breadcrumbsItems={ breadcrumbsItems }
							translate={ translate }
						/>

						<HorizontalMenu />
					</>

				}

				{permissionToShow && (
					<>
						{/* eslint-disable-next-line max-len */}
						{!this.tableByAccountId && <MagicFilterPanels mf={ this.mf } show={ true } translate={ translate } />}
						<ReferralsListMagicTable
							accountId={ accountId }
							createPopup={ {
								isOpenCreatePopup,
								toggleCreatePopup: this.toggleCreatePopup,
							} }
							data={ data }
							enums={ enums?.data }
							head={ head }
							loaded={ loaded }
							mf={ this.mf }
							popup={ {
								data: popupData,
								isOpen: isOpenPopup,
								togglePopup: this.togglePopup,
							} }
							translate={ translate }
							onDataLoad={ this.onDataLoad }
						/>
						{moreThenOnePage && <PagePanel
							doText={ this.onPageText }
							doTo={ pageId => `?page=${ pageId }` }
							filter={ pages.filter }
							page={ this.pageId() }
							take={ pages.take }
							total={ pages.total }
							variance={ pages.variance }
							onClick={ this.onPageClick }
						/>}
					</>
				)}
			</div>
		);
	}
}

export default withRouter(withLocalize(ReferralsList));