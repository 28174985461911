import React, { Component } from "react";
import { withLocalize, } from "react-localize-redux";
import '../styles/tima.scss';
import Preloader from "../../../components/LoadingHOC/Preloader";
import PagePanel from './Pages';
import Loader from "./Loader";
import { Link } from "react-router-dom";
import { MagicFilterPanels, Mentor, } from '../../../components/Magic';
import { MagicTable } from "../../../components/Magic";
import { timaService } from "../../../services/TimaDataService";

class ListTimaConditions extends Component {
    constructor (props) {
        super(props);

        const { params, } = props;
        const { strategyId, } = params || {};

        this.state = {
            loaded:           false,
            conditions: {
                data: [],
                hash: null,
            },
            strategyConditions: {
                data: [],
                hash: null,
            },
            pages: {
                filter:   0,
                total:    0,
                take:     50,
                variance: 2,
            },
            strategyId,
            filterLastChange: Date.now(),
        };

        const mf = new Mentor({
            owner: this,
            serviceId: 136,
            translate: this.props.translate,
        });

        Object.defineProperty(this, 'mf', { get: () => mf, });
    }

    // eslint-disable-next-line react/sort-comp, require-await
    save = async state => new Promise(next => this.setState(state, next));

    // eslint-disable-next-line require-await
    mfChanged = async () => {
        await this.save({ filterLastChange: Date.now(), });
    };

    mfDoing = async () => {
        await this.save({ loaded: false, });
    };

    mfDone = async () => {
        await this.mfChanged();
        await this.onDataLoad();
    };

    strategyConditionsChanges = async (strategyConditions) => {
        if (strategyConditions.hash===this.state?.strategyConditions?.hash) {
            return `${this.constructor.name}.strategyConditionsChanges: false`;
        }
        if (strategyConditions.strategyId !== this.state?.strategyId) {
            return `${this.constructor.name}.strategyConditionsChanges: false`;
        }
        if (strategyConditions.meta.filter===this.state?.pages?.filter) {
            return `${this.constructor.name}.strategyConditionsChanges: false`;
        }
        if (strategyConditions.meta.total===this.state?.pages?.total) {
            return `${this.constructor.name}.strategyConditionsChanges: false`;
        }
        const { data, meta, hash } = strategyConditions;
        await this.save({
            strategyConditions: { data, hash },
            pages: {
                ...this.state.pages,
                filter: meta?.filter,
                total: meta?.total,
            },
        });

        return `${this.constructor.name}.strategyConditionsChanges: true`;
    };

    conditionsChanges = async (conditions) => {
        if (conditions.hash===this.state?.conditions?.hash) return `${this.constructor.name}.conditionsChanges: false`;
        if (conditions.meta.filter===this.state?.pages?.filter) {
            return `${this.constructor.name}.conditionsChanges: false`;
        }
        if (conditions.meta.total===this.state?.pages?.total) {
            return `${this.constructor.name}.conditionsChanges: false`;
        }

        const { data, meta, hash } = conditions;
        await this.save({
            conditions: { data, hash },
            pages: {
                ...this.state.pages,
                filter: meta?.filter,
                total: meta?.total,
            },
        });

        return `${this.constructor.name}.conditionsChanges: true`;
    };

    componentDidMount = async () => {
        this.mf.subscribe({
            changed: this.mfChanged,
            // changing: ,
            cleaned: this.mfDone,
            cleaning: this.mfDoing,
            loaded: this.mfDone,
            loading: this.mfDoing,
            saved: this.mfDone,
            saving: this.mfDoing,
        }, this);
        await this.mf.init({ doEmit: true, });
        timaService.subscribe('conditions', this.conditionsChanges, this);
        timaService.subscribe('strategyConditions', this.strategyConditionsChanges, this);
        // await this.mf.emit('change', {});
        // await this.onDataLoad(); // TODO: moved to mfDone
    };

    componentWillUnmount = () => {
        this.mf.unsubscribe([
            this.mfChanged,
            this.mfDoing,
            this.mfDone,
        ], this);
        timaService.unsubscribe('conditions', this.conditionsChanges, this);
        timaService.unsubscribe('strategyConditions', this.strategyConditionsChanges, this);
    };

    get listTimaConditionsConfig () {
        const { translate, } = this.props;
        const prefix = 'tima_conditions_table_';

        return [ {
            path:   ['tima', 'tima_condition', 'version'],
            key:    'version',
            render: this.renderInlineValue,
            title:  `${prefix}version`,
        }, {
            path:   ['tima', 'tima_condition', 'name'],
            key:    'timaCondition',
            render: this.renderTimaLinkField('timaCondition', '/tima/condition'),
            title:  `${prefix}tima_condition`,
        }, {
            path: ['tima', 'tima_condition', 'id'],
            key:  'timaConditionId',
        }, {
            path:   ['tima', 'tima_condition', 'invest_min'],
            key:    'investMin',
            render: this.renderInlineValue,
            title:  `${prefix}invest_min`,
        }, {
            path:   ['tima', 'tima_condition', 'invest_max'],
            key:    'investMax',
            render: this.renderInlineValue,
            title:  `${prefix}invest_max`,
        }, {
            path:   ['tima', 'tima_condition', 'reward'],
            key:    'reward',
            render: this.renderInlineValue,
            title:  `${prefix}reward`,
        }, {
            path:   ['tima', 'tima_condition', 'min_invest_period'],
            key:    'minInvestPeriod',
            render: this.renderInlineValue,
            title:  `${prefix}min_invest_period`,
        }, {
            path:   ['tima', 'tima_condition', 'penalty'],
            key:    'penalty',
            render: this.renderInlineValue,
            title:  `${prefix}penalty`,
        }, {
            path:   ['tima', 'tima_manager', 'name'],
            key:    'timaAccount',
            render: this.renderTimaLinkField('timaAccount', '/tima/strategy'),
            title:  `${prefix}tima_account`,
        }, {
            path: ['tima', 'tima_manager', 'id'],
            key:  'timaAccountId',
        }, {
            path:   ['tima', 'tima_condition', 'created_at'],
            key:    'createdAt',
            render: this.renderInlineValue,
            title:  `${prefix}date_creation`,
        }, {
            path:   ['tima', 'tima_condition', 'updated_at'],
            key:    'updatedAt',
            render: this.renderInlineValue,
            title:  `${prefix}date_closing`,
        }, ].map(({ title: t, xtitle: x, ...item }) => ({ ...item, ...x && { title: x }, ...t && { title: translate(t) }, }));
    }

    renderLink = (path, text, className = "") => (<Link to={path} className={className}>{text}</Link>);

    renderInlineValue = (value, { item, items }) => {
        if (item?.access?.('index')) {
            return value ?? '-';
        }
        return '-';
    };

    renderTimaLinkField = (fieldName, path) => (value, { item, items }) => {
        const id = items?.[`${fieldName}Id`];
        if (item?.access?.('index', 'show')) {
            return this.renderLink(`${path}/${ id.valueOf }`, value);
        } else if (item?.access?.('index')) {
            return value;
        }
        return '-';
    };

    render = () => {
        const { translate, } = this.props;
        const { strategyId, } = this.state;
        let data;
        data = strategyId ? this.state.strategyConditions.data : this.state.conditions.data;

        const options = {
            config: this.listTimaConditionsConfig,
            data,
            head:   [
                'version',
                'timaCondition',
                'investMin',
                'investMax',
                'reward',
                'minInvestPeriod',
                'penalty',
                'timaAccount',
                'createdAt',
                'updatedAt',
            ],
        };

        return (
            <>
                <MagicFilterPanels
                    mf={ this.mf }
                    show={ true }
                    translate={ translate }
                />
                <div className='content-block'>
                    <div className='table-wrapper'>
                        <div className='position-relative'>

                            <Loader
                                loaded={this.state.loaded}
                                loading={(<Preloader scale={this.props.scale}/>)}
                                translate={this.props.translate}
                            >
                                <MagicTable {...options} />

                                <PagePanel
                                    filter={this.state.pages.filter}
                                    take={this.state.pages.take}
                                    total={this.state.pages.total}
                                    variance={this.state.pages.variance}
                                    page={this.pageId()}
                                    onClick={this.onPageClick}
                                    doText={this.onPageText}
                                    doTo={(pageId) => `?page=${ pageId }`}
                                />
                            </Loader>

                        </div>
                    </div>
                </div>
            </>
        );
    };

    pageId = () => {
        const { query } = this.props;
        const page = query.get('page');
        return Number(page) || 0;
    };

    pageIdAsGET = (pageId) => {
        pageId = pageId === undefined ? this.pageId() : pageId;
        const { filterId ,} = this.mf;
        const result = {
            filterId,
            take: this.state.pages.take,
            skip: pageId * this.state.pages.take,
        };
        return result;
    };

    onDataLoad = async (pageId) => {
        await this.save({ loaded: false, });
        const pageOptions = this.pageIdAsGET(pageId);
        const { strategyId, } = this.state;
        const loaded = (name, data, meta) => ({ pages, }) => ({
            [name]: data,
            pages: { ...pages, ...meta, },
        });
        try {
            let response,
                name = '';
            if (strategyId) {
                response = await timaService.strategyConditions(strategyId, pageOptions);
                name = 'strategyConditions';
            } else {
                response = await timaService.conditions(pageOptions);
                name = 'conditions';
            }
            const { data = [], meta, hash } = response;
            const { filter = 0, total = 0 } = meta;
            await this.save(loaded(name, { data, hash }, { filter, total, }, ));
        } catch (error) {
            const emptyData = { data: [], hash: null };

            await this.save(loaded(strategyId ? 'strategyConditions' : 'conditions', emptyData, { filter: 0, total: 0, }));

            error?.showErrorNotification?.();
        }
        await this.save({ loaded: true, });
    };

    onPageClick = ({ event, pageId, pageIs, }) => {
        // event.preventDefault();
        this.onDataLoad(pageId);
    };

    onPageText = (pageId, pageIs) => {
        const { translate } = this.props;
        const { current, first, prev, next, last, } = pageIs;
        const { skipped, taken, filter, total, } = pageIs;
        if (skipped || taken) {
            const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : '?';
            const text = skipped ? translate('tima_conditions_pages_items') : '';
            return skipped ? `${ text }: ${ id }` : id;
        } else if (filter || total) {
            const id = Number.isInteger(pageId) ? `${ pageId }` : '?';
            const text = translate(filter ? 'tima_conditions_pages_filtered' : 'tima_conditions_pages_total');
            return `${ text }: ${ id }`;
        } else if (first || prev || next || last) {
            return '';
        } else if (current) {
            return `${ pageId + 1}`;
        } else {
            return `${ pageId + 1}`;
        }
    };
};

export default withLocalize(ListTimaConditions);
