import coreAccounts from './new-core-account';
import listAccountsSystems from './new-list-accounts-systems';
import listAccountsTradings from './new-list-accounts-tradings';

const [ accounts, ] = coreAccounts;
const systems = listAccountsSystems;
const tradings = listAccountsTradings;

export default [ {
  ...accounts,
  filters: accounts.filters.filter(_ => _.$.id === 'f:aFullname'),
  id: 240,
  orders: accounts.orders,
  panels: [],
}, {
  ...accounts,
  filters: accounts.filters.filter(_ => _.$.id === 'f:aEmailPhone'),
  id: 241,
  orders: accounts.orders,
  panels: [],
}, {
  ...systems,
  filters: systems.filters.filter(_ => _.$.id === 'f:aFullname'),
  id: 248,
  orders: systems.orders,
  panels: [],
}, {
  ...systems,
  filters: systems.filters.filter(_ => _.$.id === 'f:saId'),
  id: 249,
  orders: systems.orders,
  panels: [],
}, {
  ...tradings,
  filters: tradings.filters.filter(_ => _.$.id === 'f:aFullname'),
  id: 256,
  orders: tradings.orders,
  panels: [],
}, {
  ...tradings,
  filters: tradings.filters.filter(_ => _.$.id === 'f:taMtLogin'),
  id: 257,
  orders: tradings.orders,
  panels: [],
}, ];