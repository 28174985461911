export const BALANCE_OPERATIONS_EXPORT_FIELDS = {
	balance_operations: [
		"core-account-name",
		"core-account-surname",
		"core-system_accounts_transaction_history-id",
		"core-system_accounts_transaction_history-system_account_id",
		"core-system_accounts_transaction_history-amount",
		"core-system_accounts_transaction_history-comment",
		"core-system_accounts_transaction_history-created_at",
		"core-system_accounts_transaction_history-updated_at",
	],
};