import {
  itemsBuild,
  itemsDrain,
  itemsResort,
  CFG_ITEM_TYPE,
} from '../Config';
// import {} from '../Mentor/withDropDown';

export default {
  id: 52,
  ...[

    // filters
    ...[ {
      f: [ 'core.user.surname', 'core.user.name', ],
      id: 'f:uFullname',
      o: 'like',
    }, {
      f: 'core.departments.name',
      id: 'f:uDepartments',
      o: 'like',
      off: true, // TODO:
    }, {
      f: 'core.user.role_id',
      id: 'f:uRoleId',
      off: true, // TODO:
    }, {
      f: 'core.user_kpi_plan.kpi_type_id',
      id: 'f:uKpiTypeId',
      j: 'y',
    }, {
      f: 'core.user.status',
      id: 'f:uStatus',
    }, {
      auto: 1,
      f: 'core.user_kpi_plan.active',
      id: 'f:uKpiPlanActive',
      j: 'y',
      o: '=',
      v: '1',
    }, {
      f: 'core.user_kpi_plan.year',
      id: 'f:uKpiPlanYear',
      j: 'y',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

    // orders
    ...[{
      f: 'core.user.id',
      id: 'o:uId',
    }, {
      f: 'core.user.surname',
      id: 'o:aSurname',
    }, {
      f: 'core.user_kpi_plan.year',
      id: 'o:uKpiPlanYear',
    }, {
      f: 'core.user_kpi_plan.kpi_type_id',
      id: 'o:uKpiPlanTypeId',
    },
    ...Array(12).fill(0).map((_, index) => ({
      f: `core.user_kpi_plan.kpi_month_${ index + 1 }`,
      id: `o:userKpiPlanMonth-${ index + 1 }`,
    })), 
    {
      f: '',
      id: 'o:',
      off: true, // TODO:
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

    // panels
    ...[{
      ii: 'f:uFullname',
      t: 'magic_filter_translate_user_fullname',
    }, {
      ii: 'f:uDepartments',
      off: true, // TODO:
      t: 'magic_filter_translate_user_department',
    }, {
      ii: 'f:uRoleId',
      off: true, // TODO:
      t: 'magic_filter_translate_user_role',
    }, {
      ii: 'f:uKpiTypeId',
      t: 'magic_filter_translate_user_kpi_plan_kpi_type_id',
    }, {
      ii: 'f:uStatus',
      t: 'magic_filter_translate_user_status',
    }, {
      ii: 'f:uKpiPlanActive',
      off: true, // TODO:
      t: 'magic_filter_translate_user_kpi_plan_active',
    }, {
      ii: 'f:uKpiPlanYear',
      t: 'magic_filter_translate_user_kpi_plan_year',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

  ]
  .map(itemsBuild)
  .reduce(itemsDrain, [])
  .reduce(itemsResort, {}),

  path: 'core/user-kpi-plan',
  widget: false,
};