import LinkConfig from "./LinkConfig";

export const breadcrumbs4ListOfCrons = (translate) => {
	// eslint-disable-next-line new-cap
	return LinkConfig(
		translate,
		[
			"settings",
			"cron",
		],
	);
};