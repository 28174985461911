import { DataService } from "./DataService";
import { get, post, put, del } from "../components/Magic/helpers/MagicRequest";
import {
    CHANGE_RESPONSIBLE, CREATE_MESSAGE,
    DELETE_FILE_MESSAGE, EDIT_MESSAGE,
    GET_MESSAGES,
    GET_RESPONSIBLE,
    GET_TICKETS
} from "../modules/support/apiRoutes";
import { hash as hashData } from "./CachingService";

class SupportDataService extends DataService {
    constructor () {
        super([
            'getTickets',
            'getTicketById',
            'getResponsibles',
            'getMessagesByTicketId',
            'changeResponsible',
            'closeTicketById',
            'deleteFileMessage',
            'changeMessageById',
            'createMessageByTicketId',
        ]);
        Object.freeze(this);
    }

    async getTickets ({ filterId, take, skip }) {
        const url = GET_TICKETS;
        const data = await get(url, { filterId, take, skip });
        const hash = await hashData(data);
        const tickets = { data, hash, };
        await this.emit('getTickets', tickets);

        return tickets;
    }

    async getTicketById (id) {
        const url = `${GET_TICKETS}/${id}`;
        const data = await get(url);
        await this.emit('getTicketById', data);

        return data;
    }

    async getResponsibles () {
        const url = GET_RESPONSIBLE;
        const data = await get(url);
        await this.emit('getResponsibles', data);

        return data;
    }

    async getMessagesByTicketId (id) {
        const url = `${GET_MESSAGES}/${id}`;
        const data = await get(url);
        await this.emit('getMessagesByTicketId', data);

        return data;
    }

    async changeResponsible (id, options) {
        const url = `${CHANGE_RESPONSIBLE}/${id}`;
        const data = await put(url, options);
        await this.emit('changeResponsible', data);

        return data;
    }

    async closeTicketById (id, { status }) {
        const url = `${GET_TICKETS}/${id}`;
        const data = await put(url, { status });
        await this.emit('closeTicketById', data);

        return data;
    }

    async deleteFileMessage (id) {
        const url = `${DELETE_FILE_MESSAGE}/${id}`;
        const data = await del(url);
        await this.emit('deleteFileMessage', data);

        return data;
    }

    async changeMessageById (id, formData) {
        const url = `${EDIT_MESSAGE}/${id}`;
        const data = await post(url, {}, formData);
        await this.emit('changeMessageById', data);

        return data;
    }

    async createMessageByTicketId (formData) {
        const url = `${CREATE_MESSAGE}`;
        const data = await post(url, {}, formData);
        await this.emit('createMessageByTicketId', data);

        return data;
    }
}

export const supportService = new SupportDataService();
