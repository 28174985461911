import React, { Component, } from "react";
import { Link, withRouter, } from "react-router-dom";
import { withLocalize, } from "react-localize-redux";

import {
	MagicTable, Mentor, MagicFilterPanels,
} from "../../../../../components/Magic";
import {
	rebateProjects,
} from "../../../../../services/PartnersRequestService";
import { enumService, } from "../../../../../services/EnumDataService";

import PagePanel from "../../../../tima/components/Pages";
import Preloader from "../../../../../components/LoadingHOC/Preloader";
import { showExportToExcelError, } from "../../../../../components/Magic";

class RebateTable extends Component {
	constructor (props) {
		super(props);

		this.state = {
			data: [],
			languages: null,
			filterLastChange: Date.now(),
			isEmptyData: false,
			loaded: false,
			meta: {},
			pages: {
				filter: 0,
				take: 50,
				total: 0,
				variance: 2,
			},
		};

		const mf = new Mentor({
			owner: this,
			serviceId: 163,
			translate: this.props.translate,
		});

		Object.defineProperty(this, "mf", { get: () => mf, });
	}

	async componentDidMount () {
		enumService.subscribe('enums', this.enumsChange, this);
		await enumService.enums;

		this.mf.subscribe(
			{
				changed: this.mfChanged,
				cleaned: this.mfDone,
				cleaning: this.mfDoing,
				loaded: this.mfDone,
				loading: this.mfDoing,
				saved: this.mfDone,
				saving: this.mfDoing,
			},
			this,
		);

		await this.mf.init({ doEmit: true, });
	}

	componentWillUnmount () {
		enumService.unsubscribe('enums', this.enumsChange, this);
	}
  save = state => new Promise(next => this.setState(state, next));

  onChange = (name, value) => this.save({ [name]: value, });

  params = () => this.props.match.params;

  mfChanged = async () => {
  	const { handleFilterIdChange, } = this.props;
  	const { filterId, } = this.mf;

  	await handleFilterIdChange(filterId);
  	await this.save({ filterLastChange: Date.now(), });
  };

  mfDoing = async () => {
  	await this.save({ loaded: false, });
  };

  mfDone = async () => {
  	await this.mfChanged();
  	await this.onDataLoad();
  };

  enumsChange = async (enums) => {
  	if (enums.hash === this.state?.enums?.hash) {
  		return `${ this.constructor.name }.enumsChange: false`;
  	}
  	await this.save({ enums, });

  	return `${ this.constructor.name }.enumsChange: true`;
  };
  query = () => new URLSearchParams(this.props.location.search);

  onDataLoad = async (pageId) => {
  	await this.save({ isEmptyData: false, loaded: false, });

  	const pageOptions = this.pageIdAsGET(pageId);
  	const loaded = (data, meta) => ({ pages, }) => ({
  		data,
  		pages: { ...pages, ...meta, },
  		...!data.length && { isEmptyData: true, },
  	});

  	try {
  		const response = await rebateProjects(pageOptions);
  		const {
  			data = [],
  			meta: { filter = 0, total = 0, },
  		} = response;

  		await this.save(loaded(data, { filter, total, }));
  	} catch (error) {
  		await this.save(loaded([], { filter: 0, total: 0, }));
  		showExportToExcelError(error);
  	}
  	await this.save({ loaded: true, });
  };
  get config () {
  	const { translate, } = this.props;
  	const prefix = "partners_rebate_";

  	return [

  		{
  			key: "rebateCountSum",
  			path: [ "partners", "rebate_statistic", "count_sum_rebate", ],
  		},
  		{
  			key: "accountName",
  			path: [ "core", "account", "name", ],
  		},
  		{
  			key: "accountSurname",
  			path: [ "core", "account", "surname", ],
  		},
  		{
  			key: "rebateId",
  			orderId: "o:rebateId",
  			path: [ "partners", "rebate_projects", "id", ],
  			render: this.renderInlineValue,
  			title: `${ prefix }id`,
  		},

  		{
  			key: "rebatePartner",
  			orderId: "o:rebatePartner",
  			path: [ "partners", "partners_setup", "id", ],
  			render: this.renderPartnerName,
  			title: `${ prefix }partner`,
  		},
  		{
  			key: "rebateProject",
  			orderId: "o:rebateProject",
  			path: [ "partners", "rebate_projects", "name", ],
  			render: this.renderInlineValue,
  			title: `${ prefix }project`,
  		},
  		{
  			key: "rebateRate",
  			orderId: "o:rebateRate",
  			path: [ "partners", "rebate_projects", "rate", ],
  			render: this.renderInlineValue,
  			title: `${ prefix }rate`,
  		},
  		{
  			key: "rebateCondition",
  			orderId: "o:rebateCondition",
  			path: [ "partners", "rebate_projects", "condition", ],
  			render: (value, { item, }) => this.renderEnumField(value, 'RebateProjectConditionEnum', { item, }),
  			title: `${ prefix }condition`,
  		},
  		{
  			key: "rebateStatus",
  			orderId: "o:rebateStatus",
  			path: [ "partners", "rebate_projects", "status", ],
  			render: (value, { item, }) => this.renderEnumField(value, 'RebateProjectStatusEnum', { item, }),
  			title: `${ prefix }status`,
  		},
  		{
  			key: "rebateApplicationDate",
  			orderId: "o:rebateApplicationDate",
  			path: [ "partners", "rebate_projects", "created_at", ],
  			render: this.renderDate,
  			title: `${ prefix }application_date`,
  		},
  		{
  			key: "rebateSum",
  			orderId: "o:rebateSum",
  			path: [ "partners", "rebate_projects", "id", ],
  			render: this.renderRebateSum,
  			title: `${ prefix }sum`,
  		},

  	].map(({
  		orderId: o, title: t, xtitle: x, ...item
  	}) => ({
  		...item,
  		...o && {
  			order: async () => {
  				await this.mf.orderedToNext(o);
  			},
  			orderTo: this.mf.orderedTo(o),
  		},
  		...t && { title: translate(t), },
  		...x && { title: x, },
  	}));
  }

  renderInlineValue = (value, { item, items, }) => {
  	if (item.access("index")) {
  		return value ?? "-";
  	}

  	return "-";
  };

  renderPartnerName = (value, { item, items, }) => {
	  const accountName = items.accountName;
	  const accountSurname = items.accountSurname;

	  const fullName = `${ accountName.access('index') ? accountName.valueOf : '' } ${ accountSurname.access('index') ? accountSurname.valueOf : '' }`;

  	if (item.access("index") && fullName.trim()) {
  		return <Link to={ `/partners/requests/partners-list/${ value }` }>{fullName}</Link>;
  	}

  	return "-";
  };

  renderRebateSum = (value, { item, items, }) => {
  	if (item.access("index")) {
  		return items.rebateCountSum.valueOf ?? "-";
  	}

  	return "-";
  };
  renderDate = (value, { item, items, }) => {
  	if (item.access("index") && value) {
      //date and time on separate lines
  		return value.split(' ').map((date, index) => <div key={ `date-${ items.bannerId.valueOf }-${ index }` }>{date}</div>);
  	}

  	return "-";
  };

  renderEnumField = (value, enumField, { item, }) => {
  	if (item.access("index")) {
  		const { enums, } = this.state;

  		return enums.data.partners[enumField][value];
  	}

  	return "-";
  };

  renderPartnersListMagicTableTable () {
  	const { translate, } = this.props;
  	const { loaded, data, } = this.state;

  	const head = [
  		'rebateId',
  		'rebatePartner',
  		'rebateProject',
  		'rebateRate',
  		'rebateCondition',
  		'rebateStatus',
  		'rebateApplicationDate',
  		'rebateSum',
  	];

  	if (!loaded) {
  		return <Preloader />;
  	}
  	if (data.length === 0) {
  		return <h4 className="mx-auto text-align-center pt-5">{translate("symbols_empty")}</h4>;
  	}

  	const options = {
  		config: this.config,
  		data,
  		head,
  	};

  	return (
		  //eslint-disable-next-line
		<>
			<MagicTable { ...options } />
  		</>
  	);
  }

  render () {
  	const { pages, enums, } = this.state;
  	const {
  		translate, getPermissions,
  	} = this.props;

  	const showPagePannel = pages.total > pages.take;

  	const permissionToShow = getPermissions('index');

  	return (
        //eslint-disable-next-line
        <>
	{ permissionToShow &&
	<div>
			<MagicFilterPanels
				mf={ this.mf }
				show={ true }
				translate={ translate }
			/>
			{this.renderPartnersListMagicTableTable()}
			{
				showPagePannel && <PagePanel
					doTo={ pageId => `?page=${ pageId }` }
					filter={ pages.filter }
					page={ this.pageId() }
					take={ pages.take }
					total={ pages.total }
					variance={ pages.variance }
					onClick={ this.onPageClick }
				/>
			}
		</div>
  			}
  		</>
  	);
  }

  pageId = () => {
  	const page = this.query().get("page");

  	return Number(page) || 0;
  };

  pageIdAsGET = (pageId) => {
  	const pages = this.state.pages;

    // eslint-disable-next-line no-param-reassign
  	pageId = pageId === undefined ? this.pageId() : pageId;
  	const { filterId, } = this.mf;
  	const result = {
  		filterId,
  		skip: pageId * pages.take,
  		take: pages.take,
  	};

  	return result;
  };
  // eslint-disable-next-line no-unused-vars
  onPageClick = async ({ event, pageId, pageIs, }) => {
  	await this.onDataLoad(pageId);
  };
}

export default withRouter(withLocalize(RebateTable));