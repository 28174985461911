import React                                from 'react';
import { withRouter, Link }                 from 'react-router-dom';
import { withLocalize }                     from "react-localize-redux";
import '../../../../style/_auto-call.scss';
import { MagicSelect, }                     from "../../../../components/Magic";
import { callService, }                     from "../../../../services/CallDataService";
import Preloader from "../../../../components/LoadingHOC/Preloader";
import Loader from "../../../tima/components/Loader";

class AutoCall extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      userCallSettings: {
        data: {
          days_to_no_answer_state: 0,
          pause_after_auto_dial: 0,
          unsuccessful_tries_in_row: 0,
          time_to_answer: 0,
          tries: 0,
          wrap_up_time: 0,
        },
        hash: null,
      },
    };
  }

  // eslint-disable-next-line react/sort-comp, require-await
  save = async state => new Promise(next => this.setState(state, next));

  userCallSettingsChange = async (userCallSettings) => {
    await this.save({ loaded: false });
    if (userCallSettings.hash===this.state.userCallSettings?.hash) {
      return `${ this.constructor.name }.userCallSettingsChange: false`;
    }
    const { hash, user_calls_setting } = userCallSettings;
    await this.save({ userCallSettings: {
        hash,
        data: user_calls_setting,
      },
    });
    await this.save({ loaded: true });

    return `${ this.constructor.name }.userCallSettingsChange: true`;
  };

  componentDidMount = async () => {
    document.querySelector("#crm-app").dataset.autocall = true;
    callService.subscribe('userCallSettings', this.userCallSettingsChange, this);
    await callService.userCallSettings();
  };

  componentWillUnmount () {
    document.querySelector("#crm-app").dataset.autocall = false;
    callService.unsubscribe('userCallSettings', this.userCallSettingsChange, this);
  }

  generateDefaultValue = (number) => {
    return Array.from({ length: number, }, (value, key) => key + 1);
  };

  sendData = async (type, value) => {
    try {
      await callService.updateUserCallSettings({ [type]: value, });
      await this.save({ userCallSettings: {
          ...this.state.userCallSettings,
          data: {
            ...this.state.userCallSettings.data,
            [type]: value,
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  renderPause = () => {
    const staticValues = this.generateDefaultValue(30);
    const options = {
      value:    this.state.userCallSettings.data.pause_after_auto_dial,
      onChange: async value => {
        await this.sendData('pause_after_auto_dial', value);
      },
      values: staticValues,
      valueToLabel: (id) => {
        const find = staticValues.find((data) => id === data);

        return `${find}` ?? this.props.translate('auto_call_default_value');

      },
    };
    return (
        <div className='magic-block'>
          <div className="tittle">{this.props.translate('auto_call_first_select')}, {this.props.translate('auto_call_text_days')}</div>
          <MagicSelect { ...options } />
        </div>
    );
  };

  renderUnsuccessfulDials = () => {
    const staticValues = this.generateDefaultValue(10);
    const options = {
      value:    this.state.userCallSettings.data.unsuccessful_tries_in_row,
      onChange: async value => {
        await this.sendData('unsuccessful_tries_in_row', value);
      },
      values: staticValues,
      valueToLabel: (id) => {
        const find = staticValues.find((data) => id === data);

        return `${find}` ?? this.props.translate('auto_call_default_value');

      },
    };
    return (
        <div className='magic-block'>
          <div className="tittle">{this.props.translate('auto_call_third_select')}</div>
          <MagicSelect { ...options } />
        </div>
    );
  };

  renderDialTries = () => {
    const staticValues = this.generateDefaultValue(5);
    const options = {
      value:    this.state.userCallSettings.data.tries,
      onChange: async value => {
        await this.sendData('tries', value);
      },
      values: staticValues,
      valueToLabel: (id) => {
        const find = staticValues.find((data) => id === data);

        return `${find}` ?? this.props.translate('auto_call_default_value');

      },
    };
    return (
        <div className='magic-block'>
          <div className="tittle">{this.props.translate('auto_call_fours_select')}</div>
          <MagicSelect { ...options } />
        </div>
    );
  };

  renderTimeToAnswer = () => {
    const staticValues = this.generateDefaultValue(10);
    const options = {
      value:    this.state.userCallSettings.data.time_to_answer,
      onChange: async value => {
        await this.sendData('time_to_answer', value);
      },
      values: staticValues,
      valueToLabel: (id) => {
        const find = staticValues.find((data) => id === data);

        return `${find} ${ this.props.translate('auto_call_text_sec')}` ?? this.props.translate('auto_call_default_value');

      },
    };

    return (
        <div className='magic-block'>
          <div className="tittle">{this.props.translate('auto_call_fifth_select')}</div>
          <MagicSelect { ...options } />
        </div>
    );
  };

  renderAutoDealerInterval = () => {
    const staticValues = this.generateDefaultValue(60);
    const options = {
      value:    this.state.userCallSettings.data.wrap_up_time,
      onChange: async value => {
        await this.sendData('wrap_up_time', value);
      },
      values: staticValues,
      valueToLabel: (id) => {
        const find = staticValues.find((data) => id === data);

        return `${find} ${ this.props.translate('auto_call_text_sec')}` ?? this.props.translate('auto_call_default_value');

      },
    };

    return (
        <div className='magic-block'>
          <div className="tittle">{this.props.translate('auto_call_six_select')}</div>
          <MagicSelect { ...options } />
        </div>
    );
  };

  renderDaysToNoAnswer = () => {
    const staticValues = this.generateDefaultValue(30);
    const options = {
      value:    this.state.userCallSettings.data.days_to_no_answer_state,
      onChange: async value => {
        await this.sendData('days_to_no_answer_state', value);
      },
      values: staticValues,
      valueToLabel: (id) => {
        const find = staticValues.find((data) => id === data);

        return `${find}` ?? this.props.translate('auto_call_default_value');

      },
    };
    return (
        <div className='magic-block'>
          <div className="tittle">{this.props.translate('auto_call_second_select')}, {this.props.translate('auto_call_text_days')}</div>
          <MagicSelect { ...options } />
        </div>
    );
  };

  render = () => {
    return (
      <Loader loaded={ this.state.loaded } loading={ <Preloader scale={ 1 } /> }>
        <React.Fragment>
          <div className='top-page-block'>
            <div className='title-wrapper'>
              <h1 className='page-title'>{this.props.translate(`auto_call_tittle`)}</h1>
              <div className='breadcrumbs'>
                <Link to='/settings'>{this.props.translate(`menu_admin_panel`)} </Link> > <Link to='/settings/auto-call'>{this.props.translate(`auto_call_tittle`)}</Link></div>
            </div>
          </div>
          <div className="auto-call">
            {this.renderPause()}
            {this.renderUnsuccessfulDials()}
            {this.renderDialTries()}
            {this.renderTimeToAnswer()}
            {this.renderAutoDealerInterval()}
            {this.renderDaysToNoAnswer()}
          </div>
        </React.Fragment>
      </Loader>
    );
  };

}

export default withRouter(withLocalize(AutoCall));
