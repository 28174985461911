import { Mentor, } from '../Mentor';

/**
 * Validate Storage constructor arguments
 *
 * @function
 * @arg {Mentor} mentor - Mentor instance
 * @returns {undefined} throw Error if any validation failed
 */
export const valid = (mentor) => {

  const msg = text => new Error(`Expect Storage${ text }`);

  if (!(mentor instanceof Object)) {
    throw msg(`(mentor) as Object`);
  } else if (!(mentor instanceof Mentor)) {
    throw msg(`(mentor) as Mentor`);
  }

};