import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import { asyncGet } from "../../../../services/PartnersRequestService";
import Preloader from "../../../../components/LoadingHOC/Preloader";
import Loader from "../../../tima/components/Loader";
import PermissionService from "../../../../services/PermissionService";
import SystemAccountBalanceHistory from "./SystemAccountBalanceHistory";
import PopupDepositWithdrawal from "./PopupDepositWithdrawal";
import { Link } from "react-router-dom";
import {STATUS_ACTIVE} from "../../../../enums/account";
import { STATUS_ACTIVE as SA_STATUS_ACTIVE, STATUS_ARCHIVED, TYPE_BALANCE} from "../../../../enums/system_account";
import PopupConvertion from "./PopupConvertion";

class OneSystemAccountGeneralInfo extends Component {
    constructor (props) {
        super(props);

        this.state = {
            loaded: false,
        };
    }

	save = async (state) => new Promise(next => this.setState(state, next));

    componentDidMount () {
        this.onDataLoad();
    }

    onDataLoad = () => {
        const setLoaded = (loaded) => this.setState(() => ({ loaded, }));

        setLoaded(false);

        (async () => {
            try {
                // const XXX = await asyncGet();
            } catch (error) {

            }
            setLoaded(true);
        })();
    };

    depositWithdrawalPopupOpen = () => {
        this.setState((state) => ({ isDepositWithdrawalPopupActive: true }));
    };

    depositWithdrawalPopupClose = () => {
        this.setState((state) => ({ isDepositWithdrawalPopupActive: false }));
    };

	convertPopupOpen = () => {
		this.save({ isConvertPopupActive: true, })
	}

	convertPopupClose = () => {
		this.save({ isConvertPopupActive: false, })
	}

    get systemAccountConfig () {
        return [
            {
                path: ['core', 'system_accounts'],
                key:  'systemAccounts',
            },
        ];
    }

    get oneSystemAccountGeneralInfoConfig () {
        const { translate, } = this.props;
        const prefix = 'list_accounts_table_system_';

        return [
            {
                path: ['core', 'account', 'id'],
                key:  'clientId',
            },
            {
                path: ['core', 'account', 'name'],
                key:  'clientName',
            },
            {
                path: ['core', 'account', 'surname'],
                key:  'clientSurname',
            },
            {
                accessChecked: true,
                key:           'client',
                render:        this.renderFullNameCont({ fieldName: 'client', path: '/clients' }),
                title:         `${prefix}client`,
            },
            {
                path:   ['core', 'system_accounts', 'id'],
                key:    'id',
                render: this.renderBlockCont(),
                title:  `${prefix}id`,
            },
            {
                path:   ['core', 'system_accounts', 'created_at'],
                key:    'createdAt',
                render: this.renderBlockCont(),
                title:  `${prefix}created_at`,
            },
            {
                path:   ['core', 'system_accounts', 'updated_at'],
                key:    'updatedAt',
                render: this.renderBlockCont(),
                title:  `${prefix}updated_at`,
            },
            {
                path:   ['core', 'system_accounts', 'type'],
                key:    'type',
                render: this.renderBlockCont({ isEnum: true, enumPath: 'core', enumName: 'SystemAccountTypeEnum' }),
                title:  `${prefix}type`,
            },
            {
                path:   ['core', 'system_accounts', 'acc_ccy'],
                key:    'currency',
                render: this.renderBlockCont({ isEnum: true, enumPath: 'core', enumName: 'AccountCurrencyEnum' }),
                title:  `${prefix}acc_ccy`,
            },
            {
                path:   ['core', 'system_accounts', 'value'],
                key:    'value',
                render: this.renderBlockCont(),
                title:  `${prefix}value`,
            },
            {
                path:   ['core', 'system_accounts', 'status'],
                key:    'status',
                render: this.renderBlockCont({ isEnum: true, enumPath: 'core', enumName: 'SystemAccountStatusEnum' }),
                title:  `${prefix}status`,
            },

        ].map(({ title: t, xtitle: x, ...item }) => ({ ...item, ...x && { title: x }, ...t && { title: translate(t) }, }));
    }

    renderBlockCont = ({ isEnum = false, enumPath = null, enumName = null } = {}) => (value, { item, items }) => {
        const title = item?.options?.title;
        const key = item?.key;
        const defaultValue = '-';
        let targetValue;

        switch (true) {
            case item?.access?.('index'): {
                if (isEnum) {
                    const enums = this.props?.enums?.[enumPath]?.[enumName];
                    targetValue = enums?.[value] ?? defaultValue;
                } else {
                    targetValue = value ?? defaultValue;
                }
                break;
            }
            default: {
                targetValue = defaultValue;
                break;
            }
        }

        return this.renderBlockContFragment({ key, title, targetValue });
    };

    renderFullNameCont = ({ fieldName = null, path = null } = {}) => (value, { item, items }) => {
        const id = items?.[`${fieldName}Id`];
        const name = items?.[`${fieldName}Name`];
        const surname = items?.[`${fieldName}Surname`];

        const title = item?.options?.title;
        const key = item?.key;
        const defaultValue = '-';

        let targetValue;
        let blockUrl;

        switch (true) {
            case id?.access('show') && name?.access?.('index') && surname?.access?.('index'): {
                targetValue = `${ surname.valueOf ?? '' } ${ name.valueOf ?? '' }`;
                blockUrl = `${path}/${ id.valueOf }`;
                break;
            }
            case name?.access?.('index') && surname?.access?.('index'): {
                targetValue = `${ surname.valueOf ?? '' } ${ name.valueOf ?? '' }`;
                blockUrl = null;
                break;
            }
            default: {
                targetValue = defaultValue;
                blockUrl = null;
                break;
            }
        }

        return this.renderBlockContFragment({ key, title, targetValue, fieldName, blockUrl });
    };

    renderBlockContFragment = ({ key, title, targetValue, fieldName, blockUrl }) => {
        const targetOption = fieldName === 'client' ? '_self' : '_blank';

        return (
            <div className="block-cont" key={`block-cont-${key}`}>
                <div className="block">
                    <div className="block-header full-cont">
                        <div className="full-cont__item">
                            {blockUrl ?
                                (
                                    <Link to={`${blockUrl}`} className='link-btn-cont' target={targetOption}>
                                        <span className='block__name link-btn'>
                                            {title}
                                        </span>
                                    </Link>
                                ) :
                                (
                                    <span className="block__name">{title}</span>
                                )
                            }

                        </div>
                        <div className="full-cont__item">
                            <span className="block__info">{targetValue}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderTitle (title) {
        return (
            <div className='title-cont'>
                <h3 className='title'>{title}</h3>
            </div>
        );
    }

    renderInformationCont (fieldsArray, castData) {
        const { translate, params, query } = this.props;

        return (
            <div className='information-cont'>
                {
                    fieldsArray.map((item, i) => {
                        return this.renderInformationContItem(item, i, castData);
                    })
                }

                <div className='information-cont__item' key={`information-cont__item-2`}>
                    {/* INCLUDE SystemAccountBalanceHistory COMPONENT*/}
                    <SystemAccountBalanceHistory
                        translate={translate}
                        params={params}
                        query={query}
                    />
                </div>
            </div>
        );
    }

    renderInformationContItem (fieldsPart, i, castData) {
        const titlesArr = ['list_accounts_basic_information_account'];
        const { translate } = this.props;

        return (
            <div className='information-cont__item' key={`information-cont__item-${i}`}>
                {this.renderTitle(translate(titlesArr[i]), i, translate)}

                <div className='blocks-wrapper'>
                    {
                        fieldsPart.map((item, i) => {
                            return castData?.[item] ?? false;
                        })
                    }
                </div>

				<div className='information-wrapper information-wrapper--last-order partners'>
					{this.renderDepositWithdrawalBtn()}
					{this.renderConvertBtn()}
				</div>
            </div>
        );
    }

    renderDepositWithdrawalBtn = () => {
        const { data = {}, translate, refreshSystemAccountData, enums, activeLanguage } = this.props;

        const { core = {} } = data;
        const { system_accounts = {}, account = {} } = core;
        const { id = '', status: systemAccountStatus } = system_accounts;
        const { status: accountStatus } = account;

        const isDepositWithdrawalBtnActive = () => {
            // SystemAccountStatusEnum, all values, except of 'archive'
            // UserStatusEnum, only for 'active'
            return !!systemAccountStatus && systemAccountStatus !== STATUS_ARCHIVED && !!accountStatus && accountStatus === STATUS_ACTIVE;
        };

        const conf = PermissionService.calc(this.systemAccountConfig);
        const depositWithdrawalPermission = conf?.systemAccounts?.access('deposit', 'withdraw') ?? false;

        const options = {
            refreshChildData: refreshSystemAccountData,
            popUpClose:       this.depositWithdrawalPopupClose,
            activeLanguage,
            translate,
            enums,
            account_id:       id,
        };

        return (
            depositWithdrawalPermission && isDepositWithdrawalBtnActive() ?
                (
                    <>
                        <div className="button button--gradient-pink deposit-withdrawal-btn additional"
                             onClick={this.depositWithdrawalPopupOpen}
                        >
                            {translate('list_accounts_system_account_deposit_withdrawal_btn')}
                        </div>
                        {this.state.isDepositWithdrawalPopupActive ?
                            (<PopupDepositWithdrawal {...options} />) : (false)
                        }
                    </>
                ) : false
        );
    };

	renderConvertBtn = () => {
		const { data = {},
			translate,
			refreshSystemAccountData,
			enums,
			activeLanguage, } = this.props;
		const { core = {} } = data;
		const { system_accounts = {}, account = {} } = core;
		const { id = '',
			type: systemAccountType,
			status: systemAccountStatus,
			value: systemAccountValue,
			acc_ccy: systemAccountAccCcy,
		} = system_accounts;
		const { status: accountStatus, id: accountId } = account;

		const isConvertBtnVisible = () => {
			// Кнопку отображать только при выполнении всех условий (И):
			// счет с TYPE_BALANCE = 1 (system_accounts.type=1)
			// статус счета - активный
			// пользователь с полномочиями Permission: back_office, financial, technical_support
			// учетная запись клиента - активный
			return !!systemAccountType && systemAccountType === TYPE_BALANCE &&
				!!systemAccountStatus && systemAccountStatus !== STATUS_ARCHIVED &&
				!!accountStatus && accountStatus === STATUS_ACTIVE;
		};

		const conf = PermissionService.calc(this.systemAccountConfig);
		const hasConvertPermission = conf?.systemAccounts?.access('convert') ?? false;

		const options = {
			id,
			systemAccountValue,
			systemAccountAccCcy,
			// refreshChildData: refreshSystemAccountData,
			popUpClose:       this.convertPopupClose,
			// activeLanguage,
			translate,
			// enums,
			accountId,
            refreshChildData: refreshSystemAccountData,
		};

		return (
			hasConvertPermission && isConvertBtnVisible() ?
			<>
				<div
					className="button button--gradient-pink convert-popup-btn"
					onClick={this.convertPopupOpen}
				>
					{translate('list_accounts_system_account_convert_btn')}
				</div>
				{this.state.isConvertPopupActive
					? (<PopupConvertion {...options} />)
					: (false)}
			</>
				: false
		);
	};

    render () {
        const { translate, fieldsForChecking, data = {} } = this.props;
        const castData = PermissionService.cast(data, this.oneSystemAccountGeneralInfoConfig);

        return (
            <Loader
                loaded={this.state.loaded}
                loading={(<Preloader scale={1}/>)}
            >
                <div className='glalex-styles list-accounts'>
                    {this.renderInformationCont(fieldsForChecking, castData)}
                </div>
            </Loader>
        );
    }
}

OneSystemAccountGeneralInfo.defaultProps = {
    fieldsForChecking: [
        [
            'id',
            'client',
            'createdAt',
            'updatedAt',
            'type',
            'currency',
            'value',
            'status',
        ],
    ],
};

export default withLocalize(OneSystemAccountGeneralInfo);
