import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withLocalize } from "react-localize-redux";
import DatePicker from "react-date-picker";
import Preloader from "../../../../components/LoadingHOC/Preloader";
import Loader from "../../../tima/components/Loader";
import PagePanel from "../../../tima/components/Pages/PagePanel";
import moment from "moment";
import { MagicTable } from "../../../../components/Magic";
import { listAccountService } from "../../../../services/ListAccountDataService";

const CURRENT_DATE = new Date();

class Logs extends Component {
    constructor (props) {
        super(props);

        this.state = {
            ...this._getInitialState(),
            dateStr: '',
        };
    }

    _getInitialState = (reset = false) => {
        return {
            loaded:     true,
            data:       [],
            dataAll:    [],
            pages:      {
                filter:   0,
                total:    0,
                take:     50,
                variance: 2,
            },
            filterData: reset ? this._getResetFilterData() : this._getInitialFilterData(),
        };
    };

    _getInitialFilterData = () => {
        try {
            const dateStr = this.query().get('date');
            const ipStr = this.query().get('ip') || '';
            const from = dateStr ? new Date(dateStr) : '';

            return {
                ...this._getResetFilterData(),
                from,
                ip: ipStr,
            };
        } catch (error) {
            return {
                ...this._getResetFilterData(),
            };
        }
    };

    _getResetFilterData = () => {
        return {
            from:  '',
            mode:  4, // the most detailed information, HARD CODE, in current version of CRM
            login: this.props.mt_login, //21572,
            ip:    '',
        };
    };

    query = () => new URLSearchParams(this.props?.location?.search);

    componentDidMount () {
        this.state?.filterData?.from && this.onApplyBtnClick();
    }

    get logsConfig () {
        const { translate, } = this.props;
        const prefix = 'list_accounts_table_logs_';

        return [
            {
                accessChecked: true,
                path:   ['time'],
                key:    'date',
                render: this.renderDateTime({ fieldName: 'date' }),
                title:  `${prefix}date`,
            },
            {
                accessChecked: true,
                path:   ['time'],
                key:    'time',
                render: this.renderDateTime({ fieldName: 'time' }),
                title:  `${prefix}time`,
            },
            {
                accessChecked: true,
                path:   ['ip'],
                key:    'ip',
                render: this.renderInlineValue,
                title:  `${prefix}ip`,
            },
            {
                accessChecked: true,
                path:   ['message'],
                key:    'message',
                render: this.renderInlineValue,
                title:  `${prefix}message`,
            },

        ].map(({ title: t, xtitle: x, ...item }) => ({ ...item, ...x && { title: x }, ...t && { title: translate(t) }, }));
    }

    dateFormatter = (dateString, option) => {
        const defaultValue = '';

        let date = dateString ? dateString?.split(' ')?.[0] : null,
            time = dateString ? dateString?.split(' ')?.[1] : null,
            resultStr = '';

        try {
            switch (option) {
                case 'date':
                    resultStr = date ? date : defaultValue;
                    break;
                case 'time':
                    resultStr = time ? time?.split('.')?.[0] : defaultValue;
                    break;
                default:
                    resultStr = defaultValue;
                    break;
            }
        } catch (error) {
            resultStr = null;
        }

        return resultStr;
    };

    renderDateTime = ({ fieldName }) => (value, { item, items }) => {
        const calcVal = this.dateFormatter(value, fieldName);
        const defaultValue = '-';

        if (item?.access?.('index')) {
            return calcVal ?? defaultValue;
        }
        return defaultValue;
    };

    renderInlineValue = (value, { item, items }) => {
        const defaultValue = '-';

        if (item?.access?.('index')) {
            return value ?? defaultValue;
        }
        return defaultValue;
    };

    onDateChange = (fieldName) => (date) => {
        const newState = { filterData: { ...this.state.filterData, [fieldName]: date } };

        this.setState(newState);
    };

    onInputChange = (fieldName) => (event) => {
        const { value } = event.target;
        const newState = { filterData: { ...this.state.filterData, [fieldName]: value } };

        this.setState(newState);
    };

    onApplyBtnClick = (event) => {
        const { filterData } = this.state;
        const { from, ip, mode, login } = filterData;

        if (from) {
            try {
                let fromConvertedStr = moment(from).format('DD.MM.YYYY HH:mm:ss');
                fromConvertedStr = fromConvertedStr.replace(',', '');

                const toConvertedStr = fromConvertedStr.replace('00:00:00', '23:59:59');
                const dateStr = from.toDateString();

                const options = {
                    mode,
                    from: fromConvertedStr,
                    to:   toConvertedStr,
                    login,
                };

                this.setState({ dateStr });
                this.onDataLoad({ hardLoad: true, mtParams: options, searchStrIp: ip });
            } catch (error) {

            }
        }
    };

    onResetBtnClick = (event) => {
        this.setState({ ...this._getInitialState(true) });
    };

    render () {
        const { translate, enums } = this.props;
        const { filterData, dateStr, data, } = this.state;
        const { ip } = filterData;

        const dateParam = dateStr ? `&date=${ dateStr }` : ``;
        const ipParam = ip ? `&ip=${ ip }` : ``;

        const options = {
            config: this.logsConfig,
            data,
            head:   [
                'date',
                'time',
                'ip',
                'message',
            ],
        };

        return (
            <div className='orders-page logs-page'>
                <div className='table-top'>
                    <div className='table-filters'>
                        <div className='table-filters-wrapper'>
                            <div className='table-filters_left'>
                                <div className='input-block'>
                                    <input className={`input-table`}
                                           autoComplete='off'
                                           type='text'
                                           placeholder={`IP`}
                                           value={this.state?.filterData?.ip}
                                           onChange={this.onInputChange('ip')}
                                    />
                                </div>

                                <div className='input-block'>
                                    <DatePicker
                                        value={this.state?.filterData?.from}
                                        maxDate={CURRENT_DATE}
                                        onChange={this.onDateChange('from')}
                                        format='yyyy.MM.dd'
                                        locale={this.props?.activeLanguage?.code}
                                        calendarClassName={'react-datepicker__month-container'}
                                        className={`react-datepicker input-table`}
                                        clearIcon={null}
                                        calendarIcon={null}
                                        disabled={false}
                                        dayPlaceholder={'DD'}
                                        monthPlaceholder={'MM'}
                                        yearPlaceholder={'YYYY'}
                                    />
                                </div>
                            </div>
                            <div className='table-filters_right'>
                                <div className='button blue templateManagement-btn_apply'
                                     onClick={this.onApplyBtnClick}>
                                    {translate(`list_accounts_apply`)}
                                </div>
                                <div className='button templateManagement-filters-reset'
                                     onClick={this.onResetBtnClick}>
                                    {translate(`list_accounts_reset`)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Loader
                    loaded={this.state.loaded}
                    loading={(<Preloader scale={this.props.scale}/>)}
                    translate={translate}
                >
                    <MagicTable {...options} />

                    <PagePanel
                        filter={this.state.pages.filter}
                        take={this.state.pages.take}
                        total={this.state.pages.total}
                        variance={this.state.pages.variance}
                        page={this.pageId()}
                        onClick={this.onPageClick}
                        doText={this.onPageText}
                        doTo={(pageId) => `?page=${ pageId }${dateParam}${ipParam}`}
                    />
                </Loader>

            </div>
        );
    }

    pageId = () => {
        const defaultPageId = 0;

        try {
            const page = this.query().get('page');

            return Number(page) || defaultPageId;
        } catch (e) {
            return defaultPageId;
        }
    };

    pageIdAsGET = (pageId) => {
        pageId = pageId === undefined ? this.pageId() : pageId;
        const result = {
            take: this.state.pages.take,
            skip: pageId * this.state.pages.take,
        };
        return result;
    };

    onDataLoad = async ({ pageId = this.pageId(), hardLoad = false, mtParams, searchStrIp }) => {
        const filterData = (data, options) => {
            if (options && options.fieldName && options.searchStrLike) {
                const { fieldName, searchStrLike } = options;
                const filteredData = data.filter((item) => {
                    return item[fieldName].toLowerCase().indexOf(searchStrLike.toLowerCase()) !== -1;
                });

                return filteredData;
            } else {
                return data ? data : [];
            }
        };

        const getPartialData = (data, startIndex, count) => {
            if (data && startIndex >= 0 && count) {
                return data.slice(startIndex, startIndex + count);
            } else {
                return [];
            }
        };

        const setState = async (state) => new Promise((resolve) => this.setState(state, resolve));
        const setLoaded = async (loaded) => await setState({ loaded, });
        const setData = async (data) => await setState({ ...data, });
        const setPages = async ({ filter, total }) => {
            await setState((state) => ({ pages: { ...state.pages, filter, total, }, }));
        };
        const load0 = async (pageOptions) => await listAccountService.tradingAccountsLogs(this.props.id, mtParams);
        const { dataAll, pages } = this.state;
        const { take } = pages;
        await setLoaded(false);
        const pageOptions = this.pageIdAsGET(pageId);

        if (hardLoad) {
            try {
                const response = await load0(pageOptions);
                const { journal: data } = response;
                const filteredData = filterData(data, { fieldName: 'ip', searchStrLike: searchStrIp });
                const partialData = getPartialData(filteredData, pageId * take, take);

                await setData({ data: partialData, dataAll: data });
                await setPages({ total: data.length, filter: filteredData.length });
            } catch (error) {
                await setData({ data: [], });
                await setPages({ filter: 0, total: 0, });

                error?.showErrorNotification?.();
            }
        } else {
            const filteredData = filterData(dataAll, { fieldName: 'ip', searchStrLike: searchStrIp });
            const partialData = getPartialData(filteredData, pageId * take, take);

            await setData({ data: partialData });
        }

        await setLoaded(true);
    };

    onPageClick = ({ event, pageId, pageIs, }) => {
        // event.preventDefault();
        this.onDataLoad({ pageId });
    };

    onPageText = (pageId, pageIs) => {
        const { translate } = this.props;
        const { current, first, prev, next, last, } = pageIs;
        const { skipped, taken, filter, total, } = pageIs;
        if (skipped || taken) {
            const id = Number.isInteger(pageId) ? `${ pageId + 1 }` : '?';
            const text = skipped ? translate('partners_pages_items') : '';
            return skipped ? `${ text }: ${ id }` : id;
        } else if (filter || total) {
            const id = Number.isInteger(pageId) ? `${ pageId }` : '?';
            const text = translate(filter ? 'partners_pages_filtered' : 'partners_pages_total');
            return `${ text }: ${ id }`;
        } else if (first || prev || next || last) {
            return '';
        } else if (current) {
            return `${ pageId + 1}`;
        } else {
            return `${ pageId + 1}`;
        }
    };
}

export default withRouter(withLocalize(Logs));
