import {
	itemsBuild,
	itemsDrain,
	itemsResort,
	CFG_ITEM_TYPE,
} from '../Config';

export default {
	id: 49,
	...[
		// filters
		[],
		// orders
		...[ {
			f: 'log.mails.delivered_date',
			id: 'o:mDate',
		}, {
			f: 'log.mails.subject',
			id: 'o:mSubject',
		}, {
			f: 'log.mails.status',
			id: 'o:mStatus',
		}, ]
		.filter(_ => _ && !_.off)
		.map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),
		// panels
		[],

	]
	.map(itemsBuild)
	.reduce(itemsDrain, [])
	.reduce(itemsResort, {}),

	path: 'log/mails/get-by-account-id/1',
	widget: false,
};
