export const delegation = {
    "delegation": ["Delegation", "Делегирование", "Делегування"],

    "delegation_table_id": ["ID", "ID", "ID"],
    "delegation_table_user_id": ["Manager", "Менеджер", "Менеджер"],
    "delegation_table_task_id": ["Task", "Задача", "Завдання"],
    "delegation_table_active":  ["Status", "Статус", "Статус"],

    "delegation_table_check_delegation":  ["Choose a task", "Выбрать задачу", "Вибрати завдання"],
    "delegation_table_manager":  ["Manager", "Менеджер", "Менеджер"],
    "delegation_table_manager_delegation":  ["To task delegated", "Кому делегирована задача", "Кому делегована завдання"],
    "delegation_table_position":  ["Position", "Должность", "Посада"],
    "delegation_table_throughput":  ["Throughput", "Пропускная способность", "Пропускна здатність"],
    "delegation_table_finish_date":  ["Deadline", "Крайний срок", "Крайній термін"],
    "delegation_table_task":  ["Task", "Задача", "Завдання"],
    "delegation_table_client":  ["Client", "Клиент", "Клієнт"],
    "delegation_table_task_description":  ["Task description", "Описание задачи", "Опис завдання"],
    "delegation_table_type":  ["Type", "Тип", "Тип"],
    "delegation_table_status":  ["Status", "Статус", "Статус"],
    "delegation_table_importance":  ["Importance", "Важность", "Важливість"],

    "delegation_filter_title": ["Filters", "Фильтры", "Фільтри"],
    "delegation_filter_deadline": ["Deadline", "Крайний срок сдачи", "Крайній термін здачі"],
    "delegation_filter_throughput": ["Throughput ", "Пропускная способность ", "Пропускна здатність ", ],
    "delegation_filter_manager": ["Manager", "Менеджер", "Менеджер"],
    "delegation_filter_apply": ["Apply", "Применить", "Застосувати"],
    "delegation_filter_reset": ["Reset", "Сброс", "Скасувати"],
    "delegation_filter_btn_delegation": ["Delegate", "Делегировать", "Делегувати"],
    "delegation_filter_btn_remove_delegation": ["Remove delegation", "Снять делегирование", "Зняти делегування"],

    "delegation_pages_items": ["Items", "Записи", "Записи"],
    "delegation_pages_filtered": ["Items with filter", "Записей с фильтрацией", "Записів із фільтрацією"],
    "delegation_pages_total": ["Items total", "Записей в целом", "Записів в цілому"],

    "delegation_modal_title": ["Tasks delegation", "Делегирование задач", "Делегування завданнь"],
    "delegation_modal_btn_save": ["Save", "Сохранить", "Зберегти"],
    "delegation_modal_btn_cancel": ["Cancel", "Отменить", "Скасувати"],
    "delegation_modal_assign_namager": ["Assign a manager", "Назначить менеджера", "Призначити менеджера"],

    "delegation_modal_are_shure": ["Are you sure?", "Вы уверены?", "Ви впевнені?"],
    "delegation_modal_are_shure_descr": ["Do you want to save the changes?", "Вы хотите сохранить изменения?", "Ви хочете зберегти зміни?"],
    "delegation_modal_are_shure_no": ["No", "Нет", "Нi"],
    "delegation_modal_are_shure_yes": ["Yes", "Да", "Так"],

    "delegation_modal_title_manager": ["Manager", "Менеджер", "Менеджер"],
    "delegation_modal_title_manager_delegation": ["Delegated Manager", "Делегированный Менеджер", "Делегований Менеджер"],

    "delegation_error_select_task": ["You must select at least one task.", "Необходимо выбрать минимум одну задачу.", "Необхідно вибрати мінімум одну задачу."],

    //"delegation_": ["", "", ""],
};
