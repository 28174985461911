// eslint-disable-next-line no-unused-vars
const LinkConfig = (translate, order, options = {}, permissions) =>
	[
		{
			id: "symbols-lists",
			link: "/partners/affiliate-programs-management/symbols-lists",
			text: translate("partners_symbols_lists_title"),
		},
		{
			id: "affiliate-programs-management",
			link: "/partners/affiliate-programs-management",
			text: translate("partners_system_affiliate_programs_management"),
		},
		{
			id: "partners",
			link: "/partners",
			text: translate("partners_h1"),
		},
		{
			id: "dashboard",
			link: "/dashboard",
			text: translate("menu_dashboard"),
		},
	]
		.filter(({ id, }) => order.includes(id))
		.sort(({ id: a, }, { id: b, }) => order.indexOf(a) - order.indexOf(b))
		.map(({ id, ...data }) => ({ ...data, key: id, }))
		.filter((item) => {
			if (permissions) {
				return permissions?.[item?.key]?.access("index") ?? false;
			}

			return true;
		});

export default LinkConfig;