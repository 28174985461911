import {
	isEmptySelectValue,
	isEmptyString,
} from "../../../../../utils/helpers";

async function validateOnSubmit (state, save) {
	const errors = Object
		.keys(state.formData)
		.reduce((total, field) => {
			const validateAllowSeeCreateRebate = field === 'allow_see_create_rebate' && !isEmptySelectValue(state.formData.RevshareSingle);

			if (field === 'name') {
				if (state.formData[field].length < 3 || state.formData[field].length > 100) {
					total = [ ...total, field, ];
				}
			}else if (field === 'client_name'){
					if (state.formData[field].length < 3 || state.formData[field].length > 100) {
						total = [ ...total, field, ];
					}
			} else if (field === 'description') {
				if (state.formData[field].length > 1000) {
					total = [ ...total, field, ];
				}
			} else if (field === 'cookie_lifetime_days') {
				if (state.formData[field] < 1 || state.formData[field] > 365) {
					total = [ ...total, field, ];
				}
			} else if (field === 'name' || field === 'client_name' || field === 'cookie_lifetime_days' || validateAllowSeeCreateRebate) {
				if (
					isEmptySelectValue(state.formData[field]) ||
										isEmptyString(state.formData[field])
				) {
					total = [ ...total, field, ];
				}
			} else if (field === 'conditionIds') {
				if (state.formData[field].length === 0) {
					total = [ ...total, field, ];
				}
			}

			return total;
		}, []);

	await save({ errors, });

	return errors.length === 0;
}
export { validateOnSubmit, };