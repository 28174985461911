import React from 'react';
import * as PropTypes from 'prop-types';
import { MagicDate, } from '../../../../../MagicDate';

export class MagicFilterInputDate extends React.Component {
  static propTypes = {
    disabled: PropTypes.bool.isRequired,
    filter: PropTypes.object.isRequired,
    // eslint-disable-next-line sort-keys
    emptyLabel: PropTypes.string,
    format: PropTypes.string,
    locale: PropTypes.string,
  };

  get filter () {
    return this.props.filter;
  }

  get mentor () {
    return this.filter.mentor;
  }

  // eslint-disable-next-line require-await
  onChange = async (value) => {
    return this.mentor.filterChange({
      id: this.filter.$id,
      setter: { v: value, },
      $$changed: true,
    });
  };

  renderDate = (value, onChange, key) => {
    return (<MagicDate
      className={ [
        'magic-filter-input-date',
        'magic-date__filter-input-date',
      ] }
      disabled={ this.props.disabled }
      emptyLabel={ this.props.emptyLabel }
      format={ this.props.format }
      { ...key!==undefined && { key, } }
      locale={ 'uk' }
      showRelease={ true }
      value={ value }
      onChange={ onChange }
    />);
  }

  render = () => {
    if (this.filter.value instanceof Array) {
      const onChange = (values, index) => async (value) => {
        this.onChange(values.map((v, i) => i===index ? value : v));
      };
      const items = this.filter.value.map((value, index, values) => {
        return this.renderDate(value, onChange(values, index), index);
      });
      return (<React.Fragment>{ items }</React.Fragment>);
    }
    return this.renderDate(this.filter.value, this.onChange);
  };
};