import React      from 'react';
import PropTypes  from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { withLocalize } from "react-localize-redux";

import { MagicSelect, MagicTable, }  from '../../../components/Magic';
import { MagicTooltip } from '../../tima/components/Magic/MagicTooltip';

import letter from "../../../images/letter.svg";
import call from "../../../images/call.svg";
import attachmentImg from '../../../images/att.svg';
import moment from 'moment';
import LangService from '../../../services/LangService';
import { callService } from "../../../services/CallDataService";

class TasksMagicTable extends React.Component {
  static propTypes = {
    data: PropTypes.array,
    accountLabels: PropTypes.array,
    accountLastContacts: PropTypes.array,
    accountStatuses: PropTypes.array,
    accountTypes: PropTypes.array,
    taskStatuses: PropTypes.array,
    enums: PropTypes.object,
    mf: PropTypes.object.isRequired,
    onDataLoad: PropTypes.func.isRequired,
    updateCustomerSign: PropTypes.func.isRequired,
    head: PropTypes.array.isRequired,
  };

  static defaultProps = {
    data: [],
    accountLabels: [],
    accountLastContacts: [],
    accountTypes: [],
    taskStatuses: [],
    enums: {},
    head: [],
  };

  get enums () { return this.props?.enums ?? {}; }

  get mf () {
    return this.props.mf;
  }

  get params () { return this.props?.match?.params; }

  get query () { return new URLSearchParams(this.props?.location?.search); }

  get taskConfig () {
    const { translate, } = this.props;
    const result = [ {
      path: [ 'core', 'account', 'id' ],
      key: 'accountId',
    }, {
      path: [ 'core', 'account', 'is_loyal' ],
      key: 'accountIsLoyal',
    }, {
      path: [ 'core', 'account', 'is_vip' ],
      key: 'accountIsVip',
    }, {
      path: [ 'core', 'account', 'name' ],
      key: 'accountName',
    }, {
      path: [ 'core', 'account', 'surname' ],
      key: 'accountSurname',
    }, {
      path: ['core', 'account', 'is_phone_verify'],
      key: 'isPhoneVerified'
    }, {
      path: [ 'tasks', 'tasks', 'id' ],
      key: 'taskId',
      render: this.renderInlineValue,
      title: 'tasks_task_id',
      orderId: 'o:tTaskId',
    }, {
      path: [ 'tasks', 'tasks', 'state_id'],
      key: 'stateId'
    }, {
      path: [ 'core', 'user', 'id' ],
      key: 'userId',
    }, {
      path: [ 'core', 'user', 'name' ],
      key: 'userName',
    }, {
      path: [ 'core', 'user', 'surname' ],
      key: 'userSurname',
    }, {
      path: [ 'calls', 'calls', 'created_at' ],
      key: 'callLastContactDate',
    }, {
      accessChecked: true,
      key: 'accountFullname',
      render: this.renderAccountFullname,
      title: 'tasks_client',
      orderId: 'o:aSurname',
    }, {
      path: [ 'tasks', 'tasks', 'description' ],
      key: 'taskDescription',
      render: this.renderTaskDescription,
      title: 'tasks_description',
      orderId: 'o:tDesc',
    }, {
      path: [ 'core', 'account_categories', 'created_at' ],
      key: 'accountCategoryChangeDate',
      render: this.renderDateValue,
      title: 'tasks_date_account_categories',
      orderId: 'o:acCreated',
    }, {
      path: [ 'core', 'account_types', 'type_id' ],
      key: 'taskAccountType',
      render: this.renderDropDown(this.props.accountTypes),
      title: 'tasks_type_client',
    }, {
    //   path: [  ],
    //   key: 'taskCreated',
    //   render: null,
    //   title: 'tasks_created_at',
    //   orderId: '?',
    // }, {
      path: [ 'core', 'account_labels', 'label_id' ],
      key: 'taskAccountLabels',
      render: this.renderDropDown(this.props.accountLabels),
      title: 'tasks_account_labels',
    }, {
      // path: [  ],
      // key: 'taskUpdated',
      // render: null,
      // title: 'tasks_updated_at',
      // orderId: '?',
    }, {
      // path: [  ],
      // key: 'taskName',
      // render: null,
      // title: 'tasks_task_name',
      // orderId: '?',
    }, {
      // path: [ 'tasks', 'task_documents', '???' ],
      // key: 'taskAttachments',
      // render: this.renderTaskAttachments,
      // title: 'tasks_attachments',
      // orderId: '?',
    }, {
      // path: ['tasks', 'tasks', 'state_id'],
      // key: 'taskStatus',
      // render: this.renderTaskStatus,
      // title: 'tasks_status',
    },{
      path: [ "tasks", "task_states", "name"],
      key: 'taskStatus',
      render: this.renderStatus,
      title: 'tasks_status',
      orderId: 'o:tStatus',
    }, {
      path: [ 'core', 'account_status', 'status_id' ],
      key: 'taskLastContact',
      render: this.renderDropDown(this.props.accountLastContacts),
      title: 'tasks_last_contact',
    }, {
      accessChecked: true,
      path: ['calls', 'calls', 'created_at'],
      key: 'taskLastContactDate',
      render: this.renderDateValue,
      title: 'tasks_date_last_contact',
    }, {
      path: [ 'core', 'account_categories', 'category_id' ],
      key: 'accountCategory',
      render: this.renderEnums(this.enums?.core?.AccountCategoryEnum),
      title: 'tasks_category_client',
      orderId: 'o:acCategoryId',
    }, {
      path: [ 'core', 'account', 'id' ],
      key: 'taskPhone',
      render: this.renderPhone,
      title: 'tasks_phone',
    }, {
      accessChecked: true,
      key: 'taskCustomerSign',
      render: this.renderAccountCustomerSign,
      title: 'tasks_customer_sign',
    }, {
      path: [ 'core', 'account', 'warm_type' ],
      key: 'taskWarm',
      render: this.renderEnums(this.enums?.core?.AccountWarmTypeEnum),
      title: 'tasks_warm',
      orderId: 'o:aWarmType',
    }, {
      path: [ 'core', 'account', 'warm_type_last_change' ],
      key: 'taskWarmDate',
      render: this.renderDateValue,
      title: 'tasks_date_warm',
      orderId: 'o:aAtWarmChanged',
    }, {
      path: [ 'tasks', 'tasks', 'finish_date' ],
      key: 'taskDeadline',
      render: this.renderDateValue,
      title: 'tasks_deadline',
      orderId: 'o:tAtFinish',
    }, {
      accessChecked: true,
      key: 'taskResponsible',
      render: this.renderUserResponsible,
      title: 'tasks_responsible',
      orderId: 'o:uSurname',
    }, {
      path: [ 'tasks', 'task_urgency', 'name' ],
      key: 'taskUrgency',
      render: this.renderInlineValue,
      title: 'tasks_urgency',
    }, ].map(({
      orderId: o,
      title: t,
      xtitle: x,
      ...item
    }) => ({
      ...item,
      ...o && {
        order: async () => {
          await this.mf.orderedToNext(o);
          // await this.onDataLoad(); // TODO: remove cause reaction should be in subscribe
        },
        orderTo: this.mf.orderedTo(o),
      },
      ...t && { title: translate(t), },
      ...x && { title: x },
    }));
    return result;
  }

  renderInlineValue = (value, { item, items, }) => {
    return item.access('index') && value ? value : '-';
  };

  renderDateValue = (value, { item }) => {
    if (item.access('index') && value) {
      const date = moment(value, 'YYYY-MM-DD HH:mm:ss');

      return (<React.Fragment>{ date.format('YYYY-MM-DD') }<br />{ date.format('HH:mm:ss') }</React.Fragment>);
    }

    return '-';
  }

  renderLink = (text, to) => to ? <Link to={to} target="_blank">{text}</Link> : text;

  renderDropDown = (dropDown) => (value, { item, items, }) => {
    if (item.access('index')) {
      const cast = (id) => dropDown?.find?.((item) => item?.id===id)?.name;
      return value?.map?.(cast)?.join(', ') ?? '-';
    }
    return '-';
  };

  renderEnums = (enums) => (value, { item, }) => {
    if (enums && item.access('index')) {
      return enums?.[value] ?? '-';
    }
    return '-';
  };

  renderAccountFullname = (value, { items, }) => {
    const name = items.accountName;
    const surname = items.accountSurname;
    if (name.access('index') && surname.access('index')) {
      const LENGTH_SHORT = 20;
      const text = (
        <MagicTooltip
          classNameTooltip={`tooltip tooltip--large-width`}
          content={ `${ name?.valueOf } ${ surname?.valueOf ?? '' }` }
          lengthCut={ LENGTH_SHORT }
        />
      );
      const accountId = items.accountId;
      const taskId = items.taskId;
      if (accountId.access('index', 'show') && taskId.access('index', 'show')) {
        const url = `/clients/${ accountId.valueOf }`;
        return this.renderLink(text, url);
      }
      return text;
    }
    return '-';
  };

  renderAccountLastContactDate = (value, { item, items, }) => {
    if (items?.callLastContactDate?.access?.('index') && value) {
      const dateToFormat = value?.split(' ');

      return (
        <>{dateToFormat[0] ?? ''}<br />{dateToFormat[1] ?? ''} </>
      );
    }

    return '-';
  };

  renderTaskDescription = (value, { item, items, }) => {
    if (item.access('index')) {
      const accountId = items.accountId;
      const taskId = items.taskId;
      const LENGTH_SHORT = 20;
      const text = (
        <MagicTooltip
          classNameTooltip={`tooltip tooltip--large-width`}
          content={ value }
          lengthCut={ LENGTH_SHORT }
        />
      );
      const url = `/clients/${ accountId.valueOf }/tasks/task/${ taskId.valueOf }`;
      return this.props.leadComponent ? text : this.renderLink(text, url);
    }
    return '-';
  };

  renderStatus = (value, { item, items }) => {
    const canReject = [1, 2, 4, 15].includes(+this.props?.userDepartmentId);
    const taskStatuses = canReject ? this.props?.taskStatuses
        : this.props?.taskStatuses?.filter(item => item.tasks.task_states.id !== 4);
    const { code } = LangService.getActiveLanguage();

    if (this.props.leadComponent) {
      return <div className={`status-task status-${items.stateId.valueOf}`}><span />{value}</div>
    }

    if ( items?.stateId.access('index', 'show', 'store', 'update')) {
      const options = {
        onChange: async (val) => {
          await this.props.updateTask({state_id: val }, items?.taskId?.valueOf);
          this.props.openStatusEdit(false);
        },
        value:        items?.stateId?.valueOf,
        values:       taskStatuses?.map?.((data) => data?.tasks?.task_states?.id) ?? [],
        label: () => value,
        valueToLabel: (id) => {
          if (id === false) {
            return '';
          }
          if (taskStatuses.length) {
            const find = taskStatuses?.find((data) => id === data?.tasks?.task_states?.id);

            return find ? find.tasks.task_states[`name_${code}`] : '';
          }
        },
      };

      if (this.props.editStatus === items?.taskId?.valueOf) {
        window.addEventListener('mousedown', this.props.handleClickOutsideStatus);

        return (
          <div className = 'tasks-status__id'>
            <MagicSelect { ...options } />
          </div>
        );
      }

      return (
        <div className="edit-block" onClick = { () => this.props.openStatusEdit(items?.taskId?.valueOf) }>
          { value } <i className="pen"></i>
        </div>
      );
    }

    return value ?? '-';
  };

  // renderTaskAttachments = (value, { item, items, }) => {
  //     return this.renderInlineValue(value, { item, items, });
  //     return (<div className='attachment-empty'><img alt='attachment' src={attachmentImg} /></div>);
  // };

  renderPhone = (value, { item, items, }) => {
    const accountId = items.accountId;
    if (item.access('index') && accountId) {
      const options = {
        onClick: async () =>  callService.sendCall({ account_id: accountId.valueOf }),
        style: {
          cursor: 'pointer',
          display: 'inline-flex',
          width: '80px',
        },
      };
      const isVerified = items?.isPhoneVerified.valueOf;
      const img = (<img alt='call' src={call } style={{ margin: '-3px 10px 0 0' }} data-is-verified={isVerified}/>);
      const text = this.props?.translate?.(`tasks_createform_types_call`) ?? '?';
      return (<span { ...options }>{ img }{ text }</span>);
    }
    return '-';
  };

  renderAccountCustomerSign = (value, { items, }) => {
    const isLoyal = items?.accountIsLoyal;
    const isVip = items?.accountIsVip;
    if (isLoyal.access('index') && isVip.access('index')) {
      const inputs = [
        {
          checked: isVip.valueOf,
          name: 'is_vip',
          text: this.props?.translate(`tasks_is_vip`)
        },
        {
          checked: isLoyal.valueOf,
          name: 'is_loyal',
          text: this.props?.translate?.(`tasks_is_loyal`),
        },
      ].map(item => (
        <div
          className='block-header_input-checkbox'
          key={ item.name }
          style={{
            position: 'relative',
            width: '90px',
            textAlign: 'left',
            top: 0,
            left: 0,
          }}
        >
          <label className='checkbox'>
            <input { ...{
              checked: [1, '1'].includes(item.checked),
              type: 'checkbox',
              name: item.name,
              onChange: async ({ target }) => {
                await this.props?.updateCustomerSign?.({
                  [item.name]: target?.checked ? '1' : '2',
                }, items?.accountId.valueOf);
                await this.props?.onDataLoad();
              },
            } } />
            <span style={{ 'marginRight': '8px' }} />
            { item.text }
          </label>
        </div>
      ));
      return (<React.Fragment>{ inputs }</React.Fragment>);
    }
    return '-';
  };

  renderTaskDeadline = (value, { item, }) => {
    const date = moment(value);
    if (item.access('index') && date.isValid()) {
      const options = {
        className: [
          'finish_date',
          ...moment().diff(date)>0 ? ['expired-date'] : [],
        ].join(' '),
      };
      return (<span { ...options }>{ value }</span>);
    }
    return '-';
  };

  renderUserResponsible = (value, { items, }) => {
    const name = items.userName;
    const surname = items.userSurname;
    if (name.access('index') && surname.access('index')) {
      const LENGTH_SHORT = 20;
      const text = (
        <MagicTooltip
          classNameTooltip={`tooltip tooltip--large-width`}
          content={ `${ name?.valueOf } ${ surname?.valueOf }` }
          lengthCut={ LENGTH_SHORT }
        />
      );
      const userId = items.userId;
      if (userId.access('index', 'show')) {
        const url = `/settings/user/${ userId.valueOf }`;
        return this.renderLink(text, url);
      }
      return text;
    }
    return '-';
  };

  renderTaskStatus = (value, { item, }) => {
    if (item.access('index')) {
      return <div className = { `status-task status-${this.props.data['tasks-tasks-state_id']}` } ><span />{this.props.data["tasks-task_states-name"]}</div>
    }
  };
  render = () => {
    if (!this.props?.data?.length) {
      return null;
    }

    const options = {
      config: this.taskConfig,
      data: this.props?.data,
      head: this.props.head,
      RowProps: this.props.RowProps ? ({ items }) => {
        return {
          'onClick': () => { this.props.setActiveUser(items.taskId.valueOf) },
          'data-active': this.props.currentId === items?.taskId?.valueOf,
        }
      } : ({ index, items, head }) => index===undefined ? {} : { 'data-state': items?.stateId?.valueOf, },
    };

    return (<MagicTable { ...options } />);
  }
}

export default withRouter(withLocalize(TasksMagicTable));
