import React, { Component } from 'react';
import {
    Carousel,
    CarouselItem,
} from 'reactstrap';
import AttachmentDocument from './AttachmentDocument';
import NotificationService from "../../../../../services/NotificationService";
import { templateService } from "../../../../../services/TemplateDataService";

class SliderMailTemplate extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeIndex: 0,
            animating: false,
        };
    }

    onExiting = () => {
        this.setState({ animating: true });
    }

    onExited = () => {
        this.setState({ animating: false });
    }

    next = (items) => {
        // if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === Math.ceil(items?.length/2-1) ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous = (items) => {
        // if (this.state.animating) return;
        const nextIndex = this.state.activeIndex === 0 && items ? Math.ceil(items?.length/2-1) : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex === -1 ? 0 : nextIndex });
    }

    renderDocuments = (documents, mail_template_t_documents) => {
        return (
            <Carousel
                next={ () => {} }
                previous={ () => {} }
                activeIndex = { this.state.activeIndex }>
                {documents}
                <div className = 'document-arrows'>
                    <span className = {`document-arrow previos ${mail_template_t_documents.length > 2 ? 'visible' : ''}`} onClick = { () => this.previous(mail_template_t_documents) }>
                        <i className = 'la la-angle-left' />
                    </span>
                    <span className = {`document-arrow next ${mail_template_t_documents.length > 2 ? 'visible' : ''}`} onClick = { () => this.next(mail_template_t_documents) }>
                        <i className = 'la la-angle-right' />
                    </span>
                </div>
            </Carousel>
        );
    };

    downlDocMailTempl = async (alias, fileName, translate) => {
        try {
            await templateService.downloadDoc(alias, fileName);
            NotificationService.success({
                title:     'success',
                message:   translate(`template_management_download_doc_translation`),
                remove:    true,
                view:      true,
            });
        } catch (error) {
            NotificationService.errors(error);
        }
    };

    render () {

        let mail_template_t_documents = this.props.mail_template_t_documents ?? [];
        let renderDocuments = [];

        if (mail_template_t_documents.length > 0) {
            const arrayDocuments = Array(mail_template_t_documents.length).fill(0).map((_, index) => index).filter(index => index%2===0);

            renderDocuments = this.renderDocuments(arrayDocuments.map((item, index) => {
                return (
                    <CarouselItem
                        onExiting={this.onExiting}
                        onExited={this.onExited}
                        key={index}
                        item={item}>
                        {
                            mail_template_t_documents.slice(item, item + 2).map((item2, index2) => {
                                return (
                                    <AttachmentDocument
                                        { ...this.props }
                                        item={item}
                                        key={index2}
                                        index={ index*2 + index2 } // calculate current index for element
                                        attachment = { item2 }
                                        downlDocMailTempl = { this.downlDocMailTempl}
                                        moveForward={ this.previous }
                                        allDocuments={mail_template_t_documents}
                                    />
                                );
                            })
                        }
                    </CarouselItem>
                );
            }), mail_template_t_documents)}

        return (
            <div className='carousel_template'>
                {renderDocuments}
            </div>
        );
    }
}

export default SliderMailTemplate;
