import {
  itemsBuild,
  itemsDrain,
  itemsResort,
  CFG_ITEM_TYPE,
} from '../Config';

const f_status = {
  'drop-down': false,
  'o=': [ '=', '!=', ],
};

export default {
  id: 172,
  ...[
    // filters
    ...[{
      f: 'partners.groups_lists.id',
      id: 'f:id',
      },
      {
        f: 'partners.groups_lists.name',
        id: 'f:name',
      },
      {
        f: 'core.dictionary_trade_servers.type',
        id: 'f:server',
      },
      {
        f: 'partners.groups_lists.status',
        id: 'f:status',
        ...f_status,
      },
      {
        f: 'partners.groups_lists.created_at',
        id: 'f:created',
      },
      {
        f: 'partners.groups_lists.updated_at',
        id: 'f:updated',
      },
    ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

    // orders
    ...[{
      f: 'partners.groups_lists.id',
      id: 'o:id',
      },
      {
        f: 'partners.groups_lists.name',
        id: 'o:name',
      },
      {
        f: 'partners.groups_lists.amount',
        id: 'o:amountGroup',
      },
      {
        f: 'core.dictionary_trade_servers.type',
        id: 'o:serverType',
      },
      {
        f: 'partners.groups_lists.status',
        id: 'o:statusList',
      },
      {
        f: 'partners.groups_lists.created_at',
        id: 'o:createdAt',
      },
      {
        f: 'partners.groups_lists.updated_at',
        id: 'o:updatedAt',
      },
    ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

    // panels
    ...[
      {
        ii: 'f:id',
        t: 'magic_filter_translate_partners_symbols_lists_id',
      },
      {
        ii: 'f:name',
        t: 'magic_filter_translate_partners_symbols_lists_name',
      },
      {
        ii: 'f:server',
        t: 'magic_filter_translate_partners_symbols_lists_server',
      },
      {
        ii: 'f:status',
        t: 'magic_filter_translate_partners_symbols_lists_status',
      },
      {
        ii: 'f:created',
        t: 'magic_filter_translate_partners_symbols_lists_created_at',
      },
      {
        ii: 'f:updated',
        t: 'magic_filter_translate_partners_symbols_lists_updated_at',
      },
    ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

  ]
  .map(itemsBuild)
  .reduce(itemsDrain, [])
  .reduce(itemsResort, {}),

  path: 'partners/groups-lists',
  widget: false,
};
