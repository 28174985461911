import {
  itemsBuild,
  itemsDrain,
  itemsResort,
  CFG_ITEM_TYPE,
} from '../Config';
import { onEnabled, render, } from '../Mentor/withDropDown';

const f_city = {
  'drop-down': '/api/core/city/with-account/drop-down?take=all',
  'o=': [ '=', '!=', ],
};

const f_country = {
  'drop-down': '/api/core/country/drop-down?take=all',
  'o=': [ '=', '!=', ],
};

const f_timeZone = {
  'drop-down': '/api/core/time-zone/drop-down?take=all&add_fields[]=offset',
  'o=': [ '=', '!=', ],
};

const p_city = {
  onEnabled: onEnabled('cities'),
  render: render('cities'),
};

const p_country = {
  onEnabled: onEnabled('countries'),
  render: render('countries'),
};

const p_timeZone = {
  onEnabled: onEnabled('timeZones'),
  render: render(
    'timeZones',
    _ => _ ? `${ _.name } (${ _.offset })` : '-',
  ),
};

export default {
  id: 164,
  ...[

    // filters
    ...[ {
      f: [ 'core.account.surname', 'core.account.name', ],
      id: 'f:aFullname',
      o: 'like',
    }, {
      f: 'core.system_accounts.id',
      id: 'f:saId',
      off: true, // TODO:
    }, {
      f: 'core.system_accounts.type',
      id: 'f:saType',
    }, {
      f: 'core.account.created_at',
      id: 'f:aCreated',
    }, {
      f: 'core.account_categories.category_id',
      id: 'f:acCategoryId',
    }, {
      f: 'core.country.id',
      id: 'f:country',
      ...f_country,
    }, {
      f: 'core.city.id',
      id: 'f:city',
      ...f_city,
    }, {
      f: 'core.account.time_zone_id',
      id: 'f:timeZoneId',
      ...f_timeZone,
    }, {
      f: 'payment.orders.date_paid',
      id: 'f:oAtPaid',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

    // orders
    ...[ {
      f: 'partners.partner.name',
      id: 'o:pName',
    }, {
      f: 'core.account.name',
      id: 'o:aName',
    }, {
      f: 'core.account.created_at',
      id: 'o:aCreated',
    }, {
      f: 'core.account_categories.category_id',
      id: 'o:acCategoryId',
    }, {
      f: 'core.account.referrer_page',
      id: 'o:aReferrerPage',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

    // panels
    ...[ {
      ii: 'f:aFullname',
      t: 'magic_filter_translate_partners_referrals-4-partner_account',
    }, {
      ii: 'f:saId',
      off: true, // TODO:
      t: 'magic_filter_translate_partners_referrals-4-partner_system_accounts',
    }, {
      ii: 'f:saType',
      t: 'magic_filter_translate_partners_referrals-4-partner_system_accounts_type',
    }, {
      ii: 'f:aCreated',
      t: 'magic_filter_translate_partners_referrals-4-partner_created_at',
    }, {
      ii: 'f:acCategoryId',
      t: 'magic_filter_translate_partners_referrals-4-partner_category_id',
    }, {
      ii: 'f:country',
      off: true, // TODO:
      t: 'magic_filter_translate_partners_referrals-4-partner_country',
      ...p_country,
    }, {
      ii: 'f:timeZoneId',
      t: 'magic_filter_translate_partners_requests_time_zone',
      ...p_timeZone,
    }, {
      ii: 'f:city',
      t: 'magic_filter_translate_partners_referrals-4-partner_city',
      ...p_city,
    }, {
      ii: 'f:oAtPaid',
      t: 'magic_filter_translate_partners_referrals-4-partner_date_paid',
    }, ]
    .filter(_ => _ && !_.off)
    .map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

  ]
  .map(itemsBuild)
  .reduce(itemsDrain, [])
  .reduce(itemsResort, {}),

  path: 'partners/referrals/by-partner_id/1',
  widget: false,
};