import Config from "./ListAccountsLinkConfig";

export const toolbarGeneral = (
    translate,
    active,
    tradingAccountId,
    permissions
) => Config(
    translate,
    [ 'general', ],
    // () => ({ no: true }),
    id => ({
        ...(id === active) && { active: true },
    }),
    { tradingAccountId, },
    permissions
);

export const toolbarMoreMT4 = (
    translate,
    active,
    tradingAccountId,
    permissions,
    isDemoTradingAccountType
) => Config(
    translate,
    [
        `opened-orders-mt4`,
        `closed-orders-mt4`,
        `risk-manager-mt4`,
        `logs-mt4`,
    ],
    id => ({
        ...(id === active) && { active: true },
    }),
    { tradingAccountId, },
    permissions,
    isDemoTradingAccountType
);

export const toolbarMoreMT5 = (
    translate,
    active,
    tradingAccountId,
    permissions,
    isDemoTradingAccountType
) => Config(
    translate,
    [
        `opened-positions-mt5`,
        `opened-orders-mt5`,
        `closed-orders-mt5`,
        `closed-deals-mt5`,
        'closed-positions-mt5',
        `risk-manager-mt5`,
    ],
    id => ({
        ...(id === active) && { active: true },
    }),
    { tradingAccountId, },
    permissions,
    isDemoTradingAccountType
);
