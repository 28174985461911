import { MagicObservable } from "../Magic/components/MagicFilter/services/classes/MagicObservable";

export default class FileService {
    constructor () {
        let uploadingFiles = [],
            newFiles = false;
        const event = new MagicObservable();
        const subscribe = (listener) => {
            event.subscribe(listener);
        };
        const unsubscribe = (listener) => {
            event.unsubscribe(listener);
        };
        const emit = async (...data) => {
            await event.emit(...data);
        };
        Object.defineProperties(this, {
            uploadingFiles: {
                get: () => [...uploadingFiles],
                set: (value) => {
                    uploadingFiles = value,
                    newFiles = true;
                },
            },
            filterDocumentArray: {
                value: (cond) => {
                    if (cond instanceof Function) {
                        const indexes = uploadingFiles
                            .map((item, index) => cond(item) ? index : false)
                            .filter(index => index !== false);

                        return this.filterDocumentArray(indexes);
                    } else if (cond instanceof Array) {
                        const indexes = cond.sort((a, b) => b-a);
                        const items = indexes.map(index => this.filterDocumentArray(index));

                        return [].concat(...items);
                    } else if (typeof cond === 'number') {
                        return uploadingFiles.splice(cond, 1);
                    }
                    return [];
                },
            },
            newFiles: {
                get: () => newFiles,
            },
            event:       { get: () => event },
            subscribe:   { value: subscribe },
            unsubscribe: { value: unsubscribe },
            emit:        { value: emit },
        });
    }

    async updateDocument (template) {
        this.uploadingFiles = template;
        await this.changeDocuments(this.uploadingFiles);
    }

    async changeDocuments (option) {
        await this.emit('change', option);
    }

    getFileFromInput = (options) => async (event) => {
        const file = event?.target?.files?.[0];
        const newFile = { file, xx: 27 };
        if (file?.size <= options.size) {
            const uploadFiles = [...this.uploadingFiles, newFile];
            this.uploadingFiles = uploadFiles;
            await this.changeDocuments(this.uploadingFiles);

            return true;
        } else {
            return false;
        }
    };
}
