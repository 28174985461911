import React from "react";
import { map } from "lodash";
import { withLocalize, Translate } from "react-localize-redux";

import dateFns from "date-fns";
import { get_random_guid } from "../../../../helpers/OtherHelper";

class UserProgress extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            currentDay:   this.props.currentDay,
            currentMonth: this.props.currentMonth,
            currentMonthNum: this.props.currentMonthNum,
            staticCurMonth: new Date().getMonth() + 1,
        };
    }

    UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
        this.setState({
            currentMonth: nextProps.currentMonth,
            currentMonthNum: nextProps.currentMonthNum,
            selectedDate: nextProps.selectedDate,
        });
    }

    render () {
        const results = this.props.results;
        const { currentMonth, currentDay, staticCurMonth, currentMonthNum } = this.state;

        const monthStart = dateFns.startOfMonth(currentMonth);
        const monthEnd = dateFns.endOfMonth(monthStart, { weekStartsOn: 1 });
        const styleMonth = staticCurMonth > currentMonthNum ?
            {
            width:     `${monthEnd.getDate() * 100 / monthEnd.getDate()}%`,
            flexBasis: `${monthEnd.getDate() * 100 / monthEnd.getDate()}%`,
        } : {
                width:     `${currentDay.getDate() * 100 / monthEnd.getDate()}%`,
                flexBasis: `${currentDay.getDate() * 100 / monthEnd.getDate()}%`,
            }
        ;

        return (
          <Translate>
              {({translate}) => {
                  return (
                      <React.Fragment>
                          <div className = 'month-line'>
                              <div className = 'progress-month' style = { styleMonth } />
                          </div>
                          {
                              map(results, (item) => {
                                  const progress = Math.round(item.result * 100 / item.aim);
                                  const should = staticCurMonth > currentMonthNum ? Math.round(item.aim / monthEnd.getDate() * monthEnd.getDate()) : Math.round(item.aim / monthEnd.getDate() * currentDay.getDate());
                                  const shouldWidth = should * 100 / item.aim;
                                  const progressWidth = item.result * 100 / item.aim;

                                  const styleShould = {
                                      width: `${shouldWidth < 100 ? shouldWidth : 100}%`,
                                  };
                                  const styleShouldLine = {
                                      left: `${shouldWidth < 100 ? shouldWidth : 100}%`,
                                  };
                                  const styleActual = {
                                      width: `${progressWidth < 100 ? progressWidth : 100}%`,
                                  };

                                  return (
                                      <div className = 'progress-block' key = { get_random_guid() }>
                                          <div className = 'progress-title'>{translate(`${item.title}`)}</div>
                                          <div className = 'progressbar-wrapper'>
                                              <div className="progress-aim">{item.aim}</div>
                                              <div className = 'progress-general'></div>
                                              <div className = 'progress-should' style = { styleShould }>
                                                  <span>{should}</span>
                                              </div>
                                              <div className = 'progress-should-line' style = { styleShouldLine }></div>
                                              <div className = 'progress-actual' style = { styleActual }></div>
                                              <div className = 'progress-actual-text' style = { styleActual }><span>{item.result}/{progress}%</span></div>
                                          </div>
                                      </div>
                                  );
                              })
                          }
                      </React.Fragment>
                  );
              }}
          </Translate>
        );
    }
}

export default withLocalize(UserProgress);
