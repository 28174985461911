import ContestsLinkConfig from "./ContestsLinkConfig";

export const breadcrumbs4Contest = ( translate, contestId ) => {
    return ContestsLinkConfig(
        translate,
        [
            'contests',
            'contest',
        ],
        {
          contestId,
        }
    );
};

export const breadcrumbs4Contests = ( translate ) => {
    return ContestsLinkConfig(
        translate,
        [
            'contests',
        ],
        { }
    );
};
