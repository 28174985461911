import React from 'react';
import { withLocalize, } from "react-localize-redux";
import SearchAccounts from './SearchAccounts';
import SearchAccountsSafe from './SearchAccountsSafe';
import SearchSystemAccounts from './SearchSystemAccounts';
import SearchSystemAccountsId from './SearchSystemAccountsId';
import SearchTradingAccounts from './SearchTradingAccounts';
import SearchTradingAccountsId from './SearchTradingAccountsId';

class Search extends React.Component {
  state = {
    status: Array(6).fill(null),
  };

  // eslint-disable-next-line require-await
  save = async (state) => {
    return new Promise(next => this.setState(state, next));
  };

  count = index => async (value) => {
    await this.save(({ status: s, }) => {
      return { status: Object.values({ ...s, [index]: value, }), };
    });
  };

  render = () => (
    <>
      <div><SearchAccounts count={ this.count(0) } /></div>
      <div><SearchAccountsSafe count={ this.count(1) } /></div>
      <div><SearchSystemAccounts count={ this.count(2) } /></div>
      <div><SearchSystemAccountsId count={ this.count(3) } /></div>
      <div><SearchTradingAccounts count={ this.count(4) } /></div>
      <div><SearchTradingAccountsId count={ this.count(5) } /></div>
      {
        this.state.status.every(_ => _ === 0) &&
        (<h2>{ this.props.translate('search_not_found') }</h2>)
      }
    </>
  );
}

export default withLocalize(Search);