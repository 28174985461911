import React, { Component } from 'react';
import { withLocalize } from "react-localize-redux";
import { withRouter } from 'react-router-dom';
import EditPopUp from "./EditPopUp";
import { isNull, isUndefined, isEmpty } from "lodash";
import RiskManagerParametrs from "./RiskManagerParametrs";
import RiskManagerInstruments from "./RiskManagerInstruments";
import { VALUE, PERCENT, TIMA, USD, EUR } from '../../../consts/constants';
import { MagicConfirm } from "../../../../tima/components/Magic/MagicConfirm";
import NotificationService from "../../../../../services/NotificationService";
import Preloader from "../../../../../components/LoadingHOC/Preloader";
import Loader from "../../../../tima/components/Loader";
import moment from "moment";
import RiskNodeServiceConnect from "../../../../../services/RiskNodeService";
import { enumService } from "../../../../../services/EnumDataService";
import { listAccountService } from "../../../../../services/ListAccountDataService";

const DATE_FORMAT = 'DD.MM.YYYY, 00:00:00';
const DATE_FORMAT_FULL = 'DD.MM.YYYY, HH:mm:ss';
const PATH_TO_TRADING_ACCOUNTS = '/list-accounts/tradings';

class RiskManager extends Component {
    constructor (props) {
        super(props);

        this.state = {
            isPopUpActive:          false,
            isHintActive:           false,
            riskData:               {},
            risksServerData:        {},
            riskToolsAll:           [],
            riskToolsCategoriesAll: [],
            get_risks_categorie_ID: {},
            RISK_TYPE:              null,
            user_id:                null, // mt_login
            riskId:                 null,
            symbol:                 null,

            isMagicConfirmVisible: {
                unlock: false,
                delete: false,
            },

            isBtnDisabled: {
                edit:    false,
                refresh: false,
                unlock:  false,
                delete:  false,
            },

            isBlockedRiskManager: false,
            loadedInnerRequest:   true,
            loaded:               false,
        };

        RiskNodeServiceConnect.init(this.isReal(), this.isPlatformMT4()); // Инициализация сервиса в конструкторе риск компонента

        window.addEventListener('beforeunload', (e) => {
            RiskNodeServiceConnect.logout(); // дисконнект
        });
    }

    save = async (state) => new Promise(next => this.setState(state, next));

    enumsChange = async (enums) => {
        if (enums.hash===this.state?.enums?.hash) return `${this.constructor.name}.enumsChange: false`;
        await this.save({ enums, });
        return `${this.constructor.name}.enumsChange: true`;
    };

    async componentDidMount () {
        enumService.subscribe('enums', this.enumsChange, this);
        await enumService.enums;
        await this.onDataLoad();
    }

    componentWillUnmount () {
        RiskNodeServiceConnect.logout(); // дисконнект
        enumService.unsubscribe('enums', this.enumsChange, this);
    }

    get coreTradingAccounts () { return this.props?.data?.core?.trading_accounts ?? {}; }

    get tradingAccountType () { return this.coreTradingAccounts?.type?.toString() ?? null; }

    get platform () { return this.coreTradingAccounts?.platform?.toString() ?? null; }

    get mt5GroupType () { return this.coreTradingAccounts?.mt5_group_type?.toString() ?? null; }

    isReal = () => !this.props?.isDemoTradingAccountType;

    isPlatformMT4 = () => this.platform === '1'; // AccountBalancesPlatformEnum

    isPlatformMT5 = () => this.platform === '2'; // AccountBalancesPlatformEnum

    initRiskNodeService = () => {
        const { user_id: mt_login } = this.state;

        if (mt_login) {
            RiskNodeServiceConnect.on().message(this.callbackFunc);
            RiskNodeServiceConnect.emit().newID(mt_login.toString());
        }
    };

    reConnectRiskWebSocket = () => {
        RiskNodeServiceConnect.init(this.isReal(), this.isPlatformMT4()); // Инициализация сервиса в конструкторе риск компонента
        this.initRiskNodeService();
    };

    callbackFunc = (data) => {
        let result = JSON.parse(data);

        if (result.success === 'false') {
            return false;
        }
        if (isUndefined(result) || isNull(result) || isEmpty(result)) {
            return false;
        }

        const newState = {
            riskData: {
                ...this.state.riskData,
                risk_app: { response: result },
            },
        };

        this.setState(newState);
    };

    // TODO: onDataLoad renew style
    onDataLoad = () => {
        this._getRisksID(this.props.id);
    };

    setLoadedInnerRequest (loadedInnerRequest) {
        this.setState(() => ({ loadedInnerRequest, }));
    }

    _getRisksID = async (id) => {
        const setLoaded = (loaded) => this.setState(() => ({ loaded, }));

        /*const { isBtnDisabled } = this.state;
        const action = 'refresh';

        if (isBtnDisabled[action]) {
            return;
        }*/

        setLoaded(false);
        this.setAllBtnsDisabled(true);

        try {
            const { data } = this.isPlatformMT5() ? await listAccountService.risksByTradingAccountIdMT5(id) : await listAccountService.risksByTradingAccountId(id);
            const { isDemoTradingAccountType } = this.props;

            this.setState(() => {
                const { core = {} } = data;
                const { risks_live = {}, risks_demo = {}, risk_app = {} } = core;
                const risksServerData = isDemoTradingAccountType ? risks_demo : risks_live;
                const { risk_type, user_id, id: riskId } = risksServerData;
                const { response: risk_app_response = {} } = risk_app;
                const { is_blocked: isBlockedRiskManager = false } = risk_app_response;

                return {
                    riskData:  core,
                    risksServerData,
                    RISK_TYPE: risk_type,
                    isBlockedRiskManager,
                    user_id,
                    riskId,
                };
            });

            this.symbolType();
            await this._getRisksCategories(id);
            await this._getRiskToolsAllByLoginMT(id);

            this.initRiskNodeService();
        } catch (error) {
            error?.showErrorNotification?.();
        }

        setLoaded(true);
        this.setAllBtnsDisabled(false);

    };

    _getRisksCategories = async (trading_account_id) => {
        try {
            const { data } = await listAccountService.risksCategories(trading_account_id);

            const formattedData = data?.map?.((item) => item.core);

            this.setState(() => {
                return { riskToolsCategoriesAll: formattedData };
            });
        } catch (error) {
            error?.showErrorNotification?.();
        }
    };

    _getRiskToolsAllByLoginMT = async (trading_account_id) => {
        try {
            const { data } = await listAccountService.riskToolsAllByLoginMT(trading_account_id);

            const formattedData = data?.map?.((item) => item.core);

            this.setState(() => {
                return { riskToolsAll: formattedData };
            });
        } catch (error) {
            error?.showErrorNotification?.();
        }
    };

    symbolType = () => {
        const enumSymbol = this.state.enums?.data?.core?.AccountCurrencyEnum?.[this.state.riskData?.trading_accounts?.acc_ccy];
        const symbol = enumSymbol; // String.fromCodePoint(enumSymbol);

        if (parseInt(this.state.RISK_TYPE) === VALUE) {
            if (parseInt(this.state.riskData.trading_accounts.acc_ccy) === USD || parseInt(this.state.riskData.trading_accounts.acc_ccy) === EUR) {
                this.setState(() => ({
                    symbol: symbol,
                }));
            }
        } else if (parseInt(this.state.RISK_TYPE) === PERCENT || parseInt(this.state.RISK_TYPE) === TIMA) {
            this.setState(() => ({
                symbol: '%',
            }));
        } else {
        }
    };

    editRefresh = () => {
        this._getRisksID(this.props.id);
    };

    setBtnDisabled = (action, isBtnDisabled) => {
        this.setState((prevState) => {
            return { isBtnDisabled: { ...prevState.isBtnDisabled, [action]: isBtnDisabled, } };
        });

    };

    setAllBtnsDisabled = (isBtnDisabled) => {
        this.setState((prevState) => {
            for (let key in prevState.isBtnDisabled) {
                prevState.isBtnDisabled[key] = isBtnDisabled;
            }

            return { isBtnDisabled: prevState.isBtnDisabled };
        });

    };

    onConfirmClick = (action) => (event) => {
        const { isBtnDisabled } = this.state;

        this.closeAllMagicConfirm();

        if (isBtnDisabled[action]) {
            return;
        }

        this.setAllBtnsDisabled(true);
        this.setLoadedInnerRequest(false);

        (async () => {
            try {
                const { id } = this.props;
                const { user_id, riskId } = this.state;

                switch (action) {
                    case 'refresh': {
                        const { data } = this.isPlatformMT5() ? await listAccountService.riskManagerMT5(id) : await listAccountService.riskManager(id); // id of TradingAccountId

                        if (data.error) {
                            throw new Error(data.error);
                        }

                        this.onDataLoad();
                        // TODO: the logic after success !!!
                        break;
                    }
                    case 'unlock': {
                        const { data } = this.isPlatformMT5() ? await listAccountService.unlockRiskManagerMT5(id) : await listAccountService.unlockRiskManager(id); // id of TradingAccountId

                        if (data.error) {
                            throw new Error(data.error);
                        }

                        this.onDataLoad();
                        // TODO: the logic after success !!!
                        break;
                    }
                    case 'delete': {
                        const { data } = this.isPlatformMT5() ? await listAccountService.deleteRiskManagerMT5(id) : await listAccountService.deleteRiskManager(id); // id of TradingAccountId

                        if (!data) {
                            throw new Error(data.error);
                        }

                        // go to OneTradingAccountGeneralInfo page
                        this.props.history.push(`${PATH_TO_TRADING_ACCOUNTS}/${id}`);
                        // TODO: the logic after success !!!
                        break;
                    }
                    default: {
                        throw new Error(`no necessary request for action: '${action}' !`);
                    }
                }

            } catch (error) {
                NotificationService.error({
                    title:   "error",
                    message: error.message,
                    remove:  false,
                });
            }

            this.setAllBtnsDisabled(false);
            this.setLoadedInnerRequest(true);
        })();
    };

    closeAllMagicConfirm = async () => {
        const { isMagicConfirmVisible } = this.state;

        for (const key in isMagicConfirmVisible) {
            isMagicConfirmVisible[key] = false;
        }

        await this.save({ isMagicConfirmVisible });
    };

    confirmRejectHandler = (actionName) => (event) => {
        this.closeAllMagicConfirm();
    };

    onActionBtnClick = (actionName) => async (event) => {
        const { isMagicConfirmVisible } = this.state;

        for (const key in isMagicConfirmVisible) {
            isMagicConfirmVisible[key] = key === actionName;
        }

        await this.save({ isMagicConfirmVisible });
    };

    editPopUpClose = () => {
        const { isBtnDisabled, isPopUpActive } = this.state;
        const newIsPopUpActive = !isPopUpActive;
        const action = 'edit';

        if (isBtnDisabled[action]) {
            return;
        }

        newIsPopUpActive ? RiskNodeServiceConnect.logout() : this.reConnectRiskWebSocket();
        this.setState({ isPopUpActive: newIsPopUpActive });
    };

    hintClose = () => {
        this.setState((state) => ({ isHintActive: !state.isHintActive }));
    };

    isUnlockBtnShown = () => {
        return this.state.isBlockedRiskManager;
    };

    isDeleteBtnShown = () => {
        /*const TradingAccountTypeEnum = {
            "2": "Tima_manager",
            "5": "Trading",
            "6": "Demo",
            "7": "Demo_contest",
            "8": "Demo_risk",
            "10": "Demo_m",
        };*/

        return this.tradingAccountType !== '2';
    };

    getEnumObjByFieldName = (fieldName) => {
        const { enums } = this.state;
        let enumObj = {};

        try {
            switch (fieldName) {
                case 'blocked_by': {
                    enumObj = enums?.data?.core?.RiskLockingReasonsEnum;
                    break;
                }
                default: {
                    break;
                }
            }
        } catch (error) {
            throw new Error(`Error getEnumObjByFieldName { fieldName: ${ fieldName } }`);
        }

        return enumObj;
    };

    getFieldValueFromState = (fieldName) => {
        const { riskData = {}, risksServerData = {} } = this.state;
        const { risk_app = {}, } = riskData;
        const { response = {} } = risk_app;
        const { risk_params = {} } = risksServerData;
        const defaultFieldValue = '';

        let fieldValue;

        try {
            switch (fieldName) {
                case 'blocked_by':
                case 'blocked_until': {
                    fieldValue = response[fieldName];
                    break;
                }
                case 'month_risk': {
                    fieldValue = Math.abs(parseFloat(risk_params[fieldName])) || 0;
                    break;
                }
                case 'action_type':
                case 'action_data':
                case 'action_date': {
                    fieldValue = risksServerData[fieldName];
                    break;
                }

                default: {
                    fieldValue = defaultFieldValue;
                    break;
                }
            }
        } catch (error) {
            fieldValue = defaultFieldValue;
        }

        return fieldValue;
    };

    getFieldValueWithFormatter (fieldName) {
        const fieldValue = this.getFieldValueFromState(fieldName);

        return this.fieldFormatter(fieldName, fieldValue);
    }

    fieldFormatter (fieldName, fieldValue) {
        try {
            switch (fieldName) {
                case 'blocked_by': {
                    return this.getEnumObjByFieldName(fieldName)[fieldValue];
                }
                case 'blocked_until': {
                    return moment(fieldValue * 1000).format(DATE_FORMAT);
                }
                case 'action_date': {
                    return moment(fieldValue * 1000).format(DATE_FORMAT_FULL);
                }
                default:
                    return fieldValue;
            }
        } catch (error) {
            throw new Error(`Error fieldFormatter( fieldName: ${ fieldName } }`);
        }
    }

    getNextMonth = () => {
        return moment().add(1, 'months').startOf('month').format(DATE_FORMAT);
    };

    render () {
        const { translate, id, isDemoTradingAccountType } = this.props;
        const {
            riskData = {},
            risksServerData = {},
            riskToolsCategoriesAll = [],
            riskToolsAll = [],
            isMagicConfirmVisible,
            isBtnDisabled = {},
            loadedInnerRequest,
            loaded,
            symbol,
            enums,
        } = this.state;

        const tradingAccountType = this.tradingAccountType;
        const platform = this.platform;
        const mt5GroupType = this.mt5GroupType;
        const { risk_app = {}, trading_accounts = {} } = riskData;
        const { action_type } = risksServerData;

        const { unlock: isUnlockConfirmVisible, delete: isDeleteConfirmVisible } = isMagicConfirmVisible;
        const {
            unlock:  isUnlockBtnDisabled,
            delete:  isDeleteBtnDisabled,
            refresh: isRefreshBtnDisabled,
            edit:    isEditBtnDisabled,
        } = isBtnDisabled;
        const btnClassNameDisabled = 'update_info_btn--disabled';
        const editBtnClassName = isEditBtnDisabled ? btnClassNameDisabled : '';
        const refreshBtnClassName = isRefreshBtnDisabled ? btnClassNameDisabled : '';
        const unlockBtnClassName = isUnlockBtnDisabled ? btnClassNameDisabled : '';
        const deleteBtnClassName = isDeleteBtnDisabled ? btnClassNameDisabled : '';
        const [isUnlockBtnShown, isDeleteBtnShown] = [this.isUnlockBtnShown(), this.isDeleteBtnShown()];

        return (
            <div className='information-wrapper additional-info-account risk-manager'>

                {!loadedInnerRequest ?
                    (
                        <Preloader className='loaderUniversal--fixed-pos' scale={1}/>
                    ) : false
                }

                <div className="update_info_btn update_info_btn--flex-cont">
                    <span className={`update_info_btn_edit ${editBtnClassName}`}
                          onClick={() => this.editPopUpClose()}>{translate('list_accounts_risk_edit')}</span>
                    <span className={`update_info_btn_refresh ${refreshBtnClassName}`}
                          onClick={this.onConfirmClick('refresh')}>{translate('list_accounts_risk_refresh')}</span>

                    {
                        isUnlockBtnShown ?
                            (
                                <div className="magic-confirm-wrapper">
                                    <MagicConfirm
                                        onAccept={this.onConfirmClick('unlock')}
                                        onReject={this.confirmRejectHandler('unlock')}
                                        title={translate(`magic_confirm_title`)}
                                        accept={translate(`magic_confirm_yes`)}
                                        reject={translate(`magic_confirm_no`)}
                                        isVisible={!!isUnlockConfirmVisible}
                                    />

                                    <span className={`update_info_btn--unlock ${unlockBtnClassName}`}
                                          onClick={this.onActionBtnClick('unlock')}
                                    >
                                        {translate('list_accounts_risk_unlock_btn')}
                                    </span>
                                </div>
                            ) : false
                    }

                    {
                        isDeleteBtnShown ?
                            (
                                <div className="magic-confirm-wrapper">
                                    <MagicConfirm
                                        onAccept={this.onConfirmClick('delete')}
                                        onReject={this.confirmRejectHandler('delete')}
                                        title={translate(`magic_confirm_title`)}
                                        accept={translate(`magic_confirm_yes`)}
                                        reject={translate(`magic_confirm_no`)}
                                        isVisible={!!isDeleteConfirmVisible}
                                    />

                                    <span className={`update_info_btn--delete ${deleteBtnClassName}`}
                                          onClick={this.onActionBtnClick('delete')}
                                    >
                                        {translate('list_accounts_risk_delete_btn')}
                                    </span>
                                </div>
                            ) : false
                    }

                </div>

                <div className='basic_information-line'/>

                <div className='title-cont--errors'>
                    {
                        isUnlockBtnShown ?
                            (
                                <div className='title-cont title-cont--error'>
                                    <p className='title'>
                                        {`${translate('list_accounts_risk_blocked_text_1')} ${this.getFieldValueWithFormatter('blocked_until')} ${translate('list_accounts_risk_blocked_text_2')} "${this.getFieldValueWithFormatter('blocked_by')}"`}
                                    </p>
                                </div>
                            ) : false
                    }

                    {
                        !isNull(action_type) && parseInt(action_type) === 1 ?
                            (
                                <div className='title-cont title-cont--error'>
                                    <p className='title'>
                                        {`${translate('list_accounts_risk_delete_risk_text')} ${this.getFieldValueWithFormatter('action_date')}`}
                                    </p>
                                </div>
                            ) : false
                    }

                    {
                        !isNull(action_type) && parseInt(action_type) === 2 ?
                            (
                                <div className='title-cont title-cont--error'>
                                    <p className='title'>
                                        {`${translate('list_accounts_risk_change_risk_month_text')} ${this.getNextMonth()} ${translate('list_accounts_risk_change_risk_month_from')} ${this.getFieldValueFromState('month_risk')} ${symbol} ${translate('list_accounts_risk_change_risk_month_to')} ${this.getFieldValueFromState('action_data')} ${symbol}`}
                                    </p>
                                </div>
                            ) : false
                    }
                </div>

                <div className='title-cont'>
                    <p className='title'>{translate(`list_accounts_risk_manager_options`)}</p>
                </div>

                <Loader
                    loaded={loaded}
                    loading={(<Preloader scale={1}/>)}
                >
                    <React.Fragment>
                        <RiskManagerParametrs
                            risksServerData={risksServerData}
                            riskAppData={risk_app}
                            enums={enums?.data}
                            symbol={symbol}
                            platform={platform}
                            tradingAccountType={tradingAccountType}
                            mt5GroupType={mt5GroupType}
                        />

                        <RiskManagerInstruments
                            riskToolsAll={riskToolsAll}
                            riskToolsCategoriesAll={riskToolsCategoriesAll}
                            translate={translate}
                        />

                        {this.state.isPopUpActive
                            ?
                            <EditPopUp
                                editPopUpClose={this.editPopUpClose}
                                refreshDataFunc={this.onDataLoad}
                                isDemoTradingAccountType={isDemoTradingAccountType}
                                riskData={riskData}
                                risksServerData={risksServerData}
                                riskToolsAll={riskToolsAll}
                                riskToolsCategoriesAll={riskToolsCategoriesAll}
                                enums={enums?.data}
                                symbol={symbol}
                                platform={platform}
                                tradingAccountType={tradingAccountType}
                                mt5GroupType={mt5GroupType}
                                translate={translate}
                            />
                            : false}

                    </React.Fragment>
                </Loader>
            </div>
        );
    }
}

export default withLocalize(withRouter(RiskManager));
