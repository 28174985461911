import React from 'react';
import PropTypes, {bool} from 'prop-types';
import {LEAD_PROP_TYPES} from '../../../../components/PropTypes/Lead_PropTypes';
import {Col, Fade, Nav, NavItem, Row, TabContent, TabPane,} from 'reactstrap';

import classnames from 'classnames';

import LeadCustomerData from "./LeadCustomerData/LeadCustomerData";
import userAvatar from "../../../../images/avatar-2.jpg";
import LeadAppealsView from "./LeadAppeals/LeadAppealsView";
import LeadDeposits from "./LeadDeposits";
import LeadPayments from "./LeadPayments";
import LeadSupport from "./LeadSupport";
import LeadTima from './LeadTima';
import Interaction from "./Interaction/index";
import LeadPopUpAction from "./LeadPopUpAction/LeadPopUpAction";
import {Link, withRouter} from 'react-router-dom';
import connect from "react-redux/es/connect/connect";
import {bindActionCreators} from "redux";
import {
    clearClient,
    deleteClient,
    getAccountCountries,
    getClient,
    getDictionaryAccountType,
    setAccountStatusActive,
    setAccountStatusArchive,
    setResetError,
} from "../../services/AccountService";
import {isNull, isUndefined, map, startCase, toLower} from "lodash";
import SelectSubStatus from "./SelectSubStatus/SelectSubStatus";
import {Translate, withLocalize} from 'react-localize-redux';
import PermissionService, {MODULE_KEYS as pm} from "../../../../services/PermissionService";
import CheckUndefinedPermission from "../../../../components/Common/CheckUndefinedPermission";
import ErrorBoundry from "../../../../components/ErrorBoundry/index";
import HistoryChangesComponent from "../../../../components/HistoryChanges/index";
import {GET_ACCOUNT_HISTORY} from "../../../../apiRoutes";
import UserModalPopUp from "./UserModalPopUp/index";
import person from "../../../../images/person.svg";
import letter from "../../../../images/letter.svg";
import call from "../../../../images/call.svg";
import {Scrollbars} from "react-custom-scrollbars";
import NewTaskCreateForm from "../../../tasks/components/NewTaskCreateForm/index";
import DataStorage from "../../../../helpers/DataStorage";
import NotificationService from "../../../../services/NotificationService";
import {setMailTemplates, setSmsTemplates} from "../../../dashboard/actions";
import {
    changeWarmType,
    resendRegistrationEmail,
    sendAccountManager,
    updateAccountProperty,
    changePhoneAccount,
    updateEmailAccount,
    updatePhoneAccount,
} from '../../services/AccountRequestService';
import LoadingHOC from "../../../../components/LoadingHOC";
import {TABID_0, TABID_4} from './constants';
import {descriptionHelperCategory, descriptionHelperWarmType} from "../../services/descriptionHelper";
import moment from "moment";
import {MagicPopUp} from "../../../../components/Magic/components/MagicPopUp";
import './LeadComponent.scss';
import {MagicTooltip} from "../../../tima/components/Magic/MagicTooltip";
import MessagePopupNew from '../MessagePopup/index';
import {MagicButton} from '../../../../components/Magic';
import {callService,} from "../../../../services/CallDataService";
import {profileService,} from '../../../../services/ProfileDataService';
import {userService,} from "../../../../services/UserDataService";
import Preloader from "../../../../components/LoadingHOC/Preloader";
import {MagicConfirm} from "../../../tima/components/Magic/MagicConfirm";
import LeadLetters from "./LeadLetters/LeadLetters";
import {clientService,} from "../../../../services/ClientDataService";
import mavrikiy from "../../../../images/flags/license_country_flags/mavrikiy.svg";
import vanuaty from "../../../../images/flags/license_country_flags/vanuaty.svg";
import Conversations from "../../../conversations/Conversations";
import {
    getAllDepartmentsByUserId
} from "../../../dashboard/services/DashboardRequestService";
import {setErrorClass, onValidEmailInput, emailInputIsValid, phoneInputIsValid, onValidPhoneInput} from "./helpers/helpers"
import { getMessagesByAccounttId } from '../../../conversations/services/ConversationsRequestService'

const customStyles = {
    control:     () => ({
        backgroundColor: '#ffffff',
    }),
    singleValue: (base, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { opacity, transition };
    },
};

class LeadComponent extends React.Component {
    static translateTabNav = {
        'clientdata':         'customer_data',
        'accounts':           'accounts',
        'interaction':        'interaction',
        'depositsandwithdrawals': 'payments_and_withdrawals',
        'appeals':            'appeals',
        'letters':            'letters',
        // 'activity':           'activity',
        'support':            'support',
        'tima':               'tima',
    };

    static propTypes = {
        refreshClientData: PropTypes.func.isRequired,
    };

    constructor (props) {
        super(props);

        const client = this.props?.dataAfterLoading?.core;
        const leadId = this.props.match.params.id ? this.props.match.params.id : null;

        const phonesArray = [];
        const emailsArray = [];

        if (!isNull(leadId) && !isUndefined(client.account_phones)) {
            if (!isNull(client.account_phones)) {
                client.account_phones.map((item) => {
                    phonesArray.push(item.phone);
                });
            }
        }

        if (isNull(leadId) && !isUndefined(client.account_emails)) {
            if (!isNull(client.account_emails)) {
                client.account_emails.map((item) => {
                    emailsArray.push(item.email);
                });
            }
        }

        this.toggle = this.toggle.bind(this);
        this.togglePopup = this.togglePopup.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.statusFormatter = this.statusFormatter.bind(this);
        this.deleteClientAction = this.deleteClientAction.bind(this);
        this.createTask = this.createTask.bind(this);
        this.winResizeRefBlock = this.winResizeRefBlock.bind(this);
        this.userModal = this.userModal.bind(this);
        this.sendManager = this.sendManager.bind(this);
        this.state = {
            account: null,
            quickTask:                   false,
            showCreate:                  null,
            activeTab:                   !isUndefined(this.props.match.params.taskId) ? TABID_4 : TABID_0,
            fadeIn:                      false,
            fadeInPop:                   false,
            userId:                      this.props.match.params.id,
            error:                       this.props.error,
            errors:                      [],
            account_type:                1,
            account_status:              1,
            elHeight:                    {},
            elDom:                       null,
            showHistoryChanges:          false,
            userFadePopUp:               false,
            userLanguageForConversations: [],
            manager:                     {
                user_id: null,
            },
            user: {
                hash: null,
                data: {},
            },
            usersAllAvatar:              {
                hash: null,
                data: {},
            },
            dropdownOpen:                false,
            togglePopupItem:             {
                bool: false,
                type: null,
                top:  0,
            },
            action_mails:                emailsArray,
            action_phones:               phonesArray,
            mailTemplates:               this.props.mailTemplates,
            smsTemplates:                this.props.smsTemplates,
            selectedType:                {},
            templates:                   [],
            writeType:                   [],
            selectedTemplate:            {},
            get_dictionary_account_type: {},
            archive_status:              {},
            showClientMessagePopup:      false,
            departmentsList: [],
            departmentsListNames: [],
            magicPopUp:                  {
                isActive: false,
                mode:     '',
                message:  '',
            },
            sendMessageType:              {},
            messageText: "",
            accountCountries:            [],

            ...this.getInitialActionFieldsValues(),
            loadedInnerRequest:          true,
            confirmRegistration: false,
            emailsAsSpam: false,
        };
    }

    save = async (state) => new Promise(next => this.setState(state, next));

    getInitialActionFieldsValues = () => {
        return {
            action_mail:  '',
            action_phone: '',
            subject:      '',
            subjectD:     '',
        };
    };

    avatarsChange = async (usersAllAvatar) => {
        if (usersAllAvatar.hash===this.state?.usersAllAvatar?.hash) return `${this.constructor.name}.avatarsChange: false`;
        await this.save({ usersAllAvatar });
        return `${this.constructor.name}.avatarsChange: true`;
    };

    profileChange = async (profile) => {
        if (profile.hash===this.state?.profile?.hash) {
					return `${this.constructor.name}.profileChange: false`;
				}
        await this.save({ profile, });
        return `${this.constructor.name}.profileChange: true`;
    };

    userChange = async (user) => {
        if (user.hash===this.state?.user?.hash) {
            return `${this.constructor.name}.userChange: false`;
        }
        if (user.id && user.id!==this.state?.profile?.data?.id) {
            return `${this.constructor.name}.userChange: false`;
        }
        await this.save({ user });
        return `${this.constructor.name}.userChange: true`;
    };

    userDepartmentIds = () => {
        return [this.state?.profile?.data?.permission_template_id];
    };

    componentDidMount = async () => {
        const { account } = this.props.dataAfterLoading?.core;
        await this.save({ account,});

        profileService.subscribe('profile', this.profileChange, this);
        userService.subscribe('getAllAvatar', this.avatarsChange, this);
        userService.subscribe('getUser', this.userChange, this);

        this.toggle(this.props.activeTab);
        this.props.setMailTemplates();
        this.props.setSmsTemplates();
        await profileService.profile;
        const profileId = this.state?.profile?.data?.id;
        await userService.getUser(profileId);

        const usersAllAvatar = await userService.getAllAvatar();
        [5, 15].includes(this.state.profile?.data?.permission_template_id)
            ? await this.checkAccountInSpamList()
            : null;
        await this.save({ usersAllAvatar });

        this._getDictionaryAccountType();
        await this.loadAccountCountries();
        const { data, } =  await getMessagesByAccounttId(this.state?.userId)
        await this.save({ userLanguageForConversations: data, });

        try {
            const responseDep = await getAllDepartmentsByUserId(this.state?.userId);
            const departmentsList = responseDep?.data;
            const departmentsListNames = departmentsList?.map(item => item?.alias);

            await this.save({ departmentsList, departmentsListNames, userBalance,  });
        } catch (error) {
            console.log(error);
        }

        window.addEventListener('resize', this.winResizeRefBlock);
        document.addEventListener('click', this.handlerClosePopup);
    };

    UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
        this.setState({
            error:         nextProps?.error,
            mailTemplates: nextProps?.mailTemplates,
            smsTemplates:  nextProps?.smsTemplates,
        });
        const writeType = [
            { value: '1', label: 'dashboard_crm_email' },
            { value: '2', label: 'dashboard_sendpulse' },
            { value: '3', label: 'dashboard_sms' }
        ];

        writeType.map((item) => {
            item.label = this.props?.translate?.(`${item?.label}`);
        });

        const templates = {};
        const val = [1, 2, 3];

        map(val, (num) => {
            templates[num] = [];
        });

        map(nextProps?.mailTemplates, (item) => {
            templates?.[item.system_id]?.push?.({
                value:   item?.template_id,
                label:   item?.template_name,
                subject: item?.template_subject,
            });
        });
        map(nextProps?.smsTemplates, (item) => {
            templates?.[3]?.push?.({
                value:   item?.core?.sms_templates?.id,
                label:   item?.core?.sms_templates?.name,
                subject: item?.core?.sms_templates?.text,
            });
        });
        ['3', 3].includes(this.state?.selectedType?.value) ?
            this.setState(
                {
                    templates,
                    writeType,
                    selectedType:     writeType?.[0],
                    selectedTemplate: templates?.[writeType?.[0]?.value] ? templates?.[writeType?.[0]?.value]?.[0] : '',
                    subject:          '',
                    subjectD:         '',
                }
            ) : this.setState(
            {
                templates,
                writeType,
                selectedType:     writeType?.[0],
                selectedTemplate: templates?.[writeType?.[0]?.value] ? templates?.[writeType?.[0]?.value]?.[0] : '',
                subject:          templates?.[writeType?.[0]?.value] ? templates?.[writeType?.[0]?.value]?.[0] ? templates?.[writeType?.[0]?.value]?.[0]?.subject : '' : '',
                subjectD:         templates?.[writeType?.[0]?.value] ? templates?.[writeType?.[0]?.value]?.[0] ? templates?.[writeType?.[0]?.value]?.[0]?.subject : '' : '',
            });

    }

    componentWillUnmount () {
        userService.unsubscribe('getAllAvatar', this.avatarsChange, this);
        userService.unsubscribe('getUser', this.userChange, this);
        profileService.unsubscribe('profile', this.profileChange, this);
        this.props.setResetError();
        this.props.clearClient();

        window.addEventListener('resize', this.winResizeRefBlock);
        document.removeEventListener('click', this.handlerClosePopup);
    }

    loadAccountCountries = async () => {
        try {
            const response = await getAccountCountries();

            response && await this.save({ accountCountries: response, });
        } catch (error) {
            await this.save({ accountCountries: [], });
            error?.showErrorNotification?.();
        }
    };

    _getSelectedCountryById = (countryId) => {
        let options = this?.state?.accountCountries ?? [];

        return options?.filter?.((item) => item?.id?.toString() === countryId?.toString())?.[0] ?? null;
    };

    get leadConfig() {
        return [
            {
                path: ['core', 'account'],
                key:  'coreAccount',
            },
            {
                path: ['core', 'account'],
                key: 'sendText',
            },
            {
                path: ['core', 'mail'],
                key: 'sendTemplate',
            },
        ];
    };

    // get changeEmailConfig() {
    //     return [{
    //             path: ['core', 'account_change_email', 'email'],
    //             key:  'changeEmail',
    //     },]
    // }

    // get changePhoneConfig(){
    //     return [{
    //         path: ['core', 'account_change_phone', 'phone'],
    //         key:  'changePhone',
    //     }, ]
    // }

    get accountPropertyConfig() {
        return [
            {
                path: ['core', 'account', 'email'],
                key:  'changeEmail',
            },
            {
                path: ['core', 'account', 'phone'],
                key:  'changePhone',
            },
            {
                path: ['core', 'account', 'is_email_verify'],
                key:  'verifyEmail',
            },
            {
                path: ['core', 'account', 'is_phone_verify'],
                key:  'verifyPhone',
            },
            {
                path: ['core', 'account'],
                key:  'decryptData',
            },
        ]
    }


    get isTechSupport (){
        return this.state.profile?.data?.tree_info?.some(department => department.department_alias === 'technical_support')
    }

    get isBackOffice (){
        return this.state.profile?.data?.tree_info?.some(department => department.department_alias === 'back_office')
    }

    get permissionToChangeEmail(){
        const permissionToUpdateEmail = this.getAccountPropertyPermissions('update', 'changeEmail');
        const is_email_verify = this.state.account?.is_email_verify;
        if(this.isTechSupport){
            return permissionToUpdateEmail && is_email_verify === 2
        }

        return permissionToUpdateEmail
    }

    get permissionToVerifyEmail(){
        const is_email_verify = this.state.account?.is_email_verify;
        return this.getAccountPropertyPermissions('update', 'verifyEmail') && is_email_verify === 2;
    }

    get permissionToChangePhone(){
        const permissionToUpdatePhone = this.getAccountPropertyPermissions('update', 'changePhone');

        const is_phone_verify = this.state.account?.is_phone_verify;

        if(this.isTechSupport){
            return permissionToUpdatePhone && is_phone_verify === 2
        }

        return permissionToUpdatePhone
    }

    get permissionToVerifyPhone(){
        const is_phone_verify = this.state.account?.is_phone_verify;
        return this.getAccountPropertyPermissions('update', 'verifyPhone') && is_phone_verify === 2;
    }

    _getDictionaryAccountType = () => {
        getDictionaryAccountType()
            .then((response) => {
                this.setState(() => {
                    return {
                        get_dictionary_account_type: response,
                    };
                });
            })
            .catch(() => {
            });
    };

    toggle (tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    toggleModal () {
        this.setState({
            fadeIn: !this.state.fadeIn,
        });
    }

    togglePopup () {
        this.setState({
            fadeInPop: !this.state.fadeInPop,
        });
    }

    statusFormatter (value) {
        const langCategoryAccount = {
            1: "client_category_lead_zero",
            2: "client_category_lead",
            3: "client_category_lead_one",
            4: "client_category_client_zero",
            5: "client_category_client_one",
            6: "client_category_client_two",
            7: "client_category_client_three",
        };

        const statusType = {
            '1':  'bg-violet-blue',
            '2':  'bg-shadow-blue',
            '3':  'bg-grass-green',
            '4':  'bg-deep-blue',
            '5':  'bg-pink',
            '6':  'bg-deep-yellow',
            '7':  'bg-red-orange',
            '8':  'bg-coral',
            '9':  'bg-blood-red',
            '10': 'bg-turquoise',
        };

        const curObj = statusType[value];

        const className = `table-status ${curObj}`;

        return (
            <Translate>
                {({ translate }) => {
                    return <div className={className}>{translate(`${langCategoryAccount[parseInt(value)]}`)}</div>;
                }}
            </Translate>
        );
    }

    subStatus (subStatusId, accountId) {
        return (
            <div className='sideBarTypeClient'>
                <LoadingHOC callFunc={false} callReduxProps={this.props.enums.core} scale={0.3}>
                    <SelectSubStatus account_id={accountId} subStatusId={subStatusId}/>
                </LoadingHOC>
            </div>
        );
    }

    getAccountPropertyPermissions = (action, key) => {
        const permissions = PermissionService.calc(this.accountPropertyConfig);
        return permissions[key].access(action)
    }

    deleteClientAction () {
        this.props.deleteClient(this.state.userId)
            .then(() => {
                NotificationService.success({
                    title:   'success',
                    message: `Account was delete`,
                    remove:  false
                });
            }, (error) => {
                NotificationService.error({
                    title:   'error',
                    message: `Could not delete account`,
                    remove:  false
                });
            });

        this.props.history.push("/clients");
    }

    createTask () {
        this.props.history.push(`/taskCreate`);
    }

    refCallback = (el) => {
        !isNull(el) ? this.setState({ elDom: el, elHeight: el.getBoundingClientRect() }) : null;
    };

    winResizeRefBlock () {
        !isNull(this.state.elDom) ? this.setState({ elHeight: this.state.elDom.getBoundingClientRect() }) : null;
    }

    handlerClosePopup = async (e) => {
        const targetClassList = [ ...e?.target?.classList ];
        const isOpenDropDownModal = this.state?.togglePopupItem?.bool ?? false;
        const isOpenDropDown = this.state?.dropdownOpen ?? false;

        if (!isOpenDropDownModal && isOpenDropDown && targetClassList.includes("dropDown__overlay")) {
            return await this.toggleDropdown();
        } else if (isOpenDropDownModal && targetClassList.includes("dropDown__modal_overlay")) {
            return await this.closePopupAction();
        }
    }

    showHistoryChanges = () => {
        this.setState({
            showHistoryChanges: true,
        });
    };

    closeHistoryChangesComponent = () => {
        this.setState({
            showHistoryChanges: false,
        });
    };

    userModal () {
        this.setState({
            userFadePopUp: !this.state.userFadePopUp,
        });
    }

    sendManager = async (id) => {
        // TODO: change to async logic
        this.setState({
            manager: { user_id: id },
        }, () => {
            sendAccountManager(this.state.userId, this.state.manager)
                .then(() => {
                        // const obj = {
                        //    account_id: this.state.userId,
                        //    user_id: this.state.manager.user_id,
                        // };

                        // setCategory(obj)
                        //    .then((response) => {
                        //    })
                        //    .catch((error) => {
                        //       NotificationService.errors(error.response.data);
                        //    });
                        this.props.getClient(this.state.userId);
                    },
                    (error) => {
                        NotificationService.error({
                            title:   'error',
                            message: error?.errors?.user_id ? error.errors.user_id : "Could not update",
                            remove:  false,
                        });
                    }
                );
        });
    };

    toggleDropdown = async () => {
        // if (this.state.writeType.length === 0) {
        //     return null;
        // }

        await this.save({
            dropdownOpen: !this.state.dropdownOpen,
            ...this.getInitialActionFieldsValues(),
            togglePopupItem: {
                ...this.state.togglePopupItem,
                bool: false,
            },
            confirmRegistration: false,
        });
    }

    closePopupAction = async () => {
        await this.save((prevProps) => {
            return {
                ...this.getInitialActionFieldsValues(),
                togglePopupItem: {
                    ...prevProps.togglePopupItem,
                    type: '',
                    top:  '',
                    bool: !prevProps.togglePopupItem.bool,
                },
                messageText: "",
                errors:[],
                // dropdownOpen: !prevProps.dropdownOpen,
            };
        });
    };

    handleChangeWarmType = async () => {
        try {
            await changeWarmType(this.props?.dataAfterLoading?.core?.account?.id);

            this.props.refreshClientData();
        } catch (error) {
            error?.showErrorNotification();
        }
    }

    handleResendEmail = async () => {
        try {
            await this.save({
                dropdownOpen: false,
                confirmRegistration: false
            });
            await resendRegistrationEmail({ account_id: this.props?.dataAfterLoading?.core?.account?.id });
        } catch (error) {
            await this.save({
                dropdownOpen: false,
                confirmRegistration: false
            });
            error?.showErrorNotification();
        }
    };

    dropdownItemNav = async (e) => {
        let element = e.target;
        const positionTop = `${e.clientY - (e.clientY - e.target.offsetTop)}px`;
        await this.save({
            ...this.getInitialActionFieldsValues(),
            togglePopupItem: { ...this.state.togglePopupItem, bool: false, },
        });

        while (!element.getAttribute('data-type')) {
            element = element.parentElement;
            if (!isNull(element.getAttribute('data-type'))) {
                return await this.setStatePopupItem(element.getAttribute('data-type'), positionTop);
            }
        }

        return await this.setStatePopupItem(element.getAttribute('data-type'), positionTop);
    };

    setStatePopupItem = async (type, positionTop) => {
        await this.save((state) => ({
            togglePopupItem: {
                ...state.togglePopupItem,
                bool: !state.togglePopupItem.bool,
                type,
                top:  positionTop,
            }
        }));
    };

    setNewEmail = (event) => {
        if(!onValidEmailInput(event.target.value)) return null
        this.save({
            errors: this.state.errors.filter(error => error !== event.target.name),
            action_mail: event.target.value
        })
    };

    setNewPhone = (event) => {
        if (!onValidPhoneInput(event.target.value)) return null
        this.save({
            errors: this.state.errors.filter(error => error !== event.target.name),
            action_phone: event.target.value
        })
    };

    updateEmail = async () => {
        try {
            const response = await updateEmailAccount(this.props.dataAfterLoading.core.account.id, { emails: [this.state.action_mail] });

            response && this.closePopupAction();
        } catch (error) {
            NotificationService.error({
                title:   'error',
                message: error.message,
                remove:  false,
            });
        }
    };
    setErrorByProperty = (obj) => {
        if(obj.hasOwnProperty('phone')){
            return this.save({errors: [...this.state.errors, 'change_phone']})
        }
        if(obj.hasOwnProperty('email')){
            return this.save({errors: [...this.state.errors, 'change_email']})
        }
    }

    changeAccountProperty = async (obj) => {
        try {
            const response = await updateAccountProperty(this.props.dataAfterLoading.core.account.id, obj);
            response && await this.closePopupAction();
            await this.props.refreshClientData();
        } catch (error) {
            NotificationService.error({
                title:   'error',
                message: error.message,
                remove:  false,
            });
            await this.setErrorByProperty(obj);
        }
    }

    updatePhone = async () => {
        try {
            const response = await updatePhoneAccount(this.props.dataAfterLoading.core.account.id, { phones: [this.state.action_phone] });

            response && this.closePopupAction();
        } catch (error) {
            NotificationService.error({
                title:   'error',
                message: error.message,
                remove:  false,
            });
        }
    };

    popUpItem = () => {
        const client = this.props?.dataAfterLoading?.core;
        const renderModalBtns = () => {
            return (
                <div className='modal-popup-btn'>
                    <MagicButton
                        className = {"magic-button_cancel"}
                        onClick = { () => this.closePopupAction() }
                        children = { this.props?.translate('client_message_cancel') }
                    />
                    <MagicButton
                        className = {"magic-button_send"}
                        onClick = { () => this.updateEmail() }
                        children = { this.props?.translate('kpi_send') }
                    />
                </div>)
        }

        if (this.state.togglePopupItem.type.includes('duplicate')) {

            /*TODO START temporary template with 30 id*/
            const numbers = [...Array(30).keys()];
            const listItems = numbers.map((number, key) =>
                (<li key={key}>
                    <label className='checkbox'>
                        <input type='checkbox'/>
                        <span/>
                    </label>
                    <p>{`ID ${number}`} &nbsp;&nbsp;&nbsp; {`Ambros Johnson-${number}`}</p>
                </li>)
            );

            /*TODO END*/

            return (
                <div className='popup_DropdownItem popup_DropdownItem-left' style={{ top: this.state.togglePopupItem.top }}>
                    <div className='popup_DropdownItem_modal'>
                        <div className='modal-popup-title'>
                            <i className='modal_duplicate'/>
                            { this.props?.translate('client_snap_as_duplicate') }
                            <button className='modal-popup-close-button' onClick={this.closePopupAction}/>
                        </div>

                        <div className='modal-popup-body'>
                            <div className='modal-popup-body_dublicate'>
                                <input className='input_dublicate' type='text'/>
                                <Scrollbars style={{ width: '96%', height: 340, margin: '20px 0' }}>
                                    <ul>
                                        {/*TODO temporary template with 30 id*/}
                                        {listItems}
                                    </ul>
                                </Scrollbars>
                            </div>
                        </div>

                        <div className='modal-popup-btn'>
                            <span className='modal-popup-btn-cancel' onClick={() => this.closePopupAction()}>{ this.props?.translate('account_comments_customer_button_cancel') }</span>
                            <span className='modal-popup-btn-add'>{ this.props?.translate('account_comments_customer_button_add') }</span>
                        </div>
                    </div>
                </div>
            );
        }
        if (this.state.togglePopupItem.type.includes('change_phone')) {
            return (
                <div className='popup_DropdownItem popup_DropdownItem-left' style={{ top: this.state.togglePopupItem.top }}>
                    <div className='popup_DropdownItem_modal'>
                        <div className='modal-popup-title'>
                            <i className='modal_phone'/>
                            { this.props?.translate('client_change_phone') }
                            <button className='modal-popup-close-button' onClick={this.closePopupAction}/>
                        </div>
                        <div className='modal-popup-body pseudo-plus'>
                            <input
                                autoComplete='off'
                                className={`input_phone_mail pseudo-plus__input ${setErrorClass(this.state.errors, 'change_phone')}`}
                                name={'change_phone'}
                                onChange={this.setNewPhone}
                                type='text'
                                value={this.state.action_phone}
                                placeholder={this.props?.translate('client_change_phone_ph')}
                            />
                        </div>
                        <div className='modal-popup-btn'>
                            <span className='modal-popup-btn-cancel' onClick={() => this.closePopupAction()}>{ this.props?.translate('account_comments_customer_button_cancel') }</span>
                            <span className='modal-popup-btn-add' onClick={ async () => {
                                const phoneIsValid = await phoneInputIsValid(this.save, this.state);
                                if(!phoneIsValid) return
                                this.changeAccountProperty({ phone: `+${this.state.action_phone}`})
                            }}>{ this.props?.translate('client_change_phone') }</span>
                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.togglePopupItem.type.includes('verify_phone')) {
            return (
                <div className='popup_DropdownItem popup_DropdownItem-left' style={{ top: this.state.togglePopupItem.top }}>
                    <div className='popup_DropdownItem_modal'>
                    <div className='modal-popup-title'>
                            <i className='modal_phone'/>
                            { this.props?.translate('client_verify_phone') }
                            <button className='modal-popup-close-button' onClick={this.closePopupAction}/>
                        </div>

                        <div className='modal-popup-body'>
                            <p>{this.props?.translate('client_verify_phone_content')} {this.state.account.phone}?</p>
                        </div>

                        <div className='modal-popup-btn'>
                            <span className='modal-popup-btn-cancel' onClick={() => this.closePopupAction()}>{ this.props?.translate('account_comments_customer_button_cancel') }</span>
                            <span className='modal-popup-btn-add' onClick={() => this.changeAccountProperty({ is_phone_verify: 1 })}>{ this.props?.translate('client_verify_phone') }</span>
                        </div>
                    </div>
                </div>
            );
        }


        if (this.state.togglePopupItem.type.includes('phone')) {
            return (
                <div className='popup_DropdownItem popup_DropdownItem-left' style={{ top: this.state.togglePopupItem.top }}>
                    <div className='popup_DropdownItem_modal'>
                        <div className='modal-popup-title'>
                            <i className='modal_phone'/>
                            { this.props?.translate('client_add_phone') }
                            <button className='modal-popup-close-button' onClick={this.closePopupAction}/>
                        </div>

                        <div className='modal-popup-body pseudo-plus'>
                            <input
                                autoComplete='off'
                                className='input_phone_mail pseudo-plus__input'
                                name={'phones'}
                                onChange={this.setNewPhone}
                                type='text'
                                value={this.state.action_phone}
                                placeholder={this.props?.translate('client_change_phone_ph')}
                            />
                        </div>
                        <div className='modal-popup-btn'>
                            <span className='modal-popup-btn-cancel' onClick={() => this.closePopupAction()}>{ this.props?.translate('account_comments_customer_button_cancel') }</span>
                            <span className='modal-popup-btn-add' onClick={() => this.updatePhone()}>{ this.props?.translate('account_comments_customer_button_add') }</span>
                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.togglePopupItem.type.includes('change_email')) {
            return (
                <div className='popup_DropdownItem popup_DropdownItem-left' style={{ top: this.state.togglePopupItem.top }}>
                    <div className='popup_DropdownItem_modal'>
                        <div className='modal-popup-title'>
                            <i className='modal_email'/>
                            { this.props?.translate(`clients_change_email`)}
                            <button className='modal-popup-close-button' onClick={this.closePopupAction}/>
                        </div>

                        <div className='modal-popup-body'>
                            <input
                                autoComplete='off'
                                className={`input_phone_mail ${setErrorClass(this.state.errors, 'change_email')}`}
                                name={'change_email'}
                                onChange={this.setNewEmail}
                                type='text'
                                value={this.state.action_mail}
                                placeholder={this.props?.translate('client_email_input_ph')}
                            />
                        </div>

                        <div className='modal-popup-btn'>
                            <span className='modal-popup-btn-cancel' onClick={() => this.closePopupAction()}>{ this.props?.translate('account_comments_customer_button_cancel') }</span>
                            <span className='modal-popup-btn-add' onClick={ async () => {
                                const emailIsValid = await emailInputIsValid(this.save, this.state);
                                if(!emailIsValid) return
                                this.changeAccountProperty({email:this.state.action_mail})}
                            }>{ this.props?.translate('clients_change_email') }</span>
                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.togglePopupItem.type.includes('verify_email')) {
            return (
                <div className='popup_DropdownItem popup_DropdownItem-left' style={{ top: this.state.togglePopupItem.top }}>
                    <div className='popup_DropdownItem_modal'>
                        <div className='modal-popup-title'>
                            <i className='modal_email'/>
                            { this.props?.translate(`client_verify_email`)}
                            <button className='modal-popup-close-button' onClick={this.closePopupAction}/>
                        </div>

                        <div className='modal-popup-body'>
                            <p>{this.props?.translate('client_verify_email_content')} {this.state.account.email}?</p>
                        </div>

                        <div className='modal-popup-btn'>
                            <span className='modal-popup-btn-cancel' onClick={() => this.closePopupAction()}>{ this.props?.translate('account_comments_customer_button_cancel') }</span>
                            <span className='modal-popup-btn-add' onClick={() =>  this.changeAccountProperty({ is_email_verify: 1 })}>{ this.props?.translate('client_verify_email') }</span>
                        </div>
                    </div>
                </div>
            );
        }

        if (this.state.togglePopupItem.type.includes('email')) {
            return (
                <div className='popup_DropdownItem popup_DropdownItem-left' style={{ top: this.state.togglePopupItem.top }}>
                    <div className='popup_DropdownItem_modal'>
                        <div className='modal-popup-title'>
                            <i className='modal_email'/>
                            { this.props?.translate('client_add_email') }
                            <button className='modal-popup-close-button' onClick={this.closePopupAction}/>
                        </div>

                        <div className='modal-popup-body'>
                            <input
                                autoComplete='off'
                                className='input_phone_mail'
                                name={'emails'}
                                onChange={this.setNewEmail}
                                type='text'
                                value={this.state.action_mail}
                                placeholder={this.props?.translate('client_email_input_ph')}
                            />
                        </div>

                        <div className='modal-popup-btn'>
                            <span className='modal-popup-btn-cancel' onClick={() => this.closePopupAction()}>{ this.props?.translate('account_comments_customer_button_cancel') }</span>
                            <span className='modal-popup-btn-add' onClick={() => this.updateEmail()}>{ this.props?.translate('account_comments_customer_button_add') }</span>
                        </div>
                    </div>
                </div>
            );
        }

        if (this.state?.togglePopupItem?.type?.includes?.('message')) {
            return (
                <div className="dropDown__modal dropDown__modal_zIndex" style={{ top: `calc(${this.state.togglePopupItem.top} + 30px)` }}>
                    <button className='modal-popup-close-button' onClick={this.closePopupAction}/>
                    <MessagePopupNew
                        className = { 'dropDown__modal_message' }
                        accountId = { String(this.state.userId) }
                        languageId = { client?.account?.language_id }
                        title = { this.props.translate('client_message_send') }
                        translate = { this.props.translate }
                        onCancel = { () => this.closePopupAction() }
                    />
                </div>
            )
        }
    };

    onChangeSubject = (event) => {
        const value = event.target.value;

        this.setState({
            subject:      value,
            errorSubject: false,
        });
    };

    setStateModalCreateTask = (id, quickTask) => {
        this.setState({
            showCreate: id,
            quickTask,
            togglePopupItem: {
                type: '',
                top:  '',
                bool: false,
            },
            confirmRegistration: false,
        });
    };

    renderInput () {
        switch (this.state.selectedType.value) {
            case '1': {
                return (<div className='write-action-block'>
                    <span>{this.props.translate(`dashboard_subject`)}</span>
                    <div className='input'>
                        <input
                            className={`input-subject ${this.state.errorSubject ? 'error' : ''}`}
                            name='input-subject'
                            placeholder={'Type...'}
                            type='text'
                            onChange={this.onChangeSubject}
                            value={this.state.subject}
                            disabled={this.state.subjectD}
                        />
                    </div>
                </div>);
            }
            case '2': {
                return (<div className='write-action-block'>
                    <span>{this.props.translate(`dashboard_subject`)}</span>
                    <div className='input'>
                        <input
                            className={`input-subject ${this.state.errorSubject ? 'error' : ''}`}
                            name='input-subject'
                            placeholder={'Type...'}
                            type='text'
                            onChange={this.onChangeSubject}
                            value={this.state.subject ? this.state.subject : ''}
                        />
                    </div>
                </div>);
            }
            case '3': {
                return false;
            }
            default: {
                return false;
            }
        }
    }

    onArchiveBtnClick = (accountId) => async () => {
        const { translate, } = this.props;
        const isAccountStatusActive = this._isAccountStatusActive();
        const isAccountStatusArchived = this._isAccountStatusArchived();

        let setFunc = null;

        switch (true) {
            case isAccountStatusActive: {
                setFunc = setAccountStatusArchive;
                break;
            }
            case isAccountStatusArchived: {
                setFunc = setAccountStatusActive;
                break;
            }
            default: {
                throw new Error(`Can*t do the request in the current account_status!`);
            }
        }

        await this.save({ loadedInnerRequest: false, });

        try {
            const response = await setFunc(accountId,);

            if (response?.error) {
                throw new Error(response?.error);
            }

            const requestStatusMsg = isAccountStatusArchived
                ? translate('account_success_rearchived')
                : translate('account_success_archived');
            NotificationService.success({
                title:   'success',
                message: requestStatusMsg,
                remove:  false,
            });

            await this.props?.refreshClientData();
        } catch (error) {
            error?.showErrorNotification?.();
        }

        await this.save({ loadedInnerRequest: true, });
    };

    helperDescription = (id, type) => {
        let locale = DataStorage.getData('locale');

        if (type === 'category') {
            return (
                <>
                    {descriptionHelperCategory[id][`name_${locale}`]}
                </>
            );
        }
        if (type === 'warmType') {
            return (
                <>
                    {descriptionHelperWarmType[id][`name_${locale}`]}
                </>
            );
        }

    };

    getAccountStatus = () => {
        return parseInt(this.props?.dataAfterLoading?.core?.account?.status);
    };

    _isAccountStatusActive = () => this.getAccountStatus()?.toString() === '1'; // AccountStatusEnum

    _isAccountStatusDeleted = () => this.getAccountStatus()?.toString() === '2'; // AccountStatusEnum

    _isAccountStatusArchived = () => this.getAccountStatus()?.toString() === '3'; // AccountStatusEnum

    onDisabledContentClick = (event) => {
        event.stopPropagation();
    };

    renderDisabledContent = (condition) => {
        return (
            condition
              ? ( <div className='disabled-content' onClick={this.onDisabledContentClick} /> )
              : ''
        );
    };

    renderTabs = (translate) => {
        const client = this.props.dataAfterLoading.core;
        const accountStatus = this.getAccountStatus();
        const permissionTemplateId = this.state.profile?.data?.permission_template_id;
        const readyToShow = [5, 15].includes(permissionTemplateId); // check permission for support and back-office

        return (
            <div className='lead-page-tabs'>
                <div className='lead-page-tabs-inner'>
                    <Nav tabs>
                        <NavItem>
                            <Link
                                // className={`${classnames({ active: this.state.activeTab === TABID_0 })} customer`}
                                className={`nav-link ${classnames({
                                    active:   this.props.activeTab === 'clientdata',
                                    disabled: this.state.navItemIsDisabled
                                })} customer`}
                                to={`/clients/${+this.state.userId}/clientdata`}
                                // onClick={() => {
                                //     this.toggle(TABID_0);
                                // }}
                            >
                                {translate(`client_customer_data`)}
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link
                                // className={`${classnames({ active: this.state.activeTab === TABID_1 })} deposits`}
                                // onClick={() => {
                                //     this.toggle(TABID_1);
                                // }}
                                className={`nav-link ${classnames({
                                    active:   this.props.activeTab === 'accounts',
                                    disabled: this.state.navItemIsDisabled
                                })} deposits`}
                                to={`/clients/${+this.state.userId}/accounts`}
                            >
                                {translate(`client_accounts`)}
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link
                                className={`nav-link ${classnames({
                                    active:   this.props.activeTab === 'tima',
                                    disabled: this.state.navItemIsDisabled
                                })} payments`}
                                to={`/clients/${+this.state.userId}/tima`}
                            >
                                {translate('client_tima')}
                            </Link>
                        </NavItem>
                        {/*Timless hidden this item in current version crm*/}
                        {/*<NavItem>
                            <Link
                                // className={`${classnames({ active: this.state.activeTab === TABID_2 })} interaction`}
                                // onClick={() => {
                                //     this.toggle(TABID_2);
                                // }}
                                className={`nav-link ${classnames({
                                    active:   this.props.activeTab === 'interaction',
                                    disabled: this.state.navItemIsDisabled
                                })} interaction`}
                                to={`/clients/${+this.state.userId}/interaction`}
                            >
                                {translate(`client_interaction`)}
                            </Link>
                        </NavItem>*/}
                        <NavItem>
                            <Link
                                // className={`${classnames({ active: this.state.activeTab === TABID_3 })} payments`}
                                // onClick={() => {
                                //     this.toggle(TABID_3);
                                // }}
                                className={` nav-link ${classnames({
                                    active:   this.props.activeTab === 'depositsandwithdrawals',
                                    disabled: this.state.navItemIsDisabled
                                })} payments`}
                                to={`/clients/${+this.state.userId}/depositsandwithdrawals`}
                            >
                                {translate(`client_payments_and_withdrawals`)}
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link
                              className={` nav-link ${classnames({
                                  active:   this.props.activeTab === 'appeals',
                                  disabled: this.state.navItemIsDisabled
                              })} payments`}
                              to={`/clients/${+this.state.userId}/tasks`}
                            >
                                {translate(`client_appeals`)}
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link
                                className={`nav-link ${classnames({
                                    active:   this.props.activeTab === 'conversations',
                                    disabled: this.state.navItemIsDisabled
                                })} payments`}
                                to={`/clients/${+this.state.userId}/conversations`}
                            >
                                {translate('menu_conversations')}
                            </Link>
                        </NavItem>
                        <NavItem>
                            <Link
                                className={`nav-link ${classnames({
                                    active:   this.props.activeTab === 'support',
                                    disabled: this.state.navItemIsDisabled
                                })} payments`}
                                to={`/clients/${+this.state.userId}/support`}
                            >
                                {translate('client_support')}
                            </Link>
                        </NavItem>
                        {
                            readyToShow && <NavItem>
                                <Link
                                    className={`nav-link ${classnames({
                                        active:   this.props.activeTab === 'letters',
                                        disabled: this.state.navItemIsDisabled
                                    })} letters`}
                                    to={`/clients/${+this.state.userId}/letters`}
                                >
                                    {translate(`client_letters`)}
                                </Link>
                            </NavItem>
                        }
                    </Nav>
                    <TabContent activeTab={this.state.activeTab} style={{ position: 'relative', }}>
                        {this.renderDisabledContent(this._isAccountStatusArchived())}

                        {this.state.activeTab === 'clientdata' ?
                            <TabPane tabId={'clientdata'}>
                                <LeadCustomerData
                                    client={client}
                                    clientId={this.state.userId}
                                    enums={this.props.enums?.core}
                                    getClient={this.props.getClient}
                                    refreshClientData={this.props?.refreshClientData}
                                />
                            </TabPane>
                            : ''}
                        {this.state.activeTab === 'accounts' ?
                            <TabPane className='tab-autoheight' tabId={'accounts'}>
                                <Row>
                                    <Col sm='12'>
                                        <LoadingHOC callFunc={false} callReduxProps={this.props.enums} scale={0.8}>
                                            <LeadDeposits clientId={this.state.userId} translate={translate}/>
                                        </LoadingHOC>
                                    </Col>
                                </Row>
                            </TabPane>
                            : ''}
                        {this.state.activeTab === 'interaction' ?
                            <TabPane tabId={'interaction'}>
                                <Row>
                                    <Col sm='12'>
                                        <Interaction clientId={this.state.userId}/>
                                    </Col>
                                </Row>
                            </TabPane>
                            : ''}
                        {this.state.activeTab === 'depositsandwithdrawals' ?
                            <TabPane tabId={'depositsandwithdrawals'}>
                                <Row>
                                    <Col sm='12'>
                                        <LeadPayments
                                            departmentsListNames={ this.state?.departmentsListNames }
                                            clientId={this.state.userId}
                                            enums={this.props?.enums}
                                        />
                                    </Col>
                                </Row>
                            </TabPane>
                            : ''}
                        {this.state.activeTab === 'appeals' ?
                            <TabPane tabId={'appeals'}>
                                <Row>
                                    <LeadAppealsView
                                        clientId={this.state.userId}
                                        enums={this.props?.enums}
                                    />
                                </Row>
                            </TabPane>
                            : ''}
                         {this.state.activeTab === 'letters' ?
                            <TabPane tabId={'letters'}>
                                <Row>
                                    <Col sm='12'>
                                        <LeadLetters
                                            accountId={client?.account?.id}
                                            enums={this.props?.enums}
                                            />
                                    </Col>
                                </Row>
                            </TabPane>
                            : ''}
                        {this.state.activeTab === 'conversations' ?
                            <TabPane tabId={'conversations'}>
                                <Row>
                                    <Col sm='12'>
                                        <Conversations
                                            accountId={client?.account?.id}
                                            enums={this.props?.enums}
                                        />
                                    </Col>
                                </Row>
                            </TabPane>
                            : ''}
                        {this.state.activeTab === 'support' ?
                            <TabPane tabId={'support'}>
                                <Row>
                                    <Col sm='12'>
                                        <LeadSupport
                                            accountId={client?.account?.id}
                                            enums={this.props?.enums}
                                        />
                                    </Col>
                                </Row>
                            </TabPane>
                            : ''}
                        {this.state.activeTab === 'tima' ?
                            <TabPane tabId={'tima'}>
                                <Row>
                                    <Col sm='12'>
                                        <LeadTima
                                            accountId={client?.account?.id}
                                            enums={this.props?.enums}
                                        />
                                    </Col>
                                </Row>
                            </TabPane>
                            : ''}
                    </TabContent>
                </div>
            </div>
        );
    };

    magicPopUpOpen = (options) => {
        this.setState((state) => ({ magicPopUp: { ...state.magicPopUp, ...options, isActive: true } }));
    };

    magicPopUpClose = () => {
        this.setState((state) => ({ magicPopUp: { ...state.magicPopUp, isActive: false } }));
    };

    renderMagicPopUp = () => {
        const { magicPopUp = {} } = this.state;
        const { isActive, mode, message, callBackFunc } = magicPopUp;
        const magicOptions = {
            message,
            mode,
            closeFunc: this.magicPopUpClose,
            callBackFunc,
        };

        return isActive && (
            <MagicPopUp {...magicOptions} />
        );
    };

    renderArchiveBtn = (accountId) => {
        const { translate, } = this.props;
        const calcData = PermissionService.calc(this.leadConfig);

        const isAccountStatusArchived = this._isAccountStatusArchived();
        const isAccountStatusDeleted = this._isAccountStatusDeleted();
        const permissionActive = calcData?.coreAccount?.access('active');
        const permissionArchive = calcData?.coreAccount?.access('archive');

        const btnNameText = !isAccountStatusArchived ? translate(`client_archive`) : translate(`client_unzip`);
        const renderFlag = !isAccountStatusDeleted && (isAccountStatusArchived ? permissionActive : permissionArchive);

        return renderFlag ? (
            <>
                { this.state?.dropdownOpen && <div className="dropDown__overlay" /> }
                <div className={`dropDown__wrapper ${ this.state?.dropdownOpen ? 'open' : 'close'}`}>
                    <div className='dropDown__item' onClick={this.onArchiveBtnClick(accountId)}>
                        <i className='action_tasks' />
                        { btnNameText }
                    </div>
                </div>
            </>
        ) : null;
    };

    renderConfirmBlock = () => {
        return (
            <MagicConfirm
                className='magic-confirm magic-confirm--right account-confirm'
                onAccept={this.handleResendEmail}
                onReject={async () => await this.save({ confirmRegistration: false })}
                title={this.props.translate(`magic_confirm_title_account`)}
                accept={this.props.translate(`magic_confirm_yes`)}
                reject={this.props.translate(`magic_confirm_no`)}
                isVisible={true}
            />
        );
    };

    showConfirmBlock = async () => await this.save({ confirmRegistration: true });

    checkAccountInSpamList = async () => {
        try {
            const emailsAsSpam = await clientService.checkAccInSpamList({ account_id: +this.state?.userId });
            await this.save({ emailsAsSpam });
        } catch (e) {
            console.error(e);
            await this.save({ emailsAsSpam: false });
        }
    };

    deleteAccountFromSpam = async () => {
        try {
            await clientService.delFromSpam({ account_id: +this.state?.userId });
            await this.save({ emailsAsSpam: false });
        } catch (error) {
            NotificationService.error({
                title:   'error',
                message: this.props.translate('spam_error'),
                remove:  false,
            });
            await this.save({ emailsAsSpam: false });
        }
    };

    showDeleteFromSpamBlock = () => {
        const emailsAsSpam = this.state.emailsAsSpam;

        if ([5, 15].includes(this.state.profile?.data?.permission_template_id) && emailsAsSpam.mail_validate && emailsAsSpam.is_suppression_list) {
            return (
                <div className="spam-block">
                    <span>{this.props.translate('spam_message')}</span>
                    <span
                        className="spam-block_delete"
                        onClick={ async () => await this.deleteAccountFromSpam() }
                    >{this.props.translate('spam_del_btn')}</span>
                </div>
            )
        } else if ([5, 15].includes(this.state.profile?.data?.permission_template_id) && !emailsAsSpam.mail_validate && !emailsAsSpam.is_suppression_list) {
            return (
                <div className="spam-block">
                <span>{this.props.translate('spam_message')}</span>
                    <span className="spam-block_cannot-delete">{this.props.translate('cannot_spam_del_btn')}</span>
                </div>
            )
        }
    };

    render () {
        const { loadedInnerRequest, } = this.state;
        const client = this.props?.client?.data?.core ?? this.props.dataAfterLoading.core;
        const accountId = client?.account?.id;
        const accountStatus = this.getAccountStatus();

        const permissionToChangeEmail = this.permissionToChangeEmail;
        const permissionToChangePhone = this.permissionToChangePhone;
        const permissionToVerifyEmail = this.permissionToVerifyEmail;
        const permissionToVerifyPhone = this.permissionToVerifyPhone;
        const isBackOffice = this.isBackOffice;

        const renderDropDown = () => {
            return (
                <>
                    { this.state.togglePopupItem.bool
                        ? <>{this.popUpItem()}</>
                        : null
                    }

                    { this.state?.dropdownOpen && <div className="dropDown__overlay" /> }
                    <div className={`dropDown__wrapper ${ this.state?.dropdownOpen ? 'open' : 'close'}`}>
                        <div className='dropDown__item' onClick={() => { this.setStateModalCreateTask(1, false); }} >
                            <i className='action_tasks'/>
                            { this.props?.translate(`client_create_task`) }
                        </div>
                        <div className='dropDown__item' onClick={(e) => this.dropdownItemNav(e)} data-type='duplicate' >
                            <i className='action_dublicate'/>
                            { this.props?.translate(`client_snap_as_duplicate`) }
                        </div>
                        <div className='dropDown__item' onClick={(e) => this.dropdownItemNav(e)} data-type='message' >
                            <i className='action_message'/>
                            { this.props?.translate(`client_send_message`) }
                        </div>
                        {
                            this.props?.dataAfterLoading?.core?.account?.warm_type === 2 &&
                            this.props?.dataAfterLoading?.core?.user?.id === 24
                                ? ( <div className="dropDown__item" onClick = { () => this.handleChangeWarmType()} >
                                        <i className="action_change" />
                                        {this.props?.translate('client_change_warmth')}
                                    </div> )
                                : null
                        }
                        {
                            PermissionService.actionForRoute(pm.CORE_ACCOUNT, 'resendActivationEmail') &&
                            this.props?.dataAfterLoading?.core?.account?.status === 1 &&
                            this.props?.dataAfterLoading?.core?.account?.is_email_verify === 2
                                ? ( <div className='dropDown__item' onClick={() => this.showConfirmBlock()} >
                                        <i className="action_add-resend-reg-email" />
                                        {this.props?.translate('client_add_resend-reg-email')}
                                    </div> )
                                : null
                        }
                        <div className='dropDown__item' onClick={(e) => this.dropdownItemNav(e)} data-type='phone' >
                           <i className='action_add-phone'/>
                           { this.props?.translate(`client_add_phone`) }
                        </div>
                        {permissionToChangePhone && <div className='dropDown__item' onClick={(e) => this.dropdownItemNav(e)} data-type='change_phone' >
                            <i className='action_add-phone'/>
                            { this.props?.translate(`client_change_phone`) }
                        </div>}
                        {permissionToVerifyPhone &&  isBackOffice && <div className='dropDown__item' onClick={(e) => this.dropdownItemNav(e)} data-type='verify_phone' >
                            <i className='action_add-phone'/>
                            { this.props?.translate(`client_verify_phone`) }
                        </div>}
                        <div className='dropDown__item' onClick={(e) => this.dropdownItemNav(e)} data-type='email' >
                           <i className='action_add-email'/>
                           { this.props?.translate(`client_add_email`) }
                        </div>
                        {permissionToChangeEmail && <div className='dropDown__item' onClick={(e) => this.dropdownItemNav(e)} data-type='change_email' >
                           <i className='action_add-email'/>
                           { this.props?.translate(`clients_change_email`) }
                        </div>}
                        {permissionToVerifyEmail && isBackOffice && <div className='dropDown__item' onClick={(e) => this.dropdownItemNav(e)} data-type='verify_email' >
                           <i className='action_add-email'/>
                           { this.props?.translate(`client_verify_email`) }
                        </div>}
                        <div className='dropDown__item' onClick={this.onArchiveBtnClick(client?.account?.id)}>
                           <i className='action_tasks'/>
                           {!this._isAccountStatusArchived()
                                ? this.props?.translate(`client_archive`)
                                : this.props?.translate(`client_unzip`)
                            }
                        </div>
                        { this.state.confirmRegistration && this.renderConfirmBlock(accountId) }
                    </div>
                </>
            )
        };

        return (
            <CheckUndefinedPermission error={this.props.error}>
                {/* <Translate>
                    {({ translate }) => {
                        return ( */}
                            <ErrorBoundry>
                                <UserModalPopUp
                                    client={client}
                                    sendManager={this.sendManager}
                                    userFadePopUp={this.state.userFadePopUp}
                                    userModal={this.userModal}
                                    usersAllAvatar={this.state.usersAllAvatar.data}
                                />
                                {!isNull(this.state.showCreate) ?
                                    <NewTaskCreateForm
                                        close={this.setStateModalCreateTask}
                                        quick={this.state.quickTask}
                                        userId={this.state.userId}
                                        responsible={client.user}
                                    />
                                    : ""
                                }

                                <div className='lead-page'>
                                    { !loadedInnerRequest
                                          ? ( <Preloader className='loaderUniversal--fixed-pos' scale={1} /> )
                                          : null
                                    }
                                    <div className='content-block'>
                                        <div className='top-page-block'>
                                            <div className='breadcrumbs'>
                                                <Link to='/clients'>{this.props?.translate(`client_clients`)} </Link>
                                                <span> {">"} </span>
                                                <Link to={`/clients/${+this.state.userId}`}>
                                                    {PermissionService.show(pm.CORE_ACCOUNT).can('name') ? client?.account?.name : ''}&nbsp;
                                                    {PermissionService.show(pm.CORE_ACCOUNT).can('surname') ? client?.account?.surname || '' : ''}
                                                </Link>
                                                {(this.props.activeTab && this.props.activeTab.length) && (
                                                    <React.Fragment>
                                                        <span> {">"} </span>
                                                        <Link
                                                            to={`/clients/${+this.state.userId}/${this.props.activeTab}`}>
                                                            {this.props?.translate(`client_${LeadComponent.translateTabNav[this.props?.activeTab]}`)}
                                                        </Link>
                                                    </React.Fragment>
                                                )}
                                            </div>
                                            <div className='top-button-area'>
                                                {
                                                    this.state.emailsAsSpam ? this.showDeleteFromSpamBlock() : null
                                                }
                                                <div className='lead-page-dropdown'>
                                                    <div onClick={ this.toggleDropdown } className='button-icon dropDown__toggle-icon'>
                                                        <i className='la la-plus-circle' />
                                                        {this.props?.translate(`client_action`)}
                                                    </div>

                                                    <div>
                                                        { (this.state.togglePopupItem.bool && this.state.dropdownOpen) && <div className="dropDown__modal_overlay" /> }
                                                        {/* {
                                                            this.props?.dataAfterLoading?.core?.account?.warm_type === 2 &&
                                                            this.props?.dataAfterLoading?.core?.user?.id === 24
                                                              ? (<DropdownItem header>
                                                                    <div className="wrap_DropdownItem" onClick = { () => this.handleChangeWarmType()} >
                                                                        <span>
                                                                            <i className="action_change" />
                                                                            {this.props?.translate('client_change_warmth')}
                                                                        </span>
                                                                    </div>
                                                                </DropdownItem> )
                                                              : null
                                                        } */}
                                                        { !this._isAccountStatusArchived()
                                                            ? renderDropDown()
                                                            : <>{ this.renderArchiveBtn(accountId) }</>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='button-icon' onClick={this.showHistoryChanges}>
                                                    <i className='la la-clock-o'/>
                                                    { this.props?.translate(`client_history_of_changes`) }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='sidebar-wrapper sideBar-leads' style={{ flexDirection: 'column', }}>
                                        <div className={`lead-block-wrapper ${this.state.fadeIn ? 'overflow ' : ' '}${this.state.fadeInPop ? 'overflow' : ''}`}>
                                            <div
                                                className={`lead-info-wrapper ${classnames({ 'lineRow': this.state.elHeight.height > 200 })} lead-component__page`}
                                                ref={this.refCallback}
                                                style={{ position: 'relative' }}
                                            >
                                                { this.renderDisabledContent(this._isAccountStatusArchived()) }

                                                {this.state.showHistoryChanges
                                                    ? <HistoryChangesComponent
                                                        closeFunction={this.closeHistoryChangesComponent}
                                                        url={`${GET_ACCOUNT_HISTORY}/${this.state.userId}`}
                                                    />
                                                    : null}
                                                <Fade className='fade-block' in={this.state.fadeInPop}>
                                                    <LeadPopUpAction
                                                        translate={this.props.translate}
                                                        delete={this.deleteClientAction}
                                                        toogle={this.togglePopup}
                                                    />
                                                </Fade>
                                                {this.renderAvatar(this.props?.translate)}
                                                {this.renderTitle(this.props?.translate)}
                                                {this.renderInfoMain(this.props?.translate)}
                                                {this.renderInfoPhone(this.props?.translate, PermissionService.show(pm.CORE_ACCOUNT).can('phone'), client)}
                                                {this.renderInfoEmail(this.props?.translate, PermissionService.show(pm.CORE_ACCOUNT).can('email'), client)}
                                                {this.renderInfoCategory(this.props?.translate, PermissionService.store(pm.CORE_ACCOUNT_CATEGORIES).can('category_id'))}
                                                {this.renderInfoWarm(this.props?.translate, PermissionService.show(pm.CORE_ACCOUNT).can('warm_type'))}
                                                {this.renderInfoAssigned(this.props?.translate)}
                                                {/*{this.renderInfoType(translate, PermissionService.show(pm.CORE_ACCOUNT_TYPES).can('id'))}*/}
                                                {this.renderInfoStatus(this.props?.translate, PermissionService.show(pm.CORE_ACCOUNT).can('is_verify_document'))}
                                                {this.renderNewInBriefcase(this.props?.translate)}
                                                {this.renderProcessedInfo(this.props?.translate)}
                                                {this.renderClientLanguage(this.props?.translate)}
                                                <div className="lead-component__item_last" />
                                            </div>
                                            {this.renderTabs(this.props?.translate)}
                                        </div>
                                    </div>
                                    {this.renderMagicPopUp()}
                                </div>
                            </ErrorBoundry>
                        {/* );}}</Translate> */}
            </CheckUndefinedPermission>
        );
    }

    renderAvatar = (translate) => {
        // const client = this.props?.dataAfterLoading?.core;
        return (
            <div className="lead-component__item lead-component__avatar">
                <span className='lead-img'><img alt='user' src={userAvatar}/></span>
            </div>
        );
    };

    renderTitle = (translate) => {
        const client = this.props?.dataAfterLoading?.core;
        if ([2].includes(+client?.account?.register_type)) {
            const corporate = PermissionService.show(pm.CORE_ACCOUNT).can('name') && client?.account?.name;
            const account_corporate = client?.account_corporate?.filter(a => +a?.is_admin === 1).shift();
            const name = PermissionService.show(pm.CORE_ACCOUNT_CORPORATE).can('name') && account_corporate?.name;
            const surname = PermissionService.show(pm.CORE_ACCOUNT_CORPORATE).can('surname') && account_corporate?.surname;
            const position = PermissionService.show(pm.CORE_ACCOUNT_CORPORATE).can('position') && account_corporate?.position;
            const fullName = [name, surname,].filter(x => x).join(' ');
            const license_alias = client?.dictionary_licenses?.alias;
            const license_translations = this.props.enums?.core?.CustomLicenseAliasEnum[license_alias] ?? '';
            const imageName = license_alias === 'MAURITIUS' ? mavrikiy : vanuaty;

            return (
                <div className="lead-component__item lead-component__title">
                    <span className='lead-info-name tooltip-wrapper'>
                        {corporate || '?'}
                    </span>
                    <span className='lead-info-name tooltip-wrapper' style={{ fontSize: 'smaller', marginLeft: '12px', }}>
                        {fullName || '?'}
                    </span>
                    {/*<span className='lead-info-name tooltip-wrapper' style={{ fontSize: 'smaller', marginLeft: '12px', }}>
                        {position || '?'}
                    </span>*/}
                    <span className='lead-info-name tooltip-wrapper'
                          style={{ fontSize: 'smaller', marginLeft: '12px', }}>
                        {` (${translate('client_corporate_position')})` || '?'}
                    </span>
                    <span className='lead-info-title' style={{ marginLeft: '12px', }}>
                        <span className='lead-info-title' style={{ marginLeft: '12px', }}>
                        <img alt={ imageName }
                             className='form-background'
                             src={ imageName }
                             style={{ marginRight: '10px', height: '13px' }}/>
                            {license_translations}
                    </span>
                    </span>
                </div>
            );
        } else {
            const countCharName = 60;
            const name = PermissionService.show(pm.CORE_ACCOUNT).can('name') && client?.account?.name;
            const surname = PermissionService.show(pm.CORE_ACCOUNT).can('surname') && client?.account?.surname;
            const fullName = [name, surname,].filter(x => x).join(' ');
            const shortName = fullName.length > countCharName ? `${fullName.substring(0, countCharName)}...` : fullName;
            const tooltip = shortName !== fullName && (<div className='tooltip'>{fullName}</div>);
            const license_alias = client?.dictionary_licenses?.alias;
            const license_translations = this.props.enums?.core?.CustomLicenseAliasEnum[license_alias] ?? '';
            const imageName = license_alias === 'MAURITIUS' ? mavrikiy : vanuaty;

            return (
                <div className="lead-component__item lead-component__title">
                    <div className='lead-info-name tooltip-wrapper'>
                        {shortName || '?'}
                        {tooltip}
                    </div>
                    <span className='lead-info-title' style={{ marginLeft: '12px', }}>
                        <img alt={ imageName }
                             className='form-background'
                             src={ imageName }
                             style={{ marginRight: '10px', height: '13px' }}/>
                        {license_translations}
                    </span>
                </div>
            );
        }
    };

    renderInfoMain = (translate) => {
        return (
            <div className='lead-general-info lead-component__item lead-component__info' data-info="main">
                <div className='lead-personal-info'>
                    <table className='lead-info-table'>
                        <tbody>
                        {this.renderInfoCountry(translate, PermissionService.show(pm.CORE_ACCOUNT).can('country_id'))}
                        {this.renderInfoBirth(translate, PermissionService.show(pm.CORE_ACCOUNT).can('birth_date'))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    };

    renderInfoCountry = (translate, show = false) => {
        if (!show) {
            return '';
        }
        const client = this.props?.dataAfterLoading?.core;
        const accountRegisterType = client?.account?.register_type?.toString();
        const accountAddresses = client?.account_addresses;

        let targetAddressObj = {};

        // AccountRegisterTypeEnum: 1 - Individual, 2 - Business, 3 - Test
        switch (accountRegisterType) {
            case '1':
            case '3': {
                // INDIVIDUAL_FACTUAL_ADDRESS = 1
                targetAddressObj = accountAddresses?.filter((item) => item?.type?.toString() === '1')?.[0] ?? {};
                break;
            }
            case '2': {
                // CORPORATE_REGISTRATION_ADDRESS = 7
                targetAddressObj = accountAddresses?.filter((item) => item?.type?.toString() === '7')?.[0] ?? {};
                break;
            }
            default: {
                break;
            }
        }

        const country_id = targetAddressObj?.country_id;
        const selectedCountry = this._getSelectedCountryById(country_id);
        const name = selectedCountry?.name ?? '';
        const alpha_2 = selectedCountry?.alpha_2?.toLowerCase() ?? '';
        const COUNTRY_LENGTH_SHORT = 15;

        return (
            <tr>
                <td className='lead-info-title'
                    style={{ whiteSpace: 'nowrap' }}>{startCase(toLower(translate(`client_country`)))}</td>

                <td className='lead-info-text lead-info-text--country'>
                    <MagicTooltip
                        classNameTooltip={`tooltip`}
                        content={name}
                        lengthCut={COUNTRY_LENGTH_SHORT}
                    />
                </td>
                <td><span className={`flag-icon flag-icon-squared flag-icon-${alpha_2}`}/></td>
            </tr>
        );
    };

    renderInfoBirth = (translate, show = false) => {
        if (!show) {
            return '';
        }
        const client = this.props.dataAfterLoading?.core;
        const format = 'YYYY-MM-DD';
        const dob = moment(client?.account?.birth_date, format); // date-of-birth
        const now = moment();
        const birth = dob.isValid() && dob.format('DD/MM/YYYY');
        const age = dob.isValid() && now.diff(dob, 'years');
        const pie = dob.isValid() && Math.abs(dob.add(age, 'years').diff(now, 'd')) <= 3;
        return (
            <tr>
                <td className='lead-info-title' style={{ whiteSpace: 'nowrap' }}>{translate(`client_birth_date`)}</td>
                <td className='lead-info-text' style={{ whiteSpace: 'nowrap' }}>{birth} {age > 0 ? `(${age})` : ''}</td>
                {pie && (<td><i className='la la-birthday-cake'/></td>)}
            </tr>
        );
    };

    renderInfoPhone = (translate, show = false, client = undefined) => {
        if (!show) {
            return '';
        }
        const phone = this.state.account?.phone;
        const isVerified = this.state?.account?.is_phone_verify ?? '2';

        const notDecryptPhone = this.getAccountPropertyPermissions('decryptPhone', 'decryptData');

        return (
            <div className='lead-info lead-component__item lead-component__info' data-info="phone">
                <div className='lead-info-title'>{translate(`client_phone`)}</div>
                <div className='lead-info-text'>
                    <div className='lead-info-text' id='show-phone'>
                  <span onClick={ async () => callService.sendCall({ account_id: this.props.match.params.id }) }>
                     <img
                         alt='call'
                         src={call}
                         data-is-verified={isVerified}
                     /> {translate(`tasks_createform_types_call`)}
                  </span>
                    </div>
                </div>
                {notDecryptPhone && <p className='lead-info-contacts'>{phone}</p>}
            </div>
        );
    };

    renderInfoEmail = (translate, show = false, client = undefined) => {
        if (!show) {
            return '';
        }
        const isVerified = this.state?.account?.is_email_verify ?? '2';

        const email = this.state.account?.email ?? '';
        const cutEmail = `${email.slice(0, 2)}...${email.includes('@') ? '@' + email.split('@')[1] : ''}` //email can be passed without @, so different cut-styles required
        const notDecryptEmail = this.getAccountPropertyPermissions('decryptEmail', 'decryptData');

        return (
            <div className='lead-info lead-component__item lead-component__info' data-info="email">
                <div className='lead-info-title'>{translate(`client_email`)}</div>
                <div
                    className='lead-info-text'
                    onClick={(e) => { this.dropdownItemNav(e) }}
                    data-type='clientMessage'
                >
                    <span>
                        <img alt='letter' src={letter} data-is-verified={isVerified} />
                        { translate(`tasks_client_write`) }
                    </span>
                </div>

                {
                    notDecryptEmail && <div className={`tooltip-wrapper lead-info-contacts`}> {/* custom tooltip */}
                        <div>
                            {cutEmail}
                        </div>
                        <div className={`tooltip lead-info-contacts`}>
                            {email}
                        </div>
                    </div>
                }

                <div style={{ top: this.state?.togglePopupItem?.top }}>
                    { this.state?.togglePopupItem?.type === "clientMessage" &&
                         <MessagePopupNew
                            className  = { 'dropDown__modal_message' }
                            accountId  = { String(this.state.userId) }
                            languageId = { client?.account?.language_id }
                            title      = { this.props.translate('client_message_send') }
                            translate  = { this.props.translate }
                            onCancel   = { () => this.closePopupAction() }
                        />
                    }
                </div>
            </div>
        );
    };

    renderInfoCategory = (translate, show = false) => {
        const categoryId = this.props.dataAfterLoading?.core?.account_categories?.category_id;
        const categoryName = this.statusFormatter(categoryId);
        if (!show) {
            return (
                <div className='lead-info lead-component__item lead-component__info' data-info="category">
                    <div className='lead-info-title'>{translate(`client_category`)}</div>
                    <div className='lead-info-text'>{categoryName}</div>
                </div>
            );
        }
        return (
            <div className='lead-info lead-component__item lead-component__info' data-info="category">
                <div className='lead-info-title'>{translate(`client_category`)}</div>
                <div className='lead-info-text label-not-hover hintHelper-wrapper'>
                    <span className = { `select-account-category-label label-${+categoryId}` }>{this.props.enums?.core?.AccountCategoryEnum[+categoryId]}</span>
                    <div className='hintHelper hintHelper-bottom'>{this.helperDescription(parseInt(categoryId), 'category')}</div>
                </div>
            </div>
        );
    };

    renderInfoWarm = (translate, show = false) => {
        const client = this.props?.dataAfterLoading?.core;
        const warmTypeId = client?.account?.warm_type;
        const warmTypeName = this.statusFormatter(warmTypeId);
        if (!show) {
            return (
                <div className='lead-info lead-component__item lead-component__info' data-info="warm">
                    <div className='lead-info-title'>{translate(`client_sign_of_warmth`)}</div>
                    <div className='lead-info-text'>{warmTypeName}</div>
                </div>
            );
        }
        let { account_warm_rule_translation: warmTexts = null } = client;
        // warmTexts = warmTexts instanceof Array ? warmTexts.join('\n') : '';
        // warmTexts = warmTexts instanceof Array && warmTexts.length ? warmTexts.map((item) => item.text ? item.text : '').join('\n') : '';
        warmTexts = warmTexts.text;
        return (
            <div className='lead-info lead-component__item lead-component__info' data-info="warm">
                <div className='lead-info-title'>{translate(`client_sign_of_warmth`)}</div>
                <div className='lead-info-text label-not-hover hintHelper-wrapper'>
                    <span className = { `select-account-warm_type-label label-${+warmTypeId}` }>{this.props.enums?.core?.AccountWarmTypeEnum[+warmTypeId]}</span>
                    <div className='hintHelper hintHelper-bottom'>
                        {/*{this.helperDescription(parseInt(warmTypeId), 'warmType')}*/}
                        {warmTexts}
                    </div>
                </div>
            </div>
        );
    };

    renderInfoAssigned = (translate) => {
        const client = this.props?.client?.data?.core ?? this.props?.dataAfterLoading?.core;
        const departmentIds = this.userDepartmentIds();
        const adminDepartmentIds = departmentIds.filter(id => [ 2, 4, 12, 14, ].includes(id));
        const hasAccess = !!adminDepartmentIds.length;
        const options = {
            ...hasAccess && { onClick: this.userModal, },
        };
        return (
            <div className='lead-info lead-component__item lead-component__info' data-info="assigness">
                <div className='lead-info-title'>{translate(`client_assigned_to_responsible`)}</div>
                <div className='lead-info-text' { ...options }>
                    {`${client?.user?.name ?? ""} ${client?.user?.surname ?? ""}`}
                    <img alt='person' src={person}/>
                </div>
            </div>
        );
    };

    renderNewInBriefcase = (translate) => {
        const client = this.props?.client?.data?.core ?? this.props?.dataAfterLoading?.core;
        return (
            <div className='lead-info lead-component__item lead-component__info' data-info="briefcase">
                <div className='lead-info-title'>{translate(`client_new_in_briefcase`)}</div>
                <div className='lead-info-text'>
                    {client?.account?.new_in_briefcase === 2 ? translate("client_no") : translate("client_yes")}
                </div>
            </div>
        );
    };

    renderProcessedInfo = (translate) => {
        const client = this.props?.client?.data?.core ?? this.props?.dataAfterLoading?.core;
        return (
            <div className='lead-info lead-component__item lead-component__info' data-info="assigness">
                <div className='lead-info-title'>{translate(`client_processed`)}</div>
                <div className='lead-info-text'>
                    {this.props.enums?.core?.AccountIsProcessedEnum[client?.account?.is_processed]}
                </div>
            </div>
        );
    };
    renderClientLanguage = (translate) => {
        const { userLanguageForConversations } = this.state;
        const firsElement = userLanguageForConversations?.length - 1;

        return userLanguageForConversations?.slice(firsElement)?.map((clientInfo, index) => {
            const { language, } = clientInfo?.novatalks?.contacts;
            const langTranslate = language === 'ru' ? 'Русский' : language === 'uk' ? 'Українська' : language === 'en' ? 'English' : '';

            return (
                        <div key={index} className='lead-info lead-component__item lead-component__info' data-info="assigness">
                           <div className='lead-info-title'>{translate(`conversation_client_language`)}</div>
                            <div className='lead-info-text'>
                                <span className={'select-account-category-label label-3'}>{langTranslate}</span>
                            </div>
                        </div>
            )

        })
    };

    renderInfoStatus = (translate, show = false) => {
        if (!show) {
            return '';
        }
        const statusDocument = this.props?.dataAfterLoading?.core?.account?.is_verify_document;
        //const subStatus = this.subStatus(client.account_status.status_id, client.account.id);
        return (
            <div className='lead-info lead-component__item lead-component__info' data-info="status">
                <div className='lead-info-title'>{translate(`client_status_document`)}</div>
                <div className='lead-info-text label-not-hover'>
                    <span className = { `select-account-status-document label-${+statusDocument}` }>{this.props.enums?.core?.AccountVerifyDocumentEnum[+statusDocument]}</span>
                </div>
            </div>
        );
    };

}

const mapStateToProps = (store) => {
    return {
        client:        store.clients.client,
        error:         store.clients.error,
        mailTemplates: store.dashboard.mailTemplates,
        smsTemplates:  store.dashboard.smsTemplates,
    };
};

LeadComponent.propTypes = {
    dataAfterLoading: LEAD_PROP_TYPES,
    error:            bool,
};

export default withLocalize(withRouter(connect(
    mapStateToProps,
    (dispatch) =>
        bindActionCreators({
            getClient,
            clearClient,
            deleteClient,
            setResetError,
            setMailTemplates,
            setSmsTemplates
        }, dispatch)
)(LeadComponent)));
