import React from 'react';

import PermissionService, { MODULE_KEYS as pm, } from '../../services/PermissionService';
import { affiliateProgramsManagementConfig, } from './domains/AffiliateProgramsManagement/routes';
import { partnersConfig, } from './domains/Partners/routes';
import { referralsConfig, } from './domains/Referrels/routes';
import { promoConfig, } from './domains/Promomaterials/routes';

import PartnersSystem from './domains/PartnersSystem';
import AffiliatePayouts from './domains/AffiliatePayouts/AffiliatePayouts';
import AffiliateProgramsManagment from './domains/AffiliateProgramsManagement/AffiliateProgramsManagment';
import Partners from './domains/Partners/Partners';
import Referrels from './domains/Referrels/Referrels';
import Statistics from './domains/Statistics/Statistics';
import Promomaterials from './domains/Promomaterials/Promomaterials';
import Rebate from './domains/Rebate/Rebate';
import Managers from './domains/Managers/Managers';
import CalculationDashboard from "./domains/AffiliateProgramsManagement/CalculationsDashboard/CalculationDashboard";

export const partnersSystemConfig = [
	...affiliateProgramsManagementConfig,
	...partnersConfig,
	...referralsConfig,
	...promoConfig,
	{
		permission: pm.PARTNERS_REFERRALS,
		action: PermissionService.indexRoute(),
		component: <Referrels />,
		path: [
			'/partners/referrels',
		],
	},
	{
    // permission: pm.PARTNERS_REQUESTS,
    // action: PermissionService.indexRoute(),
		permission: null,
		component: <Partners />,
		path: [
			'/partners/requests',
		],
	},
	{
		permission: pm.PARTNERS_PAYMENTS,
		action: PermissionService.indexRoute(),
		component: <AffiliatePayouts />,
		path: [
			'/partners/partner-payouts/:id',
		],
	},
	{
		permission: pm.PARTNERS_PAYMENTS,
		action: PermissionService.indexRoute(),
		component: <AffiliatePayouts />,
		path: [
			'/partners/partner-payouts/',
		],
	},
	{
		permission: null,
		component: <AffiliateProgramsManagment />,
		path: [
			'/partners/affiliate-programs-management',
		],
	},
	{
		permission: null,
		component: <Promomaterials />,
		path: [
			'/partners/promo',
		],
	},
	{
		permission: null,
		component: <CalculationDashboard />,
		path: [
			'/partners/dashboards',
		],
	},
	{
		permission: pm.PARTNERS_REBATE,
		action: PermissionService.indexRoute(),
		component: <Rebate />,
		path: [
			'/partners/rebate',
		],
	},
	{
		permission: pm.PARTNERS_STATISTICS,
		action: PermissionService.indexRoute(),
		component: <Statistics />,
		path: [
			'/partners/statistics',
		],
	},
	{
		permission: pm.PARTNERS_STATISTICS,
		action: PermissionService.indexRoute(),
		component: <Managers />,
		path: [
			'/partners/managers',
		],
	},
	{
		permission: null,
		component: <PartnersSystem />,
		path: [ '/partners', ],
	},
];