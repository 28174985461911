export const kpiLang = {
  "kpi_calls": ["Calls", "Звонки", "Дзвінки"],
  "kpi_dialogs":["Dialogs", "Диалоги", "Діалоги"],
  "kpi_average_check":["Average Check", "Средний чек","Середня перевірка"],
  "kpi_january":["January", "Январь", "Січень"],
  "kpi_february":["February", "Февраль", "Лютий"],
  "kpi_march":["March", "Март", "Березень"],
  "kpi_april":["April", "Апрель", "Квітень"],
  "kpi_may":["May", "Май", "Травень"],
  "kpi_june":["June", "Июнь", "Червень"],
  "kpi_july":["July", "Июль", "Липень"],
  "kpi_august":["August", "Август", "Серпень"],
  "kpi_september":["September", "Сентябрь", "Вересень"],
  "kpi_october":["October", "Октябрь", "Жовтень"],
  "kpi_november":["November", "Ноябрь", "Листопад"],
  "kpi_december":["December", "Декабрь", "Грудень"],
  "kpi_send":        ["SEND", "Отправить", "Відіслати"],
}