import {shape, number, bool, object} from 'prop-types';

const USER_PROP_TYPES =  shape({
    login: shape({
        errors: object,
        httpStatus: number,
        success: bool
    }),
});

const OUTPUT_PROP_TYPES = shape({
    skip: number,
    take: number,
});

export {USER_PROP_TYPES, OUTPUT_PROP_TYPES};