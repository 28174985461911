import React from "react";
import { map } from "lodash";

import Select from 'react-select';
import DepartmentUsers from './DepartmentUsers';
import { Dropdown, DropdownToggle, DropdownMenu, Collapse } from 'reactstrap';

import clock from '../../../../images/clock.svg';
import person from '../../../../images/person.svg';

const customStyles = {
    control: () => ({
        backgroundColor: '#ffffff',
    }),
    singleValue: (base, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { opacity, transition };
    },
};

export class KPIWidgetFilter extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            dropdownOpen:      false,
            collapseName:      false,
            collapseDate:      false,
            dataYear:          this.props.dataYear,
            firstPossibleYear: this.props.firstPossibleYear,
            userName:          false,
            userSurname:       false,
            dataMonth:         this.props.dataMonth,
            selectedMonth:     { value: this.props.dataMonth, label: this.props.translate(this.props.months[this.props.dataMonth]) },
            selectedYear:      { value: this.props.dataYear, label: this.props.dataYear },
            usersDepartment:   this.props.usersDepartment,
        };
    }

    UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
        this.setState({
            usersDepartment: nextProps.usersDepartment,
        });
    }

    toggleDropdown = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    };

    setMonth = (event) => {
        this.setState({
            selectedMonth: { value: event.value, label: this.props.translate(this.props.months[event.value]) },
        });
        this.props.changeDataMonth(event);
    };

    setYear = (event) => {
        this.setState({
            selectedYear: { value: event.value, label: event.value },
        });
        this.props.changeDataYear(event.value);
    };

    componentDidMount () {

    }

    toggleCollapseDate = () => {
        this.setState({
            collapseDate: !this.state.collapseDate,
        });
    };

    toggleCollapseName = () => {
        this.setState({
            collapseName: !this.state.collapseName,
        });
    };

    changeUser = (event) => {
        this.setState({
            userName:    event.target.dataset.name,
            userSurname: event.target.dataset.surname,
        });

        this.props.changeDataUserId(event);
    };

    applyFilter = () => {
        this.setState({
            dropdownOpen:  false,
            dataMonth:     this.props.dataMonth,
            dataYear:      this.props.dataYear,
            selectedYear:  { value: this.props.dataYear, label: this.props.dataYear },
            selectedMonth: { value: this.props.dataMonth, label: this.props.translate(this.props.months[this.props.dataMonth]) },
        });
        this.props.applyKpiFilter();
    };

    render () {
        const months = [];

        const years = [];

        map(this.props.months, (item, key) => {
            months.push({ value: key, label: this.props.translate(item) });
        });

        let year;

        for (year = this.state.firstPossibleYear; year <= new Date().getFullYear(); year++) {
            years.push({ value: year, label: year });
        }
        return (
            <div className = 'kpi-filter'>
                <Dropdown isOpen = { this.state.dropdownOpen } toggle = { this.toggleDropdown }>
                    <DropdownToggle className = 'kpi-filter-button' tag = 'div'>
                        <i className = 'la la-cog' />
                    </DropdownToggle>
                    <DropdownMenu
                        modifiers = { {
                            setLeft: {
                                enabled: true,
                                order:   890,
                                fn:      (data) => {
                                    return {
                                        ...data,
                                        styles: {
                                            ...data.styles,
                                            left:       '50%',
                                            marginLeft: '-97px',
                                            padding:    0,
                                            border:     'none',
                                        },
                                    };
                                },
                            },
                        } }>
                        <div onClick = { this.toggleCollapseDate } className = 'kpi-main-collapse' ><img alt = 'clock' src = { clock } /> {this.props.translate(this.props.months[this.state.dataMonth])} {this.state.dataYear }</div>
                        <Collapse isOpen = { this.state.collapseDate }>
                            <div className = 'date-select'>
                                <Select
                                    className = 'filter-select'
                                    classNamePrefix = 'select'
                                    isSearchable = { false }
                                    onChange = { this.setMonth }
                                    options = { months }
                                    styles = { customStyles }
                                    value = { this.state.selectedMonth }
                                />
                                <Select
                                    className = 'filter-select'
                                    classNamePrefix = 'select'
                                    isSearchable = { false }
                                    onChange = { this.setYear }
                                    options = { years }
                                    styles = { customStyles }
                                    value = { this.state.selectedYear }
                                />
                            </div>
                        </Collapse>
                        <div onClick = { this.toggleCollapseName } className = 'kpi-main-collapse' ><img alt = 'person' src = { person } /> {this.state.userName ? this.state.userName : this.props.userName} {this.state.userSurname ? this.state.userSurname : this.props.userSurname}</div>
                        <Collapse isOpen = { this.state.collapseName }>
                            {map(this.state.usersDepartment, (item, key) => {
                                return (<DepartmentUsers
                                    changeDataUserId = { this.changeUser }
                                    item = { item.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1) }
                                    key = { key }
                                />);
                            })}
                        </Collapse>
                        <div className = 'kpi-button-apply' onClick = { this.applyFilter }>{this.props.translate('kpi_send')}</div>
                    </DropdownMenu>
                </Dropdown>
            </div>
        );
    }
}
