
const accessChecked = undefined;

export const menu = [
	{
		icon: 'dashboard',
		label: 'menu_dashboard',
		to: '/dashboard',
		path: [ 'core', ],
		key: 'menuDashboard',
		accessChecked: accessChecked ?? true, // !?!?!?!
	},
	{	//Not sure about ? fields, may change later
		icon: 'conversations',
		label: 'menu_conversations',
		to: '/conversations',
		subMenuItem: false,
		clientItem: false,
		accessChecked,
		path: [ 'core', ],
		key: 'menuConversations',
		dropDownArrow: false,
	},
	{
		icon: 'marketing',
		label: 'menu_marketing',
		to: '/marketing',
		path: [ 'statistics', ],
		key: 'menuMarketing',
		content: [
			{
				label: 'menu_marketing_advertising_channels',
				to: '/marketing/advertising_channels',
				key: 'menuMarketingAdvertisingChannels',
				path: [ 'statistics', ],
			},
		],
		accessChecked,
	},
	{
		icon: 'assignment-ind',
		label: 'menu_clients',
		clientItem: true,
		to: '/clients',
		path: [ 'core', 'account', ],
		key: 'menuClients',
		content: [],
		accessChecked,
	},
	{
		icon: 'check-circle',
		label: 'menu_tasks',
		to: '/tasks',
		notification: '99+',
		path: [ 'tasks', 'tasks', ],
		key: 'menuTasks',
		accessChecked,
	},
	{
		icon: 'calendar-today',
		label: 'menu_calendar',
		to: '/calendar',
		path: [ 'tasks', ],
		key: 'menuCalendar',
		content: [
			{
				label: 'calendar_day',
				to: '/calendar/day',
				key: 'menuCalendarDay',
				path: [ 'tasks', 'tasks', ],
			},
			{
				label: 'calendar_week',
				to: '/calendar/week',
				key: 'menuCalendarWeek',
				path: [ 'tasks', 'tasks', ],
			},
			{
				label: 'calendar_month',
				to: '/calendar/month',
				key: 'menuCalendarMonth',
				path: [ 'tasks', 'tasks', ],
			},
		],
		accessChecked,
	},
	{
		icon: 'call',
		label: 'menu_callstat',
		to: '/calls-statistics',
		path: [ 'calls', 'calls', ],
		key: 'menuCallstat',
		accessChecked,
	},
	{
		icon: 'settings',
		label: 'menu_admin_panel',
		to: '/settings',
		path: [ 'core', ],
		key: 'menuAdminPanel',
		content: [
			{
				label: 'menu_user',
				to: '/settings',
				key: 'menuUser',
				path: [ 'core', 'user', ],
			},
			{
				label: 'menu_kpi_settings',
				to: '/settings/admin-kpi',
				key: 'menuKpiSettings',
				path: [ 'core', 'user_kpi_plan', ],
			},
			{
				label: 'menu_tree',
				to: '/settings/tree',
				key: 'menuTree',
				path: [ 'core', 'hierarchy_tree', ],
			},
			{
				label: 'template_management',
				to: '/settings/template-management',
				key: 'templateManagement',
				path: [ 'core', 'sms_template_translation', ],
			},
			{
				label: 'distribution_settings',
				to: '/settings/distribution',
				key: 'distributionSettings',
				path: [ 'core', 'distribution_setting', ],
			},
			{
				label: 'template_permission',
				to: '/settings/template-permission',
				key: 'templatePermission',
				path: [ 'core', 'permission_templates', ],
			},
			{
				label: 'delegation',
				to: '/settings/delegation',
				key: 'delegation',
				path: [ 'tasks', 'task_delegation', ],
			},
			{
				label: 'auto_call',
				to: '/settings/auto-call',
				key: 'autoCall',
				path: [ 'calls', 'user_calls_setting', ],
			},
			{
				label: 'list_of_crons',
				to: '/settings/cron',
				key: 'cron',
				path: [ 'log', 'cron', ],
			},
			{
				label: 'menu_admin_activity_report',
				to: '/settings/activity-report',
				key: 'activityReport',
				path: [ 'core', ],
			},
		],
		accessChecked,
	},
	{
		icon: 'list-alt',
		label: 'list__accounts',
		to: '/list-accounts',
		path: [ 'core', ],
		key: 'listAccounts',
		content: [
			{
				label: 'list_accounts_system',
				to: '/list-accounts/systems',
				key: 'listAccountsSystem',
				path: [ 'core', 'system_accounts', ],
			},
			{
				label: 'list_accounts_trading',
				to: '/list-accounts/tradings',
				key: 'listAccountsTrading',
				path: [ 'core', 'trading_accounts', ],
			},
			{
				label: 'list_accounts_corporate_action',
				to: '/list-accounts/corporate-action',
				key: 'listAccountsCorporateAction',
				path: [ 'corporateaction', 'corporate_action', ],
			},
			{
				label: 'balance_operations',
				to: '/list-accounts/balance-operations',
				key: 'listAccountsBalanceOperations',
				path: [ 'core', 'system_accounts_transaction_history', ],
			},
		],
		accessChecked,
	},
	{
		icon: 'tima',
		label: 'menu_tima',
		to: '/tima',
		path: [ 'tima', ],
		key: 'menuTima',
		content: [
			{
				label: 'menu_tima_strategies',
				to: '/tima/strategies',
				key: 'menuTimaStrategies',
				path: [ 'tima', 'tima_manager', ],
			},
			{
				label: 'menu_tima_investments',
				to: '/tima/investments',
				key: 'menuTimaInvestments',
				path: [ 'tima', 'tima_investment', ],
			},
			{
				label: 'menu_tima_conditions',
				to: '/tima/conditions',
				key: 'menuTimaConditions',
				path: [ 'tima', 'tima_condition', ],
			},
			{
				label: 'menu_tima_agents',
				to: '/tima/agents',
				key: 'menuTimaAgents',
				path: [ 'tima', 'tima_agents', ],
			},
		],
		accessChecked,
	},
	{
		icon: 'people-alt',
		label: 'menu_partners',
		to: '/partners',
		path: [ 'partners', ],
		key: 'menuPartners',
		content: [
			{
				label: 'menu_partners_system_affiliate_programs_management',
				to: '/partners/affiliate-programs-management',
				path: [ 'partners', ],
				key: 'menuPartnersSystemAffiliateProgramsManagement',
				content: [
					{
						label: 'menu_partners_system_affiliate_programs',
						to: '/partners/affiliate-programs-management/affiliate-programs',
						key: 'menuPartnersSystemAffiliatePrograms',
						path: [ 'partners', 'programs', ],
					},
					{
						label: 'menu_partners_system_conditions_for_accruals',
						to: '/partners/affiliate-programs-management/conditions-for-accruals',
						key: 'menuPartnersSystemConditionsForAccruals',
						path: [ 'partners', 'new_partners_conditions', ],
					},
					{
						label: 'menu_partners_system_country_list_with_payouts_rates',
						to: '/partners/affiliate-programs-management/payout-rate-lists',
						key: 'menuPartnersSystemCountryListWithPayoutsRates',
						path: [ 'partners', 'payout_rate_lists', ],
					},
					{
						label: 'menu_partners_system_symbols',
						to: '/partners/affiliate-programs-management/symbols',
						key: 'menuPartnersSystemSymbols',
						path: [ 'partners', 'symbols', ],
					},
					{
						label: 'menu_partners_system_symbols_groups',
						to: '/partners/affiliate-programs-management/symbols-groups',
						key: 'menuPartnersSystemSymbolsGroups',
						path: [ 'partners', 'symbols_groups', ],
					},
					{
						label: 'menu_partners_system_symbols_lists',
						to: '/partners/affiliate-programs-management/symbols-lists',
						key: 'menuPartnersSystemSymbolsLists',
						path: [ 'partners', 'symbols_lists', ],
					},
					{
						label: 'menu_partners_system_account_groups_list',
						to: '/partners/affiliate-programs-management/account-groups-lists',
						key: 'menuPartnersSystemAccountGroupsList',
						path: [ 'partners', 'groups_lists', ],
					},
				],
				accessChecked,
			},
			{
				label: 'menu_partners_system_affiliate_payouts',
				to: '/partners/partner-payouts',
				key: 'menuPartnersSystemAffiliatePayouts',
				path: [ 'partners', 'accruals', ],
				content: [
					{
						label: 'partners_system_affiliate_payouts_history',
						to: '/partners/partner-payouts',
						key: 'menuPartnersSystemHistoryOfAllPayouts',
						path: [ 'partners', 'accruals', ],
					},
				],
				accessChecked,
			},
			{
				label: 'menu_partners_list',
				to: '/partners/requests',
				key: 'menuPartnersSystemPartners',
				path: [ 'partners', ],
				content: [
					{
						label: 'menu_partners_system_partners_list',
						to: '/partners/requests/partners-list',
						key: 'menuPartnersSystemPartnersList',
						path: [ 'partners', 'partners', ],
					},
					{
						label: 'menu_partners_system_partnership_applications',
						to: '/partners/requests/partnership-applications',
						key: 'menuPartnersSystemPartnershipApplications',
						path: [ 'partners', 'requests', ],
					},
				],
				accessChecked,
			},
			{
				label: 'menu_partners_referrer',
				to: '/partners/referrels',
				key: 'menuPartnersReferrer',
				path: [ 'partners', 'referrals', ],
				content: [
					{
						label: 'menu_partners_system_referrer_list',
						to: '/partners/referrels/referrals-list',
						key: 'menuPartnersSystemReferrersList',
						path: [ 'partners', 'referrals', ],
					},
				],
				accessChecked,
			},
			{
				label: 'partners_manage_promo_materials',
				to: '/partners/promo',
				key: 'menuPartnersPromo',
				path: [ 'partners', 'promo', ],
				content: [
					{
						label: 'partners_banners',
						to: '/partners/promo/banners',
						key: 'menuPartnersBanners',
						path: [ 'partners', 'promo', ],
					},
					{
						label: 'partner_target_page',
						to: '/partners/promo/target-page',
						key: 'menuPartnersTargetPages',
						path: [ 'partners', 'target_pages', ],
					},
					{
						label: 'partner_logos',
						to: '/partners/promo/logos',
						key: 'menuPartnersLogos',
						path: [ 'partners', 'logos', ],
					},
					{
						label: 'partners_lendings',
						to: '/partners/promo/lending',
						key: 'menuPartnersLending',
						path: [ 'partners', 'lending', ],
					},
				],
				accessChecked,
			},
			{
				label: 'menu_partners_system_dashboard_calculation',
				to: '/partners/dashboards',
				key: 'menuPartnersSystemDashboards',
				path: [ 'partners', 'condition_calculation_state', ],
				content: [
					{
						label: 'menu_partners_system_dashboard_calculation',
						to: '/partners/dashboards/calculations-dashboard',
						key: 'menuPartnersSystemCalculationDashboard',
						path: [ 'partners', 'condition_calculation_state', ],
					},
					{
						label: 'menu_partners_system_dashboard_calculation_payout',
						to: '/partners/dashboards/payouts-dashboard',
						key: 'menuPartnersSystemCalculationPayoutDashboard',
						path: [ 'partners', 'condition_payout_state', ],
					},
					{
						label: 'menu_partners_system_waiting_switch',
						to: '/partners/dashboards/partners-waiting-switch',
						key: 'menuPartnersSwitchDashboard',
						path: [ 'partners', 'awaiting_change_program', ],
					},
					{
						label: 'menu_partners_referrals_waiting_switch',
						to: '/partners/dashboards/referrals-waiting-switch',
						key: 'menuPartnersSwitchReferralsDashboard',
						path: [ 'partners', 'awaiting_add_referral_register', ],
					},
				],
				accessChecked,
			},
			{
				label: 'partners_rebate',
				to: '/partners/rebate',
				key: 'menuPartnersRebate',
				path: ['partners', 'rebate_projects'],
				content: [
					{
						label: 'partners_rebate_projects',
						to: '/partners/rebate',
						key: 'menuPartnersRebateProjects',
						path: ['partners', 'rebate_projects'],
					},
				],
				accessChecked,
			},
		],
	},
	{
		icon: 'bonuses',
		label: 'menu_bonuses',
		to: '/bonuses',
		path: [ 'core', 'bonus_program_to_account', ],
		key: 'menuBonuses',
		accessChecked,
		content: [
			{
				label: 'menu_bonuses_client_list',
				to:    '/bonuses',
				key:   'menuBonusProgramToAccount',
				path: [ 'core', 'bonus_program_to_account', ],
			},
		],
	},
	{
		icon: 'swap-horizontal-circle',
		label: 'menu_payments',
		to: '/payments',
		accessChecked,
		path: [ 'payment', 'orders', ],
		key: 'menuPayments',
		content: [
			{
				label: 'menu_payments',
				to: '/payments',
				key: 'menuPaymentsList',
				path: [ 'payment', 'orders', ],
			},
			{
				label: 'summary_table_title',
				to: '/payments/summary_date',
				key: 'menuPaymentsSummary',
				path: [ 'payment', 'daily_aggregated_fin_transactions', ],
			},
		],
	},
	{
		icon: 'category',
		label: 'menu_contests',
		to: '/contests',
		path: [ 'contests', 'contests', ],
		key: 'menuContests',
		accessChecked,
	},
	{
		icon: 'headset-mic',
		label: 'menu_support',
		to: '/support',
		path: [ 'tickets', 'tickets', ],
		key: 'menuSupport',
		accessChecked,
	},
];