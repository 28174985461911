import React from "react";
import Select from 'react-select';

const customStyles = {
    control: () => ({
        width: 100,
    }),
    singleValue: (base, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { opacity, transition };
    },
};

class ImportanceSelect extends React.Component {
    constructor (props) {
        super(props);
    }

    componentDidMount () {
        if (this.props.getData) {
            this.props.getData();
        }
    }

    render () {
        let constValueLabel = "";

        if (this.props.data.length > 0) {
            constValueLabel = this.props.data[0].label;

            this.props.data.map((item, key) => {
                if (item.value === this.props.task.tasks.task_urgency.id) {
                    constValueLabel =  item.label;
                }
            });
        }

        const selectedOptionDefault = {
            value: this.props.task.tasks.task_urgency.id,
            label: constValueLabel,
        };

        return (

            <div className = 'table-filter-select tooltip-wrapper'>
                <Select
                    isSearchable = {false}
                    className = {`task-select page-select urgency-${this.props.error('urgency_id')}`}
                    classNamePrefix = 'select'
                    placeholder = {this.props.translate(`tasks_list_select`)}
                    options = { this.props.data }
                    styles = { customStyles }
                    onChange = { this.props.handleChangeImportance }
                    value = { this.props.dataTask.urgency_id ? ((event) => this.event)() : selectedOptionDefault }
                />
                <div className = 'tooltip tooltip-top'>{ constValueLabel }</div>
            </div>

        );
    }
}

export default ImportanceSelect;
