import React from 'react';
import { connect } from 'react-redux';
import { isUndefined, isNull } from "lodash";

import AccessDeniedPage from "../modules/common/components/AccessDeniedPage";
import PermissionService from '../services/PermissionService';

class RoutePermission extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            component: this.props.data.component,
        };

        this.setComponent = this.setComponent.bind(this);
    }

    UNSAFE_componentWillReceiveProps (nextProps, oldProps) {
        if (!isUndefined(nextProps.data.action) && !isNull(nextProps.data.action)) {
            this.setComponent(
                (PermissionService.actionForRoute(nextProps.data.permission, nextProps.data.action)) ?
                    nextProps.data.component
                    :
                    <AccessDeniedPage />
            );
        } else if (!isNull(nextProps.data.permission)) {
            this.setComponent(
                (PermissionService.canRoute(nextProps.data.permission)) ?
                    nextProps.data.component
                    :
                    <AccessDeniedPage />
            );
        } else {
            this.setComponent(nextProps.data.component);
        }
    }

    setComponent(component) {
        this.setState({
            component
        });
    }

    render () {
        const component = this.state.component;

        return (
            component
        );
    }
}

const mapStateToProps = (store) => {
    return {
        profile: store.user.profile,
    };
};

export default connect(mapStateToProps)(RoutePermission);
