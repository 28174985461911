import { valid, } from './Config.valid';

/**
 * @typedef {Object} Filter
 * @property {Object} $ - { id: String, type: String, }
 * @property {Number} [enabled=0] - enabled in { 0 | 1 }
 * @property {String | String[]} f - field name(s)
 * @property {String} [o="="] - operator
 * @property {Number | Number[] | String | String[]} [v] - value
 * @property {Number} [having] - having in { 0 | 1 }
 * @property {String} [j="n"] - j in { "n" | "y" }
 * @property {Number} [sub_where] - sub_where in { 0 | 1 }
 * @property {Object | Object[]} [fields] - fields
 */

/**
 * @typedef {Object} Order
 * @property {Object} $ - { id: String, type: String, }
 * @property {Number} [enabled=0] - enabled in { 0 | 1 }
 * @property {String} f - field
 * @property {String} [o="order_by"] - operator const "order_by"
 * @property {String} [v="asc"] - value in { "asc" | "desc" }
 * @property {String} [j="n"] - j in { "n" | "y" }
 * @property {Number} [order] - order in { 0 | 1 }
 */

/**
 * @typedef {Object} Panel
 * @property {String} id - panelId for new panel item
 * @property {String | String[]} items - filterIds
 * @property {String} [translate] - translate for new panel item
 */

/**
 * @class
 */
export class Config {

  /**
   * Creates instance of Config
   *
   * @arg {Object} settings - {
   *   id: Number,
   *   filters: Filter[],
   *   orders: Order[],
   *   panels: Panel[],
   *   path: String,
   *   widget: Boolean,
   *   translate: Function,
   * }
   * @memberof Config
   */
  constructor (settings) {
    valid(settings);

    // eslint-disable-next-line object-curly-newline
    const { id, filters, orders, panels, path, translate, widget, } = settings;

    Object.defineProperties(this, {
      filters: {
        get: () => ({ flat = false, single = false, }) => {
          let items = JSON.parse(JSON.stringify(filters));

          if (flat || single) {
            items = items.map((item) => {
              const { fields, ...F } = item;

              if (flat && Array.isArray(fields)) {
                return [ single ? F : item, ...fields, ];
              } else if (flat && fields) {
                return [ single ? F : item, fields, ];
              }

              return [ single ? F : item, ];
            });

            items = [].concat(...items);
          }

          return items;
        },
      },
      id: { enumerable: true, get: () => id, },
      orders: {
        enumerable: true,
        get: () => orders.map(({ $, ..._ }) => ({
          $: { ...$, },
          ..._,
        })),
      },
      panels: {
        enumerable: true,
        // eslint-disable-next-line no-shadow
        get: () => panels.map(({ id, items: i, ..._ }) => ({
          ..._,
          id,
          items: Array.isArray(i) ? [ ...i, ] : i,
          label: () => this.label(id),
        })),
      },
      path: { enumerable: true, get: () => path, },
      settings: { enumerable: true, get: () => settings, },
      toObject: { get: () => () => settings, },
      translate: { get: () => translate, },
      widget: { enumerable: true, get: () => widget, },
    });

    Object.freeze(this);

    panels.forEach((_) => {
      // eslint-disable-next-line no-shadow
      if (Array.isArray(_.items) && !_.items.every(_ => this.filter(_))) {
        throw new Error(`Expect every Config.panels[].items [${ _.items }] is in Config.filters`);
      } else if (!Array.isArray(_.items) && !this.filter(_.items)) {
        throw new Error(`Expect Config.panels[].items [${ _.items }] is in Config.filters`);
      }
    });
  }

  /**
   * Get Filter by filterId
   *
   * @arg {String} id - filterId
   * @arg {Boolean} [single=false] - if item.fields
   * @returns {Filter} filter from filters
   * @memberof Config
   */
  filter (id, single = false) {
    const match = ({ $, }) => $.id === id;

    return this.filters({ flat: true, single, }).find(match);
  }

  /**
   * Get Panel translated label by panelId
   *
   * @arg {String} id - panelId
   * @return {String} panel label
   * @memberof Config
   */
  label (id) {
    const { translate: t, xlabel: x, } = this.panel(id) ?? {};

    return x ? x : t ? this.translate(t) : '?Panel?';
  }

  /**
   * Generate URL to get filter optoions
   *
   * @returns {String} URL
   * @memberof Config
   */
  loadFilterOptions () {
    const url = `/api/${ this.path }/?filter_options=1`;

    // eslint-disable-next-line no-restricted-globals
    return new URL(url, location.href);
  }

  /**
   * Generate URL to service bind filter to user
   *
   * @returns {String} URL
   * @memberof Config
   */
  loadUserFilter () {
    const url = `/api/core/user-filter/user-type/${ this.id }`;

    // eslint-disable-next-line no-restricted-globals
    return new URL(url, location.href);
  }

  /**
   * Get Order by orderId
   *
   * @arg {String} id - orderId
   * @returns {Order} order
   * @memberof Config
   */
  order (id) {
    return this.orders.find(_ => _.$.id === id);
  }

  /**
   * Get Panel by panelId
   *
   * @arg {String} id - panelId
   * @return {Panel} panel
   * @memberof Config
   */
  panel (id) {
    return this.panels.find(_ => _.id === id);
  }

  /**
   * Generate URL to service filter save
   *
   * @returns {String} URL
   * @memberof Config
   */
  saveFilter () {
    const url = `/api/${ this.path }/filter`;

    // eslint-disable-next-line no-restricted-globals
    return new URL(url, location.href);
  }

  /**
   * Generate URL to service binding filter to user
   *
   * @returns {String} URL
   * @memberof Config
   */
  saveUserFilter () {
    const url = `/api/core/user-filter`;

    // eslint-disable-next-line no-restricted-globals
    return new URL(url, location.href);
  }

  toJSON () {
    return {
      filters: this.filters({ flat: false, }),
      orders: this.orders,
      panels: this.panels,
    };
  }

  /**
   * Generate URL to service unbind filter from user
   *
   * @arg {Number} userFilterId - id of bind filter to user
   * @returns {String} URL
   * @memberof Config
   */
  unsaveUserFilter (userFilterId) {
    const url = `/api/core/user-filter/${ userFilterId }`;

    // eslint-disable-next-line no-restricted-globals
    return new URL(url, location.href);
  }

  /**
   *
   * @readonly
   * @memberof Config
   */
  get json () {
    return this.toJSON();
  }

  /**
   *
   * @readonly
   * @memberof Config
   */
  get object () {
    return this.toObject();
  }

}