import React from "react";

class DeleteTemplatePopup extends React.Component {
    constructor (props) {
        super(props);
    }

    render () {
        return (
            <div className = 'modal-task-page'>
                <div className = 'overlay' />
                <div className = 'fade-block fade show'>
                    <div className = 'lead-modal'>
                        <div
                            className = 'close-button' onClick = { () => {
                                this.props.toogle();
                            } }>
                            <i className = 'la la-close' />
                        </div>
                        <h1 className = 'aligncenter'>{ this.props.translate(`tasks_delete_are_u_sure`) }</h1>
                        <span>
                            {this.props.countUser === 0 ?
                                this.props.translate(`template_remove_template_message_two`)
                                :
                                this.props.translate(`template_remove_template_message`)
                            }
                        </span>
                        <div className = 'button-area'>
                            {this.props.countUser === 0 ?
                                <div className = 'button bright-blue' onClick = { this.props.delete }>{ this.props.translate(`tasks_delete_yes`) }</div>
                                :
                                ""
                            }

                            <div
                                className = 'button white shadowed' onClick = { () => {
                                    this.props.toogle();
                                } }>{ this.props.translate(`tasks_delete_no`) }</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DeleteTemplatePopup;
