import React from 'react';
import { Button, Form, FormGroup } from 'reactstrap';
import { isNull } from "lodash";
import { getIcon } from '../../services/TaskHelperService'
import DiscussionBlockMention from './DiscussionBlockMention';
import DiscussionBlockCommentInput from './DiscussionBlockCommentInput';

export default class DiscussionBlockCommentForm extends React.Component {
    constructor (props) {
        super(props);

        this.state = {
            commentTask: { ...this.props.commentTask },
        };
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        this.setState({
            commentTask: { ...nextProps.commentTask },
        });
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.props.sendComment();
        }
    };

    renderAttachment = (item, i) => {
        const nameArr = item?.name?.split('.');
        const type = nameArr[nameArr?.length - 1] ?? null;
        const icon = getIcon(type);

        return (
            <div key = { i } className="comment-form__attachment">
                {icon}
                <p>{item.name}</p>
                <img
                    alt = "delete"
                    className = "delete-attachment"
                    onClick = { () => this.props.handleDeleteAttachment(i) }
                    src = "/images/remove.svg"
                />
            </div>
        );
    }

    handleMentionUser = (id) => {
        const comment = this.props.commentTask.description;
        const lastIndex = comment.lastIndexOf('@');
        const formated = comment.substring(0, lastIndex) + ` #USER_ID:${id}# ` + comment.substring(lastIndex + 1);

        this.props.setToStateComment({ target: { name: 'description', value: formated }});
    }

    handleInputChange = (e, key) => {
        const words = this.props?.commentTask?.description?.split(/(#USER_ID:\d+#)/);

        words[key] = e.target.value;
        this.props.setToStateComment({ target: { name: 'description', value: words.join('') }});
    }

    getDescription (description) {
        const rx = /\#USER_ID:([0-9]+)\#/g;

        if (rx.test(description)) {
            description.match(rx).forEach((item) => {
                const id = item.match(/[0-9]+/)[0];
                const user = this.props.findUserById(id);

                description = user ? description.replace(/\#USER_ID:([0-9]+)\#/, `${user?.name ?? ''} ${user?.surname ?? ''}`) : description;
            });
        }

        return description;
    }

    render () {
        const description = this.props?.commentTask?.description;

        return (
            <Form className = 'discussion-form'>
                <FormGroup>
                    {(/@$/).test(description) ?
                        (<DiscussionBlockMention
                            assistants = { this.props.assistants ?? [] }
                            handleMentionUser = { this.handleMentionUser }
                            translate = { this.props.translate }
                        />) : ''}
                    {this.props.reply && !isNull(this.props.commentTask.parent_id) ?
                        <div className = { `discussion-sendForm_1` }>
                            <div className = { `discussion-sendForm-text_1` }>
                                {this.getDescription(this.props.allComent[this.props.commentTask.parent_id]?.tasks?.task_comments?.description)}
                                <div className = { `one` }>
                                    <div
                                        className = { `two` } onClick = {
                                            () => {
                                                this.props.removeReply();
                                            }
                                        }>
                                        X
                                    </div>
                                </div>
                            </div>
                            <div style = { { position: 'relative' } }>
                                <label className = {`custom-file-upload ${this.props.commentTask.files.length >= 5 ? 'disabled' : ''}`}>
                                    <input
                                        type = 'file'
                                        onChange = { (e) => {
                                            this.props.attachFileToComment(e);
                                            e.target.value = '';
                                        }}
                                        disabled = { this.props.commentTask.files.length >= 5 }
                                    />
                                </label>
                                <DiscussionBlockCommentInput
                                    comment = { this.props.commentTask.description }
                                    findUserById = { this.props.findUserById }
                                    handleInputChange = { this.handleInputChange }
                                    setComment = { (e) => this.props.setToStateComment(e) }
                                    autoFocus = { this.props.autoFocus }
                                    setAutoFocusTrue = { this.props.setAutoFocusTrue }
                                />
                                <Button
                                    onClick = { () => {
                                        this.props.sendComment();
                                    } }>{ this.props.translate(`tasks_send`)}</Button>
                            </div>
                        </div>
                        :
                        <div style={{position: 'relative'}}>
                            <label className = {`custom-file-upload ${this.props.commentTask.files.length >= 5 ? 'disabled' : ''}`}>
                                <input
                                    type = 'file'
                                    onChange = { (e) => {
                                        this.props.attachFileToComment(e);
                                        e.target.value = '';
                                    }}
                                    disabled = { this.props.commentTask.files.length >= 5 }
                                />
                            </label>
                            <DiscussionBlockCommentInput
                                comment = { this.props.commentTask.description }
                                findUserById = { this.props.findUserById }
                                handleInputChange = { this.handleInputChange }
                                setComment = { (e) => this.props.setToStateComment(e) }
                                autoFocus = { this.props.autoFocus }
                                setAutoFocusTrue = { this.props.setAutoFocusTrue }
                            />
                            <Button
                                onClick = { () => {
                                    this.props.sendComment();
                                } }>{ this.props.translate(`tasks_send`)}</Button>
                        </div>
                    }
                </FormGroup>
                {this.state?.commentTask?.files?.length > 0 &&
                 this.state?.commentTask?.files.map((item, i) => this.renderAttachment(item, i))}
            </Form>
        );
    }
}
