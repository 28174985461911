export const callstatisticsLang = {
    "callstatistics": [ "Calls statistic", "Статистика звонков", "Статистика дзвінків" ],
    "callstatistics_date":           ["Date", "Дата", "Дата"],
    "callstatistics_call":           ["call", "звонок", "дзвінок"],
    "callstatistics_client":         ["Client", "Клиент", "Клієнт"],
    "callstatistics_user":           ["Manager", "Менеджер", "Менеджер"],
    "callstatistics_type_incoming":  ["Incoming", "Входящий", "Вхідний"],
    "callstatistics_result":         ["Result", "Результат", "Результат"],
    "callstatistics_type_outgoing":  ["Outgoing", "Исходящий", "Вихідний"],
    "callstatistics_type_missed":    ["Missed", "Пропущенный", "Пропущений"],
    "callstatistics_type_call":      ["Type call", "Тип звонка", "Тип дзвінка"],
    "callstatistics_duration":       ["Duration", "Продолжительность", "Тривалість"],
    "callstatistics_result_value_1": ["It is a long established fact that a reader will be distracted\n" +
    "by the readable content of a page when looking at its layout.", "Это давно установленный факт, что читатель будет отвлекаться\n" +
    "читаемым содержимым страницы при просмотре ее макета.", "Це давно встановлений факт, що читач буде відволікатися\n" +
    "читається вміст сторінки при перегляді її компонування."],
    "outgoing":                  ["Outgoing", "Исходящий", "Вихідний"],
    "incoming":                  ["Incoming", "Входящий", "Вхідний"],
    "missed":                    ["Missed", "Пропущенный", "Пропущений"],
    "call_statistics_answered":  ["Answered", "Отвечен", "Відповіли"],
    "call_statistics_no_answer": ["Not answered", "Пропущенный", "Пропущений"],
    "call_statistics_busy":      ["Busy", "Занято", "Зайнято"],
    "call_statistics_failed":    ["Failed", "Неуспешный", "Невдалий"],
    "call_queue_members_add": ["Add accounts to queue", "Добавить клиентов в очередь", "Додати клієнтів до черги"],
    "call_queue_members_clr": ["Clear queue", "Очистить очередь", "Очистити чергу"],
};
