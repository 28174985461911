import React from 'react';
import { isUndefined } from "lodash";
import { connect } from 'react-redux';

import CheckErrorId from "./CheckErrorId";

const CheckUndefinedPermission = ({ permission_data, children, error }) => {

    return (

        !isUndefined(permission_data) ? <CheckErrorId error = { error }>{children}</CheckErrorId> : null
    );
};

export default connect(
    (store) => {
        return {
            permission_data: store.user.profile.permission_data,
        };
    }
)(CheckUndefinedPermission);
