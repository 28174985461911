import React                  from 'react';
import * as PropTypes         from 'prop-types';


export class MagicButton extends React.Component {
	static defaultProps = {
		children: 'MagicButton',
		className: [],
		disabled: false,
	};

	static childrenPropType = PropTypes.node;
	static classNamePropType = PropTypes.string;
	static classNamePropTypes = PropTypes.oneOfType( [
		MagicButton.classNamePropType,
		PropTypes.arrayOf( MagicButton.classNamePropType ),
	] );
	static disabledPropType = PropTypes.bool;
	static propTypes = {
		children: MagicButton.childrenPropType.isRequired,
		className: MagicButton.classNamePropTypes.isRequired,
		disabled: MagicButton.disabledPropType.isRequired,
		onClick: PropTypes.func.isRequired,
		onDoubleClick: PropTypes.func,
	};

	async onClick ( event ) {
		event.preventDefault();
		const { onClick, } = this.props;
		onClick && await onClick( event );
	}

	async onDoubleClick ( event ) {
		event.preventDefault();
		const { onDoubleClick, } = this.props;
		onDoubleClick && await onDoubleClick( event );
	}

	render () {
		const { children, className, disabled, } = this.props;
		const classNames = className instanceof Array ? className : [ className ];
		const options = {
			children,
			className: [ 'magic-button', ...classNames ].join(' '),
			disabled,
			onClick: this.onClick.bind( this ),
			onDoubleClick: this.onDoubleClick.bind( this ),
		};
		return (<button { ...options } />);
	}

};
