import { valid, } from './Storage.valid';
import { StorageItemFilter, } from '../StorageItemFilter';
import { StorageItemOrder, } from '../StorageItemOrder';

/**
 * Check is Filter or not
 *
 * @arg {Object} _ - StorageItemFilter
 * @returns {Boolean} check result
 */
const isFilter = _ => _?.$type === 'filter'; // instanceof StorageItemFilter;

/**
 * Check is Order or not
 *
 * @arg {Object} _ - StorageItemOrder
 * @returns {Boolean} check result
 */
const isOrder = _ => _?.$type === 'order'; // instanceof StorageItemOrder;

/**
 * Check is Filter / Order or not
 *
 * @arg {Object} _ - StorageItemFilter or StorageItemOrder
 * @returns {Boolean} check result
 */
const isItem = _ => isFilter(_) || isOrder(_);

/**
 * Create compare function to find match StorageItemFilter
 *
 * @arg {Object} filter - { id: String, match: Function, }
 * @returns {Function} Filter match functino
 */
const fmatch = ({ id, match, }) => {
  return typeof id === 'string'
    ? _ => isFilter(_) && _.$id === id
    : match instanceof Function
      ? _ => isFilter(_) && match(_)
      : () => false;
};

/**
 * Create compare function to find match StorageItemOrder
 *
 * @arg {Object} filter - { id: String, match: Function, }
 * @returns {Function} Order match functino
 */
const omatch = ({ id, match, }) => {
  return typeof id === 'string'
    ? item => isOrder(item) && item.$id === id
    : match instanceof Function
      ? item => isOrder(item) && match(item)
      : () => false;
};

/**
 * @class
 */
export class Storage {

  /**
   * Creates as instance of Storage
   *
   * @arg {Mentor} mentor - owner instance
   * @memberof Storage
   */
  constructor (mentor) {
    valid(mentor);

    Object.defineProperties(this, {
      config: { enumerable: true, get: () => mentor.config, },
      mentor: { enumerable: true, get: () => mentor, },
      options: { enumerable: true, get: () => mentor.options, },
    });

    let items = [];

    Object.defineProperties(this, {
      indexes: {
        enumerable: true,
        get: () => Object.keys(items).map(Number),
      },
      item: {
        get: () => index => items[index],
        set: ({
          id, $$, match, item, index,
        }) => {
          if (id && $$ && match) {
            // eslint-disable-next-line no-shadow
            const index = this.index(match({ id, }));

            if (this.indexes.includes(index)) {
              // eslint-disable-next-line no-unused-vars
              const { $, ...setter } = $$;
              const clone = this.item(index).$$(setter, []);

              this.item = { index, item: clone, };
            } else {
              throw new Error(`Expect Storage.item = {id, $$, match,} matched id not found`);
            }
          } else if (!isItem(item)) {
            throw new Error(`Expect Storage.item = {item,} as StorageItem`);
          } else if (this.indexes.includes(index)) {
            items.splice(index, 1, item);
          } else if ([ -1, ].includes(index)) {
            items.push(item);
          } else if (match) {
            this.item = { index: this.index(match({ id: item.$id, })), item, };
          } else {
            throw new Error(`Expect Storage.item = unknown`);
          }
        },
      },
      items: { enumerable: true, get: () => [ ...items, ], },
      itemsAmount: { enumerable: true, get: () => items.length, },
      unitem: { get: () => i => items.splice(i, +(i in items)).shift(), },
      unitems: { get: () => match => items = items.filter(match), },
    });

    Object.freeze(this);
  }

  /**
   * Search matching item in Storage
   *
   * @arg {Function} match - compare-function
   * @returns {Number} search item by match function
   * @memberof Storage
   */
  index (match) {
    if (match instanceof Function) {
      const findIndex = (item, index) => {
        const matched = match(item, index);

        return matched;
      };

      return this.items.findIndex(findIndex);
    }

    return -1;
  }

  /**
   * Exporting Storage to be stored to DB
   *
   * @returns {JSON} Export Storage items
   * @memberof Storage
   */
  toJSON () {
    return this.items.map(item => item.toJSON());
  }

  /**
   * Cast Storage to String
   *
   * @returns {String} Stringified Storage items
   * @memberof Storage
   */
  toString () {
    return `Storage ${ JSON.stringify(
      this.toJSON(),
      null,
      '\t',
    ) }`;
  }

  /**
   * Remove Filter item from Storage
   *
   * @returns {undefined}
   * @memberof Storage
   */
  unfilter ({ id, match, }) {
    const index = this.index(fmatch({ id, match, }));

    return this.unitem(index);
  }

  /**
   * Remove all Filter items from Storage
   *
   * @returns {undefined}
   * @memberof Storage
   */
  unfilters () {
    return this.unitems(_ => !isFilter(_));
  }

  /**
   * Remove Order item from Storage
   *
   * @returns {undefined}
   * @memberof Storage
   */
  unorder ({ id, match, }) {
    const index = this.index(omatch({ id, match, }));

    return this.unitem(index);
  }

  /**
   * Remove all Order items from Storage
   *
   * @returns {undefined}
   * @memberof Storage
   */
  unorders () {
    return this.unitems(_ => !isOrder(_));
  }

  /**
   * Get Filter item from Storage by id or match-function
   *
   * @readonly
   * @memberof Storage
   */
  get filter () {
    return ({ id, match, }) => {
      const index = this.index(fmatch({ id, match, }));

      return this.item(index);
    };
  }

  /**
   * Set Filter item to Storage
   *
   * @readonly
   * @arg {StorageItemFilter | StorageItemFilterData | Array} item - item to append or repalce
   * @memberof Storage
   */
  set filter (item) {
    if (isFilter(item)) {
      this.item = { item, match: fmatch, };
    } else if (Array.isArray(item)) {
      const [ id, $$, ] = item;

      this.item = { $$, id, match: fmatch, };
    } else if (item instanceof Object) {
      const children = [];
      const child = _ => children.push(_);
      const $$ = { ...item.$$, child, };
      const filter = new StorageItemFilter(item, this, $$);

      [ filter, ...children, ].forEach(_ => this.filter = _);
    }
  }

  /**
   * Get array of all Filter items from Storage
   *
   * @readonly
   * @memberof Storage
   */
  get filters () {
    return this.items.filter(isFilter);
  }

  /**
   * Get amount of Filter item in Storage
   *
   * @readonly
   * @memberof Storage
   */
  get filtersAmount () {
    return this.filters.length;
  }

  /**
   * Get this.toJSON() as property for debug
   *
   * @readonly
   * @memberof Storage
   */
  get json () {
    return this.toJSON();
  }

  /**
   * Get Order item from Storage by id or match-function
   *
   * @readonly
   * @memberof Storage
   */
  get order () {
    return ({ id, match, }) => {
      const index = this.index(omatch({ id, match, }));

      return this.item(index);
    };
  }

  /**
   * Set Order item to Storage
   *
   * @readonly
   * @arg {StorageItemOrder | StorageItemOrderData | Array} item - item to append or repalce
   * @memberof Storage
   */
  set order (item) {
    if (isOrder(item)) {
      this.item = { item, match: omatch, };
    } else if (Array.isArray(item)) {
      const [ id, $$, ] = item;

      this.item = { $$, id, match: omatch, };
    } else if (item instanceof Object) {
      const children = [];
      const child = _ => children.push(_);
      const $$ = { ...item.$$, child, };
      const order = new StorageItemOrder(item, this, $$);

      [ order, ...children, ].forEach(_ => this.order = _);
    }
  }

  /**
   * Get array of all Order items from Storage
   *
   * @readonly
   * @memberof Storage
   */
  get orders () {
    return this.items.filter(isOrder);
  }

  /**
   * Get amount of Order item in Storage
   *
   * @readonly
   * @memberof Storage
   */
  get ordersAmount () {
    return this.orders.length;
  }

}