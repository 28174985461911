import React from "react";
import Select from 'react-select';

const customStyles = {
    control: () => ({
        width: 100,
    }),
    singleValue: (base, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';

        return { opacity, transition };
    },
};

class StatusSelect extends React.Component {
    constructor (props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount () {
        if (this.props.getData) {
            this.props.getData(this.props.translate);
        }
    }

    handleChange (value) {
        this.props.setToStateSelect(value, this.props.name);
    }

    render () {

        return (
            <div className = 'table-filter-select'>
                <Select
                    isSearchable = {false}
                    className = {`task-select page-select status-${this.props.error('state_id')}`}
                    classNamePrefix = 'select'
                    placeholder = {this.props.translate(`tasks_list_select`)}
                    defaultValue = { this.props.defaultValue }
                    name = { this.props.name }
                    onChange = { this.handleChange }
                    options = { this.props.data }
                    styles = { customStyles }
                />
            </div>

        );
    }
}

export default StatusSelect;
