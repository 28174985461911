import {shape, string, number, oneOfType, } from 'prop-types';

const TASK_PROP_TYPES = shape({
    core: shape({
        account: shape({
            addresses: string,
            birth_date: string,
            city_id: number,
            country_id: number,
            created_at: string,
            description: string,
            email: string,
            enn: string,
            gender: oneOfType([string, number]),
            id: number,
            is_brand_lawyer: oneOfType([ number, string ]),
            is_email_verify: oneOfType([ number, string ]),
            is_have_demo: oneOfType([ number, string ]),
            is_phone_verify: oneOfType([ number, string ]),
            is_verify_document: oneOfType([ number, string ]),
            language_id: number,
            library_card: string,
            lk_old_id: string,
            lk_old_user_id: string,
            main_account_id: number,
            manager_id: number,
            name: string,
            partner_id: number,
            password: string,
            patronymic: string,
            phone: string,
            processed: string,
            register_type: oneOfType([ number, string ]),
            remember_token: number,
            status: oneOfType([ number, string ]),
            surname: string,
            system_type: string,
            time_zone_id: number,
            token: string,
            trader_diary: oneOfType([ number, string ]),
            updated_at: string,
            warm_type: oneOfType([ number, string ]),
        }),
    }),
    tasks: shape({
        task_states: shape({
            alias: string,
            event: string,
            id: number,
            name_en: string,
            name_ru: string,
            name_uk: string,
        }),
        task_types: shape({
            alias: string,
            event: string,
            id: number,
            name_en: string,
            name_ru: string,
            name_uk: string,
        }),
        task_urgency: shape({
            alias: string,
            event: string,
            id: number,
            name_en: string,
            name_ru: string,
            name_uk: string,
        }),
        tasks: shape({
            client_id: number,
            created_at: string,
            description: string,
            finish_date: string,
            id: number,
            name: string,
            state_id: number,
            status: oneOfType([ number, string ]),
            type_id: number,
            updated_at: string,
            urgency_id: number,
            work_status: string,
        }),
    }),
});

export {TASK_PROP_TYPES};