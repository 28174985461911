import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import Preloader from "../../../components/LoadingHOC/Preloader";
import Loader from "./Loader";
import PermissionService from "../../../services/PermissionService";
import { Link } from "react-router-dom";
import { MagicConfirm } from "./Magic/MagicConfirm";
import { MagicTextarea } from "./Magic/MagicTextarea";
import NotificationService from "../../../services/NotificationService";
import { timaService } from "../../../services/TimaDataService";

class OneTimaCondition extends Component {
    constructor (props) {
        super(props);

        let id = null;

        if (this.props.params && this.props.params.conditionId) {
            id = +this.props.params.conditionId;
        }

        this.state = {
            loaded:                false,
            condition: {
                data: {},
                hash: null,
            },
            id,
            magicEditValuePrev:    {},
            magicEditValueCurrent: {},
            magicEditSelectionPos: {},
            confirm:               false,
            editMode:              false,
        };
    }

    save = async (state) => new Promise(next => this.setState(state, next));

    conditionChanges = async (condition) => {
        if (condition.hash===this.state?.condition?.hash) return `${this.constructor.name}.conditionChanges: false`;
        if (condition.conditionId !== this.state.id) return `${this.constructor.name}.conditionChanges: false`;
        const { data, hash } = condition;
        await this.save({
            condition: { data, hash },
        });

        return `${this.constructor.name}.conditionChanges: true`;
    };

    componentDidMount = async () => {
        timaService.subscribe('condition', this.conditionChanges, this);
        try {
            const { data, hash, } = await timaService.condition(this.state.id);
            const condition = { data, hash, };
            await this.save({ loaded: true, condition, });
        } catch (error) {
            error?.showErrorNotification?.();
        }
    };

    componentWillUnmount = () => {
        timaService.unsubscribe('condition', this.conditionChanges, this);
    };

    get OneTimaConditionConfig () {
        const { translate, } = this.props;
        const prefix = 'tima_conditions_table_';

        return [
            {
                path:   ['tima', 'tima_condition', 'version'],
                key:    'timaConditionVersion',
                render: this.renderBlockCont(),
                title:  `${prefix}version`,
            },
            {
                path:   ['tima', 'tima_condition', 'name'],
                key:    'timaConditionName',
                render: this.renderBlockCont(),
                title:  `${prefix}tima_condition`,
            },
            {
                path:   ['tima', 'tima_condition', 'invest_min'],
                key:    'timaConditionInvestMin',
                render: this.renderBlockCont({ fieldName: 'invest_min' }),
                title:  `${prefix}invest_min`,
            },
            {
                path:   ['tima', 'tima_condition', 'invest_max'],
                key:    'timaConditionInvestMax',
                render: this.renderBlockCont({ fieldName: 'invest_max' }),
                title:  `${prefix}invest_max`,
            },
            {
                path:   ['tima', 'tima_condition', 'min_invest_period'],
                key:    'timaConditionMinInvestPeriod',
                render: this.renderBlockCont({ fieldName: 'min_invest_period' }),
                title:  `${prefix}min_invest_period`,
            },
            {
                path:   ['tima', 'tima_condition', 'penalty'],
                key:    'timaConditionPenalty',
                render: this.renderBlockCont({ fieldName: 'penalty' }),
                title:  `${prefix}penalty`,
            },
            {
                path:   ['tima', 'tima_condition', 'reward'],
                key:    'timaConditionReward',
                render: this.renderBlockCont({ fieldName: 'reward' }),
                title:  `${prefix}reward`,
            },
            {
                accessChecked: true,
                path:          ['tima', 'tima_condition', 'status'],
                key:           'timaConditionStatus',
                render:        this.renderBlockCont({
                    isEnum:   true,
                    enumPath: 'tima',
                    enumName: 'TimaConditionStatusEnum'
                }),
                title:         `${prefix}status`,
            },
            {
                path: ['tima', 'tima_manager', 'id'],
                key:  'timaManagerId',
            },
            {
                path:   ['tima', 'tima_manager', 'name'],
                key:    'timaManagerName',
                render: this.renderBlockContLink({ path: '/tima/strategy' }),
                title:  `${prefix}tima_account`,
            },
            {
                path:   ['tima', 'tima_condition', 'created_at'],
                key:    'timaConditionCreatedAt',
                render: this.renderBlockCont(),
                title:  `${prefix}date_creation`,
            },
            {
                path:   ['tima', 'tima_condition', 'updated_at'],
                key:    'timaConditionUpdatedAt',
                render: this.renderBlockCont(),
                title:  `${prefix}date_closing`,
            },
        ].map(({ title: t, xtitle: x, ...item }) => ({ ...item, ...x && { title: x }, ...t && { title: translate(t) }, }));
    }

    renderBlockCont = ({ fieldName = '', isEnum = false, enumPath = null, enumName = null } = {}) => (value, { item, items }) => {
        const title = item?.options?.title;
        const key = item?.key;
        const defaultValue = '-';

        let targetValue;

        switch (true) {
            case item?.access?.('show'): {
                if (isEnum) {
                    const enums = this.props?.enums?.[enumPath]?.[enumName];
                    targetValue = enums?.[value] ?? defaultValue;
                } else {
                    targetValue = value ?? defaultValue;
                }
                break;
            }
            default: {
                targetValue = defaultValue;
                break;
            }
        }

        return this.renderBlockContFragment({ fieldName, key, title, targetValue, value, item, items });
    };

    renderBlockContLink = ({ path = null } = {}) => (value, { item, items }) => {
        const id = items?.timaManagerId;
        const title = item?.options?.title;
        const key = item?.key;
        const defaultValue = '-';

        let targetValue;
        let blockUrl;

        switch (true) {
            case id?.access?.('show') && item?.access?.('show'): {
                targetValue = value ?? defaultValue;
                blockUrl = !isNaN(parseInt(`${id?.valueOf}`)) ? `${path}/${ id?.valueOf }` : null;
                break;
            }
            case item?.access?.('show'): {
                targetValue = value ?? defaultValue;
                blockUrl = null;
                break;
            }
            default: {
                targetValue = defaultValue;
                blockUrl = null;
                break;
            }
        }

        return this.renderBlockContFragment({ key, title, targetValue, blockUrl });
    };

    renderBlockContFragment = ({ key, title, targetValue, fieldName, blockUrl, value, item, items }) => {
        const targetOption = fieldName === 'client' ? '_self' : '_blank';

        return (
            <div className="block-cont" key={`block-cont-${key}`}>
                <div className="block">
                    <div className="block-header full-cont">
                        <div className="full-cont__item">
                            {blockUrl ?
                                (
                                    <Link to={`${blockUrl}`} className='link-btn-cont' target={targetOption}>
                                        <span className='block__name link-btn'>
                                            {title}
                                        </span>
                                    </Link>
                                ) :
                                (
                                    <span className="block__name">{title}</span>
                                )
                            }

                        </div>
                        <div className="full-cont__item">
                            {/*<span className="block__info">{targetValue}</span>*/}
                            {this.renderBlockInfoFragment({ fieldName, targetValue, value, item, items })}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderBlockInfoFragment = ({ fieldName, targetValue, value, item, items }) => {
        const { translate } = this.props;

        switch (fieldName) {
            case 'invest_min':
            case 'invest_max':
            case 'min_invest_period':
            case 'penalty':
            case 'reward': {
                const valueFromStatePrev = this.state?.magicEditValuePrev?.[fieldName] ?? value;
                const valueFromStateCurrent = this.state?.magicEditValueCurrent?.[fieldName] ?? value;
                const isVisible = this.confirmCheck(fieldName);
                const isEditMode = this.editModeCheck(fieldName);

                if (item?.access?.('show', 'update')) {
                    return (
                        <div className='magic-edit-mode'>
                            <span className='block__info edit-btn'
                                  onClick={this.onEnterEditMode(fieldName)}
                            >
                                {valueFromStatePrev}
                            </span>

                            {isEditMode ?
                                (
                                    <div
                                        className="magic-confirm-wrapper magic-confirm-wrapper--large-width magic-confirm-wrapper--textarea">
                                        <i className="icon-bg icon-bg--success save-btn"
                                           onClick={
                                               (event) => {
                                                   this.setConfirm([fieldName, valueFromStateCurrent]);
                                               }
                                           }
                                        />

                                        <MagicConfirm
                                            onAccept={this.confirmAcceptHandler(fieldName, valueFromStatePrev)}
                                            onReject={this.confirmRejectHandler(fieldName, valueFromStatePrev)}
                                            title={translate(`magic_confirm_title`)}
                                            accept={translate(`magic_confirm_yes`)}
                                            reject={translate(`magic_confirm_no`)}
                                            isVisible={!!isVisible}
                                        />

                                        <MagicTextarea
                                            className={'magic-textarea magic-textarea--auto-height scroll'}
                                            value={valueFromStateCurrent.toString()}
                                            onChange={this.onChangeTextarea(fieldName)}
                                            selectionStart={this.state?.magicEditSelectionPos?.[fieldName]?.[0] ?? value?.toString()?.length ?? 0}
                                            selectionEnd={this.state?.magicEditSelectionPos?.[fieldName]?.[1] ?? value?.toString()?.length ?? 0}
                                        />

                                        <i className="gl-icon close-btn"
                                           onClick={this.onClickCloseConfirmBtn(fieldName, valueFromStatePrev)}
                                        />
                                    </div>
                                ) : ''}
                        </div>
                    );
                } else if (item?.access?.('show')) {
                    return (
                        <div>
                            <span className='block__info'>
                                {valueFromStateCurrent}
                            </span>
                        </div>
                    );
                }
                return '-';
            }
            default: {
                return (
                    <span className="block__info">{targetValue}</span>
                );
            }
        }
    };

    renderTitle (title) {
        return (
            <div className='title-cont'>
                <h3 className='title'>{title}</h3>
            </div>
        );
    }

    renderInformationCont (fieldsArray, castData) {
        const { translate, params, query } = this.props;

        return (
            <div className='information-cont'>
                {
                    fieldsArray.map((item, i) => {
                        return this.renderInformationContItem(item, i, castData);
                    })
                }
            </div>
        );
    }

    renderInformationContItem (fieldsPart, i, castData) {
        return (
            <div className='information-cont__item' key={`information-cont__item-${i}`}>
                <div className='blocks-wrapper'>
                    {
                        fieldsPart.map((item, i) => {
                            return (
                                <React.Fragment key={`key-${i}`}>
                                    {castData?.[item] ?? false}
                                </React.Fragment>
                            );
                        })
                    }
                </div>
            </div>
        );
    }

    isTextareaFieldCheck = (fieldName) => {
        const textareaArray = [
            'invest_min',
            'invest_max',
            'min_invest_period',
            'penalty',
            'reward',
        ];

        return textareaArray.indexOf(fieldName) !== -1;
    };

    returnToPrevStateTextarea = async (fieldName, valueFromStatePrev) => {
        const magicEditValuePrev = this.state?.magicEditValuePrev?.[fieldName] ?? valueFromStatePrev;
        const valuePrevLength = magicEditValuePrev?.toString()?.length ?? 0;
        const state = {
            magicEditValueCurrent: {
                ...this.state.magicEditValueCurrent,
                [fieldName]: magicEditValuePrev,
            },

            magicEditSelectionPos: {
                ...this.state.magicEditSelectionPos,
                [fieldName]: [valuePrevLength, valuePrevLength],
            },
        };
        await this.save(state);
    };

    onClickCloseConfirmBtn = (fieldName, valueFromStatePrev) => async (event) => {
        await this.setEditMode(false);
        await this.setConfirm(false);

        if (this.isTextareaFieldCheck(fieldName)) {
            await this.returnToPrevStateTextarea(fieldName, valueFromStatePrev);
        }
    };

    onChange = (fieldName) => async ({ value, scrollPosY }) => {
        await this.setConfirm([fieldName, value, scrollPosY]);
    };

    onChangeTextarea = (fieldName) => async ({ value, selectionStart, selectionEnd }) => {
        const state = {
            magicEditValueCurrent: {
                ...this.state.magicEditValueCurrent,
                [fieldName]: value,
            },
            magicEditSelectionPos: {
                ...this.state.magicEditSelectionPos,
                [fieldName]: [selectionStart, selectionEnd],
            },
        };
        await this.save(state);
    };

    onEnterEditMode = (fieldName) => async () => {
        await this.setEditMode([fieldName]);
    };

    confirmAcceptHandler = (fieldName, valuePrev) => async (event) => {
        const confirm = this.confirmCheck(fieldName);
        if (!confirm) {
            await this.setConfirm(false);
            return;
        }
        const value = confirm[1];
        const conditionId = this.state?.condition.data?.tima?.tima_condition?.id;

        try {
            const fieldMap = fieldName === "tima_account" ? 'name' : fieldName;
            const data = { [fieldMap]: value };
            const { response } = await timaService.updateCondition(conditionId, data);

            if (!response) {
                throw new Error(`Can*t update the fieldName: '${fieldName}', please try again later!`);
            }

            let newValue;

            switch (fieldName) {
                case 'invest_min':
                case 'invest_max':
                case 'min_invest_period':
                case 'penalty':
                case 'reward': {
                    newValue = value;
                    break;
                }

                default: {
                    newValue = +value;
                    break;
                }
            }

            const state = {
                magicEditValuePrev: {
                    ...this.state.magicEditValuePrev,
                    [fieldName]: newValue,
                },
            };
            await this.save(state);

        } catch (error) {
            NotificationService.error({
                title:   "error",
                message: error.message,
                remove:  false,
            });

            if (this.isTextareaFieldCheck(fieldName)) {
                await this.returnToPrevStateTextarea(fieldName, this.state?.magicEditValuePrev?.[fieldName] ?? valuePrev);
            }
        }
        await this.setConfirm(false);
        await this.setEditMode(false);
    };

    confirmRejectHandler = (fieldName, valuePrev) => async (event) => {
        await this.setConfirm(false);
        await this.setEditMode(false);
    };

    confirmCheck = (fieldName) => {
        const { confirm } = this.state;
        return confirm instanceof Array && confirm[0] === fieldName ? confirm : false;
    };

    editModeCheck = (fieldName) => {
        const { editMode } = this.state;
        return editMode instanceof Array && editMode[0] === fieldName ? editMode : false;
    };

    setConfirm = async (confirm) => {
        await this.save((state) => ({ ...state, confirm }));
    };

    setEditMode = async (editMode) => {
        await this.save((state) => ({ ...state, editMode }));
    };

    render () {
        const { fieldsForChecking, translate, enums, scale } = this.props;
        const { condition: { data }, loaded } = this.state;
        const castData = PermissionService.cast(data, this.OneTimaConditionConfig);

        return (
            <Loader
                loaded={loaded && !!enums}
                loading={(<Preloader scale={scale}/>)}
                translate={translate}
            >
                <div className='glalex-styles tima'>
                    {this.renderTitle(data?.tima?.tima_condition?.name)}

                    {this.renderInformationCont(fieldsForChecking, castData)}
                </div>
            </Loader>
        );
    }
}

OneTimaCondition.defaultProps = {
    fieldsForChecking: [
        [
            'timaConditionVersion',
            'timaConditionName',
            'timaConditionInvestMin',
            'timaConditionInvestMax',
        ],
        [
            'timaConditionMinInvestPeriod',
            'timaConditionPenalty',
            'timaConditionReward',
            'timaConditionStatus',
        ],
        [
            'timaManagerName',
            'timaConditionCreatedAt',
            'timaConditionUpdatedAt',
        ],
    ],

    /*editableFields:         [
        'invest_min',
        'invest_max',
        'min_invest_period',
        'penalty',
        'reward',
    ],
    editableTextareaFields: [
        'invest_min',
        'invest_max',
        'min_invest_period',
        'penalty',
        'reward',
    ],*/
};

export default withLocalize(OneTimaCondition);
