import { isEmpty, map } from 'lodash';
import React, { Component } from 'react';
import { Collapse, Button, Table } from 'reactstrap';
import dateFns from "date-fns";

const INSERT_DATA = 'insert_data';
const UPDATE_DATA = 'update_data';
const DELETE_DATA = 'delete_data';

export default class HistoryChangesComponentRow extends Component {
    constructor (props) {
        super(props);

        this.state = {
            collapse: false,
        };
    }

    toggle = () => {
        this.setState((state) => ({
            collapse: !state.collapse,
        }));
    };

    getInformationButton = (key) => {
        if (!isEmpty(this.props.item[key])) {
            return <div>
                <Button className = 'history-changes-component-row-info btn-sm' color = 'primary' onClick = { this.toggle }>
                    <i className = 'la la-angle-down' /> info <i className = 'la la-angle-down' />
                </Button>
            </div>;
        }

        return null;
    };

    getInformation = (key) => {
        if (!isEmpty(this.props.item[key])) {
            return <div>
                <Collapse className = 'history-changes-component-row-collapse' isOpen = { this.state.collapse }>
                    <Table className = 'table-stripped'>
                        <tbody>
                        {map(this.props.item[key], (item, key) => {
                            return (<tr>
                                <td>{ key }</td>
                                <td>{ item }</td>
                            </tr>);
                        })}
                        </tbody>
                    </Table>
                </Collapse>
            </div>;
        }

        return null;
    };

    render () {
        const date = dateFns.format(new Date(this.props.item['created_at']), 'DD-MM-YYYY HH:mm:ss');

        const hiddenIcon = <i className="fa fa-eye-slash" aria-hidden="true" style={{ fontSize: '20px' }}></i>;
        const deleteIcon = <i className="fa fa-trash-o" aria-hidden="true" style={{ fontSize: '20px', color: 'red' }}></i>;
        const oldValue = this.props.item['old'] === 'hidden' ? hiddenIcon : this.props.item['old'];
        const newValue = (this.props.item['new'] === '' ) ? deleteIcon : (this.props.item['new'] === 'hidden') ? hiddenIcon : this.props.item['new'];

        return (
            <React.Fragment>
                <tr>
                    <td>{ date }</td>
                    <td>
                        { this.props.item['author']['model_attributes']['name'] } &nbsp;
                        { this.props.item['author']['model_attributes']['surname'] }
                    </td>
                    <td>
                        { this.props.item['field'] }
                    </td>
                    <td>
                        { oldValue }

                        { this.getInformationButton(DELETE_DATA) }
                    </td>
                    <td>
                        { newValue }

                        { this.getInformationButton(INSERT_DATA) }
                        { this.getInformationButton(UPDATE_DATA) }
                    </td>
                    <td>
                        {this.props.item['message']}
                    </td>
                </tr>
                <tr className = 'tr-info'>
                    <td colSpan = '5'>
                        { this.getInformation(INSERT_DATA) }
                        { this.getInformation(UPDATE_DATA) }
                        { this.getInformation(DELETE_DATA) }
                    </td>
                </tr>
            </React.Fragment>
        );
    }
}
