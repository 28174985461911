import { DateTime, } from 'luxon';
import { operators as allOperators, } from '../Operators';
import { TYPE, } from '../Options';
import { valid, } from './StorageItemFilter.valid';

/**
 * Structure for creation of StorageItemFilter
 *
 * @typedef {Object} StorageItemFilterData - Setting for StorageItemFilter
 * @property {Object} $ - {
 *   [enabled: Number(0, 1),]
 *   [group: String,]
 *   id: String,
 *   type: String("filter"),
 * }
 * @property {Number(0, 1)} [enabled=1] - Apply status
 * @property {String | String[]} f - Filter by field
 * @property {String} o - Filter with operator
 * @property {null | Date | Datetime | Number | String | Array} v - Filter value
 * @property {String("n", "y")} [j="n"] - Filter in join [from Config!]
 * @property {URL} ['drop-down'] - URL for drop-down data  [from Options!]
 * @property {Number(0, 1)} [having] - Filter in having [from Options!]
 * @property {Number(0, 1)} [sub_where] - Filter in sub_where [from Options!]
 * @property {Object | Object[]} [fields] - Child filter
 * @property {Object} [...item={}]
 */

/**
 * @class
 */
export class StorageItemFilter {

  /**
   * Creates an instance of StorageItemFilter.
   *
   * @arg {StorageItemFilterData} data - StorageItemFilter settings
   * @arg {Storage} storage - Storage instance (options.fields, filter(id))
   * @arg {Object} $$ -
   * @memberof StorageItemFilter
   */
  constructor (
      data,
      storage,
      $$ = {
        changed: null,
        child: null,
        fields: null,
        inited: null,
        parentId: null,
      },
  ) {
    valid(data, storage, $$);

    Object.defineProperties(this, {
      config: { enumerable: true, get: () => storage.config, },
      data: { enumerable: true, get: () => data, },
      mentor: { enumerable: true, get: () => storage.mentor, },
      options: { enumerable: true, get: () => storage.options, },
      storage: { enumerable: true, get: () => storage, },
      toObject: { get: () => () => data, },
    });

    const {
      $, enabled, f, o, v,
      fields,
      ..._
    } = data;

    Object.freeze($);

    Object.defineProperties(this, {
      $: { enumerable: true, get: () => ({ ...$, }), },
      $$changed: { enumerable: true, get: () => $$.changed, },
      $$inited: { enumerable: true, get: () => $$.inited, },
      $$parentId: { enumerable: true, get: () => $$.parentId, },
      _: { enumerable: true, get: () => _, },
      // TODO: MagicFilterPanel check enabled = item.$enabled ?? item.enabled
      enabled: { enumerable: true, get: () => enabled, },
      f: { enumerable: true, get: () => f, },
      fields: { enumerable: true, get: () => $$.fields ?? fields, },
      o: { enumerable: true, get: () => o, },
      v: { enumerable: true, get: () => v, },
    });

    Object.freeze(this);

    // eslint-disable-next-line no-shadow
    const isField = _ => [ ...this.options.fields, ...this['f+'], ].includes(_);

    // check field in storage.fields
    if (Array.isArray(f) && !f.every(isField)) {
      throw new Error(`Expect every StorageItemFilter.f ${ f } in Storage.fields`);
    } else if (!Array.isArray(f) && !isField(f)) {
      throw new Error(`Expect StorageItemFilter.f ${ f } in Storage.fields`);
    }

    const isValue = type => (value) => {
      const format1 = 'yyyy-MM-dd';
      const format2 = 'yyyy-MM-dd HH:mm:ss';
      // eslint-disable-next-line no-shadow
      const typeOf = (value, ...types) => types.includes(typeof value);

      if (value === null) {
        return true;
      } else if ([ TYPE.date, ].includes(type)) {
        if (value instanceof Date) {
          return true;
        } else if (typeOf(value, 'string')) {
          return DateTime.fromFormat(value, format1).isValid;
        }
      } else if ([ TYPE.datetime, ].includes(type)) {
        if (value instanceof Date) {
          return true;
        } else if (typeOf(value, 'string')) {
          return DateTime.fromFormat(value, format2).isValid;
        }
      } else if ([ TYPE.enum, ].includes(type)) {
        return typeOf(value, 'string'); // && Number.isInteger(+value);
      } else if ([ TYPE.id, ].includes(type)) {
        return typeOf(value, 'number', 'string') && Number.isInteger(+value);
      } else if ([ TYPE.number, ].includes(type)) {
        return typeOf(value, 'number', 'string') && Number.isFinite(+value);
      } else if ([ TYPE.string, ].includes(type)) {
        return typeof value === 'string';
      }

      return false;
    };

    // check v by type
    if (Array.isArray(v) && !v.every(isValue(this.type))) {
      throw new Error(`Expect every StorageItemFilter.v ${ v } as Value`);
    } else if (!Array.isArray(v) && !isValue(this.type)(v)) {
      throw new Error(`Expect StorageItemFilter.v ${ v } as Value`);
    }

    if ($$.fields) {
      // clone creation
    } else if (!fields) {
      // on import only! creation without children
    } else if (!$$.child) {
      throw new Error(`Expect StorageItemFilter(..., ..., {child,}) as Function`);
    } else {
      // on import only! creation with children
      const parentId = $.id;
      const { changed, inited, } = $$;
      // eslint-disable-next-line no-shadow
      const child = _ => new StorageItemFilter(_, storage, {
        changed,
        inited,
        parentId,
      });
      const children = Array.isArray(fields) ? fields : [ fields, ];

      children.map(child).map($$.child);
    }
  }

  /**
   * Create new StorageItemFilter instance with chaged state
   *
   * @arg {Object} setter={} - changed properties
   * @arg {String[]} unsetter=[] - removed properties
   * @arg {Object} $$ -
   * @returns {StorageItemFilter} Clone
   * @memberof StorageItemFilter
   */
  $$ (setter = {}, unsetter = [], $$ = {}) {
    const clone = this.toClone();
    const mix = Object.entries({ ...clone, ...setter, })
      .filter(([ key, ]) => !unsetter.includes(key))
      .map(([ key, value, ]) => ({ [key]: value, }));
    const data = Object.assign({}, ...mix);

    return new StorageItemFilter(data, this.storage, {
      changed: this.$$changed,
      fields: this.fields,
      inited: this.$$inited,
      parentId: this.$$parentId,
      ...$$,
    });
  }

  /**
   * Shugar access to filter config by key
   *
   * @arg {String} key - Key in Config
   * @returns {*} Config value
   * @memberof StorageItemFilter
   */
  cfg (key) {
    const filter = this.config.filter(this.$id);
    const data = filter?.[key];
    return data;
  }

  /**
   * Shugar access to filter options by field and key
   *
   * @arg {String} key - Key in Field Options
   * @arg {String | String[]} field - Field(s) in Options
   * @returns {*} Options value
   * @memberof StorageItemFilter
   */
  opt (key, field) {
    const opt = f => {
      const field = this.options.field(f);
      const data = field?.[key];
      return data;
    };

    if (Array.isArray(field)) {
      // TODO: if this.f is Array return Object<field, option> - is it right ?
      return Object.assign({}, ...field
        .map(f => [ f, opt(f), ])
        // eslint-disable-next-line no-shadow, no-unused-vars
        .filter(([ f, opt, ]) => ![ undefined, ].includes(opt))
        // eslint-disable-next-line no-shadow
        .map(([ f, opt, ]) => ({ [f]: opt, })),
      );
    }

    return opt(field);

  }

  /**
   * Return changable part of StorageItemFilter for cloning
   *
   * @returns {JSON} Plain clone (recursive)
   * @memberof StorageItemFilter
   */
  toClone () {
    // const deepClone = (_) => {
    //   if ([ 'boolean', 'number', 'string' ].includes(typeof _)) {
    //     return _;
    //   } else if (_ instanceof Function) {
    //     return _;
    //   } else if (_ instanceof Object) {
    //     const clone = ([k, v]) => ({ [k]: deepClone(v), });
    //     return Object.assign({}, ...Object.entries(_).map(clone));
    //   }
    //   return _;
    // };

    const enabled = this.$enabled;
    const fields = this.fields;
    const group = this.$group;
    const id = this.$id;
    const type = this.$type;

    return {
      ...this._,
      // eslint-disable-next-line object-curly-newline
      $: { id, type, ...enabled && { enabled, }, ...group && { group, }, },
      enabled: this.enabled,
      f: this.f,
      o: this.o,
      v: this.v,
      ...fields && { fields, },
    };
  }

  /**
   * Exporting StorageItemFilter to be stored to DB
   *
   * @returns {JSON} Export clone
   * @memberof StorageItemFilter
   */
  toJSON () {
    const enabled = this.$enabled;
    const fields = this.$$fields;
    const group = this.$group;
    const having = this.having;
    const id = this.$id;
    const sub_where = this.sub_where;
    const type = this.$type;

    return {
      // eslint-disable-next-line object-curly-newline
      $: { id, type, ...enabled && { enabled, }, ...group && { group, }, },
      enabled: this.enabled,
      f: this.f,
      o: this.o,
      v: this.v,
      // eslint-disable-next-line sort-keys
      j: this.j,
      ...fields && { fields, },
      ...[ 0, 1, ].includes(having) && { having, },
      ...[ 0, 1, ].includes(sub_where) && { sub_where, },
    };
  }

  /**
   * Cast StorageItemFilter to String
   *
   * @returns {String} Cast to String
   * @memberof StorageItemFilter
   */
  toString () {
    const $$childId = this.$$childId;
    const $$parentId = this.$$parentId;
    const dropDown = this['drop-down'];
    const enums = this.enums;
    const o_equal = this['o='];
    const o_minus = this['o-'];
    const o_plus = this['o+'];

    return `StorageItemFilter ${ JSON.stringify({
      ...this.toJSON(),
      Operator: this.Operator,
      amountOfValues: this.amountOfValues,
      empty: this.empty,
      filled: this.filled,
      filling: this.filling,
      object: this.toObject(),
      operators: this.operators,
      type: this.type,
      types: this.types,
      ...$$childId && { $$childId, },
      ...$$parentId && { $$parentId, },
      ...dropDown && { 'drop-down': dropDown, },
      ...enums && { enum: this.enum, enums, },
      ...o_plus && { 'o+': o_plus, },
      ...o_equal && { 'o=': o_equal, },
      ...o_minus && { 'o-': o_minus, },
    }, null, '\t') }`;
  }

  /**
   * Get child filter if is one
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get $$child () {
    const $$childId = this.$$childId;
    const childById = id => this.storage.filter({ id, });

    if (Array.isArray($$childId)) {
      return $$childId.map(childById).filter(_ => _);
    } else if ($$childId) {
      return childById($$childId);
    }

    return undefined;
  }

  /**
   * Get child filter id if is one
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get $$childId () {
    if (Array.isArray(this.fields)) {
      return this.fields.map(_ => _.$.id)?.filter(_ => _);
    } else if (this.fields instanceof Object) {
      return this.fields.$.id;
    }

    return undefined;
  }

  /**
   * Get fields as plain Clone or plain Clone[] of StorageItemFilter
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get $$fields () {
    const $$child = this.$$child;

    if (Array.isArray($$child)) {
      return $$child.map(_ => _.toJSON()); // return $$child.map(_ => _.toClone());
    } else if ($$child instanceof StorageItemFilter) {
      return $$child.toJSON(); // return $$child.toClone();
    }

    return undefined;
  }

  /**
   * Get filter parent if is one
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get $$parent () {
    const id = this.$$parentId;

    return this.storage.filter({ id, });
  }

  /**
   * Get filter enabled if empty item
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get $enabled () {
    return this.$.enabled ?? 0;
  }

  /**
   * Get group name
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get $group () {
    return this.$.group;
  }

  /**
   * Get filter id to be stored to DB
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get $id () {
    return this.$.id;
  }

  /**
   * Get filter const type "filter" to be stored to DB
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get $type () {
    return this.$.type;
  }

  /**
   * Get Operator instance by operator
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get Operator () {
    return allOperators.find(this.o);
  }

  /**
   * Get value length by operator
   * x
   * @readonly
   * @memberof StorageItemFilter
   */
  get amountOfValues () {
    return this.Operator?.amountOfValues;
  }

  /**
   * Get auto status
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get auto () {
    return this.cfg('auto') ?? 0;
  }

  /**
   * Get this.toClone() as property for debug
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get clone () {
    return this.toClone();
  }

  /**
   * Get drop-down URL
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get ['drop-down'] () {
    return this.cfg('drop-down') ?? this.opt('drop-down', this.f);
  }

  /**
   * Check filter is empty
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get empty () {
    const empty = _ => _ === null;

    switch (this.amountOfValues) {
      case 0: return false;
      case 1: return !Array.isArray(this.v) && empty(this.v);
      case Infinity:
      case 2: return Array.isArray(this.v) && this.v.every(empty);
      default: return true;
    }
  }

  /**
   * Get enum path
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get enum () {
    return this.cfg('enum') ?? this.opt('enum', this.f);
  }

  /**
   * Get enums mapping
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get enums () {
    return this.cfg('enums') ?? this.opt('enums', this.f);
  }

  /**
   * Used in this.options.fields to append some elemnts on return
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get ['f+'] () {
    return this.cfg('f+') ?? [];
  }

  /**
   * Get filter field(s) copy
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get field () {
    return Array.isArray(this.f) ? [ ...this.f, ] : this.f;
  }

  /**
   * Check filter is full filled
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get filled () {
    const filled = _ => _ !== null;

    switch (this.amountOfValues) {
      case 0: return true;
      case 1: return !Array.isArray(this.v) && filled(this.v);
      case Infinity:
      case 2: return Array.isArray(this.v) && this.v.every(filled);
      default: return false;
    }
  }

  /**
   * Check filter is half filled for multi values & full filled for single value
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get filling () {
    const filled = _ => _ !== null;

    switch (this.amountOfValues) {
      case Infinity:
      case 2: return Array.isArray(this.v) && this.v.some(filled);
      default: return this.filled;
    }
  }

  /**
   * Get having flag
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get having () {
    return this.cfg('having') ?? this.opt('having', this.f);
  }

  /**
   * Get j flag
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get j () {
    return this.cfg('j') ?? 'n';
  }

  /**
   * Get this.toJSON() as property for debug
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get json () {
    return this.toJSON();
  }

  /**
   * Used in this.operators to append some elemnts on return
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get ['o+'] () {
    return this.cfg('o+') ?? [];
  }

  /**
   * Used in this.operators to replace the return
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get ['o='] () {
    return this.cfg('o=') ?? [];
  }

  /**
   * Used in this.operators to filter some elemnts on resurn
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get ['o-'] () {
    return this.cfg('o-') ?? [];
  }

  /**
   * Get this.toObject() as property for debug
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get object () {
    return this.toObject();
  }

  /**
   * Get operator
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get operator () {
    return this.o;
  }

  /**
   * Get operators for filter
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get operators () {
    if (this['o='].length) {
      return this['o='];
    }

    // eslint-disable-next-line no-shadow
    const intersect = (_, o) => _.filter(_ => o.includes(_));
    const operators = Array.isArray(this.f)
      ? Object.values(this.opt('operators', this.f)).reduce(intersect)
      : this.opt('operators', this.f);
    const items = [ ...operators, ...this?.['o+'], ]
      .filter(_ => !this?.['o-']?.includes?.(_))
      .filter(_ => allOperators.aliases.includes(_));

    return [ ...new Set(items), ];
  }

  /**
   * Get sub_where flag
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get sub_where () {
    return this.cfg('sub_where') ?? this.opt('sub_where', this.f);
  }

  /**
   * Get field translate
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get translate () {
    return this.cfg('translate') ?? this.opt('translate', this.f);
  }

  /**
   * Get DB type
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get type () {
    const values = f => Object.values(this.opt('type', f));

    return this.cfg('type') ?? (
      Array.isArray(this.f)
        ? [ ...new Set(values(this.f)), ].sort().join('+')
        : this.opt('type', this.f)
    );
  }

  /**
   * Get Object that is mapping fields to types
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get types () {
    return this.cfg('type') ?? (
      Array.isArray(this.f)
        ? this.opt('type', this.f)
        : { [this.f]: this.opt('type', this.f), }
    );
  }

  /**
   * Get value(s) with cast to type(s)
   *
   * @readonly
   * @memberof StorageItemFilter
   */
  get value () {
    const castToDate = (value, format = 'yyyy-MM-dd HH:mm:ss') => {
      if (value === null) {
        return null;
      } else if (value instanceof Date) {
        return value;
      } else if (typeof value === 'string') {
        const moment = DateTime.fromFormat(value, format);

        return moment.isValid ? moment.toJSDate() : null;
      }

      return null;
    };

    const castToEnum = (value) => {
      if (value === null) {
        return false;
      } else if ([ 'number', 'string', ].includes(typeof value)) {
        return `${ value }`;
      }

      return false;
    };

    const castToId = (value) => {
      const isId = _ => Number.isInteger(_) && _>0;

      if (value === null) {
        return null;
      } else if ([ 'number', 'string', ].includes(typeof value) && isId(+value)) {
        return +value;
      } else if (value instanceof Number && isId(value)) {
        return value.valueOf();
      }

      return null;
    };

    const castToNumber = (value) => {
      if (value === null) {
        return null;
      } else if ([ 'number', 'string', ].includes(typeof value)) {
        return +value;
      } else if (value instanceof Number) {
        return value.valueOf();
      }

      return null;
    };

    const castToString = (value) => {
      if (value === null) {
        return '';
      } else if ([ 'number', 'string', ].includes(typeof value)) {
        return `${ value }`;
      }

      return '';
    };

    const castByType = type => (value) => {
      if ([ TYPE.date, ].includes(type)) {
        return castToDate(value, 'yyyy-MM-dd');
      } else if ([ TYPE.datetime, ].includes(type)) {
        return castToDate(value);
      } else if ([ TYPE.enum, ].includes(type)) {
        return castToEnum(value);
      } else if ([ TYPE.id, ].includes(type)) {
        return castToId(value);
      } else if ([ TYPE.number, ].includes(type)) {
        return castToNumber(value);
      } else if ([ TYPE.string, ].includes(type)) {
        return castToString(value);
      } else if ([ TYPE.enum, TYPE.id, ].join('+') === type) {
        return castToEnum(value);
      } else if ([ TYPE.enum, TYPE.number, ].join('+') === type) {
        return castToEnum(value);
      } else if ([ TYPE.enum, TYPE.string, ].join('+') === type) {
        return castToString(value);
      } else if ([ TYPE.id, TYPE.number, ].join('+') === type) {
        return castToNumber(value);
      } else if ([ TYPE.id, TYPE.string, ].join('+') === type) {
        return castToString(value);
      } else if ([ TYPE.number, TYPE.string, ].join('+') === type) {
        return castToString(value);
      } else if ([ TYPE.enum, TYPE.id, TYPE.number, ].join('+') === type) {
        return castToEnum(value);
      } else if ([ TYPE.enum, TYPE.id, TYPE.string, ].join('+') === type) {
        return castToString(value);
      } else if ([ TYPE.enum, TYPE.number, TYPE.string, ].join('+') === type) {
        return castToString(value);
      } else if ([
        TYPE.enum,
        TYPE.id,
        TYPE.number,
        TYPE.string,
      ].join('+') === type) {
        return castToString(value);
      }

      return null;
    };

    const cast = castByType(this.type);

    return Array.isArray(this.v) ? this.v.map(cast) : cast(this.v);
  }

}