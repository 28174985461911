import React                  from "react";
import {
   Dropdown,
   DropdownItem,
   DropdownMenu,
   DropdownToggle,
   Fade
}                             from 'reactstrap';
import { withRouter }         from 'react-router-dom';
import connect                from "react-redux/es/connect/connect";
import { bindActionCreators } from "redux";
import {
   // setEnumsTasks,
   // getSelectAccountModel,
   saveAllTask,
   getAllTaskUsers,
}                             from "../../services/TasksService";
import { getResponsibles }    from '../../services/TaskRequestService';
import classnames             from 'classnames';

import { map, has, isEmpty, isNull, upperFirst } from "lodash";
import { securedRequest }                        from "../../../../services/RequestService/index";
import { addHours, addDays, format }             from "date-fns";
import InitiatorBlock                            from "./InitiatorBlock";
import ResponsibleBlock                          from "./ResponsibleBlock";
import { withLocalize, Translate }               from "react-localize-redux";
import { Collapse }                              from 'reactstrap';
import ClientBlock                               from "./ClientBlock";
import StatusBlock                               from './StatusBlock';

import DelateTaskPopup       from "./DelateTaskPopup";
import SpectatorBlock        from "../NewTaskPage/SpectatorBlock";
import ButtonArea            from "./ButtonArea";
import NotificationService   from "../../../../services/NotificationService";
import clientColor           from "../../../../images/clients-gradient.svg";
import clientGrey            from "../../../../images/clients-grey.svg";
import TaskSpectatorModal    from "../NewTaskPage/TaskModal/TaskSpectatorModal";
import { getAppealsAccount } from "../../../clients/services/AccountService";
import { toggle }            from "../../services/TaskToggleService";
import { enumService } from "../../../../services/EnumDataService";
import { profileService } from "../../../../services/ProfileDataService";
import { userService } from "../../../../services/UserDataService";

class TaskCreateForm extends React.Component {
   constructor(props) {
      super(props);

      document.body.style.overflow = 'hidden';
      this.ROLE_1 = 1;
      this.ROLE_2 = 2;
      this.ROLE_3 = 3;
      this.ROLE_4 = 4;
      this.ROLE_5 = 5;

      this.spectator_user_id = [];
      this.spectator_user_obj = [];

      this.removeTypeConst = {
         client: "client",
         task: "task",
         document: "document",
         checkList: "checkList",
         comment: "comment",
      };
      this.state = {
         roleUser: 0,
         taskModal: false,
         editHead: false,
         editDescription: false,
         actionMenu: false,
         activeIndex: 0,
         collapse: false,
         removeType: "",
         removeDocumentId: null,
         checkList: null,
         dataTask: {
            name: '',
            description: '',
            type_id: this.props.quick ? '14' : "1",
            state_id: this.props.quick ? '1' : '',
            urgency_id: this.props.quick ? '2' : '',
            finish_date: addHours(new Date(), this.props.quick ? 2 : 1),
            initiator_id: "",
            responsible_id: this.props?.responsible?.id ?? '',
            spectator_ids: [],
            assistance_ids: [],
            // account_id: this.props.quick ? this.props.userId : null,
            account_id: this.props.userId ? +this.props.userId : null, // set account_id automatically without select, in case of opening from client page
         },
         dataTaskClient: {
            account_id: '',
         },
         createTaskSelect: {
            type_id: this.props.quick ? '14' : "1",
            state_id: this.props.quick ? '1' : '',
            urgency_id: this.props.quick ? '2' : '',
         },
         createParticipants: {
            task_id: null,
            user_id: '',
            role_id: '',
         },
         checkListTask: {
            task_id: null,
            name: '',
            check: '1',
         },
         participantsId: '',
         addCheckList: false,
         checklistEmpty: false,
         fadeIn: false,
         fadeInPop: false,
         fadeInWraper: false,
         clientName: 'Client',
         fieldClientName: 'account_id',
         usersAllAvatar: {
            hash: null,
            data: {},
         },
         errorForm: {},
         showHistoryChanges: false,
         getAllManager: {},
         selectPartipiants: {
            initiator_id: [],
            responsible_id: [],
         },
         getInitiator: {},
         getResponsible: [],
         getSpectator: [],
         checkReset: '',
         sortBtnChecklist: false,
         dropdownOpen: false,
         disabledSaveTask: false,
         getAllUsers: [],
      };

      // toggle.init(this);
   }

   save = async (state) => new Promise(next => this.setState(state, next));

   enumsChange = async (enums) => {
      if (enums.hash===this.state?.enums?.hash) return `${this.constructor.name}.enumsChange: false`;
      await this.save({ enums, });
      return `${this.constructor.name}.enumsChange: true`;
   };

   profileChange = async (profile) => {
      if (profile.hash===this.state?.profile?.hash) return `${this.constructor.name}.profileChange: false`;
      await this.save({ profile, });
      return `${this.constructor.name}.profileChange: true`;
   };

   async componentDidMount() {
      enumService.subscribe('enums', this.enumsChange, this);
      profileService.subscribe('profile', this.profileChange, this);
      userService.subscribe('getAllAvatar', this.avatarsChange, this);
      await Promise.all([
         enumService.enums,
         profileService.profile
      ]);
      // this.props.setEnumsTasks();
      this.props.getAllTaskUsers();
      // this.props.getSelectAccountModel();

      const usersAllAvatar = await userService.getAllAvatar();
      await this.save({
         usersAllAvatar,
         dataTask: {
            ...this.state.dataTask,
            initiator_id: this.state?.profile?.data?.id,
         }
      });
      this.getAllManager();
      //this._getResponsiblesManagers();
   }

   componentWillUnmount() {
      enumService.unsubscribe('enums', this.enumsChange, this);
      profileService.unsubscribe('profile', this.profileChange, this);
      userService.unsubscribe('getAllAvatar', this.avatarsChange, this);
      toggle.terminate();
   }

   avatarsChange = async (usersAllAvatar) => {
      if (usersAllAvatar.hash===this.state?.usersAllAvatar?.hash) return `${this.constructor.name}.avatarsChange: false`;
      await this.save({ usersAllAvatar });
      return `${this.constructor.name}.avatarsChange: true`;
   };

   // _getResponsiblesManagers = async () => {
   //    try {
   //       const response = await getResponsibles();
   //       this.setState({getAllUsers: response})
   //    } catch (e) {
   //       console.log(e)
   //    }
   // };

   removeParticipants = (user_id) => {

      const removeSpectatorObj = [];
      const removeUserId = [];

      map(this.spectator_user_obj, (item) => {
         // if (item['core-user-id'] !== user_id) {
         if ( item['id'] !== user_id ) {
            removeSpectatorObj.push(item);
         }
      });
      map(this.spectator_user_id, (item) => {
         if ( item !== user_id ) {
            removeUserId.push(item);
         }
      });
      this.spectator_user_obj = removeSpectatorObj;
      this.spectator_user_id = removeUserId;
      this.setState({
         dataTask: {
            ...this.state.dataTask,
            spectator_ids: removeUserId
         }
      });
   };

   removeParticipantsClient = () => {
      this.setState({dataTask: {...this.state.dataTask, account_id: null}});
      toggle.popup();
   };

   handleChangeData = (date) => {
      this.setState({
         dataTask:
            {
               ...this.state.dataTask,
               finish_date: format(date, 'YYYY-MM-DD HH:mm:ss'),
            },
      });
   };

   setToStateSelect = (event, name) => {
      this.setState({
         dataTask:
            {...this.state.dataTask, [name]: event.value},
         createTaskSelect: {...this.state.createTaskSelect, [name]: event},
      });
   };

   getAllManager = async() => {
      // securedRequest.get(`/api/core/user/select-models`)
      try {
         const response = await getResponsibles();

         let spectator_object = [];
         let assistance_object = [];

         response.map((item) => {
            // if (item['core-user-id'] === this.state.dataTask.initiator_id) {
            if ( item['id'] === this.state.dataTask.initiator_id ) {
               this.setState({getInitiator: item});
            }

            // if (item['core-user-id'] === this.state.dataTask.responsible_id) {
            if ( item['id'] === this.state.dataTask.responsible_id ) {
               this.setState({getResponsible: item});
            }

            // if (this.state.dataTask.spectator_ids.includes(item['core-user-id'])) {
            if ( this.state.dataTask.spectator_ids.includes(item['id']) ) {
               spectator_object.push(item);
            }

            if ( this.state.dataTask.assistance_ids.includes(item['id'])) {
               assistance_object.push(item);
            }
         });
         this.spectator_user_obj = spectator_object;
         this.assistance_user_obj = assistance_object;

         this.setState({
            getAllManager: response,
            getSpectator: spectator_object,
            getAssistents: assistance_object
         });

      } catch (error) {
         console.log(`All responsibles do not download ${error}`)
      }
   };

   // clientBlock = () => {
   //    return (<ClientBlock
   //       dataTask={this.state.dataTask}
   //       get_enums_tasks={this.props.get_enums_tasks}
   //       getAccountModel={this.props.getAccountModel}
   //       name={this.state.name}
   //    />);
   // };

   returnErrorTextClass = (className) => {
      return has(this.state.errorForm, className) ? "error" : "";
   };

   getSpectator = () => {
      if (this.state.roleUser === this.ROLE_4) {
         return this.state.getSpectator;
      } else if (this.state.roleUser === this.ROLE_2) {
         return [this.state.getInitiator];
      } else if (this.state.roleUser === this.ROLE_3) {
         return isEmpty(this.state.getResponsible) ? [] : [this.state.getResponsible];
      } else if (this.state.roleUser === this.ROLE_5) {
         return [];
      }

      return [];
   }

   spectatorModal = () => {
      return (<TaskSpectatorModal
         type="create"
         fade={this.state.fadeIn}
         setDataModal={this.setDataModal}
         updateDataClient={() => {
         }}
         // getAccountModel={this.props.getAccountModel}
         getAllManager={this.state.getAllManager}
         //getAllManager = { getAllManager }
         getSpectator={this.getSpectator()}
         toggleModalTask={toggle.modalTask}
         usersAllAvatar={this.state.usersAllAvatar.data}
         ROLE_1={this.ROLE_1}
         ROLE_2={this.ROLE_2}
         ROLE_3={this.ROLE_3}
         ROLE_4={this.ROLE_4}
         ROLE_5={this.ROLE_5}
         roleUser={this.state.roleUser}
         translate={this.props.translate}
      />);
   };

   setDataModal = (id, name) => {
      this.setState({
         dataTask: {...this.state.dataTask, [name]: id},
      });
      this.getAllManager();
   };

   setErrorData = (name, message, status) => {
      this.setState({
         errorForm:
            {...this.state.errorForm, [name]: {message, error: status}},
      });
   };

   changeHead = (event) => {
      this.setState({
         dataTask: {...this.state.dataTask, name: event.target.value},
      });
   };

   changeDescr = (event) => {
      this.setState({
         dataTask: {...this.state.dataTask, description: event.target.value},
      });
   };

   sendData = async (translate) => {
      if (this.props.quick) {
         await this.save({ disabledSaveTask: true });
      }

      try {
         const response = await saveAllTask(this.state.dataTask);
         if (this.props.quick) {
            toggle.closeTask();
            NotificationService.success({
               title: 'success',
               message: translate(`tasks_success_create_quick`),
               remove: false,
               view: true,
               viewClick: async () => {
                  await getAppealsAccount(this.props.userId);
                  this.props.history.push(`/clients/${this.props.userId}/task/${response.id}`);
               },
            });
         } else {
            if (response.error) {
                NotificationService.error({
                    title:   "error",
                    message: response.error,
                    remove:  true,
                });
            } else {
                this.props.history.push(`/clients/${this.props.userId}/clientdata?focusComment=true`);
            }
         }
      } catch (error) {
         await this.save({
            errorForm: {},
            disabledSaveTask: false
         });

         NotificationService.errors(error);

         for ( let i = 0; i <= Object.keys(error.errors).length - 1; i++ ) {
            const name = Object.keys(error.errors)[i];

            this.setErrorData(name, error.errors[name], true);
         }
      };
   };

   render() {
      toggle.init(this);

      return (
         <Translate>
            {({translate}) => {
               return (
                  <div>
                     <div className='modal-task-page'>
                        <div className='sidebar-wrapper sideBar-leads'>
                           {this.state.fadeInPop ?
                              <div className='overlay'/>
                              : ""}
                           <div
                              className={`lead-block-wrapper ${this.state.fadeInWraper ? 'overflow ' : ''}${this.state.fadeInPop ? 'overflow' : ''}`}>
                              <div className='lead-info-wrapper'>
                                 <Fade className='fade-block'
                                       in={this.state.fadeInPop}>
                                    <DelateTaskPopup
                                       delete={
                                          (() => {
                                             switch ( this.state.removeType ) {
                                                case this.removeTypeConst.client :
                                                   return this.removeParticipantsClient;
                                             }
                                          })()
                                       }
                                       toogle={toggle.popup}
                                       translate={translate}
                                       type={this.state.removeType}
                                    />
                                 </Fade>

                              </div>

                           </div>
                        </div>
                        <div className='task-page'>
                           <div
                              className={`task-block-wrapper ${this.state.fadeInWraper ? 'hidden ' : ''}${this.state.fadeInPop ? 'hidden' : ''}`}>
                              <div className='users-wrapper'
                                   style={{zIndex: 1002}}>
                                 {this.spectatorModal(translate)}
                              </div>
                           </div>
                        </div>
                        <div className='overlay' onClick={toggle.closeTask}/>
                        <div className='modal-task-container image'>
                           {/*<div className = 'modal-task-container__titl'>
                                        <input
                                            autoFocus = 'true'
                                            onChange = { this.changeHead }
                                            className = { `input_name ${this.returnErrorTextClass('name')}` }
                                            type = 'text'
                                            placeholder = { `${translate(`tasks_type_the_title`)}...` }
                                            value = { this.state.dataTask.name }
                                        />
                                    </div>*/}
                           <div className='modal-task-container__users-wrapper'>
                              <div className='small-column flex-column'>
                                 <h4 className='modal-title'>{translate('tasks_create_task')}</h4>
                                 <ResponsibleBlock
                                    dataTask={this.state.dataTask}
                                    error={this.returnErrorTextClass}
                                    get_enums_tasks={this.state?.enums?.data?.tasks}
                                    getAllManager={this.getAllManager}
                                    allManager={this.state.getAllManager}
                                    responsible={this.props.responsible}
                                    //getAllUsers = { this.state.getResponsiblesManagers }
                                    name={this.state.name}
                                    toggleModal={toggle.modalTask}
                                    usersAllAvatar={this.state.usersAllAvatar.data}
                                    userRoleId = { this.state?.profile?.data?.permission_template_id }
                                 />
                                 {/* HIDE ACTIONS_BLOCK IN CURRENT VERSION OF CRM */}
                                 {/*{!this.props.quick ?
                                    <React.Fragment>
                                       <div className='lead-block'>
                                          {this.clientBlock()}
                                       </div>

                                       <div className='lead-block' style={{
                                          minWidth: '114px',
                                          margin: 'auto'
                                       }}>

                                          <Dropdown
                                             isOpen={this.state.dropdownOpen}
                                             toggle={toggle.dropdown}>
                                             <DropdownToggle tag={'div'}>
                                                                    <span
                                                                       className='action-button'
                                                                       onClick={() => {
                                                                          toggle.actionMenu();
                                                                       }}>
                                                                        <i className={`fa fa-plus-circle`}/>
                                                                        <div
                                                                           className='text'>
                                                                            {translate(`tasks_action`)}
                                                                        </div>
                                                                    </span>
                                             </DropdownToggle>
                                             <DropdownMenu
                                                className='action-menu'>
                                                {isNull(this.state.dataTask.account_id) ?
                                                   <DropdownItem tag={'div'}
                                                                 className='first'
                                                                 onClick={() => {
                                                                    toggle.modalTask(this.ROLE_1);
                                                                 }}>
                                                      <div
                                                         className="action-img-wrapper">
                                                         <img src={clientColor}
                                                              alt="client"/>
                                                      </div>
                                                      {translate(`task_add_client`)}
                                                   </DropdownItem>
                                                   :
                                                   <DropdownItem tag={'div'}
                                                                 className='first'
                                                                 onClick={() => {
                                                                    toggle.popup(this.removeTypeConst.client);
                                                                 }}>
                                                      <div
                                                         className="action-img-wrapper">
                                                         <img src={clientGrey}
                                                              alt="client"/>
                                                      </div>
                                                      {translate(`task_remove_client`)}
                                                   </DropdownItem>
                                                }
                                             </DropdownMenu>

                                          </Dropdown>
                                       </div>
                                    </React.Fragment>
                                    : ""
                                 }*/}
                              </div>
                           </div>
                           {!this.props.quick ?
                              <React.Fragment>
                                 <Collapse isOpen={this.state.collapse}>
                                    <div
                                       className='modal-task-container__spoiler'>
                                       <div className='spoiler_content'>
                                          <div
                                             className='modal-task-container__users-wrapper'>
                                             <div className='small-column'>
                                                {/** assistance */}
                                             <InitiatorBlock
                                                   dataTask={this.state.dataTask}
                                                   get_enums_tasks={this.state?.enums?.data?.tasks}
                                                   getAllManager={this.getAllManager}
                                                   //getAllUsers = { this.props.getAllUsers }
                                                   getAllUsers={this.state.getAllUsers}
                                                   name={this.state.name}
                                                   toggleModal={toggle.modalTask}
                                                   usersAllAvatar={this.state.usersAllAvatar.data}
                                                   roleUser={this.ROLE_5}
                                                />
                                                <div
                                                   className='spectator-block'>
                                                   <div className='block'>
                                                      <div>{translate(`tasks_task_assistance`)}</div>
                                                      {!isEmpty(this.assistance_user_obj) ?
                                                         <div
                                                            className='edit'
                                                            onClick={
                                                               () => {
                                                                  this.getAllManager();
                                                                  toggle.modalTask(this.ROLE_5);
                                                               }
                                                            }>{translate(`tasks_edit`).toLowerCase()}<i
                                                            className='fa fa-pencil'/>
                                                         </div>
                                                         :
                                                         ""
                                                      }
                                                   </div>
                                                   <div
                                                      className='block-content'>
                                                      {!isEmpty(this.assistance_user_obj) ?
                                                         this.assistance_user_obj.slice(0, 8).map((item, value) => {
                                                            return (
                                                               <SpectatorBlock
                                                                  key={value}
                                                                  item={item}
                                                                  value={value}
                                                               />
                                                            );
                                                         })
                                                         :
                                                         <div
                                                            className='spectator-add'
                                                            onClick={
                                                               () => {
                                                                  this.getAllManager();
                                                                  toggle.modalTask(this.ROLE_5);
                                                               }
                                                            }>
                                                            <i className='fa fa-user'/> {translate(`tasks_assistance_add`)}
                                                         </div>
                                                      }
                                                   </div>
                                                </div>
                                                <InitiatorBlock
                                                   dataTask={this.state.dataTask}
                                                   get_enums_tasks={this.props.get_enums_tasks}
                                                   getAllManager={this.getAllManager}
                                                   //getAllUsers = { this.props.getAllUsers }
                                                   getAllUsers={this.state.getAllUsers}
                                                   name={this.state.name}
                                                   toggleModal={toggle.modalTask}
                                                   usersAllAvatar={this.state.usersAllAvatar}
                                                />
                                                {/* <div
                                                   className='spectator-block'>
                                                   <div className='block'>
                                                      <div>{translate(`tasks_spectator`)}</div>
                                                      {!isEmpty(this.spectator_user_obj) ?
                                                         <div
                                                            className='edit'
                                                            onClick={
                                                               () => {
                                                                  this.getAllManager();
                                                                  toggle.modalTask(this.ROLE_4);
                                                               }
                                                            }>{translate(`tasks_edit`).toLowerCase()}<i
                                                            className='fa fa-pencil'/>
                                                         </div>
                                                         :
                                                         ""
                                                      }
                                                   </div>
                                                   <div
                                                      className='block-content'>
                                                      {!isEmpty(this.spectator_user_obj) ?
                                                         this.spectator_user_obj.slice(0, 8).map((item, value) => {
                                                            return (
                                                               <SpectatorBlock
                                                                  key={value}
                                                                  item={item}
                                                                  value={value}
                                                               />
                                                            );
                                                         })
                                                         :
                                                         <div
                                                            className='spectator-add'
                                                            onClick={
                                                               () => {
                                                                  this.getAllManager();
                                                                  toggle.modalTask(this.ROLE_4);
                                                               }
                                                            }>
                                                            <i className='fa fa-user'/> {translate(`tasks_spectator_add`)}
                                                         </div>
                                                      }
                                                   </div>
                                                </div> */}
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </Collapse>
                                 <div className='toggle-button-wrapper'>
                                                <span className='toggle-button'
                                                      onClick={toggle.collapse}>
                                                    <i className={`la la-angle-${(this.state.collapse ? 'up' : 'down')}`}/>
                                                </span>
                                 </div>
                              </React.Fragment>
                              : ""
                           }
                           <div className='modal-task-container__info'
                                style={this.props.quick ? {paddingTop: '15px'} : {}}>{/*appealsList__info*/}
                              <div className='page'>
                                 <StatusBlock
                                    createTaskSelect={this.state.createTaskSelect}
                                    error={this.returnErrorTextClass}
                                    handleChangeData={this.handleChangeData}
                                    quick={true}
                                    selected={this.state.dataTask.finish_date}
                                    setToStateSelect={this.setToStateSelect}
                                    translate={translate}
                                    usersAllAvatar={this.state.usersAllAvatar.data}
                                 />
                              </div>
                           </div>
                           <div
                              className={`modal-edit-description editDescription ${this.returnErrorTextClass('description')}`}>
                              <div
                                 className='modal-task-container__titl editDescriptionPadding'>
                                 <h6
                                    className='modal-task-container__titleHeadList'>
                                    {upperFirst(translate(`tasks_description`))}
                                 </h6>
                              </div>
                              <div
                                 className='modal-task-container__info renderDescription'>
                                 <div
                                    className='modal-task-container__descr_content'>
                                    <textarea name='description'
                                              onChange={this.changeDescr}
                                              placeholder={`${translate(`tasks_type_the_description`)}...`}
                                              rows='12'
                                              value={this.state.dataTask.description}/>
                                 </div>
                              </div>
                           </div>
                           <div
                              className={`modal-task-container__info ${classnames({disabled: this.state.disabledSaveTask})}`}>
                              <ButtonArea
                                 closeTask={toggle.closeTask}
                                 sendData={() => {
                                    this.sendData(translate);
                                 }}
                                 translate={translate}
                              />
                           </div>
                        </div>
                     </div>
                  </div>
               );
            }}
         </Translate>
      );
   }
}

TaskCreateForm.defaultProps = {};

export default withRouter(withLocalize(connect(
   null,
   (dispatch) =>
      bindActionCreators({
         saveAllTask,
         // setEnumsTasks,
         getAllTaskUsers,
         // getSelectAccountModel,
         // getAppealsAccount
      }, dispatch)
)(TaskCreateForm)));
