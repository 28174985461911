import { DataService, } from "./DataService";
import {  hash as hashData, } from "./CachingService";
import { get, } from "../components/Magic";
import { GET_COUNT_TASK } from "../modules/tasks/apiRoutes";

class TaskDataService extends DataService {
    constructor () {
        super([
            'countTask',
        ]);
        Object.freeze(this);
    }

    async countTask () {
        const url = `${ GET_COUNT_TASK }`;
        const data = await get(url);
        const hash = await hashData(data);
        const countTask = { data, hash, };
        this.emit('countTask', countTask);

        return countTask;
    }

}

export const taskService = new TaskDataService();
