import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link, } from 'react-router-dom';
import { withLocalize, } from 'react-localize-redux';
import { MagicSelect, MagicTable, } from '../../../../../components/Magic';
import {
	MagicConfirm,
	MagicSwitch,
	MagicTooltip,
} from '../../../../tima/components/Magic';
import Moment from 'moment';
import NotificationService from '../../../../../services/NotificationService';
import AmountPopUp from './AmountPopUp';
import iconTip from '../../../../../images/privacy-tip.svg';
import iconTipActive from '../../../../../images/privacy-tip-active.svg';
import { paymentService, } from '../../../../../services/PaymentDataService';
import { MagicRadioSwitch, } from "../../../../tima/components/Magic/MagicSwitch";
import { PAYMENT_UPDATE_METHODS, } from "../consts/PAYMENT_UPDATE_METHODS";
import { PAYMENT_TYPES, } from "../consts/PAYMENT_TYPES";

class ListPaymentsMagicTable extends React.Component {
    static propTypes = {
    	data: PropTypes.array,
    	enums: PropTypes.object,
    	refreshData: PropTypes.func,
    };

    static defaultProps = {
    	data: [],
    	enums: {},
    	refreshData: () => {},
    };
    constructor (props) {
    	super(props);
    	this.state = {
    		orderId: 0,
    		resolution: 0,
    		sendFlexiGate: 0,
    		manuallyClosingUpdatedValue: {},
    		sendResolutionRequest: {},
    		departmentsListNames: [],
    	};
    };

    get paymentsConfig () {
    	const { translate, } = this.props;

    	return [
    		{
    			path: [ 'core', 'account', 'name', ],
    			key: 'paymentClientName',
    		},
    		{
    			path: [ 'core', 'account', 'surname', ],
    			key: 'paymentClientSurname',
    		},
    		{
    			path: [ 'core', 'account', 'register_type', ],
    			key: 'paymentClientRegisterType',
    		},
    		{
    			path: [ 'core', 'user', 'surname', ],
    			key: 'paymentResponsibleSurname',
    		},
    		{
    			path: [ 'payment', 'orders', ],
    			key: 'paymentOrders',
         // accessChecked: true,
    		},
    		{
    			path: [ 'payment', 'orders', 'source', ],
    			key: 'paymentOrderSource',
    		},
    		{
    			path: [ 'payment', 'orders', 'account_id', ],
    			key: 'paymentClientId',
    		},
    		{
    			path: [ 'payment', 'orders', 'is_sended', ],
    			key: 'paymentIsSended',
    		},
    		{
    			path: [ 'payment', 'orders', 'has_invoice', ],
    			key: 'paymentHasInvoice',
    		},
    		{
    			path: [ 'payment', 'orders', 'was_already_closed', ],
    			key: 'paymentWasAlreadyClosed',
    		},
    		{
    			path: [ 'payment', 'orders', 'type', ],
    			key: 'paymentOrderType',
    		},
    		{
    			path: [ 'payment', 'orders', 'withdrawal_alert', ],
    			key: 'withdrawalAlert',
    		},
    		{
    			path: [ 'payment', 'orders', 'payment_system', ],
    			key: 'paymentSystemId',
    		},
    		{
    			path: [ 'payment', 'system_gateway', 'name', ],
    			key: 'paymentGateway',
    		},
    		{
    			path: [ 'payment', 'orders', 'created_at', ],
    			key: 'paymentCreateAt',
    			render: this.renderPaymentDate,
    			title: 'payment_table_create_at',
    		},
    		{
    			path: [ 'payment', 'orders', 'id', ],
    			key: 'paymentOrderId',
    			render: this.renderPaymentOrderId,
    			title: 'payment_table_order_id',
    		},
    		{
    			path: [ 'core', 'system_accounts', 'id', ],
    			key: 'paymentSystemAccountId',
    			render: this.renderPaymentSystemAccountId,
    			title: 'payment_table_system_account_id',
    		},
    		{
    			path: [ 'core', 'account', 'name', ],
    			key: 'paymentClientFullname',
    			render: this.renderPaymentClient,
    			title: 'payment_table_client',
    		},
    		{
    			path: [ 'core', 'user', 'name', ],
    			key: 'paymentResponsibleFullName',
    			render: this.renderPaymentResponsible,
    			title: 'payment_table_responsible',
    		},
    		{
    			path: [ 'core', 'system_accounts', 'acc_ccy', ],
    			key: 'paymentCurrency',
    			render: this.renderPaymentCurrency,
    			title: 'payment_table_currency',
    		},
    		{
    			path: [ 'payment', 'orders', 'amount', ],
    			key: 'paymentAmount',
    			render: this.renderPaymentAmount,
    			title: 'payment_table_amount',
    		},
    		{
    			path: [ 'payment', 'orders', 'fee', ],
    			key: 'paymentFee',
    			render: this.renderPaymentText,
    			title: 'payment_table_fee',
    		},
    		{
    			path: [ 'payment', 'orders', 'amount_paid', ],
    			key: 'paymentAmountPaid',
    			render: this.renderPaymentText,
    			title: 'payment_table_amount_paid',
    		},
    		{
    			path: [ 'payment', 'system_types', 'alias', ],
    			key: 'paymentMethod',
    			render: this.renderPaymentText,
    			title: 'payment_table_payment_method',
    		},
    		{
    			path: [ 'payment', 'systems', 'name', ],
    			key: 'paymentSystem',
    			render: this.renderPaymentText,
    			title: 'payment_table_payment_system',
    		},
    		{
    			path: [ 'payment', 'cards', 'name', ],
    			key: 'paymentCWB',
    			render: this.renderPaymentText,
    			title: 'payment_table_cwb',
    		},
    		{
    			path: [ 'payment', 'cards', 'card_holder', ],
    			key: 'paymentCardHolder',
    			render: this.renderPaymentText,
    			title: 'payment_table_card_holder',
    		},
    		{
    			path: [ 'payment', 'orders', 'description', ],
    			key: 'paymentDescription',
    			render: this.renderPaymentText,
    			title: 'payment_table_description',
    		},
    		{
    			path: [ 'payment', 'orders', 'date_paid', ],
    			key: 'paymentDatePaid',
    			render: this.renderPaymentDate,
    			title: 'payment_table_date_paid',
    		},
      // {
      //    path: ['payment', 'orders', 'type'],
      //    key: 'paymentType',
      //    render: this.renderPaymentType,
      //    title: 'payment_table_type_request',
      // },
    		{
    			path: [ 'payment', 'orders', 'error', ],
    			key: 'paymentError',
    			render: this.renderPaymentText,
    			title: 'payment_table_error',
    		},
    		{
    			path: [ 'payment', 'orders', 'manually_closing', ],
    			key: 'paymentManuallyClosing',
    			render: this.renderPaymentManuallyClosing,
    			title: 'payment_table_manually_closing',
    		},
    		{
    			path: [ 'payment', 'orders', 'status', ],
    			key: 'paymentStatus',
    			render: this.renderPaymentStatus,
    			title: 'payment_table_status',
    		},
      // {
      //    path: ['payment', 'orders', 'internal_payment_id'],
      //    key: 'paymentId',
      //    render: this.renderPaymentText,
      //    title: 'payment_table_id',
      // },
    		{
    			path: [ 'payment', 'orders', 'resolution', ],
    			key: 'paymentResolution',
    			render: this.renderPaymentResolution,
    			title: 'payment_table_resolution',
    		},
    		{
    			path: [ 'payment', 'systems_by_card', 'name', ],
    			key: 'paymentSystemPlatformName',
    			render: this.renderPaymentText,
    			title: 'payment_table_platform',
    		},
    	].map(({ title: t, ...data }) => ({ ...data, ...t && { title: translate(t), }, }));

    };

   save = async state => new Promise(next => this.setState(state, next));

	 componentDidMount = async () => {
			 const { departmentsListNames, } = this.props;

			 await this.save((state) => {
					 return {
							 departmentsListNames: {
									 ...state.departmentsListNames,
									 departmentsListNames,

							 },
					 };
			 });
	 }

	getUrlPathByPaymentData = (paymentType, paymentMethod, id) => {
		if (!PAYMENT_TYPES[paymentType]) {
			return `${ id }`;
		}

		const paymentMethodKey = paymentMethod || "BY_SYSTEM"; //if empty we count it as system

		return `${ PAYMENT_TYPES[paymentType] }/${ PAYMENT_UPDATE_METHODS[paymentMethodKey] }/${ id }`;
	}

	setResolution = async (orderId, value) => {
   		await this.save({ orderId, resolution: value, });
	};

   setStatus = async (orderId, value) => {
   	await this.save({ orderId, status: value, });
   };

   setFlexiGate = async (orderId) => {
   	await this.save({ sendFlexiGate: orderId, });
   };

   clearResolution = async () => {
   	await this.save({ orderId: 0, resolution: 0, });
   };

   clearStatus = async () => {
   	await this.save({ orderId: 0, status: 0, });
   };

   onChangeManuallyClosing = async (value, id) => {
   	await this.save({
   		manuallyClosingUpdatedValue: {
   			...this.state.manuallyClosingUpdatedValue,
   			[id]: value,
   		},
   	});
   };

   onChangeResolution = async (value, id) => {
   	await this.save({
   		sendResolutionRequest: {
   			...this.state.sendResolutionRequest,
   			[id]: value,
   		},
   	});
   };

   showDateEnrollmentError = (errors, id) => {
	   return NotificationService.error({
   		title: "error_validation", message: errors.date_enrollment[0], view: true, viewClick: () => this.props.history.push(`/payments/order/${ id }`), remove: false,
   	});
   }

   handleOnResolutionChangeResponseErrors = (errorFromResponse, id) => {
	   const errors = errorFromResponse?.response?.data.errors;

	   if ('date_enrollment' in errors) { //if date_enrollment is among errors, show single error with link to the order edit
		   return this.showDateEnrollmentError(errors, id);
	   }

	   NotificationService?.errors?.({ errors, remove: false, });
   }

   renderConfirm = ({
   	translate, isVisible, onAccept, onReject,
   }) => {
   	const options = {
   		onAccept,
   		onReject,
   		title: this.props.translate(translate?.title),
   		accept: this.props.translate(translate?.accept),
   		reject: this.props.translate(translate?.reject),
   		isVisible,
   	};

   	return (<MagicConfirm { ...options } />);
   };

   renderPaymentLink = (path, text) => (<Link to={ path }>{text}</Link>);

   renderPaymentDate = (date, { item, }) => {
   	if (item.access('index')) {
   		const formatDB = 'YYYY-MM-DD HH:mm:ss';
   		const formatOut = 'YYYY-MM-DD HH:mm:ss';

   		date = Moment(date, formatDB);

   		return date.isValid() ? date.format(formatOut) : "-";
   	}

   	return '-';
   };

   renderPaymentText = (text, { item, }) => {
   	const options = {
   		content: text ? text : "-",
   		lengthCut: 20,
   	};

   	if (item?.access('index')) {
   		return (<MagicTooltip { ...options } />);
   	}

   	return '-';
   };

   renderPaymentSystemAccountId = (id, { item, }) => {
   	const options = {
   		content: String(id),
   		lengthCut: 20,
   	};
   	const text = (<MagicTooltip { ...options } />);

   	if (item?.access('index', 'show')) {
   		return this.renderPaymentLink(`/list-accounts/systems/${ id }`, text);
   	} else if (item?.access('index')) {
   		return text;
   	}

   	return '-';
   };

   renderPaymentOrderId = (id, { item, items, }) => {
   	const orderTypeIndex = +items?.paymentOrderType?.valueOf;
   	const typesOrder = this.props?.enums?.payment?.OrderTypeStatusEnum ?? {};
   	const options = {
   		content: typesOrder?.[orderTypeIndex],
   		lengthCut: 20,
   	};
   	const text = (<MagicTooltip { ...options } />);

   	if (item?.access('index', 'show')) {
   		return (
	<React.Fragment>
	{id}
	<div className={ [ "2", 2, ].includes(orderTypeIndex) ? 'withdrawal' : 'replenishment' }>
	{this.renderPaymentLink(`/payments/order/${ id }`, text)}
   				</div>
   			</React.Fragment>
   		);
   	} else if (item?.access('index')) {
   		return (<React.Fragment>{id}<div>{text}</div></React.Fragment>);
   	}

   	return '-';
   };

   renderPaymentCurrency = (currency, { item, }) => {
   	const allCurrencies = this.props.enums?.core?.AccountCurrencyEnum ?? {};
   	const options = {
   		content: allCurrencies?.[currency] ?? "-",
   		lengthCut: 20,
   	};

   	if (item?.access('index')) {
   		return (<MagicTooltip { ...options } />);
   	}

   	return "-";
   };

   renderPaymentClient = (_, { item, items, }) => {
   	const name = items?.paymentClientName?.valueOf;
   	const surname = items?.paymentClientSurname?.valueOf;
   	const registerType = +items?.paymentClientRegisterType?.valueOf;
   	const clientName = registerType === 2 ? `${ name }` : `${ name } ${ surname }`;
   	const options = {
   		content: clientName,
   		lengthCut: 20,
   	};
   	const text = (<MagicTooltip { ...options } />);

   	if (item?.access('index', 'show')) {
   		const clientId = items?.paymentClientId?.valueOf;

   		return this.renderPaymentLink(`/clients/${ clientId }/depositsandwithdrawals`, text);
   	} else if (item?.access('index')) {
   		return text;
   	}

   	return '-';
   };

   renderPaymentResponsible = (name, { item, items, }) => {
   	const surname = items?.paymentResponsibleSurname?.valueOf;
   	const fullName = `${ name ?? "" } ${ surname ?? "" }`;

   	return this.renderPaymentText(fullName, { item, });
   };

   // renderPaymentType = (type, {item,}) => {
   //    const typesOrder = this.props?.enums?.payment?.OrderTypeStatusEnum ?? {};
   //    const options = {
   //       content: typesOrder?.[type] ?? "-",
   //       lengthCut: 20,
   //    };
   //    if ( item?.access('index') ) {
   //       return (<MagicTooltip {...options} />)
   //    }
   //    return '-';
   // };

   renderPaymentManuallyClosing = (manually_closing, { item, items, }) => {
   	const indexShowPermissions = item?.access('index', 'show');
   	const indexShowUpdateStorePermissions = item?.access('index', 'show', 'update', 'store');

   	if (indexShowPermissions || indexShowUpdateStorePermissions) {
   		const orderManuallyClosedEnum = this.props?.enums?.payment?.OrderManuallyClosedEnum ?? {};
   		const {
   			paymentOrderId,
   			paymentMethod,
   			paymentStatus,
   			paymentResolution,
   			paymentOrderSource,
   			paymentGateway,
   			paymentIsSended,
   		} = items;

   		const case_1 = [ "PO", ].includes(paymentOrderSource?.valueOf); // PO
   		const case_2 = [ "CARD", ].includes(paymentMethod?.valueOf); // CARD
   		const case_3 = [ 9, ].includes(paymentResolution?.valueOf); // Resolution: STATUS_NEED_CONFIRM
   		const case_4 = [ 1, ].includes(paymentResolution?.valueOf); // Resolution: APPROVED
   		const case_5 = [ 2, ].includes(paymentResolution?.valueOf); // Resolution: DECLINED
   		const case_6 = [ 5, ].includes(paymentResolution?.valueOf); // Resolution: CHARGEBACK
   		const case_7 = [ 10, ].includes(paymentResolution?.valueOf); // Resolution: ERROR
   		const case_8 = +paymentStatus?.valueOf === 3; // Status: Closed
   		const case_9 = +paymentStatus?.valueOf === 2; // Status: In Process

		//SHARPAY CASES
   		const case_10 = paymentGateway.valueOf === "SharPay"; //Payment gateway: Sharpay
   		const case_11 = +paymentStatus.valueOf === 1; //Status: Open
   		const case_12 = paymentMethod?.valueOf === "WALLET"; //Payment method: WALLET
   		const case_13 = paymentMethod?.valueOf === "CRYPTO_CURRENCY"; //Payment method: CRYPTO
   		const case_14 = paymentResolution?.valueOf === 0; //Resolution: WAIT
   		const case_15 = paymentIsSended?.valueOf === false; // Payment wasn't sent

         // PO,
         // CARD,
   		const condition_1 = case_1 && case_2;

		//PO,
		//SHARPAY
		//Payment method: CARD or WALLET or CRYPTO
   		const condition_2 = case_1 && case_10 && (case_2 || case_12 || case_13) && case_15;

         // PO,
         // CARD without SHARPAY or SHARPAY and CARD or WALLET or CRYPTO,
         // Status: Closed OR In process,
         // Resolution: Approved OR Declined OR Chargeback,
   		const condition_3 = (condition_1 || condition_2) && ((case_8 || case_9) && (case_4 || case_5 || case_6));

         // PO,
         // CARD,
         // Status: In process,
         // Resolution: Need confirm OR Error,
   		const condition_4 = condition_1 && case_9 && (case_3 || case_7);

		// Status: Open,
		// Resolution: Wait,
   		const condition_5 = case_11 && case_14;

		// Status: In process,
		// Resolution: Need confirm,
   		const condition_6 = case_9 && case_3;

   		// PO,
		// SHARPAY
		// Payment method: CARD or WALLET or CRYPTO
		// Status: Open,
		// Resolution: Wait,
		// OR
		// Status: In process,
		// Resolution: Need confirm,
   		const condition_7 = condition_2 && (condition_5 || condition_6);

         // orderManuallyClosedEnum[1] – Custom closing,
         // orderManuallyClosedEnum[2] – Standard closing,
   		const value = manually_closing === 1
   			? orderManuallyClosedEnum[1]
   			: manually_closing === 2
   				? orderManuallyClosedEnum[2]
   				: '-';

   		if (condition_3) {
   			const options = {
   				className: indexShowUpdateStorePermissions ? `max-content-payments-table magic-switch magic-switch--inline` : `max-content-payments-table magic-switch magic-switch--inline disabled-magic-payment-switch`,
   				direction: `column-reverse`,
   				indexNone: false,
   				isDisabled: true,
   				index: manually_closing === 1 ? 0 : -1,
   				reverse: false,
   				texts: [ value, ],
   				values: [ value, ],
   				updateStateFromProps: true,
   			};

   			return value !== '-' ? <MagicRadioSwitch { ...options } /> : <MagicTooltip content={ [ value, ] } lengthCut={ 20 } />;
   		} else if (condition_4 || condition_7) {
   			const value = this.state?.manuallyClosingUpdatedValue?.[paymentOrderId.valueOf] ?? manually_closing ?? 2;
   			const text = { 1: orderManuallyClosedEnum[1], 2: orderManuallyClosedEnum[2], }[value];

   			const options = {
   				className: indexShowUpdateStorePermissions ? `max-content-payments-table magic-switch magic-switch--inline` : `max-content-payments-table magic-switch magic-switch--inline disabled-magic-payment-switch`,
   				reverse: false,
   				isDisabled: !indexShowUpdateStorePermissions,
   				index: indexShowUpdateStorePermissions ? 0 : -1,
   				values: [ 2, 1, ],
   				text: indexShowUpdateStorePermissions ? [ text, ] : '-',
   				onChange: indexShowUpdateStorePermissions ? ({ value, }) => this.onChangeManuallyClosing(value, paymentOrderId.valueOf) : null,
   			};

   			return <MagicSwitch { ...options } />;
   		}

   		return '-';

   	}
   };

   renderPaymentStatus = (status, { item, items, }) => {
   	const { data, } = this.props;
   	const transactionStatusEnum = this.props?.enums?.payment?.TransactionStatusEnum ?? {};
   	const allStatuses = this.props.enums?.payment?.TransactionStatusEnum ?? {};
   	const { departmentsListNames, } = this.props;

   	const financialDepartment = departmentsListNames.includes('financial');
   	const translate = {
   		title: "one_payment_table_confirm_title",
   		accept: "one_payment_table_confirm_accept",
   		reject: "one_payment_table_confirm_reject",
   	};

   	const indexShowPermissions = item?.access('index', 'show');
   	const indexShowUpdateStorePermissions = item?.access('index', 'show', 'update', 'store');

   	if (indexShowPermissions || indexShowUpdateStorePermissions) {
   		const {
   			paymentOrderId,
   			paymentOrderType,
   			paymentMethod,
   			paymentStatus,
   			paymentResolution,
   			paymentOrderSource,
   			paymentWasAlreadyClosed,
   			paymentHasInvoice,
   		} = items;
   		const case_1 = +paymentStatus?.valueOf === 3; // [ 3 => "CLOSED" ]
   		const case_2 = +paymentOrderType?.valueOf === 1; // [ 1 => "ПОПОЛНЕНИЕ" ]
   		const case_3 = [ 1, 2, 5, ].includes(+paymentResolution?.valueOf); //[ "STATUS_APPROVED", "STATUS_DECLINED", "STATUS_CHARGEBACK" ]
   		const case_4 = paymentWasAlreadyClosed.valueOf;
   		const case_5 = +paymentStatus?.valueOf === 2; // [ 2 => "IN_PROCESS" ]
         // const case_6 = items?.paymentOrderSource?.valueOf === "PO";     // созданная в ЛК
   		const case_7 = paymentOrderSource?.valueOf === "CRM"; // созданная в CRM
   		const case_8 = [ "CARD", ].includes(paymentMethod?.valueOf);
   		const case_9 = [ "WALLET", ].includes(paymentMethod?.valueOf);
   		const case_10 = [ "BANK_TRANSFER", ].includes(paymentMethod?.valueOf);
   		const case_11 = paymentHasInvoice?.valueOf;

         // общее условие
         // 1) ПОПОЛНЕНИЕ
         // 2) имеет решение  "STATUS_APPROVED", "STATUS_DECLINED", "STATUS_CHARGEBACK"
         // 3) через "CARD", "WALLET", "BANK_TRANSFER" ( Карта, Кошелек, Банковский перевод ), если карточка и кошелек то CRM
   		const condition_1 = case_2 && case_3 && (case_10 || case_8 && case_7 || case_9 && case_7);

         // условие 2: если заявка
         // 1) имеет статус "CLOSED",
   		const condition_2 = condition_1 && case_1;

         // условие 2: если заявка
         // 1) имеет статус "IN_PROCESS",
         // 5) заявка раньше была закрыта / и есть валидный инвойс
   		const condition_3 = condition_1 && case_5 && (case_11 || case_9 && !case_11);

   		const confirm = this.renderConfirm({
   			translate,
   			isVisible: this.state?.orderId === paymentOrderId?.valueOf,
   			onAccept: async () => {
   				try {
   					const id = paymentOrderId?.valueOf;
   					const options = { status: this.state.status, };

   					const path = this.getUrlPathByPaymentData(paymentOrderType.valueOf, paymentMethod.valueOf, id);
   					const response = await paymentService.editPaymentPatch(path, options);

   					await this.props?.refreshData?.();
   				} catch (error) {
   					NotificationService?.errors?.({ ...error?.response?.data, remove: false, });
   				} finally {
   					await this.clearStatus?.();
   				}
   			},
   			onReject: async () => await this.clearStatus?.(),
   		});

   		const onChange = async value => await this.setStatus?.(paymentOrderId?.valueOf, value);

   		if ((condition_2 || condition_3) && financialDepartment) {
   			const [ CLOSED, IN_PROCESS, ] = [ 3, 2, ];
   			const values = case_1 ? [ CLOSED, IN_PROCESS, ] : [ IN_PROCESS, CLOSED, ];
   			const options = {
   				values: [ ...new Set(values), ],
   				valueToLabel: value => allStatuses?.[value === status ? status : value],
   				ItemProps: value => value === values[0] ? { disabled: true, } : {},
   				onChange: value => onChange(value),
   				value: values?.[0] ?? +status,
   			};

   			return (<div style={ { position: 'relative', } }>{confirm}<MagicSelect { ...options } /></div>);
   		}
   		const options = {
   			content: `${ transactionStatusEnum?.[status] }`,
   			lengthCut: 20,
   		};

   		return (<MagicTooltip { ...options } />);

   	}

   	return '-';
   };

   renderPaymentAmount = (amount, { item, items, }) => {
   	const case_1 = items.paymentOrders.access('inOutStatistic'); // есть ли доступ на получение данных попапа
   	const case_2 = +items.paymentStatus.valueOf !== 3; // статус не "closed"
   	const case_3 = +items.paymentResolution.valueOf !== 1; // Resolution ≠ Approved
   	const case_4 = +items?.paymentOrderType?.valueOf === 2; // withdrawal
   	const case_5 = items?.withdrawalAlert.valueOf === true; // если бек вернул поле withdrawal_alert

   	if (item?.access('index')) {
   		const isSelected = this.props.selectedAmountId === items.paymentOrderId.valueOf;
   		const icon =  !isSelected ?
   			(<img src={ iconTip } onClick={ () => this.props.handleSelectAmount(items.paymentOrderId.valueOf) } />)
   			: (<img src={ iconTipActive } />);
   		const isIconAvaliable = case_1 && case_2 && case_3 && case_4 && case_5;

   		return (
	<div className="amount-container">
	{amount}
	{isIconAvaliable ? icon : ''}
	{isSelected
   					? (<AmountPopUp
		close={ this.props.handleSelectAmount }
		paymentId={ items.paymentOrderId.valueOf }
   					/>)
   					: ''}
   			</div>
   		);
   	}
   };

   renderPaymentResolution = (resolution, { item, items, }) => {
   	const allResolutions = this.props?.enums?.payment?.OrderResolutionEnum ?? {};
   	const orderId = items.paymentOrderId.valueOf;
   	const { manuallyClosingUpdatedValue, } = this.state;
   	const translate = {
   		title: "one_payment_table_confirm_title",
   		accept: "one_payment_table_confirm_accept",
   		reject: "one_payment_table_confirm_reject",
   	};

   	if (item?.access('index', 'update')) {
   		const {
   			paymentOrderId,
   			paymentSystem,
   			paymentStatus,
   			paymentMethod,
   			paymentOrderType,
   			paymentCWB,
   			paymentDescription,
   			paymentOrderSource,
   			paymentIsSended,
   			paymentHasInvoice,
   			paymentResolution,
   			paymentOrdersSystemId,
   			paymentSystemId,
   			paymentGateway,
   		} = items;
         // Общие условия
         // 1) имеет статус "IN_PROCESS"
         // 2) имеет заполненое поле "Платежная система"
         // 3) раньше не закрывалась
   		const case_1 = +paymentStatus?.valueOf === 2;
   		const case_2 = paymentSystem?.valueOf?.length > 0;
   		const case_3 = paymentHasInvoice?.valueOf;
   		const condition_1 = case_1 && case_2;
   		const case_4 = [ 1, ].includes(+paymentOrderType?.valueOf); // [ 1 => "ПОПОЛНЕНИЕ" ]
   		const case_5 = [ 2, ].includes(+paymentOrderType?.valueOf); // [ 2 => "ВЫВОД" ]
   		const case_6 = [ "PO", ].includes(paymentOrderSource?.valueOf); // созданная в ЛК
   		const case_7 = [ "CRM", ].includes(paymentOrderSource?.valueOf); // созданная в CRM
   		const case_8 = [ "BANK_TRANSFER", ].includes(paymentMethod?.valueOf); // через BANK_TRANSFER
   		const case_9 = [ "WALLET", ].includes(paymentMethod?.valueOf); // через WALLET
   		const case_10 = [ "CRYPTO_CURRENCY", ].includes(paymentMethod?.valueOf); // через CRYPTO_CURRENCY
   		const case_11 = paymentCWB?.valueOf?.length > 0 && paymentCWB?.valueOf !== "-"; // заполнено поле CWB
   		const case_12 = paymentDescription?.valueOf?.length > 0; // Заполнено поле Назначение платежа
   		const case_13 = [ "CARD", ].includes(paymentMethod?.valueOf); // через CARD
   		const case_14 = paymentHasInvoice?.valueOf; // Invoice
   		const case_15 = [ 1, 2, 5, ].includes(+resolution); // [ "STATUS_APPROVED", "STATUS_DECLINED", "STATUS_CHARGEBACK" ]
   		const case_16 = [ 47, ].includes(+paymentOrdersSystemId?.valueOf); // PAYEER
   		const case_17 = [ 1, ].includes(+resolution); // [ "STATUS_APPROVED" ]
   		const case_18 = [ "Payeer", ].includes(paymentSystem.valueOf);
   		const case_19 = paymentSystem?.valueOf === 'Bank USD';
   		const case_20 = paymentSystem?.valueOf === 'Bank EUR';
   		const case_21 = paymentGateway.valueOf === "SharPay"; //Sharpay
   		const case_22 = +paymentResolution?.valueOf === 0; //Resolution: WAIT
   		const case_23 = +paymentStatus?.valueOf === 1;//Status: Open
   		const case_24 = manuallyClosingUpdatedValue[orderId] === 1; //Is manually closing
   		const case_25 = +paymentResolution?.valueOf === 9; // Resolution = Need Confirm
   		const case_26 = [ null, "", "SYSTEM_DEPOSIT", ].includes(items?.paymentMethod?.valueOf); // Через Системный депозит - if empty we suppose it's system operation
   		const case_27 = [ null, "", "SYSTEM_WITHDRAWAL", ].includes(items?.paymentMethod?.valueOf); // Через Системный вывод - if empty we suppose it's system operation
   		const case_28 = paymentIsSended?.valueOf === false; // Payment wasn't sent

         // ВЫВОД "BANK_TRANSFER", "WALLET", "CARD", "CRYPTO_CURRENCY"
         // 1) "ВЫВОД"
         // 2) заполнено поле Номер банк.счета (BANK_TRANSFER),
         //                   Номер кошелька (WALLET), номер карты (CARD),
         //                   номер криптокошелька (CRYPTO_CURRENCY)
   		const condition_2 = condition_1 && case_5 && case_11 && (case_6 && case_5 && !case_18);
         // ПОПОЛНЕНИЕ "WALLET", "CARD"
         // 1) "ПОПОЛНЕНИЕ"
         // 2) через "WALLET"
         // 3) заполнено поле Номер кошелька(WALLET), номер карты (CARD)
         // 4) создана в CRM (WALLET), создана в ЛК (CARD)
   		const condition_3 = condition_1 && (case_4 || case_5) && (case_7 && case_9 || case_6 && case_13) && case_11 && !case_17 && !case_15;
         // ПОПОЛНЕНИЕ "BANK_TRANSFER","CRYPTO_CURRENCY"
         // 1) "ПОПОЛНЕНИЕ"
         // 2) через "BANK_TRANSFER" + заполнено поле номер банк. счета и есть инвойс
         // 3) через "CRYPTO_CURRENCY" + назначение платежа
   		const BT_valid = case_8 && case_14 && !case_15;
   		const CC_valid = case_10 && case_11 && case_12;

   		const condition_4 = condition_1 && case_4 && (BT_valid || CC_valid);
   		const condition_5 = paymentIsSended?.valueOf; // отправка на FlexiGate
         // const condition_6 = condition_1 && case_4 && case_6 && case_16;
   		const condition_7 = condition_1 && case_5 && case_9 && case_16 && (case_6 || case_7) && case_11;

         // 1) IN PROCESS
         // 2) ПОПОЛНЕНИЕ
         // 3) ЛК или ЦРМ
         // 4) WALLET
         // 5) PAYEER
         // 6) Заполнено ли поле CWB
         // 7) Переоткрыта заявка
   		const condition_8 = case_1 && case_4 && (case_6 || case_7) && case_9 && case_16 && case_11 && !case_17 && !case_15;

   		const condition_9 = case_1 && case_4 && case_9 && !case_15 && case_11;

   		const condition_10 = case_1 && case_4 && case_8 && case_14 && !case_15 && !case_19 && !case_20;

		//PO and WALLET or Card, or just just Crypto
		//SHARPAY
		//RESOLUTION: WAIT
		//STATUS: OPEN
		//Manually closing
		//Order wasn't sent
   		const condition_11 = (case_6 && (case_9 || case_13) || case_10) && case_21 && case_22 && case_23 && case_24 && case_28;

		//PO and WALLET or Card, or just just Crypto
		//SHARPAY
		//RESOLUTION: Need confirm
		//STATUS: In process
		//Order wasn't sent
   		const condition_12 = (case_6 && (case_9 || case_13) || case_10) && case_21 && case_25 && case_1 && case_28;

		//Withdrawal
		//Sharpay and WALLET or CRYPTO or CARD
		//or
		//BankTransfer or System
   		const condition_13 = case_5 && (case_21 && (case_9 || case_10 || case_13) || (case_8 || case_27));

		//RESOLUTION: Need confirm
		//STATUS: In process
		//Method (BANK_TRANSFER or SYSTEM_DEPOSIT or SYSTEM_WITHDRAWAL)
   		const condition_14 = case_1 && case_25 && (case_8 || case_26 || case_27);

   		const confirm = this.renderConfirm({
   			translate,
   			isVisible: this.state?.orderId === paymentOrderId?.valueOf,
   			onAccept: async () => {
   				try {
   					(case_8 || case_9 || case_16 || case_13) && await this.onChangeResolution(true, paymentOrderId.valueOf);
   					const id = paymentOrderId?.valueOf;
   					const options = {
   						resolution: this.state?.resolution,
   						...this.state.manuallyClosingUpdatedValue?.[paymentOrderId?.valueOf] === 1 && (this.state?.resolution === 1 || case_21) && {
   							manually_closing: 1,
   						},
   					};
   					const path = this.getUrlPathByPaymentData(paymentOrderType.valueOf, paymentMethod.valueOf, id);
   					const response = await paymentService.editPaymentPatch(path, options);

   					response?.data?.[0] === false && await this.onChangeResolution(true, paymentOrderId.valueOf);
   					case_15 && !this.state.sendFlexiGate && await this.props?.refreshData?.();
   					case_18 && await this.props?.refreshData?.();

   					if (!case_21 && case_6 && (case_4 || case_5) && (case_9 || case_13)) { //case_21 - Sharpay: Sharpay doesn't need flexiGate
                     // если ПОПОЛНЕНИЕ или ВЫВОД в ЛК через Карточку или Кошелек то меняем в состоянии поле sendFlexiGate
                     // (селект меняется на поле "ЗАПРОС ОТПРАВЛЕН")
   						await this.setFlexiGate?.(paymentOrderId?.valueOf);
   						"resolution" in options && response?.data?.[0] === false && NotificationService?.error?.({
   							title: "error",
   							message: this.props.translate('payment_table_flexi_error'),
   							remove: false,
   						});
   						response?.data?.[0] === false && await this.onChangeResolution(false, paymentOrderId.valueOf);
   					} else {
   						await this.props?.refreshData?.();
   					}

   					this.state.manuallyClosingUpdatedValue?.[paymentOrderId?.valueOf] === 1
                  && this.state?.resolution === 1
   						? await this.props?.refreshData?.() && await this.save({ sendResolutionRequest: {}, })
   						: null;

   					case_8 || case_9 || case_10 || case_15 && await this.props?.refreshData?.();
   					case_13 && this.state.manuallyClosingUpdatedValue?.[paymentOrderId?.valueOf] === 1 && this.state?.resolution === 1 ? await this.props?.refreshData?.() : null;
   				} catch (error) {
   					this.handleOnResolutionChangeResponseErrors(error, items.paymentOrderId.valueOf);
   				} finally {
   					await this.clearResolution?.();
   				}
   			},
   			onReject: async () => await this.clearResolution?.(),
   		});
   		const onChange = async value => await this.setResolution?.(paymentOrderId?.valueOf, value);
   		const STATUS_APPROVED = 1;
   		const STATUS_DECLINED = 2;
   		const STATUS_CHARGEBACK = 5;
   		let values = [];

   		if (condition_2 || condition_13) {
   			values = [ resolution, STATUS_APPROVED, STATUS_CHARGEBACK, ];
   		} else if (!case_5 || condition_3 || condition_4) {
   			values = [ resolution, STATUS_APPROVED, STATUS_DECLINED, ];
   		}
   		if (condition_5 || this.state.sendResolutionRequest?.[paymentOrderId?.valueOf]) {
   			const options = {
   				content: this.props.translate("payment_table_send_request"),
   				lengthCut: 20,
   			};

   			return (<MagicTooltip { ...options } />);
   		} else if (condition_2 || condition_3 || condition_4 || condition_7 || condition_8 || condition_9 || condition_10 || condition_11 || condition_12 || condition_14) {
   			const options = {
   				values: [ ...new Set(values), ],
   				ItemProps: value => value === +resolution ? { disabled: true, } : {},
   				value: values?.[0] ?? +resolution,
   				onChange: value => onChange(value),
   				valueToLabel: value => allResolutions?.[value === resolution ? resolution : value],
   			};

   			return (<div style={ { position: 'relative', } }>{ confirm }<MagicSelect { ...options } /></div>);
   		}
   	}
   	if (item?.access('show')) {
   		const options = {
   			content: `${ allResolutions?.[resolution] }`,
   			lengthCut: 20,
   		};

   		return (<MagicTooltip { ...options } />);
   	}

   	return "-";
   };

   render () {
   	if (!this.props?.data?.length) {
   		return null;
   	}

   	const options = {
   		config: this.paymentsConfig,
   		data: this.props?.data,
   		head: [
   			"paymentCreateAt",
   			"paymentOrderId",
   			"paymentSystemAccountId",
   			"paymentClientFullname",
   			"paymentResponsibleFullName",
   			"paymentCurrency",
   			"paymentAmount",
   			"paymentFee",
   			"paymentAmountPaid",
   			"paymentMethod",
   			"paymentSystem",
   			"paymentSystemPlatformName",
   			"paymentCWB",
   			"paymentCardHolder",
   			"paymentDescription",
   			"paymentDatePaid",
            // "paymentType",
   			"paymentError",
   			"paymentManuallyClosing",
   			"paymentStatus",
            // "paymentId",
   			"paymentResolution",
   		],
   	};

   	return (<MagicTable { ...options } />);
   }

}

ListPaymentsMagicTable.defaultProps = {
	orderManuallyCustomClosing: [
		[ 'Custom closing', ],
		[ 'Нестандартное закрытие', ],
		[ 'Нестандартне закриття', ],
	],
	orderManuallyStandardClosing: [
		[ 'Standart closing', ],
		[ 'Стандартное закрытие', ],
		[ 'Стандартне закриття', ],
	],
};

export default withRouter(withLocalize(ListPaymentsMagicTable));