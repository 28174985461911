import React from 'react';

import PermissionService, { MODULE_KEYS as pm, } from "../../services/PermissionService";

import Lead from "./components/Leads";
// import CreateClientNew from "./components/Leads/CreateClientNew";
import SingleTradingAccount from "./components/SingleTradingAccount/SingleTradingAccount";

// const Clients = lazy(() => import('./components/Clients'));
import Clients from './components/Clients';

export const clientsConfig = [
	{
		permission: pm.CORE_ACCOUNT,
		action: PermissionService.indexRoute(),
		component: <Clients />,
		path: [
			'/clients/category/:categoryId',
		],
	},
	{
		permission: pm.CORE_ACCOUNT,
		action: PermissionService.showRoute(),
		component: <Lead activeTab="support" />,
		path: [
			'/clients/:id/support',
		],
	},
	{
		permission: pm.CORE_ACCOUNT,
		action: PermissionService.showRoute(),
		component: <Lead activeTab="interaction" />,
		path: [
			'/clients/:id/interaction',
		],
	},
	{
		permission: pm.CORE_ACCOUNT,
		action: PermissionService.showRoute(),
		component: <Lead activeTab="conversations" />,
		path: [
			'/clients/:id/conversations',
		],
	},
	{
		permission: pm.CORE_ACCOUNT,
		action: PermissionService.showRoute(),
		component: <Lead activeTab="appeals" />,
		path: [
			'/clients/:id/tasks/task/:taskId',
			'/clients/:id/tasks',
		],
	},
	{
		permission: pm.CORE_ACCOUNT,
		action: PermissionService.showRoute(),
		component: <Lead activeTab="depositsandwithdrawals" />,
		path: [
			'/clients/:id/depositsandwithdrawals',
		],
	},
	{
		permission: pm.CORE_ACCOUNT,
		action: PermissionService.showRoute(),
		component: <Lead activeTab="tima" />,
		path: [
			'/clients/:id/tima/:accountTab',
			'/clients/:id/tima',
		],
	},
	{
		permission: pm.CORE_ACCOUNT,
		action: PermissionService.showRoute(),
		component: <Lead activeTab="accounts" />,
		path: [
			'/clients/:id/accounts/:accountTab',
			'/clients/:id/accounts',
		],
	},
	{
		permission: pm.LOG_MAILS,
		action: PermissionService.showRoute(),
		component: <Lead activeTab="letters" />,
		path: [
			'/clients/:id/letters',
		],
	},
	{
		permission: pm.CORE_ACCOUNT,
		action: PermissionService.showRoute(),
		component: <Lead activeTab="clientdata" />,
		path: [
			'/clients/:id/clientdata',
			'/clients/:id',
		],
	},
    // {
    //     permission: pm.CORE_ACCOUNT,
    //     action: PermissionService.showRoute(),
    //     component: <Lead activeTab='activity' />,
    //     path: [
    //         '/clients/:id/activity',
    //     ],
    // },

    // { permission: pm.CORE_ACCOUNT, action: PermissionService.storeRoute(), component: <CreateClientNew />, path: '/clientCreate' },
    // { permission: pm.CORE_ACCOUNT, action: PermissionService.updateRoute(), component: <CreateClientNew />, path: '/clientEdit/:id' },
	{
		permission: pm.CORE_ACCOUNT,
		action: PermissionService.indexRoute(),
		component: <Clients />,
		path: '/clients',
	},
	{ permission: null, component: <SingleTradingAccount />, path: '/tradingAccount/:id', },
];