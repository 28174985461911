import React from "react";
import PropTypes from 'prop-types';
import './styles.scss';
import PageLink, {
    propTypesPageClick,
    propTypesPageId,
    propTypesPageOptions,
    propTypesPageText,
    propTypesPageTo,
} from './PageLink';


const PageItem = ( props ) => {
    const { class4item, class4itemFirst, class4itemLast, class4itemPrev, class4itemNext, class4itemCurrent, } = props;
    const { disabled, options, } = props;
    const { is, } = options;
    const { current, first, prev, next, last, } = is;
    const classNames = [
            [ true, class4item ],
            [ current, class4itemCurrent, ],
            [ first, class4itemFirst, ],
            [ last, class4itemLast, ],
            [ next, class4itemNext, ],
            [ prev, class4itemPrev, ],
        ]
        .filter( ( [ _ ] ) => _ )
        .map( ( [ _, className ] ) => className )
        .join( ' ' );
    return (
      <li
        { ...{
            className: classNames,
            'data-disabled': disabled,
        } }
      >
          <PageLink { ...props }/>
      </li>
    );
};


PageItem.propTypes = {
    class4item: PropTypes.string,
    class4itemCurrent: PropTypes.string,
    class4itemFirst: PropTypes.string,
    class4itemLast: PropTypes.string,
    class4itemNext: PropTypes.string,
    class4itemPrev: PropTypes.string,
    disabled: PropTypes.bool,
    options: propTypesPageOptions,
};


PageItem.defaultProps = {
    class4item: `page__item`,
    class4itemCurrent: `page__item_current`,
    class4itemFirst: `page__item_first`,
    class4itemLast: `page__item_last`,
    class4itemNext: `page__item_next`,
    class4itemPrev: `page__item_prev`,
    disabled: false,
};


export default PageItem;
export {
    propTypesPageClick,
    propTypesPageId,
    propTypesPageText,
    propTypesPageTo,
};
