import React from 'react';

import PermissionService, { MODULE_KEYS as pm } from "../../services/PermissionService";

import CalendarMagic from "./components/CalendarMagic";

export const calendarConfig = [
    {
        permission: pm.CORE_USER_CALENDAR,
        action:     PermissionService.indexRoute(),
        component:  <CalendarMagic activeTab='month'/>,
        path:       [
            '/calendar/month',
        ],
    },
    {
        permission: pm.CORE_USER_CALENDAR,
        action:     PermissionService.indexRoute(),
        component:  <CalendarMagic activeTab='week'/>,
        path:       [
            '/calendar/week',
        ],
    },
    {
        permission: pm.CORE_USER_CALENDAR,
        action:     PermissionService.indexRoute(),
        component:  <CalendarMagic activeTab='day'/>,
        path:       [
            '/calendar/day',
            '/calendar'
        ],
    }
];
