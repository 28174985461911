import { DataService } from "./DataService";
import { hash as hashData, } from './CachingService';
import { get, post, put, del } from "../components/Magic/helpers/MagicRequest";
import {
    ADD_TRANSLATION,
    DELETE_CONTEST, DELETE_TRANSLATION,
    DISQUALIFY_MEMBER, GET_ALL_LANGUAGES, GET_ALL_NATIONALITY,
    GET_CONTEST_LIST_MEMBERS,
    GET_CONTEST_TRANSLATION,
    GET_CONTESTS, GET_TRANSLATIONS
} from "../modules/contests/apiRoutes";

class ContestDataService extends DataService {
    constructor () {
        super([
            'contests',
            'languages',
            'saveContest',
            'membersList',
            'contestById',
            'updateContest',
            'nationalities',
            'addTranslation',
            'delContestsById',
            'disqualifyMember',
            'delTranslationById',
            'contestsTranslations',
            'contestTranslationsById',
        ]);
        Object.freeze(this);
    }

    async contests ({ filterId, take, skip }) {
        const url = GET_CONTESTS;
        const { data, meta } = await get(url, { filterId, take, skip });
        const hash = await hashData(data);
        const contests = { data, hash, meta, };
        this.emit('contests', contests);

        return contests;
    }

    async membersList (contestId, { filterId, take, skip }) {
        const url = `${GET_CONTEST_LIST_MEMBERS}/${contestId}`;
        const { data, meta } = await get(url, { filterId, take, skip });
        const hash = await hashData(data);
        const membersList = { data, hash, meta, };
        this.emit('membersList', membersList);

        return membersList;
    }

    async disqualifyMember (memberId, { status }) {
        const url = `${DISQUALIFY_MEMBER}/${memberId}`;
        const data = await put(url, { status });
        const disqualifyMember = { data };
        this.emit('disqualifyMember', disqualifyMember);

        return disqualifyMember;
    }

    async contestById (contestId) {
        const url = `${GET_CONTESTS}/${contestId}`;
        const data = await get(url);
        const hash = await hashData(data);
        const contestById = { data, hash, };
        this.emit('contestById', contestById);

        return contestById;
    }

    async contestTranslationsById (contestId) {
        const url = `${GET_CONTEST_TRANSLATION}/${contestId}`;
        const data = await get(url);
        const hash = await hashData(data);
        const contestTranslationsById = { data, hash, };
        this.emit('contestTranslationsById', contestTranslationsById);

        return contestTranslationsById;
    }

    async contestsTranslations () {
        const url = `${GET_TRANSLATIONS}`;
        const data = await get(url);
        const hash = await hashData(data);
        const contestsTranslations = { data, hash, };
        this.emit('contestsTranslations', contestsTranslations);

        return contestsTranslations;
    }

    async nationalities () {
        const url = `${GET_ALL_NATIONALITY}`;
        const data = await get(url);
        const hash = await hashData(data);
        const nationalities = { data, hash, };
        this.emit('nationalities', nationalities);

        return nationalities;
    }

    async languages () {
        const url = `${GET_ALL_LANGUAGES}`;
        const data = await get(url);
        const hash = await hashData(data);
        const languages = { data, hash, };
        this.emit('languages', languages);

        return languages;
    }

    async delContestsById (contestId) {
        const url = `${DELETE_CONTEST}/${contestId}`;
        const data = await del(url);
        const delContestsById = { data };
        this.emit('delContestsById', delContestsById);
    }

    async delTranslationById (translationId) {
        const url = `${DELETE_TRANSLATION}/${translationId}`;
        const data = await del(url);
        const delTranslationById = { data };
        this.emit('delTranslationById', delTranslationById);
    }

    async saveContest (formData) {
        const url = `${GET_CONTESTS}`;
        const data = await post(url, formData);
        const hash = await hashData(data);
        const saveContest = { data, hash, };
        this.emit('saveContest', saveContest);

        return saveContest;
    }

    async updateContest (contestId, formData) {
        const url = `${GET_CONTESTS}/${contestId}`;
        const data = await post(url, formData);
        const hash = await hashData(data);
        const updateContest = { data, hash, };
        this.emit('updateContest', updateContest);

        return updateContest;
    }

    async addTranslation (formData) {
        const url = ADD_TRANSLATION;
        const data = await post(url, formData);
        const hash = await hashData(data);
        const addTranslation = { data, hash, };
        this.emit('addTranslation', addTranslation);

        return addTranslation;
    }
}

export const contestService = new ContestDataService();
