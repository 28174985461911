import React, { Component, } from "react";
import { PartnerRoleStatuses, } from "../consts/consts";
import { Popup, } from "../../../../components";
class ConfirmPartnerStatus extends Component {
	get statusOperationIs () {
		const { currentStatus, newStatus, } = this.props;
		const { ACTIVE, FROZEN, BLOCKED, } = PartnerRoleStatuses;

		return {
			ActiveToFrozen: currentStatus === ACTIVE && newStatus === FROZEN,
			ActiveToBlocked: currentStatus === ACTIVE && newStatus === BLOCKED,
			FrozenToBlocked: currentStatus === FROZEN && newStatus === BLOCKED,
			FrozenToActive: currentStatus === FROZEN && newStatus === ACTIVE,
		};
	}

	renderDescription () {
		const { translate, } = this.props;

		switch (true) {
			case this.statusOperationIs.ActiveToFrozen: {
				return translate('partner_change_partner_role_status_active_to_frozen_text');
			} case this.statusOperationIs.ActiveToBlocked: {
				return translate('partner_change_partner_role_status_active_to_blocked_text');
			} case this.statusOperationIs.FrozenToBlocked: {
				return translate('partner_change_partner_role_status_frozen_to_blocked_text');
			}  case this.statusOperationIs.FrozenToActive: {
				return translate('partner_change_partner_role_status_frozen_to_active_text');
			} default: {
				return '';
			}
		}
	}

	renderConfirmButton () {
		const { translate, } = this.props;

		switch (true) {
			case this.statusOperationIs.FrozenToActive: {
				return 'partner_confirm_proceed';
			}
			default: {
				return 'symbols_remove_accept';
			}
		}
	}

	renderDeclineButton () {
		const { translate, } = this.props;

		switch (true) {
			case this.statusOperationIs.FrozenToActive: {
				return 'partner_confirm_cancel';
			}
			default: {
				return 'symbols_remove_reject';
			}
		}
	}

    renderCheckBox = () => {
    	const { translate, handleOnCheckbox, is_payout, } = this.props;

    	return (
			<div className="block-header_input-checkbox switch-custom-styles">
				<label className="checkbox">
					<input
						checked={ is_payout }
						name="is_payout"
						type="checkbox"
						onClick={ handleOnCheckbox }
					/>
					<span className="checkbox__icon" />
				</label>
				<p className={ "checkbox__label" }> {translate("affiliate_program_with_renumeration")}</p>
    		</div>
    	);
    };

    render () {
    	const {
    		onConfirm, onCancel, translate, loaded
    	} = this.props;

    	return (
			<Popup
				cancelBtnText={ this.renderDeclineButton() }
				createBtnText={ this.renderConfirmButton() }
				isImage={ false }
				size={ 'smd' }
				loadData={loaded}
				title={ translate('partner_change_partner_role_status') }
				wrapperClassname={ `change-partner-programm change-programm-status text-center` }
				onClick={ onConfirm }
				onClose={ onCancel }
				showCloseButton={false}
			>
				<p className={ 'change-programm-status__description text-justify' }>{this.renderDescription()}</p>
				{this.statusOperationIs.FrozenToActive && this.renderCheckBox()}
    		</Popup>
    	);
    }
}

export default ConfirmPartnerStatus;