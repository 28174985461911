import * as actions from './actionTypes';

export const setAllTasks = (data) => ({
    type: actions.SET_TASKS,
    data,
});

export const setTask = (data) => ({
    type: actions.SET_TASK,
    data,
});

export const resetError = () => ({
    type: actions.RESET_ERROR,
    data: false,
});

export const setError = (data) => ({
    type: actions.SET_ERROR,
    data,
});

export const setFilter = (data) => ({
    type: actions.SET_FILTER,
    data,
});
export const getEnumsTasks = (data) => ({
    type: actions.GET_ENUMS_TASKS,
    data,
});
export const tasksTypes = (data) => ({
    type: actions.GET_TYPES_TASKS,
    data,
});
export const tasksStatus = (data) => ({
    type: actions.GET_STATUS_TASKS,
    data,
});
export const setOneHundredTasks = (data) => ({
    type: actions.SET_ONE_HUNDRED_TASKS,
    data,
});
export const setCommentsTaskID = (data) => ({
    type: actions.SET_COMMENTS_TASK_ID,
    data,
});
export const delComment = () => ({
    type: actions.DELETE_COMMENT,
    data: {},
});
export const setCountNotWork = (data) => ({
    type: actions.SET_COUNT_NOT_WORK,
    data,
});
export const setTaskUsers = (data) => ({
    type: actions.SET_TASK_USERS,
    data,
});
export const setAllTaskUsers = (data) => ({
    type: actions.SET_ALL_TASKS_USERS,
    data,
});
export const setParticipants = (data) => ({
    type: actions.SET_PARTICIPANTS,
    data,
});
export const delParticipants = () => ({
    type: actions.DELETE_PARTICIPANTS,
    data: {},
});
export const setSelectAccountModel = (data) => ({
    type: actions.SET_SELECT_ACCOUNT_MODEL,
    data,
});
export const delTask = () => ({
    type: actions.DELETE_TASK,
    data: {},
});
export const getTaskHis = (data) => ({
    type: actions.GET_HISTOR,
    data
});
export const delParticipantsClient = () => ({
  type: actions.DELETE_PARTICIPANTS_CLIENT,
  data: {},
});

export const clearTask = () => ({
    type: actions.CLEAR_TASK,
    data: {},
});

export const filterHash = (data) => ({
    type: actions.FILTER_HASH,
    data,
});
