import React                                       from 'react';
import PermissionService                           from '../../../../services/PermissionService';
import {
   getMailTemplatesByLanguageId,
   getSmsTemplatesByLanguageId,
   sendEmail,
   sendSms,
   sendMessageText }                               from '../../services/AccountRequestService';
import {
   MagicSelect,
   MagicInput,
   MagicButton,
   MagicTemplate }                                 from '../../../../components/Magic';
import NotificationService                         from '../../../../services/NotificationService';
import PropTypes                                   from 'prop-types';
import FileService                                 from "../../../../components/FileService/FileService";
import SliderMailTemplate                          from '../../../../modules/admin_panel/template_management/components/Modal/SliderMailTemplate';

export default class MessagePopupNew extends React.Component {

   static propTypes = {
      accountId: PropTypes.string,
      title:  PropTypes.string,
      isFilterTemplate:  PropTypes.bool,
      translate:  PropTypes.func,
      onCancel: PropTypes.func,
      className: PropTypes.string,
      languageId: PropTypes.number,
   };

   static defaultProps = {
      accountId: "0",
      languageId: null,
      title: "",
      isFilterTemplate: false,
      translate: () => {},
      onCancel: () => {},
      className: '',
   }

   constructor(props) {
      super(props);
      this.state = {
         data: {},
         selected: {},
         type: "",
         errors: [],
         position: "left",
         editorParams: {
            text: "",
            maxOnlyText: 3500,
            maxAllText: 10000,
            counterOnlyText: 0,
            counterAllText: 0,
         },
         maxFileSize: 10485760,
         templateDocs: [],
         disabled: false,
         subject: "",
      };
      this.editor = React.createRef();

      const fileServiceInstance = new FileService();

      Object.defineProperties(this, {
         fileServiceInstance: { get: () => fileServiceInstance },
      });
   }

   get pmConfig() {
      return [
         {
            path: ['core', 'account'],
            key: 'sendText',
         },
         {
            path: ['core', 'sms_templates'], //access('index)
            key: 'sendSmsTemplate',
         },
         {
            path: ['core', 'mail'], //access('index)
            key: 'sendMailTemplate',
         },
      ];
   };

   get pmService() { return PermissionService.calc(this.pmConfig); }

   get pmText() { return this.pmService?.sendText?.access('emailSendText'); }
   get pmSmsTemplate() { return this.pmService?.sendSmsTemplate?.access('index'); }
   get pmMailTemplate() { return this.pmService?.sendMailTemplate?.access('sendToAccount'); }

   componentDidMount = async () => {
      document.addEventListener('click', this.handleClickOutside);
      await this.getData();
      await this.fileServiceInstance.subscribe(this.documentsChange);
      await this.fileServiceInstance.updateDocument(this.state.templateDocs);
   };

   componentWillUnmount = async () => {
      document.removeEventListener('click', this.handleClickOutside);
      await this.fileServiceInstance.unsubscribe(this.documentsChange);
   };

   documentsChange = async () => this.save({ documentsChange: Date.now() });

   handlerFile = async (file) => {
      const options = { size: this.state?.maxFileSize, };
      const uploadFiles = await this.fileServiceInstance.getFileFromInput(options)(file);
      !uploadFiles && NotificationService.error({
         title:   'error',
         message: this.props.translate('template_management_error_size'),
         remove:  true,
         view:    true,
      });
   };

   save = async (state) => new Promise(next => this.setState(state, next));

   debounce = (fn, delay) => {
      let timeoutID;
      return function(...args) {
        timeoutID && clearTimeout(timeoutID)
        timeoutID = setTimeout(() => fn(...args), delay);
      };
   };

   handleClickOutside = (e) => {
      (e.target.className.includes('message-popup__overlay')) && this.props?.onCancel?.(e);
   };

   checkPosition = async () => {
      const { left } = document.querySelector('.message-popup__wrapper').getBoundingClientRect();
      await this.save({ position: left < 0 ? 'right' : 'left' })
   };

   getData = async () => {
      await this.checkPosition();
      await this._getOptionsTypesMessage();
      await this._getOptionsTypesTemplate();
      await this._getMailTemplates(this.props?.languageId);
      await this._getSMSTemplates(this.props?.languageId);
   };

   _getOptionsTypesMessage = async () => {
      let typesMessage = [{ value: "", label: null, }];

      if (this.pmText) {
         typesMessage.push({ value: 0, label: this.props.translate('client_message_type_text'), });
      }
      if (this.pmSmsTemplate || this.pmMailTemplate) {
         typesMessage.push({ value: 1, label: this.props.translate('client_message_type_template'),});
      }

      await this.save(state => ({
         data: { ...state.data, typesMessage, },
         type: typesMessage?.[0]?.value,
      }))
   };

   // toDo
   _getOptionsTypesTemplate = async () => {
      let typesTemplate = [{ value: "", label: null, }];
      if (this.pmMailTemplate) {
         typesTemplate.push(
            { value: 0, label: this.props.translate('dashboard_crm_email') },
            { value: 1, label: this.props.translate('dashboard_sendpulse') },
         );
      }
      if (this.pmSmsTemplate) {
         typesTemplate.push({ value: 2, label: this.props.translate('dashboard_sms') });
      }
      await this.save(state => ({
         data: { ...state.data, typesTemplate, },
         selected: { type: typesTemplate?.[0]?.value }
      }))
   };

   _getMailTemplates = async (languageId) => {
      try {
         if (this.pmMailTemplate) {
            const templates = await getMailTemplatesByLanguageId(languageId);
            let emails = [], sendpulse = [];
            // system_id = 1 -> emails, system_id = 2 -> sendpulse
            templates?.map(t => [1, "1"].includes(t?.system_id) ? emails.push(t) : sendpulse.push(t));
            await this.save(state => ({ data: { ...state.data, emails, sendpulse } }));
         }
      } catch (error) {
         error?.showErrorNotification?.();
      }
   };

   _getSMSTemplates = async (languageId) => {
      try {
         if (this.pmSmsTemplate) {
            const response = await getSmsTemplatesByLanguageId(languageId);
            const sms = response?.data?.map?.(item => {
               const { id: template_id, name: template_name } = item?.core?.sms_templates;
               return { template_name, template_id, }
            })
            await this.save(state => ({ data: { ...state.data, sms }}));
         }
      } catch (error) {
         error?.showErrorNotification?.();
      }
   };

   _sendText = async (account_id = 0, subject = "", text = "", files = [] ) => {
      try {
         await this.save({ errors: [], disabled: true, });
         const formData = new FormData();
         files?.forEach?.(x => formData.append('files[]', x));
         formData.append('account_id', account_id);
         formData.append('subject', subject);
         formData.append('text', text);
         await sendMessageText(formData);
         await this.props?.onCancel?.();
         this.showAlert({
            type: "success",
            title: "success",
            message: this.props.translate('client_message_send_success'),
         });
      } catch (errors) {
         await this.setErrors(errors?.response?.data);
         NotificationService.errors(errors?.response?.data);
      } finally {
         await this.save({ disabled: false, });
      }
   };

   _sendTemplate = async (selectedType) => {
      try {
         await this.save({ errors: [], disabled: true, });
         const { subject, selected: { template_id } = {} } = this.state;

         if (!template_id) return await this.checkTemplateId();

         const system_id = this.state?.data
            ?.[['emails', 'sendpulse'][selectedType]]
            ?.find(e => e?.template_id === template_id)?.system_id;
         let data;
         if (system_id) {
            data = {
               account_id: this.props?.accountId,
               ...subject && { subject },
               ...system_id && { system_id },
               ...template_id && { template_id },
            };
            await sendEmail(data);
         } else {
            data = {
               account_ids: [ this.props?.accountId ],
               ...subject && { subject },
               ...template_id && { template_id },
            };
            await sendSms(data);
         }
         this.showAlert({ type: "success", title: "success", message: this.props.translate('client_message_send_success'), });
         await this.props?.onCancel?.();
      } catch (errors) {
         this.setErrors(errors?.response?.data);
         NotificationService.errors(errors?.response?.data);
         await this.save({ disabled: false, });
      }
   };

   checkType = async () => !this.state.type && await this.setError('type');

   checkTemplateId = async () => {
      return typeof this.state?.selected?.type === 'number'
      ? await this.setError('template_id')
      : await this.setError('source')
   };

   handlerSendMessage = async (type, ) => {
      const { accountId } = this.props;
      const { subject, selected, editorParams: { text, }, } = this.state;
      switch (type) {
         // TEXT
         case 0:
            const files = this.fileServiceInstance.uploadingFiles.map(e => e?.file);
            return await this._sendText(accountId, subject, text, files);
          // TEMPLATE
         case 1:
            return await this._sendTemplate(selected?.type);
         default:
            return await this.checkType();
      };
   };

   setErrors = async ({ errors }) => await this.save({ errors: Object.keys(errors), });

   setError = async (error) => {
      await this.save(state => ({ errors: [...state.errors, error], disabled: false, }));
      await this.showErrors(this.state.errors);
   };

   showErrors = async (errors) => {
      errors?.forEach?.(error => {
         this.showAlert({ type: "error", title: "error", message: this.props.translate(`client_message_choose_${error}`) })
      })
   };

   showAlert = ({ type, title, message, }) => {
      return (NotificationService?.[type]({ title, message, time: 9000, }))
   };

   setSendPulseSubject = async (template_id) => {
      let newSubject;

      if (this.state?.selected?.type === 0) {
         newSubject = this.state?.data?.emails?.find(el => el?.template_id === template_id)?.template_subject;   
      }
      if (this.state?.selected?.type === 1) {
         newSubject = this.state?.data?.sendpulse?.find(el => el?.template_id === template_id)?.template_subject;   
      }

      if (newSubject !== '') { await this.save(state => ({ subject: newSubject })) }
   }

   clearError = async (field) => {
      if (field !== undefined) {
         let deleteCurentError = this.state.errors?.filter(i => i !== field);
         await this.save({ errors: deleteCurentError })
      } else {
         await this.save({ errors: [] })
      }
   };

   renderField = ({ field, title, description, className=""}) => {
      const error = this.state.errors.includes(field) ? "error" : "";
      return (
         <div className={`message-popup__field_wrapper`}>
            { title && <span className="message-popup__field_title">{ this.props.translate(title) }</span> }
            <span className={`message-popup__field_description  ${className} ${error}`}>{ description }</span>
         </div>
      )
   };

   renderType = () => {
      const options = {
         value: this.state?.type,
         values: this.state?.data?.typesMessage?.map(e => e?.value) ?? [],
         onChange:  async (value) => {
            await this.save(state => ({
                  type: value,
                  selected: { type: state.data?.typesTemplate?.[0]?.value, },
            }));
            await this.clearError();
         },
         ItemProps: value => value === "" ? { disabled: true, } : {},
         valueToLabel: value => {
            const i = this.state?.data?.typesMessage?.findIndex?.(v => value === v?.value);
            return this.state?.data?.typesMessage?.[ i ]?.label ?? this.props.translate('client_message_choose');
         },
      };
      return (
         this.renderField({
            field: 'type',
            title: 'client_message_enter_type',
            description: <MagicSelect { ...options } />,
         })
      );
   };

   renderSubject = () => {
      const { selected, type, subject, } = this.state;
      const isHidden = [].includes(selected?.type) || (["", 1].includes(type) && selected?.type === "");
      const isDisabled = [0, 2].includes(selected?.type);

      if (isHidden) return null;
      const options = {
         value: subject ?? "",
         placeholder: this.props?.translate("client_message_subject_ph"),
         onChange: async (value) => {
            await this.clearError('subject'); 
            await this.save({ subject: value, });
         },
         onValid: value => { if (value?.length > 60) throw new Error('error');},
         disabled: isDisabled,
      };

      return (
         this.renderField({
            field: "subject",
            title: 'client_message_subject',
            description: <MagicInput { ...options } />,
         })
      );
   };

   renderText = () => {
      const { text, } = this.state.editorParams;
      const options = {
         className: "message-popup__editor",
         placeholder: this.props?.translate('client_message_enter_text'),
         value: text,
         handleChange: async (value) => {
            const counterOnlyText = this.editor?.current?.querySelector('.ql-editor')?.textContent?.length;
            await this.save(state => ({
               editorParams: {
                  ...state.editorParams,
                  text: value,
                  counterAllText: value?.length,
                  counterOnlyText,
               }}));
            await this.clearError('text');
            },
      };
      return (
         this.renderField({
            field: "text",
            title: false,
            description: <div ref={this.editor}><MagicTemplate { ...options } /></div>,
            className: "editor__wrapper",
         })
      )
   };

   renderCounters = () => {
      const {
         editorParams: { maxOnlyText, maxAllText, counterOnlyText, counterAllText, },
         type
      } = this.state;
      const isTextType = [0].includes(type);
      return isTextType && (
         <div className="message-popup__counters">
            <div className="counter">{`${counterOnlyText}/${maxOnlyText}`}</div>
            {/*<div className="counter">{`${counterAllText}/${maxAllText}`}</div>*/}
         </div>)
   };

   renderTypeTemplate = () => {
      const { isFilterTemplate } = this.props;
      let values = this.state?.data?.typesTemplate?.map(e => e?.value) ?? [];
      const options = {
         value: this.state?.selected?.type ?? "",
         values: isFilterTemplate ? values?.filter?.(v => ![2].includes(v)) : values,
         onChange:  async (value) => {
            await this.save({ selected: { type: value, },});
            await this.clearError();
         },
         ItemProps: value => value === "" ? { disabled: true, } : {},
         valueToLabel: value => {
            const i = this.state?.data?.typesTemplate?.findIndex?.(v => value === v?.value)
            return this.state?.data?.typesTemplate?.[ i ]?.label ?? this.props.translate('client_message_choose');
         },
      }
      return (
         this.renderField({
            field: 'source',
            title: 'client_message_source',
            description: <MagicSelect {...options} />,
         })
      )
   };

   renderTemplate = typeTemplate => {
      const { data, selected } = this.state;
      if (selected?.type === "") return null;
      let values = data?.[['emails', 'sendpulse', 'sms'][typeTemplate]];

      const options = {
         value: selected?.template_id ?? "",
         values: values?.map?.(e => e?.template_id) ?? [],
         onChange:  async (value) => {
            await this.setSendPulseSubject(value);
            await this.save(state => ({ selected: { ...state.selected, template_id: value, },}));
            await this.clearError();
         },
         valueToLabel: value => {
            const i = values?.findIndex?.(v => value === v?.template_id);
            return values?.[ i ]?.template_name ?? this.props?.translate('client_select_template');
         },
      }
      return (
         this.renderField({
            field: "template_id",
            title: 'dashboard_template',
            description: <MagicSelect {...options} />,
         })
      )
   };

   renderFields = () => {
      const { type, } = this.state;
      if (type === "") return null;
      const isTextType = [0].includes(type);
      return isTextType
         ?  this.renderText()
         :  <React.Fragment>
               { this.renderTypeTemplate() }
               { this.renderTemplate(this.state?.selected?.type) }
            </React.Fragment>
   };

   updateTemplateTranslation = async (translate) => {
      try {
          await this.fileServiceInstance.updateDocument(this.state.templateDocs);
          NotificationService.success({
              title:   'success',
              message: translate(`template_management_update_template_translation`),
              remove:  true,
              view:    true,
          });
      } catch (error) {
          NotificationService.errors(error);
      }
  };

   deleteDocument = async (itemIndex) => {
      await this.fileServiceInstance.filterDocumentArray(itemIndex);
      await this.fileServiceInstance.changeDocuments();
      NotificationService.success({
          title:   'success',
          message: this.props.translate(`template_management_delete_doc_translation`),
          remove:  true,
          view:    true,
      });
   };

   renderInputFiles = () => {
      if (["", "1", 1,].includes(this.state?.type)) return null;
      const options = {
         type: "file",
         accept: "application/pdf,image/jpeg,image/png",
         onChange: async (e) => this.handlerFile(e),
         onClick: e => e.target.value = "",
      };
      return (
         <div className="message-popup__files">
            <label className={`files__add ${ this.fileServiceInstance.uploadingFiles?.length >= 5 && 'disabled' }`} title={ this.props?.translate("client_message_add_files") }>
               <span>{ this.props?.translate("client_message_add_files") }</span>
               <input {...options} />
            </label>
            {this.fileServiceInstance?.uploadingFiles?.length > 0 &&
               <SliderMailTemplate
                  mail_template_t_documents = { this.fileServiceInstance.uploadingFiles }
                  updateTemplateTranslation = { this.updateTemplateTranslation }
                  deleteDocument={ this.deleteDocument }
               />
            }
         </div>

      )
   };

   renderBtns = () => {
      const { disabled, selected, type} = this.state;
      const optionsCancel = {
         className: "magic-button_cancel",
         onClick: async (e) => await this.props?.onCancel(e),
         children: this.props?.translate('client_message_cancel'),
         disabled,
      };

      const optionsSend = {
         className: "magic-button_send",
         onClick: this.debounce(async () => await this.handlerSendMessage(this.state?.type), 1000),
         children: this.props?.translate('kpi_send'),
         disabled,
      };

      return (
         <div className="message-popup__buttons">
            <MagicButton {...optionsCancel} />
            <MagicButton {...optionsSend} />
         </div>
      )
   };

   renderPopup = () => {
      const { accountId, className } = this.props;
      const { position, } = this.state;
      return (
         <div className={ `message-popup__wrapper message-popup__wrapper_${ position } ${ className }` }>
            { accountId && <div className="message-popup__overlay"></div> }
            <div className="message-popup__title">{this.props.title ?? ""}</div>
               { this.renderType() }
               { this.renderSubject() }
               { this.renderCounters() }
               { this.renderFields() }
               { this.renderInputFiles() }
               { this.renderBtns() }
            </div>
      )
   };

   render = () => this.renderPopup();
}
