export const globalLang = {
    "login":            ["Login", "Увійти", "Войти"],
    "profile":          ["Profile", "Профіль", "Профиль"],
    "logout":           ["Logout", "Вийти", "Выйти"],
    "global_standings": ["Standings", "Рейтинг", "Рейтинг"],
    "global_search":    ["Search", "Поиск", "Пошук"],

    "export_excel_btn":           ["Export", "Экспорт", "Експорт"],
    "export_excel_with_file_btn": ["Configurable export", "Настраиваемый экспорт", "Налаштовуваний експорт"],
};
