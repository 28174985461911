export const SALES_PLANNING_EXPORT_FIELDS = {
   salesPlanning: [
      'core-user-id',
      'core-user-name',
      'core-user-surname',
      'core-user_kpi_plan-kpi_type_id',
      'core-user_kpi_plan-year',
      'core-user_kpi_plan-kpi_month_1',
      'core-user_kpi_plan-kpi_month_2',
      'core-user_kpi_plan-kpi_month_3',
      'core-user_kpi_plan-kpi_month_4',
      'core-user_kpi_plan-kpi_month_5',
      'core-user_kpi_plan-kpi_month_6',
      'core-user_kpi_plan-kpi_month_7',
      'core-user_kpi_plan-kpi_month_8',
      'core-user_kpi_plan-kpi_month_9',
      'core-user_kpi_plan-kpi_month_10',
      'core-user_kpi_plan-kpi_month_11',
      'core-user_kpi_plan-kpi_month_12',
   ],
};
