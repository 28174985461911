export const managerPageLang = {
    "managerPage_admin_panel": ["Admin panel", "Админ панель", "Адмін панель"],
    "managerPage_users":["Users", "Пользователи","Користувачі"],
    "managerPage_advanced_filters": ["Advanced Filters", "Расширенные фильтры","Розширені фільтри"],
    "managerPage_create_new":["Create new", "Создать новый", "Створити новий"],
    "managerPage_export_to_excel": ["Export", "Экспорт","Експорт"],
    'managerPage_id':["ID", "ID", "ID"],
    'managerPage_avatar':["Avatar", "Аватар", "Аватар"],
    'managerPage_name':["First name", "Имя","Iм'я"],
    'managerPage_fullname':["First Name Last Name", "Имя Фамилия","Ім'я Прізвище"],
    'managerPage_adaptation':["Adaptation", "Адаптация", "Адаптація"],
    'managerPage_email':["Email", "Эл. адрес *","Електронна пошта *"],
    'managerPage_phone':["Phone *", "Телефон *","Телефон *"],
    'managerPage_internal_phone':["Manager line", "Линия менеджера", "Лінія менеджера"],
    'managerPage_status':["Status *", "Статус *","Статус *"],
    'managerPage_nickname':["Nickname", "Прозвище","Псевдонім"],
    'managerPage_actions':["Actions", "Действия","Дії"],
    'managerPage_reset':["Reset", "Сброс","Скидання"],
    'managerPage_search':["Search", "Поиск", "Пошук"],
    "managerPage_from": ["from", "из", "з"],
    "managerPage_first": ["First", "Начало","Початок"],
    "managerPage_last": ["Last", "Последняя","Остання"],
    "managerPage_user_list": ["User List", "Список пользователей", "Список користувачів"],
    "managerPage_new_user":["Create new user", "Создание нового пользователя", "Створення нового користувача"],
    "managerPage_first_name": ["First Name *", "Имя *", "Ім'я *"],
    "managerPage_last_name": ["Last Name *", "Фамилия *", "Прізвище *"],
    "managerPage_image_profile":["Image Profile", "Изображение профиля", "Зображення профілю"],
    "managerPage_choose_image":["Choose image", "Выберите изображение", "Виберіть Зображення"],
    "managerPage_photo_up":["Image up to 1МБ, 1024х1024 jpg, png", "Изображение до 1МБ, 1024х1024 jpg, png", "Зображення до 1МБ, 1024х1024 jpg, png"],
    "managerPage_birth_date":["Birth Date", "Дата рождения", "День народження"],
    "managerPage_password":["Password *", "Пароль *", "Пароль *"],
    "managerPage_password_conf":["Password confirmation *", "Подтверждение пароля *", "Підтвердження паролю *"],
    "managerPage_created": ["Created", "Создан", "Створено"],
    "managerPage_access_rights": ["Access Rights *", "Права доступа *", "Права доступу *"],
    "managerPage_note":["Note", "Примечание", "Примітка"],
    "managerPage_languages":["Languages *", "Языки *", "Мови *"],
    "managerPage_interface":["Interface language", "Язык интерфейса", "Мова інтерфейсу"],
    "managerPage_settings":["Account Settings", "Настройки интерфейса", "Налаштування інтерфейсу"],
    "managerPage_account":["Account", "Аккаунт", "Акаунт"],
    "managerPage_profile_settings":["Profile Settings", "Настройки профиля", "Налаштування профиля"],
    "managerPage_sidebar":["Sidebar", "Сайдбар", "Сайдбар"],
    "managerPage_schedule":["Schedule", "График", "Розклад"],
    "managerPage_notification":["Notification", "Уведомления", "Сповіщення"],
    "managerPage_save":["Save", "Сохранить", "Зберегти"],
    "managerPage_create":["Create", "Создать", "Створити"],
    "managerPage_cancel":["Cancel", "Отменить", "Скасувати"],
    "managerPage_block":["Block", "Заблокировать", "Заблокувати"],
    "managerPage_monday":["Monday", "Заблокировать", "Заблокувати"],
    "managerPage_tuesday":["Tuesday", "Заблокировать", "Заблокувати"],
    "managerPage_wednesday":["Wednesday", "Заблокировать", "Заблокувати"],
    "managerPage_thursday":["Thursday", "Заблокировать", "Заблокувати"],
    "managerPage_friday":["Friday", "Заблокировать", "Заблокувати"],
    "managerPage_saturday":["Saturday", "Заблокировать", "Заблокувати"],
    "managerPage_sunday":["Sunday", "Заблокировать", "Заблокувати"],
    "managerPage_vacation":["Vacation", "Отпуск","Відпустка"],
    "managerPage_profile":["Profile", "Профиль","Профіль"],
    "template_pm_apply": ["Apply", "Применить", "Застосувати"],
    "template_pm_admin": ["Admin", "Админ", "Адмiн"],
    "template_pm_director": ["Director", "Директор", "Директор"],
    "template_pm_sales_manager": ["Sales manager", "Руководитель отдела продаж", ""],
    "template_pm_timlid_sales_department": ["Timlid sales department", "Тимлид отдела продаж", ""],
    "template_pm_r_manager": ["Retension manager", "Менеджер ретеншена", ""],
    "template_pm_conversion_manager": ["Conversion manager", "Менеджер конверсии", ""],
    "template_pm_verification_manager": ["Verification manager", "Менеджер верификации", ""],
    "template_pm_head_of_marketing_department": ["Head of marketing", "Руководитель отдела маркетинга", ""],
    "template_pm_partnership_department": ["Partnership department", "Партнёрский отдел", ""],
    "template_pm_marketer": ["Marketer", "Маркетолог", "Маркетолог"],
    "template_pm_hr": ["HR", "HR", ""],
    "template_pm_financier": ["Financier", "Финансист", ""],
    "template_pm_it_specialist": ["It specialist", "ИТ специалист", ""],
    "verification": ["Verification", "Верификация", "Верифікація"],
    "technical_support": ["Technical support", "Техническая поддержка", "Технічна підтримка"],
    "system": ["System", "Система", "Система"],
    "sales": ["Sales", "Отдел продаж", "Відділ продажів"],
    "retention": ["Retention", "Ретеншн", "Ретеншн"],
    "head_of_marketing": ["Marketing", "Маркетинг", "Маркетинг"],
    "financial": ["Financial", "Финансовый отдел", "Фінансовий відділ"],
    "conversion": ["Сonversion", "Конверсия", "Конверсія"],
    "back_office": ["Back office", "Бек офис", "Бек офіс"],
    "analytics": ["Analytics", "Аналитика", "Аналітика"],
    "affiliate": ["Affiliate", "Партнерский отдел", "Партнерський відділ"],
    "administration_of_sales": ["Administration of sales", "Администратор отдела продаж", "Адміністратор відділу продажів"],
    "administration": ["Administration", "Администрация", "Адміністрація"],
    "managerPage_department": ["Department Name", "Наименование отдела", "Найменування відділу"],
    "managerPage_template_permission":["Access rights *", "Права доступа *", "Права доступу *"],
    "managerPage_permission_templates": ["Access rights", "Права доступа", "Права доступу"],
    "managerPage_kpi_type_id": [ "Type", "Тип", "Тип" ],
    "managerPage_year": [ "Year", "Год", "Рік" ],
    "managerPage_active": [ "Active", "Активен", "Активен" ],
    "managerPage_capacity_of_opportunity":["The capacity of opportunity *", "Пропускная возможность *", "Пропускна можливість *"],
    "managerPage_error": ["Invalid input", "Недопустимый ввод", "Неприпустиме введення"],
    "managerPage_fail_validation": ["Validation error", "Ошибка проверки", "Помилка перевірки"],
    "managerPage_update_user": ["User updated successfully", "Пользователь успешно обновлен", "Користувач успішно оновлений"],
    "managerPage_confirm_pass_error": ["Password mismatch", "Пароли не совпадают", "Паролі не співпадають"],
    "managerPage_pass_error": ["Your password must be between 8 and 30 characters long and contain at least one uppercase letter, one character, and a number",
        "Ваш пароль должен быть от 8 до 30 символов длиной, и содержать минимум одну заглавную букву, один символ, и число",
        "Ваш пароль повинен бути від 8 до 30 символів довжиною, і містити мінімум одну велику літеру, один символ, і число"
    ],
    "managerPage_confirm": ["Сurrent changes won't be saved. Continue?", "Текущие изменения не будут сохранены. Продолжить?", "Поточні зміни не будуть збережені. Продовжити?"],
    "managerPage_confirm_accept": ["Yes", "Да", "Так"],
    "managerPage_confirm_reject": ["No", "Нет", "Ні"],
    "daily_load": ["Daily load", "Дневная нагрузка", "Денне навантаження"],

    "events_notifications": ["Notifications", "Уведомления", "Сповіщення"],
    "events_push_notifications": ["Push", "Push", "Push"],
    "events_email_notifications": ["Email", "Email", "Email"],


    "events_notifications_disabled": ["Disabled", "Выключены", "Вимкнені"],
    "events_notifications_enabled": ["Enabled", "Включены", "Ввімкнені"],

    "events_notifications_push_and_notifications": ["Push and notification", "Push и уведомление", "Push та сповіщення"],
    "events_notifications_notifications_only": ["Notification only", "Только уведомление", "Тільки сповіщення"],


}
