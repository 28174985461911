import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";


import { MagicConfirm } from "../../../../../../../tima/components/Magic/MagicConfirm";
import MessagePopupNew from "../../../../../../../clients/components/MessagePopup/MessagePopupNew";

import { setErrorClass } from "../../../../../../../clients/components/Leads/helpers/validation-helpers";
import PermissionService, {
	MODULE_KEYS as pm,
} from "../../../../../../../../services/PermissionService";

class ActionsDropdown extends Component {
	get translate() {
		return this.props.translate;
	}

	get isTechSupport() {
		return this.props.profile?.data?.tree_info?.some(
			department => department.department_alias === "technical_support",
		);
	}

	get isBackOffice() {
		return this.props.profile?.data?.tree_info?.some(
			department => department.department_alias === "back_office",
		);
	}

	get permissionToChangeEmail() {
		const permissionToUpdateEmail = this.getAccountPropertyPermissions(
			"update",
			"changeEmail",
		);
		const is_email_verify = this.props.account?.is_email_verify;
		if (this.isTechSupport) {
			return permissionToUpdateEmail && is_email_verify === 2;
		}

		return permissionToUpdateEmail;
	}

	get permissionToVerifyEmail() {
		const is_email_verify = this.props.account?.is_email_verify;
		return (
			this.getAccountPropertyPermissions("update", "verifyEmail") &&
			is_email_verify === 2
		);
	}

	get permissionToChangePhone() {
		const permissionToUpdatePhone = this.getAccountPropertyPermissions(
			"update",
			"changePhone",
		);
		const is_phone_verify = this.props.account?.is_phone_verify;

		if (this.isTechSupport) {
			return permissionToUpdatePhone && is_phone_verify === 2;
		}

		return permissionToUpdatePhone;
	}

	get permissionToVerifyPhone() {
		const is_phone_verify = this.props.account?.is_phone_verify;
		return (
			this.getAccountPropertyPermissions("update", "verifyPhone") &&
			is_phone_verify === 2
		);
	}

	getAccountPropertyPermissions = (action, key) => {
		const permissions = PermissionService.calc(this.props.accountPropertyConfig);
		return permissions[key].access(action);
	};

	save = state => new Promise(next => this.setState(state, next));

	renderConfirmBlock = () => {
		const { handleResendEmail, handleRejectResendEmail } = this.props;
		return (
			<MagicConfirm
				className="magic-confirm magic-confirm--right account-confirm"
				onAccept={handleResendEmail}
				onReject={handleRejectResendEmail}
				title={this.props.translate(`magic_confirm_title_account`)}
				accept={this.props.translate(`magic_confirm_yes`)}
				reject={this.props.translate(`magic_confirm_no`)}
				isVisible={true}
			/>
		);
	};

	popUpItem = () => {
		const {
			account,
			togglePopupItem,
			closePopupAction,
			setNewPhone,
			action_phone,
			setNewEmail,
			action_mail,
			errors,
			handleChangeEmail,
			handleChangePhone,
			handleVerifyPhone,
			handleVerifyEmail,
			updateEmail,
			updatePhone,
		} = this.props;

		if (togglePopupItem.type.includes("duplicate")) {
			/*START temporary template with 30 id*/
			const numbers = [...Array(30).keys()];
			const listItems = numbers.map((number, key) => (
				<li key={key}>
					<label className="checkbox">
						<input type="checkbox" />
						<span />
					</label>
					<p>
						{`ID ${number}`} &nbsp;&nbsp;&nbsp; {`Ambros Johnson-${number}`}
					</p>
				</li>
			));
			/*END*/

			return (
				<div
					className="popup_DropdownItem popup_DropdownItem-left"
					style={{ top: togglePopupItem.top }}
				>
					<div className="popup_DropdownItem_modal">
						<div className="modal-popup-title">
							<i className="modal_duplicate" />
							{this.props?.translate("client_snap_as_duplicate")}
							<button
								className="modal-popup-close-button"
								onClick={closePopupAction}
							/>
						</div>

						<div className="modal-popup-body">
							<div className="modal-popup-body_dublicate">
								<input className="input_dublicate" type="text" />
								<Scrollbars
									style={{ width: "96%", height: 340, margin: "20px 0" }}
								>
									<ul>
										{/*Temporary template with 30 id*/}
										{listItems}
									</ul>
								</Scrollbars>
							</div>
						</div>

						<div className="modal-popup-btn">
							<span
								className="modal-popup-btn-cancel"
								onClick={() => closePopupAction()}
							>
								{this.props?.translate(
									"account_comments_customer_button_cancel",
								)}
							</span>
							<span className="modal-popup-btn-add">
								{this.props?.translate("account_comments_customer_button_add")}
							</span>
						</div>
					</div>
				</div>
			);
		}
		if (togglePopupItem.type.includes("change_phone")) {
			return (
				<div
					className="popup_DropdownItem popup_DropdownItem-left"
					style={{ top: togglePopupItem.top }}
				>
					<div className="popup_DropdownItem_modal">
						<div className="modal-popup-title">
							<i className="modal_phone" />
							{this.props?.translate("client_change_phone")}
							<button
								className="modal-popup-close-button"
								onClick={closePopupAction}
							/>
						</div>
						<div className="modal-popup-body pseudo-plus">
							<input
								autoComplete="off"
								className={`input_phone_mail pseudo-plus__input ${setErrorClass(
									errors,
									"change_phone",
								)}`}
								name={"change_phone"}
								onChange={setNewPhone}
								type="text"
								value={action_phone}
								placeholder={this.props?.translate("client_change_phone_ph")}
							/>
						</div>
						<div className="modal-popup-btn">
							<span
								className="modal-popup-btn-cancel"
								onClick={() => closePopupAction()}
							>
								{this.props?.translate(
									"account_comments_customer_button_cancel",
								)}
							</span>
							<span className="modal-popup-btn-add" onClick={handleChangePhone}>
								{this.props?.translate("client_change_phone")}
							</span>
						</div>
					</div>
				</div>
			);
		}

		if (togglePopupItem.type.includes("verify_phone")) {
			return (
				<div
					className="popup_DropdownItem popup_DropdownItem-left"
					style={{ top: togglePopupItem.top }}
				>
					<div className="popup_DropdownItem_modal">
						<div className="modal-popup-title">
							<i className="modal_phone" />
							{this.props?.translate("client_verify_phone")}
							<button
								className="modal-popup-close-button"
								onClick={closePopupAction}
							/>
						</div>

						<div className="modal-popup-body">
							<p>
								{this.props?.translate("client_verify_phone_content")}{" "}
								{this.props.account.phone}?
							</p>
						</div>

						<div className="modal-popup-btn">
							<span
								className="modal-popup-btn-cancel"
								onClick={() => closePopupAction()}
							>
								{this.props?.translate(
									"account_comments_customer_button_cancel",
								)}
							</span>
							<span className="modal-popup-btn-add" onClick={handleVerifyPhone}>
								{this.props?.translate("client_verify_phone")}
							</span>
						</div>
					</div>
				</div>
			);
		}

		if (togglePopupItem.type.includes("phone")) {
			return (
				<div
					className="popup_DropdownItem popup_DropdownItem-left"
					style={{ top: togglePopupItem.top }}
				>
					<div className="popup_DropdownItem_modal">
						<div className="modal-popup-title">
							<i className="modal_phone" />
							{this.props?.translate("client_add_phone")}
							<button
								className="modal-popup-close-button"
								onClick={closePopupAction}
							/>
						</div>

						<div className="modal-popup-body pseudo-plus">
							<input
								autoComplete="off"
								className="input_phone_mail pseudo-plus__input"
								name={"phones"}
								onChange={setNewPhone}
								type="text"
								value={action_phone}
							/>
						</div>
						<div className="modal-popup-btn">
							<span
								className="modal-popup-btn-cancel"
								onClick={() => closePopupAction()}
							>
								{this.props?.translate(
									"account_comments_customer_button_cancel",
								)}
							</span>
							<span className="modal-popup-btn-add" onClick={updatePhone}>
								{this.props?.translate("account_comments_customer_button_add")}
							</span>
						</div>
					</div>
				</div>
			);
		}

		if (togglePopupItem.type.includes("change_email")) {
			return (
				<div
					className="popup_DropdownItem popup_DropdownItem-left"
					style={{ top: togglePopupItem.top }}
				>
					<div className="popup_DropdownItem_modal">
						<div className="modal-popup-title">
							<i className="modal_email" />
							{this.props?.translate(`clients_change_email`)}
							<button
								className="modal-popup-close-button"
								onClick={closePopupAction}
							/>
						</div>

						<div className="modal-popup-body">
							<input
								autoComplete="off"
								className={`input_phone_mail ${setErrorClass(
									errors,
									"change_email",
								)}`}
								name={"change_email"}
								onChange={setNewEmail}
								type="text"
								value={action_mail}
								placeholder={this.props?.translate("client_email_input_ph")}
							/>
						</div>

						<div className="modal-popup-btn">
							<span
								className="modal-popup-btn-cancel"
								onClick={() => closePopupAction()}
							>
								{this.props?.translate(
									"account_comments_customer_button_cancel",
								)}
							</span>
							<span className="modal-popup-btn-add" onClick={handleChangeEmail}>
								{this.props?.translate("clients_change_email")}
							</span>
						</div>
					</div>
				</div>
			);
		}

		if (togglePopupItem.type.includes("verify_email")) {
			return (
				<div
					className="popup_DropdownItem popup_DropdownItem-left"
					style={{ top: togglePopupItem.top }}
				>
					<div className="popup_DropdownItem_modal">
						<div className="modal-popup-title">
							<i className="modal_email" />
							{this.props?.translate(`client_verify_email`)}
							<button
								className="modal-popup-close-button"
								onClick={closePopupAction}
							/>
						</div>

						<div className="modal-popup-body">
							<p>
								{this.props?.translate("client_verify_email_content")}{" "}
								{this.props.account.email}?
							</p>
						</div>

						<div className="modal-popup-btn">
							<span
								className="modal-popup-btn-cancel"
								onClick={() => closePopupAction()}
							>
								{this.props?.translate(
									"account_comments_customer_button_cancel",
								)}
							</span>
							<span className="modal-popup-btn-add" onClick={handleVerifyEmail}>
								{this.props?.translate("client_verify_email")}
							</span>
						</div>
					</div>
				</div>
			);
		}

		if (togglePopupItem.type.includes("email")) {
			return (
				<div
					className="popup_DropdownItem popup_DropdownItem-left"
					style={{ top: togglePopupItem.top }}
				>
					<div className="popup_DropdownItem_modal">
						<div className="modal-popup-title">
							<i className="modal_email" />
							{this.props?.translate("client_add_email")}
							<button
								className="modal-popup-close-button"
								onClick={closePopupAction}
							/>
						</div>

						<div className="modal-popup-body">
							<input
								autoComplete="off"
								className="input_phone_mail"
								name={"emails"}
								onChange={setNewEmail}
								type="text"
								value={action_mail}
							/>
						</div>

						<div className="modal-popup-btn">
							<span
								className="modal-popup-btn-cancel"
								onClick={() => closePopupAction()}
							>
								{this.props?.translate(
									"account_comments_customer_button_cancel",
								)}
							</span>
							<span className="modal-popup-btn-add" onClick={updateEmail}>
								{this.props?.translate("account_comments_customer_button_add")}
							</span>
						</div>
					</div>
				</div>
			);
		}

		if (togglePopupItem?.type?.includes?.("message")) {
			return (
				<div
					className="dropDown__modal dropDown__modal_zIndex"
					style={{ top: `calc(${togglePopupItem.top} + 30px)` }}
				>
					<button
						className="modal-popup-close-button"
						onClick={closePopupAction}
					/>
					<MessagePopupNew
						className={"dropDown__modal_message"}
						accountId={String(6)}
						languageId={account.languageId}
						title={this.props.translate("client_message_send")}
						translate={this.props.translate}
						onCancel={() => closePopupAction()}
					/>
				</div>
			);
		}
	};

	renderDropDown = () => {
		const {
			togglePopupItem,
			onArchiveBtnClick,
			_isAccountStatusArchived,
			account,
			dropdownOpen,
			dropdownItemNav,
			setStateModalCreateTask,
			confirmRegistration,
			handleChangeWarmType,
			user,
		} = this.props;

		const accountId = account.id;
		const permissionToChangeEmail = this.permissionToChangeEmail;
		const permissionToChangePhone = this.permissionToChangePhone;
		const permissionToVerifyEmail = this.permissionToVerifyEmail;
		const permissionToVerifyPhone = this.permissionToVerifyPhone;
		const isBackOffice = this.isBackOffice;

		return (
			<>
				{togglePopupItem.bool ? <>{this.popUpItem()}</> : null}

				{dropdownOpen && <div className="dropDown__overlay" />}
				<div className={`dropDown__wrapper ${dropdownOpen ? "open" : "close"}`}>
					<div
						className="dropDown__item"
						onClick={() => {
							setStateModalCreateTask(1, false);
						}}
					>
						<i className="action_tasks" />
						{this.props?.translate(`client_create_task`)}
					</div>
					<div
						className="dropDown__item"
						onClick={e => dropdownItemNav(e)}
						data-type="duplicate"
					>
						<i className="action_dublicate" />
						{this.props?.translate(`client_snap_as_duplicate`)}
					</div>
					<div
						className="dropDown__item"
						onClick={e => dropdownItemNav(e)}
						data-type="message"
					>
						<i className="action_message" />
						{this.props?.translate(`client_send_message`)}
					</div>
					{account?.warm_type === 2 && user?.id === 24 ? (
						<div
							className="dropDown__item"
							onClick={() => handleChangeWarmType()}
						>
							<i className="action_change" />
							{this.props?.translate("client_change_warmth")}
						</div>
					) : null}
					{PermissionService.actionForRoute(
						pm.CORE_ACCOUNT,
						"resendActivationEmail",
					) &&
					account?.status === 1 &&
					account?.is_email_verify === 2 ? (
						<div
							className="dropDown__item"
							onClick={() => this.props.showConfirmBlock()}
						>
							<i className="action_add-resend-reg-email" />
							{this.props?.translate("client_add_resend-reg-email")}
						</div>
					) : null}
					<div
						className="dropDown__item"
						onClick={e => dropdownItemNav(e)}
						data-type="phone"
					>
						<i className="action_add-phone" />
						{this.props?.translate(`client_add_phone`)}
					</div>
					{permissionToChangePhone && (
						<div
							className="dropDown__item"
							onClick={e => dropdownItemNav(e)}
							data-type="change_phone"
						>
							<i className="action_add-phone" />
							{this.props?.translate(`client_change_phone`)}
						</div>
					)}
					{permissionToVerifyPhone &&
						isBackOffice && (
							<div
								className="dropDown__item"
								onClick={e => dropdownItemNav(e)}
								data-type="verify_phone"
							>
								<i className="action_add-phone" />
								{this.props?.translate(`client_verify_phone`)}
							</div>
						)}
					<div
						className="dropDown__item"
						onClick={e => dropdownItemNav(e)}
						data-type="email"
					>
						<i className="action_add-email" />
						{this.props?.translate(`client_add_email`)}
					</div>
					{permissionToChangeEmail && (
						<div
							className="dropDown__item"
							onClick={e => dropdownItemNav(e)}
							data-type="change_email"
						>
							<i className="action_add-email" />
							{this.props?.translate(`clients_change_email`)}
						</div>
					)}
					{permissionToVerifyEmail &&
						isBackOffice && (
							<div
								className="dropDown__item"
								onClick={e => dropdownItemNav(e)}
								data-type="verify_email"
							>
								<i className="action_add-email" />
								{this.props?.translate(`client_verify_email`)}
							</div>
						)}
					<div
						className="dropDown__item"
						onClick={onArchiveBtnClick(accountId)}
					>
						<i className="action_tasks" />
						{!_isAccountStatusArchived()
							? this.props?.translate(`client_archive`)
							: this.props?.translate(`client_unzip`)}
					</div>
					{confirmRegistration && this.renderConfirmBlock(accountId)}
				</div>
			</>
		);
	};

	render() {
		return <>{this.renderDropDown()}</>;
	}
}

export default ActionsDropdown;
