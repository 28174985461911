import React, {Component} from 'react';
import {Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import {Link} from "react-router-dom";
import {Translate, withLocalize} from "react-localize-redux";
import TradingAccountInformation from "./TradingAccountInformation";
import TradingPaymentHistory from "./TradingPaymentHistory";
import {securedRequest} from "../../../../services/RequestService/index";
import {GET_SINGLE_ACCOUNT} from "../../../../apiRoutes";
import Pagination from './Pagination';

class SingleTradingAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tradingAccount: [],
            tradingId:    this.props.match.params.id,
        }
    }

    componentDidMount () {

        securedRequest.get(`${GET_SINGLE_ACCOUNT}/${this.state.tradingId}`).then((response) => {
            console.log(response);
            this.setState({
                tradingAccount: response.data.data[0]
            });
        }).catch((error) => {
        });
    }

    render() {
        return (
            <Translate>
                {({translate}) => {
                    return (
                        <div className='lead-page'>
                            <div className='content-block'>
                                <div className='top-page-block'>
                                    <div>
                                        <h5>{this.state.tradingId}</h5>
                                        <div className='breadcrumbs'>
                                            <Link to='/dashboard'>{translate(`dashboard_title`)} </Link> > <Link to='/clients'>{translate(`client_account_list`)}</Link>
                                        </div>
                                    </div>
                                    <div className='top-button-area'>
                                        <UncontrolledDropdown className='lead-page-dropdown'>
                                            <DropdownToggle caret>
                                                <div className='button-icon'><i
                                                    className='la la-plus-circle'
                                                /> {translate(`client_action`)}
                                                </div>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem>
                                                            <span><i
                                                                className='la la-check'
                                                            /> {translate(`client_create_task`)}</span>
                                                </DropdownItem>
                                                <DropdownItem>
                                                            <span><i
                                                                className='la la-plus'
                                                            /> {translate(`client_snap_as_duplicate`)}</span>
                                                </DropdownItem>
                                                <DropdownItem>
                                                            <span><i
                                                                className='la la-phone'
                                                            /> {translate(`client_add_phone`)}</span>
                                                </DropdownItem>
                                                <DropdownItem>
                                                            <span><i
                                                                className='la la-envelope'
                                                            /> {translate(`client_add_email`)}</span>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <span><i
                                                        className='la la-minus'/> {translate(`client_delete`)}</span>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                        <div className='button-icon'>
                                            <i className='la la-clock-o'/>
                                            {translate(`client_history_of_changes`)}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <Row>
                                <Col sm='4'>
                                    <TradingAccountInformation
                                    account = {this.state.tradingAccount}
                                    />
                                </Col>
                                <Col sm='8'>
                                    <TradingPaymentHistory />
                                    <Pagination
                                    translate = {translate}
                                    />
                                </Col>
                            </Row>
                        </div>

                    );
                }}
            </Translate>
        );
    }
}
export default withLocalize(SingleTradingAccount);