import {getClients, getClientsByFilterHash, getFilterHash, postFilterData, setFilterHash } from "./AccountRequestService";
import {del, post, get,} from '../../../components/Magic/helpers/MagicRequest'
import {clearAllClients, clearFilterCategory, filterHash } from "../actions";
import {forEach, isArray, isNull, isUndefined, map, omit, reduce, clone} from "lodash";
import { CLIENTS_EXPORT_FIELDS } from "../components/constants";

// TODO: check to remove
export const getFilterHashTable = (id, output = '') => (dispatch) => {
    return getFilterHash(id)
        .then((response) => {
            if (response.data[0]) {
                dispatch(filterHash(response.data));
                return getClientsByFilterHash(response.data[0]["core-filter_hash-id"], output)
            } else {
                return getClients(output);
            }
        });
};

// TODO: check to remove
export const getFilterHashOne = (id) => (dispatch) => {
    return getFilterHash(id)
        .then((response) => {
            if (response.data[0]) {
                dispatch(filterHash(response.data));
            }
        });
};

// TODO: check to remove
export const getFilteredData = (params = 'data=[]', output = '') => {
    let filter;
    return postFilterData(params)
        .then((response) => {
            filter = response;
            return setFilterHash(filter);
            })
        .then(() => {
            return getClientsByFilterHash(filter.data.id, output)
            })
};

// TODO: check to remove
export const clearFilterCategoryAccount = () => (dispatch) => {
    dispatch(clearFilterCategory());
};

export const clearReduxAllClients = () => (dispatch) => {
    dispatch(clearAllClients());
};

// TODO: check to remove
export function onChangeFilter (event) {
    // TODO: to remove (used in app/modules/list_accounts/components/ListTradingAccounts.js)
    const value = event.target.value;
    const name = event.target.name;

    this.setState({
            filter:
                {
                    ...this.state.filter,
                    [name]: {
                        ...this.state.filter[name], 'v': value,
                    },
                },
            advancedFilters:
                {
                    ...this.state.advancedFilters,
                    [name]: {
                        ...this.state.advancedFilters[name],
                        'o': this.state.filter[name].o,
                        'v': value,
                    },
                },
        }
    );
}


// TODO: check to remove
export function applyFilter () {
    // TODO: to remove (used in app/modules/list_accounts/components/ListTradingAccounts.js)
    map(this.props.defFilter, (item) => {
        document.getElementsByName(item)[0] ? document.getElementsByName(item)[0].parentElement.classList.remove('active') : '';
    });
    const filterDataToObj = this.state.advancedFilters;
    const filterData = [];
    const activeFilters = {};
    let newAdvancedFilters = {};
    let filter = JSON.parse( JSON.stringify(this.props.initialFilter) );

    map(filterDataToObj, (item, key) => {
        switch (this.props.fieldsForCheckingAdvanced[key].type) {
            case 'f': {
                let valueArr = [];

                if (item.v) {
                    if (isArray(item.v) && item.o !== 'between') {
                        valueArr = item.v;
                    } else {
                        valueArr.push(item.v);
                    }

                    newAdvancedFilters = {
                        ...newAdvancedFilters,
                        [key]: { 'o': this.state.advancedFilters[key].o, 'v': valueArr }
                    }

                    if (this.props.defFilter.indexOf(key) < 0) {
                        activeFilters[key] = {
                            'o': this.state.advancedFilters[key].o, 'v': valueArr,
                        };
                    } else {
                        filter[key] = {
                            ...filter[key], 'v': valueArr.join(),
                        };
                    }
                }

                if (valueArr.length) {
                    filterData.push({
                        "f": this.props.fieldsForCheckingAdvanced[key].f,
                        "o": this.state.advancedFilters[key].o,
                        "v": valueArr,
                    });
                }
            }
            case 'fkey': {
                let valueArr = [];

                if (item.v) {
                    if (isArray(item.v) && item.o !== 'between') {
                        valueArr = item.v;
                    } else {
                        valueArr.push(item.v);
                    }

                    newAdvancedFilters = {
                        ...newAdvancedFilters,
                        [key]: { 'o': this.state.advancedFilters[key].o, 'v': item.v }
                    }

                    if (this.props.defFilter.indexOf(key) < 0) {
                        activeFilters[key] = {
                            'o': this.state.advancedFilters[key].o, 'v': valueArr,
                        };
                    } else {
                        filter[key] = {
                            ...filter[key], 'v': valueArr,
                        };
                    }
                }

                forEach(this.props.fieldsForCheckingAdvanced[key].fkey, (fItem, k) => {
                    if (key === 'val') {
                        filterData.push({
                            "fkey": this.props.fieldsForCheckingAdvanced[key].f,
                            [fItem.type]: fItem.f,
                            "o": fItem.o,
                            "v": fItem.v,
                        })
                    } else {
                        filterData.push({
                            "fkey": this.props.fieldsForCheckingAdvanced[key].f,
                            [fItem.type]: fItem.f,
                            "o": fItem.o,
                            "v": valueArr,
                        })
                    }
                })
            }
        }
    });

    filterData.push({
        "f": this.state.sort.f,
        "o": this.state.sort.o,
        "v": this.state.sort.v,
    });

    const filters = JSON.stringify(filterData);
    this.setState({
            selectedFilters: {
                ...newAdvancedFilters
            },
            advancedFilters: {
                ...newAdvancedFilters
            },
            filter: {
                ...filter
            },
            activeFilter: false,
            additionalActiveFilters: {
                ...activeFilters,
            },
        }, () => {
            this.getFilteredData(`data=${filters}`, objectToQueryString(this.state.output)).then((response) => {
                this.setState({
                    data: response.data.data,
                    filterCount: response.data.meta.filter,
                    total: response.data.meta.total
                });
            });

            forEach(newAdvancedFilters, (item, key) => {
                if (this.props.defFilter.indexOf(key) >= 0 && !document.getElementsByName(key)[0].parentElement.classList.contains('active')) {
                    document.getElementsByName(key)[0].parentElement.className += ' active';
                }
            });
        }
    );
}


// TODO: check to remove
export const objectToQueryString = (obj) => {
    const qs = reduce(obj, (result, value, key) => {

        return !isNull(value) && !isUndefined(value) ? result += `${key}=${value}&` : result;
    }, '').slice(0, -1);

    return qs;
};

// TODO: check to remove
export function tableSortToggle () {
    this.setState({
        tableSort: !this.state.tableSort,
    });
}

// TODO: check to remove
export function getClassForTableSort () {
    if (this.state.tableSort) {
        return 'sorted';
    }

    return '';
}

// TODO: check to remove
export function onChangeTableSort (event) {

    const type = event.currentTarget.dataset.type;

    if (this.state.total > this.state.filterCount) {
        this.setState({
            sort:
                {
                    ...this.state.sort, 'f': type, 'v': this.state.sort.v === 'asc' ? 'desc' : 'asc',
                },
        }, () => {
            this.applyFilter();
        });
    } else {
        this.setState({
            sort:
                {
                    ...this.state.sort, 'f': type, 'v': this.state.sort.v === 'asc' ? 'desc' : 'asc',
                },
        }, () => {
            this.getFilteredData(`data=${JSON.stringify([{ 'f': this.state.sort.f, 'o': this.state.sort.o, 'v': this.state.sort.v }])}`, objectToQueryString(this.state.output)).then((response) => {
                this.setState({
                    data: response.data.data,
                    filterCount: response.data.meta.filter,
                    total: response.data.meta.total
                });
            });
        });
    }
}

// TODO: check to remove
export function onChangeFilterDataType (event, i) {

    const value = event;
    const item = i;

    this.setState({
        advancedFilters:
            {
                ...this.state.advancedFilters,
                [item]: {
                    ...this.state.advancedFilters[item],
                    'v': value,
                },
            },
    });
}

// TODO: check to remove
export function removeFilterAdditional (event) {
    const item = event.target.dataset.item;
    const additionalActiveFilters = omit(this.state.additionalActiveFilters, item);
    const advancedFilters = omit(this.state.advancedFilters, item);

    this.setState({
            advancedFilters:
                {
                    ...advancedFilters
                },
            additionalActiveFilters:
                {
                    ...additionalActiveFilters
                }
        },
        () => {
            this.applyFilter();
        });
}

// TODO: check to remove
export function removeFilter (event) {
    // TODO: to remove (used in app/modules/list_accounts/components/ListTradingAccounts.js)
    const name = event.target.parentElement.parentElement.children[0].name;
    const filters = {...this.state.filter, [name]: {...this.state.filter[name], 'v': ''}};
    const advancedFilters = omit(this.state.advancedFilters, name);

    this.setState({
            filter:          { ...filters },
            advancedFilters: { ...advancedFilters},
        },
        () => {
            this.applyFilter();
        });

    event.target.parentElement.parentElement.children[0].value = '';
    event.target.parentElement.parentElement.classList.remove('active');
}

// TODO: check to remove
export function onChangeFilterSelectTable (event, name) {
    this.setState({
        filter:
            {
                ...this.state.filter,
                [name]: {
                    ...this.state.filter[name],
                    'v': event.value,
                },
            },
        advancedFilters:
            {
                ...this.state.advancedFilters,
                [name]: {
                    ...this.state.advancedFilters[name],
                    'o': this.state.filter[name].o,
                    'v': event.value,
                },
            }
    });
}

// TODO: check to remove
export function next () {
    const diff = parseInt(this.state.output.skip) + parseInt(this.state.output.take);

    if (parseInt(this.state.filterCount) > diff) {
        const skip = this.state.output.skip + this.state.output.take;

        this.setState({ output: { ...this.state.output, skip }}, () => {
            this.applyFilter();
        });
    }
}

// TODO: check to remove
export function prev () {
    if (this.state.output.skip >= 1) {
        const skip = this.state.output.skip - this.state.output.take;

        this.setState({ output: { ...this.state.output, skip }}, () => {
            this.applyFilter();
        });
    }

    return true;
}

// TODO: check to remove
export function first () {
    this.setState({ output: { ...this.state.output, 'skip': 0 }}, () => {
        this.applyFilter();
    });
}

// TODO: check to remove
export function last () {
    const div = this.state.filterCount % this.state.output.take;

    let skip = 0;

    if (div === 0) {
        skip = this.state.filterCount - this.state.output.take;
    } else {
        skip = this.state.filterCount - div;
    }

    this.setState({ output: { ...this.state.output, skip }}, () => {
        this.applyFilter();
    });
}

// TODO: check to remove
export function reset () {
    // TODO: to remove (used in app/modules/list_accounts/components/ListTradingAccounts.js)
    this.setState({
            filter: {
                ...this.props.filter,
            },
            advancedFilters: {},
            additionalActiveFilters: {}
        },
        () => {
            this.applyFilter();
        }
    );

    const allFilterInputs = document.querySelectorAll('.input-block .filter');

    allFilterInputs.forEach((item) => {
        item.parentElement.children[0].value = '';
        item.parentElement.classList.remove('active');
    });

}

// TODO: check to remove
export function handlePwdKeyUp (e) {
    // TODO: to remove (used in app/modules/list_accounts/components/ListTradingAccounts.js)
    if (e.key === 'Enter') {
        this.first();
    }
}

export async function exportToExcel (filter_id, checkAccess) {
    const url = `/api/core/account/export-to-excel`;
    const options = {
        file_name: 'Clients',
        fields: CLIENTS_EXPORT_FIELDS['clients'].filter(checkAccess),
    };

    if (filter_id) {
        options['filter_id'] = filter_id;
    }

    try {
        await post(url, options);
    } catch (error) {
        error?.showErrorNotification();
    }
}

// TODO: check to remove
export function toggleFilter () {
    this.setState({
        activeFilter: !this.state.activeFilter,
    });
}

export async function submitDistribution ({user_id, account_ids}) {
    try {
    	return await post('/api/core/account/change-managers', {user_id, account_ids});
    } catch (error) {
        error?.showErrorNotification();
    }
}

export async function submitCreateCallQueue ({user_id, account_ids}) {
    try {
    	return await post('/api/calls/user-call-queue-members', {user_id, account_ids});
    } catch (error) {
        error?.showErrorNotification?.();
    }
}

export async function submitClearCallQueue ({ user_id }) {
    try {
    	return await del(`/api/calls/user-call-queue-members/clear-queue/${user_id}`);
    } catch (error) {
        error?.showErrorNotification?.();
    }
}

export async function getCallQueue ({ user_id }) {
    try {
    	return await get(`/api/calls/user-call-queue-members/get-queue/${user_id}`);
    } catch (error) {
        error?.showErrorNotification?.();
    }
}