export const dashboardLang = {
    "dashboard_title": ["Dashboard", "Панель инструментов", "Панель інструментів"],
    "dashboard_dateFrom": ["From", "От", "Від"],
    "dashboard_dateTo": ["To", "До", "До"],
    "dashboard_crm_email": ["CRM email templates", "CRM шаблоны писем", "CRM шаблони листів"],
    "dashboard_sendpulse": ["SendPulse email templates", "SendPulse шаблоны писем", "SendPulse шаблони листів"],
    "dashboard_sms": ["CRM SMS templates", "CRM шаблоны SMS", "CRM шаблони SMS"],
    "dashboard_event_type": ["Event Type", "Событие", "Подія"],
    "dashboard_subject": ["Subject", "Тема", "Тема"],
    "dashboard_template": ["Template", "Шаблон", "Шаблон"],
    "dashboard_call": ["Call", "Звонок", "Дзвінок"],
    "dashboard_write": ["Write", "Письмо", "Лист"],
    "dashboard_export": ["Export", "Экспорт", "Експорт"],
    "dashboard_by_date": ["New", "Новые", "Нові"],
    "dashboard_all_time": ["Old", "Старые", "Старі"],
    "dashboard_conversion": ["Conversion", "Конверсия", "Конверсія"],
    "dashboard_processing": ["Processing", "Обработка", "Обробка"],
    "dashboard_warming": ["Warming", "Утепление", "Утеплення"],
    "dashbord_total": ["Total", "Всего", "Вcього"],
    "dashboard_not_urgent": ["Not urgent", "Не срочный", "Не терміновий"],
    "dashboard_ordinary": ["Ordinary", "Обычный", "Звичайний"],
    "dashboard_important": ["Important", "Важный", "Важливий"],
    "dashboard_urgent": ["Urgent", "Срочный", "Терміновий"],
    "dashboard_new": ["New", "Новый", "Новий"],
    "dashboard_considered": ["Considered", "На рассмотрении", "Розглядається"],
    "dashboard_in_progress": ["In Process", "В процессе", "У процесі"],
    "dashboard_ready": ["Ready", "Готово", "Готово"],
    "dashboard_done": ["Done", "Сделано", "Зроблено"],
    "dashboard_reset": ["Reset", "Сбросить", "Скинути"],
    "dashboard_details": ["Details", "Подробности", "Деталі"],
    "no_data": ["No data", "Нет данных за период", "Немає даних за перiод"],
    "briefcase": ["Briefcase", "Портфель", "Портфель"],
    "funnel": ["Funnel", "Воронка", "Воронка"],
    "registrations": ["Registrations", "Регистрации", "Реєстрації"],
    "briefcase_size": ["Briefcase size", "Размер портфеля", "Розмір потрфеля"],
    "conversion_perc": ["Conversion percent", "Процент конверсии", "Відсоток конверсії"],
    "processing_perc": ["Processing percent", "Процент обработки", "Відсоток обробки"],
    "processing_table": ["Processing", "Обработка", "Обробка"],
    "briefcase_title": ["Briefcase percentage of processing", "Портфель: % обработки", "Портфель: % обробки"],
    "briefcase_title_1": ['Briefcase status: Portfolio processing', 'Состояние портфеля: % обработки', 'Стан портфелю: % обробки'],
    "funnel_title": ["% сonversion", "% конверсии", "% конверсії"],
    'filter-currentMonth': ['Current month', 'За текущий месяц', 'За поточний місяць'],
    'filter-exactMonth': ['Certian month', 'За определенный месяц', 'За певний місяць'],
    'filter-quarter': ['Certain quarter ', 'За определенный квартал', 'За певний квартал'],
    'filter-year': ['Certain year', 'За определенный год', 'За певний рік'],
    'filter-exactDate': ['Date to date', 'С даты по дату', 'З дати по дату'],
    'filter-allTime': ['All period', 'За все время', 'За весь час'],
    'filter-halfYear': ['Six months', 'Полгода', 'Півроку'],
    'filter-warmth': ['Warmth filter', 'Фильтр теплоты', 'Фільтр теплоти'],
    'filter-select-user': ['Select user', 'Выберите менеджера', 'Виберіть менеджера'],
    'checkbox-warm': ['Warm', 'Теплый', 'Теплий'],
    'checkbox-hot': ['Hot', 'Горячий', 'Гарячий'],
    'inout-widget': ['In/Out', 'Ввод/Вывод', 'Введення/Виведення'],
    'clients-span': ['Clients', 'Клиентов', 'Клієнтів'],
    'in-span': ['Quantity IN', 'Кол-во завели', 'Кількість завели'],
    'out-span': ['Quantity OUT', 'Кол-во вывели', 'Кількість вивели'],
    'plan-in-span': ['Plan In', 'План ввода', 'План вводу'],
    'plan-out-span': ['Plan Out', 'План вывода', 'План виведення'],
    'plan-inout-span': ['InOut plan', 'План InOut', 'План InOut'],
    'inout': ['InOut', 'Ввод-Вывод', 'Ввід-Вивід'],
    'activations': ['Activations', 'Активации', 'Активації'],
    'common-activations': ['General activations', 'Общие активации', 'Загальні активації'],
    'new-activations': ['Activations (new clients)', 'Активаций (новых клиентов)', 'Активацій (нових клієнтів)'],
    'old-activations': ['Activations (old clients)', 'Активаций (старых клиентов)', 'Активацій (старих клієнтів)'],
    'pie-primary': ['Primary Replenishment', 'Кол-во первичных', 'Поповнилися вперше'],
    'pie-second-primary': ['Secondary replenishment', 'Кол-во вторичных', 'Кількість повторних'],
    'pie-first-overall': ['Primary replenishment', 'Первичные пополнения', 'Первинні поповнення'],
    'pie-second-overall': ['Secondary replenishment', 'Вторичные пополнения', 'Вторинні поповнення'],
    'pie-average': ['Average check', 'Средний чек', 'Середній чек'],
    'pie-ovarall': ['Overall', 'Сумма общая', 'Сума загальна'],
    'pie-title': ['Account recharging', 'Пополнения', 'Поповнення'],
    'pie-popup-title-1': ['Primary', 'Первичное', 'Перше'],
    'cold-clients': ['Cold clients', 'Холодные клиенты', 'Холоднi клiєнти'],
    'conversion-percantage-span': ['percentage of processing', '% обработки', '% обробки'],
    'clients-amount': ['amount of clients', 'кол-во клиентов', 'к-сть клієнтів'],
    'clients-amount-1': ['Quantity of clients', 'Кол-во (клиентов)', 'Кількість (клиентiв)'],
    'tooltip-1': ['(except cold, archived and deleted)', '(кроме холодных, архивных и удаленных)', '(крім холодних, архівних і видалених)'],
    'deposits-title': ['Top-up amounts', 'Суммы пополнений', 'Суми поповнень'],
    'deposits-0': ['up to 499.99 $', 'до 499.99 $', 'до 499.99 $'],
    'deposits-1': ['from 500 to 999.99 $', 'от 500 до 999.99 $', 'від 500 до 999.99 $'],
    'deposits-2': ['from 1000 to 2999.99 $', 'от 1000 до 2999.99 $', 'від 1000 до 2999.99 $'],
    'deposits-3': ['from 3000 to 9999.99 $', 'от 3000 до 9999.99 $', 'від 3000 до 9999.99 $'],
    'deposits-4': ['from 10000 to 49999.99 $', 'от 10000 до 49999.99 $', 'від 10000 до 49999.99 $'],
    'deposits-5': ['from 50000 to 99999.99 $', 'от 50000 до 99999.99 $', 'від 50000 до 99999.99 $'],
    'deposits-6': ['from 100000.00 $', 'от 100000.00 $', 'від 100000.00 $'],
    'dashboard-rating': ['Rating', 'Рейтинг', 'Рейтинг'],
    'conversion-rating-title': [`Сonversion manager's rating`, 'Рейтинг менеджеров по конверсии', 'Рейтинг менеджерiв по конверсії'],
    'inout-rating-title': ['InOut rating of reten managers', 'Рейтинг менеджеров ретена по InOut', 'Рейтинг менеджерів ретена по InOut'],
    'dashboard-company-title': ['Overall state', 'По компании в целом', 'Загалом по компанiї'],
    'recharge-title': ['Account recharging', 'Пополнения', 'Поповнення'],
    'deposits-amount': ['Replenishment quantity', 'Кол-во пополнений', 'Кількість поповнень'],
    "deposits-sum": ["Deposits sum for the period", "Сумма пополнений за период", "Сума поповнень за період"],
    'deposits-widget-title': ['Account recharging', 'Пополнения', 'Поповнення'],
    'manager-statistic-title': ['Manager’s statistic', 'Статистика по менеджеру', 'Статистика по менеджеру'],
    'results-contacts': ['Contacts', 'Контакты', 'Контакти'],
    'total-sum': ['Total', 'Сумма', 'Сума'],
    'out-sum': ['Out', 'Вывод', 'Вивід'],
    'total-activations': ['Total activation', 'Всего активаций', 'Всього активацій'],
    'processed-percent': ['Processed', 'Обработано', 'Опрацьовано'],
    'conversion-percent': ['Conversion', 'Конверсия', 'Конверсія'],
    'total-in-briefcase': ['Total in briefcase', 'В портфеле', 'В портфелі'],
    'results-title': ['My results: % conversion', 'Мои результаты: % конверсии', 'Мої здобутки: % конверсії'],
    'statistics-h1': ['Registration statistics by advertising channels', 'Статистика регистраций по рекламным каналам', 'Статистика реєстрацій по рекламним каналам'],
    'statistics-channels': ['Channels', 'Каналы', 'Канали'],
    'statistics-subchannels': ['Subchannels', 'Подканалы', 'Пiдканали'],
    'statistics-products': ['Products', 'Продукты', 'Продукти'],
    'statistics-choose-source': ['Choose channel', 'Выберите канал', 'Оберiть канал'],
    'statistics-choose-medium': ['Choose subchannel', 'Выберите подканал', 'Оберiть пiдканал'],
    'statistics-dateFrom': ['Date From', 'Дата С', 'Дата З'],
    'statistics-dateTo': ['Date To', 'Дата По', 'Дата По'],
    'statistics-registration-source': ['Registrations', 'Регистрации', 'Реєстрації'],
    'statistics-source-payment': ['Top-up amount', 'Сумма пополнений', 'Сума поповнень'],
    'statistics-utm-source': ["Channels", "Каналы", "Канали"],
    'statistics-utm-medium': ["Subchannels", "Подканалы", "Пiдканали"],
    'statistics-utm-campaign': ["Products", "Продукты", "Продукти"],
    'statistics-utm-period': ["Statistics for the period", "Статистика за период", "Статистика за період"],
    'statistics-not-found-utm-medium': ["No information", "Нет информации", "Немає інформації"]
};
