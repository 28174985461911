import {
	itemsBuild, itemsDrain, itemsResort, CFG_ITEM_TYPE,
} from "../Config";
import { onEnabled, render, } from "../Mentor/withDropDown";

const f_lang = {
	"drop-down": "/api/core/language/drop-down",
	"o=": [ "=", "!=", "null", "not_null", ],
};

const p_lang = {
	onEnabled: onEnabled("lendingsLanguage"),
	render: render("lendingsLanguage"),
};

const f_target_page = {
	"drop-down": "/api/partners/target-pages/drop-down",
	"o=": [ "=", "!=", "null", "not_null", ],
}

const p_target_page = {
	onEnabled: onEnabled("targetPage"),
	render: render("targetPage"),
};

export default {
	id: 169,
	...[
		// filters
		...[
			{
				f: "partners.lending.id",
				id: "f:lendingId",
			},
			{
				id: "f:lendingName",
				f: "partners.lending.name",
			},
			{
				id: "f:landingLanguage",
				f: "core.language.id",
				...f_lang,
			},
			{
				id: "f:lendingStatus",
				f: "partners.lending.status",
			},
			{
				id: "f:lendingTargetPages",
				f: "partners.lending.target_page_id",
				...f_target_page,
			},
			{
				id: "f:lendingApplicationDate",
				f: "partners.lending.created_at",
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: "filter", ..._, })),

		// orders
		...[
			{
				id: "o:lendingId",
				f: "partners.lending.id",
			},
			{
				id: "o:lendingName",
				f: "partners.lending.name",
			},
			{
				id: "o:lendingStatus",
				f: "partners.lending.status",
			},
			{
				id: "o:lendingTargetPage",
				f: "partners.target_pages.name",
			},
			{
				id: "o:lendingApplicationDate",
				f: "partners.lending.created_at",
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: "order", ..._, })),

		// panels
		...[
			{
				t: "partners_lendings_id",
				ii: "f:lendingId",
			},

			{
				t: "partners_lendings_name",
				ii: "f:lendingName",
			},
			{
				t: "partners_lendings_language",
				ii: "f:landingLanguage",
				...p_lang,
			},
			{
				t: "partners_lendings_status",
				ii: "f:lendingStatus",
			},
			{
				t: "partners_lendings_target_page",
				ii: "f:lendingTargetPages",
				...p_target_page,
			},
			{
				t: "partners_lendings_application_date",
				ii: "f:lendingApplicationDate",
			},
		]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: "panel", ..._, })),
	]
		.map(itemsBuild)
		.reduce(itemsDrain, [])
		.reduce(itemsResort, {}),

	path: "partners/lending",
	widget: false,
};