import PropTypes from "prop-types";
import React from "react";

export const DefaultIcon = ({
    name = '',
    surname = '',
}) => {
    return (
        <span className = 'nav-top-img'>
            <div className="defaultIcon">
                {name?.charAt?.(0).toUpperCase() ?? ''}
                {surname.charAt(0).toUpperCase()}
            </div>
        </span>
    );
}

DefaultIcon.propTypes = {
    name: PropTypes.string,
    surname: PropTypes.string, // TODO should be required?
};