import React, { Component } from 'react';
import { withLocalize } from "react-localize-redux";
import {EDIT_TEMPLATE, SMS_KEY, E_MAIL_KEY} from '../constants';
import PermissionService from "../../../../../services/PermissionService";

class ModalBTNTable extends Component {
    constructor (props) {
        super(props);

        this.state = {
            are_u_sure: false,
        };
    }

    get templatesConfig () {
        return [
            {
                path: ['core', 'sms_templates'],
                key:  'smsTemplates',
            },
            {
                path: ['core', 'mail_templates'],
                key:  'mailTemplates',
            },
        ];
    }

    areSure = () => {
        this.setState((state)=>{
            return { are_u_sure: !state.are_u_sure }
        })
    }

    renderEditBtn = ({ id_template, tabId, translate, permission }) => {
        const defaultValue = '-';
        const keyForTemplate = parseInt(tabId) === SMS_KEY ? SMS_KEY : parseInt(tabId) === E_MAIL_KEY ? E_MAIL_KEY : null;

        if (permission?.access('index', 'update')) {
            return (
                <a className='button templateManagement_table_btn_edit'
                   onClick={() => {
                       this.props.togglePopUpTemplateManagement(keyForTemplate, EDIT_TEMPLATE, id_template);
                       this.props.togglePopup();
                   }}
                >
                    {translate(`template_management_btn_edit`)}
                </a>
            );
        } else if (permission?.access('index')) {
            return (
                <span className='button templateManagement_table_btn_edit button--disabled'>
                    {translate(`template_management_btn_edit`)}
                </span>
            );
        } else {
            return <span>{defaultValue}</span>;
        }
    };

    renderDeleteBtn = ({ translate, permission }) => {
        const defaultValue = '-';

        if (permission?.access('index', 'destroy')) {
            return (
                <a className='button templateManagement_table_btn_delete'
                   onClick={()=>{this.areSure()}}
                >
                    {translate(`template_management_btn_delete`)}
                </a>
            );
        } else if (permission?.access('index')) {
            return (
                <span className='button templateManagement_table_btn_delete button--disabled'>
                    {translate(`template_management_btn_delete`)}
                </span>
            );
        } else {
            return <span>{defaultValue}</span>;
        }
    };

    render () {
        const { tabId, id_template, translate } = this.props;
        const conf = PermissionService.calc(this.templatesConfig);
        const pathMap = {[SMS_KEY]: 'smsTemplates', [E_MAIL_KEY]: 'mailTemplates'};
        const permission = conf?.[pathMap[parseInt(tabId)]];

        return (
            <div className = 'modal_management-BTN modal_management-BTN_templates'>

                <React.Fragment>
                    {this.renderEditBtn({ id_template, tabId, translate, permission })}
                    {this.renderDeleteBtn({ translate, permission })}
                </React.Fragment>

                {this.state.are_u_sure ?
                    <div className="modal_delete">
                        <h4>{translate(`template_management_are_u_sure`)}</h4>
                        <p>{translate(`template_management_you_want_to_delete`)} {translate(`template_management_template`)}</p>
                        <div className="modal_delete-btn">
                            <a className = 'btn_yes' onClick={()=>{
                                this.props.deleteTemplate(id_template, tabId, translate);
                            }}>{translate(`template_management_yes`)}</a>
                            <a className = 'btn_no' onClick={()=>{this.areSure()}}>{translate(`template_management_no`)}</a>
                        </div>
                    </div>
                    : ''
                }

            </div>
        );
    }
}
export default withLocalize(ModalBTNTable);
