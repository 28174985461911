import {
	setErrorClass,
	onValidEmailInput,
	emailInputIsValid,
	phoneInputIsValid,
	onValidPhoneInput
} from "./validation-helpers";

export {
	setErrorClass,
	onValidEmailInput,
	emailInputIsValid,
	phoneInputIsValid,
	onValidPhoneInput,

};