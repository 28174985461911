import React, { Component, } from "react";

import { MagicConfirm, } from "../../../../../tima/components/Magic";
import { Popup, Select, } from "../../../../components";

import {
	getPartnersPrograms,
	changePartnerProgramm,
} from "../../../../../../services/PartnersRequestService";

import { findOptionByValue, setErrorClass, } from "../../../../utils/helpers";

export default class SwitchPartnerProgramm extends Component {
	constructor (props) {
		super(props);

		this.state = {
			allPrograms: [],
			errors: [],
			loaded: false,
			runCalculations: 2,
			selectedProgramm: null,
			showConfirm: true,
		};
	}

	get programsAvailableOptions () {
		const { allPrograms, } = this.state;
		const currentProgrammId = this.props.partner.partners.program.id;

		if (!allPrograms) {
			return [];
		}

		return allPrograms
			.filter(
				program =>
					+program.partners.programs.status !== 1 &&
					+program.partners.programs.status !== 5 &&
					+program.partners.programs.id !== +currentProgrammId,
			)
			.map((program) => {
				return {
					label: program.partners.programs.name,
					value: program.partners.programs.id,
				};
			}); //not NEW or CLOSED
	}

	save = state => new Promise(next => this.setState(state, next));

	componentDidMount = async () => {
		try {
			const response = await getPartnersPrograms();
			const allPrograms = response.data;

			await this.save({ allPrograms, });
		} catch (error) {
			console.log(error);
			error.showErrorNotification();
		} finally {
			await this.save({ loaded: true, });
		}
	};

	getSendData = () => {
		const partner_id = this.props.partner.core.account.id;
		const program_id = this.state.selectedProgramm;
		const is_payout = +this.state.runCalculations;

		return { is_payout, partner_id, program_id, };
	};

	handleOnChange = (event) => {
		const { name, value, } = event.target;
		const { errors, } = this.state;

		//clear error onChange
		this.save({ errors: errors.filter(error => error !== name), });

		switch (name) {
			case "program_id": {
				this.save({ selectedProgramm: value.value, });
				break;
			}
			case "run_calculations": {
				this.save({ runCalculations: event.target.checked ? 1 : 2, });
				break;
			}
			default: {
				return null;
			}
		}
	};

	renderSelectPartnerProgramm = () => {
		const programs = this.programsAvailableOptions;
		const { selectedProgramm, errors, } = this.state;
		const translate = this.props.translate;

		return (
			<div className="view-edit-request__row">
				<Select
					className={ setErrorClass(errors, "program_id") }
					hideLabel={ true }
					name={ "program_id" }
					options={ programs }
					placeholder={ translate(
						"partners_requests_partner_program_placeholder",
					) }
					value={ findOptionByValue(programs, selectedProgramm) }
					// eslint-disable-next-line max-len
					onChange={ this.handleOnChange }
				/>
			</div>
		);
	};

	renderRunCalculationsSwitch = () => {
		const translate = this.props.translate;

		return (
			<div className="block-header_input-checkbox switch-custom-styles">
				<label className="checkbox">
					<input
						name="run_calculations"
						type="checkbox"
						onClick={ this.handleOnChange }
					/>
					<span className="checkbox__icon" />
				</label>
				<p> {translate("partner_run_calculations")}</p>
			</div>
		);
	};

	toggleConfirm = () => this.save({ showConfirm: !this.state.showConfirm, });

	onPopupAccept = async () => {
		try {
			const { refreshClientData, onClose, } = this.props;
			const { selectedProgramm, errors, } = this.state;

			if (!selectedProgramm) {
				return this.save({ errors: [ ...errors, "program_id", ], });
			}

			this.save({ loaded: false, });

			const sendData = this.getSendData();
			await changePartnerProgramm(sendData);

			await onClose();
			await refreshClientData();
			await this.save({ loaded: true, });
		} catch (error) {
			await this.save({ loaded: true, });
			error.showErrorNotification?.();
		}
	};

	render () {
		const { onClose, translate, } = this.props;
		const { loaded, showConfirm, } = this.state;
		const { onConfirmAccept, toggleConfirm, onPopupAccept, } = this;
		const popupDynamicSize = showConfirm ? 'md' : 'smd';

		return (
			<>
				<Popup
					cancelBtnText={ "partner_confirm_cancel" }
					createBtnText={ "partner_confirm_proceed" }
					isImage={ false }
					loadData={ loaded }
					size={ popupDynamicSize } //popup body is overflow hidden, need more space for confirm
					title={ translate("partner_programm_change") }
					wrapperClassname={ `change-partner-programm` }
					onClick={ onPopupAccept }
					onClose={ onClose }
				>
					{this.renderSelectPartnerProgramm()}
					{this.renderRunCalculationsSwitch()}
					<MagicConfirm
						accept={ translate("partner_confirm_proceed") }
						className={ 'magic-confirm magic-confirm_popup-style' }
						isVisible={ showConfirm }
						reject={ translate("partner_confirm_cancel") }
						title={ translate(`magic_confirm_title`) }
						text={ translate("partner_confirm_change_programm") }
						onAccept={ toggleConfirm }
						onReject={ onClose }
					/>
				</Popup>
			</>
		);
	}
}