import {
	itemsBuild,
	itemsDrain,
	itemsResort,
	CFG_ITEM_TYPE,
} from '../Config';
import {onEnabled, render} from "../Mentor/withDropDown";

const f_partner = {
	'drop-down': '/api/partners/programs/drop-down?take=all',
};
const p_partner = {
	onEnabled: onEnabled('tUserIds'),
	render: render(
		'tUserIds',
		_ => _ ? `${ _?.name }` : '-',
	),
};
export default {
	id: 184,
	...[

        // filters
		...[ {
			f: 'partners.awaiting_change_program.id',
			id: 'f:id',
		}, {
			f: [ 'core.account.surname', 'core.account.name', ],
			id: 'f:partners',
		}, {
			f: 'partners.awaiting_change_program.program_id',
			id: 'f:newPartnership',
			...f_partner,
		}, {
			f: 'partners.awaiting_change_program.status',
			id: 'f:status',
		}, {
			f: 'partners.awaiting_change_program.created_at',
			id: 'f:created',
		}, {
			f: 'partners.awaiting_change_program.updated_at',
			id: 'f:updated',
		}, ]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: 'filter', ..._, })),

        // orders
		...[ {
			f: 'partners.awaiting_change_program.id',
			id: 'o:id',
		}, {
			f: 'partners.awaiting_change_program.partner_id',
			id: 'o:partners',
		}, {
			f: 'partners.awaiting_change_program.program_id',
			id: 'o:newPartnership',
		}, {
			f: 'partners.awaiting_change_program.status',
			id: 'o:status',
		}, {
			f: 'partners.awaiting_change_program.created_at',
			id: 'o:created',
		}, {
			f: 'partners.awaiting_change_program.updated_at',
			id: 'o:updated',
		}, ]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: 'order', ..._, })),

        // panels
		...[ {
			ii: 'f:id',
			t: 'symbols_id',
		}, {
			ii: 'f:partners',
			t: 'partner_dashboard_table_partners',
		}, {
			ii: 'f:newPartnership',
			t: 'partner_dashboard_table_new_partnership',
			...p_partner,
		}, {
			ii: 'f:status',
			t: 'symbols_status',
		}, {
			ii: 'f:created',
			t: 'symbols_created_at',
		}, {
			ii: 'f:updated',
			t: 'symbols_updated_at',
		}, ]
			.filter(_ => _ && !_.off)
			.map(_ => ({ [CFG_ITEM_TYPE]: 'panel', ..._, })),

	]
		.map(itemsBuild)
		.reduce(itemsDrain, [])
		.reduce(itemsResort, {}),

	path: 'partners/awaiting-change-program',
	widget: false,
};